import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IndustryTypeBase {

//#region industryTypeId Prop
        @prop()
        industryTypeId : number;
//#endregion industryTypeId Prop


//#region industryTypeName Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        //@alpha({allowWhiteSpace:true})
        @trim()
        industryTypeName : string;
//#endregion industryTypeName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}