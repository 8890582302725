import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vShippingMethodBase {

//#region shippingMethodID Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'ShippingMethodListComponent_ShippingMethodID_gh', keyColumn: true})
        shippingMethodID : number;
//#endregion shippingMethodID Prop


//#region shippingMethodName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'ShippingMethodListComponent_ShippingMethodName_gh', keyColumn: false})
        shippingMethodName : string;
//#endregion shippingMethodName Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'ShippingMethodListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}