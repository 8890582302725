import {vGemStoneCutTypesLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneCutTypesLookUp extends vGemStoneCutTypesLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}