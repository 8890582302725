import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CoreComponent } from '@rxweb/angular-router';
import { AppGrid } from 'src/app/domain/app-grid';
import { multilingual } from '@rxweb/localization';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { FabergeOrderDetailsViewModel } from 'src/app/view-model/faberge-order-details-view-model';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';
@multilingual("CorporateOrdersDetailComponent")
@Component({
    selector: "app-coporate-orders-detail",
    templateUrl: './corporate-orders-detail.component.html'
})
export class CorporateOrdersDetailComponent extends CoreComponent implements OnInit, OnDestroy {
    subscription: any;
    router: Router;
    modalView: ModalView;
    @Input() orderId: number;
    @Input() hide: Function;
    fabergeOrderDetailsViewModel: FabergeOrderDetailsViewModel[];
    fabergeOrderDetailGrid: AppGrid;
    rowCount: number = PaginationEnum.RowCount;
    pageIndex: number = PaginationEnum.PageIndex;
    sortOrder: string = "";
    orderBy: string = "";
    isFilter: boolean;
    totalRecords: number;
    spin: boolean = false;
    get componentName(): string {
        return "CorporateOrdersDetailComponent";
    }
    titleClose: string;
    constructor(private activatedRoute: ActivatedRoute, router: Router, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.router = router;
        this.modalView = modalView;
        location.onPopState(() => this.hide());
    }
    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.isFilter = false;
        this.bindGrid();
    }

    bindGrid() {
        var json = {
            orderId: this.orderId,
            rowCount: this.rowCount,
            pageIndex: this.pageIndex,
            sortOrder: this.sortOrder,
            orderBy: this.orderBy
        }
        this.spin = true;
        this.totalRecords = 0;
        this.post({ path: 'api/SearchUsers/CorporateOrderDetails', body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
            this.spin = false;
            this.fabergeOrderDetailsViewModel = t;
            this.totalRecords = this.fabergeOrderDetailsViewModel[0].totalCount;
            if (!this.isFilter) {
                this.fabergeOrderDetailGrid = new AppGrid(this.fabergeOrderDetailsViewModel, FabergeOrderDetailsViewModel, { actions: { onSerialNoLink: this.onSerialNoLink.bind(this) } });
                this.fabergeOrderDetailGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            } else {
                this.fabergeOrderDetailGrid.storeProcedure.length = this.totalRecords;
                this.fabergeOrderDetailGrid.updateSource([]);
                this.fabergeOrderDetailGrid.updateSource(this.fabergeOrderDetailsViewModel);
            }
            this.fabergeOrderDetailGrid.maxPerPage = this.rowCount;
            this.fabergeOrderDetailGrid.componentId = this.componentName;
        })
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.pageIndex = x;
        this.rowCount = this.fabergeOrderDetailGrid.maxPerPage;
        this.fabergeOrderDetailGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.sortOrder = y;
        this.orderBy = x;
        this.fabergeOrderDetailGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onSerialNoLink(fabergeOrderDetailsViewModel: FabergeOrderDetailsViewModel) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/product-piece', fabergeOrderDetailsViewModel.productVariantBatchPieceId], { queryParams: { "activeTab": "piece-info" } }));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(['/product-piece', fabergeOrderDetailsViewModel.productVariantBatchPieceId], { queryParams: { "activeTab": "piece-info" } });
        this.hide();
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
