import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IANCompanyCustomerMappingBase {

//#region iANCompanyCustomerMappingId Prop
        @prop()
        iANCompanyCustomerMappingId : number;
//#endregion iANCompanyCustomerMappingId Prop

//#region companyId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required({message:"Please Select Gemfields Group Entity"})
        companyId : number;
//#endregion companyId Prop

//#region customerId Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
       // @required()
        @prop({defaultValue:0})
        customerId : number;
//#endregion customerId Prop

//#region OccuranceType Prop
        @prop()
        OccuranceType : string;
//#endregion OccuranceType Prop

//#region sensitive Prop
        @prop({defaultValue:0})
        sensitive: boolean;
//#endregion sensitive Prop

        @prop({defaultValue:0})
        general: boolean;
}