import { Injectable } from "@angular/core";
import { BlobStorageService } from "./blob-storage.service";
import { BlobUploadCommonResponse } from "@azure/storage-blob";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BlobUploadsViewStateService {
  constructor(private blobStorage: BlobStorageService) {}

  uploadItems(files: File, fileName: string) {
    return this.uploadFile(files, fileName);
  }

  uploadMultipleItems(files: File[], fileName: string) {
    return this.uploadMultipleFile(files, fileName).then((data) => {
      return data;
    });
  }

  private uploadMultipleFile = (files: File[], fileName: string) => {
    return this.blobStorage.multipleUploadToBlobStorage(
      files,
      "client-blob/documents/notes/" + fileName
    );
  };

  private uploadFile = (file: File,  fileName: string) => {
    return this.blobStorage.uploadToBlobStorage(
      file,
      fileName
    );
  };
}
