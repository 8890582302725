import { prop, range } from '@rxweb/reactive-form-validators';

export class HomeLayoutDetailLocalizedBase {

    //#region layoutLocalizedId Prop
    @prop()
    layoutLocalizedId: number;
    //#endregion layoutLocalizedId Prop

    //#region detailId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @prop()
    detailId: number;
    //#endregion detailId Prop

    //#region languageId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @prop()
    languageId: number;
    //#endregion languageId Prop

    //#region title Prop
    @prop()
    title: string;
    //#endregion title Prop

    //#region subTitle Prop
    @prop()
    subTitle: string;
    //#endregion subTitle Prop

    //#region description Prop
    @prop()
    description: string;
    //#endregion description Prop

    //#region url Prop
    @prop()
    url: string;
    //#endregion url Prop

}