import { gridColumn, actionColumn } from '@rxweb/grid';
import { FabergePriceListPiecesViewModelBase } from '../models/database-models/faberge-price-list-pieces-view-model-base';
import { FileDetailViewModel } from './file-detail-view-model';

@actionColumn({
  allowSorting: false,
  style: { width: "2%", "text-align": "center" },
  configuredTemplate: { templateName: 'pencilButton' }, columnIndex: 41, headerTitle: "Action"
})

export class PiecesViewModel {

  @gridColumn({
    parameter: false,
    template: {
      a: { 
        attributes: {
          href: function (e) {
            return this.imageUrlOrg
          },
          type: "image/*",
          title: function (e) {
            return this.imageUrlOrg.split('/')[this.imageUrlOrg.split('/').length - 1]?.split('?')[0];
          },
          "data-gallery": ""
        },
        childrens: [{
          div: {
            class: ["picture-tab-div"]
          },
          img: {
            attributes: {
              src: function (e) {
                return this.imageUrl
              },
            },
            event: {
              error: "onImgError"
            },
            style: { width: "90px", "max-height": "100%", "cursor": "pointer" },
            class: ["grid-image"]
          }
        }],
        event: {
          click: "onImgLoad"
        },
      }
    }
    , style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "imageUrl", visible: true, columnIndex: 0, allowSorting: false, headerKey: 'ProductPieceListComponent_Image_gh', keyColumn: false
  })
  imageUrl: any;

  @gridColumn({
    parameter: true,
    template: {
      img: {
        attributes: {
          src: function (e) {
            return this.stockImageUrl
          },
        },
        event: {
          error: "onStockImgError"
        },
        style: { width: "90px", "max-width": "100%", "max-height": "100%", "height": "90px" },
        class: ["grid-image"]
      }
    }
    , style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "imageUrl", visible: false, columnIndex: 0, allowSorting: false, headerKey: 'ProductPieceListComponent_Image_gh', keyColumn: false
  })
  stockImageUrl: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.designName;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ProductPieceListComponent_Design_gh', keyColumn: false
  })
  designName: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.productName;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'ProductPieceListComponent_ProductVariantName_gh', keyColumn: false
  })
  productName: string;

  @gridColumn({
    parameter: false, style: { width: "2%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 3, allowSorting: false, headerKey: 'ProductPieceListComponent_IsCoreProduct_gh', keyColumn: false,
    configuredTemplate: { templateName: 'coreProduct' }
  })
  coreProduct: boolean;

  @gridColumn({
    configuredTemplate: { templateName: 'superSeller' },
    parameter: false, style: { width: "2%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'ProductPieceListComponent_IsSuperSeller_gh', keyColumn: false
  })
  superSeller: boolean;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ProductPieceListComponent_BatchNo_gh', keyColumn: false })
  productVariantBatchId: number;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.pieceSerialNumber;
          }
        },
      }
    },
    parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ProductPieceListComponent_Serial_gh', keyColumn: false
  })
  pieceSerialNumber: string;

  @gridColumn({
    parameter: false, configuredTemplate: { templateName: 'barcodeImageView' }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "barcodeUrl", visible: true, columnIndex: 7, allowSorting: false, headerKey: 'ProductPieceListComponent_Barcode_gh', keyColumn: false
  })
  barcodeUrl: any;
  
  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'ProductPieceListComponent_GTINNumber_gh', keyColumn: false })
  gtinNumber: number;

  @gridColumn({template: {
    div: {
      childrens: [{
        span: {
          attributes: {
            innerHTML: function (x, y) {
              return this.orderIdUrl;
            }
          },
        }
      }]
    }}
  ,parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'ProductPieceListComponent_OrderId_gh', keyColumn: false })
  orderIdUrl: string;
  
  orderId: number;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.customerName;
          }
        },
      }
    },
    parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex:10, allowSorting: true, headerKey: 'ProductPieceListComponent_PieceOwnerCustomer_gh', keyColumn: false
  })
  customerName: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.bookingDetails;
          }
        },
      }
    },
    parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: false, headerKey: 'ProductPieceListComponent_BookingDetails_gh', keyColumn: false
  })
  bookingDetails: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'ProductPieceListComponent_Supplier_gh', keyColumn: false })
  supplier: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 13, allowSorting: true, headerKey: 'ProductPieceListComponent_CollectionName_gh', keyColumn: false })
  collectionName: string;

  @gridColumn({ parameter: false, style: { width: "6%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 14, allowSorting: true, headerKey: 'ProductPieceListComponent_OldInventoryNumber_gh', keyColumn: false })
  oldInventoryNo: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 15, allowSorting: true, headerKey: 'ProductPieceListComponent_LocationCity_gh', keyColumn: false })
  location: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 16, allowSorting: true, headerKey: 'ProductPieceListComponent_DutyStatus_gh', keyColumn: false })
  dutyStatus: string;

  productStatus: string;

  //#region productionStatus Prop
  @gridColumn({ parameter: false,style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 17, allowSorting: true, headerKey: 'ProductPieceListComponent_ProductionStatus_gh', keyColumn: false })
  productionStatus: string;
  //#endregion productionStatus Prop

  //#region salesStatus Prop
  @gridColumn({ parameter: false,style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 18, allowSorting: true, headerKey: 'ProductPieceListComponent_SalesStatus_gh', keyColumn: false })
  salesStatus: string;
  //#endregion salesStatus Prop

  //#region consignmentStatus Prop
  @gridColumn({ parameter: false,style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 19, allowSorting: true, headerKey: 'ProductPieceListComponent_ConsignmentStatus_gh', keyColumn: false })
  consignmentStatus: string;
  //#endregion consignmentStatus Prop

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.otherStatus;
          }
        },
      }
    }, parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 20, allowSorting: true, headerKey: 'ProductPieceListComponent_OtherStatus_gh', keyColumn: false
  })
  otherStatus: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.mappedPiece;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 21, allowSorting: false, headerKey: 'ProductPieceListComponent_MappedPiece_gh', keyColumn: false
  })
  mappedPiece: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 22, allowSorting: true, headerKey: 'ProductPieceListComponent_CreatedOn_gh', keyColumn: false })
  createdOn: string;

  @gridColumn({ parameter: false, style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 23, allowSorting: true, headerKey: 'ProductPieceListComponent_ShippedDate_gh', keyColumn: false })
  shippedDate: string;

  @gridColumn({ parameter: false, style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 24, allowSorting: true, headerKey: 'ProductPieceListComponent_SupplierDate_gh', keyColumn: false })
  supplierDate: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 24, allowSorting: true, headerKey: 'ProductPieceListComponent_AvailableForSale_gh', keyColumn: false })
  forSaleDate: string;

  @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 25, allowSorting: true, headerKey: 'ProductPieceListComponent_ProfitMargin_gh', keyColumn: false })
  grossProfitMargin: string;

  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 25, allowSorting: false, headerKey: '', keyColumn: false })
  isUpcycled: boolean;

  @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 26, allowSorting: true, headerKey: 'ProductPieceListComponent_InsurrenceValue_gh', keyColumn: false })
  insuranceValue: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.upcycled;
          }
        },
      }
    },
    parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 27, allowSorting: false, headerKey: 'ProductPieceListComponent_Upcycled_gh', keyColumn: false
  })
  upcycled: any;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.bespoke;
          }
        },
      }
    },
    parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 28, allowSorting: false, headerKey: 'ProductPieceListComponent_Bespoke_gh', keyColumn: false
  })
  bespoke: any;

  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 29, allowSorting: false, headerKey: '', keyColumn: false })
  isBespoke: boolean;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.bespokeOrderFormNo;
          }
        },
      }
    },
    parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 30, allowSorting: true, headerKey: 'ProductPieceListComponent_BespokeOrderFormNo_gh', keyColumn: false
  })
  bespokeOrderFormNo: any;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.exportPrice;
          }
        },
      }
    },
    parameter: false, style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 31, allowSorting: true, headerKey: 'ProductPieceListComponent_Cost_gh', keyColumn: false
  })
  exportPrice: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 32, allowSorting: true, headerKey: 'ProductPieceListComponent_PurchaseOrderNo_gh', keyColumn: false })
  purchaseOrderNo: string;


  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 33, allowSorting: true, headerKey: 'ProductPieceListComponent_SupplierOrderNo_gh', keyColumn: false })
  supplierOrderNo: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 34, allowSorting: true, headerKey: 'ProductPieceListComponent_SalesChannel_gh', keyColumn: false })
  salesChannel: string;

  @gridColumn({
    template: {
      div: {
        childrens: [{
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onStockPrice"
            },
            childrens: [{
              text: {
                text: function (e) { return "With Price" }
              }
            }]
          }
        }]
      }
    },
    parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 35, allowSorting: false, headerKey: 'ProductPieceListComponent_PrintStockCard_gh', keyColumn: false
  })
  withPrice: string;

  @gridColumn({
    template: {
      div: {
        childrens: [{
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onStockWithoutPrice"
            },
            childrens: [{
              text: {
                text: function (e) { return "Without Price" }
              }
            }]
          }
        }]
      }
    },
    parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 36, allowSorting: false, headerKey: 'ProductPieceListComponent_PrintStockCard_gh', keyColumn: false
  })
  withoutPrice: string;

  @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 18, allowSorting: false, headerKey: 'ProductPieceListComponent_MappedPiece_gh', keyColumn: false })
  mappedPieceForExcel: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  serialNo: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  shortSerialNo: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  size: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  sizes: string;

  // @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  // orderId: number; 
  
  @gridColumn({visible: false, columnIndex: 12, keyColumn: false })
  noReorder: boolean;

  @gridColumn({  parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-center"], configuredTemplate: { templateName: "recordActiveStatus" }, visible: false, columnIndex: 37, allowSorting: false, headerKey: 'ProductPieceListComponent_IsConsigned_gh', keyColumn: false })
  isConsigned: boolean;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex:38, allowSorting: true, headerKey: 'ProductPieceListComponent_Category_gh', keyColumn: false })
  categoryName: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex:39, allowSorting: true, headerKey: 'ProductPieceListComponent_LegalOwner_gh', keyColumn: false })
  legalOwner: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex:40, allowSorting: true, headerKey: 'ProductPieceListComponent_DeliveryDate_gh', keyColumn: false })
  actualDeliveryDate: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex:41, allowSorting: true, headerKey: 'ProductPieceListComponent_Country_gh', keyColumn: false })
  country: string;


  hasCoreProduct: boolean;
  hasSuperSeller: boolean;
  totalCount: number;
  productVariantBatchPieceId: number;
  productVariantId: number;
  productId: number;
  design: string;
  product: string;
  description: string;
  adminComment: string;
  usdRate: number;
  gbpRate: number;
  eurRate: number;
  hkdRate: number;
  chfRate: number;
  pictureID: number;
  stockcardPictureID: number;
  exportRetailPrice: number;
  supplierDeliveryDate: Date;
  orderDetails: string;
  totalInsuranceValue: any;
  totalRRPValue: any;
  //@gridColumn({parameter:true,style: { width: "6%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 8, allowSorting: true, headerTitle: 'bookingData', keyColumn: false })
  bookingData: string;
  isRRP: boolean;
  //#region file Prop
  file: FileDetailViewModel;
  //#endregion file Prop
  imageUrlOrg: string; 
  bespokeOrderFormNoTextOnly: any;
}
