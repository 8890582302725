import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { actionColumn, gridColumn } from "@rxweb/grid"
@actionColumn({
  allowSorting: false,
  style: { width: "1%", "text-align": "left" },
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 2
})

export class vActivityLogTypeBase {

  //#region activityLogTypeId Prop
  @gridColumn({ name: "activityLogTypeId", style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ActivityLogTypeListComponent_ActivityLogTypeId_gh', keyColumn: true })
  activityLogTypeId: number;
  //#endregion activityLogTypeId Prop


  //#region activityName Prop
  @gridColumn({ name: "activityName", style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ActivityLogTypeListComponent_ActivityName_gh', keyColumn: false })
  activityName: string;
  //#endregion activityName Prop


  //#region systemKeyword Prop
  // @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'ActivityLogTypeListComponent_SystemKeyword_gh', keyColumn: false})
  //  systemKeyword : string;
  //#endregion systemKeyword Prop

  //#region isEnabled Prop
  @gridColumn({ name: "isEnabled", configuredTemplate: { templateName: 'bindCheckBox' }, class: ["text-centre"], visible: false, columnIndex: 2, allowSorting: false, headerKey: 'ActivityLogTypeListComponent_IsEnabled_gh', keyColumn: false, style: { width: "0.4%", "text-align": "initial" } })
  isEnabled: boolean;
  //#region isEnabled Prop

}
