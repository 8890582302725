import {vPOSCreditCardTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPOSCreditCardTypeLookUp extends vPOSCreditCardTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}