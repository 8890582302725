import { GoalsCompanyCustomerMappingBase } from '@app/database-models'
import { prop, required } from '@rxweb/reactive-form-validators';
import { TrackMeCompanyUserMappingBase } from '../database-models/trackme-company-user-mapping-base';

export class TrackMeCompanyUserMapping extends TrackMeCompanyUserMappingBase {

    //#region Generated Reference Properties
    @required({ message: "Please Select Person" })
    userIds: string;
    //#endregion Generated Reference Properties
}