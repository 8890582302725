import {BangleSizeBase,} from '@app/database-models'
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators'
export class BangleSize extends BangleSizeBase 
{


  @prop()
  files: File
//#region Generated Reference Properties

//#endregion Generated Reference Properties












}
