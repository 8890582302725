import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserKownToBase {

//#region knownToId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'knownToId', keyColumn: true})
        knownToId : number;
//#endregion knownToId Prop


//#region userId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : number;
//#endregion userId Prop


//#region knownToUserId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'knownToUserId', keyColumn: false})
        knownToUserId : number;
//#endregion knownToUserId Prop


//#region fullName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'fullName', keyColumn: false})
        fullName : string;
//#endregion fullName Prop


//#region jobTitle Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'jobTitle', keyColumn: false})
        jobTitle : string;
//#endregion jobTitle Prop


//#region company Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'company', keyColumn: false})
        company : string;
//#endregion company Prop


//#region relationShipName Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'relationShipName', keyColumn: false})
        relationShipName : string;
//#endregion relationShipName Prop


//#region relationShipId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'relationShipId', keyColumn: false})
        relationShipId : number;
//#endregion relationShipId Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region userRelationId Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'userRelationId', keyColumn: false})
        userRelationId : any;
//#endregion userRelationId Prop

}