import { vProductVariantStatusBase, } from '@app/database-models'
//Generated Imports
import { gridColumn, actionColumn } from "@rxweb/grid"
@actionColumn({
  visible: false,
  name: "action",
  allowSorting: false,
  style: { width: "2%" },
  configuredTemplate: { templateName: 'action' }, columnIndex: 5, headerTitle: "Action"
})



export class vProductVariantStatus extends vProductVariantStatusBase {


  @gridColumn({
    visible: false,
    name: "editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 5, headerTitle: "Edit"
  })
  editAction: any;

  @gridColumn({
    visible: false,
    name: "deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 6, headerTitle: "Delete"
  })
  deleteAction: any;

  //#region Generated Reference Properties

  //#endregion Generated Reference Properties










}
