import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class InvoiceBatchMappingBase {

//#region invoiceBatchID Prop
        @prop()
        invoiceBatchID : number;
//#endregion invoiceBatchID Prop


//#region invoiceID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        invoiceID : number;
//#endregion invoiceID Prop


//#region productVariantBatchID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchID : number;
//#endregion productVariantBatchID Prop





}