import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class WatchInvoiceBase {

        //#region watchInvoiceId Prop
        @prop({ defaultValue: 0 })
        watchInvoiceId: number;
        //#endregion watchInvoiceId Prop


        //#region watchPartId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        watchPartId: number;
        //#endregion watchPartId Prop


        //#region fileName Prop
        @required()
        @maxLength({ value: 255 })
        fileName: string;
        //#endregion fileName Prop


        //#region createdOn Prop
        @required()
        @prop({ defaultValue: new Date() })
        createdOn: Date;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @prop({ defaultValue: 0 })
        createdBy: number;
        //#endregion createdBy Prop


        //#region invoiceNumber Prop
        @maxLength({ value: 50 })
        invoiceNumber: string;
        //#endregion invoiceNumber Prop





}