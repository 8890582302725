<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/leave-types']" rxText="Label_Leave_Type_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="LeaveTypeEditComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>
  <div class="wrapper wrapper-content flex-grow-1">
    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="LeaveTypeEditComponent_Title"></label></h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="tabs-container people-tabs">
          <ul id="" class="nav nav-tabs flex-nowrap mb-0  slick-slider" role="tablist">
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'leave-type-info'"
                (click)="activeTab('leave-type-info')"><label rxText="LeaveTypeAddEditComponent_LeaveTypeInfo_t"></label></a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'group-of-companies'"
                (click)="activeTab('group-of-companies')"><label rxText="LeaveTypeAddEditComponent_GroupOfCompanies_t"></label></a>
            </li>
          </ul>

          <div class="slick_demo-btn"></div>
          <div class="tab-content">
            <div role="tabpanel" *ngIf="selectedTab == 'leave-type-info'" class="tab-pane"
              [class.active]="selectedTab == 'leave-type-info'" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
              <div class="panel-body" *ngIf="showComponent" [formGroup]="leaveTypeFormGroup"
                 [rxLocalisationInit]="componentName">
                <div class="row" *ngIf="showComponent" [rxLocalisationInit]="componentName">
                  <div class="col-xl-12">
                    <div class="ibox-title bg-white px-0 border-bottom mb-4">
                      <h5>Leave Type Info</h5>
                    </div>
                    <div class="row add-countries-form" (keyup.enter)="editLeaveType(false)">
                      <div class="col-md-4 pr-xl-4">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label"  rxText="LeaveTypeEditComponent_ID_t"></label>
                          <div class="col-md-8">
                            <label type="text" 
                              class="form-control disableLabel-withoutheight mb-0">{{(leaveTypeFormGroup.value.leaveId
                              === null || leaveTypeFormGroup.value.leaveId === undefined ) ? '' :
                              leaveTypeFormGroup.value.leaveId}}</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 pr-xl-5">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" 
                            rxText="LeaveTypeAddEditComponent_TypeName_t"></label>
                          <div class="col-md-9">
                            <input type="text" formControlName="leaveTypeName" class="form-control "
                               rxPlaceholder="LeaveTypeAddEditComponent_TypeName_p"
                              rxFocus />
                            <small class="form-text text-danger"
                              [class.d-block]="leaveTypeFormGroup.controls.leaveTypeName.errors">{{leaveTypeFormGroup.controls.leaveTypeName.errorMessage}}<br /></small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 pr-xl-5">
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label" 
                            rxText="LeaveTypeAddEditComponent_Color_t"></label>
                          <div class="col-md-9">
                            <div class="row">
                              <label class="col-md-7" style="padding-top: 7px;">Current Colour:
                                {{leaveTypeFormGroup.controls.typeColor.value}}</label>
                              <input type="color" formControlName="typeColor" class="form-control col-md-5"
                                style="height: 34px;"  />
                              <small class="form-text text-danger"
                                [class.d-block]="leaveTypeFormGroup.controls.typeColor.errors">{{leaveTypeFormGroup.controls.typeColor.errorMessage}}<br /></small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row mt-auto">
                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="col text-right">
                    <button (click)="editLeaveType(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                    <button (click)="editLeaveType(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                    <ng-container *ngIf="leaveTypeDelete" [rxLocalisationInit]="componentName">
                      <button (click)="deleteLeaveType()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="group-of-companies" *ngIf="selectedTab == 'group-of-companies'"
              [class.active]="selectedTab == 'group-of-companies'" class="tab-pane">
              <div class="panel-body sub-components">
                <app-leave-type-corporate-mapping-list>
                </app-leave-type-corporate-mapping-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>