import {vGemfieldsArrangementBase,} from '@app/database-models'
//Generated Imports
import { gridColumn, actionColumn } from "@rxweb/grid"
@actionColumn({
     allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 3, headerTitle: "Action"
})
export class vGemfieldsArrangement extends vGemfieldsArrangementBase 
{

    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 4, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 5, headerTitle: "Delete"
    })
    deleteAction:any;


//#region Generated Reference Properties

//#endregion Generated Reference Properties










}