import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_KNOWN_TO_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.knownToUserId != null || this.knownToUserId != undefined) {
                return "/users/" + this.knownToUserId + "?activeTab=user-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.knownToUserId != null || this.knownToUserId != undefined) {
                return "/users," + this.knownToUserId + "?activeTab=user-edit";
              }
            },
            onClick: "return false;"
          }
        }
      },
      {
        text:{
          text : ' is their:'
        }
      }
    ]
  },
};
