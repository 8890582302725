<!-- <div class="search-result-container" *rxMultilingual="">
  <div class="ibox mb-0 d-flex flex-column mt-4" [rxSpinner]="spin">
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <button class="btn btn-success btn-sm mb-0" (click)="addOwnership()">Add New</button>

      </div>
      <div class="input-group custom-alert mb-3 flex-nowrap">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </span>
        </div>
        <div class="input-group-desc px-3 py-4 d-flex flex-grow-1">
          <ul class="p-0 m-0 ml-3">
            <li class="mb-2">*denotes mandatory fields.</li>
            <li class="mb-2">This tab tracks the ownership / shareholding of Corporates / Groups. The start and end dates of ownership can be added if known, but are not mandatory.</li>
            <li>Be sure to select "Person" or "Company" depending on who the shareholder is that you wish to add to the table</li>
          </ul>
        </div>
      </div>
      <div class="table-responsive">
        <label class="ml-auto mr-0 mb-3">
          <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
            placeholder="">
        </label>
        <label *ngIf="isShowGrid" class="ml-auto mr-0 px-1 mb-3 admin pull-right" [attr.class]="totalOwnershipPerc != 100 ? 'label-danger': ''">
          Total Ownership(%): <b>{{totalOwnershipPerc}}</b>
        </label>

          <rx-grid [design]="ownerMappingGrid" *ngIf="isShowGrid"></rx-grid>

      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>
</div> -->
<div class="ibox mb-0 d-flex flex-column mt-4" *rxMultilingualExtended="_this">
  <div class="ibox-title bg-white" *ngIf="componentType == 'user-edit'" [rxLocalisationInit]="componentName">
    <h5 rxText="OwnershipMappingListComponent_OwnershipHeader_t"></h5>
  </div>
  <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
    <div class="row mx-0  py-3" *ngIf="componentType != 'user-edit'" [rxLocalisationInit]="componentName">
      <button class="btn btn-success btn-sm mb-0" (click)="addOwnership()" rxText="Btn_Add_New_t"></button>
    </div>
    <div class="input-group custom-alert mb-3 flex-nowrap" *ngIf="componentType != 'user-edit'">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </span>
      </div>
      <div class="input-group-desc px-3 py-4 d-flex flex-grow-1">
        <div>
          <span class="text" [innerHTML]="transformYourHtml(noticeData)"></span>
        </div>
        <!-- <ul class="p-0 m-0 ml-3">
      <li class="mb-2">*denotes mandatory fields.</li>
      <li class="mb-2">This tab tracks the ownership / shareholding of Corporates / Groups. The start and end dates of
        ownership can be added if known, but are not mandatory.</li>
      <li>Be sure to select "Person" or "Company" depending on who the shareholder is that you wish to add to the table
      </li>
    </ul> -->
      </div>
    </div>
    <div class="hr-line-dashed border-top w-100" *ngIf="componentType != 'user-edit'"></div>

    <div [rxSpinner]="spin">
      <!-- <div class="ibox-content bg-white d-flex flex-column flex-grow-1"> -->

      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <div *ngIf="componentType == 'user-edit'" [rxLocalisationInit]="componentName">
          <button class="btn btn-success btn-sm mb-3" (click)="addOwnership()" rxText="Btn_Add_New_t"></button>
        </div>
        <label class="ml-auto mr-0 mb-3">
          <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
            placeholder="" [title]="titleSearch">
        </label>
        <div class="ibox-tools mb-4 pb-2 ml-3" style="position: relative;"
          *ngIf="(!isNoRecordFound) && (componentType != 'user-edit')">
          <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
            <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
            (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
            [gridColumnConfig]="ownerMappingGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
            [moduleID]="moduleID" [selectedTab]="selectedTab">
          </app-system-table-setting>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="ibox-content bg-white d-flex flex-column flex-grow-1"> -->
      <div class="table-responsive" *ngIf="isShowGrid">
        <label *ngIf="componentType != 'user-edit'" class="ml-auto mr-0 px-1 mb-3 admin pull-right"
          [attr.class]="totalOwnershipPerc != 100 ? 'label-danger p-2': ''" [rxLocalisationInit]="componentName">
          <span rxText="OwnershipMappingListComponent_TotlOwnrshipPrctge_t"></span> <b>{{totalOwnershipPerc}}</b>
        </label>
        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
          <rx-grid [design]="ownerMappingGrid"></rx-grid>
        </div>
      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
