import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductDownloadPictureBase {

//#region productVariantDownlaodPictureID Prop
        @prop()
        productVariantDownlaodPictureID : number;
//#endregion productVariantDownlaodPictureID Prop


//#region productVariantID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID : number;
//#endregion productVariantID Prop


//#region pictureID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID : number;
//#endregion pictureID Prop


//#region imageName Prop
        @required()
        @maxLength({value:5000})
        imageName : string;
//#endregion imageName Prop





}