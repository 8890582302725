import { ApplicationModuleBase, RoleBase, RolePermissionBase } from '../database-models';
import { RolePermissionViewModel } from '../../view-model/role-permission-view-model';
import { propArray, prop } from '@rxweb/reactive-form-validators';

//Generated Imports
export class RolePermission extends RolePermissionBase 
{




//#region Generated Reference Properties
//#region applicationModule Prop
applicationModule : ApplicationModuleBase;
//#endregion applicationModule Prop
//#region role Prop
role : RoleBase;
//#endregion role Prop

@propArray(RolePermissionViewModel)
rolePermissionsArray:RolePermissionViewModel[];

//#endregion Generated Reference Properties

@prop()
isForDepartment:boolean;












}