import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ClientRepairPieceMappingBase {

//#region clientRepairPieceMappingID Prop
        @prop()
        clientRepairPieceMappingID : number;
//#endregion clientRepairPieceMappingID Prop


//#region customerID Prop
        @prop()
        customerID : number;
//#endregion customerID Prop


//#region pieceID Prop
        @prop()
        pieceID : number;
//#endregion pieceID Prop

}