<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" *rxMultilingual=""  [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_Faberge_Pieces_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item active">Inventory</li>
      <li class="breadcrumb-item active" rxText="Label_Faberge_Pieces_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content " [rxSpinner]="spin" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5 rxText="Label_Pieces_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column" *ngIf="showComponent"
      [formGroup]="productPieceSearchFormGroup" (keyup.enter)="onSearchPress()" [rxLocalisationInit]="componentName">

      <div class="d-flex flex-wrap align-items-center">
        <div class=" ml-auto  text-right mb-4" role="group" aria-label="Basic example">
          <button class="btn btn-success" (click)="importExcel()" rxText="ProductPieceListComponent_ImportExcelBtn_t"></button>
          <ng-container *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
            <button type="button" class="btn btn-primary ml-2" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t"></button>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xl-4 pr-xl-5">

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_ProductTitle_t"> </label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="1" formControlName="title"
                rxPlaceholder="ProductPieceListComponent_ProductTitle_p"  />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.title.errors">{{productPieceSearchFormGroup.controls.title.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_DesignCategory_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="categoryLookup" [controlTabIndex]="4" mainClass="form-control"
                formControlName="categoryId" [selectPlaceholder]="'All'"
                [keyValueProps]="['categoryName','categoryId']"></rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceNo_t"> </label>
            <div class="col-md-8">
              <input type="text" tabindex="7" class="form-control" formControlName="pieceNo"
                 rxPlaceholder="ProductPieceListComponent_PieceNo_p" />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.pieceNo.errors">{{productPieceSearchFormGroup.controls.pieceNo.errorMessage}}</small>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="ProductPieceListComponent_ConsignmentStatus_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                    <rx-select #rxSelect [(source)]="lookups.consignmentStatusLookup"
                        [selectPlaceholder]="'Select Consignment Status'" mainClass="form-control"
                        formControlName="consignmentStatusId" [keyValueProps]="['productStatusName','productStatusId']"></rx-select>
                </div>
          </div>
          
         <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="ProductPieceListComponent_ProductionStatus_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                    <rx-select #rxSelect [(source)]="lookups.productionTeamStatusLookup" [selectPlaceholder]="'Select Production Status'"
                    mainClass="form-control" formControlName="productionStatusId"
                    [keyValueProps]="['productStatusName','productStatusId']"></rx-select>
                </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProductPieceListComponent_SalesStatus_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                  <rx-select #rxSelect [(source)]="lookups.salesStatusFilterLookup" [selectPlaceholder]="'Select Sales Status'"
                      mainClass="form-control" formControlName="salesStatusId"
                      [keyValueProps]="['productStatusName','productStatusId']"></rx-select>
              </div>
      </div>

      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
          rxText="ProductPieceListComponent_SpecialStatus_t"> </label>
        <div class="col-md-8" [rxTagExtended]="_this">

          <rx-tag #rxTag [(source)]="lookups.specialStatusLookup" formControlName="specialStatusIds" [controlTabIndex]="10"
            placeholder="Select Special Status" [isSort]="false" mainClass="form-control"
            [keyValueProps]="['productStatusName','productStatusId']">
          </rx-tag>
        </div>
      </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_Location_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <rx-tag #rxTag [source]="warehouseLookUp" [controlTabIndex]="13" formControlName="location"
                mainClass="form-control" placeholder="Select Location" [keyValueProps]="['warehouseName','warehouseId']"
                [isSort]="false"></rx-tag>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_SalesChannel_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [source]="salesChannelLookUps" [controlTabIndex]="13" formControlName="salesChannel"
                mainClass="form-control" [selectPlaceholder]="'Select Sales Channel'" [keyValueProps]="['salesChannelName','salesChannelId']"
                [isSort]="false"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_LegalOwner_t"> </label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <rx-tag #rxTag [source]="pieceOwnerLookUp" [controlTabIndex]="17" formControlName="owner"
                mainClass="form-control" placeholder="Select Owner" [keyValueProps]="['ownerName','ownerId']"
                [isSort]="false"></rx-tag>
            </div>
          </div> 
          
           <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_DesignCollection_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <rx-tag #rxTag [source]="collectionLookup" [controlTabIndex]="20" [isSort]="false" formControlName="collection"
                mainClass="form-control" placeholder="Select Collection"
                [keyValueProps]="['name','specificationAttributeOptionId']"></rx-tag>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceRingSize_t"> </label>
            <div class="col-md-8" [rxTagExtended]="_this">
                <rx-tag #rxTag [source]="ringSizeLookup" [controlTabIndex]="23" [isSort]="false" formControlName="ringSizeId"
                mainClass="form-control" placeholder="Select Ring Size"
                [keyValueProps]="['ringSize','ringSizeId']"></rx-tag>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_ProdBatchEggSize_t"> </label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="productBatchEggSizeLookUp" [controlTabIndex]="26" mainClass="form-control"
                formControlName="eggSizeId" [selectPlaceholder]="'Select Egg Size'"
                [keyValueProps]="['ringSize','ringSizeId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_AvailableForSale_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="29" formControlName="forSaleDate" pickerClass="form-control"
                placeholder="Select Date"></rx-date>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-4 col-xl-4 col-form-label" 
            rxText="ProductPieceListComponent_wholeSale_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select #rxSelect mainClass="form-control" [controlTabIndex]="32"
                    [(source)]="wholeSalePartnerLookUp" formControlName="wholeSalePartnerId"
                    [selectPlaceholder]="'Select WholeSale Partner'" [keyValueProps]="['name','id']">
                </rx-select>
            </div>
          </div>

        </div>
        <div class="col-lg-6 col-xl-4 pr-xl-5">

          <div class="form-group row">
            <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="ProductPieceListComponent_piecesId_t"></label>
            <div class="col-md-8 ">
              <div class="d-flex">
                <input type="text" class="form-control mr-3" tabindex="1" formControlName="piecesId"
                       rxPlaceholder="ProductPieceListComponent_piecesId_p" (keyup.enter)="goTo()"/>
                <button (click)="goTo()" class="btn btn-success search-result-btn" [rxLocalisationInit]="componentName"><span rxText="Btn_Go_t"></span></button>
              </div>
              <small class="form-text text-danger" *ngIf="msgError==true" [rxLocalisationInit]="componentName"><span rxText="ProductPieceListComponent_Go_Error_t"></span><br /></small>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_CaratWeight_t"> </label>
            <div class="col-md-8">
              <input type="text" tabindex="7" class="form-control" formControlName="caratWeight"
                 rxPlaceholder="ProductPieceListComponent_CaratWeight_p" />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.caratWeight.errors">{{productPieceSearchFormGroup.controls.caratWeight.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_BangleSizeName_t"> </label>
            <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-tag #rxTag [source]="bangleSizeLookup" [controlTabIndex]="2" [isSort]="false" formControlName="bangleSizeId"
                mainClass="form-control" placeholder="Select Bangle Size"
                [keyValueProps]="['bangleSizeName','bangleSizeId']"></rx-tag>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_ProdBatchUpcycled_t"> </label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'5'" [checkFormControl]="productPieceSearchFormGroup.controls.upcycled">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_ProductBatchBespoke_t"> </label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'8'" [checkFormControl]="productPieceSearchFormGroup.controls.bespoke">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_OldInventoryNumber_t"> </label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="11" formControlName="inventoryNo"
                rxPlaceholder="ProductPieceListComponent_OldInventoryNumber_p" />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.inventoryNo.errors">{{productPieceSearchFormGroup.controls.inventoryNo.errorMessage}}</small>

            </div>
          </div>

          <div class="form-group row mb-1">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PiecePriceRange_t"> </label>
            <div class="col-md-3 mt-2">
              <div class="input">

                <input type="text" class="form-control" tabindex="14" formControlName="priceFrom" />
                <small class="form-text text-danger"
                  [class.d-block]="productPieceSearchFormGroup.controls.priceFrom.errors">{{productPieceSearchFormGroup.controls.priceFrom.errorMessage}}</small>
                <!-- <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Go</span>
                        </div> -->
              </div>
            </div>
            <label class="col-md-1 col-lg-1 col-xl-1 col-form-label mt-2" >To</label>
            <div class="col-md-3 mt-2">
              <div class="input">

                <input type="text" class="form-control" tabindex="15" formControlName="priceTo" />
                <small class="form-text text-danger"
                  [class.d-block]="productPieceSearchFormGroup.controls.priceTo.errors">{{productPieceSearchFormGroup.controls.priceTo.errorMessage}}</small>
                <!-- <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">Go</span>
                      </div> -->
              </div>
            </div>

          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_DutyStatus_t"> </label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="dutyStatusLookUp" [controlTabIndex]="18" mainClass="form-control"
                formControlName="dutyStatusId" [selectPlaceholder]="'Select Duty Status'"
                [keyValueProps]="['dutyStatusName','dutyStatusId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_Supplier_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="supplierLookUps" [controlTabIndex]="21" mainClass="form-control"
                formControlName="supplierId" [selectPlaceholder]="'Select Supplier'"
                [keyValueProps]="['company','supplierId']"></rx-select>
            </div>
          </div>

          <div class="form-group row mb-1" *ngIf="showSupplierDeliveryDate" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceDeliveryDateFrom_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="24" formControlName="deliveryDateFrom"
                pickerClass="form-control" placeholder="Select Date"></rx-date>

            </div>
          </div>

          <div class="form-group row" *ngIf="showSupplierDeliveryDate" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceDeliveryDateTo_t"> </label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="27" formControlName="deliveryDateTo"
                pickerClass="form-control" placeholder="Select Date"></rx-date>
              <small class="form-text text-danger" *ngIf="deliveryDateError==true">Supplier Delivery date To should be
                greater than Supplier Delivery From Date<br /></small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_Published_t"></label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'30'" [checkFormControl]="productPieceSearchFormGroup.controls.publishedOnWeb">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_Consigned_t"></label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'8'" [checkFormControl]="productPieceSearchFormGroup.controls.isConsigned">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_SupplierOrderNo_t"> </label>
            <div class="col-md-8">
              <input type="text" tabindex="7" class="form-control" formControlName="supplierOrderNo"
                 rxPlaceholder="ProductPieceListComponent_SupplierOrderNo_p" />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.supplierOrderNo.errors">{{productPieceSearchFormGroup.controls.supplierOrderNo.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_BatchNo_t"></label>
            <div class="col-md-8">
              <input type="text" tabindex="7" class="form-control" formControlName="batchNo"
                 rxPlaceholder="ProductPieceListComponent_BatchNo_p" />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.batchNo.errors">{{productPieceSearchFormGroup.controls.batchNo.errorMessage}}</small>
            </div>
          </div>



        </div>
        <div class="col-lg-6 col-xl-4 ">


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceExpiryDateFrom_t"> </label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="3" formControlName="expiryDateFrom"
                pickerClass="form-control" placeholder="Select Date"></rx-date>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceExpiryDateTo_t"> </label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="6" formControlName="expiryDateTo" pickerClass="form-control"
                placeholder="Select Date"></rx-date>
              <small class="form-text text-danger" *ngIf="expiryDateError==true">Carnet Expiry To Date should be greater
                than Carnet Expiry From Date<br /></small>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceTripFrom_t"> </label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="9" formControlName="tripFrom" pickerClass="form-control"
                placeholder="Select Date"></rx-date>

            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceTripTo_t"> </label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="12" formControlName="tripTo" pickerClass="form-control"
                placeholder="Select Date"></rx-date>
              <small class="form-text text-danger" *ngIf="tripDateError==true">Booking To Date should be greater than
                Booking From Date<br /></small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="ProductPieceListComponent_PieceCreateDF_t"
              ></label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="16" formControlName="pieceCreatedDateFrom"
                [controlTabIndex]="0" pickerClass="form-control" placeholder="Select Date"></rx-date>
              <!-- <small class="form-text text-danger" *ngIf="tripDateError==true">Piece From<br /></small> -->
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="ProductPieceListComponent_PieceCreateDT_t"
              ></label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="19" formControlName="pieceCreatedDateTo"
                [controlTabIndex]="0" pickerClass="form-control" placeholder="Select Date"></rx-date>
              <small class="form-text text-danger" *ngIf="pieceCreatedError==true">Piece Created To Date should be
                greater than Piece Created From Date<br /></small>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_GemstoneType_t"> </label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="productGemstonetypeLookUps" [controlTabIndex]="22" mainClass="form-control"
                formControlName="gemstoneTypeId" [selectPlaceholder]="'Select Gemstone Type'"
                [keyValueProps]="['categoryName','categoryId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_Color_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="productColorLookUps" [controlTabIndex]="25" mainClass="form-control"
                formControlName="colorId" [selectPlaceholder]="'Select Colour'"
                [keyValueProps]="['colorName','colorId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceMaterialName_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [(source)]="productMaterialLookUps" [controlTabIndex]="28" mainClass="form-control"
                formControlName="materialId" [selectPlaceholder]="'Select Material'"
                [keyValueProps]="['materialName','materialId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceCoreProduct_t"></label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'31'" [checkFormControl]="productPieceSearchFormGroup.controls.coreProduct">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceNoReorder_t"></label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'33'" [checkFormControl]="productPieceSearchFormGroup.controls.noReorder">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_PieceSuperSeller_t"></label>
            <div class="col-md-8">
              <div class="i-checks">
                <label class="mt-1">
                  <app-checkbox [tabIndex]="'34'" [checkFormControl]="productPieceSearchFormGroup.controls.superSeller">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>
   
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
            rxText="ProductPieceListComponent_PurchaseOrderNo_t"> </label>
            <div class="col-md-8">
              <input type="text" tabindex="7" class="form-control" formControlName="purchaseOrderNo"
              rxPlaceholder="ProductPieceListComponent_PurchaseOrderNo_p" />
              <small class="form-text text-danger"
              [class.d-block]="productPieceSearchFormGroup.controls.purchaseOrderNo.errors">{{productPieceSearchFormGroup.controls.purchaseOrderNo.errorMessage}}</small>
            </div>
          </div>
          
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="ProductPieceListComponent_GTINNumber_t"></label>
            <div class="col-md-8">
              <input type="text" tabindex="7" class="form-control" formControlName="gtinNumber"
                 rxPlaceholder="ProductPieceListComponent_GTINNumber_p" />
              <small class="form-text text-danger"
                [class.d-block]="productPieceSearchFormGroup.controls.gtinNumber.errors">{{productPieceSearchFormGroup.controls.gtinNumber.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProductPieceListComponent_Country_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [selectPlaceholder]="'Select Country'" [(source)]="lookups.countryLookUp" #rxSelect
                mainClass="form-control" formControlName="countryId" [keyValueProps]="['countryName','countryId']">
              </rx-select>
            </div>
          </div>

        </div>

      </div>

      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100 mt-1"></div>
        <div class="col text-right">
          <button class="btn btn-success mr-1 search-result-btn" (click)="onSearchPress()" rxText="Btn_Search_t"></button>
          <button class="btn btn-primary mr-1 search-result-btn" (click)="resetSearch()" rxText="Btn_Reset_t"></button>
        </div>
      </div>
    </div>

  </div>

  <div class="ibox mb-0 d-flex flex-column mt-4" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
    <div class="ibox-content bg-white d-flex flex-column flex-md-row flex-grow-1">


      <ul class="d-flex justify-content-between mb-0 inventory-keys">
        <li class="d-flex align-items-center pr-3 mr-3" *ngIf="!isSalesTeam"><span class="mr-2"><b rxText="ProductPieceListComponent_Total_Insurance_t"></b></span><span>{{insuranceValue}}</span></li>
        <li class="d-flex align-items-center pr-3 mr-3" *ngIf="!isSalesTeam"><span class="mr-2"><b rxText="ProductPieceListComponent_Total_RRP_t"></b></span><span>{{rrpValue}}</span></li>
        <li class="d-flex align-items-center pr-3 mr-3"><span class="mr-2"><b rxText="ProductPieceListComponent_Total_Pieces_t">
            </b></span><span>{{totalRecords}}</span></li>
        <li class="d-flex align-items-center pr-3"><span><b rxText="ProductPieceListComponent_Keys_t"></b></span></li>
        <li class="d-flex align-items-center pr-3"><i class="fr-no-re-order text-danger mr-3"></i><span rxText="ProductPieceListComponent_No_ReOrder_t"></span></li>
        <li class="d-flex align-items-center pr-3"><i
            class="fr-core-products text-info mr-3"></i><span rxText="ProductPieceListComponent_Core_Product_t"></span></li>
        <li class="d-flex align-items-center pr-3"><i class="fr-prices mr-3"></i><span><span rxText="ProductPieceListComponent_Individual_Piece_1_t"></span><br><span rxText="ProductPieceListComponent_Individual_Piece_2_t"></span></span></li>
        <li class="d-flex align-items-center pr-3"><i class="fr-star text-warning mr-3"></i><span rxText="ProductPieceListComponent_Super_Seller_t"></span>
        </li>
      </ul>

    </div>

  </div>

  <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showGrid" [rxLocalisationInit]="componentName">
    <div class="ibox-title bg-white">
      <h5 rxText="Label_Search_Result_t"></h5>

      <!-- <div class="ibox mb-0 d-flex flex-column mt-5" *ngIf="isShowGrid"> -->


      <div class="ibox-tools">
        <label class="ml-auto mr-2 mb-3">
          <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
            placeholder="" [title]="titleSearch">
        </label>

        <a class="dropdown-toggle" (click)="showGridColumn()" *ngIf="!isNoRecordFound" [title]="titleGridColumnSetting">
          <i class="fa fa-cog"></i>
        </a>
        <app-system-table-setting [componentId]="'ProductPieceListComponent'" *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="piecesGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID">
        </app-system-table-setting>

      </div>
    </div>
    <!-- </div> -->
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" id="piecesTable" *ngIf="isShowGrid">
      <div class="table-responsive">
        <div class="dataTables_wrapper pb-0 dt-bootstrap4" id="piecesGrid">
          <rx-grid [design]="piecesGrid"></rx-grid>
        </div>
      </div>
    </div>

  </div>
  <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>
  <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls" data-hide-page-scrollbars="false">
    <!-- <div class="slides"></div>
  <h3 class="title"></h3>
  <a class="prev">‹</a>
  <a class="next">›</a>
  <a class="close">×</a>
  <a class="play-pause"></a>
  <ol class="indicator"></ol> -->
  </div>

</div>
