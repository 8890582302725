import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vActivityLogTypeRecordBase {

//#region activityLogTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'activityLogTypeId', keyColumn: true})
        activityLogTypeId : number;
//#endregion activityLogTypeId Prop


//#region systemKeyword Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'systemKeyword', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}