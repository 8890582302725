import { Component, OnInit, OnDestroy, Input, EventEmitter } from "@angular/core"

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { ProductPiece } from '@app/models';
import { AbstractProductPiece } from '../domain/abstract-product-piece';
import { ProductPieceViewModel } from 'src/app/view-model/product-piece-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { GridColumnConfig } from '@rxweb/grid';
import { List } from '@rxweb/generics';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { StockCardComponent } from '../stock-card/stock-card.component';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import {
  Workbook
} from 'exceljs';
import {
  DatePipe
} from '@angular/common';
import * as fs from 'file-saver';
import * as moment from 'moment'
import { isSalesRole, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { rolesEnum } from "src/app/enums/roles-enum";
import { NotesEnum } from 'src/app/enums/notes.enum';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { DEFAULT_BLOB_IMAGE_URL } from "src/app/domain/system-constant";
import { ImgModalComponent } from "src/app/components/img-modal/img-modal.component";
@multilingual("ProductPieceDetailComponent")

@Component({
  selector: "app-product-piece-detail",
  templateUrl: './product-piece-detail.component.html'
})
export class ProductPieceDetailComponent extends AbstractProductPiece implements OnInit, OnDestroy {

  @Input() productId: number;
  @Input() productName: string;
  @Input() isAllRRPProduct: boolean;
  subscription: any;
  productPieceGrid: AppGrid;
  productPieceList: ProductPieceViewModel[];
  productPieceExcelList: ProductPieceViewModel[];
  isFilter: boolean;
  totalRecords: number;
  avgDayInStock:number;
  avgOngoingDayInStock:number;
  exportExcel = new ExportToExcelModel();
  json: any;
  list: any[];
  isShowGridColumn: boolean = false;
  showStockCard: boolean;
  isSalesTeam: boolean;
  browserStorage: BrowserStorage;
  loggedInUserRole: string;
  @Input() selectedTab: string = "";
  @Input() moduleID: any;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  titleGridColumnSetting: string;
  titleSearch: string;
  constructor(private formBuilder: RxFormBuilder, modelView: ModalView, domSanitizer: DomSanitizer, route: Router, blobState: BlobUploadsViewStateService) {
    super(domSanitizer,blobState);
    this.modalView = modelView;
    this.router = route;
    this.browserStorage = new BrowserStorage();
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
  }

  ngOnInit(): void {
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.isSalesTeam = isSalesRole();
    this.isFilter = false;
    this.json = JSON.parse(JSON.stringify({
      productId: this.productId,
      //clientUrl:CLIENT_URL, 
      pageIndex: 1, rowCount: 25, searchString: ''
    }));
    this.json["productId"] = this.productId;
    this.json["pageIndex"] = PaginationEnum.PageIndex;
    this.json["rowCount"] = PaginationEnum.RowCount;

    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      this.get({ path: "api/UserLookups/CheckShowSupplierDeliveryDate"}).subscribe((res:any)=>{
        this.showSupplierDeliveryDate = res;
        setTimeout(() => {
          this.bindGrid();
        }, 0);
      })
    })

    // this.bindGrid();
  }
  //
  bindGrid() {
    this.spin = true;
    this.isShowGrid = true;
    this.totalRecords = 0;
    this.avgDayInStock = 0;
    this.avgOngoingDayInStock = 0;


    this.post({ path: 'api/SearchProducts/GetProductPieces', body: { query: JSON.stringify(this.json) } }).subscribe((t: ProductPieceViewModel[]) => {    
      this.productPieceList = t;
      this.spin = false;
      this.totalRecords = this.productPieceList.length > 0 ? this.productPieceList[0].totalCount : 0;
      this.avgDayInStock = this.productPieceList.length > 0 ? this.productPieceList[0].avgDayInStock : 0;
      this.avgOngoingDayInStock = this.productPieceList.length > 0 ? this.productPieceList[0].avgOngoingDayInStock : 0;
      this.isShowGrid = this.totalRecords > 0 ? true : false;
      this.isNoRecordFound = this.totalRecords > 0 ? false : true;
      if (this.isShowGrid == true) {
        if (!this.isFilter) {
          let categoryId = this.productPieceList.filter(x => x.pieceId > 0)[0].categoryId;
          this.productPieceGrid = new AppGrid(this.productPieceList, ProductPieceViewModel, { actions: { onStockPrice: this.onStockPrice.bind(this), onStockWithoutPrice: this.onStockWithoutPrice.bind(this) , onBarcodeImgError: this.onBarcodeImgError.bind(this), onBarcodeImgLoad: this.onBarcodeImgLoad.bind(this)} });
          if (this.isSalesTeam) {
            this.productPieceGrid.gridColumns.splice(this.productPieceGrid.gridColumns.findIndex((x) => x.name == 'bomCost'), 1)
          }
          if (!this.showSupplierDeliveryDate) {
            this.productPieceGrid.gridColumns.splice(this.productPieceGrid.gridColumns.findIndex((x) => x.name == 'supplierDate'), 1)
          }
          this.productPieceGrid.gridColumns.forEach(x => {
            if (x.name == 'ringSize') {
              x.visible = categoryId == 99 ? true : false;
            }
            if (x.name == 'bangleSize') {
              x.visible = categoryId == 90 ? true : false;
            }
            if (x.name == 'bomCost') {
              x.visible = !this.isSalesTeam;
            }
          })
          this.productPieceGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }

        }
        else {
          this.productPieceGrid.updateSource([]);
          this.productPieceGrid.storeProcedure.length = this.totalRecords;
          this.productPieceGrid.updateSource(this.productPieceList);
        }

        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(x => {
            this.productPieceGrid.gridColumns.forEach(y => {
              if (y.name == x.ColumnName)
                y.visible = x.Visible;
            });
          });
          this.gridColumnConfig = this.productPieceGrid.gridColumns;
        }
        this.productPieceGrid.componentId = this.componentName;
        this.productPieceGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.productPieceGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.productPieceGrid.maxPerPage = this.json["rowCount"];

      }
    });
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.productPieceGrid.reDesign();
    this.productPieceGrid.updateSource([]);
    this.productPieceGrid.updateSource(this.productPieceList);
    this.arrConfig = gridColumnConfig;
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = y;
    this.json["orderBy"] = x;
    this.productPieceGrid.storeProcedure.nextPage = z;
    this.productPieceGrid.updateSource([]);
    this.bindGrid();
  }

  onBarcodeImgError(t) {
    t.ImageUrl = DEFAULT_BLOB_IMAGE_URL;
  }

  onBarcodeImgLoad(pieceData: ProductPieceViewModel) {
    if (pieceData.barcodeUrl) {
      this.modalView.show(ImgModalComponent, { imgSrc: pieceData.barcodeUrl, isBarcode: true }).then(x => { });
    }
  }

  search(event: any) {
    this.json["searchString"] = event.target.value.replace(/^\s+|\s+$/gm, '');;
    this.isFilter = true;
    this.json["pageIndex"] = 1;
    if (this.productPieceGrid != null) {
      this.productPieceGrid.storeProcedure.nextPage = 1;
      this.productPieceGrid.updateSource([]);
    }
    this.isShowGrid = false;
    this.bindGrid();
  }
  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.productPieceGrid.maxPerPage;
    this.productPieceGrid.storeProcedure.nextPage = x;
    this.productPieceGrid.updateSource([]);
    this.bindGrid();
  }
  onStockPrice(pieceData: ProductPieceViewModel) {
    let jsonData: any;
    let gbpPrice: number = Math.round(pieceData.exportRetailPrice * pieceData.gbpRate);
    let usdPrice: number = Math.round(pieceData.exportRetailPrice * pieceData.usdRate);
    let chfPrice: number = Math.round(pieceData.exportRetailPrice * pieceData.chfRate);
    let hkdPrice: number = Math.round(pieceData.exportRetailPrice * pieceData.hkdRate);
    let eurPrice: number = Math.round(pieceData.exportRetailPrice * pieceData.eurRate);
    this.jsonParam = JSON.parse(JSON.stringify({ pieceId: pieceData.pieceId, productId: this.productId }));
    this.post({ path: 'api/SearchPieces/GetStockCardImage', body: { query: JSON.stringify(this.jsonParam) } }).subscribe(t => {
      jsonData = JSON.parse(JSON.stringify({
        productName: pieceData.productName,
        withPrice: true,
        gbpPrice: gbpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        usdPrice: usdPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        chfPrice: chfPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        hkdPrice: hkdPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        eurPrice: eurPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        description: pieceData.description,
        adminComment: pieceData.adminComment,
        imageUrl: t,
        serialNo: pieceData.pieceNumber
      }))

      let navigationExtras: NavigationExtras = {
        queryParams: {
          "cardJson": JSON.stringify(jsonData)
        }
      };

      let url = this.router.serializeUrl(this.router.createUrlTree(["/stock-card"], navigationExtras));
      window.open(url, '_blank', 'location=yes,height=800,width=960,scrollbars=yes,status=yes');
    });

  }

  onStockWithoutPrice(pieceData: ProductPieceViewModel) {
    let jsonData: any;
    this.jsonParam = JSON.parse(JSON.stringify({ pieceId: pieceData.pieceId, productId: this.productId }));
    this.post({ path: 'api/SearchPieces/GetStockCardImage', body: { query: JSON.stringify(this.jsonParam) } }).subscribe(t => {
      jsonData = JSON.parse(JSON.stringify({
        productName: pieceData.productName,
        withPrice: false,
        gbpPrice: 0,
        usdPrice: 0,
        chfPrice: 0,
        hkdPrice: 0,
        eurPrice: 0,
        description: pieceData.description,
        adminComment: pieceData.adminComment,
        imageUrl: t,
        serialNo: pieceData.pieceNumber
      }))
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "cardJson": JSON.stringify(jsonData)
        }
      };

      let url = this.router.serializeUrl(this.router.createUrlTree(["/stock-card"], navigationExtras));
      window.open(url, '_blank', 'location=yes,height=800,width=960,scrollbars=yes,status=yes');
    });

  }


  exportToExcel() {
    this.spin = true;
    this.list = this.productPieceList;
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    this.exportExcel.FileName = "Pieces" + MMDDYY;
    this.exportExcel.Type = "Excel";
    this.json["rowCount"] = this.productPieceList[0].totalCount;
    // this.json["searchString"] = "";
    this.json["pageIndex"] = 1;
    //this.exportExcel.Result = JSON.stringify(this.list);
    this.post({ path: 'api/SearchProducts/GetProductPieces', body: { query: JSON.stringify(this.json) } }).subscribe((t: ProductPieceViewModel[]) => {
      this.productPieceExcelList = t;
      this.generateProductsExcel(this.productPieceExcelList, this.productPieceGrid.gridColumns);
    });


  }

  generateProductsExcel(productPieceGrid: any, gridColumns: any[]) {
    this.spin = true;
    let returnedRequestData = this.requestExcelData(productPieceGrid, gridColumns); 
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    var FileName = "ProductsPieceRecords" + MMDDYY;
    this.exportExcel.Type = "Excel";
    this.exportExcel.Description = "ProductsPieceRecords"
    this.exportExcel.Result = JSON.stringify(returnedRequestData);
    return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
      this.downloadDocument(Response, this.exportExcel.FileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      this.spin = false;
    }, error => {
      this.spin = false;
    }) 
  }

  Uint8ToString(u8a) {
    var CHUNK_SZ = 0x8000;
    var c = [];
    for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
      c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
    }
    return c.join("");
  }
  requestExcelData(productPieceGrid: any, gridColumns: any[]) {
    
    var listObject = productPieceGrid.map(function (obj) {
      var otherStatusArray = obj.otherStatus.split("</br>")
            otherStatusArray.forEach((element, index) => {
                if (index == otherStatusArray.length - 1 && element == "") {
                    otherStatusArray.splice(index,1)
                }
            });
            var otherStatusForExcel = otherStatusArray.join(',\n')
            obj.otherStatus = otherStatusForExcel;
      if (gridColumns != undefined && gridColumns != null) {
        var objNew: any = {}
        gridColumns.forEach(e => {
          if (e.visible) {
            objNew[this.getGridColumnName(e.name)] = obj[e.name];
          }
        }, this);
        return objNew;
      } else {
        return obj;
      }
    }, this)
    var list = new List<any>(listObject);
    return list;
  }
  getGridColumnName(key: string) {
    var localizationData = (MultiLingualData.get(this.componentName) as any);
    switch (key) {
      case "batchNo":
        return localizationData.ProductPieceDetailComponent_BatchNo_ex_ex;
      case "serial":
        return localizationData.ProductPieceDetailComponent_Serial_ex_ex;
      case "orderID":
        return localizationData.ProductPieceDetailComponent_OrderId_ex_ex;
      case "oldInventoryNumber":
        return localizationData.ProductPieceDetailComponent_OldInventoryNumber_ex_ex;
      case "carnetNo":
        return localizationData.ProductPieceDetailComponent_CarnetNo_ex_ex;
      case "productionStatus":
        return localizationData.ProductPieceDetailComponent_ProductionStatus_ex_ex;
      case "consignmentStatus":
        return localizationData.ProductPieceDetailComponent_ConsignmentStatus_ex_ex;
      case "salesStatus":
        return localizationData.ProductPieceDetailComponent_SalesStatus_ex_ex;
      case "otherStatus":
        return localizationData.ProductPieceDetailComponent_OtherStatus_ex_ex;
      case "bomCost":
        return localizationData.ProductPieceDetailComponent_BomCost_ex_ex;
      case "ringSize":
        return localizationData.ProductPieceDetailComponent_RingSize_ex_ex;
      case "bangleSize":
        return localizationData.ProductPieceDetailComponent_BangleSize_ex_ex;
      case "exportPrice":
        return localizationData.ProductPieceDetailComponent_ExportPrice_ex_ex;
      case "supplier":
        return localizationData.ProductPieceDetailComponent_Supplier_ex_ex;
      case "location":
        return localizationData.ProductPieceDetailComponent_Location_ex_ex;
      case "supplierDate":
        return localizationData.ProductPieceDetailComponent_SupplierDate_ex_ex;
      case "orderDate":
        return localizationData.ProductPieceDetailComponent_OrderDate_ex_ex;
      case "daysInStock":
        return localizationData.ProductPieceDetailComponent_DaysInStock_ex_ex;
        case "legalOwner":
        return localizationData.ProductPieceDetailComponent_CurrOwner_ex_ex;
      case "gtinNumber":
        return localizationData.ProductPieceDetailComponent_GTINNumber_ex_ex;
      default:
        return ""
    }
  }
  public downloadDocument(data: any, fileName: string, fileType: string) {
    var binaryString = window.atob(data.result.result);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var link = document.createElement('a');
    var blob = new Blob([bytes], { type: fileType });
    link.href = window.URL.createObjectURL(blob);
    link.download = data.result.fileName;
    document.body.appendChild(link);
    link.click();

  }



  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ProductPieceDetailComponent";
  }


}

class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
  Description: string;
}
class SaleSuccessData {
  "Batch No": string;
  "Piece Number": string;
  "Old Inventory Number": string;
  "ATA Carnet Number": string;
  "Availability Status": string;
  "Ring Size": string;
  "Bangle Size": string;
  "BOM Cost": string;
  "Export Retail Price": string;
  "Location": string;
  "Supplier": string;
  "Supplier Delivery Date": string;
  "Sold Date": string;
}





