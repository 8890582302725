import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailMessageTemplateBase {

//#region messageTemplateDetailID Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'messageTemplateDetailID', keyColumn: true})
        messageTemplateDetailID : number;
//#endregion messageTemplateDetailID Prop


//#region messageTemplateID Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'messageTemplateID', keyColumn: false})
        messageTemplateID : number;
//#endregion messageTemplateID Prop


//#region languageId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'languageId', keyColumn: false})
        languageId : number;
//#endregion languageId Prop


//#region bccEmailAddresses Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'bccEmailAddresses', keyColumn: false})
        bccEmailAddresses : string;
//#endregion bccEmailAddresses Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'subject', keyColumn: false})
        subject : string;
//#endregion subject Prop


//#region body Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'body', keyColumn: false})
        body : string;
//#endregion body Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region emailAccountId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'emailAccountId', keyColumn: false})
        emailAccountId : number;
//#endregion emailAccountId Prop


//#region isActive Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'isActive', keyColumn: false})
        isActive : boolean;
//#endregion isActive Prop


//#region parentMessageTemplateDetailID Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'parentMessageTemplateDetailID', keyColumn: false})
        parentMessageTemplateDetailID : any;
//#endregion parentMessageTemplateDetailID Prop


//#region templateName Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'templateName', keyColumn: false})
        templateName : string;
//#endregion templateName Prop


//#region fromEmailAddress Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'fromEmailAddress', keyColumn: false})
        fromEmailAddress : string;
//#endregion fromEmailAddress Prop


//#region fromEmailName Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'fromEmailName', keyColumn: false})
        fromEmailName : string;
//#endregion fromEmailName Prop

}