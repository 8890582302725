import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductProductTypeMappingBase {

//#region ProductVariantProductTypeId Prop
        @prop()
        productVariantProductTypeId : number;
//#endregion ProductVariantProductTypeId Prop


//#region ProductVariantId Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion ProductVariantId Prop


//#region ProductTypeId Prop
        @prop()
        productTypeId : number;
//#endregion ProductTypeId Prop

// //#region categoryId Prop
//         @prop()
//         categoryId : number;
// //#endregion categoryId Prop

}