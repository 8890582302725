import { prop,propObject,propArray,required,maxLength,range, notEmpty, pattern  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TopicBase {

//#region topicId Prop
        @prop()
        topicId : number;
//#endregion topicId Prop


//#region name Prop
        @required({message: "Please enter System Name"})
        @notEmpty()
        @pattern({ expression: {'name': /^(?!.*--)[a-z_\-A-Z]*$/}, message: "You can not add blank space or special character between System Name"} )
        @maxLength({value:200})
        name : string;
//#endregion name Prop


//#region isPasswordProtected Prop
        @prop({defaultValue: false})
        @required()
        isPasswordProtected : boolean;
//#endregion isPasswordProtected Prop


//#region password Prop
        @prop({defaultValue: ""})
        //@required()
        //@maxLength({value:200})
        password : string;
//#endregion password Prop


//#region includeInSitemap Prop
        @prop({defaultValue: false})
        @required()
        includeInSitemap : boolean;
//#endregion includeInSitemap Prop

//#region includeInSitemap Prop
@prop({defaultValue: false})
@required()
isLayoutPage : boolean;
//#endregion includeInSitemap Prop

//#region isPublished Prop
@prop({defaultValue: false})
@required()
isPublished : boolean;
//#endregion isPublished Prop

}