import { prop, propArray } from '@rxweb/reactive-form-validators';
import { LeaveMasterDetailViewModelBase } from '../database-models/leave-master-detail-view-model-base';
import { LeaveFlightMappingBase } from '../database-models/leave-flight-mapping-base';
import { LeaveAccomodationMappingBase } from '../database-models/leave-accomodation-mapping-base';
import { LeaveVehicleMappingBase } from '../database-models/leave-vehicle-mapping-base';

export class LeaveMasterDetailViewModel extends LeaveMasterDetailViewModelBase {

        //#region managerId
        managerId: number;
        //#endregion managerId

        //#region managerName
        managerName: string;
        //#endregion managerName

        //#region reportToIds
        reportToIds: string;
        //#endregion reportToIds

        //#region reportToNames
        reportToNames: string;
        //#endregion reportToNames

        //#region reportToNamesWithHOD
        reportToNamesWithHOD: string;
        //#endregion reportToNamesWithHOD

        //#region leaveId
        leaveId: number;
        //#endregion leaveId

        //#region leaveMasterEventId
        leaveMasterEventId: string
        //#endregion leaveMasterEventId

        //#region leaveMasterEventId
        leaveMasterProjectId: string
        //#endregion leaveMasterEventId
        
        //#region leaveMasterCorporatId
        leaveMasterCorporatId: string
        //#endregion leaveMasterCorporatId
        
        //#region userId
        userId: number
        //#endregion userId

        //#region startSession
        startSession: number
        //#endregion startSession

        //#region endSession
        endSession: number
        //#endregion endSession

        //#region status
        status: number
        //#endregion status

        //#region currency
        currency: number
        //#endregion currency

        //#region budget
        budget: number
        //#endregion budget

        //#region budgetInUSD
        budgetInUSD: number
        //#endregion budgetInUSD

        //#region citiesMinesLocations
        citiesMinesLocations: string
        //#endregion citiesMinesLocations

        //#region comments
        comments: string
        //#endregion comments

        //#region attachment
        attachment: string
        //#endregion attachment

        //#region fileUrl
        fileUrl: string
        //#endregion

        //#region leaveUserFullName
        leaveUserFullName: string;
        //#endregion leaveUserFullName

        //#region isVisibleTravelType
        @prop({ defaultValue: false })
        isVisibleTravelType: boolean;
        //#endregion isVisibleTravelType

        //#region isVisibleTravelType
        @prop()
        isDomesticTravel: boolean;
        //#endregion isVisibleTravelType


        //#region purposeOfTravel Prop
        @prop()
        purposeOfTravel: string;
        //#endregion purposeOfTravel Prop

        //#region isOfficial Prop
        @prop()
        isOfficial: boolean;
        //#endregion isOfficial Prop

        //#region isDetailedFormAdded Prop
        @prop()
        isDetailedFormAdded: boolean;
        //#endregion isDetailedFormAdded Prop

        //#region isVehicleRequested Prop
        @prop()
        isVehicleRequested: boolean;
        //#endregion isVehicleRequested Prop

        //#region isFlightRequested Prop
        @prop()
        isFlightRequested: boolean;
        //#endregion isFlightRequested Prop

        //#region isAccomodationRequested Prop
        @prop()
        isAccomodationRequested: boolean;
        //#endregion isAccomodationRequested Prop

        //#region totalHrsRequested Prop
        @prop()
        totalHrsRequested: string;
        //#endregion totalHrsRequested Prop

        //#region logisticUse Prop
        @prop()
        logisticUse: string;
        //#endregion logisticUse Prop

        //#region carProvided Prop
        @prop()
        carProvided: string;
        //#endregion carProvided Prop

        //#region driverProvided Prop
        @prop()
        driverProvided: string;
        //#endregion driverProvided Prop

        //#region driverPhone Prop
        @prop()
        driverPhone: string;
        //#endregion driverPhone Prop

        //#region emailForUpdates Prop
        @prop()
        emailForUpdates: string;
        //#endregion emailForUpdates Prop

        //#region telePhoneForUpdates Prop
        @prop()
        telePhoneForUpdates: string;
        //#endregion telePhoneForUpdates Prop

        @propArray(LeaveVehicleMappingBase)
        leaveVehicleMappings: LeaveVehicleMappingBase[];

        @propArray(LeaveFlightMappingBase)
        leaveFlightMappings: LeaveFlightMappingBase[];

        @propArray(LeaveAccomodationMappingBase)
        leaveAccomodationMappings: LeaveAccomodationMappingBase[];
        

        //#region reason
        @prop()
        reason: string;
        //#endregion reason

        //#region cancel
        hrCanCancelLeave: boolean;
        //#endregion cancel

        //#region travelReportToNames
        travelReportToNames:string;
        //#endregion travelReportToNames
}
