import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLegalOwnerLookUpBase {

//#region legalOwnerId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'legalOwnerId', keyColumn: true})
        legalOwnerId : number;
//#endregion legalOwnerId Prop


//#region legalOwnerName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'legalOwnerName', keyColumn: false})
        legalOwnerName : string;
//#endregion legalOwnerName Prop

}