import { Component, OnInit, Input } from '@angular/core';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ChangePasswordComponent } from 'src/app/components/authentication/change-password/change-password.component';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-password-alert',
  templateUrl: './password-alert.component.html',
  styleUrls: ['./password-alert.component.css']
})
export class PasswordAlertComponent implements OnInit {
  @Input() daysLeft: number;
  @Input() expiryDays: number;
  @Input() userId: number;
  @Input() hide: Function;
  modalView: ModalView;
  passwordScreenUrl: string;
  router: Router;
  titleClose: string;
  constructor(route: Router, modelView: ModalView,private location : PlatformLocation) {
    this.router = route;
    this.modalView = modelView;
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.passwordScreenUrl = CLIENT_URL + "/reset-password/" + this.userId;
  }

  changePasswordScreen() {
    this.hide();
    this.modalView.show(ChangePasswordComponent, { userId: this.userId }).then(t => {

    })
    //this.router.navigate(['reset-password',this.userId])
  }
}
