import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vReturnStatusLookUpBase {

//#region returnStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'returnStatusId', keyColumn: true})
        returnStatusId : number;
//#endregion returnStatusId Prop


//#region returnStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'returnStatusName', keyColumn: false})
        returnStatusName : string;
//#endregion returnStatusName Prop

}