import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription, Subject } from 'rxjs';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { getLocalizedValue, dateCompareValidation, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { AppGrid } from 'src/app/domain/app-grid';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { multilingual } from '@rxweb/localization';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";
import { AbstractCorporateOrders } from "../domain/abstract-corporate-orders";
import { OrderSearchFilterViewModelBase } from "src/app/models/database-models/order-search-filter-view-model-base";
import { OrderSearchFilterViewModel } from "src/app/models/extended-models/order-search-filter-view-mode";
import { OrderViewModel } from "src/app/models/extended-models/order-view-model";
import { Router } from "@angular/router";
import { CorporateOrderListViewModel } from "src/app/models/extended-models/corporate-order-list-view-model";
import { CorporateOrderEventProjectMapping } from "src/app/models/extended-models/corporate-order-event-project-mapping";
@multilingual("CorporateOrdersAddComponent")
@Component({
    selector: "app-corporate-orders-add",
    templateUrl: './corporate-orders-add.component.html'
})
  //#291870//28-06-2024//adding order mapping to project and event
export class CorporateOrdersAddComponent extends AbstractCorporateOrders implements OnInit, OnDestroy {

    @Input() mapId: number;
    @Input() hide: Function;
    @Input() entityTypeId: number;


    CorporateOrderEventProjectListGrid: AppGrid;
    orderSearchFilterFormGroup: IFormGroup<OrderSearchFilterViewModel>;
    orderSearchFilterViewModelBase: OrderSearchFilterViewModelBase;
    CorporateOrderEventProjectList: CorporateOrderListViewModel[];
    corporateOrderEventProjectMappingArray: CorporateOrderEventProjectMapping[];
    excludedCorporateOrderEventProjectMappingArray: CorporateOrderEventProjectMapping[];
    subscription: any;
    path: string;
    isFilter: boolean = false;
    spin: boolean = false;
    totalRecords: number = 0;
    headerBtnChk: boolean = false;
    cloneArray: CorporateOrderListViewModel[];
    orderIds: number[];
     excludedOrdersIds:number[];
    showGrid: boolean = false;
    titleClose: string;
    ErrorMsg: string;
    searchPerson: string;
    dateError: boolean = false;
    filterJson:any;
    _this;
    orderIdError:boolean = false;
    isSearch: boolean = false;
    router: Router;
    corporateOrderEventProjectIds: number[];
    excludedcorporateOrderEventProjectIds:number[];
    constructor(router: Router,private formBuilder: RxFormBuilder, modelView: ModalView, private browserStorage: BrowserStorage,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.orderIds = new Array<number>();
         this.cloneArray = new Array<CorporateOrderListViewModel>();
        this.corporateOrderEventProjectMappingArray = new Array<CorporateOrderEventProjectMapping>();
        this.excludedCorporateOrderEventProjectMappingArray = new Array<CorporateOrderEventProjectMapping>();
        this.corporateOrderEventProjectIds = new Array<number>();
        this.excludedOrdersIds = new Array<number>()
        this._this = this;
        this.router = router;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }
    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.lookup([
            { path: USER_LOOKUPS["orderStatusLookUps"], propName: "orderStatusLookUp" },
            { path: USER_LOOKUPS["paymentStatusLookUps"], propName: "paymentStatusLookUp" },
            { propName: "salesChannelLookUps", path: "api/UserLookups/SalesChannelLookUps" },
            { propName: "salesAdvisorLookUps", path: "api/UserLookups/SaleOrderAdminUserLookUps" }

        ]).subscribe((lookup: any) => {
            this.orderSearchFilterViewModelBase = new OrderSearchFilterViewModelBase();
            this.orderSearchFilterViewModelBase.pageIndex = PaginationEnum.PageIndex;
            this.orderSearchFilterViewModelBase.rowCount = PaginationEnum.RowCount;
            this.orderSearchFilterFormGroup = this.formBuilder.formGroup(this.orderSearchFilterViewModelBase) as IFormGroup<OrderSearchFilterViewModelBase>; 
            this.corporateOrderLookup = lookup;
            setTimeout(() => {
                this.spin = false;
                this.showComponent = true;
            }, 100);
        });
    }
    search() {
        this.cloneArray = [];
        this.cloneArray = new Array<CorporateOrderListViewModel>();
        if(this.headerBtnChk) {
            this.corporateOrderEventProjectMappingArray = [];
            this.excludedCorporateOrderEventProjectMappingArray = [];
            this.corporateOrderEventProjectIds = [];
            this.excludedcorporateOrderEventProjectIds= [];
            this.excludedOrdersIds = [];
        }
        this.headerBtnChk = false;
        if (this.CorporateOrderEventProjectListGrid != null) {
            this.isFilter = false;
            this.CorporateOrderEventProjectListGrid.updateSource([]);
        }
        this.orderSearchFilterFormGroup.value.pageIndex = PaginationEnum.PageIndex;
        this.searchOrder();
    }

    searchOrder() {
        this.orderSearchFilterFormGroup.submitted = true;
        this.totalRecords = 0;
        if (dateCompareValidation(this.orderSearchFilterFormGroup.value.startDate, this.orderSearchFilterFormGroup.value.endDate) == true) {
            this.dateError = true;
            this.isShowGrid = false;
        }
        else {
          this.dateError = false;
          this.showGrid = true;
            this.isShowGrid = true;
            if (this.orderSearchFilterFormGroup.valid) {
                this.spin = true;
                var json = JSON.parse(JSON.stringify(this.orderSearchFilterFormGroup.value));
                // json["userId"] = 0;
                json["statusId"] = 0;
                if (this.entityTypeId == NotesEnum.ProjectNotes)
                    json["projectId"] = this.mapId;
                else if (this.entityTypeId == NotesEnum.EventNotes)
                    json["eventId"] = this.mapId;

                json["component"] = this.mapId;   
                this.filterJson = json;
                this.filterJson["component"] = this.entityTypeId == NotesEnum.ProjectNotes ? 'project':'event'
              this.subscription = this.post({ path: "api/SearchSalesOrder/CorporateSalesOrderSearch", body: { "query": JSON.stringify(this.filterJson) } }).subscribe((t: any) => {

                    this.spin = false;
                    if (this.CorporateOrderEventProjectListGrid != null) {
                        this.isFilter = true;
                    }
                    this.CorporateOrderEventProjectList = t;
                    // this.ErrorMsg = t[0].ErrorMessage;
                    if (this.CorporateOrderEventProjectList.length > 0) {
                        this.showGrid = true;
                        this.isShowGrid = true;
                        this.totalRecords = this.CorporateOrderEventProjectList[0].totalCount;
                        this.CorporateOrderEventProjectList.forEach(x => {
                            x.isChecked = false;
                        })
                        this.CorporateOrderEventProjectList.forEach(x => {
                            x.isChecked = this.headerBtnChk;
                        })
                        this.CorporateOrderEventProjectList.forEach(x => {
                            if (this.cloneArray.findIndex(z => z.OrderId == x.OrderId) == -1) {
                                this.cloneArray.push(x);
                            }
                            else {
                                this.cloneArray.forEach(z => {
                                    if (z.OrderId == x.OrderId) {
                                        x.isChecked = z.isChecked
                                    }
                                })
                            }
                            if(this.headerBtnChk) {
                                if(this.excludedOrdersIds.indexOf(x.OrderId) != -1) {
                                    x.isChecked = false;
                                }
                            }
                            else {
                                if(this.orderIds.indexOf(x.OrderId) != -1){
                                    x.isChecked = true;
                                }
                            }   
                        })

                        this.updateCorporateOrderEventMappingArray();
                        this.isNoRecordFound = false;
                    }
                    if (this.CorporateOrderEventProjectList.length == 0) {
                        this.isNoRecordFound = true;
                        this.isShowGrid = false;
                    }
                    if (this.isNoRecordFound == false) {
                        if (!this.isFilter) {
                            this.CorporateOrderEventProjectListGrid= new AppGrid(this.CorporateOrderEventProjectList, CorporateOrderListViewModel, { actions: { onOneLink: this.onOneLink.bind(this),onOrderNoLink: this.onOrderNoLink.bind(this),onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this)} });
                            this.CorporateOrderEventProjectListGrid.storeProcedure = {
                                length: this.totalRecords,
                                onPageChanging: this.onPageChanging.bind(this),
                                nextPage: 1,
                                onPageSorting: this.onPageSorting.bind(this)
                            }
                        }
                        else {
                            if (this.CorporateOrderEventProjectListGrid != null) {
                                this.CorporateOrderEventProjectListGrid.storeProcedure.length = this.totalRecords;
                                if (this.orderSearchFilterFormGroup.value.pageIndex == 1) {
                                    this.CorporateOrderEventProjectListGrid.storeProcedure.nextPage = 1;
                                }
                                this.CorporateOrderEventProjectListGrid.updateSource([]);
                                this.CorporateOrderEventProjectListGrid.updateSource(this.CorporateOrderEventProjectList);
                            }
                        }
                        this.CorporateOrderEventProjectListGrid.maxPerPage = this.orderSearchFilterFormGroup.value.rowCount;
                        this.CorporateOrderEventProjectListGrid.componentId = this.componentName;
                        this.CorporateOrderEventProjectListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                        this.CorporateOrderEventProjectListGrid.designClass.headerClass = "table-header-sticky".split(" ");

                    }
                })
            }
            else{
                this.isNoRecordFound = true;
                this.isShowGrid = false;
            }
        }
    }
   
    onOrderNoLink(OrderViewModel: OrderViewModel, event) {
        let url: any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/orders', OrderViewModel.OrderId], { queryParams: { "activeTab": "sales-order" } }));
        window.open(url, '_blank');   
    }

    onOneLink(OrderViewModel: OrderViewModel, event) {

        let url: any;

        if (OrderViewModel.CustomerID == 0) {
            url = this.router.serializeUrl(this.router.createUrlTree(["/suppliers/", OrderViewModel.SupplierId], { queryParams: { activeTab: 'supplier-edit' } }));
        }
        else {
            url = this.router.serializeUrl(this.router.createUrlTree(["/users/", OrderViewModel.CustomerID], { queryParams: { activeTab: 'user-edit' } }));
        }
        window.open(url, '_blank');
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.orderSearchFilterFormGroup.patchValue({
            sortOrder: y,
            orderBy: x
        });
        this.cloneArray = [];
        this.CorporateOrderEventProjectListGrid.storeProcedure.nextPage = z;
        this.searchOrder();
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.cloneArray = [];
        this.orderSearchFilterFormGroup.patchValue({
            pageIndex: x,
            rowCount: this.CorporateOrderEventProjectListGrid.maxPerPage
        });
        this.CorporateOrderEventProjectListGrid.storeProcedure.nextPage = x;
        this.searchOrder();
    }
    

    updateCorporateOrderEventMappingArray() {
        this.cloneArray.forEach(x => {
            let corporateOrderEventProjectMappingObj = new CorporateOrderEventProjectMapping();
            corporateOrderEventProjectMappingObj.corporateOrderEventProjectMappingId = 0;
            corporateOrderEventProjectMappingObj.orderId = Number(x.OrderId);
            if (this.entityTypeId == NotesEnum.ProjectNotes)
                corporateOrderEventProjectMappingObj.projectId = +this.mapId;
            else if (this.entityTypeId == NotesEnum.EventNotes)
                corporateOrderEventProjectMappingObj.eventId = +this.mapId;
            
            corporateOrderEventProjectMappingObj.entityId = this.entityTypeId;
            if (x.isChecked) {
                if(this.headerBtnChk){
                    if(this.excludedCorporateOrderEventProjectMappingArray.findIndex(t => t.orderId == x.OrderId) != -1 ) {
                        this.excludedCorporateOrderEventProjectMappingArray.splice(this.excludedCorporateOrderEventProjectMappingArray.findIndex(t => t.orderId == x.OrderId),1);
                        this.excludedOrdersIds.splice(this.excludedCorporateOrderEventProjectMappingArray.findIndex(t => t.orderId == x.OrderId),1);
                    }
                }
                else{
                    if (this.orderIds.indexOf(Number(x.OrderId)) == -1) {
                        this.corporateOrderEventProjectMappingArray.push(corporateOrderEventProjectMappingObj);
                        this.orderIds.push(Number(x.OrderId));
                    }
                }
            }
            else {
                if(this.headerBtnChk){
                    if (this.excludedCorporateOrderEventProjectMappingArray.findIndex(t => t.orderId == x.OrderId) == -1) {
                        this.excludedCorporateOrderEventProjectMappingArray.push(corporateOrderEventProjectMappingObj);
                        this.excludedOrdersIds.push(x.OrderId);
                      }
                }
                else{
                    if (this.orderIds.indexOf(Number(x.OrderId)) != -1) {
                        this.corporateOrderEventProjectMappingArray.splice(this.orderIds.indexOf(Number(x.OrderId)), 1);
                        this.orderIds.splice(this.orderIds.indexOf(Number(x.OrderId)), 1);
                    }
                }
            }
        })
    }

    onCheckboxSelect(t, x) {
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<CorporateOrderListViewModel>();

        oldCloneArray.forEach(data => {
            let newObj = new CorporateOrderListViewModel();
            newObj.OrderId = data.OrderId;
            newObj.OrderDate = data.OrderDate;
            newObj.OrderStatus = data.OrderStatus;
            newObj.PaymentStatus = data.PaymentStatus;
            newObj.ShippingStatus = data.ShippingStatus;
            //newObj.isChecked = this.headerBtnChk;
            newObj.isChecked = data.OrderId == t.OrderId ? x.target.checked : data.isChecked
            newObj.totalCount = data.totalCount;
            newObj.Customer=data.Customer;
            newObj.NumberOfPieces=data.NumberOfPieces;
            newObj.OrderTotalIncTax=data.OrderTotalIncTax;
            this.cloneArray.push(newObj);

        });
        this.CorporateOrderEventProjectListGrid.updateSource([]);
        this.CorporateOrderEventProjectListGrid.updateSource(this.cloneArray);
        this.updateCorporateOrderEventMappingArray();
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<CorporateOrderListViewModel>();
        this.corporateOrderEventProjectMappingArray = [];
        this.excludedCorporateOrderEventProjectMappingArray = [];
        this.orderIds = [];
        this.excludedOrdersIds= [];
        this.CorporateOrderEventProjectList.forEach(data => {
            let newObj = new CorporateOrderListViewModel();
            newObj.OrderId = data.OrderId;
            newObj.OrderDate = data.OrderDate;
            newObj.OrderStatus = data.OrderStatus;
            newObj.PaymentStatus = data.PaymentStatus;
            newObj.ShippingStatus = data.ShippingStatus;
            newObj.isChecked = this.headerBtnChk;
            newObj.totalCount = data.totalCount;
            newObj.Customer=data.Customer;
            newObj.NumberOfPieces=data.NumberOfPieces;
            newObj.OrderTotalIncTax=data.OrderTotalIncTax;
            this.cloneArray.push(newObj);

        });

        this.updateCorporateOrderEventMappingArray();
        this.CorporateOrderEventProjectListGrid.updateSource([]);
        this.CorporateOrderEventProjectListGrid.updateSource(this.cloneArray);
    }

    
    save(isRedirect){
       if(this.headerBtnChk){
        this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
        this.dialog.defaultConfig.showIcon = true;
        this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
        this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludedCorporateOrderEventProjectMappingArray.length)  + " Records";;
        this.dialog
              .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
              .then((t) => {
                if (t === DialogEnum.Ok) {
                  this.saveOrder(isRedirect);
                  this.dialog.hide();
                }
                else{
                  this.spin = false;
                  this.dialog.hide();
                }
              });
       }
       else{
        this.saveOrder(isRedirect);
       }
    }

    saveOrder(isRedirect) {
        var corporateOrderEventProjectMappingInfo = new CorporateOrderEventProjectMapping();
            // corporateOrderEventProjectMappingInfo.projectId = +this.mapId;
            if (this.entityTypeId == NotesEnum.ProjectNotes){
                corporateOrderEventProjectMappingInfo.isProject = true;
                corporateOrderEventProjectMappingInfo.projectId = +this.mapId;
            }
            else if (this.entityTypeId == NotesEnum.EventNotes){
                corporateOrderEventProjectMappingInfo.isProject = false;
                corporateOrderEventProjectMappingInfo.eventId = +this.mapId;
            }
            corporateOrderEventProjectMappingInfo.OrderIds = this.corporateOrderEventProjectMappingArray;
        if(this.headerBtnChk){
                corporateOrderEventProjectMappingInfo.OrderIds = this.excludedCorporateOrderEventProjectMappingArray;
                corporateOrderEventProjectMappingInfo.headerBtnCheck = this.headerBtnChk;
                this.filterJson["sortOrder"] = "false";
                this.filterJson["rowCount"] = this.totalRecords;
                this.filterJson["pageIndex"] = 1;
                // this.filterJson["component"] = corporateOrderEventProjectMappingInfo.isProject ? 'project':'event'
                corporateOrderEventProjectMappingInfo.query = JSON.stringify(this.filterJson);
                corporateOrderEventProjectMappingInfo.projectId = this.mapId;
        }

        this.spin = true;
        this.post({
            path: "api/SearchSalesOrder/AddCorporateOrderEventProjectMapping",
            body: corporateOrderEventProjectMappingInfo
        }).subscribe(data => {
            this.spin = false;
            var existsAlert = getLocalizedValue("Order Added");
            if (existsAlert) {
                this.toastr.success(existsAlert);
            }
            this.corporateOrderEventProjectMappingArray = [];
            this.cloneArray = [];
            this.headerBtnChk = false;
            isRedirect ? this.searchOrder() : this.hide();
            //this.hide();
        });
    }

    ngOnDestroy(): void {

    }

    get componentName(): string {
        return "CorporateOrdersAddComponent";
    }
}
