import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AccessPointBase {

//#region accessPointId Prop
    @prop()
    accessPointId : number;
//#endregion accessPointId Prop

//#region accessPointName Prop
    @maxLength({value:500})
    @trim()
    accessPointName : string;
//#endregion accessPointName Prop

//#region active Prop
    @prop({defaultValue:false})
    active : boolean;
//#endregion active Prop

//#region deleted Prop
    @prop({defaultValue:false})
    deleted : boolean;
//#endregion deleted Prop

//#region createdOn Prop
    @required()
    createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
    @prop()
    createdBy : number;
//#endregion createdBy Prop

}