import {vIndustryTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vIndustryTypeLookUp extends vIndustryTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}