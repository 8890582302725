import { prop, email } from '@rxweb/reactive-form-validators';

export class  KnownToUserViewModel{

    @prop()
    knownToId:number;
    @prop()
    userId: Number;
    @prop()
    kownToUserId:number;
    @prop()
    userRelationId:number;
    @prop()
    relationShipId: Number;
    @prop()
    fullName : String; 
    @prop()
    company : String; 
    @prop()
    jobTitle : String; 
    @prop()
    relationShipName : String;
    @prop()
    statusId:number;
  
}