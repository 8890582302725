<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Activity_Log_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content " [rxSpinner]="spin" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_Activity_Log_t"></label></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div>

    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="activityLogSearchFormGroup"
      *ngIf="showComponent" (keyup.enter)="onKeyPress()" [rxLocalisationInit]="componentName">
      <div class="row add-countries-form">
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ActivityLogListComponent_CreatedFrom_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="createdFrom" placeholder="Select Date"
                pickerClass="form-control"></rx-date>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 px-xl-4">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ActivityLogListComponent_CreatedTo_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="createdTo" placeholder="Select Date" pickerClass="form-control"
               ></rx-date>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 pl-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ActivityLogListComponent_CustomerEmail_t"></label>
            <div class="col-md-8">
              <input type="text" formControlName="email" class="form-control"
                rxPlaceholder="ActivityLogListComponent_CustomerEmail_p" />
              <small class="form-text text-danger"
                [class.d-block]="activityLogSearchFormGroup.controls.email.errors">{{activityLogSearchFormGroup.controls.email.errorMessage}}</small>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ActivityLogListComponent_Name_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="activityLogTypeId"> -->
              <!-- <option [selected]="true" value="">Select</option>
                <option *ngFor="let item of activityLogTypeLookups.activitylogtypelookUp"
                        [value]="item.activityLogTypeId">
                  {{item.activityName}}
                </option> -->
              <rx-select [(source)]="activityLogTypeLookups.activityLogTypeLookUp" #rxSelect mainClass="form-control"
                formControlName="activityLogTypeId" [selectPlaceholder]="'Select Activity Log Type'"
                [keyValueProps]="['activityName','activityLogTypeId']"></rx-select>
              <!-- </select> -->
            </div>
          </div>
        </div>
      </div>
      <div class="hr-line-dashed border-top row"></div>
      <div class=" d-flex flex-wrap align-items-center row">
        <div class="col text-right">
          <button (click)="onKeyPress()" class="btn btn-success mr-1" rxText="Btn_Search_t"></button>
          <ng-container [rxLocalisationInit]="componentName" *ngIf="activityLogDelete">
            <button (click)="clearAll()" class="btn btn-danger mr-1" [disabled]="this.activityLogIds.length === 0"
              rxText="Btn_Clear_All_t"></button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showSearchBar" [rxLocalisationInit]="componentName">
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <label class="ml-auto mr-0 mb-3">
          <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
            placeholder="" [title]="titleSearch">
        </label>
      </div>
      <div class="table-responsive" *ngIf="isShowGrid">
        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
          <rx-grid [design]="activityLogsGrid"></rx-grid>
        </div>
      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>

</div>
