import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MessageTemplateBase {

//#region messageTemplateID Prop
        @prop()
        messageTemplateID : number;
//#endregion messageTemplateID Prop


//#region name Prop
        @required()
        @maxLength({value:200})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

//#region description Prop
        @prop()
        description : string;
//#endregion description Prop



}