import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductionOrderFormTypeBase {

//#region formTypeID Prop
        @prop()
        formTypeID : number;
//#endregion formTypeID Prop


//#region formTypeName Prop
        @required()
        formTypeName : string;
//#endregion formTypeName Prop

}