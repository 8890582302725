import {vDiscountTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vDiscountTypeLookUp extends vDiscountTypeLookUpBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}