import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneColorRecordBase {

//#region colorId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'colorId', keyColumn: true})
        colorId : number;
//#endregion colorId Prop


//#region colorName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'colorName', keyColumn: false})
        colorName : string;
//#endregion colorName Prop

}