import {ProductionFormMappingDetailBase,ProductionOrderFormBase} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';

export class ProductionFormMappingDetail extends ProductionFormMappingDetailBase{

     //#region Generated Reference Properties

     @prop()
     productName:string;

     @prop()
     relatedProductNames:string;

     @prop()
     totalRRP:number;
     
    //#region productionOrderForm Prop
    productionOrderForm: ProductionOrderFormBase;
    //#endregion productionOrderForm Prop


}