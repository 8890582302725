import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLowStockActivityLookUpBase {

//#region lowStockActivityId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'lowStockActivityId', keyColumn: true})
        lowStockActivityId : number;
//#endregion lowStockActivityId Prop


//#region lowStockActivityName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'lowStockActivityName', keyColumn: false})
        lowStockActivityName : string;
//#endregion lowStockActivityName Prop

}