import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,numeric,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CreditCardTypeBase {

//#region creditCardTypeId Prop
        @prop()
        creditCardTypeId : number;
//#endregion creditCardTypeId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        @notEmpty()
        @trim()
        //@alpha({allowWhiteSpace:true})
        name : string;
//#endregion name Prop


//#region systemKeyword Prop
        @required()
        @maxLength({value:100})
        @trim()
        @notEmpty()
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop

}