import { prop,propObject,propArray,required,maxLength,range, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class NoteBase {

//#region noteId Prop
        @prop()
        noteId : number;
//#endregion noteId Prop


//#region entityId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        entityId : number;
//#endregion entityId Prop


//#region entityTypeId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        entityTypeId : number;
//#endregion entityTypeId Prop


//#region noteDescription Prop
        @prop({defaultValue:""})
        @required()
        @notEmpty()
        @trim()
        noteDescription : string;
//#endregion noteDescription Prop


//#region noteType Prop
        @prop()
        noteType : number;
//#endregion noteType Prop


//#region createdOn Prop
       // @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region subject Prop
        @prop({defaultValue:""})
        @required()
        @notEmpty()
        @maxLength({value:250})
        subject : string;
//#endregion subject Prop


//#region isManual Prop
        @prop()
        isManual : boolean;
//#endregion isManual Prop


//#region isDisplayTeamMember Prop
        @prop({defaultValue:false})
        @required()
        isDisplayTeamMember : boolean;
//#endregion isDisplayTeamMember Prop

//#region isViewByPeople Prop
        @prop({defaultValue:false})
        @required()
        isViewByPeople : boolean;
//#endregion isViewByPeople Prop

        @prop()
        supplierId: number;
        @prop()
        companyName: string;

}