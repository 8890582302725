import {vUserLockoutBase,} from '@app/database-models'
//Generated Imports
import { gridColumn } from '@rxweb/grid';
export class vUserLockout extends vUserLockoutBase {

    //@gridColumn({ name:"isLocked",  configuredTemplate: { templateName: 'bindCheckBox' }, visible: true, columnIndex: 0, allowSorting: true, headerKey: 'image', keyColumn: false })
    //isLocked: boolean;


    //#region Generated Reference Properties

//#endregion Generated Reference Properties






































}