import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceLoadAllPagedFlattenedBase {

//#region id Prop
        @prop()
        id : number;
//#endregion id Prop


//#region productVariantBatchPieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region specificationAttributeOptionId Prop
        @prop()
        specificationAttributeOptionId : number;
//#endregion specificationAttributeOptionId Prop


//#region specatt_name Prop
        @maxLength({value:500})
        specatt_name : string;
//#endregion specatt_name Prop


//#region productId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productId : number;
//#endregion productId Prop


//#region product_name Prop
        @required()
        @maxLength({value:400})
        product_name : string;
//#endregion product_name Prop


//#region product_Published Prop
        @required()
        product_Published : boolean;
//#endregion product_Published Prop


//#region producu_Deleted Prop
        @required()
        producu_Deleted : boolean;
//#endregion producu_Deleted Prop


//#region shortDescription Prop
        @required()
        shortDescription : string;
//#endregion shortDescription Prop


//#region fullDescription Prop
        @required()
        fullDescription : string;
//#endregion fullDescription Prop


//#region productVariantId Prop
        @prop()
        productVariantId : number;
//#endregion productVariantId Prop


//#region productVariant_name Prop
        @maxLength({value:400})
        productVariant_name : string;
//#endregion productVariant_name Prop


//#region isAllRRP Prop
        @prop()
        isAllRRP : boolean;
//#endregion isAllRRP Prop


//#region price Prop
        @prop()
        price : number;
//#endregion price Prop


//#region pictureId Prop
        @prop()
        pictureId : number;
//#endregion pictureId Prop


//#region showSalesTeam Prop
        @prop()
        showSalesTeam : boolean;
//#endregion showSalesTeam Prop


//#region coreProduct Prop
        @prop()
        coreProduct : boolean;
//#endregion coreProduct Prop


//#region noreOrder Prop
        @prop()
        noreOrder : boolean;
//#endregion noreOrder Prop


//#region productVariant_Published Prop
        @prop()
        productVariant_Published : boolean;
//#endregion productVariant_Published Prop


//#region productVariant_Deleted Prop
        @prop()
        productVariant_Deleted : boolean;
//#endregion productVariant_Deleted Prop


//#region sku Prop
        @maxLength({value:100})
        sku : string;
//#endregion sku Prop


//#region description Prop
        @maxLength({value:4000})
        description : string;
//#endregion description Prop


//#region adminComment Prop
        @maxLength({value:4000})
        adminComment : string;
//#endregion adminComment Prop


//#region inspiration Prop
        @maxLength({value:4000})
        inspiration : string;
//#endregion inspiration Prop


//#region shippingNotes Prop
        @maxLength({value:255})
        shippingNotes : string;
//#endregion shippingNotes Prop


//#region customsDescription Prop
        @maxLength({value:255})
        customsDescription : string;
//#endregion customsDescription Prop


//#region availableStartDateTime Prop
        @prop()
        availableStartDateTime : Date;
//#endregion availableStartDateTime Prop


//#region availableEndDateTime Prop
        @prop()
        availableEndDateTime : Date;
//#endregion availableEndDateTime Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region categoryId Prop
        @prop()
        categoryId : number;
//#endregion categoryId Prop


//#region name Prop
        @maxLength({value:400})
        name : string;
//#endregion name Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region cost Prop
        @required()
        cost : number;
//#endregion cost Prop


//#region serialNo Prop
        @required()
        @maxLength({value:50})
        serialNo : string;
//#endregion serialNo Prop


//#region oldInventoryNumber Prop
        @maxLength({value:100})
        oldInventoryNumber : string;
//#endregion oldInventoryNumber Prop


//#region aTACarnetNo Prop
        @required()
        @maxLength({value:50})
        aTACarnetNo : string;
//#endregion aTACarnetNo Prop


//#region location Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        location : number;
//#endregion location Prop


//#region displayRRPFor Prop
        @required()
        @maxLength({value:2})
        displayRRPFor : string;
//#endregion displayRRPFor Prop


//#region exportPrice Prop
        @required()
        exportPrice : number;
//#endregion exportPrice Prop


//#region pictureId1 Prop
        @prop()
        pictureId1 : number;
//#endregion pictureId1 Prop


//#region supplierDeliveryDate Prop
        @prop()
        supplierDeliveryDate : Date;
//#endregion supplierDeliveryDate Prop


//#region size Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        size : number;
//#endregion size Prop


//#region eggSize Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        eggSize : number;
//#endregion eggSize Prop


//#region dutyStatusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        dutyStatusId : number;
//#endregion dutyStatusId Prop


//#region carnetExpiryDate Prop
        @prop()
        carnetExpiryDate : Date;
//#endregion carnetExpiryDate Prop


//#region pieceOwner Prop
        @prop()
        pieceOwner : number;
//#endregion pieceOwner Prop


//#region chainLengthId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        chainLengthId : number;
//#endregion chainLengthId Prop


//#region productStatus Prop
        @maxLength({value:150})
        productStatus : string;
//#endregion productStatus Prop


//#region pieceOwnerId Prop
        @prop()
        pieceOwnerId : number;
//#endregion pieceOwnerId Prop


//#region chainLength Prop
        @required()
        chainLength : string;
//#endregion chainLength Prop


//#region designColour Prop
        @required()
        @maxLength({value:100})
        designColour : string;
//#endregion designColour Prop


//#region collectionName Prop
        @maxLength({value:500})
        collectionName : string;
//#endregion collectionName Prop


//#region searchData Prop
        @prop()
        searchData : string;
//#endregion searchData Prop


//#region sEARCH Prop
        @prop()
        sEARCH : string;
//#endregion sEARCH Prop


//#region isSearch Prop
        @prop()
        isSearch : boolean;
//#endregion isSearch Prop


//#region superSeller Prop
        @prop()
        superSeller : boolean;
//#endregion superSeller Prop


//#region dutyStatus Prop
        @maxLength({value:1000})
        dutyStatus : string;
//#endregion dutyStatus Prop


//#region dutyStatusDate Prop
        @prop()
        dutyStatusDate : Date;
//#endregion dutyStatusDate Prop


//#region bangleSize Prop
        @prop()
        bangleSize : number;
//#endregion bangleSize Prop


//#region isUpcycled Prop
        @required()
        isUpcycled : boolean;
//#endregion isUpcycled Prop


//#region isBeSpoke Prop
        @required()
        isBeSpoke : boolean;
//#endregion isBeSpoke Prop

}