import { prop } from '@rxweb/reactive-form-validators';

export class LocalizationSearchModel{
    @prop()
    languageId:number;

    @prop()
    localizationKey:string;

    @prop()
    localizationValue:string;
}