import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneClarityGemfieldBase {

//#region clarityId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemStoneClarityGemfieldListComponent_ClarityId_gh', keyColumn: true })
        clarityId : number;
//#endregion clarityId Prop


//#region clarityName Prop
        @gridColumn({ isAscending:true, style: { width: "50%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemStoneClarityGemfieldListComponent_ClarityNme_gh', keyColumn: false })
        clarityName : string;
//#endregion clarityName Prop

}