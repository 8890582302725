import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSystemInformationBase      {

//#region systemInformationId Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'SystemInformationListComponent_SystemId_gh', keyColumn: true})
        systemInformationId : number;
//#endregion systemInformationId Prop


//#region projectName Prop
        @gridColumn({isAscending:true, style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:1, allowSorting: true, headerKey: 'SystemInformationListComponent_ProjectName_gh', keyColumn: false})
        projectName : string;
//#endregion projectName Prop


//#region ProjectTypeId Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: false, columnIndex:8, allowSorting: true, headerKey: 'SystemInformationListComponent_ProjectTypeId_gh', keyColumn: false})
        projectTypeId : number;
//#endregion ProjectTypeId Prop

//#region ProjectTypeName Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:2, allowSorting: true, headerKey: 'SystemInformationListComponent_ProjectTypeName_gh', keyColumn: false})
        projectTypeName : string;
//#endregion ProjectTypeName Prop


//#region technology Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:3, allowSorting: true, headerKey: 'SystemInformationListComponent_Technology_gh', keyColumn: false})
        technology : string;
//#endregion technology Prop


//#region deploymentVerison Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:4, allowSorting: true, headerKey: 'SystemInformationListComponent_DeployVerison_gh', keyColumn: false})
        deploymentVerison : string;
//#endregion deploymentVerison Prop


//#region publishedDate Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:5, allowSorting: true, headerKey: 'SystemInformationListComponent_PublishedDate_gh', keyColumn: false})
        publishedDate : any;
//#endregion publishedDate Prop


//#region createdDate Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:6, allowSorting: true, headerKey: 'SystemInformationListComponent_CreatedDate_gh', keyColumn: false})
        createdDate : any;
//#endregion createdDate Prop


//#region isDeploymentSuccessful Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:7, allowSorting: true, headerKey: 'SystemInformationListComponent_DeploySuccessful_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" }})
        isDeploymentSuccessful : boolean;
//#endregion isDeploymentSuccessful Prop

}