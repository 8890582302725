import { Component, OnInit, Input, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { vCustomerEvent } from 'src/app/models/extended-models/v-customer-event';
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { List } from '@rxweb/generics';
import { DatePipe, PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreComponent } from '@rxweb/framework';
import { http } from '@rxweb/http';
import { multilingual } from '@rxweb/localization';
import { GridColumnConfig } from '@rxweb/grid';

import { HseEntryPeopleViewModel } from 'src/app/view-model/hse-entry-people-view-model';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { BaseDialog, DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { RiskPressMediaAddViewModel } from 'src/app/view-model/risk-press-media-add-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { RiskPressMediaViewAddModel } from 'src/app/view-model/risk-press-media-add-view-model';
import { RiskProjectMapping } from '@app/models';
import { RiskProjectMappingAddViewModel } from 'src/app/view-model/risk-project-mapping-add-view-model';

@multilingual("PressMediaViewAddComponent")
@Component({
    selector: 'app-press-media-view-add',
    templateUrl: './press-media-view-add.component.html',
})

export class PressMediaViewAddComponent extends CoreComponent implements OnInit, OnDestroy {
    spin: boolean = false;
    isShowGridColumn: boolean = false;
    showComponent: boolean = false;
    dialog: BaseDialog;
    modalView: ModalView;
    subscription: any;

    data: List<any>;
    id: number;
    isSearch: boolean = false;
    gridSearchString: string = "";
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    router: Router;
    paginationViewModel: PaginationViewModel;
    @Input() hide: Function;
    @Input() riskId : number
    @Input() hseId : number
    riskPressMediaFormGroup : IFormGroup<RiskPressMediaAddViewModel>;
    riskPressMediaViewModel : RiskPressMediaAddViewModel;
    addedByLookup : any;
    hashtagLookup : any;
    toastr: BaseToastr;
    localizedMessages: any;

    riskPressMediaList : RiskPressMediaViewAddModel[];
    riskPressMediaGrid : AppGrid;
    showGrid : boolean = false
    ErrorMsg : string ;
    isShowGrid : boolean = false;
    isNoRecordFound: boolean;
    totalRecords: number = 0;
    isFilter: boolean = false;
    headerBtnChk: boolean = false;
    cloneArray: RiskPressMediaViewAddModel[];
    pressMediaInputModel:RiskPressMediaViewAddModel;
    riskPressMapping: RiskProjectMapping;
    excludedRiskPressMapping : RiskProjectMapping;
    pressInputModel: RiskProjectMappingAddViewModel;
    pressMediaIds: Array<number>;
    excludedRiskPressMapId : Array<number>;
    filterJson:any;
    _this;

    constructor(private datePipe: DatePipe,modalView : ModalView, router: Router, private activatedRoute: ActivatedRoute, private formBuilder : RxFormBuilder,private location : PlatformLocation) {
      super();
      this.modalView =  modalView;
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.headerBtnChk = false;
        this.toastr = new BaseToastr();
        Window["PressMediaViewAddComponent"] = this;
        this.router = router;
        this.pressMediaIds = new Array<number>();
        this.excludedRiskPressMapId = new Array<number>();
        this._this = this;
        this.dialog = new BaseDialog();
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
      this.cloneArray = new Array<RiskPressMediaViewAddModel>();
      this.spin = true;
      this.lookup([
        { path: 'api/SearchCommon/GetPressMediaLookup', queryParams: { "query": 'hashtag' }, propName: 'hashtagLookup' },
        { path: 'api/SearchCommon/GetPressMediaLookup', queryParams: { "query": "addedBy" }, propName: 'addedByLookup' },
      ])
      .subscribe((lookupData : any)=>{
        this.hashtagLookup = lookupData.hashtagLookup;
        this.addedByLookup = lookupData.addedByLookup;
        this.riskPressMediaViewModel = new RiskPressMediaAddViewModel();
        this.riskPressMediaViewModel.riskId = this.riskId;
        this.riskPressMediaViewModel.hseId = this.hseId;
        this.riskPressMediaFormGroup = this.formBuilder.formGroup(RiskPressMediaAddViewModel, this.riskPressMediaViewModel) as IFormGroup<RiskPressMediaAddViewModel>;
        this.showComponent = true;
        this.riskPressMapping = new RiskProjectMapping();
        this.excludedRiskPressMapping = new RiskProjectMapping();
        this.riskPressMapping.riskMenuDetailId = this.riskId;
        this.riskPressMapping.projectRiskMappingId = 0;
        this.riskPressMapping.ProjectId = 1;
        if(this.hseId)
        {
        this.riskPressMapping.hseTab = true ;
        this.riskPressMapping.riskMenuDetailId = this.hseId;
        }
        this.riskPressMapping.riskProjectInput = new Array<RiskProjectMappingAddViewModel>();
        this.excludedRiskPressMapping.riskProjectInput = new Array<RiskProjectMappingAddViewModel>();
        this.spin = false;
      });
    }
    ngOnDestroy(): void {
      if (this.subscription)
          this.subscription.unsubscribe();
    }
    searchRiskPressList()
    {
      this.cloneArray = new Array<RiskPressMediaViewAddModel>();
      if(this.headerBtnChk) {
        this.excludedRiskPressMapId = [];
        this.excludedRiskPressMapping.riskProjectInput = [];
        this.riskPressMapping.riskProjectInput = [];
        this.pressMediaIds = [];
      }
      this.headerBtnChk = false;
      this.riskPressMapping.riskProjectInput = new Array<RiskProjectMappingAddViewModel>();
      if(this.riskPressMediaGrid != null)
      {
        this.isFilter = true;
        this.riskPressMediaGrid.updateSource([]);
        this.riskPressMediaGrid.storeProcedure.nextPage = 1;
      }
      this.riskPressMediaFormGroup.patchValue({pageIndex: 1})

      this.searchRiskPress();
    }
    searchRiskPress()
    {
      this.spin = true;
      this.isShowGrid = true;
      this.filterJson = this.riskPressMediaFormGroup.value
      this.subscription = this.get(
        {
          path:"api/SearchProject/GetRiskAddPressMediaList",
          queryParams:{query : encodeURIComponent(JSON.stringify(this.riskPressMediaFormGroup.value))}
        }).subscribe((data:any)=>{
          this.riskPressMediaList = data[0].UserData;
          this.ErrorMsg = data[0].ErrorMessage;
          if (this.riskPressMediaGrid != null) {
            this.isFilter = true;
          }
          if (this.riskPressMediaList.length > 0) {
            this.riskPressMediaList.forEach(x => x.isChecked = this.headerBtnChk);
            this.riskPressMediaList.forEach(x => {
                if (this.cloneArray.findIndex(z => z.PressMediaId == x.PressMediaId) == -1) {
                    this.cloneArray.push(x);
                }
                else {
                    this.cloneArray.forEach(z => {
                        if (z.PressMediaId == x.PressMediaId) {
                            x.isChecked = z.isChecked
                        }
                    })
                }
                if(this.headerBtnChk){
                  if(this.excludedRiskPressMapId.indexOf(x.PressMediaId) != -1){
                    x.isChecked = false;
                  }
                }
                else{
                  if(this.pressMediaIds.indexOf(x.PressMediaId) != -1) {
                    x.isChecked = true;
                  }
                }
            })
            this.updateProjectEventArray();
            this.totalRecords = this.riskPressMediaList[0].totalCount;
            this.isNoRecordFound = false;
          }
          else {
              this.isNoRecordFound = true;
              this.isShowGrid = false;
              this.headerBtnChk = false;
          }
          if (!this.isFilter) {
            this.riskPressMediaList.length > 0 ? this.showGrid = true : this.showGrid = false;
            this.riskPressMediaGrid = new AppGrid(this.riskPressMediaList, RiskPressMediaViewAddModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });

            this.riskPressMediaGrid.storeProcedure = {
                length: this.totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                nextPage: 1,
                onPageSorting: this.onPageSorting.bind(this)
            }
          }
          else {
              if (this.riskPressMediaGrid != null) {
                  this.riskPressMediaGrid.storeProcedure.length = this.totalRecords;
                  this.riskPressMediaGrid.updateSource([]);
                  this.riskPressMediaGrid.updateSource(this.riskPressMediaList)
              }
          }
          this.spin = false;
          this.riskPressMediaGrid.maxPerPage = this.riskPressMediaFormGroup.controls.rowCount.value;
          this.riskPressMediaGrid.componentId = this.componentName;
        });
    }
    onCheckboxSelect(t, x) {

      let oldCloneArray = this.cloneArray;
      this.cloneArray = new Array<RiskPressMediaViewAddModel>();

      oldCloneArray.forEach(data => {
          let newObj = new RiskPressMediaViewAddModel();
          newObj.PressMedia = data.PressMedia;
          newObj.isChecked = data.PressMediaId == t.PressMediaId ? x.target.checked : data.isChecked;
          newObj.Article = data.Article;
          newObj.PressMediaId = data.PressMediaId;
          newObj.totalCount = data.totalCount;
          this.cloneArray.push(newObj);
      });

      this.riskPressMediaGrid.updateSource([]);
      this.riskPressMediaGrid.updateSource(this.cloneArray);
      this.updateProjectEventArray();
  }
    onPageSorting(x, y, z) {
      this.isFilter = true;
      this.riskPressMediaFormGroup.patchValue({
          sortOrder: y,
          orderBy: x
      });
      this.cloneArray = [];
      // this.headerBtnChk = false;

      this.riskPressMediaGrid.storeProcedure.nextPage = z;
      this.searchRiskPress();
    }

    onPageChanging(x) {
      this.isFilter = true;
      let fromIndex: number = this.riskPressMediaFormGroup.value.rowCount;
      let toIndex: number = this.riskPressMediaGrid.maxPerPage;
      // if (this.riskPressMediaFormGroup.value.pageIndex < x) {
          this.cloneArray = [];
      // }
      // this.headerBtnChk = false;
      if (this.riskPressMediaGrid.maxPerPage < this.riskPressMediaFormGroup.value.rowCount) {
          for (let i = fromIndex; i > toIndex; i--) {
              this.cloneArray.splice(i - 1, 1);
          }
      }
      this.riskPressMediaFormGroup.patchValue({
          pageIndex: x,
          rowCount: this.riskPressMediaGrid.maxPerPage
      });
      this.riskPressMediaGrid.storeProcedure.nextPage = x;
      this.searchRiskPress();
    }
    onHeaderCheckBoxSelect(t, x) {
      this.headerBtnChk = x.target.checked;

      this.cloneArray = new Array<RiskPressMediaViewAddModel>();

      this.excludedRiskPressMapId = [];
      this.excludedRiskPressMapping.riskProjectInput = [];
      this.riskPressMapping.riskProjectInput = [];
      this.pressMediaIds = [];

      this.riskPressMediaList.forEach(data => {
          let newObj = new RiskPressMediaViewAddModel();
          newObj.PressMedia = data.PressMedia;
          newObj.isChecked = this.headerBtnChk;
          newObj.PressMediaId = data.PressMediaId;
          newObj.Article = data.Article;
          //newObj.projectType=data.projectType;
          newObj.totalCount = data.totalCount;
          this.cloneArray.push(newObj);
      });

      this.updateProjectEventArray();


      this.riskPressMediaGrid.updateSource([]);
      this.riskPressMediaGrid.updateSource(this.cloneArray);
    }
    updateProjectEventArray() {
      this.cloneArray.forEach(t => {
          if (t.PressMediaId) {
              this.pressInputModel = new RiskProjectMappingAddViewModel();
              this.pressInputModel.projectID = t.PressMediaId;
              if (t.isChecked) {
                if(this.headerBtnChk){
                  if(this.excludedRiskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId) != -1 ) {
                    this.excludedRiskPressMapId.splice(this.excludedRiskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId),1);
                    this.excludedRiskPressMapping.riskProjectInput.splice(this.excludedRiskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId),1);
                  }
                }
                else{
                  if (this.riskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId) == -1) {
                      this.riskPressMapping.riskProjectInput.push(this.pressInputModel);
                      this.pressMediaIds.push(t.PressMediaId);
                  }
                }
              }
              else {
                if(this.headerBtnChk){
                  if (this.excludedRiskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId) == -1) {
                    this.excludedRiskPressMapping.riskProjectInput.push(this.pressInputModel);
                    this.excludedRiskPressMapId.push(t.PressMediaId);
                  }
                }
                else{
                  if (this.riskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId) != -1) {
                      this.riskPressMapping.riskProjectInput.splice(this.riskPressMapping.riskProjectInput.findIndex(x => x.projectID == t.PressMediaId), 1);
                      this.pressMediaIds.splice(this.pressMediaIds.indexOf(t.PressMediaId), 1);
                  }
                  if(this.pressMediaIds.indexOf(t.PressMediaId) != -1) {
                    this.pressMediaIds.splice(this.pressMediaIds.indexOf(t.PressMediaId), 1);
                  }
                }
              }
          }
      });
    }

    savePressMedia(){
      if(this.headerBtnChk){
        this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
        this.dialog.defaultConfig.showIcon = true;
        this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
        this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludedRiskPressMapping.riskProjectInput.length)  + " Records";;
        this.dialog
              .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
              .then((t) => {
                if (t === DialogEnum.Ok) {
                  this.save();
                  this.dialog.hide();
                }
                else{
                  this.spin = false;
                  this.dialog.hide();
                }
              });
       }
       else{
        this.save();
       }
    }

    save()
    {
      if(this.riskPressMapping.riskProjectInput.length > 0 || this.excludedRiskPressMapping.riskProjectInput.length > 0 || this.headerBtnChk)
      {
        if(this.headerBtnChk){
          this.riskPressMapping.riskProjectInput = this.excludedRiskPressMapping.riskProjectInput;
          this.riskPressMapping.headerBtnChk = this.headerBtnChk;
          this.filterJson["sortOrder"] = "false";
          this.filterJson["rowCount"] = this.totalRecords;
          this.filterJson["pageIndex"] = 1;
          this.riskPressMapping.query = JSON.stringify(this.filterJson);
          this.riskPressMapping.ProjectId = this.hseId;
        }
        this.spin = true;
        this.post({body:this.riskPressMapping, path:"api/SearchProject/AddRiskPressGina",params:[0]}).subscribe(data=>{
          this.spin = false;
          this.cloneArray = [];
          this.headerBtnChk = false;
          var existsAlert = getLocalizedValue("Data_Added");
          if(existsAlert){
            this.toastr.success(existsAlert);
          }
          this.hide();
        })
      }
    }
    get componentName(): string {
        return "PressMediaViewAddComponent";
    }
}
