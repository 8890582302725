import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
//import { AllSetting } from '@app/models';
import { AllSettingViewModel } from '../../../../view-model/all-setting-view-model';
import { CoreComponent,  } from '@rxweb/angular-router';
import { Setting, vSalesAdvisorLookUp, vSalesChannel, vCustomerRoleLookUp, vCountryLookUp, vSystemRatingLookUp, vkeyRelationshipHolderLookUp, vIndustryTypeLookUp, vCategoryLookUp, vTaxDisplayTypeLookUp, vTaxBasedOnLookUp, vStateProvinceLookUp, vTaxCategoryLookUp, vCustomerRegistrationTypeLookUp } from '../../../../models/extended-models';
import { FormArray } from '@angular/forms';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';

@http({
    path: "api/AllSettings"
})
export class AbstractAllSetting extends CoreComponent {
    allSettingFormGroup: IFormGroup<Setting>
    setting: Setting;
    get allSettingViewModelFormArray(): FormArray {
        let appointmentCustomeFormArray = this.allSettingFormGroup.controls["allSettingViewModels"] as IAbstractControl;
        let data = <FormArray>appointmentCustomeFormArray["controls"]
        return data;
    }

    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    spin:boolean;
    stateLookup: any;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    settingLookups: any;
}
