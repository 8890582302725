import {vVatNumberStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vVatNumberStatusLookUp extends vVatNumberStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}