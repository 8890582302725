import { EventBase, EventPictureBase, EventTeamMappingBase, EventBookingBase, EventCustomerMappingBase, EventInvitationBase, ProjectEventMappingBase, BookingBase, } from '@app/database-models'

//Generated Imports
import { prop, propArray, propObject } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { EventBooking } from '.';
import { EventSendEInvitation } from 'src/app/view-model/event-send-einvitation-model';
import { EventCustomerMapping } from './event-customer-mapping';
export class Event extends EventBase implements FileDetailViewModel {

    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    blobFileName:string;
    @prop()
    blobPath:string;
    @prop()
    size:number;
    @prop()
    fileData3: string;
    @prop()
    fileExtention3: string;
    @prop()
    fileName3: string;
    @prop()
    fileData4: string;
    @prop()
    fileExtention4: string;
    @prop()
    fileName4: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;

    @prop({ defaultValue: false })
    isSendInvitation: boolean;


    //#region Generated Reference Properties
    //#region eventPictures Prop
    eventPictures: EventPictureBase[];
    //#endregion eventPictures Prop
    //#region eventTeamMappings Prop
    eventTeamMappings: EventTeamMappingBase[];
    //#endregion eventTeamMappings Prop
    //#region projectEventMappings Prop
    @propArray(ProjectEventMappingBase)
    projectEventMappings: ProjectEventMappingBase[];
    //#endregion projectEventMappings Prop
    //#region eventBookings Prop
    @propObject(EventBookingBase)
    eventBooking: EventBookingBase;
    //#endregion eventBookings Prop

    //#region eventBookings Prop
    @propObject(EventCustomerMappingBase)
    eventCustomerMapping: EventCustomerMappingBase;
    //#endregion eventBookings Prop

    @prop({ defaultValue: '' })
    invitedUserIds: string;

    //#region eventSendEInvitation Prop
    @propArray(EventSendEInvitation)
    eventSendEInvitation: EventSendEInvitation[];
    //#endregion eventSendEInvitation Prop



    //#region bookings Prop

    // bookings : BookingBase;
    //#endregion bookings Prop

    //#region eventCustomerMappings Prop
    eventCustomerMappings: EventCustomerMappingBase[];
    //#endregion eventCustomerMappings Prop
    //#region eventInvitations Prop
    eventInvitations: EventInvitationBase[];
    //#endregion eventInvitations Prop

    //#endregion Generated Reference Properties

    @prop({ defaultValue: false })
    isRemovedEventPicture: boolean;

    @prop()
    eventServiceTypeIds: string;

}