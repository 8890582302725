import { TemplateConfig } from '@rxweb/grid';

export const PUBLISHED_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: ["label", function (e) {
            return this[e.name] ? "label-primary" : '';
        }],
        childrens: [{
            text: {
                text: function (e) { return this[e.name] ? "Published" : "UnPublished" }
            }
        }]
    }
}
export const DATE_DISPLAY_GRID_TEMPLATE: TemplateConfig = {
  span: {
    childrens: [{
      text: {
        text: function (e) {
          if(this[e.name] == null || this[e.name]=="1900-01-01T00:00:00"){
            return "";
          }else{
            var dateHoliday = new Date(this[e.name]);
            return dateHoliday.getDate() + '/' + monthsShort[dateHoliday.getMonth()] + '/' + dateHoliday.getFullYear();
            ;
          }
          // debugger
          // if (e && e.parameter) {
          //   var dateHoliday = new Date(this[e.name]);
          //   return dateHoliday.getDate() + '/' + (dateHoliday.getMonth() + 1) + '/' + dateHoliday.getFullYear();
          // }
          // var date = new Date(this[e.name]).toLocaleDateString("en-GB", options);
          // return date;
        }
      }
    }]
  }
}
const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var options = {
  year: "numeric",
  month: "2-digit",
  day: "numeric"
};

export const RESTRICTED_GRID_TEMPLATE: TemplateConfig = {
  span: {
      class: ["label", function (e) {
          return this[e.name] ? "label-danger" : '';
      }],
      childrens: [{
          text: {
              text: function (e) { return this[e.name] ? "Restricted" : "UnRestricted" }
          }
      }]
  }
}