import {RiskMenuDetailBase,} from '@app/database-models'
//Generated Imports
export class RiskMenuDetail extends RiskMenuDetailBase 
{

    riskMenuDetails: RiskMenuDetail[];
    //#region Generated Reference Properties
    //#endregion Generated Reference Properties

    IsEditable:any;
}