//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentEventSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean
  
  eventId: string;

  //#region eventName
  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.eventName;
          }
        },
      }
    }, parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_EventName_gh', keyColumn: false
  })
  eventName: string;
  //#endregion eventName

  //#region eventOwnerName
  @gridColumn({ parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_EventOwner_gh', keyColumn: false })
  eventOwnerName: string;
  //#endregion eventOwnerName

  //#region eventType
  @gridColumn({ parameter: false, style: { width: "16%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_EventType_gh', keyColumn: false })
  eventType: string;
  //#endregion eventType

  //#region eventStartDate
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_EventStartDate_gh', keyColumn: false })
  eventStartDate: string;
  //#endregion eventStartDate

  //#region eventEndDate
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'MoveNoteDocumentComp_EventEndDate_gh', keyColumn: false })
  eventEndDate: string;
  //#endregion eventEndDate

  totalCount: number;
  UserProfilePicture:string;
  isRestrictedAllowed: boolean;
}
