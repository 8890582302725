import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSpokenLanguageLookUpBase {

//#region SpokenLangId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerTitle: 'SpokenLangId', keyColumn: true})
        SpokenLangId : number;
//#endregion SpokenLangId Prop


//#region language Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerTitle: 'language', keyColumn: false})
        language : string;
//#endregion language Prop

//#region statusId Prop
      //  @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerTitle: 'statusId', keyColumn: false})
      //  statusId : number;
//#endregion statusId Prop

}