import {vPieceDeliveryStatusRecordBase,} from '@app/database-models'
//Generated Imports
export class vPieceDeliveryStatusRecord extends vPieceDeliveryStatusRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}