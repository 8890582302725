import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { FileModule } from '../../shared/global/file/file.module';
import { SystemTableSettingModule } from '../../shared/system-table-settings/system-table-setting.module';
import { SharedModule } from '../../shared/shared.module';
import { WatchInvoiceListComponent } from './list/watch-invoice-list.component';
import { WatchInvoiceEditComponent } from './edit/watch-invoice-edit.component';
import { WatchInvoiceAddComponent } from './add/watch-invoice-add.component';



@NgModule({
    imports: [FileModule, SystemTableSettingModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, SharedModule],
    declarations: [WatchInvoiceListComponent, WatchInvoiceEditComponent, WatchInvoiceAddComponent],
    exports: [WatchInvoiceListComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WatchInvoiceSharedModule { }

