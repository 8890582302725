import {
    Directive,
    Input,
    ContentChild,
    AfterContentInit,
    OnInit,
} from "@angular/core";
import { RxSelectComponent } from "@rxweb/angular";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
    selector: "[rxSelectExtended]",
})
export class RxSelectExtendedDirective implements OnInit,AfterContentInit {
    @ContentChild("rxSelect") rxSelect: RxSelectComponent;
    componentConstructor = null;
    @Input("rxSelectExtended") set component(value: any) {
        this.componentConstructor = value;
    }

    private rxSelectSubject$ : Subject<string> = new Subject<string>();
    clickListener: EventListenerOrEventListenerObject;
    isGINAForm: boolean = false;
    ngOnInit() {
        this.rxSelectSubject$.pipe(debounceTime(300)).subscribe(t => {
            this.onFocus(t);
        })
        this.isGINAForm = document.getElementById('hse-gina-add-form')!= null;        
    }

    ngAfterContentInit() {
        this.rxSelect.onKeyup = this.onKeyUpRxSelect.bind(this);
        if(this.isGINAForm){
            const select: any = this.rxSelect;
            const clearActiveTabClass = select.clearActiveTabClass;
            this.clickListener = (e) => {
                const ele = e.target as HTMLElement;
                if (ele.id != select.searchId) {
                    setTimeout(() => {
                        clearActiveTabClass.bind(select).call()
                    }, 50);
                }
            }
            window.document.addEventListener('click', this.clickListener)
            if (!select.path)
                select.clearActiveTabClass = () => {
                    if (select.timeOutId) {
                        setTimeout(() => {
                            window.clearTimeout(select.timeOutId)
                            select.timeOutId = undefined
                        }, 400)
                    }
                }
        }
        
    }
    
    onKeyUpRxSelect(event) {
        if (event.key == "Tab") {
            this.rxSelect.onFocus(event);
            return;
        }
        var value = event.target.value;
        if (
            value !== null
            && value != 'tab'
            && event.key !== "Shift"
            && event.key !== "ArrowUp"
            && event.key !== "ArrowDown"
            && event.key !== "Enter"
            && event.key !== "ArrowLeft"
            && event.key !== "ArrowRight"
        ) {
            if (this.rxSelect.autoComplete) {
                this.rxSelect["currentValue"] = value;
                // this.rxSelect["onFocus"](event);
                this.rxSelectSubject$.next(event);
            }
            else {
                var newValue = value.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    // .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
                    .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').trim();
                var regex = new RegExp(newValue.toLowerCase());
                this.rxSelect.dropSource = this.rxSelect["mainSource"].filter(t => regex.test(t.value.toLowerCase().normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")));


                this.rxSelect.dropSource.sort((a, b) => {
                    const nameA = a.value.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    const nameB = b.value.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                    // Compare the starting position of the keyword in fullname
                    if (nameA.startsWith(newValue.toLowerCase()) && !nameB.startsWith(newValue.toLowerCase())) {
                        return -1; // a comes first
                    } else if (!nameA.startsWith(newValue.toLowerCase()) && nameB.startsWith(newValue.toLowerCase())) {
                        return 1; // b comes first
                    }

                    return 0;
                })



                this.rxSelect["setActiveTabClass"]();
            }
            if (this.rxSelect.enableFreeText)
                this.rxSelect["updateFormValue"](value);
        }
    }

    onFocus(event) {
        if ((!this.rxSelect.userSource && this.rxSelect["mainSource"].length === 0 && this.rxSelect.lookup) || (this.rxSelect.autoComplete) || this.rxSelect.isObjectChanged) {
            if ((this.rxSelect["currentValue"] && this.rxSelect["currentValue"].length >= this.rxSelect.minimumCharacterSearchLength) || (!this.rxSelect.autoComplete)) {
                let params = [];
                if (this.rxSelect.params)
                    this.rxSelect.params.forEach(t => params.push(t));
                if (this.rxSelect.autoComplete && this.rxSelect["currentValue"]) {
                    if (this.rxSelect.valueInQueryParams) {
                        if (!this.rxSelect.queryParams)
                            this.rxSelect.queryParams = {};
                        this.rxSelect.queryParams['q'] = this.rxSelect["currentValue"];
                    }
                    else
                        params.push(this.rxSelect["currentValue"]);
                }
                this.rxSelect.isSpinner = true;
                Object.keys(this.rxSelect.queryParams).forEach(key => {
                    if (key == "q") {
                        var str: string = this.rxSelect.queryParams[key];
                        this.rxSelect.queryParams[key] = encodeURIComponent(str);
                    }
                });
                this.rxSelect.get({ path: this.rxSelect.path, params: params, queryParams: this.rxSelect.queryParams }).subscribe((t) => {
                    this.rxSelect.userSource = t as any;
                    this.rxSelect["mainSource"] = [];
                    this.rxSelect.dropSource = [];
                    this.rxSelect["setMainSource"](t);
                    this.rxSelect.showItemBox();
                    this.rxSelect.isSpinner = false;
                    this.rxSelect.isObjectChanged = false;
                });
            }
        }
        else if (!this.rxSelect.autoComplete)
            this.rxSelect.showItemBox();
        return false;
    }

    ngOnDestroy(){
        if(this.isGINAForm)
            window.document.removeEventListener('click',this.clickListener);
    }
}
