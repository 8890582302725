import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductionFormCorporateBase {

//#region supplierId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'supplierId', keyColumn: true})
        supplierId : number;
//#endregion supplierId Prop


//#region company Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'company', keyColumn: false})
        company : string;
//#endregion company Prop

}