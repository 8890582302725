import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { AbstractCompanyCustomerMapping } from '../domain/abstract-company-customer-mapping';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';

import { CompanyCustomerMapping } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';

import { CompanyCorporateViewModel } from '../../../../view-model/comapny-corporate-mapping-view-model';
import { ModalView } from '../../../../domain/customize-design/modal';

import { multilingual } from '@rxweb/localization';
import { dateCompareValidation, getLocalizedValue } from '../../../../domain/common-logic/common-logic';
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from "@angular/common";
@multilingual("CompanyCustomerMappingEditComponent")
@Component({
    selector: "app-company-customer-mapping-edit",
    templateUrl: './company-customer-mapping-edit.component.html'
})
export class CompanyCustomerMappingEditComponent extends AbstractCompanyCustomerMapping implements OnInit, OnDestroy {

    customerCompanyEditFormGroup: IFormGroup<CompanyCorporateViewModel>;
    companyCorporate: CompanyCorporateViewModel;
    @Input() hide: Function;
    @Input() isDirector: number;
    @Input() userId: number;
    @Input() mappingId: number;
    @Input() companyId: number;
    @Input() componentType: string;
    @Input() statusName: string;
    @Input() isCorporate: boolean;
    // status: boolean;
    showDate: boolean = false;
    dateError: boolean = false;
    companyCustomerMapping: CompanyCustomerMapping;
    subscription: any;
    company: string;
    titleClose: string;


    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this._this = this;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }



    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.lookup([{ propName: "companyCustomer", params: [this.mappingId] }, { propName: "companyLookup", path: USER_LOOKUPS.companyLookUp }, { propName: "reportingHeadLookup", path: USER_LOOKUPS.reportingHeadLookUp }]).subscribe((lookup: any) => {
            this.employmentData = lookup;
            this.companyCustomerMappingFormGroup = this.formBuilder.formGroup(CompanyCustomerMapping, this.employmentData.companyCustomer) as IFormGroup<CompanyCustomerMapping>;
            this.showComponent = true;

        });
    }

    EditForm() {
        if (dateCompareValidation(this.companyCustomerMappingFormGroup.value.startDate, this.companyCustomerMappingFormGroup.value.endDate) == true) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            this.companyCustomerMappingFormGroup.submitted = true;
            this.companyCustomerMappingFormGroup.patchValue({
                isCorporate: this.isCorporate,
                isCorporateNote: false
                //companyId:this.companyId
            })
            if (this.companyCustomerMappingFormGroup.valid) {
                this.spin = true;
                this.put({ body: this.companyCustomerMappingFormGroup.value, params: [this.mappingId] }).subscribe(data => {
                    //this.put({params:[this.mappingId],body:this.companyCustomerMappingFormGroup.value}).subscribe(data => {

                    // this.put({params:[this.mappingId],body:this.companyCustomerMappingFormGroup.value,queryParams:{isCorporate:this.isCorporate}}).subscribe(data=>{
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Updated");
                    if (existsAlert) {
                        //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }
        }

    }

    DeleteForm() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.spin = true;
                this.dialog.hide();
                this.delete({ body: null, params: [this.mappingId], queryParams: { paramFlag: 0 } }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_People_Employment");
        if (existsAlert) {
            return existsAlert;
        }
    }
    changeStatus(event) {
        this.companyCustomerMappingFormGroup.patchValue({
            endDate: event.target.value == 1 ? null : new Date()
        })
    }
    changeDirectorship(event) {
        this.showDate = event.target.checked ? true : false;
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
