import { Component, OnInit, Input } from '@angular/core';
import { ModalView } from '../../../../domain/customize-design/modal';
import { KeyAccess } from '../../../../temp-service/key-access-service';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-bed-request',
  templateUrl: './bed-request.component.html',
  styleUrls: ['./bed-request.component.css']
})
export class BedRequestComponent implements OnInit {
  @Input() hide: Function;
  @Input() validationMessages: string;
  @Input() headerMessage: string = "";
  showComponent: boolean = false;
  validationMessagesList: string[];
  titleClose: string;
  constructor(private keyAccess: KeyAccess,private location : PlatformLocation) {
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.keyAccess.disableTab();
    this.showComponent = true;
    document.getElementById("closeButton").focus();
    this.validationMessagesList = JSON.parse(this.validationMessages)
    if(JSON.parse(this.validationMessages) == "StatusUpdateValidation") {
      this.validationMessagesList = [getLocalizedValue("StatusUpdateValidation")]
    }
    if(JSON.parse(this.validationMessages) == "CustomerHolidayDeleteValidation") {
      this.validationMessagesList = [getLocalizedValue("CustomerHolidayDeleteValidation")]
    }
    if(JSON.parse(this.validationMessages) == "CustomerHolidayAddValidation") {
      this.validationMessagesList = [getLocalizedValue("CustomerHolidayAddValidation")]
    }
    if(JSON.parse(this.validationMessages) == "ProductDeleteValidation") {
      this.validationMessagesList = [getLocalizedValue("ProductDeleteValidation")]
    }
    if(JSON.parse(this.validationMessages)[0] == "PieceDeleteValidation") {
      this.validationMessagesList = [getLocalizedValue("PieceDeleteValidation") + JSON.parse(this.validationMessages)[1]]
    }
    if(JSON.parse(this.validationMessages)[0] == "PieceDeleteValidation") {
      this.validationMessagesList = [getLocalizedValue("PieceDeleteValidation") + JSON.parse(this.validationMessages)[1]]
    }
    if(JSON.parse(this.validationMessages)[0] == "PieceDeleteBatchQauantityValidation") {
      this.validationMessagesList = [getLocalizedValue("PieceDeleteBatchQauantityValidation")]
    }
    if(JSON.parse(this.validationMessages)[0] == "Directorship_Exists_Error") {
      this.validationMessagesList = [getLocalizedValue("Directorship_Exists_Error")]
    }
    if(JSON.parse(this.validationMessages)[0] == "Active_Directorship_Exists_Error") {
      this.validationMessagesList = [getLocalizedValue("Active_Directorship_Exists_Error")]
    }
    if(JSON.parse(this.validationMessages)[0] == "legalOwnerAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("legalOwnerAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0] == "watchItemTypeAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("watchItemTypeAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0] == "watchMaterialAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("watchMaterialAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0] == "watchStatusGemfieldsAlreadyExist") {
      this.validationMessagesList = [getLocalizedValue("watchStatusGemfieldsAlreadyExist")]
    }
    if(JSON.parse(this.validationMessages)[0] == "TrainingTypeExists") {
      this.validationMessagesList = [getLocalizedValue("TrainingTypeExists")]
    }
    if(JSON.parse(this.validationMessages)[0] == "occurrenceAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("occurrenceAlreadyExists")]
    }
    // if(JSON.parse(this.validationMessages)[0] == "adhocAlreadyExists") {
    //   this.validationMessagesList = [getLocalizedValue("adhocAlreadyExists")]
    // }
    if(JSON.parse(this.validationMessages)[0] == "SENameAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("SENameAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0] == "ProductType_Exists_Error") {
      this.validationMessagesList = [getLocalizedValue("ProductType_Exists_Error")]
    }
    if(JSON.parse(this.validationMessages)[0]== "DeliveryTypeAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("DeliveryTypeAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0]== "GrievanceFormUrlSuffixAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("GrievanceFormUrlSuffixAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0] == "Gemstone_Color_Exists_Error") {
      this.validationMessagesList = [getLocalizedValue("Gemstone_Color_Exists_Error")]
    }
    if(JSON.parse(this.validationMessages)[0] == "EventRestrictedValidation") {
      this.validationMessagesList = [getLocalizedValue("EventRestrictedValidation")]
    }
    if(JSON.parse(this.validationMessages)[0] == "ProjectRestrictedValidation") {
      this.validationMessagesList = [getLocalizedValue("ProjectRestrictedValidation")]
    }
    if(JSON.parse(this.validationMessages)[0]== "FileExtensionAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("FileExtensionAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0]== "powerBIReportAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("powerBIReportAlreadyExists")]
    }
    if(JSON.parse(this.validationMessages)[0]== "PowerBI_ReportNotFound_t") {
      this.validationMessagesList = [getLocalizedValue("PowerBI_ReportNotFound_t")]
    }
    if(JSON.parse(this.validationMessages)[0]== "EventRestrictAccessValidation") {
      this.validationMessagesList = [getLocalizedValue("EventRestrictAccess_OnlyOwner_t")]
    }
    if(JSON.parse(this.validationMessages)[0]== "userAlreadyExists") {
      this.validationMessagesList = [getLocalizedValue("userAlreadyExists")]
    }
  }

  hidePopup() {
    this.keyAccess.enableTab();
    this.hide()
  }
}
