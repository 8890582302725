import {EmailAccountBase,MessageTemplateDetailBase,} from '@app/database-models'
import { prop, email, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class EmailAccount extends EmailAccountBase 
{

    constructor() {
        super();
        this.enableSSL=false;
        this.useDefaultCredentials=false;
    }

        @prop()
        @email()
        mailTo:string;
//#region Generated Reference Properties
//#region messageTemplateDetails Prop
//messageTemplateDetails : MessageTemplateDetailBase[];
//#endregion messageTemplateDetails Prop

//#endregion Generated Reference Properties












}