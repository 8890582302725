import { actionColumn, gridColumn } from '@rxweb/grid';

@actionColumn({
    allowSorting: false,
    visible:false,
    name:"action",
    style: { width: "2%" },
    configuredTemplate: { templateName: 'priceBandAction' }, columnIndex: 12, headerTitle: "Action"
})
export class LocalizationListViewModel {


    //#region languageContentKeyId Prop
    @gridColumn({ style: { width: "3%", "text-align": "initial" },class: ["text-left"], parameter: false,headerKey:'LanguageContentKeyListComponent_Id_gh' ,visible: true, columnIndex: 0, keyColumn: true })
    languageContentKeyId: number;
    //#endregion languageContentKeyId Prop

    //#region component Prop
    @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 1, headerKey: 'LanguageContentKeyListComponent_Component_gh', keyColumn: false })
    component: string;
    //#endregion component Prop

    //#region keyName Prop
    @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'LanguageContentKeyListComponent_ResourceName_gh', keyColumn: false })
    keyName: string;
    //#endregion keyName Prop

    //#region languageContentId Prop
    @gridColumn({ parameter: false, visible: false, columnIndex: 3, headerKey: 'locationCity', keyColumn: false })
    languageContentId: number;
    //#endregion languageContentId Prop

    //#region contentType Prop
    @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, headerKey: 'LanguageContentKeyListComponent_ContentType_gh', keyColumn: false })
    contentType: string;
    //#endregion contentType Prop

    //#region english Prop
    @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'LanguageContentKeyListComponent_ValueEnglish_gh', keyColumn: false })
    english: string;
    //#endregion english Prop

    //#region english Prop
    @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'LanguageContentKeyListComponent_ValueMobile_gh', keyColumn: false })
    mobile: string;
    //#endregion english Prop

    //#region french Prop
    @gridColumn({ parameter: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, headerKey: 'LanguageContentKeyListComponent_ValueFrench_gh', keyColumn: false })
    french: string;
    //#endregion french Prop

    //#region totalCount 
    @gridColumn({ parameter: false, name: "totalCount", visible: false, columnIndex: 7, keyColumn: false })
    totalCount: number;
    //#endregion totalCount

    //#region componentKeyId 
    @gridColumn({ parameter: false, name: "componentKeyId", visible: false, columnIndex: 8, keyColumn: false })
    componentKeyId: number;
    //#endregion componentKeyId

    

}