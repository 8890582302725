import { gridColumn } from "@rxweb/grid"


export class vGGLEmployeesLookUpBase {

//#region agreementTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'id', keyColumn: true})
        id : number;
//#endregion agreementTypeId Prop


//#region agreementTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion agreementTypeName Prop

}