import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSBrandLookUpBase {

//#region brandId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'brandId', keyColumn: true})
        brandId : number;
//#endregion brandId Prop


//#region brandName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'brandName', keyColumn: false})
        brandName : string;
//#endregion brandName Prop

}