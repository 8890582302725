import { prop } from '@rxweb/reactive-form-validators';

export class EventSendEInvitation{

    @prop({defaultValue:0})
    eventId:number;

    @prop({defaultValue:''})
    email:string;

    @prop({defaultValue:''})
    body:string;

    @prop({defaultValue:''})
    eventName:string;

    @prop()
    userId:number;

    @prop()
    userType:string;
}