import { prop,propObject,propArray,required,maxLength,range,numeric, pattern  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { IANTypeEnum } from "src/app/enums/i-a-n-type.enum";


export class TrackIncidentBase {

//#region incidentId Prop
        @prop()
        incidentId : number;
//#endregion incidentId Prop


//#region ref_No Prop
        @required()
        @maxLength({value:30})
        ref_No : string;
//#endregion ref_No Prop


//#region corporateId Prop
        @prop()
        corporateId : number;
//#endregion corporateId Prop

//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop


//#region supplierId Prop
        @prop()
        @required()
        supplierId : number;
//#endregion supplierId Prop


//#region occurrenceType Pro
        // @required()
        @prop({defaultValue:''})
        occurrenceType : any;
//#endregion occurrenceType Prop


//#region isOccurrenceinCompany Prop
        @prop({defaultValue:false})
        @required()
        isOccurrenceinCompany : boolean;
//#endregion isOccurrenceinCompany Prop


//#region occurrence_where Prop
        @prop()
        @maxLength({value:100})
        occurrence_where : string;
//#endregion occurrence_where Prop


//#region occurrence_date Prop
        @prop()
        @required()
        occurrence_date : Date;
//#endregion occurrence_date Prop


//#region occurrence_hours Prop
        @prop()
        @required()
        occurrence_hours : string;
//#endregion occurrence_hours Prop


//#region occurrence_mins Prop
        @prop()
        @required()
        occurrence_mins : string;
//#endregion occurrence_mins Prop


//#region occurrenceDescription Prop
        @prop()
        @required()
        // @maxLength({value:1000})
        occurrenceDescription : string;
//#endregion occurrenceDescription Prop


//#region actionTaken Prop
        @prop()
        // @maxLength({value:1000})
        actionTaken : string;
//#endregion actionTaken Prop


//#region actionTakenFollowup Prop
        @prop()
        // @maxLength({value:1000})
        actionTakenFollowup : string;
//#endregion actionTakenFollowup Prop


//#region preventiveRisk Prop
        @prop()
        //@maxLength({value:1000})
        preventiveRisk : string;
//#endregion preventiveRisk Prop


//#region notify_Team Prop
        @prop({defaultValue:false})
        @required()
        notify_Team : boolean;
//#endregion notify_Team Prop


//#region totalWorkinghrslost Prop
        @prop()
        totalWorkinghrslost : number;
//#endregion totalWorkinghrslost Prop


//#region createdOn Prop
        @prop()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @prop()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region updatedBy Prop
        @prop()
        updatedBy : number;
//#endregion updatedBy Prop


//#region active Prop
        @prop()
        active : boolean;
//#endregion active Prop


//#region deleted Prop
        @prop()
        deleted : boolean;
//#endregion deleted Prop


//#region addlLocationDetails Prop
        @prop()
        @maxLength({value:1000})
        addlLocationDetails : string;
//#endregion addlLocationDetails Prop


//#region hSEStatusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        hseStatusId : number;
//#endregion hSEStatusId Prop

//#region Latitude Prop
        @prop()
        latitude : number;

//#region Longitude Prop
        @prop()
        longitude : number;

//#region injuryCategoryType Prop
        @prop()
        injuryCategoryType : number;
//#region injuryCategoryType Prop

//#region isRestrictedForm Prop
        @prop({defaultValue: false})
        isRestrictedForm: boolean;
//#endregion isRestrictedForm Prop

//#region isInjuryOccur Prop
@prop({defaultValue:0})
isInjuryOccur : number;
//#endregion isInjuryOccur Prop

//#region isProperyDamange Prop
@prop({defaultValue:0})
isProperyDamange : number;
//#endregion isProperyDamange Prop

//#region isNearMiss Prop
@prop({defaultValue:0})
isNearMiss : number;
//#endregion isNearMiss Prop

//#region isNearMiss Prop
@prop({defaultValue:0})
isIntrusionOnLicenceArea : number;
//#endregion isNearMiss Prop

@prop()
@numeric({message:"Enter only number without decimal"})
@pattern({expression:{"intrusionOnLicenceAreaPeople":/^[0-9]\d{0,8}$/},message:"You can not enter value with more than 9 digits"})
//#region intrusionOnLicenceAreaPeople Prop
intrusionOnLicenceAreaPeople : string;
//#endregion intrusionOnLicenceAreaPeople Prop

//#region isIntrusionOnMiningPit Prop
@prop({defaultValue:0})
isIntrusionOnMiningPit : number;
//#endregion isIntrusionOnMiningPit Prop

//#region intrusionOnMiningPitPeople Prop
@prop()
@numeric({message:"Enter only number without decimal"})
@pattern({expression:{"intrusionOnMiningPitPeople":/^[0-9]\d{0,8}$/},message:"You can not enter value with more than 9 digits"})
intrusionOnMiningPitPeople : string;
//#endregion intrusionOnMiningPitPeople Prop

//#region isrecoverdGemstone Prop
@prop({defaultValue:0})
isrecoverdGemstone : number;
//#endregion isrecoverdGemstone Prop

//#region recoverdGemstoneGems Prop
@prop()
@numeric({message:"Enter only number without decimal"})
@pattern({expression:{"recoverdGemstoneGems":/^[0-9]\d{0,8}$/},message:"You can not enter value with more than 9 digits"})
recoverdGemstoneGems : string;
//#endregion recoverdGemstoneGems Prop

//#region recoverdGemstoneGrams Prop
@prop()
@pattern({expression:{"recoverdGemstoneGrams":/^[0-9]\d{0,6}(\.\d{1,4})??$/},message:"Please enter valid Grams(7 digits and 4 decimal places allowed)"})
recoverdGemstoneGrams : string;
//#endregion recoverdGemstoneGrams Prop

//#region isrecoverdGemBearingMaterial Prop
@prop({defaultValue:0})
isrecoverdGemBearingMaterial : number;
//#endregion isrecoverdGemBearingMaterial Prop

//#region recoverdGemBearingMaterialBags Prop
@prop()
@numeric({message:"Enter only number without decimal"})
@pattern({expression:{"recoverdGemBearingMaterialBags":/^[0-9]\d{0,8}$/},message:"You can not enter value with more than 9 digits"})
recoverdGemBearingMaterialBags : string;
//#endregion recoverdGemBearingMaterialBags Prop

//#region recoverdGemBearingMaterialKgs Prop
@prop()
@pattern({expression:{"recoverdGemBearingMaterialKgs":/^[0-9]\d{0,6}(\.\d{1,4})??$/},message:"Please enter valid Kgs(7 digits and 4 decimal places allowed)"})
recoverdGemBearingMaterialKgs : string;
//#endregion recoverdGemBearingMaterialKgs Prop

//#region isIllegalMinersSighted Prop
@prop({defaultValue:0})
isIllegalMinersSighted : number;
//#endregion isIllegalMinersSighted Prop

//#region illegalMinersSightedPeople Prop
@prop()
@numeric({message:"Enter only number without decimal"})
@pattern({expression:{"illegalMinersSightedPeople":/^[0-9]\d{0,8}$/},message:"You can not enter value with more than 9 digits"})
illegalMinersSightedPeople : string;
//#endregion illegalMinersSightedPeople Prop

//#region isIllegalMiningPitsOpened Prop
@prop({defaultValue:0})
isIllegalMiningPitsOpened : number;
//#endregion isIllegalMiningPitsOpened Prop

//#region illegalMiningPitsOpened Prop
@prop()
@numeric({message:"Enter only number without decimal"})
@pattern({expression:{"illegalMiningPitsOpened":/^[0-9]\d{0,8}$/},message:"You can not enter value with more than 9 digits"})
illegalMiningPitsOpened : string;
//#endregion illegalMiningPitsOpened Prop

//#region isRequireOfficialGrievance Prop
@prop({defaultValue:0})
isRequireOfficialGrievance : number;
//#endregion isRequireOfficialGrievance Prop

//#region grievanceRaisedDate Prop
@prop()
grievanceRaisedDate : Date;
//#endregion grievanceRaisedDate Prop

@prop({defaultValue:false})
isMailSent : boolean

//#region isStolenItem Prop
@prop({defaultValue:0})
isStolenItem : number;
//#endregion isStolenItem Prop

//#region stolenItems Prop
@prop()
@maxLength({value:500})
stolenItems : string;
//#endregion stolenItems Prop

//#region isAnySufferedFatality Prop
@prop({defaultValue:0})
isAnySufferedFatality : number;
//#endregion isAnySufferedFatality Prop


@prop({defaultValue:0})
isInternalAudit : number;
@prop({defaultValue:0})
isCourtFiled : number;
@prop({defaultValue:0})
isCourtCredible : number;
@prop({defaultValue:0})
isHseqPerformace : number;
@prop({defaultValue:0})
@required()
isDisturbing : number;
























}