import {vInvoiceStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vInvoiceStatusLookUp extends vInvoiceStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}