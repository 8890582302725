import { prop,propObject,propArray,required,maxLength,range, numeric  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CallMeBase {

//#region callMeId Prop
        @prop()
        callMeId : number;
//#endregion callMeId Prop


//#region userId Prop
        @required()
        userId : number;
//#endregion userId Prop


//#region phone Prop
        @required()
        //@numeric()
        //@maxLength({value:50})
        phone : string;
//#endregion phone Prop


//#region salesAdvisorComment Prop
        @maxLength({value:255})
        salesAdvisorComment : string;
//#endregion salesAdvisorComment Prop


//#region createdOn Prop
        @prop()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @prop()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region isCompleted Prop
        @prop()
        isCompleted : boolean;
//#endregion isCompleted Prop


//#region email Prop
        @maxLength({value:50})
        email : string;
//#endregion email Prop


//#region firstName Prop
        @maxLength({value:100})
        firstName : string;
//#endregion firstName Prop


//#region lastName Prop
        @maxLength({value:100})
        lastName : string;
//#endregion lastName Prop


//#region message Prop
        @prop()
        message : string;
//#endregion message Prop


//#region countryId Prop
        @prop()
        countryId : number;
//#endregion countryId Prop


//#region productVariantId Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop

        @maxLength({value:250})
        fullName : string;

//#region customerIP Prop
        @maxLength({value:50})
        customerIP : string;
//#endregion customerIP Prop

        //#region orderId Prop
        @prop({defaultValue:0})
        orderId : number;
        //#endregion orderId Prop
}