<div class="modal-dialog modal-lg" *ngIf="showComponent==true">
  <div class="modal-content" *rxMultilingual="">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" *ngIf="!isSupplier">Edit Corporate/Groups</h4>
      <h4 class="modal-title mb-0" *ngIf="isSupplier">Edit People/Directors</h4>

      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="ml-4 modal-body pb-0 ibox-content bg-white d-flex flex-column flex-grow-1"
      [formGroup]="customerCompanyEditFormGroup">
      <div class="form-group row" *ngIf="!isSupplier">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="CorporateGroupsAddEditComponent_Name_t"></label>
        <div class="col-md-6">
          <!-- <rx-select [disabled]="true" [(source)]="employmentData.companyLookUp" mainClass="form-control" formControlName="companyId" [keyValueProps]="['company','supplierId']"  [disabled]="true"></rx-select> -->
          <!-- <input type="text" class="form-control" formControlName="company" disabled /> -->
          <label type="text" class="form-control disableLabel mb-0">{{(customerCompanyEditFormGroup.value.company
            ===
            null ||
            customerCompanyEditFormGroup.value.company ===
            undefined ||
            customerCompanyEditFormGroup.value.company === '')
            ? '' :
            customerCompanyEditFormGroup.value.company}}</label>
        </div>
      </div>
      <div class="form-group row" *ngIf="isSupplier">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="CorporateGroupsAddEditComponent_Name_t"></label>
        <div class="col-md-6">
          <!-- <input type="text" class="form-control" formControlName="fullName" disabled /> -->
          <label type="text" class="form-control disableLabel mb-0">{{(customerCompanyEditFormGroup.value.fullName
            ===
            null ||
            customerCompanyEditFormGroup.value.fullName ===
            undefined ||
            customerCompanyEditFormGroup.value.fullName === '')
            ? '' :
            customerCompanyEditFormGroup.value.fullName}}</label>
        </div>
      </div>
      <div class="form-group row" *ngIf="!isDirector">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="CorporateGroupsAddEditComponent_JobTitle_t"></label>
        <div class="col-md-6">
          <input type="text" class="form-control" formControlName="jobTitle"
            rxPlaceholder="CorporateGroupsAddEditComponent_JobTitle_p" />
        </div>
      </div>
      <!-- <div class="form-group row check-group">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Director</label>
          <div class="col-md-auto d-flex align-items-center">
            <div class="i-checks">
              <label class="mb-0">
                <app-checkbox [checkFormControl]="customerCompanyEditFormGroup.controls.isDirector" class="col-md-auto d-flex align-items-center" [disabled]="true"></app-checkbox>
              </label>
            </div>
          </div>
        </div> -->
      <div class="form-group row" *ngIf="isDirector">
        <label class="col-md-4 col-xl-4 col-form-label"
          rxText="CorporateGroupsAddEditComponent_StartDate_t"></label>
        <div class="col-md-6">
          <div class="" id="data_1">
            <div class="input-group date">
              <rx-date showAddon="true" formControlName="startDate" placeholder="Select Date"
                pickerClass="form-control"></rx-date>

            </div>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="isDirector">
        <label class="col-md-4 col-xl-4 col-form-label"
          rxText="CorporateGroupsAddEditComponent_EndDate_t"></label>
        <div class="col-md-6">
          <div class="" id="data_1">
            <div class="input-group date">
              <rx-date showAddon="true" formControlName="endDate" placeholder="Select Date" pickerClass="form-control">
              </rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">End Date should be greater than Start
                Date<br /></small>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-group row check-group">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Employment Active</label>
          <div class="col-md-auto d-flex align-items-center">
            <div class="i-checks">
              <label class="mb-0">
                <app-checkbox class="col-md-auto d-flex align-items-center" readonly="true"></app-checkbox>
              </label>
            </div>
          </div>
        </div> -->
      <div class="modal-footer">
        <button (click)="EditCorporate()" class="btn btn-success mr-1">Save</button>
        <button type="button" class="btn btn-outline-primary" (click)="hide()">Close</button>
      </div>
    </div>

  </div>
</div>