import {GemfieldsEnhancementTypeBase,} from '@app/database-models'
//Generated Imports
export class GemfieldsEnhancementType extends GemfieldsEnhancementTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}