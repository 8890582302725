<div *rxMultilingual="">
  <div *ngIf="isPopupComponent">
    <div *ngIf="showComponent" class="modal fade show" id="address_edit" style="padding-right: 17px; display: block;"
    tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" *ngIf="showComponent" [rxLocalisationInit]="componentName">
      <div class="modal-content">
        <div class="modal-header text-left">
          <h4 class="modal-title mb-0" rxText="LeaveMasterAddComponent_Apply_Leave_t"></h4>
          <button type="button" class="close py-2" (click)="hide()">
            <span aria-hidden="true" [title]="titleClose">×</span>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body pb-0" [formGroup]="leaveMasterFormGroup" id="leaveMasterFormGroup"
          [rxSpinner]="spin">
          <div class="form-group row" *ngIf="masterId > 0" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_Name_t">
            </label>
            <div class="col-md-8">
              <!-- <input type="text" class="form-control" formControlName="leaveUserFullName"
            [readonly]="true"> -->
              <label type="text"
                class="form-control disableLabel-withoutheight mb-0"> {{(leaveMasterFormGroup.value.leaveUserFullName ==
                null
                || leaveMasterFormGroup.value.leaveUserFullName == undefined ||
                leaveMasterFormGroup.value.leaveUserFullName
                == '') ? '' : leaveMasterFormGroup.value.leaveUserFullName}}</label>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.leaveUserFullName.errors">{{leaveMasterFormGroup.controls.leaveUserFullName.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMApplyFor_t">
            </label>
            <div class="col-md-8">
              <!-- <select class="form-control" formControlName="leaveType"
            (change)="changeLeaveType($event.target.value); getApplyLeaveLookups()"
            [attr.disabled]="isReadOnly ? '' : null" rxFocus>
            <option *ngFor="let item of leaveLookUps.vLeaveTypeLookUp" [value]=item.leaveId>
              {{item.leaveTypeName}}
            </option>
          </select> -->
              <ng-container *ngIf="!isReadOnly" [rxSelectExtended]="_this">
              <rx-select  [(source)]="leaveLookUps.vLeaveTypeLookUp" #rxSelect mainClass="form-control"
                (changed)="changeLeaveType($event.item.key); getApplyLeaveLookups()" formControlName="leaveType"
                [keyValueProps]="['leaveTypeName','leaveId']" [selectDisabled]="isReadOnly"
                [selectPlaceholder]="'Select Apply For'" rxFocus>
              </rx-select>
            </ng-container>
              <div class="input-group">
                <label *ngIf="isReadOnly" type="text"
                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(leaveType == null ||
                  leaveType == undefined ||
                  leaveType == '') ? 'Select Apply For' : leaveType}}</label>
                <span *ngIf="isReadOnly" class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-spinner fa-pulse d-none"></i>
                    <i class="fa fa-caret-down"></i>
                  </span>
                </span>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.leaveType.errors">{{leaveMasterFormGroup.controls.leaveType.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row" *ngIf="(selectLeaveType == 8 && isVisibleTravelType)" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMTravelType_t"></label>
            <div class="col-md-8">
              <div class="row col-md-8 d-flex justify-content-between align-items-center">
                <!-- <div class="col-md-6 d-flex align-items-center">
              <input type="radio" class="form-control w-auto" name="isDomesticTravel" [value]="true"
                formControlName="isDomesticTravel" [attr.disabled]="isReadOnly ? true : null"><label class="mb-0 ml-1"
                rxText="LeaveMasterAddComponent_LMDomesticTravel_t"></label>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <input type="radio" class="form-control w-auto" name="isDomesticTravel" [value]="false"
                formControlName="isDomesticTravel" [attr.disabled]="isReadOnly ? true : null"><label class="mb-0 ml-1"
                rxText="LeaveMasterAddComponent_LMForeignTravel_t"></label>
            </div> -->
                <div class="i-checks mr-2" [rxLocalisationInit]="componentName">
                  <label class="mb-0">
                    <div class="iradio_square-green" [class.checked]="leaveMasterFormGroup.value.isDomesticTravel==true"
                      [attr.style]="radioFilter">
                      <input type="radio" [value]="true" [attr.disabled]="isReadOnly ? true : null"
                        formControlName="isDomesticTravel" style="visibility: hidden;">
                    </div>
                    <label class="mb-0 ml-1" rxText="LeaveMasterAddComponent_LMDomesticTravel_t"></label>
                  </label>
                </div>

                <div class="i-checks" [rxLocalisationInit]="componentName">
                  <label class="mb-0">
                    <div class="iradio_square-green"
                      [class.checked]="leaveMasterFormGroup.value.isDomesticTravel==false" [attr.style]="radioFilter">
                      <input type="radio" [value]="false" [attr.disabled]="isReadOnly ? true : null"
                        formControlName="isDomesticTravel" style="visibility: hidden;">
                    </div>
                    <label class="mb-0 ml-1" rxText="LeaveMasterAddComponent_LMForeignTravel_t"></label>
                  </label>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-8 d-flex justify-content-between align-items-center">
          <div class="i-checks">
            <label class="mb-0">
              <div class="iradio_square-green" [class.checked]="leaveMasterFormGroup.value.isDomesticTravel == 1"
                style="position: relative;">
                <input type="radio" value="1" formControlName="isDomesticTravel" style="visibility: hidden;" [attr.disabled]="isReadOnly ? '' : null">
              </div><label class="ml-2">Domestic Travel</label>
            </label>
          </div>
          <div class="i-checks mr-5">
            <label class="mb-0">
              <div class="iradio_square-green" [class.checked]="leaveMasterFormGroup.value.isDomesticTravel == 0"
                style="position: relative;">
                <input type="radio" value="0" formControlName="isDomesticTravel" style="visibility: hidden;" [attr.disabled]="isReadOnly ? '' : null">
              </div><label class="ml-2">Foreign Travel</label>
            </label>
          </div>
        </div> -->
          </div>


          <div class="form-group row" *ngIf="hideUpdateButton" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMStartDate_t">
            </label>
            <div class="col-md-8">
              <!-- <input type="date" class="form-control" formControlName="startDate"
                (change)="getApplyLeaveLookups()"
                [readonly]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl)"> -->
              <!-- <rx-date showAddon="true" formControlName="startDate" placeholder="" pickerClass="form-control"
            (change)="getApplyLeaveLookups()"></rx-date> -->
              <rx-date
                *ngIf="!(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                showAddon="true" formControlName="startDate" pickerClass="form-control"
                (onSelected)="getApplyLeaveLookups('startDate')"
                [pickerDisabled]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl) ? true : false">
              </rx-date>
              <div class="input-group">
                <label
                  *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                  type="text"
                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(leaveMasterFormGroup.value.startDate
                  == null ||
                  leaveMasterFormGroup.value.startDate == undefined || leaveMasterFormGroup.value.startDate == '') ? ''
                  :
                  leaveMasterFormGroup.value.startDate | date: 'dd/MMM/yyyy'}}</label>
                <div
                  *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                  class="input-group-append"><span class="input-group-text"><i class="fa fa-calendar"></i></span></div>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.startDate.errors">{{leaveMasterFormGroup.controls.startDate.errorMessage}}</small>
            </div>
          </div>


          <div class="form-group row" *ngIf="!hideUpdateButton" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMStartDate_t">
            </label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="startDate" pickerClass="form-control"
                (onSelected)="getApplyLeaveLookups('startDate')">
              </rx-date>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.startDate.errors">{{leaveMasterFormGroup.controls.startDate.errorMessage}}</small>
            </div>
          </div>



          <div class="form-group row" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMStartSession_t">
            </label>
            <div class="col-md-8">
              <!-- <select formControlName="startSession" class="form-control"
            (change)="getApplyLeaveLookups()"
            [attr.disabled]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl) ? '' : null">
            <option value="1">A.M.</option>
            <option value="2">P.M.</option>
          </select> -->
              <ng-container *ngIf="(showUpdateButton || showEditButton || isAddLeave)" [rxSelectExtended]="_this">
              <rx-select  [(source)]="timeZoneLookUp" #rxSelect
                mainClass="form-control" [selectDisabled]="!(showUpdateButton || showEditButton || isAddLeave)"
                formControlName="startSession" [keyValueProps]="['value','id']" (changed)="getApplyLeaveLookups()"
                [selectPlaceholder]="'Select Start Session'">
              </rx-select>
            </ng-container>
              <div class="input-group">
                <label *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" type="text"

                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">
                  {{(startSession == null || startSession == undefined || startSession == '') ? '' : startSession}}
                </label>
                <span *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-spinner fa-pulse d-none"></i>
                    <i class="fa fa-caret-down"></i>
                  </span>
                </span>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.startSession.errors">{{leaveMasterFormGroup.controls.startSession.errorMessage}}</small>
            </div>
          </div>


          <div class="form-group row" *ngIf="hideUpdateButton" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMEndDate_t">
            </label>
            <div class="col-md-8">
              <!-- <input type="date" class="form-control" formControlName="endDate"
                (change)="getApplyLeaveLookups()"
                [readonly]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl)"> -->
              <rx-date
                *ngIf="!(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                showAddon="true" formControlName="endDate" pickerClass="form-control"
                (onSelected)="getApplyLeaveLookups('endDate');"
                [pickerDisabled]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl) ? true : false">
              </rx-date>
              <div class="input-group">
                <label
                  *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                  type="text"
                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(leaveMasterFormGroup.value.endDate
                  == null ||
                  leaveMasterFormGroup.value.endDate == undefined || leaveMasterFormGroup.value.endDate == '') ? '' :
                  leaveMasterFormGroup.value.endDate | date: 'dd/MMM/yyyy'}}</label>
                <div
                  *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                  class="input-group-append"><span class="input-group-text"><i class="fa fa-calendar"></i></span></div>
              </div>
              <!-- <rx-date showAddon="true" formControlName="endDate" placeholder="" pickerClass="form-control"
                (change)="getApplyLeaveLookups()"></rx-date> -->
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.endDate.errors">{{leaveMasterFormGroup.controls.endDate.errorMessage}}</small>
              <small class="form-text text-danger mt-2" *ngIf="dateError">
                End Date should be greater than Start
                Date
              </small>
            </div>
          </div>

          <div class="form-group row" *ngIf="!hideUpdateButton" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMEndDate_t">
            </label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="endDate" pickerClass="form-control"
                (onSelected)="getApplyLeaveLookups('endDate');">
              </rx-date>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.endDate.errors">{{leaveMasterFormGroup.controls.endDate.errorMessage}}</small>
              <small class="form-text text-danger mt-2" *ngIf="dateError">
                End Date should be greater than Start
                Date
              </small>
            </div>
          </div>


          <div class="form-group row" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMEndSession_t">
            </label>
            <div class="col-md-8">
              <!-- <select formControlName="endSession" class="form-control"
            (change)="getApplyLeaveLookups()"
            [attr.disabled]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl) ? '' : null">
            <option value="1">A.M.</option>
            <option value="2">P.M.</option>
          </select> -->
          <ng-container *ngIf="(showUpdateButton || showEditButton || isAddLeave)" [rxSelectExtended]="_this">
              <rx-select  [(source)]="timeZoneLookUp" #rxSelect
                mainClass="form-control" [selectDisabled]="!(showUpdateButton || showEditButton || isAddLeave)"
                formControlName="endSession" [keyValueProps]="['value','id']" (changed)="getApplyLeaveLookups()"
                [selectPlaceholder]="'Select End Session'">
              </rx-select>
            </ng-container>
              <div class="input-group">
                <label *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" type="text"

                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(endSession == null ||
                  endSession == undefined ||
                  endSession == '') ? '' : endSession}}</label>
                <span *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-spinner fa-pulse d-none"></i>
                    <i class="fa fa-caret-down"></i>
                  </span>
                </span>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.endSession.errors">{{leaveMasterFormGroup.controls.endSession.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMDays_t"></label>
            <div class="col-md-8">
              <!-- <input type="text" class="form-control" formControlName="days"
            [readonly]="true"> -->
              <label type="text" class="form-control disableLabel mb-0"
                id="lblDays">{{(leaveMasterFormGroup.value.days == null ||
                leaveMasterFormGroup.value.days == undefined) ? '' :
                leaveMasterFormGroup.value.days}}</label>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.days.errors">{{leaveMasterFormGroup.controls.days.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row mb-4" *ngIf="selectLeaveType == 1" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMBalance_t"></label>
            <div class="col-md-8">
              <!-- <input type="text" class="form-control" formControlName="balance"
            [readonly]="true"> -->
              <label type="text"
                class="form-control disableLabel mb-0">{{(leaveMasterFormGroup.value.balance == null ||
                leaveMasterFormGroup.value.balance == undefined) ? '' :
                leaveMasterFormGroup.value.balance}}</label>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.balance.errors">{{leaveMasterFormGroup.controls.balance.errorMessage}}</small>
              <span class="form-text text-danger" style="margin-bottom: -25px;">{{multipleYearBalance}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMApplyTo_t">
            </label>
            <div class="col-md-8">
              <!-- <textarea class="form-control" formControlName="reportToName"
            [readonly]="true"></textarea> -->
              <label type="text"
                class="form-control disableLabel mb-0">{{(leaveMasterFormGroup.value.reportToName == null ||
                leaveMasterFormGroup.value.reportToName == undefined || leaveMasterFormGroup.value.reportToName == '') ?
                ''
                : leaveMasterFormGroup.value.reportToName}}</label>
              <!-- <small class="form-text text-danger"
            [class.d-block]="leaveMasterFormGroup.controls.managerID.errors">{{leaveMasterFormGroup.controls.reportToIds.errorMessage}}</small> -->
            </div>
          </div>

          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType == 8" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMCurrency_t"></label>
            <div class="col-md-8">
              <!-- <select formControlName="currency" class="form-control"
            [attr.disabled]="isReadOnly ? '' : null">
            <option value="" [selected]="defaultCurrencySelected">Select Currency</option>
            <option *ngFor="let item of leaveLookUps.vCurrencyLookUp" [value]=item.currencyId>
              {{item.currencyName}}
            </option>
          </select> -->
          <ng-container *ngIf="!isReadOnly" [rxSelectExtended]="_this">
              <rx-select  [(source)]="leaveLookUps.vCurrencyLookUp" #rxSelect mainClass="form-control"
                [selectDisabled]="isReadOnly" formControlName="currency" [keyValueProps]="['currencyName','currencyId']"
                [selectPlaceholder]="'Select Currency'">
              </rx-select>
            </ng-container>
              <div class="input-group">
                <label *ngIf="isReadOnly" type="text"
                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(currency == null ||
                  currency == undefined ||
                  currency == '') ? '' : currency}}</label>
                <span *ngIf="isReadOnly" class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-spinner fa-pulse d-none"></i>
                    <i class="fa fa-caret-down"></i>
                  </span>
                </span>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.currency.errors">{{leaveMasterFormGroup.controls.currency.errorMessage}}</small>
            </div>
          </div>

          <!-- <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType == 8">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
            rxText="LeaveMasterAddComponent_LMBudget_t">
          </label>
          <div class="col-md-8">
            <input *ngIf="!(travelLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)" type="text"
              class="form-control" formControlName="budget"
              [readonly]="(travelLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
              rxPlaceholder="LeaveMasterAddComponent_LMBudget_p">
            <label *ngIf="(travelLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)" type="text"

              class="form-control disableLabel-withoutheight mb-0">{{(leaveMasterFormGroup.value.budget == null ||
              leaveMasterFormGroup.value.budget == undefined) ? 'Enter Budget'
              : leaveMasterFormGroup.value.budget}}</label>
            <small class="form-text text-danger"
              [class.d-block]="leaveMasterFormGroup.controls.budget.errors">{{leaveMasterFormGroup.controls.budget.errorMessage}}</small>
          </div>
        </div> -->

          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType == 8" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMBudget_t">
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="budget"
                [readonly]="hideUpdateButton" rxPlaceholder="LeaveMasterAddComponent_LMBudget_p">
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.budget.errors">{{leaveMasterFormGroup.controls.budget.errorMessage}}</small>
            </div>
          </div>



          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType == 8" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMEvents_t"></label>
            <div class="col-md-8">
              <fieldset [rxTagExtended]="_this" *ngIf="isSourceChanged">
                <rx-tag  [tagDisabled]="hideUpdateButton" [(source)]="eventLookUp" #rxTag
                  formControlName="eventIds" [attr.mainClass]="hideUpdateButton?'form-control disabled-drop-down':'form-control'" [keyValueProps]="['eventname','eventid']"
                  placeholder="Select Event"></rx-tag>
              </fieldset>
            </div>
          </div>

          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType == 8" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMProject_t"></label>
            <div class="col-md-8">
              <fieldset [rxTagExtended]="_this" *ngIf="isSourceChanged">
                <rx-tag  [tagDisabled]="hideUpdateButton" [(source)]="projectLookUp" #rxTag
                  formControlName="projectIds" [attr.mainClass]="hideUpdateButton?'form-control disabled-drop-down':'form-control'" [keyValueProps]="['projectName','projectId']"
                  placeholder="Select Project">
                </rx-tag>
              </fieldset>
            </div>
          </div>
          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType != 1" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMAttachment_t"></label>
            <div class="col-md-8" *ngIf="masterId==0">
              <!--<input type="file" class="form-control" formControlName="attachment"
          [attr.disabled]="isReadOnly ? '' : null">-->
              <app-file [fileViewModel]="fileViewModel" (files)="updateFiles($event)"></app-file>
              <small class="form-text mt-2"
                *ngIf="leaveMasterFormGroup.value.fileName">{{leaveMasterFormGroup.value.fileName}}</small>
            </div>
            <div class="col-md-8" *ngIf='masterId > 0'>
              <a href="{{leaveMaster.fileUrl}}" target="_blank">{{leaveMaster.attachment}}</a>
            </div>
          </div>

          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType == 8" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMCitiesMinesLocations_t"></label>
            <div class="col-md-8">
              <textarea *ngIf="!isReadOnly" class="form-control" formControlName="citiesMinesLocations"
                [readonly]="isReadOnly"
                rxPlaceholder="LeaveMasterAddComponent_LMCitiesMinesLocations_p"></textarea>
              <label *ngIf="isReadOnly" type="text"
                class="form-control disableLabel mb-0">{{(leaveMasterFormGroup.value.citiesMinesLocations == null ||
                leaveMasterFormGroup.value.citiesMinesLocations == undefined ||
                leaveMasterFormGroup.value.citiesMinesLocations == '') ? 'Enter Location' :
                leaveMasterFormGroup.value.citiesMinesLocations}}</label>
              <small class="form-text text-danger"
                [class.d-block]="leaveMasterFormGroup.controls.citiesMinesLocations.errors">{{leaveMasterFormGroup.controls.citiesMinesLocations.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row" *ngIf="selectLeaveType > 0 && selectLeaveType != 1" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="LeaveMasterAddComponent_LMReason_t">
            </label>
            <div class="col-md-8">
              <textarea class="form-control" formControlName="reason"
                [readonly]="hideUpdateButton" rxPlaceholder="LeaveMasterAddComponent_LMReason_p"></textarea>
            </div>
          </div>
          <div class="form-group row" *ngIf="status != ''">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Status
            </label>
            <div class="col-md-8">
              <label type="text" class="col-form-label">{{status}}</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button id="btnSave" type="button" class="btn btn-success" (click)="addLeave()" *ngIf="masterId == 0" [rxLocalisationInit]="componentName"
            rxText="LeaveMasterAddComponent_Save_t"></button>
          <button type="button" class="btn btn-success" (click)="updateLeave(1)" [rxLocalisationInit]="componentName"
            *ngIf="masterId > 0 && showApproveButton" rxText="LeaveMasterAddComponent_Approve_t"></button>
          <button type="button" class="btn btn-success" (click)="updateLeave(2)" [rxLocalisationInit]="componentName"
            *ngIf="masterId > 0 && showUpdateButton" rxText="LeaveMasterAddComponent_Update_t"></button>
          <button type="button" class="btn btn-success" (click)="updateLeave(3)" *ngIf="masterId > 0 && showEditButton" [rxLocalisationInit]="componentName"
            rxText="LeaveMasterAddComponent_Re_Submit_t"></button>
          <button type="button" class="btn btn-danger" (click)="updateLeave(4)" *ngIf="masterId > 0 && showRejectButton" [rxLocalisationInit]="componentName"
            rxText="LeaveMasterAddComponent_Reject_t"></button>
          <button type="button" class="btn btn-danger" (click)="updateLeave(5)" *ngIf="masterId > 0 && showCancelButton" [rxLocalisationInit]="componentName"
            rxText="LeaveMasterAddComponent_Cancel_t"></button>
          <button id="btnClose" type="button" class="btn btn-outline-primary" (click)="hide()" [rxLocalisationInit]="componentName"
            rxText="Btn_Close_t"></button>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf="!isPopupComponent">
    <div *ngIf="showComponent" style="padding-right: 17px; display: block;">
      <div *ngIf="showComponent" [rxLocalisationInit]="componentName">
        

          <div class=" pb-0" [formGroup]="leaveMasterFormGroup" id="leaveMasterFormGroup" [rxSpinner]="spin">

            <div class="row">
              <div class="col-md-6" *ngIf="masterId > 0">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddComponent_Name_t">
                  </label>
                  <div class="col-md-8">
                    <label type="text" class="form-control disableLabel-withoutheight mb-0">
                      {{(leaveMasterFormGroup.value.leaveUserFullName ==
                      null
                      || leaveMasterFormGroup.value.leaveUserFullName == undefined ||
                      leaveMasterFormGroup.value.leaveUserFullName
                      == '') ? '' : leaveMasterFormGroup.value.leaveUserFullName}}</label>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.leaveUserFullName.errors">{{leaveMasterFormGroup.controls.leaveUserFullName.errorMessage}}</small>
                  </div>
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMApplyFor_t">
                  </label>
                  <div class="col-md-8">
                    <ng-container *ngIf="!isReadOnly" [rxSelectExtended]="_this">
                      <rx-select [(source)]="leaveLookUps.vLeaveTypeLookUp" #rxSelect mainClass="form-control"
                        (changed)="changeLeaveType($event.item.key); getApplyLeaveLookups()" formControlName="leaveType"
                        [keyValueProps]="['leaveTypeName','leaveId']" [selectDisabled]="isReadOnly"
                        [selectPlaceholder]="'Select Apply For'" rxFocus>
                      </rx-select>
                    </ng-container>
                    <div class="input-group">
                      <label *ngIf="isReadOnly" type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(leaveType ==
                        null
                        ||
                        leaveType == undefined ||
                        leaveType == '') ? 'Select Apply For' : leaveType}}</label>
                      <span *ngIf="isReadOnly" class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-spinner fa-pulse d-none"></i>
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.leaveType.errors">{{leaveMasterFormGroup.controls.leaveType.errorMessage}}</small>
                  </div>
                </div>

              </div>
              <div class="col-md-6" *ngIf="(selectLeaveType == 8 && isVisibleTravelType)">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-form-label" rxText="LeaveMasterAddComponent_LMTravelType_t"></label>
                  <div class="col-md-8">
                    <div class="row col-md-12 d-flex justify-content-between align-items-center">
                      <div class="i-checks mr-2" [rxLocalisationInit]="componentName">
                        <label class="mb-0">
                          <div class="iradio_square-green"
                            [class.checked]="leaveMasterFormGroup.value.isDomesticTravel==true"
                            [attr.style]="radioFilter">
                            <input type="radio" [value]="true" [attr.disabled]="isReadOnly ? true : null"
                              formControlName="isDomesticTravel" style="visibility: hidden;">
                          </div>
                          <label class="mb-0 ml-1" rxText="LeaveMasterAddComponent_LMDomesticTravel_t"></label>
                        </label>
                      </div>

                      <div class="i-checks" [rxLocalisationInit]="componentName">
                        <label class="mb-0">
                          <div class="iradio_square-green"
                            [class.checked]="leaveMasterFormGroup.value.isDomesticTravel==false"
                            [attr.style]="radioFilter">
                            <input type="radio" [value]="false" [attr.disabled]="isReadOnly ? true : null"
                              formControlName="isDomesticTravel" style="visibility: hidden;">
                          </div>
                          <label class="mb-0 ml-1" rxText="LeaveMasterAddComponent_LMForeignTravel_t"></label>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div class="col-md-6" *ngIf="hideUpdateButton">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMStartDate_t">
                  </label>
                  <div class="col-md-4">
                    <rx-date
                      *ngIf="!(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                      showAddon="true" formControlName="startDate" pickerClass="form-control"
                      (onSelected)="getApplyLeaveLookups('startDate')"
                      [pickerDisabled]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl) ? true : false">
                    </rx-date>
                    <div class="input-group">
                      <label
                        *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                        type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(leaveMasterFormGroup.value.startDate
                        == null ||
                        leaveMasterFormGroup.value.startDate == undefined || leaveMasterFormGroup.value.startDate == '')
                        ?
                        ''
                        :
                        leaveMasterFormGroup.value.startDate | date: 'dd/MMM/yyyy'}}</label>
                      <div
                        *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                        class="input-group-append"><span class="input-group-text"><i class="fa fa-calendar"></i></span>
                      </div>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.startDate.errors">{{leaveMasterFormGroup.controls.startDate.errorMessage}}</small>
                  </div>
                  <div class="col-md-4">
                    <ng-container *ngIf="(showUpdateButton || showEditButton || isAddLeave)" [rxSelectExtended]="_this">
                      <rx-select [(source)]="timeZoneLookUp" #rxSelect mainClass="form-control"
                        [selectDisabled]="!(showUpdateButton || showEditButton || isAddLeave)"
                        formControlName="startSession" [keyValueProps]="['value','id']"
                        (changed)="getApplyLeaveLookups()" [selectPlaceholder]="'Select Start Session'">
                      </rx-select>
                    </ng-container>
                    <div class="input-group">
                      <label *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">
                        {{(startSession == null || startSession == undefined || startSession == '') ? '' :
                        startSession}}
                      </label>
                      <span *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-spinner fa-pulse d-none"></i>
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.startSession.errors">{{leaveMasterFormGroup.controls.startSession.errorMessage}}</small>
                  </div>
                </div>

              </div>


              <div class="col-md-6" *ngIf="!hideUpdateButton">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMStartDate_t">
                  </label>
                  <div class="col-md-4">
                    <rx-date showAddon="true" formControlName="startDate" pickerClass="form-control"
                      (onSelected)="getApplyLeaveLookups('startDate')">
                    </rx-date>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.startDate.errors">{{leaveMasterFormGroup.controls.startDate.errorMessage}}</small>
                  </div>
                  <div class="col-md-4">
                    <ng-container *ngIf="(showUpdateButton || showEditButton || isAddLeave)" [rxSelectExtended]="_this">
                      <rx-select [(source)]="timeZoneLookUp" #rxSelect mainClass="form-control"
                        [selectDisabled]="!(showUpdateButton || showEditButton || isAddLeave)"
                        formControlName="startSession" [keyValueProps]="['value','id']"
                        (changed)="getApplyLeaveLookups()" [selectPlaceholder]="'Select Start Session'">
                      </rx-select>
                    </ng-container>
                    <div class="input-group">
                      <label *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">
                        {{(startSession == null || startSession == undefined || startSession == '') ? '' :
                        startSession}}
                      </label>
                      <span *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-spinner fa-pulse d-none"></i>
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.startSession.errors">{{leaveMasterFormGroup.controls.startSession.errorMessage}}</small>
                  </div>
                </div>

              </div>

              <div class="col-md-6" *ngIf="hideUpdateButton">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddComponent_LMEndDate_t">
                  </label>
                  <div class="col-md-4">
                    <rx-date
                      *ngIf="!(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                      showAddon="true" formControlName="endDate" pickerClass="form-control"
                      (onSelected)="getApplyLeaveLookups('endDate');"
                      [pickerDisabled]="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl) ? true : false">
                    </rx-date>
                    <div class="input-group">
                      <label
                        *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                        type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(leaveMasterFormGroup.value.endDate
                        == null ||
                        leaveMasterFormGroup.value.endDate == undefined || leaveMasterFormGroup.value.endDate == '') ?
                        ''
                        :
                        leaveMasterFormGroup.value.endDate | date: 'dd/MMM/yyyy'}}</label>
                      <div
                        *ngIf="(annualLeaveReadOnlyControl || travelLeaveReadOnlyControl || otherLeaveReadOnlyControl || travelLeaveApprovedReadOnlyControl)"
                        class="input-group-append"><span class="input-group-text"><i class="fa fa-calendar"></i></span>
                      </div>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.endDate.errors">{{leaveMasterFormGroup.controls.endDate.errorMessage}}</small>
                    <small class="form-text text-danger mt-2" *ngIf="dateError">
                      End Date should be greater than Start
                      Date
                    </small>
                  </div>
                  <div class="col-md-4">
                    <ng-container *ngIf="(showUpdateButton || showEditButton || isAddLeave)" [rxSelectExtended]="_this">
                      <rx-select [(source)]="timeZoneLookUp" #rxSelect mainClass="form-control"
                        [selectDisabled]="!(showUpdateButton || showEditButton || isAddLeave)"
                        formControlName="endSession" [keyValueProps]="['value','id']" (changed)="getApplyLeaveLookups()"
                        [selectPlaceholder]="'Select End Session'">
                      </rx-select>
                    </ng-container>
                    <div class="input-group">
                      <label *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(endSession ==
                        null ||
                        endSession == undefined ||
                        endSession == '') ? '' : endSession}}</label>
                      <span *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-spinner fa-pulse d-none"></i>
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.endSession.errors">{{leaveMasterFormGroup.controls.endSession.errorMessage}}</small>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="!hideUpdateButton">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddComponent_LMEndDate_t">
                  </label>
                  <div class="col-md-4">
                    <rx-date showAddon="true" formControlName="endDate" pickerClass="form-control"
                      (onSelected)="getApplyLeaveLookups('endDate');">
                    </rx-date>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.endDate.errors">{{leaveMasterFormGroup.controls.endDate.errorMessage}}</small>
                    <small class="form-text text-danger mt-2" *ngIf="dateError">
                      End Date should be greater than Start
                      Date
                    </small>
                  </div>

                  <div class="col-md-4">
                    <ng-container *ngIf="(showUpdateButton || showEditButton || isAddLeave)" [rxSelectExtended]="_this">
                      <rx-select [(source)]="timeZoneLookUp" #rxSelect mainClass="form-control"
                        [selectDisabled]="!(showUpdateButton || showEditButton || isAddLeave)"
                        formControlName="endSession" [keyValueProps]="['value','id']" (changed)="getApplyLeaveLookups()"
                        [selectPlaceholder]="'Select End Session'">
                      </rx-select>
                    </ng-container>
                    <div class="input-group">
                      <label *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(endSession ==
                        null ||
                        endSession == undefined ||
                        endSession == '') ? '' : endSession}}</label>
                      <span *ngIf="!(showUpdateButton || showEditButton || isAddLeave)" class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-spinner fa-pulse d-none"></i>
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.endSession.errors">{{leaveMasterFormGroup.controls.endSession.errorMessage}}</small>
                  </div>

                </div>

              </div>

              <div class="col-md-6">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMDays_t"></label>
                  <div class="col-md-8">
                    <label type="text" class="form-control disableLabel mb-0"
                      id="lblDays">{{(leaveMasterFormGroup.value.days == null ||
                      leaveMasterFormGroup.value.days == undefined) ? '' :
                      leaveMasterFormGroup.value.days}}</label>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.days.errors">{{leaveMasterFormGroup.controls.days.errorMessage}}</small>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="selectLeaveType == 1">
                <div class="form-group row mb-4" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMBalance_t"></label>
                  <div class="col-md-8">
                    <label type="text" class="form-control disableLabel mb-0">{{(leaveMasterFormGroup.value.balance ==
                      null ||
                      leaveMasterFormGroup.value.balance == undefined) ? '' :
                      leaveMasterFormGroup.value.balance}}</label>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.balance.errors">{{leaveMasterFormGroup.controls.balance.errorMessage}}</small>
                    <span class="form-text text-danger" style="margin-bottom: -25px;">{{multipleYearBalance}}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">

                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddComponent_LMApplyTo_t">
                  </label>
                  <div class="col-md-8">
                    <label type="text" class="form-control disableLabel mb-0">{{(leaveMasterFormGroup.value.reportToName
                      == null ||
                      leaveMasterFormGroup.value.reportToName == undefined || leaveMasterFormGroup.value.reportToName ==
                      '') ?
                      ''
                      : leaveMasterFormGroup.value.reportToName}}</label>
                   </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType == 8">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMCurrency_t"></label>
                  <div class="col-md-8">
                    <ng-container *ngIf="!isReadOnly" [rxSelectExtended]="_this">
                      <rx-select [(source)]="leaveLookUps.vCurrencyLookUp" #rxSelect mainClass="form-control"
                        [selectDisabled]="isReadOnly" formControlName="currency"
                        [keyValueProps]="['currencyName','currencyId']" [selectPlaceholder]="'Select Currency'">
                      </rx-select>
                    </ng-container>
                    <div class="input-group">
                      <label *ngIf="isReadOnly" type="text"
                        class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(currency ==
                        null
                        ||
                        currency == undefined ||
                        currency == '') ? '' : currency}}</label>
                      <span *ngIf="isReadOnly" class="input-group-append">
                        <span class="input-group-text">
                          <i class="fa fa-spinner fa-pulse d-none"></i>
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
                    </div>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.currency.errors">{{leaveMasterFormGroup.controls.currency.errorMessage}}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType == 8">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddComponent_LMBudget_t">
                  </label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" formControlName="budget" [readonly]="hideUpdateButton"
                      rxPlaceholder="LeaveMasterAddComponent_LMBudget_p">
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.budget.errors">{{leaveMasterFormGroup.controls.budget.errorMessage}}</small>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType == 8">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMEvents_t"></label>
                  <div class="col-md-8">
                    <fieldset [rxTagExtended]="_this" *ngIf="isSourceChanged">
                      <rx-tag [tagDisabled]="hideUpdateButton" [(source)]="eventLookUp" #rxTag
                        formControlName="eventIds"
                        [attr.mainClass]="hideUpdateButton?'form-control disabled-drop-down':'form-control'"
                        [keyValueProps]="['eventname','eventid']" placeholder="Select Event"></rx-tag>
                    </fieldset>
                  </div>
                </div>
              </div>


              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType == 8">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMProject_t"></label>
                  <div class="col-md-8">
                    <fieldset [rxTagExtended]="_this" *ngIf="isSourceChanged">
                      <rx-tag [tagDisabled]="hideUpdateButton" [(source)]="projectLookUp" #rxTag
                        formControlName="projectIds"
                        [attr.mainClass]="hideUpdateButton?'form-control disabled-drop-down':'form-control'"
                        [keyValueProps]="['projectName','projectId']" placeholder="Select Project">
                      </rx-tag>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType != 1">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMAttachment_t"></label>
                  <div class="col-md-8" *ngIf="masterId==0">
                    <app-file [fileViewModel]="fileViewModel" (files)="updateFiles($event)"></app-file>
                    <small class="form-text mt-2"
                      *ngIf="leaveMasterFormGroup.value.fileName">{{leaveMasterFormGroup.value.fileName}}</small>
                  </div>
                  <div class="col-md-8" *ngIf='masterId > 0'>
                    <a href="{{leaveMaster.fileUrl}}" target="_blank">{{leaveMaster.attachment}}</a>
                  </div>
                </div>
              </div>


              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType == 8">

                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="LeaveMasterAddComponent_LMCitiesMinesLocations_t"></label>
                  <div class="col-md-8">
                    <textarea *ngIf="!isReadOnly" class="form-control" formControlName="citiesMinesLocations"
                      [readonly]="isReadOnly"
                      rxPlaceholder="LeaveMasterAddComponent_LMCitiesMinesLocations_p"></textarea>
                    <label *ngIf="isReadOnly" type="text"
                      class="form-control disableLabel mb-0">{{(leaveMasterFormGroup.value.citiesMinesLocations == null
                      ||
                      leaveMasterFormGroup.value.citiesMinesLocations == undefined ||
                      leaveMasterFormGroup.value.citiesMinesLocations == '') ? 'Enter Location' :
                      leaveMasterFormGroup.value.citiesMinesLocations}}</label>
                    <small class="form-text text-danger"
                      [class.d-block]="leaveMasterFormGroup.controls.citiesMinesLocations.errors">{{leaveMasterFormGroup.controls.citiesMinesLocations.errorMessage}}</small>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="selectLeaveType > 0 && selectLeaveType != 1">
                <div class="form-group row" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddComponent_LMReason_t">
                  </label>
                  <div class="col-md-8">
                    <textarea class="form-control" formControlName="reason" [readonly]="hideUpdateButton"
                      rxPlaceholder="LeaveMasterAddComponent_LMReason_p"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="status != ''">
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Status
                  </label>
                  <div class="col-md-8">
                    <label type="text" class="col-form-label">{{status}}</label>
                  </div>
                </div>
              </div>




            </div>

            <hr>

            <div class="row">
              <div class="col-md-4 mb-3 d-flex align-items-center">
                <h4 class="m-0" rxText="LeaveMasterAddEditComponent_VTA_Option_t"></h4>
                <app-checkbox class="ml-2" [checkFormControl]="leaveMasterFormGroup.controls.isDetailedFormAdded" ></app-checkbox>
              </div>
            </div>

            <div *ngIf="leaveMasterFormGroup.value.isDetailedFormAdded == true">
              
              <div class="row">

                <div class="col-md-6">
                  <div class="form-group row" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                      rxText="LeaveMasterAddEditComponent_PurposeOfTravel_t">
                      </label>
                    <div class="col-md-8">
                      <textarea class="form-control" formControlName="purposeOfTravel"
                        rxPlaceholder="LeaveMasterAddEditComponent_PurposeOfTravel_p"></textarea>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-form-label" rxText="LeaveMasterAddEditComponent_RequestType_t">
                      </label>
                    <div class="col-md-8">
                      <div class="row col-md-12 d-flex justify-content-between align-items-center">
                        <div class="i-checks mr-2" [rxLocalisationInit]="componentName">
                          <label class="mb-0">
                            <div class="iradio_square-green"
                              [class.checked]="leaveMasterFormGroup.value.isOfficial==true"
                              >
                              <input type="radio" [value]="true"
                                formControlName="isOfficial" style="visibility: hidden;">
                            </div>
                            <label class="mb-0 ml-1"
                              rxText="LeaveMasterAddEditComponent_RequestType_official_t"></label>
                          </label>
                        </div>

                        <div class="i-checks" [rxLocalisationInit]="componentName">
                          <label class="mb-0">
                            <div class="iradio_square-green"
                              [class.checked]="leaveMasterFormGroup.value.isOfficial==false"
                              >
                              <input type="radio" [value]="false"
                                formControlName="isOfficial" style="visibility: hidden;">
                            </div>
                            <label class="mb-0 ml-1" rxText="LeaveMasterAddEditComponent_RequestType_special_t">
                              </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="row">
                <div class="col-md-4 mb-3 d-flex align-items-center" [rxLocalisationInit]="componentName">
                  <h4 class="m-0" rxText="LeaveMasterAddEditComponent_Vehicle_Request_t"></h4>
                  <app-checkbox class="ml-2" (change)="UpdateVehicleDetail($event)"  [checkFormControl]="leaveMasterFormGroup.controls.isVehicleRequested"></app-checkbox>
                </div>
              </div>


              <div class="col-md-12" *ngIf="leaveMasterFormGroup.value.isVehicleRequested == true" [rxLocalisationInit]="componentName">

                <table class="table-bordered table table-hover">
                  <thead *ngIf="leaveVehicleDetailFormArray.length>0"  [rxLocalisationInit]="componentName">
                    <tr>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_DestinationFrom_t"> </span>
                      </th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_DestinationTo_t"></span>
                      </th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_DateFrom_t"></span></th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_DateTo_t"></span></th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_ExitOrReturn_t"></span></th>
                      <th width="3%"><span rxText="LeaveMasterAddEditComponent_Action_t"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [formGroup]="leaveVehicleMappingFormGroup" [rxLocalisationInit]="componentName"
                      *ngFor="let leaveVehicleMappingFormGroup of leaveVehicleDetailFormArray; let i = index">
                      <td>
                        <input type="text" formControlName="destinationFrom" class="form-control" tabindex="26"
                          rxPlaceholder="LeaveMasterAddEditComponent_DestinationFrom_p">
                        <small class="form-text text-danger"
                          [class.d-block]="leaveVehicleMappingFormGroup.controls.destinationFrom.errors">{{leaveVehicleMappingFormGroup.controls.destinationFrom.errorMessage}}</small>
                      </td>

                      <td>
                        <input type="text" formControlName="destinationTo" class="form-control" tabindex="27"
                          rxPlaceholder="LeaveMasterAddEditComponent_DestinationTo_p">
                        <small class="form-text text-danger"
                          [class.d-block]="leaveVehicleMappingFormGroup.controls.destinationTo.errors">{{leaveVehicleMappingFormGroup.controls.destinationTo.errorMessage}}</small>
                      </td>

                      <td>
                        <rx-date showAddon="true" formControlName="dateFrom" pickerClass="form-control">
                        </rx-date>
                        <small class="form-text text-danger"
                          [class.d-block]="leaveVehicleMappingFormGroup.controls.dateFrom.errors">{{leaveVehicleMappingFormGroup.controls.dateFrom.errorMessage}}</small>
                      </td>

                      <td>
                        <rx-date showAddon="true" formControlName="dateTo" pickerClass="form-control">
                        </rx-date>
                        <small class="form-text text-danger"
                          [class.d-block]="leaveVehicleMappingFormGroup.controls.dateTo.errors">{{leaveVehicleMappingFormGroup.controls.dateTo.errorMessage}}</small>
                      </td>

                      <td [rxSelectExtended]="_this">

                        <rx-select [(source)]="leaveLookUps.vehicleRequestTypesLookup" #rxSelect mainClass="form-control"
                          formControlName="exitOrReturn"
                          [keyValueProps]="['vehicleRequestType','vehicleRequestTypeID']" [selectPlaceholder]="'Select Exit/Return'">
                        </rx-select>
                      </td>
                      <td >
                        <button class="btn btn-danger" [disabled]="leaveVehicleDetailFormArray.length<=1"
                          (click)="DeleteVehicle(i)" rxText="Btn_Delete_t"></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="form-group row">
                  <button (click)="AddVehicle()" class="btn btn-primary btn btn-primary ml-3"
                    rxText="LeaveMasterAddEditComponent_AddVehicle_t"></button>
                </div>

                <div class="row">

                  <!-- <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_TotalHrRequested_t"> </label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="totalHrsRequested" 
                          rxPlaceholder="LeaveMasterAddEditComponent_TotalHrRequested_p">
                      </div>
                    </div>
                  </div> -->
  
                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_LogisticUse_t">
                      </label>
                      <div class="col-md-8">
                        <textarea class="form-control" formControlName="logisticUse"
                                rxPlaceholder="LeaveMasterAddEditComponent_LogisticUse_p"></textarea>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_CarProvided_t">
                     </label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="carProvided" 
                          rxPlaceholder="LeaveMasterAddEditComponent_CarProvided_p">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_DriverProvided_t">
                      </label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="driverProvided" 
                          rxPlaceholder="LeaveMasterAddEditComponent_DriverProvided_p">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_DriverPhone_t">
                      </label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="driverPhone" 
                          rxPlaceholder="LeaveMasterAddEditComponent_DriverPhone_p">
                      </div>
                    </div>
                  </div>


                </div>

              </div>


              <div class="row">
                <div class="col-md-4 mb-3 d-flex align-items-center" [rxLocalisationInit]="componentName">
                  <h4 class="m-0" rxText="LeaveMasterAddEditComponent_Flight_Request_t"></h4>
                  <app-checkbox class="ml-2" (change)="UpdateFlightDetail($event)" [checkFormControl]="leaveMasterFormGroup.controls.isFlightRequested"></app-checkbox>
                </div>
              </div>

              <div class="col-md-12" *ngIf="leaveMasterFormGroup.value.isFlightRequested == true" [rxLocalisationInit]="componentName">

                <table class="table-bordered table table-hover">
                  <thead *ngIf="leaveFlightDetailFormArray.length>0"  [rxLocalisationInit]="componentName">
                    <tr>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_FlightFrom_t"> </span>
                      </th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_FlightTo_t"></span>
                      </th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_DepartureDate_t"> </span></th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_ArrivalDate_t"> </span></th>
                      <th width="13%"><span rxText="LeaveMasterAddEditComponent_ClassOfService_t"></span></th>
                      <th width="3%"><span rxText="LeaveMasterAddEditComponent_Action_t"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [formGroup]="leaveFlightMappingFormGroup" [rxLocalisationInit]="componentName"
                      *ngFor="let leaveFlightMappingFormGroup of leaveFlightDetailFormArray; let i = index">
                      <td>
                      <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="6" formControlName="flightFrom"
                          [queryParams]="{'lookupType':'airports'}" [valueInQueryParams]="true"
                          [keyValueProps]="['airportName','airportId']" [selectPlaceholder]="'Select Airport'" path="api/SearchEvent/GetLookups"
                          [minimumCharacterSearchLength]="2" [enableFreeText]="false" [text]="leaveFlightMappingFormGroup.value.flightFromName"
                          >
                      </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="leaveFlightMappingFormGroup.controls.flightFrom.errors">{{leaveFlightMappingFormGroup.controls.flightFrom.errorMessage}}</small>
                      </td>

                      <td>
                        <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="6" formControlName="flightTo"
                          [queryParams]="{'lookupType':'airports'}" [valueInQueryParams]="true"
                          [keyValueProps]="['airportName','airportId']" [selectPlaceholder]="'Select Airport'" path="api/SearchEvent/GetLookups"
                          [minimumCharacterSearchLength]="2" [enableFreeText]="false" [text]="leaveFlightMappingFormGroup.value.flightToName"
                          >
                      </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="leaveFlightMappingFormGroup.controls.flightTo.errors">{{leaveFlightMappingFormGroup.controls.flightTo.errorMessage}}</small>
                      </td>

                      <td>
                        <rx-date showAddon="true" formControlName="departureDate" pickerClass="form-control">
                        </rx-date>
                        <small class="form-text text-danger"
                          [class.d-block]="leaveFlightMappingFormGroup.controls.departureDate.errors">{{leaveFlightMappingFormGroup.controls.departureDate.errorMessage}}</small>
                      </td>

                      <td>
                        <rx-date showAddon="true" formControlName="arrivalDate" pickerClass="form-control">
                        </rx-date>
                        <small class="form-text text-danger"
                          [class.d-block]="leaveFlightMappingFormGroup.controls.arrivalDate.errors">{{leaveFlightMappingFormGroup.controls.arrivalDate.errorMessage}}</small>
                      </td>

                      <td [rxSelectExtended]="_this">
                        <rx-select [(source)]="leaveLookUps.vFlightClassLookUp" #rxSelect mainClass="form-control"
                          formControlName="classOfService"
                          [keyValueProps]="['flightClass','flightClassId']" [selectPlaceholder]="'Select Class of Service'">
                        </rx-select>
                      </td>
                      <td>
                        <button class="btn btn-danger" [disabled]="leaveFlightDetailFormArray.length<=1"
                          (click)="DeleteFlight(i)" rxText="Btn_Delete_t"></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="form-group row">
                  <button (click)="AddFlight()" class="btn btn-primary btn btn-primary ml-3"
                    rxText="LeaveMasterAddEditComponent_AddFlight_t"></button>
                </div>

                <!-- <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_emailForUpdates_t">
                      </label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="emailForUpdates" 
                          rxPlaceholder="LeaveMasterAddEditComponent_emailForUpdates_p">
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_TelephoneNo_t">
                      </label>
                      <div class="col-md-8" >
                        <input type="text" class="form-control" formControlName="telePhoneForUpdates"
                          rxPlaceholder="LeaveMasterAddEditComponent_TelephoneNo_p">
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="row">
                <div class="col-md-4 mb-3 d-flex align-items-center" [rxLocalisationInit]="componentName">
                  <h4 class="m-0" rxText="LeaveMasterAddEditComponent_AccomodationPref_t"></h4>
                  <app-checkbox class="ml-2" (change)="UpdateAccomodationDetail($event)"  [checkFormControl]="leaveMasterFormGroup.controls.isAccomodationRequested"></app-checkbox>
                </div>
              </div>              

              <div class="col-md-12" *ngIf="leaveMasterFormGroup.value.isAccomodationRequested == true" >

               
                <div class="row" [formGroup]="leaveAccomodationMappingFormGroup" *ngFor="let leaveAccomodationMappingFormGroup of leaveAccomodationDetailFormArray; let i = index" [rxLocalisationInit]="componentName">

                  
                  <!-- <span class="col-md-12 text-danger col-form-label font-weight-normal"  rxText="LeaveMasterAddEditComponent_AccomodationPrefWarn_t"></span> -->
                

                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_AccomodationPrefLbl_t">
                      </label>
                      <div class="col-md-8">
                        <rx-select [(source)]="leaveLookUps.vAccomodationPreferenceLookUp" #rxSelect mainClass="form-control"
                          formControlName="accomodationPreferenceID"
                          [keyValueProps]="['accomodationPreference','accomodationPreferenceID']" [selectPlaceholder]="'Select Accomodation Preference'"
                          >
                        </rx-select>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-6" *ngIf="showreason || leaveAccomodationMappingFormGroup.value.reasonID > 0">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_AReason_t">
                      </label>
                      <div class="col-md-8">
                        <rx-select [(source)]="leaveLookUps.vAccomodationPreferenceReasonsLookUp" #rxSelect mainClass="form-control"
                          formControlName="reasonID"
                          [keyValueProps]="['accomodationPreferenceReason','accomodationPreferenceReasonID']" 
                          [selectPlaceholder]="'Select Reason'"
                          (changed)="checkAccomodationPreferenceReason($event)"> 
                        </rx-select>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-6" *ngIf="showotherreason || leaveAccomodationMappingFormGroup.value.otherReason != ''">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_AOtherReason_t">
                     </label>

                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="otherReason" 
                          rxPlaceholder="LeaveMasterAddEditComponent_AOtherReason_p">
                      </div>
                    </div>
  
                  </div> -->

                  <div class="col-md-6">
                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="LeaveMasterAddEditComponent_CheckInDate_t">
                      </label>
                      <div class="col-md-8">
                        <rx-date showAddon="true" formControlName="checkInDate" pickerClass="form-control">
                        </rx-date>
                       </div>
                    </div>
    
                  </div>

                  <div class="col-md-6">

                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LeaveMasterAddEditComponent_CheckOutDate_t">
                      </label>
                      <div class="col-md-8">
                        <rx-date showAddon="true" formControlName="checkOutDate" pickerClass="form-control"
                          >
                        </rx-date>
                      </div>    
                    </div>
    
                  </div>

                  <div class="col-md-6">

                    <div class="form-group row" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="LeaveMasterAddEditComponent_ADays_t"></label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="noOfDays"
                        rxPlaceholder="LeaveMasterAddEditComponent_ADays_p">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div class="modal-footer">
            <button id="btnSave" type="button" class="btn btn-success" (click)="addLeave()" *ngIf="masterId == 0"
              [rxLocalisationInit]="componentName" rxText="LeaveMasterAddComponent_Save_t"></button>
            <button type="button" class="btn btn-success" (click)="updateLeave(1)" [rxLocalisationInit]="componentName"
              *ngIf="masterId > 0 && showApproveButton" rxText="LeaveMasterAddComponent_Approve_t"></button>
            <button type="button" class="btn btn-success" (click)="updateLeave(2)" [rxLocalisationInit]="componentName"
              *ngIf="masterId > 0 && showUpdateButton" rxText="LeaveMasterAddComponent_Update_t"></button>
            <button type="button" class="btn btn-success" (click)="updateLeave(3)" *ngIf="masterId > 0 && showEditButton"
              [rxLocalisationInit]="componentName" rxText="LeaveMasterAddComponent_Re_Submit_t"></button>
            <button type="button" class="btn btn-danger" (click)="updateLeave(4)" *ngIf="masterId > 0 && showRejectButton"
              [rxLocalisationInit]="componentName" rxText="LeaveMasterAddComponent_Reject_t"></button>
            <button type="button" class="btn btn-danger" (click)="updateLeave(5)" *ngIf="masterId > 0 && showCancelButton && !isCancelled"
              [rxLocalisationInit]="componentName" rxText="LeaveMasterAddComponent_Cancel_t"></button>

          </div>
      </div>
    </div>
  </div>
</div>
