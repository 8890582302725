//Generated Imports
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop, required } from '@rxweb/reactive-form-validators';
import { FormsDocumentBase } from '../database-models/forms-document-base';
export class FormsDocument extends FormsDocumentBase implements FileDetailViewModel
{
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1:string;
    @prop()
    fileExtention1:string;
    @prop()
    @required()
    fileName1:string;
    @prop()
    fileData2:string;
    @prop()
    fileExtention2:string;
    @prop()
    fileName2:string;
    @prop()
    productId:string;
    @prop()
    filePath: string;
    @prop()
    fileUrl: string;
    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
}