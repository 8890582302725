import {POSCartBase,} from '@app/database-models'
//Generated Imports
export class POSCart extends POSCartBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





































}