import {vCustomerEmploymentBase,} from '@app/database-models'
//Generated Imports
export class vCustomerEmployment extends vCustomerEmploymentBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}