import { actionColumn, gridColumn } from '@rxweb/grid'

@actionColumn({
    allowSorting: false,
    style: { width: "5%", "text-align": "initial" },
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})
export class DocumentTypeUserViewModelBase {

    //#region isChecked Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean;
    //#endregion isChecked Prop

    //#region userId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: false, columnIndex: 1, headerTitle: 'userId', keyColumn: true })
    userId: number;
    //#endregion userId Prop

    //#region fullName Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'DocumentTypeUserMappingAddComponent_FullName_gh', keyColumn: false })
    fullName: string
    //#endregion fullName Prop
   
     //#region email Prop
     @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.email;
                    }
                }
            }
        }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'DocumentTypeUserMappingAddComponent_Email_gh', keyColumn: false
    })
    email: string
    //#endregion email Prop

    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'ITTicketCustomerCorpMapAddEditComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName: "recordStatus" } })
    statusId: number;

    //#region totalCount Prop
    @gridColumn({ style: { width: "35%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: true, headerKey: 'Total Count', keyColumn: false })
  
    totalCount: number
    //#endregion totalCount Prop
    contactPermissionRequired:boolean;
    introducesBy:string;
    isUnwelcome:boolean;

}