import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Department, vPeopleTypeLookUp } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { Router } from '@angular/router';
@http({
    path: "api/Departments",
})
export class AbstractDepartment extends CoreComponent {
    departmentFormGroup: IFormGroup<Department>
    showComponent: boolean = false;
    showTab: boolean = false;
    spin: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr;
    selectedTab: string="department-info";
    dialog: BaseDialog;
    router: Router;
    id:number;
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    
    departmentLookup: any = {
        peopleTypeLookUp: vPeopleTypeLookUp,
        department: Department
    }

    activeTab(tabName: string) {
      this.selectedTab = tabName;
      this.router.navigate(["departments", this.id], { queryParams: { activeTab: tabName } });
  }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }

 
}
