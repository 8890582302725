import { HttpClient, HttpEventType } from "@angular/common/http";
import {
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreComponent } from "@rxweb/angular-router";
import { saveAs } from "file-saver";
import { MultiLingualData, multilingual } from "@rxweb/localization";
import { Title } from "@angular/platform-browser";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { encryptString, getServerUrl } from "src/app/domain/common-logic/common-logic";
import { ApplicationBroadcaster } from "src/app/temp-service/application-broadcaster";
import { DocumentOTPVerificationViewModel } from "src/app/view-model/document-otp-verification-view-model";
import * as CryptoJS from 'crypto-js';

@multilingual("DownloadPageComponent")
@Component({
  selector: "app-download-page",
  templateUrl: "./download-page.component.html",
  styleUrls: ["./download-page.component.css"],
})

/**
 * This component used to show download page
 */
export class DownloadPageComponent
  extends CoreComponent
  implements OnInit {
  documentId: number;
  documentType: string;
  statusId: number;
  showComponent: boolean = false;
  message: string;
  notice: string;
  isGenerated: boolean = false;
  isError: boolean = false;
  percentDone = 0;
  localizationData = null;
  otpObject: DocumentOTPVerificationViewModel = null;
  isRestrictedDocument: boolean = false;

  constructor(
    private browserStorage: BrowserStorage,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private title: Title,
    private applicationBroadcaster: ApplicationBroadcaster
  ) {
    super();

    // Managing redirection & URL flow in case of restricted file download
    if (this.router.url.startsWith('/download-verified-content') && this.applicationBroadcaster.documentVerifiedOTP != undefined) {
      this.otpObject = this.applicationBroadcaster.documentVerifiedOTP;
      this.documentId = this.otpObject.documentId;
      this.documentType = 'document';
      this.isRestrictedDocument = true;
    }
    else if (this.router.url.startsWith('/download-content')) {
      this.activatedRoute.queryParams.subscribe((t) => {
        this.documentId = t["did"];
        this.documentType = t["t"];
      });
    }
    else {
      window.location.href = "/dashboard";
    }
    this.message = "";
    this.notice = "";
  }

  async ngOnInit() {
    // Managing redirection & URL flow in case of restricted file download
    if (this.router.url.startsWith('/download-content') && this.applicationBroadcaster.documentVerifiedOTP == undefined) {
      var response = await this.get<any>({ path: 'api/Document/CheckRestriction', params: [encryptString(this.documentId.toString())] }).toPromise();
      if (response) {
        this.isRestrictedDocument = true;
        this.applicationBroadcaster.isDownloadProcessStarted = true;
        this.router.navigate(['download-verification'], { queryParams: { 'did': this.documentId, 't': this.documentType } });
        return;
      }
      this.showComponent = true;
    }
    else {
      this.showComponent = true;
    }
    this.title.setTitle("Download - G-Trac Admin");
    var element = document.getElementById("gtrac");
    element.classList.add("leave-master-view");
    element.classList.remove("login-page-view");
    this.localizationData = (MultiLingualData.get(this.componentName) as any);
    if (!this.localizationData) {
      this.localizationData = {
        DownloadPageComponent_Downloading_t_t: "",
        DownloadPageComponent_Notice_t_t: "",
        DownloadPageComponent_InvalidURL_t_t: "",
        DownloadPageComponent_FileNotFound_t_t: "",
        DownloadPageComponent_SomethingWentWrong_t_t: "",
        DownloadPageComponent_DownloadSuccess_t_t: ""
      };
    }
    this.message = this.localizationData.DownloadPageComponent_Downloading_t_t;
    this.notice = this.localizationData.DownloadPageComponent_Notice_t_t;
    if (this.documentId == undefined || this.documentType == undefined || isNaN(this.documentId) || this.documentType.toLowerCase() != "document") {
      this.isError = true;
      this.message = this.localizationData.DownloadPageComponent_InvalidURL_t_t;
      this.redirectAfterFewSec();
    } else {
      this.getDocument();
    }
  }

  /**
   * This function Calls API to download files.
   * If document is restricted, it will call 'api/document/AnonymousDownload/', otherwise it will call 'api/document/Download/'
   */
  getDocument() {
    var authToken = this.browserStorage.local.get("auth", false);
    let apiURL = getServerUrl();

    if (this.isRestrictedDocument) {
      this.httpClient.post(apiURL + "/api/document/AnonymousDownload/" + this.documentId,
        {
          token: this.otpObject.token,
          email: this.otpObject.email
        },
        {
          reportProgress: true,
          observe: "events",
          responseType: "blob",
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }).subscribe(
          (res: any) => {
            this.handleSuccessResponse(res);
          },
          (err) => {
            this.handleErrorResponse();
          }
        );
    }
    else {
      
      this.httpClient.get(apiURL + "/api/document/Download/" + encryptString(this.documentId.toString()),
        {
          reportProgress: true,
          observe: "events",
          responseType: "blob",
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken
          }
        }).subscribe(
          (res: any) => {
            this.handleSuccessResponse(res);
          },
          (err) => {
            this.handleErrorResponse();
          },
          () => {
            this.otpObject = null;
            this.applicationBroadcaster.documentVerifiedOTP = null;
          }
        );
    }
  }

  /**
   * Function to handle error response
   */
  private handleErrorResponse() {
    this.isError = true;
    this.message = this.localizationData.DownloadPageComponent_FileNotFound_t_t;
    this.redirectAfterFewSec();
  }

  /**
   * Function to handle success reponse, track download percentage & download file
   * @param res Response data received from download API
   */
  private handleSuccessResponse(res: any) {
    this.isError = false;
    if (res.type === HttpEventType.DownloadProgress) {
      this.percentDone = Math.round(
        (100 * res.loaded) / res.total
      );
    }
    else if (res.type === HttpEventType.Response) {
      var fileName = res.headers.get('FileName');
      this.percentDone = 100;
      setTimeout(() => {
        saveAs(res.body, fileName);
        this.message = this.localizationData.DownloadPageComponent_DownloadSuccess_t_t;
        this.isGenerated = true;
        setTimeout(() => {
          window.close();
          window.self.close();
        }, 1000);
      }, 750);
    } else if (!res) {
      this.isError = true;
      this.message = this.localizationData.DownloadPageComponent_InvalidURL_t_t;
      this.redirectAfterFewSec();
    }
  }

  /**
   * Function to handle redirection after 3 seconds
   */
  redirectAfterFewSec() {
    let redirectionTO = setTimeout(() => {
      clearTimeout(redirectionTO);
      window.location.href = "/dashboard";
    }, 3000);
  }

  get componentName(): string {
    return "DownloadPageComponent";
  }
}
