import { prop, required, propObject } from '@rxweb/reactive-form-validators';
import { MindMapUserFilterViewModel } from './mind-map-user-filters-view-model';
import { MindMapCorporateFilterViewModel } from './mind-map-corporate-filters-view-model';
import { MindMapEventFilterViewModel } from './mind-map-event-filters-view-model';
import { MindMapPressMediaFilterViewModel } from './mind-map-press-media-filters-view-model';
import { MindMapProjectFilterViewModel } from './mind-map-project-filters-view-model';
import { MindMapOrderFilterViewModel } from './mind-map-order-filters-view-model';

export class MindMapFilterViewModel {

    @required()
    rootEntityTypeId: number;

    // @prop()
    // rootEntityId: number;

    // @prop()
    // entityTypeId: number;

    // @prop()
    // entityId: number;

    @propObject(MindMapUserFilterViewModel)
    mindMapUserFilterViewModel: MindMapUserFilterViewModel;

    @propObject(MindMapCorporateFilterViewModel)
    mindMapCorporateFilterViewModel: MindMapCorporateFilterViewModel;

    @propObject(MindMapEventFilterViewModel)
    mindMapEventFilterViewModel: MindMapEventFilterViewModel;

    @propObject(MindMapProjectFilterViewModel)
    mindMapProjectFilterViewModel: MindMapProjectFilterViewModel;

    @propObject(MindMapPressMediaFilterViewModel)
    mindMapPressMediaFilterViewModel: MindMapPressMediaFilterViewModel;

    @propObject(MindMapOrderFilterViewModel)
    mindMapOrderFilterViewModel: MindMapOrderFilterViewModel;

    constructor() {
        this.mindMapUserFilterViewModel = new MindMapUserFilterViewModel();
        this.mindMapCorporateFilterViewModel = new MindMapCorporateFilterViewModel();
        this.mindMapEventFilterViewModel = new MindMapEventFilterViewModel();
        this.mindMapProjectFilterViewModel = new MindMapProjectFilterViewModel();
        this.mindMapPressMediaFilterViewModel = new MindMapPressMediaFilterViewModel();
        this.mindMapOrderFilterViewModel = new MindMapOrderFilterViewModel();
    }
}
