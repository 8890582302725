import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRegionBase {

  //#region regionName Prop
  @gridColumn({ parameter: true, isAscending: true, style: { width: "14%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'RegionListComponent_RegionName_gh', keyColumn: false })
  regionName: string;
  //#endregion regionName Prop


  //#region regionId Prop
  @gridColumn({ parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'RegionListComponent_RegionId_gh', keyColumn: true })
  regionId: number;
  //#endregion regionId Prop

  //#region displayOrder Prop
  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'RegionListComponent_DisplayOrder_gh', keyColumn: false })
  displayOrder: number;
  //#endregion displayOrder Prop


  //#region published Prop
  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'RegionListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
  published: boolean;
  //#endregion published Prop

}
