import { actionColumn, gridColumn } from '@rxweb/grid';
import { propArray, prop } from '@rxweb/reactive-form-validators';
import { TrackIncidentRawFormAffectedPerson } from './track-incident-raw-form-affected-person';

@actionColumn({
    visible:false,
    name:"action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 6, headerTitle: "Action"
})

export class TrackIncidentRawForm {

    @prop({defaultValue:0})
    trackIncidentFormId: number;

    @prop({defaultValue:0})
    trackIncidentId: number;

    @prop({defaultValue:''})
    @gridColumn(
        { 
            template:{
                div:{
                  style:{
                    "word-break": "break-all",
                  },
                  attributes:{
                    innerHTML:function(x,y){
                     return this.fullNameHtml;
                    }
                  },
                  childrens:[{
                    a: {
                            isBind: (x) => {
                                    if (x.fullNameCount > 100) {
                                            return true;
                                    }
                                    else {
                                            return false;
                                    }
                            }, style: {
                                    "display": "block"
                            },
                            childrens: [{
                                    span: {
                                            class: "more-btn mr-2".split(" "),
                                            childrens: [{
                                                    text: {
                                                            text: function (e) {
                                                                    return "Show More";
                                                            }
                                                    }
                                            }]
                                    }
                            }],
                            event: {
                                    click: "onMoreClickFullName"
                            }
                    },
            }]
                }
            }
             ,style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'TrackIncidentRawFormList_fullName_gh', keyColumn: false})
    fullName: string

    fullNameHtml: string
    fullNameCount: number

    @gridColumn({isAscending:true, style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'TrackIncidentRawFormList_Ref_gh', keyColumn: true})
    ref_No: string;

    @prop({defaultValue:''})
    @gridColumn({ style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'TrackIncidentRawFormList_Corporate_gh', keyColumn: false})
    gemfieldGroupEntity: string

    @prop({defaultValue:0})
    occurrenceType: number

    @prop({defaultValue:false})
    isOccurrenceInCompany: boolean

    @prop({defaultValue:''})
    occurrenceLocation: string

    @prop()
    occurrenceDate: Date;

    occurrenceDateString: string;

    @prop({defaultValue:''})
    occurrenceTime: string

    @prop({defaultValue:''})
    grievancePersonAffectedCategoryId: string

    grievancePersonAffectedCategory: string

    @prop({defaultValue:''})
    occuranceDescription: string

    @prop({defaultValue:''})
    grievanceTypeId: string

    grievanceType: string

    @prop({defaultValue:''})
    grievanceAccessPointId: string

    grievanceAccessPoint: string

    @prop({defaultValue:''})
    grievanceAccessPointText: string

    @prop({defaultValue:''})
    grievanceVehicleInvolvedId: string

    grievanceVehicleInvolved: string

    @propArray(TrackIncidentRawFormAffectedPerson)
    affectedPersons: TrackIncidentRawFormAffectedPerson[];

    @gridColumn({style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:5, allowSorting: true, headerKey: 'TrackIncidentRawFormList_HSERef_gh', keyColumn: false})
    trackIncidentRefNo: string;

    @prop()
    imageURL:string;

    @prop()
    supplierId:number;

    @prop()
    @gridColumn({ style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'TrackIncidentRawFormList_occurrenceDate_gh', keyColumn: false})
    occurrenceDateFormatted:Date;

    @prop({defaultValue:""})
    representativePersonName:string;

    @prop()
    totalCount:number;
}
