import {vPOSEventDataLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPOSEventDataLookUp extends vPOSEventDataLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}