import {vBoutiqueRecordBase,} from '@app/database-models'
//Generated Imports
export class vBoutiqueRecord extends vBoutiqueRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}