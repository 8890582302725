import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { AbstractTaxProvider } from '../domain/abstract-tax-provider';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxProvider } from '@app/models';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';

import { TaxRateAddComponent } from '../../tax-rates/add/tax-rate-add.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";

@multilingual("TaxProviderEditComponent")
@access({ accessLevel: RolePermissionEnums.TaxProviders, action: "put" })

@Component({
    selector: "app-tax-provider-edit",
    templateUrl: './tax-provider-edit.component.html',
    entryComponents: [TaxRateAddComponent]
})
export class TaxProviderEditComponent extends AbstractTaxProvider implements OnInit, OnDestroy {
    taxProvider: TaxProvider;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID:number;
    titleHelp:string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,private applicationBroadcaster :ApplicationBroadcaster, private router: Router, modelView: ModalView, private el:ElementRef, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.TaxProviders;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Tax Providers - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {

            if (t) {
                this.taxProviderFormGroup = this.formBuilder.formGroup(TaxProvider, t) as IFormGroup<TaxProvider>;
                this.showComponent = true;
            }
            else {
                this.router.navigate(['/tax-provider']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }
    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editTaxProvider(isRedirect: boolean) {
        this.taxProviderFormGroup.submitted = true;
        if (this.taxProviderFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.taxProviderFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.ngOnInit() : this.router.navigate(['/system']);
            })
        } else {
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.taxProviderFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }

    deleteTaxProvider() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/tax-provider']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Tax_Provider_Detail");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    isEnableTaxConfiguration() {
        return this.taxProviderClassses.any(x => x.id == this.id && x.isUIActive == true)
    }
    get componentName(): string {
        return "TaxProviderEditComponent";
    }
}
