import { prop, email } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class  ProjectRiskAddModel{

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "riskURL";
    }

    @prop({defaultValue: ''})
    specificRiskName: string;

    @prop({defaultValue: 0})
    projectId: number;

    @prop({defaultValue: 0})
    riskId: number;

    @prop({defaultValue : 0})
    specificRiskId : number;

    @prop({defaultValue : 0})
    gemfieldGroupCompany : number;

    @prop({defaultValue: ''})
    categoryId: number;

    @prop({defaultValue:25})
    rowCount:number;

    @prop({defaultValue:1})
    pageIndex:number;

    @prop({defaultValue:'true'})
    sortOrder:string;

    @prop({defaultValue:'riskURL'})
    orderBy:string;
}
