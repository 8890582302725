import { numeric, prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';
import { HttpClient } from '@angular/common/http';

export class MoveNoteDocumentBookingFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "false";
        this.orderBy = "bookingId";
        this.responsiblePersons = 0;
        this.bookingType = 0;
    }

    @prop()
    bookingStartDate: Date;
    @prop()
    bookingEndDate: Date;
    @prop()
    bookingName: String;

    @prop()
    responsiblePersons: number;

    @prop()
    piece : number;

    @prop()
    pieceNumberText : string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    bookingType: number;

    @prop()
    searchString: string;

    @prop()
    @numeric()
    bookingId: number;
}
