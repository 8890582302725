import {TopicBase,TopicLocalizationBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class Topic extends TopicBase 
{



topicLocalizationsSEO : TopicLocalizationBase[];
//#region Generated Reference Properties
//#region topicLocalizations Prop
topicLocalizations : TopicLocalizationBase[];
//#endregion topicLocalizations Prop

//#endregion Generated Reference Properties
@prop()
displayImage: boolean;

@prop()
fileData: string;

@prop()
imageURL1: string;

@prop()
fileExtention: string;

@prop()
fileName: string;

@prop({defaultValue: false})
isRemovedFileData: boolean;

@prop()
blobPath: string;

@prop()
size: number;

@prop()
blobFileName: string;























}