import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule,  } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SharedModule } from '../../shared/shared.module';
import { DocumentAddComponent } from './add/document-add.component';
import { DocumentEditComponent } from './edit/document-edit.component';
import { DocumentListComponent } from './list/document-list.component';
import { FileModule } from '../../shared/global/file/file.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
	IgxDragDirective,
	IgxDropDirective,
	IgxDragDropModule,
	IgxDialogModule
 } from "igniteui-angular";
import { SystemTableSettingModule } from '../../shared/system-table-settings/system-table-setting.module';
import { NoteDocumentComponent } from './note-document/note-document.component';


@NgModule({
  imports: [FileModule, NgxDropzoneModule,
    CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, SharedModule,DragDropModule,IgxDragDropModule,IgxDialogModule
  ,IgxDragDropModule,IgxDialogModule,SystemTableSettingModule],
  declarations: [DocumentAddComponent, DocumentEditComponent, DocumentListComponent,
    NoteDocumentComponent],
  providers: [],
  exports: [DocumentAddComponent, DocumentEditComponent, DocumentListComponent,
    NoteDocumentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DocumentSharedModule { }

