import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractLanguageContentKey } from '../domain/abstract-language-content-key';
import { LanguageContentKey } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { localizationFilterViewModel } from 'src/app/view-model/localization-filter-view-model';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { AppGrid } from 'src/app/domain/app-grid';
import { LocalizationListViewModel } from 'src/app/view-model/localization-list-view-model';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { debounceTime } from 'rxjs/operators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { LanguageContentKeyAddComponent } from '../add/language-content-key-add.component';
import { Workbook } from 'exceljs';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from "@angular/common";
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("LanguageContentKeyListComponent")
@access({ accessLevel: RolePermissionEnums.Localization, action: "get" })
@Component({
    selector: "app-language-content-key-list",
    templateUrl: './language-content-key-list.component.html'
})
export class LanguageContentKeyListComponent extends AbstractLanguageContentKey implements OnInit, OnDestroy {
    languageContentKeys: List<LanguageContentKey>;
    localizationSearchFormGroup: IFormGroup<localizationFilterViewModel>;
    subscription: any;
    localizations: localizationFilterViewModel;
    router: Router;
    showGrid: boolean = false;
    isShowGrid: boolean;
    totalRecords: number = 0;
    localizationGrid: AppGrid;
    isFilter: boolean = false;
    isNoRecordFound: boolean;
    resetCommonControl: boolean = true;
    localizationList: LocalizationListViewModel[];
    localizationAdd: any = LanguageContentKeyAddComponent;
    private languageContentSubject: Subject<string> = new Subject<string>();
    list: any;
    exportExcel = new ExportToExcelModel();
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;


    _this;

    constructor(private formBuilder: RxFormBuilder, modalView: ModalView, router: Router, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private location : PlatformLocation,private blobState: BlobUploadsViewStateService) {
        super();
        this.modalView = modalView;
        this.router = router;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.Localization;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Localisation - G-Trac Admin")
        this.titleHelp = getLocalizedValue("Title_Help");
        this.spin = true;
        this.languageContentSubject.pipe(debounceTime(300)).subscribe(t => {
            this.searchlocalization();
        })
        this.lookup([
            { path: USER_LOOKUPS["languageUserLookUps"], propName: "languageUserLookUp" }
        ]).subscribe((lookup: any) => {
            this.localizations = new localizationFilterViewModel();
            this.localizations.pageIndex = PaginationEnum.PageIndex;
            this.localizations.rowCount = PaginationEnum.RowCount;
            this.localizationSearchFormGroup = this.formBuilder.formGroup(this.localizations) as IFormGroup<localizationFilterViewModel>;
            this.localizationLookup = lookup;
            this.showComponent = true;
            this.spin = false;
        });
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    onKeyPress() {
        this.localizationSearchFormGroup.patchValue({
          pageIndex : PaginationEnum.PageIndex,
          orderBy : "keyName",
          sortOrder : "true",
          rowCount : PaginationEnum.RowCount
        });

        this.languageContentSubject.next();
    }
  
    searchlocalization() {
        if (this.localizationGrid != null) {
            this.isFilter = true;
        }
        this.bindGrid();
    }

    bindGrid() {
        this.localizationSearchFormGroup.submitted = true;
        this.spin = true;
        this.showGrid = true;
        this.isShowGrid = true;
        this.totalRecords = 0;
        if (this.localizationSearchFormGroup.controls["componentText"].value == null || this.localizationSearchFormGroup.controls["id"].value > 0) {
            this.localizationSearchFormGroup.patchValue({
                componentText: ''
            })
        }

        if (this.localizationSearchFormGroup.controls["id"].value == '') {
            this.localizationSearchFormGroup.patchValue({
                id: null
            })
        }

        if (this.localizationSearchFormGroup.value.componentText != null && this.localizationSearchFormGroup.value.componentText != "") {
            this.localizationSearchFormGroup.patchValue({
                id: null
            });
        }
        var json = JSON.parse(JSON.stringify(this.localizationSearchFormGroup.value));
        this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: any) => {
            this.showComponent = true;
            setTimeout(() => {
                //this.isShowGrid = true;
                if (this.localizationGrid) {
                    this.localizationGrid.storeProcedure.length = 0;
                }
            });

            this.spin = false;
            this.localizationList = t;
            if (this.localizationList.length > 0) {
                this.totalRecords = this.localizationList[0].totalCount;
                this.isNoRecordFound = false;
            }
            else {
                this.isShowGrid = false;
                this.isNoRecordFound = true;
            }
            if (!this.isFilter) {
                this.localizationGrid = new AppGrid(this.localizationList, LocalizationListViewModel, { actions: { onDelete: this.onDelete.bind(this),onEdit: this.onEdit.bind(this) } });
                this.localizationGrid.gridColumns.forEach(x => {
                    if (x.name == "action") {
                        x.visible = this.localizationGrid.authorize({ accessLevel: RolePermissionEnums.Localization, action: "put" });
                    }
                })
                this.localizationGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            }
            else {
                if (this.localizationGrid != null) {
                    this.localizationGrid.storeProcedure.length = this.totalRecords;
                    if (this.localizationSearchFormGroup.value.pageIndex == 1) {
                        this.localizationGrid.storeProcedure.nextPage = 1;

                    }
                    this.localizationGrid.updateSource([]);
                    this.localizationGrid.updateSource(this.localizationList);
                }
            }
            this.localizationGrid.maxPerPage = this.localizationSearchFormGroup.value.rowCount;
            this.localizationGrid.componentId = this.componentName;
            this.localizationGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.localizationGrid.designClass.headerClass = "table-header-sticky".split(" ");
        });
    }
    onDelete(localization: LocalizationListViewModel) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [localization.languageContentKeyId] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.searchlocalization();
                    //this.router.navigate(['/language-content-keys']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Localization");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    exportToExcel() {
        this.spin = true;

        var json = JSON.parse(JSON.stringify(this.localizationSearchFormGroup.value));
        json["pageIndex"] = PaginationEnum.PageIndex;
        json["rowCount"] = 40000;
        json["searchString"] = "";
        json["componentText"] = "";
        json["contentType"] = "";
        json["resourceName"] = "";
        json["resourceValue"] = "";

        this.subscription = this.get({ queryParams: { "query": JSON.stringify(json) } }).subscribe((response: any) => {
            this.spin = false;
            this.list = response;
            this.generatePiecesExcel();
        })
    }
    generatePiecesExcel() {
        this.spin = true;
        let returnedRequestData = this.requestExcelData(this.list);
        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet();
        // Cell Style : Fill and Header
        var TodayDate = new Date();
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
        var FileName = "Localisation_" + MMDDYY;

        this.exportExcel.FileName = "Localisation" + MMDDYY;
        this.exportExcel.Type = "Excel";
        this.exportExcel.Description = "Localisation"

        // const headerRow = worksheet.addRow(header);
        var localizationData = (MultiLingualData.get(this.componentName) as any);
        worksheet.columns = [
            { header: localizationData.LanguageContentKeyListComponent_Id_ex_ex, key: 'Id', width: 10 },
            { header: localizationData.LanguageContentKeyListComponent_ResourceName_ex_ex, key: 'KeyName', width: 40 },
            { header: localizationData.LanguageContentKeyListComponent_ValueEnglish_ex_ex, key: 'Value', width: 40 },
            { header: localizationData.LanguageContentKeyListComponent_ValueMobile_ex_ex, key: 'mobile', width: 40 }
        ];

        returnedRequestData.forEach(x => {
            worksheet.addRow(x);
        });
        worksheet.getRow(1).eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '00000000'
                },
                bgColor: {
                    argb: '00000000'
                },
            };
            cell.font = {
                color: {
                    argb: 'FFFFFFFF',
                },
                bold: true
            }
            cell.border = {
                top: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            };
        });


        setTimeout(() => {
            this.spin = false;
            workbook.xlsx.writeBuffer().then(async (y: any) => {
                var u8 = new Uint8Array(y);
                var b64encoded = btoa(this.Uint8ToString(u8));

                const blob = new Blob([y], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                let uploadedBlobName = await this.uploadExcelToBlob(blob, "xlsx");
                // this.exportExcel.Result = b64encoded;
                this.exportExcel.BlobFileName = uploadedBlobName;
                this.post({ body: this.exportExcel, path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
                    fs.saveAs(blob, Response);

                })
            });
        }, 1000);


    }

    async uploadExcelToBlob(file: any, fileExtention: string): Promise<string> {

        let fileUploadRes = [];
        let newGUID = uuidv4();
        let fileName = BLOB_BASE.ExcelSheet + `${newGUID}_${new Date().toISOString()}.${fileExtention}`;
        var res = await this.blobState.uploadItems(file, fileName);
    
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.ExcelSheet + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
    
        // IF SUCCEED TO UPLOAD
        if (res && (res._response.status == 200 || res._response.status == 201)) {
          if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.toastr.error(
            "Error occured while uploading file! Please try again."
          );
          if (uploadedFileName != undefined) {
            this.modalView
              .show(BedRequestComponent, {
                validationMessages: JSON.stringify(
                  uploadedFileName
                ),
                headerMessage: "Image failed to upload:",
              })
              .then(() => { });
          }
        }
        return uploadedFileName;
      }
    Uint8ToString(u8a) {
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return c.join("");
    }

    requestExcelData(usersGrid: LocalizationListViewModel[]) {
        let userListData = new Array<SaleSuccessData>();

        usersGrid.forEach(element => {
            let listData = new SaleSuccessData();
            listData["Id"] = element.languageContentKeyId;
            listData["KeyName"] = element.keyName;
            listData["Value"] = element.english;
            listData["mobile"] = element.mobile;
            userListData.push(listData);
        });
        return userListData;
    }




    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.localizationSearchFormGroup.patchValue({ sortOrder: y, orderBy: x, pageIndex : z })
        this.localizationGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.localizationSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.localizationGrid.maxPerPage })
        this.localizationGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    onEdit(localization: LocalizationListViewModel) {
        let CT: string = "";
        if (this.contentTypeArray.findIndex(x => x.value == localization.contentType) != -1) {
            CT = this.contentTypeArray[this.contentTypeArray.findIndex(x => x.value == localization.contentType)].id;
            //this.router.navigate(["language-content-keys", localization.languageContentKeyId, CT]);
            let url : any;
            url = this.router.serializeUrl(this.router.createUrlTree(["language-content-keys", localization.languageContentKeyId, CT]));
            redirectOnCTRL(url,this.router,event);
        }
    }
    resetSearch() {
        this.spin = true;
        this.localizationSearchFormGroup.reset();
        this.showComponent = false;
        this.resetCommonControl = false
        setTimeout(function () {
            this.resetCommonControl = true;
        }.bind(this), 50);
        if(this.localizationGrid && this.localizationGrid.source.length > 0){
            this.localizationGrid.destroy();
        }
        if (this.localizationGrid) {
            this.localizationGrid = null
            this.isFilter = false;
        }
        this.isShowGrid = false;
        this.showGrid = false;
        setTimeout(() => {
            this.showComponent = true;
            this.spin = false;
        }, 100);
    }

    checkNameText(event: any) {
        this.localizationSearchFormGroup.patchValue({
            componentText: event.target.value
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "LanguageContentKeyListComponent";
    }

}
class SaleSuccessData {
    Id: number
    KeyName: string;
    Value: string;
}


class ExportToExcelModel {
    Result: string;
    Type: string;
    FileName: string;
    Description: string;
    BlobFileName:string;
}
