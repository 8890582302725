import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { LeaveType, LeaveTypeCorporateMapping } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { AppGrid } from "src/app/domain/app-grid";

@http({
    path: "api/LeaveTypeCorporateMappings",
})
export class AbstractLeaveTypeCorporateMapping extends CoreComponent {
  leaveTypeCorporateMappingFormGroup: IFormGroup<LeaveTypeCorporateMapping>
    leaveTypeFormGroup: IFormGroup<LeaveType>
    showComponent: boolean = false;
    spin: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr;
    dialog: BaseDialog;
    leaveTypeCorporateGrid: AppGrid;
    isShowGrid:boolean;
    isNoRecordFound : boolean;

	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
}
