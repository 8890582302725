import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HSEUpdateBase {

//#region hSEStatusMappingId Prop
        @prop()
        hSEStatusMappingId : number;
//#endregion hSEStatusMappingId Prop


//#region customerId Prop
        @required()
        @prop()
        customerId : string;
//#endregion customerId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}