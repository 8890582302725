import {CustomerRelationshipBase,CustomerChildRelationshipBase,} from '@app/database-models'
//Generated Imports
export class CustomerRelationship extends CustomerRelationshipBase 
{




//#region Generated Reference Properties
//#region customerChildRelationships Prop
customerChildRelationships : CustomerChildRelationshipBase[];
//#endregion customerChildRelationships Prop

//#endregion Generated Reference Properties












}