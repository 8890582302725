import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSpecificationAttributeOptionRecordBase {

//#region specificationAttributeOptionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'specificationAttributeOptionId', keyColumn: true})
        specificationAttributeOptionId : number;
//#endregion specificationAttributeOptionId Prop


//#region specificationAttributeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'specificationAttributeId', keyColumn: false})
        specificationAttributeId : number;
//#endregion specificationAttributeId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region viewbyClients Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'viewbyClients', keyColumn: false})
        viewbyClients : any;
//#endregion viewbyClients Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region inspirations Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'inspirations', keyColumn: false})
        inspirations : string;
//#endregion inspirations Prop


//#region pictureId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'pictureId', keyColumn: false})
        pictureId : any;
//#endregion pictureId Prop


//#region pictureId2 Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'pictureId2', keyColumn: false})
        pictureId2 : any;
//#endregion pictureId2 Prop


//#region pictureId3 Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'pictureId3', keyColumn: false})
        pictureId3 : any;
//#endregion pictureId3 Prop


//#region totCollection Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'totCollection', keyColumn: false})
        totCollection : number;
//#endregion totCollection Prop


//#region parentCollectionId Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'parentCollectionId', keyColumn: false})
        parentCollectionId : any;
//#endregion parentCollectionId Prop


//#region metaKeywords Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'metaKeywords', keyColumn: false})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'metaDescription', keyColumn: false})
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'metaTitle', keyColumn: false})
        metaTitle : string;
//#endregion metaTitle Prop


//#region videoUpload Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'videoUpload', keyColumn: false})
        videoUpload : string;
//#endregion videoUpload Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:16, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : boolean;
//#endregion published Prop


//#region sEName Prop
        @gridColumn({visible: true, columnIndex:17, allowSorting: true, headerKey: 'sEName', keyColumn: false})
        sEName : string;
//#endregion sEName Prop

}