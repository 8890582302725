import { vGGLEmployeesLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGGLEmployeesLookUp extends vGGLEmployeesLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}