import {vActivityLogTypeBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
// @actionColumn({
//     allowSorting: false,
//     style: { width: "2%" },
//     configuredTemplate: { templateName: 'action' }, columnIndex: 12, headerTitle: "Action"
// })
export class vActivityLogType extends vActivityLogTypeBase 
{

    totalCount: number;

    //activityLogTypeIds: number[];
    
//#region Generated Reference Properties

//#endregion Generated Reference Properties





}