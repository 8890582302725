<div [rxSpinner]="spin"  *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="tab-content">
        <div class="dataTables_filter  d-flex flex-wrap align-items-center">
            <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="WatchInvoiceListComponent_Add_Invoice_Btn_t"></button>
            <label class="ml-auto mr-3 mb-3">
                <input type="search" (keyup.enter)="search($event)"
                    class=" table_search form-control form-control-sm pr-4" placeholder="" [title]="titleSearch">
            </label>
            <div class="ibox-tools mb-4 pb-1" style="position: relative;" *ngIf="!isNoRecordFound">
                <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
                  <i class="fa fa-cog"></i>
              </a>
              <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
              (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
              [gridColumnConfig]="watchInvoicesGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
              [moduleID]="moduleID" [selectedTab]="selectedTab">
              </app-system-table-setting>
              </div>
        </div>
        <div class="table-responsive" *ngIf="isShowGrid">
            <div id="watchInvoicesGrid" class="dataTables_wrapper pb-0 dt-bootstrap4">          
            </div>
        </div>
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
        </div>
    </div>
</div>
