import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RoleBase {

//#region roleId Prop
        @prop()
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @required()
        @maxLength({value:100})
        @trim()
        @notEmpty()
        roleName : string;
//#endregion roleName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region roleType Prop
        @prop()
        roleType : number;
//#endregion roleType Prop


//#region roleDomainId Prop
        @prop({defaultValue:10})
        @required()
        roleDomainId : number;
//#endregion roleDomainId Prop

//#region roleDomainId Prop
        @prop({defaultValue:true})
        active : boolean;
//#endregion roleDomainId Prop

//#region departmentId Prop
@prop()
departmentId : number;
//#endregion departmentId Prop






}