import { CompanyCustomerMappingBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { CustomerCompanyListViewModel } from './customer-company-list-view-model';
//Generated Imports
export class CompanyCustomerMapping extends CompanyCustomerMappingBase {

    @prop()
    company: string
    @prop()
    fullName: string
    @prop()
    status: boolean
    @prop()
    industryTypeName: string
    @prop()
    isActive: string
    @prop()
    isCorporate: boolean;
    @prop()
    isCustomer: boolean;
    @prop({defaultValue:false})
    isEndEmployment : boolean;

    corporateUserIds: CompanyCustomerMappingBase[];

    isActiveStatus: CustomerCompanyListViewModel;

    @prop()
    activeEmployement:boolean;

    @prop()
    isCorporateNote: boolean;

    @prop()
    reportToId: number;
    //#292627//08-07-2024//added two param in model for employment grid
    @prop()
    payRollId:number;
    
    @prop()
    patersonGradeId:number;

    @prop({defaultValue:0})
    directorMappingId:number;

    @prop({defaultValue:0})
    ownerMappingId:number;

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties


    @prop()
    expatId:number;









}
