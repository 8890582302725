<div *ngIf="showComponent">
  <div id="wrapper" *ngIf="showMenusLayout">
    <app-side-bar *ngIf="isShowDashboard" class="d-flex"></app-side-bar>
    <div id="page-wrapper" class="d-flex flex-column">
      <app-top-bar *ngIf="isShowDashboard"></app-top-bar>
      <router-outlet></router-outlet>
      <app-footer-bar *ngIf="isShowDashboard"></app-footer-bar>
      <app-popup></app-popup>
    </div>
  </div>
  <div id="wrapper" *ngIf="!showMenusLayout && isShowLogin">
    <div id="page-wrapper" class="d-flex flex-column">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div id="stockwrapper" *ngIf="!showMenusLayout && !isShowLogin">
    <router-outlet></router-outlet>
  </div>
</div>

<a href="javascript:void(0);" class="scroll_top" [title]="scrolToTop"><i class="fa fa-angle-up" aria-hidden="true"></i></a>