<div [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="tab-content">
        <div class="dataTables_filter  d-flex flex-wrap align-items-center">
            <ng-container *ngIf="isRisk" [rxLocalisationInit]="componentName">
                <button class="btn btn-success btn-sm mb-3"  (click)="addPressMedia()" rxText="Btn_Add_New_t" ></button>
            </ng-container>
            <ng-container *ngIf="isHSE" [rxLocalisationInit]="componentName">
                <button class="btn btn-success btn-sm mb-3"  (click)="addPressMedia()" rxText="Btn_Add_New_t" ></button>
            </ng-container>
        <div class="ml-auto d-flex flex-wrap align-items-center mb-2">
          <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowDelete && isShowGrid">
            <button class="btn btn-danger mr-2" (click)="deletePressMedia()"
                rxText="Btn_Delete_t" [disabled]="deletePressMediaIds.length == 0"></button>
          </ng-container>
            <label class="mr-4 mt-2" *ngIf="isShowSearch" [rxLocalisationInit]="componentName">
                <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
                    placeholder="" [title]="titleSearch">
            </label>
            <div class="ibox-tools" *ngIf="isShowGrid">
                <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
                    <i class="fa fa-cog"></i>
                </a>
                <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
                    (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
                    [gridColumnConfig]="pressMediaGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID"
                    [selectedTab]="selectedTab">
                </app-system-table-setting>
            </div>
        </div>

        </div>
        <div data-toggle="buttons" *ngIf="isLongTextExists"
            class="btn-group btn-group-gray btn-group-toggle mb-1 d-flex flex-wrap justify-content-end" [rxLocalisationInit]="componentName">
            <ng-container [rxLocalisationInit]="componentName" *ngIf="!isShowAll">
              <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Show_All_t" ></button>
            </ng-container>
            <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowAll">
              <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Hide_All_t" ></button>
            </ng-container>
        </div>
        <div class="table-responsive">

            <div class="dataTables_wrapper pb-0 dt-bootstrap4" *ngIf="isShowGrid">
                <rx-grid [design]="pressMediaGrid"></rx-grid>
            </div>
        </div>
        <div class="ibox-title bg-white px-0" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
        </div>
    </div>
</div>
