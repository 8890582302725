import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TicketDocumentDetailBase {

//#region ticketDocumentDetailId Prop
        @prop()
        ticketDocumentDetailId : number;
//#endregion ticketDocumentDetailId Prop


//#region ticketId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        ticketId : number;
//#endregion ticketId Prop


//#region fileName Prop
        @required()
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop

}