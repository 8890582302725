import { gridColumn } from "@rxweb/grid"


export class vRiskCategoryLookUpBase {

//#region riskCategoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'riskCategoryId', keyColumn: true})
        riskCategoryId : number;
//#endregion riskCategoryId Prop


//#region riskCategoryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'riskCategoryName', keyColumn: false})
        riskCategoryName : string;
//#endregion riskCategoryName Prop

}