import { prop,propObject,propArray,required,maxLength,range ,notEmpty, alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemFieldsBookingStatusBase {

//#region gemFieldsBookingStatusId Prop
        @prop()
        gemFieldsBookingStatusId : number;
//#endregion gemFieldsBookingStatusId Prop


//#region gemFieldsBookingStatusName Prop
        @required()
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        @maxLength({value:100})
        @trim()
        gemFieldsBookingStatusName : string;
//#endregion gemFieldsBookingStatusName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}