import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ProjectCorporateMapping, vSalesAdvisorLookUp, vIndustryTypeLookUp } from '@app/models';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent,  } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { http } from '@rxweb/http';
import { vCompanyLookup } from 'src/app/models/extended-models/v-company-lookup';
import { AppGrid } from 'src/app/domain/app-grid';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { vSalesUserLookUpBase } from 'src/app/models/database-models/v-sales-user-look-up';
import { vProductCategoryLookUp } from 'src/app/models/extended-models/v-product-category-look-up';

@http({
    path: "api/ProjectCorporateMappings",
})

export class AbstractProjectCorporateMapping extends CoreComponent {
    projectCorporateMappingFormGroup: IFormGroup<ProjectCorporateMapping>;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    showComponent: boolean = false;
    dateError:boolean=false;
    ProjectUserListGrid: AppGrid;
    projectCorporateGrid: AppGrid;
    isShowGrid:boolean;
    isNoRecordFound : boolean;
    categoryLookup :any;
    collectionLookup: any;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    projectCorporateMappingLookup: any = {
        //salesAdvisorLookUp: vSalesAdvisorLookUp,
        salesAdvisorLookUp: vSalesUserLookUpBase,
        companyLookUp:vCompanyLookup,
        industryTypeLookUp:vIndustryTypeLookUp,
        productCategoryLookUps: vProductCategoryLookUp,
    }
    applyClass() {
        this.ProjectUserListGrid.designClass.rowClass = [function () {
            let data = this;
            if (data.roleId == CustomerRoleTypeEnum.Admin) {
                return "admin";
            }
            else {
                return "customer";
            }
        }]
    }

    applyClassProjectCorporateGrid() {
        this.projectCorporateGrid.designClass.rowClass = [function () {
            let data = this;
            if (data.roleId == CustomerRoleTypeEnum.Admin) {
                return "admin";
            }
            else {
                return "customer";
            }
        }]
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
