import { prop, required, propObject } from '@rxweb/reactive-form-validators';
import { MoveNoteDocumentUserFilterViewModel } from './move-note-document-user-filters-view-model';
import { MoveNoteDocumentCorporateFilterViewModel } from './move-note-document-corporate-filters-view-model';
import { MoveNoteDocumentEventFilterViewModel } from './move-note-document-event-filters-view-model';
import { MoveNoteDocumentProjectFilterViewModel } from './move-note-document-project-filters-view-model';
import { MoveNoteDocumentPressMediaFilterViewModel } from './move-note-document-press-media-filters-view-model';
import { MoveNoteDocumentOrderFilterViewModel } from './move-note-document-order-filters-view-model';
import { MoveNoteDocumentLegalAgreementFilterViewModel } from './move-note-document-legal-agreement-filter-view-model';
import { MoveNoteDocumentHSEFilterViewModel } from './move-note-document-hse-filter-view-model';
import { MoveNoteDocumentRiskFilterViewModel } from './move-note-document-risk-filter-view-model';
import { MoveNoteDocumentITHelpDeskFilterViewModel } from './move-note-document-it-help-desk-filter-view-model';
import { MoveNoteDocumentPieceFilterViewModel } from './move-note-document-piece-filter-view-model';
import { MoveNoteDocumentProductFilterViewModel } from './move-note-document-product-filter-view-model';
import { MoveNoteDocumentGemstoneFilterViewModel } from './move-note-document-gemstone-filter-view-model';
import { MoveNoteDocumentBookingFilterViewModel } from './move-note-document-booking-filter-view-model';
import { MoveNoteDocumentDesignFilterViewModel } from './move-note-document-design-filter-view-model';
import { MoveNoteDocumentPricelistFilterViewModel } from './move-note-document-pricelist-filter-view-model';
import { MoveNoteDocumentBatchFilterViewModel } from './move-note-document-batch-filter-view-model';

export class MoveNoteDocumentFilterViewModel {

    @required()
    rootEntityTypeId: number;

    @propObject(MoveNoteDocumentUserFilterViewModel)
    moveNoteDocumentUserFilterViewModel: MoveNoteDocumentUserFilterViewModel;

    @propObject(MoveNoteDocumentCorporateFilterViewModel)
    moveNoteDocumentCorporateFilterViewModel: MoveNoteDocumentCorporateFilterViewModel;

    @propObject(MoveNoteDocumentEventFilterViewModel)
    moveNoteDocumentEventFilterViewModel: MoveNoteDocumentEventFilterViewModel;

    @propObject(MoveNoteDocumentProjectFilterViewModel)
    moveNoteDocumentProjectFilterViewModel: MoveNoteDocumentProjectFilterViewModel;

    @propObject(MoveNoteDocumentPressMediaFilterViewModel)
    moveNoteDocumentPressMediaFilterViewModel: MoveNoteDocumentPressMediaFilterViewModel;

    @propObject(MoveNoteDocumentOrderFilterViewModel)
    moveNoteDocumentOrderFilterViewModel: MoveNoteDocumentOrderFilterViewModel;

    @propObject(MoveNoteDocumentLegalAgreementFilterViewModel)
    moveNoteDocumentLegalAgreementFilterViewModel: MoveNoteDocumentLegalAgreementFilterViewModel;

    @propObject(MoveNoteDocumentHSEFilterViewModel)
    moveNoteDocumentHSEFilterViewModel: MoveNoteDocumentHSEFilterViewModel;

    @propObject(MoveNoteDocumentRiskFilterViewModel)
    moveNoteDocumentRiskFilterViewModel: MoveNoteDocumentRiskFilterViewModel;

    @propObject(MoveNoteDocumentITHelpDeskFilterViewModel)
    moveNoteDocumentITHelpDeskFilterViewModel: MoveNoteDocumentITHelpDeskFilterViewModel;

    @propObject(MoveNoteDocumentPieceFilterViewModel)
    moveNoteDocumentPieceFilterViewModel: MoveNoteDocumentPieceFilterViewModel;

    @propObject(MoveNoteDocumentProductFilterViewModel)
    moveNoteDocumentProductFilterViewModel: MoveNoteDocumentProductFilterViewModel;

    @propObject(MoveNoteDocumentGemstoneFilterViewModel)
    moveNoteDocumentGemstoneFilterViewModel: MoveNoteDocumentGemstoneFilterViewModel;

    @propObject(MoveNoteDocumentBookingFilterViewModel)
    moveNoteDocumentBookingFilterViewModel: MoveNoteDocumentBookingFilterViewModel;

    @propObject(MoveNoteDocumentDesignFilterViewModel)
    moveNoteDocumentDesignFilterViewModel: MoveNoteDocumentDesignFilterViewModel;

    @propObject(MoveNoteDocumentPricelistFilterViewModel)
    moveNoteDocumentPricelistFilterViewModel: MoveNoteDocumentPricelistFilterViewModel;

    @propObject(MoveNoteDocumentBatchFilterViewModel)
    moveNoteDocumentBatchFilterViewModel: MoveNoteDocumentBatchFilterViewModel;

    constructor() {
        this.moveNoteDocumentUserFilterViewModel = new MoveNoteDocumentUserFilterViewModel();
        this.moveNoteDocumentCorporateFilterViewModel = new MoveNoteDocumentCorporateFilterViewModel();
        this.moveNoteDocumentEventFilterViewModel = new MoveNoteDocumentEventFilterViewModel();
        this.moveNoteDocumentProjectFilterViewModel = new MoveNoteDocumentProjectFilterViewModel();
        this.moveNoteDocumentPressMediaFilterViewModel = new MoveNoteDocumentPressMediaFilterViewModel();
        this.moveNoteDocumentOrderFilterViewModel = new MoveNoteDocumentOrderFilterViewModel();
        this.moveNoteDocumentLegalAgreementFilterViewModel = new MoveNoteDocumentLegalAgreementFilterViewModel();
        this.moveNoteDocumentHSEFilterViewModel = new MoveNoteDocumentHSEFilterViewModel();
        this.moveNoteDocumentRiskFilterViewModel = new MoveNoteDocumentRiskFilterViewModel();
        this.moveNoteDocumentITHelpDeskFilterViewModel = new MoveNoteDocumentITHelpDeskFilterViewModel();
        this.moveNoteDocumentPieceFilterViewModel = new MoveNoteDocumentPieceFilterViewModel();
        this.moveNoteDocumentProductFilterViewModel = new MoveNoteDocumentProductFilterViewModel();
        this.moveNoteDocumentGemstoneFilterViewModel = new MoveNoteDocumentGemstoneFilterViewModel();
        this.moveNoteDocumentBookingFilterViewModel = new MoveNoteDocumentBookingFilterViewModel();
        this.moveNoteDocumentDesignFilterViewModel = new MoveNoteDocumentDesignFilterViewModel();
        this.moveNoteDocumentPricelistFilterViewModel = new MoveNoteDocumentPricelistFilterViewModel();
        this.moveNoteDocumentBatchFilterViewModel = new MoveNoteDocumentBatchFilterViewModel();
    }
}
