import {vPaymentStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPaymentStatusLookUp extends vPaymentStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}