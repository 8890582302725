import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductBatchPieceOwnerBase {

//#region pieceOwnerId Prop
        @prop()
        pieceOwnerId : number;
//#endregion pieceOwnerId Prop


//#region pieceOwnerName Prop
        @required()
        @maxLength({value:100})
        pieceOwnerName : string;
//#endregion pieceOwnerName Prop


//#region locationId Prop
        @prop()
        locationId : number;
//#endregion locationId Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop

}