import {SpecificationMasterBase,SpecificationAttributeOptionBase,SpecificationAttributeBase,} from '@app/database-models'
//Generated Imports
export class SpecificationMaster extends SpecificationMasterBase 
{




//#region Generated Reference Properties
//#region specificationAttributeOptions Prop
specificationAttributeOptions : SpecificationAttributeOptionBase[];
//#endregion specificationAttributeOptions Prop
//#region specificationAttributes Prop
specificationAttributes : SpecificationAttributeBase[];
//#endregion specificationAttributes Prop

//#endregion Generated Reference Properties





















}