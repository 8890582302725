import { prop, propObject, propArray, required, maxLength, range, numeric, notEmpty, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceBase {

  //#region productVariantBatchPieceId Prop
  @prop()
  productVariantBatchPieceId: number;
  //#endregion productVariantBatchPieceId Prop


  //#region productVariantBatchId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  productVariantBatchId: number;
  //#endregion productVariantBatchId Prop


  //#region serial Prop
  @prop()
  serial: number;
  //#endregion serial Prop


  //#region serialNo Prop
  @required()
  @notEmpty()
  @maxLength({ value: 50 })
  serialNo: string;
  //#endregion serialNo Prop


  //#region cost Prop
  @required()
  @notEmpty()
  cost: any;
  //#endregion cost Prop


  //#region aTACarnetNo Prop
  //  @required() --13 july
  //   @notEmpty()
  @prop({ defaultValue: '' })
  @maxLength({ value: 50 })
  ataCarnetNo: string;
  //#endregion aTACarnetNo Prop


  //#region inspectedAcceptedBy Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  //@required()
  @prop({ defaultValue: 0 })
  inspectedAcceptedBy: number;
  //#endregion inspectedAcceptedBy Prop


  //#region location Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  location: number;
  //#endregion location Prop


  //#region status Prop
  status: number;
  //#endregion status Prop


  //#region createdOn Prop
  @required()
  createdOn: Date;
  //#endregion createdOn Prop


  //#region updatedOn Prop
  @required()
  updatedOn: Date;
  //#endregion updatedOn Prop


  //#region oldInventoryNumber Prop
  @maxLength({ value: 100 })
  oldInventoryNumber: string;
  //#endregion oldInventoryNumber Prop


  //#region description Prop
  @prop()
  @required()
  @notEmpty()
  //@maxLength({ value: 400 })
  description: string;
  //#endregion description Prop


  //#region carnetExpiryDate Prop
  @prop()
  carnetExpiryDate: Date;
  //#endregion carnetExpiryDate Prop


  //#region gramWeight Prop
  @prop({ defaultValue: "0.01" })
  @required()
  @range({ minimumNumber: 0.01, maximumNumber: 99999, message: 'The Piece Gram Weight must be from 0.01 to 99999' })
  @pattern({expression:{"customPrice":/^[0-9]\d{0,8}(\.\d{1,2})??$/},message:"Please enter valid Piece Gram"})
  @numeric({ allowDecimal: true })
  gramWeight: any;
  //#endregion gramWeight Prop


  //#region pieceOwner Prop
  @prop()
  pieceOwner: number;
  //#endregion pieceOwner Prop

  //#region pieceOwnerUserId Prop
  @prop()
  pieceOwnerUserId: number;
  //#endregion pieceOwnerUserId Prop

  //#region pieceOwnerSupplierId Prop
  @prop()
  pieceOwnerSupplierId: number;
  //#endregion pieceOwnerSupplierId Prop

  //#region actualDeliveryDate Prop
  @prop()
  actualDeliveryDate: Date;
  //#endregion actualDeliveryDate Prop


  //#region size Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  // @required()
  @prop({ defaultValue: 0 }) // 13 July
  size: number;
  //#endregion size Prop


  //#region eggSize Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  eggSize: number;
  //#endregion eggSize Prop


  //#region supplierInvoiceNo Prop
  //@required()
  //  @notEmpty()
  @prop({ defaultValue: '' }) // 13 July
  @maxLength({ value: 50 })
  supplierInvoiceNo: string;
  //#endregion supplierInvoiceNo Prop


  //#region invoice Prop
  @maxLength({ value: 500 })
  invoice: string;
  //#endregion invoice Prop


  //#region exportPrice Prop
  @prop({ defaultValue: 0 })
  //@range({minimumNumber:0,maximumNumber:213456789})
  //  @numeric({ allowDecimal: true })
  exportPrice: any;
  //#endregion exportPrice Prop


  //#region doNotPublish Prop
  @prop({ defaultValue: false })
  doNotPublish: boolean;
  //#endregion doNotPublish Prop


  //#region pictureID1 Prop
  @prop({ defaultValue: 0 })
  pictureID1: number;
  //#endregion pictureID1 Prop


  //#region pictureID2 Prop
  @prop({ defaultValue: 0 })
  pictureID2: number;
  //#endregion pictureID2 Prop

  //#region pictureID3 Prop
  @prop({ defaultValue: 0 })
  pictureID3: number;
  //#endregion pictureID3 Prop

  //#region stockcardPictureID Prop
  @prop()
  stockcardPictureID: number;
  //#endregion stockcardPictureID Prop


  //#region displayRRPFor Prop
  @required()
  @prop({ defaultValue: "1" })
  @maxLength({ value: 2 })
  displayRRPFor: string;
  //#endregion displayRRPFor Prop


  //#region imageAlt Prop
  @prop({ defaultValue: '' }) // 13 july
  @maxLength({ value: 400 })
  imageAlt: string;
  //#endregion imageAlt Prop


  //#region goldGramWeight Prop
  @required()
  @prop({ defaultValue: "0.01" })
  @range({ minimumNumber: 0.01, maximumNumber: 99999, message: 'The Gold Gram Weight must be from 0.01 to 99999' })
  @pattern({expression:{"customPrice":/^[0-9]\d{0,8}(\.\d{1,2})??$/},message:"Please enter valid Gold Gram Weight"})
  @numeric({ allowDecimal: true })
  goldGramWeight: any;
  //#endregion goldGramWeight Prop


  //#region size1 Prop
  @maxLength({ value: 50 })
  size1: string;
  //#endregion size1 Prop


  //#region eggSize1 Prop
  @maxLength({ value: 50 })
  eggSize1: string;
  //#endregion eggSize1 Prop


  //#region dutyStatusID Prop
  //  @range({minimumNumber:0,maximumNumber:2147483647})
  // @required()
  @prop({ defaultValue: 0 })
  dutyStatusID: number;
  //#endregion dutyStatusID Prop


  //#region supplierStoneCertificate Prop
  @maxLength({ value: 1000 })
  supplierStoneCertificate: string;
  //#endregion supplierStoneCertificate Prop


  //#region dutyStatusDate Prop
  @prop()
  dutyStatusDate: Date;
  //#endregion dutyStatusDate Prop


  //#region priceWholesalesoldat Prop
  //@required()
  @prop({ defaultValue: 0 }) // 13 july
  priceWholesalesoldat: number;
  //#endregion priceWholesalesoldat Prop


  //#region wholesalepiecestatus Prop
  @prop({ defaultValue: false })
  wholesalepiecestatus: boolean;
  //#endregion wholesalepiecestatus Prop


  //#region supplierDeliveryDate Prop
  @prop()
  supplierDeliveryDate: Date;
  //#endregion supplierDeliveryDate Prop


  //#region shippedDate Prop
  @prop()
  shippedDate: Date;
  //#endregion shippedDate Prop


  //#region pieceDeliveryStatus Prop
  @prop()
  pieceDeliveryStatus: number;
  //#endregion pieceDeliveryStatus Prop


  //#region wholesalepartnerSoldDate Prop
  @prop()
  wholesalepartnerSoldDate: Date;
  //#endregion wholesalepartnerSoldDate Prop


  //#region soldTo Prop
  @prop()
  soldTo: number;
  //#endregion soldTo Prop

        //#region soldToUserId Prop
        @prop()
        soldToUserId: number;
        //#endregion soldToUserId Prop

        //#region soldToSupplierId Prop
        @prop()
        soldToSupplierId: number;
        //#endregion soldToSupplierId Prop


  //#region enamelReference Prop
  @prop({ defaultValue: '' })
  @maxLength({ value: 400 })
  enamelReference: string;
  //#endregion enamelReference Prop


  //#region isPrototype Prop
  @prop({ defaultValue: false })
  isPrototype: boolean;
  //#endregion isPrototype Prop


  //#region chainLengthID Prop
  //   @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  chainLengthID: number;
  //#endregion chainLengthID Prop


  //#region bangleSize Prop
  //  @range({minimumNumber:1,maximumNumber:2147483647})
  //@required()
  @prop({ defaultValue: 0 })
  bangleSize: number;
  //#endregion bangleSize Prop


  //#region isUpcycled Prop
  @prop({ defaultValue: false })
  isUpcycled: boolean;
  //#endregion isUpcycled Prop


  //#region isBeSpoke Prop
  @prop({ defaultValue: false })
  isBeSpoke: boolean;
  //#endregion isBeSpoke Prop

  //#region statusId Prop
  @prop()
  statusId: number;
  //#endregion statusId Prop

  //#region  previousLegalOwner Prop
  @prop()
  previousLegalOwner: number;
  //#endregion  previousLegalOwner Prop

  //#region  frontDescription Prop
  @prop()
  frontDescription: string;
  //#endregion  frontDescription Prop

//#region showImage Prop
// @prop({defaultValue:false})
// @required()
showImage : boolean;
//#endregion showImage Prop

@prop({ defaultValue: false })
isShowImageURL5:boolean;

@prop({ defaultValue: false })
isShowImageURL3:boolean;
@prop({ defaultValue: false })
isShowImageURL1:boolean;
@prop({ defaultValue: false })
isShowImageURL4:boolean;


//#region dateOfSale Prop
@prop()
dateOfSale : Date;
//#endregion dateOfSale Prop

 @prop({ defaultValue: false })
 isConsigned: boolean;





}
