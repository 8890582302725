import { gridColumn } from '@rxweb/grid';

export class DocumentTypeNoteViewModel{
    
    @gridColumn({ parameter: false, visible: false, columnIndex: 4, keyColumn: true })
    noteId: number;

    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 1,headerKey: 'DocumentTypeNotesDetailComponent_CreatedDate_gh', keyColumn: false ,allowSorting:true})
    createdDate: Date;

    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 2,headerKey: 'DocumentTypeNotesDetailComponent_CreatedBy_gh', keyColumn: false ,allowSorting:true})
    createdBy: string;

    @gridColumn({template: {
            div: {
                style: {
                    "word-break": "break-all"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.note;
                    }
                }
        }}, parameter: false, style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 3,headerKey: 'DocumentTypeNotesDetailComponent_Note_gh', keyColumn: false ,allowSorting:true})
    note: string;

    createdById:number;
    totalCount:number;


}