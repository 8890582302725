import { vUserLockout } from '../models/extended-models/v-user-lockout';

export class UserLockoutViewModel extends vUserLockout{

    userIds: number[];

    userIdStr: string;

    status : boolean;
}
