import {
  ProductBatchBase,
  ProductBase,
  ProductPieceBase,
  InvoiceBatchMappingBase,
} from "@app/database-models";
import { prop, required, propArray } from "@rxweb/reactive-form-validators";
import { BOMListViewModel } from "src/app/view-model/bom-list-view-model";
//Generated Imports
export class ProductBatch extends ProductBatchBase {
  @prop({ defaultValue: 0 })
  inspectedBy: number;
  @prop()
  @required()
  location: number;
  @prop()
  @required()
  salesStatusId: number;
  @required()
  productionStatusId: number;
  @prop()
  consignmentStatusId: number;

  @prop()
  productionOrderFormID: number;

  //#region Generated Reference Properties
  //#region product Prop
  product: ProductBase;
  //#endregion product Prop
  //#region productPieces Prop
  productPieces: ProductPieceBase[];
  //#endregion productPieces Prop
  //#region invoiceBatchMappings Prop
  invoiceBatchMappings: InvoiceBatchMappingBase[];
  //#endregion invoiceBatchMappings Prop

  //#endregion Generated Reference Properties

  @propArray(BOMListViewModel)
  bomItems: BOMListViewModel[];

  @prop()
  designId: number;

  @prop()
  productName: string;

  @prop()
  totalCost: number;

  disableRingSize: boolean;
  disableBangleSize: boolean;
  disableEggSize:boolean;
  productImageUrl: string;

  supplierEmail: string;
  supplierName: string;
}
