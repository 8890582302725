import { RxHttp } from "@rxweb/http";
import { CLIENT_URL, DEFAULT_BLOB_IMAGE_URL } from 'src/app/domain/system-constant';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Document, Picture, ProductDesignPicture } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog, DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { FileInputViewModel } from '../../../../view-model/file-input-view-model';
import { FileOutputViewModel } from '../../../../view-model/file-output-view-model';
import { FileTypeEnums, FileSizeEnums, FileTypeValiationEnums } from '../../../../custom-enum/file-type-enums';
import { Input, Directive } from '@angular/core';
import { vDocuments } from 'src/app/models/extended-models/v-document';
import { List } from '@rxweb/generics';
import { AppGrid } from 'src/app/domain/app-grid';
import { DocumentListModel } from 'src/app/models/extended-models/DocumentListModel';
import { DatePipe, PlatformLocation } from '@angular/common';
import { DocumentListViewModel } from 'src/app/models/extended-models/document-list-view-model';
import { FormArray } from '@angular/forms';
import { DialogViewMode } from '@rxweb/js';
import { Subscription } from 'rxjs/internal/Subscription';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { PictureViewModel } from 'src/app/view-model/picture-view-model';
import { PictureListViewModel } from 'src/app/view-model/picture-list-view-model';
import { Observable, range } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { ProductWebPicture } from "src/app/models/database-models/product-web-picture";
import { dollarCircled } from "@igniteui/material-icons-extended";
import { StatusEnum } from "src/app/enums/status.enum";
import { ProductWebPictureListViewModel, ProductWebPictureViewModel } from "src/app/view-model/product-web-picture-view-model";
import { DashboardElement } from "src/app/models/extended-models/dashboard-element";
import { WebPictureHeight, WebPictureWidth } from "src/app/enums/webPicture.enum";
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
@Directive()
export class AbstractWebPicture extends CoreComponent {

  message: string = "";
  pictureWebFormGroup: IFormGroup<ProductWebPicture>
  pictureWebUpdateFormGroup: IFormGroup<ProductWebPicture>
  showComponent: boolean = false;
  spin: boolean = false;
  baseToastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  fileViewModel: FileInputViewModel;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() moduleName: string;
  @Input() isPicture: boolean;
  @Input() isDamagedPiece: boolean;
  id: number;
  products : ProductWebPictureViewModel[];
  productsList : ProductWebPictureListViewModel[] ;
  document: List<Document>;
  documnentList: vDocuments[];
  subscription: any;
  documentListGrid: AppGrid;
  nameArray = new Array<string>();
  pictureListModel: PictureListViewModel[];
  datePipe: DatePipe
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  isValidate: boolean = true;
  isArrayCount: number = 0;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  pictureUpdate: Document;
  fileNames: string = "";
  priority: string;
  isMinimumSize: boolean = true;
  ImageName: string = "";
  ImageSizeValidationMessage: string = "";
  hideButton : boolean = true;
  supportedExtensions:any;
  constructor(private location : PlatformLocation,private blobState: BlobUploadsViewStateService) {
    super();
    this.isPicture = true;
    this.nameArray = new Array<string>();
    this.baseToastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.SpecificImage;
    this.fileViewModel.fileAllowSize = FileSizeEnums.MaxMB;
    this.fileViewModel.isMultipart = true;
    //this.datePipe = new DatePipe();
    location.onPopState(() => this.dialog.hide());
    this.lookup([{ hostUri: CLIENT_URL, path: "assets/preview-supported-extensions/preview-supported-extensions.json", propName: "supportedExtensions" }]).subscribe((respose:any)=>{
      this.supportedExtensions = respose.supportedExtensions.SupportedExtensions;
    })
  }

  onFileChange(data: File[], ImageType : number, PictureIndex : number, formBuilder: RxFormBuilder): void {
    //let fileNames="";
    let scannedFiles: number = 0;
    let fileStrings = new Array<string>();
    let imageString = new Array<string>();
    this.fileNames = "";
    let validationMessage: string = "";
    var width = 0;
    var height = 0;
    switch (ImageType) {
      case 1:
          width = WebPictureWidth.WebPicture;
          height = WebPictureHeight.WebPicture;
        break;
      case 2:
        width = WebPictureWidth.MobileZoom;
        height = WebPictureHeight.MobileZoom;
        break;
      case 3:
        width = WebPictureWidth.ZoomPicture;
        height = WebPictureHeight.ZoomPicture;
        break;
      default:
        break;
    }
    this.nameArray.splice(0, this.nameArray.length);
    if (data.length > 1) {
      this.baseToastr.error("At a time maximum 1 file is allowed");
    }
    else {
      data.forEach(x => {
        if (this.isPicture) {
          let split_str = FileTypeValiationEnums.Image;
          let extension = x.name.substr((x.name.lastIndexOf('.') + 1)).toLowerCase();
          this.isValidate = true;
          if (split_str.indexOf(extension) == -1) {
            validationMessage = "Please select " + FileTypeValiationEnums.Image + " files only."
            this.isValidate = false;
          }
        }
      })
      if (this.isValidate) {
        range(0, data.length).pipe(
          concatMap(index => {
            return this.readFile(data[index]).pipe(
              map(result => ({ result, index })));
          })
        ).subscribe(fileWithIndex => {
          fileStrings.push(fileWithIndex.result);
          if (fileStrings.length == data.length) {
            range(0, fileStrings.length).pipe(
              concatMap(index => {
                return this.readImage(data[index].name, fileStrings[index],width, height).pipe(
                  map(result => ({ result, index }))
                );
              })
            ).subscribe(imageithIndex => {
              scannedFiles++;
              if (scannedFiles == data.length) {
                let j = 0;
                data.forEach(fileArray => {
                  let file = fileArray;

                  this.message = this.fileNames != "" ? (this.fileNames + (this.nameArray.length == 1 ? " has " : " have ") + " not been uploaded as the resolution is less than "+width+"x"+height) : "";
                  // var file = dataArray;
                  var _size = file.size;
                  var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
                    i = 0; while (_size > 900) { _size /= 1024; i++; }
                  var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
                  // if (file.size / 1024 > (this.fileViewModel.fileAllowSize * 1024)) {
                  //   this.baseToastr.error("File Size should be " + exactSize);
                  //   return;
                  // }
                  // else if (((exactSize.toString().indexOf("Bytes") != -1) || (exactSize.toString().indexOf("KB") != -1)) && parseFloat(exactSize) < FileSizeEnums.MinimumSizeKB) {
                  //   this.isMinimumSize = false;
                  //   if (!this.fileNames.includes(file.name)) {
                  //     this.ImageName += file.name + ",";
                  //     this.ImageSizeValidationMessage = this.ImageName + "size should be minimum 35 kb."
                  //   }
                  // }
                  // else {
                    this.isMinimumSize = true;
                  // }
                  if (this.ImageSizeValidationMessage != null && this.ImageSizeValidationMessage != undefined && this.ImageSizeValidationMessage != "" && this.message != null) {
                    this.message = this.message + " " + this.ImageSizeValidationMessage;
                  }
                  let validationMessage;
                  let filename = file.name;
                  if (this.isValidate) {
                    this.isArrayCount++;
                    if (this.isMinimumSize) {
                      let documentArray = this.pictureWebFormGroup.get("pictures") as FormArray;
                      let document = new PictureViewModel();
                      document.createdBy = 1;
                      document.displayPriority = 1;
                      document.documentId = 0;
                      document.isPicture = this.isPicture;
                      document.moduleName = this.moduleName;
                      document.seeOnline = false;
                      document.entityId = this.id;
                      document.entityTypeId = this.entityTypeId;
                      document.file = file;
                      document.fileString = "";
                      document.fileName = file.name;
                      if (this.nameArray.findIndex(x => x == file.name) == -1) {
                        document.fileString = fileStrings[j];
                        documentArray.push(formBuilder.formGroup(document) as IFormGroup<FormArray>);
                      }
                    }
                    if (this.isValidate && this.isArrayCount == data.length) {
                      this.uploadDocument(formBuilder,ImageType,PictureIndex);
                    }
                  }
                  // else {
                  //   this.baseToastr.error(validationMessage);
                  // }
                  j++;
                });
              }
            });
          }
        });
      }
      else {
        this.baseToastr.error(validationMessage);
      }
    }
  }









  //    setupReader(file) {
  //     var name = file.name;
  //     var reader = new FileReader();
  //     this.fi
  //     reader.onload = function(e) {
  //         get file content

  //     }
  //     reader.readAsDataURL(file);
  // }

  readFile(file: File): Observable<string> {
    return new Observable(obs => {
      const reader = new FileReader();
      reader.onload = () => {
        obs.next(reader.result as string);
        obs.complete();
      }
      reader.readAsDataURL(file);
    });
  }


  readImage(name: string, fileString: string,width : number, height : number): Observable<string> {
    return new Observable(obs => {
      const img = new Image();
      img.onload = () => {
        if (img.width < width || img.height < height) {
          this.nameArray.push(name);
          this.fileNames = this.fileNames + name + " ,";
        }
        else {
          img.width = width;
          img.height = height;
        }
        obs.next(img.src as string);

        obs.complete();
      }
      img.src = fileString;
    });
  }

  bindGrid() {
    this.isShowGrid = true;
    this.spin = true;
    var json =  JSON.parse(JSON.stringify({'entityId':this.id,'entityTypeId':this.entityTypeId}));
    //var json = JSON.parse(JSON.stringify({ entityId: this.id, entityTypeId: this.entityTypeId, isDamagedPiece: this.isDamagedPiece }));
    this.subscription = this.get(
      {
        path: 'api/ProductWebPictures',
        queryParams : {
          "Query":JSON.stringify(json)
        }
      }).subscribe((t: any) => {
        this.spin = false;
        this.documnentList = t;
        this.products = t[0].products;
        this.productsList = t[0].productList;
        if(this.productsList.length >= 4)
        {
          this.hideButton = false;
        }
        else
        {
          this.hideButton = true;
        }
        if(this.products.length == 0)
        {
          var model = new ProductWebPictureViewModel();
          model.pictureIndex = 1;
          model.pictureType = 1;
          model.pictureURL = DEFAULT_BLOB_IMAGE_URL;
          this.products.push(model);
          model.pictureType = 2;
          this.products.push(model);
          model.pictureType = 3;
          this.products.push(model);
          this.productsList.push({EntityId : this.id, EntityTypeId : this.entityTypeId, DisplayOrder :  1, PictureIndex : 1,IsDrag : 0,Destination : 1});
        }
        if(this.products.length>0){
          this.products.forEach(x => {
            if(this.checkSupportedExtension(x.pictureURL?.split("?")[0])){
              x.isPreviewSupported = true;
            }
            else{
              x.isPreviewSupported = false;
            }
          })
        }
        //<-----For No Record Found Start------>


        setTimeout(function () {
          this.showComponent = true;
        }, 1000)
      })


    //this.spin = false;
  }

  bindFormGroup(): ProductWebPicture {
    let document = new ProductWebPicture();
    document.productWebPictureID = 0;
    document.pictureID = 0;
    document.pictureName = "";

    document.entityId = this.id;
    document.entityTypeId = this.entityTypeId;
    document.displayOrder = 1;
    document.fileName = "test";
    document.isDamagedPiece = this.isDamagedPiece == null || this.isDamagedPiece == undefined ? false : this.isDamagedPiece;
    document.pictures = new Array<PictureViewModel>();
    document.modifiedBy = 0;
    document.createdBy = 0;
    document.statusId = StatusEnum.Active;
    return document;
  }

  async uploadDocument(formBuilder: RxFormBuilder,ImageType : number, PictureIndex : number) {
    this.pictureWebFormGroup.submitted = true;
    if (this.pictureWebFormGroup.valid && this.isArrayCount > 0) {
      let data = this.pictureWebFormGroup.value;

      this.isShowGrid = true;
      if (this.nameArray.length == this.isArrayCount && this.message != "" && this.message != undefined) {
        this.isArrayCount = 0;
        this.baseToastr.error(this.message);
        this.message = "";
        this.nameArray.splice(0, this.nameArray.length);
        this.ImageName = "";
        this.ImageSizeValidationMessage = "";
      }
      else if (data.pictures.length == 0 && this.ImageSizeValidationMessage) {
        if (this.message != "") {
          this.isArrayCount = 0;
          this.nameArray.splice(0, this.nameArray.length);
          this.baseToastr.error(this.message);
          this.message = "";
          this.ImageSizeValidationMessage = "";
          this.ImageName = "";
          this.pictureWebFormGroup.value.pictures = [];
        }
      }
      else {
        this.spin = true;
        this.isArrayCount = 0;
        this.pictureWebFormGroup.patchValue({
          pictureType : ImageType,
          pictureIndex : PictureIndex
        });
        let arrayOfPromises = [];
      let allfileNames = [];
      let data = this.pictureWebFormGroup.value;
      for (let i = 0; i < this.pictureWebFormGroup.value.pictures.length; i++) {
        const document = this.pictureWebFormGroup.value.pictures[i];
      let newGUID = uuidv4();

      let fileName =  BLOB_BASE.PieceWebPictures + `${newGUID}_${new Date().toISOString()}.${document.fileName
        .split(".")
        .pop()}`;
        allfileNames.push({
          blobFileName:fileName,
          blobPath:BLOB_BASE.PieceWebPictures,
          size:document.file.size,
          fileName:document.file.name
        });

        arrayOfPromises.push(
          this.blobState.uploadItems(document.file, fileName)
          );

          this.pictureWebFormGroup.value.pictures[i] = {
            ...this.pictureWebFormGroup.value.pictures[i],
            blobFileName: fileName,
            blobPath: BLOB_BASE.PieceWebPictures,
            size: document.file.size
          };
        }
        let combinedRes = await Promise.all(arrayOfPromises);
      let finishedFileUploads = [];
      let erroredFileUploads = [];
      combinedRes.forEach((res) => {
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.PieceWebPictures + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
        let fileNameObj = allfileNames.find(
          (x) => x.blobFileName == uploadedFileName
        );
        // IF SUCCEED TO UPLOAD
        if (
          res &&
          (res._response.status == 200 || res._response.status == 201)
        ) {
          if (fileNameObj != undefined) finishedFileUploads.push(fileNameObj);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.baseToastr.error(
            "Error occured while uploading files! Please try again."
          );
          if (fileNameObj != undefined) erroredFileUploads.push(fileNameObj);
        }
      });
        this.post({ path: 'api/ProductWebPictures', body: this.pictureWebFormGroup.toFormData() }).subscribe(data => {
          //this.spin = false;
          let documentArray = this.pictureWebFormGroup.get("pictures") as FormArray;
          this.pictureWebFormGroup.value.pictures = [];
          documentArray.controls = [];

          //this.baseToastr.success("Data added successfully");
          var existsAlert = getLocalizedValue("Data_Added");
          if (existsAlert) {
            this.baseToastr.success(existsAlert);
          }
          this.nameArray.splice(0, this.nameArray.length);
          setTimeout(() => {
            if (this.message != "") {
              this.baseToastr.error(this.message);
              this.message = "";
              this.ImageSizeValidationMessage = "";
              this.ImageName = "";
            }
            this.bindGrid();
          }, 400)


        })
      }
    }
  }
  onDelete(productWebImage: ProductWebPictureViewModel,pictureIndex : number = 0) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;

        this.delete({ path: 'api/ProductWebPictures', params: [productWebImage.productWebPictureId], queryParams : {"StartIndex":pictureIndex,"entityId" : this.id, "entityTypeId":this.entityTypeId} ,body: null }).subscribe(data => {
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            this.baseToastr.success(existsAlert);
          }
          this.bindGrid();
        })

      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Picture");
    if (existsAlert) {
      return existsAlert;
    }
  }

  checkSupportedExtension(fileName:string):boolean{
    let extension = fileName
        .substr(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
    let checkExtension = this.supportedExtensions.find(x => x == extension.toLowerCase());
    if(checkExtension==null){
      return false;
    }
    else{
      return true;
    }
  }
}
