import {LocationGroupBase,LocationGroupWarehouseMappingBase,} from '@app/database-models'
import { propArray, required, prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class LocationGroup extends LocationGroupBase 
{




//#region Generated Reference Properties
  //#region locationGroupWarehouseMapping Prop
  @propArray(LocationGroupWarehouseMappingBase)
  locationGroupWarehouseMapping: LocationGroupWarehouseMappingBase[];
//#endregion locationGroupWarehouseMapping Prop

//#endregion Generated Reference Properties

  @prop()
  @required()
  warehouseIds: string;










}
