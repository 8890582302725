import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CoreComponent } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { AppGrid } from 'src/app/domain/app-grid';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { TaskDetailsViewModel } from 'src/app/view-model/task-details-view-model';
import { TaskDetailViewComponent } from '../view/task-detail-view.component';
import { TaskDetailAddComponent } from '../add/task-detail-add.component';

@multilingual("TaskDetailListComponent")
@Component({
  selector: 'app-task-detail-list',
  templateUrl: './task-detail-list.component.html'
})
export class TaskDetailListComponent extends CoreComponent implements OnInit, OnDestroy {
  @Input() entityId:any
  @Input() entityTypeId:any

  TaskDetailList: TaskDetailsViewModel[];
  subscription: any;
  TaskDetailListGrid: AppGrid;
  isFilter: boolean;
  totalRecords: number;
  showGrid: boolean;
  isNoRecordFound: boolean;
  isShowSearch: boolean = false;
  titleSearch: string;
  id: any;
  modalView: ModalView;
  spin: boolean;
  taskDetailForModal: TaskDetailsViewModel;
  rowCount: number = PaginationEnum.RowCount;
  pageIndex: number = PaginationEnum.PageIndex;
  sortOrder: boolean = false;
  orderBy: string = "taskDetailIdText";
  gridSearchString: string = '';
  toastr: BaseToastr;
  loggedInUser: number;
  taskDescriptionShowHideButton: boolean = false;
  isShowAllTaskDescription: boolean = false;
  isFromMyProfile: boolean = false;
  _this: any;

  constructor(modalView: ModalView, private activatedRoute: ActivatedRoute, private browserStorage: BrowserStorage,  private router: Router) {
    super();
    this.toastr = new BaseToastr();
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })

    this.activatedRoute.queryParams.subscribe(t => {
      this.isFromMyProfile = t['myTasks']=="true" ? true:false;
    })

    this.modalView = modalView;
    this._this = this;
    if (this.browserStorage.local.get("userId") != null) {
      this.loggedInUser = this.browserStorage.local.get("userId");
    } else {
      this.loggedInUser = 0;
    }
  }

  ngOnInit(): void {
    this.taskDetailForModal = new TaskDetailsViewModel();
    this.titleSearch = getLocalizedValue("Title_Search");
    this.isFilter = false;
    this.spin = false;
    this.totalRecords = 0;
    this.bindGrid();
  }

  bindGrid(isOnload: boolean = true) {
    this.spin = true;
    this.showGrid = true;
    this.taskDescriptionShowHideButton = false;
    var json = {
      entityId: this.entityId,
      entityTypeId : this.entityTypeId,
      timezoneOffset: new Date().getTimezoneOffset(),
      rowCount: this.rowCount,
      pageIndex: this.pageIndex,
      orderBy: this.orderBy,
      sortOrder: this.sortOrder,
      searchString: this.gridSearchString
    }

    if(this.isFromMyProfile){
      json["taskStatus"]=1;
    }else{
      json["taskStatus"]=0;
    }
    this.get({ path: 'api/TaskDetails', queryParams: { query: encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: any) => {
      this.TaskDetailList = t;

      this.spin = false;
      if (this.TaskDetailList.length > 0) {
        this.TaskDetailList.forEach(x => x.description = (x.description as any).replaceAll('\n','<br/>'))
        this.totalRecords = this.TaskDetailList[0].totalCount;
        this.isNoRecordFound = false;
        this.showGrid = true;
      }
      else {
        this.showGrid = false;
        this.isNoRecordFound = true;
      }
      if (isOnload && this.totalRecords > 0) {
        this.isShowSearch = true;
      }
      for (let element of this.TaskDetailList) {
        if (element.descriptionCount > 100) {
          this.taskDescriptionShowHideButton = true;
          break;
        }
      }

      if (this.showGrid) {
        if (!this.isFilter) {
          this.TaskDetailListGrid = new AppGrid(this.TaskDetailList, TaskDetailsViewModel, { actions: { onEditData: this.onEditData.bind(this), onTaskStatusSelect: this.onTaskStatusSelect.bind(this), onModuleNameClick: this.onModuleNameClick.bind(this), onTaskIDClick: this.onTaskIDClick.bind(this), onMoreClick: this.onTaskDescriptionMoreClick.bind(this) } });
          this.TaskDetailListGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.TaskDetailListGrid.gridColumns.forEach((col) => {
            if(col.name == 'CheckedAt') col.visible = true;
            if(col.name == 'action') col.visible = false;
            if(col.name == 'isCompleted') col.visible = false;
            if(col.name == 'taskDetailIdLink') col.visible = false;
            if(col.name == 'moduleDetail') col.visible = false;
            if(col.name == 'taskDetailIdText') col.visible = true;
          });
        }
        else {
          if (this.TaskDetailListGrid != null) {
            this.TaskDetailListGrid.storeProcedure.length = this.totalRecords;
            this.TaskDetailListGrid.updateSource([]);
            this.TaskDetailListGrid.updateSource(this.TaskDetailList);
          }
        }
        this.TaskDetailListGrid.maxPerPage = this.rowCount;
        this.TaskDetailListGrid.componentId = this.componentName;
        this.TaskDetailListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(' ');
        this.TaskDetailListGrid.designClass.headerClass = "table-header-sticky".split(" ");
      }
    })
  }
  onTaskStatusSelect(x, y) {
    var taskID = x.taskDetailId
    this.TaskDetailList.forEach(x => {
      if (x.taskDetailId == taskID) {
        x.isCompleted = y.target.checked;
      }
    })
  }

  onMoreClickAllTaskDescription(){
    this.isShowAllTaskDescription = !this.isShowAllTaskDescription;
    this.TaskDetailListGrid.destroy();
    this.TaskDetailListGrid = new AppGrid(this.TaskDetailList, TaskDetailsViewModel, {actions: { onEditData: this.onEditData.bind(this), onTaskStatusSelect: this.onTaskStatusSelect.bind(this), onModuleNameClick: this.onModuleNameClick.bind(this), onTaskIDClick: this.onTaskIDClick.bind(this), onMoreClick: this.onTaskDescriptionMoreClick.bind(this) }});
    this.TaskDetailListGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: this.pageIndex,
      onPageSorting: this.onPageSorting.bind(this)
    }
    this.TaskDetailListGrid.gridColumns.forEach((col) => {
      if (col.name == 'description') {
        col.visible = !this.isShowAllTaskDescription;
      }
      if (col.name == 'taskDescription') {
        col.visible = this.isShowAllTaskDescription;
      }
      if(col.name == 'CheckedAt') col.visible = true;
      if(col.name == 'action') col.visible = false;
      if(col.name == 'isCompleted') col.visible = false;
      if(col.name == 'taskDetailIdLink') col.visible = false;
      if(col.name == 'moduleDetail') col.visible = false;
      if(col.name == 'taskDetailIdText') col.visible = true;
    });
    this.TaskDetailListGrid.maxPerPage = this.rowCount;
    this.TaskDetailListGrid.currentPage = this.pageIndex;
    this.TaskDetailListGrid.componentId = this.componentName;
    this.TaskDetailListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(' ');
        this.TaskDetailListGrid.designClass.headerClass = "table-header-sticky".split(" ");
  }

  onTaskDescriptionMoreClick(t, x) {
    var element = document.getElementById("task_desc_" + t.taskDetailId);
    var maskedElement = document.getElementById("maskedtask_desc_" + t.taskDetailId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");

    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  onEditData(taskDetail: TaskDetailsViewModel) {
    this.put({ path: 'api/TaskDetails', body: taskDetail, params: [taskDetail.taskDetailId] }).subscribe(data => {
      var existsAlert = getLocalizedValue("Data_Updated");
          if (existsAlert) {
              this.toastr.success(existsAlert);
          }
      this.isFilter = true;
      this.bindGrid();
    });
  }

  onModuleNameClick(taskDetail: TaskDetailsViewModel) {
    let url: any;
    let moduleData = JSON.parse(taskDetail.moduleDetail)
    url = this.router.serializeUrl(this.router.createUrlTree([moduleData.module, moduleData.entityId], { queryParams: { activeTab: moduleData.activeTab !='' ? moduleData.activeTab:'' } }));
    redirectOnCTRL(url, this.router, event);
  }

  onTaskIDClick(taskDetail: TaskDetailsViewModel){
    this.modalView.show(TaskDetailViewComponent, { taskDetail: taskDetail, loggedInUserId: this.loggedInUser, entityId : this.entityId, entityTypeId:this.entityTypeId}).then(e=>{
      this.isFilter = true;
      this.bindGrid();
    });
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.sortOrder = y;
    this.orderBy = x;
    this.TaskDetailListGrid.storeProcedure.nextPage = z;
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.pageIndex = x;
    this.rowCount = this.TaskDetailListGrid.maxPerPage;
    this.TaskDetailListGrid.storeProcedure.nextPage = x;
    this.bindGrid(false);
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
      this.pageIndex = PaginationEnum.PageIndex;

      if (this.TaskDetailListGrid != null) {
        this.TaskDetailListGrid.updateSource([]);
        this.rowCount = this.TaskDetailListGrid.maxPerPage;
        this.TaskDetailListGrid.storeProcedure.nextPage = 1;
      }
      this.bindGrid();
    }, 1000);
    this.spin = true;
  }

  showAddForm(){
    this.modalView.show(TaskDetailAddComponent, { taskDetail: null, loggedInUserId: this.loggedInUser,entityId : this.entityId, entityTypeId:this.entityTypeId}).then(e=>{
      if(this.TaskDetailListGrid != undefined && this.TaskDetailListGrid != null){
        this.isFilter = true;
      }
      this.bindGrid();
    });
  }

  resetFilter(){
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['myTasks']) {
        const newQueryParams = { ...queryParams };
        delete newQueryParams['myTasks'];
        const navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['users', this.id], navigationExtras);
      }
    });
  }


  get componentName(): string {
    return "TaskDetailListComponent";
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
