import {ModuleContentBase,ApplicationModuleBase,} from '@app/database-models'
//Generated Imports
export class ModuleContent extends ModuleContentBase 
{




//#region Generated Reference Properties
//#region applicationModule Prop
applicationModule : ApplicationModuleBase;
//#endregion applicationModule Prop

//#endregion Generated Reference Properties












}