import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractActivityLogType } from '../domain/abstract-activity-log-type';
import { ActivityLogType, vActivityLogType } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { vActivityLogTypeBase } from '@app/database-models';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { PaginationViewModel } from "src/app/view-model/pagination-view-model";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("ActivityLogTypeListComponent")
@access({ accessLevel: RolePermissionEnums.ActivityLogTypes, action: "get" })

@Component({
  selector: "app-activity-log-type-list",
  templateUrl: './activity-log-type-list.component.html'
})
export class ActivityLogTypeListComponent extends AbstractActivityLogType implements OnInit, OnDestroy {
  activityLogTypes: vActivityLogType[];
  subscription: any;
  activityLogTypeGrid: AppGrid;
  logTypeGridColumns: any[];
  activityLogTypeArray: ActivityLogType[];
  activityLogType: ActivityLogType;
  activityLogTypeEdit: any = dbAccessModule[RolePermissionEnums.ActivityLogTypes]["put"];
  headerBtnChk: boolean = false;
  gridSearchString: string = "";
  isSaveBtnDisable: boolean = true;
  json: any;
  paginationViewModel: PaginationViewModel;
  isHeaderBtnChk: boolean = false;
  rowCount: number = PaginationEnum.RowCount;
  pageIndex: number = PaginationEnum.PageIndex;
  sortOrder: string = "true";
  orderByColumn: string = "activityName";
  moduleID: number;
  titleHelp: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;
  constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private location : PlatformLocation) {
    super();
    this.modalView = modelView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.moduleID = RolePermissionEnums.ActivityLogTypes;
    location.onPopState(() => this.dialog.hide());
  }
  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Activity Types - G-Trac Admin");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.bindGrid();
    this.activityLogTypeArray = new Array<ActivityLogType>();
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
}


  bindGrid(page: number = 0) {
    // this.paginationViewModel = new PaginationViewModel();
    // this.paginationViewModel.sortOrder = "true";
    // this.paginationViewModel.orderBy = "activityName";
    var json = {
      rowCount: this.rowCount,
      pageIndex: page == 0 ? this.pageIndex : page,
      sortOrder: this.sortOrder,
      orderBy: this.orderByColumn,
      searchString: this.gridSearchString,
    }
    this.spin = true;
    this.isShowGrid = true;
    this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: vActivityLogType[]) => {
      this.spin = false;
      if (this.activityLogTypeGrid) {
        this.activityLogTypeGrid.storeProcedure.length = 0;
      }
      if (this.activityLogTypeGrid != null) {
        this.isFilter = true;
      }
      this.activityLogTypes = t;
      if (this.activityLogTypes.length > 0) {
        this.totalRecords = this.activityLogTypes[0].totalCount;
        this.isNoRecordFound = false;
      }
      else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }
      this.activityLogTypes.forEach(element => {
        element.isEnabled = this.headerBtnChk ? this.headerBtnChk : element.isEnabled;
        let activityLogTypeObj = new ActivityLogType();
        activityLogTypeObj.activityLogTypeId = element.activityLogTypeId;
        activityLogTypeObj.isEnabled = this.headerBtnChk ? this.headerBtnChk : element.isEnabled;
        activityLogTypeObj.activityName = element.activityName;
        activityLogTypeObj.systemKeyword = "";
        this.activityLogTypeArray.push(activityLogTypeObj);
      });
      if (this.isShowGrid) {
        if (!this.isFilter || this.activityLogTypeGrid == null) {
          this.activityLogTypeGrid = new AppGrid(this.activityLogTypes, vActivityLogType, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
          this.activityLogTypeGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.activityLogTypeGrid.gridColumns.forEach(x => {
            if (x.name == "isEnabled") {
              x.visible = this.activityLogTypeGrid.authorize({ accessLevel: RolePermissionEnums.ActivityLogTypes, action: "put" });
            }
          })
        }
        else {
          this.activityLogTypeGrid.storeProcedure.length = this.totalRecords;
          this.activityLogTypeGrid.updateSource([]);
          this.activityLogTypeGrid.updateSource(this.activityLogTypes)
        }
      }
      this.activityLogTypeGrid.maxPerPage = this.rowCount;
      this.activityLogTypeGrid.componentId = this.componentName;
      this.activityLogTypeGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
      this.activityLogTypeGrid.designClass.headerClass = "table-header-sticky".split(" ");
      this.showComponent = true;
    })
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.sortOrder = y;
    this.orderByColumn = x;
    this.activityLogTypeGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.pageIndex = x;
    this.rowCount = this.activityLogTypeGrid.maxPerPage;
    this.activityLogTypeGrid.storeProcedure.nextPage = x;
    this.activityLogTypeArray = [];
    if (!this.headerBtnChk) {
      this.isHeaderBtnChk = false;
    }
    this.isSaveBtnDisable = true;
    this.bindGrid(x);
  }

  onCheckboxSelect(t, x, y, z) {
    // let activityLogTypeObj = new ActivityLogType();
    // activityLogTypeObj.activityLogTypeId = t.activityLogTypeId;
    // activityLogTypeObj.isEnabled = x.target.checked;
    // activityLogTypeObj.activityName = t.activityName;
    // activityLogTypeObj.systemKeyword = t.systemKeyword;
    // let existObj = this.activityLogTypeArray.find(x => x.activityLogTypeId === t.activityLogTypeId);
    // if (existObj) {
    //   this.activityLogTypeArray.splice(this.activityLogTypeArray.findIndex(x => x.activityLogTypeId == existObj.activityLogTypeId), 1);
    // }
    // else {
    //   this.activityLogTypeArray.push(activityLogTypeObj);
    // }

    let existObj = this.activityLogTypeArray.findIndex(x => x.activityLogTypeId == t.activityLogTypeId);
    if (existObj >= 0) {
      this.activityLogTypeArray[existObj].isEnabled = x.target.checked;
      if (this.isHeaderBtnChk) {
        this.isSaveBtnDisable = false;
      } else {
        let originalValue = this.activityLogTypes[this.activityLogTypes.findIndex(x => x.activityLogTypeId == t.activityLogTypeId)].isEnabled;
        if (originalValue == x.target.checked) {
          this.isSaveBtnDisable = true;
        } else {
          this.isSaveBtnDisable = false;
        }
      }
    }
  }

  onHeaderCheckBoxSelect(t, x) {
    this.headerBtnChk = x.target.checked;
    this.isHeaderBtnChk = true;
    this.isSaveBtnDisable = false;
    if (this.headerBtnChk) {
      this.activityLogTypes.forEach(element => {
        element.isEnabled = this.headerBtnChk;
      });
      this.activityLogTypeArray.forEach(element => {
        element.isEnabled = this.headerBtnChk;
      });
    } else {
      this.activityLogTypes.forEach(element => {
        element.isEnabled = this.headerBtnChk;
      });
      this.activityLogTypeArray.forEach(element => {
        element.isEnabled = this.headerBtnChk;
      });
    }
    this.activityLogTypeGrid.updateSource([]);
    this.activityLogTypeGrid.updateSource(this.activityLogTypes);
  }

  editActivityLogType() {
    this.spin = true;
    this.activityLogType = new ActivityLogType();
    this.activityLogType.activityLogTypeIds = this.activityLogTypeArray;;
    this.put({ body: this.activityLogType, params: [0] }).subscribe(data => {
      this.spin = false;
      var existsAlert = getLocalizedValue("Data_Updated");
      if (existsAlert) {
        //this.toastr.success("Data Updated Successfully")
        this.toastr.success(existsAlert);
      }
      this.activityLogTypeArray = [];
      this.activityLogTypeGrid.destroy();
      this.activityLogTypeGrid = undefined;
      if (!this.headerBtnChk) {
        this.isHeaderBtnChk = false;
      }
      this.headerBtnChk = false;
      this.isSaveBtnDisable = true;
      this.isFilter = true;
      this.bindGrid();
    })
  }
  onEdit(activityLogTypes: vActivityLogType) {
    this.router.navigate(["activity-log-types", activityLogTypes.activityLogTypeId]);
  }

  onDelete(activityLogTypes: vActivityLogType) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [activityLogTypes.activityLogTypeId] }).subscribe(data => {
          this.spin = false;
          this.activityLogTypeGrid.remove(activityLogTypes.activityLogTypeId);
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
          this.router.navigate(['/activity-log-types']);
        })
      }
      else {
        this.dialog.hide()
      }

    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Activity_LogType");
    if (existsAlert) {
      return existsAlert;
    }
  }
  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
      this.totalRecords = 0;
      this.pageIndex = 1;
      this.bindGrid();
    }, 1000)
    this.spin = true;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ActivityLogTypeListComponent";
  }
}
