import { gridColumn } from '@rxweb/grid';

export class DepartmentCustomerViewModel{
    totalCount:number;
    userId:number;
    @gridColumn({parameter:true,style: { width: "20%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 1, allowSorting: true, headerKey: 'CustomerDetailComponent_Name_gh', keyColumn: false })
    fullName:string;
    @gridColumn({parameter:true,style: { width: "10%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 0, allowSorting: true, headerKey: 'CustomerDetailComponent_Email_gh', keyColumn: false })
    email:string;
    @gridColumn({
        template:{
            div:{
              attributes:{
                innerHTML:function(x,y){
                 return this.viewButton;
                }
              },
            }
        }
        ,parameter:true,style: { width: "3%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 2, allowSorting: false, headerTitle: 'View', keyColumn: false })
    viewButton:string;
}