import { TemplateConfig } from "@rxweb/grid";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";

export const BIND_TASK_STATUS_CHECKBOX_GRID: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            style:{"margin-bottom":"0px"},
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style"],
                  style: {
                    "margin-top": "-4px",
                    "position": "relative",
                    "margin-left": "auto",
                    "cursor": "pointer",
                  },
  
                  event: {
                    input: "onTaskStatusSelect"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      
                      return this[e.name];
                    },
                    checked: function (e) {
                      if (this.isCompleted) {
                          return true;
                      }
                      else {
                          return false;
                      }
                  },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  
  export const BIND_TASK_STATUS_GRID: TemplateConfig = {
    span: {
        class: ["label", function (e) {
            var labelClass = "";
  
            if(this['isCompletedChecked']){
                labelClass = "label-primary";
            }
            else{
              labelClass = "Awaiting"
            }
  
            return labelClass;
        }],
        childrens: [{
            text: {
                text: function (e) {
                    var status = "";
  
                    if (this['isCompletedChecked']) {
                      status = getLocalizedValue('Task_Detail_Status_Completed_t');
                    }
                    else {
                      status = getLocalizedValue('Task_Detail_Status_Pending_Completion_t')
                    }
  
                    return status;
                }
            }
        }]
    }
  }
  
  export const NOTE_DETAIL_MODAL: TemplateConfig = {
    div: {
      childrens: [
        {
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onModuleNameClick"
            },
            childrens: [{
              text: {
                text: function (e) {
                  return this[e.name];
                }
              }
            }]
          },
        }
      ]
    },
  };

  export const TASK_DETAIL_MODAL: TemplateConfig = {
    div: {
      childrens: [
        {
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onTaskIDClick"
            },
            childrens: [{
              text: {
                text: function (e) {
                  return "Task #"+ this.taskDetailId;
                }
              }
            }]
          },
        }
      ]
    },
  };

  export const MODULE_NAME :TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                      click: "onModuleNameClick"
                    },
                    childrens: [{
                      text: {
                        text: function (e) { 
                          return this.module;
                        }
                      }
                    }],
                    attributes: {
                        href: function(e) {
                            if (this.moduleDetail != null || this.moduleDetail != undefined) {
                                let moduleData = JSON.parse(this.moduleDetail)
                                let url = "/"+moduleData.module+"/" + moduleData.entityId;
                                if(moduleData.activeTab!= '') url+= "?activeTab="+moduleData.activeTab;
                                return url
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                          if (this.moduleDetail != null || this.moduleDetail != undefined) {
                            let moduleData = JSON.parse(this.moduleDetail)
                            let url = "/"+moduleData.module+"/" + moduleData.entityId;
                            if(moduleData.activeTab!= '') url+= "?activeTab="+moduleData.activeTab;
                            return url
                          }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    },
}