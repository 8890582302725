import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductAttributeRecordBase {

//#region productAttributeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'productAttributeId', keyColumn: true})
        productAttributeId : number;
//#endregion productAttributeId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop

}