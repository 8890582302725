import {BangleSizeLocalizationBase,} from '@app/database-models'
//Generated Imports
export class BangleSizeLocalization extends BangleSizeLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}