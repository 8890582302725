import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DocumentTypeBase {

//#region documentTypeId Prop
        @prop()
        documentTypeId : number;
//#endregion documentTypeId Prop


//#region documentTypeName Prop
        @required()
        @trim()
        @notEmpty()
        documentTypeName : string;
//#endregion documentTypeName Prop

//#region isRestricted Prop
        @prop({defaultValue:false})
        isRestricted: boolean;
//#region isRestricted Prop

//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

        @prop()
        createdOn: Date;

        @prop()
        createdBy: number;

        @prop()
        modifiedBy: number;

        @prop()
        modifiedOn: Date;

}