import {vPriceListExportTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPriceListExportTypeLookUp extends vPriceListExportTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}