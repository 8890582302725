import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerChildRelationshipRecordBase {

//#region customerRelationshipChildId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerRelationshipChildId', keyColumn: true})
        customerRelationshipChildId : number;
//#endregion customerRelationshipChildId Prop


//#region status Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'status', keyColumn: false})
        status : string;
//#endregion status Prop


//#region genderName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'genderName', keyColumn: false})
        genderName : string;
//#endregion genderName Prop


//#region customerRelationShipStatus Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'customerRelationShipStatus', keyColumn: false})
        customerRelationShipStatus : string;
//#endregion customerRelationShipStatus Prop

}