import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { vCustomerEvent } from 'src/app/models/extended-models/v-customer-event';
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { List } from '@rxweb/generics';
import { DatePipe, PlatformLocation } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CoreComponent } from '@rxweb/framework';
import { http } from '@rxweb/http';
import { multilingual } from '@rxweb/localization';
import { vCustomerSupplierEvent } from 'src/app/models/extended-models/vCustomerSupplierEvent';
import { AbstractEvent } from '../domain/abstract-event';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { vRiskEvent } from 'src/app/models/database-models/v-risk-event';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { ProjectEventAddComponent } from 'src/app/components/project/project-events/add/project-event-add.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { GridColumnConfig } from '@rxweb/grid';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { vEventBookings } from 'src/app/models/database-models/v-event-bookings';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';

@multilingual("EventDetailComponent")
@Component({
  selector: 'app-event-details',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})

@http({
  path: "api/Events",
})

export class EventDetailComponent extends AbstractEvent implements OnInit {
  spin: boolean = false;
  showComponent: boolean = false;
  events: any[];
  subscription: any;
  customerEventGrid: AppGrid;
  isNoRecordFound: boolean;
  isShowGrid: boolean;
  isShowSearch : boolean = false;
  data: List<any>;
  id: number;
  isFromMyProfile: boolean = false;
  totalRecords: number = 0;
  isFilter: boolean = false;
  @Input() component: number;
  @Input() isRisk : number;
  json: any;
  titleSearch: string;
  titleGridColumnSetting: string;
  isShowGridColumn: boolean = false;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";
  isHideAll: boolean = false;
  isShowAll: boolean = false;
  isLongTextExists: boolean = false;

  constructor(private datePipe: DatePipe, private activatedRoute: ActivatedRoute, router: Router,ModalView: ModalView, private browserStorage: BrowserStorage,blobState: BlobUploadsViewStateService,private location : PlatformLocation) {
    super(blobState);
    this.modalView = ModalView;
    this.router = router;
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];    
    })
    this.activatedRoute.queryParams.subscribe(t => {
      this.isFromMyProfile = t['myEvents']=="true" ? true:false;
    })
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.json = JSON.parse(JSON.stringify({ pageIndex: 1, rowCount: 25, sortOrder: "true", orderBy: "eventName", loggedInUser:0 }));
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
  }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
          this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      setTimeout(() => {
          this.bindGrid();
      }, 0);
  })
  }

  bindGrid(isOnload: boolean = true) {
    this.isShowGrid = true;
    this.spin = true;
    if(this.isRisk == 1)
    {
      this.json["riskMenuId"] = this.id;
    }
    else if(this.isRisk == 2)
    {
      this.json["bookingId"] = this.id;
    }
    if(this.isFromMyProfile){
      this.json["isFromMyProfile"]=1;
    }else{
      this.json["isFromMyProfile"]=0;
    }
    this.subscription = this.get({ queryParams: { userId: this.id, flag: true, isCorporate: this.component == 1 ? true : false, moduleId : this.isRisk == 1 ? RolePermissionEnums.GemfieldsRiskMenu : this.isRisk == 2 ? RolePermissionEnums.Bookings : 0,
                                                  query: encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((t: any) => {
      this.spin = false;
      if (this.component == 1) {
        this.events = t;
      } else {
        this.events = t;
      }

      if (this.events.length > 0) {

        this.isNoRecordFound = false;
        this.isShowGrid = true;
        this.totalRecords = this.events[0].TotalCount;
        if(isOnload && this.totalRecords > 0){
          this.isShowSearch = true;
        }
        // if (this.component == 1) {

        //   this.events.forEach(x => {
        //     x.Status = (x.Invited == true ? 'Invited, ' : '')
        //       + (x.Involved == true ? 'Involved, ' : '')
        //       + (x.Accepted == true ? 'Accepted, ' : '')
        //       + (x.Attended == true ? 'Attended, ' : '')
        //       + (x.SendEInvitation == true ? 'Send E-Invitation, ' : '')
        //       + (x.Declined == true ? 'Declined ' : '')
        //       x.Status=x.Status.replace(/,\s*$/, "");
        //  })

        // } else {
        // this.events.forEach(x => {
        //   x.status = this.formStatus(x);
        // })

        // }
        this.events.forEach(x => {
          x.status = this.component == 1 ? this.formCorporateStatus(x) : this.formStatus(x);
        })


        //if (this.component == 1) {
        if (!this.isFilter) {
          if (this.component == 1) {

            this.customerEventGrid = new AppGrid(this.events, vCustomerSupplierEvent, { actions: { onRowSelect: this.onView.bind(this), onOneLink: this.onEditLink.bind(this), onInviteesAttendeesClick: this.onInviteesAttendeesClick.bind(this), onMoreClick: this.onMoreClick.bind(this) } });
          }
          else {
            if(this.isRisk == 1)
            {
              this.customerEventGrid = new AppGrid(this.events, vRiskEvent, {actions : {onDelete : this.onDelete.bind(this)}});
            }
            // this.isRisk = 2 means request comes from booking edit.
            else if (this.isRisk == 2)
            {
              this.customerEventGrid = new AppGrid(this.events, vEventBookings);
            }
            else
            {
              this.customerEventGrid = new AppGrid(this.events, vCustomerEvent, { actions: { onRowSelect: this.onView.bind(this), onOneLink: this.onEditLink.bind(this), onMoreClick: this.onMoreClick.bind(this) , onDocClick: this.onDocumentDownloadLink.bind(this) } });
            }
          }
          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
              this.customerEventGrid.gridColumns.forEach(y => {
                if (y.name == x.ColumnName)
                y.visible = x.Visible;
              });
            });
            this.gridColumnConfig = this.customerEventGrid.gridColumns;
            this.gridColumnSettings.emit(this.gridColumnConfig)
          }
          this.checkForLongTexts()
          this.customerEventGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          // this.customerEventGrid.design(document.getElementById("customerEvents"));
        }
        else {
          this.customerEventGrid.storeProcedure.length = this.totalRecords;
          this.customerEventGrid.updateSource(this.events)
          this.checkForLongTexts();
        }

        // } else {
        //   if (!this.isFilter) {
        //     this.customerEventGrid = new AppGrid(this.events, vCustomerEvent, { actions: { onRowSelect: this.onView.bind(this), onOneLink: this.onEditLink.bind(this) } });
        //     this.customerEventGrid.storeProcedure = {
        //       length: this.totalRecords,
        //       onPageChanging: this.onPageChanging.bind(this),
        //       nextPage: 1,
        //       onPageSorting: this.onPageSorting.bind(this)
        //     }
        //     // this.customerEventGrid.design(document.getElementById("customerEvents"));
        //   } else {
        //     this.customerEventGrid.storeProcedure.length = this.totalRecords;
        //     this.customerEventGrid.updateSource(this.events)
        //   }
        // }

        this.customerEventGrid.componentId = this.componentName;

      }
      else {
        this.isLongTextExists = false;
        this.isShowGrid = false;
        this.isNoRecordFound = true;
      }

    })
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
}
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(element => {
        var index = this.customerEventGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
        if (index != -1) {
          if (this.customerEventGrid.gridColumns[index].visible != element.Visible) {
            element.Visible = this.customerEventGrid.gridColumns[index].visible;
          }
        }
      })
    }
    this.checkForLongTexts()
    this.customerEventGrid.reDesign();
}

  onEditLink(eventData: any) {
    var eventID = this.component == 1 ? eventData.eventID : eventData.eventID;
    let url : any;
    url = this.router.serializeUrl(this.router.createUrlTree(["events", eventID], { queryParams: { activeTab: 'event-info' } }));
    redirectOnCTRL(url,this.router,event);
    //this.router.navigate(["events", eventID], { queryParams: { activeTab: 'event-info' } });
  }
  onView(chainColours: vCustomerEvent) {
  }

  checkForLongTexts() {
    this.isLongTextExists = false;
    let isDescriptionCheckRequired = false;
    this.customerEventGrid.gridColumns.forEach(column => {
      if (column.name == "note" && column.visible) {
        isDescriptionCheckRequired = true;
      }
    });
    if (isDescriptionCheckRequired) {
      for (let element of this.events) {

        if (element.charCount > 100 && isDescriptionCheckRequired) {
          this.isLongTextExists = true;
          break;
        }
      }
    }
  }

  showOrHideAll() {
    this.isShowAll = !this.isShowAll;
    this.isFilter = false;
    this.customerEventGrid.destroy();
    this.customerEventGrid = null;
    this.json["isShowAll"] = this.isShowAll;
    this.json["pageIndex"] = 1;
    this.bindGrid();
  }

  onMoreClick(t, x) {
    var element = this.component == 1? document.getElementById("note_" + t.eventID+ t.CustomerID): document.getElementById("note_" + t.eventID);
    var maskedElement = this.component == 1? document.getElementById("maskednote_" + t.eventID+ t.CustomerID): document.getElementById("maskednote_" + t.eventID);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

    onDocumentDownloadLink(x) {
      //this.isNoteComponent=true;
      this.get({
        path: "api/Document/Notes",
        params: [x.documentId],
      }).subscribe(
        (res: string) => {
          window.open(res, "_blank");
        },
        (err) => { },
        () => {
          this.spin = false;
        }
      );
    }

  onInviteesAttendeesClick(vCustomerSupplierEventObj: vCustomerSupplierEvent) {
    let url : any;
    if (vCustomerSupplierEventObj.IsCorporate) {
      url = this.router.serializeUrl(this.router.createUrlTree(["suppliers", vCustomerSupplierEventObj.CustomerID], { queryParams: { activeTab: 'supplier-edit' } }));
      redirectOnCTRL(url,this.router,event);
      //this.router.navigate(["suppliers", vCustomerSupplierEventObj.CustomerID], { queryParams: { activeTab: 'supplier-edit' } });
    } else {
      url = this.router.serializeUrl(this.router.createUrlTree(["users", vCustomerSupplierEventObj.CustomerID], { queryParams: { activeTab: 'user-edit' } }));
      redirectOnCTRL(url,this.router,event);
      //this.router.navigate(["users", vCustomerSupplierEventObj.CustomerID], { queryParams: { activeTab: 'user-edit' } });
    }
  }

  formStatus(obj: vCustomerEvent): any {
    let statusString: any;
    let accepted: string;
    let invited: string;
    let attended: string;
    let einvite: string;
    let declined: string;
    let involved: string;
    accepted = obj.accepted != undefined && obj.accepted == true ? "checked" : "";
    invited = obj.invited != undefined && obj.invited == true ? "checked" : "";
    attended = obj.attended != undefined && obj.attended == true ? "checked" : "";
    einvite = obj.sendEInvitation != undefined && obj.sendEInvitation == true ? "checked" : "";
    declined = obj.declined != undefined && obj.declined == true ? "checked" : "";
    involved = obj.involved != undefined && obj.involved == true ? "checked" : "";
    statusString = "<input dclass=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.sendEInvitation + "\" " + einvite + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Send E-Invitation</span>&nbsp;"
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.involved + "\" " + involved + " style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\" disabled=\"true\"><span>Involved</span><br/>";
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\"value=\"" + obj.invited + "\" " + invited + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Invited</span>&nbsp;";
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.attended + "\" " + attended + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Attended</span><br/>";
    statusString = statusString + " <input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.accepted + "\" " + accepted + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Accepted</span>&nbsp;";
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.declined + "\" " + declined + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Declined</span>";
    return statusString;
  }

  formCorporateStatus(obj: vCustomerSupplierEvent): any {
    let statusString: any;
    let accepted: string;
    let invited: string;
    let attended: string;
    let einvite: string;
    let declined: string;
    let involved: string;
    accepted = obj.Accepted != undefined && obj.Accepted == true ? "checked" : "";
    invited = obj.Invited != undefined && obj.Invited == true ? "checked" : "";
    attended = obj.Attended != undefined && obj.Attended == true ? "checked" : "";
    einvite = obj.SendEInvitation != undefined && obj.SendEInvitation == true ? "checked" : "";
    declined = obj.Declined != undefined && obj.Declined == true ? "checked" : "";
    involved = obj.Involved != undefined && obj.Involved == true ? "checked" : "";
    statusString = "<input dclass=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.SendEInvitation + "\" " + einvite + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Send E-Invitation</span>&nbsp;"
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.Involved + "\" " + involved + " style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\" disabled=\"true\"><span>Involved</span><br/>";
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\"value=\"" + obj.Invited + "\" " + invited + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Invited</span>&nbsp;";
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.Attended + "\" " + attended + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Attended</span><br/>";
    statusString = statusString + " <input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.Accepted + "\" " + accepted + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Accepted</span>&nbsp;";
    statusString = statusString + "<input class=\"form-check-input checkbox-custom-style\" type=\"checkbox\" value=\"" + obj.Declined + "\" " + declined + " disabled=\"true\" style=\"margin-top: -4px; position: relative; margin-left: auto;margin-right:2px\"><span>Declined</span>";
    return statusString;
  }


  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = y;
    this.json["orderBy"] = x;
    this.customerEventGrid.storeProcedure.nextPage = z;
    this.customerEventGrid.updateSource([]);
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.customerEventGrid.maxPerPage;
    this.customerEventGrid.storeProcedure.nextPage = x;
    this.customerEventGrid.updateSource([]);
    this.bindGrid(false);
  }

  // search(value) {
  //   this.customerEventGrid.search = value.target.value;
  // }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.totalRecords = 0;
      this.json['pageIndex'] = 1;
      this.json['searchString'] =value.target.value;
      if (this.customerEventGrid != null) {
        this.customerEventGrid.updateSource([]);
        this.customerEventGrid.storeProcedure.nextPage = 1;
      }
      this.bindGrid();
    }, 2000);
    this.spin = true;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "EventDetailComponent";
  }
  onDelete(element : vRiskEvent)
  {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({path:'api/SearchUsers/RiskHsePressMediaRemove', body: { eventId: Number(element.eventID), riskMenuDetailId: this.id, riskIncident : 0,isEvent : 1 }}).subscribe(data => {
                  var existsAlert = getLocalizedValue("Data_Deleted");
                  if (existsAlert) {
        //this.toastr.success("Data Deleted Successfully")
                      this.toastr.success(existsAlert);
                  }
                  this.spin = false;
                  this.isFilter = true;
                  this.totalRecords = 0;
                  this.customerEventGrid.updateSource([]);
                  this.bindGrid();
              })
      }
      this.dialog.hide()
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Event");
    if (existsAlert) {
        return existsAlert;
    }
  }
  showAdd()
  {
    // this.isRisk = 2 means request comes from booking edit.

    this.modalView.show(ProjectEventAddComponent, { projectId: this.id, isEvent : 1, isBooking : (this.isRisk == 2 ? 1 : 0 ) }).then(t => {
      if(this.customerEventGrid)
        this.isFilter = true;
      this.bindGrid();
    });
  }

  resetFilter(){
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['myEvents']) {
        const newQueryParams = { ...queryParams };
        delete newQueryParams['myEvents'];
        const navigationExtras: NavigationExtras = {
          queryParams: newQueryParams
        };
        this.router.navigate(['users', this.id], navigationExtras);
      }
    });
  }
}
