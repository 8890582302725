import {vTitleLookUpBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class vTitleLookUp extends vTitleLookUpBase 
{

//#region Generated Reference Properties

//#endregion Generated Reference Properties










}