import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractRole } from '../domain/abstract-role';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Role, vCustomerRoleTypeLookUp, vCustomerRoleDomainLookUp } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("RoleEditComponent")
@access({ accessLevel: RolePermissionEnums.UserRoles, action: "put" })

@Component({
    selector: "app-role-edit",
    templateUrl: './role-edit.component.html'
})
export class RoleEditComponent extends AbstractRole implements OnInit, OnDestroy {
    role: Role;
    subscription: any;
    id: number;
    roleDelete:any=dbAccessModule[RolePermissionEnums.UserRoles]["delete"];
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;


    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, private applicationBroadcaster:ApplicationBroadcaster,modelView: ModalView, private el: ElementRef, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.UserRoles;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("User Roles - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.lookup([{ params: [this.id], propName: "role" }, { path: USER_LOOKUPS.customerRoleDomainLookUp, propName: "customerRoleDomainLookUps" }, { path: USER_LOOKUPS.customerRoleTypeLookUp, propName: "customerRoleTypeLookUps" }]).
            subscribe((lookups: any) => {
                if (lookups.role) {
                    this.roleLookups = lookups;
                    this.roleFormGroup = this.formBuilder.formGroup(Role, this.roleLookups.role) as IFormGroup<Role>;
                    this.showComponent = true;

                }
                else {
                    this.router.navigate(['/roles']);
                }

            })

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }


    editRole(isRedirect) {
        this.roleFormGroup.submitted = true;
        if (this.roleFormGroup.valid) {
            this.spin=true;
            this.put({ body: this.roleFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/roles', this.id]) : this.router.navigate(['/roles']);
            })
        }else{
            for (const key of Object.keys(this.roleFormGroup.controls)) {
                if (this.roleFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    deleteRole() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/roles']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_User_Role");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    get componentName(): string {
        return "RoleEditComponent";
    }
}
