import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxCategoryLookUpBase {

//#region taxCategoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'taxCategoryId', keyColumn: true})
        taxCategoryId : number;
//#endregion taxCategoryId Prop


//#region taxCategoryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'taxCategoryName', keyColumn: false})
        taxCategoryName : string;
//#endregion taxCategoryName Prop

}