<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
    <div class="modal-content" >
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0">Incident Details</h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>

        <div class="modal-body"  *ngIf="showGrid">
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">
                <div class="table-responsive" *ngIf="isShowGrid">
                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                            <rx-grid [design]="hseReportIncidentDataGrid"></rx-grid>
                        </div>
                    </div>
                </div>
                <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                    <h4>No Record Found</h4>
                </div>
            </div>
        </div>
    </div>
</div>