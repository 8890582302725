import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SpecificationAttributeBase {

//#region specificationAttributeOptionID Prop
        @prop()
        specificationAttributeOptionID : number;
//#endregion specificationAttributeOptionID Prop


//#region specificationAttributeID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        specificationAttributeID : number;
//#endregion specificationAttributeID Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region viewbyClients Prop
        @prop()
        viewbyClients : boolean;
//#endregion viewbyClients Prop


//#region description Prop
        @prop()
        description : string;
//#endregion description Prop


//#region inspirations Prop
        @prop()
        inspirations : string;
//#endregion inspirations Prop


//#region pictureID Prop
        @prop()
        pictureID : number;
//#endregion pictureID Prop


//#region pictureID2 Prop
        @prop()
        pictureID2 : number;
//#endregion pictureID2 Prop


//#region pictureID3 Prop
        @prop()
        pictureID3 : number;
//#endregion pictureID3 Prop


//#region totCollection Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        totCollection : number;
//#endregion totCollection Prop


//#region parentCollectionID Prop
        @prop()
        parentCollectionID : number;
//#endregion parentCollectionID Prop


//#region metaKeywords Prop
        @prop()
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @prop()
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @maxLength({value:400})
        metaTitle : string;
//#endregion metaTitle Prop


//#region videoUpload Prop
        @maxLength({value:200})
        videoUpload : string;
//#endregion videoUpload Prop


//#region published Prop
        @required()
        published : boolean;
//#endregion published Prop


//#region sEName Prop
        @prop()
        sEName : string;
//#endregion sEName Prop


//#region pictureID4 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID4 : number;
//#endregion pictureID4 Prop





}