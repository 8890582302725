import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerSupplierEvent {

  //#region eventID Prop
  @gridColumn({ visible: false, parameter:false, columnIndex: 0, headerTitle: 'eventID', keyColumn: true })
  eventID: number;
  //#endregion eventID Prop

  //#region eventName Prop
  @gridColumn({
    template: {
      div: {
        childrens: [
          {
            a: {
              class: "text-blue font-weight-bold".split(" "),
              event: {
                click: "onOneLink"
              },
              childrens: [{
                text: {
                  text: function (e) { return this.EventName }
                }
              }],
              attributes: {
                href: function (e) {
                  return "/events/" + this.eventID + "?activeTab=event-info";
                },
                "ng-reflect-router-link": function (e) {
                  return "/events/" + this.eventID + "?activeTab=event-info";
                },
                onClick: "return false;"
              }
            }
          }
        ]
      },
    }, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], parameter:false,visible: true, columnIndex: 1, allowSorting: true, headerKey: 'EventDetailComponent_EventName_gh', keyColumn: false
  })
  EventName: string;
  //#endregion eventName Prop



  //#region eventType Prop
  @gridColumn({ style: { width: "15%", "text-align": "initial" },  parameter:false,class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'EventDetailComponent_EventType_gh', keyColumn: false })
  EventType: any;
  //#endregion eventType Prop


  //#region startDate Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" },  parameter:false,class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'EventDetailComponent_StartDate_gh', keyColumn: false })
  StartDate: string;
  //#endregion startDate Prop


  //#region location Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" },  parameter:false,class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'EventDetailComponent_Location_gh', keyColumn: false })
  Location: string;
  //#endregion location Prop


  //#region eventDate Prop
  @gridColumn({ visible: false, columnIndex: 22, allowSorting: true, headerTitle: 'Send E Invitation', keyColumn: false })
  SendEInvitation: any;
  //#endregion eventDate Prop


  //#region accepted Prop
  @gridColumn({ visible: false, columnIndex: 7, allowSorting: true, headerTitle: 'Accepted', keyColumn: false })
  Accepted: any;
  //#endregion accepted Prop


  //#region declined Prop
  @gridColumn({ visible: false, columnIndex: 8, allowSorting: true, headerTitle: 'Declined', keyColumn: false })
  Declined: any;
  //#endregion declined Prop


  //#region invited Prop
  @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerTitle: 'Invited', keyColumn: false })
  Invited: any;
  //#endregion invited Prop


  //#region involved Prop
  @gridColumn({ visible: false, columnIndex: 10, allowSorting: true, headerTitle: 'Involved', keyColumn: false })
  Involved: any;
  //#endregion involved Prop


  //#region attended Prop
  @gridColumn({ visible: false, columnIndex: 11, allowSorting: true, headerTitle: 'Attended', keyColumn: false })
  Attended: any;
  //#endregion attended Prop


  //#region endDate Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, parameter:false, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'EventDetailComponent_EndDate_gh', keyColumn: false })
  EndDate: string;
  //#endregion endDate Prop


  //#region customerID Prop
  @gridColumn({ visible: false, columnIndex: 12, allowSorting: true, headerTitle: 'Customer ID', keyColumn: false })
  CustomerID: string;
  //#endregion customerID Prop

  //#region status Prop
  //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 13, headerKey: 'EventDetailComponent_Status_gh', keyColumn: false })
  
  @gridColumn({
    template:{
    div:{ 
    attributes:{
            innerHTML:function(x,y){
            return this.status;
            }
    },
    }
    },visible: true,  parameter:false,columnIndex: 13,style: { width: "20%","min-width":"225px", "text-align": "initial"},class:["text-left"], allowSorting: true, headerKey: 'EventDetailComponent_Status_gh',name:'status',keyColumn:false
})
  Status: string
  //#endregion status Prop

  //#region inviteesAttendees Prop
  // @gridColumn({ parameter: false, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerTitle: 'Invitees and Attendees', keyColumn: false })
  // InviteesAttendees: string
  //#endregion inviteesAttendees Prop

  //#region inviteesAttendees Prop
  @gridColumn({
    template: {
      div: {
        childrens: [
          {
            a: {
              class: "text-blue font-weight-bold".split(" "),
              event: {
                click: "onInviteesAttendeesClick"
              },
              childrens: [{
                text: {
                  text: function (e) { return this.InviteesAttendees }
                }
              }],
              attributes: {
                href: function (e) {
                  if (this.IsCorporate) {
                    return "/suppliers/" + this.CustomerID + "?activeTab=supplier-edit";
                  } else {
                    return "/users/" + this.CustomerID + "?activeTab=user-edit";
                  }
                },
                "ng-reflect-router-link": function (e) {
                  if (this.IsCorporate) {
                    return "/suppliers/" + this.CustomerID + "?activeTab=supplier-edit";
                  } else {
                    return "/users/" + this.CustomerID + "?activeTab=user-edit";
                  }
                },
                onClick: "return false;"
              }
            }
          }
        ]
      },
    }, style: { width: "15%", "text-align": "initial" },  parameter:false,class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'EventDetailComponent_InviteesAttendees_gh', keyColumn: false
  })
  InviteesAttendees: string;
  //#endregion inviteesAttendees Prop

  //#region note
  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.note;
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.charCount > 100 && !x.isShowAll) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClick"
            }
          },
        }]
      }
    }, visible: true, parameter: false, columnIndex: 10, allowSorting: false, headerKey:'EventDetailComponent_ManualNote_gh', name: 'note', style: { width: "15%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], keyColumn: false
  })

  note: string;
  //#endregion note
  charCount: number;

  //#region TotalCount Prop
  @gridColumn({ visible: false, columnIndex: 14, headerTitle: 'TotalCount', keyColumn: true })
  TotalCount: number;
  //#endregion TotalCount Prop

  //#region IsCorporate Prop
  IsCorporate: boolean;
  //#endregion IsCorporate Prop

}