import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewContainerRef, EventEmitter, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from '@angular/router';
import { IFormGroup, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Subject, Subscription } from 'rxjs';
import { AppGrid, AppGridSelectDesigner } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { RiskMenuDetailFilterViewModel } from 'src/app/view-model/risk-menu-detail-filter-view-model';
import { SpecificRiskViewModel } from 'src/app/view-model/specific-risk-view-model';
import { AbstractRiskMenuDetail } from '../domain/abstract-risk-menu-detail';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { GemfieldRiskListViewModel } from 'src/app/view-model/gemfield-risk-list-view-model';
import { GemfieldRiskAddViewModel } from 'src/app/view-model/gemfield-risk-add--view-model';

import { RiskMenuDetail } from '@app/models';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { multilingual, MultiLingualData } from "@rxweb/localization";
import { scroolOnSearchResult } from "src/app/domain/customize-plugin/bind-scroll-on-search";
import { SERVER_URL } from "src/app/domain/system-constant";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { Workbook } from "exceljs";
import * as moment from "moment";
import { DomSanitizer, Title } from '@angular/platform-browser';
import { List } from "@rxweb/generics";
import * as fs from 'file-saver';
import { debounceTime } from 'rxjs/operators';
import { ApplicationBroadcaster } from "src/app/temp-service/application-broadcaster";
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";
import { access } from "@rxweb/angular-router";
import * as CryptoJS from 'crypto-js';
import { dbAccessModule } from "src/app/domain/security/authorization-resolver";
import { DatePipe } from "@angular/common";
import { NotesEnum } from "src/app/enums/notes.enum";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { getLocalizedValue, appendPageFooter, removeScrollableFooter, removeFooter, innerTextFromHtmlContent, getSystemHelpStatus, redirectOnCTRL, dateCompareValidation } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { GridColumnConfig } from "@rxweb/grid";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@multilingual("RiskMenuDetailListComponent")
@access({ accessLevel: RolePermissionEnums.GemfieldsRiskMenu, action: "get" })
@Component({
    selector: "app-risk-menu-detail-list",
    templateUrl: './risk-menu-detail-list.component.html'
})
export class RiskMenuDetailListComponent extends AbstractRiskMenuDetail implements OnInit, OnDestroy {
    gemfieldRiskSearchFormGroup: IFormGroup<RiskMenuDetailFilterViewModel>;
    gemfieldRiskSearch: RiskMenuDetailFilterViewModel;
    subscription: any;
    paginationViewModel: PaginationViewModel;
    gemfieldRiskGrid: AppGrid;
    gemfieldRiskAddGrid: AppGridSelectDesigner;
    gemfieldRisks: GemfieldRiskListViewModel[];
    gemfieldRisksForExport: GemfieldRiskListViewModel[];
    gemfieldRisksAdd: GemfieldRiskAddViewModel[];
    json: any;
    loggedInUserId: number = 0;
    sort: boolean;
    checkboxBtnChk: boolean = false;
    selectedRiskArray: number[];
    selectedRiskObjArray: RiskMenuDetail[];
    showErrorMsg: boolean = false;
    selectedCompany: number = 0;
    selectedRiskCategoryId: number = 0;
    showExportToExcel: boolean = false;
    exportExcel = new ExportToExcelModel();
    private riskMenuSubject: Subject<string> = new Subject<string>();
    selectedLocalRiskOwnerId: number;
    selectedBoardRiskOwnerId: number;
    riskIdError: boolean = false;
    datepipe: DatePipe;
    moduleID: number;
    titleHelp: string;
    newRiskAdd: boolean = false;
    riskForm:any;
    riskMenuDetailAdd: any = dbAccessModule[RolePermissionEnums.GemfieldsRiskMenu]["post"];
    showSystemHelpIcon: boolean = false;
    dateError: boolean;
    titleGridColumnSetting: string;
    isShowGridColumn: boolean = false;
    arrConfig: any[];
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    noticeData: any;
    myrisks:boolean = false;



    _this;

    constructor(private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster, router: Router, modelView: ModalView, private componentResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, private browserStorage: BrowserStorage, private title: Title, datepipe: DatePipe,private sanitizer: DomSanitizer,blobState: BlobUploadsViewStateService, private activatedRoute: ActivatedRoute) {
        super(blobState);
        this.router = router;
        this.modalView = modelView;
        this.loggedInUserId = this.browserStorage.local.get('userId');
        this.selectedRiskArray = new Array<number>();
        this.selectedRiskObjArray = new Array<RiskMenuDetail>();
        this.datepipe = datepipe;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'gemfield-risk-menu', subMenu: '' });
        setTimeout(() => {
            this.get({path:'api/SearchCommon/GetRiskGuidanceForm'}).subscribe((result:any)=>{
                //document.getElementById('risk-form').innerHTML = result;
                this.riskForm = result;
            })
        }, 500);

        this.riskMenuSubject.pipe(debounceTime(300)).subscribe(t => {
            this.searchGemfieldRisk(false);
        })
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.moduleID = RolePermissionEnums.GemfieldsRiskMenu;
        this.activatedRoute.queryParams.subscribe(t => {
            this.myrisks = t['myrisks']
          })
        Window["RiskMenuDetailListComponent"] = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Gemfields Enterprise Risk Management System (GERMS) - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.sort = true;
        this.showGrid = true;
        this.isShowGrid = true;
        this.get({
          path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
          this.getColumnsData = t;
          if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
            this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
          }
          this.bindForm();
        });
    }
    noteData(){
        var localizationData = (MultiLingualData.get(this.componentName) as any);
        this.noticeData=localizationData.RiskMenuDetailListComponent_Notice_gn_gn;

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindForm(isreset = false) {
        this.spin = true;
        this.subscription = this.lookup([
            { propName: "gemfieldGroupCompanyLookUp", path: USER_LOOKUPS.gemfieldGroupCompanyLookUp },
            { propName: "riskCategoryLookUp", path: USER_LOOKUPS.riskCategoryLookUp },
            { params: [this.selectedRiskCategoryId], propName: "specificRiskLookUp", path: USER_LOOKUPS.specificRiskLookUp },
            { params: [this.selectedCompany], propName: "localRiskOwnerLookUp", path: USER_LOOKUPS.localRiskOwnerLookUp },
            { params: [this.selectedCompany], propName: "boardRiskOwnerLookUp", path: USER_LOOKUPS.boardRiskOwnerLookUp }
        ]).subscribe((lookup: any) => {
            this.spin = false;
            this.gemfieldRiskSearchLookUp = lookup;
            this.gemfieldRiskSearch = new RiskMenuDetailFilterViewModel();
            if (this.selectedCompany > 0) {
                this.gemfieldRiskSearch.gemfieldGroupCompanyId = this.selectedCompany;
            }
            if (this.selectedRiskCategoryId > 0) {
                this.gemfieldRiskSearch.riskCategoryId = this.selectedRiskCategoryId;
            }
            if (this.selectedLocalRiskOwnerId > 0) {
                this.gemfieldRiskSearch.localRiskOwnerId = this.selectedLocalRiskOwnerId;
            }
            if (this.selectedBoardRiskOwnerId > 0) {
                this.gemfieldRiskSearch.boardRiskOwnerId = this.selectedBoardRiskOwnerId;
            }
            this.gemfieldRiskSearchFormGroup = this.formBuilder.formGroup(RiskMenuDetailFilterViewModel, this.gemfieldRiskSearch) as IFormGroup<RiskMenuDetailFilterViewModel>;
            
                this.searchGemfieldRisk(false);
           
            if (this.showAddRiskGrid) {
                this.bindGridRiskAdd();
            }
            setTimeout(() => {
                this.showComponent = true;
            }, 0);
        }
        );
        this.noteData()
    }
    transformYourHtml(htmlTextWithStyle) {
        return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
      }
    onCompanyChange(companyId: number) {
        if (companyId > 0) {
            this.selectedCompany = companyId;
            if(this.gemfieldRiskSearchFormGroup.value.riskCategoryId== undefined || this.gemfieldRiskSearchFormGroup.value.riskCategoryId.toString() == "" )
            {
                this.gemfieldRiskSearchFormGroup.patchValue({
                    riskCategoryId : parseInt("")
                })
                this.selectedRiskCategoryId =  0;
            }
            this.showComponent = false;
            this.showAddRiskGrid = true;
            this.isShowAddRiskGrid = true;
            this.showGrid = true;
            this.isShowGrid = true;
            this.bindForm();
            // if(this.gemfieldRiskAddGrid)
            // {
            //   this.gemfieldRiskAddGrid.updateSource([]);
            // }
            // this.searchGemfieldRiskAdd();
        }
        //this.searchGemfieldRisk(false);
    }
    goToRisk() {
        this.gemfieldRiskSearchFormGroup.submitted = true;

        if (this.gemfieldRiskSearchFormGroup.controls.riskId.valid) {
            this.id = this.gemfieldRiskSearchFormGroup.controls.riskId.value;
            if (this.gemfieldRiskSearchFormGroup.controls.riskId.value == null || this.gemfieldRiskSearchFormGroup.controls.riskId.value == "") {
                this.riskMenuDetailAdd = false
                this.riskIdError = true;
            }
            else {
                this.router.navigate(['/gemfield-risk-menu', this.id], { queryParams: { "activeTab": "risk-edit" } });
                this.isNoRecordFound = true;
            }

        }
        else {
            this.riskMenuDetailAdd = false
            this.riskIdError = true;
        }
    }

    onRiskCategoryChange(riskCategoryId: number) {
      if (riskCategoryId > 0) {
        if(this.showAddRiskGrid){
          this.showAddRiskGrid = true;
          this.isShowAddRiskGrid = true;
        }
        this.showGrid = true;
        this.isShowGrid = true;
        // if (riskCategoryId > 0) {
        this.selectedRiskCategoryId = riskCategoryId;
        if( this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId == undefined || this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId.toString() == "" )
        {
            this.gemfieldRiskSearchFormGroup.patchValue({
                gemfieldGroupCompanyId : parseInt("")
            })
            this.selectedCompany = 0;
        }
        if (this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId > 0 && this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId != undefined && this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId != null) {
            this.selectedLocalRiskOwnerId = this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId;
        }
        if (this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId > 0 && this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId != undefined && this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId != null) {
            this.selectedBoardRiskOwnerId = this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId;
        }
        this.showComponent = false;
        if (this.gemfieldRiskGrid != null) {
            this.isFilter = true;
            this.gemfieldRiskGrid.updateSource([]);
            this.gemfieldRiskGrid.storeProcedure.nextPage = 1;
            this.gemfieldRiskSearchFormGroup.value.rowCount = this.gemfieldRiskGrid.maxPerPage;
        }
        this.gemfieldRiskSearchFormGroup.value.pageIndex = 1
        this.bindForm();

      }
    }
    onKeyPress() {
        this.myrisks = false;
        this.riskMenuSubject.next();
    }

    searchGemfieldRisk(fromAddButton: boolean) {
        // if (this.gemfieldRiskGrid) {
        //     this.isFilter = true;
        // }
        this.dateError = false;
        if (dateCompareValidation(this.gemfieldRiskSearchFormGroup.value.lastupdatesFrom, this.gemfieldRiskSearchFormGroup.value.lastupdatesTo) == true) {
            this.dateError = true;
            return;
        }
        else {
            if(!fromAddButton) {
                this.showAddRiskGrid = false;
                this.isShowAddRiskGrid = false;
                this.showGrid = true;
                this.isShowGrid = true;
            }
            this.json = JSON.parse(JSON.stringify(this.gemfieldRiskSearchFormGroup.value));
            this.json["clientUrl"] = SERVER_URL;
            this.json["userId"] = 0;
            this.json["riskId"] = 0;
            if (this.gemfieldRiskGrid != null) {
                this.isFilter = true;
                this.gemfieldRiskGrid.updateSource([]);
                this.gemfieldRiskGrid.storeProcedure.nextPage = 1;
                this.gemfieldRiskSearchFormGroup.value.rowCount = this.gemfieldRiskGrid.maxPerPage;
            }
            this.gemfieldRiskSearchFormGroup.value.pageIndex = 1;
            setTimeout(() => {
                if (this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId > 0 && !fromAddButton) {
                    //this.showAddRiskGrid = false;
                    //this.isShowAddRiskGrid = false;
                    this.bindGrid();
                } else {
                    //this.showAddRiskGrid = true;
                    //this.isShowAddRiskGrid = true;
                    this.bindGrid();
                }
            }, 50);
        }
    }

    resetSearch() {
        this.spin = true;
        this.myrisks = false;
        this.gemfieldRiskSearchFormGroup.reset();
        this.selectedCompany = 0;
        this.selectedRiskCategoryId = 0;
        this.selectedLocalRiskOwnerId = 0;
        this.selectedBoardRiskOwnerId = 0;
        this.showComponent = false;
        this.showGrid = false;
        this.isShowGrid = false;
        //this.showAddRiskGrid = false;
        //this.isShowAddRiskGrid = false;
        this.bindForm(true);
        if (this.gemfieldRiskGrid) {
            this.isFilter = true;
            this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId = this.selectedCompany;
            this.gemfieldRiskSearchFormGroup.value.riskCategoryId = this.selectedRiskCategoryId;
            this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId = this.selectedLocalRiskOwnerId;
            this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId = this.selectedBoardRiskOwnerId;   
            this.json = JSON.parse(JSON.stringify(this.gemfieldRiskSearchFormGroup.value));
            this.json["clientUrl"] = SERVER_URL;
            this.json["userId"] = this.loggedInUserId;
            this.showGrid = true;
            this.bindGrid();
        }
    }

    bindGrid() {
        this.spin = true;
        if(this.selectedCompany)
        this.selectedCompany = String(this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId) == '' || this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId == null || this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId == undefined ? 0 : this.gemfieldRiskSearchFormGroup.value.gemfieldGroupCompanyId;
        this.selectedRiskCategoryId = String(this.gemfieldRiskSearchFormGroup.value.riskCategoryId) == '' || this.gemfieldRiskSearchFormGroup.value.riskCategoryId == null || this.gemfieldRiskSearchFormGroup.value.riskCategoryId == undefined ? 0 : this.gemfieldRiskSearchFormGroup.value.riskCategoryId;
        //this.showGrid = true;
        //this.isShowGrid = true;
        this.totalRecords = 0;

        if(this.myrisks)
        {
            this.json["myrisks"] = true;
        }

        this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(this.json)), "paramFlag": 1 } }).subscribe((t: GemfieldRiskListViewModel[]) => {
            if (this.gemfieldRiskGrid) {
                this.gemfieldRiskGrid.storeProcedure.length = 0;
            }
            this.spin = false;
            this.gemfieldRisks = t;
            this.gemfieldRisks.forEach(x => {
                if (x.projectDetails != null && x.projectDetails != undefined && x.projectDetails.length > 1) {
                    x.projects = this.formCompanyTable(x);
                }
                if(x.incidentDetails != null && x.incidentDetails != undefined && x.incidentCount > 1)
                {
                  x.incidents = this.formIncidentTable(x);
                }
                if(x.pressMediaDetails != null && x.pressMediaDetails != undefined && x.pressMediaCount > 1)
                {
                  x.pressMedias = this.formPressMediaTable(x);
                }
                if(x.eventDetails != null && x.eventDetails != undefined && x.eventCount > 1)
                {
                  x.events = this.formEventTable(x);
                }
                if(x.legalAgreementDetails != null && x.legalAgreementDetails != undefined && x.legalAgreementCount > 1)
                {
                  x.legalAgreement = this.formLegalAgreementTable(x);
                }
                if(x.likelihood != null && x.likelihood != undefined){
                    let arrow = this.getArrowSign('Likelihood',x.lastUpdates);
                    let arr = arrow!=null?arrow=='U'?' <i class="fa fa-arrow-up" style="color: green;"></i>':' <i class="fa fa-arrow-down" style="color: red;"></i>':'\u2003';
                    x.likelihood = x.likelihood + arr;
                }
                if(x.impact != null && x.impact != undefined){
                    let arrow = this.getArrowSign('Impact',x.lastUpdates);
                    let arr = arrow!=null?arrow=='U'?' <i class="fa fa-arrow-up" style="color: green;"></i>':' <i class="fa fa-arrow-down" style="color: red;"></i>':'\u2003';
                    x.impact = x.impact + arr;
                }
            })

            if (this.gemfieldRisks.length > 0) {
                this.totalRecords = this.gemfieldRisks[0].totalCount;
                this.isNoRecordFound = false;
                this.showExportToExcel = true;
            }
            else {
                this.isNoRecordFound = true;
                this.isShowGrid = false;
                this.showExportToExcel = false;
            }
            if (!this.isFilter) {
                this.gemfieldRiskGrid = new AppGrid(this.gemfieldRisks, GemfieldRiskListViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.onRiskIdClik.bind(this) } });
                this.gemfieldRiskGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            }
            else {
                // this.gemfieldRiskGrid.storeProcedure.length = this.totalRecords;
                // this.gemfieldRiskGrid.updateSource([]);
                // this.gemfieldRiskGrid.updateSource(this.gemfieldRisks)
                if (this.gemfieldRiskGrid != null) {
                    this.gemfieldRiskGrid.storeProcedure.length = this.totalRecords;
                    if (this.json["rowCount"] == 1) {
                        this.gemfieldRiskGrid.storeProcedure.nextPage = 1;
                    }
                    this.gemfieldRiskGrid.updateSource([]);
                    this.gemfieldRiskGrid.updateSource(this.gemfieldRisks);
                }
            }

            if (this.arrConfig != undefined && this.arrConfig != null) {
              this.arrConfig.forEach(x => {
                this.gemfieldRiskGrid.gridColumns.forEach(y => {
                  if (y.name == x.ColumnName)
                    y.visible = x.Visible;
                });
              });
            }

            //this.gemfieldRiskGrid.maxPerPage = this.gemfieldRiskSearchFormGroup.value.rowCount;
            this.gemfieldRiskGrid.componentId = this.componentName;
            this.gemfieldRiskGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.gemfieldRiskGrid.designClass.headerClass = "table-header-sticky".split(" ");

            var riskElement = this.gemfieldRiskGrid.gridColumns.filter(x=>x.visible == true).findIndex(x => x.name == "riskIndex");
            if(riskElement != -1)
            {
              setTimeout(() => {
                  this.gemfieldRisks.forEach(x => {
                      if (this.gemfieldRiskGrid.getRowElement(x.riskMenuDetailId) != undefined) {
                          let element = this.gemfieldRiskGrid.getRowElement(x.riskMenuDetailId);
                          if (x.riskIndex >= 77) {
                              element.children[riskElement].style.backgroundColor = "red";
                          }
                          if (x.riskIndex <= 25) {
                              element.children[riskElement].style.backgroundColor = "lightGreen";
                          }
                          if (x.riskIndex >= 26 && x.riskIndex <= 51) {
                              element.children[riskElement].style.backgroundColor = "yellow";
                          }
                          if (x.riskIndex >= 52 && x.riskIndex <= 76) {
                              element.children[riskElement].style.backgroundColor = "Orange";
                          }
                      }
                  })
                  scroolOnSearchResult();
              }, 0);
            }
        })

    }

    formCompanyTable(item: GemfieldRiskListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table><tbody>";
        let subTable: any;
        subTable = "<tr id='demo" + item.riskMenuDetailId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.projectDetails != null && item.projectDetails != undefined) {
            for (let i = 0; i < item.projectDetails.length; i++) {
                let x: any = item.projectDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.riskMenuDetailId + "'";
                    row = row + "<tr><td >" + item.projectDetails[i].project + "<i class='fa fa-chevron-down ml-2' id='icon" + item.riskMenuDetailId + "' onclick='Window.RiskMenuDetailListComponent.expandTable(" + item.riskMenuDetailId + ")'></i></td></tr>";
                }
                else {
                    subRow = subRow + "<tr><td>" + x.project + "</td></tr>";
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    expandTable(id: number) {
        var element = document.getElementById("demo" + id.toString());
        var iconElement = document.getElementById("icon" + id.toString());
        if (element != null) {
            if (element.style.display == "none") {
                element.style.display = "block";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-up ml-2"
                }

            }
            else {
                element.style.display = "none";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-down ml-2"
                }

            }
        }
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json["sortOrder"] = y;
        this.json["orderBy"] = x;
        this.gemfieldRiskGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.json["pageIndex"] = x;
        this.json["rowCount"] = this.gemfieldRiskGrid.maxPerPage;

        this.gemfieldRiskGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    onMoreClick(t, x) {
        var element = document.getElementById("note_" + t.noteId);
        var maskedElement = document.getElementById("maskednote_" + t.noteId);
        if (element.className == "collapse") {
            x.srcElement.innerText = "Show Less";
            x.srcElement.className = "less-btn"
            element.classList.add("show");
            maskedElement.setAttribute("style", "display:none");
        }
        else {
            element.classList.remove("show");
            x.srcElement.innerText = "Show More";
            x.srcElement.className = "more-btn";
            maskedElement.setAttribute("style", "display:inline");
        }
    }

    onRiskIdClik(risk: GemfieldRiskListViewModel, event) {

        let url: any;
        url = this.router.serializeUrl(this.router.createUrlTree(["gemfield-risk-menu", risk.riskMenuDetailId], { queryParams: { activeTab: 'risk-edit' } }));
        redirectOnCTRL(url, this.router, event);

        //this.router.navigate(["gemfield-risk-menu", risk.riskMenuDetailId], { queryParams: { activeTab: 'risk-edit' } });
    }

    addNewRisk() {
        this.gemfieldRiskSearchFormGroup.submitted = true;
        if (this.gemfieldRiskSearchFormGroup.valid) {
            this.newRiskAdd = false;
            this.showAddRiskGrid = true;
            this.isShowAddRiskGrid = true;
            this.showGrid = true;
            this.isShowGrid = true;
            this.searchGemfieldRisk(true);
            this.searchGemfieldRiskAdd();
            this.showGrid = false;
            this.isShowGrid = false;
        }
        else {
            this.newRiskAdd = true;
        }
    }

    exportToExcel() {
        this.spin = true;
        //this.exportExcel.FileName = "Risks.xlsx";
        this.exportExcel.Type = "Excel";
        //let paginationViewModel = new PaginationViewModel();
        //paginationViewModel.sortOrder = "true";
        //paginationViewModel.orderBy = "productVariantInfo";
        //this.json = JSON.parse(JSON.stringify(this.gemfieldRiskSearchFormGroup.value));
        //this.json = JSON.parse(JSON.stringify(this.json));
        this.json["clientUrl"] = SERVER_URL;
        this.json["userId"] = 0;
        this.json["pageIndex"] = PaginationEnum.PageIndex;
        this.json["rowCount"] = this.totalRecords;
        this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(this.json)), "paramFlag": 1 } }).subscribe((t: GemfieldRiskListViewModel[]) => {
            this.gemfieldRisksForExport = t;
            this.generateProductsExcel(this.gemfieldRisksForExport);
        })
    }
        generateProductsExcel(list: any){
          let returnedRequestData = this.requestExcelData(list,this.gemfieldRiskGrid.gridColumns);
            this.exportExcel.Result = JSON.stringify(returnedRequestData);
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet();
            var TodayDate = new Date();
            let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
            var FileName = "Risks" + MMDDYY;
            this.exportExcel.FileName = FileName;

            let columnHeaders = [];
            this.gemfieldRiskGrid.gridColumns.forEach(e => {
                let name;
                if (e.visible) {
                    if (e.name == "projects") name = 'projectExcel'
                    else if (e.name == "events") name = 'eventExcel'
                    else if (e.name == "legalAgreement") name = 'legalAgreementExcel'
                    else if (e.name == "pressMedias") name = 'pressMediaExcel'
                    else if (e.name == "incidents") name = 'incidentExcel'
                    else name = e.name;

                    columnHeaders.push(
                        { header: this.getGridColumnName(name), key: this.getGridColumnName(name), width: this.getGridColumnWidth(name) }
                    )
                }
            }, this);
            worksheet.columns = columnHeaders;
            const objKeys = Object.keys(returnedRequestData['_entities'][0]);
            for (let index = 0; index < returnedRequestData['_entities'].length; index++) {
                let row = {};
                objKeys.forEach(key => {
                    if (key == this.getGridColumnName('riskIndex')){
                        row[key] = returnedRequestData['_entities'][index][key] + '%'
                    }
                    else if(key == this.getGridColumnName('noteDescription')){
                        row[key] = this.removeHTML(returnedRequestData['_entities'][index][key])
                    }
                    else if(key == this.getGridColumnName('isRelevant')){
                        row[key] = returnedRequestData['_entities'][index][key].toString()
                    }
                    else if(key !='')
                        row[key] = returnedRequestData['_entities'][index][key]
                });
                worksheet.addRow(row);
            }
            for (var row = 2; row <= worksheet.rowCount; row++) {
                worksheet.getRow(row).eachCell((cell,number)=>{
                    cell.alignment = { vertical: 'middle', horizontal: 'left' };
                })
            }

            var objRiskIndex = columnHeaders.find(i=>i.key === this.getGridColumnName('riskIndex'));
            if(objRiskIndex){
                worksheet.getColumn(this.getGridColumnName('riskIndex')).eachCell((cell,rowNumber)=>{
                    let rIndex = parseInt(cell.value.toString());
                    let color = 'FFFFFFFF'
                    if(!isNaN(rIndex)){
                        if(rIndex >= 77) color = 'FFFF0000'
                        else if(rIndex >= 52) color = 'FFFFA500'
                        else if(rIndex >= 26) color = 'FFFFFF00'
                        else color = 'FF90EE90'
                    }
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: color
                        },
                        bgColor: {
                            argb: color
                        }
                    }
                    cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    cell.border = {
                        top: { style: 'thin', color: {argb:'FF808080'}},
                        left: { style: 'thin', color: {argb:'FF808080'}},
                        bottom: { style: 'thin', color: {argb:'FF808080'}},
                        right: { style: 'thin', color: {argb:'FF808080'}},
                    };
                });
            }
            var objLiklihood = columnHeaders.find(i => i.key === this.getGridColumnName('likelihood'));
            if (objLiklihood) {
                worksheet.getColumn(this.getGridColumnName('likelihood')).eachCell((cell, rowNumber) => {
                    if (cell.value.toString().indexOf('D') != -1) {
                        cell.value = {
                            richText: [
                                { text: cell.value.toString()[0] + ' ' },
                                { text: String.fromCharCode(0x2193), font: { name: 'Source Code Pro Black', family: 2, size: 14, color: { argb: 'ffdc3545' }, bold: true } }
                            ]
                        };
                    }
                    else if (cell.value.toString().indexOf('U') != -1) {
                        cell.value = {
                            richText: [
                                { text: cell.value.toString()[0] + ' ' },
                                { text: String.fromCharCode(0x2191), font: { name: 'Source Code Pro Black', family: 2, size: 14, color: { argb: 'ff28a745' }, bold: true } }
                            ]
                        };
                    }
                });
            }
            var objImpact = columnHeaders.find(i => i.key === this.getGridColumnName('impact'));
            if (objImpact) {
                worksheet.getColumn(this.getGridColumnName('impact')).eachCell((cell, rowNumber) => {
                    if (cell.value.toString().indexOf('D') != -1) {
                        cell.value = {
                            richText: [
                                { text: cell.value.toString()[0] + ' ' },
                                { text: String.fromCharCode(0x2193), font: { name: 'Source Code Pro Black', family: 2, size: 14, color: { argb: 'ffdc3545' }, bold: true } }
                            ]
                        };
                    }
                    else if (cell.value.toString().indexOf('U') != -1) {
                        cell.value = {
                            richText: [
                                { text: cell.value.toString()[0] + ' ' },
                                { text: String.fromCharCode(0x2191), font: { name: 'Source Code Pro Black', family: 2, size: 14, color: { argb: 'ff28a745' }, bold: true } }
                            ]
                        };
                    }
                });
            }
        //     var localizationData = (MultiLingualData.get(this.componentName) as any);
        //     worksheet.columns = [
        //         { header: localizationData.RiskMenuDetailListComp_RiskId_ex_ex, key: 'RiskMenuDetailId', style: { alignment: { horizontal: 'right' } } },
        //         { header: localizationData.RiskMenuDetailListComp_GemfieldsGroupCompany_ex_ex, key: 'Company' },
        //         { header: localizationData.RiskMenuDetailListComp_CreatedDate_ex_ex, key: 'CreatedOn' },
        //         { header: localizationData.RiskMenuDetailListComp_RiskCategory_ex_ex, key: 'RiskCategoryName' },
        //         { header: localizationData.RiskMenuDetailListComp_SpecificRiskName_ex_ex, key: 'SpecificRiskName' },
        //         { header: localizationData.RiskMenuDetailListComp_IsRelevant_ex_ex, key: 'IsRelevant' },
        //         { header: localizationData.RiskMenuDetailListComp_Likelihood_ex_ex, key: 'Likelihood', style: { alignment: { horizontal: 'right' } } },
        //         { header: localizationData.RiskMenuDetailListComp_Impact_ex_ex, key: 'Impact', style: { alignment: { horizontal: 'right' } } },
        //         { header: localizationData.RiskMenuDetailListComp_RiskIndex_ex_ex, key: 'RiskIndex', style: { alignment: { horizontal: 'left' } } },
        //         { header: localizationData.RiskMenuDetailListComp_LocalRiskOwner_ex_ex, key: 'LocalRiskOwnerName' },
        //         { header: localizationData.RiskMenuDetailListComp_BoardRiskOwner_ex_ex, key: 'BoardRiskOwnerName' },
        //         { header: localizationData.RiskMenuDetailListComp_Note_ex_ex, key: 'NoteDescription' },
        //         { header: localizationData.RiskMenuDetailListComp_lastUpdated_ex_ex, key: 'modifiedOn' }
        //     ];
        //     returnedRequestData.forEach(x => {
        //         worksheet.addRow({
        //             RiskMenuDetailId: x.RiskMenuDetailId,
        //             Company: x.Company,
        //             CreatedOn: x.CreatedOn,
        //             RiskCategoryName: x.RiskCategoryName,
        //             SpecificRiskName: x.SpecificRiskName,
        //             IsRelevant: x.IsRelevant,
        //             Likelihood: x.Likelihood,
        //             Impact: x.Impact,
        //             RiskIndex: x.RiskIndex,
        //             LocalRiskOwnerName: x.LocalRiskOwnerName,
        //             BoardRiskOwnerName: x.BoardRiskOwnerName,
        //             NoteDescription: x.NoteDescription,
        //             modifiedOn: x.LastUpdatedDate
        //         });
        //     });
            worksheet.getRow(1).eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '00000000'
                    },
                    bgColor: {
                        argb: '00000000'
                    },
                };
                cell.font = {
                    color: {
                        argb: 'FFFFFFFF',
                    },
                    bold: true
                }
                cell.border = {
                    top: {
                        style: 'thin'
                    },
                    left: {
                        style: 'thin'
                    },
                    bottom: {
                        style: 'thin'
                    },
                    right: {
                        style: 'thin'
                    }
                };
                cell.alignment = { vertical: 'middle', horizontal: 'left' };
            });

            setTimeout(() => {
                this.spin = false;
                workbook.xlsx.writeBuffer().then(async (y: any) => {
                    // const blob = new Blob([y], {
                    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    // });
                    // fs.saveAs(blob, FileName + '.xlsx');
                    var u8 = new Uint8Array(y);
                    var b64encoded = btoa(this.Uint8ToString(u8));
                    const blob = new Blob([y], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    let uploadedBlobName = await this.uploadExcelToBlob(blob, "xlsx");
                    // this.exportExcel.Result = b64encoded;
                    this.exportExcel.BlobFileName = uploadedBlobName;
                    this.exportExcel.EntityType = NotesEnum.RiskNotes;
                    this.exportExcel.Description = "Risks"
                    this.post({ body: this.exportExcel, path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
                        fs.saveAs(blob, Response);
                    })
                    //fs.saveAs(blob, FileName + '.xlsx');
                });
            }, 1000);
        // });
        // return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
        //     //this.downloadDocumentRisk(Response, this.exportExcel.FileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        //     this.spin = false;
        //   }, error => {
        //     this.spin = false;
        //     //  this.toastr.error("There was some problem while downloading the Excel");
        //   })
    }


  //requestExcelData(gemfieldRisksGrid: any) {
  //       this.spin = true;
  //       let returnedRequestData = this.requestRiskExcelData(list,this.gemfieldRiskGrid.gridColumns);
  //        let gemfieldRisksData = new List<gemfieldRiskData>();
  //        gemfieldRisksGrid.forEach(element => {
  //            let listData = new gemfieldRiskData();
  //            listData.RiskMenuDetailId = element.riskMenuDetailId;
  //            listData.Company = element.company;
  //            listData.CreatedOn = element.createdOn;
  //            listData.RiskCategoryName = element.riskCategoryName;
  //            listData.SpecificRiskName = element.specificRiskName;
  //            listData.IsRelevant = element.isRelevant;
  //            listData.Likelihood = element.likelihood;
  //            listData.Impact = element.impact;
  //            listData.RiskIndex = element.riskIndex + '%';
  //            listData.LocalRiskOwnerName = element.localRiskOwnerName;
  //            listData.BoardRiskOwnerName = element.boardRiskOwnerName;
  //            listData.LastUpdatedDate = element.modifiedOn;
  //            var noteFOREXCEL = this.removeHTML(element.noteDescription);
  //            //listData.NoteDescription = (element.noteDescription == null || element.noteDescription == undefined || element.noteDescription == "") ? "" : (element.noteDescription).replace(/<[^>]*>/g, '');
  //            listData.NoteDescription = noteFOREXCEL

  //            gemfieldRisksData.add(listData);
  //        });
  //        return gemfieldRisksData;
  //   }
  requestExcelData(gemfieldRiskGrid: any[], gridColumns: any[]) {
        var listObject = gemfieldRiskGrid.map(function (obj) {
          if (gridColumns != undefined && gridColumns != null) {
            var objNew: any = {}
            gridColumns.forEach(e => {
                if (e.visible) {
                    if(e.name == "projects")
                        objNew[this.getGridColumnName('projectExcel')] = obj['projectExcel'];
                    else if (e.name == "events")
                    objNew[this.getGridColumnName('eventExcel')] = obj['eventExcel'];
                    else if (e.name == "legalAgreement")
                    objNew[this.getGridColumnName('legalAgreementExcel')] = obj['legalAgreementExcel'];
                    else if (e.name == "pressMedias")
                    objNew[this.getGridColumnName('pressMediaExcel')] = obj['pressMediaExcel'];
                    else if (e.name == "incidents")
                    objNew[this.getGridColumnName('incidentExcel')] = obj['incidentExcel'];
                    else if (e.name == "lastUpdates")
                        objNew[this.getGridColumnName(e.name)] = obj[e.name].replaceAll('<br>', '\r');
                    else if(e.name == 'likelihood')
                        objNew[this.getGridColumnName('likelihood')] = obj['likelihood'] + this.getArrowSign('Likelihood',obj['lastUpdates']);
                    else if(e.name == 'impact')
                        objNew[this.getGridColumnName('impact')] = obj['impact'] + this.getArrowSign('Impact',obj['lastUpdates']);
                    else
                        objNew[this.getGridColumnName(e.name)] = obj[e.name];
              }
            }, this);
            return objNew;
          } else {
            return obj;
          }
        }, this)
        var list = new List<any>(listObject);
        return list;
      }
      getGridColumnName(key: string) {
        var localizationData = (MultiLingualData.get(this.componentName) as any);
        switch (key) {
          case "riskMenuDetailId":
            return localizationData.RiskMenuDetailListComp_RiskId_ex_ex;
          case "company":
            return localizationData.RiskMenuDetailListComp_GemfieldsGroupCompany_ex_ex;
          case "createdOn":
            return localizationData.RiskMenuDetailListComp_CreatedDate_ex_ex;
          case "riskCategoryName":
            return localizationData.RiskMenuDetailListComp_RiskCategory_ex_ex;
          case "specificRiskName":
            return localizationData.RiskMenuDetailListComp_SpecificRiskName_ex_ex;
            case "isRelevant":
            return localizationData.RiskMenuDetailListComp_IsRelevant_ex_ex
          case "likelihood":
            return localizationData.RiskMenuDetailListComp_Likelihood_ex_ex;
          case "impact":
            return localizationData.RiskMenuDetailListComp_Impact_ex_ex;
          case "riskIndex":
            return localizationData.RiskMenuDetailListComp_RiskIndex_ex_ex;
          case "localRiskOwnerName":
            return localizationData.RiskMenuDetailListComp_LocalRiskOwner_ex_ex;
          case "boardRiskOwnerName":
            return localizationData.RiskMenuDetailListComp_BoardRiskOwner_ex_ex;
          case "noteDescription":
            return localizationData.RiskMenuDetailListComp_Note_ex_ex;
          case "modifiedOn":
            return localizationData.RiskMenuDetailListComp_lastUpdated_ex_ex;
          case "projectExcel":
                return localizationData.RiskMenuDetailListComp_Project_ex_ex;
          case "eventExcel":
            return localizationData.RiskMenuDetailListComp_Events_ex_ex;
          case "legalAgreementExcel":
            return localizationData.RiskMenuDetailListComp_LegalAgreements_ex_ex;
          case "pressMediaExcel":
            return localizationData.RiskMenuDetailListComp_Press_Media_ex_ex;
          case "incidentExcel":
            return localizationData.RiskMenuDetailListComp_HSE_Entrie_ex_ex;
          case "lastUpdates":
            return localizationData.RiskMenuDetailListComponent_LastUpdate_ex_ex;
          default:
            return ""
        }
      }

    getGridColumnWidth(key: string) {
        switch (key) {
          case "riskMenuDetailId": return 10;
          case "company": return 30;
          case "createdOn": return 15;
          case "riskCategoryName": return 30;
          case "specificRiskName": return 50;
          case "isRelevant": return 15
          case "likelihood": return 15
          case "impact": return 15
          case "riskIndex": return 20
          case "localRiskOwnerName": return 30
          case "boardRiskOwnerName": return 30
          case "noteDescription": return 50
          case "modifiedOn": return 15
          case "projectExcel": return 50
          case "eventExcel": return 50
          case "legalAgreementExcel": return 50
          case "pressMediaExcel": return 50
          case "incidentExcel": return 50
          case "lastUpdates": return 30
          default: return 15
        }
    }

    pdfRiskreport() {
        this.spin = true;

        this.exportExcel.FileName = "RiskReport_" + this.datepipe.transform(Date.now(), 'yyyy-MM-dd-hh-mm-ss') + "_" + Math.floor(1000 + Math.random() * 9000) + ".pdf";

        this.exportExcel.Type = "PDF";
        //let paginationViewModel = new PaginationViewModel();
        //paginationViewModel.sortOrder = "true";
        //paginationViewModel.orderBy = "productVariantInfo";
        //this.json = JSON.parse(JSON.stringify(this.gemfieldRiskSearchFormGroup.value));
        this.json["clientUrl"] = SERVER_URL;
        this.json["userId"] = 0;
        this.json["pageIndex"] = PaginationEnum.PageIndex;
        this.json["rowCount"] = this.totalRecords;
        this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(this.json)), "paramFlag": 1 } }).subscribe((t: GemfieldRiskListViewModel[]) => {
            this.gemfieldRisksForExport = t;
            let returnedRequestData = this.requestPdfData(this.gemfieldRisksForExport,this.gemfieldRiskGrid.gridColumns);
            this.exportExcel.Result = JSON.stringify(returnedRequestData);
            return this.post({ body: this.exportExcel, path: "api/BookingSearch/GetRiskReport" }).subscribe(Response => {
                this.downloadDocument(Response, this.exportExcel.FileName, "application/pdf");
                this.spin = false;
            }, error => {
                this.spin = false;
            })
        });
    }

    getArrowSign(key:string, lastUpdateStr:string){
        let sign = null;
        if(lastUpdateStr.indexOf(key)!=-1){
            let numArr = lastUpdateStr.match(/[\d\.]+/g);
            if(lastUpdateStr.indexOf(key)==0){
                sign = (numArr[0] < numArr[1])?'U':'D';
            }
            else{
                sign = (numArr[2] < numArr[3])?'U':'D';
            }
        }
        return sign;
    }

    requestPdfData(pieceListGrid: any[], gridColumns: any[]) {
 
    var listObject = pieceListGrid.map(obj => {
        var objNew: any = {};

        gridColumns.forEach(column => {
           
                if (column.name === "riskMenuDetailId")
                    objNew[this.getGridColumnName('riskMenuDetailId')] = obj['riskMenuDetailId'];
                else if (column.name === "company")
                    objNew[this.getGridColumnName('company')] = obj['company'];
                else if (column.name === "riskCategoryName")
                    objNew[this.getGridColumnName('riskCategoryName')] = obj['riskCategoryName'];
                else if (column.name === "specificRiskName")
                    objNew[this.getGridColumnName('specificRiskName')] = obj['specificRiskName'];
              
                else if (column.name === 'likelihood')
                    objNew[this.getGridColumnName('likelihood')] = obj['likelihood'] + this.getArrowSign('Likelihood', obj['lastUpdates']);
                else if (column.name === 'impact')
                    objNew[this.getGridColumnName('impact')] = obj['impact'] + this.getArrowSign('Impact', obj['lastUpdates']);
                else if (column.name === 'riskIndex')
                    objNew[this.getGridColumnName('riskIndex')] = obj['riskIndex']  + '%' ;
                else if (column.name === 'localRiskOwnerName')
                    objNew[this.getGridColumnName('localRiskOwnerName')] = obj['localRiskOwnerName'] ;
                else if (column.name === 'boardRiskOwnerName')
                    objNew[this.getGridColumnName('boardRiskOwnerName')] = obj['boardRiskOwnerName'] ;
                else if (column.name === 'modifiedOn')
                    objNew[this.getGridColumnName('modifiedOn')] = obj['modifiedOn'] ;
                else if (column.name === "lastUpdates")
                objNew[this.getGridColumnName(column.name)] = obj[column.name].replaceAll('<br>', '\r');
            
            
        });

        return objNew;
    });

    var list = new List<any>(listObject);
    return list;
}

    public downloadDocument(data: any, fileName: string, fileType: string) {
        var binaryString = window.atob(data.result);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var link = document.createElement('a');
        var blob = new Blob([bytes], { type: fileType });
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
    }
    public downloadDocumentRisk(data: any, fileName: string, fileType: string) {
        var binaryString = window.atob(data.result.result);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var link = document.createElement('a');
        var blob = new Blob([bytes], { type: fileType });
        link.href = window.URL.createObjectURL(blob);
        link.download = data.result.fileName;
        document.body.appendChild(link);
        link.click();

      }


    removeHTML(str: any) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || "";
    }

    searchGemfieldRiskAdd() {
        if (this.gemfieldRiskAddGrid) {
            this.isRiskAddFilter = true;
            if (document.getElementById("gemfieldRiskAddGrid") != undefined)
                this.gemfieldRiskAddGrid.refresh("gemfieldRiskAddGrid");
            this.gemfieldRiskSearchFormGroup.value.rowCount = this.gemfieldRiskAddGrid.maxPerPage;
        }
        this.json = JSON.parse(JSON.stringify(this.gemfieldRiskSearchFormGroup.value));
        this.json["orderby"] = "specificRiskName";
        this.bindGridRiskAdd();
    }

    bindGridRiskAdd() {
        this.spin = true;
        //this.showAddRiskGrid = true;
        //this.isShowAddRiskGrid = true;
        this.totalAddRiskRecords = 0;
        this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(this.json)), "paramFlag": 0 } }).subscribe((t: GemfieldRiskAddViewModel[]) => {
            setTimeout(() => {
                this.spin = false;
                if (this.gemfieldRiskAddGrid) {
                    this.gemfieldRiskAddGrid.storeProcedure.length = 0;
                }
                this.gemfieldRisksAdd = t;
                if (this.gemfieldRisksAdd.length > 0) {
                    this.totalAddRiskRecords = this.gemfieldRisksAdd[0].totalCount;
                    this.isNoRiskAddRecordFound = false;
                }
                else {
                    this.isNoRiskAddRecordFound = true;
                    this.isShowAddRiskGrid = false;
                }
                if (this.isShowAddRiskGrid) {
                    if (!this.isRiskAddFilter) {
                        this.gemfieldRiskAddGrid = new AppGridSelectDesigner(this.gemfieldRisksAdd, GemfieldRiskAddViewModel, { actions: { onCheckBoxSelect: this.onCheckBoxSelect.bind(this), onReleventSelect: this.onIsRelevent.bind(this), onLikeChange: this.onLikeChange.bind(this), onImpactChange: this.onImpactChange.bind(this), onAdd: this.saveRisk.bind(this) } }, this.componentResolver, this.viewContainerRef, this.json["sortOrder"]);
                        this.gemfieldRiskAddGrid.storeProcedure = {
                            length: this.totalAddRiskRecords,
                            onPageChanging: this.onRiskAddPageChanging.bind(this),
                            nextPage: 1,
                            onPageSorting: this.onRiskAddPageSorting.bind(this)
                        }
                        this.gemfieldRiskAddGrid.gridColumns.forEach(x => {
                            if (x.columnIndex == 0) {
                                x.visible = true;

                            } if (x.columnIndex == 1) {
                                x.visible = false;
                            }
                        });
                    }
                    else {
                        this.gemfieldRiskAddGrid = new AppGridSelectDesigner(this.gemfieldRisksAdd, GemfieldRiskAddViewModel, { actions: { onCheckBoxSelect: this.onCheckBoxSelect.bind(this), onReleventSelect: this.onIsRelevent.bind(this), onLikeChange: this.onLikeChange.bind(this), onImpactChange: this.onImpactChange.bind(this), onAdd: this.saveRisk.bind(this) } }, this.componentResolver, this.viewContainerRef, this.json["sortOrder"]);
                        this.gemfieldRiskAddGrid.storeProcedure = {
                            length: this.totalAddRiskRecords,
                            onPageChanging: this.onRiskAddPageChanging.bind(this),
                            nextPage: this.json["pageIndex"],
                            onPageSorting: this.onRiskAddPageSorting.bind(this)
                        }
                        this.gemfieldRiskAddGrid.gridColumns.forEach(x => {
                            if (x.columnIndex == 0) {
                                x.visible = true;

                            } if (x.columnIndex == 1) {
                                x.visible = false;
                            }
                        });
                    }
                    this.gemfieldRiskAddGrid.currentPage = this.json["pageIndex"];
                    this.gemfieldRiskAddGrid.pagingSource.splice(this.gemfieldRiskAddGrid.pagingSource.findIndex(x => x == this.json["rowCount"]), 1);
                    this.gemfieldRiskAddGrid.pagingSource.unshift(this.json["rowCount"]);
                    this.gemfieldRiskAddGrid.maxPerPage = this.json["rowCount"];
                    this.gemfieldRiskAddGrid.designClass.tableClass.push("chosen-drop-static");
                    this.gemfieldRiskAddGrid.componentId = this.componentName;
                    this.gemfieldRiskAddGrid.design(document.getElementById("gemfieldRiskAddGrid"));
                }
            }, 100);
        })
    }

    onRiskAddPageSorting(x, y, z) {
        this.isRiskAddFilter = true;
        this.json["sortOrder"] = (!this.sort).toString();
        this.sort = !this.sort;
        this.json["orderBy"] = x;
        this.gemfieldRiskAddGrid.storeProcedure.nextPage = z;
        this.gemfieldRiskAddGrid.refresh("gemfieldRiskAddGrid");
        this.bindGridRiskAdd();
    }

    onRiskAddPageChanging(x) {
        this.isRiskAddFilter = true;
        this.json["pageIndex"] = x;
        this.json["rowCount"] = this.gemfieldRiskAddGrid.maxPerPage;
        this.gemfieldRiskAddGrid.storeProcedure.nextPage = x;
        this.gemfieldRiskAddGrid.refresh("gemfieldRiskAddGrid");
        this.bindGridRiskAdd();
    }

    onLikeChange(risk: GemfieldRiskAddViewModel, y) {
        this.gemfieldRisksAdd.forEach(x => {
            if (x.specificRiskId == risk.specificRiskId) {
                if (risk.impact > 0 && +y.target.value > 0) {
                    risk.riskIndex = (+y.target.value * risk.impact * 4) + ' %';
                    x.riskIndex = (+y.target.value * risk.impact * 4) + ' %';
                }
                x.likelihood = +y.target.value;
            }
        });
        this.refreshGrid();
    }

    onImpactChange(risk: GemfieldRiskAddViewModel, y) {
        this.gemfieldRisksAdd.forEach(x => {
            if (x.specificRiskId == risk.specificRiskId) {
                if (risk.likelihood > 0 && +y.target.value > 0) {
                    risk.riskIndex = (risk.likelihood * +y.target.value * 4) + ' %';
                    x.riskIndex = (risk.likelihood * +y.target.value * 4) + ' %';
                }
                x.impact = +y.target.value;
            }
        });
        this.refreshGrid();
    }

    onIsRelevent(x: GemfieldRiskAddViewModel, y, z, u) {
        this.checkboxBtnChk = y.target.checked;
        var index = this.gemfieldRisksAdd.findIndex(t => t.specificRiskId == x.specificRiskId);
        if (index != -1) {
            this.gemfieldRisksAdd[index].isRelevant = this.checkboxBtnChk;
            x.isRelevant = this.checkboxBtnChk;
            //this.refreshGrid();
            
        }
    }

    onCheckBoxSelect(t: GemfieldRiskAddViewModel, x, y, z) {
        if (x.target.checked) {
            if (this.selectedRiskArray.indexOf(t.specificRiskId) == -1) {
                this.selectedRiskArray.push(t.specificRiskId);
            }
        }
        else {
            if (this.selectedRiskArray.indexOf(t.specificRiskId) != -1) {
                this.selectedRiskArray.splice(this.selectedRiskArray.indexOf(t.specificRiskId), 1);
            }
        }
        var index = this.gemfieldRisksAdd.findIndex(r => r.specificRiskId == t.specificRiskId);
        if (index != -1) {
            this.gemfieldRisksAdd[index].isChecked = x.target.checked;
            t.isChecked = x.target.checked;
        }
       // this.refreshGrid();
    }

    refreshGrid() {
        this.gemfieldRiskAddGrid = new AppGridSelectDesigner(this.gemfieldRisksAdd, GemfieldRiskAddViewModel, { actions: { onCheckBoxSelect: this.onCheckBoxSelect.bind(this), onReleventSelect: this.onIsRelevent.bind(this), onLikeChange: this.onLikeChange.bind(this), onImpactChange: this.onImpactChange.bind(this), onAdd: this.saveRisk.bind(this) } }, this.componentResolver, this.viewContainerRef, this.json["sortOrder"]);
        this.gemfieldRiskAddGrid.storeProcedure = {
            length: this.totalAddRiskRecords,
            onPageChanging: this.onRiskAddPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onRiskAddPageSorting.bind(this)
        }
        this.gemfieldRiskAddGrid.gridColumns.forEach(x => {
            if (x.columnIndex == 0) {
                x.visible = true;

            } if (x.columnIndex == 1) {
                x.visible = false;
            }
        });
        this.gemfieldRiskAddGrid.currentPage = this.json["pageIndex"];
        this.gemfieldRiskAddGrid.pagingSource.splice(this.gemfieldRiskAddGrid.pagingSource.findIndex(x => x == this.json["rowCount"]), 1);
        this.gemfieldRiskAddGrid.pagingSource.unshift(this.json["rowCount"]);
        this.gemfieldRiskAddGrid.maxPerPage = this.json["rowCount"];
        this.gemfieldRiskAddGrid.designClass.tableClass.push("chosen-drop-static");
        this.gemfieldRiskAddGrid.componentId = this.componentName;
        this.gemfieldRiskAddGrid.refresh("gemfieldRiskAddGrid");
        this.gemfieldRiskAddGrid.design(document.getElementById("gemfieldRiskAddGrid"));
    }

    showError(risk: GemfieldRiskAddViewModel) {
        var element = this.gemfieldRiskAddGrid.getRowElement(risk.specificRiskId);
        if (risk.likelihood == 0 || risk.likelihood == null && risk.likelihood == undefined) {
            element.children[5].lastElementChild.style.borderColor = "red";
        }

        if (risk.impact == 0 || risk.impact == null && risk.impact == undefined) {
            element.children[6].lastElementChild.style.borderColor = "red";
        }

        if (risk.keyId == 0 || risk.keyId == null && risk.keyId == undefined) {
            element.children[8].lastElementChild.style.border = "1px solid red";
        }

        if (risk.keyId1 == 0 || risk.keyId1 == null && risk.keyId1 == undefined) {
            element.children[9].lastElementChild.style.border = "1px solid red";
        }
    }

    saveRisk(risk: GemfieldRiskAddViewModel) {
        if (risk.likelihood > 0 && risk.impact > 0 && risk.keyId > 0 && risk.keyId1 > 0) {
            this.spin = true;
            let riskMenuDetail = new RiskMenuDetail();
            riskMenuDetail.riskMenuDetailID = 0;
            riskMenuDetail.companyId = risk.companyId;
            riskMenuDetail.riskCategoryId = risk.riskCategoryId;
            riskMenuDetail.specificRiskId = risk.specificRiskId;
            riskMenuDetail.isRelevant = risk.isRelevant;
            riskMenuDetail.likelihood = risk.likelihood;
            riskMenuDetail.impact = risk.impact;
            riskMenuDetail.riskIndex = risk.likelihood * risk.impact * 4;
            riskMenuDetail.localRiskOwner = risk.keyId;
            riskMenuDetail.boardRiskOwner = risk.keyId1;
            riskMenuDetail.isDeleted = false;
            riskMenuDetail.createdOn = new Date();
            riskMenuDetail.createdBy = this.loggedInUserId;
            this.selectedRiskObjArray.push(riskMenuDetail);
            let riskAdd = new RiskMenuDetail();
            riskAdd.riskMenuDetails = this.selectedRiskObjArray;
            this.subscription = this.post({ body: riskAdd }).subscribe(data => {
                this.spin = false;
                var existsAlert = getLocalizedValue("Risk_Added");
                if (existsAlert) {
                    //this.toastr.success("Risk Added Successfully");
                    this.toastr.success(existsAlert);
                }
                this.selectedRiskArray = [];
                this.selectedRiskObjArray = [];
                this.searchGemfieldRisk(true);
                this.searchGemfieldRiskAdd();
            });
        } else {
            var existsAlert = getLocalizedValue("Select_Mandatoryfields");
            if (existsAlert) {
                //this.toastr.warning("Please Select mandatory fields");
                this.toastr.warning(existsAlert);
            }
            this.showError(risk);
        }
    }


    saveSelectedRisk() {
        this.showErrorMsg = false;
        this.selectedRiskObjArray = [];
        this.gemfieldRisksAdd.forEach(x => {
            let index = this.selectedRiskArray.indexOf(x.specificRiskId);
            if (index != -1) {
                if (x.likelihood > 0 && x.impact > 0 && x.keyId > 0 && x.keyId1 > 0) {
                    let riskMenuDetail = new RiskMenuDetail();
                    riskMenuDetail.riskMenuDetailID = 0;
                    riskMenuDetail.companyId = x.companyId;
                    riskMenuDetail.riskCategoryId = x.riskCategoryId;
                    riskMenuDetail.specificRiskId = x.specificRiskId;
                    riskMenuDetail.isRelevant = x.isRelevant;
                    riskMenuDetail.likelihood = x.likelihood;
                    riskMenuDetail.impact = x.impact;
                    riskMenuDetail.riskIndex = x.likelihood * x.impact * 4;
                    riskMenuDetail.localRiskOwner = x.keyId;
                    riskMenuDetail.boardRiskOwner = x.keyId1;
                    riskMenuDetail.isDeleted = false;
                    riskMenuDetail.createdOn = new Date();
                    riskMenuDetail.createdBy = this.loggedInUserId;
                    this.selectedRiskObjArray.push(riskMenuDetail);
                } else {
                    this.showErrorMsg = true;
                    this.showError(x);
                }
            }
        });
        if (this.showErrorMsg) {
            var existsAlert = getLocalizedValue("Select_Mandatoryfields");
            if (existsAlert) {
                //this.toastr.warning("Please Select mandatory fields");
                this.toastr.warning(existsAlert);
            }
        } else {
            if (this.selectedRiskObjArray.length > 0) {
                this.spin = true;
                let risk = new RiskMenuDetail();
                risk.riskMenuDetails = this.selectedRiskObjArray;
                this.subscription = this.post({ body: risk }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Risk_Added");
                    if (existsAlert) {
                        //this.toastr.success("Risk Added Successfully");
                        this.toastr.success(existsAlert);
                    }
                    this.selectedRiskArray = [];
                    this.selectedRiskObjArray = [];
                    this.searchGemfieldRisk(true);
                    this.searchGemfieldRiskAdd();
                });
            }
        }
    }
    Uint8ToString(u8a) {
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return c.join("");
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "RiskMenuDetailListComponent";
    }

    formPressMediaTable(item: GemfieldRiskListViewModel): any {
      let tabledata: any;
      let row: string = "";
      let subRow: any = "";
      tabledata = "<div><table><tbody>";
      let subTable: any;
      subTable = "<tr id='demoP&M" + item.riskMenuDetailId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
      if (item.pressMediaDetails != null && item.pressMediaDetails != undefined) {
          for (let i = 0; i < item.pressMediaDetails.length; i++) {
              let x: any = item.pressMediaDetails[i];
              if (i == 0) {
                  let id: string;
                  id = "'P&M" + item.riskMenuDetailId + "'";
                  row = row + `<tr><td >` + item.pressMediaDetails[i].pressMedia + `<i class='fa fa-chevron-down ml-2' id='iconP&M` + item.riskMenuDetailId + `' onclick='Window.RiskMenuDetailListComponent.expandTable("P&M` + item.riskMenuDetailId + `")'></i></td></tr>`;
              }
              else {
                  subRow = subRow + "<tr><td>" + x.pressMedia + "</td></tr>";
              }
          }
          if (subRow != "") {
              subTable = subTable + subRow + "</tbody></table></div></td></tr>";
              row = row + subTable;
          }
          tabledata = tabledata + row + "</tbody></table></div>"
      }
      return tabledata;
    }

    formIncidentTable(item: GemfieldRiskListViewModel): any {
      let tabledata: any;
      let row: string = "";
      let subRow: any = "";
      tabledata = "<div><table><tbody>";
      let subTable: any;
      subTable = "<tr id='demoHSE" + item.riskMenuDetailId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
      if (item.incidentDetails != null && item.incidentDetails != undefined) {
          for (let i = 0; i < item.incidentDetails.length; i++) {
              let x: any = item.incidentDetails[i];
              if (i == 0) {
                  let id: string;
                  id = "'HSE" + item.riskMenuDetailId + "'";
                  row = row + `<tr><td >` + item.incidentDetails[i].incident + `<i class='fa fa-chevron-down ml-2' id='iconHSE` + item.riskMenuDetailId + `' onclick='Window.RiskMenuDetailListComponent.expandTable("HSE` + item.riskMenuDetailId + `")'></i></td></tr>`;
              }
              else {
                  subRow = subRow + "<tr><td>" + x.incident + "</td></tr>";
              }
          }
          if (subRow != "") {
              subTable = subTable + subRow + "</tbody></table></div></td></tr>";
              row = row + subTable;
          }
          tabledata = tabledata + row + "</tbody></table></div>"
      }
      return tabledata;
    }
    formEventTable(item: GemfieldRiskListViewModel): any {
      let tabledata: any;
      let row: string = "";
      let subRow: any = "";
      tabledata = "<div><table><tbody>";
      let subTable: any;
      subTable = "<tr id='demoEvent" + item.riskMenuDetailId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
      if (item.eventDetails != null && item.eventDetails != undefined) {
          for (let i = 0; i < item.eventDetails.length; i++) {
              let x: any = item.eventDetails[i];
              if (i == 0) {
                  let id: string;
                  id = "'Event" + item.riskMenuDetailId + "'";
                  row = row + `<tr><td >` + item.eventDetails[i].event + `<i class='fa fa-chevron-down ml-2' id='iconEvent` + item.riskMenuDetailId + `' onclick='Window.RiskMenuDetailListComponent.expandTable("Event` + item.riskMenuDetailId + `")'></i></td></tr>`;
              }
              else {
                  subRow = subRow + "<tr><td>" + x.event + "</td></tr>";
              }
          }
          if (subRow != "") {
              subTable = subTable + subRow + "</tbody></table></div></td></tr>";
              row = row + subTable;
          }
          tabledata = tabledata + row + "</tbody></table></div>"
      }
      return tabledata;
    }
    formLegalAgreementTable(item: GemfieldRiskListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table><tbody>";
        let subTable: any;
        subTable = "<tr id='demoLegalAgreement" + item.riskMenuDetailId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.legalAgreementDetails != null && item.legalAgreementDetails != undefined) {
            for (let i = 0; i < item.legalAgreementDetails.length; i++) {
                let x: any = item.legalAgreementDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'LegalAgreement" + item.riskMenuDetailId + "'";
                    row = row + `<tr><td >` + item.legalAgreementDetails[i].legalAgreement + `<i class='fa fa-chevron-down ml-2' id='iconLegalAgreement` + item.riskMenuDetailId + `' onclick='Window.RiskMenuDetailListComponent.expandTable("LegalAgreement` + item.riskMenuDetailId + `")'></i></td></tr>`;
                }
                else {
                    subRow = subRow + "<tr><td>" + x.legalAgreement + "</td></tr>";
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
      }
    showGridColumn() {
      this.isShowGridColumn = !this.isShowGridColumn;
    }
    applyVisible(gridColumnConfig: GridColumnConfig[]) {
      if (this.arrConfig != undefined && this.arrConfig != null) {
        this.arrConfig.forEach(x => {
          gridColumnConfig.forEach(y => {
            if (y.name == x.ColumnName)
              x.Visible = y.visible;
          });
        });
      }

      this.gemfieldRiskGrid.reDesign();
      this.gemfieldRiskGrid.updateSource([])
      this.gemfieldRiskGrid.updateSource(this.gemfieldRisks);
      var riskElement = this.gemfieldRiskGrid.gridColumns.filter(x=>x.visible == true).findIndex(x => x.name == "riskIndex");
      if(riskElement != -1)
      {
        setTimeout(() => {
          this.gemfieldRisks.forEach(x => {
            if (this.gemfieldRiskGrid.getRowElement(x.riskMenuDetailId) != undefined) {
              let element = this.gemfieldRiskGrid.getRowElement(x.riskMenuDetailId);
              if (x.riskIndex >= 77) {
                element.children[riskElement].style.backgroundColor = "red";
              }
              if (x.riskIndex <= 25) {
                element.children[riskElement].style.backgroundColor = "lightGreen";
              }
              if (x.riskIndex >= 26 && x.riskIndex <= 51) {
                element.children[riskElement].style.backgroundColor = "yellow";
              }
              if (x.riskIndex >= 52 && x.riskIndex <= 76) {
                element.children[riskElement].style.backgroundColor = "Orange";
              }
            }
          })
          scroolOnSearchResult();
        }, 0);
      }
    }
}

class ExportToExcelModel {
    Result: string;
    Type: string;
    FileName: string;
    EntityType: number;
    Description: string;
    BlobFileName:string;
}


class gemfieldRiskData {
    RiskMenuDetailId: number;
    Company: string;
    CreatedOn: string;
    RiskCategoryName: string;
    SpecificRiskName: string;
    IsRelevant: boolean;
    Likelihood: number;
    Impact: number;
    RiskIndex: string;
    LocalRiskOwnerName: string;
    BoardRiskOwnerName: string;
    NoteDescription: string;
    LastUpdatedDate: string;
}

class pdfRiskData {
    "RiskMenuDetailId": number;
    "Company": string;
    "RiskCategoryName": string;
    "SpecificRiskName": string;
    "IsRelevant": boolean;
    "Likelihood": string;
    "Impact": string;
    "RiskIndex": string;
    "LocalRiskOwnerName": string;
    "BoardRiskOwnerName": string;
    "LastUpdatedDate": string;
    "LastUpdates": string;
}
