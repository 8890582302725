import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MindMapService {
  mindMapObject: any = null;
  graphId: number = 1;
  parentNodeToExpand = '';
  zoomPercentage: number = 100;

  tempSubgraphData: any = {
    root: null,
    event: null,
    eventList: null,
    project: null,
    projectList: null,
    hse: null,
    hseList: null,
    order: null,
    orderList: null,
    user: null,
    userList: null,
    corporate: null,
    corporateList: null,
    knownTo: null,
    knownToList: null,
    myDirectTeam: null,
    myDirectTeamList: null,
    legalAgreement: null,
    legalAgreementList: null,
    pressMedia: null,
    pressMediaList: null,
    peopleDirector: null,
    peopleDirectorList: null,
    ownership: null,
    ownershipList: null,
    risk: null,
    riskList: null,
    pressMediaPeople: null,
    pressMediaPeopleList: null,
    pressMediaCorporate: null,
    pressMediaCorporateList: null,
    pressMediaJournalist: null,
    pressMediaJournalistList: null,
    pressMediaPublication: null,
    pressMediaPublicationList: null,
    pressMediaProduct: null,
    pressMediaProductList: null,
    peopleCorporateInvolved: null,
    peopleCorporateInvolvedList: null,
    employment: null,
    employmentList: null,
    pictureVideo:null,
    pictureVideoList:null
  };
  constructor() { }

  resetTempSubGraphData() {
    this.tempSubgraphData = {
      root: null,
      event: null,
      eventList: null,
      project: null,
      projectList: null,
      hse: null,
      hseList: null,
      order: null,
      orderList: null,
      user: null,
      userList: null,
      corporate: null,
      corporateList: null,
      knownTo: null,
      knownToList: null,
      legalAgreement: null,
      legalAgreementList: null,
      pressMedia: null,
      pressMediaList: null,
      peopleDirector: null,
      peopleDirectorList: null,
      ownership: null,
      ownershipList: null,
      risk: null,
      riskList: null,
      pressMediaPeople: null,
      pressMediaPeopleList: null,
      pressMediaCorporate: null,
      pressMediaCorporateList: null,
      pressMediaJournalist: null,
      pressMediaJournalistList: null,
      pressMediaPublication: null,
      pressMediaPublicationList: null,
      pressMediaProduct: null,
      pressMediaProductList: null,
      peopleCorporateInvolved: null,
      peopleCorporateInvolvedList: null,
      employment: null,
      employmentList: null,
      pictureVideo:null,
      pictureVideoList:null
    };
  }

  customSpinnerSubject: Subject<boolean> = new Subject<boolean>();

}
