import {RequestLogBase,} from '@app/database-models'
//Generated Imports
export class RequestLog extends RequestLogBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}