<div *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/help-supports']" rxText="Label_Help_Support_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="HelpSupportEditComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="HelpSupportEditComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">

        <div class="tabs-container people-tabs">

          <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="currentActiveTab == 'help-supports'"
                (click)="activatedTab('help-supports')"> <label class="mb-0" rxText="HelpSupportAddEditComponent_HelpSupportInfo_t"></label></a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="currentActiveTab == 'help-supports-user-role'"
                (click)="activatedTab('help-supports-user-role')"><label class="mb-0" rxText="HelpSupportAddEditComponent_UserRoles_t"></label></a>
            </li>
          </ul>
          <div class="slick_demo-btn"></div>

          <div class="tab-content">
            <div role="tabpanel" class="tab-pane d-block" [rxLocalisationInit]="componentName" *ngIf="currentActiveTab == 'help-supports'">
              <div class="panel-body">
                <div class="bg-white d-flex flex-column flex-grow-1 pb-0 px-0"
                  [formGroup]="helpSupportFormGroup">
                  <div class="row add-countries-form" (keyup.enter)="editHelpSupport(false)">
                    <div class="col-md-4 pr-xl-5">
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"  rxText="HelpSupportEditComponent_ID_t"></label>
                        <div class="col-md-8">
                          <label type="text"
                                      class="form-control disableLabel-withoutheight mb-0">{{(helpSupportFormGroup.value.helpSupportId
                                      === null || helpSupportFormGroup.value.helpSupportId === undefined ) ? '' :
                                      helpSupportFormGroup.value.helpSupportId}}</label>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"
                          rxText="HelpSupportAddEditComponent_Name_t"> </label>
                        <div class="col-md-8">
                          <input type="text" formControlName="name" tabindex="1" class="form-control "
                             rxPlaceholder="HelpSupportAddEditComponent_Name_p" rxFocus />
                          <small class="form-text text-danger"
                            [class.d-block]="helpSupportFormGroup.controls.name.errors">{{helpSupportFormGroup.controls.name.errorMessage}}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4  col-form-label"
                          rxText="HelpSupportAddEditComponent_Description_t"> </label>
                        <div class="col-md-8">
                          <textarea class="form-control " formControlName="description" tabindex="3"
                             rxPlaceholder="HelpSupportAddEditComponent_Description_p"></textarea>
                          <small class="form-text text-danger"
                            [class.d-block]="helpSupportFormGroup.controls.description.errors">{{helpSupportFormGroup.controls.description.errorMessage}}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"
                          rxText="HelpSupportAddEditComponent_ParentMenu_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                          <!-- <select class="form-control" formControlName="parentHelpSupportId">
                            <option *ngFor="let item of helpSupportLookups.parentHelpSupportLookUp"
                              [value]="item.helpSupportId">{{item.name}}</option>
                          </select> -->
                          <rx-select [(source)]="helpSupportLookups.parentHelpSupportLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="4"
                            [selectPlaceholder]="'Select Parent Menu'"
                            formControlName="parentHelpSupportId" [keyValueProps]="['name','helpSupportId']">
                          </rx-select>
                          <small class="form-text text-danger"
                            [class.d-block]="helpSupportFormGroup.controls.parentHelpSupportId.errors">{{helpSupportFormGroup.controls.parentHelpSupportId.errorMessage}}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"
                          rxText="HelpSupportAddEditComponent_DisplayOrder_t"></label>
                        <div class="col-md-8">
                          <input type="text" formControlName="displayOrderId" class="form-control " tabindex="5"
                             rxPlaceholder="HelpSupportAddEditComponent_DisplayOrder_p" />
                          <small class="form-text text-danger"
                            [class.d-block]="helpSupportFormGroup.controls.displayOrderId.errors">{{helpSupportFormGroup.controls.displayOrderId.errorMessage}}</small>
                        </div>
                      </div>
                      <div class="form-group row check-group">
                        <label class="col-md-4 col-form-label"
                          rxText="HelpSupportAddEditComponent_Published_t"> </label>
                        <div class="col-md-auto d-flex align-items-center mt-2">
                          <div class="i-checks" >
                            <label class="mb-0">
                              <!-- <input type="checkbox" formControlName="published"><i></i> -->
                              <app-checkbox [tabIndex]="'6'"[checkFormControl]="helpSupportFormGroup.controls.published"></app-checkbox>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 px-xl-4">

                      <div class="form-group row">
                        <label class="col-md-2  col-form-label"
                          rxText="HelpSupportAddEditComponent_Body_t"> </label>
                        <div class="col-md-8">
                          <!--<textarea class="form-control " formControlName="body"  rxPlaceholder="helpSupportBody"></textarea>-->
                          <editor
                          formControlName="body"
                          tabindex="7"
                          [init]="{
                            height: 400,
                            menubar: true,
                            automatic_uploads: false,
                            convert_urls: false,
                            relative_urls: false,
                            remove_script_host: false,
                            images_upload_handler: tinyMCE_images_upload_handler,
                            plugins:
                                  'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode fullscreen insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed mergetags  tinymcespellchecker',
                            toolbar:
                                  'undo redo | blocks | bold italic backcolor | spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help ',
                                  spellchecker_languages: 'UK English=en_GB',
                            statusbar: true,
                            menu: {
                              format: {
                                title: 'Format',
                                items: 'configurepermanentpen | bold italic underline strikethrough ' +
                                  'superscript subscript codeformat | styles blocks fontfamily ' +
                                  'fontsize align lineheight | forecolor backcolor | removeformat'
                              }
                            },
                            a11y_advanced_options: true,
                            allow_html_in_named_anchor: true,
                            content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                          }"
                          apiKey="{{ apiKey }}"
                        >
                        </editor>
                          <small class="form-text text-danger"
                            [class.d-block]="helpSupportFormGroup.controls.body.errors">{{helpSupportFormGroup.controls.body.errorMessage}}</small>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group row">
                          <label class="col-md-2 mt-3 col-form-label"
                            rxText="HelpSupportEditComponent_EditorDocument_t"></label>
                          <div class="col-md-8">
                            <app-document-list [entityTypeId]="entityTypeIdForEditorDocuments" [isPicture]="false" [isEditorDocument]="true"></app-document-list>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pl-xl-5">


                    </div>
                  </div>

                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="row mt-auto">

                    <div class="col text-right">
                      <button (click)="editHelpSupport(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                      <button (click)="editHelpSupport(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                      <ng-container *ngIf="helpSupportDelete" [rxLocalisationInit]="componentName">
                        <button (click)="deleteHelpSupport()" class="btn btn-danger"
                          rxText="Btn_Delete_t"></button>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane d-block" *ngIf="currentActiveTab == 'help-supports-user-role'">
              <app-help-support-user-role-add></app-help-support-user-role-add>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
