import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TemporaryAssigneeBase {

//#region tAId Prop
        @prop()
        tAId : number;
//#endregion tAId Prop


//#region dateofArrival Prop
        @prop()
        dateofArrival : Date;
//#endregion dateofArrival Prop


//#region hAWBNumber Prop
        @maxLength({value:50})
        hAWBNumber : string;
//#endregion hAWBNumber Prop


//#region referenceNumber Prop
        @maxLength({value:50})
        referenceNumber : string;
//#endregion referenceNumber Prop


//#region totalIncomingValue Prop
        @required()
        @notEmpty()
        totalIncomingValue : number;
//#endregion totalIncomingValue Prop


//#region totalCarats Prop
        @prop()
        @numeric()
        totalCarats : number;
//#endregion totalCarats Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region tATypeId Prop
        @prop()
        tATypeId : number;
//#endregion tATypeId Prop


//#region stationId Prop
        @prop()
        stationId : number;
//#endregion stationId Prop


//#region dateofDeparture Prop
        @prop()
        dateofDeparture : Date;
//#endregion dateofDeparture Prop


//#region shippingCost Prop
        @required()
        @numeric({allowDecimal:true})
        shippingCost : number;
//#endregion shippingCost Prop


//#region locationId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        locationId : number;
//#endregion locationId Prop


//#region currencyId Prop
        @prop()
        currencyId : number;
//#endregion currencyId Prop





}