import { TemplateConfig } from '@rxweb/grid';

export const RECORD_ACTIVE_GRID_TEMPLATE: TemplateConfig = {
    i: {
        class: [function (e) {
            return (this[e.name] || this[e.name] == 'true') ? "fa fa-check text-green".split(" ") : 'fa fa-times text-danger'.split(" ");
        }],
    }
}

export const RECORD_ACTIVE_NEWSLETTER_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                i: {
                    class: [function (e) {
                        return (this[e.name] || this[e.name] == 'true') ? "fa fa-check text-green".split(" ") : 'fa fa-times text-danger'.split(" ");
                    }],
                    childrens : [{
                        span: {
                            isBind: (x) => {
                                if (x.IsSuppressed) {
                                  return true;
                                }
                                else {
                                  return false;
                                }
                              },
                            class: [function (e) {
                                return (this.IsSuppressed || this.IsSuppressed == 'true') ? "text-capitalize font-weight-bold text-danger".split(" ") : ''.split(" ");
                            }],
                            childrens: [{
                                text: {
                                    text: " (suppressed)"
                                }
                            }]
                        }
                    }]
                }
            },
            // {
            //
            // }
        ]
    }
}

export const RECORD_ONLY_ACTIVE_GRID_TEMPLATE: TemplateConfig = {
    i: {
        class: [function (e) {
            return (this[e.name] || this[e.name] == 'true') ? "fa fa-check text-green".split(" ") : '';
        }],
    }
}
export const RECORD_ACTIVE_DEACTIVE_DEFAULT_GRID_TEMPLATE: TemplateConfig = {
    i: {
        class: [function (e) {
            if(this[e.name] == 1 || this[e.name] == 'true')
            {
                return "fa fa-check text-green".split(" ");
            }else if(this[e.name] == 0 || this[e.name] == 'false')
            {
                return 'fa fa-times text-danger'.split(" ");
            }
            else{
                return "";
            }
        }],
    }
}
