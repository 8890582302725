import { ITTicketCompanyCustomerMappingBase, } from '@app/database-models'
import { prop, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ITTicketCompanyCustomerMapping extends ITTicketCompanyCustomerMappingBase {



  //#region Generated Reference Properties
  @prop()
  @required({ message: "Please Select Person" })
  customerIds: string;
  //#endregion Generated Reference Properties

  @prop({ defaultValue: 0 })
  ticketId: number;

  @prop({ defaultValue: false })
  enableTicketUpdateForUser: boolean;

}
