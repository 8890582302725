<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
    <div class="modal-content">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0">Import People(s) From Excel</h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>

        <div class="modal-body" [formGroup]="ImportUserFromExcelFormGroup"
        *ngIf="showComponent">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Excel File</label>
                        <div class="col-md-8">
                            <!-- <app-file [fileViewModel]="fileViewModelExcel" (files)="fileUpload($event)">
                          </app-file>   -->
                            <!-- <label>{{ImportGemstoneFromExcelFormGroup.value.fileName}}</label> -->
                            <!-- <input id="file" type="file" accept=".xlsx" (change)="oldfileUpload($event)">  -->

                            <div class="custom-file" *ngIf="showComponent" style="margin-left:8px !important">
                                <!-- <label for="{{id}}">{{fileOutputViewModel.fileName}}</label> -->
                                <input id="file" #fileInput
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    (click)="fileInput.value = null" value="" (change)="fileUpload($event)" type="file"
                                    class="custom-file-input">

                                <label for="file"
                                    class="custom-file-label">{{ImportUserFromExcelFormGroup.value.fileName}}</label>
                            </div>
                            <!-- <label class="mt-1">{{ImportGemstoneFromExcelFormGroup.value.fileName}}</label> -->

                            <button *ngIf="ImportUserFromExcelFormGroup.value.fileName != null"
                                (click)="removeDoc()" class="btn btn-danger ml-2" style="margin-top: 5px;">
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <a (click)="ExportToExcel()"> Download Template File</a>

            <div class="modal-footer px-0">
                <button (click)="SaveExcel()" *ngIf="ImportUserFromExcelFormGroup.value.fileName != null"
                    class="btn btn-success mr-1">Upload</button>
                <button (click)="hide()" class="btn btn-primary mr-1">Close</button>
            </div>
        </div>
    </div>
</div>
