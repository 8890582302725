import { gridColumn } from '@rxweb/grid';
import { actionColumn } from '@rxweb/grid';


export class ProjectListViewModel {


//#region month Prop
    @gridColumn({ parameter: false, class: ["text-left"], visible: true, columnIndex:0,  allowSorting: true, headerKey: 'ProjectListComponent_Month_gh', keyColumn: false})
    month : string;
//#endregion month Prop

//#region projectId Prop
  @gridColumn({ parameter: true, class: ["text-left"], visible: false, columnIndex:1,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectId_gh', keyColumn: true})
    projectId : number;
//#endregion projectId Prop

//#region projectType Prop
    @gridColumn({parameter: true, class: ["text-left"], visible: false, columnIndex:2,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectType_gh', keyColumn: false})
    projectType : number;
//#endregion projectType Prop

//#region projectTypeName Prop
    @gridColumn({parameter: false, class: ["text-left"], visible: false, columnIndex:3,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectTypeName_gh', keyColumn: false})
    projectTypeName : string;
//#endregion projectTypeName Prop

//#region projectOwner Prop
    @gridColumn({parameter: true, class: ["text-left"], visible: false, columnIndex:4,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectOwner_gh', keyColumn: false})
    projectOwner : number;
//#endregion projectOwner Prop

//#region projectOwnerName Prop
    @gridColumn({parameter: false, class: ["text-left"], visible: true, columnIndex:5,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectOwnerName_gh', keyColumn: false})
    projectOwnerName : string;
//#endregion projectOwnerName Prop

//#region projectName Prop
  @gridColumn({ parameter: false, configuredTemplate: { templateName:"projectLink" }, class: ["text-left"], visible: true, columnIndex:6,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectName_gh', keyColumn: false})
    projectName : string;
//#endregion projectName Prop

//#region projectIndicator Prop
  @gridColumn({ parameter: false, configuredTemplate: { templateName:"progressBar" }, class: ["text-left"], visible: true, columnIndex:8,  allowSorting: true, headerKey: 'ProjectListComponent_ProjectIndicator_gh', keyColumn: false})
    projectIndicator : number;
//#endregion projectIndicator Prop 

//#region roleId Prop
    @gridColumn({parameter: true, visible: false, columnIndex:9, keyColumn: false})
    roleId : string;
//#endregion roleId Prop 

 //#region totalCount 
    @gridColumn({ parameter: true, name: "totalCount", visible: false, columnIndex: 10, keyColumn: false })
    totalCount: number;
 //#endregion totalCount


//#region parentId 
    @gridColumn({ visible: false, columnIndex: 11, keyColumn: false })
    parentId: number;
//#endregion parentId

//#region projectStartDate 
    @gridColumn({ visible: false, columnIndex: 12, keyColumn: false })
    projectStartDate: number;
//#endregion projectStartDate

//#region projectEndDate 
    @gridColumn({ visible: false, columnIndex: 13, keyColumn: false })
    projectEndDate: number;
//#endregion projectEndDate

//#region subProjects 
    @gridColumn({ visible: false, columnIndex: 14, keyColumn: false })
    subProjects: number;
//#endregion subProjects

//#region isRestrictedProject 
    @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
    isRestrictedProject: boolean;
//#endregion isRestrictedProject

}
