import { NoteMemberMappingBase, } from '@app/database-models'
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
export class NoteMemberMapping extends NoteMemberMappingBase {


  //#region Generated Reference Properties

  //#endregion Generated Reference Properties






































}
