import {vEmailMessageTemplateBase,} from '@app/database-models'
//Generated Imports
export class vEmailMessageTemplate extends vEmailMessageTemplateBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}