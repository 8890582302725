import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingGemstoneBase {

//#region tripGemstoneID Prop
        @prop()
        tripGemstoneID : number;
//#endregion tripGemstoneID Prop


//#region tripGemstoneGUID Prop
        @required()
        tripGemstoneGUID : any;
//#endregion tripGemstoneGUID Prop


//#region tripID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        tripID : number;
//#endregion tripID Prop


//#region gemstoneID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemstoneID : number;
//#endregion gemstoneID Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop


//#region sold Prop
        @prop()
        sold : boolean;
//#endregion sold Prop


//#region customerID Prop
        @prop()
        customerID : number;
//#endregion customerID Prop







}