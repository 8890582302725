import { vCorporateCreditRatingBase } from '../database-models/v-corporate-credit-rating-base';
import { actionColumn, gridColumn } from '@rxweb/grid';
@actionColumn({
    visible: false,
    name: "action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 6, headerTitle: "Action"
})
export class vCorporateCreditRating extends vCorporateCreditRatingBase {
    @gridColumn({
        visible: false,
        name: "editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 6, headerTitle: "Edit"
    })
    editAction: any;

    @gridColumn({
        visible: false,
        name: "deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 7, headerTitle: "Delete"
    })
    deleteAction: any;

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties
}