<div *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']" rxText="Label_Home_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/power-bi-reports']" rxText="Label_Power_BI_Report_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="PowerBIReportAddComponent_AddNewTitle_t"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="PowerBIReportAddComponent_AddNewTitle_t"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="powerBiReportFormGroup"
         [rxSpinner]="spin">
        <div class="row add-countries-form" (keyup.enter)="addPowerBIReport(false)">
          <div class="col-md-6 pr-xl-7">
            <div class="form-group row">
              <label class="col-md-4 col-form-label"  rxText="PowerBIReportAddEditComponent_Name_t">
              </label>
              <div class="col-md-8">
                <input type="text" formControlName="powerBIReportName" tabindex="1" class="form-control "
                  rxPlaceholder="PowerBIReportAddEditComponent_Name_p" rxFocus />
                <small class="form-text text-danger"
                  [class.d-block]="powerBiReportFormGroup.controls.powerBIReportName.errors">{{powerBiReportFormGroup.controls.powerBIReportName.errorMessage}}</small>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-4 col-form-label"  rxText="PowerBIReportAddEditComponent_GUID_t">
              </label>
              <div class="col-md-8">
                <input type="text" formControlName="powerBIReportGUID" tabindex="2" class="form-control "
                  rxPlaceholder="PowerBIReportAddEditComponent_GUID_p" />
                <small class="form-text text-danger"
                  [class.d-block]="powerBiReportFormGroup.controls.powerBIReportGUID.errors">{{powerBiReportFormGroup.controls.powerBIReportGUID.errorMessage}}</small>
              </div>
            </div>
          
            <div class="form-group row check-group">
              <label class="col-md-4 col-form-label"  rxText="PowerBIReportAddEditComponent_Published_t">
              </label>
              <div class="col-md-auto d-flex align-items-center mt-2">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox [tabIndex]="'6'" [checkFormControl]="powerBiReportFormGroup.controls.published"></app-checkbox>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 pl-xl-5">
          </div>
        </div>

        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="addPowerBIReport(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="addPowerBIReport(true)" class="btn btn-primary mr-1" *rxAuthorize="[powerBIReportEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
