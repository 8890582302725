export class RolePermissionViewModel{
    RolePermissionId:number;
    ModuleMastersId:number;
    ParentId:number;
    CanAdd:boolean;
    CanView:boolean;
    CanEdit:boolean;
    CanDelete:boolean;
    HasView:boolean;
    HasAdd:boolean;
    HasEdit:boolean;
    HasDelete:boolean;
    ApplicationModuleId:number;
    ModulesMastersName:string;
    UserId:number;
    ParentName:string;
    DisplayOrder:number;
    // DepartmentId:number;
    RoleId:number;
    FullControl:boolean;
    checkFCId:string;
    checkAddId:string;
    checkEditId:string;
    checkDeleteId:string;
    checkViewId:string;
    // showRow:boolean;
}
