import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneCutTypeGemfieldBase {

//#region cutTypeId Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemStoneCutTypeGemfieldListComponent_CutTypeId_gh', keyColumn: true })
        cutTypeId : number;
//#endregion cutTypeId Prop


//#region cutTypeName Prop
        @gridColumn({isAscending:true, style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemStoneCutTypeGemfieldListComponent_CutTypeNme_gh', keyColumn: false })
        //@gridColumn({isAscending:true, style: { width: "47%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemStoneCutTypeGemfieldListComponent_CutTypeNme_gh', keyColumn: false })
        cutTypeName : string;
//#endregion cutTypeName Prop


//#region typeCode Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'GemStoneCutTypeGemfieldListComponent_TypeCode_gh', keyColumn: false })
        typeCode : string;
//#endregion typeCode Prop

}