import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vExternalPeopleTypeLookUpBase {

//#region externalPersonTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'externalPersonTypeId', keyColumn: true})
        externalPersonTypeId : number;
//#endregion externalPersonTypeId Prop


//#region externalPersonTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'externalPersonTypeName', keyColumn: false})
        externalPersonTypeName : string;
//#endregion externalPersonTypeName Prop

}