// For local
export enum EditorDocumentsEnum {
    HelpSupport = 1530,
    SystemHelp = 1531,
    Notes = 1532,
    PressMedia = 1533,
    MessageQueue = 1534,
    MessageTemplate = 1535,
    ProductPiece = 1536,
    ProductBatch = 1537,
    Topic = 1538,
    EventSendEInvitation = 1539,
    FabergeNews = 1540,
    FabergeHistory = 1541
}

// For STG

// export enum EditorDocumentsEnum {
//     HelpSupport = 1521,
//     SystemHelp = 1522,
//     Notes = 1523,
//     PressMedia = 1524,
//     MessageQueue = 1525,
//     MessageTemplate = 1526,
//     ProductPiece = 1527,
//     ProductBatch = 1528,
//     Topic = 1530,
//     EventSendEInvitation = 1529,
//     FabergeNews = 1531,
//     FabergeHistory = 1532
// }

// For UAT/PRD

// export enum EditorDocumentsEnum {
//     HelpSupport = 1506,
//     SystemHelp = 1507,
//     Notes = 1508,
//     PressMedia = 1509,
//     MessageQueue = 1510,
//     MessageTemplate = 1511,
//     ProductPiece = 1512,
//     ProductBatch = 1513,
//     Topic = 1515,
//     EventSendEInvitation = 1514,
//     FabergeNews = 1516,
//     FabergeHistory = 1517
// }