import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LeaveTypeCorporateMappingBase {

//#region leaveTypeCorporateMapppingId Prop
        @prop()
        leaveTypeCorporateMapppingId : number;
//#endregion leaveTypeCorporateMapppingId Prop


//#region leaveTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        leaveTypeId : number;
//#endregion leaveTypeId Prop


//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop


//#region corporateId Prop
        @prop()
        corporateId : number;
//#endregion corporateId Prop

}