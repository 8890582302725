import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractCountry } from '../domain/abstract-country';
import { Country, vCountry } from '../../../../models/extended-models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppGrid } from '../../../../domain/app-grid';
import { IFormGroup, RxFormBuilder, prop } from '@rxweb/reactive-form-validators';
import { FormGroup, FormArray } from '@angular/forms';
import { GridColumnConfig } from '@rxweb/grid';
import { ToastrMessageType, DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { vCountryBase } from '../../../../models/database-models';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { CountryAddComponent } from '../add/country-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("CountryListComponent")
@access({ accessLevel: RolePermissionEnums.Countries, action: "get" })

@Component({
    selector: "app-country-list",
    templateUrl: './country-list.component.html'
})
export class CountryListComponent extends AbstractCountry implements OnInit, OnDestroy {
    countries: List<Country>;
    subscription: any;
    countriesGrid: AppGrid;
    countryAdd: any = CountryAddComponent;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    titleGridColumnSetting: string;
    showSystemHelpIcon: boolean = false;
    getColumnsData: any;
    arrConfig: any[];

    constructor(private formBuilder: RxFormBuilder, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.Countries;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.titleSearch = getLocalizedValue("Title_Search");
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.title.setTitle("Countries - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)) }
        }).subscribe((t: any) => {
            this.getColumnsData = t;
            if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            }
        })
        this.bindGrid();
    }
    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: any) => {
            this.spin = false;
            this.countries = t;
            this.countriesGrid = new AppGrid(t, vCountry, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.countriesGrid.gridColumns.forEach(x => {
                if (this.countriesGrid.authorize({ accessLevel: RolePermissionEnums.Countries, action: "put" }) && this.countriesGrid.authorize({ accessLevel: RolePermissionEnums.Countries, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.countriesGrid.authorize({ accessLevel: RolePermissionEnums.Countries, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.countriesGrid.authorize({ accessLevel: RolePermissionEnums.Countries, action: "delete" });
                    }
                }
            })
            if (this.arrConfig != undefined && this.arrConfig != null) {
                this.arrConfig.forEach(x => {
                    this.countriesGrid.gridColumns.forEach(y => {
                        if (y.name == x.ColumnName)
                            y.visible = x.Visible;
                    });
                });
            }
            this.countriesGrid.componentId = this.componentName;
            this.countriesGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.countriesGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.countriesGrid.design(document.getElementById("countries"));
            this.showComponent = true;
        })
    }

    onEdit(country: vCountry) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["countries", country.countryId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["countries", country.countryId]);
    }

    onDelete(country: vCountry) {

        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [country.countryId] }).subscribe(data => {
                    this.spin = false;
                    this.countriesGrid.remove(country.countryId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Country");
        if (existsAlert) {
            return existsAlert;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    search(value) {
        this.countriesGrid.search = value.target.value;
    }

    applyVisible(gridColumnConfig: GridColumnConfig[]) {
        this.arrConfig = gridColumnConfig;
        this.countriesGrid.reDesign();
    }

    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }

    get componentName(): string {
        return "CountryListComponent";
    }
}
