<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li> 
        <li class="breadcrumb-item active" rxText="Label_Users_Lockout_t"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
</div>
  
  <div class="wrapper wrapper-content" [rxLocalisationInit]="componentName">
  
    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="Label_Users_Lockout_t"></label>
        </h5>
        <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x" ></i>
        </a>
      </div>
      <div>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="userLockoutSearchFormGroup"
           *ngIf="showComponent" [rxLocalisationInit]="componentName"  [rxSpinner]="spin">
        <div class="row add-countries-form" (keyup.enter)="onKeyPress()">

          <div class="col-md-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="UserLockoutListComponent_LockoutFrom_t"></label>
              <div class="col-md-8">
                <!--<input type="date" formControlName="lockoutFrom" class="form-control"  />-->
                <rx-date showAddon="true" rxFocus formControlName="lockoutFrom" placeholder="Select Date" pickerClass="form-control"  rxFocus></rx-date>
              </div>
            </div>
          </div>
          <div class="col-md-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="UserLockoutListComponent_LockoutTo_t"></label>
              <div class="col-md-8">
                <!--<input type="date" formControlName="lockoutTo" class="form-control"  />-->
                <rx-date showAddon="true" formControlName="lockoutTo" placeholder="Select Date" pickerClass="form-control" ></rx-date>
              </div>
            </div>
          </div>
          <div class="col-md-4 pl-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="UserLockoutListComponent_Email_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="email" class="form-control"  rxPlaceholder="UserLockoutListComponent_Email_p" />
                <small class="form-text text-danger" [class.d-block]="userLockoutSearchFormGroup.controls.email.errors">{{userLockoutSearchFormGroup.controls.email.errorMessage}}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="hr-line-dashed border-top w-100"></div>
        <div class="row">
          <div class="col text-right">
            <button (click)="onKeyPress()" class="btn btn-success mr-1" rxText="Btn_Search_t"></button>
            <ng-container *ngIf="unblockUser" [rxLocalisationInit]="componentName">
              <button (click)="unLoackUser()" class="btn btn-primary mr-1" [disabled]="this.userIds.length === 0" rxText="Btn_Unlock_User_t"></button>
            </ng-container>
          </div>
        </div>
        
         <!-- <div *ngIf="showSearchBar">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="dataTables_filter  d-flex flex-wrap align-items-center" >
            <label class="ml-auto mr-0 mb-3">
              <input type="search" (keyup)="search($event)" class=" table_search form-control form-control-sm pr-4"
                     placeholder="">
            </label>
          </div>
        </div> -->
        <!-- <div class="table-responsive">
          <div id="user-lockout" class="dataTables_wrapper pb-0 dt-bootstrap4">
          </div>
        </div>  -->
      </div>
    </div>

    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showGrid">
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="dataTables_filter  d-flex flex-wrap align-items-center">
          <label class="ml-auto mr-0 mb-3">
            <input (keyup.enter)="search($event)" type="search" *ngIf="showSearchBar" class=" table_search form-control form-control-sm pr-4"
              placeholder="">
          </label>
        </div>
        <div class="table-responsive"  *ngIf="!isNoRecordFound">
          <div id="user-lockout" class="dataTables_wrapper pb-0 dt-bootstrap4">
            <!-- <rx-grid [design]="activityLogsGrid"></rx-grid> -->
          </div>
        </div>
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
          <h4>No Record Found</h4>
        </div>
      </div>
    </div>
  
  </div>
