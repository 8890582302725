import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSPaymentMethodLookUpBase {

//#region paymentMethodId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'paymentMethodId', keyColumn: true})
        paymentMethodId : number;
//#endregion paymentMethodId Prop


//#region paymentMethodName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'paymentMethodName', keyColumn: false})
        paymentMethodName : string;
//#endregion paymentMethodName Prop

}