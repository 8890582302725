import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTitleRecordBase {

//#region titleId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'titleId', keyColumn: true})
        titleId : number;
//#endregion titleId Prop


//#region typeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'typeId', keyColumn: false})
        typeId : number;
//#endregion typeId Prop


//#region genderId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'genderId', keyColumn: false})
        genderId : any;
//#endregion genderId Prop


//#region titleName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'titleName', keyColumn: false})
        titleName : string;
//#endregion titleName Prop

}