export enum LeaveStatusEnum {
        Apply = 344,
        Approve = 345,
        Reject = 346,
        Cancle = 347,
}

export enum LeaveStatusEnumString {
        Awaiting = 344,
        Approved = 345,
        Rejected = 346,
        Cancelled = 347
}

export enum StatusEnumString {
        Pending = 0,
        Approved = 1,
        Declined = 2,
        Cancelled = 3,
        Added = 4,
        Updated = 5,
        SentToFactory = 6,
        Confirmed = 7,
        Completed = 8
}

export enum LeaveTravelButtons {
        Approve = 1,
        Update = 2,
        Edit = 3,
        Reject = 4,
        Cancel = 5
}
