import { http, RxHttp } from "@rxweb/http";
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { Router } from '@angular/router';
import { vAgreementTypeLookUp, vGGLEmployeesLookUp, vSpecificRiskLookUp, vSupplierLookUp } from '@app/models';

@http({
    path: "api/LegalAgreementProjectMappings",
})

export class AbstractLegalAgreementProjectMapping extends RxHttp {
    spin: boolean = false;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    router: Router;
    id: number = 0;
    showGrid: boolean = false;
    isShowGrid: boolean;
    totalRecords: number = 0;
    isNoRecordFound: boolean;
    isFilter: boolean = false;
    legalAgreementLookup: any = {
        agreementTypeLookUp: vAgreementTypeLookUp,
        gglEmployeesLookUp: vGGLEmployeesLookUp
    }

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
