import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StateProvince } from '@app/models';
import { AbstractStateProvince } from '../domain/abstract-state-province';
import { vCountryLookUp } from '@app/models';
import { StatusEnum } from '../../../../enums/status.enum';
import { Router } from '@angular/router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { LanguagesEnums } from '../../../../custom-enum/language-enums';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { StateProvinceEditComponent } from '../edit/state-province-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
@access({ accessLevel: RolePermissionEnums.StateProvince, action: "post" })

@multilingual("StateProvinceAddComponent")
@Component({
    selector: "app-state-province-add",
    templateUrl: './state-province-add.component.html'
})
export class StateProvinceAddComponent extends AbstractStateProvince implements OnInit, OnDestroy {
    stateProvince: StateProvince;
    subscription: any;
    stateProvinceEdit:any=StateProvinceEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.StateProvince;
        this._this = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("States/Provinces - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.lookup([{ path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" }]).subscribe((response:any) => {
            this.stateProvinceLookup.countryLookUp=new Array<vCountryLookUp>();
            this.stateProvinceLookup.countryLookUp=response.countryLookUp
            // let country=new vCountryLookUp();
            // country.countryId=0;
            // country.countryName="Select";
            // this.stateProvinceLookup.countryLookUp.unshift(country);
            this.stateProvince = new StateProvince();
            this.stateProvince.stateProvinceId = 0;
            this.stateProvince.statusId = StatusEnum.Active;
            this.stateProvince.countryId=0;
            this.stateProvince.languageId = LanguagesEnums.English;
            this.stateProvinceFormGroup = this.formBuilder.formGroup(this.stateProvince) as IFormGroup<StateProvince>;
            this.showComponent = true;

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addStateProvince(isRedirect: boolean) {
        this.stateProvinceFormGroup.submitted = true;
        if (this.stateProvinceFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.stateProvinceFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/state-provinces', data]) : this.router.navigate(['/state-provinces']);

            })
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.stateProvinceFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }

    }

    get componentName(): string {
      return "StateProvinceAddComponent";
    }
}
