import { gridColumn } from "@rxweb/grid"


export class LeaveMasterHolidayProcessListBase {

        //#region masterId Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'masterId', keyColumn: true })
        masterId: number;
        //#endregion masterId Prop


        //#region leaveTypeName Prop
        @gridColumn({ style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'LeaveMasterListComponent_LeaveTypeName_gh', keyColumn: false })
        leaveTypeName: string;
        //#endregion leaveTypeName Prop


        //#region createdOn Prop
        @gridColumn({ style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 2, allowSorting: true, headerKey: 'LeaveMasterListComponent_CreatedOn_gh', keyColumn: false })
        createdOn: Date;
        //#endregion createdOn Prop


        //#region startDate Prop
        @gridColumn({ isAscending: true, style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 3, allowSorting: true, headerKey: 'LeaveMasterListComponent_StartDate_gh', keyColumn: false })
        startDate: Date;
        //#endregion startDate Prop


        //#region endDate Prop
        @gridColumn({ style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 4, allowSorting: true, headerKey: 'LeaveMasterListComponent_EndDate_gh', keyColumn: false })
        endDate: Date;
        //#endregion endDate Prop


        //#region days Prop
        @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'LeaveMasterListComponent_Days_gh', keyColumn: false })
        days: number;
        //#endregion days Prop


        //#region status Prop
        @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: false, headerKey: 'LeaveMasterListComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName: "leaveStatus" } })
        status: number;
        //#endregion status Prop

        //#region name Prop
        @gridColumn({ visible: false, columnIndex: 7, allowSorting: true, headerKey: 'name', keyColumn: false })
        name: string;
        //#endregion name Prop

        //#region managerName Prop
        @gridColumn({ visible: false, columnIndex: 8, allowSorting: true, headerKey: 'managerName', keyColumn: false })
        managerName: string;
        //#endregion managerName Prop

        //#region reason Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerKey: 'reason', keyColumn: false })
        reason: string;
        //#endregion reason Prop

        //#region holidayType Prop
        @gridColumn({ visible: false, columnIndex: 10, allowSorting: true, headerKey: 'holidayType', keyColumn: false })
        holidayType: string;
        //#endregion holidayType Prop

        //#region canCancel Prop
        canCancel: boolean
        //#endregion canCancel Prop
}