import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
import { DocumentTypeUserCompanyViewModel } from 'src/app/view-model/document-type-user-mapping-company-view-model';

@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 18, headerTitle: "Action"
})

export class DocumentTypeUserMappingViewModel {

    //#region documentTypeUserMappingId 
    @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'masterId', keyColumn: true })
    documentTypeUserMappingId: number;
    //#endregion documentTypeUserMappingId 

    //#region fullName Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'DocumentTypeUserMappingListComponent_FullName_gh', keyColumn: false })
    fullName: string;
    //#endregion fullName Prop

    @gridColumn({
        template:{
            div:{
              attributes:{
                innerHTML:function(x,y){
                 return this.company;
                }
              },
            }
        }, parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, headerKey: 'DocumentTypeNotesDetailComponent_Corporate_gh', keyColumn: false
    })
    
    company:string

    //#region emailAddress 
    @gridColumn({ isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'DocumentTypeNotesDetailComponent_Email_gh', keyColumn: false, parameter : false })
    emailAddress: string;
    //#endregion emailAddress 

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop
    companyDetails: DocumentTypeUserCompanyViewModel[];
}