<div [rxLocalisationInit]="componentName" class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
  <div class="modal-content" >
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="EventCustomerMappingAddComponent_Title_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body"  [formGroup]="importEventSearchFormGroup"
      *ngIf="showComponent" [rxLocalisationInit]="componentName" (keyup.enter)="searchEvents()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="EventCustomerMappingAddComponent_Event_Name_t"></label>
            <!-- <div class="col-md-8">
              <input type="text" formControlName="eventName" tabindex="1" class="form-control"
                placeholder="Enter Event Name" rxFocus>
            </div> -->
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1" formControlName="eventName"
                 [valueInQueryParams]="true" [queryParams]="{'eventId': eventId }"
                [keyValueProps]="['#EventName#','EventId']" [selectPlaceholder]="'Select Event'" path="api/SearchEvent/GetEvents"
                (keyup)="checkNameText($event)" (keyup.enter)="checkNameText($event)" (changed)="onNameClick($event)"
                [text]="searchEventName" [minimumCharacterSearchLength]="2" [enableFreeText]="true">
              </rx-select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="EventCustomerMappingAddComponent_Event_Type_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="eventTypeLookUp" #rxSelect mainClass="form-control"
                formControlName="eventType" [controlTabIndex]="3"
                [keyValueProps]="['eventTypeName','eventTypeId']" [selectPlaceholder]="'Select Event Type'">
              </rx-select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="EventCustomerMappingAddComponent_Event_Start_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="eventStartDate" [controlTabIndex]="3"
                placeholder="Select Start Date" pickerClass="form-control" ></rx-date>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="EventCustomerMappingAddComponent_Event_End_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="eventEndDate" [controlTabIndex]="2"
                placeholder="Select End Date" pickerClass="form-control" ></rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">End Date should be greater than Start
                Date<br /></small>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer px-0">
        <button (click)="searchEvents()" class="btn btn-success" rxText="Btn_Search_t"></button>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">
        <div class="table-responsive" *ngIf="isShowGrid">
          <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="improtEventsGrid"></rx-grid>
            </div>
          </div>
        </div>
        <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
          <h4>{{ErrorMsg}}</h4>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
      <div *ngIf="showSaveButton" [rxLocalisationInit]="componentName">
        <button (click)="saveEvents(false)" class="btn btn-success" [disabled]="this.eventIds.length == 0" rxText="EventCustomerMappingAddComponent_Button_t">
        </button>
      </div>
      <button (click)="hide()" class="btn btn-outline-primary ml-0" rxText="EventCustomerMappingAddComponent_Close_t"></button>
    </div>
  </div>
</div>
