import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserDetailBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop

//#region userDetailId Prop
       @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'userDetailId', keyColumn: false})
       userDetailId : number;
//#endregion userDetailId Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region fullName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'fullName', keyColumn: false})
        fullName : string;
//#endregion fullName Prop

//#region roleTypeId Prop
       @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'roleTypeId', keyColumn: false})
       roleTypeId : number;
//#endregion roleTypeId Prop

//#region title Prop
       @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'title', keyColumn: false})
       title : number;
//#endregion title Prop

}