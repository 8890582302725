import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
  allowSorting: false,
  style: { width: "1%","text-align": "left" },
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})

export class ProjectEventsListViewModel {

    @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'eventCheckbox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region eventId Prop
    @gridColumn({ visible: false, columnIndex: 1, headerTitle: 'Action', keyColumn: true, allowSorting: false })
    eventId: number;
    //#endregion eventId Prop

    //#region eventName Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 2, headerKey: 'ProjectEventAddEditComponent_EventName_gh', keyColumn: false, allowSorting: true })
    eventName: string;
    //#endregion eventName Prop

    //#region eventTypeId Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 3, headerKey: 'ProjectEventAddEditComponent_EventTypeID_gh', keyColumn: false, allowSorting: false })
    eventTypeId: number;
    //#endregion eventTypeId Prop

    //#region eventType Prop
    @gridColumn({ style: { width: "15%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 4, headerKey: 'ProjectEventAddEditComponent_EventType_gh', keyColumn: false, allowSorting: true })
    eventType: string;
    //#endregion eventType Prop

    //#region eventStartDate Prop
    @gridColumn({ style: { width: "15%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'ProjectEventAddEditComponent_EventStartDate_gh', keyColumn: false, allowSorting: true })
    eventStartDate: string;
    //#endregion eventStartDate Prop

    //#region eventEndDate Prop
    @gridColumn({ style: { width: "15%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 6, headerKey: 'ProjectEventAddEditComponent_EventEndDate_gh', keyColumn: false, allowSorting: true })
    eventEndDate: string;
    //#endregion eventEndDate Prop


    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 7, headerKey: 'totalCount', keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    isRestrictedEvent:boolean;

    @gridColumn({
      template:{
        div:{
            style: {
            "word-break": "break-word;",
          },
          attributes:{
            innerHTML:function(x,y){
            return this.evName;
            }
          },
        }
    },
  style: { width: "15%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'ProjectEventAddEditComponent_EventName_gh', keyColumn: false, allowSorting: true })
  evName:string

  isAllowed: boolean;

  totalRestricted:number;

}