import { prop } from '@rxweb/reactive-form-validators';
import { gridColumn, actionColumn } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
@actionColumn({
  allowSorting: false,
  style: { width: "1%" },
  configuredTemplate: { templateName: 'delete' }, columnIndex: 12, headerTitle: "Action"
})
export class DocumentListModel {

  @gridColumn({ allowSorting: false, style: {
    width: "1%",
    "text-align": "center",
    "min-width": "60px",
    " max-width": "60px",
  }, class:["w-60"], visible: true,name:"isChecked", configuredTemplate: { templateName: 'bindMoveDocumentCheckBox' }, columnIndex: 1, headerKey:'DocumentListComponent_SelectDoc_gh', keyColumn: false })
  isChecked: boolean

  @gridColumn({ isAscending:false, visible: false, columnIndex: 0, allowSorting: true, headerTitle: 'Document Id', keyColumn: true })
  documentId: number;

  @gridColumn({
    visible: false,  class: ["text-left"],
    columnIndex: 1, allowSorting: true, headerTitle: 'Event Type', keyColumn: false
  })
  totalCount: number;

  @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 1, allowSorting: true, headerKey: 'DocumentListComponent_CreatedOn_gh', keyColumn: false  })
  createdOn: Date;

  @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 2, allowSorting: true, headerKey: 'DocumentListComponent_CreatedBy_gh', keyColumn: false })
  userName: string;

  @gridColumn({  template:{
    div: {
      childrens: [
        {
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onOneLink"
            },
            childrens: [{
              text: {
                text: function (e) { return this.fileName }
              }
            }]
          }
        },
        {
          a: {
            isBind:(x)=>{
              if(x.fileUrl && x.fileUrl?.length > 0)
                return true;
              return false;
            },
            event: {
                click: "onCopy"
            },
            childrens: [{
                i: {
                    class: "fa fa-copy mx-2".split(" "),
                    attributes: {"title": getLocalizedValue("Title_Copy_Link")}
                }
            }]
          }
        }
      ]
    }
  }, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 3, allowSorting: true, headerKey: 'DocumentListComponent_FileName_gh', keyColumn: false })
   fileName:string


   @gridColumn({  template:{
    div: {
      childrens: [{
        text: {
          text: function (e) { 
            
          var _size = this.size;
          var fSExt = new Array("Bytes", "KB", "MB", "GB"),
          i = 0;
          while (_size > 900) {
          _size /= 1024;
          i++;
          }
          var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

            
            return exactSize 
          
          }
        }
      }]
  }
  }, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'DocumentListComponent_FileSize_gh', keyColumn: false })
   size:string

 @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 5, allowSorting: true, headerKey: 'DocumentListComponent_NoteType_gh', keyColumn: false })
  noteType: string;

  @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 6, allowSorting: true, headerKey: 'DocumentListComponent_Subject_gh', keyColumn: false })
  subject: string;

  @gridColumn({
    visible: false, columnIndex: 7, allowSorting: true, headerKey: '', keyColumn: false
  })
  thumbnailFileUrl: string;

  @gridColumn({
    visible: false, columnIndex: 8, allowSorting: true, headerKey: 'DocumentListComponent_URL_gh', keyColumn: false
  })
  fileUrl: string;

  @gridColumn({ style: { width: "8%", "text-align": "left" }, class: ["text-left"], configuredTemplate: { templateName: "gridTextAreaDocumentDescription" }, visible: true, columnIndex: 9, headerKey: 'DocumentListComponent_DocumentDescription_gh', keyColumn: false, allowSorting: true }) //#287800//03-06-2024//allowSorting change true from false 
  documentDescription:string;

//#region documentTypeId Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 10, allowSorting: false,headerKey: 'DocumentListComponent_DocumentTypeId_gh', keyColumn: false })
  documentTypeId: number;
  //#endregion documentTypeId Prop


  //#region documentTypeData Prop
  @gridColumn({
    visible: true,
    template: {
      div: {
        onDemandSelector: {
          selector: 'rx-select', columnName: 'documentTypeData'
        }
      }
    }, headerKey: 'DocumentListComponent_DocumentTypeId_gh', name: "documentTypeData", columnIndex: 11, style: { width: "10%", "min-width": "220px", "text-align": "initial" }, class: ["text-left"], allowSorting: true  //#287800//03-06-2024//allowSorting change true from false 
  })
  documentTypeData: any;
  //#endregion documentTypeData Prop

  documentTypeRestrictedData: any;
  isVideo:string;

  dbDocumentDescription:string;

  displayOrder:number;

  entityId:number;

  entityTypeId :number;

  createdBy : number;

  noteId : number;

  seeOnline : boolean;

  isPicture : boolean;

  type : any;

  pictureId:any;

  isPreviewSupported:boolean;

  dbDocumentTypeId : number;

  documentTypeFlag : boolean;

}
