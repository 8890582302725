import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './app-checkbox.component.html',
  styleUrls: ['./app-checkbox.component.css']
})
export class AppCheckboxComponent implements OnInit {

  isChecked: boolean;
  @Input() tabIndex:number;
  @Input() checked:boolean = false;
  id: string;
  showComponent:boolean=false;
  @Input() checkFormControl: FormControl = new FormControl();
  @Input() checkboxCheckControl:boolean;
  @Input() isDisabled: boolean = false;
  constructor() { }

  ngOnInit() {
    this.id = "file" + Math.random().toString();
    if(this.checkFormControl.value==null){
      if(this.checked){
        this.isChecked=true;
      }
      else{
        this.isChecked=false;
      }
    }
    else{
      if (this.checkFormControl.value == "true" || this.checkFormControl.value == true) {
        this.isChecked = true;
      }
      else
      {
        this.isChecked = false;
      }
      if(this.checked){
        this.isChecked = true;
      }
    }
    
    this.showComponent=true
  }

onfocus(event:any){
  //position: relative;border: 2px solid #74c874;border-radius: 2px;
(event.target.parentElement as HTMLElement).style.position="relative";
(event.target.parentElement as HTMLElement).style.border="2px solid #74c874";
(event.target.parentElement as HTMLElement).style.borderRadius="2px";
}
onSpace(event:any){
  setTimeout(() => {
    if(!event.target.checked){
      (event.target.parentElement as HTMLElement).style.position="relative";
    (event.target.parentElement as HTMLElement).style.border="2px solid #74c874";
    (event.target.parentElement as HTMLElement).style.borderRadius="2px";
    }
    
    else{
      (event.target.parentElement as HTMLElement).style.border="1px solid black";
    }
  }, 1);
  
}
onfocusout(event:any){
  (event.target.parentElement as HTMLElement).style.border="1px solid black";
}
click(event:any){
  setTimeout(() => {
    if(!event.target.checked){
      (event.target.parentElement as HTMLElement).style.position="relative";
    (event.target.parentElement as HTMLElement).style.border="2px solid #74c874";
    (event.target.parentElement as HTMLElement).style.borderRadius="2px";
    }
    
    else{
      (event.target.parentElement as HTMLElement).style.border="1px solid black";
    }
  }, 1);
}
ondivfocus(){
  console.log("div focused");
}
  onChange(checked: boolean) {
    this.checkFormControl.setValue(!this.isChecked);
    this.isChecked = !this.isChecked;
  }
}
