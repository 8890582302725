import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const GOAL_OBJECTIVES_REPORT_EXPAND: TemplateConfig = {
  div: {
    isBind: (x) => {
      return (x.goalDetails.length > 0);
    },
    childrens: [{
      a: {
        event: {
          click: "onNoteExpand"
        },
        childrens: [{
          i: {
            class: "fa fa-plus fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Expand") }
          }
        }],
        class: ["mr-2"]
      }
    }]
  }
};
