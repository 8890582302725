<!-- <div *ngIf="showComponent">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
      <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2">System Admin</h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/system']">System Admin</a>
          </li>
          <li class="breadcrumb-item active">Blacklist</li>
        </ol>
      </div>
      <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
      </div>
    </div>
  </div>
    <div class="wrapper wrapper-content flex-grow-1"  [rxSpinner]="spin">

      <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white">
          <h5>Blacklist</h5>
        </div>

        <div class="ibox-content bg-white d-flex flex-column flex-grow-1">

          <div class="tabs-container people-tabs">

            <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0" role="tablist">
              <li>
                <a class="nav-link active" data-toggle="tab" (click)="activatedTab('1')">IP Addresses</a>
              </li>
              <li>
                <a class="nav-link" data-toggle="tab" (click)="activatedTab('2')">IP Networks</a>
              </li>
            </ul>
            <div class="slick_demo-btn"></div>

            <div class="tab-content">
              <div role="tabpanel" class="tab-pane d-block" *ngIf="currentActiveTab == '1'">
                <div class="panel-body">
                  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
                    <a class="btn btn-success btn-sm mb-3" [routerLink]="['/banned-ip-addresses/add']">Add banned IP</a>
                    <label class="ml-auto mr-0 mb-3">
                      <input type="search" (keyup)="search($event)" class=" table_search form-control form-control-sm pr-4" placeholder="">
                    </label>
                  </div>
                  <div class="table-responsive">
                    <div id="banned-ip-addresses">
                    </div>
                  </div>
                </div>
              </div>
              <div role="tabpanel" class="tab-pane d-block" *ngIf="currentActiveTab == '2'">
                <div class="panel-body">
                  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
                    <a class="btn btn-success btn-sm mb-3" [routerLink]="['/banned-ip-network/add']">Add banned network</a>
                    <label class="ml-auto mr-0 mb-3">
                      <input type="search" (keyup)="search($event)" class=" table_search form-control form-control-sm pr-4" placeholder="">
                    </label>
                  </div>
                  <app-banned-ip-network-list></app-banned-ip-network-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

<div class="ibox-content bg-white d-flex flex-column flex-grow-1 border-0 p-0" *rxMultilingual="" [rxLocalisationInit]="componentName" [rxSpinner]="spin">
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <a class="btn btn-success btn-sm mb-3" [routerLink]="['/banned-ip-addresses/add']" rxText="Btn_Add_Banned_IP_t" ></a>
    <label class="ml-auto mr-0 mb-3">
      <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
        placeholder="" [title]="titleSearch">
    </label>
  </div>
  <div class="table-responsive" *ngIf="!isNoRecordFound"> 
    <rx-grid [design]="bannedIPAddressGrid"></rx-grid>
  </div>
  <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>
</div>
