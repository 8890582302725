import {vGemStoneClarityLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneClarityLookUp extends vGemStoneClarityLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}