import {IncidentPersonsAffectedBase,LookupInjurySeverityBase,LookupLostTimeInjuryBase,SupplierBase,TrackIncidentBase,UserBase,PersonBodilyLocationBase,PersonInjuryTypeBase, UserDetailBase,} from '@app/database-models'
import { prop, propArray, email, required } from '@rxweb/reactive-form-validators';
import { AbstractRingEggSize } from 'src/app/components/master/ring-egg-sizes/domain/abstract-ring-egg-size';
//Generated Imports
export class IncidentPersonsAffected extends IncidentPersonsAffectedBase 
{

    constructor() {
        super()
        this.injurySeverityName = "";
        this.lostTimeInjuryName="";
        this.name="";
        this.bodilyLocationName="";
        this.injurySeverityName="";
        this.injuryTypeName="";
        this.conditionMedicalTreatment="";
        this.involvementRole="";
        //this.workinghrslost=0;
        //this.lostTimeInjuryId = 0;
        this.daysLost = 0;
        this.ginaCount = 0;
    }
@prop()
//@required()
userName:string;
@prop()
bodilyLocationIds:string;
@prop()
injuryTypeIds:string;
@prop()
personsAffectedIds:string;
@prop()
userId:number;
@prop()
injurySeverityName:string;
@prop()
lostTimeInjuryName:string;
@prop()
name:string;
@prop()
bodilyLocationName:string;
@prop()
injuryTypeName:string;
@prop()
minerType:string;
@prop()
typeName:string;
@prop()
daysCount:any;
@prop()
incidentId: number;
@prop()
contactPermissionRequired:boolean;
@prop()
introducesBy: string;
@prop()
isUnwelcome:boolean;
@prop()
roleTypeId: number;
@prop()
ginaCount: number;

//#region Generated Reference Properties
//#region lookupInjurySeverity Prop
lookupInjurySeverity : LookupInjurySeverityBase;
//#endregion lookupInjurySeverity Prop
//#region lookupLostTimeInjury Prop
lookupLostTimeInjury : LookupLostTimeInjuryBase;
//#endregion lookupLostTimeInjury Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region personBodilyLocations Prop
@propArray(PersonBodilyLocationBase)
personBodilyLocations : PersonBodilyLocationBase[];
//#endregion personBodilyLocations Prop
//#region personInjuryTypes Prop
@propArray(PersonInjuryTypeBase)
personInjuryTypes : PersonInjuryTypeBase[];
//#endregion personInjuryTypes Prop

//#endregion Generated Reference Properties


@prop()
personsAffectedCategoryIds:string;

@prop()
diseaseIds:string;

@prop()
company:string;








































}
