import { TemplateConfig } from '@rxweb/grid';

export const BOM_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => {
        if(x.showCostOnly==null || x.showCostOnly==false){
          return true;
        }
        else{
          return false;
        }
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },

                 event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  title:"Select and Calculate Cost",
                  id: function (e) {
                    return "selected-checkbox-" + this.materialId;
                  },
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                    return this[e.name];
                  },
                  disabled: function(e) {
                    if (this.quantity > 0 && this.exchangeRate > 0 && this.currencyId > 0 && Number(this.unitPrice) > 0 && (this.materialName != "" || this.materialName != null || this.materialName != undefined))
                      return false;
                    else return true;
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}
