import {ProjectEventMappingBase,ProjectBase,} from '@app/database-models'
import { propArray, prop } from '@rxweb/reactive-form-validators';
import { ProjectEventInputModel } from 'src/app/view-model/project-event-input-model';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
//Generated Imports
export class ProjectEventMapping extends ProjectEventMappingBase 
{


    eventIds: ProjectEventMapping[];
   
//#region Generated Reference Properties
//#region project Prop

project : ProjectBase;

@propArray(ProjectEventMappingViewModel)
projectEventInput:ProjectEventMappingViewModel[];
//#endregion project Prop

//#endregion Generated Reference Properties


headerBtnCheck:boolean;

query:string;











}