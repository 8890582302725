<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0">Change your password</h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="ml-4 modal-body pb-0 mb-2">
      <span class="text-danger" *ngIf="daysLeft<=expiryDays && daysLeft>1"><b>Your password will expire in {{daysLeft}}
          days. Please update password</b></span>
      <span class="text-danger" *ngIf="daysLeft==1"><b>Your password will expire today. Please update
          password</b></span>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="changePasswordScreen()">Change Password</button>
      <button (click)="hide()" class="btn btn-outline-primary">Close</button>
    </div>
  </div>
</div>