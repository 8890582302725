import { actionColumn, gridColumn } from '@rxweb/grid';

export class MoveNoteDocumentBookingSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  //#region bookingId Prop
  @gridColumn({ parameter: false, configuredTemplate: { templateName: "reDirect" }, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, keyColumn: false, headerKey: 'MoveNoteDocumentComp_BookingId_gh'})
  bookingId: number;
  //#endregion bookingId Prop

  //#region bookingName Prop
  @gridColumn({ parameter: false, style: { width: "2%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, headerKey: 'MoveNoteDocumentComp_BookingName_gh', keyColumn: false })
  bookingName: string;
  //#endregion bookingName Prop

  //#region startDate Prop
  @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, headerKey: 'MoveNoteDocumentComp_BookingStartDate_gh', keyColumn: false })
  startDate: string;
  //#endregion startDate Prop

  //#region endDate Prop
  @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, headerKey: 'MoveNoteDocumentComp_BookingEndDate_gh', keyColumn: false })
  endDate: string;
  //#endregion endDate Prop

  //#region responsiblePerson Prop
  @gridColumn({ parameter: false, style: { width: "2%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'MoveNoteDocumentComp_BookingResponsiblePer_gh', keyColumn: false })
  responsiblePerson: string;
  //#endregion responsiblePerson Prop

  //#region totalCount Prop
  //@gridColumn({ parameter: false, name: "totalCount", visible: false, columnIndex: 10, keyColumn: false })
  totalCount: number;
  //#endregion totalCount Prop
}
