import { prop, email, required, propArray } from '@rxweb/reactive-form-validators';
export class AllSettingFile {
    @prop()
    settingKeyId: number

    @prop()
    settingKeyName: string

    @prop()
    settingValue: string

    @prop()
    fileName: string

    @prop()
    fileExtention: string

    @prop()
    fileData: string
    @prop()
    blobFileName:string
    
}
export class AllSettingViewModel {
    @prop()
    settingKeyId: number;

    @prop()
    settingKeyName: string;

    @prop()
    @required()
    settingValue: string;

    @prop()
    description: string;

    @prop()
    statusId: number;

    @prop()
    type: number;

    @prop()
    settingJson: string;

    @prop()
    localLizationKey: string;

    @prop()
    placeHolderLocalLizationKey: string;

    @propArray(AllSettingFile)
    file: AllSettingFile[];

    @prop()
    dataKey: string;

}


