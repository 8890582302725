import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CollectionDesignBase {

//#region collectionDesignId Prop
        @prop()
        collectionDesignId : number;
//#endregion collectionDesignId Prop


//#region collectionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        collectionId : number;
//#endregion collectionId Prop


//#region description Prop
        @prop()
        @maxLength({value:1000})
        @trim()
        description : string;
//#endregion description Prop


//#region designTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        designTypeId : number;
//#endregion designTypeId Prop


//#region displayOrderId Prop
        @prop()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region videoUrl Prop
        @maxLength({value:500})
        @trim()
        videoUrl : string;
//#endregion videoUrl Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region imageUrlNameFirst Prop
        @required()
        @maxLength({value:100})
        imageUrlNameFirst : string;
//#endregion imageUrlNameFirst Prop


//#region imageUrlAltFirst Prop
        @maxLength({value:100})
        @trim()
        imageUrlAltFirst : string;
//#endregion imageUrlAltFirst Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region imageUrlNameSecond Prop
        @required()
        @maxLength({value:100})
        imageUrlNameSecond : string;
//#endregion imageUrlNameSecond Prop


//#region imageUrlAltSecond Prop
        @maxLength({value:100})
        @trim()
        imageUrlAltSecond : string;
//#endregion imageUrlAltSecond Prop


//#region imageUrlNameThird Prop
        @required()
        @maxLength({value:100})
        imageUrlNameThird : string;
//#endregion imageUrlNameThird Prop


//#region imageUrlAltThird Prop
        @maxLength({value:100})
        @trim()
        imageUrlAltThird : string;
//#endregion imageUrlAltThird Prop


//#region imageUrlNameFourth Prop
        @required()
        @maxLength({value:100})
        imageUrlNameFourth : string;
//#endregion imageUrlNameFourth Prop


//#region imageUrlAltFourth Prop
        @maxLength({value:100})
        @trim()
        imageUrlAltFourth : string;
//#endregion imageUrlAltFourth Prop


//#region imageUrlNameFifth Prop
        @required()
        @maxLength({value:100})
        imageUrlNameFifth : string;
//#endregion imageUrlNameFifth Prop


//#region imageUrlAltFifth Prop
        @maxLength({value:100})
        @trim()
        imageUrlAltFifth : string;
//#endregion imageUrlAltFifth Prop


//#region parentCollectionDesignId Prop
        @prop()
        parentCollectionDesignId : number;
//#endregion parentCollectionDesignId Prop



}