import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPieceOwnerReportLookUpBase {

//#region Id Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'Id', keyColumn: true})
        Id : number;
//#endregion Id Prop


//#region Value Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'Value', keyColumn: false})
        Value : string;
//#endregion Value Prop

}