import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vShippingMethodRecordBase {

//#region shippingMethodID Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'shippingMethodID', keyColumn: true})
        shippingMethodID : number;
//#endregion shippingMethodID Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}