
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
import { ValidateUserBase } from '../database-models/validate-user-base';

@actionColumn({
    visible: true,
    name: "action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'viewModel' }, columnIndex: 8, headerKey: 'ExistsUserSearchComponent_Select_gh'
  })

export class vValidateUser extends ValidateUserBase 
{
}
