import {CustomerActionBase,ACLBase,ACLMenuBase,} from '@app/database-models'
//Generated Imports
export class CustomerAction 
{




//#region Generated Reference Properties
//#region aCL Prop
aCL : ACLBase[];
//#endregion aCL Prop
//#region aCLMenus Prop
aCLMenus : ACLMenuBase[];
//#endregion aCLMenus Prop

//#endregion Generated Reference Properties































}