
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { WatchInvoiceBase } from '../database-models/watch-invoice-base';

//Generated Imports
export class WatchInvoice extends WatchInvoiceBase
{

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;

    watchInvoices: Array<WatchInvoice>;


//#region Generated Reference Properties
//#region watchPart Prop
watchPart : WatchInvoiceBase;
//#endregion watchPart Prop


//#endregion Generated Reference Properties

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;



}