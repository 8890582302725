import {ProductVariantStatusBase,} from '@app/database-models'
//Generated Imports
export class ProductVariantStatus extends ProductVariantStatusBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}