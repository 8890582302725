import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDutyStatusLookUpBase {

//#region dutyStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'dutyStatusId', keyColumn: true})
        dutyStatusId : number;
//#endregion dutyStatusId Prop


//#region dutyStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'dutyStatusName', keyColumn: false})
        dutyStatusName : string;
//#endregion dutyStatusName Prop

}