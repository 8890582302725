import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { HolidayList, vCountryLookUp } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { vHolidayTypeLookUp } from 'src/app/models/extended-models/v-holiday-type-look-up';

@http({
  path: "api/HolidayLists",
})
export class AbstractBankHoliday extends CoreComponent {

  holidayListFormGroup: IFormGroup<HolidayList>
  showComponent: boolean = false;
  spin: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  domainType: number;
  modalView: ModalView;
  countryIds: string[];
  holidayLookups: any = {
    countryLookUp: vCountryLookUp,
    holidayList: HolidayList,
    holidayTypeLookUp: vHolidayTypeLookUp
  }
  validField: string[] = ["holidayName","holidayYear","countryIds"];
  breakLoop: boolean;
  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }
  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }


}
