import { TemplateConfig } from '@rxweb/grid';

  export const SELECT_BIND: TemplateConfig = {
    select:
    {
      class: ["checkbox-custom-style","form-control"],
      event: {
        input: "onGridSelect"
      },
      style:{
        "margin-top":"-6px",
        "width":"auto"
      },
      attributes: {
        value: function (e) {
          return this.value;
        }
      },
      childrens: [
        {
          option: {
            attributes: {
              value: function (e) {
                return this.value;
              },
              selected: function (e) {
              // return this.parentObject.Invited && this.value==1
              var gridValue=0;
                if(this.parentObject.accepted==true){
                  
                 return this.parentObject.accepted && this.value==4
                }
                else if(this.parentObject.invited==true){
                  return this.parentObject.invited && this.value==1
                }
                else if(this.parentObject.attended==true){
                  return this.parentObject.attended && this.value==2
                }
                else if(this.parentObject.declined==true){
                  return this.parentObject.declined && this.value==3
                }
            }
            },
            
            childrens: [{
              text: {
                text: function (e) { return this.text }
              }
            }]
          }
        }
      ],
      sourceItems: DAta(),
    }
  }

             

  
  export function DAta() {
    return [{ value: 0, text: "Select Value" },{value:4,text:"Accepted"}, { value: 2, text: "Attended" },{value:3,text:"Declined"},{ value: 1, text: "Invited" }]
  }


  export const EXPAND: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style"],
                  event: {
                    input: "onExpand"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    },
                    checked: function (e) {
                      // var a = this[e.name];
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    }
  }
  