import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLegalOwnerBase {

//#region legalOwnerId Prop
@gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'LegalOwnerListComponent_Id_gh', keyColumn: true })
legalOwnerId : number;
//#endregion legalOwnerId Prop

//#region legalOwnerName Prop
@gridColumn({ isAscending:true, style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'LegalOwnerListComponent_LegalOwner_gh',keyColumn: false })
legalOwnerName : string;
//#endregion legalOwnerName Prop

}