import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ClientRepair } from '@app/models';
import { CoreComponent } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileSizeEnums, FileTypeEnums } from 'src/app/custom-enum/file-type-enums';
import { FileComponent } from 'src/app/components/shared/global/file/file.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { vCustomerPieceLookup } from 'src/app/view-model/vCustomerPieceLookup';
@http({

    path: "api/ClientRepairs"
  })
export class AbstractClientRepair extends CoreComponent {

    clientRepairFormGroup: IFormGroup<ClientRepair>
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    formArray:number[];
    id:number;
    showComponent:boolean;
    fileInputViewModel1: FileInputViewModel;
    fileInputViewModel2: FileInputViewModel;
    fileInputViewModel3: FileInputViewModel;
    fileComponent:FileComponent;
    index:number=0;
    sanitizer:DomSanitizer;
    pieceLookup:vCustomerPieceLookup[];
    spin:boolean;
    fileURL1:any;
    fileURL2:any;
    fileURL3:any;
    oldPieceError:boolean;
    pieceIdError:boolean;
    disabelPieces:boolean;    
    showImageURL1:boolean;
    showImageURL2:boolean;
    showImageURL3:boolean;
    constructor(private domsanitizer:DomSanitizer) {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.sanitizer=domsanitizer;
        
      }






uploadFiles($event: FileOutputViewModel,i:number)
  {
    this.fileComponent=new FileComponent(this.toastr,this.sanitizer);
    if(i==1){
      this.clientRepairFormGroup.patchValue({
        fileData: $event.fileBaseString,
        fileExtention: $event.fileExtension,
        fileName: $event.fileName,
        displayImage: $event.displayImage
      });
      this.fileURL1=null;
      if(this.clientRepairFormGroup.controls.fileExtention.value == 'jpg'  || this.clientRepairFormGroup.controls.fileExtention.value == 'jpeg' || this.clientRepairFormGroup.controls.fileExtention.value == 'png')
      {
        this.showImageURL1=true;
      }
      else
      {
        this.showImageURL1=false;
      }
      
    }
    else if(i==2){
      this.clientRepairFormGroup.patchValue({
        fileData1: $event.fileBaseString,
        fileExtention1: $event.fileExtension,
        fileName1: $event.fileName,
        displayImage: $event.displayImage
      });
      this.fileURL2=null;
      if(this.clientRepairFormGroup.controls.fileExtention1.value == 'jpg'  || this.clientRepairFormGroup.controls.fileExtention1.value == 'jpeg' || this.clientRepairFormGroup.controls.fileExtention1.value == 'png')
      {
        this.showImageURL2=true;
      }
      else
      {
        this.showImageURL2=false;
      }
    }
    else{
      this.clientRepairFormGroup.patchValue({
        fileData2: $event.fileBaseString,
        fileExtention2: $event.fileExtension,
        fileName2: $event.fileName,
        displayImage: $event.displayImage
      });
      this.fileURL3=null;     
     if(this.clientRepairFormGroup.controls.fileExtention2.value == 'jpg'  || this.clientRepairFormGroup.controls.fileExtention2.value == 'jpeg' || this.clientRepairFormGroup.controls.fileExtention2.value == 'png')
     {
      this.showImageURL3=true;
    }
    else
    {
      this.showImageURL3=false;
    }
    }
   
  }

  getImgContent(i:number): SafeUrl {    
    if(i==1)
    {
    if(this.clientRepairFormGroup.controls.fileExtention.value == 'jpg' || this.clientRepairFormGroup.controls.fileExtention.value == 'jpeg' || this.clientRepairFormGroup.controls.fileExtention.value == 'png' )
    {
    let imgUrl="data:"+this.clientRepairFormGroup.controls.fileExtention.value+";base64,"+this.clientRepairFormGroup.controls.fileData.value;    
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
      }   
     
    }
    else if(i==2)
    {
      if(this.clientRepairFormGroup.controls.fileExtention1.value == 'jpg' || this.clientRepairFormGroup.controls.fileExtention1.value == 'jpeg' || this.clientRepairFormGroup.controls.fileExtention1.value == 'png' )
      {
       
    let imgUrl="data:"+this.clientRepairFormGroup.controls.fileExtention1.value+";base64,"+this.clientRepairFormGroup.controls.fileData1.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
    }
   
  } 
    else
    {
      if(this.clientRepairFormGroup.controls.fileExtention2.value == 'jpg' || this.clientRepairFormGroup.controls.fileExtention1.value == 'jpeg' || this.clientRepairFormGroup.controls.fileExtention2.value == 'png' )
      {
        
    let imgUrl="data:"+this.clientRepairFormGroup.controls.fileExtention2.value+";base64,"+this.clientRepairFormGroup.controls.fileData2.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
    }
  
  }

}
}
