import {vEventTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vEventTypeLookUp extends vEventTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}