import { TemplateConfig } from '@rxweb/grid';

export const REPORT_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.name != "Total" ? true : false;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
        label: {
          isBind: (x) => {
            return x.name == "Total" ? true : false;
          },
          class: "mb-0 font-weight-bold".split(" "),
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ],
    class: "text-left".split(" ")
  },
};

export const REPORT_NOSALEMADE_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoSalesMadeClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const REPORT_NOCLIENTADDED_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoClientAddedClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const REPORT_NOCLIENTSSALESADVISORADDED_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoClientsSalesAdvisorAddedClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const REPORT_NOCLIENTINTRODUCEDBY_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoClientsIntroducedByClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const REPORT_NOCLIENTSNOTESADDED_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoClientsNotesAddedClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const REPORT_NOEVENTSNOTESADDED_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoEventsNotesAddedClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const REPORT_NOINVENTORYNOTESADDED_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNoInventoryNotesAddedClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
      }
    ]
  },
};

export const GINA_REPORT_DETAIL_LTI_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.company == "Total" ? false : true;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
        span: {
          isBind: (x) => {
            return x.company == "Total" ? true : false;
          },
          style: { "word-break": "break-all;", "font-weight": "bold" },
          attributes: {
            innerHTML: function (x, y) {
              return this.lostTimeInjuries;
            }
          },
        }
      }
    ]
  },
};

export const GINA_REPORT_DETAIL_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.company == "Total" ? false : true;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onFatalityClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
        span: {
          isBind: (x) => {
            return x.company == "Total" ? true : false;
          },
          style: { "word-break": "break-all;", "font-weight": "bold" },
          attributes: {
            innerHTML: function (x, y) {
              return this.fatalities;
            }
          },
        }
      }
    ]
  },
};

export const GINA_REPORT_DETAIL_NEARMISS_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.company == "Total" ? false : true;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onNearMissClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
        span: {
          isBind: (x) => {
            return x.company == "Total" ? true : false;
          },
          style: { "word-break": "break-all;", "font-weight": "bold" },
          attributes: {
            innerHTML: function (x, y) {
              return this.nearMisses;
            }
          },
        }
      }
    ]
  },
};


export const GINA_REPORT_DETAIL_TOTALFORMS_REDIRECT_MODAL: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.company == "Total" ? false : true;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onTotalFormsClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        },
        span: {
          isBind: (x) => {
            return x.company == "Total" ? true : false;
          },
          style: { "word-break": "break-all;", "font-weight": "bold" },
          attributes: {
            innerHTML: function (x, y) {
              return this.totalIANMs;
            }
          },
        }
      }
    ]
  },
};