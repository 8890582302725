import { prop,propObject,propArray,required,maxLength,range, notEmpty  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SEORedirectionBase {

//#region id Prop
        // @range({minimumNumber:1,maximumNumber:2147483647})
        // @required()
        @prop()
        id : number;
//#endregion id Prop


//#region newURL Prop
        @required()
        @notEmpty()
        newURL : string;
//#endregion newURL Prop


//#region oldURL Prop
        @required()
        @notEmpty()
        oldURL : string;
//#endregion oldURL Prop


//#region isActive Prop
        @required()
        isActive : boolean;
//#endregion isActive Prop

}