<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="ProjectEditComponent_Edit_Title_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/projects']" rxText="Label_Projects_t"></a>
      </li>
      <li class="breadcrumb-item active">Edit Project</li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" [rxSpinner]="spin" [rxLocalisationInit]="componentName"
  *ngIf="allProjectList !== undefined">
  <div class="ibox mb-0 mt-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5>
        <a class="font-weight-bold" [routerLink]="['/projects', allProjectList[0].projectId]"
          [queryParams]="{ activeTab: 'project-edit' }">{{ allProjectList[0].projectName }}
        </a>
        <label class="mb-0" rxText="ProjectEditComponent_Main_Project_t"></label>
          <span *ngIf="id == allProjectList[0].projectId" [rxLocalisationInit]="componentName"><label
              class="fa fa-arrow-left fa-2x mb-0" aria-hidden="true" rxText="ProjectEditComponent_You_Are_Here_t">
            </label></span>
      </h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showTab"
      [rxLocalisationInit]="componentName">
      <label class="col-md-4 col-xl-4 col-form-label px-0"
        rxText="ProjectEditComponent_Sub_Project_Workstream_t"></label>
      <ul class="col-md-12 col-xl-12 col-form-label px-0">
        <li *ngFor="
            let subProjectsLevel1 of allProjectList[0].subProjects;
            index as i
          ">
          <div class="project-container">
            <span *ngIf="
                        subProjectsLevel1.projectId > 0 && subProjectsLevel1.linkId == 0
                      ">{{ i + 1 }}.
            </span>
            <a class="font-weight-bold project-name ml-1" *ngIf="
                          subProjectsLevel1.projectId > 0 && subProjectsLevel1.linkId == 0
                        " [routerLink]="['/projects', subProjectsLevel1.projectId]" [rxLocalisationInit]="componentName"
              [queryParams]="{ activeTab: 'project-edit' }">{{ subProjectsLevel1.projectName }}
              </a>
              <div *ngIf="subProjectsLevel1.projectIndicator == 100" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-xl-4 mb-0 px-0 ml-2 mr-2 font-weight-bold text-dark" rxText="ProjectEditComponent_Project_Completed_t"></label>
              </div>
              <div *ngIf="subProjectsLevel1.projectIndicator != 100 && subProjectsLevel1.projectId != 0" class="text-center d-flex align-items-center ml-2 w-150 div-td">
                <div class="progress progress progress-mini w-100 mt-0 mr-1">
                  <div *ngIf="subProjectsLevel1.projectIndicator == 0" class="progress-bar">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 0 && subProjectsLevel1.projectIndicator <= 30"
                    class="progress-bar progress-bar-danger progress-width-danger">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 30 && subProjectsLevel1.projectIndicator <= 60"
                    class="progress-bar progress-bar-warning progress-width-warning">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 60 && subProjectsLevel1.projectIndicator <= 90"
                    class="progress-bar progress-bar-success progress-width-success">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 90 && subProjectsLevel1.projectIndicator <= 99"
                    class="progress-bar progress-bar-full">
                  </div>
                </div>
                <div class="font-weight-bold text-dark progress-bar-result text-center">
                  {{subProjectsLevel1.projectIndicator}}%
                </div>
              </div>

              <span *ngIf="id == subProjectsLevel1.projectId" [rxLocalisationInit]="componentName"><label class="fa fa-arrow-left fa-2x mb-0" aria-hidden="true"
                rxText="ProjectEditComponent_You_Are_Here_t">
              </label></span>
          </div>
          <div class="project-container">
            <span *ngIf="
                          subProjectsLevel1.projectId == 0 && subProjectsLevel1.linkId > 0
                        ">{{ i + 1 }}.
            </span>
            <a class="font-weight-bold project-name ml-1" *ngIf="
                          subProjectsLevel1.projectId == 0 && subProjectsLevel1.linkId > 0
                        " [routerLink]="['/product-design', subProjectsLevel1.linkId]"
              [queryParams]="{ activeTab: 'design-info' }">{{ subProjectsLevel1.projectName }}
              <div *ngIf="subProjectsLevel1.projectIndicator == 100" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-xl-4 mb-0 px-0 ml-2 font-weight-bold text-dark" [rxLocalisationInit]="componentName" rxText="ProjectEditComponent_Project_Completed_t"></label>
              </div>
              <div *ngIf="subProjectsLevel1.projectIndicator != 100 && subProjectsLevel1.projectId != 0 "
                class="text-center d-flex align-items-center ml-2 w-150 div-td">
                <div class="progress progress progress-mini w-100 mt-0 mr-1">
                  <div *ngIf="subProjectsLevel1.projectIndicator == 0" class="progress-bar">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 0 && subProjectsLevel1.projectIndicator <= 30"
                    class="progress-bar progress-bar-danger progress-width-danger">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 30 && subProjectsLevel1.projectIndicator <= 60"
                    class="progress-bar progress-bar-warning progress-width-warning">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 60 && subProjectsLevel1.projectIndicator <= 90"
                    class="progress-bar progress-bar-success progress-width-success">
                  </div>
                  <div *ngIf="subProjectsLevel1.projectIndicator > 90 && subProjectsLevel1.projectIndicator <= 99"
                    class="progress-bar progress-bar-full">
                  </div>
                </div>
                <div class="font-weight-bold text-dark progress-bar-result text-center">
                  {{subProjectsLevel1.projectIndicator}}%
                </div>
              </div>
              <span *ngIf="id == subProjectsLevel1.linkId" [rxLocalisationInit]="componentName"><i class="fa fa-arrow-left fa-2x"
                  aria-hidden="true" rxText="ProjectEditComponent_You_Are_Here_t">
                </i></span>
            </a>
          </div>
          <ul>
            <li *ngFor="
                let subProjectsLevel2 of subProjectsLevel1.subProjects;
                index as j
              ">
              <div class="project-container">
                <span *ngIf="
                                  subProjectsLevel2.projectId > 0 &&
                                  subProjectsLevel2.linkId == 0
                                ">{{ i + 1 }}.{{ j + 1 }}.
                </span>
                <a class="font-weight-bold project-name ml-1" *ngIf="
                                  subProjectsLevel2.projectId > 0 &&
                                  subProjectsLevel2.linkId == 0
                                " [routerLink]="['/projects', subProjectsLevel2.projectId]"
                  [queryParams]="{ activeTab: 'project-edit' }">{{ subProjectsLevel2.projectName }}
                </a>
                  <div *ngIf="subProjectsLevel2.projectIndicator == 100" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 mb-0 px-0 ml-2 mr-2 font-weight-bold text-dark" [rxLocalisationInit]="componentName" rxText="ProjectEditComponent_Project_Completed_t"></label>
                  </div>
                  <div *ngIf="subProjectsLevel2.projectIndicator != 100 && subProjectsLevel2.projectId != 0" class="text-center d-flex align-items-center ml-2 w-150 div-td">
                    <div class="progress progress progress-mini w-100 mt-0 mr-1">
                      <div *ngIf="subProjectsLevel2.projectIndicator == 0" class="progress-bar">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 0 && subProjectsLevel2.projectIndicator <= 30"
                        class="progress-bar progress-bar-danger progress-width-danger">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 30 && subProjectsLevel2.projectIndicator <= 60"
                        class="progress-bar progress-bar-warning progress-width-warning">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 60 && subProjectsLevel2.projectIndicator <= 90"
                        class="progress-bar progress-bar-success progress-width-success">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 90 && subProjectsLevel2.projectIndicator <= 99"
                        class="progress-bar progress-bar-full">
                      </div>
                    </div>
                    <div class="font-weight-bold text-dark progress-bar-result text-center">
                      {{subProjectsLevel2.projectIndicator}}%
                    </div>
                  </div>
                  <span *ngIf="id == subProjectsLevel2.projectId" [rxLocalisationInit]="componentName"><label class="fa fa-arrow-left fa-2x mb-0"
                      aria-hidden="true" rxText="ProjectEditComponent_You_Are_Here_t">
                    </label></span>
              </div>
              <div class="project-container">
                <span *ngIf="subProjectsLevel2.projectId == 0 && subProjectsLevel2.linkId > 0">
                  {{ i + 1 }}.{{ j + 1 }}.
                </span>
                <a class="font-weight-bold project-name ml-1" *ngIf="
                                  subProjectsLevel2.projectId == 0 &&
                                  subProjectsLevel2.linkId > 0
                                " [routerLink]="['/product-design', subProjectsLevel2.linkId]"
                  [queryParams]="{ activeTab: 'design-info' }">{{ subProjectsLevel2.projectName }}
                  <div *ngIf="subProjectsLevel2.projectIndicator == 100" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 mb-0 px-0 ml-2 font-weight-bold text-dark" [rxLocalisationInit]="componentName" rxText="ProjectEditComponent_Project_Completed_t"></label>
                  </div>
                  <div *ngIf="subProjectsLevel2.projectIndicator != 100 && subProjectsLevel2.projectId != 0" class="text-center d-flex align-items-center ml-2 w-150 div-td">
                    <div class="progress progress progress-mini w-100 mt-0 mr-1">
                      <div *ngIf="subProjectsLevel2.projectIndicator == 0" class="progress-bar">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 0 && subProjectsLevel2.projectIndicator <= 30"
                        class="progress-bar progress-bar-danger progress-width-danger">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 30 && subProjectsLevel2.projectIndicator <= 60"
                        class="progress-bar progress-bar-warning progress-width-warning">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 60 && subProjectsLevel2.projectIndicator <= 90"
                        class="progress-bar progress-bar-success progress-width-success">
                      </div>
                      <div *ngIf="subProjectsLevel2.projectIndicator > 90 && subProjectsLevel2.projectIndicator <= 99"
                        class="progress-bar progress-bar-full">
                      </div>
                    </div>
                    <div class="font-weight-bold text-dark progress-bar-result text-center">
                      {{subProjectsLevel2.projectIndicator}}%
                    </div>
                  </div>
                  <span *ngIf="id == subProjectsLevel2.linkId" [rxLocalisationInit]="componentName"><i class="fa fa-arrow-left fa-2x"
                      aria-hidden="true" rxText="ProjectEditComponent_You_Are_Here_t">
                    </i></span></a>
              </div>
              <ul>
                <li *ngFor="
                    let subProjectsLevel3 of subProjectsLevel2.subProjects;
                    index as k
                  ">
                  <div class="project-container">
                    <span *ngIf="
                                          subProjectsLevel3.projectId > 0 &&
                                          subProjectsLevel3.linkId == 0
                                        ">{{ i + 1 }}.{{ j + 1 }}.{{ k + 1 }}.
                    </span>
                    <a class="font-weight-bold project-name ml-1" *ngIf="
                                          subProjectsLevel3.projectId > 0 &&
                                          subProjectsLevel3.linkId == 0
                                        " [routerLink]="['/projects', subProjectsLevel3.projectId]"
                      [queryParams]="{ activeTab: 'project-edit' }">{{ subProjectsLevel3.projectName }}
                    </a>
                      <div *ngIf="subProjectsLevel3.projectIndicator == 100" [rxLocalisationInit]="componentName">
                        <label class="col-md-4 col-xl-4 mb-0 px-0 ml-2 mr-2 font-weight-bold text-dark" [rxLocalisationInit]="componentName" rxText="ProjectEditComponent_Project_Completed_t"></label>
                      </div>
                      <div *ngIf="subProjectsLevel3.projectIndicator != 100 && subProjectsLevel3.projectId != 0" class="text-center d-flex align-items-center ml-2 w-150 div-td">
                        <div class="progress progress-mini w-100 mt-0 mr-1">
                          <div *ngIf="subProjectsLevel3.projectIndicator == 0" class="progress-bar">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 0 && subProjectsLevel3.projectIndicator <= 30"
                            class="progress-bar progress-bar-danger progress-width-danger">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 30 && subProjectsLevel3.projectIndicator <= 60"
                            class="progress-bar progress-bar-warning progress-width-warning">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 60 && subProjectsLevel3.projectIndicator <= 90"
                            class="progress-bar progress-bar-success progress-width-success">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 90 && subProjectsLevel3.projectIndicator <= 99"
                            class="progress-bar progress-bar-full">
                          </div>
                        </div>
                        <div class="font-weight-bold text-dark progress-bar-result text-center">
                          {{subProjectsLevel3.projectIndicator}}%
                        </div>
                      </div>
                      <span *ngIf="id == subProjectsLevel3.projectId" [rxLocalisationInit]="componentName"><label class="fa fa-arrow-left fa-2x mb-0"
                          aria-hidden="true" rxText="ProjectEditComponent_You_Are_Here_t">
                      </label></span>
                  </div>
                  <div class="project-container">
                      <span *ngIf="
                                          subProjectsLevel3.projectId == 0 &&
                                          subProjectsLevel3.linkId > 0
                                        ">{{ i + 1 }}.{{ j + 1 }}.{{ k + 1 }}.
                    </span>
                    <a class="font-weight-bold" *ngIf="
                                          subProjectsLevel3.projectId == 0 &&
                                          subProjectsLevel3.linkId > 0
                                        " [routerLink]="['/product-design', subProjectsLevel3.linkId]"
                      [queryParams]="{ activeTab: 'design-info' }">{{ subProjectsLevel3.projectName }}
                      <div *ngIf="subProjectsLevel3.projectIndicator == 100" [rxLocalisationInit]="componentName">
                        <label class="col-md-4 col-xl-4 mb-0 px-0 ml-2 font-weight-bold text-dark" [rxLocalisationInit]="componentName" rxText="ProjectEditComponent_Project_Completed_t"></label>
                      </div>
                      <div *ngIf="subProjectsLevel3.projectIndicator != 100 && subProjectsLevel3.projectId != 0" class="text-center d-flex align-items-center ml-2 w-150 div-td">
                        <div class="progress progress progress-mini w-100 mt-0 mr-1">
                          <div *ngIf="subProjectsLevel3.projectIndicator == 0" class="progress-bar">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 0 && subProjectsLevel3.projectIndicator <= 30"
                            class="progress-bar progress-bar-danger progress-width-danger">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 30 && subProjectsLevel3.projectIndicator <= 60"
                            class="progress-bar progress-bar-warning progress-width-warning">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 60 && subProjectsLevel3.projectIndicator <= 90"
                            class="progress-bar progress-bar-success progress-width-success">
                          </div>
                          <div *ngIf="subProjectsLevel3.projectIndicator > 90 && subProjectsLevel3.projectIndicator <= 99"
                            class="progress-bar progress-bar-full">
                          </div>
                        </div>
                        <div class="font-weight-bold text-dark progress-bar-result text-center">
                          {{subProjectsLevel3.projectIndicator}}%
                        </div>
                      </div>
                      <span *ngIf="id == subProjectsLevel3.linkId" [rxLocalisationInit]="componentName"><i class="fa fa-arrow-left fa-2x"
                          aria-hidden="true" rxText="ProjectEditComponent_You_Are_Here_t">
                        </i></span></a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!-- <label class="col-form-label px-0"
        >Note: Visit the "Sub-Project/Workstream" tab in the Main Project above
        to see, for all sub-projects combined, the most recent updates. Also see
        the Main Project's Gannt chart tab for the combined timetable.</label
      > -->
      <span class="text" [innerHTML]="transformYourHtml(noticeData)"></span>
    </div>
  </div>
  
  <div class="ibox mb-0 mt-4 d-flex flex-column">
    <div class="ibox-title bg-white pr-3" id="scrollToDiv" >
      <h5>
        <span rxText="ProjectEditComponent_Title_t"></span>{{ projectName }}
        <ng-container *ngIf="isRestrictedProject" [rxLocalisationInit]="componentName">
          <span class="text-danger" rxText="ProjectEditComponent_Restricted_Project_t"></span>
        </ng-container>
        <ng-container *ngIf="isSubProject" [rxLocalisationInit]="componentName">
          <a (click)="backToProject()" rxText="ProjectEditComponent_Back_To_Parent_t"></a><a
            (click)="backToProjectList()" rxText="ProjectEditComponent_Back_To_Project_List_t"></a>
        </ng-container>

        <span *tabPermission="'mind-map'" style="border-left: 1px solid #e7eaec; margin: 0px 5px;"></span>
        <a *tabPermission="'mind-map'" (click)="activeTab('mind-map')" [title]="titleMindMap"><i class="fa fa-code-fork mx-1"
            style="font-size: 1.5em;line-height: 0.75em;vertical-align: -15%;"></i><span
            style="font-size: 12px">{{labelMindMap}}</span></a>
      </h5>
      <button data-toggle="modal" class="btn btn-success float-right" rxText="ProjectEditComponent_MoveProject_t"
            data-target="#change-parent-modal" data-backdrop="static"></button>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showTab"
      [rxLocalisationInit]="componentName">
      <div class="tabs-container people-tabs">
        <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'project-edit'"
              (click)="activeTab('project-edit')">
              <label class="mb-0" rxText="ProjectAddEditComponent_ProjectInfo_t"></label></a>
          </li>
          <li *tabPermission="'people,corporate'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'person-corporate-involved'"
              (click)="activeTab('person-corporate-involved')">
              <label class="mb-0" rxText="ProjectAddEditComponent_PerCorpInvolved_t"></label></a>
          </li>
          <li *tabPermission="'people,corporate'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'project-restrict-access'"
              (click)="activeTab('project-restrict-access')">
              <label class="mb-0" rxText="ProjectAddEditComponent_RestrictedAccess_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'pictures'"
              (click)="activeTab('pictures')">
              <label class="mb-0" rxText="ProjectAddEditComponent_PictureVideo_t"></label></a>
          </li>
          <li *tabPermission="'event'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'events'"
              (click)="activeTab('events')">
              <label class="mb-0" rxText="ProjectAddEditComponent_Events_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'subproject-workstream'"
              *ngIf="isshowSubprojecttab" [rxLocalisationInit]="componentName" (click)="activeTab('subproject-workstream')"
              [rxLocalisationInit]="componentName">
              <label class="mb-0" rxText="ProjectAddEditComponent_SubProWorkstream_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'gantt-chart'"
              (click)="activeTab('gantt-chart')">
              <label class="mb-0" rxText="ProjectAddEditComponent_GanttChart_t"></label></a>
          </li>
          <li *tabPermission="'risk'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'risk'" (click)="activeTab('risk')">
              <label class="mb-0" rxText="ProjectAddEditComponent_Risk_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'travel'"
              (click)="activeTab('travel')">
              <label class="mb-0" rxText="ProjectAddEditComponent_Travel_t"></label></a>
          </li>
          <li *tabPermission="'press-media'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'press-media'"
              (click)="activeTab('press-media')"><label class="mb-0"
                rxText="ProjectAddEditComponent_PressMedia_t"></label></a>
          </li>
          <li *tabPermission="'legal-agreement'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'legal-agreement'"
              (click)="activeTab('legal-agreement')"><label class="mb-0"
                rxText="ProjectAddEditComponent_LegalAgreements_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'orders'"
              (click)="activeTab('orders')">
              <label class="mb-0" rxText="ProjectAddEditComponent_Orders_t"></label></a>
          </li>
          <li *tabPermission="'mind-map'" [rxLocalisationInit]="componentName">
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'mind-map'"
              (click)="activeTab('mind-map')"><label class="mb-0"
                rxText="ProjectAddEditComponent_VisualMindMap_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'tasks'" (click)="activeTab('tasks')">
              <label class="mb-0" rxText="ProjectEditComponent_Tasks_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'notes'" (click)="activeTab('notes')">
              <label class="mb-0" rxText="ProjectAddEditComponent_Notes_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'document-files'"
              (click)="activeTab('document-files')">
              <label class="mb-0" rxText="ProjectAddEditComponent_DocumentsFiles_t"></label></a>
          </li>

        </ul>
        <div class="slick_demo-btn"></div>
        <div class="tab-content">
          <div role="tabpanel" *ngIf="selectedTab == 'project-edit'" class="tab-pane"
            [class.active]="selectedTab == 'project-edit'">
            <div class="panel-body" [formGroup]="projectFormGroup" *ngIf="showComponent"
              [rxLocalisationInit]="componentName">
              <div class="row">
                <!-- <div class="hr-line-dashed border-top w-100"></div> -->

                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-lg-6 col-xl-4 pr-xl-4">


                      <div class="form-group row">
                        <label class="col-md-1 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectId_t"></label>
                        <div class="col-md-8 mt-2">
                          <div class="col-md-auto d-flex align-items-center">
                            <label class="mb-0" style="font-weight: 600">
                              {{this.id}}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectName_t"></label>
                        <div class="col-md-8">
                          <input type="text" tabindex="4" class="form-control" formControlName="projectName"
                            rxPlaceholder="ProjectAddEditComponent_ProjectName_p" rxFocus />
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.projectName.errors
                            ">{{
                            projectFormGroup.controls.projectName.errorMessage
                            }}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectDescription_t"></label>
                        <div class="col-md-8">
                          <textarea class="form-control" formControlName="projectDescription" tabindex="7"
                            rxPlaceholder="ProjectAddEditComponent_ProjectDescription_p" rows="5"></textarea>
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.projectDescription
                                .errors
                            ">{{
                            projectFormGroup.controls.projectDescription
                            .errorMessage
                            }}</small>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectPicture_t"></label>
                        <div class="col-md-8">
                          <app-file [fileViewModel]="fileViewModel" [tabindex]="'5'" (files)="updateFiles($event)"
                            [title]="imageTooltip">
                          </app-file>
                          <div style="margin-top: 0.5em">
                            <img *ngIf="
                                projectFormGroup.value.displayImage &&
                                projectFormGroup.value.fileData == null
                              " src="{{ projectFormGroup.value.imageURL1 }}" class="upload-img-preview" width="100"
                              height="100" />
                            <img *ngIf="projectFormGroup.value.fileData != null" [src]="getImgContent()"
                              class="event-img upload-img-preview" width="100" height="100" />
                            <ng-container [rxLocalisationInit]="componentName" *ngIf="
                                  projectFormGroup.value.displayImage ||
                                  projectFormGroup.value.fileData != null
                                " (click)="removeImage()">
                              <button class="btn btn-danger ml-2" rxText="Btn_Remove_t" (click)="removeImage()"></button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-6 col-xl-4 px-xl-4">
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectStartDate_t"></label>
                        <div class="col-md-8">
                          <rx-date showAddon="true" formControlName="projectStartDate" [controlTabIndex]="8"
                            placeholder="Select Start Date" pickerClass="form-control">
                          </rx-date>
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.projectStartDate.errors
                            ">{{
                            projectFormGroup.controls.projectStartDate
                            .errorMessage
                            }}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectEndDate_t"></label>
                        <div class="col-md-8">
                          <rx-date showAddon="true" [controlTabIndex]="11" formControlName="projectEndDate"
                            placeholder="Select End Date" pickerClass="form-control">
                          </rx-date>
                          <small class="form-text text-danger" *ngIf="dateError == true">Project End Date should be
                            greater than Project
                            Start Date<br /></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_Budget_t"></label>
                        <div class="col-md-8">
                          <input type="text" class="form-control" formControlName="budget" tabindex="3"
                            rxPlaceholder="ProjectAddEditComponent_ProjectBudget_p" />
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.budget.errors
                            ">{{
                            projectFormGroup.controls.budget.errorMessage
                            }}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ActualCost_t"></label>
                        <div class="col-md-8">
                          <input type="text" class="form-control" formControlName="actualCost" tabindex="6"
                            rxPlaceholder="ProjectAddEditComponent_ProjectActualCost_p" />
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.actualCost.errors
                            ">{{
                            projectFormGroup.controls.actualCost.errorMessage
                            }}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_BudgetCostCertificate_t"></label>
                        <div class="col-md-8">
                          <app-file [fileViewModel]="fileViewModelDoc" (files)="updateDocFiles($event)"
                            [tabindex]="'9'"></app-file>
                          <div style="margin-top: 0.5em">
                            <label *ngIf="projectFormGroup.value.fileName1 != null" class="mt-1">{{
                              projectFormGroup.value.fileName1 }}</label>
                            <label *ngIf="projectFormGroup.value.fileName1 == null" class="mt-1"><a target="_blank"
                                href="{{ projectFormGroup.value.filePath }}">{{
                                projectFormGroup.value.budgetCostCertificate
                                }}</a></label>
                            <ng-container [rxLocalisationInit]="componentName" *ngIf="
                                  projectFormGroup.value.fileName1 != null ||
                                  projectFormGroup.value.filePath != null
                                " (click)="removeDoc()">
                              <button class="btn btn-danger ml-2" rxText="Btn_Remove_t" (click)="removeDoc()">
                              </button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-xl-4 pl-xl-4">
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectIndicator_t"></label>
                        <div class="col-md-8">
                          <input type="text" class="form-control" formControlName="projectIndicator" tabindex="12"
                            (keyup)="chekcValidation()" rxPlaceholder="ProjectAddEditComponent_ProjectProgress_p" />
                          <small class="form-text text-danger" *ngIf="projectIndicatorError == true">You need to enter
                            appropriate value in this
                            field</small>
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.projectIndicator.errors
                            ">{{
                            projectFormGroup.controls.projectIndicator
                            .errorMessage
                            }}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectOwner_t"
                        ></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                          <rx-select
                            [(source)]="projectLookup.projectOwnerLookUp" #rxSelect
                            mainClass="form-control"
                            [controlTabIndex]="2"
                            formControlName="projectOwner"
                            [selectPlaceholder]="'Select Owner'"
                            [keyValueProps]="['fullName', 'userId']"
                          >
                          </rx-select>
                          <small class="form-text text-danger" [class.d-block]="
                              projectFormGroup.controls.projectOwner.errors
                            ">{{
                            projectFormGroup.controls.projectOwner
                            .errorMessage
                            }}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_ProjectType_t"
                        ></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                          <rx-select [(source)]="projectLookup.genfieldsGroupEntity" #rxSelect [controlTabIndex]="9"
                              formControlName="gemfieldsGroupCompanyProjectOwner" mainClass="form-control"
                              [keyValueProps]="['company','supplierId']"
                              [selectPlaceholder]="'Select Gemfields Group Entity'"
                              ></rx-select>

                          <small class="form-text text-danger"
                              [class.d-block]="projectFormGroup.controls.gemfieldsGroupCompanyProjectOwner.errors">{{projectFormGroup.controls.gemfieldsGroupCompanyProjectOwner.errorMessage}}</small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          class="col-md-4 col-xl-4 col-form-label"
                          rxText="ProjectAddEditComponent_Dept_t"
                        ></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                          <rx-select
                            [(source)]="projectLookup.departmentLookUp" #rxSelect
                            mainClass="form-control"
                            [selectPlaceholder]="'Select Department'"
                            formControlName="department"
                            [controlTabIndex]="10"
                            [keyValueProps]="['departmentName', 'departmentId']"
                          >
                          </rx-select>
                          <small class="form-text text-danger"
                            [class.d-block]="projectFormGroup.controls.department.errors">{{projectFormGroup.controls.department.errorMessage}}</small>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="ibox mb-3 d-flex flex-column" *ngIf="displayProjectList">
                                <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                                    <div class="ibox-title bg-white px-0 border-bottom mb-2">
                                        <h5><a class="font-weight-bold"
                                                [routerLink]="['/projects', allProjectList[0].projectId]"
                                                [queryParams]="{ activeTab: 'project-edit' }">{{allProjectList[0].projectName}}
                                                (Main Project)
                                                <span *ngIf="id==allProjectList[0].projectId"><i
                                                        class="fa fa-arrow-left fa-2x" aria-hidden="true"></i></span>
                                                 You Are Here</a></h5>
                                    </div>
                                    <label class="col-md-4 col-xl-4 col-form-label px-0"
                                       >Sub-Project/Workstream</label>
                                    <ul class="col-md-12 col-xl-12 col-form-label px-0">
                                        <li *ngFor="let subProjectsLevel1 of allProjectList[0].subProjects; index as i">
                                            <span
                                                *ngIf="(subProjectsLevel1.projectId > 0 && subProjectsLevel1.linkId == 0)">{{i+1}}.
                                            </span>
                                            <a class="font-weight-bold"
                                                *ngIf="(subProjectsLevel1.projectId > 0 && subProjectsLevel1.linkId == 0)"
                                                [routerLink]="['/projects', subProjectsLevel1.projectId]"
                                                [queryParams]="{ activeTab: 'project-edit' }">{{subProjectsLevel1.projectName}}
                                                <span *ngIf="id==subProjectsLevel1.projectId"><i
                                                        class="fa fa-arrow-left fa-2x" aria-hidden="true">You Are
                                                        Here</i></span></a>
                                            <span
                                                *ngIf="(subProjectsLevel1.projectId == 0 && subProjectsLevel1.linkId > 0)">{{i+1}}.
                                            </span>
                                            <a class="font-weight-bold"
                                                *ngIf="(subProjectsLevel1.projectId == 0 && subProjectsLevel1.linkId > 0)"
                                                [routerLink]="['/product-design', subProjectsLevel1.linkId]"
                                                [queryParams]="{ activeTab: 'design-info' }">{{subProjectsLevel1.projectName}}
                                                <span *ngIf="id==subProjectsLevel1.linkId"><i
                                                        class="fa fa-arrow-left fa-2x" aria-hidden="true">You
                                                        Are Here</i></span></a>
                                            <ul>
                                                <li
                                                    *ngFor="let subProjectsLevel2 of subProjectsLevel1.subProjects; index as j">
                                                    <span
                                                        *ngIf="(subProjectsLevel2.projectId > 0 && subProjectsLevel2.linkId == 0)">{{i+1}}.{{j+1}}.
                                                    </span> <a class="font-weight-bold"
                                                        *ngIf="(subProjectsLevel2.projectId > 0 && subProjectsLevel2.linkId == 0)"
                                                        [routerLink]="['/projects', subProjectsLevel2.projectId]"
                                                        [queryParams]="{ activeTab: 'project-edit' }">{{subProjectsLevel2.projectName}}
                                                        <span *ngIf="id==subProjectsLevel2.projectId"><i
                                                                class="fa fa-arrow-left fa-2x" aria-hidden="true">You
                                                                Are Here</i></span></a>
                                                    <span
                                                        *ngIf="(subProjectsLevel2.projectId == 0 && subProjectsLevel2.linkId > 0)">{{i+1}}.{{j+1}}.
                                                    </span> <a class="font-weight-bold"
                                                        *ngIf="(subProjectsLevel2.projectId == 0 && subProjectsLevel2.linkId > 0)"
                                                        [routerLink]="['/product-design', subProjectsLevel1.linkId]"
                                                        [queryParams]="{ activeTab: 'design-info' }">{{subProjectsLevel2.projectName}}
                                                        <span *ngIf="id==subProjectsLevel2.linkId"><i
                                                                class="fa fa-arrow-left fa-2x" aria-hidden="true">You
                                                                Are Here</i></span></a>
                                                    <ul>
                                                        <li
                                                            *ngFor="let subProjectsLevel3 of subProjectsLevel2.subProjects; index as k">
                                                            <span
                                                                *ngIf="(subProjectsLevel3.projectId > 0 && subProjectsLevel3.linkId == 0)">{{i+1}}.{{j+1}}.{{k+1}}.
                                                            </span> <a class="font-weight-bold"
                                                                *ngIf="(subProjectsLevel3.projectId > 0 && subProjectsLevel3.linkId == 0)"
                                                                [routerLink]="['/projects', subProjectsLevel3.projectId]"
                                                                [queryParams]="{ activeTab: 'project-edit' }">{{subProjectsLevel3.projectName}}
                                                                <span *ngIf="id==subProjectsLevel3.projectId"><i
                                                                        class="fa fa-arrow-left fa-2x"
                                                                        aria-hidden="true"> You Are Here</i></span></a>
                                                            <span
                                                                *ngIf="(subProjectsLevel3.projectId == 0 && subProjectsLevel3.linkId > 0)">{{i+1}}.{{j+1}}.{{k+1}}.
                                                            </span> <a class="font-weight-bold"
                                                                *ngIf="(subProjectsLevel3.projectId == 0 && subProjectsLevel3.linkId > 0)"
                                                                [routerLink]="['/product-design', subProjectsLevel1.linkId]"
                                                                [queryParams]="{ activeTab: 'design-info' }">{{subProjectsLevel3.projectName}}
                                                                <span *ngIf="id==subProjectsLevel3.linkId"><i
                                                                        class="fa fa-arrow-left fa-2x"
                                                                        aria-hidden="true"> You Are Here</i></span></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <label class="col-form-label px-0">Note: Visit the "Sub-Project/Workstream" tab in
                                        the Main Project
                                        above to see, for all sub-projects combined, the most recent updates. Also see
                                        the Main Project's
                                        Gannt chart tab for the combined timetable.</label>
                                </div>
                            </div> -->
              <div class="hr-line-dashed border-top w-100"></div>
              <div class="row mt-auto">
                <div class="col text-right">
                  <button class="btn btn-success mr-1" tabindex="13" (click)="updateProject(true)" rxText="Btn_Save_t">
                  </button>
                  <button class="btn btn-primary mr-1" tabindex="14" (click)="updateProject(false)"
                    rxText="Btn_Save_Continue_Edit_t">
                  </button>
                  <ng-container [rxLocalisationInit]="componentName" *ngIf="projectDelete">
                    <button class="btn btn-danger" tabindex="15" (click)="deleteProject()"
                      rxText="Btn_Delete_t">
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *tabPermission="'people,corporate'">
            <div role="tabpanel" *ngIf="selectedTab == 'person-corporate-involved'" class="tab-pane"
              [class.active]="selectedTab == 'person-corporate-involved'">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xl-12">
                    <app-project-corporate-mapping-list [componentType]="'project'" [selectTab]="selectedTab"
                      [moduleID]="moduleID" [isRestricted]="isRestrictedProject">
                    </app-project-corporate-mapping-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *tabPermission="'people,corporate'">
            <div role="tabpanel" *ngIf="selectedTab == 'project-restrict-access'" class="tab-pane"
              [class.active]="selectedTab == 'project-restrict-access'">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xl-12">
                    <app-project-corporate-mapping-list [componentType]="'project-restrict-access'" [selectedTab]="selectedTab"
                      [moduleID]="moduleID" [isRestricted]="isRestrictedProject">
                    </app-project-corporate-mapping-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" *ngIf="selectedTab == 'pictures'" class="tab-pane"
            [class.active]="selectedTab == 'pictures'">
            <div class="panel-body">
              <div class="row">
                <div class="col-xl-12">
                  <app-document-list [isPicture]="true" [entityTypeId]="entityTypeId" [selectedTab]="selectedTab">
                  </app-document-list>
                </div>
              </div>
            </div>
          </div>
          <div *tabPermission="'event'">
            <div role="tabpanel" *ngIf="selectedTab == 'events'" class="tab-pane"
              [class.active]="selectedTab == 'events'">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xl-12">
                    <app-project-event-list [selectedTab]="selectedTab" [moduleID]="moduleID"></app-project-event-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- task tab -->
          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'tasks'" [class.active]="selectedTab == 'tasks'"
            class="tab-pane">
            <div class="panel-body">
              <div class="row">
                <div class="col-xl-12">
                  <app-task-detail-list [entityTypeId]="entityTypeId" [entityId]="entityId"></app-task-detail-list>
                </div>
              </div>
            </div>
          </div>

          <!-- task end -->
          <div role="tabpanel" *ngIf="selectedTab == 'notes'" class="tab-pane" [class.active]="selectedTab == 'notes'">
            <div class="panel-body">
              <div class="row">
                <div class="col-xl-12">
                  <app-note-list [entityTypeId]="entityTypeId" [entityId]="entityId" [selectedTab]="selectedTab"
                    [moduleID]="moduleID" [eventProjectRestricted]="isRestrictedProject"></app-note-list>
                </div>
              </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
            </div>
          </div>
          <div role="tabpanel" *ngIf="selectedTab == 'document-files'" class="tab-pane"
            [class.active]="selectedTab == 'document-files'">
            <div class="panel-body">
              <div class="row">
                <div class="col-xl-12">
                  <app-document-list [isPicture]="false" [entityTypeId]="entityTypeId" [selectedTab]="selectedTab"
                    [moduleID]="moduleID">
                  </app-document-list>
                </div>
              </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
            </div>
          </div>
          <div *tabPermission="'sales-order'">
            <div role="tabpanel" *ngIf="selectedTab == 'orders'" class="tab-pane"
              [class.active]="selectedTab == 'orders'">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xl-12">
                    <app-corporate-orders-list [isSupplier]="false" [entityTypeId]="entityTypeId" [selectedTab]="selectedTab" [moduleID]="moduleID">
                    </app-corporate-orders-list>
                  </div>
                </div>
                <div class="hr-line-dashed border-top w-100 mb-2"></div>
              </div>
            </div>
          </div>
          <div role="tabpanel" *ngIf="selectedTab == 'subproject-workstream'" class="tab-pane"
            [class.active]="selectedTab == 'subproject-workstream'">
            <div class="panel-body">
              <div class="row">
                <div class="col-xl-12">
                  <app-sub-project-list (projectIdChange)="onProjectIdChange($event)" [selectedTab]="selectedTab"
                    [moduleID]="moduleID">
                  </app-sub-project-list>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" *ngIf="selectedTab == 'gantt-chart'" class="tab-pane"
            [class.active]="selectedTab == 'gantt-chart'">
            <div class="panel-body">
              <div class="row">
                <div class="col-xl-12">
                  <div class="gantt"></div>
                </div>
              </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
            </div>
          </div>
          <div *tabPermission="'risk'">
            <div role="tabpanel" *ngIf="selectedTab == 'risk'" class="tab-pane" [class.active]="selectedTab == 'risk'">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xl-12">
                    <app-project-risk-list [projectId]="id" [selectedTab]="selectedTab" [moduleID]="moduleID"
                      [entityTypeId]="entityTypeId"></app-project-risk-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *tabPermission="'press-media'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'press-media'"
              [class.active]="selectedTab == 'press-media'" class="tab-pane">
              <div class="panel-body">
                <div class="col-xl-12">
                  <app-press-media-view [moduleId]="entityId" [moduleType]="'Projects'" [selectedTab]="selectedTab"
                    [moduleID]="moduleID">
                  </app-press-media-view>
                </div>
              </div>
            </div>
          </div>
          <div *tabPermission="'legal-agreement'">
            <div role="tabpanel" *ngIf="selectedTab == 'legal-agreement'" class="tab-pane"
              [class.active]="selectedTab == 'legal-agreement'">
              <div class="panel-body">
                <div class="row">
                  <div class="col-xl-12">
                    <app-legal-agreement-project-mapping-list [entityTypeId]="entityTypeId" [selectedTab]="selectedTab"
                      [moduleID]="moduleID">
                    </app-legal-agreement-project-mapping-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'travel'" class="tab-pane"
            [class.active]="selectedTab == 'travel'">
            <div class="panel-body">
              <div class="col-xl-12">
                <app-travel-list [projectId]="id" [selectedTab]="selectedTab" [moduleID]="moduleID"
                  [entityTypeId]="entityTypeId">
                </app-travel-list>
              </div>
            </div>
          </div>

          <div *tabPermission="'mind-map'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'mind-map'" class="tab-pane"
              [class.active]="selectedTab == 'mind-map'">
              <div class="panel-body">
                <app-mind-map-detail [entityID]="id" [entityName]="projectName" [moduleID]="moduleID"
                  [selectedTab]="selectedTab" [imageUrl]="ProfileImageTag">
                </app-mind-map-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="change-parent-modal" class="modal " tabindex="-1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title mb-0" rxText="ProjectEditComponent_MoveProject_t"></h4>

            <button type="button" class="close py-2"  data-dismiss="modal" [title]="titleClose" (click)="checkProjectNameText(true)">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>

        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
            rxText="ProjectEditComponent_ParentProject_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this" *ngIf="showParentProject">
                <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1"
                [valueInQueryParams]="true"
                [keyValueProps]="['#ProjectName#','ProjectId']" [selectPlaceholder]="'Select Parent Project'" path="api/SearchProject/SearchParentProjectName"
                (keyup)="checkProjectNameText()" (changed)="onProjectNameClick($event)"
                [text]="searchProjectName" [minimumCharacterSearchLength]="1" >
                </rx-select>
                <ng-container *ngIf="!isValidParent" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="ProjectEditComponent_ValidParentErr_t"></small>
                </ng-container>
              </div>
          </div>
          <ng-container *ngIf="isValidParentErr" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label pl-0" rxText="ProjectEditComponent_MoveProject_Note_t"></label>
            <ul class="ml-3 list-unstyled">
              <li type="square" *ngFor="let item of validParentErrorList"> {{item}}</li>
            </ul>

          </ng-container>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success"
              rxText="ProjectEditComponent_UpdateButton_t" (click)="moveproject()"></button>
          <button data-dismiss="modal" class="btn btn-outline-primary ml-0"
               id="change-parent-modal-close-btn" rxText="ProjectEditComponent_CloseButton_t" (click)="checkProjectNameText(true)"></button>
      </div>
      </div>
    </div>
  </div>
</div>
