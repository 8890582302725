import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractCustomerHoliday } from '../domain/abstract-customer-holiday';
import { CustomerHoliday, vHolidayProcessList } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { vHolidayProcess } from 'src/app/models/extended-models/v-holiday-process';
import { Router, NavigationExtras } from '@angular/router';
import { WeekDaysEnum } from 'src/app/enums/week-days.enum';

import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { CustomerHolidayAddComponent } from '../add/customer-holiday-add.component';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { rolesEnum } from "src/app/enums/roles-enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";
import { CustomerHolidayBulkAddComponent } from "../bulk-add/customer-holiday-bulk-add.component";
import { CustomerRoleTypeEnum } from "src/app/enums/customer-role-type.enum";
@multilingual("CustomerHolidayListComponent")
@access({ accessLevel: RolePermissionEnums.HolidayProcess, action: "get" })

@Component({
  selector: "app-customer-holiday-list",
  templateUrl: './customer-holiday-list.component.html'
})
export class CustomerHolidayListComponent extends AbstractCustomerHoliday implements OnInit, OnDestroy {
  customerHolidays: vHolidayProcess[];
  holidayList: vHolidayProcessList[];
  subscription: any;
  holidayProcessGrid: AppGrid;
  holidayListGrid: AppGrid;
  holidayProcessGridColumns: any[];
  holidayListGridColumns: any[];
  isHolidayList: boolean = false;
  customerHolidayAdd: any = CustomerHolidayAddComponent;
  jsonCustHoliday: any[];
  jsonHolidayList: any[];
  paginationViewModelCustHoliday: PaginationViewModel;
  paginationViewModelHolidayList: PaginationViewModel;
  isFilterCustHoliday: boolean = false;
  isFilterHolidayList: boolean = false;
  totalRecordsHolidayProcessList: number = 0;
  totalRecordsHolidayList: number = 0;
  isNoRecordFoundHolidayProcessList: boolean;
  isNoRecordFoundHolidaList: boolean;
  isShowGridHolidayProcessList: boolean;
  isShowGridHolidayList: boolean;
  firstCallCustHoliday: number = 0;
  firstCallHolidayList: number = 0;
  loggedInUserRole: string;
  showAdButton: boolean = false;
  moduleID: number;
  titleHelp: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;
  constructor(private router: Router, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private browserStorage: BrowserStorage, private location : PlatformLocation) {
    super();
    this.modalView = modalView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.customerHolidays = new Array<vHolidayProcess>();
    this.holidayList = new Array<vHolidayProcessList>();
    this.browserStorage = new BrowserStorage();
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
    this.moduleID = RolePermissionEnums.HolidayProcess;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Holiday Process - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.paginationViewModelCustHoliday = new PaginationViewModel();
    this.paginationViewModelCustHoliday.sortOrder = "true";
    this.paginationViewModelCustHoliday.orderBy = "leaveTypeName";
    this.paginationViewModelHolidayList = new PaginationViewModel();
    this.paginationViewModelHolidayList.sortOrder = "true";
    this.paginationViewModelHolidayList.orderBy = "leaveTypeName";
    this.jsonCustHoliday = JSON.parse(JSON.stringify(this.paginationViewModelCustHoliday));
    this.jsonHolidayList = JSON.parse(JSON.stringify(this.paginationViewModelHolidayList));
    if (this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString())
        || this.loggedInUserRole.split(',').includes(rolesEnum.HRCompanySpecific.toString())
        || this.loggedInUserRole.split(',').includes(rolesEnum.HRGroupwide.toString())
        || this.loggedInUserRole.split(',').includes(rolesEnum.Senior.toString())
        || this.loggedInUserRole.split(',').includes(rolesEnum.Advanced.toString())) {
      this.showAdButton = true;
      this.bindcustomerHolidaysGrid();
    }
    this.bindholidayListGrid();
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  bindcustomerHolidaysGrid() {
    this.spin = true;
    this.subscription = this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(this.jsonCustHoliday)) } }).subscribe((t: any) => {
      this.spin = false;
      var holidayProcess = t;
      this.customerHolidays = holidayProcess[0].holidayList;
      if (this.holidayProcessGrid) {
        this.holidayProcessGrid.storeProcedure.length = 0;
        this.isFilterCustHoliday = true;
      } else {
        this.isFilterCustHoliday = false;
      }
      if (this.customerHolidays.length > 0) {
        this.totalRecordsHolidayProcessList = this.customerHolidays[0].totalCount;
        this.isNoRecordFoundHolidayProcessList = false;
        this.isShowGridHolidayProcessList = true;
      } else {
        this.isNoRecordFoundHolidayProcessList = true;
        this.isShowGridHolidayProcessList = false;
      }
      this.weekOff(this.customerHolidays);
      setTimeout(() => {
        if (!this.isFilterCustHoliday) {
          this.holidayProcessGrid = new AppGrid(this.customerHolidays, vHolidayProcess, { actions: { onEdit: this.onEditHolidayProcessList.bind(this), onDelete: this.onDeleteHolidayProcessList.bind(this) } });
          this.holidayProcessGrid.storeProcedure = {
            length: this.totalRecordsHolidayProcessList,
            onPageChanging: this.onPageChangingHolidayProcessList.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingHolidayProcessList.bind(this)
          }
          this.holidayProcessGrid.gridColumns.forEach(x => {
            if (x.name == "editAction") {
              x.visible = x.visible = this.holidayProcessGrid.authorize({ accessLevel: RolePermissionEnums.HolidayProcess, action: "put" });
            }
            if (x.name == "deleteAction") {
              x.visible = x.visible = this.holidayProcessGrid.authorize({ accessLevel: RolePermissionEnums.HolidayProcess, action: "delete" });
            }
          })
          this.holidayProcessGrid.componentId = this.componentName;
          this.holidayProcessGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.holidayProcessGrid.designClass.headerClass = "table-header-sticky".split(" ");
          setTimeout(() => {
            this.firstCallCustHoliday = 1;
          }, 0);
        }
        else {
          if (this.holidayProcessGrid != null) {
            this.holidayProcessGrid.storeProcedure.length = this.totalRecordsHolidayProcessList;
            if (this.jsonCustHoliday["pageIndex"] == 1) {
              this.holidayProcessGrid.storeProcedure.nextPage = 1;
            }
            this.holidayProcessGrid.updateSource([]);
            this.holidayProcessGrid.updateSource(this.customerHolidays);
          }
        }
        this.holidayProcessGrid.maxPerPage = this.jsonCustHoliday["rowCount"];
        this.holidayProcessGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.holidayProcessGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.holidayProcessGrid.componentId = this.componentName;
        this.showComponent = true;
      }, 0);
    })
  }
  

  onDeleteHolidayProcessList(leaveMasterHolidayProcessViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ path: "api/CustomerHolidays", body: null, params: [leaveMasterHolidayProcessViewModel.id] }).subscribe(data => {
          this.spin = false;
          this.holidayProcessGrid.remove(leaveMasterHolidayProcessViewModel.id);
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
        })
      }
      this.dialog.hide()
    }).then(t => {
      this.ngOnInit();
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Allotted_Holiday");
    if (existsAlert) {
      return existsAlert;
    }
  }


  bindholidayListGrid() {
    this.spin = true;
    this.subscription = this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(this.jsonHolidayList)) } }).subscribe((t: any) => {
      this.spin = false;
      var holidayProcess = t;
      this.holidayList = holidayProcess[0].holidayProcess;
      if (this.holidayListGrid) {
        this.holidayListGrid.storeProcedure.length = 0;
      }
      if (this.holidayList.length > 0) {
        this.totalRecordsHolidayList = this.holidayList[0].totalCount;
        this.isNoRecordFoundHolidaList = false;
        this.isShowGridHolidayList = true;
      } else {
        this.isNoRecordFoundHolidaList = true;
        this.isShowGridHolidayList = false;
      }
      setTimeout(() => {
        if(this.holidayListGrid) {
          this.isFilterHolidayList = true;
        }
        if (!this.isFilterHolidayList) {
          this.holidayListGrid = new AppGrid(this.holidayList, vHolidayProcessList, { actions: { onEdit: this.onEditHolidayList.bind(this) } });
          this.holidayListGrid.storeProcedure = {
            length: this.totalRecordsHolidayList,
            onPageChanging: this.onPageChangingHolidayList.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingHolidayList.bind(this)
          }
          this.holidayListGrid.gridColumns.forEach(x => {
            if (x.name == "action") {
              x.visible = x.visible = this.holidayListGrid.authorize({ accessLevel: RolePermissionEnums.HolidayProcess, action: "put" });
            }
          })
          this.holidayListGrid.componentId = this.componentName;
          this.holidayListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.holidayListGrid.designClass.headerClass = "table-header-sticky".split(" ");
          setTimeout(() => {
            this.firstCallHolidayList = 1;
          }, 0);
        }
        else {
          if (this.holidayListGrid != null) {
            this.holidayListGrid.storeProcedure.length = this.totalRecordsHolidayList;
            if (this.jsonHolidayList["pageIndex"] == 1) {
              this.holidayListGrid.storeProcedure.nextPage = 1;
            }
            this.holidayListGrid.updateSource([]);
            this.holidayListGrid.updateSource(this.holidayList);
          }
        }
        this.holidayListGrid.maxPerPage = this.jsonHolidayList["rowCount"];
        this.holidayListGrid.componentId = this.componentName;
        this.holidayListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.holidayListGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.showComponent = true;
      }, 0);
    })
  }


  onPageChangingHolidayProcessList(x) {
    this.isFilterCustHoliday = true;
    this.jsonCustHoliday["pageIndex"] = x;
    this.jsonCustHoliday["rowCount"] = this.holidayProcessGrid.maxPerPage;
    this.holidayProcessGrid.storeProcedure.nextPage = x;
    this.bindcustomerHolidaysGrid();
  }

  onPageSortingHolidayProcessList(x, y, z) {
    this.isFilterCustHoliday = true;
    this.jsonCustHoliday["sortOrder"] = y;
    this.jsonCustHoliday["orderBy"] = x;
    this.holidayProcessGrid.storeProcedure.nextPage = z;
    this.bindcustomerHolidaysGrid();
  }

  onPageChangingHolidayList(x) {
    this.isFilterHolidayList = true;
    this.jsonHolidayList["pageIndex"] = x;
    this.jsonHolidayList["rowCount"] = this.holidayListGrid.maxPerPage;
    this.holidayListGrid.storeProcedure.nextPage = x;
    this.bindholidayListGrid();
  }

  onPageSortingHolidayList(x, y, z) {
    this.isFilterHolidayList = true;
    this.jsonHolidayList["sortOrder"] = y;
    this.jsonHolidayList["orderBy"] = x;
    this.holidayListGrid.storeProcedure.nextPage = z;
    this.bindholidayListGrid();
  }

  weekOff(data: vHolidayProcess[]) {
    data.forEach(element => {
      var array = element.weekOff.split(',');
      element.weekOff = '';
      this.bindWeekDay(array, WeekDaysEnum.Monday, element, 'Mon');
      this.bindWeekDay(array, WeekDaysEnum.Tuesday, element, 'Tue');
      this.bindWeekDay(array, WeekDaysEnum.Wednesday, element, 'Wed');
      this.bindWeekDay(array, WeekDaysEnum.Thursday, element, 'Thu');
      this.bindWeekDay(array, WeekDaysEnum.Friday, element, 'Fri');
      this.bindWeekDay(array, WeekDaysEnum.Saturday, element, 'Sat');
      this.bindWeekDay(array, WeekDaysEnum.Sunday, element, 'Sun');
    });
  }

  bindWeekDay(array, Weekday, element, day) {
    if (!array.includes(Weekday.toString())) {
      if (element.weekOff == '') {
        element.weekOff = element.weekOff + day;
      } else {
        element.weekOff = element.weekOff + ',' + day;
      }
    }
  }

  onEditHolidayProcessList(holiday: vHolidayProcess) {

    //this.router.navigate(["customer-holidays", holiday.id]);
    let url : any;
    url = this.router.serializeUrl(this.router.createUrlTree(["customer-holidays", holiday.id]));
    redirectOnCTRL(url,this.router,event);
  }

  onEditHolidayList(LeaveDay: vHolidayProcessList) {
    //!this.isHolidayList ? this.isHolidayList = true : this.isHolidayList;
    //let navigationExtras: NavigationExtras = {
    //    queryParams: {
    //        "isHolidayList": this.isHolidayList
    //    }
    //};
    let url : any;
    url = this.router.serializeUrl(this.router.createUrlTree(["leave-masters", LeaveDay.masterId]));
    redirectOnCTRL(url,this.router,event);
    //this.router.navigate(["leave-masters", LeaveDay.masterId]);
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  searchHolidayProcees(value) {
    this.spin = false;
    this.isFilterCustHoliday = true;
    this.totalRecordsHolidayProcessList = 0;
    this.jsonCustHoliday['searchString'] = value.target.value;
    this.jsonCustHoliday['pageIndex'] = 1;
    if (this.holidayProcessGrid != null) {
      this.holidayProcessGrid.storeProcedure.nextPage = 1;
    }
    this.bindcustomerHolidaysGrid();
  }

  searchHolidayList(value) {
    this.spin = false;
    this.isFilterHolidayList = true;
    this.totalRecordsHolidayList = 0;
    this.jsonHolidayList['searchString'] = value.target.value;
    this.jsonHolidayList['pageIndex'] = 1;
    if (this.holidayListGrid != null) {
      this.holidayListGrid.storeProcedure.nextPage = 1;
    }
    this.bindholidayListGrid();
  }

  bulkAddLeaves() {
    this.modalView.show(CustomerHolidayBulkAddComponent, {}).then(t => {});
  }

  get componentName(): string {
    return "CustomerHolidayListComponent";
  }

}
