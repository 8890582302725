import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLanguageRecordBase {

//#region languageId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'languageId', keyColumn: true})
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region languageCulture Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'languageCulture', keyColumn: false})
        languageCulture : string;
//#endregion languageCulture Prop


//#region flagImageFileName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'flagImageFileName', keyColumn: false})
        flagImageFileName : string;
//#endregion flagImageFileName Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}