import { ComponentFactoryResolver, Component, OnInit, OnDestroy, Input, EventEmitter, Output } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractTaxRate } from '../domain/abstract-tax-rate';
import { TaxRate } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { vTaxRate } from '../../../../models/extended-models/v-tax-rate';

import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { TaxRateAddComponent } from '../add/tax-rate-add.component';
import { ApplicationBroadcaster } from '../../../../temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
@multilingual("TaxRateListComponent")

@Component({
    selector: "app-tax-rate-list",
    templateUrl: './tax-rate-list.component.html',
    entryComponents: [TaxRateAddComponent]
})
export class TaxRateListComponent extends AbstractTaxRate implements OnInit, OnDestroy {
    taxRates: vTaxRate[];
    subscription: any;
    @Input() taxProviderId: number;
    taxRateGrid: AppGrid;
    taxRateId: number = 0;
    isRefresh: boolean = false;
    @Output() editTaxRate: EventEmitter<vTaxRate> = new EventEmitter<vTaxRate>();
    isNoRecordFound: boolean = false;
    titleSearch: string;
    constructor(private applicationBroadcaster: ApplicationBroadcaster, private router: Router, modelView: ModalView, componentFactoryResolver: ComponentFactoryResolver, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.modalView.setResolver(componentFactoryResolver);
        //this.applicationBroadcaster.taxRateSubscriber.subscribe(data => {
        //    this.isRefresh = true;
        //    this.ngOnInit();
        //})
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }


    onEdit(taxRate: vTaxRate) {
        this.modalView.show(TaxRateAddComponent, { taxRateId: taxRate.taxRateId }).then(t => {
            this.taxRateGrid.refresh("tax-rate");
            this.bindGrid();
        })
    }


    onAdd() {
        this.modalView.show(TaxRateAddComponent, { taxProviderId: this.taxProviderId }).then(t => {
            this.taxRateGrid.refresh("tax-rate");
            this.bindGrid();
        })
    }

    bindGrid() {
        this.spin = true
        setTimeout(() => {
            this.subscription = this.get().subscribe((t: vTaxRate[]) => {
                this.spin = false
                this.taxRates = t
                this.taxRateGrid = new AppGrid(t, vTaxRate, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.taxRateGrid.componentId = this.componentName;
                this.taxRateGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.taxRateGrid.designClass.headerClass = "table-header-sticky".split(" ");                
                this.taxRateGrid.design(document.getElementById("tax-rate"));
                this.showComponent = true;
            })
        }, 100);
    }

    onDelete(taxCategory: vTaxRate) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [taxCategory.taxRateId] }).subscribe(data => {
                    this.spin = false;
                    this.taxRateGrid.remove(taxCategory.taxRateId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                })
            }
            else {
                this.dialog.hide()
            }
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Tax_Provider_Detail");
        if (existsAlert) {
            return existsAlert;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.taxRates.filter(x => x.countryName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.taxRateId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || (x.stateName == null ? "" : x.stateName).toLowerCase().includes(value.target.value.trim().toLowerCase()) || (x.zip == null ? "" : x.zip).toLowerCase().includes(value.target.value.trim().toLowerCase())
                || (x.percentage == null ? "" : x.percentage).toString().toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.taxCategoryName.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.taxRateGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.taxRateGrid = null;
        }
        setTimeout(() => {
            if (this.taxRateGrid == null || this.taxRateGrid == undefined) {
                this.taxRateGrid = new AppGrid(this.taxRates, vTaxRate, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.taxRateGrid.componentId = this.componentName;
                this.taxRateGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.taxRateGrid.designClass.headerClass = "table-header-sticky".split(" "); 
                this.taxRateGrid.refresh("tax-rate");
                this.taxRateGrid.design(document.getElementById("tax-rate"));
            }
            this.taxRateGrid.search = value.target.value;
        }, 0);
    }
    get componentName(): string {
        return "TaxRateListComponent";
    }
}
