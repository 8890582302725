import {vGemStoneColorGemfieldsRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneColorGemfieldsRecord extends vGemStoneColorGemfieldsRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}