import {ModuleMasterBase,ApplicationModuleBase,} from '@app/database-models'
//Generated Imports
export class ModuleMaster extends ModuleMasterBase 
{




//#region Generated Reference Properties
//#region applicationModules Prop
applicationModules : ApplicationModuleBase[];
//#endregion applicationModules Prop

//#endregion Generated Reference Properties












}