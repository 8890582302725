import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemComponent } from '../shared/master/system/system.component';
import { FabergePriceDetailComponent } from '../inventory/faberge-price/detail/faberge-price-detail.component';
import { StockCardComponent } from '../inventory/product-piece/stock-card/stock-card.component';
import { UnderMaintenanceComponent } from '../shared/under-maintenance/under-maintenance.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AnonymousTokenGenerator } from 'src/app/domain/services/token-activator';
import { BaseCanActivate } from '@rxweb/angular-router';
import { DownloadPageComponent } from '../shared/download-page/download-page.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
//import { UserViewComponent } from '../user/users/view/user-view.component';

const ROUTES: Routes = [
  {
    path: "help-and-training",
    loadChildren: () => import("../help-and-trainning/help-and-trainning/help-and-trainning.module").then(m => m.HelpAndTrainningModule),
    data : { title : "Help & Training" }
  },
  {
    path: "newsletter-subscriber",
    loadChildren: () => import("../user/newsletter-subscriber/newsletter-subscriber.module").then(m => m.NewsletterSubscriberModule),
    data : { title : "Newsletter Subscribers" }
  },
  {
    path: "faberge-customer-call-me",
    loadChildren: () => import("../user/faberge-customer-call-me/faberge-customer-call-me.module").then(m => m.FabergeCustomerCallMeModule),
    data : { title : "Faberge Customer Call Me" }
  },
  {
    path: '', redirectTo: 'dashboard', pathMatch: 'full',
    data : { title : "Dashboard" }
  },

  {
    path: 'login', loadChildren:() => import("../authentication/login/login.module").then(m=>m.LoginModule)

  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
},
  {
    path: 'sso-redirected',
    loadChildren: ()=> import("../authentication/ssoredirected/ssoredirected.module").then(m=>m.SSORedirectedModule)

  },
  {
    path: 'unauthorized',
    loadChildren: ()=> import("../shared/global/unauthorized/unauthorized.module").then(m=>m.UnauthorizedModule)
  },
  {
    path : 'black-list',
    loadChildren: ()=> import("../authentication/black-listed/black-listed.module").then(m=>m.BlackListedModule),
    data : { title : "Unauthorized Access"}
  },
  {
    path: 'quick-reply/:id',
    loadChildren: ()=> import("../note/notes/quick-reply/quick-reply.module").then(m=>m.QuickReplyModule)
  },
  {
    path: 'stock-card', component: StockCardComponent

  },
  {
    path: 'print-caption-report',
    loadChildren: () => import("../reports/faberge-inventory/product-caption-report/print-report/print-product-caption-report.module").then(m=>m.PrintProductCaptionReportModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import("../authentication/forgot-password/forgot-password.module").then(m=>m.ForgotPasswordModule)

  },
  {
    path: 'reset-password/:id',
    loadChildren: () => import("../authentication/reset-password/reset-password.module").then(m=>m.ResetPasswordModule)
  },
  {
    path: 'unlock-account',
    loadChildren: () => import("../authentication/user-account-locked/user-account-locked.module").then(m=>m.UserAccountLockedModule)
  },
  {
    path: 'member-feedback', loadChildren:() => import("../authentication/member-feedback/member-feedback.module").then(m=>m.MemberFeedbackModule),
  },
  {
    path: 'event-redirection', loadChildren:() => import("../authentication/event-redirection/event-redirection.module").then(m=>m.EventRedirectionModule),
  },
  {
    path: 'system', component: SystemComponent,
    loadChildren: () => import("../shared/master/system/system.module").then(m => m.SystemModule)
  },
  {
    path: 'faberge',
    loadChildren: () => import("../shared/master/faberge/faberge.module").then(m=>m.FabergeModule)
  },
  {
    path: 'gemfield',
    loadChildren: () => import("../shared/master/gemfiled/gemfield.module").then(m=>m.GemfieldModule)
  },
  {
    path: 'leavetravel',
    loadChildren: () => import("../master/leave-masters/view/leave-master-view.module").then(m=>m.LeaveMasterViewModule)
  },
  {
    path: 'accessrequest',
    loadChildren: () => import("../event/events/view/restricted-event-aceess-master-view.module").then(m=>m.RestrictedEventAccessMasterViewModule)
  },
  {
    path: 'i-t-help-desk-weekly-report-view',
    loadChildren: () => import("../reports/i-t-help-desk/i-t-help-desk-weekly-report/view/i-t-help-desk-weekly-report-view.module").then(m=>m.ITHelpDeskWeeklyReportViewModule)
  },
  {
    path: 'people',
    loadChildren: () => import("../shared/master/people/people.module").then(m => m.PeopleModule)
  },
  {
    path: 'faberge-sales-orders',
    loadChildren: () => import("../shared/master/faberge-sales-orders/faberge-sales-orders.module").then(m => m.FabergeSalesOrderModule)

  },
  {
    path: 'faberge-inventory',
    loadChildren: () => import("../shared/master/faberge-inventory/faberge-inventory.module").then(m=>m.FabergeInventoryModule)
  },
  // {
  //   path: 'emailvalidation', component: UserViewComponent,
  // },
  {
    path: "activity-log-types",
    loadChildren: () => import("../master/activity-log-types/activity-log-type.module").then(m => m.ActivityLogTypeModule),
    data : { title : "Activity Types"}
  },
  {
    path: "bangle-sizes",
    loadChildren: () => import("../master/bangle-sizes/bangle-size.module").then(m => m.BangleSizeModule),
    data : { title : "Bangle Sizes"}
  },
  {
    path: "boutique-pictures",
    loadChildren: () => import("../master/boutique-pictures/boutique-picture.module").then(m => m.BoutiquePictureModule)
  },
  {
    path: "boutiques",
    loadChildren: () => import("../master/boutiques/boutique.module").then(m => m.BoutiqueModule),
    data : { title : "Boutiques"}
  },
  {
    path: "categories",
    loadChildren: () => import("../master/categories/category.module").then(m => m.CategoryModule),
    data : { title : "Design Categories"}
  },
  {
    path: "bom",
    loadChildren: () => import("../master/bom/bom.module").then(m => m.BomModule),
    data : { title : "BOM Categories"}
  },
  {
    path: "gemstone",
    loadChildren: () => import("../master/gemstones/gemstone.module").then(m => m.GemstoneModule),
    data : { title : "Gemstone Types"}
  },
  {
    path: "chain-colours",
    loadChildren: () => import("../master/chain-colours/chain-colour.module").then(m => m.ChainColourModule),
    data : { title : "Chain Colour"}
  },
  {
    path: "chain-lengths",
    loadChildren: () => import("../master/chain-lengths/chain-length.module").then(m => m.ChainLengthModule),
    data : { title : "Chain Length"}
  },
  {
    path: "countries",
    loadChildren: () => import("../master/countries/country.module").then(m => m.CountryModule),
    data : { title : "Countries"}
  },
  {
    path: "credit-card-types",
    loadChildren: () => import("../master/credit-card-types/credit-card-type.module").then(m => m.CreditCardTypeModule),
    data : { title : "Credit Cards"}
  },
  {
    path: "currencies",
    loadChildren: () => import("../master/currencies/currency.module").then(m => m.CurrencyModule),
    data : { title : "Currencies"}
  },
  {
    path: "customer-child-relationships",
    loadChildren: () => import("../master/customer-child-relationships/customer-child-relationship.module").then(m => m.CustomerChildRelationshipModule)
  },
  {
    path: "customer-relationships",
    loadChildren: () => import("../master/customer-relationships/customer-relationship.module").then(m => m.CustomerRelationshipModule),
    data : { title : "Manage Customer Relationship"}
  },
  {
    path: "departments",
    loadChildren: () => import("../master/departments/department.module").then(m => m.DepartmentModule),
    data : { title : "Department"}
  },
  {
    path: "duty-statuses",
    loadChildren: () => import("../master/duty-statuses/duty-status.module").then(m => m.DutyStatusModule),
    data : { title : "Duty Status"}
  },
  {
    path: "email-account",
    loadChildren: () => import("../master/email-account/email-account.module").then(m => m.EmailAccountModule)
  },
  {
    path: "gemfield-sales-channels",
    loadChildren: () => import("../master/gemfield-sales-channels/gemfield-sales-channel.module").then(m => m.GemfieldSalesChannelModule),
    data : { title : "Gemfields Sales Channel"}
  },
  {
    path: "gemfields-arrangements",
    loadChildren: () => import("../master/gemfields-arrangements/gemfields-arrangement.module").then(m => m.GemfieldsArrangementModule),
    data : { title : "Arrangement"}
  },
  {
    path: "gem-fields-booking-statuses",
    loadChildren: () => import("../master/gem-fields-booking-statuses/gem-fields-booking-status.module").then(m => m.GemFieldsBookingStatusModule),
    data : { title : "Gemfields Booking Type"}
  },
  {
    path: "gemfields-carrier",
    loadChildren: () => import("../master/gemfields-carrier/gemfields-carrier.module").then(m => m.GemfieldsCarrierModule),
    data : { title : "Carrier"}
  },
  {
    path: "gemfields-enhancement",
    loadChildren: () => import("../master/gemfields-enhancement/gemfields-enhancement.module").then(m => m.GemfieldsEnhancementModule),
    data : { title : "Enhancement"}
  },
  {
    path: "gemfields-enhancement-type",
    loadChildren: () => import("../master/gemfields-enhancement-type/gemfields-enhancement-type.module").then(m => m.GemfieldsEnhancementTypeModule),
    data : { title : "Enhancement Type"}
  },
  {
    path: "gemfields-location",
    loadChildren: () => import("../master/gemfields-location/gemfields-location.module").then(m => m.GemfieldsLocationModule),
    data : { title : "Location"}
  },
  {
    path: "event-service-types",
    loadChildren: () => import("../master/event-service-types/event-service-types.module").then(m => m.EventServiceTypesModule),
    data: { title: "Event Service Types"}
  },
  {
    path: "it-asset-location",
    loadChildren: () => import("../i-t-help-desk/i-t-asset-location/i-t-asset-location-module").then(m => m.ITAssetLocationModule),
    data : { title : "IT Asset"}
  },
  {
    path: "it-asset-manufacturer",
    loadChildren: () => import("../i-t-help-desk/i-t-asset-manufacturer/i-t-asset-manufacturer.module").then(m => m.ITAssetManufacturerModule),
    data : { title : "IT Asset"}
  },
  {
    path: "gemfields-station",
    loadChildren: () => import("../master/gemfields-station/gemfields-station.module").then(m => m.GemfieldsStationModule),
    data : { title : "Station"}
  },
  {
    path: "gem-stone-clarities",
    loadChildren: () => import("../master/gem-stone-clarities/gem-stone-clarity.module").then(m => m.GemStoneClarityModule),
    data : { title : "Gemstone Clarities"}
  },
  {
    path: "gem-stone-colors",
    loadChildren: () => import("../master/gem-stone-colors/gem-stone-color.module").then(m => m.GemStoneColorModule),
    data : { title : "Gemstone Colour"}

  },
  {
    path: "gem-stone-cut-types",
    loadChildren: () => import("../master/gem-stone-cut-types/gem-stone-cut-type.module").then(m => m.GemStoneCutTypeModule),
    data : { title : "Cut Type"}
  },
  {
    path: "materials",
    loadChildren: () => import("../master/materials/material.module").then(m => m.MaterialModule),
    data : { title : "Material"}
  },
  {
    path: "gemstone-origins",
    loadChildren: () => import("../master/gemstone-origins/gemstone-origin.module").then(m => m.GemstoneOriginModule),
    data : { title : "Gemstone Origin"}
  },
  {
    path: "gemstone-statuses",
    loadChildren: () => import("../master/gemstone-statuses/gemstone-status.module").then(m => m.GemstoneStatusModule),
    data : { title : "Gemstone Status"}
  },
  {
    path: "help-supports",
    loadChildren: () => import("../master/help-supports/help-support.module").then(m => m.HelpSupportModule),
    data : { title : "Help & Support"}
  },
  {
    path: "power-bi-reports",
    loadChildren: () => import("../master/power-bi-reports/power-bi-report.module").then(m => m.PowerBIReportModule),
    data : { title : "Power BI Reports"}
  },

  {
    path: "help-support-user-roles",
    loadChildren: () => import("../master/help-support-user-roles/help-support-user-role.module").then(m => m.HelpSupportUserRoleModule)
  },
  {
    path: "holiday-lists",
    loadChildren: () => import("../master/holiday-lists/holiday-list.module").then(m => m.HolidayListModule),
    data : { title : "Bank Holiday"}
  },
  {
    path: "bank-holidays",
    loadChildren: () => import("../master/bank-holidays/bank-holidays.module").then(m => m.BankHolidayModule),
    data : { title : "Bank Holiday"}
  },
  {
    path: "industry-types",
    loadChildren: () => import("../master/industry-types/industry-type.module").then(m => m.IndustryTypeModule),
    data : { title : "Industry Type"}
  },
  {
    path: "system-help",
    loadChildren: () => import("../master/system-help/system-help.module").then(m => m.SystemHelpModule),
    data : { title : "System Help"}
  },
  {
    path: "languages",
    loadChildren: () => import("../master/languages/language.module").then(m => m.LanguageModule),
    data : { title : "Languages"}
  },
  {
    path: "leave-types",
    loadChildren: () => import("../master/leave-types/leave-type.module").then(m => m.LeaveTypeModule),
    data : { title : "Leave Type"}
  },
  {
    path: "location-groups",
    loadChildren: () => import("../master/location-groups/location-group.module").then(m => m.LocationGroupModule),
    data : { title : "Group Location"}
  },
  {
    path: "main-collections",
    loadChildren: () => import("../master/main-collections/main-collection.module").then(m => m.MainCollectionModule),
    data : { title : "Collections"}
  },
  {
    path: "customised-pages",
    loadChildren: () => import("../master/adhoc-pages/adhoc-pages.module").then(m => m.AdhocPagesModule),
    data : { title : "Customised"}
  },
  {
    path: "measure-dimensions",
    loadChildren: () => import("../master/measure-dimensions/measure-dimension.module").then(m => m.MeasureDimensionModule),
    data : { title : "Measure Dimensions"}
  },
  {
    path: "measure-weights",
    loadChildren: () => import("../master/measure-weights/measure-weight.module").then(m => m.MeasureWeightModule),
    data : { title : "Measure Weights"}
  },
  //{
  //    path: "message-template",
  //    loadChildren: () => import("../master/message-template/message-template.module").then(m => m.MessageTemplateModule)
  //},
  //{
  //    path: "message-template-detail",
  //    loadChildren: () => import("../master/message-template-detail/message-template-detail.module").then(m => m.MessageTemplateDetailModule)
  //},
  {
    path: "DownloadExcel",
    loadChildren: () => import("../master/export-to-excel-option/export-to-excel-option.module").then(m => m.ExportToExcelOptionModule),
    data : { title : "DownloadExcel"}
  },
  {
    path: "nationalities",
    loadChildren: () => import("../master/nationalities/nationality.module").then(m => m.NationalityModule),
    data : { title : "Nationality"}
  },
  {
    path: "note-type",
    loadChildren: () => import("../master/note-type/note-type.module").then(m => m.NoteTypeModule),
    data : { title : "Hashtags"}
  },
  {
    path: "hscode", //#285950/ Naitik Bhatt/21-05-2024 added routing for HS code For F admin
    loadChildren: () => import("../master/hscode/hscode.module").then(m => m.HsCodeModule),
    data : { title : "hscode"}
  },
  {
    path: "payment-methods",
    loadChildren: () => import("../master/payment-methods/payment-method.module").then(m => m.PaymentMethodModule),
    data : { title : "Payment Methods"}
  },
  {
    path: "piece-delivery-statuses",
    loadChildren: () => import("../master/piece-delivery-statuses/piece-delivery-status.module").then(m => m.PieceDeliveryStatusModule),
    data : { title : "Piece Delivery Status"}
  },
  {
    path: "piece-owners",
    loadChildren: () => import("../master/piece-owners/piece-owner.module").then(m => m.PieceOwnerModule),
    data : { title : "Piece Owners"}
  },
  {
    path: "p-o-s-brands",
    loadChildren: () => import("../master/p-o-s-brands/p-o-s-brand.module").then(m => m.POSBrandModule),
    data : { title : "Brands"}
  },
  {
    path: "priceband-options",
    loadChildren: () => import("../master/priceband-options/priceband-option.module").then(m => m.PricebandOptionModule),
    data : { title : "Price Bands"}
  },
  {
    path: "product-attributes",
    loadChildren: () => import("../master/product-attributes/product-attribute.module").then(m => m.ProductAttributeModule),
    data : { title : "Design Attributes"}
  },
  {
    path: "product-variant-statuses",
    loadChildren: () => import("../master/product-variant-statuses/product-variant-status.module").then(m => m.ProductVariantStatusModule),
    data : { title : "Product Piece Status"}
  },
  {
    path: "ring-egg-sizes",
    loadChildren: () => import("../master/ring-egg-sizes/ring-egg-size.module").then(m => m.RingEggSizeModule),
    data : { title : "Egg Sizes"}
  },
  {
    path: "ring-sizes",
    loadChildren: () => import("../master/ring-sizes/ring-size.module").then(m => m.RingSizeModule),
    data : { title : "Ring Sizes"}
  },
  {
    path: "roles",
    loadChildren: () => import("../master/roles/role.module").then(m => m.RoleModule),
    data : { title : "User Roles"}
  },
  {
    path: "sales-channels",
    loadChildren: () => import("../master/sales-channels/sales-channel.module").then(m => m.SalesChannelModule),
    data : { title : "Sales Channels"}
  },
  {
    path: "shipping-method",
    loadChildren: () => import("../master/shipping-method/shipping-method.module").then(m => m.ShippingMethodModule),
    data : { title : "Shipping Methods"}
  },
  {
    path: "state-provinces",
    loadChildren: () => import("../master/state-provinces/state-province.module").then(m => m.StateProvinceModule),
    data : { title : "States/Provinces"}
  },
  {
    path: "tax-category",
    loadChildren: () => import("../master/tax-category/tax-category.module").then(m => m.TaxCategoryModule),
    data : { title : "Tax Classes"}
  },
  {
    path: "tax-provider",
    loadChildren: () => import("../master/tax-provider/tax-provider.module").then(m => m.TaxProviderModule),
    data : { title : "Tax Providers"}
  },
  {
    path: "titles",
    loadChildren: () => import("../master/titles/title.module").then(m => m.TitleModule),
    data : { title : "Titles"}
  },
  {
    path: "trip-types",
    loadChildren: () => import("../master/trip-types/trip-type.module").then(m => m.TripTypeModule),
    data : { title : "Booking Type"}
  },
  {
    path: "warehouses",
    loadChildren: () => import("../master/warehouses/warehouse.module").then(m => m.WarehouseModule),
    data : { title : "Locations"}
  },
  // {
  //   path: "activity-log-types",
  //   loadChildren: () => import("../master/activity-log-types/activity-log-type.module").then(m => m.ActivityLogTypeModule)
  // },
  // {
  //   path: "bangle-sizes",
  //   loadChildren: () => import("../master/bangle-sizes/bangle-size.module").then(m => m.BangleSizeModule)
  // },
  // {
  //   path: "boutique-pictures",
  //   loadChildren: () => import("../master/boutique-pictures/boutique-picture.module").then(m => m.BoutiquePictureModule)
  // },
  // {
  //   path: "boutiques",
  //   loadChildren: () => import("../master/boutiques/boutique.module").then(m => m.BoutiqueModule)
  // },
  // {
  //   path: "categories",
  //   loadChildren: () => import("../master/categories/category.module").then(m => m.CategoryModule)
  // },
  // {
  //   path: "chain-colours",
  //   loadChildren: () => import("../master/chain-colours/chain-colour.module").then(m => m.ChainColourModule)
  // },
  // {
  //   path: "chain-lengths",
  //   loadChildren: () => import("../master/chain-lengths/chain-length.module").then(m => m.ChainLengthModule)
  // },
  // {
  //   path: "countries",
  //   loadChildren: () => import("../master/countries/country.module").then(m => m.CountryModule)
  // },
  // {
  //   path: "credit-card-types",
  //   loadChildren: () => import("../master/credit-card-types/credit-card-type.module").then(m => m.CreditCardTypeModule)
  // },
  // {
  //   path: "currencies",
  //   loadChildren: () => import("../master/currencies/currency.module").then(m => m.CurrencyModule)
  // },
  // {
  //   path: "customer-child-relationships",
  //   loadChildren: () => import("../master/customer-child-relationships/customer-child-relationship.module").then(m => m.CustomerChildRelationshipModule)
  // },
  // {
  //   path: "customer-relationships",
  //   loadChildren: () => import("../master/customer-relationships/customer-relationship.module").then(m => m.CustomerRelationshipModule)
  // },
  // {
  //   path: "departments",
  //   loadChildren: () => import("../master/departments/department.module").then(m => m.DepartmentModule)
  // },
  // {
  //   path: "duty-statuses",
  //   loadChildren: () => import("../master/duty-statuses/duty-status.module").then(m => m.DutyStatusModule)
  // },
  // {
  //   path: "email-account",
  //   loadChildren: () => import("../master/email-account/email-account.module").then(m => m.EmailAccountModule)
  // },
  // {
  //   path: "gemfield-sales-channels",
  //   loadChildren: () => import("../master/gemfield-sales-channels/gemfield-sales-channel.module").then(m => m.GemfieldSalesChannelModule)
  // },
  // {
  //   path: "gemfields-arrangements",
  //   loadChildren: () => import("../master/gemfields-arrangements/gemfields-arrangement.module").then(m => m.GemfieldsArrangementModule)
  // },
  // {
  //   path: "gem-fields-booking-statuses",
  //   loadChildren: () => import("../master/gem-fields-booking-statuses/gem-fields-booking-status.module").then(m => m.GemFieldsBookingStatusModule)
  // },
  // {
  //   path: "gemfields-carrier",
  //   loadChildren: () => import("../master/gemfields-carrier/gemfields-carrier.module").then(m => m.GemfieldsCarrierModule)
  // },
  // {
  //   path: "gemfields-enhancement",
  //   loadChildren: () => import("../master/gemfields-enhancement/gemfields-enhancement.module").then(m => m.GemfieldsEnhancementModule)
  // },
  // {
  //   path: "gemfields-enhancement-type",
  //   loadChildren: () => import("../master/gemfields-enhancement-type/gemfields-enhancement-type.module").then(m => m.GemfieldsEnhancementTypeModule)
  // },
  // {
  //   path: "gemfields-location",
  //   loadChildren: () => import("../master/gemfields-location/gemfields-location.module").then(m => m.GemfieldsLocationModule)
  // },
  // {
  //   path: "gemfields-station",
  //   loadChildren: () => import("../master/gemfields-station/gemfields-station.module").then(m => m.GemfieldsStationModule)
  // },
  // {
  //   path: "gem-stone-clarities",
  //   loadChildren: () => import("../master/gem-stone-clarities/gem-stone-clarity.module").then(m => m.GemStoneClarityModule)
  // },
  // {
  //   path: "gem-stone-colors",
  //   loadChildren: () => import("../master/gem-stone-colors/gem-stone-color.module").then(m => m.GemStoneColorModule)
  // },
  // {
  //   path: "gem-stone-cut-types",
  //   loadChildren: () => import("../master/gem-stone-cut-types/gem-stone-cut-type.module").then(m => m.GemStoneCutTypeModule)
  // },
  // {
  //   path: "gemstone-origins",
  //   loadChildren: () => import("../master/gemstone-origins/gemstone-origin.module").then(m => m.GemstoneOriginModule)
  // },
  // {
  //   path: "gemstone-statuses",
  //   loadChildren: () => import("../master/gemstone-statuses/gemstone-status.module").then(m => m.GemstoneStatusModule)
  // },
  // {
  //   path: "help-supports",
  //   loadChildren: () => import("../master/help-supports/help-support.module").then(m => m.HelpSupportModule)
  // },
  // {
  //   path: "help-support-user-roles",
  //   loadChildren: () => import("../master/help-support-user-roles/help-support-user-role.module").then(m => m.HelpSupportUserRoleModule)
  // },
  // {
  //   path: "holiday-lists",
  //   loadChildren: () => import("../master/holiday-lists/holiday-list.module").then(m => m.HolidayListModule)
  // },
  // {
  //   path: "industry-types",
  //   loadChildren: () => import("../master/industry-types/industry-type.module").then(m => m.IndustryTypeModule)
  // },
  // {
  //   path: "languages",
  //   loadChildren: () => import("../master/languages/language.module").then(m => m.LanguageModule)
  // },
  // {
  //   path: "leave-types",
  //   loadChildren: () => import("../master/leave-types/leave-type.module").then(m => m.LeaveTypeModule)
  // },
  {
    path: "leave-masters",
    loadChildren: () => import("../master/leave-masters/leave-master.module").then(m => m.LeaveMasterModule)
  },
  // {
  //   path: "location-groups",
  //   loadChildren: () => import("../master/location-groups/location-group.module").then(m => m.LocationGroupModule)
  // },
  // {
  //   path: "main-collections",
  //   loadChildren: () => import("../master/main-collections/main-collection.module").then(m => m.MainCollectionModule)
  // },
  // {
  //   path: "customised-pages",
  //   loadChildren: () => import("../master/adhoc-pages/adhoc-pages.module").then(m => m.AdhocPagesModule)
  // },
  // {
  //   path: "measure-dimensions",
  //   loadChildren: () => import("../master/measure-dimensions/measure-dimension.module").then(m => m.MeasureDimensionModule)
  // },
  // {
  //   path: "measure-weights",
  //   loadChildren: () => import("../master/measure-weights/measure-weight.module").then(m => m.MeasureWeightModule)
  // },
  //{
  //    path: "message-template",
  //    loadChildren: () => import("../master/message-template/message-template.module").then(m => m.MessageTemplateModule)
  //},
  //{
  //    path: "message-template-detail",
  //    loadChildren: () => import("../master/message-template-detail/message-template-detail.module").then(m => m.MessageTemplateDetailModule)
  //},
  // {
  //   path: "nationalities",
  //   loadChildren: () => import("../master/nationalities/nationality.module").then(m => m.NationalityModule)
  // },
  // {
  //   path: "note-type",
  //   loadChildren: () => import("../master/note-type/note-type.module").then(m => m.NoteTypeModule)
  // },
  // {
  //   path: "payment-methods",
  //   loadChildren: () => import("../master/payment-methods/payment-method.module").then(m => m.PaymentMethodModule)
  // },
  // {
  //   path: "piece-delivery-statuses",
  //   loadChildren: () => import("../master/piece-delivery-statuses/piece-delivery-status.module").then(m => m.PieceDeliveryStatusModule)
  // },
  // {
  //   path: "piece-owners",
  //   loadChildren: () => import("../master/piece-owners/piece-owner.module").then(m => m.PieceOwnerModule)
  // },
  // {
  //   path: "p-o-s-brands",
  //   loadChildren: () => import("../master/p-o-s-brands/p-o-s-brand.module").then(m => m.POSBrandModule)
  // },
  // {
  //   path: "priceband-options",
  //   loadChildren: () => import("../master/priceband-options/priceband-option.module").then(m => m.PricebandOptionModule)
  // },
  // {
  //   path: "product-attributes",
  //   loadChildren: () => import("../master/product-attributes/product-attribute.module").then(m => m.ProductAttributeModule)
  // },
  // {
  //   path: "product-variant-statuses",
  //   loadChildren: () => import("../master/product-variant-statuses/product-variant-status.module").then(m => m.ProductVariantStatusModule)
  // },
  // {
  //   path: "ring-egg-sizes",
  //   loadChildren: () => import("../master/ring-egg-sizes/ring-egg-size.module").then(m => m.RingEggSizeModule)
  // },
  // {
  //   path: "roles",
  //   loadChildren: () => import("../master/roles/role.module").then(m => m.RoleModule)
  // },
  // {
  //   path: "sales-channels",
  //   loadChildren: () => import("../master/sales-channels/sales-channel.module").then(m => m.SalesChannelModule)
  // },
  // {
  //   path: "shipping-method",
  //   loadChildren: () => import("../master/shipping-method/shipping-method.module").then(m => m.ShippingMethodModule)
  // },
  // {
  //   path: "state-provinces",
  //   loadChildren: () => import("../master/state-provinces/state-province.module").then(m => m.StateProvinceModule)
  // },
  // {
  //   path: "tax-category",
  //   loadChildren: () => import("../master/tax-category/tax-category.module").then(m => m.TaxCategoryModule)
  // },
  // {
  //   path: "tax-provider",
  //   loadChildren: () => import("../master/tax-provider/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  // {
  //   path: "titles",
  //   loadChildren: () => import("../master/titles/title.module").then(m => m.TitleModule)
  // },
  // {
  //   path: "trip-types",
  //   loadChildren: () => import("../master/trip-types/trip-type.module").then(m => m.TripTypeModule)
  // },
  // {
  //   path: "warehouses",
  //   loadChildren: () => import("../master/warehouses/warehouse.module").then(m => m.WarehouseModule)
  // },

  {
    path: "dashboard",
    loadChildren: () => import("../dashboard/dashboard.module").then(m => m.DashboardModule),
    data : { title : "Dashboard" }
  },
  {
    path: "gem-stone-clarity-gemfields",
    loadChildren: () => import("../master/gem-stone-clarity-gemfields/gem-stone-clarity-gemfield.module").then(m => m.GemStoneClarityGemfieldModule),
    data : { title : "Clarity"}
  },
  {
    path: "gem-stone-color-gemfields",
    loadChildren: () => import("../master/gem-stone-color-gemfields/gem-stone-color-gemfield.module").then(m => m.GemStoneColorGemfieldModule),
    data : { title : "Colour"}
  },
  {
    path: "gem-stone-cut-type-gemfields",
    loadChildren: () => import("../master/gem-stone-cut-type-gemfields/gem-stone-cut-type-gemfield.module").then(m => m.GemStoneCutTypeGemfieldModule),
    data : { title : "Cut Type"}
  },
  // {
  //   path: "gem-stone-clarity-gemfields",
  //   loadChildren: () => import("../master/gem-stone-clarity-gemfields/gem-stone-clarity-gemfield.module").then(m => m.GemStoneClarityGemfieldModule)
  // },
  // {
  //   path: "gem-stone-color-gemfields",
  //   loadChildren: () => import("../master/gem-stone-color-gemfields/gem-stone-color-gemfield.module").then(m => m.GemStoneColorGemfieldModule)
  // },
  // {
  //   path: "gem-stone-cut-type-gemfields",
  //   loadChildren: () => import("../master/gem-stone-cut-type-gemfields/gem-stone-cut-type-gemfield.module").then(m => m.GemStoneCutTypeGemfieldModule)
  // },
  {
    path: "watch-status",
    loadChildren: () => import("../master/watch-status/watch-status.module").then(m => m.WatchStatusModule),
    data : { title : "Watch Status"}
  },
  {
    path: "legal-owner",
    loadChildren: () => import("../master/legal-owners/legal-owner.module").then(m => m.LegalOwnerModule),
    data : { title : "Legal Owner"}
  },
  {
    path: "watch-item-types",
    loadChildren: () => import("../master/watch-item-types/watch-item-type.module").then(m => m.WatchItemModule),
    data : { title : "Watch Items"}
  },
  {
    path: "watch-materials",
    loadChildren: () => import("../master/watch-materials/watch-material.module").then(m => m.WatchMaterialModule),
    data : { title : "Watch Materials"}
  },
  //      {
  //	path: "message-template-details",
  //	loadChildren: () => import("../master/message-template-details/message-template-detail.module").then(m => m.MessageTemplateDetailModule)
  //},
  //      {
  //	path: "message-templates",
  //	loadChildren: () => import("../master/message-templates/message-template.module").then(m => m.MessageTemplateModule)
  //},

  {
    path: "activity-logs",
    loadChildren: () => import("../master/activity-logs/activity-log.module").then(m => m.ActivityLogModule),
    data : { title : "Activity Log"}
  },
  {
    path: "application-exception-logs",
    loadChildren: () => import("../master/application-exception-logs/application-exception-log.module").then(m => m.ApplicationExceptionLogModule),
    data : { title : "Log"}
  },
  //     {
  // 	path: "banned-ip-addresses",
  // 	loadChildren: () => import("../master/banned-ip-addresses/banned-ip-address.module").then(m => m.BannedIpAddressModule)
  // },
  // {
  // 	path: "banned-ip-network",
  // 	loadChildren: () => import("../master/banned-ip-network/banned-ip-network.module").then(m => m.BannedIpNetworkModule)
  // },
  // {
  // 	path: "settings",
  // 	loadChildren: () => import("../master/settings/setting.module").then(m => m.SettingModule)
  // },
  {
    path: "banned-ip-addresses",
    loadChildren: () => import("../master/banned-ip-addresses/banned-ip-address.module").then(m => m.BannedIpAddressModule),
    data : { title : "Blacklist"}
  },
  {
    path: "banned-ip-network",
    loadChildren: () => import("../master/banned-ip-network/banned-ip-network.module").then(m => m.BannedIpNetworkModule)
  },
  {
    path: "settings",
    loadChildren: () => import("../master/settings/setting.module").then(m => m.SettingModule),
    data : { title : "All Settings"}
  },
  {
    path: "user-lockout",
    loadChildren: () => import("../master/user-lockout/user-lockout.module").then(m => m.UserLockoutModule)
  },
  // {
  //   path: "user-lockout",
  //   loadChildren: () => import("../master/user-lockout/user-lockout.module").then(m => m.UserLockoutModule)
  // },
  {
    path: "message-templates",
    loadChildren: () => import("../master/message-templates/message-template.module").then(m => m.MessageTemplateModule),
    data : { title : "Message Templates"}
  },
  {
    path: "temporary-assignee-types",
    loadChildren: () => import("../master/temporary-assignee-types/temporary-assignee-type.module").then(m => m.TemporaryAssigneeTypeModule),
    data : { title : "Tax/Duty Treatment"}
  },
  {
    path: "h-s-e-updates",
    loadChildren: () => import("../master/h-s-e-updates/h-s-e-update.module").then(m => m.HSEUpdateModule),
    data : { title : "HSE Status Update"}
  },
  // {
  //   path: "h-s-e-updates",
  //   loadChildren: () => import("../master/h-s-e-updates/h-s-e-update.module").then(m => m.HSEUpdateModule)
  // },
  // {
  //   path: "customer-holidays",
  //   loadChildren: () => import("../master/customer-holidays/customer-holiday.module").then(m => m.CustomerHolidayModule)
  // },
  {
    path: "customer-holidays",
    loadChildren: () => import("../master/customer-holidays/customer-holiday.module").then(m => m.CustomerHolidayModule),
    data : { title : "Holiday Process"}
  },
  {
    path: "tax-rates",
    loadChildren: () => import("../master/tax-rates/tax-rate.module").then(m => m.TaxRateModule)
  },
  {
    path: "bookings",
    loadChildren: () => import("../booking/bookings/booking.module").then(m => m.BookingModule),
    data : { title : "Bookings"}
  },
  {
    path: "events",
    loadChildren: () => import("../event/events/event.module").then(m => m.EventModule),
    data : { title : "Events"}
  },

  {
    path: "address",
    loadChildren: () => import("../user/address/address.module").then(m => m.AddressModule)
  },

  {
    path: "known-tos",
    loadChildren: () => import("../user/known-tos/known-to.module").then(m => m.KnownToModule)
  },
  {
    path: "suppliers",
    loadChildren: () => import("../user/suppliers/supplier.module").then(m => m.SupplierModule),
    data : { title : "Corporates/Groups"}
  },
  {
    path: "users",
    loadChildren: () => import("../user/users/user.module").then(m => m.UserModule),
    data: { title : "People" }
  },
  {
    path: "internal-directory",
    loadChildren: () => import("../user/internal-directory/internal-directory.module").then(m => m.InternalDirectoryModule),
    data : { title : "Internal Directory"}
  },
  {
    path: "all-settings",
    loadChildren: () => import("../master/all-settings/all-setting.module").then(m => m.AllSettingModule)
  },
  {
    path: "projects",
    loadChildren: () => import("../project/projects/project.module").then(m => m.ProjectModule),
    data : { title : "Projects"}
  },
  // {
  //   path: "projects",
  //   loadChildren: () => import("../project/projects/project.module").then(m => m.ProjectModule)
  // },
  {
    path: "customer-employment",
    loadChildren: () => import("../user/company-customer-mappings/company-customer-mapping.module").then(m => m.CompanyCustomerMappingModule)
  },
  {
    path: "system-information",
    loadChildren: () => import("../master/system-information/system-information.module").then(m => m.SystemInformationModule)
  },
  {
    path: "user-email-settings",
    loadChildren: () => import("../user/user-email-settings/user-email-setting.module").then(m => m.UserEmailSettingModule)
  },

  // {
  //   path: "notes",
  //   loadChildren: () => import("../note/notes/note.module").then(m => m.NoteModule)
  // },

  // {
  //   path: "notes",
  //   loadChildren: () => import("../note/notes/note.module").then(m => m.NoteModule)
  // },

  // {
  //   path: "notes",
  //   loadChildren: () => import("../note/notes/note.module").then(m => m.NoteModule)
  // },
  // {
  //   path: "event-customer-mappings",
  //   loadChildren: () => import("../event/event-customer-mappings/event-customer-mapping.module").then(m => m.EventCustomerMappingModule)
  // },
  // {
  //   path: "event-customer-mappings",
  //   loadChildren: () => import("../event/event-customer-mappings/event-customer-mapping.module").then(m => m.EventCustomerMappingModule)
  // },

  // {
  //   path: "event-customer-mappings",
  //   loadChildren: () => import("../event/event-customer-mappings/event-customer-mapping.module").then(m => m.EventCustomerMappingModule)
  // },

  // {
  //   path: "document",
  //   loadChildren: () => import("../document/document/document.module").then(m => m.DocumentModule)
  // },
  {
    path: "project-events",
    loadChildren: () => import("../project/project-events/project-event.module").then(m => m.ProjectEventModule)
  },
  {
    path: "event-customer-mappings",
    loadChildren: () => import("../event/event-customer-mappings/event-customer-mapping.module").then(m => m.EventCustomerMappingModule)
  },
  {
    path: "document",
    loadChildren: () => import("../document/document/document.module").then(m => m.DocumentModule)
  },
  {
    path: "document-types",
    loadChildren: () => import("../master/document-type/document-type.module").then(m => m.DocumentTypeModule)
  },
  {
    path: "desktop-assets",
    loadChildren: () => import("../master/desktop-assets/desktop-assets.module").then(m => m.DesktopModule)
  },
  {
    path: "mobile-assets",
    loadChildren: () => import("../master/mobile-assets/mobile-assets.module").then(m => m.MobileModule)
  },
  {
    path: "project-event-mapping",
    loadChildren: () => import("../event/project-event-mapping/project-event-mapping.module").then(m => m.ProjectEventMappingModule)
  },
  {
    path: "discounts",
    loadChildren: () => import("../master/discounts/discount.module").then(m => m.DiscountModule),
    data : { title : "Discounts"}
  },
  // {
  //   path: "discounts",
  //   loadChildren: () => import("../master/discounts/discount.module").then(m => m.DiscountModule)
  // },
  // {
  //   path: "discounts",
  //   loadChildren: () => import("../master/discounts/discount.module").then(m => m.DiscountModule)
  // },
  // {
  //   path: "discounts",
  //   loadChildren: () => import("../master/discounts/discount.module").then(m => m.DiscountModule)
  // },



  {
    path: "pictures",
    loadChildren: () => import("../document/pictures/picture.module").then(m => m.PictureModule)
  },
  // {
  //   path: "pictures",
  //   loadChildren: () => import("../document/pictures/picture.module").then(m => m.PictureModule)
  // },
  // {
  //   path: "pictures",
  //   loadChildren: () => import("../document/pictures/picture.module").then(m => m.PictureModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  {
    path: "queued-emails",
    loadChildren: () => import("../master/queued-emails/queued-email.module").then(m => m.QueuedEmailModule),
    data : { title : "Message Queue"}
  },
  //     {
  // 	path: "shipping-methods",
  // 	loadChildren: () => import("../master/shipping-methods/shipping-method.module").then(m => m.ShippingMethodModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  // {
  //   path: "pictures",
  //   loadChildren: () => import("../document/pictures/picture.module").then(m => m.PictureModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  // {
  //   path: "queued-emails",
  //   loadChildren: () => import("../master/queued-emails/queued-email.module").then(m => m.QueuedEmailModule)
  // },
  //     {
  // 	path: "shipping-methods",
  // 	loadChildren: () => import("../master/shipping-methods/shipping-method.module").then(m => m.ShippingMethodModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  // {
  //   path: "pictures",
  //   loadChildren: () => import("../document/pictures/picture.module").then(m => m.PictureModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  // {
  //   path: "queued-emails",
  //   loadChildren: () => import("../master/queued-emails/queued-email.module").then(m => m.QueuedEmailModule)
  // },
  //     {
  // 	path: "shipping-methods",
  // 	loadChildren: () => import("../master/shipping-methods/shipping-method.module").then(m => m.ShippingMethodModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  // {
  //   path: "pictures",
  //   loadChildren: () => import("../document/pictures/picture.module").then(m => m.PictureModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  // {
  //   path: "queued-emails",
  //   loadChildren: () => import("../master/queued-emails/queued-email.module").then(m => m.QueuedEmailModule)
  // },
  {
    path: "news",
    loadChildren: () => import("../news/news.module").then(m => m.NewsModule),
    data : { title : "News"}
  }
  //,
  //     {
  // 	path: "shipping-methods",
  // 	loadChildren: () => import("../master/shipping-methods/shipping-method.module").then(m => m.ShippingMethodModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  , {
    path: "supplier-roles",
    loadChildren: () => import("../user/supplier-roles/supplier-role.module").then(m => m.SupplierRoleModule)
  },
  {
    path: "corporate-company-mappings",
    loadChildren: () => import("../user/corporate-company-mappings/corporate-company-mapping.module").then(m => m.CorporateCompanyMappingModule)
  },
  {
    path: "client-repairs",
    loadChildren: () => import("../user/client-repairs/client-repair.module").then(m => m.ClientRepairModule)
  },
  {
    path: "watch-repair-details",
    loadChildren: () => import("../inventory/watch-repair-details/watch-repair-detail.module").then(m => m.WatchRepairDetailModule)
  },
  {
    path: "ownership-mappings",
    loadChildren: () => import("../user/ownership-mappings/ownership-mapping.module").then(m => m.OwnershipMappingModule)
  },
  {
    path: "call-me",
    loadChildren: () => import("../user/call-me/call-me.module").then(m => m.CallMeModule)
  },

  {
    path: "pointof-sales",
    loadChildren: () => import("../user/pointof-sales/pointof-sale.module").then(m => m.PointofSaleModule)
  },
  {
    path: "bespoke-orders",
    loadChildren: () => import("../user/bespoke-orders/bespoke-order.module").then(m => m.BespokeOrderModule)
  },

  {
    path: "exception-logs",
    loadChildren: () => import("../master/exception-log/exception-logs.module").then(m => m.ExceptionLogModule)
  },

  //     {
  // 	path: "export-to-excel",
  // 	loadChildren: () => import("../user/export-to-excel/export-to-excel.module").then(m => m.ExportToExcelModule)
  // },
  {
    path: "p-o-s-member-mappings",
    loadChildren: () => import("../user/p-o-s-member-mappings/p-o-s-member-mapping.module").then(m => m.POSMemberMappingModule)
  },

  {
    path: "bespoke-approval",
    loadChildren: () => import("../user/bespoke-order-approval/bespoke-order-approval.module").then(m => m.BespokeOrderApprovalModule)
  },
  {
    path: "client-repair-approval",
    loadChildren: () => import("../user/client-repair-approval/client-repair-approval-module").then(m => m.ClientRepairApprovalModule)
  },
  {
    path: "watch-repair-approval",
    loadChildren: () => import("../inventory/watch-repair-approval/watch-repair-approval-module").then(m => m.WatchRepairApprovalModule)
  },
  {
    path: "product-batch-piece-repair-approval",
    loadChildren: () => import("../inventory/product-batch-piece-repair-approval/product-batch-piece-repair-approval-module").then(m => m.ProductBatchPieceRepairApprovalModule)
  },
  {
    path: "user-email-validation",
    loadChildren: () => import("../user/user-email-validation/user-email-validation-module").then(m => m.UserEmailValidationModule)
  },
  {
    path: "track-incidents",
    loadChildren: () => import("../form/track-incidents/track-incident.module").then(m => m.TrackIncidentModule),
    data: { title : "HSE/GINA" },
  },
  // {
  //   path: "track-incidents",
  //   loadChildren: () => import("../form/track-incidents/track-incident.module").then(m => m.TrackIncidentModule)
  // },
  // {
  //   path: "track-incidents",
  //   loadChildren: () => import("../form/track-incidents/track-incident.module").then(m => m.TrackIncidentModule)
  // },
  // {
  //   path: "track-incidents",
  //   loadChildren: () => import("../form/track-incidents/track-incident.module").then(m => m.TrackIncidentModule)
  // },
  // {
  //   path: "track-incidents",
  //   loadChildren: () => import("../form/track-incidents/track-incident.module").then(m => m.TrackIncidentModule)
  // },
  //     {
  // 	path: "export-to-excel",
  // 	loadChildren: () => import("../user/export-to-excel/export-to-excel.module").then(m => m.ExportToExcelModule)
  // }
  //,
  //     {
  // 	path: "user-detail",
  // 	loadChildren: () => import("../user/user-detail/user-detail.module").then(m => m.UserDetailModule)
  // },
  {
    path: "role-permissions",
    loadChildren: () => import("../master/role-permissions/role-permission.module").then(m => m.RolePermissionModule),
    data : { title : "Role Permissions"}
  },
  // {
  //   path: "role-permissions",
  //   loadChildren: () => import("../master/role-permissions/role-permission.module").then(m => m.RolePermissionModule)
  // },
  // {
  //   path: "role-permissions",
  //   loadChildren: () => import("../master/role-permissions/role-permission.module").then(m => m.RolePermissionModule)
  // },
  // {
  //   path: "role-permissions",
  //   loadChildren: () => import("../master/role-permissions/role-permission.module").then(m => m.RolePermissionModule)
  // },
  {
    path: "faberge-team-login-report",
    loadChildren: () => import("../reports/people/faberge-team-login-report/faberge-team-login-report.module").then(m => m.FabergeTeamLoginReportModule),
    data : { title : "User Login Report"}
  },
  {
    path: "repeat-customers-report",
    loadChildren: () => import("../reports/people/repeat-customers-report/repeat-customers-report.module").then(m => m.RepeatCustomerModule),
    data : { title : "Repeat Customers"}
  },
  {
    path: "faberge-team",
    loadChildren: () => import("../master/faberge-team/faberge-team.module").then(m => m.FabergeTeamModule),
    data : { title : "Faberge Team"}
  },
  {
    path: "gemfield-team",
    loadChildren: () => import("../master/gemfield-team/gemfield-team.module").then(m => m.GemfieldTeamModule),
    data : { title : "Gemfields Team"}
  },
  {
    path: "travel-report",
    loadChildren: () => import("../reports/people/travel-report/travel-report.module").then(m => m.TravelReportModule),
    data : { title : "Travel Report"}
  },
  {
    path: "leave-report",
    loadChildren: () => import("../reports/people/leave-report/leave-report.module").then(m => m.LeaveReportModule),
    data : { title : "Leave Report"}
  },
  {
    path: "hr-leave-report",
    loadChildren: () => import("../reports/people/hr-leave-report/hr-leave-report.module").then(m => m.HrLeaveReportModule),
    data : { title : "HR Leave Report"}
  },
  {
    path: "encrypted-user-report",
    loadChildren: () => import("../reports/people/encrypted-user-report/encrypted-user-report.module").then(m => m.EncryptedUserReportModule),
    data : { title : "Encrypted Users"}
  },
  {
    path: "customer-last-contacted",
    loadChildren: () => import("../reports/people/customers-last-contacted/customer-last-contacted-report.module").then(m => m.CustomerLastContactedModule),
    data : { title : "Customer Last Contacted"}
  },
  {
    path: "faberge-price-lists",
    loadChildren: () => import("../inventory/faberge-price/faberge-price.module").then(m => m.FabergePriceModule),
    data : { title : "Faberge Price Lists"}
  },
  // ,
  //     {
  // 	path: "export-to-excel",
  // 	loadChildren: () => import("../user/export-to-excel/export-to-excel.module").then(m => m.ExportToExcelModule)
  // },
  //     {
  // 	path: "booking-customer-mappings",
  // 	loadChildren: () => import("../booking/booking-customer-mappings/booking-customer-mapping.module").then(m => m.BookingCustomerMappingModule)
  // },
  //     {
  // 	path: "booking-gemstones",
  // 	loadChildren: () => import("../booking/booking-gemstones/booking-gemstone.module").then(m => m.BookingGemstoneModule)
  // },
  //     {
  // 	path: "booking-products",
  // 	loadChildren: () => import("../booking/booking-products/booking-product.module").then(m => m.BookingProductModule)
  // },
  //     {
  // 	path: "booking-customer-mappings",
  // 	loadChildren: () => import("../booking/booking-customer-mappings/booking-customer-mapping.module").then(m => m.BookingCustomerMappingModule)
  // },
  //     {
  // 	path: "booking-gemstones",
  // 	loadChildren: () => import("../booking/booking-gemstones/booking-gemstone.module").then(m => m.BookingGemstoneModule)
  // },
  //     {
  // 	path: "booking-products",
  // 	loadChildren: () => import("../booking/booking-products/booking-product.module").then(m => m.BookingProductModule)
  // },
  //     {
  // 	path: "booking-customer-mappings",
  // 	loadChildren: () => import("../booking/booking-customer-mappings/booking-customer-mapping.module").then(m => m.BookingCustomerMappingModule)
  // },
  //     {
  // 	path: "booking-gemstones",
  // 	loadChildren: () => import("../booking/booking-gemstones/booking-gemstone.module").then(m => m.BookingGemstoneModule)
  // },
  //     {
  // 	path: "booking-products",
  // 	loadChildren: () => import("../booking/booking-products/booking-product.module").then(m => m.BookingProductModule)
  // },
  //     {
  // 	path: "booking-customer-mappings",
  // 	loadChildren: () => import("../booking/booking-customer-mappings/booking-customer-mapping.module").then(m => m.BookingCustomerMappingModule)
  // },
  //     {
  // 	path: "booking-gemstones",
  // 	loadChildren: () => import("../booking/booking-gemstones/booking-gemstone.module").then(m => m.BookingGemstoneModule)
  // },
  //     {
  // 	path: "booking-products",
  // 	loadChildren: () => import("../booking/booking-products/booking-product.module").then(m => m.BookingProductModule)
  // },
  //     {
  // 	path: "booking-customer-mappings",
  // 	loadChildren: () => import("../booking/booking-customer-mappings/booking-customer-mapping.module").then(m => m.BookingCustomerMappingModule)
  // },
  //     {
  // 	path: "booking-gemstones",
  // 	loadChildren: () => import("../booking/booking-gemstones/booking-gemstone.module").then(m => m.BookingGemstoneModule)
  // },
  {
    path: "booking-products",
    loadChildren: () => import("../booking/booking-products/booking-product.module").then(m => m.BookingProductModule)

  },
  {
    path: "customer-statistics",
    loadChildren: () => import("../reports/people/customer-statistics/customer-statistic-report/customer-statistics-report.module").then(m => m.CustomerStatisticsModule),
    data : { title : "Customer Statistics"}
  },
  {
    path: "team-member-report",
    loadChildren: () => import("../reports/people/team-member-report/team-member-report.module").then(m => m.TeamMemberReportModule),
    data : { title : "Team Member Report"}
  }
  ,
  // {
  //   path: "leave-report",
  //   loadChildren: () => import("../reports/people/leave-report/leave-report.module").then(m => m.LeaveReportModule)
  // },
  {
    path: "note-report",
    loadChildren: () => import("../reports/notes-report/notes-report.module").then(m => m.NotesReportModule),
    data : { title : "Notes Report"}
  },
  {
    path: "carnets",
    loadChildren: () => import("../inventory/carnets/carnet.module").then(m => m.CarnetModule),
    data : { title : "Faberge Carnets"}
  }
  , {
    path: "blacklist",
    loadChildren: () => import("../master/black-list/blacklist.module").then(m => m.BlackListModule),
    data : { title : "Blacklist"}
  },
  {
    path: "hse-report",
    loadChildren: () => import("../reports/hse-report/hse-report.module").then(m => m.HseReportModule),
    data : { title : "HSE/GINA Report"}
  },
  {
    path: "carnets-products",
    loadChildren: () => import("../inventory/carnets-products/carnets-product.module").then(m => m.CarnetsProductModule)
  },
  {
    path: "incident-persons-affected",
    loadChildren: () => import("../form/incident-persons-affected/incident-persons-affected.module").then(m => m.IncidentPersonsAffectedModule)
  },
  {
    path: "content-management-news",
    loadChildren: () => import("../content-management/news/news.module").then(m => m.NewsModule),
    data : { title : "Faberge News"}
  },
  //       {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  {
    path: "related-product",
    loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  {
    path: "product-batch",
    loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule),
    data : { title : "Faberge Products"}
  },
  {
    path:"batch-section",
    loadChildren:()=> import("../inventory/product-batch-section/product-batch-section.module").then(m=>m.ProductBatchSectionModule)
  },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  {
    path: "product-piece",
    loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule),
    data : { title : "Faberge Pieces"}
  },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },

  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  {
    path: "product-piece-gemstones",
    loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule),
    data : { title : "Faberge Gemstones"}
  },
  {
    path: "watch-parts",
    loadChildren: () => import("../inventory/watch-parts/watch-part.module").then(m => m.WatchPartModule),
    data : { title : "Faberge Watch Parts"}
  },
  {
    path: "gemstone-orders",
    loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule),
    data : { title : "Faberge Gemstones Orders"}
  },
  // {
  //   path: "gemstone-orders",
  //   loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  // {
  //   path: "gemstone-orders",
  //   loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  // {
  //   path: "gemstone-orders",
  //   loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  // {
  //   path: "gemstone-orders",
  //   loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  // {
  //   path: "gemstone-orders",
  //   loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  {
    path: "gemstone-order-gemstone-mapping",
    loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //          {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  {
    path: "p-o-s-carts",
    loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule),
    data : { title : "POS"}
  },
  {
    path: "product-gemstone-certificates",
    loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  },
  //generated code
  {
    path: "news",
    loadChildren: () => import("../content-management/news/news.module").then(m => m.NewsModule),
    data : { title : "Faberge News"}
  },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  //     {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  //    {
  // 	path: "email-accounts",
  // 	loadChildren: () => import("../master/email-accounts/email-account.module").then(m => m.EmailAccountModule)
  // },
  //     {
  // 	path: "tax-providers",
  // 	loadChildren: () => import("../master/tax-providers/tax-provider.module").then(m => m.TaxProviderModule)
  // },
  {
    path: "orders",
    loadChildren: () => import("../sales-order/orders/order.module").then(m => m.OrderModule),
    data : { title : "Faberge Sales Orders"}
  },  
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  // {
  //   path: "related-product",
  //   loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },

  {
    path: "language-content-keys",
    loadChildren: () => import("../master/language-content-keys/language-content-key.module").then(m => m.LanguageContentKeyModule),
    data : { title : "Localisation"}
  },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  //       {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",AF
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },

  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },


  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },



  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },





  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  //generated code
  //       {
  // 	path: "product-design",
  // 	loadChildren: () => import("../inventory/product-design/product-design.module").then(m => m.ProductDesignModule)
  // },
  //     {
  // 	path: "product-design",
  // 	loadChildren: () => import("../inventory/product-design/product-design.module").then(m => m.ProductDesignModule)
  // },
  //     {
  // 	path: "product-design",
  // 	loadChildren: () => import("../inventory/product-design/product-design.module").then(m => m.ProductDesignModule)
  // },
  //     {
  // 	path: "product-design",
  // 	loadChildren: () => import("../inventory/product-design/product-design.module").then(m => m.ProductDesignModule)
  // },
  //     {
  // 	path: "product-design",
  // 	loadChildren: () => import("../inventory/product-design/product-design.module").then(m => m.ProductDesignModule)
  // },
  {
    path: "product-design",
    loadChildren: () => import("../inventory/product-design/product-design.module").then(m => m.ProductDesignModule),
    data : { title : "Faberge Designs"}
  },
  //     {
  // 	path: "product",
  // 	loadChildren: () => import("../inventory/product/product.module").then(m => m.ProductModule)
  // },
  //     {
  // 	path: "product",
  // 	loadChildren: () => import("../inventory/product/product.module").then(m => m.ProductModule)
  // },
  //     {
  // 	path: "product",
  // 	loadChildren: () => import("../inventory/product/product.module").then(m => m.ProductModule)
  // },
  //     {
  // 	path: "product",
  // 	loadChildren: () => import("../inventory/product/product.module").then(m => m.ProductModule)
  // },
  //     {
  // 	path: "product",
  // 	loadChildren: () => import("../inventory/product/product.module").then(m => m.ProductModule)
  // },
  {
    path: "product",
    loadChildren: () => import("../inventory/product/product.module").then(m => m.ProductModule),
    data : { title : "Faberge Products"}
  },
  //     {
  // 	path: "related-product-design",
  // 	loadChildren: () => import("../inventory/related-product-design/related-product-design.module").then(m => m.RelatedProductDesignModule)
  // },
  //     {
  // 	path: "related-product-design",
  // 	loadChildren: () => import("../inventory/related-product-design/related-product-design.module").then(m => m.RelatedProductDesignModule)
  // },
  //     {
  // 	path: "related-product-design",
  // 	loadChildren: () => import("../inventory/related-product-design/related-product-design.module").then(m => m.RelatedProductDesignModule)
  // },
  //     {
  // 	path: "related-product-design",
  // 	loadChildren: () => import("../inventory/related-product-design/related-product-design.module").then(m => m.RelatedProductDesignModule)
  // },
  //     {
  // 	path: "related-product-design",
  // 	loadChildren: () => import("../inventory/related-product-design/related-product-design.module").then(m => m.RelatedProductDesignModule)
  // },
  {
    path: "related-product-design",
    loadChildren: () => import("../inventory/related-product-design/related-product-design.module").then(m => m.RelatedProductDesignModule)
  },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  //     {
  // 	path: "product-design-picture",
  // 	loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  // },
  {
    path: "product-design-picture",
    loadChildren: () => import("../inventory/product-design-picture/product-design-picture.module").then(m => m.ProductDesignPictureModule)
  },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "related-product",
  // 	loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  // {
  //   path: "related-product",
  //   loadChildren: () => import("../inventory/related-product/related-product.module").then(m => m.RelatedProductModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-batch",
  // 	loadChildren: () => import("../inventory/product-batch/product-batch.module").then(m => m.ProductBatchModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },
  //     {
  // 	path: "product-piece",
  // 	loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule)
  // },

  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  // {
  //   path: "product-piece-gemstones",
  //   loadChildren: () => import("../inventory/product-piece-gemstones/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  // },
  //       {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  //     {
  // 	path: "gemstone-orders",
  // 	loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },
  // {
  //   path: "gemstone-orders",
  //   loadChildren: () => import("../inventory/gemstone-orders/gemstone-order.module").then(m => m.GemstoneOrderModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },
  //     {
  // 	path: "gemstone-order-gemstone-mapping",
  // 	loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  // {
  //   path: "gemstone-order-gemstone-mapping",
  //   loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  // {
  //   path: "gemstone-order-gemstone-mapping",
  //   loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  // {
  //   path: "gemstone-order-gemstone-mapping",
  //   loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  // {
  //   path: "gemstone-order-gemstone-mapping",
  //   loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  // {
  //   path: "gemstone-order-gemstone-mapping",
  //   loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  // {
  //   path: "gemstone-order-gemstone-mapping",
  //   loadChildren: () => import("../inventory/gemstone-order-gemstone-mapping/gemstone-order-gemstone-mapping.module").then(m => m.GemstoneOrderGemstoneMappingModule)
  // },
  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "product-picture",
  // 	loadChildren: () => import("../inventory/product-picture/product-picture.module").then(m => m.ProductPictureModule)
  // },

  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "watch-attribute-mappings",
  // 	loadChildren: () => import("../inventory/watch-attribute-mappings/watch-attribute-mapping.module").then(m => m.WatchAttributeMappingModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  //     {
  // 	path: "product-batch-piece-repairs",
  // 	loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  // },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },
  {
    path: "product-batch-piece-repairs",
    loadChildren: () => import("../inventory/product-batch-piece-repairs/product-batch-piece-repair.module").then(m => m.ProductBatchPieceRepairModule)
  },
  //     {
  // 	path: "product-piece-picture",
  // 	loadChildren: () => import("../inventory/product-piece-picture/product-piece-picture.module").then(m => m.ProductPiecePictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  //     {

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },

  //     {
  // 	path: "product-piece-big-picture",
  // 	loadChildren: () => import("../inventory/product-piece-big-picture/product-piece-big-picture.module").then(m => m.ProductPieceBigPictureModule)
  // },
  // {
  //   path: "p-o-s-carts",
  //   loadChildren: () => import("../p-o-s/p-o-s-carts/p-o-s-cart.module").then(m => m.POSCartModule)
  // },
  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },



  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },



  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },






  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },





  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },



  // {
  //   path: "product-gemstone-certificates",
  //   loadChildren: () => import("../inventory/product-gemstone-certificates/product-gemstone-certificate.module").then(m => m.ProductGemstoneCertificateModule)
  // },




  //generated code

  {
    path: "booking-approval",
    loadChildren: () => import("../booking-approval/booking-approval/booking-approval.module").then(m => m.BookingApprovalModule),
    data : { title : "Booking Approval"}
  },

  {
    path: "current-purchase-list",
    loadChildren: () => import("../sales-order/faberge-current-purchase-list/current-purchase-list.module").then(m => m.CurrentPurchaseListModule),
    data : { title : "Current Purchase Lists"}
  },
  {
    path:"return-orders",
    loadChildren: () => import("../sales-order/return-order/return-order.module").then(m => m.ReturnOrderModule),
    data : { title : "Return Order"}
  },
  {
    path: "live-user-tracking",
    loadChildren: () => import("../trackme/liveusertracking/live-user-tracking.module").then(m => m.LiveUserTrackingModule),
    data : { title : "Live user Tracking"}
  },
  {
    path: "timeline-user-tracking",
    loadChildren: () => import("../trackme/timelineusertraking/timeline-user-tracking.module").then(m => m.TimelineUserTrackingModule),
    data : { title : "Timeline user tracking"}
  },
  {
    path: "history-mir-faberge",
    loadChildren: () => import("../content-management/history-mir-faberge/history-mir-faberge.module").then(m => m.HistoryMirFabergeModule),
    data : { title : "Faberge History/MirFaberge"}
  },
  {
    path: "sales-analysis-report",
    loadChildren: () => import("../reports/faberge-sales-report/sales-analysis-report/sales-analysis-report.module").then(m => m.SalesAnalysisReportModule),
    data : { title : "Sales Analysis Report"}
  },
  {
    path: "sales-advisor-report",
    loadChildren: () => import("../reports/faberge-sales-report/sales-advisor-report/sales-advisor-report.module").then(m => m.SalesAdvisorReportModule),
    data : { title : "Sales Advisor Report"}
  },
  {
    path: "sales-margin-report",
    loadChildren: () => import("../reports/faberge-sales-report/sales-margin-report/sales-margin-report.module").then(m => m.SalesMarginReportModule),
    data : { title : "Sales Margin Report"}
  },
  {
    path: "client-sales-report",
    loadChildren: () => import("../reports/faberge-sales-report/client-sales-report/client-sales-report.module").then(m => m.ClientSalesReportModule),
    data : { title : "Client Sales Report"}
  },
  {
    path: "wholesale-report",
    loadChildren: () => import("../reports/faberge-sales-report/wholesale-report/wholesale-report.module").then(m => m.WholeSaleReportModule),
    data : { title : "WholeSale Report"}
  },
  {
    path: "product-piece-booking",
    loadChildren: () => import("../inventory/product-piece/product-piece-booking/product-piece-booking.module").then(m => m.ProductPieceBookingModule)
  },
  {
    path: "sales-order-booking",
    loadChildren: () => import("../sales-order/orders/sales-order-booking/sales-order-booking.module").then(m => m.SalesOrderBookingModule)
  },
  {
    path: "product-piece-gemstone",
    loadChildren: () => import("../inventory/product-piece/product-piece-gemstone/product-piece-gemstone.module").then(m => m.ProductPieceGemstoneModule)
  },
  {
    path: "product-piece",
    loadChildren: () => import("../inventory/product-piece/product-piece.module").then(m => m.ProductPieceModule),
    data : { title : "Faberge Pieces"}
  },
  {
    path: "p-o-s-customer-search",
    loadChildren: () => import("../p-o-s/p-o-s-customer-search/p-o-s-customer-search.module").then(m => m.POSCustomerSearchModule),
    data : { title : "POS"}
  },
  {
    path: "ticket-details",
    loadChildren: () => import("../i-t-help-desk/ticket-details/ticket-detail.module").then(m => m.TicketDetailModule),
    data : { title : "IT Help Desk"}
  },
  {
    path: "it-assets",
    loadChildren: () => import("../i-t-help-desk/it-assets/it-assets.module").then(m => m.ItAssetsModule),
    data : { title : "IT Assets"}
  },
  {
    path: "p-o-s-order",
    loadChildren: () => import("../p-o-s/p-o-s-order/p-o-s-order.module").then(m => m.POSOrderModule)
  }, {
    path: "bom-report",
    loadChildren: () => import("../reports/faberge-inventory/bom-report/bom-report.module").then(m => m.BOMReportModule),
    data : { title : "BOM Report"}
  }, {
    path: "sold-piece-report",
    loadChildren: () => import("../reports/faberge-inventory/sold-piece-report/sold-piece-report.module").then(m => m.SoldPieceReportModule),
    data : { title : "Sold Piece Report"}
  }, {
    path: "bespoke-order-report",
    loadChildren: () => import("../reports/faberge-inventory/bespokes-order-report/bespoke-order-report.module").then(m => m.BespokeOrderReportModule),
    data : { title : "Bespoke Order Report"}
  }, {
    path: "product-caption-report",
    loadChildren: () => import("../reports/faberge-inventory/product-caption-report/product-caption-report.module").then(m => m.ProductCaptionReportModule),
    data : { title : "Product Caption Report"}
  }, {
    path: "core-product-list-report",
    loadChildren: () => import("../reports/faberge-inventory/core-product-list-report/core-product-list-report.module").then(m => m.CoreProductListReportModule),
    data : { title : "Core Product List Summary"}
  }, {
    path: "gemstone-set-piece-report",
    loadChildren: () => import("../reports/faberge-inventory/gemstone-set-piece-report/gemstone-set-piece-report.module").then(m => m.GemstoneSetPieceReportModule),
    data : { title : "Gemstone Set Piece Report"}
  },
  {
    path: "piece-inproduction-report",
    loadChildren: () => import("../reports/faberge-inventory/piece-inproduction-report/piece-inproduction-report.module").then(m => m.PieceInproductionReportModule),
    data : { title : "Piece Inproduction Report"}
  },
  {
    path: "finance-report",
    loadChildren: () => import("../reports/faberge-inventory/finance-report/finance-report.module").then(m => m.FinanceReportModule),
    data : { title : "Finance Report"}
  },
  {
    path: "timepieces-sales-report",
    loadChildren: () => import("../reports/faberge-inventory/timepieces-sales-report/timepieces-sales-report.module").then(m => m.TimePiecesSalesReportModule),
    data : { title : "Timepieces Sales Report"}
  },
  {
    path: "stock-by-location-analyser-report",
    loadChildren: () => import("../reports/faberge-inventory/stock-by-location-analyser-report/stock-by-location-analyser-report.module").then(m => m.StockByLocationAnalyserReportModule),
    data : { title : "Stock By Location Analyser Report"}
  },
  {
    path: "design-product-success-report",
    loadChildren: () => import("../reports/faberge-inventory/design-product-success-report/design-product-success-report.module").then(m => m.DesignProductSuccessReportModule),
    data : { title : "Design & Product Success Report"}
  },
  {
    path: "missing-product-description-report",
    loadChildren: () => import("../reports/faberge-inventory/missing-product-description/missing-product-description-report.module").then(m => m.MissingProductDescriptionReportModule),
    data : { title : "Missing Product Description"}
  },
  {
    path: "piece-owner-report",
    loadChildren: () => import("../reports/faberge-inventory/piece-owner-report/piece-owner-report.module").then(m => m.PieceOwnerReportModule),
    data : { title : "Piece Owner Report"}
  },
  {
    path: "missing-product-picture-report",
    loadChildren: () => import("../reports/faberge-inventory/missing-product-picture/missing-product-picture-report.module").then(m => m.MissingProductPictureReportModule),
    data : { title : "Missing Product Picture"}
  },
  {
    path: "inventory-summary-report",
    loadChildren: () => import("../reports/faberge-inventory/inventory-summary-report/inventory-summary-report.module").then(m => m.InventorySummaryReportModule),
    data : { title : "Inventory Summary Report"}
  },
  {
    path: "inventory-location-summary-report",
    loadChildren: () => import("../reports/faberge-inventory/inventory-location-summary-report/inventory-location-summary-report.module").then(m => m.InventoryLocationSummaryReportModule),
    data : { title : "Location Inventory Summary Report"}
  },
  {
    path: "location-inventory-summary-by-collection-report",
    loadChildren: () => import("../reports/faberge-inventory/location-inventory-summary-by-collection-report/location-inventory-summary-by-collection-report.module").then(m => m.LocationInventorySummaryByCollectionReportModule),
    data : { title : "Location Inventory Summary By Collection Report"}
  },
  {
    path: "faberge-advanced-search",
    loadChildren: () => import("../faberge-advanced-search/faberge-advanced-search.module").then(m => m.FabergeAdvancedSearchModule),
    data : { title : "Advanced Search"}
  },
  {
    path: "supplier-production-report",
    loadChildren: () => import("../reports/faberge-inventory/supplier-production-report/supplier-production-report.module").then(m => m.SupplierProductionReportModule),
    data : { title : "Supplier Production Report"}
  },
  {
    path: "price-band-option-report",
    loadChildren: () => import("../reports/faberge-inventory/price-band-option-report/price-band-option-report.module").then(m => m.PriceBandOptionReportModule),
    data : { title : "Price Band Option Report"}
  },
  {
    path: "home-layout-details",
    loadChildren: () => import("../content-management/home-layout-details/home-layout-detail.module").then(m => m.HomeLayoutDetailModule)
  },
  {
    path: "home-layouts",
    loadChildren: () => import("../content-management/home-layouts/home-layout.module").then(m => m.HomeLayoutModule),
    data : { title : "Home Page Layout"}
  },
  {
    path: "faberge-pages",
    loadChildren: () => import("../content-management/topics/topic.module").then(m => m.TopicModule),
    data : { title : "Faberge Pages"}
  },
  {
    path: "inventory-comment-report",
    loadChildren: () => import("../reports/notes-report/notes-report.module").then(m => m.NotesReportModule),
    data : { title : "Notes Report"}
  },
  {
    path: "menus",
    loadChildren: () => import("../master/main-menu-items/main-menu-item.module").then(m => m.MainMenuItemModule),
    data: { shouldReuseRoute: true, title : "Menu Layout" }
  },
  {
    path: "mines",
    loadChildren: () => import("../reports/mines/mines.module").then(m => m.MinesModule),
    data : { title : "Mines"}
  },
  {
    path: "g-i-n-a-report",
    loadChildren: () => import("../reports/g-i-n-a-report/g-i-n-a-report.module").then(m => m.GINAReportModule),
    data : { title : "Group-wide HSE/GINA Report"}
  },
  {
    path: "press-media-report",
    loadChildren: () => import("../reports/press-media-report/press-media-report.module").then(m => m.PressMediaReportModule),
    data : { title : "Press And Media Report"}
  },
  {
    path: "event-rate-mappings",
    loadChildren: () => import("../user/event-rate-mappings/event-rate-mapping.module").then(m => m.EventRateMappingModule)
  },
  {
    path: "goal-rating-mappings",
    loadChildren: () => import("../user/goal-rating-mappings/goal-rating-mapping.module").then(m => m.GoalRatingMappingModule)
  },
  {
    path: "performance-review",
    loadChildren: () => import("../user/performance-review/performance-review.module").then(m => m.PerformanceReviewModule)
  },
  {
    path: "goals",
    loadChildren: () => import("../user/goals/goal.module").then(m => m.GoalModule)
  },
  {
    path: "project-rate-mappings",
    loadChildren: () => import("../user/project-rate-mappings/project-rate-mapping.module").then(m => m.ProjectRateMappingModule)
  },
  {
    path: "i-a-n-company-customer-mappings",
    loadChildren: () => import("../master/i-a-n-company-customer-mappings/i-a-n-company-customer-mapping.module").then(m => m.IANCompanyCustomerMappingModule),
    data : { title : "IAN Notifications"}
  },
  {
    path: "personal-company-customer-mapping",
    loadChildren: () => import("../master/personal-company-customer-mapping/personal-company-customer-mapping.module").then(m => m.PersonalCompanyCustomerMappingModule),
    data : { title : "Personal Detail Access Permissions"}
  },
  {
    path: "grievance-tiers",
    loadChildren: () => import("../master/grievance-tiers/grievance-tier.module").then(m => m.GrievanceTierModule),
    data : { title : "Grievance Tier"}
  },
  {
    path: "risk-categories",
    loadChildren: () => import("../master/risk-categories/risk-category.module").then(m => m.RiskCategoryModule),
    data : { title : "Risk Categories"}
  },
  {
    path: "grievance-types",
    loadChildren: () => import("../master/grievance-types/grievance-type.module").then(m => m.GrievanceTypeModule),
    data : { title : "Grievance Type"}
  },
  {
    path: "specific-risk",
    loadChildren: () => import("../master/specific-risks/specific-risk.module").then(m => m.SpecificRiskModule),
    data : { title : "Specific Risk"}
  },
  {
    path: "agreement-type",
    loadChildren: () => import("../master/agreement-type/agreement-type.module").then(m => m.AgreementTypeModule),
    data : { title : "Agreement Type"}
  },
  {
    path: "gemfield-risk-menu",
    loadChildren: () => import("../form/risk-menu-details/risk-menu-detail.module").then(m => m.RiskMenuDetailModule),
    data : { title : "Gemfields Enterprise Risk Management System (GERMS)"}
  },
  {
    path: "gemfield-risk-notifications",
    loadChildren: () => import("../master/risk-company-customer-mappings/risk-company-customer-mapping.module").then(m => m.RiskCompanyCustomerMappingModule)
  },
  {
    path: "gemfield-sos-notifications",
    loadChildren: () => import("../master/trackme-company-user-mapping/trackme-company-user-mapping.module").then(m => m.TrackMeCompanyUserMappingModule)
  },
  {
    path: "i-t-inc-categories",
    loadChildren: () => import("../i-t-help-desk/i-t-inc-categories/i-t-inc-category.module").then(m => m.ITIncCategoryModule),
    data : { title : "IT Incident Categories"}
  },
  {
    path: "i-t-inc-types",
    loadChildren: () => import("../i-t-help-desk/i-t-inc-types/i-t-inc-type.module").then(m => m.ITIncTypeModule),
    data : { title : "IT Ticket Types"}
  },
  {
    path: "i-t-ticket-severity",
    loadChildren: () => import("../i-t-help-desk/i-t-ticket-severity/i-t-ticket-severity.module").then(m => m.ITTicketSeverityModule),
    data : { title : "IT Ticket Types"}
  },
  {
    //#292627//08-07-2024// PatersonGrade master routing added in gemfield admin
    path: "patersongrade",
    loadChildren: () => import("../master/patersongrade/patersongrade.module").then(m => m.PatersonGradeModule),
    data : { title : "Paterson Grade"}
  },
  {
    path: "occurrence-types",
    loadChildren: () => import("../i-t-help-desk/incident-types/incident-type.module").then(m => m.IncidentTypeModule),
    data : { title : "Occurrence Types"}
  },
  {
    path: "internal-directory-diagram",
    loadChildren: () => import("../master/admin-setting/admin-setting.module").then(m => m.AdminSettingModule),
    data : { title : "Internal Directory Diagram"}
  },
  {
    path: "i-t-ticket-company-customer-mappings",
    loadChildren: () => import("../i-t-help-desk/i-t-ticket-company-customer-mappings/i-t-ticket-company-customer-mapping.module").then(m => m.ITTicketCompanyCustomerMappingModule),
    data : { title : "IT Ticket Notifications"}
  },
  {
    path: "press-media-detail",
    loadChildren: () => import("../press-media/press-media-detail/press-media-detail.module").then(m => m.PressMediaDetailModule),
    data : { title : "Press And Media"}
  },
  {
    path: "legal-agreement",
    loadChildren: () => import("../form/legal-agreement/legal-agreement.module").then(m => m.LegalAgreementModule),
    data : { title : "Legal Agreements"}
  },
  {
    path: "chain-styles",
    loadChildren: () => import("../master/chain-styles/chain-style.module").then(m => m.ChainStyleModule)
  },
  {
    path: "chain-gauges",
    loadChildren: () => import("../master/chain-gauges/chain-gauge.module").then(m => m.ChainGaugeModule)
  },
  {
    path: "faberge-localization",
    loadChildren: () => import("../master/faberge-localization/faberge-localization.module").then(m => m.FabergeLocalizationModule),
    data : { title : "Faberge Localisation"}
  },
  {
    path: "production-order-forms",
    loadChildren: () => import("../form/production-order-forms/production-order-form.module").then(m => m.ProductionOrderFormModule),
    data : { title : "Faberge Production Order Form"}
  },
  {
    path: "i-t-ticket-customer-corporate-mappings",
    loadChildren: () => import("../i-t-help-desk/i-t-ticket-customer-corporate-mappings/i-t-ticket-customer-corporate-mapping.module").then(m => m.ITTicketCustomerCorporateMappingModule)
  },
  {
    path: "main-seo-details",
    loadChildren: () => import("../master/main-seo-details/main-seo-detail.module").then(m => m.MainSeoDetailModule)
  },
  {
    path: "forms-document",
    loadChildren: () => import("../master/forms-document/forms-document.module").then(m => m.FormsDocumentModule)
  },
  {
    path: "seo-redirections",
    loadChildren: () => import("../master/seo-redirections/seo-redirections.module").then(m => m.SeoRedirectionsModule),
    data : { title : "SEO Redirections"}
  },
  {
    path: "product-types",
    loadChildren: () => import("../master/product-types/product-type.module").then(m => m.ProductTypeModule),
    data : { title : "Product Types"}
  },
  {
    path:"training-types",
    loadChildren : ()=>import("../master/training-type/training-type.module").then(m=>m.TrainingTypeModule),
    data : { title : "Training Types"}
  },
  {
    path: "under-maintenance",
    component: UnderMaintenanceComponent
  },
  {
    path: "g-i-n-a-company-customer-mappings",
    loadChildren: () => import("../form/g-i-n-a-company-customer-mappings/g-i-n-a-company-customer-mapping.module").then(m => m.GINACompanyCustomerMappingModule)
  },
  {
    path: "i-t-help-desk-weekly-report",
    loadChildren: () => import("../reports/i-t-help-desk/i-t-help-desk-weekly-report/i-t-help-desk-weekly-report.module").then(m => m.ITHelpDeskWeeklyReportModule)
  },
  {
    path: "electronic-gift-forms",
    loadChildren: () => import("../form/electronic-gift-forms/electronic-gift-form.module").then(m => m.ElectronicGiftFormModule),
    data : { title : "Electronic Gift Form"}
  },
  {
    path: "goals-notifications",
    loadChildren: () => import("../master/goals-company-customer-mapping/goals-company-customer-mapping.module").then(m => m.GoalsCompanyCustomerMappingModule),
    data : { title : "Goals Notifications"}
  },
  {
    path: "corporate-credit-ratings",
    loadChildren: () => import("../master/corporate-credit-ratings/corporate-credit-rating.module").then(m => m.CorporateCreditRatingModule),
    data : { title : "Corporate Credit Rating"}
  },
  {
    path: "leave-notifications",
    loadChildren: () => import("../master/leave-company-customer-mappings/leave-company-customer-mapping.module").then(a=>a.LeaveCompanyCustomerMappingModule),
    data : { title : "Leave Notifications"}
  },
  {
    path: "regions",
    loadChildren: () => import("../master/regions/region.module").then(a=>a.RegionModule),
    data : { title : "Regions"}
  },
  {
    path: "mind-map",
    loadChildren: () => import("../mind-map/mind-map.module").then(a=>a.MindMapModule),
    data : { title : "Visual Mind Map"}
  },
  {
    path: "treatment",
    loadChildren: () => import("../master/gemfields-treatment/gemfields-treatment.module").then(m => m.GemfieldsTreatmentModule),
    data : { title : "Treatment"}
  },
  {
    path:"file-extentions",
    loadChildren:() => import("../master/file-extentions/file-extentions.module").then(m=>m.FileExtentionsModule),
    data : { title : "File Extentions"}
  },
  {
    path:"application-object",
    loadChildren:() => import("../master/application-objects/application-objects.module").then(m=>m.ApplicationObjectModule),
    data : { title : "Application Object"}
  },
  {
    path: "repair-report",
    loadChildren: () => import("../reports/faberge-inventory/repair-report/repair-report.module").then(m => m.RepairReportModule),
    data : { title : "Repair Report"}
  },
  {
    path: "inventory-history-report",
    loadChildren: () => import("../reports/faberge-inventory/inventory-history-report/inventory-history-report.module").then(m => m.InventoryHistoryReportModule),
    data : { title : "Inventory History Report"}
  },
  {
    path: "delivery-types",
    loadChildren: () => import("../master/delivery-type/delivery-type.module").then(m => m.DeliveryTypeModule),
    data : { title : "Delivery Type"}
  },
  {
    path: "legal-agreement-access-permissions",
    loadChildren: () => import("../master/legal-agreement-access-permissions/legal-agreement-access-permission.module").then(m => m.LegalAgreementAccessPermissionModule),
    data : { title : "Legal Agreement Access Permissions"}
  },
  {
    path: "additional-group-company-user-mappings",
    loadChildren: () => import("../master/additional-group-company-user-mappings/additional-group-company-user-mapping.module").then(m => m.AdditionalGroupCompanyUsersMappingModule),
    data : { title : "Additional Group Company User Mappings"}
  },
  {
    path:"production-order-form-user-department-mappings",
    loadChildren: () => import("../master/production-order-form-user-department-mappings/production-order-form-user-department-mapping.module").then(m => m.productionOrderFormUserDepartmentMappingModule),
    data : { title : "Production Order From User Department Mappings"}
  },
  {
    path: "grievance-forms",
    loadChildren: () => import("../form/track-incident-raw-forms/track-incident-raw-form.module").then(m => m.TrackIncidentRawFormModule),
    data : { title : "Grievance Forms"}
  },
  {
    path: "grievance-form/add",
    loadChildren: () => import("../form/track-incident-raw-forms/add/track-incident-raw-form-add.module").then(m=>m.TrackIncidentRawFormAddModule)
  },
  {
    path: 'purchase-order-update',
    loadChildren: () => import("../inventory/product-batch/purchase-order-link-response/purchase-order-link-response.module").then(m=>m.PurchaseOrderLinkResponseModule)
  },
  {
    path: 'purchase-order-download',
    loadChildren: () => import("../inventory/product-batch/purchase-order-download/purchase-order-download.module").then(m=>m.PurchaseOrderDownloadModule)
  },
  {
    path: "colors",
    loadChildren: () => import("../master/colors/color.module").then(m => m.ColorModule),
    data : { title : "Color"}
  },
  {
    path: "goals-objectives-report",
    loadChildren: () => import("../reports/people/goal-objectives-report/goal-objectives-report.module").then(m => m.GoalObjectivesReportModule),
    data : { title : "Goals/Objectives Report"}
  },
  {
    path:"duplicate-customer",
    loadChildren: () => import("../master/duplicate-customer/duplicate-customer.module").then(m => m.DuplicateCustomerModule),
    data : { title : "Duplicate Customer"}
  },
  {
    path: 'download-content', component: DownloadPageComponent,
    data : { title : "Download"}, canActivate: [BaseCanActivate]
  },
  {
    path: 'download-verification',
    loadChildren : () => import("../shared/download-verification/download-verification.module").then(m=>m.DownloadVerificationModule)
  },
  {
    path: 'download-verified-content', component: DownloadPageComponent,
    data : { title : "Download"}
  },
  {
    path: "powerbireports",
    loadChildren: () => import("../reports/powerBI-reports/powerbi-report.module").then(m => m.PowerBIReportModule),
    data : { title : "GTrac Power BI Report"}
  },
  {
    path: "customer-stock-notification-report",
    loadChildren: () => import("../reports/faberge-inventory/customer-stock-notification-report/customer-stock-notificationreport.module").then(m => m.CustomerStockRequestReportModule),
    data : { title : "Inventory History Report"}
  },
  {
    path:"api-keys",
    loadChildren: () => import("../master/api-key-manager/api-key-manager.module").then(m => m.ApiKeyManagerModule),
    data : { title : "ApiKeys manager"}
  },


  { path: '**', redirectTo: '/dashboard', data : { title : "Dashboard" } },
];

export const ROUTING: ModuleWithProviders<any> = RouterModule.forRoot(ROUTES, {
  // Don't perform initial navigation in iframes or popups
  initialNavigation:
    !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
      ? "enabledNonBlocking"
      : "disabled", // Set to enabledBlocking to use Angular Universal
});
