import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCompanyLookupBase {

//#region company Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'company', keyColumn: false})
        company : string;
//#endregion company Prop

// //#region company Prop
// @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'supplierId', keyColumn: false})
// upplierId : number;
// //#endregion company Prop


}