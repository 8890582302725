import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vNoteLookUpBase {

//#region noteId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'noteId', keyColumn: true})
        noteId : number;
//#endregion noteId Prop


//#region noteName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'noteName', keyColumn: false})
        noteName : string;
//#endregion noteName Prop

}