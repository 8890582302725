import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AuditRecordBase {

//#region auditRecordId Prop
        @prop()
        auditRecordId : number;
//#endregion auditRecordId Prop


//#region auditRequestId Prop
        @prop()
        auditRequestId : number;
//#endregion auditRequestId Prop


//#region eventType Prop
        @required()
        @maxLength({value:9})
        eventType : string;
//#endregion eventType Prop


//#region tableName Prop
        @required()
        @maxLength({value:50})
        tableName : string;
//#endregion tableName Prop


//#region recordId Prop
        @required()
        @maxLength({value:100})
        recordId : string;
//#endregion recordId Prop


//#region recordName Prop
        @required()
        recordName : string;
//#endregion recordName Prop





}