import { prop, required, notEmpty, trim } from "@rxweb/reactive-form-validators";
import { StatusEnum } from "src/app/enums/status.enum";

export class LegalAgreementBase {

    //#region legalAgreementId Prop
    @prop({ defaultValue: 0 })
    legalAgreementId: number;
    //#endregion legalAgreementId Prop

    //#region name Prop
    @prop()
    @required()
    @notEmpty()
    @trim()
    name: string;
    //#endregion name Prop

    //#region summary Prop
    @prop()
    @required()
    @notEmpty()
    @trim()
    summary: string;
    //#endregion name Prop

    //#region type Prop
    @prop()
    @required()
    type: number;
    //#endregion type Prop

    //#region date Prop
    @prop()
    @required()
    date: Date;
    //#endregion date Prop

    //#region leader Prop
    @prop()
    @required()
    leader: number;
    //#endregion leader Prop

    //#region signedAgreement Prop
    @prop()
    signedAgreement: string;
    //#endregion signedAgreement Prop

    //#region executionVersion Prop
    @prop()
    executionVersion: string;
    //#endregion executionVersion Prop

    //#region date Prop
    @prop()
    expiryDate: Date;
    //#endregion date Prop

    //#region isDeleted Prop
    @prop({defaultValue: false})
    isDeleted: boolean;
    //#endregion isDeleted Prop

    //#region createdOn Prop
    @prop()
    @required()
    createdOn: Date;
    //#endregion createdOn Prop

    //#region createdBy Prop
    @prop()
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region modifiedOn Prop
    @prop()
    modifiedOn: Date;
    //#endregion modifiedOn Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region isSigned Prop
    @prop({defaultValue: false})
    isSigned: boolean;
    //#endregion isSigned Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    @required()
    statusId: number;
    //#endregion statusId Prop

}