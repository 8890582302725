import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { BrowserModule, Title } from '@angular/platform-browser'; 
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { MeasureDimension } from '@app/models';
import { AbstractMeasureDimension } from '../domain/abstract-measure-dimension';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { MeasureDimensionEditComponent } from '../edit/measure-dimension-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@multilingual("MeasureDimensionAddComponent")
@access({ accessLevel: RolePermissionEnums.MeasureDimensions, action: "post" })

@Component({
    selector: "app-measure-dimension-add",
    templateUrl: './measure-dimension-add.component.html'
})
export class MeasureDimensionAddComponent extends AbstractMeasureDimension implements OnInit, OnDestroy {
    measureDimension: MeasureDimension;
    subscription: any;
    measureDimensionEdit:any=MeasureDimensionEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder,private router:Router, modelView: ModalView, private el:ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.MeasureDimensions;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.titleHelp = getLocalizedValue("Title_Help");
        this.title.setTitle("Measure Dimensions - G-Trac Admin");
        this.measureDimension = new MeasureDimension();
        this.measureDimension.measureDimensionId=0;
        this.measureDimension.statusId=StatusEnum.Active;
        this.measureDimensionFormGroup = this.formBuilder.formGroup(this.measureDimension) as IFormGroup<MeasureDimension>;
        this.measureDimensionFormGroup.controls.ratio.setValidators([RxwebValidators.required({message:"You can't leave this field empty"}),
        RxwebValidators.pattern({expression:{'ratio':/^[0-9]{0,5}[.]{0,1}[0-9]{0,2}$/}, message:'Please enter valid rate(5 digits and 2 decimal places allowed)'})]);
        this.showComponent=true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addMeasureDimension(isRedirect: boolean) {
        this.measureDimensionFormGroup.submitted = true;
        if (this.measureDimensionFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.measureDimensionFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/measure-dimensions', data]) : this.router.navigate(['/measure-dimensions']);
            })
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.measureDimensionFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }
    get componentName(): string {
        return "MeasureDimensionAddComponent";
      }
}
