import { http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { CustomerHoliday, vLeaveTypeLookUp } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent, } from '@rxweb/angular-router';
import { vWeekDayLookUp } from 'src/app/models/extended-models/v-week-day-look-up';
import { HolidayInfoViewModel } from 'src/app/view-model/holiday-info-view-model';

@http({
    path: "api/CustomerHolidays",
})

export class AbstractCustomerHoliday extends CoreComponent {
    customerHolidayFormGroup: IFormGroup<CustomerHoliday>;
    holidayProcessListFormGroup: IFormGroup<HolidayInfoViewModel>;
    showComponentHoliday: boolean = false;
    showComponentHolidayList: boolean = false;
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    userId: number = 0;
    vLookUpYear: number[];
    vYearLookUp: any[];
    choises: any[] = [{id: 0, choise: "No"},{id: 1, choise: "Yes"}]

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.vLookUpYear = new Array;
        this.vYearLookUp = new Array;
        this.bindYearLookup();
        this.bindAddHolidayYearLookup();
    }

    holidayProcessLookups: any = {
        leaveTypeLookUp: vLeaveTypeLookUp,
        weekDaysLookUp: vWeekDayLookUp,
        customerHoliday: CustomerHoliday
    }

    bindYearLookup() {
        for (let i = -2; i <= 2; i++) {
            this.vLookUpYear.push(new Date().getFullYear() + i);
        }
    }
    bindAddHolidayYearLookup()
    {
      for (let i = -1; i < 6; i++) {
        this.vYearLookUp.push({id: (new Date().getFullYear() + i), year: (new Date().getFullYear() + i)});
      }
    }
    // bindAddHolidayYearLookup()
    // {
    //   for (let i = 0; i < 6; i++) {
    //     this.vYearLookUp.push({id: (new Date().getFullYear() + i), year: (new Date().getFullYear() + i)});
    //   }
    // }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
