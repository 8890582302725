import {CallMeBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class CallMe extends CallMeBase 
{

    @prop()
    productName: string;

    @prop()
    entityTypeId: number;

//#region Generated Reference Properties

//#endregion Generated Reference Properties




}