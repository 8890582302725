import {TemporaryAssigneeTypeBase,TemporaryAssigneeBase,} from '@app/database-models'
//Generated Imports
export class TemporaryAssigneeType extends TemporaryAssigneeTypeBase 
{




//#region Generated Reference Properties
//#region temporaryAssignees Prop
temporaryAssignees : TemporaryAssigneeBase[];
//#endregion temporaryAssignees Prop

//#endregion Generated Reference Properties






































}