import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRingEggSizeRecordBase {

//#region sizeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'sizeId', keyColumn: true})
        sizeId : number;
//#endregion sizeId Prop


//#region size Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'size', keyColumn: false})
        size : number;
//#endregion size Prop


//#region sizeType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'sizeType', keyColumn: false})
        sizeType : number;
//#endregion sizeType Prop

}