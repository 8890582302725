import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TitleBase {

//#region titleId Prop
        @prop()
        titleId : number;
//#endregion titleId Prop


//#region titleName Prop
        @required()
        @maxLength({value:100})
        @notEmpty()
        @trim()
        //@alpha({allowWhiteSpace:true})
        titleName : string;
//#endregion titleName Prop


//#region genderId Prop
        @prop()
        genderId : number;
//#endregion genderId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region parentTitleId Prop
        @prop()
        parentTitleId : number;
//#endregion parentTitleId Prop

}