import {vGemstoneArrangementsGemfieldBase,} from '@app/database-models'
//Generated Imports
export class vGemstoneArrangementsGemfield extends vGemstoneArrangementsGemfieldBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}