import { numeric, notEmpty, required, prop } from '@rxweb/reactive-form-validators';
import { CLIENT_URL } from '../domain/system-constant';

export class RelatedDesignSearchModel{
    @prop()
    category:number;
    
    @prop({defaultValue:25})
    rowCount:number;

    @prop({defaultValue:'productName'}) 
    orderBy:string;

    @prop({defaultValue:false})
    sortOrder:boolean;

    @prop({defaultValue:1})
    pageIndex:number;

    @prop()
    title:string;

    // @prop({defaultValue:CLIENT_URL})
    // clientUrl:string;

    @prop()
    searchString:string;

    @prop({defaultValue:'true'})
    isSearch:string;

    @prop()
    designId:number;
}