import {SpecificationAttributeOptionLocalizationBase,LanguageBase,SpecificationAttributeOptionBase,} from '@app/database-models'
//Generated Imports
export class SpecificationAttributeOptionLocalization extends SpecificationAttributeOptionLocalizationBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop
//#region specificationAttributeOption Prop
specificationAttributeOption : SpecificationAttributeOptionBase;
//#endregion specificationAttributeOption Prop

//#endregion Generated Reference Properties






































}