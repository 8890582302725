import { prop,propObject,propArray,required,maxLength,range, notEmpty, numeric  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductDesignPictureBase {

//#region productPictureID Prop
        @prop()
        productPictureID : number;
//#endregion productPictureID Prop


//#region productID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID : number;
//#endregion productID Prop


//#region pictureID Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
       // @required()
       @prop()
        pictureID : number;
//#endregion pictureID Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @numeric({allowDecimal:false})
        @notEmpty()
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}