import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractQueuedEmail } from '../domain/abstract-queued-email';
import { QueuedEmail } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { QueuedEmailFilterViewModel } from 'src/app/view-model/queued-emails-filter-view-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { QueuedEmailViewModel } from 'src/app/view-model/queued-email-view-model';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { PaginationEnum } from 'src/app/enums/pagination.enums';

import { dateCompareValidation, appendPageFooter, removeScrollableFooter, getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { debounceTime } from 'rxjs/operators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("QueuedEmailListComponent")
@access({ accessLevel: RolePermissionEnums.MessageQueue, action: "get" })


@Component({
  selector: "app-queued-email-list",
  templateUrl: './queued-email-list.component.html'
})
export class QueuedEmailListComponent extends AbstractQueuedEmail implements OnInit, OnDestroy {
  queuedEmails: List<QueuedEmail>;
  subscription: any;
  queuedemails: QueuedEmailViewModel[];
  queuedemail: QueuedEmailFilterViewModel;
  queuedEmailSearchFormGroup: IFormGroup<QueuedEmailFilterViewModel>;
  queuedEmailListGrid: AppGrid;
  queuedEmailListGridColumns: any[];
  queuedEmailIds: number[] = [];
  id: any;
  rowCount: number = 25;
  pageIndex: number = 1;
  sortOrder: string = "false";
  isFirstTime: boolean = true;
  dateError: boolean;
  orderByColumn: string = "queuedEmailId";
  isFilter: boolean = false;
  totalRecords: number = 0;
  json: any;
  cloneArray: QueuedEmailViewModel[];
  headerBtnChk: boolean = false;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  queueEmailDelete: any = dbAccessModule[RolePermissionEnums.MessageQueue]["delete"];
  private queueEmailSubject: Subject<string> = new Subject<string>();
  moduleID:number;
  titleHelp:string;
  showSystemHelpIcon: boolean = false;

  constructor(private title: Title, private activatedRoute: ActivatedRoute, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private formBuilder: RxFormBuilder, private location : PlatformLocation) {
    super();
    this.modalView = modelView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.activatedRoute.queryParams.subscribe(t => {
      this.noRecordFound = t['recordState'];
    })
    this.moduleID = RolePermissionEnums.MessageQueue;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Message Queue - G-Trac Admin");
    this.queuedemail = new QueuedEmailFilterViewModel();
    this.cloneArray = new Array<QueuedEmailViewModel>();
    this.queuedEmailSearchFormGroup = this.formBuilder.formGroup(this.queuedemail) as IFormGroup<QueuedEmailFilterViewModel>;
    this.showComponent = true;
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    this.titleHelp = getLocalizedValue("Title_Help");

    this.queuedEmailSearchFormGroup.patchValue({
      rowCount: PaginationEnum.RowCount,
      //pageIndex:PaginationEnum.PageIndex,
      sortOrder: this.sortOrder,
      orderByColumn: this.orderByColumn
    });

    this.queueEmailSubject.pipe(debounceTime(300)).subscribe(t => {
      this.searchQueuedEmail();
    })

  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  onKeyPress() {
    this.queueEmailSubject.next();
  }

  searchQueuedEmail() {
    this.queuedEmailSearchFormGroup.submitted = true;
    this.headerBtnChk = false;
    this.cloneArray = [];
    if (this.queuedEmailSearchFormGroup.valid) {
      this.queuedEmailSearchFormGroup.patchValue({
        queuedEmailId: null
      });
      if (dateCompareValidation(this.queuedEmailSearchFormGroup.value.startDate, this.queuedEmailSearchFormGroup.value.endDate) == true) {
        this.dateError = true;
      }
      else {
        this.dateError = false;
        if (this.queuedEmailListGrid != null) {
          this.totalRecords = 0;
          this.queuedEmailListGrid.updateSource([]);
        }
        this.queuedEmailSearchFormGroup.patchValue({ pageIndex: 1 });
        this.bindGrid();
      }
    }
  }

  bindGrid() {

    this.spin = true;
    if (this.queuedEmailListGrid != null) {
      this.isFilter = true;
    }
    this.subscription = this.get(
      {
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(this.queuedEmailSearchFormGroup.value)),
          "timezoneOffset": new Date().getTimezoneOffset()
        }
      }).subscribe((t: any) => {
        this.spin = false;
        this.queuedemails = t;
        this.noRecordFound = this.queuedemails.length > 0 ? false : true;
        if (!this.noRecordFound) {
          if (this.queuedemails.length > 0) {
            this.queuedemails.forEach(x => {
              x.ischecked = this.headerBtnChk;
            })

            this.queuedemails.forEach(x => {
              if (this.cloneArray.findIndex(z => z.queuedEmailId == x.queuedEmailId) == -1) {
                this.cloneArray.push(x);
              }
              else {
                this.cloneArray.forEach(z => {
                  if (z.queuedEmailId == x.queuedEmailId) {
                    x.ischecked = z.ischecked
                  }
                })
              }

              if(this.queuedEmailIds.indexOf(x.queuedEmailId) != -1) {
                x.ischecked = true;
              }
            })
            this.updateMailArray();
            this.totalRecords = this.queuedemails[0].totalCount;
            console.log(this.queuedemails[0].totalCount);
          }

          if (!this.isFilter) {
            this.queuedEmailListGrid = new AppGrid(this.queuedemails, QueuedEmailViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
            this.queuedEmailListGrid.gridColumns.forEach(x => {
              if (this.queuedEmailListGrid.authorize({ accessLevel: RolePermissionEnums.MessageQueue, action: "put" }) && this.queuedEmailListGrid.authorize({ accessLevel: RolePermissionEnums.MessageQueue, action: "delete" })) {
                if (x.columnIndex == 13) {
                  x.visible = true;
                }
                else {
                  if (x.columnIndex == 13) {
                    x.visible = false;
                  }
                  if (x.name == "editAction") {
                    x.visible = this.queuedEmailListGrid.authorize({ accessLevel: RolePermissionEnums.MessageQueue, action: "put" });
                  }
                  if (x.name == "action") {
                    x.visible = this.queuedEmailListGrid.authorize({ accessLevel: RolePermissionEnums.MessageQueue, action: "delete" });
                  }
                  if (x.name == "ischecked") {
                    x.visible = this.queuedEmailListGrid.authorize({ accessLevel: RolePermissionEnums.MessageQueue, action: "delete" });
                  }
                  if (x.name == "deleteAction") {
                    x.visible = this.queuedEmailListGrid.authorize({ accessLevel: RolePermissionEnums.MessageQueue, action: "delete" })
                  }
                }
              }
            })

            this.queuedEmailListGrid.storeProcedure = {
              length: this.totalRecords,
              onPageChanging: this.onPageChanging.bind(this),
              nextPage: 1,
              onPageSorting: this.onPageSorting.bind(this)
            }

          }
          else {
            // this.queuedEmailListGrid.storeProcedure.length = this.totalRecords;
            // this.queuedEmailListGrid.updateSource(this.queuedemails)
            if (this.queuedEmailListGrid != null) {
              this.queuedEmailListGrid.storeProcedure.length = this.totalRecords;
              if (this.queuedEmailSearchFormGroup.value.pageIndex == 1) {
                this.queuedEmailListGrid.storeProcedure.nextPage = 1;
              }
              this.queuedEmailListGrid.updateSource([]);
              this.queuedEmailListGrid.updateSource(this.queuedemails);
            }
          }
          this.queuedEmailListGrid.maxPerPage = this.queuedEmailSearchFormGroup.value.rowCount;
          this.queuedEmailListGrid.componentId = this.componentName;
          this.queuedEmailListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.queuedEmailListGrid.designClass.headerClass = "table-header-sticky".split(" ");
          setTimeout(() => {
            if (!this.noRecordFound) {
              if (!this.isFilter) {
                appendPageFooter("messageQueueTable");
              }
              else {
                removeScrollableFooter("queuedEmailListGrid");
              }
            }

          }, 400);
        } else {
          if (this.queuedEmailListGrid != null) {
            this.queuedEmailListGrid.updateSource([]);
            this.queuedEmailListGrid = null;
            this.isFilter = false;
          }
        }
      })
  }

  onHeaderCheckBoxSelect(t, x) {
    this.headerBtnChk = x.target.checked;
    // this.cloneArray.forEach(y=>{
    //    y.ischecked=this.headerBtnChk;
    // })
    this.cloneArray = new Array<QueuedEmailViewModel>();
    this.queuedemails.forEach(data => {
      let newObj = new QueuedEmailViewModel();
      newObj.body = data.body;
      newObj.createdOn = data.createdOn;
      newObj.emailAccountId = data.emailAccountId;
      newObj.emailFrom = data.emailFrom;
      newObj.emailTo = data.emailTo;
      newObj.ischecked = this.headerBtnChk;
      newObj.priority = data.priority;
      newObj.queuedEmailId = data.queuedEmailId;
      newObj.sendTries = data.sendTries;
      newObj.sentOn = data.sentOn;
      newObj.subject = data.subject;
      newObj.totalCount = data.totalCount;
      newObj.templateId = data.templateId;
      this.cloneArray.push(newObj);
    });

    this.updateMailArray();
    this.queuedEmailListGrid.updateSource([]);
    this.queuedEmailListGrid.updateSource(this.cloneArray);
  }

  onEdit(queue: QueuedEmailViewModel) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/queued-emails', queue.queuedEmailId]));
        redirectOnCTRL(url,this.router,event);
    //this.router.navigate(['/queued-emails', queue.queuedEmailId]);
  }

  onDelete(queue: QueuedEmailViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [queue.queuedEmailId] }).subscribe(data => {
          this.spin = false;
          this.totalRecords = 0;
          // this.usersGrid.updateSource([]);
          this.isFilter = true;
          // this.usersGrid.refresh("users-Grid");
          this.bindGrid();
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert[0].showMessage);
          }
        })
      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Message_Queue_Email");
    if (existsAlert) {
      return existsAlert[0].showMessage;
    }
  }

  DeleteConfirmationMessageSelectedMails() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Message_Queue_Emails");
    if (existsAlert) {
      return existsAlert[0].showMessage;
    }
  }
  goDirectlyToEmail() {
    this.queuedEmailSearchFormGroup.submitted = true;
    if (this.queuedEmailSearchFormGroup.controls.queuedEmailId.valid) {
      this.id = this.queuedEmailSearchFormGroup.controls.queuedEmailId.value;
      this.router.navigate(['/queued-emails', this.id]);
      this.noRecordFound = true;
    }
  }

  updateMailArray() {
    this.cloneArray.forEach(x => {
      if (x.ischecked) {
        if (!this.queuedEmailIds.includes(x.queuedEmailId)) {
          this.queuedEmailIds.push(x.queuedEmailId);
        }
      }
      else {
        if (this.queuedEmailIds.includes(x.queuedEmailId)) {
          this.queuedEmailIds.splice(this.queuedEmailIds.indexOf(x.queuedEmailId), 1);
        }
      }
    })
  }
  onCheckboxSelect(t, x) {
    this.cloneArray.forEach(z => {
      if (z.queuedEmailId == t.queuedEmailId) {
        z.ischecked = x.target.checked;
      }
    })
    this.updateMailArray();
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  deleteSelected() {
    this.dialog.show(this.DeleteConfirmationMessageSelectedMails(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [0], queryParams: { "query": JSON.stringify(this.queuedEmailIds) } }).subscribe(data => {
          this.spin = false;
          this.queuedEmailIds.forEach(element => {

            //   this.queuedEmailListGrid.remove(element);

          });
          this.isFilter = true;
          this.queuedEmailListGrid.updateSource([]);
          this.bindGrid();
          this.toastr.success("Data Deleted Successfully");
          this.queuedEmailIds = [];
          this.cloneArray = [];
          this.headerBtnChk = false;
        })
      }
      this.dialog.hide()
    })
  }

  onPageSorting(x, y, z) {
    this.queuedEmailSearchFormGroup.patchValue({
      sortOrder: y,
      orderByColumn: x
    });
    this.isFilter = true;
    this.cloneArray = [];
    this.queuedEmailListGrid.updateSource([]);
    this.queuedEmailListGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x, y, z) {
    this.isFilter = true;
    let fromIndex: number = this.queuedEmailSearchFormGroup.value.rowCount;
    let toIndex: number = this.queuedEmailListGrid.maxPerPage;
    if (this.queuedEmailSearchFormGroup.value.pageIndex < x) {
      this.cloneArray = [];
    }
    if (this.queuedEmailListGrid.maxPerPage < this.queuedEmailSearchFormGroup.value.rowCount) {
      for (let i = fromIndex; i > toIndex; i--) {
        this.cloneArray.splice(i - 1, 1);
      }
    }
    this.queuedEmailSearchFormGroup.patchValue({
      rowCount: this.queuedEmailListGrid.maxPerPage,
      pageIndex: x
    });
    this.queuedEmailListGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }
  get componentName(): string {
    return "QueuedEmailListComponent";
  }
}
