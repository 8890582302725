import {GemstoneStationGemFieldBase,} from '@app/database-models'
//Generated Imports
export class GemstoneStationGemField extends GemstoneStationGemFieldBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}