import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSEventDataLookUpBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventData Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventData', keyColumn: false})
        eventData : string;
//#endregion eventData Prop

}