import { prop, propArray } from '@rxweb/reactive-form-validators';
import { GoalDetailViewModel } from 'src/app/view-model/goal-detail-view-model';
import { GoalBase } from '../database-models/goal-base';
import { GoalRatingMappingBase } from '../database-models/goal-rating-mapping-base';

export class Goal extends GoalBase {


    //#region homeLayoutDetails Prop
    @propArray(GoalDetailViewModel)
    listGoalDetailViewModel: GoalDetailViewModel[];
    //#endregion homeLayoutDetails Prop


    //#region GoalRatingMappings Prop
    @propArray(GoalRatingMappingBase)
    GoalRatingMappings: GoalRatingMappingBase[];
    //#endregion GoalRatingMappings Prop

    //#region submitType Prop
    @prop()
    submitType: string;
    //#endregion submitType Prop

    goalIds: Goal[];
    selectedUserId: number;

    @prop()
    objective: string;
    selectedYear: number;
    query: string;
    isRatingMailSent: boolean;
    lineMangerRatingId: number;
    userRatingId: number;   

    @prop()
    lineManagerRatingId:number;

    @prop({ defaultValue: false })
    isFromRatingPage: boolean;

    @prop()
    lineManagerFeedback:string;

    @prop()
    lineManagerRatings:any;
}