import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ITTicketNotificationNoteBase {

//#region iTTicketNotificationNoteId Prop
        @prop()
        iTTicketNotificationNoteId : number;
//#endregion iTTicketNotificationNoteId Prop


//#region iTTicketCompanyCustomerMappingId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        iTTicketCompanyCustomerMappingId : number;
//#endregion iTTicketCompanyCustomerMappingId Prop


//#region note Prop
        @required()
        note : string;
//#endregion note Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop

}