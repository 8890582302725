import { gridColumn } from '@rxweb/grid';

export class vBoardRiskOwnerLookUpBase{

    //#region boardRiskOwner Prop
    @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'boardRiskOwner', keyColumn: true})
    boardRiskOwner : number;
    //#endregion boardRiskOwner Prop


    //#region fullName Prop
    @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'fullName', keyColumn: false})
    fullName : string;
    //#endregion fullName Prop
}