import {vObjectTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vObjectTypeLookUp extends vObjectTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}