import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { MeasureWeight } from '@app/models';
import { AbstractMeasureWeight } from '../domain/abstract-measure-weight';
import { StatusEnum } from '../../../../enums/status.enum';
import { BrowserModule, Title } from '@angular/platform-browser'; 
import { Router } from "@angular/router";
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { MeasureWeightEditComponent } from '../edit/measure-weight-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("MeasureWeightAddComponent")
@access({ accessLevel: RolePermissionEnums.MeasureWeights, action: "post" })

@Component({
    selector: "app-measure-weight-add",
    templateUrl: './measure-weight-add.component.html'
})
export class MeasureWeightAddComponent extends AbstractMeasureWeight implements OnInit, OnDestroy {
    measureWeight: MeasureWeight;
    subscription: any;
    measureWeightEdit:any=MeasureWeightEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder,private router:Router, modelView: ModalView, private el:ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.MeasureWeights;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.titleHelp = getLocalizedValue("Title_Help");
        this.title.setTitle("Measure Weights - G-Trac Admin");
        this.measureWeight = new MeasureWeight();
        this.measureWeight.measureWeightId=0;
        this.measureWeight.statusId=StatusEnum.Active;
        this.measureWeight.ratioText="1.00";
        this.measureWeightFormGroup = this.formBuilder.formGroup(this.measureWeight) as IFormGroup<MeasureWeight>;
        this.measureWeightFormGroup.controls.ratioText.setValidators([RxwebValidators.required({message:"You can't leave this field empty"}),
        RxwebValidators.pattern({expression:{'ratioText':/^[0-9]{0,5}[.]{0,1}[0-9]{0,2}$/}, message:'Please enter valid rate(5 digits and 2 decimal places)'})]);
        this.showComponent=true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addMeasureWeight(isRedirect:boolean) {
        this.measureWeightFormGroup.submitted = true;
        if(this.measureWeightFormGroup.valid)
        {
            this.spin=true;
            let ratio:number;
            ratio = Number(this.measureWeightFormGroup.value.ratioText);
            this.measureWeightFormGroup.patchValue({
                ratio:ratio
            })
            this.post({ body: this.measureWeightFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect?this.router.navigate(['/measure-weights',data]):this.router.navigate(['/measure-weights']);

            })
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.measureWeightFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
        
    }
    get componentName(): string {
        return "MeasureWeightAddComponent";
    }
}
