import {ProductAttributeMappingBase,ProductAttributeBase,ProductBase,ProductAttributeValueBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ProductAttributeMapping extends ProductAttributeMappingBase 
{


@prop()
productAttributeValueName:string;
//#region Generated Reference Properties
//#region productAttribute Prop
productAttribute : ProductAttributeBase;
//#endregion productAttribute Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop
//#region productAttributeValues Prop
productAttributeValues : ProductAttributeValueBase[];
//#endregion productAttributeValues Prop

//#endregion Generated Reference Properties




}