import {vSalesAdvisorLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSalesAdvisorLookUp extends vSalesAdvisorLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}