import {vCustomerRelationshipLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCustomerRelationshipLookUp extends vCustomerRelationshipLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}