<!-- <div class="i-checks" *ngIf="showComponent"> -->
<div class="i-checks">
  <label class="mb-0">
    <div class="icheckbox_square-green" [ngClass]="{'icheckbox_square-gray': isDisabled}" [class.checked]="isChecked" [ngStyle]="{'cursor':isDisabled ? 'not-allowed': 'pointer'}"
      style="position: relative;">
      <input id="{{id}}" [value]="checkFormControl.value" (focus)="onfocus($event)" (keyup.space)="onSpace($event)" [ngStyle]="{'cursor':isDisabled ? 'not-allowed': 'pointer'}"
        (focusout)="onfocusout($event)" (click)="click($event)" tabindex="{{tabIndex}}"
        checked="checkFormControl.value == 'true'" type="checkbox"
        style="position: absolute;opacity: 0; cursor:pointer;" [formControl]="checkFormControl"
        (change)="onChange($event.target.checked)">
    </div><i></i>
  </label>
</div>
