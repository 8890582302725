import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductGemstoneCertificateBase {

        //#region gemStoneCertificateID Prop
        @prop({ defaultValue: 0 })
        gemStoneCertificateID: number;
        //#endregion gemStoneCertificateID Prop


        //#region gemStoneID Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        gemStoneID: number;
        //#endregion gemStoneID Prop


        //#region fileName Prop
        @required()
        @maxLength({ value: 255 })
        fileName: string;
        //#endregion fileName Prop


        //#region createdOn Prop
        @required()
        @prop({ defaultValue: new Date() })
        createdOn: Date;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @prop({ defaultValue: 0 })
        createdBy: number;
        //#endregion createdBy Prop


        //#region certificateNumber Prop
        @maxLength({ value: 50 })
        certificateNumber: string;
        //#endregion certificateNumber Prop


        //#region laboratoryID Prop
        @prop()
        laboratoryID: number;
        //#endregion laboratoryID Prop





}