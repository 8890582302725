import {vDutyStatusRecordBase,} from '@app/database-models'
//Generated Imports
export class vDutyStatusRecord extends vDutyStatusRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}