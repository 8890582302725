import {LookupOccurrenceAreaBase,IncidentOccurrenceAreaBase,} from '@app/database-models'
//Generated Imports
export class LookupOccurrenceArea extends LookupOccurrenceAreaBase 
{




//#region Generated Reference Properties
//#region incidentOccurrenceAreas Prop
incidentOccurrenceAreas : IncidentOccurrenceAreaBase[];
//#endregion incidentOccurrenceAreas Prop

//#endregion Generated Reference Properties






































}