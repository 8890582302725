import { prop } from '@rxweb/reactive-form-validators';

export class MoveNoteDocumentModel {

    isDocument: boolean
    isHeaderBtnChecked: boolean
    excludedIds: number[]
    selectedIds: number[]
    entityId: number
    entityTypeId: number
    currentEntityId: number
    currentEntityTypeId: number
    currentModuleId: number
    targetModuleId: number
    query: string

}