import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPaymentStatusLookUpBase {

//#region paymentStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'paymentStatusId', keyColumn: true})
        paymentStatusId : number;
//#endregion paymentStatusId Prop


//#region paymentStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'paymentStatusName', keyColumn: false})
        paymentStatusName : string;
//#endregion paymentStatusName Prop

}