import { http } from "@rxweb/http";
import { IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
import { Supplier, vStateProvinceLookUp, vSalesAdvisorLookUp, vCustomerRegistrationTypeLookUp } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { vRoleLookUp, vCountryLookUp, vSalesChannel, vCustomerRoleLookUp, vIndustryTypeLookUp, vkeyRelationshipHolderLookUp } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { SupplierDetailBase } from '@app/database-models';
import { FormArray, FormGroup } from '@angular/forms';
import { List } from '@rxweb/generics';
import { Router } from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import { vSupplierRoleLookUp } from 'src/app/models/extended-models/v-supplier-role-lookup';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import * as CryptoJS from 'crypto-js';
import { vSalesUserLookUpBase } from 'src/app/models/database-models/v-sales-user-look-up';
import { checkEmojiCharactersValidation, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { vCorporateCreditRating } from 'src/app/models/extended-models/v-corporate-credit-rating';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { showToolTip } from "src/app/domain/customize-plugin/bind-slider";

@http({
  path: 'api/Suppliers'
})

export class AbstractSupplier extends CoreComponent {
  supplierFormGroup: IFormGroup<Supplier>
  modalView: ModalView;
  router: Router;
  //isRedirect:boolean=false;
  isShowGridColumn: boolean = false;
  isRedirect: string = "No";
  userId: number;
  showComponent: boolean = false;
  spin: boolean = false;
  dialog: BaseDialog;
  toastr: BaseToastr
  activeStep: string = 'step-1';
  step: number = 1;
  supplierDetailBase: SupplierDetailBase;
  supplierRoleValidation: boolean = false;
  emojisErr : boolean = false;
  validArray: List<boolean>;
  id: number = 0;
  isRoles: boolean = false;
  personaNonGrataMean:string;
  supplierRoles: List<vSupplierRoleLookUp>;
  countryId: number;
  showSelectOption: boolean = false;
  stateProvinceLookUp: vStateProvinceLookUp[];
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  fileViewModel: FileInputViewModel;
  isNoRecordFound: boolean;
  isShowGrid: boolean;
  isDDStateChange: boolean = true;
  industryTypeLookUp: any;
  countryLookUp: any;
  country: number;
  isshowStateTextbox: boolean = true;
  supplierLookups: any = {
    salesChannel: vSalesChannel,
    customerRoleLookUps: vCustomerRoleLookUp,
    countryLookUp: vCountryLookUp,
    stateProvinceLookUp: vStateProvinceLookUp,
    keyRelationshipHolderLookUp: vkeyRelationshipHolderLookUp,
    industryTypeLookUp: vIndustryTypeLookUp,
    supplierRoleLookUp: vSupplierRoleLookUp,
    hasPurchasedFromList: null,
    roleLookUp: vRoleLookUp,
    //salesAdvisorLookUp: vSalesAdvisorLookUp,
    salesAdvisorLookUp: vSalesUserLookUpBase,
    customerRegistrationTypeLookUp: vCustomerRegistrationTypeLookUp,
    supplier: Supplier,
    fabergeStockistLookup: null,
    kycLookups: null,
    corporateCreditRatings: vCorporateCreditRating
  }
  constructor(private blobState: BlobUploadsViewStateService) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.Image;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
  }
  selectedTab: string = "supplier-edit"
  statusEnums: any = StatusEnum;
  badRequest = (data) => {
    this.step = this.step - 1;
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
      if (this.step == Steps.BasicInfo) {
        this.activeStep = "step-" + Steps.BasicInfo;
      }
      else (this.step == Steps.ContactInfo)
      {
        this.activeStep = "step-" + Steps.ContactInfo;
      }
    })
  }
  peopleWizard(step: number, next: boolean) {
    this.supplierFormGroup.submitted = true;
    this.supplierRoleValidation = false;
    let isStepValidated = this.validateForm(step);;
    if (!next) {
      isStepValidated = true;
    }
    if (isStepValidated) {
      this.supplierFormGroup.submitted = false;
      if (next) {
        this.step += 1;
      }
      else {
        this.step -= 1;
      }
      this.activeStep = "step-" + this.step;
      if (this.step == Steps.Final) {
        this.addSupplier();
      }
    } else {
      var existsAlert = getLocalizedValue("Enter_Valid_Details");
      if (existsAlert) {
        //this.toastr.warning("Please enter valid details !");
        this.toastr.warning(existsAlert);
      }
    }
    this.personaNonGrataMean =  getLocalizedValue("UserAddEditComponent_PersonaNonGrataMean_t");
    setTimeout(() => {
      showToolTip("supplier_IsUnwelcome_helpToolTip", this.personaNonGrataMean);
    }, 1000);
  }
  validateForm(step: number): boolean {
    this.validArray = new List<boolean>();
    if (step == Steps.BasicInfo) {
      let controlsSupplierDetails = ["company", "companyRegisteredNumber", "description"];
      controlsSupplierDetails.forEach(control => {
        let data = this.supplierDetailFormArray;
        for (let i = 0; i < data.length; i++) {
          this.validArray.add(data[i].controls[control].valid);
        }
      });
      let controlsSupplier = ["registrationDate", "industryTypeId"];
      controlsSupplier.forEach(control => {
        this.validArray.add(this.supplierFormGroup.controls[control].valid);
      });

      if (checkEmojiCharactersValidation(this.supplierDetailFormArray[0].value.company))
      {
        this.emojisErr = true;
        this.validArray.add(false);
      }
      else
      {
            this.emojisErr = false;
      }
      this.supplierFormGroup.patchValue({ username: this.supplierDetailFormArray[0].value.company })
      if (!this.checkSupplierRoleSelected()) {
        this.supplierRoleValidation = true;
        this.validArray.add(false);
      }
    }
    else if (step == Steps.ContactInfo) {
      let controlsSupplierDetails = ["streetAddress", "streetAddress2", "workPhoneNumber", "city", "countryId", "stateProvinceId", "zipPostalCode", "poBox", "webSite", "jewelleryStockist", "watchStockist", "currentFabergeStockist", "commu_FabergeMBRupdates"];
      controlsSupplierDetails.forEach(control => {
        let data = this.supplierDetailFormArray;
        for (let i = 0; i < data.length; i++) {
          this.validArray.add(data[i].controls[control].valid);
        }
      });
      let controlsSupplier = ["email", "salesAdvisorsId", "registrationTypeId", "introducesBy", "contactPermissionRequired"];
      controlsSupplier.forEach(control => {
        this.validArray.add(this.supplierFormGroup.controls[control].valid);
      });
    }
    return !this.validArray.any(x => x == false);
  }


  get supplierDetailFormArray(): FormArray {
    let supplierDetailFormArray = this.supplierFormGroup.controls["supplierDetails"] as IAbstractControl;
    let data = <FormArray>supplierDetailFormArray["controls"]
    return data;
  }

  get supplierRoleFormArray(): FormArray {
    let supplierDetailFormArray = this.supplierFormGroup.controls["supplierRoles"] as IAbstractControl;
    let data = <FormArray>supplierDetailFormArray["controls"]
    return data;
  }

  getRoleName(roleId: number): string {
    let roleName = this.supplierRoles.firstOrDefault(x => x.roleId == roleId);
    return roleName["roleName"];
  }
  async addSupplier() {
    this.supplierFormGroup.submitted = true;
    if (this.supplierFormGroup.valid) {
      this.spin = true
      if (this.supplierFormGroup.value.fileData) {

        let res = await this.uploadToBlob(this.fileOutputViewModel, this.supplierFormGroup.value.blobPath);
        this.supplierFormGroup.patchValue({
            fileData: null,
            blobFileName: res
        });
    }
      this.supplierFormGroup.patchValue({
        userId: this.isRedirect == "Yes" ? this.userId : 0
      })
      this.supplierDetailFormArray[0].patchValue({
        company:this.supplierDetailFormArray[0].value.company.trim(),
        description:this.supplierDetailFormArray[0].value.description.trim()
      })
      this.supplierFormGroup.value.supplierRoles = this.supplierFormGroup.value.supplierRoles.filter(function(item) {
        return item.supplierRoleId != null
      })

      this.post({ body: this.supplierFormGroup.value }).subscribe(data => {
        if (data) {
          this.spin = false;
          this.id = data as number;
          var existsAlert = getLocalizedValue("Data_Added");
          if (existsAlert) {
            //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert);
          }
          if (this.isRedirect == "Yes") {
            this.router.navigate(["users", this.userId]);
          }
          else {
            this.activeStep = "step-" + this.step;
          }

        }
      })
    }
    else {
      var existsAlert = getLocalizedValue("Enter_Valid_Details");
      if (existsAlert) {
        //this.toastr.warning("Please enter valid details !");
        this.toastr.warning(existsAlert);
      }
    }
  }

  checkSupplierRoleSelected(): boolean {
    let isSelected = false;
    let data = this.supplierRoleFormArray;
    for (let i = 0; i < data.length; i++) {
      if (data[i].value.active == true) {
        return true;
      }
    }
    return isSelected;
  }
  selectState(event) {
    if(event.item!=null && event.item != undefined)
      this.stateChange(event.item.key);
  }
  stateChange(id: number, onInit: boolean = false) {
    // this.isshowStateTextbox = true;
    this.showSelectOption = onInit == true ? false : true;
    this.countryId = id;
    if (this.country != this.countryId) {
      this.supplierFormGroup.patchValue({
        stateName: null
      })
    }
    this.isDDStateChange = true;
    setTimeout(function () {
      this.isDDStateChange = true;
    }.bind(this), 100);
    this.stateProvinceLookUp = this.supplierLookups.stateProvinceLookUp;
    this.stateProvinceLookUp = this.stateProvinceLookUp.filter(x => x.countryId == this.countryId);
    this.isshowStateTextbox = this.stateProvinceLookUp.length > 0 ? false : true;
  }
  updateStatus($event) {
    if ($event.target.checked) {
      this.supplierFormGroup.patchValue({ statusId: StatusEnum.Active })
    } else {
      this.supplierFormGroup.patchValue({ statusId: StatusEnum.Inactive })
    }
  }

  updateCompliance($event) {
    if ($event.target.checked) {
      this.supplierFormGroup.patchValue({ complianceForm: StatusEnum.Active })
    } else {
      this.supplierFormGroup.patchValue({ complianceForm: StatusEnum.Inactive })
    }
  }

  updateCompany($event) {
    if ($event.target.checked) {
      this.supplierFormGroup.patchValue({ companyID: StatusEnum.Active })
    } else {
      this.supplierFormGroup.patchValue({ companyID: StatusEnum.Inactive })
    }
  }

  updateKeyIndividualID($event) {
    if ($event.target.checked) {
      this.supplierFormGroup.patchValue({ keyIndividualID: StatusEnum.Active })
    } else {
      this.supplierFormGroup.patchValue({ keyIndividualID: StatusEnum.Inactive })
    }
  }

  goToSupplier() {
    this.router.navigate(['/suppliers', this.id], { queryParams: { activeTab: this.selectedTab } })
  }
  
  async updateFiles($event: FileOutputViewModel) {
    
    

    this.fileOutputViewModel = $event;
    this.supplierFormGroup.patchValue({
      fileData: $event.fileBaseString,
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      displayImage: $event.displayImage,
      isRemovedImage: true,
      //blobFileName:uploadedFileName,
      blobPath:BLOB_BASE.SupplierFiles,
      size:$event.files.size
    });

  }

  removeImage() {
    this.supplierFormGroup.patchValue({
      fileData: null,
      blobFileName:null,
      fileExtention: null,
      fileName: null,
      displayImage: false,
      isRemovedImage: true
      //pictureID:0
    });
  }

  async uploadToBlob($event:FileOutputViewModel,blobPath:string){
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
        .split(".")
        .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);

    let url = res._response.request.url;
    let uploadedFileName = blobPath + decodeURIComponent(
        url.split("?")[0].split("/").pop()
    );

    // IF SUCCEED TO UPLOAD
    if (res && (res._response.status == 200 || res._response.status == 201)) {
        if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
        // IF FAILED TO UPLOAD
        console.log("Failed to upload file to blob from client!", res);
        this.toastr.error(
            "Error occured while uploading file! Please try again."
        );
        if (uploadedFileName != undefined) {
            this.modalView
                .show(BedRequestComponent, {
                    validationMessages: JSON.stringify(
                        uploadedFileName
                    ),
                    headerMessage: "Image failed to upload:",
                })
                .then(() => { });
        }
    }
    return uploadedFileName;
}


  activeTab(tabName: string) {
    this.selectedTab = tabName;
    this.router.navigate(["suppliers", this.id], { queryParams: { activeTab: tabName } });
  }
}

export enum Steps {
  BasicInfo = 1,
  ContactInfo = 2,
  Final = 3
}
