import { TemplateConfig } from '@rxweb/grid';

export const PIECE_REDIRECT_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onSerialNoLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        onClick: "return false;"
                    }
                },
                span: {
                    childrens: [{
                        text: {
                            text: function (e) {
                                if (this.categoryId == 99) {
                                    return " Ring Size : " + this.ringSize;
                                } if (this.categoryId == 90) {
                                    return " Bangle Size : " + this.bangleSize;
                                }
                                else {
                                    return "";
                                }
                            }
                        }
                    }]
                },
            }
        ]
    },
};

export const REDIRECT_PIECE_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onSerialNoLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        onClick: "return false;"
                    }
                }, span: {
                    childrens: [{
                        text: {
                            text: function (e) {
                                if (this.size != null) {
                                    return " " + this.size;
                                } else {
                                    return "";
                                }
                            }
                        }
                    }]
                },
            }
        ]
    },
}


export const REDIRECT_GEMSTONE_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onGemstoneIdLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.gemstonesId != null || this.gemstonesId != undefined) {
                                return "/product-piece-gemstones/" + this.gemstonesId + "?activeTab=gemstone-edit";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.gemstonesId != null || this.gemstonesId != undefined) {
                                return "/product-piece-gemstones/" + this.gemstonesId + "?activeTab=gemstone-edit";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    },
}


export const PIECE_INFO_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                span: {
                    childrens: [{
                        text: {
                            text: function (e) {
                                var result = this.serial;
                                if (this.categoryId == 99) {
                                    result = result + " Ring Size : " + this.ringSize;
                                }

                                if (this.categoryId == 90) {
                                    result = result + " Bangle Size : " + this.bangleSize;
                                }

                                return result;
                            }
                        },
                        span:{
                            attributes: {
                                innerHTML: function (e) {
                                    var result = "";
                                    if (this.notAvailableMessage != "" && this.notAvailableMessage != null) {
                                        result = result + " <br><span style='color: Red;'>" + this.notAvailableMessage + "</span>";
                                    }
                                    if (this.futureTripAlert != "" && this.futureTripAlert != null) {
                                        result = result + ' <br>' + this.futureTripAlert;
                                    }
                                    return result;
                                }
                            }
                        }
                    }],
                },
            }
        ]
    },
};


export const REDIRECT_ORDER_LINK_PIECE_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onOrderIdLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { 
                                if (this.orderId != null && this.orderId != undefined && this.orderId > 0) {    
                                    return this[e.name] 
                                }
                            }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.orderId != null || this.orderId != undefined && this.orderId > 0) {
                                return "/orders/" + this.orderId + "?activeTab=sales-order";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.orderId != null || this.orderId != undefined && this.orderId > 0) {
                                return "/orders/" + this.orderId + "?activeTab=sales-order";
                            }
                        },
                        onClick: "return false;"
                    }
                }, span: {
                    childrens: [{
                        text: {
                            text: function (e) {
                                if (this.orderId == 0) {
                                    return this[e.name];
                                }
                            }
                        }
                    }]
                },
            }
        ]
    },
};


export const POSPIECE_INFO_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                span: {
                    childrens: [{
                        span:{
                            attributes: {
                                innerHTML: function (e) {
                                    var result = "";
                                    if (this.currentBooking != "" && this.currentBooking != null) {
                                        result = result + " <span style='color: Red;'>Current booking is : " + this.currentBooking + "</span>";
                                    }
                                    if (this.futurebooking != "" && this.futurebooking != null) {
                                        result = result + " <br><span style='color: green;'>Future booking is : <br>" + this.futurebooking+ "</span>";
                                    }
                                    return result;
                                }
                            }
                        }
                    }],
                },
            }
        ]
    },
};