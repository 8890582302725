export enum FileTypeValiationEnums {
  Image = ".jpg,.jpeg,.png,.gif,.tiff,.eps,.HEIC,.heic,heif,.HEIF,.wav,.avi,.webp",  
  Video = ".mp4,.mp3,.wmv,.mov,.hevc,.HEVC",
  Doc = ".pdf,.docx,.doc,.ppt,.pptx,.xlsx,.tif,.zif,.zip,.msg,.xls,.odt,.xlsm,.gif,.rtf,.m4a,.ods,.kmz,.jfif,.pub,.avi,.csv,.ppsx,.tiff,htm,.eps,.bmp,.rar,.dotx,.dwg,.m4v,.numbers,.html,.log,.eml,.mpeg,.txt,.xps,.webp,.jpe,.mpp,.xml,.ai,.xspf,.xlsb,.dot,.step,.webloc,.wav",
  Pdf = ".pdf",
  Exl = ".xlsx,.xls",
  DocAll = ".docx,.pdf,.jpg,.jpeg,.png",
  SpecificImage = ".jpg,.png",
  DocOnly = ".docx",
  HomeLayoutFrontImage = ".jpg,.jpeg,.png,.svg,.gif,.webp",
  HomeLayoutMobileImage = ".jpg,.jpeg,.png,.svg,.gif,.webp",
  VideoFabergeGemstone = ".mp4,.mp3,.wmv,.mov,.avi,.flv",
  Audio = ".aac",
  Logo=".jpg,.jpeg,.png,.gif,.svg"
}
export enum FileTypeEnums {
  Image = 1,
  Video = 2,
  Doc = 3,
  All = 4,
  Pdf = 5,
  Exl = 6,
  DocAll = 7,
  SpecificImage = 8,
  DocOnly = 9,
  HomeLayoutFrontImage = 10,
  HomeLayoutMobileImage = 11,
  VideoFabergeGemstone = 12,
  Logo=13
}

export enum FileSizeEnums {
  OneMB = 1,
  TwoMB = 2,
  ThreeMB = 3,
  FourMB = 4,
  FiveMB = 5,
  SixMB = 6,
  MidMB = 500,
  MaxMB = 1024,
  MinimumSizeKB = 35
}

export enum FilePlaceHolders {
  Image = "select only image file",
  Video = "select only video file",
  Doc = "select only document file",
  Pdf = "select only pdf file",
  Exl = "select only xlsx file",
  DocAll = "select only document file",
  SpecificImage = "select only .jpg,.png file",
  DocOnly = "select only .docx file",
  HomeLayoutFrontImage = "select front image file",
  HomeLayoutMobileImage = "select mobile image file",
  Logo="select only image file"
}
