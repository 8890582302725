import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SpokenLanguageBase {

//#region spokenLangId Prop
        @prop()
        spokenLangId : number;
//#endregion spokenLangId Prop


//#region language Prop
        @required()
        @maxLength({value:100})
        language : string;
//#endregion language Prop

//#region statusId Prop
        @prop()
        @required()
        statusId : number;
//#endregion statusId Prop

}