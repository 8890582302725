import {vPageSslProtectionLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPageSslProtectionLookUp extends vPageSslProtectionLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}