import {vCategoryTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCategoryTypeLookUp extends vCategoryTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}