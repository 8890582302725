<div  [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="Btn_Add_Legal_Agreement_t"  *ngIf="showAddButton"></button>
        <label class="ml-auto mr-3 mb-3" *ngIf="isShowSearch" [rxLocalisationInit]="componentName">
            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
                placeholder="" [title]="titleSearch">
        </label>
        <div class="ibox-tools mb-4 pb-1" style="position: relative;" *ngIf="!isNoRecordFound">
            <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
                <i class="fa fa-cog"></i>
            </a>
            <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
                (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
                [gridColumnConfig]="legalAgreementProjectGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
                [moduleID]="moduleID" [selectedTab]="selectedTab">
            </app-system-table-setting>
        </div>
    </div>
    <div class="table-responsive" *ngIf="isShowGrid">
        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
            <rx-grid [design]="legalAgreementProjectGrid"></rx-grid>
        </div>
    </div>
    <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound" [rxLocalisationInit]="componentName">
        <h4 rxText="Label_NoRecordFound_t" ></h4>
    </div>
</div>