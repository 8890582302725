import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'viewModel' }, columnIndex: 14, headerTitle: "Action"
})
export class LeaveMasterDetailViewModelBase {

    //#region masterId 
    @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'masterId', keyColumn: true })
    masterId: number;
    //#endregion masterId 

    //#region leaveUserFullName 
    @gridColumn({ isAscending: true, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'LeaveMasterListComponent_Name_gh', keyColumn: false })
    leaveUserFullName: string;
    //#endregion leaveUserFullName 

    //#region leaveTypeName 
    @gridColumn({ isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'LeaveMasterListComponent_Type_gh', keyColumn: false })
    leaveTypeName: string;
    //#endregion leaveTypeName 

    //#region stStartDate 
    @gridColumn({ isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'LeaveMasterListComponent_StartDate_gh', keyColumn: false })
    stStartDate: string;
    //#endregion stStartDate 

    //#region StartDate 
    @gridColumn({ isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 5, allowSorting: true, headerKey: 'LeaveMasterListComponent_StartDate_gh', keyColumn: false })
    startDate: Date;
    //#endregion StartDate 

    //#region stEndDate 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: true, headerKey: 'LeaveMasterListComponent_EndDate_gh', keyColumn: false })
    stEndDate: string;
    //#endregion stEndDate 

    //#region EndDate 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 7, allowSorting: true, headerKey: 'LeaveMasterListComponent_EndDate_gh', keyColumn: false })
    endDate: Date;
    //#endregion EndDate 

    //#region postedOn 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 8, allowSorting: true, headerKey: 'LeaveMasterListComponent_CreatedOn_gh', keyColumn: false })
    postedOn: Date;
    //#endregion postedOn 

    //#region leaveTypeName 
    // @gridColumn({
    //     template: {
    //         div: {
    //           attributes: {
    //             innerHTML: function (x, y) {
    //               return this.associatedEvents;
    //             }
    //           },
    //         }
    //       }, isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: false, headerKey: 'LeaveMasterListComponent_AssociatedEvents_gh', keyColumn: false })
    // associatedEvents: string;
    // //#endregion leaveTypeName 

    // #region events prop
    @gridColumn({
    template: {
      div: {
          style: {
              "word-break": "break-all;",
          },
          attributes: {
              innerHTML: function (x, y) {
                  if (this.events == null) {
                      return "";
                  } else {
                      return this.events;
                  }
              }
          }
      }
  }, style: { width: "10%", "text-align": "initial", "min-width":"200px"  }, parameter : false,  class: ["text-left"], visible: true, columnIndex: 9, allowSorting: false, headerKey : 'LeaveMasterListComponent_AssociatedEvents_gh' , keyColumn: false })
  events : string;
// #endregion events Prop

    eventCount : number;

    eventDetails : any[];

    //#region leaveTypeName 
    // @gridColumn({
    //     template: {
    //     div: {
    //       attributes: {
    //         innerHTML: function (x, y) {
    //           return this.associatedProjects;
    //         }
    //       },
    //     }
    //   }, isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: false, headerKey: 'LeaveMasterListComponent_AssociatedProjects_gh', keyColumn: false })
    // associatedProjects: string;
    //#endregion leaveTypeName 

        // #region projects prop
        @gridColumn({
          template: {
              div: {
                  style: {
                      "word-break": "break-all;",
                  },
                  attributes: {
                      innerHTML: function (x, y) {
                          if (this.projects == null) {
                              return "";
                          } else {
                              return this.projects;
                          }
                      }
                  }
              }
          }, style: { width: "10%", "text-align": "initial", "min-width":"200px" }, parameter : false,  class: ["text-left"], visible: true, columnIndex: 10, allowSorting: false, headerKey : 'LeaveMasterListComponent_AssociatedProjects_gh' , keyColumn: false })
      projects : string;
      // #endregion projects Prop

    projectDetails:any[];
    //#region corporats start #282530/Naitik/09/05/2024
    @gridColumn({
        template: {
          div: {
              style: {
                  "word-break": "break-all;",
              },
              attributes: {
                  innerHTML: function (x, y) {
                      if (this.corporats == null) {
                          return "";
                      } else {
                          return this.corporats;
                      }
                  }
              }
          }
      }, style: { width: "10%", "text-align": "initial", "min-width":"200px"  }, parameter : false,  class: ["text-left"], visible: true, columnIndex: 11, allowSorting: false, headerKey : 'LeaveMasterListComponent_AssociatedCorporats_gh' , keyColumn: false })
      corporats : string;
    // #endregion events Prop
    
        corporatCount : number;
    
        corporatDetails : any[];
    //#endregion corporat      
    //#region days 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex: 12, allowSorting: true, headerKey: 'LeaveMasterListComponent_Days_gh', keyColumn: false })
    days: number;
    //#endregion days

    //#region stStatus 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex: 13, allowSorting: false, headerKey: 'LeaveMasterListComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName: "leaveStatus" } })
    status: number;
    //#endregion stStatus

    //#region cancel 
    // @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], columnIndex: 7, allowSorting: true, headerTitle: 'Cancel', keyColumn: false })
    // cancel: string;
    //#endregion cancel



    //#region cancel
    @gridColumn({ style: { width: "10%" }, parameter: false, configuredTemplate: { templateName: "cancelAction" }, name: "cancel", visible: true, columnIndex: 14, allowSorting: false, headerKey: 'LeaveMasterListComponent_Cancel_gh', keyColumn: false })
    cancel: boolean;
    //#endregion cancel 

}
