import {CategoryBase, ProductBase, ProductProductTypeMappingBase,} from '@app/database-models'
import { ProductTypeBase } from '../database-models/product-type-base';
//Generated Imports
export class ProductProductTypeMapping extends ProductProductTypeMappingBase 
{




//#region Generated Reference Properties
//#region productType Prop
productType : ProductTypeBase;
//#endregion productType Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop
// //#region category Prop
// category : CategoryBase;
// //#endregion category Prop

//#endregion Generated Reference Properties











}