import { gridColumn } from '@rxweb/grid';

export class vChainGaugeLookUpBase {

    //#region chainGaugeId Prop
    @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'chainGaugeId', keyColumn: true })
    chainGaugeId: number;
    //#endregion chainGaugeId Prop


    //#region gaugeName Prop
    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'gaugeName', keyColumn: false })
    gaugeName: string;
    //#endregion gaugeName Prop

}