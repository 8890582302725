import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vVatNumberStatusLookUpBase {

//#region vatNumberStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'vatNumberStatusId', keyColumn: true})
        vatNumberStatusId : number;
//#endregion vatNumberStatusId Prop


//#region vatNumberStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'vatNumberStatusName', keyColumn: false})
        vatNumberStatusName : string;
//#endregion vatNumberStatusName Prop

}