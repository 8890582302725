import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { CompanyCustomerMapping } from '@app/models';
import { AbstractCompanyCustomerMapping } from '../domain/abstract-company-customer-mapping';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CoreComponent, } from '@rxweb/angular-router';
import { CustomerCorporateMappingViewModel } from '../../../../view-model/customer-corporate-mapping-view-model';
import { CustomerCorporateMapplingListViewModel } from '../../../../models/extended-models/customer-corporate-mapping-list-view-model';
import { AppGrid } from '../../../../domain/app-grid';
import { PaginationEnum } from '../../../../enums/pagination.enums';
import { ModalView } from '../../../../domain/customize-design/modal';

import { multilingual } from '@rxweb/localization';
import { PaginationViewModel } from '../../../../view-model/pagination-view-model';
import { currentDateValidation, dateCompareValidation, getLocalizedValue } from '../../../../domain/common-logic/common-logic';
import * as CryptoJS from 'crypto-js';
import { DatePipe, PlatformLocation } from "@angular/common";

@multilingual("CompanyCustomerMappingAddComponent")

@Component({
    selector: "app-company-customer-mapping-add",
    templateUrl: './company-customer-mapping-add.component.html'
})
export class CompanyCustomerMappingAddComponent extends AbstractCompanyCustomerMapping implements OnInit, OnDestroy {
    @Input() isDirector: number;
    @Input() userId: number;
    @Input() hide: Function;
    @Input() componentType: string;
    @Input() isCustomer: boolean = false;
    // isRedirect:boolean=true;
    isRedirect: string = "Yes";
    customerCorporateListViewModel: CustomerCorporateMapplingListViewModel[];
    customerCorporateMappingSearchFormGroup: IFormGroup<CustomerCorporateMappingViewModel>;
    CompanyCustomerMappingInfo: CompanyCustomerMapping;
    companyCustomerMappingArray: CompanyCustomerMapping[];
    corporateUserListGrid: AppGrid;
    companyCustomerMapping: CompanyCustomerMapping;
    customerCorporate: CustomerCorporateMappingViewModel;
    subscription: any;
    corporateIds = [];
    isFilter: boolean = false;
    totalRecords: number = 0;
    dateError: boolean = false;
    primaryEndDateError: boolean = false;
    directorDateError: boolean = false;
    directorEndDateError: boolean = false;
    ownershipDateError: boolean = false;
    ownershipPerError: boolean = false;
    ownershipEndDateError: boolean = false;
    employmentError: boolean = false;
    paginationViewModel: PaginationViewModel;
    gridSearchString: string = "";
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    headerBtnChk: boolean = false;
    cloneArray: CustomerCorporateMapplingListViewModel[];
    isFutureDate: boolean = false;
    titleClose: string;


    _this;

    constructor(private formBuilder: RxFormBuilder, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.companyCustomerMappingArray = new Array<CompanyCustomerMapping>();
        this.corporateIds = new Array<CompanyCustomerMapping>();
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.totalRecords = 0;
        this.cloneArray = new Array<CustomerCorporateMapplingListViewModel>();
        if (this.componentType == 'corporate') {
            this.customerCorporate = new CustomerCorporateMappingViewModel();
            this.customerCorporate.pageIndex = PaginationEnum.PageIndex;
            this.customerCorporate.rowCount = PaginationEnum.RowCount;
            this.customerCorporate.userId = this.userId;
            this.customerCorporateMappingSearchFormGroup = this.formBuilder.formGroup(this.customerCorporate) as IFormGroup<CustomerCorporateMappingViewModel>;

            this.showComponent = true;
            this.spin = false;

        }
        else {
          this.lookup([{ propName: "patersongrade", path: USER_LOOKUPS.getPatersongradeLookups }, { propName: "payrolltype", path: USER_LOOKUPS.getPayrolltypeLookups },{ propName: "companyLookup", path: USER_LOOKUPS.companyLookUp }, { propName: "reportingHeadLookup", path: USER_LOOKUPS.reportingHeadLookUp }]).subscribe((lookup: any) => {
                this.employmentData = lookup;
              this.payrolltype = lookup.payrolltype.payRollType; //#292627//08-07-2024// added  payrollLookup
              this.patersongrade = lookup.patersongrade.patersonGrade; //#292627//08-07-2024// added  paterson grade lookup 
                this.companyCustomerMapping = new CompanyCustomerMapping();
                this.companyCustomerMapping.userId = this.userId;
                this.companyCustomerMapping.isDirector = this.isDirector == 1 ? true : false;
                //this.companyCustomerMapping.statusId = StatusEnum.Inactive;
                this.companyCustomerMapping.companyCustomerMappingId = 0;
                this.companyCustomerMapping.reportTo = 0;
                this.companyCustomerMapping.expatId = 0;
                this.companyCustomerMapping.payRollId = 0; //#292627//08-07-2024//by default it should 0 as it is add form
                this.companyCustomerMapping.patersonGradeId = 0; //#292627//08-07-2024//by default it should 0 as it is add form
                this.companyCustomerMapping.jobTitle = "";
                this.employmentData.companyCustomer = this.companyCustomerMapping;
                this.companyCustomerMappingFormGroup = this.formBuilder.formGroup(CompanyCustomerMapping, this.employmentData.companyCustomer) as IFormGroup<CompanyCustomerMapping>;
                this.showComponent = true;
                this.spin = false;

            });
        }
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    AddForm() {
        var date = new Date();

      
        // else if(this.companyCustomerMappingFormGroup.value.isPrimary == true && this.companyCustomerMappingFormGroup.value.endDate != undefined && this.companyCustomerMappingFormGroup.value.endDate < new Date()) {
        //     this.primaryEndDateError = true;
        //     if (dateCompareValidation(this.companyCustomerMappingFormGroup.value.startDate, this.companyCustomerMappingFormGroup.value.endDate) == true) {
        //         this.dateError = true;
        //     }
        // }
        // else if (this.companyCustomerMappingFormGroup.value.isEmployment && dateCompareValidation(this.companyCustomerMappingFormGroup.value.startDate, this.companyCustomerMappingFormGroup.value.endDate) == true) {
        //     this.dateError = true;
        // }
        // else if (this.companyCustomerMappingFormGroup.value.isDirector && dateCompareValidation(this.companyCustomerMappingFormGroup.value.directorStartDate, this.companyCustomerMappingFormGroup.value.directorEndDate) == true) {
        //     this.directorDateError = true;
        // }
        // else if (this.companyCustomerMappingFormGroup.value.isOwnership && dateCompareValidation(this.companyCustomerMappingFormGroup.value.ownershipStartDate, this.companyCustomerMappingFormGroup.value.ownershipEndDate) == true) {
        //     this.ownershipDateError = true;
        // }
        // else if(this.companyCustomerMappingFormGroup.value.isOwnership && (!this.companyCustomerMappingFormGroup.value.ownershipPerc || this.companyCustomerMappingFormGroup.value.ownershipPerc == null)){
        //     this.ownershipPerError = true;
        // }
            this.employmentError = false;
            this.dateError = false;
            this.primaryEndDateError = false;
            this.directorDateError = false;
            this.ownershipDateError = false;
            this.ownershipPerError = false;
            //this.isFutureDate = false;
            this.companyCustomerMappingFormGroup.submitted = true;
            var statusId;
            if(!this.isCustomer){
                statusId = this.companyCustomerMappingFormGroup.value.endDate != null ? StatusEnum.Inactive : StatusEnum.Active
            }
            else{
                statusId = this.companyCustomerMappingFormGroup.value.activeEmployement == true ? StatusEnum.Active : StatusEnum.Inactive
            }
            this.companyCustomerMappingFormGroup.patchValue({
                isCustomer: this.isCustomer,
                statusId: statusId,
                supplierId: this.companyCustomerMappingFormGroup.value.companyId
            })
            if (this.companyCustomerMappingFormGroup.valid) {
                this.companyCustomerMappingFormGroup.value.corporateUserIds = new Array<CompanyCustomerMapping>();
                this.spin = true;
                if(this.isDirector == 0){
                    this.post({path:'api/CompanyCustomerMappings/AddEmployment', body: this.companyCustomerMappingFormGroup.value }).subscribe(data => {
                        this.spin = false;
                        var existsAlert = getLocalizedValue("Data_Added");
                        if (existsAlert) {
                            //this.toastr.success("Data Added Successfully")
                            this.toastr.success(existsAlert);
                        }
                        this.hide();
                    })
                }
                else{
                    this.post({body: this.companyCustomerMappingFormGroup.value }).subscribe(data => {
                        this.spin = false;
                        var existsAlert = getLocalizedValue("Data_Added");
                        if (existsAlert) {
                            //this.toastr.success("Data Added Successfully")
                            this.toastr.success(existsAlert);
                        }
                        this.hide();
                    })
                }
            }
        

    }

    searchCorporateUser() {
        this.isShowGrid = true;
        this.totalRecords = 0;
        if (this.corporateUserListGrid) {
            this.isFilter = true;
        }
        this.customerCorporateMappingSearchFormGroup.submitted = true;
        if (this.customerCorporateMappingSearchFormGroup.valid) {
            this.spin = true;
            var json;

            json = JSON.parse(JSON.stringify(this.customerCorporateMappingSearchFormGroup.value));
            json["searchString"] = this.gridSearchString.replace('&', '%26');
            this.subscription = this.post({
                path: "api/SearchCustomerCorporateMapping",
                body: {
                    query: JSON.stringify(json),
                }
            }).subscribe((t: any) => {
                this.spin = false;
                if (this.corporateUserListGrid) {
                    this.corporateUserListGrid.storeProcedure.length = 0;
                }
                this.customerCorporateListViewModel = t;
                if (this.customerCorporateListViewModel.length > 0) {
                    this.totalRecords = this.customerCorporateListViewModel[0].totalCount;
                    this.isNoRecordFound = false;
                    this.customerCorporateListViewModel.forEach(x => {
                        x.isChecked = this.headerBtnChk;
                    })

                    this.customerCorporateListViewModel.forEach(x => {
                        if (this.cloneArray.findIndex(z => z.supplierId == x.supplierId) == -1) {
                            this.cloneArray.push(x);
                        }
                        else {
                            this.cloneArray.forEach(z => {
                                if (z.supplierId == x.supplierId) {
                                    x.isChecked = z.isChecked
                                }
                            })
                        }
                    })

                    this.updateCorporateArray();
                }
                else {
                    this.isNoRecordFound = true;
                    this.isShowGrid = false;
                }
                if (!this.isFilter) {
                    this.corporateUserListGrid = new AppGrid(this.customerCorporateListViewModel, CustomerCorporateMapplingListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                    this.corporateUserListGrid.storeProcedure = {
                        length: this.totalRecords,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                }
                else {
                    this.corporateUserListGrid.storeProcedure.length = this.totalRecords;
                    this.corporateUserListGrid.updateSource([]);
                    this.corporateUserListGrid.updateSource(this.customerCorporateListViewModel)
                }
                this.corporateUserListGrid.maxPerPage = this.customerCorporateMappingSearchFormGroup.value.rowCount;
                this.corporateUserListGrid.componentId = this.componentName;
                this.showSaveButton = true;
            })

        }
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.cloneArray = [];
        this.customerCorporateMappingSearchFormGroup.patchValue({ sortOrder: y, orderBy: x })
        this.corporateUserListGrid.storeProcedure.nextPage = z;
        this.searchCorporateUser();
    }
    onCheckboxSelect(t, x) {
        this.cloneArray.forEach(z => {
            if (z.supplierId == t.supplierId) {
                z.isChecked = x.target.checked;
            }
        })
        this.updateCorporateArray();
    }


    updateCorporateArray() {
        this.cloneArray.forEach(x => {
            if (x.isChecked) {
                let CompanyCustomerMappingObj = new CompanyCustomerMapping();
                CompanyCustomerMappingObj.companyCustomerMappingId = 0;
                CompanyCustomerMappingObj.companyId = x.supplierId;
                CompanyCustomerMappingObj.userId = this.userId;
                CompanyCustomerMappingObj.supplierId = x.supplierId;
                CompanyCustomerMappingObj.startDate = null;
                CompanyCustomerMappingObj.endDate = null;
                CompanyCustomerMappingObj.jobTitle = null;
                CompanyCustomerMappingObj.reportTo = 0;
                CompanyCustomerMappingObj.isDirector = false;
                CompanyCustomerMappingObj.statusId = StatusEnum.Inactive;
                if (this.corporateIds.indexOf(x.supplierId) == -1) {
                    this.companyCustomerMappingArray.push(CompanyCustomerMappingObj);
                    this.corporateIds.push(x.supplierId);
                }
            } else {
                if (this.corporateIds.indexOf(x.supplierId) != -1) {
                    this.companyCustomerMappingArray.splice(this.corporateIds.indexOf(x.supplierId), 1);
                    this.corporateIds.splice(this.corporateIds.indexOf(x.supplierId), 1);
                }
            }
        });

    }


    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = [];

        this.customerCorporateListViewModel.forEach(data => {
            data.isChecked = this.headerBtnChk;
            this.cloneArray.push(data);
        });
        this.updateCorporateArray();
        this.corporateUserListGrid.updateSource([]);
        this.corporateUserListGrid.updateSource(this.customerCorporateListViewModel);
    }
    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.customerCorporateMappingSearchFormGroup.value.rowCount;
        let toIndex: number = this.corporateUserListGrid.maxPerPage;
        if (this.customerCorporateMappingSearchFormGroup.value.pageIndex < x) {
            this.cloneArray = [];
        }
        if (this.corporateUserListGrid.maxPerPage < this.customerCorporateMappingSearchFormGroup.value.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }
        }
        this.customerCorporateMappingSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.corporateUserListGrid.maxPerPage })
        this.corporateUserListGrid.storeProcedure.nextPage = x;
        this.searchCorporateUser();
    }
    addCorporateUser() {
        this.CompanyCustomerMappingInfo = new CompanyCustomerMapping();
        this.CompanyCustomerMappingInfo.corporateUserIds = this.companyCustomerMappingArray;
        this.spin = true;
        this.post({ body: this.CompanyCustomerMappingInfo }).subscribe(data => {
            this.spin = false;
            var existsAlert = getLocalizedValue("Corporate_Added");
            if (existsAlert) {
                //this.toastr.success("Corporate Added Successfully");
                this.toastr.success(existsAlert);
            }
            this.companyCustomerMappingArray = [];
            this.hide();
        });
    }
    clearGrid() {
        if (this.corporateUserListGrid) {
            this.corporateUserListGrid.refresh("company-customer-mappings")
        }
    }
    // onCheckboxSelect(t, x, y, z) {
    //     let CompanyCustomerMappingObj = new CompanyCustomerMapping();
    //     CompanyCustomerMappingObj.companyCustomerMappingId = 0;
    //     CompanyCustomerMappingObj.companyId = t.companyId;
    //     CompanyCustomerMappingObj.userId = this.userId;
    //     CompanyCustomerMappingObj.supplierId = t.supplierId;
    //     CompanyCustomerMappingObj.startDate = t.startDate;
    //     CompanyCustomerMappingObj.endDate = t.endDate;
    //     CompanyCustomerMappingObj.jobTitle = t.jobTitle;
    //     CompanyCustomerMappingObj.reportTo = t.reportTo;
    //     CompanyCustomerMappingObj.isDirector = t.isDirector;
    //     CompanyCustomerMappingObj.statusId = StatusEnum.Active;
    //     let existObj = this.companyCustomerMappingArray.find(x => x.supplierId === t.supplierId);
    //     if (existObj) {
    //         for (var i in this.companyCustomerMappingArray) {
    //             if (this.companyCustomerMappingArray[i].supplierId == existObj.supplierId) {
    //                 this.companyCustomerMappingArray[i].companyId = existObj.companyId;
    //                 this.companyCustomerMappingArray[i].userId = existObj.userId;
    //                 this.companyCustomerMappingArray[i].supplierId == existObj.supplierId;
    //                 this.companyCustomerMappingArray[i].startDate = existObj.startDate;
    //                 this.companyCustomerMappingArray[i].endDate = existObj.endDate;
    //                 this.companyCustomerMappingArray[i].jobTitle = existObj.jobTitle;
    //                 this.companyCustomerMappingArray[i].reportTo = existObj.reportTo;
    //                 this.companyCustomerMappingArray[i].isDirector = existObj.isDirector;
    //                 break;
    //             }
    //         }
    //     }
    //     else {
    //         this.companyCustomerMappingArray.push(CompanyCustomerMappingObj);
    //     }
    // }



    searchCorporate(value) {
        this.isFilter = true;
        if (!(value.keyCode >= 37 && value.keyCode <= 40) && value.keyCode != 9 && value.keyCode != 32) {
            if ((value.target.value.replace(/ /g, '').length > 2 && value.target.value != undefined && value.target.value.replace(/ /g, '') != "") || (value.keyCode == 8 && value.target.value == '')) {
                setTimeout(() => {
                    this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
                    this.paginationViewModel.pageIndex = 1;
                    this.totalRecords = 0;
                    this.searchCorporateUser();
                }, 1000);
            }
        }
    }
    redirectCorporate() {
        this.router.navigate(["/suppliers/add"], { queryParams: { userId: this.userId, isRedirect: this.isRedirect } });
        this.hide();
    }
    get componentName(): string {
        return "CompanyCustomerMappingAddComponent";
    }
}
