import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { AbstractWebPicture } from '../domain/abstract-web-picture';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

//import { Picture } from '@app/models';

@Component({
  selector: "app-web-picture-edit",
  templateUrl: './web-picture-edit.component.html'
})
export class WebPictureEditComponent extends AbstractWebPicture implements OnInit, OnDestroy {
  //picture: Picture;
  subscription: any;
  id: number;
  constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(location,blobState);
    //this.activatedRoute.queryParams.subscribe(t => {
    //  this.id = t['id'];
    //})
  }

  ngOnInit(): void {
    //this.get({ params: [this.id] }).subscribe(t => {
    //  this.pictureFormGroup = this.formBuilder.formGroup(Picture, t) as IFormGroup<Picture>;
    //})
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
