import { RxHttp,http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { EmailAccount } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { FileExtention } from 'src/app/models/extended-models/file-extention';

@http({
    path: "api/FileExtentions",
})


export class AbstractFileExtention extends CoreComponent {
    fileExtentionFormGroup: IFormGroup<FileExtention>
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    breakLoop: boolean;
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
     fileExtentionType:any=[
      { value: "Image", id: 1},
      { value: "Video", id:2},
      { value: "Documnet", id:3}
      // { value: "Pdf", id: 3},
      // { value: "Exl", id: 6},
      // { value: "DocAll", id: 7},
      // { value: "SpecificImage", id: 8},
      // { value: "DocOnly", id: 9},
      // { value: "HomeLayoutFrontImage", id: 10},
      // { value: "HomeLayoutMobileImage", id: 11},
      // { value: "VideoFabergeGemstone", id: 12},
  ];


}
