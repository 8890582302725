import {vPriceListExportModeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPriceListExportModeLookUp extends vPriceListExportModeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}