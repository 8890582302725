import {vRecurringPaymentTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vRecurringPaymentTypeLookUp extends vRecurringPaymentTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}