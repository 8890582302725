import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Injectable({
    providedIn:'root',
})

export class MetaService {

    constructor(private title: Title, private meta: Meta) { }
 
    updateTitle(title: string){
        if (title) {
            this.title.setTitle(title + " - G-Trac Admin");
        }
    }

}