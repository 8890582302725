import {vDesignStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vDesignStatusLookUp extends vDesignStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}