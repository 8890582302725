import {CustomerSessionBase,ShoppingCartItemBase,} from '@app/database-models'
//Generated Imports
export class CustomerSession extends CustomerSessionBase 
{




//#region Generated Reference Properties
//#region shoppingCartItems Prop
shoppingCartItems : ShoppingCartItemBase[];
//#endregion shoppingCartItems Prop

//#endregion Generated Reference Properties




































}