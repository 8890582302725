import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class OrderPieceStatusHistoryMappingBase {

//#region orderPieceStatusHistoryMappingId Prop
        @prop()
        orderPieceStatusHistoryMappingId : number;
//#endregion orderPieceStatusHistoryMappingId Prop


//#region orderId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        orderId : number;
//#endregion orderId Prop


//#region pieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pieceId : number;
//#endregion pieceId Prop


//#region pieceStatus Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pieceStatus : number;
//#endregion pieceStatus Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop


//#region createdBy Prop
        @required()
        @maxLength({value:50})
        createdBy : string;
//#endregion createdBy Prop


//#region updatedDate Prop
        @prop()
        updatedDate : Date;
//#endregion updatedDate Prop


//#region updateBy Prop
        @maxLength({value:50})
        updateBy : string;
//#endregion updateBy Prop


//#region isActive Prop
        @required()
        isActive : boolean;
//#endregion isActive Prop

}