import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCanadaPostLanguageLookUpBase {

//#region canadaPostLanguageId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'canadaPostLanguageId', keyColumn: true})
        canadaPostLanguageId : number;
//#endregion canadaPostLanguageId Prop


//#region canadaPostLanguageName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'canadaPostLanguageName', keyColumn: false})
        canadaPostLanguageName : string;
//#endregion canadaPostLanguageName Prop

}