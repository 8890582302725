import {
  prop,
  propObject,
  propArray,
  required,
  maxLength,
  range,
  numeric,
  notEmpty,
  trim,
} from "@rxweb/reactive-form-validators";
import { gridColumn } from "@rxweb/grid";
import { FabergePriceListPiecesViewModel } from "../extended-models/faberge-price-list-pieces-view-model";

export class ProductBatchBase {
  //#region productVariantBatchId Prop
  @prop()
  productVariantBatchId: number;
  //#endregion productVariantBatchId Prop

  //#region productVariantId Prop
  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
  @required()
  productVariantId: number;
  //#endregion productVariantId Prop

  //#region supplierID Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  supplierID: number;
  //#endregion supplierID Prop

  //#region supplierOrderNo Prop
  @required()
  @notEmpty()
  @trim()
  @maxLength({ value: 50 })
  supplierOrderNo: string;
  //#endregion supplierOrderNo Prop

  //#region supplierInvoiceNo Prop
  //@required()
  @prop({ defaultValue: "" })
  @maxLength({ value: 50 })
  supplierInvoiceNo: string;
  //#endregion supplierInvoiceNo Prop

  //#region supplierDeliveryDate Prop
  @required()
  supplierDeliveryDate: Date;
  //#endregion supplierDeliveryDate Prop

  //#region quantity Prop
  @prop({ defaultValue: 1 })
  @required({ message: "Please enter quantity" })
  @numeric({ allowDecimal: false, message: "Only Number Required" })
  @range({
    minimumNumber: 1,
    maximumNumber: 500,
    message: "Can not add more than 500 pieces at one time",
  })
  quantity: number;
  //#endregion quantity Prop

  //#region cost Prop
  @required()
  @prop({ defaultValue: 106 })
  cost: number;
  //#endregion cost Prop

  //#region shipperId Prop
  @prop()
  @required()
  shipperId: number;
  //#endregion shipperId Prop

  //#region fABERGEPurchaseOrderNo Prop
  @required()
  @notEmpty()
  @trim()
  @maxLength({ value: 50 })
  fabergePurchaseOrderNo: string;
  //#endregion fABERGEPurchaseOrderNo Prop

  //#region createdOn Prop
  @required()
  createdOn: Date;
  //#endregion createdOn Prop

  //#region updatedOn Prop
  @required()
  updatedOn: Date;
  //#endregion updatedOn Prop

  //#region invoiceStatus Prop
  @required()
  @prop({ defaultValue: false })
  invoiceStatus: boolean;
  //#endregion invoiceStatus Prop

  //#region size Prop
  @required()
  @prop({ defaultValue: "temp" })
  @maxLength({ value: 50 })
  size: string;
  //#endregion size Prop

  //#region eggSize Prop
  // @range({minimumNumber:0,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  // @required()
  eggSize: number;
  //#endregion eggSize Prop

  //#region isPrototype Prop
  @required()
  @prop({ defaultValue: false })
  isPrototype: boolean;
  //#endregion isPrototype Prop

  //#region chainLengthID Prop
  // @range({minimumNumber:0,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  // @required()
  chainLengthID: number;
  //#endregion chainLengthID Prop

  //#region isUpcycled Prop
  @required()
  @prop({ defaultValue: false })
  isUpcycled: boolean;
  //#endregion isUpcycled Prop

  //#region isBeSpoke Prop
  @required()
  @prop({ defaultValue: false })
  isBeSpoke: boolean;
  //#endregion isBeSpoke Prop

  //#region statusId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  statusId: number;
  //#endregion statusId Prop

  //#region legalOwner Prop
  //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
  //@required()
  @prop()
  legalOwner: number;
  //#endregion legalOwner Prop

  //#region Description Prop
  @prop()
  description: string;
  //#endregion Description Prop

  //#region FrontDescription Prop
  @prop()
  frontDescription: string;
  //#endregion FrontDescription Prop

  //#region dateOfSale Prop
  @prop()
  dateOfSale: Date;
  //#endregion dateOfSale Prop

  @prop()
  poNotes: string;

  @prop()
  terms: string;

  @prop({ defaultValue: 0 })
  batchBangleSize;

  @prop({ defaultValue: 0 })
  batchRingSize;
}
