import {ClientRepairBase,UserBase,SupplierBase,} from '@app/database-models'

//Generated Imports
import { prop, required } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
export class ClientRepair extends ClientRepairBase implements FileDetailViewModel
{

//#region pieceIds Prop
//@prop({defaultValue:''})
@prop()
pieceIds:string;
//#endregion pieceIds Prop


    
     //#region showClientRepairApproval Prop
      showClientRepairApproval:boolean;
      //#endregion showClientRepairApproval Prop


//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop

//#region salesPersonName Prop
@prop()
salesPersonName : string;
//#endregion salesPersonName Prop

//#region clientName Prop
@prop()
clientName : string;
//#endregion clientName Prop
@prop()
estimationId:number;

//#region clientURL Prop
// @prop()
// clientURL : string;
//#endregion clientURL Prop


//#region isApprove Prop
@prop({defaultValue:0})
isApprove : number;
//#endregion isApprove Prop

//#region isApprove Prop
@prop({defaultValue:0})
isApproveOnly : number;
//#endregion isApprove Prop
//#endregion Generated Reference Properties

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;












}