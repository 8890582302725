import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { EventCustomerMapping } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { AppGrid } from 'src/app/domain/app-grid';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { BedRequestComponent } from "src/app/components/shared/global/bed-request/bed-request.component";
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@http({

  path: "api/EventCustomerMappings",
})
export class AbstractEventCustomerMapping extends CoreComponent {
  spin: boolean = false;
  showComponent: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  isShowGridColumn: boolean = false;
  eventInviteeGrid: AppGrid;
  sort:boolean;
  eventCustomerMappingFormGroup: IFormGroup<EventCustomerMapping>
  isShowGrid:boolean;
  isNoRecordFound : boolean;

  constructor(private blobState: BlobUploadsViewStateService) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }

  applyClass() {
    this.eventInviteeGrid.designClass.rowClass = [function () {
      let data = this;
      if (data.roleId == CustomerRoleTypeEnum.Admin) {
        return "admin";
      }
      else {
        return "customer";
      }
    }]
  }

  async uploadToBlob(file: any, fileExtention: string): Promise<string>  {
    
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = BLOB_BASE.EventDocFiles + `${newGUID}_${new Date().toISOString()}.${ fileExtention }`;
    var res = await this.blobState.uploadItems(file, fileName);
 
    let url = res._response.request.url;
    let uploadedFileName = BLOB_BASE.EventDocFiles  + decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );
    
    // IF SUCCEED TO UPLOAD
    if ( res && (res._response.status == 200 || res._response.status == 201)  ) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined){
        this.modalView
        .show(BedRequestComponent, {
          validationMessages: JSON.stringify(
            uploadedFileName
          ),
          headerMessage: "Image failed to upload:",
        })
        .then(() => {});
      } 
    }
    return uploadedFileName;
  }

}
