<div class="modal-dialog modal-lg h-75" *rxMultilingual="">
    <div class="modal-content" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0">
                <label rxText="TaskDetailAddComponent_Title_t"></label>
            </h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="taskDetailFormGroup">
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetailAddComponent_Description_t"></label>
                        <div class="col-md-8">
                            <textarea class="form-control" rows="5" formControlName="taskDescription"
                                placeholder="Enter Task Description"
                                tabindex="1"></textarea>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.taskDescription.errors">{{taskDetailFormGroup.controls.taskDescription.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetailAddComponent_AssignedBy_t"></label>
                        <div class="col-md-8">
                            <fieldset [rxSelectExtended]="_this">
                                <rx-select mainClass="form-control" [autoComplete]="true"
                                    [queryParams]="{'lookupType':'adminUsers'}" path="api/SearchEvent/GetLookups"
                                    #rxSelect [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                                    [controlTabIndex]="2" formControlName="assignedByUserId"
                                    [selectPlaceholder]="'Select Assigned By'" [keyValueProps]="['#name#','userId']"
                                    [text]="taskDetailFormGroup.value.assignedByUserName">
                                </rx-select>
                            </fieldset>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.assignedByUserId.errors">{{taskDetailFormGroup.controls.assignedByUserId.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetailAddComponent_AssignedTo_t"></label>
                        <div class="col-md-8">
                            <fieldset [rxSelectExtended]="_this">
                                <rx-select mainClass="form-control" [autoComplete]="true"
                                    [queryParams]="{'lookupType':'adminUsers'}" path="api/SearchEvent/GetLookups"
                                    #rxSelect [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                                    [controlTabIndex]="3" formControlName="assignedToUserId"
                                    [selectPlaceholder]="'Select Assignee'" [keyValueProps]="['#name#','userId']"
                                    [text]="taskDetailFormGroup.value.assignedToUserName">
                                </rx-select>
                            </fieldset>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.assignedToUserId.errors">{{taskDetailFormGroup.controls.assignedToUserId.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetailAddComponent_CompletionDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" tabindex="4" formControlName="completionDate" pickerClass="form-control">
                            </rx-date>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.completionDate.errors">{{taskDetailFormGroup.controls.completionDate.errorMessage}}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div>
        </div>
        <div class="modal-footer">
                <button class="btn btn-success mr-0" tabindex="6" (click)="saveTask()" [disabled]="isProcessing" rxText="TaskDetailAddComponent_BtnSave_t"></button>
            <button (click)="hide()" tabindex="7" class="btn btn-outline-primary" rxText="TaskDetailAddComponent_BtnClose_t"></button>
        </div>
    </div>
</div>