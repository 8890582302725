import { PaginationEnum } from '../enums/pagination.enums';
import { prop } from '@rxweb/reactive-form-validators';

export class PaginationViewModel {
  rowCount: number = PaginationEnum.RowCount;
  pageIndex: number = PaginationEnum.PageIndex;

  @prop({defaultValue:'createdOn'})
  orderBy: string;

  @prop({defaultValue:'false'})
  sortOrder: string;
  
  noteType: number;

  clientUrl:string;
  
  @prop({defaultValue:'false'})
  isShowAll:boolean;

}
