import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldSalesChannelBase {

//#region salesChannelId Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldSalesChannelListComponent_SalesChanelId_gh', keyColumn: true })
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region salesChannel Prop
        @gridColumn({isAscending:true, style: { width: "33%", "text-align": "initial" },visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemfieldSalesChannelListComponent_SalesChannel_gh', class: ["text-left"], keyColumn: false })
        salesChannel : string;
//#endregion salesChannel Prop


//#region channelType Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'GemfieldSalesChannelListComponent_ChannelType_gh', keyColumn: false })
        //@gridColumn({ style: { width: "47%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'GemfieldSalesChannelListComponent_ChannelType_gh', keyColumn: false })
        channelType : string;
//#endregion channelType Prop

}