import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SharedModule } from '../../shared/shared.module';
import { OwnershipMappingListComponent } from './list/ownership-mapping-list.component';
import { OwnershipMappingEditComponent } from './edit/ownership-mapping-edit.component';
import { OwnershipMappingAddComponent } from './add/ownership-mapping-add.component';
import { SystemTableSettingModule } from '../../shared/system-table-settings/system-table-setting.module';
import { GridModule } from '@rxweb/grid';
import { FileModule } from '../../shared/global/file/file.module';



@NgModule({
    imports: [FileModule,
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, SharedModule,SystemTableSettingModule, GridModule],
    declarations: [OwnershipMappingListComponent, OwnershipMappingAddComponent, OwnershipMappingEditComponent],
    providers: [],
    exports: [OwnershipMappingListComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OwnershipMappingSharedModule { }

