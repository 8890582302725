<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Holiday_Process_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content"  *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="search-result-container-holidayProcessGrid" *ngIf="showAdButton" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column search-result-container">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="Label_Holiday_Process_t"></label>
        </h5>
        <button class="float-right btn btn-primary mr-1" (click)="bulkAddLeaves()" rxText="CustomerHolidayListComponent_BulkAdd_t"></button>
        <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x" ></i>
      </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="d-flex align-items-center mb-4">
          <button routerLink="/customer-holidays/add" *ngIf="showAdButton" class="btn btn-success">Add Holidays</button>
          <label class="ml-auto mr-0">
            <input type="search" (keyup.enter)="searchHolidayProcees($event)"
              class=" table_search form-control form-control-sm pr-4" placeholder="" [title]="titleSearch">
          </label>
        </div>
        <div class="tab-content">
          <div class="table-responsive" *ngIf="!isNoRecordFoundHolidayProcessList">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="holidayProcessGrid"></rx-grid>
            </div>
          </div>
          <div class="ibox-title bg-white" *ngIf="isNoRecordFoundHolidayProcessList">
            <h4>No Record Found</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-result-container-holidayListGrid">
    <div class="ibox mb-0 d-flex flex-column search-result-container" [ngClass]="showAdButton ? 'mt-4' : ''">
      <div class="ibox-title bg-white d-flex flex-wrap pr-3">
        <h5><label rxText="CustomerHolidayListComponent_AppliedLeaves_Title"></label>
        </h5>
        <label class="ml-auto mr-0">
          <input type="search" (keyup.enter)="searchHolidayList($event)"
            class=" table_search form-control form-control-sm pr-4" placeholder="" [title]="titleSearch">
        </label>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="tab-content">
          <div class="table-responsive" *ngIf="!isNoRecordFoundHolidaList">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="holidayListGrid"></rx-grid>
            </div>
          </div>
          <div class="ibox-title bg-white" *ngIf="isNoRecordFoundHolidaList">
            <h4>No Record Found</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>