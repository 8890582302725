import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBodilyLocationLookUpBase {

//#region bodilyLocationId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'bodilyLocationId', keyColumn: false})
        bodilyLocationId : any;
//#endregion bodilyLocationId Prop


//#region bodilyLocationName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'bodilyLocationName', keyColumn: false})
        bodilyLocationName : string;
//#endregion bodilyLocationName Prop

}