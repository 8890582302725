import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { LanguageContentKey, vLanguageLookUp } from '@app/models';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { LocalizationFormViewModel } from 'src/app/view-model/localization-form-view-model';
import { LocalizationContent } from 'src/app/models/extended-models/localization-content';
import { CoreComponent } from '@rxweb/angular-router';
import { FRONTSTORESERVER_URL } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
@http({
    path: "api/LocalizationContent",
})

export class AbstractFabergeLocalization extends CoreComponent {

    localizationFormGroup: IFormGroup<LocalizationContent>;
    localizationModel:LocalizationContent;
    spin: boolean = false;
    showComponent: boolean = false;
    id: number = 0;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    languageLookup:any;
    isShowGrid:boolean=false;
    isNoRecordFound:boolean=false;
    constructor(private blobState: BlobUploadsViewStateService) {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    async uploadExcelToBlob(file: any, fileExtention: string): Promise<string> {

        let fileUploadRes = [];
        let newGUID = uuidv4();
        let fileName = BLOB_BASE.ExcelSheet + `${newGUID}_${new Date().toISOString()}.${fileExtention}`;
        var res = await this.blobState.uploadItems(file, fileName);
    
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.ExcelSheet + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
    
        // IF SUCCEED TO UPLOAD
        if (res && (res._response.status == 200 || res._response.status == 201)) {
          if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.toastr.error(
            "Error occured while uploading file! Please try again."
          );
          if (uploadedFileName != undefined) {
            this.modalView
              .show(BedRequestComponent, {
                validationMessages: JSON.stringify(
                  uploadedFileName
                ),
                headerMessage: "Image failed to upload:",
              })
              .then(() => { });
          }
        }
        return uploadedFileName;
      }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
