import {IncidentOccurrenceAreaBase,LookupOccurrenceAreaBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class IncidentOccurrenceArea extends IncidentOccurrenceAreaBase 
{




//#region Generated Reference Properties
//#region lookupOccurrenceArea Prop
lookupOccurrenceArea : LookupOccurrenceAreaBase;
//#endregion lookupOccurrenceArea Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties






































}