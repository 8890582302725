import { MindMapDataTypeEnum, MindMapNodeType } from 'src/app/enums/mind-map-data-type-enum';
import { RxHttp } from '@rxweb/http';
import { MindMapData } from './mind-map-data';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';

export class RootUserMindMap {

    expandedProjectId: number = 0;
    oldExpandedProjectId: number = 0;
    expandedOrderId: number = 0;
    expandedOrderData: string = '';
    oldExpandedOrderId: number = 0;

    mindMapData: any = null;

    rootEntityName: string = '';
    profileImageUrl:string = '';
    mindMap;
    mindMapDataObj: MindMapData;

    httpClient: any;

    constructor(private mindMapService: MindMapService) {
        this.httpClient = new RxHttp();
        this.mindMapDataObj = new MindMapData();
        Window['RootUserMindMap'] = this;
    }

    async setupUserMindMap(mindMapData, rootEntityName, imageUrl) {
        this.mindMapData = mindMapData;
        this.rootEntityName = rootEntityName;
        this.profileImageUrl = imageUrl;
        if (this.mindMapData != null) {
            await this.createMindMap();
        }
    }


    async createMindMap() {
        var [projectData
            , eventData
            , orderData
            , hseData
            , knownToData
            , legalAgreementData
            , pressMediaData
            , myDirectTeamData
            , employmentData
            ,directorshipData
            ,pictureVideoData] = await Promise.all([
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.ProjectData, "project-templates.json", "Project", this.mindMapService.graphId, MindMapDataTypeEnum.PROJECT, RolePermissionEnums.Project),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.EventData, "event-templates.json", "Event", this.mindMapService.graphId, MindMapDataTypeEnum.EVENT, RolePermissionEnums.Event),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.OrderData, "order-templates.json", "Order", this.mindMapService.graphId, MindMapDataTypeEnum.ORDER, RolePermissionEnums.SalesOrders),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.HSEData, "hse-templates.json", "HSE", this.mindMapService.graphId, MindMapDataTypeEnum.HSE, RolePermissionEnums.HSE),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.KnownToData, "knownTo-templates.json", "KnownTo", this.mindMapService.graphId, MindMapDataTypeEnum.KNOWNTO, RolePermissionEnums.User),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.LegalAgreementData, "legal-agreement-templates.json", "LegalAgreement", this.mindMapService.graphId, MindMapDataTypeEnum.LEGALAGREEMENT, RolePermissionEnums.LegalAgreements),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PressMediaData, "press-media-templates.json", "PressMedia", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIA, RolePermissionEnums.PressandMedia),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.MyDirectTeamData, "my-direct-team-templates.json", "MyDirectTeam", this.mindMapService.graphId, MindMapDataTypeEnum.MYDIRECTTEAM, RolePermissionEnums.User),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.EmploymentData, "employment-templates.json", "Employment", this.mindMapService.graphId, MindMapDataTypeEnum.EMPLOYMENT, RolePermissionEnums.Supplier),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.DirectorshipData, "directorship-templates.json", "Directorship", this.mindMapService.graphId, MindMapDataTypeEnum.DIRECTORSHIP, RolePermissionEnums.Supplier),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PictureVideoData, "picture-video-templates.json", "PICTUREVIDEO", this.mindMapService.graphId, MindMapDataTypeEnum.PICTUREVIDEO, RolePermissionEnums.User)
            ]);

        var childrenArray = [];
        if (this.mindMapService.graphId == 1) {

            if (this.mindMapData.ProjectData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PROJECT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: projectData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: projectData,
                            direction: 'right',
                            selectedType: 'PROJECTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.EventData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'EVENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: eventData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: eventData,
                            direction: 'right',
                            selectedType: 'EVENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.HSEData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}` + '\')\">HSE Entries (' + this.mindMapData.HSEData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'HSE',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: hseData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: hseData,
                            direction: 'left',
                            selectedType: 'HSELIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.OrderData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}` + '\')\">Orders (' + this.mindMapData.OrderData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'ORDER',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: orderData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: orderData,
                            direction: 'left',
                            selectedType: 'ORDERLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.KnownToData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">Known To (' + this.mindMapData.KnownToData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'KNOWNTO',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: knownToData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: knownToData,
                            direction: 'left',
                            selectedType: 'KNOWNTOLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.LegalAgreementData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'LEGALAGREEMENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: legalAgreementData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: legalAgreementData,
                            direction: 'left',
                            selectedType: 'LEGALAGREEMENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.PressMediaData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PRESSMEDIA',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pressMediaData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pressMediaData,
                            direction: 'right',
                            selectedType: 'PRESSMEDIALIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.MyDirectTeamData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">My Direct Team (' + this.mindMapData.MyDirectTeamData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'MYDIRECTTEAM',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: myDirectTeamData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: myDirectTeamData,
                            direction: 'left',
                            selectedType: 'MYDIRECTTEAMLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PICTUREVIDEO',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pictureVideoData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pictureVideoData,
                            direction: 'right',
                            selectedType: 'PICTUREVIDEOLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }


            if (this.mindMapData.EmploymentData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Employments (' + this.mindMapData.EmploymentData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'EMPLOYMENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: employmentData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: employmentData,
                            direction: 'left',
                            selectedType: 'EMPLOYMENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.DirectorshipData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Directorships (' + this.mindMapData.DirectorshipData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'DIRECTORSHIP',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: directorshipData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: directorshipData,
                            direction: 'left',
                            selectedType: 'DIRECTORSHIPLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            this.mindMapService.mindMapObject = {
                format: 'nodeTree',
                data: {
                    id: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    isroot: true,
                    topic: '<h2 class="m-0" style="font-size:20px!important"><a class="d-flex align-items-center" target="_blank" href="/users/' + this.mindMapData.RootEntityId + '?activeTab=mind-map"> ' +  this.profileImageUrl  + '<b>' + this.rootEntityName + '</b></a></h2>',
                    selectedType: "",
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: childrenArray
                }
            };
        }
        else {
            this.mindMapService.tempSubgraphData.root = {
                parentNodeId: this.mindMapService.parentNodeToExpand,
                nodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                topic: '<h2 class="m-0"><a target="_blank" href="/users/' + this.mindMapData.RootEntityId + '?activeTab=mind-map"><b>' + this.rootEntityName + '</b></a></h2>',
                data: { selectedType: "ROOT" }
            };

            if (this.mindMapData.ProjectData.length > 0) {
                this.mindMapService.tempSubgraphData.project = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    data: { selectedType: "PROJECT" }
                };

                this.mindMapService.tempSubgraphData.projectList = (projectData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                    topic: projectData,
                    data: { selectedType: "PROJECTLIST" }
                };
            }
            if (this.mindMapData.EventData.length > 0) {
                this.mindMapService.tempSubgraphData.event = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    data: { selectedType: "EVENT" }
                };

                this.mindMapService.tempSubgraphData.eventList = (eventData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                    topic: eventData,
                    data: { selectedType: "EVENTLIST" }
                };
            }
            if (this.mindMapData.HSEData.length > 0) {
                this.mindMapService.tempSubgraphData.hse = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}` + '\')\">HSE Entries (' + this.mindMapData.HSEData.length + ')</span>',
                    data: { selectedType: "HSE" }
                };

                this.mindMapService.tempSubgraphData.hseList = (hseData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.LIST}`,
                    topic: hseData,
                    data: { selectedType: "HSELIST" }
                };
            }
            if (this.mindMapData.OrderData.length > 0) {
                this.mindMapService.tempSubgraphData.order = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}` + '\')\">Orders (' + this.mindMapData.OrderData.length + ')</span>',
                    data: { selectedType: "ORDER" }
                };

                this.mindMapService.tempSubgraphData.orderList = (orderData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.LIST}`,
                    topic: orderData,
                    data: { selectedType: "ORDERLIST" }
                };
            }
            if (this.mindMapData.KnownToData.length > 0) {
                this.mindMapService.tempSubgraphData.knownTo = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">Known To (' + this.mindMapData.KnownToData.length + ')</span>',
                    data: { selectedType: "KNOWNTO" }
                };

                this.mindMapService.tempSubgraphData.knownToList = (knownToData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.KNOWNTO}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                    topic: knownToData,
                    data: { selectedType: "KNOWNTOLIST" }
                };
            }
            if (this.mindMapData.LegalAgreementData.length > 0) {
                this.mindMapService.tempSubgraphData.LegalAgreement = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    data: { selectedType: "LEGALAGREEMENT" }
                };

                this.mindMapService.tempSubgraphData.LegalAgreementList = (legalAgreementData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                    topic: legalAgreementData,
                    data: { selectedType: "LEGALAGREEMENTLIST" }
                };
            }
            if (this.mindMapData.PressMediaData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMedia = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIA" }
                };

                this.mindMapService.tempSubgraphData.pressMediaList = (pressMediaData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                    topic: pressMediaData,
                    data: { selectedType: "PRESSMEDIALIST" }
                };
            }
            if (this.mindMapData.MyDirectTeamData.length > 0) {
                this.mindMapService.tempSubgraphData.myDirectTeam = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">My Direct Team (' + this.mindMapData.MyDirectTeamData.length + ')</span>',
                    data: { selectedType: "MYDIRECTTEAM" }
                };

                this.mindMapService.tempSubgraphData.myDirectTeamList = (myDirectTeamData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.MYDIRECTTEAM}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                    topic: myDirectTeamData,
                    data: { selectedType: "MYDIRECTTEAMLIST" }
                };
            }
            if (this.mindMapData.PictureVideoData.length > 0) {
                this.mindMapService.tempSubgraphData.pictureVideo = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    data: { selectedType: "PICTUREVIDEO" }
                };

                this.mindMapService.tempSubgraphData.pictureVideoList = (pictureVideoData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                    topic: pictureVideoData,
                    data: { selectedType: "PICTUREVIDEOLIST" }
                };
            }
            if (this.mindMapData.EmploymentData.length > 0) {
                this.mindMapService.tempSubgraphData.employment = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Employments (' + this.mindMapData.EmploymentData.length + ')</span>',
                    data: { selectedType: "EMPLOYMENT" }
                };

                this.mindMapService.tempSubgraphData.employmentList = (employmentData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EMPLOYMENT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                    topic: employmentData,
                    data: { selectedType: "EMPLOYMENTLIST" }
                };
            }
            if (this.mindMapData.DirectorshipData.length > 0) {
                this.mindMapService.tempSubgraphData.directorship = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.User}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Directorships (' + this.mindMapData.DirectorshipData.length + ')</span>',
                    data: { selectedType: "DIRECTORSHIP" }
                };

                this.mindMapService.tempSubgraphData.directorshipList = (directorshipData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.DIRECTORSHIP}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                    topic: directorshipData,
                    data: { selectedType: "DIRECTORSHIPLIST" }
                };
            }
        }
        return;
    }
}
