import {vProjectEventMappingsBase,} from '@app/database-models'
//Generated Imports
export class vProjectEventMapping extends vProjectEventMappingsBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}