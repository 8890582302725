<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName"
  *rxMultilingual="">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_Events_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/events']" rxText="Label_Events_t"></a>
      </li>
      <li class="breadcrumb-item active">Edit Event</li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <div class="mb-2">
        <h5 *ngIf="showComponent" [rxLocalisationInit]="componentName">
          <span rxText="EventEditComponent_Title_t"></span>{{eventName}}
          <ng-container *ngIf="isRestrictedEvent" [rxLocalisationInit]="componentName">
            <span class="text-danger" rxText="EventEditComponent_Restricted_Event_t"></span>
          </ng-container>
          <span *tabPermission="'mind-map'" style="border-left: 1px solid #e7eaec; margin: 0px 5px;"></span>
          <a *tabPermission="'mind-map'" (click)="activeTab('mind-map')" [title]="titleMindMap"><i class="fa fa-code-fork mx-1"
              style="font-size: 1.5em;line-height: 0.75em;vertical-align: -15%;"></i><span
              style="font-size: 12px">{{labelMindMap}}</span></a>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showTab" [ngStyle]="{'padding-bottom':selectedTab == 'event-info'?'7rem':'0'}" >
      <div [rxLocalisationInit]="componentName" class="tabs-container people-tabs">
        <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'event-info'"
              (click)="activeTab('event-info')">
              <label class="mb-0" rxText="EventAddEditComponent_EventInfo_t"></label></a>
          </li>
          <li *tabPermission="'people,corporate'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              [class.active]="selectedTab == 'invitees'" (click)="activeTab('invitees')">
              <label class="mb-0" rxText="EventAddEditComponent_InviteesAttendees_t"></label></a>
          </li>
          <li *tabPermission="'people,corporate'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              [class.active]="selectedTab == 'people'" (click)="activeTab('people')">
              <label class="mb-0" rxText="EventAddEditComponent_PeopleCorporate_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'pictures'"
              (click)="activeTab('pictures')">
              <label class="mb-0" rxText="EventAddEditComponent_PicturesVideos_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'e-invite'"
              (click)="activeTab('e-invite')">
              <label class="mb-0" rxText="EventAddEditComponent_E_Invitation_t"></label></a>
          </li>
          <li *tabPermission="'project'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              [class.active]="selectedTab == 'projects'" (click)="activeTab('projects')">
              <label class="mb-0" rxText="EventAddEditComponent_Projects_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'summary'"
              (click)="activeTab('summary')">
              <label class="mb-0" rxText="EventAddEditComponent_Summary_t"></label></a>
          </li>
          <li *tabPermission="'press-media'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              [class.active]="selectedTab == 'press-media'" (click)="activeTab('press-media')">
              <label class="mb-0" rxText="EventAddEditComponent_PressMedia_t"></label></a>
          </li>
          <li *tabPermission="'legal-agreement'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              [class.active]="selectedTab == 'legal-agreement'" (click)="activeTab('legal-agreement')">
              <label class="mb-0" rxText="EventAddEditComponent_LegalAgreements_t"></label></a>
          </li>
          <li *tabPermission="'risk'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              [class.active]="selectedTab == 'risk'" (click)="activeTab('risk')">
              <label class="mb-0" rxText="EventAddEditComponent_Risks_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'travel'"
              (click)="activeTab('travel')">
              <label class="mb-0" rxText="EventAddEditComponent_Travel_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'orders'"
              (click)="activeTab('orders')">
              <label class="mb-0" rxText="EventAddEditComponent_Orders_t"></label></a>
          </li>
          <li *tabPermission="'mind-map'">
            <a [rxLocalisationInit]="componentName" class="nav-link" data-toggle="tab"
              class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'mind-map'"
              (click)="activeTab('mind-map')">
              <label class="mb-0" rxText="EventAddEditComponent_VisualMindMap_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'tasks'" (click)="activeTab('tasks')">
              <label class="mb-0" rxText="EventAddEditComponent_Tasks_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'notes'" (click)="activeTab('notes')">
              <label class="mb-0" rxText="EventAddEditComponent_Notes_t"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'documents'"
              (click)="activeTab('documents')">
              <label class="mb-0" rxText="EventAddEditComponent_DocumentFiles_t"></label></a>
          </li>
        </ul>
        <!-- <div class="slick_demo-btn"></div> -->
        <div class="tab-content">
          <div role="tabpanel" *ngIf="selectedTab == 'event-info'" class="tab-pane"
            [class.active]="selectedTab == 'event-info'" [rxSpinner]="spin">
            <div class="panel-body">
              <div class="bg-white d-flex flex-column flex-grow-1" [formGroup]="eventFormGroup" *ngIf="showComponent" [rxLocalisationInit]="componentName">
                <div class="row add-countries-form">
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_LinkedProject_t"></label>
                      <div class="col-md-8 col-form-label">
                        <ol style="list-style: decimal;" class="pl-3">
                          <span *ngFor="let item of event.projectEventMappings">
                            <li>
                              <a [routerLink]="['/projects',item.projectID]"
                                target="_blank">{{item.project.projectName}} ({{item.project.projectID}}) </a>
                            </li>
                          </span>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row add-countries-form">
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Name_t"></label>
                      <div class="col-md-8">
                        <input formControlName="eventName" tabindex="1" type="text" class="form-control"
                          rxPlaceholder="EventAddEditComponent_Name_p" rxFocus />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.eventName.errors">{{eventFormGroup.controls.eventName.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label" rxText="EventAddEditComponent_Description_t"></label>
                      <div class="col-md-8">
                        <textarea formControlName="eventDescription" tabindex="4" rows="4" class="form-control"
                          rxPlaceholder="EventAddEditComponent_Description_p"></textarea>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.eventDescription.errors">{{eventFormGroup.controls.eventDescription.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Format_t"></label>
                      <div class="col-md-8">
                        <textarea formControlName="eventFormat" tabindex="7" rows="4" class="form-control"
                          rxPlaceholder="EventAddEditComponent_Format_p"></textarea>
                        <small class="form-text text-danger d-none"
                          [class.d-block]="eventFormGroup.controls.eventFormat.errors">{{eventFormGroup.controls.eventFormat.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Location_t"></label>
                      <div class="col-md-8">
                        <input formControlName="location" tabindex="10" type="text" class="form-control"
                          rxPlaceholder="EventAddEditComponent_Location_p" />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.location.errors">{{eventFormGroup.controls.location.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Owner_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <rx-select [autoComplete]="false" [(source)]="eventLookup.eventOwnerLookUp" #rxSelect
                          [selectPlaceholder]="'Select Event Owner'" mainClass="form-control"
                          formControlName="reportFiledBy" [controlTabIndex]="13" [keyValueProps]="['fullName','userId']"
                          [text]="fullName">
                        </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.reportFiledBy.errors">{{eventFormGroup.controls.reportFiledBy.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Group_Company_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <rx-select [(source)]="eventLookup.genfieldsGroupEntity" [controlTabIndex]="16" #rxSelect
                          mainClass="form-control" formControlName="gemfieldsGroupCompanyEventOwner" [keyValueProps]="['company','supplierId']"
                          [selectPlaceholder]="'Select Gemfields Group Entity'">
                        </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.gemfieldsGroupCompanyEventOwner.errors">{{eventFormGroup.controls.gemfieldsGroupCompanyEventOwner.errorMessage}}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_StartDate_t"></label>
                      <div class="col-md-8">
                        <rx-date showAddon="true" [controlTabIndex]="2" formControlName="eventDate"
                          placeholder="Select Event Start Date" pickerClass="form-control">
                        </rx-date>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.eventDate.errors">{{eventFormGroup.controls.eventDate.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_EndDate_t"></label>
                      <div class="col-md-8">
                        <rx-date showAddon="true" [controlTabIndex]="5" formControlName="eventEndDate"
                          placeholder="Select Event End Date" pickerClass="form-control">
                        </rx-date>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.eventEndDate.errors">{{eventFormGroup.controls.eventEndDate.errorMessage}}</small>
                        <small class="form-text text-danger" *ngIf="dateError==true">Event End
                          Date should be greater
                          than Event Start Date<br /></small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Type_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <rx-select [(source)]="eventLookup.eventTypeLookUp" #rxSelect
                          [selectPlaceholder]="'Select Event Type'" mainClass="form-control" formControlName="eventType"
                          [controlTabIndex]="8" [keyValueProps]="['eventTypeName','eventTypeId']"
                          (changed)="deliveryMethodValidation($event)"></rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.eventType.errors">{{eventFormGroup.controls.eventType.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Website_t"></label>
                      <div class="col-md-8">
                        <input formControlName="website" type="text" tabindex="11" class="form-control "
                          rxPlaceholder="EventAddEditComponent_Website_p" />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.website.errors">{{eventFormGroup.controls.website.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Indicator_t"></label>
                      <div class="col-md-8">
                        <input formControlName="eventIndicator" tabindex="14" type="text" class="form-control "
                          rxPlaceholder="EventAddEditComponent_Indicator_p" />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.eventIndicator.errors">{{eventFormGroup.controls.eventIndicator.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Country_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <rx-select [(source)]="eventLookup.countryLookUp" [controlTabIndex]="17" #rxSelect
                          mainClass="form-control" formControlName="countryId"
                          [keyValueProps]="['countryName','countryId']" [selectPlaceholder]="'Select Country'">
                        </rx-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Department_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <rx-select [(source)]="eventLookup.departmentLookUp" [controlTabIndex]="19" #rxSelect
                          mainClass="form-control" formControlName="department" [keyValueProps]="['departmentName', 'departmentId']"
                          [selectPlaceholder]="'Select Department'">
                        </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.department.errors">{{eventFormGroup.controls.department.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Type_Of_Services_t"></label>
                      <div class="col-md-8" [rxTagExtended]="_this">
                        <rx-tag #rxTag [controlTabIndex]="20" [(source)]="eventLookup.eventServiceTypeLookUp"
                          formControlName="eventServiceTypeIds" mainClass="form-control" placeholder=" Select Types of Support / Services"
                          [isSort]="false" [keyValueProps]="['eventServiceName','eventServiceId']">
                        </rx-tag>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_CostPerGuest_t"></label>
                      <div class="col-md-8">
                        <input formControlName="costPerGuest" tabindex="3" type="text" class="form-control "
                          rxPlaceholder="EventAddEditComponent_CostPerGuest_p" />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.costPerGuest.errors">{{eventFormGroup.controls.costPerGuest.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Budget_t"></label>
                      <div class="col-md-8">
                        <input formControlName="budget" tabindex="6" type="text" class="form-control "
                          rxPlaceholder="EventAddEditComponent_Budget_p" />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.budget.errors">{{eventFormGroup.controls.budget.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_ActCost_t"></label>
                      <div class="col-md-8">
                        <input formControlName="actualCost" tabindex="9" type="text" class="form-control "
                          rxPlaceholder="EventAddEditComponent_ActCost_p" />
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.actualCost.errors">{{eventFormGroup.controls.actualCost.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_SalesLeads_t"></label>
                      <div class="col-md-8">
                        <textarea formControlName="salesLeads" tabindex="12" rows="4" class="form-control"
                          rxPlaceholder="EventAddEditComponent_ReportSalesLeads_p"></textarea>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.salesLeads.errors">{{eventFormGroup.controls.salesLeads.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Picture_t"></label>
                      <div class="col-md-8">
                        <app-file [fileViewModel]="fileViewModel" [tabindex]="'15'" (files)="updateFiles($event)"
                          [title]="imageTooltip">
                        </app-file>
                        <img *ngIf="eventFormGroup.value.displayImage && eventFormGroup.value.fileData==null"
                          src="{{eventFormGroup.value.imageURL1}}" class="event-img  mt-2 upload-img-preview" />
                        <img *ngIf="eventFormGroup.value.fileData!=null" [src]="getImgContent()"
                          class="event-img mt-2 upload-img-preview" />
                        <ng-container *ngIf="eventFormGroup.value.displayImage || eventFormGroup.value.fileData!=null"
                          [rxLocalisationInit]="componentName">
                          <button (click)="deleteImage()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                        </ng-container>
                      </div>
                    </div>
                    <div class="form-group row" [formGroup]="eventFormGroup.controls.eventBooking">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Booking_t"></label>
                      <div class="col-md-8">
                        <ng-container *ngIf="showDropdown==true && bookingLookup.length>0" [rxSelectExtended]="_this">
                          <rx-select [(source)]="bookingLookup" #rxSelect [controlTabIndex]="18"
                            mainClass="form-control" formControlName="bookingID"
                            [selectPlaceholder]="'Select Event Booking'" [keyValueProps]="['tripName','tripId']">
                          </rx-select>
                        </ng-container>
                        <span *ngIf="showDropdown==false && bookingName!=null">
                          <label class="d-block">{{bookingName}}</label>
                        </span>
                        <button (click)="searchBookings()" class="btn btn-primary mr-1 mt-1"
                          title="You need to select Event Start date in order to search bookings"
                          rxText="Btn_Search_Booking_t"></button>
                        <small class="form-text text-danger" *ngIf="bookingError==true">You need
                          to select Event Start
                          date in order to search bookings</small>
                        <small class="form-text text-danger" *ngIf="showDropdown==true && bookingLookup.length == 0">No
                          Bookings found.</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-auto" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                  <div class="hr-line-dashed border-top w-100 mb-2"></div>
                  <div class="ibox-title col-12 bg-white px-0 border-bottom mb-4">
                    <h5 class="ml-2" rxText="EventAddEditComponent_Training_Details_t"></h5>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Delivery_Method_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <!-- <input formControlName="deliveryMethod" type="text" tabindex="11" class="form-control "
                               rxPlaceholder="" placeholder="method of delivery" /> -->
                        <rx-select [(source)]="eventLookup.eventTrainerDeliveryMethod" #rxSelect [controlTabIndex]="21"
                          mainClass="form-control" formControlName="deliveryMethod"
                          [keyValueProps]="['eventTrainingTypeName','eventTrainingTypeId']"
                          [selectPlaceholder]="localizationData.EventAddEditComponent_Delivery_Method_p_p">
                        </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="eventFormGroup.controls.deliveryMethod.errors">{{eventFormGroup.controls.deliveryMethod.errorMessage}}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Risk_Category_t"></label>
                      <div class="col-md-8" [rxSelectExtended]="_this">
                        <rx-select [(source)]="eventLookup.eventEmpoyeeRiskCategory" #rxSelect [controlTabIndex]="22"
                          mainClass="form-control" formControlName="riskCategory"
                          [keyValueProps]="['eventTrainingTypeName','eventTrainingTypeId']"
                          [selectPlaceholder]="localizationData.EventAddEditComponent_Risk_Category_p_p">
                        </rx-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 d-none d-xl-block col-xl-4 pr-xl-4">
                    <div class="form-group row checkbox"
                      *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Attendance_Uploaded_t"></label>
                      <div class="col-md-auto d-flex align-items-center">
                        <div class="i-checks">
                          <label class="mb-0">
                            <app-checkbox tabindex="23"
                              [checkFormControl]="eventFormGroup.controls.isAttendanceUploaded">
                            </app-checkbox>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Trainer_Details_t"></label>
                      <div class="col-md-8">
                        <textarea formControlName="trainerDetails" tabindex="24" rows="4" class="form-control"
                          rxPlaceholder="EventAddEditComponent_Trainer_Details_p"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Training_Area_Covered_t"></label>
                      <div class="col-md-8" [rxTagExtended]="_this">
                        <rx-tag [source]="eventLookup.eventTrainerCoveredArea" #rxTag mainClass="form-control"
                          formControlName="trainingAreas"
                          [keyValueProps]="['eventTrainingTypeName','eventTrainingTypeId']"
                          placeholder="{{localizationData.EventAddEditComponent_Training_Area_Covered_p_p}}"
                          [isSort]="false">
                        </rx-tag>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row checkbox"
                      *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Included_In_Business_t"></label>
                      <div class="col-md-auto d-flex align-items-center">
                        <div class="i-checks">
                          <label class="mb-0">
                            <app-checkbox tabindex="25"
                              [checkFormControl]="eventFormGroup.controls.isIncludeBusinessElement">
                            </app-checkbox>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 d-xl-none col-xl-4 pr-xl-4">
                    <div class="form-group row checkbox"
                      *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                        rxText="EventAddEditComponent_Attendance_Uploaded_t"></label>
                      <div class="col-md-auto d-flex align-items-center">
                        <div class="i-checks">
                          <label class="mb-0">
                            <app-checkbox tabindex="26"
                              [checkFormControl]="eventFormGroup.controls.isAttendanceUploaded">
                            </app-checkbox>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-auto">
                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="col text-right">
                    <button tabindex="27" (click)="EditEventInfo(false)" class="btn btn-success"
                      rxText="Btn_Save_t"></button>
                    <button tabindex="28" (click)="EditEventInfo(true)" class="btn btn-primary ml-1"
                      rxText="Btn_Save_Continue_Edit_t">
                    </button>
                    <ng-container *ngIf="eventDelete" [rxLocalisationInit]="componentName">
                      <button tabindex="29" (click)="DeleteEvent()" class="btn btn-danger ml-1"
                        rxText="Btn_Delete_t"></button>
                    </ng-container>
                    <!-- <button (click)="EditEventInfo(false)" class="btn btn-success ml-3" *rxAuthorize="[eventPut]">Save</button>
                                  <button (click)="EditEventInfo(true)" class="btn btn-primary ml-3" *rxAuthorize="[eventPut]">Save & Continue Edit</button> -->
                    <!-- <button (click)="DeleteEvent()" class="btn btn-danger mr-1">Delete</button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *tabPermission="'people,corporate'">
            <div role="tabpanel" *ngIf="selectedTab == 'invitees'" [class.active]="selectedTab == 'invitees'"
              class="tab-pane">
              <div class="panel-body">
                <app-event-customer-mapping-list [selectedTab]="selectedTab">
                </app-event-customer-mapping-list>
              </div>
            </div>
          </div>
          <div *tabPermission="'people,corporate'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'people'"
              [class.active]="selectedTab == 'people'" class="tab-pane">
              <div class="panel-body">
                <app-project-corporate-mapping-list [componentType]="'events'" [selectedTab]="selectedTab"
                  [moduleID]="moduleID" [isRestricted]="isRestrictedEvent">
                </app-project-corporate-mapping-list>
              </div>
            </div>
          </div>
          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'pictures'"
            [class.active]="selectedTab == 'pictures'" class="tab-pane">
            <div class="panel-body">
              <app-document-list [entityTypeId]="entityTypeId" [isPicture]="true" [selectedTab]="selectedTab"></app-document-list>
            </div>
          </div>
          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'e-invite'"
            [class.active]="selectedTab == 'e-invite'" class="tab-pane">
            <div class="panel-body">
              <app-event-send-einvitation-edit></app-event-send-einvitation-edit>
            </div>
          </div>

          <div *tabPermission="'project'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'projects'"
              [class.active]="selectedTab == 'projects'" class="tab-pane">
              <div class="panel-body">
                <app-project-event-mapping-list [selectedTab]="selectedTab" [moduleID]="moduleID">
                </app-project-event-mapping-list>
              </div>
            </div>
          </div>

          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'summary'"
            [class.active]="selectedTab == 'summary'" class="tab-pane">
            <div class="panel-body">
              <app-event-summary-list [selectedTab]="selectedTab" [moduleID]="moduleID"></app-event-summary-list>
            </div>
          </div>
          <!-- Task tab -->

          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'tasks'" [class.active]="selectedTab == 'tasks'"
            class="tab-pane">
            <div class="panel-body">
              <app-task-detail-list [entityTypeId]="entityTypeId" [entityId]="entityId"></app-task-detail-list> 
            </div>
          </div>

          <!-- task end -->
          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'notes'" [class.active]="selectedTab == 'notes'"
            class="tab-pane">
            <div class="panel-body">
              <app-note-list [entityTypeId]="entityTypeId" [entityId]="entityId" [selectedTab]="selectedTab"
                [moduleID]="moduleID" [eventProjectRestricted]="isRestrictedEvent"></app-note-list>
            </div>
          </div>
          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'documents'"
            [class.active]="selectedTab == 'documents'" class="tab-pane">
            <div class="panel-body">
              <app-document-list [entityTypeId]="entityTypeId" [isPicture]="false"
                [selectedTab]="selectedTab" [moduleID]="moduleID"></app-document-list>
            </div>
          </div>
          <div *tabPermission="'sales-order'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'orders'"
              [class.active]="selectedTab == 'orders'" class="tab-pane">
              <div class="panel-body">
                <app-corporate-orders-list [isSupplier]="false" [selectedTab]="selectedTab" [entityTypeId]="entityTypeId" [moduleID]="moduleID">
                </app-corporate-orders-list>
              </div>
            </div>
          </div>
          
          <div *tabPermission="'press-media'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'press-media'"
              [class.active]="selectedTab == 'press-media'" class="tab-pane">
              <div class="panel-body">
                <div class="col-xl-12">
                  <app-press-media-view [moduleId]="entityId" [moduleType]="'Events'" [selectedTab]="selectedTab"
                    [moduleID]="moduleID">
                  </app-press-media-view>
                </div>
              </div>
            </div>
          </div>

          <div *tabPermission="'legal-agreement'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'legal-agreement'" class="tab-pane"
              [class.active]="selectedTab == 'legal-agreement'">
              <div class="panel-body">
                <app-legal-agreement-project-mapping-list [entityTypeId]="entityTypeId" [selectedTab]="selectedTab"
                  [moduleID]="moduleID">
                </app-legal-agreement-project-mapping-list>
              </div>
            </div>
          </div>

          <div *tabPermission="'risk'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'risk'" class="tab-pane"
              [class.active]="selectedTab == 'risk'">
              <div class="panel-body">
                <div class="col-xl-12">
                  <app-project-risk-list [projectId]="id" [selectedTab]="selectedTab" [moduleID]="moduleID"
                    [entityTypeId]="entityTypeId"></app-project-risk-list>
                </div>
              </div>
            </div>
          </div>

          <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'travel'" class="tab-pane"
            [class.active]="selectedTab == 'travel'">
            <div class="panel-body">
              <div class="col-xl-12">
                <app-travel-list [eventId]="id" [selectedTab]="selectedTab" [moduleID]="moduleID"
                  [entityTypeId]="entityTypeId">
                </app-travel-list>
              </div>
            </div>
          </div>

          <div *tabPermission="'mind-map'">
            <div role="tabpanel" id="tab-billing" *ngIf="selectedTab == 'mind-map'" class="tab-pane"
              [class.active]="selectedTab == 'mind-map'">
              <div class="panel-body">
                <app-mind-map-detail [entityID]="id" [entityName]="eventName" [moduleID]="moduleID"
                  [selectedTab]="selectedTab" [imageUrl]="ProfileImageTag">
                </app-mind-map-detail>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
