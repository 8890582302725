import { ProjectRateMappingBase } from '../database-models/project-rate-mapping-base';
import { prop } from '@rxweb/reactive-form-validators';

export class ProjectRateMapping extends ProjectRateMappingBase {
    projectRateMappingIds: ProjectRateMapping[];

    @prop()
    projectName:string;

    @prop()
    projectStartDate:string;

    @prop()
    projectEndDate:string;

    @prop()
    projectTypeName:number;
}