import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    visible:false,
    name:'headerCheckbox',
    allowSorting: false,
    style: { width: "5%","text-align": "initial"},
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 2
  })
export class RelatedDesignViewModel{
    totalCount:number;
    designId:number;
    relatedDesignId:number;

    @gridColumn({name:'isChecked',style: { width: "4%", "text-align": "initial" },visible: true,configuredTemplate: { templateName: 'bindCheckBox' },allowSorting:false, columnIndex: 1, headerTitle: '', keyColumn: false })
    isChecked:boolean

    @gridColumn({name:'isChecked1',style: { width: "1%", "text-align": "initial" },visible: false,configuredTemplate: { templateName: 'bindCheckBox' },allowSorting:false, columnIndex: 0, /*headerTitle: 'Action'*/ headerKey:'RelatedProductDesignListComponent_Action_gh', keyColumn: false })
    isChecked1:boolean
 
    @gridColumn({parameter:false,style: { width: "25%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 3, allowSorting: true, headerKey: 'RelatedProductDesignListComponent_DesignName_gh', keyColumn: false })
    designName:string;

    @gridColumn({
        template:{ 
            div: {
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onData"
                            },
                            attributes: {
                              type: "number",
                              value: function (e) {
                                return this.displayOrder;
                              },
                              min:1,
                              step:1,
                              pattern:"[0-9]+"
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,name:'displayOrder',style: { width: "5%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: false, columnIndex: 6, allowSorting: true, headerKey: 'RelatedProductDesignListComponent_DisplayOrder_gh', keyColumn: false })
    displayOrder:number;

    @gridColumn({
        template:{
          div:{
            attributes:{
              innerHTML:function(x,y){
               return this.ViewButton;
              }
            },
          }
      },
    parameter:false,style: { width: "4%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 5, allowSorting: false, /*headerTitle: 'View'*/headerKey:'RelatedProductDesignListComponent_View_gh', name:'ViewButton',keyColumn: false })
    ViewButton:string;

    @gridColumn({ name:'published',parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'RelatedProductDesignListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
   published:boolean;

   @prop({defaultValue:false})
   isDelete:boolean;

   relatedProductID:number;
}