import {vGrievanceTypeBase,} from '@app/database-models'
//Generated Imports
export class vGrievanceType extends vGrievanceTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}