//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';
export class MoveNoteDocumentHSESearchViewModel {

    id: number;

    @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
    isChecked: boolean

    isAllowMoveNoteDocument: boolean
    
    //#region incidentID
    @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 0, allowSorting: true, headerKey: 'incidentID', keyColumn: true })
    incidentID: number;
    //#endregion incidentID

    @gridColumn({

        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.incidentURL == null) {
                            return "";
                        } else {

                            return this.incidentURL;
                        }
                    }
                }
            }
        }, style: { width: "10%", "min-width": "75px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'MoveNoteDocumentComp_HSERefNo_gh', keyColumn: false
    })
    incidentURL: string;

    //#region ref_No
    //  @gridColumn({ configuredTemplate: { templateName:"reDirect" }, parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'TrackIncidentListComponent_referenceNo_gh', keyColumn: false })
    //  // @gridColumn({ configuredTemplate: { templateName:"reDirect" }, parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerTitle: 'Ref. No.', keyColumn: false })
    ref_No: string;
    //#endregion ref_No

    //#region stOccurrence_date
    @gridColumn({ parameter: false, allowSorting: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'MoveNoteDocumentComp_HSEOccurranceDate_gh', keyColumn: false})
    //@gridColumn({ parameter: false, allowSorting: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2,headerTitle: 'Occurrence Date', keyColumn: false })
    stOccurrence_date: Date;
    //#endregion stOccurrence_date

    //#region occurrence_date
    occurrence_date: Date;
    //#endregion occurrence_date

    //#region gemfieldsGroupEntity
    @gridColumn({ parameter: false, style: { width: "10%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_HSECompany_gh', keyColumn: false })
    //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerTitle: 'Gemfields Group Entity', keyColumn: false })
    gemfieldsGroupEntity: string;
    //#endregion gemfieldsGroupEntity

    //#region occurrenceType
    // @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: true, headerKey: 'TrackIncidentListComponent_typeOfOccurrence_gh', keyColumn: false })
    //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerTitle: 'Type of occurrence', keyColumn: false })
    occurrenceType: string;
    //#endregion occurrenceType
    //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerTitle: 'Type of Incident', keyColumn: false })
    typeofIAN: string;
    typeofIANHtml: string;
    //#endregion typeofIAN

    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.occurrenceDescriptionHtml;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.occurrenceDescCount > 100) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreNotelick"
                        }
                    },
                }]
            }
        }, parameter: false, visible: false, columnIndex: 11, allowSorting: true, headerKey: 'TrackIncidentListComponent_occurrenceDesc_gh', name: 'occurrenceDescription', style: { width: "10%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], keyColumn: false,headerTitle:"Desc"
    })

    occurrenceDescription: number;
    occurrenceDescriptionHtml: string;
    //#endregion occurrenceDescription

    totalCount: number
    injuredPersons: any[];
    riskDetail: any[];
    countOfRisks: number;
    persons: string;
    risksData: string;
    isRestrictedForm: boolean;
    isInvolved: boolean;
    occurrenceDescCount: number;
    typeofIANCount: number;


}
