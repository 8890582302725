import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HomeLayoutBase {

        //#region layoutId Prop
        @prop()
        layoutId: number;
        //#endregion layoutId Prop


        //#region sectionName Prop
        @required()
        @notEmpty()
        @trim()
        sectionName: string;
        //#endregion sectionName Prop


        //#region published Prop
        @required()
        published: boolean;
        //#endregion published Prop


        //#region url Prop
        @required()
        @notEmpty()
        @trim()
        url: string;
        //#endregion url Prop


        //#region mainLayoutId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        mainLayoutId: number;
        //#endregion mainLayoutId Prop


        //#region class Prop
        @required()
        class: boolean;
        //#endregion class Prop

        @required()
        @prop({defaultValue:'1'})
        textPosition:string;

        //#region btnColor Prop
        @required()
        btnColor: boolean;
        //#endregion btnColor Prop


       
        



}