import { gridColumn } from '@rxweb/grid';

export class vLocalRiskOwnerLookUpBase{

    //#region localRiskOwner Prop
    @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'localRiskOwner', keyColumn: true})
    localRiskOwner : number;
    //#endregion localRiskOwner Prop


    //#region fullName Prop
    @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'fullName', keyColumn: false})
    fullName : string;
    //#endregion fullName Prop
}