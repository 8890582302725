<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
    <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']">Home</a>
            </li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
            </li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/queued-emails']" rxText="Label_Message_Queue_t"></a>
            </li>
            <!-- <li class="breadcrumb-item active">List</li> -->
        </ol>
    </div>
    <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
    </div>
</div>

<div class="wrapper wrapper-content" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
            <h5><label rxText="Label_Message_Queue_t"></label>
            </h5>
            <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x"></i>
            </a>
        </div>


        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [rxLocalisationInit]="componentName" *ngIf="showComponent" [formGroup]="queuedEmailSearchFormGroup"  [rxSpinner]="spin">

            <div class="row people_form_details" (keyup.enter)="onKeyPress()">

                <div class="col-lg-6 col-xl-4 pr-xl-5">

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_StartDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" rxFocus formControlName="startDate" placeholder="Select Start Date" [controlTabIndex]="1" pickerClass="form-control"  rxFocus></rx-date>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_EndDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" formControlName="endDate" placeholder="Select End Date" [controlTabIndex]="4" pickerClass="form-control" ></rx-date>
                                    <small class="form-text text-danger" *ngIf="dateError==true">Registration To should
                                        be greater than Registration From<br /></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_GoToEmail_t"></label>
                        <div class="col-md-6">
                            <input type="text" class="form-control" formControlName="queuedEmailId" tabindex="6" rxPlaceholder="QueuedEmailListComponent_GoToEmail_p" />
                            <small class="form-text text-danger" [class.d-block]="queuedEmailSearchFormGroup.controls.queuedEmailId.errors">{{queuedEmailSearchFormGroup.controls.queuedEmailId.errorMessage}}</small>
                        </div>
                        <div class="col-md-2">
                            <button (click)="goDirectlyToEmail()" tabindex="7" class="form-control btn btn-success mr-1 search-result-btn">Go</button>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-4 pr-xl-5">

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_FromAddress_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" tabindex="2" formControlName="emailFrom" rxPlaceholder="QueuedEmailListComponent_FromEmail_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_ToAddress_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" tabindex="5" formControlName="emailTo" rxPlaceholder="QueuedEmailListComponent_ToEmail_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_MaxSendAttempt_t"></label>
                        <div class="col-md-8">
                            <input class="form-control" formControlName="sendTries" tabindex="8" rxPlaceholder="QueuedEmailListComponent_MaxAttempt_p">
                            <small class="form-text text-danger" [class.d-block]="queuedEmailSearchFormGroup.controls.sendTries.errors">{{queuedEmailSearchFormGroup.controls.sendTries.errorMessage}}</small>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-4 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_NotSentEmailOnly_t"></label>
                        <div class="col-md-8">
                            <app-checkbox [tabIndex]="'3'" [checkFormControl]="queuedEmailSearchFormGroup.controls.loadNotSentItemsOnly">
                            </app-checkbox>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="QueuedEmailListComponent_Subject_t"> </label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" tabindex="5" formControlName="subject" rxPlaceholder="QueuedEmailListComponent_Subject_p" />
                        </div>
                    </div>
                </div>

            </div>

            <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100 mt-1"></div>
                <div class="col text-right">
                    <button class="btn btn-success mr-1 search-result-btn" (click)="onKeyPress()" rxText="Btn_Search_t"></button>
                    <ng-container *ngIf="queueEmailDelete" [rxLocalisationInit]="componentName">
                        <button (click)="deleteSelected()" class="btn btn-danger mr-1" [disabled]="this.queuedEmailIds.length === 0" rxText="Btn_Delete_Selected_t"></button>
                    </ng-container>
                </div>
            </div>

        </div>

    </div>

    <div class="search-result-container" *ngIf="noRecordFound==false">     
         <!-- 
        <div class="ibox-title bg-white d-flex flex-wrap pr-5">
            <h5>Search Result</h5>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
            <div class="table-responsive">
                <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                    <rx-grid [design]="queuedEmailListGrid"></rx-grid>
                </div>
            </div>


        </div>  -->

 

    </div>

    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="noRecordFound==false" [rxLocalisationInit]="componentName">
        <div class="ibox-title bg-white">
            <h5 rxText="Label_Search_Result_t"></h5>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" id="messageQueueTable">
            <div class="dataTables_filter  d-flex flex-wrap align-items-center">
                <!-- <label class="ml-auto mr-0 mb-3">
                        <input (keyup)="search($event)" type="search"
                            class=" table_search form-control form-control-sm pr-4" placeholder="">
                    </label> -->
            </div>
            <div class="table-responsive">
                <div class="dataTables_wrapper pb-0 dt-bootstrap4" id="queuedEmailListGrid">
                    <rx-grid [design]="queuedEmailListGrid"></rx-grid>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="search-result-container" *ngIf="noRecordFound">
    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container">
        <div class="ibox-title bg-white d-flex flex-wrap pr-5">
            <h5>Search Result</h5>
            <div></div>
        <div class="table-responsive" >
            <h1>No Record Found</h1>
          </div>
        </div>
    </div>
</div> -->
    <div class="ibox-title bg-white" *ngIf="noRecordFound" [rxLocalisationInit]="componentName">
        <h5 rxText="Label_Search_Result_t"></h5>
        <h4>No Record Found</h4>
    </div>
    <!-- <p *ngIf="noRecordFound"><b>No Record Found</b></p> -->

</div>