import {vPersonCorporateMappingBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 8, headerTitle: "Action"
})
export class vPersonCorporateMapping extends vPersonCorporateMappingBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}