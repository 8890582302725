import { RxHttp } from '@rxweb/http';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { MindMapNodeType } from 'src/app/enums/mind-map-data-type-enum';

export class MindMapData {

    template: any;

    async getTemplateFormattedData(rawData: any[], templateName: string, keyPrefix: string, graphId: number = 1, dataTypeId: number = 0, moduleTypeId: number = 0) {
        this.template = null;
        var httpClient = new RxHttp();
        var nodeId = `${graphId}_${dataTypeId}_${moduleTypeId}_${MindMapNodeType.LIST}`;
        var response = await httpClient.get({ hostUri: CLIENT_URL, path: "assets/mind-map-templates/" + templateName }).toPromise();
        if (response != undefined && response != null) {
            this.template = response;
        }
        if (rawData.length > 0 && this.template != null) {
            var nodeTemplate = this.template.node;
            var recordTemplate = this.template.record;
            var innerHtml = "";
            rawData.forEach(dataElement => {
                var record = recordTemplate;
                Object.keys(dataElement).forEach(key => {
                    record = record.replaceAll("%" + keyPrefix + "." + key + "%", dataElement[key]);
                });
                record = record.replaceAll("%NodeUID%", nodeId);
                innerHtml += record;
            });

            nodeTemplate = nodeTemplate.replace("%" + keyPrefix + ".Records%", innerHtml);
            return nodeTemplate;
        }
        else {
            return '';
        }
    }

}