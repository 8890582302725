import { IANCompanyCustomerMappingBase, } from '@app/database-models'
import { prop, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class IANCompanyCustomerMapping extends IANCompanyCustomerMappingBase {

    //#region Generated Reference Properties

    @required({ message: "Please Select Person" })
    customerIds: string;

    //#endregion Generated Reference Properties

    @prop()
    NearMissUnSafeAct: boolean;
    @prop()
    AccidentInjury: boolean;
    @prop()
    Incident: boolean;
    @prop()
    Grievance: boolean;
  
}