import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DesignProductStatusBase {

//#region designProductStatusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        designProductStatusId : number;
//#endregion designProductStatusId Prop


//#region designProductStatusName Prop
        @required()
        @maxLength({value:150})
        designProductStatusName : string;
//#endregion designProductStatusName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}