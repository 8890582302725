<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/departments']" rxText="Label_Department_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="DepartmentEditComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="DepartmentEditComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="departmentFormGroup"
         *ngIf="showTab" [rxLocalisationInit]="componentName">
        <div class="tabs-container people-tabs">
          <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0  slick-slider" role="tablist">
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'department-info'"
                (click)="activeTab('department-info')"><label class="mb-0" rxText="DepartmentAddEditComponent_DepartmentInfo_t"></label></a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'customer-detail'"
                (click)="activeTab('customer-detail')"><label class="mb-0" rxText="DepartmentAddEditComponent_CustOfDepartment_t"></label></a>
            </li>
          </ul>
          <div class="slick_demo-btn"></div>
          <div class="tab-content" [rxSpinner]="spin">
            <div role="tabpanel" *ngIf="selectedTab == 'department-info'" class="tab-pane"
              [class.active]="selectedTab == 'department-info'" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
              <div class="panel-body">
                <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showComponent"
                  [formGroup]="departmentFormGroup" [rxLocalisationInit]="componentName">
                  <div class="row add-countries-form" (keyup.enter)="editDepartment(false)">
                    <div class="col-md-4 pr-xl-4">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label"  rxText="DepartmentEditComponent_ID_t"></label>
                        <div class="col-md-8">
                          <label type="text" 
                                      class="form-control disableLabel-withoutheight mb-0">{{(departmentFormGroup.value.departmentId
                                      === null || departmentFormGroup.value.departmentId === undefined ) ? '' :
                                      departmentFormGroup.value.departmentId}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pr-xl-5">
                      <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                          rxText="DepartmentAddEditComponent_Name_t"></label>
                        <div class="col-md-8">
                          <input type="text" formControlName="name" class="form-control "
                             rxPlaceholder="DepartmentAddEditComponent_Name_p"
                            rxFocus />
                          <small class="form-text text-danger"
                            [class.d-block]="departmentFormGroup.controls.name.errors">{{departmentFormGroup.controls.name.errorMessage}}<br /></small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 px-xl-4">
                      <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                          rxText="DepartmentAddEditComponent_Type_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                          <!-- <select class="form-control" formControlName="peopleType">
                            <option *ngFor="let item of departmentLookup.peopleTypeLookUp" [value]="item.peopleTypeId">
                              {{item.peopleTypeName}}</option>
                          </select> -->
                          <rx-select [(source)]="departmentLookup.peopleTypeLookUp" #rxSelect mainClass="form-control"
                            formControlName="peopleType"
                            [keyValueProps]="['peopleTypeName','peopleTypeId']" [selectPlaceholder]="'Select Type'">
                          </rx-select>
                          <small class="form-text text-danger"
                            [class.d-block]="departmentFormGroup.controls.peopleType.errors">{{departmentFormGroup.controls.peopleType.errorMessage}}<br /></small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-auto">
                    <div class="hr-line-dashed border-top w-100"></div>
                    <div class="col text-right">
                      <button (click)="editDepartment(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                      <button (click)="editDepartment(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                      <ng-container *ngIf="departmentDelete" [rxLocalisationInit]="componentName">
                        <button (click)="deleteDepartment()" class="btn btn-danger"
                           rxText="Btn_Delete_t"></button>
                      </ng-container>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div role="tabpanel" *ngIf="selectedTab == 'customer-detail'"
              [class.active]="selectedTab == 'customer-detail'" class="tab-pane">
              <div class="panel-body">
                <app-customer-detail [departmentId]="id"></app-customer-detail>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>

  </div>

</div>