import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vParentAdhocLookupBase {

//#region adhocId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'adhocId', keyColumn: true})
        adhocId : number;
//#endregion adhocId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop

}