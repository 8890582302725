import { prop,propObject,propArray,required,maxLength,range, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ClientRepairBase {

//#region clientRepairId Prop
        @prop()
        clientRepairId : number;
//#endregion clientRepairId Prop


//#region clientRepairGUId Prop
        @required()
        clientRepairGUId : any;
//#endregion clientRepairGUId Prop


//#region customerId Prop
        @prop()
        customerId : number;
//#endregion customerId Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region dateOfRequest Prop
        @required()
        dateOfRequest : Date;
//#endregion dateOfRequest Prop


//#region salesPersonId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        salesPersonId : number;
//#endregion salesPersonId Prop


//#region approvalStatus Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        approvalStatus : number;
//#endregion approvalStatus Prop


//#region oldFabergePieces Prop
        @prop()
        @maxLength({value:4000})
        @trim()
        oldFabergePieces : string;
//#endregion oldFabergePieces Prop


//#region estimationRequired Prop
        @required()
        estimationRequired : boolean;
//#endregion estimationRequired Prop


//#region deliveryDate Prop
        @required()
        deliveryDate : Date;
//#endregion deliveryDate Prop


//#region pieceCondition Prop
        @prop()
        @required()
        @notEmpty()
        @maxLength({value:4000})
        pieceCondition : string;
//#endregion pieceCondition Prop


//#region repairNeeded Prop
        @prop()
        @required()
        @notEmpty()
        @maxLength({value:4000})
        repairNeeded : string;
//#endregion repairNeeded Prop


//#region additionalRepairDetails Prop
        @prop()
        @maxLength({value:4000})
        additionalRepairDetails : string;
//#endregion additionalRepairDetails Prop


//#region uploadFile1 Prop
        @prop()
        uploadFile1 : string;
//#endregion uploadFile1 Prop


//#region uploadFile2 Prop
        @prop()
        uploadFile2 : string;
//#endregion uploadFile2 Prop


//#region uploadFile3 Prop
        @prop()
        uploadFile3 : string;
//#endregion uploadFile3 Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop





}