import {GemStoneCutTypeBase,ProductPieceGemstoneBase,ProductGemstoneCutMappingBase} from '@app/database-models'
//Generated Imports
export class GemStoneCutType extends GemStoneCutTypeBase 
{




//#region Generated Reference Properties
//#region productPieceGemstones Prop
productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop

//#region productGemstoneCutMappings Prop
productGemstoneCutMappings : ProductGemstoneCutMappingBase[];
//#endregion productGemstoneCutMappings Prop

//#endregion Generated Reference Properties












}