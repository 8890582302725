import { gridColumn } from '@rxweb/grid';

export class vCorporateCreditRatingBase {
    //#region corporateCreditRatingId Prop
    @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'CorporateCreditRatingListComponent_Id_gh', keyColumn: true })
    corporateCreditRatingId: number;
    //#endregion corporateCreditRatingId Prop

    //#region moody Prop
    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'CorporateCreditRatingListComponent_Moody_gh', keyColumn: false })
    moody: string;
    //#endregion moody Prop

    //#region sP Prop
    @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'CorporateCreditRatingListComponent_SP_gh', keyColumn: false })
    sp: string;
    //#endregion sP Prop

    //#region fitch Prop
    @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'CorporateCreditRatingListComponent_Fitch_gh', keyColumn: false })
    fitch: string;
    //#endregion fitch Prop

    //#region creditWorthiness Prop
    @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'CorporateCreditRatingListComponent_Worth_gh', keyColumn: false })
    creditWorthiness: string;
    //#endregion creditWorthiness Prop

    //#region statusId Prop
    // @gridColumn({ visible: false, columnIndex: 5, allowSorting: true, configuredTemplate: { templateName: "recordStatus" }, headerKey: 'statusId', keyColumn: false })
    // statusId: number;
    //#endregion statusId Prop

}