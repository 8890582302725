import { numeric, prop, required } from "@rxweb/reactive-form-validators";

export class ProductWebPictureViewModel {

  constructor()
  {
    this.productWebPictureId = 0;
    this.productVariantId = 0;
    this.pictureIndex = 0;
    this.pictureType = 0;
    this.pictureName = '';
    this.pictureURL =  '';
    this.displayOrder = 0;
  }
  @prop()
  productWebPictureId : number;

  @required()
  productVariantId : number;

  @prop()
  pictureIndex : number;

  @prop()
  pictureType : number;

  @prop()
  pictureID : number;

  @prop()
  pictureName : string;

  @prop()
  pictureURL : string;

  @numeric({allowDecimal:false})
  @required()
  displayOrder : number;
  isPreviewSupported : boolean;
}

export class ProductWebPictureListViewModel
{
  constructor()
  {
    this.EntityId = 0;
    this.EntityTypeId = 0;
    this.PictureIndex = 0;
    this.DisplayOrder = 0;
    this.IsDrag = 0;
    this.Destination = 0;
  }

  @prop()
  EntityId : number;

  @prop()
  EntityTypeId : number;

  @prop()
  PictureIndex : number;

  @prop()
  DisplayOrder : number;

  @prop()
  IsDrag : number;

  @prop()
  Destination : number;
}
