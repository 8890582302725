import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPeopleTypeLookUpBase {

//#region peopleTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'peopleTypeId', keyColumn: true})
        peopleTypeId : number;
//#endregion peopleTypeId Prop


//#region peopleTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'peopleTypeName', keyColumn: false})
        peopleTypeName : string;
//#endregion peopleTypeName Prop

}