import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Department } from '@app/models';
import { AbstractDepartment } from '../domain/abstract-department';
import { Router } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { DepartmentEditComponent } from '../edit/department-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("DepartmentAddComponent")
@access({ accessLevel: RolePermissionEnums.Department, action: "post" })

@Component({
  selector: "app-department-add",
  templateUrl: './department-add.component.html'
})
export class DepartmentAddComponent extends AbstractDepartment implements OnInit, OnDestroy {
  department: Department;
  subscription: any;
  departmentEdit: any = DepartmentEditComponent;
  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title) {
    super();
    this.modalView = modelView;
    this.router = router;
    this.moduleID = RolePermissionEnums.Department;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Department - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.lookup([{ path: USER_LOOKUPS.peopleTypeLookUp, propName: "peopleTypeLookUp" }]).subscribe((lookup: any) => {
      this.departmentLookup = lookup;
      this.department = new Department();
      this.department.departmentId = 0;
      this.department.statusId = StatusEnum.Active;
      this.departmentFormGroup = this.formBuilder.formGroup(this.department) as IFormGroup<Department>;
      this.showComponent = true;

    })
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addDepartment(isRedirect) {
    this.departmentFormGroup.submitted = true;
    if (this.departmentFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.departmentFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
			      //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert[0].showMessage);
                    }
        isRedirect ? this.router.navigate(['/departments', data]) : this.router.navigate(['/departments']);
      })
    } else {
      for (const key of Object.keys(this.departmentFormGroup.controls)) {
        if (this.departmentFormGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }

  get componentName(): string {
    return "DepartmentAddComponent";
  }
}
