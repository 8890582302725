import {SupplierDetailBase,} from '@app/database-models'
//Generated Imports
export class SupplierDetail extends SupplierDetailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}