import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileComponent } from './file.component';



@NgModule({
    declarations: [FileComponent],
    imports: [
        CommonModule
    ],
  exports: [FileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class FileModule { }
