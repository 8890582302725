import {WatchAttributeMappingsLocalizationBase,} from '@app/database-models'
//Generated Imports
export class WatchAttributeMappingsLocalization extends WatchAttributeMappingsLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}