import {vCategoryLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCategoryLookUp extends vCategoryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}