import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneSaturationBase {

        //#region saturationID Prop
        @prop()
        saturationID: number;
        //#endregion saturationID Prop


        //#region saturationName Prop
        @required()
        @maxLength({ value: 50 })
        saturationName: string;
        //#endregion saturationName Prop

        //#region StatusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        StatusId: number;
        //#endregion StatusId Prop



}