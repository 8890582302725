import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HSEStatusMappingBase {

    //#region hSEStatusMappingId Prop
    @prop()
    hSEStatusMappingId: number;
    //#endregion hSEStatusMappingId Prop


    //#region userId Prop
    @required()
    @prop()
    userId: string;
    //#endregion userId Prop


    //#region statusId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    statusId: number;
    //#endregion statusId Prop

}

