import {vEnhancementTypeGemfieldsLookUpBase,} from '@app/database-models'
//Generated Imports
export class vEnhancementTypeGemfieldsLookUp extends vEnhancementTypeGemfieldsLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}