import { actionColumn } from "@rxweb/grid";
import { vTicketSeverityBase } from "../database-models/v-ticket-severity-base";

@actionColumn({
    visible: true,
    name: "action",
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 3, headerTitle: "Action"
})
export class vTicketSeverity extends vTicketSeverityBase {

}