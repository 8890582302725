import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerEventBase {

        //#region eventID Prop
        @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerTitle: 'eventID', keyColumn: true })
        eventID: number;
        //#endregion eventID Prop

        //#region eventName Prop
        @gridColumn({ configuredTemplate: { templateName:"reDirect" },isAscending: true, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 1, allowSorting: true, headerKey: 'EventDetailComponent_EventName_gh', keyColumn: false })
        eventName: string;
        //#endregion eventName Prop



        //#region eventType Prop
        @gridColumn({ style: { width: "17%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 2, allowSorting: true, headerKey: 'EventDetailComponent_EventType_gh', keyColumn: false })
        eventType: any;
        //#endregion eventType Prop


        //#region startDate Prop
        @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 3, allowSorting: true, headerKey: 'EventDetailComponent_StartDate_gh', keyColumn: false })
        startDate: string;
        //#endregion startDate Prop


        //#region location Prop
        @gridColumn({ style: { width: "13%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 5, allowSorting: true, headerKey: 'EventDetailComponent_Location_gh', keyColumn: false })
        location: string;
        //#endregion location Prop


        //#region eventDate Prop
        @gridColumn({ visible: false, columnIndex: 22, allowSorting: true, headerTitle: 'Send E Invitation', keyColumn: false })
        sendEInvitation: any;
        //#endregion eventDate Prop


        //#region accepted Prop
        @gridColumn({ visible: false, columnIndex: 6, allowSorting: true, headerTitle: 'Accepted', keyColumn: false })
        accepted: any;
        //#endregion accepted Prop


        //#region declined Prop
        @gridColumn({ visible: false, columnIndex: 7, allowSorting: true, headerTitle: 'Declined', keyColumn: false })
        declined: any;
        //#endregion declined Prop


        //#region invited Prop
        @gridColumn({ visible: false, columnIndex: 8, allowSorting: true, headerTitle: 'Invited', keyColumn: false })
        invited: any;
        //#endregion invited Prop


        //#region involved Prop
        @gridColumn({ visible: false, columnIndex: 9, allowSorting: true, headerTitle: 'Involved', keyColumn: false })
        involved: any;
        //#endregion involved Prop


        //#region attended Prop
        @gridColumn({ visible: false, columnIndex: 10, allowSorting: true, headerTitle: 'Attended', keyColumn: false })
        attended: any;
        //#endregion attended Prop


        //#region endDate Prop
        @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'EventDetailComponent_EndDate_gh', keyColumn: false })
        endDate: string;
        //#endregion endDate Prop


        //#region customerID Prop
        @gridColumn({ visible: false, columnIndex: 12, allowSorting: true, headerTitle: 'Customer ID', keyColumn: false })
        customerID: string;
        //#endregion customerID Prop

        //#region status Prop
//  @gridColumn({ parameter: false, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 13, allowSorting: true, headerKey: 'EventDetailComponent_Status_gh', keyColumn: false })
        @gridColumn({
                template:{
                div:{
                attributes:{
                        innerHTML:function(x,y){
                        return this.status;
                        }
                },
                }
                },visible: true, parameter:false,keyColumn:false,columnIndex: 13,style: { width: "20%", "text-align": "initial"},class:["text-left"], allowSorting: true, headerKey: 'EventDetailComponent_Status_gh',name:'status'
        })

        status: string
        //#endregion status Prop

    //#region note
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.note;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.charCount > 100 && !x.isShowAll) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreClick"
                        }
                    },
                },
                {
                    span: {
                        isBind: (x) => {
                            if (x.physicalFileName != null && x.restrictedUser != "true") {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        style: {
                            display: "block",
                        },
                        attributes: {
                            innerHTML: function (x, y) {
                            return this.multipleDocumentName;
                            },
                        },
                        event: {
                            click: "onDocClick",
                        },
                    },
                },

                ]
            }
        }, visible: true, parameter: false, columnIndex: 10, allowSorting: false, headerKey: 'EventDetailComponent_ManualNote_gh', name: 'note', style: { width: "15%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], keyColumn: false
    })

    note: string;
        //#endregion note
        charCount: number;

}
