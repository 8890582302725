import { prop, propObject, propArray, required, maxLength, range,trim,notEmpty,email } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserBase {

    //#region userId Prop
    @prop()
    userId: number;
    //#endregion userId Prop


    //#region userGUID Prop
    @required()
    @prop({ defaultValue: "2C846AF5-5D73-45F1-9915-0C0623C02913" })
    userGUID: any;
    //#endregion userGUID Prop


    //#region email Prop
    //@required()
    @maxLength({ value: 255 })
    @email()
    @trim()
    email: string;
    //#endregion email Prop


    //#region username Prop
    //@required()
    @prop()
    @maxLength({ value: 250 })
    username: string;
    //#endregion username Prop


    //#region password Prop
  //  @required()
   // @notEmpty()
    @maxLength({ value: 20 })
    password: string;
    //#endregion password Prop


    //#region salt Prop
    @required()
    @maxLength({ value: 255 })
    @prop({ defaultValue: "temp" })
    salt: string;
    //#endregion salt Prop


    //#region applicationTimeZoneId Prop
    @prop()
    applicationTimeZoneId: number;
    //#endregion applicationTimeZoneId Prop


    //#region affiliateID Prop
  //  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    affiliateID: number;
    //#endregion affiliateID Prop


    //#region billingAddressID Prop
  //  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    billingAddressID: number;
    //#endregion billingAddressID Prop


    //#region shippingAddressID Prop
  //  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    shippingAddressID: number;
    //#endregion shippingAddressID Prop


    //#region lastPaymentMethodID Prop
    //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    lastPaymentMethodID: number;
    //#endregion lastPaymentMethodID Prop


    //#region lastAppliedCouponCode Prop
    @required()
    @maxLength({ value: 100 })
    @prop({ defaultValue: "temp" })
    lastAppliedCouponCode: string;
    //#endregion lastAppliedCouponCode Prop


    //#region giftCardCouponCodes Prop
    @required()
    @prop({ defaultValue: "temp" })
    giftCardCouponCodes: string;
    //#endregion giftCardCouponCodes Prop


    //#region checkoutAttributes Prop
    @required()
    @prop({ defaultValue: "temp" })
    checkoutAttributes: string;
    //#endregion checkoutAttributes Prop


    //#region languageId Prop
    @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue:7 })
    languageId: number;
    //#endregion languageId Prop


    //#region currencyID Prop
    //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    currencyID: number;
    //#endregion currencyID Prop


    //#region taxDisplayTypeID Prop
    //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    taxDisplayTypeID: number;
    //#endregion taxDisplayTypeID Prop


    //#region isTaxExempt Prop
    @required()
    @prop({ defaultValue: false })
    isTaxExempt: boolean;
  
    //#endregion isTaxExempt Prop


    //#region isAdmin Prop
    @required()
    @prop({ defaultValue: false })
    isAdmin: boolean;
    //#endregion isAdmin Prop


    //#region isGuest Prop
    @prop({ defaultValue: false })
    @required()
    isGuest: boolean;
  
    //#endregion isGuest Prop


    //#region isForumModerator Prop
    @required()
    @prop({ defaultValue: false})
    isForumModerator: boolean;
    //#endregion isForumModerator Prop


    //#region totalForumPosts Prop
   // @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    totalForumPosts: number;
 
    //#endregion totalForumPosts Prop


    //#region signature Prop
    @required()
    @maxLength({ value: 300 })
    @prop({ defaultValue: "temp" })
    signature: string;
  
    //#endregion signature Prop


    //#region adminComment Prop
    @required()
    @maxLength({ value: 4000 })
    @prop({ defaultValue: "temp" })
    adminComment: string;
    //#endregion adminComment Prop


    //#region statusId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    statusId: number;
    //#endregion statusId Prop

    //#region welcomeEmailstatusId Prop
    @prop({ defaultValue: 0 })
    welcomeEmailstatusId: number;
    //#endregion welcomeEmailstatusId Prop

    //#region deleted Prop
    @required()
    @prop({ defaultValue: false })
    deleted: boolean;
    //#endregion deleted Prop


    //#region registrationDate Prop
    @required()
    registrationDate: Date;
    //#endregion registrationDate Prop


    //#region avatarID Prop
   // @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    @prop({ defaultValue: 0 })
    avatarID: number;
    //#endregion avatarID Prop


    //#region dateOfBirth Prop
    @prop()
    dateOfBirth: Date;
    //#endregion dateOfBirth Prop

    //#region userDateOfBirth Prop
    @prop()
    userDateOfBirth: Date;
    //#endregion userDateOfBirth Prop


    //#region registrationTypeID Prop
    @prop()
    @prop({ defaultValue: 0 })
    registrationTypeID: number;
    //#endregion registrationTypeID Prop


    //#region salesAdvisorsID Prop
  //  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    //@required()
    //@prop({ defaultValue: 0 })
    @prop()
    salesAdvisorsID: number;
    //#endregion salesAdvisorsID Prop


    //#region roleTypeId Prop
    @prop()
    @required()
    roleTypeId: number;
    //#endregion roleTypeId Prop


    //#region isBanOrders Prop
    @required()
    @prop({ defaultValue: false })
    isBanOrders: boolean;
    //#endregion isBanOrders Prop


    //#region lastLockoutDate Prop
    @prop()
    lastLockoutDate: Date;
    //#endregion lastLockoutDate Prop


    //#region failedPasswordAttemptCount Prop
    @prop()
    @prop({ defaultValue: 0 })
    failedPasswordAttemptCount: number;
    //#endregion failedPasswordAttemptCount Prop


    //#region introducesBy Prop
    @prop()
    @prop({ defaultValue: 0 })
    introducesBy: number;
    //#endregion introducesBy Prop


    //#region profileAbout Prop
    @prop()
    @trim()
    @maxLength({ value: 4000 })
    profileAbout: string;
    //#endregion profileAbout Prop


    //#region dietaryInfo Prop
    @maxLength({ value: 400 })
    dietaryInfo: string;
    //#endregion dietaryInfo Prop


    //#region salesAdvisorAdded Prop
    @prop()
    salesAdvisorAdded: Date;
    //#endregion salesAdvisorAdded Prop


    //#region introducedByAdded Prop
    @prop()
    introducedByAdded: Date;
    //#endregion introducedByAdded Prop


    //#region contactPermissionRequired Prop
    @required()
    @prop({ defaultValue: false })
    contactPermissionRequired: boolean;
    //#endregion contactPermissionRequired Prop


    //#region industryTypeId Prop
    @prop()
    @prop({ defaultValue: 0 })
    industryTypeId: number;
    //#endregion industryTypeId Prop


    //#region anniversaryDate Prop
    @prop()
    anniversaryDate: Date;
    //#endregion anniversaryDate Prop


    //#region reportTo Prop
    @prop()
    @prop({ defaultValue: 0 })
    reportTo: number;
    //#endregion reportTo Prop


    //#region widgetState Prop
    @maxLength({ value: 150 })
    widgetState: string;
    //#endregion widgetState Prop


    //#region loginBlocked Prop
    @required()
    @prop({ defaultValue: false })
    loginBlocked: boolean;
    //#endregion loginBlocked Prop


    //#region languageCode Prop
    @maxLength({ value: 3 })
    languageCode: string;
    //#endregion languageCode Prop

    //#region ChannelIEC Prop  //#287839//11-06-2024//new formcontroller added for Channel 
    @prop()
    channelIECId: number;  
    //#endregion ChannelIEC Prop

    //#region applicationLocaleId Prop
    @prop()
    @prop({ defaultValue: 0 })
    applicationLocaleId: number;
    //#endregion applicationLocaleId Prop

    //#region birthdayProtected Prop
    @prop({ defaultValue: 0 })
    birthdayProtected:number;
    //#endregion birthdayProtected Prop

    //#region isUnwelcome Prop
    @required()
    @prop({ defaultValue: false })
    isUnwelcome: boolean;
    //#endregion isUnwelcome Prop

    //#region isSSOEnabled Prop
    @required()
    @prop({ defaultValue: true })
    isSSOEnabled: boolean;
    //#endregion isSSOEnabled Prop




















}
