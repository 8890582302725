import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLanguageBase {

//#region languageId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'LanguageListComponent_LanguageId_gh', keyColumn: true})
        languageId : number;
//#endregion languageId Prop


//#region languageName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'LanguageListComponent_LanguageName_gh', keyColumn: false})
        languageName : string;
//#endregion languageName Prop


//#region languageCulture Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'LanguageListComponent_LanguageCulture_gh', keyColumn: false})
        languageCulture : string;
//#endregion languageCulture Prop


//#region flagImageFileName Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'LanguageListComponent_FlagImageFileName_gh', keyColumn: false})
        flagImageFileName : string;
//#endregion flagImageFileName Prop


//#region isPublished Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:4, allowSorting: true, headerKey: 'LanguageListComponent_IsPublished_gh', keyColumn: false,configuredTemplate: { templateName:"published" }})
        isPublished : boolean;
//#endregion isPublished Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial"},class: ["text-left"],visible: true, columnIndex:5, allowSorting: true, headerKey: 'LanguageListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}