import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { title } from 'process';

export const PROJECT_REDIRECT_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                var shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                var enddate = this.projectEndDate == null ? "" : new Date(this.projectEndDate).getDate() + " " + shortMonths[new Date(this.projectEndDate).getMonth()] + " " + new Date(this.projectEndDate).getFullYear() + " ";
                var projectName = new Date(this.projectStartDate).getDate() + " " + shortMonths[new Date(this.projectStartDate).getMonth()] + " " + new Date(this.projectStartDate).getFullYear() + " - " + enddate;
                return projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      },
      {
        div:{
          childrens:[{
            span:{
              class: [function (e) {
                if (this.parentId == 0) {
                  return "pagination-bold mr-1".split(" ");
                } else {
                  return "font-weight-bold text-muted mr-1".split(" ");
                }
              }],
              childrens: [
                {  
                i:{
                  class: [function (e) {
                    if (this.parentId == 0) {
                      return "fa fa-list-alt mr-1".split(" ");
                    } else {
                      return "fa fa-sitemap mr-1".split(" ");
                    }
                  }]
                },
                text: {
                  text: function (e) {
                    if(this.parentId == 0) {
                      return getLocalizedValue("MainProject_label")
                    }
                    else{
                      return getLocalizedValue("SubProject_label")
                    }
                  }
                }
              }]
            },
            i: {
              class: [function (e) {
                if (this.subProjects > 0) {
                  return "fa fa-chevron-down mr-2".split(" ");
                } else {
                  return "";
                }
              }],
              attributes: {
                "title": function (e) {
                  return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
                }
              },
              event: {
                click: "onExpand"
              }
            }
          }]
        }
      }
    ]
  },
};

export const PROJECT_LEVEL2_REDIRECT_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                var shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                var enddate = this.projectEndDate == null ? "" : new Date(this.projectEndDate).getDate() + " " + shortMonths[new Date(this.projectEndDate).getMonth()] + " " + new Date(this.projectEndDate).getFullYear() + " ";
                var projectName = new Date(this.projectStartDate).getDate() + " " + shortMonths[new Date(this.projectStartDate).getMonth()] + " " + new Date(this.projectStartDate).getFullYear() + " - " + enddate;
                return projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      },
      {
        div:{
          childrens:[{
            span:{
              class: [function (e) {
                if (this.parentId == 0) {
                  return "pagination-bold mr-1".split(" ");
                } else {
                  return "font-weight-bold text-muted mr-1".split(" ");
                }
              }],
              childrens: [
                {  
                i:{
                  class: [function (e) {
                    if (this.parentId == 0) {
                      return "fa fa-list-alt mr-1".split(" ");
                    } else {
                      return "fa fa-sitemap mr-1".split(" ");
                    }
                  }]
                },
                text: {
                  text: function (e) {
                    if(this.parentId == 0) {
                      return getLocalizedValue("MainProject_label")
                    }
                    else{
                      return getLocalizedValue("SubProject_label")
                    }
                  }
                }
              }]
            },
            i: {
              class: [function (e) {
                if (this.subProjects > 0) {
                  return "fa fa-chevron-down mr-2".split(" ");
                } else {
                  return "";
                }
              }],
              attributes: {
                "title": function (e) {
                  return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
                }
              },
              event: {
                click: "onExpand"
              }
            }
          }]
        }
      }
    ],
    style: {
      "padding-left": "10%",
      "width": "100%"
    }
  },
};

export const PROJECT_LEVEL3_REDIRECT_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                var shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                var enddate = this.projectEndDate == null ? "" : new Date(this.projectEndDate).getDate() + " " + shortMonths[new Date(this.projectEndDate).getMonth()] + " " + new Date(this.projectEndDate).getFullYear() + " ";
                var projectName = new Date(this.projectStartDate).getDate() + " " + shortMonths[new Date(this.projectStartDate).getMonth()] + " " + new Date(this.projectStartDate).getFullYear() + " - " + enddate;
                return projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      },
      {
        div:{
          childrens:[{
            span:{
              class: [function (e) {
                if (this.parentId == 0) {
                  return "pagination-bold mr-1".split(" ");
                } else {
                  return "font-weight-bold text-muted mr-1".split(" ");
                }
              }],
              childrens: [
                {  
                i:{
                  class: [function (e) {
                    if (this.parentId == 0) {
                      return "fa fa-list-alt mr-1".split(" ");
                    } else {
                      return "fa fa-sitemap mr-1".split(" ");
                    }
                  }]
                },
                text: {
                  text: function (e) {
                    if(this.parentId == 0) {
                      return getLocalizedValue("MainProject_label")
                    }
                    else{
                      return getLocalizedValue("SubProject_label")
                    }
                  }
                }
              }]
            },
            i: {
              class: [function (e) {
                if (this.subProjects > 0) {
                  return "fa fa-chevron-down mr-2".split(" ");
                } else {
                  return "";
                }
              }],
              attributes: {
                "title": function (e) {
                  return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
                }
              },
              event: {
                click: "onExpand"
              }
            }
          }]
        }
      }
    ],
    style: {
      "padding-left": "20%",
      "width": "100%"
    }
  },
};

export const PROJECT_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        // i: {
        //   class: [function (e) {
        //     if (this.subProjects > 0 || this.subsubProjects > 0 || this.Level4Projects > 0) {
        //       return "fa fa-chevron-down mr-2".split(" ");
        //     } else {
        //       return "";
        //     }
        //   }],
        //   attributes: {
        //     "title": function (e) {
        //       return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
        //     }
        //   },
        //   event: {
        //     click: "onExpand"
        //   }
        // },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {

              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const PROJECT_LEVEL2_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0 || this.subsubProjects > 0 || this.Level4Projects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {

              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ],
    style: {
      "padding-left": "10%",
      "width": "100%"
    }
  },
};

export const PROJECT_LEVEL3_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0 || this.subsubProjects > 0 || this.Level4Projects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {

              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ],
    style: {
      "padding-left": "20%",
      "width": "100%"
    }
  },
};

export const PROJECT_LEVEL4_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0 || this.subsubProjects > 0 || this.Level4Projects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {

              if (this.ProjectID != null || this.ProjectID != undefined) {
                return "/projects/" + this.ProjectID + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ],
    style: {
      "padding-left": "30%",
      "width": "100%"
    }
  },
};

export const DASHBOARD_PROJECT_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }
          ],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return e.projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects," + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};


export const DASHBOARD_PROJECT_LEVEL2_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return e.projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects," + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ],
    style: {
      "padding-left": "10%",
      "width": "100%"
    }
  },
};

export const DASHBOARD_PROJECT_LEVEL3_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return e.projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects," + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ],
    style: {
      "padding-left": "20%",
      "width": "100%"
    }
  },
};

export const DASHBOARD_PROJECT_LEVEL4_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        i: {
          class: [function (e) {
            if (this.subProjects > 0) {
              return "fa fa-chevron-down mr-2".split(" ");
            } else {
              return "";
            }
          }],
          attributes: {
            "title": function (e) {
              return (this.subProjects > 0 ? getLocalizedValue("Title_Expand") : "")
            }
          },
          event: {
            click: "onExpand"
          }
        },
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return e.projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects," + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ],
    style: {
      "padding-left": "30%",
      "width": "100%"
    }
  },
};

export const DASHBOARD_PROJECT_UPDATE_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return e.projectName;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects," + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const PROJECT_NAME_SET_PROJECTEVENTRATINGTAB: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onProClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                var projectName = this[e.name];
                if (this.isRestrictedProject) {
                  projectName = projectName + ' (Restricted)'
                }
                return projectName;
              }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects/" + this.projectId + "?activeTab=project-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.projectId != null || this.projectId != undefined) {
                return "/projects," + this.projectId + "?activeTab=project-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};


export const EVENT_NAME_SET_PROJECTEVENTRATINGTAB: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onEveClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.eventId != null || this.eventId != undefined) {
                return "/events/" + this.eventId + "?activeTab=event-info";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.eventId != null || this.eventId != undefined) {
                return "/events," + this.eventId + "?activeTab=event-info";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};
