import { SupplierViewModelBase } from '../database-models/supplier-view-model-base';
import { prop, trim } from '@rxweb/reactive-form-validators';

export class SupplierViewModel extends SupplierViewModelBase {

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderByColumn: string;
}