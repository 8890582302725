import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { CoreComponent } from '@rxweb/framework';


export class SaveGridColumnBase extends CoreComponent{

    //#region GridColumnId Prop
    @prop()
    gridColumnId : number;
    //#endregion GridColumnId Prop

    //#region ModuleId Prop
    @required()
    moduleId : number;
    //#endregion ModuleId Prop

    //#region UserId Prop
    @required()
    userId : number;
    //#endregion UserId Prop

    //#region GridColumns Prop
    gridColumns : string;
    //#endregion GridColumns Prop

    //#region SaveGridColumn Prop
    childTabs : string;
    //#endregion SaveGridColumn Prop

    @prop()
    @required()
    createdOn : Date;

    @prop()
    @required()
    createdby : number;

    @prop()
    updatedOn : Date;

    @prop()
    updatedby : number;

    //#region statusId Prop
   
    @required()
    statusId: number;
    //#endregion statusId Prop

}