import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    name:"action",
    allowSorting: false,
    visible:false,
    style: { width: "0.5%","text-align": "initial"},
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
  })

export class SubProjectDesignListViewModel {

    @gridColumn({ style: { width: "0.01%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region productId Prop
    @gridColumn({ visible: false, columnIndex: 1, headerTitle: 'Action', keyColumn: true, allowSorting: false })
    productId: number;
    //#endregion productId Prop

    //#region design Prop
    @gridColumn({ style: { width: "5%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'SubProjectAddEditComponent_Design_gh', keyColumn: false, allowSorting: true })
    design: string;
    //#endregion design Prop

    //#region startDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 3, headerKey: 'SubProjectAddEditComponent_StartDate_gh', keyColumn: false, allowSorting: true })
    startDate: string;
    //#endregion startDate Prop

    //#region endDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 4, headerKey: 'SubProjectAddEditComponent_EndDate_gh', keyColumn: false, allowSorting: true })
    endDate: string;
    //#endregion endDate Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 5, headerKey: 'totalCount', keyColumn: false, allowSorting: true })
    totalCount: number;
    //#endregion totalCount Prop

}