import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HelpSupportUserRoleBase {

//#region helpSupportUserRoleId Prop
        @prop()
        helpSupportUserRoleId : number;
//#endregion helpSupportUserRoleId Prop


//#region helpSupportId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        helpSupportId : number;
//#endregion helpSupportId Prop


//#region userRoleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userRoleId : number;
//#endregion userRoleId Prop


//#region stausId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        stausId : number;
//#endregion stausId Prop


//#region isActive Prop
         @prop({defaultValue:false})
        isActive : boolean;
//#endregion isActive Prop



}