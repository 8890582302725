import { prop, required, propObject, trim, numeric } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentProjectFilterViewModel {
    
    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "projectName";
    }

    @prop({ defaultValue: "" })
    projectStartDate: Date;

    @prop({ defaultValue: "" })
    projectEndDate: Date;

    @prop({ defaultValue: "" })
    projectName: string;

    @prop({ defaultValue: "" })
    @numeric()
    projectId: string;

    @prop({ defaultValue: 0 })
    projectOwner: number;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}
