import { prop } from '@rxweb/reactive-form-validators';

export class HSEReportFilterModel{
   @prop()
    startDate:Date;

    @prop()
    endDate:Date;

    @prop()
    supplierId:string;

    @prop()
    showByMonths:boolean;

    @prop({defaultValue:25})
    rowCount:number;

    @prop({defaultValue:'name'})
    orderBy:string;

    @prop({defaultValue:true})
    sortOrder:boolean;

    @prop({defaultValue:1})
    pageIndex:number;

}