import {RelationshipBase,KnownToBase,} from '@app/database-models'
//Generated Imports
export class Relationship extends RelationshipBase 
{




//#region Generated Reference Properties
//#region knownTos Prop
knownTos : KnownToBase[];
//#endregion knownTos Prop

//#endregion Generated Reference Properties












}