import { getClientUrl, getFrontServerUrl, getFrontStoreUrl, getServerUrl } from './common-logic/common-logic';

export const TINY_MCE_KEY: string = "cbwfrcxc45k6jnt86s7evixiusx7jhkb07762skv60kc5uhn";

//local
export const CLIENT_URL: string = getClientUrl();
export const SERVER_URL: string = getServerUrl();
export const FRONTSTORE_URL: string = getFrontStoreUrl();
export const FRONTSTORESERVER_URL:string = getFrontServerUrl();
export const BLOB_PUBLIC_URI:string = getBlobUriConfig().publicUri;
export const BLOB_CONTAINER:string=getBlobUriConfig().container;
export const BLOB_PUBLIC_CONTAINER:string=getBlobUriConfig().publicContainer;
//export const BLOB_BASE:string= "client-blob/documents/notes/";
export const captchaSiteKey:string="6Le0eiYTAAAAAPuo-AL4MyZ1b3RjGhNJExjHAd3o";
export const encryptionKeyForDocuments:string = getEncryptionKeyForDocumentDownload();
export const authKeyForAzureJob:string = getAuthKeyForAzureFunction();
export function getBlobImgURL(fileName : string) : string {
    let imgURL = BLOB_PUBLIC_URI + "/" + BLOB_CONTAINER + "/" + fileName;
    return imgURL;
   }
export const DEFAULT_BLOB_IMAGE_URL = BLOB_PUBLIC_URI + "/" + BLOB_PUBLIC_CONTAINER + "/images/thumbs/default_image_560.png";
export const DEFAULT_THUMBNAIL_IMAGE_URL = BLOB_PUBLIC_URI + "/" + BLOB_PUBLIC_CONTAINER + "/images/thumbs/preview_not_available.png";
//staging
// export const CLIENT_URL: string = "http://fabergegtracrebuild.live1.dev.radixweb.net";
// export const SERVER_URL: string = "http://fabergegtracrebuild-api.live1.dev.radixweb.net";
export const BLOB_BASE: any = {
    DocumentFiles: "documents/notes/",
    //EventFiles: "/client-blob/event/",
    EventFiles: "images/thumbs/",
    EventDocFiles: "documents/notes/",
    SupplierFiles: "images/thumbs/",
    //picturesTab:"images/thumbs/"
    POSFiles:"images/thumbs/",
    PressMedia:"documents/pressmedia/",
    ItHelpDesk:"documents/ticket/",
    UserFiles:"images/thumbs/",
    UserDocumentFiles:"documents/user/",
    ProjectDocumentFiles:"documents/project/",
    ProjectFiles:"images/thumbs/",
    LegalAgreements:"documents/legalAgreement/",
    HSEGINAFiles: "images/thumbs/",
    InventoryDesignFiles: "images/thumbs/",
    InventoryDesignDocumentFiles:"documents/designs/",
    InventoryGemStoneDocumentFiles:"documents/fabergeGemstone/",
    InventoryWatchPartsDocumentFiles:"documents/watchInvoice/",
    ProductImages:"images/thumbs/",
    POFormFiles: "documents/notes/",
    RiskFiles:"images/thumbs/",
    InventoryProductBatchFiles: "documents/notes/",
    ProductPictureImages:"images/",
    ProductPieceImages:"images/thumbs/",
    ProductDocumentFiles:"documents/product/",
    PieceDocumentFiles:"documents/pieces/",
    ProductWebPictures:"images/Web-Images/",
    PieceWebPictures:"images/Web-Images/",
    FabAdminGemstoneType:"images/thumbs/",
    FabAdminBOMCategories:"images/thumbs/",
    FabAdminCollections:"images/thumbs/",
    FabAdminBoutiques:"images/thumbs/",
    FabAdminCustomised:"images/thumbs/",
    FabNews:"images/thumbs/",
    FabHomeLayout:"images/homelayout/",
    FabHomeLayoutMobile:"images/homelayoutmobile/",
    FabMenuLayout:"images/thumbs/",
    FabHistoryImages:"images/thumbs/",
    FabHistoryDocumentFiles:"documents/news/",
    SystemFormsDocument:"documents/formsdocument/",
    InternalDirectoryDiagramDocument:"documents/master/",
    PeopleLeaveDocument:"documents/leave/",
    ExcelSheet:"documents/notes/",
    FabAdminBoutiquesPictures:"images/thumbs/",
    HelpSupportDocumentsForEditor:"documents/notes/",

    EventsPicturesVideoTab: "images/events/",
    SupplierPicturesVideoTab: "images/supplier/",
    HSEGINAPicturesVideoTab: "images/hsegina/",
    ProjectPicturesVideoTab:"images/project/",
    UserPicturesVideoTab:"images/user/",

    RiskPicturesTab:"images/thumbs/",
    CMSFabergeNewsPictureTab: "images/thumbs/",
    InventoryGemStonePicturesTab:"images/thumbs/",

    CMSFabergeNewsDocumentsForEditor: "images/thumbs/",
    CMSFabergePageDocumentsForEditor:"images/thumbs/",
    CMSFabergeHistoryDocumentsForEditor:"images/thumbs/",
};

export function getMSALAuthConfig(): MsalAuthConfig {
    var msalAuthConfig = new MsalAuthConfig();
    if(getClientUrl().indexOf('127.0.0.1') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = 'b8ca9c91-b4a4-43ad-a734-005bb54a03fc';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('localhost') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = 'b8ca9c91-b4a4-43ad-a734-005bb54a03fc';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('fabgtracdev') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = 'b8ca9c91-b4a4-43ad-a734-005bb54a03fc';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('fabergegtracrebuild') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = 'b8ca9c91-b4a4-43ad-a734-005bb54a03fc';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('rebuildadmin') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '60a8942c-b5ae-418a-9d7c-c955c89e6eb8';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('rebuilduatadmin') > -1 || getClientUrl().indexOf('gtrac-2af14') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '60a8942c-b5ae-418a-9d7c-c955c89e6eb8';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('https://ggl.g-trac.net') > -1){
        msalAuthConfig.tenantId = '';
        msalAuthConfig.clientId = '';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('beta-ggl.g-trac.net') > -1){
        msalAuthConfig.tenantId = '';
        msalAuthConfig.clientId = '';
        msalAuthConfig.authority = 'https://login.microsoftonline.com/' + msalAuthConfig.tenantId;
        msalAuthConfig.redirectUri = getClientUrl() +'/sso-redirected';
        msalAuthConfig.logoutRedirectUri = getClientUrl() +'/login';
        return msalAuthConfig;
    }
    return msalAuthConfig;
}

export class MsalAuthConfig{
    tenantId: string
    clientId: string
    authority: string
    redirectUri: string
    logoutRedirectUri: string
}

export function getMSALAuthConfigForPowerBI(): MsalAuthConfigForPowerBI {
    var msalAuthConfig = new MsalAuthConfigForPowerBI();
    if(getClientUrl().indexOf('127.0.0.1') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('localhost') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('fabgtracdev') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('fabergegtracrebuild') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('rebuildadmin') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('demo.g-trac') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('stg-gks-gtrac') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('fir-gks-gtrac') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('rebuilduatadmin') > -1 || getClientUrl().indexOf('gtrac-2af14') > -1 || getClientUrl().indexOf('ggl-china-test') > -1){
        msalAuthConfig.tenantId = 'efd225f9-01c6-4fb8-a4cb-d1b15ea065b2';
        msalAuthConfig.clientId = '5b6f5d1f-a3b9-451d-b6fc-da6c5b3a894c';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('https://ggl.g-trac.net') > -1){
        msalAuthConfig.tenantId = '';
        msalAuthConfig.clientId = '';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }

    if(getClientUrl().indexOf('beta-ggl.g-trac.net') > -1){
        msalAuthConfig.tenantId = '';
        msalAuthConfig.clientId = '';
        msalAuthConfig.scope = 'user.read offline_access';
        msalAuthConfig.state = '951236';
        msalAuthConfig.redirectURI = getServerUrl() + "/api/Authentication/OAuthLoginUsingCodeForPR";
        return msalAuthConfig;
    }
    return msalAuthConfig;
}

export class MsalAuthConfigForPowerBI {
    tenantId: string
    clientId: string
    scope: string
    state: string
    redirectURI: string
}

export function getBlobUriConfig(): {publicUri: string, container: string,publicContainer: string} {
    var currentUrl = window.location.href;
    var blobUriContainer = {publicUri: '', container: '' ,publicContainer: ''};
    if (currentUrl.indexOf('127.0.0.1') > -1 || currentUrl.indexOf('localhost') > -1) {
        blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
        blobUriContainer.container = "test";
        blobUriContainer.publicContainer = "test-front";
        return blobUriContainer;
    }
    if (currentUrl.indexOf('fabergegtracrebuild') > -1) {
        blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
        blobUriContainer.container = "test";
        blobUriContainer.publicContainer = "test-front";
        return blobUriContainer;
    }
    if (currentUrl.indexOf('rebuildadmin') > -1) {
        blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
        blobUriContainer.container = "test";
        blobUriContainer.publicContainer = "test-front";
        return blobUriContainer;
    }
    if (currentUrl.indexOf('rebuilduatadmin') > -1 || currentUrl.indexOf("gtrac-2af14") > -1 || currentUrl.indexOf("ggl-china-test") > -1) {
        blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
        blobUriContainer.container = "uat-container";
        blobUriContainer.publicContainer = "test-front";
        return blobUriContainer;
    }
    if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) {
        blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
        blobUriContainer.container = "test";
        blobUriContainer.publicContainer = "test-front";
        return blobUriContainer;
    }
    if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) {
        blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
        blobUriContainer.container = "test";
        blobUriContainer.publicContainer = "test-front";
        return blobUriContainer;
    }
    blobUriContainer.publicUri = "https://stagegksassetspublic1.blob.core.windows.net";
    blobUriContainer.container = "test";
    blobUriContainer.publicContainer = "test-front";
    return blobUriContainer;
}

export function getEncryptionKeyForDocumentDownload(): string{
    var currentUrl = window.location.href;
    if (currentUrl.indexOf('127.0.0.1') > -1 || currentUrl.indexOf('localhost') > -1) return 'b14ca5898a4e4133bbce2ea2315a1916';
    if (currentUrl.indexOf('fabergegtracrebuild') > -1) return 'b14ca5898a4e4133bbce2ea2315a1916';
    if (currentUrl.indexOf('rebuildadmin') > -1) return 'b14ca5898a4e4133bbce2ea2315a1916';
    if (currentUrl.indexOf('rebuilduatadmin') > -1 || currentUrl.indexOf("gtrac-2af14") > -1 || currentUrl.indexOf("ggl-china-test") > -1) return 'b14ca5898a4e4133bbce2ea2315a1916';
    if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) return 'b14ca5898a4e4133bbce2ea2315a1916';
    if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) return 'b14ca5898a4e4133bbce2ea2315a1916';
    return 'b14ca5898a4e4133bbce2ea2315a1916';
}

export function getAuthKeyForAzureFunction(): string{
    var currentUrl = window.location.href;
    if (currentUrl.indexOf('127.0.0.1') > -1 || currentUrl.indexOf('localhost') > -1) return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
    if (currentUrl.indexOf('fabergegtracrebuild') > -1) return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
    if (currentUrl.indexOf('rebuildadmin') > -1) return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
    if (currentUrl.indexOf('rebuilduatadmin') > -1 || currentUrl.indexOf("gtrac-2af14") > -1 || currentUrl.indexOf("ggl-china-test") > -1) return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
    if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
    if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
    return 'HrNM5ThYXerXo8YlwEMBmsCtKlaRYpdA';
}