import {BannedIpAddressBase,} from '@app/database-models'
//Generated Imports
export class BannedIpAddress extends BannedIpAddressBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}