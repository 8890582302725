import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"

export class PowerBIReportBase {

//#region powerBIReportId Prop
        @prop()
        powerBIReportId : number;
//#endregion powerBIReportId Prop

//#region name Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        powerBIReportName : string;
//#endregion name Prop

//#region name Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        powerBIReportGUID : string;
//#endregion name Prop

//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop

//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

        @prop()
        createdBy:number

        @prop()
        createdOn:Date

}
