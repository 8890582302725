<div [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="ProjectEventListComponent_Add_Event_t"
      ></button>
    <div class="text-right ml-auto">
      <ng-container *ngIf="!isNoRecordFound" [rxLocalisationInit]="componentName">
        <button type="button"  class="btn btn-success mr-1" data-toggle="modal"
        data-target="#eventAttendees" data-backdrop="static" data-keyboard="false" (click)="BindAttendeesData()"
        rxText="ProjectEventListComponent_Attendees_t" ></button>
      </ng-container>
      <!-- <button type="button" class="btn btn-success mr-1" (click)="saveContinue(true)" *ngIf="!isNoRecordFound"
        [disabled]="selectedEvents.length==0">Save</button> -->
      <ng-container *ngIf="!isNoRecordFound" [rxLocalisationInit]="componentName">
        <button type="button" class="btn btn-danger mr-3" (click)="saveContinue(false)"
        [disabled]="selectedEvents.length==0" rxText="Btn_Remove_t"></button>
      </ng-container>
        <label class="ml-auto mr-4">
        <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
          placeholder="" [title]="titleSearch">
      </label>
      <div class="ibox-tools" *ngIf="!isNoRecordFound">
        <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
          <i class="fa fa-cog"></i>
        </a>
        <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="projectEventsGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID"
          [selectedTab]="selectedTab">
        </app-system-table-setting>
      </div>
    </div>
  </div>
  <div data-toggle="buttons" *ngIf="isLongTextExists"
    class="btn-group btn-group-gray btn-group-toggle mb-1 d-flex flex-wrap justify-content-end">
    <ng-container *ngIf="!isShowAll" [rxLocalisationInit]="componentName">
      <button class="btn btn-success" (click)="showOrHideAll()"  rxText="Btn_Show_All_t"
      ></button>
    </ng-container>
    <ng-container *ngIf="isShowAll" [rxLocalisationInit]="componentName">
      <button class="btn btn-success" (click)="showOrHideAll()"  rxText="Btn_Hide_All_t"
      ></button>
    </ng-container>
  </div>
  <div class="table-responsive" *ngIf="isShowGrid">
    <div class="dataTables_wrapper pb-0 dt-bootstrap4">
      <rx-grid [design]="projectEventsGrid"></rx-grid>
    </div>
  </div>
  <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>
  <div class="modal" id="eventAttendees" tabindex="-1" role="dialog" aria-labelledby="eventAttendeesLabel" [rxLocalisationInit]="componentName"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel" rxText="ProjectEventListComponent_EventAttendees_t"></h3>
          <button type="button" #closebutton data-dismiss="modal" class='d-none'
            ></button>
          <button type="button" class="close" data-dismiss="modal" (click)="eventAttendeesModalClose()"
             aria-label="Close" [title]="titleClose">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="!eventAttendees" [rxLocalisationInit]="componentName">

            <div class="text-right ml-auto">
              <label class="ml-auto mr-3 mb-3">
                <input (keyup.enter)="searchAttendees($event)" type="search"
                  class=" table_search form-control form-control-sm pr-4" placeholder="" [title]="titleSearch">
              </label>
              <button class="btn btn-primary mt-3 mb-3 mr-3" (click)="exportToExcel()"
                 rxText="Btn_Export_to_Excel_t"></button>
            </div>


            <div class="table-responsive">
              <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                  <rx-grid [design]="projectEventAttendeesGrid"></rx-grid>
              </div>
            </div>
          </div>
          <div class="ibox-title bg-white" *ngIf="eventAttendees">
            <h4>No Record Found</h4>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" rxText="Btn_Close_t"></button> -->
          <button type="button" class="btn btn-secondary" (click)="eventAttendeesModalClose()"
             rxText="Btn_Close_t"></button>
        </div>
      </div>
    </div>
  </div>
</div>