import {vProductColorLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductColorLookUp extends vProductColorLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}