import { prop } from '@rxweb/reactive-form-validators';
import { gridColumn, actionColumn } from '@rxweb/grid';
import { getLocalizedValue, showPersonaNonGrataToolTip } from '../domain/common-logic/common-logic';


@actionColumn({
   allowSorting: false,
   style: { width: "2%", "text-align": "center" },
   configuredTemplate: { templateName: 'saveButton' }, columnIndex: 18, headerTitle: "Action"
})

export class  EventInviteesViewModel{

  // @gridColumn({parameter:false,style: { width: "1%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 0, allowSorting: false, headerTitle: 'Action', keyColumn: false })
    @gridColumn({ allowSorting: false, style: { width: "1%", "text-align": "initial" }, class: ["text-centre"],visible: true,configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })

  isChecked:boolean

  @gridColumn({ parameter: true,name:"eventCustomerId", visible: true,style: { "text-align": "initial", width : "1%" },configuredTemplate: { templateName: 'eventExpand' }, columnIndex: 0, allowSorting: false, headerKey: 'expand', keyColumn: true })
  eventCustomerId:number


  userId:number
  // @gridColumn({parameter:false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"],  visible: true,configuredTemplate: { templateName: 'corporateLink' }, columnIndex: 3, allowSorting: true, headerKey: 'company', keyColumn: false })

  @gridColumn({
    // template:{
    //     div:{
    //       attributes:{
    //         innerHTML:function(x,y){
    //          return this.company;
    //         }
    //       },
    //     }
    // },
    visible: true,parameter:false,keyColumn:false, columnIndex: 5,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSorting: false, configuredTemplate: { templateName: 'corporateGroupLink' }, headerKey: 'EventCustomerMappingListComponent_Company_gh',name:'company'
})
  company:string

    @gridColumn({
      template:{
          div:{
            attributes:{
              innerHTML:function(x,y){
               return this.directorship;
              }
            },
          }
      },parameter:false,visible: true,keyColumn:false, columnIndex: 6,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSorting: false, headerKey: 'EventCustomerMappingListComponent_Directorship_gh',name:'directorship'
  })
  directorship:string

  @gridColumn({
    template:{

      div:{
        style: {
         "word-break": "break-all",
      },
        attributes:{
          innerHTML:function(x,y){
           return this.latestNote;
          }
        },
        childrens:[{
          a:{
        isBind: (x) => {
          if(x.noteCharCount>100  && !x.isShowAll){
            return true;
          }
            else{
              return false;
            }
        },style:{
          "display":"block"
        },
        childrens: [{
          span: {
            class:"more-btn mr-2".split(" "),
            childrens: [{
              text: {
                text: function (e) {
                  return "Show More";
                }
              }
            }]
          }
        }],
        event: {
          click: "onMoreClick"
        }
      },
        }]
      }
  },visible: true,keyColumn : false, columnIndex: 12,style: { width: "10%","min-width":"250px","max-width":"250px", "text-align": "initial" }, class: ["text-left"], allowSorting: false, headerKey:'EventCustomerMappingListComponent_Latest_Note_gh',name:'latestNote', parameter : false
})
  latestNote:string

  noteCharCount : number;
  dietaryCharCount:number;
  profileCharCount:number;
  noteId : number;

  @gridColumn({ parameter:false,visible: true, columnIndex: 9, allowSorting: false,  name: "rating", style: { width: "7%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "peopleRating" }, headerKey: 'EventCustomerMappingListComponent_Rating_gh', keyColumn: false })
   rating:number

   eventId:number

   roleId:number
   //@gridColumn({ parameter:false,visible: false, columnIndex: 0, allowSorting: true, headerKey: 'eventCustomerId', keyColumn: true })
   //eventCustomerId:number

   @gridColumn({parameter:true,style: { width: "10%", "text-align": "initial", "min-width": "120px;" }, class: ["text-left"],columnIndex: 3,  visible: false,allowSorting: true, keyColumn: false })
   lastName:string
   @gridColumn({parameter:true,style: { width: "10%", "text-align": "initial", "min-width": "120px;" }, class: ["text-left"],columnIndex: 2,  visible: false,allowSorting: true, keyColumn: false })
   MiddleName:string
   @gridColumn({parameter:true,style: { width: "10%", "text-align": "initial", "min-width": "120px;" }, class: ["text-left"],  visible: true, columnIndex: 1, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_Name_gh',configuredTemplate: { templateName: 'reDirectPeopleAndTooltip' }, keyColumn: false })
   fullName:string

   @gridColumn({
    configuredTemplate: { templateName: 'peopleAnotherNameAndTooltip' },parameter:false,style: { width: "15%", "text-align": "initial", "min-width": "120px;" }, class: ["text-left"],  visible: true, columnIndex: 8, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_IntroBy_gh', keyColumn: false })
   introducesBy:string
   //@gridColumn({visible: false, columnIndex: 3, allowSorting: true, headerTitle: 'Job Title', keyColumn: false })
   jobTitle:string
  //  @gridColumn({parameter:false,style: { width: "15%", "text-align": "initial","min-width": "200px;" }, class: ["text-left"],  visible: true, columnIndex: 8, allowSorting: true, headerKey: 'knownTo', keyColumn: false })
  //  knownTo:string
   @gridColumn({template:{
      div:{
        style: {"word-break": "break-all"},
        attributes:{
          innerHTML:function(x,y){
            return this.profileHtml;
          }
        },
        childrens:[{
          a:{
            isBind: (x) => {
              if(x.profileCharCount>100  && !x.isShowAll){
                return true;
              }
              else{
                return false;
              }
            },
            style:{"display":"block"},
            childrens: [{
              span: {
                class:"more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreProfileClick"
            }
          },
        }]
      }
    },parameter:false,style: { width: "15%", "min-width":"250px", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 11, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_Profile_gh', keyColumn: false })
   profile:string
   profileHtml:string

   @gridColumn({parameter:false,style: { width: "7%", "text-align": "initial" , "min-width": "130px;"}, class: ["text-left"],  visible: true, columnIndex: 10, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_SalesAdvisor_gh', keyColumn: false })
   salesAdvisorId:string

   title:string
  //  @gridColumn({parameter:false,style: { width: "10%", "text-align": "initial" , "min-width": "120px;"}, class: ["text-left"],  visible: true, columnIndex: 10, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_DietInfo_gh', keyColumn: false })
  //  dietaryInfo:string
   @gridColumn({template:{
    div:{
      style: {"word-break": "break-all"},
      attributes:{
        innerHTML:function(x,y){
          return this.dietaryInfoHtml;
        }
      },
      childrens:[{
        a:{
          isBind: (x) => {
            if(x.dietaryCharCount>100  && !x.isShowAll){
              return true;
            }
            else{
              return false;
            }
          },
          style:{"display":"block"},
          childrens: [{
            span: {
              class:"more-btn mr-2".split(" "),
              childrens: [{
                text: {
                  text: function (e) {
                    return "Show More";
                  }
                }
              }]
            }
          }],
          event: {
            click: "onMoreDietaryInfoClick"
          }
        },
      }]
    }
  },parameter:false,style: { width: "15%", "min-width":"250px", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 13, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_DietInfo_gh', keyColumn: false })
  dietaryInfo:string
  dietaryInfoHtml:string
  //  @gridColumn({parameter:false,style: { width: "10%", "text-align": "center" , "min-width": "120px;"}, class: ["text-left"],  visible: true, columnIndex: 10, allowSorting: true, headerKey: 'knownTo', keyColumn: false })
  //  knownTo:string
  @gridColumn({ template:{
    div:{
      attributes:{
        innerHTML:function(x,y){
         return this.knownTo;
        }
      }
    }
},parameter:false,style: { width: "10%", "text-align": "initial" , "min-width": "120px;"}, class: ["text-left"],  visible: true, columnIndex: 14, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_KnownTo_gh', keyColumn: false })
  knownTo:any;

   @gridColumn({parameter:false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 7, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_Email_gh', keyColumn: false })
   email:string

   accepted:boolean

   declined:boolean

   invited:boolean

   attended:boolean
   @gridColumn({parameter:false,style: { width: "5%", "text-align": "initial"}, class: ["text-center"], configuredTemplate: { templateName: 'eInviteCheck' }, visible: true, columnIndex: 17, allowSorting: false, headerKey: 'EventCustomerMappingListComponent_SendEInv_gh', keyColumn: false })
   sendEInvitation:boolean


   @gridColumn({parameter:false,style: { width: "5%", "text-align": "initial"}, class: ["text-center"],configuredTemplate: { templateName: 'involvedCheck' } , visible: true, columnIndex: 16, allowSorting: false, headerKey: 'EventCustomerMappingListComponent_Involved_gh', keyColumn: false })

   isEventOrganiser:boolean

   @gridColumn({parameter:false,style: { width: "8%", "text-align": "initial"}, class: ["text-left"],configuredTemplate:{templateName:"number"},  visible: true, columnIndex: 4, allowSorting: true, headerKey: 'EventCustomerMappingListComponent_GuestCount_gh', keyColumn: false })

   guestCount:number

   isMapped:any

   totalCount:number

   inviteeCount:number

   acceptedCount:number

   involvedCount:number

   eInviteCount:number

   declinedCount:number

   attendedCount:number

   totalRecordsCount:number;

   @gridColumn({configuredTemplate:{templateName:"select"}, parameter:false,style: { width: "10%", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 15, allowSorting: false, headerKey: 'EventCustomerMappingListComponent_Status_gh', keyColumn: false })
   status:string;

   companyDetails:any[];
   knownToDetail:any[];
   directorshipDetails:any[];
   contactPermissionRequired: boolean;
   isUnwelcome: boolean;
   isUserUnwelcome: boolean;
   companyId:number;
   involved: boolean;
   userContactPermissionRequired:boolean;
   userIntroducesBy:string;
  //    @gridColumn({parameter:false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 9, allowSorting: false, headerTitle: 'status', keyColumn: false })
  //  inviteeStatus:string

}
