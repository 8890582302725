import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTitleLookUpBase {

//#region titleId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'titleId', keyColumn: true})
        titleId : number;
//#endregion titleId Prop


//#region titleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'titleName', keyColumn: false})
        titleName : string;
//#endregion titleName Prop

}