import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
const EXCEL_EXTENSION = ".xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
import { RxFormBuilder, IFormGroup } from "@rxweb/reactive-form-validators";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { USER_LOOKUPS } from "src/app/const/uris/user-lookups.uri";
import { AbstractProductPiece } from "../domain/abstract-product-piece";
import { DomSanitizer } from "@angular/platform-browser";
import { UpdatePiecesFromExcelViewModel } from "src/app/view-model/update-pieces-from-excel-view-model";
import { ErrorUpdatePiecesFromExcelComponent } from "../error-update-pieces-from-excel/error-update-pieces-from-excel.component";
import { BulkUpdatePiece } from "src/app/view-model/bulk-update-piece";
import { multilingual } from "@rxweb/localization";
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@multilingual("UpdatePiecesFromExcelComponent")
@Component({
  selector: "app-update-pieces-from-excel",
  templateUrl: "./update-pieces-from-excel.component.html",
  styleUrls: ["./update-pieces-from-excel.component.css"],
})
export class UpdatePiecesFromExcelComponent
  extends AbstractProductPiece
  implements OnInit
{
  fileId: string;
  @Input() hide: Function;
  TodayDate = new Date();
  MMDDYY = moment(this.TodayDate).format("MMDDYY").toString();
  updatePiecesFromExcelObject: UpdatePiecesFromExcelViewModel;
  titleClose: string;
  fileToUpload: File = null;
  pieceImportobj: BulkUpdatePiece[];
  errorUserList: BulkUpdatePiece[];
  lookUp: any;
  invalidExcelTypeMsg: string;
  updatePiecesFromExcelFormGroup: IFormGroup<UpdatePiecesFromExcelViewModel>;

  constructor(
    private formBuilder: RxFormBuilder,
    _modalview: ModalView,
    private domSanitizer: DomSanitizer,
    private location : PlatformLocation, blobState: BlobUploadsViewStateService
  ) {
    super(domSanitizer,blobState);
    this.modalView = _modalview;
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.invalidExcelTypeMsg = getLocalizedValue("InvalidExcelFile");
    this.fileId = "file" + Math.random().toString();
    this.updatePiecesFromExcelObject = new UpdatePiecesFromExcelViewModel();
    this.updatePiecesFromExcelFormGroup = this.formBuilder.formGroup(
      this.updatePiecesFromExcelObject
    ) as IFormGroup<UpdatePiecesFromExcelViewModel>;
    this.showComponent = true;
  }

  removeDoc() {
    this.fileToUpload = null;
    this.updatePiecesFromExcelFormGroup.patchValue({
      fileData: null,
      fileExtention: null,
      fileName: null,
      filePath: null,
      excelFile: null,
    });
  }

  ExportToExcel() {
    this.exportAsExcelFile();
  }

  public exportAsExcelFile(): void {
    this.spin = true;
    this.lookup([
      {
        propName: "eggSizeLookUp",
        path: USER_LOOKUPS.productBatchEggSizeLookUp,
      },
      { propName: "chainLengthLookUp", path: USER_LOOKUPS.chainLengthLookUp },
      { propName: "ringSizeLookup", path: USER_LOOKUPS.ringSizeLookup },
      { propName: "bangleSizeLookup", path: USER_LOOKUPS.bangleSizeLookup },
    ]).subscribe((response: any) => {
      this.lookUp = response;
      let ringSizeList = this.lookUp.ringSizeLookup;
      let eggSizeList = this.lookUp.eggSizeLookUp.map((x) => {
        return {
          eggSizeId: x.ringSizeId,
          eggSize: x.ringSize,
        };
      });
      let bangleSizeList = this.lookUp.bangleSizeLookup;
      let chainLengthSizeList = this.lookUp.chainLengthLookUp;

      var sizeReferencesArray: any[][] = [
        ["Ring Sizes", "Egg Sizes", "Chain Lengths", "Bangle Sizes"],
        ["N/A", "N/A", "N/A", "N/A"],
      ];
      let loopIndex = 0;
      while (1) {
        let ringSizeLookupLength = ringSizeList.length;
        let eggSizeLookupLength = eggSizeList.length;
        let chainLengthLookupLength = chainLengthSizeList.length;
        let bangleSizeLookupLength = bangleSizeList.length;

        if (
          loopIndex >= ringSizeLookupLength &&
          loopIndex >= eggSizeLookupLength &&
          loopIndex >= chainLengthLookupLength &&
          loopIndex >= bangleSizeLookupLength
        ) {
          break;
        }

        let ringSize =
          loopIndex < ringSizeLookupLength
            ? ringSizeList[loopIndex].ringSize.toString()
            : "";
        let eggSize =
          loopIndex < eggSizeLookupLength ? eggSizeList[loopIndex].eggSize.toString() : "";
        let chainLength =
          loopIndex < chainLengthLookupLength
            ? chainLengthSizeList[loopIndex].chainLengthName
            : "";
        let bangleSize =
          loopIndex < bangleSizeLookupLength
            ? bangleSizeList[loopIndex].bangleSizeName
            : "";

        sizeReferencesArray.push([ringSize, eggSize, chainLength, bangleSize]);
        loopIndex++;
      }

      this.spin = false;
      let pieceData = [
        {
          "Invoice No": "",
          "Product Code": "",
          Description: "",
          "Total Weight": "",
          "Metal Weight": "",
          "Ring Size": "",
          "Bangle Size": "",
          "Egg Size": "",
          "Chain Length": "",
          "Workshop Ref No": "",
        },
      ];

      let requiredData = [
        {
          "Please refer to the following instructions and validations for PiecesInfo sheet:":
            "",
        },
      ];

      const worksheetRequiredData: XLSX.WorkSheet =
        XLSX.utils.json_to_sheet(requiredData);
      const worksheetPieceData: XLSX.WorkSheet =
        XLSX.utils.json_to_sheet(pieceData);
      const worksheetSizeReferencesData: XLSX.WorkSheet =
        XLSX.utils.aoa_to_sheet(sizeReferencesArray);

      var wscolsPieces = [
        { wch: 15 },
        { wch: 15 },
        { wch: 50 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      var rows = [];
      for (let index = 0; index < 13; index++) {
        rows.push({ hpt: 30 });
      }
      worksheetRequiredData["!rows"] = rows;

      worksheetRequiredData["!cols"] = [{ wch: 150 }];
      worksheetPieceData["!cols"] = wscolsPieces;
      worksheetSizeReferencesData["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      XLSX.utils.sheet_add_aoa(
        worksheetRequiredData,
        [
          ["'PiecesInfo' sheet is required to update pieces details."],
          ["Make sure to keep the sheet name as 'PiecesInfo', while uploading the file."],
          ["Empty fields in the sheet will be ignored by the system."],
          ["Invoice No: It should not exceed length of 50 characters."],
          [
            "Product Code: Product Code should not be empty in order to upload sheet as it is primary & identifier field for pieces to update. Piece should exist in the system with respective product code.",
          ],
          [
            "Description: '^' sign is used for line break. i.e. Use '^^'('^' twice) in order to add 2 line breaks.",
          ],
          [
            "Total Weight: It should be numeric and in the range of 0.01 - 9999.",
          ],
          [
            "Metal Weight: It should be numeric and in the range of 0.01 - 9999.",
          ],
          [
            "Ring Size: Ring size drop-down should be enabled & value should exist in drop-down in the Piece Edit Page.",
          ],
          [
            "Bangle Size: Bangle size drop-down should be enabled & value should exist in drop-down in the Piece Edit Page.",
          ],
          [
            "Egg Size: Egg size should exist in drop-down in the Piece Edit Page.",
          ],
          [
            "Chain Length: Chain length should exist in drop-down in the Piece Edit Page.",
          ],
          [
            "Workshop Ref: Length of this field should not exceed 4000 characters.",
          ],
        ],
        { origin: "A2" }
      );

      const workbook: XLSX.WorkBook = {
        Sheets: {
          ReadMe: worksheetRequiredData,
          PiecesInfo: worksheetPieceData,
          SizeReferences: worksheetSizeReferencesData,
        },
        SheetNames: ["ReadMe", "PiecesInfo", "SizeReferences"],
      };

      const excelBuffer: any = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer);
    });
  }

  private saveAsExcelFile(buffer: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      "Pieces Mass Update Template " + this.MMDDYY + EXCEL_EXTENSION
    );
  }
  SaveExcel() {
    this.parseExcel(this.fileToUpload);
  }

  parseExcel(file) {
    this.spin = true;
    try {
      if (
        this.fileToUpload.type == "application/vnd.ms-excel" ||
        this.fileToUpload.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        this.fileToUpload.type == "text/csv"
      ) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let data = (<any>e.target).result;
          this.pieceImportobj = [];

          let workbook = XLSX.read(data, {
            type: "binary",
          });

          let valid = true;
          workbook.SheetNames = ["PiecesInfo"];

          if (this.fileToUpload.type == "text/csv" || this.fileToUpload.type == "application/vnd.ms-excel") {
            if(workbook.Sheets.Sheet1 != undefined)
              workbook.Sheets.PiecesInfo = workbook.Sheets.Sheet1;
          }

          if (workbook.Sheets.PiecesInfo != undefined) {
            if (
              (workbook.Sheets.PiecesInfo.A1 != undefined
                ? workbook.Sheets.PiecesInfo.A1.v != "Invoice No"
                : true) ||
              (workbook.Sheets.PiecesInfo.B1 != undefined
                ? workbook.Sheets.PiecesInfo.B1.v != "Product Code"
                : true) ||
              (workbook.Sheets.PiecesInfo.C1 != undefined
                ? workbook.Sheets.PiecesInfo.C1.v != "Description"
                : true) ||
              (workbook.Sheets.PiecesInfo.D1 != undefined
                ? workbook.Sheets.PiecesInfo.D1.v != "Total Weight"
                : true) ||
              (workbook.Sheets.PiecesInfo.E1 != undefined
                ? workbook.Sheets.PiecesInfo.E1.v != "Metal Weight"
                : true) ||
              (workbook.Sheets.PiecesInfo.F1 != undefined
                ? workbook.Sheets.PiecesInfo.F1.v != "Ring Size"
                : true) ||
              (workbook.Sheets.PiecesInfo.G1 != undefined
                ? workbook.Sheets.PiecesInfo.G1.v != "Bangle Size"
                : true) ||
              (workbook.Sheets.PiecesInfo.H1 != undefined
                ? workbook.Sheets.PiecesInfo.H1.v != "Egg Size"
                : true) ||
              (workbook.Sheets.PiecesInfo.I1 != undefined
                ? workbook.Sheets.PiecesInfo.I1.v != "Chain Length"
                : true) ||
              (workbook.Sheets.PiecesInfo.J1 != undefined
                ? workbook.Sheets.PiecesInfo.J1.v != "Workshop Ref No"
                : true)
            ) {
              this.toastr.error(
                "Invalid sheet format. Please follow instructions from sample sheet."
              );
              this.spin = false;
              valid = false;
              return;
            }
          } else {
            this.spin = false;
            this.toastr.error(
              "Invalid sheet format. Please follow instructions from sample sheet."
            );
            valid = false;
            return;
          }

          workbook.SheetNames.forEach((sheetName) => {
            // Here is your object

            let XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets.PiecesInfo
            );

            XL_row_object.forEach((obj: any) => {
              if (
                obj["Product Code"] == undefined ||
                obj["Product Code"] == ""
              ) {
                this.toastr.warning(
                  "Invalid sheet format. Please follow instructions from sample sheet."
                );
                valid = false;
                return;
              }

              let pieceData = new BulkUpdatePiece();
              pieceData.invoiceNo = obj["Invoice No"];
              pieceData.productCode = obj["Product Code"];
              pieceData.description = obj["Description"];
              pieceData.totalWeight = obj["Total Weight"];
              pieceData.metalWeight = obj["Metal Weight"];
              pieceData.ringSize = obj["Ring Size"];
              pieceData.bangleSize = obj["Bangle Size"];
              pieceData.eggSize = obj["Egg Size"];
              pieceData.unitPrice = obj["Unit Price"];
              pieceData.chainLength = obj["Chain Length"];
              pieceData.workshopRefNo = obj["Workshop Ref No"];
              this.pieceImportobj.push(pieceData);
            });

            if (this.pieceImportobj.length > 0 && valid == true) {
              var formData = new FormData();
              formData.set("file", this.fileToUpload);
              this.post({
                body: formData,
                path: "api/SearchPieces/PieceExcelUpload",
              }).subscribe((data: string) => {
                this.post({
                  body: this.pieceImportobj,
                  path: "api/SearchPieces/UpdatePieceImportExcel",
                }).subscribe((data: string) => {
                  this.hide();
                  this.spin = false;
                  try {
                    if (data != undefined && data.length == 0) {
                      this.toastr.success("All pieces updated successfully!");
                    } else {
                      this.toastr.warning("Pieces updated partially!");
                      this.modalView
                        .show(ErrorUpdatePiecesFromExcelComponent, {
                          failedPiecesList: data,
                          totalPieces: this.pieceImportobj.length,
                        })
                        .then((t) => {});
                    }
                  } catch (error) {}
                });
              });
            } else {
              this.spin = false;
            }
          }, this);
        };
        reader.onerror = function (ex) {};
        reader.readAsBinaryString(file);
      } else {
        this.spin = false;
        this.toastr.error(this.invalidExcelTypeMsg);
        return;
      }
    } catch (error) {
      this.spin = false;
      this.toastr.error(this.invalidExcelTypeMsg);
      return;
    }
  }

  fileUpload(args: any) {
    let files = args.target.files; // FileList object
    this.fileToUpload = files[0];
    if (this.fileToUpload != null)
      this.updatePiecesFromExcelFormGroup.value.fileName =
        this.fileToUpload.name;
  }

  get componentName(): string {
    return "UpdatePiecesFromExcelComponent";
  }
}
