import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { WebPictureListComponent } from './list/web-picture-list.component'
import { WebPictureEditComponent } from './edit/web-picture-edit.component';
import { WebPictureAddComponent } from './add/web-picture-add.component';
import { SharedModule } from '../../shared/shared.module';
import { FileModule } from '../../shared/global/file/file.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
	IgxDragDirective,
	IgxDropDirective,
	IgxDragDropModule,
	IgxDialogModule
 } from "igniteui-angular";


@NgModule({
  imports: [SharedModule, FileModule, NgxDropzoneModule,
    CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule,IgxDragDropModule,IgxDialogModule
    ,IgxDragDropModule,IgxDialogModule,DragDropModule],
  declarations: [WebPictureListComponent, WebPictureEditComponent, WebPictureAddComponent],
  providers: [],
  exports: [WebPictureListComponent, WebPictureEditComponent, WebPictureAddComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA,  NO_ERRORS_SCHEMA]
})
export class WebPictureSharedModule { }

