import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vWarehouseRecordBase {

//#region warehouseId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'warehouseId', keyColumn: true})
        warehouseId : number;
//#endregion warehouseId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region phoneNumber Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'phoneNumber', keyColumn: false})
        phoneNumber : string;
//#endregion phoneNumber Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region faxNumber Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'faxNumber', keyColumn: false})
        faxNumber : string;
//#endregion faxNumber Prop


//#region address1 Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'address1', keyColumn: false})
        address1 : string;
//#endregion address1 Prop


//#region address2 Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'address2', keyColumn: false})
        address2 : string;
//#endregion address2 Prop


//#region city Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'city', keyColumn: false})
        city : string;
//#endregion city Prop


//#region stateProvince Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'stateProvince', keyColumn: false})
        stateProvince : string;
//#endregion stateProvince Prop


//#region zipPostalCode Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'zipPostalCode', keyColumn: false})
        zipPostalCode : string;
//#endregion zipPostalCode Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : number;
//#endregion countryId Prop


//#region customerId Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'customerId', keyColumn: false})
        customerId : any;
//#endregion customerId Prop

}