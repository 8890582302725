import { prop, propObject, propArray, required, maxLength, range, notEmpty, alpha, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LocationGroupBase {

        //#region locationGroupId Prop
        @prop()
        locationGroupId: number;
        //#endregion locationGroupId Prop


        //#region locationGroupTitle Prop
        @required()
        @notEmpty()
        @maxLength({ value: 200 })
        // @alpha({allowWhiteSpace:true})
        @trim()
        locationGroupTitle: string;
        //#endregion locationGroupTitle Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        //#region warehouseId Prop
        @prop()
        warehouseId: number;
        //#endregion warehouseId Prop


}
