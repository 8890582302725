import { gridColumn } from "@rxweb/grid"


export class vGemstoneSaturationLookUpBase {

    //#region SaturationId Prop
    @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'SaturationId', keyColumn: true })
    SaturationId: number;
    //#endregion SaturationId Prop


    //#region SaturationName Prop
    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'SaturationName', keyColumn: false })
    SaturationName: string;
    //#endregion SaturationName Prop


}