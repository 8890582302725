import {BespokeRequestOrderPieceMappingBase,} from '@app/database-models'
//Generated Imports
export class BespokeRequestOrderPieceMapping extends BespokeRequestOrderPieceMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}