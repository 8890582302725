import {GemStoneCertificateGemFieldBase,GemStoneGemfieldBase,GemStoneLaboratoryGemFieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneCertificateGemField extends GemStoneCertificateGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfield Prop
gemStoneGemfield : GemStoneGemfieldBase;
//#endregion gemStoneGemfield Prop
//#region gemStoneLaboratoryGemField Prop
gemStoneLaboratoryGemField : GemStoneLaboratoryGemFieldBase;
//#endregion gemStoneLaboratoryGemField Prop

//#endregion Generated Reference Properties






































}