import {ProductAttributeBase,ProductAttributeMappingBase,} from '@app/database-models'
//Generated Imports
export class ProductAttribute extends ProductAttributeBase 
{




//#region Generated Reference Properties
//#region productAttributeMappings Prop
// productAttributeMappings : ProductAttributeMappingBase[];
//#endregion productAttributeMappings Prop

//#endregion Generated Reference Properties












}