import {EnhancementGemFieldBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class EnhancementGemField extends EnhancementGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop

//#endregion Generated Reference Properties





}