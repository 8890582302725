import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_INTERNAL_DRIECTORY_REPORTS_TOGRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.ReportsToId != null || this.ReportsToId != undefined) {
                return "/users/" + this.ReportsToId + "?activeTab=user-edit";
              }
              // if(this.userId != null || this.userId != undefined){
              //   return "/users/" + this.userId + "?activeTab=user-edit";
              // }
            },
            "ng-reflect-router-link": function (e) {
              if (this.ReportsToId != null || this.ReportsToId != undefined) {
                return "/users," + this.ReportsToId + "?activeTab=user-edit";
              }
              // if(this.userId != null || this.userId != undefined){
              //   return "/users/" + this.userId + "?activeTab=user-edit";
              // }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};
