import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductDesignLookUpBase {

//#region productId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'productId', keyColumn: true})
        productId : number;
//#endregion productId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop

}