import {vProductionOrderCurrencyLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductionOrderCurrencyLookUp extends vProductionOrderCurrencyLookUpBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}