import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '@app/models';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { AbstractDocumentType } from "../domain/abstract-document-type";

@multilingual("DocumentTypeEditComponent")
@access({ accessLevel: RolePermissionEnums.DocumentType, action: "put" })

@Component({
    selector: "app-document-type-edit",
    templateUrl: './document-type-edit.component.html'
})
export class DocumentTypeEditComponent extends AbstractDocumentType implements OnInit, OnDestroy {
    documentType: DocumentType;
    subscription: any;
    id: number;
    documentTypeName: string;
    documentTypeDelete: any = dbAccessModule[RolePermissionEnums.DocumentType]["delete"];
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    titleSearch: string;
    isRestricted:boolean = false;

    constructor(private title: Title, private router: Router, private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster, private activatedRoute: ActivatedRoute, modelView: ModalView, private el: ElementRef) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        this.activatedRoute.queryParams.subscribe(t => {
            this.selectedTab = t['activeTab'];
        });
        this.activatedRoute.queryParams.subscribe(t => {
            if (t['tabChange']) {
                this.tabChange = t['tabChange'];
            }
        });
        this.moduleID = RolePermissionEnums.DocumentType;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Document Type - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {
            if (t) {
                this.documentTypeFormGroup = this.formBuilder.formGroup(DocumentType, t) as IFormGroup<DocumentType>;
                this.showComponent = true;
                this.isRestricted = t["isRestricted"];
                this.documentTypeName = t["documentTypeName"];
            }
            else {
                this.router.navigate(['/document-types']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    activeTab(tabName: string) {
        this.tabChange = true;
        this.selectedTab = tabName;
        this.router.navigate(["document-types", this.id], { queryParams: { activeTab: tabName } });
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editDocumentType(isRedirect) {
        this.documentTypeFormGroup.submitted = true;
        if (this.documentTypeFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.documentTypeFormGroup.value, params: [this.id] }).subscribe(data => {
                this.isRestricted = this.documentTypeFormGroup.value.isRestricted;
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                if (existsAlert) {
                    this.toastr.success(existsAlert[0].showMessage);
                }
                isRedirect ?  this.ngOnInit() : this.router.navigate(['/document-types']);
                // window.location.reload();
            })
        } else {
            for (const key of Object.keys(this.documentTypeFormGroup.controls)) {
                if (this.documentTypeFormGroup.controls[key].invalid) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    invalidControl.focus();
                    break;
                }
            }
        }
    }

    deleteDocumentType() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/document-types']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Document_Type");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    get componentName(): string {
        return "DocumentTypeEditComponent";
    }
}
