import { prop, required, maxLength, range, trim, notEmpty } from '@rxweb/reactive-form-validators';

export class LocalizationFormViewModel {

    //#region keyName Prop
    @required()
    @notEmpty()
    @trim()
    @maxLength({ value: 50 })
    keyName: string;
    //#endregion keyName Prop

    //#region contentType Prop
    @required()
    @notEmpty()
    @trim()
    @maxLength({ value: 3 })
    contentType: string;
    //#endregion contentType Prop

    //#region en Prop
    @required()
    @notEmpty()
    @trim()
    en: string;
    //#endregion en Prop

    //#region m_en Prop
    @prop()
    @trim()
    m_en: string;
    //#endregion m_en Prop

    //#region fr Prop
    @prop()
    //@notEmpty()
    @trim()
    fr: string;
    //#endregion fr Prop

    //#region id Prop
    @required()
    id: number;
    //#endregion id Prop

}
