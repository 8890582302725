import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop, propArray } from '@rxweb/reactive-form-validators';
import { vSubMenuItemsBase } from '@app/database-models';

@actionColumn({
    visible:true,
    name:"action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 5, headerTitle: "Action"
})
export class vSubMenuItems extends vSubMenuItemsBase{
  @prop()
  totalCount:number;

  @prop()
  languageId:number;

  @prop()
  subMenuNameEng:string;

  @prop()
  isChildMenu:boolean;

  @prop({defaultValue:false})
  isDrag:boolean;

  @propArray(vSubMenuItemsBase)
  DragItems:vSubMenuItemsBase[];

  @prop()
  featuredNameEng:string;
}