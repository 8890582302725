import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductGemstoneMappingBase {

//#region productVariantGemstoneID Prop
        @prop()
        productVariantGemstoneID : number;
//#endregion productVariantGemstoneID Prop


//#region productVariantID Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID : number;
//#endregion productVariantID Prop


//#region gemStoneID Prop
        @prop()
        gemStoneID : number;
//#endregion gemStoneID Prop


//#region gemstoneTypeID Prop
        @prop()
        gemstoneTypeID : number;
//#endregion gemstoneTypeID Prop







}