import {SpecificationAttributeOptionBase,SpecificationMasterBase,SpecificationAttributeOptionLocalizationBase,} from '@app/database-models'
//Generated Imports
export class SpecificationAttributeOption extends SpecificationAttributeOptionBase 
{




//#region Generated Reference Properties
//#region specificationMaster Prop
specificationMaster : SpecificationMasterBase;
//#endregion specificationMaster Prop
//#region specificationAttributeOptionLocalizations Prop
specificationAttributeOptionLocalizations : SpecificationAttributeOptionLocalizationBase[];
//#endregion specificationAttributeOptionLocalizations Prop

//#endregion Generated Reference Properties












}