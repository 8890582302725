import {vTaxRateBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 7, headerTitle: "Action"
})
export class vTaxRate extends vTaxRateBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}