import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vChannelTypeLookUpBase {

//#region channelTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'channelTypeId', keyColumn: true})
        channelTypeId : number;
//#endregion channelTypeId Prop


//#region channelTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'channelTypeName', keyColumn: false})
        channelTypeName : string;
//#endregion channelTypeName Prop

}