import {vMessageTemplateRecordBase,} from '@app/database-models'
//Generated Imports
export class vMessageTemplateRecord extends vMessageTemplateRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}