import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHolidayListRecordBase {

//#region holidayId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'holidayId', keyColumn: true})
        holidayId : number;
//#endregion holidayId Prop


//#region holidayName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'holidayName', keyColumn: false})
        holidayName : string;
//#endregion holidayName Prop


//#region holidayYear Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'holidayYear', keyColumn: false})
        holidayYear : any;
//#endregion holidayYear Prop

}