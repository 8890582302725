import {BannedIpNetworkBase,} from '@app/database-models'
//Generated Imports
export class BannedIpNetwork extends BannedIpNetworkBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}