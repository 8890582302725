import {vLaboratoryLookUpBase,} from '@app/database-models'
//Generated Imports
export class vLaboratoryLookUp extends vLaboratoryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}