import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserLockoutBase {

//#region userId Prop
        //@gridColumn({visible: false, columnIndex:0, allowSorting: false, headerKey: 'userId', keyColumn: true})
        @gridColumn({ name:"userId",configuredTemplate: { templateName: 'bindCheckBox' }, visible: false, columnIndex:0,  allowSorting: false,  headerTitle: "Action", keyColumn: true, style: { width: "6%", "text-align": "initial" }})
        userId : number;
//#endregion userId Prop

//#region userId Prop
        //@gridColumn({visible: false, columnIndex:0, allowSorting: false, headerKey: 'userId', keyColumn: true})
        @gridColumn({ visible: true, columnIndex:1,  allowSorting: false,  headerKey:'UserLockoutListComponent_UserIdNo_gh' , keyColumn: false, style: { width: "10%", "text-align": "initial" },class: ["text-left"]})
        userIdNumber : number;
//#endregion userId Prop


//#region email Prop
        @gridColumn({style: { width: "18%", "text-align": "initial" },class: ["text-left"],isAscending:true,visible: true, columnIndex:2, allowSorting: true, headerKey: 'UserLockoutListComponent_Email_gh', keyColumn: false})
        email : string;
//#endregion email Prop


//#region username Prop
        @gridColumn({style: { width: "14%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'UserLockoutListComponent_UserName_gh', keyColumn: false})
        username : string;
//#endregion username Prop


//#region registrationDate Prop
        @gridColumn({style: { width: "5%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:7, allowSorting: true, headerKey: 'UserLockoutListComponent_RegDate_gh', keyColumn: false})
        registrationDate : any;
//#endregion registrationDate Prop


//#region lastLockoutDate Prop
        @gridColumn({style: {"text-align": "initial" },class: ["text-left"],visible: false, columnIndex:5, allowSorting: true, headerKey: 'UserLockoutListComponent_LastLockoutDate_gh', keyColumn: false})
        lastLockoutDate : any;
//#endregion lastLockoutDate Prop


//#region failedPasswordAttemptCount Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:6, allowSorting: true, headerKey: 'UserLockoutListComponent_FailedPassAttemptCount_gh', keyColumn: false})
        failedPasswordAttemptCount : any;
//#endregion failedPasswordAttemptCount Prop


//#region loginBlocked Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:8, allowSorting: true, headerKey: 'UserLockoutListComponent_LoginBlocked_gh', keyColumn: false, configuredTemplate: { templateName:"recordActive" }})
        loginBlocked : boolean;
//#endregion loginBlocked Prop


  //#region statusId Prop
  @gridColumn({ style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'UserLockoutListComponent_StatusId_gh',  keyColumn: false, configuredTemplate: { templateName: "recordStatus" } })
        statusId : number;
//#endregion statusId Prop 

@gridColumn({ style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'UserLockoutListComponent_RoleType_gh',  keyColumn: false})
roleType : string;

roleId:any;
}
