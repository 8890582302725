import { TemplateConfig } from '@rxweb/grid';

  export const REGISTERED_CUSTOMER_BIND: TemplateConfig = {
    select:
    {
      class: ["checkbox-custom-style","form-control"],
      event: {
        input: "onTimeSpanGridSelect"
      },
      style:{
        "margin-top":"-6px",
        "width":"auto"
      },
      attributes: {
        value: function (e) {
          return this.value;
        }
      },
      childrens: [
        {
          option: {
            attributes: {
              value: function (e) {
                return this.value;
              },
              selected: function (e) {
               //return this.parentObject.countFor24Hour && this.value==0
              var gridValue=0;
                if(this.parentObject.countFor24HourId==true){
                  
                 return this.parentObject.countFor24HourId && this.value==0
                }
                else if(this.parentObject.count7DaysId==true){
                  return this.parentObject.count7DaysId && this.value==1
                }
                else if(this.parentObject.count14DaysId==true){
                  return this.parentObject.count14DaysId && this.value==2
                }
                else if(this.parentObject.count1MonthId==true){
                  return this.parentObject.count1MonthId && this.value==3
                }
                else if(this.parentObject.count1YearId==true){
                  return this.parentObject.count1YearId && this.value==4
                }
            }
            },
            
            childrens: [{
              text: {
                text: function (e) { return this.text }
              }
            }]
          }
        }
      ],
      sourceItems: DAta(),
    }
  }

             

  
  export function DAta() {
    return [{ value: 0, text: "24 hours" },{ value: 1, text: "7 days" }, { value: 2, text: "14 days" },{value:3,text:"month"},{value:4,text:"year"}]
  }