import {vHolidayProcessBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
// @actionColumn({
//     visible:false,
//     name:"action",
//     allowSorting: false,
//     style: { width: "2%" },
//     configuredTemplate: { templateName: 'editIconForHolidayProcess' }, columnIndex: 9, headerTitle: "Action"
// })
export class vHolidayProcess extends vHolidayProcessBase 
{

    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'editModel' }, columnIndex: 10, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 11, headerTitle: "Delete"
    })
    deleteAction:any;


//#region Generated Reference Properties

//#endregion Generated Reference Properties





}