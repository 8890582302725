import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vActivityLogTypeLookUpBase {

//#region activityLogTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'activityLogTypeId', keyColumn: true})
        activityLogTypeId : number;
//#endregion activityLogTypeId Prop


//#region activityName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'activityName', keyColumn: false})
        activityName : string;
//#endregion activityName Prop


//#region systemKeyword Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'systemKeyword', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop

}