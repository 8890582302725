import { prop } from "@rxweb/reactive-form-validators";

export class WhiteListIpBase {
    @prop()
    id:number;

    @prop()
    ipAddress:string;

    @prop()
    comment:string;

    @prop()
    createdOn:Date;

    @prop()
    updatedOn:Date;

    @prop()
    statusId:number;

}