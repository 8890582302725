import { Component, OnInit, OnDestroy, Input, EventEmitter } from "@angular/core"
import { AbstractProjectCorporateMapping } from '../domain/abstract-project-corporate-mapping';
import { vPersonCorporateMapping, Project, Event, vEventCorporateMapping } from "@app/models";
import { ProjectCorporateMappingAddComponent } from '../add/project-corporate-mapping-add.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe, PlatformLocation } from '@angular/common';
import { AppGrid } from 'src/app/domain/app-grid';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { PeopleCorporateInvolvedModel } from 'src/app/view-model/people-corporate-involved-model';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { multilingual } from '@rxweb/localization';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { GridColumnConfig } from '@rxweb/grid';
@multilingual("ProjectCorporateMappingListComponent")

@Component({
  selector: "app-project-corporate-mapping-list",
  templateUrl: './project-corporate-mapping-list.component.html'
})
export class ProjectCorporateMappingListComponent extends AbstractProjectCorporateMapping implements OnInit, OnDestroy {
  projectCorporateMappings: PeopleCorporateInvolvedModel[];
  projectCorporate: PeopleCorporateInvolvedModel;
  projectCorporateGridColumns: any;
  subscription: any;
  id: number = 0;
  @Input() componentType: string;
  @Input() isRestricted: boolean = false;
  modalView: ModalView;
  json: any;
  projectOrEvent: number;
  totalRecords: number = 0;
  isFilter: boolean = false;
  gridSearchString: string = "";
  pageIndex: number = PaginationEnum.PageIndex;
  rowCount: number = PaginationEnum.RowCount;
  paginationViewModel: PaginationViewModel;
  project: Project;
  event: Event;
  projectCorporateMappingList: vPersonCorporateMapping[];
  EventCorporateMappingList: any;
  loggedInUser: number;
  isHideAll: boolean = false;
  isShowAll: boolean = false;
  expandTitle: string = "Expand";
  collapseTitle: string = "Collapse";
  titleSearch: string;
  isShowGridColumn: boolean = false;
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";
  isLongTextExists: boolean = false;
  allowEditRestrictAccess: boolean = false;

  constructor(private router: Router, private browserStorage: BrowserStorage, modelView: ModalView, private datePipe: DatePipe, private activatedRoute: ActivatedRoute,private location : PlatformLocation) {
    super();
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    });
    if (this.browserStorage.local.get("userId") != null) {
      this.loggedInUser = this.browserStorage.local.get("userId");
    } else {
      this.loggedInUser = 0;
    }
    this.modalView = modelView;
    Window["ProjectCorporateMappingListComponent"] = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.isFilter = false;
    this.titleSearch = getLocalizedValue("Title_Search");
    this.expandTitle = getLocalizedValue("Title_Expand");
    this.collapseTitle = getLocalizedValue("Title_Collapse");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    if(this.componentType == 'project'){
      this.projectOrEvent = 0;
    }
    else if(this.componentType == 'events'){
      this.projectOrEvent = 1;
    }
    else if(this.componentType == 'project-restrict-access'){
      this.projectOrEvent = 2;
    }
    this.json = JSON.parse(JSON.stringify({
      eventId: this.id, projectId: this.id,
      userId: 0, projectOrEvent: this.projectOrEvent, pageIndex: 1, rowCount: 25, sortOrder: true, orderBy: 'person', searchString: ''
    }));
    this.json["pageIndex"] = PaginationEnum.PageIndex;
    this.json["rowCount"] = PaginationEnum.RowCount;
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
      this.post({ path: 'api/SearchEvent/AllowEditRestrictAccess', body: { query: JSON.stringify(this.json) } }).subscribe((res: any) => {
        this.allowEditRestrictAccess = res;
      })
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      setTimeout(() => {
        this.bindGrid();
      }, 0);
    })
  }

  bindGrid(isOnload: boolean = true) {
    this.isShowGrid = true;
    if(this.componentType == 'project'){
      this.projectOrEvent = 0;
    }
    else if(this.componentType == 'events'){
      this.projectOrEvent = 1;
    }
    else if(this.componentType == 'project-restrict-access'){
      this.projectOrEvent = 2;
    }
    this.json["eventId"] = this.id;
    this.json["projectId"] = this.id;
    this.json["projectOrEvent"] = this.projectOrEvent;
    this.spin = true;
    this.post({ path: 'api/SearchEvent/PeopleSearch', body: { query: JSON.stringify(this.json) } }).subscribe((t: any) => {
      this.spin = false;
      this.projectCorporateMappings = t;
      // if (isOnload) {
      //     for (let element of this.projectCorporateMappings) {
      //         if (element.noteCharCount > 100) {
      //             this.isShowAll = true;
      //             break;
      //         }
      //     }
      // }
      if (this.projectCorporateMappings.length > 0) {
        this.totalRecords = this.projectCorporateMappings[0].totalCount;
        this.projectCorporateMappings.forEach(x => {
          if (x.companyDetails != null && x.companyDetails != undefined && x.companyDetails.length > 0 && x.customerID != 0) {
            x.corporate = this.createCorporateTable(x);
          }
        });
        this.isNoRecordFound = false;
      }
      else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }

      if (!this.isFilter) {
        this.projectCorporateGrid = new AppGrid(this.projectCorporateMappings, PeopleCorporateInvolvedModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onDelete: this.onDelete.bind(this), onOneLink: this.reDirectToPeople.bind(this), onCorporateLink: this.redirectToCorporate.bind(this), onMoreNotelick: this.onMoreNotelick.bind(this), onMoreProfDesclick:this.onMoreProfDesclick.bind(this) } });
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(x => {
            this.projectCorporateGrid.gridColumns.forEach(y => {
              if (y.name == x.ColumnName)
                y.visible = x.Visible;
            });
          });
          this.gridColumnConfig = this.projectCorporateGrid.gridColumns;
          this.gridColumnSettings.emit(this.gridColumnConfig)
        }
        this.projectCorporateGrid.gridColumns.forEach(element=>{
          if(element.name == 'mostRecentPressMedia' && this.projectOrEvent == 1){
            element.visible = false;
            element.parameter = true;
          }
        })
        this.checkForLongTexts();
        this.projectCorporateGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }
      }
      else {
        if (this.projectCorporateGrid != null) {
          this.projectCorporateGrid.storeProcedure.length = this.totalRecords;
          this.projectCorporateGrid.updateSource([]);

          this.projectCorporateGrid.updateSource(this.projectCorporateMappings)
        }
        this.checkForLongTexts();
      }
      this.projectCorporateGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
      this.projectCorporateGrid.designClass.headerClass = "table-header-sticky".split(" ");
      if (this.projectCorporateGrid != null) {
        this.projectCorporateGridColumns = this.projectCorporateGrid.gridColumns.filter(x => x.keyColumn == false);
        this.applyClassProjectCorporateGrid();
      }
      this.projectCorporateGrid.maxPerPage = this.json['rowCount'];
      this.projectCorporateGrid.componentId = this.componentName;
      this.spin = false;
      this.showComponent = true;
    });
  }
  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  setRestriction() {
    this.isRestricted = !this.isRestricted
  }

  UpdateRestriction(isRedirect: boolean) {
    this.spin = true;
    this.post({ path: 'api/SearchEvent/UpdateRestriction', body: { projectOrEvent: this.projectOrEvent, id: this.id, Flag: this.isRestricted } }).subscribe(res => {
      if(this.componentType=='events'){
      if (res == 1) {
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
        if (isRedirect) {
          this.router.navigate(['/events', this.id], { queryParams: { activeTab: 'people', tabChange: 'true' } });
          this.ngOnInit;
          this.spin=false;
        }
        else {
          this.router.navigate(['/events']);
        }
      }
    }
    else if(this.componentType=='project-restrict-access'){
        if (res == 1) {
          var existsAlert = getLocalizedValue("Data_Updated");
          if (existsAlert) {
            this.toastr.success(existsAlert);
          }
          if (isRedirect) {
            this.router.navigate(['/projects', this.id], { queryParams: { activeTab: 'project-restrict-access', tabChange: 'true' } });
            this.ngOnInit;
            this.spin=false;
          }
          else {
            this.router.navigate(['/projects']);
          }
        }
      }
    })
  }
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(element => {
        var index = this.projectCorporateGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
        if (index != -1) {
          if (this.projectCorporateGrid.gridColumns[index].visible != element.Visible) {
            element.Visible = this.projectCorporateGrid.gridColumns[index].visible;
          }
        }
      })
  }
    this.checkForLongTexts();
    this.projectCorporateGrid.reDesign();
    this.projectCorporateGrid.updateSource([])
    this.projectCorporateGrid.updateSource(this.projectCorporateMappings);
  }
  showOrHideAll() {
    this.isShowAll = !this.isShowAll;
    this.isFilter = false;
    this.projectCorporateGrid.destroy();
    this.projectCorporateGrid = null;
    this.json["isShowAll"] = this.isShowAll;
    this.json["pageIndex"] = 1;
    this.bindGrid();
  }
  checkForLongTexts() {
    this.isLongTextExists = false;
    let isDescriptionCheckRequired = false;
    this.projectCorporateGrid.gridColumns.forEach(column => {
      if (column.name == "notes" && column.visible) {
        isDescriptionCheckRequired = true;
      }
    });
    if (isDescriptionCheckRequired) {
      for (let element of this.projectCorporateMappings) {

        if (element.noteCharCount > 100 && isDescriptionCheckRequired) {
          this.isLongTextExists = true;
          break;
        }
      }
    }
  }
  onMoreClick(t, x) {
    console.log(t, x);
    var element = document.getElementById("note_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  onMoreNotelick(t, x) {
    var element = document.getElementById("note_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  onMoreProfDesclick(t, x) {
    var element = document.getElementById("prof_desc_" + t.projectCorporateID);
    var maskedElement = document.getElementById("maskedprof_desc_" + t.projectCorporateID);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  onMoreClickAll() {
    this.isFilter = true;
    //this.isShowGrid=true;
    this.projectCorporateGrid.destroy();
    this.projectCorporateGrid = new AppGrid(this.projectCorporateMappings, PeopleCorporateInvolvedModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onDelete: this.onDelete.bind(this), onOneLink: this.reDirectToPeople.bind(this), onCorporateLink: this.redirectToCorporate.bind(this), onMoreNotelick: this.onMoreNotelick.bind(this) } });

    this.projectCorporateGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: 1,
      onPageSorting: this.onPageSorting.bind(this)
    }
    this.projectCorporateGrid.gridColumns.forEach(z => {
      // if (z.name == "isViewByPeople") {
      //   z.visible = this.entityTypeId == NotesEnum.OrderNotes ? true : false;
      // }
      if (z.name == 'notes') {
        z.visible = false
      }
      if (z.name == 'notes1') {
        z.visible = true
      }
      if(z.name == 'mostRecentPressMedia' && this.projectOrEvent == 1){
        z.visible = false;
        z.parameter = true;
      }
    })
    this.projectCorporateGrid.designClass.tableClass.push("table-list");
    if (this.projectCorporateGrid != null) {
      this.projectCorporateGridColumns = this.projectCorporateGrid.gridColumns.filter(x => x.keyColumn == false);
      this.applyClassProjectCorporateGrid();
    }
    // this.isShowAll = false;
    // this.isHideAll = true;
    this.projectCorporateGrid.maxPerPage = this.json['rowCount'];
    this.projectCorporateGrid.componentId = this.componentName;
  }
  onLessClickAll() {
    this.projectCorporateGrid.destroy();
    this.projectCorporateGrid = new AppGrid(this.projectCorporateMappings, PeopleCorporateInvolvedModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onDelete: this.onDelete.bind(this), onOneLink: this.reDirectToPeople.bind(this), onCorporateLink: this.redirectToCorporate.bind(this), onMoreNotelick: this.onMoreNotelick.bind(this) } });
    this.projectCorporateGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: 1,
      onPageSorting: this.onPageSorting.bind(this)
    }
    this.projectCorporateGrid.gridColumns.forEach(z => {
      if (this.projectCorporateGrid != null) {
        this.projectCorporateGridColumns = this.projectCorporateGrid.gridColumns.filter(x => x.keyColumn == false);
        this.applyClassProjectCorporateGrid();
      }
      if (z.name == 'notes') {
        z.visible = true
      }
      if (z.name == 'notes1') {
        z.visible = false
      }
      if(z.name == 'mostRecentPressMedia' && this.projectOrEvent == 1){
        z.visible = false;
        z.parameter = true;
      }
    })
    this.projectCorporateGrid.designClass.tableClass.push("table-list");
    if (this.projectCorporateGrid != null) {
      this.projectCorporateGridColumns = this.projectCorporateGrid.gridColumns.filter(x => x.keyColumn == false);
      this.applyClassProjectCorporateGrid();
    }
    // this.isShowAll = true;
    // this.isHideAll = false;
    this.projectCorporateGrid.maxPerPage = this.json['rowCount'];
    this.projectCorporateGrid.componentId = this.componentName;
  }


  reDirectToPeople(projectCorporateMapping: PeopleCorporateInvolvedModel) {
    //this.router.navigate(["users", projectCorporateMapping.customerID], { queryParams: { activeTab: 'user-edit' } });
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["users", projectCorporateMapping.customerID], { queryParams: { activeTab: 'user-edit' } }));
    redirectOnCTRL(url, this.router, event);
  }

  redirectToCorporate(invitee: PeopleCorporateInvolvedModel) {
    //this.router.navigate(['/suppliers', invitee.customerID], { queryParams: { activeTab: 'supplier-edit' } })
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(['/suppliers', invitee.customerID], { queryParams: { activeTab: 'supplier-edit' } }));
    redirectOnCTRL(url, this.router, event);
  }

  showAddForm() {
    this.modalView.show(ProjectCorporateMappingAddComponent, { mapId: this.id, componentType: this.componentType, involved: true }).then(t => {
      this.isFilter = true;
      this.totalRecords = 0;
      this.projectCorporateGrid.updateSource([]);
      this.bindGrid();
    });
  }

  onDelete(personCorporate: PeopleCorporateInvolvedModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        let mappingId = (this.componentType == 'project' || this.componentType == 'project-restrict-access') ? personCorporate.projectCorporateID : personCorporate.eventCustomerID;
        let path = (this.componentType == 'project' || this.componentType == 'project-restrict-access') ? "api/ProjectCorporateMappings" : "api/EventCustomerMappings";
        this.delete({ path: path, body: null, params: [mappingId], queryParams: { projectOrEvent: this.projectOrEvent } }).subscribe(data => {

          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully");
            this.toastr.success(existsAlert);
          }
          if (this.componentType == 'project' || this.componentType == 'project-restrict-access') {
            this.subscription = this.get({ params: [this.id], path: "api/Projects" }).subscribe(response => {
              this.project = response as Project;
              if (this.project.isRestrictedProject) {
                this.subscription = this.get({
                  path: "api/ProjectCorporateMappings",
                  queryParams: {
                    projectId: this.id
                  }
                }).subscribe((t: any) => {
                  this.projectCorporateMappingList = t;
                  if (this.projectCorporateMappingList.length > 0) {
                    if (this.projectCorporateMappingList.findIndex(x => x.customerID == this.loggedInUser) == -1 && this.loggedInUser != this.project.projectOwner) {
                      this.router.navigate(["projects/restricted-project", this.id]);
                    } else {
                      this.isFilter = true;
                      this.totalRecords = 0;
                      this.projectCorporateGrid.updateSource([]);
                      this.bindGrid();
                    }
                  } else {
                    if (this.loggedInUser != this.project.projectOwner) {
                      this.router.navigate(["projects/restricted-project", this.id]);
                    } else {
                      this.isFilter = true;
                      this.totalRecords = 0;
                      this.projectCorporateGrid.updateSource([]);
                      this.bindGrid();
                    }
                  }
                });
              }
              else {
                this.isFilter = true;
                this.totalRecords = 0;
                this.projectCorporateGrid.updateSource([]);
                this.bindGrid();
              }
            });
          }
          else if (this.componentType == 'events') {
            this.subscription = this.get({ params: [this.id], path: "api/Events" }).subscribe(response => {
              this.event = response as Event;
              if (this.event.isRestrictedEvent) {

                this.subscription = this.lookup([
                  { path: 'api/userlookups/EventCustomerInvloveList', propName: "eventInvolveLookUp", params: [this.id] },
                ]).subscribe((t: any) => {
                  this.EventCorporateMappingList = t.eventInvolveLookUp;
                  if (this.EventCorporateMappingList.length > 0) {
                    if (this.EventCorporateMappingList.findIndex(x => x.customerID == this.loggedInUser && x.involved) == -1 && this.loggedInUser != this.event.reportFiledBy) {
                      this.router.navigate(["events/restricted-event", this.id]);
                    } else {
                      this.isFilter = true;
                      this.totalRecords = 0;
                      this.projectCorporateGrid.updateSource([]);
                      this.bindGrid();
                    }
                  } else {
                    if (this.loggedInUser != this.event.reportFiledBy) {
                      this.router.navigate(["events/restricted-event", this.id]);
                    } else {
                      this.isFilter = true;
                      this.totalRecords = 0;
                      this.projectCorporateGrid.updateSource([]);
                      this.bindGrid();
                    }
                  }
                });
              }
              else {
                this.isFilter = true;
                this.totalRecords = 0;
                this.projectCorporateGrid.updateSource([]);
                this.bindGrid();
              }
            });
          }
          else {
            this.isFilter = true;
            this.totalRecords = 0;
            this.projectCorporateGrid.updateSource([]);
            this.bindGrid();
          }
        })
      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confimation");
    if(this.projectOrEvent == 0){
      existsAlert = getLocalizedValue("Delete_Confirmation_Project_PersonCorporate");
    }
    else if(this.projectOrEvent == 1){
      existsAlert = getLocalizedValue("Delete_Confirmation_Event_Person");
    }
    else if(this.projectOrEvent == 2){
      existsAlert = getLocalizedValue("Delete_Confirmation_Project_Person");
    } 
    if (existsAlert) {
      return existsAlert;
    }
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.json['searchString'] = value.target.value.replace(/^\s+|\s+$/gm, '');
      this.totalRecords = 0;
      this.projectCorporateGrid.storeProcedure.nextPage = 1;
      this.json['pageIndex'] = 1;
      this.bindGrid(false);
    }, 4000);
    this.spin = true;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json['sortOrder'] = y;
    this.json['orderBy'] = x;
    this.projectCorporateGrid.storeProcedure.nextPage = z;
    this.projectCorporateGrid.updateSource([]);
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json['pageIndex'] = x;
    this.json['rowCount'] = this.projectCorporateGrid.maxPerPage
    this.projectCorporateGrid.storeProcedure.nextPage = x;
    this.bindGrid(false);
  }

  createCorporateTable(item: PeopleCorporateInvolvedModel): any {
    let tabledata: any;
    let row: string = "";
    let subRow: any = "";
    tabledata = "<div><table style='width: 100%;'><tbody>";
    let subTable: any;
    subTable = "<tr id='corporate_" + item.projectCorporateID + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
    if (item.companyDetails != null && item.companyDetails != undefined) {
      for (let i = 0; i < item.companyDetails.length; i++) {
        let x: any = item.companyDetails[i];
        if (i == 0) {
          let id: string;
          id = "'" + item.projectCorporateID + "'";
          row = row + `<tr><td><a class="${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold" href="${x.companyUrl}">${x.companyName}</a>
          ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
          ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy.replaceAll("'",'&#39;') + "' id='" + x.companyId + "' style='margin-left: 0.15rem;'  (mouseover)='bindTooltip(" + x.companyId + "," + x.companyIntroducesBy.replaceAll("'",'&#39;') + ")'></i>": ""}
          ${x.jobTitle == "" || x.jobTitle == null ? "" : `(${x.jobTitle})`} - ${x.companyStatus}`;

          if (item.companyDetails.length > 1) {
            row = row + `<i class='fa fa-chevron-down ml-2' title='${this.expandTitle}' id='icon_corporate_${item.projectCorporateID}' onclick='Window.ProjectCorporateMappingListComponent.expandCorporateTable(${item.projectCorporateID})'></i></td></tr>`;
         }

        }
        else {
          subRow = subRow + `<tr><td><a class="${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold" href="${x.companyUrl}">${x.companyName}</a>
          ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
          ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy.replaceAll("'",'&#39;') + "' id='" + x.companyId + "' style='margin-left: 0.15rem;' (mouseover)='bindTooltip(" + x.companyId + ", '" + x.companyIntroducesBy.replaceAll("'",'&#39;') + "')'></i>": ""}
          ${x.jobTitle == "" || x.jobTitle == null ? "" : ` (${x.jobTitle})`} - ${x.companyStatus}</td></tr>`;
        }
      }
      if (subRow != "") {
        subTable = subTable + subRow + "</tbody></table></div></td></tr>";
        row = row + subTable;
      }
      tabledata = tabledata + row + "</tbody></table></div>"
    }
    return tabledata;
  }

  expandCorporateTable(id: number) {
    var element = document.getElementById("corporate_" + id.toString());
    var iconElement = document.getElementById("icon_corporate_" + id.toString());
    this.changeIcon(element, iconElement);
  }

  private changeIcon(element: HTMLElement, iconElement: HTMLElement) {
    if (element != null) {
      if (element.style.display == "none") {
        element.style.display = "block";
        if (iconElement != null) {
          iconElement.className = "fa fa-chevron-up ml-2";
          iconElement.title = this.collapseTitle;
        }

      }
      else {
        element.style.display = "none";
        if (iconElement != null) {
          iconElement.className = "fa fa-chevron-down ml-2";
          iconElement.title = this.expandTitle;
        }
      }
    }
  }

  get componentName(): string {
    return "ProjectCorporateMappingListComponent";
  }

}
