import {vBodilyLocationRecordBase,} from '@app/database-models'
//Generated Imports
export class vBodilyLocationRecord extends vBodilyLocationRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}