import {vEmailAccountRecordBase,} from '@app/database-models'
//Generated Imports
export class vEmailAccountRecord extends vEmailAccountRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}