import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxBasedOnLookUpBase {

//#region taxBasedOnId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'taxBasedOnId', keyColumn: true})
        taxBasedOnId : number;
//#endregion taxBasedOnId Prop


//#region taxBasedOnName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'taxBasedOnName', keyColumn: false})
        taxBasedOnName : string;
//#endregion taxBasedOnName Prop

}