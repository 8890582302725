import {CollectionDesignModuleBase,} from '@app/database-models'
//Generated Imports
export class CollectionDesignModule extends CollectionDesignModuleBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}