import {vGetShoppingCartByUserSessionGuidBase,} from '@app/database-models'
//Generated Imports
export class vGetShoppingCartByUserSessionGuid extends vGetShoppingCartByUserSessionGuidBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}