import {vCurrencyLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCurrencyLookUp extends vCurrencyLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}