import {GemStoneNoteBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class GemStoneNote extends GemStoneNoteBase 
{




//#region Generated Reference Properties
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop

//#endregion Generated Reference Properties






































}