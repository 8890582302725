import {vLeaveTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vLeaveTypeLookUp extends vLeaveTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}