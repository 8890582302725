import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SpecificationMasterBase {

//#region specificationAttributeId Prop
        @prop()
        specificationAttributeId : number;
//#endregion specificationAttributeId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region pictureId Prop
        @prop()
        pictureId : number;
//#endregion pictureId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop





}