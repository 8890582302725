import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPaymentMethodBase {

//#region paymentMethodId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'PaymentMethodListComponent_MethodId_gh', keyColumn: true})
        paymentMethodId : number;
//#endregion paymentMethodId Prop


//#region paymentMethodName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'PaymentMethodListComponent_MethodName_gh', keyColumn: false})
        paymentMethodName : string;
//#endregion paymentMethodName Prop


//#region visibleName Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'PaymentMethodListComponent_VisibleName_gh', keyColumn: false})
        visibleName : string;
//#endregion visibleName Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'PaymentMethodListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region isImpersonated Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'PaymentMethodListComponent_IsImpersonated_gh', keyColumn: false,configuredTemplate: { templateName: "recordActive" } })
        isImpersonated : boolean;
//#endregion isImpersonated Prop


//#region isActive Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'PaymentMethodListComponent_IsActive_gh', keyColumn: false,configuredTemplate: { templateName: "recordActive" } })
        isActive : boolean;
//#endregion isActive Prop

}