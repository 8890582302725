import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemfieldSalesChannelBase {

//#region salesChannelId Prop
        @prop()
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region salesChannel Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        salesChannel : string;
//#endregion salesChannel Prop


//#region salesChannelType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        salesChannelType : number;
//#endregion salesChannelType Prop


//#region billingCountryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        billingCountryId : number;
//#endregion billingCountryId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}