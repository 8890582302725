import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { Picture } from '@app/models';
import { AbstractWebPicture } from '../domain/abstract-web-picture';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@Component({
  selector: "app-web-picture-add",
  templateUrl: './web-picture-add.component.html'
})
export class WebPictureAddComponent extends AbstractWebPicture implements OnInit, OnDestroy {
  picture: Picture;
  subscription: any;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  titleClose: string;
  constructor(private formBuilder: RxFormBuilder, location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(location,blobState);
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    // this.picture = new Picture();
    // this.pictureFormGroup = this.formBuilder.formGroup(this.picture) as IFormGroup<Picture>;
    // this.showComponent = true;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
