import {vProductionFormSalesChannelBase,} from '@app/database-models'
//Generated Imports
export class vProductionFormSalesChannel extends vProductionFormSalesChannelBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}