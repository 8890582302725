<div class="modal-dialog modal-lg modal-xl">
    <div class="modal-content" *rxMultilingual="">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" [rxLocalisationInit]="componentName">
                <label rxText="ExistsUserSearchComponent_Title_t" class="m-0"></label>
            </h4>
            <button type="button" class="close py-2" (click)="close()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body pb-0">
            <div class="search-result-container" [rxSpinner]="spin">
                <div class="ibox mb-0 d-flex flex-column mt-4" *ngIf="isShowGrid">
                    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                        <div class="table-responsive">
                            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                                <rx-grid [design]="userSearchGrid"></rx-grid>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                    <div>
                        <h4>No Record Found</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" [rxLocalisationInit]="componentName">
            <button (click)="hide()" class="btn btn-outline-primary" ><span rxText="ExistsUserSearchComponent_Skip_t"></span></button>
        </div>
    </div>
</div>
