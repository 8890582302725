import { gridColumn } from "@rxweb/grid"


export class vBookingTypeLookUpBase {

//#region bookingId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'bookingId', keyColumn: true})
        bookingId : number;
//#endregion bookingId Prop


//#region bookingType Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'bookingType', keyColumn: false})
        bookingType : string;
//#endregion bookingType Prop

}