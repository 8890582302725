import { numeric, prop, required } from '@rxweb/reactive-form-validators';

export class ProductionFormMappingDetailBase {

  //#region formMappingDetailID Prop
  @prop()
  formMappingDetailID: number;
  //#endregion formMappingDetailID Prop

  //#region productionOrderFormID Prop
  @required()
  @prop()
  productionOrderFormID: number;
  //#endregion productionOrderFormID Prop

  @prop()
  quoteRequired: boolean;
  //#endregion quoteRequired Prop

  //#region quoteRequiredDate Prop
  //@required()
  @prop()
  quoteRequiredDate: Date;
  //#endregion quoteRequiredDate Prop

  //#region stoneViewingRequired Prop
  @prop()
  stoneViewingRequired: boolean;
  //#endregion stoneViewingRequired Prop

  //#region stoneViewingRequiredDate Prop
  //@required()
  @prop()
  stoneViewingRequiredDate: Date;
  //#endregion stoneViewingRequiredDate Prop

  //#region designsRequired Prop
  @prop()
  designsRequired: boolean;
  //#endregion designsRequired Prop

  //#region designsRequiredDate Prop
  //@required()
  @prop()
  designsRequiredDate: Date;
  //#endregion designsRequiredDate Prop

  //#region itemDescription Prop
  @prop()
  itemDescription: string;
  //#endregion itemDescription Prop

  //#region ringSizeCountryID Prop
  @prop()
  ringSizeCountryID: number;
  //#endregion ringSizeCountryID Prop

  //#region quantity Prop
  // @prop({defaultValue:1})
  // @numeric({allowDecimal:false})
  quantity: number;
  //#endregion quantity Prop

  //#region enhancementTypeID Prop
  @prop()
  enhancementTypeID: number;
  //#endregion enhancementTypeID Prop

  //#region colorID Prop
  @prop()
  colorID: number;
  //#endregion colorID Prop

  //#region cutTypeID Prop
  @prop()
  cutTypeIDs: string;
  //#endregion cutTypeID Prop

  //#region originID Prop
  @prop()
  originID: number;
  //#endregion originID Prop

  //#region clarityID Prop
  @prop()
  clarityID: number;
  //#endregion clarityID Prop


  //#region laboratoryID Prop
  @prop()
  laboratoryIDs: string;
  //#endregion laboratoryID Prop

  //#region productID Prop
  @prop()
  productID: number;
  //#endregion productID Prop

  //#region rrp Prop
  @prop()
  rrp: number;
  //#endregion rrp Prop

  //#region gemstoneID Prop
  @prop()
  gemstoneIDs: string;
  //#endregion gemstoneID Prop

  //#region addStoneInfo Prop
  @prop()
  addStoneInfo: string;
  //#endregion addStoneInfo Prop

  //#region metalID Prop
  @prop()
  materialIDs: string;
  //#endregion metalID Prop

  //#region chainLengthID Prop
  @prop()
  chainLengthID: number;
  //#endregion chainLengthID Prop

  //#region chainGaugeID Prop
  @prop()
  chainGaugeID: number;
  //#endregion chainGaugeID Prop

  //#region chainStyleID Prop
  @prop()
  chainStyleID: number;
  //#endregion chainStyleID Prop

  //#region engraving Prop
  @prop()
  engraving: string;
  //#endregion engraving Prop

  //#region clientBudget Prop
  @prop()
  clientBudget: number;
  //#endregion clientBudget Prop

  //#region currencyID Prop
  @prop()
  currencyID: number;
  //#endregion currencyID Prop

  //#region clientBudgetUSD Prop
  @prop({})
  clientBudgetUSD: number;
  //#endregion clientBudgetUSD Prop

  //#region deliveryDate Prop
  @prop()
  deliveryDate: number;
  //#endregion deliveryDate Prop

  //#region occasion Prop
  @prop()
  occasion: string;
  //#endregion occasion Prop

  //#region deliveryCountryID Prop
  @prop()
  deliveryCountryID: number;
  //#endregion deliveryCountryID Prop

  //#region isDeleted Prop
  @prop()
  isDeleted: boolean;
  //#endregion isDeleted Prop

  //#region bangleSizeID Prop
  @prop()
  bangleSizeID: number;
  //#endregion bangleSizeID Prop

  //#region clientDescription Prop
  @prop()
  clientDescription: string;
  //#endregion clientDescription Prop


  //#region productCategoryID Prop
  @prop()
  productCategoryID: number;
  //#endregion productCategoryID Prop


  //#region ringSize Prop
  @prop()
  ringSize: number;
  //#endregion ringSize Prop

  //#region relatedProductIds Prop
  @prop()
  relatedProductIds: string;
  //#endregion relatedProductIds Prop

  //#region customVariationDescription Prop
  @prop()
  customVariationDescription: string;
  //#endregion customVariationDescription Prop

  //#region materialType Prop
  @prop()
  materialType: string;
  //#endregion materialType Prop

  //#region gemstoneDescription Prop
  @prop()
  gemstoneDescription: string;
  //#endregion gemstoneDescription Prop

  //#region measurement Prop
  @prop()
  measurement: string;
  //#endregion measurement Prop

  //#region additionalRequirementDescription Prop
  @prop()
  additionalRequirementDescription: string;
  //#endregion additionalRequirementDescription Prop

  //#region paymentTerms Prop
  @prop()
  paymentTerms: string;
  //#endregion paymentTerms Prop

  //#region uploadFile Prop
  @prop()
  uploadFile: string;
  //#endregion uploadFile Prop

  //#region SalesChannelID Prop
  @prop()
  uploadFile1: string;
  //#endregion uploadFile1 Prop

  //#region SalesChannelID Prop
  @prop()
  uploadFile2: string;
  //#endregion uploadFile2 Prop

  //#region createdBy Prop
      //@required()
      @prop({defaultValue:0})
      createdBy: number;
  //#endregion createdBy Prop

  //#region createdOn Prop
  //@required()
  @prop({defaultValue:new Date()})
  createdOn: Date;
  //#endregion createdOn Prop


  //#region modifiedOn Prop
  @prop()
  modifiedOn: Date;
  //#endregion modifiedOn Prop

  //#region modifiedBy Prop
  @prop({defaultValue:0})
  modifiedBy: number;
  //#endregion modifiedBy Prop


  //#region additionalProductInfo Prop
  @prop()
  additionalProductInfo: string;
  //#endregion additionalProductInfo Prop


  //#region  shippingQuote Prop
    @prop()
    shippingQuotation: string;
  //#endregion shippingQuote Prop

  //#region repairQuote Prop
    @prop()
    repairQuotation: string;
  //#endregion repairQuote Prop


  @prop()
  pieceIds: string;

  @prop()
  pieceName: number;

  @prop()
  oldFabergePiece: string;

  @prop()
  warranty: boolean;

  @prop()
  gold:string;

  @prop()
  rrp2:string;

  @prop()
  repairInvNo:string;

  @prop()
  supplierId:number;

  @prop()
  supplierName:string;

  @prop()
  RepairGuid:string;

  @prop()
  estimationRequired:boolean;

  @prop()
  AdditionalRepairDetails:boolean;
}
