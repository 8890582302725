import { notEmpty, prop, required, trim } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';

export class AgreementTypeBase {

    //#region agreementTypeId Prop
    @prop({ defaultValue: 0 })
    agreementTypeId: number;
    //#endregion agreementTypeId Prop

    //#region agreementTypeName Prop
    @required()
    @notEmpty()
    @prop()
    @trim()
    agreementTypeName: string;
    //#endregion agreementTypeName Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    statusId: number;
    //#endregion statusId Prop

}