<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
        <div class="row wrapper white-bg page-heading py-2 align-items-center">
            <div class="col-md-8 my-1">
              <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard']">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/languages']" rxText="Label_Languages_t"></a>
                </li>
                <li class="breadcrumb-item active">Add New</li>
              </ol>
            </div>
            <div class="col-md-4 text-right">
              <app-system-time></app-system-time>
            </div>
          </div>

        <div class="wrapper wrapper-content flex-grow-1">

          <div class="ibox mb-0 d-flex flex-column">
            <div class="ibox-title bg-white pr-3">
              <h5><label rxText="LanguageAddComponent_Title"></label></h5>
              <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x"></i>
              </a>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="languageFormGroup" [rxSpinner]="spin">
              <div class="row add-countries-form" (keyup.enter)="addLanguage(false)">
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LanguageAddEditComponent_LanguageName_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="name" tabindex="1" class="form-control" rxPlaceholder="LanguageAddEditComponent_LanguageName_p" rxFocus />
                      <small class="form-text text-danger" [class.d-block]="languageFormGroup.controls.name.errors">{{languageFormGroup.controls.name.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LanguageAddEditComponent_DisplayPriority_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="displayOrder" tabindex="4" class="form-control" rxPlaceholder="LanguageAddEditComponent_DisplayPriority_p" />
                      <small class="form-text text-danger" [class.d-block]="languageFormGroup.controls.displayOrder.errors">{{languageFormGroup.controls.displayOrder.errorMessage}}</small>
                    </div>
                  </div>

                </div>
                <div class="col-lg-6 col-xl-4 px-xl-4">

                  <div class="form-group row check-group">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LanguageAddEditComponent_LanguagePublished_t"></label>
                    <div class="col-md-auto d-flex align-items-center">
                      <div class="i-checks">
                        <label class="mb-0">
                          <!-- <input type="checkbox" formControlName="published"><i></i> -->
                          <app-checkbox [checkFormControl]="languageFormGroup.controls.published" tabindex="2"></app-checkbox>
                        </label>
                      </div>
                      <small class="form-text text-danger" [class.d-block]="languageFormGroup.controls.published.errors">{{languageFormGroup.controls.published.errorMessage}}</small>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label" rxText="LanguageAddEditComponent_FlagImageFileName_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="flagImageFileName" tabindex="5" class="form-control" rxPlaceholder="LanguageAddEditComponent_FlagImageFileName_p" />
                      <small class="form-text text-danger" [class.d-block]="languageFormGroup.controls.flagImageFileName.errors">{{languageFormGroup.controls.flagImageFileName.errorMessage}}</small>
                    </div>

                  </div>

                </div>
                <div class="col-lg-6 col-xl-4 pl-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LanguageAddEditComponent_LanguageCulture_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <select class="form-control" formControlName="languageCulture">
                        <option [selected]="true">Select</option>
                        <option *ngFor="let item of languageCultureLookup.culturesLookUp" [value]="item.ietfLanguageTag">{{item.name}}</option>
                      </select> -->
                          <rx-select [(source)]="languageCultureLookup.culturesLookUp" #rxSelect tabindex="3"
                        mainClass="form-control" formControlName="languageCulture"
                        [keyValueProps]="['name','ietfLanguageTag']" [selectPlaceholder]="'Select Language culture'"></rx-select>
                      <small class="form-text text-danger" [class.d-block]="languageFormGroup.controls.languageCulture.errors">{{languageFormGroup.controls.languageCulture.errorMessage}}</small>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="LanguageAddEditComponent_Domain_t"></label>
                    <div class="col-md-8">
                      <div class="d-grid align-items-center">
                        <div class="i-checks" *ngFor="let item of languageCultureLookup.customerRoleDomainLookUp">
                          <label class="mb-0">
                            <div class="iradio_square-green"
                              [class.checked]="languageFormGroup.value.domain==item.customerRoleDomainId"
                              style="position: relative;">
                              <input tabindex="11" type="radio" [value]="item.customerRoleDomainId" formControlName="domain"
                                style="visibility: hidden;">
                            </div><i></i>{{item.customerRoleDomainName}}
                          </label>
                        </div>
                      </div>

                      <small class="form-text text-danger"
                        [class.d-block]="languageFormGroup.controls.domain.errors">{{languageFormGroup.controls.domain.errorMessage}}</small>
                    </div>
                  </div>

                </div>
                </div>
                <div class="row mt-auto">
                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="col text-right">
                    <button (click)="addLanguage(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                    <ng-container [rxLocalisationInit]="componentName" *rxAuthorize="[languageEdit]">
                      <button (click)="addLanguage(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                    </ng-container>
                  </div>
                </div>
              </div>
          </div>

        </div>

      </div>
