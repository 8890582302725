import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vVLNewTypeLookUpBase {

//#region vLNewTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'vLNewTypeId', keyColumn: true})
        vLNewTypeId : number;
//#endregion vLNewTypeId Prop


//#region vLNewTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'vLNewTypeName', keyColumn: false})
        vLNewTypeName : string;
//#endregion vLNewTypeName Prop

}