import {CarnetNoteDocumentBase,CarnetBase,} from '@app/database-models'
//Generated Imports
export class CarnetNoteDocument extends CarnetNoteDocumentBase 
{




//#region Generated Reference Properties
//#region carnet Prop
carnet : CarnetBase;
//#endregion carnet Prop

//#endregion Generated Reference Properties











}