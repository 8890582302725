import { TemplateConfig } from '@rxweb/grid';

export const COREPRODUCT_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => { 
        if(x.hasCoreProduct==null || x.hasCoreProduct==undefined || x.hasCoreProduct==false){
          return false;
        }
        else{
          return true;
        }
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },
                 event: {
                  input: "onCoreSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

