import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";

import { SharedModule } from '../../shared/shared.module';
import { FileModule } from '../../shared/global/file/file.module';
import { CorporateCompanyMappingEditComponent } from '../corporate-company-mappings/edit/corporate-company-mapping-edit.component';
import { CorporateGroupsAddComponent } from '../users/details/corporate-groups/add/corporate-groups-add.component';
import { CorporateCompanyMappingAddComponent } from '../corporate-company-mappings/add/corporate-company-mapping-add.component';
import { CorporateGroupsEditComponent } from '../users/details/corporate-groups/edit/corporate-groups-edit.component';
import { OwnershipMappingSharedModule } from '../ownership-mappings/ownership-mapping-shared.module';


@NgModule({
    imports: [FileModule, OwnershipMappingSharedModule,
        CommonModule, SharedModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule],
    declarations: [CorporateCompanyMappingEditComponent, CorporateCompanyMappingAddComponent, CorporateGroupsAddComponent, CorporateGroupsEditComponent],
    providers: []
})
export class SupplierSharedModule { }

