import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTemporaryAssigneeTypeBase {

//#region temporaryAssigneeTypeID Prop
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:0, allowSorting: true, headerKey: 'TemporaryAssigneeTypeListComponent_ID_gh', keyColumn: true})
        temporaryAssigneeTypeID : number;
//#endregion temporaryAssigneeTypeID Prop


//#region typeName Prop
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, isAscending:true, columnIndex:1, allowSorting: true, headerKey: 'TemporaryAssigneeTypeListComponent_TypeName_gh', keyColumn: false})   
        //@gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, isAscending:true, columnIndex:1, allowSorting: true, headerKey: 'TemporaryAssigneeTypeListComponent_TypeName_gh', keyColumn: false})
        typeName : string;
//#endregion typeName Prop

}