import {vPOSBrandRecordBase,} from '@app/database-models'
//Generated Imports
export class vPOSBrandRecord extends vPOSBrandRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}