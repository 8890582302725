import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';
import { AppDivGrid, AppGrid } from 'src/app/domain/app-grid';
import { DatePipe } from '@angular/common';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { List } from '@rxweb/generics';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { vIANSuppliersDetailLookUp } from 'src/app/models/extended-models/v-i-a-n-suppliers-detail-look-up';
import { HSEReportFilterModel } from 'src/app/models/extended-models/hse-report-filter-model';
import { HSEReportViewModel } from 'src/app/models/extended-models/hse-report-view-model';
import { AbstractHSEReport } from '../domain/abstract-hseReport';
import { HseReportAddComponent } from '../add/hse-report-add.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import * as moment from 'moment';
import { IANTypeEnum } from 'src/app/enums/i-a-n-type.enum';
import { debounceTime } from 'rxjs/operators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { SystemHelpViewComponent } from 'src/app/components/master/system-help/view/system-help-view.component';
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { HSEGrievanceReportViewModel } from 'src/app/models/extended-models/hse-grievance-report-view-model';

export var isexistsGrivance: boolean;
@multilingual("HseReportListComponent")
@access({ accessLevel: RolePermissionEnums.HSEReport, action: "get" })
@Component({
  selector: 'app-hse-report-list',
  templateUrl: './hse-report-list.component.html'
})

export class HseReportListComponent extends AbstractHSEReport implements OnInit, OnDestroy {

    _this;

  loggedInUserId: number;
  subscription: any;
  hseReportGrid: AppGrid;
  hseGrievanceReportGrid: AppGrid;
  isShowGrid: boolean;
  isShowGridGrievance: boolean;
  isNoRecordFound: boolean = false;
  isNoRecordFoundGrievance: boolean = false;
  id: number;
  dateError: boolean = false;
  dateRangeError: boolean = false;
  totalRecords: number;
  isFilter: boolean;
  isFilterGrievance: boolean;
  spin: boolean = false;
  browserStorage: BrowserStorage;
  showComponent: boolean = false;
  userId: number;
  list: any[];
  grievanceList:any[];
  startDate = new Date();
  endDate = new Date();
  vIANSuppliersDetailLookUp: List<vIANSuppliersDetailLookUp>;
  hseReportSearchModel: HSEReportFilterModel;
  hseReportSearchFormGroup: IFormGroup<HSEReportFilterModel>
  hseReportViewModel: HSEReportViewModel[];
  hseGrievanceReportViewModel: HSEGrievanceReportViewModel[];
  hseLookups: any;
  openArray: number[];
  showGrid: boolean = false;
  exportExcel = new ExportToExcelModel();
  columns: any[];
  columnsGrievance:any[];
  run: boolean = false;
  runGrievanceReport: boolean = false;
  moduleID: number;
  titleHelp: string;
  //typeId: number[] = [62, 63, 61,1514];
  typeId: number[] = [1,IANTypeEnum.AccidentInjury, IANTypeEnum.Incident, IANTypeEnum.NearMissUnSafeAct, IANTypeEnum.Grievance];
  private hseReportSubject: Subject<string> = new Subject<string>();
  showSystemHelpIcon: boolean = false;

  isShowByMonths: boolean = false;
  lastDataIsShowByMonths: boolean = false;
  isShowGridTable1: boolean = false;
  isShowGridTable2: boolean = false;
  constructor(modelView: ModalView, private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private title: Title) {
    super();
    this.activatedRoute.queryParams.subscribe(t => {
      this.id = t['id'];
    })
    this.modalView = modelView;
    this.browserStorage = new BrowserStorage();
    this.loggedInUserId = this.browserStorage.local.get("userId", true);
    this.openArray = new Array<number>();
    this.columns = new Array<any>();
    this.columnsGrievance = new Array<any>();
    this.applicationBroadcaster.activeMenu({ activeMenu: 'reports', subMenu: 'hse-report' });
    this.moduleID = RolePermissionEnums.HSEReport;
    this._this=this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("HSE/GINA Report - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.spin = true;
    this.isFilter = false;
    this.isFilterGrievance=false;
    this.hseReportSearchModel = new HSEReportFilterModel();
    this.run = false;
    this.runGrievanceReport=false;
    this.hseReportSubject.pipe(debounceTime(300)).subscribe(t => {
      this.search();
    })
    this.lookup([
      { propName: "hseIANSupplierDetail", path: "api/FormLookups/IANSuppliersDetailLookUps" }
    ]).subscribe((lookup: any) => {
      this.spin = false;
      this.hseReportSearchFormGroup = this.formBuilder.formGroup(HSEReportFilterModel, this.hseReportSearchModel) as IFormGroup<HSEReportFilterModel>;
      this.hseLookups = lookup;
      this.showComponent = true;
      this.hseReportViewModel = new Array<HSEReportViewModel>();
      this.hseGrievanceReportViewModel = new Array<HSEGrievanceReportViewModel>();
    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  onKeyPress() {
    this.hseReportSubject.next();
  }

  search() {
    this.openArray = [];
    this.columns = [];
    this.columnsGrievance=[];
    this.hseReportViewModel = [];
    this.hseGrievanceReportViewModel=[];
    this.list = []
    this.grievanceList=[];
    this.searchHSEReport();
  }

  searchHSEReport() {
    this.startDate = this.hseReportSearchFormGroup.controls.startDate.value;
    this.endDate = this.hseReportSearchFormGroup.controls.endDate.value;
    if (this.startDate != null && this.endDate != null && this.startDate > this.endDate) {
      this.dateError = true;
      this.dateRangeError = false;
    }
    else if (this.isShowByMonths && this.startDate != null && this.endDate != null && this.endDate >= new Date(this.startDate.getFullYear() + 4, this.startDate.getMonth(), this.startDate.getDate())) {
      this.dateRangeError = true;
      this.dateError = false;
    }
    else if (this.hseReportSearchFormGroup.invalid) {
      return;
    }
    else {
      if (this.hseReportSearchFormGroup.value) {
        this.occurrenecGrid();
        this.grievanceGrid();

      }
    }
  }

  occurrenecGrid()
  {
    this.dateError = false;
    this.dateRangeError = false;
    this.spin = true;
    this.showGrid = true;
    this.isShowGrid = true;
    this.lastDataIsShowByMonths = this.isShowByMonths;
    var json = JSON.parse(JSON.stringify(this.hseReportSearchFormGroup.value));
    this.subscription = this.post({ path: "api/SearchNotesReport/HSEReportSearch", body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
      this.showComponent = true;
      this.isShowGridTable1 = true;
      setTimeout(() => {
        this.typeId.forEach(element => {
          t.forEach(ele => {
            if (ele.TypeId == element) {
              this.hseReportViewModel.push(ele);
            }
          });
        });
        isexistsGrivance = false;
        // if (t.find(x => x.TypeId == null)) {
        //   this.hseReportViewModel.push(t.find(x => x.TypeId == null));
        // }
        if(t.find (x => x.Company == null))
        {
          var index = this.hseReportViewModel.findIndex(x => x.Company == null);
        this.hseReportViewModel[index].Company='Total';
        }
       // this.list = this.list + this.hseReportViewModel;
        this.spin = false;
        if (this.hseReportViewModel.length > 0) {
          this.isNoRecordFound = false;
          for (var c in this.hseReportViewModel[0]) {
            if (c != "TypeId" && c != "Company" && c != "Total") {
              this.columns.push(
                { name: c, visible: true, columnIndex: 1, allowSorting: false, headerKey: c, headerTitle: c, keyColumn: false, configuredTemplate: { templateName: 'incidentDetails' } }
              )
            }
          }
        }
        else {
          this.isNoRecordFound = true;
          this.isShowGrid = false;
        }
        if (this.hseReportGrid != null) {
          this.isFilter = true;
        }
        if (this.hseReportViewModel.length > 0) {
          if (this.run) {
            this.addColumn();
            this.hseReportGrid.destroy();
          }
          else {
            this.run = true;
          }
          this.hseReportGrid = new AppGrid(this.hseReportViewModel, HSEReportViewModel, {
            actions: { onIncidentDataClick: this.onIncidentDataClick.bind(this) },
            columns: {
              startColumnIndex: 2, columnConfigs: this.columns
            }
          });
          var companyColumnIndex = this.hseReportGrid.gridColumns.findIndex(x => x.name == 'Company');
          if (companyColumnIndex > -1 && this.isShowByMonths) {
            this.hseReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Months_gh';
          }
          else {
            this.hseReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Company_gh';
          }
          this.isFilter = true;
          this.hseReportGrid.hideFooter = true;
          this.hseReportGrid.allowSorting = false;
          this.hseReportGrid.maxPerPage = 100;
          this.hseReportGrid.componentId = this.componentName;
          this.hseReportGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.hseReportGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.hseReportGrid.design(document.getElementById("hseReportGrid"));
          if (document.getElementsByClassName("highlight")) {
            for (var i = 0; i < document.getElementsByClassName("highlight").length; i++) {
              document.getElementsByClassName("highlight")[i].parentElement.style.backgroundColor = "#d2d3d4";
            }
          }
          if (document.getElementsByClassName("highlightFinal")) {
            for (var i = 0; i < document.getElementsByClassName("highlightFinal").length; i++) {
              document.getElementsByClassName("highlightFinal")[i].parentElement.style.backgroundColor = "#b4b5b6";
            }
          }
          if (this.hseReportGrid.gridColumns.length == 3) {
            this.addColumn();
          }
          //this.grievanceGrid();
        }

      }, 500);
    })

  }
  grievanceGrid()
  {
    this.dateError = false;
    this.dateRangeError = false;
    this.spin = true;
    this.showGrid = true;
    this.isShowGridGrievance=true;
    this.lastDataIsShowByMonths = this.isShowByMonths;
    var json = JSON.parse(JSON.stringify(this.hseReportSearchFormGroup.value));
  this.subscription = this.post({ path: "api/SearchNotesReport/HSEGrievanceReportSearch", body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
          this.showComponent = true;
          this.isShowGridTable2=true
          setTimeout(() => {
            this.typeId.forEach(element => {
              t.forEach(ele => {
                if (ele.TypeId == element) {
                  this.hseGrievanceReportViewModel.push(ele);
                }
              });
            });
            isexistsGrivance = true;
            if(t.find (x => x.Company == null))
            {
              var index = this.hseGrievanceReportViewModel.findIndex(x => x.Company == null);
            this.hseGrievanceReportViewModel[index].Company='Total';


            }
           // this.list= this.list + this.hseGrievanceReportViewModel;
            this.spin = false;
            if (this.hseGrievanceReportViewModel.length > 0) {
              this.isNoRecordFoundGrievance = false;
              for (var c in this.hseGrievanceReportViewModel[0]) {
                if (c != "TypeId" && c != "Company" && c != "Total") {
                  this.columnsGrievance.push(
                    { name: c, visible: true, columnIndex: 1, allowSorting: false, headerKey: c, headerTitle: c, keyColumn: false, configuredTemplate: { templateName: 'incidentDetails' } }
                  )
                }
              }
            }
            else {
              this.isNoRecordFoundGrievance = true;
              this.isShowGridGrievance = false;
            }
            if (this.hseGrievanceReportGrid != null) {
              this.isFilterGrievance = true;
            }
            if (this.hseGrievanceReportViewModel.length > 0) {
              if (this.runGrievanceReport) {
                this.addGrievanceColumn();

                this.hseGrievanceReportGrid.destroy();
              }
              else {
                this.runGrievanceReport = true;
              }
              this.hseGrievanceReportGrid = new AppGrid(this.hseGrievanceReportViewModel, HSEGrievanceReportViewModel, {
                actions: { onIncidentDataClick: this.onIncidentDataClick.bind(this) },
                columns: {
                  startColumnIndex: 2, columnConfigs: this.columnsGrievance
                }
              });
              var companyColumnIndex = this.hseGrievanceReportGrid.gridColumns.findIndex(x => x.name == 'Company');
              if (companyColumnIndex > -1 && this.isShowByMonths) {
                this.hseGrievanceReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Months_gh';
              }
              else {
                this.hseGrievanceReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Company_gh';
              }
              this.isFilterGrievance = true;
              this.hseGrievanceReportGrid.hideFooter = true;
              this.hseGrievanceReportGrid.allowSorting = false;
              this.hseGrievanceReportGrid.maxPerPage = 100;
              this.hseGrievanceReportGrid.componentId = this.componentName;
              this.hseGrievanceReportGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
              this.hseGrievanceReportGrid.designClass.headerClass = "table-header-sticky".split(" ");
              this.hseGrievanceReportGrid.design(document.getElementById("hseGrievanceReportGrid"));
              if (document.getElementsByClassName("highlight")) {
                for (var i = 0; i < document.getElementsByClassName("highlight").length; i++) {
                  document.getElementsByClassName("highlight")[i].parentElement.style.backgroundColor = "#d2d3d4";
                }
              }
              if (document.getElementsByClassName("highlightFinal")) {
                for (var i = 0; i < document.getElementsByClassName("highlightFinal").length; i++) {
                  document.getElementsByClassName("highlightFinal")[i].parentElement.style.backgroundColor = "#b4b5b6";
                }
              }
              if (this.hseGrievanceReportGrid.gridColumns.length == 3) {
                this.addGrievanceColumn();
              }
            }
          }, 500);
        })
  }

  addColumn() {
    if(this.hseReportGrid){
      this.hseReportGrid.destroy();
    }
    this.hseReportGrid = new AppGrid(this.hseReportViewModel, HSEReportViewModel, {
      actions: { onIncidentDataClick: this.onIncidentDataClick.bind(this) },
      columns: {
        startColumnIndex: 2, columnConfigs: this.columns
      }
    });
    var companyColumnIndex = this.hseReportGrid.gridColumns.findIndex(x => x.name == 'Company');
    if (companyColumnIndex > -1 && this.isShowByMonths) {
      this.hseReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Months_gh';
    }
    else {
      this.hseReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Company_gh';
    }
    this.isFilter = true;
    this.hseReportGrid.hideFooter = true;
    this.hseReportGrid.allowSorting = false;
    this.hseReportGrid.maxPerPage = 100;
    this.hseReportGrid.componentId = this.componentName;
    this.hseReportGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
    this.hseReportGrid.designClass.headerClass = "table-header-sticky".split(" ");

    this.hseReportGrid.design(document.getElementById("hseReportGrid"));
    for (var i = 0; i < document.getElementsByClassName("highlight").length; i++) {
      document.getElementsByClassName("highlight")[i].parentElement.style.backgroundColor = "#d2d3d4";
    }
  }
  addGrievanceColumn() {
    if(this.hseGrievanceReportGrid){
      this.hseGrievanceReportGrid.destroy();
    }
    this.hseGrievanceReportGrid = new AppGrid(this.hseGrievanceReportViewModel, HSEGrievanceReportViewModel, {
      actions: { onIncidentDataClick: this.onIncidentDataClick.bind(this) },
      columns: {
        startColumnIndex: 2, columnConfigs: this.columnsGrievance
      }
    });
    var companyColumnIndex = this.hseGrievanceReportGrid.gridColumns.findIndex(x => x.name == 'Company');
    if (companyColumnIndex > -1 && this.isShowByMonths) {
      this.hseGrievanceReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Months_gh';
    }
    else {
      this.hseGrievanceReportGrid.gridColumns[companyColumnIndex].headerKey = 'HseReportListComponent_Company_gh';
    }
    this.isFilterGrievance = true;
    this.hseGrievanceReportGrid.hideFooter = true;
    this.hseGrievanceReportGrid.allowSorting = false;
    this.hseGrievanceReportGrid.maxPerPage = 100;
    this.hseGrievanceReportGrid.componentId = this.componentName;
    this.hseGrievanceReportGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
    this.hseGrievanceReportGrid.designClass.headerClass = "table-header-sticky".split(" ");

    this.hseGrievanceReportGrid.design(document.getElementById("hseGrievanceReportGrid"));
    for (var i = 0; i < document.getElementsByClassName("highlight").length; i++) {
      document.getElementsByClassName("highlight")[i].parentElement.style.backgroundColor = "#d2d3d4";
    }
  }

  onIncidentDataClick(hseData: any, y, x, w) {
    this.modalView.show(HseReportAddComponent, { OccuranceName: hseData.Company, CompanyName: y.currentTarget.offsetParent.id, TypeId: hseData.TypeId, StartDate: this.startDate, EndDate: this.endDate, TCount: y.currentTarget.offsetParent.outerText, IsByMonth: this.isShowByMonths, supplierIds: this.hseReportSearchFormGroup.value.supplierId }).then(t => {
    });
  }

  changeReportType(data) {
    this.isShowByMonths = !this.isShowByMonths;
    if (this.isShowByMonths) {
      this.hseReportSearchFormGroup.controls.startDate.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" })]);
      this.hseReportSearchFormGroup.controls.endDate.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" })]);
      this.validateDates();
    }
    else {
      this.hseReportSearchFormGroup.controls.startDate.clearValidators();
      this.hseReportSearchFormGroup.controls.endDate.clearValidators();
      this.dateError = false;
      this.dateRangeError = false;
    }
    this.hseReportSearchFormGroup.controls.startDate.updateValueAndValidity();
    this.hseReportSearchFormGroup.controls.endDate.updateValueAndValidity();
    this.hseReportSearchFormGroup.controls.showByMonths.patchValue(this.isShowByMonths);
  }

  validateDates() {
    this.startDate = this.hseReportSearchFormGroup.controls.startDate.value;
    this.endDate = this.hseReportSearchFormGroup.controls.endDate.value;
    if (this.startDate != null && this.endDate != null && this.startDate > this.endDate) {
      this.dateError = true;
      this.dateRangeError = false;
    }
    else if (this.isShowByMonths && this.startDate != null && this.endDate != null && this.endDate >= new Date(this.startDate.getFullYear() + 4, this.startDate.getMonth(), this.startDate.getDate())) {
      this.dateRangeError = true;
      this.dateError = false;
    }
    else {
      this.dateRangeError = false;
      this.dateError = false;
    }
  }

  resetSearch() {
    this.hseReportSearchFormGroup.controls.startDate.clearValidators();
    this.hseReportSearchFormGroup.controls.endDate.clearValidators();
    this.hseReportSearchFormGroup.controls.startDate.updateValueAndValidity();
    this.hseReportSearchFormGroup.controls.endDate.updateValueAndValidity();
    this.hseReportSearchFormGroup.reset();
    this.dateError = false;
    this.isShowByMonths = false;
    this.showComponent = false;
    
    if(this.hseReportGrid && this.hseReportGrid.source.length > 0){
        this.hseReportGrid.destroy();
    }
    if(this.hseReportGrid){
        this.hseReportGrid = null;
        this.isFilter = false;
    }
    

    if(this.hseGrievanceReportGrid && this.hseGrievanceReportGrid.source.length > 0){
      this.hseGrievanceReportGrid.destroy();
    }
    if(this.hseGrievanceReportGrid){
        this.hseGrievanceReportGrid = null;
        this.isFilterGrievance = false;
    }
    this.showGrid = false;
    this.isShowGridTable1 = false;
    this.isShowGridTable2 = false;
    this.isShowGrid = false
    this.isShowGridGrievance = false;
    this.isNoRecordFound = false;
    this.isNoRecordFoundGrievance = false;
    

    setTimeout(() => {
        this.showComponent = true;
        this.spin = false;
    }, 100);

  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "HseReportListComponent";
  }

  exportToExcel() {
    this.spin = true;
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    this.exportExcel.FileName = "HSE_GINAReportResult" + MMDDYY;
    this.exportExcel.Type = "Excel";
    this.list=this.hseReportViewModel ;
    this.list=[].concat(this.hseReportViewModel,this.hseGrievanceReportViewModel);
    let returnedRequestData = this.requestExcelData(this.list);
    this.exportExcel.Result = JSON.stringify(returnedRequestData);
    this.exportExcel.Description = "HSE GINA Report";
    return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
      this.downloadDocument(Response, this.exportExcel.FileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      this.spin = false;
    }, error => {
      this.spin = false;
    })
  }

  requestExcelData(hseReportData: any) {
    let hseReportResultData = new List();
    hseReportData.forEach(element => {
      let listData = {};
      var Company = "";
      if (element.Company == "Accident / Injury / Fatality") {
        Company = "A.Sub-Total : Accident / Injury / Fatality";
      }
      else if (element.Company == "Incident") {
        Company = "B.Sub-Total : Incident";
      }
      else if (element.Company == "Near-miss / Unsafe act") {
        Company = "C.Sub-Total : Near-miss / Unsafe act";
      }
      else if (element.Company == "Grievance") {
        Company = "D.Sub-Total : Grievance";
      }
      else if (element.Company == "Total" ) {

        if (element.TypeId == 1487)
        {
          Company = "Total: Grievance";
        }
        else
        {
          Company = "Total: Occurrence Type";
        }

              }
      else if (element.Company == "Total" && element.TypeId == null) {
        if (isexistsGrivance) {
          Company = "GRAND TOTAL A+B+C+D";
        }
        else {
          Company = "GRAND TOTAL A+B+C";
        }

      }
      else {
        Company = element.Company;
      }
      listData[this.getGridColumnName("Company")] = Company;
      this.columns.forEach(ele => {
        if (["Company", "TypeId", "Total", "grid"].indexOf(ele.name) == -1) {
          listData[ele.name] = element[ele.name];
        }
      });
      listData[this.getGridColumnName("Total")] = element.Total;
      hseReportResultData.add(listData);
    });
    return hseReportResultData;
  }

  getGridColumnName(key: string) {
    var localizationData = (MultiLingualData.get(this.componentName) as any);
    switch (key) {
      case "Company":
        return this.lastDataIsShowByMonths ? localizationData.HseReportListComponent_Months_ex_ex : localizationData.HseReportListComponent_Company_ex_ex;
      case "Company":
        return localizationData.HseReportListComponent_Company_ex_ex;
      case "Total":
        return localizationData.HseReportListComponent_Total_ex_ex;
      default:
        return ""
    }
  }

  public downloadDocument(data: any, fileName: string, fileType: string) {
    var binaryString = window.atob(data.result.result);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var link = document.createElement('a');
    var blob = new Blob([bytes], { type: fileType });
    link.href = window.URL.createObjectURL(blob);
    link.download = data.result.fileName;
    document.body.appendChild(link);
    link.click();
  }
}

class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
  Description: string;
}
