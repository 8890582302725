"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubjectSubscription = void 0;
var SubjectSubscription = (function () {
    function SubjectSubscription(subject, subscriber) {
        this.subject = subject;
        this.subscriber = subscriber;
        this.closed = false;
    }
    SubjectSubscription.prototype.unsubscribe = function () {
        if (this.closed) {
            return;
        }
        this.closed = true;
        var subject = this.subject;
        var observers = subject.observers;
        this.subject = null;
        if (!observers || observers.length === 0) {
            return;
        }
        var subscriberIndex = observers.indexOf(this.subscriber);
        if (subscriberIndex !== -1) {
            observers.splice(subscriberIndex, 1);
        }
    };
    return SubjectSubscription;
}());
exports.SubjectSubscription = SubjectSubscription;
