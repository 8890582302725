import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDesignTypeLookUpBase {

//#region designTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'designTypeId', keyColumn: true})
        designTypeId : number;
//#endregion designTypeId Prop


//#region designTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'designTypeName', keyColumn: false})
        designTypeName : string;
//#endregion designTypeName Prop

}