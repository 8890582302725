import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Language } from '@app/models';
import { CultureInfoViewModel } from '../../../../view-model/culture-info-view-model';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent, } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { SystemHelpBase } from "src/app/models/database-models/system-help-base";
import { Router } from "@angular/router";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
// import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
@http({
  path: "api/SystemHelp",
})
export class AbstractSystemHelp extends CoreComponent {

  systemHelpFormGroup: IFormGroup<SystemHelpBase>
  showComponent: boolean = false;
  spin: boolean = false;
  modalView: ModalView;
  // router:Router;
  toastr: BaseToastr;
  dialog: BaseDialog;
  languageCultureLookup: any = {
    culturesLookUp: CultureInfoViewModel,
    language: Language
  }
  hasAddAccess: boolean;
  hasEditAccess: boolean;
  SystemHelpSearchPage: number = SystemHelpPage.Search;
  SystemHelpAddPage: number = SystemHelpPage.Add;
  SystemHelpEditPage: number = SystemHelpPage.Edit;

  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }

  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }
}
