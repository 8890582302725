import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMusicPreferenceLookUpBase {

//#region musicPreferenceId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'musicPreferenceId', keyColumn: true})
        musicPreferenceId : number;
//#endregion musicPreferenceId Prop


//#region musicPreferenceName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'musicPreferenceName', keyColumn: false})
        musicPreferenceName : string;
//#endregion musicPreferenceName Prop

}