import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subject, Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { CoreComponent } from '@rxweb/angular-router';
import { ExceptionLogsViewModel } from 'src/app/view-model/exception-logs-view-model';
import { ExceptionLogsFilterViewModel } from 'src/app/view-model/exception-log-filter-view-model';
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";
import { dbAccessModule } from "src/app/domain/security/authorization-resolver";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { BaseDialog, DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { debounceTime } from 'rxjs/operators';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { dateCompareValidation, getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
@multilingual("ExceptionLogListComponent")

@Component({
  selector: "app-exception-log-list",
  templateUrl: './exception-log-list.component.html'
})
export class ExceptionLogListComponent extends CoreComponent implements OnInit, OnDestroy {
  applicationExceptionLogs: ExceptionLogsViewModel[];
  subscription: any;
  exceptionLogListGrid: AppGrid;
  id: number = 0;
  exceptionLogsSearchFormGroup: IFormGroup<ExceptionLogsFilterViewModel>;
  exceptionLogsFilterViewModel: ExceptionLogsFilterViewModel;
  rowCount: number = 25;
  pageIndex: number = 1;
  isFilter: boolean = false;
  sortOrder: string = "false";
  orderByColumn: string = "ExceptionLogId";
  rebuildApplicationExceptionLogIds: number[] = [];
  showComponent: boolean = false;
  spin: boolean = false;
  totalRecords: number = 0;
  isNoRecordFound: boolean;
  isShowGrid: boolean;
  showSearchBar: boolean = false;
  gridSearchString: string = "";
  logDelete: any = dbAccessModule[RolePermissionEnums.Log]["delete"];
  dialog: BaseDialog;
  toastr: BaseToastr;
  titleSearch: string;
  dateError: boolean;
  private exceptionLogsSubject: Subject<string> = new Subject<string>();
  constructor(private router: Router, modelView: ModalView, private formBuilder: RxFormBuilder, private location : PlatformLocation) {
    super();
    this.dialog = new BaseDialog();
    this.toastr = new BaseToastr();
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.exceptionLogsSearchFormGroup = this.formBuilder.formGroup(ExceptionLogsFilterViewModel, this.exceptionLogsFilterViewModel) as IFormGroup<ExceptionLogsFilterViewModel>
    this.showComponent = true;
    this.isFilter = false;
    this.exceptionLogsSubject.pipe(debounceTime(300)).subscribe(t => {
      this.filterSearch();
    })
  }

  onKeyPress() {
    this.exceptionLogsSubject.next();
  }

  clearGrid() {
    if (this.exceptionLogListGrid && this.isShowGrid) {
      this.exceptionLogListGrid.refresh("exception-logs")
    }
  }

  bindGrid(page: number = 0,onload :boolean=true) {
    this.titleSearch = getLocalizedValue("Title_Search");
    var json = JSON.parse(JSON.stringify(this.exceptionLogsSearchFormGroup.value));
    json["rowCount"] = this.rowCount;
    json["pageIndex"] = page == 0 ? this.pageIndex : page;
    json["sortOrder"] = this.sortOrder;
    json["orderByColumn"] = this.orderByColumn;
    json["searchString"] = this.gridSearchString.replace('&', '%26');
    this.spin = true;
    this.isShowGrid = true;
    this.subscription = this.post({ path: 'api/SearchUsers/GetExceptionLogs', body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
      this.spin = false;
      this.applicationExceptionLogs = t;
      if(onload ){
        this.showSearchBar=true;
      }
      else{
        this.showSearchBar=false;

      }
      debugger;
      if (this.exceptionLogListGrid != null) {
        this.isFilter = true;
      }
      if (this.applicationExceptionLogs.length > 0) {
        this.totalRecords = this.applicationExceptionLogs[0].totalCount;

        this.isNoRecordFound = false;
        this.showSearchBar = true;

        if (!this.isFilter) {
          this.exceptionLogListGrid = new AppGrid(this.applicationExceptionLogs, ExceptionLogsViewModel, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } }); // , onEdit: this.onEdit.bind(this)
          this.exceptionLogListGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.exceptionLogListGrid.gridColumns.forEach(x => {
            if (this.exceptionLogListGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "put" }) && this.exceptionLogListGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "delete" })) {
              if (x.name == "action") {
                x.visible = true;
              }
              if (x.name == "exceptionLogId") {
                x.visible = true;
              }
            }
            else {
              if (x.name == "action") {
                x.visible = false;
              }
              if (x.name == "editAction") {
                x.visible = this.exceptionLogListGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "put" });
              }
              if (x.name == "deleteAction") {
                x.visible = this.exceptionLogListGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "delete" });
              }
              if (x.name == "exceptionLogId") {
                x.visible = this.exceptionLogListGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "delete" });
              }
            }
          })
        }
        else {
          if (this.exceptionLogListGrid != null) {
            this.exceptionLogListGrid.storeProcedure.length = this.totalRecords;
            if (this.pageIndex == 1) {
              this.exceptionLogListGrid.storeProcedure.nextPage = 1;
            }
            setTimeout(() => {
              this.exceptionLogListGrid.updateSource([]);
              this.exceptionLogListGrid.updateSource(this.applicationExceptionLogs);
            }, 100);
          }
        }
        this.exceptionLogListGrid.maxPerPage = this.rowCount;
        this.exceptionLogListGrid.componentId = this.componentName;
        this.exceptionLogListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.exceptionLogListGrid.designClass.headerClass = "table-header-sticky".split(" ");

      } else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
        //this.showSearchBar = false;
        this.totalRecords = 0;
      }
    })
  }

  filterSearch() {
    this.exceptionLogsSearchFormGroup.submitted = true;
    if (this.exceptionLogsSearchFormGroup.valid) {
      if (dateCompareValidation(this.exceptionLogsSearchFormGroup.value.fromDate, this.exceptionLogsSearchFormGroup.value.toDate) == true) {
        this.dateError = true;
      }
      else {
        this.dateError = false;
        if (this.exceptionLogsSearchFormGroup.valid) {
          if (this.exceptionLogListGrid) {
            this.isFilter = true;
          }
          this.pageIndex = 1;
          this.bindGrid(this.pageIndex,false);
        }
      }
    }
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.sortOrder = y;
    this.orderByColumn = x;
    this.exceptionLogListGrid.storeProcedure.nextPage = z;
    this.bindGrid(this.pageIndex,true);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.pageIndex = x;
    this.rowCount = this.exceptionLogListGrid.maxPerPage;
    this.exceptionLogListGrid.storeProcedure.nextPage = x;
    this.bindGrid(x,true);
  }

  onCheckboxSelect(t, x, y, z) {
    if (x.target.checked) {
      if (!this.rebuildApplicationExceptionLogIds.includes(t.ExceptionLogId)) {
        this.rebuildApplicationExceptionLogIds.push(t.ExceptionLogId);
      }
    }
    else {
      if (this.rebuildApplicationExceptionLogIds.includes(t.ExceptionLogId)) {
        this.rebuildApplicationExceptionLogIds.splice(this.rebuildApplicationExceptionLogIds.indexOf(t.ExceptionLogId), 1);
      }
    }
  }

  onEdit(log: ExceptionLogsViewModel) {
    //this.router.navigate(["application-exception-logs", log.ExceptionLogId], { queryParams: { log: 'new-log' } });
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["application-exception-logs", log.ExceptionLogId], { queryParams: { log: 'new-log' } }));
    redirectOnCTRL(url, this.router, event);
  }

  onDelete(log: ExceptionLogsViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ path: "api/ApplicationExceptionLogs", body: null, params: [0], queryParams: { "query": log.ExceptionLogId.toString(), "paramFlag": 1 } }).subscribe(data => {
          this.spin = false;
          this.isFilter = true;
          this.bindGrid();
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
        })
      }
      this.dialog.hide()
    })
  }

  clearLog() {
    if (this.rebuildApplicationExceptionLogIds.length > 0) {
      this.dialog.show(this.DeleteConfirmationMessageClearLog(), DialogViewMode.okWithCancel).then(t => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({ path: "api/ApplicationExceptionLogs", body: null, params: [0], queryParams: { "query": this.rebuildApplicationExceptionLogIds.toString(), "paramFlag": 1 } }).subscribe(data => {
            this.spin = false;
            this.isFilter = true;
            this.bindGrid();
            var existsAlert = getLocalizedValue("LogsClear_Success");
            if (existsAlert) {
              //this.toastr.success("Logs Clear Successfully");
              this.toastr.success(existsAlert);
            }
            this.rebuildApplicationExceptionLogIds = [];
          })
        }
        this.dialog.hide()
      })
    }
  }

  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Exception_Log");
    if (existsAlert) {
      return existsAlert;
    }
  }
  DeleteConfirmationMessageClearLog() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Exception_Logs");
    if (existsAlert) {
      return existsAlert;
    }
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.totalRecords = 0;
      this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '').replace('&', '%26');
      this.pageIndex = 1;
      this.bindGrid(this.pageIndex,true);
    }, 0);
    this.spin = true;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ExceptionLogListComponent";
  }
}
