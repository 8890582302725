import { NoteBase, NoteHastagMappingBase, NoteMemberMappingBase, TaskDetailBase } from '@app/database-models'
import { required, prop, propArray } from '@rxweb/reactive-form-validators';
import { NoteMemberMapping } from '@app/models';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
//Generated Imports
export class Note extends NoteBase implements FileDetailViewModel {


  constructor() {
    super();
    this.isDisplayTeamMember = false;
    this.isReplay = false;
  }

  @prop()
  file: File[];
  @prop()
  fileData1: string;
  @prop()
  fileExtention1: string;
  @prop()
  fileName1: string;
  @prop()
  fileData2: string;
  @prop()
  fileExtention2: string;
  @prop()
  fileName2: string;
  @prop()
  fileData: string;
  @prop()
  fileExtention: string;
  @prop()
  fileName: string;
  @prop()
  fileData3: string;
  @prop()
  fileExtention3: string;
  @prop()
  fileName3: string;
  @prop()
  fileData4: string;
  @prop()
  fileExtention4: string;
  @prop()
  fileName4: string;
  @prop()
  displayImage: boolean;
  @prop()
  imageURL1: string;
  @prop()
  imageURL2: string;
  @prop()
  imageURL3: string;
  @prop()
  imageURL4: string;
  @prop()
  imageURL5: string;
  @prop()
  isReplay: boolean = false;

  //@required()
  @prop({ defaultValue: '' })
  toEmails: string;

  @prop()
  ccEmails: string;

  @prop()
  hasTags: string;

  @prop({ defaultValue: false })
  isAllUser: boolean;

  @propArray(NoteMemberMappingBase)
  noteMemberMappings: NoteMemberMappingBase[];


  //#region Generated Reference Properties

  //#region noteHastagMappings Prop
  @propArray(NoteHastagMappingBase)
  noteHastagMappings: NoteHastagMappingBase[];
  //#endregion noteHastagMappings Prop

  //#endregion Generated Reference Properties

  @propArray(TaskDetailBase)
  taskDetailMappings: TaskDetailBase[];

  @prop()
  blobFileName: string;

  @prop()
  taskCount: number;

  @prop()
  from:string;









}
