import {vPictureTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPictureTypeLookUp extends vPictureTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}