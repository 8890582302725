import {DirectorshipMappingBase,} from '@app/database-models'
//Generated Imports
export class DirectorshipMapping extends DirectorshipMappingBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}