import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRingEggSizeBase {

//#region sizeId Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'RingEggSizeListComponent_Id_gh', keyColumn: true})
        sizeId : number;
//#endregion sizeId Prop

//#region sizeValue Prop        
        @gridColumn({style: { width: "17%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'RingEggSizeListComponent_Value_gh', keyColumn: false})
        sizeValue : number;
//#endregion sizeValue Prop

        @gridColumn({style: { width: "15%","text-align": "initial" },class: ["text-left"],name:'inches',visible: true, columnIndex:2, allowSorting: true, headerKey: 'RingEggSizeListComponent_Inches_gh', keyColumn: false})
        inches : string;

        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],name:'mm',visible: true, columnIndex:3, allowSorting: true, headerKey: 'RingEggSizeListComponent_MM_gh', keyColumn: false})
        mm : string;

        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],name:'ukSize',visible: true, columnIndex:4, allowSorting: true, headerKey: 'RingEggSizeListComponent_UKSize_gh', keyColumn: false})
        ukSize : string;

        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],name:'usSize',visible: true, columnIndex:5, allowSorting: true, headerKey: 'RingEggSizeListComponent_USSize_gh', keyColumn: false})
        usSize : string;

        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],name:'published',visible: true, columnIndex:6, allowSorting: true, headerKey: 'RingEggSizeListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName:"published" } })
        published : boolean;

//#region typeName Prop
        @gridColumn({isAscending:true,style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:7, allowSorting: true, headerKey: 'RingEggSizeListComponent_Name_gh', keyColumn: false})
        typeName : string;
//#endregion typeName Prop

//#region sizeType Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },visible: false, columnIndex:8, allowSorting: true, headerKey: 'RingEggSizeListComponent_Type_gh', keyColumn: false})
        sizeType : number;
//#endregion sizeType Prop

}