import { prop, email,trim  } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class  UserKnownToFilterViewModel{

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "fullName";
    }
    @prop()
    userId: Number;

    @prop()
    ragistrationFromDate : Date;  
      
    @prop()
    ragistrationToDate : Date;

    @prop()
    @trim()
    name : String; 

    @prop()
    //@email()
    @trim()
    email : String;

    @prop()
    @trim()
    nameText : String; 

    @prop()
    //@email()
    @trim()
    emailText : String;

    @prop()
    salesAdvisor: Number;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;
    
    @prop()
    @trim()
    orderBy: string;
  
}