import { RxHttp,http } from "@rxweb/http";
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { Router } from '@angular/router';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ImportGemstoneFromExcelViewModel } from 'src/app/view-model/import-gemstone-from-excel-view-model';
import { ImportUserFromExcelViewModel } from 'src/app/view-model/import-user-from-excel-view-model';

export class AbstractImportUserFromExcel extends RxHttp {
    spin: boolean = false;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    router: Router;
    //id: number = 0;
    fileViewModelExcel: FileInputViewModel;
    fileOutputViewModelExcel: FileOutputViewModel = new FileOutputViewModel();
    ImportUserFromExcelFormGroup: IFormGroup<ImportUserFromExcelViewModel>;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.fileViewModelExcel = new FileInputViewModel();
        this.fileViewModelExcel.fileMode = FileTypeEnums.Exl;
        this.fileViewModelExcel.fileAllowSize = FileSizeEnums.MaxMB;
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }

    // updateExcelFiles($event: FileOutputViewModel) {
    //     this.fileOutputViewModelExcel = $event;
    //     this.ImportGemstoneFromExcelFormGroup.patchValue({
    //         fileData: $event.fileBaseString,
    //         fileExtention: $event.fileExtension,
    //         fileName: $event.fileName,
    //     });
    // }

}
