<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0">Alert </h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="ml-4 modal-body pb-0">
      <h4 *ngIf="headerMessage != ''">{{headerMessage}}</h4>
      <ul class="ml-3 list-unstyled">
        <li type="square" *ngFor="let item of validationMessagesList">{{item}}</li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="hide()" id="closeButton">Close</button>
    </div>
  </div>
</div>
