import {vOrderStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vOrderStatusLookUp extends vOrderStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}