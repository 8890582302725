import {SpecificationAttributeBase,SpecificationMasterBase,ProductSpecificationAttributeBase,} from '@app/database-models'
//Generated Imports
export class SpecificationAttribute extends SpecificationAttributeBase 
{




//#region Generated Reference Properties
//#region specificationMaster Prop
specificationMaster : SpecificationMasterBase;
//#endregion specificationMaster Prop
//#region productSpecificationAttributes Prop
productSpecificationAttributes : ProductSpecificationAttributeBase[];
//#endregion productSpecificationAttributes Prop

//#endregion Generated Reference Properties




















}