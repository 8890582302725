import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemstoneStatusBase {

//#region gemstoneStatusId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:0, allowSorting: true, headerKey: 'GemstoneStatusListComponent_Id_gh', keyColumn: true})
        gemstoneStatusId : number;
//#endregion gemstoneStatusId Prop


//#region gemstoneStatusName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'GemstoneStatusListComponent_Name_gh', keyColumn: false})
        gemstoneStatusName : string;
//#endregion gemstoneStatusName Prop

}