import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { User, UserRole, vRole, vRoleLookUp, vTitleLookUp, vNationalitieLookUp } from '@app/models';
import { AbstractUser } from '../domain/abstract-user';
import { UserDetailBase, UserRoleBase } from '@app/database-models';
import { StatusEnum } from '../../../../enums/status.enum';
import { CustomerRoleTypeEnum } from '../../../../enums/customer-role-type.enum';
import { Routes, ActivatedRoute, Router } from "@angular/router";
import { ModalView } from '../../../../domain/customize-design/modal';
import { List } from '@rxweb/generics';
import { BrowserModule, Title } from '@angular/platform-browser';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { SubscriptionBase } from 'src/app/models/database-models/subscription-base';
import { SUBSCRIPTION } from 'src/app/collections/subscription.collection'
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RoleTypesEnums } from '../../../../custom-enum/role-type-enum';
import { multilingual } from '@rxweb/localization';
import { $ } from 'protractor';
import { CustomerRegistrationTypeEnum } from '../../../../enums/customer-registration-type.enum';
import { SystemRatingEnum } from '../../../../enums/system-rating.enum';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { ApplicationBroadcaster } from "src/app/temp-service/application-broadcaster";
import { FormControl } from '@angular/forms';
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { rolesEnum } from "src/app/enums/roles-enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { showToolTip } from "src/app/domain/customize-plugin/bind-slider";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { getBlobImgURL } from "src/app/domain/system-constant";

@access({ accessLevel: RolePermissionEnums.User, action: "post" })
@multilingual("UserAddComponent")
@Component({
  selector: "app-user-add",
  templateUrl: './user-add.component.html'
})
export class UserAddComponent extends AbstractUser implements OnInit, OnDestroy {

  subscription: any;
  isBasicInfo: boolean = true;
  isContactInfo: boolean = false;
  isUserRegistered: boolean = false;
  userRole: Array<UserRole> = new Array<UserRole>();
  showCustomerControl: boolean;
  show: boolean = false;
  role: UserRole;
  userroles: UserRole[];
  action: any;
  RoleEnum: boolean;
  checkFormControl: FormControl = new FormControl();
  loggedInUserRole: string;
  browserStorage: BrowserStorage;
  showRoleType: boolean = false;
  showPassword: boolean = false;
  loggedInUserRoleType: number;
  titleHelp: string;
  moduleID: any;
  showSystemHelpIcon: boolean = false;
  imageTooltip: string;
  personaNonGrataMean: string;

  get componentName(): string {
    return "UserAddComponent";
  }



  _this;

  constructor(modalView: ModalView, private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster,
    private activatedRoute: ActivatedRoute, router: Router, private sanitizer: DomSanitizer, private title: Title, blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.router = router;
    this.modalView = modalView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'people', subMenu: 'users' })
    this.activatedRoute.queryParams.subscribe(t => {
      if (t['action']) {
        this.action = t['action'];
      }
    })
    this.browserStorage = new BrowserStorage();
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
    if (this.browserStorage.local.get("roleId") != null) {
      this.loggedInUserRoleType = this.browserStorage.local.get("roleId");
    }
    this.moduleID = RolePermissionEnums.User;
    this._this = this;
  }

  ngOnInit(): void {
    this.personaNonGrataMean =  getLocalizedValue("UserAddEditComponent_PersonaNonGrataMean_t");
    this.imageTooltip = getLocalizedValue("UserAddEditComponent_ImageUploadTooltip_t");
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("People - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.showCustomerControl = true;
    this.titleLookUp = new Array<vTitleLookUp>();
    this.nationalityLookUp = new Array<vNationalitieLookUp>();
    setTimeout(() => {      
      showToolTip("user_IsUnwelcome_helpToolTip",this.personaNonGrataMean);
    }, 2500);
    this.lookup([{ path: USER_LOOKUPS.nationalitylookUp, propName: "nationalityLookUp" },
    { path: USER_LOOKUPS.customerRegistrationTypeLookUp, propName: "customerRegistrationTypeLookUp" },
    { path: USER_LOOKUPS.salesUserLookup, propName: "salesAdvisorLookUp" },
    { path: USER_LOOKUPS.roleLookUp, propName: "roleLookUp" },
    { path: USER_LOOKUPS.customerRoleTypeLookUp, propName: "customerRoleTypeLookUp" },
    { path: USER_LOOKUPS.titleLookUp, propName: "titleLookUp" },
    { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
    { path: USER_LOOKUPS.stateProvinceLookUp, propName: "stateProvinceLookUp" },
    { path: USER_LOOKUPS.customerContactMethodTypeLookUp, propName: "customerContactMethodTypeLookUp" },
    { path: USER_LOOKUPS.departmentLookUp, propName: "departmentLookUp" },
    { path: USER_LOOKUPS.musicPreferenceLookUp, propName: "musicPreferenceLookUp" },
    { path: USER_LOOKUPS.keyRelationshipHolderLookUp, propName: "keyRelationshipHolderLookUp" },
    { path: USER_LOOKUPS.systemRatingLookUp, propName: "systemRatingLookUp" },
    { path: USER_LOOKUPS.languageUserLookUps, propName: "languageLookUp" },
    { path: USER_LOOKUPS.spokenLanguageLookUp, propName: "spokenLanguageLookUp" },
    { path: USER_LOOKUPS.internalDepartment, propName: "internalDepartment" },
    { path: USER_LOOKUPS.externalDepartment, propName: "externalDepartment" },
    { path: USER_LOOKUPS.pressMediaTypesToSendEmail, propName: "pressMediaTypesToSendEmail" },
    { path: USER_LOOKUPS.channelIECLookUps, propName: "channelIECLookUps" },  //#287839//11-06-2024//new LookUp for Channel DropDown added 
    ]).subscribe((response: any) => {
      this.userLookUps = response;
      this.titleLookUp = response.titleLookUp;
      this.nationalityLookUp = response.nationalityLookUp;
      this.userLookUps.customerRoleTypeLookUp = this.userLookUps.customerRoleTypeLookUp.filter(x => x.customerRoleId != CustomerRoleTypeEnum.Supplier);
      this.userRoles = new List(this.userLookUps.roleLookUp);
      this.user = new User();
      this.user.subscriptions = new Array<SubscriptionBase>();
      this.user.userId = 0;
      this.user.statusId = StatusEnum.Active;
      this.user.displayImage = false;
      this.user.registrationDate = new Date();
      //this.user.roleTypeId = CustomerRoleTypeEnum.Customer;
      this.user.registrationTypeID = CustomerRegistrationTypeEnum.Standard;
      this.userDetailBase = new UserDetailBase();
      this.userDetailBase.userId = 0;
      this.userDetailBase.userDetailId = 0;
      this.userDetailBase.stateProvinceId = 0;
      this.userDetailBase.pressMediaEmailsType = '';


      //this.userDetailBase.rating = SystemRatingEnum.UnknownForwardSlashuncategorised;

      // let titleObj = new vTitleLookUp();
      // titleObj.titleId = 0;
      // titleObj.titleName = "Select";

      // this.titleLookUp.unshift(titleObj);
      this.userDetailBase.title = 0;

      // let nationnalityObj = new vNationalitieLookUp();
      // nationnalityObj.nationalityId = 0;
      // nationnalityObj.nationalityName = "Select";
      // this.nationalityLookUp.unshift(nationnalityObj);
      this.userDetailBase.nationality = 0;
      this.user.userDetails = new Array<UserDetailBase>(this.userDetailBase);
      this.user.subscriptions = this.bindSubscription();

      this.user.userRoles = this.bindUserRole(CustomerRoleTypeEnum.Customer);
      this.user.adminRoles = this.bindUserRole(CustomerRoleTypeEnum.Admin);
      if (this.action == 'faberge-team' || this.action == 'gemfield-team') {
        this.user.roleTypeId = CustomerRoleTypeEnum.Admin;
        this.showAdminRole = true;
        this.showCustomerRole = false;
        this.showCustomerControl = false;
      }
      else {
        this.user.roleTypeId = CustomerRoleTypeEnum.Customer;
        this.showAdminRole = false;
        this.showCustomerRole = true;
        this.showCustomerControl = true;
      }
      this.showPassword = +this.loggedInUserRoleType === CustomerRoleTypeEnum.Admin ? true : false;
      if (this.user.roleTypeId === CustomerRoleTypeEnum.Admin) {
        if (this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRCompanySpecific.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRGroupwide.toString())) {
          this.showPassword = true;
        } else {
          this.showPassword = false;
        }
      }
      if (this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRCompanySpecific.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRGroupwide.toString())) {
        this.showRoleType = true;
      };
      // if ((this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRCompanySpecific.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRGroupwide.toString())) ) {
      //   this.showEmailNotificationCheckbox = true;
      // }
      this.userFormGroup = this.formBuilder.formGroup(User, this.user) as IFormGroup<User>;
      if (this.user.roleTypeId == CustomerRoleTypeEnum.Customer) {
        this.userFormGroup.controls.password.setValidators([RxwebValidators.pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&,.=_])[A-Za-z\d@$!%*#?&,.=_]{8,49}$/ }, message: 'Must be at least 8 characters and less than 50<br/> Must contain at least one lower case letter, one upper case letter, one digit and one special character.<br/> Allowed special characters are @$!%*#?&,.=_' })]);
        this.userFormGroup.controls.password.updateValueAndValidity();
        this.userFormGroup.controls.email.setValidators([RxwebValidators.email({ message: "Please enter valid email address" }), RxwebValidators.maxLength({ value: 255, message: "More than 255 characters are not permitted" })]);
        this.userFormGroup.controls.email.updateValueAndValidity();
      } else {
        this.userFormGroup.controls.password.setValidators([
          //RxwebValidators.required({ message: "You can't leave this field empty" }),
          //RxwebValidators.notEmpty({ message: "You can't leave this field empty" }),
          RxwebValidators.pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&,.=_])[A-Za-z\d@$!%*#?&,.=_]{8,49}$/ }, message: 'Must be at least 8 characters and less than 50<br/> Must contain at least one lower case letter, one upper case letter, one digit and one special character.<br/> Allowed special characters are @$!%*#?&,.=_' })]);
        this.userFormGroup.controls.password.updateValueAndValidity();
      }
      // this.userFormGroup.controls.password.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
      // RxwebValidators.notEmpty({ message: "You can't leave this field empty" }),
      // RxwebValidators.pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&,.=_])[A-Za-z\d@$!%*#?&,.=_]{8,49}$/ }, message: 'Must be at least 8 characters and less than 50<br/> Must contain at least one lower case letter, one upper case letter, one digit and one special character.<br/> Allowed special characters are @$!%*#?&,.=_' })]);
      this.showComponent = true;
    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  getImgContent(): SafeUrl {
    let imgUrl = "data:" + this.userFormGroup.controls.fileExtention.value + ";base64," + this.userFormGroup.controls.fileData.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
  }

  getTabIndex(a: number, b: number): number {
    return a + b;
  }

  changeRoleType(event) {
    this.roleTypeId = +event.item.key;
    this.userFormGroup.patchValue({
      isAdmin: this.roleTypeId == CustomerRoleTypeEnum.Customer ? 0 : 1
    })
    debugger;
    if (this.roleTypeId == CustomerRoleTypeEnum.Customer) {
      this.showCustomerRole = true;
      this.showAdminRole = false;
      this.showExternalDepartment = true;
      this.showInternalDepartment = false;
      this.showCustomerControl = true;
      this.showEmailNotificationCheckbox=false;
    }
    else {
      this.showCustomerRole = false;
      this.showAdminRole = true;
      this.showInternalDepartment = true;
      this.showExternalDepartment = false;
      this.showCustomerControl = false;
      this.showEmailNotificationCheckbox=true;
    }
    if (this.roleTypeId == CustomerRoleTypeEnum.Customer) {
      this.userFormGroup.controls.password.setValidators([RxwebValidators.pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&,.=_])[A-Za-z\d@$!%*#?&,.=_]{8,49}$/ }, message: 'Must be at least 8 characters and less than 50<br/> Must contain at least one lower case letter, one upper case letter, one digit and one special character.<br/> Allowed special characters are @$!%*#?&,.=_' })]);
      this.userFormGroup.controls.password.updateValueAndValidity();
      this.userFormGroup.controls.email.setValidators([RxwebValidators.email({ message: "Please enter valid email address" }), RxwebValidators.maxLength({ value: 255, message: "More than 255 characters are not permitted" })]);
      this.userFormGroup.controls.email.updateValueAndValidity();
    } else {
      this.userFormGroup.controls.password.setValidators([
        //RxwebValidators.required({ message: "You can't leave this field empty" }),
        //RxwebValidators.notEmpty({ message: "You can't leave this field empty" }),
        RxwebValidators.pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&,.=_])[A-Za-z\d@$!%*#?&,.=_]{8,49}$/ }, message: 'Must be at least 8 characters and less than 50<br/> Must contain at least one lower case letter, one upper case letter, one digit and one special character.<br/> Allowed special characters are @$!%*#?&,.=_' })]);
      this.userFormGroup.controls.password.updateValueAndValidity();
      this.userFormGroup.controls.email.setValidators([
        //RxwebValidators.required({ message: "You can't leave this field empty" }),
        //RxwebValidators.notEmpty({ message: "You can't leave this field empty" }),
        RxwebValidators.email({ message: "Please enter valid email address" }),
        RxwebValidators.maxLength({ value: 255 })
      ]);
      this.userFormGroup.controls.email.updateValueAndValidity();
    }
  }

  clickRadio(event: any) {
    setTimeout(() => {
      if (!event.target.checked) {
        (event.target.parentElement as HTMLElement).style.position = "relative";
        (event.target.parentElement as HTMLElement).style.border = "2px solid #74c874";
        (event.target.parentElement as HTMLElement).style.borderRadius = "2px";
      }

      else {
        (event.target.parentElement as HTMLElement).style.border = "1px solid black";
      }
    }, 1);
  }

  changeStatus(event: any, i: number) {
    this.adminRoleFormArray[i].patchValue({
      active: !this.adminRoleFormArray[i].value.active
    });
    for (let j = 0; j < this.adminRoleFormArray.length; j++) {
      if (j != i) {
        this.adminRoleFormArray[j].patchValue({
          active: false
        });
      }
    }
    this.userRoleValidation = false;
  }

  bindUserRole(id: number): UserRole[] {

    let roleLookUps = this.userLookUps.roleLookUp as Array<vRoleLookUp>;
    let userRoleArray = new Array<UserRole>()

    if (id == CustomerRoleTypeEnum.Admin) {
      this.RoleEnum = true;
    }
    else if (id == CustomerRoleTypeEnum.Customer) {
      this.RoleEnum = false;
    }

    if (this.user.userId == 0) {
      roleLookUps = roleLookUps.filter(x => x.roleType == id);
    }

    if (id == CustomerRoleTypeEnum.Customer) {

      let roleData = roleLookUps.find(x => x.roleId == 67);

      roleLookUps.forEach((role: vRoleLookUp) => {
        if (role.roleId != 67) {
          let userRole = new UserRole();
          userRole.userRoleId = 0;
          userRole.userId = 0;
          userRole.active = false;
          userRole.roleId = role.roleId;
          userRoleArray.push(userRole)
        }
      })
      let userRole = new UserRole();
      userRole.userRoleId = 0;
      userRole.userId = 0;
      userRole.active = false;
      userRole.roleId = roleData.roleId;
      userRoleArray.push(userRole)
    }
    else {
      roleLookUps.forEach((role: vRoleLookUp) => {
        let userRole = new UserRole();
        userRole.userRoleId = 0;
        userRole.userId = 0;
        userRole.active = false;
        userRole.roleId = role.roleId;
        userRoleArray.push(userRole)
      })
    }

      const columns = 3; 
      const rows = Math.ceil(userRoleArray.length / columns);

      // Create a 2D array to store the rearranged elements
      const rearrangedArray = Array.from({ length: rows }, () => []);

      // Loop through the original array and rearrange the elements
      userRoleArray.forEach((element, index) => {   
        let row = index % rows;
        rearrangedArray[row].push(element); 
      });

      // make Array with Same Size 
      const rearrangedArraySize = [];
    const maxSize = Math.max(...rearrangedArray.map(arr => arr.length));


        let userRole = new UserRole();
        userRole.userRoleId = 0;
        userRole.userId = 0;
        userRole.active = false;
        userRole.roleId = 2147483647;


      for (const subArray of rearrangedArray) {
        const missingElements = maxSize - subArray.length;
        const newArray = [...subArray, ...Array(missingElements).fill(userRole)];
        rearrangedArraySize.push(newArray);
      }
      
      const result = [].concat(...rearrangedArraySize.map(test => test));
     
      return result;


    //return userRoleArray;

  }

  RoleSelectionCheck() {
    var isShowValidation = true;
    let data = this.showCustomerRole == true ? this.userRoleFormArray : this.adminRoleFormArray;
    for (let i = 0; i < data.length; i++) {
      if (data[i].value.active == true) {
        isShowValidation = false;
        break;
      }
    }
    this.userRoleValidation = isShowValidation;
  }
}
