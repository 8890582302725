import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMeasureDimensionBase {

//#region measureDimensionId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'MeasureDimensionListComponent_DimensionId_gh', keyColumn: true})
        measureDimensionId : number;
//#endregion measureDimensionId Prop


//#region measureDimensionName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'MeasureDimensionListComponent_DimensionName_gh', keyColumn: false})
        measureDimensionName : string;
//#endregion measureDimensionName Prop


//#region systemKeyword Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:2, allowSorting: true, headerKey: 'MeasureDimensionListComponent_SystemKeyword_gh', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region ratio Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'MeasureDimensionListComponent_Ratio_gh', keyColumn: false})
        ratio : number;
//#endregion ratio Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'MeasureDimensionListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}