import { maxLength, numeric, prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class ProjectsFilterViewModel {

    constructor() {
        this.department = 0;
        this.projectOwner = 0;
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "false";
        this.orderBy = "month";
    }


    @prop()
    projectStartDate: Date;
    @prop()
    projectEndDate: Date;
    @prop()
    projectName: String;
    @prop()
    projectNameId: number;
    @prop()
    projectOwner: Number;
    @prop()
    persons: String;
    @prop()
    corporates: number;
    @prop()
    gemfieldsGroupCompanyProjectOwner: number;
    @prop()
    gemfieldsGroupCompanyProjectOwnerText: string;
    @prop()
    department: Number;
    @prop()
    countryId: Number;
    @prop()
    eventId: number;
    @prop({ defaultValue: 1 })
    projectOrEvent: number;

    @prop()
    @trim()
    personText: string;

    @prop()
    @trim()
    corporateText: string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    projectType: number;

    @prop()
    searchString: string;

    @prop({ defaultValue: 'false' })
    isSearch: string;

    @prop()
    userId: number;

    @prop()
    @numeric()
    @maxLength({ value: 9 }) // #295644 / 29 - 07 - 2024 / Update Search by projectId
    projectId: number;

    @prop()
    specificRiskId: string;
}
