<div class="modal fade show" id="tax-rate" tabindex="-1" role="dialog" style="display: block;" *rxMultilingual="">
  <div class="modal-dialog modal-md">
    <div class="modal-content" [formGroup]="taxRateFormGroup" *ngIf="showComponent" [rxLocalisationInit]="componentName">
      <div class="modal-header text-left" (keyup.enter)="addConfiguration(false)">
        <h4 class="modal-title mb-0" *ngIf="taxRateId == 0" [rxLocalisationInit]="componentName"><label rxText="TaxRateAddComponent_Title_Add"></label></h4>
        <h4 class="modal-title mb-0" *ngIf="taxRateId > 0" [rxLocalisationInit]="componentName"><label rxText="TaxRateAddComponent_Title_Edit"></label></h4>
        <button type="button" class="close py-2" data-dismiss="modal" (click)="hide()" [title]="titleClose">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="modal-body pb-0" [rxSpinner]="spin">

        <div class="form-group row" *ngIf="taxRateId > 0" [rxLocalisationInit]="componentName">
          <label class="col-md-4 col-form-label"
            rxText="TaxRateAddEditComponent_TaxRateId_t"></label>
          <div class="col-md-8">
            <!-- <select rxFocus class="form-control" id="countryId" formControlName="countryId" (change)="selectState()">
                <option [selected]="showSelectOption" value="0">Select</option>
                <option *ngFor="let item of taxRateLookUps.countryLookUp" [value]="item.countryId">{{item.countryName}}</option>
              </select> -->
            <label type="text" class="form-control disableLabel-withoutheight mb-0">{{(taxRateFormGroup.value.taxRateId
              === null || taxRateFormGroup.value.taxRateId === undefined ) ? '' :
              taxRateFormGroup.value.taxRateId}}</label>

          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            rxText="TaxRateAddEditComponent_TaxRateCountry_t"></label>
          <div class="col-md-8" [rxSelectExtended]="_this">
            <!-- <select rxFocus class="form-control" id="countryId" formControlName="countryId" (change)="selectState()">
                <option [selected]="showSelectOption" value="0">Select</option>
                <option *ngFor="let item of taxRateLookUps.countryLookUp" [value]="item.countryId">{{item.countryName}}</option>
              </select> -->
            <rx-select #rxSelect [(source)]="taxRateLookUps.countryLookUp" formControlName="countryId" mainClass="form-control"
              (changed)="selectState()" [keyValueProps]="['countryName','countryId']"
              [selectPlaceholder]="'Select Country'"></rx-select>
            <small class="form-text text-danger"
              [class.d-block]="taxRateFormGroup.controls.countryId.errors">{{taxRateFormGroup.controls.countryId.errorMessage}}<br /></small>

          </div>
        </div>
        <div class="form-group row" *ngIf="showSelectOption" [rxLocalisationInit]="componentName">
          <label class="col-md-4 col-form-label"
            rxText="TaxRateAddEditComponent_TaxRateStateProvince_t"></label>
          <div class="col-md-8" [rxSelectExtended]="_this">
            <!-- <select class="form-control" formControlName="stateProvinceId">
                 <option [selected]="showSelectOption" value="0">All</option>
                <option *ngFor="let item of stateProvinceLookUp" [value]="item.stateProvinceId">{{item.stateProvinceName}}</option>
              </select> -->
            <rx-select #rxSelect [(source)]="stateProvinceLookUp" formControlName="stateProvinceId" mainClass="form-control"
              [keyValueProps]="['stateProvinceName','stateProvinceId']" [selectPlaceholder]="'All'"></rx-select>
            <!-- <small class="form-text text-danger" [class.d-block]="taxRateFormGroup.controls.stateProvinceId.errors">{{taxRateFormGroup.controls.stateProvinceId.errorMessage}}<br/></small> -->

          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            rxText="TaxRateAddEditComponent_TaxRateZip_t"></label>
          <div class="col-md-8">
            <input type="text" class="form-control " formControlName="zip"
              rxPlaceholder="TaxRateAddEditComponent_TaxRateZip_p" />
            <small class="form-text text-danger"
              [class.d-block]="taxRateFormGroup.controls.zip.errors">{{taxRateFormGroup.controls.zip.errorMessage}}<br /></small>

          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            rxText="TaxRateAddEditComponent_TaxRateTaxCategory_t"></label>
          <div class="col-md-8" [rxSelectExtended]="_this">
            <!-- <select class="form-control" formControlName="taxCategoryId">
               <option value="0">Select</option>
                <option *ngFor="let item of taxRateLookUps.taxCategoryLookUp" [value]="item.taxCategoryId">{{item.taxCategoryName}}</option>
              </select> -->

            <rx-select #rxSelect [(source)]="taxRateLookUps.taxCategoryLookUp" formControlName="taxCategoryId"
              mainClass="form-control" [keyValueProps]="['taxCategoryName','taxCategoryId']"
              [selectPlaceholder]="'Select Tax Category'"></rx-select>
            <small class="form-text text-danger"
              [class.d-block]="taxRateFormGroup.controls.taxCategoryId.errors">{{taxRateFormGroup.controls.taxCategoryId.errorMessage}}<br /></small>

          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"
            rxText="TaxRateAddEditComponent_TaxRatePercentage_t"></label>
          <div class="col-md-8">
            <input type="text" class="form-control " formControlName="percentage"
              rxPlaceholder="TaxRateAddEditComponent_TaxRatePercentage_p" />
            <small class="form-text text-danger"
              [class.d-block]="taxRateFormGroup.controls.percentage.errors">{{taxRateFormGroup.controls.percentage.errorMessage}}<br /></small>

          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="addConfiguration(false)" rxText="Btn_Save_t"></button>
        <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
      </div>
    </div>
  </div>
</div>
