import { prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class SubProjectsDesignFilterViewModel {

  constructor() {
    this.pageIndex = PaginationEnum.PageIndex
    this.rowCount = PaginationEnum.RowCount;
    this.sortOrder = "true";
    this.orderBy = "design";
  }
  @prop()
  name: String;
  @prop()
  startDate: Date;
  @prop()
  endDate: Date;
  @prop()
  rowCount: number;

  @prop()
  pageIndex: number;

  @prop()
  @trim()
  sortOrder: string;

  @prop()
  @trim()
  orderBy: string;
}
