import { prop,propObject,propArray,required,maxLength,range, notEmpty, numeric  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductWebPictureBase {

//#region productPictureID Prop
        @prop()
        productWebPictureID : number;
//#endregion productPictureID Prop


@prop()
entityId:number;

@prop()
entityTypeId:number;
//#region pictureIndex Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
       // @required()
       @prop()
        pictureIndex : number;
//#endregion pictureIndex Prop

//#region pictureType Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
       // @required()
       @prop()
        pictureType : number;
//#endregion pictureType Prop

//#region pictureID Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
       // @required()
       @prop()
        pictureID : number;
//#endregion pictureID Prop

        @prop()
        pictureName : string;
        @prop()
        createdBy : number;
        @prop()
        createdDate : Date;
        @prop()
        modifiedBy : number;
        @prop()
        modifiedDate : Date;
        @prop()
        statusId : number;

//#region displayOrder Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @numeric({allowDecimal:false})
        @notEmpty()
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}
