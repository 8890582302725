import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Discount, vDiscountTypeLookUp } from '@app/models';
import { CoreComponent,  } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DiscountLookupGroup } from '../../../../view-model/discount-lookup-group';
import { DiscountRequirementEnum } from 'src/app/enums/discount-requirement.enum';
import { DiscountlimitationEnum } from 'src/app/enums/discountlimitation.enum';
@http({
    path: "api/Discounts",
})
export class AbstractDiscount extends CoreComponent {

    discountFormGroup: IFormGroup<Discount>
    discount: Discount;
    discountTypeLookUp: vDiscountTypeLookUp[];    
    showComponent: boolean = false;
    showRequiredShippingCountry:boolean=false;
    showRequiredBillingCountry: boolean = false;
    showRestrictedDesignProducts: boolean = false;
    showCouponcode:boolean = false;
    showNtimes:boolean = false;
    showDiscountPercentage : boolean = false;
    showDiscountAmount : boolean = true;
    spin: boolean = false;
    toastr: BaseToastr
    dialog: BaseDialog;
    modalView: ModalView;
    dateError:boolean;
    discountLookupGroup:DiscountLookupGroup;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
     requiresCouponCode($event){
        if($event.target.checked)
        {
            this.showCouponcode = true;
            this.discountFormGroup.patchValue({
                couponCode: ""
            });
            this.discountFormGroup.controls.couponCode.setValidators([RxwebValidators.required({message:"You can't leave this field empty"})])
        }
        else
        {
            //this.discountFormGroup.controls.couponCode.clearValidators()
            this.showCouponcode = false;
            this.discountFormGroup.patchValue({
                couponCode: " "
            });
        }
    }
    discountLimitation(event)
    {
        this.discountLimitationChange(event.item.key)
    }
    discountLimitationChange(id : number)
    {
        if(+id == DiscountlimitationEnum.NTimesOnly || +id == DiscountlimitationEnum.NTimesPerCustomer)
        {
            this.showNtimes = true;
        }
        else
        {
            this.showNtimes = false;    
            this.discountFormGroup.patchValue({
                limitationTimes: 1
            });
        }
    }
    userPercentage($event){
        if($event.target.checked)
        {
            // this.discountFormGroup.controls.discountPercentage.setValidators([RxwebValidators.range({minimumNumber:0,maximumNumber:100,message:"Enter a Value less then 100"})]);
            this.discountFormGroup.controls.discountPercentage.setValidators([
                RxwebValidators.required({message:"You can't leave this field empty"}),
                RxwebValidators.pattern({expression:{'discountPercentage':/^(?:100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/}, message:'Please Enter a Correct Value'}),
                RxwebValidators.numeric({allowDecimal:true, message:'Only numbers are allowed'})
                 ]);
            this.showDiscountPercentage = true;
            this.discountFormGroup.controls.discountAmount.clearValidators();
            this.showDiscountAmount = false;
            this.discountFormGroup.patchValue({
                discountAmount: 0
            });
        }
        else
        {
            this.discountFormGroup.controls.discountPercentage.clearValidators();
            this.discountFormGroup.controls.discountAmount.setValidators([
                RxwebValidators.required({message:"You can't leave this field empty"}),
                RxwebValidators.pattern({expression:{'discountAmount':/^(?:[0-9][0-9]{0,8}(?:\.\d{1,2})?|2147483641|2147483641.00)$/}, message:'Please Enter a Correct Value'}),
                RxwebValidators.numeric({allowDecimal:true, message:'Only numbers are allowed'})
            ]);
            this.discountFormGroup.patchValue({
                discountPercentage: 0   
            });
            this.showDiscountPercentage = false;
            this.showDiscountAmount = true;
           
        }
    }
    discountRequirementChange(event) {
        this.change(event.item.key);
    }
    change(id: number) {
        if (+id == DiscountRequirementEnum.HasalloftheseDesignProductsintheTray || 
            +id == DiscountRequirementEnum.HasoneoftheseDesignProductsintheTray || 
            +id == DiscountRequirementEnum.HaspurchasedalloftheseDesignProducts ||
            +id == DiscountRequirementEnum.HaspurchasedoneoftheseDesignProducts) {
                this.showRestrictedDesignProducts = true;
                this.showRequiredBillingCountry = false;
                this.showRequiredShippingCountry = false;
        }
        else if (+id == DiscountRequirementEnum.Billingcountryis) {
          this.showRequiredBillingCountry = true;
          this.showRestrictedDesignProducts = false;
          this.showRequiredShippingCountry = false;
        }
        else if (+id == DiscountRequirementEnum.Shippingcountryis) {
           this.showRequiredShippingCountry = true;
           this.showRestrictedDesignProducts = false;
           this.showRequiredBillingCountry = false;
        }
        else
        {
            this.showRequiredShippingCountry = false;
            this.showRestrictedDesignProducts = false;
            this.showRequiredBillingCountry = false;
        }
    }
}
