import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { PaymentMethod } from '@app/models';
import { AbstractPaymentMethod } from '../domain/abstract-payment-method';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser'; 
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { PaymentMethodEditComponent } from '../edit/payment-method-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("PaymentMethodAddComponent")
@access({ accessLevel: RolePermissionEnums.PaymentMethods, action: "post" })

@Component({
    selector: "app-payment-method-add",
    templateUrl: './payment-method-add.component.html'
})
export class PaymentMethodAddComponent extends AbstractPaymentMethod implements OnInit, OnDestroy {
    paymentMethod: PaymentMethod;
    subscription: any;
    paymentMethodEdit:any=PaymentMethodEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder,private router:Router, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.PaymentMethods;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Payment Methods - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.paymentMethod = new PaymentMethod();
        this.paymentMethod.paymentMethodId = 0;
        this.paymentMethod.statusId = StatusEnum.Active;
        this.paymentMethodFormGroup = this.formBuilder.formGroup(this.paymentMethod) as IFormGroup<PaymentMethod>;
        this.showComponent = true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addPaymentMethod(isRedirect: boolean) {
        this.paymentMethodFormGroup.submitted = true;
        if (this.paymentMethodFormGroup.valid) { 
            this.spin=true;
            this.post({ body: this.paymentMethodFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/payment-methods', data]) : this.router.navigate(['/payment-methods']);
            })
        } else {
            for (const key of Object.keys(this.paymentMethodFormGroup.controls)) {
                if (this.paymentMethodFormGroup.controls[key].invalid) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    invalidControl.focus();
                    break;
                }
            }
        }
    }
    get componentName(): string {
        return "PaymentMethodAddComponent";
    }
}
