import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneGFEnhancementTypeMappingBase {

//#region gemstoneEnhancementTypeId Prop
        @prop()
        gemstoneEnhancementTypeId : number;
//#endregion gemstoneEnhancementTypeId Prop


//#region gemStoneId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemStoneId : number;
//#endregion gemStoneId Prop


//#region enhancementTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        enhancementTypeId : number;
//#endregion enhancementTypeId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}