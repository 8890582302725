import {vBoutiqueSupervisorLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBoutiqueSupervisorLookUp extends vBoutiqueSupervisorLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}