import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPricebandOptionBase {

//#region pricebandOptionId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'PricebandOptionListComponent_Id_gh', keyColumn: true})
        pricebandOptionId : number;
//#endregion pricebandOptionId Prop


//#region pricebandName Prop
        @gridColumn({ isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'PricebandOptionListComponent_Name_gh', keyColumn: false})
        pricebandName : string;
//#endregion pricebandName Prop


//#region pricebandFrom Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'PricebandOptionListComponent_From_gh', keyColumn: false})
        pricebandFrom : any;
//#endregion pricebandFrom Prop


//#region pricebandTo Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'PricebandOptionListComponent_To_gh', keyColumn: false})
        pricebandTo : any;
//#endregion pricebandTo Prop

}