import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerContactMethodTypeLookUpBase {

//#region customerContactMethodTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerContactMethodTypeId', keyColumn: true})
        customerContactMethodTypeId : number;
//#endregion customerContactMethodTypeId Prop


//#region customerContactMethodTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'customerContactMethodTypeName', keyColumn: false})
        customerContactMethodTypeName : string;
//#endregion customerContactMethodTypeName Prop

}