import {vSpokenLanguageLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSpokenLanguageLookUp extends vSpokenLanguageLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}