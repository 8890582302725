import {testtablemodelBase,} from '@app/database-models'
//Generated Imports
export class testtablemodel 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}