import {EnhancementTypeGemFieldBase,} from '@app/database-models'
//Generated Imports
export class EnhancementTypeGemField extends EnhancementTypeGemFieldBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}