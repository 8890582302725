import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEnhancementGemfieldsLookUpBase {

//#region enhancementId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'enhancementId', keyColumn: true})
        enhancementId : number;
//#endregion enhancementId Prop


//#region enhancementName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'enhancementName', keyColumn: false})
        enhancementName : string;
//#endregion enhancementName Prop

}