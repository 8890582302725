import {PricebandOptionBase,} from '@app/database-models'
//Generated Imports
export class PricebandOption extends PricebandOptionBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}