import { maxLength, prop, range, required } from '@rxweb/reactive-form-validators';

export class ProjectRateMappingBase {

    //#region projectRateMappingId Prop
    @prop()
    projectRateMappingId: number;
    //#endregion projectRateMappingId Prop

    //#region projectId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    projectId: number;
    //#endregion projectId Prop

    //#region projectRatingId Prop
    // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    // @required()
    @prop()
    projectRatingId: number;
    //#endregion projectRatingId Prop

    //#region feedBack Prop
    @maxLength({ value: 4000 })
    feedBack: string;
    //#endregion feedBack Prop

    //#region createdBy Prop
    @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdDate Prop
    @required()
    createdDate: Date;
    //#endregion createdDate Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region modifiedDate Prop
    @prop()
    modifiedDate: Date;
    //#endregion modifiedDate Prop
}