import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBoutiqueLookUpBase {

//#region boutiqueId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'boutiqueId', keyColumn: true})
        boutiqueId : number;
//#endregion boutiqueId Prop


//#region boutiqueName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'boutiqueName', keyColumn: false})
        boutiqueName : string;
//#endregion boutiqueName Prop

}