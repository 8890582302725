<div [rxLocalisationInit]="componentName">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
      <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/faberge-localization']" rxText="Label_Faberge_Localisation_t"></a>
          </li>
          <li class="breadcrumb-item active" rxText="FabergeLocalizationEditComponent_Title"></li>
        </ol>
      </div>
      <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
      </div>
    </div>
  
    <div class="wrapper wrapper-content flex-grow-1">
  
      <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
          <h5><label rxText="FabergeLocalizationEditComponent_Title"></label></h5>
          <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
            <i class="fa fa-question-circle fa-2x"></i>
          </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1"  [rxSpinner]="spin">
         <div  [formGroup]="localizationFormGroup"  *ngIf="showComponent" [rxLocalisationInit]="componentName" (keyup.enter)="editLocalization(false)">
            <div class="row add-countries-form">
              <!-- <div class="col-md-4 pr-xl-5">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label" >ID</label>
                  <div class="col-md-8">
                    <label type="text" 
                                class="form-control disableLabel-withoutheight mb-0">{{(localizationFormGroup.value.languageId
                                === null || localizationFormGroup.value.languageId === undefined ) ? '' :
                                localizationFormGroup.value.languageId}}</label>
                  </div>
                </div>
              </div> -->

                <div class="col-md-4 pr-xl-5">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label"  rxText="FabergeLocalizationEditComponent_Language_t"></label>
                      <div class="col-md-8">
                        <label class="col-form-label" >{{localizationFormGroup.value.languageName}}</label>
                      </div>
                    </div>
                  </div>


                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"  rxText="FabergeLocalizationEditComponent_Name_t"> </label>
                    <div class="col-md-8">
                      <input type="text" formControlName="localizationKey" class="form-control "  rxPlaceholder="FabergeLocalizationEditComponent_Name_p" rxFocus/>
                      <small class="form-text text-danger" [class.d-block]="localizationFormGroup.controls.localizationKey.errors">{{localizationFormGroup.controls.localizationKey.errorMessage}}</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"  rxText="FabergeLocalizationEditComponent_Value_t"> </label>
                    <div class="col-md-8">
                      <textarea formControlName="localizationValue" rows="5" class="form-control "  rxPlaceholder="FabergeLocalizationEditComponent_Value_p" ></textarea>
                      <small class="form-text text-danger" [class.d-block]="localizationFormGroup.controls.localizationValue.errors">{{localizationFormGroup.controls.localizationValue.errorMessage}}</small>
                    </div>
                  </div>
                </div>
      
               
              </div>

              <!-- <div class="row add-countries-form" >
                <div class="col-md-4 pr-xl-5">
                    <div class="form-group row">
                      <label class="col-md-4 col-form-label"  rxText="FabergeLocalizationEditComponent_Value_t"> </label>
                      <div class="col-md-8">
                        <textarea formControlName="localizationValue" rows="5" class="form-control "  rxPlaceholder="FabergeLocalizationEditComponent_Value_p" ></textarea>
                        <small class="form-text text-danger" [class.d-block]="localizationFormGroup.controls.localizationValue.errors">{{localizationFormGroup.controls.localizationValue.errorMessage}}</small>
                      </div>
                    </div>
                  </div>
        
              </div> -->
              <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100"></div>
                <div class="col text-right">
                  <button (click)="editLocalization(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                  <button (click)="editLocalization(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                  <ng-container *ngIf="localizationDelete" [rxLocalisationInit]="componentName">
                    <button (click)="deleteLocalization()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
                  </ng-container>
                </div>
              </div>
         </div>
           
         
        </div>
      </div>
  
    </div>
  
  </div>
  