import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerActionBase {

//#region customerActionId Prop
        @prop()
        customerActionId : number;
//#endregion customerActionId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region systemKeyword Prop
        @required()
        @maxLength({value:100})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region comment Prop
        @required()
        @maxLength({value:1000})
        comment : string;
//#endregion comment Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region published Prop
         @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region typeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        typeId : number;
//#endregion typeId Prop





}