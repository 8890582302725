import {GrievanceTypeBase,} from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"

export class GrievanceType extends GrievanceTypeBase 
{

 

//#region Generated Reference Properties

//#endregion Generated Reference Properties






}