import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerRelationshipRecordBase {

//#region customerRelationShipId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerRelationShipId', keyColumn: true})
        customerRelationShipId : number;
//#endregion customerRelationShipId Prop


//#region customerRelationShipStatus Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'customerRelationShipStatus', keyColumn: false})
        customerRelationShipStatus : string;
//#endregion customerRelationShipStatus Prop

}