import { Component, OnInit } from '@angular/core';
import { AbstractFabergeLocalization } from '../domain/abstract-faberge-localization';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationContent } from 'src/app/models/extended-models/localization-content';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Title } from '@angular/platform-browser';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { FRONTSTORESERVER_URL } from 'src/app/domain/system-constant';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
@multilingual("FabergeLocalizationEditComponent")
@access({ accessLevel: RolePermissionEnums.FabergeLocalization, action: "put" })
@Component({
  selector: 'app-faberge-localization-edit',
  templateUrl: './faberge-localization-edit.component.html',
  styleUrls: ['./faberge-localization-edit.component.css']
})
export class FabergeLocalizationEditComponent extends AbstractFabergeLocalization implements OnInit {


  langaugeId:number;
  keyName:string;
  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
  localizationDelete: any = dbAccessModule[RolePermissionEnums.FabergeLocalization]["delete"];
  titleHelp: string;
  moduleID: number;
  showSystemHelpIcon: boolean = false;
  constructor(private activatedRoute:ActivatedRoute,private formBuilder:RxFormBuilder,private title:Title,private router:Router,modelView:ModalView, private location : PlatformLocation,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView=modelView;
    this.activatedRoute.queryParams.subscribe(t=>{
      this.keyName=t['key'];
    })
    this.activatedRoute.params.subscribe(t=>{
      this.langaugeId=t['id'];
    })
    this.moduleID = RolePermissionEnums.FabergeLocalization;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit() {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
    this.title.setTitle("Faberge Localisation - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.getLocalizationData();
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
  }

  getLocalizationData(){
    this.showComponent=false;
    this.spin=true;
    this.get({  hostUri: FRONTSTORESERVER_URL,  params:[this.langaugeId],queryParams:{"key":this.keyName}}).subscribe((t:LocalizationContent)=>{
        this.spin=false;
        if(t==null || t==undefined){
          this.router.navigate(['/faberge-localization']);
        }
        else{
          this.localizationModel=t;
          this.localizationFormGroup=this.formBuilder.formGroup(LocalizationContent,this.localizationModel) as IFormGroup<LocalizationContent>;
          this.showComponent=true;
        }

    })
  }
  editLocalization(isRedirect:boolean){
    this.localizationFormGroup.submitted=true;
    if(this.localizationFormGroup.valid){
      this.spin=true;
      this.put({  hostUri: FRONTSTORESERVER_URL , params:[this.langaugeId],body:this.localizationFormGroup.value}).subscribe(t=>{
        this.spin=false;
        if(staticMetaLocalization.indexOf(this.keyName) != -1) {
          this.refreshAppPoolClearCache();
        }

        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
        if (existsAlert) {
          //this.toastr.success("Data Updated Successfully")
            this.toastr.success(existsAlert[0].showMessage);
        }
        if(isRedirect){
          this.keyName=this.localizationFormGroup.value.localizationKey;
            this.getLocalizationData();
            this.router.navigate(['/faberge-localization', this.langaugeId], { queryParams: { "key":this.keyName } });
        }
        else{
          this.router.navigate(['/faberge-localization']);
        }
      })
    }

  }
  deleteLocalization() {
    let localizationObj=new LocalizationContent();
    this.localizationFormGroup.patchValue({
      isDelete:true
    })
    localizationObj.isDelete=true;
      this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
          if(t === DialogEnum.Ok)
          {
              this.dialog.hide();
              this.spin=true;
              this.put({  hostUri: FRONTSTORESERVER_URL, params:[1],body:this.localizationFormGroup.value }).subscribe(data => {
                  this.spin=false;
                  var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					            //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                  this.router.navigate(['/faberge-localization']);
              })
          }
          this.dialog.hide()
      })
  }
  DeleteConfirmationMessage() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_F_Localization");
    if (existsAlert) {
        return existsAlert[0].showMessage;
    }
  }
  get componentName(): string {
    return "FabergeLocalizationEditComponent";
  }

  refreshAppPoolClearCache() {
    var browserStorage = new BrowserStorage();
    this.post({
      hostUri: FRONTSTORESERVER_URL, path: 'api/SearchCommon/AppPoolRecycle',
      queryParams: { gtracToken: browserStorage.local.get("auth", false).toString() }, body: []
    }).subscribe(t => {
      localStorage.removeItem('isMenuUpdate');
      var existsAlert = getLocalizedValue("App_Pool");
      if (existsAlert) {
        this.toastr.success(existsAlert);
      }
    });
  }

}

var staticMetaLocalization = [
"ContactUs.MetaKeywords",
"contact_meta_title",
"ContactUs.MetaDescription",
"news_metakeywords",
"news_meta_title",
"news_metadescription",
"creations_metakeywords",
"creations_metatitle",
"creations_metadescription",
"timepieces_metakeywords",
"timepieces_metatitle",
"timepieces_metadescription",
"jewellery_metakeywords",
"jewellery_metatitle",
"jewellery_metadescription",
"Boutique.Metakeywords",
"Boutique.MetaTitle",
"Boutique.Metadescription",
"theworldoffaberge_metatitle",
"theworldoffaberge_metakeywords",
"Homepage_metakeywords",
"Homepage_title",
"Homepage_metadescription"
]
