import {StateProvinceLocalizationBase,} from '@app/database-models'
//Generated Imports
export class StateProvinceLocalization extends StateProvinceLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}