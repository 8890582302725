import {vEnhancementTypeGemfieldsRecordBase,} from '@app/database-models'
//Generated Imports
export class vEnhancementTypeGemfieldsRecord extends vEnhancementTypeGemfieldsRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}