import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBespokeReportSalesPersonLookUpBase {

//#region SalesPersonId Prop
        //@gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'collectionId', keyColumn: true})
        SalesPersonId : number;
//#endregion SalesPersonId Prop


//#region SalesPersonName Prop
        //@gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        SalesPersonName : string;
//#endregion SalesPersonName Prop

}