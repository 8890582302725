<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
      <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/settings']" rxText="Label_All_Settings_t"></a>
          </li>
          <li class="breadcrumb-item active">Add New</li>
        </ol>
      </div>
      <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
      </div>
    </div>
  
    <div class="wrapper wrapper-content flex-grow-1">
  
      <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
          <h5><label rxText="Label_All_Settings_t"></label></h5>
          <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
            <i class="fa fa-question-circle fa-2x"></i>
          </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="settingFormGroup"  [rxSpinner]="spin">
          <div class="row add-countries-form">
            <div class="col-lg-6 col-xl-4 pr-xl-5">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"  rxText="SettingAddEditComponent_SettingName_t"></label>
                <div class="col-md-8">
                  <textarea class="form-control" formControlName="name" rows="4"  rxPlaceholder="SettingAddEditComponent_SettingName_p"></textarea>
                  <!-- <input type="text" formControlName="name" class="form-control"  rxPlaceholder="SettingAddEditComponent_SettingName_p" rxFocus /> -->
                  <small class="form-text text-danger" [class.d-block]="settingFormGroup.controls.name.errors">{{settingFormGroup.controls.name.errorMessage}}</small>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 px-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"  rxText="SettingAddEditComponent_SettingValues_t"></label>
                <div class="col-md-8">
                  <textarea class="form-control" formControlName="value" rows="4"  rxPlaceholder="SettingAddEditComponent_SettingValues_p"></textarea>
                  <small class="form-text text-danger" [class.d-block]="settingFormGroup.controls.value.errors">{{settingFormGroup.controls.value.errorMessage}}</small>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 pl-xl-5">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"  rxText="SettingAddEditComponent_SettingDescription_t"> </label>
                <div class="col-md-8">
                  <textarea class="form-control" formControlName="description" rows="4"  rxPlaceholder="SettingAddEditComponent_SettingDescription_p"></textarea>
                  <small class="form-text text-danger" [class.d-block]="settingFormGroup.controls.description.errors">{{settingFormGroup.controls.description.errorMessage}}</small>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mt-auto">
            <div class="hr-line-dashed border-top w-100"></div>
            <div class="col text-right">
              <button (click)="addSetting(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
              <button (click)="addSetting(true)" class="btn btn-primary mr-1" *rxAuthorize="[settingEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  
  </div>
