import {LocationGemFieldBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class LocationGemField extends LocationGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop

//#endregion Generated Reference Properties






































}