import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerRoleNoteTypeMappingBase {

//#region notetypeRoleId Prop
        @prop()
        notetypeRoleId : number;
//#endregion notetypeRoleId Prop


//#region typeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        typeId : number;
//#endregion typeId Prop


//#region customerRoleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerRoleId : number;
//#endregion customerRoleId Prop


//#region allow Prop
        @prop({defaultValue:false})
        allow : boolean;
//#endregion allow Prop

}