import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPieceDeliveryStatusBase {

//#region pieceDeliveryStatusId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'PieceDeliveryStatusListComponent_Id_gh', keyColumn: true})
        pieceDeliveryStatusId : number;
//#endregion pieceDeliveryStatusId Prop


//#region pieceDeliveryStatusName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'PieceDeliveryStatusListComponent_Name_gh', keyColumn: false})
        pieceDeliveryStatusName : string;
//#endregion pieceDeliveryStatusName Prop

}