import { ProductBase, ProductDesignBase, ProductSketchBase, ProductPictureBase, RelatedProductBase, BoutiqueProductMappingBase, ProductDownloadPictureBase, ProductBatchBase, CarnetsProductBase, ProductAttributeMappingBase, BookingProductBase, ProductVariantLocalizationBase, ProductGemstoneMappingBase, ProductGemstoneCutMappingBase, ProductOccassionMappingBase, ProductOrderBase, ShoppingCartItemBase, WatchAttributeMappingBase, ProductProductTypeMappingBase, ProductVariantMaterialMappingBase, ProductVariantColorMappingBase } from '@app/database-models'
import { ProductViewModel } from 'src/app/view-model/product-view-model';
import { propArray, prop, required, range } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { ProductAttributeMapping } from '.';

//Generated Imports
export class Product extends ProductBase implements FileDetailViewModel {

    @prop()
    occassionIds: string;
    @prop()
    gemstoneTypeIds: string;
    @prop()
    gemstoneCutIds: string;
    @prop()
    productTypeIds: string;
    @prop()
    materialIds: string;
    @prop()
    colorIds: string;
    @prop({ defaultValue: 0 })
    secondaryCategoryId: number;
    @prop({ defaultValue: 7 })
    @required()
    @range({ minimumNumber: 7, maximumNumber: 2313213214 })
    languageId: number;
    @prop()
    parentCategory: string;
    @prop()
    designName: string;
    @prop({ defaultValue: false })
    isWatch: boolean;
    @prop()
    productBriefImage: string;
    @prop()
    isCustomerPrice: boolean;
    @prop()
    storeUrl: string;

    @prop()
    parentCategoryId :number;
    //#region Generated Reference Properties
    //#region productDesign Prop
    productDesign: ProductDesignBase;
    //#endregion productDesign Prop
    //#region productSketches Prop
    productSketches: ProductSketchBase[];
    //#endregion productSketches Prop
    //#region productPictures Prop
    productPictures: ProductPictureBase[];
    //#endregion productPictures Prop
    //#region relatedProducts Prop
    relatedProducts: RelatedProductBase[];
    //#endregion relatedProducts Prop
    //#region boutiqueProductMappings Prop
    boutiqueProductMappings: BoutiqueProductMappingBase[];
    //#endregion boutiqueProductMappings Prop
    //#region productDownloadPictures Prop
    productDownloadPictures: ProductDownloadPictureBase[];
    //#endregion productDownloadPictures Prop
    //#region productBatches Prop
    productBatches: ProductBatchBase[];
    //#endregion productBatches Prop
    //#region carnetsProducts Prop
    carnetsProducts: CarnetsProductBase[];
    //#endregion carnetsProducts Prop
    //#region productAttributeMappings Prop
    // productAttributeMappings : ProductAttributeMappingBase[];
    //#endregion productAttributeMappings Prop
    //#region bookingProducts Prop
    bookingProducts: BookingProductBase[];
    //#endregion bookingProducts Prop
    //#region productVariantLocalizations Prop
    // productVariantLocalizations : ProductVariantLocalizationBase[];
    //#endregion productVariantLocalizations Prop
    //#region productGemstoneMappings Prop
    @propArray(ProductGemstoneMappingBase)
    productGemstoneMappings: ProductGemstoneMappingBase[];
    //#endregion productGemstoneMappings Prop
    //#region productGemstoneCutMappings Prop
    @propArray(ProductGemstoneCutMappingBase)
    productGemstoneCutMappings: ProductGemstoneCutMappingBase[];
    //#endregion productGemstoneCutMappings Prop
    //#region productProductTypeMappings Prop
    @propArray(ProductProductTypeMappingBase)
    productProductTypeMappings: ProductProductTypeMappingBase[];
    //#endregion productProductTypeMappings Prop
    //#region productOccassionMappings Prop
    @propArray(ProductOccassionMappingBase)
    productOccassionMappings: ProductOccassionMappingBase[];
    //#endregion productOccassionMappings Prop
    //#region ProductVariantMaterialMappingBase Prop
    @propArray(ProductVariantMaterialMappingBase)
    productVariantMaterialMappings: ProductVariantMaterialMappingBase[];
    //#endregion ProductVariantMaterialMappingBase Prop
    //#region ProductVariantColorMappingBase Prop
    @propArray(ProductVariantColorMappingBase)
    productVariantColorMappings: ProductVariantColorMappingBase[];
    //#endregion ProductVariantColorMappingBase Prop
    //#region productOrders Prop
    productOrders: ProductOrderBase[];
    //#endregion productOrders Prop
    //#region shoppingCartItems Prop
    shoppingCartItems: ShoppingCartItemBase[];
    //#endregion shoppingCartItems Prop
    //#region watchAttributeMappings Prop
    watchAttributeMappings: WatchAttributeMappingBase[];
    //#endregion watchAttributeMappings Prop
    //#region productAttributeMappings Prop
    //@propArray(ProductAttributeMapping)
    //productAttributeMappings:ProductAttributeMappingBase[];
    //#endregion productAttributeMappings Prop

    //#endregion Generated Reference Properties
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;

    @prop({ defaultValue: false })
    isImageRemoved: boolean;

    @propArray(ProductViewModel)
    productsInput: ProductViewModel[];
    @propArray(ProductViewModel)
    designsInput: ProductViewModel[];
    @prop({ defaultValue: false })
    isDelete: boolean;

    @prop({ defaultValue: false })
    buyButton: boolean;



    @prop({ defaultValue: false })
    isVisibleShowSalesTeam: boolean;

    @prop({ defaultValue: false })
    isShowImageURL5:boolean;

    @prop({ defaultValue: false })
    isShowImageURL3:boolean;
    @prop({ defaultValue: false })
    isShowImageURL1:boolean;
    @prop({ defaultValue: false })
    isShowImageURL4:boolean;
    @prop({ defaultValue: false })
    removeFromAdsXML:boolean;

    disableRingSize: boolean;
    disableBangleSize: boolean;

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;
    @prop()
    rawShopifyId:string;
}
