import {
  ProductPieceBase,
  ProductBatchBase,
  ProductPieceBigPictureBase,
  ProductPiecePictureBase,
  BookingProductBase,
  ProductBatchPieceRepairBase,
  ProductPieceGemstoneMappingBase,
  ProductPieceOrderBase,
  CarnetsProductBase,
  ProductPieceGemstoneBase,
} from "@app/database-models";
import { PiecesViewModel } from "src/app/view-model/pieces-view-model";
import {
  prop,
  maxLength,
  range,
  required,
  propObject,
} from "@rxweb/reactive-form-validators";
import { FileDetailViewModel } from "src/app/view-model/file-detail-view-model";
import { ProductPieceGemstoneMapping } from "./product-piece-gemstone-mapping";
import { WholeSaleReportViewModel } from "./wholesale-report-view-model";
import { ProductPieceStatusMappingBase } from "../database-models/product-piece-status-mapping-base";
//Generated Imports
export class ProductPiece
  extends ProductPieceBase
  implements FileDetailViewModel
{
  //#region Generated Reference Properties
  //#region productBatch Prop
  productBatch: ProductBatchBase;
  //#endregion productBatch Prop
  //#region productPieceBigPictures Prop
  productPieceBigPictures: ProductPieceBigPictureBase[];
  //#endregion productPieceBigPictures Prop
  //#region productPiecePictures Prop
  productPiecePictures: ProductPiecePictureBase[];
  //#endregion productPiecePictures Prop
  //#region bookingProducts Prop
  bookingProducts: BookingProductBase[];
  //#endregion bookingProducts Prop
  //#region productBatchPieceRepairs Prop
  productBatchPieceRepairs: ProductBatchPieceRepairBase[];
  //#endregion productBatchPieceRepairs Prop
  //#region productPieceGemstoneMappings Prop
  productPieceGemstoneMappings: ProductPieceGemstoneMappingBase[];
  //#endregion productPieceGemstoneMappings Prop
  //#region productPieceOrders Prop
  productPieceOrders: ProductPieceOrderBase[];
  //#endregion productPieceOrders Prop
  //#region carnetsProducts Prop
  carnetsProducts: CarnetsProductBase[];
  //#endregion carnetsProducts Prop

  //#endregion Generated Reference Properties

  selectedPiece: PiecesViewModel;

  @prop({ defaultValue: 7 })
  @required()
  @range({ minimumNumber: 7, maximumNumber: 2313213214 })
  languageId: number;

  @prop()
  supplierId: number;

  @prop()
  dutyDate: string;

  @prop()
  pieceOwnerName: string;
  @prop()
  showRepairTab: boolean;

  @prop()
  @maxLength({ value: 50 })
  supplierOrderNo: string;

  @prop()
  @maxLength({ value: 50 })
  fabergeOrderNo: string;
  @prop()
  selectedPieceOwner: string;
  @prop()
  productPrice: any;
  @prop()
  soldToOwner: string;
  @prop()
  selectedSoldTo: string;
  @prop()
  shipperId: number;
  @prop()
  specificationImage: string;
  @prop()
  isShowPublishLabel: boolean;
  @prop()
  isProductPublished: boolean;
  @prop()
  disableSoldToOwner: boolean;
  @prop()
  productName: string;
  @prop()
  orderNo: number;
  @prop()
  disableCurrentOwner: boolean;
  @prop()
  disableStatus: boolean;
  @prop()
  showRepair: boolean;
  @prop()
  isWatch: boolean;
  @prop()
  bespokeNo: number;
  @prop()
  disableRingSize: boolean;
  @prop()
  disableBangleSize: boolean;
  @prop()
  isRRP: boolean;
  @prop()
  productId: number;
  @prop()
  designId: number;

  @prop()
  soldToOwnerName: string;
  @prop()
  currentOwnerName: string;
  @prop()
  isUser: boolean;
  @prop()
  userId: number;
  @prop()
  stockCardImageUrl: string;

  @prop()
  pieceDescription: string;
  @prop()
  fileData: string;
  @prop()
  fileExtention: string;
  @prop()
  fileName: string;
  @prop()
  displayImage: boolean;
  @prop()
  imageURL1: string;
  @prop()
  imageURL2: string;
  @prop()
  imageURL3: string;
  @prop()
  imageURL4: string;
  @prop()
  imageURL5: string;
  @prop()
  fileData1: string;
  @prop()
  fileExtention1: string;
  @prop()
  fileName1: string;
  @prop()
  fileData2: string;
  @prop()
  fileExtention2: string;
  @prop()
  fileName2: string;

  @prop()
  fileURL1: string;
  @prop()
  fileURL2: string;
  @prop()
  productDescription: string;

  @prop()
  primaryCurrencyId: number;

  @prop()
  primaryExchangeRate: number;

  @propObject(ProductPieceStatusMappingBase)
  productPieceStatusMappings?: ProductPieceStatusMappingBase;

  bOMDetails: ProductPieceGemstoneMapping;

  wholeSaleUpdateDetails: WholeSaleReportViewModel[];

  consignmentStatus: string;
  salesStatus: string;
  productionTeamStatus: string;
  @prop()
  blobFileName: string;
  @prop()
  blobPath: string;
  @prop()
  size: number;
  @prop()
  blobFileName2: string;
  @prop()
  blobPath2: string;
  @prop()
  size2: number;
  @prop()
  blobFileName3: string;
  @prop()
  blobPath3: string;
  @prop()
  size3: number;

  @prop()
  gtinNumber: number;

  @prop()
  barcodeUrl: string;

  @prop()
  parentCategory: string;

  @prop()
  designName: string;

  categoryName: string;
  isRing: boolean;
  isBangle: boolean;
  pieceSize: string;
}
