import {BookingWatchPartBase,BookingBase,UserBase,} from '@app/database-models'
//Generated Imports
export class BookingWatchPart extends BookingWatchPartBase 
{


    watchPartIds: BookingWatchPart[];
    locationId: number;
    ownerId: number;
    approveOrRejet: number;

//#region Generated Reference Properties
//#region booking Prop
booking : BookingBase;
//#endregion booking Prop
//#region productPieceWatchPart Prop
productPieceWatchPart : any;
//#endregion productPieceWatchPart Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties











}