import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAddressLookupBase {

//#region userName Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userName', keyColumn: false})
        userName : string;
//#endregion userName Prop


//#region addressId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'addressId', keyColumn: true})
        addressId : number;
//#endregion addressId Prop


//#region userId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : any;
//#endregion userId Prop


//#region supplierId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'supplierId', keyColumn: false})
        supplierId : any;
//#endregion supplierId Prop


//#region isBillingAddress Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'isBillingAddress', keyColumn: false})
        isBillingAddress : boolean;
//#endregion isBillingAddress Prop


//#region phoneNumber Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'phoneNumber', keyColumn: false})
        phoneNumber : string;
//#endregion phoneNumber Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region faxNumber Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'faxNumber', keyColumn: false})
        faxNumber : string;
//#endregion faxNumber Prop


//#region company Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'company', keyColumn: false})
        company : string;
//#endregion company Prop


//#region address Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'address', keyColumn: false})
        address : string;
//#endregion address Prop


//#region zipPostalCode Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'zipPostalCode', keyColumn: false})
        zipPostalCode : string;
//#endregion zipPostalCode Prop


//#region stateProvinceName Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'stateProvinceName', keyColumn: false})
        stateProvinceName : string;
//#endregion stateProvinceName Prop

}