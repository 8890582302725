import { Component, OnInit, OnDestroy } from "@angular/core";
import { AbstractSystemInformation } from '../domain/abstract-system-information';
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { vSystemInformation } from 'src/app/models/extended-models/v-system-information';
import { DatePipe } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { ModalView } from "src/app/domain/customize-design/modal";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("SystemInformationListComponent")
@access({ accessLevel: RolePermissionEnums.SystemInformation, action: "get" })


@Component({
    selector: "app-system-information-list",
    templateUrl: './system-information-list.component.html'
})
export class SystemInformationListComponent extends AbstractSystemInformation implements OnInit, OnDestroy {
    systemInformation: vSystemInformation[];
    systemInformationGrid: AppGrid;
    subscription: any;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private datePipe: DatePipe, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title) {
        super();
        this.modalView = modalView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.SystemInformation;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("System Information - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vSystemInformation[]) => {
            this.spin = false;
            t.forEach(x => x.publishedDate = this.datePipe.transform(x.publishedDate, 'dd/MMM/yyyy'));
            t.forEach(x => x.createdDate = this.datePipe.transform(x.createdDate, 'dd/MMM/yyyy'));
            this.systemInformation = t;
            this.systemInformationGrid = new AppGrid(t, vSystemInformation);
            this.systemInformationGrid.componentId = this.componentName;
            this.systemInformationGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.systemInformationGrid.designClass.headerClass = "table-header-sticky".split(" ");            
            this.systemInformationGrid.design(document.getElementById("system-information"));
            this.showComponent = true;
        })
    }

    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.systemInformation.filter(x => x.projectName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.systemInformationId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.projectTypeName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.technology.toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.technology.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.deploymentVerison.toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.publishedDate.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.createdDate.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.systemInformationGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.systemInformationGrid = null;
        }
        setTimeout(() => {
            if (this.systemInformationGrid == null || this.systemInformationGrid == undefined) {
                this.systemInformationGrid = new AppGrid(this.systemInformation, vSystemInformation);
                this.systemInformationGrid.componentId = this.componentName;
                this.systemInformationGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.systemInformationGrid.designClass.headerClass = "table-header-sticky".split(" ");  
                this.systemInformationGrid.refresh("system-information");
                this.systemInformationGrid.design(document.getElementById("system-information"));
            }
            this.systemInformationGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "SystemInformationListComponent";
    }

}
