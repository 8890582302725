import {vCurrencyRecordBase,} from '@app/database-models'
//Generated Imports
export class vCurrencyRecord extends vCurrencyRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}