import { Component, Input, OnInit } from '@angular/core';
import { CoreComponent } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { UserHolidayBulkAllocationViewModel } from 'src/app/view-model/user-holiday-bulk-allocation-view-model';
import * as CryptoJS from 'crypto-js';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { PlatformLocation } from '@angular/common';

@multilingual('CustomerHolidayBulkAddComponent')
@Component({
    selector: 'app-customer-holiday-bulk-add',
    templateUrl: './customer-holiday-bulk-add.component.html',
})
export class CustomerHolidayBulkAddComponent extends CoreComponent implements OnInit {
    titleClose: string;
    @Input() hide: Function;
    vYearLookUp: any[] = new Array();
    userHolidayBulkAllocationFormGroup: IFormGroup<UserHolidayBulkAllocationViewModel>;
    userHolidayBulkAllocationViewModel: UserHolidayBulkAllocationViewModel;
    spin: boolean = false;
    showComponent: boolean = false;
    Jsonstring: any;
    decryptstring: string;
    localizedMessages: any;


    constructor(private formBuilder: RxFormBuilder, private toastr: BaseToastr,private location : PlatformLocation) {
        super();
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.bindAddHolidayYearLookup();
        this.userHolidayBulkAllocationViewModel = new UserHolidayBulkAllocationViewModel();
        this.userHolidayBulkAllocationFormGroup = this.formBuilder.formGroup(UserHolidayBulkAllocationViewModel, this.userHolidayBulkAllocationViewModel) as IFormGroup<UserHolidayBulkAllocationViewModel>;
        this.showComponent = true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    bindAddHolidayYearLookup() {
        for (let i = new Date().getFullYear() - 1; i < new Date().getFullYear() + 6; i++) {
            this.vYearLookUp.push({
                id: i,
                year: i,
            });
        }
    }

    onSubmit() {
        this.userHolidayBulkAllocationFormGroup.submitted = true;
        if (this.userHolidayBulkAllocationFormGroup.valid) {
            this.spin = true;
            this.post({ path: 'api/CustomerHolidays/BulkLeaveAllocation', body: {}, queryParams: this.userHolidayBulkAllocationFormGroup.value }).subscribe((t: any) => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert[0].showMessage);
                }
                this.hide(true);
            })
        }
    }

    get componentName(): string {
        return "CustomerHolidayBulkAddComponent";
    }
}
