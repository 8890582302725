import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractHSEReport } from '../domain/abstract-hseReport';
import { HSEReportIncidentData } from 'src/app/view-model/hsereport-incident-data';
import { AppGrid } from 'src/app/domain/app-grid';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';
@multilingual("HseReportAddComponent")
@Component({
  selector: 'app-hse-report-add',
  templateUrl: './hse-report-add.component.html'
})

export class HseReportAddComponent extends AbstractHSEReport implements OnInit, OnDestroy {
  subscription: any;
  isShowGrid: boolean;
  isNoRecordFound: boolean = false;
  totalRecords: number;
  isFilter: boolean = false;
  spin: boolean = false;
  showComponent: boolean = false;
  showGrid: boolean = false;
  @Input() OccuranceName: string;
  @Input() CompanyName: string;
  @Input() TypeId: number;
  @Input() StartDate: Date;
  @Input() EndDate: Date;
  @Input() hide: Function;
  @Input() TCount: number;
  @Input() IsByMonth: boolean = false;
  @Input() supplierIds: string = '';

  hseReportIncidentData: HSEReportIncidentData[];
  hseReportIncidentDataGrid: AppGrid;
  router: Router;
  json: any;
  titleClose: string;
  constructor(router: Router,private location : PlatformLocation) {
    super();
    this.router = router;
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.json = JSON.parse(JSON.stringify({
      occuranceName: this.OccuranceName,
      companyName: this.CompanyName,
      typeId: this.TypeId,
      startDate: this.StartDate,
      endDate: this.EndDate,
      showByMonths: this.IsByMonth,
      supplierIds: this.supplierIds,
      rowCount: PaginationEnum.RowCount,
      pageIndex: PaginationEnum.PageIndex,
      orderBy: 'Ref_No',
      sortOrder: 'true'
    }));
    this.bindgrid();
  }

  bindgrid() {
    this.spin = true;
    this.showGrid = true;
    this.isShowGrid = true;
    this.showComponent = true;
    this.subscription = this.post({
      path: "api/SearchUsers/GetIncidentDetail",
      body: { query: JSON.stringify(this.json) }
    }).subscribe((t: any) => {
      this.spin = false;
      this.hseReportIncidentData = t;
      if (this.hseReportIncidentDataGrid) {
        this.hseReportIncidentDataGrid.storeProcedure.length = 0;
      }
      if (this.hseReportIncidentData.length > 0) {
        // this.totalRecords = this.hseReportIncidentData.length;
        this.totalRecords = this.TCount;
        this.isNoRecordFound = false;
      }
      else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }
      if (!this.isFilter) {
        this.hseReportIncidentDataGrid = new AppGrid(this.hseReportIncidentData, HSEReportIncidentData, { actions: { onOneLink: this.onOneLink.bind(this) } });
        this.hseReportIncidentDataGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }
      }
      else {
        this.hseReportIncidentDataGrid.storeProcedure.length = this.totalRecords;
        this.hseReportIncidentDataGrid.updateSource([]);
        this.hseReportIncidentDataGrid.updateSource(this.hseReportIncidentData);
      }
      this.hseReportIncidentDataGrid.componentId = this.componentName;
    });
  }

  onOneLink(incidentData: HSEReportIncidentData, event) {
    if (!event.ctrlKey) {
      this.hide();
    }
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["/track-incidents/", incidentData.IncidentID], { queryParams: { activeTab: 'form-edit' } }));
    redirectOnCTRL(url,this.router,event);
    //this.router.navigate(["/track-incidents/", incidentData.IncidentID], { queryParams: { activeTab: 'form-edit' } });
    //this.hide();
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = y;
    this.json["orderBy"] = x;
    this.hseReportIncidentDataGrid.storeProcedure.nextPage = z;
    this.bindgrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.hseReportIncidentDataGrid.maxPerPage;
    this.hseReportIncidentDataGrid.storeProcedure.nextPage = x;
    this.bindgrid();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "HseReportAddComponent";
}
}
