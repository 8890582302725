import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSalesChannelBase {

        //#region salesChannelId Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'SalesChannelListComponent_Id_gh', keyColumn: true })
        salesChannelId: number;
        //#endregion salesChannelId Prop


        //#region salesChannelName Prop
        @gridColumn({ isAscending: true, style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'SalesChannelListComponent_Name_gh', keyColumn: false })
        salesChannelName: string;
        //#endregion salesChannelName Prop


        //#region channelType Prop
        //@gridColumn({ style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'SalesChannelListComponent_Type_gh', keyColumn: false })
        @gridColumn({ style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'SalesChannelListComponent_Type_gh', keyColumn: false })
        channelType: string;
        //#endregion channelType Prop

}