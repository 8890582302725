import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewContainerRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';

import { Document } from '@app/models';
import { AbstractDocument } from '../domain/abstract-document';
import { FormArray } from '@angular/forms';
import { FileTypeValiationEnums, FileTypeEnums } from '../../../../custom-enum/file-type-enums';
import { DocumentListViewModel } from '../../../../models/extended-models/document-list-view-model';
import { DatePipe, PlatformLocation } from '@angular/common';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-document-add",
  templateUrl: './document-add.component.html'
})
export class DocumentAddComponent extends AbstractDocument implements OnInit, OnDestroy {
  subscription: any;
  @Input() hide: Function;
  index: number = 0;
  titleClose: string;
  constructor(private formBuilder: RxFormBuilder, browserStorage: BrowserStorage, blobState: BlobUploadsViewStateService, httpClient: HttpClient,location : PlatformLocation, componentFactoryResolver: ComponentFactoryResolver, viewContainerRef: ViewContainerRef) {
    super(browserStorage, blobState, httpClient,location, componentFactoryResolver, viewContainerRef);
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.documentFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<Document>;
    this.showComponent = true;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  hidePopup() {
    this.hide()
  }

  get documentsFormArray(): FormArray {
    let documentsFormArray = this.documentFormGroup.controls["documents"] as IAbstractControl;
    let data = <FormArray>documentsFormArray["controls"]
    return data;
  }




}
