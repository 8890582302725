import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerRoleDomainLookUpBase {

//#region customerRoleDomainId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerRoleDomainId', keyColumn: true})
        customerRoleDomainId : number;
//#endregion customerRoleDomainId Prop


//#region customerRoleDomainName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'customerRoleDomainName', keyColumn: false})
        customerRoleDomainName : string;
//#endregion customerRoleDomainName Prop

}