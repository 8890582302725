import { minNumber, notEmpty, prop, required } from "@rxweb/reactive-form-validators";

export class ProductionOrderFormUserDepartmentMappingBase {

    //#region formUserDepartmentMappingId prop
    @prop()
    formUserDepartmentMappingId:number;
    //#endregion formUserDepartmentMappingId prop

    //#region formTypeId prop
    @prop()
    @required()
    @minNumber({value:0})
    formTypeId:number;
    //#endregion formTypeId prop

    //#region userId prop
    @prop()
    @required()
    @minNumber({value:0})
    userId:number;
    //#endregion userId prop

    //#region department prop
    @prop()
    @minNumber({value:0})
    department:number;
    //#endregion department prop
}