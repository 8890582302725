<div class="modal-dialog modal-xl">
  <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="KnownToColleguesViewComponent_Title_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
  
    <div class="modal-body" [rxSpinner]="spin"  [rxLocalisationInit]="componentName">

      <div class="data-Table">
        <div class="dataTables_filter d-flex flex-wrap align-items-center bg-white d-flex flex-wrap" *ngIf="isShowSearch">
          <label class="ml-auto mr-0 mb-3">
            <input type="search" (keyup.enter)="searchKnownTo($event)"
              class=" table_search form-control form-control-sm" placeholder="" [title]="titleSearch">
          </label>
        </div>
        <div class="dataTables_wrapper pb-0 dt-bootstrap4" *ngIf="isShowGrid">
          <rx-grid [design]="userKnownToGrid"></rx-grid>
        </div>
      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
      
    </div>

    <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
      <button (click)="hide()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
    </div>

  </div>
</div>
