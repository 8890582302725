export enum StatusEnum {
        Open = 209,
        Assigned = 210,
        Resolved = 211,
        Closed = 212,
        OnHold = 213,
        InProgress = 214,
        Active = 307,
        Inactive = 308,
        Reopen = 1496
}