import {LookupInjurySeverityBase,LookupLostTimeInjuryBase,SupplierBase,TrackIncidentBase,UserBase,PersonBodilyLocationBase,PersonInjuryTypeBase, UserDetailBase,} from '@app/database-models'
import { prop, propArray, email, required } from '@rxweb/reactive-form-validators';
import { AbstractRingEggSize } from 'src/app/components/master/ring-egg-sizes/domain/abstract-ring-egg-size';
import { IncidentCorporatesAffectedBase } from '../database-models/incident-corporates-affected-base';
//Generated Imports
export class IncidentCorporatesAffected extends IncidentCorporatesAffectedBase 
{

    constructor() {
        super()
        this.involvementRole="";
    }

@prop()
incidentId: number;

@prop()
supplierId:number;
@prop()
//@required()
userName:string;
@prop()
companyContactPermissionRequired:boolean;

@prop()
companyIntroducesBy:string;

@prop()
isSupplierUnwelcome:boolean;


}
