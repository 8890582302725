import {TemporaryAssigneeBase,TemporaryAssigneeTypeBase,GemStoneShippingDocGemFieldBase,} from '@app/database-models'
//Generated Imports
export class TemporaryAssignee extends TemporaryAssigneeBase 
{




//#region Generated Reference Properties
//#region temporaryAssigneeType Prop
temporaryAssigneeType : TemporaryAssigneeTypeBase;
//#endregion temporaryAssigneeType Prop
//#region gemStoneShippingDocGemFields Prop
gemStoneShippingDocGemFields : GemStoneShippingDocGemFieldBase[];
//#endregion gemStoneShippingDocGemFields Prop

//#endregion Generated Reference Properties






































}