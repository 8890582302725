import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { CoreComponent } from '@rxweb/angular-router';
import { TaskDetailsViewModel } from 'src/app/view-model/task-details-view-model';
import { PlatformLocation } from '@angular/common';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { TaskDetailBase } from '@app/database-models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { TaskReplyComponent } from 'src/app/components/note/notes/Task-Reply/task-reply.component';

@multilingual("TaskDetailViewComponent")
@Component({
  selector: 'app-task-detail-view',
  templateUrl: './task-detail-view.component.html'
})
export class TaskDetailViewComponent extends CoreComponent implements OnInit {
  @Input() taskDetail: TaskDetailsViewModel;
  @Input() Isdashboard: boolean=false;
  @Input() loggedInUserId: number = 0;
  @Input() entityId: number = 0;
  @Input() entityTypeId: number = 0;
  @Input() hide: () => any;
  @ViewChildren('commentDetailsRow', { read: ViewContainerRef }) commentDetailsRows: QueryList<ViewContainerRef>;
  @ViewChildren('commentDetailsDiv', { read: ViewContainerRef }) commentDetailsDivs: QueryList<ViewContainerRef>;
  commentRowViewRef: ViewContainerRef;
  commentDivViewRef: ViewContainerRef;
  toastr: BaseToastr;
  _this;

  taskDetailFormGroup: IFormGroup<TaskDetailBase>
  constructor(private location: PlatformLocation, private formBuilder: RxFormBuilder, private componentFactoryResolver: ComponentFactoryResolver) {
    super();
    this.toastr = new BaseToastr();
    location.onPopState(() => this.hide());
    this._this = this;
  }
  spin: boolean = false;
  showComponent: boolean = false;
  titleClose: string;
  isEditAccess: boolean = false;
  isCheckboxAccess: boolean = false;
  isProcessing: boolean = false;

  ngOnInit() {
   
    this.titleClose = getLocalizedValue("Title_Close");
    var task = new TaskDetailBase();
    const htmlTagRegex = /<[^>]*>/g;
    const htmlEntityRegex = /&nbsp;|&amp;|&lt;|&gt;|&quot;|&#39;/g;
    task.taskDetailId = this.taskDetail.taskDetailId;
    task.taskDescription = this.taskDetail.taskDescription;
    task.assignedByUserId = this.taskDetail.assignedByUserId;
    task.assignedByUserName = this.taskDetail.assignedByUserName;
    task.assignedToUserId = this.taskDetail.assignedToUserId;
    task.assignedToUserName = this.taskDetail.assignedToUserName;
    task.createdOn = this.taskDetail.createdOn;
    task.completionDate = this.taskDetail.completionDate;
    task.completedAt = this.taskDetail.completedAt;
    task.isCompleted = this.taskDetail.isCompleted;
    task.noteId = this.taskDetail.noteId;
    task.noteDescription = this.taskDetail.noteDescription.replace(htmlTagRegex, '').replace(htmlEntityRegex, '');
    task.entityId = this.taskDetail.noteId > 0 ? 0 : this.entityId;
    task.entityTypeId = this.taskDetail.noteId > 0 ? 0 : this.entityTypeId;

    this.isEditAccess = this.taskDetail.assignedByUserId > 0 && this.taskDetail.assignedByUserId == this.loggedInUserId && !this.taskDetail.isCompleted
    this.isCheckboxAccess = this.loggedInUserId > 0 && (this.taskDetail.assignedToUserId == this.loggedInUserId || this.taskDetail.assignedByUserId == this.loggedInUserId)
    this.taskDetailFormGroup = this.formBuilder.formGroup(TaskDetailBase, task) as IFormGroup<TaskDetailBase>;

    this.spin = false;
    this.showComponent = true;
    
   
          
  }

  saveTask() {
    this.taskDetailFormGroup.submitted = true;
    if (this.taskDetailFormGroup.valid) {
      this.spin = true;
      this.isProcessing = true;
      this.hide()
      this.put({ path: 'api/TaskDetails', body: this.taskDetailFormGroup.value, params: [this.taskDetailFormGroup.value.taskDetailId] }).subscribe(data => {
        this.spin = false;
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
        this.isProcessing = false;
      });
    }
    else {
      this.toastr.warning("Plese enter valid data");
    }
  }


  get componentName(): string {
    return "TaskDetailViewComponent";
  }
}
