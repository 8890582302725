import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerNameFormatLookUpBase {

//#region customerNameFormatId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerNameFormatId', keyColumn: true})
        customerNameFormatId : number;
//#endregion customerNameFormatId Prop


//#region customerNameFormatName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'customerNameFormatName', keyColumn: false})
        customerNameFormatName : string;
//#endregion customerNameFormatName Prop

}