import { prop, range, required, notEmpty, numeric } from '@rxweb/reactive-form-validators';

export class MoveNoteDocumentProductFilterViewModel {
  @prop()
  @numeric()
  productVariantId:number
  @prop()
  title: string;
  @prop()
  categoryId: number;
  @prop()
  collectionId: number;
  @prop()
  questionId: number;
  @prop({ defaultValue: true })
  isAnswer: boolean;
  @prop()
  statusId: number;
  @prop()
  @numeric()
  productNumber: number;
  @prop({ defaultValue: 25 })
  rowCount: number;
  @prop({ defaultValue: 1 })
  pageIndex: number;
  @prop({ defaultValue: 'productVariantId' })
  orderBy: string;
  @prop({ defaultValue: false })
  sortOrder: boolean;
  // @prop()
  // clientUrl:string;
  @prop()
  userId: number;

  @prop({ defaultValue: "" })
  specialStatusIds: string
  @prop()
  salesStatusId: number;
  @prop()
  productionStatusId: number;
  @prop()
  consignmentStatusId: number;

  @prop({ defaultValue: "" })
  gemstoneTypeIds: string

  @prop({ defaultValue: "" })
  colorIds: string

  @prop({ defaultValue: "" })
  materialIds: string

  @prop()
  eggSizeId: number;
  
  @prop({ defaultValue: false })
  isHighJewellery: boolean;
}