import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRelationShipLookUpBase {

//#region relationShipName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'relationShipName', keyColumn: false})
        relationShipName : string;
//#endregion relationShipName Prop


//#region relationShipId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        relationShipId : number;
//#endregion relationShipId Prop

}