import { LeaveMasterHolidayProcessListBase } from '../database-models/leave-master-holiday-process-list-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'editModel' }, columnIndex: 9, headerTitle: "Action"
})
export class LeaveMasterHolidayProcessList extends LeaveMasterHolidayProcessListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



}