import { prop, required } from '@rxweb/reactive-form-validators';

export class SubMenuItemLocalizationBase{
    
         @prop()
        subMenuItemLocalizationId:number;

        @prop()
        subMenuItemId:number;

        @prop()
        subMenuName:string;

      
        @prop()
        languageId:number;

        @prop()
        featuredName:string;
}