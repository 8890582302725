import {vHolidayListLookUpBase,} from '@app/database-models'
//Generated Imports
export class vHolidayListLookUp extends vHolidayListLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}