import { TemplateConfig } from '@rxweb/grid';

export const PROGRESS_BAR_TEMPLATE: TemplateConfig = {
    div: {
        class: "text-center d-flex align-items-center div-td".split(" "),
        childrens: [{
            div: {
                class: "progress progress progress-mini w-100 mt-0 mr-3".split(" "),
                childrens: [{
                    div: {
                        class: ["progress-bar",
                            function (e) {
                                if (this[e.name] == 0) {
                                    return "";
                                }

                                if (this[e.name] > 0 && this[e.name] <= 30) {
                                    return "progress-bar-danger progress-width-danger".split(" ");
                                }

                                if (this[e.name] > 30 && this[e.name] <= 60) {
                                    return "progress-bar-warning progress-width-warning".split(" ");
                                }

                                if (this[e.name] > 60 && this[e.name] <= 90) {
                                    return "progress-bar-success progress-width-success".split(" ");
                                }

                                if (this[e.name] > 90 && this[e.name] <= 100) {
                                    return "progress-bar-full";
                                }
                            }
                        ]
                    }
                }]
            }
        }, {
            div: {
                class: "font-weight-bold progress-bar-result text-center".split(" "),
                childrens: [{
                    text: {
                        text: function (e) { return this[e.name] + "%" }
                    }
                }]
            }
        }]
    }
}