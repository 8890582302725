import {MessageTemplateDetailBase,EmailAccountBase,LanguageBase,MessageTemplateBase,} from '@app/database-models'
//Generated Imports
export class MessageTemplateDetail extends MessageTemplateDetailBase
{

    
    
    constructor() {
        super();
        this.isActive = false;
    }

//#region Generated Reference Properties
//#region emailAccount Prop
emailAccount : EmailAccountBase;
//#endregion emailAccount Prop
//#region language Prop
language : LanguageBase;
//#endregion language Prop
//#region messageTemplate Prop

//#endregion messageTemplate Prop

//#endregion Generated Reference Properties












}