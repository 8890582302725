import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
import { HolidayList } from '@app/models';
import { AbstractBankHoliday } from '../domain/abstract-bank-holidays';
import { Router, ActivatedRoute } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HolidayMappingBase } from '@app/database-models';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { FormArray } from '@angular/forms';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { BankHolidayEditComponent } from '../edit/bank-holidays-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@multilingual("BankHolidayAddComponent")
@access({ accessLevel: RolePermissionEnums.BankHolidays, action: "post" })

@Component({
  selector: "app-bank-holiday-add",
  templateUrl: './bank-holidays-add.component.html'
})
export class BankHolidayAddComponent extends AbstractBankHoliday implements OnInit, OnDestroy {
  holidayList: HolidayList;
  subscription: any;
  holidayListEdit: any = BankHolidayEditComponent;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;



  _this;

  constructor(private title: Title, private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.BankHolidays;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Bank Holiday - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    // this.activatedRoute.queryParams.subscribe(t => {
    //   this.domainType = t['domain'];
    // });

    this.lookup([
      { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.holidayTypeLookUp, propName: "holidayTypeLookUp" },
    ]).subscribe((lookup: any) => {
      this.holidayLookups = lookup;
      this.holidayList = new HolidayList();
      this.holidayList.statusId = StatusEnum.Active;
      this.holidayList.holidayId = 0;
      this.holidayList.holidayMappings = new Array<HolidayMappingBase>();
      this.holidayListFormGroup = this.formBuilder.formGroup(this.holidayList) as IFormGroup<HolidayList>;
      this.showComponent = true;

    })
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  ngOnDestroy(): void {
    Swal.close();
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addHolidayList(isRedirect) {
    this.holidayListFormGroup.submitted = true;
    if (this.holidayListFormGroup.valid) {
      Swal.fire({
        width: 600,
        icon: 'info',
        title: getLocalizedValue("Bank_Holiday_Confirmation"),
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
        hideClass: {
          popup: '',                     // disable popup fade-out animation
        },
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.holidayListFormGroup.patchValue({
            recalculateLeave: true
          })
          this.addBankHoliday(isRedirect);
        } else if (result.isDenied) {
          this.holidayListFormGroup.patchValue({
            recalculateLeave: false
          })
          this.addBankHoliday(isRedirect);
        }
      })
    }
    else {
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.holidayListFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
  }
  addBankHoliday(isRedirect) {
    if (this.holidayListFormGroup.value.countryIds) {
      this.countryIds = this.holidayListFormGroup.value.countryIds.split(",");
    }
    let holidayMappingFormArray = this.holidayListFormGroup.get("holidayMappings") as FormArray;
    this.countryIds.forEach(data => {
      let holidayMapping = new HolidayMappingBase();
      holidayMapping.countryId = parseInt(data);
      holidayMapping.holidayId = 0;
      holidayMapping.holidayMappingId = 0;
      this.holidayListFormGroup.get("holidayMappings") as FormArray;
      holidayMappingFormArray.push(this.formBuilder.formGroup(holidayMapping) as IFormGroup<HolidayMappingBase>);
    })
    this.spin = true;
    this.post({ body: this.holidayListFormGroup.value }).subscribe(data => {
      this.spin = false;
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
      if (existsAlert) {
        //this.toastr.success("Data Added Successfully")
        this.toastr.success(existsAlert[0].showMessage);
      }
      // let link = "/holiday-lists/" + data;
      isRedirect ? this.router.navigate(['/bank-holidays', data]) : this.router.navigate(['/bank-holidays']);
    })
  }

  get componentName(): string {
    return "BankHolidayAddComponent";
  }
}
