import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneCertificateGemFieldBase {

//#region gemStoneCertificateId Prop
        @prop()
        gemStoneCertificateId : number;
//#endregion gemStoneCertificateId Prop


//#region gemStoneId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemStoneId : number;
//#endregion gemStoneId Prop


//#region fileName Prop
        @required()
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region certificateNumber Prop
        @maxLength({value:50})
        certificateNumber : string;
//#endregion certificateNumber Prop


//#region laboratoryId Prop
        @prop()
        laboratoryId : number;
//#endregion laboratoryId Prop





}