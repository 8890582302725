import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHolidayListLookUpBase {

//#region holidayId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'holidayId', keyColumn: true})
        holidayId : number;
//#endregion holidayId Prop


//#region holidayName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'holidayName', keyColumn: false})
        holidayName : string;
//#endregion holidayName Prop

}