import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { LeaveType } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
@http({
    path: "api/LeaveTypes",
})
export class AbstractLeaveType extends CoreComponent {  
  selectedTab: string = "leave-type-info";
  tabChange: boolean = false;
    leaveTypeFormGroup: IFormGroup<LeaveType>
    showComponent: boolean = false;
    spin: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr;
    dialog: BaseDialog;
	
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
	
}
