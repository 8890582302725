import { prop, required } from "@rxweb/reactive-form-validators";
import { StatusEnum } from "src/app/enums/status.enum";

export class LegalAgreementProjectMappingBase {

    //#region legalAgreementProjectMappingId Prop
    @prop({ defaultValue: 0 })
    legalAgreementProjectMappingId: number;
    //#endregion legalAgreementProjectMappingId Prop

    //#region legalAgreementId Prop
    @prop()
    @required()
    legalAgreementId: number;
    //#endregion legalAgreementId Prop

    //#region projectId Prop
    @prop()
    @required()
    projectId: number;
    //#endregion projectId Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    @required()
    statusId: number;
    //#endregion statusId Prop

}