import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDownloadActivationTypeLookUpBase {

//#region downloadActivationTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'downloadActivationTypeId', keyColumn: true})
        downloadActivationTypeId : number;
//#endregion downloadActivationTypeId Prop


//#region downloadActivationTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'downloadActivationTypeName', keyColumn: false})
        downloadActivationTypeName : string;
//#endregion downloadActivationTypeName Prop

}