import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxFormGroup, RxFormArray, IAbstractControl } from '@rxweb/reactive-form-validators';

import { HelpSupportUserRole, vRoleLookUp, HelpSupport } from '@app/models';
import { AbstractHelpSupportUserRole } from '../domain/abstract-help-support-user-role';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { List } from '@rxweb/generics';
import { ModalView } from '../../../../domain/customize-design/modal';
import * as CryptoJS from 'crypto-js';
import { CustomerRoleTypeEnum } from "src/app/enums/customer-role-type.enum";
import { multilingual } from '@rxweb/localization';
@multilingual("HelpSupportUserRoleAddComponent")
@Component({
    selector: "app-help-support-user-role-add",
    templateUrl: './help-support-user-role-add.component.html'
})
export class HelpSupportUserRoleAddComponent extends AbstractHelpSupportUserRole implements OnInit, OnDestroy {
    //helpSupportUserRole: HelpSupportUserRole;
    subscription: any;
    helpSupportId: number;
    helpSupportUserRole: List<HelpSupportUserRole>;
    isEdit: boolean = false;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, modelView: ModalView) {
        super();
        this.modalView = modelView;
        this.activatedRoute.params.subscribe(t => {
            this.helpSupportId = t['id'];
        })

    }

    get helpSupportUserRoles(): FormArray {
        let appointmentCustomeFormArray = this.helpSupportFormGroup.controls["helpSupportUserRoles"] as IAbstractControl;
        let data = <FormArray>appointmentCustomeFormArray["controls"]
        return data;
    }

    ngOnInit(): void {
        this.spin = true;
        this.lookup([{ params: [this.helpSupportId], propName: "helpSupport" }, { propName: "roleLookUp", path: USER_LOOKUPS.roleLookUp }]).subscribe((lookup: any) => {
            this.helpSupportLookUps = lookup;
            let tempAdminRolesArray: Array<vRoleLookUp> = new Array<vRoleLookUp>();
            this.helpSupportLookUps.roleLookUp.forEach(element => {
                if (element.roleType == CustomerRoleTypeEnum.Admin) {
                    tempAdminRolesArray.push(element);
                }
            });
            this.helpSupportLookUps.roleLookUp = tempAdminRolesArray;
            this.helpSupportLookUps.helpSupport = lookup.helpSupport[0];
            if (lookup.helpSupport.helpSupportUserRoles.length > 0) {
                this.helpSupportUserRole = new List<HelpSupportUserRole>(lookup.helpSupport.helpSupportUserRoles);
            }
            this.helpSupportLookUps.helpSupport.helpSupportUserRoles = new Array<HelpSupportUserRole>();
            this.helpSupportLookUps.helpSupport.helpSupportUserRoles = this.bindFormGroup();
            this.helpSupportFormGroup = this.formBuilder.formGroup(HelpSupport, this.helpSupportLookUps.helpSupport) as IFormGroup<HelpSupport>;
            this.spin = false;
            this.showComponent = true;
        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    bindFormGroup(): HelpSupportUserRole[] {
        let roleLookUps = this.helpSupportLookUps.roleLookUp as Array<vRoleLookUp>;
        let helpSupportUserRoleArray = Array<HelpSupportUserRole>();
        roleLookUps.forEach((roleLookUp: vRoleLookUp) => {
            let helpSupportUserRole = new HelpSupportUserRole();
            if (this.helpSupportUserRole !== undefined && this.helpSupportUserRole !== null) {
                if (this.helpSupportUserRole.where(x => x.userRoleId == roleLookUp.roleId).count() > 0) {
                    let helpSupportUserRoleEdit = this.helpSupportUserRole.firstOrDefault(x => x.userRoleId == roleLookUp.roleId);
                    if (helpSupportUserRoleEdit) {
                        helpSupportUserRole.isActive = helpSupportUserRoleEdit["isActive"]
                        helpSupportUserRole.helpSupportUserRoleId = helpSupportUserRoleEdit["helpSupportUserRoleId"];
                    }
                }
                else {
                    helpSupportUserRole.isActive = false;
                    helpSupportUserRole.helpSupportUserRoleId = 0;
                }
            } else {
                helpSupportUserRole.isActive = false;
                helpSupportUserRole.helpSupportUserRoleId = 0;
            }
            helpSupportUserRole.userRoleId = roleLookUp.roleId;
            helpSupportUserRole.stausId = StatusEnum.Active;
            helpSupportUserRole.helpSupportId = this.helpSupportId;
            helpSupportUserRoleArray.push(helpSupportUserRole)
        })
        return helpSupportUserRoleArray;
    }

    getRoleName(roleId: number): string {
        let roleName = new List<vRoleLookUp>(this.helpSupportLookUps.roleLookUp, vRoleLookUp).firstOrDefault(x => x.roleId == roleId);
        return roleName["roleName"];
    }

    manageUserRoles(isRedirect: boolean): void {
        this.spin = true;
        this.put({ params: [this.helpSupportId], path: "api/HelpSupports", body: this.helpSupportFormGroup.value }).subscribe(data => {
            this.spin = false;
            var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
            if (existsAlert) {
                this.toastr.success(existsAlert[0].showMessage);
            }
            isRedirect ? this.ngOnInit() : this.router.navigate(['/help-supports']);
        })
    }
    get componentName(): string {
        return "HelpSupportUserRoleAddComponent";
    }

}
