import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';

import { ProjectCorporateMapping, EventCustomerMapping, ProjectEventMapping, BookingCustomerMapping, vUserLookUp } from '@app/models';
import { AbstractProjectCorporateMapping } from '../domain/abstract-project-corporate-mapping';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { ProjectsCorporateMappingFilterViewModel } from 'src/app/view-model/project-corporate-mapping-filter-view-model';
import { ProjectUserListViewModel } from 'src/app/models/extended-models/project-user-list-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { ActivatedRoute } from '@angular/router';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { EventInviteesViewModel } from 'src/app/view-model/event-invitees-view-model';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { PeopleCorporateInvolvedModel } from 'src/app/view-model/people-corporate-involved-model';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { DatePipe, PlatformLocation } from '@angular/common';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { CoreComponent, } from '@rxweb/angular-router';

import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
@multilingual("ProjectCorporateMappingAddComponent")

@Component({
    selector: "app-project-corporate-mapping-add",
    templateUrl: './project-corporate-mapping-add.component.html'
})
export class ProjectCorporateMappingAddComponent extends AbstractProjectCorporateMapping implements OnInit, OnDestroy {
    projectCorporateMapping: ProjectsCorporateMappingFilterViewModel;
    projectsCorporateMappingSearchFormGroup: IFormGroup<ProjectsCorporateMappingFilterViewModel>;
    ProjectUserListViewModel: ProjectUserListViewModel[];
    subscription: any;

    ProjectUserListColumns: any[];
    @Input() mapId: number;
    @Input() hide: Function;
    @Input() componentType: string;
    @Input() involved: boolean;
    @Input() peopleOrCorporate: number;
    @Input() hasBoughtProductFlag : boolean = false;
    userType: number = 0;
    showSaveButton: boolean = false;
    isPersonOrCorporate: boolean = true;
    projectCorporateMappingInfo: ProjectCorporateMapping;
    projectCorporateMappingArray: ProjectCorporateMapping[];
    eventCustomerMappingArray: EventCustomerMapping;
    excludedeventCorporateMappingArray: EventCustomerMapping;
    errorMessage: string = "[\n\"Person/Corporate already exists !\"\n]";
    path: string;
    isFilter: boolean = false;
    totalRecords: number = 0;
    headerBtnChk: boolean = false;
    cloneArray: ProjectUserListViewModel[];
    projectCorporateIds: number[];
    excludeProjectCorporateIds:number[];
    excludeEventCorporateIds : number[];
    showGrid: boolean = false;
    titleClose: string;
    ErrorMsg:string;
    bookingClientsMappingInfo: BookingCustomerMapping;
    eventIds: number[];
    clientIds: number[];
    bookingClientsMappingArray: BookingCustomerMapping[];
    showCategoryCollection: boolean =false;
    searchBadgeId : string = "";
    resetControl: boolean = true;
    filterJson:any;
    excludedProjectCorporateMappingArray: ProjectCorporateMapping[];
    excludebookingClientsMappingArray: BookingCustomerMapping[];
    excludeClientIds: number[];

    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, modelView: ModalView, private datePipe: DatePipe, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.projectCorporateMappingArray = new Array<ProjectCorporateMapping>();
        this.eventCustomerMappingArray = new EventCustomerMapping();
        this.excludedeventCorporateMappingArray = new EventCustomerMapping();
        this.excludedeventCorporateMappingArray.PeopleEventInput = new Array<ProjectEventMappingViewModel>();
        this.eventCustomerMappingArray.PeopleEventInput = new Array<ProjectEventMappingViewModel>();
        this.bookingClientsMappingArray = new Array<BookingCustomerMapping>();
        this.excludebookingClientsMappingArray = new Array<BookingCustomerMapping>();
        this.projectCorporateIds = new Array<number>();
        this.excludeProjectCorporateIds = new Array<number>();
        this.excludeEventCorporateIds = new Array<number>();
        this.eventIds = new Array<number>();
        this.clientIds = new Array<number>();
        this.excludeClientIds = new Array<number>();
        this._this = this;
        this.excludedProjectCorporateMappingArray = new Array<ProjectCorporateMapping>();
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.isFilter = false;
        this.cloneArray = new Array<ProjectUserListViewModel>();
        this.lookup([{ path: USER_LOOKUPS["salesUserLookup"], propName: "salesAdvisorLookUp" }, { path: USER_LOOKUPS["companyLookUp"], propName: "companyLookUp" }, { path: USER_LOOKUPS["industryTypeLookUp"], propName: "industryTypeLookUp" }, { path: USER_LOOKUPS["countryLookUp"], propName: "countryLookUp" }, {path :USER_LOOKUPS["designCategoryLookup"],propName:"categoryLookup"},{ path: USER_LOOKUPS["getCollectionLookUps"], propName: "collectionLookup", params: [13] }]).subscribe((lookup: any) => {
            this.categoryLookup = lookup.categoryLookup;
            this.categoryLookup = this.categoryLookup.filter(t => t.parentCategoryId != 0);
            this.categoryLookup = this.categoryLookup.filter(t => t.categoryName = t.categoryName.substring(t.categoryName.indexOf(">") + 3));
            this.collectionLookup = lookup.collectionLookup;
            this.projectCorporateMapping = new ProjectsCorporateMappingFilterViewModel();
            this.projectCorporateMapping.projectId = this.mapId;
            this.projectCorporateMapping.eventId = this.mapId;
            this.projectCorporateMapping.rowCount = PaginationEnum.RowCount;
            this.projectCorporateMapping.pageIndex = PaginationEnum.PageIndex;
            this.projectCorporateMapping.isInvitee = this.involved == true ? "false" : "true";
            this.projectCorporateMapping.hasBoughtProduct = false;
            if(this.componentType == 'project'){
                this.projectCorporateMapping.projectOrEvent = 0;
            }
            else if(this.componentType == 'events'){
                this.projectCorporateMapping.projectOrEvent = 1;
            }
            else if(this.componentType == 'bookings'){
                this.projectCorporateMapping.projectOrEvent = 2;
            }
            else if(this.componentType == 'project-restrict-access'){
                this.projectCorporateMapping.projectOrEvent = 3;
            }
            this.projectCorporateMapping.peopleOrCorporate = (this.peopleOrCorporate != undefined || this.peopleOrCorporate != null) ? this.peopleOrCorporate : 0;
            this.projectsCorporateMappingSearchFormGroup = this.formBuilder.formGroup(this.projectCorporateMapping) as IFormGroup<ProjectsCorporateMappingFilterViewModel>;
            this.projectCorporateMappingLookup = lookup;
            this.showComponent = true;
            this.checkUserType();
            this.spin = false;

        });
    }
    search() {
        this.cloneArray = [];
        this.cloneArray = new Array<ProjectUserListViewModel>();
        if(this.headerBtnChk) {
            this.cloneArray = new Array<ProjectUserListViewModel>();
            this.projectCorporateMappingArray = [];
            this.excludedProjectCorporateMappingArray = [];
            this.excludedeventCorporateMappingArray.PeopleEventInput = [];
            this.projectCorporateIds = [];
            this.eventCustomerMappingArray.PeopleEventInput = [];
            this.excludebookingClientsMappingArray = [];
            this.bookingClientsMappingArray = [];
            this.excludeClientIds = [];
            this.clientIds = [];
            this.eventIds = [];
            this.excludeEventCorporateIds = [];
            this.excludeProjectCorporateIds = [];

        }
        this.headerBtnChk = false;
        if (this.ProjectUserListGrid != null) {
            this.isFilter = true;
            this.ProjectUserListGrid.updateSource([]);
            this.ProjectUserListGrid.storeProcedure.nextPage = 1;
            this.projectsCorporateMappingSearchFormGroup.value.rowCount = this.ProjectUserListGrid.maxPerPage;
        }



        this.projectsCorporateMappingSearchFormGroup.value.pageIndex = 1
        this.searchPeopleOrCorporate();
    }
    changeLayout($event) {
        if($event.target.checked){
            this.showCategoryCollection = true;
            this.projectsCorporateMappingSearchFormGroup.patchValue({
                categoryId: this.projectsCorporateMappingSearchFormGroup.controls.categoryId.value,
                collectionId:this.projectsCorporateMappingSearchFormGroup.controls.collectionId.value
            })
        }
        else{
            this.showCategoryCollection = false;
            this.projectsCorporateMappingSearchFormGroup.patchValue({
                categoryId: undefined,
                collectionId: undefined
            })
        }
    }

    searchPeopleOrCorporate() {
        this.projectsCorporateMappingSearchFormGroup.submitted = true;
        this.totalRecords = 0;
        let startDate = new Date();
        startDate = this.projectsCorporateMappingSearchFormGroup.controls.ragistrationFromDate.value;
        let endDate = new Date();
        endDate = this.projectsCorporateMappingSearchFormGroup.controls.ragistrationToDate.value;
        if (endDate != null && startDate > endDate) {
            this.dateError = true;
        }
        else {
            this.showGrid = true;
            this.isShowGrid = true;
            this.dateError = false;
            if (this.projectsCorporateMappingSearchFormGroup.valid) {

                if(this.projectsCorporateMappingSearchFormGroup.value.badgeIdText == null || (this.projectsCorporateMappingSearchFormGroup.value.userId > 0 && typeof this.projectsCorporateMappingSearchFormGroup.value.userId === 'number')){
                    this.projectsCorporateMappingSearchFormGroup.patchValue({
                        badgeIdText: ''
                    })
                }

                if(this.projectsCorporateMappingSearchFormGroup.value.badgeIdText != ''){
                    this.projectsCorporateMappingSearchFormGroup.patchValue({
                        userId: null
                    })
                }

                this.resetControl = false;
                setTimeout(() => {
                    this.resetControl = true;
                }, 10);

                this.filterJson = this.projectsCorporateMappingSearchFormGroup.value
                this.spin = true;

                this.subscription = this.post({
                    path: "api/SearchPorjectLookUps",
                    body: {
                        query: JSON.stringify(this.projectsCorporateMappingSearchFormGroup.value),
                        bookingType : this.projectsCorporateMappingSearchFormGroup.value.projectOrEvent
                    }
                }).subscribe((t: any) => {
                    this.spin = false;
                    if (this.ProjectUserListGrid != null) {
                        this.isFilter = true;
                    }
                    this.ProjectUserListViewModel = t[0].UserData;
                    this.ErrorMsg = t[0].ErrorMessage;
                    if (this.ProjectUserListViewModel.length > 0) {
                        this.totalRecords = this.ProjectUserListViewModel[0].totalCount;
                        this.ProjectUserListViewModel.forEach(x => {
                            x.isChecked = false;
                        })
                        this.ProjectUserListViewModel.forEach(x => {
                            x.isChecked = this.headerBtnChk;
                        })
                      this.ProjectUserListViewModel.forEach(x => {

                            if (this.componentType == 'events' &&
                                ((this.eventIds != undefined || this.eventIds != null) ||
                                (this.excludeEventCorporateIds != undefined || this.excludeEventCorporateIds!= null )
                                )) {
                                if(this.headerBtnChk) {
                                    if(this.excludeEventCorporateIds.indexOf(Number(x.id))!= -1){
                                        x.isChecked = false;
                                    }
                                }
                                else {
                                    if(this.eventIds.indexOf(Number(x.id))!= -1){
                                        x.isChecked = true;
                                    }
                                }
                            }
                            if ((this.componentType == 'project' || this.componentType == 'project-restrict-access') &&
                                (
                                    (this.projectCorporateIds != undefined || this.projectCorporateIds != null) ||
                                    (this.excludeProjectCorporateIds != undefined || this.excludeProjectCorporateIds != null)
                                )) {
                                    if(this.headerBtnChk) {
                                        if(this.excludeProjectCorporateIds.indexOf(Number(x.id))!= -1){
                                            x.isChecked = false;
                                        }
                                    }
                                    else {
                                        if(this.projectCorporateIds.indexOf(Number(x.id))!= -1){
                                            x.isChecked = true;
                                        }
                                    }
                            }
                            if(this.componentType == 'bookings' &&
                            ((this.clientIds != undefined || this.clientIds != null) ||
                            (this.excludeClientIds != undefined || this.excludeClientIds != null)
                            ) ) {
                                if(this.headerBtnChk) {
                                    if(this.excludeClientIds.indexOf(Number(x.id)) != -1) {
                                        x.isChecked = false;
                                    }
                                }
                                else {
                                    if(this.clientIds.indexOf(Number(x.id)) != -1 ) {
                                        x.isChecked = true;
                                    }
                                }
                            }
                            if (this.cloneArray.findIndex(z => z.id == x.id) == -1) {
                                this.cloneArray.push(x);
                            }
                            else {
                                this.cloneArray.forEach(z => {
                                    if (z.id == x.id) {
                                        x.isChecked = z.isChecked
                                    }
                                })
                            }
                        })
                        this.updateProjectMappingArray();
                        this.isNoRecordFound = false;
                    }
                    if (this.ProjectUserListViewModel.length == 0) {
                        this.isNoRecordFound = true;
                        this.isShowGrid = false;
                    }
                    if (this.isNoRecordFound == false) {
                        if (!this.isFilter) {
                            this.ProjectUserListGrid = new AppGrid(this.ProjectUserListViewModel, ProjectUserListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                            this.ProjectUserListGrid.storeProcedure = {
                                length: this.totalRecords,
                                onPageChanging: this.onPageChanging.bind(this),
                                nextPage: 1,
                                onPageSorting: this.onPageSorting.bind(this)
                            }
                            this.ProjectUserListGrid.gridColumns.forEach(x => {
                                if (x.columnIndex == 0) {
                                    x.visible = true;

                                } if (x.columnIndex == 1) {
                                    x.visible = false;
                                }
                            });
                            if (this.userType == 0) {
                                this.ProjectUserListGrid.gridColumns.forEach(x => {
                                    if (x.columnIndex == 5) {
                                        x.visible = true;
                                    }
                                    if (x.columnIndex == 4) {
                                        x.visible = true;
                                    }
                                });
                            }
                        }
                        else {
                            if (this.ProjectUserListGrid != null) {
                                this.ProjectUserListGrid.storeProcedure.length = this.totalRecords;
                                if (this.projectsCorporateMappingSearchFormGroup.value.pageIndex == 1) {
                                    this.ProjectUserListGrid.storeProcedure.nextPage = 1;
                                }
                                this.ProjectUserListGrid.updateSource([]);
                                this.ProjectUserListGrid.updateSource(this.ProjectUserListViewModel);
                            }
                        }
                        this.applyClass();
                        this.ProjectUserListGrid.maxPerPage = this.projectsCorporateMappingSearchFormGroup.value.rowCount;
                        this.ProjectUserListGrid.componentId = this.componentName;
                        this.ProjectUserListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                        this.ProjectUserListGrid.designClass.headerClass = "table-header-sticky".split(" ");
                    }
                    this.showSaveButton = true;
                })
            }
        }
    }

    onCheckboxSelect(t, x) {
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<ProjectUserListViewModel>();

        oldCloneArray.forEach(data => {
            let newObj = new ProjectUserListViewModel();
            newObj.corporate = data.corporate;
            newObj.email = data.email;
            newObj.fullName = data.fullName;
            newObj.rating = data.rating;
            newObj.id = data.id;
            newObj.isChecked = data.id == t.id ? x.target.checked : data.isChecked
            newObj.registrationDate = data.registrationDate;
            newObj.roleId = data.roleId;
            newObj.statusId = data.statusId;
            newObj.totalCount = data.totalCount;
            newObj.contactPermissionRequired = data.contactPermissionRequired;
            newObj.introducesBy = data.introducesBy;
            newObj.isUnwelcome = data.isUnwelcome;
            this.cloneArray.push(newObj);

        });

        let scrollTop: number = 0;
        let gridScrollTop: number = 0;
        if(navigator.userAgent.match(/edg/i)){
            let elements = document.getElementsByClassName('table-responsive');
            scrollTop = document.getElementById('app-project-corporate-mapping-add').scrollTop;
            gridScrollTop = elements.item(elements.length - 1).scrollTop;
        }
        this.ProjectUserListGrid.updateSource([]);
        this.ProjectUserListGrid.updateSource(this.cloneArray);
        if(navigator.userAgent.match(/edg/i)){
            let elements = document.getElementsByClassName('table-responsive');
            elements.item(elements.length - 1).scrollTop = gridScrollTop;
            document.getElementById('app-project-corporate-mapping-add').scrollTop = scrollTop;
        }

        this.updateProjectMappingArray();
    }

    updateProjectMappingArray() {

        if (this.componentType == 'project' || this.componentType == 'project-restrict-access') {
            this.cloneArray.forEach(x => {
                let projectCorporateMappingObj = new ProjectCorporateMapping();
                projectCorporateMappingObj.projectCorporateID = 0;
                projectCorporateMappingObj.projectID = +this.mapId;
                projectCorporateMappingObj.corporateID = Number(x.id);
                projectCorporateMappingObj.insiderRequestApproval = 0;
                projectCorporateMappingObj.peopleOrCorporate = this.projectsCorporateMappingSearchFormGroup.value.peopleOrCorporate;
                if (x.isChecked) {
                    if(this.headerBtnChk) {
                        if(this.excludedProjectCorporateMappingArray.findIndex(t => t.corporateID == x.id) != -1 ) {
                            this.excludedProjectCorporateMappingArray.splice(this.excludedProjectCorporateMappingArray.findIndex(t => t.corporateID == x.id),1);
                            this.excludeProjectCorporateIds.splice(this.excludeProjectCorporateIds.indexOf(x.id),1);
                        }
                    }
                    else{
                        if (this.projectCorporateIds.indexOf(Number(x.id)) == -1) {
                            this.projectCorporateMappingArray.push(projectCorporateMappingObj);
                            this.projectCorporateIds.push(Number(x.id));
                        }
                    }
                }
                else {
                    if(this.headerBtnChk == true) {
                        if(this.excludedProjectCorporateMappingArray.findIndex(t => t.corporateID == x.id) == -1 ) {
                            this.excludedProjectCorporateMappingArray.push(projectCorporateMappingObj);
                            this.excludeProjectCorporateIds.push(x.id);
                        }
                    }else{
                        if (this.projectCorporateIds.indexOf(Number(x.id)) != -1) {
                            this.projectCorporateMappingArray.splice(this.projectCorporateIds.indexOf(Number(x.id)), 1);
                            this.projectCorporateIds.splice(this.projectCorporateIds.indexOf(Number(x.id)), 1);
                        }
                        if (this.projectCorporateIds.indexOf(Number(x.id)) != -1) {
                            this.projectCorporateIds.splice(this.projectCorporateIds.indexOf(Number(x.id)), 1);
                        }
                    }
                }
            })
        }
        else if (this.componentType == 'events') {

            this.cloneArray.forEach(x => {
                let projectEventModel = new ProjectEventMappingViewModel();
                projectEventModel.projectID = Number(x.id);
                this.eventCustomerMappingArray.eventID = this.mapId;
                this.eventCustomerMappingArray.involved = this.involved;
                projectEventModel.peopleOrCorporate = this.projectsCorporateMappingSearchFormGroup.value.peopleOrCorporate;
                if (x.isChecked) {
                    if(this.headerBtnChk){
                        if(this.excludedeventCorporateMappingArray.PeopleEventInput.findIndex(t => t.projectID == x.id) != -1 ) {
                            this.excludedeventCorporateMappingArray.PeopleEventInput.splice(this.excludedeventCorporateMappingArray.PeopleEventInput.findIndex(t => t.projectID == x.id),1);
                            this.excludeEventCorporateIds.splice(this.excludeEventCorporateIds.indexOf(x.id),1);
                        }
                    }
                    else{
                        if (this.eventCustomerMappingArray.PeopleEventInput.findIndex(z => z.projectID == Number(x.id)) == -1) {
                            this.eventCustomerMappingArray.PeopleEventInput.push(projectEventModel);
                        }
                        if (this.eventIds.indexOf(Number(x.id)) == -1) {
                            this.eventIds.push(Number(x.id));
                        }
                    }
                }
                else {
                    if(this.headerBtnChk == true) {
                        if(this.excludedeventCorporateMappingArray.PeopleEventInput.findIndex(t => t.projectID == x.id) == -1 ) {
                            this.excludedeventCorporateMappingArray.PeopleEventInput.push(projectEventModel);
                            this.excludeEventCorporateIds.push(x.id);
                        }
                    }
                    else{
                        if (this.eventCustomerMappingArray.PeopleEventInput.findIndex(z => z.projectID == Number(x.id)) != -1) {
                            this.eventCustomerMappingArray.PeopleEventInput.splice(this.eventCustomerMappingArray.PeopleEventInput.findIndex(z => z.projectID == Number(x.id)), 1);
                            this.eventIds.splice(this.eventIds.indexOf(Number(x.id)), 1);
                        }
                        if (this.eventIds.indexOf(Number(x.id)) != -1) {
                            this.eventIds.splice(this.eventIds.indexOf(Number(x.id)), 1);
                        }
                    }
                }
            });
        }
        else if(this.componentType == "bookings")
        {
          //this.bookingClientsMappingArray = new Array<BookingCustomerMapping>();
          //this.clientIds = new Array<number>();
          this.cloneArray.forEach(x => {
            let BookingClientsMappingObj = new BookingCustomerMapping();
            BookingClientsMappingObj.tripCustomerID = 0;
            BookingClientsMappingObj.tripID = this.mapId;
            BookingClientsMappingObj.customerID = Number(x.id);
              if (x.isChecked) {
                //this.bookingClientListGrid.source.forEach(z => z.isAllSelected = x.isChecked);
                 if(this.headerBtnChk) {
                    if (this.excludeClientIds.indexOf(Number(x.id)) != -1) {
                        this.excludebookingClientsMappingArray.splice(this.excludeClientIds.indexOf(Number(x.id)), 1);
                        this.excludeClientIds.splice(this.excludeClientIds.indexOf(Number(x.id)), 1);
                    }
                 }
                 else {
                    if (this.clientIds.indexOf(Number(x.id)) == -1) {
                        this.bookingClientsMappingArray.push(BookingClientsMappingObj);
                        this.clientIds.push(Number(x.id));
                    }
                }
              } else {
                if(this.headerBtnChk) {
                    if (this.excludeClientIds.indexOf(Number(x.id)) == -1) {
                        this.excludebookingClientsMappingArray.push(BookingClientsMappingObj);
                        this.excludeClientIds.push(Number(x.id));
                    }
                }
                else {
                    if (this.clientIds.indexOf(Number(x.id)) != -1) {
                        this.bookingClientsMappingArray.splice(this.clientIds.indexOf(Number(x.id)), 1);
                        this.clientIds.splice(this.clientIds.indexOf(Number(x.id)), 1);
                    }
                }
            }
          });
        }
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<ProjectUserListViewModel>();
        this.projectCorporateMappingArray = [];
        this.excludedProjectCorporateMappingArray = [];
        this.excludedeventCorporateMappingArray.PeopleEventInput = [];
        this.projectCorporateIds = [];
        this.eventCustomerMappingArray.PeopleEventInput = [];
        this.excludebookingClientsMappingArray = [];
        this.bookingClientsMappingArray = [];
        this.excludeClientIds = [];
        this.clientIds = [];
        this.eventIds = [];
        this.excludeEventCorporateIds = [];
        this.excludeProjectCorporateIds = [];

        this.ProjectUserListViewModel.forEach(data => {
            let newObj = new ProjectUserListViewModel();
            newObj.corporate = data.corporate;
            newObj.email = data.email;
            newObj.fullName = data.fullName;
            newObj.rating = data.rating;
            newObj.id = data.id;
            newObj.isChecked = this.headerBtnChk;
            newObj.registrationDate = data.registrationDate;
            newObj.roleId = data.roleId;
            newObj.statusId = data.statusId;
            newObj.totalCount = data.totalCount;
            newObj.contactPermissionRequired = data.contactPermissionRequired;
            newObj.introducesBy = data.introducesBy;
            newObj.isUnwelcome = data.isUnwelcome;
            this.cloneArray.push(newObj);

        });
        // if (this.projectCorporateMappingArray.length > 0 || this.excludedProjectCorporateMappingArray.length > 0 || this.headerBtnChk) {
        //     this.showSaveButton = true;
        // }
        // else {
        //     this.showSaveButton = false;
        // }
        this.updateProjectMappingArray();
        this.ProjectUserListGrid.updateSource([]);
        this.ProjectUserListGrid.updateSource(this.cloneArray);
    }

    checkUserType() {
        this.userType = this.projectsCorporateMappingSearchFormGroup.get('peopleOrCorporate').value;
        this.projectsCorporateMappingSearchFormGroup.patchValue({
            ragistrationFromDate: null,
            ragistrationToDate: null,
            email: null,
            userId: null,
            badgeIdText:null,
            salesAdvisor: null,
            industryType: null,
            corporate: null,
            name: null,
            orderBy: 'fullName',
            sortOrder: 'true',
            pageIndex: 1
        });
        this.searchBadgeId = '';
        if (this.ProjectUserListGrid) {
            this.cloneArray = [];
            if (this.componentType == 'project' || this.componentType == 'project-restrict-access') {
                this.projectCorporateMappingArray = [];
            }
            if (this.componentType == 'events') {
                this.eventCustomerMappingArray.PeopleEventInput = [];
            }
            this.headerBtnChk = false;
            this.showGrid = false;
            this.isFilter = false;
            this.ProjectUserListGrid = undefined;
        }
    }

    checkBadgeIdText(event: any){
        this.projectsCorporateMappingSearchFormGroup.patchValue({
            badgeIdText: event.target.value
        })
        this.searchBadgeId = event.target.value
    }

    onBadgeIdClick($event) {
        this.projectsCorporateMappingSearchFormGroup.patchValue({
            badgeIdText: ''
        })
        this.searchBadgeId = $event.item.value;
    }
    savePersonOrCorporate(isRedirect) {
        this.projectCorporateMappingInfo = new ProjectCorporateMapping();
        this.projectCorporateMappingInfo.corporateIds = this.projectCorporateMappingArray;
        this.spin = true;
        if (this.componentType == 'project' || this.componentType == 'project-restrict-access') {
            this.projectCorporateMappingInfo.corporateIds.forEach(x=>
                x.projectOrEvent = this.projectCorporateMapping.projectOrEvent
            )
            this.projectCorporateMappingInfo.projectOrEvent = this.projectCorporateMapping.projectOrEvent;
            if(this.headerBtnChk){
                this.projectCorporateMappingInfo.corporateIds = this.excludedProjectCorporateMappingArray;
                this.projectCorporateMappingInfo.isHeaderChecked = this.headerBtnChk;
                this.filterJson["pageIndex"] = 1;
                this.filterJson["rowCount"] = this.totalRecords;
                this.filterJson["sortOrder"] = "false";
                this.projectCorporateMappingInfo.projectID = this.mapId
                this.projectCorporateMappingInfo.query = JSON.stringify(this.filterJson);
                this.projectCorporateMappingInfo.peopleOrCorporate = this.projectsCorporateMappingSearchFormGroup.value.peopleOrCorporate == 1 ? 1 : 0;
            }
            else{
                this.projectCorporateMappingInfo.corporateIds = this.projectCorporateMappingArray;
            }
            this.post({ body: this.projectCorporateMappingInfo, path: 'api/ProjectCorporateMappings' }).subscribe(data => {
                this.spin = false;
                var existsAlert = getLocalizedValue("PersonCorporate_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
                this.projectCorporateMappingArray = [];
                this.cloneArray = [];
                this.headerBtnChk = false;
                this.eventCustomerMappingArray.PeopleEventInput = [];
                isRedirect ? this.searchPeopleOrCorporate() : this.hide();
                //this.hide();
            });
        }
        if (this.componentType == 'events') {
            if(this.headerBtnChk){
                this.eventCustomerMappingArray = this.excludedeventCorporateMappingArray;
                this.eventCustomerMappingArray.isHeaderChecked = this.headerBtnChk;
                this.filterJson["pageIndex"] = 1;
                this.filterJson["rowCount"] = this.totalRecords;
                this.filterJson["sortOrder"] = "false";
                this.eventCustomerMappingArray.query = JSON.stringify(this.filterJson);
                this.eventCustomerMappingArray.isSupplier = this.projectsCorporateMappingSearchFormGroup.value.peopleOrCorporate == 1 ? true : false;
                this.eventCustomerMappingArray.eventID = this.mapId;
            }
            this.eventCustomerMappingArray.customerID = 1;
            this.eventCustomerMappingArray.addedBy = 1;
            this.eventCustomerMappingArray.involved = this.involved;
            this.post({ body: this.eventCustomerMappingArray, path: 'api/EventCustomerMappings' }).subscribe(data => {
                this.spin = false;
                this.toastr.success("People / Corporate Added Successfully");
                this.projectCorporateMappingArray = [];
                this.cloneArray = [];
                this.headerBtnChk = false;
                this.eventCustomerMappingArray.PeopleEventInput = [];
                isRedirect ? this.searchPeopleOrCorporate() : this.hide();
                //this.hide();
            });
        }
    }

    save(isRedirect){
        if(this.headerBtnChk){
         var count = this.totalRecords - ((this.componentType == 'project' || this.componentType == 'project-restrict-access')
            ? this.excludeProjectCorporateIds.length
            : this.componentType == 'events' ? this.excludeEventCorporateIds.length
                                             : this.excludebookingClientsMappingArray.length );
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  count  + " Records";;
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   if(this.componentType == 'bookings') {
                        this.saveBookingClient(isRedirect);
                   }
                   else {
                       this.savePersonOrCorporate(isRedirect);
                   }
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else {
            if(this.componentType == 'bookings') {
                this.saveBookingClient(isRedirect);
            }
            else {
                this.savePersonOrCorporate(isRedirect);
            }
        }
     }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
       

    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.projectsCorporateMappingSearchFormGroup.patchValue({
            sortOrder: y,
            orderBy: x
        });
        this.cloneArray = [];
        this.ProjectUserListGrid.storeProcedure.nextPage = z;
        this.searchPeopleOrCorporate();
    }

    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.projectsCorporateMappingSearchFormGroup.value.rowCount;
        let toIndex: number = this.ProjectUserListGrid.maxPerPage;
        // if (this.projectsCorporateMappingSearchFormGroup.value.pageIndex < x) {
            this.cloneArray = [];
        // }
        if (this.ProjectUserListGrid.maxPerPage < this.projectsCorporateMappingSearchFormGroup.value.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }
        }
        this.projectsCorporateMappingSearchFormGroup.patchValue({
            pageIndex: x,
            rowCount: this.ProjectUserListGrid.maxPerPage
        });

        this.ProjectUserListGrid.storeProcedure.nextPage = x;
        this.searchPeopleOrCorporate();
    }
    saveBookingClient(isRedirect : boolean)
    {
      this.spin = true;
      this.bookingClientsMappingInfo = new BookingCustomerMapping();
      this.bookingClientsMappingInfo.customerID = 2;
      this.bookingClientsMappingInfo.tripID = this.mapId;
      if(this.headerBtnChk) {
        this.bookingClientsMappingInfo.headerBtnChk = this.headerBtnChk;
        this.filterJson["rowCount"] = this.totalRecords;
        this.filterJson["sortOrder"] = "false";
        this.filterJson["pageIndex"] = 1;
        this.bookingClientsMappingInfo.query = JSON.stringify(this.filterJson);
        this.bookingClientsMappingInfo.clientIds = this.excludebookingClientsMappingArray;
      }
      else {
          this.bookingClientsMappingInfo.clientIds = this.bookingClientsMappingArray;
      }

      this.post({ path: "api/BookingCustomerMappings", body: this.bookingClientsMappingInfo }).subscribe(data => {
        this.spin = false;
        this.cloneArray = [];
        //this.toastr.success("Clients Added Successfully");
        var existsAlert = getLocalizedValue("Clients_Added");
        if (existsAlert) {
            this.toastr.success(existsAlert);
        }
        this.bookingClientsMappingArray = [];
        this.headerBtnChk = false;
        isRedirect ? this.searchPeopleOrCorporate() : this.hide();

    });

    }

    get componentName(): string {
        return "ProjectCorporateMappingAddComponent";
    }
}
