import { prop, propObject, propArray, required, maxLength, range, numeric, notEmpty, pattern, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BulkUploadUserBase {

        //#region gemStoneID Prop
        @prop()
        Id: number;
        //#endregion gemStoneID Prop


        @maxLength({ value: 100 })
        @trim()
        @required()
        @notEmpty()
        @prop()
        FirstName: string;
        //#endregion title Prop


        //#region categoryID Prop
        @maxLength({ value: 100 })
        @trim()
        @required()
        @notEmpty()
        @prop()
        LastName: string;
        //#endregion categoryID Prop

        //#region MiddleName Prop
        // @maxLength({ value: 100 })
        // @trim()
        // @required()
        // @notEmpty()
        @prop()
        MiddleName: string;
        //#endregion MiddleName Prop

        @prop()
        @required()
        UserRoleType: number;
        //#endregion clientDescription Prop

        @prop()
        @required()
        RoleId: number;
        //#region internalDescription Pro

        @prop()
        PrimaryEmailAddress: string;
        //#endregion internalDescription Prop


        //#region status Prop
        //@required()
        @prop()
        SecondaryEmailAddress: string;
        //#endregion status Prop


        //#region published Prop
        @prop()
        MobileNumber: string;
        //#endregion published Prop


        //#region deleted Prop
        @prop()
        PhoneNumber: string;
        //#endregion deleted Prop


        //#region displayOrder Prop
        @prop()
        WorkPhoneNumber: string;
        //#endregion displayOrder Prop


        //#region createdOn Prop
        @prop()
        StreetAddress: string;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @prop()
        StreetAddress2: string;
        //#endregion updatedOn Prop


        //#region carats Prop      
        @prop()
        City: string;
        //#endregion carats Prop


        //#region cutType Prop
        @prop()
        CountryId: number;
        //#endregion cutType Prop


        //#region pricePerCarat Prop    
        //   @maxLength({ value: 18 })
        @prop()
        ZipPostalCode: string;
        //#endregion pricePerCarat Prop


        //#region video Prop
        @prop({ defaultValue: false })
        ContactPermissionRequired: boolean;
        //#endregion video Prop

}
