import { gridColumn } from '@rxweb/grid';

export class vSpecificRiskLookUpBase {

    //#region specificRiskId Prop
            @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'specificRiskId', keyColumn: true})
            specificRiskId : number;
    //#endregion specificRiskId Prop
    
    
    //#region specificRiskName Prop
            @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'specificRiskName', keyColumn: false})
            specificRiskName : string;
    //#endregion specificRiskName Prop
    
    }