import {vCustomerBookingBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';

@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 12, headerTitle: "Action"
})
export class vCustomerBooking extends vCustomerBookingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}