import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventPictureBase {

//#region eventPictureID Prop
        @prop()
        eventPictureID : number;
//#endregion eventPictureID Prop


//#region eventID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        eventID : number;
//#endregion eventID Prop


//#region pictureID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID : number;
//#endregion pictureID Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region comment Prop
        @maxLength({value:4000})
        comment : string;
//#endregion comment Prop





}