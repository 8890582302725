import {ProductOccassionBase,ProductOccassionMappingBase,} from '@app/database-models'
//Generated Imports
export class ProductOccassion extends ProductOccassionBase 
{




//#region Generated Reference Properties
//#region productOccassionMappings Prop
productOccassionMappings : ProductOccassionMappingBase[];
//#endregion productOccassionMappings Prop

//#endregion Generated Reference Properties




































}