import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractUser } from '../domain/abstract-user';
import { User } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { UserSearchFilterViewModel } from 'src/app/view-model/users-search-filter-view-model';
import { IFormGroup, RxFormBuilder, json } from '@rxweb/reactive-form-validators';
import { UsersSearchViewModel } from 'src/app/custom-templates/grid-view-model/users-search-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { PaginationEnum } from '../../../../enums/pagination.enums';
import { GridColumnConfig } from '@rxweb/grid';
import { CustomerRoleTypeEnum } from '../../../../enums/customer-role-type.enum';
import { StatusEnum } from 'src/app/enums/status.enum';
import { scroolOnSearchResult } from '../../../../domain/customize-plugin/bind-scroll-on-search';
import { importTimelineChart } from '../../../../domain/customize-plugin/timeline-chart';
import { checkDateMonth, dateCompareValidation, getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { GanttChart } from '../../../../domain/customize-plugin/gantt-chart';
import { ApplicationBroadcaster } from '../../../../temp-service/application-broadcaster'
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { SystemRatingEnum } from '../../../../enums/system-rating.enum';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { UserAddComponent } from '../add/user-add.component';
import { vSystemRatingLookUpBase } from '@app/database-models';
import * as moment from 'moment';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { NotesEnum } from 'src/app/enums/notes.enum';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { ImportUserFromExcelComponent } from '../details/import-user-from-excel/list/import-user-from-excel.component';
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@access({ accessLevel: RolePermissionEnums.User, action: "get" })
@multilingual("UserListComponent")
@Component({
  selector: "",
  templateUrl: './user-list.component.html'
})
export class UserListComponent extends AbstractUser implements OnInit, OnDestroy {
  usersSearchViewModel: UsersSearchViewModel[];
  usersSearchViewModel1: UsersSearchViewModel[];
  subscription: any;
  usersGridColumns: any[];
  emailLookup: any[];
  nameLookup: any[];
  searchString: string;
  resetCommonControl: boolean = true;
  resetNameControl: boolean = true;
  id: number = 0;
  usersGridDisplay: boolean = false;
  users: UserSearchFilterViewModel;
  userSearchFilterViewModelFormGroup: IFormGroup<UserSearchFilterViewModel>;
  isSearch: boolean = false;
  isFilter: boolean = false;
  totalRecords: number = 0;
  company: string;
  dateError: boolean;
  anniversarydateError: boolean;
  dateOfBirthdateError: boolean;
  dateOfBirthFromError: boolean;
  dOBFromError: boolean;
  dateOfBirthToError: boolean;
  monthOfBirthFromError: boolean;
  monthOfBirthToError: boolean;
  list: any[];
  isvisible: boolean = false;
  exportExcel = new ExportToExcelModel();
  sort: boolean;
  pagination: boolean;
  json: any;
  rowCount: number = 25;
  pageIndex: number = 1;
  ratingId: number = SystemRatingEnum.UnknownForwardSlashuncategorised;
  userAdd: any = UserAddComponent;
  systemRatingLookUp: vSystemRatingLookUpBase[];
  registrationFrom: Date;
  Roletypeid:number;
  isFromDashboard: boolean = false;
  private usersSubject: Subject<string> = new Subject<string>();
  searchName: string;
  searchCorporateName: string = "";
  minerTypeLookup: MinerTypeLookUp[];
  moduleID: number;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  titleHelp: string;
  titleExpand: string;
  titleCollapse: string;
  titleGridColumnSetting: string;
  showSystemHelpIcon: boolean = false;

  dateList: DateList[];
  monthList: MonthList[];
  dateListFrom: DateList[];
  showDateFrom: boolean = true;
  dateListTo: DateList[];
  showDateTo: boolean = true;

  isBirthday:boolean = true;
  anniversaryDateListFrom: DateList[];
  showAnniversaryDateFrom: boolean = true;
  anniversaryDateListTo: DateList[];
  showAnniversaryDateTo: boolean = true;

  anniversaryDateFromError: boolean;
  anniversaryDateToError: boolean;
  anniversaryMonthFromError: boolean;
  anniversaryMonthToError: boolean;
  isOrderCancelled:boolean;

  userId: number;
  userIdGo:number;
  msgError: boolean = false;

  _this;

  constructor(private applicationBroadcaster: ApplicationBroadcaster, router: Router, modelView: ModalView, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private title: Title,private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modelView;
    this.router = router;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'people', subMenu: 'users' })
    Window["UserListComponent"] = this;
    this.activatedRoute.queryParams.subscribe(t => {
      this.registrationFrom = t['registrationFrom'];
      this.isFromDashboard = t['isFromDashboard'];
      this.Roletypeid=t['Roleid']
      this.userId = t['userId'];
    });
    this.moduleID = RolePermissionEnums.User;
    this.monthList = new Array<MonthList>(
      { month: "Jan", id: 1 },
      { month: "Feb", id: 2 },
      { month: "Mar", id: 3 },
      { month: "Apr", id: 4 },
      { month: "May", id: 5 },
      { month: "Jun", id: 6 },
      { month: "Jul", id: 7 },
      { month: "Aug", id: 8 },
      { month: "Sep", id: 9 },
      { month: "Oct", id: 10 },
      { month: "Nov", id: 11 },
      { month: "Dec", id: 12 }
    );
    this.dateList = new Array<DateList>(
      { date: 1, id: 1 },
      { date: 2, id: 2 },
      { date: 3, id: 3 },
      { date: 4, id: 4 },
      { date: 5, id: 5 },
      { date: 6, id: 6 },
      { date: 7, id: 7 },
      { date: 8, id: 8 },
      { date: 9, id: 9 },
      { date: 10, id: 10 },
      { date: 11, id: 11 },
      { date: 12, id: 12 },
      { date: 13, id: 13 },
      { date: 14, id: 14 },
      { date: 15, id: 15 },
      { date: 16, id: 16 },
      { date: 17, id: 17 },
      { date: 18, id: 18 },
      { date: 19, id: 19 },
      { date: 20, id: 20 },
      { date: 21, id: 21 },
      { date: 22, id: 22 },
      { date: 23, id: 23 },
      { date: 24, id: 24 },
      { date: 25, id: 25 },
      { date: 26, id: 26 },
      { date: 27, id: 27 },
      { date: 28, id: 28 },
      { date: 29, id: 29 },
      { date: 30, id: 30 },
      { date: 31, id: 31 }
    );
    this.dateListFrom = this.dateList.slice(0, this.dateList.length);
    this.dateListTo = this.dateList.slice(0, this.dateList.length);
    this.anniversaryDateListFrom = this.dateList.slice(0, this.dateList.length);
    this.anniversaryDateListTo = this.dateList.slice(0, this.dateList.length);
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("People - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleExpand = getLocalizedValue("Title_Expand");
    this.titleCollapse = getLocalizedValue("Title_Collapse");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.spin = true;
    this.emailLookup = new Array<any>();
    this.nameLookup = new Array<any>();
    this.systemRatingLookUp = new Array<vSystemRatingLookUpBase>();
    this.showComponent = false;
    this.usersSubject.pipe(debounceTime(300)).subscribe(t => {
      this.userSearch();
    })
    this.lookup([
      { propName: "salesAdvisorLookUp", path: USER_LOOKUPS.salesUserLookup },
      { propName: "salesChannel", path: USER_LOOKUPS.salesChannel },
      { propName: "customerRoleLookUps", path: USER_LOOKUPS.customerRoleLookUp },
      { propName: "countryLookUp", path: USER_LOOKUPS.countryLookUp },
      { propName: "systemRatingLookUp", path: USER_LOOKUPS.systemRatingLookUp },
      { propName: "keyRelationshipHolderLookUp", path: USER_LOOKUPS.keyRelationshipHolderLookUp },
      { propName: "industryTypeLookUp", path: USER_LOOKUPS.industryTypeLookUp },
      //{ propName: "categoryLookUp", path: USER_LOOKUPS.categoryLookUp },
      { path: USER_LOOKUPS.titleLookUp, propName: "titleLookUp" },   //287714//06-06-2024/added Title Lookup same as working in add/edit form
      { propName: "parentCollectionLookup", path: USER_LOOKUPS.parentCollectionLookUp },
      {path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp"},
      { path: USER_LOOKUPS["getCollectionLookUps"], propName: "collectionLookup", params: [13] },
      { path: USER_LOOKUPS["designCategoryLookup"], propName: "categoryLookup" },
      { path: USER_LOOKUPS["fabergePurchasedLookUp"], propName: "fabergePurchasedLookUp" },
      { path: USER_LOOKUPS.nationalitylookUp, propName: "nationalityLookUp" },
    ]).subscribe((lookup: any) => {
      this.spin = false;
      this.users = new UserSearchFilterViewModel();
      this.users.pageIndex = PaginationEnum.PageIndex;
      this.users.rowCount = PaginationEnum.RowCount;
      // let newRatingObj=new vSystemRatingLookUpBase();
      // newRatingObj.ratingId=315;
      // newRatingObj.ratingName="Unknown/uncategorised";
      // this.systemRatingLookUp=lookup.systemRatingLookUp;
      // this.systemRatingLookUp.unshift(newRatingObj);
      this.collectionLookup = lookup.collectionLookup;
      this.categoryLookup = lookup.categoryLookup;
      this.titleLookUp = lookup.titleLookUp;     //287714//06-06-2024/added Title Lookup same as working in add/edit form
      this.minerTypeLookup = new Array<MinerTypeLookUp>();
      let item = new MinerTypeLookUp();
      item = new MinerTypeLookUp();
      item.id = 1;
      item.type = "Artisanal/Illegal Miner";
      this.minerTypeLookup.push(item);
      item = new MinerTypeLookUp();
      item.id = 2;
      item.type = "Trespasser";
      this.minerTypeLookup.push(item);

      this.userSearchFilterViewModelFormGroup = this.formBuilder.formGroup(this.users) as IFormGroup<UserSearchFilterViewModel>;
      this.userLookups = lookup;
      this.showComponent = true;
      if (this.isFromDashboard) {
        this.bindGrid();
      }

      if (this.userId != undefined) 
      {
          if(this.userLookups.salesAdvisorLookUp.some(x=>x.userId == this.userId))
          {
              this.userSearchFilterViewModelFormGroup.patchValue({
                salesAdvisor: this.userId
              });
          }
          if(this.userLookups.keyRelationshipHolderLookUp.some(x=>x.userId == this.userId))
          {
            this.userSearchFilterViewModelFormGroup.patchValue({
                keyRelationshipHolder: this.userId
            });
          }
          if(this.userSearchFilterViewModelFormGroup.value.salesAdvisor > 0 || this.userSearchFilterViewModelFormGroup.value.keyRelationshipHolder > 0)
            this.bindGrid();
      }

    })

  }

  bindGrid(page: number = 0) {
    this.spin = true;
    this.totalRecords = 0;

    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)) }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
    })

    if (this.isFromDashboard) {
      if (this.userSearchFilterViewModelFormGroup.controls["registrationFrom"].value == null) {
        let registrationDate = new Date(this.registrationFrom);
        registrationDate.setHours(0);
        registrationDate.setMinutes(registrationDate.getMinutes() + Math.abs(registrationDate.getTimezoneOffset()));
        registrationDate.setSeconds(0);
        this.userSearchFilterViewModelFormGroup.patchValue({
          registrationFrom: registrationDate
        })
      }
        this.userSearchFilterViewModelFormGroup.patchValue({
          roles:this.Roletypeid
        })

    }
    if (this.userSearchFilterViewModelFormGroup.controls["emailText"].value == null || this.userSearchFilterViewModelFormGroup.controls["email"].value > 0) {
      this.userSearchFilterViewModelFormGroup.patchValue({
        emailText: ''
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["nameText"].value == null && this.userSearchFilterViewModelFormGroup.controls["name"].value > 0) {
      this.userSearchFilterViewModelFormGroup.patchValue({
        nameText: ''
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["email"].value == '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        email: null
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["name"].value == '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        name: null
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["dateofBirthFrom"].value == '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        dateofBirth: null
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["dateofBirthTo"].value == '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        dateofBirth: null
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["anniversaryDateFrom"].value == '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        anniversaryDate: null
      })
    }
    if (this.userSearchFilterViewModelFormGroup.controls["anniversaryDateTo"].value == '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        anniversaryDate: null
      })
    }

    if (this.userSearchFilterViewModelFormGroup.value.corporateText == null || (this.userSearchFilterViewModelFormGroup.value.corporates > 0 && typeof this.userSearchFilterViewModelFormGroup.value.corporates === 'number')) {
      this.userSearchFilterViewModelFormGroup.patchValue({
        corporateText: ''
      })
    }

    if (this.userSearchFilterViewModelFormGroup.value.corporateText != '') {
      this.userSearchFilterViewModelFormGroup.patchValue({
        corporates: null
      });
    }
    this.isSearch = true;
    
    this.subscription = this.post({ body: { query: JSON.stringify(this.userSearchFilterViewModelFormGroup.value) }, path: "api/SearchUsers/UserSearch" }).subscribe((t: any) => {
      this.showComponent = true;
      setTimeout(() => {
        scroolOnSearchResult();
        this.spin = false;

        this.resetNameControl = false;
        setTimeout(() => {
          this.resetNameControl = true;
        }, 10);
        this.usersSearchViewModel = t;
        if (this.usersSearchViewModel.length > 0) {
          this.isNoRecordFound = false;
          this.totalRecords = this.usersSearchViewModel[0].totalCount;

          this.usersSearchViewModel.forEach(x => {
            if (x.companyDetails != null && x.companyDetails != undefined && x.companyDetails.length > 0) {
              x.company = this.formEmploymentTable(x);
            }
            if (x.directorshipDetails != null && x.directorshipDetails != undefined && x.directorshipDetails.length > 0) {
              x.directorship = this.formDirectorshipTable(x);
            }
          });
        }
        else {
          this.isNoRecordFound = true;
        }       
        if (!this.isFilter) {
          this.usersGrid = new AppGrid(this.usersSearchViewModel, UsersSearchViewModel, { actions: { onCheckBoxSelect: this.onCheckBoxSelect.bind(this), onDelete: this.onDelete.bind(this), onEdit: this.onUserInfoLink.bind(this), onOneLink: this.onUserInfoLink.bind(this), onMoreClickProfile: this.onMoreClickProfile.bind(this) } });

          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
              this.usersGrid.gridColumns.forEach(y => {
                if (y.name == x.ColumnName)
                  y.visible = x.Visible;
              });
            });
            this.gridColumnConfig = this.usersGrid.gridColumns;
            this.gridColumnSettings.emit(this.gridColumnConfig)
          }


          this.usersGrid.gridColumns.forEach(x => {
            if (this.usersGrid.authorize({ accessLevel: RolePermissionEnums.User, action: "put" }) && this.usersGrid.authorize({ accessLevel: RolePermissionEnums.User, action: "delete" })) {
              if (x.name == 'action') {
                x.visible = true;
              }
            }
            else {
              if (x.name == 'action') {
                x.visible = false;
              }
              if (x.name == "editAction") {
                x.visible = this.usersGrid.authorize({ accessLevel: RolePermissionEnums.User, action: "put" })
              }
              if (x.name == "deleteAction") {
                x.visible = this.usersGrid.authorize({ accessLevel: RolePermissionEnums.User, action: "delete" })
              }
            }
          })
          this.usersGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
        }
        else {
          this.usersGrid.storeProcedure.length = this.totalRecords;
          this.usersGrid.updateSource([]);
          this.usersGrid.updateSource(this.usersSearchViewModel);
        }       
        this.usersGrid.maxPerPage = this.userSearchFilterViewModelFormGroup.value.rowCount;
        this.usersGrid.componentId = this.componentName;
        this.usersGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.usersGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.applyClass();
        setTimeout(() =>{
          this.isSearch = this.isNoRecordFound ? false : true;
        },0)
      }, 0);
    })
    this.isFromDashboard = false;
  }
  onUserInfoLink(userInfo: UsersSearchViewModel, event) {
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["users", userInfo.userId], { queryParams: { activeTab: 'user-edit' } }));
    //this.router.navigate(["users", userInfo.userId], { queryParams: { activeTab: 'user-edit' } });
    redirectOnCTRL(url, this.router, event);
  }
  onKeyPress() {
    //this.userSearchFilterViewModelFormGroup.value.pageIndex = PaginationEnum.PageIndex;
    this.usersSubject.next();
  }

  goTo() {
        this.userSearchFilterViewModelFormGroup.submitted = true;
        if (this.userSearchFilterViewModelFormGroup.controls.userId.valid) {
                  this.userIdGo = this.userSearchFilterViewModelFormGroup.controls.userId.value;
                  if(this.userIdGo){
                    this.get({ path: 'api/SearchCommon/IsRecordExist', queryParams: {"id":this.userIdGo,"moduleId":NotesEnum.CustomerNotes} }).subscribe((data: any) => {
                      if(data){
                        this.router.navigate(['/users', this.userIdGo], { queryParams: { "activeTab": "user-edit" } });
                            }else{
                              this.msgError=true;
                            }
                      });
                  }else{
                    this.msgError=true;
                  }
                }
                else {
                    this.msgError = true;
                }
  }

  changeLayout(isBoolean: boolean) {
    this.isBirthday = isBoolean;
    this.userSearchFilterViewModelFormGroup.patchValue({
      isBirthdate: this.isBirthday
    })
    if(this.isBirthday){
        this.anniversarydateError = this.dOBFromError = this.monthOfBirthFromError = this.monthOfBirthToError = this.dateOfBirthdateError = this.dateError = this.dateOfBirthFromError = this.dateOfBirthToError = false
        this.userSearchFilterViewModelFormGroup.patchValue({
          birthDateFrom: this.userSearchFilterViewModelFormGroup.controls.anniversaryFromDate.value,
          birthDateTo: this.userSearchFilterViewModelFormGroup.controls.anniversaryToDate.value,
          birthMonthFrom: this.userSearchFilterViewModelFormGroup.controls.anniversaryMonthFrom.value,
          birthMonthTo: this.userSearchFilterViewModelFormGroup.controls.anniversaryMonthTo.value,
          anniversaryFromDate: undefined,
          anniversaryToDate: undefined,
          anniversaryMonthFrom: undefined,
          anniversaryMonthTo: undefined,
      })
      let birthMonthFrom = this.userSearchFilterViewModelFormGroup.controls.birthMonthFrom.value
      if(birthMonthFrom!=null && birthMonthFrom!= undefined){
        let event = {item:{key:birthMonthFrom}}
        this.setDateList(event, true)
      }
      let birthMonthTo = this.userSearchFilterViewModelFormGroup.controls.birthMonthTo.value
      if(birthMonthTo!=null && birthMonthTo!= undefined){
        let event = {item:{key:birthMonthTo}}
        this.setDateList(event, false)
      }
    }
    else{
        this.anniversarydateError = this.dOBFromError = this.monthOfBirthFromError = this.monthOfBirthToError = this.dateOfBirthdateError = this.dateError = this.dateOfBirthFromError = this.dateOfBirthToError = false
        this.userSearchFilterViewModelFormGroup.patchValue({
          anniversaryFromDate: this.userSearchFilterViewModelFormGroup.controls.birthDateFrom.value,
          anniversaryToDate: this.userSearchFilterViewModelFormGroup.controls.birthDateTo.value,
          anniversaryMonthFrom: this.userSearchFilterViewModelFormGroup.controls.birthMonthFrom.value,
          anniversaryMonthTo: this.userSearchFilterViewModelFormGroup.controls.birthMonthTo.value,
          birthDateFrom: undefined,
          birthDateTo: undefined,
          birthMonthFrom: undefined,
          birthMonthTo: undefined,
      })
      let anniversaryMonthFrom = this.userSearchFilterViewModelFormGroup.controls.anniversaryMonthFrom.value
      if(anniversaryMonthFrom!=null && anniversaryMonthFrom!= undefined){
        let event = {item:{key:anniversaryMonthFrom}}
        this.setAnniversaryDateList(event, true)
      }
      let anniversaryMonthTo = this.userSearchFilterViewModelFormGroup.controls.anniversaryMonthTo.value
      if(anniversaryMonthTo!=null && anniversaryMonthTo!= undefined){
        let event = {item:{key:anniversaryMonthTo}}
        this.setAnniversaryDateList(event, false)
      }
    }
  } 

  changeOrder(event:any){

    if(event.text=='Faberge'){
      this.isOrderCancelled=true
    }
    else{
      this.isOrderCancelled=false
    }    
  }

  userSearch() {
    this.anniversarydateError = this.dOBFromError = this.monthOfBirthFromError = this.monthOfBirthToError = this.dateOfBirthdateError = this.dateError = this.dateOfBirthFromError = this.dateOfBirthToError = false
    this.userSearchFilterViewModelFormGroup.patchValue({
      dateofBirthFrom: undefined,
      dateofBirthTo: undefined,
      anniversaryDateFrom: undefined,
      anniversaryDateTo: undefined
    })
    if (this.usersGrid) {
      this.isFilter = true;
      //this.usersGrid.refresh("users-Grid");
    }
    var dateFromError = checkDateMonth(this.userSearchFilterViewModelFormGroup.value.birthDateFrom, this.userSearchFilterViewModelFormGroup.value.birthMonthFrom);
    var dateToError = checkDateMonth(this.userSearchFilterViewModelFormGroup.value.birthDateTo, this.userSearchFilterViewModelFormGroup.value.birthMonthTo);
    var anniversaryDateFromError = checkDateMonth(this.userSearchFilterViewModelFormGroup.value.anniversaryFromDate, this.userSearchFilterViewModelFormGroup.value.anniversaryMonthFrom);
    var anniversaryDateToError = checkDateMonth(this.userSearchFilterViewModelFormGroup.value.anniversaryToDate, this.userSearchFilterViewModelFormGroup.value.anniversaryMonthTo);

    if ((dateFromError == 0 || dateFromError == 3) && (dateToError == 3 || dateToError == 0)) {
      if (dateFromError == 0) {
        var dataFrom = new Date();
        dataFrom.setFullYear(2020)
        dataFrom.setDate(this.userSearchFilterViewModelFormGroup.value.birthDateFrom);
        dataFrom.setMonth(this.userSearchFilterViewModelFormGroup.value.birthMonthFrom - 1);
        this.userSearchFilterViewModelFormGroup.patchValue({
          dateofBirthFrom: dataFrom
        })
      }
      if (dateToError == 0) {
        var dataTo = new Date();
        dataTo.setFullYear(2020)
        dataTo.setDate(this.userSearchFilterViewModelFormGroup.value.birthDateTo);
        dataTo.setMonth(this.userSearchFilterViewModelFormGroup.value.birthMonthTo - 1);
        this.userSearchFilterViewModelFormGroup.patchValue({
          dateofBirthTo: dataTo
        })
      }
      this.dateOfBirthFromError = this.dateOfBirthToError = this.monthOfBirthFromError = this.monthOfBirthToError = false;
    }
    this.dateOfBirthToError = dateToError == 1;
    this.monthOfBirthToError = dateToError == 2;
    this.dateOfBirthFromError = dateFromError == 1;
    this.monthOfBirthFromError = dateFromError == 2;
    this.msgError = false;
    this.userSearchFilterViewModelFormGroup.patchValue({
      userId:null
    })
    if ((anniversaryDateFromError == 0 || anniversaryDateFromError == 3) && (anniversaryDateToError == 3 || anniversaryDateToError == 0)) {
      if (anniversaryDateFromError == 0) {
        var dataFrom = new Date();
        dataFrom.setFullYear(2020)
        dataFrom.setDate(this.userSearchFilterViewModelFormGroup.value.anniversaryFromDate);
        dataFrom.setMonth(this.userSearchFilterViewModelFormGroup.value.anniversaryMonthFrom - 1);
        this.userSearchFilterViewModelFormGroup.patchValue({
          anniversaryDateFrom: dataFrom
        })
      }
      if (anniversaryDateToError == 0) {
        var dataTo = new Date();
        dataTo.setFullYear(2020)
        dataTo.setDate(this.userSearchFilterViewModelFormGroup.value.anniversaryToDate);
        dataTo.setMonth(this.userSearchFilterViewModelFormGroup.value.anniversaryMonthTo - 1);
        this.userSearchFilterViewModelFormGroup.patchValue({
          anniversaryDateTo: dataTo
        })
      }
      this.anniversaryDateFromError = this.anniversaryDateToError = this.anniversaryMonthFromError = this.anniversaryMonthToError = false;
    }
    this.anniversaryDateToError = anniversaryDateToError == 1;
    this.anniversaryMonthToError = anniversaryDateToError == 2;
    this.anniversaryDateFromError = anniversaryDateFromError == 1;
    this.anniversaryMonthFromError = anniversaryDateFromError == 2;


    if (dateCompareValidation(this.userSearchFilterViewModelFormGroup.value.registrationFrom, this.userSearchFilterViewModelFormGroup.value.registrationTo) == true) {
      this.dateError = true;
    }
    if (!this.dateOfBirthFromError && !this.dateOfBirthToError && !this.monthOfBirthFromError && !this.monthOfBirthToError) {
      if (this.userSearchFilterViewModelFormGroup.value.birthDateFrom) {
        if (this.userSearchFilterViewModelFormGroup.value.birthDateFrom.toString() != "") {
          if (dateCompareValidation(this.userSearchFilterViewModelFormGroup.value.dateofBirthFrom, this.userSearchFilterViewModelFormGroup.value.dateofBirthTo) == true) {
            this.dateOfBirthdateError = true;
          }
        }
        else {
          if (this.userSearchFilterViewModelFormGroup.value.birthDateTo) {
            if (this.userSearchFilterViewModelFormGroup.value.birthDateTo.toString() != "") {
              var dataFrom = new Date();
              dataFrom.setDate(1);
              dataFrom.setMonth(0);
              this.userSearchFilterViewModelFormGroup.patchValue({
                dateofBirthFrom: dataFrom
              });
            }
          }
        }
      }
      else {
        if (this.userSearchFilterViewModelFormGroup.value.birthDateTo) {
          if (this.userSearchFilterViewModelFormGroup.value.birthDateTo.toString() != "") {
            var dataFrom = new Date();
            dataFrom.setDate(1);
            dataFrom.setMonth(0);
            this.userSearchFilterViewModelFormGroup.patchValue({
              dateofBirthFrom: dataFrom
            });
          }
        }
      }
    }
    if (!this.anniversaryDateFromError && !this.anniversaryDateToError && !this.anniversaryMonthFromError && !this.anniversaryMonthToError) {
      if (this.userSearchFilterViewModelFormGroup.value.anniversaryFromDate) {
        if (this.userSearchFilterViewModelFormGroup.value.anniversaryFromDate.toString() != "") {
          if (dateCompareValidation(this.userSearchFilterViewModelFormGroup.value.anniversaryDateFrom, this.userSearchFilterViewModelFormGroup.value.anniversaryDateTo) == true) {
            this.anniversarydateError = true;
          }
        }
        else {
          if (this.userSearchFilterViewModelFormGroup.value.anniversaryToDate) {
            if (this.userSearchFilterViewModelFormGroup.value.anniversaryToDate.toString() != "") {
              var dataFrom = new Date();
              dataFrom.setDate(1);
              dataFrom.setMonth(0);
              this.userSearchFilterViewModelFormGroup.patchValue({
                anniversaryDateFrom: dataFrom
              });
            }
          }
        }
      }
      else {
        if (this.userSearchFilterViewModelFormGroup.value.anniversaryToDate) {
          if (this.userSearchFilterViewModelFormGroup.value.anniversaryToDate.toString() != "") {
            var dataFrom = new Date();
            dataFrom.setDate(1);
            dataFrom.setMonth(0);
            this.userSearchFilterViewModelFormGroup.patchValue({
              anniversaryDateFrom: dataFrom
            });
          }
        }
      }
    }
    // if (dateCompareValidation(this.userSearchFilterViewModelFormGroup.value.anniversaryDateFrom, this.userSearchFilterViewModelFormGroup.value.anniversaryDateTo) == true) {
    //   this.anniversarydateError = true;
    // }
    if (
      !this.anniversarydateError &&
      !this.anniversaryDateFromError &&
      !this.anniversaryDateToError &&
      !this.anniversaryMonthFromError &&
      !this.anniversaryMonthToError &&
      !this.dateOfBirthdateError &&
      !this.dateError &&
      !this.dateOfBirthFromError &&
      !this.dateOfBirthToError &&
      !this.monthOfBirthFromError &&
      !this.monthOfBirthToError &&
      !this.dOBFromError
    ) {
      this.dateOfBirthdateError = false;
      this.anniversarydateError = false;
      this.dateError = false;
      if (this.usersGrid != null) {
        this.isFilter = true;
        this.usersGrid.updateSource([]);
        this.usersGrid.storeProcedure.nextPage = 1;
        this.userSearchFilterViewModelFormGroup.value.rowCount = this.usersGrid.maxPerPage;
      }
      this.userSearchFilterViewModelFormGroup.value.pageIndex = 1;
      this.bindGrid();
    }
  }

  formEmploymentTable(item: UsersSearchViewModel): any {
    let tabledata: any;
    let row: string = "";
    let subRow: any = "";
    tabledata = "<div><table class='w-100'><tbody>";
    let subTable: any;
    subTable = "<tr id='employment-row-" + item.userId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
    if (item.companyDetails != null && item.companyDetails != undefined) {
      if (item.companyDetails.length > 1) {
        for (let i = 0; i < item.companyDetails.length; i++) {
          let x = item.companyDetails[i];
          if (i == 0) {
            row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.jobTitle} ${x.companyStatus}<i title="${this.titleExpand}" class='float-right fa fa-chevron-down ml-2' id='icon-employment-${item.userId}' onclick='Window.UserListComponent.expandEmploymentTable("${item.userId}")'></i></td></tr>`;
          }
          else {
            subRow = subRow + `<tr><td><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.jobTitle} ${x.companyStatus}</td></tr>`;
          }
        }
      }
      else if (item.companyDetails.length == 1) {
        let x = item.companyDetails[0];
        row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.jobTitle} ${x.companyStatus}</td></tr>`;
      }
      if (subRow != "") {
        subTable = subTable + subRow + "</tbody></table></div></td></tr>";
        row = row + subTable;
      }
      tabledata = tabledata + row + "</tbody></table></div>"
    }
    return tabledata;
  }

  formDirectorshipTable(item: UsersSearchViewModel): any {
    let tabledata: any;
    let row: string = "";
    let subRow: any = "";
    tabledata = "<div><table class='w-100'><tbody>";
    let subTable: any;
    subTable = "<tr id='directorship-row-" + item.userId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
    if (item.directorshipDetails != null && item.directorshipDetails != undefined) {
      if (item.directorshipDetails.length > 1) {
        for (let i = 0; i < item.directorshipDetails.length; i++) {
          let x = item.directorshipDetails[i];
          if (i == 0) {
            row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.companyStatus}<i title="${this.titleExpand}" class='float-right fa fa-chevron-down ml-2' id='icon-directorship-${item.userId}' onclick='Window.UserListComponent.expandDirectorshipTable("${item.userId}")'></i></td></tr>`;
          }
          else {
            subRow = subRow + `<tr><td><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.companyStatus}</td></tr>`;
          }
        }
      }
      else if (item.directorshipDetails.length == 1) {
        let x = item.directorshipDetails[0];
        row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.companyStatus}</td></tr>`;
      }
      if (subRow != "") {
        subTable = subTable + subRow + "</tbody></table></div></td></tr>";
        row = row + subTable;
      }
      tabledata = tabledata + row + "</tbody></table></div>"
    }
    return tabledata;
  }

  expandEmploymentTable(id: number) {
    var element = document.getElementById("employment-row-" + id.toString());
    var iconElement = document.getElementById("icon-employment-" + id.toString());
    if (element != null) {
      if (element.style.display == "none") {
        element.style.display = "block";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-up ml-2"
          iconElement.title = this.titleCollapse;
        }

      }
      else {
        element.style.display = "none";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-down ml-2"
          iconElement.title = this.titleExpand;
        }

      }
    }
  }

  expandDirectorshipTable(id: number) {
    var element = document.getElementById("directorship-row-" + id.toString());
    var iconElement = document.getElementById("icon-directorship-" + id.toString());
    if (element != null) {
      if (element.style.display == "none") {
        element.style.display = "block";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-up ml-2"
          iconElement.title = this.titleCollapse;
        }

      }
      else {
        element.style.display = "none";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-down ml-2"
          iconElement.title = this.titleExpand;
        }

      }
    }
  }

  onNameClick($event) {
    this.userSearchFilterViewModelFormGroup.patchValue({
      nameText: ''
    })
    this.searchName = $event.item.value;
  }
  checkNameText(event: any) {

    this.userSearchFilterViewModelFormGroup.patchValue({
      nameText: event.target.value
    })
    this.searchName = event.target.value;
    // let json:any=JSON.parse(JSON.stringify({searchString:"",lookupType:"users"}));

    // if(!(event.keyCode>=37 && event.keyCode<=40) && event.keyCode!=9 && event.keyCode!=32){
    //   this.nameLookup=[];
    //   this.removeActive("nameDropdown");
    //   this.userSearchFilterViewModelFormGroup.patchValue({
    //     nameText: event.target.value
    //   })
    //   if(this.userSearchFilterViewModelFormGroup.value.nameText.length<=2){
    //     this.nameLookup=[];
    //   }
    //     if((event.target.value.replace(/ /g,'').length>2 && event.target.value!=undefined && event.target.value.replace(/ /g,'')!="") || (event.keyCode==8 && event.target.value=='')){
    //       json['searchString']=event.target.value;
    //       this.post({path:'api/SearchEvent/GetLookups',body:json}).subscribe((res:any[])=>{
    //         this.nameLookup=res;
    //        var nameElement= document.getElementById("nameDropdown");
    //        if(nameElement!=null){
    //         nameElement.getElementsByClassName("chosen-container")[0].className+="chosen-with-drop chosen-container-active";
    //        }
    //        })
    //     }
    // }
  }


  checkEmailText(event: any) {
    this.userSearchFilterViewModelFormGroup.patchValue({
      emailText: event.target.value,
    });

  }

  checkCorpText(event: any) {
    this.userSearchFilterViewModelFormGroup.patchValue({
      corporateText: event.target.value
    })
    this.searchCorporateName = event.target.value;
  }
  onCorpClick($event) {

    this.userSearchFilterViewModelFormGroup.patchValue({
      corporateText: '',
    })
    this.searchCorporateName = $event.item.value;
  }

  // checkEmailText(event: any) {
  //   let json:any=JSON.parse(JSON.stringify({searchString:"",lookupType:"userEmail"}));

  //   if(!(event.keyCode>=37 && event.keyCode<=40) && event.keyCode!=9 && event.keyCode!=32)
  //   {
  //   this.emailLookup=[];
  //   this.removeActive("emailDropdown");
  //   this.userSearchFilterViewModelFormGroup.patchValue({
  //     emailText: event.target.value
  //   })
  //   if(this.userSearchFilterViewModelFormGroup.value.emailText.length<=2){
  //     this.emailLookup=[];
  //   }
  //     if((event.target.value.replace(/ /g,'').length>2 && event.target.value!=undefined && event.target.value.replace(/ /g,'')!="") || (event.keyCode==8 && event.target.value=='')){
  //      json['searchString']=event.target.value;
  //     // this.removeOptions("emailDropdown");
  //      setTimeout(()=>{
  //               this.post({path:'api/SearchEvent/GetLookups',body:json}).subscribe((res:any[])=>{
  //                 this.emailLookup=[];
  //                 this.emailLookup=res;
  //                 var email= document.getElementById("emailDropdown");
  //                 if(email!=null){
  //                   email.getElementsByClassName("chosen-container")[0].className+="chosen-with-drop chosen-container-active";
  //                 }
  //                 })
  //         },2000)
  //     }
  //   }
  // if(this.userSearchFilterViewModelFormGroup.value.emailText.length>2){
  //   this.get({path:'api/SearchEvent/GetLookups',params:['userEmail',event.target.value]}).subscribe((res:any[])=>{

  //     this.emailLookup=res;
  //    var email= document.getElementById("emailDropdown");
  //     email.getElementsByClassName("chosen-container")[0].className+="chosen-with-drop chosen-container-active";


  // })
  // }
  //}




  removeActive(elementId: string) {
    var dropdown = document.getElementById(elementId);
    if (dropdown != null) {
      dropdown.getElementsByClassName("chosen-container chosen-with-drop chosen-container-active")[0].className = "chosen-container";
    }
  }

  removeOptions(selectElementId: string) {
    var element = document.getElementById(selectElementId) as HTMLSelectElement;
    var i, L = element.options.length - 1;
    if (L > 0) {
      for (i = L; i >= 1; i--) {
        element.remove(i);
      }
    }
  }


  resetSearch() {
    this.spin = true;
    this.msgError = false;
    this.userSearchFilterViewModelFormGroup.patchValue({
      industryType: 0,
      salesChannel: 0,
      salesAdvisor: 0,
      roles: 0,
      country: 0,
      //this.rating = 0;
      keyRelationshipHolder: 0,
      arrested: false,
      juvenile: false,
      isCancelledOrder:false,
      artisanalIllegalMiner: 0,
      cateogry: "",
      collection: "",
      registrationFrom: null,
      hasPurchasedFrom: 0,
      userId:null
    })
    this.searchName = '';
    this.searchCorporateName = '';
    this.showComponent = false;
    this.resetNameControl = false;
    this.resetCommonControl = false;
    this.isOrderCancelled = false;
    this.isBirthday = true;
    this.isNoRecordFound = false;
    setTimeout(function () {
      this.resetCommonControl = true;
      this.resetNameControl = true;
    }.bind(this), 50);
        if(this.usersGrid && this.usersGrid.source.length > 0){
            this.usersGrid.destroy();
        }
        if(this.usersGrid){
            this.usersGrid = null;
            this.isFilter = false;
        }
        this.isSearch = false;
        this.userSearchFilterViewModelFormGroup.reset();
        setTimeout(() => {
            this.showComponent = true;
            this.spin = false;
        }, 100);
  }





  clearGrid() {
    this.usersGrid.refresh("users")
  }
  applyClass() {
    this.usersGrid.designClass.rowClass = [function () {
      let data = this;
      if (data.roleId == CustomerRoleTypeEnum.Admin) {
        return "admin";
      }
      else {
        return "customer";
      }
    }]
  }
  // onPageSorting(x, y, z) {
  //   this.isFilter = true;
  //   //this.sort=!this.sort;
  //   this.userSearchFilterViewModelFormGroup.patchValue({ sortOrder: y, orderBy: x })
  //   this.usersGrid.storeProcedure.nextPage = z;
  //   //this.usersGrid.refresh("users-Grid");
  //   this.bindGrid();
  // }

  // onPageChanging(x) {
  //   this.isFilter = true;
  //   // this.pagination=!this.pagination;
  //   this.userSearchFilterViewModelFormGroup.patchValue({ pageIndex: x, rowCount: this.usersGrid.maxPerPage })
  //   this.usersGrid.storeProcedure.nextPage = x;
  //   //this.usersGrid.refresh("users-Grid");
  //   this.bindGrid();

  // }
  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.userSearchFilterViewModelFormGroup.patchValue({ sortOrder: y, orderBy: x, pageIndex: z })
    this.usersGrid.storeProcedure.nextPage = z;

    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.userSearchFilterViewModelFormGroup.patchValue({ pageIndex: x, rowCount: this.usersGrid.maxPerPage })
    this.usersGrid.storeProcedure.nextPage = x;

    this.bindGrid();
  }
  onEdit(user: UsersSearchViewModel) {
    this.router.navigate(["users", user.userId], { queryParams: { "activeTab": "user-edit" } });
  }

  onDelete(user: UsersSearchViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [user.userId] }).subscribe(data => {
          this.spin = false;
          this.totalRecords = 0;
          // this.usersGrid.updateSource([]);
          this.isFilter = true;
          // this.usersGrid.refresh("users-Grid");
          this.bindGrid();
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
        })
      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_User");
    if (existsAlert) {
      return existsAlert;
    }
  }

  // search(value) {
  //   setTimeout(() => {
  //     this.spin = false;
  //     this.isFilter = true;
  //     this.userSearchFilterViewModelFormGroup.patchValue({ pageIndex: 1, rowCount: this.usersGrid.maxPerPage, searchString: value.target.value.replace(/^\s+|\s+$/gm, '').replace('&', '%26') })
  //     this.bindGrid();
  //   }, 1000);
  //   this.spin = true;
  // }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.usersSearchViewModel.forEach(x => {
      if (x.companyDetails != null && x.companyDetails != undefined && x.companyDetails.length > 0) {
        x.company = this.formEmploymentTable(x);
      }
      if (x.directorshipDetails != null && x.directorshipDetails != undefined && x.directorshipDetails.length > 0) {
        x.directorship = this.formDirectorshipTable(x);
      }
    });
    this.usersGrid.reDesign();
    this.usersGrid.updateSource([])
    this.usersGrid.updateSource(this.usersSearchViewModel);
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  onCheckBoxSelect(data, x, y, z) {
  }

  // exportToExcel() {
  //   this.spin = true;
  //   this.exportExcel.FileName = "Users.xlsx";
  //   this.exportExcel.Type = "Excel";
  //   let returnedRequestData = this.requestExcelData(this.list);
  //   this.exportExcel.Result = JSON.stringify(returnedRequestData);
  //   return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
  //     this.downloadDocument(Response, "Users", "application/vnd.ms-excel");
  //     this.spin = false;
  //   }, error => {
  //     this.spin = false;
  //   })
  // }
  exportToExcel() {
    this.spin = true;
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    this.exportExcel.FileName = "Users" + MMDDYY;
    this.exportExcel.Type = "Excel";
    this.exportExcel.EntityType = NotesEnum.CustomerNotes;
    this.exportExcel.Description = "People";
    var json = JSON.parse(JSON.stringify(this.userSearchFilterViewModelFormGroup.value));
    json["pageIndex"] = PaginationEnum.PageIndex;
    json["rowCount"] = this.totalRecords
    this.post({ body: { query: JSON.stringify(json) }, path: "api/SearchUsers/UserSearch" }).subscribe((t: any) => {
      this.usersSearchViewModel = t
      let returnedRequestData = this.requestExcelData(this.usersSearchViewModel, this.usersGrid.gridColumns);
      this.exportExcel.Result = JSON.stringify(returnedRequestData);
      return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
        this.downloadDocument(Response, this.exportExcel.FileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        this.spin = false;
      }, error => {
        this.spin = false;
      })
    });
  }
  requestExcelData(usersGrid: any[], gridColumns: any[]) {
    var listObject = usersGrid.map(function (obj) {
      if (gridColumns != undefined && gridColumns != null) {
        var objNew: any = {}
        var isNameAdded = false;
        gridColumns.forEach(e => {
           
          if(e.visible && e.name == 'title'){
            objNew[this.getGridColumnName(e.name)] = obj[e.name];
          }
          
          if(!isNameAdded && e.name == "name"){
            this.ExcelColumns.forEach(e => {
              if (e == "exportName") {
                objNew[this.getGridColumnName(e)] = obj[e];
              }
   
            }, this);
            isNameAdded = true;
          }

          if (e.visible) {
            if (e.name == "company" || e.name == "directorship") {
              var activeSpace = (obj[e.name]).replace(/- Active/g, "- Active, ");
              var formerSpace = (activeSpace).replace(/- Former/g, "- Former, ");
              var lastCommaIndex = formerSpace.lastIndexOf(",");
              var value = formerSpace.substring(0, lastCommaIndex);
              objNew[this.getGridColumnName(e.name)] = value;
            }
            else {
              //var name = e.name == "isVerified" ? "verified" : e.name;
              var name = e.name =="isVerified" ? "verified" : (e.name =="rating" ? "ratingName" : e.name);
              if(e.name != "name" && e.name != 'title')
              {
              objNew[this.getGridColumnName(e.name)] = obj[name];
              }
            }

          }
        }, this);
        // this.ExcelColumns.forEach(e => {
        //   if (e == "ratingName") {
        //     objNew[this.getGridColumnName(e)] = obj[e];
        //   }
        // }, this);

        return objNew;
      } else {
        return obj;
      }
    }, this)
    var list = new List<any>(listObject);
    return list;
    // let userListData = new List<UserListData>();
    // usersGrid.forEach(element => {
    //   let listData = new UserListData();
    //   listData["Name"] = element.name;
    //   listData["Email"] = element.email;
    //   listData["Verified"] = element.verified;
    //   listData["Registration Date"] = element.stRegistrationDate;
    //   // this.company = (element.company).replace(/<[^>]*>/g, '');
    //   //this.company.replace("Active","Active ").replace("Former","Former ").replace("Unknown","Unknown ");
    //   //listData["Corporate/Groups"] = (element.company).replace(/<[^>]*>/g, '');
    //   if (element.company != null) {
    //     var activeSpace = (element.company).replace(/- Active/g, "- Active, ");
    //     var formerSpace = (activeSpace).replace(/- Former/g, "- Former, ");
    //     var lastCommaIndex = formerSpace.lastIndexOf(",");
    //     var comapny = formerSpace.substring(0, lastCommaIndex);
    //   }
    //   else {
    //     var comapny = element.company
    //   }
    //   listData["Corporate/Groups"] = comapny
    //   listData["Country"] = element.countryName;
    //   listData["Has Purchased From"] = element.hasPurchasedFrom;
    //   listData["Rating"] = element.ratingName;
    //   listData["Sales Advisor"] = element.salesAdversierName;
    //   listData["Key Relationship Holder"] = element.keyRelationshipHolder;

    //   userListData.add(listData);
    // });
    // return userListData;
  }
  getGridColumnName(key: string) {
    var localizationData = (MultiLingualData.get(this.componentName) as any);
    switch (key) {
      case "title":
        return localizationData.UserListComponent_titleName_ex_ex;
      case "exportName":
        return localizationData.UserListComponent_Name_ex_ex;
      case "email":
        return localizationData.UserListComponent_Email_ex_ex;
      case "company":
        return localizationData.UserListComponent_CorporatesOrGroups_ex_ex;
      case "directorship":
        return localizationData.UserListComponent_Directorships_ex_ex;
      case "departmentName":
        return localizationData.UserListComponent_Department_ex_ex;
      case "salesAdversierName":
        return localizationData.UserListComponent_SalesAdversierName_ex_ex;
      case "keyRelationshipHolder":
        return localizationData.UserListComponent_KeyRelationshipHolder_ex_ex;
      case "isVerified":
        return localizationData.UserListComponent_IsVerified_ex_ex;
      case "stRegistrationDate":
        return localizationData.UserListComponent_RegistrationDate_ex_ex;
      case "countryName":
        return localizationData.UserListComponent_CountryName_ex_ex;
      case "hasPurchasedFrom":
        return localizationData.UserListComponent_HasPurchased_ex_ex;
      case "rating":
        return localizationData.UserListComponent_Rating_ex_ex;
      case "dateOfBirth":
        return localizationData.UserListComponent_DateOfBirth_ex_ex;
      case "anniversaryDate":
        return localizationData.UserListComponent_AnniversaryDate_ex_ex;
      case "city":
        return localizationData.UserListComponent_City_ex_ex;
      case "jobTitle":
        return localizationData.UserListComponent_JobTitle_ex_ex;
      case "workPhoneNumber":
        return localizationData.UserListComponent_WorkPhoneNumber_ex_ex;
        case "profile":
        return localizationData.UserListComponent_profile_ex_ex;
      default:
        return ""
    }
  }
  public downloadDocument(data: any, fileName: string, fileType: string) {
    var binaryString = window.atob(data.result.result);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var link = document.createElement('a');
    var blob = new Blob([bytes], { type: fileType });
    link.href = window.URL.createObjectURL(blob);
    link.download = data.result.fileName;
    document.body.appendChild(link);
    link.click();

  }
  get componentName(): string {
    return "UserListComponent";
  }

  ExcelColumns = [
    'exportName',
    'ratingName'
  ]

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }
  setDateList($event, dateFrom: boolean) {
    if ($event.item)
      switch ($event.item.key) {
        case 4:
        case 6:
        case 9:
        case 11:
          if (dateFrom) {
            this.showDateFrom = false;
            setTimeout(() => {
              this.dateListFrom = this.dateList.slice(0, this.dateList.length - 1);
              this.showDateFrom = true;
            }, 100);
          }
          else {
            this.showDateTo = false;
            setTimeout(() => {
              this.dateListTo = this.dateList.slice(0, this.dateList.length - 1);
              this.showDateTo = true;
            }, 100);
          }
          break;
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          if (dateFrom) {
            this.showDateFrom = false;
            setTimeout(() => {
              this.dateListFrom = this.dateList.slice(0, this.dateList.length);
              this.showDateFrom = true;
            }, 100);
          }
          else {
            this.showDateTo = false;
            setTimeout(() => {
              this.dateListTo = this.dateList.slice(0, this.dateList.length);
              this.showDateTo = true;
            }, 100);
          }
          break;
        case 2:
          if (dateFrom) {
            this.showDateFrom = false;
            setTimeout(() => {
              this.dateListFrom = this.dateList.slice(0, this.dateList.length - 2);
              this.showDateFrom = true;
            }, 100);
          }
          else {
            this.showDateTo = false;
            setTimeout(() => {
              this.dateListTo = this.dateList.slice(0, this.dateList.length - 2);
              this.showDateTo = true;
            }, 100);
          }
          break;
        default:
          break;
      }
  }
  onMoreClickProfile(t, x) {
    debugger;
    var element = document.getElementById("profile_" + t.userId);
    var maskedElement = document.getElementById("maskedprofile_" + t.userId);
    if (element.className == "collapse") {
        x.srcElement.innerText = "Show Less";
        x.srcElement.className = "less-btn"
        element.classList.add("show");
        maskedElement.setAttribute("style", "display:none");
    }
    else {
        element.classList.remove("show");
        x.srcElement.innerText = "Show More";
        x.srcElement.className = "more-btn";
        maskedElement.setAttribute("style", "display:inline");
    }
}
  setAnniversaryDateList($event, dateFrom: boolean) {
    if ($event.item)
      switch ($event.item.key) {
        case 4:
        case 6:
        case 9:
        case 11:
          if (dateFrom) {
            this.showAnniversaryDateFrom = false;
            setTimeout(() => {
              this.anniversaryDateListFrom = this.dateList.slice(0, this.dateList.length - 1);
              this.showAnniversaryDateFrom = true;
            }, 100);
          }
          else {
            this.showAnniversaryDateTo = false;
            setTimeout(() => {
              this.anniversaryDateListTo = this.dateList.slice(0, this.dateList.length - 1);
              this.showAnniversaryDateTo = true;
            }, 100);
          }
          break;
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          if (dateFrom) {
            this.showAnniversaryDateFrom = false;
            setTimeout(() => {
              this.anniversaryDateListFrom = this.dateList.slice(0, this.dateList.length);
              this.showAnniversaryDateFrom = true;
            }, 100);
          }
          else {
            this.showAnniversaryDateTo = false;
            setTimeout(() => {
              this.anniversaryDateListTo = this.dateList.slice(0, this.dateList.length);
              this.showAnniversaryDateTo = true;
            }, 100);
          }
          break;
        case 2:
          if (dateFrom) {
            this.showAnniversaryDateFrom = false;
            setTimeout(() => {
              this.anniversaryDateListFrom = this.dateList.slice(0, this.dateList.length - 2);
              this.showAnniversaryDateFrom = true;
            }, 100);
          }
          else {
            this.showAnniversaryDateTo = false;
            setTimeout(() => {
              this.anniversaryDateListTo = this.dateList.slice(0, this.dateList.length - 2);
              this.showAnniversaryDateTo = true;
            }, 100);
          }
          break;
        default:
          break;
      }
  }

  importExcel() {
    this.modalView.show(ImportUserFromExcelComponent, { tripId: this.id }).then(t => {

    });
  }

}

class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
  EntityType: number;
  Description: string;
  BlobFileName:string;
}
class UserListData {
  "Name": string;
  "Email": string;
  "Verified": string;
  "Registration Date": string;
  "Corporate/Groups": string;
  "Country": string;
  "Rating": string;
  "Active": string;
  "Sales Advisor": string;
  "Key Relationship Holder": string;
  "Has Purchased From": string;
}
class MinerTypeLookUp {
  id: number;
  type: string;
}

class DateList {
  date: number;
  id: number;
}
class MonthList {
  month: string;
  id: number;
}
