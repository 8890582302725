import {ITIncCategoryBase,} from '@app/database-models'
//Generated Imports
export class ITIncCategory extends ITIncCategoryBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}