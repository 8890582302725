import { TemplateConfig } from '@rxweb/grid';


export const BIND_RADIOBUTTON_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["i-checks", "iradio_square-green"],
                style: {
                  "margin-top": "12px"
                },
                event: {
                  input: "onRadioButtonChecked"
                },
                attributes: {
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}


