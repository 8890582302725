import { TemplateConfig } from '@rxweb/grid';
import { CategoryEnum } from "../../enums/categories-type.enum";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const ACTION_GRID_TEMPLATE_FLOPPY: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          authorize: "edit",
          event: {
            click: "onEdit"
          },
          childrens: [
            {
              i: {
                class: "fa fr-save fa-4x font-md text-default".split(" "),
                attributes: { "title": getLocalizedValue("Title_Save") }
              }
            }
          ],
          class: ["mr-3"]
        }
      },
      {
        a: {
          authorize: "delete",
          event:
          {
            click: "onDelete"
          },
          childrens:
            [{
              i: {
                class: "fr-delete fa-4x font-md text-danger".split(" "),
                attributes: { "title": getLocalizedValue("Title_Delete") }
              }
            }]
        }
      }
    ]
  }
}

export const ACTION_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.desktopAssetId != null || this.desktopAssetId != undefined) {
              return "/desktop-assets/" + this.desktopAssetId+ "?activeTab=edit-info";
            }
            if (this.MobileAssetId != null || this.MobileAssetId != undefined) {
              return "/mobile-assets/" + this.MobileAssetId+ "?activeTab=edit-info";
            }
            if (this.assetManufacturerId != null || this.assetManufacturerId != undefined) {
              return "/it-asset-manufacturer/" + this.assetManufacturerId;
            }
            if (this.assetLocationId != null || this.assetLocationId != undefined) {
              return "/it-asset-location/" + this.assetLocationId;
            }
             if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
              return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
            }
            if (this.UserId != null || this.UserId != undefined) {
              return "/users/" + this.UserId + "?activeTab=user-edit";
            }
            if (this.adhocId != null || this.adhocId != undefined) {
              return "/customised-pages/" + this.adhocId + "?activeTab=adhoc-pages";
            }
            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.companyId != null || this.companyId != undefined) {
              return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
            }
            if (this.CompanyID != null || this.CompanyID != undefined) {
              return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
            }
            if (this.watchStatusId != null || this.watchStatusId != undefined) {
              return "/watch-status/" + this.watchStatusId
            }
            if ((this.legalOwnerId != null || this.legalOwnerId != undefined)) {
              return "/legal-owner/" + this.legalOwnerId
            }
            if ((this.watchItemTypeId != null || this.watchItemTypeId != undefined)) {
              return "/watch-item-types/" + this.watchItemTypeId
            }
            if ((this.watchMaterialId != null || this.watchMaterialId != undefined)) {
              return "/watch-materials/" + this.watchMaterialId
            }
            if (this.clarityId != null || this.clarityId != undefined) {
              return "/gem-stone-clarity-gemfields/" + this.clarityId
            }
            if (this.enhancementId != null || this.enhancementId != undefined) {
              return "/gemfields-enhancement/" + this.enhancementId
            }
            if (this.cutTypeId != null || this.cutTypeId != undefined) {
              return "/gem-stone-cut-types/" + this.cutTypeId
            }
            if (this.materialId != null || this.materialId != undefined) {
              return "/materials/" + this.materialId
            }
            if (this.fabergeColorId != null || this.fabergeColorId != undefined) {
              return "/colors/" + this.fabergeColorId
            }
            if (this.carrierId != null || this.carrierId != undefined) {
              return "/gemfields-carrier/" + this.carrierId
            }
            if (this.colorId != null || this.colorId != undefined) {
              return "/gem-stone-colors/" + this.colorId
            }
            if (this.locationId != null || this.locationId != undefined) {
              return "/gemfields-location/" + this.locationId
            }
            if (this.arrangementId != null || this.arrangementId != undefined) {
              return "/gemfields-arrangements/" + this.arrangementId
            }
            if (this.productTypeId != null || this.productTypeId != undefined) {
              return "/product-types/" + this.productTypeId
            }
            if (this.temporaryAssigneeTypeID != null || this.temporaryAssigneeTypeID != undefined) {
              return "/temporary-assignee-types/" + this.temporaryAssigneeTypeID
            }
            if (this.stationId != null || this.stationId != undefined) {
              return "/gemfields-station/" + this.stationId
            }
            if (this.enhancementTypeId != null || this.enhancementTypeId != undefined) {
              return "/gemfields-enhancement-type/" + this.enhancementTypeId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/gemfield-sales-channels/" + this.salesChannelId
            }
            if (this.gemFieldsBookingStatusId != null || this.gemFieldsBookingStatusId != undefined) {
              return "/gem-fields-booking-statuses/" + this.gemFieldsBookingStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "true") {
              return "/holiday-lists/" + this.holidayId
            }
            if (this.messageTemplateID != null || this.messageTemplateID != undefined) {
              return "/message-templates/" + this.messageTemplateID
            }
            if (this.emailAccountId != null || this.emailAccountId != undefined) {
              return "/email-account/" + this.emailAccountId
            }
            if (this.bannedIpNetworkId != null || this.bannedIpNetworkId != undefined) {
              return "/banned-ip-network/" + this.bannedIpNetworkId
            }
            if (this.bannedIpAddressID != null || this.bannedIpAddressID != undefined) {
              return "/banned-ip-addresses/" + this.bannedIpAddressID
            }
            if (this.measureDimensionId != null || this.measureDimensionId != undefined) {
              return "/measure-dimensions/" + this.measureDimensionId
            }
            if (this.measureWeightId != null || this.measureWeightId != undefined) {
              return "/measure-weights/" + this.measureWeightId
            }
            if (this.settingID != null || this.settingID != undefined) {
              return "/settings/" + this.settingID
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId
            }
            if (this.creditCardTypeId != null || this.creditCardTypeId != undefined) {
              return "/credit-card-types/" + this.creditCardTypeId
            }
            if (this.paymentMethodId != null || this.paymentMethodId != undefined) {
              return "/payment-methods/" + this.paymentMethodId
            }
            if (this.discountId != null || this.discountId != undefined) {
              return "/discounts/" + this.discountId
            }
            if (this.taxCategoryId != null || this.taxCategoryId != undefined) {
              return "/tax-category/" + this.taxCategoryId
            }
            if (this.shippingMethodID != null || this.shippingMethodID != undefined) {
              return "/shipping-method/" + this.shippingMethodID
            }
            if (this.stateProvinceId != null || this.stateProvinceId != undefined) {
              return "/state-provinces/" + this.stateProvinceId
            }
            if (this.deliveryTypeId != null || this.deliveryTypeId != undefined) {
              return "/delivery-types/" + this.deliveryTypeId;
            }
            if ((this.languageId != null || this.languageId != undefined) && (this.localizationKey == null || this.localizationKey == undefined)) {
              return "/languages/" + this.languageId
            }
            if (this.categoryId != null || this.categoryId != undefined) {
              if (this.categoryTypeId == CategoryEnum.Design) {
                return "/categories/" + this.categoryId  + "?activeTab=categories";
              }
              else if (this.categoryTypeId == CategoryEnum.Bom) {
                return "/bom/" + this.categoryId
              }
              else if (this.categoryTypeId == CategoryEnum.Gemstone) {
                return "/gemstone/" + this.categoryId
              }
            }
            if (this.warehouseId != null || this.warehouseId != undefined) {
              return "/warehouses/" + this.warehouseId
            }
            if (this.brandId != null || this.brandId != undefined) {
              return "/p-o-s-brands/" + this.brandId
            }
            if (this.pieceOwnerId != null || this.pieceOwnerId != undefined) {
              return "/piece-owners/" + this.pieceOwnerId
            }
            if (this.roleId != null || this.roleId != undefined) {
              return "/roles/" + this.roleId
            }
            if (this.customerRelationShipId != null || this.customerRelationShipId != undefined) {
              return "/customer-relationships/" + this.customerRelationShipId
            }
            if (this.countryId != null || this.countryId != undefined) {
              return "/countries/" + this.countryId
            }
            if (this.collectionId != null || this.collectionId != undefined) {
              return "/main-collections/" + this.collectionId  + "?activeTab=main-collections";
            }
            if (this.productAttributeId != null || this.productAttributeId != undefined) {
              return "/product-attributes/" + this.productAttributeId
            }
            if (this.currencyId != null || this.currencyId != undefined) {
              return "/currencies/" + this.currencyId
            }
            if (this.titleId != null || this.titleId != undefined) {
              return "/titles/" + this.titleId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/sales-channels/" + this.salesChannelId
            }
            if (this.boutiqueId != null || this.boutiqueId != undefined) {
              return "/boutiques/" + this.boutiqueId + "?activeTab=boutiques";
            }
            if (this.pieceDeliveryStatusId != null || this.pieceDeliveryStatusId != undefined) {
              return "/piece-delivery-statuses/" + this.pieceDeliveryStatusId
            }
            if (this.productStatusId != null || this.productStatusId != undefined) {
              return "product-variant-statuses/" + this.productStatusId
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.sizeId != null || this.sizeId != undefined) {
              return "/ring-egg-sizes/" + this.sizeId;
            }
            if (this.fileExtentionId != null || this.fileExtentionId != undefined) {
              return "/file-extentions/" + this.fileExtentionId;
            }
            if (this.applicationObjectId != null || this.applicationObjectId != undefined) {
              return "/application-object/" + this.applicationObjectId + "?activeTab=edit-info";
            }
            if (this.occurrenceTypeId != null || this.occurrenceTypeId != undefined)
            {
              return "/occurrence-types/" + this.occurrenceTypeId
            }
            if (this.bangleSizeId != null || this.bangleSizeId != undefined) {
              return "/bangle-sizes/" + this.bangleSizeId;
            }
            if (this.dutyStatusId != null || this.dutyStatusId != undefined) {
              return "/duty-statuses/" + this.dutyStatusId;
            }
            if (this.noteTypeId != null || this.noteTypeId != undefined) {
              return "/note-type/" + this.noteTypeId;
            }

            if (this.nationalityId != null || this.nationalityId != undefined) {
              return "/nationalities/" + this.nationalityId;
            }
            if (this.industryTypeId != null || this.industryTypeId != undefined) {
              return "/industry-types/" + this.industryTypeId;
            }
            if (this.tripTypeId != null || this.tripTypeId != undefined) {
              return "/trip-types/" + this.tripTypeId;
            }
            if (this.lengthId != null || this.lengthId != undefined) {
              return "/chain-lengths/" + this.lengthId;
            }
            if (this.EventTrainingTypeId != null || this.EventTrainingTypeId != undefined) {
              return "/training-types/" + this.EventTrainingTypeId;
            }
            if (this.chainColourId != null || this.chainColourId != undefined) {
              return "/chain-colours/" + this.chainColourId;
            }
            if (this.departmentId != null || this.departmentId != undefined) {
              return "/departments/" + this.departmentId;
            }
            if (this.locationGroupId != null || this.locationGroupId != undefined) {
              return "/location-groups/" + this.locationGroupId;
            }
            if (this.originId != null || this.originId != undefined) {
              return "/gemstone-origins/" + this.originId;
            }
            if (this.leaveId != null || this.leaveId != undefined) {
              return "/leave-types/" + this.leaveId + "?activeTab=leave-type-info";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/customer-holidays/" + this.masterId;
            }
            if ((this.gemstoneStatusId != null || this.gemstoneStatusId != undefined) && this.isGemfield == 'false') {
              return "/gemstone-statuses/" + this.gemstoneStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "false") {
              return "/bank-holidays/" + this.holidayId
            }
            if (this.supplierId != null || this.supplierId != undefined) {
              return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
            }
            if (this.carnetId != null || this.carnetId != undefined) {
              return "/carnets/" + this.carnetId;
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/faberge-price-lists/" + this.tripId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
           
            if (this.mainMenuItemId != null || this.mainMenuItemId != undefined) {
              return "/menus/" + this.mainMenuItemId;
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
            if (this.riskCategoryId != null || this.riskCategoryId != undefined) {
              return "/risk-categories/" + this.riskCategoryId;
            }
            if (this.helpSupportId != null || this.helpSupportId != undefined) {
              return "/help-supports/" + this.helpSupportId;
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.incTypeId != null || this.incTypeId != undefined) {
              return "/i-t-inc-types/" + this.incTypeId;
            }
            if (this.incCategoryId != null || this.incCategoryId != undefined) {
              return "/i-t-inc-categories/" + this.incCategoryId;
            }
            if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            }
            if (this.agreementTypeId != null || this.agreementTypeId != undefined) {
              return "/agreement-type/" + this.agreementTypeId;
            }
            if (this.chainGaugeID != null || this.chainGaugeID != undefined) {
              return "/chain-gauges/" + this.chainGaugeID;
            }
            if (this.chainStyleID != null || this.chainStyleID != undefined) {
              return "/chain-styles/" + this.chainStyleID;
            }
            if (this.topicId != undefined && this.topicId != null && this.topicId > 0) {
              return "/faberge-pages/" + this.topicId + "?activeTab=page-edit";
            }
            if (this.localizationKey != null || this.localizationKey != undefined) {
              return "/faberge-localization/" + this.languageId + "?key=" + this.localizationKey;
            }
            if (this.formId != null || this.formId != undefined) {
              return "/forms-document/" + this.formId;
            }
            if (this.regionId != null || this.regionId != undefined) {
              return "/regions/" + this.regionId;
            }
            if (this.watchPartId != null || this.watchPartId != undefined) {
              return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
            }
            if(this.treatmentId != null || this.treatmentId != undefined){
              return "/treatment/" + this.treatmentId;
            }
            if (this.eventServiceId != null || this.eventServiceId != undefined) {
              return "/event-service-types/" + this.eventServiceId
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.desktopAssetId != null || this.desktopAssetId != undefined) {
              return "/desktop-assets/" + this.desktopAssetId+ "?activeTab=edit-info";
            }
            if (this.MobileAssetId != null || this.MobileAssetId != undefined) {
              return "/mobile-assets/" + this.MobileAssetId+ "?activeTab=edit-info";
            }
            if (this.assetManufacturerId != null || this.assetManufacturerId != undefined) {
              return "/it-asset-manufacturer/" + this.assetManufacturerId;
            }
            if (this.assetLocationId != null || this.assetLocationId != undefined) {
              return "/it-asset-location/" + this.assetLocationId;
            }
            if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
              return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
            }
            if (this.UserId != null || this.UserId != undefined) {
              return "/users," + this.UserId + "?activeTab=user-edit";
            }
            if (this.adhocId != null || this.adhocId != undefined) {
              return "/customised-pages/," + this.adhocId + "?activeTab=adhoc-pages";
            }

            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.companyId != null || this.companyId != undefined) {
              return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
            }
            if (this.CompanyID != null || this.CompanyID != undefined) {
              return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
            }
            if (this.watchStatusId != null || this.watchStatusId != undefined) {
              return "/watch-status/" + this.watchStatusId
            }
            if (this.legalOwnerId != null || this.legalOwnerId != undefined) {
              return "/legal-owner/" + this.legalOwnerId
            }
            if (this.watchItemTypeId != null || this.watchItemTypeId != undefined) {
              return "/watch-item-types/" + this.watchItemTypeId
            }
            if ((this.watchMaterialId != null || this.watchMaterialId != undefined)) {
              return "/watch-materials/" + this.watchMaterialId
            }
            if (this.clarityId != null || this.clarityId != undefined) {
              return "/gem-stone-clarity-gemfields/" + this.clarityId
            }
            if (this.enhancementId != null || this.enhancementId != undefined) {
              return "/gemfields-enhancement/" + this.enhancementId
            }
            if (this.cutTypeId != null || this.cutTypeId != undefined) {
              return "/gem-stone-cut-types/" + this.cutTypeId
            }
            if (this.materialId != null || this.materialId != undefined) {
              return "/materials/" + this.materialId
            }
            if (this.fabergeColorId != null || this.fabergeColorId != undefined) {
              return "/colors/" + this.fabergeColorId
            }
            if (this.carrierId != null || this.carrierId != undefined) {
              return "/gemfields-carrier/" + this.carrierId
            }
            if (this.colorId != null || this.colorId != undefined) {
              return "/gem-stone-colors/" + this.colorId
            }
            if (this.locationId != null || this.locationId != undefined) {
              return "/gemfields-location/" + this.locationId
            }
            if (this.arrangementId != null || this.arrangementId != undefined) {
              return "/gemfields-arrangements/" + this.arrangementId
            }
            if (this.productTypeId != null || this.productTypeId != undefined) {
              return "/product-types/" + this.productTypeId
            }
            if (this.temporaryAssigneeTypeID != null || this.temporaryAssigneeTypeID != undefined) {
              return "/temporary-assignee-types/" + this.temporaryAssigneeTypeID
            }
            if (this.stationId != null || this.stationId != undefined) {
              return "/gemfields-station/" + this.stationId
            }
            if (this.enhancementTypeId != null || this.enhancementTypeId != undefined) {
              return "/gemfields-enhancement-type/" + this.enhancementTypeId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/gemfield-sales-channels/" + this.salesChannelId
            }
            if (this.gemFieldsBookingStatusId != null || this.gemFieldsBookingStatusId != undefined) {
              return "/gem-fields-booking-statuses/" + this.gemFieldsBookingStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "true") {
              return "/holiday-lists/" + this.holidayId
            }
            if (this.messageTemplateID != null || this.messageTemplateID != undefined) {
              return "/message-templates/" + this.messageTemplateID
            }
            if (this.emailAccountId != null || this.emailAccountId != undefined) {
              return "/email-account/" + this.emailAccountId
            }
            if (this.bannedIpNetworkId != null || this.bannedIpNetworkId != undefined) {
              return "/banned-ip-network/" + this.bannedIpNetworkId
            }
            if (this.bannedIpAddressID != null || this.bannedIpAddressID != undefined) {
              return "/banned-ip-addresses/" + this.bannedIpAddressID
            }
            if (this.measureDimensionId != null || this.measureDimensionId != undefined) {
              return "/measure-dimensions/" + this.measureDimensionId
            }
            if (this.measureWeightId != null || this.measureWeightId != undefined) {
              return "/measure-weights/" + this.measureWeightId
            }
            if (this.settingID != null || this.settingID != undefined) {
              return "/settings/" + this.settingID
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId
            }
            if (this.creditCardTypeId != null || this.creditCardTypeId != undefined) {
              return "/credit-card-types/" + this.creditCardTypeId
            }
            if (this.paymentMethodId != null || this.paymentMethodId != undefined) {
              return "/payment-methods/" + this.paymentMethodId
            }
            if (this.discountId != null || this.discountId != undefined) {
              return "/discounts/" + this.discountId
            }
            if (this.taxCategoryId != null || this.taxCategoryId != undefined) {
              return "/tax-category/" + this.taxCategoryId
            }
            if (this.shippingMethodID != null || this.shippingMethodID != undefined) {
              return "/shipping-method/" + this.shippingMethodID
            }
            if (this.stateProvinceId != null || this.stateProvinceId != undefined) {
              return "/state-provinces/" + this.stateProvinceId
            }
            if (this.deliveryTypeId != null || this.deliveryTypeId != undefined) {
              return "/delivery-types/" + this.deliveryTypeId;
            }
            if ((this.languageId != null || this.languageId != undefined) && (this.localizationKey == null || this.localizationKey == undefined)) {
              return "/languages/" + this.languageId
            }
            if (this.categoryId != null || this.categoryId != undefined) {
              if (this.categoryTypeId == CategoryEnum.Design) {
                return "/categories/" + this.categoryId  + "?activeTab=categories";
              }
              else if (this.categoryTypeId == CategoryEnum.Bom) {
                return "/bom/" + this.categoryId
              }
              else if (this.categoryTypeId == CategoryEnum.Gemstone) {
                return "/gemstone/" + this.categoryId
              }
            }
            if (this.warehouseId != null || this.warehouseId != undefined) {
              return "/warehouses/" + this.warehouseId
            }
            if (this.brandId != null || this.brandId != undefined) {
              return "/p-o-s-brands/" + this.brandId
            }
            if (this.pieceOwnerId != null || this.pieceOwnerId != undefined) {
              return "/piece-owners/" + this.pieceOwnerId
            }
            if (this.roleId != null || this.roleId != undefined) {
              return "/roles/" + this.roleId
            }
            if (this.customerRelationShipId != null || this.customerRelationShipId != undefined) {
              return "/customer-relationships/" + this.customerRelationShipId
            }
            if (this.countryId != null || this.countryId != undefined) {
              return "/countries/" + this.countryId
            }
            if (this.collectionId != null || this.collectionId != undefined) {
              return "/main-collections/" + this.collectionId  + "?activeTab=main-collections";
            }
            if (this.productAttributeId != null || this.productAttributeId != undefined) {
              return "/product-attributes/" + this.productAttributeId
            }
            if (this.currencyId != null || this.currencyId != undefined) {
              return "/currencies/" + this.currencyId
            }
            if (this.titleId != null || this.titleId != undefined) {
              return "/titles/" + this.titleId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/sales-channels/" + this.salesChannelId
            }
            if (this.boutiqueId != null || this.boutiqueId != undefined) {
              return "/boutiques/" + this.boutiqueId + "?activeTab=boutiques";
            }
            if (this.pieceDeliveryStatusId != null || this.pieceDeliveryStatusId != undefined) {
              return "/piece-delivery-statuses/" + this.pieceDeliveryStatusId
            }
            if (this.productStatusId != null || this.productStatusId != undefined) {
              return "product-variant-statuses/" + this.productStatusId
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.sizeId != null || this.sizeId != undefined) {
              return "/ring-egg-sizes/" + this.sizeId;
            }
            if (this.fileExtentionId != null || this.fileExtentionId != undefined) {
              return "/file-extentions/" + this.fileExtentionId;
            }
            if (this.applicationObjectId != null || this.applicationObjectId != undefined) {
              return "/application-object/" + this.applicationObjectId + "?activeTab=edit-info";
            }
            if (this.occurrenceTypeId != null || this.occurrenceTypeId != undefined)
            {
              return "/occurrence-types/" + this.occurrenceTypeId
            }
            if (this.bangleSizeId != null || this.bangleSizeId != undefined) {
              return "/bangle-sizes/" + this.bangleSizeId;
            }

            if (this.dutyStatusId != null || this.dutyStatusId != undefined) {
              return "/duty-statuses/" + this.dutyStatusId;
            }
            if (this.noteTypeId != null || this.noteTypeId != undefined) {
              return "/note-type/" + this.noteTypeId;
            }
            if (this.nationalityId != null || this.nationalityId != undefined) {
              return "/nationalities/" + this.nationalityId;
            }
            if (this.industryTypeId != null || this.industryTypeId != undefined) {
              return "/industry-types/" + this.industryTypeId;
            }

            if (this.tripTypeId != null || this.tripTypeId != undefined) {
              return "/trip-types/" + this.tripTypeId;
            }
            if (this.lengthId != null || this.lengthId != undefined) {
              return "/chain-lengths/" + this.lengthId;
            }
            if (this.EventTrainingTypeId != null || this.EventTrainingTypeId != undefined) {
              return "/training-types/" + this.EventTrainingTypeId;
            }
            if (this.chainColourId != null || this.chainColourId != undefined) {
              return "/chain-colours/" + this.chainColourId;
            }
            if (this.departmentId != null || this.departmentId != undefined) {
              return "/departments/" + this.departmentId;
            }
            if (this.locationGroupId != null || this.locationGroupId != undefined) {
              return "/location-groups/" + this.locationGroupId;
            }
            if (this.originId != null || this.originId != undefined) {
              return "/gemstone-origins/" + this.originId;
            }
            if (this.leaveId != null || this.leaveId != undefined) {
              return "/leave-types/" + this.leaveId + "?activeTab=leave-type-info";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/customer-holidays/" + this.masterId;
            }
            if ((this.gemstoneStatusId != null || this.gemstoneStatusId != undefined) && this.isGemfield == 'false') {
              return "/gemstone-statuses/" + this.gemstoneStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "false") {
              return "/bank-holidays/" + this.holidayId
            }
            if (this.supplierId != null || this.supplierId != undefined) {
              return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
            }
            if (this.carnetId != null || this.carnetId != undefined) {
              return "/carnets/" + this.carnetId;
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/faberge-price-lists/" + this.tripId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.mainMenuItemId != null || this.mainMenuItemId != undefined) {
              return "/menus/" + this.mainMenuItemId;
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
            if (this.riskCategoryId != null || this.riskCategoryId != undefined) {
              return "/risk-categories/" + this.riskCategoryId;
            }
            if (this.helpSupportId != null || this.helpSupportId != undefined) {
              return "/help-supports/" + this.helpSupportId;
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.incTypeId != null || this.incTypeId != undefined) {
              return "/i-t-inc-types/" + this.incTypeId;
            }
            if (this.incCategoryId != null || this.incCategoryId != undefined) {
              return "/i-t-inc-categories/" + this.incCategoryId;
            }
            if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            }
            if (this.agreementTypeId != null || this.agreementTypeId != undefined) {
              return "/agreement-type/" + this.agreementTypeId;
            }
            if (this.chainGaugeID != null || this.chainGaugeID != undefined) {
              return "/chain-gauges/" + this.chainGaugeID;
            }
            if (this.chainStyleID != null || this.chainStyleID != undefined) {
              return "/chain-styles/" + this.chainStyleID;
            }
            if (this.topicId != undefined && this.topicId != null && this.topicId > 0) {
              return "/faberge-pages/" + this.topicId + "?activeTab=page-edit";
            }
            if (this.formId != null || this.formId != undefined) {
              return "/forms-document/" + this.formId;
            }
            if (this.regionId != null || this.regionId != undefined) {
              return "/regions/" + this.regionId;
            }
            if (this.watchPartId != null || this.watchPartId != undefined) {
              return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
            }
            if(this.treatmentId != null || this.treatmentId != undefined){
              return "/treatment/" + this.treatmentId;
            }
            if (this.eventServiceId != null || this.eventServiceId != undefined) {
              return "/event-service-types/" + this.eventServiceId
            }
          },
          onClick: "return false;"

        },
        class:[function(e) {
          if((!this.isRoleSubmitted) || (!this.lineManagerSubmitted && this.isempSubmittedCommon)){
            return "mr-2".split(" ");  
          }else{
            return "mr-2 editdisabled".split(" ");
          }
      }]
      }
    }, {
      a: {
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }],
        class:[function(e) {
          if(!this.isRoleSubmitted){
            return "mr-2".split(" ");  
          }else{
            return "mr-2 editdisabled".split(" ");
          }
      }]
      }

    }]
  }
}

export const ACTION_LEGAL_AGREEMENT_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            //if (this.isAccess == true) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            // }
            // else{
            //   return "/legal-agreement/restricted-legal-agreement/" + this.legalAgreementId;
            // }
          },
          "ng-reflect-router-link": function (e) {
            //if (this.isAccess == true) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            // }
            // else{
            //   return "/legal-agreement/restricted-legal-agreement/" + this.legalAgreementId;
            // }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        isBind :(x) =>{
          if(x.isAccess)
          {
            return true;
          }
          else
          {
            return false;
          }
        },
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}

export const IAN_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "add",
        event: {
          click: "onAdd"
        },
        childrens: [{
          i: {
            class: "fa fr-save font-md".split(" "),
            attributes: { "title": getLocalizedValue("Title_Save") }
          }
        }],
        attributes:
        {
          title: "save/update"
        }
        ,
        class: ["mr-2"]
      }
    }
    ]
  }
}

export const IAN_GRID_BUTTON_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                if (this.PersonName != null || this.PersonName != undefined) {
                  //return "<br><span>" + this.PersonName + "</span>";
                  return this.PersonName;
                }
              }
            },
          }]
        },
        input: {
          class: "btn btn-success".split(" "),
          attributes: {
            type: "button",
            value: `Add New Person`,
          },
          event: {
            click: "AddPerson"
          },
          style: {
            "white-space": "normal",
            "width": "80px",
            /* -webkit-appearance: none; */
            /* background: #ddd; */
            "border": "none"
          }
        },
      }
    ]
  },
}

export const IAN_GRIDHEADER_BUTTON_TEMPLATE: TemplateConfig = {
  div: {
    class: ["text-right", "w-100"],
    childrens: [
      {
        input: {
          class: "btn btn-success".split(" "),
          attributes: {
            type: "button",
            value: "Update All",
          },
          event: {
            click: "updateAllData"
          },
        },
      }
    ]
  },
}

export const ACTION_GRID_TEMPLATE_CORPORATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          authorize: "edit",
          isBind: (x) => {
            if (x.total != 'Total') {
              return true;
            }
            else {
              return false;

            }
          },
          event: {
            click: "onEdit"
          },
          childrens: [{
            i: {
              class: "fa fa-edit fa-4x font-md text-default".split(" "),
              attributes: { "title": getLocalizedValue("Title_Edit") }
            }
          }],
          class: ["mr-2"]
        }
      }, {
        a: {
          authorize: "delete",
          isBind: (x) => {
            if (x.total != 'Total') {
              return true;
            }
            else {
              return false;

            }
          },
          event: {
            click: "onDelete"
          },
          childrens: [{
            i: {
              class: "fr-delete fa-4x font-md text-danger".split(" "),
              attributes: { "title": getLocalizedValue("Title_Delete") }
            }
          }]
        }
      }
    ]
  }
}

export const ACTION_EDIT_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            //console.log(this)
            if (this.batchNo != null || this.batchNo != undefined) {
              return "/product-batch/" + this.batchNo + "?activeTab=batch-info";
            }
            if (this.desktopAssetId != null || this.desktopAssetId != undefined) {
              return "/desktop-assets/" + this.desktopAssetId+ "?activeTab=edit-info";
            }
            if (this.MobileAssetId != null || this.MobileAssetId != undefined) {
              return "/mobile-assets/" + this.MobileAssetId+ "?activeTab=edit-info";
            }
            if (this.tripID != null || this.tripID != undefined) {
              return "/bookings/" + this.tripID
            }
            if (this.adhocId != null || this.adhocId != undefined) {
              return "/customised-pages/" + this.adhocId + "?activeTab=adhoc-pages";
            }
            if (this.queuedEmailId != null || this.queuedEmailId != undefined) {
              return "/queued-emails/" + this.queuedEmailId;
            }
            if (this.layoutId != null || this.layoutId != undefined) {
              return "/home-layouts/" + this.layoutId;
            }
            if (this.BookingId != null || this.BookingId != undefined) {
              return "/bookings/" + this.BookingId
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.agreementTypeId != null || this.agreementTypeId != undefined) {
              return "/agreement-type/" + this.agreementTypeId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.topicId != undefined && this.topicId != null && this.topicId > 0) {
              return "/faberge-pages/" + this.topicId + "?activeTab=page-edit";
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            }

            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId
            }
            if (this.UserId != null || this.UserId != undefined) {
              return "/users/" + this.UserId + "?activeTab=user-edit";
            }
            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.companyId != null || this.companyId != undefined) {
              return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
            }
            if (this.CompanyID != null || this.CompanyID != undefined) {
              return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
            }
            if (this.watchStatusId != null || this.watchStatusId != undefined) {
              return "/watch-status/" + this.watchStatusId
            }
            if (this.legalOwnerId != null || this.legalOwnerId != undefined) {
              return "/legal-owner/" + this.legalOwnerId
            }
            if (this.watchItemTypeId != null || this.watchItemTypeId != undefined) {
              return "/watch-item-types/" + this.watchItemTypeId
            }
            if ((this.watchMaterialId != null || this.watchMaterialId != undefined)) {
              return "/watch-materials/" + this.watchMaterialId
            }
            if (this.clarityId != null || this.clarityId != undefined) {
              return "/gem-stone-clarity-gemfields/" + this.clarityId
            }
            if (this.enhancementId != null || this.enhancementId != undefined) {
              return "/gemfields-enhancement/" + this.enhancementId
            }
            if (this.cutTypeId != null || this.cutTypeId != undefined) {
              return "/gem-stone-cut-types/" + this.cutTypeId
            }
            if (this.materialId != null || this.materialId != undefined) {
              return "/materials/" + this.materialId
            }
            if (this.fabergeColorId != null || this.fabergeColorId != undefined) {
              return "/colors/" + this.fabergeColorId
            }
            if (this.carrierId != null || this.carrierId != undefined) {
              return "/gemfields-carrier/" + this.carrierId
            }
            if (this.colorId != null || this.colorId != undefined) {
              return "/gem-stone-colors/" + this.colorId
            }
            if (this.locationId != null || this.locationId != undefined) {
              return "/gemfields-location/" + this.locationId
            }
            if (this.arrangementId != null || this.arrangementId != undefined) {
              return "/gemfields-arrangements/" + this.arrangementId
            }
            if (this.productTypeId != null || this.productTypeId != undefined) {
              return "/product-types/" + this.productTypeId
            }
            if (this.temporaryAssigneeTypeID != null || this.temporaryAssigneeTypeID != undefined) {
              return "/temporary-assignee-types/" + this.temporaryAssigneeTypeID
            }
            if (this.stationId != null || this.stationId != undefined) {
              return "/gemfields-station/" + this.stationId
            }
            if (this.enhancementTypeId != null || this.enhancementTypeId != undefined) {
              return "/gemfields-enhancement-type/" + this.enhancementTypeId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/gemfield-sales-channels/" + this.salesChannelId
            }
            if (this.gemFieldsBookingStatusId != null || this.gemFieldsBookingStatusId != undefined) {
              return "/gem-fields-booking-statuses/" + this.gemFieldsBookingStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "true") {
              return "/holiday-lists/" + this.holidayId
            }
            if (this.messageTemplateID != null || this.messageTemplateID != undefined) {
              return "/message-templates/" + this.messageTemplateID
            }
            if (this.emailAccountId != null || this.emailAccountId != undefined) {
              return "/email-account/" + this.emailAccountId
            }
            if (this.bannedIpNetworkId != null || this.bannedIpNetworkId != undefined) {
              return "/banned-ip-network/" + this.bannedIpNetworkId
            }
            if (this.bannedIpAddressID != null || this.bannedIpAddressID != undefined) {
              return "/banned-ip-addresses/" + this.bannedIpAddressID
            }
            if (this.measureDimensionId != null || this.measureDimensionId != undefined) {
              return "/measure-dimensions/" + this.measureDimensionId
            }
            if (this.measureWeightId != null || this.measureWeightId != undefined) {
              return "/measure-weights/" + this.measureWeightId
            }
            if (this.settingID != null || this.settingID != undefined) {
              return "/settings/" + this.settingID
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId
            }
            if (this.creditCardTypeId != null || this.creditCardTypeId != undefined) {
              return "/credit-card-types/" + this.creditCardTypeId
            }
            if (this.paymentMethodId != null || this.paymentMethodId != undefined) {
              return "/payment-methods/" + this.paymentMethodId
            }
            if (this.discountId != null || this.discountId != undefined) {
              return "/discounts/" + this.discountId
            }
            if (this.taxCategoryId != null || this.taxCategoryId != undefined) {
              return "/tax-category/" + this.taxCategoryId
            }
            if (this.shippingMethodID != null || this.shippingMethodID != undefined) {
              return "/shipping-method/" + this.shippingMethodID
            }
            if (this.stateProvinceId != null || this.stateProvinceId != undefined) {
              return "/state-provinces/" + this.stateProvinceId
            }
            if (this.deliveryTypeId != null || this.deliveryTypeId != undefined) {
              return "/delivery-types/" + this.deliveryTypeId;
            }
            if ((this.languageId != null || this.languageId != undefined) && (this.localizationKey == null || this.localizationKey == undefined)) {
              return "/languages/" + this.languageId
            }
            if (this.categoryId != null || this.categoryId != undefined) {
              if (this.categoryTypeId == CategoryEnum.Design) {
                return "/categories/" + this.categoryId + "?activeTab=categories";
              }
              else if (this.categoryTypeId == CategoryEnum.Bom) {
                return "/bom/" + this.categoryId
              }
              else if (this.categoryTypeId == CategoryEnum.Gemstone) {
                return "/gemstone/" + this.categoryId
              }
            }
            if (this.warehouseId != null || this.warehouseId != undefined) {
              return "/warehouses/" + this.warehouseId
            }
            if (this.brandId != null || this.brandId != undefined) {
              return "/p-o-s-brands/" + this.brandId
            }
            if (this.pieceOwnerId != null || this.pieceOwnerId != undefined) {
              return "/piece-owners/" + this.pieceOwnerId
            }
            if (this.roleId != null || this.roleId != undefined) {
              return "/roles/" + this.roleId
            }
            if (this.customerRelationShipId != null || this.customerRelationShipId != undefined) {
              return "/customer-relationships/" + this.customerRelationShipId
            }
            if (this.countryId != null || this.countryId != undefined) {
              return "/countries/" + this.countryId
            }
            if (this.collectionId != null || this.collectionId != undefined) {
              return "/main-collections/" + this.collectionId  + "?activeTab=main-collections";
            }
            if (this.productAttributeId != null || this.productAttributeId != undefined) {
              return "/product-attributes/" + this.productAttributeId
            }
            if (this.currencyId != null || this.currencyId != undefined) {
              return "/currencies/" + this.currencyId
            }
            if (this.titleId != null || this.titleId != undefined) {
              return "/titles/" + this.titleId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/sales-channels/" + this.salesChannelId
            }
            if (this.boutiqueId != null || this.boutiqueId != undefined) {
              return "/boutiques/" + this.boutiqueId + "?activeTab=boutiques";
            }
            if (this.pieceDeliveryStatusId != null || this.pieceDeliveryStatusId != undefined) {
              return "/piece-delivery-statuses/" + this.pieceDeliveryStatusId
            }
            if (this.productStatusId != null || this.productStatusId != undefined) {
              return "product-variant-statuses/" + this.productStatusId
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.sizeId != null || this.sizeId != undefined) {
              return "/ring-egg-sizes/" + this.sizeId;
            }
            if (this.fileExtentionId != null || this.fileExtentionId != undefined) {
              return "/file-extentions/" + this.fileExtentionId;
            }
            if (this.applicationObjectId != null || this.applicationObjectId != undefined) {
              return "/application-object/" + this.applicationObjectId + "?activeTab=edit-info";
            }
            if (this.occurrenceTypeId != null || this.occurrenceTypeId != undefined)
            {
              return "/occurrence-types/" + this.occurrenceTypeId
            }
            if (this.bangleSizeId != null || this.bangleSizeId != undefined) {
              return "/bangle-sizes/" + this.bangleSizeId;
            }
            if (this.dutyStatusId != null || this.dutyStatusId != undefined) {
              return "/duty-statuses/" + this.dutyStatusId;
            }
            if (this.noteTypeId != null || this.noteTypeId != undefined) {
              return "/note-type/" + this.noteTypeId;
            }
            if (this.nationalityId != null || this.nationalityId != undefined) {
              return "/nationalities/" + this.nationalityId;
            }
            if (this.industryTypeId != null || this.industryTypeId != undefined) {
              return "/industry-types/" + this.industryTypeId;
            }
            if (this.tripTypeId != null || this.tripTypeId != undefined) {
              return "/trip-types/" + this.tripTypeId;
            }
            if (this.lengthId != null || this.lengthId != undefined) {
              return "/chain-lengths/" + this.lengthId;
            }
            if (this.EventTrainingTypeId != null || this.EventTrainingTypeId != undefined) {
              return "/training-types/" + this.EventTrainingTypeId;
            }
            if (this.chainColourId != null || this.chainColourId != undefined) {
              return "/chain-colours/" + this.chainColourId;
            }
            if (this.departmentId != null || this.departmentId != undefined) {
              return "/departments/" + this.departmentId;
            }
            if (this.locationGroupId != null || this.locationGroupId != undefined) {
              return "/location-groups/" + this.locationGroupId;
            }
            if (this.originId != null || this.originId != undefined) {
              return "/gemstone-origins/" + this.originId;
            }
            if (this.leaveId != null || this.leaveId != undefined) {
              return "/leave-types/" + this.leaveId + "?activeTab=leave-type-info";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/customer-holidays/" + this.masterId;
            }
            if ((this.gemstoneStatusId != null || this.gemstoneStatusId != undefined) && this.isGemfield == 'false') {
              return "/gemstone-statuses/" + this.gemstoneStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "false") {
              return "/bank-holidays/" + this.holidayId
            }
            if (this.trackIncidentFormId != null || this.trackIncidentFormId != undefined) {
              return "/grievance-forms/" + this.trackIncidentFormId;
            }
            if (this.supplierId != null || this.supplierId != undefined) {
              return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
            }
            if (this.carnetId != null || this.carnetId != undefined) {
              return "/carnets/" + this.carnetId;
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/faberge-price-lists/" + this.tripId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
              return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
            }
            if (this.mainMenuItemId != null || this.mainMenuItemId != undefined) {
              return "/menus/" + this.mainMenuItemId;
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
            if (this.riskCategoryId != null || this.riskCategoryId != undefined) {
              return "/risk-categories/" + this.riskCategoryId;
            }
            if (this.helpSupportId != null || this.helpSupportId != undefined) {
              return "/help-supports/" + this.helpSupportId;
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.incTypeId != null || this.incTypeId != undefined) {
              return "/i-t-inc-types/" + this.incTypeId;
            }
            if (this.incCategoryId != null || this.incCategoryId != undefined) {
              return "/i-t-inc-categories/" + this.incCategoryId;
            }
            if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            }
            if (this.agreementTypeId != null || this.agreementTypeId != undefined) {
              return "/agreement-type/" + this.agreementTypeId;
            }
            if (this.chainGaugeID != null || this.chainGaugeID != undefined) {
              return "/chain-gauges/" + this.chainGaugeID;
            }
            if (this.chainStyleID != null || this.chainStyleID != undefined) {
              return "/chain-styles/" + this.chainStyleID;
            }
            if (this.localizationKey != null || this.localizationKey != undefined) {
              return "/faberge-localization/" + this.languageId + "?key=" + this.localizationKey;
            }
            if (this.formId != null || this.formId != undefined) {
              return "/forms-document/" + this.formId;
            }
            if (this.SeoRedirectionId != null || this.SeoRedirectionId != undefined) {
              return "/seo-redirections/" + this.SeoRedirectionId;
            }
            if (this.moduleMasterId != null || this.moduleMasterId != undefined) {
              return "/system-help/" + this.moduleMasterId;
            }
            if (this.giftFormId != null || this.giftFormId != undefined) {
              return "/electronic-gift-forms/" + this.giftFormId + "?activeTab=electronic-gift-edit";
            }
            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
              return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
            }
            if (this.watchPartId != null || this.watchPartId != undefined) {
              return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
            }
            if(this.treatmentId != null || this.treatmentId != undefined){
              return "/treatment/" + this.treatmentId;
            }
            if (this.eventServiceId != null || this.eventServiceId != undefined) {
              return "/event-service-types/" + this.eventServiceId
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.batchNo != null || this.batchNo != undefined) {
              return "/product-batch/" + this.batchNo + "?activeTab=batch-info";
            }if (this.desktopAssetId != null || this.desktopAssetId != undefined) {
              return "/desktop-assets/" + this.desktopAssetId+ "?activeTab=edit-info";
            }
            if (this.MobileAssetId != null || this.MobileAssetId != undefined) {
              return "/mobile-assets/" + this.MobileAssetId+ "?activeTab=edit-info";
            }
            if (this.tripID != null || this.tripID != undefined) {
              return "/bookings/" + this.tripID
            }
            if (this.adhocId != null || this.adhocId != undefined) {
              return "/customised-pages/" + this.adhocId + "?activeTab=adhoc-pages";
            }
            if (this.queuedEmailId != null || this.queuedEmailId != undefined) {
              return "/queued-emails/" + this.queuedEmailId;
            }
            if (this.layoutId != null || this.layoutId != undefined) {
              return "/home-layouts/" + this.layoutId;
            }
            if (this.BookingId != null || this.BookingId != undefined) {
              return "/bookings/" + this.BookingId
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.agreementTypeId != null || this.agreementTypeId != undefined) {
              return "/agreement-type/" + this.agreementTypeId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.topicId != undefined && this.topicId != null && this.topicId > 0) {
              return "/faberge-pages/" + this.topicId + "?activeTab=page-edit";
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId
            }
            if (this.UserId != null || this.UserId != undefined) {
              return "/users/" + this.UserId + "?activeTab=user-edit";
            }
            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.companyId != null || this.companyId != undefined) {
              return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
            }
            if (this.CompanyID != null || this.CompanyID != undefined) {
              return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
            }
            if (this.watchStatusId != null || this.watchStatusId != undefined) {
              return "/watch-status/" + this.watchStatusId
            }
            if (this.legalOwnerId != null || this.legalOwnerId != undefined) {
              return "/legal-owner/" + this.legalOwnerId
            }
            if (this.watchItemTypeId != null || this.watchItemTypeId != undefined) {
              return "/watch-item-types/" + this.watchItemTypeId
            }
            if ((this.watchMaterialId != null || this.watchMaterialId != undefined)) {
              return "/watch-materials/" + this.watchMaterialId
            }
            if (this.clarityId != null || this.clarityId != undefined) {
              return "/gem-stone-clarity-gemfields/" + this.clarityId
            }
            if (this.enhancementId != null || this.enhancementId != undefined) {
              return "/gemfields-enhancement/" + this.enhancementId
            }
            if (this.cutTypeId != null || this.cutTypeId != undefined) {
              return "/gem-stone-cut-types/" + this.cutTypeId
            }
            if (this.materialId != null || this.materialId != undefined) {
              return "/materials/" + this.materialId
            }
            if (this.fabergeColorId != null || this.fabergeColorId != undefined) {
              return "/colors/" + this.fabergeColorId
            }
            if (this.carrierId != null || this.carrierId != undefined) {
              return "/gemfields-carrier/" + this.carrierId
            }
            if (this.colorId != null || this.colorId != undefined) {
              return "/gem-stone-colors/" + this.colorId
            }
            if (this.locationId != null || this.locationId != undefined) {
              return "/gemfields-location/" + this.locationId
            }
            if (this.arrangementId != null || this.arrangementId != undefined) {
              return "/gemfields-arrangements/" + this.arrangementId
            }
            if (this.productTypeId != null || this.productTypeId != undefined) {
              return "/product-types/" + this.productTypeId
            }
            if (this.temporaryAssigneeTypeID != null || this.temporaryAssigneeTypeID != undefined) {
              return "/temporary-assignee-types/" + this.temporaryAssigneeTypeID
            }
            if (this.stationId != null || this.stationId != undefined) {
              return "/gemfields-station/" + this.stationId
            }
            if (this.enhancementTypeId != null || this.enhancementTypeId != undefined) {
              return "/gemfields-enhancement-type/" + this.enhancementTypeId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/gemfield-sales-channels/" + this.salesChannelId
            }
            if (this.gemFieldsBookingStatusId != null || this.gemFieldsBookingStatusId != undefined) {
              return "/gem-fields-booking-statuses/" + this.gemFieldsBookingStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "true") {
              return "/holiday-lists/" + this.holidayId
            }
            if (this.messageTemplateID != null || this.messageTemplateID != undefined) {
              return "/message-templates/" + this.messageTemplateID
            }
            if (this.emailAccountId != null || this.emailAccountId != undefined) {
              return "/email-account/" + this.emailAccountId
            }
            if (this.bannedIpNetworkId != null || this.bannedIpNetworkId != undefined) {
              return "/banned-ip-network/" + this.bannedIpNetworkId
            }
            if (this.bannedIpAddressID != null || this.bannedIpAddressID != undefined) {
              return "/banned-ip-addresses/" + this.bannedIpAddressID
            }
            if (this.measureDimensionId != null || this.measureDimensionId != undefined) {
              return "/measure-dimensions/" + this.measureDimensionId
            }
            if (this.measureWeightId != null || this.measureWeightId != undefined) {
              return "/measure-weights/" + this.measureWeightId
            }
            if (this.settingID != null || this.settingID != undefined) {
              return "/settings/" + this.settingID
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId
            }
            if (this.creditCardTypeId != null || this.creditCardTypeId != undefined) {
              return "/credit-card-types/" + this.creditCardTypeId
            }
            if (this.paymentMethodId != null || this.paymentMethodId != undefined) {
              return "/payment-methods/" + this.paymentMethodId
            }
            if (this.discountId != null || this.discountId != undefined) {
              return "/discounts/" + this.discountId
            }
            if (this.taxCategoryId != null || this.taxCategoryId != undefined) {
              return "/tax-category/" + this.taxCategoryId
            }
            if (this.shippingMethodID != null || this.shippingMethodID != undefined) {
              return "/shipping-method/" + this.shippingMethodID
            }
            if (this.stateProvinceId != null || this.stateProvinceId != undefined) {
              return "/state-provinces/" + this.stateProvinceId
            }
            if (this.deliveryTypeId != null || this.deliveryTypeId != undefined) {
              return "/delivery-types/" + this.deliveryTypeId;
            }
            if ((this.languageId != null || this.languageId != undefined) && (this.localizationKey == null || this.localizationKey == undefined)) {
              return "/languages/" + this.languageId
            }
            if (this.categoryId != null || this.categoryId != undefined) {
              if (this.categoryTypeId == CategoryEnum.Design) {
                return "/categories/" + this.categoryId + "?activeTab=categories";
              }
              else if (this.categoryTypeId == CategoryEnum.Bom) {
                return "/bom/" + this.categoryId
              }
              else if (this.categoryTypeId == CategoryEnum.Gemstone) {
                return "/gemstone/" + this.categoryId
              }
            }
            if (this.warehouseId != null || this.warehouseId != undefined) {
              return "/warehouses/" + this.warehouseId
            }
            if (this.brandId != null || this.brandId != undefined) {
              return "/p-o-s-brands/" + this.brandId
            }
            if (this.pieceOwnerId != null || this.pieceOwnerId != undefined) {
              return "/piece-owners/" + this.pieceOwnerId
            }
            if (this.roleId != null || this.roleId != undefined) {
              return "/roles/" + this.roleId
            }
            if (this.customerRelationShipId != null || this.customerRelationShipId != undefined) {
              return "/customer-relationships/" + this.customerRelationShipId
            }
            if (this.countryId != null || this.countryId != undefined) {
              return "/countries/" + this.countryId
            }
            if (this.collectionId != null || this.collectionId != undefined) {
              return "/main-collections/" + this.collectionId  + "?activeTab=main-collections";
            }
            if (this.productAttributeId != null || this.productAttributeId != undefined) {
              return "/product-attributes/" + this.productAttributeId
            }
            if (this.currencyId != null || this.currencyId != undefined) {
              return "/currencies/" + this.currencyId
            }
            if (this.titleId != null || this.titleId != undefined) {
              return "/titles/" + this.titleId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/sales-channels/" + this.salesChannelId
            }
            if (this.boutiqueId != null || this.boutiqueId != undefined) {
              return "/boutiques/" + this.boutiqueId + "?activeTab=boutiques";
            }
            if (this.pieceDeliveryStatusId != null || this.pieceDeliveryStatusId != undefined) {
              return "/piece-delivery-statuses/" + this.pieceDeliveryStatusId
            }
            if (this.productStatusId != null || this.productStatusId != undefined) {
              return "product-variant-statuses/" + this.productStatusId
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.sizeId != null || this.sizeId != undefined) {
              return "/ring-egg-sizes/" + this.sizeId;
            }
            if (this.fileExtentionId != null || this.fileExtentionId != undefined) {
              return "/file-extentions/" + this.fileExtentionId;
            }
            if (this.applicationObjectId != null || this.applicationObjectId != undefined) {
              return "/application-object/" + this.applicationObjectId + "?activeTab=edit-info";
            }
            if (this.occurrenceTypeId != null || this.occurrenceTypeId != undefined)
            {
              return "/occurrence-types/" + this.occurrenceTypeId
            }
            if (this.bangleSizeId != null || this.bangleSizeId != undefined) {
              return "/bangle-sizes/" + this.bangleSizeId;
            }
            if (this.dutyStatusId != null || this.dutyStatusId != undefined) {
              return "/duty-statuses/" + this.dutyStatusId;
            }
            if (this.noteTypeId != null || this.noteTypeId != undefined) {
              return "/note-type/" + this.noteTypeId;
            }
            if (this.nationalityId != null || this.nationalityId != undefined) {
              return "/nationalities/" + this.nationalityId;
            }
            if (this.industryTypeId != null || this.industryTypeId != undefined) {
              return "/industry-types/" + this.industryTypeId;
            }
            if (this.tripTypeId != null || this.tripTypeId != undefined) {
              return "/trip-types/" + this.tripTypeId;
            }
            if (this.lengthId != null || this.lengthId != undefined) {
              return "/chain-lengths/" + this.lengthId;
            }
            if (this.EventTrainingTypeId != null || this.EventTrainingTypeId != undefined) {
              return "/training-types/" + this.EventTrainingTypeId;
            }
            if (this.chainColourId != null || this.chainColourId != undefined) {
              return "/chain-colours/" + this.chainColourId;
            }
            if (this.departmentId != null || this.departmentId != undefined) {
              return "/departments/" + this.departmentId;
            }
            if (this.locationGroupId != null || this.locationGroupId != undefined) {
              return "/location-groups/" + this.locationGroupId;
            }
            if (this.originId != null || this.originId != undefined) {
              return "/gemstone-origins/" + this.originId;
            }
            if (this.leaveId != null || this.leaveId != undefined) {
              return "/leave-types/" + this.leaveId + "?activeTab=leave-type-info";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/customer-holidays/" + this.masterId;
            }
            if ((this.gemstoneStatusId != null || this.gemstoneStatusId != undefined) && this.isGemfield == 'false') {
              return "/gemstone-statuses/" + this.gemstoneStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "false") {
              return "/bank-holidays/" + this.holidayId
            }
            if (this.trackIncidentFormId != null || this.trackIncidentFormId != undefined) {
              return "/grievance-forms/" + this.trackIncidentFormId;
            }
            if (this.supplierId != null || this.supplierId != undefined) {
              return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
            }
            if (this.carnetId != null || this.carnetId != undefined) {
              return "/carnets/" + this.carnetId;
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/faberge-price-lists/" + this.tripId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
              return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
            }
            if (this.mainMenuItemId != null || this.mainMenuItemId != undefined) {
              return "/menus/" + this.mainMenuItemId;
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
            if (this.riskCategoryId != null || this.riskCategoryId != undefined) {
              return "/risk-categories/" + this.riskCategoryId;
            }
            if (this.helpSupportId != null || this.helpSupportId != undefined) {
              return "/help-supports/" + this.helpSupportId;
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.incTypeId != null || this.incTypeId != undefined) {
              return "/i-t-inc-types/" + this.incTypeId;
            }
            if (this.incCategoryId != null || this.incCategoryId != undefined) {
              return "/i-t-inc-categories/" + this.incCategoryId;
            }
            if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
              return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
            }
            if (this.agreementTypeId != null || this.agreementTypeId != undefined) {
              return "/agreement-type/" + this.agreementTypeId;
            }
            if (this.chainGaugeID != null || this.chainGaugeID != undefined) {
              return "/chain-gauges/" + this.chainGaugeID;
            }
            if (this.chainStyleID != null || this.chainStyleID != undefined) {
              return "/chain-styles/" + this.chainStyleID;
            }
            if (this.formId != null || this.formId != undefined) {
              return "/forms-document/" + this.formId;
            }
            if (this.SeoRedirectionId != null || this.SeoRedirectionId != undefined) {
              return "/seo-redirections/" + this.SeoRedirectionId;
            }
            if (this.moduleMasterId != null || this.moduleMasterId != undefined) {
              return "/system-help/" + this.moduleMasterId;
            }
            if (this.giftFormId != null || this.giftFormId != undefined) {
              return "/electronic-gift-forms/" + this.giftFormId + "?activeTab=electronic-gift-edit";
            }
            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
              return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
            }
            if (this.watchPartId != null || this.watchPartId != undefined) {
              return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
            }
            if(this.treatmentId != null || this.treatmentId != undefined){
              return "/treatment/" + this.treatmentId;
            }
            if (this.eventServiceId != null || this.eventServiceId != undefined) {
              return "/event-service-types/" + this.eventServiceId
            }
          },
          onClick: "return false;"
        },
        class:[function(e) {
          if(this.lineManagerSubmitted){
              return "mr-2 editdisabled".split(" ");
          }else{
              return "mr-2".split(" ");
          }
      }] ,
      }
    }]
  }
}
export const ACTION_DELETE_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }
    }]
  }
}
export const ACTION_LEGAL_AGREEMENT_DELETE_TEMPLATE: TemplateConfig = {
  div: {
    isBind : (x)=>{
      if(x.isAccess)
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    childrens: [{
      a: {
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }
    }]
  }
}
export const ACTION_DELETE_GINA_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => {
      if (x.Sensitive == 1) {
        return false;
      }
      else {
        return true;
      }
    },
    childrens: [{
      a: {
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }
    }]
  }
}
export const ACTION_VIEW_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onView"
        },
        childrens: [{
          i: {
            class: "fa fa-eye fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.OrderId != null || this.OrderId != undefined) {
              return "/orders/" + this.OrderId + "?activeTab=sales-order";
            }
            if (this.desktopAssetId != null || this.desktopAssetId != undefined) {
              return "/desktop-assets/" + this.desktopAssetId+ "?activeTab=edit-info";
            }
            if (this.MobileAssetId != null || this.MobileAssetId != undefined) {
              return "/mobile-assets/" + this.MobileAssetId+ "?activeTab=edit-info";
            }
            else {
              return "/users";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.OrderId != null || this.OrderId != undefined) {
              return "/orders/" + this.OrderId + "?activeTab=sales-order";
            }
            if (this.desktopAssetId != null || this.desktopAssetId != undefined) {
              return "/desktop-assets/" + this.desktopAssetId+ "?activeTab=edit-info";
            }
            if (this.MobileAssetId != null || this.MobileAssetId != undefined) {
              return "/mobile-assets/" + this.MobileAssetId+ "?activeTab=edit-info";
            }
            else {
              return "/users";
            }
          },
          onClick: "return false;"
        }
      }
    }]
  }
}
export const DASHBOARD_ACTION_VIEW_TEMPLATE: TemplateConfig = {
  // div: {
  //   childrens: [{
  //     a: {
  //       event: {
  //         click: "onView"
  //       },
  //       childrens: [{
  //         i: { class: "fa fa-eye fa-4x font-md text-default".split(" ") }
  //       }],
  //     }
  //   }]
  // }
  div: {
    childrens: [ 
      {
        a: {
          event: {
            click: "onView"
          },
          childrens: [{
            i: {
              class: "fa fa-eye fa-4x font-md text-default".split(" "),
              attributes: { "title": getLocalizedValue("Title_View") }
            },
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              var registrationFrom = new Date();
              if (this.Name == "Registered Customers") {
                if (this.countFor24HourId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 1);
                }
                else if (this.count7DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 7);
                }
                else if (this.count14DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 14);
                }
                else if (this.count1MonthId) {
                  registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }
                else {
                  registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }

                var formattedDate = registrationFrom.toISOString().split('T')[0];
                return "/users?registrationFrom=" + formattedDate + "&isFromDashboard=true" + "&Roleid=14"  ;
              }
              else {
                if (this.countFor24HourId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 1);
                }
                else if (this.count7DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 7);
                }
                else if (this.count14DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 14);
                }
                else if (this.count1MonthId) {
                  registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }
                else {
                  registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }
                var formattedDate = registrationFrom.toISOString().split('T')[0];
                return "/faberge-team-login-report?registrationFrom=" + formattedDate + "&isFromDashboard=true";
              }
            },
            "ng-reflect-router-link": function (e) {
              var registrationFrom = new Date();
              if (this.Name == "Registered Customers") {
                if (this.countFor24HourId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 1);
                }
                else if (this.count7DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 7);
                }
                else if (this.count14DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 14);
                }
                else if (this.count1MonthId) {
                  registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }
                else {
                  registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }

                var formattedDate = registrationFrom.toISOString().split('T')[0];
                return "/users?registrationFrom=" + formattedDate + "&isFromDashboard=true" + "&Roleid=14";
              }
              else {
                if (this.countFor24HourId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 1);
                }
                else if (this.count7DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 7);
                }
                else if (this.count14DaysId) {
                  registrationFrom.setDate(registrationFrom.getDate() - 14);
                }
                else if (this.count1MonthId) {
                  registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }
                else {
                  registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() );
                }
                var formattedDate = registrationFrom.toISOString().split('T')[0];
                return "/faberge-team-login-report?registrationFrom=" + formattedDate + "&isFromDashboard=true";
              }

            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
}

export const ACTION_VIEW_TEMPLATE_LEAVE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onView"
        },
        childrens: [{
          i: {
            class: "fa fa-eye fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View") }
          }
        }],
      }
    }]
  }
}
export const ACTION_SELECT_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onSelect"
        },
        childrens: [{
          i: { class: "btn btn-success fa fa-check".split(" ") }
        }]
      }
    }]
  }
}

export const ACTION_SHOPPING_BAG_TEMPLATE: TemplateConfig = {
  a: {
    event: {
      click: "onShoppingBag"
    },
    childrens: [{
      i: {
        class: "fr-bag".split(" "),
        attributes: { "title": getLocalizedValue("Title_AddToCart") }
      }
    }],
    class: "mr-2 place-order-cart text-blue font-xmd".split(" ")
  }
}

export const ACTION_SUPPLIER_POS_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.posId != null || this.posId != undefined) {
              return "/pointof-sales/" + this.posId + "?supplierId=" + this.supplierId;
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.UserId != null || this.UserId != undefined) {
              return "/pointof-sales/" + this.posId + "?supplierId=" + this.supplierId;
            }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}
export const ACTION_HISTORY_MIRFABERGE_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.newsId != null || this.newsId != undefined) {
              return "/history-mir-faberge/" + this.newsId;
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.newsId != null || this.newsId != undefined) {
              return "/history-mir-faberge/" + this.newsId;
            }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}
export const ACTION_FOR_MODEL_EDIT_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
      }
    }]
  }
}

export const ACTION_FOR_MODEL_LIST_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onClick"
        },
        childrens: [{
          i: {
            class: "fa fa-list fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View") }
          }
        }],
      }
    }]
  }
}



export const ACTION_GRID_TEMPLATE_LOGS: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}

export const ACTION_GRID_SPECIFICRISK: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.specificRiskId != null || this.specificRiskId != undefined) {
              return "/specific-risk/" + this.specificRiskId;
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.specificRiskId != null || this.specificRiskId != undefined) {
              return "/specific-risk," + this.specificRiskId;
            }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}



export const ACTION_GRID_FABGEMSTONE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        authorize: "delete",
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}
export const ACTION_GRID_TEMPLATE_IAN: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        isBind: (x) => {
          if (x.total != 'Total') {
            return true;
          }
          else {
            return false;

          }
        },
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fr-save fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          title: "save/update",
          href: function (e) {
            if (this.UserId != null || this.UserId != undefined) {
              return "/users/" + this.UserId + "?activeTab=user-edit";
            }

            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.companyId != null || this.companyId != undefined) {
              return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
            }
            if (this.CompanyID != null || this.CompanyID != undefined) {
              return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
            }
            if (this.watchStatusId != null || this.watchStatusId != undefined) {
              return "/watch-status/" + this.watchStatusId
            }
            if (this.legalOwnerId != null || this.legalOwnerId != undefined) {
              return "/legal-owner/" + this.legalOwnerId
            }
            if (this.watchItemTypeId != null || this.watchItemTypeId != undefined) {
              return "/watch-item-types/" + this.watchItemTypeId
            }
            if ((this.watchMaterialId != null || this.watchMaterialId != undefined)) {
              return "/watch-materials/" + this.watchMaterialId
            }
            if (this.clarityId != null || this.clarityId != undefined) {
              return "/gem-stone-clarity-gemfields/" + this.clarityId
            }
            if (this.enhancementId != null || this.enhancementId != undefined) {
              return "/gemfields-enhancement/" + this.enhancementId
            }
            if (this.cutTypeId != null || this.cutTypeId != undefined) {
              return "/gem-stone-cut-types/" + this.cutTypeId
            }
            if (this.materialId != null || this.materialId != undefined) {
              return "/materials/" + this.materialId
            }
            if (this.fabergeColorId != null || this.fabergeColorId != undefined) {
              return "/colors/" + this.fabergeColorId
            }
            if (this.carrierId != null || this.carrierId != undefined) {
              return "/gemfields-carrier/" + this.carrierId
            }
            if (this.colorId != null || this.colorId != undefined) {
              return "/gem-stone-colors/" + this.colorId
            }
            if (this.locationId != null || this.locationId != undefined) {
              return "/gemfields-location/" + this.locationId
            }
            if (this.arrangementId != null || this.arrangementId != undefined) {
              return "/gemfields-arrangements/" + this.arrangementId
            }
            if (this.productTypeId != null || this.productTypeId != undefined) {
              return "/product-types/" + this.productTypeId
            }
            if (this.temporaryAssigneeTypeID != null || this.temporaryAssigneeTypeID != undefined) {
              return "/temporary-assignee-types/" + this.temporaryAssigneeTypeID
            }
            if (this.stationId != null || this.stationId != undefined) {
              return "/gemfields-station/" + this.stationId
            }
            if (this.enhancementTypeId != null || this.enhancementTypeId != undefined) {
              return "/gemfields-enhancement-type/" + this.enhancementTypeId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/gemfield-sales-channels/" + this.salesChannelId
            }
            if (this.gemFieldsBookingStatusId != null || this.gemFieldsBookingStatusId != undefined) {
              return "/gem-fields-booking-statuses/" + this.gemFieldsBookingStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "true") {
              return "/holiday-lists/" + this.holidayId
            }
            if (this.messageTemplateID != null || this.messageTemplateID != undefined) {
              return "/message-templates/" + this.messageTemplateID
            }
            if (this.emailAccountId != null || this.emailAccountId != undefined) {
              return "/email-account/" + this.emailAccountId
            }
            if (this.bannedIpNetworkId != null || this.bannedIpNetworkId != undefined) {
              return "/banned-ip-network/" + this.bannedIpNetworkId
            }
            if (this.bannedIpAddressID != null || this.bannedIpAddressID != undefined) {
              return "/banned-ip-addresses/" + this.bannedIpAddressID
            }
            if (this.measureDimensionId != null || this.measureDimensionId != undefined) {
              return "/measure-dimensions/" + this.measureDimensionId
            }
            if (this.measureWeightId != null || this.measureWeightId != undefined) {
              return "/measure-weights/" + this.measureWeightId
            }
            if (this.settingID != null || this.settingID != undefined) {
              return "/settings/" + this.settingID
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId
            }
            if (this.creditCardTypeId != null || this.creditCardTypeId != undefined) {
              return "/credit-card-types/" + this.creditCardTypeId
            }
            if (this.paymentMethodId != null || this.paymentMethodId != undefined) {
              return "/payment-methods/" + this.paymentMethodId
            }
            if (this.discountId != null || this.discountId != undefined) {
              return "/discounts/" + this.discountId
            }
            if (this.taxCategoryId != null || this.taxCategoryId != undefined) {
              return "/tax-category/" + this.taxCategoryId
            }
            if (this.shippingMethodID != null || this.shippingMethodID != undefined) {
              return "/shipping-method/" + this.shippingMethodID
            }
            if (this.stateProvinceId != null || this.stateProvinceId != undefined) {
              return "/state-provinces/" + this.stateProvinceId
            }
            if (this.deliveryTypeId != null || this.deliveryTypeId != undefined) {
              return "/delivery-types/" + this.deliveryTypeId;
            }
            if ((this.languageId != null || this.languageId != undefined) && (this.localizationKey == null || this.localizationKey == undefined)) {
              return "/languages/" + this.languageId
            }
            if (this.categoryId != null || this.categoryId != undefined) {
              if (this.categoryTypeId == CategoryEnum.Design) {
                return "/categories/" + this.categoryId + "?activeTab=categories";
              }
              else if (this.categoryTypeId == CategoryEnum.Bom) {
                return "/bom/" + this.categoryId
              }
              else if (this.categoryTypeId == CategoryEnum.Gemstone) {
                return "/gemstone/" + this.categoryId
              }
            }
            if (this.warehouseId != null || this.warehouseId != undefined) {
              return "/warehouses/" + this.warehouseId
            }
            if (this.brandId != null || this.brandId != undefined) {
              return "/p-o-s-brands/" + this.brandId
            }
            if (this.pieceOwnerId != null || this.pieceOwnerId != undefined) {
              return "/piece-owners/" + this.pieceOwnerId
            }
            if (this.roleId != null || this.roleId != undefined) {
              return "/roles/" + this.roleId
            }
            if (this.customerRelationShipId != null || this.customerRelationShipId != undefined) {
              return "/customer-relationships/" + this.customerRelationShipId
            }
            if (this.countryId != null || this.countryId != undefined) {
              return "/countries/" + this.countryId
            }
            if (this.collectionId != null || this.collectionId != undefined) {
              return "/main-collections/" + this.collectionId  + "?activeTab=main-collections";
            }
            if (this.productAttributeId != null || this.productAttributeId != undefined) {
              return "/product-attributes/" + this.productAttributeId
            }
            if (this.currencyId != null || this.currencyId != undefined) {
              return "/currencies/" + this.currencyId
            }
            if (this.titleId != null || this.titleId != undefined) {
              return "/titles/" + this.titleId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/sales-channels/" + this.salesChannelId
            }
            if (this.boutiqueId != null || this.boutiqueId != undefined) {
              return "/boutiques/" + this.boutiqueId
            }
            if (this.pieceDeliveryStatusId != null || this.pieceDeliveryStatusId != undefined) {
              return "/piece-delivery-statuses/" + this.pieceDeliveryStatusId
            }
            if (this.productStatusId != null || this.productStatusId != undefined) {
              return "product-variant-statuses/" + this.productStatusId
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.sizeId != null || this.sizeId != undefined) {
              return "/ring-egg-sizes/" + this.sizeId;
            }
            if (this.fileExtentionId != null || this.fileExtentionId != undefined) {
              return "/file-extentions/" + this.fileExtentionId;
            }
            if (this.applicationObjectId != null || this.applicationObjectId != undefined) {
              return "/application-object/" + this.applicationObjectId + "?activeTab=edit-info";
            }
            if (this.occurrenceTypeId != null || this.occurrenceTypeId != undefined)
            {
              return "/occurrence-types/" + this.occurrenceTypeId
            }
            if (this.bangleSizeId != null || this.bangleSizeId != undefined) {
              return "/bangle-sizes/" + this.bangleSizeId;
            }
            if (this.dutyStatusId != null || this.dutyStatusId != undefined) {
              return "/duty-statuses/" + this.dutyStatusId;
            }
            if (this.noteTypeId != null || this.noteTypeId != undefined) {
              return "/note-type/" + this.noteTypeId;
            }

            if (this.nationalityId != null || this.nationalityId != undefined) {
              return "/nationalities/" + this.nationalityId;
            }
            if (this.industryTypeId != null || this.industryTypeId != undefined) {
              return "/industry-types/" + this.industryTypeId;
            }
            if (this.tripTypeId != null || this.tripTypeId != undefined) {
              return "/trip-types/" + this.tripTypeId;
            }
            if (this.lengthId != null || this.lengthId != undefined) {
              return "/chain-lengths/" + this.lengthId;
            }
            if (this.EventTrainingTypeId != null || this.EventTrainingTypeId != undefined) {
              return "/training-types/" + this.EventTrainingTypeId;
            }
            if (this.chainColourId != null || this.chainColourId != undefined) {
              return "/chain-colours/" + this.chainColourId;
            }
            if (this.departmentId != null || this.departmentId != undefined) {
              return "/departments/" + this.departmentId;
            }
            if (this.locationGroupId != null || this.locationGroupId != undefined) {
              return "/location-groups/" + this.locationGroupId;
            }
            if (this.originId != null || this.originId != undefined) {
              return "/gemstone-origins/" + this.originId;
            }
            if (this.leaveId != null || this.leaveId != undefined) {
              return "/leave-types/" + this.leaveId + "?activeTab=leave-type-info";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/customer-holidays/" + this.masterId;
            }
            if ((this.gemstoneStatusId != null || this.gemstoneStatusId != undefined) && this.isGemfield == 'false') {
              return "/gemstone-statuses/" + this.gemstoneStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "false") {
              return "/bank-holidays/" + this.holidayId
            }
            if (this.supplierId != null || this.supplierId != undefined) {
              return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
            }
            if (this.carnetId != null || this.carnetId != undefined) {
              return "/carnets/" + this.carnetId;
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/faberge-price-lists/" + this.tripId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
              return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
            }
            if (this.mainMenuItemId != null || this.mainMenuItemId != undefined) {
              return "/menus/" + this.mainMenuItemId;
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
            if (this.riskCategoryId != null || this.riskCategoryId != undefined) {
              return "/risk-categories/" + this.riskCategoryId;
            }
            if (this.helpSupportId != null || this.helpSupportId != undefined) {
              return "/help-supports/" + this.helpSupportId;
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.chainGaugeID != null || this.chainGaugeID != undefined) {
              return "/chain-gauges/" + this.chainGaugeID;
            }
            if (this.chainStyleID != null || this.chainStyleID != undefined) {
              return "/chain-styles/" + this.chainStyleID;
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.UserId != null || this.UserId != undefined) {
              return "/users," + this.UserId + "?activeTab=user-edit";
            }

            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.companyId != null || this.companyId != undefined) {
              return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
            }
            if (this.CompanyID != null || this.CompanyID != undefined) {
              return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
            }
            if (this.watchStatusId != null || this.watchStatusId != undefined) {
              return "/watch-status/" + this.watchStatusId
            }
            if (this.legalOwnerId != null || this.legalOwnerId != undefined) {
              return "/legal-owner/" + this.legalOwnerId
            }
            if (this.watchItemTypeId != null || this.watchItemTypeId != undefined) {
              return "/watch-item-types/" + this.watchItemTypeId
            }
            if ((this.watchMaterialId != null || this.watchMaterialId != undefined)) {
              return "/watch-materials/" + this.watchMaterialId
            }
            if (this.clarityId != null || this.clarityId != undefined) {
              return "/gem-stone-clarity-gemfields/" + this.clarityId
            }
            if (this.enhancementId != null || this.enhancementId != undefined) {
              return "/gemfields-enhancement/" + this.enhancementId
            }
            if (this.cutTypeId != null || this.cutTypeId != undefined) {
              return "/gem-stone-cut-types/" + this.cutTypeId
            }
            if (this.materialId != null || this.materialId != undefined) {
              return "/materials/" + this.materialId
            }
            if (this.fabergeColorId != null || this.fabergeColorId != undefined) {
              return "/colors/" + this.fabergeColorId
            }
            if (this.carrierId != null || this.carrierId != undefined) {
              return "/gemfields-carrier/" + this.carrierId
            }
            if (this.colorId != null || this.colorId != undefined) {
              return "/gem-stone-colors/" + this.colorId
            }
            if (this.locationId != null || this.locationId != undefined) {
              return "/gemfields-location/" + this.locationId
            }
            if (this.arrangementId != null || this.arrangementId != undefined) {
              return "/gemfields-arrangements/" + this.arrangementId
            }
            if (this.productTypeId != null || this.productTypeId != undefined) {
              return "/product-types/" + this.productTypeId
            }
            if (this.temporaryAssigneeTypeID != null || this.temporaryAssigneeTypeID != undefined) {
              return "/temporary-assignee-types/" + this.temporaryAssigneeTypeID
            }
            if (this.stationId != null || this.stationId != undefined) {
              return "/gemfields-station/" + this.stationId
            }
            if (this.enhancementTypeId != null || this.enhancementTypeId != undefined) {
              return "/gemfields-enhancement-type/" + this.enhancementTypeId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/gemfield-sales-channels/" + this.salesChannelId
            }
            if (this.gemFieldsBookingStatusId != null || this.gemFieldsBookingStatusId != undefined) {
              return "/gem-fields-booking-statuses/" + this.gemFieldsBookingStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "true") {
              return "/holiday-lists/" + this.holidayId
            }
            if (this.messageTemplateID != null || this.messageTemplateID != undefined) {
              return "/message-templates/" + this.messageTemplateID
            }
            if (this.emailAccountId != null || this.emailAccountId != undefined) {
              return "/email-account/" + this.emailAccountId
            }
            if (this.bannedIpNetworkId != null || this.bannedIpNetworkId != undefined) {
              return "/banned-ip-network/" + this.bannedIpNetworkId
            }
            if (this.bannedIpAddressID != null || this.bannedIpAddressID != undefined) {
              return "/banned-ip-addresses/" + this.bannedIpAddressID
            }
            if (this.measureDimensionId != null || this.measureDimensionId != undefined) {
              return "/measure-dimensions/" + this.measureDimensionId
            }
            if (this.measureWeightId != null || this.measureWeightId != undefined) {
              return "/measure-weights/" + this.measureWeightId
            }
            if (this.settingID != null || this.settingID != undefined) {
              return "/settings/" + this.settingID
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId
            }
            if (this.creditCardTypeId != null || this.creditCardTypeId != undefined) {
              return "/credit-card-types/" + this.creditCardTypeId
            }
            if (this.paymentMethodId != null || this.paymentMethodId != undefined) {
              return "/payment-methods/" + this.paymentMethodId
            }
            if (this.discountId != null || this.discountId != undefined) {
              return "/discounts/" + this.discountId
            }
            if (this.taxCategoryId != null || this.taxCategoryId != undefined) {
              return "/tax-category/" + this.taxCategoryId
            }
            if (this.shippingMethodID != null || this.shippingMethodID != undefined) {
              return "/shipping-method/" + this.shippingMethodID
            }
            if (this.stateProvinceId != null || this.stateProvinceId != undefined) {
              return "/state-provinces/" + this.stateProvinceId
            }
            if (this.deliveryTypeId != null || this.deliveryTypeId != undefined) {
              return "/delivery-types/" + this.deliveryTypeId;
            }
            if ((this.languageId != null || this.languageId != undefined) && (this.localizationKey == null || this.localizationKey == undefined)) {
              return "/languages/" + this.languageId
            }
            if (this.categoryId != null || this.categoryId != undefined) {
              if (this.categoryTypeId == CategoryEnum.Design) {
                return "/categories/" + this.categoryId + "?activeTab=categories";
              }
              else if (this.categoryTypeId == CategoryEnum.Bom) {
                return "/bom/" + this.categoryId
              }
              else if (this.categoryTypeId == CategoryEnum.Gemstone) {
                return "/gemstone/" + this.categoryId
              }
            }
            if (this.warehouseId != null || this.warehouseId != undefined) {
              return "/warehouses/" + this.warehouseId
            }
            if (this.brandId != null || this.brandId != undefined) {
              return "/p-o-s-brands/" + this.brandId
            }
            if (this.pieceOwnerId != null || this.pieceOwnerId != undefined) {
              return "/piece-owners/" + this.pieceOwnerId
            }
            if (this.roleId != null || this.roleId != undefined) {
              return "/roles/" + this.roleId
            }
            if (this.customerRelationShipId != null || this.customerRelationShipId != undefined) {
              return "/customer-relationships/" + this.customerRelationShipId
            }
            if (this.countryId != null || this.countryId != undefined) {
              return "/countries/" + this.countryId
            }
            if (this.collectionId != null || this.collectionId != undefined) {
              return "/main-collections/" + this.collectionId  + "?activeTab=main-collections";
            }
            if (this.productAttributeId != null || this.productAttributeId != undefined) {
              return "/product-attributes/" + this.productAttributeId
            }
            if (this.currencyId != null || this.currencyId != undefined) {
              return "/currencies/" + this.currencyId
            }
            if (this.titleId != null || this.titleId != undefined) {
              return "/titles/" + this.titleId
            }
            if (this.salesChannelId != null || this.salesChannelId != undefined) {
              return "/sales-channels/" + this.salesChannelId
            }
            if (this.boutiqueId != null || this.boutiqueId != undefined) {
              return "/boutiques/" + this.boutiqueId
            }
            if (this.pieceDeliveryStatusId != null || this.pieceDeliveryStatusId != undefined) {
              return "/piece-delivery-statuses/" + this.pieceDeliveryStatusId
            }
            if (this.productStatusId != null || this.productStatusId != undefined) {
              return "product-variant-statuses/" + this.productStatusId
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.sizeId != null || this.sizeId != undefined) {
              return "/ring-egg-sizes/" + this.sizeId;
            }
            if (this.fileExtentionId != null || this.fileExtentionId != undefined) {
              return "/file-extentions/" + this.fileExtentionId;
            }
            if (this.applicationObjectId != null || this.applicationObjectId != undefined) {
              return "/application-object/" + this.applicationObjectId + "?activeTab=edit-info";
            }
            if (this.occurrenceTypeId != null || this.occurrenceTypeId != undefined)
            {
              return "/occurrence-types/" + this.occurrenceTypeId
            }
            if (this.bangleSizeId != null || this.bangleSizeId != undefined) {
              return "/bangle-sizes/" + this.bangleSizeId;
            }

            if (this.dutyStatusId != null || this.dutyStatusId != undefined) {
              return "/duty-statuses/" + this.dutyStatusId;
            }
            if (this.noteTypeId != null || this.noteTypeId != undefined) {
              return "/note-type/" + this.noteTypeId;
            }
            if (this.nationalityId != null || this.nationalityId != undefined) {
              return "/nationalities/" + this.nationalityId;
            }
            if (this.industryTypeId != null || this.industryTypeId != undefined) {
              return "/industry-types/" + this.industryTypeId;
            }

            if (this.tripTypeId != null || this.tripTypeId != undefined) {
              return "/trip-types/" + this.tripTypeId;
            }
            if (this.lengthId != null || this.lengthId != undefined) {
              return "/chain-lengths/" + this.lengthId;
            }
            if (this.EventTrainingTypeId != null || this.EventTrainingTypeId != undefined) {
              return "/training-types/" + this.EventTrainingTypeId;
            }
            if (this.chainColourId != null || this.chainColourId != undefined) {
              return "/chain-colours/" + this.chainColourId;
            }
            if (this.departmentId != null || this.departmentId != undefined) {
              return "/departments/" + this.departmentId;
            }
            if (this.locationGroupId != null || this.locationGroupId != undefined) {
              return "/location-groups/" + this.locationGroupId;
            }
            if (this.originId != null || this.originId != undefined) {
              return "/gemstone-origins/" + this.originId;
            }
            if (this.leaveId != null || this.leaveId != undefined) {
              return "/leave-types/" + this.leaveId + "?activeTab=leave-type-info";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/customer-holidays/" + this.masterId;
            }
            if ((this.gemstoneStatusId != null || this.gemstoneStatusId != undefined) && this.isGemfield == 'false') {
              return "/gemstone-statuses/" + this.gemstoneStatusId
            }
            if ((this.holidayId != null || this.holidayId != undefined) && this.isGemfield == "false") {
              return "/bank-holidays/" + this.holidayId
            }
            if (this.supplierId != null || this.supplierId != undefined) {
              return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
            }
            if (this.carnetId != null || this.carnetId != undefined) {
              return "/carnets/" + this.carnetId;
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/faberge-price-lists/" + this.tripId;
            }
            if (this.newsId != null || this.newsId != undefined) {
              return "/content-management-news/" + this.newsId;
            }
            if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
              return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
            }
            if (this.mainMenuItemId != null || this.mainMenuItemId != undefined) {
              return "/menus/" + this.mainMenuItemId;
            }
            if (this.applicationExceptionLogId != null || this.applicationExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.applicationExceptionLogId + "?log=old-log";
            }
            if (this.ExceptionLogId != null || this.ExceptionLogId != undefined) {
              return "/application-exception-logs/" + this.ExceptionLogId + "?log=new-log";
            }
            if (this.riskCategoryId != null || this.riskCategoryId != undefined) {
              return "/risk-categories/" + this.riskCategoryId;
            }
            if (this.helpSupportId != null || this.helpSupportId != undefined) {
              return "/help-supports/" + this.helpSupportId;
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.chainGaugeID != null || this.chainGaugeID != undefined) {
              return "/chain-gauges/" + this.chainGaugeID;
            }
            if (this.chainStyleID != null || this.chainStyleID != undefined) {
              return "/chain-styles/" + this.chainStyleID;
            }
          },
          onClick: "return false;"

        },
        class: ["mr-2"]
      }
    }, {
      a: {
        authorize: "delete",
        isBind: (x) => {
          if (x.total != 'Total') {
            return true;
          }
          else {
            return false;

          }
        },
        event: {
          click: "onDelete"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }

    }]
  }
}

export const LEGAL_ARGREEMENT_SPECIFICRISK_VIEW_LIST_GRID: TemplateConfig = {
  div: {
    childrens: [{
      span: {
        childrens: [{
          text: {
            text: function (e) {
              return this.specificRiskId + ' - ' + this.specificRiskName;
            }
          },
        }]
      },
    }]
  }
}

export const ACTION_GRID_TEMPLATE_PRODUCTION: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          authorize: "edit",
          event: {
            click: "onEdit"
          },
          childrens: [{
            i: {
              class: "fa fa-edit fa-4x font-md text-default".split(" "),
              attributes: { "title": getLocalizedValue("Title_Edit") }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.formId != null || this.formId != undefined) {
                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.formId != null || this.formId != undefined) {
                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
              }
            },
            onClick: "return false;"
          },
          class: ["mr-2"]
        }
      }, {
        a: {
          authorize: "delete",
          event: {
            click: "onDelete"
          },
          // isBind: (x) => {
          //   return (x.isAllowDelete == 'true') ? true : false
          // },

          childrens: [{
            i: {
              class: "fr-delete fa-4x font-md text-danger".split(" "),
              attributes: { "title": getLocalizedValue("Title_Delete") }
            }
          }]
        }
      }
    ]
  }
}

export const ACTION_FOR_MODEL_VIEW_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onView"
        },
        childrens: [{
          i: {
            class: "fa fa-eye fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View") }
          }
        }],
      }
    }]
  }
}

export const ACTION_FOR_MODEL_VIEW_TEMPLATE_REPORTING_HEAD: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        isBind: (x)=> {
          if(x.reportToId != 0 && x.reportTo != null && x.reportTo != undefined && x.reportTo != "") {
            return true;
          }
          else {
            return false;
          }
        },
        event: {
          click:"onViewRH"
        },
        childrens: [{
          i: {
            class: "fa fa-eye fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View_ReportTo") }
          }
        }]
      }
    }]
  }
}

export const ACTION_FOR_MODEL_VIEW_TEMPLATE_MIND_MAP: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        isBind: (x) => {
          if (x.isRestrictedAllowed == false) {
            return false;
          }
          else {
            return true;
          }
        },
        event: {
          click: "onView"
        },
        childrens: [{
          i: {
            class: "fa fa-eye fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View") }
          }
        }],
      }
    }]
  }
}

export const ACTION_LEGAL_AGREEMENT_MAPPING_DELETE_TEMPLATE: TemplateConfig = {
  div: {
    isBind : (x)=>{
      if(x.isAccess)
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    childrens: [{
      a: {
        event: {
          click: "onDeleteMapping"
        },
        childrens: [{
          i: {
            class: "fr-delete fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Delete") }
          }
        }]
      }
    }]
  }
}


export const ACTION_GRID_TEMPLATE_GROUP_COMPANY: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          authorize: "edit",
          event: {
            click: "onEdit"
          },
          childrens: [{
            i: {
              class: "fa fa-edit fa-4x font-md text-default".split(" "),
              attributes: { "title": getLocalizedValue("Title_Edit") }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.supplierId != null || this.supplierId != undefined) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.supplierId != null || this.supplierId != undefined) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
            },
            onClick: "return false;"
          },
          class: ["mr-2"]
        }
      }, {
        a: {
          authorize: "delete",
          isBind: (x) => {
            return (x.corporateCompanyMapppingId != 0)
          },
          event: {
            click: "onDelete"
          },
          childrens: [{
            i: {
              class: "fr-delete fa-4x font-md text-danger".split(" "),
              attributes: { "title": getLocalizedValue("Title_Delete") }
            }
          }]
        }
      }
    ]
  }
}

export const ITHELPDESK_DELETEACTION_GRID_TEMPLATE : TemplateConfig = {
  div: {
    isBind: (x) => { 
      if( x.ticketCustomerId ==0){
        return false;
      }
      else{
        return true;
      }
  },
    childrens: [{
        a: {
            event: {
                click: "onDelete"
            },
            childrens: [{
                i: {
                    class: "fr-delete fa-4x font-md text-danger".split(" "),
                    attributes: { "title": getLocalizedValue("Title_Delete") }
                }
            }]
        }
    }]
}
}

export const ACTION_VIEW_TEMPLATE_ACCESS_REQUEST: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onApproveRequest"
        },
        childrens: [{
          i: {
            class: "fa fa-check fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Approve") }
          }
        }],
        class: ["mr-2"]
      }
    }, {
      a: {
        event: {
          click: "onRejectRequest"
        },
        childrens: [{
          i: {
            class: "fa fa-times fa-4x font-md text-danger".split(" "),
            attributes: { "title": getLocalizedValue("Title_Reject") }
          }
        }]
      }
    }]
  }
}
