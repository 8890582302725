import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { Language } from '@app/models';
import { AbstractLanguage } from '../domain/abstract-language';
import {Router} from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { LanguageEditComponent } from '../edit/language-edit.component';
import { CultureInfoViewModel } from 'src/app/view-model/culture-info-view-model';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@multilingual("LanguageAddComponent")
@access({ accessLevel: RolePermissionEnums.Languages, action: "post" })

@Component({
    selector: "app-language-add",
    templateUrl: './language-add.component.html'
})
export class LanguageAddComponent extends AbstractLanguage implements OnInit, OnDestroy {
    language: Language;
    subscription: any;
    languageEdit:any=LanguageEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder,private router:Router, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.Languages;
        this._this = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Languages - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.lookup([{ path: USER_LOOKUPS.cultureInfoLookUp
          , propName: "culturesLookUp" }
          , {  path: USER_LOOKUPS.customerRoleDomainLookUp, propName: "customerRoleDomainLookUp" }]).subscribe(response => {
        this.languageCultureLookup.culturesLookUp=new Array<CultureInfoViewModel>();
        this.languageCultureLookup = response;
        this.languageCultureLookup.customerRoleDomainLookUp.splice(this.languageCultureLookup.customerRoleDomainLookUp.map(e => e.customerRoleDomainName).indexOf('Faberge'), 1)
        this.languageCultureLookup.customerRoleDomainLookUp[this.languageCultureLookup.customerRoleDomainLookUp.map(e => e.customerRoleDomainName).indexOf('Gemfields')].customerRoleDomainName = "G-Trac"
        this.language = new Language();
        this.language.languageId=0;
        this.language.statusId=StatusEnum.Active;
        this.language.languageCulture=" ";
        // let languageCulture=new CultureInfoViewModel();
        // languageCulture.ietfLanguageTag=" ";
        // languageCulture.name="Select";
        // this.languageCultureLookup.culturesLookUp.unshift(languageCulture);
        this.languageFormGroup = this.formBuilder.formGroup(this.language) as IFormGroup<Language>;
        this.showComponent=true;

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addLanguage(isRedirect:boolean) {
        this.languageFormGroup.submitted = true;
        if(this.languageFormGroup.valid)
        {
            this.spin=true;
            this.post({ body: this.languageFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect?this.router.navigate(['/languages',data]):this.router.navigate(['/languages']);

            })
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.languageFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }

    }

    get componentName(): string {
      return "LanguageAddComponent";
    }
}
