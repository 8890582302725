import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class FormsDocumentBase {

//#region FormId Prop
        @prop()
        FormId : number;
//#endregion FormId Prop


//#region name Prop
        @required()
        @maxLength({value:80})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop



//#region description Prop
        // @required()
        // @notEmpty()
        @maxLength({value:4000})
        @trim()
        description : string;
//#endregion description Prop


//#region displayOrderId Prop
        @prop()
        @numeric()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        displayOrderId : number;
//#endregion displayOrderId Prop

//#region displayOrderId Prop
@prop({defaultValue:0})
@numeric()
// @range({ minimumNumber: 0, maximumNumber: 2147483647 })
// @required()
userRoleId : number;
//#endregion displayOrderId Prop


//#region body Prop
        @prop()
      //  @maxLength({value:4000})
        @trim()
        filename : string;
//#endregion body Prop


//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region IsActive Prop
@prop({defaultValue:false})
isActive : boolean;
//#endregion IsActive Prop



}
