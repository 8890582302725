import {vDesignProductStatusRecordBase,} from '@app/database-models'
//Generated Imports
export class vDesignProductStatusRecord extends vDesignProductStatusRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}