import { vProductStatusForReportLookUpBase } from '../database-models/v-product-status-for-report-look-up-base';

//Generated Imports
export class vProductStatusForReportLookUp extends vProductStatusForReportLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}