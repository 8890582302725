import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { http, RxHttp } from "@rxweb/http";
import { Observable } from "rxjs";
import { ResetPasswordService } from "src/app/components/authentication/reset-password/reset-password-service";
import { ResetPasswordModel } from "src/app/components/authentication/reset-password/reset-password.component";
import { ResetPasswordViewModel } from "src/app/view-model/reset-password-view-model";
import { BaseToastr } from "../customize-design/toastr";
import { BrowserStorage } from "./browser-storage";

export var userGuid: any;
export var emailTemplateLookup: any;

@Injectable()
export class AnonymousTokenGenerator implements CanActivate {
    route: ActivatedRouteSnapshot;
    resetPasswordModel: ResetPasswordViewModel;
    http: RxHttp;
    ispasswordUpdate: boolean;
    isLoaded: boolean;
    storage: BrowserStorage;
    anonymousToken: any;
    toastr: BaseToastr;
    constructor(private activatedRoute: ActivatedRoute, private router: Router, private resetPasswordService: ResetPasswordService) {
        this.storage = new BrowserStorage();
        this.http = new RxHttp();
        this.resetPasswordModel = new ResetPasswordViewModel();
        this.toastr = new BaseToastr();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        var promise = new Promise<boolean>((resolve, reject) => {

            this.http.get({ path: "api/authentication" }).subscribe((t: any) => {
                this.anonymousToken = t;
                this.storage.local.save("anonymousAuth", this.anonymousToken, false);
                console.log(route.url)
                Array.from(route.url).forEach(element => {
                    if (element.path == "reset-password") {
                        if (route.params)
                            this.resetPasswordModel.userId = (route.params['id'] == undefined || route.params['id'] == null) ? 0 : route.params['id'];
                        if (route.queryParams)
                            this.resetPasswordModel.guid = (route.queryParams['qry'] == null || route.queryParams['qry'] == undefined) ? "" : route.queryParams['qry'];
                        userGuid = this.resetPasswordModel.guid;
                        this.ispasswordUpdate = this.resetPasswordModel.guid == "" ? true : false;
                        this.resetPasswordModel.isCheckpassword = this.ispasswordUpdate;
                        this.resetPasswordService.CheckUser(this.resetPasswordModel).subscribe(t => {

                            if (Number(t) == 0) {
                                this.toastr.error("Token is not valid. Please do forgot password again.");
                                this.router.navigate(['/login']);
                            }
                        });
                    }

                });
                if (t)
                    resolve(true);

            })


            return promise;
        })
        return true;
    }
}
