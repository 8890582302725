<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/email-account']" rxText="Label_Email_Accounts_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="EmailAccountEditComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="EmailAccountEditComponent_Title"></label></h5>
        <a (click)="showSystemHelp()" class="float-right mt-1" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
        <button (click)="callMicrosoftOAuth()" class="btn btn-success mx-2 float-right"  rxText="EmailAccountAddEditComponent_OAuthButton_t"></button>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="emailAccountFormGroup"
         [rxSpinner]="spin">
        <div class="row add-countries-form" (keyup.enter)="editEmailAccount(false)">
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="EmailAccountEditComponent_ID_t"></label>
              <div class="col-md-8">
                <label type="text" 
                            class="form-control disableLabel-withoutheight mb-0">{{(emailAccountFormGroup.value.emailAccountId
                            === null || emailAccountFormGroup.value.emailAccountId === undefined ) ? '' :
                            emailAccountFormGroup.value.emailAccountId}}</label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_EmailAddress_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="email" class="form-control " tabindex="1" 
                  rxPlaceholder="EmailAccountAddEditComponent_EmailAddress_p" rxFocus />
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.email.errors">{{emailAccountFormGroup.controls.email.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_Password_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="password" class="form-control " tabindex="4"
                   rxPlaceholder="EmailAccountAddEditComponent_Password_p" />
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.password.errors">{{emailAccountFormGroup.controls.password.errorMessage}}</small>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-4 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_EmailAccountPort_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="port" class="form-control " tabindex="7" 
                  rxPlaceholder="EmailAccountAddEditComponent_EmailAccountPort_p" />
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.port.errors">{{emailAccountFormGroup.controls.port.errorMessage}}</small>
              </div>
            </div>

          </div>
          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_EmailAccountUser_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="username" class="form-control " tabindex="2" 
                  rxPlaceholder="EmailAccountAddEditComponent_EmailAccountUser_p" />
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.username.errors">{{emailAccountFormGroup.controls.username.errorMessage}}</small>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-4 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_EmailAccountHost_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="host" class="form-control " tabindex="5" 
                  rxPlaceholder="EmailAccountAddEditComponent_EmailAccountHost_p" />
                <small class="form-text text-danger"
                  *ngIf="emailAccountFormGroup.controls.host.errors">{{emailAccountFormGroup.controls.host.errorMessage}}</small>
              </div>
            </div>


            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_Credentials_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="useDefaultCredentials"><i></i> -->
                    <app-checkbox [checkFormControl]="emailAccountFormGroup.controls.useDefaultCredentials" tabindex="8">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.useDefaultCredentials.errors">{{emailAccountFormGroup.controls.useDefaultCredentials.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_DisplayName_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="displayName" class="form-control " tabindex="3" 
                  rxPlaceholder="EmailAccountAddEditComponent_DisplayName_p" />
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.email.errors">{{emailAccountFormGroup.controls.displayName.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="EmailAccountAddEditComponent_EnableSSL_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="enableSSL"><i></i> -->
                    <app-checkbox [checkFormControl]="emailAccountFormGroup.controls.enableSSL" tabindex="6"></app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="emailAccountFormGroup.controls.enableSSL.errors">{{emailAccountFormGroup.controls.enableSSL.errorMessage}}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="hr-line-dashed border-top w-100"></div>
        <h5 rxText="Send_Test_Email_Note_t"></h5>
        <div class="row add-countries-form">
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="EmailAccountAddEditComponent_SendEmail_t"></label>
              <div class="col-md-8">
                <input type="text"  formControlName="mailTo" tabindex="9" (keyup)="changeMailTo($event)"  class="form-control "  rxPlaceholder="EmailAccountAddEditComponent_EmailAddress_p"/>
                <small class="form-text text-danger"
                  *ngIf="emailAccountFormGroup.controls.mailTo.errors">{{emailAccountFormGroup.controls.mailTo.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pl-xl-4">
            <div class="form-group row">
              <div class="col-md-8">
              <ng-container *ngIf="sendMailTo" [rxLocalisationInit]="componentName">
                <button (click)="sendTestMail()" class="btn btn-primary mr-1" rxText="Btn_Send_Test_Email_t"></button>
              </ng-container>
            </div>
            </div>
          </div>
        </div>

        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="editEmailAccount(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="editEmailAccount(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
            <ng-container *ngIf="emailAccountDelete" [rxLocalisationInit]="componentName">
              <button (click)="deleteEmailAccount()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>