import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vReportingHeadLookupBase {

//#region fullName Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'fullName', keyColumn: false})
        fullName : string;
//#endregion fullName Prop


//#region userId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop

}