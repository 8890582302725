import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ApplicationObject } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent, } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { Router } from "@angular/router";

@http({
  path: "api/ApplicationObject",
})

export class AbstractApplicationObject extends CoreComponent {
  applicationObjectFormGroup: IFormGroup<ApplicationObject>
  showComponent: boolean = false;
  spin: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  isFilter: boolean = false;
  totalRecords: number = 0;
  isNoRecordFound: boolean;
  isShowGrid: boolean;
  router: Router;
  selectedTab: string = "edit-info";
  id: number;

  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }

  activeTab(tabName: string) {
    this.selectedTab = tabName;
    this.router.navigate(["application-object", this.id], { queryParams: { activeTab: tabName } });
  }

  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }

}
