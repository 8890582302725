import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AbstractDocumentType } from '../domain/abstract-document-type';
import { Router } from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { DocumentType } from "@app/models";
import { DocumentTypeEditComponent } from "../edit/document-type-edit.component";
@multilingual("DocumentTypeAddComponent")
@access({ accessLevel: RolePermissionEnums.DocumentType, action: "post" })

@Component({
    selector: "app-document-type-add",
    templateUrl: './document-type-add.component.html'
})
export class DocumentTypeAddComponent extends AbstractDocumentType implements OnInit, OnDestroy {
    documentType: DocumentType;
    subscription: any;
    documentTypeEdit:any= DocumentTypeEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private router: Router,private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.DocumentType;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.titleHelp = getLocalizedValue("Title_Help");
        this.title.setTitle("Document Type - G-Trac Admin");
        this.documentType = new DocumentType();
        this.documentType.statusId = StatusEnum.Active;
        this.documentType.documentTypeId = 0;
        this.documentTypeFormGroup = this.formBuilder.formGroup(this.documentType) as IFormGroup<DocumentType>;
        this.showComponent = true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addDocumentType(isRedirect) {
        this.documentTypeFormGroup.submitted = true;
        if (this.documentTypeFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.documentTypeFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                // isRedirect ? this.router.navigate(['/document-types', data]) : this.router.navigate(['/document-types']);
                isRedirect ? this.router.navigate(['/document-types', data], { queryParams: { activeTab: "document-type-info" } }) : this.router.navigate(['/document-types']);

            })
        }else{
            for (const key of Object.keys(this.documentTypeFormGroup.controls)) {
                if (this.documentTypeFormGroup.controls[key].invalid) { 
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    get componentName(): string {
        return "DocumentTypeAddComponent";
    }
}
