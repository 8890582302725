import { prop, required, compare, pattern } from '@rxweb/reactive-form-validators';

export class ChangePasswordViewModel {
    //#region userId Prop
    @prop()
    @required()
    userId: number
    //#endregion chainColourId Prop

    //#region currentPassword Prop
    @prop()
    @required()
    currentPassword: string
    //#endregion currentPassword Prop

    //#region newPassword Prop
    @prop()
    // @required()
    // @pattern({ expression: { "alpha": /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&_^]{8,49}$/ }, messageKey: "password" })

    newPassword: string
    //#endregion newPassword Prop

    //#region confirmPassword Prop
    @prop()
    @required()
    @compare({ fieldName: 'newPassword', message: "New Password and Confirm Password doesn't match" })

    confirmPassword: string
    //#endregion confirmPassword Prop

    //#region isChangePassword Prop
    @prop({ defaultValue: true })
    @required()
    isChangePassword: boolean

    @prop()
    guid:any;
    //#endregion isChangePassword Prop

    @prop()
    isExpired:boolean;

   

    @prop()
    screen:string;
}