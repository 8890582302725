import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GINACompanyCustomerMappingBase {

//#region gIANCompanyCustomerMappingId Prop
        @prop()
        gIANCompanyCustomerMappingId : number;
//#endregion gIANCompanyCustomerMappingId Prop

//#region incidentId Prop
        @prop()
        @required()
        incidentId : number;
//#endregion incidentId Prop

//#region companyId Prop
        @prop()
        companyId : number;
//#endregion companyId Prop

//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop

//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop


}