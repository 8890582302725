import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { LanguageContentKey, vLanguageLookUp } from '@app/models';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { LocalizationFormViewModel } from 'src/app/view-model/localization-form-view-model';
import { TINY_MCE_KEY } from 'src/app/domain/system-constant';

@http({
    path: "api/LanguageContentKeys",
})

export class AbstractLanguageContentKey extends RxHttp {
    languageContentKeyFormGroup: IFormGroup<LanguageContentKey>;
    localizationFormGroup: IFormGroup<LocalizationFormViewModel>;
    spin: boolean = false;
    showComponent: boolean = false;
    id: number = 0;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    validField: string[] = ["keyName","id","contentType","en","m_en"];
    breakLoop: boolean;
    apiKey: any = TINY_MCE_KEY;
    contentTypeArray: Array<any> = [
        { id: 'gh', value: 'Grid Header'},
        { id: 'p', value: 'Placeholder'},
        { id: 't', value: 'Text' },
        { id: 'tr', value: 'Toastr' },
        { id: 'ex', value: 'Excel Sheet Header' },
        { id: 'gn', value: 'Guidance Note' },
        //{ id: 'g', value: 'Server side validation' },
        //{ id: 'v', value: 'Client side validation'}
      ];
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    localizationLookup: any = {
        languageLookUp: vLanguageLookUp
    }
    



    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
