import {AccidentInjurySeverityBase,LookupInjurySeverityBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class AccidentInjurySeverity extends AccidentInjurySeverityBase 
{




//#region Generated Reference Properties
//#region lookupInjurySeverity Prop
lookupInjurySeverity : LookupInjurySeverityBase;
//#endregion lookupInjurySeverity Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties






































}