import {EventPictureBase,EventBase,PictureBase,} from '@app/database-models'
//Generated Imports
export class EventPicture extends EventPictureBase 
{




//#region Generated Reference Properties
//#region event Prop
event : EventBase;
//#endregion event Prop
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop

//#endregion Generated Reference Properties











}