import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import {Router} from '@angular/router';
import { TaxCategory } from '@app/models';
import { AbstractTaxCategory } from '../domain/abstract-tax-category';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { TaxCategoryEditComponent } from '../edit/tax-category-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@access({ accessLevel: RolePermissionEnums.TaxCategories, action: "post" })

@multilingual("TaxCategoryAddComponent")
@Component({
    selector: "app-tax-category-add",
    templateUrl: './tax-category-add.component.html'
})
export class TaxCategoryAddComponent extends AbstractTaxCategory implements OnInit, OnDestroy {
    taxCategory: TaxCategory;
    subscription: any;
    taxClassEdit:any=TaxCategoryEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder,private router:Router, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.TaxCategories;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Tax Classes - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.taxCategory = new TaxCategory();
        this.taxCategory.taxCategoryId=0;
        this.taxCategory.statusId=StatusEnum.Active;
        this.taxCategoryFormGroup = this.formBuilder.formGroup(this.taxCategory) as IFormGroup<TaxCategory>;
        this.showComponent=true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    addTaxCategory(isRedirect:boolean) {
        this.taxCategoryFormGroup.submitted = true;
        if(this.taxCategoryFormGroup.valid)
        {
            this.spin=true;
            this.post({ body: this.taxCategoryFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                if (existsAlert) {
                 //this.toastr.success("Data Added Successfully")
                    this.toastr.success(existsAlert[0].showMessage);
                }
                isRedirect?this.router.navigate(['/tax-category',data]):this.router.navigate(['/tax-category']);

            })
        }else{
            for (const key of Object.keys(this.taxCategoryFormGroup.controls)) {
                if (this.taxCategoryFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }

    }

    get componentName(): string {
      return "TaxCategoryAddComponent";
  }
}
