import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim, numeric, pattern } from "@rxweb/reactive-form-validators"

export class WatchPartBase {
    //#region WatchPartId Prop
    @prop()
    watchPartId: number;
    //#endregion WatchPartId Prop

    //#region watchItemTypeId Prop
    @required()
    watchItemTypeId: number;
    //#endregion watchItemTypeId Prop

    //#region watchMaterialId Prop
    @prop()
    watchMaterialId: number;
    //#endregion watchMaterialId Prop

    //#region description Prop
    @prop()
    description: string;
    //#endregion description Prop

    //#region quantity Prop
    @pattern({ expression: { "custom": /^[1-9]\d*$/ }, message: "Please enter valid quantity" })
    @numeric({ allowDecimal: true })
    quantity: number;
    //#endregion quantity Prop

    //#region unitaryWeight Prop
    @pattern({ expression: { "custom": /^[0-9]\d{0,5}(\.\d{1,3})??$/ }, message: "Please enter valid Unitary Weight" })
    @numeric({ allowDecimal: true })
    unitaryWeight: number;
    //#endregion unitaryWeight Prop

    //#region unitaryPreciousWeight Prop
    @pattern({ expression: { "custom": /^[0-9]\d{0,5}(\.\d{1,3})??$/ }, message: "Please enter valid Unitary Precious Weight" })
    @numeric({ allowDecimal: true })
    unitaryPreciousWeight: number;
    //#endregion unitaryPreciousWeight Prop

    //#region unitaryValue Prop
    @pattern({ expression: { "custom": /^[0-9]\d{0,5}(\.\d{1,3})??$/ }, message: "Please enter valid Unitary Value" })
    @numeric({ allowDecimal: true })
    unitaryValue: number;
    //#endregion unitaryValue Prop

    //#region comments Prop
    @prop()
    comments: string;
    //#endregion comments Prop

    //#region unitaryValue Prop
    @numeric()
    watchStatusId: number;
    //#endregion unitaryValue Prop

    //#region locationID Prop
    @prop()
    watchLocationId: number;
    //#endregion locationID Prop

    //#region legalOwnerID Prop
    @prop()
    watchLegalOwnerId: number;
    //#endregion legalOwnerID Prop

    //#region deleted Prop
    @prop()
    deleted: boolean;
    //#endregion deleted Prop

    //#region createdOn Prop
    @prop()
    createdOn: Date;
    //#endregion createdOn Prop

    //#region updatedOn Prop
    @prop()
    updatedOn: Date;
    //#endregion updatedOn Prop

    // //#region carats Prop      
    // @prop()
    // @pattern({ expression: { "carats": /^[0-9]\d{0,14}(\.\d{1,3})?%?$/ }, message: "Please enter valid carats:(15 digits and 3 decimal places allowed)" })
    // @numeric({ allowDecimal: true })
    // carats: number;
    // //#endregion carats Prop

    // //#region pricePerCarat Prop    
    // //   @maxLength({ value: 18 })
    // @pattern({ expression: { "pricePerCarat": /^[0-9]\d{0,8}(\.\d{1,2})?%?$/ }, message: "Please enter valid Price Per Carat:(9 digits and 2 decimal places allowed)" })
    // @numeric({ allowDecimal: true })
    // //  @range({minimumNumber:1,maximumNumber:2147483647})
    // @prop()
    // pricePerCarat: number;
    // //#endregion pricePerCarat Prop

    //#region createdBy Prop
    @prop()
    createdBy: number;
    //#endregion createdBy Prop

    //#region updatedBy Prop
    @prop()
    updatedBy: number;
    //#endregion updatedBy Prop

    @prop()
    supplierID: number;

}