import {vDesignTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vDesignTypeLookUp extends vDesignTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}