import {vCustomerNameFormatLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCustomerNameFormatLookUp extends vCustomerNameFormatLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}