<p class="m-0">{{currentTime | date:'EEEE,dd MMMM,yyyy'}}</p>
<p class="m-0">{{currentTime | date:'HH:mm'}}</p>









