import {vUpdateFrequencyLookUpBase,} from '@app/database-models'
//Generated Imports
export class vUpdateFrequencyLookUp extends vUpdateFrequencyLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}