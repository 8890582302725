import {ACLMenuBase,CustomerActionBase,} from '@app/database-models'
//Generated Imports
export class ACLMenu {




    //#region Generated Reference Properties
//#region customerAction Prop
customerAction : CustomerActionBase;
//#endregion customerAction Prop

//#endregion Generated Reference Properties






























}