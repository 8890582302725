import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductAttributeLookUpBase {

//#region productAttributeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'productAttributeId', keyColumn: true})
        productAttributeId : number;
//#endregion productAttributeId Prop


//#region productAttributeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'productAttributeName', keyColumn: false})
        productAttributeName : string;
//#endregion productAttributeName Prop

}