import { prop,propObject,propArray,required,maxLength,range ,alpha } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IP2LocationsBase {

//#region iPId Prop
        @prop()
        iPId : any;
//#endregion iPId Prop


//#region ipFrom Prop
        @required()
        ipFrom : any;
//#endregion ipFrom Prop


//#region ipTo Prop
        @required()
        ipTo : any;
//#endregion ipTo Prop


//#region countrycode Prop
        @required()
        @maxLength({value:2})
        countrycode : string;
//#endregion countrycode Prop


//#region countryname Prop
        @required()
        @maxLength({value:64})
        @alpha()
        countryname : string;
//#endregion countryname Prop


//#region region Prop
        @prop()
        region : string;
//#endregion region Prop


//#region city Prop
        @prop()
        city : string;
//#endregion city Prop


//#region latitude Prop
        @prop()
        latitude : string;
//#endregion latitude Prop


//#region longtitude Prop
        @prop()
        longtitude : string;
//#endregion longtitude Prop


//#region zipcode Prop
        @prop()
        zipcode : string;
//#endregion zipcode Prop


//#region ipCompare4 Prop
        @maxLength({value:15})
        ipCompare4 : string;
//#endregion ipCompare4 Prop


//#region ipCompare3 Prop
        @maxLength({value:15})
        ipCompare3 : string;
//#endregion ipCompare3 Prop


//#region strDiff4 Prop
        @maxLength({value:15})
        strDiff4 : string;
//#endregion strDiff4 Prop


//#region strDiff3 Prop
        @maxLength({value:15})
        strDiff3 : string;
//#endregion strDiff3 Prop

}