import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBannedIpNetworkBase {

  //#region bannedIpNetworkID Prop
  @gridColumn({ style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_NetworkId_gh', keyColumn: true })
  bannedIpNetworkId: number;
  //#endregion bannedIpNetworkID Prop


  //#region startAddress Prop
  @gridColumn({ isAscending: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_StartAddress_gh', keyColumn: false })
  startAddress: string;
  //#endregion startAddress Prop


  //#region endAddress Prop
  @gridColumn({ visible: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 2, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_EndAddress_gh', keyColumn: false })
  endAddress: string;
  //#endregion endAddress Prop


  //#region comment Prop
  @gridColumn({ visible: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 3, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_Comment_gh', keyColumn: false })
  comment: string;
  //#endregion comment Prop


  //#region ipException Prop
  @gridColumn({ visible: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 4, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_IpException_gh', keyColumn: false })
  ipException: string;
  //#endregion ipException Prop


  //#region createdOn Prop
  @gridColumn({ configuredTemplate: { templateName: "dateDispay" }, visible: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 5, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_CreatedOn_gh', keyColumn: false })
  createdOn: any;
  //#endregion createdOn Prop


  //#region updatedOn Prop
  @gridColumn({ configuredTemplate: { templateName: "dateDispay" }, visible: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 6, allowSorting: true, headerKey: 'BannedIpNetworkListComponent_UpdatedOn_gh', keyColumn: false })
  updatedOn: any;
  //#endregion updatedOn Prop

  //#region createdOn Prop
  @gridColumn({ visible: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 5, allowSorting: false, headerKey: 'BannedIpNetworkListComponent_CreatedOn_gh', keyColumn: false })
  stCreatedOn: string;
  //#endregion createdOn Prop


  //#region updatedOn Prop
  @gridColumn({ visible: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], columnIndex: 6, allowSorting: false, headerKey: 'BannedIpNetworkListComponent_UpdatedOn_gh', keyColumn: false })
  stUpdatedOn: string;
  //#endregion updatedOn Prop

}
