import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { AbstractMeasureWeight } from '../domain/abstract-measure-weight';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute,Router } from '@angular/router';
import { MeasureWeight } from '@app/models';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("MeasureWeightEditComponent")
@access({ accessLevel: RolePermissionEnums.MeasureWeights, action: "put" })

@Component({
    selector: "app-measure-weight-edit",
    templateUrl: './measure-weight-edit.component.html'
})
export class MeasureWeightEditComponent extends AbstractMeasureWeight implements OnInit, OnDestroy {
    measureWeight: MeasureWeight;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    measureWeightDelete:any=dbAccessModule[RolePermissionEnums.MeasureWeights]["delete"];
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,private applicationBroadcaster:ApplicationBroadcaster,private router:Router, modelView: ModalView, private el:ElementRef, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.MeasureWeights;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Measure Weights - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {

            if (t) {
                this.measureWeightFormGroup = this.formBuilder.formGroup(MeasureWeight,t) as IFormGroup<MeasureWeight>;
                this.measureWeightFormGroup.controls.ratio.setValidators([RxwebValidators.required({message:"You can't leave this field empty"}),
                RxwebValidators.pattern({expression:{'ratio':/^[0-9]{0,5}[.]{0,1}[0-9]{0,2}$/}, message:'Please enter valid rate(5 digits and 2 decimal places)'})]);
                this.showComponent = true;
            }
            else {
                this.router.navigate(['/measure-weights']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editMeasureWeight(isRedirect:boolean)
    {   this.measureWeightFormGroup.submitted = true;
        if (this.measureWeightFormGroup.valid) {
            this.spin=true;
            this.put({ body: this.measureWeightFormGroup.value, params: [this.id]}).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.ngOnInit() : this.router.navigate(['/measure-weights']);
            })
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.measureWeightFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }

    deleteMeasureWeight() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/measure-weights']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Measure_Weights");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    get componentName(): string {
        return "MeasureWeightEditComponent";
    }
}
