import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,numeric, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TaxProviderBase {

//#region taxProviderId Prop
        @prop()
        taxProviderId : number;
//#endregion taxProviderId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        //@alpha({allowWhiteSpace:true})
        @notEmpty()
        @trim()
        name : string;
//#endregion name Prop


//#region description Prop
        @required()
        @maxLength({value:4000})
        @notEmpty()
        @trim()
        description : string;
//#endregion description Prop


//#region configureTemplatePath Prop
        @required()
        @maxLength({value:500})
        @notEmpty()
        @trim()
        configureTemplatePath : string;
//#endregion configureTemplatePath Prop


//#region className Prop
        @required()
        @maxLength({value:500})
        @notEmpty()
        @trim()
        className : string;
//#endregion className Prop


//#region displayOrder Prop
        @required()
        @numeric()
        @notEmpty()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}