import {vkeyRelationshipHolderLookUpBase,} from '@app/database-models'
//Generated Imports
export class vkeyRelationshipHolderLookUp extends vkeyRelationshipHolderLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}