import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSSpecificationAttributeOptionLookUpBase {

//#region specificationAttributeOptionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'specificationAttributeOptionId', keyColumn: true})
        specificationAttributeOptionId : number;
//#endregion specificationAttributeOptionId Prop


//#region specificationAttributeOption Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'specificationAttributeOption', keyColumn: false})
        specificationAttributeOption : string;
//#endregion specificationAttributeOption Prop

}