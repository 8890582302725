import { gridColumn } from '@rxweb/grid';

export class vChainStyleLookUpBase {

    //#region chainStyleId Prop
    @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'chainStyleId', keyColumn: true })
    chainStyleId: number;
    //#endregion chainStyleId Prop


    //#region styleName Prop
    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'styleName', keyColumn: false })
    styleName: string;
    //#endregion styleName Prop

}