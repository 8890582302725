import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventBookingBase {

//#region eventBookingID Prop
        @prop()
        eventBookingID : number;
//#endregion eventBookingID Prop


//#region eventID Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        // @required()
        @prop()
        eventID : number;
//#endregion eventID Prop


//#region bookingID Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        // @required()
        @prop()
        bookingID : number;
//#endregion bookingID Prop




}