import { UserDetailBase, UserBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { PersonTypeEnum } from 'src/app/enums/person-type.enum';
//Generated Imports
export class UserDetail extends UserDetailBase {
    constructor() {
        super()
        this.commu_FabergeMBRupdates = false,
            this.jewelleryStockist = false,
            this.watchStockist = false,
            this.currentFabergeStockist = false,
            this.useRewardPointsDuringCheckout = false,
            this.displayPrice = false
            

    }
    @prop({defaultValue:1})
    personType:number;
    @prop()
    externalPeopleType:number;
    @prop()
    personTypeName:number;
    @prop()
    externalPeopleTypeName:number;
    // @prop()
    // roleTypeId:number;

    //#region Generated Reference Properties
    //#region user Prop
    user: UserBase;
    //#endregion user Prop

    //#endregion Generated Reference Properties

    //#region POSId Prop
    @prop()
    posId: number;
    //#endregion POSId Prop

    //#region supplierId Prop
    @prop()
    supplierId: number;
    //#endregion supplierId Prop

    //#region supplierId Prop
    @prop({ defaultValue: false })
    newsLetterSubscription: boolean;
    //#endregion supplierId Prop











}