import {GemstoneOrderGemstoneMapingBase,GemstoneOrderBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class GemstoneOrderGemstoneMaping extends GemstoneOrderGemstoneMapingBase
{




//#region Generated Reference Properties
//#region gemstoneOrder Prop
gemstoneOrder : GemstoneOrderBase;
//#endregion gemstoneOrder Prop
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop

//#endregion Generated Reference Properties

gemstoneMappingObject: GemstoneOrderGemstoneMaping[];

rowCount:number;

pageIndex:number;
    headerBtnChk: boolean;
    query: string;







}