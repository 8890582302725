import { actionColumn, gridColumn } from '@rxweb/grid';

@actionColumn({
    visible: false,
    name: "action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action_LegalAgreement' }, columnIndex: 25, headerTitle: "Action"
})

export class LegalAgreementsListViewModel {

    //#region legalAgreementId Prop
    @gridColumn({ parameter: true, style: { width: "1%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 0, headerKey: 'LegalAgreementListComponent_AgreementId_gh', keyColumn: true, configuredTemplate: { templateName: 'legalAgreementRedirect' } })
    legalAgreementId: number;
    //#endregion legalAgreementId Prop

    //#region legalAgreementName Prop
    @gridColumn({ parameter: false, style: { width: "2%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, headerKey: 'LegalAgreementListComponent_AgreementName_gh', keyColumn: false })
    legalAgreementName: string;
    //#endregion legalAgreementName Prop

    //#region summary Prop
    @gridColumn({
        parameter: false,
        template: {
            div: {
                style: {
                    "word-break": "break-all",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.summary;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.summaryCharCount > 100) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreSummaryClick"
                        }
                    },
                }]
            }
        }, style: { width: "2%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'LegalAgreementListComponent_AgreementSummary_gh', keyColumn: false
    })
    summary: string;
    //#endregion summary Prop

    //#region agreementTypeId Prop
    @gridColumn({ visible: false, columnIndex: 3, keyColumn: false })
    agreementTypeId: number;
    //#endregion agreementTypeId Prop

    //#region agreementTypeName Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, headerKey: 'LegalAgreementListComponent_AgreementType_gh', keyColumn: false })
    agreementTypeName: string;
    //#endregion agreementTypeName Prop

    //#region date Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'LegalAgreementListComponent_AgreementDate_gh', keyColumn: false })
    date: string;
    //#endregion date Prop

    //#region expiryDate Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, headerKey: 'LegalAgreementListComponent_ExpiryDate_gh', keyColumn: false })
    expiryDate: string;
    //#endregion expiryDate Prop

    //#region leader Prop
    @gridColumn({ visible: false, columnIndex: 7, keyColumn: false })
    leader: number;
    //#endregion leader Prop

    //#region leaderName Prop
    @gridColumn({ parameter: false, style: { width: "2%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, headerKey: 'LegalAgreementListComponent_Leader_gh', keyColumn: false, configuredTemplate: { templateName: "reDirectPeopleAndTooltip" } })
    leaderName: string;
    //#endregion leaderName Prop

    //#region signedAgreement Prop
    @gridColumn({ parameter: false, style: { width: "2%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, headerKey: 'LegalAgreementListComponent_SignedAgreement_gh', keyColumn: false, configuredTemplate: { templateName: "signedAgreementPDF" } })
    signedAgreement: string;
    //#endregion signedAgreement Prop

    //#region isSigned Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 10, headerKey: 'LegalAgreementListComponent_IsSigned_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
    isSigned: boolean;
    //#endregion isSigned Prop

    //#region ecutionVersion Prop
    @gridColumn({ parameter: false, style: { width: "2%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 11, headerKey: 'LegalAgreementListComponent_ExecutionVersion_gh', keyColumn: false, configuredTemplate: { templateName: "executionVersionDoc" } })
    executionVersion: string;
    //#endregion ecutionVersion Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 12, keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    //#region events Prop
    @gridColumn({
        parameter: false,
        template: {
            div: {
                attributes: {
                    innerHTML: function (x, y) {
                        return this.events;
                    }
                }
            }
        },
        style: { width: "2%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 13, allowSorting: false, headerKey: 'LegalAgreementListComponent_Events_gh', keyColumn: false
    })
    events: string;
    //#endregion events Prop

    eventDetails: any;

    //#region projects Prop
    @gridColumn({
        parameter: false,
        template: {
            div: {
                attributes: {
                    innerHTML: function (x, y) {
                        return this.projects;
                    }
                }
            }
        },
        style: { width: "2%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 14, allowSorting: false, headerKey: 'LegalAgreementListComponent_Projects_gh', keyColumn: false
    })
    projects: string;
    //#endregion projects Prop

    projectDetails: any;

    //#region risks Prop
    @gridColumn({
        parameter: false,
        template: {
            div: {
                attributes: {
                    innerHTML: function (x, y) {
                        return this.risks;
                    }
                }
            }
        },
        style: { width: "2%", "min-width": "80px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 16, allowSorting: false, headerKey: 'LegalAgreementListComponent_Risks_gh', keyColumn: false
    })
    risks: string;
    //#endregion risks Prop

    riskDetails: any;

    //#region counterParties Prop
    @gridColumn({
        parameter: false,
        template: {
            div: {
                attributes: {
                    innerHTML: function (x, y) {
                        return this.counterParties;
                    }
                }
            }
        },
        style: { width: "2%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 18, allowSorting: false, headerKey: 'LegalAgreementListComponent_Counterparties_gh', keyColumn: false
    })
    counterParties: string;
    //#endregion counterParties Prop

    counterPartyDetails: any;

    //#region signedAgreementPdfURL Prop
    @gridColumn({ visible: false, columnIndex: 20, keyColumn: false })
    signedAgreementPdfURL: string;
    //#endregion signedAgreementPdfURL Prop

    //#region executionVersionDocURL Prop
    @gridColumn({ visible: false, columnIndex: 21, keyColumn: false })
    executionVersionDocURL: string;
    //#endregion executionVersionDocURL Prop

    //#region summaryCharCount Prop
    @gridColumn({ visible: false, columnIndex: 22, keyColumn: false })
    summaryCharCount: string;
    //#endregion summaryCharCount Prop

    //#region editAction Prop
    @gridColumn({
        visible: false,
        name: "editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 23, headerTitle: "Edit"
    })
    editAction: any;
    //#endregion editAction Prop

    //#region deleteAction Prop
    @gridColumn({
        visible: false,
        name: "deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'deleteLegalAgreement' }, columnIndex: 24, headerTitle: "Delete"
    })
    deleteAction: any;
    //#endregion deleteAction Prop

    //#region deleteMappingAction Prop
    @gridColumn({
        visible: false,
        name: "deleteMappingAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'deleteLegalAgreementMapping' }, columnIndex: 26, headerTitle: "Delete"
    })
    deleteMappingAction: any;
    //#endregion deleteMappingAction Prop

    notes: string
    noteCharCount:any;
    isAccess : boolean;
    userId:any;
    contactPermissionRequired:boolean;
    introducesBy:string;
}
