import { prop, propObject, propArray, required, maxLength, range, numeric, notEmpty, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceGemstoneBase {

  //#region gemStoneID Prop
  @prop()
  gemStoneID: number;
  //#endregion gemStoneID Prop


  //#region title Prop
  @required()
  @maxLength({ value: 100 })
  title: string;
  //#endregion title Prop


  //#region categoryID Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  categoryID: number;
  //#endregion categoryID Prop


  //#region clientDescription Prop
  @prop()
  clientDescription: string;
  //#endregion clientDescription Prop


  //#region internalDescription Prop
  @prop()
  internalDescription: string;
  //#endregion internalDescription Prop


  //#region status Prop
  //@required()
  @numeric()
  status: number;
  //#endregion status Prop


  //#region published Prop
  @prop({ defaultValue: false })
  published: boolean;
  //#endregion published Prop


  //#region deleted Prop
  @required()
  deleted: boolean;
  //#endregion deleted Prop


  //#region displayOrder Prop
  @required()
  @numeric()
  @notEmpty()
  displayOrder: number;
  //#endregion displayOrder Prop


  //#region createdOn Prop
  @required()
  createdOn: Date;
  //#endregion createdOn Prop


  //#region updatedOn Prop
  @required()
  updatedOn: Date;
  //#endregion updatedOn Prop


  //#region carats Prop
  @prop()
  @pattern({ expression: { "carats": /^[0-9]\d{0,14}(\.\d{1,3})?%?$/ }, message: "Please enter valid carats:(15 digits and 3 decimal places allowed)" })
  @numeric({ allowDecimal: true })
  carats: number;
  //#endregion carats Prop


  //#region cutType Prop
  @prop()
  cutType: number;
  //#endregion cutType Prop


  //#region pricePerCarat Prop
  //   @maxLength({ value: 18 })
  @pattern({ expression: { "pricePerCarat": /^[0-9]\d{0,8}(\.\d{1,2})?%?$/ }, message: "Please enter valid Price Per Carat:(9 digits and 2 decimal places allowed)" })
  @numeric({ allowDecimal: true })
  //  @range({minimumNumber:1,maximumNumber:2147483647})
  @prop()
  pricePerCarat: number;
  //#endregion pricePerCarat Prop


  //#region video Prop
  @maxLength({ value: 100 })
  video: string;
  //#endregion video Prop


  //#region locationID Prop
  @prop()
  locationID: number;
  //#endregion locationID Prop


  //#region legalOwnerID Prop
  @prop()
  legalOwnerID: number;
  //#endregion legalOwnerID Prop


  //#region colorID Prop
  @prop()
  colorID: number;
  //#endregion colorID Prop


  //#region saturationID Prop
  @prop()
  saturationID: number;
  //#endregion saturationID Prop


  //#region clartyID Prop
  @prop()
  clartyID: number;
  //#endregion clartyID Prop


  //#region consignmentInvoice Prop
  @prop({ defaultValue: false })
  consignmentInvoice: boolean;
  //#endregion consignmentInvoice Prop


  //#region consignmentNote Prop
  @prop({ defaultValue: '' })
  consignmentNote: string;
  //#endregion consignmentNote Prop


  //#region quantity Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @numeric()
  @required()
  quantity: number;
  //#endregion quantity Prop


  //#region supplierID Prop
  @prop()
  @required()
  supplierID: number;
  //#endregion supplierID Prop


  //#region createdBy Prop
  @required()
  createdBy: number;
  //#endregion createdBy Prop


  //#region originID Prop
  @prop()
  originID: number;
  //#endregion originID Prop

  @prop()
  treatmentID: number;

  @prop()
  invoiceNumber: string;

  @prop()
  invoice: string;




}
