import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TemporaryAssigneeTypeBase {

//#region temporaryAssigneeTypeId Prop
        @prop()
        temporaryAssigneeTypeId : number;
//#endregion temporaryAssigneeTypeId Prop


//#region typeName Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        @trim()
        typeName : string;
//#endregion typeName Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop



}