import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LegalOwnerBase {

//#region legalOwnerId Prop
        @prop()
        legalOwnerId : number;
//#endregion legalOwnerId Prop


//#region legalOwnerName Prop
        @required()
        @maxLength({value:100})
        @notEmpty()
        legalOwnerName : string;
//#endregion legalOwnerName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}