import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductVariantStatusBase {

  //#region productStatusId Prop
  @gridColumn({ style: { width: "12.5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ProductVariantStatusListComponent_Id_gh', keyColumn: true })
  productStatusId: number;
  //#endregion productStatusId Prop

  //#region productStatusName Prop
  @gridColumn({ isAscending: true, style: { width: "30%", "text-align": "initial", "word-break": "break-all" }, class: ["text-left", "word-break"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ProductVariantStatusListComponent_Status_gh', keyColumn: false })
  productStatusName: string;
  //#endregion productStatusName Prop

  //#region productStatusName Prop
  @gridColumn({ style: { width: "22.5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'ProductPieceStatusListComponent_StatusType_gh', keyColumn: false })
  statusTypeName: string;
  //#endregion productStatusName Prop

  @gridColumn({ style: { width: "15%" }, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ProductVariantStatusAddEdit_IncludeInFrontStore_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  includeInFrontStore: boolean;

  @gridColumn({ style: { width: "15%" }, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'ProductVariantStatusAddEdit_IncludeInPOS_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  includeInPOS: boolean;
}
