import {ProductCategoryMappingBase,CategoryBase,ProductDesignBase,} from '@app/database-models'
//Generated Imports
export class ProductCategoryMapping extends ProductCategoryMappingBase 
{




//#region Generated Reference Properties
//#region category Prop
category : CategoryBase;
//#endregion category Prop
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop

//#endregion Generated Reference Properties











}