import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPieceOwnerBase {

        //#region pieceOwnerId Prop
        @gridColumn({ style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'PieceOwnerListComponent_OwnerId_gh', keyColumn: true })
        pieceOwnerId: number;
        //#endregion pieceOwnerId Prop


        //#region pieceOwnerName Prop
        @gridColumn({ isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'PieceOwnerListComponent_OwnerName_gh', keyColumn: false })
        pieceOwnerName: string;
        //#endregion pieceOwnerName Prop

          //#region vatOrTax Prop
          @gridColumn({ isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'PieceOwnerListComponent_VatOrTax_gh', keyColumn: false })
          vatOrTax: string;
          //#endregion vatOrTax Prop

           //#region address Prop
           @gridColumn({ isAscending: true, style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'PieceOwnerListComponent_Address_gh', keyColumn: false })
           address: string;
           //#endregion address Prop

}