export class vApplicationObjectTypeLookupBase {

    //#region applicationObjectTypeId Prop
            applicationObjectTypeId : number;
    //#endregion applicationObjectTypeId Prop
    
    
    //#region applicationObjectTypeName Prop
            applicationObjectTypeName : string;
    //#endregion applicationObjectTypeName Prop
    
    
    //#region isActive Prop
            isActive : boolean;
    //#endregion isActive Prop
    
    }