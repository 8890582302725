import { Component, OnInit, OnDestroy } from "@angular/core"
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { AbstractDocumentType } from "../domain/abstract-document-type";
import { DocumentTypeAddComponent } from "../add/document-type-add.component";
import { vDocumentType } from "@app/models";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";

@access({ accessLevel: RolePermissionEnums.DocumentType, action: "get" })

@multilingual("DocumentTypeListComponent")
@Component({
    selector: "app-document-type-list",
    templateUrl: './document-type-list.component.html'
})
export class DocumentTypeListComponent extends AbstractDocumentType implements OnInit, OnDestroy {
    vdocumentType : vDocumentType[];
    documentTypesGrid: AppGrid;

    subscription: any;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    documentTypeAdd: any = DocumentTypeAddComponent;

    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.DocumentType;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Document Type - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
         this.bindGrid()
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vDocumentType[]) => {
            this.spin = false;
            this.vdocumentType = t;
            this.documentTypesGrid = new AppGrid(t, vDocumentType, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.documentTypesGrid.gridColumns.forEach(x => {
                if (this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "put" }) && this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "delete" });
                    }
                }
            })
            this.documentTypesGrid.componentId = this.componentName;
            this.documentTypesGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.documentTypesGrid.designClass.headerClass = "table-header-sticky".split(" ");            
            this.documentTypesGrid.design(document.getElementById("document-types"));
        })
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    onEdit(documentTypes: vDocumentType) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["document-types", documentTypes.documentTypeId], { queryParams: { activeTab: "document-type-info" }}));
        redirectOnCTRL(url,this.router,event);    
    }

    onDelete(documentTypes: vDocumentType) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [documentTypes.documentTypeId] }).subscribe(data => {
                    this.spin = false;
                    this.documentTypesGrid.remove(documentTypes.documentTypeId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/document-types']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Document_Type");
        if (existsAlert) {
            return existsAlert;
        }
    }
   
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.vdocumentType.filter(x => x.documentTypeName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.documentTypeId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.documentTypesGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.documentTypesGrid = null;
        }
        setTimeout(() => {
            if (this.documentTypesGrid == null || this.documentTypesGrid == undefined) {
                this.documentTypesGrid = new AppGrid(this.vdocumentType, vDocumentType, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.documentTypesGrid.gridColumns.forEach(x => {
                    if (this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "put" }) && this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.documentTypesGrid.authorize({ accessLevel: RolePermissionEnums.DocumentType, action: "delete" });
                        }
                    }
                })
                this.documentTypesGrid.componentId = this.componentName;
                this.documentTypesGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.documentTypesGrid.designClass.headerClass = "table-header-sticky".split(" "); 
                this.documentTypesGrid.refresh("document-types");
                this.documentTypesGrid.design(document.getElementById("document-types"));
            }
            this.documentTypesGrid.search = value.target.value;
        }, 0);
    }

    get componentName(): string {
        return "DocumentTypeListComponent";
    }

}
