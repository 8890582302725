import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreComponent } from '@rxweb/angular-router';
import { GridColumnConfig } from '@rxweb/grid';
import { multilingual } from '@rxweb/localization';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { LeaveMasterAddComponent } from 'src/app/components/master/leave-masters/add/leave-master-add.component';
import { AppGrid } from 'src/app/domain/app-grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { TravelListModel } from 'src/app/models/extended-models/travel-list-view-model';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';

@multilingual("TravelListComponent")
@Component({
  selector: 'app-travel-list',
  templateUrl: './travel-list.component.html',
  //styleUrls: ['./travel-list.component.css']
})
export class TravelListComponent extends CoreComponent implements OnInit {
  showComponent: boolean = false;
  subscription: any;
  paginationViewModel: PaginationViewModel;
  travelEventGrid: AppGrid;
  isShowGridColumn: boolean = false;
  travelEventMappingList: TravelListModel[];
  id: number;
  json: any;
  isFilter: boolean = false;
  isShowGrid: boolean;
  showGrid: boolean = false;
  isNoRecordFound: boolean = false;
  totalRecords: number = 0;
  modalView: ModalView;
  spin: boolean = false;
  getColumnsData: any;
  titleSearch: string;
  titleGridColumnSetting: string;
  @Input() eventId: number;
  @Input() projectId: number;
  @Input() corporatId: number;
  @Input() entityTypeId: number;
  @Input() selectedTab: string = "";
  @Input() moduleID: any;
  arrConfig: any[];

  constructor(private applicationBroadcaster: ApplicationBroadcaster, private router: Router, modal: ModalView, private formBuilder: RxFormBuilder, private browserStorage: BrowserStorage, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
    super();
    this.modalView = modal;
    this.activatedRoute.queryParams.subscribe(t => {
      this.id = t['id'];
    });
    this.router = router;
    this.paginationViewModel = new PaginationViewModel();
    this.paginationViewModel.sortOrder = "true";
    this.paginationViewModel.orderBy = "masterId";
  }

  ngOnInit(): void {
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
      this.titleSearch = getLocalizedValue("Title_Search");
      this.totalRecords = 0;
      this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
      this.json["eventId"] = this.eventId;
      this.json["projectId"] = this.projectId;
      this.json["corporatId"] = this.corporatId;
      this.json["userId"] = 0;
      this.json["moduleId"] = this.entityTypeId;
      this.json['searchString'] = "";
      this.showComponent = true;
      this.bindGrid();
    })
  }

  bindGrid() {
    this.spin = true;
    this.showGrid = true;
    this.isShowGrid = true;
    this.totalRecords = 0;

    this.subscription = this.post({ path: "api/SearchEvent/TravelSearch", body: { "query": JSON.stringify(this.json) } }).subscribe((t: TravelListModel[]) => {
      this.spin = false;
      if (this.travelEventGrid) {
        this.travelEventGrid.storeProcedure.length = 0;
      }
      this.travelEventMappingList = t;

      if (this.travelEventMappingList.length > 0) {
        this.totalRecords = this.travelEventMappingList[0].totalCount;
        this.isNoRecordFound = false;
      }
      else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }

      if (!this.isFilter) {
        this.travelEventGrid = new AppGrid(this.travelEventMappingList, TravelListModel, { actions: { onView: this.onView.bind(this) } });
        this.travelEventGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }
        this.travelEventGrid.gridColumns.forEach(x => {
          if (x.columnIndex == 1) {
            x.visible = false;
          }
        });
      }
      else {
        this.travelEventGrid.storeProcedure.length = this.totalRecords;
        this.travelEventGrid.updateSource([]);
        this.travelEventGrid.updateSource(this.travelEventMappingList)
      }

      if (this.arrConfig != undefined && this.arrConfig != null) {
        this.arrConfig.forEach(x => {
          this.travelEventGrid.gridColumns.forEach(y => {
            if (y.name == x.ColumnName)
              y.visible = x.Visible;
          });
        });
      }
      this.travelEventGrid.maxPerPage = this.json["rowCount"];
      this.travelEventGrid.componentId = this.componentName;
      this.travelEventGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
      this.travelEventGrid.designClass.headerClass = "table-header-sticky".split(" ");
      this.applyClass();
    })
  }

  applyClass() {
    this.travelEventGrid.designClass.rowClass = [function () {
      let data = this;
      if (data.roleId == CustomerRoleTypeEnum.Admin) {
        return "admin";
      }
      else {
        return "customer";
      }
    }]
  }
  onView(travelListViewModel: TravelListModel) {
    this.modalView.show(
      LeaveMasterAddComponent,
      {
        leaveId: travelListViewModel.leaveId,
        userId: travelListViewModel.userId,
        masterId: travelListViewModel.masterId,
        reportTo: travelListViewModel.managerId,
        reportToName: travelListViewModel.managerName,
        reportTos: travelListViewModel.reportToIds,
        reportToNames: travelListViewModel.reportToNames,
        isVisibleTravelType: travelListViewModel.isVisibleTravelType,
        reportToNamesWithHOD: travelListViewModel.reportToNamesWithHOD,
        year: 0,
        hideUpdateButton: true
      })
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {

    gridColumnConfig.forEach(column => {
      var arrConfigColumnIndex = this.arrConfig.findIndex(x => x.ColumnName == column.name);
      if (arrConfigColumnIndex != -1) {
        this.arrConfig[arrConfigColumnIndex].Visible = column.visible;
      }
    });

    this.travelEventGrid.reDesign();
    this.travelEventGrid.updateSource([])
    this.travelEventGrid.updateSource(this.travelEventMappingList);
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = y;
    this.json["orderBy"] = x;
    this.travelEventGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.travelEventGrid.maxPerPage;
    this.travelEventGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }

  search(value) {
    this.spin = false;
    this.isFilter = true;
    this.totalRecords = 0;
    this.json['searchString'] = value.target.value;
    if (this.travelEventGrid != null) {
      this.travelEventGrid.updateSource([]);
      this.json["pageIndex"] = 1;
      this.travelEventGrid.storeProcedure.nextPage = 1;
    }
    this.bindGrid();
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "TravelListComponent";
  }
}
