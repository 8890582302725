import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { ProductPiece } from '@app/models';
import { AbstractProductPiece } from '../domain/abstract-product-piece';
import { DatePipe, PlatformLocation } from '@angular/common';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { BLOB_CONTAINER, BLOB_PUBLIC_CONTAINER, BLOB_PUBLIC_URI, DEFAULT_BLOB_IMAGE_URL } from 'src/app/domain/system-constant';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@Component({
    selector: "app-stock-card",
    templateUrl: './stock-card.component.html'
})
export class StockCardComponent extends AbstractProductPiece implements OnInit, OnDestroy {

    //@Input() cardJson:any;
    cardJson:any;
    @Input() hide:Function;
    currentYear:number;
    timeString:string;
    showCard:boolean=true;
    logoUrl:string;
    constructor(private formBuilder: RxFormBuilder,private datePipe:DatePipe,domSanitizer:DomSanitizer,private activatedRoute:ActivatedRoute, private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
        super(domSanitizer,blobState);
        this.activatedRoute.queryParams.subscribe(params => {
          this.cardJson = JSON.parse(params["cardJson"]);
        });
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.logoUrl= BLOB_PUBLIC_URI + "/" + BLOB_PUBLIC_CONTAINER + "/images/thumbs/logo_lookfaberge.gif";
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let currentDate= new Date();
    this.currentYear=currentDate.getFullYear();
        document.getElementById("fabLogo").setAttribute("src",this.logoUrl);
        document.getElementById("imgProduct").setAttribute("src",this.cardJson.imageUrl);
        document.getElementById("cardProductName").innerHTML=this.cardJson.productName;
        document.getElementById("serialNo").innerHTML=this.cardJson.serialNo;
        if(this.cardJson.withPrice==true){
            document.getElementById("gbpPrice").innerHTML=this.cardJson.gbpPrice+" | ";
            document.getElementById("eurPrice").innerHTML=this.cardJson.eurPrice+" | ";
            document.getElementById("hkdPrice").innerHTML=this.cardJson.hkdPrice+" |" ;
            document.getElementById("chfPrice").innerHTML=this.cardJson.chfPrice+" | ";
            document.getElementById("usdPrice").innerHTML=this.cardJson.usdPrice;
        }
        else{
            document.getElementById("stockCardPriceData").style.display="none";
            document.getElementById("gbpPrice").style.display="none";
            document.getElementById("eurPrice").style.display="none";
            document.getElementById("hkdPrice").style.display="none";
            document.getElementById("chfPrice").style.display="none";
            document.getElementById("usdPrice").style.display="none";
        }

        document.getElementById("description").innerHTML=this.cardJson.description;
        document.getElementById("adminComment").innerHTML=this.cardJson.adminComment;
        document.getElementById("copyrightText").innerHTML="Copyright &copy; "+ this.currentYear +" FABERGÉ. All rights reserved.";
        document.getElementById("footerTime").innerHTML=days[currentDate.getDay()]+", "+this.datePipe.transform(currentDate,"MMMM dd, yyyy, h:MM")+(currentDate.getHours() >= 12? " PM" : " AM")+"(Swiss)";
        this.printStockCard();


    }

    onImageError(event:any){
      event.target.src = DEFAULT_BLOB_IMAGE_URL;
    }
    printStockCard(): void {
        let printContents, popupWin;
        let body=document.getElementById("gtrac");
        if(body!=null){
            body.style.backgroundColor="white";
        }
        // let wrapper=document.getElementById("stockwrapper");
        // if(wrapper!=null){
        //     wrapper.style.marginRight="35%";
        //     wrapper.style.marginLeft="35%";
        // }
        printContents = document.getElementById('stock-card').innerHTML;
       this.showCard=false;
       // popupWin = window.open('', '_blank','location=yes,height=720,width=960,scrollbars=yes,status=yes');
       // popupWin.document.open();
      window.print();
        // setTimeout(() => {
        //  // window.close();
        //   popupWin.document.write(`
        //   <html>
        //       <head>
        //       <title>Print tab</title>
        //       <style type = "text/css">

        //               .titles_stock {
        //                    font-size: 26px !important;
        //                   font-weight: 100 !important;
        //                   padding-left: 5px !important;
        //                   padding-right: 5px !important;
        //                   text-align: center !important;
        //               }
        //               .stock_Price, .stock_producttag, .stock_text, .titles_stock {
        //                 color: #454444 !important;
        //                 font-family: "Gill Sans","Gill Sans MT",GillSans,Calibri,"Trebuchet MS",sans-serif  !important;
        //             }
        //             .stock_title_sub {
        //               font-size: 12pt !important;
        //             }
        //             .stock_title_sub {
        //               height: 12px !important;
        //               padding-bottom: 0 !important;
        //             }
        //             .stock_Price {
        //               font-size: 7pt !important;
        //             }
        //             .stock_Price, .stock_producttag {
        //               font-size: 14px !important;
        //               text-transform: uppercase !important;
        //             }
        //             .stock_text {
        //               font-size: 12pt;
        //               font-family: "Gill Sans", "Gill Sans MT", GillSans, Calibri, "Trebuchet MS", sans-serif  !important;
        //               line-height: 18px !important;
        //             }
        //               .stock_footer {
        //                   text-align: center;
        //                   background-color: #ffffff;
        //                   font-size: 9pt;
        //               }

        //       </style>
        //       </head>
        //   <body onload="window.print()">${printContents}</body>
        //   </html>`
        //   );
        //   popupWin.document.close();
        // }, 200);



     //  this.hide();
      }

    ngOnDestroy(): void {

    }

}
