import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractCountry } from '../domain/abstract-country';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Country, vCustomerRoleDomainLookUp, vLanguageLookUp } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { BrowserModule, Title } from '@angular/platform-browser';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { LanguagesEnums } from '../../../../custom-enum/language-enums';
import { StatusEnum } from '../../../../enums/status.enum';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { vRegion } from 'src/app/models/extended-models/v-region';
import { PlatformLocation } from "@angular/common";
@multilingual("CountryEditComponent")
@access({ accessLevel: RolePermissionEnums.Countries, action: "put" })

@Component({
    selector: "app-country-edit",
    templateUrl: './country-edit.component.html'
})
export class CountryEditComponent extends AbstractCountry implements OnInit, OnDestroy {
    country: Country;
    subscription: any;
    id: number;
    languageId: number = LanguagesEnums.English;
    countryDelete: any = dbAccessModule[RolePermissionEnums.Countries]["delete"];
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    countriesLookup: {
        customerRoleDomainLookUp: vCustomerRoleDomainLookUp,
        languageLookUp: vLanguageLookUp,
        regionsLookup: vRegion[],
        country: Country
    }


    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,private applicationBroadcaster:ApplicationBroadcaster, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.activatedRoute.params.subscribe(t => {
            if (t['languageId']) {
                this.languageId = t['languageId'];
            }
        })
        this.moduleID = RolePermissionEnums.Countries;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Countries - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.country = new Country();
        this.changeLanguage(this.languageId);
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editCountry(isRedirect: boolean) {
        this.countryFormGroup.submitted = true;
        if (this.countryFormGroup.valid) {
            this.spin = true;
            if (this.countryFormGroup.value.parentCountryId > 0 && this.countryFormGroup.value.countryId == 0) {
                this.post({ body: this.countryFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    this.countryFormGroup.patchValue({ countryId: data });
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.changeLanguage(this.languageId) : this.router.navigate(['/countries']);
                })
            }
            else {
                this.put({ body: this.countryFormGroup.value, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.changeLanguage(this.languageId) : this.router.navigate(['/countries']);
                })
            }

        } else {
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.countryFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }

    deleteCountry() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {

            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                     this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/countries']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Country");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    changeLanguage(languageId: number) {
        this.showComponent = false;
        this.spin = true;
        this.lookup([
            { params: [this.id], queryParams: { "languageId": languageId }, propName: "country" },
            { path: USER_LOOKUPS.languageLookUp, propName: "languageLookUp" },
            { propName: "customerRoleDomainLookUp", path: USER_LOOKUPS.customerRoleDomainLookUp },
            { propName: "regionsLookup", path: USER_LOOKUPS.regionsLookup }
        ]).subscribe((lookup: any) => {

            if (lookup.country) {
                this.countriesLookup = lookup;
                this.country = lookup.country as Country;
                this.countryFormGroup = this.formBuilder.formGroup(Country, lookup.country) as IFormGroup<Country>;
                this.countryFormGroup.controls.insurancePer.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                RxwebValidators.pattern({ expression: { 'insurancePer': /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ }, message: 'Please enter valid rate(8 digits and 2 decimal places allowed)' })]);
                this.countryFormGroup.controls.dutyPer.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                RxwebValidators.pattern({ expression: { 'dutyPer': /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ }, message: 'Please enter valid rate(8 digits and 2 decimal places allowed)' })]);
                this.showComponent = true;

            }
            else {

                this.countriesLookup = lookup;
                this.country.name = "";
                this.country.countryId = 0;
                this.country.languageId = languageId;
                this.country.parentCountryId = this.id;
                this.country.regionId = 0;
                this.country.statusId = StatusEnum.Active;
                this.countryFormGroup = this.formBuilder.formGroup(Country, this.country) as IFormGroup<Country>;
                this.countryFormGroup.controls.insurancePer.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                RxwebValidators.pattern({ expression: { 'insurancePer': /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ }, message: 'Please enter valid rate(8 digits and 2 decimal places allowed)' })]);
                this.countryFormGroup.controls.dutyPer.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                RxwebValidators.pattern({ expression: { 'dutyPer': /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ }, message: 'Please enter valid rate(8 digits and 2 decimal places allowed)' })]);
            }
            this.spin = false;
            this.languageId = languageId
            this.showComponent = true;
        })
    }

    get componentName(): string {
        return "CountryEditComponent";
    }
}
