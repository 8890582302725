import {POSPictureBase,} from '@app/database-models'
//Generated Imports
export class POSPicture extends POSPictureBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}