<div class="ibox-content bg-white d-flex flex-column flex-grow-1"  [rxSpinner]="spin"
  *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <ng-container *ngIf="isRisk!=0" [rxLocalisationInit]="componentName">
      <button class="btn btn-success btn-sm mb-3" (click)="showAdd()" rxText = "EventDetailComponent_Add_Button_t"></button>
    </ng-container>
    <label class="ml-auto mr-3 mb-3">
      <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
        placeholder="" [title]="titleSearch" *ngIf="isShowSearch">
    </label>
    <div class="ibox-tools mb-4 pb-1" style="position: relative;" *ngIf="!isNoRecordFound">        
      <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
        <i class="fa fa-cog"></i>
    </a>
    <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
    (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
    [gridColumnConfig]="customerEventGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
    [moduleID]="moduleID" [selectedTab]="selectedTab">
</app-system-table-setting>
    </div>
  </div>
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <button class="btn btn-success mb-2" (click)="resetFilter()" rxText="EventDetailComponent_Btn_ViewAllEvents_t" *ngIf="isFromMyProfile"></button>
    <div data-toggle="buttons" class="btn-group btn-group-gray btn-group-toggle ml-auto pr-2 mb-2"
    *ngIf="isShowSearch">
    <div *ngIf="isLongTextExists">
      <ng-container *ngIf="!isShowAll" [rxLocalisationInit]="componentName">
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Show_All_t"></button>
      </ng-container>
      <ng-container *ngIf="isShowAll" [rxLocalisationInit]="componentName">
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Hide_All_t"></button>
      </ng-container>
    </div>
  </div>
    </div>
 
  <div class="table-responsive" *ngIf="isShowGrid">
    <div id="customerEvents" class="dataTables_wrapper pb-0 dt-bootstrap4">
      <rx-grid [design]="customerEventGrid"></rx-grid>
    </div>
  </div>
  <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>
</div>
