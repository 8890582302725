import { vProjectsLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProjectsLookUp extends vProjectsLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}