<div class="row wrapper white-bg page-heading py-2 align-items-center" *rxMultilingual="" [rxLocalisationInit]="componentName"  [rxSpinner]="spin">
    <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard">Home</a>
            </li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
              </li>
            <li class="breadcrumb-item active" rxText="Label_Localisation_t"></li>
        </ol>
    </div>
    <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
    </div>
</div>

<div class="wrapper wrapper-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
            <h5><label rxText="Label_Localisation_t"></label></h5>
            <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x" ></i>
            </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="localizationSearchFormGroup"
             (keyup.enter)="onKeyPress()">
            <div class="d-flex align-items-center mb-4">
                <button routerLink="/language-content-keys/add" class="btn btn-success mb-4" *rxAuthorize="[localizationAdd]" rxText="Btn_Add_Localisation_t"></button>
                <ng-container *ngIf="showGrid" [rxLocalisationInit]="componentName">
                    <button class="btn btn-primary mb-3 ml-auto mb-4" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t"></button>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                    <!-- <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            >Select language</label>
                        <div class="col-md-8">
                            <select class="form-control"  formControlName="languageId">
                                <option [selected]="true" value="0">Select</option>
                                <option *ngFor="let item of localizationLookup.languageUserLookUp"
                                    [value]="item.languageId">
                                    {{item.languageName}}
                                </option>
                            </select>
                        </div>
                    </div> -->
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                         rxText="LanguageContentKeyListComponent_ContentType_t"></label>
                        <div class="col-md-8" *ngIf="resetCommonControl" [rxSelectExtended]="_this">
                            <!-- <select class="form-control"
                                formControlName="contentType">
                                <option [selected]="true" value="0">Select</option>
                                <option value="t">Text </option>
                                <option value="gh">Grid Header </option>
                                <option value="p">Placeholder </option>
                                <option value="g">Server side validation </option>
                                <option value="v">Client side validation </option>
                            </select> -->
                            <rx-select [(source)]="contentTypeArray" #rxSelect mainClass="form-control" formControlName="contentType"
                            [keyValueProps]="['value','id']" [selectPlaceholder]="'Select Content Type'"></rx-select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="false" [rxLocalisationInit]="componentName">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                             rxText="LanguageContentKeyListComponent_Component_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="id"
                                id="nameDropdown" [queryParams]="{'lookupType':'Component'}" [valueInQueryParams]="true"
                                [keyValueProps]="['#keyName#','id']" path="api/SearchEvent/GetLookups"
                                [minimumCharacterSearchLength]="2" (keyup)="checkNameText($event)"
                                [enableFreeText]="true">
                            </rx-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                             rxText="LanguageContentKeyListComponent_ResourceName_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control"
                                formControlName="resourceName" rxFocus rxPlaceholder="LanguageContentKeyListComponent_Name_p"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                             rxText="LanguageContentKeyListComponent_ResourceValue_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control"
                                formControlName="resourceValue" rxPlaceholder="LanguageContentKeyListComponent_Value_p"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                             rxText="LanguageContentKeyListComponent_MResourceValue_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control"
                                formControlName="resourceValue" rxPlaceholder="LanguageContentKeyListComponent_MValue_p"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100 mt-1"></div>
                <div class="col text-right">
                    <button (click)="onKeyPress()"
                        class="btn btn-success mr-1 search-result-btn" rxText="Btn_Search_t"></button>
                    <button (click)="resetSearch()" class="btn btn-primary mr-1" rxText="Btn_Reset_t"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="search-result-container">
        <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showGrid" [rxLocalisationInit]="componentName">
            <div class="ibox-title bg-white d-flex flex-wrap pr-5">
                <h5 rxText="Label_Search_Result_t"></h5>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                <div class="tab-content">
                    <div class="table-responsive" *ngIf="isShowGrid">
                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                            <rx-grid [design]="localizationGrid"></rx-grid>
                        </div>
                    </div>
                    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                        <h4>No Record Found</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
