import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxCategoryRecordBase {

//#region taxCategoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'taxCategoryId', keyColumn: true})
        taxCategoryId : number;
//#endregion taxCategoryId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : any;
//#endregion displayOrder Prop

}