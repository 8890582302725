import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCurrencyBase {

  //#region currencyId Prop
  @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'CurrencyListComponent_Id_gh', keyColumn: true })
  currencyId: number;
  //#endregion currencyId Prop


  //#region currencyName Prop
  @gridColumn({ parameter: true, isAscending: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'CurrencyListComponent_Name_gh', keyColumn: false })
  currencyName: string;
  //#endregion currencyName Prop


  //#region currencyCode Prop
  @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'CurrencyListComponent_Code_gh', keyColumn: false })
  currencyCode: string;
  //#endregion currencyCode Prop


  //#region displayLocale Prop
  @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'CurrencyListComponent_DisplayLocale_gh', keyColumn: false })
  displayLocale: string;
  //#endregion displayLocale Prop


  //#region rate Prop
  //@gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'CurrencyListComponent_Rate_gh', keyColumn: false })
  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'CurrencyListComponent_Rate_gh', keyColumn: false })
  rate: string;
  //#endregion rate Prop


  //#region published Prop
  @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'CurrencyListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
  published: boolean;
  //#endregion published Prop


  //#region countryName Prop
  @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'CurrencyListComponent_CountryName_gh', keyColumn: false })
  countryName: string;
  //#endregion countryName Prop


  //#region displayOrder Prop
  //@gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'CurrencyListComponent_DisplayOrder_gh', keyColumn: false })
  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'CurrencyListComponent_DisplayOrder_gh', keyColumn: false })
  displayOrder: number;
  //#endregion displayOrder Prop

}
