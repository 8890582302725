import { gridColumn } from '@rxweb/grid';
import { actionColumn } from '@rxweb/grid';
import { IgxNumberSummaryOperand } from 'igniteui-angular';
import { UserGridCompanyMappingViewModel } from 'src/app/view-model/user-grid-company-mapping-view-model';


export class MainProjectListViewModel {


  //#region month Prop
  @gridColumn({ parameter: false, class: ["project"], style:{"min-width": "5%", "max-width": "7%" }, visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ProjectListComponent_Month_gh', keyColumn: false })
  month: string;
  //#endregion month Prop

  //#region projectId Prop
  @gridColumn({ parameter: true, class: ["project"],style:{"min-width": "5%", "max-width": "7%" }, visible: false, columnIndex: 1, keyColumn: true })
  projectId: number;
  //#endregion projectId Prop

  @gridColumn({ parameter: false, class: ["project"], style: { "min-width": "5%", "max-width": "7%" }, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ProjectListComponent_ProjectId_gh', keyColumn: false })
  viewProjectId: number;

  //#region projectType Prop
  @gridColumn({ parameter: true, class: ["text-left", "projectsListRows"], style: { "min-width": "5%", "max-width": "10%" }, visible: false, columnIndex: 2, allowSorting: true, headerKey: 'ProjectListComponent_ProjectType_gh', keyColumn: false })
  projectType: number;
  //#endregion projectType Prop

  // //#region projectTypeName Prop
  // @gridColumn({ parameter: false, class: ["projectsListRows"], style: { "min-width": "5%", "max-width": "10%" }, visible: false, columnIndex: 3, allowSorting: false, headerKey: 'ProjectListComponent_ProjectTypeName_gh', keyColumn: false })
  // projectTypeName: string;
  // //#endregion projectTypeName Prop

  //#region gemfieldsGroupCompanyProjectOwnerName Prop
  @gridColumn({ parameter: false, class: ["projectsListRows"], style: { "min-width": "5%", "max-width": "10%" }, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ProjectListComponent_gemCompPO_gh', keyColumn: false })
  gemfieldsGroupCompanyProjectOwnerName: string;
  //#endregion gemfieldsGroupCompanyProjectOwnerName Prop

  //#region projectOwner Prop
  @gridColumn({ parameter: true, class: ["text-left", "projectsListRows"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'ProjectListComponent_ProjectOwner_gh', keyColumn: false })
  projectOwner: number;
  //#endregion projectOwner Prop

  //#region projectOwnerName Prop
  @gridColumn({ parameter: false, style: { "min-width": "5%", "max-width": "10%" }, class: ["projectsListRows"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ProjectListComponent_ProjectOwnerName_gh', keyColumn: false })
  projectOwnerName: string;
  //#endregion projectOwnerName Prop

  //#region projectName Prop
  @gridColumn({ parameter: false, style:{"min-width":"5%","max-width":"30%" }, configuredTemplate: { templateName: "projectLink" }, class: ["projectname"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ProjectListComponent_ProjectName_gh', keyColumn: false})
  projectName: string;
  //#endregion projectName Prop

  //#region departmentName Prop
  @gridColumn({ parameter: false, style: { "min-width": "5%", "max-width": "10%" }, class: ["text-left", "projectsListRows"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ProjectListComponent_DepartmentName_gh', keyColumn: false})
  departmentName: string;
  //#endregion departmentName Prop

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.companyName;
          }
        },
      }
    }, parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left", "projectscompanyName"], visible: true, columnIndex: 8, allowSorting: false, headerKey: 'ProjectListComponent_CompanyName_gh', keyColumn: false
  })

  companyName: string

  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-word",
          "width": "100%"
        },
        attributes: {
          innerHTML: function (x, y) {
            return "<label style='margin-left:0%'>" + this.recentNote + "</label>";
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.charCount > 100) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClick"
            }
          },
        }]
      }
    }, parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left", "recentNote"], visible: true, columnIndex: 9, allowSorting: false, headerKey: 'ProjectListComponent_RecentNote_gh', keyColumn: false
  })

  recentNote: string

  //#region companyName Prop
  // @gridColumn({ parameter: false, style: { "min-width": "5%", "max-width": "15%" }, class: ["project"], visible: false, columnIndex: 17, allowSorting: true, headerKey: 'ProjectListComponent_CompanyName_gh', keyColumn: false})
  // companyName : string;
  //#endregion companyName Prop


  
  //#region noteCreatedDate
  @gridColumn({ parameter: false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: false, headerKey: 'ProjectListComponent_NoteCreatedDate_gh', keyColumn: false })
  noteCreatedDate: string;
  //#endregion noteCreatedDate


  companyDetails: string;


  //#region projectIndicator Prop
  @gridColumn({ parameter: false, configuredTemplate: { templateName:"progressBar" }, class: ["text-right"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'ProjectListComponent_ProjectIndicator_gh', keyColumn: false })
  projectIndicator: number;
  //#endregion projectIndicator Prop

  //#region countryName Prop
  @gridColumn({ parameter: false, class: ["projectsListRows"], style: { "min-width": "5%", "max-width": "10%" }, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'ProjectListComponent_Country_gh', keyColumn: false })
  countryName: string;
  //#endregion countryName Prop

  //#region roleId Prop
  @gridColumn({ parameter: true, visible: false, columnIndex: 11, keyColumn: false})
  roleId: string;
  //#endregion roleId Prop

  //#region totalCount
  @gridColumn({ parameter: true, name: "totalCount", visible: false, columnIndex: 12, keyColumn: false })
  totalCount: number;
  //#endregion totalCount


  //#region parentId
  @gridColumn({ visible: false, columnIndex: 13, keyColumn: false })
  parentId: number;
  //#endregion parentId

  //#region projectStartDate
  @gridColumn({ visible: false, columnIndex: 14, keyColumn: false })
  projectStartDate: number;
  //#endregion projectStartDate

  //#region projectEndDate
  @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
  projectEndDate: number;
  //#endregion projectEndDate

  //#region subProjects
  @gridColumn({ visible: false, columnIndex: 16, keyColumn: false })
  subProjects: number;
  //#endregion subProjects

  //#region isRestrictedProject
  @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
  isRestrictedProject: boolean;
  //#endregion isRestrictedProject



}
