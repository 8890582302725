import {NewsLocalizationBase,} from '@app/database-models'
//Generated Imports
export class NewsLocalization extends NewsLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}