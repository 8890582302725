import {vGemStoneCutTypeGemfieldsLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneCutTypeGemfieldsLookUp extends vGemStoneCutTypeGemfieldsLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}