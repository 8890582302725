import {IncidentTypeBase,LookupIncidentTypeBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class IncidentType extends IncidentTypeBase 
{




//#region Generated Reference Properties
//#region lookupIncidentType Prop
lookupIncidentType : LookupIncidentTypeBase;
//#endregion lookupIncidentType Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties





































}