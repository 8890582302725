import { prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class LegalAgreementProjectMappingFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "legalAgreementId";
        this.agreementType = 0;
    }

    @prop()
    legalAgreementId: String;

    @prop()
    legalAgreementName: String;

    @prop()
    agreementType: number;

    @prop()
    leader: number;

    @prop()
    startDate: Date;

    @prop()
    endDate: Date;

    @prop()
    @trim()
    agreementTypeText: string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    searchString: string;  
}
