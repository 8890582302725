import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCountryBase {

  //#region countryName Prop
  @gridColumn({ parameter: true, isAscending: true, style: { width: "14%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'CountryListComponent_Name_gh', keyColumn: false })
  countryName: string;
  //#endregion countryName Prop


  //#region countryId Prop
  @gridColumn({ parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'CountryListComponent_Id_gh', keyColumn: true })
  countryId: number;
  //#endregion countryId Prop


  //#region allowsRegistration Prop
  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'CountryListComponent_AllowReg_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  allowsRegistration: boolean;
  //#endregion allowsRegistration Prop


  //#region allowsBilling Prop
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'CountryListComponent_AllowBilling_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  allowsBilling: boolean;
  //#endregion allowsBilling Prop


  //#region allowsShipping Prop
  @gridColumn({ parameter: false, style: { width: "11%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'CountryListComponent_AllowShipping_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  allowsShipping: boolean;
  //#endregion allowsShipping Prop


  //#region twoLetterISOCode Prop
  @gridColumn({ parameter: false, style: { width: "13%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: true, headerKey: 'CountryListComponent_TwoLetISOCode_gh', keyColumn: false })
  twoLetterISOCode: string;
  //#endregion twoLetterISOCode Prop


  //#region threeLetterISOCode Prop
  @gridColumn({ parameter: false, style: { width: "14%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: true, headerKey: 'CountryListComponent_ThreeLetISOCode_gh', keyColumn: false })
  threeLetterISOCode: string;
  //#endregion threeLetterISOCode Prop


  //#region numericISOCode Prop
  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'CountryListComponent_NumericISOCode_gh', keyColumn: false })
  numericISOCode: number;
  //#endregion numericISOCode Prop


  //#region subjectToVAT Prop
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, headerKey: 'CountryListComponent_SubToVAT_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  subjectToVAT: boolean;
  //#endregion subjectToVAT Prop


  //#region allowPOBShipping Prop
  @gridColumn({ parameter: false, style: { width: "13%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerKey: 'CountryListComponent_AllowPOBShip_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  allowPOBShipping: boolean;
  //#endregion allowPOBShipping Prop


  //#region displayOrder Prop
  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'CountryListComponent_DisplayOrder_gh', keyColumn: false })
  displayOrder: number;
  //#endregion displayOrder Prop


  //#region published Prop
  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'CountryListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
  published: boolean;
  //#endregion published Prop

}
