import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LookupLostTimeInjuryBase {

//#region lostTimeInjuryId Prop
        @prop()
        lostTimeInjuryId : any;
//#endregion lostTimeInjuryId Prop


//#region lostTimeInjuryName Prop
        @maxLength({value:250})
        lostTimeInjuryName : string;
//#endregion lostTimeInjuryName Prop


//#region active Prop
        @required()
        active : boolean;
//#endregion active Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop



}