import {LookupVehicleTypeBase,AccidentVehicleTypeBase,} from '@app/database-models'
//Generated Imports
export class LookupVehicleType extends LookupVehicleTypeBase 
{




//#region Generated Reference Properties
//#region accidentVehicleTypes Prop
accidentVehicleTypes : AccidentVehicleTypeBase[];
//#endregion accidentVehicleTypes Prop

//#endregion Generated Reference Properties






































}