import { prop, propObject, propArray, required, maxLength, range, notEmpty, alpha, trim, numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MaterialBase {

        //#region materialId Prop
        @prop()
        materialId: number;
        //#endregion materialId Prop


        //#region materialName Prop
        @required()
        @maxLength({ value: 50 })
        @notEmpty()
        @trim()
        materialName: string;
        //#endregion materialName Prop

        //#region imageUrl Prop
        // @maxLength({ value: 100 })
        @prop()
        imageUrl: number;
        //#endregion imageUrl Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        //#region languageId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        languageId: number;
        //#endregion languageId Prop

        //#region displayOrder Prop
        @prop()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrder : number;
        //#endregion displayOrder Prop

        //#region isActive Prop
        @prop()
        isActive : boolean;
        //#endregion isActive Prop

}
