import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEventOwnerLookupBase {

//#region eventTypeId Prop
      @prop()
      userId : number;
//#endregion eventTypeId Prop


//#region eventTypeName Prop
       @prop()
       FullName : string;
//#endregion eventTypeName Prop

}