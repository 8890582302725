import { gridColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

export class vSubMenuItemsBase{
    //#region subMenuItemId Prop
    @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerTitle: 'bangleSizeId', keyColumn: true})
    subMenuItemId : number;
//#endregion subMenuItemId Prop


//#region subMenuName Prop
    @gridColumn({template:{
        div: {
            childrens: [
              {
                label: {
                    style:{"display":"inline"},
                  childrens: [
                    {
                      input:
                      {
                        class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                        event: {
                          input: "onNameData"
                        },

                        attributes: {
                          type: "text",
                          value: function (e) {
                            return this.subMenuName;
                          },
                          maxlength:30,
                          placeholder: "Enter Name"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
    },
        style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'SubMenuItemListComponent_subMenuName_gh', keyColumn: false})
    subMenuName : string;
//#endregion subMenuName Prop

//#region subMenuUrl Prop
    @gridColumn({template:{
        div: {
            childrens: [
              {
                label: {
                    style:{"display":"inline"},
                  childrens: [
                    {
                      input:
                      {
                        class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                        event: {
                          input: "onUrlData"
                        },

                        attributes: {
                          type: "text",
                          value: function (e) {
                            return this.subMenuUrl;
                          },
                          placeholder: "Enter Url"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
    },
        style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'SubMenuItemListComponent_subMenuUrl_gh', keyColumn: false})
    subMenuUrl : string;
//#endregion subMenuUrl Prop

//#region subMenuUrl Prop
@gridColumn({template:{
  div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                  event: {
                    input: "onOrderData"
                  },

                  attributes: {
                    type: "number",
                    value: function (e) {
                      return this.displayOrder;
                    },
                    min: 1,
                    placeholder: "Enter Display Order"
                  }
                }
              }
            ]
          }
        }
      ]
    }
},
  style: { width: "5%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'SubMenuItemListComponent_displayOrder_gh', keyColumn: false})
displayOrder : number;
//#endregion subMenuUrl Prop
//#region parentMenuId Prop
    @prop()
    parentMenuId : number;
//#endregion parentMenuId Prop

//#region isVisible Prop
    @prop()
    isVisible : boolean;
//#endregion isVisible Prop

}