import {vProductMaterialLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductMaterialLookUp extends vProductMaterialLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}