import { NewsBase, LanguageBase, } from '@app/database-models'
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class News extends NewsBase implements FileDetailViewModel {
    @prop()
    fileData: string;

    @prop()
    fileExtention: string;

    @prop()
    fileName: string;

    @prop()
    displayImage: boolean;

    @prop()
    imageURL1: string;

    @prop()
    imageURL2: string;

    @prop()
    imageURL3: string;

    @prop()
    imageURL4: string;

    @prop()
    imageURL5: string;

    @prop()
    fileData1: string;

    @prop()
    fileExtention1: string;

    @prop()
    fileName1: string;

    @prop()
    displayBigImage: boolean;

    @prop()
    fileData2: string;

    @prop()
    fileExtention2: string;

    @prop()
    fileName2: string;

    @prop()
    filePath: string;
    //#region Generated Reference Properties
    //#region language Prop
    language: LanguageBase;
    //#endregion language Prop

    //#endregion Generated Reference Properties

    @prop({defaultValue: false})
    isRemovedFileData: boolean;

    @prop({defaultValue: false})
    isRemovedFileData1: boolean;

    @prop({defaultValue: false})
    isRemovedFileData2: boolean;

    @prop()
    removedVideoName: string;

    @prop()
    stCreatedOn: string;
    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;
    @prop()
    blobFileName3: string;
    @prop()
    blobPath3: string;
    @prop()
    size3: number;
}