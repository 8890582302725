import {MembersFeedBackBase,} from '@app/database-models'
import { prop, required } from '@rxweb/reactive-form-validators'
//Generated Imports
export class MembersFeedBack extends MembersFeedBackBase 
{
    @required({message: "Please validate the reCAPTCHA."})
    recaptcha

    @prop()
    fileData: string;

    @prop()
    fileExtention: string;

    @prop()
    fileName: string;

    @prop()
    blobFileName:string;

    @prop()
    blobPath:string;
    
    @prop()
    size:number;


//#region Generated Reference Properties

//#endregion Generated Reference Properties











}