<div [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="Label_Blacklist_t"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right"> 
      <app-system-time></app-system-time>
    </div>
  </div>
</div>
<div class="wrapper wrapper-content flex-grow-1"  [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_Blacklist_t"></label></h5>
      <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x" ></i>
      </a>
    </div>

    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="tabs-container people-tabs">
        <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'address'"
              (click)="activeTab('address')">
              <label rxText="BlackListComponent_Title_IPAddresses"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'network'"
              (click)="activeTab('network')">
              <label rxText="BlackListComponent_Title_IPNetworks"></label></a>
          </li>
        </ul>
        <!-- <div class="slick_demo-btn"></div> -->
        <div class="tab-content">
          <!-- <div role="tabpanel" class="tab-pane d-block" *ngIf="selectedTab == 'address'"> -->
          <div role="tabpanel" *ngIf="selectedTab == 'address'" class="tab-pane d-block"
            [class.active]="selectedTab == 'address'">
            <div class="panel-body">
              <app-banned-ip-address-list></app-banned-ip-address-list>
            </div>
          </div>
          <!-- <div role="tabpanel" class="tab-pane d-block" *ngIf="selectedTab == 'network'"> -->
          <div role="tabpanel" *ngIf="selectedTab == 'network'" class="tab-pane d-blockp"
            [class.active]="selectedTab == 'network'">
            <div class="panel-body">
              <app-banned-ip-network-list></app-banned-ip-network-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>