import {ProductDesignerMappingBase,ProductDesignBase,} from '@app/database-models'
//Generated Imports
export class ProductDesignerMapping extends ProductDesignerMappingBase 
{




//#region Generated Reference Properties
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop

//#endregion Generated Reference Properties











}