import {vPaymentMethodRecordBase,} from '@app/database-models'
//Generated Imports
export class vPaymentMethodRecord extends vPaymentMethodRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}