import {BookingCustomerMappingBase,BookingBase,} from '@app/database-models'
//Generated Imports
export class BookingCustomerMapping extends BookingCustomerMappingBase
{


    clientIds: BookingCustomerMapping[];

//#region Generated Reference Properties
//#region booking Prop
booking : BookingBase;
    headerBtnChk: boolean;
    query: string;
//#endregion booking Prop

//#endregion Generated Reference Properties











}