import {vSSpecificationAttributeOptionLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSSpecificationAttributeOptionLookUp extends vSSpecificationAttributeOptionLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}