import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductVariantLocalizationBase {

//#region productVariantLocalizedId Prop
        @prop()
        productVariantLocalizedId : number;
//#endregion productVariantLocalizedId Prop


//#region productVariantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @required()
        @maxLength({value:400})
        name : string;
//#endregion name Prop


//#region description Prop
        @required()
        description : string;
//#endregion description Prop


//#region altTag Prop
        @maxLength({value:4000})
        altTag : string;
//#endregion altTag Prop


//#region metaTitle Prop
        @maxLength({value:800})
        metaTitle : string;
//#endregion metaTitle Prop


//#region metaKeywords Prop
        @maxLength({value:800})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @maxLength({value:4000})
        metaDescription : string;
//#endregion metaDescription Prop


//#region tempDescription Prop
        @required()
        tempDescription : string;
//#endregion tempDescription Prop


//#region productVariant_Description Prop
        @prop()
        productVariant_Description : string;
//#endregion productVariant_Description Prop


//#region productVariant_ShortDescription Prop
        @prop()
        productVariant_ShortDescription : string;
//#endregion productVariant_ShortDescription Prop





}