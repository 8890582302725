import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HolidayListBase {

        //#region holidayId Prop
        @prop()
        holidayId: number;
        //#endregion holidayId Prop


        //#region holidayName Prop
        @required()
        @maxLength({ value: 50 })
        @notEmpty()
        @trim()
        holidayName: string;
        //#endregion holidayName Prop


        //#region holidayYear Prop
        @required()
        @notEmpty()
        holidayYear: Date;
        //#endregion holidayYear Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        //#region holidayTypeId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        holidayTypeId: number;
        //#endregion holidayTypeId Prop

        //#region recalculateLeave Prop
        @prop({ defaultValue: true })
        recalculateLeave: boolean;
        //#endregion recalculateLeave Prop

}