import { gridColumn, actionColumn } from '@rxweb/grid';
import { Currency } from '../extended-models/currency';
// @actionColumn({
//     allowSorting: false,
//     style: { width: "2%" },
//     configuredTemplate: { templateName: 'view' }, columnIndex: 10, headerTitle: "Action"
// })
//#291870//28-06-2024//adding order mapping to project and event
@actionColumn({
    allowSorting: false,
    style: { width: "3%", "text-align": "initial" },
    headerTitle: '        ',
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})
export class CorporateOrderListViewModelBase {

    //#region isChecked Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: true, columnIndex: 0, headerKey: 'CorporateOrdersAddComponent_Action_gh', configuredTemplate: { templateName: 'bindCheckBox' }, allowSorting: false })
    isChecked: boolean;
    //#endregion isChecked Prop
    @gridColumn({ parameter: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: 'redirectSalesOrderLink' }, columnIndex: 1, allowSorting: true,headerKey:'CorporateOrdersAddComponent_OrderId_gh', keyColumn: true })
    OrderId: number;

    @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: "CorporateOrdersAddComponent_OrderDate_gh", keyColumn: false })
    OrderDate: Date;

    @gridColumn({ parameter: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: "CorporateOrdersAddComponent_OrderStatus_gh", keyColumn: false })
    OrderStatus: string;

    @gridColumn({ parameter: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: "CorporateOrdersAddComponent_PaymentStatus_gh", keyColumn: false })
    PaymentStatus: string;



    @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: "CorporateOrdersAddComponent_ShippingStatus_gh", keyColumn: false })
    ShippingStatus: string;

    @gridColumn({ parameter: true, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: "CorporateOrdersAddComponent_OrderTotalIncTax_gh", keyColumn: false })
    OrderTotalIncTax: number;


    @gridColumn({ parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: "CorporateOrdersAddComponent_NumbOfPieces_gh", keyColumn: false })
    NumberOfPieces: number;

    @gridColumn({ parameter: true, configuredTemplate: { templateName: "reDirectPeopleCorporate" }, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: "CorporateOrdersAddComponent_Customer_gh", keyColumn: false })
    Customer: string;

 
    OrderStatusID: number;
    paymentStatusID: number;
    ShippingStatusID: number;
    CustomerID: number;
    @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 0, allowSorting: true, headerKey: 'SupplierId', keyColumn: true })
     SupplierId: number;
    CustEmail: string;
    SalesChannelID: number;
    SalesUserId: number;
    overallOrderTotalIncTax: number;
    totalCount: number;
    ShippingDetailsData:any;

}
