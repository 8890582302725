import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TripBase {

//#region tripID Prop
        @prop()
        tripID : number;
//#endregion tripID Prop


//#region tripGuID Prop
        @required()
        tripGuID : any;
//#endregion tripGuID Prop


//#region tripDescription Prop
        @required()
        @maxLength({value:4000})
        tripDescription : string;
//#endregion tripDescription Prop


//#region requestBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        requestBy : number;
//#endregion requestBy Prop


//#region responsibleBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        responsibleBy : number;
//#endregion responsibleBy Prop


//#region locationCity Prop
        @maxLength({value:200})
        locationCity : string;
//#endregion locationCity Prop


//#region locationCountry Prop
        @prop()
        locationCountry : number;
//#endregion locationCountry Prop


//#region dateCheckOut Prop
        @required()
        dateCheckOut : Date;
//#endregion dateCheckOut Prop


//#region dateCheckIn Prop
        @required()
        dateCheckIn : Date;
//#endregion dateCheckIn Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @prop()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region deleted Prop
        @prop()
        deleted : boolean;
//#endregion deleted Prop


//#region tripName Prop
        @maxLength({value:255})
        tripName : string;
//#endregion tripName Prop


//#region tripFlag Prop
        @prop()
        tripFlag : number;
//#endregion tripFlag Prop


//#region currencyID Prop
        @prop()
        currencyID : number;
//#endregion currencyID Prop


//#region priceBasis Prop
        @prop()
        priceBasis : number;
//#endregion priceBasis Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region includepiecespecification Prop
        @required()
        includepiecespecification : boolean;
//#endregion includepiecespecification Prop


//#region tripType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        tripType : number;
//#endregion tripType Prop


//#region inventoryMove Prop
        @prop()
        inventoryMove : number;
//#endregion inventoryMove Prop


//#region movementBy Prop
        @prop()
        movementBy : number;
//#endregion movementBy Prop



}