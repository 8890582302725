import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCarrierGemfieldBase {

//#region carrierId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'carrierId', keyColumn: true})
        carrierId : number;
//#endregion carrierId Prop


//#region carrierName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'carrierName', keyColumn: false})
        carrierName : string;
//#endregion carrierName Prop

}