import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { AbstractEvent } from '../domain/abstract-event';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EventSearchModel } from 'src/app/view-model/event-search-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { EventViewModel } from 'src/app/view-model/event-view-model';
import { AllEventViewModel } from 'src/app/view-model/all-event-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { EventTypeEnum } from 'src/app/enums/event-type.enum';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { importTimelineChart } from 'src/app/domain/customize-plugin/timeline-chart';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { access } from '@rxweb/angular-router';
import { EventAddComponent } from '../add/event-add.component';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { EventInviteesViewModel } from 'src/app/view-model/event-invitees-view-model';
import { Workbook } from 'exceljs';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { EventExcelViewModel } from 'src/app/models/extended-models/event-excel-view-model';
import { NotesEnum } from "src/app/enums/notes.enum";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("EventSearchComponent")
@access({ accessLevel: RolePermissionEnums.Event, action: "get" })

@Component({
  selector: "app-event-list",
  templateUrl: './event-search.component.html'
})
export class EventSearchComponent extends AbstractEvent implements OnInit, OnDestroy {

  eventSearchFormGroup: IFormGroup<EventSearchModel>
  searchEventName: string = "";
  eventSearchModel: EventSearchModel;
  eventsGrid: AppGrid;
  allEvents: AllEventViewModel[];
  specificEvents: EventViewModel[];
  isFilter: boolean;
  showTab: boolean = false;
  eventAdd: any = EventAddComponent;
  totalRecords: number = 0;
  exportExcel = new ExportToExcelModel();
  list: any;
  exportToExcelObj = new ExportToExcel();
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;
  showEventFilter: boolean = true;
  resetNameControl: boolean = true;
  eventIdGo:number;
  msgError: boolean = false;


  _this;

  constructor(private datePipe: DatePipe, modalView: ModalView, private formBuilder: RxFormBuilder, router: Router, private applicationBroadcaster: ApplicationBroadcaster, private title: Title,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modalView;
    this.router = router;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'events', subMenu: '' });
    this.isGridLayout = true;
    this.isFilter = false;
    this.moduleID = RolePermissionEnums.Event;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Events - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.selectedTab = "event-all";
    this.spin = true;
    this.lookup([
      { path: USER_LOOKUPS["eventOwnerLookUp"], propName: "eventOwnerLookUp" },
      { path: USER_LOOKUPS["eventTypeLookUp"], propName: "eventTypeLookUp" },
      { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.genfieldsGroupEntity, propName: "genfieldsGroupEntity" },
      { path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp" },
      { path: USER_LOOKUPS["eventServiceTypeLookUp"], propName: "eventServiceTypeLookUp" },
      { path: USER_LOOKUPS.specificRiskLookUp, propName: "specificRiskLookUp", params: [0] }
    ]).subscribe(response => {
      this.spin = false;
      this.eventLookup = response;

      this.eventSearchFormGroup = this.formBuilder.formGroup(EventSearchModel, this.eventSearchModel) as IFormGroup<EventSearchModel>;

      this.showComponent = true;

    });

  }

  goTo() {
    this.eventSearchFormGroup.submitted = true;
    if (this.eventSearchFormGroup.controls.eventId.valid) {
              this.eventIdGo = this.eventSearchFormGroup.controls.eventId.value;
              if(this.eventIdGo){
                this.get({ path: 'api/SearchCommon/IsRecordExist', queryParams: {"id":this.eventIdGo,"moduleId":NotesEnum.EventNotes} }).subscribe((data: any) => {
                  debugger;
                  if(data){
                    this.router.navigate(['/events', this.eventIdGo], { queryParams: { "activeTab": "event-edit" } });
                        }else{
                          this.msgError=true;
                        }
                  });
              }else{
                this.msgError = true;
              }
            }
            else {
                this.msgError = true;
            }
}
  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  searchButtonClicked() {
    this.closeBubble();
    this.isFilter = false;
    this.selectedTab = "event-all";
    // this.eventSearchFormGroup.patchValue({
    //     eventType: 0

    // });
    this.searchEvents();
  }

  changeLayout($event) {
    this.isGridLayout = $event.target.checked ? false : true;
    //this.showEventFilter = $event.target.checked ? false : true;
    if ($event.target.checked) {
      this.showEventFilter = false;
      this.eventSearchFormGroup.patchValue({
        eventType: undefined
      })
    }
    else {
      this.showEventFilter = true;
      this.eventSearchFormGroup.patchValue({
        eventType: this.eventSearchFormGroup.controls.eventType.value
      })
    }
  }

  searchEvents() {
    // this.closeBubble();
    // this.isFilter = false;
    // this.selectedTab = "event-all";
    // this.eventSearchFormGroup.patchValue({
    //     eventType: 0

    // });
    this.msgError=false;
    this.eventSearchFormGroup.patchValue({
      eventId:null
    });
    if (dateCompareValidation(this.eventSearchFormGroup.value.eventStartDate, this.eventSearchFormGroup.value.eventEndDate) == true) {
      this.dateError = true;
    }
    else {
      this.resetNameControl = false;
      setTimeout(() => {
        this.resetNameControl = true;
      }, 10);
      if (!this.isGridLayout) {
        this.showTab = false;
        this.isShowChart = false;
        this.dateError = false;
        this.showTab = true;
        this.isShowGrid = false;
        this.spin = true;
        let eventType = this.selectedTab == 'event-all' ? 0 : Number(this.selectedTab);
        this.isShowChart = true;
        this.eventSearchFormGroup.patchValue({
          //clientURL:CLIENT_URL,
          eventType: eventType
        });

        this.post({ path: 'api/SearchEvent/EventSearch', body: { query: JSON.stringify(this.eventSearchFormGroup.value) } }).subscribe((response: any) => {
          this.allEvents = response;
          let headerDivId: string;
          if (this.selectedTab != "event-all") {
            headerDivId = "h" + this.selectedTab;
            document.getElementById(headerDivId).style.display = "none";
          }

          if (!this.isFilter) {
            if (this.allEvents.length > 0) {
              this.isNoRecordFound = false;
              importTimelineChart(response, this.selectedTab);
            }
            else {
              this.isNoRecordFound = true;
              this.isShowChart = false;
            }
          }
          else {

            if (this.allEvents.length > 0) {
              //document.getElementById(headerDivId).style.display="none";
              importTimelineChart(response, this.selectedTab);
            }
            else {
              document.getElementById(headerDivId).style.display = "inline-block";
            }
          }

          this.spin = false;


        });
      }
      else
      // {
      //     if(this.eventsGrid!=null){
      //         this.eventsGrid.updateSource([]);
      //         this.eventsGrid.destroy();
      //         this.eventsGrid=null;
      //        this.isFilter=false;
      //     }

      //     this.bindGrid();
      // }
      {
        if (this.eventsGrid != null) {
          this.isFilter = true;
          this.eventsGrid.updateSource([]);
          this.eventsGrid.storeProcedure.nextPage = 1;
          this.eventSearchFormGroup.value.rowCount = this.eventsGrid.maxPerPage;
        }
        this.eventSearchFormGroup.value.pageIndex = 1
        this.bindGrid();
      }

    }

  }

  bindGrid() {
    this.showTab = true;
    this.isShowChart = false;
    this.isShowGrid = false;
    this.dateError = false;
    this.spin = true;
    this.isShowGrid = true;
    this.post({ path: 'api/SearchEvent/EventSearchTable', body: { query: JSON.stringify(this.eventSearchFormGroup.value) } }).subscribe((response: any) => {
      this.allEvents = response;
      this.spin = false;
      if (this.allEvents != undefined && this.allEvents.length > 0) {
        this.isNoRecordFound = false;
        this.totalRecords = this.allEvents[0].totalCount;
        if (!this.isFilter) {


          this.eventsGrid = new AppGrid(this.allEvents, AllEventViewModel);
          this.eventsGrid.maxPerPage = this.totalRecords;
          this.eventsGrid.hideFooter = true;

          //   this.eventsGrid.pagingSource=[];
          //  this.eventsGrid.pagingSource=[25,100,2500,3000];
          this.eventsGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
        }
        else {
          // this.eventsGrid.pagingSource=[];
          // this.eventsGrid.pagingSource=[25,100,2500,3000];
          this.eventsGrid.storeProcedure.length = this.totalRecords;
          this.eventsGrid.updateSource([]);
          this.eventsGrid.updateSource(this.allEvents)
        }

        this.eventsGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.eventsGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.eventsGrid.componentId = this.componentName;
      }
      else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
      }
    });
  }


  onPageChanging(x) {
    this.isFilter = true;
    this.eventSearchFormGroup.value.pageIndex = x;
    this.eventSearchFormGroup.value.rowCount = this.eventsGrid.maxPerPage;
    this.eventsGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.eventSearchFormGroup.value.sortOrder = y;
    this.eventSearchFormGroup.value.orderBy = x;
    this.eventsGrid.storeProcedure.nextPage = z;
    this.eventsGrid.updateSource([]);
    this.bindGrid();
  }

  exportToExcel() {
    this.spin = true;
    this.exportExcel.eventStartDate = this.eventSearchFormGroup.value.eventStartDate;
    this.exportExcel.eventEndDate = this.eventSearchFormGroup.value.eventEndDate;
    this.exportExcel.eventName = this.eventSearchFormGroup.value.eventName;
    this.exportExcel.eventOwner = this.eventSearchFormGroup.value.eventOwner;
    this.exportExcel.eventType = this.eventSearchFormGroup.value.eventType;
    this.exportExcel.eventCountry = this.eventSearchFormGroup.value.eventCountry;
    this.exportExcel.gemfieldsGroupCompanyEventOwner = this.eventSearchFormGroup.value.gemfieldsGroupCompanyEventOwner;
    this.exportExcel.department = this.eventSearchFormGroup.value.department;
    this.exportExcel.eventServiceTypeIds = this.eventSearchFormGroup.value.eventServiceTypeIds;
    this.exportExcel.specificRiskId = this.eventSearchFormGroup.value.specificRiskId;
    this.post({ path: 'api/SearchEvent/EventExcel', body: { query: JSON.stringify(this.exportExcel) } }).subscribe((response: any) => {
      this.spin = false;
      this.list = response;
      this.generatePiecesExcel();
    })

  }
  generatePiecesExcel() {
    this.spin = true;
    let returnedRequestData = this.requestExcelData(this.list);
    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    // Cell Style : Fill and Header
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    var FileName = "Events" + MMDDYY;

    this.exportToExcelObj.FileName = FileName;
    this.exportToExcelObj.Type = "Excel";
    // const headerRow = worksheet.addRow(header);
    var localizationData = (MultiLingualData.get(this.componentName) as any);
    worksheet.columns = [
      { header: localizationData.EventSearchComponent_MonthName_ex_ex, key: 'MonthName', width: 20 },
      { header: localizationData.EventSearchComponent_FabergeEvent_ex_ex, key: 'Faberge Event', width: 20 },
      { header: localizationData.EventSearchComponent_MediaFabergeEvent_ex_ex, key: 'MediaFaberge Event', width: 20 },
      { header: localizationData.EventSearchComponent_NonFabergeEvent_ex_ex, key: 'NonFaberge Event', width: 20 },
      { header: localizationData.EventSearchComponent_GemfieldsEvent_ex_ex, key: 'Gemfields Event', width: 20 },
      { header: localizationData.EventSearchComponent_ProductVariantEvent_ex_ex, key: 'ProductVariant Event', width: 20 },
      { header: localizationData.EventSearchComponent_Wholesale_ex_ex, key: 'Wholesale', width: 20 },
      { header: localizationData.EventSearchComponent_Training_ex_ex, key: 'Training', width: 20 },
      { header: localizationData.EventSearchComponent_BoardMeetings_ex_ex, key: 'BoardMeetings', width: 20 },
      { header: localizationData.EventSearchComponent_GemfieldsMediaEvent_ex_ex, key: 'GemfieldsMedia Event', width: 20 }

    ];

    returnedRequestData.forEach(x => {
      worksheet.addRow(x);
    });
    worksheet.getRow(1).eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: '00000000'
        },
        bgColor: {
          argb: '00000000'
        },
      };
      cell.font = {
        color: {
          argb: 'FFFFFFFF',
        },
        bold: true
      }
      cell.border = {
        top: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        bottom: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        }
      };
    });


    setTimeout(() => {
      this.spin = false;
      workbook.xlsx.writeBuffer().then(async (y: any) => {
        // const blob = new Blob([y], {
        //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // });
        // fs.saveAs(blob, FileName + '.xlsx');
        var u8 = new Uint8Array(y);
        var b64encoded = btoa(this.Uint8ToString(u8));
        const blob = new Blob([y], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        let uploadedBlobName = await this.uploadExcelToBlob(blob, "xlsx");
        // this.exportExcel.Result = b64encoded;
        this.exportExcel.BlobFileName = uploadedBlobName;        
        this.exportToExcelObj.EntityType = NotesEnum.EventNotes;
        this.exportToExcelObj.Description = "Events"
        this.post({ body: this.exportToExcelObj, path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
          fs.saveAs(blob, Response);
        })
        //fs.saveAs(blob, FileName + '.xlsx');
      });
    }, 1000);


  }

  requestExcelData(usersGrid: EventExcelViewModel[]) {
    let userListData = new Array<SaleSuccessData>();

    usersGrid.forEach(element => {
      let listData = new SaleSuccessData();
      listData["MonthName"] = element.eventMonthYear;
      listData["Faberge Event"] = element.fabergeEvent
      listData["MediaFaberge Event"] = element.mediafabergeEvent;
      listData["NonFaberge Event"] = element.nonfabergeEvent;
      listData["Gemfields Event"] = element.gemfieldsEvent;
      listData["ProductVariant Event"] = element.productVariantEvent;
      listData["Wholesale"] = element.wholesale;
      listData["Training"] = element.training;
      listData["BoardMeetings"] = element.boardMeetings;
      listData["GemfieldsMedia Event"] = element.gemfieldsMediaEvent;
      userListData.push(listData);

    });
    return userListData;
  }
  Uint8ToString(u8a) {
    var CHUNK_SZ = 0x8000;
    var c = [];
    for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
      c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
    }
    return c.join("");
  }
  onEdit(events: AllEventViewModel) {
    //  this.router.navigate(['/events',events.eventId]);
    this.router.navigate(["events", events.eventId], { queryParams: { activeTab: 'event-info' } });
  }

  search(value) {
    this.eventsGrid.search = value.target.value;
  }
  activeTabGrid(tabNo: string) {
    this.selectedTab = tabNo;
    this.isFilter = true;
    //  this.showTab=false;
    this.closeBubble();
    setTimeout(() => {
      this.searchEvents();
    }, 100);
    ///if(this.allEvents.length>0){

    // }
  }
  ngOnDestroy() {
    this.closeBubble();
  }

  closeBubble() {
    var bodyElement = document.getElementsByTagName("body")[0];
    if (bodyElement != null && bodyElement.className.includes("simileAjax-bubble")) {
      bodyElement.removeChild(bodyElement.lastChild);
    }
  }
  checkNameText(event: any) {
    this.eventSearchFormGroup.patchValue({
      eventText: event.target.value
    });
    this.searchEventName = event.target.value;
  }
  onNameClick($event) {
    this.eventSearchFormGroup.patchValue({
      eventText: ''
    })
    this.searchEventName = $event.item.value;
  }
  checkOwnerText(event: any) {
    this.eventSearchFormGroup.patchValue({
      eventOwnerText: event.target.value
    });
    //this.searchEventName = event.target.value;
  }
  onOwnerClick($event) {
    this.eventSearchFormGroup.patchValue({
      eventOwnerText: ''
    })

  }
  get componentName(): string {
    return "EventSearchComponent";
  }
}

class ExportToExcelModel {
  eventEndDate: Date;
  eventStartDate: Date;
  eventName: string;
  eventOwner: number;
  eventType: number;
  eventCountry: number;
  BlobFileName:string;
  gemfieldsGroupCompanyEventOwner: number;
  department: number;
  eventServiceTypeIds: string;
  specificRiskId: String; 
}
class ExportToExcel {
  Result: string;
  Type: string;
  FileName: string;
  EntityType: number;
  Description: string;
}
class SaleSuccessData {
  "MonthName": any;
  "Faberge Event": string;
  "MediaFaberge Event": string;
  "NonFaberge Event": string;
  "Gemfields Event": string;
  "ProductVariant Event": string;
  "Wholesale": string;
  "Training": string;
  "BoardMeetings": string;
  "GemfieldsMedia Event": string;
}
