import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from "@angular/core"
import { AbstractDiscount } from '../domain/abstract-discount';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Discount } from '@app/models';
import { LanguagesEnums } from 'src/app/custom-enum/language-enums';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { DiscountLookupGroup } from 'src/app/view-model/discount-lookup-group';
import { DatePipe, PlatformLocation } from '@angular/common';
import { DiscountRequirementEnum } from 'src/app/enums/discount-requirement.enum';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { multilingual } from '@rxweb/localization';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { ModalView } from "src/app/domain/customize-design/modal";
@multilingual("DiscountEditComponent")
@access({ accessLevel: RolePermissionEnums.Discounts, action: "put" })

@Component({
    selector: "app-discount-edit",
    templateUrl: './discount-edit.component.html'
})
export class DiscountEditComponent extends AbstractDiscount implements OnInit, OnDestroy {
    discount: Discount;
    subscription: any;
    id: number;
    languageId: number = LanguagesEnums.English;
    englishLanguageId: any = LanguagesEnums.English;
    discountId: number = 0;
    DiscountRequirementEnumid: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    discountDelete: any = dbAccessModule[RolePermissionEnums.Discounts]["delete"];
    @Input() hide: Function;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(modalView:ModalView, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private applicationBroadcaster:ApplicationBroadcaster, private router: Router, private datePipe: DatePipe, private el: ElementRef, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.Discounts;
        this._this = this;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Discounts - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.lookup<DiscountLookupGroup>([{ propName: "discount", params: [this.id] }, { path: USER_LOOKUPS.discountTypeLookUp, propName: "discountTypeLookUp" }, { path: USER_LOOKUPS.discountRequirementLookUp, propName: "discountRequirementLookUp" }, { path: USER_LOOKUPS.discountLimitationLookUp, propName: "discountLimitationLookUp" }, { path: USER_LOOKUPS.billingCountryLookUp, propName: "billingCountryLookUp" }, { path: USER_LOOKUPS.shippingCountryLookUp, propName: "shippingCountryLookUp" }]).subscribe(response => {
            if (response.discount) {
                this.discountLookupGroup = response;
                this.discountFormGroup = this.formBuilder.formGroup(Discount, this.discountLookupGroup.discount) as IFormGroup<Discount>;
                if (this.discountFormGroup.value.requiresCouponCode == true) {
                    this.showCouponcode = true;
                    if (this.discountFormGroup.value.couponCode == " ") {
                        this.discountFormGroup.patchValue({
                            couponCode: ""
                        });
                    }
                    this.discountFormGroup.controls.couponCode.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" })])
                }

                if (this.discountFormGroup.value.usePercentage == true) {
                    this.showDiscountPercentage = true;
                    this.showDiscountAmount = false;
                }

                this.discountLimitationChange(this.discountFormGroup.value.discountLimitationId);
                this.change(this.discountFormGroup.value.discountRequirementId);
                this.discountFormGroup.controls.discountPercentage.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                RxwebValidators.pattern({ expression: { 'discountPercentage': /^(?:100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/ }, message: 'Please Enter a Correct Value' }),
                RxwebValidators.numeric({ allowDecimal: true, message: 'Only numbers are allowed' })]);
                this.discountFormGroup.controls.discountAmount.setValidators([
                    RxwebValidators.required({ message: "You can't leave this field empty" }),
                    RxwebValidators.pattern({ expression: { 'discountAmount': /^(?:[0-9][0-9]{0,8}(?:\.\d{1,2})?|2147483641|2147483641.00)$/ }, message: 'Please Enter a Correct Value' }),
                    RxwebValidators.numeric({ allowDecimal: true, message: 'Only numbers are allowed' })
                ]);

                this.showComponent = true;
            }
            else {
                this.router.navigate(['/discounts']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editdiscount(isRedirect) {
        this.discountFormGroup.submitted = true;
        if (dateCompareValidation(this.discountFormGroup.value.startDate, this.discountFormGroup.value.endDate) == true) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            if (this.discountFormGroup.valid) {
                this.spin = true;
                this.DiscountRequirementEnumid = this.discountFormGroup.value.discountRequirementId;
                if (this.DiscountRequirementEnumid == DiscountRequirementEnum.HasalloftheseDesignProductsintheTray ||
                    this.DiscountRequirementEnumid == DiscountRequirementEnum.HasoneoftheseDesignProductsintheTray ||
                    this.DiscountRequirementEnumid == DiscountRequirementEnum.HaspurchasedalloftheseDesignProducts ||
                    this.DiscountRequirementEnumid == DiscountRequirementEnum.HaspurchasedoneoftheseDesignProducts) {
                    this.discountFormGroup.value.requirementBillingCountryIs = 0;
                    this.discountFormGroup.value.requirementShippingCountryIs = 0;
                }
                else if (this.DiscountRequirementEnumid == DiscountRequirementEnum.Billingcountryis) {
                    this.discountFormGroup.value.requirementSpentAmount = 0;
                    this.discountFormGroup.value.requirementShippingCountryIs = 0;
                }
                else if (this.DiscountRequirementEnumid == DiscountRequirementEnum.Shippingcountryis) {
                    this.discountFormGroup.value.requirementSpentAmount = 0;
                    this.discountFormGroup.value.requirementBillingCountryIs = 0;
                }
                else {
                    this.discountFormGroup.value.requirementSpentAmount = 0;
                    this.discountFormGroup.value.requirementBillingCountryIs = 0;
                    this.discountFormGroup.value.requirementShippingCountryIs = 0;
                }

                if (this.discountFormGroup.value.usePercentage == true) {
                    this.discountFormGroup.controls.discountAmount.clearValidators();
                    this.discountFormGroup.controls.discountPercentage.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                    RxwebValidators.pattern({ expression: { 'discountPercentage': /^(?:100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/ }, message: 'Please Enter a Correct Value' }),
                    RxwebValidators.numeric({ allowDecimal: true, message: 'Only numbers are allowed' })]);
                    this.discountFormGroup.value.discountAmount = 0;
                }
                else {
                    this.discountFormGroup.controls.discountPercentage.clearValidators();
                    this.discountFormGroup.controls.discountAmount.setValidators([
                        RxwebValidators.required({ message: "You can't leave this field empty" }),
                        RxwebValidators.pattern({ expression: { 'discountAmount': /^(?:[0-9][0-9]{0,8}(?:\.\d{1,2})?|2147483641|2147483641.00)$/ }, message: 'Please Enter a Correct Value' }),
                        RxwebValidators.numeric({ allowDecimal: true, message: 'Only numbers are allowed' })
                    ]);

                    this.discountFormGroup.value.discountPercentage = 0;
                }
                console.log(this.discountFormGroup);
                this.put({ body: this.discountFormGroup.value, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.router.navigate(['/discounts', data]) : this.router.navigate(['/discounts']);
                })
            } else {
                for (const key of Object.keys(this.discountFormGroup.controls)) {
                    if (this.discountFormGroup.controls[key].invalid) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                        invalidControl.focus();
                        break;
                    }
                }
            }
        }
    }

    deletediscount() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/discounts']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Discount");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    get componentName(): string {
        return "DiscountEditComponent";
    }
}
