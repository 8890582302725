import { prop, required, propObject, trim, numeric } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentUserFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "fullName";
    }

    @prop()
    @trim()
    name: string;

    @prop()
    @trim()
    nameText: string;

    @prop()
    @trim()
    email: string;

    @prop()
    @trim()
    emailText: string;

    @prop()
    country: number;

    @prop()
    @numeric()
    userId: number;

    @prop()
    keyRelationshipHolder: number;

    @prop()
    salesAdvisor: number;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}