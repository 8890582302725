import {WatchStatusBase,} from '@app/database-models'
//Generated Imports
export class WatchStatus extends WatchStatusBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}