import {HSEStatusUpdateBase,} from '@app/database-models'
//Generated Imports
export class HSEStatusUpdate extends HSEStatusUpdateBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}