import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AuditRequestBase {

//#region auditRequestId Prop
        @prop()
        auditRequestId : number;
//#endregion auditRequestId Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region applicationModuleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        applicationModuleId : number;
//#endregion applicationModuleId Prop


//#region applicationTimeZoneId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        applicationTimeZoneId : number;
//#endregion applicationTimeZoneId Prop


//#region mainRecordId Prop
        @required()
        @maxLength({value:100})
        mainRecordId : string;
//#endregion mainRecordId Prop


//#region uri Prop
        @required()
        @maxLength({value:1024})
        uri : string;
//#endregion uri Prop


//#region requestMethod Prop
        @required()
        @maxLength({value:10})
        requestMethod : string;
//#endregion requestMethod Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop



}