export enum RolePermissionEnums {
    WatchStatus = 1,
    GemstoneClarity = 2,
    GemfieldsEnhancement = 3,
    GemStoneCutType = 4,
    GemfieldsCarrier = 5,
    GemStoneColor = 6,
    GemfieldsLocation = 7,
    GemfieldsArrangement = 8,
    TaxDutyTreatment = 9,
    GemfieldsStation = 10,
    GemfieldsEnhancementType = 11,
    GemfieldsSalesChannel = 12,
    BankHoliday = 14,
    GemFieldsBookingStatuses = 15,
    GemstoneOrigins = 16,
    GemstoneStationGemfields = 17,
    GemstoneStatuses = 18,
    DesignCategories = 20,
    Locations = 21,
    Brands = 22,
    PieceOwner = 23,
    GemstoneTypes = 24,
    BOMCategories = 25,
    ManageCustomerRelationship = 26,
    Countries = 27,
    Collections = 28,
    DesignAttributes = 29,
    Currencies = 30,
    Titles = 31,
    SalesChannels = 32,
    Boutique = 33,
    PriceBands = 34,
    PieceDeliveryStatus = 35,
    ProductPieceStatus = 36,
    RingSizes = 37,
    EggSizes = 38,
    DutyStatus = 39,
    Hashtags = 40,
    Nationality = 41,
    Industrytypes = 42,
    BookingTypes = 43,
    ChainLength = 44,
    ChainColours = 45,
    Department = 47,
    GroupLocation = 48,
    HolidayLeaveTypes = 50,
    BangleSizes = 51,
    BankHolidays = 52,
    HolidayProcess = 53,
    PaymentMethods = 54,
    TaxProviders = 56,
    TaxCategories = 57,
    ShippingMethods = 58,
    StateProvince = 60,
    Languages = 61,
    ActivityLogTypes = 63,
    CreditCard = 64,
    HelpSupport = 73,
    Event = 75,
    User = 76,
    Supplier = 77,
    NewsletterSubscriber = 78,
    InternalDirectory = 79,
    CallMe = 80,
    Project = 81,
    News = 82,
    HelpTraining = 91,
    MeasureWeights = 94,
    MeasureDimensions = 95,
    Blacklist = 96,
    EmailAccounts = 97,
    MessageTemplates = 98,
    GlobalSettings = 99,
    AllSettings = 100,
    //GlobalSettings = 101,
    SystemInformation = 102,
    ActivityLog = 103,
    Log = 104,
    //PaymentMethods = 105,
    Discounts = 106,
    Localization = 107,
    ModuleLevelPermissions = 108,
    GemfieldsTeam = 109,
    FabergeTeam = 110,
    HSEUpdates = 111,
    BlacklistIpNetwork = 112,
    UserRoles = 113,
    UserLockout = 114,
    MessageQueue = 115,
    TeamLoginReport = 116,
    RepeatCustomersReport = 117,
    CustomerStatisticsReport = 118,
    CustomerLastContactedReport = 119,
    TeamMemberReport = 120,
    TravelReport = 121,
    ProductDesign = 122,
    Products = 123,
    Pieces = 124,
    IncidentType = 125,
    IncidentCategory = 126,
    TicketNotification = 127,
    ITHelpDesk = 128,
    AdvancedSearch = 129,
    ContentManagement = 130,
    FabergeNews = 131,
    FabergeHistory = 132,
    FabergePages = 133,
    HomePageLayout = 134,
    HSE = 135,
    GemfieldsRiskMenu = 136,
    PressandMedia = 137,
    SalesOrders = 138,
    CurrentPurchaseLists = 139,
    FabergeGemstones = 140,
    FabergeGemstoneOrders = 141,
    Bookings = 142,
    BookingApproval = 143,
    FabergePriceLists = 144,
    FabergeCarnets = 145,
    LeaveReport = 146,
    HrLeaveReport = 3215,
    InventorySummaryReport = 147,
    FinanceReport = 148,
    SupplierProductionReport = 149,
    InventoryCommentReport = 150,
    MissingProductDescription = 151,
    MissingProductPicture = 152,
    ProductCaptionReport1 = 53,
    CoreProductListReport = 154,
    DesignProductSuccessReport = 155,
    PieceOwnerReport = 156,
    PriceBandOptionReport = 157,
    BOMReport = 158,
    SoldPieceReport = 159,
    BespokeOrderReport = 160,
    GemstoneSetPieceReport = 161,
    PieceInProductionReport = 162,
    TimepiecesSalesReport = 163,
    LocationSummaryReport = 164,
    LocationSummaryByCollectionReport = 165,
    StockByLocationAnalysisReport = 166,
    SalesAnalysisReport = 167,
    SalesMarginReport = 168,
    SalesAdvisorReport = 169,
    ClientSalesReport = 170,
    WholeSaleReport = 171,
    NotesReport = 172,
    HSEReport = 173,
    MiningPerformance = 174,
    AgreementType = 175,
    LegalAgreements = 176,
    ProductionOrderForm = 177,
    MenuLayout = 178,
    RiskCategories = 179,
    SpecificRisks = 180,
    GrievanceTier = 181,
    GrievanceType = 182,
    ChainStyle = 183,
    ChainGauge = 184,
    GemfieldsRiskNotifications = 185,
    IANNotifications = 186,
    POS = 187,
    FabergeLocalization = 189,
    FormsDocument = 190,
    GINAReport = 191,
    SeoRedirection = 193,
    SystemHelp = 194,
    ITHelpDeskWeeklyReport = 195,
    ElectronicGiftForm = 196,
    ProductTypes = 197,
    InciType = 198,
    GoalsNotifications = 199,
    LeavesNotifications = 200,
    PressMediaReport = 201,
    CorporateCreditRating = 202,
    AdminSetting = 203,
    FileExtentions = 204,
    Regions = 205,
    MindMap = 206,
    Treatment = 207,
    LegalOwner = 208,
    WatchItemType=209,
    WatchMaterial=210,
    FabergeWatchParts=211,
    TrainingTypes = 212,
    Adhocs = 213,
    Materials = 214,
    RepairReport = 215,
    InventoryHistoryReport = 216,
    DeliveryType = 217,
    LegalAgreementAccessPermission = 218,
    PersonalAccessPermission = 219,
    AdditionalGroupCompanyUserMapping = 220,
    GrievanceForms = 221,
    ProductionOrderFormUserDepartmentMapping = 222,
    Colors = 223,
    DuplicateCustomers = 224,
    PowerBIReports = 226,
    GoalsObjectivesReport = 3216,
    TicketSeverity = 3217,
    DocumentType = 225,
    FabergeBatches=3218,
    AssetManufacturer=3252,
    AssetLocation=3253,
    CustomerStockNotificationReport=229,

    Dashboard=3219,
    BestSellers=3220,
    BusinessTravelThisMonth=3221,
    ContractsExpiring=3222,
    CustomerStatistics=3223,
    FabergeIncompleteOrders=3224,
    FabergeSales=3225,
    FabergeTopSalesPerson=3226,
    GroupEventUpdates=3227,
    GroupEventsWeek=3228,
    ITHelpDeskWidget=3229,
    LeaveApprovalRejectionPending=3230,
    MyBookings=3231,
    MyEvents=3232,
    MyProjects=3233,
    PendingTasks=3234,
    PeopleJoiningin7Days=3235,
    PeopleLeavingin7Days=3236,
    PressMediaCoverage=3237,
    ProjectUpdates=3238,
    RecentComments=3239,
    StockMovementRequired=3240,
    TeamMembersAnniversary=3241,
    TeamMembersOutOfOffice=3242,
    TeamMembersUpcomingBirthday=3243,
    UpcomingBirthdayAnniversery=3244,
    LiveUserTracking=3248,
    TimelineUserTracking=3249,
    SOSNotifications = 3250,
    ITAssets = 3254,
    ITAssetsGraph = 3255,
    EventServiceTypes=3256,
    ApiKeys=3257,
    HsCode = 3258, //#285950/ Naitik Bhatt/21-05-2024 RollPermission for HScode in F admin
    PatersonGrade = 3259, //#292627//08-07-2024//rollpermission for paterson grade
    ApplicationObject = 3260,
    AccessRequest = 3261,
    ReturnOrders = 3262
}
