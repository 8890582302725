<div class="ibox-content bg-white d-flex flex-column flex-grow-1"  [rxSpinner]="spin"
  *rxMultilingual="" [rxLocalisationInit]="componentName">

  <div class="dataTables_filter align-items-center">
    <div class="row">
      <div class="col-lg-7">
        
          <h3>{{productName}}</h3>
          <div [rxLocalisationInit]="componentName">
            <label class="col-form-label"><span rxText="ProductPieceDetailComponent_avgDayInStock_t"></span> {{avgDayInStock}} Days</label>
            <label class="col-form-label ml-2"><span rxText="ProductPieceDetailComponent_avgOngoingDayInStock_t"></span> {{avgOngoingDayInStock}} Days</label>
          </div>        
        <ng-container *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
          <button class="btn btn-primary mb-3 mr-sm-3" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t"></button>
        </ng-container>
      </div>
      <div class="col-lg-5">
        <label class="ml-auto mb-3 mt-5 mr-5 float-right">
          <input (keyup.enter)="search($event)" type="search"
            class=" table_search form-control form-control-sm pr-4 pull-right" placeholder=""
            *ngIf="(isShowGrid && !isFilter) || isFilter" [title]="titleSearch">
        </label>
        <div class="ibox-tools mr-3 mt-4 pt-3" *ngIf="!isNoRecordFound">
          <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
            <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="'ProductPieceDetailComponent'" *ngIf="isShowGridColumn"
            (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
            [gridColumnConfig]="productPieceGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
            [moduleID]="moduleID" [selectedTab]="selectedTab"></app-system-table-setting>
        </div>
      </div>

    </div>

  </div>

  <div class="table-responsive" *ngIf="isShowGrid">

    <div class="dataTables_wrapper pb-0 dt-bootstrap4" id="productGrid">
      <rx-grid [design]="productPieceGrid"></rx-grid>
    </div>
  </div>

  <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>

</div>
