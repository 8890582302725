<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
        <div class="row wrapper white-bg page-heading py-2 align-items-center">
            <div class="col-md-8 my-1">
              <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard']">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/measure-dimensions']" rxText="Label_Measure_Dimensions_t"></a>
                </li>
                <li class="breadcrumb-item active">Add New</li>
              </ol>
            </div>
            <div class="col-md-4 text-right">
              <!--<sapp-system-time></sapp-system-time>-->
            </div>
          </div>
      
        <div class="wrapper wrapper-content flex-grow-1">
      
          <div class="ibox mb-0 d-flex flex-column">
            <div class="ibox-title bg-white pr-3">
              <h5><label rxText="MeasureDimensionAddComponent_Title"></label></h5>
              <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x"></i>
              </a>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="measureDimensionFormGroup"  [rxSpinner]="spin">
              <div class="row add-countries-form" (keyup.enter)="addMeasureDimension(false)">
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="MeasureDimensionAddEditComponent_Name_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="name" class="form-control "  rxPlaceholder="MeasureDimensionAddEditComponent_Name_p" rxFocus />
                      <small class="form-text text-danger" [class.d-block]="measureDimensionFormGroup.controls.name.errors">{{measureDimensionFormGroup.controls.name.errorMessage}}</small>
                    </div>
                  </div>                 
                </div>
                <div class="col-lg-6 col-xl-4 px-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="MeasureDimensionAddEditComponent_RatioToPrimary_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="ratio" class="form-control"  rxPlaceholder="MeasureDimensionAddEditComponent_RatioToPrimary_p" />
                      <small class="form-text text-danger" [class.d-block]="measureDimensionFormGroup.controls.ratio.errors">{{measureDimensionFormGroup.controls.ratio.errorMessage}}</small>
                    </div>
                  </div>

                </div>
                <div class="col-lg-6 col-xl-4 pl-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label"  rxText="MeasureDimensionAddEditComponent_SystemKeyword_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="systemKeyword" class="form-control "  rxPlaceholder="MeasureDimensionAddEditComponent_SystemKeyword_p" />
                      <small class="form-text text-danger" [class.d-block]="measureDimensionFormGroup.controls.systemKeyword.errors">{{measureDimensionFormGroup.controls.systemKeyword.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4 pr-xl-5">                 
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="MeasureDimensionAddEditComponent_DisplayPriority_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="displayOrder" class="form-control"  rxPlaceholder="MeasureDimensionAddEditComponent_DisplayPriority_p" />
                      <small class="form-text text-danger" [class.d-block]="measureDimensionFormGroup.controls.displayOrder.errors">{{measureDimensionFormGroup.controls.displayOrder.errorMessage}}</small>
                    </div>
                  </div>
                </div>
              </div>
                <div class="row mt-auto">
                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="col text-right">
                    <button (click)="addMeasureDimension(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                    <button (click)="addMeasureDimension(true)" class="btn btn-primary mr-1" *rxAuthorize="[measureDimensionEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
                  </div>
                </div>
              </div>
          </div>
      
        </div>
      
      </div>
