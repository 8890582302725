import {GrievanceCategoryBase,} from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"

export class GrievanceCategory extends GrievanceCategoryBase 
{

 

//#region Generated Reference Properties

//#endregion Generated Reference Properties






}