<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
        <div class="row wrapper white-bg page-heading py-2 align-items-center">
            <div class="col-md-8 my-1">
              <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard']">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/state-provinces']" rxText="Label_States/Provinces_t"></a>
                </li>
                <li class="breadcrumb-item active" rxText="StateProvinceEditComponent_Title"></li>
              </ol>
            </div>
            <div class="col-md-4 text-right">
              <app-system-time></app-system-time>
            </div>
          </div>

        <div class="wrapper wrapper-content flex-grow-1">

          <div class="ibox mb-0 d-flex flex-column">
            <div class="ibox-title bg-white pr-3">
              <h5><label rxText="StateProvinceEditComponent_Title"></label></h5>
              <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x"></i>
              </a>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="stateProvinceFormGroup" [rxSpinner]="spin">
              <div class="row add-countries-form" (keyup.enter)="editStateProvince(false)">
                <div class="col-lg-6 col-xl-4 pr-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" rxText="StateProvinceEditComponent_ID_t"></label>
                    <div class="col-md-8">
                      <label type="text"
                                  class="form-control disableLabel-withoutheight mb-0">{{(stateProvinceFormGroup.value.stateProvinceId
                                  === null || stateProvinceFormGroup.value.stateProvinceId === undefined ) ? '' :
                                  stateProvinceFormGroup.value.stateProvinceId}}</label>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="StateProvinceAddEditComponent_Language_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <select class="form-control" formControlName="languageId" (change)="changeLanguage(stateProvinceFormGroup.value.languageId)" rxFocus>
                        <option *ngFor="let item of stateProvinceLookup.languageLookUp" [value]="item.languageId">{{item.languageName}}</option>
                      </select> -->
                      <rx-select [(source)]="stateProvinceLookup.languageLookUp" #rxSelect formControlName="languageId" [controlTabIndex]="1"
                      mainClass="form-control" (changed)="changeLanguage(stateProvinceFormGroup.value.languageId)" rxFocus
                      [keyValueProps]="['languageName','languageId']" [selectPlaceholder]="'Select Language'"></rx-select>
                      <!-- <small class="form-text text-danger" *ngIf="detail.controls.languageId.errors">{{detail.controls.languageId.errorMessage}}<br /></small> -->
                    </div>
                  </div>
                  <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="StateProvinceAddEditComponent_DisplayPriority_t"></label>
                      <div class="col-md-8">
                        <input type="text" formControlName="displayOrder" tabindex="4" class="form-control" rxPlaceholder="StateProvinceAddEditComponent_DisplayPriority_p" />
                        <small class="form-text text-danger" [class.d-block]="stateProvinceFormGroup.controls.displayOrder.errors">{{stateProvinceFormGroup.controls.displayOrder.errorMessage}}</small>
                      </div>
                    </div>

                </div>
                <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row mb-xl-3 mb-md-4">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="StateProvinceAddEditComponent_Name_t"></label>
                        <div class="col-md-8">
                          <input type="text" formControlName="name" class="form-control" tabindex="2" rxPlaceholder="StateProvinceAddEditComponent_Name_p" />
                          <small class="form-text text-danger" [class.d-block]="stateProvinceFormGroup.controls.name.errors">{{stateProvinceFormGroup.controls.name.errorMessage}}</small>
                        </div>
                      </div>

                      <div class="form-group row">
                          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="StateProvinceAddEditComponent_Country_t"></label>
                          <div class="col-md-8" [rxSelectExtended]="_this">
                            <!-- <select class="form-control" formControlName="countryId">
                              <option *ngFor="let item of stateProvinceLookup.countryLookUp" [value]="item.countryId">{{item.countryName}}</option>
                            </select> -->
                            <rx-select [(source)]="stateProvinceLookup.countryLookUp" #rxSelect formControlName="countryId"
                            mainClass="form-control" [controlTabIndex]="5" [selectPlaceholder]="'Select Country'"
                            [keyValueProps]="['countryName','countryId']"></rx-select>
                            <small class="form-text text-danger" [class.d-block]="stateProvinceFormGroup.controls.countryId.errors">{{stateProvinceFormGroup.controls.countryId.errorMessage}}</small>
                          </div>
                        </div>


                </div>
                <div class="col-lg-6 col-xl-4 pr-xl-4">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="StateProvinceAddEditComponent_Abbreviation_t"></label>
                        <div class="col-md-8">
                          <input type="text" formControlName="abbreviation" tabindex="3" class="form-control" rxPlaceholder="StateProvinceAddEditComponent_Abbreviation_p" />
                          <small class="form-text text-danger" [class.d-block]="stateProvinceFormGroup.controls.abbreviation.errors">{{stateProvinceFormGroup.controls.abbreviation.errorMessage}}</small>
                        </div>
                      </div>

                </div>
              </div>
              <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100"></div>
                <div class="col text-right">
                  <button (click)="editStateProvince(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                  <button (click)="editStateProvince(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                  <button (click)="deleteStateProvince()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
