import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGrievanceTypeBase {

//#region tripId Prop
    @prop()
    grievanceTypeId : number;
//#endregion tripId Prop

//#region tierName Prop
    @prop()
    grievanceTypeName : string;
//#endregion tierName Prop

}