import { gridColumn } from '@rxweb/grid';

export class vGemfieldGroupCompanyLookUpBase{
    
    //#region supplierId Prop
    @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'supplierId', keyColumn: true})
    supplierId : number;
    //#endregion supplierId Prop


    //#region company Prop
    @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'company', keyColumn: false})
    company : string;
    //#endregion company Prop
}