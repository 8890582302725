<div class="modal-dialog" *ngIf="showComponent">
  <div class="modal-content">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0">Change Password</h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-0" [formGroup]="changePasswordFormGroup" 
      [rxSpinner]="spin">
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" >Current
          Password</label>
        <div class="col-md-8">
          <input type="password" class="form-control" formControlName="currentPassword"
            >
          <small class="form-text text-danger"
            [class.d-block]="changePasswordFormGroup.controls.currentPassword.errors">{{changePasswordFormGroup.controls.currentPassword.errorMessage}}</small>
          <small class="form-text text-danger password-validation-message mt-1" *ngIf="isCurrentPassword">Incorrect
            Current Password</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" >New Password</label>
        <div class="col-md-8">
          <input type="password" class="form-control" formControlName="newPassword" 
            (keyup)="hideMsg()">
          <!-- <small class="form-text text-danger password-validation-message"
            [class.d-block]="changePasswordFormGroup.controls.newPassword.errors">{{changePasswordFormGroup.controls.newPassword.errorMessage}}</small> -->
          <small class="form-text text-danger password-validation-message"
            [class.d-block]="changePasswordFormGroup.controls.newPassword.errors"
            [innerHtml]="changePasswordFormGroup.controls.newPassword.errorMessage"></small>
          <!-- <small class="form-text text-danger password-validation-message" *ngIf="passwordAlreadyUsed">This password already exists. Please enter new password</small> -->
          <small class="form-text text-danger password-validation-message" *ngIf="passwordAlreadyUsed">Please choose a
            password that you haven't used before. To help protect your account, you need to choose a new password every
            time you reset it.</small>
          <!-- <small class="form-text text-danger password-validation-message" *ngIf="isSameCurrentPassword">New password cannot be same as Current Password</small> -->
          <small class="form-text text-danger password-validation-message" *ngIf="isSameCurrentPassword">Please choose a
            password that you haven't used before. To help protect your account, you need to choose a new password every
            time you reset it.</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" >Confirm
          Password</label>
        <div class="col-md-8">
          <input type="password" class="form-control" formControlName="confirmPassword"
            >
          <small class="form-text text-danger"
            [class.d-block]="changePasswordFormGroup.controls.confirmPassword.errors">{{changePasswordFormGroup.controls.confirmPassword.errorMessage}}</small>
          <small class="form-text text-danger" *ngIf="confirmPasswordError">New Password and Confirm Password doesn't
            match</small>

        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="ChangePassword()">Save</button>
      <button type="button" class="btn btn-outline-primary" (click)="hide()">Close</button>
    </div>
  </div>
</div>