import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductVariantMaterialMappingBase {

//#region ProductVariantMaterialMappingId Prop
        @prop()
        productVariantMaterialMappingId : number;
//#endregion ProductVariantMaterialMappingId Prop


//#region ProductVariantId Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion ProductVariantId Prop


//#region MaterialId Prop
        @prop()
        materialId : number;
//#endregion MaterialId Prop

}