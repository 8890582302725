import {vGemstoneStatusBase,} from '@app/database-models'
//Generated Imports
import { gridColumn, actionColumn } from "@rxweb/grid"
import { prop } from '@rxweb/reactive-form-validators';

@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 2, headerTitle: "Action"
})
export class vGemstoneStatus extends vGemstoneStatusBase 
{



    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 3, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 4, headerTitle: "Delete"
    })
    deleteAction:any;
//#region Generated Reference Properties

@prop()
isGemfield:string;
//#endregion Generated Reference Properties










}