import { CarnetsProductBase, CarnetBase, ProductPieceBase, ProductBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class CarnetsProduct extends CarnetsProductBase {


    carnetsProductList: CarnetsProduct[];

    @prop()
    productVariantBatchId: number;

    //#region Generated Reference Properties
    //#region carnet Prop
    carnet: CarnetBase;
    //#endregion carnet Prop
    //#region productPiece Prop
    productPiece: ProductPieceBase;
    //#endregion productPiece Prop
    //#region product Prop
    product: ProductBase;
    headerBtnChk: boolean;
    query: string;
    //#endregion product Prop

    //#endregion Generated Reference Properties











}