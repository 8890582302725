import { prop,propObject,propArray,required,maxLength,range, notEmpty  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MembersFeedBackBase {

//#region id Prop
        @prop()
        id : number;
//#endregion id Prop


//#region name Prop
        @prop({defaultValue: ""})
        @maxLength({value:30})
        name : string;
//#endregion name Prop


//#region comments Prop
        @required()
        @notEmpty()
        @maxLength({value:250})
        comments : string;
//#endregion comments Prop


//#region iPAddress Prop
        @required()
        @maxLength({value:50})
        iPAddress : string;
//#endregion iPAddress Prop


//#region isActive Prop
        @prop({defaultValue: 1})
        @required()
        isActive : number;
//#endregion isActive Prop


//#region isDeleted Prop
        @prop({defaultValue: 0})
        @required()
        isDeleted : number;
//#endregion isDeleted Prop

}