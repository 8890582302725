import { prop, propObject, propArray, required, maxLength, range, numeric, notEmpty, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TicketDetailBase {

        //#region ticketDetailId Prop
        @prop()
        ticketDetailId: number;
        //#endregion ticketDetailId Prop


        //#region statusId Prop
        @required()
        statusId: any;
        //#endregion statusId Prop


        //#region userId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required({ message: "Please select User" })
        userId: number;
        //#endregion userId Prop


        //#region lineManagerId Prop
        // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        // @required()
        @prop({ defaultValue: 0 })
        lineManagerId: number;
        //#endregion lineManagerId Prop


        //#region assignedToUserId Prop
        @prop()
        assignedToUserId: number;
        //#endregion assignedToUserId Prop


        //#region iNCTypeId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647, message: "Please select Ticket Type" })
        @required({ message: "Please select Ticket Type" })
        iNCTypeId: any;
        //#endregion iNCTypeId Prop


        //#region iNCCategoryId Prop
        @required({ message: "Please select Ticket Category" })
        @range({ minimumNumber: 1, maximumNumber: 2147483647, message: "Please select Ticket Category" })
        iNCCategoryId: any;
        //#endregion iNCCategoryId Prop

        //#region iNCCategoryId Prop
        @prop()
        iNCSubCategoryId: any;
        //#endregion iNCCategoryId Prop

        //#region severityId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647, message: "Please select Severity" })
        @required({ message: "Please select Severity" })
        severityId: any;
        //#endregion severityId Prop


        //#region contactNumber Prop
        // @maxLength({ value: 20 })
        @maxLength({ value: 30 })
        contactNumber: string;
        //#endregion contactNumber Prop


        //#region title Prop
        @required({ message: "Please enter Title" })
        @maxLength({ value: 100 })
        @notEmpty()
        @trim()
        title: string;
        //#endregion title Prop


        //#region description Prop
        @prop()
        @required({ message: "Please enter Description" })
        @notEmpty()
        @trim()
        description: string;
        //#endregion description Prop


        //#region satisfied Prop
        @prop()
        satisfied: boolean;
        //#endregion satisfied Prop


        //#region ratings Prop
        @prop()
        ratings: number;
        //#endregion ratings Prop


        //#region assignedDate Prop
        @prop()
        assignedDate: Date;
        //#endregion assignedDate Prop


        //#region resolvedDate Prop
        @prop()
        resolvedDate: Date;
        //#endregion resolvedDate Prop


        //#region createdBy Prop
        // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        // @required()
        @prop()
        createdBy: number;
        //#endregion createdBy Prop


        //#region createdDate Prop
        //@required()
        @prop()
        createdDate: Date;
        //#endregion createdDate Prop


        //#region updatedBy Prop
        @prop()
        updatedBy: number;
        //#endregion updatedBy Prop


        //#region updatedDate Prop
        @prop()
        updatedDate: Date;
        //#endregion updatedDate Prop


        //#region incidentNumber Prop
        @maxLength({ value: 1000 })
        incidentNumber: string;
        //#endregion incidentNumber Prop


        //#region sendToUser Prop
        @prop({ defaultValue: true })
        sendToUser: boolean;
        //#endregion sendToUser Prop


        //#region sendToTechUser Prop
        @prop({ defaultValue: true })
        sendToTechUser: boolean;
        //#endregion sendToTechUser Prop


        //#region assetTag Prop
        // @required()
        @maxLength({ value: 100 })
        assetTag: string;
        //#endregion assetTag Prop


        //#region isDeleted Prop
        @prop({ defaultValue: false })
        isDeleted: boolean;
        //#endregion isDeleted Prop


        //#region companyName Prop
        // @required()
        @maxLength({ value: 100 })
        companyName: string;
        //#endregion companyName Prop

        //#region closedDate Prop
        @prop()
        closedDate: Date;
        //#endregion closedDate Prop

        @prop({ defaultValue : 0 })
        companyId:number;
}
