import { vColorBase, } from '@app/database-models'
import { gridColumn, actionColumn } from '@rxweb/grid';
//Generated Imports
@actionColumn({
    visible:false,
    name:"action",
    allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 4, headerTitle: "Action"
})

export class vColor extends vColorBase {

    @gridColumn({
        visible: false,
        name: "editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 5, headerTitle: "Edit"
    })
    editAction: any;

    @gridColumn({
        visible: false,
        name: "deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 6, headerTitle: "Delete"
    })
    deleteAction: any;


    //#region Generated Reference Properties

    //#endregion Generated Reference Properties

}