import {GemstoneOriginBase,} from '@app/database-models'
//Generated Imports
export class GemstoneOrigin extends GemstoneOriginBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}