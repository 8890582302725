import { MindMapDataTypeEnum, MindMapNodeType } from 'src/app/enums/mind-map-data-type-enum';
import { RxHttp } from '@rxweb/http';
import { MindMapData } from './mind-map-data';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';

export class RootEventMindMap {


    mindMapData: any = null;

    rootEntityName: string = '';
    profileImageUrl:string = '';

    mindMap;
    mindMapDataObj: MindMapData;

    httpClient: any;

    constructor(private mindMapService: MindMapService) {
        this.httpClient = new RxHttp();
        this.mindMapDataObj = new MindMapData();
        Window['RootEventMindMap'] = this;
    }

    async setupEventMindMap(mindMapData, rootEntityName,imageUrl) {
        this.mindMapData = mindMapData;
        this.rootEntityName = rootEntityName;
        this.profileImageUrl = imageUrl;
        if (this.mindMapData != null) {
            await this.createMindMap();
        }
    }


    async createMindMap() {
        var projectData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.ProjectData, "project-templates.json", "Project", this.mindMapService.graphId, MindMapDataTypeEnum.PROJECT, RolePermissionEnums.Project);
        var legalAgreementData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.LegalAgreementData, "legal-agreement-templates.json", "LegalAgreement", this.mindMapService.graphId, MindMapDataTypeEnum.LEGALAGREEMENT, RolePermissionEnums.LegalAgreements);
        var pressMediaData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PressMediaData, "press-media-templates.json", "PressMedia", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIA, RolePermissionEnums.PressandMedia);
        var peopleCorporateInvolvedData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PeopleCorporateInvolvedData, "people-corporate-involved-templates.json", "PeopleCorporateInvolved", this.mindMapService.graphId, MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED, 0);
        var inviteesAttendeesData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.InviteesAttendeesData, "invitees-attendees-templates.json", "InviteesAttendees", this.mindMapService.graphId, MindMapDataTypeEnum.INVITEESATTENDEES, 0);
        var pictureVideoData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PictureVideoData, "picture-video-templates.json", "PICTUREVIDEO", this.mindMapService.graphId, MindMapDataTypeEnum.PICTUREVIDEO,0)

        var childrenArray = [];
        if (this.mindMapService.graphId == 1) {
            if (this.mindMapData.ProjectData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PROJECT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: projectData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: projectData,
                            direction: 'right',
                            selectedType: 'PROJECTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.LegalAgreementData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'LEGALAGREEMENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: legalAgreementData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: legalAgreementData,
                            direction: 'left',
                            selectedType: 'LEGALAGREEMENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PressMediaData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PRESSMEDIA',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pressMediaData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pressMediaData,
                            direction: 'right',
                            selectedType: 'PRESSMEDIALIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PeopleCorporateInvolvedData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}` + '\')\">People/Corporate Involved (' + this.mindMapData.PeopleCorporateInvolvedData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PEOPLECORPORATEINVOLVED',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: peopleCorporateInvolvedData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: peopleCorporateInvolvedData,
                            direction: 'left',
                            selectedType: 'PEOPLECORPORATEINVOLVEDLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.InviteesAttendeesData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.HEADER}` + '\')\">Invitees/Attendees (' + this.mindMapData.InviteesAttendeesData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'INVITEESATTENDEES',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: inviteesAttendeesData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: inviteesAttendeesData,
                            direction: 'left',
                            selectedType: 'INVETEESATTENDEESLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PICTUREVIDEO',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pictureVideoData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pictureVideoData,
                            direction: 'left',
                            selectedType: 'PICTUREVIDEOLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }


            this.mindMapService.mindMapObject = {
                format: 'nodeTree',
                data: {
                    id: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    isroot: true,
                    topic: '<h2 class="m-0" style="font-size:20px!important"><a class="d-flex align-items-center" target="_blank" href="/events/' + this.mindMapData.RootEntityId + '?activeTab=mind-map&tabChange=true"> '+this.profileImageUrl+' <b>' + this.rootEntityName + '</b></a></h2>',
                    selectedType: "",
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: childrenArray
                }
            };
        }
        else {
            this.mindMapService.tempSubgraphData.root = {
                parentNodeId: this.mindMapService.parentNodeToExpand,
                nodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                topic: '<h2 class="m-0" style=""><a target="_blank" href="/events/' + this.mindMapData.RootEntityId + '?activeTab=mind-map&tabChange=true"><b>' + this.rootEntityName + '</b></a></h2>',
                data: { selectedType: "ROOT" }
            };

            if (this.mindMapData.ProjectData.length > 0) {
                this.mindMapService.tempSubgraphData.project = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    data: { selectedType: "PROJECT" }
                };

                this.mindMapService.tempSubgraphData.projectList = (projectData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                    topic: projectData,
                    data: { selectedType: "PROJECTLIST" }
                };
            }

            if (this.mindMapData.LegalAgreementData.length > 0) {
                this.mindMapService.tempSubgraphData.LegalAgreement = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    data: { selectedType: "LEGALAGREEMENT" }
                };

                this.mindMapService.tempSubgraphData.LegalAgreementList = (legalAgreementData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                    topic: legalAgreementData,
                    data: { selectedType: "LEGALAGREEMENTLIST" }
                };
            }

            if (this.mindMapData.PressMediaData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMedia = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIA" }
                };

                this.mindMapService.tempSubgraphData.pressMediaList = (pressMediaData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                    topic: pressMediaData,
                    data: { selectedType: "PRESSMEDIALIST" }
                };
            }

            if (this.mindMapData.PeopleCorporateInvolvedData.length > 0) {
                this.mindMapService.tempSubgraphData.peopleCorporateInvolved = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}` + '\')\">People/Corporate Involved (' + this.mindMapData.PeopleCorporateInvolvedData.length + ')</span>',
                    data: { selectedType: "PEOPLECORPORATEINVOLVED" }
                };

                this.mindMapService.tempSubgraphData.peopleCorporateInvolvedList = (peopleCorporateInvolvedData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.LIST}`,
                    topic: peopleCorporateInvolvedData,
                    data: { selectedType: "PEOPLECORPORATEINVOLVEDLIST" }
                };
            }

            if (this.mindMapData.InviteesAttendeesData.length > 0) {
                this.mindMapService.tempSubgraphData.inviteesAttendees = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.HEADER}` + '\')\">Invitees/Attendees (' + this.mindMapData.InviteesAttendeesData.length + ')</span>',
                    data: { selectedType: "INVITEESATTENDEES" }
                };

                this.mindMapService.tempSubgraphData.inviteesAttendeesList = (inviteesAttendeesData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.INVITEESATTENDEES}_0_${MindMapNodeType.LIST}`,
                    topic: inviteesAttendeesData,
                    data: { selectedType: "INVITEESATTENDEESLIST" }
                };
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                this.mindMapService.tempSubgraphData.pictureVideo = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Event}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    data: { selectedType: "PICTUREVIDEO" }
                };

                this.mindMapService.tempSubgraphData.pictureVideoList = (pictureVideoData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                    topic: pictureVideoData,
                    data: { selectedType: "PICTUREVIDEOLIST" }
                };
            }

        }
        return;

    }
}
