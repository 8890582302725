import {vStateProvinceRecordBase,} from '@app/database-models'
//Generated Imports
export class vStateProvinceRecord extends vStateProvinceRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}