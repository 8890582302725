import { gridColumn } from '@rxweb/grid';
import { FileDetailViewModel } from './file-detail-view-model';

export class ProductPieceViewModel{
    pieceId:number;
    @gridColumn({parameter:false,style: { width: "3%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ProductPieceDetailComponent_BatchNo_gh', keyColumn: false })
    batchNo:number;

    pictureId:number;
    productName:string;
    @gridColumn({ template:{
        div:{
          attributes:{
            innerHTML:function(x,y){
             return this.serial;
            }
          },
        }
    },parameter:false,style: { width: "5%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ProductPieceDetailComponent_Serial_gh', keyColumn: false })
    serial:string;

    pieceNumber:string;

    @gridColumn({
      parameter: false, configuredTemplate: { templateName: 'barcodeImageView' }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "barcodeUrl", visible: true, columnIndex: 2, allowSorting: false, headerKey: 'ProductPieceDetailComponent_Barcode_gh', keyColumn: false
    })
    barcodeUrl: any;

    @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ProductPieceDetailComponent_GTINNumber_gh', keyColumn: false })
    gtinNumber: number;

    @gridColumn({
      template: {
        div: {
          attributes: {
            innerHTML: function (x, y) {
              return this.orderID;
            }
          },
        }
      },
      parameter:false,style: { width: "5%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 4, allowSorting: true, headerKey: 'ProductPieceDetailComponent_OrderId_gh', keyColumn: false })
    orderID:string;

    @gridColumn({parameter:false,style: { width: "5%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 5, allowSorting: true, headerKey: 'ProductPieceDetailComponent_OldInventoryNumber_gh', keyColumn: false })
    oldInventoryNumber:string;

    @gridColumn({parameter:false,style: { width: "4%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 6, allowSorting: true, headerKey: 'ProductPieceDetailComponent_CarnetNo_gh', keyColumn: false })
    carnetNo:number;

    productStatus:string;

    @gridColumn({parameter:false,style: { width: "9%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 10, allowSorting: true, headerKey: 'ProductPieceDetailComponent_BomCost_gh', keyColumn: false })
    bomCost:number;

    @gridColumn({parameter:true,style: { width: "5%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 9, allowSorting: false, headerKey: 'ProductPieceDetailComponent_RingSize_gh',name:'ringSize', keyColumn: false })
    ringSize:string;
    @gridColumn({parameter:true,style: { width: "5%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 9, allowSorting: false, headerKey: 'ProductPieceDetailComponent_BangleSize_gh', name:'bangleSize',keyColumn: false })
    bangleSize:string;

    @gridColumn({parameter:false,style: { width: "5%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 13, allowSorting: true, headerKey: 'ProductPieceDetailComponent_Supplier_gh', keyColumn: false })
    supplier:string;

    @gridColumn({parameter:false,style: { width: "7%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 12, allowSorting: true, headerKey: 'ProductPieceDetailComponent_Location_gh', keyColumn: false })
    location:string;

    @gridColumn({parameter:false,style: { width: "4%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 11, allowSorting: true, headerKey: 'ProductPieceDetailComponent_ExportPrice_gh', keyColumn: false })
    exportPrice:string;

    supplierDeliveryDate:Date;

    @gridColumn({parameter:false,style: { width: "4%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 15, allowSorting: true, headerKey: 'ProductPieceDetailComponent_SupplierDate_gh', keyColumn: false })
    supplierDate:string;

    @gridColumn({parameter:false,style: { width: "4%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 16, allowSorting: true, headerKey: 'ProductPieceDetailComponent_OrderDate_gh', keyColumn: false })
    orderDate:string;

    @gridColumn({parameter:false,style: { width: "7%", "min-width":"95px", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 17, allowSorting: true, headerKey: 'ProductPieceDetailComponent_DaysInStock_gh', keyColumn: false })
    daysInStock:string;

    @gridColumn({
        template:{
          div:{
            attributes:{
              innerHTML:function(x,y){
               return this.viewButton;
              }
            },
          }
      },
    parameter:true,style: { width: "3%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 19, allowSorting: false,/* headerTitle: 'View'*/headerKey: 'ProductPieceDetailComponent_View_gh', name:'ViewButton',keyColumn: false })
    viewButton:string;

    @gridColumn({
        template:{
            div: {
                childrens: [{
                  a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                      click: "onStockPrice"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return "With Price" }
                          }
                    }]
                  }
                }]
              }
        },
        parameter:true,style: { width: "3%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 20, allowSorting: false, /*headerTitle: 'Print Stock Card'*/headerKey: 'ProductPieceDetailComponent_StockCard_gh', keyColumn: false })
    withPrice:string;

    @gridColumn({
        template:{
            div: {
                childrens: [{
                  a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                      click: "onStockWithoutPrice"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return "Without Price" }
                          }
                    }]
                  }
                }]
              }
        },
        parameter:true,style: { width: "3%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 21, allowSorting: false, /*headerTitle: 'Print Stock Card'*/headerKey: 'ProductPieceDetailComponent_StockCard_gh', keyColumn: false })
    withoutPrice:string;

    //#region productionStatus Prop
    @gridColumn({ parameter: false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ProductPieceDetailComponent_ProductionStatus_gh', keyColumn: false})
    productionStatus: string;
    //#endregion productionStatus Prop

    //#region salesStatus Prop
    @gridColumn({ parameter: false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ProductPieceDetailComponent_SalesStatus_gh', keyColumn: false})
    salesStatus: string;
    //#endregion salesStatus Prop

    //#region consignmentStatus Prop
    @gridColumn({ parameter: false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ProductPieceDetailComponent_ConsignmentStatus_gh', keyColumn: false})
    consignmentStatus: string;
    //#endregion consignmentStatus Prop

    @gridColumn({
      template: {
        div: {
          attributes: {
            innerHTML: function (x, y) {
              return this.otherStatus;
            }
          },
        }
      }, parameter: false,style: { width: "10%", "text-align": "initial"}, class: ["text-left","w-150"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'ProductPieceDetailComponent_OtherStatus_gh', keyColumn: false
    })
    otherStatus: string;

    //#region consignmentStatus Prop
    @gridColumn({ parameter: false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"], visible: true, columnIndex: 18, allowSorting: true, headerKey: 'ProductPieceDetailComponent_CurrOwner_gh', keyColumn: false})
    legalOwner: string;
    //#endregion consignmentStatus Prop

    usdRate:number;
    gbpRate:number;
    eurRate:number;
    hkdRate:number;
    chfRate:number;
     cost:number;
    imageUrl:string;
    categoryId:number;
    description:string;
    adminComment:string;
    totalCount:number;
    avgDayInStock:number;
    avgOngoingDayInStock:number;
    exportRetailPrice:number;
    barcodeFile: FileDetailViewModel;
}
