<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="Label_Global_Settings_t"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="Label_Global_Settings_t"></label></h5>
        <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x" ></i>
        </a>
      </div>


      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [rxSpinner]="spin">
        <div class="row">
          <div class="col-lg-6 col-xl-4 ">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" rxText="AllSettingAddComponent_SelectSetting_t"></label>
              <!-- <select class="form-control" (change)="bindSetting($event)">
                  <option [selected]="true">Select Setting</option>
                  <option *ngFor="let item of settingList" [value]="item.key">{{item.value}}</option>
                </select> -->
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="settingList" #rxSelect mainClass="form-control" (changed)="bindSetting($event)"
                  [keyValueProps]="['value','key']" [selectPlaceholder]="'Select Setting'"></rx-select>
              </div>
            </div>
          </div>
        </div>
        <label class="my-4 alert alert-warning" *ngIf="settingKeyObj.description"
          [innerHTML]="settingKeyObj.description"></label>

        <div class="row add-countries-form" *ngIf="isShowSetting && allSettingFormGroup"
          [formGroup]="allSettingFormGroup">

          <div class="col-lg-6 col-xl-4 " [formGroup]="allSettingViewModel"
            *ngFor="let allSettingViewModel of allSettingViewModelFormArray; let i = index" [rxLocalisationInit]="componentName">
            <div class="form-group row check-group" *ngIf="allSettingViewModel.value.type ==  inputTypeEnum.CheckBox" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-form-label" [componentId]="componentName" rxText="{{allSettingViewModel.value.localLizationKey}}"
                [text]="allSettingViewModel.value.localLizationKey+'_t'"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="settingValue" [checked]="allSettingViewModel.value.settingValue == 'true'"><i></i> -->
                    <!-- {{allSettingViewModel.controls.settingValue.value}} -->
                    <app-checkbox [checkFormControl]="allSettingViewModel.controls.settingValue"></app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="allSettingViewModel.controls.settingValue.errors">{{allSettingViewModel.controls.settingValue.errorMessage}}<br /></small>
              </div>
            </div>


            <div class="form-group row" *ngIf="allSettingViewModel.value.type ==  inputTypeEnum.TextBox" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-form-label" [componentId]="componentName" rxText="{{allSettingViewModel.value.localLizationKey}}"
                [text]="allSettingViewModel.value.localLizationKey+'_t'"></label>
              <div class="col-md-8">
                <input formControlName="settingValue" type="text" class="form-control" />
                <small class="form-text text-danger"
                  [class.d-block]="allSettingViewModel.controls.settingValue.errors">{{allSettingViewModel.controls.settingValue.errorMessage}}<br /></small>
              </div>
            </div>
            <div class="form-group row" *ngIf="allSettingViewModel.value.type ==  inputTypeEnum.Select" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-form-label" [componentId]="componentName" rxText="{{allSettingViewModel.value.localLizationKey}}"
                [text]="allSettingViewModel.value.localLizationKey+'_t'"></label>
              <div class="col-md-8">
                <select class="form-control" formControlName="settingValue"
                  *ngIf="allSettingViewModel.value.dataKey == 'countryLookUps'"
                  (change)="stateBind($event.target.value)">
                  <option value="0">Select</option>
                  <option *ngFor="let item of getData(allSettingViewModel.value.settingKeyName)" [value]="item.key">
                    {{item.value}}
                  </option>
                </select>
                <select class="form-control" formControlName="settingValue"
                  *ngIf="allSettingViewModel.value.dataKey == 'stateProvinceLookUps'">
                  <option *ngFor="let item of getData(allSettingViewModel.value.settingKeyName)" [value]="item.key">
                    {{item.value}}
                  </option>
                </select>
                <select class="form-control" formControlName="settingValue"
                  *ngIf="(allSettingViewModel.value.dataKey != 'countryLookUps' && allSettingViewModel.value.dataKey != 'stateProvinceLookUps')">
                  <option *ngFor="let item of getData(allSettingViewModel.value.settingKeyName)" [value]="item.key">
                    {{item.value}}
                  </option>
                </select>
                <!-- <rx-select [(source)]="data" mainClass="form-control" formControlName="settingValue"
                [keyValueProps]="['value','key']"></rx-select>  -->
                <small class="form-text text-danger"
                  [class.d-block]="allSettingViewModel.controls.settingValue.errors">{{allSettingViewModel.controls.settingValue.errorMessage}}<br /></small>
              </div>
            </div>
            <div *ngIf="allSettingViewModel.value.type ==  inputTypeEnum.File" [rxLocalisationInit]="componentName">
              <div class="form-group row" [formGroup]="file"
                *ngFor="let file of fileFormArray(allSettingViewModel,i); let y = index" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-form-label" [componentId]="componentName" rxText="{{allSettingViewModel.value.localLizationKey}}"
                  [text]="allSettingViewModel.value.localLizationKey+'_t'"></label>
                <div class="col-md-8 ">
                  <a href="{{file.value.settingValue}}" target="_blank">View File</a>
                  <app-file [fileViewModel]="fileType(allSettingViewModel.value.settingKeyName)"
                    (files)="updateFiles($event,file,allSettingViewModel.value.settingKeyName)"></app-file>
                  <label class="mt-1">{{file.value.fileName}}</label>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row add-countries-form" *ngIf="isShowSetting && allSettingFormGroup && selectedId == 5">
          <button class="btn btn-primary mx-3" (click)="generateSiteMap()">Generate Sitemap</button>
        </div> -->

        <div class="row mt-auto" *ngIf="isShowSetting" [rxLocalisationInit]="componentName">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button class="btn btn-success mr-1" (click)="updateSetting()"
              [disabled]="this.allSettingFormGroup.submitted" rxText="Btn_Save_t"></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
