import { maxLength, prop } from "@rxweb/reactive-form-validators";

export class UserPassportDetailBase
{

    @prop()
    userPassportDetailId :number; 
    
    @prop()
    userId :number; 

    @maxLength({ value: 100 })
    passportNumber :number; 

    @maxLength({ value: 100 })
    visaNumber :number; 

    @prop()
    passportIssueCountry :number; 

    @prop()
    createdOn :Date; 

    @prop()
    createdBy :number; 

    @prop()
    updatedOn :Date; 

    @prop()
    updatedBy :number;

}