import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductBatchPieceMappingLocalizationBase {

//#region vLProductVariantBatch_PieceLocalizedId Prop
        @prop()
        vLProductVariantBatch_PieceLocalizedId : number;
//#endregion vLProductVariantBatch_PieceLocalizedId Prop


//#region productVariantBatchPieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region specification Prop
        @required()
        specification : string;
//#endregion specification Prop


//#region pieceNumber Prop
        @required()
        @maxLength({value:150})
        pieceNumber : string;
//#endregion pieceNumber Prop


//#region enamelReference Prop
        @required()
        @maxLength({value:4000})
        enamelReference : string;
//#endregion enamelReference Prop


//#region imageAlt Prop
        @required()
        @maxLength({value:400})
        imageAlt : string;
//#endregion imageAlt Prop

}