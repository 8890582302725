import { prop } from "@rxweb/reactive-form-validators";

export class vProductionOrderFormDepartmentLookUpBase {
    //#region DepartmentId Prop
    @prop()
    DepartmentId:number;
    //#endregion DepartmentId Prop

    //#region DepartmentName Prop
    @prop()
    DepartmentName:string;
    //#region DepartmentName Prop
}