import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPictureBase {

//#region productVariantPictureID Prop
        @prop()
        productVariantPictureID : number;
//#endregion productVariantPictureID Prop


//#region productVariantID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID : number;
//#endregion productVariantID Prop


//#region pictureID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID : number;
//#endregion pictureID Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @notEmpty()
        @numeric()
        displayOrder : number;
//#endregion displayOrder Prop


//#region pictureID1 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID1 : number;
//#endregion pictureID1 Prop





}