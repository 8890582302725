import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ProductPiece } from '@app/models';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileComponent } from 'src/app/components/shared/global/file/file.component';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FileTypeEnums, FileSizeEnums, FilePlaceHolders } from 'src/app/custom-enum/file-type-enums';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE, getBlobImgURL } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@http({
  path: 'api/ProductPiece'
})

export class AbstractProductPiece extends RxHttp {
  productPieceFormGroup: IFormGroup<ProductPiece>;
  totalRecords: number;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  designStatusLookup: any;
  startDate: Date;
  endDate: Date;
  collectionLookup: any;
  productBatchEggSizeLookUp: any;
  pieceOwnerLookUp: any;
  warehouseLookUp: any;
  ringSizeLookup: any;
  bangleSizeLookup: any;
  dutyStatusLookUp: any;
  supplierLookUps: any;
  productMaterialLookUps: any;
  productColorLookUps: any;
  productGemstonetypeLookUps: any;
  categoryLookup: any;
  availabilityStatusLookUp: any;
  productQuestions: any;
  spin: boolean = false;
  modalView: ModalView;
  showComponent: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  id: number;
  isFilter: boolean;
  sanitizer: DomSanitizer;
  fileInputViewModel1: FileInputViewModel;
  fileViewModel: FileInputViewModel;
  fileInputViewModel2: FileInputViewModel;
  fileInputViewModel3: FileInputViewModel;
  fileComponent: FileComponent;
  fileURL1: any;
  fileURL2: any;
  isSearch: boolean;
  expiryDateError: boolean;
  tripDateError: boolean;
  deliveryDateError: boolean;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  fileOutputViewModel1: FileOutputViewModel = new FileOutputViewModel();
  fileOutputViewModel2: FileOutputViewModel = new FileOutputViewModel();
  dateError: boolean = false;
  bookingError: boolean;
  customerName: string;
  Address: string;
  phoneNumber: string;
  customerEmail: string;
  selectedTab: string = "piece-info"
  imgError: boolean = false;
  imgSizeError: boolean = false;
  fullName: string;
  streetAddress: string;
  phone: string;
  workPhone: string;
  mobilePhone: string;
  email: string;
  validField: string[] = ["description", "gramWeight", "goldGramWeight"];
  breakLoop: boolean;
  showSupplierDeliveryDate: boolean=false;
  jsonParam:any;
  constructor(domSanitizer: DomSanitizer,private blobState: BlobUploadsViewStateService) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.sanitizer = domSanitizer;

  }


  removeImage() {
    this.productPieceFormGroup.patchValue({
      fileData: null,
      blobFileName:null,
      fileExtention: null,
      fileName: null,
      displayImage: false,
      isImageRemoved: true,
      imageURL3: null,
      imageURL2: null,
      imageURL1: null,
      imageURL4: null,
      imageURL5: null,
      pictureID: 0,
      pictureID1: 0,
      stockcardPictureID: 0,
      pictureID2: 0,
      pictureID3: 0
    });
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.Image;
    this.fileViewModel.filePlaceHolder = FilePlaceHolders.Image;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
  }
  uploadImageFiles($event: FileOutputViewModel) {
    this.fileOutputViewModel = $event;
    var file = this.fileOutputViewModel.files;
    var _size = file.size;
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0; while (_size > 900) { _size /= 1024; i++; }
    var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
    var img = new Image();
    img.onload = async () => {
      if (img.width < 800 || img.height < 799) {
        this.imgError = true;
        this.imgSizeError = false;
      }
      else if (((exactSize.toString().indexOf("Bytes") != -1) || (exactSize.toString().indexOf("KB") != -1)) && parseFloat(exactSize) < FileSizeEnums.MinimumSizeKB) {
        this.imgError = false;
        this.imgSizeError = true;
        //this.toastr.error($event.fileName + "size should be minimum 35 kb.");
      }
      else {
           
        this.imgError = false;
        this.imgSizeError = false;
        this.productPieceFormGroup.patchValue({
          fileData: $event.fileBaseString,
          fileExtention: $event.fileExtension,
          fileName: $event.fileName,
          displayImage: $event.displayImage,
          //blobFileName:uploadedFileName,
          blobPath:BLOB_BASE.ProductPieceImages,
          size:$event.files.size
        });
      }
    };
    img.src = "data:" + $event.fileExtension + ";base64," + $event.fileBaseString;

  }

  async uploadStoneCertificate($event: FileOutputViewModel) {
    this.fileOutputViewModel1=$event;
    this.productPieceFormGroup.patchValue({
      fileData1: $event.fileBaseString,
      fileExtention1: $event.fileExtension,
      fileName1: $event.fileName,
      displayImage: $event.displayImage,
      //blobFileName2:uploadedFileName,
      blobPath2:BLOB_BASE.PieceDocumentFiles,
      size2:$event.files.size
    });
  }

 async uploadInvoice($event: FileOutputViewModel) {
  this.fileOutputViewModel2=$event;
    this.productPieceFormGroup.patchValue({
      fileData2: $event.fileBaseString,
      fileExtention2: $event.fileExtension,
      fileName2: $event.fileName,
      displayImage: $event.displayImage,
      //blobFileName3:uploadedFileName,
      blobPath3:BLOB_BASE.PieceDocumentFiles,
      size3:$event.files.size
    });
  }


  remmoveInvoice() {
    this.productPieceFormGroup.patchValue({
      fileData2: null,
      blobFileName3:null,
      fileExtention2: null,
      fileName2: null,
      invoice: null,
      displayImage: null,
      fileURL2: null
    });

  }


  remmoveStoneCertificate() {
    this.productPieceFormGroup.patchValue({
      fileData1: null,
      blobFileName2:null,
      fileExtention1: null,
      fileName1: null,
      supplierStoneCertificate: null,
      displayImage: null,
      fileURL1: null
    });

  }

  async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);

    let url = res._response.request.url;
    let uploadedFileName = blobPath + decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );

    // IF SUCCEED TO UPLOAD
    if (res && (res._response.status == 200 || res._response.status == 201)) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined) {
        this.modalView
          .show(BedRequestComponent, {
            validationMessages: JSON.stringify(
              uploadedFileName
            ),
            headerMessage: "Image failed to upload:",
          })
          .then(() => { });
      }
    }
    return uploadedFileName;
  }

  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
    })
  }

  getImgContent(): SafeUrl {
    let imgUrl = "data:" + this.productPieceFormGroup.controls.fileExtention.value + ";base64," + this.productPieceFormGroup.controls.fileData.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
  }
}
