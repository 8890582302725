import {GemStoneShippingDocGemFieldBase,TemporaryAssigneeBase,} from '@app/database-models'
//Generated Imports
export class GemStoneShippingDocGemField extends GemStoneShippingDocGemFieldBase 
{




//#region Generated Reference Properties
//#region temporaryAssignee Prop
temporaryAssignee : TemporaryAssigneeBase;
//#endregion temporaryAssignee Prop

//#endregion Generated Reference Properties






































}