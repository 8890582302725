import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
// import { EventListComponent } from './list/event-list.component';
import { SharedModule } from '../../shared/shared.module';
import { SystemTimeSharedModule } from '../../shared/system-time/system-time.shared-module';
import { EventDetailComponent } from './detail/event-detail.component';
import { SystemTableSettingModule } from '../../shared/system-table-settings/system-table-setting.module';
import { FileModule } from '../../shared/global/file/file.module';
import { SummaryDetailsComponent } from './summary-details/summary-details.component';
import { EventCustomerMappingSharedModule } from '../event-customer-mappings/event-customer-mapping-shared.module';
import { ProjectEventMappingSharedModule } from '../project-event-mapping/project-event-mapping-shared.module';
import { ProjectCorporateMappingSharedModule } from '../../project/project-corporate-mappings/project-corporate-mapping-shared.module';
import { PressMediaDetailSharedModule } from '../../press-media/press-media-detail/press-media-detail-shared.module';
import { LegalAgreementEventMappingsSharedModule } from '../../form/legal-agreement/details/legal-agreement-event-mapping/legal-agreement-event-mapping-shared.module';
import { EventSendEInvitationSharedModule } from '../event-send-einvitation/event-send-einvitation-shared.module';
import { ProjectEventMappingAddComponent } from '../project-event-mapping/add/project-event-mapping-add.component';
import { ProjectCorporateMappingEditComponent } from '../../project/project-corporate-mappings/edit/project-corporate-mapping-edit.component';
import { ProjectCorporateMappingAddComponent } from '../../project/project-corporate-mappings/add/project-corporate-mapping-add.component';



@NgModule({
    imports: [FileModule,
        CommonModule, FormsModule, SystemTimeSharedModule, SharedModule, ReactiveFormsModule, RxReactiveFormsModule, SystemTableSettingModule
    ],
    declarations: [EventDetailComponent, SummaryDetailsComponent, ProjectEventMappingAddComponent, ProjectCorporateMappingEditComponent, ProjectCorporateMappingAddComponent],
    exports: [EventDetailComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventSharedModule { }

