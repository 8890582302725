import { OwnershipMappingBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class OwnershipMapping extends OwnershipMappingBase {




    //#region Generated Reference Properties

    //#endregion Generated Reference Properties



    //#region peopleOrCorporate prop
    @prop()
    peopleOrCorporate: number;
    //#endregion peopleOrCorporate prop

    //#region corporateName prop
    @prop()
    corporateName: string;
    //#endregion corporateName prop

    //#region personName prop
    @prop()
    personName: string;
    //#endregion personName prop







}