import {CustomerHolidayBase,} from '@app/database-models'
//Generated Imports
export class CustomerHoliday extends CustomerHolidayBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}