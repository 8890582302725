import {ConditionOfProductBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ConditionOfProduct extends ConditionOfProductBase 
{


// @prop()
// isActive:boolean;

//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}