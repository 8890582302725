import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductStatusForReportLookUpBase {

//#region warrantyCardId Prop
        productStatusId : number;
//#endregion weekDayId Prop


//#region warrantyCardName Prop
        productStatusName : string;
//#endregion warrantyCardName Prop

}