import {vEggSizeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vEggSizeLookUp extends vEggSizeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}