import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractActivityLog } from '../domain/abstract-activity-log';
import { ActivityLog, vActivityLog, vActivityLogTypeLookUp } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { RxFormBuilder, IFormGroup, json } from '@rxweb/reactive-form-validators';
import { ActivityLogFilterViewModel } from 'src/app/view-model/activity-log-filter-view-model';

import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { Router } from '@angular/router';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { multilingual } from '@rxweb/localization';
import { debug } from 'util';
import { debounceTime } from 'rxjs/operators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";

@access({ accessLevel: RolePermissionEnums.ActivityLog, action: "get" })
@multilingual("ActivityLogListComponent")
@Component({
  selector: "app-activity-log-list",
  templateUrl: './activity-log-list.component.html'
})


export class ActivityLogListComponent extends AbstractActivityLog implements OnInit, OnDestroy {
  activityLogs: vActivityLog[];
  activityLogSearchFormGroup: IFormGroup<ActivityLogFilterViewModel>;
  activityLogInfo: vActivityLog;
  activityLog: ActivityLogFilterViewModel;
  subscription: any;
  activityLogsGrid: AppGrid;
  activityLogsGridColumns: any;
  showSearchBar: boolean = false;
  rowCount: number = 25;
  pageIndex: number = 1;
  activityLogIds: number[] = [];
  isMultiDelete: boolean = false;
  gridSearchString: string = "";
  sortOrder: string = "true";
  orderByColumn: string = "name";
  isFirstTime: boolean = true;
  searchString: string;
  totalRecords: number = 0;
  isFilter: boolean = false;
  activityLogDelete: any = dbAccessModule[RolePermissionEnums.ActivityLog]["delete"];
  private activityLogSubject: Subject<string> = new Subject<string>();
  moduleID: number;
  titleHelp: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private router: Router, private title: Title, private location : PlatformLocation) {
    super();
    this.modalView = modalView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.moduleID = RolePermissionEnums.ActivityLog;
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Activity Log - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.spin = true;
    this.activityLogSubject.pipe(debounceTime(300)).subscribe(t => {
      this.searchActivityLog();
    })
    this.lookup([
      { propName: "activitylogtypelookUp", path: USER_LOOKUPS.activitylogtypelookUp },
    ]).subscribe((lookup: any) => {
      this.activityLog = new ActivityLogFilterViewModel();
      this.activityLog.pageIndex = PaginationEnum.PageIndex;
      this.activityLog.rowCount = PaginationEnum.RowCount;
      this.activityLog.activityLogTypeId = 0;
      this.activityLogSearchFormGroup = this.formBuilder.formGroup(this.activityLog) as IFormGroup<ActivityLogFilterViewModel>;
      let activityType = new vActivityLogTypeLookUp();
      activityType.activityLogTypeId = 0;
      activityType.activityName = "All";
      this.activityLogTypeLookups.activityLogTypeLookUp = new Array<vActivityLogTypeLookUp>();
      this.activityLogTypeLookups.activityLogTypeLookUp = lookup.activitylogtypelookUp;

      this.activityLogTypeLookups.activityLogTypeLookUp.unshift(activityType);
      this.spin = false;
      this.showComponent = true;
    });
  }
  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
}

  onKeyPress() {
    this.activityLogSubject.next();
  }

  searchActivityLog() {
    if (this.activityLogsGrid) {
      this.isFilter = true;
    }
    this.bindGrid();
  }

  bindGrid(page: number = 0) {
    this.activityLogSearchFormGroup.submitted = true;
    if (this.activityLogSearchFormGroup.valid && this.activityLogSearchFormGroup.submitted) {
      var json = JSON.parse(JSON.stringify(this.activityLogSearchFormGroup.value));
      json["searchString"] = this.gridSearchString;
      this.isShowGrid = true;
      this.spin = true;
      this.subscription = this.get({
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(json))
        }
      }).subscribe((t: any) => {
        this.spin = false;
        this.isFirstTime = false;
        this.showSearchBar = true;


        if (this.activityLogsGrid) {
          this.activityLogsGrid.storeProcedure.length = 0;
        }
        this.activityLogs = t;
        if (this.activityLogs.length > 0) {
          this.totalRecords = this.activityLogs[0].totalCount;
          this.isNoRecordFound = false;
        } else {
          this.isNoRecordFound = true;
          this.isShowGrid = false;
        }
        if (!this.isFilter) {
          this.activityLogsGrid = new AppGrid(this.activityLogs, vActivityLog, { actions: { onDelete: this.onDelete.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this), onOneLink: this.onEdit.bind(this) } });
          this.activityLogsGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.activityLogsGrid.gridColumns.forEach(x => {
            if (x.name == "activityLogID" || x.name == "action") {
              x.visible = this.activityLogsGrid.authorize({ accessLevel: RolePermissionEnums.ActivityLog, action: "delete" });
            }
          })
        }
        else {
          if (this.activityLogsGrid != undefined) {
            this.activityLogsGrid.storeProcedure.length = this.totalRecords;
            this.activityLogsGrid.updateSource(this.activityLogs);
          }
        }
        this.activityLogsGrid.maxPerPage = this.activityLogSearchFormGroup.value.rowCount;
        this.activityLogsGrid.componentId = this.componentName;
        this.activityLogsGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.activityLogsGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.isFirstTime = false;
      })
    }
  }

  clearGrid() {
    if (this.activityLogsGrid) {
      this.activityLogsGrid.refresh("activity-logs")
    }
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.activityLogSearchFormGroup.patchValue({ sortOrder: y, orderBy: x })
    this.activityLogsGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.activityLogSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.activityLogsGrid.maxPerPage })
    this.activityLogsGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }

  onEdit(activityLog: vActivityLog) {
    this.router.navigate(["users", activityLog.userId], { queryParams: { activeTab: 'user-edit' } });
  }

  onCheckboxSelect(t, x, y, z) {
    if (x.target.checked) {
      if (!this.activityLogIds.includes(t.activityLogID)) {
        this.activityLogIds.push(t.activityLogID);
      }
    }
    else {
      if (this.activityLogIds.includes(t.activityLogID)) {
        this.activityLogIds.splice(this.activityLogIds.indexOf(t.activityLogID), 1);
      }
    }
  }

  clearAll() {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.activityLogInfo = new vActivityLog();
        this.activityLogInfo.activityLogIds = this.activityLogIds;
        this.spin = true;
        this.delete({ body: null, params: [0], queryParams: { "query": JSON.stringify(this.activityLogInfo.activityLogIds) } }).subscribe(data => {
          this.spin = false;
          this.isFilter = true;
          this.bindGrid();
          var existsAlert = getLocalizedValue("ActivityLogs_Clear");
          if (existsAlert) {
            //this.toastr.success("Activity Logs Clear Successfully");
            this.toastr.success(existsAlert);
          }
          this.activityLogIds = [];
        })
      }
      this.dialog.hide()
    })
  }


  search(value) {
    this.isFilter = true;
    this.gridSearchString = value.target.value;
    this.activityLogSearchFormGroup.patchValue({ pageIndex: 1 })
    this.bindGrid();
  }

  onDelete(activityLogs: vActivityLog) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [0], queryParams: { "query": JSON.stringify([activityLogs.activityLogID]), } }).subscribe(data => {
          this.spin = false;
          this.isFilter = true;
          this.bindGrid();
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
          this.activityLogIds.length = 0;
        })
      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Activity_Log");
    if (existsAlert) {
      return existsAlert;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ActivityLogListComponent";
  }
}
