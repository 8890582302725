import { DocumentBase } from "@app/database-models";
//Generated Imports
import { propArray, prop } from "@rxweb/reactive-form-validators";
import { DocumentListViewModel } from "./document-list-view-model";
import { PictureViewModel } from "src/app/view-model/picture-view-model";
export class Document extends DocumentBase {
  @propArray(DocumentListViewModel)
  documents: DocumentListViewModel[];

  //#region Generated Reference Properties

  //#endregion Generated Reference Properties

  @propArray(PictureViewModel)
  pictures: PictureViewModel[];

  @prop()
  file: File;

  @prop()
  displayPriority: number;

  @prop()
  physicalFileName: any;

  @prop({ defaultValue: false })
  isNoteComponent: boolean;

  @prop()
  fileUrl: any;

  @prop()
  destinationId: number;

  @prop()
  sourceId: number;

  @prop()
  displayOrder: number;

  @prop()
  isDrag: boolean;

  @prop()
  size: number;

  @propArray<string>()
  fileNamesCollection: { fileName: string; blobFileName: string; size:number; }[];
  
  @propArray<string>()
  documentTypesCollection: { documentId: number; documentTypeId: number }[];

  @prop()
  storedDocumentTypeId: number;
}
