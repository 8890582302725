import { maxLength, prop, range, required } from '@rxweb/reactive-form-validators';

export class GoalDetailViewModel {
    //#region goalId Prop
    @prop()
    goalId: number;
    //#endregion goalId Prop

    //#region goalDescription Prop
    @maxLength({ value: 4000 })
    @required()
    goalDescription: string
    //#endregion goalDescription Prop

    //#region completionDate Prop
    @prop()
    @required()
    completionDate: Date
    //#endregion completionDate Prop

    //#region startDate Prop
    @prop()
    startDate: Date
    //#endregion startDate Prop

    //#region createdBy Prop
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdDate Prop
    @required()
    createdDate: Date;
    //#endregion createdDate Prop

    //#region isSubmitted Prop
    @prop({ defaultValue: false })
    isSubmitted: boolean;
    //#endregion isSubmitted Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region modifiedDate Prop
    @prop()
    modifiedDate: Date;
    //#endregion modifiedDate Prop

    //#region goalRejectionNote Prop
    @maxLength({ value: 4000 })
    @prop()
    goalRejectionNote: string;
    //#endregion goalRejectionNote Prop

    //#region isDeleted Prop
    @prop({ defaultValue: false })
    isDeleted: boolean;
    //#endregion isDeleted Prop

    //#region goalStatusId Prop
    @required()
    goalStatusId: number;
    //#endregion goalStatusId Prop

    //#region userRatingId Prop
    @prop()
    userRatingId: number;
    //#endregion userRatingId Prop

    //#region lineMangerRatingId Prop
    @prop()
    lineManagerRatingId: number;
    //#endregion lineMangerRatingId Prop

    //#region feedBack Prop
    @prop()
    feedBack: string
    //#endregion feedBack Prop

    //#region goalStatus Prop
    @prop()
    goalStatus: string;
    //#endregion goalStatus Prop

    //#region isApproveMailSent Prop
    @prop({ defaultValue: false })
    isApproveMailSent: boolean;
    //#endregion isApproveMailSent Prop

    //#region isGoalAddUpdateMailSent Prop
    @prop({ defaultValue: false })
    isGoalAddUpdateMailSent: boolean;
    //#endregion isGoalAddUpdateMailSent Prop

    //#region isUpdated Prop
    @prop({ defaultValue: false })
    isUpdated: boolean;
    //#endregion isUpdated Prop

    //#region isRatingChanged Prop
    @prop({ defaultValue: false })
    isRatingChanged: boolean;
    //#endregion isRatingChanged Prop

    //#region isAdded Prop
    @prop({ defaultValue: false })
    isAdded: boolean;
    //#endregion isAdded Prop

    //#region isRatingVisible Prop
    @prop({ defaultValue: false })
    isRatingVisible: boolean;
    //#endregion isRatingVisible Prop

    //#region isEnableRating Prop
    @prop({ defaultValue: false })
    isEnableRating: boolean;
    //#endregion isEnableRating Prop

    //#region isLMRatingVisible Prop
    @prop({ defaultValue: false })
    isLMRatingVisible: boolean;
    //#endregion isLMRatingVisible Prop

    //#region isLMEnableRating Prop
    @prop({ defaultValue: false })
    isLMEnableRating: boolean;
    //#endregion isLMEnableRating Prop

    //#region isEnableGoalDescription Prop
    @prop({ defaultValue: false })
    isEnableGoalDescription: boolean;
    //#endregion isEnableGoalDescription Prop

    //#region isEnableCompletionDate Prop
    @prop({ defaultValue: false })
    isEnableCompletionDate: boolean;
    //#endregion isEnableCompletionDate Prop

    //#region isVisibleFeedback Prop
    @prop({ defaultValue: false })
    isVisibleFeedback: boolean;
    //#endregion isVisibleFeedback Prop

    //#region isRejectionNoteVisible Prop
    @prop({ defaultValue: false })
    isRejectionNoteVisible: boolean;
    //#endregion isRejectionNoteVisible Prop

    @prop()
    lineManagerRatingDetails:any;

    @prop()
    lineManagerRatings:any;

    @prop()
    objectiveId : number;
}