import { prop,propObject,propArray,required,maxLength,range,email } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SubscriptionBase {

//#region subscriptionID Prop
      //  @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        subscriptionID : number;
//#endregion subscriptionID Prop


//#region entitytypeId Prop
        @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        entitytypeId : number;
//#endregion entitytypeId Prop


//#region oldSubscriptionID Prop
      //  @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        oldSubscriptionID : number;
//#endregion oldSubscriptionID Prop


//#region newsLetterSubscriptionGuid Prop
        @prop()
        newsLetterSubscriptionGuid : any;
//#endregion newsLetterSubscriptionGuid Prop


//#region email Prop
        @required()
        @maxLength({value:255})
        @email()
        email : string;
//#endregion email Prop


//#region isActive Prop
        @required()
        @prop({defaultValue:false})
        isActive : boolean;
//#endregion isActive Prop


//#region createdOn Prop
        @prop()
        createdOn : Date;
//#endregion createdOn Prop


//#region isNewProductActive Prop
        @required()
        isNewProductActive : boolean;
//#endregion isNewProductActive Prop


//#region isGeneralNewsLetter Prop
        @required()
        isGeneralNewsLetter : boolean;
//#endregion isGeneralNewsLetter Prop

//#region userId Prop
        @prop()
     //   @range({minimumNumber:0,maximumNumber:2147483647})
        userId : number;
//#endregion userId Prop

//#region isSubscribedEver Prop
        @required()
        @prop({defaultValue:false})
        isSubscribedEver : boolean;
//#endregion isSubscribedEver Prop

}