import { SupplierBase, BookingGemstoneGemFieldBase, IncidentPersonsAffectedBase, ClientRepairBase, TrackIncidentBase, BespokeOrderCustomerMappingBase, SupplierPictureBase, AddressBase, BoutiqueProductMappingBase, InvoiceBase, ActivityLogBase, GemstoneOrderBase, POSMemberMappingBase, BespokeOrderBase, SupplierDetailBase, SupplierRoleBase, } from '@app/database-models'
//Generated Imports
import { propArray, propObject, prop, maxLength, alpha, trim } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
export class Supplier extends SupplierBase implements FileDetailViewModel {

    @prop()
    userId: number;


    @prop()
    @maxLength({ value: 250 })
    // @alpha({allowWhiteSpace:true})
    @trim()
    stateName:string;

    //#region userDetails Prop
    @propArray(SupplierRoleBase)
    supplierRoles: SupplierRoleBase[];
    //#endregion userDetails Prop

    //#region userDetails Prop
    @propArray(SupplierDetailBase)
    supplierDetails: SupplierDetailBase[];
    //#endregion userDetails Prop

    //#region clientRepairCount Prop
    @prop({ defaultValue: 0 })
    clientRepairCount: number;
    //#endregion clientRepairCount Prop

    //#region showClientRepair Prop

    showClientRepair: boolean;
    //#endregion showClientRepair Prop

    //#region bespokeOrderCount Prop
    @prop({ defaultValue: 0 })
    bespokeOrderCount: number;
    //#endregion bespokeOrderCount Prop


    //#region Generated Reference Properties
    //#region bookingGemstoneGemFields Prop
    bookingGemstoneGemFields: BookingGemstoneGemFieldBase[];
    //#endregion bookingGemstoneGemFields Prop
    //#region incidentPersonsAffected Prop
    incidentPersonsAffected: IncidentPersonsAffectedBase[];
    //#endregion incidentPersonsAffected Prop
    //#region clientRepairs Prop
    clientRepairs: ClientRepairBase[];
    //#endregion clientRepairs Prop
    //#region trackIncidents Prop
    trackIncidents: TrackIncidentBase[];
    //#endregion trackIncidents Prop
    //#region bespokeOrderCustomerMappings Prop
    bespokeOrderCustomerMappings: BespokeOrderCustomerMappingBase[];
    //#endregion bespokeOrderCustomerMappings Prop
    //#region supplierPictures Prop
    supplierPictures: SupplierPictureBase[];
    //#endregion supplierPictures Prop
    //#region address Prop
    address: AddressBase[];
    //#endregion address Prop
    //#region boutiqueProductMappings Prop
    boutiqueProductMappings: BoutiqueProductMappingBase[];
    //#endregion boutiqueProductMappings Prop
    //#region invoices Prop
    invoices: InvoiceBase[];
    //#endregion invoices Prop
    //#region activityLogs Prop
    activityLogs: ActivityLogBase[];
    //#endregion activityLogs Prop
    //#region gemstoneOrders Prop
    gemstoneOrders: GemstoneOrderBase[];
    //#endregion gemstoneOrders Prop
    //#region pOSMemberMappings Prop
    pOSMemberMappings: POSMemberMappingBase[];
    //#endregion pOSMemberMappings Prop
    //#region bespokeOrders Prop
    bespokeOrders: BespokeOrderBase[];
    //#endregion bespokeOrders Prop

    //#endregion Generated Reference Properties


    @prop({ defaultValue: false })
    accountActivation: boolean;

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    stRegistrationDate: string;


    //#region showClientRepair Prop
    keyRelationshipHolderName: string;
    //#endregion showClientRepair Prop
    @prop({ defaultValue: false })
    isRemovedImage: boolean

    @prop()
    industryTypeIds:string;

    @prop()
    blobFileName:string;
    @prop()
    blobPath:string;
    @prop()
    size:number;
}
