import {RelatedProductDesignBase,ProductDesignBase,} from '@app/database-models'
import { RelatedDesignViewModel } from 'src/app/view-model/related-design-view-model';
import { propArray } from '@rxweb/reactive-form-validators';
import { RelatedDesignAddViewModel } from 'src/app/view-model/related-design-add-view-model';
//Generated Imports
export class RelatedProductDesign extends RelatedProductDesignBase
{




//#region Generated Reference Properties
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop

//#endregion Generated Reference Properties

@propArray(RelatedDesignViewModel)
relatedDesignsInput:RelatedDesignViewModel[];

 @propArray(RelatedDesignAddViewModel)
 relatedDesignsAddInput:RelatedDesignAddViewModel[];
    HeaderBtnChk: boolean;
    query:string;








}