import { gridColumn } from "@rxweb/grid";
// @actionColumn({
//   allowSorting: false,
//   style: { width: "5%" },
//   configuredTemplate: { templateName: 'action',childrenTemplateNames:['replyButton'] }, columnIndex: 8, headerTitle: "Action"
// })

// @actionColumn({
//   allowSorting: false,
//   style: { width: "5%" },
//   configuredTemplate: { templateName: 'action'}, columnIndex: 8, headerTitle: "Action"
// })
export class NotesSpList {

    //#region isChecked Prop
    @gridColumn({ allowSorting: false, style: {
      width: "5%",
      "text-align": "initial",
      "min-width": "60px",
      " max-width": "60px",
    }, class:["w-60"], visible: true,name:"isChecked", configuredTemplate: { templateName: 'bindMoveNoteCheckBox' }, columnIndex: 1, headerKey:'NoteListComponent_SelectNote_gh', keyColumn: false })
    isChecked: boolean
    //#endregion isChecked Prop

  //#region noteId Prop
  @gridColumn({
    parameter: true,
    name: "noteId",
    visible: true,
    style: {
      "text-align": "initial",
      "min-width": "30px",
      "max-width": "30px",
    },
    configuredTemplate: { templateName: "noteExpand" },
    class: ["noteid","w-30"],
    columnIndex: 0,
    allowSorting: false,
    headerKey: "expand",
    keyColumn: true
  })
  noteId: number;
  //#endregion noteId Prop

  // //#region isExpand Prop
  // @gridColumn({ parameter: false, visible: false, columnIndex: 0, allowSorting: false, headerTitle: '', keyColumn: true })
  isExpand: boolean;
  // //#endregion isExpand Prop

  //#region createdOn Prop
  @gridColumn({
    parameter: true,
    isAscending: false,
    style: { "min-width": "100px", "text-align": "initial", "max-width": "135px" },
    class: ["w-135"],
    name: "createdOn",
    visible: true,
    columnIndex: 3,
    allowSorting: true,
    headerKey: "NoteListComponent_createdOn_gh",
    keyColumn: false,
  })
  createdOn: Date;

  //#endregion createdOn Prop

  //#region noteDescription Prop
  //@gridColumn({ parameter: false, name: "noteDescription",style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 5, allowSorting: true, headerKey: 'noteDescription', keyColumn: false })
  @gridColumn({
    template: {
      div: {
        style: { "word-break": "break-word", "max-height": "85vh", "padding-bottom": "1.25rem", "padding": "0 0"},
        attributes: {
          innerHTML: function (x, y) {
            return this.noteDescription;
          },
        },
        childrens: [
          {
            span: {
              isBind: (x) => {


                if (x.physicalFileName != null && x.restrictedUser != "true") {
                  return true;
                } else {
                  return false;
                }
              },
              style: {
                display: "block",
              },
              attributes: {
                innerHTML: function (x, y) {
                  return '<ul class="pl-3 mb-0">' + this.multipleDocumentName + '</ul>';
                },
              },
              event: {
                click: "onDocClick",
              },
            },
          },
        ],
      },
    },
    visible: true,
    columnIndex: 7,
    allowSorting: false,
    headerKey: "NoteListComponent_NoteDescription_gh",
    name: "noteDescription",
    style: { width: "30%", "text-align": "initial","min-width": "400px"},
    class: ["text-left","overflow-auto","note-description"],
  })
  noteDescription: any;

  fileUrl: any;
  //#endregion noteDescription Prop

  documentName: any;
  multipleDocumentName:any;
  physicalFileName: any;

  // recordActive
  @gridColumn({
    parameter: true,
    name: "isViewByPeople",
    configuredTemplate: { templateName: "recordActive" },
    visible: false,
    columnIndex: 8,
    allowSorting: true,
    headerKey: "NoteListComponent_DisplayToCustomer_gh",
    keyColumn: false,
    style:{"min-width":"70px","max-width":"90px"},
    class:["w-110"]
  })
  isViewByPeople: boolean;
  //#region subject Prop
  @gridColumn({
    template: {
      div: {
        style: {
          "text-overflow": "ellipsis",
          display: "-webkit-box",
          "-webkit-box-orient": "vertical",
          "-webkit-line-clamp": "3",
          "line-height": "20px",
          "max-height": "60px",
          width: "200px",
          "word-break": "break-word",
          overflow: "hidden",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.subject;
          },
        },
      },
    },
    parameter: false,
    style: { width: "12%", "text-align": "initial", "max-width": "150px", "min-width": "100px" },
    name: "subject",
    visible: true,
    columnIndex: 5,
    class: ["w-152"],
    allowSorting: true,
    headerKey: "NoteListComponent_Subject_gh",
    keyColumn: false,
  })
  //@gridColumn({ parameter: false, name: "subject",style: { "width": "5%", "text-align": "initial","word-break":"break-all"},class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'subject', keyColumn: false })
  subject: string;
  //#endregion subject Prop

  //#region userName Prop
  @gridColumn({
    style: { width: "12%", "text-align": "initial", "max-width": "150px", "min-width": "100px" },
    parameter: false,
    class: ["w-150"],
    name: "userName",
    visible: true,
    columnIndex: 6,
    allowSorting: true,
    headerKey: "NoteListComponent_UserName_gh",
    keyColumn: false,
  })
  userName: string;
  //#endregion userName Prop

  //#region isManual Prop
  @gridColumn({
    parameter: true,
    name: "isManual",
    visible: false,
    columnIndex: 9,
    allowSorting: true,
    headerKey: "NoteListComponent_IsManual_gh",
    keyColumn: false,
  })
  isManual: boolean;
  //#endregion isManual Prop

  //#region isDisplayTeamMember Prop
  @gridColumn({
    parameter: true,
    name: "isDisplayTeamMember",
    visible: false,
    columnIndex: 10,
    allowSorting: true,
    headerKey: "NoteListComponent_IsDisplayTeamMember_gh",
    keyColumn: false,
  })
  isDisplayTeamMember: boolean;
  //#endregion isDisplayTeamMember Prop

  //#region typeName Prop
  @gridColumn({
    parameter: false,
    style: { "text-align": "initial", "max-width": "150px", "min-width": "100px" },
    name: "typeName",
    visible: true,
    columnIndex: 4,
    class: ["w-150"],
    allowSorting: true,
    headerKey: "NoteListComponent_TypeName_gh",
    keyColumn: false,
  })
  typeName: string;
  //#endregion typeName Prop

  //#region typeName Prop
  @gridColumn({
    parameter: true,
    name: "totalCount",
    visible: false,
    columnIndex: 13,
    allowSorting: true,
    headerKey: "totalCount",
    keyColumn: false,
  })
  totalCount: number;
  //#endregion typeName Prop

  //#region isReply Prop
  @gridColumn({
    parameter: true,
    style: {
      width: "5%",
      "text-align": "initial",
      "min-width": "60px",
      " max-width": "60px",
    },
    name: "reply",
    visible: true,
    columnIndex: 1,
    allowSorting: false,
    configuredTemplate: { templateName: "noteReplyButton" },
    headerTitle: "Reply",
    keyColumn: false,
    class:["w-60"]
  })
  isReply: boolean;
  //#endregion isReply Prop

  //#region isEdit Prop
  @gridColumn({
    parameter: true,
    style: {
      width: "5%",
      "text-align": "initial",
      "min-width": "50px",
      " max-width": "50px",
    },
    name: "edit",
    visible: true,
    columnIndex: 12,
    allowSorting: false,
    configuredTemplate: { templateName: "noteEditButton" },
    headerTitle: "Edit",
    keyColumn: false,
    class:["w-50"]
  })
  isEdit: boolean;
  //#endregion isEdit Prop

  //#region isDelete Prop
  @gridColumn({
    parameter: true,
    style: {
      width: "5%",
      "text-align": "initial",
      "min-width": "55px",
      " max-width": "60px",
    },
    name: "delete",
    visible: true,
    columnIndex: 11,
    allowSorting: false,
    configuredTemplate: { templateName: "noteDeleteButton" },
    headerTitle: "Delete",
    keyColumn: false,
    class:["w-55"]
  })
  isDelete: boolean;
  //#endregion isDelete Prop

  //#region isTask Prop
  @gridColumn({
    parameter: true,
    style: {
      width: "5%",
      "text-align": "initial",
      "min-width": "60px",
      " max-width": "60px",
    },
    name: "Task",
    visible: true,
    columnIndex: 2,
    allowSorting: false,
    configuredTemplate: { templateName: "noteTaskButton" },
    headerKey: "NoteListComponent_Task_gh",
    keyColumn: false,
    class:["w-60"]
  })
  isTask: boolean;
  //#endregion isTask Prop

  isSubNote: boolean;

  isShowAll: boolean;
  taskCount: number;

}
