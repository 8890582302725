import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHelpSupportRecordBase {

//#region helpSupportId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'helpSupportId', keyColumn: true})
        helpSupportId : number;
//#endregion helpSupportId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region parentHelpSupportId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'parentHelpSupportId', keyColumn: false})
        parentHelpSupportId : any;
//#endregion parentHelpSupportId Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'displayOrderId', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop


//#region body Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'body', keyColumn: false})
        body : string;
//#endregion body Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : boolean;
//#endregion published Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}