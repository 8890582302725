import { MindMapDataTypeEnum, MindMapNodeType } from 'src/app/enums/mind-map-data-type-enum';
import { RxHttp } from '@rxweb/http';
import { MindMapData } from './mind-map-data';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';

export class RootProjectMindMap {

    mindMapData: any = null;

    rootEntityName: string = '';
    profileImageUrl:string = '';

    mindMap;
    mindMapDataObj: MindMapData;

    httpClient: any;

    constructor(private mindMapService: MindMapService) {
        this.httpClient = new RxHttp();
        this.mindMapDataObj = new MindMapData();
        Window['RootProjectMindMap'] = this;
    }

    async setupProjectMindMap(mindMapData, rootEntityName,imageUrl) {
        this.mindMapData = mindMapData;
        this.rootEntityName = rootEntityName;
        this.profileImageUrl = imageUrl;
        if (this.mindMapData != null) {
            await this.createMindMap();
        }
    }


    async createMindMap() {
        var subProjectData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.SubProjectData, "project-templates.json", "Project", this.mindMapService.graphId, MindMapDataTypeEnum.SUBPROJECT, RolePermissionEnums.Project);
        var legalAgreementData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.LegalAgreementData, "legal-agreement-templates.json", "LegalAgreement", this.mindMapService.graphId, MindMapDataTypeEnum.LEGALAGREEMENT, RolePermissionEnums.LegalAgreements);
        var pressMediaData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PressMediaData, "press-media-templates.json", "PressMedia", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIA, RolePermissionEnums.PressandMedia);
        var eventData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.EventData, "event-templates.json", "Event", this.mindMapService.graphId, MindMapDataTypeEnum.EVENT, RolePermissionEnums.Event);
        var peopleCorporateInvolvedData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.InvolvedData, "people-corporate-involved-templates.json", "PeopleCorporateInvolved", this.mindMapService.graphId, MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED, 0);
        var riskData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.RiskData, "risk-templates.json", "Risk", this.mindMapService.graphId, MindMapDataTypeEnum.RISK, RolePermissionEnums.GemfieldsRiskMenu);
        var pictureVideoData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PictureVideoData, "picture-video-templates.json", "PICTUREVIDEO", this.mindMapService.graphId, MindMapDataTypeEnum.PICTUREVIDEO, 0)

        var childrenArray = [];
        if (this.mindMapService.graphId == 1) {
            if (this.mindMapData.SubProjectData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Sub-Projects (' + this.mindMapData.SubProjectData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'SUBPROJECT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: subProjectData != '' ? [
                        {
                        id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: subProjectData,
                            direction: 'right',
                            selectedType: 'SUBPROJECTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.EventData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'EVENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: eventData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: eventData,
                            direction: 'right',
                            selectedType: 'EVENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.LegalAgreementData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'LEGALAGREEMENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: legalAgreementData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: legalAgreementData,
                            direction: 'left',
                            selectedType: 'LEGALAGREEMENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PressMediaData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PRESSMEDIA',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pressMediaData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pressMediaData,
                            direction: 'right',
                            selectedType: 'PRESSMEDIALIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.InvolvedData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}` + '\')\">People/Corporate Involved (' + this.mindMapData.InvolvedData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PEOPLECORPORATEINVOLVED',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: peopleCorporateInvolvedData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: peopleCorporateInvolvedData,
                            direction: 'left',
                            selectedType: 'PEOPLECORPORATEINVOLVEDLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.RiskData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}` + '\')\">Risks (' + this.mindMapData.RiskData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'RISK',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: riskData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: riskData,
                            direction: 'left',
                            selectedType: 'RISKLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PICTUREVIDEO',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pictureVideoData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pictureVideoData,
                            direction: 'left',
                            selectedType: 'PICTUREVIDEOLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            this.mindMapService.mindMapObject = {
                format: 'nodeTree',
                data: {
                    id: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    isroot: true,
                    topic: '<h2 class="m-0" style="font-size:20px!important"><a class="d-flex align-items-center" target="_blank" href="/projects/' + this.mindMapData.RootEntityId + '?activeTab=mind-map&tabChange=true"> '+this.profileImageUrl+'  <b>' + this.rootEntityName + '</b></a></h2>',
                    selectedType: "",
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: childrenArray
                }
            };
        }
        else {
            this.mindMapService.tempSubgraphData.root = {
                parentNodeId: this.mindMapService.parentNodeToExpand,
                nodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                topic: '<h2 class="m-0" style=""><a target="_blank" href="/projects/' + this.mindMapData.RootEntityId + '?activeTab=mind-map&tabChange=true"><b>' + this.rootEntityName + '</b></a></h2>',
                data: { selectedType: "ROOT" }
            };

            if (this.mindMapData.SubProjectData.length > 0) {
                this.mindMapService.tempSubgraphData.subProject = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Sub-Projects (' + this.mindMapData.SubProjectData.length + ')</span>',
                    data: { selectedType: "SUBPROJECT" }
                };

                this.mindMapService.tempSubgraphData.subProjectList = (subProjectData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.SUBPROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                    topic: subProjectData,
                    data: { selectedType: "SUBPROJECTLIST" }
                };
            }

            if (this.mindMapData.EventData.length > 0) {
                this.mindMapService.tempSubgraphData.event = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    data: { selectedType: "EVENT" }
                };

                this.mindMapService.tempSubgraphData.eventList = (eventData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                    topic: eventData,
                    data: { selectedType: "EVENTLIST" }
                };
            }

            if (this.mindMapData.LegalAgreementData.length > 0) {
                this.mindMapService.tempSubgraphData.LegalAgreement = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    data: { selectedType: "LEGALAGREEMENT" }
                };

                this.mindMapService.tempSubgraphData.LegalAgreementList = (legalAgreementData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                    topic: legalAgreementData,
                    data: { selectedType: "LEGALAGREEMENTLIST" }
                };
            }

            if (this.mindMapData.PressMediaData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMedia = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIA" }
                };

                this.mindMapService.tempSubgraphData.pressMediaList = (pressMediaData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                    topic: pressMediaData,
                    data: { selectedType: "PRESSMEDIALIST" }
                };
            }

            if (this.mindMapData.InvolvedData.length > 0) {
                this.mindMapService.tempSubgraphData.peopleCorporateInvolved = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}` + '\')\">People/Corporate Involved (' + this.mindMapData.InvolvedData.length + ')</span>',
                    data: { selectedType: "PEOPLECORPORATEINVOLVED" }
                };

                this.mindMapService.tempSubgraphData.peopleCorporateInvolvedList = (peopleCorporateInvolvedData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLECORPORATEINVOLVED}_0_${MindMapNodeType.LIST}`,
                    topic: peopleCorporateInvolvedData,
                    data: { selectedType: "PEOPLECORPORATEINVOLVEDLIST" }
                };
            }

            if (this.mindMapData.RiskData.length > 0) {
                this.mindMapService.tempSubgraphData.risk = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}` + '\')\">Risks (' + this.mindMapData.RiskData.length + ')</span>',
                    data: { selectedType: "RISK" }
                };

                this.mindMapService.tempSubgraphData.riskList = (riskData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.LIST}`,
                    topic: riskData,
                    data: { selectedType: "RISKLIST" }
                };
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                this.mindMapService.tempSubgraphData.pictureVideo = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Project}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    data: { selectedType: "PICTUREVIDEO" }
                };

                this.mindMapService.tempSubgraphData.pictureVideoList = (pictureVideoData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                    topic: pictureVideoData,
                    data: { selectedType: "PICTUREVIDEOLIST" }
                };
            }

        }
        return;
    }
}
