<div class="modal-dialog modal-lg" *ngIf="showComponent==true">
  <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-header text-left">
      <ng-container *ngIf="isDirector==0" [rxLocalisationInit]="componentName">
        <h4 class="modal-title mb-0" rxText="CompanyCustomerMappingAddComponent_AddEmployment_t"></h4>
      </ng-container>
      <ng-container *ngIf="componentType=='corporate'" [rxLocalisationInit]="componentName">
        <h4 class="modal-title mb-0" rxText="CompanyCustomerMappingAddComponent_Corporate_t"></h4>
      </ng-container>

      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-0" [formGroup]="companyCustomerMappingFormGroup"
      [rxSpinner]="spin" *ngIf="componentType!='corporate'" [rxLocalisationInit]="componentName" (keyup.enter)="AddForm()">
      <div class="row">
        <div class="col-md-9">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="employmentCompanyId"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [autoComplete]="false" [(source)]="employmentData.companyLookup" #rxSelect mainClass="form-control"
                formControlName="companyId" [keyValueProps]="['company','supplierId']"
                [selectPlaceholder]="'Select Corporate/Groups'"></rx-select>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.companyId.errors">{{companyCustomerMappingFormGroup.controls.companyId.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>
      </div>
      <!-- Employment -->
      <!-- <div class="row" [rxLocalisationInit]="componentName">
        <div class="col-md-6" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_IsEmployment_t"></label>
            <div class="col-md-8">
              <app-checkbox [checkFormControl]="companyCustomerMappingFormGroup.controls.isEmployment"
                class="col-md-auto d-flex align-items-center"></app-checkbox>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.isDirector.errors">{{companyCustomerMappingFormGroup.controls.isDirector.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="companyCustomerMappingFormGroup.value.isEmployment && isCustomer"></div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isEmployment">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
              rxText="CompanyCustomerMappingAdd_JobTitle_t"> </label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="jobTitle"
                rxPlaceholder="CompanyCustomerMappingAdd_JobTitle_p">
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.jobTitle.errors">{{companyCustomerMappingFormGroup.controls.jobTitle.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>
       
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isEmployment && !isCustomer">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="CompanyCustomerMappingAdd_EmploymentStartDate_t">
            </label>
            <div class="col-md-8">
              <rx-date class="w-100" showAddon="true" formControlName="startDate" placeholder="Enter Start Date"
                pickerClass="form-control"></rx-date>
            </div>
          </div>
        </div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isEmployment && !isCustomer">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="CompanyCustomerMappingAdd_EmploymentEndDate_t"></label>
            <div class="col-md-8">
              <rx-date class="w-100" showAddon="true" formControlName="endDate" placeholder="Enter End Date"
                pickerClass="form-control"></rx-date>
                <ng-container *ngIf="dateError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="CompanyCustomerMappingAddComponent_EndDateVal_t"><br /></small>
                </ng-container>
                <ng-container  *ngIf="primaryEndDateError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="CompanyCustomerMappingAddComponent_IsPrimary_t"><br /></small>
                </ng-container>
            </div>
          </div>
        </div>
        
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isEmployment && !isCustomer">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="employmentReportTo"> </label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="employmentData.reportingHeadLookup" #rxSelect mainClass="form-control"
                formControlName="reportTo" [keyValueProps]="['fullName','userId']"
                [selectPlaceholder]="'Select Report To'">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.userId.errors">{{companyCustomerMappingFormGroup.controls.userId.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>

        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isEmployment && !isCustomer">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="CompanyCustomerMappingAdd_Primary_t"></label>
            <div class="col-md-8">
              <app-checkbox
                      [checkFormControl]="companyCustomerMappingFormGroup.controls.isPrimary"
                      class="col-md-auto d-flex align-items-center"></app-checkbox>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.userId.errors">{{companyCustomerMappingFormGroup.controls.isPrimary.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>

        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isEmployment && isCustomer">
          <div class="form-group row check-group">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="CompanyCustomerMappingAdd_EmploymentActive_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <app-checkbox class="col-md-auto d-flex align-items-center"
                    [checkFormControl]="companyCustomerMappingFormGroup.controls.activeEmployement"
                    ></app-checkbox>
                </label>
              </div>
            </div>
          </div>
        </div>

      </div> -->
      <!-- Directorship -->
      <!-- <div class="row">
        <div class="col-md-6" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_IsDirectorship_t"></label>
            <div class="col-md-8">
              <app-checkbox [checkFormControl]="companyCustomerMappingFormGroup.controls.isDirector"
                class="col-md-auto d-flex align-items-center"></app-checkbox>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.isDirector.errors">{{companyCustomerMappingFormGroup.controls.isDirector.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isDirector">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_DirectorshipStartDate_t">
            </label>
            <div class="col-md-8">
              <rx-date class="w-100" showAddon="true" formControlName="directorStartDate" placeholder="Enter Start Date"
                pickerClass="form-control"></rx-date>
            </div>
          </div>
        </div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isDirector">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_DirectorshipEndDate_t"></label>
            <div class="col-md-8">
              <rx-date class="w-100" showAddon="true" formControlName="directorEndDate" placeholder="Enter End Date"
                pickerClass="form-control"></rx-date>
              <ng-container *ngIf="directorDateError==true" [rxLocalisationInit]="componentName">
                <small class="form-text text-danger" rxText="CompanyCustomerMappingAdd_DirectorshipEndDateVal_t"><br /></small>
              </ng-container>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Ownership -->
      <!-- <div class="row">
        <div class="col-md-6" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_IsOwnership_t"></label>
            <div class="col-md-8">
              <app-checkbox [checkFormControl]="companyCustomerMappingFormGroup.controls.isOwnership"
                class="col-md-auto d-flex align-items-center"></app-checkbox>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.isOwnership.errors">{{companyCustomerMappingFormGroup.controls.isDirector.errorMessage}}
                <br />
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isOwnership">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_OwnershipPerc_t"
              ></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="ownershipPerc"
                rxPlaceholder="" placeholder="Enter Ownership percentage">
                <ng-container *ngIf="ownershipPerError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger">You can't leave this field empty<br /></small>
                  </ng-container>
              <small class="form-text text-danger"
                [class.d-block]="companyCustomerMappingFormGroup.controls.ownershipPerc.errors">{{companyCustomerMappingFormGroup.controls.ownershipPerc.errorMessage}}</small>
            </div>
          </div>
        </div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isOwnership">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_OwnershipStartDate_t">
            </label>
            <div class="col-md-8">
              <rx-date class="w-100" showAddon="true" formControlName="ownershipStartDate" placeholder="Enter Start Date"
                pickerClass="form-control"></rx-date>
            </div>
          </div>
        </div>
        <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="companyCustomerMappingFormGroup.value.isOwnership">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CompanyCustomerMappingAdd_OwnershipEndDate_t"></label>
            <div class="col-md-8">
              <rx-date class="w-100" showAddon="true" formControlName="ownershipEndDate" placeholder="Enter End Date"
                pickerClass="form-control"></rx-date>
              <ng-container *ngIf="ownershipDateError==true" [rxLocalisationInit]="componentName">
                <small class="form-text text-danger" rxText="CompanyCustomerMappingAdd_OwnershipEndDateVal_t"><br /></small>
              </ng-container>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <ng-container *ngIf="employmentError==true && !(companyCustomerMappingFormGroup.value.isEmployment || companyCustomerMappingFormGroup.value.isDirector || companyCustomerMappingFormGroup.value.isOwnership)" [rxLocalisationInit]="componentName">
        <small class="form-text text-danger pb-2" style="font-weight: 400;" rxText="CompanyCustomerMappingAdd_CheckboxError_t"></small>
      </ng-container> -->
      <div class="modal-footer px-0">
        <button (click)="AddForm()" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
        <button type="button" class="btn btn-outline-primary mr-1" (click)="hide()" rxText="Btn_Close_t"></button>
      </div>
    </div>
    <div class="modal-body pb-0" [formGroup]="customerCorporateMappingSearchFormGroup"
      [rxSpinner]="spin" *ngIf="componentType=='corporate'" (keyup.enter)="searchCorporateUser()" [rxLocalisationInit]="componentName">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" rxText="CompanyCustomerMappingAddComponent_Name_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="name" rxFocus placeholder="Enter Name">
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer px-0">

        <button (click)="searchCorporateUser()" class="btn btn-success mr-1" rxText="Btn_Search_t"></button>
        <button (click)="redirectCorporate()" class="btn btn-success mr-1" rxText="CompanyCustomerMappingAddComponent_Add_Corporate_t"></button>

      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">

        <div class="dataTables_filter  d-flex flex-wrap align-items-center mt-3">
        </div>
        <div class="table-responsive" (keyup.enter)="addCorporateUser()">
          <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
            <div class="table-responsive" *ngIf="isShowGrid">
              <rx-grid [design]="corporateUserListGrid"></rx-grid>
            </div>
          </div>
        </div>
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
          <h4>No Record Found</h4>
        </div>
        <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
          <ng-container *ngIf="showSaveButton" [rxLocalisationInit]="componentName">
            <button class="btn btn-success mr-1" (click)="addCorporateUser()"
              [disabled]="this.companyCustomerMappingArray.length === 0" rxText="Btn_Save_t"></button>
          </ng-container>
          <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
        </div>
      </div>

    </div>
  </div>
</div>
