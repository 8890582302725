import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GrievanceTierBase {

//#region tierId Prop
    @prop()
    tierId : number;
//#endregion tierId Prop

//#region tierName Prop
    @required({message:"Tier Name is required"})
    @notEmpty()
    @maxLength({value:50})
    @trim()
    tierName : string;
//#endregion tierName Prop

//#region tierShortCode Prop
    @required({message:"Tier Short Code is required"})
    @notEmpty()
    @maxLength({value:50})
    @trim()
    tierShortCode : string;
//#endregion tierShortCode Prop

//#region active Prop
    @prop({defaultValue:false})
    active : boolean;
//#endregion active Prop

//#region deleted Prop
    @prop({defaultValue:false})
    deleted : boolean;
//#endregion deleted Prop

//#region createdOn Prop
    @required()
    createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
    @prop()
    createdBy : number;
//#endregion createdBy Prop

//#region modifiedOn Prop
    @prop()
    modifiedOn : Date;
//#endregion modifiedOn Prop


//#region modifiedBy Prop
    @prop()
    modifiedBy : number;
//#endregion modifiedBy Prop





}