import {vUsersListBase} from '@app/database-models'
//Generated Imports
export class vUsersList extends vUsersListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}