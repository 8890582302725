import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerAttributeBase {

//#region customerAttributeId Prop
        @prop()
        customerAttributeId : number;
//#endregion customerAttributeId Prop


//#region customerId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerId : number;
//#endregion customerId Prop


//#region key Prop
        @required()
        @maxLength({value:100})
        key : string;
//#endregion key Prop


//#region value Prop
        @required()
        @maxLength({value:1000})
        value : string;
//#endregion value Prop

}