import { prop, email } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class  ProjectsCorporateMappingFilterViewModel{

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "fullName";
        this.hasBoughtProduct = false;
    }

    @prop({defaultValue: 0})
    peopleOrCorporate: Number;

    @prop({defaultValue:0})
    userId: number;

    @prop({defaultValue:''})
    badgeIdText:string;
    
    @prop()
    incidentId: number;

    @prop({defaultValue: 0})
    projectOrEvent: Number;
    @prop({defaultValue: 'false'})
    isInvitee: string;
    @prop()
    name : String;
    @prop()
    ragistrationFromDate : Date;
    @prop()
    ragistrationToDate : Date;
    @prop()
   // @email()
    email : String;
    @prop()
    salesAdvisor: Number;
    @prop()
    projectId: Number;

    @prop()
    industryType:number;

    @prop()
    corporate:string;

    @prop()
    eventId:number;

    @prop({defaultValue:25})
    rowCount:number;

    @prop({defaultValue:1})
    pageIndex:number;

    @prop({defaultValue:true})
    sortOrder:string;

    @prop({defaultValue:'fullName'})
    orderBy:string;

    @prop()
    country:number;

    @prop({defaultValue:"false"})
    hasBoughtProduct : boolean;

    @prop()
    categoryId:number;

    @prop()
    collectionId:number;

    @prop()
    industryTypeIds: string="";
}
