import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DocumentBase {
  constructor() {
    this.seeOnline = false;
  }

  //#region documentId Prop
  @prop()
  documentId: number;
  //#endregion documentId Prop


  //#region entityId Prop
  //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  entityId: number;
  //#endregion entityId Prop


  //#region entityTypeId Prop
  //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  entityTypeId: number;
  //#endregion entityTypeId Prop


  //#region createdOn Prop
 // @required()
  createdOn: Date;
  //#endregion createdOn Prop


  //#region createdBy Prop
  //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  createdBy: number;
  //#endregion createdBy Prop


  //#region noteId Prop
  @prop()
  noteId: number;
  //#endregion noteId Prop


  //#region fileName Prop
  @required()
  fileName: string;
  //#endregion fileName Prop


  //#region seeOnline Prop
  @required()
  seeOnline: boolean;
  //#endregion seeOnline Prop


  //#region documentDescription Prop
  @prop()
  documentDescription: string;
  //#endregion documentDescription Prop

  //#region documentDescription Prop
  @prop()
  isPicture: boolean;
  //#endregion documentDescription Prop

  @prop()
  displayOrder:number;
  @prop()
  BlobFileNameBeforeUpdate?:string;
  @prop()
    BlobPath:string;
    @prop()
    ModuleUrl:string;
    @prop()
    size:number;

    @prop()
    documentTypeId: number;
}
