import {vPOSBrandLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPOSBrandLookUp extends vPOSBrandLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}