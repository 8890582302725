import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ITIncTypeBase {

        //#region incTypeId Prop
        @prop()
        incTypeId: number;
        //#endregion incTypeId Prop


        //#region incTypeName Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({ value: 4000 })
        incTypeName: string;
        //#endregion incTypeName Prop

}