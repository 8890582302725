import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { AbstractKnownTo } from "../domain/abstract-known-to";

import { Subscription } from "rxjs";
import { RxFormBuilder, IFormGroup } from "@rxweb/reactive-form-validators";
import { ActivatedRoute } from "@angular/router";

import { KnownTo, vUserKnownTo } from "@app/models";
import { KnownToUserListViewModel } from "../../../../models/extended-models/known-to-user-list-view-model";
import { vUserKnownToBase } from "../../../../models/database-models/v-user-known-to-base";
import { USER_LOOKUPS } from "../../../../const/uris/user-lookups.uri";
import { ModalView } from "../../../../domain/customize-design/modal";
import { KnownToUserViewModel } from "../../../../view-model/known-to-user-view-model";
import { StatusEnum } from "../../../../enums/status.enum";
import { multilingual } from "@rxweb/localization";
import * as CryptoJS from "crypto-js";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("KnownToEditComponent")
@Component({
  selector: "app-known-to-edit",
  templateUrl: "./known-to-edit.component.html",
})
export class KnownToEditComponent
  extends AbstractKnownTo
  implements OnInit, OnDestroy
{
  userKnownToEditFormGroup: IFormGroup<KnownToUserViewModel>;
  userKnownTo: KnownToUserViewModel;
  knownTo: KnownTo;
  subscription: any;
  id: number;
  @Input() userId: number;
  @Input() knownToId: number;
  @Input() hide: Function;
  userRelationId: number;
  titleClose: string;
  constructor(
    private formBuilder: RxFormBuilder,
    private activatedRoute: ActivatedRoute,
    modalView: ModalView,
    private location : PlatformLocation
  ) {
    super();
    this.modalView = modalView;
    this.activatedRoute.queryParams.subscribe((t) => {
      this.id = t["id"];
    });
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.spin = true;
    this.lookup([
      { params: [this.knownToId], propName: "knownTo" },
      { path: USER_LOOKUPS.relationShipLookUp, propName: "relationShipLookUp" },
    ]).subscribe((lookup: any) => {
      this.userKnownToLookUp = lookup;
      this.userKnownTo = new KnownToUserViewModel();
      this.userRelationId = this.userKnownToLookUp.knownTo.knownToUserId;
      this.userKnownToEditFormGroup = this.formBuilder.formGroup(
        KnownToUserViewModel,
        this.userKnownToLookUp.knownTo
      ) as IFormGroup<KnownToUserViewModel>;

      this.spin = false;
      this.showComponent = true;
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
  editRelationShip() {
    this.userKnownToEditFormGroup.submitted = true;
    this.userKnownToEditFormGroup.value.statusId = StatusEnum.Active;
    this.userKnownToEditFormGroup.value.userRelationId = this.userRelationId;
    if (this.userKnownToEditFormGroup.valid) {
      this.spin = true;
      this.put({
        params: [this.knownToId],
        body: this.userKnownToEditFormGroup.value,
      }).subscribe((data) => {
        this.spin = false;
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          //this.toastr.success("Data Updated Successfully")
          this.toastr.success(existsAlert);
        }
        this.hide();
        // this.ngOnInit();
      });
    }
  }

  get componentName(): string {
    return "KnownToEditComponent";
  }
}
