import {CarrierGemFieldBase,} from '@app/database-models'
//Generated Imports
export class CarrierGemField extends CarrierGemFieldBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}