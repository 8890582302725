import {vGemFieldsBookingStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemFieldsBookingStatusLookUp extends vGemFieldsBookingStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}