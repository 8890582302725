import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemstoneOriginLookUpBase {

//#region gemstoneOriginId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'gemstoneOriginId', keyColumn: true})
        gemstoneOriginId : number;
//#endregion gemstoneOriginId Prop


//#region gemstoneOriginName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'gemstoneOriginName', keyColumn: false})
        gemstoneOriginName : string;
//#endregion gemstoneOriginName Prop

}