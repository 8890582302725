import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLocationGroupBase {

        //#region locationGroupId Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'LocationGroupListComponent_Id_gh', keyColumn: true })
        locationGroupId: number;
        //#endregion locationGroupId Prop


        //#region locationGroupTitle Prop
        @gridColumn({ isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'LocationGroupListComponent_Title_gh', keyColumn: false })
        locationGroupTitle: string;
        //#endregion locationGroupTitle Prop

        //#region locationGroupTitle Prop
        @gridColumn({
                template: {
                        div: {
                                attributes: {
                                        innerHTML: function (x, y) {
                                                return this.location;
                                        }
                                }
                        }
                },
                name: 'location', isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'LocationGroupListComponent_Location_gh', keyColumn: false
        })
        location: string;
        //#endregion locationGroupTitle Prop

}