import { TemplateConfig } from '@rxweb/grid';

export const HYPERLINK_GRID_TEMPLATE: TemplateConfig = {
    a: {
        event: {
            click: "onView"
        },
        class: ["mr-2"]
    }
};


export const FAB_GEMSTONE_CERTIFICATE_LINK_GRID_TEMPLATE: TemplateConfig = {
    a: {
        event: {
            click: "onCertificateView"
        },
        class: "text-blue font-weight-bold".split(" "),
        style: {
            "overflow-wrap": "break-word"
        },
        childrens: [{
            text: {
                text: function (e) { return this[e.name] }
            }
        }]
    }
};

export const FAB_GEMSTONE_Invoice_LINK_GRID_TEMPLATE: TemplateConfig = {
    a: {
        event: {
            click: "onInvoiceView"
        },
        class: "text-blue font-weight-bold".split(" "),
        style: {
            "overflow-wrap": "break-word"
        },
        childrens: [{
            text: {
                text: function (e) { return this[e.name] }
            }
        }]
    }
};


export const LA_SIGNED_AGREEMENT_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    isBind: (x) => {
                        return x.isAccess;
                    },
                    event: {
                        click: "onPDFView"
                    },
                    class: "text-blue font-weight-bold".split(" "),
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }]
                }
            },
            {
                label: {
                    isBind: (x) => {
                        return !x.isAccess;
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }]
                }
            }
        ]
    }
    
};

export const LA_EXECUTION_VERSION_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    isBind: (x) => {
                        return x.isAccess;
                    },
                    event: {
                        click: "onDOCView"
                    },
                    class: "text-blue font-weight-bold".split(" "),
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }]
                }
            },
            {
                label: {
                    isBind: (x) => {
                        return !x.isAccess;
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }]
                }
            }
        ]
    }
};