import {vTitleRecordBase,} from '@app/database-models'
//Generated Imports
export class vTitleRecord extends vTitleRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}