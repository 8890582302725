import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHelpSupportUserRoleRecordBase {

//#region helpSupportUserRoleId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'helpSupportUserRoleId', keyColumn: true})
        helpSupportUserRoleId : number;
//#endregion helpSupportUserRoleId Prop


//#region helpSupportId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'helpSupportId', keyColumn: false})
        helpSupportId : number;
//#endregion helpSupportId Prop


//#region userRoleId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'userRoleId', keyColumn: false})
        userRoleId : number;
//#endregion userRoleId Prop


//#region stausId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'stausId', keyColumn: false})
        stausId : number;
//#endregion stausId Prop

}