import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemFieldsBookingStatusBase {

//#region gemFieldsBookingStatusName Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, visible: true, columnIndex: 1, allowSorting: true, class: ["text-left"],headerKey: 'GemFieldsBookingStatusListComponent_StatusName_gh', keyColumn: false })
        gemFieldsBookingStatusName : string;
//#endregion gemFieldsBookingStatusName Prop


//#region gemFieldsBookingStatusId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemFieldsBookingStatusListComponent_StatusId_gh', keyColumn: true })
        gemFieldsBookingStatusId : number;
//#endregion gemFieldsBookingStatusId Prop

}