import { gridColumn, actionColumn } from '@rxweb/grid';
export class MoveNoteDocumentProductSearchViewModel{
    id: number;

    @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
    isChecked: boolean;

    isAllowMoveNoteDocument: boolean

    @gridColumn({
        template:{
            span: {
              isBind: (x) => { 
                if(x.productVariantId==null || x.productVariantId==undefined || x.productVariantId<=0){
                  return false;
                }
                else{
                  return true;
                }
            },
            childrens: [{
              text: {
                  text: function (e) { return this.productVariantId }
              }
          }]
            
          }
        }
        ,style: { width: "3%", "text-align": "initial" },class: ["text-left"],visible: true,parameter:false,allowSorting:false, columnIndex: 4, headerKey: 'MoveNoteDocumentComp_ProductId_gh', keyColumn: false})
    productVariantId:number;

    designId:number;
    designName:string;

    @gridColumn({
      template:{
        div:{
          attributes:{
            innerHTML:function(x,y){
             return this.productName;
            }
          },
        }
    },
      parameter:false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 3, allowSorting: false, headerKey: 'MoveNoteDocumentComp_ProductTitle_gh', keyColumn: false })
    productName:string

    pictureId:number;
  
    @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 14, allowSorting: false, headerKey: 'MoveNoteDocumentComp_ProductPublished_gh', keyColumn: false, configuredTemplate: { templateName: "published" }})
    published:boolean;

    @gridColumn({
        template:{
          div:{
            isBind: (x) => { 
                if(x.exportPrice==null || x.exportPrice==undefined || x.exportPrice=="NA"){
                  return false;
                }
                else{
                  return true;
                }
             },
            attributes:{
              innerHTML:function(x,y){
               return this.exportPrice;
              }
            },
          }
      },
    parameter:false,style: { width: "8%", "text-align": "initial",}, class: ["text-left"],  visible: true, columnIndex: 5, allowSorting: false, headerKey: 'MoveNoteDocumentComp_ProductExportPrice_gh', keyColumn: false})
    exportPrice:string

    totalCount:number;
    hasCoreProduct:boolean;
    hasNoReorder:boolean;
    hasSuperSeller:boolean;
    design:string;
    name:string;
    exportRetailPrice:string;
    hasCategoryPriority:boolean;
    hasCollectionPriority:boolean;
    userAllowed:boolean;
}