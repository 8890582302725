import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTripTypeLookUpBase {

//#region tripTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'tripTypeId', keyColumn: true})
        tripTypeId : number;
//#endregion tripTypeId Prop


//#region tripTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'tripTypeName', keyColumn: false})
        tripTypeName : string;
//#endregion tripTypeName Prop

}