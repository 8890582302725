import { BoutiqueBase, BoutiqueSalesTeamBase, BoutiqueProductMappingBase, } from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';
export class Boutique extends BoutiqueBase implements FileDetailViewModel {

  constructor() {
    super();
    this.isFaberge = false;
  }
  @prop()
  fileData1: string;
  @prop()
  fileExtention1: string;
  @prop()
  fileName1: string;
  @prop()
  fileData2: string;
  @prop()
  fileExtention2: string;
  @prop()
  fileName2: string;
  @prop()
  fileData: string;
  @prop()
  fileExtention: string;
  @prop()
  fileName: string;
  @prop()
  fileData3: string;
  @prop()
  fileExtention3: string;
  @prop()
  fileName3: string;
  @prop()
  fileData4: string;
  @prop()
  fileExtention4: string;
  @prop()
  fileName4: string;
  @prop()
  displayImage: boolean;
  @prop()
  displayImage2: boolean;
  @prop()
  imageURL1: string;
  @prop()
  imageURL2: string;
  @prop()
  imageURL3: string;
  @prop()
  imageURL4: string;
  @prop()
  imageURL5: string;
  @prop({defaultValue: false})
  IsRemovedImage:boolean;
  @prop({defaultValue: false})
  IsRemovedImage2:boolean;




  //#region Generated Reference Properties
  //#region boutiqueSalesTeams Prop
  @propArray(BoutiqueSalesTeamBase)
  boutiqueSalesTeams: BoutiqueSalesTeamBase[];
  //#endregion boutiqueSalesTeams Prop
  //#region boutiqueProductMappings Prop
  boutiqueProductMappings: BoutiqueProductMappingBase[];
  //#endregion boutiqueProductMappings Prop

  //#endregion Generated Reference Properties

  @prop()
  salesPersionIds: string;

  @prop()
  SearchLocationName: string;


  @prop()
  blobFileName: string;
  @prop()
  blobFileName2: string;
  @prop()
  blobPath: string;
  @prop()
  blobPath2: string;
  @prop()
  size: number;
  @prop()
  size2: number;







}
