import {vUserBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';


export class vUser extends vUserBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}