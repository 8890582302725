import {ChainLengthBase,} from '@app/database-models'
//Generated Imports
export class ChainLength extends ChainLengthBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}