import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivityLog, vActivityLogTypeLookUp } from '@app/models';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent,  } from '@rxweb/angular-router';

@http({
    path: "api/ActivityLogs",
})

export class AbstractActivityLog extends CoreComponent {
    activityLogFormGroup: IFormGroup<ActivityLog>;
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    isNoRecordFound : boolean;
    isShowGrid : boolean;
    constructor(){
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    activityLogTypeLookups: any = {
        activityLogTypeLookUp: vActivityLogTypeLookUp,
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {})
    }
}
