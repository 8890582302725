import {vAdhocPagesBase,} from '@app/database-models'
//Generated Imports
import { actionColumn,gridColumn} from '@rxweb/grid';
@actionColumn({
    visible:false,
    name:"action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName:'action' }, columnIndex: 10, headerTitle: "Action"
})

export class vAdhocPages extends vAdhocPagesBase 
{
    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 11, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 12, headerTitle: "Delete"
    })
    deleteAction:any;

    // @gridColumn({ class: ["text-left"],  name: "imageURL1", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'image', keyColumn: false })
    // imageURL1: number;

    // @gridColumn({ class: ["text-left"],  name: "imageURL2", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'image', keyColumn: false })
    // imageURL2: number;

    // @gridColumn({ class: ["text-left"],  name: "imageURL3", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'image', keyColumn: false })
    // imageURL3: number;

//#region Generated Reference Properties

//#endregion Generated Reference Properties










}