import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBoutiqueRecordBase {

//#region boutiqueId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'boutiqueId', keyColumn: true})
        boutiqueId : number;
//#endregion boutiqueId Prop


//#region boutiqueName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'boutiqueName', keyColumn: false})
        boutiqueName : string;
//#endregion boutiqueName Prop


//#region address Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'address', keyColumn: false})
        address : string;
//#endregion address Prop


//#region openingHours Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'openingHours', keyColumn: false})
        openingHours : string;
//#endregion openingHours Prop


//#region phoneNumber Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'phoneNumber', keyColumn: false})
        phoneNumber : string;
//#endregion phoneNumber Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region pictureId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'pictureId', keyColumn: false})
        pictureId : number;
//#endregion pictureId Prop


//#region latitude Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'latitude', keyColumn: false})
        latitude : string;
//#endregion latitude Prop


//#region longitude Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'longitude', keyColumn: false})
        longitude : string;
//#endregion longitude Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region boutiqueManager Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'boutiqueManager', keyColumn: false})
        boutiqueManager : string;
//#endregion boutiqueManager Prop


//#region managerId Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'managerId', keyColumn: false})
        managerId : any;
//#endregion managerId Prop


//#region supervisorId Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'supervisorId', keyColumn: false})
        supervisorId : any;
//#endregion supervisorId Prop


//#region isFaberge Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'isFaberge', keyColumn: false})
        isFaberge : boolean;
//#endregion isFaberge Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : number;
//#endregion countryId Prop


//#region cityName Prop
        @gridColumn({visible: true, columnIndex:16, allowSorting: true, headerKey: 'cityName', keyColumn: false})
        cityName : string;
//#endregion cityName Prop


//#region sEName Prop
        @gridColumn({visible: true, columnIndex:17, allowSorting: true, headerKey: 'sEName', keyColumn: false})
        sEName : string;
//#endregion sEName Prop


//#region metaTitleCountry Prop
        @gridColumn({visible: true, columnIndex:18, allowSorting: true, headerKey: 'metaTitleCountry', keyColumn: false})
        metaTitleCountry : string;
//#endregion metaTitleCountry Prop


//#region metaDescriptionCountry Prop
        @gridColumn({visible: true, columnIndex:19, allowSorting: true, headerKey: 'metaDescriptionCountry', keyColumn: false})
        metaDescriptionCountry : string;
//#endregion metaDescriptionCountry Prop


//#region metaKeywordsCountry Prop
        @gridColumn({visible: true, columnIndex:20, allowSorting: true, headerKey: 'metaKeywordsCountry', keyColumn: false})
        metaKeywordsCountry : string;
//#endregion metaKeywordsCountry Prop


//#region metaTitleCity Prop
        @gridColumn({visible: true, columnIndex:21, allowSorting: true, headerKey: 'metaTitleCity', keyColumn: false})
        metaTitleCity : string;
//#endregion metaTitleCity Prop


//#region metaDescriptionCity Prop
        @gridColumn({visible: true, columnIndex:22, allowSorting: true, headerKey: 'metaDescriptionCity', keyColumn: false})
        metaDescriptionCity : string;
//#endregion metaDescriptionCity Prop


//#region metaKeywordsCity Prop
        @gridColumn({visible: true, columnIndex:23, allowSorting: true, headerKey: 'metaKeywordsCity', keyColumn: false})
        metaKeywordsCity : string;
//#endregion metaKeywordsCity Prop


//#region metaTitleBoutique Prop
        @gridColumn({visible: true, columnIndex:24, allowSorting: true, headerKey: 'metaTitleBoutique', keyColumn: false})
        metaTitleBoutique : string;
//#endregion metaTitleBoutique Prop


//#region metaDescriptionBoutique Prop
        @gridColumn({visible: true, columnIndex:25, allowSorting: true, headerKey: 'metaDescriptionBoutique', keyColumn: false})
        metaDescriptionBoutique : string;
//#endregion metaDescriptionBoutique Prop


//#region metaKeywordsBoutique Prop
        @gridColumn({visible: true, columnIndex:26, allowSorting: true, headerKey: 'metaKeywordsBoutique', keyColumn: false})
        metaKeywordsBoutique : string;
//#endregion metaKeywordsBoutique Prop

}