import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractBannedIpNetwork } from '../domain/abstract-banned-ip-network';
import { BannedIpNetwork, vBannedIpNetwork } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { Router } from '@angular/router';
import { DatePipe, PlatformLocation } from '@angular/common';

import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
@multilingual("BannedIpNetworkListComponent")
//@access({ accessLevel: RolePermissionEnums.BlacklistIpNetwork, action: "get" })

@Component({
    selector: "app-banned-ip-network-list",
    templateUrl: './banned-ip-network-list.component.html',
})
export class BannedIpNetworkListComponent extends AbstractBannedIpNetwork implements OnInit, OnDestroy {
    bannedIpNetwork: List<BannedIpNetwork>;
    bannedIpNet: vBannedIpNetwork[];
    subscription: any;
    bannedIPNetworkGrid: AppGrid;
    bannedIPNetworkGridColumns: any[];
    isNoRecordFound: boolean = false;
    titleSearch: string;
    constructor(private router: Router, private datePipe: DatePipe, private location : PlatformLocation) {
        super();
        this.isNoRecordFound = false;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }
    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vBannedIpNetwork[]) => {
            this.spin = false;
            this.bannedIpNet = t;
            // t.forEach(x=>x.createdOn = this.datePipe.transform(x.createdOn,'dd/MMM/yyyy'));
            //t.forEach(x=>x.updatedOn = this.datePipe.transform(x.updatedOn,'dd/MMM/yyyy'));
            // this.bannedIPNetworkGrid.gridColumns.forEach(x=>{
              //     if(this.bannedIPNetworkGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpNetwork,action:"put"}) && this.bannedIPNetworkGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpNetwork,action:"delete"})){
                //         if(x.name=="action"){
                  //             x.visible=true;
                  //         }
                  //     }
                  //     else{
                    //             if(x.name=="action"){
                      //                 x.visible=false;
                      //             }
                      //             if(x.name=="editAction"){
                        //                 x.visible=this.bannedIPNetworkGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpNetwork,action:"put"});
                        //             }
                        //             if(x.name=="deleteAction"){
                          //                 x.visible=this.bannedIPNetworkGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpNetwork,action:"delete"});
                          //             }
                          //     }
                          // })
            if(t.length > 0)
            {
              this.isNoRecordFound = false;
              this.bannedIPNetworkGrid = new AppGrid(t, vBannedIpNetwork, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
              this.bannedIPNetworkGrid.componentId = this.componentName;
              this.bannedIPNetworkGridColumns = this.bannedIPNetworkGrid.gridColumns.filter(x => x.keyColumn == false);
            }
            else
            {
              this.isNoRecordFound = true;
            }
        })
    }
    onEdit(log: vBannedIpNetwork) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["banned-ip-network", log.bannedIpNetworkId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["banned-ip-network", log.bannedIpNetworkId]);
    }

    onDelete(log: vBannedIpNetwork) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [log.bannedIpNetworkId] }).subscribe(data => {
                    this.spin = false;
                    this.bannedIPNetworkGrid.remove(log.bannedIpNetworkId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/blacklist'], { queryParams: { "activeTab": "network" } });
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Banned_IP_NW");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.bannedIpNet.filter(x => x.startAddress.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.bannedIpNetworkId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.endAddress.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.comment.toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.ipException.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.stCreatedOn.toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.stUpdatedOn.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.bannedIPNetworkGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.bannedIPNetworkGrid = null;
        }
        setTimeout(() => {
            if (this.bannedIPNetworkGrid == null || this.bannedIPNetworkGrid == undefined) {
                this.bannedIPNetworkGrid = new AppGrid(this.bannedIpNet, vBannedIpNetwork, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.bannedIPNetworkGrid.componentId = this.componentName;
                this.bannedIPNetworkGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.bannedIPNetworkGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.bannedIPNetworkGrid.refresh("banned-ip-network");
                this.bannedIPNetworkGrid.design(document.getElementById("banned-ip-network"));
                this.bannedIPNetworkGridColumns = this.bannedIPNetworkGrid.gridColumns.filter(x => x.keyColumn == false);
            }
            this.bannedIPNetworkGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "BannedIpNetworkListComponent";
    }

}
