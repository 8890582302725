import {CurrencyBase,CountryBase,ProductPieceGemstoneMappingBase,} from '@app/database-models'
//Generated Imports
export class Currency extends CurrencyBase 
{


    constructor() {
        super();
        this.published = false;
    }

//#region Generated Reference Properties
//#region country Prop
country : CountryBase;
//#endregion country Prop
//#region productPieceGemstoneMappings Prop
productPieceGemstoneMappings : ProductPieceGemstoneMappingBase[];
//#endregion productPieceGemstoneMappings Prop

//#endregion Generated Reference Properties












}