<div  [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="bg-white d-flex flex-column flex-grow-1">
    <div class="row add-countries-form" (keyup.enter)="onKeyPress()" [formGroup]="exceptionLogsSearchFormGroup"
      *ngIf="showComponent" [rxLocalisationInit]="componentName">
      <div class="col-lg-6 col-xl-4 pr-xl-5">
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
            rxText="ExceptionLogListComponent_StartDate_t">
          </label>
          <div class="col-md-8">
            <rx-date showAddon="true" formControlName="fromDate" pickerClass="form-control"
               placeholder="Select Date"></rx-date>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-4 px-xl-4">
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
            rxText="ExceptionLogListComponent_EndDate_t">
          </label>
          <div class="col-md-8">
            <rx-date showAddon="true" formControlName="toDate" pickerClass="form-control"
               placeholder="Select Date"></rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">End Date should
                be greater than Start Date<br /></small>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-4 pl-xl-5">
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
            rxText="ExceptionLogListComponent_Message_t"></label>
          <div class="col-md-8">
            <input type="text" formControlName="message" class="form-control" 
              rxPlaceholder="ExceptionLogListComponent_Message_p" />
          </div>
        </div>
      </div>
    </div>
    <div class="hr-line-dashed border-top w-100"></div>
    <div class=" d-flex flex-wrap align-items-center row">
      <div class="col text-right">
        <button (click)="onKeyPress()" class="btn btn-success mr-1" rxText="Btn_Search_t"></button>
        <ng-container *ngIf="logDelete" [rxLocalisationInit]="componentName">
          <button (click)="clearLog()" class="btn btn-danger" [disabled]="this.rebuildApplicationExceptionLogIds.length === 0" rxText="Btn_Clear_Log_t"></button>
        </ng-container>
      </div>
    </div>
    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showSearchBar">
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="dataTables_filter  d-flex flex-wrap align-items-center">
          <label class="ml-auto mr-0 mb-3">
            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
              placeholder="" [title]="titleSearch">
          </label>
        </div>
        
      </div>
    </div>
    <div class="table-responsive" *ngIf="isShowGrid">
      <div class="dataTables_wrapper pb-0 dt-bootstrap4">
        <rx-grid [design]="exceptionLogListGrid"></rx-grid>
      </div>
    </div>
    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
      <h4>No Record Found</h4>
    </div>
  </div>
</div>