import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"

export class PowerBIReportUserRoleBase {

        @prop()
        powerBIReportUserRoleId : number;

        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        powerBIReportId : number;


        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userRoleId : number;

        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        stausId : number;

        @prop({defaultValue:false})
        isActive : boolean;

}