import { prop,propObject,propArray,required,maxLength,range, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class WatchRepairDetailBase {

//#region watchRepairID Prop
        @prop()
        watchRepairID : number;
//#endregion watchRepairID Prop


//#region productVariantBatchPieceID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceID : number;
//#endregion productVariantBatchPieceID Prop


//#region requestDate Prop
        @required()
        requestDate : Date;
//#endregion requestDate Prop


//#region model Prop
        @maxLength({value:100})
        model : string;
//#endregion model Prop


//#region warrantyRepair Prop
        @prop()
        warrantyRepair : boolean;
//#endregion warrantyRepair Prop


//#region warrantyCard Prop
        @maxLength({value:50})
        warrantyCard : string;
//#endregion warrantyCard Prop


//#region warrantyDate Prop
        @prop()
        warrantyDate : Date;
//#endregion warrantyDate Prop


//#region repairHistory Prop
        @maxLength({value:500})
        repairHistory : string;
//#endregion repairHistory Prop


//#region reasonOfReturn Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:1000})
        reasonOfReturn : string;
//#endregion reasonOfReturn Prop


//#region comments Prop
        @maxLength({value:2000})
        comments : string;
//#endregion comments Prop


//#region uploadFile1 Prop
        @maxLength({value:200})
        uploadFile1 : string;
//#endregion uploadFile1 Prop


//#region uploadFile2 Prop
        @maxLength({value:200})
        uploadFile2 : string;
//#endregion uploadFile2 Prop


//#region uploadFile3 Prop
        @maxLength({value:200})
        uploadFile3 : string;
//#endregion uploadFile3 Prop


//#region approvalStatus Prop
        @prop()
        approvalStatus : number;
//#endregion approvalStatus Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop


//#region salesChannelID Prop
        @prop()
        salesChannelID : number;
//#endregion salesChannelID Prop


//#region customerID Prop
        @prop()
        customerID : number;
//#endregion customerID Prop


//#region productName Prop
        @maxLength({value:400})
        productName : string;
//#endregion productName Prop

}