import {vPricebandOptionRecordBase,} from '@app/database-models'
//Generated Imports
export class vPricebandOptionRecord extends vPricebandOptionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}