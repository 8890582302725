import { IFormGroup } from '@rxweb/reactive-form-validators';
import { SystemInformation } from '@app/models';
import { CoreComponent,  } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { http } from '@rxweb/http';


@http({
    path: "api/SystemInformation",
})

export class AbstractSystemInformation extends CoreComponent {
    systemInformationFormGroup: IFormGroup<SystemInformation>
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
}
