import { propArray } from '@rxweb/reactive-form-validators';
import { ITTicketCustomerCorporateMappingBase } from '../database-models/i-t-ticket-customer-corporate-mapping-base';
//Generated Imports
export class ITTicketCustomerCorporateMapping extends ITTicketCustomerCorporateMappingBase {



    //#region Generated Reference Properties

    //#endregion Generated Reference Properties

    //#region iTTicketCustomerCorporateMappingBase
    @propArray(ITTicketCustomerCorporateMappingBase)
    iTTicketCustomerCorporateMappingBase: ITTicketCustomerCorporateMappingBase[];
    //#endregion iTTicketCustomerCorporateMappingBase

    headerBtnCheck:boolean;
    query:string;
    isSupplier:boolean;
}