import { Toastr, ToastrMessageType } from '@rxweb/js';
import { Injectable } from "@angular/core";

@Injectable()
export class BaseToastr extends Toastr {
    constructor() {
        super();
        this.designClass.root = ["toast-container","toast-bottom-right"];
        this.designClass.secondLevelDiv = ["toast", function () { return this.messageType == ToastrMessageType.success ? "toast-success" : "toast-error" }]
        this.designClass.thirdLevelDiv = ["toast-message"]
        this.defaultConfig.timeOut = 10000;
    }

    success(message: string) {
        this.show(message, ToastrMessageType.success);
    }

    error(message: string) {
        this.show(message, ToastrMessageType.error);
    }

    warning(message: string) {
        this.show(message, ToastrMessageType.warning);
    }

    info(message: string) {
        this.show(message, ToastrMessageType.info);
    }

}

