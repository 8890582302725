import { MainMenuItemBase } from '@app/database-models';
import { propObject, prop, propArray } from '@rxweb/reactive-form-validators';
import { SubMenuItem } from './sub-menu-item';

class SubMenuItemTabs {
    @prop()
    tabName: string;
    @prop()
    tabTitle: string;
}
export class MainMenuItem extends MainMenuItemBase {

    @propArray(SubMenuItem)
    subMenus: SubMenuItem[];

    @propObject(SubMenuItem)
    featuredMenu: SubMenuItem;

    @prop()
    subMenuItemTabs: SubMenuItemTabs[];

    @prop()
    languageId: number;

    @prop()
    menuNameEng: string;

    @prop()
    featuredNameEng: string;
    
}


