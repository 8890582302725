import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { Address, vStateProvinceLookUp, vCountryLookUp } from '@app/models';
import { AbstractAddress } from '../domain/abstract-address';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { AddressEditComponent } from '../edit/address-edit.component';

import { StatusEnum } from 'src/app/enums/status.enum';
import { CoreComponent, } from '@rxweb/angular-router';
import * as CryptoJS from 'crypto-js';

import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
@multilingual("AddressAddComponent")
@Component({
    selector: "app-address-add",
    templateUrl: './address-add.component.html'
})


export class AddressAddComponent extends AbstractAddress implements OnInit, OnDestroy {
    @Input() userId: number;
    @Input() isBillingAddress: boolean;
    @Input() hide: Function;
    @Input() email: string;
    @Input() isCorporate: boolean;
    address: Address;
    subscription: any;
    addressLookup: any = {
        stateProvinceLookup: vStateProvinceLookUp,
        countryLookup: vCountryLookUp
    };
    id: number;


    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, modelView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.activatedRoute.queryParams.subscribe(t => {
            this.id = t['id'];
        })
        this._this = this;
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.userOrSupplier = this.isCorporate ? "Supplier" : "Customer";
        this.lookup([{ propName: "vStateProvinceLookUp", path: USER_LOOKUPS.stateProvinceLookup }, { propName: "vCountryLookUp", path: USER_LOOKUPS.countryLookUp }]).subscribe((lookup: any) => {
            this.address = new Address();
            this.provinceShow=0;
            this.address.userId = this.isCorporate ? null : this.userId;
            this.address.supplierId = this.isCorporate ? this.userId : null;
            this.address.isBillingAddress = this.isBillingAddress;
            this.address.createdOn = new Date();
            this.address.updatedOn = new Date();
            this.address.isImpersonated = false;
            this.address.addressId = 0;
            this.address.statusId = StatusEnum.Active;
            this.address.stateID=0;
            this.addressFormGroup = this.formBuilder.formGroup(this.address) as IFormGroup<Address>;
            this.addressLookup = lookup;
            this.showComponent = true;


        })
    }

    onEmailKeyPress(){
        this.addressFormGroup.submitted=true;
        this.emailError=true;
    }

    onPhoneKeyPress(){
        this.addressFormGroup.submitted=true;
        this.phoneError=true;
    }

    AddForm() {
        this.addressFormGroup.submitted = true;
        // this.formSubmit=true;
        if (this.addressFormGroup.valid )
        //     && (
        //     (this.isshowStateTextbox==true && this.addressFormGroup.value.stateProvinceName!=null && this.addressFormGroup.value.stateProvinceName.trim()!="")
        //     ||
        //     (this.isshowStateTextbox==false && this.addressFormGroup.value.stateProvinceID!=null && this.addressFormGroup.value.stateProvinceID>0)

        //  ))
          {
            this.stateProvinceError=true;
            this.spin = true;
            if((this.addressFormGroup.value.stateProvinceID==null || this.addressFormGroup.value.stateProvinceID.toString()==""))
            {
                this.addressFormGroup.patchValue({
                    stateProvinceID:0
                })
            }
            if(this.isshowStateTextbox==true)
            {
                this.addressFormGroup.patchValue({
                    stateProvinceID:0
                })
            }
            else
            {
                var state:vStateProvinceLookUp = this.addressLookup.vStateProvinceLookUp.find(x => x.stateProvinceId == this.addressFormGroup.value.stateProvinceID)
                this.addressFormGroup.patchValue({
                    stateProvinceName:(state!=null && state!=undefined)?state.stateProvinceName:null
                })
            }
            this.post({ body: this.addressFormGroup.value }).subscribe(response => {
                this.spin = false;
                //this.toastr.success("Data Added Successfully")
                var existsAlert = getLocalizedValue("Address_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
            })
            this.hide();
        }
        // else{
        //     if(
        //         (this.isshowStateTextbox==true && (this.addressFormGroup.value.stateProvinceName==null || this.addressFormGroup.value.stateProvinceName.trim()==""))
        //         ||
        //         (this.isshowStateTextbox==false && (this.addressFormGroup.value.stateProvinceID==null || this.addressFormGroup.value.stateProvinceID<=0))
        //     ){
        //         this.stateProvinceError=true;
        //     }
        // }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "AddressAddComponent";
      }

}
function Viewchild(arg0: string): (target: AddressAddComponent, propertyKey: "rxSelect") => void {
    throw new Error("Function not implemented.");
}
