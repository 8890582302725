import {vLocationGroupLookUpBase,} from '@app/database-models'
//Generated Imports
export class vLocationGroupLookUp extends vLocationGroupLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}