import {vHolidayProcessListBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    name:"action",
    visible:false,
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'editModel' }, columnIndex: 9, headerTitle: "Action"
})
export class vHolidayProcessList extends vHolidayProcessListBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



}