import { TemplateConfig } from '@rxweb/grid';
import { LeaveStatusEnum, StatusEnumString } from 'src/app/enums/leave-status.enum';

export const LEAVESTATUS_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: ["label", function (e) {
            var labelClass = "";

            if(this[e.name] == LeaveStatusEnum.Apply){
                labelClass = "Awaiting";
            }

            if(this[e.name] == LeaveStatusEnum.Approve){
                labelClass ="label-primary";
            }

            if(this[e.name] == LeaveStatusEnum.Reject){
                labelClass = "label-danger";
            }

            if(this[e.name] == LeaveStatusEnum.Cancle){
                labelClass = "label-warning";
            }

            return labelClass;
        }],
        childrens: [{
            text: {
                text: function (e) {
                    if(this.statusName != undefined && this.statusName != null && this.statusName != "") {
                        return this.statusName;
                    }
                    var status = "";

                    if(this[e.name] == LeaveStatusEnum.Apply){
                       status = "Awaiting";
                    }

                    if(this[e.name] == LeaveStatusEnum.Approve){
                       status ="Approved";
                    }

                    if(this[e.name] == LeaveStatusEnum.Reject){
                       status = "Rejected";
                    }

                    if(this[e.name] == LeaveStatusEnum.Cancle){
                       status = "Cancelled";
                    }
                    return status;
                }
            }
        }]
    }
}

export const PRODCUTION_STATUS_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: ["label", function (e) {
            var labelClass = "";

            if(this[e.name] == StatusEnumString.Pending){
                labelClass = "Awaiting";
            }

            if(this[e.name] == StatusEnumString.Approved){
                labelClass ="label-primary";
            }

            if(this[e.name] == StatusEnumString.Declined){
                labelClass = "label-danger";
            }

            if(this[e.name] == StatusEnumString.Cancelled){
                labelClass = "label-warning";
            }

            if(this[e.name] == StatusEnumString.Confirmed){
                labelClass = "label-warning";
            }


            if(this[e.name] == StatusEnumString.Completed){
                labelClass = "label-success";
            }


            return labelClass;
        }],
        childrens: [{
            text: {
                text: function (e) {
                    var status = "";

                    if(this[e.name] == StatusEnumString.Pending){
                        status = "Pending";
                    }

                    if(this[e.name] == StatusEnumString.Approved){
                        status ="Approved";
                    }

                    if(this[e.name] == StatusEnumString.Declined){
                        status = "Declined";
                    }

                    if(this[e.name] == StatusEnumString.Cancelled){
                        status = "Cancelled";
                    }

                    if(this[e.name] == StatusEnumString.Confirmed){
                        status = "Confirmed";
                    }

                    if(this[e.name] == StatusEnumString.Completed){
                        status = "Completed";
                    }

                    return status;
                }
            }
        }]
    }
}
