import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    visible:false,
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 13, headerTitle: "Action"
})
// @actionColumn({
//     name:"editAction",
//     allowSorting: false,
//     style: { width: "1%" },
//     configuredTemplate: { templateName: 'edit' }, columnIndex: 11, headerTitle: "Edit"
// })
// @actionColumn({
//     name:"deleteAction",
//     allowSorting: false,
//     style: { width: "1%" },
//     configuredTemplate: { templateName: 'delete' }, columnIndex: 12, headerTitle: "Delete"
// })
export class SupplierViewModelBase {
    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'id', keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    //#region supplierId Prop
    @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'id', keyColumn: true })
    supplierId: number;
    //#endregion supplierId Prop

    //#region company Prop
    @gridColumn({ parameter: true, configuredTemplate: { templateName: "reDirectPeopleCorporateAndTooltip" }, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'SupplierListComponent_CompanyName_gh', keyColumn: false })
    company: string;
    //#endregion company Prop

    //#region salesAdvisor Prop
    @gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'SupplierListComponent_SalesAdvisor_gh', keyColumn: false })
    salesAdvisor: string;
    //#endregion salesAdvisor Prop

    //#region introduceBy Prop
    @gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: true, headerKey: 'SupplierListComponent_IntroducedBy_gh', keyColumn: false })
    introduceBy: string;
    //#endregion introduceBy Prop
  

    //region isVerified 
    @gridColumn({  parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "recordStatus" }, visible: true, columnIndex: 13, allowSorting: true, headerKey: 'SupplierListComponent_Verified_gh', keyColumn: false })
    statusId: number;
    //#endregion isVerified 

    //region verified 
    //@gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: false, headerKey: 'verified', keyColumn: false })
    verified: number;
    //#endregion verified

    //region registrationDate 
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: true, headerKey: 'SupplierListComponent_RegistrationDate_gh', keyColumn: false })
    registrationDate: any;
    //#endregion registrationDate 

    //region stRegistrationDate 
    @gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'SupplierListComponent_RegistrationDate_gh', keyColumn: false })
    stRegistrationDate: any;
    //#endregion stRegistrationDate 

    //region loginCount 
    @gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 12, allowSorting: true, headerKey: 'SupplierListComponent_LoginCount_gh', keyColumn: false })
    loginCount: number;
    //#endregion loginCount

    //region countryName 
    @gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'SupplierListComponent_Country_gh', keyColumn: false })
    countryName: string;
    //#endregion countryName

    //#region hasPurchasedFrom Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: false, headerKey: 'SupplierListComponent_HasPurchasedFrom_gh', keyColumn: false })
    hasPurchasedFrom: string;
    //#endregion hasPurchasedFrom Prop

    //region DDKYCStatus 
    @gridColumn({ parameter: false,style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 10, allowSorting: false, headerKey: 'SupplierListComponent_KYC_gh', keyColumn: false })
    ddKYCStatus: string;
    //#endregion DDKYCStatus

    //#region emailAddress
    @gridColumn({ parameter:false,style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible:true,columnIndex:3, allowSorting: true, headerKey:'SupplierListComponent_EmailAddress_gh', keyColumn:false })
    emailAddress: string;
    //#endregion emailAddress

    @gridColumn({ parameter:false,style: { width: "15%", "text-align": "initial" },class: ["text-left"],configuredTemplate: { templateName: "recordStatuses" }, visible:true,columnIndex:11, allowSorting: true, headerKey:'SupplierListComponent_SensitiveContact_gh', keyColumn:false })
    contactPermissionRequired: boolean;
    //#endregion emailAddress

    @gridColumn({
    visible:false,
    name:"editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 11, headerTitle: "Edit"
})
editAction:any;

@gridColumn({
    visible:false,
    name:"deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 12, headerTitle: "Delete"
})
deleteAction:any;
}