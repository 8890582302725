import {ProductTemplateBase,ProductDesignBase,} from '@app/database-models'
//Generated Imports
export class ProductTemplate extends ProductTemplateBase 
{




//#region Generated Reference Properties
//#region productDesigns Prop
productDesigns : ProductDesignBase[];
//#endregion productDesigns Prop

//#endregion Generated Reference Properties












}