import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LanguageBase {

//#region languageId Prop
        @prop()
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        @trim()
        name : string;
//#endregion name Prop


//#region languageCulture Prop
        @required()
        @notEmpty()
        @maxLength({value:20})
        languageCulture : string;
//#endregion languageCulture Prop


//#region flagImageFileName Prop
        @required()
        @notEmpty()
        @maxLength({value:50})
        @trim()
        flagImageFileName : string;
//#endregion flagImageFileName Prop


//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @required()
        @notEmpty()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

//#region domain Prop
@range({minimumNumber:1,maximumNumber:2147483647})
@required()
domain : number;
//#endregion domain Prop



















}
