import { Component, OnInit, OnDestroy } from "@angular/core"
import { AbstractPowerBIReport } from '../domain/abstract-power-bi-report'
import { AppGrid } from '../../../../domain/app-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { PowerBIReportAddComponent } from '../add/power-bi-report-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
import { vPowerBIReport } from "src/app/models/extended-models/v-power-bi-report";
import { getMSALAuthConfigForPowerBI } from "src/app/domain/system-constant";

@multilingual("PowerBIReportListComponent")
@access({ accessLevel: RolePermissionEnums.PowerBIReports, action: "get" })

@Component({
    selector: "app-power-bi-report-list",
    templateUrl: './power-bi-report-list.component.html'
})
export class PowerBIReportListComponent extends AbstractPowerBIReport implements OnInit, OnDestroy {
    powerBIReports: vPowerBIReport[];
    subscription: any;
    powerBIReportsGrid: AppGrid;
    powerBIReportAdd: any = PowerBIReportAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    authMessageType: string = "";

    ouathCredentials: {
        clientId: string,
        scope: string,
        state: string,
        redirectURI: string
       } = {
         clientId: getMSALAuthConfigForPowerBI().clientId,
         scope: getMSALAuthConfigForPowerBI().scope,
         state: getMSALAuthConfigForPowerBI().state,
         redirectURI: getMSALAuthConfigForPowerBI().redirectURI
      };

    constructor(private activatedRoute: ActivatedRoute, private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private location : PlatformLocation) {
        super();
        this.activatedRoute.queryParams.subscribe(t => {
            this.authMessageType = (t['type'] == undefined ? "" : t['type']);
        });
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.PowerBIReports;
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Power BI Reports - G-Trac Admin");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.bindGrid();
    }

    ngAfterViewInit() {
        if (this.authMessageType != "") {
            if (this.authMessageType == "error")
                this.toastr.error(getLocalizedValue("PowerBIReportListComponent_OAuth_Error_Msg_t"));
            if (this.authMessageType == "success")
                this.toastr.success(getLocalizedValue("PowerBIReportListComponent_OAuth_Success_Msg_t"));
            this.router.navigate(["/power-bi-reports"], { replaceUrl: true });
        }
    }

    callMicrosoftOAuth() {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.ouathCredentials.clientId}&response_type=code&redirect_uri=${this.ouathCredentials.redirectURI}&scope=${this.ouathCredentials.scope}&state=${this.ouathCredentials.state}_PowerBIAccountDetail&prompt=login`);
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get({ queryParams: { powerBIRepoert: 0 } }).subscribe((t: vPowerBIReport[]) => {
            this.spin = false;
            this.powerBIReports = t;
            this.powerBIReportsGrid = new AppGrid(t, vPowerBIReport, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.powerBIReportsGrid.gridColumns.forEach(x => {
                if (this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "put" }) && this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "delete" });
                    }
                }
            })
            this.powerBIReportsGrid.componentId = this.componentName;
            this.powerBIReportsGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.powerBIReportsGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.powerBIReportsGrid.design(document.getElementById("power-bi-reports"));
        })
    }

    onEdit(powerbiReports: vPowerBIReport) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["power-bi-reports", powerbiReports.powerBIReportId]));
        redirectOnCTRL(url,this.router,event);
    }

    onDelete(powerbiReports: vPowerBIReport) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [powerbiReports.powerBIReportId] }).subscribe(data => {
                    this.spin = false;
                    this.powerBIReportsGrid.remove(powerbiReports.powerBIReportId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/power-bi-reports']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Power_BI_Report");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.powerBIReports.filter(x => x.powerBIReportName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.powerBIReportId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
                // || (x.displayOrderId == null ? "" : x.displayOrderId).toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.powerBIReportsGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.powerBIReportsGrid = null;
        }
        setTimeout(() => {
            if (this.powerBIReportsGrid == null || this.powerBIReportsGrid == undefined) {
                this.powerBIReportsGrid = new AppGrid(this.powerBIReports, vPowerBIReport, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.powerBIReportsGrid.gridColumns.forEach(x => {
                    if (this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "put" }) && this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.powerBIReportsGrid.authorize({ accessLevel: RolePermissionEnums.PowerBIReports, action: "delete" });
                        }
                    }
                })
                this.powerBIReportsGrid.componentId = this.componentName;
                this.powerBIReportsGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.powerBIReportsGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.powerBIReportsGrid.refresh("power-bi-reports");
                this.powerBIReportsGrid.design(document.getElementById("power-bi-reports"));
            }
            this.powerBIReportsGrid.search = value.target.value;
        }, 0);
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "PowerBIReportListComponent";
    }

}
