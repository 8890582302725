<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
  <div class="modal-content">
    <div class="modal-header text-left" [rxLocalisationInit]="componentName">
      <h4 class="modal-title mb-0" rxText="ProjectEventAddComponent_Title_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body" [formGroup]="projectEventSearchFormGroup" [rxLocalisationInit]="componentName"
      *ngIf="showComponent" (keyup.enter)="searchEvents()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectEventAddEditComponent_EventName_t"></label>
            <div class="col-md-8">
              <input type="text" formControlName="eventName" tabindex="1" class="form-control"
                placeholder="Enter Event Name" rxFocus>
            </div>
          </div>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectEventAddEditComponent_EventStartDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="eventStartDate" [controlTabIndex]="3"
                placeholder="Select Start Date" pickerClass="form-control"></rx-date>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectEventAddEditComponent_EventEndDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="eventEndDate" [controlTabIndex]="2"
                placeholder="Select End Date" pickerClass="form-control"></rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">End Date should be greater than Start
                Date<br /></small>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer px-0">
        <button (click)="searchEvents()" class="btn btn-success" rxText="Btn_Search_t"></button>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">
        <div class="table-responsive" (keyup.enter)="save()" *ngIf="isShowGrid">
          <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="ProjectEventListGrid"></rx-grid>
            </div>
          </div>
        </div>
        <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
          <h4>{{ErrorMsg}}</h4>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
      <div *ngIf="showSaveButton" [rxLocalisationInit]="componentName">
        <button (click)="save()" class="btn btn-success" [disabled]="(this.projectEventsMappingArray.length == 0 && this.excludedProjectEvent.eventIds.length == 0 && !this.headerBtnChk)
        || (this.totalRecords - this.excludedProjectEvent.eventIds.length - this.totalRestrictedCount == 0)" rxText="Btn_Save_t">
        </button>
      </div>
      <button (click)="hide()" class="btn btn-outline-primary ml-0" rxText="Btn_Close_t"></button>
    </div>
  </div>
</div>
