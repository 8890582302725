import {HelpSupportBase,HelpSupportUserRoleBase,} from '@app/database-models'
//Generated Imports
import { HelpSupportUserRole } from './help-support-user-role'
import { propArray } from '@rxweb/reactive-form-validators'
export class HelpSupport extends HelpSupportBase 
{

    constructor() {
        super();
        this.published = false;
    }

    @propArray(HelpSupportUserRole)
    helpSupportUserRoles: HelpSupportUserRole[]

    //#region Generated Reference Properties
//#region helpSupportUserRoles Prop
//   helpSupportUserRoles: HelpSupportUserRoleBase[];
//#endregion helpSupportUserRoles Prop

//#endregion Generated Reference Properties











}