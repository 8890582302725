import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BannedIpAddressBase {

//#region bannedIpAddressID Prop
        @prop()
        bannedIpAddressID : number;
//#endregion bannedIpAddressID Prop


//#region address Prop
        @required()
        @maxLength({value:50})
        @trim()
        @notEmpty()
        address : string;
//#endregion address Prop


//#region comment Prop
        @maxLength({value:500})
        @trim()
        comment : string;
//#endregion comment Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @required()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop

}