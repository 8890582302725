declare const $: any;
export function GanttChart(data:any[]) {
  $(".gantt").gantt({
    source: data,
    navigate: "scroll",
    maxScale: "hours",
    onItemClick: function (data) {
    },
    itemsPerPage: 6
  });


}

