import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Event, vEventTypeLookUp, vEventOwnerLookUp, vBookingLookup, EventBooking } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { dateCompareValidation } from 'src/app/domain/common-logic/common-logic';
import { BedRequestComponent } from "src/app/components/shared/global/bed-request/bed-request.component";
import { EventTypeEnum } from "src/app/enums/event-type.enum";
import { BLOB_BASE,BLOB_CONTAINER } from "src/app/domain/system-constant";
import { v4 as uuidv4 } from "uuid";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@http({

    path: "api/Events",
})
export class AbstractEvent extends CoreComponent {

    isShowGrid: boolean;
    isShowChart: boolean;
    isGridLayout: boolean;
    isNoRecordFound: boolean = true;
    eventFormGroup: IFormGroup<Event>
    event: Event;
    spin: boolean = false;
    modalView: ModalView;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    router: Router;
    id: number;
    eventTypeEnum = EventTypeEnum
    fileViewModel: FileInputViewModel;
    fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
    selectedTab: string = "event-info";
    dateError: boolean = false;
    bookingError: boolean;
    startDate: Date;
    localizationData : any;
    endDate: Date;
    eventLookup: any = {
        eventTypeLookUp: vEventTypeLookUp,
        eventOwnerLookUp: vEventOwnerLookUp,
        event: Event
    }
    bookingLookup:vBookingLookup[];
    showDropdown:boolean;
    validField: string[] = ["eventName","eventDescription","eventDate","eventIndicator","budget","eventFormat","location"];
    breakLoop: boolean;
    tabChange: boolean = false;
    constructor(private blobState: BlobUploadsViewStateService) {
        super();

        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.fileViewModel = new FileInputViewModel();
        this.fileViewModel.fileMode = FileTypeEnums.Image;
        this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
        this.fileViewModel.isMultipart = false;

    }



  async updateFiles($event: FileOutputViewModel) {
    // if($event.)

    this.fileOutputViewModel = $event;
    this.eventFormGroup.patchValue({
      fileData: $event.fileBaseString,
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      pictureID: 0,
      isRemovedEventPicture: true,
      //blobFileName: uploadedFileName,
      blobPath: BLOB_BASE.EventFiles,
      size: $event.files.size
      // ,
      // displayImage: !$event.displayImage
    });
  }
 
    deleteImage() {

        this.eventFormGroup.patchValue({
            fileData: null,
            blobFileName:null,
            fileExtention: null,
            fileName: null,
            displayImage: false,
            isRemovedEventPicture: true
        });

    }
    async uploadExcelToBlob(file: any, fileExtention: string): Promise<string> {

      let fileUploadRes = [];
      let newGUID = uuidv4();
      let fileName = BLOB_BASE.ExcelSheet + `${newGUID}_${new Date().toISOString()}.${fileExtention}`;
      var res = await this.blobState.uploadItems(file, fileName);
  
      let url = res._response.request.url;
      let uploadedFileName = BLOB_BASE.ExcelSheet + decodeURIComponent(
        url.split("?")[0].split("/").pop()
      );
  
      // IF SUCCEED TO UPLOAD
      if (res && (res._response.status == 200 || res._response.status == 201)) {
        if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
      } else {
        // IF FAILED TO UPLOAD
        console.log("Failed to upload file to blob from client!", res);
        this.toastr.error(
          "Error occured while uploading file! Please try again."
        );
        if (uploadedFileName != undefined) {
          this.modalView
            .show(BedRequestComponent, {
              validationMessages: JSON.stringify(
                uploadedFileName
              ),
              headerMessage: "Image failed to upload:",
            })
            .then(() => { });
        }
      }
      return uploadedFileName;
    }

  async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);

    let url = res._response.request.url;
    let uploadedFileName = blobPath + decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );

    // IF SUCCEED TO UPLOAD
    if (res && (res._response.status == 200 || res._response.status == 201)) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined) {
        this.modalView
          .show(BedRequestComponent, {
            validationMessages: JSON.stringify(
              uploadedFileName
            ),
            headerMessage: "Image failed to upload:",
          })
          .then(() => { });
      }
    }
    return uploadedFileName;
  }

    searchBookings() {
        var json = JSON.parse("{\"bookingStartDate\":0,\"bookingEndDate\":0,\"bookingType\":\"\",\"bookingName\":\"\",\"responsiblePerson\":\"\"}");
        json['bookingStartDate'] = this.eventFormGroup.controls.eventDate.value;
        json['bookingEndDate'] = this.eventFormGroup.controls.eventEndDate.value;

        if (this.eventFormGroup.controls.eventDate.value == undefined) {
            this.bookingError = true;
        }
        else {
            this.bookingError = false;
            this.startDate = json['bookingStartDate'];
            this.endDate = json['bookingEndDate'];
            if (dateCompareValidation(this.startDate, this.endDate) == true) {
                this.dateError = true;
            } else {
                this.dateError = false;
                this.spin = true;
                this.bookingLookup = [];
                this.post({ path: 'api/SearchEvent/BookingSearch', body: { query: JSON.stringify(json) } }).subscribe((response: any) => {
                    this.spin = false;
                    let jsonResponse: any[] = JSON.parse(response);
                    this.bookingLookup = JSON.parse(response);
                    this.showDropdown = true;
                });
            }
        }


    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
        })
    }
}
