import {WatchAttributeMappingBase,ProductBase,} from '@app/database-models'
import { WatchAttribute } from './watch-attribute';
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class WatchAttributeMapping extends WatchAttributeMappingBase 
{




//#region Generated Reference Properties
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties

@prop()
watchAttribute:WatchAttribute;










}