import {vNotifyTeamLookUpBase,} from '@app/database-models'
//Generated Imports
export class vNotifyTeamLookUp extends vNotifyTeamLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}