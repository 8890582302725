import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCountryRecordBase {

//#region countryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'countryId', keyColumn: true})
        countryId : number;
//#endregion countryId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region allowsRegistration Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'allowsRegistration', keyColumn: false})
        allowsRegistration : boolean;
//#endregion allowsRegistration Prop


//#region allowsBilling Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'allowsBilling', keyColumn: false})
        allowsBilling : boolean;
//#endregion allowsBilling Prop


//#region allowsShipping Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'allowsShipping', keyColumn: false})
        allowsShipping : boolean;
//#endregion allowsShipping Prop


//#region twoLetterISOCode Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'twoLetterISOCode', keyColumn: false})
        twoLetterISOCode : string;
//#endregion twoLetterISOCode Prop


//#region threeLetterISOCode Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'threeLetterISOCode', keyColumn: false})
        threeLetterISOCode : string;
//#endregion threeLetterISOCode Prop


//#region numericISOCode Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'numericISOCode', keyColumn: false})
        numericISOCode : number;
//#endregion numericISOCode Prop


//#region subjectToVAT Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'subjectToVAT', keyColumn: false})
        subjectToVAT : boolean;
//#endregion subjectToVAT Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : boolean;
//#endregion displayOrder Prop


//#region dutyPer Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'dutyPer', keyColumn: false})
        dutyPer : number;
//#endregion dutyPer Prop


//#region insurancePer Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'insurancePer', keyColumn: false})
        insurancePer : number;
//#endregion insurancePer Prop


//#region allowPOBShipping Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'allowPOBShipping', keyColumn: false})
        allowPOBShipping : boolean;
//#endregion allowPOBShipping Prop


//#region domain Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'domain', keyColumn: false})
        domain : number;
//#endregion domain Prop


//#region regionId Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'regionId', keyColumn: false})
        regionId : number;
//#endregion regionId Prop


//#region sEName Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'sEName', keyColumn: false})
        sEName : string;
//#endregion sEName Prop

}