import {vProductvariantOrderStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductvariantOrderStatusLookUp extends vProductvariantOrderStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}