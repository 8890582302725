import {vChannelTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vChannelTypeLookUp extends vChannelTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}