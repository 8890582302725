import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class POSBrandBase {

//#region brandId Prop
        @prop()
        brandId : number;
//#endregion brandId Prop


//#region brandName Prop
        @maxLength({value:500})
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        @trim()
        brandName : string;
//#endregion brandName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region isActive Prop
        @prop({defaultValue:false})     
        isActive : boolean;
//#endregion isActive Prop

}