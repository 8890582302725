import { prop, required } from "@rxweb/reactive-form-validators";
import { StatusEnum } from "src/app/enums/status.enum";

export class LegalAgreementCounterPartyMappingBase {

    //#region legalAgreementCounterPartyMappingId Prop
    @prop({ defaultValue: 0 })
    legalAgreementCounterPartyMappingId: number;
    //#endregion legalAgreementCounterPartyMappingId Prop

    //#region legalAgreementId Prop
    @prop()
    @required()
    legalAgreementId: number;
    //#endregion legalAgreementId Prop

    //#region counterPartyId Prop
    @prop()
    @required()
    counterPartyId: number;
    //#endregion counterPartyId Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    @required()
    statusId: number;
    //#endregion statusId Prop

    //#region counterPartySupplierId Prop
    @prop()
    @required()
    counterPartySupplierId: number;
    //#endregion counterPartySupplierId Prop

}