import { maxLength, prop } from "@rxweb/reactive-form-validators";

export class LeaveFlightMappingBase
{



    //#region LFM_MapppingID Prop
    @prop()
    lfM_MapppingID : number;
    //#endregion LFM_MapppingID Prop


    //#region masterId Prop
    //@range({minimumNumber:0,maximumNumber:2147483647})
    //@required()
    @prop()
    masterId : number;
    //#endregion masterId Prop


    //#region FlightFrom Prop
    @prop()
    flightFrom : number;
    //#endregion FlightFrom Prop


     //#region FlightTo Prop
     @prop()
     flightTo : number;
     //#endregion FlightTo Prop


     @prop()
     departureDate :Date; 
 
     @prop()
     arrivalDate :Date; 

    @prop()
    classOfService :number; 

    @prop()
    flightFromName:string;

    @prop()
    flightToName:string;
    
}

