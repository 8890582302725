import { vBespokeReportSalesPersonLookUpBase } from '@app/database-models';


//Generated Imports
export class vBespokeReportSalesPersonLookUp extends vBespokeReportSalesPersonLookUpBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}