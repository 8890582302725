import {vCollectionInspirationBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';
@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 12, headerTitle: "Action"
})
export class vCollectionInspiration extends vCollectionInspirationBase 
{

    @gridColumn({style: { width: "25%", "text-align": "initial" },allowSearch:false, class: ["text-left"], configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'CollectionInspirationListComponent_Image_gh', keyColumn: false })
    imageURL1: number;


//#region Generated Reference Properties

//#endregion Generated Reference Properties










}