import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSalesChannelTypeLookUpBase {

//#region salesChanneTypelId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'salesChanneTypelId', keyColumn: true})
        salesChanneTypelId : number;
//#endregion salesChanneTypelId Prop


//#region salesChannelTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'salesChannelTypeName', keyColumn: false})
        salesChannelTypeName : string;
//#endregion salesChannelTypeName Prop

}