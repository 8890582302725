import { HolidayListBase, HolidayMappingBase, } from '@app/database-models'
import { propArray, prop, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class HolidayList extends HolidayListBase {




  //#region Generated Reference Properties
  //#region holidayMappings Prop
  @propArray(HolidayMappingBase)
  holidayMappings: HolidayMappingBase[];
  //#endregion holidayMappings Prop

  //#endregion Generated Reference Properties

  @prop()
  @required()
  countryIds: string;

  @prop()
  files: File









}
