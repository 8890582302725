import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LeaveTypeBase {

//#region leaveId Prop
        @prop()
        leaveId : number;
//#endregion leaveId Prop


//#region leaveTypeName Prop
        @required()
        @maxLength({value:50})
        @trim()
        @notEmpty()
        leaveTypeName : string;
//#endregion leaveTypeName Prop


//#region typeColor Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:100})
        typeColor : string;
//#endregion typeColor Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}