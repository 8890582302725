import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneCutTypeRecordBase {

//#region cutTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'cutTypeId', keyColumn: true})
        cutTypeId : number;
//#endregion cutTypeId Prop


//#region cutTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'cutTypeName', keyColumn: false})
        cutTypeName : string;
//#endregion cutTypeName Prop

}