import { MindMapDataTypeEnum, MindMapNodeType } from 'src/app/enums/mind-map-data-type-enum';
import { RxHttp } from '@rxweb/http';
import { MindMapData } from './mind-map-data';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';

export class RootOrderMindMap {

    mindMapData: any = null;

    rootEntityName: string = '';

    mindMap;
    mindMapDataObj: MindMapData;

    httpClient: any;

    constructor(private mindMapService: MindMapService) {
        this.httpClient = new RxHttp();
        this.mindMapDataObj = new MindMapData();
        Window['RootOrderMindMap'] = this;
    }

    async setupOrderMindMap(mindMapData, rootEntityName) {
        this.mindMapData = mindMapData;
        this.rootEntityName = rootEntityName;
        if (this.mindMapData != null) {
            await this.createMindMap();
        }
    }


    async createMindMap() {
        var productPiecesData = await this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.OrderProductPieceData, "order-product-piece-templates.json", "OrderProductPiece", this.mindMapService.graphId, MindMapDataTypeEnum.PRODUCTPIECES, 0);

        var childrenArray = [];
        if (this.mindMapData.OrderTotal != undefined) {
            childrenArray.push({
                id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDERTOTAL}_0_${MindMapNodeType.HEADER}`,
                color: '#000000',
                topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDERTOTAL}_0_${MindMapNodeType.HEADER}` + '\')\">Order Total</span>',
                direction: 'left',
                expanded: false,
                selectedType: 'ORDERTOTAL',
                backgroundColor: 'rgba(250, 250, 250, 1)',
                children: [
                    {
                        id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDERTOTAL}_0_${MindMapNodeType.LIST}`,
                        color: '#000000',
                        topic: this.mindMapData.OrderTotal,
                        direction: 'left',
                        selectedType: 'ORDERTOTALLIST',
                        backgroundColor: 'rgba(250, 250, 250, 1)',
                        children: []
                    },
                ]
            });
        }

        if (this.mindMapData.OrderProductPieceData.length > 0) {
            childrenArray.push({
                id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRODUCTPIECES}_${RolePermissionEnums.Pieces}_${MindMapNodeType.HEADER}`,
                color: '#000000',
                topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRODUCTPIECES}_${RolePermissionEnums.Pieces}_${MindMapNodeType.HEADER}` + '\')\">Product/Pieces (' + this.mindMapData.OrderProductPieceData.length + ')</span>',
                direction: 'right',
                expanded: false,
                selectedType: 'PRODUCTPIECES',
                backgroundColor: 'rgba(250, 250, 250, 1)',
                children: productPiecesData != '' ? [
                    {
                        id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRODUCTPIECES}_${RolePermissionEnums.Pieces}_${MindMapNodeType.LIST}`,
                        color: '#000000',
                        topic: productPiecesData,
                        direction: 'right',
                        selectedType: 'PRODUCTPIECESLIST',
                        backgroundColor: 'rgba(250, 250, 250, 1)',
                        children: []
                    },
                ] : []
            });
        }

        this.mindMapService.mindMapObject = {
            format: 'nodeTree',
            data: {
                id: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.ROOT}`,
                isroot: true,
                topic: '<h2 class="m-0" style="font-size:20px!important"><a target="_blank" href="/orders/' + this.mindMapData.RootEntityId +'?activeTab=sales-order"><b>Order ID: ' + this.rootEntityName + '</b></a></h2>',
                selectedType: "",
                backgroundColor: 'rgba(250, 250, 250, 1)',
                children: childrenArray
            }
        };
        return;

    }
}