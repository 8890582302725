import { SettingBase, } from '@app/database-models'
//Generated Imports
import { propArray } from '@rxweb/reactive-form-validators'
import { AllSettingViewModel } from '../../view-model/all-setting-view-model'
export class Setting extends SettingBase {




    //#region Generated Reference Properties
    @propArray()
    allSettingViewModels: AllSettingViewModel[]
    //#endregion Generated Reference Properties





}
