<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" *rxMultilingual="">
  <div class="col-md-8 my-1" [rxLocalisationInit]="componentName">
    <h2 class="m-0 page-title mb-2" rxText="Label_RiskMenu_Title_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <!-- <li class="breadcrumb-item active">Forms</li> -->
      <li class="breadcrumb-item active" rxText="Label_RiskMenu_Title_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5 rxText="Label_RiskMenu_Title_t"></h5>
      <h5 id="risk-form" class="ml-1" [innerHTML]="riskForm" *ngIf="riskForm!=null && riskForm!=undefined"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="gemfieldRiskSearchFormGroup"
      (keyup.enter)="onKeyPress()">
      <div class="d-flex align-items-center mb-4">
        <button class="btn btn-success" (click)="addNewRisk()" rxText="Btn_Add_New_Risk_t"></button>
        <div class="d-flex align-items-center mt-0 ml-auto">
          <div class="col text-right">
            <ng-container [rxLocalisationInit]="componentName" *ngIf="showExportToExcel">
              <button class="btn btn-primary" (click)="exportToExcel()"
                rxText="Btn_Export_to_Excel_t"></button>
              <button class="btn btn-primary ml-1" (click)="pdfRiskreport()"
                rxText="Btn_Generate_Risk_Report_t">
              </button>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row mb-1">
            <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="RiskMenuDetailListComp_riskId_t"></label>
            <div class="col-md-8 ">
              <div class="d-flex">
                <input type="text" class="form-control mr-3" tabindex="1" formControlName="riskId"
                       rxPlaceholder="RiskMenuDetailListComp_riskId_p" (keyup.enter)="goToRisk()"/>

                <button (click)="goToRisk()" class="btn btn-success search-result-btn" [rxLocalisationInit]="componentName"><span rxText="Btn_Go_t"></span></button>
              </div>
              <small class="form-text text-danger" *ngIf="riskIdError==true" [rxLocalisationInit]="componentName"><span rxText="Label_Valid_Risk_t"></span><br /></small>
            </div>
          </div>
          <div class="form-group row ">
            <label class="col-md-4 col-lg-3 col-xl-4 mt-1 col-form-label"
              rxText="RiskMenuDetailListComp_GemfieldsGroupCompany_t"></label>
            <div class="col-md-8 mt-2" [rxSelectExtended]="_this">
              <!-- Changes as per Ticket - 215251 -->
              <!-- <rx-select [(source)]="gemfieldRiskSearchLookUp.gemfieldGroupCompanyLookUp"
                                mainClass="form-control" [controlTabIndex]="3" formControlName="gemfieldGroupCompanyId"
                                [selectPlaceholder]="'Select Gemfields Group'"
                                (changed)="onCompanyChange($event.item == undefined ? 0 : $event.item.key)" [keyValueProps]="['company','supplierId']">
                            </rx-select> -->
              <rx-select [(source)]="gemfieldRiskSearchLookUp.gemfieldGroupCompanyLookUp" #rxSelect
                mainClass="form-control" [controlTabIndex]="3" formControlName="gemfieldGroupCompanyId"
                [selectPlaceholder]="'Select Gemfields Group'" [keyValueProps]="['company','supplierId']">
              </rx-select>
              <small class="form-text text-danger" *ngIf="newRiskAdd"
                [class.d-block]="gemfieldRiskSearchFormGroup.controls.gemfieldGroupCompanyId.errors">{{gemfieldRiskSearchFormGroup.controls.gemfieldGroupCompanyId.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="RiskMenuDetailListComp_RiskCategory_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- Changes as per Ticket - 215251 -->
              <!-- <rx-select [(source)]="gemfieldRiskSearchLookUp.riskCategoryLookUp" mainClass="form-control"
                                [controlTabIndex]="5" formControlName="riskCategoryId"
                                [selectPlaceholder]="'Select Strategic Risk Category'"
                                (changed)="onRiskCategoryChange($event.item == undefined ? 0 : $event.item.key)"
                                [keyValueProps]="['riskCategoryName','riskCategoryId']"></rx-select> -->
              <rx-select [(source)]="gemfieldRiskSearchLookUp.riskCategoryLookUp" #rxSelect mainClass="form-control"
                [controlTabIndex]="5" formControlName="riskCategoryId"
                [selectPlaceholder]="'Select Strategic Risk Category'"
                [keyValueProps]="['riskCategoryName','riskCategoryId']"></rx-select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="RiskMenuDetailListComp_LocalRiskOwner_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="gemfieldRiskSearchLookUp.localRiskOwnerLookUp" #rxSelect mainClass="form-control"
                [controlTabIndex]="2" formControlName="localRiskOwnerId" [selectPlaceholder]="'Select Local Risk Owner'"
                [keyValueProps]="['fullName','localRiskOwner']"></rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="RiskMenuDetailListComp_BoardRiskOwner_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="gemfieldRiskSearchLookUp.boardRiskOwnerLookUp" #rxSelect mainClass="form-control"
                [controlTabIndex]="4" formControlName="boardRiskOwnerId" [selectPlaceholder]="'Select Board Risk Owner'"
                [keyValueProps]="['fullName','boardRiskOwner']"></rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="RiskMenuDetailListComp_SpecificRiskName_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="gemfieldRiskSearchLookUp.specificRiskLookUp" #rxSelect mainClass="form-control"
                [controlTabIndex]="6" formControlName="specificRiskId" [selectPlaceholder]="'Select Risk Type'"
                [keyValueProps]="['specificRiskName','specificRiskId']"></rx-select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-5 col-lg-4 col-xl-5 col-form-label"
              rxText="RiskMenuDetailListComponent_LastUpdatesFrom_t"></label>
            <div class="col-md-7">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date pickerClass="" class="w-100" rxFocus showAddon="true" formControlName="lastupdatesFrom"
                    [controlTabIndex]="7" pickerClass="form-control">
                  </rx-date>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-5 col-lg-4 col-xl-5 col-form-label"
              rxText="RiskMenuDetailListComponent_LastUpdatesTo_t"></label>
            <div class="col-md-7">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date class="w-100" showAddon="true" formControlName="lastupdatesTo" [controlTabIndex]="8"
                    pickerClass="form-control">
                  </rx-date>
                  <small class="form-text text-danger" *ngIf="dateError==true" [rxLocalisationInit]="componentName"><span
                      rxText="RiskMenuDetailListComponent_DateError_t"></span><br /></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100 mt-1"></div>
        <div class="col text-right">
          <button (click)="onKeyPress()" class="btn btn-success search-result-btn" rxText="Btn_Search_t"></button>
          <button (click)="resetSearch()" class="btn btn-primary ml-1" rxText="Btn_Reset_t"></button>
        </div>
      </div>
    </div>
  </div>

  <div class="search-result-container">
    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showGrid" [rxLocalisationInit]="componentName">
      <div class="ibox-title bg-white d-flex flex-wrap pr-5">
        <h5 rxText="Label_Search_Result_t"></h5>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="row mx-0" *ngIf="isShowGrid">
          <div class="col-md-12">
            <span class="text" [innerHTML]="transformYourHtml(noticeData)"></span>
            <div class="ibox-tools ibox-position" style="top:50px !important;position: absolute !important;">
              <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
                <i class="fa fa-cog"></i>
              </a>
              <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
                (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
                [gridColumnConfig]="gemfieldRiskGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
                [moduleID]="moduleID" [selectedTab]="selectedTab">
              </app-system-table-setting>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="table-responsive" *ngIf="isShowGrid">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="gemfieldRiskGrid"></rx-grid>
            </div>
          </div>
          <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-result-container">
    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showAddRiskGrid" [rxLocalisationInit]="componentName">
      <div class="ibox-title bg-white d-flex flex-wrap pr-5">
        <h5 rxText="Label_Add_Gemfields_Risks_t"></h5>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="row mx-0">
          <div class="col-md-12">
            <span class="text" [innerHTML]="transformYourHtml(noticeData)"></span>
          </div>
          <div class="tab-content">
            <div class="table-responsive" *ngIf="isShowAddRiskGrid">
              <div id="gemfieldRiskAddGrid" class="dataTables_wrapper pb-0 dt-bootstrap4">
                <!-- <rx-grid [design]="gemfieldRiskAddGrid"></rx-grid> -->
              </div>
            </div>
            <div class="ibox-title bg-white" *ngIf="isNoRiskAddRecordFound">
              <h4>No Record Found</h4>
            </div>
          </div>
          <div class="row mx-0 mt-auto w-100">
            <div class="hr-line-dashed border-top w-100 mt-1"></div>
            <div class="col text-right pr-0">
              <button [disabled]="selectedRiskArray.length==0" (click)="saveSelectedRisk()"
                class="btn btn-success mr-1 search-result-btn" rxText="Btn_Save_All_t"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
