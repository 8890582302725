import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBoutiquePictureRecordBase {

//#region boutiquePictureId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'boutiquePictureId', keyColumn: true})
        boutiquePictureId : number;
//#endregion boutiquePictureId Prop


//#region boutiqueId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'boutiqueId', keyColumn: false})
        boutiqueId : number;
//#endregion boutiqueId Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region fileName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'fileName', keyColumn: false})
        fileName : string;
//#endregion fileName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}