import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPurchasedFromLookUpBase {

//#region purchasedFromId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'purchasedFromId', keyColumn: true})
        purchasedFromId : number;
//#endregion purchasedFromId Prop


//#region purchasedFromName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'purchasedFromName', keyColumn: false})
        purchasedFromName : string;
//#endregion purchasedFromName Prop

}