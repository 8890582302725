import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    allowSorting: false,
    style:{"flex-shrink": 12,"text-align": "initial"}, class: ["ownershipAction"],
    configuredTemplate: { templateName: 'corporateEditDelete' }, columnIndex: 14, headerTitle: "Action"
})
export class OwnerMappingViewModelBase {

    //#region ownershipId Prop
    @gridColumn({ visible: false, parameter:false,columnIndex: 1, allowSorting: true, headerKey: 'ownershipId', keyColumn: true })
    ownershipId: number;
    //#endregion ownershipId Prop

    //#region companyId Prop
    @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'companyId', keyColumn: false })
    companyId: number;
    //#endregion companyId Prop

    //#region customerId Prop
    @gridColumn({ visible: false, columnIndex: 3, allowSorting: true, headerKey: 'customerId', keyColumn: false })
    customerId: number;
    //#endregion totalCount Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 3, allowSorting: true, headerKey: 'totalCount', keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    //#region expand Prop
    @gridColumn({style:{"flex-shrink": 12, "text-align": "initial"}, configuredTemplate: { templateName:"dashboardExpand" }, class: ["ownershipAction"], visible: true, allowSorting: false, columnIndex:4, headerKey: 'OwnershipMappingListComponent_Expand_gh', keyColumn: false})
    expand : string;
    //#endregion expand Prop

    //#region companyName Prop
    @gridColumn({ style:{"flex-grow": 14, "text-align": "initial"}, class: ["ownership"], parameter:false, configuredTemplate: { templateName:"corporateGroupLink" }, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'OwnershipMappingListComponent_CompanyName_gh', keyColumn: false })
    companyName: string;
    //#endregion companyName Prop

    //#region userName Prop
    @gridColumn({ style:{"flex-grow": 14, "text-align": "initial"}, class: ["ownership"], parameter:false, configuredTemplate: { templateName:"reDirectPeopleAndTooltip" }, visible: true, name: "userName", columnIndex: 6, allowSorting: true, headerKey: 'OwnershipMappingListComponent_PersonName_gh', keyColumn: false })
    userName: string;
    //#endregion userName Prop

    //#region startDate Prop
    @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 7, allowSorting: true, headerKey: 'OwnershipMappingListComponent_StartDate_gh', keyColumn: false })
    startDate: Date;
    //#endregion startDate Prop

    //#region stStartDate Prop
    @gridColumn({ style:{"flex-grow": 14, "text-align": "initial"}, class: ["ownership"], parameter:false, visible: true, columnIndex: 8, allowSorting: true, headerKey: 'OwnershipMappingListComponent_StartDate_gh', keyColumn: false })
    stStartDate: string;
    //#endregion stStartDate Prop

    //#region endDate Prop
    @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 9, allowSorting: true, headerKey: 'OwnershipMappingListComponent_EndDate_gh', keyColumn: false })
    endDate: Date;
    //#endregion endDate Prop

    //#region stEndDate Prop
    @gridColumn({ style:{"flex-grow": 14, "text-align": "initial"}, class: ["ownership"], parameter:false, visible: true,  columnIndex: 10, allowSorting: true, headerKey: 'OwnershipMappingListComponent_EndDate_gh', keyColumn: false })
    stEndDate: string;
    //#endregion stEndDate Prop

    //#region ownershipPerc Prop
    @gridColumn({ style:{"flex-grow": 14, "text-align": "initial"}, class: ["ownership"], parameter:false, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'OwnershipMappingListComponent_OwnershipPerctage_gh', keyColumn: false })
    ownershipPerc: number;
    //#endregion ownershipPerc Prop

    //#region detail Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 12, allowSorting: true, headerTitle: 'Detail', keyColumn: false })
    detail: string;
    //#endregion detail Prop

    //#region totalOwnershipPerc Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 13, allowSorting: true, headerTitle: 'Total Ownership %', keyColumn: false })
    totalOwnershipPerc: number;
    //#endregion totalOwnershipPerc Prop
    
    @prop()
    contactPermissionRequired: boolean;

    @prop()
    introducesBy: string;

    @prop()
    isUnwelcome: boolean;
    supplierId:number;
}