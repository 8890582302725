import { vShippingCountryLookUpBase } from '../database-models/v-shipping-country-look-up-base';

//Generated Imports
export class vShippingCountryLookUp extends vShippingCountryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}