import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductGemstoneCutMappingBase {

//#region ProductVariantGemstoneCutId Prop
        @prop()
        productVariantGemstoneCutId : number;
//#endregion ProductVariantGemstoneCutId Prop


//#region ProductVariantId Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion ProductVariantId Prop


//#region GemstoneCutId Prop
        @prop()
        gemstoneCutId : number;
//#endregion GemstoneCutId Prop

}