<div class="row wrapper white-bg page-heading py-2 align-items-center" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_Report_t"></h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/dashboard']" rxText="Label_Home_t"></a>
            </li>
            <li class="breadcrumb-item active" rxText="Label_Reports_t"></li>
            <li class="breadcrumb-item active" rxText="Label_HSE_GINA_Report_t"></li>
        </ol>
    </div>
    <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
    </div>
</div>

<div class="wrapper wrapper-content" *ngIf="showComponent" [rxLocalisationInit]="componentName" [rxSpinner]="spin">
    <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
            <h5 rxText="Label_HSE_GINA_Report_t"></h5>
            <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x"></i>
            </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="hseReportSearchFormGroup"
             (keyup.enter)="onKeyPress()">
            <div class="dataTables_filter  d-flex flex-wrap align-items-center">
                <div class=" ml-auto mb-4" role="group" aria-label="Basic example">
                    <ng-container *ngIf="this.hseReportViewModel.length > 0" [rxLocalisationInit]="componentName">
                        <button class="btn btn-primary" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t"></button>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="HseReportListComponent_FromDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" [controlTabIndex]="1" rxFocus formControlName="startDate"
                                placeholder="Select Start Date"  (onSelected)="validateDates()"
                                pickerClass="form-control"></rx-date>
                            <small class="form-text text-danger"
                                *ngIf="hseReportSearchFormGroup.controls.startDate.errors">{{hseReportSearchFormGroup.controls.startDate.errors.required.message}}<br /></small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-4 col-xl-4 col-form-label" 
                            rxText="HseReportListComponent_GroupEntity_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag #rxTag [(source)]="hseLookups.hseIANSupplierDetail" [controlTabIndex]="3"
                                formControlName="supplierId" mainClass="form-control" [keyValueProps]="['value','id']"
                                [isSort]="false" placeholder="Select Gemfields Group Entity"></rx-tag>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="HseReportListComponent_ToDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" [controlTabIndex]="2"  (onSelected)="validateDates()"
                                formControlName="endDate" placeholder="Select End Date" pickerClass="form-control">
                            </rx-date>
                            <small class="form-text text-danger"
                                *ngIf="dateError==true && hseReportSearchFormGroup.controls.endDate.valid">To Date
                                should be greater than
                                From Date<br /></small>
                            <small class="form-text text-danger"
                                *ngIf="dateRangeError==true && hseReportSearchFormGroup.controls.endDate.valid" rxText="HseReportListComponent_DateRangeValidation_t"></small>
                            <small class="form-text text-danger"
                                *ngIf="hseReportSearchFormGroup.controls.endDate.errors">{{hseReportSearchFormGroup.controls.endDate.getError('required').message}}<br /></small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" style="max-width:fit-content;"
                            rxText="HseReportListComponent_ShowCompanies_t"></label>
                        <div class="col-md-4 col-lg-3 col-xl-3 d-flex align-items-center"
                            style="max-width:fit-content;">
                            <fieldset>
                                <label class="switch mb-0"><input formcontrolname="isTimeline"
                                        (change)="changeReportType($event)" type="checkbox" /><span
                                        class="slider round"></span></label>
                            </fieldset>
                        </div>
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" style="max-width:fit-content;"
                            rxText="HseReportListComponent_ShowMonths_t"></label>
                    </div>
                </div>

            </div>
            <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100 mt-1"></div>
                <div class="col text-right">
                    <button class="btn btn-success mr-1 search-result-btn" (click)="onKeyPress()" rxText="Btn_Search_t"></button>
                    <button (click)="resetSearch()" class="btn btn-primary" rxText="Btn_Reset_t"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="search-result-container">
        <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showGrid" [rxLocalisationInit]="componentName">
            <div class="ibox-title bg-white d-flex flex-wrap pr-5">
                <h5 rxText="Label_Search_Result_t"></h5>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="isShowGridTable1">
                <div class="tab-content">
                    <div class="table-responsive" *ngIf="isShowGrid">
                        <div id="hseReportGrid" class="dataTables_wrapper pb-0 dt-bootstrap4">
                        </div>

                    </div>
                    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                        <h4>No Record Found</h4>
                    </div>
                </div>
            </div>

            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="isShowGridTable2">
                <div class="tab-content">
                    <div class="table-responsive" *ngIf="isShowGridGrievance">
                        <div id="hseGrievanceReportGrid" class="dataTables_wrapper pb-0 dt-bootstrap4">
                        </div>

                    </div>
                    <div class="ibox-title bg-white" *ngIf="isNoRecordFoundGrievance">
                        <h4>No Record Found</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
