import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, date, prop } from '@rxweb/reactive-form-validators';

import { CoreComponent } from '@rxweb/angular-router';
import { vCountryLookUp, vCurrencyRecord, vCountryRecord, vTaxRate, vStateProvinceLookUp } from '@app/models';
import { List } from '@rxweb/generics';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { vCurrencyLookUp } from 'src/app/models/extended-models/v-currency-look-up';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { CLIENT_URL, BLOB_CONTAINER, BLOB_PUBLIC_URI, DEFAULT_BLOB_IMAGE_URL, BLOB_PUBLIC_CONTAINER } from "src/app/domain/system-constant";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
@multilingual("PriceLabelComponent")

@Component({
  selector: 'app-print-evaluation',
  templateUrl: './print-evaluation.component.html'
})
export class PrintEvaluationComponent extends CoreComponent implements OnInit, OnDestroy {

  @Input() pieceNumber: string;
  @Input() productName: string;
  @Input() productSpecification: string;
  @Input() price: number;
  @Input() imageUrl: string;
  @Input() primaryCurrenyId: number;
  @Input() primaryExchangeRate: number;

  actualPrice: number;
  priceEvaluationSearchModel: PriceEvaluationFilterModel;
  priceEvaluationFormGroup: IFormGroup<PriceEvaluationFilterModel>

  subscription: any;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  modalView: ModalView;
  spin: boolean = false;
  showComponent: boolean = false;
  currencyLookUp1: any;
  countryLookup1: any;
  taxRateLookup: List<vTaxRate>;
  currencyLookUp: List<vCurrencyRecord>;
  countryLookup: List<vCountryRecord>;
  stateProvinceLookUp1: any;
  stateProvinceLookUp: List<vStateProvinceLookUp>;
  priceLabelLookups: any = {
    currencyLookUp: vCurrencyLookUp,
    countryLookup: vCountryLookUp,
    taxRateLookup: vTaxRate,
    stateProvinceLookUp: vStateProvinceLookUp
  }
  @Input() hide: Function;
  countryName: string;
  currency: string;
  countryId: number;
  currencyId: number;
  showSelectOption: boolean = false;
  currencyCode: string;
  value: number = 0;
  n_array: number[] = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
  priceToWords: string = "";
  isPrintEvaluation: boolean = false;
  ImageofCompanyName: string;
  logoImage: string;
  certificateCss: string;
  newLogoUrl: string;
  fabHallmarkUrl: string;
  titleClose: string;
  stateProvinceName: string;
  stateProvinceId: number;
  showStateProvince: boolean = true;
  includingVATPrice: number;
  includingVATPriceToWords: string = "";
  get componentName(): string {
    return "PriceLabelComponent";
  }


  _this;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, router: Router, modelView: ModalView,private location : PlatformLocation) {
    super();

    this.router = router;
    this.modalView = modelView;
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this._this = this;
    location.onPopState(() => this.hide());
  }


  ngOnInit() {
    this.spin = true;
    this.titleClose = getLocalizedValue("Title_Close");
    this.lookup([{ propName: "countryLookup", path: "api/MasterLookups/CountryRecord" },
    { propName: "currencyLookUp", path: "api/MasterLookups/CurrencyRecord" },
    { propName: "taxRateLookup", path: "api/MasterLookups/TaxRateLookUp" },
    { propName: "stateProvinceLookups", path: "api/MasterLookups/StateProvinceLookups" }
    ]).subscribe(response => {

      this.spin = false;
      this.isPrintEvaluation = false;
      this.priceLabelLookups = response;
      this.currencyLookUp1 = this.priceLabelLookups.currencyLookUp;
      this.countryLookup1 = this.priceLabelLookups.countryLookup;
      this.taxRateLookup = new List<vTaxRate>(this.priceLabelLookups.taxRateLookup);
      this.currencyLookUp = new List<vCurrencyRecord>(this.priceLabelLookups.currencyLookUp);
      this.countryLookup = new List<vCountryRecord>(this.priceLabelLookups.countryLookup);
      this.stateProvinceLookUp1 = this.priceLabelLookups.stateProvinceLookups;
      this.stateProvinceLookUp = new List<vStateProvinceLookUp>(this.priceLabelLookups.stateProvinceLookups);

      this.priceEvaluationFormGroup = this.formBuilder.formGroup(PriceEvaluationFilterModel, this.priceEvaluationSearchModel) as IFormGroup<PriceEvaluationFilterModel>;
      this.actualPrice = this.price;
      this.currencyId = this.currencyLookUp.where(x => x.currencyId == 1)["_entities"][0]["currencyId"]; //US Dollar
      this.priceEvaluationFormGroup.patchValue({ currencyId: this.currencyId });
      this.changeCurrency(this.currencyId, true);

      this.imageUrl = this.imageUrl == null ? DEFAULT_BLOB_IMAGE_URL : this.imageUrl;
      this.ImageofCompanyName = BLOB_PUBLIC_URI + "/" + BLOB_PUBLIC_CONTAINER + "/images/thumbs/logo_lookfaberge.gif";
      this.logoImage = BLOB_PUBLIC_URI + "/" + BLOB_PUBLIC_CONTAINER + "/images/thumbs/Fabergé_Hallmark.ico"

      this.fabHallmarkUrl = CLIENT_URL + "/assets/images/Faberge_Hallmark_GLD.png";
      this.newLogoUrl = CLIENT_URL + "/assets/images/new-logo.png";
      this.certificateCss = CLIENT_URL + "/assets/css/print-certificate.css";

      this.showComponent = true;

    })
  }

  change(event) {
    //this.changeCurrency(event.target.value);
    //this.changeCountry(event.target.value);
    this.calculatePrice(event.target.value, event.target.value);
  }

  changeCurrency(id: number, onInit: boolean = false) {
    // this.showSelectOption = onInit == true ? false : true;
    if (id > 0) {
      this.currencyId = id;
      this.currencyLookUp = new List<vCurrencyRecord>(this.priceLabelLookups.currencyLookUp);
      if (this.currencyLookUp.length > 0 && id != 0 && id != null && id != undefined) {
        this.currencyCode = this.currencyLookUp.where(x => x.currencyId == id)["_entities"][0]["currencyCode"]; //USD
        this.currency = this.currencyLookUp.where(x => x.currencyId == id)["_entities"][0]["name"];
        this.countryId = this.currencyLookUp.where(x => x.currencyId == id)["_entities"][0]["countryId"]; //244
        this.priceEvaluationFormGroup.patchValue({ countryId: this.countryId });
        this.changeCountry(this.countryId, true);
      }
    }
  }

  changeCountry(id: number, onInit: boolean = false) {
    if(id > 0){
      this.showSelectOption = false;
      this.countryLookup = new List<vCountryRecord>(this.priceLabelLookups.countryLookup);
      this.countryLookup1 = this.priceLabelLookups.countryLookup;
      this.countryLookup1 = this.countryLookup1.filter(t => t.countryId == id);
      if (this.countryLookup1.length > 0 && id != 0 && id != null && id != undefined) {
        this.countryName = this.countryLookup.where(x => x.countryId == id)["_entities"][0]["name"];
      }
      setTimeout(() => {
        this.showSelectOption = true;
        this.filterStateProvince(id)
        this.calculatePrice(this.currencyId, id);
      }, 400);
    }
    else{
      this.price = 0;
      this.includingVATPrice = 0;
      this.filterStateProvince(id);
    }  
  }

  filterStateProvince(countryId: number) {
    this.stateProvinceId = null
    this.stateProvinceName = null;
    this.showStateProvince = false;

    if(countryId > 0){
      var selectedCountryName = this.countryLookup.where(x => x.countryId == countryId)["_entities"][0]["name"];
      if(selectedCountryName.toLowerCase().trim() == "usa" || selectedCountryName.toLowerCase().trim() == "canada" || selectedCountryName.toLowerCase().trim() == "united arab emirates"){
        this.showStateProvince = true;
      }
    } 

    this.priceEvaluationFormGroup.patchValue({ stateProvinceId: undefined });
    this.stateProvinceLookUp = new List<vStateProvinceLookUp>(this.priceLabelLookups.stateProvinceLookups);
    this.stateProvinceLookUp1 = this.priceLabelLookups.stateProvinceLookups;
    this.stateProvinceLookUp1 = this.stateProvinceLookUp1.filter(t => t.countryId == countryId);
  }

  changeStateProvince(stateProvinceId: number) {
    this.stateProvinceName = null;
    this.stateProvinceId = stateProvinceId
    if (stateProvinceId != 0 && stateProvinceId != null && stateProvinceId != undefined) {
      this.stateProvinceName = this.stateProvinceLookUp.where(x => x.stateProvinceId == stateProvinceId)["_entities"][0]["stateProvinceName"];
    }
    setTimeout(() => {
      this.calculatePrice(this.currencyId, this.countryId, this.stateProvinceName);
    }, 400);
  }

  calculatePrice(currencyId: number, countryId: number, stateProvinceName: string = null) {
    if ((this.currencyId != null && this.currencyId > 0) && (this.countryId != null && this.countryId > 0)) {
      this.currencyLookUp = new List<vCurrencyRecord>(this.priceLabelLookups.currencyLookUp);
      this.countryLookup = new List<vCountryRecord>(this.priceLabelLookups.countryLookup);

      var dutyPer = this.countryLookup.where(x => x.countryId == countryId)["_entities"][0]["dutyPer"];
      var exchangeRate = this.currencyLookUp.where(x => x.currencyId == currencyId)["_entities"][0]["rate"];

      //var actualExportPrice = this.actualPrice * exchangeRate;
      //this.price = this.currencyCode.toUpperCase() != "USD" ? this.getPriceRoundOf_100(actualExportPrice): actualExportPrice;

      this.price = this.currencyCode.toUpperCase() != "USD" ? this.roundValueToNext_100(this.convertCurrency(this.actualPrice, this.primaryCurrenyId, currencyId, this.primaryExchangeRate, exchangeRate)) : parseInt((this.actualPrice).toString());
      this.priceToWords = this.convertNumberToWords(this.price);

      //For Including VAT
      var CountryName = this.countryLookup.where(x => x.countryId == this.countryId)["_entities"][0]["name"]
      var taxRate = 0;
      if(stateProvinceName != null){
        if (this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == stateProvinceName)["_entities"].length > 0) {
          taxRate = this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == stateProvinceName)["_entities"][0]["percentage"];
        }
      }
      else{
        if (this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == '')["_entities"].length > 0) {
          taxRate = this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == '')["_entities"][0]["percentage"];
        }
      }      
      var actualExportPrice = this.price;
      var dutyfees = ((actualExportPrice * dutyPer) / 100);
      var vatValue = ((actualExportPrice + dutyfees) * taxRate) / 100;
      var taxValue = Math.round(dutyfees + vatValue);
      var totalValue = actualExportPrice + taxValue;
      var deliveryPrice = totalValue;

      this.includingVATPrice = Math.round(deliveryPrice);
      this.includingVATPriceToWords = this.convertNumberToWords(this.includingVATPrice);
    }
  }
  convertCurrency(amount: any, sourceCurrency: number, targetCurrency: number, sourceRate: number, targetRate: number) {
    var result = 0;
    result = Math.round(amount);
    if (sourceCurrency == targetCurrency) {
      return result;
    }
    if (result != 0 && sourceCurrency != targetCurrency) {
      result = this.convertToPrimaryExchangeRateCurrency(result, sourceRate);
      result = this.convertFromPrimaryExchangeRateCurrency(result, targetRate);
    }
    result = this.roundValueToNext_100(result);
    return result;
  }
  convertToPrimaryExchangeRateCurrency(amount: any, sourceRate: number) {
    var result = amount;
    var exchangeRate = 0;
    var power = 0;
    power = (Math.pow(10.0, 4))
    exchangeRate = Math.trunc((power * sourceRate)) / power;
    result = result / exchangeRate;
    return result;
  }

  convertFromPrimaryExchangeRateCurrency(amount: any, targetRate: number) {
    var result = amount;
    var exchangeRate = 0;
    var power = 0;
    power = (Math.pow(10.0, 4))
    exchangeRate = Math.trunc((power * targetRate)) / power;
    result = result * exchangeRate;
    return result;
  }
  roundValueToNext_100(value: any) {
    var result = 0;
    var remainder = ((parseInt(value) % 100));
    // if (value < 100)
    // {
    //     result = 100;
    // }
    if (remainder != 0) {
      result = parseInt(((100 - value % 100) + value).toString());
    }
    else {
      result = parseInt((value.toString()));
    }
    return parseFloat(result.toString());
  }
  printEvaluation(): void {
    let printContents, popupWin;
    //this.isPrintEvaluation = true;

    printContents = document.getElementById('print-evaluation').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head><link rel="stylesheet" type="text/css" href="${this.certificateCss}">
          <title></title>
        </head>
        <body onload="window.print();">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  hidePopup() {
    this.hide()
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  /**
   * @summary convert numeric numbers to numeric words.
   * @param number take numeric numbers as input
   * @returns a numeric words string formed on base of input
   */
  convertNumberToWords(number) {
    const words = ["", "Thousand", "Million", "Billion", "Trillion"];
    let result = "";

    if (number === 0) {
      return "Zero";
    }

    let i = 0;

    while (number > 0) {
      const chunk = number % 1000;

      if (chunk !== 0) {
        if (result !== "") {
          result = " " + result;
        }
        result = this.convertThreeDigitsToWords(chunk) + " " + words[i] + result;
      }

      number = Math.floor(number / 1000);
      i++;
    }

    return result.trim();
  }

  /**
   * @summary convert three digit words to equivalent numeric word string.
   * @param number take three digit numeric number
   * @returns a numeric words string formed on base of input
   */
  convertThreeDigitsToWords(num: number): string {
    const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    let result = "";

    const hundredsDigit = Math.floor(num / 100);
    const remainder = num % 100;

    if (hundredsDigit > 0) {
      result += units[hundredsDigit] + " Hundred";
    }

    if (remainder !== 0) {
      if (result !== "") {
        result += " ";
      }

      if (remainder < 10) {
        result += units[remainder];
      } else if (remainder < 20) {
        result += teens[remainder - 10];
      } else {
        const tensDigit = Math.floor(remainder / 10);
        const onesDigit = remainder % 10;

        result += tens[tensDigit];
        if (onesDigit > 0) {
          result += "-" + units[onesDigit];
        }
      }
    }

    return result;
  }

  getPriceRoundOf_100(price: number) {
    var result = 0;
    var remainder = price % 100;
    // if (price < 100) {
    //   result = 100;
    // }
    // else
    if (remainder != 0) {
      result = ((100 - price % 100) + price);
    }
    else {
      result = price;
    }
    return result;
  }

}

class PriceEvaluationFilterModel {
  @prop()
  pieceNumber: string;

  @prop()
  productName: string;

  @prop()
  currencyId: number;

  @prop()
  countryId: number;

  @prop()
  stateProvinceId: number;

  @prop()
  printwithPriceOrVatPrice: number;
}
