import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldsEnhancementBase {

//#region enhancementId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldsEnhancementListComponent_EnhancementId_gh', keyColumn: true })
        enhancementId : number;
//#endregion enhancementId Prop


//#region enhancementName Prop
        @gridColumn({isAscending:true, style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsEnhancementListComponent_EnhncementNme_gh', keyColumn: false })
        //@gridColumn({isAscending:true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsEnhancementListComponent_EnhncementNme_gh', keyColumn: false })
        enhancementName : string;
//#endregion enhancementName Prop

}