import { RiskCompanyCustomerMappingBase } from '@app/database-models';
import { prop, required } from '@rxweb/reactive-form-validators';

export class RiskCompanyCustomerMapping extends RiskCompanyCustomerMappingBase {

    @prop()
    @required({ message: "Please Select Person" })
    customerIds: string;

    @required({message:"Please Select Group Entity"})
    @prop({ defaultValue: 0 })
    leaveCompanyId: number

}