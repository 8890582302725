import { TemplateConfig } from '@rxweb/grid';

  export const RELATIONSHIP_BIND: TemplateConfig = {
    select:
    {
      class: ["checkbox-custom-style","form-control"],
      event: {
        input: "onGridRelationChange"
      },
      style:{
        "margin-top":"-6px",
        "width":"auto"
      },
      attributes: {
        value: function (e) {
          return this.value;
        },
        selected: function (e) {
        var data  = this[e.name];
          return data;
        }
      },
      childrens: [
        {
          option: {
            attributes: {
              value: function (e) {
                return this.value;
              },
              selected: function (e) {
                if(this.parentObject.relationship=="Boss"){
                  
                 return this.parentObject.relationship && this.value==1
                }
                if(this.parentObject.relationship=="Brother"){
                  
                    return this.parentObject.relationship && this.value==2
                   }
                   if(this.parentObject.relationship=="Mother"){
                  
                    return this.parentObject.relationship && this.value==3
                   }
                   if(this.parentObject.relationship=="Sister"){
                  
                    return this.parentObject.relationship && this.value==4
                   }
            }
            },
            
            childrens: [{
              text: {
                text: function (e) { return this.text }
              }
            }]
          }
        }
      ],
      sourceItems: ActiveFormer(),
    }
  }

             

  
  export function ActiveFormer() {
    return [{ value: 1, text: "Boss" }, { value: 2, text: "Brother" },{ value: 3, text: "Mother" },{ value: 4, text: "Sister" }]
  }