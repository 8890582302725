import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SalesChannelBase {

//#region salesChannelId Prop
        @prop()
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region salesChannelName Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        salesChannelName : string;
//#endregion salesChannelName Prop


//#region salesChannelType Prop
      //  @range({minimumNumber:1,maximumNumber:2147483647})
        // @required()
        @prop({defaultValue:0})
        salesChannelType : number;
//#endregion salesChannelType Prop


//#region billingCountryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        billingCountryId : number;
//#endregion billingCountryId Prop


//#region salesChannelAddress Prop
        @required()
        @notEmpty()
        salesChannelAddress : string;
//#endregion salesChannelAddress Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}