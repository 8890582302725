import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vShippingMethodLookUpBase {

//#region shippingMethodID Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'shippingMethodID', keyColumn: true})
        shippingMethodID : number;
//#endregion shippingMethodID Prop


//#region shippingMethodName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'shippingMethodName', keyColumn: false})
        shippingMethodName : string;
//#endregion shippingMethodName Prop

}