import {vCustomerEventBase,} from '@app/database-models'
//Generated Imports

export class vCustomerEvent extends vCustomerEventBase 
{

TotalCount: number;


//#region Generated Reference Properties
//#endregion Generated Reference Properties
}