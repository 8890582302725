import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
//import { HelpSupport } from '@app/models';
import { AbstractHelpSupport } from '../domain/abstract-help-support';
import { Router } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { HelpSupport } from '@app/models';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { BrowserModule, Title } from '@angular/platform-browser';
import { TINY_MCE_KEY } from '../../../../domain/system-constant';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { HelpSupportEditComponent } from '../edit/help-support-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";

import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@multilingual("HelpSupportAddComponent")
@access({ accessLevel: RolePermissionEnums.HelpSupport, action: "post" })

@Component({
  selector: "app-help-support-add",
  templateUrl: './help-support-add.component.html'
})
export class HelpSupportAddComponent extends AbstractHelpSupport implements OnInit, OnDestroy {
  helpSupport: HelpSupport;
  subscription: any;
  helpSupportEdit: any = HelpSupportEditComponent;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  entityTypeIdForEditorDocuments = EditorDocumentsEnum.HelpSupport;

  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.HelpSupport;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Help & Support - G-Trac Admin");
    this.spin = true;
    this.titleHelp = getLocalizedValue("Title_Help");
    this.lookup([{ params: [0], path: USER_LOOKUPS.parentHelpSupportLookUp, propName: "parentHelpSupportLookUp" }]).subscribe((lookup: any) => {
      this.spin = false;
      this.helpSupportLookups = lookup;
      this.helpSupport = new HelpSupport();
      this.helpSupport.statusId = StatusEnum.Active;
      this.helpSupport.helpSupportId = 0;
      this.helpSupport.parentHelpSupportId = 0;
      this.helpSupportFormGroup = this.formBuilder.formGroup(this.helpSupport) as IFormGroup<HelpSupport>;
      this.showComponent = true;
      this.decryptstring = localStorage.getItem('AllLocalizedMessages');
      this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
      this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  addHelpSupport(isRedirect) {
    this.helpSupportFormGroup.submitted = true;
    if (this.helpSupportFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.helpSupportFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          this.toastr.success(existsAlert[0].showMessage);
        }
        isRedirect ? this.router.navigate(['/help-supports', data]) : this.router.navigate(['/help-supports']);
      })
    } else {
      for (const key of Object.keys(this.helpSupportFormGroup.controls)) {
        if (this.helpSupportFormGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }
  tinyMCE_images_upload_handler = (blobInfo, success, failure) => {
    var fiileName = blobInfo.blob().name;
    if (fiileName != undefined) {
    }
  }
  get componentName(): string {
    return "HelpSupportAddComponent";
  }
}
