import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRecurringPaymentTypeLookUpBase {

//#region recurringPaymentTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'recurringPaymentTypeId', keyColumn: true})
        recurringPaymentTypeId : number;
//#endregion recurringPaymentTypeId Prop


//#region recurringPaymentTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'recurringPaymentTypeName', keyColumn: false})
        recurringPaymentTypeName : string;
//#endregion recurringPaymentTypeName Prop

}