import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_EDIT_LEAVE_POPUP: TemplateConfig = {
    div: {
        childrens: [
          {
            a: {
              class: [
                function(e){
                  if(this.CitiesMinesLocations == 'Total'){
                    return "text-dark font-weight-bold".split(" ")
                  }
                  else
                  {
                    return "text-blue font-weight-bold".split(" ")
                  }
                }
              ],
              event: {
                click: "onTravelSummaryClick"
              },
              childrens: [{
                text: {
                  text: function (e) {
                       return this[e.name];
                  }
                }
              }]
            },
          }
        ]
      },
};