import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BoutiqueProductMappingBase {

//#region boutiqueProductVariantId Prop
        @prop()
        boutiqueProductVariantId : number;
//#endregion boutiqueProductVariantId Prop


//#region manager_Id Prop
        @prop()
        manager_Id : number;
//#endregion manager_Id Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region productVariantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region productVariantBatchPieceId Prop
        @prop()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region qty Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        qty : number;
//#endregion qty Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region boutique_Id Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        boutique_Id : number;
//#endregion boutique_Id Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop


//#region updatedDate Prop
        @prop()
        updatedDate : Date;
//#endregion updatedDate Prop


//#region updatedBy Prop
        @prop()
        updatedBy : number;
//#endregion updatedBy Prop









}