import {vCustomerTitleTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCustomerTitleTypeLookUp extends vCustomerTitleTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}