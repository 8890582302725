import { prop, required } from '@rxweb/reactive-form-validators';

export class MainMenuItemLocalizationBase{
    
         @prop()
        mainMenuItemLocalizationId:number;

        @prop()
        mainMenuItemId:number;

        @prop()
        menuName:string;

      
        @prop()
        languageId:number;
}