import {vMeasureDimensionLookUpBase,} from '@app/database-models'
//Generated Imports
export class vMeasureDimensionLookUp extends vMeasureDimensionLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}