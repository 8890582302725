import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHPLeaveStatusLookUpBase {

//#region hPLeaveStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'hPLeaveStatusId', keyColumn: true})
        hPLeaveStatusId : number;
//#endregion hPLeaveStatusId Prop


//#region hPLeaveStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'hPLeaveStatusName', keyColumn: false})
        hPLeaveStatusName : string;
//#endregion hPLeaveStatusName Prop

}