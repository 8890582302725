import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { RolePermission } from '@app/models';
import { CoreComponent } from '@rxweb/angular-router';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { ModalView } from 'src/app/domain/customize-design/modal';
@http({
    
    path: "api/RolePermissions",
})
export class AbstractRolePermission extends CoreComponent {
    rolePermissionFormGroup: IFormGroup<RolePermission>;
    modalView:ModalView;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    spin:boolean;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
}
