import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentGemstoneSearchViewModel {

    id: number;

    @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh' , keyColumn: false })
    isChecked: boolean

    isAllowMoveNoteDocument: boolean

    //#region gemStoneId Prop
    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, headerKey: 'MoveNoteDocumentComp_GemstoneId_gh', keyColumn: false, configuredTemplate: {templateName: "reDirect"} })
    gemStoneId: number;
    //#endregion gemStoneId Prop

    //#region title Prop
    @gridColumn({ visible: false, columnIndex: 0,  keyColumn: true })
    title: string;
    //#endregion title Prop

    //#region categoryId Prop
    @gridColumn({ visible: false, columnIndex: 2,  keyColumn: false })
    categoryId: number;
    //#endregion categoryId Prop

    //#region type Prop
    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, headerKey: 'MoveNoteDocumentComp_GemstoneType_gh', keyColumn: false })
    type: string;
    //#endregion type Prop

    //#region locationId Prop
    @gridColumn({ visible: false, columnIndex: 13,  keyColumn: false })
    locationId: number;
    //#endregion locationId Prop

    //#region location Prop
    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 14, headerKey: 'MoveNoteDocumentComp_GemstoneLocation_gh', keyColumn: false })
    location: string;
    //#endregion location Prop

    //#region supplierId Prop
    @gridColumn({ visible: false, columnIndex: 15,  keyColumn: false })
    supplierId: number;
    //#endregion supplierId Prop

    //#region statusId Prop
    @gridColumn({ visible: false, columnIndex: 16,  keyColumn: false })
    statusId: number;
    //#endregion statusId Prop

    //#region status Prop
    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 17, headerKey: 'MoveNoteDocumentComp_GemstoneStatus_gh', configuredTemplate: {templateName: "gemstoneSerialNo"}, keyColumn: false })
    status: string;
    //#endregion status Prop

    //#region supplier Prop
    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 18, headerKey: 'MoveNoteDocumentComp_GemstoneSupplier_gh', keyColumn: false })
    supplier: string;
    //#endregion supplier Prop
    
    @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 19, headerKey: 'MoveNoteDocumentComp_GemstoneOwner_gh', keyColumn: false })
    legalOwner: string;

    //#region serialNo Prop
    @gridColumn({ visible: false, columnIndex: 17,  keyColumn: false })
    serialNo: number;
    //#endregion serialNo Prop

    //#region productVariantBatchPieceId Prop
    @gridColumn({ visible: false, columnIndex: 23,  keyColumn: false })
    productVariantBatchPieceId: number;
    //#endregion productVariantBatchPieceId Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 24,  keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    @gridColumn({ visible: false, columnIndex: 7,  keyColumn: false })
    clarityName: string;

    @gridColumn({ visible: false, columnIndex: 19,  keyColumn: false })
    consignment: string;

}
