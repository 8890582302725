import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneColorGemfieldBase {

//#region colorId Prop
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemStoneColorGemfieldListComponent_ColorId_gh', keyColumn: true })
        colorId : number;
//#endregion colorId Prop


//#region colorName Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, visible: true, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'GemStoneColorGemfieldListComponent_ColorName_gh', keyColumn: false })
        colorName : string;
//#endregion colorName Prop

}