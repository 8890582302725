import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppGrid } from 'src/app/domain/app-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { AbstractDocumentType } from '../domain/abstract-document-type';
import { DocumentTypeNoteViewModel } from 'src/app/view-model/document-type-notes-detail-view-model';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';

@access({ accessLevel: RolePermissionEnums.DocumentType, action: "get" })

@multilingual("DocumentTypeNotesDetailComponent")
@Component({
    selector: 'app-document-type-notes-detail',
    templateUrl: './document-type-notes-detail.component.html'
})
export class DocumentTypeNotesDetailComponent extends AbstractDocumentType implements OnInit, OnDestroy {
    @Input() documentTypeId : number;
    [x: string]: any;
    subscription: any;
    documentTypeNoteGridList: DocumentTypeNoteViewModel[];
    documentTypeNoteGrid: AppGrid;
    id: number;
    isNoRecordFound: boolean;
    isShowGrid: boolean;
    paginationViewModel: PaginationViewModel;
    gridSearchString: string = "";
    totalRecords: number = 0;
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    sort: boolean;
    titleSearch: string;
    constructor(private activatedRoute: ActivatedRoute, router: Router) {
        super();
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
            this.documentTypeId = this.id;
            
        })
        this.router = router;
        this.paginationViewModel = new PaginationViewModel();
        this.paginationViewModel.orderBy = "createdDate";
        this.paginationViewModel.sortOrder = 'false';
    }
    ngOnInit() {
        this.titleSearch = getLocalizedValue("Title_Search");
        this.sort = true;
        this.bindGrid();
    }
    bindGrid() {
        this.spin = true;
        var json = JSON.parse(JSON.stringify(this.paginationViewModel));
        json["searchString"] = this.gridSearchString;
        json["documentTypeId"] = this.documentTypeId;
        json["timezoneOffset"] = new Date().getTimezoneOffset();
        this.subscription = this.post({ path: "api/FormLookups/DocumentTypeNotesDetails", body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
            this.spin = false;
            this.documentTypeNoteGridList = t;
            this.totalRecords = this.documentTypeNoteGridList.length > 0 ? this.documentTypeNoteGridList[0].totalCount : 0;
            this.isShowGrid = this.documentTypeNoteGridList.length > 0 ? true : false;
            this.isNoRecordFound = this.documentTypeNoteGridList.length > 0 ? false : true;
            if (!this.isFilter) {
                this.documentTypeNoteGrid = new AppGrid(this.documentTypeNoteGridList, DocumentTypeNoteViewModel, { actions: {} });
                this.documentTypeNoteGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            }
            else {
                this.documentTypeNoteGrid.storeProcedure.length = this.totalRecords;
                this.documentTypeNoteGrid.updateSource([]);
                this.documentTypeNoteGrid.updateSource(this.documentTypeNoteGridList);
            }
            this.documentTypeNoteGrid.maxPerPage = this.documentTypeNoteGrid.maxPerPage;
            this.documentTypeNoteGrid.componentId = this.componentName;
            this.documentTypeNoteGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.documentTypeNoteGrid.designClass.headerClass = "table-header-sticky".split(" ");

        })
    }
    search($event) {
        setTimeout(() => {
            this.spin = true;
            this.isFilter = true;
            this.paginationViewModel.pageIndex = 1;
            this.paginationViewModel.rowCount = this.documentTypeNoteGrid.maxPerPage;
            this.gridSearchString = $event.target.value.replace(/^\s+|\s+$/gm, '');
            this.documentTypeNoteGrid.updateSource([]);
            this.bindGrid();
        }, 4);
        this.spin = false;
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.paginationViewModel.sortOrder = (!this.sort).toString();
        this.sort = !this.sort;
        this.paginationViewModel.orderBy = x;
        this.paginationViewModel.pageIndex = z;
        this.documentTypeNoteGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }
    onPageChanging(x) {
        this.isFilter = true;
        this.paginationViewModel.pageIndex = x;
        this.paginationViewModel.rowCount = this.documentTypeNoteGrid.maxPerPage
        this.documentTypeNoteGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }
    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    get componentName(): string {
        return "DocumentTypeNotesDetailComponent";
    }
}
