import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserRoleBase {

    //#region userRoleId Prop
    @prop()
    userRoleId: number;
    //#endregion userRoleId Prop


    //#region userId Prop
    //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    userId: number;
    //#endregion userId Prop


    //#region roleId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    roleId: number;
    //#endregion roleId Prop

    //#region roleId Prop
    @prop({ defaultValue: false })
    active: boolean;
    //#endregion roleId Prop

}
