import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vWatchStatusBase {

//#region watchStatusId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'WatchStatusListComponent_StatusId_gh', keyColumn: true })
        watchStatusId : number;
//#endregion watchStatusId Prop

//#region watchStatusName Prop
        @gridColumn({ isAscending:true, style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'WatchStatusListComponent_StatusName_gh', keyColumn: false })
        watchStatusName : string;
//#endregion watchStatusName Prop




}
