import { Injector, Directive,  EmbeddedViewRef, ViewContainerRef, TemplateRef, Input } from "@angular/core";
import { NgIfContext } from "@angular/common";
import { routeContainer,IMultilingual } from "@rxweb/angular-router"

/** THIS DIRECTIVE IS CREATED TO RESOLVE LOCALISATION ISSUE FOR CHILD COMPONENTS DEFINED IN COMPONENT'S HTML TEMPLATE */

/** IT IS NEEDED FOR NEWER VERSIONS OF ANGULAR */

/** TO CHECK USAGE, REFER TO 'company-customer-mapping-list.component.html' && it's .ts component file */

@Directive({
    selector: '[rxMultilingualExtended]'
})
export class RxMultilingualExtended {
    private viewRef: EmbeddedViewRef<any> | null = null;
    private _context: NgIfContext = new NgIfContext();

    constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, private injector: Injector) {}

    @Input('rxMultilingualExtended') set component(value: any) {
        let childMultiLingual:any = routeContainer.get().childMultilingualResolver
        if (childMultiLingual && value) {
            var childMultilingual = this.injector.get(childMultiLingual) as IMultilingual;
            //var result =null;// childMultilingual.resolveChildren(this.viewContainerRef["_view"].component.constructor);
            // const viewContainer:any = this.viewContainerRef;
            const result = childMultilingual.resolveChildren(value.constructor)
            // if (viewContainer["_view"] && viewContainer["_view"].component != null)
            //     result = childMultilingual.resolveChildren(viewContainer["_view"].component.constructor);
            // else {
            //     var component = viewContainer._hostLView[0].__ngContext__[viewContainer._hostLView[0].__ngContext__.length - 1];
            //     result = childMultilingual.resolveChildren(component.constructor);
            // }
            if(typeof result == "boolean")
                this.updateView(result);
            else
                result.then(t => {
                    this.updateView(t);
                })
        }
    }
    ngOnInit() {
        this.component = null;
    }
    private updateView(value: boolean) {
        if (this.viewRef) {
            this.viewContainerRef.clear();
            this.viewRef = null;
        }
        if (value)
            this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, this._context);
        else
            this.viewContainerRef.clear();
    }
}