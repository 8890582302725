import { prop,propObject,propArray,required,maxLength,range ,email,notEmpty,numeric,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EmailAccountBase {

//#region emailAccountId Prop
        @prop()
        emailAccountId : number;
//#endregion emailAccountId Prop


//#region email Prop
        @required()
        @maxLength({value:255})
        @email()
        @trim()
        @notEmpty()
        email : string;
//#endregion email Prop


//#region displayName Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        displayName : string;
//#endregion displayName Prop


//#region host Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        host : string;
//#endregion host Prop


//#region port Prop
        @required()
        @numeric()
        @notEmpty()
        @range({minimumNumber:1,maximumNumber:2147483647})
        port : number;
//#endregion port Prop


//#region username Prop
        @required()
        @maxLength({value:255})
        @trim()
        @notEmpty()
        username : string;
//#endregion username Prop


//#region password Prop
        @required()
        @maxLength({value:16})
        @notEmpty()
        password : string;
//#endregion password Prop


//#region enableSSL Prop
        @prop({defaultValue:false})
        enableSSL : boolean;
//#endregion enableSSL Prop


//#region useDefaultCredentials Prop
        @prop({defaultValue:false})
        useDefaultCredentials : boolean;
//#endregion useDefaultCredentials Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}