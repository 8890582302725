import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { vTaxRate } from '../models/extended-models/v-tax-rate';
import { TaxRate } from '../models/extended-models';
import { DocumentOTPVerificationViewModel } from '../view-model/document-otp-verification-view-model';

@Injectable()
export class ApplicationBroadcaster {
  private loginSubject: Subject<boolean> = new Subject<boolean>();
  public loginSubscriber: Observable<boolean>;

  private boutiqueSubject: Subject<boolean> = new Subject<boolean>();
  public boutiqueSubscriber: Observable<boolean>;

  private taxRateSubject: Subject<vTaxRate | TaxRate> = new Subject<vTaxRate | TaxRate>();
  public taxRateSubscriber: Observable<vTaxRate | TaxRate>;

  private forgotPasswordSubject: Subject<boolean> = new Subject<boolean>();
  public forgotPasswordSubscriber: Observable<boolean>;
  
  private ssoRedirectedSubject: Subject<any> = new Subject<any>();
  public ssoRedirectedSubscriber: Observable<any>;

  private resetPasswordSubject: Subject<boolean> = new Subject<boolean>();
  public resetPasswordSubscriber: Observable<boolean>;

  private memberFeedbackSubject: Subject<boolean> = new Subject<boolean>();
  public memberFeedbackSubscriber: Observable<boolean>;

  private menuLevelSubject: Subject<any> = new Subject<any>();
  public menuLevelSubscriber: Observable<any>;

  private advancedSearchSubject: Subject<any> = new Subject<any>();
  public advancedSearchSubscriber: Observable<any>;

  public blackListedSubject: Subject<boolean> = new Subject<boolean>();

  private userProfileChangeSubject: Subject<boolean> = new Subject<boolean>();
  public userProfileChangeSubscriber: Observable<boolean>;

  private previousUrlSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isPreviousUrlSubscriber: Observable<any> =this.previousUrlSubject.asObservable();

  // Below 2 properties are used for Document Download functionality
  public documentVerifiedOTP: DocumentOTPVerificationViewModel;
  public isDownloadProcessStarted: boolean = false;

  constructor() {
    this.loginSubscriber = this.loginSubject.asObservable();
    this.boutiqueSubscriber = this.boutiqueSubject.asObservable();
    this.taxRateSubscriber = this.taxRateSubject.asObservable();
    this.forgotPasswordSubscriber = this.forgotPasswordSubject.asObservable();
    this.ssoRedirectedSubscriber = this.ssoRedirectedSubject.asObservable();
    this.resetPasswordSubscriber = this.resetPasswordSubject.asObservable();
    this.memberFeedbackSubscriber = this.memberFeedbackSubject.asObservable();
    this.menuLevelSubscriber = this.menuLevelSubject.asObservable();
    this.advancedSearchSubscriber = this.advancedSearchSubject.asObservable();
    this.userProfileChangeSubscriber = this.userProfileChangeSubject.asObservable();
  }

  activeMenu(activeMenu: any): void {
    this.menuLevelSubject.next(activeMenu);
  }

  loginBroadCast(value: boolean): void {
    this.loginSubject.next(value);
  }

  boutiqueBroadCast(): void {
    this.boutiqueSubject.next();
  }

  taxRateBroadCast(taxRate: vTaxRate | TaxRate) {
    this.taxRateSubject.next(taxRate);
  }

  forgotPasswordBroadCast(value: boolean): void {
    this.forgotPasswordSubject.next(value);
  }

  ssoRedirectedBroadCast(value: any): void {
    this.ssoRedirectedSubject.next(value);
  }

  resetPasswordBrodcast(value: boolean): void {
    this.resetPasswordSubject.next(value);
  }

  memberFeedbackBrodcast(value: boolean): void {
    this.memberFeedbackSubject.next(value);
  }

  advancedSearchClick(value: any): void {
    this.advancedSearchSubject.next(value);
  }

  userProfileChangeBrodcast(value: boolean): void {
    this.userProfileChangeSubject.next(value);
  }
  setPreviousUrl(previousUrl: string) {
    this.previousUrlSubject.next(previousUrl);
   }

  /**
   * Function to save OTP Token data from document-verification page
   */
  saveDocumentOTPToken(value: DocumentOTPVerificationViewModel): void {
    this.documentVerifiedOTP = value;
  }
}
