import { prop,propObject,propArray,required,maxLength,range ,alpha,trim, notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TripTypeBase {

//#region tripTypeId Prop
        @prop()
        tripTypeId : number;
//#endregion tripTypeId Prop


//#region tripTypeName Prop
        @required()
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        @trim()
        @maxLength({value:100})
        tripTypeName : string;
//#endregion tripTypeName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}