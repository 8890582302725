import { Injectable } from '@angular/core';
import { RxHttp } from '@rxweb/http';
import { ForgotPasswordViewModel } from 'src/app/view-model/forgot-password-view-model';
import { getServerUrl } from 'src/app/domain/common-logic/common-logic';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';


@Injectable({
    providedIn: 'root'
})

export class ResetPasswordService {
    constructor(private http: RxHttp, private httprequest: HttpClient, private browserStorage: BrowserStorage) {
        //this.http.badRequest = (data) => {
        //}
    }
   
    CheckUser(resetPassword: any) {
        return this.http.post<any>({ body: resetPassword, path: "api/Authentication/CheckUser" });
    }

    
}
