import {vHolidayListRecordBase,} from '@app/database-models'
//Generated Imports
export class vHolidayListRecord extends vHolidayListRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}