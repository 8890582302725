import {vProductVariantStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductVariantStatusLookUp extends vProductVariantStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}