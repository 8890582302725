import { Component, OnInit, OnDestroy, AfterViewInit, EventEmitter, Input } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractOwnershipMapping } from '../domain/abstract-ownership-mapping';
import { OwnershipMapping } from "@app/models";
import { Subscription } from 'rxjs';
import { OwnerMappingViewModel } from 'src/app/models/extended-models/owner-mapping-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { AppGrid, AppDivGrid, DivGrid, ChildGridWithHeader } from 'src/app/domain/app-grid';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { OwnerMappingChildViewModel } from 'src/app/models/extended-models/owner-mapping-child-view-model';
import { OwnershipMappingAddComponent } from '../add/ownership-mapping-add.component';
import { OwnershipMappingEditComponent } from '../edit/ownership-mapping-edit.component';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { DomSanitizer } from '@angular/platform-browser';
import { CLIENT_URL } from "src/app/domain/system-constant";
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { GridColumnConfig } from '@rxweb/grid';
import { PlatformLocation } from "@angular/common";
@multilingual("OwnershipMappingListComponent")
@Component({
  selector: "app-ownership-mapping-list",
  templateUrl: './ownership-mapping-list.component.html'
})
export class OwnershipMappingListComponent extends AbstractOwnershipMapping implements OnInit, OnDestroy {
  _this: any;
  ownershipMappings: List<OwnershipMapping>;
  ownerMappingViewModel: OwnerMappingViewModel[];
  ownerMappingChildViewModel: OwnerMappingChildViewModel[];
  header: OwnerMappingChildViewModel;
  subscription: any;
  id: number;
  ownerMappingGrid: AppGrid;
  rowCount: number = PaginationEnum.RowCount;
  pageIndex: number = PaginationEnum.PageIndex;
  sortOrder: string = "true";
  orderBy: string = "companyName";
  isFirstTime: boolean = true;
  childGrid: DivGrid;
  totalOwnershipPerc: number;
  openArray: number[];
  gridSearchString: string = "";
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  isFilter: boolean = false;
  totalRecords: number = 0;
  titleSearch: string;
  noticeData: any;
  isShowGridColumn: boolean = false;
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  isUserEdit: boolean = false;
  @Input() moduleID: any;
  @Input() componentType: string = "";
  @Input() selectedTab: string = "";

  constructor(private activatedRoute: ActivatedRoute, router: Router, modalView: ModalView, private sanitizer: DomSanitizer, private location : PlatformLocation) {
    super();
    this._this = this;
    this.router = router;
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })
    this.modalView = modalView;
    location.onPopState(() => this.dialog.hide());
  }
  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.openArray = new Array<number>();
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      setTimeout(() => {
        this.bindGrid();
      }, 0);
    })
  }
  bindGrid() {
    if (this.componentType == "user-edit")
      this.isUserEdit = true;
    var json = {
      corporateBy: this.id,
      pageIndex: this.pageIndex,
      rowCount: this.rowCount,
      sortOrder: this.sortOrder,
      orderBy: this.orderBy,
      searchString: this.gridSearchString,
      isUserEdit: this.isUserEdit//,
      //clientUrl: CLIENT_URL
    }
    this.spin = true;
    this.isShowGrid = true;
    this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: any) => {
      this.spin = false;
      this.isShowGrid = true;
      this.ownerMappingViewModel = t;
      if (this.ownerMappingViewModel.length > 0) {
        this.totalOwnershipPerc = this.ownerMappingViewModel[0].totalOwnershipPerc;
        this.totalRecords = this.ownerMappingViewModel[0].totalCount;
        this.isNoRecordFound = false;
        this.isShowGrid = true;
      }
      else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
      }
      if (!this.isFilter) {
        this.ownerMappingGrid = new AppDivGrid(this.ownerMappingViewModel, OwnerMappingViewModel, { actions: { onOneLink: this.onUserInfoLink.bind(this), onCorporateLink: this.onSupplierInfoLink.bind(this), onExpand: this.getOwnership.bind(this), onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this), onCorporateGroupLink: this.onCorporateGroupLink.bind(this) } });
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(x => {
            this.ownerMappingGrid.gridColumns.forEach(y => {
              if (y.name == x.ColumnName)
                y.visible = x.Visible;
              if (y.name == "userName" && this.componentType == "user-edit") {
                y.visible = false;
                y.parameter = true;
              }
            });
          });
          this.gridColumnConfig = this.ownerMappingGrid.gridColumns;
          this.gridColumnSettings.emit(this.gridColumnConfig)
        }
        this.ownerMappingGrid.designClass.tableClass = "div-table div-table-height div-table-striped div-table-hover dataTables-example dataTable text-center border-bottom table-responsive ownership-form-table".split(" ");
        this.ownerMappingGrid.designClass.headerClass = "div-table-header-sticky".split(" ");
        this.ownerMappingGrid.componentId = this.componentName;
        this.ownerMappingGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }

      }
      else {
        this.ownerMappingGrid.storeProcedure.length = this.totalRecords;
        this.ownerMappingGrid.updateSource([]);
        this.ownerMappingGrid.updateSource(this.ownerMappingViewModel)
      }
      this.ownerMappingGrid.maxPerPage = this.rowCount;
      this.ownerMappingGrid.componentId = this.componentName;

    })
    this.noteData();
  }
  noteData() {
    setTimeout(() => {
      var localizationData = (MultiLingualData.get(this.componentName) as any);
      this.noticeData = localizationData.OwnershipMappingListComponent_Notice_gn_gn;
    }, 500);
  }
  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.ownerMappingGrid.source.forEach(x => {
      this.ownerMappingGrid.removeChildGrid(x.ownershipId);
    })
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(element => {
        var index = this.ownerMappingGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
        if (index != -1) {
          if (this.ownerMappingGrid.gridColumns[index].visible != element.Visible) {
            element.Visible = this.ownerMappingGrid.gridColumns[index].visible;
          }
        }
      })
    }
    this.openArray = [];
    this.ownerMappingGrid.reDesign();
    this.ownerMappingGrid.updateSource([])
    this.ownerMappingGrid.updateSource(this.ownerMappingViewModel);
  }

  onPageSorting(x, y, z) {
    this.sortOrder = y;
    this.orderBy = x;
    this.isFilter = true;
    this.ownerMappingGrid.storeProcedure.nextPage = z;
    this.openArray = [];
    this.bindGrid();
  }

  onPageChanging(x, y, z) {
    this.isFilter = true;
    this.ownerMappingGrid.storeProcedure.nextPage = x;
    this.pageIndex = x;
    this.rowCount = this.ownerMappingGrid.maxPerPage;
    this.openArray = [];
    this.bindGrid();
  }

  onEdit(ownerMappingViewModel: OwnerMappingViewModel) {
    this.modalView.show(OwnershipMappingEditComponent, { corporateBy: this.id, ownershipId: ownerMappingViewModel.ownershipId }).then(t => {
      this.isFilter = true;
      this.bindGrid();
    });
  }

  onDelete(ownerMappingViewModel: OwnerMappingViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [ownerMappingViewModel.ownershipId] }).subscribe(data => {
          this.spin = false;
          this.isFilter = true;
          this.bindGrid();
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
        })
      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_OwnerShip_Mapping");
    if (existsAlert) {
      return existsAlert;
    }
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.rowCount = this.ownerMappingGrid.maxPerPage;
      this.pageIndex = 1;
      this.sortOrder = "true";
      this.orderBy = "companyName";
      this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
      this.isFilter = true;
      if (this.ownerMappingGrid != null) {
        this.ownerMappingGrid.storeProcedure.nextPage = 1;
      }
      this.bindGrid();
    }, 4000);
    this.spin = true;
  }

  getOwnership(t, x, y, z) {
    if (t.detail) {
      var element = this.ownerMappingGrid.getRowElement(t.ownershipId);
      if (this.openArray.findIndex((x) => x == t.ownershipId) != -1) {
        x.srcElement.className = "fa fa-plus mr-2 mt-1"
        x.srcElement.setAttribute("title", getLocalizedValue("Title_Expand"))
        this.openArray.splice(this.openArray.findIndex((x) => x == t.ownershipId), 1);
        element.removeChild(element.lastChild);
      } else {
        x.srcElement.className = "fa fa-minus mr-2 mt-1"
        x.srcElement.setAttribute("title", getLocalizedValue("Title_Collapse"))
        if (element) {
          this.ownerMappingChildViewModel = new Array<OwnerMappingChildViewModel>();
          this.ownerMappingChildViewModel = t.detail;
          this.childGrid = new ChildGridWithHeader(this.ownerMappingChildViewModel, OwnerMappingChildViewModel, { actions: { onOneLink: this.onUserInfoLink.bind(this), onCorporateLink: this.onSupplierInfoLink.bind(this) } });
          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
              this.childGrid.gridColumns.forEach(y => {
                if (y.name == x.ColumnName)
                  y.visible = x.Visible;
                if (y.name == "userName" && this.componentType == "user-edit") {
                  y.visible = false;
                  y.parameter = true;
                }
              });
            });
            this.gridColumnConfig = this.ownerMappingGrid.gridColumns;
            this.gridColumnSettings.emit(this.gridColumnConfig)
          }
          this.childGrid.designClass.tableClass = "div-table div-table-height div-table-striped div-table-hover dataTables-example dataTable text-center border-bottom table-responsive ownership-form-table".split(" ");
          this.childGrid.designClass.headerClass = "div-table-header-sticky".split(" ");
          this.childGrid.componentId = this.componentName;
          this.childGrid.maxPerPage = 1000000;
          this.ownerMappingGrid.addChildGrid(t.ownershipId, this.childGrid);
          element.lastElementChild.className = "div-tr";
          this.openArray.push(t.ownershipId);
        }
      }
    }
  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  addOwnership() {
    this.modalView.show(OwnershipMappingAddComponent, { corporateBy: this.id, componentType: this.componentType }).then(t => {
      this.isFilter = true;
      this.bindGrid();
    });
  }

  onCorporateGroupLink(supplierInfo: OwnerMappingViewModel) {
    let url : any;
    url = this.router.serializeUrl(this.router.createUrlTree(["suppliers", supplierInfo.supplierId], { queryParams: { activeTab: 'supplier-edit' } }));
    redirectOnCTRL(url,this.router,event);
  }

  onUserInfoLink(userInfo: OwnerMappingViewModel) {
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["users", userInfo.customerId], { queryParams: { activeTab: 'user-edit' } }));
    redirectOnCTRL(url, this.router, event);
    //this.router.navigate(["users", userInfo.customerId], { queryParams: { activeTab: 'user-edit' } });
  }
  onSupplierInfoLink(supplierInfo: OwnerMappingViewModel) {
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["suppliers", supplierInfo.companyId], { queryParams: { activeTab: 'supplier-edit' } }));
    redirectOnCTRL(url, this.router, event);
    //this.router.navigate(["suppliers", supplierInfo.companyId], { queryParams: { activeTab: 'supplier-edit' } });
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "OwnershipMappingListComponent";
  }
}
