import {vGemStoneClarityGemfieldsRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneClarityGemfieldsRecord extends vGemStoneClarityGemfieldsRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}