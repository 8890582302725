import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"

export class vPowerBIReportBase {

//#region powerBIReportId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'PowerBIReportListComponent_Id_gh', keyColumn: true})
        powerBIReportId : number;
//#endregion powerBIReportId Prop


//#region name Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'PowerBIReportListComponent_Name_gh', keyColumn: false})
        powerBIReportName : string;
//#endregion name Prop

//#region powerBIReportGUID Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'PowerBIReportListComponent_GUID_gh', keyColumn: false})
        powerBIReportGUID : string;
//#endregion powerBIReportGUID Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'PowerBIReportListComponent_Published_gh', keyColumn: false,configuredTemplate: { templateName:"published" }})
        published : boolean;
//#endregion published Prop

}