import { prop, propObject, propArray, required, maxLength, range, trim } from "@rxweb/reactive-form-validators"
import { actionColumn, gridColumn } from "@rxweb/grid"

export class SystemHelpBase {

    systemHelpId?: number;

    moduleMasterId: number;
    moduleMasterName?: string;

    endUserHelp: string;
    technicalHelp: string;
    
    @prop()
    // @trim()
    endUserHelpDraft: string;

    approvedBy?: number;

    isApproved?: boolean;
    isAllowApprove?:boolean;
    approvedAt?: Date;

    @prop()
    // @trim()
    technicalHelpDraft: string;

    // @prop()
    isPublished?: boolean;

    createdAt?: Date;
    createdBy?: number;
    updatedAt?: Date;
    updatedBy?: number;
    systemHelpPageId: number;
    isShowTechnicalHelp?:boolean;


}
