import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingCustomerMappingBase {

//#region tripCustomerID Prop
        @prop()
        tripCustomerID : number;
//#endregion tripCustomerID Prop


//#region tripID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        tripID : number;
//#endregion tripID Prop


//#region customerID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerID : number;
//#endregion customerID Prop



}