import {vLanguageRecordBase,} from '@app/database-models'
//Generated Imports
export class vLanguageRecord extends vLanguageRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}