import { TemplateConfig } from '@rxweb/grid';
export const BIND_MULTIPLE_RADIO_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style", "iradio_square-green"],
                style: {
                  "margin-top": "-1px",
                  "position": "relative",
                  "margin-left": "auto",
                },
                event: {
                  input: "onRadioSelect"
                },
                attributes: {
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  },
                }
              },
              label: {                
                style: {
                  "margin-right": "20px",
                  "position": "relative"
                },
                childrens: [
                  {
                    text: {
                      text: function (e) {
                        var radioTag = this.categoryNamesArray[0];
                        return radioTag;
                      }
                    }
                  }
                ]
              }
            },
          ]
        },
        span: {
          isBind: (x) => {
            if (x.isShowMultipleRadioButton) {
              return true;
            }
            else {
              return false;
            }
          },
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style", "iradio_square-green"],
                style: {
                  "margin-top": "-1px",
                  "position": "relative",
                  "margin-left": "auto",
                },
                event: {
                  input: "onOtherRadioSelect"
                },
                attributes: {
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    var isPrimary = this.isPrimaryValueArray[1];
                    return isPrimary;
                  },
                }
              },
              label: {                
                childrens: [
                  {
                    text: {
                      text: function (e) {
                        var radioTag = this.categoryNamesArray[1];
                        return radioTag;
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  }
}