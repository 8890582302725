import {IncidentPrevailingWeatherBase,LookupPrevailingWeatherBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class IncidentPrevailingWeather extends IncidentPrevailingWeatherBase 
{




//#region Generated Reference Properties
//#region lookupPrevailingWeather Prop
lookupPrevailingWeather : LookupPrevailingWeatherBase;
//#endregion lookupPrevailingWeather Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties





































}