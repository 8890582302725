import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPriceListExportTypeLookUpBase {

//#region priceListExportTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'priceListExportTypeId', keyColumn: true})
        priceListExportTypeId : number;
//#endregion priceListExportTypeId Prop


//#region priceListExportTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'priceListExportTypeName', keyColumn: false})
        priceListExportTypeName : string;
//#endregion priceListExportTypeName Prop

}