import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DepartmentBase {

//#region departmentId Prop
        @prop()
        departmentId : number;
//#endregion departmentId Prop


//#region name Prop
        @required()
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        @trim()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region peopleType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        peopleType : number;
//#endregion peopleType Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}