import { prop, propObject, propArray, required, maxLength, range, numeric, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IncidentPersonsAffectedBase {

        //#region incPersonAffId Prop
        @prop()
        incPersonAffId: number;
        //#endregion incPersonAffId Prop


        //#region incidentId Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        //@prop({defaultValue:0})
       // @required()
        incidentId: number;
        //#endregion incidentId Prop


        //#region occurrenceType Prop
       // @required()
        @prop({defaultValue:''})
        occurrenceType: any;
        //#endregion occurrenceType Prop


        //#region personAffectedId Prop
        @prop()
        //@required()
        personAffectedId: number;
        //#endregion personAffectedId Prop


        //#region supplierId Prop
        @prop()
        supplierId: number;
        //#endregion supplierId Prop

        //#region isInjured Prop
        @prop({ defaultValue: false })
        isInjured:boolean
        //#endregion isInjured Prop

        //#region injurySeverityId Prop
        @prop()
      //  @required()
        injurySeverityId: number;
        //#endregion injurySeverityId Prop

        //#region conditionMedicalTreatment Prop
        @prop()
        @maxLength({ value: 1000 })
        conditionMedicalTreatment: string;
        //#endregion conditionMedicalTreatment Prop


        //#region involvementRole Prop
        @prop()
        @maxLength({ value: 500 })
        involvementRole: string;
        //#endregion involvementRole Prop


        //#region workinghrslost Prop
        @prop()
        //@pattern({expression:{"workinghrslost":/^[0-9]\d{0,3}(\.\d{1,2})?%?$/},message:"Please enter valid Working Houre Lost(4 digits and 2 decimal places allowed)"})
        //workinghrslost: number;
        //#endregion workinghrslost Prop


        //#region lostTimeInjuryId Prop
        @prop({ defaultValue: 0 })
      //  @required()
        lostTimeInjuryId: number;
        //#endregion lostTimeInjuryId Prop


        //#region createdOn Prop
      //  @required()
        //@prop({defaultValue:new Date()})
        createdOn: Date;
        //#endregion createdOn Prop


        //#region createdBy Prop
        @prop()
        createdBy: number;
        //#endregion createdBy Prop


        //#region isFatality Prop
        @prop({ defaultValue: false })
        isFatality: boolean;
        //#endregion isFatality Prop


        //#region isArrested Prop
        @prop({ defaultValue: false })
        isArrested: boolean;
        //#endregion isArrested Prop


        //#region isArtisanal Prop
        //@prop({defaultValue:false})
        @prop()
        isArtisanal: number;
        //#endregion isArtisanal Prop

        //#region deleted Prop
        @prop()
        deleted: boolean;
        //#endregion deleted Prop

        //#region daysLost Prop
        @prop()
        daysLost: number;
        //#endregion daysLost Prop

        //#region injuryType Prop
        @prop()
        injuryType: number;
        //#endregion injuryType Prop

        //#region isOfficalGrievance Prop
        @prop({ defaultValue: false })
        isOfficalGrievance:boolean
        //#endregion isOfficalGrievance Prop

        //#region personType Prop
        @prop()
        personType:number;
        //#endregion personType Prop

        //#region isTrespasser Prop
        @prop({ defaultValue: false })
        isTrespasser:boolean;
        //#endregion isTrespasser Prop

        //#region isIllegalMiner Prop
        @prop({ defaultValue: false })
        isIllegalMiner:boolean;
        //#endregion isIllegalMiner Prop

        //#region injuryCategory Prop
        @prop()
        injuryCategory:number;
        //#endregion injuryCategory Prop

        @prop()
        personTypeId:number;

        @prop()
        dayLost:number;

        @prop()
        injuryCategoryTypeId:number;

   














}