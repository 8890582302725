import { TrackIncidentBase, SupplierBase, UserBase, IncidentPrevailingWeatherBase, AccidentVehicleTypeBase, IncidentTypeBase, PersonBodilyLocationBase, PersonInjuryTypeBase, AccidentInjurySeverityBase, IncidentAccidentTypeBase, IncidentOccurrenceAreaBase, IncidentPersonsAffectedBase, IncidentPersonsAffectedCategoryBase, IncidentGrievanceAccessPointMappingBase, IncidentGrievanceTypeBase, IncidentGrievanceCategoryMappingBase, } from '@app/database-models'
import { propArray, prop, required } from '@rxweb/reactive-form-validators';
import { IncidentPersonsAffected } from './incident-persons-affected';
import { OccurrenceType } from './occurrence-type';
import { IncidentCorporatesAffected } from './incident-corporates-affected';
//Generated Imports
export class TrackIncident extends TrackIncidentBase {

    constructor() {
        super()
       
        this.occurrenceTypes = new Array<OccurrenceType>();
    }

    @prop()
    supplierIds: string;
    @prop()
    weatherIds: string;
    @prop()
    occurrenceAreaIds: string;
    @prop()
    accidentVehicleIds: string;
    @prop()
    incidentTypeIds: string;
    @prop()
    bodilyLocationIds: string;
    @prop()
    injuryTypeIds: string;
    @prop()
    injurySeverityIds: string;
    @prop()
    accidentTypeIds: string;
    @prop()
    personsAffectedIds: string;
    @prop()
    nearMissPersonsAffectedIds: string;
    @prop()
    personsAffectedCategoryIds: string;

    @prop()
    occurrenceTypeName: string;
    @prop()
    supplierName: string;
    @prop()
    isGlobalAdmin: boolean;

    @prop()
    grievanceTypeIds: string;
    @prop()
    grievanceCategoryIds: string;
    @prop()
    grievanceAccessPointIds: string;
    @prop()
    bodilyLocationName: string;
    @prop()
    injuryTypeName: string;

    @prop()
    isAllowEdit: boolean

    @prop()
    formRestrictedRights: boolean

    @prop()
    nearMissPersonList:string

    @propArray(IncidentPersonsAffected)
    incidentPersonsAffectedList: IncidentPersonsAffected[]
    
    @propArray(IncidentCorporatesAffected)
    incidentCorporatesAffectedList: IncidentCorporatesAffected[]
    

    //#region Generated Reference Properties

    //#region supplier Prop
    supplier: SupplierBase;
    //#endregion supplier Prop

    //#region user Prop
    user: UserBase;
    //#endregion user Prop

    //#region incidentPrevailingWeathers Prop
    @propArray(IncidentPrevailingWeatherBase)
    incidentPrevailingWeathers: IncidentPrevailingWeatherBase[];
    //#endregion incidentPrevailingWeathers Prop

    //#region accidentVehicleTypes Prop
    @propArray(AccidentVehicleTypeBase)
    accidentVehicleTypes: AccidentVehicleTypeBase[];
    //#endregion accidentVehicleTypes Prop

    //#region incidentTypes Prop
    @propArray(IncidentTypeBase)
    incidentTypes: IncidentTypeBase[];
    //#endregion incidentTypes Prop

    //#region personBodilyLocations Prop
    @propArray(PersonBodilyLocationBase)
    personBodilyLocations: PersonBodilyLocationBase[];
    //#endregion personBodilyLocations Prop

    //#region personInjuryTypes Prop
    @propArray(PersonInjuryTypeBase)
    personInjuryTypes: PersonInjuryTypeBase[];
    //#endregion personInjuryTypes Prop

    //#region accidentInjurySeverity Prop
    @propArray(AccidentInjurySeverityBase)
    accidentInjurySeverity: AccidentInjurySeverityBase[];
    //#endregion accidentInjurySeverity Prop

    //#region incidentAccidentTypes Prop
    @propArray(IncidentAccidentTypeBase)
    incidentAccidentTypes: IncidentAccidentTypeBase[];
    //#endregion incidentAccidentTypes Prop

    //#region incidentOccurrenceAreas Prop
    @propArray(IncidentOccurrenceAreaBase)
    incidentOccurrenceAreas: IncidentOccurrenceAreaBase[];
    //#endregion incidentOccurrenceAreas Prop

    //#region incidentPersonsAffected Prop
    @propArray(IncidentPersonsAffected)
    incidentPersonsAffected: IncidentPersonsAffected[];
    @propArray(IncidentCorporatesAffected)
    incidentCorporatesAffected: IncidentCorporatesAffected[];
    //#endregion incidentPersonsAffected Prop

    //#region incidentPersonsAffectedCategories Prop
    @propArray(IncidentPersonsAffectedCategoryBase)
    incidentPersonsAffectedCategories: IncidentPersonsAffectedCategoryBase[];
    //#endregion incidentPersonsAffectedCategories Prop

    //#region incidentGrievanceAccessPointMappings Prop
    @propArray(IncidentGrievanceAccessPointMappingBase)
    incidentGrievanceAccessPointMappings: IncidentGrievanceAccessPointMappingBase[];
    //#endregion incidentGrievanceAccessPointMappings Prop

    //#region incidentGrievanceTypes Prop
    @propArray(IncidentGrievanceTypeBase)
    incidentGrievanceTypes: IncidentGrievanceTypeBase[];
    //#endregion incidentGrievanceTypes Prop

    //#region incidentGrievanceCategoryMappings Prop
    @propArray(IncidentGrievanceCategoryMappingBase)
    incidentGrievanceCategoryMappings: IncidentGrievanceCategoryMappingBase[];
    //#endregion incidentGrievanceCategoryMappings Prop

    //#endregion Generated Reference Properties

    @prop({defaultValue:''})
    riskIds : string;

    @prop()
    SearchLocationName: string;

    @prop()
    existsGrievanceRights: boolean;

    @prop()
    ticketId : number;

    @prop()
    trackIncidentFormId: number;

    @prop()
    trackIncidentFormRefNo: string;

    @prop()
    @required()
    departmentIds :string;

    @prop()
    vehicleTypeIds:string;

    @propArray(OccurrenceType)
    occurrenceTypes: OccurrenceType[]

    @propArray(OccurrenceType)
    occTypesSelected: OccurrenceType[]

    @propArray(IncidentPersonsAffected)
    incidentPersonSelected: IncidentPersonsAffected[]
   
    @propArray(IncidentCorporatesAffected)
    incidentCorporateSelected: IncidentCorporatesAffected[]

    @prop({defaultValue:false})
    isHSE:boolean

    @prop()
    intrusionOnLicenceAreaPeopleOption: number;
    @prop({defaultValue:0})
    damageId: number;

    @prop()
    intrusionOnMiningPitPeopleOption: number;

    @prop()
    illegalMinersSightedPeopleOption: number;

    @prop()
    recoverdGemstoneGemsOption: number;

    @prop()
    recoverdGemstoneGramsOption: number;

    @prop()
    recoverdGemBearingMaterialBagsOption: number;

    @prop()
    recoverdGemBearingMaterialKgsOption: number;

    @prop()
    illegalMiningPitsOpenedOption: number;
 
































}
