import { gridColumn } from '@rxweb/grid';

export class vMainMenuItemsBase{
    //#region mainMenuItemId Prop
    @gridColumn({parameter:false,visible: false, columnIndex:0, allowSorting: true, headerTitle: 'bangleSizeId', keyColumn: true})
    mainMenuItemId : number;
//#endregion mainMenuItemId Prop


//#region topMenuName Prop
    @gridColumn({style: { width: "15%", "text-align": "initial" },parameter: false,class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'MainMenuItemListComponent_menuName_gh', keyColumn: false})
    topMenuName : string;
//#endregion topMenuName Prop

//#region topMenuUrl Prop
@gridColumn({style: { width: "20%", "text-align": "initial" },class: ["text-left"],parameter: false,visible: true, columnIndex:2, allowSorting: true, headerKey: 'MainMenuItemListComponent_menuUrl_gh', keyColumn: false})
topMenuUrl : string;
//#endregion topMenuUrl Prop

//#region active Prop
@gridColumn({style: { width: "5%", "text-align": "initial" },configuredTemplate: { templateName: "recordActive" },parameter: false,class: ["text-left"],visible: true, columnIndex:3, allowSorting: false, headerKey: 'MainMenuItemListComponent_menuActive_gh', keyColumn: false})
active : boolean;
//#endregion active Prop

//#region displayOrder Prop
@gridColumn({style: { width: "10%", "text-align": "initial" },parameter: false,class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'MainMenuItemListComponent_displayOrder_gh', keyColumn: false})
displayOrder : number;
//#endregion displayOrder Prop
}