import {vUserKnownToBase,} from '@app/database-models'
//Generated Imports
export class vUserKownTo extends vUserKnownToBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties

}