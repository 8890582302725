import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser'; 
import { Setting } from '@app/models';
import { AbstractSetting } from '../domain/abstract-setting';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { StatusEnum } from 'src/app/enums/status.enum';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';

import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { SettingEditComponent } from '../edit/setting-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("SettingAddComponent")
@access({ accessLevel: RolePermissionEnums.AllSettings, action: "post" })


@Component({
    selector: "app-setting-add",
    templateUrl: './setting-add.component.html'
})
export class SettingAddComponent extends AbstractSetting implements OnInit, OnDestroy {
    setting: Setting;
    subscription: any;
    settingEdit:any=SettingEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder,private router:Router,modalView:ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView=modalView;
        this.moduleID = RolePermissionEnums.AllSettings;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("All Settings - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.setting = new Setting();
        this.setting.value="";
        this.setting.description="";
        this.setting.settingID=0;
        this.setting.statusId= StatusEnum.Active;
        this.settingFormGroup = this.formBuilder.formGroup(this.setting) as IFormGroup<Setting>;
        this.showComponent=true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    addSetting(isRedirect:boolean)
    {
        this.settingFormGroup.submitted = true;
        if (this.settingFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.settingFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                if (existsAlert) {
                 //this.toastr.success("Data Added Successfully")
                    this.toastr.success(existsAlert[0].showMessage);
                }
                isRedirect ? this.router.navigate(['/settings', data]) : this.router.navigate(['/settings']);
            })
        }else{
            for (const key of Object.keys(this.settingFormGroup.controls)) {
                if (this.settingFormGroup.controls[key].invalid) { 
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }
    get componentName(): string {
        return "SettingAddComponent";
    }
}
