import {vCallMeBase,} from '@app/database-models'
//Generated Imports
export class vCallMe extends vCallMeBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}