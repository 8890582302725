import {vGemStoneColorLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneColorLookUp extends vGemStoneColorLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}