import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductDesignerMappingBase {

//#region productDesignerId Prop
        @prop()
        productDesignerId : number;
//#endregion productDesignerId Prop


//#region productID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID : number;
//#endregion productID Prop


//#region designerID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        designerID : number;
//#endregion designerID Prop



}