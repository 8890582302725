import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class NoteMemberMappingBase {

  //#region noteTeammembermappingId Prop
  @prop()
  noteTeammembermappingId: number;
  //#endregion noteTeammembermappingId Prop


  //#region noteId Prop
  // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  noteId: number;
  //#endregion noteId Prop


  //#region teammemberId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  teammemberId: number;
  //#endregion teammemberId Prop


  //#region typeofNote Prop
  //  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  typeofNote: number;
  //#endregion typeofNote Prop


  //#region isTo Prop
  @prop()
  isTo: boolean;
  //#endregion isTo Prop

}
