import { Component, OnInit, OnDestroy, Input, EventEmitter } from "@angular/core";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { multilingual } from "@rxweb/localization";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { AbstractLegalAgreementProjectMapping } from "../domain/abstract-legal-agreement-project-mapping";
import { GridColumnConfig } from "@rxweb/grid";
import { LegalAgreementsListViewModel } from "src/app/view-model/lagal-agreement-list-view-model";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { LegalAgreementProjectMappingAddComponent } from '../add/legal-agreement-project-mapping-add.component';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { LegalAgreementProjectMapping } from '@app/models';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { PlatformLocation } from "@angular/common";
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";
import { showSensitiveContactToolTip } from "src/app/domain/customize-plugin/bind-slider";
@multilingual("LegalAgreementProjectMappingListComponent")
@Component({
    selector: "app-legal-agreement-project-mapping-list",
    templateUrl: './legal-agreement-project-mapping-list.component.html'
})
export class LegalAgreementProjectMappingListComponent extends AbstractLegalAgreementProjectMapping implements OnInit, OnDestroy {
    subscription: any;
    router: Router;
    json: any;
    id: number = 0;
    legalAgreementProjectGrid: AppGrid;
    legalAgreementProjects: LegalAgreementsListViewModel[];
    paginationViewModel: PaginationViewModel;
    isShowGridColumn: boolean = false;
    isShowSearch : boolean = false;
    @Input() selectedTab: string = "";
    @Input() moduleID: any;
    @Input() showAddButton: boolean = true;
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    arrConfig: any[];
    titleSearch: string;
    titleGridColumnSetting: string;
    expandTitle: string = "Expand";
    collapseTitle: string = "Collapse";
    modalView: ModalView;
    @Input() entityTypeId:number
    isLongTextExists: boolean = false;
    isHideAll: boolean = false;
    isShowAll: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, router: Router, private browserStorage: BrowserStorage, modelView: ModalView,private location : PlatformLocation) {
        super();
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        this.router = router;
        this.paginationViewModel = new PaginationViewModel();
        this.paginationViewModel.sortOrder = "true";
        this.paginationViewModel.orderBy = "legalAgreementId";
        this.modalView = modelView;
        Window["LegalAgreementProjectMappingListComponent"] = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.titleSearch = getLocalizedValue("Title_Search");
        this.expandTitle = getLocalizedValue("Title_Expand");
        this.collapseTitle = getLocalizedValue("Title_Collapse");
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
        if(this.entityTypeId== NotesEnum.ProjectNotes)
            this.json["projectId"] = this.id;
        else if(this.entityTypeId == NotesEnum.EventNotes)
            this.json["eventId"] = this.id;
        else if(this.entityTypeId == NotesEnum.CorporateGroupsNotes || this.entityTypeId == NotesEnum.CustomerNotes)
            this.json["counterPartyId"] = this.id;
        else if(this.entityTypeId == NotesEnum.RiskNotes)
            this.json["riskMenuDetailId"] = this.id;
        // this.json["clientUrl"] = CLIENT_URL;
        // this.json["serverUrl"] = SERVER_URL;
        this.json["moduleId"] = this.moduleID;
        this.json["userId"] = 0;
        this.showComponent = true;

        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
            this.getColumnsData = t;
            if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            }
            setTimeout(() => {
                this.bindGrid();
            }, 0);
        })
    }

    bindGrid(isOnload: boolean = true) {
        this.spin = true;
        this.showGrid = true;
        this.isShowGrid = true;
        this.totalRecords = 0;


        this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((t: any) => {
            this.spin = false;
            if (this.legalAgreementProjectGrid) {
                this.legalAgreementProjectGrid.storeProcedure.length = 0;
            }
            t = t.map(record => {
                let riskDetailsArray = [];
                let projectDetailsArray = [];
                let eventDetailsArray = [];
                let counterPartyDetailsArray = [];
                try {
                    riskDetailsArray = JSON.parse(record.riskDetails);
                    projectDetailsArray = JSON.parse(record.projectDetails);
                    eventDetailsArray = JSON.parse(record.eventDetails);
                    counterPartyDetailsArray = JSON.parse(record.counterPartyDetails);
                } catch (error) {
                    return {
                        ...record,
                        riskDetails: [],
                        projectDetails: [],
                        eventDetails: [],
                        counterPartyDetails: []
                    }
                }
                return {
                    ...record,
                    riskDetails: riskDetailsArray,
                    projectDetails: projectDetailsArray,
                    eventDetails: eventDetailsArray,
                    counterPartyDetails: counterPartyDetailsArray
                }
            });

            this.legalAgreementProjects = t;
            if (this.legalAgreementProjects.length > 0) {
                this.totalRecords = this.legalAgreementProjects[0].totalCount;
                if(isOnload && this.totalRecords > 0){
                    this.isShowSearch = true;
                }
                this.legalAgreementProjects.forEach(x => {
                    if (x.eventDetails != null && x.eventDetails != undefined && x.eventDetails.length > 1) {
                        x.events = this.createEventTable(x);
                    }
                    if (x.projectDetails != null && x.projectDetails != undefined && x.projectDetails.length > 1) {
                        x.projects = this.createProjectTable(x);
                    }
                    if (x.riskDetails != null && x.riskDetails != undefined && x.riskDetails.length > 1) {
                        x.risks = this.createRiskTable(x);
                    }
                    if (x.counterPartyDetails != null && x.counterPartyDetails != undefined && x.counterPartyDetails.length > 1) {
                        x.counterParties = this.createCounterPartyTable(x);
                    }
                });
                this.isNoRecordFound = false;
            }
            else {
                this.isShowGrid = false;
                this.isNoRecordFound = true;
            }
            if (!this.isFilter) {
                this.legalAgreementProjectGrid = new AppGrid(this.legalAgreementProjects, LegalAgreementsListViewModel, {
                    actions: {
                        onLegalAgreementLink: this.onLegalAgreementEdit.bind(this),onOneLink:this.onUserClick.bind(this), onUserLink: this.onUserClick.bind(this), onPDFView: this.onPDFView.bind(this), onDOCView: this.onDOCView.bind(this), onMoreSummaryClick: this.onMoreSummaryClick.bind(this), onDeleteMapping: this.onDeleteMapping.bind(this)
                    }
                });
                this.legalAgreementProjectGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
                this.legalAgreementProjectGrid.gridColumns.forEach((col) => {
                    if (col.columnIndex == 18) {
                        col.visible = false;
                    }
                    if (col.columnIndex == 26) {
                        col.visible = true;
                    }
                });
            }
            else {
                if (this.legalAgreementProjectGrid != null) {
                    this.legalAgreementProjectGrid.storeProcedure.length = this.totalRecords;
                    if (this.json["pageIndex"] == 1) {
                        this.legalAgreementProjectGrid.storeProcedure.nextPage = 1;
                    }
                    this.legalAgreementProjectGrid.updateSource([]);
                    this.legalAgreementProjectGrid.updateSource(this.legalAgreementProjects);
                }
            }
            if (this.arrConfig != undefined && this.arrConfig != null) {
                this.arrConfig.forEach(x => {
                    this.legalAgreementProjectGrid.gridColumns.forEach(y => {
                        if (y.name == x.ColumnName)
                            y.visible = x.Visible;
                    });
                });
                this.gridColumnConfig = this.legalAgreementProjectGrid.gridColumns;
                this.gridColumnSettings.emit(this.gridColumnConfig)
            }
            this.legalAgreementProjectGrid.maxPerPage = this.json["rowCount"];
            this.legalAgreementProjectGrid.componentId = this.componentName;
            this.legalAgreementProjectGrid.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(' ');
            this.legalAgreementProjectGrid.designClass.headerClass = "table-header-sticky".split(" ");
        });
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json["sortOrder"] = y;
        this.json["orderBy"] = x;
        this.legalAgreementProjectGrid.storeProcedure.nextPage = z;
        this.bindGrid(false);
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.json["pageIndex"] = x;
        this.json["rowCount"] = this.legalAgreementProjectGrid.maxPerPage;
        this.legalAgreementProjectGrid.storeProcedure.nextPage = x;
        this.bindGrid(false);
    }

    onLegalAgreementEdit(legalAgreement: LegalAgreementsListViewModel) {
        const urlUser = this.router.serializeUrl(
            this.router.createUrlTree(["legal-agreement", legalAgreement.legalAgreementId], { queryParams: { activeTab: 'legal-agreement-info' } })
        );
        window.open(urlUser, '_blank');
    }

    onUserClick(legalAgreement: LegalAgreementsListViewModel) {
        const urlUser = this.router.serializeUrl(
            this.router.createUrlTree(["users", legalAgreement.leader], { queryParams: { activeTab: 'user-edit' } })
        );
        window.open(urlUser, '_blank');
    }

    onPDFView(legalAgreement: LegalAgreementsListViewModel) {
        if (legalAgreement.signedAgreementPdfURL != null && legalAgreement.signedAgreementPdfURL != "") {
            window.open(legalAgreement.signedAgreementPdfURL, "_blank");
        }
    }

    onDOCView(legalAgreement: LegalAgreementsListViewModel) {
        if (legalAgreement.executionVersionDocURL != null && legalAgreement.executionVersionDocURL != "") {
            window.open(legalAgreement.executionVersionDocURL, "_blank");
        }
    }

    onMoreSummaryClick(t, x) {
        var element = document.getElementById("legalAgreementLASummary_" + t.legalAgreementId);
        var maskedElement = document.getElementById("maskedLASummary_" + t.legalAgreementId);
        if (element.className == "collapse") {
            x.srcElement.innerText = "Show Less";
            x.srcElement.className = "less-btn"
            element.classList.add("show");
            maskedElement.setAttribute("style", "display:none");
        }
        else {
            element.classList.remove("show");
            x.srcElement.innerText = "Show More";
            x.srcElement.className = "more-btn";
            maskedElement.setAttribute("style", "display:inline");
        }
    }

    onDeleteMapping(legalAgreement: LegalAgreementsListViewModel) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
              this.dialog.hide();
              this.spin = true;
              this.delete({path: 'api/SearchProject/deleteLegalAgreementMapping',body: {entityTypeId: this.entityTypeId, entityId: this.id, id: legalAgreement.legalAgreementId}}).subscribe(response => {
                if (response == 1) {
                  var existsAlert = getLocalizedValue("Data_Deleted");
                  if (existsAlert) {
                    this.toastr.success(existsAlert);
                  }
                  this.spin = false;
                  this.isFilter = true;
                  this.totalRecords = 0;
                  this.legalAgreementProjectGrid.updateSource([]);
                  this.isShowSearch  = false;
                  this.bindGrid();
                }
                else {
                  this.spin = false;
                }
              });
            }
            this.dialog.hide()
          })
    }
    DeleteConfirmationMessage() {

        var existsAlert = getLocalizedValue("Delete_Confimation");
        if(this.moduleID == RolePermissionEnums.User){
            existsAlert = getLocalizedValue("Delete_Confirmation_LegalAgree_User");
        }
        else if(this.moduleID == RolePermissionEnums.Project){
            existsAlert = getLocalizedValue("Delete_Confirmation_LegalAgree_Project");
        }
        else if(this.moduleID == RolePermissionEnums.Event){
            existsAlert = getLocalizedValue("Delete_Confirmation_LegalAgree_Event");
        }
        else if(this.moduleID == RolePermissionEnums.Supplier){
            existsAlert = getLocalizedValue("Delete_Confirmation_LegalAgree_Suppliers");
        }
        else if(this.moduleID == RolePermissionEnums.GemfieldsRiskMenu){
            existsAlert = getLocalizedValue("Delete_Confirmation_LegalAgree_GemfieldsRiskMenu");
        }
        
        if (existsAlert) {
            return existsAlert;
        }
    }

    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }

    applyVisible(gridColumnConfig: GridColumnConfig[]) {
        this.legalAgreementProjectGrid.reDesign();
        this.legalAgreementProjectGrid.updateSource([])
        this.legalAgreementProjectGrid.updateSource(this.legalAgreementProjects);
    }

    search(value) {
        setTimeout(() => {
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.json["searchString"] = value.target.value;
            if (this.legalAgreementProjectGrid != null) {
                this.legalAgreementProjectGrid.updateSource([]);
                this.json["pageIndex"] = 1;
                this.legalAgreementProjectGrid.storeProcedure.nextPage = 1;
                // this.json["rowCount"] = this.projectEventsGrid.maxPerPage;
            }
            this.bindGrid();
        }, 0);
        this.spin = true;
    }

    createEventTable(item: LegalAgreementsListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table style='width: 100%;'><tbody>";
        let subTable: any;
        subTable = "<tr id='event_" + item.legalAgreementId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.eventDetails != null && item.eventDetails != undefined) {
            for (let i = 0; i < item.eventDetails.length; i++) {
                let x: any = item.eventDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.legalAgreementId + "'";
                    row = row + "<tr><td >" + x.EventName + "<i title='" + this.expandTitle + "' class='fa fa-chevron-down ml-2' id='icon_event_" + item.legalAgreementId + "' onclick='Window.LegalAgreementProjectMappingListComponent.expandEventTable(" + item.legalAgreementId + ")'></i></td></tr>";
                }
                else {
                    subRow = subRow + "<tr><td>" + x.EventName + "</td></tr>";
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    expandEventTable(id: number) {
        var element = document.getElementById("event_" + id.toString());
        var iconElement = document.getElementById("icon_event_" + id.toString());
        this.changeIcon(element, iconElement);
    }

    createProjectTable(item: LegalAgreementsListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table style='width: 100%;'><tbody>";
        let subTable: any;
        subTable = "<tr id='project_" + item.legalAgreementId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.projectDetails != null && item.projectDetails != undefined) {
            for (let i = 0; i < item.projectDetails.length; i++) {
                let x: any = item.projectDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.legalAgreementId + "'";
                    row = row + "<tr><td >" + x.ProjectName + "<i title='" + this.expandTitle + "' class='fa fa-chevron-down ml-2' id='icon_project_" + item.legalAgreementId + "' onclick='Window.LegalAgreementProjectMappingListComponent.expandProjectTable(" + item.legalAgreementId + ")'></i></td></tr>";
                }
                else {
                    subRow = subRow + "<tr><td>" + x.ProjectName + "</td></tr>";
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    expandProjectTable(id: number) {
        var element = document.getElementById("project_" + id.toString());
        var iconElement = document.getElementById("icon_project_" + id.toString());
        this.changeIcon(element, iconElement);
    }

    createRiskTable(item: LegalAgreementsListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table style='width: 100%;'><tbody>";
        let subTable: any;
        subTable = "<tr id='risk_" + item.legalAgreementId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.riskDetails != null && item.riskDetails != undefined) {
            for (let i = 0; i < item.riskDetails.length; i++) {
                let x: any = item.riskDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.legalAgreementId + "'";
                    row = row + "<tr><td >" + x.risk + "<i title='" + this.expandTitle + "' class='fa fa-chevron-down ml-2' id='icon_risk_" + item.legalAgreementId + "' onclick='Window.LegalAgreementProjectMappingListComponent.expandRiskTable(" + item.legalAgreementId + ")'></i></td></tr>";
                }
                else {
                    subRow = subRow + "<tr><td>" + x.risk + "</td></tr>";
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    expandRiskTable(id: number) {
        var element = document.getElementById("risk_" + id.toString());
        var iconElement = document.getElementById("icon_risk_" + id.toString());
        this.changeIcon(element, iconElement);
    }

    createCounterPartyTable(item: LegalAgreementsListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table style='width: 100%;'><tbody>";
        let subTable: any;
        subTable = "<tr id='counterparty_" + item.legalAgreementId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.counterPartyDetails != null && item.counterPartyDetails != undefined) {
            for (let i = 0; i < item.counterPartyDetails.length; i++) {
                let x: any = item.counterPartyDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.legalAgreementId + "'";
                    if(x.userContactPermissionRequired == 1){
                       this.bindTooltip(x.userId.toString()+x.LegalAgreementId.toString(),x.userIntroducesBy.replaceAll("'",'&#39;'))
                    }
                    if(x.supplierContactPermissionRequired == 1){
                        this.bindTooltip(x.supplierId.toString()+x.LegalAgreementId.toString(),x.supplierIntroducesBy.replaceAll("'",'&#39;'))
                    }
                    row = row + "<tr><td>" + x.CounterPartyName + "<i title='" + this.expandTitle + "' class='fa fa-chevron-down ml-2' id='icon_counterparty_" + item.legalAgreementId + "' onclick='Window.LegalAgreementProjectMappingListComponent.expandCounterPartyTable(" + item.legalAgreementId + ")'></i>" +
                    (x.isSupplierUnwelcome == 1 || x.isUnwelcome==1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") + "' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>" : "") + (x.userContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='"+x.userIntroducesBy.replaceAll("'",'&#39;')+"' id='"+x.userId+x.LegalAgreementId+"'style='margin-left: 0.15rem;'></i>": "")+(x.supplierContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='"+x.supplierIntroducesBy.replaceAll("'",'&#39;')+"' id='"+x.supplierId+x.LegalAgreementId+"'style='margin-left: 0.15rem;'></i>": "")
                    "</td></tr>";                  
                }
                else {
                    if(x.userContactPermissionRequired == 1){
                        this.bindTooltip(x.userId.toString()+x.LegalAgreementId.toString(),x.userIntroducesBy.replaceAll("'",'&#39;'))
                     }
                     if(x.supplierContactPermissionRequired == 1){
                         this.bindTooltip(x.supplierId.toString()+x.LegalAgreementId.toString(),x.supplierIntroducesBy.replaceAll("'",'&#39;'))
                     }
                    subRow = subRow + "<tr><td>" + x.CounterPartyName +
                        (x.isSupplierUnwelcome == 1 || x.isUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") + "' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>" : "") +(x.userContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='"+x.userIntroducesBy.replaceAll("'",'&#39;')+"' id='"+x.userId+x.LegalAgreementId+"'style='margin-left: 0.15rem;'></i>": "")+(x.supplierContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='"+x.supplierIntroducesBy.replaceAll("'",'&#39;')+"' id='"+x.supplierId+x.LegalAgreementId+"'style='margin-left: 0.15rem;'></i>": "")
                        "</td></tr>";

                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    expandCounterPartyTable(id: number) {
        var element = document.getElementById("counterparty_" + id.toString());
        var iconElement = document.getElementById("icon_counterparty_" + id.toString());
        this.changeIcon(element, iconElement);
    }

    private changeIcon(element: HTMLElement, iconElement: HTMLElement) {
        if (element != null) {
            if (element.style.display == "none") {
                element.style.display = "block";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-up ml-2";
                    iconElement.title = this.collapseTitle;
                }

            }
            else {
                element.style.display = "none";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-down ml-2";
                    iconElement.title = this.expandTitle;
                }
            }
        }
    }

    showAddForm() {
        this.modalView.show(LegalAgreementProjectMappingAddComponent, { mapId: this.id, entityTypeId: this.entityTypeId }).then(t => {
            this.isFilter = true;
            this.totalRecords = 0;
            this.legalAgreementProjectGrid.updateSource([]);
            this.bindGrid();
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "LegalAgreementProjectMappingListComponent";
    }
    bindTooltip(id?, introducesBy?) {
        setTimeout(() => {
          showSensitiveContactToolTip(id.toString(), getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + introducesBy);
        }, 100);
    }
}
