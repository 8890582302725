<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Log_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content "  [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0  d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_Log_t"></label></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showTab" [rxLocalisationInit]="componentName">
      <div class="tabs-container people-tabs">
        <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'logs'"
              (click)="activeTab('logs')"><label class="mb-0" rxText="ApplicationExceptionLogListComponent_OldLog_Title"></label></a>
          </li>
          <li>
            <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'exception-logs'"
              (click)="activeTab('exception-logs')"><label class="mb-0"
                rxText="ApplicationExceptionLogListComponent_NewLog_Title"></label></a>
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" [rxLocalisationInit]="componentName" *ngIf="selectedTab == 'logs'" class="tab-pane" [class.active]="selectedTab == 'logs'"
            >
            <div class="panel-body" [formGroup]="applicationExceptionLogsSearchFormGroup" [rxLocalisationInit]="componentName" *ngIf="showComponent">
              <div class="row add-countries-form" (keyup.enter)="onKeyPress()">
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                      rxText="ApplicationExceptionLogListComponent_CreatedForm_t"></label>
                    <div class="col-md-8">
                      <rx-date showAddon="true" formControlName="createdFrom" placeholder="Select Date"
                        pickerClass="form-control" ></rx-date>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4 px-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                      rxText="ApplicationExceptionLogListComponent_CreatedTo_t"></label>
                    <div class="col-md-8">
                      <rx-date showAddon="true" formControlName="createdTo" placeholder="Select Date"
                        pickerClass="form-control" ></rx-date>
                        <small class="form-text text-danger" *ngIf="dateError==true">End Date should
                          be greater than Start Date<br /></small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4 pl-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                      rxText="ApplicationExceptionLogListComponent_Message_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="message" class="form-control"
                        
                        rxPlaceholder="ApplicationExceptionLogListComponent_Message_p" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                      rxText="ApplicationExceptionLogListComponent_ErrorType_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="errorTypeLookUps.errorTypeLookUps" #rxSelect mainClass="form-control"
                        formControlName="errorTypeId" [keyValueProps]="['errorTypeName','errorTypeId']"
                        [selectPlaceholder]="'All'"></rx-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hr-line-dashed border-top w-100"></div>
              <div class=" d-flex flex-wrap align-items-center row">
                <div class="col text-right">
                  <button (click)="onKeyPress()" class="btn btn-success mr-1" rxText="Btn_Search_t"></button>
                  <ng-container *ngIf="logDelete" [rxLocalisationInit]="componentName">
                    <button (click)="clearLog()" class="btn btn-danger" 
                      [disabled]="this.applicationExceptionLogIds.length === 0" rxText="Btn_Clear_Log_t"></button>
                  </ng-container>
                </div>
              </div>
              <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showSearchBar">
                <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
                    <label class="ml-auto mr-0 mb-3">
                      <input (keyup.enter)="search($event)" type="search"
                        class=" table_search form-control form-control-sm pr-4" placeholder="" [title]="titleSearch">
                    </label>
                  </div>
                </div>
              </div>
              <div class="table-responsive" *ngIf="isShowGrid">
                <div id="application-exception-logs" class="dataTables_wrapper pb-0 dt-bootstrap4">
                  <rx-grid [design]="logGrid"></rx-grid>
                </div>
              </div>
              <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                <h4>No Record Found</h4>
              </div>
            </div>
          </div>
          <div role="tabpanel" *ngIf="selectedTab == 'exception-logs'" [class.active]="selectedTab == 'exception-logs'"
            class="tab-pane">
            <div class="panel-body">
              <app-exception-log-list></app-exception-log-list>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>