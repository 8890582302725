import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSupplierLookUpBase {

    //#region SupplierId Prop
    @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'supplierId', keyColumn: true })
    supplierId: number;
    //#endregion SupplierId Prop


    //#region company Prop
    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'company', keyColumn: false })
    company: string;
    //#endregion company Prop


    //#region email Prop
    @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'email', keyColumn: false })
    email: string;
    //#endregion email Prop

    //#region fullName Prop
    @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'fullName', keyColumn: false })
    fullName: string;
    //#endregion fullName Prop

}