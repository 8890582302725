import { gridColumn, actionColumn } from '@rxweb/grid'
import { prop } from '@rxweb/reactive-form-validators'


@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'eyeAction' }, columnIndex: 10, headerTitle: "Action"
 })

 //action column for Select all checkbox
 @actionColumn({
   allowSorting: false,
   style: { width: "3%","text-align": "initial"},
   configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 1
 })
export class ProjectEventMappingViewModel{

  //add this isChecked column for individual checkbox selection
   @gridColumn({
     configuredTemplate: { templateName: 'projectCheckbox' },
     style: { width: "2%", "text-align": "initial" },visible: true,allowSorting:false, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
   isChecked:boolean

   isRestrictedProject:boolean;
   @gridColumn({style: { width: "1%", "text-align": "initial" },visible: false, columnIndex: 2, headerTitle: 'Action', keyColumn: true })
   projectID:number
  //  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"],visible: false, parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'ProjectEventMappingListComponent_Type_gh', keyColumn: false })
   projectType:string
   @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"],visible: true,parameter:false, columnIndex: 5, allowSorting: true, headerKey: 'ProjectEventMappingListComponent_StartDate_gh', keyColumn: false })
   projectStartDate:string
   @gridColumn({style: { width: "10%", "text-align": "initial" }, class: ["text-left"],  visible: true,parameter:false, columnIndex: 6, allowSorting: true, headerKey: 'ProjectEventMappingListComponent_EndDate_gh', keyColumn: false })
   projectEndDate:string
  //  @gridColumn({style: { width: "35%", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 7, allowSorting: true, headerKey: 'noteDescription', keyColumn: false })
   @gridColumn({
      template:{
          div:{
            style: {
               "word-break": "break-all;",
              //  "display": "-webkit-box",
              //  "-webkit-line-clamp": "3",
              //  "-webkit-box-orient": "vertical",
              //  "overflow": "hidden",
              //  "text-overflow": "ellipsis"
             },
            attributes:{
              innerHTML:function(x,y){
               return this.noteDescription;
              }
            },
            childrens:[{
              a:{
            isBind: (x) => {
             // return true;
              if(x.noteCharCount>100 && !x.isShowAll){
                return true;
              }
                else{
                  return false;
                }
            },style:{
              "display":"block"
            },
            childrens: [{
              span: {
                class:"more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClick"
            }
          },
            }]
          }
      },style: { width: "35%", "text-align": "initial" }, class: ["text-left"],  visible: true,parameter:false, columnIndex: 8, allowSorting: false, headerKey: 'ProjectEventMappingListComponent_NoteDesc_gh',name:'noteDescription',keyColumn:false
})
   noteDescription:string
//    @gridColumn({
//     template:{
//         div:{
//           style: {
//              "word-break": "break-all;",
//             //  "display": "-webkit-box",
//             //  "-webkit-line-clamp": "3",
//             //  "-webkit-box-orient": "vertical",
//             //  "overflow": "hidden",
//             //  "text-overflow": "ellipsis"
//            },
//           attributes:{
//             innerHTML:function(x,y){
//              return this.noteDescription1;
//             }
//           }
//         }
//     },style: { width: "35%", "text-align": "initial" }, class: ["text-left"],  visible: false, columnIndex: 8, allowSorting: false, headerKey: 'ProjectEventMappingListComponent_NoteDesc_gh',name:'noteDescription1',keyColumn:false
// })
//  noteDescription1:string
 noteCharCount: any;
   @gridColumn({  visible: false, columnIndex: 7, allowSorting: true, headerTitle: 'eventID', keyColumn: false })
   eventID:number
   @gridColumn({
      template:{
        div:{
            style: {
            "word-break": "break-word;",
          //  "display": "-webkit-box",
          //  "-webkit-line-clamp": "3",
          //  "-webkit-box-orient": "vertical",
          //  "overflow": "hidden",
          //  "text-overflow": "ellipsis"
          },
          attributes:{
            innerHTML:function(x,y){
            return this.prjName;
            }
          },
        }
    },
  style: { width: "16%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 3, allowSorting: true,name:'prjName', headerKey: 'ProjectEventMappingListComponent_Name_gh', keyColumn: false })
  prjName:string


  @gridColumn({
    template:{
      div:{
          style: {
          "word-break": "break-word;",
        //  "display": "-webkit-box",
        //  "-webkit-line-clamp": "3",
        //  "-webkit-box-orient": "vertical",
        //  "overflow": "hidden",
        //  "text-overflow": "ellipsis"
        },
        attributes:{
          innerHTML:function(x,y){
          return this.projectName;
          }
        },
      }
  },
style: { width: "16%", "text-align": "initial" }, class: ["text-left"],visible: false,columnIndex: 3, allowSorting: true, headerKey: 'ProjectEventMappingListComponent_Name_gh',name:'projectName', keyColumn: false, parameter:false })
projectName:string

  //#region projectIndicator Prop
  @gridColumn({ parameter: false, configuredTemplate: { templateName:"progressBar" },style: { width: "15%", "min-width": "150px", "text-align": "initial" }, class: ["text-right"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'ProjectEventMappingListComponent_PrjIndicator_gh', keyColumn: false })
  projectIndicator: number;
  //#endregion projectIndicator Prop

   @prop()
   totalCount:number;
   @prop()
   noteId:number;

   @prop({ defaultValue: 0 })
  peopleOrCorporate:Number;

  @prop()
  eventCustomerId:Number;

  @prop()
  totalRestrictedProjects:number;

}
