import { Component, OnInit, OnDestroy, ComponentFactoryResolver, Input, EventEmitter, ViewContainerRef, ElementRef, HostListener } from "@angular/core"
import { AbstractDocument } from '../domain/abstract-document';
import { Document } from "@app/models";
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe, PlatformLocation } from '@angular/common';
import { DocumentAddComponent } from '../add/document-add.component';
import { DocumentEditComponent } from '../edit/document-edit.component';
import { multilingual } from '@rxweb/localization';
import { DocumentListModel } from '../../../../models/extended-models/DocumentListModel';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { SERVER_URL } from "src/app/domain/system-constant";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { GridColumnConfig } from '@rxweb/grid';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { HttpClient } from "@angular/common/http";

@multilingual("DocumentListComponent")
@Component({
    selector: "app-document-list",
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent extends AbstractDocument implements OnInit, OnDestroy {

  documentListGridColumns: any[];
  files: File[] = [];
  paginationViewModel: PaginationViewModel;
  titleSearch: string;
  isDrag: boolean = false;
  public dragIconId: number;
  public dropTileId: number;
  serverUrl:string = SERVER_URL;
  titleSave: string;
  titleDelete: string;
  titleView: string;
  titleDownload:string;
  deleteHelp : string = '';
  copyHelp : string = '';
  isShowGridColumn: boolean = false;
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";
  @Input() showInnerSpin: boolean = true;
  @Input() disabled:boolean = false;
  footerHeight : number ;
  _this: any;

  constructor(private elementRef: ElementRef,private formBuilder: RxFormBuilder, private router: Router, private activatedRoute: ActivatedRoute,browserStorage:BrowserStorage, componentFactoryResolver: ComponentFactoryResolver, viewContainerRef: ViewContainerRef, modelView: ModalView, datePipe: DatePipe, blobState: BlobUploadsViewStateService, httpClient: HttpClient,location : PlatformLocation) {
    super(browserStorage, blobState, httpClient,location,componentFactoryResolver, viewContainerRef);
    this._this = this;
    this.datePipe = datePipe;
    this.modalView = modelView;
    this.modalView.setResolver(componentFactoryResolver);
    this.activatedRoute.params.subscribe(t => {
      if(t['id'] != null && t['id'] != undefined)
        this.id = t['id'];
    })
    this.deleteHelp = getLocalizedValue("Title_Delete");
    this.copyHelp = getLocalizedValue("Title_Copy");
  }

  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleSave = getLocalizedValue("Title_Save");
    this.titleDelete = getLocalizedValue("Title_Delete");
    this.titleView = getLocalizedValue("Title_View");
    this.titleDownload = getLocalizedValue("Title_Download");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.documentFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<Document>;
    this.documentUpdateFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<Document>;
    this.paginationViewModel = new PaginationViewModel();
    this.paginationViewModel.sortOrder = "false";
    this.paginationViewModel.orderBy = "createdOn";
    this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
    //console.log(this.entityId)
    if(this.isEditorDocument == undefined)
      this.isEditorDocument = false;
    if(this.entityId != undefined && this.isEditorDocument) {
      this.id = this.entityId;
    }
    if(this.entityTypeId == NotesEnum.CustomerNotes || 
      this.entityTypeId == NotesEnum.CorporateGroupsNotes || 
      this.entityTypeId == NotesEnum.ProjectNotes || 
      this.entityTypeId == NotesEnum.EventNotes || 
      this.entityTypeId ==  NotesEnum.RiskNotes ||
      this.entityTypeId == NotesEnum.IncidentAccidentNearDashMissNotes
      ){
      this.disableDragDrop = true;
    }
    if(
      this.entityTypeId == NotesEnum.CustomerNotes || 
      this.entityTypeId == NotesEnum.CorporateGroupsNotes ||
      this.entityTypeId == NotesEnum.IncidentAccidentNearDashMissNotes){
      this.isUserorSupplier = true;
    }
    if (this.entityTypeId == NotesEnum.ElectronicGiftFormNotes || this.entityTypeId == NotesEnum.ProductionOrderFormNotes
      || this.entityTypeId == NotesEnum.CarnetsNotes || this.entityTypeId == NotesEnum.WatchPartNotes
      || this.entityTypeId == NotesEnum.POSNotes || this.entityTypeId == NotesEnum.BoutiqueNotes
      || this.entityTypeId == NotesEnum.MainCollectionNotes || this.entityTypeId == NotesEnum.DesignCategoryNotes
      || this.entityTypeId == NotesEnum.CustomisedPageNotes || this.entityTypeId == NotesEnum.ProductionOrderFormInfoNotes) {
      this.isAllowMoveDocuments = false;
    }

    this.delete({ params: [1] , body: null, queryParams: { isPicture: false, entityTypeId: this.entityTypeId, flag : true, "EntityId": this.id } }).subscribe(data => {
      this.get({
        path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
        this.getColumnsData = t;
        if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
            this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
        }
        setTimeout(() => {
            this.bindGrid();
        }, 0);
    })
    });
    //this.bindGrid();
    var footerelement=document.querySelector(".footer");
    this.footerHeight=footerelement!=null?(footerelement as HTMLDivElement).offsetHeight:40;
  }

  @HostListener('window:scroll', ['$event'])

  onWindowScroll(event) {
    if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight)- this.footerHeight) {
      if( this.isPicture && (this.documentListModel.length + this.deletedCount) < this.totalRecords && !this.loading)
      {
        this.bindOnScroll();
      }
    }
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.totalRecords = 0;
      this.json["searchString"] = value.target.value;
      this.json["pageIndex"]=1;
      this.trackArray = new Array<DocumentListModel>();
     if(this.documentListGrid!=null){
       this.documentListGrid.storeProcedure.nextPage=1;
     }
      this.bindGrid();
    }, 0);
    this.spin = true;
  }


  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();


  }
  openFullScreen(imageId: any, url: any, event:Event) {
    event.preventDefault();
    const element = document.getElementById(imageId) as HTMLAnchorElement | null;
    if (element) {
      const currentHref = element.href;
      element.href = url;
      element.click();
      setTimeout(() => {
        element.href = currentHref;
      }, 500); 
    }
  }

  
  
  public onIconDropped(ev) {
    ev.drag.dropFinished();
  }

  public onEnterHandler(ev): void {
    this.dropTileId = parseInt(ev.owner.element.nativeElement.id, 10);
    // the event gets raised immediately, but we want to swap only when we drag over another icon
    if (this.dragIconId === this.dropTileId) {
      return;
    }
    const dragIndex = this.documentListModel.findIndex(
      (iconObj) => iconObj.pictureId === this.dragIconId
    );
    const dropIndex = this.documentListModel.findIndex(
      (iconObj) => iconObj.pictureId === this.dropTileId
    );
  }
  public dragStartHandler(id: string): void {
    this.dragIconId = parseInt(id, 10);
    //console.log('docstartId', this.dragIconId);

  }

  public dragEndHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'visible';
    //console.log('docEndId', this.dropTileId);


    this.documentUpdateFormGroup.patchValue({
          sourceId: this.dragIconId,
          destinationId: this.dropTileId,
          displayOrder: 1,
          entityId:  this.id,
          entityTypeId: this.entityTypeId,
          isPicture:true,
          isDrag:true,
          ModuleUrl:window.location.href

        })

        if(//this.documentUpdateFormGroup.controls.entityTypeId.value!=NotesEnum.NewsNotes &&
           this.documentUpdateFormGroup.controls.destinationId.value != null &&
          this.documentUpdateFormGroup.controls.destinationId.value != undefined)
        {
          this.put({ body: this.documentUpdateFormGroup.toFormData(), params: [0] }).subscribe(data => {
            this.spin = false;
            //this.baseToastr.success("Data Updated successfully");
            var existsAlert = getLocalizedValue("Data_Updated");
            if (existsAlert) {
             this.baseToastr.success(existsAlert);
           }
           this.bindGrid();
          })
        }


  }

  public ghostCreateHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'hidden';
  }

  urlExistOrNot(variable) {
    var image = new Image();
    var url_image = './ImageFolder/' + variable + '.jpg';
    image.src = variable;
    if (image.width == 0) {
      return "../assets/images/dimond.jpg";
    } else {
      return variable;
    }
  }

  onSelect(event) {
    this.onFileChange(event.addedFiles, this.formBuilder);
  }

  onRemove(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  hasComment(){
    if(this.entityTypeId==NotesEnum.GemStoneNotes){
      return false;
    }
    else{
      return true;
    }
  }
  fabergeNews()
  {
    if(this.entityTypeId==NotesEnum.NewsNotes)
    {
      return true;
    }
    else{
      return false;
    }
  }
  onCopy(url : string)
  {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  onEdit(userProject: DocumentListModel) {
    this.spin = true;
    this.documentUpdateFormGroup.patchValue({
      documentId: userProject.documentId,
      createdOn: userProject.createdOn,
      documentDescription: userProject.documentDescription,
      fileName: userProject.fileName,
      entityId: userProject.entityId,
      entityTypeId: userProject.entityTypeId,
      noteId: userProject.noteId,
      seeOnline: userProject.seeOnline,
      isPicture: userProject.isPicture,
      createdBy: userProject.createdBy,
      displayOrder: userProject.displayOrder,
      isDrag : false,
      ModuleUrl:window.location.href
    })

    this.put({ body: this.documentUpdateFormGroup.toFormData(), params: [userProject.documentId] }).subscribe(data => {
      this.spin = false;
      //this.baseToastr.success("Data Updated successfully");
      var existsAlert = getLocalizedValue("Data_Updated");
      if (existsAlert) {
       this.baseToastr.success(existsAlert);
     }
     if(!this.isPicture)
      this.bindGrid();
    })
  }

  discriptionChange(discription: any, userProject: DocumentListModel) {
    userProject.documentDescription = discription.target.value;

  }
  displayOrderChange(order :any,userProject: DocumentListModel)
  {
    if(userProject.displayOrder == null)
    {
      userProject.displayOrder=1;
    }
    else
    {
    userProject.displayOrder=order.target.value;
    }
  }

  get componentName(): string {
    return "DocumentListComponent";
 }

  onImageError(event:any){
    event.target.src=this.defaultThumbnailPath;
  }

  onVideoError(event:any){
    let imgElement = document.createElement("img");
    imgElement.setAttribute("src", this.defaultThumbnailPath);
    imgElement.setAttribute("class", 'img-fluid pic-tab-gallary-img');
    event.target.parentElement.parentElement.appendChild(imgElement);
    event.target.parentElement.parentElement.removeChild(event.target.parentElement);
  }

}
