import { Injectable, Inject } from '@angular/core';
import { IAuthorize, AuthorizeConfig } from '@rxweb/angular-router';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { RxHttp } from '@rxweb/http';
import { resolve } from 'dns';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { DOCUMENT } from '@angular/common';
import { PreviousPath } from 'src/app/components/start/app.component';
import { getLocalizedValue } from '../common-logic/common-logic';


export var dbAccessModule : any ;

@Injectable({
    providedIn: 'root',
})
export class AuthorizationResolver implements IAuthorize {
    toastr: BaseToastr;
    constructor(private http: RxHttp, private router: Router) {
        this.toastr = new BaseToastr();
        
    }

    authorize(authorizeConfig: AuthorizeConfig, route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
        let currentURL = window.location.href;
        var previousURL=(window.location.href.split('/'));
        var splitData=(currentURL.split('/')[4]);
    
        var promise = new Promise<boolean>((resolve, reject) => {
            var t = this;
            if (dbAccessModule == undefined || dbAccessModule == null) {

                this.http.get<string>({ path: "api/Authorize/access", }).subscribe(response => {
                    dbAccessModule = JSON.parse(response);

                    //resolve(dbAccessModule);
                    let isAccess = this.verifyAuthorization(authorizeConfig);
                    resolve(isAccess);
                    if(!isAccess)
                    {
                        // this.router.navigate(["/dashboard"])
                        this.toastr.info(getLocalizedValue("WarningMessage_DontAccessKey_t"));
                        setTimeout(() => {
                            this.router.navigate(["/"+  (['it-assets', 'desktop-assets', 'mobile-assets'].some(asset => previousURL[3].includes(asset))) ? 'dashboard' : previousURL[3]
                        ])
                        }, 2000);
                    }

                })
            }
            else{
                let isAccess = this.verifyAuthorization(authorizeConfig);
                resolve(isAccess);
                if(!isAccess)
                {
                        this.toastr.info(getLocalizedValue("WarningMessage_DontAccessKey_t"));
                        setTimeout(() => {
                            this.router.navigate([ "/"+ (['it-assets', 'desktop-assets', 'mobile-assets'].some(asset => previousURL[3].includes(asset))) ? 'dashboard' : previousURL[3]
                        ])
                        }, 2000);
                       
                }

            }
        })
        return promise;
    }

    verifyAuthorization(authorizeConfig: AuthorizeConfig): boolean{
        return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action]
    }

    authorizeChildren(authorizeConfig: AuthorizeConfig, pageAuthorizeConfig: AuthorizeConfig) {

        if (dbAccessModule === undefined || dbAccessModule == null) {
            const promise = new Promise<boolean>((resolve, reject) => {
                this.http.get<string>({ path: 'api/Authorize/access' }).subscribe(response => {
                    dbAccessModule = JSON.parse(response);
                    const result = this.verifyAuthorization(authorizeConfig);
                    resolve(result);
                });
            });
            return promise;
        } else {
            return this.verifyAuthorization(authorizeConfig);
        }

    }

    clearPermissions() {
        dbAccessModule = null;
    }
}
