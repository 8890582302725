import {vSubscriptionLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSubscriptionLookUp extends vSubscriptionLookUpBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}