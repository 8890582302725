import { routerModule, IMultilingual } from '@rxweb/angular-router';
import { NgModule } from '@angular/core';


import { ComponentMultilingualContentResolver } from './domain/localization/component-multilingual-content.resolver';
import { GlobalMultilingualContentResolver } from './domain/localization/global-multilingual-content.resolver';

import { AuthorizationResolver } from './domain/security/authorization-resolver';
import { AuthResolver } from './domain/security/authentication-resolver';
// import { AccessService } from './domain/security/middleware/access-resolver';


@routerModule({
  // authentication: AuthResolver,
  authorization: AuthorizationResolver,
  middlewares: [GlobalMultilingualContentResolver, ComponentMultilingualContentResolver],
  urlEncryption: false,
  childMultilingualResolver: ComponentMultilingualContentResolver,
})
@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [],
})
export class RxWebModule {

}
