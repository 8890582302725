import {vHelpSupportUserRoleRecordBase,} from '@app/database-models'
//Generated Imports
export class vHelpSupportUserRoleRecord extends vHelpSupportUserRoleRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}