import { prop, propObject, propArray, required, maxLength, range, notEmpty, numeric, email, alpha, trim, alphaNumeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AddressBase {

        constructor() {
                //this.stateProvinceID = 0;
        }
        //#region addressId Prop
        @prop()
        addressId: number;
        //#endregion addressId Prop


        //#region userId Prop
        @prop()
        userId: number;
        //#endregion userId Prop


        //#region supplierId Prop
        @prop()
        supplierId: number;
        //#endregion supplierId Prop


        //#region isBillingAddress Prop
        @prop({ defaultValue: false })
        isBillingAddress: boolean;
        //#endregion isBillingAddress Prop


        //#region firstName Prop
        //@required()
        //@notEmpty()
        @prop({defaultValue:''})
        @maxLength({ value: 100 })
        //@alphaNumeric({allowWhiteSpace:true})
        @trim()
        firstName: string;
        //#endregion firstName Prop


        //#region lastName Prop
        @required()
        @maxLength({ value: 100 })
        //@alphaNumeric({allowWhiteSpace:true})
        @notEmpty()
        @trim()
        lastName: string;
        //#endregion lastName Prop


        //#region phoneNumber Prop
        //@required()
        @prop()
        //@maxLength({ value: 50 })
        //@numeric()
        //@notEmpty()
        @trim()
        phoneNumber: string;
        //#endregion phoneNumber Prop


        //#region email Prop
        //@required()
        @prop({defaultValue:''})
        @maxLength({ value: 255 })
        @email()
        // @notEmpty()
        @trim()
        email: string;
        //#endregion email Prop


        //#region faxNumber Prop
        @prop({defaultValue:''})
        //@required()
        @maxLength({ value:50 })
        // @numeric()
        // @notEmpty()
        @trim()
        faxNumber: string;
        //#endregion faxNumber Prop


        //#region company Prop
        //@required()
        @prop({defaultValue:''})
        @maxLength({ value: 100 })
        // @notEmpty()
        @trim()
        company: string;
        //#endregion company Prop


        //#region address1 Prop
        @required()
        @maxLength({ value: 100 })
        @notEmpty()
        @trim()
        address1: string;
        //#endregion address1 Prop


        //#region address2 Prop
        //@required()
        @prop({defaultValue:''})
        @maxLength({ value: 100 })
        //@notEmpty()
        @trim()
        address2: string;
        //#endregion address2 Prop


        //#region city Prop
        @required()
        @maxLength({ value: 100 })
        //@alpha({ allowWhiteSpace: true })
        @notEmpty()
        @trim()
        city: string;
        //#endregion city Prop


        //#region stateProvinceID Prop
       //  @range({minimumNumber:0,maximumNumber:2147483647})
        //  @required()
        @prop({defaultValue:0})
        stateProvinceID: number;
        //#endregion stateProvinceID Prop


        //#region zipPostalCode Prop
        @required()
        @notEmpty()
        @maxLength({ value: 30 })
        @trim()
        //@alphaNumeric({allowWhiteSpace:true})
        zipPostalCode: string;
        //#endregion zipPostalCode Prop


        //#region countryID Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        countryID: number;
        //#endregion countryID Prop


        //#region createdOn Prop
        @required()
        createdOn: Date;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @required()
        updatedOn: Date;
        //#endregion updatedOn Prop


        //#region isImpersonated Prop
        @prop({ defaultValue: false })
        isImpersonated: boolean;
        //#endregion isImpersonated Prop


        //#region stateProvinceName Prop
        @maxLength({ value: 100 })
        //@alpha({allowWhiteSpace:true})
        @trim()
        stateProvinceName: string;
        //#endregion stateProvinceName Prop


        //#region stateID Prop
        @prop()
        stateID: number;
        //#endregion stateID Prop


        //#region statusId Prop
        @prop()
        statusId: number;
        //#endregion statusId Prop





}