<div class="modal-dialog modal-lg" *rxMultilingual="">
    <div class="modal-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="OwnershipMappingAddComponent_Title_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body pb-0" [formGroup]="ownershipMappingFormGroup"
            [rxSpinner]="spin">
            <div class="row">
              <div class="col-12">
                <div class="form-group row" *ngIf="componentType != 'user-edit'" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-form-label required"
                    rxText="OwnershipMappingAddEditComponent_PersonCorporate_t"> </label>

                  <div class="col-md-8">
                    <div class="row col-md-6 d-flex justify-content-between align-items-center">
                        <!-- <div class="col-md-6 d-flex align-items-center">
                            <input type="radio" class="form-control w-auto" name="peopleOrCorporate" [value]="0"
                                formControlName="peopleOrCorporate" (change)="changePeopleOrCorporate(0)"><label
                                class="mb-0 ml-1" rxText="OwnershipMappingAddEditComponent_Person_t"></label>
                        </div>
                        <div class="col-md-6 d-flex align-items-center">
                            <input type="radio" class="form-control w-auto" name="peopleOrCorporate" [value]="1"
                                formControlName="peopleOrCorporate" (change)="changePeopleOrCorporate(1)"><label
                                class="mb-0 ml-1" rxText="OwnershipMappingAddEditComponent_Corporate_t"></label>
                        </div> -->
                        <div class="i-checks mr-2 ">
                            <label class="mb-0">
                                <div class="iradio_square-green"
                                    [class.checked]="ownershipMappingFormGroup.value.peopleOrCorporate==0"
                                    style="position: relative;">
                                    <input (change)="changePeopleOrCorporate(0)" type="radio" value="0"
                                        formControlName="peopleOrCorporate" style="visibility: hidden;">
                                </div>
                                <label class="mb-0 ml-1" rxText="OwnershipMappingAddEditComponent_Person_t"></label>
                            </label>
                        </div>

                        <div class="i-checks">
                            <label class="mb-0">
                                <div class="iradio_square-green"
                                    [class.checked]="ownershipMappingFormGroup.value.peopleOrCorporate==1"
                                    style="position: relative;">
                                    <input (change)="changePeopleOrCorporate(1)" type="radio" value="1"
                                        formControlName="peopleOrCorporate" style="visibility: hidden;">
                                </div>
                                <label class="mb-0 ml-1" rxText="OwnershipMappingAddEditComponent_Corporate_t"></label>
                            </label>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                      rxText="OwnershipMappingAddEditComponent_StartDate_t"></label>
                  <div class="col-md-8">
                      <rx-date showAddon="true" formControlName="startDate" pickerClass="form-control"
                          placeholder="Select Start Date">
                          <!-- (onSelected)="validateDate()" -->
                      </rx-date>
                      <small class="form-text text-danger"
                          [class.d-block]="ownershipMappingFormGroup.controls.startDate.errors">{{ownershipMappingFormGroup.controls.startDate.errorMessage}}</small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                      rxText="OwnershipMappingAddEditComponent_EndDate_t"></label>
                  <div class="col-md-8">
                      <rx-date showAddon="true" formControlName="endDate" pickerClass="form-control"
                          placeholder="Select End Date">
                          <!-- (onSelected)="validateDate()" -->
                      </rx-date>
                      <small class="form-text text-danger"
                          [class.d-block]="ownershipMappingFormGroup.controls.endDate.errors">{{ownershipMappingFormGroup.controls.endDate.errorMessage}}</small>
                      <small class="form-text text-danger mt-2" *ngIf="dateError">
                          End Date should be greater than Start Date
                      </small>
                  </div>
                </div>
              </div>
              <div class="col-md-6">

                <div class="form-group row" *ngIf="peopleOrCorporate == 1" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
                      rxText="OwnershipMappingAddEditComponent_Corporate_t"></label>
                  <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <rx-select autoComplete="true" mainClass="form-control" formControlName="companyID"
                          [params]="['suppliers']" path="api/SearchEvent/GetLookups" [keyValueProps]="['#name#','id']"
                          [minimumCharacterSearchLength]="3">
                      </rx-select> -->

                      <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="companyID"
                          [queryParams]="{'lookupType':'ownershipSupplier'}" path="api/SearchEvent/GetLookups"
                          [keyValueProps]="['#name#','id']" [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                          [selectPlaceholder]="'Select Corporate'">
                      </rx-select>

                      <small class="form-text text-danger"
                          [class.d-block]="ownershipMappingFormGroup.controls.companyID.errors">{{ownershipMappingFormGroup.controls.companyID.errorMessage}}</small>
                      <!-- <small class="form-text text-danger" *ngIf="corporateError">Please select Company</small> -->
                  </div>
                </div>
                <div class="form-group row" *ngIf="peopleOrCorporate == 0" [rxLocalisationInit]="componentName">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
                      rxText="OwnershipMappingAddEditComponent_Person_t"></label>
                  <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select #rxSelect autoComplete="true" mainClass="form-control" formControlName="customerID"
                          [queryParams]="{'lookupType':'ownershipUser'}" path="api/SearchEvent/GetLookups"
                          [keyValueProps]="['#name#','id']" [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                          [selectPlaceholder]="'Select Person'">
                      </rx-select>
                      <small class="form-text text-danger"
                          [class.d-block]="ownershipMappingFormGroup.controls.customerID.errors">{{ownershipMappingFormGroup.controls.customerID.errorMessage}}</small>
                      <!-- <small class="form-text text-danger" *ngIf="personError">Please select Person</small> -->
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
                      rxText="OwnershipMappingAddEditComponent_Percentage_t"></label>
                  <div class="col-md-8">
                      <input type="text" class="form-control" formControlName="ownershipPerc"
                          rxPlaceholder="OwnershipMappingAddEditComponent_Percentage_p">
                      <small class="form-text text-danger"
                          [class.d-block]="ownershipMappingFormGroup.controls.ownershipPerc.errors">{{ownershipMappingFormGroup.controls.ownershipPerc.errorMessage}}</small>
                      <!-- <small class="form-text text-danger"
                          [class.d-block]="ownershipMappingFormGroup.controls.ownershipPerc.errors">{{ownershipMappingFormGroup.controls.ownershipPerc.errorMessage}}</small> -->
                  </div>
                </div>
              </div>
            </div>


        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="saveOwnershipMappings()" rxText="Btn_Save_t"></button>
            <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
        </div>
    </div>
</div>

<style>
    .required:after {
        content: " *";
        color: red;
    }
</style>
