import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRoleLookUpBase {

//#region roleId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'roleId', keyColumn: true})
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'roleName', keyColumn: false})
        roleName : string;
//#endregion roleName Prop

  //#region roleName Prop
  @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'roleTypeId', keyColumn: false })
  roleType: number;
//#endregion roleName Prop

  //#region roleName Prop
  @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'roleDomainId', keyColumn: false })
  roleDomainId: number;
//#endregion roleName Prop



}
