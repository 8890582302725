import { TemplateConfig } from '@rxweb/grid';

export const POS_PIECE_NAME_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                span: {
                    childrens: [{
                        text: {
                            text: function (e) {
                                var product = "";
                                product = this.productName + " " + this.productVariantName;
                                return product;
                            }
                        }
                    }]
                }
            }
        ]
    }
}


export const POS_PIECE_REDIRECT_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onSerialNoLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    },
}

export const POS_PRINT_CERTIFICATE_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onPrintCertificate"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    // attributes: {
                    //     href: function (e) {
                    //         if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                    //             return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                    //         }
                    //     },
                    //     "ng-reflect-router-link": function (e) {
                    //         if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                    //             return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                    //         }
                    //     },
                    //     onClick: "return false;"
                    // }
                }
            }
        ]
    },
}