import {IncidentPersonsAffectedCategoryBase,LookupPersonsAffectedCategoryBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class IncidentPersonsAffectedCategory extends IncidentPersonsAffectedCategoryBase 
{




//#region Generated Reference Properties
//#region lookupPersonsAffectedCategory Prop
lookupPersonsAffectedCategory : LookupPersonsAffectedCategoryBase;
//#endregion lookupPersonsAffectedCategory Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties






































}