import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { LanguageContentKey, LanguageContent, ComponentLanguageContent } from '@app/models';
import { AbstractLanguageContentKey } from '../domain/abstract-language-content-key';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { LocalizationFormViewModel } from 'src/app/view-model/localization-form-view-model';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { LanguageContentKeyEditComponent } from '../edit/language-content-key-edit.component';
import * as CryptoJS from 'crypto-js';
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("LanguageContentKeyAddComponent")
@access({ accessLevel: RolePermissionEnums.Localization, action: "post" })
@Component({
    selector: "app-language-content-key-add",
    templateUrl: './language-content-key-add.component.html'
})
export class LanguageContentKeyAddComponent extends AbstractLanguageContentKey implements OnInit, OnDestroy {
    languageContentKey: LanguageContentKey;
    languageContents: LanguageContent;
    componentLanguage: ComponentLanguageContent;
    localization: LocalizationFormViewModel;
    subscription: any;
    router: Router;
    localizationEdit: any = LanguageContentKeyEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    isEditor: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder, router: Router, private el: ElementRef, private title: Title, modelView: ModalView) {
        super();
        this.router = router;
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.Localization;
        this._this = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Localisation - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.spin = true;
        this.lookup([{ path: USER_LOOKUPS["languageUserLookUps"], propName: "languageUserLookUp" }, { path: USER_LOOKUPS["localizationComponents"], propName: "localizationComponents" }]).subscribe(response => {
            this.localizationLookup = response;
            this.localization = new LocalizationFormViewModel();
            this.languageContentKey = new LanguageContentKey();
            this.languageContents = new LanguageContent();
            this.componentLanguage = new ComponentLanguageContent();
            this.languageContentKey.languageContents = new Array<LanguageContent>();
            this.languageContentKey.componentLanguageContents = new Array<ComponentLanguageContent>();
            this.localizationFormGroup = this.formBuilder.formGroup(this.localization) as IFormGroup<LocalizationFormViewModel>;
            this.showComponent = true;
            this.spin = false;
        });
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }
    changeContentType(key:any)
    {
        if(key=='gn')
        {
            this.isEditor=true;
        }
        else{
            this.isEditor=false;
        }
    }
    saveLocalization(isRedirect: boolean) {
        this.localizationFormGroup.submitted = true;
        if (this.localizationFormGroup.valid) {
            this.spin = true;
            this.languageContents.languageContentId = 0;
            this.languageContents.languageContentKeyId = 0;
            this.languageContents.contentType = this.localization.contentType;
            this.languageContents.en = this.localization.en;
            this.languageContents.m_en = this.localization.m_en;
            this.languageContents.fr = this.localization.fr == null ? this.localization.en + "---" : this.localization.fr;
            this.componentLanguage.componentKeyId = this.localization.id;
            this.componentLanguage.en = "";
            this.componentLanguage.m_en = "";
            this.componentLanguage.fr = "";
            this.languageContentKey.languageContentKeyId = 0;
            this.languageContentKey.keyName = this.localization.keyName;
            this.languageContentKey.isComponent = false;
            this.languageContentKey.languageContents.push(this.languageContents);
            this.languageContentKey.componentLanguageContents.push(this.componentLanguage);
            this.post({
                body: this.languageContentKey
            }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                if (existsAlert) {
                    //this.toastr.success("Data Added Successfully")
                    this.toastr.success(existsAlert[0].showMessage);
                }
                let CT: string = "";
                CT = this.contentTypeArray[this.contentTypeArray.findIndex(x => x.id == this.localization.contentType)].id;
                isRedirect ? this.router.navigate(['/language-content-keys', data, CT]) : this.router.navigate(['/language-content-keys']);
            });
        } else {
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.localizationFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "LanguageContentKeyAddComponent";
    }
}
