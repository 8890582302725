import { FileDetailViewModel } from './file-detail-view-model';
import { prop } from '@rxweb/reactive-form-validators';

export class ImportUserFromExcelViewModel implements FileDetailViewModel {

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop() 
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1:string;
    @prop()
    fileExtention1:string;
    @prop()
    fileName1:string;
    @prop()
    fileData2:string;
    @prop()
    fileExtention2:string;
    @prop()
    fileName2:string;
    @prop()
    productId:string;
    @prop()
    filePath: string;
    @prop()
    excelFile : string;

}