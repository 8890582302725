<ng-container [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/countries']" rxText="Label_Countries_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="CountryEditComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>
  <div class="wrapper wrapper-content flex-grow-1" *ngIf="showComponent" [formGroup]="countryFormGroup"
    [rxSpinner]="spin" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="CountryEditComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">

        <div class="row add-countries-form" (keyup.enter)="editCountry(false)">
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CountryEditComponent_ID_t"></label>
              <div class="col-md-8">
                <label type="text"
                            class="form-control disableLabel-withoutheight mb-0">{{(countryFormGroup.value.countryId
                            === null || countryFormGroup.value.countryId === undefined ) ? '' :
                            countryFormGroup.value.countryId}}</label>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_Language_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <!-- <select class="form-control" formControlName="languageId"
                  (change)="changeLanguage(countryFormGroup.value.languageId)" rxFocus>
                  <option *ngFor="let item of countriesLookup.languageLookUp" [value]="item.languageId">
                    {{item.languageName}}</option>
                </select> -->
                <rx-select [controlTabIndex]="1" #rxSelect [(source)]="countriesLookup.languageLookUp" mainClass="form-control"
                  formControlName="languageId" [selectPlaceholder]="'Select Language'"
                  (changed)="changeLanguage($event.item.key)" rxFocus
                  [keyValueProps]="['languageName','languageId']"></rx-select>
                <!-- <small class="form-text text-danger" *ngIf="detail.controls.languageId.errors">{{detail.controls.languageId.errorMessage}}<br /></small> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row mb-0">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_Name_t"></label>
              <div class="col-md-8">
                <input tabindex="2" type="text" class="form-control" formControlName="name"
                  rxPlaceholder="CountryAddEditomponent_Name_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.name.errors">{{countryFormGroup.controls.name.errorMessage}}<br /></small>
              </div>
            </div>
          </div>
        </div>
        <div class="hr-line-dashed border-top row"></div>
        <div class="row add-countries-form" (keyup.enter)="editCountry(false)">
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_AllowReg_t"> </label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="allowsRegistration"><i></i> -->
                    <app-checkbox [tabIndex]="'3'" [checkFormControl]="countryFormGroup.controls.allowsRegistration">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.allowsRegistration.errors">{{countryFormGroup.controls.allowsRegistration.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_ThreeLetISOCode_t"></label>
              <div class="col-md-8">
                <input tabindex="4" type="text" class="form-control" formControlName="threeLetterISOCode"
                  rxPlaceholder="CountryAddEditomponent_ThreeLetISOCode_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.threeLetterISOCode.errors">{{countryFormGroup.controls.threeLetterISOCode.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_StoreDuty_t"> </label>
              <div class="col-md-8">
                <input tabindex="5" type="text" class="form-control" formControlName="dutyPer"
                  rxPlaceholder="CountryAddEditomponent_StoreDuty_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.dutyPer.errors">{{countryFormGroup.controls.dutyPer.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_AllowBilling_t"> </label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="allowsBilling"><i></i> -->
                    <app-checkbox [tabIndex]="'6'" [checkFormControl]="countryFormGroup.controls.allowsBilling">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.allowsBilling.errors">{{countryFormGroup.controls.allowsBilling.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_NumericISOCode_t"> </label>
              <div class="col-md-8">
                <input tabindex="7" type="text" class="form-control" formControlName="numericISOCode"
                  rxPlaceholder="CountryAddEditomponent_NumericISOCode_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.numericISOCode.errors">{{countryFormGroup.controls.numericISOCode.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_InsuCharge_t"> </label>
              <div class="col-md-8">
                <input tabindex="8" type="text" class="form-control" formControlName="insurancePer"
                  rxPlaceholder="CountryAddEditomponent_InsuCharge_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.insurancePer.errors">{{countryFormGroup.controls.insurancePer.errorMessage}}</small>
              </div>
            </div>
          </div>



          <div class="col-lg-6 col-xl-4 pr-xl-5">


            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_AllowShipping_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="allowsShipping"><i></i> -->
                    <app-checkbox [tabIndex]="'9'" [checkFormControl]="countryFormGroup.controls.allowsShipping">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.allowsShipping.errors">{{countryFormGroup.controls.allowsShipping.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 px-xl-4">

            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_SubToVAT_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="allowPOBShipping"><i></i> -->
                    <app-checkbox [tabIndex]="'10'" [checkFormControl]="countryFormGroup.controls.allowPOBShipping">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.subjectToVAT.errors">{{countryFormGroup.controls.subjectToVAT.errorMessage}}</small>
              </div>
            </div>

          </div>

          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row m-b-0">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_Domain_t"></label>
              <div class="col-md-8">
                <!-- <div class="i-checks" *ngFor="let item of countriesLookup.customerRoleDomainLookUp">
                  <label class="mb-0">
                    <input type="radio" [value]="item.customerRoleDomainId" formControlName="domain"> <i></i>
                    {{item.customerRoleDomainName}}
                  </label>
                </div> -->
                <div class="d-flex justify-content-between align-items-center">
                  <div class="i-checks" *ngFor="let item of countriesLookup.customerRoleDomainLookUp">
                    <label class="mb-0">
                      <div class="iradio_square-green"
                        [class.checked]="countryFormGroup.value.domain==item.customerRoleDomainId"
                        style="position: relative;">
                        <input tabindex="11" type="radio" [value]="item.customerRoleDomainId" formControlName="domain"
                          style="visibility: hidden;">
                      </div><i></i>{{item.customerRoleDomainName}}
                    </label>
                  </div>
                </div>
              
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.domain.errors">{{countryFormGroup.controls.domain.errorMessage}}</small>
              </div>
            </div>
          </div>


          <div class="col-lg-6 col-xl-4 pr-xl-5">

            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_AllowPOBShip_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="subjectToVAT"><i></i> -->
                    <app-checkbox [tabIndex]="'12'" [checkFormControl]="countryFormGroup.controls.subjectToVAT">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.allowPOBShipping.errors">{{countryFormGroup.controls.allowPOBShipping.errorMessage}}</small>
              </div>
            </div>

          </div>

          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_Published_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="published"><i></i> -->
                    <app-checkbox [tabIndex]="'13'" [checkFormControl]="countryFormGroup.controls.published">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.published.errors">{{countryFormGroup.controls.published.errorMessage}}</small>
              </div>
            </div>
          </div>


          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_TwoLetISOCode_t"></label>
              <div class="col-md-8">
                <input tabindex="14" type="text" class="form-control" formControlName="twoLetterISOCode"
                  rxPlaceholder="CountryAddEditomponent_TwoLetISOCode_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.twoLetterISOCode.errors">{{countryFormGroup.controls.twoLetterISOCode.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_DisplayPriority_t"> </label>
              <div class="col-md-8">
                <input tabindex="15" type="text" class="form-control" formControlName="displayOrder"
                  rxPlaceholder="CountryAddEditomponent_DisplayPriority_p">
                <small class="form-text text-danger"
                  [class.d-block]="countryFormGroup.controls.displayOrder.errors">{{countryFormGroup.controls.displayOrder.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="CountryAddEditomponent_Region_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [controlTabIndex]="1" #rxSelect [(source)]="countriesLookup.regionsLookup" mainClass="form-control"
                  formControlName="regionId" [selectPlaceholder]="'Select Region'"
                  rxFocus
                  [keyValueProps]="['regionName','regionId']"></rx-select>
                <small class="form-text text-danger" *ngIf="countryFormGroup.controls.regionId.errors">{{countryFormGroup.controls.regionId.errorMessage}}<br /></small>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="editCountry(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="editCountry(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
            <ng-container [rxLocalisationInit]="componentName" *ngIf="countryDelete">
              <button (click)="deleteCountry()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
