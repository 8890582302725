import { prop } from '@rxweb/reactive-form-validators';
import { WatchPartBase } from '../database-models/watch-part-base';
//Generated Imports
export class WatchPart extends WatchPartBase {

    @prop()
    totalValueUSD: string;
    @prop()
    totalValue: string;
    @prop()
    totalPreciousWeight: number;
    @prop()
    totalWeight: number;
    @prop()
    leftOverQuantity: number;

}