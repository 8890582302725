import { ACTION_GRID_TEMPLATE, ACTION_EDIT_TEMPLATE, ACTION_DELETE_TEMPLATE, ACTION_VIEW_TEMPLATE, ACTION_SHOPPING_BAG_TEMPLATE, ACTION_GRID_TEMPLATE_CORPORATE, ACTION_VIEW_TEMPLATE_LEAVE, ACTION_SUPPLIER_POS_TEMPLATE, ACTION_HISTORY_MIRFABERGE_TEMPLATE, ACTION_FOR_MODEL_EDIT_TEMPLATE, DASHBOARD_ACTION_VIEW_TEMPLATE, IAN_GRID_TEMPLATE, ACTION_GRID_TEMPLATE_LOGS, ACTION_GRID_SPECIFICRISK, ACTION_GRID_FABGEMSTONE, ACTION_GRID_TEMPLATE_IAN, IAN_GRID_BUTTON_TEMPLATE, LEGAL_ARGREEMENT_SPECIFICRISK_VIEW_LIST_GRID, ACTION_GRID_TEMPLATE_PRODUCTION, IAN_GRIDHEADER_BUTTON_TEMPLATE, ACTION_FOR_MODEL_VIEW_TEMPLATE, ACTION_FOR_MODEL_LIST_TEMPLATE, ACTION_GRID_TEMPLATE_FLOPPY, ACTION_DELETE_GINA_TEMPLATE, ACTION_FOR_MODEL_VIEW_TEMPLATE_MIND_MAP, ACTION_LEGAL_AGREEMENT_DELETE_TEMPLATE, ACTION_LEGAL_AGREEMENT_GRID_TEMPLATE, ACTION_LEGAL_AGREEMENT_MAPPING_DELETE_TEMPLATE, ACTION_FOR_MODEL_VIEW_TEMPLATE_REPORTING_HEAD, ACTION_GRID_TEMPLATE_GROUP_COMPANY, ITHELPDESK_DELETEACTION_GRID_TEMPLATE, ACTION_VIEW_TEMPLATE_ACCESS_REQUEST } from './action';
import { PUBLISHED_GRID_TEMPLATE, DATE_DISPLAY_GRID_TEMPLATE, RESTRICTED_GRID_TEMPLATE } from './published';
import { PEOPLE_TYPE_GRID_TEMPLATE } from './people-type';
import { RECORD_ACTIVE_GRID_TEMPLATE, RECORD_ACTIVE_DEACTIVE_DEFAULT_GRID_TEMPLATE, RECORD_ONLY_ACTIVE_GRID_TEMPLATE, RECORD_ACTIVE_NEWSLETTER_GRID_TEMPLATE } from './record-active';
import { RECORD_STATUSES_GRID_TEMPLATE, RECORD_STATUS_GRID_TEMPLATE, RECORD_STATUS_GrievanceTier_GRID_TEMPLATE } from './record-status';
import { IS_IMPERSONATE_GRID_TEMPLATE } from './is-impersonate';
import { PRICEBANDACTION_GRID_TEMPLATE, EDITICON_GRID_TEMPLATE, EDITICON_GRID_TEMPLATE_HOLIDAYPROCESS, EDITICON_GRID_TEMPLATE_FABCALLME, EDITICON_GRID_TEMPLATE_PROJEVENTTAB, EDITICON_GRID_TEMPLATE_PRODUCTION_FORM, EDITICON_GRID_TEMPLATE_BOOKING_CLIENT, EYEACTION_GRID_TEMPLATE, PRICE_WITH_PRODUCT_OUT_OF_STOCK } from './priceBandAction';
import { DELETEACTION_GRID_TEMPLATE, SAVEDELETEACTION_GRID_TEMPLATE } from './deleteAction';
import { BARCODE_IMAGE_VIEW_GRID_TEMPLATE, IMAGE_VIEW_GRID_TEMPLATE } from './image-view';
import { BIND_CHECKBOX_GRID_TEMPLATE, BIND_CHECKBOX_WITHOUT_CHECKED_GRID_TEMPLATE, BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS, BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS_SECOND, BIND_CHECKBOX_GRID_TEMPLATE_FOR_BOOKING_APPROVAL, BIND_CHECKBOX_GRID_TEMPLATE_FOR_SALES_REPORT, BIND_ADD_PROJECT_CHECKBOX_GRID_TEMPLATE, BIND_CHECKBOX_GEMFIELD_RISK_ADD_GRID_TEMPLATE, BIND_CHECKBOX_SHIPPING_GRID_TEMPLATE, BIND_CHECKBOX_EVENT_INVITEES_GRID_TEMPLATE, BIND_CHECKBOX_EMERGENCY_CONTACT_GRID_TEMPLATE, BIND_CHECKBOX_IS_CLOSE_GRID_TEMPLATE, BIND_CHECKBOX_GRID_TEMPLATE_FOR_EMPLOYMENT_TABLE, BIND_MOVE_NOTE_GRID_TEMPLATE, BIND_MOVE_DOCUMENT_GRID_TEMPLATE } from './bind-checkbox';
import { LEAVESTATUS_GRID_TEMPLATE, PRODCUTION_STATUS_GRID_TEMPLATE } from './leave-status';
import {  REDIRECT_MOBILEASSET_LINK_GRID_TEMPLATE,REDIRECT_DESKTOPASSET_LINK_GRID_TEMPLATE,REDIRECT_LEGAL_AGREEMENT_LINK_GRID_TEMPLATE, REDIRECT_LINK_EVENT_NAME_GRID_TEMPLATE, REDIRECT_LINK_GRID_TEMPLATE, REDIRECT_MY_DIRECT_REPORT_FORTHCOMING_LEAVE_GRID_TEMPLATE, REDIRECT_PRODUCT_LINK_GRID_TEMPLATE, REDIRECT_REPAIR_LINK_GRID_TEMPLATE, REDIRECT_USER_LEAVE_GRID_TEMPLATE } from './redirect-link';
import { PEOPLE_CORPORATE_ORDER_STATUS_GRID_TEMPLATE, PEOPLE_CORPORATE_PAYMENT_STATUS_GRID_TEMPLATE, PEOPLE_CORPORATE_SHIPPING_STATUS_GRID_TEMPLATE, PEOPLE_RATING_GRID_TEMPLATE, PEOPLE_REDIRECT_LINK_GRID_TEMPLATE } from './people-rating';
import { CANCELACTION_GRID_TEMPLATE } from './cancel-action';
import { CORPORATE_GROUP_LINK_GRID_TEMPLATE, CORPORATE_LINK_GRID_TEMPLATE } from './corporate-link';
import { INVITED_LINK_GRID_TEMPLATE } from './invitedLink';
import { ACCEPTED_LINK_GRID_TEMPLATE } from './acceptedLink';
import { DECLINED_LINK_GRID_TEMPLATE } from './declinedLink';
import { ATTENDED_LINK_GRID_TEMPLATE } from './attendedLink';
import { INTRODUCED_LINK_GRID_TEMPLATE } from './introducedLink';
import { CHILD_ACTION_TEMPLATE } from './replyButton';
import { DATA_BIND, POS_TAX_DUTY_DELIVERY_PRICE, POS_SOLD_PRICE, FAB_GEMSTONE_CERTIFICATE_NUMBER, RATING_FEEDBACK, TEAM_OBJECTIVE_RATING_FEEDBACK, WATCH_INVOICE_NUMBER, FAB_GEMSTONE_Invoice_NUMBER } from './gridTextbox';
import { SELECT_BIND, EXPAND } from './gridSelect';
import { EInvite_CHECKBOX_GRID_TEMPLATE } from './eInviteCheckbox';
import { INVOLVED_CHECKBOX_GRID_TEMPLATE } from './involveCheckbox';
import { PROGRESS_BAR_TEMPLATE } from './progressBar';
import { PENCILBUTTON_GRID_TEMPLATE } from './pencilButton';
import { GRID_TEXTAREA_ACCIDENT, GRID_TEXTAREA_DOCUMENTDESCRIPTION, GRID_TEXTAREA_INCIDENT,GRID_TEXTAREA_JOBTITLE, GRID_TEXTBOX, GRID_TEXTBOX_WORKING, GRID_TEXTBOX_WORKING_HR } from './textbox-in-grid';
//import { GRID_TEXTBOX } from './textbox-in-grid';
import { ACTIVE_FORMER_BIND, ACTIVE_FORMER_EMPLOYMENT_BIND } from './gridActiveFormer';
import { DASHBOARD_PROJECT_GRID_TEMPLATE, DASHBOARD_PROJECT_LEVEL2_GRID_TEMPLATE, DASHBOARD_PROJECT_LEVEL3_GRID_TEMPLATE, DASHBOARD_PROJECT_LEVEL4_GRID_TEMPLATE, EVENT_NAME_SET_PROJECTEVENTRATINGTAB, PROJECT_LEVEL2_REDIRECT_LINK_GRID_TEMPLATE, PROJECT_LEVEL2_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE, PROJECT_LEVEL3_REDIRECT_LINK_GRID_TEMPLATE, PROJECT_LEVEL3_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE, PROJECT_LEVEL4_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE, PROJECT_NAME_SET_PROJECTEVENTRATINGTAB, PROJECT_REDIRECT_LINK_GRID_TEMPLATE, PROJECT_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE } from './project-redirect-link';
import { NOTEEDIT_GRID_TEMPLATE } from './noteEditButton';
import { NOTEREPLY_GRID_TEMPLATE } from './noteReplyButton';
import { NOTEDELETE_GRID_TEMPLATE } from './noteDeleteButton';
import { NOTEEXPAND_GRID_TEMPLATE } from './noteExpand';
import { PARSE_HTML_TEMPLATE } from './parseHTML';
//import { MULTIPLE_VIEW_GRID_TEMPLATE } from './multiple-image';
import { COLLAPSE_NOTIFICATION_BUTTON_GRID_TEMPLATE, EXPAND_BUTTON_DASHBOARD_TEMPLATE, EXPAND_BUTTON_GRID_TEMPLATE, EXPAND_NOTIFICATION_BUTTON_GRID_TEMPLATE } from '../grid/expand-button';
import { HEADER_CHECKBOX_GRID_TEMPLATE, HEADER_CHECKED_CHECKBOX_GRID_TEMPLATE, HEADER_GEMSTONECHECKBOX_GRID_TEMPLATE, HEADER_INFORMATION_TOOLTIPTABLE_GRID_TEMPLATE, HEADER_INFORMATION_TOOLTIP_GRID_TEMPLATE, HEADER_KnownToCheckBox_GRID_TEMPLATE } from './header-checkbox-template';
import { CORPORATE_LINK_REDIRECT_GRID_TEMPLATE } from './corporate-link-redirect';
import { REDIRECT_LINK_KNOWN_TO_GRID_TEMPLATE } from './redirect-known-to';
import { RELATIONSHIP_BIND } from 'src/app/custom-templates/grid/relationship';
import { REDIRECT_LINK_PEOPLE_CORPORATE_GRID_TEMPLATE, REDIRECT_LINK_USER_GRID_TEMPLATE, REDIRECT_LINK_INTERNAL_DIRECTORY_DOCUMENT_GRID_TEMPLATE, RESTRICTED_PROJECT_PERSON_INVOLED_REDIRECT_TEMPLATE, REDIRECT_LINK_LA_LEADER_GRID_TEMPLATE, REDIRECT_LINK_PEOPLE_CORPORATE_AND_TOOLTIP_GRID_TEMPLATE, PEOPLE_NAME_AND_TOOLTIP_GRID_TEMPLATE, REDIRECT_LINK_PEOPLE_AND_TOOLTIP_GRID_TEMPLATE, CORPORATE_NAME_AND_TOOLTIP_GRID_TEMPLATE, PEOPLE_ANOTHERNAME_AND_TOOLTIP_GRID_TEMPLATE, CORPORATE_PEOPLE_AND_TOOLTIP_GRID_TEMPLATE, CORP_NAME_AND_TOOLTIP_GRID_TEMPLATE, REDIRECT_LINK_CORPORATE_AND_TOOLTIP_GRID_TEMPLATE } from './reDirect-People-Corporate';
import { PROJECT_REDIRECT_SUB_CHILDGRID_LINK_GRID_TEMPLATE } from './sub-project-redirect-link';
import { REDIRECT_LINK_FABERGE_PRICE_GRID_TEMPLATE, REDIRECT_LINK_FABERGE_WITHOUT_PRICE_GRID_TEMPLATE } from './redirect-faberge-price';
import { REGISTERED_CUSTOMER_BIND } from './registered-customer-select';
import { TextAreaIAN_BIND, TextArea_BIND } from './gridTextArea';
import { TextArea2_BIND } from './gridText';
import { BIND_CHECKBOX_GRID_isArrested, BIND_CHECKBOX_GRID_isArtisanal, BIND_CHECKBOX_GRID_isFatality, BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS_THIRD } from './gridCheckbox-bind';
import { PIECE_REDIRECT_LINK_GRID_TEMPLATE, REDIRECT_PIECE_LINK_GRID_TEMPLATE, REDIRECT_GEMSTONE_LINK_GRID_TEMPLATE, PIECE_INFO_GRID_TEMPLATE, REDIRECT_ORDER_LINK_PIECE_GRID_TEMPLATE, POSPIECE_INFO_GRID_TEMPLATE } from './piece-redirect-link';
import { REDIRECT_LINK_INTERNAL_DRIECTORY_REPORTS_TOGRID_TEMPLATE } from './reDirectLinkInternalDriectory';
import { REDIRECT_REGISTERED_CUSTOMER } from './register-customer-redriect';
import { ORDER_TOTAL_FORMAT } from './order_total_format';
import { CURRENT_PURCHASE_LIST_GRID_TEMPLATE } from './currentPurchaseListRedirect';
import { HSE_REPORT_DATA_SEPERATE_TEMPLATE_GRIEVANCE, HSE_RPORT_DATA_SEPERATE_TEMPLATE } from './hse-report-data-seperate';
import { REDIRECT_LINK_NEWS_GRID_TEMPLATE, REDIRECT_LINK_FABERGE_HISTORY_GRID_TEMPLATE } from './redirect-news';
import { INCIDENT_DETAILS, PRICEBAND_OPTION_REPORT_PRODUCT_REDIRECT, TIMEPIECE_SALES_REPORT } from './incidentDetails';
import { NOOREORDER_CHECKBOX_GRID_TEMPLATE } from './noReorder';
import { SUPERSELLER_CHECKBOX_GRID_TEMPLATE } from './superSeller';
import { COREPRODUCT_CHECKBOX_GRID_TEMPLATE } from './coreProduct';
import { REDIRECT_LINK_EDIT_LEAVE_POPUP } from './redriectLeaveEditPopup';
import { IMAGE_VIEW_WITH_SOURCE_GRID_TEMPLATE } from './image-view-with-src';
import { CURRENT_PURCHASE_LIST_EXPAND_BUTTON_GRID_TEMPLATE } from './currentPurchaseListExpand';
import { REPORT_DETAIL_REDIRECT_MODAL, REPORT_NOCLIENTADDED_DETAIL_REDIRECT_MODAL, REPORT_NOCLIENTSSALESADVISORADDED_DETAIL_REDIRECT_MODAL, REPORT_NOCLIENTINTRODUCEDBY_DETAIL_REDIRECT_MODAL, REPORT_NOCLIENTSNOTESADDED_DETAIL_REDIRECT_MODAL, REPORT_NOEVENTSNOTESADDED_DETAIL_REDIRECT_MODAL, REPORT_NOINVENTORYNOTESADDED_DETAIL_REDIRECT_MODAL, REPORT_NOSALEMADE_DETAIL_REDIRECT_MODAL, GINA_REPORT_DETAIL_REDIRECT_MODAL, GINA_REPORT_DETAIL_LTI_REDIRECT_MODAL, GINA_REPORT_DETAIL_NEARMISS_REDIRECT_MODAL, GINA_REPORT_DETAIL_TOTALFORMS_REDIRECT_MODAL } from './report-detail-redirect-modal';
import { GRID_TEXTBOX_WITH_VALUE, GRID_TEXTBOX_WITH_VALUE_REPORT } from './textbox-value-in-grid';
import { BOM_CHECKBOX_GRID_TEMPLATE } from './bom-checkbox';
import { GEMSTONE_SERIAL_NO_GRID_TEMPLATE, DESIGN_REDIRECT_GRID_TEMPLATE, PRODUCT_REDIRECT_GRID_TEMPLATE, PIECE_REDIRECT_GRID_TEMPLATE } from './gemstoneSerialNo';
//import { MULTIPLE_VIEW_GRID_TEMPLATE } from './multiple-image';
import { ACTION_SELECT_TEMPLATE } from './action';
import { POS_PIECE_NAME_GRID_TEMPLATE, POS_PRINT_CERTIFICATE_GRID_TEMPLATE } from './p-o-s-piece-name';
import { REDIRECT_LINK_PIECE_SALES_MARGIN_REPORT_GRID_TEMPLATE, REDIRECT_ORDER_LINK_GRID_TEMPLATE, REDIRECT_RETURN_ORDER_LINK_GRID_TEMPLATE, REDIRECT_SALES_ORDER_LINK_GRID_TEMPLATE } from './redirect-order-link';
import { REDIRECT_LINK_PRODUCT_SALES_ORDER_GRID_TEMPLATE } from './redirect-order-link';
import { REDIRECT_LINK_PIECE_SALES_ORDER_GRID_TEMPLATE } from './redirect-order-link';
import { REDIRECT_TICKET_DETAIL_GRID_TEMPLATE } from './redirect-ticket-detail';
import { DASHBOARD_CUSTOMER_BIND } from './dashboard-customer-statisctic';
import { REDIRECT_LINK_FABERGE_ADVANCED_SEARCH_GRID_TEMPLATE } from './redirect-faberge-advanced-search';
import { EVENT_CHECKBOX_GRID_TEMPLATE, ITHELPDESK_CHECKBOX_GRID_TEMPLATE, PROJECT_CHECKBOX_GRID_TEMPLATE } from './project-checkbox';
import { FAB_GEMSTONE_CERTIFICATE_LINK_GRID_TEMPLATE, FAB_GEMSTONE_Invoice_LINK_GRID_TEMPLATE, LA_EXECUTION_VERSION_LINK_GRID_TEMPLATE, LA_SIGNED_AGREEMENT_LINK_GRID_TEMPLATE } from './hyperLink';
import { REDIRECT_CONTENT_MANAGEMENT_FAB_PAGE_GRID_TEMPLATE } from './content-management-fab-page-redirect';
import { DROPDOWN_EVENT_RATING, DROPDOWN_OBJECTIVE_RATING, DROPDOWN_OBJECTIVE_STATUS, DROPDOWN_PROJECT_RATING } from './dropdown-for-team-objective-grid';
import { GEMFIELD_RISK_INDEX, SPECIFICRISK_TOOLTIP } from './gemfield-risk-index';
import { DROPDOWN_GEMFIELD_RISK_IMPACT, DROPDOWN_GEMFIELD_RISK_LIKELIHOOD, DROPDOWN_GINA_LIT, DROPDOWN_GINA_LIT_EDIT } from './dropdown-for-gemfield-risk-add-grid';
import { ACTION_GRID_TEMPLATE_RISK } from './risk-action-template';
import { REDIRECT_RISK_LINK_GRID_TEMPLATE } from './reDriect-risk';
import { TemplateConfig } from '@rxweb/grid';
import { SAVEBUTTON_GRID_TEMPLATE } from './save-button';
import { BIND_MOVE_NOTE_DOC_TARGET_RADIO_GRID_TEMPLATE, BIND_RADIO_EMPLOYMENT_TABLE_GRID_TEMPLATE, BIND_RADIO_Employment_GRID_TEMPLATE, BIND_RADIO_GRID_TEMPLATE, BIND_SINGLE_RADIO_GRID_TEMPLATE } from './bind-radio';
import { BIND_RADIOBUTTON_GRID_TEMPLATE } from './bind-radiobutton';
import { BIND_CHECKBOX_GRID_NOTIFICATION, BIND_IANGeneral_CHECKBOX_GRID_NOTIFICATION, IAN_MULTIPLE_CHECKBOX_GRID_TEMPLATE } from './ian-multiple-checkbox';
import { PRODUCTION_ORDER_FORM_EXPAND } from './production-order-form-expand';
import { EVENTEXPAND_GRID_TEMPLATE } from './eventExpand';
import { BIND_MULTIPLE_RADIO_GRID_TEMPLATE } from './bind-multiple-radio';
import { CLIENT_REPAIR } from './clientRepair';
import { GOAL_OBJECTIVES_REPORT_EXPAND } from './goal-objectives-report-expand';
import { REPAIR_FROM } from './RepairForm';
import { POS_PRODUCT_CONSIGNMENTSTATUS_GRID_TEMPLATE, POS_PRODUCT_PRODUCTIONSTATUS_GRID_TEMPLATE, POS_PRODUCT_SALESSTATUS_GRID_TEMPLATE } from './pos-grid-templates';
import { NOTETASK_GRID_TEMPLATE } from './noteTaskButton';
import { BIND_TASK_STATUS_CHECKBOX_GRID, BIND_TASK_STATUS_GRID, MODULE_NAME, NOTE_DETAIL_MODAL, TASK_DETAIL_MODAL } from './task-detail';
import { Batch_ACTIVE_GRID_TEMPLATE } from './batch-active';
import { SAVEANDNOTEREPLY_GRID_TEMPLATE } from './saveandnoteReplyButton';
import { BIND_GemstoneCheckBox_GRID_TEMPLATE } from './gemstone-checkbox';

export const GRID_CUSTOM_TEMPLATES: { [key: string]: TemplateConfig } = {
    floppyAction : ACTION_GRID_TEMPLATE_FLOPPY,
    action: ACTION_GRID_TEMPLATE,
    actionIAN: ACTION_GRID_TEMPLATE_IAN,
    productionFormAction:EDITICON_GRID_TEMPLATE_PRODUCTION_FORM,
    action_bom: ACTION_GRID_TEMPLATE_CORPORATE,
    action_LegalAgreement : ACTION_LEGAL_AGREEMENT_GRID_TEMPLATE,
    action_production_form: ACTION_GRID_TEMPLATE_PRODUCTION,
    edit: ACTION_EDIT_TEMPLATE,
    editBookingClient : EDITICON_GRID_TEMPLATE_BOOKING_CLIENT,
    deleteGINA: ACTION_DELETE_GINA_TEMPLATE,
    delete: ACTION_DELETE_TEMPLATE,
    deleteLegalAgreement :ACTION_LEGAL_AGREEMENT_DELETE_TEMPLATE,
    selectButton: ACTION_SELECT_TEMPLATE,
    view: ACTION_VIEW_TEMPLATE,
    dashboardView: DASHBOARD_ACTION_VIEW_TEMPLATE,
    dashboardLeaveView: ACTION_VIEW_TEMPLATE_LEAVE,
    dashboardAccessRequestView: ACTION_VIEW_TEMPLATE_ACCESS_REQUEST,
    published: PUBLISHED_GRID_TEMPLATE,
    restricted: RESTRICTED_GRID_TEMPLATE,
    peopleType: PEOPLE_TYPE_GRID_TEMPLATE,
    recordActive: RECORD_ACTIVE_GRID_TEMPLATE,
    recordActiveNewsletter : RECORD_ACTIVE_NEWSLETTER_GRID_TEMPLATE,
    batchActive:Batch_ACTIVE_GRID_TEMPLATE,
    recordOnlyActive:RECORD_ONLY_ACTIVE_GRID_TEMPLATE,
    recordActiveDeactiveDefault: RECORD_ACTIVE_DEACTIVE_DEFAULT_GRID_TEMPLATE,
    recordStatus: RECORD_STATUS_GRID_TEMPLATE,
    recordStatuses: RECORD_STATUSES_GRID_TEMPLATE,
    recordActiveStatus: RECORD_STATUS_GrievanceTier_GRID_TEMPLATE,
    isImpersonate: IS_IMPERSONATE_GRID_TEMPLATE,
    priceBandAction: PRICEBANDACTION_GRID_TEMPLATE,
    eyeAction:EYEACTION_GRID_TEMPLATE,
    deleteAction: DELETEACTION_GRID_TEMPLATE,
    imageView: IMAGE_VIEW_GRID_TEMPLATE,
    barcodeImageView: BARCODE_IMAGE_VIEW_GRID_TEMPLATE,
    imageViewWithSrc: IMAGE_VIEW_WITH_SOURCE_GRID_TEMPLATE,
    bindCheckBox: BIND_CHECKBOX_GRID_TEMPLATE,
    bindGemstoneCheckBox: BIND_GemstoneCheckBox_GRID_TEMPLATE,
    bindEmergencyContactCheckBox: BIND_CHECKBOX_EMERGENCY_CONTACT_GRID_TEMPLATE,
    bindMoveNoteCheckBox: BIND_MOVE_NOTE_GRID_TEMPLATE,
    bindMoveDocumentCheckBox: BIND_MOVE_DOCUMENT_GRID_TEMPLATE,
    bindIsCloseCheckBox: BIND_CHECKBOX_IS_CLOSE_GRID_TEMPLATE,
    bindCheckBoxLable : BIND_CHECKBOX_EVENT_INVITEES_GRID_TEMPLATE,
    bindCheckBoxShipping: BIND_CHECKBOX_SHIPPING_GRID_TEMPLATE,
    bindAddProjectCheckBox: BIND_ADD_PROJECT_CHECKBOX_GRID_TEMPLATE,
    bindCheckBoxWithoutChecked: BIND_CHECKBOX_WITHOUT_CHECKED_GRID_TEMPLATE,
    bindCheckBoxWithoutFormCheckClass: BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS,
    bindCheckBoxWithoutFormCheckClassSecond: BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS_SECOND,
    bindCheckBoxWithoutFormCheckClassThird: BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS_THIRD,
    bindCheckBoxWithoutForSalesReport: BIND_CHECKBOX_GRID_TEMPLATE_FOR_SALES_REPORT,
    bindCheckBoxForEmploymentTable: BIND_CHECKBOX_GRID_TEMPLATE_FOR_EMPLOYMENT_TABLE,
    isFatalityCB: BIND_CHECKBOX_GRID_isFatality,
    isArrestedCB: BIND_CHECKBOX_GRID_isArrested,
    isArtisanalCB: BIND_CHECKBOX_GRID_isArtisanal,
    leaveStatus: LEAVESTATUS_GRID_TEMPLATE,
    productionStatus: PRODCUTION_STATUS_GRID_TEMPLATE,
    reDirect: REDIRECT_LINK_GRID_TEMPLATE,
    redirectproduct:REDIRECT_PRODUCT_LINK_GRID_TEMPLATE,
    reDirectUserLeaveTab: REDIRECT_USER_LEAVE_GRID_TEMPLATE,
    legalAgreementRedirect : REDIRECT_LEGAL_AGREEMENT_LINK_GRID_TEMPLATE,
    desktopAssetRedirect : REDIRECT_DESKTOPASSET_LINK_GRID_TEMPLATE,
    mobileAssetRedirect : REDIRECT_MOBILEASSET_LINK_GRID_TEMPLATE,
    reDirectEventInvitees : REDIRECT_LINK_EVENT_NAME_GRID_TEMPLATE,
    repairRedirect: REDIRECT_REPAIR_LINK_GRID_TEMPLATE,
    reDirectKnownTo: REDIRECT_LINK_KNOWN_TO_GRID_TEMPLATE,
    corporateLink: CORPORATE_LINK_GRID_TEMPLATE,
    corporateLinkRedirect: CORPORATE_LINK_REDIRECT_GRID_TEMPLATE,
    peopleRating: PEOPLE_RATING_GRID_TEMPLATE,
    peopleCorporateOrderStatus: PEOPLE_CORPORATE_ORDER_STATUS_GRID_TEMPLATE,
    peopleCorporatePaymentStatus: PEOPLE_CORPORATE_PAYMENT_STATUS_GRID_TEMPLATE,
    peopleCorporateShippingStatus: PEOPLE_CORPORATE_SHIPPING_STATUS_GRID_TEMPLATE,
    dateDispay: DATE_DISPLAY_GRID_TEMPLATE,
    peopleRedirect: PEOPLE_REDIRECT_LINK_GRID_TEMPLATE,
    invitedLink: INVITED_LINK_GRID_TEMPLATE,
    acceptedLink: ACCEPTED_LINK_GRID_TEMPLATE,
    declinedLink: DECLINED_LINK_GRID_TEMPLATE,
    attendedLink: ATTENDED_LINK_GRID_TEMPLATE,
    introducedLink: INTRODUCED_LINK_GRID_TEMPLATE,
    cancelAction: CANCELACTION_GRID_TEMPLATE,
    reDirectInternalDirectory: REDIRECT_LINK_INTERNAL_DRIECTORY_REPORTS_TOGRID_TEMPLATE,
    reDirectProductDtl: REDIRECT_LINK_PRODUCT_SALES_ORDER_GRID_TEMPLATE,
    reDirectPieceDtl: REDIRECT_LINK_PIECE_SALES_ORDER_GRID_TEMPLATE,
    reDirectSalesMarginPiece: REDIRECT_LINK_PIECE_SALES_MARGIN_REPORT_GRID_TEMPLATE,
    reDriectEditLeavePopup: REDIRECT_LINK_EDIT_LEAVE_POPUP,
    //replyButton: CHILD_ACTION_TEMPLATE,
    projectUpdateRedirect: DASHBOARD_PROJECT_GRID_TEMPLATE,
    relationshipSelect: RELATIONSHIP_BIND,
    ianAddAction: IAN_GRID_TEMPLATE,
    number: DATA_BIND,
    gridTextbox: GRID_TEXTBOX,
    gridTextAreaJobTitle:GRID_TEXTAREA_JOBTITLE,
    gridTextAreaDocumentDescription:GRID_TEXTAREA_DOCUMENTDESCRIPTION,
    gridTextAreaAccident: GRID_TEXTAREA_ACCIDENT,
    gridTextareaIncident: GRID_TEXTAREA_INCIDENT,
    gridTextboxWithValue: GRID_TEXTBOX_WITH_VALUE,
    gridTextBoxWorkingHr: GRID_TEXTBOX_WORKING,
    gridWorkingHrEdit: GRID_TEXTBOX_WORKING_HR,
    gridTextboxWithValueReport: GRID_TEXTBOX_WITH_VALUE_REPORT,
    gridTextarea: TextArea_BIND,
    TextareaIAN: TextAreaIAN_BIND,
    select: SELECT_BIND,
    activeFormerBind: ACTIVE_FORMER_BIND,
    activeFormerEmploymentBind: ACTIVE_FORMER_EMPLOYMENT_BIND,
    eInviteCheck: EInvite_CHECKBOX_GRID_TEMPLATE,
    involvedCheck: INVOLVED_CHECKBOX_GRID_TEMPLATE,
    pencilButton: PENCILBUTTON_GRID_TEMPLATE,
    progressBar: PROGRESS_BAR_TEMPLATE,
    expand: EXPAND,
    projectLink: PROJECT_REDIRECT_LINK_GRID_TEMPLATE,
    projectLevele2Link: PROJECT_LEVEL2_REDIRECT_LINK_GRID_TEMPLATE,
    projectLevele3Link: PROJECT_LEVEL3_REDIRECT_LINK_GRID_TEMPLATE,
    dashboardProjectLink: DASHBOARD_PROJECT_GRID_TEMPLATE,
    dashboardProjectLevel2Link: DASHBOARD_PROJECT_LEVEL2_GRID_TEMPLATE,
    dashboardProjectLevel3Link: DASHBOARD_PROJECT_LEVEL3_GRID_TEMPLATE,
    dashboardProjectLevel4Link: DASHBOARD_PROJECT_LEVEL4_GRID_TEMPLATE,
    projectLinkTeamMember: PROJECT_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE,
    projectLevel2LinkTeamMember: PROJECT_LEVEL2_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE,
    projectLevel3LinkTeamMember: PROJECT_LEVEL3_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE,
    projectLevel4LinkTeamMember: PROJECT_LEVEL4_REDIRECT_TEAMMEMBER_LINK_GRID_TEMPLATE,
    subprojectLink: PROJECT_REDIRECT_SUB_CHILDGRID_LINK_GRID_TEMPLATE,
    noteEditButton: NOTEEDIT_GRID_TEMPLATE,
    noteTaskButton: NOTETASK_GRID_TEMPLATE,
    noteReplyButton: NOTEREPLY_GRID_TEMPLATE,
    saveandnoteReplyButton: SAVEANDNOTEREPLY_GRID_TEMPLATE,
    noteDeleteButton: NOTEDELETE_GRID_TEMPLATE,
    noteExpand: NOTEEXPAND_GRID_TEMPLATE,
    eventExpand: EVENTEXPAND_GRID_TEMPLATE,
    parserHTML: PARSE_HTML_TEMPLATE,
    plusBtn: EXPAND_BUTTON_GRID_TEMPLATE,
    dashboardExpand: EXPAND_BUTTON_DASHBOARD_TEMPLATE,
    notificationExpand: EXPAND_NOTIFICATION_BUTTON_GRID_TEMPLATE,
    notificationCollapse : COLLAPSE_NOTIFICATION_BUTTON_GRID_TEMPLATE,
    //plusBtnDashboard:EXPAND_BUTTON_GRID_TEMPLATE_DASHBOARD,
    bindHeaderCheckBox: HEADER_CHECKBOX_GRID_TEMPLATE,
    bindHeaderInformation:HEADER_INFORMATION_TOOLTIP_GRID_TEMPLATE,
    bindHeaderTableInformation:HEADER_INFORMATION_TOOLTIPTABLE_GRID_TEMPLATE,
    bindGemstoneHeaderCheckBox: HEADER_GEMSTONECHECKBOX_GRID_TEMPLATE,
    bindKnownToHeaderCheckBox: HEADER_KnownToCheckBox_GRID_TEMPLATE,
    bindHeaderCheckedCheckBox: HEADER_CHECKED_CHECKBOX_GRID_TEMPLATE,
    reDirectPeopleCorporate: REDIRECT_LINK_PEOPLE_CORPORATE_GRID_TEMPLATE,
    reDirectPeopleCorporateAndTooltip: REDIRECT_LINK_PEOPLE_CORPORATE_AND_TOOLTIP_GRID_TEMPLATE,
    reDirectPeopleAndTooltip: REDIRECT_LINK_PEOPLE_AND_TOOLTIP_GRID_TEMPLATE,
    reDirectCorporateAndTooltip: REDIRECT_LINK_CORPORATE_AND_TOOLTIP_GRID_TEMPLATE,
    peopleNameAndTooltip: PEOPLE_NAME_AND_TOOLTIP_GRID_TEMPLATE,
    peopleCorporateNameAndTooltip: CORPORATE_PEOPLE_AND_TOOLTIP_GRID_TEMPLATE,
    peopleAnotherNameAndTooltip: PEOPLE_ANOTHERNAME_AND_TOOLTIP_GRID_TEMPLATE,
    CorporateNameAndTooltip: CORPORATE_NAME_AND_TOOLTIP_GRID_TEMPLATE,
    CorpNameAndTooltip: CORP_NAME_AND_TOOLTIP_GRID_TEMPLATE,
    userRedirectLink: REDIRECT_LINK_USER_GRID_TEMPLATE,
    redirectFabergePriceList: REDIRECT_LINK_FABERGE_PRICE_GRID_TEMPLATE,
    redirectFabergePriceListWithoutPrice: REDIRECT_LINK_FABERGE_WITHOUT_PRICE_GRID_TEMPLATE,
    registeredCustomerBind: REGISTERED_CUSTOMER_BIND,
    dashboardCustomerStat: DASHBOARD_CUSTOMER_BIND,
    registerCustomerLink: REDIRECT_REGISTERED_CUSTOMER,
    corporateEditDelete: ACTION_GRID_TEMPLATE_CORPORATE,
    //  multipleImage: MULTIPLE_VIEW_GRID_TEMPLATE
    pieceRedirectLink: PIECE_REDIRECT_LINK_GRID_TEMPLATE,
    orderRedirectLink: REDIRECT_ORDER_LINK_PIECE_GRID_TEMPLATE,
    redirectPieceLinkTemplate: REDIRECT_PIECE_LINK_GRID_TEMPLATE,
    redirectDocumentLink: REDIRECT_LINK_INTERNAL_DIRECTORY_DOCUMENT_GRID_TEMPLATE,
    bindCheckoxForBookingApproval: BIND_CHECKBOX_GRID_TEMPLATE_FOR_BOOKING_APPROVAL,
    ordertotalformat: ORDER_TOTAL_FORMAT,
    currentPurchaseListRedirect: CURRENT_PURCHASE_LIST_GRID_TEMPLATE,
    redirectNewsLink: REDIRECT_LINK_NEWS_GRID_TEMPLATE,
    redirectFabergeHistoryLink: REDIRECT_LINK_FABERGE_HISTORY_GRID_TEMPLATE,
    hseReportDataSeperate: HSE_RPORT_DATA_SEPERATE_TEMPLATE,
    hseGinaReportDataSeperate: HSE_REPORT_DATA_SEPERATE_TEMPLATE_GRIEVANCE,
    incidentDetails: INCIDENT_DETAILS,
    clientRepair: CLIENT_REPAIR,
    repairForm:REPAIR_FROM,
    noReorder: NOOREORDER_CHECKBOX_GRID_TEMPLATE,
    superSeller: SUPERSELLER_CHECKBOX_GRID_TEMPLATE,
    coreProduct: COREPRODUCT_CHECKBOX_GRID_TEMPLATE,
    customerPurchaseListExpandBtn: CURRENT_PURCHASE_LIST_EXPAND_BUTTON_GRID_TEMPLATE,
    reportDetailRedirectModal: REPORT_DETAIL_REDIRECT_MODAL,
    reportNoSaleMadeDetailRedirectModal: REPORT_NOSALEMADE_DETAIL_REDIRECT_MODAL,
    reportNoClientAddedRedirectModal: REPORT_NOCLIENTADDED_DETAIL_REDIRECT_MODAL,
    reportNoClientsSalesAdvisorRedirectModal: REPORT_NOCLIENTSSALESADVISORADDED_DETAIL_REDIRECT_MODAL,
    reportNoClientIntroduceByRedirectModal: REPORT_NOCLIENTINTRODUCEDBY_DETAIL_REDIRECT_MODAL,
    reportNoClientsNotesAddedRedirectModal: REPORT_NOCLIENTSNOTESADDED_DETAIL_REDIRECT_MODAL,
    reportNoEventsNotesAddedRedirectModal: REPORT_NOEVENTSNOTESADDED_DETAIL_REDIRECT_MODAL,
    reportNoInventoryNotesAddedRedirectModal: REPORT_NOINVENTORYNOTESADDED_DETAIL_REDIRECT_MODAL,
    bomCheckbox: BOM_CHECKBOX_GRID_TEMPLATE,
    gemstoneSerialNo: GEMSTONE_SERIAL_NO_GRID_TEMPLATE,
    designRedirect: DESIGN_REDIRECT_GRID_TEMPLATE,
    productRedirect: PRODUCT_REDIRECT_GRID_TEMPLATE,
    pieceRedirect: PIECE_REDIRECT_GRID_TEMPLATE,
    pieceInfo: PIECE_INFO_GRID_TEMPLATE,
    POSPieceBookingInfo: POSPIECE_INFO_GRID_TEMPLATE,
    saveButton: SAVEBUTTON_GRID_TEMPLATE,
    buttonTemplate: IAN_GRID_BUTTON_TEMPLATE,
    radiobuttonTemplate: BIND_RADIOBUTTON_GRID_TEMPLATE,
    saveDeleteTemplate: SAVEDELETEACTION_GRID_TEMPLATE,
    ianmultiplecheckboxTemplate: IAN_MULTIPLE_CHECKBOX_GRID_TEMPLATE,
    iangridHeaderTemplate: IAN_GRIDHEADER_BUTTON_TEMPLATE,
    priceWithProductOutOfStock: PRICE_WITH_PRODUCT_OUT_OF_STOCK,
    // group headers
    groupHeader1: {
        div: {
            style: {
                "background-color": "#f7f7f7",
                "min-width": "35%",
                "max-width": "35%"
            },
            class: [function (t) { return "div-th" }],
            childrens: [{
                text: { text: function (e) { return this.gridData.name } },

            },

            ]

        }
    },
    groupHeader2: {
        div: {
            style: {

                "padding-left": "3.5%",
                "border-left": "0.5px solid #b5b5b5",
                "border-right": "0.5px solid #b5b5b5",
                "min-width": "10%",
                "max-width": "10%"
            },
            class: [function (t) { return "div-th" }],
            childrens: [{
                text: { text: function (e) { return this.gridData.name } },

            },

            ]

        }
    },
    groupHeader3: {
        div: {
            style: {
                "padding-left": "3.5%",
                "min-width": "10%",
                "max-width": "10%",
                "background-color": "#f7f7f7",
                "border-right": "0.5px solid #b5b5b5"
            },
            class: [function (t) { return "div-th" }],
            childrens: [{
                text: { text: function (e) { return this.gridData.name } },

            },

            ]

        }
    },
    groupHeaderDesignProductSuccessEmpty: {
        div: {
            style: {
                "padding-left": "3%",
                "min-width": "35%",
                "max-width": "35%",
                "background-color": "#f7f7f7",
                "border-right": "0.5px solid #b5b5b5"
            },
            class: [function (t) { return "div-th" }],
            childrens: [{
                text: { text: function (e) { return this.gridData.name } },

            },

            ]

        }
    },
    groupHeaderDesignProductSuccessSalesHistory: {
        div: {
            style: {
                "padding-left": "3.5%",
                "min-width": "10%",
                "max-width": "10%",
                "background-color": "#f7f7f7",
                "border-right": "0.5px solid #b5b5b5"
            },
            class: [function (t) { return "div-th" }],
            childrens: [{
                text: { text: function (e) { return this.gridData.name } },

            },

            ]

        }
    },
    groupHeaderDesignProductSuccessSalesSplit: {
        div: {
            style: {
                "padding-left": "3.5%",
                "min-width": "10%",
                "max-width": "10%",
                "background-color": "#f7f7f7",
                "border-right": "0.5px solid #b5b5b5"
            },
            class: [function (t) { return "div-th" }],
            childrens: [{
                text: { text: function (e) { return this.gridData.name } },

            },

            ]

        }
    },
    parentTemplate: {
        div: {
            class: ["div-tr"],
            style: {
                "background-color": "#f7f7f7",
                "flex-wrap": "nowrap",
                "border": "0.5px solid #b5b5b5"


            }
        }
    },
    POSPieceName: POS_PIECE_NAME_GRID_TEMPLATE,
    POSPieceSalesStatus: POS_PRODUCT_SALESSTATUS_GRID_TEMPLATE,
    POSPieceProductionStatus: POS_PRODUCT_PRODUCTIONSTATUS_GRID_TEMPLATE,
    POSPieceConsignmentStatus: POS_PRODUCT_CONSIGNMENTSTATUS_GRID_TEMPLATE,
    POSShoppingBag: ACTION_SHOPPING_BAG_TEMPLATE,
    POSTaxDutyDeliveryPrice: POS_TAX_DUTY_DELIVERY_PRICE,
    POSSoldPrice: POS_SOLD_PRICE,
    POSPrintCertificate: POS_PRINT_CERTIFICATE_GRID_TEMPLATE,
    redirectOrderLink: REDIRECT_ORDER_LINK_GRID_TEMPLATE,
    redirectSalesOrderLink: REDIRECT_SALES_ORDER_LINK_GRID_TEMPLATE,
    redirectReturnOrderLink: REDIRECT_RETURN_ORDER_LINK_GRID_TEMPLATE,
    redirectTicketDetail: REDIRECT_TICKET_DETAIL_GRID_TEMPLATE,
    gemstoneRedirect: REDIRECT_GEMSTONE_LINK_GRID_TEMPLATE,
    fabergeAdvancedSearchRedirect: REDIRECT_LINK_FABERGE_ADVANCED_SEARCH_GRID_TEMPLATE,
    editIconForGemstoneOrder: EDITICON_GRID_TEMPLATE,
    supplierPOSAction: ACTION_SUPPLIER_POS_TEMPLATE,
    historyMirFabergeAction: ACTION_HISTORY_MIRFABERGE_TEMPLATE,
    editIconForHolidayProcess: EDITICON_GRID_TEMPLATE_HOLIDAYPROCESS,
    editIconForFabCallMe: EDITICON_GRID_TEMPLATE_FABCALLME,
    editModel: ACTION_FOR_MODEL_EDIT_TEMPLATE,
    listModel: ACTION_FOR_MODEL_LIST_TEMPLATE,
    viewModel: ACTION_FOR_MODEL_VIEW_TEMPLATE,
    viewModelReportingHeader: ACTION_FOR_MODEL_VIEW_TEMPLATE_REPORTING_HEAD,
    viewModelMindMap: ACTION_FOR_MODEL_VIEW_TEMPLATE_MIND_MAP,
    timePieceSalesReport: TIMEPIECE_SALES_REPORT,
    corporateGroupLink: CORPORATE_GROUP_LINK_GRID_TEMPLATE,
    restrictedProjPerInvoledRedirect: RESTRICTED_PROJECT_PERSON_INVOLED_REDIRECT_TEMPLATE,
    editForProjEventTab: EDITICON_GRID_TEMPLATE_PROJEVENTTAB,
    projectCheckbox: PROJECT_CHECKBOX_GRID_TEMPLATE,
    eventCheckbox : EVENT_CHECKBOX_GRID_TEMPLATE,
    fabGemstoneCertificateLink: FAB_GEMSTONE_CERTIFICATE_LINK_GRID_TEMPLATE,
    fabGemstoneInvoiceLink: FAB_GEMSTONE_Invoice_LINK_GRID_TEMPLATE,
    fabGemCertiNum: FAB_GEMSTONE_CERTIFICATE_NUMBER,
    fabGemInvoiceNum: FAB_GEMSTONE_Invoice_NUMBER,
    onWatchInvoiceNumber:WATCH_INVOICE_NUMBER,
    fabPageRedirectLink: REDIRECT_CONTENT_MANAGEMENT_FAB_PAGE_GRID_TEMPLATE,
    setProjectName: PROJECT_NAME_SET_PROJECTEVENTRATINGTAB,
    setEventName: EVENT_NAME_SET_PROJECTEVENTRATINGTAB,
    feedback: RATING_FEEDBACK,
    teamObjectiveRatingFeedback: TEAM_OBJECTIVE_RATING_FEEDBACK,
    dropdownObjectiveStatus: DROPDOWN_OBJECTIVE_STATUS,
    dropdownObjectiveRating: DROPDOWN_OBJECTIVE_RATING,
    dropdownProjectRating: DROPDOWN_PROJECT_RATING,
    dropdownEventRating: DROPDOWN_EVENT_RATING,
    gemfieldRiskIndex: GEMFIELD_RISK_INDEX,
    gemfieldRiskLikelihood: DROPDOWN_GEMFIELD_RISK_LIKELIHOOD,
    gemfieldRiskImpact: DROPDOWN_GEMFIELD_RISK_IMPACT,
    isReleventSelect: BIND_CHECKBOX_GEMFIELD_RISK_ADD_GRID_TEMPLATE,
    riskProjectMappingAction: ACTION_GRID_TEMPLATE_RISK,
    reDirectRiskMenuDetail: REDIRECT_RISK_LINK_GRID_TEMPLATE,
    logsAction: ACTION_GRID_TEMPLATE_LOGS,
    specificRiskAction: ACTION_GRID_SPECIFICRISK,
    fabGemstoneAction: ACTION_GRID_FABGEMSTONE,
    pricebandOptionRpoertProductRedirect:PRICEBAND_OPTION_REPORT_PRODUCT_REDIRECT,
    bindRadio: BIND_RADIO_GRID_TEMPLATE,
    bindSingleRadio: BIND_SINGLE_RADIO_GRID_TEMPLATE,
    bindMoveNoteDocRadio: BIND_MOVE_NOTE_DOC_TARGET_RADIO_GRID_TEMPLATE,
    bindRadioEmployement:BIND_RADIO_Employment_GRID_TEMPLATE,
    bindRadioEmployementTable:BIND_RADIO_EMPLOYMENT_TABLE_GRID_TEMPLATE,
    bindMultipleRadio: BIND_MULTIPLE_RADIO_GRID_TEMPLATE,
    leaveUseAction: REDIRECT_MY_DIRECT_REPORT_FORTHCOMING_LEAVE_GRID_TEMPLATE,
    specificRiskViewName: LEGAL_ARGREEMENT_SPECIFICRISK_VIEW_LIST_GRID,
    leaderRedirect: REDIRECT_LINK_LA_LEADER_GRID_TEMPLATE,
    signedAgreementPDF: LA_SIGNED_AGREEMENT_LINK_GRID_TEMPLATE,
    executionVersionDoc: LA_EXECUTION_VERSION_LINK_GRID_TEMPLATE,
    specificRiskTooltip: SPECIFICRISK_TOOLTIP,
    GINA_LIT:DROPDOWN_GINA_LIT,
    GINA_LIT_EDIT:DROPDOWN_GINA_LIT_EDIT,
    ginaReportDetailRedirectModal: GINA_REPORT_DETAIL_REDIRECT_MODAL,
    ginaReportDetailLTIRedirectModal: GINA_REPORT_DETAIL_LTI_REDIRECT_MODAL,
    ginaReportDetailNearMissRedirectModal: GINA_REPORT_DETAIL_NEARMISS_REDIRECT_MODAL,
    ginaReportDetailTotalFormsRedirectModal: GINA_REPORT_DETAIL_TOTALFORMS_REDIRECT_MODAL,
    ianNotification:BIND_CHECKBOX_GRID_NOTIFICATION,
    ianGeneralNotification:BIND_IANGeneral_CHECKBOX_GRID_NOTIFICATION,
    productionOrderFormExpand: PRODUCTION_ORDER_FORM_EXPAND,
    goalObjectivesReportExpand: GOAL_OBJECTIVES_REPORT_EXPAND,
    iTHelpDeskCheckbox: ITHELPDESK_CHECKBOX_GRID_TEMPLATE,
    itHelpDeskDeleteAction : ITHELPDESK_DELETEACTION_GRID_TEMPLATE,
    deleteLegalAgreementMapping: ACTION_LEGAL_AGREEMENT_MAPPING_DELETE_TEMPLATE,
    groupCompanyAction: ACTION_GRID_TEMPLATE_GROUP_COMPANY,
    taskStatusCheckbox:BIND_TASK_STATUS_CHECKBOX_GRID,
    taskStatus:BIND_TASK_STATUS_GRID,
    noteDetailModal:NOTE_DETAIL_MODAL,
    moduleName:MODULE_NAME,
    taskModal:TASK_DETAIL_MODAL
}
