import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { DecimalProvider } from '@rxweb/reactive-form-validators/domain/element-processor/decimal.provider';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


export class OrderBase {

//#region orderID Prop
        @prop({defaultValue: 0})
        orderID : number;
//#endregion orderID Prop


//#region orderGUID Prop
        @required()
        orderGUID : any;
//#endregion orderGUID Prop


//#region customerID Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        customerID : number;
//#endregion customerID Prop


//#region supplierId Prop
//@range({minimumNumber:0,maximumNumber:2147483647})
@required()
supplierId : number;
//#endregion supplierId Prop


//#region customerLanguageID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerLanguageID : number;
//#endregion customerLanguageID Prop


//#region customerTaxDisplayTypeID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerTaxDisplayTypeID : number;
//#endregion customerTaxDisplayTypeID Prop


//#region customerIP Prop
        @required()
        @maxLength({value:50})
        customerIP : string;
//#endregion customerIP Prop


//#region orderSubtotalInclTax Prop
        @required()
        orderSubtotalInclTax : number;
//#endregion orderSubtotalInclTax Prop


//#region orderSubtotalExclTax Prop
        @required()
        orderSubtotalExclTax : number;
//#endregion orderSubtotalExclTax Prop


//#region orderShippingInclTax Prop
        @required()
        orderShippingInclTax : number;
//#endregion orderShippingInclTax Prop


//#region orderShippingExclTax Prop
        @required()
        orderShippingExclTax : number;
//#endregion orderShippingExclTax Prop


//#region paymentMethodAdditionalFeeInclTax Prop
        @required()
        paymentMethodAdditionalFeeInclTax : number;
//#endregion paymentMethodAdditionalFeeInclTax Prop


//#region paymentMethodAdditionalFeeExclTax Prop
        @required()
        paymentMethodAdditionalFeeExclTax : number;
//#endregion paymentMethodAdditionalFeeExclTax Prop


//#region taxRates Prop
        @required()
        @maxLength({value:4000})
        taxRates : string;
//#endregion taxRates Prop


//#region orderTax Prop
        @required()
        orderTax : number;
//#endregion orderTax Prop

//#region orderDuty Prop
        @required()
        orderDuty : number;
//#endregion orderDuty Prop


//#region orderTotal Prop
        @required()
        orderTotal : number;
//#endregion orderTotal Prop


//#region refundedAmount Prop
        @required()
        refundedAmount : number;
//#endregion refundedAmount Prop


//#region orderDiscount Prop
        @required()
        orderDiscount : number;
//#endregion orderDiscount Prop


//#region orderSubtotalInclTaxInCustomerCurrency Prop
        @required()
        orderSubtotalInclTaxInCustomerCurrency : number;
//#endregion orderSubtotalInclTaxInCustomerCurrency Prop


//#region orderSubtotalExclTaxInCustomerCurrency Prop
        @required()
        orderSubtotalExclTaxInCustomerCurrency : number;
//#endregion orderSubtotalExclTaxInCustomerCurrency Prop


//#region orderShippingInclTaxInCustomerCurrency Prop
        @required()
        orderShippingInclTaxInCustomerCurrency : number;
//#endregion orderShippingInclTaxInCustomerCurrency Prop


//#region orderShippingExclTaxInCustomerCurrency Prop
        @required()
        orderShippingExclTaxInCustomerCurrency : number;
//#endregion orderShippingExclTaxInCustomerCurrency Prop


//#region paymentMethodAdditionalFeeInclTaxInCustomerCurrency Prop
        @required()
        paymentMethodAdditionalFeeInclTaxInCustomerCurrency : number;
//#endregion paymentMethodAdditionalFeeInclTaxInCustomerCurrency Prop


//#region paymentMethodAdditionalFeeExclTaxInCustomerCurrency Prop
        @required()
        paymentMethodAdditionalFeeExclTaxInCustomerCurrency : number;
//#endregion paymentMethodAdditionalFeeExclTaxInCustomerCurrency Prop


//#region taxRatesInCustomerCurrency Prop
        @required()
        @maxLength({value:4000})
        taxRatesInCustomerCurrency : string;
//#endregion taxRatesInCustomerCurrency Prop


//#region orderTaxInCustomerCurrency Prop
        @required()
        orderTaxInCustomerCurrency : number;
//#endregion orderTaxInCustomerCurrency Prop

//#region orderDutyInCustomerCurrency Prop
        @required()
        orderDutyInCustomerCurrency : number;
//#endregion orderDutyInCustomerCurrency Prop


//#region orderTotalInCustomerCurrency Prop
        @required()
        orderTotalInCustomerCurrency : number;
//#endregion orderTotalInCustomerCurrency Prop


//#region orderDiscountInCustomerCurrency Prop
        @required()
        orderDiscountInCustomerCurrency : number;
//#endregion orderDiscountInCustomerCurrency Prop


//#region customerCurrencyCode Prop
        @required()
        @maxLength({value:5})
        customerCurrencyCode : string;
//#endregion customerCurrencyCode Prop


//#region checkoutAttributeDescription Prop
        @required()
        @maxLength({value:4000})
        checkoutAttributeDescription : string;
//#endregion checkoutAttributeDescription Prop


//#region checkoutAttributesXML Prop
        @required()
        checkoutAttributesXML : string;
//#endregion checkoutAttributesXML Prop


//#region orderWeight Prop
        @required()
        orderWeight : number;
//#endregion orderWeight Prop


//#region affiliateID Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        affiliateID : number;
//#endregion affiliateID Prop


//#region orderStatusID Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        orderStatusID : number;
//#endregion orderStatusID Prop


//#region allowStoringCreditCardNumber Prop
        @required()
        allowStoringCreditCardNumber : boolean;
//#endregion allowStoringCreditCardNumber Prop


//#region cardType Prop
        @required()
        @maxLength({value:100})
        cardType : string;
//#endregion cardType Prop


//#region cardName Prop
        @required()
        @maxLength({value:1000})
        cardName : string;
//#endregion cardName Prop


//#region cardNumber Prop
        @required()
        @maxLength({value:100})
        cardNumber : string;
//#endregion cardNumber Prop


//#region maskedCreditCardNumber Prop
        @required()
        @maxLength({value:100})
        maskedCreditCardNumber : string;
//#endregion maskedCreditCardNumber Prop


//#region cardCVV2 Prop
        @required()
        @maxLength({value:100})
        cardCVV2 : string;
//#endregion cardCVV2 Prop


//#region cardExpirationMonth Prop
        @required()
        @maxLength({value:100})
        cardExpirationMonth : string;
//#endregion cardExpirationMonth Prop


//#region cardExpirationYear Prop
        @required()
        @maxLength({value:100})
        cardExpirationYear : string;
//#endregion cardExpirationYear Prop


//#region paymentMethodID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        paymentMethodID : number;
//#endregion paymentMethodID Prop


//#region paymentMethodName Prop
        @required()
        @maxLength({value:100})
        paymentMethodName : string;
//#endregion paymentMethodName Prop


//#region authorizationTransactionID Prop
        @required()
        @maxLength({value:4000})
        authorizationTransactionID : string;
//#endregion authorizationTransactionID Prop


//#region authorizationTransactionCode Prop
        @required()
        @maxLength({value:4000})
        authorizationTransactionCode : string;
//#endregion authorizationTransactionCode Prop


//#region authorizationTransactionResult Prop
        @required()
        @maxLength({value:4000})
        authorizationTransactionResult : string;
//#endregion authorizationTransactionResult Prop


//#region captureTransactionID Prop
        @required()
        @maxLength({value:4000})
        captureTransactionID : string;
//#endregion captureTransactionID Prop


//#region captureTransactionResult Prop
        @required()
        @maxLength({value:4000})
        captureTransactionResult : string;
//#endregion captureTransactionResult Prop


//#region subscriptionTransactionID Prop
        @required()
        @maxLength({value:4000})
        subscriptionTransactionID : string;
//#endregion subscriptionTransactionID Prop


//#region purchaseOrderNumber Prop
        @required()
        @maxLength({value:100})
        purchaseOrderNumber : string;
//#endregion purchaseOrderNumber Prop


//#region paymentStatusID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        paymentStatusID : number;
//#endregion paymentStatusID Prop


//#region paidDate Prop
        @prop()
        paidDate : Date;
//#endregion paidDate Prop


//#region billingFirstName Prop
        //@required()
        @maxLength({value:100})
        billingFirstName : string;
//#endregion billingFirstName Prop


//#region billingLastName Prop
        //@required()
        @maxLength({value:100})
        billingLastName : string;
//#endregion billingLastName Prop


//#region billingPhoneNumber Prop
        //@required()
        //@maxLength({value:50})
        @prop()
        billingPhoneNumber : string;
//#endregion billingPhoneNumber Prop


//#region billingEmail Prop
        //@required()
        @maxLength({value:255})
        billingEmail : string;
//#endregion billingEmail Prop


//#region billingFaxNumber Prop
        //@required()
        @maxLength({value:50})
        billingFaxNumber : string;
//#endregion billingFaxNumber Prop


//#region billingCompany Prop
        //@required()
        @maxLength({value:100})
        billingCompany : string;
//#endregion billingCompany Prop


//#region billingAddress1 Prop
        //@required()
        @maxLength({value:100})
        billingAddress1 : string;
//#endregion billingAddress1 Prop


//#region billingAddress2 Prop
        //@required()
        @maxLength({value:100})
        billingAddress2 : string;
//#endregion billingAddress2 Prop


//#region billingCity Prop
        //@required()
        @maxLength({value:100})
        billingCity : string;
//#endregion billingCity Prop


//#region billingStateProvince Prop
        //@required()
        @maxLength({value:100})
        billingStateProvince : string;
//#endregion billingStateProvince Prop


//#region billingStateProvinceID Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        //@required()
        @prop()
        billingStateProvinceID : number;
//#endregion billingStateProvinceID Prop


//#region billingZipPostalCode Prop
        //@required()
        @maxLength({value:30})
        billingZipPostalCode : string;
//#endregion billingZipPostalCode Prop


//#region billingCountry Prop
        //@required()
        @maxLength({value:100})
        billingCountry : string;
//#endregion billingCountry Prop


//#region billingCountryID Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        //@required()
        @prop()
        billingCountryID : number;
//#endregion billingCountryID Prop


//#region shippingStatusID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        shippingStatusID : number;
//#endregion shippingStatusID Prop


//#region shippingFirstName Prop
        @required()
        @maxLength({value:100})
        shippingFirstName : string;
//#endregion shippingFirstName Prop


//#region shippingLastName Prop
        @required()
        @maxLength({value:100})
        shippingLastName : string;
//#endregion shippingLastName Prop


//#region shippingPhoneNumber Prop
        @required()
        //@maxLength({value:50})
        shippingPhoneNumber : string;
//#endregion shippingPhoneNumber Prop


//#region shippingEmail Prop
        @required()
        @maxLength({value:255})
        shippingEmail : string;
//#endregion shippingEmail Prop


//#region shippingFaxNumber Prop
        @required()
        @maxLength({value:50})
        shippingFaxNumber : string;
//#endregion shippingFaxNumber Prop


//#region shippingCompany Prop
        @required()
        @maxLength({value:100})
        shippingCompany : string;
//#endregion shippingCompany Prop


//#region shippingAddress1 Prop
        @required()
        @maxLength({value:100})
        shippingAddress1 : string;
//#endregion shippingAddress1 Prop


//#region shippingAddress2 Prop
        @required()
        @maxLength({value:100})
        shippingAddress2 : string;
//#endregion shippingAddress2 Prop


//#region shippingCity Prop
        @required()
        @maxLength({value:100})
        shippingCity : string;
//#endregion shippingCity Prop


//#region shippingStateProvince Prop
        @required()
        @maxLength({value:100})
        shippingStateProvince : string;
//#endregion shippingStateProvince Prop


//#region shippingStateProvinceID Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        shippingStateProvinceID : number;
//#endregion shippingStateProvinceID Prop


//#region shippingZipPostalCode Prop
        @required()
        @maxLength({value:30})
        shippingZipPostalCode : string;
//#endregion shippingZipPostalCode Prop


//#region shippingCountry Prop
        @required()
        @maxLength({value:100})
        shippingCountry : string;
//#endregion shippingCountry Prop


//#region shippingCountryID Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        shippingCountryID : number;
//#endregion shippingCountryID Prop


//#region shippingMethod Prop
        //@required()
        @maxLength({value:100})
        shippingMethod : string;
//#endregion shippingMethod Prop


//#region shippingRateComputationMethodID Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        shippingRateComputationMethodID : number;
//#endregion shippingRateComputationMethodID Prop


//#region shippedDate Prop
        @prop()
        shippedDate : Date;
//#endregion shippedDate Prop


//#region trackingNumber Prop
        @required()
        @maxLength({value:100})
        trackingNumber : string;
//#endregion trackingNumber Prop


//#region deliveryDate Prop
        @prop()
        deliveryDate : Date;
//#endregion deliveryDate Prop


//#region vatNumber Prop
        @required()
        @maxLength({value:100})
        vatNumber : string;
//#endregion vatNumber Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region orderSubTotalDiscountInclTax Prop
        @required()
        orderSubTotalDiscountInclTax : number;
//#endregion orderSubTotalDiscountInclTax Prop


//#region orderSubTotalDiscountInclTaxInCustomerCurrency Prop
        @required()
        orderSubTotalDiscountInclTaxInCustomerCurrency : number;
//#endregion orderSubTotalDiscountInclTaxInCustomerCurrency Prop


//#region orderSubTotalDiscountExclTax Prop
        @required()
        orderSubTotalDiscountExclTax : number;
//#endregion orderSubTotalDiscountExclTax Prop


//#region orderSubTotalDiscountExclTaxInCustomerCurrency Prop
        @required()
        orderSubTotalDiscountExclTaxInCustomerCurrency : number;
//#endregion orderSubTotalDiscountExclTaxInCustomerCurrency Prop


//#region orderDate Prop
        @required()
        orderDate : Date;
//#endregion orderDate Prop


//#region invoicenumber Prop
        @maxLength({value:50})
        invoicenumber : string;
//#endregion invoicenumber Prop


//#region salesChannelID Prop
        @required()
        salesChannelID : number;
//#endregion salesChannelID Prop


//#region salesChannelTypeID Prop
        @prop()
        salesChannelTypeID : number;
//#endregion salesChannelTypeID Prop


//#region salesUserId Prop
        @prop()
        salesUserId : number;
//#endregion salesUserId Prop


//#region receiptnumber Prop
        @maxLength({value:50})
        receiptnumber : string;
//#endregion receiptnumber Prop


//#region exchangerate Prop
        @prop()
        exchangerate : DecimalProvider;
//#endregion exchangerate Prop


//#region giftwrapMessgae Prop
        @maxLength({value:4000})
        giftwrapMessgae : string;
//#endregion giftwrapMessgae Prop


//#region addGiftwrap Prop
        @prop()
        addGiftwrap : boolean;
//#endregion addGiftwrap Prop


//#region isGifted Prop
        @prop()
        isGifted : boolean;
//#endregion isGifted Prop


//#region shippingMethodID Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        //@required()
        @prop()
        shippingMethodID : number;
//#endregion shippingMethodID Prop


//#region noAddressProvided Prop
        @required()
        noAddressProvided : boolean;
//#endregion noAddressProvided Prop


//#region exchangerate_original Prop
        @prop()
        exchangerate_original : number;
//#endregion exchangerate_original Prop


//#region notes Prop
        @maxLength({value:4000})
        notes : string;
//#endregion notes Prop


//#region salebyCRM_VIP_BIZ Prop
        @prop({defaultValue: false})
        @required()
        salebyCRM_VIP_BIZ : boolean;
//#endregion salebyCRM_VIP_BIZ Prop



}