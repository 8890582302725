import {vFiltersLookupBase,} from '@app/database-models'
//Generated Imports
export class vFiltersLookup extends vFiltersLookupBase
{



//#region Generated Reference Properties

//#endregion Generated Reference Properties










}