import { http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from '../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../domain/customize-design/dialog';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { MoveNoteDocumentFilterViewModel } from "src/app/view-model/move-note-document-filters-view-model";

@http({
    path: 'api/Notes'
})
export class AbstractMoveNoteDocumentTarget extends CoreComponent {

    showComponent: boolean;
    toastr: BaseToastr;
    dialog: BaseDialog;
    spin: boolean = false;
    moveNoteDocumentLookUps: any;
    moveNoteDocumentFilters: MoveNoteDocumentFilterViewModel = new MoveNoteDocumentFilterViewModel();
    moveNoteDocumentFormGroup: IFormGroup<MoveNoteDocumentFilterViewModel>;
    rootEntityName: string = '';
    modouleIdObj = {
        userModuleId: RolePermissionEnums.User,
        corporateModuleId: RolePermissionEnums.Supplier,
        eventModuleId: RolePermissionEnums.Event,
        projectModuleId: RolePermissionEnums.Project,
        pressMediaModuleId: RolePermissionEnums.PressandMedia,
        orderModuleId: RolePermissionEnums.SalesOrders,
        legalAgreementModuleId: RolePermissionEnums.LegalAgreements,
        hseModuleId: RolePermissionEnums.HSE,
        riskModuleId: RolePermissionEnums.GemfieldsRiskMenu,
        itHelpDeskModuleId: RolePermissionEnums.ITHelpDesk,
        piecesModuleId: RolePermissionEnums.Pieces,
        bookingsModuleId: RolePermissionEnums.Bookings,
        priceListModuleId: RolePermissionEnums.FabergePriceLists,
        designModuleId: RolePermissionEnums.ProductDesign,
        gemstoneModuleId: RolePermissionEnums.FabergeGemstones,
        productModuleId: RolePermissionEnums.Products,
        batchesModuleId: RolePermissionEnums.FabergeBatches,
    }

    browserStorage: BrowserStorage;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.browserStorage = new BrowserStorage();
    }

}
