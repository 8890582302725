import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEggSizeLookUpBase {

//#region ringSizeId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'ringSizeId', keyColumn: true})
        ringSizeId : number;
//#endregion ringSizeId Prop


//#region ringSize Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'ringSize', keyColumn: false})
        ringSize : number;
//#endregion ringSize Prop


//#region ringSizeType Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'ringSizeType', keyColumn: false})
        ringSizeType : number;
//#endregion ringSizeType Prop

}