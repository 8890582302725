import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CollectionInspirationBase {

//#region collectionInspirationId Prop
        @prop()
        collectionInspirationId : number;
//#endregion collectionInspirationId Prop


//#region collectionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        collectionId : number;
//#endregion collectionId Prop


//#region displayOrderId Prop
        @prop()
        @required()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region imageAlt Prop
        @maxLength({value:100})
        @trim()
        imageAlt : string;
//#endregion imageAlt Prop


//#region imageUrlName Prop
        @prop()
        @required()
        imageUrlName : number;
//#endregion imageUrlName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}