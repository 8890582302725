import {vBoutiqueSalesTeamLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBoutiqueSalesTeamLookUp extends vBoutiqueSalesTeamLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}