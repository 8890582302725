import { Component, OnInit, Input } from '@angular/core';
import { AbstractDepartment } from '../domain/abstract-department';
import { AppGrid } from 'src/app/domain/app-grid';
import { DepartmentCustomerViewModel } from 'src/app/view-model/department-customer-view-model';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { multilingual } from '@rxweb/localization';
@multilingual("CustomerDetailComponent")

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent extends AbstractDepartment implements OnInit {
  @Input() departmentId:number;
  deptCustomerGrid:AppGrid;
  deptCustomerList:DepartmentCustomerViewModel[];
  isFilter:boolean;
  totalRecords:number;
  json:any;
  list: any[];
  isShowGrid : boolean;
  isNoRecordFound : boolean;
  isShowGridColumn: boolean = false;
  constructor() { 
    super();
  }

  ngOnInit() {
    this.isFilter=false;
    this.json = JSON.parse(JSON.stringify({department: this.departmentId,
      //clientUrl:CLIENT_URL,
       pageIndex: 1,isTab:1, rowCount: 25,searchString:null}));
    this.json["pageIndex"] = PaginationEnum.PageIndex;
    this.json["rowCount"] = PaginationEnum.RowCount;
   this.bindGrid();
  }


  bindGrid(){
    this.spin=true;
    this.isShowGrid=true;
    this.totalRecords=0;
    this.post({path:'api/SearchUsers/GetUsersByDepartment',body:{query:JSON.stringify(this.json)}}).subscribe((t: DepartmentCustomerViewModel[]) => {
    this.deptCustomerList=t;
    this.spin=false;
    this.totalRecords=this.deptCustomerList.length>0?this.deptCustomerList[0].totalCount:0;
    this.isShowGrid=this.totalRecords>0?true:false;
    this.isNoRecordFound=this.totalRecords>0?false:true;
    if(this.isShowGrid==true){
     
      if(!this.isFilter){
        this.deptCustomerGrid=new AppGrid(this.deptCustomerList,DepartmentCustomerViewModel);
        this.deptCustomerGrid.storeProcedure = {
          length: this.totalRecords,
         onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }
        
      }
      else{
          this.deptCustomerGrid.updateSource([]);
          this.deptCustomerGrid.storeProcedure.length=this.totalRecords;
          this.deptCustomerGrid.updateSource(this.deptCustomerList);
      }
      this.deptCustomerGrid.componentId = this.componentName;
      this.deptCustomerGrid.maxPerPage=this.json["rowCount"];
       
     }
  });
}

onPageSorting(x, y, z) {
  this.isFilter = true;
  this.json["sortOrder"]=y;
  this.json["orderBy"]=x;
  this.deptCustomerGrid.storeProcedure.nextPage = z;
  this.deptCustomerGrid.updateSource([]);
  this.bindGrid();
}

search(event:any){
  this.json["searchString"]=event.target.value.replace(/^\s+|\s+$/gm,'');;
  this.isFilter=true;
  this.json["pageIndex"]=1;
  if(this.deptCustomerGrid!=null){
      this.deptCustomerGrid.updateSource([]);
  }
  this.isShowGrid=false;
  this.bindGrid();
}
onPageChanging(x) {
  this.isFilter = true;
  this.json["pageIndex"]=x;
  this.json["rowCount"]= this.deptCustomerGrid.maxPerPage;
  this.deptCustomerGrid.storeProcedure.nextPage = x;
  this.deptCustomerGrid.updateSource([]);
  this.bindGrid();
}
get componentName(): string {
  return "CustomerDetailComponent";
}

}
