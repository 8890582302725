import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDiscountTypeLookUpBase {

//#region applicationObjectId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'applicationObjectId', keyColumn: true})
        applicationObjectId : number;
//#endregion applicationObjectId Prop


//#region applicationObjectTypeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'applicationObjectTypeId', keyColumn: false})
        applicationObjectTypeId : number;
//#endregion applicationObjectTypeId Prop


//#region applicationObjectName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'applicationObjectName', keyColumn: false})
        applicationObjectName : string;
//#endregion applicationObjectName Prop


//#region isActive Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'isActive', keyColumn: false})
        isActive : any;
//#endregion isActive Prop

}