import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBangleSizeRecordBase {

//#region id Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'id', keyColumn: true})
        id : number;
//#endregion id Prop


//#region size Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'size', keyColumn: false})
        size : string;
//#endregion size Prop

}