<div class="search-result-container" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column mt-4" [rxSpinner]="spin">
        <div class="ibox-title bg-white">
            <h5 *ngIf="!isShowSalesorderbutton" rxText="CorporateOrdersListComponent_Faberge_Orders_t"></h5>
            <button *ngIf="isShowSalesorderbutton" class="btn btn-success" (click)="showAddForm()" rxText="CorporateOrdersListComponent_AddOrders_t"></button>
            <div class="ibox-tools d-flex">
                <label class="ml-auto mr-0 mb-3" *ngIf="isShowSearch">
                    <input type="search" (keyup.enter)="search($event)"
                        class=" table_search form-control form-control-sm pr-4" placeholder="" [title]="titleSearch">
                </label>
                <a class="dropdown-toggle" (click)="showGridColumn()" *ngIf="isShowGrid" [title]="titleGridColumnSetting">
                    <i class="fa fa-cog"></i>
                </a>
                <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
                    (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
                    [gridColumnConfig]="fabergeOrdersGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
                    [moduleID]="moduleID"
                    [selectedTab]="selectedTab">
                </app-system-table-setting>
            </div>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
            <div class="mb-2 d-flex justify-content-between flex-column flex-sm-row" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
                <div class="d-flex align-items-center mb-1">
                    <span rxText="CorporateOrdersListComponent_Total_Pieces_t"></span>
                    <h3 class="my-0 ml-1">{{totalNumberPieces}}</h3>
                </div>
                <div class="d-flex align-items-center mb-1">
                    <span rxText="CorporateOrdersListComponent_Total_Amount_t"></span>
                    <h3 class="my-0 ml-1">{{totalOrderAmount}}</h3>
                </div>
            </div>
            <div class="table-responsive" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
                <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                    <rx-grid [design]="fabergeOrdersGrid"></rx-grid>
                </div>
            </div>
            <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                <h4>No Record Found</h4>
            </div>
        </div>
    </div>
</div>
