import {ProductPieceGemstoneMappingBase,CategoryBase,CurrencyBase,InvoiceBase,ProductPieceGemstoneBase,ProductPieceBase,} from '@app/database-models'
//Generated Imports
export class ProductPieceGemstoneMapping extends ProductPieceGemstoneMappingBase 
{




//#region Generated Reference Properties
//#region category Prop
category : CategoryBase;
//#endregion category Prop
//#region currency Prop
currency : CurrencyBase;
//#endregion currency Prop
//#region invoice Prop
invoice : InvoiceBase;
//#endregion invoice Prop
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop
//#region productPiece Prop
productPiece : ProductPieceBase;
//#endregion productPiece Prop

//#endregion Generated Reference Properties
supplier: string










}