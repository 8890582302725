import {GemstoneLaboratoryBase,ProductGemstoneCertificateBase,} from '@app/database-models'
//Generated Imports
export class GemstoneLaboratory extends GemstoneLaboratoryBase 
{




//#region Generated Reference Properties
//#region productGemstoneCertificates Prop
productGemstoneCertificates : ProductGemstoneCertificateBase[];
//#endregion productGemstoneCertificates Prop

//#endregion Generated Reference Properties











}