import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { KnownTo, vSalesAdvisorLookUp, vRelationShipLookUp } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, } from '@rxweb/angular-router';
import { vUserKnownToBase } from '../../../../models/database-models/v-user-known-to-base';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { vSalesUserLookUpBase } from 'src/app/models/database-models/v-sales-user-look-up';


@http({
    path: "api/KnownTos",
})

export class AbstractKnownTo extends CoreComponent {
    knownToFormGroup: IFormGroup<KnownTo>
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    spin: boolean = false;
    showComponent: boolean = false;
    isNoRecordFound :boolean ;
    isShowGrid :boolean =true;
    id: number;
    allowEdit:boolean;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    userKnownToLookUp: any = {
        //salesAdvisorLookUp: vSalesAdvisorLookUp,
        salesAdvisorLookUp: vSalesUserLookUpBase,
        relationShipLookUp: vRelationShipLookUp
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
