import { gridColumn } from "@rxweb/grid"


export class vColorBase {

        //#region fabergeColorId Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ColorListComponent_ColorId_gh', keyColumn: true })
        fabergeColorId: number;
        //#endregion fabergeColorId Prop


        //#region colorName Prop
        @gridColumn({ style: { width: "33%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, isAscending: true, allowSorting: true, headerKey: 'ColorListComponent_ColorName_gh', keyColumn: false })
        colorName: string;
        //#endregion colorName Prop

        @gridColumn({
                template: {
                        div: {
                                style: {
                                        "word-break": "break-all",
                                },
                                attributes: {
                                        innerHTML: function (x, y) {
                                                if (this.isActive) {
                                                        return "<i class='fa fa-check' style='color : #28a745'></i>";
                                                }
                                                else {
                                                        return "<i class='fa fa-times text-danger'></i>";
                                                }
                                        }
                                },
                        }
                }, style: { width: "32%", "text-align": "center" }, class: ["text-center"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'ColorListComponent_IsActive_gh', keyColumn: false
        })
        isActive: boolean;


}
