import {ProductAttributeValueBase,ProductAttributeMappingBase,ProductAttributeValueLocalizationBase,} from '@app/database-models'
import { propArray } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ProductAttributeValue extends ProductAttributeValueBase 
{




//#region Generated Reference Properties
//#region productAttributeMapping Prop
@propArray(ProductAttributeMappingBase)
productAttributeMapping : ProductAttributeMappingBase;
//#endregion productAttributeMapping Prop
//#region productAttributeValueLocalizations Prop
productAttributeValueLocalizations : ProductAttributeValueLocalizationBase[];
//#endregion productAttributeValueLocalizations Prop

//#endregion Generated Reference Properties

}