import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SpecificationAttributeOptionLocalizationBase {

//#region specificationAttributeOptionLocalizedId Prop
        @prop()
        specificationAttributeOptionLocalizedId : number;
//#endregion specificationAttributeOptionLocalizedId Prop


//#region specificationAttributeOptionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        specificationAttributeOptionId : number;
//#endregion specificationAttributeOptionId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @required()
        @maxLength({value:500})
        name : string;
//#endregion name Prop


//#region description Prop
        @prop()
        description : string;
//#endregion description Prop


//#region metaKeywords Prop
        @prop()
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @prop()
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @maxLength({value:800})
        metaTitle : string;
//#endregion metaTitle Prop





}