import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractDepartment } from '../domain/abstract-department';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("DepartmentEditComponent")
@access({ accessLevel: RolePermissionEnums.Department, action: "put" })

@Component({
    selector: "app-department-edit",
    templateUrl: './department-edit.component.html'
})
export class DepartmentEditComponent extends AbstractDepartment implements OnInit, OnDestroy {
    department: Department;
    subscription: any;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    departmentDelete:any=dbAccessModule[RolePermissionEnums.Department]["delete"];
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private title: Title, router: Router,private formBuilder: RxFormBuilder,private applicationBroadcaster:ApplicationBroadcaster, private activatedRoute: ActivatedRoute, modelView: ModalView, private el: ElementRef, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.activatedRoute.queryParams.subscribe(t => {
            if (t['activeTab']) {
                this.selectedTab = t['activeTab'];
            }
            else{
                this.selectedTab="department-info";
            }
        })
        this.router=router;
        this.moduleID = RolePermissionEnums.Department;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Department - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.lookup([{ params: [this.id], propName:"department" }, { path: USER_LOOKUPS.peopleTypeLookUp, propName: "peopleTypeLookUp" }]).subscribe((lookup: any) => {
            if (lookup.department) {
                this.departmentLookup = lookup;
                this.departmentFormGroup = this.formBuilder.formGroup(Department, this.departmentLookup.department) as IFormGroup<Department>;
                this.showComponent = true;
                this.showTab = true;
                this.spin = false;
            }
            else {
                this.router.navigate(['/departments']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editDepartment(isRedirect) {
        this.departmentFormGroup.submitted = true;
        if (this.departmentFormGroup.valid) {
            this.spin=true;
            this.put({ body: this.departmentFormGroup.value, params: [this.id] }).subscribe(data => {
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                if(isRedirect){
                    this.showComponent=false;
                    this.spin=false;
                    this.ngOnInit()
                }
                else{
                    this.router.navigate(['/departments']);
                }
            })
        }else {
            for (const key of Object.keys(this.departmentFormGroup.controls)) {
                if (this.departmentFormGroup.controls[key].invalid) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    invalidControl.focus();
                    break;
                }
            }
        }
    }

    deleteDepartment() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/departments']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Department");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    get componentName(): string {
        return "DepartmentEditComponent";
    }
}
