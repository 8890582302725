import {
  Directive,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  ElementRef,
} from "@angular/core";
import { RxTextDirective } from "@rxweb/angular-localization";
import { RxPlaceholderDirective } from "@rxweb/angular-localization";

@Directive({
  selector: "[rxLocalisationInit]",
})
export class RxLocalisationInitDirective implements AfterContentInit {

  @ContentChildren(RxTextDirective, { descendants: true }) rxTextChildDirectives: QueryList<RxTextDirective>;
  @ContentChildren(RxPlaceholderDirective, { descendants: true }) rxPlaceholderChildDirectives: QueryList<RxPlaceholderDirective>;

  componentConstructor = null;

  @Input("rxLocalisationInit") set component(value: any) {
    this.componentConstructor = value;
  }

  ngAfterContentInit() {
      this.rxTextChildDirectives.forEach((t) => {
        if(!t["element"]["isPopulated"]){
        t.componentId = this.componentConstructor;
        t.ngAfterViewInit();}
      });
      this.rxPlaceholderChildDirectives.forEach((t) => {
        if(!t["element"]["isPopulated"]) {
        t.componentId = this.componentConstructor;
        t.ngAfterViewInit();}
      });
  }
}
