import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { CompanyCustomerMapping } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { vCompanyLookup } from 'src/app/models/extended-models/v-company-lookup';
import { vReportingHeadLookup } from 'src/app/models/extended-models/v-reporting-head-lookup';
@http({
    path: 'api/CompanyCustomerMappings'
})
export class AbstractCompanyCustomerMapping extends CoreComponent {
    companyCustomerMappingFormGroup: IFormGroup<CompanyCustomerMapping>
    showComponent: boolean = false;
    toastr: BaseToastr
    dialog: BaseDialog;
    modalView: ModalView;
    spin: boolean = false;
    showSaveButton: boolean = false;
    isNoRecordFound : boolean;
    isShowGrid : boolean;
    isShowCompanyCustomerMappingGrid: boolean;
    isShowEmploymentMappingGrid: boolean;
    isShowDirectorshipMappingGrid: boolean;
    employmentData: any = {
        companyCustomer: CompanyCustomerMapping,
        companyLookUp: vCompanyLookup,
        reportingHeadLookup: vReportingHeadLookup
    }
    payrolltype: any; //#292627//08-07-2024//added payroll lookup variable
    patersongrade: any; //#292627//08-07-2024//added paterson grad lookup variable 
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => { })
    }
}
