import { required, notEmpty, maxLength, prop, range } from '@rxweb/reactive-form-validators';
import { gridColumn } from '@rxweb/grid';

export class ValidateUserBase {

        //#region userId Prop
        @gridColumn({  style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ExistsUserSearchComponent_UserId_gh',headerTitle:'', keyColumn: true })
        userId: number;
        //#endregion userId Prop


        //#region fullName Prop
        @gridColumn({ style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, configuredTemplate: { templateName: 'peopleNameAndTooltip' }, columnIndex: 1, allowSorting: true, headerKey: 'ExistsUserSearchComponent_Name_gh',headerTitle:'', keyColumn: false })
        fullName: any;
        //#endregion fullName Prop

        //#region email Prop
        @gridColumn({ style: { width: "20%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 2, allowSorting: true, headerKey: 'ExistsUserSearchComponent_Email_gh',headerTitle:'', keyColumn: false })
        email: any;
        //#endregion email Prop

        //#region roleType Prop
        @gridColumn({ style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ExistsUserSearchComponent_RoleType_gh',headerTitle:'', keyColumn: false })
        roleType: any;
        //#endregion roleType Prop

        //#region company Prop
        @gridColumn({
                template:{
                    div:{
                      attributes:{
                        innerHTML:function(x,y){
                         return this.companyName;
                        }
                      },
                    }
                }, parameter: false, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'ExistsUserSearchComponent_Company_gh',headerTitle:'', keyColumn: false 
              })            
        companyName:string
        //#region company Prop

        //#region countryName 
        @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ExistsUserSearchComponent_Country_gh',headerTitle:'', keyColumn: false })
        countryName: string;
        //#endregion countryName

        //#region salesAdversierName 
        @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ExistsUserSearchComponent_SalesAdvisor_gh',headerTitle:'' ,keyColumn: false })
        salesAdversierName: string;
        //#endregion salesAdversierName 

        //#region keyRelationshipHolder 
        @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ExistsUserSearchComponent_KeyHolder_gh',headerTitle:'', keyColumn: false })
        keyRelationshipHolder: string;
        //#endregion keyRelationshipHolder 

        contactPermissionRequired: boolean;
        isUnwelcome: boolean;
}