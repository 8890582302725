import {NoteTypeBase,} from '@app/database-models'
//Generated Imports
export class NoteType extends NoteTypeBase 
{

    constructor() {
        super();
        this.active = false;
    }


//#region Generated Reference Properties

//#endregion Generated Reference Properties













}