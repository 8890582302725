import { TemplateConfig } from '@rxweb/grid';
import { RouterLink } from '@angular/router';
import { route } from '@rxweb/angular-router';

export const CORPORATE_LINK_REDIRECT_GRID_TEMPLATE: TemplateConfig = {

  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onCorporateLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if(this.companyId != null || this.companyId != undefined){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              }
              if(this.CompanyID != null || this.CompanyID != undefined){
                return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if(this.companyId != null || this.companyId != undefined){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              }
              if(this.CompanyID != null || this.CompanyID != undefined){
                return "/suppliers/" + this.CompanyID + "?activeTab=supplier-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};
