import {UserEmailSettingBase,UserBase,} from '@app/database-models'
import { propArray } from '@rxweb/reactive-form-validators';
//Generated Imports
export class UserEmailSetting extends UserEmailSettingBase 
{


 @propArray(UserEmailSetting)
 userEmailSettings: UserEmailSetting[]

//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties


}