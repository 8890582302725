import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';

import { ProjectEventMapping, Project } from '@app/models';
import { AbstractProjectEventMapping } from '../domain/abstract-project-event-mapping';
import { ProjectsFilterViewModel } from 'src/app/view-model/projects-filter-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe, PlatformLocation } from '@angular/common';
import { AppGrid } from 'src/app/domain/app-grid';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { ProjectEventInputModel } from 'src/app/view-model/project-event-input-model';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { vProjectEventMapping } from 'src/app/models/extended-models/v-project-event-mapping';
import { CoreComponent } from '@rxweb/angular-router';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { multilingual } from '@rxweb/localization';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
@multilingual("ProjectEventMappingAddComponent")

@Component({
    selector: "app-project-event-mapping-add",
    templateUrl: './project-event-mapping-add.component.html'
})
export class ProjectEventMappingAddComponent extends AbstractProjectEventMapping implements OnInit, OnDestroy {
    projectEventMapping: ProjectEventMapping;
    projectInputModel: ProjectEventMappingViewModel;
    showGrid: boolean = false;
    @Input() hide: Function;
    @Input() eventId: number;
    totalRecords: number = 0;
    isFilter: boolean;
    dateError: boolean = false;
    headerBtnChk: boolean = false;
    cloneArray: ProjectEventMappingViewModel[];

    subscription: any;
    id: number;
    projectFilterMapping: ProjectsFilterViewModel;
    projectEventMappingFilterFormGroup: IFormGroup<ProjectsFilterViewModel>;
    projectName: string = "";
    titleClose: string;
    ErrorMsg:string;
    filtersJson:any;
    excludedProjectEvent:ProjectEventMapping;
    excludedProjectEventIds:number[];
    projectIds:number[];
    totalRestrictedCount:number = 0;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, modelView: ModalView, private datePipe: DatePipe, private browserStorage: BrowserStorage,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.excludedProjectEvent = new ProjectEventMapping();
        this.excludedProjectEvent.projectEventInput = new Array <ProjectEventMappingViewModel>();
        this.excludedProjectEventIds = new Array<number>();
        this.projectIds = new Array<number>();
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.isFilter = false;
        this.spin = true;
        this.cloneArray = new Array<ProjectEventMappingViewModel>();
        this.projectEventMapping = new ProjectEventMapping();
        this.projectEventMapping.eventID = this.eventId;
        this.projectEventMapping.projectEventMappingID = 0;
        this.projectEventMapping.projectID = 1;
        this.projectEventMapping.projectEventInput = new Array<ProjectEventMappingViewModel>();
        this.projectFilterMapping = new ProjectsFilterViewModel();
        this.projectFilterMapping.rowCount = PaginationEnum.RowCount;
        this.projectFilterMapping.sortOrder = 'true';
        this.projectFilterMapping.orderBy = 'projectName';
        this.projectFilterMapping.searchString = '';
        this.projectFilterMapping.projectOrEvent = 1;
        this.projectFilterMapping.pageIndex = PaginationEnum.PageIndex;
        this.spin = false;
        this.projectFilterMapping.userId = 0;
        this.projectEventMappingFilterFormGroup = this.formBuilder.formGroup(ProjectsFilterViewModel, this.projectFilterMapping) as IFormGroup<ProjectsFilterViewModel>;
        this.projectEventMappingFilterFormGroup.controls.projectEndDate.setValidators(RxwebValidators.date({ messageKey: 'projectEndDate', message: 'End Date should be greater than start date', conditionalExpression: (x, y) => x.projectEndDate >= x.projectStartDate }));
        this.showComponent = true;

    }



    getProjectEventMappings() {
        let startDate = new Date();
        startDate = this.projectEventMappingFilterFormGroup.controls.projectStartDate.value;
        let endDate = new Date();
        this.totalRecords = 0;
        endDate = this.projectEventMappingFilterFormGroup.controls.projectEndDate.value;
        if (endDate != null && startDate > endDate) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            this.cloneArray = new Array<ProjectEventMappingViewModel>();
            if(this.headerBtnChk) {
                this.projectEventMapping.projectEventInput = [];
                this.excludedProjectEvent.projectEventInput = [];
                this.excludedProjectEventIds = [];
                this.projectIds = [];
            }
            this.headerBtnChk = false;
            this.projectEventMapping.projectEventInput = new Array<ProjectEventMappingViewModel>();
            if (this.projectEventMappingGrid != null) {
                this.isFilter = true;
                this.projectEventMappingGrid.updateSource([]);
                this.projectEventMappingGrid.storeProcedure.nextPage = 1;
                this.projectEventMappingFilterFormGroup.value.rowCount = this.projectEventMappingGrid.maxPerPage;
            }
            if (this.projectEventMappingFilterFormGroup.value.projectName != null || this.projectEventMappingFilterFormGroup.value.projectName != undefined) {
                // this.relatedProductSearchFormGroup.value.productName=this.relatedProductSearchFormGroup.value.productName.replace(/^\s+|\s+$/gm,'');
                this.projectEventMappingFilterFormGroup.value.projectName = this.projectEventMappingFilterFormGroup.controls.projectName.value;
                this.projectName = this.projectEventMappingFilterFormGroup.controls.projectName.value;
                // this.projectEventMappingFilterFormGroup.patchValue({
                //     projectName: encodeURIComponent(this.projectEventMappingFilterFormGroup.value.projectName.replace(/^\s+|\s+$/gm, ''))
                // });
                //  this.projectEventMappingFilterFormGroup.value.projectName=encodeURIComponent(this.projectEventMappingFilterFormGroup.value.projectName.replace(/^\s+|\s+$/gm,''));
            }
            //this.projectEventMappingFilterFormGroup.value.pageIndex=1
            this.projectEventMappingFilterFormGroup.patchValue({
                pageIndex: 1
            });



            this.bindGrid();
        }
    }

    bindGrid() {
        this.isShowGrid = true;
        this.spin = true;
        this.projectEventMappingFilterFormGroup.patchValue({
            isSearch: 'true',
            eventId: this.eventId
        });
        this.filtersJson = this.projectEventMappingFilterFormGroup.value
        this.subscription = this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(this.projectEventMappingFilterFormGroup.value)) } }).subscribe((t: any) => {
            this.projectEventMappingList = t[0].UserData;
            this.ErrorMsg = t[0].ErrorMessage;
            this.projectEventMappingFilterFormGroup.patchValue({
                projectName: this.projectName
            });
            if (this.projectEventMappingGrid != null) {
                this.isFilter = true;
            }
            if (this.projectEventMappingList.length > 0) {
                this.projectEventMappingList.forEach(x => x.isChecked = this.headerBtnChk);
                this.projectEventMappingList.forEach(x => {
                    if (this.cloneArray.findIndex(z => z.projectID == x.projectID) == -1) {
                        this.cloneArray.push(x);
                    }
                    else {
                        this.cloneArray.forEach(z => {
                            if (z.projectID == x.projectID) {
                                x.isChecked = z.isChecked
                            }
                        })
                    }
                    if(this.headerBtnChk){
                        if(this.excludedProjectEventIds.indexOf(x.projectID) != -1) {
                            x.isChecked = false;
                        }
                    }
                    else{
                        if(this.projectIds.indexOf(x.projectID) != -1) {
                            x.isChecked = true;
                        }
                    }
                })
                this.updateProjectEventArray();
                this.totalRecords = this.projectEventMappingList[0].totalCount;
                this.totalRestrictedCount = this.projectEventMappingList[0].totalRestrictedProjects;

                this.isNoRecordFound = false;
            }
            else {
                this.isNoRecordFound = true;
                this.isShowGrid = false;
            }

            if (!this.isFilter) {
                this.projectEventMappingList.length > 0 ? this.showGrid = true : this.showGrid = false;
                this.projectEventMappingGrid = new AppGrid(this.projectEventMappingList, ProjectEventMappingViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                this.projectEventMappingGrid.gridColumns.forEach(x => {
                    if (x.columnIndex == 8) {
                        x.visible = false;
                    }
                    if (x.columnIndex == 9) {
                        x.visible = false;
                    }
                    if (x.columnIndex == 10) {
                        x.visible = false;
                    }
                    if (x.name == 'prjName') {
                        x.visible = true;
                    }
                    if (x.name == 'projectName') {
                        x.visible = false;
                    }
                })
                this.projectEventMappingGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }

            }
            else {
                if (this.projectEventMappingGrid != null) {
                    this.projectEventMappingGrid.storeProcedure.length = this.totalRecords;
                    this.projectEventMappingGrid.updateSource([]);
                    this.projectEventMappingGrid.updateSource(this.projectEventMappingList)
                }
            }
            this.spin = false;
            this.projectEventMappingGrid.maxPerPage = this.projectEventMappingFilterFormGroup.controls.rowCount.value;
            this.projectEventMappingGrid.componentId = this.componentName;
        })
    }

    // onCheckboxSelect(t, x){
    //     this.projectEventMappingList.forEach(data=>{
    //         if(data.projectID==t.projectID){
    //             data.isChecked=this.headerBtnChk;
    //         }
    //     });
    //     this.cloneArray.forEach(z=>{
    //         if(z.projectID==t.projectID){
    //           z.isChecked=x.target.checked;
    //         }
    //       })
    //       this.projectEventMappingGrid.updateSource([]);
    //       this.projectEventMappingGrid.updateSource(this.cloneArray);
    //          this.updateProjectEventArray();

    // }

    onCheckboxSelect(t, x) {
        // this.ProjectUserListViewModel.forEach(data => {
        //     if (data.id == t.id) {
        //         data.isChecked = x.target.checked;
        //     }
        // });
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<ProjectEventMappingViewModel>();

        //    oldCloneArray.forEach(data=>{
        //     let newObj = new ProjectEventMappingViewModel();
        //     newObj.corporate=data.corporate;
        //     newObj.email=data.email;
        //     newObj.fullName=data.fullName;
        //     newObj.rating=data.rating;
        //     newObj.id=data.id;
        //     newObj.isChecked= data.id==t.id ? x.target.checked : data.isChecked
        //     newObj.registrationDate=data.registrationDate;
        //     newObj.roleId=data.roleId;
        //     newObj.statusId=data.statusId;
        //     newObj.totalCount=data.totalCount;
        //     this.cloneArray.push(newObj);

        // });
        oldCloneArray.forEach(data => {
            let newObj = new ProjectEventMappingViewModel();
            newObj.eventID = data.eventID;
            newObj.isChecked = data.projectID == t.projectID ? x.target.checked : data.isChecked;
            newObj.noteDescription = data.noteDescription;
            newObj.projectEndDate = data.projectEndDate;
            newObj.projectID = data.projectID;
            newObj.prjName = data.prjName;
            newObj.isRestrictedProject = data.isRestrictedProject;
            newObj.projectStartDate = data.projectStartDate;
            newObj.projectType = data.projectType;
            newObj.totalCount = data.totalCount;
            this.cloneArray.push(newObj);
        });
        // this.cloneArray.forEach(z => {
        //     if (z.userId == t.userId) {
        //         z.isChecked = x.target.checked;
        //     }
        // })

        let scrollTop: number = 0;
        if(navigator.userAgent.match(/edg/i))
            scrollTop = document.getElementById('app-project-event-mapping-add').scrollTop;
        this.projectEventMappingGrid.updateSource([]);
        this.projectEventMappingGrid.updateSource(this.cloneArray);
        if(navigator.userAgent.match(/edg/i))
            document.getElementById('app-project-event-mapping-add').scrollTop = scrollTop;
        this.updateProjectEventArray();
    }


    updateProjectEventArray() {
        this.cloneArray.forEach(t => {
            if (t.isRestrictedProject == null || t.isRestrictedProject == false) {
                this.projectInputModel = new ProjectEventMappingViewModel();
                this.projectInputModel.projectID = t.projectID;
                if (t.isChecked) {
                    if(this.headerBtnChk){
                        if(this.excludedProjectEvent.projectEventInput.findIndex(x => x.projectID == t.projectID) != -1 ) {
                            this.excludedProjectEvent.projectEventInput.splice(this.excludedProjectEvent.projectEventInput.findIndex(x => t.projectID == x.projectID),1);
                            this.excludedProjectEventIds.splice(this.excludedProjectEvent.projectEventInput.findIndex(x => x.projectID == t.projectID),1);
                        }
                    }
                    else{
                        if (this.projectEventMapping.projectEventInput.findIndex(x => x.projectID == t.projectID) == -1) {
                            this.projectEventMapping.projectEventInput.push(this.projectInputModel);
                            this.projectIds.push(t.projectID);
                        }
                    }
                }
                else {
                    if (this.headerBtnChk) {
                        if (this.excludedProjectEvent.projectEventInput.findIndex(x => t.projectID == x.projectID) == -1) {
                          this.excludedProjectEvent.projectEventInput.push(this.projectInputModel);
                          this.excludedProjectEventIds.push(t.projectID)
                        }
                      }
                    else{
                        if (this.projectEventMapping.projectEventInput.findIndex(x => x.projectID == t.projectID) != -1) {
                            this.projectEventMapping.projectEventInput.splice(this.projectEventMapping.projectEventInput.findIndex(x => x.projectID == t.projectID), 1);
                            this.projectIds.splice(this.projectIds.push(t.projectID), -1)
                        }
                    }
                }
            }
        });

    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.projectEventMapping.projectEventInput = [];
        this.excludedProjectEvent.projectEventInput = [];
        this.excludedProjectEventIds = [];
        this.projectIds = [];
        this.cloneArray = new Array<ProjectEventMappingViewModel>();
        this.projectEventMappingList.forEach(data => {
            let newObj = new ProjectEventMappingViewModel();
            newObj.eventID = data.eventID;
            newObj.isChecked = this.headerBtnChk;
            newObj.noteDescription = data.noteDescription;
            newObj.projectEndDate = data.projectEndDate;
            newObj.projectID = data.projectID;
            newObj.prjName = data.prjName;
            newObj.isRestrictedProject = data.isRestrictedProject;
            newObj.projectStartDate = data.projectStartDate;
            newObj.projectType = data.projectType;
            newObj.totalCount = data.totalCount;
            this.cloneArray.push(newObj);
        });

        this.updateProjectEventArray();
        this.projectEventMappingGrid.updateSource([]);
        this.projectEventMappingGrid.updateSource(this.cloneArray);
    }

    // search(value) {
    //     // this.projectEventMappingGrid.search = value.target.value;
    //     this.projectEventMappingFilterFormGroup.patchValue({
    //         searchString:value.target.value
    //     });
    //     this.isFilter=true;
    //     this.getProjectEventMappings();
    // }

    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludedProjectEvent.projectEventInput.length - this.totalRestrictedCount)  + " Records";
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveProjectEventMappings();
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveProjectEventMappings();
        }
     }
    saveProjectEventMappings() {

        if (this.projectEventMapping.projectEventInput.length > 0 || this.excludedProjectEvent.projectEventInput.length >0 || this.headerBtnChk) {
            if(this.headerBtnChk){
                this.projectEventMapping.projectEventInput = this.excludedProjectEvent.projectEventInput;
                this.projectEventMapping.headerBtnCheck = this.headerBtnChk;
                var json = JSON.parse(JSON.stringify(this.filtersJson));
                json["sortOrder"] = "false";
                json["rowCount"] = this.totalRecords;
                json["pageIndex"] = 1;
                this.projectEventMapping.query = JSON.stringify(json);
                this.projectEventMapping.eventID = this.eventId;
                this.projectEventMapping.projectID = 0;
            }
            else{
                this.projectEventMapping.projectEventInput = this.projectEventMapping.projectEventInput.filter(x => x.isRestrictedProject == null || x.isRestrictedProject == false);
            }
            this.spin = true;
            this.post({ body: this.projectEventMapping }).subscribe(data => {
                this.spin = false;
                this.cloneArray = [];
                this.headerBtnChk = false;
                //this.toastr.success("Data Added Successfully")
                var existsAlert = getLocalizedValue("Data_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
                this.hide();
            })

        }

    }


    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.projectEventMappingFilterFormGroup.patchValue({
            sortOrder: y,
            orderBy: x
        });
        this.cloneArray = [];
        this.projectEventMappingGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.projectEventMappingFilterFormGroup.value.rowCount;
        let toIndex: number = this.projectEventMappingGrid.maxPerPage;
        //if (this.projectEventMappingFilterFormGroup.value.pageIndex < x) {
            this.cloneArray = [];
        //}
        if (this.projectEventMappingGrid.maxPerPage < this.projectEventMappingFilterFormGroup.value.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }
        }
        this.projectEventMappingFilterFormGroup.patchValue({
            pageIndex: x,
            rowCount: this.projectEventMappingGrid.maxPerPage
        });
        this.projectEventMappingGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "ProjectEventMappingAddComponent";
    }
}
