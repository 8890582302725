import { TemplateConfig } from '@rxweb/grid';

export const PROJECT_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => { 
        if(x.isRestrictedProject==true){
          return false;
        }
        else{
          return true;
        }
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },
                
                 event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const EVENT_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => { 
        if(x.isRestrictedEvent==true && !x.isAllowed){
          return false;
        }
        else{
          return true;
        }
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },
                
                 event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const ITHELPDESK_CHECKBOX_GRID_TEMPLATE = {
  div: {
    isBind: (x) => { 
        if(x.customerId==0 || x.ticketCustomerId ==0){
          return false;
        }
        else{
          return true;
        }
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },
                
                 event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}
