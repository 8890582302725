import {vLegalOwnerRecordBase,} from '@app/database-models'
//Generated Imports
export class vLegalOwnerRecord extends vLegalOwnerRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}