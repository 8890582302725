import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { RxHttp, http } from "@rxweb/http";
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyCustomerMapping, vCompanyLookup } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { CompanyCorporateViewModel } from '../../../../../../view-model/comapny-corporate-mapping-view-model';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CustomerCorporateMappingViewModel } from '../../../../../../view-model/customer-corporate-mapping-view-model';
import { CoreComponent } from '@rxweb/framework';
import { BaseToastr } from '../../../../../../domain/customize-design/toastr';
import { StatusEnum } from '../../../../../../enums/status.enum';
import { BedRequestComponent } from '../../../../../shared/global/bed-request/bed-request.component';
import { dateCompareValidation, getLocalizedValue } from '../../../../../../domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from "@angular/common";
@multilingual("CorporateGroupsEditComponent")
@Component({
    selector: "app-corporate-groups-edit",
    templateUrl: './corporate-groups-edit.component.html'
})

export class CorporateGroupsEditComponent extends CoreComponent implements OnInit, OnDestroy {

    customerCompanyEditFormGroup: IFormGroup<CompanyCustomerMapping>;
    showDate: boolean = false;
    showJobTitle: boolean = true;
    dateError: boolean = false;
    modalView: ModalView;
    spin: boolean = false;
    toastr: BaseToastr;
    showComponent: boolean = false;
    @Input() hide: Function;
    @Input() isDirector: boolean;
    @Input() userId: number;
    @Input() mappingId: number;
    @Input() isCorporate: boolean;
    @Input() isSupplier: boolean;
    id: number;
    date: Date;
    companyCustomerMapping: CompanyCustomerMapping;
    subscription: any;
    isEditable: boolean = false;
    employmentData: any = {
        companyCustomer: CompanyCustomerMapping,
        companyLookUp: vCompanyLookup
    }
    titleClose: string;
    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.toastr = new BaseToastr();
        location.onPopState(() => this.hide());

    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.lookup([{ path: 'api/CompanyCustomerMappings', propName: "companyCustomer", params: [this.mappingId] }, { propName: "companyLookup", path: USER_LOOKUPS.companyLookUp }]).subscribe((lookup: any) => {
            this.spin = false;
            this.employmentData = lookup;
            this.customerCompanyEditFormGroup = this.formBuilder.formGroup(CompanyCustomerMapping, this.employmentData.companyCustomer) as IFormGroup<CompanyCustomerMapping>;
            this.showComponent = true;

        });
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => { })
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    EditCorporate() {
        if (dateCompareValidation(this.customerCompanyEditFormGroup.value.startDate, this.customerCompanyEditFormGroup.value.endDate) == true) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            this.customerCompanyEditFormGroup.submitted = true;
            if (this.isSupplier == false) {
                this.customerCompanyEditFormGroup.patchValue({
                    isCorporate: this.isCorporate,
                    statusId: StatusEnum.Active,
                    companyCustomerMappingId: this.mappingId,
                    userId: this.userId,
                    isCorporateNote: this.isSupplier
                })
            }
            else {
                this.customerCompanyEditFormGroup.patchValue({
                    isCorporate: this.isCorporate,
                    statusId: StatusEnum.Active,
                    companyCustomerMappingId: this.mappingId,
                    companyId: this.userId,
                    isCorporateNote: this.isSupplier
                })
            }

            if (this.customerCompanyEditFormGroup.valid) {
                this.spin = true;
                this.put({ path: 'api/CompanyCustomerMappings', body: this.customerCompanyEditFormGroup.value, params: [this.mappingId] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Updated");
                    if (existsAlert) {
                        //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }
        }
    }

    get componentName() {
        return "CorporateGroupsEditComponent";
    }

}
