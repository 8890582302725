import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class OwnershipMappingBase {

//#region ownershipId Prop
        @prop()
        ownershipId : number;
//#endregion ownershipId Prop


//#region startDate Prop
        @prop()
        startDate : Date;
//#endregion startDate Prop


//#region endDate Prop
        @prop()
        endDate : Date;
//#endregion endDate Prop


//#region companyID Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        //@required()
        @prop()
        companyID : number;
//#endregion companyID Prop


//#region customerID Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        //@required()
        @prop()
        customerID : number;
//#endregion customerID Prop


//#region ownershipPerc Prop
        @prop()
        ownershipPerc : number;
//#endregion ownershipPerc Prop


//#region createdOn Prop
        @prop()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        // @range({minimumNumber:1,maximumNumber:2147483647})
        // @required()
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region corporateBy Prop
        @prop()
        corporateBy : number;
//#endregion corporateBy Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}