import {vLanguageLookUpBase,} from '@app/database-models'
//Generated Imports
export class vLanguageLookUp extends vLanguageLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}