import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemstoneOriginBase {

//#region originId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'GemstoneOriginListComponent_Id_gh', keyColumn: true})
        originId : number;
//#endregion originId Prop


//#region originName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'GemstoneOriginListComponent_Name_gh', keyColumn: false})
        originName : string;
//#endregion originName Prop

}