import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxFocusModule } from "@rxweb/angular/focus"
import { RxSpinnerModule } from "@rxweb/angular/spinner"
import { RxDatePickerModule } from "@rxweb/angular/datepicker"
import { RxMaskModule } from "@rxweb/angular/mask"
import { RxSelectModule } from "@rxweb/angular/select"
import { RxTagModule } from "@rxweb/angular/tag"
import { AppCheckboxComponent } from '../../directive/checkbox/app-checkbox/app-checkbox.component';
import { RxRoutingModule } from "@rxweb/angular-router"
import { RxLocalizationModule } from "@rxweb/angular-localization";
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { GridModule } from '@rxweb/grid';
import { CommonModule } from '@angular/common';
import { RxMultilingualExtended } from '../../directive/rx-multilingual-extended';
import { TabPermission } from 'src/app/directive/tab-permission';
import { RxTagExtendedDirective } from 'src/app/directive/rx-tag-extended-directive';
import { RxSelectExtendedDirective } from 'src/app/directive/rx-select-extended-directive';
import { RxLocalisationInitDirective } from 'src/app/directive/rx-localisation-init';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, RxRoutingModule, RxLocalizationModule, CommonModule],
    declarations: [AppCheckboxComponent, UnderMaintenanceComponent, RxMultilingualExtended, RxTagExtendedDirective, RxSelectExtendedDirective, TabPermission, RxLocalisationInitDirective],
    exports: [RxRoutingModule, RxLocalizationModule, AppCheckboxComponent, RxLocalizationModule, RxFocusModule, RxSpinnerModule, RxDatePickerModule, RxMaskModule, RxSelectModule, RxTagModule, GridModule, RxMultilingualExtended, RxTagExtendedDirective, RxSelectExtendedDirective, TabPermission, RxLocalisationInitDirective]
})
export class SharedModule { }
