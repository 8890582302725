import {ProductPieceOrderBase,ProductOrderBase,ProductPieceBase,} from '@app/database-models'
//Generated Imports
export class ProductPieceOrder extends ProductPieceOrderBase 
{




//#region Generated Reference Properties
//#region productOrder Prop
productOrder : ProductOrderBase;
//#endregion productOrder Prop
//#region productPiece Prop
productPiece : ProductPieceBase;
//#endregion productPiece Prop

//#endregion Generated Reference Properties











}