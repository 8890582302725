import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent, } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { TINY_MCE_KEY } from '../../../../domain/system-constant';
import { FormsDocumentBase } from 'src/app/models/database-models/forms-document-base';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FormsDocument } from 'src/app/models/extended-models/forms-document';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@http({
  path: "api/FormsDocument",
})
export class AbstractFormsDocument extends CoreComponent {
  // apiKey: any = TINY_MCE_KEY;
  formsDocumentFormGroup: IFormGroup<FormsDocument>
  showComponent: boolean = false;
  fileViewModelDoc: FileInputViewModel;
  // currentActiveTab: string = "help-supports";
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  fileViewModel: FileInputViewModel;
  fileOutputViewModelDoc: FileOutputViewModel = new FileOutputViewModel();
  spin: boolean = false;
  tabChange: boolean = false;
  selectedTab: string = "forms-document-edit";
  id: number = 0;

  constructor(private blobState: BlobUploadsViewStateService) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.Image;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
    this.fileViewModelDoc = new FileInputViewModel();
    this.fileViewModelDoc.fileMode = FileTypeEnums.Doc;
    this.fileViewModelDoc.fileAllowSize = FileSizeEnums.FourMB;
  }

 async updateDocFiles($event: FileOutputViewModel) {
    
    this.fileOutputViewModelDoc = $event;
    this.formsDocumentFormGroup.patchValue({
      fileData1: $event.fileBaseString,
      fileExtention1: $event.fileExtension,
      fileName1: $event.fileName,
      //blobFileName: uploadedFileName,
      blobPath: BLOB_BASE.SystemFormsDocument,
      size: $event.files.size
    });
  }
  async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);

    let url = res._response.request.url;
    let uploadedFileName = blobPath + decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );

    // IF SUCCEED TO UPLOAD
    if (res && (res._response.status == 200 || res._response.status == 201)) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined) {
        this.modalView
          .show(BedRequestComponent, {
            validationMessages: JSON.stringify(
              uploadedFileName
            ),
            headerMessage: "Image failed to upload:",
          })
          .then(() => { });
      }
    }
    return uploadedFileName;
  }
//   activeTab(tabName: string) {
//     this.tabChange = true;
//     this.selectedTab = tabName;
//     // if (this.selectedTab == 'gantt-chart') {
//     //     this.bindGanttChar();
//     // }
//     this.router.navigate(["forms-document", this.id], { queryParams: { activeTab: tabName, tabChange: this.tabChange } });
// }

  removeDoc(){  
    this.formsDocumentFormGroup.patchValue({
      fileData1: null, blobFileName: null,
      fileExtention1: null,
      fileName1: null,
      filePath: null,
      // budgetCostCertificate: null
    });
}

  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }



}
