import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TitleLocalizationBase {

//#region titleLocalizedId Prop
        @prop()
        titleLocalizedId : number;
//#endregion titleLocalizedId Prop


//#region titleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        titleId : number;
//#endregion titleId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region title Prop
        @required()
        @maxLength({value:100})
        title : string;
//#endregion title Prop

}