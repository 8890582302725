import { Component, OnInit, Input } from '@angular/core';
import { AbstractImportUserFromExcel } from '../domain/abstract-import-user-from-excel';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

import { List } from '@rxweb/generics';
import { ImportUserFromExcelViewModel } from 'src/app/view-model/import-user-from-excel-view-model';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BulkUploadUser } from 'src/app/models/extended-models/bulk-upload-user';
import { ErrorImportingUserComponent } from './error-importing-user/error-importing-user.component';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from '@angular/common';
import { MultiLingualData } from '@rxweb/localization';

@Component({
  selector: 'app-import-user-from-excel',
  templateUrl: './import-user-from-excel.component.html',
  styleUrls: ['./import-user-from-excel.component.css']
})
export class ImportUserFromExcelComponent extends AbstractImportUserFromExcel implements OnInit {

  id: string;
  @Input() hide: Function;
  exportExcel = new ExportToExcelModel();
  TodayDate = new Date();
  MMDDYY = moment(this.TodayDate).format('MMDDYY').toString();
  ImportUserFromExcel: ImportUserFromExcelViewModel;
  titleClose: string;
  fileToUpload: File = null;
  userDetailsImportobj: BulkUploadUser[];
  errorUserList: BulkUploadUser[];
  userLookUp: any;
  invalidExcelTypeMsg:string;
  invalidExcelSheetStrMsg:string;

  constructor(private formBuilder: RxFormBuilder, modelview: ModalView,private location : PlatformLocation) {
    super();
    this.modalView = modelview;
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.invalidExcelTypeMsg = getLocalizedValue("InvalidExcelFile");
    this.invalidExcelSheetStrMsg = getLocalizedValue("InvalidExcelSheetStructure")
    this.id = "file" + Math.random().toString();
    this.ImportUserFromExcel = new ImportUserFromExcelViewModel();
    this.ImportUserFromExcelFormGroup = this.formBuilder.formGroup(this.ImportUserFromExcel) as IFormGroup<ImportUserFromExcelViewModel>;
    this.showComponent = true;
  }

  removeDoc() {
    this.fileToUpload = null;
    this.ImportUserFromExcelFormGroup.patchValue({
      fileData: null,
      fileExtention: null,
      fileName: null,
      filePath: null,
      excelFile: null
    });
  }

  ExportToExcel() {

    this.spin = true;
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    this.exportExcel.FileName = "Users Mass Upload Template" + MMDDYY + ".xlsx";
    this.exportExcel.Type = "ExcelWithMultipleSheets";
    // var json = JSON.parse(JSON.stringify(this.userSearchFilterViewModelFormGroup.value));
    // json["pageIndex"] = PaginationEnum.PageIndex;
    // json["rowCount"] = this.totalRecords
    let returnedRequestData = this.requestExcelData();
    //let returnMasterData = this.requestMasterExcelData();
    //   worksheet.columns = [
    //     { key: 'FirstName', width: 10 },
    //     { key: 'LastName', width: 15 },
    //     { key: 'PrimaryE-mailAddress', width: 15 },
    //     { key: 'SecondaryE-mailAddress', width: 15 },
    //     { key: 'MobileTelephone', width: 15 },
    //     { key: 'HomeTelephone', width: 20 },
    //     { key: 'WorkTelephone', width: 20 },
    //     { key: 'StreetAddress', width: 25 },
    //     { key: 'StreetAddress2', width: 20 },
    //     { key: 'City', width: 30 },
    //     { key: 'Country', width: 20 },
    //     { key: 'Zip/postalcode', width: 30 },
    //     { key: 'Sensitivecontact-PermissionRequiredtoContact', width: 30 }
    // ];
    // worksheet.properties.defaultRowHeight = 25;

    let finalResult = [];

    //   returnedRequestData.forEach(x => {
    //     worksheet.addRow(x);
    // });
    finalResult.push(returnedRequestData);
    //finalResult.push(returnMasterData);
    console.log(finalResult);
    //let returnedData = Object.assign({}, returnedRequestData, returnMasterData)

    //this.exportExcel.Result= JSON.stringify(returnedRequestData).concat(JSON.stringify(returnMasterData));
    this.exportExcel.Result = JSON.stringify(finalResult);
    let lookupsData: any[] = [];
    //this.exportExcel.Result = JSON.stringify(myExcelData);
    this.exportAsExcelFile(finalResult, '');
    // return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
    //    //this.downloadDocument(Response, this.exportExcel.FileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    //   this.spin = false;
    // }, error => {
    //   this.spin = false;
    // })
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    this.lookup([{ path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" }, { path: USER_LOOKUPS.roleLookUpExcel, propName: "roleLookUp" }]).subscribe((response: any) => {
      this.userLookUp = response;
      let adminRole = this.userLookUp.roleLookUp.filter(x => x.roleType == 9);
      let customerRole = this.userLookUp.roleLookUp.filter(x => x.roleType == 7);
      this.spin = false;
      let userData = [{
        "FirstName": "",
        "MiddleName":"",
        "LastName": "",
        "UserRoleType": "",
        "RoleId": "",
        "PrimaryEmailAddress": "",
        "SecondaryEmailAddress": "",
        "MobileTelephone": "",
        "HomeTelephone": "",
        "WorkTelephone": "",
        "StreetAddress": "",
        "StreetAddress2": "",
        "City": "",
        "Country": "",
        "ZipPostalcode": "",
        "SensitivecontactPermissionRequiredtoContact": "",
      }];

      let requiredData = [{
       "":""
      }]


      let countries = JSON.stringify(this.userLookUp.countryLookUp);
      let adminRoles = JSON.stringify(adminRole);
      let customerRoles = JSON.stringify(customerRole);
      const worksheetUserData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(userData);
      const worksheetAdminRoleData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(adminRoles));
      const worksheetCustomerRoleData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(customerRoles));
      const worksheetCountry: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(countries));
      const worksheetRequiredData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(requiredData);


      var wscolsUsers = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 40 },
      ];

      var wscols = [
        { wch: 20 },
        { wch: 13 },
        { wch: 130 }
      ]
      var wscolsRole = [
        { wch: 20 },
        { wch: 40 },
        { wch: 20 },
        { wch: 20 }
      ]

      worksheetUserData['!cols'] = wscolsUsers;
      worksheetCountry['!cols'] = wscolsRole;
      worksheetAdminRoleData['!cols'] = wscolsRole;
      worksheetCustomerRoleData['!cols'] = wscolsRole;
      worksheetRequiredData['!cols'] = wscols;
     // worksheetRequiredData['A2'].v = 'First Name';
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Files Supported :']], { origin: 'A2' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['.xls']], { origin: 'B2' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['.xlsx']], { origin: 'B3' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['.csv']], { origin: 'B4' });

     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Required Fields : ']], { origin: 'A6' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['First Name']], { origin: 'B6' });
    //  XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Middle Name']], { origin: 'B7' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Last Name']], { origin: 'B7' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['User Role Type']], { origin: 'B8' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Role ID']], { origin: 'B9' });

     var userFirstName = getLocalizedValue("UserFirstName");
     if (userFirstName) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userFirstName]], { origin: 'C6' });
     }

    //  var userMiddleName = getLocalizedValue("UserMiddleName");
    //  if (userMiddleName) {
    //     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userMiddleName]], { origin: 'C7' });
    //  }
     var userLastName = getLocalizedValue("UserLastName");
     if (userLastName) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userLastName]], { origin: 'C7' });
     }
     var userRoleType = getLocalizedValue("UserRoleType");
     if (userRoleType) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userRoleType]], { origin: 'C8' });
     }
     var roleId = getLocalizedValue("RoleId");
     if (roleId) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[roleId]], { origin: 'C9' });
     }

     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Additional Information : ']], { origin: 'A11' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['1)']], { origin: 'B11' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['2)']], { origin: 'B12' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['3)']], { origin: 'B13' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['4)']], { origin: 'B14' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['4a)']], { origin: 'B15' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['4b)']], { origin: 'B16' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['5)']], { origin: 'B17' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['6)']], { origin: 'B18' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['7)']], { origin: 'B19' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['8)']], { origin: 'B20' });


     var userReadMe_Info_1 =getLocalizedValue("UserReadMe_Info_1");
     if (userReadMe_Info_1) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_1]], { origin: 'C11' });
     }
 
     var userReadMe_Info_2 =getLocalizedValue("UserReadMe_Info_2");
     if (userReadMe_Info_2) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_2]], { origin: 'C12' });
     }
 
     var userReadMe_Info_3 =getLocalizedValue("UserReadMe_Info_3");
     if (userReadMe_Info_3) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_3]], { origin: 'C13' });
     }
 
     var userReadMe_Info_4 =getLocalizedValue("UserReadMe_Info_4");
     if (userReadMe_Info_4) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_4]], { origin: 'C14' });
     }
 
     var userReadMe_Info_4a =getLocalizedValue("UserReadMe_Info_4a");
     if (userReadMe_Info_4a) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_4a]], { origin: 'C15' });
     }
 
     var userReadMe_Info_4b =getLocalizedValue("UserReadMe_Info_4b");
     if (userReadMe_Info_4b) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_4b]], { origin: 'C16' });
     }
     
     var userReadMe_Info_5 =getLocalizedValue("UserReadMe_Info_5");
     if (userReadMe_Info_5) {       
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_5]], { origin: 'C17' });
     } 
     var userReadMe_Info_6 = getLocalizedValue("UserReadMe_Info_6");
     if (userReadMe_Info_6) {       
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_6]], { origin: 'C18' });
     } 
     var userReadMe_Info_7 = getLocalizedValue("UserReadMe_Info_7");
     if (userReadMe_Info_7) {       
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_7]], { origin: 'C19' });
     }
     var userReadMe_Info_8 =getLocalizedValue("UserReadMe_Info_8");
     if (userReadMe_Info_8) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_8]], { origin: 'C20' });
     }
      const workbook: XLSX.WorkBook = { Sheets: { 'ReadMe': worksheetRequiredData, 'UserInfo': worksheetUserData, 'AdminRoles': worksheetAdminRoleData, 'CustomerRoles': worksheetCustomerRoleData, 'Countries': worksheetCountry }, SheetNames: ['ReadMe', 'UserInfo', 'AdminRoles', 'CustomerRoles', 'Countries'] };

      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    })
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + 'User Mass Upload Template' + this.MMDDYY + EXCEL_EXTENSION);
  }
  SaveExcel() {

    this.parseExcel(this.fileToUpload);
  }

  parseExcel(file) {


    let reader = new FileReader();
    reader.onload = (e) => {

      let data = (<any>e.target).result;
      //let data = file;

      this.userDetailsImportobj = [];
      let user = new BulkUploadUser();

      let workbook = XLSX.read(data, {
        type: 'binary'
      });

      let valid = true;

      workbook.SheetNames = ['UserInfo'];

      if(this.fileToUpload.type == "application/vnd.ms-excel"){
        if (workbook.Sheets.UserInfo != undefined) {

          if (
            (workbook.Sheets.UserInfo.A1 != undefined ? (workbook.Sheets.UserInfo.A1.v != "FirstName") : true) ||
            (workbook.Sheets.UserInfo.C1 != undefined ? (workbook.Sheets.UserInfo.B1.v != "MiddleName") : true) ||
            (workbook.Sheets.UserInfo.B1 != undefined ? (workbook.Sheets.UserInfo.C1.v != "LastName") : true) ||
            (workbook.Sheets.UserInfo.D1 != undefined ? (workbook.Sheets.UserInfo.D1.v != "UserRoleType") : true) ||
            (workbook.Sheets.UserInfo.E1 != undefined ? (workbook.Sheets.UserInfo.E1.v != "RoleId") : true) ||
            (workbook.Sheets.UserInfo.F1 != undefined ? (workbook.Sheets.UserInfo.F1.v != "PrimaryEmailAddress") : true) ||
            (workbook.Sheets.UserInfo.G1 != undefined ? (workbook.Sheets.UserInfo.G1.v != "SecondaryEmailAddress") : true) ||
            (workbook.Sheets.UserInfo.H1 != undefined ? (workbook.Sheets.UserInfo.H1.v != "MobileTelephone") : true) ||
            (workbook.Sheets.UserInfo.I1 != undefined ? (workbook.Sheets.UserInfo.I1.v != "HomeTelephone") : true) ||
            (workbook.Sheets.UserInfo.J1 != undefined ? (workbook.Sheets.UserInfo.J1.v != "WorkTelephone") : true) ||
            (workbook.Sheets.UserInfo.K1 != undefined ? (workbook.Sheets.UserInfo.K1.v != "StreetAddress") : true) ||
            (workbook.Sheets.UserInfo.L1 != undefined ? (workbook.Sheets.UserInfo.L1.v != "StreetAddress2") : true) ||
            (workbook.Sheets.UserInfo.M1 != undefined ? (workbook.Sheets.UserInfo.M1.v != "City") : true) ||
            (workbook.Sheets.UserInfo.N1 != undefined ? (workbook.Sheets.UserInfo.N1.v != "Country") : true) ||
            (workbook.Sheets.UserInfo.O1 != undefined ? (workbook.Sheets.UserInfo.O1.v != "ZipPostalcode") : true) ||
            (workbook.Sheets.UserInfo.P1 != undefined ? (workbook.Sheets.UserInfo.P1.v != "SensitivecontactPermissionRequiredtoContact") : true)
          ) {
            this.toastr.warning(this.invalidExcelTypeMsg);
            valid = false;
            return;
          }
        }
        else {
          // this.toastr.warning(this.invalidExcelTypeMsg);
          this.toastr.warning(this.invalidExcelSheetStrMsg);
          valid = false;
          return;
        }
      }
      else if(this.fileToUpload.type == "text/csv") {

        // delete Object.assign(workbook.Sheets, {[Sheet1]: workbook.Sheets[UserInfo] })[Sheet1];
        workbook.Sheets.UserInfo = workbook.Sheets.Sheet1;
        if (
          (workbook.Sheets.UserInfo.A1 != undefined ? (workbook.Sheets.UserInfo.A1.v != "FirstName") : true) ||
          (workbook.Sheets.UserInfo.C1 != undefined ? (workbook.Sheets.UserInfo.B1.v != "MiddleName") : true) ||
          (workbook.Sheets.UserInfo.B1 != undefined ? (workbook.Sheets.UserInfo.C1.v != "LastName") : true) ||
          (workbook.Sheets.UserInfo.D1 != undefined ? (workbook.Sheets.UserInfo.D1.v != "UserRoleType") : true) ||
          (workbook.Sheets.UserInfo.E1 != undefined ? (workbook.Sheets.UserInfo.E1.v != "RoleId") : true) ||
          (workbook.Sheets.UserInfo.F1 != undefined ? (workbook.Sheets.UserInfo.F1.v != "PrimaryEmailAddress") : true) ||
          (workbook.Sheets.UserInfo.G1 != undefined ? (workbook.Sheets.UserInfo.G1.v != "SecondaryEmailAddress") : true) ||
          (workbook.Sheets.UserInfo.H1 != undefined ? (workbook.Sheets.UserInfo.H1.v != "MobileTelephone") : true) ||
          (workbook.Sheets.UserInfo.I1 != undefined ? (workbook.Sheets.UserInfo.I1.v != "HomeTelephone") : true) ||
          (workbook.Sheets.UserInfo.J1 != undefined ? (workbook.Sheets.UserInfo.J1.v != "WorkTelephone") : true) ||
          (workbook.Sheets.UserInfo.K1 != undefined ? (workbook.Sheets.UserInfo.K1.v != "StreetAddress") : true) ||
          (workbook.Sheets.UserInfo.L1 != undefined ? (workbook.Sheets.UserInfo.L1.v != "StreetAddress2") : true) ||
          (workbook.Sheets.UserInfo.M1 != undefined ? (workbook.Sheets.UserInfo.M1.v != "City") : true) ||
          (workbook.Sheets.UserInfo.N1 != undefined ? (workbook.Sheets.UserInfo.N1.v != "Country") : true) ||
          (workbook.Sheets.UserInfo.O1 != undefined ? (workbook.Sheets.UserInfo.O1.v != "ZipPostalcode") : true) ||
          (workbook.Sheets.UserInfo.P1 != undefined ? (workbook.Sheets.UserInfo.P1.v != "SensitivecontactPermissionRequiredtoContact") : true)
          ) {
            this.toastr.warning(this.invalidExcelTypeMsg);
            valid = false;
            return;
          }
        // }
        // else {
        //   // this.toastr.warning(this.invalidExcelTypeMsg);
        //   this.toastr.warning("Need a sheet with name userInfo");
        //   valid = false;
        //   return;
        // }
      }
      else {
        if (workbook.Sheets.UserInfo != undefined) {

          if (
            (workbook.Sheets.UserInfo.A1 != undefined ? (workbook.Sheets.UserInfo.A1.v != "FirstName") : true) ||
            (workbook.Sheets.UserInfo.C1 != undefined ? (workbook.Sheets.UserInfo.B1.v != "MiddleName") : true) ||
            (workbook.Sheets.UserInfo.B1 != undefined ? (workbook.Sheets.UserInfo.C1.v != "LastName") : true) ||
            (workbook.Sheets.UserInfo.D1 != undefined ? (workbook.Sheets.UserInfo.D1.v != "UserRoleType") : true) ||
            (workbook.Sheets.UserInfo.E1 != undefined ? (workbook.Sheets.UserInfo.E1.v != "RoleId") : true) ||
            (workbook.Sheets.UserInfo.F1 != undefined ? (workbook.Sheets.UserInfo.F1.v != "PrimaryEmailAddress") : true) ||
            (workbook.Sheets.UserInfo.G1 != undefined ? (workbook.Sheets.UserInfo.G1.v != "SecondaryEmailAddress") : true) ||
            (workbook.Sheets.UserInfo.H1 != undefined ? (workbook.Sheets.UserInfo.H1.v != "MobileTelephone") : true) ||
            (workbook.Sheets.UserInfo.I1 != undefined ? (workbook.Sheets.UserInfo.I1.v != "HomeTelephone") : true) ||
            (workbook.Sheets.UserInfo.J1 != undefined ? (workbook.Sheets.UserInfo.J1.v != "WorkTelephone") : true) ||
            (workbook.Sheets.UserInfo.K1 != undefined ? (workbook.Sheets.UserInfo.K1.v != "StreetAddress") : true) ||
            (workbook.Sheets.UserInfo.L1 != undefined ? (workbook.Sheets.UserInfo.L1.v != "StreetAddress2") : true) ||
            (workbook.Sheets.UserInfo.M1 != undefined ? (workbook.Sheets.UserInfo.M1.v != "City") : true) ||
            (workbook.Sheets.UserInfo.N1 != undefined ? (workbook.Sheets.UserInfo.N1.v != "Country") : true) ||
            (workbook.Sheets.UserInfo.O1 != undefined ? (workbook.Sheets.UserInfo.O1.v != "ZipPostalcode") : true) ||
            (workbook.Sheets.UserInfo.P1 != undefined ? (workbook.Sheets.UserInfo.P1.v != "SensitivecontactPermissionRequiredtoContact") : true)
          ) {
            this.toastr.warning(this.invalidExcelTypeMsg);
            valid = false;
            return;
          }
        }
        else {
          // this.toastr.warning(this.invalidExcelTypeMsg);
          this.toastr.warning(this.invalidExcelSheetStrMsg);
          valid = false;
          return;
        }
      }

      workbook.SheetNames.forEach((function (sheetName) {

        // Here is your object

        let XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets.UserInfo);

        XL_row_object.forEach((obj: BulkUploadUser | any) => {

          if ((obj.FirstName == undefined || obj.FirstName == '') ||
            (obj.LastName == undefined || obj.LastName == '') ||
            (obj.UserRoleType == undefined || obj.UserRoleType == '') ||
            (obj.RoleId == undefined || obj.RoleId == '')) {
            this.toastr.warning(this.invalidExcelTypeMsg);
            valid = false;
            return;
          }

          let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]');                                                        // REGEX for Email fields
          if (!this.isInt(obj.RoleId) || obj.RoleId >= 2147483647 || obj.RoleId < 0                                 // 2147483647 - MAX LIMIT FOR Int32
            || !this.isInt(obj.UserRoleType) || obj.UserRoleType >= 2147483647 || obj.UserRoleType < 0
            || (obj.Country != undefined && obj.Country != "" && (!this.isInt(obj.Country) || obj.Country >= 2147483647 || obj.Country < 0))
            || (obj.PrimaryEmailAddress != undefined && obj.PrimaryEmailAddress != "" && !regex.test(obj.PrimaryEmailAddress))
            || (obj.SecondaryEmailAddress != undefined && obj.SecondaryEmailAddress != "" && !regex.test(obj.SecondaryEmailAddress))
          ) {
            this.toastr.warning(this.invalidExcelTypeMsg);
            valid = false;
            return;
          }

          var isRoleValid = true
          var isSensitiveContactValid = true
          if (obj.UserRoleType != undefined && obj.UserRoleType != '') {
            if (!(Number(obj.UserRoleType) == 7 || Number(obj.UserRoleType) == 9))
              isRoleValid = false
          }
          if (obj.SensitivecontactPermissionRequiredtoContact != undefined && obj.SensitivecontactPermissionRequiredtoContact != "") {
            if (!(obj.SensitivecontactPermissionRequiredtoContact.toUpperCase() == "YES" || obj.SensitivecontactPermissionRequiredtoContact.toUpperCase() == "NO"))
              isSensitiveContactValid = false
          }

          if ((!isRoleValid) || (!isSensitiveContactValid)) {
            this.toastr.warning(this.invalidExcelTypeMsg);
            valid = false;
            return;
          }

          // if (
          //   (obj.FirstName != undefined && obj.FirstName ) ||
          //   (obj.LastName == undefined ) ||
          //   (obj.PrimaryEmailAddress == undefined) ||
          //   (obj.SecondaryEmailAddress == undefined ) ||
          //   (obj.MobileTelephone == undefined ) ||
          //   (obj.HomeTelephone == undefined ) ||
          //   (obj.WorkTelephone == undefined ) ||
          //   (obj.StreetAddress == undefined ) ||
          //   (obj.StreetAddress2 == undefined ) ||
          //   (obj.City == undefined && obj.City) ||
          //   (obj.Country == undefined ) ||
          //   (obj.ZipPostalcode == undefined)
          //   ) {
          //   this.toastr.warning(this.invalidExcelTypeMsg);
          //   valid = false;
          //   return;
          // }

          let userDetail = new BulkUploadUser();
          userDetail.FirstName = obj.FirstName;
          userDetail.MiddleName = obj.MiddleName == undefined ? "" : obj.MiddleName;
          userDetail.LastName = obj.LastName;
          userDetail.PrimaryEmailAddress = obj.PrimaryEmailAddress == undefined ? "" : obj.PrimaryEmailAddress;
          userDetail.SecondaryEmailAddress = obj.SecondaryEmailAddress == undefined ? "" : obj.SecondaryEmailAddress;
          userDetail.MobileNumber = obj.MobileTelephone == undefined ? "" : obj.MobileTelephone;
          userDetail.PhoneNumber = obj.HomeTelephone == undefined ? "" : obj.HomeTelephone;
          userDetail.WorkPhoneNumber = obj.WorkTelephone == undefined ? "" : obj.WorkTelephone;
          userDetail.StreetAddress = obj.StreetAddress == undefined ? "" : obj.StreetAddress;
          userDetail.StreetAddress2 = obj.StreetAddress2 == undefined ? "" : obj.StreetAddress2;
          userDetail.City = obj.City == undefined ? "" : obj.City;
          userDetail.CountryId = obj.Country == undefined ? 0 : Number(obj.Country);
          userDetail.ZipPostalCode = obj.ZipPostalcode == undefined ? "" : obj.ZipPostalcode;
          userDetail.UserRoleType = obj.UserRoleType;
          userDetail.RoleId = obj.RoleId == undefined ? 0 : Number(obj.RoleId);
          if (obj.SensitivecontactPermissionRequiredtoContact != undefined) {
            if (obj.SensitivecontactPermissionRequiredtoContact != '') {
              if (obj.SensitivecontactPermissionRequiredtoContact.toUpperCase() == "YES")
                userDetail.ContactPermissionRequired = true
              else
                userDetail.ContactPermissionRequired = false
            }
            else
              userDetail.ContactPermissionRequired = false
          }
          else
            userDetail.ContactPermissionRequired = false

          this.userDetailsImportobj.push(userDetail);
        });

        if (this.userDetailsImportobj.length > 0 && valid == true) {

          this.spin = true;
          let userDetail = new BulkUploadUser();
          userDetail.bulkUploadUsers = this.userDetailsImportobj;
          this.subscription = this.post({ body: userDetail, path: "api/SearchUsers/BulkUserImportExcel" }).subscribe(data => {
            this.hide();
            this.spin = false;
            //console.log("retuenResult",data);
            this.userDetailsImportobj = [];
            if (JSON.parse(data).BulkUploadUsers == "") {
              this.toastr.success("File Imported Successfully!!");
            }
            else {
              this.modalView.show(ErrorImportingUserComponent, { errorUserList: JSON.parse(data).BulkUploadUsers }).then(t => {

              })
            }
            // if(data!= undefined && data!= ""){
            //   if(JSON.parse(data) != null && JSON.parse(data) !=""){
            //     this.toastr.warning( "User with E-mail "+ JSON.parse(data) +" Already Exists, Please re-upload the correct Sheet");
            //   }
            //   else{
            //     this.toastr.success("File Imported Successfully");
            //   }
            // }
            // else{
            //   this.toastr.success("File Imported Successfully");
            // }
          });
        }
      }).bind(this), this);
    };
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  requestExcelData() {
    let userListData = new List<UserListData>();
    let excelData = [];
    // usersGrid.forEach(element => {
    let listData = new UserListData();

    listData["FirstName"] = "";
    listData["MiddleName"]="";
    listData["LastName"] = "";
    listData["UserRole"] = "";
    listData["PrimaryEmailAddress"] = "";
    listData["SecondaryEmailAddress"] = "";
    listData["MobileTelephone"] = "";
    listData["HomeTelephone"] = "";
    listData["WorkTelephone"] = "";
    listData["StreetAddress"] = "";
    listData["StreetAddress2"] = "";
    listData["City"] = "";
    listData["Country"] = "";
    listData["ZipPostalcode"] = "";
    listData["SensitivecontactPermissionRequiredtoContact"] = "";

    userListData.add(listData);

    return userListData;
  }

  fileUpload(args: any) {
    let files = args.target.files; // FileList object
    this.fileToUpload = files[0];
    if (this.fileToUpload != null)
      this.ImportUserFromExcelFormGroup.value.fileName = this.fileToUpload.name;
  }

  isInt(n) {
    return n % 1 === 0;
  }

}
class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
}
class UserListData {
  "FirstName": string;
  "MiddleName": string;
  "LastName": string;
  "UserRole": string;
  "PrimaryEmailAddress": string;
  "SecondaryEmailAddress": string;
  "MobileTelephone": string;
  "HomeTelephone": string;
  "WorkTelephone": string;
  "StreetAddress": string;
  "StreetAddress2": string;
  "City": string;
  "Country": string;
  "ZipPostalcode": string;
  "SensitivecontactPermissionRequiredtoContact": string;
}
