import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vChainColourBase {

  //#region chainColourId Prop
  @gridColumn({ style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, isAscending: false, columnIndex: 0, allowSorting: true, headerKey: 'ChainColourListComponent_Id_gh', keyColumn: true })
  chainColourId: number;
  //#endregion chainColourId Prop


  //#region colourName Prop
  @gridColumn({ isAscending:true, name: "colourName", style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, keyColumn: false, columnIndex: 1, allowSorting: true, headerKey: 'ChainColourListComponent_Name_gh', })
  colourName: string;
  //#endregion colourName Prop

}
