import { TemplateConfig } from '@rxweb/grid';
import { isexistsGrivance } from 'src/app/components/reports/hse-report/list/hse-report-list.component';

export const HSE_RPORT_DATA_SEPERATE_TEMPLATE: TemplateConfig = {
    div: {
        attributes: {
            innerHTML: function (e) {
                var Company = "";
                if (this.Company == "Accident / Injury / Fatality") {
                    Company = "<b>A.Sub-Total : Accident / Injury / Fatality</b>";
                }
                else if (this.Company == "Incident") {
                    Company = "<b>B.Sub-Total : Incident</b>";
                }
                else if (this.Company == "Near-miss / Unsafe act") {
                    Company = "<b>C.Sub-Total : Near-miss / Unsafe act</b>";
                }
                else if (this.Company == "Grievance") {
                    Company = "<b>D.Sub-Total : Grievance</b>";
                }
                else if (this.Company == "Total") {
                    Company = "<b>Total: Occurrence Type</b>";
                }
                else if (this.Company == "Total" && this.TypeId == null) {
                    if (isexistsGrivance) {
                        Company = "<b>GRAND TOTAL A+B+C+D</b>";
                    }
                    else {
                        Company = "<b>GRAND TOTAL A+B+C</b>";
                    }
                }
                else {
                    Company = this.Company;
                }
                return Company;
            }
        },
        class: [
            function (e) {
                if (this.Company == "Accident / Injury / Fatality") {
                    return "highlight";
                }
                else if (this.Company == "Incident") {
                    return "highlight";
                }
                else if (this.Company == "Near-miss / Unsafe act") {
                    return "highlight";
                }
                else if (this.Company == "Grievance") {
                    return "highlight";
                }
                else if (this.Company == "Total" && this.TypeId == null) {
                    return "highlightFinal";
                }
                else if (e.name == "Total" || this.Company == "Total") {
                    return "highlightFinal";
                }
                else {
                    return "";
                }
            }
        ]
    }
}
export const HSE_REPORT_DATA_SEPERATE_TEMPLATE_GRIEVANCE: TemplateConfig = {
    div: {
        attributes: {
            innerHTML: function (e) {
                var Company = "";
                if (this.Company == "Accident / Injury / Fatality") {
                    Company = "<b>A.Sub-Total : Accident / Injury / Fatality</b>";
                }
                else if (this.Company == "Incident") {
                    Company = "<b>B.Sub-Total : Incident</b>";
                }
                else if (this.Company == "Near-miss / Unsafe act") {
                    Company = "<b>C.Sub-Total : Near-miss / Unsafe act</b>";
                }
                else if (this.Company == "Grievance") {
                    Company = "<b>D.Sub-Total : Grievance</b>";
                }
                else if (this.Company == "Total") {
                    Company = "<b>Total: Grievance</b>";
                }
                else if (this.Company == "Total" && this.TypeId == null) {
                    if (isexistsGrivance) {
                        Company = "<b>GRAND TOTAL A+B+C+D</b>";
                    }
                    else {
                        Company = "<b>GRAND TOTAL A+B+C</b>";
                    }
                }
                else {
                    Company = this.Company;
                }
                return Company;
            }
        },
        class: [
            function (e) {
                if (this.Company == "Accident / Injury / Fatality") {
                    return "highlight";
                }
                else if (this.Company == "Incident") {
                    return "highlight";
                }
                else if (this.Company == "Near-miss / Unsafe act") {
                    return "highlight";
                }
                else if (this.Company == "Grievance") {
                    return "highlight";
                }
                else if (this.Company == "Total" && this.TypeId == null) {
                    return "highlightFinal";
                }
                else if (e.name == "Total" || this.Company == "Total") {
                    return "highlightFinal";
                }
                else {
                    return "";
                }
            }
        ]
    }
}
