import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const SAVEBUTTON_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [{
            a: {
                event: {
                    click: "onEditData"
                },
                childrens: [{

                    // i: { class: "fa fa-check fa-4x font-md text-default".split(" ") }
                    i: {
                        class: "fa fr-save font-md text-default".split(" "),
                        attributes: { "title": getLocalizedValue("Title_Save") }
                    }

                }],
                class: ["mr-2"]
            }
        }]
    }
}
    ;
