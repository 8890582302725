import {vGemStoneColorRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneColorRecord extends vGemStoneColorRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}