import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductionOrderCurrencyLookUpBase {

//#region currencyId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'currencyId', keyColumn: true})
        currencyId : number;
//#endregion currencyId Prop


//#region currencyName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'currencyName', keyColumn: false})
        currencyName : string;
//#endregion currencyName Prop

}