import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Supplier, SupplierRole, vSupplierRoleLookUp, vIndustryTypeLookUp, vCountryLookUp, vSalesAdvisorLookUp, vkeyRelationshipHolderLookUp, vStateProvinceLookUp } from '@app/models';
import { AbstractSupplier } from '../domain/abstract-supplier';
import { Router, ActivatedRoute } from "@angular/router";
import { ModalView } from '../../../../domain/customize-design/modal';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { SupplierDetailBase } from '@app/database-models';
import { List } from '@rxweb/generics';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CustomerRegistrationTypeEnum } from 'src/app/enums/customer-registration-type.enum';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { ApplicationBroadcaster } from "src/app/temp-service/application-broadcaster";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { CorporateCreditRatingViewComponent } from 'src/app/components/master/corporate-credit-ratings/view/corporate-credit-rating-view.component';
import { showToolTip } from "src/app/domain/customize-plugin/bind-slider";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@access({ accessLevel: RolePermissionEnums.Supplier, action: "post" })
@multilingual("SupplierAddComponent")
@Component({
  selector: "app-supplier-add",
  templateUrl: './supplier-add.component.html'
})
export class SupplierAddComponent extends AbstractSupplier implements OnInit, OnDestroy, AfterViewChecked  {
  supplier: Supplier;
  supplierDetailBase: SupplierDetailBase;
  subscription: any;
  moduleID: number;
  titleHelp: string;
  imageTooltip: string;
  personaNonGrataMean: string;

  _this;

  constructor(private applicationBroadcaster: ApplicationBroadcaster, modalView: ModalView, private formBuilder: RxFormBuilder, router: Router, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private title: Title, private readonly changeDetectorRef: ChangeDetectorRef,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.router = router;
    this.modalView = modalView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'suppliers', subMenu: '' })
    this.activatedRoute.queryParams.subscribe(t => {
      this.userId = t['userId'];
      this.isRedirect = t['isRedirect'];
    })
    this.moduleID = RolePermissionEnums.Supplier;
    this._this = this;
  }
  showSystemHelpIcon: boolean = false;
  ngOnInit(): void {
    this.personaNonGrataMean =  getLocalizedValue("UserAddEditComponent_PersonaNonGrataMean_t");
    this.imageTooltip = getLocalizedValue("SupplierAddEditComponent_ImageUploadTooltip_t");
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Corporates/Groups - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    setTimeout(() => {
      showToolTip("supplier_IsUnwelcome_helpToolTip", getLocalizedValue("UserAddEditComponent_PersonaNonGrataMean_t"));
    }, 2000);
    this.lookup([
      { path: USER_LOOKUPS.industryTypeLookUp, propName: "industryTypeLookUp" },
      { path: USER_LOOKUPS.supplierRoleLookUp, propName: "supplierRoleLookUp" },
      { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.stateProvinceLookUp, propName: "stateProvinceLookUp" },
      { path: USER_LOOKUPS.salesUserLookup, propName: "salesAdvisorLookUp" },
      { path: USER_LOOKUPS.customerRegistrationTypeLookUp, propName: "customerRegistrationTypeLookUp" },
      { path: USER_LOOKUPS.keyRelationshipHolderLookUp, propName: "keyRelationshipHolderLookUp" },
      { path: USER_LOOKUPS.corporateCreditRatings, propName: "corporateCreditRatings" },
    ]).subscribe(response => {
      this.supplierLookups = response;

      // Lookup default option add as per rx-select
      // let newIndustryTypeObj = new vIndustryTypeLookUp();
      // newIndustryTypeObj.industryTypeName = "Select Industry Type";
      // newIndustryTypeObj.industryTypeId = 0;
      // this.supplierLookups.industryTypeLookUp.unshift(newIndustryTypeObj);

      // let newCountryObj = new vCountryLookUp();
      // newCountryObj.countryName = "Select Country";
      // newCountryObj.countryId = 0;
      // this.supplierLookups.countryLookUp.unshift(newCountryObj);

      // let newSalesAdvisorObj = new vSalesAdvisorLookUp();
      // newSalesAdvisorObj.username = "Select Sales Advisor";
      // newSalesAdvisorObj.userId = 0;
      // this.supplierLookups.salesAdvisorLookUp.unshift(newSalesAdvisorObj);

      // let newKeyRelaObj = new vkeyRelationshipHolderLookUp();
      // newKeyRelaObj.username = "Select Key Relationship Holder";
      // newKeyRelaObj.userId = 0;
      // this.supplierLookups.keyRelationshipHolderLookUp.unshift(newKeyRelaObj);

      this.stateProvinceLookUp = [];

      // Lookup default option add as per rx-select


      this.supplierRoles = new List(this.supplierLookups.supplierRoleLookUp);
      this.supplier = new Supplier();
      this.supplierDetailBase = new SupplierDetailBase();
      this.supplier.supplierId = 0;
      this.supplier.registrationTypeId = CustomerRegistrationTypeEnum.Standard;
      this.supplier.email = "";
      this.supplier.industryTypeId = 0;
      this.supplierDetailBase.supplierId = 0;
      this.supplierDetailBase.supplierDetailId = 0;
      this.supplierDetailBase.stateProvinceId = 0;
      this.supplier.statusId = StatusEnum.Active;
      this.supplier.supplierDetails = new Array<SupplierDetailBase>(this.supplierDetailBase);
      this.supplier.supplierRoles = this.bindSupplierRole();
      this.supplierFormGroup = this.formBuilder.formGroup(this.supplier) as IFormGroup<Supplier>;
      this.showComponent = true;
    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }
  corporateCreditRating() {
    this.modalView.show(CorporateCreditRatingViewComponent, {});
  }

  bindSupplierRole(): SupplierRole[] {
    let roleLookUps = this.supplierLookups.supplierRoleLookUp as Array<vSupplierRoleLookUp>;
    let supplierRoleArray = new Array<SupplierRole>()
    roleLookUps.forEach((role: vSupplierRoleLookUp) => {
      let supplierRole = new SupplierRole();
      supplierRole.supplierRoleId = 0;
      supplierRole.supplierId = 0;
      supplierRole.active = false;
      supplierRole.roleId = role.roleId;
      supplierRoleArray.push(supplierRole)
    })
    this.isRoles = true;


      const columns = 3; 
      const rows = Math.ceil(supplierRoleArray.length / columns);

      // Create a 2D array to store the rearranged elements
      const rearrangedArray = Array.from({ length: rows }, () => []);

      // Loop through the original array and rearrange the elements
      supplierRoleArray.forEach((element, index) => {   
        let row = index % rows;
        rearrangedArray[row].push(element); 
      });

      // make Array with Same Size 
      const rearrangedArraySize = [];
      const maxSize = Math.max(...rearrangedArray.map(arr => arr.length));

      for (const subArray of rearrangedArray) {
        const missingElements = maxSize - subArray.length;
        
        const newArray = [...subArray, ...Array(missingElements).fill(new SupplierRole())];
        rearrangedArraySize.push(newArray);
      }
      
      const result = [].concat(...rearrangedArraySize.map(test => test));
     
      return result;


    //return supplierRoleArray;

  }
  getImgContent(): SafeUrl {
    let imgUrl = "data:" + this.supplierFormGroup.controls.fileExtention.value + ";base64," + this.supplierFormGroup.controls.fileData.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
  }

  getTabIndex(a: number, b: number): number {
    return a + b;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "SupplierAddComponent";
  }
}
