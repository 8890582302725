import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vChainLengthLookUpBase {

//#region chainLengthId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'chainLengthId', keyColumn: true})
        chainLengthId : number;
//#endregion chainLengthId Prop


//#region chainLengthName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'chainLengthName', keyColumn: false})
        chainLengthName : string;
//#endregion chainLengthName Prop

}