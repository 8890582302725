//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentPressMediaSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  @gridColumn({ parameter: true, style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PressMediaId_gh', keyColumn: false })
  pressMediaId: string;

  @gridColumn({ parameter: true, style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PressMediaHastagName_gh', keyColumn: false })
  hashtagName: string;

  @gridColumn({ parameter: false, style: { width: "30%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PressMediaPublishedOn_gh', keyColumn: false })
  publishedOn: string

  totalCount: number;
}
