<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Roles_Permissions_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content " [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_Roles_Permissions_t"></label></h5>
      <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x" ></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [rxSpinner]="spin">


      <div class="row people_form_details" [formGroup]="rolePermissionSearchFormGroup" 
        *ngIf="showComponent" (keyup.enter)="getPermissions()" [rxLocalisationInit]="componentName">
        <div class="col-lg-6 col-xl-6 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="RolePermissionListComponent_PermissionDepartment_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select  formControlName="department" class="form-control" (change)="getUsers($event)">
                                 <option *ngFor="let item of departmentLookup" value="{{item.departmentId}}">{{item.departmentName}}</option>
                             </select> -->

              <rx-select [(source)]="roleLookup" #rxSelect formControlName="roleId" mainClass="form-control"
                (changed)="getUsers($event)" [selectPlaceholder]="'Select Role'"
                [keyValueProps]="['roleName','roleId']"></rx-select>
              <small class="form-text text-danger"
                [class.d-block]="rolePermissionSearchFormGroup.controls.roleId.errors">{{rolePermissionSearchFormGroup.controls.roleId.errorMessage}}<br /></small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="RolePermissionListComponent_PermissionUser_t"></label>
            <div class="col-md-8">
              <ng-container *ngIf="!dropdownDisabled && showUser" [rxSelectExtended]="_this">
              <rx-select  [(source)]="userLookup" #rxSelect [autoComplete]="false"
                (changed)="enableButton()" formControlName="userId" mainClass="form-control"
                [selectPlaceholder]="'Select User'" [keyValueProps]="['fullName','userId']"
                [selectDisabled]="dropdownDisabled"></rx-select>
              </ng-container>
              <div class="input-group">
                <label *ngIf="dropdownDisabled" type="text" 
                  class="form-control disableLabel-withoutheight disableLabel-rightborder mb-0">{{(fullName === null || fullName === undefined ||
                  fullName === '') ? 'Select User' : fullName}}</label>
                <span *ngIf="dropdownDisabled" class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-spinner fa-pulse d-none"></i>
                    <i class="fa fa-caret-down"></i>
                  </span>
                </span>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="rolePermissionSearchFormGroup.controls.userId.errors">{{rolePermissionSearchFormGroup.controls.userId.errorMessage}}<br /></small>
            </div>

          </div>
          <div class="row mt-auto">
            <div class="col text-right">
              <button (click)="getPermissions()" class="btn btn-success mr-1" id="searchBtn" rxText="Btn_Search_t"></button>
            </div>
          </div>
        </div>
      </div>


    </div>

  </div>

  <div class="ibox mb-0 d-flex flex-column mt-4" *ngIf="showDiv" [rxLocalisationInit]="componentName">
    <div class="ibox-title bg-white">
      <div class="row">
        <div class="col-md-1 col-xl-1">
          <h5><label rxText="RolePermissionListComponent_Application"></label></h5>
        </div>
        <div class="col-md-9 col-xl-9" *ngIf="isForDepartment" [rxLocalisationInit]="componentName"><label class="form-label" rxText="Label_Access_Permissions_Role_t"></label></div>
        <div class="col-md-9 col-xl-9" *ngIf="!isForDepartment" [rxLocalisationInit]="componentName"><label class="form-label" rxText="Label_Access_Permissions_Role_t"></label></div>
      </div>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="table-responsive" *ngFor="let parent of parentModules; let i = index" [rxLocalisationInit]="componentName">
        <table class="table table-striped table-hover dataTables-example role-table dataTable border-bottom">
          <thead>
            <tr role="row">
              <th class="sorting " style="min-width:50px; width:400px;">{{parent.ParentName}}</th>
              <th class="sorting" style="min-width:80px;"><label
                  rxText="RolePermissionListComponent_PermFullControl_t"></label> </th>
              <th class="sorting " style="min-width:50px;"><label
                  rxText="RolePermissionListComponent_PermissionAdd_t"></label></th>
              <th class="sorting " style="min-width:50px;"><label
                  rxText="RolePermissionListComponent_PermissionEdit_t"></label></th>
              <th class="sorting " style="min-width:50px;"><label
                  rxText="RolePermissionListComponent_PermissionView_t"></label></th>
              <th class="sorting " style="min-width:50px;"><label
                  rxText="RolePermissionListComponent_PermissionDelete_t"></label></th>
              <th class="sorting text-right" style="min-width:50px; "><i class="fa fa-chevron-down role-togle" [title]="titleToggle[i]"
                  (click)="collapseDiv(parent,i)"></i></th>
            </tr>
          </thead>
          <tbody style="display: table-row-group;" [id]="parent.ParentId">
            <tr *ngFor="let permission of rolePermissioinsData" [rxLocalisationInit]="componentName">
              <td *ngIf="permission.ParentId==parent.ParentId">{{permission.ModulesMastersName}}</td>
              <td *ngIf="permission.ParentId==parent.ParentId">
                <!-- <fieldset [disabled]="permission.FullControl"> -->
                <!-- <app-checkbox [checked]="permission.FullControl" (change)="checkboxSelect($event,permission,'fullControl')"></app-checkbox> -->
                <div class="i-checks">
                  <label class="mb-0">
                    <div class="icheckbox_square-green" [id]="permission.checkFCId"
                      [class.checked]="permission.FullControl" style="position: relative;">
                      <input [checked]="permission.FullControl" type="checkbox" style="position: absolute;opacity: 0;"
                        (change)="checkboxSelect($event,permission,'fullControl')">
                    </div><i></i>
                  </label>
                </div>
                <!-- </fieldset> -->
                <!-- <app-checkbox [checkFormControl]="userFormGroup.controls.isBanOrders"></app-checkbox> -->
              </td>

              <td *ngIf="permission.ParentId==parent.ParentId">
                <div class="i-checks" *ngIf="permission.HasAdd">
                  <label class="mb-0">
                    <div class="icheckbox_square-green" [id]="permission.checkAddId" [class.checked]="permission.CanAdd"
                      style="position: relative;">
                      <input [checked]="permission.CanAdd" type="checkbox" style="position: absolute;opacity: 0;"
                        (change)="checkboxSelect($event,permission,'add')">
                    </div><i></i>
                  </label>
                </div>
                <!-- <app-checkbox *ngIf="permission.HasAdd"  [checked]="permission.CanAdd" (change)="checkboxSelect($event,permission,'add')"></app-checkbox> -->
              </td>

              <td *ngIf="permission.ParentId==parent.ParentId">
                <div class="i-checks" *ngIf="permission.HasEdit">
                  <label class="mb-0">
                    <div class="icheckbox_square-green" [id]="permission.checkEditId"
                      [class.checked]="permission.CanEdit" style="position: relative;">
                      <input [checked]="permission.CanEdit" type="checkbox" style="position: absolute;opacity: 0;"
                        (change)="checkboxSelect($event,permission,'edit')">
                    </div><i></i>
                  </label>
                </div>
                <!-- <app-checkbox  *ngIf="permission.HasEdit" [checked]="permission.CanEdit" (change)="checkboxSelect($event,permission,'edit')"></app-checkbox> -->
              </td>
              <td *ngIf="permission.ParentId==parent.ParentId">
                <div class="i-checks" *ngIf="permission.HasView">
                  <label class="mb-0">
                    <div class="icheckbox_square-green" [id]="permission.checkViewId"
                      [class.checked]="permission.CanView" style="position: relative;">
                      <input [checked]="permission.CanView" type="checkbox" style="position: absolute;opacity: 0;"
                        (change)="checkboxSelect($event,permission,'view')">
                    </div><i></i>
                  </label>
                </div>
                <!-- <app-checkbox *ngIf="permission.HasView" [checked]="permission.CanView" (change)="checkboxSelect($event,permission,'view')"></app-checkbox> -->
              </td>
              <td *ngIf="permission.ParentId==parent.ParentId">
                <div class="i-checks" *ngIf="permission.HasDelete">
                  <label class="mb-0">
                    <div class="icheckbox_square-green" [id]="permission.checkDeleteId"
                      [class.checked]="permission.CanDelete" style="position: relative;">
                      <input [checked]="permission.CanDelete" type="checkbox" style="position: absolute;opacity: 0;"
                        (change)="checkboxSelect($event,permission,'delete')">
                    </div><i></i>
                  </label>
                </div>
                <!-- <app-checkbox *ngIf="permission.HasDelete"  [checked]="permission.CanDelete" (change)="checkboxSelect($event,permission,'delete')"></app-checkbox> -->
              </td>
              <td *ngIf="permission.ParentId==parent.ParentId"></td>
            </tr>
          </tbody>
        </table>
      </div>


      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100"></div>
        <div class="col text-right">
          <button class="btn btn-success mr-1" (click)="savePermissions()" [disabled]="isDataChanged" rxText="Btn_Save_t"></button>
        </div>
      </div>
    </div>
  </div>

</div>



<!-- Modal -->


<!-- <div ngIf="showDialog" class="sweet-alert sweet-alert-danger showSweetAlert visible" style="display: block; margin-top: -128px;"><h2>Delete Alert</h2><p>Are you sure, you want to give default permissions to the selected user ?</p><div><button>Ok</button><button>Cancel</button></div></div> -->