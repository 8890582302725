import {LookupInjurySeverityBase,IncidentPersonsAffectedBase,AccidentInjurySeverityBase,} from '@app/database-models'
//Generated Imports
export class LookupInjurySeverity extends LookupInjurySeverityBase 
{




//#region Generated Reference Properties
//#region incidentPersonsAffected Prop
incidentPersonsAffected : IncidentPersonsAffectedBase[];
//#endregion incidentPersonsAffected Prop
//#region accidentInjurySeverity Prop
accidentInjurySeverity : AccidentInjurySeverityBase[];
//#endregion accidentInjurySeverity Prop

//#endregion Generated Reference Properties






































}