import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerRelationshipBase {

//#region customerRelationShipId Prop
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'CustomerRelationshipListComponent_Id_gh', keyColumn: true})
        customerRelationShipId : number;
//#endregion customerRelationShipId Prop


//#region customerRelationShipStatus Prop
        //@gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'CustomerRelationshipListComponent_Status_gh', keyColumn: false})
        @gridColumn({isAscending:true,style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'CustomerRelationshipListComponent_Status_gh', keyColumn: false})
        customerRelationShipStatus : string;
//#endregion customerRelationShipStatus Prop

}