import { HomeLayoutDetailBase, HomeLayoutBase, } from '@app/database-models'
import { prop, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class HomeLayoutDetail extends HomeLayoutDetailBase {




    //#region Generated Reference Properties
    //#region homeLayout Prop
    homeLayout: HomeLayoutBase;
    //#endregion homeLayout Prop
    @prop()
    fileData: string;

    @prop()
    fileExtention: string;

    @prop()
    fileName: string;

    @prop()
    displayImage: boolean;

    @prop()
    imageURL1: string;

    @prop({ defaultValue: false })
    imgError: boolean;

    @prop()
    fileData1: string;

    @prop()
    fileExtention1: string;

    @prop()
    fileName1: string;

    @prop()
    displayImage1: boolean;

    @prop({ defaultValue: false })
    imgError1: boolean;

    @prop({ defaultValue: false })
    imgNameInvalid:boolean

    @prop({ defaultValue: false })
    imgNameInvalid1:boolean
    //#endregion Generated Reference Properties

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;




































}