import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ProductGemstoneCertificate, vLaboratoryLookUp } from '@app/models';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileSizeEnums, FileTypeEnums } from 'src/app/custom-enum/file-type-enums';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { WatchInvoice } from 'src/app/models/extended-models/watch-invoice';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

export class AbstractWatchInvoice extends RxHttp {
    watchInvoiceFormGroup: IFormGroup<WatchInvoice>;
    watchInvoice2FormGroup: IFormGroup<WatchInvoice>;
    watchInvoice3FormGroup: IFormGroup<WatchInvoice>;
    modalView: ModalView;
    toastr: BaseToastr;
    dialog: BaseDialog;
    spin: boolean = false;
    showComponent: boolean = false;
    fileViewModelCertificate: FileInputViewModel;
    fileViewModelCertificate2: FileInputViewModel;
    fileViewModelCertificate3: FileInputViewModel;
    fileOutputViewModelCertificate: FileOutputViewModel = new FileOutputViewModel();
    fileOutputViewModelCertificate2: FileOutputViewModel = new FileOutputViewModel();
    fileOutputViewModelCertificate3: FileOutputViewModel = new FileOutputViewModel();

    constructor(private blobState: BlobUploadsViewStateService) {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.fileViewModelCertificate = new FileInputViewModel();
        this.fileViewModelCertificate.fileMode = FileTypeEnums.Pdf;
        this.fileViewModelCertificate.fileAllowSize = FileSizeEnums.MaxMB;
        this.fileViewModelCertificate2 = new FileInputViewModel();
        this.fileViewModelCertificate2.fileMode = FileTypeEnums.Pdf;
        this.fileViewModelCertificate2.fileAllowSize = FileSizeEnums.MaxMB;
        this.fileViewModelCertificate3 = new FileInputViewModel();
        this.fileViewModelCertificate3.fileMode = FileTypeEnums.Pdf;
        this.fileViewModelCertificate3.fileAllowSize = FileSizeEnums.MaxMB;
    }

   async updateInvoiceFiles($event: FileOutputViewModel) {
        
        this.fileOutputViewModelCertificate = $event;
        this.watchInvoiceFormGroup.patchValue({
            fileData: $event.fileBaseString,
            fileExtention: $event.fileExtension,
            fileName: $event.fileName,
            //blobFileName:uploadedFileName,
            blobPath:BLOB_BASE.InventoryWatchPartsDocumentFiles,
            size:$event.files.size
        });
    }

   async updateInvoice2Files($event: FileOutputViewModel) {
        
        this.fileOutputViewModelCertificate2 = $event;
        this.watchInvoice2FormGroup.patchValue({
            fileData: $event.fileBaseString,
            fileExtention: $event.fileExtension,
            fileName: $event.fileName,
            //blobFileName:uploadedFileName,
            blobPath:BLOB_BASE.InventoryWatchPartsDocumentFiles,
            size:$event.files.size
        });
    }

   async updateInvoice3Files($event: FileOutputViewModel) {
        
        this.fileOutputViewModelCertificate3 = $event;
        this.watchInvoice3FormGroup.patchValue({
            fileData: $event.fileBaseString,
            fileExtention: $event.fileExtension,
            fileName: $event.fileName,
            //blobFileName:uploadedFileName,
            blobPath:BLOB_BASE.InventoryWatchPartsDocumentFiles,
            size:$event.files.size
        });
    }

    async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
      let fileUploadRes = [];
      let newGUID = uuidv4();
      let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
        .split(".")
        .pop()}`;
      var res = await this.blobState.uploadItems($event.files, fileName);
      // fileUploadRes.push(res);
  
      let url = res._response.request.url;
      let uploadedFileName = blobPath + decodeURIComponent(
        url.split("?")[0].split("/").pop()
      );
  
      // IF SUCCEED TO UPLOAD
      if (res && (res._response.status == 200 || res._response.status == 201)) {
        if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
      } else {
        // IF FAILED TO UPLOAD
        console.log("Failed to upload file to blob from client!", res);
        this.toastr.error(
          "Error occured while uploading file! Please try again."
        );
        if (uploadedFileName != undefined) {
          this.modalView
            .show(BedRequestComponent, {
              validationMessages: JSON.stringify(
                uploadedFileName
              ),
              headerMessage: "Image failed to upload:",
            })
            .then(() => { });
        }
      }
      return uploadedFileName;
    }

    removeInvoice(){
        
      this.watchInvoiceFormGroup.patchValue({
        fileData: null,
        blobFileName:null,
        fileExtention: null,
        fileName: null,
        filePath: null
    });
    }

    removeInvoice2(){
        this.watchInvoice2FormGroup.patchValue({
            fileData: null,
            blobFileName:null,
            fileExtention: null,
            fileName: null,
            filePath: null
        });
    }

    removeInvoice3(){
        this.watchInvoice3FormGroup.patchValue({
            fileData: null,
            fileExtention: null,
            blobFileName:null,
            fileName: null,
            filePath: null
        });
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
