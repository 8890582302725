import {ApplicationLocaleBase,} from '@app/database-models'
//Generated Imports
export class ApplicationLocale extends ApplicationLocaleBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}