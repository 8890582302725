import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, date } from '@rxweb/reactive-form-validators';

import { CoreComponent } from '@rxweb/angular-router';
import { List } from '@rxweb/generics';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';

import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { SERVER_URL, CLIENT_URL } from "src/app/domain/system-constant";
import { PlatformLocation } from "@angular/common";
import { ProductPiece } from "@app/models";

@Component({
  selector: 'app-print-barcode',
  templateUrl: './print-barcode.component.html',
})
export class PrintBarcodeComponent extends CoreComponent implements OnInit, OnDestroy {

  @Input() pieceList: ProductPiece[];

  subscription: any;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  modalView: ModalView;
  spin: boolean = false;
  showComponent: boolean = false;
  @Input() hide: Function;
  pieceSerialNo: string;
  pieceName: string;
  pieceSpecification: string;
  pieceDescription: string;
  pieceImageUrl: string;
  printBrocodeData:ProductPiece[];
  fabHallmarkUrl:string;
  newLogoUrl:string;
  certificateCss: string;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, router: Router, modelView: ModalView,private location : PlatformLocation) {
    super();
    this.router = router;
    this.modalView = modelView;
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.printBrocodeData = new Array<ProductPiece>();
    this.printBrocodeData = this.pieceList;
    setTimeout(() => {
      if (this.printBrocodeData != null) {
         this.printBarcode();
      }
    }, 200)
  }

  printBarcode(): void {

    let printContents, popupWin;

    printContents = document.getElementById('print-barcode').innerHTML;
    this.hide();
    let params = 'width=' + screen.width;
    params += ', height=' + screen.height;
    params += ', top=0, left=0'
    params += ', fullscreen=yes';

    popupWin = window.open('', '_blank', params);
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
        </head>
        <body >${printContents}</body>
      </html>`
    );
     setTimeout(() => {
            popupWin.focus();
            popupWin.print();
        }, 1000);
    // popupWin.document.close();

  }
  hidePopup() {
    this.hide()
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
class PiecePrintBarcodeData {
  productVariantBatchPieceId: number;
  pieceNumber: string;
  productName: string;
  gtinNumber: number;
  barcodeUrl: string;
}
