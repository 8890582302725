import {vSystemRatingLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSystemRatingLookUp extends vSystemRatingLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}