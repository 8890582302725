import { Component, OnInit } from '@angular/core';
import { extend } from 'webdriver-js-extender';
import { RxHttp } from '@rxweb/http';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ChangePasswordComponent } from '../../authentication/change-password/change-password.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserStorage } from '../../../domain/services/browser-storage';
import { AuthorizationResolver } from 'src/app/domain/security/authorization-resolver';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { triggerPeopleMenuClick } from 'src/app/domain/customize-plugin/bind-slider';
import { ProductPieceListComponent } from '../../inventory/product-piece/list/product-piece-list.component';
import { PreviousPath } from '../../start/app.component';
import { Subscription } from 'rxjs';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import { TaskDetailBase } from '@app/database-models';
import { MsalService } from '@azure/msal-angular';
import { ProjectListComponent } from '../../project/projects/list/project-list.component';
import { RiskMenuDetailListComponent } from '../../form/risk-menu-details/list/risk-menu-detail-list.component';
import { UserListComponent } from '../../user/users/list/user-list.component';
import { SupplierListComponent } from '../../user/suppliers/list/supplier-list.component';
import { EventSearchComponent } from '../../event/events/search/event-search.component';
import { rolesEnum } from 'src/app/enums/roles-enum';
@multilingual("TopBarComponent")
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent extends RxHttp implements OnInit {

  isSideBarActive: boolean = false;
  loginUserName: string;
  loggedInUserId: number;
  modalView: ModalView;
  profileImage: string;
  expireDays: number;
  daysLeft: number;
  searchString: string;
  activeMenu: string;
  productPieceList: any = ProductPieceListComponent;
  projectList:any = ProjectListComponent
  eventList:any = EventSearchComponent
  riskList:any = RiskMenuDetailListComponent
  peopleList:any = UserListComponent
  corporateList:any = SupplierListComponent
  subscription: any;
  titleSearch: string;
  titleLogOut : string;
  totalEvents:any;
  totalProjects:any;
  totalSupplier:any;
  totalMyTeam:any;
  totalMyRisk:any;
  totalPeople:any;
  totalTasks:any;
  isSSOEnabled: boolean = true;
  element: number;
  adminRole: boolean = false;

  constructor(modelView: ModalView, private browserStorage: BrowserStorage, private authResolver: AuthorizationResolver, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, private authService: MsalService) {
    super();
    this.modalView = modelView;
  }

  ngOnInit() {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleLogOut = getLocalizedValue("LogOut_tolltip");
    this.searchString = "Product+Fast+Find";
    var auth = this.browserStorage.local.get("auth", false);
    this.expireDays = Number(this.browserStorage.local.get('expireDays'));
    this.daysLeft = Number(this.browserStorage.local.get('daysLeft'));
    // this.profileImage = this.browserStorage.local.get("avatar");
    this.isSSOEnabled = Boolean(this.browserStorage.local.get("isSSOEnabled", true));

    this.applicationBroadcaster.loginSubscriber.subscribe(t => {
      if (t) {
        auth = this.browserStorage.local.get("auth", false);
        if (auth) {
          this.loggedInUserId = Number(this.browserStorage.local.get("userId", true));
          if (localStorage.getItem("userName")) {

            this.loginUserName = this.browserStorage.local.get("userName", false);
          }
          else {
            this.loginUserName = this.browserStorage.local.get("userEmail", true);
          }
        }
      }
    });

    this.applicationBroadcaster.userProfileChangeSubscriber.subscribe(t => {
      if(t){
        auth = this.browserStorage.local.get("auth", false);
        if (auth) {
          this.isSSOEnabled = Boolean(this.browserStorage.local.get("isSSOEnabled", true));
          if (localStorage.getItem("userName")) {
            this.loginUserName = this.browserStorage.local.get("userName", false);
          }
          else {
            this.loginUserName = this.browserStorage.local.get("userEmail", true);
          }
        }
      }
    });

    if (auth) {
      this.loggedInUserId = Number(this.browserStorage.local.get("userId", true));
      if (localStorage.getItem("userName")) {

        this.loginUserName = this.browserStorage.local.get("userName", false);
      }
      else {
        this.loginUserName = this.browserStorage.local.get("userEmail", true);
      }

    }
    // this.isToggleSideBar();

    let roles: any[];
      let roleString: string = this.browserStorage.local.get('userRoles');
      roles = roleString.split(',');
      for (let index = 0; index < roles.length; index++) {
        this.element = roles[index];
        if (this.element == rolesEnum.Administrator || this.element == rolesEnum.HRCompanySpecific || this.element == rolesEnum.HRGroupwide) {
          this.adminRole = true;
        }
      }
  }

  isToggleSideBar() {
    var element = document.getElementById("gtrac");
    this.isSideBarActive = !this.isSideBarActive
    if (this.isSideBarActive) {
      element.classList.add("mini-navbar");
    }
    else {
      element.classList.remove("mini-navbar");
    }
  }
  showChangePasswordModal() {
    this.modalView.show(ChangePasswordComponent, { userId: Number(this.browserStorage.local.get("userId")) }).then(t => {
      if (this.daysLeft <= this.expireDays) {
        window.location.reload();
      }
      else {
        this.ngOnInit();
      }

    });
  }
  redirectPieces(f: NgForm) {
    this.searchString = f.value["searchProduct"];
    if (this.searchString == "" || this.searchString == null) {
      this.searchString = "Product+Fast+Find";
    }
    (document.getElementById("top-search") as HTMLInputElement).value = "";
    this.router.navigate(["product-piece"], { queryParams: { searchTerms: this.searchString } })
  }

  enterSearchString(event: any) {
    this.searchString = event.target.value;
  }

  changeActiveMenu(): void {
    ///this.applicationBroadcaster.activeMenu({ activeMenu: 'people', subMenu: 'users' })
    triggerPeopleMenuClick();
  }

  displayCount() {
      this.totalTasks = 0
      this.totalEvents = 0
      this.totalProjects = 0
      this.totalPeople = 0
      this.totalMyTeam = 0
      this.totalSupplier = 0
      this.totalMyRisk = 0
    this.get({
      path: 'api/SearchCommon/GetProfileCount'
    }).subscribe((t: any) => {
      this.totalEvents = t[0].Events;
      this.totalProjects = t[0].Project;
      this.totalPeople = t[0].Mypeople;
      this.totalMyTeam = t[0].Myteam;
      this.totalSupplier = t[0].Supplier;
      this.totalMyRisk = t[0].MyRisk;
      this.totalTasks = t[0].MyTask;
    })
  }

  logout() {
    this.post({ path: "api/Authorize/logout", body: { "AudienceType": "web", "LanguageCode": "en" } }).subscribe(data => {
      this.clearLocalStore();
      // localStorage.clear();
      // sessionStorage.clear();
      // document.cookie = "requestContext=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
      window.setTimeout(() => {
        window.location.href = "/login";
        //this.router.navigate(["login"])
      }, 1000);
      this.authResolver.clearPermissions();
    })
  }
  clearLocalStore() {
    //localStorage.clear();
    this.browserStorage.local.remove('anonymousAuth');
    this.browserStorage.local.remove('auth');
    this.browserStorage.local.remove('x-request');
    this.browserStorage.local.remove('userName');
    this.browserStorage.local.remove('roleId');
    this.browserStorage.local.remove('userEmail');
    this.browserStorage.local.remove('lcode');
    this.browserStorage.local.remove('userId');
    this.browserStorage.local.remove('isGA');
    this.browserStorage.local.remove('userRoles');
    this.browserStorage.local.remove('avatar');
    this.browserStorage.local.remove('daysLeft');
    this.browserStorage.local.remove('expireDays');
    this.browserStorage.local.remove('SystemHelp');
    this.browserStorage.local.remove('documentVisited');
    this.browserStorage.local.remove('isSSOEnabled');
    this.authService.instance['browserStorage'].clear();
    localStorage.removeItem('previousroute');
    localStorage.removeItem('gridRowCount');
    localStorage.removeItem('TinyMCEdraft')
    sessionStorage.clear();
    document.cookie = "requestContext=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
  get componentName(): string {
    return "TopBarComponent";
  }
}
