import { prop, required, maxLength, range, trim, notEmpty } from "@rxweb/reactive-form-validators"


export class MainSeoDetailBase {

    //#region topicLocalizedId Prop
    @prop()
    seoId: number;
    //#endregion topicLocalizedId Prop


    //#region metaTitle Prop
    //@required()
    @prop({ defaultValue: "" })
    @maxLength({ value: 400 })
    @trim()
    //@notEmpty()
    metaTitle: string;
    //#endregion metaTitle Prop


    //#region metaKeyword Prop
    //@required()
    @prop({ defaultValue: "" })
    @maxLength({ value: 400 })
    @trim()
    //@notEmpty()
    metaKeyword: string;
    //#endregion metaKeyword Prop


    //#region metaDescription Prop
    //@required()
    @prop({ defaultValue: "" })
    @maxLength({ value: 4000 })
    @trim()
    //@notEmpty()
    metaDescription: string;
    //#endregion metaDescription Prop

    //#region moduleTypeId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    moduleTypeId: number;
    //#endregion moduleTypeId Prop

    //#region moduleId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    moduleId: number;
    //#endregion moduleId Prop


    //#region languageId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    languageId: number;
    //#endregion languageId Prop

    //#region statusId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    statusId: number;
    //#endregion statusId Prop

    //#region createdAt Prop
    @required()
    createdAt: Date;
    //#endregion createdAt Prop

    //#region updatedAt Prop
    @prop()
    updatedAt: Date;
    //#endregion updatedAt Prop

    //#region createdBy Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region updatedBy Prop
    @prop()
    updatedBy: number;
    //#endregion updatedBy Prop
}