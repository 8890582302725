import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, RxFormGroup } from '@rxweb/reactive-form-validators';
import { HelpSupportUserRole, vRoleLookUp, HelpSupport } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { ModalView } from '../../../../domain/customize-design/modal';

@http({
    path: "api/HelpSupports",
})
export class AbstractHelpSupportUserRole extends CoreComponent {
    spin: boolean = false;
    helpSupportUserRoleFormGroup: IFormGroup<HelpSupportUserRole>
    helpSupportFormGroup: IFormGroup<HelpSupport>;
    helpSupportUserRoleFormGroupArray: IFormGroup<HelpSupportUserRole[]>
    showComponent: boolean = false;
    helpSupportLookUps: any = {
        roleLookUp: vRoleLookUp,
        helpSupportUserRole: HelpSupportUserRole,
        helpSupport: HelpSupport
    }
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    badRequest = (data) => {
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
