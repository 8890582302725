import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class POSMemberMappingBase {

//#region pOSMemberId Prop
        @prop()
        pOSMemberId : number;
//#endregion pOSMemberId Prop


//#region pOSId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pOSId : number;
//#endregion pOSId Prop


//#region memberId Prop
        @prop()
        memberId : number;
//#endregion memberId Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region roleType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        roleType : number;
//#endregion roleType Prop





}