import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemTimeComponent } from './system-time.component';


@NgModule({
    declarations: [SystemTimeComponent],
    imports: [
        CommonModule
    ],
    exports: [SystemTimeComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SystemTimeSharedModule {
    static forRoot(): ModuleWithProviders<SystemTimeSharedModule> { return { ngModule: SystemTimeSharedModule, providers: [] }; }
}

