import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPricebandOptionLookUpBase {

//#region pricebandOptionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'pricebandOptionId', keyColumn: true})
        pricebandOptionId : number;
//#endregion pricebandOptionId Prop


//#region pricebandName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'pricebandName', keyColumn: false})
        pricebandName : string;
//#endregion pricebandName Prop

}