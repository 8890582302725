import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLocationCustomerTypeLookUpBase {

//#region locationCustomerTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'locationCustomerTypeId', keyColumn: true})
        locationCustomerTypeId : number;
//#endregion locationCustomerTypeId Prop


//#region locationCustomerTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'locationCustomerTypeName', keyColumn: false})
        locationCustomerTypeName : string;
//#endregion locationCustomerTypeName Prop

}