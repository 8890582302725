import { prop,propObject,propArray,required,maxLength,range, notEmpty, numeric  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BespokeOrderBase {

//#region bespokeOrderId Prop
        @prop()
        bespokeOrderId : number;
//#endregion bespokeOrderId Prop


//#region bespokeOrderGUId Prop
        @required()
        bespokeOrderGUId : any;
//#endregion bespokeOrderGUId Prop


//#region customerId Prop
        @prop()
        customerId : number;
//#endregion customerId Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region dateOfRequest Prop
        @required()
        dateOfRequest : Date;
//#endregion dateOfRequest Prop


//#region salesPersonId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        salesPersonId : number;
//#endregion salesPersonId Prop


//#region approvalStatus Prop
       //        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        approvalStatus : number;
//#endregion approvalStatus Prop


//#region clientDescription Prop
        @prop()
        @maxLength({value:200})
        clientDescription : string;
//#endregion clientDescription Prop


//#region productCategoryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productCategoryId : number;
//#endregion productCategoryId Prop


//#region salesChannelId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region customVariationDescription Prop
        @prop()
        @required()
        @notEmpty()
        @maxLength({value:200})
        customVariationDescription : string;
//#endregion customVariationDescription Prop


//#region materialType Prop
        @prop()
        @required()
        @notEmpty()
        @maxLength({value:100})
        materialType : string;
//#endregion materialType Prop


//#region clientBudget Prop
        @required()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2134566788})
        clientBudget : number;
//#endregion clientBudget Prop


//#region gemstoneDescription Prop
        @prop()
        @maxLength({value:200})
        gemstoneDescription : string;
//#endregion gemstoneDescription Prop


//#region measurement Prop
        @prop()
       // @notEmpty()
        @numeric()
        @maxLength({value:10})
        measurement : string;
//#endregion measurement Prop


//#region additionalRequirementDescription Prop
        @prop()
        @required()
        @notEmpty()
        @maxLength({value:200})
        additionalRequirementDescription : string;
//#endregion additionalRequirementDescription Prop


//#region clientDeliveryDate Prop
        @required()
        clientDeliveryDate : Date;
//#endregion clientDeliveryDate Prop


//#region specialOccassion Prop
        @prop()
        @maxLength({value:100})
        specialOccassion : string;
//#endregion specialOccassion Prop


//#region deliveryCountryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        deliveryCountryId : number;
//#endregion deliveryCountryId Prop


//#region paymentTerms Prop
        @prop()
        @required()
        @notEmpty()
        @maxLength({value:200})
        paymentTerms : string;
//#endregion paymentTerms Prop


//#region uploadFile Prop
        @prop()
        uploadFile : string;
//#endregion uploadFile Prop


//#region uploadFile1 Prop
        @prop()
        uploadFile1 : string;
//#endregion uploadFile1 Prop


//#region uploadFile2 Prop
        @prop()
        uploadFile2 : string;
//#endregion uploadFile2 Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop


//#region currencyID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        currencyID : number;
//#endregion currencyID Prop


//#region customerCurrencyCode Prop
        @required()
        @maxLength({value:5})
        customerCurrencyCode : string;
//#endregion customerCurrencyCode Prop





}