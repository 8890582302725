import { IFormGroup } from '@rxweb/reactive-form-validators';
import { QueuedEmail } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CoreComponent,  } from '@rxweb/angular-router';
import { http } from '@rxweb/http';
@http({
    path: "api/QueuedEmails",
})
export class AbstractQueuedEmail extends CoreComponent {

    queuedEmailFormGroup: IFormGroup<QueuedEmail>
    spin: boolean = false;
    showComponent: boolean = false;
    showGird:boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    noRecordFound:boolean;
    constructor(){
        super();
        this.toastr=new BaseToastr();
        this.dialog=new BaseDialog();
    }
}
