import { Inject, Injectable } from "@angular/core";
import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";
import { RxHttp } from "@rxweb/http";

@Injectable({
  providedIn: "root",
})
export class BlobStorageService {
  containerName: string = "";

  constructor(private rxHttp: RxHttp) {}

  async uploadToBlobStorage(file: File, filePath: string) {
    const blockBlobClient = await this.getBlockBlobClient(filePath);
      return this.uploadFile(blockBlobClient, file);
  }

  async multipleUploadToBlobStorage(files: File[], filePath: string) {
    const blockBlobClient = await this.getBlockBlobClient(filePath);
    var responses: any[];
    await files.forEach(file => {
       this.uploadFile(blockBlobClient, file).then( (x) =>{
        responses.push(x);
      });
      return responses;
    });
  }

  private async getBlockBlobClient(filePath: string) {
    const containerClient = await this.getContainerClient();
    return containerClient.getBlockBlobClient(filePath);
  }

  private async getContainerClient() {
    const blobServiceClient = await this.buildClient();
    return blobServiceClient.getContainerClient(this.containerName);
  }

  private async buildClient() {
    var res = await this.rxHttp
      .get({ path: "api/authentication/getBlobTemporaryToken" })
      .toPromise<any>();
    if (res) {
      this.containerName = res.container;
      return new BlobServiceClient(`${res.uri}?${res.token}`);
    }
    // PASSING NULL OBJECT
    return null;
  }

  private uploadFile(blockBlobClient: BlockBlobClient, file: File) {
    return blockBlobClient.uploadBrowserData(file);
  }

  private uploadFiles(blockBlobClient: BlockBlobClient, files: File[]) {
    files.forEach(file => {
      return blockBlobClient.uploadBrowserData(file);
    });
  }
}
