import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class StateProvinceBase {

//#region stateProvinceId Prop
        @prop()
        stateProvinceId : number;
//#endregion stateProvinceId Prop


//#region countryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        countryId : number;
//#endregion countryId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        //@alpha({allowWhiteSpace:true})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region abbreviation Prop
        @required()
        @maxLength({value:30})
        @notEmpty()
        @trim()
        abbreviation : string;
//#endregion abbreviation Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region parentStateProvinceId Prop
        @prop()
        parentStateProvinceId : number;
//#endregion parentStateProvinceId Prop



}