import { DefaultUrlSerializer, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { forEach } from "jszip";
import { an } from "@fullcalendar/core/internal-common";

/* This service is created to serialize url

* It's needed to remove all white spaces from url.

* Removed all special characters except ?=&/- as we are using those in params, route names etc..
  
*If route URL contains "duplicate-customer", this route has emailid param so allowed @._ special characters.
  
*If route URL contains "stock-card", this route has json string param so allowed "%:,._{} special characters and spaces 

*/

@Injectable({ providedIn: 'root' })
export class CustomUrlSerializer extends DefaultUrlSerializer {

    customUrl: any;
    parse(url: string): UrlTree {
        return super.parse(this.removeAllSpaceNSpecialCharacters(url));
    }

    private removeAllSpaceNSpecialCharacters(url: string): string {

        // return url.replace('\s+','');  // regex of space
        // RegExp is not working in url as space coming as %20 so replaced that with blank string

        this.customUrl = url;

        if (this.customUrl.includes("sso-redirected")) {

            return url;
        }

        if (this.customUrl.includes("%20") && !this.customUrl.includes("stock-card") && !this.customUrl.includes("print-caption-report")
            && !this.customUrl.includes("faberge-localization")) {

            this.customUrl = this.customUrl.replaceAll("%20", "");
        }

        // "duplicate-customer" this route has emailid param so allowed @._ special characters  
        if (this.customUrl.includes("duplicate-customer")) {
            this.customUrl = this.customUrl.replaceAll(/[^a-zA-Z0-9\s@._?=&/-]/g, "");
        }
        // "stock-card" this route has Json string param so allowed "%:,._{} special characters and spaces 
        else if (this.customUrl.includes("stock-card")) {

            this.customUrl = this.customUrl.replaceAll(/[^a-zA-Z0-9\s"%:,._{}?=&/-]/g, "");
        }
        // "print-caption-report" this route has Json string param so allowed "%:,._{} special characters and spaces
        else if (this.customUrl.includes("print-caption-report")) {

            this.customUrl = this.customUrl.replaceAll(/[^a-zA-Z0-9\s"%:,._{}?=&/-]/g, "");
        }
        // "faberge-localization" this route has Key string param so allowed "%:,._{} special characters and spaces
        else if (this.customUrl.includes("faberge-localization")) {

            this.customUrl = this.customUrl.replaceAll(/[^a-zA-Z0-9\s"%:,._{}?=&/-]/g, "");
        }
        else {
            this.customUrl = this.customUrl.replaceAll(/[^a-zA-Z0-9\s?=&/-]/g, "");
        }

        url = this.customUrl;
        return url;

    }
}
