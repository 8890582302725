import {ProductAttributeValueLocalizationBase,LanguageBase,ProductAttributeValueBase,} from '@app/database-models'
//Generated Imports
export class ProductAttributeValueLocalization extends ProductAttributeValueLocalizationBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop
//#region productAttributeValue Prop
productAttributeValue : ProductAttributeValueBase;
//#endregion productAttributeValue Prop

//#endregion Generated Reference Properties




}