<div [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <ng-container *ngIf="this.componentType == 'project'">
      <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="Btn_Add_Person_Corporate_t"></button>
    </ng-container>
    <ng-container *ngIf="this.componentType == 'events' || this.componentType == 'project-restrict-access'">
      <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="ProjectCorporateMappingListComponent_Add_Person_t"></button>
    </ng-container>
    <label class="ml-auto mr-3 mb-3">
      <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
        placeholder="" [title]="titleSearch">
    </label>
    <div class="ibox-tools mb-4 pb-2" style="position: relative;" *ngIf="!isNoRecordFound">
      <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
        <i class="fa fa-cog"></i>
      </a>
      <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
        (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
        [gridColumnConfig]="projectCorporateGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
        [moduleID]="moduleID" [selectedTab]="selectedTab">
      </app-system-table-setting>
    </div>
  </div>
  <div class="d-flex flex-wrap align-items-end mb-2">
    <div>
      <div class="d-flex align-items-center">
        <ng-container [rxLocalisationInit]="componentName" *ngIf="projectOrEvent==2">
          <label class="col-form-label" rxText="ProjectCorporateMappingListComponent_RestProject_t"></label>
        </ng-container>
        <ng-container [rxLocalisationInit]="componentName" *ngIf="projectOrEvent==1">
          <label class="col-form-label" rxText="ProjectCorporateMappingListComponent_RestEvent_t"></label>
        </ng-container>
        <ng-container *ngIf="projectOrEvent==1 || projectOrEvent==2">
          <fieldset class="mb-0">
            <div class="i-checks">
              <label class="mb-0 ml-3 pr-1">
                
                <app-checkbox [tabindex]="'1'" [checked]="isRestricted" (change)="setRestriction()">
                </app-checkbox>

              </label>
            </div>
          </fieldset>
        </ng-container>
      </div>
      <div>
        <ng-container [rxLocalisationInit]="componentName" *ngIf="projectOrEvent==2">
          <small class="form-text text-danger d-block" rxText="ProjectCororateList_RestritProjectWarning_t"></small>
        </ng-container>
        <ng-container [rxLocalisationInit]="componentName" *ngIf="projectOrEvent==1">
          <small class="form-text text-danger d-block" rxText="ProjectCororateList_RestritEventWarning_t"></small>
        </ng-container>
      </div>
    </div>
    <div data-toggle="buttons" *ngIf="isLongTextExists" class="btn-group btn-group-gray btn-group-toggle ml-auto">
      <ng-container *ngIf="!isShowAll" [rxLocalisationInit]="componentName">
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Show_All_t"></button>
      </ng-container>
      <ng-container *ngIf="isShowAll" [rxLocalisationInit]="componentName">
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Hide_All_t"></button>
      </ng-container>
    </div>

  </div>
  <div class="table-responsive" *ngIf="isShowGrid">
    <div class="dataTables_wrapper pb-0 dt-bootstrap4">
      <rx-grid [design]="projectCorporateGrid"></rx-grid>
    </div>
  </div>
  <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
    <h4 class="mt-3">No Record Found</h4>
  </div>
</div>
<div class="row mt-auto" *ngIf="projectOrEvent==1 || projectOrEvent==2">
  <div class="hr-line-dashed border-top w-100"></div>
  <div class="col text-right" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <button tabindex="18" (click)="UpdateRestriction(false)" class="btn btn-success" rxText="Btn_Save_t"></button>
    <button tabindex="19" (click)="UpdateRestriction(true)" class="btn btn-primary ml-1"
      rxText="Btn_Save_Continue_Edit_t">
    </button>
  </div>
</div>