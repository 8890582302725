import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vObjectTypeLookUpBase {

//#region objectTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'objectTypeId', keyColumn: true})
        objectTypeId : number;
//#endregion objectTypeId Prop


//#region objectTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'objectTypeName', keyColumn: false})
        objectTypeName : string;
//#endregion objectTypeName Prop

}