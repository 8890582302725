import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerStockRequestNotificationBase {

    //#region RequestId Prop
    @gridColumn({ isAscending: false ,style: { width: "10%", "text-align": "initial"}, class: ["text-left","word-break"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'CustomerStockNotificationReport_requestId_gh', keyColumn: true })
    requestId: number;
    //#endregion RequestId Prop


    //#region RecepientEmail Prop
    @gridColumn({ style: { width: "25%", "text-align": "initial"  }, class: ["text-left","word-break","pr-4"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'CustomerStockNotificationReport_customerEmail_gh', keyColumn: false })
    recepientEmail: string;
    //#endregion dutyStatusName Prop

    @gridColumn({
        template: {
            div: {
                childrens: [
                    {
                        a: {
                            attributes: {
                                href: function (e) {
                                    return "/product/" + this.productVariantId + "?activeTab=product-info";
                                },
                            },
                            class: ["text-blue","font-weight-bold","mr-3"],
                            childrens : [
                                {
                                    text: {
                                        text : function(e) {
                                            return this.productName
                                        }
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },  style: { width: "25%", "text-align": "initial"  }, class: ["text-left","pr-4"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'CustomerStockNotificationReport_pname_gh', keyColumn: false
    })
    productName: string;

    @gridColumn({  style: { width: "10%", "text-align": "initial"  }, class: ["text-left","word-break"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'CustomerStockNotificationReport_size_gh', keyColumn: false })
    size: string;

    @gridColumn({  style: { width: "10%", "text-align": "initial"  }, class: ["text-left","word-break"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'CustomerStockNotificationReport_clang_gh', keyColumn: false })
    languageName: string;

    @gridColumn({  style: { width: "10%", "text-align": "initial" }, class: ["text-left","word-break"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'CustomerStockNotificationReport_createdOn_gh', keyColumn: false })
    createdOn: string;

    @gridColumn({  style: { width: "10%", "text-align": "initial"  }, class: ["text-left","word-break"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'CustomerStockNotificationReport_mailSentOn_gh', keyColumn: false })
    mailSentOn: string;


    @gridColumn({ configuredTemplate: { templateName: "recordActive"  }, style: { width: "10%", "text-align": "initial"  }, class: ["text-center","word-break"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'CustomerStockNotificationReport_ismailsent_gh', keyColumn: false })
    isMailSent: boolean;


    productVariantId;


}