import { TemplateConfig } from '@rxweb/grid';
export const GRID_TEXTBOX_WITH_VALUE: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1"],
                  event: {
                    input: "onDataChange"
                  },
                  
                  attributes: {
                    type: "text",
                    value: function (e) {
                      return this.PriceWholesalesoldat;
                    },
                    min:0
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }

  export const GRID_TEXTBOX_WITH_VALUE_REPORT: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1"],
                  event: {
                    input: "onDataChange"
                  },
                  
                  attributes: {
                    type: "text",
                    value: function (e) {
                      return this.PriceWholesalesoldat;
                    },
                    min:0,
                    maxlength:9
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }