import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class POSDocumentBase {

//#region pOSDocumentId Prop
        @prop()
        pOSDocumentId : number;
//#endregion pOSDocumentId Prop


//#region saleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        saleId : number;
//#endregion saleId Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region fileName Prop
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop


//#region noteId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        noteId : number;
//#endregion noteId Prop

}