import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class InvoiceBase {

//#region invoiceID Prop
        @prop()
        invoiceID : number;
//#endregion invoiceID Prop


//#region invoiceGUID Prop
        @required()
        invoiceGUID : any;
//#endregion invoiceGUID Prop


//#region invoiceNumber Prop
        @required()
        @maxLength({value:50})
        invoiceNumber : string;
//#endregion invoiceNumber Prop


//#region invoiceDate Prop
        @required()
        invoiceDate : Date;
//#endregion invoiceDate Prop


//#region supplierID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        supplierID : number;
//#endregion supplierID Prop


//#region fileName Prop
        @required()
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @prop()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region updatedBy Prop
        @prop()
        updatedBy : number;
//#endregion updatedBy Prop


//#region isMailSent Prop
        @required()
        isMailSent : boolean;
//#endregion isMailSent Prop







}