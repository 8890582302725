import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxProviderRecordBase {

//#region taxProviderId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'taxProviderId', keyColumn: true})
        taxProviderId : number;
//#endregion taxProviderId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region configureTemplatePath Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'configureTemplatePath', keyColumn: false})
        configureTemplatePath : string;
//#endregion configureTemplatePath Prop


//#region className Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'className', keyColumn: false})
        className : string;
//#endregion className Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}