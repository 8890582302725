import { TemplateConfig } from '@rxweb/grid';

export const INCIDENT_DETAILS: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onIncidentDataClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                if (this[e.name] > 0 && e.name != "Total" && this.Company != "Total") {
                  return this[e.name];
                }
              }
            }
          }]
        },
        text: {
          text: function (e) {
            if (this[e.name] == 0 || e.name == "Total" || this.Company == "Total") {
              return this[e.name];
            }
          }
        }
      }
    ],
    class: [
      function (e) {
        if (this.Company == "Accident / Injury / Fatality") {
          return "highlight";
        }
        else if (this.Company == "Incident") {
          return "highlight";
        }
        else if (this.Company == "Near-miss / Unsafe act") {
          return "highlight";
        }
        else if (this.Company == "Grievance") {
          return "highlight";
        }
        else if (this.Company == "Total" && this.TypeId == null) {
          return "highlightFinal";
        }
        else if ((e.name == "Total" && (this.Company == "Accident / Injury / Fatality"
       || this.Company == "Incident")
       || this.Company == "Near-miss / Unsafe act"
       || this.Company == "Grievance"
        ) || this.Company == "Total") {
          return "highlight";
        }
        else {
          return "";
        }
      }
    ]
  },
};

export const TIMEPIECE_SALES_REPORT: TemplateConfig = {
  div: {
    childrens: [
      {
        text: {
          text: function (e) {
            if (e.name != "Total" && e.name != "Total") {
              return this[e.name];
            }
          }
        }
      }
    ]
  },
};


export const PRICEBAND_OPTION_REPORT_PRODUCT_REDIRECT: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onIncidentDataClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                if (this[e.name] > 0 && e.name != "Total" && this.INVENTORY != "Total") {
                  return this[e.name];
                }
              }
            }
          }]
        },
        text: {
          text: function (e) {
            if (this[e.name] == 0 || e.name == "Total" || this.INVENTORY == "Total") {
              return this[e.name];
            }
          }
        }
      }
    ],
    class: [
      function (e) {
        if (this.INVENTORY == "" ) {
          return "highlightFinal";
        }
        else if ((e.name == "Total" ) || this.INVENTORY == "Total") {
          return "highlight";
        }
        else {
          return "";
        }
      }
    ]
  },
};