import {EggHuntBase,} from '@app/database-models'
//Generated Imports
export class EggHunt extends EggHuntBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}