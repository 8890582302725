import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ChainColourBase {

//#region chainColourId Prop
        @prop()
        chainColourId : number;
//#endregion chainColourId Prop


//#region colourName Prop
        @prop()
        // @alpha({allowWhiteSpace:true})
        @trim()
        @notEmpty()
        colourName : string;
//#endregion colourName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}