import {BoutiqueSalesTeamBase,BoutiqueBase,} from '@app/database-models'
//Generated Imports
export class BoutiqueSalesTeam extends BoutiqueSalesTeamBase 
{




//#region Generated Reference Properties
//#region boutique Prop
boutique : BoutiqueBase;
//#endregion boutique Prop

//#endregion Generated Reference Properties












}