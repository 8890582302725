import { prop, email, required, notEmpty } from '@rxweb/reactive-form-validators';

export class  ResetPasswordViewModel{
    @prop()
    userId: number;

    @prop()
    guid:any;

    @prop()
    //@required()
    currentPassword : string;

    @prop()
    @required()
    @notEmpty()
    newPassword : string;

    @prop()
    @required()
    @notEmpty()
    confirmPassword : string;

    @prop({defaultValue:true})
    @required()
    isChangePassword:boolean;

    @prop()
    isCheckpassword:boolean;

    @prop()
    isExpired:boolean;
    @prop()
    screen:string;
}
