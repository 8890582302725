import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subject, Subscription } from 'rxjs';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { EventCustomerMapping, vEventTypeLookUp } from '@app/models';
import { AbstractEventCustomerMapping } from '../domain/abstract-event-customer-mapping';
import { TeamLoginSearchModel } from 'src/app/view-model/team-login-search-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { TeamLoginReportViewModel } from 'src/app/view-model/team-login-report-view-model';
import { DatePipe, PlatformLocation } from '@angular/common';
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { ModalView } from "src/app/domain/customize-design/modal";
import { ImportEventInviteesAttendeesViewModel } from "src/app/view-model/import-event-invitees-attendees-view-model";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { debounceTime } from "rxjs/operators";
import { ImportEventsInviteesAttendeesListViewModel } from "src/app/models/extended-models/import-events-invitees-attendees-list-view-model";
import { multilingual } from "@rxweb/localization";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("EventCustomerMappingAddComponent")
@Component({
    selector: "app-event-customer-mapping-add",
    templateUrl: './event-customer-mapping-add.component.html'
})
export class EventCustomerMappingAddComponent extends AbstractEventCustomerMapping implements OnInit, OnDestroy {

    eventTypeLookUp: vEventTypeLookUp;
    showSaveButton : boolean;
    titleClose: string;
    startDate:Date;
    endDate:Date;
    dateError:boolean=false;
    eventInviteesAttendees : ImportEventInviteesAttendeesViewModel;
    importEventSearchFormGroup : IFormGroup<ImportEventInviteesAttendeesViewModel>;
    improtEventsGrid : AppGrid;
    totalRecords : number;
    headerBtnChk : boolean = false;
    isFilter : boolean = false;
    @Input() eventId : number;
    @Input() hide: Function;
    searchEventName : string;
    importEventsList : ImportEventsInviteesAttendeesListViewModel[];
    cloneArray : ImportEventsInviteesAttendeesListViewModel[];
    subscription: any;
    ErrorMsg:string;
    eventIds: number[];
    eventIdArr : number[];


    _this;
    private importEventSubject: Subject<string> = new Subject<string>();

    constructor(private formBuilder: RxFormBuilder,private datePipe:DatePipe, modalView: ModalView, private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.modalView = modalView;
        this.titleClose = getLocalizedValue("Title_Close");
        this.cloneArray = new Array<ImportEventsInviteesAttendeesListViewModel>();
        this.eventIds = new Array<number>();
        this.eventIdArr =  new Array<number>();
        location.onPopState(() => this.hide());
      }

      ngOnInit(): void {
        this.eventInviteesAttendees = new ImportEventInviteesAttendeesViewModel();
        this.eventInviteesAttendees.pageIndex = PaginationEnum.PageIndex;
        this.eventInviteesAttendees.rowCount = PaginationEnum.RowCount;
        this.eventInviteesAttendees.eventId = Number(this.eventId);
        this.lookup([
          { path: USER_LOOKUPS["eventTypeLookUp"], propName: "eventTypeLookUp"}
        ]).subscribe((response : any) => {
          this.spin = false;
          this.eventTypeLookUp = response.eventTypeLookUp;

          this.importEventSearchFormGroup = this.formBuilder.formGroup(this.eventInviteesAttendees) as IFormGroup<ImportEventInviteesAttendeesViewModel>;
          this.eventIdArr.push(this.eventId);
          this.showComponent = true;
        });
      this.importEventSubject.pipe(debounceTime(300)).subscribe(t=>{
        this.bindGrid();
      })
    }

    ngOnDestroy(): void {
  
    }
    searchEvents()
    {
      this.spin =true;
      if(this.improtEventsGrid)
      {
        this.isFilter = true;
        this.cloneArray = new Array<ImportEventsInviteesAttendeesListViewModel>();
        this.headerBtnChk = false;
        this.improtEventsGrid.updateSource([]);
        this.improtEventsGrid.storeProcedure.nextPage = 1;
      }
      this.importEventSearchFormGroup.value.pageIndex = 1;
      this.importEventSubject.next();
    }
    bindGrid()
    {
      this.spin = true;
      this.importEventSearchFormGroup.submitted = true;
      this.startDate = new Date();
      this.startDate = this.importEventSearchFormGroup.value.eventStartDate;
      this.endDate = new Date();
      this.endDate = this.importEventSearchFormGroup.value.eventEndDate;
      if(this.endDate != null && this.startDate > this.endDate)
      {
        this.dateError = true;
        this.spin = false;
      }
      else
      {
        this.dateError = false;
        if(this.importEventSearchFormGroup.valid)
        {
          this.isShowGrid = true;
          this.spin = true;
          this.subscription = this.post
          (
            {
              path : 'api/SearchEvent/GetImportEvents',
              body : { query : JSON.stringify(this.importEventSearchFormGroup.value)}
            }
          ).subscribe(
          (response : any)=>
          {
            //this.isShowGrid = true;
            if(this.improtEventsGrid)
            {
              this.improtEventsGrid.storeProcedure.length = 0;
            }
            this.importEventsList = response[0].UserData;
            this.ErrorMsg = response[0].ErrorMessage;
            this.importEventsList.forEach(element=>{
              if(this.cloneArray.findIndex(x=>element.eventId == x.eventId) == -1)
              {
                element.isChecked = this.headerBtnChk;
                this.cloneArray.push(element);
              }
              else
              {
                this.cloneArray.forEach(z=>{
                  if(z.eventId == element.eventId)
                  {
                    element.isChecked = z.isChecked;
                  }
                })
              }
              if(this.eventIds.indexOf(element.eventId)!= -1){
                element.isChecked = true;
              }
            });

            this.isShowGrid = true;
            this.spin = false;
            if(this.importEventsList.length > 0)
            {
              this.totalRecords = this.importEventsList[0].totalCount;
              this.isNoRecordFound = false;
            }
            else
            {
              this.isNoRecordFound = true;
              this.isShowGrid = false;
            }
            if(!this.isFilter)
            {
              this.improtEventsGrid = new AppGrid(this.importEventsList, ImportEventsInviteesAttendeesListViewModel, { actions: { onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
              this.improtEventsGrid.storeProcedure = {
                length : this.totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                nextPage: 1,
                onPageSorting: this.onPageSorting.bind(this)
              }
            }
            else
            {
              setTimeout(() => {
                this.improtEventsGrid.storeProcedure.length = this.totalRecords;
                this.improtEventsGrid.updateSource([]);
                this.improtEventsGrid.updateSource(this.importEventsList)
              }, 0);
            }
            this.updateImportEventArray();
            this.improtEventsGrid.maxPerPage = this.importEventSearchFormGroup.value.rowCount;
            this.improtEventsGrid.componentId = this.componentName;
            this.showSaveButton = true;
          }
        );
      }
    }
  }
  onPageSorting(x, y, z) {
    this.spin = true;
    this.isFilter = true;
    this.cloneArray = [];
    this.importEventSearchFormGroup.patchValue({ sortOrder: y, orderBy: x, pageIndex : z})
    this.improtEventsGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x) {
      this.isFilter = true;
      let fromIndex: number = this.importEventSearchFormGroup.value.rowCount;
      let toIndex: number = this.improtEventsGrid.maxPerPage;
      if (this.importEventSearchFormGroup.value.pageIndex < x) {
          this.cloneArray = [];
          // this.projectEventsMappingArray = [];
          // this.eventIds = [];
      }
      if (this.improtEventsGrid.maxPerPage < this.importEventSearchFormGroup.value.rowCount) {
          for (let i = fromIndex; i > toIndex; i--) {
              this.cloneArray.splice(i - 1, 1);
          }
      }
      this.importEventSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.improtEventsGrid.maxPerPage })
      this.improtEventsGrid.storeProcedure.nextPage = x;
      this.bindGrid();
  }
  onHeaderCheckBoxSelect(t,x)
  {
    if(x.target.checked == false)
    {
      this.eventIds = [];
    }
    this.headerBtnChk = x.target.checked;
    this.cloneArray = new Array<ImportEventsInviteesAttendeesListViewModel>();
    this.importEventsList.forEach(data=>{
      let newObj = new ImportEventsInviteesAttendeesListViewModel();
      newObj.eventId = data.eventId;
      newObj.eventEndDate = data.eventEndDate;
      newObj.eventName = data.eventName;
      newObj.eventStartDate = data.eventStartDate;
      newObj.isChecked = this.headerBtnChk;
      newObj.eventTypeId = data.eventTypeId;
      newObj.eventType = data.eventType;
      newObj.totalCount = data.totalCount;
      newObj.inviteesAttendeesCount = data.inviteesAttendeesCount;
      newObj.evName = data.evName;
      newObj.isAllowed = data.isAllowed;
      newObj.isRestrictedEvent = data.isRestrictedEvent;
      this.cloneArray.push(newObj);
    });
    this.updateImportEventArray();
    this.improtEventsGrid.updateSource([]);
    this.improtEventsGrid.updateSource(this.cloneArray);
  }
  updateImportEventArray()
  {
    this.cloneArray.forEach(element=>{
      if(!element.isRestrictedEvent || (element.isRestrictedEvent && element.isAllowed))
      {
        if(element.isChecked)
        {
          if(this.eventIds.indexOf(element.eventId) == -1)
          {
            this.eventIds.push(element.eventId);
          }
        }
        else
        {
          if(this.eventIds.indexOf(element.eventId) != -1)
          {
            this.eventIds.splice(this.eventIds.indexOf(element.eventId),1);
          }
        }
      }
    })
  }
  onCheckboxSelect(t,x)
  {
    if(x.target.checked)
    {
      if(this.eventIds.indexOf(t.eventId) == -1)
      {
        this.eventIds.push(t.eventId);
      }
    }
    else
    {
      if(this.eventIds.indexOf(t.eventId) != -1)
      {
        this.eventIds.splice(this.eventIds.indexOf(t.eventId),1);
      }
    }
  }
  saveEvents(isRedirect : boolean)
  {

    this.spin = true;
    this.post({path : "api/SearchEvent/ImportEventInviteesAttendees", body : {"events": this.eventIds,"eventId" : this.eventIdArr}}).subscribe(response =>{
      this.spin = false;
      var existsAlert = getLocalizedValue("Event_Invitees_Attendees_Imported");;
      if (existsAlert) {
          this.toastr.success(existsAlert);
      }
      if (isRedirect) {
          this.showComponent = false;
          setTimeout(() => {
              this.ngOnInit();
          }, 400)
      }
      else {
          this.hide();
      }
    })
  }
  checkNameText(event: any)
  {
    this.importEventSearchFormGroup.patchValue({
      eventText : event.target.value
    });
    this.searchEventName = event.target.value;
  }
  onNameClick($event) {
    this.importEventSearchFormGroup.patchValue({
      eventText: '',
      eventName :  $event.item.value
    })
    this.searchEventName = $event.item.value;
  }
  get componentName(): string {
    return "EventCustomerMappingAddComponent";
  }
}
