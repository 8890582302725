import {RegionBase,} from '@app/database-models'
//Generated Imports
export class Region extends RegionBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}