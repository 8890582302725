<div *ngIf="showComponent" class="modal fade show" id="bom_add" tabindex="-1" role="dialog"
    style="padding-right: 17px; display: block;">
    <div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
        <div class="modal-content" [rxLocalisationInit]="componentName">
            <div class="modal-header text-left">
                <h4 class="modal-title mb-0">Print Evaluation</h4>
                <button type="button" class="close py-2" (click)="hidePopup()" data-dismiss="modal"
                    [title]="titleClose">
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                </button>
            </div>
            <div class="modal-body pb-0" [formGroup]="priceEvaluationFormGroup">               
                <div class="row">
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_Currency_t"></label>
                            <div class="col-md-6" [rxSelectExtended]="_this">
                                <rx-select #rxSelect mainClass="form-control" [(source)]="currencyLookUp1"
                                    [selectPlaceholder]="'Select Value'" formControlName="currencyId"
                                    (changed)="changeCurrency($event.item.key)" [keyValueProps]="['name','currencyId']">
                                </rx-select>
                                <!-- <select rxFocus class="form-control" (change)="changeCurrency($event.target.value)">
                                <option *ngFor="let item of currencyLookUp1" [value]="item.currencyId" value="{{this.currencyId}}">
                                    {{item.name}}
                                </option>
                            </select> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_Country_t"></label>
                            <div *ngIf="showSelectOption" class="col-md-6" [rxSelectExtended]="_this">
                                <rx-select #rxSelect mainClass="form-control" [(source)]="countryLookup1"
                                    [selectPlaceholder]="'Select Value'" formControlName="countryId"
                                    (changed)="changeCountry($event.text != '' ? $event.item.key : 0)" [keyValueProps]="['name','countryId']">
                                </rx-select>
                                <!-- <select class="form-control rounded" (change)="changeCountry($event.target.value)">
                                <option *ngFor="let item of countryLookup1" [value]="item.countryId" value="{{this.countryId}}">
                                    {{item.name}}
                                </option>
                            </select> -->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showStateProvince" class="col-lg-12 col-xl-12 pr-xl-12" [rxLocalisationInit]="componentName">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_StateProvince_t"></label>
                            <div *ngIf="showSelectOption" class="col-md-6" [rxSelectExtended]="_this">
                                <rx-select #rxSelect [selectPlaceholder]="'Select State / Province'" mainClass="form-control"
                                    [(source)]="stateProvinceLookUp1" (changed)="changeStateProvince($event.text != '' ? $event.item.key : 0)"
                                    formControlName="stateProvinceId" [keyValueProps]="['stateProvinceName','stateProvinceId']">
                                </rx-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_Price_t"></label>
                            <div class="col-md-6">
                                <!-- <input type="text" disabled class="form-control" placeholder="" value="{{this.price}}"> -->
                                <label type="text" 
                                    class="form-control disableLabel mb-0">{{(price === null || price === undefined ||
                                    price === 0) ? 0 : price}}</label>
                            </div>
                            <div class="col-md-2 ">
                                <div class="i-checks">
                                  <label class="mt-1">
                                    <div class="iradio_square-green" [class.checked]="priceEvaluationFormGroup.value.printwithPriceOrVatPrice==0"
                                        style="position: relative;">
                                        <input tabindex="10" type="radio" value="0" formControlName="printwithPriceOrVatPrice" style="visibility: hidden;">
                                    </div>
                                </label>
                                </div>
                            </div>
            
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_IncludingVATPrice_t"></label>
                            <div class="col-md-6">
                                <label type="text" 
                                    class="form-control disableLabel mb-0">{{(includingVATPrice === null || includingVATPrice === undefined ||
                                    includingVATPrice === 0) ? 0 : includingVATPrice}}</label>
                            </div>
                            <div class="col-md-2 ">
                                <div class="i-checks">
                                    <label class="mt-1">
                                      <div class="iradio_square-green" [class.checked]="priceEvaluationFormGroup.value.printwithPriceOrVatPrice==1"
                                          style="position: relative;">
                                          <input tabindex="10" type="radio" value="1" formControlName="printwithPriceOrVatPrice" style="visibility: hidden;">
                                      </div>
                                  </label>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="print-evaluation" style="display: none;">
                    <div style="height: 97vh; page-break-after: always;">
                        <div class="border-div"
                            style="border: 1px solid #af9a65; position: fixed; width: 94.5%; height: 95%; left: 15px; top: 25px; right: 15px; bottom: 10px; z-index:9;">
                        </div>
                        <div class="faberge-logo"
                            style="position: fixed; left: 0; top: 12px; right:0; margin:0 auto; background: #ffffff; z-index: 99;  padding: 0px 15px; width:250px; text-align:center;">
                            <img width="210" src="{{newLogoUrl}}" /> </div>
                        <div class="faberge-certificate"
                            style="padding: 80px 50px; text-align: center; position:relative; z-index:9; height:80%;">
                            <div class="faberge-header"
                                style="font-size: 16px; letter-spacing: 1px; color: #000000; text-align: center; margin-bottom: 10px; font-family: 'garda-titlingtwo';font-variant-ligatures: none; font-weight:normal;">
                                VALUATION FOR INSURANCE PURPOSES</div>
                            <div class="faberge-dec"
                                style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic; margin-bottom: 20px; font-weight: 100;">
                                <!-- With this document we certify that this an authentic Fabergé creation. Your creation has been crafted to the most -->
                                <!-- <br/> exacting standards and with the attention to detail for which Fabergé is renowned. It bears both the authentic Fabergé
                                    <br/> name and hallmark. The details of your Fabergé creation are recorded on this certificate. In accordance with family tradition,
                                    <br/> its particulars and associated inventory number have also been recorded in our archives. Peter Carl Fabergé, legendary
                                    <br/> jeweller and goldsmith to the Russian Imperial Court, left a rich heritage of inspired artistry and unrivalled craftsmanship that
                                    <br/> has resonated through the decades and still exerts a powerful influence today. We at Fabergé remain passionately committed
                                    <br/> to perpetuating our predecessor’s original values, philosophy and spirit, established more than 150 years ago when Gustav
                                    <br/> Fabergé, Peter Carl’s father, established his firm in 1842. We hope this creation brings you many years of enjoyment.
                                    <br/> -->
                            </div>
                            <div class="prod-title"
                                style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;">
                                Product Title </div>
                            <div class="prod-header"
                                style="font-size: 12px; color: #000000; font-weight: 300; text-align: center; text-transform: uppercase ;margin-bottom: 15px; font-family: 'garda-titlingtwo';font-variant-ligatures: none;">
                                {{this.productName}} </div>
                            <div class="prod-title"
                                style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;">
                                Unique Product Code </div>
                            <div class="prod-header"
                                style="font-size: 12px; color: #000000; font-weight: 300; text-align: center; text-transform: uppercase ;margin-bottom: 15px; font-family: 'garda-titlingtwo';font-variant-ligatures: none;">
                                {{this.pieceNumber}} </div>
                            <div class="prod-img" style="text-align: center;margin-bottom: 15px;"> <img width="220"
                                    [src]="this.imageUrl" /> </div>
                            <div class="prod-title"
                                style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;">
                                Specifications </div>
                            <div class="prod-header"
                                style="font-size: 10px; color: #000000; font-weight: 300; text-align: center;font-style: italic; font-weight: 100; margin-bottom: 20px;">
                                {{this.productSpecification}} </div>

                            <div class="prod-title"
                                style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;">
                                Replacement Retail Value </div>
                            <div *ngIf="priceEvaluationFormGroup.value.printwithPriceOrVatPrice==0" class="prod-header"
                                style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic; font-weight: 100; margin-bottom: 20px;">
                                {{this.price}} ({{this.currencyCode}})
                                Excluding VAT
                                <br>
                                ({{this.priceToWords}} {{this.currency}}) </div>

                            <div *ngIf="priceEvaluationFormGroup.value.printwithPriceOrVatPrice==1" class="prod-header"
                                style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic; font-weight: 100; margin-bottom: 20px;">
                                {{this.includingVATPrice}} ({{this.currencyCode}})
                                Including VAT
                                <br>
                                ({{this.includingVATPriceToWords}} {{this.currency}}) </div>

                            <div class="date-location" style="width: 90%; margin: 0 auto; margin: 10px 0px;">
                                <table style="width: 80%; margin: 0 auto">
                                    <tbody>
                                        <tr>
                                            <td class="lbl"
                                                style="text-align: right; font-style: italic; padding: 10px 0px; font-size: 10px;"
                                                width="30%">Date &amp; Location:</td>
                                            <td class="ans" style="width: 50%;" width="40%"><span
                                                    style="border-bottom: 1px solid; display: inline-block; width: 100%;">&nbsp;</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="lbl"
                                                style="text-align: right; font-style: italic; padding: 10px 0px; font-size: 10px;"
                                                width="30%">Signature:</td>
                                            <td class="ans" style="width: 50%;" width="40%"><span
                                                    style="border-bottom: 1px solid; display: inline-block; width: 100%;">&nbsp;</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="hallmwark"
                            style="position: fixed; right: 0px; bottom: -40px; z-index: 0;background : #ffffff;"> <img
                                width="130" src="{{fabHallmarkUrl}}" /> </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" [disabled]="priceEvaluationFormGroup.value.printwithPriceOrVatPrice!=0 && priceEvaluationFormGroup.value.printwithPriceOrVatPrice!=1" (click)="printEvaluation()" class="btn btn-success"
                    printSectionId="print-evaluation" ngxPrint>Print</button>
            </div>
        </div>
    </div>
</div>
