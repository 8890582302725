import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SharedModule } from '../../shared/shared.module';
import { FileModule } from '../../shared/global/file/file.module';
import { UserViewComponent } from './view/user-view.component';
import { ClientRepairAddComponent } from '../client-repairs/add/client-repair-add.component';
import { ClientRepairEditComponent } from '../client-repairs/edit/client-repair-edit.component';
import { CompanyCustomerMappingEditComponent } from '../company-customer-mappings/edit/company-customer-mapping-edit.component';
import { CompanyCustomerMappingAddComponent } from '../company-customer-mappings/add/company-customer-mapping-add.component';
import { AddressAddComponent } from '../address/add/address-add.component';
import { AddressEditComponent } from '../address/edit/address-edit.component';
import { KnownToAddComponent } from '../known-tos/add/known-to-add.component';
import { KnownToEditComponent } from '../known-tos/edit/known-to-edit.component';
import { ExistsUserSearchComponent } from './search/exists-user-search.component';
import { KnownToColleguesViewComponent } from '../known-tos/view/known-to-colleagues-view.component';




@NgModule({
  imports: [FileModule,
    CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, SharedModule],
    declarations: [UserViewComponent,ClientRepairAddComponent,ClientRepairEditComponent,CompanyCustomerMappingEditComponent,CompanyCustomerMappingAddComponent,AddressAddComponent,AddressEditComponent,KnownToAddComponent,KnownToEditComponent,ExistsUserSearchComponent, KnownToColleguesViewComponent],
    exports:[UserViewComponent],
    entryComponents:[ClientRepairAddComponent,ClientRepairEditComponent,CompanyCustomerMappingEditComponent,CompanyCustomerMappingAddComponent,AddressAddComponent,AddressEditComponent,KnownToAddComponent,KnownToEditComponent,ExistsUserSearchComponent, KnownToColleguesViewComponent],
    providers: []
})
export class UserSharedModule { }

