import { prop, email } from '@rxweb/reactive-form-validators';

export class  UserLockoutFilterViewModel{
    @prop()
    lockoutFrom : Date;
    @prop()
    lockoutTo : Date;
    @prop()
    @email()
    email : String;
}
