import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RelatedProductDesignBase {

//#region relatedProductID Prop
        @prop()
        relatedProductID : number;
//#endregion relatedProductID Prop


//#region productID1 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID1 : number;
//#endregion productID1 Prop


//#region productID2 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID2 : number;
//#endregion productID2 Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop



}