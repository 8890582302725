import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalizationContent } from 'src/app/models/extended-models/localization-content';
import { AbstractFabergeLocalization } from '../domain/abstract-faberge-localization';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { LocalizationSearchModel } from 'src/app/view-model/localization-search-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { AppGrid } from 'src/app/domain/app-grid';
import { LocalizationContentViewModel } from 'src/app/view-model/localization-content-view-model';
import { Router } from '@angular/router';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { FabergeLocalizationAddComponent } from '../add/faberge-localization-add.component';
import * as CryptoJS from 'crypto-js';
import { Workbook } from 'exceljs';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { PlatformLocation } from '@angular/common';
import { FRONTSTORESERVER_URL } from 'src/app/domain/system-constant';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';

@multilingual("FabergeLocalizationListComponent")
@access({ accessLevel: RolePermissionEnums.FabergeLocalization, action: "get" })
@Component({
  selector: 'app-faberge-localization-list',
  templateUrl: './faberge-localization-list.component.html',
  styleUrls: ['./faberge-localization-list.component.css']
})
export class FabergeLocalizationListComponent extends AbstractFabergeLocalization implements OnInit {


  _this;

  constructor(private formBuilder:RxFormBuilder, modalView: ModalView, private router:Router,private title:Title, private location : PlatformLocation,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modalView;
    this.moduleID = RolePermissionEnums.FabergeLocalization;
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

   localizationSearchModel:LocalizationSearchModel;
   localizationSearchFormGroup: IFormGroup<LocalizationSearchModel>;
   localizationGrid:AppGrid;
   localizationAdd:any=FabergeLocalizationAddComponent;
   exportExcel = new ExportToExcelModel();

  localizationList:LocalizationContentViewModel[];
  isFilter:boolean=false;

  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
  moduleID:number;
  titleHelp:string;
  showSystemHelpIcon: boolean = false;

  ngOnInit() {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Faberge Localisation - G-Trac Admin");
      this.get({path:USER_LOOKUPS.languageLookUp}).subscribe(t=>{
        this.languageLookup=t;
        this.localizationSearchModel=new LocalizationSearchModel();
        this.localizationSearchModel.languageId=7;
        this.localizationSearchFormGroup=this.formBuilder.formGroup(LocalizationSearchModel,this.localizationSearchModel) as IFormGroup<LocalizationSearchModel>;
        this.showComponent=true;
        this.bindGrid();
      })
      this.titleHelp = getLocalizedValue("Title_Help");
      this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
}

  searchGrid(){
    this.spin=true;
    if(this.localizationGrid){
     this.localizationGrid.refresh("localization-content");
    }
    this.bindGrid();
  }

  // bindGrid(){
  //   this.isShowGrid=true;
  //   this.spin=true;
  //   this.get({queryParams:{languageId:7}}).subscribe((t:LocalizationContentViewModel[])=>{
  //        this.spin=false;
  //       this.localizationList=t;
  //       this.isShowGrid=this.localizationList.length>0?true:false;
  //       this.isNoRecordFound=this.localizationList.length==0?true:false;
  //       if(this.isShowGrid){
  //         if(this.localizationGrid){
  //           this.localizationGrid.refresh("localization-content");
  //          }
  //           this.localizationGrid=new AppGrid(this.localizationList,LocalizationContentViewModel,{ actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
  //           this.localizationGrid.componentId = this.componentName;
  //           setTimeout(() => {
  //             this.localizationGrid.design(document.getElementById("localization-content"));
  //           }, 500);
  //       }
  //   })
  // }


  bindGrid(){
    this.isShowGrid=true;
    this.spin=true;
    this.get({ hostUri: FRONTSTORESERVER_URL , queryParams:{languageId:this.localizationSearchFormGroup.value.languageId
      ,key:this.localizationSearchFormGroup.value.localizationKey==null || this.localizationSearchFormGroup.value.localizationKey==""?"":this.localizationSearchFormGroup.value.localizationKey,
      query:this.localizationSearchFormGroup.value.localizationValue==null || this.localizationSearchFormGroup.value.localizationValue==""?"":this.localizationSearchFormGroup.value.localizationValue,
    }}).subscribe((t:LocalizationContentViewModel[])=>{
      this.spin=false;
      this.localizationList=t;
      this.isShowGrid=this.localizationList.length>0?true:false;
      this.isNoRecordFound=this.localizationList.length==0?true:false;
      if(this.isShowGrid){
          this.localizationGrid=new AppGrid(this.localizationList,LocalizationContentViewModel,{ actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
          this.localizationGrid.gridColumns.forEach(x=>{
            if(this.localizationGrid.authorize({accessLevel:RolePermissionEnums.FabergeLocalization,action:"put"}) && this.localizationGrid.authorize({accessLevel:RolePermissionEnums.FabergeLocalization,action:"delete"})){
                if(x.name=="editAction"){
                    x.visible=true;
                }
                if(x.name=="deleteAction"){
                  x.visible=true;
              }
            }
            else{
                    if(x.name=="editAction"){
                        x.visible=this.localizationGrid.authorize({accessLevel:RolePermissionEnums.FabergeLocalization,action:"put"});
                    }
                    if(x.name=="deleteAction"){
                        x.visible=this.localizationGrid.authorize({accessLevel:RolePermissionEnums.FabergeLocalization,action:"delete"});
                    }
            }
        })
          this.localizationGrid.componentId = this.componentName;
          this.localizationGrid.design(document.getElementById("localization-content"));
      }
    })
  }
  exportToExcel(){
    this.spin = true;
    this.get({  hostUri: FRONTSTORESERVER_URL , queryParams:{languageId:this.localizationSearchFormGroup.value.languageId
      ,key:this.localizationSearchFormGroup.value.localizationKey==null || this.localizationSearchFormGroup.value.localizationKey==""?"":"",
      query:this.localizationSearchFormGroup.value.localizationValue==null || this.localizationSearchFormGroup.value.localizationValue==""?"":"",
    }}).subscribe((response:LocalizationContentViewModel[])=>{
        this.spin=false;
        this.localizationList=response;
            this.generatePiecesExcel();
    })
  }
  generatePiecesExcel(){
    this.spin = true;
        let returnedRequestData = this.requestExcelData(this.localizationList);
        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet();
        // Cell Style : Fill and Header
        var TodayDate = new Date();
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
        var FileName = "Faberge Localisation_" + MMDDYY;

        this.exportExcel.FileName = "FabergeLocalisation" + MMDDYY;
        this.exportExcel.Type = "Excel";
        this.exportExcel.Description="Faberge Localisation"

        // const headerRow = worksheet.addRow(header);
        worksheet.columns = [
            {header: 'ResourceName', key:'ResourceName',width:60},
            {header: 'ResourceValue', key:'ResourceValue',width:100}
            ] ;

        returnedRequestData.forEach(x=>{
            worksheet.addRow(x);
          });
        worksheet.getRow(1).eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '00000000'
                },
                bgColor: {
                    argb: '00000000'
                },
            };
            cell.font = {
                color: {
                    argb: 'FFFFFFFF',
                },
                bold: true
            }
            cell.border = {
                top: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            };
        });


        setTimeout(() => {
            this.spin = false;
            workbook.xlsx.writeBuffer().then(async (y: any) => {
              var u8 = new Uint8Array(y);
              var b64encoded = btoa(this.Uint8ToString(u8));

                const blob = new Blob([y], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                let uploadedBlobName = await this.uploadExcelToBlob(blob, "xlsx");
              // this.exportExcel.Result = b64encoded;
              this.exportExcel.BlobFileName = uploadedBlobName;
                this.post({ body:  this.exportExcel , path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
                    fs.saveAs(blob, Response);

                         })
            });
        }, 1000);

  }
  Uint8ToString(u8a){
    var CHUNK_SZ = 0x8000;
    var c = [];
    for (var i=0; i < u8a.length; i+=CHUNK_SZ) {
      c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
    }
    return c.join("");
  }
  requestExcelData(usersGrid: LocalizationContentViewModel[]) {
    let userListData = new Array<SaleSuccessData>();

    usersGrid.forEach(element => {
        let listData = new SaleSuccessData();
        listData["ResourceName"] = element.localizationKey;
        listData["ResourceValue"] = element.localizationValue;
        userListData.push(listData);
    });
    return userListData;
}
  onEdit(dutyStatuses: LocalizationContentViewModel) {
    let url : any;
    url = this.router.serializeUrl(this.router.createUrlTree(['/faberge-localization', this.localizationSearchFormGroup.value.languageId], { queryParams: { "key": dutyStatuses.localizationKey } }));
    redirectOnCTRL(url,this.router,event);
    //this.router.navigate(['/faberge-localization', this.localizationSearchFormGroup.value.languageId], { queryParams: { "key": dutyStatuses.localizationKey } });
}

onDelete(dutyStatuses: LocalizationContentViewModel) {
  let localizationObj=new LocalizationContent();
  localizationObj.languageId=dutyStatuses.languageId;
  localizationObj.localizationKey=dutyStatuses.localizationKey;
  localizationObj.localizationValue=dutyStatuses.localizationValue;
  localizationObj.oldKey=dutyStatuses.localizationKey;
  localizationObj.isDelete=true;
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
        if(t === DialogEnum.Ok)
        {
            this.dialog.hide();
            this.spin=true;
            this.put({  hostUri: FRONTSTORESERVER_URL,  params:[1],body:localizationObj }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
				              //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
               this.searchGrid();
            })
        }
        this.dialog.hide()
    })
}
DeleteConfirmationMessage() {
  var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_F_Localization");
  if (existsAlert) {
      return existsAlert[0].showMessage;
  }
}
search(value) {
  this.localizationGrid.search = value.target.value;
}
get componentName(): string {
  return "FabergeLocalizationListComponent";
}
}

class SaleSuccessData {
  ResourceName: string;
  ResourceValue: string;
}
class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
  Description:string;
  BlobFileName:string;
}
