import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vWarehousBase {

//#region warehouseId Prop
        @gridColumn({style: { width: "30%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'WarehouseListComponent_Id_gh', keyColumn: true})
        warehouseId : number;
//#endregion warehouseId Prop


//#region warehouseName Prop
        @gridColumn({isAscending:true,style: { width: "30%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'WarehouseListComponent_Name_gh', keyColumn: false})
        warehouseName : string;
//#endregion warehouseName Prop

//#region isWatchType Prop
@gridColumn({ template:{
        div:{
          style: {
           "word-break": "break-all",
        },
          attributes:{
            innerHTML:function(x,y){
             if(this.isWatchType)
             {
                return "<i class='fa fa-check' style='color : #28a745'></i>";
             }
             else
             {
                return "<i class='fa fa-times text-danger'></i>";
             }
            }
          },
        }
    },style: { width: "30%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 2, allowSorting: false, headerKey: 'WarehouseListComponent_IsWatchType_gh', keyColumn: false })
    isWatchType : boolean;
//#region isWatchType Prop

}