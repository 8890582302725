import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vManageInventoryMethodLookUpBase {

//#region manageInventoryMethodId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'manageInventoryMethodId', keyColumn: true})
        manageInventoryMethodId : number;
//#endregion manageInventoryMethodId Prop


//#region manageInventoryMethodName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'manageInventoryMethodName', keyColumn: false})
        manageInventoryMethodName : string;
//#endregion manageInventoryMethodName Prop

}