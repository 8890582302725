import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,    
    class: ["ownershipAction"],
    style:{"flex-shrink": 12,"text-align": "initial"}, visible: true, columnIndex: 14, headerTitle: ""
})

export class OwnerMappingChildViewModelBase {

    //#region expand Prop
    @gridColumn({style:{"flex-shrink": 12, "text-align": "initial"}, class: ["ownershipAction"], visible: true, allowSorting: false, columnIndex: 1, headerTitle: "", keyColumn: false })
    expand: string;
    //#endregion expand Prop

    //#region companyName Prop
  @gridColumn({configuredTemplate:{templateName:"corporateGroupLink"}, style: { "flex-grow": 14, "text-align": "initial"},class: ["ownership"],parameter:false, visible: true, columnIndex: 2, allowSorting: true, headerKey: 'OwnershipMappingListComponent_CompanyName_gh', keyColumn: false })
    companyName: string;
    //#endregion companyName Prop

    //#region userName Prop
    @gridColumn({ style: { "flex-grow": 14, "text-align": "initial" },class: ["ownership"],  configuredTemplate: { templateName:"reDirectPeopleAndTooltip" },parameter:false,visible: true, columnIndex: 3, allowSorting: true, headerKey: 'OwnershipMappingListComponent_PersonName_gh', keyColumn: false })
    userName: string;
    //#endregion userName Prop

    //#region stStartDate Prop
    @gridColumn({ style: { "flex-grow": 14, "text-align": "initial" },class: ["ownership"], parameter:false,visible: true, columnIndex: 4, allowSorting: true, headerKey: 'OwnershipMappingListComponent_StartDate_gh', keyColumn: false })
    stStartDate: string;
    //#endregion stStartDate Prop

    //#region stEndDate Prop
    @gridColumn({ style: { "flex-grow": 14, "text-align": "initial" },class: ["ownership"], parameter:false,visible: true, columnIndex: 5, allowSorting: true, headerKey: 'OwnershipMappingListComponent_EndDate_gh', keyColumn: false })
    stEndDate: string;
    //#endregion stEndDate Prop

    //#region ownershipPerc Prop
    @gridColumn({ style: { "flex-grow": 14, "text-align": "initial" },class: ["ownership"],parameter:false, visible: true, columnIndex: 6, allowSorting: true, headerKey: 'OwnershipMappingListComponent_OwnershipPerctage_gh', keyColumn: false })
    ownershipPerc: number;
    //#endregion ownershipPerc Prop

    companyContactPermissionRequired:boolean;
    companyIntroducesBy:string;
    isSupplierUnwelcome:boolean;
    contactPermissionRequired:boolean;
    introducesBy:string
    isUnwelcome:boolean
}
