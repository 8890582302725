import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { KnownTo, vUserKnownTo } from "@app/models";
import { AppGridDesigner } from '../../../../domain/app-grid';
import { AbstractKnownTo } from '../domain/abstract-known-to';
import { PlatformLocation } from "@angular/common";
import { RxSelectComponent } from "@rxweb/angular";
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { UserKnownToColleguesListViewModel } from "src/app/models/extended-models/user-known-to-collegues-list-view-model";
import { PaginationEnum } from '../../../../enums/pagination.enums';
import { PaginationViewModel } from '../../../../view-model/pagination-view-model';
@multilingual("KnownToColleguesViewComponent")

@Component({
    selector: "app-known-to-colleagues-view",
    templateUrl: './known-to-colleagues-view.component.html',
    entryComponents: [RxSelectComponent]
})
export class KnownToColleguesViewComponent extends AbstractKnownTo implements OnInit, OnDestroy {
    subscription: any;
    userKnownToGrid: AppGridDesigner;
    sort: boolean;
    userKnownToList: UserKnownToColleguesListViewModel[];
    json: any;
    userId: number;
    isSelect: boolean = true;
    paginationViewModel: PaginationViewModel;
    isFilter: boolean = false;
    totalRecords: number;
    gridSearchString: string = "";
    titleSearch: string;
    isShowGridColumn: boolean = false;
    isShowSearch : boolean = false;
    titleGridColumnSetting: string;
    knownToModuleId: number = RolePermissionEnums.User;
    arrConfig: any[];
    getColumnsData: any;
    expandTitle: string = "Expand";
    collapseTitle: string = "Collapse";
    @Input() selectedTab: string = "";
    titleClose: string;
    @Input() hide: Function;

    constructor(private activatedRoute: ActivatedRoute, private browserStorage: BrowserStorage, private router: Router, private componentResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, private location : PlatformLocation) {
        super()
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.titleClose = getLocalizedValue("Title_Close");
        this.paginationViewModel = new PaginationViewModel();
        this.paginationViewModel.sortOrder = "true";
        this.paginationViewModel.orderBy = "fullName";
        this.paginationViewModel.pageIndex = PaginationEnum.PageIndex;
        this.paginationViewModel.rowCount = PaginationEnum.RowCount;

        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.expandTitle = getLocalizedValue("Title_Expand");
        this.collapseTitle = getLocalizedValue("Title_Collapse");
        this.titleSearch = getLocalizedValue("Title_Search");

        this.userId = this.userId == undefined ? 0 : this.userId;
        this.totalRecords = 0;
        this.isFilter = false;
        this.sort = true;
        this.isShowGrid = false;
        this.bindGrid();
    }

    bindGrid(isOnload: boolean = true) {
        this.spin = true;
 
        var json = JSON.parse(JSON.stringify(this.paginationViewModel));;
        json["userId"] = this.userId;
        json["searchString"] = encodeURIComponent(this.gridSearchString);
        json["isUserKnownToColleguesList"] = "true";

        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.knownToModuleId)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
            this.subscription = this.get({ queryParams: { query: JSON.stringify(json) } }).subscribe((t: any) => {
                if (this.userKnownToGrid) {
                    this.userKnownToGrid.storeProcedure.length = 0;
                    this.userKnownToGrid.destroy();
                    this.userKnownToGrid = undefined;
                }
                this.userKnownToList = t;

                if (this.userKnownToList.length > 0) {
                    
                    this.userKnownToList.forEach(x => {
                        if (x.companyDetails != null && x.companyDetails != undefined && x.companyDetails.length > 0) {
                            x.company = this.createCorporateTable(x);
                        }
                    });

                    this.totalRecords = this.userKnownToList[0].totalCount;
                    this.isNoRecordFound = false;
                    if(isOnload && this.totalRecords > 0){
                        this.isShowSearch = true;
                        this.isShowGrid = true;
                    }
                   
                }
                else {
                    if (this.paginationViewModel.pageIndex > 1) {
                        this.paginationViewModel.pageIndex = 1;
                        this.bindGrid();
                    }
                    else {
                        this.isNoRecordFound = true;
                        this.isShowGrid = false;

                    }
                }
                if (this.isShowGrid == true) {
                    this.userKnownToGrid = new AppGridDesigner(this.userKnownToList, UserKnownToColleguesListViewModel, { actions: { onMoreClickProfile: this.onMoreClickProfile.bind(this),} }, this.componentResolver, this.viewContainerRef, this.isSelect);
                    this.userKnownToGrid.storeProcedure = {
                        length: this.totalRecords,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: !this.isFilter ? 1 : this.paginationViewModel.pageIndex,
                        onPageSorting: this.onPageSorting.bind(this)
                    }

                    if (this.arrConfig != undefined && this.arrConfig != null) {
                        this.arrConfig.forEach(x => {
                            this.userKnownToGrid.gridColumns.forEach(y => {
                                if (y.name == x.ColumnName)
                                    y.visible = x.Visible;

                            });
                        });
                    }
                    else {
                        let config = this.userKnownToGrid.gridColumns.map(x => { return { Visible: x.visible, ColumnName: x.name } });
                        this.arrConfig = config;
                    }

                    this.userKnownToGrid.currentPage = this.paginationViewModel.pageIndex;

                    this.userKnownToGrid.maxPerPage = this.paginationViewModel.rowCount;
                    this.userKnownToGrid.componentId = this.componentName;
                    this.userKnownToGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
                    this.userKnownToGrid.designClass.tableClass.push("chosen-drop-static");
                    this.userKnownToGrid.designClass.headerClass = "table-header-sticky".split(" ");
                }
                setTimeout(() => {
                    this.spin = false;
                }, 100);
            });
        });

    }

    onMoreClickProfile(t, x) {
        var element = document.getElementById("profile_" + t.knownToColleagueId);
        var maskedElement = document.getElementById("maskedprofile_" + t.knownToColleagueId);
        if (element.className == "collapse") {
            x.srcElement.innerText = "Show Less";
            x.srcElement.className = "less-btn"
            element.classList.add("show");
            maskedElement.setAttribute("style", "display:none");
        }
        else {
            element.classList.remove("show");
            x.srcElement.innerText = "Show More";
            x.srcElement.className = "more-btn";
            maskedElement.setAttribute("style", "display:inline");
        }
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;

        this.paginationViewModel.sortOrder = (!this.sort).toString();
        this.sort = !this.sort;
        this.paginationViewModel.orderBy = x;
        this.paginationViewModel.pageIndex = z;
        this.userKnownToGrid.storeProcedure.nextPage = z;
        this.bindGrid(false);
    }
    onPageChanging(x) {
        this.isFilter = true;

        this.paginationViewModel.pageIndex = x;
        this.paginationViewModel.rowCount = this.userKnownToGrid.maxPerPage;
        this.userKnownToGrid.storeProcedure.nextPage = x;
        this.bindGrid(false);
    }
    clearGrid() {
        this.userKnownToGrid.cnt = 0;
        this.userKnownToGrid.updateSource([]);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "KnownToColleguesViewComponent";
    }

    searchKnownTo(value) {
        setTimeout(() => {
            this.spin = false;
            this.isFilter = true;

            this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '').replace('&', '%26');
            this.totalRecords = 0;
            this.paginationViewModel.pageIndex = 1;
            this.bindGrid();
        }, 100);
        this.spin = true;
    }

    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }

    createCorporateTable(item: UserKnownToColleguesListViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table style='width: 100%;'><thead><tr><th>Corporate/Groups</th><th colspan='2'>Job Title</th><tr></thead><tbody>";
        let subTable: any;
        subTable = "<tr id='corporate_" + item.knownToColleagueId + "' style='display:none'><td colspan='3'><div class='accordian-body'><table><tbody>";
        if (item.companyDetails != null && item.companyDetails != undefined) {
            for (let i = 0; i < item.companyDetails.length; i++) {
                let x: any = item.companyDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.knownToColleagueId + "'";
                    row = row + `<tr>
                    <td style="width:65%">${x.companyUrl}${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default; data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
                    </td>
                    <td style="width:35%">${x.jobTitle == "" || x.jobTitle == null ? "" : `(${x.jobTitle})`}</td>`;

                    if(item.companyDetails.length>1){
                        row = row + `<td><i class='fa fa-chevron-down' title='${this.expandTitle}' id='icon_corporate_${item.knownToColleagueId}'
                        onclick='Window.KnownToListComponent.expandCorporateTable(${item.knownToColleagueId})'></i></td>`;
                    }

                    row = row + `</tr>`;
                }
                else {
                    subRow = subRow + `<tr><td style="width:65%">${x.companyUrl}${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default; data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
                    </td><td style="width:35%">${x.jobTitle == "" || x.jobTitle == null ? "" : `(${x.jobTitle})`}</td><td></td></tr>`;
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    expandCorporateTable(id: number) {
        var element = document.getElementById("corporate_" + id.toString());
        var iconElement = document.getElementById("icon_corporate_" + id.toString());
        this.changeIcon(element, iconElement);
    }

    private changeIcon(element: HTMLElement, iconElement: HTMLElement) {
        if (element != null) {
            if (element.style.display == "none") {
                element.style.display = "table-row";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-up ml-2";
                    iconElement.title = this.collapseTitle;
                }

            }
            else {
                element.style.display = "none";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-down ml-2";
                    iconElement.title = this.expandTitle;
                }
            }
        }
    }

}