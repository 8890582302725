import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

@actionColumn({
    allowSorting: false,
    style: { width: "5%", "text-align": "initial" },
    visible : true,
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})

export class LegalAgreementProjectListViewModel {

    @gridColumn({ style: { width: "0.1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    @gridColumn({ visible: false, columnIndex: 1, keyColumn: true })
    legalAgreementId: number;

    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'LegalAgreementProjMappingAddComponent_LAName_gh', keyColumn: false })
    legalAgreementName: string;

    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-word",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.summary;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.summaryCharCount > 50) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreSummaryClick"
                        }
                    },
                }]
            }
        }, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, headerKey : 'LegalAgreementProjMappingAddComponent_Summary_gh', keyColumn: false })
    summary: string;

    @gridColumn({ visible: false, columnIndex: 12, keyColumn: false })
    summaryCharCount: string;

    @gridColumn({ visible: false, columnIndex: 4, keyColumn: false })
    agreementTypeId: number;

    @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'LegalAgreementProjMappingAddComponent_Type_gh', keyColumn: false })
    agreementTypeName: string;

    @gridColumn({ parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, headerKey: 'LegalAgreementProjMappingAddComponent_Date_gh', keyColumn: false })
    date: string;

    @gridColumn({ visible: false, columnIndex: 7, keyColumn: false })
    leader: number;

    @gridColumn({ parameter: false, style: { width: "10%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, headerKey: 'LegalAgreementProjMappingAddComponent_Leader_gh', keyColumn: false, configuredTemplate: { templateName: "peopleNameAndTooltip" } })
    leaderName: string;

    @gridColumn({ parameter: false, style: { width: "15%", "min-width": "200px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, headerKey: 'LegalAgreementProjMappingAddComponent_Signed_gh', keyColumn: false, configuredTemplate: { templateName: "signedAgreementPDF" } })
    signedAgreement: string;

    @gridColumn({ parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, headerKey: 'LegalAgreementProjMappingAddComponent_IsSigned_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
    isSigned: boolean;

    @prop()
    roleId: number;

    @prop()
    totalCount: number;

    isAccess : boolean;
    userId:any;
    isUnwelcome:any;
    
}