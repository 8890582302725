import {
  UserBase,
  LanguageBase,
  GemstoneOrderBase,
  POSMemberMappingBase,
  UserDetailBase,
  BespokeOrderBase,
  BookingGemstoneGemFieldBase,
  IncidentPersonsAffectedBase,
  ClientRepairBase,
  TrackIncidentBase,
  BespokeOrderCustomerMappingBase,
  AddressBase,
  KnownToBase,
  BoutiqueProductMappingBase,
  ActivityLogBase,
  BookingGemstoneBase,
  UserRoleBase,
  UserEmailSettingBase,
  SubscriptionBase,
} from "@app/database-models";
//Generated Imports
import {
  propArray,
  propObject,
  prop,
  maxLength,
  alpha,
  trim,
  required,
} from "@rxweb/reactive-form-validators";
import { UserRole } from "./user-role";
import { FileDetailViewModel } from "../../view-model/file-detail-view-model";
import { Subscription } from "rxjs";
import { UserEmailSetting } from "./user-email-setting";
import { UserPassportDetailBase } from "../database-models/user-passport-detail-base";
export class User extends UserBase implements FileDetailViewModel {
  constructor() {
    super();
    (this.isTaxExempt = false),
      (this.isAdmin = false),
      (this.isGuest = false),
      (this.isForumModerator = false),
      (this.deleted = false),
      (this.isBanOrders = false),
      (this.contactPermissionRequired = false),
      (this.isUnwelcome = false),
      (this.loginBlocked = false);
    this.userRoles = new Array<UserRole>();
  }

  @prop()
  @maxLength({ value: 100 })
  // @alpha({ allowWhiteSpace: true })
  @trim()
  stateName: string;

  @prop()
  @maxLength({ value: 100 })
  // @alpha({ allowWhiteSpace: true })
  @trim()
  homeStateName: string;

  @prop()
  filePath: string;

  @prop({ defaultValue: false })
  accountActivation: boolean;

  @propArray(UserRole)
  userRoles: UserRole[];

  @propArray(UserRole)
  adminRoles: UserRole[];

  @propArray(SubscriptionBase)
  subscriptions: SubscriptionBase[];

  @propArray(UserEmailSettingBase)
  userEmailSettings: UserEmailSettingBase[];
  //#region salesPersonName Prop

  salesPersonName: string;
  //#endregion salesPersonName Prop

  //#region showClientRepair Prop

  showClientRepair: boolean;
  //#endregion showClientRepair Prop

  //#region clientRepairCount Prop

  @prop({ defaultValue: 0 })
  clientRepairCount: number;
  //#endregion clientRepairCount Prop

  //#region bespokeOrderCount Prop
  @prop({ defaultValue: 0 })
  bespokeOrderCount: number;
  //#endregion bespokeOrderCount Prop

  @prop({ defaultValue: false })
  isPasswordExpired: boolean;

  @prop({ defaultValue: false })
  canDelete: boolean;

  @prop({ defaultValue: false })
  userBooking: boolean;

  @prop({ defaultValue: false })
  userSales: boolean;

  //#region Generated Reference Properties
  //#region language Prop
  @propObject(LanguageBase)
  language: LanguageBase;
  //#endregion language Prop

  //#region gemstoneOrders Prop
  @propArray(GemstoneOrderBase)
  gemstoneOrders: GemstoneOrderBase[];
  //#endregion gemstoneOrders Prop

  //#region pOSMemberMappings Prop
  @propArray(POSMemberMappingBase)
  pOSMemberMappings: POSMemberMappingBase[];
  //#endregion pOSMemberMappings Prop

  //#region userDetails Prop
  @propArray(UserDetailBase)
  userDetails: UserDetailBase[];
  //#endregion userDetails Prop

  //#region UserPassportDetails Prop
  @propArray(UserPassportDetailBase)
  userPassportDetails: UserPassportDetailBase[];
  //#endregion UserPassportDetails Prop

  //#region bespokeOrders Prop
  @propArray(BespokeOrderBase)
  bespokeOrders: BespokeOrderBase[];
  //#endregion bespokeOrders Prop

  //#region bookingGemstoneGemFields Prop
  @propArray(BookingGemstoneGemFieldBase)
  bookingGemstoneGemFields: BookingGemstoneGemFieldBase[];
  //#endregion bookingGemstoneGemFields Prop

  //#region incidentPersonsAffected Prop
  @propArray(IncidentPersonsAffectedBase)
  incidentPersonsAffected: IncidentPersonsAffectedBase[];
  //#endregion incidentPersonsAffected Prop

  //#region clientRepairs Prop
  @propArray(ClientRepairBase)
  clientRepairs: ClientRepairBase[];
  //#endregion clientRepairs Prop

  //#region trackIncidents Prop
  @propArray(TrackIncidentBase)
  trackIncidents: TrackIncidentBase[];
  //#endregion trackIncidents Prop

  //#region bespokeOrderCustomerMappings Prop
  @propArray(BespokeOrderCustomerMappingBase)
  bespokeOrderCustomerMappings: BespokeOrderCustomerMappingBase[];
  //#endregion bespokeOrderCustomerMappings Prop

  //#region address Prop
  @propArray(AddressBase)
  address: AddressBase[];
  //#endregion address Prop

  //#region knownTos Prop
  @propArray(KnownToBase)
  knownTos: KnownToBase[];
  //#endregion knownTos Prop

  //#region boutiqueProductMappings Prop
  @propArray(BoutiqueProductMappingBase)
  boutiqueProductMappings: BoutiqueProductMappingBase[];
  //#endregion boutiqueProductMappings Prop

  //#region activityLogs Prop
  @propArray(ActivityLogBase)
  activityLogs: ActivityLogBase[];
  //#endregion activityLogs Prop

  //#region bookingGemstones Prop
  @propArray(BookingGemstoneBase)
  bookingGemstones: BookingGemstoneBase[];
  //#endregion bookingGemstones Prop

  //#endregion Generated Reference Properties

  @prop()
  fileData: string;
  @prop()
  fileExtention: string;
  @prop()
  fileName: string;
  @prop()
  displayImage: boolean;
  @prop()
  imageURL1: string;
  @prop()
  imageURL2: string;
  @prop()
  imageURL3: string;
  @prop()
  imageURL4: string;
  @prop()
  imageURL5: string;
  @prop()
  fileData1: string;
  @prop()
  fileExtention1: string;
  @prop()
  fileName1: string;
  @prop()
  fileData2: string;
  @prop()
  fileExtention2: string;
  @prop()
  fileName2: string;

  //#region showClientRepair Prop
  keyRelationshipHolderName: string;
  //#endregion showClientRepair Prop
  @prop({ defaultValue: false })
  isImageRemoved: boolean;

  //#region showGoalTab Prop
  showGoalTab: boolean;
  //#endregion showGoalTab Prop

  //#region personalDetailTabEdit Prop
  @prop({ defaultValue: false })
  personalDetailTabEdit: boolean;
  //#endregion personalDetailTabEdit Prop

  @prop({ defaultValue: false })
  isRoleUpdatedFromInfo: boolean;

  @required()
  @prop({defaultValue : 0})
  roleTypeIdFromInfoPage: number;

  @prop()
  blobFileName: string;
  @prop()
  blobPath: string;
  @prop()
  size: number;
  @prop()
  blobFileName2: string;
  @prop()
  blobPath2: string;
  @prop()
  size2: number;
  @prop()
  rawShopifyId:string;
}
