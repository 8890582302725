import { gridColumn, actionColumn } from '@rxweb/grid';

@actionColumn({
  allowSorting: false,
  style: { width: "2%" },
  configuredTemplate: { templateName: 'saveandnoteReplyButton' }, columnIndex: 14, headerTitle: "Action"
})
export class TaskDetailsViewModel {

  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 0, headerTitle: 'TaskId', keyColumn: true, allowSorting: false })
  taskDetailId: number;

  @gridColumn({
    template: {
      div: {
        childrens: [
          {
            a: {
              class: "text-blue font-weight-bold".split(" "),
              event: {
                click: "onOneLink"
              },
              childrens: [{
                text: {
                  text: function (e) {
                    return "Task #" + this.taskDetailId
                  }
                }
              }],
              attributes: {
                href: function (e) {
                  if (this.moduleDetail != null || this.moduleDetail != undefined) {
                    let url: any;
                    let moduleData = JSON.parse(this.moduleDetail)
                   // let url = "/" + moduleData.module + "/" + moduleData.entityId + "?activeTab=tasks";
                    if(moduleData.module=='events' || moduleData.module=='projects'){
                      url = "/" + moduleData.module + "/" + moduleData.entityId + "?activeTab=tasks&tabChange=true";
                    }
                    else{
                      url = "/" + moduleData.module + "/" + moduleData.entityId + "?activeTab=tasks";
                    }
                    return url
                  }
                },
                "ng-reflect-router-link": function (e) {
                  if (this.moduleDetail != null || this.moduleDetail != undefined) {
                    let moduleData = JSON.parse(this.moduleDetail)
                    let url: any;
                     if(moduleData.module=='events' || moduleData.module=='projects'){
                      url = "/" + moduleData.module + "/" + moduleData.entityId + "?activeTab=tasks&tabChange=true";
                    }
                    else{
                      url = "/" + moduleData.module + "/" + moduleData.entityId + "?activeTab=tasks";
                    }
                    return url
                  }
                },
                onClick: "return false;"
              }
            }
          }
        ]
      },
    }, parameter: false, style: { width: "8%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 1, headerKey: 'TaskDetail_TaskId_gh', keyColumn: false, allowSorting: true
  })
  taskDetailIdLink: string;

  @gridColumn({
    configuredTemplate:{templateName:'taskModal'}, parameter: false, style: { width: "8%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 2, headerKey: 'TaskDetail_TaskId_gh', keyColumn: false ,allowSorting:true})
  taskDetailIdText: string;

  @gridColumn({
    template: {
      div: {
        style: {
          "width": "100%",
          "word-break": "break-word"
        },
        attributes: {
          innerHTML: function (x, y) {
            return "<label style='margin-left:0%;margin-bottom:0%'>" + this.taskDescription.replaceAll('\n', '<br/>') + "</label>";
          }
        }
      }
    },
  parameter: false, style: { width: "18%", "text-align": "left" }, class: ["text-left", "word-break"], visible: false, columnIndex: 3, headerKey: 'TaskDetail_TaskDescription_gh', keyColumn: false, allowSorting: true })
  taskDescription: string;

  @gridColumn({
    template: {

      div: {
        style: {
          "width": "100%",
          "word-break":"break-word"
        },
        attributes: {
          innerHTML: function (x, y) {
            return "<label style='margin-left:0%;margin-bottom:0%'>" + this.description + "</label>";
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.descriptionCount > 100) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                style: {
                  "margin-left": "0%"
                },
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClick"
            }
          },
        }]
      }
    }
    ,parameter: false, style: { width: "18%", "text-align": "left" }, class: ["text-left", "word-break"], visible: true, columnIndex: 3, headerKey: 'TaskDetail_TaskDescription_gh', keyColumn: false, allowSorting: true })
  description: string;

  @gridColumn({
    template: {
      div: {
        style: { "word-break": "break-word;" },
        attributes: {
          innerHTML: function (x, y) {
            return this.noteDescription;
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.noteDescriptionCount > 100) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreNoteClick"
            }
          },
        }]
      }
    }, style: { width: "14%", "text-align": "left" }, class: ["text-left", "word-break"], visible: false, columnIndex: 4, allowSorting: false, headerKey: 'TaskDetail_NoteDescription_gh', keyColumn: false
  })
  noteDescription: string;

  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "left" }, class: ["text-left", "break-all"], visible: true, columnIndex: 5, headerKey: 'TaskDetail_AssignedBy_gh', keyColumn: false, allowSorting: true })
  assignedByUserName: string;
  assignedByUserId: number;

  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "left" }, class: ["text-left", "break-all"], visible: true, columnIndex: 6, headerKey: 'TaskDetail_AssignedTo_gh', keyColumn: false, allowSorting: true })
  assignedToUserName: string;
  assignedToUserId: number;

  @gridColumn({
    configuredTemplate: { templateName: 'moduleName' },parameter: false, style: { width: "10%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 7, headerKey: 'TaskDetail_ModuleName_gh', keyColumn: false, allowSorting: true
  })
  moduleDetail: any;
  module:string;

  @gridColumn({ parameter: false, style: { width: "12%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 8, headerKey: 'TaskDetail_CreatedAt_gh', keyColumn: false, allowSorting: true })
  createdOnString: string;
  createdOn: Date;

  completionDate: Date;
  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.completionDateString;
          }
        }
      }
    }, parameter: false, style: { width: "10%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 9, headerKey: 'TaskDetail_CompletionDate_gh', keyColumn: false, allowSorting: true
  })
  completionDateString: true;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 10, headerKey: 'TaskDetail_CompletedAt_gh', keyColumn: false, allowSorting: true })
  completedAt: Date;

  @gridColumn({ configuredTemplate: { templateName: 'taskStatus' }, parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, headerKey: 'TaskDetail_TaskStatus_gh', keyColumn: true, allowSorting: true })
  taskStatus: string;

  @gridColumn({ configuredTemplate: { templateName: 'taskStatusCheckbox' }, parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 12, headerKey: 'TaskDetail_IsCompleted_gh', keyColumn: false, allowSorting: false })
  isCompleted: boolean;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 13, headerKey: 'TaskDetail_CheckedAt_gh', keyColumn: false, allowSorting: true })
  CheckedAt: string;

  totalCount: number;
  totalTime: number;
  noteType: string;
  noteSubject: string;
  noteCreatedBy: string;
  isCompletedChecked: boolean
  noteId: number;
  descriptionCount: number;
  noteDescriptionPdf: string;
  noteDescriptionCount: number;
  commentsCount:number;

}
