import {vGemfieldsArrangementLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemfieldsArrangementLookUp extends vGemfieldsArrangementLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}