import {ProductPiecePictureBase,PictureBase,ProductPieceBase,} from '@app/database-models'
//Generated Imports
export class ProductPiecePicture extends ProductPiecePictureBase 
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region productPiece Prop
productPiece : ProductPieceBase;
//#endregion productPiece Prop

//#endregion Generated Reference Properties











}