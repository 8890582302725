import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventTeamMappingBase {

//#region eventTeamID Prop
        @prop()
        eventTeamID : number;
//#endregion eventTeamID Prop


//#region eventID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        eventID : number;
//#endregion eventID Prop


//#region teamID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        teamID : number;
//#endregion teamID Prop



}