import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractStateProvince } from '../domain/abstract-state-province';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute,Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { StateProvince } from '@app/models';
import { ModalView } from '../../../../domain/customize-design/modal';
import { LanguagesEnums } from '../../../../custom-enum/language-enums';
import { StatusEnum } from '../../../../enums/status.enum';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@access({ accessLevel: RolePermissionEnums.StateProvince, action: "put" })

@multilingual("StateProvinceEditComponent")
@Component({
    selector: "app-state-province-edit",
    templateUrl: './state-province-edit.component.html'
})
export class StateProvinceEditComponent extends AbstractStateProvince implements OnInit, OnDestroy {
    stateProvince: StateProvince;
    subscription: any;
    id: number;
    languageId: number = LanguagesEnums.English;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    stateProvinceDelete:any=dbAccessModule[RolePermissionEnums.StateProvince]["delete"];
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,private applicationBroadcaster:ApplicationBroadcaster,private router:Router, modelView: ModalView, private el: ElementRef, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.activatedRoute.params.subscribe(t => {
            if (t['languageId']) {
                this.languageId = t['languageId'];
            }
        })
        this.moduleID = RolePermissionEnums.StateProvince;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("States/Provinces - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        // this.lookup([{ params: [this.id], propName:"stateProvince" }, {  path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" }]).subscribe((lookup: any) => {
        //     if (lookup.stateProvince) {
        //         this.countryLookup = lookup;
        //         this.stateProvinceFormGroup = this.formBuilder.formGroup(StateProvince, lookup.stateProvince) as IFormGroup<StateProvince>;
        //         this.showComponent = true;
        //     }
        //     else {
        //         this.router.navigate(['/state-provinces']);
        //     }
        //
        // })
        this.stateProvince = new StateProvince();
        this.changeLanguage(this.languageId);
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editStateProvince(isRedirect:boolean)
    {    this.stateProvinceFormGroup.submitted = true;
        if (this.stateProvinceFormGroup.valid) {
            this.spin = true;
            if (this.stateProvinceFormGroup.value.parentStateProvinceId > 0 && this.stateProvinceFormGroup.value.stateProvinceId == 0) {

                this.post({ body: this.stateProvinceFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    this.stateProvinceFormGroup.patchValue({ stateProvinceId: data });
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.changeLanguage(this.languageId) : this.router.navigate(['/state-provinces']);
                })
            }
            else {
                this.put({ body: this.stateProvinceFormGroup.value, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.changeLanguage(this.languageId) : this.router.navigate(['/state-provinces']);
                })
            }

        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.stateProvinceFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }
    deleteStateProvince() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/state-provinces']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_StateorProvince");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    changeLanguage(languageId: number) {
        this.showComponent=false;
        this.languageId = languageId;
        this.lookup([{ params: [this.id], queryParams: { "languageId": languageId }, propName: "stateProvince" },
                     { path: USER_LOOKUPS.languageLookUp, propName: "languageLookUp" },
                     {  path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" }]).subscribe((lookup: any) => {

            if (lookup.stateProvince) {
                this.stateProvinceLookup=[];
                this.stateProvinceLookup = lookup;
                this.stateProvince=lookup.stateProvince as StateProvince;
                this.stateProvinceFormGroup = this.formBuilder.formGroup(StateProvince, lookup.stateProvince) as IFormGroup<StateProvince>;
                this.showComponent = true;
            }
            else {

                this.stateProvinceLookup=[];
                this.stateProvinceLookup = lookup;
                this.stateProvince.name="";
                this.stateProvince.stateProvinceId = 0;
                this.stateProvince.languageId = languageId;
                this.stateProvince.parentStateProvinceId = this.id;
                this.stateProvince.statusId = StatusEnum.Active;
                this.stateProvinceFormGroup = this.formBuilder.formGroup(StateProvince,this.stateProvince) as IFormGroup<StateProvince>;
                this.showComponent = true;
            }


        })
    }

    get componentName(): string {
      return "StateProvinceEditComponent";
    }
}
