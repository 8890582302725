import {vCollectionRecordBase,} from '@app/database-models'
//Generated Imports
export class vCollectionRecord extends vCollectionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}