import {IndustryTypeBase,} from '@app/database-models'
//Generated Imports
export class IndustryType extends IndustryTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}