import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLocationGroupRecordBase {

//#region locationGroupId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'locationGroupId', keyColumn: true})
        locationGroupId : number;
//#endregion locationGroupId Prop


//#region locationGroupTitle Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'locationGroupTitle', keyColumn: false})
        locationGroupTitle : string;
//#endregion locationGroupTitle Prop

}