import { actionColumn, gridColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'ianAddAction' }, columnIndex: 18, headerTitle: "Action"
 })

export class GemfieldRiskAddViewModel {

    //#region isChecked Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerKey: '', keyColumn: false })
    isChecked: boolean;
    //#endregion isChecked Prop

    //#region specificRiskId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 1, allowSorting: false, headerKey: '', keyColumn: true })
    specificRiskId: number;
    //#endregion specificRiskId Prop

    //#region companyId Prop
    @gridColumn({ visible: false, columnIndex: 2, keyColumn: false })
    companyId: number;
    //#endregion companyId Prop

    //#region company Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_GemfieldsGroupCompany_gh', keyColumn: false })
    company: number;
    //#endregion company Prop

    //#region riskCategoryId Prop
    @gridColumn({ visible: false, columnIndex: 4, keyColumn: false })
    riskCategoryId: number;
    //#endregion riskCategoryId Prop

    //#region riskCategoryName Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'RiskMenuDetailAddComp_RiskCategory_gh', keyColumn: false })
    riskCategoryName: string;
    //#endregion riskCategoryName Prop

    //#region specificRiskName Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'RiskMenuDetailAddComp_SpecificRiskName_gh', keyColumn: false })
    specificRiskName: string;
    //#endregion specificRiskName Prop

    //#region isRelevant Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-centre"], visible: true, columnIndex: 7, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_IsRelevant_gh', keyColumn: false, configuredTemplate: { templateName: 'isReleventSelect' } })
    isRelevant: boolean;
    //#endregion isRelevant Prop

    //#region likelihood Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_Likelihood_gh', keyColumn: false, configuredTemplate: { templateName: 'gemfieldRiskLikelihood' } })
    likelihood: number;
    //#endregion likelihood Prop

    //#region impact Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_Impact_gh', keyColumn: false, configuredTemplate: { templateName: 'gemfieldRiskImpact' } })
    impact: number;
    //#endregion impact Prop

    //#region riskIndex Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_RiskIndex_gh', keyColumn: false })
    riskIndex: string;
    //#endregion riskIndex Prop

    //#region keyId Prop
    @gridColumn({ visible: false, columnIndex: 11, keyColumn: false })
    keyId: number;
    //#endregion keyId Prop

    //#region lookupData Prop
    @gridColumn({
        template: {
            div: {
                onDemandSelector: {
                    selector: 'rx-select', columnName: 'lookupData'
                }
            }
        }, name: "lookupData", parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left", "pt-0"], visible: true, columnIndex: 12, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_LocalRiskOwner_gh', keyColumn: false
    })
    lookupData: any;
    //#region lookupData Prop

    //#region keyId Prop
    @gridColumn({ visible: false, columnIndex: 13, keyColumn: false })
    keyId1: number;
    //#endregion keyId Prop

    //#region lookupData Prop
    @gridColumn({
        template: {
            div: {
                onDemandSelector: {
                    selector: 'rx-select', columnName: 'lookupData1'
                }
            }
        }, name: "lookupData1", parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left", "pt-0"], visible: true, columnIndex: 14, allowSorting: false, headerKey: 'RiskMenuDetailAddComp_BoardRiskOwner_gh', keyColumn: false
    })
    lookupData1: any;
    //#region lookupData Prop

    //#region riskCauses Prop
    @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
    riskCauses: string;
    //#endregion riskCauses Prop

    //#region riskConsequences Prop
    @gridColumn({ visible: false, columnIndex: 16, keyColumn: false })
    riskConsequences: string;
    //#endregion riskConsequences Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop
}