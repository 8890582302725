import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHSEUpdateStatusLookupBase {

//#region customerId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerId', keyColumn: false})
        customerId : string;
//#endregion customerId Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop

}