import { Component, OnInit, Input } from '@angular/core';
import { BulkUploadUser } from 'src/app/models/extended-models/bulk-upload-user';
import * as moment from 'moment';
import { AbstractImportUserFromExcel } from '../../domain/abstract-import-user-from-excel';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { List } from '@rxweb/generics';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@multilingual("ErrorImportingUserComponent")
@Component({
  selector: 'app-error-importing-user',
  templateUrl: './error-importing-user.component.html',
  styleUrls: ['./error-importing-user.component.css']
})
export class ErrorImportingUserComponent extends AbstractImportUserFromExcel implements OnInit {
  @Input() errorUserList: BulkUploadUser[];
  @Input() hide: Function;
  userList: BulkUploadUser[];
  exportExcel = new ExportToExcelModel();
  TodayDate = new Date();
  //@Input() hide: Function;
  MMDDYY = moment(this.TodayDate).format('MMDDYY').toString();
  titleClose: string;
  userLookUp:any;

  constructor(private location : PlatformLocation) { 
    super();
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.showComponent = true;
    console.log("Called");
    this.userList = this.errorUserList;
    console.log(this.userList)
  }

  ExportToExcelErrorData() {
    //this.spin = true;
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    this.exportExcel.FileName = "User" + MMDDYY + ".xlsx";
    this.exportExcel.Type = "Excel";

    let returnedRequestData = this.requestExcelData(this.userList);

    //let gemstoneDetailsImportobj: ProductPieceGemstone[] = [];

    let stringData = JSON.stringify(returnedRequestData);
    var jsonData = JSON.parse(stringData);
    this.exportAsExcelFile(jsonData, 'ErrorData');

  }
  requestExcelData(userList: any){
    let userListData = new Array<UserListData>();
    userList.forEach(element => {
      let listData = new UserListData();
      listData["FirstName"] = element.FirstName;
      listData["MiddleName"] = element.MiddleName;
      listData["LastName"] = element.LastName;
      listData["UserRoleType"] = element.UserRoleType;
      if(element.RoleId == 0){
        listData["RoleId"]='';
      }
      else{
        listData["RoleId"] = element.RoleId;
      }  
      listData["PrimaryEmailAddress"] = element.PrimaryEmailAddress;
      listData["SecondaryEmailAddress"] = element.SecondaryEmailAddress;
      listData["MobileTelephone"] = element.MobileNumber;
      listData["HomeTelephone"] = element.PhoneNumber;
      listData["WorkTelephone"] = element.WorkPhoneNumber;
      listData["StreetAddress"] = element.StreetAddress;
      listData["StreetAddress2"] = element.StreetAddress2;
      listData["City"] = element.City;
      if(element.CountryId == 0){
        listData["Country"]='';
      }
      else{
        listData["Country"] = element.CountryId;
      }   
      listData["ZipPostalcode"] = element.ZipPostalCode;
      if(element.ContactPermissionRequired == true){
        listData["SensitivecontactPermissionRequiredtoContact"] = 'Yes';
      }
      else{
        listData["SensitivecontactPermissionRequiredtoContact"] = 'No';
      }
      userListData.push(listData);
    });
    return userListData;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void{
    this.lookup([{ path: USER_LOOKUPS.countryLookUp, propName:"countryLookUp"}, { path: USER_LOOKUPS.roleLookUpExcel, propName: "roleLookUp" }]).subscribe((response:any)=>{
    this.userLookUp = response;
    let adminRole = this.userLookUp.roleLookUp.filter(x=>x.roleType == 9);
    let customerRole = this.userLookUp.roleLookUp.filter(x=>x.roleType ==7);
    this.spin = false;
    let requiredData =[{
      "":""

    }]

    let countries = JSON.stringify(this.userLookUp.countryLookUp);
    let adminRoles = JSON.stringify(adminRole);
    let customerRoles = JSON.stringify(customerRole);
    const worksheetUserData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const worksheetAdminRoleData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(adminRoles));
    const worksheetCustomerRoleData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(customerRoles));
    const worksheetCountry: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(countries));
    const worksheetRequiredData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(requiredData);

    var wscolsUsers = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 40 },
    ];
    var wscols =[
      { wch: 20 },
      { wch: 13 },
      { wch: 130 }
    ]
    var wscolsRole=[
      {wch:20},
      {wch:40},
      {wch:20},
      {wch:20}
    ]

    worksheetUserData['!cols'] = wscolsUsers;
    worksheetCountry['!cols'] = wscolsRole;   
    worksheetAdminRoleData['!cols'] = wscolsRole;
    worksheetCustomerRoleData['!cols'] = wscolsRole;  
    worksheetRequiredData['!cols'] = wscols;
    // worksheetRequiredData['A2'].v = 'First Name';
    // worksheetRequiredData['A1'].h = {
    //   fill: {
    //     patternType: "none", // none / solid
    //     fgColor: { rgb: "FF000000" },
    //     bgColor: { rgb: "FFFFFFFF" }
    //   }
    // }
    XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Files Supported :']], {origin: 'A2'});
    XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['.xls']], { origin: 'B2' });
    XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['.xlsx']], { origin: 'B3' });
    XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['.csv']], { origin: 'B4' });

    XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Required Fields : ']], { origin: 'A6' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['First Name']], { origin: 'B6' });
    //  XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Middle Name']], { origin: 'B7' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Last Name']], { origin: 'B7' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['User Role Type']], { origin: 'B8' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Role ID']], { origin: 'B9' });


     var userFirstName = getLocalizedValue("UserFirstName");
     if (userFirstName) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userFirstName]], { origin: 'C6' });
     }

    //  var userMiddleName = getLocalizedValue("UserMiddleName");
    //  if (userMiddleName) {
    //     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userMiddleName]], { origin: 'C7' });
    //  }
     var userLastName = getLocalizedValue("UserLastName");
     if (userLastName) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userLastName]], { origin: 'C7' });
     }
     var userRoleType = getLocalizedValue("UserRoleType");
     if (userRoleType) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userRoleType]], { origin: 'C8' });
     }
     var roleId = getLocalizedValue("RoleId");
     if (roleId) {
        XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[roleId]], { origin: 'C9' });
     }
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['Additional Information : ']], { origin: 'A11' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['1)']], { origin: 'B11' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['2)']], { origin: 'B12' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['3)']], { origin: 'B13' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['4)']], { origin: 'B14' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['4a)']], { origin: 'B15' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['4b)']], { origin: 'B16' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['5)']], { origin: 'B17' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['6)']], { origin: 'B18' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['7)']], { origin: 'B19' });
     XLSX.utils.sheet_add_aoa(worksheetRequiredData, [['8)']], { origin: 'B20' });


    var userReadMe_Info_1 =getLocalizedValue("UserReadMe_Info_1");
    if (userReadMe_Info_1) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_1]], { origin: 'C11' });
    }

    var userReadMe_Info_2 =getLocalizedValue("UserReadMe_Info_2");
    if (userReadMe_Info_2) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_2]], { origin: 'C12' });
    }

    var userReadMe_Info_3 =getLocalizedValue("UserReadMe_Info_3");
    if (userReadMe_Info_3) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_3]], { origin: 'C13' });
    }

    var userReadMe_Info_4 =getLocalizedValue("UserReadMe_Info_4");
    if (userReadMe_Info_4) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_4]], { origin: 'C14' });
    }

    var userReadMe_Info_4a =getLocalizedValue("UserReadMe_Info_4a");
    if (userReadMe_Info_4a) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_4a]], { origin: 'C15' });
    }

    var userReadMe_Info_4b =getLocalizedValue("UserReadMe_Info_4b");
    if (userReadMe_Info_4b) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_4b]], { origin: 'C16' });
    }
    
    var userReadMe_Info_5 =getLocalizedValue("UserReadMe_Info_5");
    if (userReadMe_Info_5) {       
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_5]], { origin: 'C17' });
    } 
    var userReadMe_Info_6 = getLocalizedValue("UserReadMe_Info_6");
    if (userReadMe_Info_6) {       
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_6]], { origin: 'C18' });
    } 
    var userReadMe_Info_7 = getLocalizedValue("UserReadMe_Info_7");
    if (userReadMe_Info_7) {       
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_7]], { origin: 'C19' });
    }
    var userReadMe_Info_8 =getLocalizedValue("UserReadMe_Info_8");
    if (userReadMe_Info_8) {
       XLSX.utils.sheet_add_aoa(worksheetRequiredData, [[userReadMe_Info_8]], { origin: 'C20' });
    }

    const workbook: XLSX.WorkBook = { Sheets: { 'ReadMe':worksheetRequiredData, 'UserInfo': worksheetUserData, 'AdminRoles':worksheetAdminRoleData,'CustomerRoles':worksheetCustomerRoleData, 'Countries': worksheetCountry }, SheetNames: ['ReadMe','UserInfo','AdminRoles','CustomerRoles','Countries'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    })
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + ' Users' + this.MMDDYY + EXCEL_EXTENSION);
  }


}
class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
}
class UserListData {
  "FirstName": string;
  "MiddleName": string;
  "LastName": string;
  "UserRoleType": string;
  "RoleId": string;
  "PrimaryEmailAddress": string;
  "SecondaryEmailAddress": string;
  "MobileTelephone": string;
  "HomeTelephone": string;
  "WorkTelephone": string;
  "StreetAddress": string;
  "StreetAddress2": string;
  "City": string;
  "Country": string;
  "ZipPostalcode": string;
  "SensitivecontactPermissionRequiredtoContact": string;
}
