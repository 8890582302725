import { Component, Input, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { Subscription } from '@app/models';
import { CoreComponent } from '@rxweb/angular-router';
import { GridColumnConfig } from '@rxweb/grid';
import { http } from '@rxweb/http';
import { multilingual } from '@rxweb/localization';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppGrid } from 'src/app/domain/app-grid';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { PressMediaSearchModel } from 'src/app/view-model/press-media-search-model';
import { PressMediaViewSearchModel } from 'src/app/view-model/press-media-view-search-model';
import { PressMediaViewViewModel } from 'src/app/view-model/press-media-view-view-model';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { DialogViewMode } from '@rxweb/js';
import { BaseDialog, DialogEnum } from 'src/app/domain/customize-design/dialog';
import { PressMediaViewAddComponent } from './press-media-view-add/press-media-view-add.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { PlatformLocation } from '@angular/common';
@multilingual("PressMediaViewComponent")

@Component({
  selector: 'app-press-media-view',
  templateUrl: './press-media-view.component.html',
  styleUrls: ['./press-media-view.component.css']
})
export class PressMediaViewComponent extends CoreComponent implements OnInit, OnDestroy {

  subscription: any;
  @Input() moduleType: string;
  @Input() moduleId: number;
  pressMediaView: PressMediaViewSearchModel;
  pressMediaList: PressMediaViewViewModel[];
  pressMediaGrid: AppGrid;
  spin: boolean;
  pressMediaViewFormGroup: IFormGroup<PressMediaViewSearchModel>
  showComponent: boolean;
  private pressMediaSubject: Subject<string> = new Subject<string>();
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  isFilter: boolean = false;
  isShowSearch: boolean = false;
  totalRecords: number = 0;
  InputModuleType: any;
  InputModuleId: any;
  dialog: BaseDialog;
  isShowGridColumn: boolean = false;
  browserStorage: BrowserStorage;
  element: number;
  sortingArray: any[];
  @Input() selectedTab: string = "";
  @Input() moduleID: any;
  @Input() riskId: number;
  @Input() hseId: number;
  toastr: BaseToastr;
  localizedMessages: any;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  isHideAll: boolean = false;
  isShowAll: boolean = false;
  titleSearch: string;
  titleGridColumnSetting: string;
  isRisk: boolean;
  isHSE: boolean;
  modalView: ModalView;
  isLongTextExists: boolean = false;
  cloneArray: PressMediaViewViewModel[];
  headerBtnChk: boolean = false;
  deletePressMediaIds: number[];
  pressMediaIdsArray: number[];
  isShowDelete: boolean = false;
  iscolor: boolean

  constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private location: PlatformLocation) {
    super();
    this.modalView = modalView;
    this.InputModuleType = this.moduleType;
    this.InputModuleId = this.moduleId;
    this.browserStorage = new BrowserStorage();
    this.sortingArray = new Array<any>();
    this.dialog = new BaseDialog();
    this.toastr = new BaseToastr();
    this.cloneArray = new Array<PressMediaViewViewModel>();
    this.pressMediaIdsArray = new Array<number>();
    this.deletePressMediaIds = new Array<number>();
    Window["PressMediaViewComponent"] = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit() {
    this.isRisk = this.moduleID == RolePermissionEnums.GemfieldsRiskMenu;
    this.isHSE = this.moduleID == RolePermissionEnums.HSE;
    this.isShowDelete = (this.isRisk || this.isHSE) ? true : false;
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.spin = true;
    this.pressMediaView = new PressMediaViewSearchModel();
    this.showComponent = false;
    this.pressMediaView.sortOrder = false;
    this.pressMediaView.orderBy = 'pressMediaId'
    this.pressMediaViewFormGroup = this.formBuilder.formGroup(PressMediaViewSearchModel, this.pressMediaView) as IFormGroup<PressMediaViewSearchModel>;
    //this.pressMediaSubject.pipe(debounceTime(300)).subscribe(t=>{
    this.pressMediaViewFormGroup.value.rowCount = 25;
    this.pressMediaViewFormGroup.value.pageIndex = 1;

    if (this.moduleID != "" && this.moduleId != null && this.moduleID != undefined) {
      this.get({ path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab } }).subscribe
        ((t: any) => {
          this.getColumnsData = t;
          if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
            this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            var indexMost = this.arrConfig.findIndex(x => x.ColumnName == "noteDescription1");
            if (indexMost != -1) {
              this.arrConfig[indexMost].Visible = false;
            }
          }
          setTimeout(() => {
            this.bindGrid();
          }, 0);
          this.spin = false;
        });
    }
    // this.get({
    //   path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    // }).subscribe((t: any) => {
    //   this.getColumnsData = t;
    //   if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
    //     this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
    //   }
    //   setTimeout(() => {
    //     this.bindGrid();
    //   }, 0);
    // })
    // })

  }
  search(value) {
    this.isFilter = true;
    let string = value.target.value.replace(/^\s+|\s+$/gm, '')
    setTimeout(() => {
      this.spin = false;
      this.pressMediaViewFormGroup.patchValue({
        searchString: string,
        pageIndex: 1,
        rowCount: this.pressMediaGrid.maxPerPage
      });
      //this.pressMediaViewFormGroup.value.searchString=value.target.value.replace(/^\s+|\s+$/gm,'');
      this.totalRecords = 0;
      this.pressMediaGrid.storeProcedure.nextPage = 1;
      this.pressMediaGrid.updateSource([]);
      this.pressMediaViewFormGroup.value.pageIndex = 1;
      this.bindGrid(false);
    }, 4000);
    this.spin = true;
    //   }
    // }
  }
  bindGrid(isOnload: boolean = true) {

    this.spin = true;
    this.isShowGrid = true;
    this.cloneArray = [];
    this.pressMediaViewFormGroup.patchValue({
      moduleType: this.moduleType,
      moduleId: this.moduleId,
      riskId: this.riskId
    });

    // if(this.moduleID != "" && this.moduleId != null && this.moduleID != undefined)
    // {
    //   this.get
    //   ({
    //       path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    //   }).subscribe
    //   ((t: any) => {
    //       this.getColumnsData = t;
    //       if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
    //         this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
    //       }
    //   });
    // }
    // if (this.arrConfig != undefined && this.arrConfig != null) {
    //   this.arrConfig.forEach(x => {
    //     this.pressMediaGrid.gridColumns.forEach(y => {
    //       if (y.name == x.ColumnName)
    //         y.visible = x.Visible;
    //     });
    //   });
    //   this.gridColumnConfig = this.pressMediaGrid.gridColumns;
    // }
    this.post({ path: 'api/SearchCommon/GetPressMediaView', body: { query: JSON.stringify(this.pressMediaViewFormGroup.value) } }).subscribe((t: PressMediaViewViewModel[]) => {
      this.spin = false;
      this.pressMediaList = t;

      this.pressMediaList.forEach(x => {
        if (x.riskDetails != null && x.riskDetails != undefined && x.riskDetails.length > 1) {
          x.risks = this.formCompanyTable(x);
        }
        if(x.corporateList !=null && x.corporateList != undefined && x.corporateList.length > 0){
          x.corporatesData = this.formCorporateData(x);
        }
        if(x.PeoplesList !=null && x.PeoplesList != undefined && x.PeoplesList.length > 0){
          x.peopleNamesData = this.formPeopleData(x);
        }
        if(x.JournalistsList !=null && x.JournalistsList != undefined && x.JournalistsList.length > 0){
          x.journalistNamesData = this.formJournalistsData(x);
        }
        if(x.PublicationsList !=null && x.PublicationsList != undefined && x.PublicationsList.length > 0){
          x.publicationsData = this.formPublicationsData(x);
        }
      })     

      this.isLongTextExists = false;
      // for (let element of this.pressMediaList) {
      //   if (element.charCount > 100) {
      //     this.isLongTextExists = true;
      //     break;
      //   }
      // }
      // if (this.isLongTextExists && !this.isHideAll && !this.isShowAll) {
      //   this.isShowAll = true;
      // }

      if (this.pressMediaList != null && this.pressMediaList.length > 0) {
        this.totalRecords = this.pressMediaList[0].totalCount;
        this.pressMediaList.forEach(x => {
          x.isChecked = false;
        })
        this.pressMediaList.forEach(x => {
          x.isChecked = this.headerBtnChk;
        })
        this.pressMediaList.forEach(x => {
          if (this.cloneArray.findIndex(z => z.mediaId == x.mediaId) == -1) {
            this.cloneArray.push(x);
          }
          else {
            this.cloneArray.forEach(z => {
              if (z.mediaId == x.mediaId) {
                x.isChecked = z.isChecked
              }
            })
          }
          if (this.pressMediaIdsArray.findIndex(z => z == x.mediaId) != -1) {
            x.isChecked = true;
          }
        })

        this.updatePressMediaArray();
        if (isOnload && this.totalRecords > 0) {
          this.isShowSearch = true;
        }
        this.isShowGrid = this.pressMediaList[0].totalCount > 0 ? true : false;
        this.isNoRecordFound = this.pressMediaList[0].totalCount > 0 ? false : true;
        if (!this.isFilter) {
          this.pressMediaGrid = new AppGrid(this.pressMediaList, PressMediaViewViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onDelete: this.onDelete.bind(this), onMoreArticleClick: this.onMoreArticleClick.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this), onDocClick: this.onDocumentDownloadLink.bind(this) } });
          this.pressMediaGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.checkForLongTexts();
          this.pressMediaGrid.maxPerPage = this.pressMediaViewFormGroup.value.rowCount;

        }
        else {
          if (this.pressMediaGrid != null){

            this.pressMediaGrid.storeProcedure.length = this.totalRecords;
            if (this.pressMediaViewFormGroup.value.pageIndex == 1) {
              this.pressMediaGrid.storeProcedure.nextPage = 1;
            }
            // this.pressMediaGrid.currentPage=1

            this.pressMediaGrid.updateSource([]);
            this.pressMediaGrid.updateSource(this.pressMediaList);
            this.checkForLongTexts();
          }
        }

        this.pressMediaGrid.gridColumns.forEach(element => {
          if ((element.name == "action" || element.name == "isChecked") && (this.moduleID == RolePermissionEnums.GemfieldsRiskMenu || this.moduleID == RolePermissionEnums.HSE)) {
            element.visible = true;
          }
        });


        this.gridCommon();

        this.pressMediaGrid.componentId = this.componentName;
        this.pressMediaGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
        this.pressMediaGrid.designClass.headerClass = "table-header-sticky".split(" ");
      }
      else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
        // this.isShowAll = false;
      }

    })
  }
  DeleteConfirmationMessage() {
    
    var existsAlert = getLocalizedValue("Delete_Confimation");

    if(this.moduleID == RolePermissionEnums.GemfieldsRiskMenu)
    {
      existsAlert = getLocalizedValue("Delete_Confirmation_Risk_PressMedia");
    }
    else if ( this.moduleID == RolePermissionEnums.HSE)
    {
      existsAlert = getLocalizedValue("Delete_Confirmation_HSE_PressMedia");
    }
   
    if (existsAlert) {
      return existsAlert;
    }
  } 
  onDelete(entity: PressMediaViewViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        if (this.moduleID == RolePermissionEnums.GemfieldsRiskMenu) {
          this.delete({ path: 'api/SearchUsers/RiskHsePressMediaRemove', body: { pressMediaId: Number(entity.mediaId), riskMenuDetailId: this.moduleId, riskIncident: 0, isEvent: 0 } }).subscribe(data => {
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              //this.toastr.success("Data Deleted Successfully")
              this.toastr.success(existsAlert);
            }
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.pressMediaGrid.updateSource([]);
            this.bindGrid();
          })
        }
        if (this.moduleID == RolePermissionEnums.HSE) {
          this.delete({ path: 'api/FormSearch/HsePressMediaRemove', body: { pressMediaId: Number(entity.mediaId), hseId: this.moduleId, riskIncident: 0, isEvent: 0 } }).subscribe(data => {
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              //this.toastr.success("Data Deleted Successfully")
              this.toastr.success(existsAlert);
            }
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.pressMediaGrid.updateSource([]);
            this.bindGrid();
          })
        }
      }
      this.dialog.hide()
    })
  }

  onHeaderCheckBoxSelect(t, x) {
    this.headerBtnChk = x.target.checked;
    this.cloneArray = new Array<PressMediaViewViewModel>();
    this.cloneArray = [...this.pressMediaList]
    this.cloneArray.forEach(arr => arr.isChecked = this.headerBtnChk)
    this.updatePressMediaArray();
    this.pressMediaGrid.updateSource([]);
    this.pressMediaGrid.updateSource(this.cloneArray);
    this.applybgColor();

  }

  onCheckboxSelect(t, x) {
    let oldCloneArray = [...this.cloneArray];
    this.cloneArray = new Array<PressMediaViewViewModel>();
    oldCloneArray.forEach(z => {
      if (z.mediaId == t.mediaId) z.isChecked = x.target.checked
    })
    this.cloneArray = [...oldCloneArray]
    this.updatePressMediaArray();
    this.pressMediaGrid.updateSource([])
    this.pressMediaGrid.updateSource(this.cloneArray)
    this.applybgColor();
  }

  onDocumentDownloadLink(x) {
    //this.isNoteComponent=true;
    this.get({
      path: "api/Document/Notes",
      params: [x.documentId],
    }).subscribe(
      (res: string) => {
        window.open(res, "_blank");
      },
      (err) => { },
      () => {
        this.spin = false;
      }
    );
  }

  updatePressMediaArray() {
    this.cloneArray.forEach(x => {
      if (x.isChecked) {
        let pressMediaId = x.mediaId;

        if (this.deletePressMediaIds.indexOf(Number(x.mediaId)) == -1) {
          this.pressMediaIdsArray.push(Number(pressMediaId));
          this.deletePressMediaIds.push(Number(x.mediaId));
        }
      }
      else {
        if (this.deletePressMediaIds.indexOf(Number(x.mediaId)) != -1) {
          this.pressMediaIdsArray.splice(this.deletePressMediaIds.indexOf(Number(x.mediaId)), 1);
          this.deletePressMediaIds.splice(this.deletePressMediaIds.indexOf(Number(x.mediaId)), 1);
        }
      }
    })
  }

  deletePressMedia() {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        if (this.moduleID == RolePermissionEnums.GemfieldsRiskMenu) {
          this.delete({ path: 'api/FormSearch/HsePressMediaRemove', body: { pressMediaId: this.deletePressMediaIds.join(','), riskMenuDetailId: this.moduleId, riskIncident: 0, isEvent: 0, moduleID: this.moduleID } }).subscribe(data => {
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              this.toastr.success(existsAlert);
            }
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.isShowSearch = false;
            this.cloneArray = [];
            this.deletePressMediaIds = [];
            this.pressMediaIdsArray = [];
            this.headerBtnChk = false;
            this.pressMediaGrid.reDesign();
            this.pressMediaGrid.updateSource([]);
            this.pressMediaViewFormGroup.patchValue({
              pageIndex: 1,
            });
            this.bindGrid();
          })
        }
        if (this.moduleID == RolePermissionEnums.HSE) {
          this.delete({ path: 'api/FormSearch/HsePressMediaRemove', body: { pressMediaId: this.deletePressMediaIds.join(','), hseId: this.moduleId, riskIncident: 0, isEvent: 0, moduleID: this.moduleID } }).subscribe(data => {
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              this.toastr.success(existsAlert);
            }
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.isShowSearch = false;
            this.cloneArray = [];
            this.deletePressMediaIds = [];
            this.pressMediaIdsArray = [];
            this.headerBtnChk = false;
            this.pressMediaGrid.reDesign();
            this.pressMediaGrid.updateSource([]);
            this.pressMediaViewFormGroup.patchValue({
              pageIndex: 1,
            });
            this.bindGrid();
          })
        }
      }
      this.dialog.hide()
    })
  }

  formCorporateData(item: PressMediaViewViewModel):any{
    let data : any;
    let row: string = "";
    data = "<div>";
    if(item.corporateList != null && item.corporateList !=undefined){
      for(let i = 0; i < item.corporateList.length; i++){
        let x: any = item.corporateList[i];
        row = row + `<a class='${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' target="_blank" href=/suppliers/${x.supplierId}?activeTab=supplier-edit ng-reflect-router-link=/suppliers/${x.supplierId}?activeTab=user-edit><b>${x.company}</b></a>
        ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
        ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy + "' id='" + x.supplierId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.supplierId + "," + x.companyIntroducesBy + ")'></i>": ""}
         `;

        if (i !== item.corporateList.length - 1) {
          row =row+ ",";
        }
      }
      data = data + row + "</div>";
    }
    return data;
  }
  formPeopleData(item: PressMediaViewViewModel):any{
    let data : any;
    let row: string = "";
    data = "<div>";
    if(item.PeoplesList != null && item.PeoplesList !=undefined){
      for(let i = 0; i < item.PeoplesList.length; i++){
        let x: any = item.PeoplesList[i];
        row = row + `<a class='${x.contactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' target="_blank" href=/users/${x.UserId}?activeTab=user-edit ng-reflect-router-link=/users/${x.UserId}?activeTab=user-edit><b>${x.name}</b></a>
        ${x.isUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
        ${x.contactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.introducesBy + "' id='" + x.UserId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.UserId + "," + x.introducesBy + ")'></i>": ""}
        `;
        
        if (i !== item.PeoplesList.length - 1) {
          row =row+ ",";
        }
      }
      data = data + row + "</div>";
    }
    return data;
  }

  formJournalistsData(item: PressMediaViewViewModel):any{
 let data : any;
    let row: string = "";
    data = "<div>";
    if(item.JournalistsList != null && item.JournalistsList !=undefined){
      for(let i = 0; i < item.JournalistsList.length; i++){
        let x: any = item.JournalistsList[i];
        row = row + `<a class='${x.contactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' target="_blank" href=/users/${x.UserId}?activeTab=user-edit ng-reflect-router-link=/users/${x.UserId}?activeTab=user-edit><b>${x.name}</b></a>
        ${x.isUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
        ${x.contactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.introducesBy + "' id='" + x.UserId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.UserId + "," + x.introducesBy + ")'></i>": ""}
        `;

        if (i !== item.JournalistsList.length - 1) {
          row =row+ ",";
        }
      }
      data = data + row + "</div>";
    }
    return data;
  }

  formPublicationsData(item: PressMediaViewViewModel): any {
    let data: any;
    let row: string = "";
    data = "<div>";
    if (item.PublicationsList != null && item.PublicationsList != undefined) {
      for (let i = 0; i < item.PublicationsList.length; i++) {
        let x: any = item.PublicationsList[i];
        row = row + `<a  class='${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' target="_blank" href=/suppliers/${x.SupplierId}?activeTab=supplier-edit ng-reflect-router-link=/suppliers/${x.SupplierId}?activeTab=user-edit><b>${x.Company}</b></a>
        ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
        ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy + "' id='" + x.SupplierId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.SupplierId + "," + x.companyIntroducesBy + ")'></i>": ""}
        `;

        if (i !== item.PublicationsList.length - 1) {
          row = row + ",";
        }
      }
      data = data + row + "</div>";
    }
    return data;
  }

  formCompanyTable(item: PressMediaViewViewModel): any {
    let tabledata: any;
    let row: string = "";
    let subRow: any = "";
    tabledata = "<div><table><tbody>";
    let subTable: any;
    subTable = "<tr id='demo" + item.mediaId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
    if (item.riskDetails != null && item.riskDetails != undefined) {
      for (let i = 0; i < item.riskDetails.length; i++) {
        let x: any = item.riskDetails[i];
        if (i == 0) {
          let id: string;
          id = "'" + item.mediaId + "'";
          row = row + "<tr><td >" + item.riskDetails[i].risk + "<i class='fa fa-chevron-down ml-2' id='icon" + item.mediaId + "' onclick='Window.PressMediaViewComponent.expandTable(" + item.mediaId + ")'></i></td></tr>";
        }
        else {
          subRow = subRow + "<tr><td>" + x.risk + "</td></tr>";
        }
      }
      if (subRow != "") {
        subTable = subTable + subRow + "</tbody></table></div></td></tr>";
        row = row + subTable;
      }
      tabledata = tabledata + row + "</tbody></table></div>"
    }
    return tabledata;
  }
  expandTable(id: number) {
    var element = document.getElementById("demo" + id.toString());
    var iconElement = document.getElementById("icon" + id.toString());
    if (element != null) {
      if (element.style.display == "none") {
        element.style.display = "block";
        if (iconElement != null) {
          iconElement.className = "fa fa-chevron-up ml-2"
        }

      }
      else {
        element.style.display = "none";
        if (iconElement != null) {
          iconElement.className = "fa fa-chevron-down ml-2"
        }

      }
    }
  }
  gridCommon() {
    // if (this.moduleType == 'Users') {
    //   if (this.pressMediaList[0].isJournalists == 'true') {
    //     this.moduleType = "Journalists";
    //   }
    //   else {
    //     this.moduleType = "Users";
    //   }

    // }
    // if (this.moduleType == 'Corporates') {
    //   if (this.pressMediaList[0].isPublication == 'true') {
    //     this.moduleType = "Publications";
    //   }
    //   else {
    //     this.moduleType = "Corporates";
    //   }

    // }

    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(x => {
        this.pressMediaGrid.gridColumns.forEach(y => {
          if (y.name == x.ColumnName) {
            y.visible = x.Visible;

          }
        });
      });
      this.gridColumnConfig = this.pressMediaGrid.gridColumns;
      //this.gridColumnSettings.emit(this.gridColumnConfig)
    }
    if (!this.isFilter) {
      this.pressMediaGrid.gridColumns.forEach(x => {
        // if (x.columnIndex == 8 && this.moduleType == 'Corporates') {
        //   x.visible = false;
        // }
        // if (x.columnIndex == 6 && this.moduleType == 'Publications') {
        //   x.visible = false;
        // }
        // if (x.columnIndex == 7 && this.moduleType == 'Users') {
        //   x.visible = false;
        // }
        // if (x.columnIndex == 5 && this.moduleType == 'Journalists') {
        //   x.visible = false;
        // }
        if (x.columnIndex == 10 && this.moduleType == 'Events') {
          x.visible = false;
        }
        if (x.columnIndex == 11 && this.moduleType == 'Projects') {
          x.visible = false;
        }
        if (x.columnIndex == 12 && this.moduleType == 'Products') {
          x.visible = false;
        }
        // if (x.columnIndex == 12 && this.moduleType == 'HSE/GINA') {
        //   x.visible = false;
        // }
      });
    }
    this.iscolor = true;
    this.applybgColor();
  }

  onMoreClick(t, x) {

    var element = document.getElementById("note_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");

    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");


    }
  }

  onMoreClickAll() {
    this.spin = true;
    this.isFilter = true;
    this.gridColumnConfig = this.pressMediaGrid.gridColumns;
    this.pressMediaGrid.destroy();
    this.pressMediaGrid = new AppGrid(this.pressMediaList, PressMediaViewViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onDelete: this.onDelete.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
    this.pressMediaGrid.designClass.tableClass.push("table-list");
    this.pressMediaGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: 1,
      onPageSorting: this.onPageSorting.bind(this)
    }
    if (this.gridColumnConfig) {
      this.gridColumnConfig.forEach(element => {
        this.pressMediaGrid.gridColumns.forEach(y => {
          if (y.name == element.name) {
            y.visible = element.visible;
          }
        })
      })
    }
    this.pressMediaGrid.gridColumns.forEach(z => {

      if (z.name == 'mostRecentManualNote') {
        z.visible = false
        // z.parameter = true

      }
      if (z.name == 'noteDescription1') {
        z.visible = true
        //z.parameter = false
      }
    })

    this.isShowAll = false;
    this.isHideAll = true;
    this.pressMediaGrid.componentId = this.componentName;
    this.pressMediaGrid.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
    this.applybgColor();

  }

  onLessClickAll() {
    this.spin = true;
    this.gridColumnConfig = this.pressMediaGrid.gridColumns;
    this.pressMediaGrid.destroy();
    this.pressMediaGrid = new AppGrid(this.pressMediaList, PressMediaViewViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onDelete: this.onDelete.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
    this.pressMediaGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: 1,
      onPageSorting: this.onPageSorting.bind(this)
    }
    this.pressMediaGrid.designClass.tableClass.push("table-list");
    //this.gridCommon();
    if (this.gridColumnConfig) {
      this.gridColumnConfig.forEach(element => {
        this.pressMediaGrid.gridColumns.forEach(y => {
          if (y.name == element.name) {
            y.visible = element.visible;
          }
        })
      })
    }
    this.pressMediaGrid.gridColumns.forEach(z => {

      if (z.name == 'mostRecentManualNote') {
        z.visible = true
        //z.parameter = false
      }
      if (z.name == 'noteDescription1') {
        z.visible = false
        //z.parameter = true
      }
    })
    this.isShowAll = true;
    this.isHideAll = false;
    this.pressMediaGrid.componentId = this.componentName;
    this.pressMediaGrid.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
    this.applybgColor();

  }

  applybgColor() {
    var articleElement = this.pressMediaGrid.gridColumns.filter(x => x.visible == true).findIndex(x => x.name == "article");
    if (this.iscolor) {

      setTimeout((() => {
        if (articleElement != -1) {
          if (this.pressMediaList.length > 0) {
            for (let index = 0; index < this.pressMediaList.length; index++) {
              const element = this.pressMediaList[index];
              if (element != null) {
                var data_dtl = document.querySelectorAll('[data-rxwebid="row-id-' + index + '"]')[0].children[3];
                if (data_dtl != null) {
                  if (element.articleName.toLowerCase() == "negative") {
                    data_dtl["style"] = "background-color: red;";
                  }
                  else if (element.articleName.toLowerCase() == "positive") {
                    data_dtl["style"] = "background-color: lightgreen;";
                  }
                }
              }
            }
          }
          this.spin = false;
          this.iscolor = false;
        }
      }), 0);
    }
    else {
      if (articleElement != -1) {
        if (this.pressMediaList.length > 0) {
          for (let index = 0; index < this.pressMediaList.length; index++) {
            const element = this.pressMediaList[index];
            if (element != null) {
              var data_dtl = document.querySelectorAll('[data-rxwebid="row-id-' + index + '"]')[0].children[3];
              if (data_dtl != null) {
                if (element.articleName.toLowerCase() == "negative") {
                  data_dtl["style"] = "background-color: red;";
                }
                else if (element.articleName.toLowerCase() == "positive") {
                  data_dtl["style"] = "background-color: lightgreen;";
                }
              }
            }
          }
        }
        this.spin = false;
      }
    }
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(element => {
        var index = this.pressMediaGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
        if (index != -1) {
          if (this.pressMediaGrid.gridColumns[index].visible != element.Visible) {
            element.Visible = this.pressMediaGrid.gridColumns[index].visible;
          }
        }
      })
    }
    this.checkForLongTexts();
    this.pressMediaGrid.reDesign();
    this.pressMediaGrid.updateSource([]);
    this.pressMediaGrid.updateSource(this.pressMediaList);
    this.applybgColor();
  }
  showOrHideAll() {
    this.isShowAll = !this.isShowAll;
    this.isFilter = false;
    this.pressMediaGrid.destroy();
    this.pressMediaGrid = null;
    this.pressMediaViewFormGroup.patchValue({
      isShowAll: this.isShowAll,
    });
    this.bindGrid();
  }
  checkForLongTexts() {
    this.isLongTextExists = false;
    let isDescriptionCheckRequired = false;
    let isarticleCheckRequired = false;
    this.pressMediaGrid.gridColumns.forEach(column => {
      if (column.name == "mostRecentManualNote" && column.visible) {
        isDescriptionCheckRequired = true;
      }
      if (column.name == "article" && column.visible) {
        isarticleCheckRequired = true;

      }
    });
    if (isDescriptionCheckRequired || isarticleCheckRequired) {
      for (let element of this.pressMediaList) {

        if (element.charCount > 100 && isDescriptionCheckRequired) {
          this.isLongTextExists = true;
          break;
        }
        if (element.articleCount > 100 && isarticleCheckRequired) {
          this.isLongTextExists = true;
          break;
        }
      }
    }
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  onPageSorting(x, y, z) {
    this.isFilter = true;
    y = !y;
    this.cloneArray = [];
    this.pressMediaViewFormGroup.patchValue({
      sortOrder: y,
      orderBy: x
    });
    // this.pressMediaViewFormGroup.value.sortOrder = y;
    // this.pressMediaViewFormGroup.value.orderBy = x;
    this.pressMediaGrid.storeProcedure.nextPage = z;
    this.pressMediaGrid.updateSource([]);
    this.bindGrid(false);

  }

  onPageChanging(x) {
    this.isFilter = true;
    // this.pressMediaViewFormGroup.value.pageIndex = x;
    // this.pressMediaViewFormGroup.value.rowCount = this.pressMediaGrid.maxPerPage;
    this.cloneArray = [];
    this.pressMediaViewFormGroup.patchValue({
      pageIndex: x,
      rowCount: this.pressMediaGrid.maxPerPage
    });
    this.pressMediaGrid.storeProcedure.nextPage = x;
    this.pressMediaGrid.updateSource([]);
    this.bindGrid(false);
  }

  addPressMedia() {
    if (this.isRisk == true) {
      this.modalView.show(PressMediaViewAddComponent, { riskId: this.riskId }).then(t => {
        if (this.pressMediaGrid) {
          this.isFilter = true;
          this.pressMediaGrid.updateSource([]);
          this.isShowSearch = false;
          this.bindGrid();
        }
        else {
          this.isFilter = false;
          this.isShowSearch = false;
          this.bindGrid()
        }
      });
    }
    else if (this.isHSE == true) {
      this.modalView.show(PressMediaViewAddComponent, { hseId: this.hseId }).then(t => {
        if (this.pressMediaGrid) {
          this.isFilter = true;
          this.pressMediaGrid.updateSource([]);
          this.isShowSearch = false;
          this.bindGrid();
        }
        else {
          this.isFilter = false;
          this.isShowSearch = false;
          this.bindGrid()
        }
      });
    }

  }
  onMoreArticleClick(t, x) {
    var element = document.getElementById("note_" + t.mediaId);
    var maskedElement = document.getElementById("maskednote_" + t.mediaId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }
  get componentName(): string {
    return "PressMediaViewComponent";
  }

}
