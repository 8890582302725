import {KnownToBase,UserBase,RelationshipBase,} from '@app/database-models'
import { UserKnownToListViewModel } from './user-known-to-list-view-model';
//Generated Imports
export class KnownTo extends KnownToBase
{



    knownToUserIds: KnownTo[];

    deleteKnownToUserIds: KnownTo[];
    //#region Generated Reference Properties
    //#region user Prop
    user : UserBase;
    //#endregion user Prop
    //#region relationship Prop
    relationship : RelationshipBase;
    headerBtnChk: boolean;
    query: string;
    relationArray: KnownTo[];
    //#endregion relationship Prop

    relationshipStatusId: number = 0;
    isEmergencyContact: boolean = false;
    isClose: boolean = false;
    relatedKnownToId: number = 0;
    parentRelationshipId: number = 0;


    //#endregion Generated Reference Properties












}