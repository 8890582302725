import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractPowerBIReport } from '../domain/abstract-power-bi-report';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpSupport } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
import { PowerBIReport } from "src/app/models/extended-models/power-bi-report";
@multilingual("PowerBIReportEditComponent")
@access({ accessLevel: RolePermissionEnums.PowerBIReports, action: "put" })

@Component({
    selector: "app-power-bi-report-edit",
    templateUrl: './power-bi-report-edit.component.html'
})
export class PowerBIReportEditComponent extends AbstractPowerBIReport implements OnInit, OnDestroy {
    helpSupport: PowerBIReport;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    powerBIReportDelete: any = dbAccessModule[RolePermissionEnums.PowerBIReports]["delete"];reportName: any;
;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    _this;

    constructor(private title: Title, private router: Router,private applicationBroadcaster:ApplicationBroadcaster, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, modelView: ModalView, private el: ElementRef, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.PowerBIReports;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    activatedTab(tab) {

        this.currentActiveTab = tab;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Power BI Reports - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.spin = true;
        this.lookup([{ params: [this.id], propName: "powerBIReport" }]).subscribe((lookup: any) => {
            this.spin = false;
            if (lookup.powerBIReport) {
                this.powerBIReportLookups = lookup;
                this.powerBiReportFormGroup = this.formBuilder.formGroup(PowerBIReport, this.powerBIReportLookups.powerBIReport.result[0]) as IFormGroup<PowerBIReport>;
                this.reportName =this.powerBIReportLookups.powerBIReport.result[0].powerBIReportName;
                this.powerBiReportFormGroup.controls.powerBIReportGUID.setValidators([
                    RxwebValidators.required({ message: "You can't leave this field empty" }),
                    RxwebValidators.pattern({
                      expression: {'powerBIReportGUID': /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/ },
                      message: 'GUID is not valid' })
                  ]);

                this.showComponent = true;
            }
            else {
                this.router.navigate(['/power-bi-reports']);
            }
        })
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editPowerBIReport(isRedirect) {
        this.powerBiReportFormGroup.submitted = true;
        this.powerBiReportFormGroup.patchValue({
            isRequestFromInfoTab: true
        });
        if (this.powerBiReportFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.powerBiReportFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/power-bi-reports', this.id]) : this.router.navigate(['/power-bi-reports']);
            })
        } else {
            for (const key of Object.keys(this.powerBiReportFormGroup.controls)) {
                if (this.powerBiReportFormGroup.controls[key].invalid) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    invalidControl.focus();
                    break;
                }
            }
        }
    }

    deletePowerBIReport() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/power-bi-reports']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Power_BI_Report");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    tinyMCE_images_upload_handler = (blobInfo, success, failure) => {
        var fiileName = blobInfo.blob().name;
        if (fiileName != undefined) {
        }
    }

    get componentName(): string {
        return "PowerBIReportEditComponent";
    }
}
