import { prop, propObject, propArray, required, maxLength, range, numeric, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IncidentCorporatesAffectedBase {

        //#region incCorpAffId Prop
        @prop()
        incCorpAffId: number;
        //#endregion incCorpAffId Prop


        //#region incidentId Prop

        incidentId: number;
        //#endregion incidentId Prop
        @prop()
        personType:number;



        //#region corporateAffectedId Prop
        @prop()
        corporateAffectedId: number;
        //#endregion corporateAffectedId Prop




      @prop()
      supplierId:number;
      @prop()
      userName:string;


     //#region involvementRole Prop
        @prop()
        @maxLength({ value: 500 })
        involvementRole: string;
      //#endregion involvementRole Prop


}