import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BannedIpNetworkBase {

//#region bannedIpNetworkId Prop
        @prop()
        bannedIpNetworkId : number;
//#endregion bannedIpNetworkId Prop


//#region startAddress Prop
        @required()
        @maxLength({value:50})
        @notEmpty()
        @trim()
        startAddress : string;
//#endregion startAddress Prop


//#region endAddress Prop
        @required()
        @maxLength({value:50})
        @notEmpty()
        @trim()
        endAddress : string;
//#endregion endAddress Prop


//#region comment Prop
        @maxLength({value:500})
        @trim()
        comment : string;
//#endregion comment Prop


//#region ipException Prop
        @prop()
        @trim()
        ipException : string;
//#endregion ipException Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @required()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region statusId Prop
        @prop()
        statusId : number;
//#endregion statusId Prop

}