<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/leave-types']" rxText="Label_Leave_Type_t"></a>
        </li>
        <li class="breadcrumb-item active">Add New</li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="LeaveTypeAddComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="leaveTypeFormGroup"
         [rxSpinner]="spin">
        <div class="row add-countries-form" (keyup.enter)="addLeaveType(false)">
          <div class="col-md-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-3 col-form-label" 
                rxText="LeaveTypeAddEditComponent_TypeName_t"></label>
              <div class="col-md-9">
                <input type="text" formControlName="leaveTypeName" class="form-control "
                   rxPlaceholder="LeaveTypeAddEditComponent_TypeName_p" rxFocus />
                <small class="form-text text-danger"
                  [class.d-block]="leaveTypeFormGroup.controls.leaveTypeName.errors">{{leaveTypeFormGroup.controls.leaveTypeName.errorMessage}}<br /></small>
              </div>
            </div>
          </div>
          <div class="col-md-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-3 col-form-label"  rxText="LeaveTypeAddEditComponent_Color_t"></label>
              <div class="col-md-9">
                <div class="row">
                  <label class="col-md-7" style="padding-top: 7px;">Current Colour: {{leaveTypeFormGroup.controls.typeColor.value}}</label>
                  <input type="color" formControlName="typeColor" class="form-control col-md-5" style="height: 34px;"
                     />
                  <small class="form-text text-danger"
                    [class.d-block]="leaveTypeFormGroup.controls.typeColor.errors">{{leaveTypeFormGroup.controls.typeColor.errorMessage}}<br /></small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="addLeaveType(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="addLeaveType(true)" class="btn btn-primary mr-1" *rxAuthorize="[leaveTypeEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>