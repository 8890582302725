import {PaymentMethodBase,} from '@app/database-models'
//Generated Imports
export class PaymentMethod extends PaymentMethodBase 
{

    constructor() {
        super();
        this.isActive= false;
        this.isImpersonated=false;
    }


//#region Generated Reference Properties

//#endregion Generated Reference Properties












}