import {vCheckoutStepLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCheckoutStepLookUp extends vCheckoutStepLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}