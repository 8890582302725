import {ConfigurationContentBase,} from '@app/database-models'
//Generated Imports
export class ConfigurationContent extends ConfigurationContentBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}