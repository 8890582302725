import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemfieldsCarrierBase {

//#region carrierId Prop
        @prop()
        carrierId : number;
//#endregion carrierId Prop


//#region carrierName Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        //@alpha({allowWhiteSpace:true})
        @trim()
        carrierName : string;
//#endregion carrierName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}