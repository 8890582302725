import {vGemstoneStatusRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemstoneStatusRecord extends vGemstoneStatusRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}