import {LegalOwnerBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class LegalOwner extends LegalOwnerBase 
{




//#region Generated Reference Properties
//#region productPieceGemstones Prop
productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop

//#endregion Generated Reference Properties












}