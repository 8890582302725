import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCategoryBase {

//#region categoryId Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'CategoryListComponent_CategoryId_gh', keyColumn: true})
        categoryId : number;
//#endregion categoryId Prop


//#region categoryName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'CategoryListComponent_Name_gh', keyColumn: false})
        categoryName : string;
//#endregion categoryName Prop


//#region abbreviation Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: false, columnIndex:2, allowSorting: true, headerKey: 'CategoryListComponent_Abbreviation_gh', keyColumn: false})
        abbreviation : string;
//#endregion abbreviation Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'CategoryListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region published Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'CategoryListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName:"published" } })
        published : boolean;
//#endregion published Prop


//#region categoryTypeId Prop
        @gridColumn({visible: false, columnIndex:5, allowSorting: true, headerKey: 'CategoryListComponent_TypeId_gh', keyColumn: false})
        categoryTypeId : number;
//#endregion categoryTypeId Prop

//#region ParentCategoryId Prop
@gridColumn({visible: false, columnIndex:6, allowSorting: true, headerTitle: "Parent Category Id", keyColumn: false})
parentCategoryId : number;
//#endregion ParentCategoryId Prop


//#region categoryTypeName Prop
//     @gridColumn({ style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex:6, allowSorting: true, headerKey: 'CategoryListComponent_TypeName_gh', keyColumn: false})
//         categoryTypeName : string;
//#endregion categoryTypeName Prop

}