import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceDeliveredPriceBase {

//#region calculateProductVariantPriceId Prop
        @prop()
        calculateProductVariantPriceId : number;
//#endregion calculateProductVariantPriceId Prop


//#region productvariantid Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productvariantid : number;
//#endregion productvariantid Prop


//#region currencyCode Prop
        @maxLength({value:10})
        currencyCode : string;
//#endregion currencyCode Prop


//#region currencyRate Prop
        @prop()
        currencyRate : number;
//#endregion currencyRate Prop


//#region exportPrice Prop
        @prop()
        exportPrice : number;
//#endregion exportPrice Prop


//#region deliveredprice Prop
        @prop()
        deliveredprice : number;
//#endregion deliveredprice Prop

}