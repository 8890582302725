import {vMeasureWeightRecordBase,} from '@app/database-models'
//Generated Imports
export class vMeasureWeightRecord extends vMeasureWeightRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}