export const I_T_HELP_DESK_LOOKUPS: { [key: string]: string } = {
        iNCTypeLookUp: 'api/ithelpdesklookups/inctypelookup',
        iNCCategoryLookUp: 'api/ithelpdesklookups/inccategorylookup',
        ticketStatusLookUp: 'api/ithelpdesklookups/ticketstatuslookup',
        itIncTypeLookUp: 'api/ithelpdesklookups/itinctypelookup',
        itIncCategoryLookup: 'api/ithelpdesklookups/ITIncCategoryLookUp',
        itCompanyPeopleList: 'api/ithelpdesklookups/ITCompanyPeopleList',
        ticketSeverity: 'api/ithelpdesklookups/TicketSeverity',
        incSubCategory:'api/ithelpdesklookups/ParentIncCategoryLookUps',
        itincSubCategory:'api/ithelpdesklookups/ITIncSubCategoryLookUp'
}

