declare const $: any;
declare const jQuery: any;
export function scroolOnSearchResult() {
  jQuery('html, body').animate({
    scrollTop: $('.search-result-container').position().top
  }, 'slow');
}
export function peopleEditScrolOnTop() {
  jQuery('html, body').animate({
    scrollTop: $('.user-search-result-container').position().top
  }, 'slow');
}
export function scroolOnSearchResultHolidayProcessGrid() {
  jQuery('html, body').animate({
    scrollTop: $('.search-result-container-holidayProcessGrid').position().top
  }, 'slow');
}
export function scroolOnSearchResultHolidayListGrid() {
  jQuery('html, body').animate({
    scrollTop: $('.search-result-container-holidayListGrid').position().top
  }, 'slow');
}
export function scrollToTableOfPersonGrid() {
  jQuery('html, body').animate({
    scrollTop: $('.table-of-person-container').position().top
  }, 'slow');
}
export function scrollToTableOfCorporateGrid() {
  jQuery('html, body').animate({
    scrollTop: $('.table-of-corporate-container').position().top
  }, 'slow');
}

/**
 * Scroll to the position of the element.
 * @param element String value of HTML element (id, class, or any selector) for scrolling to that location.
 */
export function scrollToElement(element:string) {
  var position = $(element).offset();
  jQuery('html, body').animate({
    scrollTop: position.top - 20
  }, 'slow');
}

export function bindScroolEventInvitees(topPosition:number) {
  jQuery("html, body").animate({
    scrollTop: topPosition
  }, "slow");
}
