import { gridColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';


export class MainSubProjectChildListViewModel { 

//#region month Prop
  @gridColumn({ parameter: false, class: ["project"], style:{"min-width":"5%","max-width":"7%"}, visible: true, columnIndex: 0, allowSorting: true, headerKey: 'month', keyColumn: false })
  month: string;
//#endregion month Prop

//#region projectId Prop
    @gridColumn({class: ["project"], style:{"min-width":"5%","max-width":"7%"}, visible: true, columnIndex:1,  allowSorting: true, headerKey: 'projectId', keyColumn: true})
    projectId : number;
//#endregion projectId Prop

  //#region projectType Prop
  @gridColumn({ parameter: true, class: ["text-left","projectsListRows"], style:{"min-width":"5%","max-width":"10%"}, visible: false, columnIndex: 2, allowSorting: true, headerKey: 'projectType', keyColumn: false })
  projectType: string;
//#endregion projectType Prop

//#region projectTypeName Prop
@gridColumn({parameter: false, class: ["projectsListRows"], style:{"min-width":"5%","max-width":"10%"}, visible: false, columnIndex:3,  allowSorting: true, headerKey: 'projectTypeName', keyColumn: false})
projectTypeName : string;
//#endregion projectTypeName Prop

//#region gemfieldsGroupCompanyProjectOwnerName Prop
@gridColumn({ parameter: false, class: ["projectsListRows"], style: { "min-width": "5%", "max-width": "10%" }, visible: true, columnIndex: 3, allowSorting: false, headerKey: 'ProjectListComponent_gemCompPO_gh', keyColumn: false })
gemfieldsGroupCompanyProjectOwnerName: string;
//#endregion gemfieldsGroupCompanyProjectOwnerName Prop

  //#region projectOwner Prop    
    @gridColumn({ class: ["projectsListRows","text-left"], visible: false, columnIndex:4,  allowSorting: true, headerKey: 'projectOwner', keyColumn: false})
    projectOwner : number;
//#endregion projectOwner Prop

  //#region projectOwnerName Prop    
  @gridColumn({ class: ["projectsListRows"], style:{"min-width":"5%","max-width":"10%"}, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'projectOwnerName', keyColumn: false })
    projectOwnerName : string;
//#endregion projectOwnerName Prop

//#region projectName Prop
@gridColumn({ configuredTemplate: { templateName:"subprojectLink" }, class: ["projectname"], style:{"min-width":"5%","max-width":"40%"}, visible: true, columnIndex:6,  allowSorting: true, headerKey: 'projectName', keyColumn: false})
projectName : string;
//#endregion projectName Prop

  //#region projectStartDate Prop
  // @gridColumn({ class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'projectStartDate', keyColumn: false })
  //   projectStartDate : Date;
//#endregion projectStartDate Prop

  //#region projectEndDate Prop
  // @gridColumn({ style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, headerKey: 'projectEndDate', keyColumn: false })
  //   projectEndDate : Date;
//#endregion projectEndDate Prop

//#region departmentName Prop
@gridColumn({ parameter: false, style: { "min-width": "5%", "max-width": "10%" }, class: ["projectsListRows"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ProjectListComponent_DepartmentName_gh', keyColumn: false})
departmentName : string;
//#endregion departmentName Prop

@gridColumn({
  template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return '';
          }
        },
      }
  }, parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left","projectscompanyName"], visible: true, columnIndex: 8, allowSorting: false, headerKey: 'ProjectListComponent_CompanyName_gh', keyColumn: false
})

companyName:string


@gridColumn({
  // template: {
  //   div: {
  //     style: {
  //       "word-break": "break-word",
  //       "width": "100%"
  //     },
  //     attributes: {
  //       innerHTML: function (x, y) {
  //         return "<label style='margin-left:0%'>" + this.recentNote + "</label>";
  //       }
  //     },
  //     childrens: [{
  //       a: {
  //         isBind: (x) => {
  //           if (x.charCount > 100) {
  //             return true;
  //           }
  //           else {
  //             return false;
  //           }
  //         }, style: {
  //           "display": "block"
  //         },
  //         childrens: [{
  //           span: {
  //             class: "more-btn mr-2".split(" "),
  //             childrens: [{
  //               text: {
  //                 text: function (e) {
  //                   return "Show More";
  //                 }
  //               }
  //             }]
  //           }
  //         }],
  //         event: {
  //           click: "onMoreClick"
  //         }
  //       },
  //     }]
  //   }
  // }, 
  parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left", "recentNote"], visible: true, columnIndex: 9, allowSorting: false, headerKey: 'ProjectListComponent_RecentNote_gh', keyColumn: false
})

recentNote: string

//#region companyName Prop
// @gridColumn({ parameter: false, style: { "min-width": "5%", "max-width": "15%" }, class: ["project"], visible: false, columnIndex: 17, allowSorting: true, headerKey: 'ProjectListComponent_CompanyName_gh', keyColumn: false})
// companyName : string;
//#endregion companyName Prop



//#region noteCreatedDate
@gridColumn({ parameter: false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: false, headerKey: 'ProjectListComponent_NoteCreatedDate_gh', keyColumn: false })
noteCreatedDate: string;
//#endregion noteCreatedDate

  //#region projectIndicator Prop
  //@gridColumn({ parameter: false, configuredTemplate: { templateName: "progressBar" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'projectIndicator', keyColumn: false })
  @gridColumn({ parameter:false, configuredTemplate: { templateName: "progressBar" }, class: ["text-right"], visible: true, columnIndex:12,  allowSorting: true, headerKey: 'projectIndicator', keyColumn: false})
  projectIndicator : string;
//#endregion projectIndicator Prop 

  //#region countryName Prop
  @gridColumn({ parameter: false, class: ["projectsListRows"], style: { "min-width": "5%", "max-width": "10%" }, visible: true, columnIndex: 11, allowSorting: true, headerKey: 'ProjectListComponent_Country_gh', keyColumn: false })
  countryName: string;
  //#endregion countryName Prop

  //#region noteDescription Prop
  // @gridColumn({ class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey: 'noteDescription', keyColumn: false })
  // noteDescription : string;

//#endregion noteDescription Prop

//#region createdBy Prop
    // @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:10,  allowSorting: true, headerKey: 'createdBy', keyColumn: false})
    // createdBy : number;
//#endregion createdBy Prop

//#region noteAddedBy Prop
    // @gridColumn({style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:11,  allowSorting: true, headerKey: 'noteAddedBy', keyColumn: false})
    // noteAddedBy : string;
//#endregion noteAddedBy Prop

//#region createdDate Prop
    // @gridColumn({style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:12,  allowSorting: true, headerKey: 'createdDate', keyColumn: false})
    // createdDate : Date;
//#endregion createdDate Prop

//#region totalCount Prop
    @gridColumn({ parameter: true, name: "totalCount", visible: false, columnIndex: 13, allowSorting: true, headerKey: 'totalCount', keyColumn: false })
    totalCount: number;
//#endregion totalCount Prop

//#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 14,  keyColumn: false })
    parentId: number;
//#endregion totalCount Prop



}
