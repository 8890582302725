//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentUserSearchViewModel {

  userId: string;

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean
  
  //#region fullName
  @gridColumn({ configuredTemplate: { templateName: 'reDirectPeopleCorporateAndTooltip' }, parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_UserName_gh', keyColumn: false})
  fullName: string;
  //#endregion fullName

  //#region email
  @gridColumn({ parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_UserEmail_gh', keyColumn: false })
  email: string;
  //#endregion email

  //#region country
  @gridColumn({ parameter: false, style: { width: "16%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_UserCountry_gh', keyColumn: false })
  country: string;
  //#endregion country

  //#region keyRelationshipHolder
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_UserKeyRelHolder_gh', keyColumn: false})
  keyRelationshipHolder: string;
  //#endregion keyRelationshipHolder

  //#region salesAdvisor
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'MoveNoteDocumentComp_UserSalesAdv_gh', keyColumn: false })
  salesAdvisor: string;
  //#endregion salesAdvisor

  totalCount: number;

  UserProfilePicture: string;

  contactPermissionRequired: boolean;
  introducesBy: string;
  isUnwelcome: boolean;
}
