import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HSEStatusUpdateBase {

//#region hSEStatusMappingId Prop
        @prop()
        hSEStatusMappingId : number;
//#endregion hSEStatusMappingId Prop


//#region userId Prop
        @prop()
        userId : string;
//#endregion userId Prop

}