import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneClarityGemfieldsLookUpBase {

//#region clarityId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'clarityId', keyColumn: true})
        clarityId : number;
//#endregion clarityId Prop


//#region clarityName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'clarityName', keyColumn: false})
        clarityName : string;
//#endregion clarityName Prop

}