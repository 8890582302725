import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class POSBrandMappingBase {

//#region pOSBrandId Prop
        @prop()
        pOSBrandId : number;
//#endregion pOSBrandId Prop


//#region saleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        saleId : number;
//#endregion saleId Prop


//#region brandId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        brandId : number;
//#endregion brandId Prop

}