import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAdminRoleLookUpBase {

//#region roleId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'roleId', keyColumn: true})
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'roleName', keyColumn: false})
        roleName : string;
//#endregion roleName Prop

}