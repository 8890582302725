import {POSMemberMappingBase,SupplierBase,UserBase,} from '@app/database-models'
import { propArray } from '@rxweb/reactive-form-validators';
//Generated Imports
export class POSMemberMapping extends POSMemberMappingBase 
{




//#region Generated Reference Properties
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties

//#region pOSMemberMappingBase
@propArray(POSMemberMappingBase)
pOSMemberMappingBase: POSMemberMappingBase[];
//#endregion pOSMemberMappingBase

headerBtnCheck:boolean;
query:string;




































}