import {vPieceOwnerReportLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPieceOwnerReportLookUp extends vPieceOwnerReportLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}