import {
  prop,
  propObject,
  propArray,
  required,
  maxLength,
  range,
  numeric,
  pattern,
  trim,
  notEmpty,
} from "@rxweb/reactive-form-validators";
import { gridColumn } from "@rxweb/grid";
import { TravelReportModule } from "src/app/components/reports/people/travel-report/travel-report.module";
import { TravelSummaryViewModel } from "src/app/view-model/travel-summary-view-model";
import { empty } from "rxjs";
import { ManageInventoryMethodEnum } from "src/app/enums/manage-inventory-method.enum";

export class ProductBase {
  //#region productVariantId Prop
  @prop()
  productVariantId: number;
  //#endregion productVariantId Prop

  @prop()
  productTypeID: number;

  //#region productID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @required()
  //@prop()
  productID: number;
  //#endregion productID Prop

  //#region name Prop
  @required()
  @notEmpty()
  @trim()
  @maxLength({ value: 400 })
  name: string;
  //#endregion name Prop

  //#region webProductTitle Prop
  @trim()
  @maxLength({ value: 400 })
  webProductTitle: string;
  //#endregion webProductTitle Prop

  //#region sKU Prop
  // @required()
  @maxLength({ value: 100 })
  sKU: string;
  //#endregion sKU Prop

  //#region description Prop
  @prop({ defaultValue: "" })
  @trim()
  @maxLength({ value: 4000 })
  description: string;
  //#endregion description Prop

  //#region adminComment Prop
  @prop({ defaultValue: "" })
  @trim()
  @maxLength({ value: 4000 })
  adminComment: string;
  //#endregion adminComment Prop

  //#region manufacturerPartNumber Prop
  //@required()
  @prop({ defaultValue: "test" })
  @maxLength({ value: 100 })
  manufacturerPartNumber: string;
  //#endregion manufacturerPartNumber Prop

  //#region isGiftCard Prop
  @prop({ defaultValue: false })
  @required()
  isGiftCard: boolean;
  //#endregion isGiftCard Prop

  //#region giftCardType Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  giftCardType: number;
  //#endregion giftCardType Prop

  //#region isDownload Prop
  @prop({ defaultValue: false })
  @required()
  isDownload: boolean;
  //#endregion isDownload Prop

  //#region downloadID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  downloadID: number;
  //#endregion downloadID Prop

  //#region unlimitedDownloads Prop
  @prop({ defaultValue: true })
  @required()
  unlimitedDownloads: boolean;
  //#endregion unlimitedDownloads Prop

  //#region maxNumberOfDownloads Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @required()
  @prop({ defaultValue: 10 })
  maxNumberOfDownloads: number;
  //#endregion maxNumberOfDownloads Prop

  //#region downloadExpirationDays Prop
  @prop()
  downloadExpirationDays: number;
  //#endregion downloadExpirationDays Prop

  //#region downloadActivationType Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @required()
  @prop({ defaultValue: 1 })
  downloadActivationType: number;
  //#endregion downloadActivationType Prop

  //#region hasSampleDownload Prop
  @prop({ defaultValue: false })
  @required()
  hasSampleDownload: boolean;
  //#endregion hasSampleDownload Prop

  //#region sampleDownloadID Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @required()
  @prop({ defaultValue: 0 })
  sampleDownloadID: number;
  //#endregion sampleDownloadID Prop

  //#region hasUserAgreement Prop
  @prop({ defaultValue: false })
  @required()
  hasUserAgreement: boolean;
  //#endregion hasUserAgreement Prop

  //#region userAgreementText Prop
  @prop({ defaultValue: "test" })
  //@required()
  userAgreementText: string;
  //#endregion userAgreementText Prop

  //#region isRecurring Prop
  @prop({ defaultValue: false })
  @required()
  isRecurring: boolean;
  //#endregion isRecurring Prop

  //#region cycleLength Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 100 })
  @required()
  cycleLength: number;
  //#endregion cycleLength Prop

  //#region cyclePeriod Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  cyclePeriod: number;
  //#endregion cyclePeriod Prop

  //#region totalCycles Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 10 })
  @required()
  totalCycles: number;
  //#endregion totalCycles Prop

  //#region isShipEnabled Prop
  @prop({ defaultValue: true })
  @required()
  isShipEnabled: boolean;
  //#endregion isShipEnabled Prop

  //#region isFreeShipping Prop
  @prop({ defaultValue: false })
  @required()
  isFreeShipping: boolean;
  //#endregion isFreeShipping Prop

  //#region additionalShippingCharge Prop
  @prop({ defaultValue: 0.0 })
  @required()
  // @numeric({allowDecimal:true})
  @pattern({
    expression: { additionalShippingCharge: /^[0-9]\d{0,8}(\.\d{1,2})??$/ },
    message:
      "Please enter valid shipping charge:(9 digits and 2 decimal places allowed",
  })
  additionalShippingCharge: number;
  //#endregion additionalShippingCharge Prop

  //#region isTaxExempt //
  @required()
  @prop({ defaultValue: false })
  isTaxExempt: boolean;
  //#endregion isTaxExempt Prop

  //#region taxCategoryID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 8 })
  @required()
  taxCategoryID: number;
  //#endregion taxCategoryID Prop

  //#region manageInventory Prop
  @prop({ defaultValue: ManageInventoryMethodEnum.ManageStock })
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @required()
  manageInventory: number;
  //#endregion manageInventory Prop

  //#region stockQuantity Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  stockQuantity: number;
  //#endregion stockQuantity Prop

  //#region displayStockAvailability Prop
  @prop({ defaultValue: false })
  @required()
  displayStockAvailability: boolean;
  //#endregion displayStockAvailability Prop

  //#region displayStockQuantity Prop
  @prop({ defaultValue: false })
  @required()
  displayStockQuantity: boolean;
  //#endregion displayStockQuantity Prop

  //#region minStockQuantity Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  minStockQuantity: number;
  //#endregion minStockQuantity Prop

  //#region lowStockActivityID Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  lowStockActivityID: number;
  //#endregion lowStockActivityID Prop

  //#region notifyAdminForQuantityBelow Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 6 })
  @required()
  notifyAdminForQuantityBelow: number;
  //#endregion notifyAdminForQuantityBelow Prop

  //#region backorders Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  backorders: number;
  //#endregion backorders Prop

  //#region orderMinimumQuantity Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 1 })
  @required()
  orderMinimumQuantity: number;
  //#endregion orderMinimumQuantity Prop

  //#region orderMaximumQuantity Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 10000 })
  @required()
  orderMaximumQuantity: number;
  //#endregion orderMaximumQuantity Prop

  //#region warehouseID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  warehouseID: number;
  //#endregion warehouseID Prop

  //#region disableBuyButton Prop
  @prop({ defaultValue: false })
  @required()
  disableBuyButton: boolean;
  //#endregion disableBuyButton Prop

  //#region callForPrice Prop
  @prop({ defaultValue: false })
  @required()
  callForPrice: boolean;
  //#endregion callForPrice Prop

  //#region price Prop
  @prop({ defaultValue: 1.0 })
  @required()
  //@numeric({allowDecimal:true})
  @pattern({
    expression: { price: /^[1-9]\d{0,8}(\.\d{1,2})??$/ },
    message:
      "Please enter valid export price(9 digits and 2 decimal places allowed",
  })
  // @range({minimumNumber: 1.00, maximumNumber: 100000000.99,message:"Please enter value with in the range 1.00 - 100000000.99"})
  price: number;
  //#endregion price Prop

  //#region oldPrice Prop
  @prop({ defaultValue: 0.0 })
  @required()
  oldPrice: number;
  //#endregion oldPrice Prop

  //#region productCost Prop
  @required()
  @prop({ defaultValue: 0.0 })
  //@range({minimumNumber: 0, maximumNumber: 100000000,message:"Please enter value with in the range 0 - 100000000"})
  @pattern({
    expression: { productCost: /^[0-9]\d{0,8}(\.\d{1,2})??$/ },
    message:
      "Please enter valid expected reorder cost(9 digits and 2 decimal places allowed",
  })
  //@numeric({allowDecimal:true})
  productCost: number;
  //#endregion productCost Prop

  //#region customerEntersPrice Prop
  @prop({ defaultValue: 0 })
  @required()
  customerEntersPrice: boolean;
  //#endregion customerEntersPrice Prop

  //#region minimumCustomerEnteredPrice Prop
  @prop({ defaultValue: 0.0 })
  @required()
  minimumCustomerEnteredPrice: number;
  //#endregion minimumCustomerEnteredPrice Prop

  //#region maximumCustomerEnteredPrice Prop
  @prop({ defaultValue: 1000.0 })
  @required()
  maximumCustomerEnteredPrice: number;
  //#endregion maximumCustomerEnteredPrice Prop

  //#region weight Prop
  @prop({ defaultValue: 0.0 })
  @required()
  //@numeric({allowDecimal:true})
  @pattern({
    expression: { weight: /^[0-9]\d{0,5}(\.\d{1,2})??$/ },
    message: "Please enter valid weight(6 digits and 2 decimal places allowed)",
  })
  weight: number;
  //#endregion weight Prop

  //#region length Prop
  @prop({ defaultValue: 0.0 })
  @required()
  //@numeric({allowDecimal:true})
  @pattern({
    expression: { length: /^[0-9]\d{0,5}(\.\d{1,2})??$/ },
    message: "Please enter valid depth(6 digits and 2 decimal places allowed)",
  })
  length: number;
  //#endregion length Prop

  //#region width Prop
  @prop({ defaultValue: 0.0 })
  @required()
  //@numeric({allowDecimal:true})
  @pattern({
    expression: { height: /^[0-9]\d{0,5}(\.\d{1,2})??$/ },
    message: "Please enter valid width(6 digits and 2 decimal places allowed)",
  })
  width: number;
  //#endregion width Prop

  //#region height Prop
  @prop({ defaultValue: 0.0 })
  @required()
  //@numeric({allowDecimal:true})
  @pattern({
    expression: { height: /^[0-9]\d{0,5}(\.\d{1,2})??$/ },
    message: "Please enter valid height(6 digits and 2 decimal places allowed)",
  })
  height: number;
  //#endregion height Prop

  //#region pictureID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  pictureID: number;
  //#endregion pictureID Prop
  //#region pictureID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  pictureID2: number;
  //#endregion pictureID Prop

  //#region availableStartDateTime Prop
  @prop()
  availableStartDateTime: Date;
  //#endregion availableStartDateTime Prop

  //#region availableEndDateTime Prop
  @prop()
  availableEndDateTime: Date;
  //#endregion availableEndDateTime Prop

  //#region published Prop
  @prop({ defaultValue: false })
  @required()
  published: boolean;
  //#endregion published Prop

  //#region deleted Prop
  @prop({ defaultValue: false })
  @required()
  deleted: boolean;
  //#endregion deleted Prop

  //#region displayOrder Prop
  @prop({ defaultValue: 1 })
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  displayOrder: number;
  //#endregion displayOrder Prop

  //#region createdOn Prop
  @prop({ defaultValue: new Date() })
  @required()
  createdOn: Date;
  //#endregion createdOn Prop

  //#region updatedOn Prop
  @prop({ defaultValue: new Date() })
  @required()
  updatedOn: Date;
  //#endregion updatedOn Prop

  //#region designStatus Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  // @required()
  @prop({ defaultValue: 0 })
  designStatus: number;
  //#endregion designStatus Prop

  //#region orderStatus Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  orderStatus: number;
  //#endregion orderStatus Prop

  //#region video Prop
  @maxLength({ value: 255 })
  video: string;
  //#endregion video Prop

  //#region classicalMusic Prop
  @maxLength({ value: 255 })
  classicalMusic: string;
  //#endregion classicalMusic Prop

  //#region contemporaryMusic Prop
  @maxLength({ value: 255 })
  contemporaryMusic: string;
  //#endregion contemporaryMusic Prop

  //#region shippingNotes Prop
  @maxLength({ value: 255 })
  shippingNotes: string;
  //#endregion shippingNotes Prop

  //#region customsDescription Prop
  @maxLength({ value: 255 })
  customsDescription: string;
  //#endregion customsDescription Prop

  //#region pageTheme Prop
  @maxLength({ value: 50 })
  pageTheme: string;
  //#endregion pageTheme Prop

  //#region customPrice Prop
  @prop({ defaultValue: 0.0 })
  @required()
  //@numeric({allowDecimal:true})
  @pattern({
    expression: { customPrice: /^[0-9]\d{0,8}(\.\d{1,2})??$/ },
    message:
      "Please enter valid custom price(9 digits and 2 decimal places allowed",
  })
  customPrice: number;
  //#endregion customPrice Prop

  //#region noTimesViewed Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  noTimesViewed: number;
  //#endregion noTimesViewed Prop

  //#region occassionID Prop
  @prop()
  occassionID: number;
  //#endregion occassionID Prop

  //#region maxNumberOfMakeToOrder Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  // @numeric({allowDecimal:false})
  @pattern({
    expression: { maxNumberOfMakeToOrder: /^[0-9]{1,5}$/ },
    message: "Please enter value with in the range 0 - 99999",
  })
  @required()
  maxNumberOfMakeToOrder: number;
  //#endregion maxNumberOfMakeToOrder Prop

  //#region isMakeToOrder Prop
  @prop({ defaultValue: false })
  @required()
  isMakeToOrder: boolean;
  //#endregion isMakeToOrder Prop

  //#region makeToOrderDeliveryTime Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  //@numeric({allowDecimal:false})
  @required()
  @pattern({
    expression: { makeToOrderDeliveryTime: /^[0-9]{1,5}$/ },
    message: "Please enter value with in the range 0 - 99999",
  })
  makeToOrderDeliveryTime: number;
  //#endregion makeToOrderDeliveryTime Prop

  //#region totalNumberOfMakeToOrder Prop
  // @range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  @pattern({
    expression: { totalNumberOfMakeToOrder: /^[0-9]{1,5}$/ },
    message: "Please enter value with in the range 0 - 99999",
  })
  totalNumberOfMakeToOrder: number;
  //#endregion totalNumberOfMakeToOrder Prop

  //#region occassionForHim Prop
  @prop({ defaultValue: false })
  @required()
  occassionForHim: boolean;
  //#endregion occassionForHim Prop

  //#region occassionForHer Prop
  @prop({ defaultValue: false })
  @required()
  occassionForHer: boolean;
  //#endregion occassionForHer Prop

  //#region showSalesTeam Prop
  @prop({ defaultValue: false })
  @required()
  showSalesTeam: boolean;
  //#endregion showSalesTeam Prop

  //#region pictureID1 Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  @prop({ defaultValue: 0 })
  @required()
  pictureID1: number;
  //#endregion pictureID1 Prop

  //#region isOneOff Prop
  @prop({ defaultValue: false })
  @required()
  isOneOff: boolean;
  //#endregion isOneOff Prop

  //#region isLoggedOnly Prop
  @prop({ defaultValue: false })
  @required()
  isLoggedOnly: boolean;
  //#endregion isLoggedOnly Prop

  //#region inspiration Prop
  @maxLength({ value: 4000 })
  inspiration: string;
  //#endregion inspiration Prop

  //#region currencyExpectedReorderPrice Prop
  @prop()
  currencyExpectedReorderPrice: number;
  //#endregion currencyExpectedReorderPrice Prop

  //#region supplierID Prop
  @prop()
  supplierID: number;
  //#endregion supplierID Prop

  //#region isDisplayCreations Prop
  @prop({ defaultValue: false })
  isDisplayCreations: boolean;
  //#endregion isDisplayCreations Prop

  //#region displayOrderforCreations Prop
  @prop()
  displayOrderforCreations: number;
  //#endregion displayOrderforCreations Prop

  //#region backgroundTips Prop
  @maxLength({ value: 4000 })
  backgroundTips: string;
  //#endregion backgroundTips Prop

  //#region manufacturingGuidelines Prop
  @maxLength({ value: 4000 })
  manufacturingGuidelines: string;
  //#endregion manufacturingGuidelines Prop

  //#region tempDescription Prop
  @prop({ defaultValue: "" })
  @trim()
  @maxLength({ value: 4000 })
  tempDescription: string;
  //#endregion tempDescription Prop

  //#region stockcardPictureID Prop
  @prop()
  stockcardPictureID: number;
  //#endregion stockcardPictureID Prop

  //#region coreProduct Prop
  @prop({ defaultValue: false })
  coreProduct: boolean;
  //#endregion coreProduct Prop

  //#region storedate Prop
  @prop()
  storedate: Date;
  //#endregion storedate Prop

  //#region noTag Prop
  @prop({ defaultValue: false })
  noTag: boolean;
  //#endregion noTag Prop

  //#region noReorder Prop
  @prop({ defaultValue: false })
  noReorder: boolean;
  //#endregion noReorder Prop

  //#region priceResearch Prop
  @maxLength({ value: 500 })
  priceResearch: string;
  //#endregion priceResearch Prop

  //#region metaTitle Prop
  @maxLength({ value: 400 })
  metaTitle: string;
  //#endregion metaTitle Prop

  //#region metaKeywords Prop
  @maxLength({ value: 400 })
  metaKeywords: string;
  //#endregion metaKeywords Prop

  //#region metaDescription Prop
  @maxLength({ value: 4000 })
  metaDescription: string;
  //#endregion metaDescription Prop

  //#region isAllRRP Prop
  @prop({ defaultValue: false })
  @required()
  isAllRRP: boolean;
  //#endregion isAllRRP Prop

  //#region imageAlt Prop
  @prop({ defaultValue: "" })
  @trim()
  @maxLength({ value: 400 })
  imageAlt: string;
  //#endregion imageAlt Prop

  //#region isHighJewellery Prop
  @prop({ defaultValue: false })
  @required()
  isHighJewellery: boolean;
  //#endregion isHighJewellery Prop

  //#region chainColourID Prop
  //@range({minimumNumber:1,maximumNumber:2147483647})
  //@required()
  @prop({ defaultValue: 0 })
  chainColourID: number;
  //#endregion chainColourID Prop

  //#region superSeller Prop
  @prop({ defaultValue: false })
  @required()
  superSeller: boolean;
  //#endregion superSeller Prop

  //#region sEName Prop
  @maxLength({ value: 4000 })
  seName: string;
  //#endregion sEName Prop

  //#region isFavourites Prop
  @prop({ defaultValue: false })
  isFavourites: boolean;
  //#endregion isFavourites Prop

  //#region productVariant_Description Prop
  @prop()
  productVariant_Description: string;
  //#endregion productVariant_Description Prop

  //#region productVariant_ShortDescription Prop
  @prop()
  productVariant_ShortDescription: string;
  //#endregion productVariant_ShortDescription Prop

  //#region showImage Prop
  // @prop({defaultValue:false})
  // @required()
  showImage: boolean;
  //#endregion showImage Prop

  //#region xmlFeedProductTitle Prop
  @required()
  @notEmpty()
  @trim()
  @maxLength({ value: 150 })
  xmlFeedProductTitle: string;
  //#endregion xmlFeedProductTitle Prop

  //#region careDescription Prop
  @prop()
  @trim()
  careDescription: string;
  //#endregion careDescription Prop

  @prop({ defaultValue: '' })
  @maxLength({ value: 400 })
  @trim()
  productEnamelReference: string;

    //#285950/ Naitik Bhatt/21-05-2024  remove  hsCode and added productHSCode
   //#region producthsCode Prop
   @prop()
   @required()
   productHSCode: number;
   //#endregion producthsCode Prop
}
