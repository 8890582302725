import { CorporateCompanyMappingBase, } from '@app/database-models'
import { A } from '@fullcalendar/core/internal-common';
import { propArray } from '@rxweb/reactive-form-validators';
//Generated Imports
export class CorporateCompanyMapping extends CorporateCompanyMappingBase {




    //#region Generated Reference Properties

    //#endregion Generated Reference Properties

    //#region corporateCompanyMappingBase
    @propArray(CorporateCompanyMappingBase)
    corporateCompanyMappingBase: CorporateCompanyMappingBase[];
    //#endregion corporateCompanyMappingBase

    headerBtnCheck:boolean;

    query:any;









}