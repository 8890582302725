import {BespokeOrderBase,SupplierBase,UserBase,} from '@app/database-models'

//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
export class BespokeOrder extends BespokeOrderBase implements FileDetailViewModel
{

    user : UserBase;


@prop()
pieceIds : string;

@prop()
supplier : SupplierBase;
@prop()
salesPersonName : string;

@prop()
clientName : string;


@prop()
clientURL : string;


@prop()
isApprove : number;

  
      //#region showBespokeOrderApproval Prop
      showBespokeOrderApproval:boolean;
      //#endregion showBespokeOrderApproval Prop
//#region Generated Reference Properties


//#endregion Generated Reference Properties

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;


















}