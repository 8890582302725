import {vDepartmentRecordBase,} from '@app/database-models'
//Generated Imports
export class vDepartmentRecord extends vDepartmentRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}