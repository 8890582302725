import {vCountryLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCountryLookUp extends vCountryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}