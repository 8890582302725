import { prop, range, required, notEmpty, numeric } from '@rxweb/reactive-form-validators';
import { CLIENT_URL } from '../domain/system-constant';

export class PressMediaViewSearchModel{
    @prop()
    moduleId:string;
    @prop()
    moduleType:string;
    @prop()
    riskId : number;
    @prop({defaultValue:25})
    rowCount:number;
    @prop({defaultValue:1})
    pageIndex:number;
    @prop({defaultValue:'pressMediaId'})
    orderBy:string;
    @prop({defaultValue:false})
    sortOrder:boolean;
    // @prop({defaultValue:CLIENT_URL})
    // clientUrl:string;
    @prop()
    searchString:string;
    @prop({defaultValue:false})
    isShowAll:boolean;
}
