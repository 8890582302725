<div class="modal-dialog modal-lg" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="modal-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">
      <div class="modal-header text-left">
          <h4 class="modal-title mb-0" rxText="DocumentTypeUserMappingAddComponent_DTUserMap_t"></h4>
          <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
          </button>
      </div>
      <!-- <div class="modal-body pb-0"  [rxSpinner]="spin" (keyup.enter)="bindGrid()">
          <div class="dataTables_filter  d-flex flex-wrap align-items-center">
          
          <label class="ml-auto mr-0 mb-3">
            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
              placeholder="" [title]="titleSearch">
          </label>
        </div>
          <div class="form-group row" *ngIf="totalRecords>0">
              <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                  <div class="table-responsive">
                      <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                          <rx-grid [design]="documentTypeUserGrid"></rx-grid>
                      </div>
                  </div>
              </div>
          </div>
          <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
          </div>
      </div> -->

      <div class="modal-body" 
            [formGroup]="documentTypeUserMappingAddFilterFormGroup" (keyup.enter)="search()" >

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-12 col-form-label"  rxText="DocumentTypeUserMappingAddComponent__Name_t"></label>
                        <div class="col-md-12">
                            <input type="text" class="form-control" tabindex="1" formControlName="name" placeholder="Name" rxFocus>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-12 col-form-label"  rxText="DocumentTypeUserMappingAddComponent_Email_t"></label>
                        <div class="col-md-12">
                            <input type="text" class="form-control" tabindex="3" formControlName="email" placeholder="Email">

                            <small class="form-text text-danger"
                                [class.d-block]="documentTypeUserMappingAddFilterFormGroup.controls.email.errors">{{documentTypeUserMappingAddFilterFormGroup.controls.email.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-md-12 col-form-label" rxText="DocumentTypeUserMappingAddComponent_Corporate_t"></label>
                      <div class="col-md-12" [rxSelectExtended]="_this">
                        <rx-select [(source)]="documentTypeCorporateMappingLookup.companyLookUp" #rxSelect mainClass="form-control"
                          [controlTabIndex]="6" formControlName="corporate" [keyValueProps]="['company', 'supplierId']"
                          [selectPlaceholder]="'Select Corporate/Groups'"></rx-select>
                      </div>
                    </div>
                </div>                
            </div>
            <div class="hr-line-dashed border-top w-100"></div>
            <div class="row mx-0">
                <div class="form-group ml-auto mb-0">
                    <button (click)="search()" class="btn btn-success" rxText="Btn_Search_t">Search</button>
                </div>
            </div>
            <div class="table-responsive mt-3" (keyup.enter)="save()" *ngIf="showGrid" [rxLocalisationInit]="componentName">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
                    <div class="table-responsive" *ngIf="isShowGrid">
                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                            <rx-grid [design]="documentTypeUserGrid"></rx-grid>
                        </div>

                    </div>
                    <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
                        <h4>{{ErrorMsg}}</h4>
                    </div>
                </div>
            </div>
        </div>
      <!-- <div class="modal-footer" *ngIf="totalRecords>0" [rxLocalisationInit]="componentName">
          <button type="button" class="btn btn-success" (click)="saveDocumentTypeUserMappings()"
              [disabled]="documentTypeUserMappingArray.length <= 0" rxText="Btn_Save_t">Save</button>
          <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t">Close</button>
      </div> -->

      <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
        <button (click)="save()" class="btn btn-success"
            [disabled]="documentTypeUserMapping.documentTypeUserMappingBase.length == 0 && this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.length == 0 && !this.headerBtnChk" 
            rxText="Btn_Save_t">Save</button>
        <button (click)="hide()" class="btn btn-outline-primary ml-0" rxText="Btn_Close_t">Close</button>
    </div>
  </div>
</div>