import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHolidayProcessBase {

//#region id Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'CustomerHolidayListComponent_Id_gh', keyColumn: true})
        id : number;
//#endregion id Prop


//#region userId Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'CustomerHolidayListComponent_UserId_gh', keyColumn: false})
        userId : number;
//#endregion userId Prop

//#region userName Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'CustomerHolidayListComponent_UserName_gh', keyColumn: false})
        userName : string;
//#endregion userName Prop

//#region holidayType Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:2, allowSorting: true, headerKey: 'CustomerHolidayListComponent_HolidayType_gh', keyColumn: false})
        holidayType : number;
//#endregion holidayType Prop


//#region leaveTypeName Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:3, allowSorting: true, headerKey: 'CustomerHolidayListComponent_TypeName_gh', keyColumn: false})
        leaveTypeName : string;
//#endregion leaveTypeName Prop


//#region openingBalance Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:4, allowSorting: true, headerKey: 'CustomerHolidayListComponent_OpeningBalance_gh', keyColumn: false})
        openingBalance : number;
//#endregion openingBalance Prop


//#region holidayAlloted Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:5, allowSorting: true, headerKey: 'CustomerHolidayListComponent_HolidayAlloted_gh', keyColumn: false})
        holidayAlloted : number;
//#endregion holidayAlloted Prop


//#region weekOff Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:6, allowSorting: false, headerKey: 'CustomerHolidayListComponent_WeekOff_gh', keyColumn: false})
        weekOff : string;
//#endregion weekOff Prop


//#region workPublicHoliday Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:7, allowSorting: false, headerKey:'CustomerHolidayListComponent_WorkOnPubHoliday_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" }})
        workPublicHoliday : boolean;
        // headerKey: 'workPublicHoliday',
//#endregion workPublicHoliday Prop


//#region forYear Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:8, allowSorting: true, headerKey: 'CustomerHolidayListComponent_ForYear_gh', keyColumn: false})
        forYear : number;
//#endregion forYear Prop


//#region totalCount Prop
        @gridColumn({ visible: false, columnIndex:9, keyColumn: false})
        totalCount : number;
//#endregion totalCount Prop

        roleId:any;
}