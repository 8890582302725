import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemstoneInventoryStatusGemFieldBase {

//#region gemstoneInventoryStatusId Prop
        @prop()
        gemstoneInventoryStatusId : number;
//#endregion gemstoneInventoryStatusId Prop


//#region gemstoneInventoryStatusName Prop
        @required()
        gemstoneInventoryStatusName : string;
//#endregion gemstoneInventoryStatusName Prop

}