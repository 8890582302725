import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSBrandBase {

        @gridColumn({ name:"isChecked",  configuredTemplate: { templateName: 'bindCheckBox' }, visible: true, columnIndex:0,  allowSorting: false, headerKey: 'image', headerTitle: "Action", keyColumn: false, style: { width: "2%", "text-align": "initial" }})
        // @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'brandId', keyColumn: true})
        isChecked : boolean;

  //#region brandId Prop
          @gridColumn({  visible: false, columnIndex:1,  allowSorting: false})
         // @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'brandId', keyColumn: true})
          brandId : number;
  //#endregion brandId Prop

//#region brandId Prop
        @gridColumn( {style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2,  allowSorting: true, headerKey: 'POSBrandListComponent_BrandIdNo_gh', keyColumn: true})
        // @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'brandId', keyColumn: true})
        brandIdNumber : number;
//#endregion brandId Prop


//#region brandName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'POSBrandListComponent_BrandName_gh', keyColumn: false})
        brandName : string;
//#endregion brandName Prop


//#region statusId Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'POSBrandListComponent_StatusId_gh', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region isActive Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'POSBrandListComponent_IsActive_gh', keyColumn: false,configuredTemplate: { templateName: "recordActive" } })
        isActive : boolean;
//#endregion isActive Prop

}
