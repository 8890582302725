import { TemplateConfig, gridColumn } from '@rxweb/grid';

export const PARSE_HTML_TEMPLATE: TemplateConfig = {
    div: {
        attributes: {
            innerHTML: function(x,y){
                        console.log(x,y);
                 }
            }
          }
    }
   