import { email, maxLength, prop, trim } from "@rxweb/reactive-form-validators";

export class DocumentTypeUserMappingAddFilterViewModel {
    @prop()
    documentTypeId: number;

    @prop()
    name: string;

    @prop()
    @maxLength({ value: 255 })
    @trim()
    email: string;

    @prop()
    corporate:number;

}
