import {ProductOrderBase,OrderBase,ProductBase,ProductPieceOrderBase,} from '@app/database-models'
//Generated Imports
export class ProductOrder extends ProductOrderBase 
{




//#region Generated Reference Properties
//#region order Prop
order : OrderBase;
//#endregion order Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop
//#region productPieceOrders Prop
productPieceOrders : ProductPieceOrderBase[];
//#endregion productPieceOrders Prop

//#endregion Generated Reference Properties











}