import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractBankHoliday } from '../domain/abstract-bank-holidays';
import { HolidayList, vHolidayList } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';

import { DatePipe, PlatformLocation } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { BankHolidayAddComponent } from '../add/bank-holidays-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("BankHolidayListComponent")
@access({ accessLevel: RolePermissionEnums.BankHolidays, action: "get" })


@Component({
    selector: "app-bank-holiday-list",
    templateUrl: './bank-holidays-list.component.html'
})
export class BankHolidayListComponent extends AbstractBankHoliday implements OnInit, OnDestroy {
    holidayLists: vHolidayList[];
    subscription: any;
    holidayListsGrid: AppGrid;
    holidayAdd: any = BankHolidayAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private activatedRoute: ActivatedRoute, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private datePipe: DatePipe, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.BankHolidays;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Bank Holiday - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.activatedRoute.queryParams.subscribe(t => {
            this.domainType = t['domain'];
        })
        this.subscription = this.get().subscribe((t: vHolidayList[]) => {
            // t.forEach(x=>x.holidayYear = this.datePipe.transform(x.holidayYear,'dd/MMM/yyyy'));
            this.spin = false;
            this.holidayLists = t;
            if (this.holidayLists.length > 0) {
                this.holidayLists.forEach(x => {
                    x.isGemfield = "false";
                })
            }
            this.holidayListsGrid = new AppGrid(t, vHolidayList, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.holidayListsGrid.gridColumns.forEach(x => {
                if (this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "put" }) && this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "delete" });
                    }
                }
            })
            this.holidayListsGrid.componentId = this.componentName;
            this.holidayListsGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.holidayListsGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.holidayListsGrid.design(document.getElementById("holiday-lists"));
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    onEdit(holidayLists: vHolidayList) {
        // let link="/holiday-lists/"+holidayLists.holidayId;
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/bank-holidays', holidayLists.holidayId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(['/bank-holidays', holidayLists.holidayId]);

    }

    onDelete(holidayLists: vHolidayList) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [holidayLists.holidayId] }).subscribe(data => {
                    this.spin = false;
                    this.holidayListsGrid.remove(holidayLists.holidayId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/bank-holidays']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Bank_Holiday");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.holidayLists.filter(x => x.holidayName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.holidayId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || (x.country == null ? "" : x.country).toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.holidayDate.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.holidayListsGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.holidayListsGrid = null;
        }
        setTimeout(() => {
            if (this.holidayListsGrid == null || this.holidayListsGrid == undefined) {
                this.holidayListsGrid = new AppGrid(this.holidayLists, vHolidayList, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.holidayListsGrid.gridColumns.forEach(x => {
                    if (this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "put" }) && this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.holidayListsGrid.authorize({ accessLevel: RolePermissionEnums.BankHolidays, action: "delete" });
                        }
                    }
                })
                this.holidayListsGrid.componentId = this.componentName;
                this.holidayListsGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.holidayListsGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.holidayListsGrid.refresh("holiday-lists");
                this.holidayListsGrid.design(document.getElementById("holiday-lists"));
            }
            this.holidayListsGrid.search = value.target.value;
        }, 0);
    }

    get componentName(): string {
        return "BankHolidayListComponent";
    }


}
