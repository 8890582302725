<div class="modal-dialog modal-xl">
    <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0">
                <label rxText="CorporateCreditRatingViewComponent_Title_t"></label>
            </h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body pb-0" style="height: 625px; overflow-y: scroll;">
            <table class="table table-responsive">
                <thead>
                    <tr>
                        <th><label rxText="CorporateCreditRatingViewComponent_Moody_t"></label></th>
                        <th><label rxText="CorporateCreditRatingViewComponent_SP_t"></label></th>
                        <th><label rxText="CorporateCreditRatingViewComponent_Fitch_t"></label></th>
                        <th><label rxText="CorporateCreditRatingViewComponent_CWorth_t"></label></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of corporateCreditRating">
                        <td>{{item.moody}}</td>
                        <td>{{item.sp}}</td>
                        <td>{{item.fitch}}</td>
                        <td>{{item.creditWorthiness}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button (click)="hide()" class="btn btn-outline-primary">Close</button>
        </div>
    </div>
</div>