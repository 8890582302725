import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSCreditCardTypeLookUpBase {

//#region creditCardTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'creditCardTypeId', keyColumn: true})
        creditCardTypeId : number;
//#endregion creditCardTypeId Prop


//#region creditCartTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'creditCartTypeName', keyColumn: false})
        creditCartTypeName : string;
//#endregion creditCartTypeName Prop

}