import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { AbstractTaxCategory } from '../domain/abstract-tax-category';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute,Router } from '@angular/router';
import { TaxCategory } from '@app/models';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("TaxCategoryEditComponent")
@access({ accessLevel: RolePermissionEnums.TaxCategories, action: "put" })

@Component({
    selector: "app-tax-category-edit",
    templateUrl: './tax-category-edit.component.html'
})
export class TaxCategoryEditComponent extends AbstractTaxCategory implements OnInit, OnDestroy {
    taxCategory: TaxCategory;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    taxClassDelete:any=dbAccessModule[RolePermissionEnums.TaxCategories]["delete"];
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,private applicationBroadcaster:ApplicationBroadcaster,private router:Router, modelView: ModalView, private el: ElementRef, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.TaxCategories;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Tax Classes - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {

            if (t) {
                this.taxCategoryFormGroup = this.formBuilder.formGroup(TaxCategory,t) as IFormGroup<TaxCategory>;
                this.showComponent = true;
            }
            else {
                this.router.navigate(['/tax-category']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editTaxCategory(isRedirect:boolean)
    {   this.taxCategoryFormGroup.submitted = true;
        if (this.taxCategoryFormGroup.valid) {
            this.spin=true;
            this.put({ body: this.taxCategoryFormGroup.value, params: [this.id]}).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.ngOnInit() : this.router.navigate(['/tax-category']);
            })
        }else{
            for (const key of Object.keys(this.taxCategoryFormGroup.controls)) {
                if (this.taxCategoryFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    deleteTaxCategory() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/tax-category']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Tax_Category");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    get componentName(): string {
      return "TaxCategoryEditComponent";
    }
}
