import { Component, OnInit, OnDestroy, EventEmitter, Input, ViewChild } from "@angular/core"
import { AbstractProjectEvent } from '../domain/abstract-project-event';
import { vProjectEvents } from "@app/models";
import { Subscription } from 'rxjs';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe, PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGrid } from 'src/app/domain/app-grid';
import { ProjectEventAddComponent } from '../add/project-event-add.component';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { SERVER_URL } from "src/app/domain/system-constant";
import * as CryptoJS from 'crypto-js';
import { ProjectEventAttendeesViewModel } from "src/app/view-model/project-event-attendees-view-model";
import { Workbook } from "exceljs";
import * as moment from "moment";
import * as fs from 'file-saver';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { GridColumnConfig } from '@rxweb/grid';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
//import { setInterval, setTimeout } from "timers";

@multilingual("ProjectEventListComponent")

@Component({
    selector: "app-project-event-list",
    templateUrl: './project-event-list.component.html'
})
export class ProjectEventListComponent extends AbstractProjectEvent implements OnInit, OnDestroy {
    @Input() hide: Function;
    @ViewChild('closebutton', {static: false}) closebutton;
    projectEventsMappings: vProjectEvents[];
    projectEvents: vProjectEvents;
    projectEventsGrid: AppGrid;
    projectEventsGridColumns: any;
    subscription: any;
    id: number;
    modalView: ModalView;
    isFilter: boolean = false;
    totalRecords: number = 0;
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    paginationViewModel: PaginationViewModel;

    gridSearchString: string = "";
    selectedEvents: number[];
    json: any;
    eventAttendees: boolean = true;
    attendees: ProjectEventAttendeesViewModel[] = [];
    jsonEventAttendees: any;
    projectEventAttendeesGrid: AppGrid;
    exportExcel = new ExportToExcelModel();
    list: any[];
    isHideAll: boolean = false;
    isShowAll: boolean = false;
    spinner: boolean = false;

    pageIndexAttendees: number = PaginationEnum.PageIndex;
    rowCountAttendees: number = PaginationEnum.RowCount;
    paginationViewModelAttendees: PaginationViewModel;
    isFilterAttendees: boolean = false;
    sort: boolean;
    totalRecordsAttendees: number = 0;
    titleSearch: string;
    titleClose: string;
    dropdownCount: number;
    attendeesSearchString: string = "";
    isLongTextExists: boolean=false;
    isShowGridColumn: boolean = false;
    titleGridColumnSetting: string;
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    arrConfig: any[];
    @Input() moduleID: any;
    @Input() selectedTab: string = "";

    constructor(modelView: ModalView, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, router: Router, private browserStorage: BrowserStorage,private location : PlatformLocation,private blobState: BlobUploadsViewStateService) {
        super();
        this.router = router;
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        this.modalView = modelView;
        this.selectedEvents = new Array<number>();
        this.dropdownCount = 1;
        Window["ProjectEventListComponent"] = this;
        location.onPopState(() => {this.closebutton.nativeElement.click(), this.dialog.hide(), this.hide() });
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.paginationViewModel = new PaginationViewModel();
        this.paginationViewModel.sortOrder = "false";
        this.paginationViewModel.orderBy = "eventStartDate";
        this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
            this.getColumnsData = t;
            if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            }
            setTimeout(() => {
                this.bindGrid();
            }, 0);
        })

        this.paginationViewModelAttendees = new PaginationViewModel();

        this.paginationViewModelAttendees.orderBy = "EventURL";
        this.paginationViewModelAttendees.sortOrder = 'true';
        this.paginationViewModelAttendees.pageIndex = PaginationEnum.PageIndex;
        this.paginationViewModelAttendees.rowCount = PaginationEnum.RowCount;
        //this.GetAttendeesData();

    }

    bindGrid(isOnload: boolean = true) {
        this.isShowGrid = true;
        this.spin = true;
        this.json["projectId"] = this.id;
        this.json["userId"] = 0;
        this.json["projectOrEvent"] = 0;
        this.json["clientUrl"] = SERVER_URL;
        this.subscription = this.get({ path: "api/ProjectEventMapping", queryParams: { query: encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((t: any) => {
            this.spin = false;
            this.eventAttendees = true;
            let jsonButton;
            jsonButton = JSON.parse(JSON.stringify(this.paginationViewModelAttendees));
            jsonButton["projectId"] = this.id;
            jsonButton["ClientUrl"] = CLIENT_URL;
            jsonButton["searchString"] = this.attendeesSearchString;
            this.get(
                { path: "api/SearchProject/GetAllEventAttendees", queryParams: { "query": encodeURIComponent(JSON.stringify(jsonButton)) } }
            ).subscribe((t: ProjectEventAttendeesViewModel[]) => {
                if (t.length > 0) {
                    this.eventAttendees = false;
                }
                else {
                    this.eventAttendees = true;
                }
            });
            if (this.projectEventsGrid) {
                this.projectEventsGrid.storeProcedure.length = 0;
            }
            this.projectEventsMappings = t;
            // if (isOnload) {
            //     for (let element of this.projectEventsMappings) {
            //         if (element.noteCharCount > 100) {
            //             this.isShowAll = true;
            //             break;
            //         }
            //     }
            // }
            if (this.projectEventsMappings.length > 0) {
                this.totalRecords = this.projectEventsMappings[0].totalCount;
                this.projectEventsMappings.forEach(x => {
                    if (this.selectedEvents.indexOf(x.eventID) > -1) {
                        x.isChecked = true;
                    } else {
                        x.isChecked = false;
                    }
                });
                // this.projectEventsMappings.forEach(x => {
                //     x.isChecked = true;
                // });
                this.isNoRecordFound = false;
            }
            else {
                this.isLongTextExists = false;
                this.isNoRecordFound = true;
                this.isShowGrid = false;
            }
            //onEdit: this.onEdit.bind(this),
            if (!this.isFilter) {
                this.projectEventsGrid = new AppGrid(this.projectEventsMappings, vProjectEvents, { actions: { onOneLink: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this), onMoreNotelick: this.onMoreNotelick.bind(this) } });
                this.projectEventsGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
                if (this.arrConfig != undefined && this.arrConfig != null) {
                    this.arrConfig.forEach(x => {
                        this.projectEventsGrid.gridColumns.forEach(y => {
                            if (y.name == x.ColumnName)
                                y.visible = x.Visible;
                        });
                    });
                    this.gridColumnConfig = this.projectEventsGrid.gridColumns;
                    this.gridColumnSettings.emit(this.gridColumnConfig)
                }
                this.checkForLongTexts();
                this.projectEventsGrid.gridColumns.forEach(x => {
                    if (x.columnIndex == 0) {
                        x.visible = true;

                    } if (x.columnIndex == 3) {
                        x.visible = false;
                    }
                });
            }
            else {
                this.projectEventsGrid.storeProcedure.length = this.totalRecords;
                this.projectEventsGrid.reDesign();
                this.projectEventsGrid.updateSource([])
                this.projectEventsGrid.updateSource(this.projectEventsMappings);
                this.checkForLongTexts();
            }
            this.projectEventsGrid.designClass.tableClass.push("table-list");
            this.projectEventsGrid.maxPerPage = this.paginationViewModel.rowCount;
            this.projectEventsGrid.componentId = this.componentName;
        });
    }
    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }
    applyVisible(gridColumnConfig: GridColumnConfig[]) {
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(element => {
            var index = this.projectEventsGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
            if (index != -1) {
              if (this.projectEventsGrid.gridColumns[index].visible != element.Visible) {
                element.Visible = this.projectEventsGrid.gridColumns[index].visible;
              }
            }
          })
        }
      this.checkForLongTexts();
        this.projectEventsGrid.reDesign();
        this.projectEventsGrid.updateSource([])
        this.projectEventsGrid.updateSource(this.projectEventsMappings);
    }
    showOrHideAll() {
        this.isShowAll = !this.isShowAll;
        this.isFilter = false;
        this.projectEventsGrid.destroy();
        this.projectEventsGrid = null;
        this.json["isShowAll"] =this.isShowAll;
        this.bindGrid();
      }
      checkForLongTexts() {
        this.isLongTextExists = false;
        let isDescriptionCheckRequired = false;
        this.projectEventsGrid.gridColumns.forEach(column => {
          if (column.name == "noteDescription" && column.visible) {
            isDescriptionCheckRequired = true;
          }
        });
        if (isDescriptionCheckRequired ) {
          for (let element of this.projectEventsMappings) {

            if (element.noteCharCount > 100 && isDescriptionCheckRequired) {
              this.isLongTextExists = true;
              break;
            }
          }
        }
      }
    GetAttendees() {
        var json = JSON.parse(JSON.stringify(this.jsonEventAttendees));
        //this.eventAttendees = false;
        json["rowCount"] = this.totalRecordsAttendees >= 4000 ? 4000 : this.totalRecordsAttendees;
        json["pageIndex"] = 1;
        json["projectId"] = this.id;
        json["ClientUrl"] = CLIENT_URL;
        this.get({ path: "api/SearchProject/GetAllEventAttendees", queryParams: { "query": encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: ProjectEventAttendeesViewModel[]) => {
            if (t.length != 0) {
                this.attendees = t;
                if (this.attendees != null && this.attendees.length > 0) {
                    this.list = this.attendees;
                    this.generatePiecesExcel();
                }
                this.attendees = [];
            }
            // else {
            //     this.eventAttendees = true;
            // }
        });
    }

    eventAttendeesModalClose(){
        this.closebutton.nativeElement.click();
        this.attendeesSearchString = '';
        this.isFilter = false;
        this.projectEventsGrid.destroy();
        this.projectEventsGrid = null;
        this.bindGrid();
    }

    BindAttendeesData() {
        if (this.projectEventAttendeesGrid) {
            this.projectEventAttendeesGrid.storeProcedure.nextPage = 1;
        }
        this.paginationViewModelAttendees.orderBy = "EventURL";
        this.paginationViewModelAttendees.sortOrder = 'true';
        this.paginationViewModelAttendees.pageIndex = PaginationEnum.PageIndex;
        this.paginationViewModelAttendees.rowCount = PaginationEnum.RowCount;
        this.GetAttendeesData();
    }

    GetAttendeesData() {
        //this.eventAttendees = false;
        this.spinner = true;
        // this.paginationViewModelAttendees.orderBy = "Eventname";
        // this.paginationViewModelAttendees.sortOrder = 'false';
        // this.paginationViewModelAttendees.pageIndex = PaginationEnum.PageIndex;
        // this.paginationViewModelAttendees.rowCount =  PaginationEnum.RowCount;
        this.jsonEventAttendees = JSON.parse(JSON.stringify(this.paginationViewModelAttendees));
        this.jsonEventAttendees["projectId"] = this.id;
        this.jsonEventAttendees["ClientUrl"] = CLIENT_URL;
        this.jsonEventAttendees["searchString"] = this.attendeesSearchString;
        setTimeout(() => {
            this.get({ path: "api/SearchProject/GetAllEventAttendees", queryParams: { "query": encodeURIComponent(JSON.stringify(this.jsonEventAttendees)) } }).subscribe((projectEventAttendeesInvitees: ProjectEventAttendeesViewModel[]) => {
                this.spinner = false;
                if (this.projectEventAttendeesGrid) {
                    this.projectEventAttendeesGrid.storeProcedure.length = 0;
                    this.isFilterAttendees = true;
                }
                this.totalRecordsAttendees = projectEventAttendeesInvitees.length > 0 ? projectEventAttendeesInvitees[0].totalCount : 0;
                if (projectEventAttendeesInvitees.length == 0) {
                    //this.eventAttendees = true;
                }
                else {
                    this.dropdownCount = 1;
                    projectEventAttendeesInvitees.forEach(element => {
                        if (element.JobTitlesCount > 1 && element.JobTitles != null && element.JobTitles != undefined) {
                            element.JobTitle = this.formEventAttendeesTable(element);
                        }
                    })
                    if (!this.isFilterAttendees) {
                        this.projectEventAttendeesGrid = new AppGrid(projectEventAttendeesInvitees, ProjectEventAttendeesViewModel, { actions: {onOneLink :this.Redirect.bind(this) } });
                        this.projectEventAttendeesGrid.designClass.tableClass = "scrollTable table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                        this.projectEventAttendeesGrid.designClass.headerClass = "table-header-sticky".split(" ");
                        this.projectEventAttendeesGrid.storeProcedure = {
                            length: this.totalRecordsAttendees,
                            onPageChanging: this.onPageChangingAttendees.bind(this),
                            nextPage: 1,
                            onPageSorting: this.onPageSortingAttendees.bind(this)
                        }
                    }
                    else {
                        this.projectEventAttendeesGrid.storeProcedure.length = this.totalRecordsAttendees;
                        this.projectEventAttendeesGrid.updateSource([]);
                        this.projectEventAttendeesGrid.updateSource(projectEventAttendeesInvitees);
                    }
                }
                this.projectEventAttendeesGrid.componentId = this.componentName;
            });
        }, 1000);

    }
    onPageChangingAttendees(x) {
        this.isFilterAttendees = true;
        this.paginationViewModelAttendees.pageIndex = x;
        this.paginationViewModelAttendees.rowCount = this.projectEventAttendeesGrid.maxPerPage
        this.projectEventAttendeesGrid.storeProcedure.nextPage = x;
        this.GetAttendeesData();
    }
    onPageSortingAttendees(x, y, z) {
        this.isFilterAttendees = true;
        this.paginationViewModelAttendees.sortOrder = y;
        //this.sort = !this.sort;
        this.paginationViewModelAttendees.orderBy = x;
        this.paginationViewModelAttendees.pageIndex = z;
        this.projectEventAttendeesGrid.storeProcedure.nextPage = z;
        this.GetAttendeesData();
    }
    onCheckboxSelect(t, x, y, z) {
        if (x.target.checked) {
            if (this.selectedEvents.findIndex(x => x == t.eventID) == -1) {
                this.selectedEvents.push(t.eventID);
            }
        }
        else {
            if (this.selectedEvents.findIndex(x => x == t.eventID) != -1) {
                this.selectedEvents.splice(this.selectedEvents.indexOf(t.eventID), 1);
            }
        }
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json["sortOrder"] = y;
        this.json["orderBy"] = x;
        this.projectEventsGrid.storeProcedure.nextPage = z;
        this.projectEventsGrid.updateSource([]);
        this.bindGrid(false);
    }

    // gridDestroy() {
    //     if (this.projectEventAttendeesGrid) {
    //         //this.projectEventAttendeesGrid=null;
    //         this.projectEventAttendeesGrid.destroy();
    //         this.isFilterAttendees = false;
    //         //this.projectEventAttendeesGrid=null;

    //     }
    // }
    onPageChanging(x) {
        this.isFilter = true;
        this.paginationViewModel.pageIndex = x;
        this.paginationViewModel.rowCount = this.projectEventsGrid.maxPerPage;
        this.json["pageIndex"] = x;
        this.json["rowCount"] = this.projectEventsGrid.maxPerPage;
        this.projectEventsGrid.storeProcedure.nextPage = x;
        this.projectEventsGrid.updateSource([]);
        this.bindGrid(false);
    }

    showAddForm() {
        this.modalView.show(ProjectEventAddComponent, { projectId: this.id, isEvent: 0 }).then(t => {
            this.isFilter = true;
            this.bindGrid();
        });
    }

    onEdit(projectEvent: vProjectEvents, event) {
        var eventID = projectEvent.eventID;
        let url: any;
        url = this.router.serializeUrl(this.router.createUrlTree(["events", eventID], { queryParams: { activeTab: 'event-info' } }));
        redirectOnCTRL(url, this.router, event);

        //this.router.navigate(["events", projectEvent.eventID], { queryParams: { activeTab: 'event-info' } });
    }
    Redirect(projectEventAttendeesInvitees: ProjectEventAttendeesViewModel){
        var UserID = projectEventAttendeesInvitees.UserId;
        let url: any;
        url = this.router.serializeUrl(this.router.createUrlTree(["users", UserID], { queryParams: { activeTab: 'user-edit' } }));
        redirectOnCTRL(url, this.router, event);
    }

    onMoreNotelick(t, x) {
        var element = document.getElementById("note_" + t.noteId);
        var maskedElement = document.getElementById("maskednote_" + t.noteId);
        if (element.className == "collapse") {
            x.srcElement.innerText = "Show Less";
            x.srcElement.className = "less-btn"
            element.classList.add("show");
            maskedElement.setAttribute("style", "display:none");
        }
        else {
            element.classList.remove("show");
            x.srcElement.innerText = "Show More";
            x.srcElement.className = "more-btn";
            maskedElement.setAttribute("style", "display:inline");
        }
    }

    onMoreClickAll() {
        this.isFilter = true;
        //this.isShowGrid=true;
        this.projectEventsGrid.destroy();
        this.projectEventsGrid = new AppGrid(this.projectEventsMappings, vProjectEvents, { actions: { onEdit: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this), onMoreNotelick: this.onMoreNotelick.bind(this) } });
        this.projectEventsGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
        }
        this.projectEventsGrid.gridColumns.forEach(x => {
            if (x.columnIndex == 0) {
                x.visible = true;

            } if (x.columnIndex == 3) {
                x.visible = false;
            }
        });
        this.projectEventsGrid.designClass.tableClass.push("table-list");
        this.projectEventsGrid.gridColumns.forEach(z => {
            if (z.name == 'noteDescription') {
                z.visible = false
            }
            if (z.name == 'noteDescription1') {
                z.visible = true
            }
        })
        this.isShowAll = false;
        this.isHideAll = true;
        this.projectEventsGrid.maxPerPage = this.paginationViewModel.rowCount;
        this.projectEventsGrid.componentId = this.componentName;
    }

    onLessClickAll() {
        this.projectEventsGrid.destroy();
        this.projectEventsGrid = new AppGrid(this.projectEventsMappings, vProjectEvents, { actions: { onEdit: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this), onMoreNotelick: this.onMoreNotelick.bind(this) } });
        this.projectEventsGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
        }
        this.projectEventsGrid.gridColumns.forEach(x => {
            if (x.columnIndex == 0) {
                x.visible = true;

            } if (x.columnIndex == 3) {
                x.visible = false;
            }
        });
        this.projectEventsGrid.designClass.tableClass.push("table-list");
        this.projectEventsGrid.gridColumns.forEach(z => {
            if (z.name == 'noteDescription') {
                z.visible = true
            }
            if (z.name == 'noteDescription1') {
                z.visible = false
            }
        })
        this.isShowAll = true;
        this.isHideAll = false;
        this.projectEventsGrid.maxPerPage = this.paginationViewModel.rowCount;
        this.projectEventsGrid.componentId = this.componentName;
    }

    search(value) {
        setTimeout(() => {
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
          this.json['searchString'] = value.target.value;
            if (this.projectEventsGrid != null) {
                this.projectEventsGrid.updateSource([]);
                this.json["pageIndex"] = 1;
                this.projectEventsGrid.storeProcedure.nextPage = 1;
                this.json["rowCount"] = this.projectEventsGrid.maxPerPage;
            }
            this.bindGrid(false);
        }, 4000);
        this.spin = true;
    }

    searchAttendees(value) {
        setTimeout(() => {
            this.spinner = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.attendeesSearchString = value.target.value;
            if (this.projectEventAttendeesGrid != null) {
                this.projectEventAttendeesGrid.updateSource([]);
                this.jsonEventAttendees["pageIndex"] = 1;
                this.projectEventAttendeesGrid.storeProcedure.nextPage = 1;
                this.jsonEventAttendees["rowCount"] = this.projectEventAttendeesGrid.maxPerPage;
            }
            this.BindAttendeesData();
        }, 4000);
        this.spinner = true;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Project_Event");
        if (existsAlert) {
            return existsAlert;
        }
    }
    saveContinue(isRedirect: boolean) {
        if (this.selectedEvents.length > 0) {
            this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
                if (t === DialogEnum.Ok) {
                    this.dialog.hide();
                    this.deleteEvents(isRedirect);
                }
                this.dialog.hide()
            })
        }
        else {
            this.deleteEvents(isRedirect);
        }
    }
    deleteEvents(isRedirect: boolean) {
        this.spin = true;
        let selectedEvents: any = JSON.parse(JSON.stringify({ projectsArray: [] }));
        selectedEvents.projectsArray = this.selectedEvents;
        if (this.selectedEvents.length > 0) {
            this.delete({ path: "api/ProjectEventMapping", params: [this.id], body: null, queryParams: { "query": JSON.stringify(selectedEvents.projectsArray), "projectOrEvent": 0 } }).subscribe(data => {
                //this.spin = false;

                var existsAlert = getLocalizedValue("Data_Updated");
                if (existsAlert) {
                    //this.toastr.success("Data Updated Successfully");
                    this.toastr.success(existsAlert);
                }
                this.isFilter = true;
                this.totalRecords = 0;
                this.selectedEvents = [];
                if (!isRedirect) {
                    this.projectEventsGrid.updateSource([]);
                    this.bindGrid();
                }
                else {
                    this.router.navigate(["projects", this.id], { queryParams: { activeTab: 'project-edit' } });
                    this.spin = false;
                }

            })
        }
    }
    get componentName(): string {
        return "ProjectEventListComponent";
    }

    exportToExcel() {
        this.attendees = null;
        this.GetAttendees();
    }

    generatePiecesExcel() {
        this.spinner = true;
        let returnedRequestData = this.requestExcelData(this.list);
        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet();
        // Cell Style : Fill and Header
        var TodayDate = new Date();
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
        var FileName = "EventAttendees" + MMDDYY + "-" + this.id;
        this.exportExcel.FileName = "ProjectEventAttendees" + MMDDYY;
        this.exportExcel.Type = "Excel";
        this.exportExcel.Description = "Attendees in Project Events"
        this.exportExcel.EntityUrl = window.location.href;
        this.exportExcel.EntityId = this.id;
        // const headerRow = worksheet.addRow(header);
        this.rowCount = 1;
        var localizationData = (MultiLingualData.get(this.componentName) as any);
        worksheet.columns = [
            { header: localizationData.ProjectEventListComponent_EventName_ex_ex, key: 'Event Name', width: 20 },
            { header: localizationData.ProjectEventListComponent_EventType_ex_ex, key: 'Event Type', width: 20 },
            { header: localizationData.ProjectEventListComponent_User_ex_ex, key: 'User(s)', width: 20 },
            { header: localizationData.ProjectEventListComponent_Job_ex_ex, key: 'Job(s)', width: 20 },
            { header: localizationData.ProjectEventListComponent_Corporate_ex_ex, key: 'Corporate(s)', width: 20 },
            { header: localizationData.ProjectEventListComponent_EventStartDate_ex_ex, key:'EventDate',width:20},
            { header: localizationData.ProjectEventListComponent_EventEndDate_ex_ex, key:'EventEndDate',width:20},
            { header: localizationData.ProjectEventListComponent_Status_ex_ex, key: 'Status', width: 20 },

        ];

        returnedRequestData.forEach(x => {
            worksheet.addRow(x);
        });
        worksheet.getRow(1).eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '00000000'
                },
                bgColor: {
                    argb: '00000000'
                },
            };
            cell.font = {
                color: {
                    argb: 'FFFFFFFF',
                },
                bold: true
            }
            cell.border = {
                top: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            };
        });


        setTimeout(() => {
            this.spinner = false;
            workbook.xlsx.writeBuffer().then(async (y: any) => {

                var u8 = new Uint8Array(y);
                var b64encoded = btoa(this.Uint8ToString(u8));

                const blob = new Blob([y], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                let uploadedBlobName = await this.uploadExcelToBlob(blob, "xlsx");
                    // this.exportExcel.Result = b64encoded;
                    this.exportExcel.BlobFileName = uploadedBlobName;
                this.post({ body: this.exportExcel, path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
                    fs.saveAs(blob, Response);

                })
            });
        }, 1000);


    }
    async uploadExcelToBlob(file: any, fileExtention: string): Promise<string> {

        let fileUploadRes = [];
        let newGUID = uuidv4();
        let fileName = BLOB_BASE.ExcelSheet + `${newGUID}_${new Date().toISOString()}.${fileExtention}`;
        var res = await this.blobState.uploadItems(file, fileName);
    
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.ExcelSheet + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
    
        // IF SUCCEED TO UPLOAD
        if (res && (res._response.status == 200 || res._response.status == 201)) {
          if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.toastr.error(
            "Error occured while uploading file! Please try again."
          );
          if (uploadedFileName != undefined) {
            this.modalView
              .show(BedRequestComponent, {
                validationMessages: JSON.stringify(
                  uploadedFileName
                ),
                headerMessage: "Image failed to upload:",
              })
              .then(() => { });
          }
        }
        return uploadedFileName;
      }
    Uint8ToString(u8a) {
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return c.join("");
    }
    requestExcelData(usersGrid: ProjectEventAttendeesViewModel[]) {
        let userListData = new Array<EventAttendeesData>();

        usersGrid.forEach(element => {
            let listData = new EventAttendeesData();
            listData["Event Name"] = element.EventName;
            listData["Event Type"] = element.EventType;
            listData["User(s)"] = element.UserName;
            listData["Corporate(s)"] = element.CorporateName;
            listData["Job(s)"] = element.JobData;
            listData["Status"] = element.Status
            listData["EventDate"] = element.EventDate;
            listData["EventEndDate"] = element.EventEndDate;
            //  listData["Booking Details"]=element.bookingData;
            //   listData["Supplier"]=element.supplier;
            //   listData["Upcycled"]=element.isUpcycled==true?"True":"False";
            //   listData["Bespoke"]=element.isBespoke==true?"True":"False";
            userListData.push(listData);
        });
        return userListData;
    }
    formEventAttendeesTable(item: ProjectEventAttendeesViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table><tbody>";
        let subTable: any;
        item.JobTitles = JSON.parse(item.JobTitles);
        subTable = "<tr id='demo" + this.dropdownCount + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.JobTitles != null && item.JobTitles != undefined) {
            for (let i = 0; i < item.JobTitles.length; i++) {
                let x: any = item.JobTitles[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + this.dropdownCount + "'";
                    row = row + "<tr><td >" + item.JobTitles[i].jobtitle + "<i class='fa fa-chevron-down ml-2' id='icon" + this.dropdownCount + "' onclick='Window.ProjectEventListComponent.expandTable(" + this.dropdownCount + ")'></i>";
                    if (x.isSupplierUnwelcome == 1) {
                        row += "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span></td></tr>";
                    }
                    else{
                        row += "</td></tr>"
                    }
                }
                else {
                    subRow = subRow + "<tr><td>" + x.jobtitle ;
                    if (x.isSupplierUnwelcome == 1) {
                        subRow += "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default;' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseover)='showPersonaNonGrataToolTip()'>\u26D4</span></td></tr>";
                    }
                    else{
                        subRow += "</td></tr>"
                    }
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        this.dropdownCount++;
        return tabledata;
    }
    expandTable(id: number) {
        var element = document.getElementById("demo" + id.toString());
        var iconElement = document.getElementById("icon" + id.toString());
        if (element != null) {
            if (element.style.display == "none") {
                element.style.display = "block";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-up ml-2"
                }

            }
            else {
                element.style.display = "none";
                if (iconElement != null) {
                    iconElement.className = "fa fa-chevron-down ml-2"
                }

            }
        }
    }
}
class ExportToExcelModel {
    Result: string;
    Type: string;
    FileName: string;
    Description: string;
    BlobFileName:string;
    EntityId:number;
    EntityUrl:string;
}
class EventAttendeesData {
    "Event Name": string;
    "User Name": string;
}
