<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_System_Help_t"></li> 
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content "  [rxSpinner]="spin" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_System_Help_t"></label></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white">
      <button class="btn btn-primary m-2 float-right" (click)="exportDocument()"  rxText="Label_Export_Document_t"></button>
      <div class="table-responsive" style="top:0; position: sticky;">
        <table class="table table-striped table-hover dataTables-example role-table dataTable border-bottom mb-0">
          <thead>
            <tr role="row">
              <th class="sorting border-right" style="min-width:50px; width:400px;" rxText="SystemHelpListComponent_Module_t"></th>
              <th class="sorting text-center border-right" colspan="2" style="min-width:130px;" rxText="SystemHelpListComponent_search_t"></th>
              <th class="sorting text-center border-right" colspan="2" style="min-width:130px;" rxText="SystemHelpListComponent_Add_t"></th>
              <th class="sorting text-center border-right" colspan="2" style="min-width:130px;" rxText="SystemHelpListComponent_Edit_t"></th>
              <th class="sorting" style="width:7%"></th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="table-responsive" *ngFor="let parent of parentModulesList;let i = index" [rxLocalisationInit]="componentName">
        <table class="table table-striped table-hover dataTables-example role-table dataTable border-bottom">
          <thead>
            <tr role="row">
              <th class="sorting  border-right" style="min-width:50px; width:400px;">{{parent.parentModuleMasterName}}
              </th>
              <th class="sorting text-center border-left" style="min-width:80px;"><label class="mb-0"
                  rxText="SystemHelpListComponent_Approved_t"></label> </th>
              <th class="sorting text-center border-right" style="min-width:50px;"><label rxText="" class="mb-0" rxText="SystemHelpListComponent_Action_t"></label></th>

              <th class="sorting text-center border-left" style="min-width:80px;"><label class="mb-0"
                  rxText="SystemHelpListComponent_Approved_t"></label> </th>
              <th class="sorting text-center border-right" style="min-width:50px;"><label rxText="" class="mb-0" rxText="SystemHelpListComponent_Action_t"></label></th>

              <th class="sorting text-center border-left" style="min-width:80px;"><label class="mb-0"
                  rxText="SystemHelpListComponent_Approved_t"></label> </th>
              <th class="sorting text-center border-right" style="min-width:50px;"><label rxText="" class="mb-0" rxText="SystemHelpListComponent_Action_t"></label></th>

              <th class="sorting text-right" style="width:7%"><i class="fa role-togle" [title]="titleToggle[i]"
                  [ngClass]="{'fa-chevron-down': !parent.isCollapse,'fa-chevron-up': parent.isCollapse}"
                  (click)="collapseDiv(parent,i)"></i></th>
            </tr>
          </thead>
          <tbody style="display: table-row-group;" [id]="parent.parentApplicationModuleId"
            [ngStyle]="{'display': parent.isCollapse ? 'table-row-group' : 'none'}">
            <tr *ngFor="let module of filterModules(parent.parentApplicationModuleId)">
              <td class=" border-right">{{module.moduleMasterName}}</td>

              <!-- Search -->
              <ng-container *ngIf="module.hasView">
                <td class="text-center border-left">
                  <i class="fa fa-check text-green" *ngIf="module.searchIsApproved"></i>
                  <i class="fa fa-times text-danger" *ngIf="!module.searchIsApproved"></i>
                </td>
                <td class="text-center border-right">
                  <a [routerLink]="['/system-help/'+module.moduleMasterId+'/add']"
                    [queryParams]="{'systemHelpPageId': SystemHelpSearchPage}"
                    *ngIf="module.searchSystemHelpId == null && hasAddAccess"><i
                      class="fa fa-edit fa-4x font-md text-default" [title]="titleEdit"></i></a>
                  <a [routerLink]="['/system-help/'+module.moduleMasterId+'/edit']"
                    [queryParams]="{'systemHelpPageId': SystemHelpSearchPage}"
                    *ngIf="module.searchSystemHelpId != null && hasEditAccess"><i
                      class="fa fa-edit fa-4x font-md text-default" [title]="titleEdit"></i></a>
                </td>
              </ng-container>
              <ng-container *ngIf="!module.hasView">
                <td class="text-center border-left"></td>
                <td class="text-center border-right"></td>
              </ng-container>
              <!-- End Search -->

              <!-- Add -->
              <ng-container *ngIf="module.hasAdd">
                <td class="text-center border-left">
                  <i class="fa fa-check text-green" *ngIf="module.addIsApproved"></i>
                  <i class="fa fa-times text-danger" *ngIf="!module.addIsApproved"></i>
                </td>
                <td class="text-center border-right">
                  <a [routerLink]="['/system-help/'+module.moduleMasterId+'/add']"
                    [queryParams]="{'systemHelpPageId': SystemHelpAddPage}"
                    *ngIf="module.addSystemHelpId == null && hasAddAccess"><i
                      class="fa fa-edit fa-4x font-md text-default" [title]="titleEdit"></i></a>
                  <a [routerLink]="['/system-help/'+module.moduleMasterId+'/edit']"
                    [queryParams]="{'systemHelpPageId': SystemHelpAddPage}"
                    *ngIf="module.addSystemHelpId != null && hasEditAccess"><i
                      class="fa fa-edit fa-4x font-md text-default" [title]="titleEdit"></i></a>
                </td>
              </ng-container>
              <ng-container *ngIf="!module.hasAdd">
                <td class="text-center border-left"></td>
                <td class="text-center border-right"></td>
              </ng-container>
              <!-- End Add -->

              <!-- Edit -->
              <ng-container *ngIf="module.hasEdit">
                <td class="text-center border-left">
                  <i class="fa fa-check text-green" *ngIf="module.editIsApproved"></i>
                  <i class="fa fa-times text-danger" *ngIf="!module.editIsApproved"></i>
                </td>
                <td class="text-center border-right">
                  <a [routerLink]="['/system-help/'+module.moduleMasterId+'/add']"
                    [queryParams]="{'systemHelpPageId': SystemHelpEditPage}"
                    *ngIf="module.editSystemHelpId == null && hasAddAccess"><i
                      class="fa fa-edit fa-4x font-md text-default" [title]="titleEdit"></i></a>
                  <a [routerLink]="['/system-help/'+module.moduleMasterId+'/edit']"
                    [queryParams]="{'systemHelpPageId': SystemHelpEditPage}"
                    *ngIf="module.editSystemHelpId != null && hasEditAccess"><i
                      class="fa fa-edit fa-4x font-md text-default" [title]="titleEdit"></i></a>
                </td>
              </ng-container>
              <ng-container *ngIf="!module.hasEdit">
                <td class="text-center border-left"></td>
                <td class="text-center border-right"></td>
              </ng-container>
              <!-- End Edit -->

              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>

</div>
