import { TemplateConfig } from '@rxweb/grid';
export const GRID_TEXTBOX: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1"],

                  // isBind: (x) => {
                  //   if(x.isDirector==false){
                  //     return true;
                  //   }
                  //   else{
                  //     return false;

                  //   }
                  // },
                   event: {
                    input: "onDataChange"
                  },

                  attributes: {
                    type: "text",
                    value: function (e) {
                      return this.jobTitle;
                    },
                    placeholder:"Enter Job Title",
                    min:0
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const GRID_TEXTBOX_WORKING: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1"],
                   event: {
                    input: "onDataChange"
                  },

                  attributes: {
                    type: "text",
                    value: function (e) {
                      return this.jobTitle;
                    },
                    placeholder:"Enter Number Of Working Hours Lost",
                    min:0
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const GRID_TEXTBOX_WORKING_HR: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                   class: ["form-control","col-xs-1"],
                   isBind: (x) => {
                    return x.total != "Total" && x.isAllowEdit==true ? true : false;
                },
                   event: {
                    input: "onDataChange"
                  },
                  attributes: {
                    type: "text",
                    value: function (e) {
                      return this.workinghrslost;

                    },
                    placeholder:"Enter Number Of Working Hours Lost",
                    min:0,
                  }
                },
                span:
                {
                  isBind: (x) => {
                    return x.total != "Total" && x.isAllowEdit==false ? true : false;
                },
                childrens: [
                {
                      text: {
                        text: function (e) { return this.workinghrslost }
                      }
                }]
                },
                div: {
                  isBind: (x) => {
                      return x.total == "Total" ? true : false;
                  },
                  childrens: [
                  {
                    label:{
                      childrens: [{
                        text: {
                          text: function (e) { return "Total: " + this.workinghrslost }
                        }
                      }]
                    },
              }]
             }
              }
            ]
          },

        }
      ],
    },
  }
  export const GRID_TEXTAREA_ACCIDENT: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                textarea:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1","mb-1","mt-3"],
                  style : {
                    "width":"250px",
                    "resize":"both"
                  },
                  isBind: (x) => {
                    if(x.total!='Total'){
                      return true;
                    }
                    else{
                      return false;

                    }
                  },
                   event: {
                    input: "onTextDataChange"
                  },
                  attributes: {
                    value: function (e) {
                      return this.conditionMedicalTreatment;
                    },
                    placeholder:"Enter Condition / Medical Treatment",
                    cols:"20" ,
                    rows:"3"
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const GRID_TEXTAREA_JOBTITLE: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                textarea:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1","mb-1","mt-3"],
                   event: {
                    input: "onDataChange"
                  },
                  attributes: {
                    value: function (e) {
                      return this.jobTitle;
                    },
                    placeholder:"Enter Job Title",
                    cols:"20" ,
                    rows:"5"
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const GRID_TEXTAREA_INCIDENT: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                textarea:
                {
                  class: ["form-control","checkbox-custom-style","col-xs-1","mb-1","mt-3"],
                  isBind: (x) => {
                    if(x.total!='Total'){
                      return true;
                    }
                    else{
                      return false;

                    }
                  },
                  style : {
                    "width":"250px",
                    "resize":"both"
                  },
                   event: {
                    input: "onTextDataChange"
                  },
                  attributes: {
                    value: function (e) {
                      return this.involvementRole;
                    },
                    placeholder:"Enter Involvement / Role",
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const GRID_TEXTAREA_DOCUMENTDESCRIPTION: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                textarea:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1","mb-1","mt-3"],
                   event: {
                    input: "onDataChange"
                  },
                  attributes: {
                    value: function (e) {
                      return this.documentDescription;
                    },
                    placeholder:"Enter Document Description",
                    cols:"20" ,
                    rows:"5"
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
