import {IncidentAccidentTypeBase,LookupSituationTypeBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class IncidentAccidentType extends IncidentAccidentTypeBase 
{




//#region Generated Reference Properties
//#region lookupSituationType Prop
lookupSituationType : LookupSituationTypeBase;
//#endregion lookupSituationType Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties






































}