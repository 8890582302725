import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractEmailAccount } from '../domain/abstract-email-account';
import { EmailAccount, vEmailAccount } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { EmailAccountAddComponent } from '../add/email-account-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@access({ accessLevel: RolePermissionEnums.EmailAccounts, action: "get" })

@multilingual("EmailAccountListComponent")

@Component({
    selector: "app-email-account-list",
    templateUrl: './email-account-list.component.html'
})
export class EmailAccountListComponent extends AbstractEmailAccount implements OnInit, OnDestroy {
    emailAccount: vEmailAccount[];
    subscription: any;
    emailAccountGrid: AppGrid;
    emailAccountGridColumns: any[];
    emailAccountAdd: any = EmailAccountAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.EmailAccounts;
        location.onPopState(() => this.dialog.hide());
    }


    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Email Accounts - G-Trac Admin");
        this.bindGrid();
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: any) => {
            this.spin = false;
            this.emailAccount = t;
            this.emailAccountGrid = new AppGrid(t, vEmailAccount, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.emailAccountGrid.gridColumns.forEach(x => {
                if (this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "put" }) && this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "delete" });
                    }
                }
            })
            this.emailAccountGrid.componentId = this.componentName;
            this.emailAccountGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.emailAccountGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.emailAccountGrid.design(document.getElementById("email-account"));
            this.emailAccountGridColumns = this.emailAccountGrid.gridColumns.filter(x => x.keyColumn == false);
            this.showComponent = true;
        })
    }

    onEdit(emailAccount: vEmailAccount) {
        //this.router.navigate(["email-account", emailAccount.emailAccountId]);
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["email-account", emailAccount.emailAccountId]));
        redirectOnCTRL(url,this.router,event);
    }

    onDelete(emailAccount: vEmailAccount) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [emailAccount.emailAccountId] }).subscribe(data => {
                    this.spin = false;
                    this.emailAccountGrid.remove(emailAccount.emailAccountId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/email-account']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Email");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.emailAccount.filter(x => x.email.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.emailAccountId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.email.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.emailAccountGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.emailAccountGrid = null;
        }
        setTimeout(() => {
            if (this.emailAccountGrid == null || this.emailAccountGrid == undefined) {
                this.emailAccountGrid = new AppGrid(this.emailAccount, vEmailAccount, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.emailAccountGrid.gridColumns.forEach(x => {
                    if (this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "put" }) && this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.emailAccountGrid.authorize({ accessLevel: RolePermissionEnums.EmailAccounts, action: "delete" });
                        }
                    }
                })
                this.emailAccountGrid.componentId = this.componentName;
                this.emailAccountGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.emailAccountGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.emailAccountGrid.refresh("email-account");
                this.emailAccountGrid.design(document.getElementById("email-account"));
                this.emailAccountGridColumns = this.emailAccountGrid.gridColumns.filter(x => x.keyColumn == false);
            }
            this.emailAccountGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "EmailAccountListComponent";
    }

}
