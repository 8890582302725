import {vLocationGroupRecordBase,} from '@app/database-models'
//Generated Imports
export class vLocationGroupRecord extends vLocationGroupRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}