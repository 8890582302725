import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LeaveTravelEventProjectMappingBase {

//#region lTA_MappingID Prop
        @prop()
        lTA_MappingID : number;
//#endregion lTA_MappingID Prop


//#region masterId Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        //@required()
        @prop()
        masterId : number;
//#endregion masterId Prop


//#region eventId Prop
        @prop()
        eventId : number;
//#endregion eventId Prop


//#region projectId Prop
        @prop()
        projectId : number;
//#endregion projectId Prop

//#region projectId Prop
        @prop()
        CorporatId: number;
//#endregion projectId Prop

//#region eventOrProjectType Prop
        @maxLength({value:2})
        eventOrProjectType : string;
//#endregion eventOrProjectType Prop



}