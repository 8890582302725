import { prop, numeric, required, notEmpty, range } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentDesignFilterViewModel{

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = false;
        this.orderBy = "ProductId";
    }
    
    @prop()
    categoryId:number;
    
    @prop({defaultValue:25})
    rowCount:number;

    @prop() 
    orderBy:string;

    @prop({defaultValue:false})
    sortOrder:boolean;

    @prop({defaultValue:1})
    pageIndex:number;

    @prop()
    title:string;

    @prop()
    statusId:number;

    // @prop()
    // clientUrl:string;

    @prop()
    searchString:string;

    @prop()
    collectionId:number;

    @prop()
    @range({minimumNumber:1,maximumNumber:2147483647})
    @numeric()
    designId:number;
}