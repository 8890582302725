import {DualCategoryProductVariantMappingBase,} from '@app/database-models'
//Generated Imports
export class DualCategoryProductVariantMapping extends DualCategoryProductVariantMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}