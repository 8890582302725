import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-system-time',
    templateUrl: './system-time.component.html',
    styleUrls: ['./system-time.component.css'],
    
})
export class SystemTimeComponent implements OnInit {

    currentDate: any;
    currentTime: any;
    constructor() { }

    ngOnInit() {
        //  this.currenctDateTime = new Date();
        const now = new Date(); 
         this.currentDate = new Date(now.getTime())
         this.currentTime = this.getcurrentTime();   
          
    }
    private getcurrentTime() {
      const now = new Date(); 
      return new Date(now.getTime());

    }

     formatAMPM(date) {         
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }
      

}
