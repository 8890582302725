import { gridColumn } from "@rxweb/grid";

export class GemfieldRiskListViewModel {
  //#region riskMenuDetailId Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-left"],
    visible: true,
    columnIndex: 0,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_RiskId_gh",
    keyColumn: true,
    configuredTemplate: { templateName: "reDirect" },
  })
  riskMenuDetailId: number;
  //#endregion riskMenuDetailId Prop

  //#region company Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "200px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 1,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_GemfieldsGroupCompany_gh",
    keyColumn: false,
  })
  company: string;
  //#endregion company Prop

  //#region createdOn Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-left"],
    visible: true,
    columnIndex: 2,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_CreatedDate_gh",
    parameter: false,
    keyColumn: false,
  })
  createdOn: string;
  //#endregion createdOn Prop

  //#region riskCategoryId Prop
  @gridColumn({ visible: false, columnIndex: 3, keyColumn: false })
  riskCategoryId: number;
  //#endregion riskCategoryId Prop

  //#region riskCategoryName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-left"],
    visible: true,
    columnIndex: 4,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_RiskCategory_gh",
    parameter: false,
    keyColumn: false,
  })
  riskCategoryName: string;
  //#endregion riskCategoryName Prop

  //#region specificRiskId Prop
  @gridColumn({ visible: false, columnIndex: 5, keyColumn: false })
  specificRiskId: number;
  //#endregion specificRiskId Prop

  //#region specificRiskName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "150px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 6,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_SpecificRiskName_gh",
    keyColumn: false,
    parameter: false,
    configuredTemplate: { templateName: "specificRiskTooltip" },
  })
  specificRiskName: string;
  //#endregion specificRiskName Prop

  //#region isRelevant Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-center"],
    visible: true,
    columnIndex: 7,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_IsRelevant_gh",
    keyColumn: false,
    parameter: false,
    configuredTemplate: { templateName: "recordActive" },
  })
  isRelevant: boolean;
  //#endregion isRelevant Prop

  //#region likelihood Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "white-space": "pre" },
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.likelihood
          },
        },
      },
    },
    class: ["text-center"],
    visible: true,
    columnIndex: 8,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_Likelihood_gh",
    parameter: false,
    keyColumn: false,
  })
  likelihood: any;
  //#endregion likelihood Prop

  //#region impact Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "white-space": "pre" },
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.impact
          },
        },
      },
    },
    class: ["text-center"],
    visible: true,
    columnIndex: 9,
    allowSorting: true,
    parameter: false,
    headerKey: "RiskMenuDetailListComp_Impact_gh",
    keyColumn: false,
  })
  impact: any;
  //#endregion impact Prop

  //#region riskIndex Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-center"],
    visible: true,
    columnIndex: 10,
    allowSorting: true,
    parameter: false,
    headerKey: "RiskMenuDetailListComp_RiskIndex_gh",
    keyColumn: false,
    configuredTemplate: { templateName: "gemfieldRiskIndex" },
  })
  riskIndex: number;
  //#endregion riskIndex Prop

  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            if (this.lastUpdates == null) {
              return "";
            } else {
              return this.lastUpdates;
            }
          },
        },
      },
    },
    style: { width: "5%", "text-align": "initial", "min-width": "150px" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 11,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComponent_LastUpdate_gh",
    keyColumn: false,
  })
  lastUpdates: string;

  // @gridColumn({ style: { width: "1%", "text-align": "left", "min-width":"150px" }, parameter : false,  class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'RiskMenuDetailListComponent_LastUpdateBy_gh', keyColumn: false })
  lastUpdatesBy: string;

  //#region localRiskOwnerName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "150px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 12,
    allowSorting: true,
    parameter: false,
    headerKey: "RiskMenuDetailListComp_LocalRiskOwner_gh",
    keyColumn: false,
  })
  localRiskOwnerName: string;
  //#endregion localRiskOwnerName Prop

  //#region boardRiskOwnerName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "150px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 13,
    allowSorting: true,
    parameter: false,
    headerKey: "RiskMenuDetailListComp_BoardRiskOwner_gh",
    keyColumn: false,
  })
  boardRiskOwnerName: string;
  //#endregion boardRiskOwnerName Prop

  //#region noteId Prop
  @gridColumn({ visible: false, columnIndex: 12, keyColumn: false })
  noteId: number;
  //#endregion noteId Prop

  //#region noteDescription Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "200px" },
    template: {
      div: {
        style: {
          "word-break": "break-all",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.noteDescription;
          },
        },
        childrens: [
          {
            a: {
              isBind: (x) => {
                if (x.noteCharCount > 100) {
                  return true;
                } else {
                  return false;
                }
              },
              style: {
                display: "block",
              },
              childrens: [
                {
                  span: {
                    class: "more-btn mr-2".split(" "),
                    childrens: [
                      {
                        text: {
                          text: function (e) {
                            return "Show More";
                          },
                        },
                      },
                    ],
                  },
                },
              ],
              event: {
                click: "onMoreClick",
              },
            },
          },
        ],
      },
    },
    visible: true,
    columnIndex: 16,
    parameter: false,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComp_Note_gh",
    keyColumn: false,
    class: ["text-left"],
  })
  noteDescription: string;
  //#endregion noteDescription Prop

  //#region lastUpdatedDate Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 15,
    allowSorting: true,
    headerKey: "RiskMenuDetailListComp_lastUpdated_gh",
    keyColumn: false,
  })
  modifiedOn: string;
  //#endregion lastUpdatedDate Prop

  //#region riskCauses Prop
  @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
  riskCauses: string;
  //#endregion riskCauses Prop

  //#region riskConsequences Prop
  @gridColumn({ visible: false, columnIndex: 18, keyColumn: false })
  riskConsequences: string;
  //#endregion riskConsequences Prop

  //#region totalCount Prop
  @gridColumn({ visible: false, columnIndex: 19, keyColumn: false })
  totalCount: number;
  //#endregion totalCount Prop

  // #region projects prop
  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            if (this.projects == null) {
              return "";
            } else {
              return this.projects;
            }
          },
        },
      },
    },
    style: { width: "5%", "text-align": "initial", "min-width": "200px" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 20,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComponent_Project_gh",
    keyColumn: false,
  })
  projects: string;
  // #endregion projects Prop

  projectCount: number;

  projectDetails: any[];

  // #region incidents prop
  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            if (this.incidents == null) {
              return "";
            } else {
              return this.incidents;
            }
          },
        },
      },
    },
    style: { width: "5%", "text-align": "initial", "min-width": "125px" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 21,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComponent_HSE_gh",
    keyColumn: false,
  })
  incidents: string;
  // #endregion incidents Prop

  incidentCount: number;

  incidentDetails: any[];

  // #region pressMedias prop
  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            if (this.pressMedias == null) {
              return "";
            } else {
              return this.pressMedias;
            }
          },
        },
      },
    },
    style: { width: "5%", "text-align": "initial", "min-width": "100px" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 22,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComponent_Press_gh",
    keyColumn: false,
  })
  pressMedias: string;
  // #endregion pressMedias Prop

  pressMediaCount: number;

  pressMediaDetails: any[];

  // #region events prop
  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            if (this.events == null) {
              return "";
            } else {
              return this.events;
            }
          },
        },
      },
    },
    style: { width: "5%", "text-align": "initial", "min-width": "200px" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 23,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComponent_Event_gh",
    keyColumn: false,
  })
  events: string;
  // #endregion events Prop

  eventCount: number;

  eventDetails: any[];

  // #region legalAgreement prop
  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            if (this.legalAgreement == null) {
              return "";
            } else {
              return this.legalAgreement;
            }
          },
        },
      },
    },
    style: { width: "5%", "text-align": "initial", "min-width": "200px" },
    parameter: false,
    class: ["text-left"],
    visible: true,
    columnIndex: 24,
    allowSorting: false,
    headerKey: "RiskMenuDetailListComponent_LegalAgreement_gh",
    keyColumn: false,
  })
  legalAgreement: string;
  // #endregion legalAgreement Prop

  legalAgreementCount: number;

  legalAgreementDetails: any[];

  projectExcel: string;
  eventExcel: string;
  legalAgreementExcel: string;
  pressMediaExcel: string;
  incidentExcel: string;
}
