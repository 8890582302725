import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ShoppingCartItemBase {

//#region shoppingCartItemId Prop
        @prop()
        shoppingCartItemId : number;
//#endregion shoppingCartItemId Prop


//#region shoppingCartTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        shoppingCartTypeId : number;
//#endregion shoppingCartTypeId Prop


//#region customerSessionGUID Prop
        @required()
        customerSessionGUID : any;
//#endregion customerSessionGUID Prop


//#region productVariantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region attributesXML Prop
        @required()
        attributesXML : string;
//#endregion attributesXML Prop


//#region customerEnteredPrice Prop
        @required()
        customerEnteredPrice : number;
//#endregion customerEnteredPrice Prop


//#region quantity Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        quantity : number;
//#endregion quantity Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @required()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region productVariantBatchPieceId Prop
        @prop()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region ringSize Prop
        @prop()
        ringSize : number;
//#endregion ringSize Prop





}