import { prop, maxLength, numeric, notEmpty, range, pattern } from '@rxweb/reactive-form-validators'
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentPieceFilterViewModel{

        constructor() {
                this.pageIndex = PaginationEnum.PageIndex;
                this.rowCount = PaginationEnum.RowCount;
                this.sortOrder = false;
                this.orderBy = "pieceId";
            }

        @prop()
        @numeric()
        productVariantBatchPieceId:number

        @pattern({ expression: { "carats": /^[0-9]\d{0,14}(\.\d{1,3})?%?$/ }, message: "Please enter valid carats:(15 digits and 3 decimal places allowed)" })
        @prop()       
        @maxLength({value:100})
        caratWeight:string

        @prop()       
        @maxLength({value:100})
        purchaseOrderNo:string

        @prop()       
        @maxLength({value:100})
        supplierOrderNo:string

        @prop()       
        @maxLength({value:100})
        pieceNo:string

        @prop()     
        @maxLength({value:100})
        productName:string

        @prop({defaultValue:9999})
        categoryId:number
        
        // @prop({defaultValue:9999})
        // status:number

        @prop({defaultValue:""})
        specialStatusIds:string
        @prop()
        salesStatusId: number;
        @prop()
        productionStatusId: number;
        @prop()
        consignmentStatusId: number;
        
        @prop({defaultValue:""})
        location:string
        
        @prop({defaultValue:""})
        owner:string
        
        @prop({defaultValue:""})
        collection:string
        
        @prop()
        ringSizeId:number
        
        @prop()
        eggSizeId:number

        @prop()
        bangleSizeId:number
        
        @prop({defaultValue:null})
        upcycled:boolean
        @prop({defaultValue:null})
        bespoke:boolean
        
        @prop()
        @maxLength({value:100})
      
        inventoryNo:string

        @pattern({expression:{"priceFrom":/^[0-9]\d{0,14}(\.\d{1,2})?%?$/},message:"Please enter valid PriceFrom:(15 digits and 2 decimal places allowed)"})
        //@maxLength({value:18})
        @prop({defaultValue:'0.00'})
        @numeric({allowDecimal:true})
       // @range({minimumNumber:0.00,maximumNumber:2134567898})       
        priceFrom:any
        
        @prop({defaultValue:'0.00'})
        @pattern({expression:{"priceTo":/^[0-9]\d{0,14}(\.\d{1,2})?%?$/},message:"Please enter valid PriceTo:(15 digits and 2 decimal places allowed)"})
       // @maxLength({value:18})
        @numeric({allowDecimal:true})
        //@range({minimumNumber:0.00,maximumNumber:2134567898})       
        priceTo:any
        
        @prop()
        dutyStatusId:number
        
        @prop()
        deliveryDateFrom:Date
        
        @prop()
        deliveryDateTo:Date

        @prop()
        forSaleDate:Date
        
        @prop()
        expiryDateFrom:Date

        @prop()
        pieceCreatedDateFrom:Date;

        @prop()
        pieceCreatedDateTo:Date;
        
        @prop()
        expiryDateTo:Date
        
        @prop()
        tripFrom :Date
        
        @prop()
        tripTo:Date
       
        @prop()
        gemstoneTypeId:number
        
        @prop()
        colorId:number
        
        @prop()
        materialId:number
        
        @prop()
       
        @maxLength({value:100})
        title:string

        @prop({defaultValue:null})
        coreProduct:boolean
        
        @prop({defaultValue:null})
        noReorder:boolean
        
        @prop({defaultValue:null})
        superSeller:boolean
        
        @prop()
        supplierId:number
        
        @prop()
        orderBy:string
        
        @prop()        
        sortOrder:boolean
        
        @prop({defaultValue:1})
        pageIndex:number
        
        @prop({defaultValue:25})
        rowCount:number
        
        @prop()
        clientUrl:string
        
        @prop()
        serverUrl:string

        @prop()
        searchString:string

        @prop()
        wholeSalePartnerId:number

        @prop({defaultValue:false})
        publishedOnWeb:boolean

        @prop({defaultValue:false})
        isConsigned:boolean

        @prop()
        salesChannel: number;

        @prop()
        @range({minimumNumber:1,maximumNumber:2147483647})
        @numeric()
        batchNo:number;

        @prop()
        @numeric()
        @pattern({expression:{"gtinNumber":/^[0-9]\d{0,12}$/},message:"You can not enter value with more than 13 digits"})
        gtinNumber:number;
}