import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemstoneStationGemFieldBase {

//#region stationId Prop
        @prop()
        stationId : number;
//#endregion stationId Prop


//#region stationName Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        @alpha({allowWhiteSpace:true})
        stationName : string;
//#endregion stationName Prop

}