import {vPOSShippingStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPOSShippingStatusLookUp extends vPOSShippingStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}