import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGetShoppingCartByUserSessionGuidBase {

//#region shoppingCartItemId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'shoppingCartItemId', keyColumn: true})
        shoppingCartItemId : number;
//#endregion shoppingCartItemId Prop


//#region shoppingCartTypeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'shoppingCartTypeId', keyColumn: false})
        shoppingCartTypeId : number;
//#endregion shoppingCartTypeId Prop


//#region customerSessionGUID Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'customerSessionGUID', keyColumn: false})
        customerSessionGUID : any;
//#endregion customerSessionGUID Prop


//#region productVariantId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'productVariantId', keyColumn: false})
        productVariantId : number;
//#endregion productVariantId Prop


//#region attributesXML Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'attributesXML', keyColumn: false})
        attributesXML : string;
//#endregion attributesXML Prop


//#region customerEnteredPrice Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'customerEnteredPrice', keyColumn: false})
        customerEnteredPrice : number;
//#endregion customerEnteredPrice Prop


//#region quantity Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'quantity', keyColumn: false})
        quantity : number;
//#endregion quantity Prop


//#region createdOn Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region updatedOn Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'updatedOn', keyColumn: false})
        updatedOn : any;
//#endregion updatedOn Prop


//#region productVariantBatchPieceId Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'productVariantBatchPieceId', keyColumn: false})
        productVariantBatchPieceId : any;
//#endregion productVariantBatchPieceId Prop


//#region ringSize Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'ringSize', keyColumn: false})
        ringSize : any;
//#endregion ringSize Prop

}