import { Component, OnInit, OnDestroy } from "@angular/core"
import { AbstractBannedIpAddress } from '../domain/abstract-banned-ip-address';
import { BannedIpAddress, vBannedIpAddress } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DatePipe, PlatformLocation } from '@angular/common';

import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { access } from '@rxweb/angular-router';
import { BannedIpAddressAddComponent } from '../add/banned-ip-address-add.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
@multilingual("BannedIpAddressListComponent")
//@access({ accessLevel: RolePermissionEnums.BlacklistIpAddress, action: "get" })

@Component({
    selector: "app-banned-ip-address-list",
    templateUrl: './banned-ip-address-list.component.html',
})
export class BannedIpAddressListComponent extends AbstractBannedIpAddress implements OnInit, OnDestroy {
    bannedIpAddresses: BannedIpAddress[];
    bannedIpAddresse: vBannedIpAddress[];
    subscription: any;
    bannedIPAddressGrid: AppGrid;
    bannedIPAddressGridColumns: any[];
    firstTime: boolean = true;
    isShowGrid: boolean = false;
    isNoRecordFound: boolean = false;
    titleSearch: string;
    constructor(private router: Router, private datePipe: DatePipe, private location : PlatformLocation) {
        super();
        this.router = router;
        this.isNoRecordFound = false;
        location.onPopState(() => this.dialog.hide());
        // this.activatedTab(this.currentActiveTab,this.firstTime)
    }

    ngOnInit(): void {
        //this.showComponent = true;
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vBannedIpAddress[]) => {
            this.spin = false;
            this.bannedIpAddresse = t;
            // t.forEach(x=>vBannedIpAddress.createdOn = this.datePipe.transform(x.createdOn,'dd/MMM/yyyy'));
            //t.forEach(x=>x.updatedOn = this.datePipe.transform(x.updatedOn,'dd/MMM/yyyy'));
            // this.bannedIPAddressGrid.gridColumns.forEach(x=>{
              //     if(this.bannedIPAddressGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpAddress,action:"put"}) && this.bannedIPAddressGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpAddress,action:"delete"})){
                //         if(x.name=="action"){
                  //             x.visible=true;
                  //         }
                  //     }
                  //     else{
                    //             if(x.name=="action"){
                      //                 x.visible=false;
                      //             }
                      //             if(x.name=="editAction"){
                        //                 x.visible=this.bannedIPAddressGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpAddress,action:"put"});
                        //             }
                        //             if(x.name=="deleteAction"){
                          //                 x.visible=this.bannedIPAddressGrid.authorize({accessLevel:RolePermissionEnums.BlacklistIpAddress,action:"delete"});
                          //             }
                          //     }
                          // })
            if(this.bannedIpAddresse.length > 1)
            {
              this.isNoRecordFound = false;
              this.bannedIPAddressGrid = new AppGrid(t, vBannedIpAddress, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
              this.bannedIPAddressGrid.componentId = this.componentName;

              this.bannedIPAddressGridColumns = this.bannedIPAddressGrid.gridColumns.filter(x => x.keyColumn == false);
            }
            else
            {
              this.isNoRecordFound = true;
            }
        })
    }

    onEdit(bannedIpaddress: BannedIpAddress) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["banned-ip-addresses", bannedIpaddress.bannedIpAddressID]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["banned-ip-addresses", bannedIpaddress.bannedIpAddressID]);
    }

    onDelete(bannedIpaddress: BannedIpAddress) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [bannedIpaddress.bannedIpAddressID] }).subscribe(data => {
                    this.spin = false;
                    this.bannedIPAddressGrid.remove(bannedIpaddress.bannedIpAddressID);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/blacklist'], { queryParams: { "activeTab": "address" } });
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Banned_IP");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.bannedIpAddresse.filter(x => x.address.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.bannedIpAddressID.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
            || (x.comment == null ? "" : x.comment).toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.stCreatedOn.toLowerCase().includes(value.target.value.trim().toLowerCase())
            || x.stUpdatedOn.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.bannedIPAddressGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.bannedIPAddressGrid = null;
        }
        setTimeout(() => {
            if (this.bannedIPAddressGrid == null || this.bannedIPAddressGrid) {
                this.bannedIPAddressGrid = new AppGrid(this.bannedIpAddresse, vBannedIpAddress, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.bannedIPAddressGrid.componentId = this.componentName;
                this.bannedIPAddressGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.bannedIPAddressGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.bannedIPAddressGrid.refresh("banned-ip-addresses");
                this.bannedIPAddressGrid.design(document.getElementById("banned-ip-addresses"));
            }
            this.bannedIPAddressGrid.search = value.target.value;
        }, 0);
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }


    get componentName(): string {
        return "BannedIpAddressListComponent";
    }

}
