import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class POSCartBase {

        //#region cartId Prop
        @prop()
        cartId: number;
        //#endregion cartId Prop


        //#region customerId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        customerId: number;
        //#endregion customerId Prop


        //#region adminId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        adminId: number;
        //#endregion adminId Prop


        //#region productVariantId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        productVariantId: number;
        //#endregion productVariantId Prop


        //#region pieceId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        pieceId: number;
        //#endregion pieceId Prop


        //#region actualPrice Prop
        @required()
        actualPrice: number;
        //#endregion actualPrice Prop


        //#region taxPrice Prop
        @required()
        taxPrice: number;
        //#endregion taxPrice Prop


        //#region dutyPrice Prop
        @required()
        dutyPrice: number;
        //#endregion dutyPrice Prop


        //#region subTotal Prop
        @required()
        subTotal: number;
        //#endregion subTotal Prop


        //#region exchangrate Prop
        @required()
        exchangrate: number;
        //#endregion exchangrate Prop


        //#region actualPriceInUSD Prop
        @required()
        actualPriceInUSD: number;
        //#endregion actualPriceInUSD Prop


        //#region taxPriceInUSD Prop
        @required()
        taxPriceInUSD: number;
        //#endregion taxPriceInUSD Prop


        //#region dutyPriceInUSD Prop
        @required()
        dutyPriceInUSD: number;
        //#endregion dutyPriceInUSD Prop


        //#region subTotalInUSD Prop
        @required()
        subTotalInUSD: number;
        //#endregion subTotalInUSD Prop


        //#region purchaseCurrencyCode Prop
        @maxLength({ value: 10 })
        purchaseCurrencyCode: string;
        //#endregion purchaseCurrencyCode Prop


        //#region purchaseCurrencyId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        purchaseCurrencyId: number;
        //#endregion purchaseCurrencyId Prop

        //#region purchaseCurrencyId Prop
        countryId: number;
        //#endregion purchaseCurrencyId Prop

        //#region purchaseCurrencyId Prop
        @required()
        statusId: number;
        //#endregion purchaseCurrencyId Prop

        //#region actualUnitPriceExclTax Prop
        @required()
        actualUnitPriceExclTax: number;
        //#endregion actualUnitPriceExclTax Prop

}