import { Component, OnInit } from '@angular/core';
import { AbstractFabergeLocalization } from '../domain/abstract-faberge-localization';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { LocalizationContent } from 'src/app/models/extended-models/localization-content';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { FabergeLocalizationEditComponent } from '../edit/faberge-localization-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { FRONTSTORESERVER_URL } from 'src/app/domain/system-constant';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
@multilingual("FabergeLocalizationAddComponent")
@access({ accessLevel: RolePermissionEnums.FabergeLocalization, action: "post" })

@Component({
  selector: 'app-faberge-localization-add',
  templateUrl: './faberge-localization-add.component.html',
  styleUrls: ['./faberge-localization-add.component.css']
})
export class FabergeLocalizationAddComponent extends AbstractFabergeLocalization implements OnInit {

  languageId:number;
  languageName:string;
  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
  localizationEdit: any = FabergeLocalizationEditComponent;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;

  constructor(private activatedRoute:ActivatedRoute,private formBuilder:RxFormBuilder,private router:Router,modelView:ModalView,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView=modelView;
    this.activatedRoute.queryParams.subscribe(t=>{
      this.languageId=t['languageId']
    })
    this.moduleID = RolePermissionEnums.FabergeLocalization;
  }

  ngOnInit() {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.showComponent=false;
    this.titleHelp = getLocalizedValue("Title_Help");
    this.localizationModel=new LocalizationContent();
    this.localizationModel.languageId=this.languageId;
    this.get({path:USER_LOOKUPS.languageLookUp}).subscribe((t:any)=>{
        this.languageLookup=t;
        if(this.languageLookup!=null){
          this.languageLookup.forEach(x=>{
            if(x.languageId==this.languageId){
              this.languageName=x.languageName;
            }
          })
        }
        this.localizationFormGroup=this.formBuilder.formGroup(LocalizationContent,this.localizationModel) as IFormGroup<LocalizationContent>;
        this.showComponent=true;
    })
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  addLocalization(isRedirect:boolean){
    this.localizationFormGroup.submitted=true;
    if(this.localizationFormGroup.valid){
      this.spin=true;
      this.post({  hostUri: FRONTSTORESERVER_URL , body:this.localizationFormGroup.value}).subscribe(t=>{
        this.spin=false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert[0].showMessage);
        }
        if(isRedirect){
          this.router.navigate(['/faberge-localization', this.languageId], { queryParams: { "key":  this.localizationFormGroup.value.localizationKey } });
        }
        else{
          this.router.navigate(['/faberge-localization']);
        }
      })
    }

  }
  get componentName(): string {
    return "FabergeLocalizationAddComponent";
  }
}
