import { http } from "@rxweb/http";
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent, } from '@rxweb/angular-router';
import { vOrderStatusLookUp, vPaymentStatusLookUp } from "@app/models";
@http({

  path: "api/UserSearch"
})

export class AbstractCorporateOrders extends CoreComponent {
  showComponent: boolean = false;
  spin: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  id: number = 0;
  //#291870//28-06-2024//adding order mapping to project and event
  showGrid: boolean = false;
  isShowGrid: boolean;
  totalRecords: number = 0;
  isNoRecordFound: boolean;
  isFilter: boolean = false;
  corporateOrderLookup: any = {
    paymentStatusLookUps: vPaymentStatusLookUp,
    orderStatusLookUps: vOrderStatusLookUp
  }
  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }
  
  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
    })
  }
}
