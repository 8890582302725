import { Component, OnInit } from '@angular/core';
import { AllSettingAddComponent } from 'src/app/components/master/all-settings/add/all-setting-add.component';
import { MessageTemplateListComponent } from 'src/app/components/master/message-templates/list/message-template-list.component';
import { EmailAccountListComponent } from 'src/app/components/master/email-account/list/email-account-list.component';
import { MeasureDimensionListComponent } from 'src/app/components/master/measure-dimensions/list/measure-dimension-list.component';
import { MeasureWeightListComponent } from 'src/app/components/master/measure-weights/list/measure-weight-list.component';
import { SettingListComponent } from 'src/app/components/master/settings/list/setting-list.component';
import { ApplicationExceptionLogListComponent } from 'src/app/components/master/application-exception-logs/list/application-exception-log-list.component';
import { SystemInformationListComponent } from 'src/app/components/master/system-information/list/system-information-list.component';
import { RolePermissionListComponent } from 'src/app/components/master/role-permissions/list/role-permission-list.component';
import { CreditCardTypeListComponent } from 'src/app/components/master/credit-card-types/list/credit-card-type-list.component';
import { PaymentMethodListComponent } from 'src/app/components/master/payment-methods/list/payment-method-list.component';
import { DiscountListComponent } from 'src/app/components/master/discounts/list/discount-list.component';
import { TaxCategoryListComponent } from 'src/app/components/master/tax-category/list/tax-category-list.component';
import { ShippingMethodListComponent } from 'src/app/components/master/shipping-method/list/shipping-method-list.component';
import { StateProvinceListComponent } from 'src/app/components/master/state-provinces/list/state-province-list.component';
import { LanguageListComponent } from 'src/app/components/master/languages/list/language-list.component';
import { ActivityLogTypeListComponent } from 'src/app/components/master/activity-log-types/list/activity-log-type-list.component';
import { ActivityLogListComponent } from 'src/app/components/master/activity-logs/list/activity-log-list.component';
import { TaxProviderEditComponent } from 'src/app/components/master/tax-provider/edit/tax-provider-edit.component';
import { BannedIpAddressListComponent } from 'src/app/components/master/banned-ip-addresses/list/banned-ip-address-list.component';
import { BannedIpNetworkListComponent } from 'src/app/components/master/banned-ip-network/list/banned-ip-network-list.component';
import { BlackListComponent } from 'src/app/components/master/black-list/blacklist.component';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FabergeLocalizationListComponent } from 'src/app/components/master/faberge-localization/list/faberge-localization-list.component';
import { LanguageContentKeyListComponent } from 'src/app/components/master/language-content-keys/list/language-content-key-list.component';
import { UserLockoutListComponent } from 'src/app/components/master/user-lockout/list/user-lockout-list.component';
import { DepartmentListComponent } from 'src/app/components/master/departments/list/department-list.component';
import { QueuedEmailListComponent } from 'src/app/components/master/queued-emails/list/queued-email-list.component';
import { HelpSupportListComponent } from 'src/app/components/master/help-supports/list/help-support-list.component';
import { CountryListComponent } from 'src/app/components/master/countries/list/country-list.component';
import { RoleListComponent } from 'src/app/components/master/roles/list/role-list.component';
import { BankHolidayListComponent } from 'src/app/components/master/bank-holidays/list/bank-holidays-list.component';
import { CustomerHolidayListComponent } from 'src/app/components/master/customer-holidays/list/customer-holiday-list.component';
import { LeaveTypeListComponent } from 'src/app/components/master/leave-types/list/leave-type-list.component';
import { FormsDocumentListComponent } from 'src/app/components/master/forms-document/list/forms-document-list.component';
import { SystemHelpListComponent } from 'src/app/components/master/system-help/list/system-help-list.component';
import { FileExtentionListComponent } from 'src/app/components/master/file-extentions/list/file-extentions-list.component';
import { ApplicationObjectListComponent } from 'src/app/components/master/application-objects/list/application-objects-list.component';
import { RegionListComponent } from 'src/app/components/master/regions/list/region-list.component';
import { multilingual } from '@rxweb/localization';
import { PowerBIReportListComponent } from 'src/app/components/master/power-bi-reports/list/power-bi-report-list.component';
import { DocumentTypeListComponent } from 'src/app/components/master/document-type/list/document-type-list.component';
@multilingual("SystemComponent")
@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit {

  constructor(private browserStorage:BrowserStorage,private title:Title) { }
  index:number;
  showRolePermission:string;
  globalSettings:any=AllSettingAddComponent;
  messageTemplate:any=MessageTemplateListComponent;
  emailAccounts:any=EmailAccountListComponent;
  measureDimensions:any=MeasureDimensionListComponent;
  measureWeights:any=MeasureWeightListComponent;
  allSettings:any=SettingListComponent;
  log:any=ApplicationExceptionLogListComponent;
  systemInfo:any=SystemInformationListComponent;
  moduleLevel:any=RolePermissionListComponent;
  creditCard:any=CreditCardTypeListComponent;
  paymentMethods:any=PaymentMethodListComponent;
  discounts:any=DiscountListComponent;
  taxProviders:any=TaxProviderEditComponent
  taxClasses:any=TaxCategoryListComponent;
  shippingMethods:any=ShippingMethodListComponent;
  stateProvinces:any=StateProvinceListComponent;
  languages:any=LanguageListComponent;
  activityTypes:any=ActivityLogTypeListComponent;
  activityLogs:any=ActivityLogListComponent;
  blacklist:any=BlackListComponent;
  rolePermission:any=RolePermissionListComponent;
  fabergeLocalization:any=FabergeLocalizationListComponent;
  localizationContent:any=LanguageContentKeyListComponent;
  
  userLockout:any=UserLockoutListComponent;
  departments:any=DepartmentListComponent;
  messageQueue:any=QueuedEmailListComponent;
  helpSupport:any=HelpSupportListComponent;
  countries:any=CountryListComponent;
  userRole:any=RoleListComponent;
  bankHolidays:any=BankHolidayListComponent;
  holidayProcess:any=CustomerHolidayListComponent;
  leaveTypes:any=LeaveTypeListComponent;
  formsDocument:any=FormsDocumentListComponent;
  systemHelp:any=SystemHelpListComponent;
  regions:any = RegionListComponent;
  fileExtentions:any = FileExtentionListComponent;
  powerBIReportList:any=PowerBIReportListComponent;
  documentTypes:any=DocumentTypeListComponent;
  applicationObject:any = ApplicationObjectListComponent;

  
  // bannedIpAddressList:any=BannedIpAddressListComponent;
  // bannedIpNetworkList:any=BannedIpNetworkListComponent;
  ngOnInit() {
   this.title.setTitle("System - G-Trac Admin");   
   let roles:any[];
   //this.index =Number(this.browserStorage.local.get('isGA'));
   this.showRolePermission=this.browserStorage.local.get('isGA').toString();

  }
  get componentName(): string {
    return "SystemComponent";
  }
}
