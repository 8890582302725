<div [rxSpinner]="spin && showInnerSpin" *rxMultilingualExtended="_this" [rxLocalisationInit]="componentName">
  <div class="px-2" [ngClass]="{
      'mt-5': isUserorSupplier,
      'mt-3': !isUserorSupplier
    }">
    <ngx-dropzone [disabled]="disabled" (change)="onSelect($event)" [ngStyle]="{'height' : isEditorDocument ? '60px' : '180px'}">
      <ngx-dropzone-label>
        <ng-container *ngIf="isPicture" [rxLocalisationInit]="componentName">
          <label rxText='DocumentListComponent_DropZone_isPicture_t'></label>
        </ng-container>
        <ng-container *ngIf="!isPicture" [rxLocalisationInit]="componentName">
          <label rxText='DocumentListComponent_DropZone_isDocument_t'></label>
        </ng-container>
      </ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>

  <div class="wrapper wrapper-content pb-0" *ngIf="isPicture" >
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-content">
            <!-- <h2>Picture gallery</h2> -->

            <div class="lightBoxGallery" *ngIf="!isNoRecordFound">

              <div class="row" style="max-width: 100%;width: 100%;">

                <div class="col-sm-6 col-xl-auto mb-3 pr-0" *ngFor="let item of documentListModel;let i = index">

                  <div class="col-12 h-100 prod-pic">
                    <div *ngIf="!disableDragDrop" draggable="false" onmousedown="if (event.preventDefault) event.preventDefault()">
                      <div class="drag_container example-box-drag " igxDrag igxDrop #dragRef id="{{item.pictureId}}"
                        (dropped)="onIconDropped($event)" (dragStart)="dragStartHandler(item.pictureId)"
                        (ghostCreate)="ghostCreateHandler(dragRef)" (dragEnd)="dragEndHandler(dragRef)"
                        (enter)="onEnterHandler($event)" [ghostClass]="'drag_ghost_class'" (dblclick)="openFullScreen('image_' + i, item.fileUrl, $event)">
                        <!-- <div draggable="false" onmousedown="if (event.preventDefault) event.preventDefault()"> -->
                        <label class="mb-0">
                          <a class="" [href]="item.fileUrl" type="image/*"
                            *ngIf="item.isVideo=='false'">
                            <div class="picture-tab-div">
                              <ng-container *ngIf="item.isPreviewSupported">
                                <img class="img-fluid pic-tab-gallary-img" [src]="item.thumbnailFileUrl" [alt]="item.fileName" loading="lazy" (error)="onImageError($event)">
                              </ng-container>
                              <ng-container *ngIf="!item.isPreviewSupported">
                                <img class="img-fluid pic-tab-gallary-img" src="{{defaultThumbnailPath}}" loading="lazy" alt="Preview Not Available">
                              </ng-container>
                            </div>
                          </a>
                          <a class="" [href]="item.fileUrl" type="video/mp4"
                            *ngIf="item.isVideo=='true'">
                            <div class="picture-tab-div">
                              <ng-container *ngIf="item.isPreviewSupported">
                              <video class="img-fluid pic-tab-gallary-img" style="height: 150px !important;" controls controlsList="nodownload">
                                <source src="{{item.fileUrl}}" (error)="onVideoError($event)">
                                Your browser does not support the video tag.
                              </video>
                            </ng-container>
                            <ng-container *ngIf="!item.isPreviewSupported">
                              <img class="img-fluid pic-tab-gallary-img" src="{{defaultThumbnailPath}}" alt="Preview Not Available">
                            </ng-container>
                            </div>
                          </a>
                        </label>
                        <!-- </div>   -->
                      </div>
                    </div>
                    <div *ngIf="disableDragDrop" onmousedown="if (event.preventDefault) event.preventDefault()" style="cursor: pointer;">
                      <div class="example-box " id="{{item.pictureId}}"
                         (click)="openFullScreen('image_' + i, item.fileUrl, $event)">
                        <label class="mb-0">
                          <a class="" [href]="item.fileUrl" type="image/*"
                            *ngIf="item.isVideo=='false'">
                            <div class="picture-tab-div">
                              <ng-container *ngIf="item.isPreviewSupported">
                                <img class="img-fluid pic-tab-gallary-img" [src]="item.thumbnailFileUrl" [alt]="item.fileName" loading="lazy" (error)="onImageError($event)">
                              </ng-container>
                              <ng-container *ngIf="!item.isPreviewSupported">
                                <img class="img-fluid pic-tab-gallary-img" src="{{defaultThumbnailPath}}" loading="lazy" alt="Preview Not Available">
                              </ng-container>
                            </div>
                          </a>
                          <a class="" [href]="item.fileUrl" type="video/mp4"
                            *ngIf="item.isVideo=='true'">
                            <div class="picture-tab-div">
                              <ng-container *ngIf="item.isPreviewSupported">
                              <video class="img-fluid pic-tab-gallary-img" style="height: 150px !important;" controls controlsList="nodownload">
                                <source src="{{item.fileUrl}}" (error)="onVideoError($event)">
                                Your browser does not support the video tag.
                              </video>
                            </ng-container>
                            <ng-container *ngIf="!item.isPreviewSupported">
                              <img class="img-fluid pic-tab-gallary-img" src="{{defaultThumbnailPath}}" alt="Preview Not Available">
                            </ng-container>
                            </div>
                          </a>
                        </label>
                      </div>
                    </div>

                    <div class="h-25" [rxLocalisationInit]="componentName">
                      <textarea class="form-control pic-textarea mb-1" *ngIf="hasComment()" id="documentDescription"
                        type="text" placeholder="Description" value="{{item.documentDescription}}"
                        (change)="discriptionChange($event,item)" [rxLocalisationInit]="componentName" rows="4"> </textarea>

                      <!-- <textarea class="form-control pic-textarea " *ngIf="fabergeNews()"
                      id="displayOrder" type="number" placeholder="Display Order"
                      value="{{item.displayOrder}}" (change)="displayOrderChange($event,item)" rows="1"> </textarea>  -->
                      <div class="text-right mt-2 ml-3">
                        <span style="font-size: 12px;" [rxLocalisationInit]="componentName"><label rxText='DocumentListComponent_CreatedAt_t'></label>: {{item.createdOn}}</span>
                      </div>
                      <div class="text-right mt-2 ml-3">

                        <a class="" [id]="'image_' + i" [href]="item.fileUrl" type="image/*" data-gallery=""
                          *ngIf="item.isVideo=='false'">
                          <button class="btn btn-secondary btn-image-control" [title]="titleView">
                            <i class="fa fa-eye" aria-hidden="true" style="color:green"></i>
                          </button>

                        </a>

                        
                        <a class="" [id]="'image_' + i" [href]="item.fileUrl" type="video/mp4" data-gallery=""
                          *ngIf="item.isVideo=='true'">
                          <button class="btn btn-secondary btn-image-control" [title]="titleView" >
                            <i class="fa fa-eye" aria-hidden="true" style="color:green"></i>
                          </button>

                        </a>
                        <a class="" [href]="item.fileUrl" type="image/*"
                          *ngIf="item.isVideo=='false'" target="_blank" [download]="item.fileUrl">
                          <button class="btn btn-secondary btn-image-control" [title]="titleDownload" >
                            <i class="fa fa-download" aria-hidden="true" style="color:green"></i>
                          </button>
                        </a>
                        <a class="" [href]="item.fileUrl" type="video/mp4"
                          *ngIf="item.isVideo=='true'" target="_blank" [download]="item.fileUrl">
                          <button class="btn btn-secondary btn-image-control" [title]="titleDownload" >
                            <i class="fa fa-download" aria-hidden="true" style="color:green"></i>
                          </button>
                        </a>

                        <button *ngIf="hasComment()" (click)="onEdit(item)" [title]="titleSave" class="btn btn-secondary btn-image-control">
                          <i class="fa fr-save" style="color: #2084c3;"></i>
                        </button>
                        <button (click)="onDelete(item)" class="btn btn-secondary btn-image-control" [title]="titleDelete">
                          <i class="fr-delete text-danger"></i>
                        </button>
                      </div>
                    </div>

                  </div>

                </div>


              </div>

              <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls" data-hide-page-scrollbars="false">
                <div class="slides"></div>
                <h3 class="title"></h3>
                <a class="prev">‹</a>
                <a class="next">›</a>
                <a class="close">×</a>
                <a class="play-pause"></a>
                <ol class="indicator"></ol>
              </div>
            </div>
            <div *ngIf="documentListModel && (documentListModel.length + deletedCount) < totalRecords" id="loadMoreButtonContainer" class="button-container">
              <div *ngIf="!loading; else loadingTemplate" class="info-text btn btn-success" style="cursor: pointer;" (click)="bindOnScroll()" [rxLocalisationInit]="componentName">
                <span rxText="DocumentListComponent_LoadMoreMsg_t"></span>
                <i class="fa fa-arrow-down icon"></i> 
              </div>
            
              <ng-template #loadingTemplate>
                <div class="info-text btn btn-success" style="cursor: default;" [rxLocalisationInit]="componentName">
                  <span rxText="DocumentListComponent_LoadingMsg_t"></span>
                  <i class="fa fa-spinner fa-spin icon"></i> 
                </div>
              </ng-template>
            </div>
            <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
              <h4>No Record Found</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="wrapper wrapper-content" *ngIf="!isPicture && !isEditorDocument">
    <div class="row mydrag">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-content">
            <div class="dataTables_filter  d-flex flex-wrap align-items-center" *ngIf="isShowSearch">
              <label class="ml-auto mr-3 mb-3">
                <!-- *ngIf="!isNoRecordFound" -->
                <input type="search" (keyup.enter)="search($event)"
                  class=" table_search form-control form-control-sm pr-4 mt-3 pull-right" placeholder="" [title]="titleSearch">
                <button type="button" class="btn btn-primary mr-2 mt-3 pull-right" (click)="updateDocumentTypes()" rxText="Btn_Save_t">Save</button>
                <ng-container *ngIf="documnentList.length > 0 && isAllowMoveDocuments == true" [rxLocalisationInit]="componentName">
                  <button type="button" class="btn btn-success mr-2 mt-3 pull-right"
                    [disabled]="!(selectedDocumentIds.length > 0 || (isHeaderBtnChecked && excludedDocumentIds.length < totalMovableDocuments))"
                    (click)="moveDocuments()" rxText="DocumentListComponent_MoveBtn_t"></button>
                  <ng-container *ngIf="!isHeaderBtnChecked" [rxLocalisationInit]="componentName">
                    <button type="button" class="btn btn-primary mr-2 mt-3 pull-right" (click)="selectAllDocumentsToMove()"
                      rxText="DocumentListComponent_SelectBtn_t"></button>
                  </ng-container>
                  <ng-container *ngIf="isHeaderBtnChecked" [rxLocalisationInit]="componentName">
                    <button type="button" class="btn btn-primary mr-2 mt-3 pull-right" (click)="selectAllDocumentsToMove()"
                      rxText="DocumentListComponent_UnselectBtn_t"></button>
                  </ng-container>
                </ng-container>
              </label>
              <div class="ibox-tools mb-3 pb-1" style="position: relative;" *ngIf="!isNoRecordFound">
                <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
                  <i class="fa fa-cog"></i>
              </a>
              <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
              (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
              [gridColumnConfig]="documentListGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
              [moduleID]="moduleID" [selectedTab]="selectedTab">
              </app-system-table-setting>
              </div>
            </div>
            <div class="table-responsive" *ngIf="isShowGrid">
              <!-- *ngIf="isShowGrid" -->
              <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                <rx-grid [design]="documentListGrid"></rx-grid>
              </div>
            </div>
            <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
              <h4>No Record Found</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isEditorDocument" class="p-3">
    <p *ngFor="let url of documentListModel">
      <a href="{{url.fileUrl}}" _target="blank">{{url.fileName}}</a> <a class="float-right" [title]="deleteHelp"
        (click)="onDelete(url)"><i class="fr-delete text-danger"></i></a> <a [title]="copyHelp" class="float-right mx-2"
        (click)="onCopy(url.fileUrl)"><i class="fa fa-copy"></i></a>
    </p>
  </div>





</div>
