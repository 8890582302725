import { RxHttp,http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { MeasureDimension } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
@http({
    path: "api/MeasureDimensions",
})
export class AbstractMeasureDimension extends CoreComponent {
    measureDimensionFormGroup: IFormGroup<MeasureDimension>
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    validField: string[] = ["name","ratio","systemKeyword","displayOrder"];
    breakLoop: boolean;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
   
  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
 }

	
	
}
