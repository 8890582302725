<ng-container *rxMultilingual="">
<div class="modal-dialog modal-lg" *ngIf="showComponent==true" [rxLocalisationInit]="componentName">
  <div class="modal-content" >
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="AddressAddComponent_Title_t"></h4>
      <button type="button" class="close py-2" [title]="titleClose" (click)="hide()">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-0" [formGroup]="addressFormGroup"  [rxSpinner]="spin" (keyup.enter)="AddForm()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" >{{userOrSupplier}}</label>
            <div class="col-md-8">
              <label class="col-form-label" >{{email}}</label>
              <!-- <small class="form-text text-danger" [class.d-block]="addressFormGroup.controls.firstName.errors">{{addressFormGroup.controls.firstName.errorMessage}}</small> -->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_FirstName_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="1" formControlName="firstName"  rxPlaceholder="AddressAddEditComponent_FirstName_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.firstName.errors">{{addressFormGroup.controls.firstName.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_LastName_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="2" formControlName="lastName"  rxPlaceholder="AddressAddEditComponent_LastName_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.lastName.errors">{{addressFormGroup.controls.lastName.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_PhoneNumber_t"></label>
            <div class="col-md-8">
              <!-- (keyup)="onPhoneKeyPress()" -->
              <input type="text" class="form-control" tabindex="3" formControlName="phoneNumber"   rxPlaceholder="AddressAddEditComponent_PhoneNumber_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.phoneNumber.errors">{{addressFormGroup.controls.phoneNumber.errorMessage}}</small>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_Email_t"></label>
            <div class="col-md-8">
              <!-- (keyup)="onEmailKeyPress()" -->
              <input type="text" class="form-control" tabindex="4" formControlName="email"   rxPlaceholder="AddressAddEditComponent_Email_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.email.errors">{{addressFormGroup.controls.email.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_FaxNumber_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="5" formControlName="faxNumber"  rxPlaceholder="AddressAddEditComponent_FaxNumber_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.faxNumber.errors">{{addressFormGroup.controls.faxNumber.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_Company_t"></label>
            <div class="col-md-8">
              <input type="text" tabindex="6" class="form-control" formControlName="company"  rxPlaceholder="AddressAddEditComponent_Company_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.company.errors">{{addressFormGroup.controls.company.errorMessage}}</small>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_Address1_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="7" rxFocus formControlName="address1"  rxPlaceholder="AddressAddEditComponent_Address1_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.address1.errors">{{addressFormGroup.controls.address1.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_Address2_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="8" formControlName="address2"  rxPlaceholder="AddressAddEditComponent_Address2_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.address2.errors">{{addressFormGroup.controls.address2.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_City_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control"  tabindex="9" formControlName="city"  rxPlaceholder="AddressAddEditComponent_City_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.city.errors">{{addressFormGroup.controls.city.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="AddressAddEditComponent_Country_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="countryID" (change)="selectState()">
                <option [selected]="true" value="null">Select Country</option>
                <option *ngFor="let item of addressLookup.vCountryLookUp" [value]="item.countryId">
                  {{item.countryName}}
                </option>
              </select> -->
              <rx-select [(source)]="addressLookup.vCountryLookUp" #rxSelect [controlTabIndex]="10" mainClass="form-control" (changed)="selectState()"
              formControlName="countryID" [keyValueProps]="['countryName','countryId']" [selectPlaceholder]="'Select Country'">
              </rx-select>
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.countryID.errors">{{addressFormGroup.controls.countryID.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row" *ngIf="!isshowStateTextbox" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="AddressAddEditComponent_StateProvince_t"></label>
            <div class="col-md-8" *ngIf="isDDStateChange" [rxSelectExtended]="_this">
              <rx-select [(source)]="stateProvinceLookUp" #rxSelect mainClass="form-control" (changed)="hideMsg($event)"
              formControlName="stateProvinceID" [keyValueProps]="['stateProvinceName','stateProvinceId']" [controlTabIndex]="11" [selectPlaceholder]="'Select State or Province'">
              </rx-select>
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.stateProvinceID.errors&& !this.stateProvinceError">{{addressFormGroup.controls.stateProvinceID.errorMessage}}</small>
              <!-- <small class="form-text text-danger" *ngIf="this.stateProvinceError">You need to select appropriate value</small> -->
            </div>
          </div>

          <div class="form-group row" *ngIf="isshowStateTextbox" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_StateProvince_t"></label>
            <div class="col-md-8" *ngIf="isDDStateChange">
                <input type="text" formControlName="stateProvinceName" tabindex="11" class="form-control" (keyup)="hideMsg($event)"  placeholder="Enter State/Province">
              <!-- <small class="form-text text-danger" *ngIf="addressFormGroup.controls.stateProvinceName.errors  && formSubmit && !this.stateProvinceError">{{addressFormGroup.controls.stateProvinceName.errorMessage}}</small>
              <small class="form-text text-danger" *ngIf="this.stateProvinceError">You can't leave this field empty</small> -->
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.stateProvinceName.errors">{{addressFormGroup.controls.stateProvinceName.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="AddressAddEditComponent_ZipPostalCode_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="zipPostalCode" tabindex="12"  rxPlaceholder="AddressAddEditComponent_ZipPostalCode_p">
              <small class="form-text text-danger" *ngIf="addressFormGroup.controls.zipPostalCode.errors">{{addressFormGroup.controls.zipPostalCode.errorMessage}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="AddForm()" rxText="Btn_Save_t"></button>
      <button (click)="hide()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
    </div>
  </div>
</div>
</ng-container>
