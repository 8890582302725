import { GridDesigner, ElementOnDemand, ElementConfig } from "@rxweb/grid"
import { RxSelectComponent } from '@rxweb/angular/select';
import { FormControl } from '@angular/forms';
import { EmbeddedViewRef } from '@angular/core';
import { RxDateComponent } from '@rxweb/angular/datepicker';
import { dbAccessModule } from './security/authorization-resolver';
import { RxTagComponent } from '@rxweb/angular/tag';
import { RelationshipStatusEnum } from "../enums/relationship-status.enum";
//import { RxDatePickerComponent } from '@rxweb/angular/datepicker/rx-date-picker.component';

export class SelectDropDown implements ElementOnDemand {
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {

    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].dropdownData : this.dropSource);

    // componentRef.instance.source=[{'id':1, 'name':"India"},{'id':2, 'name':"Australia"}];
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Relationship"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].relationShipId) || this.jObject[this.count].relationShipId == "" || this.jObject[this.count].relationShipId == 0) ? "" : this.jObject[this.count].relationShipId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].relationShipId = x;
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectRelationshipStatusDropDown implements ElementOnDemand {
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {

    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].relationshipStatusDropdown : this.dropSource);

    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Relationship Status"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].relationshipStatusId) || this.jObject[this.count].relationshipStatusId == "") ? RelationshipStatusEnum.Active : this.jObject[this.count].relationshipStatusId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].relationshipStatusId = x;
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}

export class SelectParentRelationshipDropDown implements ElementOnDemand {
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {

    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].parentRelationshipDropdown : this.dropSource);

    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Relationship"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].parentRelationshipId) || this.jObject[this.count].parentRelationshipId == "" || this.jObject[this.count].parentRelationshipId == 0) ? "" : this.jObject[this.count].parentRelationshipId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].parentRelationshipId = x;
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectReportToDropDown implements ElementOnDemand {
  componentRef: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    // componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].dropDownData : this.dropSource);
    // componentRef.instance.keyValueProps = ['name', 'id'];
    // componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    // componentRef.instance.selectPlaceholder = "Select Value";
    // componentRef.instance.formControl = new FormControl();
    // if(this.jObject[this.count].reportToId !=0)
    // {
    //   componentRef.instance.formControl.value = this.jObject[this.count].reportToId;
    // }
    // componentRef.instance.formControl.valueChanges.subscribe(x => {
    //   if (!isNaN(x)) {
    //     this.jObject[this.config.columnName] = x;
    //     this.jObject[this.count].reportToId = x;
    //   }
    // })
    // this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    // return this.element;
    componentRef.instance.autoComplete = true;
    componentRef.instance.queryParams = { 'lookupType': 'reportToAdmin', "companyRoleId": this.jObject[this.count].roleId};
    componentRef.instance.path = 'api/SearchEvent/GetLookups';
    componentRef.instance.valueInQueryParams = "true";
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.minimumCharacterSearchLength = "2";
    componentRef.instance.enableFreeText = "true";
    componentRef.instance.selectPlaceholder = "Select Person";
    componentRef.instance.text = this.jObject[this.count].reportTo;
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = this.jObject[this.count].reportToId;
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].reportToId) || this.jObject[this.count].reportToId == "" || this.jObject[this.count].reportToId == 0) ? null : this.jObject[this.count].reportToId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].reportToId = x;
        if(componentRef.instance.dropSource.length > 0){
          let reportTo = componentRef.instance.dropSource.find(z=>z.key == x)
          this.jObject[this.count].reportTo = reportTo != null ? reportTo.value: '';
        }
         
      }
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectAutoCompleteElement implements ElementOnDemand {
  componentRef: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  onLoad(): HTMLElement {

    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    //componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].dropDownData : this.dropSource);
    componentRef.instance.autoComplete = true;
    componentRef.instance.queryParams = { 'lookupType': 'users' };
    componentRef.instance.path = 'api/SearchEvent/GetLookups';
    componentRef.instance.valueInQueryParams = "true";
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.minimumCharacterSearchLength = "2";
    componentRef.instance.enableFreeText = "true";
    componentRef.instance.selectPlaceholder = "Select Person";
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = this.jObject[this.count].personAffectedId;
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].personAffectedId = x;
      }
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectEditAutoCompleteElement implements ElementOnDemand {
  componentRef: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private incidentId, private dropSource?: any) {
  }
  onLoad(): HTMLElement {

    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.autoComplete = true;
    componentRef.instance.queryParams = { 'lookupType': 'ianEditUser', 'incidentId': this.incidentId };
    componentRef.instance.path = 'api/SearchEvent/GetIANEditLookups';
    componentRef.instance.valueInQueryParams = "true";
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.minimumCharacterSearchLength = "2";
    componentRef.instance.enableFreeText = "true";
    componentRef.instance.selectPlaceholder = "Select Person";
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = this.jObject[this.count].personAffectedId;
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].personAffectedId = x;
      }
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectDate implements ElementOnDemand {
  componentRef: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count) {
  }
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxDateComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);

    componentRef.instance.source = this.jObject[this.count].WholesalepartnerSoldDate;
    componentRef.instance.pickerClass = "form-control";
    componentRef.instance.showAddon = "true";
    componentRef.instance.formControl = new FormControl(this.jObject[this.count].WholesalepartnerSoldDate);
    //componentRef.instance.formControl.value= this.jObject[this.count].WholesalepartnerSoldDate;
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      this.jObject[this.config.columnName] = x;
      this.jObject[this.count].WholesalepartnerSoldDate = this.jObject[this.config.columnName];
      this.jObject[this.count].recordUpdated = true;
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectStartDate implements ElementOnDemand {
  componentRef: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxDateComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.pickerClass = "form-control";
    componentRef.instance.showAddon = "true";
    componentRef.instance.formControl = new FormControl(this.jObject[this.config.columnName]);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      this.jObject[this.config.columnName] = x;
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();

      this.componentRef = undefined;
    }
  }
}

export class AppGrid extends GridDesigner {

  constructor(source: any[], model: Function, gridConfiguration?: any,rowCount?:number) {
    super(source, model, gridConfiguration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = rowCount == undefined ? 25 :rowCount;
    this.cloneFooterOnTop = false;
    this.pagingSource = [25, 50, 75, 100,3000];
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
  authorize(authorizeConfig: { [key: string]: any }) {

    if (dbAccessModule) {
      return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action];
    } else {
      return false;
    }

  }

}

export class AppGridDocumentDesigner extends GridDesigner {
  
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private isSelect) {
    super(source, model, configuration);
    
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom .chosen-drop-static".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public rowcount: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  resolveOnDemandSelector(a, c) {

    if (this.isSelect == true) {
      
      let instance = new DocumetTypeDDL(this.componentResolver, this.viewContainerRef, a, this.source, this.rowcount, this.bindingSource);
      instance.onLoad();
      this.rowcount++;
      return instance;
    }
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}


export class DocumetTypeDDL implements ElementOnDemand {
  
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
      
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].documentTypeData :
     this.jObject[this.count].documentTypeFlag
      ? this.jObject[this.count].documentTypeData : this.dropSource[this.count].documentTypeRestrictedData);
 
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Document Type"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.selectDisabled = this.jObject[this.count].documentTypeFlag;
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].documentTypeId) || this.jObject[this.count].documentTypeId == 0) ? "" : this.jObject[this.count].documentTypeId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].documentTypeId = x;
        
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}

export class AppGridNewPiece extends GridDesigner {

  constructor(source: any[], model: Function, gridConfiguration?: any) {
    super(source, model, gridConfiguration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even odd".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
  authorize(authorizeConfig: { [key: string]: any }) {

    if (dbAccessModule) {
      return dbAccessModule[authorizeConfig.accessLevel][authorizeConfig.action];
    } else {
      return false;
    }

  }

}

export class AppGridDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private isSelect, private dropSource?: any,private dropSource1?: any,private dropSource2?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public cnt1: number = 0;
  public cnt2: number = 0;
  public cnt3: number = 0;
  public cnt4: number = 0;
  public cnt5: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  public instance: any;
  resolveOnDemandSelector(a, c) {

    if (this.isSelect == true) {
      if (this.useUpdatedSource) {
        this.source = this.updatedSource;
      }
      if(a.columnName=="relationShipId"){
        this.instance = new SelectDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.dropSource);
        this.instance.onLoad();
        this.cnt++;
      }
      if(a.columnName=="dropdownData"){
        this.instance = new SelectDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt1, this.dropSource);
        this.instance.onLoad();
        this.cnt1++;
      }
      if(a.columnName=="relationshipStatusDropdown"){
        this.instance = new SelectRelationshipStatusDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt2);
        this.instance.onLoad();
        this.cnt2++;
      }
      if(a.columnName=="relationshipStatusId"){
        this.instance = new SelectRelationshipStatusDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt3, this.dropSource1);
        this.instance.onLoad();
        this.cnt3++;
      }
      if(a.columnName=="parentRelationshipDropdown"){
        this.instance = new SelectParentRelationshipDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt4);
        this.instance.onLoad();
        this.cnt4++;
      }
      if(a.columnName=="parentRelationshipId"){
        this.instance = new SelectParentRelationshipDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt5,this.dropSource2);
        this.instance.onLoad();
        this.cnt5++;
      }
      return this.instance;
    }
    else {
      let instance = new SelectStartDate(this.componentResolver, this.viewContainerRef, a, c, this.cnt);
      instance.onLoad();
      this.cnt++;
      return instance;
    }
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}

export class AppGridCorporateDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private dropSource?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  resolveOnDemandSelector(a, c) {
    let instance = new SelectStartDate(this.componentResolver, this.viewContainerRef, a, c, this.cnt);
    instance.onLoad();
    this.cnt++;
    return instance;
  }
  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}
export class AppGridEmployeeDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private dropSource?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public count: number = 0;
  public ptcnt: number = 0;
  public expcount: number = 0;
  public pgcnt: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  public instance: any;
  resolveOnDemandSelector(a, c) {
    if (this.source.length == this.cnt) {
      this.cnt = 0;
    }
    if (this.source.length == this.count) {
      this.count = 0;
    }
    if (this.source.length == this.ptcnt) {
      this.ptcnt = 0;
    }
    if (this.source.length == this.expcount) {
      this.expcount = 0;
    }
    if (this.source.length == this.pgcnt) {
      this.pgcnt = 0;
    }
    if (a.columnName == "dropDownData") {
      this.instance = new SelectReportToDropDown(this.componentResolver, this.viewContainerRef, a, this.source, this.count, this.dropSource);
      this.instance.onLoad();
      this.count++;
    }
    if (a.columnName == "lookupData") {
      this.instance = new SelectDropDownElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.source[this.cnt].lookupData, this.source[this.cnt].keyId, "keyId", this.dropSource);
      this.instance.onLoad();
      this.cnt++;
    }
    //#292627//08-07-2024//added two new columns in employement grid PayRoll  and PatersonGrade dropdown template
    if (a.columnName == "payrollList") {
      this.instance = new SelectDropDownPayroll(this.componentResolver, this.viewContainerRef, a, this.source, this.ptcnt, this.dropSource);
      this.instance.onLoad();
      this.ptcnt++;
    }
    if (a.columnName == "expatList") {
      this.instance = new SelectDropDownExpat(this.componentResolver, this.viewContainerRef, a, this.source, this.expcount, this.dropSource);
      this.instance.onLoad();
      this.expcount++;
    }
    if (a.columnName == "patersonGradeList") {
      this.instance = new SelectDropDownPatersongrade(this.componentResolver, this.viewContainerRef, a, this.source, this.pgcnt, this.dropSource);
      this.instance.onLoad();
      this.pgcnt++;
    }
    if (a.selector == "rx-date") {
      this.instance = new SelectStartDate(this.componentResolver, this.viewContainerRef, a, c, this.cnt);
      this.instance.onLoad();
    }
    return this.instance;

  }
  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}
export class AppGridIncidentDesigner extends GridDesigner {

  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private isAccident, private dropSource?: any) {
    super(source, model, configuration);

    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public count: number = 0;
  public f : number = 0;
  public e : number = 0;
  public d: number = 0;
  public c: number = 0;
  public b: number = 0;
  public a: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  public instance: any;
  resolveOnDemandSelector(a, c) {
    if (this.useUpdatedSource) {
      this.source = this.updatedSource;
    }
    if (this.isAccident == false) {
      if (this.source.length == this.cnt) {
        this.cnt = 0;
      }
      if (this.source.length == this.d) {
        this.d = 0;
      }
      if (this.source.length == this.e) {
        this.e = 0;
      }
      if (this.source.length == this.f) {
        this.f = 0;
      }
      if (a.columnName == "dropdownList") {
        this.instance = new SelectDropDownList(this.componentResolver, this.viewContainerRef, a, this.source, this.d, this.dropSource);
        this.instance.onLoad();
        this.d++;
      }
      if (a.columnName == "daysList") {
        this.instance = new SelectDaysList(this.componentResolver, this.viewContainerRef, a, this.source, this.e, this.dropSource);
        this.instance.onLoad();
        this.e++;
      }
      if (a.columnName == "injuryTypeList") {
        this.instance = new SelectInjuryTypeList(this.componentResolver, this.viewContainerRef, a, this.source, this.f, this.dropSource);
        this.instance.onLoad();
        this.f++;
      }
      // this.instance = new SelectAutoCompleteElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.dropSource);
      // this.instance.onLoad();
      // this.cnt++;
      return this.instance;
    }
    else {
      if (this.source.length == this.cnt) {
        this.cnt = 0;
      }
      if (this.source.length == this.count) {
        this.count = 0;
      }
      if (this.source.length == this.c) {
        this.c = 0;
      }
      if (this.source.length == this.b) {
        this.b = 0;
      }
      if (this.source.length == this.a) {
        this.a = 0;
      }
      if (this.source.length == this.d) {
        this.d = 0;
      }
      if (this.source.length == this.e) {
        this.e = 0;
      }
      if (this.source.length == this.f) {
        this.f = 0;
      }
      if (a.columnName == "name") {

        this.instance = new SelectAutoCompleteElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.dropSource);
        this.instance.onLoad();
        this.cnt++;
      }
      if (a.columnName == "lookupData") {
        this.instance = new SelectSevrityElement(this.componentResolver, this.viewContainerRef, a, this.source, this.count, this.dropSource);
        this.instance.onLoad();
        this.count++;
      }
      if (a.columnName == "dropDownData") {
        this.instance = new SelectLostTimeInjuryElement(this.componentResolver, this.viewContainerRef, a, this.source, this.c, this.dropSource);
        this.instance.onLoad();
        this.c++;
      }
      if (a.columnName == "bodilyLocationData") {
        this.instance = new SelectbodilyLocationElement(this.componentResolver, this.viewContainerRef, a, this.source, this.b, this.dropSource);
        this.instance.onLoad();
        this.b++;
      }
      if (a.columnName == "injuryTypeData") {
        this.instance = new SelectInjuryTypeElement(this.componentResolver, this.viewContainerRef, a, this.source, this.a, this.dropSource);
        this.instance.onLoad();
        this.a++;
      }
      if (a.columnName == "dropdownList") {
        this.instance = new SelectDropDownList(this.componentResolver, this.viewContainerRef, a, this.source, this.d, this.dropSource);
        this.instance.onLoad();
        this.d++;
      }
      if (a.columnName == "daysList") {
        this.instance = new SelectDaysList(this.componentResolver, this.viewContainerRef, a, this.source, this.e, this.dropSource);
        this.instance.onLoad();
        this.e++;
      }
      if (a.columnName == "injuryTypeList") {
        this.instance = new SelectInjuryTypeList(this.componentResolver, this.viewContainerRef, a, this.source, this.f, this.dropSource);
        this.instance.onLoad();
        this.f++;
      }
      return this.instance;
    }
  }
  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}
export class AppGridEditIncidentDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private isAccident, private incidentId, private dropSource?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public count: number = 0;
  public f : number = 0;
  public e : number = 0;
  public d:number = 0;
  public c: number = 0;
  public b: number = 0;
  public a: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  public instance: any;
  resolveOnDemandSelector(a, c) {
    if (this.useUpdatedSource) {
      this.source = this.updatedSource;
    }
    if (this.isAccident == false) {
      if (this.source.length == this.cnt) {
        this.cnt = 0;
      }
      if (this.source.length == this.d) {
        this.d = 0;
      }
      if (this.source.length == this.e) {
        this.e = 0;
      }
      if (this.source.length == this.f) {
        this.f = 0;
      }
      if (a.columnName == "dropdownList") {
        this.instance = new SelectDropDownList(this.componentResolver, this.viewContainerRef, a, this.source, this.d, this.dropSource);
        this.instance.onLoad();
        this.d++;
      }
      if (a.columnName == "daysList") {
        this.instance = new SelectDaysList(this.componentResolver, this.viewContainerRef, a, this.source, this.e, this.dropSource);
        this.instance.onLoad();
        this.e++;
      }
      if (a.columnName == "injuryTypeList") {
        this.instance = new SelectInjuryTypeList(this.componentResolver, this.viewContainerRef, a, this.source, this.f, this.dropSource);
        this.instance.onLoad();
        this.f++;
      }
      // this.instance = new SelectEditAutoCompleteElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.incidentId, this.dropSource);
      // this.instance.onLoad();
      // this.cnt++;
      return this.instance;
    }
    else {
      if (this.source.length == this.cnt) {
        this.cnt = 0;
      }
      if (this.source.length == this.count) {
        this.count = 0;
      }
      if (this.source.length == this.d) {
        this.d = 0;
      }
      if (this.source.length == this.c) {
        this.c = 0;
      }
      if (this.source.length == this.b) {
        this.b = 0;
      }
      if (this.source.length == this.a) {
        this.a = 0;
      }
      if (this.source.length == this.e) {
        this.e = 0;
      }
      if (this.source.length == this.f) {
        this.f = 0;
      }
      if (a.columnName == "name") {

        this.instance = new SelectEditAutoCompleteElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.incidentId, this.dropSource);
        this.instance.onLoad();
        this.cnt++;
      }
      if (a.columnName == "lookupData") {
        this.instance = new SelectSevrityElement(this.componentResolver, this.viewContainerRef, a, this.source, this.count, this.dropSource);
        this.instance.onLoad();
        this.count++;
      }
      if (a.columnName == "dropDownData") {
        this.instance = new SelectLostTimeInjuryElement(this.componentResolver, this.viewContainerRef, a, this.source, this.c, this.dropSource);
        this.instance.onLoad();
        this.c++;
      }
      if (a.columnName == "bodilyLocationData") {
        this.instance = new SelectbodilyLocationElement(this.componentResolver, this.viewContainerRef, a, this.source, this.b, this.dropSource);
        this.instance.onLoad();
        this.b++;
      }
      if (a.columnName == "injuryTypeData") {
        this.instance = new SelectInjuryTypeElement(this.componentResolver, this.viewContainerRef, a, this.source, this.a, this.dropSource);
        this.instance.onLoad();
        this.a++;
      }
      if (a.columnName == "dropdownList") {
        this.instance = new SelectDropDownList(this.componentResolver, this.viewContainerRef, a, this.source, this.d, this.dropSource);
        this.instance.onLoad();
        this.d++;
      }
      if (a.columnName == "daysList") {
        this.instance = new SelectDaysList(this.componentResolver, this.viewContainerRef, a, this.source, this.e, this.dropSource);
        this.instance.onLoad();
        this.e++;
      }
      if (a.columnName == "injuryTypeList") {
        this.instance = new SelectInjuryTypeList(this.componentResolver, this.viewContainerRef, a, this.source, this.f, this.dropSource);
        this.instance.onLoad();
        this.f++;
      }
      return this.instance;
    }
  }
  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}
export class AppGridEditAccident extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private dropSource?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public count: number = 0;
  public f : number = 0;
  public e : number = 0;
  public c: number = 0;
  public b: number = 0;
  public a: number = 0;
  public d:number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  public instance: any;
  resolveOnDemandSelector(a, c) {
    if (this.useUpdatedSource) {
      this.source = this.updatedSource;
    }
    if (this.source.length == this.count) {
      this.count = 0;
    }
    if (this.source.length == this.c) {
      this.c = 0;
    }
    if (this.source.length == this.b) {
      this.b = 0;
    }
    if (this.source.length == this.a) {
      this.a = 0;
    }
    if (this.source.length == this.d) {
      this.d = 0;
    }
    if (this.source.length == this.e) {
      this.e = 0;
    }
    if (this.source.length == this.f) {
      this.f = 0;
    }
    if (a.columnName == "lookupData") {
      this.instance = new SelectSevrityElement(this.componentResolver, this.viewContainerRef, a, this.source, this.count, this.dropSource);
      this.instance.onLoad();
      this.count++;
    }
    if (a.columnName == "dropDownData") {
      this.instance = new SelectLostTimeInjuryElement(this.componentResolver, this.viewContainerRef, a, this.source, this.c, this.dropSource);
      this.instance.onLoad();
      this.c++;
    }
    if (a.columnName == "bodilyLocationData") {
      this.instance = new SelectbodilyLocationElement(this.componentResolver, this.viewContainerRef, a, this.source, this.b, this.dropSource);
      this.instance.onLoad();
      this.b++;
    }
    if (a.columnName == "injuryTypeData") {
      this.instance = new SelectInjuryTypeElement(this.componentResolver, this.viewContainerRef, a, this.source, this.a, this.dropSource);
      this.instance.onLoad();
      this.a++;
    }
    if (a.columnName == "dropdownList") {
      this.instance = new SelectDropDownList(this.componentResolver, this.viewContainerRef, a, this.source, this.d, this.dropSource);
      this.instance.onLoad();
      this.d++;
    }
    if (a.columnName == "daysList") {
      this.instance = new SelectDaysList(this.componentResolver, this.viewContainerRef, a, this.source, this.e, this.dropSource);
      this.instance.onLoad();
      this.e++;
    }
    if (a.columnName == "injuryTypeList") {
      this.instance = new SelectInjuryTypeList(this.componentResolver, this.viewContainerRef, a, this.source, this.f, this.dropSource);
      this.instance.onLoad();
      this.f++;
    }
    return this.instance;

  }
  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}
export class AppGridDirectorDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private dropSource?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public useUpdatedSource: boolean = false;
  public updatedSource: any;
  public instance: any;
  resolveOnDemandSelector(a, c) {
    if (this.source.length == this.cnt) {
      this.cnt = 0;
    }
    if (a.selector == "rx-select") {
      this.instance = new SelectDropDownElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, this.source[this.cnt].lookupData, this.source[this.cnt].keyId, "keyId", this.dropSource);
      this.instance.onLoad();
      this.cnt++;
    }
    if (a.selector == "rx-date") {
      this.instance = new SelectStartDate(this.componentResolver, this.viewContainerRef, a, c, this.cnt);
      this.instance.onLoad();
    }
    return this.instance;

  }
  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}
export class AppDivGrid extends GridDesigner {
  constructor(source: any[], model: Function, gridConfiguration?: any) {
    super(source, model, gridConfiguration);
    this.allowSorting = true;
    this.designClass.tableClass = "div-table div-table-striped div-table-hover table project-search-table".split(' ');
    // this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.headerClass = ["div-thead"];
    this.designClass.headerRowClass = "div-tr d-flex".split(" ");
    this.designClass.headerCellClass = ["div-th"];
    this.isDivBase = true;
    this.startNodeName = "div"
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.bodyClass = "div-tbody".split(" ");
    this.designClass.rowClass = [function () { return "div-tr".split(" ") }];
    // this.designClass.rowClass = [function () {   return "gradeA even".split(" ") }];
    this.designClass.cellClass = ["div-td"];
    // this.designClass.cellClass = ["sorting"];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  get tableElementConfig(): ElementConfig {
    return {
      class: ["table-responsive", "mb-3"],
      childrens: [{
        table: {
          class: this.designClass.tableClass,
          childrens: [this.headerTemplate, this.bodyTemplate]
        }
      }]
    };
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

}

export class DivGrid extends GridDesigner {

  constructor(source: any[], model: Function, gridConfiguration?: any) {
    super(source, model, gridConfiguration);
    this.isDivBase = true;
    this.startNodeName = "div"
    this.hideHeaderFooter = true;
    this.allowSorting = false;
    this.designClass.tableClass = "hiddenRow div-tr collapse show".split(" ");
    this.designClass.bodyClass = ["div-tr"];
    this.designClass.rowClass = [function () { return "div-tr".split(" ") }];
    this.designClass.cellClass = ["div-td"];
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

}

export class ChildGridWithHeader extends GridDesigner {

  constructor(source: any[], model: Function, gridConfiguration?: any) {
    super(source, model, gridConfiguration);
    this.isDivBase = true;
    this.startNodeName = "div"
    this.designClass.tableClass = "div-table div-table-striped div-table-hover table project-search-table".split(" ");
    this.hideFooter = true;
    this.allowSorting = false;
    this.designClass.headerClass = ["div-tr"];
    this.designClass.headerRowClass = "div-tr".split(" ");
    this.designClass.headerCellClass = ["div-th"];
    this.designClass.bodyClass = ["div-tr"];
    this.designClass.rowClass = [function () { return "div-tr".split(" ") }];
    this.designClass.cellClass = ["div-td"];
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

}

export class ChildGridWithHeaderAndSorting extends GridDesigner {

  constructor(source: any[], model: Function, gridConfiguration?: any) {
    super(source, model, gridConfiguration);
    this.allowSorting = true;
    this.isDivBase = true;
    this.startNodeName = "div"
    this.designClass.tableClass = "div-table div-table-striped div-table-hover table project-search-table".split(" ");
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.hideFooter = true;
    this.designClass.headerClass = ["div-tr"];
    this.designClass.headerRowClass = "div-tr".split(" ");
    this.designClass.headerCellClass = ["div-th"];
    this.designClass.bodyClass = ["div-tr"];
    this.designClass.rowClass = [function () { return "div-tr".split(" ") }];
    this.designClass.cellClass = ["div-td"];
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

}

export class AppGridDateDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private rowCount) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.hideFooter = true;
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = this.rowCount;
    this.pagingSource = [25, 50, 75, 100,3000];
  }
  cnt: number = 0;

  resolveOnDemandSelector(a, c) {
    if(this.source.length==this.cnt) this.cnt=0;
    let instance = new SelectDate(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt);
    instance.onLoad();
    this.cnt++;
    return instance;
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}


export class AppGridSelectDesigner extends GridDesigner {
  constructor(source, model, configuration, private componentResolver, private viewContainerRef, private sortOrder: boolean, private dropSource?: any) {
    super(source, model, configuration);
    this.allowSorting = true;
    this.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom".split(' ');
    this.designClass.cellClass = ["sorting"];
    this.designClass.ascendingClass = ["fr-sorting", "ml-2"];
    this.designClass.descendingClass = ["fr-sorting", "ml-2"];
    this.designClass.rowClass = [function () { return "gradeA even".split(" ") }];
    this.footerDesignClass.rootClass = "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center pagination-bold".split(' ');
    this.footerDesignClass.dropDownTemplateClass.rootClass = "dataTables_length mb-3 mb-lg-0".split(' ');
    this.footerDesignClass.dropDownTemplateClass.controlClass = "form-control form-control-sm mx-2 pagination-bold".split(" ");
    this.footerDesignClass.textTemplateClass.rootClass = "dataTables_info mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.rootClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");
    this.footerDesignClass.paginatorClass.unorderedListItemClass = "pagination pagination-noborder mb-3 mb-lg-0".split(" ");;
    this.footerDesignClass.paginatorClass.listItemClass = ["paginate_button", "page-item", function () { return this.active ? "active" : "" }];
    this.footerDesignClass.paginatorClass.anchorClass = "page-link".split(" ");
    this.footerDesignClass.dropDownTemplateClass.labelClass = "d-flex align-items-center mb-0".split(' ');
    this.maxPerPage = 25;
    this.pagingSource = [25, 50, 75, 100];
  }
  public cnt: number = 0;
  public count: number = 0;
  resolveOnDemandSelector(a, c) {
    if (this.source.length == this.cnt) {
      this.cnt = 0;
    }
    if (this.source.length == this.count) {
      this.count = 0;
    }
    if (a.columnName == "lookupData") {
      let instance = new SelectRiskAddDropDownElement(this.componentResolver, this.viewContainerRef, a, this.source, this.cnt, (this.source[this.cnt].lookupData == null ? [] : this.source[this.cnt].lookupData), this.source[this.cnt].keyId, "keyId", this.dropSource);
      instance.onLoad();
      this.cnt++;
      return instance;
    }
    if (a.columnName == "lookupData1") {
      let instance = new SelectRiskAddDropDownElement(this.componentResolver, this.viewContainerRef, a, this.source, this.count, (this.source[this.count].lookupData1 == null ? [] : this.source[this.count].lookupData1), this.source[this.count].keyId1, "keyId1", this.dropSource);
      instance.onLoad();
      this.count++;
      return instance;
    }
  }

  refresh(elementId) {
    let element = document.getElementById(elementId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }
}

export class SelectRiskAddDropDownElement implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private lookupData, private keyId, private changedKey, private dropSource?: any) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.lookupData : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Value"
    componentRef.instance.formControl = new FormControl();
    //componentRef.instance.formControl.value = ((isNaN(this.keyId) || this.keyId == "") ? 0 : this.keyId);
    if(this.keyId !=0)
    {
      componentRef.instance.formControl.value = this.keyId;
    }
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        if (this.changedKey == "keyId") {
          this.jObject[this.count].keyId = x;
        }
        if (this.changedKey == "keyId1") {
          this.jObject[this.count].keyId1 = x;
        }
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}

export class SelectDropDownElement implements ElementOnDemand {
  componentRef: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private lookupData, private keyId, private changedKey, private dropSource?: any) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    // componentRef.instance.source = (this.dropSource == undefined ? this.lookupData : this.dropSource);
    // componentRef.instance.keyValueProps = ['name', 'id'];
    // componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    // componentRef.instance.selectPlaceholder = "Select Value"
    // componentRef.instance.formControl = new FormControl();
    // componentRef.instance.formControl.value = ((isNaN(this.keyId) || this.keyId == "") ? 0 : this.keyId);
    // componentRef.instance.formControl.valueChanges.subscribe(x => {
    //   if (!isNaN(x)) {
    //     this.jObject[this.config.columnName] = x;
    //     if (this.changedKey == "keyId") {
    //       this.jObject[this.count].keyId = x;
    //     }
    //     if (this.changedKey == "keyId1") {
    //       this.jObject[this.count].keyId1 = x;
    //     }
    //   }
    // })
    // this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    // return this.element;
    componentRef.instance.autoComplete = true;
    componentRef.instance.queryParams = { 'lookupType': 'corporates' };
    componentRef.instance.path = 'api/SearchEvent/GetLookups';
    componentRef.instance.valueInQueryParams = "true";
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.minimumCharacterSearchLength = "2";
    componentRef.instance.enableFreeText = "true";
    componentRef.instance.selectPlaceholder = "Select Corporate";
    componentRef.instance.text = this.jObject[this.count].company;
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = this.jObject[this.count].keyId;
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        if (this.changedKey == "keyId") {
          this.jObject[this.count].keyId = x;
          if(componentRef.instance.dropSource.length > 0){
            let company = componentRef.instance.dropSource.find(z=>z.key == x)
            this.jObject[this.count].company = company != null ? company.value: '';
          }
        }
        if (this.changedKey == "keyId1") {
          this.jObject[this.count].keyId1 = x;
        }
      }
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
//#292627//08-07-2024//Payroll dropdown template for emplyoment grid
export class SelectDropDownPayroll implements ElementOnDemand {
  
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
      
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].payrollList : this.dropSource);
 
    componentRef.instance.keyValueProps = ['payRollName', 'payRollId'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Payroll"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].payRollId) || this.jObject[this.count].payRollId == 0) ? "" : this.jObject[this.count].payRollId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].payRollId = x;
        
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
//#292627//08-07-2024//pateron grade dropdown template for emplyoment grid

export class SelectDropDownExpat implements ElementOnDemand {
  
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
      
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].expatList : this.dropSource);
 
    componentRef.instance.keyValueProps = ['expatName', 'expatId'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Expat"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].expatId) || this.jObject[this.count].expatId == 0) ? "" : this.jObject[this.count].expatId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].expatId = x;
        
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}

export class SelectDropDownPatersongrade implements ElementOnDemand {
  
  componentRef: any;
  componentRef2 : any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].patersonGradeList : this.dropSource);
 
    componentRef.instance.keyValueProps = ['patersonGrade', 'patersonGradeId'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Paterson Grade"
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].patersonGradeId) || this.jObject[this.count].patersonGradeId == 0) ? "" : this.jObject[this.count].patersonGradeId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].patersonGradeId = x;
        
      }
    })

    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);

    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectbodilyLocationElement implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxTagComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].bodilyLocationData : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.placeholder = "Select Bodily Location";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = this.jObject[this.count].bodilyLocationIds;
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      //if (!isNaN(x)) {
      //console.log(x);
      this.jObject[this.config.columnName] = x;
      this.jObject[this.count].bodilyLocationIds = x;
      //}
    })
    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyUpRxTag(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectInjuryTypeElement implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxTagComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].injuryTypeData : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.placeholder = "Select Injury Type";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = this.jObject[this.count].injuryTypeIds;
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      // if (!isNaN(x)) {
      this.jObject[this.config.columnName] = x;
      this.jObject[this.count].injuryTypeIds = x;
      //}
    })
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyUpRxTag(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectLostTimeInjuryElement implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].dropDownData : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Value";
    componentRef.instance.formControl = new FormControl();
    //componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].lostTimeInjuryId) || this.jObject[this.count].lostTimeInjuryId == "") ? 1 : this.jObject[this.count].lostTimeInjuryId);
    componentRef.instance.formControl.value = (this.jObject[this.count].lostTimeInjuryId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].lostTimeInjuryId = x;
      }
    })
    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectDropDownList implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].dropdownList : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Value";
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].isArtisanal) || this.jObject[this.count].isArtisanal == "" || this.jObject[this.count].isArtisanal == 0) ? "" : this.jObject[this.count].isArtisanal);
    //componentRef.instance.formControl.value = (this.jObject[this.count].isArtisanal);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].isArtisanal = x;
      }
    })
    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectDaysList implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;
  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }

  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].daysList : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select";
    componentRef.instance.selectDisabled = this.jObject[this.count].lostTimeInjuryId == 1 ? true : false;
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].daysLost) || this.jObject[this.count].daysLost == "" || this.jObject[this.count].daysLost == 0) ? "" : this.jObject[this.count].daysLost);
    //componentRef.instance.formControl.value = (this.jObject[this.count].daysLost);

    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].daysLost = x;
      }
    })
    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectInjuryTypeList implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;

  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].injuryTypeList : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Value";
    componentRef.instance.selectDisabled = this.jObject[this.count].lostTimeInjuryId == 2 ? true : false;
    componentRef.instance.formControl = new FormControl();
    componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].injuryType) || this.jObject[this.count].injuryType == "" || this.jObject[this.count].injuryType == 0) ? "" : this.jObject[this.count].injuryType);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].injuryType = x;
      }
    })
    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }
  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }
  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
export class SelectSevrityElement implements ElementOnDemand {
  componentRef: any;
  componentRef2: any;

  element: any;
  i: number = 0;
  constructor(private componentResolver, private viewContainerRef, private config, private jObject, private count, private dropSource?: any) {
  }
  onLoad(): HTMLElement {
    let componentFactory = this.componentResolver.resolveComponentFactory(RxSelectComponent);
    let componentRef = this.viewContainerRef.createComponent(componentFactory, this.viewContainerRef.length, this.viewContainerRef.parentInjector);
    componentRef.instance.source = (this.dropSource == undefined ? this.jObject[this.count].lookupData : this.dropSource);
    componentRef.instance.keyValueProps = ['name', 'id'];
    componentRef.instance.mainClass = "chosen-select form-control focus-effect";
    componentRef.instance.selectPlaceholder = "Select Injury Severity";
    componentRef.instance.formControl = new FormControl();
    // componentRef.instance.formControl.value = ((isNaN(this.jObject[this.count].injurySeverityId) || this.jObject[this.count].injurySeverityId == "") ? 1 : this.jObject[this.count].injurySeverityId);
    componentRef.instance.formControl.value = (this.jObject[this.count].injurySeverityId);
    componentRef.instance.formControl.valueChanges.subscribe(x => {
      if (!isNaN(x)) {
        this.jObject[this.config.columnName] = x;
        this.jObject[this.count].injurySeverityId = x;
      }
    })
    this.componentRef2 = componentRef;
    componentRef.instance.onKeyup = this.onKeyUpRxSelect.bind(this);
    this.element = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    return this.element;
  }

  onKeyUpRxSelect(event) {
    var a = new RxSelectOrderClass(this.componentRef2);
    a.onKeyup(event);
  }

  onDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}


class RxSelectOrderClass {
  componentRef2 : any;

  constructor(componentRef: any) {
    this.componentRef2 = componentRef;
  }


  onKeyup(event) {
    if (event.key == "Tab") {
      this.componentRef2.instance.onFocus(event);
      return;
    }
    var value = event.target.value;
    if (
      value !== null
      && value != 'tab'
      && event.key !== "Shift"
      && event.key !== "ArrowUp"
      && event.key !== "ArrowDown"
      && event.key !== "Enter"
      && event.key !== "ArrowLeft"
      && event.key !== "ArrowRight"
    ) {
      if (this.componentRef2.instance.autoComplete) {
        this.componentRef2.instance["currentValue"] = value;
        
        this.onRxSelectFocus(event);
      }
      else {
        var newValue = value.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          // .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
          .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').trim();
        var regex = new RegExp(newValue.toLowerCase());
        this.componentRef2.instance.dropSource = this.componentRef2.instance["mainSource"].filter(t => regex.test(t.value.toLowerCase().normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")));


        this.componentRef2.instance.dropSource.sort((a, b) => {
          const nameA = a.value.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const nameB = b.value.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

          // Compare the starting position of the keyword in fullname
          if (nameA.startsWith(newValue.toLowerCase()) && !nameB.startsWith(newValue.toLowerCase())) {
            return -1; // a comes first
          } else if (!nameA.startsWith(newValue.toLowerCase()) && nameB.startsWith(newValue.toLowerCase())) {
            return 1; // b comes first
          }

          return 0;
        })



        this.componentRef2.instance["setActiveTabClass"]();
      }
      if (this.componentRef2.instance.enableFreeText)
        this.componentRef2.instance["updateFormValue"](value);
    }
  }

  onKeyUpRxTag(event) {
    var value = event.srcElement.value;
    var newValue = value.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "").replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    var regex = new RegExp(newValue.toLowerCase());
    if (
      value !== null &&
      event.keyCode !== 40 &&
      event.keyCode !== 13 &&
      event.keyCode !== 37
    ) {
      if (this.componentRef2.instance.autoComplete) {
        this.componentRef2.instance["currentValue"] = value;
        // this.componentRef2.instance.onFocus(event);
        this.onRxTagFocus(event);
      }
      else {

        this.componentRef2.instance.dropSource = this.componentRef2.instance["mainSource"].filter((t) =>
          regex.test(
            t.text
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
        );

        this.componentRef2.instance.dropSource.sort((a, b) => {
          const nameA = a.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const nameB = b.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

          // Compare the starting position of the keyword in fullname
          if (nameA.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && !nameB.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
            return -1; // a comes first
          } else if (!nameA.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && nameB.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
            return 1; // b comes first
          }

          return 0;
        });

        this.componentRef2.instance.selectedTags.forEach((t) => {
          if (this.componentRef2.instance.dropSource.length > 0) {
            var indexOf = this.componentRef2.instance.dropSource.indexOf(t);
            if (indexOf != -1) this.componentRef2.instance.dropSource.splice(indexOf, 1);
          }
        });
        this.componentRef2.instance["setActiveTabClass"]();
      }
    }
  }

  onRxSelectFocus(event) {
    if ((!this.componentRef2.instance.userSource && this.componentRef2.instance["mainSource"].length === 0 && this.componentRef2.instance.lookup) || (this.componentRef2.instance.autoComplete) || this.componentRef2.instance.isObjectChanged) {
        if ((this.componentRef2.instance["currentValue"] && this.componentRef2.instance["currentValue"].length >= this.componentRef2.instance.minimumCharacterSearchLength) || (!this.componentRef2.instance.autoComplete)) {
            let params = [];
            if (this.componentRef2.instance.params)
                this.componentRef2.instance.params.forEach(t => params.push(t));
            if (this.componentRef2.instance.autoComplete && this.componentRef2.instance["currentValue"]) {
                if (this.componentRef2.instance.valueInQueryParams) {
                    if (!this.componentRef2.instance.queryParams)
                        this.componentRef2.instance.queryParams = {};
                    this.componentRef2.instance.queryParams['q'] = this.componentRef2.instance["currentValue"];
                }
                else
                    params.push(this.componentRef2.instance["currentValue"]);
            }
            this.componentRef2.instance.isSpinner = true;
            Object.keys(this.componentRef2.instance.queryParams).forEach(key => {
                if (key == "q") {
                    var str: string = this.componentRef2.instance.queryParams[key];
                    this.componentRef2.instance.queryParams[key] = encodeURIComponent(str);
                }
            });
            this.componentRef2.instance.get({ path: this.componentRef2.instance.path, params: params, queryParams: this.componentRef2.instance.queryParams }).subscribe((t) => {
                this.componentRef2.instance.userSource = t as any;
                this.componentRef2.instance["mainSource"] = [];
                this.componentRef2.instance.dropSource = [];
                this.componentRef2.instance["setMainSource"](t);
                this.componentRef2.instance.showItemBox();
                this.componentRef2.instance.isSpinner = false;
                this.componentRef2.instance.isObjectChanged = false;
            });
        }
    }
    else if (!this.componentRef2.instance.autoComplete)
        this.componentRef2.instance.showItemBox();
    return false;
  }

  onRxTagFocus(event) {
    if ((!this.componentRef2.instance.userSource && this.componentRef2.instance["mainSource"].length === 0 && this.componentRef2.instance.lookup) || (this.componentRef2.instance.autoComplete) || this.componentRef2.instance.isObjectChanged) {
      if ((this.componentRef2.instance["currentValue"] && this.componentRef2.instance["currentValue"].length >= this.componentRef2.instance.minimumCharacterSearchLength) || (!this.componentRef2.instance.autoComplete)) {
        let params = [];
        if (this.componentRef2.instance.params)
          this.componentRef2.instance.params.forEach(t => params.push(t));
        if (this.componentRef2.instance.autoComplete && this.componentRef2.instance["currentValue"]) {
          if (this.componentRef2.instance.valueInQueryParams) {
            if (!this.componentRef2.instance.queryParams)
              this.componentRef2.instance.queryParams = {};
            this.componentRef2.instance.queryParams['q'] = this.componentRef2.instance["currentValue"];
          }
          else
            params.push(this.componentRef2.instance["currentValue"]);
        }
        this.componentRef2.instance.isSpinner = true;

        Object.keys(this.componentRef2.instance.queryParams).forEach(key => {
          if (key == "q") {
            var str: string = this.componentRef2.instance.queryParams[key];
            this.componentRef2.instance.queryParams[key] = encodeURIComponent(str);
          }
        });
        this.componentRef2.instance.get({ path: this.componentRef2.instance.path, params: params, queryParams: this.componentRef2.instance.queryParams }).subscribe((t) => {
          this.componentRef2.instance["userSource"] = t as any;
          this.componentRef2.instance["mainSource"] = [];
          this.componentRef2.instance.dropSource = [];
          this.componentRef2.instance["setMainSource"](t);
          this.componentRef2.instance.selectedTags.forEach(t => {
            if (this.componentRef2.instance.dropSource.length > 0) {
              var indexOf = this.componentRef2.instance.dropSource.indexOf(t);
              if (indexOf != -1)
                this.componentRef2.instance.dropSource.splice(indexOf, 1);
            }
          });
          this.componentRef2.instance["sortSource"]();
          this.componentRef2.instance["setActiveTabClass"]();
          this.componentRef2.instance.isSpinner = false;
          this.componentRef2.instance.isObjectChanged = false;
        });
      }
    }
    else {
      var value = event.srcElement.value;
      var compareValue = this.componentRef2.instance.removeDoubleBackslashes(String(value).toLowerCase());
      this.componentRef2.instance.dropSource = this.componentRef2.instance.getCloned(this.componentRef2.instance.itemTexts ? this.componentRef2.instance.dropSource : this.componentRef2.instance["mainSource"]).filter(t => String(t.text).toLowerCase().indexOf(compareValue) !== -1);
      this.componentRef2.instance.selectedTags.forEach(t => {
        if (this.componentRef2.instance.dropSource.length > 0) {
          var indexOf = this.componentRef2.instance.dropSource.indexOf(t);
          if (indexOf != -1)
            this.componentRef2.instance.dropSource.splice(indexOf, 1);
        }
      });
      this.componentRef2.instance["sortSource"]();
      this.componentRef2.instance["setActiveTabClass"]();
    }
  }
}
