import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BespokeRequestOrderPieceMappingBase {

//#region beSpokeID Prop
        @prop()
        beSpokeID : any;
//#endregion beSpokeID Prop


//#region bespokeOrderRequestId Prop
        @prop()
        bespokeOrderRequestId : any;
//#endregion bespokeOrderRequestId Prop


//#region serialNo Prop
        @maxLength({value:100})
        serialNo : string;
//#endregion serialNo Prop


//#region created_On Prop
        @prop()
        created_On : Date;
//#endregion created_On Prop


//#region created_By Prop
        @prop()
        created_By : any;
//#endregion created_By Prop


//#region productVariantBatchPieceId Prop
        @prop()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop

}