import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractMessageTemplate } from '../domain/abstract-message-template';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { MessageTemplateDetail } from '@app/models';
import { StatusEnum } from '../../../../enums/status.enum';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { MessageTemplate } from '@app/models';
import { LanguagesEnums } from 'src/app/custom-enum/language-enums';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { TINY_MCE_KEY } from "src/app/domain/system-constant";
import { PlatformLocation } from "@angular/common";

@multilingual("MessageTemplateEditComponent")
@access({ accessLevel: RolePermissionEnums.MessageTemplates, action: "put" })

@Component({
    selector: "app-message-template-edit",
    templateUrl: './message-template-edit.component.html'
})
export class MessageTemplateEditComponent extends AbstractMessageTemplate implements OnInit, OnDestroy {
    messageTemplate: MessageTemplate;
    subscription: any;
    id: number;
    languageId: number = LanguagesEnums.English;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    messageTemplateDelete: any = dbAccessModule[RolePermissionEnums.MessageTemplates]["delete"];;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    apiKey:any = TINY_MCE_KEY

    entityTypeIdForEditorDocuments = EditorDocumentsEnum.MessageTemplate;


    _this;

    constructor(private formBuilder: RxFormBuilder,private applicationBroadcaster:ApplicationBroadcaster, private activatedRoute: ActivatedRoute, private router: Router, modelView: ModalView, private el: ElementRef, private title : Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.activatedRoute.params.subscribe(t => {
            if (t['languageId']) {
                this.languageId = t['languageId'];
            }
        })
        this.moduleID = RolePermissionEnums.MessageTemplates;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Message Templates - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.messageTemplate = new MessageTemplate();
        this.changeLanguage(this.languageId);
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editMessageTemplate(isRedirect: boolean) {
        this.messageTemplateFormGroup.submitted = true;
        if (this.messageTemplateFormGroup.valid) {
            this.spin = true;
            if (this.messageTemplateFormGroup.value.messageTemplateDetails[0].parentMessageTemplateDetailID > 0 && this.messageTemplateFormGroup.value.messageTemplateDetails[0].messageTemplateDetailID == 0) {
                this.messageTemplateFormGroup.patchValue({
                    isNewLanguageMessageTemplate: true
                });
                this.messageTemplateFormGroup.value.description = this.messageTemplateFormGroup.value.description == null ? '': this.messageTemplateFormGroup.value.description.trim();
                this.post({ body: this.messageTemplateFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    this.messageTemplateFormGroup.patchValue({ messageTemplateDetailID: data });
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.changeLanguage(this.languageId) : this.router.navigate(['/message-templates']);
                })
            }
            else {
                this.messageTemplateFormGroup.value.description = this.messageTemplateFormGroup.value.description == null ? '': this.messageTemplateFormGroup.value.description.trim();
                this.put({ body: this.messageTemplateFormGroup.value, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.changeLanguage(this.languageId) : this.router.navigate(['/message-templates']);
                })
            }
        } else {
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.messageTemplateFormGroup.controls[x] != undefined && this.messageTemplateFormGroup.controls[x] != null) {
                    if (this.messageTemplateFormGroup.controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                }else{
                    if (this.messageTemplateDetails[0].controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                }
            });
        }
    }

    deleteMessageTemplate() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/message-templates']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Message_Template");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    changeLanguage(languageId: number) {
        this.lookup([{ params: [this.id], queryParams: { "languageId": languageId }, propName: "messageTemplate" }, { hostUri: CLIENT_URL, path: "assets/message-token/message-token.json", propName: "MessageKey" }, { path: USER_LOOKUPS.emailAccountLookUp, propName: "emailAccountLookup" }, { path: USER_LOOKUPS.languageLookUp, propName: "languageLookup" }]).subscribe((lookup: any) => {
            if (lookup.messageTemplate.messageTemplateDetails.length > 0) {
                this.messageTemplateLookup = lookup;
                this.messageTemplate = lookup.messageTemplate as MessageTemplate;
                this.messageTemplateFormGroup = this.formBuilder.formGroup(MessageTemplate, lookup.messageTemplate) as IFormGroup<MessageTemplate>;
                this.spin = false;
                this.showComponent = true;
            }
            else {
                this.messageTemplateLookup = lookup;
                this.messageTemplate.messageTemplateDetails[0].bccEmailAddresses = "";
                this.messageTemplate.messageTemplateDetails[0].messageTemplateDetailID = 0;
                this.messageTemplate.messageTemplateDetails[0].body = "";
                this.messageTemplate.messageTemplateDetails[0].subject = "";
                this.messageTemplate.messageTemplateDetails[0].languageId = languageId;
                this.messageTemplate.messageTemplateDetails[0].parentMessageTemplateDetailID = this.id;
                this.messageTemplate.messageTemplateDetails[0].statusId = StatusEnum.Active;
                this.messageTemplateFormGroup = this.formBuilder.formGroup(MessageTemplate, this.messageTemplate) as IFormGroup<MessageTemplate>;
            }
            this.languageId = languageId;
            this.showComponent = true;
        })
    }
    get componentName(): string {
        return "MessageTemplateEditComponent";
    }
}
