import {BookingGemstoneBase,BookingBase,ProductPieceGemstoneBase,UserBase,} from '@app/database-models'
//Generated Imports
export class BookingGemstone extends BookingGemstoneBase
{


    gemstoneIds: BookingGemstone[];
    headerSelectGemstoneIDs: BookingGemstone[];
    locationId: number;
    ownerId: number;
    approveOrRejet: number;

//#region Generated Reference Properties
//#region booking Prop
booking : BookingBase;
//#endregion booking Prop
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop
//#region user Prop
user : UserBase;
    query: string;
    headerBtnChk: boolean;
//#endregion user Prop
quantity:any;
//#endregion Generated Reference Properties











}