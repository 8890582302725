import {LookupBodilyLocationBase,PersonBodilyLocationBase,} from '@app/database-models'
//Generated Imports
export class LookupBodilyLocation extends LookupBodilyLocationBase 
{




//#region Generated Reference Properties
//#region personBodilyLocations Prop
personBodilyLocations : PersonBodilyLocationBase[];
//#endregion personBodilyLocations Prop

//#endregion Generated Reference Properties





































}