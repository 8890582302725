import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEnhancementTypeGemfieldBase {

//#region enhancementTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'enhancementTypeId', keyColumn: true})
        enhancementTypeId : number;
//#endregion enhancementTypeId Prop


//#region enhancementTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'enhancementTypeName', keyColumn: false})
        enhancementTypeName : string;
//#endregion enhancementTypeName Prop

}