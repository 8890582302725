import { TemplateConfig } from '@rxweb/grid';

export const DROPDOWN_OBJECTIVE_STATUS: TemplateConfig = {
    select:
    {
        class: ["select-option", "form-control"],
        event: {
            input: "onStatusChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.goalStatusId == 0) {
                                return this.parentObject.goalStatusId && this.value == 0;
                            }
                            if (this.parentObject.goalStatusId == 1) {
                                return this.parentObject.goalStatusId && this.value == 1;
                            }
                            if (this.parentObject.goalStatusId == 2) {
                                return this.parentObject.goalStatusId && this.value == 2;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: StatusDropdown(),
    }
}

export function StatusDropdown() {
    return [{ value: 0, text: "Pending" }, { value: 1, text: "Approve" }, { value: 2, text: "Reject" }]
}



export const DROPDOWN_OBJECTIVE_RATING: TemplateConfig = {
    select:
    {
        class: ["checkbox-custom-style", "form-control"],
        event: {
            input: "onRatingChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.lineManagerRating == 1) {
                                return this.parentObject.lineManagerRating == 1 && this.value == 1;
                            }
                            if (this.parentObject.lineManagerRating == 2) {
                                return this.parentObject.lineManagerRating == 2 && this.value == 2;
                            }
                            if (this.parentObject.lineManagerRating == 3) {
                                return this.parentObject.lineManagerRating == 3 && this.value == 3;
                            }
                            if (this.parentObject.lineManagerRating == 4) {
                                return this.parentObject.lineManagerRating == 4 && this.value == 4;
                            }
                            if (this.parentObject.lineManagerRating == 5) {
                                return this.parentObject.lineManagerRating == 5 && this.value == 5;
                            }
                            if (this.parentObject.lineManagerRating == 0) {
                                return this.parentObject.lineManagerRating == 0 && this.value == 0;
                            }
                            if (this.parentObject.lineManagerRating == -1) {
                                return this.parentObject.lineManagerRating == -1 && this.value == -1;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: RatingDropdown(),
    }
}

export function RatingDropdown() {
    return [ { value: -1, text: "---" }, { value: 5, text: "A" }, { value: 4, text: "B" }, { value: 3, text: "C" }, { value: 2, text: "D" }, { value: 1, text: "E" }, { value: 0, text: "N/A" }]
}

export const DROPDOWN_PROJECT_RATING: TemplateConfig = {
    select:
    {
        class: ["checkbox-custom-style", "form-control"],
        event: {
            input: "onProRatChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.keyId == 1) {
                                return this.parentObject.keyId == 1 && this.value == 1;
                            }
                            if (this.parentObject.keyId == 2) {
                                return this.parentObject.keyId == 2 && this.value == 2;
                            }
                            if (this.parentObject.keyId == 3) {
                                return this.parentObject.keyId == 3 && this.value == 3;
                            }
                            if (this.parentObject.keyId == 4) {
                                return this.parentObject.keyId == 4 && this.value == 4;
                            }
                            if (this.parentObject.keyId == 5) {
                                return this.parentObject.keyId == 5 && this.value == 5;
                            }
                            if (this.parentObject.keyId == 0) {
                                return this.parentObject.keyId == 0 && this.value == 0;
                            }
                            if (this.parentObject.keyId == -1) {
                                return this.parentObject.keyId == -1 && this.value == -1;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: RatingDropdown(),
    }
}


export const DROPDOWN_EVENT_RATING: TemplateConfig = {
    select:
    {
        class: ["checkbox-custom-style", "form-control"],
        event: {
            input: "onProRatChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.keyId == 1) {
                                return this.parentObject.keyId == 1 && this.value == 1;
                            }
                            if (this.parentObject.keyId == 2) {
                                return this.parentObject.keyId == 2 && this.value == 2;
                            }
                            if (this.parentObject.keyId == 3) {
                                return this.parentObject.keyId == 3 && this.value == 3;
                            }
                            if (this.parentObject.keyId == 4) {
                                return this.parentObject.keyId == 4 && this.value == 4;
                            }
                            if (this.parentObject.keyId == 5) {
                                return this.parentObject.keyId == 5 && this.value == 5;
                            }
                            if (this.parentObject.keyId == 0) {
                                return this.parentObject.keyId == 0 && this.value == 0;
                            }
                            if (this.parentObject.keyId == -1) {
                                return this.parentObject.keyId == -1 && this.value == -1;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: RatingDropdown(),
    }
}
