import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { RelatedProductDesign } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Router } from '@angular/router';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileComponent } from 'src/app/components/shared/global/file/file.component';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { CoreComponent } from '@rxweb/angular-router';
@http({
    path: "api/RelatedProductDesign",
})
export class AbstractRelatedProductDesign extends CoreComponent {
    relatedProductDesignFormGroup: IFormGroup<RelatedProductDesign>
    isShowGrid : boolean;
    isNoRecordFound : boolean;
    designStatusLookup:any;
    startDate:Date;
    endDate:Date;
    collectionLookup:any;
    categoryLookup:any;
    desginerLookup:any;
    spin: boolean = false;
    modalView:ModalView;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    router: Router;
    id:number;
    sanitizer:DomSanitizer;
    fileInputViewModel1: FileInputViewModel;
    fileInputViewModel2: FileInputViewModel;
    fileInputViewModel3: FileInputViewModel;
    fileComponent:FileComponent;
    fileURL1:any;
    fileURL2:any;
    fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
    dateError:boolean=false;
    bookingError:boolean;

    // constructor(private domsanitizer:DomSanitizer) {
    //     super();
    //     this.toastr = new BaseToastr();
    //     this.dialog = new BaseDialog();
    //     this.sanitizer=domsanitizer;

    //   }
      constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();


      }
}
