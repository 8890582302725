export const SUBSCRIPTION = [
	{
        id : 321,
		name : 'News Letter Subscription',
		value:'Fabergé General Newsletter',
	},
	{
        id : 322,
		name : 'Faberge Press Subscription',
		value:'Fabergé Press',
	},
	{
        id : 323,
		name : 'Gemfields Press Subscription',
		value:'Gemfields: Press',
	},
	{
        id : 324,
		name : 'Gemfields General Subscription',
		value:'Gemfields: General',
	},
	{
        id : 325,
		name : 'Gemfields Auction Partners Subscription',
		value:'Gemfields: Auction Partners',
	},
	{
        id : 326,
		name : 'GGL Investors Subscription',
		value:'GGL Investor News Subscription',
	},
]