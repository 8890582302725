import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLegalOwnerBatchLookUpBase {

//#region pieceOwnerId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'pieceOwnerId', keyColumn: true})
        pieceOwnerId : number;
//#endregion pieceOwnerId Prop


//#region pieceOwnerName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'pieceOwnerName', keyColumn: false})
        pieceOwnerName : string;
//#endregion pieceOwnerName Prop

}