import {DutyStatusBase,} from '@app/database-models'
//Generated Imports
export class DutyStatus extends DutyStatusBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}