import { MindMapDataTypeEnum, MindMapNodeType } from 'src/app/enums/mind-map-data-type-enum';
import { RxHttp } from '@rxweb/http';
import { MindMapData } from './mind-map-data';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';

export class RootPressMediaMindMap {
    mindMapData: any = null;

    rootEntityName: string = '';

    mindMap;
    mindMapDataObj: MindMapData;

    httpClient: any;

    constructor(private mindMapService: MindMapService) {
        this.httpClient = new RxHttp();
        this.mindMapDataObj = new MindMapData();
        Window['RootPressMediaMindMap'] = this;
    }

    async setupPressMediaMindMap(mindMapData, rootEntityName) {
        this.mindMapData = mindMapData;
        this.rootEntityName = rootEntityName;
        if (this.mindMapData != null) {
            await this.createMindMap();
        }
    }


    async createMindMap() {
        var [eventData
            , riskData
            , projectData
            , peopleData
            , journalistData
            , corporateData
            , publicationData
            , productData] = await Promise.all([
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.EventData, "event-templates.json", "Event", this.mindMapService.graphId, MindMapDataTypeEnum.EVENT, RolePermissionEnums.Event),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.RiskData, "risk-templates.json", "Risk", this.mindMapService.graphId, MindMapDataTypeEnum.RISK, RolePermissionEnums.GemfieldsRiskMenu),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.ProjectData, "project-templates.json", "Project", this.mindMapService.graphId, MindMapDataTypeEnum.PROJECT, RolePermissionEnums.Project),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PeopleData, "people-journalist-templates.json", "PeopleJournalist", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIAPEOPLE, RolePermissionEnums.User),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.JournalistData, "people-journalist-templates.json", "PeopleJournalist", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIAJOURNALIST, RolePermissionEnums.User),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.CorporateData, "corporate-publication-templates.json", "CorporatePublication", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIACORPORATE, RolePermissionEnums.Supplier),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PublicationData, "corporate-publication-templates.json", "CorporatePublication", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIAPUBLICATION, RolePermissionEnums.Supplier),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.ProductData, "press-media-product-templates.json", "PressMediaProduct", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIAPRODUCT, RolePermissionEnums.Products)
            ]);

        var childrenArray = [];
        if (this.mindMapService.graphId == 1) {
            if (this.mindMapData.ProjectData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PROJECT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: projectData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: projectData,
                            direction: 'right',
                            selectedType: 'PROJECTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.EventData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'EVENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: eventData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: eventData,
                            direction: 'right',
                            selectedType: 'EVENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.RiskData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}` + '\')\">Risks (' + this.mindMapData.RiskData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'RISK',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: riskData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: riskData,
                            direction: 'right',
                            selectedType: 'RISKLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PeopleData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">People (' + this.mindMapData.PeopleData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PRESSMEDIAPEOPLE',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: peopleData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: peopleData,
                            direction: 'left',
                            selectedType: 'PRESSMEDIAPEOPLELIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.JournalistData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">Journalists (' + this.mindMapData.JournalistData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PRESSMEDIAJOURNALIST',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: journalistData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: journalistData,
                            direction: 'left',
                            selectedType: 'PRESSMEDIAJOURNALISTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.CorporateData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Corporates (' + this.mindMapData.CorporateData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PRESSMEDIACORPORATE',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: corporateData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: corporateData,
                            direction: 'left',
                            selectedType: 'PRESSMEDIACORPORATELIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PublicationData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Publications (' + this.mindMapData.PublicationData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PRESSMEDIAPUBLICATION',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: publicationData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: publicationData,
                            direction: 'left',
                            selectedType: 'PRESSMEDIAPUBLICATIONLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.ProductData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.HEADER}` + '\')\">Products (' + this.mindMapData.ProductData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PRESSMEDIAPRODUCT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: productData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: productData,
                            direction: 'right',
                            selectedType: 'PRESSMEDIAPRODUCTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            this.mindMapService.mindMapObject = {
                format: 'nodeTree',
                data: {
                    id: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    isroot: true,
                    topic: '<h2 class="m-0" style="font-size:20px!important"><a target="_blank" href="/press-media-detail/' + this.mindMapData.RootEntityId + '?activeTab=press-media-info"><b>' + this.rootEntityName + '</b></a></h2>',
                    selectedType: "",
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: childrenArray
                }
            };
        }
        else {
            this.mindMapService.tempSubgraphData.root = {
                parentNodeId: this.mindMapService.parentNodeToExpand,
                nodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                topic: '<h2 class="m-0" style=""><a target="_blank" href="/press-media-detail/' + this.mindMapData.RootEntityId + '?activeTab=press-media-info"><b>' + this.rootEntityName + '</b></a></h2>',
                data: { selectedType: "ROOT" }
            };

            if (this.mindMapData.ProjectData.length > 0) {
                this.mindMapService.tempSubgraphData.project = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    data: { selectedType: "PROJECT" }
                };

                this.mindMapService.tempSubgraphData.projectList = (projectData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                    topic: projectData,
                    data: { selectedType: "PROJECTLIST" }
                };
            }
            if (this.mindMapData.EventData.length > 0) {
                this.mindMapService.tempSubgraphData.event = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    data: { selectedType: "EVENT" }
                };

                this.mindMapService.tempSubgraphData.eventList = (eventData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                    topic: eventData,
                    data: { selectedType: "EVENTLIST" }
                };
            }
            if (this.mindMapData.RiskData.length > 0) {
                this.mindMapService.tempSubgraphData.risk = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}` + '\')\">Risks (' + this.mindMapData.RiskData.length + ')</span>',
                    data: { selectedType: "RISK" }
                };

                this.mindMapService.tempSubgraphData.riskList = (riskData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.LIST}`,
                    topic: riskData,
                    data: { selectedType: "RISKLIST" }
                };
            }
            if (this.mindMapData.PeopleData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMediaPeople = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">People (' + this.mindMapData.PeopleData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIAPEOPLE" }
                };

                this.mindMapService.tempSubgraphData.pressMediaPeopleList = (peopleData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPEOPLE}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                    topic: peopleData,
                    data: { selectedType: "PRESSMEDIAPEOPLELIST" }
                };
            }
            if (this.mindMapData.CorporateData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMediaCorporate = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Corporates (' + this.mindMapData.CorporateData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIACORPORATE" }
                };

                this.mindMapService.tempSubgraphData.pressMediaCorporateList = (corporateData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIACORPORATE}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                    topic: corporateData,
                    data: { selectedType: "PRESSMEDIACORPORATELIST" }
                };
            }
            if (this.mindMapData.JournalistData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMediaJournalist = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">Journalists (' + this.mindMapData.JournalistData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIAJOURNALIST" }
                };

                this.mindMapService.tempSubgraphData.pressMediaJournalistList = (journalistData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAJOURNALIST}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                    topic: journalistData,
                    data: { selectedType: "PRESSMEDIAJOURNALISTLIST" }
                };
            }
            if (this.mindMapData.PublicationData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMediaPublication = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Publications (' + this.mindMapData.PublicationData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIAPUBLICATION" }
                };

                this.mindMapService.tempSubgraphData.pressMediaPublicationList = (publicationData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPUBLICATION}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                    topic: publicationData,
                    data: { selectedType: "PRESSMEDIAPUBLICATIONLIST" }
                };
            }
            if (this.mindMapData.ProductData.length > 0) {
                this.mindMapService.tempSubgraphData.product = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.HEADER}` + '\')\">Products (' + this.mindMapData.ProductData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIAPRODUCT" }
                };

                this.mindMapService.tempSubgraphData.productList = (productData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIAPRODUCT}_${RolePermissionEnums.Products}_${MindMapNodeType.LIST}`,
                    topic: productData,
                    data: { selectedType: "PRESSMEDIAPRODUCTLIST" }
                };
            }
        }

        return;

    }
}