<div class="modal fade show" id="app-project-corporate-mapping-add" tabindex="-1" role="dialog"
  [rxLocalisationInit]="componentName" style="padding-right: 17px; display: block;">
  <div class="modal-dialog modal-lg" [rxSpinner]="spin" [rxLocalisationInit]="componentName" *rxMultilingual="">
    <div class="modal-content">
      <div class="modal-header text-left">
        <h4 class="modal-title mb-0" [rxLocalisationInit]="componentName" *ngIf="componentType == 'project'"
          rxText="ProjectCorporateMappingAddComponent_Add_Person_t"></h4>
        <h4 class="modal-title mb-0" [rxLocalisationInit]="componentName" *ngIf="componentType == 'project-restrict-access'"
          rxText="ProjectCorporateMappingAddComponent_RestrictPer_t"></h4>
        <h4 class="modal-title mb-0" [rxLocalisationInit]="componentName"
          *ngIf="componentType == 'events' && involved == true"
          rxText="ProjectCorporateMappingAddComponent_Add_People_t"></h4>
        <h4 class="modal-title mb-0" [rxLocalisationInit]="componentName"
          *ngIf="componentType == 'events' && involved == false"
          rxText="ProjectCorporateMappingAddComponent_Invitees_t"></h4>
        <h4 class="modal-title mb-0" [rxLocalisationInit]="componentName" *ngIf="componentType == 'bookings'"
          rxText="ProjectCorporateMappingAddComponent_Title_Corp_t"></h4>
        <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="projectsCorporateMappingSearchFormGroup" *ngIf="showComponent"
        (keyup.enter)="search()" [rxLocalisationInit]="componentName">
        <div class="form-group row align-items-center" *ngIf="involved == true && componentType != 'project-restrict-access' && componentType != 'events'" [rxLocalisationInit]="componentName">
          <label class="col-md-4 col-form-label" rxText="ProjectCorpMappingAddEditComponent_SearchFor_t"></label>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6 d-flex align-items-center">
                <div class="i-checks mr-2">
                  <label class="mb-0">
                    <div class="iradio_square-green" [class.checked]="
                      projectsCorporateMappingSearchFormGroup.value
                        .peopleOrCorporate == 0
                    " style="position: relative">
                      <input type="radio" value="0" formControlName="peopleOrCorporate" style="visibility: hidden"
                        (change)="checkUserType()" />
                    </div>
                    <i></i>People
                  </label>
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="i-checks mr-2">
                  <label class="mb-0">
                    <div class="iradio_square-green" [class.checked]="
                      projectsCorporateMappingSearchFormGroup.value
                        .peopleOrCorporate == 1
                    " style="position: relative">
                      <input type="radio" value="1" formControlName="peopleOrCorporate" style="visibility: hidden"
                        (change)="checkUserType()" />
                    </div>
                    <i></i>Corporates/Groups
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_Name_t"></label>
              <div class="col-md-12">
                <input type="text" class="form-control" tabindex="1" formControlName="name"
                  rxPlaceholder="ProjectCorpMappingAddComponent_Name_p" rxFocus autocomplete="off" />
              </div>
            </div>

            <div class="form-group row" *ngIf="userType == 0" [rxLocalisationInit]="componentName">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_Email_t"></label>
              <div class="col-md-12">
                <input type="text" class="form-control" tabindex="3" formControlName="email"
                  rxPlaceholder="ProjectCorpMappingAddComponent_Email_p" autocomplete="off" />
                <small class="form-text text-danger" [class.d-block]="
                  projectsCorporateMappingSearchFormGroup.controls.email.errors
                ">{{
                  projectsCorporateMappingSearchFormGroup.controls.email
                  .errorMessage
                  }}</small>
              </div>
            </div>

            <div class="form-group row" *ngIf="userType == 1" [rxLocalisationInit]="componentName">
              <label class="col-md-12 col-form-label"
                rxText="ProjectCorpMappingAddEditComponent_IndustryType_t"></label>
              <div class="col-md-12" [rxTagExtended]="_this">
                <rx-tag [(source)]="projectCorporateMappingLookup.industryTypeLookUp" #rxTag [controlTabIndex]="3"
                  [isSort]="false" formControlName="industryTypeIds" mainClass="form-control" placeholder="Select Industry Type"
                  [keyValueProps]="['industryTypeName','industryTypeId']">
                </rx-tag>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_RegFrom_t"></label>
              <div class="col-md-12">
                <rx-date showAddon="true" formControlName="ragistrationFromDate" [controlTabIndex]="2"
                  placeholder="Select Date" pickerClass="form-control"></rx-date>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_RegTo_t"></label>
              <div class="col-md-12">
                <rx-date showAddon="true" formControlName="ragistrationToDate" placeholder="Select Date"
                  [controlTabIndex]="4" pickerClass="form-control"></rx-date>
                <small class="form-text text-danger mt-2" [rxLocalisationInit]="componentName"
                  *ngIf="dateError == true">Registration To Date should be greater than Registration From
                  Date</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row" [rxLocalisationInit]="componentName" *ngIf="userType == 0">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_SAdvisor_t"></label>
              <div class="col-md-12" [rxSelectExtended]="_this">
                <rx-select [(source)]="projectCorporateMappingLookup.salesAdvisorLookUp" #rxSelect
                  mainClass="form-control" [controlTabIndex]="5" formControlName="salesAdvisor"
                  [keyValueProps]="['fullName', 'userId']" [selectPlaceholder]="'Select Sales Advisor'"></rx-select>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group row" [rxLocalisationInit]="componentName" *ngIf="userType == 0">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_CorOrGrp_t"></label>
              <div class="col-md-12" [rxSelectExtended]="_this">
                <rx-select [(source)]="projectCorporateMappingLookup.companyLookUp" #rxSelect mainClass="form-control"
                  [controlTabIndex]="6" formControlName="corporate" [keyValueProps]="['company', 'company']"
                  [selectPlaceholder]="'Select Corporate/Groups'"></rx-select>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_Country_t"></label>
              <div class="col-md-12" [rxSelectExtended]="_this">
                <rx-select [(source)]="projectCorporateMappingLookup.countryLookUp" #rxSelect mainClass="form-control"
                  [controlTabIndex]="7" formControlName="country" [keyValueProps]="['countryName', 'countryId']"
                  [selectPlaceholder]="'Select Country'">
                </rx-select>
              </div>
            </div>
          </div>

          <div class="col-md-6" *ngIf="userType == 0" [rxLocalisationInit]="componentName">
            <div class="form-group row" *ngIf="resetControl" [rxLocalisationInit]="componentName">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddEditComponent_BadgeId_t"></label>
              <div class="col-md-12" [rxSelectExtended]="_this">
                <rx-select #rxSelect autoComplete="true" mainClass="form-control" formControlName="userId"
                  [valueInQueryParams]="true" [controlTabIndex]="8" path="api/MasterLookups/BadgeIdLookUp"
                  [keyValueProps]="['#badgeId#', 'userId']" [minimumCharacterSearchLength]="2"
                  (keyup)="checkBadgeIdText($event)" (keyup.enter)="checkBadgeIdText($event)"
                  (changed)="onBadgeIdClick($event)" [text]="searchBadgeId" [enableFreeText]="true"
                  [selectPlaceholder]="'Select Badge ID'">
                </rx-select>
              </div>
            </div>
          </div>

          <div class="col-md-6" *ngIf="hasBoughtProductFlag" [rxLocalisationInit]="componentName">
            <div class="form-group row" style="margin-top: 29px">
              <div class="col-2 mt-2">
                <div class="col-auto d-flex align-items-center">
                  <div class="i-checks">
                    <label class="mb-0">
                      <app-checkbox [checkFormControl]="
                        projectsCorporateMappingSearchFormGroup.controls
                          .hasBoughtProduct
                      " (change)="changeLayout($event)">
                      </app-checkbox>
                    </label>
                  </div>
                </div>
              </div>
              <label class="col-10 mt-1 col-form-label"
                rxText="ProjectCorpMappingAddEditComponent_Product_Buy_t"></label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6" [rxLocalisationInit]="componentName" *ngIf="showCategoryCollection">
            <div class="form-group row">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddComponent_Category_t"></label>
              <div class="col-md-12" [rxTagExtended]="_this">
                <!-- <rx-select [(source)]="categoryLookup" mainClass="form-control"
                [controlTabIndex]="3" formControlName="categoryId"
                [keyValueProps]="['categoryName','categoryId']" [selectPlaceholder]="'Select Category'">
              </rx-select> -->
                <rx-tag [(source)]="categoryLookup" #rxTag mainClass="form-control" formControlName="categoryId"
                  [controlTabIndex]="18" [keyValueProps]="['categoryName', 'categoryId']" placeholder="Select Category"
                  [isSort]="false"></rx-tag>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="showCategoryCollection" [rxLocalisationInit]="componentName">
            <div class="form-group row">
              <label class="col-md-12 col-form-label" rxText="ProjectCorpMappingAddComponent_Collection_t"></label>
              <div class="col-md-12" [rxTagExtended]="_this">
                <!-- <rx-select [(source)]="collectionLookup" mainClass="form-control"
                [controlTabIndex]="3" formControlName="collectionId"
                [keyValueProps]="['name','specificationAttributeOptionId']" [selectPlaceholder]="'Select Collection'">
              </rx-select> -->
                <rx-tag [source]="collectionLookup" #rxTag [controlTabIndex]="19" [isSort]="false"
                  formControlName="collectionId" mainClass="form-control" placeholder="Select Collection"
                  [keyValueProps]="['name', 'specificationAttributeOptionId']"></rx-tag>
              </div>
            </div>
          </div>
        </div>

        <div class="hr-line-dashed border-top w-100"></div>
        <div class="row mx-0">
          <div class="form-group ml-auto mb-0">
            <button (click)="search()" class="btn btn-success" rxText="Btn_Search_t"></button>
          </div>
        </div>
        <div class="table-responsive mt-3" (keyup.enter)="savePersonOrCorporate(false)" *ngIf="showGrid"
          [rxLocalisationInit]="componentName">
          <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
            <div class="table-responsive" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
              <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                <rx-grid [design]="ProjectUserListGrid"></rx-grid>
              </div>
            </div>
            <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound" [rxLocalisationInit]="componentName">
              <h4>{{ ErrorMsg }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
        <ng-container [rxLocalisationInit]="componentName" *ngIf="(componentType == 'project' || componentType == 'project-restrict-access') && showSaveButton">
          <button (click)="save(false)" class="btn btn-success"
            [disabled]="(this.projectCorporateMappingArray.length == 0 && this.excludedProjectCorporateMappingArray.length == 0 && !this.headerBtnChk)
            || (this.totalRecords - this.excludedProjectCorporateMappingArray.length == 0)"
            rxText="Btn_Save_t"></button>
        </ng-container>

        <ng-container [rxLocalisationInit]="componentName" *ngIf="(componentType == 'project' || componentType == 'project-restrict-access') && showSaveButton">
          <button (click)="save(true)" class="btn btn-success"
            [disabled]="this.projectCorporateMappingArray.length == 0 && this.excludedProjectCorporateMappingArray.length == 0 && !this.headerBtnChk
            || (this.totalRecords - this.excludedProjectCorporateMappingArray.length == 0)"
            rxText="Btn_Save_Continue_Edit_t"></button>
        </ng-container>

        <ng-container *ngIf="componentType == 'events' && showSaveButton" [rxLocalisationInit]="componentName">
          <button (click)="save(false)" class="btn btn-success"
            [disabled]="(this.eventCustomerMappingArray.PeopleEventInput.length == 0 &&  this.excludedeventCorporateMappingArray.PeopleEventInput.length == 0 && !this.headerBtnChk)
            || (this.totalRecords - this.excludedeventCorporateMappingArray.PeopleEventInput.length == 0)"
            *ngIf="componentType == 'events' && showSaveButton" rxText="Btn_Save_t"></button>
        </ng-container>

        <ng-container *ngIf="componentType == 'events' && showSaveButton" [rxLocalisationInit]="componentName">
          <button (click)="save(true)" class="btn btn-success"
            [disabled]="(this.eventCustomerMappingArray.PeopleEventInput.length == 0 &&  this.excludedeventCorporateMappingArray.PeopleEventInput.length == 0 && !this.headerBtnChk)
            || (this.totalRecords - this.excludedeventCorporateMappingArray.PeopleEventInput.length == 0)"
            rxText="Btn_Save_Continue_Edit_t"></button>
        </ng-container>

        <ng-container *ngIf="componentType == 'bookings' && showSaveButton" [rxLocalisationInit]="componentName">
          <button (click)="save(false)" class="btn btn-success"
            [disabled]="(bookingClientsMappingArray.length == 0 && this.excludebookingClientsMappingArray.length == 0 && !this.headerBtnChk)
            || (this.totalRecords - this.excludebookingClientsMappingArray.length == 0)"
            rxText="Btn_Save_t"></button>
        </ng-container>

        <ng-container *ngIf="componentType == 'bookings' && showSaveButton" [rxLocalisationInit]="componentName">
          <button (click)="save(true)" class="btn btn-success"
            [disabled]="(bookingClientsMappingArray.length == 0 && this.excludebookingClientsMappingArray.length == 0 && !this.headerBtnChk)
            || (this.totalRecords - this.excludebookingClientsMappingArray.length == 0)"
            rxText="Btn_Save_Continue_Edit_t"></button>
        </ng-container>

        <button (click)="hide()" class="btn btn-outline-primary ml-0" rxText="Btn_Close_t"></button>
      </div>
    </div>
  </div>
</div>
