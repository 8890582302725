import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDiscountBase {

//#region discountId Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: false, headerTitle: 'discountId', keyColumn: true})
        discountId : number;
//#endregion discountId Prop


//#region name Prop
        @gridColumn({isAscending:true,visible: true,style: { width: "20%", "text-align": "initial" },class: ["text-left"], columnIndex:1, allowSorting: false, headerKey: 'DiscountListComponent_Name_gh', keyColumn: false})
        name : string;
//#endregion name Prop


//#region couponCode Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: false, headerKey: 'couponCode', keyColumn: false})
        couponCode : string;
//#endregion couponCode Prop


//#region discountAmount Prop
        @gridColumn({visible: true,style: { width: "15%", "text-align": "initial" }, class: ["text-left"],columnIndex:3, allowSorting: false, headerKey: 'DiscountListComponent_DiscountAmount_gh', keyColumn: false})
        discountAmount : number;
//#endregion discountAmount Prop


//#region discountPercentage Prop
        @gridColumn({visible: true,style: { width: "15%", "text-align": "initial" }, class: ["text-left"],columnIndex:4, allowSorting: false, headerKey: 'DiscountListComponent_DiscountPercentage_gh', keyColumn: false})
        discountPercentage : number;
//#endregion discountPercentage Prop


//#region startDate Prop
        @gridColumn({visible: true, style: { width: "20%", "text-align": "initial" },class: ["text-left"],columnIndex:5, allowSorting: false, headerKey: 'DiscountListComponent_StartDate_gh', keyColumn: false})
        startDate : any;
//#endregion startDate Prop


//#region endDate Prop
        @gridColumn({visible: true, style: { width: "20%", "text-align": "initial" },class: ["text-left"],columnIndex:6, allowSorting: false, headerKey: 'DiscountListComponent_EndDate_gh', keyColumn: false})
        endDate : any;
//#endregion endDate Prop


//#region usePercentage Prop
        @gridColumn({visible: true,style: { width: "10%", "text-align": "initial" }, class: ["text-left"],columnIndex:7,allowSorting: false, headerKey: 'DiscountListComponent_UsePercentage_gh', keyColumn: false ,configuredTemplate: { templateName: "recordActive" }})
        usePercentage : any;
//#endregion usePercentage Prop

}