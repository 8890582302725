<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" *rxMultilingual=""
  [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_Events_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Events_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content">
  <div class="ibox mb-0 d-flex flex-column" [rxSpinner]="spin">
    <div class="ibox-title bg-white pr-3" [rxLocalisationInit]="componentName">
      <h5 rxText="Label_Events_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
      <!-- <a [routerLink]="['/events/add']" class="btn btn-success btn-sm ml-auto">Add Event</a> -->
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showComponent"
      [formGroup]="eventSearchFormGroup" (keyup.enter)="searchEvents()" [rxLocalisationInit]="componentName">
      <div class="dataTables_filter d-flex align-items-center">
        <!-- <a href="people-add-new-basic-info.html" class="btn btn-success btn-sm">Add New</a> -->
        <div class="" role="group" aria-label="Basic example">
          <a [routerLink]="['/events/add']" class="btn btn-success mb-3 pull-left" *rxAuthorize="[eventAdd]"
            rxText="EventSearchComponent_Add_Event_t">
          </a>
        </div>

        <div class="ml-auto text-right mb-3" role="group" aria-label="Basic example">
          <ng-container *ngIf="!isNoRecordFound" [rxLocalisationInit]="componentName">
            <button type="button" class="btn btn-primary m-2" (click)="exportToExcel()"
              rxText="Btn_Export_to_Excel_t"></button>
          </ng-container>
        </div>
      </div>

      <div class="row people_form_details">
        <!--  -->

        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row" *ngIf="resetNameControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_Name_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <input type="text" tabindex="1" class="form-control" rxFocus formControlName="eventName"
                                 rxPlaceholder="EventSearchComponent_Name_p" /> -->
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1"
                formControlName="eventName" [valueInQueryParams]="true" [queryParams]="{ eventId: 0 }"
                [keyValueProps]="['#EventName#', 'EventName']" [selectPlaceholder]="'Select Event'"
                path="api/SearchEvent/GetEvents" (keyup)="checkNameText($event)" (keyup.enter)="checkNameText($event)"
                (changed)="onNameClick($event)" [text]="searchEventName" [minimumCharacterSearchLength]="2"
                [enableFreeText]="true">
              </rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_Owner_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <rx-select autoComplete="true" formControlName="eventOwner" queryParams="[eventSearchFormGroup.value.eventOwner]" path="api/SearchEvent/GetLookups" [keyValueProps]="['fullName','userId']" [minimumCharacterSearchLength]="2"
                                 (changed)="added($event)">
                            </rx-select> -->
              <!-- <rx-select [(source)]="eventLookup.eventOwnerLookUp" mainClass="form-control"
                                formControlName="eventOwner" [controlTabIndex]="3"
                                [keyValueProps]="['fullName','userId']" [selectPlaceholder]="'Select Event Owner'">
                            </rx-select> -->
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1"
                formControlName="eventOwner" [valueInQueryParams]="true" [keyValueProps]="['#UserName#', 'UserId']"
                [selectPlaceholder]="'Select Event Owner'" path="api/SearchEvent/GetEventOwners"
                (keyup)="checkOwnerText($event)" (keyup.enter)="checkOwnerText($event)" (changed)="onOwnerClick($event)"
                [minimumCharacterSearchLength]="2">
              </rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_Country_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="eventLookup.countryLookUp" [controlTabIndex]="15" #rxSelect
                mainClass="form-control" formControlName="eventCountry" [keyValueProps]="['countryName','countryId']"
                [selectPlaceholder]="'Select Country'">
              </rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="EventSearchComponent_RiskType_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
                <rx-tag [(source)]="eventLookup.specificRiskLookUp" #rxTag [controlTabIndex]="9" [isSort]="false"
                  formControlName="specificRiskId" mainClass="form-control" placeholder="Select Risk Type"
                  [keyValueProps]="['specificRiskName','specificRiskId']">
                </rx-tag>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">
              Show Timeline Layout
            </label>
            <div class="col-md-8 d-flex align-items-center">
              <fieldset>
                <label class="switch mb-0"><input formcontrolname="isTimeline" (change)="changeLayout($event)"
                    type="checkbox" /><span class="slider round"></span></label>
              </fieldset>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 pr-xl-5">

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
            rxText="EventSearchComponent_EventId_t"></label>
          <div class="col-md-8 ">
            <div class="d-flex">
              <input type="text" class="form-control mr-3" tabindex="1" formControlName="eventId"
              rxPlaceholder="EventSearchComponent_EventId_p" (keyup.enter)="goTo()"/>
            <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
              <button (click)="goTo()" class="btn btn-success search-result-btn" [rxLocalisationInit]="componentName"><span rxText="Btn_Go_t"></span></button>
            </div>
            <small class="form-text text-danger" *ngIf="msgError==true" [rxLocalisationInit]="componentName"><span rxText="EventSearchComponent_Go_Error_t"></span><br /></small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_StartDate_t"></label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date showAddon="true" [controlTabIndex]="2" formControlName="eventStartDate"
                           pickerClass="form-control" placeholder="Select Start Date"></rx-date>
                  <!-- <rx-date class="w-100" showAddon="true" formControlName="eventStartDate" placeholder="" pickerClass="form-control" ></rx-date> -->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_EndDate_t"></label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date showAddon="true" [controlTabIndex]="4" formControlName="eventEndDate"
                           pickerClass="form-control" placeholder="Select End Date"></rx-date>
                  <!-- <rx-date class="w-100" showAddon="true" formControlName="eventEndDate" placeholder="" pickerClass="form-control" ></rx-date> -->
                  <small class="form-text text-danger" *ngIf="dateError == true">
                    Event End Date should be greater than
                    Event Start Date<br />
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="showEventFilter" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_EventType_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="eventLookup.eventTypeLookUp" #rxSelect mainClass="form-control"
                         formControlName="eventType" [controlTabIndex]="3" [keyValueProps]="['eventTypeName', 'eventTypeId']"
                         [selectPlaceholder]="'Select Event Type'">
              </rx-select>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_Group_Company_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="eventLookup.genfieldsGroupEntity" [controlTabIndex]="13" #rxSelect mainClass="form-control"
                formControlName="gemfieldsGroupCompanyEventOwner" [keyValueProps]="['company','supplierId']"
                [selectPlaceholder]="'Select Gemfields Group Entity'">
              </rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_Department_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="eventLookup.departmentLookUp" [controlTabIndex]="13" #rxSelect mainClass="form-control"
                formControlName="department" [keyValueProps]="['departmentName', 'departmentId']"
                [selectPlaceholder]="'Select Department'">
              </rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_Type_Of_Services_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <rx-tag #rxTag [controlTabIndex]="13" [(source)]="eventLookup.eventServiceTypeLookUp"
                formControlName="eventServiceTypeIds" mainClass="form-control" placeholder=" Select Types of Support / Services"
                [isSort]="false" [keyValueProps]="['eventServiceName','eventServiceId']">
              </rx-tag>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row people_form_details">
                <div class="col-lg-6 col-xl-4 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventSearchComponent_TimeLayout"></label>
                        <div class="col-md-8 d-flex align-items-center">
                            <fieldset>
                                <label class="switch mb-0"><input formcontrolname="isTimeline"
                                        (change)="changeLayout($event)" type="checkbox" /><span
                                        class="slider round"></span></label>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div> -->

      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100 mt-1"></div>
        <div class="col text-right">
          <button class="btn btn-success mr-1 search-result-btn" (click)="searchButtonClicked()"
            rxText="Btn_Search_t"></button>
        </div>
      </div>
    </div>
  </div>

  <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showTab"
    [rxLocalisationInit]="componentName">
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="isShowChart"
      [rxLocalisationInit]="componentName">
      <!-- <div class="event-overview-modal gemfields-media-popup">
    <div class="event-modal-body d-flex">

        <div class="event-desc">
            <p class="mb-0 event-modal-title">Print Galleries Now</p>
            <p class="mb-0">Sophle Ebbetts</p>
        </div>
        <div class="event-counter ml-auto d-flex align-items-center justify-content-center">
            100%
        </div>
    </div>
    <div class="event-modal-footer">
        <p class="mb-0 font-weight-bold">Date Of Event</p>
        <p class="mb-0 mt-1">1 October  - 31 October</p>
    </div>
</div> -->

      <div class="tabs-container people-tabs tab-without-border my-2">
        <ul id="menu_slider" class="nav nav-tabs events_slider flex-nowrap mb-0" role="tablist">
          <li>
            <a class="nav-link" [class.active]="selectedTab == 'event-all'" (click)="activeTabGrid('event-all')">
              All
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '165'" (click)="activeTabGrid('165')"><span
                class="events-dots f-sales-dots"></span>
              <span class="events-dots-FabergeSales">Faberge Sales &<br />Client Events</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '167'" (click)="activeTabGrid('167')"><span
                class="events-dots f-media-dots"></span><span class="events-dots-FabergeMedia">Faberge Media /
                Advertising /<br />Promotion / Press Plan
              </span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '166'" (click)="activeTabGrid('166')"><span
                class="events-dots relevant-dots"></span>
              <span class="events-dots-RelevantSector">Relevant Sector /<br />Industry Events</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '169'" (click)="activeTabGrid('169')"><span
                class="events-dots g-event-dots"></span>
              <span class="events-dots-GemfieldsEvent">Gemfields<br />Event
              </span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '174'" (click)="activeTabGrid('174')"><span
                class="events-dots g-media-dots"></span>
              <span class="events-dots-GemfieldsMedia">Gemfields Media / Advertising<br />/ Promotion / Press
                Plan</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '168'" (click)="activeTabGrid('168')"><span
                class="events-dots f-products-dots"></span>
              <span class="events-dots-FabergeProducts">Faberge Products<br />and Pieces</span></a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '170'" (click)="activeTabGrid('170')"><span
                class="events-dots f-wholesale-dots"></span>
              <span class="events-dots-FabergeWholesale">Faberge <br />Wholesale</span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '171'" (click)="activeTabGrid('171')"><span
                class="events-dots training-dots"></span>
              <span class="events-dots-Training">All<br />Training </span>
            </a>
          </li>
          <li>
            <a class="nav-link" [class.active]="selectedTab == '172'" (click)="activeTabGrid('172')"><span
                class="events-dots boards-dots"></span>
              <span class="events-dots-AllBoards">All Boards &<br />Shareholder Meetings
              </span>
            </a>
          </li>
        </ul>
        <div class="slick_demo-btn"></div>
        <div class="tab-content">
          <div role="tabpanel" id="tab-event-all" class="tab-pane" [class.active]="selectedTab == 'event-all'">
            <!-- <div class="table-responsive">
                            <div id="event-all" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div id="event-all" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-faberge-sales" class="tab-pane" [class.active]="selectedTab == '165'">
            <!-- <div class="table-responsive">
                            <div id="165" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h165" style="display: none">
                <h4>No Record Found</h4>
              </div>

              <div id="165" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-faberge-media" class="tab-pane" [class.active]="selectedTab == '167'">
            <!-- <div class="table-responsive">
                            <div id="167" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h167" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="167" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-relevant" class="tab-pane" [class.active]="selectedTab == '166'">
            <!-- <div class="table-responsive">
                            <div id="166" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h166" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="166" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-gemfields" class="tab-pane" [class.active]="selectedTab == '169'">
            <!-- <div class="table-responsive">
                            <div id="169" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h169" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="169" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-gemfields-media" class="tab-pane" [class.active]="selectedTab == '174'">
            <!-- <div class="table-responsive">
                            <div id="174" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h174" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="174" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <!-- <div role="tabpanel" id="tab-event-faberge-product" class="tab-pane" [class.active]="selectedTab == 'event-faberge-product'" (click)="activeTab('event-faberge-product')">
                        <div class="panel-body border-0 px-0">Faberge Products and Pieces</div>
                    </div> -->
          <div role="tabpanel" id="tab-event-faberge-fabergeProduct" class="tab-pane"
            [class.active]="selectedTab == '168'">
            <!-- <div class="table-responsive">
                            <div id="168" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h168" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="168" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-faberge-wholesale" class="tab-pane" [class.active]="selectedTab == '170'">
            <!-- <div class="table-responsive">
                            <div id="170" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h170" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="170" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-event-all-training" class="tab-pane" [class.active]="selectedTab == '171'">
            <!-- <div class="table-responsive">
                            <div id="171" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h171" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="171" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
          <div role="tabpanel" id="tab-all-boards" class="tab-pane" [class.active]="selectedTab == '172'">
            <!-- <div class="table-responsive">
                            <div id="172" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div>
                          </div> -->
            <div>
              <div class="ibox-title bg-white mt-2" id="h172" style="display: none">
                <h4>No Record Found</h4>
              </div>
              <div id="172" class="timeline-default" style="height: 600px; width: 100%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="isShowGrid"
      [rxLocalisationInit]="componentName">
      <div class="table-responsive">
        <!-- <div id="eventsGrid" class="dataTables_wrapper pb-0 dt-bootstrap4">
                            </div> -->
        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
          <rx-grid [design]="eventsGrid"></rx-grid>
        </div>
      </div>
    </div>
    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
      <h4>No Record Found</h4>
    </div>
  </div>
</div>
