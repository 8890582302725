import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_NEWS_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [
        {
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onRedirect"
            },
            childrens: [{
              text: {
                text: function (e) { return this[e.name] }
              }
            }],
            attributes: {
              href: function (e) {
                if (this.newsId != null || this.newsId != undefined) {
                  return "/content-management-news/" + this.newsId;
                }
              },
              "ng-reflect-router-link": function (e) {
                if (this.newsId != null || this.newsId != undefined) {
                  return "/content-management-news/" + this.newsId;
                }
              },
              onClick: "return false;"
            }
          }
        }
      ]
    },
  };

  export const REDIRECT_LINK_FABERGE_HISTORY_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [
        {
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onRedirect"
            },
            childrens: [{
              text: {
                text: function (e) { return this[e.name] }
              }
            }],
            attributes: {
              href: function (e) {
                if (this.newsId != null || this.newsId != undefined) {
                  return "/history-mir-faberge/" + this.newsId;
                }
              },
              "ng-reflect-router-link": function (e) {
                if (this.newsId != null || this.newsId != undefined) {
                  return "/history-mir-faberge/" + this.newsId;
                }
              },
              onClick: "return false;"
            }
          }
        }
      ]
    },
  };

  