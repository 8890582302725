import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AbstractCorporateCreditRating } from '../domain/abstract-corporate-credit-rating';
import { vCorporateCreditRating } from 'src/app/models/extended-models/v-corporate-credit-rating';
import { Subscription } from 'rxjs';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';
@multilingual("CorporateCreditRatingViewComponent")
@Component({
  selector: 'app-corporate-credit-rating-view',
  templateUrl: './corporate-credit-rating-view.component.html',
})
export class CorporateCreditRatingViewComponent extends AbstractCorporateCreditRating implements OnInit, OnDestroy {
  subscription: any;
  corporateCreditRating: vCorporateCreditRating[];
  @Input() hide: () => any;
  titleClose: string;
  constructor(private location : PlatformLocation) {
    super();
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.subscription = this.get().subscribe((t: vCorporateCreditRating[]) => {
      this.corporateCreditRating = t;
      this.showComponent = true;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "CorporateCreditRatingViewComponent";
  }
}
