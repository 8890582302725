import {LookupPrevailingWeatherBase,IncidentPrevailingWeatherBase,} from '@app/database-models'
//Generated Imports
export class LookupPrevailingWeather extends LookupPrevailingWeatherBase 
{




//#region Generated Reference Properties
//#region incidentPrevailingWeathers Prop
incidentPrevailingWeathers : IncidentPrevailingWeatherBase[];
//#endregion incidentPrevailingWeathers Prop

//#endregion Generated Reference Properties





































}