import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPriceBasisLookUpBase {

//#region priceBasisId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'priceBasisId', keyColumn: true})
        priceBasisId : number;
//#endregion priceBasisId Prop


//#region priceBasisName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'priceBasisName', keyColumn: false})
        priceBasisName : string;
//#endregion priceBasisName Prop

}