import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductvariantOrderStatusLookUpBase {

//#region productvariantOrderStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'productvariantOrderStatusId', keyColumn: true})
        productvariantOrderStatusId : number;
//#endregion productvariantOrderStatusId Prop


//#region productvariantOrderStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'productvariantOrderStatusName', keyColumn: false})
        productvariantOrderStatusName : string;
//#endregion productvariantOrderStatusName Prop

}