import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CurrencyBase {

//#region currencyId Prop
        @prop()
        currencyId : number;
//#endregion currencyId Prop


//#region name Prop
        @required()
        @maxLength({value:50})
        @notEmpty()
        @trim()
        //@alpha({allowWhiteSpace:true})
        name : string;
//#endregion name Prop


//#region currencyCode Prop
        @required()
        @maxLength({value:5})
        @notEmpty()
        @trim()
        currencyCode : string;
//#endregion currencyCode Prop


//#region displayLocale Prop
        @maxLength({value:50})
        @trim()
        displayLocale : string;
//#endregion displayLocale Prop


//#region rate Prop
        @required()
        @notEmpty()
        rate : number;
//#endregion rate Prop


//#region customFormatting Prop
       // @required()
        @prop({defaultValue:''})
        @maxLength({value:50})
        @trim()
        customFormatting : string;
//#endregion customFormatting Prop


//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @required()
        @notEmpty()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrder : number;
//#endregion displayOrder Prop


//#region countryId Prop
        @prop()
        countryId : number;
//#endregion countryId Prop


//#region domain Prop
        @required({message:"You can't leave this field empty"})
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        domain : number;
//#endregion domain Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


}