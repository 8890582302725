import { TemplateConfig } from '@rxweb/grid';

export const PEOPLE_TYPE_GRID_TEMPLATE: TemplateConfig = {
    span: {
        class: ["label", function (e) {
            return this[e.name] === 15 ? "label-primary" : '';
        }],
        childrens: [{
            text: {
                text: function (e) {
                    return this[e.name] === 15 ? "Internal" : "External"
                }
            }
        }]
    }
}
