import { gridColumn } from "@rxweb/grid";
import { StatusEnumString } from "../enums/leave-status.enum";

export class MoveNoteDocumentBatchSearchViewModel {
  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh' , keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  @gridColumn({
    configuredTemplate: { templateName: "reDirect" },
    parameter: false,
    style: { width: "4%", "text-align": "initial" },
    class: ["text-left"],
    visible: true,
    columnIndex: 0,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_BatchNo_gh",
    keyColumn: false,
  })
  batchNo: number;

  @gridColumn({
    parameter: false,
    style: { width: "4%", "text-align": "initial" },
    class: ["text-left"],
    visible: true,
    columnIndex: 1,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_BatchProductNo_gh",
    keyColumn: false,
  })
  productId: number;

  @gridColumn({
    parameter: false,
    style: { width: "4%", "text-align": "initial" },
    class: ["text-left"],
    visible: true,
    columnIndex: 2,
    allowSorting: true,
    configuredTemplate: { templateName: "redirectproduct" },
    headerKey: "MoveNoteDocumentComp_BatchProductName_gh",
    keyColumn: false,
  })
  productname: number;

  supplierId: number;

  @gridColumn({
    parameter: false,
    style: { width: "7%", "text-align": "initial" },
    class: ["text-left"],
    visible: true,
    columnIndex: 6,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_BatchSupplierNo_gh",
    keyColumn: false,
  })
  supplierOrderNo: string;

  @gridColumn({
    parameter: false,
    style: { width: "5%", "text-align": "initial" },
    class: ["text-left"],
    visible: true,
    columnIndex: 3,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_BatchCreatedDate_gh",
    keyColumn: false,
  })
  CreatedOnDate: string;

  @gridColumn({
    parameter: false,
    style: { width: "7%", "text-align": "initial" },
    class: ["text-left"],
    visible: true,
    columnIndex: 5,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_BatchSupplierName_gh",
    keyColumn: false,
  })
  supplierName: string;

  designId: number;
  designName: string;
  totalCount: number;
  productBatchPurchaseOrderId?: number;
  statusId?: number;
  guid: string;
  hasApproveRejectAccess: boolean;
  hasSendToFactoryAccess: boolean;
  hasConfirmPOAccess: boolean;
  supplierEmail: string;

}