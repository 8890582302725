import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractUserLockout } from '../domain/abstract-user-lockout';
//import { UserLockout } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { UserLockoutViewModel } from 'src/app/view-model/user-lokout-view-model';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { BrowserModule, Title } from '@angular/platform-browser';
// import { UserLockoutFilterViewModel } from 'src/app/view-model/user-lockout-filter-view-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { UserLockoutFilterViewModel } from 'src/app/view-model/user-lockout-filter-view-model';
import { DatePipe } from '@angular/common';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { debounceTime } from 'rxjs/operators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { CustomerRoleTypeEnum } from "src/app/enums/customer-role-type.enum";
@multilingual("UserLockoutListComponent")
@access({ accessLevel: RolePermissionEnums.UserLockout, action: "get" })

@Component({
  selector: "app-user-lockout-list",
  templateUrl: './user-lockout-list.component.html'
})
export class UserLockoutListComponent extends AbstractUserLockout implements OnInit, OnDestroy {
  userLockouts: UserLockoutViewModel[];
  userLockoutInfo: UserLockoutViewModel;
  userLockoutSearchFormGroup: IFormGroup<UserLockoutFilterViewModel>;
  userLockout: UserLockoutFilterViewModel;
  subscription: any;
  userLockoutGrid: AppGrid;
  userLockoutGridColumns: any;
  showSearchBar: boolean = false;
  userIds: number[] = [];
  unblockUser: any = dbAccessModule[RolePermissionEnums.UserLockout]["put"];
  showGrid: boolean = false;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  private userLockoutSubject: Subject<string> = new Subject<string>();
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;

  constructor(private datePipe: DatePipe, modalView: ModalView, private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster, private router: Router, private title: Title) {
    super();
    this.modalView = modalView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.moduleID = RolePermissionEnums.UserLockout;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Users Lockout - G-Trac Admin");
    this.userLockout = new UserLockoutFilterViewModel();
    this.userLockoutSearchFormGroup = this.formBuilder.formGroup(this.userLockout) as IFormGroup<UserLockoutFilterViewModel>;
    this.titleHelp = getLocalizedValue("Title_Help");
    this.showComponent = true;
    this.userLockoutSubject.pipe(debounceTime(300)).subscribe(t => {
      this.searchUserLockout();
      this.decryptstring = localStorage.getItem('AllLocalizedMessages');
      this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
      this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  onKeyPress() {
    this.userLockoutSubject.next();
  }

  searchUserLockout() {
    this.userLockoutSearchFormGroup.submitted = true;
    if (this.userLockoutSearchFormGroup.valid && this.userLockoutSearchFormGroup.submitted) {
      this.userLockoutSearchFormGroup.patchValue({
        lockoutFrom: this.userLockoutSearchFormGroup.value.lockoutFrom == undefined ? null : this.userLockoutSearchFormGroup.value.lockoutFrom,
        lockoutTo: this.userLockoutSearchFormGroup.value.lockoutTo == undefined ? null : this.userLockoutSearchFormGroup.value.lockoutTo
      });
      this.spin = true;
      this.showGrid = true;
      this.isShowGrid = true;
      this.subscription = this.get({
        queryParams: {
          startIndex: 1,
          endIndex: 100,
          fromDate: this.datePipe.transform(this.userLockoutSearchFormGroup.value.lockoutFrom, "MM/dd/yyyy"),
          toDate: this.datePipe.transform(this.userLockoutSearchFormGroup.value.lockoutTo, "MM/dd/yyyy"),
          email: this.userLockoutSearchFormGroup.value.email
        }
      }).subscribe((t: any) => {
        this.userLockoutGridColumns = t;
        this.isShowGrid = this.userLockoutGridColumns.length > 0 ? true : false;
        this.isNoRecordFound = this.userLockoutGridColumns.length > 0 ? false : true;

        if (this.isShowGrid) {
          this.userLockoutGrid = new AppGrid(this.userLockoutGridColumns, UserLockoutViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
          this.userLockoutGrid.gridColumns.forEach(x => {
            if (x.name == "userId") {
              x.visible = this.userLockoutGrid.authorize({ accessLevel: RolePermissionEnums.UserLockout, action: "put" });
            }
          })
          this.userLockoutGrid.componentId = this.componentName;
          this.userLockoutGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.userLockoutGrid.designClass.headerClass = "table-header-sticky".split(" ");
          setTimeout(() => {
            this.userLockoutGrid.refresh("user-lockout");
            this.userLockoutGrid.design(document.getElementById("user-lockout"));
          }, 300);
        }
        this.showComponent = true;
        this.spin = false;
      })
    }
  }

  
  // searchUserLockout() {
  //   this.userLockoutSearchFormGroup.submitted = true;
  //   if (this.userLockoutSearchFormGroup.valid && this.userLockoutSearchFormGroup.submitted) {
  //     this.spin = true;
  //     this.showGrid = true;
  //     this.isShowGrid = true;
  //     this.subscription = this.get({
  //       queryParams: {
  //         startIndex: 1,
  //         endIndex: 100,
  //         fromDate: this.datePipe.transform(this.userLockoutSearchFormGroup.value.lockoutFrom, "MM/dd/yyyy"),
  //         toDate: this.datePipe.transform(this.userLockoutSearchFormGroup.value.lockoutTo, "MM/dd/yyyy"),
  //         email: this.userLockoutSearchFormGroup.value.email
  //       }
  //     }).subscribe((t: any) => {
  //         this.userLockoutGridColumns = t;
  //         if (this.userLockoutGridColumns.length > 0) {
  //           this.isNoRecordFound = false;
  //           this.showSearchBar = true;
  //         }
  //         else {
  //           this.isShowGrid = false;
  //           this.isNoRecordFound = true;
  //           this.showSearchBar = false;
  //         }
  //         this.clearGrid();
  //         this.userLockoutGrid = new AppGrid(this.userLockoutGridColumns, UserLockoutViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
  //         this.userLockoutGrid.gridColumns.forEach(x => {
  //           if (x.name == "userId") {
  //             x.visible = this.userLockoutGrid.authorize({ accessLevel: RolePermissionEnums.UserLockout, action: "put" });
  //           }
  //         })
  //         this.userLockoutGrid.componentId = this.componentName;
  //         setTimeout(() => {
  //           this.userLockoutGrid.design(document.getElementById("user-lockout"));
  //         }, 0);
  //         //this.userLockoutGridColumns = this.userLockoutGrid.gridColumns.filter(x => x.keyColumn == false);
  //         this.spin = false;
  //       })
  //   }
  // }

  clearGrid() {
    if (this.userLockoutGrid && !this.isNoRecordFound) {
      let child = document.getElementById("user-lockout").lastElementChild;
      while (child) {
        document.getElementById("user-lockout").removeChild(child);
        child = document.getElementById("user-lockout").lastElementChild;
      }
    }
  }

  onCheckboxSelect(t, x, y, z) {
    if (x.target.checked) {
      if (!this.userIds.includes(t.userId)) {
        this.userIds.push(t.userId);
      }
    }
    else {
      if (this.userIds.includes(t.userId)) {
        this.userIds.splice(this.userIds.indexOf(t.userId), 1);
      }
    }
  }

  unLoackUser() {
    this.userLockoutInfo = new UserLockoutViewModel();
    this.userLockoutInfo.userIds = this.userIds;
    this.spin = true;
    this.put({ body: this.userLockoutInfo, params: [0] }).subscribe(data => {
      this.spin = false;
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "UserAccount_Unlocked");
      if (existsAlert) {
        //this.toastr.success("User account has been Unlocked Successfully");
        this.toastr.success(existsAlert[0].showMessage);
      }
      this.searchUserLockout();
      this.userIds.length = 0;
    })
  }

  search(value) {
    if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
      let ele = this.userLockoutGridColumns.filter(x => x.email.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.userIdNumber.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
        || x.username.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.registrationDate.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
      if (ele.length == 0) {
        this.isNoRecordFound = true;
      } else {
        this.isNoRecordFound = false;
        this.userLockoutGrid = null;
      }
    } else {
      this.isNoRecordFound = false;
      this.userLockoutGrid = null;
    }
    setTimeout(() => {
      if (this.userLockoutGrid == null || this.userLockoutGrid == undefined) {
        this.userLockoutGrid = new AppGrid(this.userLockoutGridColumns, UserLockoutViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
        this.userLockoutGrid.gridColumns.forEach(x => {
          if (x.name == "userId") {
            x.visible = this.userLockoutGrid.authorize({ accessLevel: RolePermissionEnums.UserLockout, action: "put" });
          }
        })
        this.userLockoutGrid.componentId = this.componentName;
        this.userLockoutGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.userLockoutGrid.designClass.headerClass = "table-header-sticky".split(" ");
        this.userLockoutGrid.refresh("user-lockout");
        this.userLockoutGrid.design(document.getElementById("user-lockout"));
        //this.userLockoutGridColumns = this.userLockoutGrid.gridColumns.filter(x => x.keyColumn == false);
      }
      this.userLockoutGrid.search = value.target.value;
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "UserLockoutListComponent";
  }
}
