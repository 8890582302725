import {GemstoneInventoryStatusGemFieldBase,} from '@app/database-models'
//Generated Imports
export class GemstoneInventoryStatusGemField extends GemstoneInventoryStatusGemFieldBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}