import { TicketDetailBase, } from '@app/database-models'
import { maxLength, prop, required } from '@rxweb/reactive-form-validators';
import { Time } from '@angular/common';
//Generated Imports
export class TicketDetail extends TicketDetailBase {




    //#region Generated Reference Properties
    //#endregion Generated Reference Properties
    @prop()
    department: string;

    @prop()
    location: string;

    @prop()
    reportToName: string;

    @prop()
    @required({ message: "Please select User" })
    userName: string;

    @prop()
    file: File;

    @prop()
    fileData: string;

    @prop()
    fileExtention: string;

    @prop()
    fileName: string;

    @prop()
    fileUrl: string;

    @prop()
    timer: string;

    @prop()
    updateDescription: string;

    @prop()
    isTechUserLogin: boolean;

    @prop({defaultValue:false})
    isHSE : boolean;

    @prop({defaultValue : "00:00"})
    occurenceTime : Time;

    @prop()
    occuranceArea : string;

    //#region occurrence_where Prop
    @prop()
    occurrence_where : string;
    //#endregion occurrence_where Prop

    //#region isOccurrenceinCompany Prop
    @prop({defaultValue:false})
    @required()
    isOccurrenceinCompany : boolean;
    //#endregion isOccurrenceinCompany Prop

    //#region Latitude Prop
    @prop()
    latitude : number;

    //#region Longitude Prop
    @prop()
    longitude : number;

    @prop({defaultValue : ''})
    addlLocationDetails : string;

    @prop({defaultValue : ''})
    actionTaken : string;

    @prop({defaultValue : ''})
    actionTakenFollowup : string;

    @prop({defaultValue : ''})
    preventiveRisk : string;

    @prop()
    incidentTypeIds: string;

    @prop()
    SearchLocationName: string;

    @prop()
    incidentId : number;

    @prop({defaultValue:0})
    documentVisited : number;

    @prop()
    blobFileName:string;

    @prop()
    blobPath:string;
    
    @prop()
    size:number;

    @prop()
    assetIds: string;
    @prop()
    isDesktop: boolean;
}
