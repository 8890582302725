import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, RxFormBuilder } from "@rxweb/reactive-form-validators";
import { Document, Picture } from "@app/models";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { BaseDialog, DialogEnum } from "src/app/domain/customize-design/dialog";
import { ModalView } from "src/app/domain/customize-design/modal";
import { CoreComponent } from "@rxweb/angular-router";
import { FileInputViewModel } from "../../../../view-model/file-input-view-model";
import { FileOutputViewModel } from "../../../../view-model/file-output-view-model";
import {
  FileTypeEnums,
  FileSizeEnums,
  FileTypeValiationEnums,
} from "../../../../custom-enum/file-type-enums";
import { FormArray, FormBuilder } from "@angular/forms";
import { Input, EventEmitter, Directive, ComponentFactoryResolver, ViewContainerRef } from "@angular/core";
import { DocumentListViewModel } from "../../../../models/extended-models/document-list-view-model";
import { vDocuments } from "../../../../models/extended-models/v-document";
import { Subscription } from "rxjs";
import { AppGrid, AppGridDocumentDesigner } from "../../../../domain/app-grid";
import { List } from "@rxweb/generics";
import { DocumentListModel } from "../../../../models/extended-models/DocumentListModel";
import { DatePipe, PlatformLocation } from "@angular/common";
import { DialogViewMode } from "@rxweb/js";
import { Router } from "@angular/router";
import { $ } from "protractor";
import { NotesEnum } from "src/app/enums/notes.enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import * as CryptoJS from "crypto-js";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { vFileExtention } from "src/app/models/extended-models/v-file-extention";
import { GridColumnConfig } from "@rxweb/grid";
import { v4 as uuidv4 } from "uuid";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { BedRequestComponent } from "src/app/components/shared/global/bed-request/bed-request.component";
import { HttpClient } from "@angular/common/http";
import { BLOB_BASE, CLIENT_URL, DEFAULT_THUMBNAIL_IMAGE_URL } from "src/app/domain/system-constant";
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { MoveNoteDocumentModel } from "src/app/models/extended-models/move-note-document-model";
import { MoveNoteDocumentTargetSearchComponent } from "src/app/components/move-note-document-target/move-note-document-target-search/move-note-document-target-search.component";
import { rolesEnum } from "src/app/enums/roles-enum";
import { PaginationEnum } from "src/app/enums/pagination.enums";


@Directive()
@http({
  path: "api/Document",
})
export class AbstractDocument extends CoreComponent {
  isShowGridColumn: boolean = false;
  documentFormGroup: IFormGroup<Document>;
  documentUpdateFormGroup: IFormGroup<Document>;
  showComponent: boolean = false;
  spin: boolean = false;
  loading: boolean = false;
  baseToastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  fileViewModel: FileInputViewModel;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() moduleName: string;
  @Input() isPicture: boolean;
  @Input() isEditorDocument: boolean;
  id: number = 0;
  document: List<Document>;
  documnentList: vDocuments[];
  subscription: any;
  isSelect: boolean = true;
  documentListGrid: AppGridDocumentDesigner;
  documentListModel: DocumentListModel[];
  documentListScrolledModel: DocumentListModel[];
  trackArray: DocumentListModel[];
  sort: boolean = true;
  datePipe: DatePipe;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  isValidate: boolean = false;
  isArrayCount: number = 0;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  pictureUpdate: Document;
  isFilter: boolean = false;
  totalRecords: number = 0;
  deletedCount: number = 0;
  json: any;
  isShowSearch: boolean = false;
  extentionList: vFileExtention[];
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<
    GridColumnConfig[]
  >();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";
  supportedExtensions:any;
  allowEdit: boolean;
  documentTypeError: boolean = false;
  isDescriptionUpdated: boolean;
  selectedDocumentIds : number[] = [];
  moveNoteDocumentModel : MoveNoteDocumentModel;
  loggedInUserRole:any
  isAllowMoveDocuments: boolean = false;
  isHeaderBtnChecked: boolean = false;
  excludedDocumentIds: number[] = [];
  totalMovableDocuments: number = 0;
  defaultThumbnailPath: string = '';
  pageIndex: number;
  isScrolled : boolean = false;
  disableDragDrop: boolean = false;
  isUserorSupplier: boolean = false;

  constructor(
    private browserStorage: BrowserStorage,
    private blobState: BlobUploadsViewStateService,
    private httpClient: HttpClient,
    private location : PlatformLocation,
    private componentResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef
  ) {
    super();
    this.baseToastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.All;
    this.fileViewModel.fileAllowSize = FileSizeEnums.MaxMB;
    this.fileViewModel.isMultipart = true;
    this.trackArray = new Array<DocumentListModel>();
    this.moveNoteDocumentModel = new MoveNoteDocumentModel();
    this.moveNoteDocumentModel.isDocument = true;
    this.lookup([{ hostUri: CLIENT_URL, path: "assets/preview-supported-extensions/preview-supported-extensions.json", propName: "supportedExtensions" }]).subscribe((respose:any)=>{
      this.supportedExtensions = respose.supportedExtensions.SupportedExtensions;
    })
    //this.datePipe = new DatePipe();
    //setTimeout(() => {
      this.get({ path: "api/FileExtentions" }).subscribe((response: any) => {
        this.extentionList = response;
      });
    //}, 1000);
    location.onPopState(() => this.dialog.hide());
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
    if (this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString())){
      this.isAllowMoveDocuments = true;
    }
  }

  onFileChange(data: File[], formBuilder: RxFormBuilder): void {
    let imgFileExtentionList = this.extentionList
      .filter((x) => x.fileType == FileTypeEnums.Image)
      .map((x) => x.fileExtentionName)
      .join(",");
    let videoExtentionList = this.extentionList
      .filter((x) => x.fileType == FileTypeEnums.Video)
      .map((x) => x.fileExtentionName)
      .join(",");
    let docExtentionList = this.extentionList
      .filter((x) => x.fileType == FileTypeEnums.Doc)
      .map((x) => x.fileExtentionName)
      .join(",");

    data.forEach((dataArray) => {
      var file = dataArray;
      var _size = file.size;
      var fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
      if (file.size / 1024 > this.fileViewModel.fileAllowSize * 1024) {
        this.baseToastr.error(
          "File Size should be " + this.fileViewModel.fileAllowSize + "MB"
        );
        this.isValidate = false;
        return;
      }

      let validationMessage;
      let filename = file.name;
      let extension = filename
        .substr(filename.lastIndexOf(".") + 1)
        .toLowerCase();
      if (this.isPicture) {
        if (
          this.entityTypeId == NotesEnum.ProjectNotes ||
          this.entityTypeId == NotesEnum.EventNotes ||
          this.entityTypeId == NotesEnum.CorporateGroupsNotes ||
          this.entityTypeId == NotesEnum.IncidentAccidentNearDashMissNotes ||
          this.entityTypeId == NotesEnum.CustomerNotes
        ) {
          let split_str = imgFileExtentionList + "," + videoExtentionList;
          let extentionArray = split_str.split(',');
          let checkExtension = extentionArray.find(x => x == '.'+extension)
          if (checkExtension == null) {
            validationMessage =
              "Only following extensions are allowed:" +
              split_str;
            this.isValidate = false;
            this.isArrayCount = 0;
          } else {
            this.isValidate = true;
          }
        } else {
          let split_str = imgFileExtentionList;
          let extentionArray = split_str.split(',');
          let checkExtension = extentionArray.find(x => x == '.'+extension)
          if (checkExtension == null) {
            validationMessage =
              "Only following extensions are allowed:" +
              split_str;
            this.isValidate = false;
            this.isArrayCount = 0;
          } else {
            this.isValidate = true;
          }
        }
      } else {
        let split_str =
          docExtentionList +
          "," +
          imgFileExtentionList +
          "," +
          videoExtentionList +
          ",";
        let extentionArray = split_str.split(',');
        let checkExtension = extentionArray.find(x => x == '.'+extension)
        if (checkExtension == null) {
          validationMessage =
            "Only following extensions are allowed:" +
            split_str;
          this.isValidate = false;
          this.isArrayCount = 0;
        } else {
          this.isValidate = true;
        }
      }

      //USE checkMimeType function to verify mime type of the file for security purpose. (Make the function async)
      //this.isValidate  = this.isValidate ? await checkMimeType(file, extension) : this.isValidate;

      if (this.isValidate) {
        let documentArray = this.documentFormGroup.get(
          "documents"
        ) as FormArray;
        let document = new DocumentListViewModel();
        document.createdBy = 1;
        document.documentId = 0;
        document.isPicture = this.isPicture;
        document.moduleName = this.moduleName;
        document.seeOnline = false;
        document.entityId = this.id;
        document.entityTypeId = this.entityTypeId;
        document.file = file;
        document.fileName = file.name;
        document.size = file.size;
        documentArray.push(
          formBuilder.formGroup(document) as IFormGroup<FormArray>
        );
        this.isArrayCount++;
      } else {
        this.isArrayCount = 0;
        this.baseToastr.error(validationMessage);
      }
    });
    if (this.isArrayCount > 0) {
      // CURRENTLY ONLY CHANGING LOGIC FOR Documents TAB
      if (
        this.selectedTab == "document" ||
        this.selectedTab == "documents" ||
        this.selectedTab == "document-files"
      ) {
        this.uploadDocumentFromDocumentsTab(formBuilder);
      }
      else if(this.selectedTab=="pictures" ||
              this.selectedTab=="picture" ||
              this.selectedTab == "news-pictures"
      ){
        this.uploadpictureDocument(formBuilder)
      }
      else {
        this.uploadDocument(formBuilder);
      }
    }
  }

  updateDocumentTypes() {
    this.isFilter = true;
    this.allowEdit = false;
    this.documentTypeError = false;
    this.isDescriptionUpdated = false;

    for (let index = 0; index < this.trackArray.length; index++) {
      const element = this.trackArray[index];
      var documentData = this.documentListGrid.source.filter(t => t.documentId == element.documentId)[0];
      let updatedDocTypeId = +documentData.documentTypeId;
      if (documentData.dbDocumentTypeId != updatedDocTypeId) {
        if (updatedDocTypeId == 0) {
          this.documentTypeError = true;
          break;
        }
        else{
          this.allowEdit = true;
        }
      }
      // else {
      //   this.allowEdit = false;
      // }
    }

    for (let index = 0; index < this.trackArray.length; index++) {
      const element = this.trackArray[index];
      var documentData = this.documentListGrid.source.filter(t => t.documentId == element.documentId)[0];
      if (documentData.dbDocumentDescription != element.documentDescription) {
        this.isDescriptionUpdated = true;
        break;
      }
      else {
        this.isDescriptionUpdated = false;
      }
    }

    if (this.documentTypeError && !this.isDescriptionUpdated) {
      var existsAlert = getLocalizedValue("DocumentType_ValidationMessage");
      if (existsAlert) {
        this.baseToastr.warning(existsAlert);
      }
    }
    else if(!this.allowEdit && !this.isDescriptionUpdated) {
      var existsAlert = getLocalizedValue("DocumentType_ValidationMessage");
      if (existsAlert) {
        this.baseToastr.warning(existsAlert);
      }
    }
    else if (this.allowEdit || this.isDescriptionUpdated) {
      if(this.documentTypeError)
      {
        var existsAlert = getLocalizedValue("DocumentType_ValidationMessage");
        if (existsAlert) {
          this.baseToastr.warning(existsAlert);
        }
      }
      else{
        let newArray = [];
        this.trackArray.forEach(x => {
          this.documentListGrid.source.forEach(t => {
            if (t.documentId == x.documentId) {
              let documentTypeId = 0;
              let documentDescription = "";

              if (t.documentTypeId > 0) {
                if (t.dbDocumentTypeId != +t.documentTypeId) {
                  documentTypeId = +t.documentTypeId;
                }
              }
              if (t.dbDocumentDescription != x.documentDescription) {
                documentDescription = x.documentDescription;
              }
              else{
                documentDescription = t.dbDocumentDescription;
              }

              newArray.push({
                documentId: t.documentId,
                documentTypeId: documentTypeId,
                documentDescription: documentDescription
              });
            }
          });
        })

        if (newArray.length > 0) {
          this.documentFormGroup.value.documents = [];
          this.documentFormGroup.value.documentTypesCollection = newArray;
          this.spin = true;
          this.put({ body: this.documentFormGroup.toFormData(), params: [this.id] }).subscribe(data => {
            this.spin = false;
            this.allowEdit = false;
            this.documentTypeError = false;
            this.isDescriptionUpdated = false;
            var existsAlert = getLocalizedValue("Data_Updated");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            this.isShowSearch = false;
            this.bindGrid();
          });
        }
        else {

          var existsAlert = getLocalizedValue("DocumentType_Saved_For_All_Documents");
          if (existsAlert) {
            this.baseToastr.warning(existsAlert);
          }
        }
      }
    }
  }

  bindGrid(isOnload: boolean = true,isFromHeaderBtn:boolean = false) {
    this.spin = true;
    this.json["userId"] = 0;
    if (this.json["orderBy"] == "documentId") {
      this.json["orderBy"] = "createdOn";
    }
    if(this.isPicture){
      this.json["rowCount"] = PaginationEnum.RowCount;
      this.json["pageIndex"] = PaginationEnum.PageIndex;
      this.pageIndex = 1;
    }
    this.isShowGrid = true;
    this.subscription = this.get({
      queryParams: {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        isPicture: this.isPicture,
        query: encodeURIComponent(JSON.stringify(this.json)),
      },
    }).subscribe((t: any) => {
      if (this.documentListGrid && !this.isEditorDocument) {
        this.documentListGrid.storeProcedure.length = 0;
        this.documentListGrid.destroy();
        this.documentListGrid = undefined;
        this.trackArray = new Array<DocumentListModel>();
      }
      this.spin = false;
      this.documnentList = t;
      this.documentListModel = t;

      if (this.documentListModel != null) {
        this.documentListModel.forEach((x) => {
          if (x.isVideo == "false") {
            x.type = "image/*";
          } else {
            x.type = "video/*";
          }

          if(this.checkSupportedExtension(x.fileUrl?.split("?")[0])){
            x.isPreviewSupported = true;
          }
          else{
            x.isPreviewSupported = false;
          }

        });
      }
      //<-----For No Record Found Start------>
      if (this.documentListModel.length > 0) {
        this.defaultThumbnailPath = DEFAULT_THUMBNAIL_IMAGE_URL
        this.totalRecords = this.documentListModel[0].totalCount;
        this.totalMovableDocuments = this.documentListModel.filter(x=>x.noteId == null || x.noteId == 0).length;
        this.isShowGrid = true;
        this.isNoRecordFound = false;
        if (isOnload && this.totalRecords > 0) {
          this.isShowSearch = true;
        }

        this.documentListModel.forEach(x => {
          if (this.trackArray.findIndex(z => z.documentId == x.documentId) == -1) {
            this.trackArray.push(x);
          }
          else {
              this.trackArray.forEach(z => {
                  if (z.documentTypeId == x.documentTypeId) {
                      // x.isChecked = z.isChecked
                  }
              })
          }
        });

      } else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }
      //<-----For No Record Found End------>
      if (!this.isPicture) {
        if (this.isShowGrid == true) {
          this.documnentList.forEach(x=>{
            x.isChecked = false;
            if(isFromHeaderBtn){
              x.isChecked = this.isHeaderBtnChecked
            }
            else{
              if(this.isHeaderBtnChecked){
                if (this.excludedDocumentIds.findIndex(p => p == x.documentId) == -1) {
                  x.isChecked = true;
                }
              }
              else{
                if (this.selectedDocumentIds.findIndex(p => p == x.documentId) != -1) {
                  x.isChecked = true;
                }
              }
            }
          })
          this.documentListGrid = new AppGridDocumentDesigner(
            this.documnentList,
            DocumentListModel,
            {
              actions: {
                onOneLink: this.onDocumentDownloadLink.bind(this),
                onDelete: this.onDelete.bind(this),
                onCopy: this.onCopyFile.bind(this),
                onDataChange: this.editDocumentDescription.bind(this),
                onMoveDocumentCheckBoxSelect: this.onMoveDocumentCheckBoxSelect.bind(this)
              },
            }, this.componentResolver, this.viewContainerRef, this.isSelect
          );
          if(!this.isAllowMoveDocuments){
            this.documentListGrid.gridColumns.forEach((y) => {
              if (y.name == 'isChecked') {
                y.visible = false
              }
            });
          }
          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach((x) => {
              this.documentListGrid.gridColumns.forEach((y) => {
                if (y.name == x.ColumnName) y.visible = x.Visible;
              });
            });
            this.gridColumnConfig = this.documentListGrid.gridColumns;
            this.gridColumnSettings.emit(this.gridColumnConfig);
          }
          this.documentListGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: this.json["pageIndex"],
            onPageSorting: this.onPageSorting.bind(this),
          };

          if (this.isPicture) {
            this.documentListGrid.gridColumns.forEach((data) => {
              if (data.columnIndex == 5 || data.columnIndex == 6) {
                data.visible = false;
              }
            });
          }
        } else {
          this.isNoRecordFound = true;
        }
        this.documentListGrid.maxPerPage = this.json["rowCount"];
        this.documentListGrid.currentPage =  this.json["pageIndex"];
        this.documentListGrid.componentId = "DocumentListComponent";
        this.isFilter = true;
      }
    });
  }

  bindOnScroll() {
    this.pageIndex++;
    this.loading = true;
    this.json["userId"] = 0;
    if(this.isPicture){
      this.json["rowCount"] = PaginationEnum.RowCount;
      this.json["pageIndex"] = this.pageIndex;
    }
    this.subscription = this.get({
      queryParams: {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        isPicture: this.isPicture,
        query: encodeURIComponent(JSON.stringify(this.json)),
      },
    }).subscribe((t: any) => {
      this.loading = false;
      this.documentListScrolledModel = t;
      if (this.documentListScrolledModel != null) {
        this.documentListScrolledModel.forEach((x) => {
          if (x.isVideo == "false") {
            x.type = "image/*";
          } else {
            x.type = "video/*";
          }
          if(this.checkSupportedExtension(x.fileUrl?.split("?")[0])){
            x.isPreviewSupported = true;
          }
          else{
            x.isPreviewSupported = false;
          }

        });
      }

      if (this.documentListScrolledModel.length > 0) {
        this.documentListModel.push(...this.documentListScrolledModel)
        this.defaultThumbnailPath = DEFAULT_THUMBNAIL_IMAGE_URL
        this.totalRecords = this.documentListModel[(this.documentListModel.length-1)].totalCount
        this.isShowGrid = true;
        this.isNoRecordFound = false;
      } else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }
    });
  }

  editDocumentDescription(x, y, z, u, docDescription: DocumentListModel) {

    var documentData = this.trackArray.filter(t => t.documentId == x.documentId)[0].documentDescription;
    this.trackArray.filter(t => t.documentId == x.documentId)[0].documentDescription = y.target.value;
  }

  onMoveDocumentCheckBoxSelect(t, x){
    let documentId = parseInt(t.documentId);
    if (x.target.checked) {
      if (this.selectedDocumentIds.findIndex(p => p == documentId) == -1) {
        this.selectedDocumentIds.push(documentId);
      }
      if (this.excludedDocumentIds.findIndex(p => p == documentId) == -1) {
        this.excludedDocumentIds.splice(this.excludedDocumentIds.findIndex(p => p == documentId), 1);
      }
    }
    else {
      if (this.excludedDocumentIds.findIndex(p => p == documentId) == -1) {
        this.excludedDocumentIds.push(documentId);
      }
      let index = this.selectedDocumentIds.findIndex(p => p == documentId);
      if (index != -1) {
        this.selectedDocumentIds.splice(index, 1);
      }
    }
  }

  selectAllDocumentsToMove(){
    this.isHeaderBtnChecked = !this.isHeaderBtnChecked;
    this.bindGrid(true,true)
  }

  moveDocuments(){
    if(this.isHeaderBtnChecked){
      let filterJson = this.json
      filterJson["userId"] = 0;
      if (filterJson["orderBy"] == "documentId") {
        filterJson["orderBy"] = "createdOn";
      }
      filterJson["rowCount"] = this.totalRecords;
      filterJson["pageIndex"] = 1;
      let query = JSON.stringify(filterJson)
      this.moveNoteDocumentModel.query = query;
    }
    this.moveNoteDocumentModel.isDocument = true
    this.moveNoteDocumentModel.selectedIds = this.selectedDocumentIds
    this.moveNoteDocumentModel.excludedIds = this.excludedDocumentIds
    this.moveNoteDocumentModel.currentEntityId = this.id
    this.moveNoteDocumentModel.currentEntityTypeId = this.entityTypeId
    this.moveNoteDocumentModel.currentModuleId = this.moduleID
    this.moveNoteDocumentModel.isHeaderBtnChecked = this.isHeaderBtnChecked;
    if(this.selectedDocumentIds.length > 0 || (this.isHeaderBtnChecked && this.excludedDocumentIds.length < this.totalMovableDocuments)){
      this.modalView.show(MoveNoteDocumentTargetSearchComponent, { moveNoteDocumentModel : this.moveNoteDocumentModel}).then(t => {
        if(t){
          this.isFilter = true;
          this.selectedDocumentIds = [];
          this.excludedDocumentIds = [];
          this.isHeaderBtnChecked = false;
          this.bindGrid();
        }
      });
    }
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  applyVisible(gridColumnConfig: GridColumnConfig[]) {

    if (this.arrConfig == undefined || this.arrConfig == null) {
      const listObject = gridColumnConfig.map(obj => {
        if (!obj.parameter && !obj.keyColumn) {
          return { ColumnName: obj.name, Visible: obj.visible };
        }
      });
      this.arrConfig = listObject;
    }
    gridColumnConfig.forEach(column => {
      var arrConfigColumnIndex = this.arrConfig.findIndex(x => x.ColumnName == column.name);
      if (arrConfigColumnIndex != -1) {
        this.arrConfig[arrConfigColumnIndex].Visible = column.visible;
      }
    });

    if (this.documentListGrid && !this.isEditorDocument) {
      this.documentListGrid.storeProcedure.length = 0;
      this.documentListGrid.destroy();
      this.documentListGrid = undefined;
      this.trackArray = new Array<DocumentListModel>();
    }

    this.documentListGrid = new AppGridDocumentDesigner(
      this.documnentList,
      DocumentListModel,
      {
        actions: {
          onOneLink: this.onDocumentDownloadLink.bind(this),
          onDelete: this.onDelete.bind(this),
          onCopy: this.onCopyFile.bind(this),
          onDataChange: this.editDocumentDescription.bind(this)
        },
      }, this.componentResolver, this.viewContainerRef, this.isSelect
    );
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach((x) => {
        this.documentListGrid.gridColumns.forEach((y) => {
          if (y.name == x.ColumnName) y.visible = x.Visible;
        });
      });
      this.gridColumnConfig = this.documentListGrid.gridColumns;
      this.gridColumnSettings.emit(this.gridColumnConfig);
    }
    this.documentListGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: this.json["pageIndex"],
      onPageSorting: this.onPageSorting.bind(this),
    };
    if (this.isPicture) {
      this.documentListGrid.gridColumns.forEach((data) => {
        if (data.columnIndex == 5 || data.columnIndex == 6) {
          data.visible = false;
        }
      });
    }
    this.documentListGrid.maxPerPage = this.json["rowCount"];
    this.documentListGrid.componentId = "DocumentListComponent";


  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = (!this.sort).toString();
    this.sort = !this.sort;
    this.json["orderBy"] = x;
    this.documentListGrid.storeProcedure.nextPage = z;
    this.trackArray = new Array<DocumentListModel>();
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.documentListGrid.maxPerPage;
    this.documentListGrid.storeProcedure.nextPage = x;
    this.trackArray = new Array<DocumentListModel>();
    this.bindGrid(false);
  }

  bindFormGroup(): Document {
    let document = new Document();
    document.createdBy = 1;
    document.documentId = 0;
    document.entityId = this.id;
    document.entityTypeId = this.entityTypeId;
    document.fileName = "test";
    document.isPicture = this.isPicture;
    document.documents = new Array<DocumentListViewModel>();
    document.fileNamesCollection = new Array<{
      fileName: string;
      blobFileName: string;
      size:number;
    }>();
    return document;
  }

  onDocumentDownloadLink(userProject: DocumentListModel) {
    let documentData = new Document();
    documentData.documentId = userProject.documentId;
    documentData.entityId = this.entityId;
    documentData.entityTypeId = this.entityTypeId;
    documentData.createdBy = 2;
    documentData.fileName = userProject.fileName;
    documentData.seeOnline = false;
    this.documentFormGroup.patchValue({
      documentId: userProject.documentId,
      noteId: userProject.noteId,
      ModuleUrl:window.location.href
    });
    let documentArray = this.documentFormGroup.get("documents") as FormArray;
    this.documentFormGroup.value.documents = [];
    documentArray.controls = [];
    this.put({
      body: this.documentFormGroup.toFormData(),
      params: [this.id],
    }).subscribe((data) => {});
    window.open(`/download-content?did=${userProject.documentId}&t=${'document'}`, "_blank");
  }

  rebindData() {}

  async uploadDocumentFromDocumentsTab(formBuilder: RxFormBuilder) {
    this.documentFormGroup.submitted = true;
    if (
      this.documentFormGroup.valid &&
      this.isArrayCount > 0 &&
      this.isValidate
    ) {
      let data = this.documentFormGroup.value;
      this.spin = true;

      let arrayOfPromises = [];
      let allfileNames = [];
      for (let i = 0; i < this.documentFormGroup.value.documents.length; i++) {
        const document = this.documentFormGroup.value.documents[i];

        let newGUID = uuidv4();
        let fileName = BLOB_BASE.DocumentFiles+`${newGUID}_${new Date().toISOString()}.${document.fileName
          .split(".")
          .pop()}`;
        allfileNames.push({
          blobFileName: fileName,
          fileName: document.fileName,
          size:document.file.size
        });
        arrayOfPromises.push(
          this.blobState.uploadItems(document.file, fileName)
        );
      }

      let combinedRes = await Promise.all(arrayOfPromises);

      let finishedFileUploads = [];
      let erroredFileUploads = [];
      combinedRes.forEach((res) => {
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.DocumentFiles+decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
        let fileNameObj = allfileNames.find(
          (x) => x.blobFileName == uploadedFileName
        );
        // IF SUCCEED TO UPLOAD
        if (
          res &&
          (res._response.status == 200 || res._response.status == 201)
        ) {
          if (fileNameObj != undefined) finishedFileUploads.push(fileNameObj);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.baseToastr.error(
            "Error occured while uploading files! Please try again."
          );
          if (fileNameObj != undefined) erroredFileUploads.push(fileNameObj);
        }
      });

      let documentArray = this.documentFormGroup.get("documents") as FormArray;
      this.documentFormGroup.value.documents = [];
      documentArray.controls = [];

      let fileNamesFormArray = this.documentFormGroup.get(
        "fileNamesCollection"
      ) as FormArray;
      fileNamesFormArray.controls = finishedFileUploads.map((fileNameObj) => {
        return formBuilder.group(fileNameObj);
      });
      this.documentFormGroup.value.fileNamesCollection = finishedFileUploads;

      if (finishedFileUploads.length > 0) {
        this.post({ body: this.documentFormGroup.toFormData() }).subscribe(
          (data) => {
            this.spin = false;
            let documentArray = this.documentFormGroup.get(
              "documents"
            ) as FormArray;
            this.documentFormGroup.value.documents = [];
            documentArray.controls = [];

            var existsAlert = getLocalizedValue("Data_Added");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            if (
              this.documentListGrid != null &&
              this.documentListGrid != undefined
            ) {
              this.documentListGrid.updateSource([]);
              this.isFilter = true;
              this.json["pageIndex"] = 1;
            }
            this.bindGrid();
            this.handleFailedUploadDocumentsPopup(erroredFileUploads);
          }
        );
      } else {
        this.handleFailedUploadDocumentsPopup(erroredFileUploads);
        this.spin = false;
      }
    }
  }

  private handleFailedUploadDocumentsPopup(erroredFileUploads) {
    if (erroredFileUploads.length > 0) {
      this.modalView
        .show(BedRequestComponent, {
          validationMessages: JSON.stringify(
            erroredFileUploads.map((x) => x.fileName)
          ),
          headerMessage: "Following files are failed to upload:",
        })
        .then(() => {});
    }
  }

 async uploadDocument(formBuilder: RxFormBuilder) {
    this.documentFormGroup.submitted = true;

    let BLOB_BASE_url = "";
    if (this.entityTypeId == NotesEnum.ProductionOrderFormInfoNotes)
      BLOB_BASE_url = BLOB_BASE.POFormFiles;
    else if (this.entityTypeId == EditorDocumentsEnum.EventSendEInvitation)
      BLOB_BASE_url = BLOB_BASE.EventDocFiles;
    else if(this.entityTypeId == EditorDocumentsEnum.ProductBatch)
      BLOB_BASE_url = BLOB_BASE.InventoryProductBatchFiles;
    else if(this.entityTypeId == EditorDocumentsEnum.FabergeNews)
    BLOB_BASE_url =  BLOB_BASE.CMSFabergeNewsDocumentsForEditor;
    else if(this.entityTypeId == EditorDocumentsEnum.Topic)
    BLOB_BASE_url =  BLOB_BASE.CMSFabergePageDocumentsForEditor;
    else if(this.entityTypeId == EditorDocumentsEnum.FabergeHistory)
    BLOB_BASE_url =  BLOB_BASE.CMSFabergeHistoryDocumentsForEditor;
    else if(this.entityTypeId == EditorDocumentsEnum.HelpSupport)
    BLOB_BASE_url =  BLOB_BASE.HelpSupportDocumentsForEditor;
    else
      BLOB_BASE_url =  BLOB_BASE.DocumentFiles;

      if (
      this.documentFormGroup.valid &&
      this.isArrayCount > 0 &&
      this.isValidate
    ) {
      this.spin = true;
      let arrayOfPromises = [];
      let allfileNames = [];
      let data = this.documentFormGroup.value;
      for (let i = 0; i < this.documentFormGroup.value.documents.length; i++) {
        const document = this.documentFormGroup.value.documents[i];
        let newGUID = uuidv4();

        let fileName =  BLOB_BASE_url + `${newGUID}_${new Date().toISOString()}.${document.fileName
        .split(".")
        .pop()}`;
        allfileNames.push({
          blobFileNameBeforeUpdate:fileName,
          blobPath:BLOB_BASE_url,
          size:document.file.size,
          fileName:document.file.name
        });

			  arrayOfPromises.push(
          this.blobState.uploadItems(document.file, fileName)
          );

      }

        let combinedRes = await Promise.all(arrayOfPromises);

      let finishedFileUploads = [];
      let erroredFileUploads = [];
      combinedRes.forEach((res) => {
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE_url + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
        let fileNameObj = allfileNames.find(
          (x) => x.blobFileNameBeforeUpdate == uploadedFileName
        );
        // IF SUCCEED TO UPLOAD
        if (
          res &&
          (res._response.status == 200 || res._response.status == 201)
        ) {
          if (fileNameObj != undefined) finishedFileUploads.push(fileNameObj);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.baseToastr.error(
            "Error occured while uploading files! Please try again."
          );
          if (fileNameObj != undefined) erroredFileUploads.push(fileNameObj);
        }
      });

      let documentArray = this.documentFormGroup.get("documents") as FormArray;
      this.documentFormGroup.value.documents = [];
      documentArray.controls = [];

      let documentsFormArray = this.documentFormGroup.get("documents") as FormArray;
      documentsFormArray.controls = finishedFileUploads.map((fileNameObj) => {
        return formBuilder.group(fileNameObj);
      });
      this.documentFormGroup.value.documents = finishedFileUploads;

      this.documentFormGroup.value.documents.forEach(element => {
        element.createdBy = 1,
        element.documentId =0
        element.isPicture = this.isPicture;
        element.moduleName = this.moduleName;
        element.seeOnline = false;
        element.entityId = this.id;
        element.entityTypeId = this.entityTypeId;
      });

      this.spin = true;
      if (finishedFileUploads.length > 0) {
        this.post({ body: this.documentFormGroup.toFormData() }).subscribe(
          (data) => {
            this.spin = false;
            let documentArray = this.documentFormGroup.get(
              "documents"
            ) as FormArray;
            this.documentFormGroup.value.documents = [];
            documentArray.controls = [];

            //this.baseToastr.success("Data added successfully");
            var existsAlert = getLocalizedValue("Data_Added");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            // if (!this.isPicture) {
            //   if(this.documentListGrid!=null){
            //     this.documentListGrid.refresh("document");
            //   }

            // }
            if (
              this.documentListGrid != null &&
              this.documentListGrid != undefined
            ) {
              this.documentListGrid.updateSource([]);
              this.isFilter = true;
              this.json["pageIndex"] = 1;
              //this.documentListGrid.refresh("documentListGrid");
            }
            this.bindGrid();
          }
        );
      }
      else {
        this.handleFailedUploadDocumentsPopup(erroredFileUploads);
        this.spin = false;
      }
    }
  }

  async uploadpictureDocument(formBuilder: RxFormBuilder){
    this.documentFormGroup.submitted = true;

    let BLOB_BASE_url = "";
    if (this.entityTypeId == NotesEnum.EventNotes)
      BLOB_BASE_url = BLOB_BASE.EventsPicturesVideoTab;
    else if (this.entityTypeId == NotesEnum.CorporateGroupsNotes)
      BLOB_BASE_url = BLOB_BASE.SupplierPicturesVideoTab;
    else if (this.entityTypeId == NotesEnum.POSNotes)
      BLOB_BASE_url = BLOB_BASE.POSFiles;
    else if (this.entityTypeId == NotesEnum.IncidentAccidentNearDashMissNotes)
        BLOB_BASE_url = BLOB_BASE.HSEGINAPicturesVideoTab;
    else if (this.entityTypeId == NotesEnum.ProjectNotes)
      BLOB_BASE_url = BLOB_BASE.ProjectPicturesVideoTab;
    else if (this.entityTypeId == NotesEnum.CustomerNotes)
      BLOB_BASE_url = BLOB_BASE.UserPicturesVideoTab;
    else if (this.entityTypeId == NotesEnum.RiskNotes)
      BLOB_BASE_url = BLOB_BASE.RiskPicturesTab;
    else if (this.entityTypeId == NotesEnum.NewsNotes) // CMS >  Faberge News and Faberge History/MirFaberge using same entityTypeId so blob path will be same in this case.
      BLOB_BASE_url = BLOB_BASE.CMSFabergeNewsPictureTab;
    else if(this.entityTypeId = NotesEnum.GemStoneNotes)
      BLOB_BASE_url = BLOB_BASE.InventoryGemStonePicturesTab;

    if (
      this.documentFormGroup.valid &&
      this.isArrayCount > 0 &&
      this.isValidate
    ) {
      this.spin = true;

      let arrayOfPromises = [];
      let allfileNames = [];
      let data = this.documentFormGroup.value;
      for (let i = 0; i < this.documentFormGroup.value.documents.length; i++) {
        const document = this.documentFormGroup.value.documents[i];
      let newGUID = uuidv4();

      let fileName =  BLOB_BASE_url + `${newGUID}_${new Date().toISOString()}.${document.fileName
        .split(".")
        .pop()}`;
        allfileNames.push({
          blobFileNameBeforeUpdate:fileName,
          blobPath:BLOB_BASE_url,
          size:document.file.size,
          fileName:document.file.name
        });

        arrayOfPromises.push(
          this.blobState.uploadItems(document.file, fileName)
          );

        }
        let combinedRes = await Promise.all(arrayOfPromises);
      let finishedFileUploads = [];
      let erroredFileUploads = [];
      combinedRes.forEach((res) => {
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE_url + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
        let fileNameObj = allfileNames.find(
          (x) => x.blobFileNameBeforeUpdate == uploadedFileName
        );
        // IF SUCCEED TO UPLOAD
        if (
          res &&
          (res._response.status == 200 || res._response.status == 201)
        ) {
          if (fileNameObj != undefined) finishedFileUploads.push(fileNameObj);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.baseToastr.error(
            "Error occured while uploading files! Please try again."
          );
          if (fileNameObj != undefined) erroredFileUploads.push(fileNameObj);
        }
      });

      let documentArray = this.documentFormGroup.get("documents") as FormArray;
      this.documentFormGroup.value.documents = [];
      documentArray.controls = [];

      let documentsFormArray = this.documentFormGroup.get("documents") as FormArray;
      documentsFormArray.controls = finishedFileUploads.map((fileNameObj) => {
        return formBuilder.group(fileNameObj);
      });
      this.documentFormGroup.value.documents = finishedFileUploads;

      this.documentFormGroup.value.documents.forEach(element => {
        element.createdBy = 1,
        element.documentId =0
        element.isPicture = this.isPicture;
        element.moduleName = this.moduleName;
        element.seeOnline = false;
        element.entityId = this.id;
        element.entityTypeId = this.entityTypeId;
        // element.file = file;
        // element.fileName = file.name;
        // element.size = file.size;
      });

      this.spin = true;
      if (finishedFileUploads.length > 0) {
        this.post({ body: this.documentFormGroup.toFormData() }).subscribe(
          (data) => {
            this.spin = false;
            let documentArray = this.documentFormGroup.get(
              "documents"
            ) as FormArray;
            this.documentFormGroup.value.documents = [];
            documentArray.controls = [];

            //this.baseToastr.success("Data added successfully");
            var existsAlert = getLocalizedValue("Data_Added");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            // if (!this.isPicture) {
            //   if(this.documentListGrid!=null){
            //     this.documentListGrid.refresh("document");
            //   }

            // }
            if (
              this.documentListGrid != null &&
              this.documentListGrid != undefined
            ) {
              this.documentListGrid.updateSource([]);
              this.isFilter = true;
              this.json["pageIndex"] = 1;
              //this.documentListGrid.refresh("documentListGrid");
            }
            this.bindGrid();
          }
        );
      } else {
        this.handleFailedUploadDocumentsPopup(erroredFileUploads);
        this.spin = false;
      }

    }
  }

  onDelete(userProject: DocumentListModel) {
    this.dialog
      .show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel)
      .then((t) => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({
            params: [userProject.documentId],
            body: null,
            queryParams: {
              isPicture: this.isPicture,
              entityId: this.id,
              entityTypeId: this.entityTypeId,
              flag: false,
            },
          }).subscribe((data) => {
            //this.baseToastr.success("Data deleted successfully");
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            this.spin = false;
            if (!this.isPicture) {
              //this.documentListGrid.remove(userProject.documentId);
              this.trackArray = new Array<DocumentListModel>();
              this.documentListGrid.updateSource([]);
              this.isShowSearch = false;
              this.documentListGrid.storeProcedure.nextPage = 1;
              this.json["pageIndex"] = 1;
              this.bindGrid();
            } else {
              this.documentListModel.splice(
                this.documentListModel.findIndex(
                  (x) => x.documentId == userProject.documentId
                ),
                1
              );
              this.totalRecords -= 1;
              this.deletedCount++;
            }
            if (this.documentListModel.length <= 0) {
              this.isNoRecordFound = true;
              this.isShowGrid = false;
            }
          });
        } else {
          this.dialog.hide();
        }
      });
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Document");
    if (existsAlert) {
      return existsAlert;
    }
  }

  checkSupportedExtension(fileName:string):boolean{
    let extension = fileName
        .substr(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
    let checkExtension = this.supportedExtensions.find(x => x == extension.toLowerCase());
    if(checkExtension==null){
      return false;
    }
    else{
      return true;
    }
  }

  onCopyFile(data: DocumentListModel){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${CLIENT_URL}/download-content?did=${data.documentId}&t=${'document'}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
