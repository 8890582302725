import {AuditRequestBase,AuditRecordBase,} from '@app/database-models'
//Generated Imports
export class AuditRequest extends AuditRequestBase 
{




//#region Generated Reference Properties
//#region auditRecords Prop
auditRecords : AuditRecordBase[];
//#endregion auditRecords Prop

//#endregion Generated Reference Properties












}