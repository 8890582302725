import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vShippingStatusLookUpBase {

//#region shippingStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'shippingStatusId', keyColumn: true})
        shippingStatusId : number;
//#endregion shippingStatusId Prop


//#region shippingStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'shippingStatusName', keyColumn: false})
        shippingStatusName : string;
//#endregion shippingStatusName Prop

}