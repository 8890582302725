import { GINACompanyCustomerMappingBase } from '@app/database-models'
import { prop, required } from '@rxweb/reactive-form-validators';
//Generated Imports
export class GINACompanyCustomerMapping extends GINACompanyCustomerMappingBase {

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties


    personCorporateIds:GINACompanyCustomerMapping[];
    isCorporate:boolean;
    headerBtnChk: boolean;
    query: string;

}