import {RingEggSizeBase,} from '@app/database-models'
//Generated Imports
export class RingEggSize extends RingEggSizeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}