import { vProjectEventsBase } from '../database-models/v-project-events-base';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

// @actionColumn({
//     allowSorting: false,
//     style: { width: "1%" },
//     configuredTemplate: { templateName: 'editForProjEventTab' }, columnIndex: 10, headerTitle: "Action"
// })
export class vProjectEvents extends vProjectEventsBase 
{

@prop()
totalCount: number;


//#region Generated Reference Properties
//#endregion Generated Reference Properties
}