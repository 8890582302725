import { prop, required, maxLength, range, notEmpty, trim } from "@rxweb/reactive-form-validators"

export class ColorBase {

        //#region colorId Prop
        @prop()
        colorId: number;
        //#endregion colorId Prop


        //#region colorName Prop
        @required()
        @maxLength({ value: 50 })
        @notEmpty()
        @trim()
        colorName: string;
        //#endregion colorName Prop

        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        //#region languageId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        languageId: number;
        //#endregion languageId Prop

        //#region isActive Prop
        @prop()
        isActive : boolean;
        //#endregion isActive Prop

}
