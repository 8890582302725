import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductCurrencyTableBase {

//#region productVariantId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: false, headerTitle: 'ProductVariantId', keyColumn: true})
        productVariantId : number;
//#endregion productVariantId Prop


//#region currency Prop
        @gridColumn({visible: true,parameter:false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"],columnIndex:1, allowSorting: false, headerKey:'ProductAddEditComponent_ProductCurrencyGrid_gh', keyColumn: false})
        currency : string;
//#endregion currency Prop

//#region currencyCode Prop
        @gridColumn({visible: true,parameter:false,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex:2, allowSorting: false, headerKey: 'ProductAddEditComponent_ProductCurrencyCode_gh', keyColumn: false})
        currencyCode : string;
//#endregion currencyCode Prop

//#region countryName Prop
        @gridColumn({visible: true,parameter:false,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex:3, allowSorting: false, headerKey: 'ProductAddEditComponent_ProductCountry_gh', keyColumn: false})
        countryName : string;
//#endregion countryName Prop

//#region currencyRate Prop
        @gridColumn({visible: true,parameter:false,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex:4, allowSorting: false, headerKey: 'ProductAddEditComponent_ProductExchangeRate_gh', keyColumn: false})
        currencyRate : string;
//#endregion currencyRate Prop

//#region exportPrice Prop
        @gridColumn({visible: true,parameter:false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"],columnIndex:5, allowSorting: false, headerKey: 'ProductAddEditComponent_ExportRetailPrice_t', keyColumn: false})
        exportPrice : string;
//#endregion exportPrice Prop

//#region deliveredprice Prop
        @gridColumn({visible: true,parameter:false,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex:6, allowSorting: false, headerKey: 'ProductAddEditComponent_DeliveredRetailPrice_gh', keyColumn: false})
        deliveredPrice : string;
//#endregion deliveredprice Prop

}