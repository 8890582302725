import {vProductVariantStatusRecordBase,} from '@app/database-models'
//Generated Imports
export class vProductVariantStatusRecord extends vProductVariantStatusRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}