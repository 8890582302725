import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPersonCorporateMappingBase {

//#region projectCorporateID Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'projectCorporateID', keyColumn: true})
        projectCorporateID : number;
//#endregion ProjectCorporateID Prop

//#region customerID Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'customerID', keyColumn: false})
        customerID : number;
//#endregion customerID Prop

//#region roleId Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'customerID', keyColumn: false})
        roleId : number;
//#endregion roleId Prop

//#region person Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], isAscending:true, visible: true, columnIndex:2, allowSorting: true, headerTitle: 'person', keyColumn: false})
        person : string;
//#endregion person Prop


//#region corporate Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:3, allowSorting: true, headerTitle: 'corporate', keyColumn: false})
        corporate : string;
//#endregion corporate Prop


//#region department Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:4, allowSorting: true, headerTitle: 'department', keyColumn: false})
        department : string;
//#endregion department Prop

//#region projectId Prop
        @gridColumn({ style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: false, columnIndex:5, allowSorting: true, headerKey: 'projectId', keyColumn: false})
        projectId : string;
//#endregion projectId Prop

//#region statusId Prop
        @gridColumn({ style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: false, columnIndex:6, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : string;
//#endregion statusId Prop




}