import {vPieceOwnerLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPieceOwnerLookUp extends vPieceOwnerLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}