import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PictureBase {

//#region pictureId Prop
        @prop()
        pictureId : number;
//#endregion pictureId Prop


//#region pictureBinary Prop
        @required()
        pictureBinary : any;
//#endregion pictureBinary Prop


//#region isNew Prop
        @required()
        isNew : boolean;
//#endregion isNew Prop


//#region mimeType Prop
        @required()
        @maxLength({value:20})
        mimeType : string;
//#endregion mimeType Prop


//#region createdOn Prop
        @prop()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @prop()
        updatedOn : Date;
//#endregion updatedOn Prop

























}