import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingProductBase {

//#region tripProductID Prop
        @prop()
        tripProductID : number;
//#endregion tripProductID Prop


//#region tripProductGUID Prop
        @required()
        tripProductGUID : any;
//#endregion tripProductGUID Prop


//#region tripID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        tripID : number;
//#endregion tripID Prop


//#region productVariantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop


//#region sold Prop
        @prop()
        sold : boolean;
//#endregion sold Prop


//#region customerID Prop
        @prop()
        customerID : number;
//#endregion customerID Prop


//#region productVariantBatchPieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region locationUpdatedDate Prop
        @prop()
        locationUpdatedDate : Date;
//#endregion locationUpdatedDate Prop







}