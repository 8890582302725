import {BoutiqueProductMappingBase,BoutiqueBase,ProductBase,SupplierBase,UserBase,} from '@app/database-models'
//Generated Imports
export class BoutiqueProductMapping extends BoutiqueProductMappingBase 
{




//#region Generated Reference Properties
//#region boutique Prop
boutique : BoutiqueBase;
//#endregion boutique Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties






































}