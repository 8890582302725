import { CLIENT_URL } from 'src/app/domain/system-constant';
import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
export const NOTEREPLY_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        isBind: (x) => {
          if (x.isReply == "true" && !x.isSubNote) {
            return true;
          }
          else {
            return false;

          }
        },
        event: {
          click: "onReply"
        },
        attributes: { "title": getLocalizedValue("Title_Reply") },
        childrens: [
          {
            div: {
              attributes: {
                innerHTML: function () {
                  return '<span class="fa-stack fa-lg email-reply"><i class="fa fa-envelope-o fa-stack-2x"></i><i class="fa fa-reply fa-stack-1x"></i><i class="fa-solid fa-text" style="line-height: 6em;padding-left:5px;font-size: xx-small;">Reply</i></span>';
                }
              }
            }
            // img: {
            //   attributes: {
            //     src: function () {
            //       return CLIENT_URL + "/assets/images/note_reply.png";
            //     }
            //   },
            // }
          }],
        class: ["mr-2"]
      }
    }]
  }
}


