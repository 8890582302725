import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductOrderBase {

//#region orderProductVariantID Prop
        @prop()
        orderProductVariantID : number;
//#endregion orderProductVariantID Prop


//#region orderID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        orderID : number;
//#endregion orderID Prop


//#region productVariantID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID : number;
//#endregion productVariantID Prop


//#region unitPriceInclTax Prop
        @required()
        unitPriceInclTax : number;
//#endregion unitPriceInclTax Prop


//#region unitPriceExclTax Prop
        @required()
        unitPriceExclTax : number;
//#endregion unitPriceExclTax Prop


//#region priceInclTax Prop
        @required()
        priceInclTax : number;
//#endregion priceInclTax Prop


//#region priceExclTax Prop
        @required()
        priceExclTax : number;
//#endregion priceExclTax Prop


//#region unitPriceInclTaxInCustomerCurrency Prop
        @required()
        unitPriceInclTaxInCustomerCurrency : number;
//#endregion unitPriceInclTaxInCustomerCurrency Prop


//#region unitPriceExclTaxInCustomerCurrency Prop
        @required()
        unitPriceExclTaxInCustomerCurrency : number;
//#endregion unitPriceExclTaxInCustomerCurrency Prop


//#region priceInclTaxInCustomerCurrency Prop
        @required()
        priceInclTaxInCustomerCurrency : number;
//#endregion priceInclTaxInCustomerCurrency Prop


//#region priceExclTaxInCustomerCurrency Prop
        @required()
        priceExclTaxInCustomerCurrency : number;
//#endregion priceExclTaxInCustomerCurrency Prop


//#region attributeDescription Prop
        @required()
        @maxLength({value:4000})
        attributeDescription : string;
//#endregion attributeDescription Prop


//#region attributesXML Prop
        @required()
        attributesXML : string;
//#endregion attributesXML Prop


//#region quantity Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        quantity : number;
//#endregion quantity Prop


//#region discountAmountInclTax Prop
        @required()
        discountAmountInclTax : number;
//#endregion discountAmountInclTax Prop


//#region discountAmountExclTax Prop
        @required()
        discountAmountExclTax : number;
//#endregion discountAmountExclTax Prop


//#region downloadCount Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        downloadCount : number;
//#endregion downloadCount Prop


//#region orderProductVariantGUID Prop
        @required()
        orderProductVariantGUID : any;
//#endregion orderProductVariantGUID Prop


//#region isDownloadActivated Prop
        @required()
        isDownloadActivated : boolean;
//#endregion isDownloadActivated Prop


//#region licenseDownloadID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        licenseDownloadID : number;
//#endregion licenseDownloadID Prop


//#region isMakeToOrder Prop
        @required()
        isMakeToOrder : boolean;
//#endregion isMakeToOrder Prop


//#region makeToOrderQuantity Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        makeToOrderQuantity : number;
//#endregion makeToOrderQuantity Prop

        //#region actualUnitPriceExclTax Prop
        actualUnitPriceExclTax : number;
        //#endregion actualUnitPriceExclTax Prop
}