import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingWatchPartBase {

        //#region bookingWatchPartId Prop
        @prop()
        bookingWatchPartId: number;
        //#endregion bookingWatchPartId Prop


        //#region watchPartGUID Prop
        @required()
        watchPartGUID: any;
        //#endregion watchPartGUID Prop


        //#region bookingId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        bookingId: number;
        //#endregion bookingId Prop


        //#region watchPartId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        watchPartId: number;
        //#endregion watchPartId Prop


        //#region status Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        status: number;
        //#endregion status Prop


        //#region approvedBy Prop
        @prop()
        approvedBy: number;
        //#endregion approvedBy Prop


        //#region approveDate Prop
        @prop()
        approveDate: Date;
        //#endregion approveDate Prop

        createdAt?: number;
        updatedAt?: number;
        createdBy?: Date;
        updatedBy?: Date;

}