import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGiftCardTypeLookUpBase {

//#region giftCardTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'giftCardTypeId', keyColumn: true})
        giftCardTypeId : number;
//#endregion giftCardTypeId Prop


//#region giftCardTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'giftCardTypeName', keyColumn: false})
        giftCardTypeName : string;
//#endregion giftCardTypeName Prop

}