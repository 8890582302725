import {vProductionFormCorporateBase,} from '@app/database-models'
//Generated Imports
export class vProductionFormCorporate extends vProductionFormCorporateBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}