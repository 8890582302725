import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 18, headerTitle: "Action"
})

export class LeaveTypeCorporateMappingViewModel {

    //#region leaveTypeCorporateMappingId 
    @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'masterId', keyColumn: true })
    leaveTypeCorporateMappingId: number;
    //#endregion leaveTypeCorporateMappingId 

    //#region companyName Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'LeaveTypeCorporateMappingListComponent_Name_gh', keyColumn: false })
    companyName: string;
    //#endregion companyName Prop

    //#region emailAddress 
    @gridColumn({ isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'LeaveTypeCorporateMappingListComponent_Email_gh', keyColumn: false, parameter : false })
    emailAddress: string;
    //#endregion emailAddress 


    //#region registrationDate 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, visible: true, class: ["text-left"], configuredTemplate: { templateName: "dateDispay" }, columnIndex: 4, allowSorting: true, headerKey: 'LeaveTypeCorporateMappingListComponent_RegDate_gh', keyColumn: false, parameter : false })
    registrationDate: Date;
    //#endregion registrationDate 

    //#region postedOn 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, visible: false, class: ["text-left"], configuredTemplate: { templateName: "dateDispay" }, columnIndex: 8, allowSorting: true, headerKey: 'TravelListComponent_PostedOn_gh', keyColumn: false, parameter : false })
    createdOn: Date;
    //#endregion postedOn


    //#region days 
    @gridColumn({ style: { width: "10%", "text-align": "initial" },visible: false, class: ["text-left"], columnIndex: 9, allowSorting: true, headerKey: 'TravelListComponent_Days_gh', keyColumn: false ,parameter : false})
    days: number;
    //#endregion days 


    //#region budget
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, visible: false, class: ["text-left"], columnIndex: 10, allowSorting: true, headerKey: 'TravelListComponent_Budget_gh', keyColumn: false, parameter : false })
    budget: number
    //#endregion budget

    //#region currency
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, visible: false, class: ["text-left"], columnIndex: 11, allowSorting: true, headerKey: 'TravelListComponent_Currency_gh', keyColumn: false, parameter : false })
    currency: number
    //#endregion currency


    //#region citiesMinesLocations
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, visible: false, class: ["text-left"], columnIndex: 12, allowSorting: true, headerKey: 'TravelListComponent_CitiesMinesLocations_gh', keyColumn: false , parameter : false })
    citiesMinesLocations: string
    //#endregion citiesMinesLocations


    //#region stStatus 
    @gridColumn({ style: { width: "7%", "text-align": "initial" }, visible: false, class: ["text-left"], columnIndex: 13, allowSorting: false, headerKey: 'TravelListComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName: "leaveStatus" }, parameter : false })
    status: number;
    //#endregion stStatus    

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop
}