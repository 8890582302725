import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldsLocationBase {

//#region locationId Prop
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldsLocationListComponent_LocationId_gh', keyColumn: true })
        locationId : number;
//#endregion locationId Prop


//#region locationName Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, visible: true, columnIndex: 1, allowSorting: true, class: ["text-left"], headerKey: 'GemfieldsLocationListComponent_LocationName_gh', keyColumn: false })
        locationName : string;
//#endregion locationName Prop

}