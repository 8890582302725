import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { RiskMenuDetail, vBoardRiskOwnerLookUp, vGemfieldGroupCompanyLookUp, vLocalRiskOwnerLookUp, vRiskCategoryLookUp, vSpecificRiskLookUp } from '@app/models';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { Router } from "@angular/router";

@http({
    path: "api/RiskMenuDetails",
})

export class AbstractProjectRiskDetail extends RxHttp {
    riskMenuDetailFormGroup: IFormGroup<RiskMenuDetail>;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    showComponent: boolean = false;
    isFilter: boolean = false;
    isRiskAddFilter: boolean = false;
    isShowGrid : boolean;
    isShowAddRiskGrid: boolean;
    showAddRiskGrid: boolean = false;
    showGrid: boolean = false;
    isNoRecordFound:boolean = false;
    isNoRiskAddRecordFound: boolean = false;
    totalRecords: number = 0;
    totalAddRiskRecords: number = 0;
    id:number = 0;
    router: Router;
    gemfieldRiskSearchLookUp: any = {
        gemfieldGroupCompany: vGemfieldGroupCompanyLookUp,
        riskCategory: vRiskCategoryLookUp,
        specificRisk: vSpecificRiskLookUp,
        localRiskOwner: vLocalRiskOwnerLookUp,
        boardRiskOwner: vBoardRiskOwnerLookUp
    }
    selectedTab: string = "risk-edit"
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        //this.router = router;
        // this.activatedRoute.params.subscribe(t => {
        //     this.id = t['id'];
        //})
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
    activeTab(tabName: string) {
        this.selectedTab = tabName;
        this.router.navigate(["gemfield-risk-menu", this.id], { queryParams: { activeTab: tabName } });
      }
}
