import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ColorLocalizationBase {

//#region id Prop
        @prop()
        id : number;
//#endregion id Prop


//#region colorId Prop
        @prop()
        colorId : number;
//#endregion colorId Prop


//#region languageId Prop
        @prop()
        languageId : number;
//#endregion languageId Prop


//#region colorName Prop
        @prop()
        colorName : string;
//#endregion colorName Prop

}