import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AbstractFileExtention } from '../domain/abstract-file-extentions';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { FileExtention } from 'src/app/models/extended-models/file-extention';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { getSystemHelpStatus, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { StatusEnum } from 'src/app/enums/status.enum';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { multilingual } from '@rxweb/localization';
import { FileExtentionEditComponent } from '../edit/file-extentions-edit.component';


@multilingual("FileExtentionAddComponent")
@access({ accessLevel: RolePermissionEnums.FileExtentions, action: "post" })
@Component({
  selector: 'app-file-extentions-add',
  templateUrl: './file-extentions-add.component.html',
  styleUrls: ['./file-extentions-add.component.css']
})

export class FileExtentionAddComponent extends AbstractFileExtention implements OnInit, OnDestroy {
    fileExtention: FileExtention;
    fileExtentionEdit: any = FileExtentionEditComponent;
    subscription: any;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    pTitle: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder,private router:Router,modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.FileExtentions;
        this._this = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("File Extentions - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.fileExtention = new FileExtention()
        this.fileExtention.fileExtentionId=0;
        this.fileExtention.statusId=StatusEnum.Active;
        this.fileExtentionFormGroup = this.formBuilder.formGroup(this.fileExtention) as IFormGroup<FileExtention>;
        this.showComponent=true;

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addExtention(isRedirect: boolean) {
        this.fileExtentionFormGroup.submitted = true;
        if (this.fileExtentionFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.fileExtentionFormGroup.value }).subscribe(data => {
                this.spin=false;
                //this.toastr.success("Data Added Successfully")
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert[0].showMessage);
                }
                isRedirect ? this.router.navigate(['/file-extentions', data]) : this.router.navigate(['/file-extentions']);
            })
        }else{
            for (const key of Object.keys(this.fileExtentionFormGroup.controls)) {
                if (this.fileExtentionFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }
    get componentName(): string {
        return "FileExtentionAddComponent";
    }
}
