"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subject = void 0;
var observable_1 = require("./observable");
var subject_subscription_1 = require("./subject-subscription");
var defaultObserver = {
    next: function () {
    },
    error: function () {
    },
    complete: function () {
    },
};
var Subject = (function () {
    function Subject() {
        this.observers = [];
    }
    Subject.prototype.next = function (val) {
        var _this = this;
        this.observer = new observable_1.Observable(function (observer) {
            observer.next(val);
            observer.complete();
        });
        this.observers.forEach(function (item) { return _this.observer.subscribe(item); });
    };
    Subject.prototype.subscribe = function (observer) {
        var newObserver;
        if (typeof observer === 'function') {
            newObserver = __assign(__assign({}, defaultObserver), { next: observer });
        }
        else {
            newObserver = __assign(__assign({}, defaultObserver), observer);
        }
        this.observers.push(newObserver);
        return new subject_subscription_1.SubjectSubscription(this, newObserver);
    };
    return Subject;
}());
exports.Subject = Subject;
