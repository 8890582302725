import { prop,propObject,propArray,required,maxLength,range ,notEmpty,email,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class MessageTemplateDetailBase {

//#region messageTemplateDetailID Prop
        @prop()
        messageTemplateDetailID : number;
//#endregion messageTemplateDetailID Prop


//#region messageTemplateID Prop
        @required()
        messageTemplateID : number;
//#endregion messageTemplateID Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region bCCEmailAddresses Prop
        // @required()
        // @notEmpty()
        @prop()
        // @maxLength({value:255})
        @trim()
        bccEmailAddresses : string;
//#endregion bCCEmailAddresses Prop


//#region subject Prop
       // @required()
        @prop({defaultValue:""})
        @maxLength({value:200})
       // @trim()
       // @notEmpty()
        subject : string;
//#endregion subject Prop


//#region body Prop
        @required()
        @notEmpty()
        @trim()
        body : string;
//#endregion body Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region emailAccountId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        emailAccountId : number;
//#endregion emailAccountId Prop


//#region isActive Prop
        @prop({defaultValue:false})
        isActive : boolean;
//#endregion isActive Prop


//#region parentMessageTemplateDetailID Prop
        @prop()
        parentMessageTemplateDetailID : number;
//#endregion parentMessageTemplateDetailID Prop







}
