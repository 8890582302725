import {EventCustomerMappingBase,EventBase,} from '@app/database-models'

//Generated Imports
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { EventInviteesViewModel } from 'src/app/view-model/event-invitees-view-model';
export class EventCustomerMapping extends EventCustomerMappingBase 
{




//#region Generated Reference Properties
//#region event Prop
event : EventBase;
//#endregion event Prop

PeopleEventInput:ProjectEventMappingViewModel[];

EventInvitees:EventInviteesViewModel[];

//#endregion Generated Reference Properties
isHeaderChecked : boolean;

query:string;

isSupplier: boolean;










}