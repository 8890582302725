import { prop, email, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class  ActivityLogFilterViewModel{

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "name";
    }
    @prop()
    createdFrom : Date;
    @prop()
    createdTo : Date;
    @prop()
    @email()
    email : String;
    @prop()
    activityLogTypeId : Number;

    @prop()
    rowCount: number;
  
    @prop()
    pageIndex: number;
  
    @prop()
    @trim()
    sortOrder: string;
  
    @prop()
    @trim()
    orderBy: string;

    @prop()
    searchString:string;
}
