import { Injectable } from '@angular/core';
import { RxHttp } from '@rxweb/http';
import { ForgotPasswordViewModel } from 'src/app/view-model/forgot-password-view-model';
import { getServerUrl } from 'src/app/domain/common-logic/common-logic';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { vSystemHelpBase } from 'src/app/models/database-models/v-system-help-base';


@Injectable({
    providedIn: 'root'
})

export class LoginService {
    constructor(private http: RxHttp, private httprequest: HttpClient, private browserStorage: BrowserStorage) {
        //this.http.badRequest = (data) => {
        //}
    }
    GetAllLocalizedMessages() {
        try {
            var LocalizedDataURL = getServerUrl();
            LocalizedDataURL = LocalizedDataURL + "/api/Authentication/GetAllLocalizedMessages";
            return this.httprequest.get<any>(LocalizedDataURL).subscribe(response => {
                if (localStorage.getItem('AllLocalizedMessages')) {
                    localStorage.removeItem('AllLocalizedMessages')
                }
                var encryptstring = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response), 'secret key 123').toString());
                localStorage.setItem('AllLocalizedMessages', encryptstring);
                this.browserStorage.local.save('LocalizedMessages', encryptstring, false);
            });
        } catch (error) {
            console.log(error);
        }
    }

    getSystemHelpInfo() {
        this.http.get(
            { path: "api/SearchCommon/SystemHelpList" }
          ).subscribe((response: vSystemHelpBase[]) => {
            var data = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response), 'secret key 123').toString());
            localStorage.setItem('SystemHelp', data);
          });
    }
    // GetAllLocalizedMessages() {
    //     try {
    //         if (!this.browserStorage.local.get("LocalizedMessages", false)) {
    //             var LocalizedDataURL = getServerUrl();
    //             LocalizedDataURL = LocalizedDataURL + "/api/Authentication/GetAllLocalizedMessages";
    //             return this.httprequest.get<any>(LocalizedDataURL).subscribe(response => {
    //                 if (localStorage.getItem('AllLocalizedMessages')) {
    //                     localStorage.removeItem('AllLocalizedMessages')
    //                 }
    //                 var encryptstring = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response), 'secret key 123').toString());
    //                 localStorage.setItem('AllLocalizedMessages', encryptstring);
    //                 //console.log(response);
    //             })
    //         }
    //         else {
    //             localStorage.setItem('AllLocalizedMessages', this.browserStorage.local.get("LocalizedMessages", false));
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    login(userCredentialModel: any) {
        return this.http.post<any>({ body: userCredentialModel, path: "api/Authentication" })
    }

    forgotPassword(forgotPasswordModel: any) {
        return this.http.post<any>({ body: forgotPasswordModel, path: "api/Authentication/forgotPassword" });
    }

    memberFeedback(memberFeedback: any) {
        return this.http.post<any>({ body: memberFeedback, path: "api/Authentication/memberfeedback" });
    }
}
