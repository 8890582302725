import { IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent, } from '@rxweb/angular-router';
import { SubProjectsDesignFilterViewModel } from 'src/app/view-model/sub-project-design-filter-view-model';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { Project } from '@app/models';
import { Input } from '@angular/core';
import { AppGrid } from 'src/app/domain/app-grid';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

export class AbstractSubProject extends CoreComponent {
  subProjectDesignSearchFormGroup: IFormGroup<SubProjectsDesignFilterViewModel>;
  spin: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  showComponent: boolean = false;
  selectedTab: string;
  showSaveButton: boolean = false;
  fileViewModel: FileInputViewModel;
  fileViewModelDoc: FileInputViewModel;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  fileOutputViewModelDoc: FileOutputViewModel = new FileOutputViewModel();
  projectFormGroup: IFormGroup<Project>;
  dateError: boolean = false;
  dateDesignError: boolean = false;
  startDate: Date;
  endDate: Date;
  subProjectDesignListGrid: AppGrid;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  validField: string[] = ["projectName", "projectDescription", "projectStartDate", "budget"];
  breakLoop: boolean;
  constructor(private blobState: BlobUploadsViewStateService,) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.Image;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
    this.fileViewModelDoc = new FileInputViewModel();
    this.fileViewModelDoc.fileMode = FileTypeEnums.Doc;
    this.fileViewModelDoc.fileAllowSize = FileSizeEnums.FourMB;
  }



  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }

  async updateFiles($event: FileOutputViewModel) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = BLOB_BASE.ProjectFiles + `${newGUID}_${new Date().toISOString()}.${ $event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);
 
    let url = res._response.request.url;
    let uploadedFileName = BLOB_BASE.ProjectFiles  +decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );
    
    // IF SUCCEED TO UPLOAD
    if ( res && (res._response.status == 200 || res._response.status == 201)  ) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined){
        this.modalView
        .show(BedRequestComponent, {
          validationMessages: JSON.stringify(
            uploadedFileName
          ),
          headerMessage: "Image failed to upload:",
        })
        .then(() => {});
      } 
    }


    this.fileOutputViewModel = $event;
    this.projectFormGroup.patchValue({
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      displayImage: $event.displayImage,
      blobFileName:uploadedFileName,
      blobPath:BLOB_BASE.ProjectFiles,
      size:$event.files.size
    });
  }

  async updateDocFiles($event: FileOutputViewModel) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = BLOB_BASE.ProjectDocumentFiles + `${newGUID}_${new Date().toISOString()}.${ $event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);
 
    let url = res._response.request.url;
    let uploadedFileName = BLOB_BASE.ProjectDocumentFiles  +decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );
    
    // IF SUCCEED TO UPLOAD
    if ( res && (res._response.status == 200 || res._response.status == 201)  ) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined){
        this.modalView
        .show(BedRequestComponent, {
          validationMessages: JSON.stringify(
            uploadedFileName
          ),
          headerMessage: "Image failed to upload:",
        })
        .then(() => {});
      } 
    }
    
    this.fileOutputViewModelDoc = $event;
    this.projectFormGroup.patchValue({
      fileExtention1: $event.fileExtension,
      fileName1: $event.fileName,
      blobFileName2:uploadedFileName,
      blobPath2:BLOB_BASE.ProjectDocumentFiles,
      size2:$event.files.size
    });
  }

  removeImage() {
    this.projectFormGroup.patchValue({
      fileData: null,
      fileExtention: null,
      fileName: null,
      displayImage: false
    });

  }

  removeDoc() {
    this.projectFormGroup.patchValue({
      fileData1: null,
      fileExtention1: null,
      fileName1: null,
      filePath: null,
      budgetCostCertificate: null
    });
  }
}
