import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const HEADER_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                style:{
                    "margin":"0px"
                },
                class: ["mt-1","pull-left", "checkbox-custom-style"],
                 event: {
                  input: "onHeaderCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                   
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                
                   return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
};

export const HEADER_GEMSTONECHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                style:{
                    "margin":"0px"
                },
                class: ["mt-1","pull-left", "checkbox-custom-style"],
                 event: {
                  input: "onHeaderCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  id: function (e) {
                    return "Gemstone-checkbox";
                  },
                  value: function (e) {
                    //var a = this[e.name];
                   
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                
                   return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
};

export const HEADER_KnownToCheckBox_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                style:{
                    "margin":"0px"
                },
                class: ["mt-1","pull-left", "checkbox-custom-style"],
                 event: {
                  input: "onHeaderCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  id: function (e) {
                    return "KnownTo-checkbox";
                  },
                  value: function (e) {
                    //var a = this[e.name];
                   
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                
                   return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
};

export const HEADER_CHECKED_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                style:{
                    "margin":"0px"
                },
                class: ["mt-1","pull-left", "checkbox-custom-style"],
                 event: {
                  input: "onHeaderCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    return true;
                  },
                  checked: function (e) {
                   return true;
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
};
export const HEADER_INFORMATION_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["d-flex", "align-items-center", "mb-0"], // Added flexbox classes for alignment
          childrens: [
            {
              span: {
                class: ["d-flex", "align-items-center"], // Added flexbox classes for alignment
                childrens: [
                  {
                    text: {
                      text: function (e) {
                        return getLocalizedValue("KnownToAddComponent_IsClose_gh"); // The header text
                      }
                    }
                  },

                  {
                    i: {
                      class: ["fa", "fa-info-circle", "fa-lg", "tooltip-icon"], // Assuming you are using Font Awesome for the "I" icon
                      style: {
                        cursor: "pointer",
                        "margin-left": "5px" // Adjusted margin for proper spacing
                      },
                      event: {
                        mouseenter: "showTooltip",
                        mouseleave: "hideTooltip"
                      },
                      attributes: {
                        "data-toggle": "tooltip",
                        "data-placement": "center",
                        "data-html": "true",
                        title: function (e) {
                          return getLocalizedValue("KnownToAddComponent_IsClosetooltip_t");//getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t"); // The tooltip text
                        }
                      }
                    }
                  },
                ]
              }
            }
          ]
        }
      }
    ]
  }
};
export const HEADER_INFORMATION_TOOLTIPTABLE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["d-flex", "align-items-center", "mb-0"], // Added flexbox classes for alignment
          childrens: [
            {
              span: {
                class: ["d-flex", "align-items-center"], // Added flexbox classes for alignment
                childrens: [
                  {
                    text: {
                      text: function (e) {
                        return getLocalizedValue("CompanyCustomerMappingListComponent_Pgrade_gh"); // The header text
                      }
                    }
                  },

                  {
                    i: {
                      class: ["fa", "fa-info-circle", "fa-lg", "tooltip-icon"], // Assuming you are using Font Awesome for the "I" icon
                      style: {
                        cursor: "pointer",
                        "margin-left": "5px" // Adjusted margin for proper spacing
                      },
                      event: {
                        click: "openToolTip"
                      },
                      attributes: {
                        title: function (e) {
                          return getLocalizedValue("CompanyCustomerMappingListComponent_PTGChartT_t");//getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t"); // The tooltip text
                        }
                      }
                    }
                  },
                ]
              }
            }
          ]
        }
      }
    ]
  }
};
