import { gridColumn } from "@rxweb/grid";


export class Taskcomment {

  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 0, headerTitle: 'TaskCommentsId', keyColumn: true, allowSorting: false })
  taskCommentsId: number;

  @gridColumn({
    parameter: true,
    isAscending: false,
    style: { width: "20%", "text-align": "left" },
    class: ["text-left"],
    name: "createdOn",
    visible: true,
    columnIndex: 1,
    allowSorting: true,
    headerKey: "TaskReply_CreatedDate_gh",
    keyColumn: false,
  })
  createdOn: Date;

  @gridColumn({
    parameter: true,
    isAscending: false,
    style: { width: "20%", "text-align": "left" },
    class: ["text-left"],
    name: "createdby",
    visible: true,
    columnIndex: 2,
    allowSorting: true,
    headerKey: "TaskReply_Createdby_gh",
    keyColumn: false,
  })
  createdby: string;

  @gridColumn({
    template: {
      div: {
        style: {
          "width": "100%",
          "word-break": "break-word"
        },
        attributes: {
          innerHTML: function (x, y) {
            return "<label style='margin-left:0%;margin-bottom:0%'>" + this.comments + "</label>";
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.commentCount > 100) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                style: {
                  "margin-left": "0%"
                },
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClick"
            }
          },
        }]
      }
    },
    visible: true,
    columnIndex: 3,
    allowSorting: true,
    class: ["text-left"],
    headerKey: "TaskReply_Comments_gh",
    name: "comments",
    style: { width: "60%", "text-align": "left"},

  })
  taskComment: string;

  @gridColumn({
    parameter: true,
    name: "totalCount",
    visible: false,
    columnIndex: 13,
    allowSorting: true,
    headerKey: "totalCount",
    keyColumn: false,
  })
  totalCount: number;


  comments: any;
  commentCount: number
}