import {vSalesAnalysisByLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSalesAnalysisByLookUp extends vSalesAnalysisByLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}