import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { TaxRate, vCountryLookUp, vStateProvinceLookUp, vTaxCategory, vTaxCategoryLookUp } from '@app/models';
import { TAX_PROVIDER } from '../../../../custom-collections/tax-provider';
import { List } from '@rxweb/generics';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';

@http({
    path: "api/TaxRates"
})
export class AbstractTaxRate extends CoreComponent {
    taxRateFormGroup: IFormGroup<TaxRate>
    taxProviderClassses: List<any> = new List(TAX_PROVIDER);
    showComponent: boolean = false;
    stateProvinceLookUp: vStateProvinceLookUp[];
    showSelectOption: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    spin: boolean = false;
    taxRateLookUps: any = {
        countryLookUp: vCountryLookUp,
        stateProvinceLookUp: vStateProvinceLookUp,
        taxCategoryLookUp: vTaxCategoryLookUp,
        taxRate: TaxRate
    }
    validField: string[] = ["countryId","stateProvinceId","zip","taxCategoryId","percentage"];
    breakLoop: boolean;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    isEnableStateDropDown: boolean = true;
    uiSelection(taxProviderId: number, uiType: number) {
        return this.taxProviderClassses.any(x => x.isUIActive == true && x.uiType == uiType && x.id == taxProviderId)
    }
    selectState(stateId: number = 0) {
        this.showSelectOption=false;
        this.stateProvinceLookUp = this.taxRateLookUps.stateProvinceLookUp;
        this.stateProvinceLookUp = this.stateProvinceLookUp.filter(x => x.countryId == this.taxRateFormGroup.value.countryId);
        this.taxRateFormGroup.patchValue({ stateProvinceId: stateId })
        setTimeout(() => {
            this.showSelectOption=true;
        }, 100);
       
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }

}
//asdas
