import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { ProductPieceDetailComponent } from './detail/product-piece-detail.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { StockCardComponent } from './stock-card/stock-card.component';
import { SystemTableSettingModule } from '../../shared/system-table-settings/system-table-setting.module';
import { FileModule } from '../../shared/global/file/file.module';
import { PriceLabelComponent } from './price-label/price-label.component';
import { PrintEvaluationComponent } from './print-evaluation/print-evaluation.component';
import { PrintCertificateComponent } from './print-certificate/print-certificate.component';
import { DocumentSharedModule } from '../../document/document/document-shared.module';
import { SystemTimeSharedModule } from '../../shared/system-time/system-time.shared-module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { WebPictureSharedModule } from '../../document/web-pictures/web-picture-shared.module';
import { PrintBarcodeComponent } from './print-barcode/print-barcode.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, SystemTableSettingModule, ReactiveFormsModule, RouterModule, DocumentSharedModule, RxReactiveFormsModule, SharedModule,
        FileModule, WebPictureSharedModule, SystemTimeSharedModule, EditorModule, SystemTableSettingModule
    ],
    declarations: [ProductPieceDetailComponent, StockCardComponent, PriceLabelComponent, PrintEvaluationComponent, PrintCertificateComponent, PrintBarcodeComponent],
    providers: [],
    exports: [ProductPieceDetailComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductPieceSharedModule { }

