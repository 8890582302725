import { BookingProductBase, BookingBase, ProductPieceBase, ProductBase, } from '@app/database-models'
//Generated Imports
export class BookingProduct extends BookingProductBase {


    productVarientIds: BookingProduct[];
    tripLocationId: number;
    pieceStatusId: number;
    salesStatusId: number;
    productionStatusId: number;
    consignmentStatusId: number;
    ownerId: number;
    approveOrRejet: number;
    tripName: string;
    inspectedById: number;
    supplierDeliveryDate: Date;
    dutyStatusDate: Date;

    //#region Generated Reference Properties
    //#region booking Prop
    booking: BookingBase;
    //#endregion booking Prop
    //#region productPiece Prop
    productPiece: ProductPieceBase;
    //#endregion productPiece Prop
    //#region product Prop
    product: ProductBase;
    //#endregion product Prop

    //#endregion Generated Reference Properties

    pieceDeliveryStatusId: number;
    pieceDeliveryDate: Date;
    pieceShippedDate: Date;

    orderId: number;
    isPaidAndDelivered: boolean;
    serialNumber: string;
    pieceStatus: string;
  headerBtnChk: boolean;
  query: string;










}
