export enum WebPictureHeight{
  WebPicture = 1594,
  ZoomPicture = 1594,
  MobileZoom = 1500
}
export enum WebPictureWidth{
  WebPicture = 3000,
  ZoomPicture = 1938,
  MobileZoom = 1500
}
