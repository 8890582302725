import {vShippingMethodLookUpBase,} from '@app/database-models'
//Generated Imports
export class vShippingMethodLookUp extends vShippingMethodLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}