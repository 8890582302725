import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductSketchBase {

//#region productVariantSketcheId Prop
        @prop()
        productVariantSketcheId : number;
//#endregion productVariantSketcheId Prop


//#region productVariantID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID : number;
//#endregion productVariantID Prop


//#region pictureID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID : number;
//#endregion pictureID Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}