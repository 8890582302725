import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { AppGrid } from "src/app/domain/app-grid";
import { DocumentTypeUserMapping } from "src/app/models/extended-models/document-type-user-mapping";
import { vSalesUserLookUpBase } from "src/app/models/database-models/v-sales-user-look-up";

@http({
    path: "api/DocumentTypeUserMappings",
})
export class AbstractDocumentTypeUserMapping extends CoreComponent {
    documentTypeUserMappingFormGroup: IFormGroup<DocumentTypeUserMapping>
    showComponent: boolean = false;
    spin: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr;
    dialog: BaseDialog;
    documentTypeUserGrid: AppGrid;
    isShowGrid:boolean;
    isNoRecordFound : boolean;
    documentTypeCorporateMappingLookup: any = {
        salesAdvisorLookUp: vSalesUserLookUpBase
    }
    dateError: boolean = false;
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
}
