<div *rxMultilingual="" >
<div class="panel-body" *ngIf="showComponent" [rxLocalisationInit]="componentName" [rxSpinner]="spin">
  <div class="row py-3">
    <div class="col-lg-6 col-xl-4">
      <div class="form-group">
        <div class="form-group">
          <form>
            <div class="i-checks" [formGroup]="helpSupportFormGroup" *ngFor="let helpSupportFormGroup of helpSupportUserRoles; let i = index">
              <label class="mb-0">
                <input type="checkbox" formControlName="isActive">
                <i></i>
                <span class="ml-3">{{getRoleName(helpSupportFormGroup.value.userRoleId)}}</span>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
  <hr>
  <div class="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-end align-items-center">
    <button type="button" (click)="manageUserRoles(false)" class="btn btn-success ml-2 my-1" rxText="Btn_Save_t"></button>
    <button type="button" (click)="manageUserRoles(true)" class="btn btn-primary ml-2 my-1" rxText="Btn_Save_Continue_Edit_t"></button>
  </div>
</div>
</div>