import { gridColumn } from '@rxweb/grid';

export class SummaryDetailViewModel{
    @gridColumn({visible: false, columnIndex: 9, headerKey: 'userId', keyColumn: true })
    userId:number;

    @gridColumn({visible: true, columnIndex: 1, headerKey: 'SummaryDetailsComponent_fullName_gh', keyColumn: false })
    fullName:string;

    @gridColumn({visible: true, columnIndex: 3, headerKey: 'SummaryDetailsComponent_introducedBy_gh', keyColumn: false })
    introducedBy:string;

    @gridColumn({visible: true, columnIndex: 2, headerKey: 'SummaryDetailsComponent_salesAdvisor_gh', keyColumn: false })
    salesAdvisor:string;

    totalCount:number;
}