import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SupplierPictureBase {

//#region supplierPictureId Prop
        @prop()
        supplierPictureId : number;
//#endregion supplierPictureId Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region pictureId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureId : number;
//#endregion pictureId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region fileName Prop
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop


//#region createdOn Prop
        @prop()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop


//#region type Prop
        @prop()
        type : number;
//#endregion type Prop


//#region comment Prop
        @prop()
        comment : string;
//#endregion comment Prop





}