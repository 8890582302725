import { http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from '../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../domain/customize-design/dialog';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { RootUserMindMap } from './root-user-mind-map';
import { RootCorporateMindMap } from './root-corporate-mind-map';
import { RootEventMindMap } from './root-event-mind-map';
import { RootProjectMindMap } from './root-project-mind-map';
import { RootOrderMindMap } from './root-order-mind-map';
import { RootPressMediaMindMap } from './root-press-media-mind-map';
import { MindMapFilterViewModel } from 'src/app/view-model/mind-map-filters-view-model';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { MindMapMain } from 'src/assets/mind-map/dist/index';
import { ChangeDetectorRef } from '@angular/core';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { DEFAULT_THUMBNAIL_IMAGE_URL } from "src/app/domain/system-constant";

const HIERARCHY_RULES = {
    ROOT: {
        name: 'ROOT',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        color: "#000000",
        getChildren: () => [
            HIERARCHY_RULES.PROJECTS,
            HIERARCHY_RULES.EVENTS,
            HIERARCHY_RULES.HSE,
            HIERARCHY_RULES.KNOWNTOS,
            HIERARCHY_RULES.ORDERS,
            HIERARCHY_RULES.GROUPCOMPANIES,
            HIERARCHY_RULES.OWNERSHIP,
            HIERARCHY_RULES.LEGALAGREEMENT,
            HIERARCHY_RULES.MYDIRECTTEAM,
            HIERARCHY_RULES.PRESSMEDIA,
            HIERARCHY_RULES.PEOPLEDIRECTOR,
            HIERARCHY_RULES.RISK,
            HIERARCHY_RULES.PEOPLECORPORATEINVOLVED,
            HIERARCHY_RULES.INVITEESATTENDEES,
            HIERARCHY_RULES.PRODUCTPIECES,
            HIERARCHY_RULES.PRESSMEDIAPEOPLE,
            HIERARCHY_RULES.PRESSMEDIAJOURNALIST,
            HIERARCHY_RULES.PRESSMEDIACORPORATE,
            HIERARCHY_RULES.PRESSMEDIAPUBLICATION,
            HIERARCHY_RULES.PRESSMEDIAPRODUCT,
            HIERARCHY_RULES.SUBPROJECT,
            HIERARCHY_RULES.EMPLOYMENT,
            HIERARCHY_RULES.DIRECTORSHIP,
            HIERARCHY_RULES.PICTUREVIDEO
        ]
    },

    SUBPROJECT: {
        name: 'SUBPROJECT',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.SUBPROJECTLIST
        ]
    },
    PRESSMEDIAPEOPLE: {
        name: 'PRESSMEDIAPEOPLE',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRESSMEDIAPEOPLELIST
        ]
    },
    PRESSMEDIAJOURNALIST: {
        name: 'PRESSMEDIAJOURNALIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRESSMEDIAJOURNALISTLIST
        ]
    },
    PRESSMEDIACORPORATE: {
        name: 'PRESSMEDIACORPORATE',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRESSMEDIACORPORATELIST
        ]
    },
    PRESSMEDIAPUBLICATION: {
        name: 'PRESSMEDIAPUBLICATION',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRESSMEDIAPUBLICATIONLIST
        ]
    },
    PRESSMEDIAPRODUCT: {
        name: 'PRESSMEDIAPRODUCT',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRESSMEDIAPRODUCTLIST
        ]
    },
    PRODUCTPIECES: {
        name: 'PRODUCTPIECES',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRODUCTPIECESLIST
        ]
    },
    PEOPLECORPORATEINVOLVED: {
        name: 'PEOPLECORPORATEINVOLVED',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PEOPLECORPORATEINVOLVEDLIST
        ]
    },
    INVITEESATTENDEES: {
        name: 'INVITEESATTENDEES',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.INVETEESATTENDEESLIST
        ]
    },
    PRESSMEDIA: {
        name: 'PRESSMEDIA',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PRESSMEDIALIST
        ]
    },
    PEOPLEDIRECTOR: {
        name: 'PEOPLEDIRECTOR',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PEOPLEDIRECTORLIST
        ]
    },
    RISK: {
        name: 'RISK',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.RISKLIST
        ]
    },
    GROUPCOMPANIES: {
        name: 'GROUPCOMPANIES',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.GROUPCOMPANIESLIST
        ]
    },
    OWNERSHIP: {
        name: 'OWNERSHIP',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.OWNERSHIPLIST
        ]
    },
    LEGALAGREEMENT: {
        name: 'LEGALAGREEMENT',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.LEGALAGREEMENTLIST
        ]
    },
    MYDIRECTTEAM: {
        name: 'MYDIRECTTEAM',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.MYDIRECTTEAMLIST
        ]
    },
    HSE: {
        name: 'HSE',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.HSELIST,
        ]
    },
    KNOWNTOS: {
        name: 'KNOWNTOS',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.KNOWNTOLIST,
        ]
    },
    ORDERS: {
        name: 'ORDERS',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.ORDERLIST,
        ]
    },
    PROJECTS: {
        name: 'PROJECTS',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.PROJECTLIST,
        ]
    },
    EVENTS: {
        name: 'EVENTS',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.EVENTLIST
        ]
    },
    EMPLOYMENT: {
        name: 'EMPLOYMENTS',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.EMPLOYMENTLIST
        ]
    },
    DIRECTORSHIP:{
        name: 'DIRECTORSHIP',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.DIRECTORSHIPLIST
        ]
    },
    PICTUREVIDEO:{
    name: 'PICTUREVIDEO',
    color: '#000000',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    getChildren: () => [
        HIERARCHY_RULES.PICTUREVIDEOLIST
    ]
},

    SUBPROJECTLIST: {
        name: 'SUBPROJECTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRESSMEDIAPEOPLELIST: {
        name: 'PRESSMEDIAPEOPLELIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRESSMEDIAJOURNALISTLIST: {
        name: 'PRESSMEDIAJOURNALISTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRESSMEDIACORPORATELIST: {
        name: 'PRESSMEDIACORPORATELIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRESSMEDIAPUBLICATIONLIST: {
        name: 'PRESSMEDIAPUBLICATIONLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRESSMEDIAPRODUCTLIST: {
        name: 'PRESSMEDIAPRODUCTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRODUCTPIECESLIST: {
        name: 'PRODUCTPIECESLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PEOPLECORPORATEINVOLVEDLIST: {
        name: 'PEOPLECORPORATEINVOLVEDLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    INVETEESATTENDEESLIST: {
        name: 'INVETEESATTENDEESLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PRESSMEDIALIST: {
        name: 'PRESSMEDIALIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PEOPLEDIRECTORLIST: {
        name: 'PEOPLEDIRECTORLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    RISKLIST: {
        name: 'RISKLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    LEGALAGREEMENTLIST: {
        name: 'LEGALAGREEMENTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    MYDIRECTTEAMLIST: {
        name: 'MYDIRECTTEAMLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    HSELIST: {
        name: 'HSELIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    GROUPCOMPANIESLIST: {
        name: 'GROUPCOMPANIESLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    OWNERSHIPLIST: {
        name: 'OWNERSHIPLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    KNOWNTOLIST: {
        name: 'KNOWNTOLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    ORDERLIST: {
        name: 'ORDERLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PROJECTLIST: {
        name: 'PROJECTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [
            HIERARCHY_RULES.ROOT,
        ]
    },
    EVENTLIST: {
        name: 'EVENTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    EMPLOYMENTLIST: {
        name: 'EMPLOYMENTLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    DIRECTORSHIPLIST:{
        name: 'DIRECTORSHIPLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    },
    PICTUREVIDEOLIST:{
        name: 'PICTUREVIDEOLIST',
        color: '#000000',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        getChildren: () => [HIERARCHY_RULES.ROOT]
    }
};

const option = {
    container: 'jsmind_container',
    theme: 'normal',
    editable: false,
    selectable: false,
    mode: 'full',
    depth: 9999999,
    layout: {
        hspace: 60, 			// horizontal spacing between nodes
        vspace: 60, 			// vertical spacing between nodes
        pspace: 10  		// Horizontal spacing between node and connection line (to place node expander)
    },
    view: {
        vmargin: 10 // verticle margin
        // lineColor: 'red',
    },
    hierarchyRule: HIERARCHY_RULES,
    enableDraggable: false,
};

@http({
    path: 'api/Users'
})
export class AbstractMindMap extends CoreComponent {

    showComponent: boolean;
    toastr: BaseToastr;
    dialog: BaseDialog;
    spin: boolean = false;
    mindMapData = null;
    mindMapFormGroup: IFormGroup<MindMapFilterViewModel>;
    rootEntityName: string = '';
    ProfileimageUrl:string = "";
    moduleMindMapObj;
    mindMap;
    isFullScreen = false;
    mindMapVisible = false;
    modouleIdObj = {
        userModuleId: RolePermissionEnums.User,
        corporateModuleId: RolePermissionEnums.Supplier,
        eventModuleId: RolePermissionEnums.Event,
        projectModuleId: RolePermissionEnums.Project,
        pressMediaModuleId: RolePermissionEnums.PressandMedia,
        orderModuleId: RolePermissionEnums.SalesOrders
    }


    titleZoomIn: string = '';
    titleZoomOut: string = '';
    titleResetZoom: string = '';
    titleBackToRoot: string = '';
    titleFullScreen: string = '';
    browserStorage: BrowserStorage;
    defaultThumbnailPath: string = DEFAULT_THUMBNAIL_IMAGE_URL;

    constructor(private mindMapService: MindMapService, private chRef: ChangeDetectorRef) {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        Window['AbstractMindMap'] = this;
        this.mindMapService.customSpinnerSubject.subscribe(x => {
            this.spin = x;
            this.chRef.detectChanges();
        });
        this.titleZoomIn = getLocalizedValue("title_zoom_in");
        this.titleZoomOut = getLocalizedValue("title_zoom_out");
        this.titleResetZoom = getLocalizedValue("title_reset_zoom");
        this.titleBackToRoot = getLocalizedValue("title_center_root");
        this.titleFullScreen = getLocalizedValue("title_fullscreen");
        this.browserStorage = new BrowserStorage();
    }

    setupMindMap(entityTypeId, entityId, imageUrl) {
        this.spin = true;
        if (entityTypeId > 0 && entityId > 0) {
            this.post({ path: 'api/MindMapSearch/GetMindMapData', body: { query: JSON.stringify({ rootEntityTypeId: entityTypeId, rootEntityId: entityId }) } }).subscribe(async (response) => {
                this.mindMapData = (response as any[]).length > 0 ? response[0] : null;


                if (entityTypeId == RolePermissionEnums.User) {
                    this.moduleMindMapObj = new RootUserMindMap(this.mindMapService);
                    await this.moduleMindMapObj.setupUserMindMap(this.mindMapData, this.rootEntityName ,imageUrl);
                }

                else if (entityTypeId == RolePermissionEnums.Supplier) {
                    this.moduleMindMapObj = new RootCorporateMindMap(this.mindMapService);
                    await this.moduleMindMapObj.setupCorporateMindMap(this.mindMapData, this.rootEntityName , imageUrl);
                }

                else if (entityTypeId == RolePermissionEnums.Event) {
                    this.moduleMindMapObj = new RootEventMindMap(this.mindMapService);
                    await this.moduleMindMapObj.setupEventMindMap(this.mindMapData, this.rootEntityName, imageUrl);
                }

                else if (entityTypeId == RolePermissionEnums.Project) {
                    this.moduleMindMapObj = new RootProjectMindMap(this.mindMapService);
                    await this.moduleMindMapObj.setupProjectMindMap(this.mindMapData, this.rootEntityName, imageUrl);
                }

                else if (entityTypeId == RolePermissionEnums.SalesOrders) {
                    this.moduleMindMapObj = new RootOrderMindMap(this.mindMapService);
                    await this.moduleMindMapObj.setupOrderMindMap(this.mindMapData, this.rootEntityName, imageUrl);
                }

                else if (entityTypeId == RolePermissionEnums.PressandMedia) {
                    this.moduleMindMapObj = new RootPressMediaMindMap(this.mindMapService);
                    await this.moduleMindMapObj.setupPressMediaMindMap(this.mindMapData, this.rootEntityName, imageUrl);
                }
                this.spin = false;

                if (this.mindMapService.graphId == 1) {
                    let imageSize = 95;
                    document.getElementById("jsmind_container").innerHTML = '';
                    this.mindMap = MindMapMain.show(option, this.mindMapService.mindMapObject);
                    var root = document.querySelector(".root");
                    if (root != undefined && root != null) {
                        (root as HTMLElement).style.setProperty('overflow',"unset", "important");
                    }
                    var rootHeading = document.querySelector(".root > h2");
                    if (rootHeading != undefined && rootHeading != null) {
                        (rootHeading as HTMLElement).style.whiteSpace = "break-spaces";
                    }
                    var rootProfilePictureLink = document.querySelector(".root > h2 > a");
                    if (rootProfilePictureLink != undefined && rootProfilePictureLink != null) {
                        var rootProfilePicture = document.querySelector(".root > h2 > a > img");
                        if (rootProfilePicture != undefined && rootProfilePicture != null) {
                            (rootProfilePicture as HTMLElement).style.setProperty('border-radius', "50%", "important");
                            (rootProfilePicture as HTMLElement).style.position = "absolute";
                            (rootProfilePicture as HTMLElement).style.width = `${imageSize}px`;
                            (rootProfilePicture as HTMLElement).style.height = `${imageSize}px`;
                            (rootProfilePicture as HTMLElement).style.top = `${-imageSize/2}px`;
                            (rootProfilePicture as HTMLElement).style.left = `${((root as HTMLElement).offsetWidth - imageSize + ((root as HTMLElement).clientWidth < 500 ? 40 : 0))/2}px`;
                            (rootProfilePicture as HTMLElement).style.setProperty('margin',"0px", "important");
                            (rootProfilePictureLink as HTMLElement).style.padding = `${imageSize/2}px 20px 0px`;
                        }
                    }
                    this.mindMapService.zoomPercentage = 100;
                }
                else {
                    this.mindMap.enableEdit();
                    try {
                        var newSubRoot = '';
                        Object.keys(this.mindMapService.tempSubgraphData).forEach(key => {
                            if (this.mindMapService.tempSubgraphData[key] != null) {
                                var node = this.mindMap.getNode(this.mindMapService.tempSubgraphData[key].parentNodeId);
                                if (node != null) {
                                    this.mindMap.addNode(
                                        node,
                                        this.mindMapService.tempSubgraphData[key].nodeId,
                                        this.mindMapService.tempSubgraphData[key].topic,
                                        this.mindMapService.tempSubgraphData[key].data
                                    );
                                    if (key.includes("List")) {
                                        this.mindMap.collapseNode(node);
                                    }
                                    if (key == "root") {
                                        newSubRoot = node.id
                                    }
                                }
                            }
                        });
                        if (newSubRoot != '') {
                            this.rootInCenter(newSubRoot);
                        }
                        this.mindMap.disableEdit();
                        var rootHeadingList = document.querySelectorAll("jmnode > h2");
                        rootHeadingList.forEach(rootHeading => {
                            if (rootHeading != undefined && rootHeading != null) {
                                (rootHeading as HTMLElement).style.whiteSpace = "break-spaces";
                            }
                        });
                    } catch (error) {
                        this.mindMap.disableEdit();
                        this.mindMapService.customSpinnerSubject.next(false);
                    }
                }


                this.mindMapService.graphId++;
                this.applyDragScroll();
                //this.changeNodePosition();
                this.mindMapService.customSpinnerSubject.next(false);
                this.spin = false;
            });
        }
        else {
            this.spin = false;
        }
    }

    onVideoError(event:any){
        let imgElement = document.createElement("img");
        imgElement.setAttribute("src", this.defaultThumbnailPath);
        imgElement.setAttribute("style", 'width: 130px !important; height: 80px !important;');
        event.target.parentElement.parentElement.appendChild(imgElement);
        event.target.parentElement.parentElement.removeChild(event.target.parentElement);
      }

      onImageError(event:any){
        event.target.src=this.defaultThumbnailPath;
      }

    expandNewGraph(moduleId: number, entityId: number, entityName: string, nodeId: string) {
        this.rootEntityName = entityName;
        var node = this.mindMap.getNode(nodeId);
        var isNodeExists = false;
        if (node != null && node.children.length > 0) {
            (node.children as any[]).forEach(childNode => {
                if (childNode.id.split('_')[1] == entityId && childNode.id.split('_')[3] == moduleId) {
                    this.mindMap.enableEdit();
                    this.mindMap.removeNode(childNode);
                    this.mindMap.disableEdit();
                    document.querySelectorAll('i[data-icon-id="' + nodeId + '-' + entityId + '-' + moduleId + '"]').forEach(x => {
                        x.classList.add('fa-eye');
                        x.classList.remove('fa-eye-slash');
                    });
                    isNodeExists = true;
                }
            });
        }
        if (!isNodeExists) {
            this.mindMapService.parentNodeToExpand = nodeId;
            this.mindMapService.resetTempSubGraphData();
            this.setupMindMap(moduleId, entityId , "");
            document.querySelectorAll('i[data-icon-id="' + nodeId + '-' + entityId + '-' + moduleId + '"]').forEach(x => {
                x.classList.remove('fa-eye');
                x.classList.add('fa-eye-slash');
            });
        }
    }

    showFullscreenMap() {
        var elem = document.getElementById("mind-map-area");
        if (!this.isFullScreen) {
            if (this.requestFullScreen(elem)) {
                this.isFullScreen = true;
            }
        }
        else {
            document.exitFullscreen();
            this.isFullScreen = false;
        }

    }

    applyDragScroll() {
        const ele = document.getElementsByClassName("jsmind-inner")[0] as HTMLElement;
        ele.style.cursor = 'grab';

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e) {
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none';

            pos = {
                left: ele.scrollLeft,
                top: ele.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function () {
            ele.style.cursor = 'grab';
            ele.style.removeProperty('user-select');

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        // Attach the handler
        ele.removeEventListener('mousedown', mouseDownHandler);
        ele.addEventListener('mousedown', mouseDownHandler);
    }

    /*** NOT FUNCTIONAL ***/
    // changeNodePosition() {
    //     const ele = document.getElementsByTagName("jmnode")[0] as HTMLElement;
    //     const containerElement = document.getElementsByClassName("jsmind-inner")[0] as HTMLElement;
    //     ele.style.cursor = 'grab';

    //     let pos = { top: 0, left: 0, x: 0, y: 0 };

    //     const mouseDownHandler = function (e) {
    //         ele.style.cursor = 'grabbing';
    //         ele.style.userSelect = 'none';

    //         pos = {
    //             left: ele.scrollLeft,
    //             top: ele.scrollTop,
    //             // Get the current mouse position
    //             x: e.clientX,
    //             y: e.clientY,
    //         };

    //         document.addEventListener('mousemove', mouseMoveHandler);
    //         document.addEventListener('mouseup', mouseUpHandler);
    //     };

    //     const mouseMoveHandler = function (e) {
    //         const dx = e.clientX - pos.x;
    //         const dy = e.clientY - pos.y;

    //         ele.style.top = (e.clientY - containerElement.getBoundingClientRect().y + containerElement.scrollTop).toString() + "px";
    //         ele.style.left = (e.clientX - containerElement.getBoundingClientRect().x + containerElement.scrollLeft - (ele.getBoundingClientRect().width/2)).toString() + "px";
    //         document.getElementById("redraw").click();
    //     };

    //     const mouseUpHandler = function () {
    //         ele.style.cursor = 'grab';
    //         ele.style.removeProperty('user-select');

    //         document.removeEventListener('mousemove', mouseMoveHandler);
    //         document.removeEventListener('mouseup', mouseUpHandler);
    //     };

    //     // Attach the handler
    //     ele.removeEventListener('mousedown', mouseDownHandler);
    //     ele.addEventListener('mousedown', mouseDownHandler);
    // }

    // reDrawLines() {
    //     this.mindMap.reDrawLines();
    // }

    requestFullScreen(element) {
        // Supports most browsers and their versions.
        var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
        if (requestMethod) { // Native full screen.
            requestMethod.call(element);
            return true;
        }
        else {
            return false;
        }
    }

    async expandCollapseList(nodeId) {
        var node = this.mindMap.getNode(nodeId);
        if (node != null && node != undefined) {
            if (node.expanded) {
                this.mindMap.collapseNode(node);
            }
            else {
                this.mindMap.expandNode(node);
            }
        }
    }

    zoomInOut(action: number) {
        if (document.getElementsByClassName("jsmind-inner").length > 0) {
            if (action == 1) {
                if (this.mindMapService.zoomPercentage < 120) {
                    this.mindMapService.zoomPercentage += 10;
                    this.mindMap.zoomIn();
                    this.mindMap.resize();
                }
                else {
                    this.toastr.warning("Zoom In limit reached.");
                }
            }
            if (action == 0) {
                if (this.mindMapService.zoomPercentage > 20) {
                    this.mindMapService.zoomPercentage -= 10;
                    this.mindMap.zoomOut();
                }
                else {
                    this.toastr.warning("Zoom Out limit reached.");
                }
            }
            if (action == 100) {
                this.mindMapService.zoomPercentage = 100;
                this.mindMap.resetZoom();
            }
        }
    }

    rootInCenter(nodeId = "") {
        if (document.getElementsByClassName("jsmind-inner").length > 0) {
            if (nodeId == "") {
                (document.getElementsByClassName("root")[0] as HTMLElement).scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
            else {
                (document.querySelector(`jmnode[nodeid="${nodeId}"]`) as HTMLElement).scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        }

    }
}
