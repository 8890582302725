import {ProductDesignBase,ProductTemplateBase,ProductDesignerMappingBase,ProductBase,ProductDesignSketchBase,RelatedProductDesignBase,ProductLocalizedBase,ProductDesignPictureBase,ProductSpecificationAttributeBase,ProductCategoryMappingBase,} from '@app/database-models'
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop, required, range } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ProductDesign extends ProductDesignBase implements FileDetailViewModel
{

    @prop()
    categoryName:string;

//#region Generated Reference Properties
//#region productTemplate Prop
productTemplate : ProductTemplateBase;
//#endregion productTemplate Prop
//#region productDesignerMappings Prop
productDesignerMappings : ProductDesignerMappingBase[];
//#endregion productDesignerMappings Prop
//#region products Prop
products : ProductBase[];
//#endregion products Prop
//#region productDesignSketches Prop
productDesignSketches : ProductDesignSketchBase[];
//#endregion productDesignSketches Prop
//#region relatedProductDesigns Prop
relatedProductDesigns : RelatedProductDesignBase[];
//#endregion relatedProductDesigns Prop
//#region productLocalized Prop
productLocalized : ProductLocalizedBase[];
//#endregion productLocalized Prop
//#region productDesignPictures Prop
productDesignPictures : ProductDesignPictureBase[];
//#endregion productDesignPictures Prop
//#region productSpecificationAttributes Prop
productSpecificationAttributes : ProductSpecificationAttributeBase[];
//#endregion productSpecificationAttributes Prop
//#region productCategoryMappings Prop
productCategoryMappings : ProductCategoryMappingBase;
//#endregion productCategoryMappings Prop

//#endregion Generated Reference Properties
@prop()
collectionIds:string;
@prop()
designerIds:string;

@prop()
@required({message:"Please select Category"})
category:number;

@prop()
fileData: string;
@prop()
fileExtention: string;
@prop()
fileName: string;
@prop()
displayImage: boolean;
@prop()
imageURL1: string;
@prop()
imageURL2: string;
@prop()
imageURL3: string;
@prop()
imageURL4: string;
@prop()
imageURL5: string;
@prop()
fileData1: string;
@prop()
fileExtention1: string;
@prop()
fileName1: string;
@prop()
fileData2: string;
@prop()
fileExtention2: string;
@prop()
fileName2: string;

@prop({defaultValue:7})
@required()
@range({minimumNumber:7,maximumNumber:2313213214})
languageId:number;

@prop({defaultValue: false})
isRemovedFabergeCertificate: boolean;

@prop({defaultValue: false})
isRemovedPriceResearch: boolean;
    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;
}