import {vBoutiqueLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBoutiqueLookUp extends vBoutiqueLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}