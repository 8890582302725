import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingGemstoneGemFieldBase {

//#region tripGemstoneId Prop
        @prop()
        tripGemstoneId : number;
//#endregion tripGemstoneId Prop


//#region tripGemstoneGUID Prop
        @required()
        tripGemstoneGUID : any;
//#endregion tripGemstoneGUID Prop


//#region tripId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        tripId : number;
//#endregion tripId Prop


//#region gemstoneId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemstoneId : number;
//#endregion gemstoneId Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop


//#region sold Prop
        @prop()
        sold : boolean;
//#endregion sold Prop


//#region customerId Prop
        @prop()
        customerId : number;
//#endregion customerId Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop





}