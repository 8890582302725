import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { HelpSupport } from '@app/models';
import { vParentHelpSupportLookUp } from "@app/models";
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent, } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { TINY_MCE_KEY } from '../../../../domain/system-constant';

@http({
  path: "api/HelpSupports",
})
export class AbstractHelpSupport extends CoreComponent {
  apiKey: any = TINY_MCE_KEY;
  helpSupportFormGroup: IFormGroup<HelpSupport>
  showComponent: boolean = false;
  helpSupportLookups: any = {
    parentHelpSupportLookUp: vParentHelpSupportLookUp,
    helpSupport: HelpSupport
  }
  currentActiveTab: string = "help-supports";
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  spin: boolean = false;

  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }

  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }



}
