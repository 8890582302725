import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const IMAGE_VIEW_GRID_TEMPLATE: TemplateConfig = {
    img: {
        attributes: {
            src: function (e) {
                return this[e.name]
            },
        },
        class: ["grid-image"],
        
        style: { width: "90px",  "max-height": "100%" }
    }
}

export const BARCODE_IMAGE_VIEW_GRID_TEMPLATE: TemplateConfig = {
    a: {
        attributes: {
            href: function (e) {
                return this[e.name]
            },
            type: "image/*",

            "data-gallery": ""
        },
        childrens: [{
            div: {
                class: ["picture-tab-div"]
            },
            img: {
                attributes: {
                    src: function (e) {
                        return this[e.name]
                    },
                    title: function (e) {
                        return getLocalizedValue("Title_Barcode_Image")
                    }
                },
                event: {
                    error: "onBarcodeImgError"
                },
                style: { width: "90px", "max-height": "100%", "cursor": "pointer" },
                class: ["grid-image"]
            }
        }],
        event: {
            click: "onBarcodeImgLoad"
        },
    }
}
