import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'viewModel' }, columnIndex: 14, headerTitle: "Action"
})

export class TravelListModel {

    //#region masterId 
    @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'masterId', keyColumn: true })
    masterId: number;
    //#endregion masterId 

    //#region userName Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'TravelListComponent_UserName_gh', keyColumn: false })
    userName: string;
    //#endregion userName Prop

    //#region StartDate 
    @gridColumn({ isAscending: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 5, allowSorting: true, headerKey: 'TravelListComponent_StartDate_gh', keyColumn: false, parameter : false })
    startDate: Date;
    //#endregion StartDate 


    //#region EndDate 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 7, allowSorting: true, headerKey: 'TravelListComponent_EndDate_gh', keyColumn: false, parameter : false })
    endDate: Date;
    //#endregion EndDate 

    //#region postedOn 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: "dateDispay" }, columnIndex: 8, allowSorting: true, headerKey: 'TravelListComponent_PostedOn_gh', keyColumn: false, parameter : false })
    createdOn: Date;
    //#endregion postedOn


    //#region days 
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex: 9, allowSorting: true, headerKey: 'TravelListComponent_Days_gh', keyColumn: false ,parameter : false})
    days: number;
    //#endregion days 


    //#region budget
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex: 10, allowSorting: true, headerKey: 'TravelListComponent_Budget_gh', keyColumn: false, parameter : false })
    budget: number
    //#endregion budget

    //#region currency
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], columnIndex: 11, allowSorting: true, headerKey: 'TravelListComponent_Currency_gh', keyColumn: false, parameter : false })
    currency: number
    //#endregion currency


    //#region citiesMinesLocations
    @gridColumn({ style: { width: "10%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], columnIndex: 12, allowSorting: true, headerKey: 'TravelListComponent_CitiesMinesLocations_gh', keyColumn: false , parameter : false })
    citiesMinesLocations: string
    //#endregion citiesMinesLocations


    //#region stStatus 
    @gridColumn({ style: { width: "7%", "text-align": "initial" }, class: ["text-left"], columnIndex: 13, allowSorting: false, headerKey: 'TravelListComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName: "leaveStatus" }, parameter : false })
    status: number;
    //#endregion stStatus    

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop




    //#region managerId 
    @prop()
    managerId: number;
    //#endregion managerId

    //#region managerName 
    managerName: string;
    //#endregion managerName   


    //#region leaveMasterEventId 
    leaveMasterEventId: string
    //#endregion leaveMasterEventId

    //#region leaveMasterEventId 
    leaveMasterProjectId: string
    //#endregion leaveMasterEventId

    //#region userId 
    @prop()
    userId: number
    //#endregion userId

    //#region startSession 
    startSession: number
    //#endregion startSession

    //#region endSession 
    endSession: number
    //#endregion endSession

    //#region currency
    //currency: number
    //#endregion currency

    //#region budget
    //budget: number
    //#endregion budget

    //#region budgetInUSD
    budgetInUSD: number
    //#endregion budgetInUSD

    //#region citiesMinesLocations
    //citiesMinesLocations: string
    //#endregion citiesMinesLocations

    //#region comments
    comments: string
    //#endregion comments

    //#region attachment
    attachment: string
    //#endregion attachment

    //#region fileUrl
    fileUrl: string
    //#endregion

    //#region leaveUserFullName
    leaveUserFullName: string;
    //#endregion leaveUserFullName


    //#region isVisibleTravelType
    @prop()
    isDomesticTravel: boolean;
    //#endregion isVisibleTravelType

    //#region reason
    @prop()
    reason: string;
    //#endregion reason

    //#region cancel
    hrCanCancelLeave: boolean;
    //#endregion cancel

    @prop()
    leaveId: number;

    @prop()
    leaveTypeName: string;

    @prop()
    openingBalance: number;

    @prop()
    holidayAlloted: number;

    @prop()
    totalEntitlement: number;

    @prop()
    availed: number;

    @prop()
    balance: number;

    @prop()
    weekDays: string;

    @prop()
    reportTo: number;

    @prop()
    reportToName: string;

    @prop()
    reportToIds: string;

    @prop()
    reportToNames: string;

    @prop({ defaultValue: false })
    isVisibleTravelType: boolean;

    @prop()
    reportToNamesWithHOD: string;

    @prop()
    typeColor: string;


    roleId:number





}