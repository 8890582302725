import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ACLMenuBase {

//#region aCLId Prop
        @prop()
        aCLId : number;
//#endregion aCLId Prop


//#region customerActionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerActionId : number;
//#endregion customerActionId Prop


//#region customerDomainId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerDomainId : number;
//#endregion customerDomainId Prop


//#region allow Prop
        @required()
        allow : boolean;
//#endregion allow Prop



}