import {InvoiceBatchMappingBase,ProductBatchBase,InvoiceBase,} from '@app/database-models'
//Generated Imports
export class InvoiceBatchMapping extends InvoiceBatchMappingBase 
{




//#region Generated Reference Properties
//#region productBatch Prop
productBatch : ProductBatchBase;
//#endregion productBatch Prop
//#region invoice Prop
invoice : InvoiceBase;
//#endregion invoice Prop

//#endregion Generated Reference Properties











}