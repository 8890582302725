import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GrievanceCategoryBase {

//#region grievanceCategoryId Prop
    @prop()
    grievanceCategoryId : number;
//#endregion grievanceCategoryId Prop

//#region grievanceCategoryName Prop
    @prop()
    grievanceCategoryName : string;
//#endregion grievanceCategoryName Prop

//#region active Prop
    @prop()
    @required()
    active : boolean;
//#endregion active Prop

//#region deleted Prop
    @prop()
    deleted : boolean;
//#endregion deleted Prop

//#region createdOn Prop
    @required()
    createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
    @prop()
    createdBy : number;
//#endregion createdBy Prop




}