import { prop, propArray } from "@rxweb/reactive-form-validators"
import { LeaveFlightMappingBase } from "../models/database-models/leave-flight-mapping-base";
import { LeaveAccomodationMappingBase } from "../models/database-models/leave-accomodation-mapping-base";
import { LeaveVehicleMappingBase } from "../models/database-models/leave-vehicle-mapping-base";

export class HolidayInfoViewModel {

        //#region masterId Prop
        @prop()
        masterId: number;
        //#endregion masterId Prop


        //#region leaveTypeName Prop
        @prop()
        leaveTypeName: string;
        //#endregion leaveTypeName Prop


        //#region createdOn Prop
        @prop()
        createdOn: Date;
        //#endregion createdOn Prop


        //#region startDate Prop
        @prop()
        startDate: Date;
        //#endregion startDate Prop


        //#region endDate Prop
        @prop()
        endDate: Date;
        //#endregion endDate Prop


        //#region days Prop
        @prop()
        days: number;
        //#endregion days Prop


        //#region status Prop
        @prop()
        status: number;
        //#endregion status Prop


        //#region name Prop
        @prop()
        name: string;
        //#endregion name Prop


        //#region managerName Prop
        @prop()
        managerName: string;
        //#endregion managerName Prop


        //#region reason Prop
        @prop()
        reason: string;
        //#endregion reason Prop


        //#region holidayType Prop
        @prop()
        holidayType: string;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        userID: number;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        startSession: number;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        managerID: number;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        leaveType: number;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        endSession: number;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        attachment: string;
        //#endregion holidayType Prop

        //#region holidayType Prop
        @prop()
        fileUrl: string;
        //#endregion holidayType Prop

        //#region currency Prop
        @prop()
        currency: number;
        //#endregion currency Prop

        //#region budget Prop
        @prop()
        budget: number;
        //#endregion budget Prop

        //#region citiesMinesLocations Prop
        @prop()
        citiesMinesLocations: string;
        //#endregion citiesMinesLocations Prop

        @prop()
        file: File;
        @prop()
        fileData: string;
        @prop()
        fileExtention: string;
        @prop()
        fileName: string;

        @prop()
        eventIds: string;
        @prop()
        projectIds: string;
        @prop()
        corporatIds: string;
         //#region purposeOfTravel Prop
         @prop()
         purposeOfTravel: string;
         //#endregion purposeOfTravel Prop
 
         //#region isOfficial Prop
         @prop()
         isOfficial: boolean;
         //#endregion isOfficial Prop
 
         //#region isDetailedFormAdded Prop
         @prop()
         isDetailedFormAdded: boolean;
         //#endregion isDetailedFormAdded Prop
 
 
         //#region isVehicleRequested Prop
         @prop()
         isVehicleRequested: boolean;
         //#endregion isVehicleRequested Prop
 
 
         //#region isFlightRequested Prop
         @prop()
         isFlightRequested: boolean;
         //#endregion isFlightRequested Prop
 
         //#region isAccomodationRequested Prop
         @prop()
         isAccomodationRequested: boolean;
         //#endregion isAccomodationRequested Prop

        //#region totalHrsRequested Prop
        @prop()
        totalHrsRequested: string;
        //#endregion totalHrsRequested Prop

        //#region logisticUse Prop
        @prop()
        logisticUse: string;
        //#endregion logisticUse Prop

        //#region carProvided Prop
        @prop()
        carProvided: string;
        //#endregion carProvided Prop


        //#region driverProvided Prop
        @prop()
        driverProvided: string;
        //#endregion driverProvided Prop


        //#region driverPhone Prop
        @prop()
        driverPhone: string;
        //#endregion driverPhone Prop


        //#region emailForUpdates Prop
        @prop()
        emailForUpdates: string;
        //#endregion emailForUpdates Prop


        //#region telePhoneForUpdates Prop
        @prop()
        telePhoneForUpdates: string;
        //#endregion telePhoneForUpdates Prop
 
         @propArray(LeaveFlightMappingBase)
         leaveFlightMappings: LeaveFlightMappingBase[];

         @propArray(LeaveVehicleMappingBase)
         leaveVehicleMappings: LeaveVehicleMappingBase[];
 
         @propArray(LeaveAccomodationMappingBase)
         leaveAccomodationMappings: LeaveAccomodationMappingBase[];

}
