import {GemStonePictureBase,PictureBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class GemStonePicture extends GemStonePictureBase 
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop

//#endregion Generated Reference Properties






































}