import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LocationGemFieldBase {

//#region locationId Prop
        @prop()
        locationId : number;
//#endregion locationId Prop


//#region name Prop
        @required()
        @maxLength({value:255})
        @notEmpty()
        name : string;
//#endregion name Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop



}