import { CLIENT_URL } from 'src/app/domain/system-constant';
import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
export const SAVEANDNOTEREPLY_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        event: {
          click: "onReply"
        },
        // isBind: (x) => { 
        //     if(x.commentsCount>0){
        //       return true;
        //     }
        //     else{
        //       return false;
        //     }
        // },
        childrens: [
          {
            i: {
                class: "fa fa-comment font-md text-default email-reply".split(" "),
                attributes: { "title": getLocalizedValue("Title_Comment") }
            }
            
          }],
        class: ["mr-2"]
      }
     
    },{a: {
        event: {
            click: "onEditData"
        },
        childrens: [{

            // i: { class: "fa fa-check fa-4x font-md text-default".split(" ") }
            i: {
                class: "fa fr-save font-md text-default".split(" "),
                attributes: { "title": getLocalizedValue("Title_Save") }
            }

        }],
        class: ["mr-2"]
    }}
    ]
   
  }
}


