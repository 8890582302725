import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vStateProvinceLookUpBase {

//#region stateProvinceId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'stateProvinceId', keyColumn: true})
        stateProvinceId : number;
//#endregion stateProvinceId Prop


//#region stateProvinceName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'stateProvinceName', keyColumn: false})
        stateProvinceName : string;
//#endregion stateProvinceName Prop


//#region abbreviation Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'abbreviation', keyColumn: false})
        abbreviation : string;
//#endregion abbreviation Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : number;
//#endregion countryId Prop

}