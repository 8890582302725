import {GemStoneSaturationBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class GemStoneSaturation extends GemStoneSaturationBase 
{




//#region Generated Reference Properties
//#region productPieceGemstones Prop
productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop

//#endregion Generated Reference Properties











}