import { TemplateConfig } from '@rxweb/grid';

export const INVOLVED_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },
                 event: {
                  input: "onInvolveSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                    return this[e.name];
                  },
                }
              },

            }
          ]
        }
      }
    ]
  },
}

