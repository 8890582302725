import { prop, propObject, propArray, required, maxLength, range, notEmpty, alpha, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"

export class TrackMeCompanyUserMappingBase {

        //#region goalsCompanyCustomerMappingId Prop
        @prop()
        trackmeCompanyUserMappingId: number;
        //#endregion goalsCompanyCustomerMappingId Prop

        //#region companyId Prop
        @required({ message: "Please Select Gemfields Group Entity" })
        companyId: number;
        //#endregion companyId Prop

        //#region customerId Prop
        // @required()
        @prop({ defaultValue: 0 })
        userId: number;
        //#endregion customerId Prop

        //#region isActive Prop
        isActive: boolean;
        //#endregion isActive Prop

        //#region createdAt Prop
        createdAt?: Date;
        //#endregion createdAt Prop

        //#region DeletedAt Prop
        DeletedAt?: Date;
        //#endregion DeletedAt 

        //#region createdBy Prop
        createdBy?: number;
        //#endregion createdBy Prop

        //#region deletedBy Prop
        deletedBy?: number;
        //#endregion deletedBy Prop
}