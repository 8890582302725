import { Observable } from 'rxjs';
import { ProductShowSalesTeamRolesEnums } from 'src/app/custom-enum/product-show-sales-team-roles-enum';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { rolesEnum } from '../../enums/roles-enum';
import * as CryptoJS from 'crypto-js';
import { vSystemHelpBase } from 'src/app/models/database-models/v-system-help-base';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { http, RxHttp } from '@rxweb/http';
import { async } from '@rxweb/reactive-form-validators';
import { encryptionKeyForDocuments } from '../system-constant';
//import { fileTypeFromBuffer } from 'file-type';
import { BaseToastr } from '../customize-design/toastr';
import { ratingEnum } from 'src/app/enums/rating.enum';
declare const $: any;

export function dateCompareValidation(startDate: Date, endDate: Date) {
  let result: Boolean;
  if ((endDate != null && endDate.toString() != "") && startDate != null && startDate > endDate) {
    result = true;
  } else {
    result = false;
  }
  return result;
}
export function checkEmojiCharactersValidation(text: string) {
  let result: Boolean;
  const emojiRegex = /^[\u2700-\u27bf]|[\ud83c][\udde6-\uddff]{2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|[\ud83c][\udd70-\udd71]|[\ud83c][\udd7e-\udd7f]|[\ud83c]\udd8e|[\ud83c][\udd91-\udd9a]|[\ud83c][\udde6-\uddff]|[\ud83c][\ude01-\ude02]|[\ud83c]\ude1a|[\ud83c]\ude2f|[\ud83c][\ude32-\ude3a]|[\ud83c][\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|[\ud83c]\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|[\ud83c]\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\uE000-\uF8FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDDFF]*$/;
  if (emojiRegex.test(text)) {
    result = true;
  } else {
    result = false;
  }
  return result;
}
export function currentDateValidation(startDate: Date, currentDate: Date) {
  let result: Boolean;
  // let TodaysDate:Date;
  // TodaysDate = new Date();
  if ((startDate != null && startDate > currentDate)) {
    result = true;
  } else {
    result = false;
  }
  return result;
}
//check Equal Dates or not
export function checkEqualDateValidation(date: Date, currentDate: Date) {
  let result: Boolean;
  const EndDateWithoutTimeStamp = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const currentDateWithoutTimeStamp = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  if ((date != null && EndDateWithoutTimeStamp.getTime() === currentDateWithoutTimeStamp.getTime())) {
    result = true;
  } else {
    result = false;
  }
  return result;
}
export function dateValidation(date: Date) {

  let result: Boolean;
  let TodaysDate: Date;
  TodaysDate = new Date();
  date.setHours(TodaysDate.getHours(), TodaysDate.getMinutes(), TodaysDate.getSeconds(), TodaysDate.getMilliseconds())
  if (date != null && date <= TodaysDate) {
    result = true;
  } else {
    result = false;
  }
  return result;
}
export function checkDateMonth(date : number, month : number)
{
  if(date != undefined)
  {
    if(date.toString() == "")
    {
      date = undefined;
    }
  }
  if(month != undefined)
  {
    if(month.toString() == "")
    {
      month = undefined;
    }
  }
  if(date != undefined && month != undefined)
  {
    return 0;
  }
  else
  {
    if(date == undefined && month == undefined)
    {
      return 3;
    }
    else if (date == undefined)
    {
      return 1;
    }
    else
    {
      return 2;
    }
  }
}
export function appendPageFooter(tableId: string) {
  var tableElement = document.getElementsByClassName("table-responsive");
  var footer = document.getElementsByClassName("dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center");

  var objTo = document.getElementById(tableId);

  //   var divtest = document.createElement("div");
  //   divtest.innerHTML = footer[0];
  if (footer.length > 0) {
    objTo.appendChild(footer[0]);

    if (footer[0].parentNode == tableElement[0]) {
      tableElement[0].removeChild(footer[0]);
      // footer[0].setAttribute("visibility","hidden");
    }
    //  footer[0].parentNode.removeChild(footer[0]);
  }

}

export function removeFooter(tableId: string) {
  var objTo = document.getElementById(tableId);
  if (objTo.childNodes[objTo.childNodes.length - 1] && (objTo.childNodes[objTo.childNodes.length - 1] as HTMLDivElement).className != "table-responsive" && (objTo.childNodes[objTo.childNodes.length - 1] as HTMLDivElement).id != "noRecordDiv") {
    objTo.removeChild(objTo.childNodes[objTo.childNodes.length - 1]);
  }
}

export function removeScrollableFooter(tableId: string) {
  var tableElement = document.getElementById(tableId);
  var footer = document.getElementsByClassName("dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center");
  if (footer.length > 0 && footer[0].parentNode == tableElement) {
    tableElement.removeChild(footer[0]);
  }
}

export function getCookie(name: string) {
  let ca: Array<string> = document.cookie.split(';');
  let caLen: number = ca.length;
  let cookieName = `${name}=`;
  let c: string;

  for (let i: number = 0; i < caLen; i += 1) {
    c = ca[i].replace(/^\s+/g, '');
    if (c.indexOf(cookieName) == 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return undefined;
}

export function deleteCookie(name) {
  this.setCookie(name, '', -2);
}

export function setCookie(name: string, value: string, expireHours: number, path: string = '') {
  let d: Date = new Date();
  d.setTime(d.getTime() * expireHours * 60 * 60 * 1000);
  let expires: string = `expires=${d.toUTCString()}`;
  let cpath: string = path ? `; path=${path}` : '';
  document.cookie = `${name}=${value}; ${expires}${cpath}`;
}

// http://rebuildadmin.g-trac.net
// http://rebuildadmin-api.g-trac.net
// http://rebuildwww.g-trac.net
// http://rebuildwww-api.g-trac.net
export function getClientUrl(): string {
  var currentUrl = window.location.href;

  if (currentUrl.indexOf('127.0.0.1') > -1) {
    return "https://127.0.0.1:4200";
  }

  if (currentUrl.indexOf('localhost') > -1) {
    return "https://localhost:4200";
  }

  if (currentUrl.indexOf('fabgtracdev') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fabgtracdev.live1.dev.radixweb.net";
    }
    else {
      return "http://fabgtracdev.live1.dev.radixweb.net";
    }

  }
  if (currentUrl.indexOf('fabergegtracrebuild') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fabergegtracrebuild.live1.dev.radixweb.net";
    }
    else {
      return "http://fabergegtracrebuild.live1.dev.radixweb.net";
    }

  }
  if (currentUrl.indexOf('rebuildadmin') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://rebuildadmin.g-trac.net";
    }
    else {
      return "http://rebuildadmin.g-trac.net";
    }

  }
  if (currentUrl.indexOf('rebuilduatadmin') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://rebuilduatadmin.g-trac.net";
    }
    else {
      return "http://rebuilduatadmin.g-trac.net";
    }
  }

  if (currentUrl.indexOf('ggl-china-test') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://ggl-china-test.web.app";
    }
    else {
      return "http://ggl-china-test.web.app";
    }
  }

  if (currentUrl.indexOf('gtrac-2af14') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://gtrac-2af14.web.app";
    }
    else {
      return "http://gtrac-2af14.web.app";
    }
  }

  if (currentUrl.indexOf('demo.g-trac') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://demo.g-trac.net";
    }
    else {
      return "http://demo.g-trac.net";
    }
  }

  if (currentUrl.indexOf('stg-gks-gtrac') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://stg-gks-gtrac-portal.web.app";
    }
    else {
      return "http://stg-gks-gtrac-portal.web.app";
    }
  }

  if (currentUrl.indexOf('fir-gks-gtrac') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fir-gks-gtrac-portal.web.app";
    }
    else {
      return "http://fir-gks-gtrac-portal.web.app";
    }
  }

  if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) {
    return "https://ggl.g-trac.net";
  }

  if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) {
    return "https://beta-ggl.g-trac.net";
  }
}


export function getServerUrl(): string {
  var currentUrl = window.location.href;
  if (currentUrl.indexOf('127.0.0.1') > -1 || currentUrl.indexOf('localhost') > -1) {
    return "https://localhost:44319";
    // return "https://fabergegtracrebuild-api.live1.dev.radixweb.net";
  }

  if (currentUrl.indexOf('fabgtracdev') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fabgtracdev-api.live1.dev.radixweb.net";
    }
    else {
      return "http://fabgtracdev-api.live1.dev.radixweb.net";
    }

  }
  if (currentUrl.indexOf('fabergegtracrebuild') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fabergegtracrebuild-api.live1.dev.radixweb.net";
    } else {
      return "http://fabergegtracrebuild-api.live1.dev.radixweb.net";
    }

  }
  if (currentUrl.indexOf('rebuildadmin') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://rebuildadmin-api.g-trac.net";
    } else {
      return "http://rebuildadmin-api.g-trac.net";
    }
  }

  if (currentUrl.indexOf('rebuilduatadmin') > -1 || currentUrl.indexOf("gtrac-2af14") > -1 || currentUrl.indexOf("ggl-china-test") > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://rebuilduatadmin-api.g-trac.net";
    } else {
      return "http://rebuilduatadmin-api.g-trac.net";
    }
  }

  if (currentUrl.indexOf('demo.g-trac') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://demo-api.g-trac.net";
    } else {
      return "http://demo-api.g-trac.net";
    }
  }

  if (currentUrl.indexOf('stg-gks-gtrac') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://stg-gks-gtrac-api.azurewebsites.net";
    } else {
      return "http://stg-gks-gtrac-api.azurewebsites.net";
    }
  }

  if (currentUrl.indexOf('fir-gks-gtrac') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://demo-gks-gtrac-api.azurewebsites.net";
    } else {
      return "http://demo-gks-gtrac-api.azurewebsites.net";
    }
  }

  if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) {

    if (currentUrl.indexOf('https:') != -1) {
      return "https://ggl-api.g-trac.net";
    } else {
      return "http://ggl-api.g-trac.net";
    }
  }

  if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) {

    if (currentUrl.indexOf('https:') != -1) {
      return "https://beta-ggl-api.g-trac.net";
    } else {
      return "http://beta-ggl-api.g-trac.net";
    }
  }

}

export function getFrontStoreUrl(): string {
  var currentUrl = window.location.href;
  if (currentUrl.indexOf('127.0.0.1') > -1) {
    return "http://127.0.0.1:4200";
  }
  // if(currentUrl.indexOf('uat')>-1){
  //     return "http://fabgtracuat-api.live1.dev.radixweb.net";
  // }
  if (currentUrl.indexOf('fabgtracdev') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://fabdev.live1.dev.radixweb.net";
    } else {
      return "http://fabdev.live1.dev.radixweb.net";
    }

  }

  if (currentUrl.indexOf('fabergegtracrebuild') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://fabergerebuild.live1.dev.radixweb.net";
    } else {
      return "http://fabergerebuild.live1.dev.radixweb.net";
    }
  }

  if (currentUrl.indexOf('rebuildwww') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://rebuildwww.live1.dev.radixweb.net";
    } else {
      return "http://rebuildwww.live1.dev.radixweb.net";
    }
  }

  if (currentUrl.indexOf('rebuilduatadmin') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://rebuilduatwww.g-trac.net";
    } else {
      return "http://rebuilduatwww.g-trac.net";
    }
  }

  if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://www.faberge.com";
    } else {
      return "http://www.faberge.com";
    }
  }

  if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://beta.faberge.com";
    } else {
      return "http://beta.faberge.com";
    }
  }

  if (currentUrl.indexOf('www.faberge.com') > -1) {
    if (currentUrl.indexOf('https:') > -1) {
      return "https://www.faberge.com";
    } else {
      return "http://www.faberge.com";
    }
  }

}
export function getFrontServerUrl(): string {
  var currentUrl = window.location.href;
  if (currentUrl.indexOf('127.0.0.1') > -1) {
    return "https://localhost:44352";
  }
  if (currentUrl.indexOf('localhost') > -1) {
    return "https://localhost:44352";
  }

  if (currentUrl.indexOf('fabgtracdev') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fabdev-api.live1.dev.radixweb.net";
    } else {
      return "http://fabdev-api.live1.dev.radixweb.net";
    }

  }
  if (currentUrl.indexOf('fabergegtracrebuild') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://fabergerebuild-api.live1.dev.radixweb.net";
    } else {
      return "http://fabergerebuild-api.live1.dev.radixweb.net";
    }

  }
  if (currentUrl.indexOf('rebuildwww') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://rebuildwww-api.g-trac.net";
    } else {
      return "http://rebuildwww-api.g-trac.net";
    }
  }
  if (currentUrl.indexOf('rebuilduatadmin') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://rebuilduatwww-api.g-trac.net";
    } else {
      return "http://rebuilduatwww-api.g-trac.net";
    }
  }

  if (currentUrl.indexOf('https://ggl.g-trac.net') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://api.faberge.com";
    } else {
      return "http://api.faberge.com";
    }
  }

  if (currentUrl.indexOf('beta-ggl.g-trac.net') > -1) {
    if (currentUrl.indexOf('https:') != -1) {
      return "https://beta-api.faberge.com";
    } else {
      return "http://beta-api.faberge.com";
    }
  }
}


// export function verifyImageDimensions(fileString:string,height:number,width:number): Observable<string>{
//         const img = new Image();
//     img.onload = function() {
//             if(img.height<height || img.width<width){
//                 return 0;
//             }
//             else{
//                 return 1;
//             }
//     }
//     img.src = fileString;
// }

export function readImage(fileString: string, width: number, height: number): Observable<string> {
  return new Observable(obs => {
    const img = new Image();
    let msg = "success";
    img.onload = () => {
      if (img.width < width || img.height < height) {
        msg = "fail"
      }
      else {
        msg = "success"
      }
      // msg="success"
      obs.next(msg as string);

      //  obs.complete();
    }
    img.src = fileString;
  });
}

export function readImageForTopic(fileString: string, width: number, height: number): Observable<string> {
  return new Observable(obs => {
    const img = new Image();
    let msg = "success";
    img.onload = () => {
      if (img.width != width || img.height != height) {
        msg = "fail"
      }
      else {
        msg = "success"
      }
      // msg="success"
      obs.next(msg as string);

      //  obs.complete();
    }
    img.src = fileString;
  });
}
export function isSalesRole(): boolean {
  //browserStorage: BrowserStorage;
  let browserStorage = new BrowserStorage();
  let roles: any[];
  let roleString: string = browserStorage.local.get('userRoles');
  let isSales: boolean = false;
  roles = roleString.split(',');
  if (!roles.includes(rolesEnum.Administrator.toString()) && !roles.includes(rolesEnum.Advanced.toString()) && !roles.includes(rolesEnum.Senior.toString()) && !roles.includes(rolesEnum.FabergeShipping.toString()) && !roles.includes(rolesEnum.FabergeStandard.toString()) && !roles.includes(rolesEnum.HRGroupwide.toString())

  ) {

    if (roles.includes(rolesEnum.FabergeSalesPerson.toString())) {
      isSales = true;
    }

  }

  return isSales;

}

export function getLocalizedValue(key: string) {
  var decryptstring = localStorage.getItem('AllLocalizedMessages');
  var localizedMsg = key;
  if (decryptstring) {
    var Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(decryptstring), 'secret key 123');
    var localizedMessages = JSON.parse(Jsonstring.toString(CryptoJS.enc.Utf8));
    var existsAlert = localizedMessages.filter(x => x.keyName == key);
    localizedMsg = !existsAlert[0] ? key : existsAlert[0].showMessage;
  }
  return localizedMsg;
}

export function showPersonaNonGrataToolTip() {

  $(".personaNonGrataToolTip").tooltip({
    content: getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t"),
    position: { my: "left+2 center", at: "right center" },
    open: function (event, ui) {
      ui.tooltip.css("z-index",9999999)
      ui.tooltip.css("max-width", "400px");
      ui.tooltip.css("background-color", "gray");
      ui.tooltip.css("color", "white");
    }
  });
}

export function getSystemHelpStatus(moduleId: number, pageId: number): boolean {
  var stringData = localStorage.getItem('SystemHelp');
  if (stringData) {
    try {
      var Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(stringData), 'secret key 123');
      var systemHelpList = JSON.parse(Jsonstring.toString(CryptoJS.enc.Utf8));
      var systemHelp = systemHelpList.find(x => x.moduleMasterId == moduleId);
      switch (pageId) {
        case SystemHelpPage.Search:
          if (systemHelp.searchIsApproved) {
            return true;
          }
          return false;
          break;

        case SystemHelpPage.Add:
          if (systemHelp.addIsApproved) {
            return true;
          }
          return false;
          break;

        case SystemHelpPage.Edit:
          if (systemHelp.editIsApproved) {
            return true;
          }
          return false;
          break;

        default:
          return false;
          break;
      }
    } catch (error) {
      return false;
    }

  }
  return false;
}

// CTRL + CLICK redirect to new tab start

export function redirectOnCTRL(url: string, router:Router, event) {
  let newTab: boolean = false;
  if (event.ctrlKey) {
    newTab = true;
  }
  if (newTab) {
    window.open(url, '_blank');
  }
  else
  {
    router.navigateByUrl(url);
  }
  return false;
}


// CTRL + CLICK redirect to new tab end

export function innerTextFromHtmlContent(s) {
  var span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export class entityClass
{
  entityTypeId : number;
  entityId : number ;
}

export class EntityDocumentCount
{
  entityTypeId : number;
  entityId : number;
  documentCount : number;
}
export function loadScript(url: string) {
  let body = <HTMLDivElement>document.body;
  let script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;
  body.appendChild(script);
}

export function encryptString(text: string): string {
  const key = CryptoJS.enc.Utf8.parse(encryptionKeyForDocuments);
  const iv = CryptoJS.enc.Hex.parse('0000000000000000');
  const encryptedBytes = CryptoJS.AES.encrypt(text, key, {
    iv: iv
  });
  return encryptedBytes.ciphertext.toString(CryptoJS.enc.Hex);
}

export function injectgoogleapi(inject:boolean){

  let link_href = ["https://fonts.googleapis.com/icon?family=Material+Icons", "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en","https://fonts.googleapis.com/css?family=Roboto:400,300,500,700"]
  let script_src = ["https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&amp;libraries=places&key=AIzaSyBxG9h6OiyXvGOxM9stnCTiriP3aQau3qc"]


if(inject)
{
        // Creating link tags:
        link_href.forEach(src => {
          let link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = src;
          document.head.appendChild(link);
        });

        // Creating script tags:
        script_src.forEach(src => {
        let script = document.createElement('script');
        script.src = src;
        document.head.appendChild(script);
        });

 }else{

          // Remove google script
        let script_map = "https://maps.googleapis.com"

        document.head.querySelectorAll("script").forEach(script => {
          if (script.src.startsWith(script_map)) {
            script.remove();
          }
        });

        // Remove google links
        let link_font = "https://fonts.googleapis.com";

        document.head.querySelectorAll("link").forEach(link => {
          if (link.href.startsWith(link_font)){
                link.remove();
          }
      });
}
}

export function injectgoogleapiPromise(inject:boolean) {
  // Simulating an asynchronous operation with a setTimeout
  return new Promise<void>((resolve) => {
      setTimeout(() => {
        let link_href = ["https://fonts.googleapis.com/icon?family=Material+Icons", "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en","https://fonts.googleapis.com/css?family=Roboto:400,300,500,700"]
        let script_src = ["https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&amp;libraries=places&key=AIzaSyBxG9h6OiyXvGOxM9stnCTiriP3aQau3qc"]


      if(inject)
      {
              // Creating link tags:
              link_href.forEach(src => {
                let link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = src;
                document.head.appendChild(link);
              });

              // Creating script tags:
              script_src.forEach(src => {
              let script = document.createElement('script');
              script.src = src;
              document.head.appendChild(script);
              });

       }else{

                // Remove google script
              let script_map = "https://maps.googleapis.com"

              document.head.querySelectorAll("script").forEach(script => {
                if (script.src.startsWith(script_map)) {
                  script.remove();
                }
              });

              // Remove google links
              let link_font = "https://fonts.googleapis.com";

              document.head.querySelectorAll("link").forEach(link => {
                if (link.href.startsWith(link_font)){
                      link.remove();
                }
            });
      }
          resolve();
      }, 2000); // Assuming 2 seconds duration
  });
}

// Function to get enum value by string input
export function getRatingEnumValueByString(input: string): number | undefined {
  // Check if the input string is a valid key in the enum
  if (input in ratingEnum) {
      return ratingEnum[input as keyof typeof ratingEnum];
  }
  return undefined; // Return undefined if the input is not a valid enum key
}

/**
 * Function to verify MIME type of files. (PART OF #284032 => 2.6.CVSS 4.3 (MEDIUM): INSUFFICIENT ANTI-VIRUS SCANNING ON FILE UPLOAD)
 * @param file File Object
 * @param extension Extension string without . (dot)
 * @returns TRUE: if mime type check is passed or bypassed due to library's contraints (refer https://www.npmjs.com/package/file-type for more info). FALSE: if mime type doesn't match with extension.
 */
export async function checkMimeType(file: File, extension: string) {
  // let baseToastr = new BaseToastr();
  // const buffer = await file.arrayBuffer();
  // // Below function will get the mime type of the file
  // const type = await fileTypeFromBuffer(buffer);
  // // file-type library doesn't work for all the extenstions. Please refer to the docs for supported extensions.
  // if(type) {
  //   // below formats are considered as 'cfb' (Compound File Binary) file. Please refer to the docs for more info.
  //   let cfbFileFormats = ["doc", "xls", "ppt", "msi"];
  //   if(type.ext.toLocaleLowerCase() === "cfb" && !cfbFileFormats.includes(extension)) {
  //     baseToastr.error(`File extension '${extension.toLowerCase()}' does not match with it's content type.`);
  //     return false;
  //   }
  //   if(type.ext.toLocaleLowerCase() !== "cfb" && type.ext.toLowerCase() != extension.toLowerCase()) {
  //     baseToastr.error(`File extension '${extension.toLowerCase()}' does not match with it's content type of '${type.ext.toLowerCase()}'.`);
  //     return false;
  //   }
  // }
  return true;
}

/**
 * Gets the enum key for a given enum value.
 * @param value - The value to find the key for.
 * @param enumObject - The enum object.
 * @returns The key of the enum as a string or undefined if not found.
 */
 export function getEnumKeyByValue(value: number, enumObject: any): string | undefined {
  const keys = Object.keys(enumObject).filter(key => enumObject[key as any] === value);
  return keys.length > 0 ? keys[0] : undefined;
}
