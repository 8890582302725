import { Component, OnInit, OnDestroy, Input, EventEmitter } from "@angular/core"
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CorporateOrdersViewModel } from 'src/app/models/extended-models/corporate-orders-view-model';
import { AbstractCorporateOrders } from '../domain/abstract-corporate-orders';
import { FabergeOrderListViewModel } from "src/app/models/extended-models/faberge-order-list-view-model";
import { AppGrid } from "src/app/domain/app-grid";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { CorporateOrdersDetailComponent } from "../details/corporate-orders-detail.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { GridColumnConfig } from "@rxweb/grid";
import { multilingual } from "@rxweb/localization";
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { CorporateOrdersAddComponent } from "../add/corporate-orders-add.component";
import { NotesEnum } from "src/app/enums/notes.enum";
import { PaginationViewModel } from "src/app/view-model/pagination-view-model";
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { CorporateOrderEventProjectMapping } from "src/app/models/extended-models/corporate-order-event-project-mapping";
@multilingual("CorporateOrdersListComponent")
@Component({
    selector: "app-corporate-orders-list",
    templateUrl: './corporate-orders-list.component.html'
})
export class CorporateOrdersListComponent extends AbstractCorporateOrders implements OnInit, OnDestroy {
    fabergeOrderListViewModel: FabergeOrderListViewModel[];
    subscription: any;
    fabergeOrdersGrid: AppGrid;
    isFilter: boolean = false;
    pageIndex: number = PaginationEnum.PageIndex
    rowCount: number = PaginationEnum.RowCount;
    paginationViewModel: PaginationViewModel;
    sortOrder: string = "true";
    orderBy: string = "company";
    totalRecords: number = 0;
    gridSearchString: string = "";
    isShowGrid: boolean;
    isShowSalesorderbutton: boolean = false;
    isNoRecordFound: boolean;
    @Input() isSupplier: boolean;
    totalOrderAmount: string = "";
    totalNumberPieces: number = 0;
    isShowGridColumn: boolean = false;
    isShowSearch : boolean = false;
    @Input() selectedTab: string = "";
    @Input() moduleID: any;
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    arrConfig: any[];
    titleSearch: string;
    titleGridColumnSetting: string;
    corporateProjectGrid: AppGrid;
    @Input() entityTypeId:number
    constructor(private router: Router, private activatedRoute: ActivatedRoute, modalView: ModalView) {
        super();
        this.modalView = modalView;
        this.router = router;
        this.paginationViewModel = new PaginationViewModel();
        this.paginationViewModel.sortOrder = "true";
        this.paginationViewModel.orderBy = "orderId";
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
    }
    json: any;
    ngOnInit(): void {
        if (this.moduleID == RolePermissionEnums.Project || this.moduleID == RolePermissionEnums.Event) {
            this.isShowSalesorderbutton=true;
        }
      this.titleSearch = getLocalizedValue("Title_Search");
      this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
      //#291870//28-06-2024//adding order mapping to project and event
        this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
        if(this.entityTypeId== NotesEnum.ProjectNotes)
            this.json["projectId"] = this.id;
        else if(this.entityTypeId == NotesEnum.EventNotes)
            this.json["eventId"] = this.id;
        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
            this.getColumnsData = t;
            if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            }
            setTimeout(() => {
                this.bindGrid();
            }, 0);
        })

    }

    bindGrid(isOnload: boolean = true) {
        this.spin = true;
        this.showGrid = true;
        this.isShowGrid = true;
        this.totalRecords = 0;
        var json = {
            userId: this.isSupplier == true && this.moduleID != RolePermissionEnums.User ? 0 : this.id,
            supplierId: this.isSupplier == true && this.moduleID == RolePermissionEnums.Supplier ? this.id : 0,
            eventId : this.moduleID == RolePermissionEnums.Event ? this.id : 0,
            projectId : this.moduleID == RolePermissionEnums.Project ? this.id : 0,
            rowCount: this.rowCount,
            pageIndex: this.pageIndex,
            orderBy: this.orderBy,
            sortOrder: this.sortOrder,
            searchString: this.gridSearchString
        }
        this.spin = true;
        this.subscription = this.post({ body: { query: JSON.stringify(json) }, path: "api/SearchUsers/CorporateOrders" }).subscribe((t: any) => {
            this.spin = false;
            this.fabergeOrderListViewModel = t;
            if (this.fabergeOrdersGrid != null) {
                this.isFilter = true;
            }
            if (this.fabergeOrderListViewModel.length > 0) {
                this.totalRecords = this.fabergeOrderListViewModel[0].totalCount;
                this.totalOrderAmount = this.fabergeOrderListViewModel[0].stTotalOrderAmount;
                this.totalNumberPieces = this.fabergeOrderListViewModel[0].totalNumberPieces;
                this.isNoRecordFound = false;
                this.isShowGrid = true;
                if(isOnload && this.totalRecords > 0){
                    this.isShowSearch = true;
                }
            }
            else {
                this.isShowGrid = false;
                this.isNoRecordFound = true;
            }
            if (!this.isFilter) {
                this.fabergeOrdersGrid = new AppGrid(this.fabergeOrderListViewModel, FabergeOrderListViewModel, { actions: { onClick: this.onClick.bind(this), onOrderNoLink: this.onOrderNoLink.bind(this), onDelete: this.onDelete.bind(this) } });
                this.fabergeOrdersGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
                if(this.moduleID != RolePermissionEnums.User){
                    this.fabergeOrdersGrid.gridColumns.splice(
                        this.fabergeOrdersGrid.gridColumns.findIndex(
                          (x) => x.name == "poaCallMe"
                        ),
                        1
                    );
                }
                if(this.moduleID != RolePermissionEnums.Event && this.moduleID != RolePermissionEnums.Project){
                    this.fabergeOrdersGrid.gridColumns.splice(
                        this.fabergeOrdersGrid.gridColumns.findIndex(
                          (x) => x.name == "customerNameUrl"
                        ),
                        1
                    );
                }
                if(this.moduleID != RolePermissionEnums.Event && this.moduleID != RolePermissionEnums.Project){
                    this.fabergeOrdersGrid.gridColumns.splice(
                        this.fabergeOrdersGrid.gridColumns.findIndex(
                          (x) => x.name == "deleteOrder" 
                        ), 
                        1
                    );
                }
                if (this.arrConfig != undefined && this.arrConfig != null) {
                    this.arrConfig.forEach(x => {
                        this.fabergeOrdersGrid.gridColumns.forEach(y => {
                            if (y.name == x.ColumnName)
                                y.visible = x.Visible;
                        });
                    });
                    this.gridColumnConfig = this.fabergeOrdersGrid.gridColumns;
                    this.gridColumnSettings.emit(this.gridColumnConfig)
                }

            }
            else {
                if (this.fabergeOrdersGrid != null) {
                    this.fabergeOrdersGrid.storeProcedure.length = this.totalRecords;
                    if (this.json["pageIndex"] == 1) {
                        this.fabergeOrdersGrid.storeProcedure.nextPage = 1;
                    }
                    this.fabergeOrdersGrid.updateSource([]);
                    this.fabergeOrdersGrid.updateSource(this.fabergeOrderListViewModel);
                }
            }
            if (this.arrConfig != undefined && this.arrConfig != null) {
                this.arrConfig.forEach(x => {
                    this.fabergeOrdersGrid.gridColumns.forEach(y => {
                        if (y.name == x.ColumnName)
                            y.visible = x.Visible;
                    });
                });
                this.gridColumnConfig = this.fabergeOrdersGrid.gridColumns;
                this.gridColumnSettings.emit(this.gridColumnConfig)
            }
            this.fabergeOrdersGrid.maxPerPage = this.json["rowCount"];
            this.fabergeOrdersGrid.componentId = this.componentName;
            this.fabergeOrdersGrid.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(' ');
            this.fabergeOrdersGrid.designClass.headerClass = "table-header-sticky".split(" ");
        })
    }

    search(value) {
        setTimeout(() => {
            this.isFilter = true;
            this.pageIndex = 1;
            this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
            if (this.fabergeOrdersGrid != null) {
                this.fabergeOrdersGrid.updateSource([]);
                this.fabergeOrdersGrid.storeProcedure.nextPage = 1;
            }
            this.bindGrid();
        }, 1000);
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.sortOrder = y;
        this.orderBy = x;
        this.fabergeOrdersGrid.storeProcedure.nextPage = z;
        this.bindGrid(false);
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.pageIndex = x;
        this.rowCount = this.fabergeOrdersGrid.maxPerPage;
        this.fabergeOrdersGrid.storeProcedure.nextPage = x;
        this.bindGrid(false);
    }
    onOrderNoLink(fabergeOrderListViewModel: FabergeOrderListViewModel) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/orders', fabergeOrderListViewModel.orderId], { queryParams: { "activeTab": "sales-order" } }));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(['/orders', fabergeOrderListViewModel.orderId], { queryParams: { "activeTab": "sales-order" } });
  }
  //#292772//12-07-2024//Removing order mapping to project and event
    onDelete(x: FabergeOrderListViewModel) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
          if (t === DialogEnum.Ok) {
            this.dialog.hide();
            this.spin = true;
            this.post({
                path: "api/SearchSalesOrder/RemoveCorporateOrderEventProjectMapping",
                 body: { id: x.orderId, entityTypeId: this.entityTypeId ,EntityId : this.id }
            }).subscribe(data => {
              this.spin = false;
              var existsAlert = getLocalizedValue("Data_Deleted");
              if (existsAlert) {
                this.toastr.success(existsAlert);
              }
              this.isFilter = true;
              this.totalRecords = 0;
              setTimeout(() => {
                this.bindGrid();
            }, 0);
            })
          }
          this.dialog.hide()
        })
    
      }
      DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_ProjectEvent_SalesOrder");
        if (existsAlert) {
          return existsAlert;
        }
      }
    onClick(fabergeOrderListViewModel: FabergeOrderListViewModel) {
        this.modalView.show(CorporateOrdersDetailComponent, { orderId: fabergeOrderListViewModel.orderId }).then(t => {
        });
    }
    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }
    applyVisible(gridColumnConfig: GridColumnConfig[]) {
        this.fabergeOrdersGrid.reDesign();
        this.fabergeOrdersGrid.updateSource([]);
        this.fabergeOrdersGrid.updateSource(this.fabergeOrderListViewModel);
    }
    showAddForm() {
        this.modalView.show(CorporateOrdersAddComponent, { mapId: this.id, entityTypeId: this.entityTypeId }).then(t => {
            this.bindGrid();
        });
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "CorporateOrdersListComponent";
    }
}
