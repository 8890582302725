import { OccurrenceTypeBase } from "../database-models/occurrence-type-base";

//Generated Imports
export class OccurrenceType extends OccurrenceTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}