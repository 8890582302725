import { prop, required, maxLength, range, numeric, notEmpty, trim, pattern } from '@rxweb/reactive-form-validators';

export class SubMenuItemBase {

        @prop({ defaultValue: 0 })
        subMenuItemId: number;

        @prop({ defaultValue: 1 })
        mainMenuItemId: number;


        @maxLength({ value: 30, message: 'More than 30 characters are not permitted' })
        @trim()
        @required()
        subMenuName: string

        @prop()
        @trim()
        subMenuUrl: string;

        @prop({ defaultValue: 0 })
        parentMenuId: number;

        @prop({ defaultValue: 0 })
        pictureId: number;


        @prop({ defaultValue: 1 })
        //@required()
        @range({ minimumNumber: 1, maximumNumber: 2145678 })
        @numeric({ allowDecimal: false })
        displayOrder: number;

        @prop({ defaultValue: false })
        isFeaturedSection: boolean;

        @prop({ defaultValue: false })
        isVisible: boolean;

        @required()
        @numeric({ allowDecimal: false })
        @pattern({expression:{"subMenuRank":/^[0-9]{1}$/},message:"Please enter Display Order between 0 to 9"})
        subMenuRank: number;

        @trim()
        @prop()
        featuredName:string
}
