import { TemplateConfig } from '@rxweb/grid';
export const TextArea_BIND: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1"],
                   event: {
                    input: "onTextChangeEvent"
                  },
                  
                  attributes: {
                    type: "text",
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    }
                   
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const TextAreaIAN_BIND: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-control", "checkbox-custom-style","col-xs-1"],
                   event: {
                    input: "onTextChangeEvent"
                  },
                  
                  attributes: {
                    type: "text",
                    value: function (e) {
                      return this[e.name];
                    }
                   
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }