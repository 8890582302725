import { gridColumn, actionColumn } from '@rxweb/grid';

@actionColumn({
  visible:false,
  name:"action",
  allowSorting: false,
  style: { width: "2%" },
  configuredTemplate: { templateName: 'logsAction' }, columnIndex: 12, headerTitle: "Action"
})

export class ApplicationExceptionLogsViewModel {


  //#region ApplicationExceptionLogId 
  @gridColumn({ name: "applicationExceptionLogId", configuredTemplate: { templateName: 'bindCheckBox' }, visible: false, columnIndex: 0, allowSorting: false, headerTitle: "Action", keyColumn: true, style: { width: "6%", "text-align": "initial" } })
  applicationExceptionLogId: number;
  //#endregion ApplicationExceptionLogId 

  //#region ApplicationExceptionLogId 
  @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ApplicationExceptionLogList_AppExcepLogIdNo_gh', keyColumn: true, style: { width: "20%", "text-align": "initial" }, class: ["text-left"] })
  applicationExceptionLogIdNumber: number;
  //#endregion ApplicationExceptionLogId 

  //#region RowNumber 
  @gridColumn({ visible: false, columnIndex: 2, allowSorting: false, headerKey: 'RowNumber', keyColumn: false })
  rowNumber: number;
  //#endregion RowNumber 

  //#region ExceptionTypeId 
  @gridColumn({ visible: false, columnIndex: 3, allowSorting: false, headerKey: 'ExceptionTypeId', keyColumn: false })
  exceptionTypeId: number;
  //#endregion ExceptionTypeId 

  //#region UserId 
  @gridColumn({ visible: false, columnIndex: 4, allowSorting: false, headerKey: 'UserId', keyColumn: false })
  userId: number;
  //#endregion UserId 


  //#region LogType 
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ApplicationExceptionLogListComponent_LogType_gh', keyColumn: false })
  logType: string;
  //#endregion LogType 

  //#region Email 
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ApplicationExceptionLogListComponent_Customer_gh', keyColumn: false })
  email: string;
  //#endregion Email 

  //#region createdDate 
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ApplicationExceptionLogListComponent_CreatedOn_gh', keyColumn: false })
  createdDate: string;
  //#endregion createdDate 

  // #region exceptionDate
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: false, headerKey: 'ApplicationExceptionLogListComponent_CreatedOn_gh', keyColumn: false })
  exceptionDate: Date;
  // #endregion exceptionDate

  //#region Message 
  @gridColumn({
    template:{
      div:{
        style: { "word-break": "break-word;", "white-space":"pre-wrap" },
        attributes: {
          innerHTML: function (x, y) {
            return this.message;
          }
      }
    }
  },
    style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'ApplicationExceptionLogListComponent_Message_gh', keyColumn: false })
  message: string;
  //#endregion Message 

  //#region totalCount 
  @gridColumn({ name: "totalCount", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 10, allowSorting: false, headerTitle: 'Total Count', keyColumn: false })
  totalCount: number;
  //#endregion totalCount


  @gridColumn({
    visible:false,
    name:"editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 10, headerTitle: "Edit"
})
editAction:any;

@gridColumn({
    visible:false,
    name:"deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 11, headerTitle: "Delete"
})
deleteAction:any;

}
