import {GemStoneNotesGemFieldBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneNotesGemField extends GemStoneNotesGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfield Prop
gemStoneGemfield : GemStoneGemfieldBase;
//#endregion gemStoneGemfield Prop

//#endregion Generated Reference Properties






































}