import {LookupInjuryTypeBase,PersonInjuryTypeBase,} from '@app/database-models'
//Generated Imports
export class LookupInjuryType extends LookupInjuryTypeBase 
{




//#region Generated Reference Properties
//#region personInjuryTypes Prop
personInjuryTypes : PersonInjuryTypeBase[];
//#endregion personInjuryTypes Prop

//#endregion Generated Reference Properties





































}