import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class NewsLocalizationBase {

//#region newsLocalizedId Prop
        @prop()
        newsLocalizedId : number;
//#endregion newsLocalizedId Prop


//#region newsId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        newsId : number;
//#endregion newsId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region title Prop
        @required()
        @maxLength({value:1000})
        title : string;
//#endregion title Prop


//#region short Prop
        @required()
        @maxLength({value:4000})
        short : string;
//#endregion short Prop


//#region full Prop
        @required()
        full : string;
//#endregion full Prop


//#region altText Prop
        @maxLength({value:500})
        altText : string;
//#endregion altText Prop


//#region shortTitle Prop
        @maxLength({value:255})
        shortTitle : string;
//#endregion shortTitle Prop

}