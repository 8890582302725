import { prop,propObject,propArray,required,maxLength,range, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductDesignBase {

//#region productId Prop
        @prop()
        productId : number;
//#endregion productId Prop


//#region name Prop
        @required({message:"Please enter Title"})
        @notEmpty()
        @maxLength({value:200})
        @trim()
        name : string;
//#endregion name Prop


//#region shortDescription Prop
      //  @required()
        @prop({defaultValue:''})
        
       // @maxLength({value:400})
        shortDescription : string;
//#endregion shortDescription Prop


//#region fullDescription Prop
        //@required()
        @prop({defaultValue:''})
       // @maxLength({value:400})
        fullDescription : string;
//#endregion fullDescription Prop


//#region adminComment Prop
        //@required()
        @prop({defaultValue:''})
        @maxLength({value:400})
        adminComment : string;
//#endregion adminComment Prop


//#region templateID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        templateID : number;
//#endregion templateID Prop


//#region showOnHomePage Prop
        @required()
        showOnHomePage : boolean;
//#endregion showOnHomePage Prop


//#region metaKeywords Prop
@prop({defaultValue:''})
        @maxLength({value:400})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @prop({defaultValue:''})
        @maxLength({value:4000})
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @prop({defaultValue:''})
        @maxLength({value:400})
        metaTitle : string;
//#endregion metaTitle Prop


//#region sEName Prop
        @prop({defaultValue:''})
        @maxLength({value:100})
        sEName : string;
//#endregion sEName Prop


//#region allowCustomerReviews Prop
        @required()
        allowCustomerReviews : boolean;
//#endregion allowCustomerReviews Prop


//#region allowCustomerRatings Prop
        @required()
        allowCustomerRatings : boolean;
//#endregion allowCustomerRatings Prop


//#region ratingSum Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        ratingSum : number;
//#endregion ratingSum Prop


//#region totalRatingVotes Prop
      //  @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        totalRatingVotes : number;
//#endregion totalRatingVotes Prop


//#region published Prop
        @required()
        published : boolean;
//#endregion published Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
       // @required()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region video Prop
        @maxLength({value:255})
        video : string;
//#endregion video Prop


//#region classicalMusic Prop
        @maxLength({value:255})
        classicalMusic : string;
//#endregion classicalMusic Prop


//#region contemporaryMusic Prop
        @maxLength({value:255})
        contemporaryMusic : string;
//#endregion contemporaryMusic Prop


//#region fabergeCertificate Prop
        @maxLength({value:255})
        fabergeCertificate : string;
//#endregion fabergeCertificate Prop


//#region teamMemberID Prop
        @prop()
        teamMemberID : number;
//#endregion teamMemberID Prop


//#region designStatus Prop
        @prop()
        designStatus : number;
//#endregion designStatus Prop


//#region collectionDisplayPriority Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        collectionDisplayPriority : number;
//#endregion collectionDisplayPriority Prop


//#region categoryDisplayPriority Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        categoryDisplayPriority : number;
//#endregion categoryDisplayPriority Prop


//#region priceResearch Prop
        @maxLength({value:500})
        priceResearch : string;
//#endregion priceResearch Prop


//#region startDate Prop
        @prop()
        startDate : Date;
//#endregion startDate Prop


//#region endDate Prop
        @prop()
        endDate : Date;
//#endregion endDate Prop

//#region AsPartOfBOM Prop
@prop()
asPartOfBOM : boolean;
//#endregion AsPartOfBOM Prop



















}