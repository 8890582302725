import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vOccurrenceMinuteLookUpBase {

//#region timeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'timeId', keyColumn: true})
        timeId : number;
//#endregion timeId Prop


//#region timeValue Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'timeValue', keyColumn: false})
        timeValue : string;
//#endregion timeValue Prop

}