import {ApplicationObjectTypeBase,} from '@app/database-models'
import { vApplicationObjectTypeLookupBase } from '../database-models/v-application-object-type-look-up-base';
//Generated Imports
export class vApplicationObjectTypeLookup extends vApplicationObjectTypeLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}