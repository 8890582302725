import { gridColumn } from "@rxweb/grid"
export class MoveNoteDocumentITHelpDeskSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean
  
  //#region ticketDetailId Prop
  @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'ticketDetailId', keyColumn: true })
  ticketDetailId: number;
  //#endregion ticketDetailId Prop

  //#region incidentNumber Prop
  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.incidentNumber;

          }
        },
      }
    }, 
    style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ITHDIncidentNo_gh', keyColumn: false
  })
  incidentNumber: string;
  //#endregion incidentNumber Prop

  //#region status Prop
  @gridColumn({ style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ITHDTicketStatus_gh', keyColumn: false })
  status: string;
  //#endregion status Prop

  //#region assignUserName Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ITHDTechPerson_gh', keyColumn: false  })
  assignUserName: string;
  //#endregion assignUserName Prop

  //#region iNCType Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 6, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ITHDTicketType_gh', keyColumn: false })
  iNCType: string;
  //#endregion iNCType Prop

  //#region stCreatedDate Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 7, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ITHDCreatedOn_gh', keyColumn: false })
  stCreatedDate: string;
  //#endregion stCreatedDate Prop

  //#region userName Prop
  @gridColumn({ configuredTemplate: { templateName: 'reDirectPeopleCorporateAndTooltip' }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 8, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ITHDUser_gh', keyColumn: false })
  userName: string;
  //#endregion userName Prop

  UserId: number;
  contactPermissionRequired:boolean;
  isUnwelcome:boolean;
  introducesBy:string;
  expandId: string;



  //#region totalCount Prop
  @gridColumn({ parameter: true, name: "totalCount", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 11, allowSorting: true, headerKey: 'Total Count', keyColumn: false })
  totalCount: number;
  //#endregion totalCount


  companyId:number;
  companyContactPermissionRequired:boolean;
  isSupplierUnwelcome:boolean;
  companyIntroducesBy : string;

  //#region stCreatedDateExport Prop
  stCreatedDateExport: string;
  //#endregion stCreatedDateExport Prop

  //#region statusId Prop
  statusId: number;
  //#endregion statusId Prop

  companyCount: number;

  companyDetails: any[];
}