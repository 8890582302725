import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { CorporateCompanyMapping } from '@app/models';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CorporateSuppliersViewModel } from 'src/app/models/extended-models/corporate-suppliers-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
@http({
  path: 'api/CorporateCompanyMappings'
})
export class AbstractCorporateCompanyMapping extends RxHttp {
  corporateCompanyMappingFormGroup: IFormGroup<CorporateCompanyMapping>
  spin: boolean = false;
  showComponent: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  modalView: ModalView;
  isShowGrid:boolean;
  isNoRecordFound:boolean;

  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }

  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
    })
  }
}
