import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const NOTETASK_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [{
        a: {
            isBind: (x) => {
              if (x.isManual && x.taskCount > 0) {
                return true;
              }
              else {
                return false;
              }
            },
          event: {
            click: "onNoteTask"
          },
          childrens: [{
            i: { class: "fa fa-tasks fa-4x font-md text-default".split(" ") }
          }],
          class: ["ml-2"],
          attributes: { "title": "Tasks" },
          style : {"line-height":"1.5rem"}
        }
      }]
    }
  }
