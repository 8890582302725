import { gridColumn } from '@rxweb/grid';

export class HSEGrievanceReportViewModel{

    @gridColumn({ visible: false, columnIndex: 0, allowSorting: false, headerKey: 'TypeId', headerTitle: "TypeId", keyColumn: false })
    TypeId: number
  
    @gridColumn({ style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: false, headerKey: 'HseReportListComponent_Company_gh'/*,headerTitle: "Company"*/, keyColumn: false, configuredTemplate: {templateName: "hseGinaReportDataSeperate"} })
    Company: string
  
    @gridColumn({ visible: true, columnIndex: 2, allowSorting: false, headerKey: 'HseReportListComponent_Total_gh'/*, headerTitle: "Total"*/ , keyColumn: false, configuredTemplate: { templateName: 'incidentDetails' } })
    Total: number
  
    grid: boolean;

}
