import {PersonInjuryTypeBase,IncidentPersonsAffectedBase,LookupInjuryTypeBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class PersonInjuryType extends PersonInjuryTypeBase 
{




//#region Generated Reference Properties
//#region incidentPersonsAffected Prop
incidentPersonsAffected : IncidentPersonsAffectedBase;
//#endregion incidentPersonsAffected Prop
//#region lookupInjuryType Prop
lookupInjuryType : LookupInjuryTypeBase;
//#endregion lookupInjuryType Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties





































}