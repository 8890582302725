import {vOccurrenceMinuteLookUpBase,} from '@app/database-models'
//Generated Imports
export class vOccurrenceMinuteLookUp extends vOccurrenceMinuteLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}