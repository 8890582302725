import {vLocationGemfieldsLookUpBase,} from '@app/database-models'
//Generated Imports
export class vLocationGemfieldsLookUp extends vLocationGemfieldsLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}