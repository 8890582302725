import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { ShippingMethod } from '@app/models';
import { AbstractShippingMethod } from '../domain/abstract-shipping-method';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ShippingMethodEditComponent } from '../edit/shipping-method-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@access({ accessLevel: RolePermissionEnums.ShippingMethods, action: "post" })

@multilingual("ShippingMethodAddComponent")
@Component({
    selector: "app-shipping-method-add",
    templateUrl: './shipping-method-add.component.html'
})
export class ShippingMethodAddComponent extends AbstractShippingMethod implements OnInit, OnDestroy {
    shippingMethod: ShippingMethod;
    subscription: any;
    shippingMethodEdit:any=ShippingMethodEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private formBuilder: RxFormBuilder,private router:Router, modelView: ModalView, private el: ElementRef, private title:Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.ShippingMethods;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Shipping Methods - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.shippingMethod = new ShippingMethod();
        this.shippingMethod.shippingMethodID=0;
        this.shippingMethod.statusId=StatusEnum.Active;
        this.shippingMethodFormGroup = this.formBuilder.formGroup(this.shippingMethod) as IFormGroup<ShippingMethod>;
        this.showComponent=true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addShippingMethod(isRedirect: boolean) {
        this.shippingMethodFormGroup.submitted = true;
        if (this.shippingMethodFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.shippingMethodFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/shipping-method', data]) : this.router.navigate(['/shipping-method']);
            })
        }else{
            for (const key of Object.keys(this.shippingMethodFormGroup.controls)) {
                if (this.shippingMethodFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    get componentName(): string {
      return "ShippingMethodAddComponent";
  }

}
