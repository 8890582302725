import {vGemfieldsEnhancementTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemfieldsEnhancementTypeLookUp extends vGemfieldsEnhancementTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}