import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductSpecificationAttributeBase {

//#region productSpecificationAttributeID Prop
        @prop()
        productSpecificationAttributeID : number;
//#endregion productSpecificationAttributeID Prop


//#region productID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID : number;
//#endregion productID Prop


//#region specificationAttributeOptionID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        specificationAttributeOptionID : number;
//#endregion specificationAttributeOptionID Prop


//#region allowFiltering Prop
        @required()
        allowFiltering : boolean;
//#endregion allowFiltering Prop


//#region showOnProductPage Prop
        @required()
        showOnProductPage : boolean;
//#endregion showOnProductPage Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}