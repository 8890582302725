import { PaginationEnum } from '../enums/pagination.enums';
import { prop, trim, numeric, pattern, range } from '@rxweb/reactive-form-validators';

export class MoveNoteDocumentGemstoneFilterViewModel {
    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "title";
        this.gemstoneType = 0;
        this.cutType = 0;
        this.gemstoneStatus = 0;
        this.location = 0;
        this.supplier = 0;
        this.color = 0;
        this.gemstoneOrigin = 0;
    }

    @prop()
    @numeric()
    @range({minimumNumber:1,maximumNumber:2147483647})
    gemstoneId: number;

    @prop()
    gemstoneType: number;

    @prop()
    @numeric({allowDecimal: true})
    totalCarats: number;

    @prop()
    cutType: number;

    @prop()
    @numeric()
    @pattern({expression:{"totalCostFrom":/^[0-9]\d{0,9}$/},message:"You can not enter value more than 10"})
    totalCostFrom: number;  

    @prop()
    @numeric()
    @pattern({expression:{"totalCostTo":/^[0-9]\d{0,9}$/},message:"You can not enter value more than 10"})
    totalCostTo: number; 
   

    @prop()
    gemstoneStatus: number;

    @prop()
    location: number;

    @prop()
    certificateNo: string;

    @prop()
    supplier: number;

    @prop()
    color: number; 

    @prop()
    gemstoneOrigin: number;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    createdDateFrom: Date;

    @prop()
    createdDateTo: Date;


    @prop()
    searchString: string;

    @prop()
    treatmentID: number;

    @prop()
    invoiceNumber: string;
}
