import { prop,propObject,propArray,required,maxLength,range ,numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RegionBase {

//#region regionId Prop
        @prop()
        regionId : number;
//#endregion regionId Prop


//#region regionName Prop
        @maxLength({value:100})
        @required()
        regionName : string;
//#endregion regionName Prop


//#region published Prop
        @prop()
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @prop()
        @numeric()
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}