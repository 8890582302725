import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerBookingBase {


//#region tripID Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'tripID', keyColumn: true})
        tripID : number;
//#endregion tripID Prop


//#region customerId Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'customerId', keyColumn: false})
        customerId : number;
//#endregion customerId Prop


//#region userName Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:5, allowSorting: true, headerKey: 'BookingDetailsComponent_ResponsiblePerson_gh', keyColumn: false})
        userName : number;
//#endregion userName Prop


//#region tripGuID Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'tripGuID', keyColumn: false})
        tripGuID : any;
//#endregion tripGuID Prop


//#region tripDescription Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'BookingDetailsComponent_TripDescription_gh', keyColumn: false})
        tripDescription : string;
//#endregion tripDescription Prop


//#region requestBy Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'requestBy', keyColumn: false})
        requestBy : number;
//#endregion requestBy Prop


//#region responsibleBy Prop
        @gridColumn({visible: false, columnIndex:22, allowSorting: true, headerKey: 'responsibleBy', keyColumn: false})
        responsibleBy : number;
//#endregion responsibleBy Prop


//#region locationCity Prop
        @gridColumn({style: { width: "5%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'BookingDetailsComponent_Location_gh', keyColumn: false})
        locationCity : string;
//#endregion locationCity Prop


//#region locationCountry Prop
        @gridColumn({visible: false, columnIndex:10, allowSorting: true, headerKey: 'BookingDetailsComponent_LocationCountry_gh', keyColumn: false})
        locationCountry : any;
//#endregion locationCountry Prop


//#region dateCheckOut Prop
        @gridColumn({style: { width: "5%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'BookingDetailsComponent_DateCheckOut_gh', keyColumn: false})
        dateCheckOut : string;
//#endregion dateCheckOut Prop


//#region dateCheckIn Prop
        @gridColumn({style: { width: "5%", "text-align": "initial" },class: ["text-left"],visible: true,columnIndex:3, allowSorting: true, headerKey: 'BookingDetailsComponent_DateCheckIn_gh', keyColumn: false})
        dateCheckIn : string;
//#endregion dateCheckIn Prop


//#region createdOn Prop
        @gridColumn({visible: false, columnIndex:11, allowSorting: true, headerKey: 'createdOn', keyColumn: false})
        createdOn : any;
//#endregion createdOn Prop


//#region updatedOn Prop
        @gridColumn({visible: false, columnIndex:12, allowSorting: true, headerKey: 'updatedOn', keyColumn: false})
        updatedOn : any;
//#endregion updatedOn Prop


//#region deleted Prop
        @gridColumn({visible: false, columnIndex:13, allowSorting: true, headerKey: 'deleted', keyColumn: false})
        deleted : any;
//#endregion deleted Prop


//#region tripName Prop
        @gridColumn({isAscending:true,style: { width: "5%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'BookingDetailsComponent_TripName_gh', keyColumn: false})
        tripName : string;
//#endregion tripName Prop


//#region tripFlag Prop
        @gridColumn({visible: false, columnIndex:14, allowSorting: true, headerKey: 'tripFlag', keyColumn: false})
        tripFlag : any;
//#endregion tripFlag Prop


//#region currencyID Prop
        @gridColumn({visible: false, columnIndex:15, allowSorting: true, headerKey: 'currencyID', keyColumn: false})
        currencyID : any;
//#endregion currencyID Prop


//#region priceBasis Prop
        @gridColumn({visible: false, columnIndex:16, allowSorting: true, headerKey: 'priceBasis', keyColumn: false})
        priceBasis : any;
//#endregion priceBasis Prop


//#region createdBy Prop
        @gridColumn({visible: false, columnIndex:17, allowSorting: true, headerKey: 'createdBy', keyColumn: false})
        createdBy : any;
//#endregion createdBy Prop


//#region includepiecespecification Prop
        @gridColumn({visible: false, columnIndex:18, allowSorting: true, headerKey: 'includepiecespecification', keyColumn: false})
        includepiecespecification : boolean;
//#endregion includepiecespecification Prop


//#region tripType Prop
        @gridColumn({visible: false, columnIndex:19, allowSorting: true, headerKey: 'BookingDetailsComponent_TripType_gh', keyColumn: false})
        tripType : number;
//#endregion tripType Prop


//#region inventoryMove Prop
        @gridColumn({visible: false, columnIndex:20, allowSorting: true, headerKey: 'inventoryMove', keyColumn: false})
        inventoryMove : any;
//#endregion inventoryMove Prop


//#region movementBy Prop
        @gridColumn({visible: false, columnIndex:21, allowSorting: true, headerKey: 'movementBy', keyColumn: false})
        movementBy : any;
//#endregion movementBy Prop

}