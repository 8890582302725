import { prop,propObject,propArray,required,maxLength,range, email, numeric, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class QueuedEmailBase {

//#region queuedEmailId Prop
        @prop()
        queuedEmailId : number;
//#endregion queuedEmailId Prop


//#region priority Prop
        @range({minimumNumber:1,maximumNumber:500})
        @required()
        @numeric()
        priority : number;
//#endregion priority Prop


//#region from Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:500})
        @email()
        from : string;
//#endregion from Prop


//#region fromName Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:500})
        fromName : string;
//#endregion fromName Prop


//#region to Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:4000})
        to : string;
//#endregion to Prop


//#region toName Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:4000})
        toName : string;
//#endregion toName Prop


//#region cc Prop
        @prop()
        cc : string;
//#endregion cc Prop


//#region bcc Prop
        @prop()
        bcc : string;
//#endregion bcc Prop


//#region subject Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:500})
        subject : string;
//#endregion subject Prop


//#region body Prop
        @required()
        @notEmpty()
        @trim()
        body : string;
//#endregion body Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region sendTries Prop
        @range({minimumNumber:1,maximumNumber:500})
        @required()
        @numeric()
        sendTries : number;
//#endregion sendTries Prop


//#region sentOn Prop
        @prop()
        sentOn : Date;
//#endregion sentOn Prop


//#region emailAccountId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        emailAccountId : number;
//#endregion emailAccountId Prop

        @prop()
        templateId:string

}