import { Component, Inject, OnInit } from "@angular/core";
import { HttpClientConfig, HttpResponse, RxHttp } from "@rxweb/http";
import { ApplicationBroadcaster } from "../../temp-service/application-broadcaster";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  RoutesRecognized,
  ActivationEnd,
  ChildActivationStart,
  ActivationStart,
} from "@angular/router";
import { AuthFilter, HttpResponseCode } from "../../temp-service/AuthFilter";
import { ReactiveFormConfig, json } from "@rxweb/reactive-form-validators";
import { GridCustomTemplate } from "@rxweb/grid";
import { GRID_CUSTOM_TEMPLATES } from "../../custom-templates/grid";
import { BaseToastr } from "../../domain/customize-design/toastr";
import { MultiLingualData } from "@rxweb/core";
import { localize } from "../../domain/customize-design/localization";
import { bindScrool } from "../../domain/customize-plugin/bind-top-scroll";
import { BrowserStorage } from "../../domain/services/browser-storage";
import { Subject, Subscription } from "rxjs";
import { externalRoutes } from "src/app/domain/external-paths";
import { HostUriConfig } from "@rxweb/http/interface/host-uri-config";
import { CoreComponent } from "@rxweb/angular-router";
import * as CryptoJS from "crypto-js";
import { AuthorizationResolver } from "src/app/domain/security/authorization-resolver";
import { LoginService } from "../authentication/login.service";
import {
  getCookie,
  setCookie,
  getLocalizedValue,
  encryptString,
} from "src/app/domain/common-logic/common-logic";
import { QueryParamsFilter } from "src/app/domain/services/query-params-filter";

import { runInThisContext } from "vm";
import { vSystemHelpBase } from "src/app/models/database-models/v-system-help-base";
import { MetaService } from "src/app/domain/services/meta-service";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { getMSALAuthConfig } from "src/app/domain/system-constant";

//localize("global", {
//  internationalization: {
//    date: {
//      format: 'mdy',
//      separator: '/'
//    }
//  }
//})
export let browserRefresh = false;
export var PreviousPath: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent extends CoreComponent implements OnInit {
  title = "fabergerebuild";
  isShowDashboard: boolean = false;
  showElement: boolean = false;
  isUserLoggedIn: boolean = false;
  showMenusLayout: boolean = true;
  subscription: any;
  paths: string[];
  previousroute: any;
  decryptstring: any;
  Jsonstring: any;
  localizedMessages: any;
  isShowLogin: boolean = false;
  showComponent: boolean = false;
  previousURL: any;
  scrolToTop: string;
  systemHelpList: vSystemHelpBase[] = [];
  applicationBroadCaster: ApplicationBroadcaster;
  documentId: number;
  documentType: string;
  previousUrl: string = '';
  currentUrl: string = '';

  private readonly _destroying$ = new Subject<void>();
  state: any;
  code: any;
  
  constructor(
    private browserStorage: BrowserStorage,
    private loginService: LoginService,
    private rxHttp: RxHttp,
    private authResolver: AuthorizationResolver,
    private baseToastr: BaseToastr,
    private activatedRoute: ActivatedRoute,
    applicationBroadCaster: ApplicationBroadcaster,
    private router: Router,
    private metaService: MetaService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    super();
    this.applicationBroadCaster = applicationBroadCaster;
    applicationBroadCaster.loginSubscriber.subscribe((t) => {
      //this.isShowDashboard = false;
      this.loginClicked(t);
    });
    // if (this.browserStorage.local.get('userId')==undefined || Number(this.browserStorage.local.get('userId') == 0)) {
    //   if (getCookie('anonymous-context') == undefined || getCookie('anonymous-context') == null || getCookie('anonymous-context') == "") {
    //     this.clearLocalStore();
    //   }
    // }
    // else {
    //   // if(this.cookieService.getCookie('requestContext')==undefined || this.cookieService.getCookie('requestContext')==null || this.cookieService.getCookie('requestContext')==""){
    //   //   this.clearLocalStore();
    //   // }
    //   if ((getCookie('requestContext') == undefined || getCookie('requestContext') == null || getCookie('requestContext') == "")) {
    //     if (this.browserStorage.session.get("requestContext") == null || this.browserStorage.session.get("requestContext") == undefined || this.browserStorage.session.get("requestContext") == "") {
    //       this.clearLocalStore();
    //     }
    //     else {
    //       //this.cookieService.setCookie('requestContext','abc',0);
    //       document.cookie = "requestContext='abc'";
    //       this.browserStorage.session.remove('requestContext');
    //     }
    //   }
    // }

    applicationBroadCaster.forgotPasswordSubscriber.subscribe((t) => {
      this.forgotPasswordClicked(t);
    });

    applicationBroadCaster.ssoRedirectedSubscriber.subscribe((t) => {
      this.microsoftLoginClicked(t);
    });

    applicationBroadCaster.resetPasswordSubscriber.subscribe((t) => {
      this.resetPasswordClicked(t);
    });

    applicationBroadCaster.memberFeedbackSubscriber.subscribe((t) => {
      this.memberFeedbackClicked(t);
    });

 
    applicationBroadCaster.blackListedSubject.subscribe((t) => {
      if (t) {
        router.navigate(["/black-list"]);
      }
    });
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

    if (window.location.pathname.indexOf("/download-content") > -1) {
      this.router.events.subscribe((event) => {
        if (event instanceof ChildActivationStart) {
          let t = event.snapshot.queryParams;
          this.documentId = t["did"];
          this.documentType = t["t"];
        }
      });
    }

  }
  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
   ).subscribe((event: NavigationEnd) => {
     this.previousUrl = this.currentUrl;
     this.currentUrl = event.url;
   //  console.log(this.previousUrl)
     this.applicationBroadCaster.setPreviousUrl(this.previousUrl)
   });
    try {
      this.decryptstring = localStorage.getItem("AllLocalizedMessages");
      this.Jsonstring = CryptoJS.AES.decrypt(
        decodeURIComponent(this.decryptstring),
        "secret key 123"
      );
      this.localizedMessages = JSON.parse(
        this.Jsonstring.toString(CryptoJS.enc.Utf8)
      );
    } catch (error) {
      if (this.browserStorage.local.get("LocalizedMessages", false)) {
        if (localStorage.getItem("AllLocalizedMessages")) {
          localStorage.removeItem("AllLocalizedMessages");
        }
        localStorage.setItem(
          "AllLocalizedMessages",
          this.browserStorage.local.get("LocalizedMessages", false)
        );
      } else {
        this.loginService.GetAllLocalizedMessages();
      }
    }

    this.scrolToTop = getLocalizedValue("Title_ScrollToTop");
    // this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    // if (this.decryptstring == null || this.decryptstring == undefined) {
    //   this.loginService.GetAllLocalizedMessages();
    // }
    // else {
    //   this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    //   if (this.Jsonstring == null || this.decryptstring == undefined) {
    //     this.loginService.GetAllLocalizedMessages();
    //   }
    //   else {
    //     this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    //     if (this.localizedMessages == null || this.localizedMessages == undefined) {
    //       this.loginService.GetAllLocalizedMessages();
    //     }
    //   }
    // }
    this.paths = new Array<string>();
    externalRoutes.forEach((x) => {
      this.paths.push(x);
    });
    var currentHostURIs: HostUriConfig[] = [];
    let currentURL = window.location.href;
    this.showMenusLayout =
      currentURL.indexOf("/stock-card?cardJson") <= -1 &&
        currentURL.indexOf("/reset-password") <= -1 &&
        currentURL.indexOf("/print-caption-report?cardJson") <= -1 &&
        currentURL.indexOf("under-maintenance") <= -1 &&
        currentURL.indexOf("i-t-help-desk-weekly-report-view") <= -1 &&
        currentURL.indexOf("/grievance-form/add") <= -1 &&
        currentURL.indexOf("/member-feedback") <= -1 &&
        currentURL.indexOf("/event-redirection") <= -1 &&
        currentURL.indexOf("purchase-order-update") <= -1 &&
        currentURL.indexOf("purchase-order-download") <= -1 &&
        currentURL.indexOf("download-content") <= -1 &&
        currentURL.indexOf("DownloadExcel") <= -1 &&
        currentURL.indexOf("download-verification") <= -1 &&
        currentURL.indexOf("download-verified-content") <= -1 
        ? true
        : false;
    // this.showMenusLayout=(currentURL.indexOf('/stock-card?cardJson')<=-1 && currentURL.indexOf('/reset-password')<=-1 && currentURL.indexOf('/print-caption-report?cardJson')<=-1 && currentURL.indexOf('under-maintenance')<=-1) ?true:false;
    // this.isShowDashboard=currentURL.indexOf('/stock-card?cardJson')<=-1 && currentURL.indexOf('/print-caption-report?cardJson')<=-1 && currentURL.indexOf('/quick-reply')<=-1  && currentURL.indexOf('under-maintenance')<=-1 && currentURL.indexOf('login')<=-1?true:false;
    this.previousURL = window.location.href.split("/");
    var splitData = currentURL.split("/")[currentURL.split("/").length-1];

    if (
      splitData != null &&
      splitData != undefined &&
      splitData.split("?")[0].length >= 10 &&
      (/^[0-9]+$/.test(splitData.split("?")[0]))
    ) {
        this.router.navigate(["/" + this.previousURL[3]]);
    }
    
    if (currentURL.indexOf("127.0.0.1") > -1) {
      currentHostURIs.push({
        name: "local",
        default: true,
        //uri: "https://fabergegtracrebuild-api.live1.dev.radixweb.net"
        uri: "https://localhost:44319",
      });
    }
    if (currentURL.indexOf("localhost") > -1) {
      currentHostURIs.push({
        name: "local",
        default: true,
        uri: "https://localhost:44319",
      });
    }

    if (currentURL.indexOf("fabgtracdev") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://fabgtracdev-api.live1.dev.radixweb.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://fabgtracdev-api.live1.dev.radixweb.net",
        });
      }
    }
    if (currentURL.indexOf("fabergegtracrebuild") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://fabergegtracrebuild-api.live1.dev.radixweb.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://fabergegtracrebuild-api.live1.dev.radixweb.net",
        });
      }
    }
    if (currentURL.indexOf("rebuildadmin") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://rebuildadmin-api.g-trac.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://rebuildadmin-api.g-trac.net",
        });
      }
    }
    if (currentURL.indexOf("rebuilduatadmin") > -1 || currentURL.indexOf("gtrac-2af14") > -1 || currentURL.indexOf("ggl-china-test") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://rebuilduatadmin-api.g-trac.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://rebuilduatadmin-api.g-trac.net",
        });
      }
    }

    if (currentURL.indexOf("demo.g-trac") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://demo-api.g-trac.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://demo-api.g-trac.net",
        });
      }
    }

    if (currentURL.indexOf("stg-gks-gtrac") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://stg-gks-gtrac-api.azurewebsites.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://stg-gks-gtrac-api.azurewebsites.net",
        });
      }
    }

    if (currentURL.indexOf("fir-gks-gtrac") > -1) {
      if (currentURL.indexOf("https:") != -1) {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "https://demo-gks-gtrac-api.azurewebsites.net",
        });
      } else {
        currentHostURIs.push({
          name: "server",
          default: true,
          uri: "http://demo-gks-gtrac-api.azurewebsites.net",
        });
      }
    }

    if (currentURL.indexOf("https://ggl.g-trac.net") > -1) {
      currentHostURIs.push({
        name: "server",
        default: true,
        uri: "https://ggl-api.g-trac.net",
      });
    }

    if (currentURL.indexOf("beta-ggl.g-trac.net") > -1) {
      currentHostURIs.push({
        name: "server",
        default: true,
        uri: "https://beta-ggl-api.g-trac.net",
      });
    }

    GridCustomTemplate.register(GRID_CUSTOM_TEMPLATES);
    HttpClientConfig.register({
      hostURIs: currentHostURIs,
      // hostURIs: [
      // {
      //   name: 'server',
      //   default: false,
      //   uri: "http://fabergegtracrebuild-api.live1.dev.radixweb.net"
      // },
      // {
      //   name: 'local',
      //   default: true,
      //   uri: "https://localhost:44319"// 'https://localhost:44376'
      // }

      //]
      filters: [{ model: AuthFilter }, { model: QueryParamsFilter }],
      onError: (response: HttpResponse) => {
        if (response.statusCode == HttpResponseCode.Unauthorized) {
          if (window.location.href.indexOf("under-maintenance") == -1) {
            this.unauthorizedErrorHandle();
          }
          // this.baseToastr.error("Timeout")
        } else if (response.statusCode == HttpResponseCode.InvalidStatusCode) {
          window.location.reload();
        } else if (
          response.statusCode == HttpResponseCode.InternalServerError
        ) {
          var errorResponse: any = <any>response;
          if (errorResponse && errorResponse.error && errorResponse.error.body)
            this.baseToastr.error(errorResponse.error.body);
          else this.baseToastr.error("Error occured");
          //this.baseToastr.error("Error occured");

          var spinnerElement = document.getElementsByClassName(
            "sk-spinner sk-spinner-wave"
          );
          if (spinnerElement != null && spinnerElement != undefined) {
            if (spinnerElement.length > 0) {
              spinnerElement[0].remove();
            }
          }
          var getURL = window.location.href.split("/");

          if(getURL.includes("desktop") || getURL.includes("mobile")){
            this.router.navigate(["/"+getURL[3]+"/"+getURL[4]])
          }
          else if(getURL.includes("desktop-assets") || getURL.includes("mobile-assets")){
            var ReUrl = getURL[3].split('-');
            this.router.navigate(["/it-assets"+"/"+ReUrl[0]])
          }
          else{

            this.router.navigate(["/" + getURL[3]]);
          }
          //this.router.navigate(["/dashboard"]);
        } else if (response.statusCode == HttpResponseCode.Forbidden) {
          // this.router.navigate(["/unauthorized"]);
          this.router.navigate(["/dashboard"]);
        } else if (response.statusCode == HttpResponseCode.BadIpFound) {
          setTimeout(() => {
            this.clearLocalStore();
            this.showComponent = true;
            this.isShowDashboard = false;
            this.applicationBroadCaster.blackListedSubject.next(true);
          }, 100);
        }
        // else
        // {
        //   this.router.navigate(["/dashboard"]);
        // }
      },
    });

    // var auth = this.browserStorage.local.get("auth", false);
    // var element = document.getElementById("gtrac");
    // if (auth) {
    //   this.activatedRoute.queryParams.subscribe(params => {
    //     this.showMenusLayout=(params["cardJson"]==undefined || params["cardJson"]==null)?true:false;
    //     this.isShowDashboard=(params["cardJson"]==undefined || params["cardJson"]==null)?true:false;
    //   });
    //  // this.isShowDashboard = true;
    //   element.classList.remove("login-page-view");

    // }
    // else{
    //   this.browserStorage.local.clearAll();
    //   this.router.navigate(["/login"])
    //  element.classList.add("login-page-view");
    // this.isShowDashboard = false;
    // }

    //  if((getCookie('requestContext') == undefined || getCookie('requestContext') == null || getCookie('requestContext') == "")){
    //   document.cookie = "requestContext='abc'";
    //  }
    this.rxHttp
      .get<string>({ path: "api/authentication/check" })
      .subscribe((response: any) => {
        if (response.status === "pass") {
          document.cookie = "requestContext='abc';SameSite=None; Secure";
          this.showComponent = true;
          this.routeManagement();
          this.routeSubscribe(
            {
              url: window.location.search
                ? `${window.location.pathname}${window.location.search}`
                : `${window.location.pathname}`,
            },
            true
          );
        } else {
          this.unauthorizedErrorHandle();
          this.routeSubscribe(
            {
              url: window.location.search
                ? `${window.location.pathname}${window.location.search}`
                : `${window.location.pathname}`,
            },
            true
          );
        }
      });

    try {
      var stringData = localStorage.getItem("SystemHelp");
      var Jsonstring = CryptoJS.AES.decrypt(
        decodeURIComponent(stringData),
        "secret key 123"
      );
      var systemHelpList = JSON.parse(Jsonstring.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      if (localStorage.getItem("SystemHelp")) {
        localStorage.removeItem("SystemHelp");
      }
      this.loginService.getSystemHelpInfo();
    }

    ReactiveFormConfig.set({
      validationMessage: {
        required: "You can't leave this empty",
        notEmpty: "You can't leave this empty",
        minLength: "Minimum  characters required",
        maxLength: "More than {{1}}  characters are not permitted",
        pattern: "The specified input format is not recognized",
        compare: "The specified values of {{0}} and {{1}} must be the same",
        contains: "The specified value must ' in the input",
        alpha: "You can use letters only",
        alphaNumeric: "You can use letters, numbers and periods only",
        range: "You need to enter appropriate value in this field",
        maxNumber: "You can not enter value more than #n#",
        numeric: "Only number required",
        email: "Please enter valid email address",
        latitude: "Please enter a valid latitude",
        longitude: "Please enter a valid longitude",
        url: "{{0}} Is not the correct url pattern.",
        password:
          "Password length should be of 8 to 50 characters and should have atleast one uppercase, one lowercase letter, a number and a special character, without any whitespaces",
      },
      reactiveForm: { errorMessageBindingStrategy: 1 },
    });
    setTimeout(() => {
      bindScrool();
    }, 50);
    document.addEventListener("click", function (event) {
      let ele = event.target as HTMLElement;
      if (
        ele.classList.contains("toast") ||
        ele.classList.contains("toast-message")
      )
        document.querySelector(".toast").remove();
    });

    document.addEventListener("scroll", function (e) {
      let ele = e.target as HTMLElement;
      // let dropdown = ele.getElementsByClassName('dropdown-menu show')[0];
      let dropdown = ele.getElementsByTagName("app-system-table-setting")[0];
      if (dropdown) {
        var dropdownMenu = dropdown.getElementsByClassName("dropdown-menu")[0];
        var body = ele.getElementsByTagName("body")[0];
        if (dropdownMenu.getBoundingClientRect().top < 0) {
          if (
            body.getBoundingClientRect().height -
            dropdown.getBoundingClientRect().bottom >
            dropdownMenu.getBoundingClientRect().height
          ) {
            var x = document.querySelector(".height-dropdown") as HTMLElement;
            x.setAttribute("style", "top:30px !important");
          }
        } else if (
          body.getBoundingClientRect().height <
          dropdownMenu.getBoundingClientRect().bottom
        ) {
          if (
            dropdown.getBoundingClientRect().top >
            dropdownMenu.getBoundingClientRect().height
          ) {
            var x = document.querySelector(".height-dropdown") as HTMLElement;
            x.setAttribute(
              "style",
              "top:-" +
              dropdownMenu.getBoundingClientRect().height +
              "px !important"
            );
          }
        }
      }
    });

    this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.checkAndSetActiveAccount();
                }
            });

            
  }
  
  checkAndSetActiveAccount(){
    let IsloginFromMicrosoft = this.browserStorage.local.get("IsloginFromMicrosoft") == undefined ? 0 : this.browserStorage.local.get("IsloginFromMicrosoft");

     /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    else {
      if (IsloginFromMicrosoft == 1) {
        if (this.authService.instance.getAllAccounts().length > 0) {
          var auth = this.browserStorage.local.get("auth", false);
          if (auth) {
            this.router.navigate(["/dashboard"]);
            this.browserStorage.local.remove("IsloginFromMicrosoft");
          } else {
            this.router.navigate(["/sso-redirected"]);
          }
        }
        else {
          this.router.navigate(["/login"]);
        }
      }
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  
  async unauthorizedErrorHandle() {


    if (window.location.pathname.indexOf("/download-content") > -1 && this.documentId != undefined && this.documentType != undefined && !isNaN(this.documentId) && this.documentType.toLowerCase() == "document") {
      var response = await this.get<any>({ path: 'api/Document/CheckRestriction', params: [encryptString(this.documentId.toString())] }).toPromise();
      if (response) {
        this.isShowLogin = false;
        this.showComponent = true;
        this.router.navigate(['download-verification'], { queryParams: { 'did': this.documentId, 't': this.documentType } });
        return;
      }
    }
    
    // this.browserStorage.local.clearAll();
    this.clearLocalStore();
    var element = document.getElementById("gtrac");
    element.classList.add("login-page-view");
    element.classList.remove("reset-page-view");
    if (getCookie("requestContext") != undefined) {
      setCookie("requestContext", "abc", -1);
    }
    //window.location.href="/login";
    console.clear();
    this.isShowLogin = true;
    this.showComponent = true;

    if (
      window.location.pathname.indexOf("/member-feedback") == -1 &&
      window.location.pathname.indexOf("/event-redirection") <= -1 &&
      window.location.pathname.indexOf("/forgot-password") == -1 &&
      window.location.pathname.indexOf("/sso-redirected") == -1 &&
      window.location.pathname.indexOf("/reset-password") == -1 &&
      window.location.pathname.indexOf("/i-t-help-desk-weekly-report-view") ==
      -1 &&
      window.location.pathname.indexOf("/grievance-form/add") == -1 &&
      window.location.pathname.indexOf("/quick-reply") == -1 &&
      window.location.pathname.indexOf("/unlock-account") == -1 &&
      window.location.pathname.indexOf("purchase-order-download") == -1
    ) {
      //PreviousPath=this.previousURL;
      // if(window.location.search && window.location.search.indexOf("=notes")!=-1){
      //   localStorage.setItem('previousroute',window.location.pathname+(window.location.search?window.location.search:""));
      // }
      if (window.location.pathname.indexOf("login") == -1) {
        localStorage.setItem(
          "previousroute",
          window.location.pathname +
          (window.location.search ? window.location.search : "")
        );
      }
      if (window.location.pathname.indexOf("/purchase-order-update") != -1) {
        window.location.href = "/login";
      }
      else {
        this.router.navigate(["/login"]);
      }
    } else {
      this.showComponent = true;
      this.routeManagement();
    }
  }

  routeManagement() {
    this.router.events.subscribe((event: any) => {
      this.routeSubscribe(event);
    });
  }

  routeSubscribe(event: { [key: string]: any }, isFromInit: boolean = false) {
    if (event instanceof RoutesRecognized || isFromInit) {
      if (
        event.url != "/login" &&
        event.url != "/member-feedback" &&
        event.url != "/event-redirection" &&
        event.url != "/forgot-password" &&
        event.url != "/sso-redirected" &&
        event.url != "/reset-password" &&
        event.url != "/unlock-account" &&
        event.url != "/i-t-help-desk-weekly-report-view" &&
        event.url != "/black-list" &&
        event.url != "/grievance-form/add" &&
        event.url != "/purchase-order-download" &&
        event.url != "/download-content" &&
        event.url != "/DownloadExcel" &&
        event.url != "/download-verification" &&
        event.url != "/download-verified-content" &&
        event.url != "/purchase-order-update"
      ) {
        PreviousPath = event.url;
      }
    }
    if (event instanceof NavigationEnd || isFromInit) {
      var auth = this.browserStorage.local.get("auth", false);
      var element = document.getElementById("gtrac");
      if (auth) {
        element.classList.remove("login-page-view");
        let currentUrl = isFromInit ? event.url : this.router.url;
        if (
          currentUrl != null &&
          currentUrl != undefined &&
          currentUrl.length <= 1
        ) {
          this.isShowDashboard = true;
          this.isShowLogin = false;
          this.router.navigate(["/dashboard"]);
        }
        this.isShowDashboard =
          (event.url.indexOf("/stock-card?cardJson") <= -1 &&
            event.url.indexOf("/print-caption-report?cardJson") <= -1 &&
            event.url.indexOf("/quick-reply") <= -1 &&
            event.url.indexOf("/under-maintenance") <= -1 &&
            event.url.indexOf("/login") <= -1 &&
            event.url.indexOf("/grievance-form/add") <= -1 &&
            event.url.indexOf("/member-feedback") <= -1 &&
            event.url.indexOf("/event-redirection") <= -1 &&
            event.url.indexOf("/purchase-order-update") <= -1 &&
            event.url.indexOf("/purchase-order-download") <= -1) ||
            event.url.indexOf("/faberge-team-login-report") !== -1 ||
            event.url.indexOf("/unlock-account") !== -1 ||
            (event.url.indexOf("/i-t-help-desk-weekly-report-view") !== -1 &&
              event.url.indexOf("/black-list") !== -1)
            ? true
            : false;
      } else {

        if (window.location.pathname.indexOf("/download-content") > -1) {
          return;
        }
        if (window.location.pathname.indexOf("/DownloadExcel") > -1) {
          return;
        }

        this.isShowDashboard = false;
        this.isShowLogin = false;

        var route = localStorage.getItem("previousroute"); //this.browserStorage.local.get("previousroute", false);

        if (
          this.paths.findIndex((z) => z == event.url.split("/")[1]) == -1 &&
          this.paths.findIndex(
            (z) =>
              z ==
              event.url.substring(
                event.url.indexOf("/") + 1,
                event.url.indexOf("?")
              )
          ) == -1
        ) {
          //  this.browserStorage.local.clearAll();
          this.clearLocalStore();
          if (
            route != null &&
            route != "/login" &&
            route.indexOf("=notes") == -1
          ) {
            PreviousPath = route;
            this.isShowLogin = true;
            this.router.navigate(["/login"]);
          } else {
            this.isShowLogin = true;
            this.router.navigate(["login"]);
          }
          element.classList.add("login-page-view");
        }
      }
      if (this.paths.findIndex((z) => z == event.url.split("/")[1]) != -1) {
        this.isShowLogin = true;
      }
    }
    if (event instanceof ActivationStart) {
      if (event.snapshot.outlet === 'primary') {
        if (event.snapshot.data) {
          if (event.snapshot.data.title) {
            this.metaService.updateTitle(event.snapshot.data.title);	
          }
        }
      }
    }
  }

  loginClicked(isClicked: boolean): void {
    if (isClicked) {
      this.isShowDashboard = true;
      this.isShowLogin = false;
      var element = document.getElementById("gtrac");
      element.classList.remove("login-page-view");
      element.classList.remove("sso-redirected-view");
      if (localStorage.getItem("previousroute") == undefined) {
        this.router.navigate(["/dashboard"]);
      }
      setTimeout(() => {
        this.isShowDashboard = true;
        this.isShowLogin = false;
      }, 50);
    }
  }

  forgotPasswordClicked(isClicked: boolean): void {
    if (isClicked) {
      this.router.navigate(["/forgot-password"]);
    }
  }

  microsoftLoginClicked(data:any): void {
    if (data.isRedirect) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
      } else {
          if (this.msalGuardConfig.authRequest) {
              const login_hint = data.userName;
              this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, loginHint: login_hint} as RedirectRequest);
          } else {
              this.authService.loginRedirect();
          }
      }

    }
  }

  resetPasswordClicked(isClicked: boolean): void {
    if (isClicked) {
      this.router.navigate(["/reset-password"]);
    }
  }

  memberFeedbackClicked(isClicked: boolean): void {
    if (isClicked) {
      this.router.navigate(["/member-feedback"]);
    }
  }

  clearLocalStore() {
    //localStorage.clear();
    this.browserStorage.local.remove("anonymousAuth");
    this.browserStorage.local.remove("auth");
    this.browserStorage.local.remove("x-request");
    this.browserStorage.local.remove("userName");
    this.browserStorage.local.remove("roleId");
    this.browserStorage.local.remove("userEmail");
    this.browserStorage.local.remove("lcode");
    this.browserStorage.local.remove("userId");
    this.browserStorage.local.remove("isGA");
    this.browserStorage.local.remove("userRoles");
    this.browserStorage.local.remove("avatar");
    this.browserStorage.local.remove("daysLeft");
    this.browserStorage.local.remove("expireDays");
    this.browserStorage.local.remove("TinyMCEdraft");
    this.browserStorage.local.remove('isSSOEnabled');
    sessionStorage.clear();
    document.cookie =
      "requestContext=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}
