<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-content">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="UpdatePiecesFromExcelComponent_UdpatePiecesTitle_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>

        <div class="modal-body" [formGroup]="updatePiecesFromExcelFormGroup"
        *ngIf="showComponent" [rxLocalisationInit]="componentName">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="UpdatePiecesFromExcelComponent_ExcelFile_t"></label>
                        <div class="col-md-8">
                            <!-- <app-file [fileViewModel]="fileViewModelExcel" (files)="fileUpload($event)">
                          </app-file>   -->
                            <!-- <label>{{ImportGemstoneFromExcelFormGroup.value.fileName}}</label> -->
                            <!-- <input id="file" type="file" accept=".xlsx" (change)="oldfileUpload($event)">  -->

                            <div class="custom-file" *ngIf="showComponent" style="margin-left:8px !important">
                                <!-- <label for="{{id}}">{{fileOutputViewModel.fileName}}</label> -->
                                <input id="file" #fileInput
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    (click)="fileInput.value = null" value="" (change)="fileUpload($event)" type="file"
                                    class="custom-file-input">

                                <label for="file"
                                    class="custom-file-label">{{updatePiecesFromExcelFormGroup.value.fileName}}</label>
                            </div>
                            <!-- <label class="mt-1">{{ImportGemstoneFromExcelFormGroup.value.fileName}}</label> -->

                            <button *ngIf="updatePiecesFromExcelFormGroup.value.fileName != null"
                                (click)="removeDoc()" class="btn btn-danger ml-2" style="margin-top: 5px;">
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <a (click)="ExportToExcel()" rxText="UpdatePiecesFromExcelComponent_Template_t"></a>

            <div class="modal-footer px-0">
                <ng-container [rxLocalisationInit]="componentName" *ngIf="updatePiecesFromExcelFormGroup.value.fileName != null">
                    <button (click)="SaveExcel()" 
                        class="btn btn-success mr-1" rxText="UpdatePiecesFromExcelComponent_Upload_t"></button>
                </ng-container>
                <button (click)="hide()" class="btn btn-primary mr-1" rxText="Btn_Close_t"></button>
            </div>
        </div>
    </div>
</div>
