import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSSalesChannelLookUpBase {

//#region salesChannelId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'salesChannelId', keyColumn: true})
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region salesChannelName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'salesChannelName', keyColumn: false})
        salesChannelName : string;
//#endregion salesChannelName Prop

//#region salesChannelType Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'salesChannelType', keyColumn: false})
        salesChannelType : number;
//#endregion salesChannelType Prop

}