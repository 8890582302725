import { prop,propObject,propArray,required,maxLength,range, numeric, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DiscountBase {

//#region discountId Prop
        @prop()
        discountId : number;
//#endregion discountId Prop


//#region discountTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        discountTypeId : number;
//#endregion discountTypeId Prop


//#region discountRequirementId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        discountRequirementId : number;
//#endregion discountRequirementId Prop


//#region requirementSpentAmount Prop
        @required()
        requirementSpentAmount : number;
//#endregion requirementSpentAmount Prop


//#region requirementBillingCountryIs Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        requirementBillingCountryIs : number;
//#endregion requirementBillingCountryIs Prop


//#region requirementShippingCountryIs Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        requirementShippingCountryIs : number;
//#endregion requirementShippingCountryIs Prop


//#region discountLimitationId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        discountLimitationId : number;
//#endregion discountLimitationId Prop


//#region limitationTimes Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        limitationTimes : number;
//#endregion limitationTimes Prop


//#region name Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region usePercentage Prop
        @required()
        @prop({defaultValue:false})
        usePercentage : boolean;
//#endregion usePercentage Prop


//#region discountPercentage Prop
        @required()
        //@range({minimumNumber:0,maximumNumber:100})
        //@numeric({allowDecimal:true})
        @prop({defaultValue: 0})
        discountPercentage : number;
//#endregion discountPercentage Prop


//#region discountAmount Prop
        @required()
        @prop({defaultValue: 0})
        //@range({minimumNumber:0,maximumNumber:2147483647})
        //@numeric({allowDecimal:true})
        discountAmount : number;
//#endregion discountAmount Prop


//#region startDate Prop
        @required()
        startDate : Date;
//#endregion startDate Prop


//#region endDate Prop
        @required()
        endDate : Date;
//#endregion endDate Prop


//#region requiresCouponCode Prop
        @required()
        @prop({defaultValue:false})
        requiresCouponCode : boolean;
//#endregion requiresCouponCode Prop


//#region couponCode Prop
        //@required()
        @maxLength({value:100})
        @prop()
        couponCode : string;
//#endregion couponCode Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}