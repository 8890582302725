import {vChainLengthLookUpBase,} from '@app/database-models'
//Generated Imports
export class vChainLengthLookUp extends vChainLengthLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}