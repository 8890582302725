import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HolidayMappingBase {

//#region holidayMappingId Prop
        @prop()
        holidayMappingId : number;
//#endregion holidayMappingId Prop


//#region holidayId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        holidayId : number;
//#endregion holidayId Prop


//#region countryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        countryId : number;
//#endregion countryId Prop



}