import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';

import { Project, vProjectTypeLookUp, vUserLookUp } from '@app/models';
import { AbstractProject } from '../domain/abstract-project';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { Router } from '@angular/router';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { SafeUrl, DomSanitizer, Title } from '@angular/platform-browser';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';

import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ProjectEditComponent } from '../edit/project-edit.component';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { vProjectOwnerLookUp } from 'src/app/models/database-models/v-project-owner-look-up';
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { getBlobImgURL } from "src/app/domain/system-constant";
@multilingual("ProjectAddComponent")
@access({ accessLevel: RolePermissionEnums.Project, action: "post" })

@Component({
    selector: "app-project-add",
    templateUrl: './project-add.component.html'
})
export class ProjectAddComponent extends AbstractProject implements OnInit, OnDestroy {
    project: Project;
    subscription: any;
    ProjectInfo: Project;
    router: Router
    dateError: boolean;
    projectEdit: any = ProjectEditComponent;
    projectIndicatorError: boolean;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    titleHelp: string;
    moduleID: any;
    showSystemHelpIcon: boolean = false;
    imageTooltip: string;
    searchCorporateName: string = "";
    projectLookup: any = {
        projectTypeLookUp: vProjectTypeLookUp,
        projectOwnerLookUp: vUserLookUp,
        //projectOwnerLookUp: vProjectOwnerLookUp,
        project: Project
    }


    _this;

    constructor(private formBuilder: RxFormBuilder, router: Router, private sanitizer: DomSanitizer, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private el: ElementRef, private title: Title, blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.router = router;
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'projects', subMenu: '' })
        this.moduleID = RolePermissionEnums.Project;
        this._this = this;
    }

    ngOnInit(): void {
        this.imageTooltip = getLocalizedValue("ProjectAddEditComponent_ImageUploadTooltip_t");
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Projects - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.selectedTab = "project-add";
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.lookup([{ path: USER_LOOKUPS["userLookUp"], propName: "projectOwnerLookUp" },
        //this.lookup([
        { path: USER_LOOKUPS.genfieldsGroupEntity, propName: "genfieldsGroupEntity" },
        { path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp" },
         { path: USER_LOOKUPS["projectTypeLookUp"], propName: "projectTypeLookUp" }]).subscribe(response => {
            this.projectLookup = response;
            this.project = new Project();
            this.projectFormGroup = this.formBuilder.formGroup(Project, this.project) as IFormGroup<Project>;
            this.showComponent = true;
         });
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    chekcValidation() {
        if (this.projectFormGroup.value.projectIndicator < 0 || this.projectFormGroup.value.projectIndicator > 100) {
            this.projectIndicatorError = true;
        } else {
            this.projectIndicatorError = false;
        }
    }

    async saveProject(isRedirect: boolean) {
        this.projectFormGroup.submitted = true;

        if (this.projectFormGroup.value.projectIndicator == null || this.projectFormGroup.value.projectIndicator == undefined || this.projectFormGroup.value.projectIndicator.toString() == "") {
            this.projectFormGroup.value.projectIndicator = 0;
        }
        if (this.projectFormGroup.value.actualCost == null || this.projectFormGroup.value.actualCost == undefined || this.projectFormGroup.value.actualCost.toString() == "") {
            this.projectFormGroup.value.actualCost = 0;
        }
        if (dateCompareValidation(this.projectFormGroup.value.projectStartDate, this.projectFormGroup.value.projectEndDate) == true) {
            this.dateError = true;
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.projectFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
        else {
            if (this.projectFormGroup.valid && !this.projectIndicatorError) {
                this.dateError = false;
                this.spin = true;
                if (this.projectFormGroup.value.fileData) {

                    let res = await this.uploadToBlob(this.fileOutputViewModel, this.projectFormGroup.value.blobPath);
                    this.projectFormGroup.patchValue({
                      fileData: null,
                      blobFileName: res
                    });
                  }
                if (this.projectFormGroup.value.fileData1) {

                    let res = await this.uploadToBlob(this.fileOutputViewModelDoc, this.projectFormGroup.value.blobPath2);
                    this.projectFormGroup.patchValue({
                      fileData1: null,
                      blobFileName2: res
                    });
                  }
                this.projectIndicatorError = false;
                if (this.projectFormGroup.value.projectIndicator == 0) {
                    this.projectFormGroup.patchValue({ projectIndicator: 0 });
                }
                if (this.projectFormGroup.value.actualCost == 0) {
                    this.projectFormGroup.patchValue({ actualCost: 0 });
                }
                this.ProjectInfo = new Project();
                this.ProjectInfo = this.projectFormGroup.value;
                if (this.ProjectInfo.projectOwner == null || this.ProjectInfo.projectOwner == undefined || this.ProjectInfo.projectOwner.toString() == "") {
                    this.ProjectInfo.projectOwner = 0;
                }
                this.ProjectInfo.projects = new Array<Project>();
                this.post({ body: this.projectFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
                      // this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    isRedirect ? this.router.navigate(['/projects', data], { queryParams: { activeTab: 'project-edit' } }) : this.router.navigate(['/projects']);
                })
            } else {
                this.breakLoop = false;
                this.validField.forEach(x => {
                    if (this.projectFormGroup.controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    getImgContent(): SafeUrl {
        let imgUrl = "data:" + this.projectFormGroup.controls.fileExtention.value + ";base64," + this.projectFormGroup.controls.fileData.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
    }
    get componentName(): string {
        return "ProjectAddComponent";
      }

}
