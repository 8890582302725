import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDocumentTypeBase {

//#region documentTypeId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'DocumentTypeListComponent_Id_gh', keyColumn: true})
        documentTypeId : number;
//#endregion documentTypeId Prop


//#region documentTypeName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'DocumentTypeListComponent_Name_gh', keyColumn: false})
        documentTypeName : string;
//#endregion documentTypeName Prop

        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'DocumentTypeListComponent_IsRestricted_gh', keyColumn: false,configuredTemplate: { templateName:"restricted" }})
        isRestricted:boolean;

        createdBy: number;

        CreatedOn: Date;

        modifiedBy: number;

        modifiedOn: Date;
}