import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductCategoryMappingBase {

//#region productCategoryID Prop
        @prop()
        productCategoryID : number;
//#endregion productCategoryID Prop


//#region productID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID : number;
//#endregion productID Prop


//#region categoryID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        categoryID : number;
//#endregion categoryID Prop


//#region isFeaturedProduct Prop
        @required()
        isFeaturedProduct : boolean;
//#endregion isFeaturedProduct Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}