import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSalesUserLookUpBase {

@prop()
userId : number;

@prop()
username : string;

@prop()
fullname : string;

@prop()
email : string;

}