export enum rolesEnum{
    Administrator = 1,
    Advanced = 2,
    Senior = 3,
    HRGroupwide = 4,
    HRCompanySpecific = 5,
    FabergeStandard = 6,
    FabergeShipping = 7,
    FabergeSalesPerson = 8,
    ITHelpDeskGroupwide = 9,
    ITHelpDeskLocalOnly = 10,
    Intermediate = 11,
    Junior = 12,
    BasicServices = 13

}