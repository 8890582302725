import {vHelpSupportRecordBase,} from '@app/database-models'
//Generated Imports
export class vHelpSupportRecord extends vHelpSupportRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}