import {vINCCategoryLookUpBase,} from '@app/database-models'
//Generated Imports
export class vINCCategoryLookUp extends vINCCategoryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}