import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SystemInformationBase {

//#region systemInformationId Prop
        @prop()
        systemInformationId : number;
//#endregion systemInformationId Prop


//#region projectName Prop
        @required()
        projectName : string;
//#endregion projectName Prop


//#region projectType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectType : number;
//#endregion projectType Prop


//#region technology Prop
        @required()
        technology : string;
//#endregion technology Prop


//#region deploymentVerison Prop
        @required()
        deploymentVerison : string;
//#endregion deploymentVerison Prop


//#region publishedDate Prop
        @required()
        publishedDate : Date;
//#endregion publishedDate Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop


//#region isDeploymentSuccessful Prop
        @required()
        isDeploymentSuccessful : boolean;
//#endregion isDeploymentSuccessful Prop

}