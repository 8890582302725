import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';

import { ClientRepair } from '@app/models';
import { AbstractClientRepair } from '../domain/abstract-client-repair';
import { vCustomerPieceLookup } from 'src/app/view-model/vCustomerPieceLookup';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import { DomSanitizer } from '@angular/platform-browser';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";

@multilingual("ClientRepairAddComponent")
@Component({
  selector: "app-client-repair-add",
  templateUrl: './client-repair-add.component.html'
})
export class ClientRepairAddComponent extends AbstractClientRepair implements OnInit, OnDestroy {
  @Input() supplierId: number
  @Input() customerId: number
  @Input() salesPerson: string
  @Input() clientName: string;
  @Input() hide: Function;
  clientRepair: ClientRepair;
  subscription: any;
  userId: number;
  estimationLookup: EstimationLookup[];
  titleClose: string;



  _this;

  constructor(private formBuilder: RxFormBuilder, sanitizer: DomSanitizer, private browserStorage: BrowserStorage,private location : PlatformLocation) {
    super(sanitizer);
    this._this = this;
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.showComponent = false;
    this.oldPieceError = false;
    this.estimationLookup = new Array<EstimationLookup>();
    let estimation = new EstimationLookup();
    estimation = new EstimationLookup();
    estimation.estimationId = 2;
    estimation.estimation = "No";
    this.estimationLookup.push(estimation);
    estimation = new EstimationLookup();
    estimation.estimationId = 1;
    estimation.estimation = "Yes";
    this.estimationLookup.push(estimation);


    this.pieceIdError = false;
    this.disabelPieces = false;
    this.formArray = new Array<number>();
    this.clientRepair = new ClientRepair();
    this.clientRepair.clientRepairId = 0;
    this.clientRepair.supplierId = this.supplierId;
    this.clientRepair.customerId = this.customerId;
    this.clientRepair.dateOfRequest = new Date();
    this.clientRepair.approvalStatus = 0;
    this.clientRepair.salesPersonId = this.browserStorage.local.get("userId");
    this.clientRepair.clientRepairGUId = this.newguidgen();
    this.userId = this.supplierId == null ? this.customerId : this.supplierId;
    this.supplierId = this.supplierId == null ? 0 : this.supplierId;
    this.customerId = this.customerId == null ? 0 : this.customerId;
    this.lookup([{ path: 'api/UserLookups/CustomerPieceLookups', propName: 'pieceLookup', params: [this.customerId, this.supplierId] }]).subscribe((t: any) => {
      this.userId = this.supplierId == null ? this.customerId : this.supplierId;
      this.pieceLookup = t.pieceLookup;
      if (this.pieceLookup == null || this.pieceLookup.length == 0) {
        this.disabelPieces = true;
      }
      this.clientRepairFormGroup = this.formBuilder.formGroup(this.clientRepair) as IFormGroup<ClientRepair>;
      this.initiatefiles();
      this.showComponent = true;

    });
  }


  initiatefiles() {
    this.fileInputViewModel1 = new FileInputViewModel();
    this.fileInputViewModel2 = new FileInputViewModel();
    this.fileInputViewModel3 = new FileInputViewModel();
    this.fileInputViewModel1.fileMode = FileTypeEnums.All;
    this.fileInputViewModel1.fileAllowSize = FileSizeEnums.FiveMB;
    this.fileInputViewModel2.fileMode = FileTypeEnums.All;
    this.fileInputViewModel2.fileAllowSize = FileSizeEnums.FiveMB;
    this.fileInputViewModel3.fileMode = FileTypeEnums.All;
    this.fileInputViewModel3.fileAllowSize = FileSizeEnums.FiveMB;

  }

  updateEstimation() {
    if (this.clientRepairFormGroup.value.estimationId != null && this.clientRepairFormGroup.value.estimationId.toString() != "") {
      this.clientRepairFormGroup.patchValue({
        estimationRequired: this.clientRepairFormGroup.value.estimationId == 1 ? true : false
      });
    } else {
      this.clientRepairFormGroup.patchValue({
        estimationRequired: null
      });
    }

  }


  removeFile(i: number) {
    this.formArray.splice(this.formArray.findIndex(x => x == i), 1);
    this.index = i;
    this.index -= 1;
    if (i == 1) {
      this.clientRepairFormGroup.patchValue({
        fileData: null,
        fileExtention: null,
        fileName: null,
        displayImage: null,
        uploadFile1: null
      });
      this.fileURL1 = null;
      this.clientRepair.uploadFile1 = null;
    }
    else if (i == 2) {
      this.clientRepairFormGroup.patchValue({
        fileData1: null,
        fileExtention1: null,
        fileName1: null,
        displayImage: null,
        uploadFile2: null
      });
      this.fileURL2 = null;
      this.clientRepair.uploadFile2 = null;
    }
    else {
      this.clientRepairFormGroup.patchValue({
        fileData2: null,
        fileExtention2: null,
        fileName2: null,
        displayImage: null,
        uploadFile3: null
      });
      this.fileURL3 = null;
      this.clientRepair.uploadFile3 = null;
    }
  }

  updateFileCount() {
    if (this.formArray.length == 0) {
      this.index = 0;
    }
    this.index += 1;
    this.formArray.push(this.index);
  }

  newguidgen() {
    function id() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return id() + id() + '-' + id() + '-' + id() + '-' +
      id() + '-' + id() + id() + id();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addClientRepair() {
    this.clientRepairFormGroup.submitted = true;
    if ((this.clientRepairFormGroup.value.pieceIds == undefined)) {
      this.clientRepairFormGroup.controls.pieceIds.setValue("");
    }
    if ((this.clientRepairFormGroup.value.oldFabergePieces == null || this.clientRepairFormGroup.value.oldFabergePieces.trim() == "") && (this.clientRepairFormGroup.value.pieceIds.trim() == "" || this.clientRepairFormGroup.value.pieceIds == undefined)) {
      this.oldPieceError = true;
      this.pieceIdError = true;
    }
    // else if(this.clientRepairFormGroup.value.oldFabergePieces!=null && this.clientRepairFormGroup.value.oldFabergePieces!=""  && (this.clientRepairFormGroup.value.pieceIds=="" || this.clientRepairFormGroup.value.pieceIds==undefined)){
    //   this.oldPieceError=false;
    //    this.pieceIdError=false;
    // }
    else {
      this.oldPieceError = false;
      this.pieceIdError = false;
      if (this.clientRepairFormGroup.valid) {
        this.spin = true;
        this.clientRepairFormGroup.patchValue({
          //clientURL:CLIENT_URL
        })
        this.post({ body: this.clientRepairFormGroup.value }).subscribe(t => {
          this.spin = false;
          var existsAlert = getLocalizedValue("Data_Added");
          if (existsAlert) {
            //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert);
          }
          this.hide();
        })
      }
    }
  }
  get componentName(): string {
    return "ClientRepairAddComponent";
  }

}

class EstimationLookup {
  estimationId: number;
  estimation: string;
}