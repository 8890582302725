import {vProductionFormOrderNoBase,} from '@app/database-models'
//Generated Imports
export class vProductionFormOrderNo extends vProductionFormOrderNoBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}