import { prop, email, required } from '@rxweb/reactive-form-validators';
import { FileExtentionBase } from '../database-models/file-extention-base';
//Generated Imports
export class FileExtention extends FileExtentionBase 
{

    




}