import {BookingGemstoneGemFieldBase,SupplierBase,UserBase,} from '@app/database-models'
//Generated Imports
export class BookingGemstoneGemField extends BookingGemstoneGemFieldBase 
{




//#region Generated Reference Properties
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties




































}