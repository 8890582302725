import { IMultilingual, IMiddleware, } from "@rxweb/angular-router"
import { RxHttp } from "@rxweb/http"
import { MultiLingualData } from "@rxweb/localization"
import { BrowserStorage } from '../services/browser-storage';
import { ActivatedRouteSnapshot } from '@angular/router';
import {  SERVER_URL } from '../system-constant';

const STORAGE_KEY: string = "language";
const LANGUAGE_CODE: string = "en";

export abstract class BaseMultilingualContentResolver extends RxHttp implements IMiddleware, IMultilingual {

  resolve(name: string): boolean | Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  private getName(component: Function) {
    return MultiLingualData.getName(component)
  }
  resolveChildren(component: Function): boolean | Promise<boolean> {
    let name = this.getName(component);
    return this.resolveData(name);
  }

  constructor(private browserStorage: BrowserStorage, private isGlobal: boolean = false) { super() }

  invoke(user: { [key: string]: any; }, activatedRouteSnapshot: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    let name = this.isGlobal ? 'global' : this.getName(activatedRouteSnapshot.component as Function);
    return this.resolveData(name);

  }

  resolveData(name: string):boolean | Promise<boolean> {
    if (name && !MultiLingualData.contains(name)) {
      var promise = new Promise<boolean>((resolve, reject) => {
        var language = this.browserStorage.local.get(STORAGE_KEY) || LANGUAGE_CODE;
        var json=JSON.parse(JSON.stringify({name:name,languageCode:language}));
        // this.get({ hostUri: SERVER_URL, path: `assets/localization/${name}-${language}.json` }).subscribe(t => {
          this.get({hostUri: SERVER_URL,path:'api/SearchCommon/GetLocalization',queryParams:{query:JSON.stringify(json)}}).subscribe((t:any) => {
          MultiLingualData.addOrUpdate(name, t);
          resolve(true);
        }, x => {
          resolve(true)
        })
      })
      return promise;
    }
    else
      return true;
  }
}
