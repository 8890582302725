import {vParentCollectionLookupBase,} from '@app/database-models'
//Generated Imports
export class vParentCollectionLookup extends vParentCollectionLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}