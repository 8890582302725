import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim, numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingBase {

        //#region tripID Prop
        @prop({ defaultValue: 0 })
        tripID: number;
        //#endregion tripID Prop


        //#region tripGuID Prop
        @required()
        @prop({ defaultValue: "13A1994E-6800-4C6F-A096-FC25742B7FD3" })
        tripGuID: any;
        //#endregion tripGuID Prop


        //#region tripDescription Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({ value: 4000 })
        tripDescription: string;
        //#endregion tripDescription Prop


        //#region requestBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        requestBy: number;
        //#endregion requestBy Prop


        //#region responsibleBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        responsibleBy: number;
        //#endregion responsibleBy Prop
        //#region responsibleByPersonIds Prop
        @required()
        @prop()
        responsibleByPersonIds: string="";
        //#endregion responsibleByPersonIds Prop


        //#region locationCity Prop
        @maxLength({ value: 200 })
        locationCity: string;
        //#endregion locationCity Prop


        //#region locationCountry Prop
        @prop()
        //@required()
        locationCountry: number;
        //#endregion locationCountry Prop


        //#region dateCheckOut Prop
        //@required()
        @prop()
        dateCheckOut: Date;
        //#endregion dateCheckOut Prop


        //#region dateCheckIn Prop
        //@required()
        @prop()
        dateCheckIn: Date;
        //#endregion dateCheckIn Prop


        //#region createdOn Prop
        @required()
        @prop({ defaultValue: new Date() })
        createdOn: Date;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @prop()
        updatedOn: Date;
        //#endregion updatedOn Prop


        //#region deleted Prop
        @prop({ defaultValue: 0 })
        deleted: boolean;
        //#endregion deleted Prop


        //#region tripName Prop
        @required()
        @prop()
        @notEmpty()
        @trim()
        @maxLength({ value: 255 })
        tripName: string;
        //#endregion tripName Prop


        //#region tripFlag Prop
        @prop({ defaultValue: 1 })
        tripFlag: number;
        //#endregion tripFlag Prop


        //#region currencyID Prop
        @prop()
        currencyID: number;
        //#endregion currencyID Prop


        //#region priceBasis Prop
        @prop()
        priceBasis: number;
        //#endregion priceBasis Prop


        //#region createdBy Prop
        @prop()
        createdBy: number;
        //#endregion createdBy Prop


        //#region includepiecespecification Prop
        @required()
        @prop({ defaultValue: false })
        includepiecespecification: boolean;
        //#endregion includepiecespecification Prop


        //#region tripType Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        tripType: number;
        //#endregion tripType Prop


        //#region inventoryMove Prop
        @prop()
        @required()
        inventoryMove: number;
        //#endregion inventoryMove Prop


        //#region movementBy Prop
        @prop()
        movementBy: number;
        //#endregion movementBy Prop

        //#region priceBasis Prop
        @prop()
        @numeric()
        orderId: number;
        //#endregion priceBasis Prop

        @prop({ defaultValue: true })
        orderIdFlag: boolean;


        //#region originalTripId Prop
        @prop()
        originalTripId: number;
        //#endregion originalTripId Prop

}
