import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSpecificationAttributeBase {

//#region specificationAttributeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'specificationAttributeId', keyColumn: true})
        specificationAttributeId : number;
//#endregion specificationAttributeId Prop


//#region specificationAttribute Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'specificationAttribute', keyColumn: false})
        specificationAttribute : string;
//#endregion specificationAttribute Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}