import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractPowerBIReport } from '../domain/abstract-power-bi-report';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PowerBIReportNoteViewModel } from 'src/app/view-model/power-bi-report-notes-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { multilingual } from '@rxweb/localization';

@multilingual("PowerBIReportNotesComponent")
@Component({
  selector: 'app-power-bi-report-notes',
  templateUrl: './power-bi-report-notes.component.html',
  styleUrls: ['./power-bi-report-notes.component.css']
})
export class PowerBIReportNotesComponent extends AbstractPowerBIReport implements OnInit, OnDestroy {

  powerBIReportId: number;
  sort: boolean;
  titleSearch: string;
  subscription: any;
  noteList: PowerBIReportNoteViewModel[];
  noteGrid: AppGrid;
  isNoRecordFound: boolean;
  isShowGrid: boolean;
  gridSearchString: string = "";
  totalRecords: number = 0;
  pageIndex: number = PaginationEnum.PageIndex;
  rowCount: number = PaginationEnum.RowCount;
  orderBy: string = 'createdDate';
  sortOrder: string = 'false';
  isFilter: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, modelView: ModalView) {
    super();
    this.modalView = modelView;
    this.activatedRoute.params.subscribe(t => {
        this.powerBIReportId = t['id'];
    })
    this.router = router;
  }

  ngOnInit(): void {
    this.sort = true;
    this.titleSearch = getLocalizedValue("Title_Search");
    this.bindGrid();
  }
  
  bindGrid() {
    var json = {
        powerBIReportId: this.powerBIReportId,
        pageIndex: this.pageIndex,
        rowCount: this.rowCount,
        orderBy: this.orderBy,
        sortOrder: this.sortOrder,
        searchString: this.gridSearchString,
        timezoneOffset: new Date().getTimezoneOffset(),
    }
    this.spin = true;
    this.subscription = this.post({ path: "api/MasterLookups/GetPowerBIReportsNotes", body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
        this.spin = false;
        this.noteList = t;
        this.totalRecords = this.noteList.length > 0 ? this.noteList[0].totalCount : 0;
        this.isShowGrid = this.noteList.length > 0 ? true : false;
        this.isNoRecordFound = this.noteList.length > 0 ? false : true;
        if (!this.isFilter) {
            this.noteGrid = new AppGrid(this.noteList, PowerBIReportNoteViewModel, { actions: {} });
            this.noteGrid.storeProcedure = {
                length: this.totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                nextPage: 1,
                onPageSorting: this.onPageSorting.bind(this)
            }
        }
        else {
            this.noteGrid.storeProcedure.length = this.totalRecords;
            this.noteGrid.updateSource([]);
            this.noteGrid.updateSource(this.noteList);
        }
        this.noteGrid.maxPerPage = this.rowCount;
        this.noteGrid.componentId = this.componentName;
        this.noteGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.noteGrid.designClass.headerClass = "table-header-sticky".split(" ");            

    })
  }
  search($event) {
      setTimeout(() => {
          this.isFilter = true;
          this.pageIndex = 1;
          this.rowCount = PaginationEnum.RowCount;
          this.gridSearchString = $event.target.value.replace(/^\s+|\s+$/gm, '');
          this.noteGrid.updateSource([]);
          this.bindGrid();
      }, 4);
  }
  onPageSorting(x, y, z) {
      this.isFilter = true;
      this.sortOrder = (!this.sort).toString();
      this.sort = !this.sort;
      this.orderBy = x;
      this.pageIndex = z;
      this.noteGrid.storeProcedure.nextPage = z;
      this.bindGrid();
  }
  onPageChanging(x) {
      this.isFilter = true;
      this.pageIndex = x;
      this.rowCount = this.noteGrid.maxPerPage
      this.noteGrid.storeProcedure.nextPage = x;
      this.bindGrid();
  }  
  
  get componentName(): string {
    return "PowerBIReportNotesComponent";
}

}
