import {vCollectionLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCollectionLookUp extends vCollectionLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}