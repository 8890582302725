import { prop, propObject, propArray, required, maxLength, range, trim, alpha, numeric, url, notEmpty, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SupplierDetailBase {

        //#region supplierDetailId Prop
        @prop()
        supplierDetailId: number;
        //#endregion supplierDetailId Prop


        //#region supplierId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        supplierId: number;
        //#endregion supplierId Prop


        //#region firstName Prop
        @maxLength({ value: 1000 })
        firstName: string;
        //#endregion firstName Prop


        //#region lastName Prop
        @maxLength({ value: 1000 })
        lastName: string;
        //#endregion lastName Prop


        //#region fullName Prop
        @maxLength({ value: 2001 })
        fullName: string;
        //#endregion fullName Prop


        //#region title Prop
        @prop()
        title: number;
        //#endregion title Prop


        //#region company Prop        
        //@pattern({ expression: { "company": /^[^\,]*$/ }, message: "Comma is not allowed in Corporates/Groups Name" })
        @maxLength({ value: 1000 })
        @trim()
        @notEmpty()
        @required()
        company: string;
        //#endregion company Prop


        //#region industryTypeId Prop
        @prop()
        industryTypeId: number;
        //#endregion industryTypeId Prop


        //#region jobTitle Prop
        @maxLength({ value: 1000 })
        jobTitle: string;
        //#endregion jobTitle Prop


        //#region profileAbout Prop
        @prop()
        profileAbout: string;
        //#endregion profileAbout Prop


        //#region email Prop
        @maxLength({ value: 255 })
        email: string;
        //#endregion email Prop


        //#region secondaryEmail Prop
        @maxLength({ value: 1000 })
        secondaryEmail: string;
        //#endregion secondaryEmail Prop


        //#region mobileNumber Prop
        //@maxLength({ value: 100 })
        @prop()
        mobileNumber: string;
        //#endregion mobileNumber Prop


        //#region streetAddress Prop
        @maxLength({ value: 1000 })
        @trim()
        streetAddress: string;
        //#endregion streetAddress Prop


        //#region streetAddress2 Prop
        @maxLength({ value: 1000 })
        @trim()
        streetAddress2: string;
        //#endregion streetAddress2 Prop


        //#region city Prop
        @maxLength({ value: 1000 })
        @trim()
        // @alpha({ allowWhiteSpace: true })
        city: string;
        //#endregion city Prop


        //#region countryId Prop
        @prop()
        countryId: number;
        //#endregion countryId Prop


        //#region stateProvinceId Prop
        @prop()
        stateProvinceId: number;
        //#endregion stateProvinceId Prop


        //#region zipPostalCode Prop
        @prop()
        @maxLength({ value: 15 })
        @trim()
        //@numeric()
        zipPostalCode: string;
        //#endregion zipPostalCode Prop


        //#region poBox Prop
        @maxLength({ value: 100 })
        @trim()
        poBox: string;
        //#endregion poBox Prop


        //#region nationality Prop
        @prop()
        nationality: number;
        //#endregion nationality Prop


        //#region familyMembers Prop
        @maxLength({ value: 1000 })
        familyMembers: string;
        //#endregion familyMembers Prop


        //#region hobbies Prop
        @maxLength({ value: 1000 })
        hobbies: string;
        //#endregion hobbies Prop


        //#region salesadvisorsID Prop
        @prop()
        salesadvisorsID: number;
        //#endregion salesadvisorsID Prop


        //#region introducesBy Prop
        @prop()
        introducesBy: number;
        //#endregion introducesBy Prop


        //#region dietaryInfo Prop
        @maxLength({ value: 400 })
        dietaryInfo: string;
        //#endregion dietaryInfo Prop


        //#region department Prop
        @prop()
        department: number;
        //#endregion department Prop


        //#region description Prop
        @prop()
        @required()
        @trim()
        @notEmpty()
        @maxLength({ value: 2000 })
        description: string;
        //#endregion description Prop


        //#region webSite Prop
        @prop()
        @trim()
        webSite: string;
        //#endregion webSite Prop


        //#region industryType Prop
        @prop()
        industryType: string;
        //#endregion industryType Prop


        //#region rating Prop
        @prop()
        rating: number;
        //#endregion rating Prop


        //#region gender Prop
        @maxLength({ value: 250 })
        gender: string;
        //#endregion gender Prop


        //#region vatNumber Prop
        @maxLength({ value: 250 })
        vatNumber: string;
        //#endregion vatNumber Prop


        //#region vatNumberStatus Prop
        @maxLength({ value: 250 })
        vatNumberStatus: string;
        //#endregion vatNumberStatus Prop


        //#region phoneNumber Prop
        //@maxLength({ value: 100 })
        @prop()
        phoneNumber: string;
        //#endregion phoneNumber Prop


        //#region faxNumber Prop
        @maxLength({ value: 250 })
        faxNumber: string;
        //#endregion faxNumber Prop


        //#region countryName Prop
        @maxLength({ value: 250 })
        countryName: string;
        //#endregion countryName Prop


        //#region knownTo Prop
        @maxLength({ value: 1000 })
        knownTo: string;
        //#endregion knownTo Prop


        //#region jewelleryStockist Prop
        @prop()
        jewelleryStockist: boolean;
        //#endregion jewelleryStockist Prop


        //#region watchStockist Prop
        @prop()
        watchStockist: boolean;
        //#endregion watchStockist Prop


        //#region currentFabergeStockist Prop
        @prop({ defaultValue: 0 })
        currentFabergeStockist: boolean;
        //#endregion currentFabergeStockist Prop


        //#region commu_FabergeMBRupdates Prop
        @prop()
        commu_FabergeMBRupdates: boolean;
        //#endregion commu_FabergeMBRupdates Prop


        //#region bankAccount Prop
        @maxLength({ value: 100 })
        bankAccount: string;
        //#endregion bankAccount Prop


        //#region financeContactName Prop
        @maxLength({ value: 100 })
        financeContactName: string;
        //#endregion financeContactName Prop


        //#region languagePreference Prop
        @prop()
        languagePreference: number;
        //#endregion languagePreference Prop


        //#region lastShippingOption Prop
        @prop()
        lastShippingOption: string;
        //#endregion lastShippingOption Prop


        //#region skypeName Prop
        @maxLength({ value: 250 })
        skypeName: string;
        //#endregion skypeName Prop


        //#region stateProvinceName Prop
        @maxLength({ value: 250 })
        stateProvinceName: string;
        //#endregion stateProvinceName Prop


        //#region useRewardPointsDuringCheckout Prop
        @prop()
        useRewardPointsDuringCheckout: boolean;
        //#endregion useRewardPointsDuringCheckout Prop


        //#region companyRegisteredNumber Prop
        @maxLength({ value: 250 })
        companyRegisteredNumber: string;
        //#endregion companyRegisteredNumber Prop


        //#region financePhone Prop
        @maxLength({ value: 100 })
        financePhone: string;
        //#endregion financePhone Prop


        //#region impersonatedCustomerGuid Prop
        @maxLength({ value: 100 })
        impersonatedCustomerGuid: string;
        //#endregion impersonatedCustomerGuid Prop


        //#region impersonatedGemfieldsCustomerGuid Prop
        @maxLength({ value: 100 })
        impersonatedGemfieldsCustomerGuid: string;
        //#endregion impersonatedGemfieldsCustomerGuid Prop


        //#region jobDescription Prop
        @maxLength({ value: 500 })
        jobDescription: string;
        //#endregion jobDescription Prop


        //#region stateProvince Prop
        @maxLength({ value: 100 })
        stateProvince: string;
        //#endregion stateProvince Prop


        //#region workPhoneNumber Prop
        @prop()
        @maxLength({ value: 1000 })
        // @numeric()
        workPhoneNumber: string;
        //#endregion workPhoneNumber Prop


        //#region accountActivationToken Prop
        @maxLength({ value: 100 })
        accountActivationToken: string;
        //#endregion accountActivationToken Prop


        //#region displayPrice Prop
        @prop()
        displayPrice: boolean;
        //#endregion displayPrice Prop


        //#region financeEmail Prop
        @maxLength({ value: 255 })
        financeEmail: string;
        //#endregion financeEmail Prop


        //#region financeFax Prop
        @maxLength({ value: 255 })
        financeFax: string;
        //#endregion financeFax Prop


        //#region musicPreference Prop
        @prop()
        musicPreference: boolean;
        //#endregion musicPreference Prop


        //#region passwordRecoveryToken Prop
        @maxLength({ value: 100 })
        passwordRecoveryToken: string;
        //#endregion passwordRecoveryToken Prop


        //#region advisorassigned Prop
        @maxLength({ value: 100 })
        advisorassigned: string;
        //#endregion advisorassigned Prop


        //#region contactMethod Prop
        @prop()
        contactMethod: number;
        //#endregion contactMethod Prop


        //#region country Prop
        @prop()
        country: number;
        //#endregion country Prop


        //#region discountLevel Prop
        @prop()
        discountLevel: number;
        //#endregion discountLevel Prop


        //#region financeMobile Prop
        @maxLength({ value: 100 })
        financeMobile: string;
        //#endregion financeMobile Prop


        //#region pOBShippingCountryId Prop
        @prop()
        pOBShippingCountryId: number;
        //#endregion pOBShippingCountryId Prop

        //#region incorporationCountry Prop
        @prop()
        incorporationCountry: number
        //#endregion incorporationCountry Prop

        //#region complianceForm Prop
        @prop()
        complianceForm: boolean
        //#endregion complianceForm Prop

        //#region companyID Prop
        @prop()
        companyID: boolean
        //#endregion companyID Prop

        //#region keyIndividualID Prop
        @prop()
        keyIndividualID: boolean
        //#endregion keyIndividualID Prop

        //#region keyIndividualID Prop
        @prop()
        corporateCreditRatingId: number;
        //#endregion keyIndividualID Prop

        @pattern({ expression: { "grievanceFormUrlSuffix": /^[a-zA-Z0-9-_]*[a-zA-Z0-9]$/ }, message: "Please enter valid URL Suffix (only '_' and '-' spacial characters are allowed)" })
        @prop()
        @trim()
        grievanceFormUrlSuffix: string = "";

        @prop()
        @trim()
        @maxLength({ value: 500 })
        grievanceFormPageTitle: string = "";
}
