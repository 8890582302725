import {ShippingMethodBase,} from '@app/database-models'
//Generated Imports
export class ShippingMethod extends ShippingMethodBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}