import { gridColumn } from '@rxweb/grid';
import { SERVER_URL } from '../domain/system-constant';

export class MoveNoteDocumentDesignSearchViewModel {
  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  @gridColumn({ parameter: false, style: { width: "9%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'MoveNoteDocumentComp_DesignId_gh', keyColumn: false })
  productId: number;
  designName: string;
  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.title;
          }
        },
      }
    }, visible: true, parameter: false, keyColumn: false, columnIndex: 2, style: { width: "10%", "text-align": "initial", "word-break": "break-all" }, class: ["text-left"], allowSorting: true, headerKey: 'MoveNoteDocumentComp_DesignTitle_gh', name: 'title'
  })
  title: any;
  pictureId: number;
  displayOrder: number;
  categoryId: number;
  designStatusId: number;
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_DesignCategory_gh', keyColumn: false })
  categoryName: string;
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'MoveNoteDocumentComp_DesignStatus_gh', keyColumn: false })
  designStatus: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial", "word-break": "break-all" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_DesignCollection_gh', keyColumn: false })
  collectionName: string;
  specificationAttributeOptionID: number;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'MoveNoteDocumentComp_DesignPublished_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
  published: boolean;

  totalCount: number;
}