import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PersonBodilyLocationBase {

//#region incPersonBodilyLocationId Prop
        @prop()
        incPersonBodilyLocationId : number;
//#endregion incPersonBodilyLocationId Prop


//#region incidentId Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        incidentId : number;
//#endregion incidentId Prop


//#region incPersonAffId Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        incPersonAffId : number;
//#endregion incPersonAffId Prop


//#region bodilyLocationId Prop
        @required()
        bodilyLocationId : any;
//#endregion bodilyLocationId Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop







}