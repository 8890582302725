<div id="stockwrapper">
  <div class="login-page under-maintainance-page d-flex flex-column p-3 align-items-center" *ngIf="showComponent">
        <div class="m-auto d-flex flex-column flex-lg-row col-12 col-md-8">
      <div class="text-center w-100 d-flex flex-column justify-content-center download-banner-in">
        <a href="/dashboard">
          <span class="mb-5 d-inline-block">
            <img alt="g-trac logo" src="/assets/images/GtracLogoColor.png" width="250">
          </span>
        </a>
        <div class="text-center h6" *ngIf="!isGenerated && !isError">
          <div data-rxwebid="2" class="sk-spinner sk-chart-spinner sk-spinner-wave"
            style="position: relative !important;background: inherit;">
            <div data-rxwebid="3" class="sk-rect1"></div>
            <div data-rxwebid="4" class="sk-rect2"></div>
            <div data-rxwebid="5" class="sk-rect3"></div>
            <div data-rxwebid="6" class="sk-rect4"></div>
            <div data-rxwebid="7" class="sk-rect5"></div>
          </div>
        </div>
        <div class="text-center h1 my-0 error-icon" *ngIf="isError">
          <i class="fa fa-warning"></i>
        </div>
        <div class="text-center h1 my-0 success-icon" *ngIf="!isError && isGenerated">
          <i class="fr-check-confirmation fa-2x mb-4"></i>
        </div>
        <div class="error-desc">
          <h2 class="font-bold">{{message}} {{!isGenerated && !isError ? percentDone + '%' : ''}}</h2>
          <h3>{{!isGenerated && !isError ? notice : ""}}</h3>

        </div>
      </div>
    </div>
  </div>
</div>
