import { Component, Input, OnInit } from "@angular/core";
import { AbstractProductBatch } from "../domain/abstract-product-batch";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { multilingual } from "@rxweb/localization";
import { PlatformLocation } from "@angular/common";

@multilingual("PurchaseOrderValidationPopupComponent")
@Component({
  selector: "app-purchase-order-validation-popup",
  templateUrl: "./purchase-order-validation-popup.component.html",
  styleUrls: ["./purchase-order-validation-popup.component.css"],
})
export class PurchaseOrderValidationPopupComponent
  extends AbstractProductBatch
  implements OnInit
{
  @Input() hide: Function;
  @Input() modalType;
  @Input() supplierEmailList: any[] = [];
  showComponent: boolean = false;
  spin: boolean = false;
  titleClose: string;

  constructor(private location : PlatformLocation) {
    super();
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.showComponent = true;
  }

  get componentName() {
    return "PurchaseOrderValidationPopupComponent";
  }
}
