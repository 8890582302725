import {vOccurrenceTimeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vOccurrenceTimeLookUp extends vOccurrenceTimeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}