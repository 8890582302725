import {CollectionDesignBase,MainCollectionBase,} from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators";
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';
export class CollectionDesign extends CollectionDesignBase implements FileDetailViewModel
{


    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    fileData3: string;
    @prop()
    fileExtention3: string;
    @prop()
    fileName3: string;
    @prop()
    fileData4: string;
    @prop()
    fileExtention4: string;
    @prop()
    fileName4: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;



//#region Generated Reference Properties
//#region mainCollection Prop
mainCollection : MainCollectionBase;
//#endregion mainCollection Prop

//#endregion Generated Reference Properties












}