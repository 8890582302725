import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vFormsDocumentBase {

//#region FormId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:0, allowSorting: true, headerKey: 'HelpSupportListComponent_Id_gh', keyColumn: true})
        formId : number;
//#endregion FormId Prop


//#region name Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'FormsDocumentListComponent_Name_gh', keyColumn: false})
        name : string;
//#endregion name Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: false, headerKey: 'FormsDocumentListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop

// //#region published Prop
// @gridColumn({visible: true, columnIndex:3, allowSorting: false, headerKey: 'HelpSupportListComponent_Published_gh', keyColumn: false,configuredTemplate: { templateName:"isactive" }})
// isActive : boolean;
// //#endregion published Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: false, headerKey: 'FormsDocumentListComponent_Published_gh', keyColumn: false,configuredTemplate: { templateName:"published" }})
        published : boolean;
//#endregion published Prop

}