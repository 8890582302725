import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProjectEventBase {

//#region projectEventMappingID Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'projectEventMappingID', keyColumn: true})
        projectEventMappingID : number;
//#endregion projectEventMappingID Prop


//#region projectID Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectID', keyColumn: false})
        projectID : number;
//#endregion projectID Prop


//#region eventID Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'eventID', keyColumn: false})
        eventID : number;
//#endregion eventID Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop


//#region eventTypeID Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'eventTypeID', keyColumn: false})
        eventTypeID : any;
//#endregion eventTypeID Prop


//#region eventType Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'eventType', keyColumn: false})
        eventType : string;
//#endregion eventType Prop


//#region eventStartDate Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'eventStartDate', keyColumn: false})
        eventStartDate : any;
//#endregion eventStartDate Prop


//#region eventEndDate Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'eventEndDate', keyColumn: false})
        eventEndDate : any;
//#endregion eventEndDate Prop

}