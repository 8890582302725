import {vCustomerChildRelationshipRecordBase,} from '@app/database-models'
//Generated Imports
export class vCustomerChildRelationshipRecord extends vCustomerChildRelationshipRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}