import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductOccassionBase {

//#region occassionId Prop
        @prop()
        occassionId : number;
//#endregion occassionId Prop


//#region occassion Prop
        @required()
        @maxLength({value:150})
        occassion : string;
//#endregion occassion Prop



}