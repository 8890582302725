import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHSEStatusLookUpBase {

//#region hSEStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'hSEStatusId', keyColumn: true})
        hseStatusId : number;
//#endregion hSEStatusId Prop


//#region hSEStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'hSEStatusName', keyColumn: false})
        hseStatusName : string;
//#endregion hSEStatusName Prop

}