import {LegalAgreementCounterPartyMappingBase} from '@app/database-models'
//Generated Imports
export class LegalAgreementCounterPartyMapping extends LegalAgreementCounterPartyMappingBase
{


    counterPartyIds: LegalAgreementCounterPartyMapping[];

    isCorporate : boolean;

    isHeaderChecked : boolean;

    query:string;
//#region Generated Reference Properties


//#endregion Generated Reference Properties











}