import { date, prop, required, maxLength, notEmpty } from '@rxweb/reactive-form-validators';

export class PressMediaDetailBase{
    @prop({defaultValue:0})
    pressMediaId:number;
    @prop()
    @required()
    @notEmpty()
    // @maxLength({value:500})
    article:string;
    @prop()
    // @maxLength({value:255})
    url:string;
    @prop()
    createdOn:Date;
    @prop()
    createdBy:number;
    @prop({defaultValue:false})
    isDeleted:boolean;
    @prop()
    primaryCategoryType:number;
    @prop({defaultValue:true})
    isDraft:boolean;
    @prop()
    @required()
    hashtag:number;
    @prop({defaultValue:true})
    include:boolean;
    @prop()
    fileName:string;
    @prop()
    updatedOn:Date;
    @prop()
    updatedBy:number;
    @prop()
    @required()
    articleType:number;
    @prop({defaultValue:false})
    isMailSent:boolean;
    @prop({defaultValue:false})
    isNewPressMediaAddedMailSent:boolean;
}