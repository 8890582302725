import {vWarehouseRecordBase,} from '@app/database-models'
//Generated Imports
export class vWarehouseRecord extends vWarehouseRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}