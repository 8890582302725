import {ITIncTypeBase,} from '@app/database-models'
//Generated Imports
export class ITIncType extends ITIncTypeBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}