import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { LeaveType } from '@app/models';
import { AbstractLeaveType } from '../domain/abstract-leave-type';
import { Router } from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { LeaveTypeEditComponent } from '../edit/leave-type-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@multilingual("LeaveTypeAddComponent")
@access({ accessLevel: RolePermissionEnums.HolidayLeaveTypes, action: "post" })

@Component({
    selector: "app-leave-type-add",
    templateUrl: './leave-type-add.component.html'
})
export class LeaveTypeAddComponent extends AbstractLeaveType implements OnInit, OnDestroy {
    leaveType: LeaveType;
    subscription: any;
    leaveTypeEdit:any=LeaveTypeEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    constructor(private router: Router,private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.HolidayLeaveTypes;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.titleHelp = getLocalizedValue("Title_Help");
        this.title.setTitle("Leave Type - G-Trac Admin");
        this.leaveType = new LeaveType();
        this.leaveType.statusId = StatusEnum.Active;
        this.leaveType.leaveId = 0;
        this.leaveType.typeColor = "#000000"
        this.leaveTypeFormGroup = this.formBuilder.formGroup(this.leaveType) as IFormGroup<LeaveType>;
        this.showComponent = true;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addLeaveType(isRedirect) {
        this.leaveTypeFormGroup.submitted = true;
        if (this.leaveTypeFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.leaveTypeFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
			            //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/leave-types', data],{ queryParams: { activeTab: 'leave-type-info' }}) : this.router.navigate(['/leave-types']);
            })
        }else{
            for (const key of Object.keys(this.leaveTypeFormGroup.controls)) {
                if (this.leaveTypeFormGroup.controls[key].invalid) { 
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    get componentName(): string {
        return "LeaveTypeAddComponent";
    }
}
