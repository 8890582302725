import {LookupPersonsAffectedCategoryBase,IncidentPersonsAffectedCategoryBase,} from '@app/database-models'
//Generated Imports
export class LookupPersonsAffectedCategory extends LookupPersonsAffectedCategoryBase 
{




//#region Generated Reference Properties
//#region incidentPersonsAffectedCategories Prop
incidentPersonsAffectedCategories : IncidentPersonsAffectedCategoryBase[];
//#endregion incidentPersonsAffectedCategories Prop

//#endregion Generated Reference Properties






































}