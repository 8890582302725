import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ACLPerObjectBase {

//#region aCLPerObjectId Prop
        @prop()
        aCLPerObjectId : number;
//#endregion aCLPerObjectId Prop


//#region objectId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        objectId : number;
//#endregion objectId Prop


//#region objectTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        objectTypeId : number;
//#endregion objectTypeId Prop


//#region customerRoleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerRoleId : number;
//#endregion customerRoleId Prop


//#region deny Prop
        @required()
        deny : boolean;
//#endregion deny Prop



}