import { TemplateConfig } from '@rxweb/grid';

export const GEMSTONE_SERIAL_NO_GRID_TEMPLATE: TemplateConfig = {
    // div: {
    //     childrens: [
    //         {
    //             span: {
    //                 childrens: [{
    //                     text: {
    //                         text: function (e) {
    //                             return this[e.name];
    //                         }
    //                     }
    //                 }]
    //             },
    //             a: {
    //                 class: "text-blue font-weight-bold".split(" "),
    //                 event: {
    //                     click: "onSerialNoClick"
    //                 },
    //                 childrens: [{
    //                     text: {
    //                         text: function (e) {
    //                             if (this.serialNo != null) {
    //                                 return " (" + this.serialNo + ")"
    //                             } else {
    //                                 return "";
    //                             }
    //                         }
    //                     }
    //                 }],
    //                 attributes: {
    //                     href: function (e) {
    //                         if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
    //                             return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
    //                         }
    //                     },
    //                     "ng-reflect-router-link": function (e) {
    //                         if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
    //                             return "/product-piece," + this.productVariantBatchPieceId + "?activeTab=piece-info";
    //                         }
    //                     },
    //                     onClick: "return false;"
    //                 }
    //             }
    //         }
    //     ]
    // } 
        div: {
                attributes: {
                        innerHTML: function (x, y) {
                                return this.status + '<br>' + this.serialNumbers;
                        }
                },
        }
}


export const DESIGN_REDIRECT_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onDesignClick"
                    },
                    childrens: [{
                        text: {
                            text: function (e) {
                                return this[e.name];
                            }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.productId != null || this.productId != undefined) {
                                return "/product-design/" + this.productId + "?activeTab=design-info";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.productId != null || this.productId != undefined) {
                                return "/product-design," + this.productId + "?activeTab=design-info";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    }
}

export const PRODUCT_REDIRECT_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onProductClick"
                    },
                    childrens: [{
                        text: {
                            text: function (e) {
                                return this[e.name];
                            }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.productVariantId != null || this.productVariantId != undefined) {
                                return "/product/" + this.productVariantId + "?activeTab=product-info";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.productVariantId != null || this.productVariantId != undefined) {
                                return "/product," + this.productVariantId + "?activeTab=product-info";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    }
}

export const PIECE_REDIRECT_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onPieceClick"
                    },
                    childrens: [{
                        text: {
                            text: function (e) {
                                return this[e.name];
                            }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                                return "/product-piece," + this.productVariantBatchPieceId + "?activeTab=piece-info";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    }
}