import {ActivityLogTypeBase,ActivityLogBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { vActivityLogType } from '@app/models';
//Generated Imports
export class ActivityLogType extends ActivityLogTypeBase 
{


    activityLogTypeIds:ActivityLogType[];

//#region Generated Reference Properties
//#region activityLogs Prop
    activityLogs : ActivityLogBase[];
//#endregion activityLogs Prop

//#region activityLogs Prop
    @prop()
    activityName : string;
//#endregion activityLogs Prop

//#endregion Generated Reference Properties












}