import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldsCarrierBase {

//#region carrierId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldsCarrierListComponent_CarrierId_gh', keyColumn: true })
        carrierId : number;
//#endregion carrierId Prop


//#region carrierName Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsCarrierListComponent_CarrierName_gh', keyColumn: false })
        carrierName : string;
//#endregion carrierName Prop

}