import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerChildRelationshipBase {

//#region customerRelationshipChildId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'CustomerChildRelationshipListComponent_Id_gh', keyColumn: true})
        customerRelationshipChildId : number;
//#endregion customerRelationshipChildId Prop


//#region status Prop
        @gridColumn({isAscending:true, visible: false, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex:1, allowSorting: true, headerKey: 'CustomerChildRelationshipListComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" }})
        status : string;
//#endregion status Prop


//#region genderName Prop
        @gridColumn({visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex:5, allowSorting: true, headerKey: 'CustomerChildRelationshipListComponent_Gender_gh', keyColumn: false})
        genderName : string;
//#endregion genderName Prop


//#region customerRelationShipStatus Prop
        @gridColumn({visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex:3, allowSorting: true, headerKey: 'CustomerChildRelationshipListComponent_ParRel_gh', keyColumn: false})
        customerRelationShipStatus : string;
//#endregion customerRelationShipStatus Prop


//#region customerRelationShipParentId Prop
        @gridColumn({visible: false, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex:4, allowSorting: true, headerKey: 'CustomerChildRelationshipListComponent_ParentId_gh', keyColumn: false})
        customerRelationShipParentId : number;
//#endregion customerRelationShipParentId Prop


//#region customerRelationshipChildParentName Prop
        @gridColumn({isAscending:true, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex:2, allowSorting: true, headerKey: 'CustomerChildRelationshipListComponent_ParName_gh', keyColumn: false})
        customerRelationshipChildParentName : string;
//#endregion customerRelationshipChildParentName Prop

}