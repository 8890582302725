import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxRateBase {

//#region taxRateId Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'TaxRateListComponent_TaxRateId_gh', keyColumn: true })
        taxRateId : number;
//#endregion taxRateId Prop


//#region taxCategoryName Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'TaxRateListComponent_TaxCategory_gh', keyColumn: false })
        taxCategoryName : string;
//#endregion taxCategoryName Prop


//#region countryName Prop
        @gridColumn({ isAscending:true, style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'TaxRateListComponent_Country_gh', keyColumn: false })
        countryName : string;
//#endregion countryName Prop


//#region stateName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'TaxRateListComponent_StateProvince_gh', keyColumn: false })
        stateName : string;
//#endregion stateName Prop


//#region zip Prop
        @gridColumn({visible: true, columnIndex: 2, allowSorting: true, headerKey: 'TaxRateListComponent_Zip_gh', keyColumn: false })
        zip : string;
//#endregion zip Prop


//#region percentage Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'TaxRateListComponent_Percentage_gh', keyColumn: false })
        percentage : number;
//#endregion percentage Prop


//#region taxCategoryId Prop
        @gridColumn({visible: false, columnIndex: 6, allowSorting: true, headerKey: 'taxCategoryId', keyColumn: false })
        taxCategoryId : number;
//#endregion taxCategoryId Prop

       countryId:number;
       dutyPer:number;

}