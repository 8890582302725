import {AccidentVehicleTypeBase,LookupVehicleTypeBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class AccidentVehicleType extends AccidentVehicleTypeBase 
{




//#region Generated Reference Properties
//#region lookupVehicleType Prop
lookupVehicleType : LookupVehicleTypeBase;
//#endregion lookupVehicleType Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties






































}