import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductMaterialLookUpBase {

//#region productAttributeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'materialId', keyColumn: true})
        materialId : number;
//#endregion productAttributeId Prop


//#region materialName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'materialName', keyColumn: false})
        materialName : string;
//#endregion materialName Prop

}