import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectEventMappingBase {

//#region projectEventMappingID Prop
        @prop()
        projectEventMappingID : number;
//#endregion projectEventMappingID Prop


//#region projectID Prop
        @required()
        projectID : number;
//#endregion projectID Prop


//#region eventID Prop
        @required()
        eventID : number;
//#endregion eventID Prop



}