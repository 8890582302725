import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CountryBase {

//#region countryId Prop
        @prop()
        countryId : number;
//#endregion countryId Prop


//#region name Prop
        @required()
        @maxLength({ value: 100 })
      //  @alpha({ allowWhiteSpace: true })
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region allowsRegistration Prop
        @prop({defaultValue:false})
        allowsRegistration : boolean;
//#endregion allowsRegistration Prop


//#region allowsBilling Prop
        @prop({defaultValue:false})
        allowsBilling : boolean;
//#endregion allowsBilling Prop


//#region allowsShipping Prop
       @prop({defaultValue:false})
        allowsShipping : boolean;
//#endregion allowsShipping Prop


//#region twoLetterISOCode Prop
        @required()
        @maxLength({ value: 2 })
        @notEmpty()
        @trim()
        twoLetterISOCode : string;
//#endregion twoLetterISOCode Prop


//#region threeLetterISOCode Prop
        @required()
        @maxLength({ value: 3 })
        @notEmpty()
        @trim()
        threeLetterISOCode : string;
//#endregion threeLetterISOCode Prop


//#region numericISOCode Prop
        @range({ minimumNumber: 1, maximumNumber: 999 })
        @required()
        @notEmpty()
        @numeric()
        numericISOCode : number;
//#endregion numericISOCode Prop


//#region subjectToVAT Prop
        @prop({defaultValue:false})
        subjectToVAT : boolean;
//#endregion subjectToVAT Prop


//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @required()
        @notEmpty()
        @numeric()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        displayOrder : number;
//#endregion displayOrder Prop


//#region dutyPer Prop
        @required()
        @numeric()
        dutyPer : number;
//#endregion dutyPer Prop


//#region insurancePer Prop
        @required()
        @numeric()
        insurancePer : number;
//#endregion insurancePer Prop


//#region allowPOBShipping Prop
        @prop({defaultValue:false})
        allowPOBShipping : boolean;
//#endregion allowPOBShipping Prop


//#region domain Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        domain : number;
//#endregion domain Prop


//#region regionId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        regionId : number;
//#endregion regionId Prop


//#region sEName Prop
        @prop()
        @trim()
        sEName : string;
//#endregion sEName Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop


//#region languageId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        languageId : number;
//#endregion languageId Prop


//#region parentCountryId Prop
        @prop()
        parentCountryId : number;
//#endregion parentCountryId Prop











}