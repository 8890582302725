import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    visible:false,
    style: { width: "2%","text-align": "initial","margin":"-3px"},
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 9
  })

export class BOMListViewModel{
    
    @gridColumn({style: { width: "1%", "text-align": "initial" },visible: true, columnIndex: 9, headerKey: 'BomDetailComponent_Action_gh',configuredTemplate: { templateName: 'bomCheckbox' },allowSorting:false})
    isChecked : boolean;

    showCostOnly:boolean;
    @gridColumn({
        template:{
            div: {
              isBind: (x) => { 
                if(x.showCostOnly==null || x.showCostOnly==false){
                  return true;
                }
                else{
                  return false;
                }
            },
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onMaterialData"
                            },
                            attributes: {
                              type: "text",
                              value: function (e) {
                                return this.materialName;
                              },
                              // placeholder:"Enter Material Name",
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,style: { width: "10%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 2, allowSorting: false, headerKey: 'BomDetailComponent_Materials_gh', keyColumn: false })
    materialName:string;
    materialId:number;
    @gridColumn({
        template:{
            div: {
              isBind: (x) => { 
                if(x.showCostOnly==null || x.showCostOnly==false){
                  return true;
                }
                else{
                  return false;
                }
            },
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onRateData"
                            },
                            attributes: {
                              type: "text",
                              value: function (e) {
                                return this.exchangeRate;
                              },
                              // placeholder:"Enter Exchange Rate",
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,style: { width: "1%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 6, allowSorting: false, headerKey: 'BomDetailComponent_ExchangeRate_gh', keyColumn: false })
    exchangeRate:number;


    @gridColumn({
        template:{
            div: {
                 childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onCostData"
                            },
                            attributes: {
                              type: "text",
                              value: function (e) {
                                return this.cost;
                              },
                            disabled:true
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,style: { width: "9%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 7, allowSorting: false, headerKey: 'BomDetailComponent_Cost_gh', keyColumn: false })
    cost:any;


    @gridColumn({
        template:{
            div: {
              isBind: (x) => { 
                if(x.showCostOnly==null || x.showCostOnly==false){
                  return true;
                }
                else{
                  return false;
                }
            },
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          textarea:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                            style:{ width: "260px"},
                             event: {
                              input: "onCommentData"
                            },
                            attributes: {
                              type: "text",
                              value: function (e) {
                                return this.comments;
                              },
                              placeholder:"Enter Comments",
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,style: { width: "20%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 8, allowSorting: false, headerKey: 'BomDetailComponent_Comments_gh', keyColumn: false })
    comments:string;

    @gridColumn({
        template:{
            div: {
              isBind: (x) => { 
                if(x.showCostOnly==null || x.showCostOnly==false){
                  return true;
                }
                else{
                  return false;
                }
            },
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onQuantityData"
                            },
                            attributes: {
                              type: "number",
                              value: function (e) {
                                return this.quantity;
                              },
                              min:1,
                              // placeholder:"Enter Quantity",
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,style: { width: "1%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 3, allowSorting: false, headerKey: 'BomDetailComponent_Quantity_gh', keyColumn: false })
    quantity:any;


    @gridColumn({
        template:{
            div: {
              isBind: (x) => { 
                if(x.showCostOnly==null || x.showCostOnly==false){
                  return true;
                }
                else{
                  return false;
                }
            },
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onPriceData"
                            },
                            attributes: {
                              type: "text",
                              value: function (e) {
                                return this.unitPrice;
                              },
                              // placeholder:"Enter Unit Price",
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
    ,parameter:false,style: { width: "7%", "text-align": "initial"}, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'BomDetailComponent_UnitPrice_gh', keyColumn: false })
    unitPrice:any;
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:9,headerTitle: 'RelationShip', keyColumn: false})
    relationShipId : number;
  //#endregion ralationShipName Prop
  
  
  //#region dropdownData Prop
//       @gridColumn({
//          template: {
//              div: {
//                  onDemandSelector: {
//                      selector: 'rx-select', columnName: 'relationShipId',
//                  },
//                  event:{
//                    change:"onSelect"
//                  }
//              }
//          }, headerTitle: "Currency", name: "relationShipId", columnIndex: 5,style: { width: "6%"},allowSorting:false
//       })
//  dropdownData : any;

@gridColumn({style: { width: "5%", "text-align": "left" }, class: ["text-left"], visible: true, headerKey: "BomDetailComponent_Currency_gh", name: "currency", columnIndex: 5,allowSorting:false})
currency:string;

//@gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:9,headerTitle: 'RelationShip',name:"dropdownData", keyColumn: false})
dropdownData:any;
exchangeRates:any;
rowCount:number;
  currencyId:number;
}
