import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Subscription } from "rxjs";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { RxFormBuilder, IFormGroup, async, IAbstractControl } from "@rxweb/reactive-form-validators";
import { DialogViewMode } from "@rxweb/js";
import { Note, TaskDetail } from "@app/models";
import { AbstractNote } from "../domain/abstract-note";
import { List } from "@rxweb/generics";
import {
  NoteMemberMappingBase,
  NoteHastagMappingBase,
  TaskDetailBase,
} from "../../../../models/database-models";
import { ActivatedRoute, NavigationStart } from "@angular/router";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { NotesEnum } from "src/app/enums/notes.enum";
import { MultiLingualData, multilingual } from "@rxweb/localization";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { v4 as uuidv4 } from "uuid";
import { Router } from '@angular/router';
import { TINY_MCE_KEY } from "src/app/domain/system-constant";
import { TinyMCEdraftModel } from "src/app/view-model/tiny-mce-draft-view-model";
import { browserRefresh } from "src/app/components/start/app.component";
import { NotePopUpType } from "src/app/enums/note-popup-type.enum";
import { FormArray, FormGroup } from "@angular/forms";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { PlatformLocation } from "@angular/common";

@multilingual("NoteAddComponent")
@Component({
  selector: "app-note-add",
  templateUrl: "./note-add.component.html",
})
export class NoteAddComponent
  extends AbstractNote
  implements OnInit, OnDestroy
{
  note: Note;
  task: TaskDetail;
  taskDetailFormGroup: IFormGroup<TaskDetailBase>

  subscription: any;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() supplierId: number;
  @Input() companyName: string;
  @Input() noteType: number;
  @Input() eventProjectRestricted: boolean;
  testData: any;
  userIdArray: any[];
  olduserIdArray: any[];
  submitted: boolean;
  entityTypeIdForEditorDocuments = EditorDocumentsEnum.Notes;
  isFromSalesOrder: boolean = false;
  checkAllUser: boolean = false;
  @ViewChild("selectSearch") input: ElementRef;
  titleClose: string;
  restrictedSubjectPrefix: string = "";
  apiKey: any = TINY_MCE_KEY;
  tinymceRetriveDraft: Array<TinyMCEdraftModel> = [];
  draftSaveInterval: any;
  noteDescriptionChangeDetect$: Subscription;
  isAllUserChangeDetetct$: Subscription;
  isViewByPeopleChangeDetect$: Subscription;
  subjectChangeDetect$: Subscription;
  hasTagsChangeDetect$: Subscription;
  isDisplayTeamMemberChangeDetect$: Subscription;
  toEmailsChangeDetect$: Subscription
  ccEmailsChangeDetect$: Subscription;
  noteDraftId: number;
  _this;
  isDataUpdated: boolean = false;
  isFormDirty: boolean = false;
  loggedInUserId: number = 0;
  showColumns: boolean = false;
  isfabergeUsersSelected: boolean = false;
  showToEmails: boolean = true;
  showCCEmails: boolean = true;
  showTinyMCEEditor: boolean = true;
  isForGINA:boolean = false;
  fromUserEmail:string = "";
  @ViewChild(EditorComponent, { static: false }) editorComponent: EditorComponent;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: RxFormBuilder,
    private browserStorage: BrowserStorage,
    private blobState: BlobUploadsViewStateService,
    private router: Router,
    private location: PlatformLocation
  ) {
    super();
    this._this = this;
    this.loggedInUserId = this.browserStorage.local.get('userId');
    location.onPopState(() => { this.hide(), this.dialog.hide() });
    if (localStorage.getItem("userName"))
      this.loginUserName = this.browserStorage.local.get("userName", false);
  }
  ngAfterViewInit(): void {

  }


  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.restrictedSubjectPrefix = getLocalizedValue(
      "Note_RestrictedNoteSubjectPrefix"
    );
    this.restrictedBodyPrefix = getLocalizedValue("Note_RestrictedNoteMessage");
    this.userIdArray = new Array<any>();
    this.olduserIdArray = new Array<any>();
    this.userId = this.browserStorage.local.get("userId");
    this.fromUserEmail = this.browserStorage.local.get("userEmail",true);
    // console.log(this.fromUserEmail);
    this.submitted = false;
    this.post({
      body: { query: '{}' },
      path: "api/NoteDbLookups/NotesHasTagPeopleList",
    }).subscribe(async (respons: any) => {
      this.notesLookups = respons;
      this.notesLookups = this.notesLookups[0];
      this.toUserList = new List<any>(this.notesLookups.peopleList);
      this.ccUserList = new List<any>(this.notesLookups.peopleList);
      this.note = new Note();
      this.note.entityId = this.entityId;
      this.note.supplierId=this.supplierId;
      this.note.companyName=this.companyName;
      this.note.entityTypeId = this.entityTypeId;
      this.note.createdBy = 1;
      this.note.createdOn = new Date();
      this.note.noteType = 0;
      this.note.isManual = true;
      this.note.noteId = 0;
      this.note.from = this.fromUserEmail;
      this.checkAllUser =
        this.entityTypeId == NotesEnum.ProjectNotes
          ? (this.checkAllUser = true)
          : (this.checkAllUser = false);
      let noteMemberMapping = new NoteMemberMappingBase();
      noteMemberMapping.isTo = true;
      noteMemberMapping.noteId = 0;
      noteMemberMapping.noteTeammembermappingId = 0;
      noteMemberMapping.typeofNote = this.noteType;
      this.note.noteMemberMappings = new Array<NoteMemberMappingBase>();
      this.note.noteHastagMappings = new Array<NoteHastagMappingBase>();
      this.note.taskDetailMappings = new Array<TaskDetailBase>();
      await this.getAddnoteDraft();
      this.showComponent = true;

      let loadTinyMce = setInterval(x => {
        if (document.querySelector(".tox-tinymce")) {
          this.isAllUserChangeDetetct$ = this.noteFormGroup.controls.isAllUser.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.isViewByPeopleChangeDetect$ = this.noteFormGroup.controls.isViewByPeople.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.subjectChangeDetect$ = this.noteFormGroup.controls.subject.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.hasTagsChangeDetect$ = this.noteFormGroup.controls.hasTags.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.toEmailsChangeDetect$ = this.noteFormGroup.controls.toEmails.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.isDisplayTeamMemberChangeDetect$ = this.noteFormGroup.controls.isDisplayTeamMember.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.noteDescriptionChangeDetect$ = this.noteFormGroup.controls.noteDescription.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          this.ccEmailsChangeDetect$ = this.noteFormGroup.controls.ccEmails.valueChanges.subscribe(change => {
            this.isDataUpdated = true;
            this.isFormDirty = true;
          })
          clearInterval(loadTinyMce)
        }

      }, 500)

      this.ccUserSplits = new Array<string>();
      this.toUserSplits = new Array<string>();
      this.isFromSalesOrder = this.entityTypeId == NotesEnum.OrderNotes ? true : false;
      this.draftSaveInterval = setInterval(() => {
        if (this.isDataUpdated && this.isFormDirty) {
          this.saveAddNotedraft()
        }
      }, 15000)

      if(this.entityTypeId == NotesEnum.LegalAgreementNotes){
        this.addLegalAgreementNameToSubject();
      }
      else if(this.entityTypeId == NotesEnum.IncidentAccidentNearDashMissNotes){
        this.isForGINA = true;
        this.setDefaultSubjectAndHashTag();
      }
    });

  }

  newTask() {
    var objTask = new TaskDetailBase();
    objTask.taskDetailId = 0;
    objTask.assignedByUserId = this.loggedInUserId;
    objTask.assignedByUserName = this.loginUserName;
    objTask.createdBy = this.loggedInUserId;
    objTask.createdOn = new Date();
    objTask.isCompleted = false;
    return this.formBuilder.formGroup(TaskDetailBase, objTask) as IFormGroup<TaskDetailBase>;
  }
  addNewTask() {
    this.isShowTask = true;
    const taskDetailFormArray = this.noteFormGroup.get("taskDetailMappings") as FormArray;
    taskDetailFormArray.push(this.newTask())
  }

  remove(index, taskDetail: IFormGroup<TaskDetailBase>) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        if (taskDetail.value.taskDetailId == 0) {
          if (this.taskDetailFormArray.length > 0) {
            const form = this.noteFormGroup.get('taskDetailMappings') as FormArray
            form.removeAt(index);
            if (form.length == 0) {
              this.isShowTask = false;
            }
            //this.toastr.warning("Data Deleted Successfully");
          }
        }
      }
      this.dialog.hide()
    })
  }

  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Note_Task");
    if (existsAlert) {
      return existsAlert;
    }
  }

  async addNotes() {
    // this.noteFormGroup.patchValue({
    //   createdOn:new Date()
    // })
    this.noteFormGroup.value.noteDescription = this.decodeEntities(this.noteFormGroup.value.noteDescription);
    this.noteFormGroup.submitted = true;
    if (this.noteFormGroup.valid) {
      if (this.noteFormGroup.value.toEmails != null) {
        this.olduserIdArray = this.noteFormGroup.value.toEmails.split(",");
        //  this.userIdArray=this.noteFormGroup.value.toEmails.split(',');
        this.userIdArray = this.olduserIdArray.filter(
          (thing, i, arr) => arr.findIndex((t) => t === thing) === i
        );
        this.noteFormGroup.patchValue({
          toEmails: "",
        });
        var newToEmails = "";
        this.userIdArray.forEach((x) => {
          newToEmails += "," + x;
        });
        this.noteFormGroup.patchValue({
          toEmails: newToEmails.substring(1, newToEmails.length),
        });
      }
      if (this.noteFormGroup.value.ccEmails != null) {
        this.olduserIdArray = this.noteFormGroup.value.ccEmails.split(",");
        //  this.userIdArray=this.noteFormGroup.value.toEmails.split(',');
        this.userIdArray = this.olduserIdArray.filter(
          (thing, i, arr) => arr.findIndex((t) => t === thing) === i
        );
        this.noteFormGroup.patchValue({
          ccEmails: "",
        });
        var newCCEmails = "";
        this.userIdArray.forEach((x) => {
          newCCEmails += "," + x;
        });
        this.noteFormGroup.patchValue({
          ccEmails: newCCEmails.substring(1, newCCEmails.length),
        });
      }
      this.bindTeamMembersMapping(this.formBuilder);
      this.bindHastags(this.formBuilder);
      this.bindTaskDetails();
      this.submitted = true;
      this.spin = true;


      if (this.noteFormGroup.value.file) {
        let fileDataObj = this.noteFormGroup.value.file;
        let fileNames: string[] = [];
        let fileUploadRes = [];
        try {

          // IF FAILED TO UPLOAD
          for (const x of this.noteFormGroup.value.file) {
            let newGUID = uuidv4();
            let fileName = `${newGUID}_${new Date().toISOString()}.${x.name
              .split(".")
              .pop()}`;
            var res = await this.blobState.uploadItems(x, fileName);
            fileUploadRes.push(res);
            fileNames.push(fileName);
          }

          if (fileUploadRes.includes(x => x._response.status != 200) && fileUploadRes.includes(res._response.status != 201)) {
            console.log(
              "Failed to upload file to blob from client!",
              fileUploadRes.find(x => x._response.status != 200 || res._response.status != 201).errorCode
            );
            this.noteFormGroup.patchValue({
              blobFileName: "",
              file: fileDataObj,
            });
          } else {
            // IF SUCCESS
            this.noteFormGroup.patchValue({
              blobFileName: fileNames.join(","),
            });
            this.callNotesPostAPI();
          }
        } catch (error) {
          console.log(error);
          this.noteFormGroup.patchValue({
            blobFileName: "",
            file: fileDataObj,
          });
        }
      } else {
        this.callNotesPostAPI();
      }
    }
  }

  saveAsDraft(){
    if(this.isFormDirty && this.isDataUpdated){
      this.saveAddNotedraft();
      this.isDataUpdated = true;
      this.isFormDirty = false;
    }
  }

  private callNotesPostAPI() {
    let fileDataObj = null;
    if (this.noteFormGroup.value.file) {
      fileDataObj = this.noteFormGroup.value.file;
      this.noteFormGroup.patchValue({
        file: null,
        supplierId:this.supplierId,
        companyName:this.companyName
      });
    }
    this.noteFormGroup.value.noteDescription = this.decodeEntities(this.noteFormGroup.value.noteDescription);
    clearInterval(this.draftSaveInterval);
    this.post({ body: this.noteFormGroup.toFormData() }).subscribe(
      (data: { documentId: number; noteId: number }) => {
        this.spin = false;
        var existsAlert = getLocalizedValue("Data_Added");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
        this.deleteAddNoteDraft();

        // Fix FireFox Bug Ticket #259650
        this.showTinyMCEEditor = false;
        this.editorComponent.ngOnDestroy();
        this.hide();
      },
      (err) => {
        this.noteFormGroup.patchValue({
          file: fileDataObj,
        });
      },
      () => { }
    );
  }

  bindTo() {
    return this.toUserList;
  }

  bindCC() {
    return this.ccUserList;
  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    clearInterval(this.draftSaveInterval);
    if (this.noteDescriptionChangeDetect$) {
      this.noteDescriptionChangeDetect$.unsubscribe();
    }
    if (this.isViewByPeopleChangeDetect$) {
      this.isViewByPeopleChangeDetect$.unsubscribe();
    }
    if (this.toEmailsChangeDetect$) {
       this.toEmailsChangeDetect$.unsubscribe();
    }
    if (this.subjectChangeDetect$) {
      this.subjectChangeDetect$.unsubscribe();
    }
    if (this.isAllUserChangeDetetct$) {
      this.isAllUserChangeDetetct$.unsubscribe();
    }
    if (this.isDisplayTeamMemberChangeDetect$) {
      this.isDisplayTeamMemberChangeDetect$.unsubscribe();
    }
    if (this.hasTagsChangeDetect$) {
      this.hasTagsChangeDetect$.unsubscribe();
    }
    if(this.ccEmailsChangeDetect$) {
      this.ccEmailsChangeDetect$.unsubscribe();
    }
  }

  hidePopup() {
    if (this.isFormDirty) {
      if (!this.hasSpecificValues(this.noteFormGroup, this.eventProjectRestricted)) {
        if(this.noteDraftId > 0){
          this.delete({ path: "api/NoteDrafts", body: null, params: [this.noteDraftId] }).subscribe((data) => {
            this.showTinyMCEEditor = false;
            this.editorComponent.ngOnDestroy();
            this.hide();
          })
        }
        else{
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        }
      }
      else {
        var multilingualData = MultiLingualData.get(this.componentName) as any;
        this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
        this.dialog.defaultConfig.showIcon = true;
        this.dialog.designClass.button.cancel = ["btn-cancle", "sweet-alert-cancel-button",];
        this.dialog.defaultConfig.text.heading = multilingualData["NoteAddEditComponent_CloseMessageTitle_t_t"];
        this.dialog.defaultConfig.text.ok = multilingualData["NoteAddEditComponent_CloseMessage_Yes_t_t"];
        this.dialog.defaultConfig.text.cancel = multilingualData["NoteAddEditComponent_CloseMessage_No_t_t"];
        this.dialog
          .show("", DialogViewMode.okWithCancel)
          .then((t) => {
            if (t === DialogEnum.Ok) {
              this.dialog.hide();
              // Fix FireFox Bug Ticket #259650
              this.showTinyMCEEditor = false;
              this.editorComponent.ngOnDestroy();
              this.hide();
            }
            else {
              this.dialog.hide();
            }
          });
      }
    }
    else {
      this.showTinyMCEEditor = false;
      this.editorComponent.ngOnDestroy();
      this.hide();
    }
  }

  changeRestrictionType(checkbox) {
    var isRestricted = checkbox.target.value;
    if (this.eventProjectRestricted) {
      if (isRestricted == "true") {
        var noteBody = this.getEventProjectRestrictedNoteData(false);
        this.noteFormGroup.controls.noteDescription.setValue(
          `<p id="restricted-event-project-notice" style="color : red;">` +
          (this.restrictedEventProjectBodyPrefix != undefined &&
            this.restrictedEventProjectBodyPrefix != ""
            ? this.restrictedEventProjectBodyPrefix
            : "Restricted [Event/Project] Note") +
          "</p>" +
          '<p id="restricted-note-notice" style="color:red;">' +
          (this.restrictedBodyPrefix != undefined &&
            this.restrictedBodyPrefix != ""
            ? this.restrictedBodyPrefix
            : "Restricted Note") +
          "</p>" +
          noteBody
        );
        this.setNoteSubject(true);
      } else {
        var noteBody = this.getEventProjectRestrictedNoteData(true);
        this.noteFormGroup.controls.noteDescription.setValue(noteBody);
        this.setNoteSubject(false);
      }
    } else {
      if (isRestricted == "true") {
        this.noteFormGroup.controls.noteDescription.setValue(
          `<p id="restricted-note-notice" style="color:red;">` +
          (this.restrictedBodyPrefix != undefined &&
            this.restrictedBodyPrefix != ""
            ? this.restrictedBodyPrefix
            : "Restricted Note") +
          "</p>" +
          (this.noteFormGroup.value.noteDescription == "" ? "<br>" : "") +
          this.noteFormGroup.controls.noteDescription.value
        );
        this.setNoteSubject(true);
      } else if (isRestricted == "false") {
        var parser = new DOMParser();
        var doc = parser.parseFromString(
          this.noteFormGroup.value.noteDescription,
          "text/html"
        );
        var noticeElement = doc.getElementById("restricted-note-notice");
        if (noticeElement != null) {
          if (noticeElement.nextSibling.nodeName == "BR") {
            noticeElement.nextSibling.remove();
          }
          noticeElement.remove();
          this.noteFormGroup.controls.noteDescription.setValue(
            doc.getElementsByTagName("BODY")[0].innerHTML
          );
        }
        this.setNoteSubject(false);
      }
    }
  }
  getEventProjectRestrictedNoteData(restirectedNote: boolean) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(
      this.noteFormGroup.value.noteDescription,
      "text/html"
    );
    var noticeElement = doc.getElementById("restricted-event-project-notice");
    if (restirectedNote) {
      var restrictedElement = doc.getElementById("restricted-note-notice");
      if (restrictedElement != null) {
        if (restrictedElement.nextSibling.nodeName == "BR") {
          restrictedElement.nextSibling.remove();
        }
        restrictedElement.remove();
        return doc.getElementsByTagName("BODY")[0].innerHTML;
      } else {
        return doc.getElementsByTagName("BODY")[0].innerHTML;
      }
    } else {
      if (noticeElement != null) {
        if (noticeElement.nextSibling.nodeName == "BR") {
          noticeElement.nextSibling.remove();
        }
        noticeElement.remove();
        return doc.getElementsByTagName("BODY")[0].innerHTML;
      } else {
        return "";
      }
    }
  }
  setNoteSubject(restirectedNote: boolean) {
    if (restirectedNote) {
      let subjectVal = this.restrictedSubjectPrefix + this.noteFormGroup.controls.subject.value;
      if(this.entityTypeId == NotesEnum.LegalAgreementNotes){
        if(subjectVal.length>=250){
          subjectVal = subjectVal.substring(0, 247);
          subjectVal += '...';
        }
      }
      this.noteFormGroup.controls.subject.setValue(subjectVal);
    } else {
      if (
        this.noteFormGroup.value.subject.indexOf(this.restrictedSubjectPrefix) >
        -1
      ) {
        var newSubject = this.noteFormGroup.value.subject.replace(
          this.restrictedSubjectPrefix,
          ""
        );
        this.noteFormGroup.controls.subject.setValue(newSubject);
      }
    }
  }

  addLegalAgreementNameToSubject() {
    if(this.noteFormGroup.controls.subject.value == ""){
      this.lookup([{ path: "api/LegalAgreements", params: [this.entityId], propName: "legalAgreement" }]).subscribe((res:any)=>{
        let agreementName = res.legalAgreement.name + " - ";
        if(agreementName.length>=250){
          agreementName = agreementName.substring(0, 247);
          agreementName += '...';
        }
        this.noteFormGroup.controls.subject.setValue(agreementName);
      });
    }
  }

  setDefaultSubjectAndHashTag(){
    if(this.noteFormGroup.controls.subject.value == ""){
      let defSubject = getLocalizedValue("Note_DefaultSubjectGINA");
      this.noteFormGroup.controls.subject.setValue(defSubject);
    }
    let defHashTag = this.notesLookups.defaultHasTagsGINA[0].defaultHasTagIds;
    this.noteFormGroup.controls.hasTags.setValue(defHashTag);
  }

  get componentName(): string {
    return "NoteAddComponent";
  }

  /**
   * @setAddnoteDraft this function is called when user clicks on close button or cross icon with unsaved changes in notes.
   * it will open one confirmation pop-up
   * - on save : draft will save.
   * - on discard : drat will be remove if exists.
   */
  setAddnoteDraft() {
    var multilingualData = MultiLingualData.get(this.componentName) as any;
    this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
    this.dialog.defaultConfig.showIcon = true;
    this.dialog.designClass.button.cancel = ["btn-cancle", "sweet-alert-cancel-button",];
    this.dialog.defaultConfig.text.heading = multilingualData["NoteAddEditComponent_DraftMessageTitle_t_t"];
    this.dialog.defaultConfig.text.ok = "Save as Draft";
    this.dialog.defaultConfig.text.cancel = "Close";
    this.dialog
      .show(multilingualData["NoteAddEditComponent_DraftMessageBody_t_t"], DialogViewMode.okWithCancel)
      .then((t) => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.saveAddNotedraft();

          // Fix FireFox Bug Ticket #259650
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        }
        else {
          this.dialog.hide();
          this.deleteAddNoteDraft();

          // Fix FireFox Bug Ticket #259650
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        }
      });

  }

  /**
   * @getAddnoteDraft This function is called on OnInit.
   * it will patch the draft data in form control if any draft found in database
   */
  async getAddnoteDraft() {
    this.spin = true;
    var data: TinyMCEdraftModel = await this.get<TinyMCEdraftModel>({ path: "api/NoteDrafts", queryParams: { EntityId: this.entityId, EntityTypeId: this.entityTypeId, ModuleId: NotePopUpType.AddNote } }).toPromise();
    if (data != null) {
      this.noteDraftId = data.noteDraftId;
      this.note.noteDescription = data.noteDescription;
      this.note.toEmails = data.sendTo;
      this.note.ccEmails = data.sendCc;
      this.note.hasTags = data.hashtags;
      this.note.subject = data.noteSubject;
      this.note.isDisplayTeamMember = data.isRestrict;
      this.note.isViewByPeople = data.isViewByPeople;
      this.note.isAllUser = data.addAllUserinProject;
      //this.isDataUpdated = true;
      //this.isFormDirty = true;
      if (this.eventProjectRestricted) {
        if (this.entityTypeId == NotesEnum.EventNotes) {
          this.restrictedEventProjectBodyPrefix = getLocalizedValue(
            "Note_Event_Restricted_PrefixBody"
          );
        } else {
          this.restrictedEventProjectBodyPrefix = getLocalizedValue(
            "Note_Project_Restricted_PrefixBody"
          );
        }
      }
    }
    else {
      this.isDataUpdated = true;
      if (this.eventProjectRestricted) {
        if (this.entityTypeId == NotesEnum.EventNotes) {
          this.restrictedEventProjectBodyPrefix = getLocalizedValue(
            "Note_Event_Restricted_PrefixBody"
          );
        } else {
          this.restrictedEventProjectBodyPrefix = getLocalizedValue(
            "Note_Project_Restricted_PrefixBody"
          );
        }
        this.note.noteDescription =
          '<p id="restricted-event-project-notice" style="color:red;">' +
          (this.restrictedEventProjectBodyPrefix != undefined &&
            this.restrictedEventProjectBodyPrefix != ""
            ? this.restrictedEventProjectBodyPrefix
            : this.entityTypeId == NotesEnum.EventNotes
              ? "This is a Restricted Event and the G-Trac file is only accessible by the persons specified on the “Involved” tab"
              : "This is a Restricted Project and the G-Trac file is only accessible by the persons specified on the “Involved” tab") +
          "</p>" + "<br>";
      }
    }
    this.noteFormGroup = this.formBuilder.formGroup(Note,
      this.note
    ) as IFormGroup<Note>;
    this.spin = false;
  }

  selectFabergeUKUsers() {
    this.showToEmails = false;
    this.isfabergeUsersSelected = true;
    this.spin = true;
    this.post({
      body: { query: '{ "isfabergeUsersSelected" : true}' },
      path: "api/NoteDbLookups/NotesHasTagPeopleList",
    }).subscribe(async (res: any) => {
      this.toUserList = new List<any>();
      this.toUserList = new List<any>(res[0].peopleList);
      this.ccUserList = new List<any>();
      this.ccUserList = new List<any>(res[0].peopleList);
      this.bindTo();
      this.bindCC();
      this.noteFormGroup.controls.toEmails.setValue(res[0].fabergeUKUsers)
      this.spin = false;
      setTimeout(() => {
      this.showToEmails = true;
    }, 100);
    })
  }

  removeFabergeUKUsers() {
    this.showToEmails = false;
    this.isfabergeUsersSelected = false;
    this.toUserList = new List<any>();
    this.toUserList = new List<any>(this.notesLookups.peopleList);
    this.ccUserList = new List<any>();
    this.ccUserList = new List<any>(this.notesLookups.peopleList);
    this.noteFormGroup.controls.toEmails.setValue('');
    setTimeout(() => {
      this.showToEmails = true;
    }, 100);
  }
  /**
   * @saveAddNotedraft This function is called on draft save button click and after every 15 sec if any unsaved changes detected in notes.
   * - it will store entityId, entityTypeId, notePopupType, sendTo, hashtags, noteSubject, noteDescription, isRestrict
   */
  saveAddNotedraft() {
      var draft: TinyMCEdraftModel = {
        entityId: this.entityId,
        entityTypeId: this.entityTypeId,
        notePopupType: NotePopUpType.AddNote,
        sendTo: this.noteFormGroup.controls.toEmails.value,
        sendCc: this.noteFormGroup.controls.ccEmails.value,
        hashtags: this.noteFormGroup.controls.hasTags.value,
        noteSubject: this.noteFormGroup.controls.subject.value,
        noteDescription: this.noteFormGroup.controls.noteDescription.value,
        isRestrict: this.noteFormGroup.controls.isDisplayTeamMember.value,
        isViewByPeople: this.noteFormGroup.controls.isViewByPeople.value,
        addAllUserinProject: this.noteFormGroup.controls.isAllUser.value,
        hasBlankDescription: this.isActualNoteDescriptionBlank(this.noteFormGroup, this.eventProjectRestricted)
      }

      this.post({ path: "api/NoteDrafts", body: draft }).subscribe((data: number) => {
        this.noteDraftId = data;
        this.spin = false;
        this.isDataUpdated = data==0;
        this.isFormDirty = false;
      });
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    return false;
  }


  /**
   * @deleteAddNoteDraft This function is called on draft discard button click and on Note save button.
   * - it will delete draft if draft id found.
   */
  deleteAddNoteDraft() {
    if (this.noteDraftId) {
      this.delete({ path: "api/NoteDrafts", body: null, params: [this.noteDraftId] }).subscribe((data) => { })
    }
  }
}
