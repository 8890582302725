import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CarnetsProductBase {

//#region carnetProductID Prop
        @prop()
        carnetProductID : number;
//#endregion carnetProductID Prop


//#region carnetProductGUID Prop
        @required()
        carnetProductGUID : any;
//#endregion carnetProductGUID Prop


//#region carnetID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        carnetID : number;
//#endregion carnetID Prop


//#region productVariantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region productVariantBatchPieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop







}