import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHelpSupportBase {

//#region helpSupportId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'HelpSupportListComponent_Id_gh', keyColumn: true})
        helpSupportId : number;
//#endregion helpSupportId Prop


//#region name Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'HelpSupportListComponent_Name_gh', keyColumn: false})
        name : string;
//#endregion name Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'HelpSupportListComponent_DisplayOrderId_gh', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'HelpSupportListComponent_Published_gh', keyColumn: false,configuredTemplate: { templateName:"published" }})
        published : boolean;
//#endregion published Prop

}