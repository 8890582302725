import {GemStoneGFEnhancementTypeMappingBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneGFEnhancementTypeMapping extends GemStoneGFEnhancementTypeMappingBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfield Prop
gemStoneGemfield : GemStoneGemfieldBase;
//#endregion gemStoneGemfield Prop

//#endregion Generated Reference Properties












}