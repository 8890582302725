import { actionColumn, gridColumn } from "@rxweb/grid";

@actionColumn({
  allowSorting: false,
  style: { width: "7%" },
  configuredTemplate: { templateName: 'listModel' }, columnIndex: 11, headerTitle: "Item Purchased"
})
export class FabergeOrderListViewModelBase {
  //#region orderId Prop
  @gridColumn({ parameter: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: 'redirectOrderLink' }, visible: true, columnIndex: 0, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Order_gh', keyColumn: true })
  orderId: number;
  //#endregion orderId Prop

  //#region orderDate Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 1, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Date_gh', keyColumn: false })
  orderDate: Date;
  //#endregion orderDate Prop

  //#region stOrderDate Prop
  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Date_gh', keyColumn: false })
  stOrderDate: string;
  //#endregion stOrderDate Prop

  //#region orderStatus Prop
  @gridColumn({ parameter: false, style: { width: "9%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "peopleCorporateOrderStatus" }, visible: true, columnIndex: 3, allowSorting: true, headerKey: 'CorporateOrdersListComponent_OrderStatus_gh', keyColumn: false })
  orderStatus: string;
  //#endregion orderStatus Prop

  //#region paymentStatus Prop
  @gridColumn({ parameter: false, style: { width: "8%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "peopleCorporatePaymentStatus" }, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'CorporateOrdersListComponent_PaymentStatus_gh', keyColumn: false })
  paymentStatus: string;
  //#endregion paymentStatus Prop

  //#region shippingStatus Prop
  @gridColumn({ parameter: false, style: { width: "9%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "peopleCorporateShippingStatus" }, visible: true, columnIndex: 5, allowSorting: true, headerKey: 'CorporateOrdersListComponent_ShippingStatus_gh', keyColumn: false })
  shippingStatus: string;
  //#endregion shippingStatus Prop

  //#region orderTotal Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Total_gh', keyColumn: false })
  orderTotal: number;
  //#endregion orderTotal Prop

  //#region stOrderTotal Prop
  @gridColumn({ parameter: false, style: { width: "9%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Total_gh', keyColumn: false })
  stOrderTotal: string;
  //#endregion stOrderTotal Prop

  //#region productCount Prop
  @gridColumn({ parameter: false, style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Quantity_gh', keyColumn: false })
  productCount: number;
  //#endregion productCount Prop

  @gridColumn({ parameter: false, style: { width: "27%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'CorporateOrdersListComponent_SalesChannel_gh', keyColumn: false })
  salesChannelName: string;

  //#region poaCallMe Prop
  @gridColumn({
    template: {
      div: {
        childrens: [{
          span: {
            attributes: {
              innerHTML: function (x, y) {
                return this.poaCallMe;
              }
            },
          }
        }]
      }
    }, parameter: false, style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'CorporateOrdersListComponent_CallMePOA_gh', keyColumn: false
  })
  poaCallMe: string;
  //#endregion poaCallMe Prop

  //#region poaCallMe Prop
  @gridColumn({
    template: {
      div: {
        childrens: [{
          span: {
            attributes: {
              innerHTML: function (x, y) {
                return this.customerNameUrl;
              }
            },
          }
        }]
      }
    }, parameter: false, style: { width: "10%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'CorporateOrdersListComponent_Customer_gh', keyColumn: false
  })
  customerNameUrl: string;
  //#endregion poaCallMe Prop

//#292772//delete button added in sales order list
//Note: if anyone is change columnName, please update also in ts file, we are removing this column for corporate and people
  //#region deleteOrder Prop
  @gridColumn({
    visible: true,
    name: "deleteOrder",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 12, headerKey: "CorporateOrdersListComponent_Action_gh"
  })
  deleteOrder: any;
  //#endregion deleteOrder Prop
  
  totalNumberPieces: number;
  totalOrderAmount: number;
  stTotalOrderAmount: string;
  currencyCode: string;
  totalCount: number;
  orderStatusId: number;
  paymentStatusId: number;
  shippingStatusId: number;
}
