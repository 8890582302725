import {DiscountBase,} from '@app/database-models'
//Generated Imports
export class Discount extends DiscountBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}