import { TemplateConfig } from '@rxweb/grid';
import { debug } from 'util';

export const CURRENT_PURCHASE_LIST_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                var guest = "";
                if (this.userId == null) {
                  guest = "Guest"
                }
                return guest;
              }
            }
          }]
        },
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onEmailClick"
          },
          childrens: [{
            text: {
              text: function (e) {
                if (this.userId != null) {
                  return this.email;
                  }
              }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users," + this.userId + "?activeTab=user-edit";
              }
            }
          }
        },
        text: {
          text: function (e) {
            return ", Last access : " + this[e.name];
          }
        }
      }
    ]
  },
};