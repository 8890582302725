import { prop, required, propObject, trim, numeric } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentEventFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "eventName";
    }

    @prop({ defaultValue: "" })
    eventStartDate: Date;

    @prop({ defaultValue: "" })
    eventEndDate: Date;

    @prop({ defaultValue: "" })
    eventName: string;

    @prop({ defaultValue: "" })
    @numeric()
    eventId: string;

    @prop({ defaultValue: 0 })
    eventOwner: number;

    @prop({ defaultValue: 0 })
    eventType: number;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}
