import {vEmailAccountLookUpBase,} from '@app/database-models'
//Generated Imports
export class vEmailAccountLookUp extends vEmailAccountLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}