import { actionColumn, gridColumn } from "@rxweb/grid"

@actionColumn({
    allowSorting: false,
    style: { width: "1%", "text-align": "left" },
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})


export class vBookingGemstonesBase {

    @gridColumn({ style: { width: "0.01%", "text-align": "initial" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region gemstoneId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" },class: ["text-left"], visible: false, columnIndex: 1, allowSorting: true, headerKey: 'gemstoneId', keyColumn: true })
    gemstoneId: number;
    //#endregion gemstoneId Prop 
    
    //#region gemstonesId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" },class: ["text-left"], visible: true, parameter:false,columnIndex: 2, allowSorting: true, headerKey: 'GemstoneListComponent_GemstonesId_gh', keyColumn: false, configuredTemplate: {templateName: "gemstoneRedirect"} })
    gemstonesId: number;
    //#endregion gemstonesId Prop 

    //#region startDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" },class: ["text-left"], visible: true, parameter:false,columnIndex: 3, allowSorting: true, headerKey: 'GemstoneListComponent_StartDate_gh', keyColumn: false })
    startDate: string;
    //#endregion startDate Prop

    //#region endDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" },class: ["text-left"], visible: true, parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'GemstoneListComponent_EndDate_gh', keyColumn: false })
    endDate: string;
    //#endregion endDate Prop

    //#region status Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 5, allowSorting: true, headerKey: 'GemstoneListComponent_Status_gh', keyColumn: false })
    status: number;
    //#endregion status Prop

    //#region categoryId Prop
    @gridColumn({ visible: false, columnIndex: 6, allowSorting: true, headerKey: 'categoryId', keyColumn: false })
    categoryId: number;
    //#endregion categoryId Prop

    //#region categoryName Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 7, allowSorting: true, headerKey: 'GemstoneListComponent_CategoryName_gh', keyColumn: false })
    categoryName: string;
    //#endregion categoryName Prop

    //#region totalCarats Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 8, allowSorting: true, headerKey: 'GemstoneListComponent_TotalCarats_gh', keyColumn: false })
    totalCarats: number;
    //#endregion totalCarats Prop

    //#region cutTypeId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerKey: 'cutTypeId', keyColumn: false })
    cutTypeId: number;
    //#endregion cutTypeId Prop

    //#region cutType Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 10, allowSorting: true, headerKey: 'GemstoneListComponent_CutType_gh', keyColumn: false })
    cutType: string;
    //#endregion cutType Prop

    //#region color Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 11, allowSorting: true, headerKey: 'GemstoneListComponent_Color_gh', keyColumn: false })
    color: string;
    //#endregion color Prop

    //#region totalCost Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 12, allowSorting: true, headerKey: 'GemstoneListComponent_TotalCost_gh', keyColumn: false })
    totalCost: number;
    //#endregion totalCost Prop

    //#region locationId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 13, allowSorting: true, headerKey: 'locationId', keyColumn: false })
    locationId: number;
    //#endregion locationId Prop

    //#region locationName Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 14, allowSorting: true, headerKey: 'GemstoneListComponent_LocationName_gh', keyColumn: false })
    locationName: string;
    //#endregion locationName Prop

    //#region supplierId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 15, allowSorting: true, headerKey: 'supplierId', keyColumn: false })
    supplierId: number;
    //#endregion supplierId Prop

    //#region supplier Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 16, allowSorting: true, headerKey: 'GemstoneListComponent_Supplier_gh', keyColumn: false })
    supplier: number;
    //#endregion supplier Prop

    //#region tripGemstoneId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 17, allowSorting: true, headerKey: 'tripGemstoneId', keyColumn: false })
    tripGemstoneId: number;
    //#endregion tripGemstoneId Prop

    //#region legalOwenerId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 18, allowSorting: true, headerKey: 'legalOwenerId', keyColumn: false })
    legalOwenerId: number;
    //#endregion legalOwenerId Prop
    
}