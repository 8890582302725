import {PieceDeliveryStatusBase,} from '@app/database-models'
//Generated Imports
export class PieceDeliveryStatus extends PieceDeliveryStatusBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}