import { TemplateConfig } from '@rxweb/grid';
import { OrderStatusEnum } from 'src/app/enums/order-status.enum';
import { PaymentStatusEnum } from 'src/app/enums/payment-status.enum';
import { ShippingStatusEnum } from 'src/app/enums/shipping-status.enum';
import { SystemRatingEnum } from '../../enums/system-rating.enum';


export const PEOPLE_RATING_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      let data = this;
      if (this.rating != null && this.rating > 0) {
        if (this.rating == SystemRatingEnum.FiveDashNotexpectedtobuy) {
          return "label".split(" ");
        }
        if (this.rating == SystemRatingEnum.FourDashLow) {
          return "label label-success".split(" ");
        }
        if (this.rating == SystemRatingEnum.ThreeDashModerate) {
          return "label label-primary".split(" ");
        }
        if (this.rating == SystemRatingEnum.TwoDashHigh) {
          return "label label-warning".split(" ");
        }
        if (this.rating == SystemRatingEnum.OneDashIntensiveCare) {
          return "label label-danger".split(" ");
        }
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          if (this.rating != null && this.rating > 0) {
            let data = this;
            if (this.rating == SystemRatingEnum.FiveDashNotexpectedtobuy) {
              return "Not expected to buy";
            }
            if (this.rating == SystemRatingEnum.FourDashLow) {
              return "Low";
            }
            if (this.rating == SystemRatingEnum.ThreeDashModerate) {
              return "Moderate";
            }
            if (this.rating == SystemRatingEnum.TwoDashHigh) {
              return "High";
            }
            if (this.rating == SystemRatingEnum.OneDashIntensiveCare) {
              return "Intensive Care";
            }
          }
        }
      }
    }]
  }
}

export const PEOPLE_REDIRECT_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        input: {
          class: ["form-check-input", "checkbox-custom-style"],
          event: {
            input: "onCheckBoxSelect"
          },
          attributes: {
            type: "button",
            value: function (e) {
              return this[e.name]
            }
          }
        }
      },
    ],

  },
};



export const PEOPLE_CORPORATE_ORDER_STATUS_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.orderStatusId != null && this.orderStatusId > 0) {
        if (this.orderStatusId == OrderStatusEnum.Cancelled) {
          return "label label-danger".split(" ");
        }
        if (this.orderStatusId == OrderStatusEnum.Complete) {
          return "label label-primary".split(" ");
        }
        if (this.orderStatusId == OrderStatusEnum.Processing) {
          return "label label-violet".split(" ");
        }
        if (this.orderStatusId == OrderStatusEnum.Pending) {
          return "label label-secondary".split(" ");
        }
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this.orderStatus;
        }
      }
    }]
  }
}
export const PEOPLE_CORPORATE_PAYMENT_STATUS_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.paymentStatusId != null && this.paymentStatusId > 0) {
        if (this.paymentStatusId == PaymentStatusEnum.Pending) {
          return "label label-secondary".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.Authorized) {
          return "label label-primary".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.Paid) {
          return "label label-primary".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.PartiallyRefunded) {
          return "label label-warning".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.Refunded) {
          return "label label-primary".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.Voided) {
          return "label label-danger".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.Fraud) {
          return "label label-danger".split(" ");
        }
        if (this.paymentStatusId == PaymentStatusEnum.Deposit) {
          return "label label-primary".split(" ");
        }
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this.paymentStatus;
        }
      }
    }]
  }
}
export const PEOPLE_CORPORATE_SHIPPING_STATUS_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.shippingStatusId != null && this.shippingStatusId > 0) {
        if (this.shippingStatusId == ShippingStatusEnum.ShippingNotRequired) {
          return "label label-success".split(" ");
        }
        if (this.shippingStatusId == ShippingStatusEnum.NotYetShipped) {
          return "label label-warning".split(" ");
        }
        if (this.shippingStatusId == ShippingStatusEnum.FullyShipped) {
          return "label label-primary".split(" ");
        }
        if (this.shippingStatusId == ShippingStatusEnum.FullyDelivered) {
          return "label label-primary".split(" ");
        }
        if (this.shippingStatusId == ShippingStatusEnum.PartlyShipped) {
          return "label label-warning".split(" ");
        }
        if (this.shippingStatusId == ShippingStatusEnum.PartlyDelivered) {
          return "label label-warning".split(" ");
        }
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this.shippingStatus;
        }
      }
    }]
  }
}