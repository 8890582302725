<div class="login-page under-maintainance-page d-flex flex-column p-3 align-items-center" >

    <div  class="m-auto d-flex flex-column flex-lg-row col-12 col-md-8 col-lg-6">
  
      <div class="text-center w-100 d-flex flex-column justify-content-center">
        <h1 class="h1 text-green mb-3">we'll be back soon!</h1>
        <p>Thank you for the intrest you have expressed in G-Trac! <br/>
            Sorry for the inconveniance but we're performing some maintainance at the moment. we'll be back shortly!</p>
      </div>
    </div>
    <p class="mb-0 text-center mt-3 copyright">
      Copyright ©
      {{yearText}}  Faberge Gemfields.
    </p>
  </div>
  <a href="javascript:void(0);" class="scroll_top" [title]="scrolToTop"><i class="fa fa-angle-up" aria-hidden="true"></i></a>
  