import {vPricebandOptionBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
@actionColumn({
    visible:false,
    name:"action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'priceBandAction' }, columnIndex: 4, headerTitle: "Action"
})


export class vPricebandOption extends vPricebandOptionBase 
{



//#region Generated Reference Properties

//#endregion Generated Reference Properties










}