<ng-container [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/regions']" rxText="Label_Regions_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="RegionEditComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>
  <div class="wrapper wrapper-content flex-grow-1" *ngIf="showComponent" [formGroup]="regionFormGroup"
    [rxSpinner]="spin" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="RegionEditComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="row add-regions-form" (keyup.enter)="editRegion(false)">
          <div class="col-lg-6 col-xl-4 px-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="RegionAddEditComponent_RegionName_t"></label>
              <div class="col-md-8">
                <input tabindex="1" type="text" class="form-control" formControlName="regionName"
                  rxPlaceholder="RegionAddEditComponent_RegionName_p">
                <small class="form-text text-danger"
                  [class.d-block]="regionFormGroup.controls.regionName.errors">{{regionFormGroup.controls.regionName.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="RegionAddEditComponent_DisplayOrder_t"> </label>
              <div class="col-md-8">
                <input tabindex="2" type="text" class="form-control" formControlName="displayOrder"
                  rxPlaceholder="RegionAddEditComponent_DisplayOrder_p">
                <small class="form-text text-danger"
                  [class.d-block]="regionFormGroup.controls.displayOrder.errors">{{regionFormGroup.controls.displayOrder.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 pl-xl-5">
            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="RegionAddEditComponent_Published_t"> </label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="allowsRegistration"><i></i> -->
                    <app-checkbox [tabIndex]="'3'" [checkFormControl]="regionFormGroup.controls.published">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="regionFormGroup.controls.published.errors">{{regionFormGroup.controls.published.errorMessage}}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="editRegion(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="editRegion(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
            <ng-container [rxLocalisationInit]="componentName" *ngIf="regionDelete">
              <button (click)="deleteRegion()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
