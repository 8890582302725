<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/document-types']" rxText="Label_DocumentType_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="DocumentTypeEditComponent_Title_t"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>
  <div class="wrapper wrapper-content flex-grow-1">
    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="DocumentTypeEditComponent_Title_t"></label> - {{documentTypeName}}</h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="tabs-container people-tabs">
          <ul id="" class="nav nav-tabs flex-nowrap mb-0  slick-slider" role="tablist">
            <li  [rxLocalisationInit]="componentName">
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'document-type-info'"
                (click)="activeTab('document-type-info')"><label rxText="DocumentTypeEditComponent_DocumentTypeInfo_t"></label></a>
            </li>
            <li  *ngIf="isRestricted"  [rxLocalisationInit]="componentName">
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'document-type-user-mapping'"
                (click)="activeTab('document-type-user-mapping')"><label rxText="DocumentTypeEditComponent_DocTypeUserMapping_t"></label></a>
            </li>
            <li  [rxLocalisationInit]="componentName">
              <a class="nav-link" data-toggle="tab" [class.active]="selectedTab == 'document-type-notes'"
                (click)="activeTab('document-type-notes')"><label rxText="DocumentTypeEditComponent_Notes_t"></label></a>
            </li>
          </ul>

          <div class="slick_demo-btn"></div>
          <div class="tab-content">
            <div role="tabpanel" *ngIf="selectedTab == 'document-type-info'" class="tab-pane"
              [class.active]="selectedTab == 'document-type-info'" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
              <div class="panel-body" *ngIf="showComponent" [formGroup]="documentTypeFormGroup"
                 [rxLocalisationInit]="componentName">
                <div class="row" *ngIf="showComponent" [rxLocalisationInit]="componentName">
                  <div class="col-xl-12">
                    <div class="ibox-title bg-white px-0 border-bottom mb-4">
                      <h5>Document Type Info</h5>
                    </div>
                    <div class="row add-countries-form" (keyup.enter)="editDocumentType(false)">
                      <div class="col-md-3 pr-xl-3">
                        <div class="form-group row">
                          <label class="col-md-7 col-form-label"  rxText="DocumentTypeEditComponent_Id_t"></label>
                          <div class="col-md-5">
                            <label type="text" 
                              class="form-control disableLabel-withoutheight mb-0">{{(documentTypeFormGroup.value.documentTypeId
                              === null || documentTypeFormGroup.value.documentTypeId === undefined ) ? '' :
                              documentTypeFormGroup.value.documentTypeId }}</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 pr-xl-6">
                        <div class="form-group row">
                          <label class="col-md-4 col-form-label" 
                            rxText="DocumentTypeEditComponent_TypeName_t"></label>
                          <div class="col-md-8">
                            <input type="text" formControlName="documentTypeName" class="form-control "  [tabIndex]="'1'"
                               rxPlaceholder="DocumentTypeEditComponent_TypeName_t"
                              rxFocus />
                            <small class="form-text text-danger"
                              [class.d-block]="documentTypeFormGroup.controls.documentTypeName.errors">{{documentTypeFormGroup.controls.documentTypeName.errorMessage}}<br /></small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 pr-xl-3">
                        <div class="form-group row check-group">
                          <label class="col-md-6 col-form-label"  rxText="DocumentTypeEditComponent_IsRestricted_t">
                          </label>
                          <div class="col-md-auto d-flex align-items-center">
                            <div class="i-checks">
                              <label class="mb-0">
                                <app-checkbox [tabIndex]="'2'" [checkFormControl]="documentTypeFormGroup.controls.isRestricted"></app-checkbox>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row mt-auto">
                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="col text-right">
                    <button (click)="editDocumentType(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                    <button (click)="editDocumentType(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                    <ng-container *ngIf="documentTypeDelete" [rxLocalisationInit]="componentName">
                      <button (click)="deleteDocumentType()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="document-type-user-mapping" *ngIf="selectedTab == 'document-type-user-mapping'"
              [class.active]="selectedTab == 'document-type-user-mapping'" class="tab-pane">
              <div class="panel-body sub-components">
                <app-document-type-user-mapping-list>
                </app-document-type-user-mapping-list>
              </div>
            </div>

            <div role="tabpanel" id="document-type-notes" *ngIf="selectedTab == 'document-type-notes'"
              [class.active]="selectedTab == 'document-type-notes'" class="tab-pane">
              <div class="panel-body sub-components">
                <app-document-type-notes-detail>
                </app-document-type-notes-detail>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>