<div *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
      <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/dashboard']">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/forms-document']" rxText="Label_Forms_Document_t"></a>
          </li>
          <li class="breadcrumb-item active">Add New</li>
        </ol>
      </div>
      <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
      </div>
    </div>
  
    <div class="wrapper wrapper-content flex-grow-1">
  
      <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
          <h5><label rxText="FormsDocumentAddComponent_Title_t"></label>
          </h5>
          <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
            <i class="fa fa-question-circle fa-2x"></i>
          </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="formsDocumentFormGroup"
           >
          <div class="row add-countries-form" (keyup.enter)="addFormsDocument(false)">
            <div class="col-md-4 pr-xl-5">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"  rxText="FormsDocumentAddComponent_Name_t">
                </label>
                <div class="col-md-8">
                  <input type="text" formControlName="name" tabindex="1" class="form-control " 
                    rxPlaceholder="FormsDocumentAddComponent_Name_p" rxFocus />
                  <small class="form-text text-danger"
                    [class.d-block]="formsDocumentFormGroup.controls.name.errors">{{formsDocumentFormGroup.controls.name.errorMessage}}</small>
                </div>
              </div>
              <div class="form-group row">
                  <label class="col-md-4  col-xl-4 col-form-label" 
                    rxText="FormsDocumentAddComponent_FileName_t"></label>
                  <div class="col-md-8">
                    <app-file [fileViewModel]="fileViewModelDoc" [tabindex]="'8'" (files)="updateDocFiles($event)">
                    </app-file>
                    <div style="margin-top: 0.5em;">
                    <small class="form-text text-danger"
                    [class.d-block]="formsDocumentFormGroup.controls.fileName1.errors">{{formsDocumentFormGroup.controls.fileName1.errorMessage}}</small>
                    <label class="mt-1">{{formsDocumentFormGroup.value.fileName1}}</label>
                    <button *ngIf="formsDocumentFormGroup.value.fileName1 != null" 
                      class="btn btn-danger ml-2" (click)="removeDoc()">Remove</button>
                  </div>
                  </div>
                  
                </div>
              <div class="form-group row">
                <label class="col-md-4  col-form-label"  rxText="FormsDocumentAddComponent_Description_t">
                </label>
                <div class="col-md-8">
                  <textarea class="form-control " tabindex="3" formControlName="description" 
                    rxPlaceholder="FormsDocumentAddComponent_Description_p"></textarea>
                  <!-- <small class="form-text text-danger"
                    [class.d-block]="formsDocumentFormGroup.controls.description.errors">{{formsDocumentFormGroup.controls.description.errorMessage}}</small> -->
                </div>
              </div>
              
              <div class="form-group row">
                <label class="col-md-4 col-form-label" 
                  rxText="FormsDocumentAddComponent_DisplayOrder_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="displayOrderId" class="form-control " tabindex="5"
                     rxPlaceholder="FormsDocumentAddComponent_DisplayOrder_p" />
                  <small class="form-text text-danger"
                    [class.d-block]="formsDocumentFormGroup.controls.displayOrderId.errors">{{formsDocumentFormGroup.controls.displayOrderId.errorMessage}}</small>
                </div>
              </div>
              <div class="form-group row check-group">
                <label class="col-md-4 col-form-label"  rxText="FormsDocumentAddComponent_Published_t">
                </label>
                <div class="col-md-auto d-flex align-items-center mt-2">
                  <div class="i-checks">
                    <label class="mb-0">
                      <!-- <input type="checkbox" formControlName="published"><i></i>-->
                      <app-checkbox [tabIndex]="'6'" [checkFormControl]="formsDocumentFormGroup.controls.published"></app-checkbox>
                    </label>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group row check-group">
                <label class="col-md-4 col-form-label"  rxText="HelpSupportAddEditComponent_Published_t">isActive
                </label>
                <div class="col-md-auto d-flex align-items-center mt-2">
                  <div class="i-checks">
                    <label class="mb-0">
                      <app-checkbox [tabIndex]="'6'" [checkFormControl]="formsDocumentFormGroup.controls.isActive"></app-checkbox>
                    </label>
                  </div>
                </div>
              </div> -->
            </div>
           
            <div class="col-md-4 pl-xl-5">
  
  
            </div>
          </div>
  
  
  
  
  
          <div class="row mt-auto">
            <div class="hr-line-dashed border-top w-100"></div>
            <div class="col text-right">
              <button (click)="addFormsDocument(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
              <ng-container *ngIf="FormsdocumentEdit" [rxLocalisationInit]="componentName">
                <button (click)="addFormsDocument(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  
  </div>
  