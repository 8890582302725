import { PointofSaleBase, POSBrandMappingBase, } from '@app/database-models'
import { propArray, prop } from '@rxweb/reactive-form-validators'
//Generated Imports
export class PointofSale extends PointofSaleBase {



    //#region posBrandMappings Prop
    @propArray(POSBrandMappingBase)
    posBrandMappings: POSBrandMappingBase[];
    //#endregion posBrandMappings Prop

    //#region brandIds Prop
    @prop()
    brandIds: string;
    //#endregion brandIds Prop

    //#region supplierId Prop
    @prop()
    supplierId: number;
    //#endregion supplierId Prop

    //#region Generated Reference Properties

    //#endregion Generated Reference Properties





































}