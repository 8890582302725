import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class POSPictureBase {

//#region pOSPictureId Prop
        @prop()
        pOSPictureId : number;
//#endregion pOSPictureId Prop


//#region saleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        saleId : number;
//#endregion saleId Prop


//#region pictureId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureId : number;
//#endregion pictureId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region comment Prop
        @maxLength({value:4000})
        comment : string;
//#endregion comment Prop

}