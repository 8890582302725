import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { AbstractLeaveTypeCorporateMapping } from '../domain/abstract-leave-type-corporate-mapping';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaveType } from '@app/models';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("LeaveTypeCorporateMappingEditComponent")
@access({ accessLevel: RolePermissionEnums.HolidayLeaveTypes, action: "put" })

@Component({
    selector: "app-leave-type-corporate-mapping-edit",
    templateUrl: './leave-type-corporate-mapping-edit.component.html'
})
export class LeaveTypeCorporateMappingEditComponent extends AbstractLeaveTypeCorporateMapping implements OnInit, OnDestroy {
    leaveType: LeaveType;
    subscription: any;
    id: number;
    leaveTypeDelete:any=dbAccessModule[RolePermissionEnums.HolidayLeaveTypes]["delete"];
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    selectedTab: string = "leave-type-info";

    constructor(private title: Title, private router: Router,private formBuilder: RxFormBuilder,private applicationBroadcaster:ApplicationBroadcaster, private activatedRoute: ActivatedRoute, modelView: ModalView, private el: ElementRef, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.HolidayLeaveTypes;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Leave Type - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {
            if (t) {
                this.leaveTypeFormGroup = this.formBuilder.formGroup(LeaveType, t) as IFormGroup<LeaveType>;
                this.showComponent = true;
            }
            else {
                this.router.navigate(['/leave-types']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editLeaveType(isRedirect) {
        this.leaveTypeFormGroup.submitted = true;
        if (this.leaveTypeFormGroup.valid) {
            this.spin=true;
            this.put({ body: this.leaveTypeFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.ngOnInit() : this.router.navigate(['/leave-types']);
            })
        }else{
            for (const key of Object.keys(this.leaveTypeFormGroup.controls)) {
                if (this.leaveTypeFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    deleteLeaveType() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/leave-types']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Leave_Type_Company");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
}
