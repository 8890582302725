import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ITTicketCompanyCustomerMappingBase {

        //#region iTTicketCompanyCustomerMappingId Prop
        @prop()
        iTTicketCompanyCustomerMappingId: number;
        //#endregion iTTicketCompanyCustomerMappingId Prop


        //#region companyId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        companyId: number;
        //#endregion companyId Prop


        //#region customerId Prop
        @prop({ defaultValue: 0 })
        customerId: number;
        //#endregion customerId Prop

}