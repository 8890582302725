import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMeasureDimensionLookUpBase {

//#region measureDimensionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'measureDimensionId', keyColumn: true})
        measureDimensionId : number;
//#endregion measureDimensionId Prop


//#region measureDimensionName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'measureDimensionName', keyColumn: false})
        measureDimensionName : string;
//#endregion measureDimensionName Prop

}