import {ProductSketchBase,PictureBase,ProductBase,} from '@app/database-models'
//Generated Imports
export class ProductSketch extends ProductSketchBase 
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties











}