<div class="modal-dialog modal-lg h-75" *rxMultilingual="">
    <div class="modal-content" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left" *ngIf="Isdashboard">
            <h4 class="modal-title mb-0" >
                <label rxText="TaskDetailViewComponent_TaskDetailTComment_t"></label>
            </h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-header text-left" *ngIf="!Isdashboard">
            <h4 class="modal-title mb-0" >
                <label rxText="TaskDetailViewComponent_TaskDetailTitle_t"></label>
            </h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="taskDetailFormGroup" >
            <div class="row" *ngIf="!Isdashboard">
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaskDetail_TaskId_t"></label>
                        <div class="col-md-8 ">
                            <label type="text" class="form-control disableLabel disableLabel-rightborder mb-0">{{'Task
                                #'+taskDetailFormGroup.value.taskDetailId}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12" >
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetail_TaskDescription_t"></label>
                        <div class="col-md-8">
                            <textarea class="form-control" rows="4" formControlName="taskDescription"
                                placeholder="Enter Task Description"
                                [attr.disabled]="isEditAccess ? null : ''" tabindex="1"></textarea>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.taskDescription.errors">{{taskDetailFormGroup.controls.taskDescription.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12" >
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetail_AssignedBy_t"></label>
                        <div class="col-md-8">
                            <fieldset *ngIf="isEditAccess" [rxSelectExtended]="_this">
                                <rx-select mainClass="form-control" [autoComplete]="true"
                                    [queryParams]="{'lookupType':'adminUsers'}" path="api/SearchEvent/GetLookups"
                                    #rxSelect [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                                    [controlTabIndex]="2" formControlName="assignedByUserId"
                                    [selectPlaceholder]="'Select Assigned By'" [keyValueProps]="['#name#','userId']"
                                    [text]="taskDetailFormGroup.value.assignedByUserName">
                                </rx-select>
                            </fieldset>
                            <fieldset [disabled]="!isEditAccess" *ngIf="!isEditAccess">
                                <div class="input-group">
                                    <label type="text"
                                        class="form-control disableLabel disableLabel-rightborder mb-0">{{(taskDetailFormGroup.value.assignedByUserName
                                        === null || taskDetailFormGroup.value.assignedByUserName === undefined
                                        || taskDetailFormGroup.value.assignedByUserName === '') ? '' :
                                        taskDetailFormGroup.value.assignedByUserName}}</label>
                                    <span class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="fa fa-spinner fa-pulse d-none"></i>
                                            <i class="fa fa-caret-down"></i>
                                        </span>
                                    </span>
                                </div>
                            </fieldset>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.assignedByUserId.errors">{{taskDetailFormGroup.controls.assignedByUserId.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12" >
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetail_AssignedTo_t"></label>
                        <div class="col-md-8">
                            <fieldset *ngIf="isEditAccess" [rxSelectExtended]="_this">
                                <rx-select mainClass="form-control" [autoComplete]="true"
                                    [queryParams]="{'lookupType':'adminUsers'}" path="api/SearchEvent/GetLookups"
                                    #rxSelect [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                                    [controlTabIndex]="3" formControlName="assignedToUserId"
                                    [selectPlaceholder]="'Select Assignee'" [keyValueProps]="['#name#','userId']"
                                    [text]="taskDetailFormGroup.value.assignedToUserName">
                                </rx-select>
                            </fieldset>
                            <fieldset [disabled]="!isEditAccess" *ngIf="!isEditAccess">
                                <div class="input-group">
                                    <label type="text"
                                        class="form-control disableLabel disableLabel-rightborder mb-0">{{(taskDetailFormGroup.value.assignedToUserName
                                        === null || taskDetailFormGroup.value.assignedToUserName === undefined
                                        || taskDetailFormGroup.value.assignedToUserName === '') ? '' :
                                        taskDetailFormGroup.value.assignedToUserName}}</label>
                                    <span class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="fa fa-spinner fa-pulse d-none"></i>
                                            <i class="fa fa-caret-down"></i>
                                        </span>
                                    </span>
                                </div>
                            </fieldset>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.assignedToUserId.errors">{{taskDetailFormGroup.controls.assignedToUserId.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12" >
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetail_CompletionDate_t"></label>
                        <div class="col-md-8">
                            <div class="input-group" *ngIf="!isEditAccess; else completionDateElse">
                                <label type="text" class="mb-0"
                                    class="form-control disableLabel disableLabel-rightborder mb-0">{{taskDetailFormGroup.value.completionDate
                                    | date: 'dd/MMM/yyyy'}}</label>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                                </div>
                            </div>
                            <ng-template #completionDateElse>
                                <rx-date showAddon="true" tabindex="4" formControlName="completionDate" pickerClass="form-control">
                                </rx-date>
                            </ng-template>
                            <small class="form-text text-danger"
                                [class.d-block]="taskDetailFormGroup.controls.completionDate.errors">{{taskDetailFormGroup.controls.completionDate.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-12" >
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="TaskDetail_IsCompleted_t"></label>
                        <div class="col-md-8">
                            <div class="col-md-2 pl-0">
                                <div class="i-checks">
                                    <label class="mt-1">
                                        <fieldset [disabled]="!isCheckboxAccess">
                                            <app-checkbox [tabIndex]="'5'" [checkFormControl]="taskDetailFormGroup.controls.isCompleted"  [isDisabled]="!isCheckboxAccess" ></app-checkbox>
                                        </fieldset>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="!Isdashboard && taskDetail.noteDescription != null && this.taskDetail.noteDescription != ''">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaskDetailView_NoteDescription_t"></label>
                        <div class="col-md-8 ">
                            <textarea class="form-control  disableLabel disableLabel-rightborder mb-0" disabled placeholder="Add a comment" formControlName="noteDescription" rows="4"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaskDetailViewComponent_Comment_t"></label>
                        <div class="col-md-8 ">
                            <textarea class="form-control" placeholder="Add a comment" formControlName="taskComment" rows="4"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div>
        </div>
        
        <div class="modal-footer" *ngIf="!Isdashboard">
            <ng-container [rxLocalisationInit]="componentName">
                <button class="btn btn-success mr-0" tabindex="6" (click)="saveTask()" [disabled]="isProcessing" rxText="TaskDetailView_Update_t"></button>
            </ng-container>
            <button (click)="hide()" tabindex="7" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
        </div>
        <div class="hr-line-dashed border-top w-100 mb-2 mt-0" *ngIf="!Isdashboard"></div>
        <app-task-reply [Isdashboard]="Isdashboard" [TaskId]="taskDetail.taskDetailId"></app-task-reply>

    </div>
</div>
