import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subject, Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { AbstractProjectEvent } from '../domain/abstract-project-event';
import { ProjectsEventsMappingFilterViewModel } from 'src/app/view-model/project-events-mapping-filter-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { ProjectEventsListViewModel } from 'src/app/models/extended-models/project-events-list-view-model';
import { Booking, EventBooking, ProjectEventMapping, RiskProjectMapping } from '@app/models';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { CoreComponent } from '@rxweb/angular-router';

import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { RiskProjectMappingViewModel } from "src/app/view-model/risk-project-mapping-view-model";
import { debounceTime } from "rxjs/operators";
import { EventBookingBase } from "@app/database-models";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { newArray } from "@angular/compiler/src/util";
import { element } from "protractor";
import { PlatformLocation } from "@angular/common";
@multilingual("ProjectEventAddComponent")

@Component({
    selector: "app-project-event-add",
    templateUrl: './project-event-add.component.html'
})
export class ProjectEventAddComponent extends AbstractProjectEvent implements OnInit, OnDestroy {
    projectEvent: ProjectsEventsMappingFilterViewModel;
    ProjectEventListViewModel: ProjectEventsListViewModel[];
    cloneArray: ProjectEventsListViewModel[];
    subscription: any;
    ProjectEventListGrid: AppGrid;
    ProjectEventListColumns: any[];
    @Input() projectId: number;
    @Input() hide: Function;
    @Input() isEvent: number;
    @Input() isBooking : number;
    showSaveButton: boolean = false;
    projectEventsMappingInfo: ProjectEventMapping;
    projectEventsMappingArray: ProjectEventMapping[];
    isFilter: boolean = false;
    totalRecords: number = 0;
    totalRestrictedCount : number = 0;
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    eventIds: number[];
    headerBtnChk: boolean = false;
    titleClose: string;
    ErrorMsg: string;
    riskEventMapping: RiskProjectMapping;
    excludedRiskEventMapping : RiskProjectMapping;
    excludedRiskEventIds : number[];
    BookingObj : Booking;
    filtersJson:any;
    excludedProjectEvent:ProjectEventMapping;
    excludedProjectEventIds:number[];

    private projectEventSubject: Subject<string> = new Subject<string>();

    constructor(private formBuilder: RxFormBuilder, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.projectEventsMappingArray = new Array<ProjectEventMapping>();
        this.eventIds = new Array<number>();
        this.cloneArray = new Array<ProjectEventsListViewModel>();
        this.excludedProjectEvent = new ProjectEventMapping();
        this.excludedProjectEvent.eventIds = new Array<ProjectEventMapping>();
        this.excludedProjectEventIds = new Array<number>();

        this.excludedRiskEventMapping = new RiskProjectMapping ();
        this.excludedRiskEventIds = new Array <number>();
        this.excludedRiskEventMapping.riskProjectInput = new Array<RiskProjectMappingViewModel>();
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.projectEvent = new ProjectsEventsMappingFilterViewModel();
        this.projectEvent.pageIndex = PaginationEnum.PageIndex;
        this.projectEvent.rowCount = PaginationEnum.RowCount;
        this.projectEventSearchFormGroup = this.formBuilder.formGroup(this.projectEvent) as IFormGroup<ProjectsEventsMappingFilterViewModel>;
        this.showComponent = true;
        this.projectEventSubject.pipe(debounceTime(300)).subscribe(t => {
            this.bindGrid();
        })
    }

    searchEvents() {
        this.spin = true;
        if (this.ProjectEventListGrid) {
            this.isFilter = true;
            this.cloneArray = new Array<ProjectEventsListViewModel>();
            if(this.headerBtnChk) {
                this.projectEventsMappingArray = [];
                this.excludedProjectEvent.eventIds = [];
                this.eventIds = [];
                this.excludedProjectEventIds = [];
            }
            this.headerBtnChk = false;
            this.ProjectEventListGrid.updateSource([]);
            this.ProjectEventListGrid.storeProcedure.nextPage = 1;
        }
        if(this.headerBtnChk) {
            this.projectEventsMappingArray = [];
            this.excludedProjectEvent.eventIds = [];
            this.eventIds = [];
            this.excludedProjectEventIds = [];
        }
        this.projectEventSearchFormGroup.value.pageIndex = 1
        // this.projectEventSearchFormGroup.patchValue({pageIndex:1 })


        this.projectEventSubject.next()
    }

    bindGrid() {
        this.projectEventSearchFormGroup.submitted = true;
        this.startDate = new Date();
        this.startDate = this.projectEventSearchFormGroup.value.eventStartDate;
        this.endDate = new Date();
        this.endDate = this.projectEventSearchFormGroup.value.eventEndDate;
        if (this.endDate != null && this.startDate > this.endDate) {
            this.dateError = true;
            this.spin = false;
        } else {
            this.dateError = false;
            if (this.projectEventSearchFormGroup.valid) {
                this.isShowGrid = true;
                this.spin = true;
                var json = JSON.parse(JSON.stringify(this.projectEventSearchFormGroup.value));
                if (this.isEvent == 1) {
                    if (this.isBooking == 1) {
                        json["projectOrEvent"] = 3;
                    }
                    else{
                        json["projectOrEvent"] = 2;
                    }
                }
                else {
                    json["projectOrEvent"] = 0;
                }
                json["projectId"] = this.projectId;
                this.filtersJson = json
                this.subscription = this.post({
                    path: "api/SearchEvent/EventSearch",
                    body: {
                        query: JSON.stringify(json),
                    }
                }).subscribe((t: any) => {
                    this.spin = false;
                    if (this.ProjectEventListGrid) {
                        this.ProjectEventListGrid.storeProcedure.length = 0;
                    }
                    this.ProjectEventListViewModel = t[0].UserData;
                    this.ErrorMsg = t[0].ErrorMessage;
                    // this.ProjectEventListViewModel.forEach(x=>x.isChecked=false);
                    this.ProjectEventListViewModel.forEach(x => {
                        if (this.cloneArray.findIndex(y => x.eventId == y.eventId) == -1) {
                            x.isChecked = this.headerBtnChk;
                            this.cloneArray.push(x);
                        }
                        else {
                            this.cloneArray.forEach(z => {
                                if (z.eventId == x.eventId) {
                                    x.isChecked = z.isChecked
                                }
                            })
                        }
                        if(this.headerBtnChk){
                            if(this.excludedProjectEventIds.indexOf(x.eventId) != -1){
                                x.isChecked = false;
                            }
                        }
                        else{
                            if(this.eventIds.indexOf(x.eventId) != -1) {
                                x.isChecked = true;
                            }
                        }

                    })
                    if (this.ProjectEventListViewModel.length > 0) {
                        this.totalRecords = this.ProjectEventListViewModel[0].totalCount;
                        this.totalRestrictedCount = this.ProjectEventListViewModel[0].totalRestricted;
                        // this.ProjectEventListViewModel.forEach(x => {
                        //     x.isChecked = false;
                        // });
                        this.isNoRecordFound = false;
                        this.showSaveButton = true;
                    }
                    else {
                        this.isNoRecordFound = true;
                        this.isShowGrid = false;
                    }
                    if (!this.isFilter) {
                        //  this.ProjectEventListGrid = new AppGrid(this.ProjectEventListViewModel, ProjectEventsListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
                        this.ProjectEventListGrid = new AppGrid(this.ProjectEventListViewModel, ProjectEventsListViewModel, { actions: { onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
                        this.ProjectEventListGrid.storeProcedure = {
                            length: this.totalRecords,
                            onPageChanging: this.onPageChanging.bind(this),
                            nextPage: 1,
                            onPageSorting: this.onPageSorting.bind(this)
                        }
                        this.ProjectEventListGrid.gridColumns.forEach(x => {
                            if (x.columnIndex == 0) {
                                x.visible = true;

                            } if (x.columnIndex == 1) {
                                x.visible = false;
                            }
                        });
                    }
                    else {

                        this.ProjectEventListGrid.storeProcedure.length = this.totalRecords;
                        this.ProjectEventListGrid.updateSource([]);
                        this.ProjectEventListGrid.updateSource(this.ProjectEventListViewModel)
                    }
                    this.ProjectEventListGrid.maxPerPage = this.projectEventSearchFormGroup.value.rowCount;
                    this.ProjectEventListGrid.componentId = this.componentName;
                    this.updateProjectEventArray();
                    // if (this.projectEventsMappingArray.length > 0 || this.headerBtnChk) {
                    //     this.showSaveButton = true;
                    // }
                    // else {
                    //     this.showSaveButton = false;
                    // }
                })
            }
        }
    }



    updateProjectEventArray() {
        this.cloneArray.forEach(x => {
            if (!x.isRestrictedEvent || (x.isRestrictedEvent && x.isAllowed)) {
                let projectEventsMappingObj = new ProjectEventMapping();
                projectEventsMappingObj.projectID = this.projectId;
                projectEventsMappingObj.eventID = x.eventId;
                if (x.isChecked) {
                    this.ProjectEventListGrid.source.forEach(z => z.isAllSelected = x.isChecked);

                    if(this.headerBtnChk){
                        if(this.excludedProjectEvent.eventIds.findIndex(t => t.eventID == x.eventId) != -1 ) {
                            this.excludedProjectEventIds.splice(this.excludedProjectEvent.eventIds.findIndex(t => t.eventID == x.eventId),1);
                            this.excludedProjectEvent.eventIds.splice(this.excludedProjectEvent.eventIds.findIndex(t => t.eventID == x.eventId),1);
                        }
                    }
                    else{
                        if (this.eventIds.indexOf(x.eventId) == -1) {
                            this.projectEventsMappingArray.push(projectEventsMappingObj);
                            this.eventIds.push(x.eventId);
                        }
                    }
                } else {
                  if (this.headerBtnChk) {
                    if (this.excludedProjectEvent.eventIds.findIndex(t => t.eventID == x.eventId) == -1) {
                      this.excludedProjectEvent.eventIds.push(projectEventsMappingObj);
                      this.excludedProjectEventIds.push(x.eventId);
                    }
                  }
                  else {
                    if (this.eventIds.indexOf(x.eventId) != -1) {
                        this.projectEventsMappingArray.splice(this.eventIds.indexOf(x.eventId), 1);
                        this.eventIds.splice(this.eventIds.indexOf(x.eventId), 1);
                    }
                  }
                }
            }
        });

    }


    onCheckboxSelect(t, x) {
        // this.cloneArray.forEach(z=>{
        //     if(z.eventId==t.eventId){
        //       z.isChecked=x.target.checked;
        //     }
        //   })
        //      this.updateProjectEventArray();
      let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<ProjectEventsListViewModel>();

        oldCloneArray.forEach(data => {
            let newObj = new ProjectEventsListViewModel();
            newObj.eventId = data.eventId;
            newObj.isChecked = data.eventId == t.eventId ? x.target.checked : data.isChecked;
            newObj.eventEndDate = data.eventEndDate;
            newObj.eventStartDate = data.eventStartDate;
            newObj.eventName = data.eventName;
            newObj.isRestrictedEvent = data.isRestrictedEvent;
            // newObj.isChecked = data.isChecked;
            newObj.eventType = data.eventType;
            newObj.totalCount = data.totalCount;
            newObj.evName = data.evName;
            newObj.eventTypeId = data.eventTypeId;
            newObj.isAllowed = data.isAllowed;
            this.cloneArray.push(newObj);
        });

        let scrollTop: number = 0;
        if(navigator.userAgent.match(/edg/i))
            scrollTop = document.getElementsByClassName('modal fade show').item(0).scrollTop;
        this.ProjectEventListGrid.updateSource([]);
        this.ProjectEventListGrid.updateSource(this.cloneArray);
        if(navigator.userAgent.match(/edg/i))
            document.getElementsByClassName('modal fade show').item(0).scrollTop = scrollTop;

        this.updateProjectEventArray();

    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<ProjectEventsListViewModel>();
        this.projectEventsMappingArray = [];
        this.excludedProjectEvent.eventIds = [];
        this.eventIds = [];
        this.excludedProjectEventIds = [];
        this.ProjectEventListViewModel.forEach(data => {
            let newObj = new ProjectEventsListViewModel();
            newObj.eventId = data.eventId
            newObj.eventEndDate = data.eventEndDate;
            newObj.eventName = data.eventName;
            newObj.eventType = data.eventType;
            newObj.eventTypeId = data.eventTypeId;
            newObj.isChecked = this.headerBtnChk;
            newObj.totalCount = data.totalCount;
            newObj.eventStartDate = data.eventStartDate;
            newObj.isRestrictedEvent = data.isRestrictedEvent;
            newObj.isAllowed = data.isAllowed;
            newObj.evName = data.evName;
            this.cloneArray.push(newObj);
        });
        // if (this.projectEventsMappingArray.length > 0 || this.headerBtnChk) {
        //     this.showSaveButton = true;
        // }
        // else {
        //     this.showSaveButton = false;
        // }
        this.updateProjectEventArray();
        this.ProjectEventListGrid.updateSource([]);
        this.ProjectEventListGrid.updateSource(this.cloneArray);
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.cloneArray = [];
        this.projectEventSearchFormGroup.patchValue({ sortOrder: y, orderBy: x })
        this.ProjectEventListGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        // let fromIndex: number = this.projectEventSearchFormGroup.value.rowCount;
        // let toIndex: number = this.ProjectEventListGrid.maxPerPage;
        // if (this.projectEventSearchFormGroup.value.pageIndex < x) {
        //     this.cloneArray = [];
        //     this.projectEventsMappingArray = [];
        //     this.eventIds = [];
        // }
        // if (this.ProjectEventListGrid.maxPerPage < this.projectEventSearchFormGroup.value.rowCount) {
        //     for (let i = fromIndex; i > toIndex; i--) {
        //         this.cloneArray.splice(i - 1, 1);
        //     }
        // }
        this.cloneArray = [];
        this.projectEventSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.ProjectEventListGrid.maxPerPage })
        this.ProjectEventListGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludedProjectEvent.eventIds.length - this.totalRestrictedCount)  + " Records";
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveEvents();
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveEvents();
        }
     }

    saveEvents() {
        if (this.isEvent == 0) {
            this.projectEventsMappingInfo = new ProjectEventMapping();
            this.projectEventsMappingInfo.eventIds = this.projectEventsMappingArray;
            if(this.headerBtnChk){
                this.projectEventsMappingInfo.eventIds = this.excludedProjectEvent.eventIds;
                this.projectEventsMappingInfo.headerBtnCheck = this.headerBtnChk;
                var json = JSON.parse(JSON.stringify(this.projectEventSearchFormGroup.value));
                json["projectOrEvent"] = 0;
                json["projectId"] = this.projectId;
                json["sortOrder"] = "false";
                json["rowCount"] = this.totalRecords;
                json["pageIndex"] = 1;
                this.projectEventsMappingInfo.query = JSON.stringify(json);
                this.projectEventsMappingInfo.projectID = this.projectId;
            }
            this.spin = true;
            this.post({ path: "api/ProjectEventMapping", body: this.projectEventsMappingInfo }).subscribe(data => {
                this.spin = false;
                var existsAlert = getLocalizedValue("Event_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
                this.projectEventsMappingArray = [];
                this.hide();
            });
        }
        else {

            if (this.isBooking == 1) {
                this.spin = true;
                this.BookingObj = new Booking();
                this.BookingObj.originalTripId = this.projectId;
                var EventBookingMapp: EventBookingBase;
                this.BookingObj.eventBookings = new Array<EventBookingBase>();
                this.projectEventsMappingArray.forEach(element => {

                    if (this.BookingObj.eventBookings.findIndex(x => x.eventID == element.eventID) == -1) {
                        EventBookingMapp = new EventBookingBase();
                        EventBookingMapp.eventID = element.eventID;
                        EventBookingMapp.bookingID = this.BookingObj.originalTripId;
                        this.BookingObj.eventBookings.push(EventBookingMapp);
                    }
                });
                if(this.headerBtnChk){
                    this.BookingObj.headerBtnCheck = this.headerBtnChk;
                    this.filtersJson["sortOrder"] = "false";
                    this.filtersJson["rowCount"] = this.totalRecords;
                    this.filtersJson["pageIndex"] = 1;
                    this.BookingObj.query = JSON.stringify(this.filtersJson);
                }
                this.post({ body: this.BookingObj, path: "api/BookingSearch/AddEventsBooking"}).subscribe(data => {
                    this.spin = false;
                    this.cloneArray = [];
                    this.headerBtnChk = false;
                    var existsAlert = getLocalizedValue("Data_Added");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })

            }
            else{

                this.spin = true;
                this.riskEventMapping = new RiskProjectMapping();
                this.riskEventMapping.riskMenuDetailId = this.projectId;
                this.riskEventMapping.ProjectId = this.projectId;
                var riskEventMap: RiskProjectMappingViewModel;
                var excludeRiskEventMap : RiskProjectMappingViewModel;
                this.riskEventMapping.riskProjectInput = new Array<RiskProjectMappingViewModel>();
                this.projectEventsMappingArray.forEach(element => {

                    if (this.riskEventMapping.riskProjectInput.findIndex(x => x.projectID == element.eventID) == -1) {
                        riskEventMap = new RiskProjectMappingViewModel();
                        riskEventMap.projectID = element.eventID;
                        this.riskEventMapping.riskProjectInput.push(riskEventMap);
                    }
                });
                if(this.headerBtnChk){
                    this.excludedProjectEvent.eventIds.forEach(element => {
                        if(this.excludedRiskEventMapping.riskProjectInput.findIndex(x => x.projectID == element.eventID) == -1){
                            excludeRiskEventMap = new RiskProjectMappingViewModel();
                            excludeRiskEventMap.projectID = element.eventID;
                            this.riskEventMapping.riskProjectInput.push(excludeRiskEventMap);
                        }
                    })
                    this.riskEventMapping.headerBtnChk = this.headerBtnChk;
                    this.filtersJson["sortOrder"] = "false";
                    this.filtersJson["rowCount"] = this.totalRecords;
                    this.filtersJson["pageIndex"] = 1;
                    this.riskEventMapping.query = JSON.stringify(this.filtersJson);
                }
                this.post({ body: this.riskEventMapping, path: "api/SearchProject/AddRiskPressGina", params: [2] }).subscribe(data => {
                    this.spin = false;
                    this.cloneArray = [];
                    this.headerBtnChk = false;
                    var existsAlert = getLocalizedValue("Data_Added");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "ProjectEventAddComponent";
    }
}
