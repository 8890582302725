import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
export const NOTEDELETE_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [{
        a: {isBind: (x) => {
            if(x.isDelete=="true"){
            return true;
            }
            else{
            return false;

            }
        },
          event: {
            click: "onNoteDelete"
          },
          childrens: [{
            i: { class: "fr-delete fa-4x font-md text-danger".split(" ") }
          }],
          attributes: { "title": getLocalizedValue("Title_Delete") },
          style : {"line-height":"1.5rem"}
        }
      }],
      style: { "width": "100%", "text-align": "center"}
  }
  }
