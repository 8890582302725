<div *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']" rxText="Label_Home_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/power-bi-reports']" rxText="Label_Power_BI_Report_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="PowerBIReportEditComponent_EditTitle_t"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="PowerBIReportEditComponent_EditTitle_t"></label> - {{reportName}}
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">

        <div class="tabs-container people-tabs">

          <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="currentActiveTab == 'power-bi-reports'"
                (click)="activatedTab('power-bi-reports')"> <label class="mb-0" rxText="PowerBIReportEditComponent_PowerBIReportInfo_t"></label></a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="currentActiveTab == 'power-bi-reports-user-role'"
                (click)="activatedTab('power-bi-reports-user-role')"><label class="mb-0" rxText="PowerBIReportEditComponent_UserRoles_t"></label></a>
            </li>
            <li>
              <a class="nav-link" data-toggle="tab" [class.active]="currentActiveTab == 'power-bi-reports-notes'"
                (click)="activatedTab('power-bi-reports-notes')"><label class="mb-0" rxText="PowerBIReportEditComponent_Notes_t"></label></a>
            </li>
          </ul>
          <div class="slick_demo-btn"></div>

          <div class="tab-content">
            <div role="tabpanel" class="tab-pane d-block" [rxLocalisationInit]="componentName" *ngIf="currentActiveTab == 'power-bi-reports'">
              <div class="panel-body">
                <div class="bg-white d-flex flex-column flex-grow-1 pb-0 px-0"
                  [formGroup]="powerBiReportFormGroup">
                  <div class="row add-countries-form" (keyup.enter)="editPowerBIReport(false)">
                    <div class="col-md-6 pr-xl-7">
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"  rxText="PowerBIReportEditComponent_ID_t"></label>
                        <div class="col-md-8">
                          <label type="text"
                                      class="form-control disableLabel-withoutheight mb-0">{{(powerBiReportFormGroup.value.powerBIReportId
                                      === null || powerBiReportFormGroup.value.powerBIReportId === undefined ) ? '' :
                                      powerBiReportFormGroup.value.powerBIReportId}}</label>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"
                          rxText="PowerBIReportAddEditComponent_Name_t"> </label>
                        <div class="col-md-8">
                          <input type="text" formControlName="powerBIReportName" tabindex="1" class="form-control "
                             rxPlaceholder="PowerBIReportAddEditComponent_Name_p" rxFocus />
                          <small class="form-text text-danger"
                            [class.d-block]="powerBiReportFormGroup.controls.powerBIReportName.errors">{{powerBiReportFormGroup.controls.powerBIReportName.errorMessage}}</small>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-md-4 col-form-label"
                          rxText="PowerBIReportAddEditComponent_GUID_t"> </label>
                        <div class="col-md-8">
                          <input type="text" formControlName="powerBIReportGUID" tabindex="2" class="form-control "
                             rxPlaceholder="PowerBIReportAddEditComponent_GUID_p" />
                          <small class="form-text text-danger"
                            [class.d-block]="powerBiReportFormGroup.controls.powerBIReportGUID.errors">{{powerBiReportFormGroup.controls.powerBIReportGUID.errorMessage}}</small>
                        </div>
                      </div>

                      <div class="form-group row check-group">
                        <label class="col-md-4 col-form-label"
                          rxText="PowerBIReportAddEditComponent_Published_t"> </label>
                        <div class="col-md-auto d-flex align-items-center mt-2">
                          <div class="i-checks" >
                            <label class="mb-0">
                              <app-checkbox [tabIndex]="'6'"[checkFormControl]="powerBiReportFormGroup.controls.published"></app-checkbox>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pl-xl-5">

                    </div>
                  </div>

                  <div class="hr-line-dashed border-top w-100"></div>
                  <div class="row mt-auto">

                    <div class="col text-right">
                      <button (click)="editPowerBIReport(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                      <button (click)="editPowerBIReport(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                      <ng-container *ngIf="powerBIReportDelete" [rxLocalisationInit]="componentName">
                        <button (click)="deletePowerBIReport()" class="btn btn-danger"
                          rxText="Btn_Delete_t"></button>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane d-block" *ngIf="currentActiveTab == 'power-bi-reports-user-role'">
              <app-power-bi-report-user-role></app-power-bi-report-user-role>
            </div>
            <div role="tabpanel" class="tab-pane d-block" *ngIf="currentActiveTab == 'power-bi-reports-notes'">
              <app-power-bi-report-notes></app-power-bi-report-notes>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
