import {vCustomerRelationshipRecordBase,} from '@app/database-models'
//Generated Imports
export class vCustomerRelationshipRecord extends vCustomerRelationshipRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}