import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCollectionDesignRecordBase {

//#region collectionDesignId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'collectionDesignId', keyColumn: true})
        collectionDesignId : number;
//#endregion collectionDesignId Prop


//#region collectionId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'collectionId', keyColumn: false})
        collectionId : number;
//#endregion collectionId Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region designtypeId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'designtypeId', keyColumn: false})
        designtypeId : number;
//#endregion designtypeId Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'displayOrderId', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop


//#region videoUrl Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'videoUrl', keyColumn: false})
        videoUrl : string;
//#endregion videoUrl Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region imageUrlNameFirst Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'imageUrlNameFirst', keyColumn: false})
        imageUrlNameFirst : string;
//#endregion imageUrlNameFirst Prop


//#region imageUrlAltFirst Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'imageUrlAltFirst', keyColumn: false})
        imageUrlAltFirst : string;
//#endregion imageUrlAltFirst Prop

}