import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class StateProvinceLocalizationBase {

//#region stateProvinceLocalizedId Prop
        @prop()
        stateProvinceLocalizedId : number;
//#endregion stateProvinceLocalizedId Prop


//#region stateProvinceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        stateProvinceId : number;
//#endregion stateProvinceId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop

}