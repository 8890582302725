<div [rxSpinner]="spin && showInnerSpin" *rxMultilingualExtended="_this" [rxLocalisationInit]="componentName">
  <div class="">
    <ngx-dropzone [disabled]="disabled" (change)="onSelect($event)"
      [ngStyle]="{'height' : isEditorDocument ? '60px' : '180px'}">
      <ngx-dropzone-label>
        <ng-container *ngIf="!isPicture" [rxLocalisationInit]="componentName">
          <label rxText='NoteDocumentComponent_DropZone_isDocument_t'></label>
        </ng-container>
      </ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>

  <div *ngIf="isEditorDocument" class="p-3">
    <p *ngFor="let url of documentListModel">
      {{url.fileName}} <a class="float-right" [title]="deleteHelp"
        (click)="onDelete(url)"><i class="fr-delete text-danger"></i></a>
    </p>
  </div>
</div>
