import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const PRICEBANDACTION_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.UserId != null || this.UserId != undefined) {
              return "/users/" + this.UserId + "?activeTab=user-edit";
            }
            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.languageContentKeyId != null || this.languageContentKeyId != undefined) {
              let CT: string = "";
              if (this.contentType == "Text") {
                CT = 't';
              }
              if (this.contentType == "Grid Header") {
                CT = 'gh';
              }
              if (this.contentType == "Placeholder") {
                CT = 'p';
              }
              if (this.contentType == "Guidance Note") {
                CT = 'gn';
              }
              if (this.contentType == "Excel Sheet Header") {
                CT = 'ex';
              }
              if (this.contentType == "Toastr") {
                CT = 'tr';
              }
              return "/language-content-keys/" + this.languageContentKeyId + "/" + CT;
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.eventID != null || this.eventID != undefined) {
              return "/events/" + this.eventID + "?activeTab=event-info";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemstoneId != null || this.gemstoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemstoneId + "?activeTab=gemstone-edit";
            }
            if (this.customerId != null || this.customerId != undefined) {
              return "/users/" + this.customerId + "?activeTab=user-edit";
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.pricebandOptionId != null || this.pricebandOptionId != undefined) {
              return "/priceband-options/" + this.pricebandOptionId + "?activeTab=gemstoneOrder-edit";
            }
            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
              return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
            }
            if (this.gemStoneOrderId != null || this.gemStoneOrderId != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderId + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/bookings/" + this.tripId + "?activeTab=booking-edit";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/leave-masters/" + this.masterId;
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.watchPartId != null || this.watchPartId != undefined) {
              return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.UserId != null || this.UserId != undefined) {
              return "/users," + this.UserId + "?activeTab=user-edit";
            }
            if (this.userId != null || this.userId != undefined) {
              return "/users/" + this.userId + "?activeTab=user-edit";
            }
            if (this.CustomerID != null || this.CustomerID != undefined) {
              return "/users/" + this.CustomerID + "?activeTab=user-edit";
            }
            if (this.incidentID != null || this.incidentID != undefined) {
              return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
            }
            if (this.IncidentID != null || this.IncidentID != undefined) {
              return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
            }
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
            if (this.languageContentKeyId != null || this.languageContentKeyId != undefined) {
              let CT: string = "";
              if (this.contentType == "Text") {
                CT = 't';
              }
              if (this.contentType == "Grid Header") {
                CT = 'gh';
              }
              if (this.contentType == "Placeholder") {
                CT = 'p';
              }
              return "/language-content-keys/" + this.languageContentKeyId + "/" + CT;
            }
            if (this.gemStoneOrderID != null || this.gemStoneOrderID != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderID + "?activeTab=gemstoneOrder-edit";
            }
            if (this.eventID != null || this.eventID != undefined) {
              return "/events/" + this.eventID + "?activeTab=event-info";
            }
            if (this.bookingId != null || this.bookingId != undefined) {
              return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
            }
            if (this.gemstoneId != null || this.gemstoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemstoneId + "?activeTab=gemstone-edit";
            }
            if (this.customerId != null || this.customerId != undefined) {
              return "/users/" + this.customerId + "?activeTab=user-edit";
            }
            if (this.gemStoneId != null || this.gemStoneId != undefined) {
              return "/product-piece-gemstones/" + this.gemStoneId + "?activeTab=gemstone-edit";
            }
            if (this.pricebandOptionId != null || this.pricebandOptionId != undefined) {
              return "/priceband-options/" + this.pricebandOptionId + "?activeTab=gemstoneOrder-edit";
            }
            if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
              return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
            }
            if (this.gemStoneOrderId != null || this.gemStoneOrderId != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderId + "?activeTab=gemstoneOrder-edit";
            }
            if (this.tripId != null || this.tripId != undefined) {
              return "/bookings/" + this.tripId + "?activeTab=booking-edit";
            }
            if (this.masterId != null || this.masterId != undefined) {
              return "/leave-masters/" + this.masterId;
            }
            if (this.tierId != null || this.tierId != undefined) {
              return "/grievance-tiers/" + this.tierId;
            }
            if (this.grievanceTypeId != null || this.grievanceTypeId != undefined) {
              return "/grievance-types/" + this.grievanceTypeId;
            }
            if (this.watchPartId != null || this.watchPartId != undefined) {
              return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
            }
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
};

export const EYEACTION_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-eye fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_View") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.projectID != null || this.projectID != undefined) {
              return "/projects/" + this.projectID + "?activeTab=project-edit";
            }
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
};
export const EDITICON_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.gemStoneOrderId != null || this.gemStoneOrderId != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderId + "?activeTab=gemstoneOrder-edit";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.gemStoneOrderId != null || this.gemStoneOrderId != undefined) {
              return "/gemstone-orders/" + this.gemStoneOrderId + "?activeTab=gemstoneOrder-edit";
            }
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
};

export const EDITICON_GRID_TEMPLATE_BOOKING_CLIENT : TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            return this.url
          },
          "ng-reflect-router-link": function (e) {
            return this.url
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
}

export const EDITICON_GRID_TEMPLATE_HOLIDAYPROCESS: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.id != null || this.id != undefined) {
              return "/customer-holidays/" + this.id;
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.id != null || this.id != undefined) {
              return "/customer-holidays/" + this.id;
            }
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
};


export const EDITICON_GRID_TEMPLATE_FABCALLME: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.callMeId != null || this.callMeId != undefined) {
              return "/faberge-customer-call-me/" + this.callMeId;
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.callMeId != null || this.callMeId != undefined) {
              return "/faberge-customer-call-me/" + this.callMeId;
            }
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
};

export const EDITICON_GRID_TEMPLATE_PROJEVENTTAB: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        attributes: {
          href: function (e) {
            if (this.eventID != null || this.eventID != undefined) {
              return "/events/" + this.eventID + "?activeTab=event-info";
            }
          },
          "ng-reflect-router-link": function (e) {
            if (this.eventID != null || this.eventID != undefined) {
              return "/events/" + this.eventID + "?activeTab=event-info";
            }
          },
          onClick: "return false;"
        },
        class: ["mr-2"]
      }
    }]
  }
};

export const EDITICON_GRID_TEMPLATE_PRODUCTION_FORM: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        authorize: "edit",
        isBind: (x) => {
          if (x.total != 'Total') {
            return true;
          }
          else {
            return false;

          }
        },
        event: {
          click: "onEdit"
        },
        childrens: [{
          i: {
            class: "fa fa-edit fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Edit") }
          }
        }],
        class: ["mr-2"]
      }
    }]
  }
};

export const PRICE_WITH_PRODUCT_OUT_OF_STOCK: TemplateConfig = {
  span: {
    childrens: [{
      text: {
        text: function (e) {
          return this[e.name];
        }
      },
      span:{
        class: "text-danger col-form-label font-weight-bold ml-2".split(" "),
        isBind : (x)=>{
          return x.inStock != null && x.inStock!= undefined && (x.inStock==false ? true : false) && x.isPOA!=null && x.isPOA!=undefined && (x.isPOA==false ? true : false);
        },
        childrens:[{
          text: {
            text: function (e) {
              return getLocalizedValue("Product_OutOfStock_Mapping");
            }
          }
        }]
      }
    }],
  }
}
