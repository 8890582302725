import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Event, vEventTypeLookUp, vEventOwnerLookUp, EventBooking } from '@app/models';
import { AbstractEvent } from '../domain/abstract-event';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { Router } from '@angular/router';
import { EventBookingBase } from '@app/database-models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CoreComponent, access } from '@rxweb/angular-router';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { EventEditComponent } from '../edit/event-edit.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { EventTypeEnum } from "src/app/enums/event-type.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { getBlobImgURL } from "src/app/domain/system-constant";

@multilingual("EventAddComponent")
@access({ accessLevel: RolePermissionEnums.Event, action: "post" })
@Component({
  selector: "app-event-add",
  templateUrl: './event-add.component.html'
})
export class EventAddComponent extends AbstractEvent implements OnInit, OnDestroy {
  subscription: any;
  isBooking: number = 0;
  eventEdit: any = EventEditComponent;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;
  imageTooltip: string;


  omponent;
  _this;


  constructor(router: Router, modalView: ModalView, private formBuilder: RxFormBuilder, private sanitizer: DomSanitizer, private applicationBroadcaster: ApplicationBroadcaster, private el: ElementRef, private title: Title,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modalView;
    this.router = router;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'events', subMenu: '' })
    this.moduleID = RolePermissionEnums.Event;
    this.localizationData = (MultiLingualData.get(this.componentName) as any);
    this._this = this;
  }

  ngOnInit(): void {
    this.imageTooltip = getLocalizedValue("EventAddEdit_ImageUploadTooltip_t");
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.titleHelp = getLocalizedValue("Title_Help");
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    this.title.setTitle("Events - G-Trac Admin");
    this.event = new Event();
    this.selectedTab = "event-info";
    this.spin = true;
    this.lookup([
      { path: USER_LOOKUPS["eventOwnerLookUp"], propName: "eventOwnerLookUp" },
      { path: 'api/SearchEvent/GetEventTrainerDeliveryMethod', propName: "eventTrainerDeliveryMethod" },
      { path: 'api/SearchEvent/GetEventTrainerCoveredArea', propName: "eventTrainerCoveredArea" },
      { path: 'api/SearchEvent/GetEventEmployeeRiskCategory', propName: "eventEmpoyeeRiskCategory" },
      { path: USER_LOOKUPS["eventTypeLookUp"], propName: "eventTypeLookUp" },
      { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.genfieldsGroupEntity, propName: "genfieldsGroupEntity" },
      { path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp" },
      { path: USER_LOOKUPS["eventServiceTypeLookUp"], propName: "eventServiceTypeLookUp" }
    ]).subscribe(response => {
      this.event = new Event();
      //this.event.eventType=165;
      this.event.eventBooking = new EventBooking();
      this.event.eventBooking.eventBookingID = 0;
      this.event.eventBooking.bookingID = 0;
      this.event.eventBooking.eventID = 0;
      this.event.eventID = 0;
      this.event.statusId = StatusEnum.Active;
      this.event.eInvitationDescription = "fdasfads";
      this.event.eventGUID = this.newguidgen();
      this.event.pictureID = 123;
      this.event.displayImage = false;
      this.event.isAttendanceUploaded = false;
      this.event.isIncludeBusinessElement = false;
      this.eventLookup = response;
      this.event.eventIndicator = 0;
      this.spin = false;
      this.eventFormGroup = this.formBuilder.formGroup(Event, this.event) as IFormGroup<Event>;
      this.eventFormGroup.controls.eventIndicator.setValidators([
        RxwebValidators.required({ message: "You can't leave this field empty" }),
        RxwebValidators.pattern({ expression: { 'eventIndicator': /^[0-9][0-9]?$|^100$/ }, message: 'Please enter correct value' })
      ]);
      this.eventFormGroup.controls.actualCost.setValidators([
        RxwebValidators.pattern({ expression: { 'actualCost': /^[0-9]\d{0,12}(\.\d{1,2})?$/ }, message: 'Please enter valid actual cost (13 digits and 2 decimal places are allowed)' })
      ]);
      this.eventFormGroup.controls.costPerGuest.setValidators([
        RxwebValidators.pattern({ expression: { 'costPerGuest': /^[0-9]\d{0,12}(\.\d{1,2})?$/ }, message: 'Please enter valid cost per guest (13 digits and 2 decimal places are allowed)' })
      ]);
      this.eventFormGroup.controls.budget.setValidators([
        RxwebValidators.required({ message: "You can't leave this field empty" }),
        RxwebValidators.pattern({ expression: { 'budget': /^[0-9]\d{0,12}(\.\d{1,2})?$/ }, message: 'Please enter valid budget (13 digits and 2 decimal places are allowed)' })
      ]);
      this.showComponent = true;

    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  newguidgen() {
    function id() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return id() + id() + '-' + id() + '-' + id() + '-' +
      id() + '-' + id() + id() + id();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  getImgContent(): SafeUrl {
    let imgUrl = "data:" + this.eventFormGroup.controls.fileExtention.value + ";base64," + this.eventFormGroup.controls.fileData.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
  }
  deliveryMethodValidation(event) {
    if (this.eventFormGroup.value.eventType != EventTypeEnum.Training) {
      this.eventFormGroup.get('deliveryMethod').clearValidators();
      this.eventFormGroup.get('deliveryMethod').updateValueAndValidity();
    }
    else {
      this.eventFormGroup.get('deliveryMethod').setValidators([RxwebValidators.required()]);
      this.eventFormGroup.get('deliveryMethod').updateValueAndValidity();
    }
  }

  async addEvent(isRedirect: boolean) {
    this.eventFormGroup.submitted = true;
    this.eventFormGroup.patchValue({ createdOn: new Date() });
    this.startDate = new Date();
    this.startDate = this.eventFormGroup.controls.eventDate.value;
    this.endDate = new Date();
    this.endDate = this.eventFormGroup.controls.eventEndDate.value;
    if (this.endDate != null && this.startDate > this.endDate) {
      this.dateError = true;
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.eventFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
    else {
      if (this.eventFormGroup.valid) {
        this.dateError = false;
        this.spin = true;
        if (this.eventFormGroup.value.fileData) {

          let res = await this.uploadToBlob(this.fileOutputViewModel, this.eventFormGroup.value.blobPath);
          this.eventFormGroup.patchValue({
              fileData: null,
              blobFileName: res
          });
      }
        if (this.eventFormGroup.value.eventBooking.bookingID == undefined) {
          this.eventFormGroup.value.eventBooking.bookingID = 0;
        }
        if (this.eventFormGroup.value.eventBooking.bookingID == 0 || this.eventFormGroup.value.eventBooking.bookingID.toString() == "" || this.eventFormGroup.value.eventBooking.bookingID == undefined || this.eventFormGroup.value.eventBooking.bookingID == null) {
          this.eventFormGroup.value.eventBooking.bookingID = 0;
        }

        if (this.eventFormGroup.value.eventType != EventTypeEnum.Training) {
          this.eventFormGroup.patchValue({
            deliveryMethod: 0,
            trainerDetails: '',
            riskCategory: 0,
            trainingAreas: '',
            isAttendanceUploaded: false,
            isIncludeBusinessElement: false,
          })
        }
        this.post({ body: this.eventFormGroup.value }).subscribe(data => {
          this.spin = false;
          //this.toastr.success("Data Added Successfully")
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
          if (existsAlert) {
            this.toastr.success(existsAlert[0].showMessage);
          }
          isRedirect ? this.router.navigate(["events", data], { queryParams: { activeTab: 'event-info' } }) : this.router.navigate(['/events']);

        })
      } else {
        this.breakLoop = false;
        this.validField.forEach(x => {
          if (this.eventFormGroup.controls[x].invalid && !this.breakLoop) {
            const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
            invalidControl.focus();
            this.breakLoop = true;
          }
        });
      }
    }


  }

  get componentName(): string {
    return "EventAddComponent";
  }
}
