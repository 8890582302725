import { prop,required,maxLength  } from "@rxweb/reactive-form-validators"


export class LookupGINASettingBase {

//#region ginaSettingId Prop
        @prop()
        ginaSettingId : any;
//#endregion ginaSettingId Prop


//#region ginaSettingName Prop
        @maxLength({value:30})
        ginaSettingName : string;
//#endregion ginaSettingName Prop

//#region isNotGeneral Prop
        @required()
        isNotGeneral : boolean;
//#endregion isNotGeneral Prop

//#region isGeneral Prop
        @required()
        isGeneral : boolean;
//#endregion isGeneral Prop

//#region isSectionC Prop
        @required()
        isSectionC : boolean;
//#endregion isSectionC Prop

//#region active Prop
        @required()
        active : boolean;
//#endregion active Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop

}