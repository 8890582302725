import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneColorGemfieldBase {

//#region colorId Prop
        @prop()
        colorId : number;
//#endregion colorId Prop


//#region colorName Prop
        @required()
        @maxLength({value:50})
       // @alpha({allowWhiteSpace:true})
        @trim()
        @notEmpty()
        colorName : string;
//#endregion colorName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region parentColorId Prop
        @prop()
        parentColorId : number;
//#endregion parentColorId Prop



}