import { TemplateConfig } from '@rxweb/grid';


export const BIND_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["mb-0"],
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                  
                },
                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_MOVE_NOTE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["mb-0"],
          childrens: [
            {
              input:
              {
                isBind : (x) =>{
                  return x.isSubNote == false && x.isManual == true ? true : false;
                },
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                  
                },
                event: {
                  input: "onMoveNoteCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_MOVE_DOCUMENT_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["mb-0"],
          childrens: [
            {
              input:
              {
                isBind : (x) =>{
                  return x.noteId != null && x.noteId > 0 ? false : true;
                },
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                  
                },
                event: {
                  input: "onMoveDocumentCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_EMERGENCY_CONTACT_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["mb-0"],
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                  
                },
                event: {
                  input: "onEmergencyContactCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_IS_CLOSE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          class: ["mb-0"],
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                  
                },
                event: {
                  input: "onIsCloseCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_EVENT_INVITEES_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                isBind : (x) =>{
                  return x.eventCustomerId != -1 ? true : false;
                },
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                },
                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              },
              label: {
                isBind: (x) => {
                  return x.eventCustomerId == -1 ? true : false;
                },
                class: "mb-0 font-weight-bold".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Action";
                    }
                  }
                }]
              },
            }
          ]
        }
      }
    ]
  },
}
export const BIND_CHECKBOX_SHIPPING_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                isBind: (x) => {
                  if(x.productVariantBatchPieceId==0)
                  {
                    return false
                  }
                  else
                  return true
                },
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                },
                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_ADD_PROJECT_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                isBind: (x) => {
                  return x.restrictedProject == false ? true : false;
                },
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                },

                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const BIND_CHECKBOX_WITHOUT_CHECKED_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative"
                },

                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    //return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative"
                },

                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS_SECOND: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative"
                },

                event: {
                  input: "onCheckBoxSelectSecond"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const BIND_CHECKBOX_GRID_TEMPLATE_FOR_BOOKING_APPROVAL: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto"
                },

                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_GRID_TEMPLATE_FOR_SALES_REPORT: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["checkbox-custom-style"],

                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ],
          style: {
            "padding-left": "25px"
          }
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_GEMFIELD_RISK_ADD_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style: {
                  "margin-top": "-4px",
                  "position": "relative",
                  "margin-left": "auto",
                },

                event: {
                  input: "onReleventSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                    // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_CHECKBOX_GRID_TEMPLATE_FOR_EMPLOYMENT_TABLE: TemplateConfig = {
  div: {
    isBind: (x) => {
      return x.ownerMappingId > 0 ? false : true
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["checkbox-custom-style"],

                event: {
                  input: "onCheckBoxSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  },
                }
              }
            }
          ],
          style: {
            "padding-left": "25px",
            "margin-bottom":"0px"
          }
        }
      }
    ]
  },
}