import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.UserId != null || this.UserId != undefined) {
                return "/users/" + this.UserId + "?activeTab=user-edit";
              }
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              // if(this.documentId!=null || this.documentId!=undefined){
              //   return this.fileUrl;
              // }
              if (this.CustomerID != null || this.CustomerID != undefined) {
                return "/users/" + this.CustomerID + "?activeTab=user-edit";
              }
              if (this.customerId != null || this.customerId != undefined) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.projectId != null || this.projectId != undefined) {
                if (this.subStreamType == "PRJ") {
                  return "/projects/" + this.projectId + "?activeTab=project-edit";
                } else {
                  return "/product-design/" + this.linkID + "?activeTab=design-info";
                }
              }
              if (this.eventID != null || this.eventID != undefined) {
                return "/events/" + this.eventID + "?activeTab=event-info";
              }
              if (this.EventID != null || this.EventID != undefined) {
                return "/events/" + this.EventID + "?activeTab=event-info";
              }
              if (this.incidentID != null || this.incidentID != undefined) {
                return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
              }
              if (this.IncidentID != null || this.IncidentID != undefined) {
                return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
              }
              if (this.batchNo != null || this.batchNo != undefined) {
                return "/product-batch/" + this.batchNo + "?activeTab=batch-info";
              }
              if (this.Id != null || this.Id != undefined) {
                if (this.EntityType == "People") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Corporates/Group") {
                  return "/suppliers/" + this.Id + "?activeTab=supplier-edit";
                }
                else if (this.EntityType == "Customer") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Event") {
                  return "/events/" + this.Id + "?activeTab=event-info";
                }
                else if (this.EntityType == "Product") {
                  return "/product/" + this.Id + "?activeTab=product-info";
                }
                else if (this.EntityType == "Design") {
                  return "/product-design/" + this.Id + "?activeTab=design-info";
                }
                else if (this.EntityType == "Piece") {
                  return "/product-piece/" + this.Id + "?activeTab=piece-info";
                }
                else if (this.EntityType == "Project") {
                  return "/projects/" + this.Id + "?activeTab=project-edit";
                }
                else if (this.EntityType == "HSE/GINA") {
                  return "/track-incidents/" + this.Id + "?activeTab=form-edit";
                }
                else {
                  return "";
                }
              }
              if ((this.gemStoneId != null || this.gemStoneId != undefined) && this.gemStoneId > 0) {
                return "/product-piece-gemstones/" + this.gemStoneId;
              }
              if ((this.GemstoneId != null || this.GemstoneId != undefined) && this.GemstoneId > 0) {
                return "/product-piece-gemstones/" + this.GemstoneId;
              }
              if ((this.ChainPieceId != null || this.ChainPieceId != undefined) && this.ChainPieceId > 0) {
                return "/product-piece/" + this.ChainPieceId + "?activeTab=piece-info";
              }
              if ((this.id != null || this.id != undefined) && this.id > 0) {
                return "/p-o-s-carts/" + this.id;
              }
              if (this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId > 0) {
                return "/gemfield-risk-menu/" + this.riskMenuDetailId;
              }
              if (this.personAffectedId != null || this.personAffectedId != undefined) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
                return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
              }
              if (this.formId != null || this.formId != undefined) {
                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
              }
              if (this.giftFormId != null || this.giftFormId != undefined) {
                return "/electronic-gift-forms/" + this.giftFormId + "?activeTab=electronic-gift-edit";
              }
              if (this.watchPartId != null || this.watchPartId != undefined) {
                return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
              }
              if (this.WatchPartId != null && this.WatchPartId != undefined && this.WatchPartId > 0) {
                return "/watch-parts/" + this.WatchPartId + "?activeTab=watch-part-edit";
              }
              if (this.bookingId != null || this.bookingId != undefined) {
                return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
              }
              else {
                return "";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.UserId != null || this.UserId != undefined) {
                return "/users," + this.UserId + "?activeTab=user-edit";
              }
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.CustomerID != null || this.CustomerID != undefined) {
                return "/users/" + this.CustomerID + "?activeTab=user-edit";
              }
              if (this.customerId != null || this.customerId != undefined) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.projectId != null || this.projectId != undefined) {
                if (this.subStreamType == "PRJ") {
                  return "/projects/" + this.projectId + "?activeTab=project-edit";
                } else {
                  return "/product-design/" + this.linkID + "?activeTab=design-info";
                }
              }
              if (this.eventID != null || this.eventID != undefined) {
                return "/events/" + this.eventID + "?activeTab=event-info";
              }
              if (this.batchNo != null || this.batchNo != undefined) {
                return "/product-batch/" + this.batchNo + "?activeTab=batch-info";
              }
              if (this.EventID != null || this.EventID != undefined) {
                return "/events/" + this.EventID + "?activeTab=event-info";
              }
              if (this.incidentID != null || this.incidentID != undefined) {
                return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
              }
              if (this.IncidentID != null || this.IncidentID != undefined) {
                return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
              }
              if (this.Id != null || this.Id != undefined) {
                if (this.EntityType == "People") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Corporates/Group") {
                  return "/suppliers/" + this.Id + "?activeTab=supplier-edit";
                }
                else if (this.EntityType == "Customer") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Event") {
                  return "/events/" + this.Id + "?activeTab=event-info";
                }
                else if (this.EntityType == "Product") {
                  return "/product/" + this.Id + "?activeTab=product-info";
                }
                else if (this.EntityType == "Design") {
                  return "/product-design/" + this.Id + "?activeTab=design-info";
                }
                else if (this.EntityType == "Piece") {
                  return "/product-piece/" + this.Id + "?activeTab=piece-info";
                }
                else if (this.EntityType == "Project") {
                  return "/projects/" + this.Id + "?activeTab=project-edit";
                }
                else if (this.EntityType == "HSE/GINA") {
                  return "/track-incidents/" + this.Id + "?activeTab=form-edit";
                }
                else {
                  return "";
                }
              }
              if ((this.gemStoneId != null || this.gemStoneId != undefined) && this.gemStoneId > 0) {
                return "/product-piece-gemstones/" + this.gemStoneId;
              }
              if ((this.GemstoneId != null || this.GemstoneId != undefined) && this.GemstoneId > 0) {
                return "/product-piece-gemstones/" + this.GemstoneId;
              }
              if ((this.ChainPieceId != null || this.ChainPieceId != undefined) && this.ChainPieceId > 0) {
                return "/product-piece/" + this.ChainPieceId + "?activeTab=piece-info";
              }
              if ((this.id != null || this.id != undefined) && this.id > 0) {
                return "/p-o-s-carts/" + this.id;
              }
              if (this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId > 0) {
                return "/gemfield-risk-menu/" + this.riskMenuDetailId;
              }
              if (this.personAffectedId != null || this.personAffectedId != undefined) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
                return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
              }
              if (this.formId != null || this.formId != undefined) {
                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
              }
              if (this.giftFormId != null || this.giftFormId != undefined) {
                return "/electronic-gift-forms/" + this.giftFormId + "?activeTab=electronic-gift-edit";
              }
              if (this.watchPartId != null && this.watchPartId != undefined) {
                return "/watch-parts/" + this.watchPartId + "?activeTab=watch-part-edit";
              }
              if (this.WatchPartId != null && this.WatchPartId != undefined && this.WatchPartId > 0) {
                return "/watch-parts/" + this.WatchPartId + "?activeTab=watch-part-edit";
              }
              if (this.bookingId != null || this.bookingId != undefined) {
                return "/bookings/" + this.bookingId + "?activeTab=booking-edit";
              }
            },
            onClick: "return false;"
          }
        },
        span:{
          class: "text-red font-bold".split(" "),
          childrens: [{
          text: {
            text: function (e) {
              if ((this.UserId != null || this.UserId != undefined || this.userId != null || this.userId != undefined) && this.deceased == true) {
                 return " (DECEASED)"
                }
              }
            }
          }],
        }
      }
    ]
  },
};


export const REDIRECT_LEGAL_AGREEMENT_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onLegalAgreementLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              //if (this.isAccess == true) {
                return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
              // }
              // else{
              //   return "/legal-agreement/restricted-legal-agreement/" + this.legalAgreementId;
              // }
            },
            "ng-reflect-router-link": function (e) {

              //if (this.isAccess == true) {
                return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
              // }
              // else
              // {
              //   return "/legal-agreement/restricted-legal-agreement/" + this.legalAgreementId ;
              // }

            },
            onClick: "return false;"
          }
        },
      }
    ]
  },
};
export const REDIRECT_MOBILEASSET_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onMobileAssetLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
                return "/mobile-assets/" + this.MobileAssetId + "?activeTab=edit-info";
            },
            "ng-reflect-router-link": function (e) {

                return "/mobile-assets/" + this.MobileAssetId + "?activeTab=edit-info";

            },
            onClick: "return false;"
          }
        },
      }
    ]
  },
};
export const REDIRECT_DESKTOPASSET_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onAssetTagLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
                return "/desktop-assets/" + this.desktopAssetId + "?activeTab=edit-info";
            },
            "ng-reflect-router-link": function (e) {
                return "/desktop-assets/" + this.desktopAssetId + "?activeTab=edit-info";
            },
            onClick: "return false;"
          }
        },
      }
    ]
  },
};
export const REDIRECT_LINK_EVENT_NAME_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind : (x)=>{
            return x.eventCustomerId != -1 ? true : false;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.UserId != null || this.UserId != undefined) {
                return "/users/" + this.UserId + "?activeTab=user-edit";
              }
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              // if(this.documentId!=null || this.documentId!=undefined){
              //   return this.fileUrl;
              // }
              if (this.CustomerID != null || this.CustomerID != undefined) {
                return "/users/" + this.CustomerID + "?activeTab=user-edit";
              }
              if (this.customerId != null || this.customerId != undefined) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.projectId != null || this.projectId != undefined) {
                if (this.subStreamType == "PRJ") {
                  return "/projects/" + this.projectId + "?activeTab=project-edit";
                } else {
                  return "/product-design/" + this.linkID + "?activeTab=design-info";
                }
              }
              if (this.eventID != null || this.eventID != undefined) {
                return "/events/" + this.eventID + "?activeTab=event-info";
              }
              if (this.EventID != null || this.EventID != undefined) {
                return "/events/" + this.EventID + "?activeTab=event-info";
              }
              if (this.incidentID != null || this.incidentID != undefined) {
                return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
              }
              if (this.IncidentID != null || this.IncidentID != undefined) {
                return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
              }
              if (this.batchNo != null || this.batchNo != undefined) {
                return "/product-batch/" + this.batchNo + "?activeTab=batch-info";
              }
              if (this.Id != null || this.Id != undefined) {
                if (this.EntityType == "People") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Corporates/Group") {
                  return "/suppliers/" + this.Id + "?activeTab=supplier-edit";
                }
                else if (this.EntityType == "Customer") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Event") {
                  return "/events/" + this.Id + "?activeTab=event-info";
                }
                else if (this.EntityType == "Product") {
                  return "/product/" + this.Id + "?activeTab=product-info";
                }
                else if (this.EntityType == "Design") {
                  return "/product-design/" + this.Id + "?activeTab=design-info";
                }
                else if (this.EntityType == "Piece") {
                  return "/product-piece/" + this.Id + "?activeTab=piece-info";
                }
                else {
                  return "";
                }
              }
              if ((this.gemStoneId != null || this.gemStoneId != undefined) && this.gemStoneId > 0) {
                return "/product-piece-gemstones/" + this.gemStoneId;
              }
              if ((this.ChainPieceId != null || this.ChainPieceId != undefined) && this.ChainPieceId > 0) {
                return "/product-piece/" + this.ChainPieceId + "?activeTab=piece-info";
              }
              if ((this.id != null || this.id != undefined) && this.id > 0) {
                return "/p-o-s-carts/" + this.id;
              }
              if (this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId > 0) {
                return "/gemfield-risk-menu/" + this.riskMenuDetailId;
              }
              if (this.personAffectedId != null || this.personAffectedId != undefined) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
                return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
              }
              if (this.formId != null || this.formId != undefined) {
                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
              }
              if (this.giftFormId != null || this.giftFormId != undefined) {
                return "/electronic-gift-forms/" + this.giftFormId + "?activeTab=electronic-gift-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.UserId != null || this.UserId != undefined) {
                return "/users," + this.UserId + "?activeTab=user-edit";
              }
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.CustomerID != null || this.CustomerID != undefined) {
                return "/users/" + this.CustomerID + "?activeTab=user-edit";
              }
              if (this.customerId != null || this.customerId != undefined) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.projectId != null || this.projectId != undefined) {
                if (this.subStreamType == "PRJ") {
                  return "/projects/" + this.projectId + "?activeTab=project-edit";
                } else {
                  return "/product-design/" + this.linkID + "?activeTab=design-info";
                }
              }
              if (this.eventID != null || this.eventID != undefined) {
                return "/events/" + this.eventID + "?activeTab=event-info";
              }
              if (this.EventID != null || this.EventID != undefined) {
                return "/events/" + this.EventID + "?activeTab=event-info";
              }
              if (this.incidentID != null || this.incidentID != undefined) {
                return "/track-incidents/" + this.incidentID + "?activeTab=form-edit";
              }
              if (this.IncidentID != null || this.IncidentID != undefined) {
                return "/track-incidents/" + this.IncidentID + "?activeTab=form-edit";
              }
              if (this.Id != null || this.Id != undefined) {
                if (this.EntityType == "People") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Corporates/Group") {
                  return "/suppliers/" + this.Id + "?activeTab=supplier-edit";
                }
                else if (this.EntityType == "Customer") {
                  return "/users/" + this.Id + "?activeTab=user-edit";
                }
                else if (this.EntityType == "Event") {
                  return "/events/" + this.Id + "?activeTab=event-info";
                }
                else if (this.EntityType == "Product") {
                  return "/product/" + this.Id + "?activeTab=product-info";
                }
                else if (this.EntityType == "Design") {
                  return "/product-design/" + this.Id + "?activeTab=design-info";
                }
                else if (this.EntityType == "Piece") {
                  return "/product-piece/" + this.Id + "?activeTab=piece-info";
                }
                else {
                  return "";
                }
              }
              if ((this.gemStoneId != null || this.gemStoneId != undefined) && this.gemStoneId > 0) {
                return "/product-piece-gemstones/" + this.gemStoneId;
              }
              if ((this.ChainPieceId != null || this.ChainPieceId != undefined) && this.ChainPieceId > 0) {
                return "/product-piece/" + this.ChainPieceId + "?activeTab=piece-info";
              }
              if ((this.id != null || this.id != undefined) && this.id > 0) {
                return "/p-o-s-carts/" + this.id;
              }
              if (this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId > 0) {
                return "/gemfield-risk-menu/" + this.riskMenuDetailId;
              }
              if (this.personAffectedId != null || this.personAffectedId != undefined) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.legalAgreementId != null || this.legalAgreementId != undefined) {
                return "/legal-agreement/" + this.legalAgreementId + "?activeTab=legal-agreement-info";
              }
              if (this.formId != null || this.formId != undefined) {
                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
              }
              if (this.giftFormId != null || this.giftFormId != undefined) {
                return "/electronic-gift-forms/" + this.giftFormId + "?activeTab=electronic-gift-edit";
              }
            },
            onClick: "return false;"
          }
        },
        label: {
          isBind: (x) => {
            return x.eventCustomerId == -1 ? true : false;
          },
          class: "mb-0 font-weight-bold".split(" "),
          childrens: [{
            text: {
              text: function (e) {
                return "Name";
              }
            }
          }]
        },
      }
    ]
  },
};

export const REDIRECT_REPAIR_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const REDIRECT_PRODUCT_LINK_GRID_TEMPLATE: TemplateConfig={
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onProductLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              return "/product/" + this.productId + "?activeTab=product-info";

            },
            "ng-reflect-router-link": function (e) {
              return "/product/" + this.productId + "?activeTab=product-info";
            },
            onClick: "return false;"
          }
        },

      }
    ]
  },
}



export const REDIRECT_MY_DIRECT_REPORT_FORTHCOMING_LEAVE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onLeaveUserClick"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            onClick: "return false;"
          }
        }
      }
    ]
  },
};



export const REDIRECT_USER_LEAVE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=leave-request";
              }
              else {
                return "";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=leave-request";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};
