<!-- <div class="wrapper wrapper-content"  [rxSpinner]="spin"> -->
  
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1"  [rxSpinner]="spin"  *rxMultilingual="" [rxLocalisationInit]="componentName">
        
        <div class="dataTables_filter align-items-center">
            <div class="row">   
                <div class="col-lg-8">
                </div>
                <div class="col-lg-4">
                      <label class="ml-auto mb-3 d-flex">
                              <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4 pull-right"
                              placeholder="" *ngIf="(isShowGrid && !isFilter) || isFilter">
                          </label>
                </div>
                  
             </div>
              
          </div>
        <div class="table-responsive" *ngIf="isShowGrid">
          <div  class="dataTables_wrapper pb-0 dt-bootstrap4" id="productGrid">
            <rx-grid [design]="deptCustomerGrid"></rx-grid>
          </div>
        </div>
      
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
          <h4>No Record Found</h4>
      </div>
      
      </div>
      
      
      <!-- </div> -->
