import { actionColumn, gridColumn } from '@rxweb/grid'

@actionColumn({
    allowSorting: false,
    style: { width: "5%", "text-align": "initial" },
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})
export class LeaveTypeCorporateViewModelBase {

    //#region userId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean;
    //#endregion userId Prop

    //configuredTemplate: { templateName: 'bindCheckBoxWithoutChecked' },

    //#region corporateId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: false, columnIndex: 1, headerTitle: 'SupplierId', keyColumn: true })
    corporateId: number;
    //#endregion corporateId Prop

    //#region company Prop
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.company;
                    }
                }
            }
        },
        style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'LeaveTypeCorporateMappingAddComponent_Name_gh', keyColumn: false
    })
    company: string
    //#endregion company Prop

    //#region email Prop
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.email;
                    }
                }
            }
        }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'LeaveTypeCorporateMappingAddComponent_Email_gh', keyColumn: false
    })
    email: string
    //#endregion email Prop

    //#region fullName Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'CorporateCompanyMappingAddComponent_FullName_gh', keyColumn: false })
    fullName: string
    //#endregion fullName Prop

    //#region totalCount Prop
    @gridColumn({ style: { width: "35%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: true, headerKey: 'Total Count', keyColumn: false })
    totalCount: number
    //#endregion totalCount Prop
}