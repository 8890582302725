import {SupplierPictureBase,PictureBase,SupplierBase,} from '@app/database-models'
//Generated Imports
export class SupplierPicture extends SupplierPictureBase 
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop

//#endregion Generated Reference Properties












}