import { prop, maxLength, required, range } from '@rxweb/reactive-form-validators';

export class CorporateCreditRatingBase {
    //#region corporateCreditRatingId Prop
    @prop()
    corporateCreditRatingId: number;
    //#endregion corporateCreditRatingId Prop

    //#region moody Prop
    @prop()
    //@required()
    @maxLength({ value: 100 })
    moody: string;
    //#endregion moody Prop

    //#region sP Prop
    @prop()
    @required()
    @maxLength({ value: 100 })
    sp: string;
    //#endregion sP Prop

    //#region fitch Prop
    @prop()
    @required()
    @maxLength({ value: 100 })
    fitch: string;
    //#endregion fitch Prop

    //#region creditWorthiness Prop
    @prop()
    @maxLength({ value: 4000 })
    creditWorthiness: string;
    //#endregion creditWorthiness Prop

    //#region statusId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    statusId: number;
    //#endregion statusId Prop

    //#region createdBy Prop
    @prop()
    //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
    //@required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdAt Prop
    @prop()
    //@required()
    createdAt: Date;
    //#endregion createdAt Prop

    //#region updatedBy Prop
    @prop()
    updatedBy: number;
    //#endregion updatedBy Prop

    //#region updatedAt Prop
    @prop()
    updatedAt: Date;
    //#endregion updatedAt Prop

}