import { vBookingClientsBase } from '@app/database-models';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

@actionColumn({
    allowSorting: false,
    style: { width: "5%" },
    configuredTemplate: { templateName: 'editBookingClient' }, columnIndex: 10, headerTitle: "Action"
})
export class vBookingClients extends vBookingClientsBase
{

@prop()
totalCount: number;


//#region Generated Reference Properties
//#endregion Generated Reference Properties
}
