import {ProjectProductMappingBase,ProjectBase,} from '@app/database-models'
//Generated Imports
export class ProjectProductMapping extends ProjectProductMappingBase 
{




//#region Generated Reference Properties
//#region project Prop
        project : ProjectBase;
//#endregion project Prop

//#endregion Generated Reference Properties
}