import { actionColumn, gridColumn } from "@rxweb/grid";
@actionColumn({
  allowSorting: false,
  style: { width: "1%"},
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 1
})
export class RiskPressMediaViewAddModel{
  @gridColumn({
    configuredTemplate: { templateName: 'bindCheckBox' },
    style: { width: "1%", "text-align": "center" },visible: true,allowSorting:false, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
  isChecked:boolean

  @gridColumn({
    template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.PressMedia;
          }
        },
      }
  },
  parameter:true,style: { width: "3%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 2, allowSorting: true, headerKey: 'PressMediaViewAddComponent_PressMedia_gh', keyColumn: false })
  PressMedia:string

  @gridColumn({
    template:{
      div:{
          style:{
            "word-break": "break-all",
          },
        attributes:{
          innerHTML:function(x,y){
           return this.Article;
          }
        }

      }
  },
  parameter:true,style: { width: "10%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 3, allowSorting: false, headerKey: 'PressMediaViewAddComponent_Article_gh', keyColumn: false })
  Article:string

  totalCount : number;
  PressMediaId : number;
}
