import { RxHttp,http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { StateProvince } from '@app/models';
import { vCountryLookUp,vLanguageLookUp} from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
@http({
    path: "api/StateProvinces",
})
export class AbstractStateProvince extends CoreComponent {
    stateProvinceFormGroup: IFormGroup<StateProvince>
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    stateProvinceLookup: any = {
        countryLookUp: vCountryLookUp,
        languageLookUp:vLanguageLookUp,
        stateProvince: StateProvince
    }
    validField: string[] = ["name","abbreviation","displayOrder","countryId"];
    breakLoop: boolean;
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }


}
