import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const CURRENT_PURCHASE_LIST_EXPAND_BUTTON_GRID_TEMPLATE: TemplateConfig = {
    a: {

        isBind: (x) => {
            if (x.detail == false || x.detail == "false" || x.detail == null || x.detail.length == 1 || x.isExpand == false) {
                x.isExpandColumn = false;
                return false;

            }
            else {
                x.isExpandColumn = true;
                return true;

            }
        },
        event: {
            click: "onExpand"
        },
        childrens: [{
            i: {
                class: "fa fa-plus mr-2".split(" "),
                attributes: { "title": getLocalizedValue("Title_Expand") }
            }
        }]
    }
};
