import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const EXPAND_BUTTON_GRID_TEMPLATE: TemplateConfig = {
  a: {

    isBind: (x) => {
      if (x.detail == false || x.detail == "false" || x.detail == null || x.isExpand == false) {
        x.isExpandColumn = false;
        return false;

      }
      else {
        x.isExpandColumn = true;
        return true;

      }
    },
    event: {
      click: "onExpand"
    },
    childrens: [{
      i: {
        class: "fa fa-plus mr-2 mt-1".split(" "),
        attributes: { "title": getLocalizedValue("Title_Expand") }
      }
    }]
  }
  // div: {
  //   childrens: [
  //     {
  //       i: {
  //         class: [function () { "fa fa-plus mr-2".split(" ")}],
  //       },
  //       a: {
  //         isBind: (x) => {
  //           if(x.detail==false)
  //           {
  //             return false;
  //           }
  //           else{
  //             return true;
  //           }
  //         }
  //       },
  //     },
  //   ],
  //   event: {
  //     click: "onExpand"
  //   }
  // },
};
export const EXPAND_BUTTON_DASHBOARD_TEMPLATE: TemplateConfig = {
  a: {

    isBind: (x) => {
      if (x.detail == false || x.detail == "false" || x.detail == null || x.isExpand == false) {
        x.isExpandColumn = false;
        return false;

      }
      else {
        x.isExpandColumn = true;
        return true;

      }
    },
    event: {
      click: "onExpand"
    },
    class: "fa fa-plus mr-2 mt-1".split(" "),
    attributes: { "title": getLocalizedValue("Title_Expand") }
  }
};
export const EXPAND_NOTIFICATION_BUTTON_GRID_TEMPLATE: TemplateConfig = {
  a: {

    isBind: (x) => {
      if (x.detail == false || x.detail == "false" || x.detail == null || x.isExpand == false) {
        x.isExpandColumn = false;
        return false;

      }
      else {
        x.isExpandColumn = true;
        return true;

      }
    },
    event: {
      click: "onExpand"
    },
    class: "fa fa-minus mr-2 mt-1".split(" "),
    attributes: { "title": getLocalizedValue("Title_Collapse") }
    // childrens: [{
    //   i: { class: "fa fa-minus mr-2".split(" ") }
    // }]
  }
};
export const COLLAPSE_NOTIFICATION_BUTTON_GRID_TEMPLATE: TemplateConfig = {
  label: {
    isBind: (x) => {
      if(x.expanded == 1)
      {
        if (x.detail == false || x.detail == "false" || x.detail == null || x.isExpand == false) {
          x.isExpandColumn = false;
          return false;

        }
        else {
          x.isExpandColumn = true;
          return true;

        }
      }
      else
      {
        return false;
      }
    },
    event: {
      click: "onExpand"
    },
    style:{
      "color":"#23be9e"
    },
    class: "fa fa-plus mr-2 mt-1".split(" "),
    attributes: { "title": getLocalizedValue("Title_Expand") }
    // childrens: [{
    //   i: { class: "fa fa-minus mr-2".split(" ") }
    // }]
  },
  span : {
    isBind: (x) => {
      if(x.expanded == 0)
      {
        if (x.detail == false || x.detail == "false" || x.detail == null || x.isExpand == false) {
            x.isExpandColumn = false;
          return false;

        }
        else {
          x.isExpandColumn = true;
          return true;

        }
      }
      else
      {
        return false
      }
    },
    event: {
      click: "onExpand"
    },
    style:{
      "color":"#23be9e"
    },
    class: "fa fa-minus mr-2 mt-1".split(" "),
    attributes: { "title": getLocalizedValue("Title_Collapse") }
  },
  // div :{
  //   isBind : (x)=>{
  //     if(x.isExpandColumn == true)
  //     {
  //       return false;
  //     }
  //     else
  //     {
  //       return true;
  //     }
  //   },
  //   childrens :[
  //     {
  //       text: {
  //         text: function (e) { return "No Employee is collaborated with company" }
  //       }
  //     }
  //   ]
  // }
};
