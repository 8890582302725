import {vEventTypeLookUpBase, vEventOwnerLookupBase,} from '@app/database-models'
//Generated Imports
export class vEventOwnerLookUp extends vEventOwnerLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}