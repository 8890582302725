import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CarnetNoteBase {

//#region carnetNoteID Prop
        @prop()
        carnetNoteID : number;
//#endregion carnetNoteID Prop


//#region carnetID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        carnetID : number;
//#endregion carnetID Prop


//#region note Prop
        @required()
        note : string;
//#endregion note Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region subject Prop
        @prop()
        subject : string;
//#endregion subject Prop


//#region isManual Prop
        @prop()
        isManual : boolean;
//#endregion isManual Prop


//#region noteType Prop
        @prop()
        noteType : number;
//#endregion noteType Prop


//#region isDisplayTeamMember Prop
        @required()
        isDisplayTeamMember : boolean;
//#endregion isDisplayTeamMember Prop



}