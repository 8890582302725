import { maxLength, prop, range, required } from '@rxweb/reactive-form-validators';

export class GoalRatingMappingBase {


    //#region goalId Prop
    @prop()
    goalRatingMappingId: number;
    //#endregion goalRatingMappingId Prop

    //#region goalId Prop
    @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    goalId: number;
    //#endregion goalId Prop

    //#region userRatingId Prop
    @prop()
    userRatingId: number;
    //#endregion userRatingId Prop

    //#region lineMangerRatingId Prop
    @prop()
    lineMangerRatingId: number;
    //#endregion lineMangerRatingId Prop

    //#region feedBack Prop
    @maxLength({ value: 4000 })
    feedBack: string;
    //#endregion feedBack Prop

    //#region createdBy Prop
    @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdDate Prop
    @required()
    createdDate: Date;
    //#endregion createdDate Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region modifiedDate Prop
    @prop()
    modifiedDate: Date;
    //#endregion modifiedDate Prop

    //#region isRatingMailSent Prop
    @prop({ defaultValue: false })
    isRatingMailSent: boolean;
    //#endregion isRatingMailSent Prop

    //#region isLMRatingMailSent Prop
    @prop({ defaultValue: false })
    isLMRatingMailSent: boolean;
    //#endregion isLMRatingMailSent Prop
    

}