import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemFieldsBookingStatusLookUpBase {

//#region gemFieldsBookingStatusName Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'gemFieldsBookingStatusName', keyColumn: false})
        gemFieldsBookingStatusName : string;
//#endregion gemFieldsBookingStatusName Prop


//#region gemFieldsBookingStatusId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'gemFieldsBookingStatusId', keyColumn: true})
        gemFieldsBookingStatusId : number;
//#endregion gemFieldsBookingStatusId Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}