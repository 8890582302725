import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductVarintBatchPieceDeliveryStatuBase {

//#region pieceDeliveryStatusId Prop
        @prop()
        pieceDeliveryStatusId : number;
//#endregion pieceDeliveryStatusId Prop


//#region pieceDeliverySatus Prop
        @required()
        @maxLength({value:200})
        pieceDeliverySatus : string;
//#endregion pieceDeliverySatus Prop

}