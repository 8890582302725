<div  [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="tab-content">
    <div class="dataTables_filter  d-flex flex-wrap align-items-center">

      <label class="ml-auto mr-4 mb-3 mt-2">
        <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
          placeholder="" [title]="titleSearch">
      </label>
      <div class="ibox-tools">
        <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
          <i class="fa fa-cog"></i>
        </a>
        <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="travelEventGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID"
          [selectedTab]="selectedTab">
        </app-system-table-setting>
      </div>
    </div>
    <div class="table-responsive">
      <div class="dataTables_wrapper pb-0 dt-bootstrap4" *ngIf="isShowGrid">
        <rx-grid [design]="travelEventGrid"></rx-grid>
      </div>
    </div>
    <div class="ibox-title bg-white px-0" *ngIf="isNoRecordFound">
      <h4>No Record Found</h4>
    </div>
  </div>
</div>