import { prop, email } from '@rxweb/reactive-form-validators';

export class  ApplicationExceptionLogsFilterViewModel{
    @prop()
    createdFrom : Date;
    @prop()
    createdTo : Date;
    @prop()
    message : string;
    @prop({defaultValue:''})
    @email()
    email : string;
    @prop()
    errorTypeId : number;
    @prop({})
    applicationExceptionLogId:number;
}