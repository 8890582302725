//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentProjectSearchViewModel {

  projectId: string;

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean
  
  //#region projectName
  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.projectName;
          }
        },
      }
    }, parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ProjectName_gh', keyColumn: false
  })
  projectName: string;
  //#endregion projectName

  //#region salesAdvisor
  @gridColumn({ parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ProjectOwner_gh', keyColumn: false })
  projectOwnerName: string;
  //#endregion salesAdvisor

  //#region projectStartDate
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ProjectStartDate_gh', keyColumn: false})
  projectStartDate: string;
  //#endregion projectStartDate

  //#region projectEndDate
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_ProjectEndDate_gh', keyColumn: false })
  projectEndDate: string;
  //#endregion projectEndDate

  totalCount: number;
  UserProfilePicture:string;
  isRestrictedAllowed: boolean;
}
