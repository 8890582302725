import {LocationGroupWarehouseMappingBase,LocationGroupBase,} from '@app/database-models'
//Generated Imports
export class LocationGroupWarehouseMapping extends LocationGroupWarehouseMappingBase 
{




//#region Generated Reference Properties
//#region locationGroup Prop
locationGroup : LocationGroupBase;
//#endregion locationGroup Prop

//#endregion Generated Reference Properties












}