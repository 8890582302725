import { actionColumn, gridColumn } from "@rxweb/grid"

@actionColumn({
    allowSorting: false,
    style: { width: "1%", "text-align": "left" },
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})


export class vBookingWatchPartsBase {

    @gridColumn({ style: { width: "0.01%", "text-align": "initial" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region watchPartId Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 1, allowSorting: true, headerKey: 'BookingWatchPartListComponent_WatchPartId_gh', keyColumn: false, configuredTemplate: { templateName: "reDirect" } })
    watchPartId: number;
    //#endregion watchPartId Prop 

    //#region itemType Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 2, allowSorting: true, headerKey: 'BookingWatchPartListComponent_ItemType_gh', keyColumn: false })
    itemType: string;
    //#endregion itemType Prop

    //#region material Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 3, allowSorting: true, headerKey: 'BookingWatchPartListComponent_Material_gh', keyColumn: false })
    material: string;
    //#endregion material Prop

    //#region location Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 4, allowSorting: true, headerKey: 'BookingWatchPartListComponent_Location_gh', keyColumn: false })
    location: string;
    //#endregion location Prop

    //#region legalOwnerName Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 5, allowSorting: true, headerKey: 'BookingWatchPartListComponent_LegalOwner_gh', keyColumn: false })
    legalOwnerName: string;
    //#endregion legalOwnerName Prop

    //#region status Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 6, allowSorting: true, headerKey: 'BookingWatchPartListComponent_WatchPartStatus_gh', keyColumn: false })
    watchPartStatus: string;
    //#endregion status Prop

    //#region status Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 7, allowSorting: true, headerKey: 'BookingWatchPartListComponent_Status_gh', keyColumn: false })
    status: string;
    //#endregion status Prop

    //#region quantity Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 8, allowSorting: true, headerKey: 'BookingWatchPartListComponent_Quantity_gh', keyColumn: false })
    quantity: number;
    //#endregion quantity Prop

    //#region startDate Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 9, allowSorting: true, headerKey: 'BookingWatchPartListComponent_StartDate_gh', keyColumn: false })
    startDate: string;
    //#endregion startDate Prop

    //#region endDate Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 10, allowSorting: true, headerKey: 'BookingWatchPartListComponent_EndDate_gh', keyColumn: false })
    endDate: string;
    //#endregion endDate Prop

    //#region bookingWatchPartId Prop
    bookingWatchPartId: number;
    //#endregion bookingWatchPartId Prop

}