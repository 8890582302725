import { maxLength, prop, required, numeric, range, notEmpty, trim } from '@rxweb/reactive-form-validators';

export class MainMenuItemBase{
   
        @prop({defaultValue:0})
        mainMenuItemId:number;
       
        @required()
        @notEmpty()
        @trim()
        @maxLength({value:30})
        topMenuName:string;

        @required()
        @notEmpty()
        @trim()
        topMenuUrl:string;
       
        @prop({defaultValue:true})
        active:boolean;

        @prop({defaultValue:1})
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric({allowDecimal:false})
        displayOrder : number;

        @prop({defaultValue:0})
        subMenuCount: number;

        @prop({defaultValue:false})
        hasFeaturedSection: boolean;
       
}