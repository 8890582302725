import {GemStoneGemfieldBase,GemstoneArrangementsGemFieldBase,EnhancementGemFieldBase,GemfieldsArrangementBase,GemfieldsEnhancementBase,GemStoneCutTypeGemfieldBase,GemStoneSaturationGemFieldBase,LocationGemFieldBase,GemStoneClarityGemfieldBase,CategoryBase,GemStoneColorGemfieldBase,GemStoneGFEnhancementTypeMappingBase,GemStoneNotesGemFieldBase,GemStonePicturesGemFieldBase,GemStoneCertificateGemFieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneGemfield extends GemStoneGemfieldBase 
{




//#region Generated Reference Properties
//#region gemstoneArrangementsGemField Prop
gemstoneArrangementsGemField : GemstoneArrangementsGemFieldBase;
//#endregion gemstoneArrangementsGemField Prop
//#region enhancementGemField Prop
enhancementGemField : EnhancementGemFieldBase;
//#endregion enhancementGemField Prop
//#region gemfieldsArrangement Prop
gemfieldsArrangement : GemfieldsArrangementBase;
//#endregion gemfieldsArrangement Prop
//#region gemfieldsEnhancement Prop
gemfieldsEnhancement : GemfieldsEnhancementBase;
//#endregion gemfieldsEnhancement Prop
//#region gemStoneCutTypeGemfield Prop
gemStoneCutTypeGemfield : GemStoneCutTypeGemfieldBase;
//#endregion gemStoneCutTypeGemfield Prop
//#region gemStoneSaturationGemField Prop
gemStoneSaturationGemField : GemStoneSaturationGemFieldBase;
//#endregion gemStoneSaturationGemField Prop
//#region locationGemField Prop
locationGemField : LocationGemFieldBase;
//#endregion locationGemField Prop
//#region gemStoneClarityGemfield Prop
gemStoneClarityGemfield : GemStoneClarityGemfieldBase;
//#endregion gemStoneClarityGemfield Prop
//#region category Prop
category : CategoryBase;
//#endregion category Prop
//#region gemStoneColorGemfield Prop
gemStoneColorGemfield : GemStoneColorGemfieldBase;
//#endregion gemStoneColorGemfield Prop

//#region gemStoneGFEnhancementTypeMappings Prop
gemStoneGFEnhancementTypeMappings : GemStoneGFEnhancementTypeMappingBase[];
//#endregion gemStoneGFEnhancementTypeMappings Prop
//#region gemStoneNotesGemFields Prop
gemStoneNotesGemFields : GemStoneNotesGemFieldBase[];
//#endregion gemStoneNotesGemFields Prop
//#region gemStonePicturesGemFields Prop
gemStonePicturesGemFields : GemStonePicturesGemFieldBase[];
//#endregion gemStonePicturesGemFields Prop
//#region gemStoneCertificateGemFields Prop
gemStoneCertificateGemFields : GemStoneCertificateGemFieldBase[];
//#endregion gemStoneCertificateGemFields Prop

//#endregion Generated Reference Properties












}