<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" *rxMultilingual="">
  <div class="col-md-8 my-1" [rxLocalisationInit]="componentName">
    <h2 class="m-0 page-title mb-2" rxText="Label_Corporates_Groups_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <!-- <li class="breadcrumb-item active">People/Companies</li> -->
      <li class="breadcrumb-item active" rxText="Label_Corporates_Groups_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>
<div class="wrapper wrapper-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column" (keyup.enter)="onKeyPress()">
    <div class="ibox-title bg-white pr-3">
      <h5 rxText="Label_Corporates_Groups_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1"
      [formGroup]="supplierSearchFilterViewModelFormGroup">
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <a routerLink="/suppliers/add" class="btn btn-success btn-sm mb-4" *rxAuthorize="[supplierAdd]"
          rxText="Btn_Add_New_t"></a>
        <div class=" ml-auto mb-4" role="group" aria-label="Basic example">
          <ng-container *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
            <button type="button" class="btn btn-primary" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t">
            </button>
          </ng-container>
        </div>
      </div>
      <div class="row people_form_details">
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="SupplierListComponent_SupplierName_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="" formControlName="name"
                rxPlaceholder="SupplierListComponent_SupplierName_p" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="SupplierListComponent_SupplierEmail_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="" formControlName="email"
                rxPlaceholder="SupplierListComponent_SupplierEmail_p" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="SupplierListComponent_SupplierIndustryType_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <!-- <select class="form-control" formControlName="industryTypeId">
                <option [selected]="true" value="0">Select Industry Type</option>
                <option *ngFor="let item of supplierLookups.industryTypeLookUp" [value]="item.industryTypeId">
                  {{item.industryTypeName}}
                </option>
              </select> -->
              <rx-tag [(source)]="supplierLookups.industryTypeLookUp" #rxTag [controlTabIndex]="3"
                [isSort]="false" formControlName="industryTypeIds" mainClass="form-control" placeholder="Select Industry Type"
                [keyValueProps]="['industryTypeName','industryTypeId']">
              </rx-tag>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="SupplierListComponent_SupplierCountry_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="countryId">
                <option [selected]="true" value="0">Select Country</option>
                <option *ngFor="let item of supplierLookups.countryLookUp" [value]="item.countryId">
                  {{item.countryName}}
                </option>
              </select> -->
              <rx-select [selectPlaceholder]="'Select Country'" [(source)]="supplierLookups.countryLookUp" #rxSelect
                mainClass="form-control" formControlName="countryId" [keyValueProps]="['countryName','countryId']">
              </rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="SupplierListComponent_HasPurchased_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [selectPlaceholder]="'Select Has Purchased From'" #rxSelect
                [(source)]="supplierLookups.fabergePurchasedLookUp" mainClass="form-control"
                formControlName="hasPurchasedFrom" [keyValueProps]="['purchasedFormName','purchasedFormId']">
              </rx-select>
            </div>
          </div>


        </div>
        <div class="col-xl-4 pl-xl-5">
          <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
          <div class="form-group row">
            <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="SupplierListComponent_SupplierId_t"></label>
            <div class="col-md-8 ">
              <div class="d-flex">
                <input type="text" class="form-control mr-3" tabindex="1" formControlName="supplierId"
                       rxPlaceholder="SupplierListComponent_SupplierId_p" (keyup.enter)="goTo()"/>
                <button (click)="goTo()" class="btn btn-success search-result-btn" [rxLocalisationInit]="componentName"><span rxText="Btn_Go_t"></span></button>
              </div>
              <small class="form-text text-danger" *ngIf="msgError==true" [rxLocalisationInit]="componentName"><span rxText="SupplierListComponent_Go_Error_t"></span><br /></small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="SupplierListComponent_CorporateRoles_t"></label>
            <div class="col-md-8" [rxSelectExtended]>
              <!-- <select class="form-control" formControlName="supplierRoleId" >
        <option [selected]="true" value="0">Select Role</option>
        <option *ngFor="let item of supplierLookups.supplierRoleLookUp" [value]="item.roleId">
          {{item.roleName}}
        </option>
      </select> -->
              <rx-select [selectPlaceholder]="'Select Corporate Role'" [(source)]="supplierLookups.supplierRoleLookUp"
                         #rxSelect mainClass="form-control" formControlName="supplierRoleId"
                         [keyValueProps]="['roleName','roleId']">
              </rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="SupplierListComponent_SupplierPeopleRoles_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="roleId" >
        <option [selected]="true" value="0">Select People Roles</option>
        <option *ngFor="let item of supplierLookups.customerRoleLookUps" [value]="item.roleId">
          {{item.roleName}}
        </option>
      </select> -->
              <rx-select [selectPlaceholder]="'Select People Roles'" [(source)]="supplierLookups.customerRoleLookUps"
                         #rxSelect mainClass="form-control" formControlName="roleId"
                         [keyValueProps]="['roleName','roleId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="SupplierListComponent_SupplierSalesChannel_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="salesChannelId" >
        <option [selected]="true" value="0">Select Sales Channel</option>
        <option *ngFor="let item of supplierLookups.salesChannel" [value]="item.salesChannelId">
          {{item.salesChannelName}}
        </option>
      </select> -->
              <rx-select [selectPlaceholder]="'Select Sales Channel'" [(source)]="supplierLookups.salesChannel"
                         #rxSelect mainClass="form-control" formControlName="salesChannelId"
                         [keyValueProps]="['salesChannelName','salesChannelId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label mt-2" rxText="SupplierListComponent_KYC_gh"></label>
            <div class="col-md-8 mt-2" [rxSelectExtended]>
              <!-- <select class="form-control" formControlName="currentFabergeStockist" >
        <option value="2" [selected]="true">Select</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
      </select> -->
              <rx-select [(source)]="supplierLookups.kycLookups" mainClass="form-control" formControlName="ddKYCStatus"
                         #rxSelect [keyValueProps]="['value','id']" [selectPlaceholder]="'Select DD/KYC Status'"></rx-select>
            </div>
          </div>
          <div class="d-flex">


            <div class="form-group w-100 row checkbox">
              <label class="col-md-4 col-lg-3 col-xl-8 col-form-label"
                     rxText="SupplierListComponent_IsApproval_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox [checkFormControl]="supplierSearchFilterViewModelFormGroup.controls.isApproval"
                                  [tabIndex]="'6'">
                    </app-checkbox>
                  </label>
                </div>
                <!-- <small class="form-text text-danger" [class.d-block]="countryFormGroup.controls.allowsRegistration.errors">{{countryFormGroup.controls.allowsRegistration.errorMessage}}<br /></small> -->
              </div>
            </div>

            <div class="form-group w-100 row checkbox">
              <label class="col-md-4 col-lg-3 col-xl-8 col-form-label"
                     rxText="SupplierListComponent_SensitiveContact_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox [checkFormControl]="supplierSearchFilterViewModelFormGroup.controls.isSensitive"
                                  [tabIndex]="'6'">
                    </app-checkbox>
                  </label>
                </div>
                <!-- <small class="form-text text-danger" [class.d-block]="countryFormGroup.controls.allowsRegistration.errors">{{countryFormGroup.controls.allowsRegistration.errorMessage}}<br /></small> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 pl-xl-5">

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="SupplierListComponent_SupplierIntroducedBy_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="introducesBy">
                <option [selected]="true" value="0">Select Key Relationship Holder</option>
                <option *ngFor="let item of supplierLookups.keyRelationshipHolderLookUp" [value]="item.userId">
                  {{item.username}}
                </option>
              </select> -->
              <rx-select [selectPlaceholder]="'Select Key Relationship Holder'"
                [(source)]="supplierLookups.keyRelationshipHolderLookUp" #rxSelect mainClass="form-control"
                formControlName="introducesBy" [keyValueProps]="['fullName','userId']"></rx-select>
            </div>
          </div>

          <div class="form-group row" *ngIf="isDisplayFStockist" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label mt-2"
              rxText="SupplierListComponent_SupplerCurntFabergeStckist_t"></label>
            <div class="col-md-8 mt-2" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="currentFabergeStockist">
                <option value="2" [selected]="true">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select> -->
              <rx-select [(source)]="supplierLookups.fabergeStockistLookup" #rxSelect mainClass="form-control"
                formControlName="currentFabergeStockist" [keyValueProps]="['value','id']"
                [selectPlaceholder]="'Select Current Faberge Stockist'"></rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label mt-2"
              rxText="SupplierListComponent_WorkPhoneNumber_t"></label>
            <div class="col-md-8 mt-2" [rxSelectExtended]="_this">
              <input type="text" class="form-control" placeholder="" formControlName="workPhoneNumber"
                rxPlaceholder="SupplierListComponent_WorkPhoneNumber_p" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="hr-line-dashed border-top w-100"></div>
      </div>
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <div class="col text-right px-0">
          <button (click)="onKeyPress()" class="btn btn-success mr-1 search-result-btn" rxText="Btn_Search_t"></button>
          <button (click)="resetSearch()" class="btn btn-primary mr-1" rxText="Btn_Reset_t"></button>

          <!-- <button type="button" class="btn btn-primary">?</button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="search-result-container">
    <div class="ibox mb-0 d-flex flex-column mt-4" *ngIf="isSearch" [rxLocalisationInit]="componentName">
      <div class="ibox-title bg-white d-flex flex-wrap pr-5">
        <h5>Search Result</h5>
        <label class="ml-auto mr-0 mb-3">
          <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
            placeholder="" [title]="titleSearch">
        </label>
        <!-- <div class="ibox-tools">
          <label class="ml-auto mr-0 mb-3">
            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
              placeholder="">
          </label>
        </div> -->
        <div class="ibox-tools mt-1">
          <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
            <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
            (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()" [selectedTab]="selectedlListTab"
            [gridColumnConfig]="suppliersGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID">
          </app-system-table-setting>
        </div>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" id="supplierTable">
        <div class="table-responsive" *ngIf="isShowGrid" id="supppliersGrid">
          <!-- <div id="supplierGrid" class="dataTables_wrapper pb-0 dt-bootstrap4"> -->
          <rx-grid [design]="suppliersGrid"></rx-grid>
          <!-- </div> -->
        </div>
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
          <h4>No Record Found</h4>
        </div>
      </div>
    </div>
  </div>
</div>
