import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vNewsLetterTypeLookUpBase {

//#region newsLetterTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'newsLetterTypeId', keyColumn: true})
        newsLetterTypeId : number;
//#endregion newsLetterTypeId Prop


//#region newsLetterTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'newsLetterTypeName', keyColumn: false})
        newsLetterTypeName : string;
//#endregion newsLetterTypeName Prop

}