import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { List } from "@rxweb/generics";
import { AbstractStateProvince } from "../domain/abstract-state-province";
import {
  StateProvince,
  vStateProvince,
  vCountryLookUp,
  vCountry,
} from "@app/models";
import { Subscription } from "rxjs";
import { AppGrid } from "../../../../domain/app-grid";
import { Router } from "@angular/router";
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "../../../../domain/customize-design/dialog";
import { ModalView } from "../../../../domain/customize-design/modal";
import { BrowserModule, Title } from "@angular/platform-browser";
import { USER_LOOKUPS } from "src/app/const/uris/user-lookups.uri";

import { multilingual } from "@rxweb/localization";
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";
import { access } from "@rxweb/angular-router";
import { StateProvinceAddComponent } from "../add/state-province-add.component";
import {
  prop,
  RxFormBuilder,
  IFormGroup,
} from "@rxweb/reactive-form-validators";
import { ApplicationBroadcaster } from "src/app/temp-service/application-broadcaster";
import * as CryptoJS from "crypto-js";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import {
  getLocalizedValue,
  getSystemHelpStatus,
  redirectOnCTRL,
} from "src/app/domain/common-logic/common-logic";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("StateProvinceListComponent")
@access({ accessLevel: RolePermissionEnums.StateProvince, action: "get" })
@Component({
  selector: "app-state-province-list",
  templateUrl: "./state-province-list.component.html",
})
export class StateProvinceListComponent
  extends AbstractStateProvince
  implements OnInit, OnDestroy
{
  stateProvinces: vStateProvince[];
  subscription: any;
  countryLookup: vCountryLookUp;
  stateProvinceAdd: any = StateProvinceAddComponent;
  stateProvinceModel: StateProvinceSearchModel;
  stateProvinceSearchFormGroup: IFormGroup<StateProvinceSearchModel>;
  stateProvinceGrid: AppGrid;
  stateProvinceGridColumns: any[];
  isNoRecordFound: boolean;
  isShowGrid: boolean;
  moduleID: number;
  titleHelp: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(
    private router: Router,
    modelView: ModalView,
    private applicationBroadcaster: ApplicationBroadcaster,
    private formBuilder: RxFormBuilder,
    private title: Title,
    private location : PlatformLocation
  ) {
    super();
    this.modalView = modelView;
    this.applicationBroadcaster.activeMenu({
      activeMenu: "system",
      subMenu: "system",
    });
    this.moduleID = RolePermissionEnums.StateProvince;
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(
      this.moduleID,
      SystemHelpPage.Search
    );
    this.title.setTitle("States/Provinces - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.showComponent = false;
    this.lookup([
      { path: USER_LOOKUPS["countryLookUp"], propName: "countryLookup" },
    ]).subscribe((lookup: any) => {
      this.stateProvinceLookup.countryLookUp = new Array<vCountryLookUp>();
      this.stateProvinceLookup.countryLookUp = lookup.countryLookup;
      // let country=new vCountryLookUp();
      // country.countryId=0;
      // country.countryName="Select Country";
      this.stateProvinceModel = new StateProvinceSearchModel();
      // this.stateProvinceLookup.countryLookUp.unshift(country);
      this.stateProvinceModel.countryName = "Select Country";
      this.stateProvinceSearchFormGroup = this.formBuilder.formGroup(
        StateProvinceSearchModel,
        this.stateProvinceModel
      ) as IFormGroup<StateProvinceSearchModel>;
      this.showComponent = true;
    });
    /// this.bindGrid();
  }
  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, {
      moduleId: this.moduleID,
      systemHelpPageId: SystemHelpPage.Search,
    });
  }
  clearGrid() {
    let child = document.getElementById("state-provinces").lastElementChild;
    while (child) {
      document.getElementById("state-provinces").removeChild(child);
      child = document.getElementById("state-provinces").lastElementChild;
    }
  }
  // clearGrid() {
  //     if (this.stateProvinceGrid && this.isShowGrid) {
  //         this.stateProvinceGrid.refresh("state-provinces")
  //       }
  //   }
  bindGrid() {
    this.spin = true;
    this.subscription = this.get({
      queryParams: {
        countryName: this.stateProvinceSearchFormGroup.value.countryName,
      },
    }).subscribe((t: any) => {
      this.spin = false;
      this.stateProvinces = t;
      this.isShowGrid = this.stateProvinces.length > 0 ? true : false;
      this.isNoRecordFound = this.stateProvinces.length > 0 ? false : true;
      //this.clearGrid();
      if (this.isShowGrid) {
        this.stateProvinceGrid = new AppGrid(t, vStateProvince, {
          actions: {
            onDelete: this.onDelete.bind(this),
            onEdit: this.onEdit.bind(this),
          },
        });
        this.stateProvinceGrid.gridColumns.forEach((x) => {
          if (
            this.stateProvinceGrid.authorize({
              accessLevel: RolePermissionEnums.StateProvince,
              action: "put",
            }) &&
            this.stateProvinceGrid.authorize({
              accessLevel: RolePermissionEnums.StateProvince,
              action: "delete",
            })
          ) {
            if (x.name == "action") {
              x.visible = true;
            }
          } else {
            if (x.name == "action") {
              x.visible = false;
            }
            if (x.name == "editAction") {
              x.visible = this.stateProvinceGrid.authorize({
                accessLevel: RolePermissionEnums.StateProvince,
                action: "put",
              });
            }
            if (x.name == "deleteAction") {
              x.visible = this.stateProvinceGrid.authorize({
                accessLevel: RolePermissionEnums.StateProvince,
                action: "delete",
              });
            }
          }
        });
        this.stateProvinceGrid.componentId = this.componentName;
        this.stateProvinceGrid.designClass.tableClass =
          "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(
            " "
          );
        this.stateProvinceGrid.designClass.headerClass =
          "table-header-sticky".split(" ");
        setTimeout(() => {
          this.stateProvinceGrid.refresh("state-provinces");
          this.stateProvinceGrid.design(
            document.getElementById("state-provinces")
          );
        }, 300);
        this.stateProvinceGridColumns =
          this.stateProvinceGrid.gridColumns.filter(
            (x) => x.keyColumn == false
          );
      }
      this.showComponent = true;
    });
  }
  onEdit(stateProvinces: vStateProvince) {
    let url: any;
    url = this.router.serializeUrl(
      this.router.createUrlTree([
        "state-provinces",
        stateProvinces.stateProvinceId,
      ])
    );
    redirectOnCTRL(url, this.router, event);
    //this.router.navigate(["state-provinces", stateProvinces.stateProvinceId]);
  }

  onDelete(stateProvinces: vStateProvince) {
    this.dialog
      .show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel)
      .then((t) => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({
            body: null,
            params: [stateProvinces.stateProvinceId],
          }).subscribe((data) => {
            this.spin = false;
            this.stateProvinceGrid.remove(stateProvinces.stateProvinceId);
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              //this.toastr.success("Data Deleted Successfully")
              this.toastr.success(existsAlert);
            }
            this.router.navigate(["/state-provinces"]);
          });
        }
        this.dialog.hide();
      });
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_StateorProvince");
    if (existsAlert) {
      return existsAlert;
    }
  }
  search(value) {
    if (
      value.target.value.trim() != "" &&
      value.target.value != undefined &&
      value.target.value != null
    ) {
      let ele = this.stateProvinces.filter(
        (x) =>
          x.stateName
            .toLowerCase()
            .includes(value.target.value.trim().toLowerCase()) ||
          x.stateProvinceId
            .toString()
            .toLowerCase()
            .includes(value.target.value.trim().toLowerCase()) ||
          x.abbreviation
            .toLowerCase()
            .includes(value.target.value.trim().toLowerCase()) ||
          x.displayOrder
            .toString()
            .toLowerCase()
            .includes(value.target.value.trim().toLowerCase())
      );
      if (ele.length == 0) {
        this.isNoRecordFound = true;
      } else {
        this.isNoRecordFound = false;
        this.stateProvinceGrid = null;
      }
    } else {
      this.isNoRecordFound = false;
      this.stateProvinceGrid = null;
    }
    setTimeout(() => {
      if (
        this.stateProvinceGrid == null ||
        this.stateProvinceGrid == undefined
      ) {
        this.stateProvinceGrid = new AppGrid(
          this.stateProvinces,
          vStateProvince,
          {
            actions: {
              onDelete: this.onDelete.bind(this),
              onEdit: this.onEdit.bind(this),
            },
          }
        );
        this.stateProvinceGrid.gridColumns.forEach((x) => {
          if (
            this.stateProvinceGrid.authorize({
              accessLevel: RolePermissionEnums.StateProvince,
              action: "put",
            }) &&
            this.stateProvinceGrid.authorize({
              accessLevel: RolePermissionEnums.StateProvince,
              action: "delete",
            })
          ) {
            if (x.name == "action") {
              x.visible = true;
            }
          } else {
            if (x.name == "action") {
              x.visible = false;
            }
            if (x.name == "editAction") {
              x.visible = this.stateProvinceGrid.authorize({
                accessLevel: RolePermissionEnums.StateProvince,
                action: "put",
              });
            }
            if (x.name == "deleteAction") {
              x.visible = this.stateProvinceGrid.authorize({
                accessLevel: RolePermissionEnums.StateProvince,
                action: "delete",
              });
            }
          }
        });
        this.stateProvinceGrid.componentId = this.componentName;
        this.stateProvinceGrid.designClass.tableClass =
          "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(
            " "
          );
        this.stateProvinceGrid.designClass.headerClass =
          "table-header-sticky".split(" ");
        this.stateProvinceGrid.refresh("state-provinces");
        this.stateProvinceGrid.design(
          document.getElementById("state-provinces")
        );
        this.stateProvinceGridColumns =
          this.stateProvinceGrid.gridColumns.filter(
            (x) => x.keyColumn == false
          );
      }
      this.stateProvinceGrid.search = value.target.value;
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "StateProvinceListComponent";
  }
}
class StateProvinceSearchModel {
  @prop()
  countryName: string;
}
