export const USER_LOOKUPS: { [key: string]: string } = {
    persons: 'api/userlookups/persons',
    activityLogTypes: 'api/userlookups/activitylogtypes',
    salesChannelTypeLookUp: 'api/masterlookups/saleschanneltypelookups',
    billingCountryLookUp: 'api/masterlookups/billingcountrylookups',
    sizeTypeLookUp: 'api/masterlookups/sizetypelookups',
    countryLookUp: 'api/masterlookups/countrylookups',
    customerRoleDomainLookUp: 'api/masterlookups/customerroledomainlookups',
    customerRoleTypeLookUp: 'api/masterlookups/customerroletypelookups',
    adminRoleTypeLookUp: 'api/userlookups/AdminRoleTypeLookUps',
    genderLookUp: 'api/masterlookups/genderlookups',
    genderRelationshipLookUp: 'api/masterlookups/GenderLookUpRelationship',
    boutiqueManagerLookUp: 'api/masterlookups/boutiquemanagerlookups',
    boutiqueSalesTeamLookUp: 'api/masterlookups/boutiqueSalesteamlookups',
    boutiqueSupervisorLookUp: 'api/masterlookups/boutiquesupervisorlookups',
    categoryTypeLookUp: 'api/masterlookups/categorytypelookups',
    parentProductCategoryLookUp: 'api/masterlookups/parentproductcategorylookups',
    productCategoryLookUp: 'api/masterlookups/ProductCategoryLookUps',
    cultureInfoLookUp: 'api/masterlookups/cultureinfolookups',
    adminUserLookUp: 'api/masterlookups/adminuserlookups',
    reportToUserInternalDirectoryLookup : 'api/masterlookups/vReportToUserInternalDirectory',
    parentCollectionLookUp: 'api/masterlookups/parentcollectionLookups',
    parentAdhocLookUp: 'api/masterlookups/parentadhocLookups',
    filtersLookUp: 'api/masterlookups/filtersLookUps',
    parentHelpSupportLookUp: 'api/masterlookups/parenthelpsupportlookups',
    peopleTypeLookUp: 'api/masterlookups/peopletypelookups',
    emailAccountLookUp: 'api/masterlookups/emailaccountlookups',
    languageLookUp: 'api/masterlookups/languagelookups',
    customerRelationshipLookUp: 'api/masterlookups/customerrelationshiplookups',
    roleLookUp: 'api/masterlookups/rolelookups',
    nationalitylookUp: 'api/userlookups/nationalitylookups',
    shoeSizelookUp:'api/userlookups/shoesizelookups',
    menShoeSizelookUp:'api/userlookups/menshoesizelookups',
    womenShoeSizelookUp:'api/userlookups/womenshoesizelookups',
    customerRegistrationTypeLookUp: 'api/userlookups/customerregistrationtypelookups',
    salesAdvisorLookUp: 'api/userlookups/salesadvisorlookups',
    salesUserLookup: 'api/userlookups/salesuserlookups',
    saleOrderAdminUserLookUp: 'api/UserLookups/SaleOrderAdminUserLookUps',
    titleLookUp: 'api/userlookups/titlelookups',
    taxCategoryLookUp: 'api/userlookups/taxcategorylookups',
    stateProvinceLookUp: 'api/masterlookups/stateprovincelookups',
    regionsLookup: 'api/masterlookups/RegionsLookUps',
    // userCountryLookUp: 'api/userlookups/countrylookups',
    // userRoleTypeLookUp: 'api/userlookups/customerroletypelookups',
    // userRoleLookUp: 'api/userlookups/rolelookups',
    leaveStatusLookUps: 'api/userlookups/LeaveStatusLookUps',
    orderStatusLookUps: 'api/userlookups/OrderStatusLookUps',
    paymentStatusLookUps: 'api/userlookups/PaymentStatusLookUps',
    shippingStatusLookUps: 'api/userlookups/ShippingStatusLookUps',
    internalDepartment: 'api/userlookups/InternalDepartmentLookUps',
    externalDepartment: 'api/userlookups/ExternalDepartmentLookUps',



    stateProvinceLookup: 'api/masterlookups/stateprovincelookups',

    activitylogtypelookUp: 'api/masterlookups/activitylogtypelookUps',
    errorTypeLookUps: 'api/masterlookups/errorTypeLookUps',
    hSEUpdatesLookup: 'api/masterlookups/hseupdateslookups',
    hSEUpdateStatusLookup: 'api/masterlookups/hseupdatestatuslookups',
    designTypeLookUp: 'api/masterlookups/designtypelookups',

    holidayTypeLookup: 'api/masterlookups/HolidayTypeLookUps',
    weekDaysLookUp: 'api/masterlookups/WeekDayLookUps',
    salesChannel: 'api/userlookups/SalesChannels',
    customerRoleLookUp: 'api/userlookups/CustomerRoleLookUps',
    systemRatingLookUp: 'api/userlookups/SystemRatingLookUps',
    keyRelationshipHolderLookUp: 'api/userlookups/KeyRelationshipHolderLookUps',
    industryTypeLookUp: 'api/userlookups/IndustryTypeLookUps',
    categoryLookUp: 'api/userlookups/CategoryLookUps',
    warehouseLookUp: 'api/masterlookups/WarehouseLookUps',
    showPeopleJoiningOrLeavingGrid: 'api/userlookups/ShowPeopleJoiningOrLeavingGrid',

    customerContactMethodTypeLookUp: 'api/userlookups/CustomerContactMethodTypeLookUps',
    departmentLookUp: 'api/userlookups/DepartmentLookUps',
    adminDepartmentLookUp: 'api/userlookups/AdminDepartmentLookUps',
    customerDepartmentLookUp: 'api/userlookups/CustomerDepartmentLookUps',
    musicPreferenceLookUp: 'api/userlookups/MusicPreferenceLookUps',
    languageUserLookUps: 'api/userlookups/LanguageLookUps',
    reportingHeadLookUp: 'api/userlookups/reportingheadlookups',
    companyLookUp: 'api/userlookups/CompanyLookups',
    activeCompanyLookUp: 'api/userlookups/ActiveCompanyLookUp',
    spokenLanguageLookUp: 'api/userlookups/SpokenLanguageLookUps',
    eventOwnerLookUp: 'api/userlookups/EventOwnerLookUps',
    eventTypeLookUp: 'api/userlookups/EventTypeLookUps',
    eventServiceTypeLookUp: 'api/userlookups/EventServiceTypeLookUps',
    getPatersongradeLookups: 'api/SearchEvent/GetPatersongradeLookups', //#292627//08-07-2024//added lookup API path
    getPayrolltypeLookups: 'api/SearchEvent/GetPayrolltypeLookups', //#292627//08-07-2024//added lookup API path
    projectOwnerLookUp: 'api/userlookups/ProjectOwnerLookUps',
    userLookUp: 'api/userlookups/UserLookUps',
    userLookUpsToShowExEmpForBookings: 'api/userlookups/UserLookUpsToShowExEmpForBookings',
    projectTypeLookUp: 'api/projectLookups/ProjectTypeLookUps',
    userTeamMembersLookUps: 'api/masterlookups/getUserTeamMembersLookUps',
    usersForTeamMemberReportLookUps: 'api/masterlookups/getUsersForTeamMemberReportLookUps',
    currencyLookUp: 'api/masterlookups/CurrencyLookUps',
    flightClassLookUp: 'api/masterlookups/FlightClassLookUps',
    accomodationPreferenceLookUp: 'api/masterlookups/AccomodationPreferenceLookUps',
    accomodationPreferenceReasonsLookUp: 'api/masterlookups/AccomodationPreferenceReasonsLookUps',
    vehicleRequestTypesLookup: 'api/masterlookups/VehicleRequestTypesLookups',
    leaveTypesLookUp: 'api/masterlookups/LeaveTypesLookUps',
    relationShipLookUp: 'api/userlookups/RelationShipLookUps',
    discountTypeLookUp: 'api/masterlookups/DiscountTypeLookUps',
    discountRequirementLookUp: 'api/masterlookups/DiscountRequirementLookUps',
    discountLimitationLookUp: 'api/masterlookups/DiscountLimitationLookUps',
    shippingCountryLookUp: 'api/masterlookups/shippingcountrylookups',
    supplierRoleLookUp: 'api/userlookups/SupplierRoleLookUps',
    subscriptionLookUp: 'api/userlookups/SubscriptionLookUps',
    posBrandLookUps: 'api/userlookups/POSBrandLookUps',
    userDetail: 'api/userDetail',
    fabergeGemfieldTeam: 'api/SearchUsers/GetFabergeGemfieldTeam',
    fabergePriceListCreatedByLookUps: 'api/userlookups/FabergePriceListCreatedByLookUps',
    fabergePurchasedLookUp: 'api/userlookups/FabergePurchasedLookUps',
    gemstoneLookup: 'api/userlookups/GemstoneLookups',

    //Booking Module LookUps
    bookingTypeLookUp: 'api/bookinglookups/BookingTypeLookUps',
    productVariantStatusLookUp: 'api/bookinglookups/productVariantStatusLookUp',
    dutyStatusLookUp: 'api/bookinglookups/dutyStatusLookUp',
    legalOwnerLookUp: 'api/bookinglookups/legalOwnerLookUp',
    lookbookCurrencyLookup: 'api/bookinglookups/LookbookCurrencyLookUp',
    inspectedByLookUp: 'api/bookinglookups/InspectedByLookUp',

    GroupEntity: 'api/FormLookups/GroupCompanyLookUp',

    // IAN Form LookUps
    occurrenceType: 'api/FormLookups/IANTypeLookUps',
    genfieldsGroupEntity: 'api/FormLookups/GemfieldsGroupEntityLooUps',
    bodilyLocation: 'api/FormLookups/LookupBodilyLocations',
    vehicleType: 'api/FormLookups/LookupVehicleTypes',
    situationType: 'api/FormLookups/LookupSituationTypes',
    prevailingWeather: 'api/FormLookups/LookupPrevailingWeathers',
    personsAffectedCategory: 'api/FormLookups/LookupPersonsAffectedCategories',
    occurenceArea: 'api/FormLookups/LookupOccurrenceAreas',
    lostTimeInjury: 'api/FormLookups/LookupLostTimeInjuries',
    injuryType: 'api/FormLookups/LookupInjuryTypes',
    injurySeverity: 'api/FormLookups/LookupInjurySeverity',
    incidentType: 'api/FormLookups/LookupIncidentTypes',
    ticketIncidentType: 'api/FormLookups/TicketIncidentTypes',
    notifyTeam: 'api/FormLookups/NotifyTeamLookUps',
    occurrenceTime: 'api/FormLookups/OccurrenceTimeLookUps',
    occurrenceMinute: 'api/FormLookups/OccurrenceMinuteLookUps',
    usersList: 'api/FormLookups/UsersList',
    hSEStatusType: 'api/FormLookups/HSEStatusLookUps',
    hseStatusEdit: 'api/FormLookups/HSEStatusEditLookUps',
    personType: 'api/FormLookups/PersonTypeLookUp',
    externalPeopleType: 'api/FormLookups/ExternalPeopleTypeLookUp',
    tierNameLookup: 'api/FormLookups/GrievanceTierLookUp',
    grievanceType: 'api/FormLookups/GrievanceTypeLookUp',
    grievanceCategory: 'api/FormLookups/GrievanceCategories',
    grievanceAccessPoint: 'api/FormLookups/AccessPoints',
    trackIncidentRawForms: 'api/FormLookups/TrackIncidentRawFormLookUp',
    occurrenceTypes: 'api/FormLookups/OccurrenceTypes',
    reportableDiseases: 'api/FormLookups/ReportableDisease',
    personTypes: 'api/FormLookups/PersonTypes',
    dayLosts: 'api/FormLookups/DayLosts',
    GINASettings: 'api/FormLookups/LookupGINASettings',
    lookupDeviceType: 'api/masterlookups/LookupDeviceType',
    DeskmanufacturerLookups: 'api/masterlookups/DesktopManufacturerLookUps',
    MobmanufacturerLookups: 'api/masterlookups/MobileManufacturerLookUps',
    assetLocationLookups: 'api/masterlookups/AssetLocationLookup',
    assetStatusLookups: 'api/masterlookups/AssetStatusLookUps',
    channelIECLookUps: 'api/masterlookups/ChannelIECLookUps', //#287839//11-06-2024//new LookUp for Channel DropDown added 
    assetCapacityLookups: 'api/masterlookups/AssetCapacityLookUps',
    assetIntuneEnrollLookUps: 'api/masterlookups/AssetIntuneEnrollLookUps',
    assetLeaseLookUps: 'api/masterlookups/AssetLeaseLookUps',
    assetTagLookUp: 'api/FormLookups/GetAssetTagLookUp',
    //End IAN LookUps

    priceBasisLookUps: 'api/masterlookups/PriceBasisLookUps',

    //Report > Note Report
    noteFilterCommentsByLookups: 'api/NoteDbLookups/NotesFilterCommByLookups',
    noteHashtagsLookups: 'api/NoteDbLookups/NotesHashtagsLookups',
    notePostecByLookups: 'api/NoteDbLookups/NotesPostedByLookups',
    getCollectionLookUps: 'api/masterlookups/GetCollectionLookUps',
    designStatusLookup: 'api/masterlookups/DesignStatusLookup',
    categoryLookup: 'api/masterlookups/CategoryLookup',
    designCategoryLookup: 'api/masterlookups/DesignCategoryLookup',
  designMinMaxValueLookUp: 'api/masterlookups/DesignMinMaxValueLookUp', // #295720//06-08-2024//min and max Design price
    productSearchCategoryLookup: 'api/masterlookups/ProductSearchCategoryLookup',
    adminUserLookup: 'api/userlookups/AdminUserLookup',
    productStatusLookUp: 'api/masterlookups/ProductStatusLookUp',
    productQuestions: 'api/masterlookups/ProductQuestions',

    newsTypeLookUp: 'api/masterlookups/NewsTypeLookUp',

    //Produc LookUps
    productDesignLookUp: 'api/InventoryLookup/ProductDesignLookUps',
    productDesignStatusLookUp: 'api/InventoryLookup/ProductDesignStatusLookUps',
    productOccassionLookUp: 'api/InventoryLookup/ProductOccassions',
    productCurrencyLookUp: 'api/InventoryLookup/ProductCurrencyLookUps',
    productHSCodeLookUp: 'api/InventoryLookup/ProductHSCodeLookUps', //#285950/ Naitik Bhatt/21-05-2024 new productlookup path, it is use in Product add/edit HScode dropdown
    productCategoryLookUps: 'api/InventoryLookup/ProductCategoryLookUps',
    displayOrderLookUp: 'api/InventoryLookup/DisplayOrderLookUps',
    productGemstonetypeLookUps: 'api/InventoryLookup/ProductGemstoneTypeLookUps',
    productMaterialLookUps: 'api/InventoryLookup/ProductMaterialLookUps',
    productColorLookUps: 'api/InventoryLookup/ProductColorLookUps',
    productSupplierLookups: 'api/InventoryLookup/ProductSupplierLookups',
    productBatchEggSizeLookUp: 'api/InventoryLookup/EggSizeLookUps',
    chainLengthLookUp: 'api/InventoryLookup/ChainLengthLookUps',
    batchWarehouseLookUp: 'api/InventoryLookup/WarehouseLookUps',
    availabilityStatusLookUp: 'api/InventoryLookup/AvailabilityStatusLookUps',
    productStatusForInProductionReportLookUp: 'api/InventoryLookup/ProductStatusForInProductionReportLookUp',
    warrantyCard: 'api/InventoryLookup/WarrantyCardLookUps',
    salesPerson: 'api/InventoryLookup/SalesPersonLookup',
    primaryCategory: 'api/InventoryLookup/PrimaryCategoryLookUps',
    legalOwnerBatchLookup: 'api/InventoryLookup/vLegalOwnerBatchLookUps',
    //End Product LookUps
    orderProductVarientStatusesLookUp: 'api/userlookups/OrderProductVarientStatusesLookUps',
    localizationComponents: 'api/masterlookups/LocalizationComponents',
    salesPersonLookUps: 'api/userlookups/SalesPersonLookUps',
    //priceBandOptionsLookUps:'api/userlookups/RingSizeLookUps'
    priceBandOptionsLookUps: 'api/userlookups/PriceBandOptionsLookUps',
    legalOwnerLookup: 'api/InventoryLookup/LegalOwnerLookUp',

    //Faberge Gemstone
    gemStoneCutTypeLookUps: 'api/InventoryLookup/GemStoneCutTypeLookUps',
    gemstoneStatusLookUps: 'api/InventoryLookup/GemstoneStatusLookUps',
    gemStoneColorLookUps: 'api/InventoryLookup/GemStoneColorLookUps',
    gemstoneOriginLookUps: 'api/InventoryLookup/GemstoneOriginLookUps',
    supplierLookUps: 'api/InventoryLookup/SupplierLookUps',
    gemStoneClarityLookUps: 'api/InventoryLookup/GemStoneClarityLookUps',
    gemstoneSaturationLookUps: 'api/InventoryLookup/GemstoneSaturationLookUps',
    laboratoryLookUps: 'api/InventoryLookup/LaboratoryLookUp',

    //pieces lookups
    pieceOwnerLookUp: 'api/InventoryLookup/LegalOwnerLookUps',
    ringSizeLookup: 'api/InventoryLookup/RingSizeLookUps',
    bangleSizeLookup: 'api/InventoryLookup/BangleSizeLookUps',
    productionTeamStatusLookUp: "api/InventoryLookup/ProductionTeamStatusLookUps",
    consignmentStatusLookup: "api/InventoryLookup/ConsignmentStatusLookups",
    salesStatusLookup: "api/InventoryLookup/SalesStatusLookups",
    salesStatusFilterLookup: "api/InventoryLookup/SalesStatusFilterLookups",
    specialStatusLookup: "api/InventoryLookup/SpecialStatusLookups",
    showSupplierDeliveryDate: "api/UserLookups/CheckShowSupplierDeliveryDate",
    checkPieceStatusTypePermission: "api/InventorySearch/CheckPieceStatusTypePermission",

    //POS lookups
    deliveryCountryLookUp: 'api/POSLookup/DeliveryCountryLookUps',
    usaStateProvinceLookUp: 'api/POSLookup/StateProvinceLookUps',
    posPaymentMethodLookUp: 'api/POSLookup/POSPaymentMethodLookUps',
    posCreditCardTypeLookUp: 'api/POSLookup/POSCreditCardTypeLookUps',
    shippingStatusLookUp: 'api/POSLookup/ShippingStatusLookUps',
    posSalesChannelLookUp: 'api/POSLookup/POSSalesChannelLookUps',
    posShippingMethodLookUp: 'api/POSLookup/POSShippingMethodLookUps',
    posFabTeamUsersLookUp: 'api/POSLookup/POSFabTeamUsersLookUps',
    posBookingDataLookUp: 'api/POSLookup/BookingDataLookUps',
    posEventDataLookUp: 'api/POSLookup/POSEventDataLookUps',
    posShippingStatusLookUp: 'api/POSLookup/POSShippingStatusLookUps',

    //Ticket Url
    userDetailForTicket: 'api/SearchUsers/GetUserDetailForTicket',
    userTicketReportToDetail: 'api/SearchUsers/GetUserTicketReportToDetail',
    getTicketDetailAccessPermission: 'api/SearchUsers/GetTicketDetailAccessPermission',

    //Inventory Report
    productStatusForReportLookUp: 'api/InventoryLookup/ProductStatusForReportLookUp',
    boutiquesForWishlistLookUp: 'api/InventoryLookup/BoutiquesForWishlistLookUp',
    bespokeReportSalesPersonLookUp: 'api/InventoryLookup/BespokeReportSalesPersonLookUp',

    //Gemfield Risk
    riskCategoryLookUp: 'api/masterlookups/RiskCategoryLookUp',
    specificRiskLookUp: 'api/FormLookups/SpecificRisksLookUp',
    localRiskOwnerLookUp: 'api/FormLookups/FormsGemfieldRiskLocalRiskOwnerLookUp',
    boardRiskOwnerLookUp: 'api/FormLookups/FormsGemfieldRiskBoardRiskOwnerLookUp',
    boardRiskOwnerEditLookUp: 'api/FormLookups/FormsGemfieldRiskEditBoardRiskOwnerLookUp',
    localRiskOwnerEditLookUp: 'api/FormLookups/FormsGemfieldRiskEditLocalRiskOwnerLookUp',
    gemfieldGroupCompanyLookUp: 'api/FormLookups/GemfielGroupCompanyLookUp',
    ITGroupCompanyLookUp: 'api/FormLookups/ITGroupCompanyLookUp',

    //Forms -> Legal Agreements
    agreementTypeLookUp: 'api/FormLookups/GetAgreementTypeLookUp',
    gglEmployeesLookUp: 'api/FormLookups/GetGGLEmployeesLookUp',
    specificRiskLALookUp: 'api/FormLookups/GetLASpecificRisksLookUp',
    projectsLookUp: 'api/FormLookups/GetProjectsLookUp',
    eventsLookUp: 'api/FormLookups/GetEventsLookUp',

    //Form->Production Order Form
    productionOrderFormType: 'api/FormLookups/ProductionFormTypeLookUp',
    productionFormOrderNo: 'api/FormLookups/ProductionFormOrderNoLookUp',
    productionSalesChannel: 'api/FormLookups/ProductionSalesChannelLookUp',
    productionSalesPerson: 'api/FormLookups/ProductionSalesPersonLookUp',
    productionStatusLookup: 'api/FormLookups/ProductionStatusLookUp',
    productionClientCorporate: 'api/FormLookups/ProductionClientCorporateLookUp',
    productionCorporate: 'api/FormLookups/ProductionCorporateLookUp',
    electronicGiftCorporate: 'api/FormLookups/ElectronicGiftCorporateLookUp',
    electronicGiftUser: 'api/FormLookups/ElectronicGiftUserLookUp',
    productionCurrencyLookup: 'api/FormLookups/vProductionOrderCurrencyLookUps',
    productionCountryLookup: 'api/FormLookups/vProductionOrderCountryLookUps',

    enhancementTypeLookUp: 'api/FormLookups/vEnhancementTypeGemfieldsLookUps',
    colorLookUp: 'api/FormLookups/vGemStoneColorGemfieldsLookUps',
    cutTypeLookUp: 'api/FormLookups/vGemStoneCutTypeGemfieldsLookUps',
    originLookUp: 'api/FormLookups/vGemstoneOriginLookUps',
    clarityLookUp: 'api/FormLookups/vGemStoneClarityGemfieldsLookUps',
    laboratoryLookUp: 'api/FormLookups/vLaboratoryLookUps',
    chainStyleLookUp: 'api/FormLookups/ChainStyleLookUps',
    chainGaugeLookUp: 'api/FormLookups/ChainGaugeLookUps',
    bangleSizeLookUp: 'api/FormLookups/BangleSizeLookUps',
    productCategoryLookup: 'api/FormLookups/GetProductCategory',
    productionProductLookup: 'api/FormLookups/GetProductionProduct',
    GetAllLocalizedMessages: 'api/masterlookups/GetAllLocalizedMessages',
    productionFormMaterialLookUps: 'api/FormLookups/ProductionFormMaterialLookUps',
    productionFormColorLookUps: 'api/FormLookups/ProductionFormColorLookUps',
    //End

    ginaGemfieldGroupCompanyLookUp: 'api/MasterLookups/GemfieldGroupCompanyLookUp',
    holidayTypeLookUp: 'api/MasterLookups/HolidayTypeLookUp',

    //Design
    designerUserLookup: 'api/userlookups/DesignerAdminUserLookup',
    corporateCreditRatings: 'api/MasterLookups/CorporateCreditRatings',

    //Press Media
    getPressMediaLookup: 'api/SearchCommon/GetPressMediaLookup',
    articleTypeLookUp: 'api/MasterLookups/ArticleTypeLookUp',

    treatmentLookup: 'api/MasterLookups/TreatmentLookups',
    roleLookUpExcel: 'api/masterlookups/RoleLookUpsExcel',

    // Watch parts
    watchItemTypeLookUps: 'api/MasterLookups/WatchItemTypeLookUps',
    watchStatusLookUps: 'api/MasterLookups/WatchStatusLookUps',
    watchLocationLookUps: 'api/MasterLookups/WatchLocationLookUps',
    watchMaterialLookUps: 'api/MasterLookups/WatchMaterialLookUps',
    watchSupplierLookups:'api/MasterLookups/WatchSupplierLookUps',
    watchInvoiceLookUp:'api/MasterLookups/GetInvoiceLookUps',

    pressMediaTypesToSendEmail: 'api/userlookups/PressMediaTypesToSendEmail',

    //personal tab access
    managersLookUps: 'api/userlookups/ManagersLookUp',
    gemfieldsGroupComapnyLookUps: 'api/userlookups/GemfieldsGroupComapnyLookUp',

    //Grievance Form
    getGrievanceFormLookups: 'api/SearchCommon/GetGrievanceFormLookups',

    //product type
    productTypeLookUps: 'api/InventoryLookup/ProductTypeLookUps',

    //production order form user department mappins
    pofDepartmentLookup: 'api/MasterLookups/GetDepartmentLookUps',

    //duplicate customer
    duplicateCustomerLookup:'api/MasterLookups/DuplicateCustomerLookup',

    productPieceStatusLookup:'api/MasterLookups/ProductPieceStatusTypeLookup',
    damageCostLookup:'api/FormLookups/DamageCost',

    // applicationObjectType
    applicationObjectTypeLookup:'api/MasterLookups/vApplicationObjectTypeLookup'
}
