import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Toastr } from '@rxweb/js';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { FileInputViewModel } from '../../../../view-model/file-input-view-model';
import { FileOutputViewModel } from "../../../../view-model/file-output-view-model"
import { FileTypeEnums, FilePlaceHolders, FileTypeValiationEnums } from '../../../../custom-enum/file-type-enums';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { vFileExtention } from 'src/app/models/extended-models/v-file-extention';
import { RxHttp, http } from "@rxweb/http";
import { CoreComponent } from '@rxweb/angular-router';
import { getBlobImgURL } from 'src/app/domain/system-constant';


@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})

export class FileComponent extends CoreComponent implements OnInit {

  @Input() fileViewModel: FileInputViewModel;
  @Input() tabindex: number;
  @Input() customPlaceHolder: string;
  @Input() title: string;
  @Output() files: EventEmitter<FileOutputViewModel> = new EventEmitter<FileOutputViewModel>();
  showComponent: boolean = false;
  id: string;
  fileSafePath: SafeUrl;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  url: string;
  extentionList: vFileExtention[];
  constructor(private baseToastr: BaseToastr, private sanitizer: DomSanitizer) {
    super()
      this.get({path:'api/FileExtentions'}).subscribe((response:any)=>{
        this.extentionList = response;
      });
    
  }

  ngOnInit() {
    this.id = "file" + Math.random().toString();
    setTimeout(() => {
      this.loadSetting();
    }, 1000);
    this.showComponent = true;
  }

  loadSetting() {
    if(this.fileViewModel.fileExtensionAllow!=""){
      this.fileViewModel.fileExtensionAllow="";
    }
    if (this.fileViewModel.fileMode == FileTypeEnums.Doc) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.Doc;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.Doc;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.Image) {
      this.fileOutputViewModel.displayImage = true;
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.Image;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.Image;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.Video) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.Video;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.Video;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.Pdf) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.Pdf;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.Pdf;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.DocAll) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.DocAll;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.DocAll;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.SpecificImage) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.SpecificImage;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.SpecificImage;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.DocOnly) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.DocOnly;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.DocOnly;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.HomeLayoutFrontImage) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.HomeLayoutFrontImage;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.HomeLayoutFrontImage;
    } 
    else if (this.fileViewModel.fileMode == FileTypeEnums.HomeLayoutMobileImage) {
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.HomeLayoutMobileImage;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.HomeLayoutMobileImage;
    }
    else if(this.fileViewModel.fileMode == FileTypeEnums.VideoFabergeGemstone){
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.Video;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.VideoFabergeGemstone;
    }
    else if (this.fileViewModel.fileMode == FileTypeEnums.Logo) {
      this.fileOutputViewModel.displayImage = true;
      this.fileViewModel.filePlaceHolder = FilePlaceHolders.Logo;
      this.fileViewModel.fileExtensionAllow = FileTypeValiationEnums.Logo;
    }
    else {
      this.fileViewModel.filePlaceHolder = "Select File";
      //this.fileViewModel.fileExtensionAllow = ".jpg,.jpeg,.png,.mp4,.mp3,.pdf,.doc,.docx,.xlsx,.ppt,.pptx,.tif,.zif,.zip,.msg,.mov,.wmv,.xls,.odt,.xlsm,.gif,.rtf,.m4a,.ods,.kmz,.jfif,.pub,.avi,.csv,.ppsx,.tiff,htm,.eps,
      //.bmp,.rar,.dotx,.dwg,.m4v,.numbers,.html,.log,.eml,.mpeg,.txt,.xps,.webp,.jpe,.mpp,.xml,.ai,.xspf,.xlsb,.dot,.step,.webloc,.aac"
      
      this.fileViewModel.fileExtensionAllow = this.extentionList.map(x=>x.fileExtentionName).join(",");
      // if(this.fileViewModel.fileExtensionAllow.includes("svg") || this.fileViewModel.fileExtensionAllow.includes("aac")){

      //   var values = this.fileViewModel.fileExtensionAllow.split(",");
      //   for(var i=0; i< values.length; i++){
      //     if(values[i]=="svg" || values[i]=="acc"){
      //       values.splice(i,1);
      //       //values.join(",");
      //     }
      //   }
      //   this.fileViewModel.fileExtensionAllow = values.toString();
      //   // this.fileViewModel.fileExtensionAllow.replace("svg",'');
      //   // this.fileViewModel.fileExtensionAllow.replace("aac",'');
      // }
    }
    this.fileViewModel.filePlaceHolder = this.customPlaceHolder != null && this.customPlaceHolder != undefined && this.customPlaceHolder != '' ? this.customPlaceHolder : this.fileViewModel.filePlaceHolder;
  }

  onFileChange(data: any): void {
    this.loadSetting();
    let target = data.target || data.srcElement;
    var file = target.files[0];
    var _size = file.size;
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0; while (_size > 900) { _size /= 1024; i++; }
    var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];

    if (file) {
      let filename = file.name;
      let extension = filename.substr((filename.lastIndexOf('.') + 1)).toLowerCase();
      let split_str = this.fileViewModel.fileExtensionAllow;
      let extentionArray = split_str.split(',');
      let checkExtension = extentionArray.find(x => x == '.'+extension)
      if (checkExtension != null) {
        if (this.fileViewModel.isMultipart) {
          let reader = new FileReader();
          reader.onload = (readerEvt: any) => {
            this.fileOutputViewModel.fileExtension = extension;
            this.fileOutputViewModel.fileName = filename;
            this.fileOutputViewModel.fileMode = this.fileViewModel.fileMode;
            this.fileOutputViewModel.files = file;
            this.fileOutputViewModel.url = data.target.value
            this.files.emit(this.fileOutputViewModel);
          };
          reader.readAsDataURL(file);
        }
        else {
          let reader = new FileReader();
          reader.onload = (readerEvt: any) => {
            let documentFilePath = btoa(readerEvt.target.result);
            this.fileOutputViewModel.fileExtension = extension;
            this.fileOutputViewModel.fileName = filename;
            this.fileOutputViewModel.fileMode = this.fileViewModel.fileMode;
            this.fileOutputViewModel.fileBaseString = documentFilePath;
            this.fileOutputViewModel.files = file;
            this.files.emit(this.fileOutputViewModel);
          };
          reader.readAsBinaryString(file);
        }
      }
      else {
        this.baseToastr.error("Only following extensions are allowed:" + this.fileViewModel.fileExtensionAllow);
        return
      }
    }

    //#197690 : Bug 5 : As per discussion with kashyap bhai, have removed the size validation on file control
    // if (file.size / 1024 > (this.fileViewModel.fileAllowSize * 1024)) {
    //   this.baseToastr.error("File Size should be " + this.fileViewModel.fileAllowSize + " " + fSExt[i]);
    //   return;
    // }
  }

  getImgContent(fileData: any): SafeUrl {
    let imgUrl = "data:" + fileData.fileExtension + ";base64," + fileData.fileData;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
  }
 
}
