import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "2%", "text-align": "center" },
    configuredTemplate: { templateName: 'pencilButton' }, columnIndex: 2, headerTitle: "Update"
 })
 @actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 3, headerTitle: "Delete"
})
export class vProductDesignPicture{
    productPictureID:number;
    productID:number;
    pictureID:number;
    @gridColumn({
        template:{
            div: {
                childrens: [
                  {
                    label: {
                      childrens: [
                        {
                          input:
                          {
                            class: ["form-control", "checkbox-custom-style","col-xs-1"],
                             event: {
                              input: "onData"
                            },
                            attributes: {
                              type: "number",
                              value: function (e) {
                                return this.displayOrder;
                              },
                              min:1
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
        }
        ,parameter:false,style: { width: "3%", "text-align": "initial"}, class: ["text-left"],  visible: true, columnIndex: 1, allowSorting: false, headerTitle: 'Display Priority', keyColumn: false })
    displayOrder:number;
    @gridColumn({  template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.imageUrl;
          }
        }
      }
  },
      class: ["text-left"],allowSearch:false, style: { width: "30%"}, name: "imageUrl", visible: true, columnIndex: 0, allowSorting: false, headerTitle: 'Large Image', keyColumn: false })
    imageUrl: string;


}