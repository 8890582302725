import {vChainColourRecordBase,} from '@app/database-models'
//Generated Imports
export class vChainColourRecord extends vChainColourRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}