import { TemplateConfig } from '@rxweb/grid';
import { disable } from '@rxweb/reactive-form-validators';

export const BIND_GemstoneCheckBox_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            class: ["mb-0"],
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style", "disabled"],
                  style: {
                    "margin-top": "-4px",
                    "position": "relative",
                    "margin-left": "auto",
                    
                  },
                  event: {
                    input: "onCheckBoxSelect"
                  },
                  attributes: {
                    type: "checkbox",
                    id: function (e) {
                      return "selected-checkbox-" + this.gemstonesId;
                    },
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    },
                    checked: function (e) {
                      // var a = this[e.name];
                      
                      return this[e.name];
                    },
                    
                    
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }