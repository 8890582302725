<div
  class="modal-dialog modal-lg"
  [rxSpinner]="spin"
  *rxMultilingual=""
  [rxLocalisationInit]="componentName"
>
  <div class="modal-content">
    <div class="modal-header text-left">
      <h4
        class="modal-title mb-0"
        rxText="LegalAgreementProjMappingAddComponent_AddLA_t"
      ></h4>

      <button
        type="button"
        class="close py-2"
        (click)="hide()"
        [title]="titleClose"
      >
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div
      [rxLocalisationInit]="componentName"
      class="modal-body"
      [formGroup]="legalAgreementProjectSearchFormGroup"
      *ngIf="showComponent"
      (keyup.enter)="search()"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label
              class="col-md-12 col-form-label"
              rxText="LegalAgreementProjMappingAddComponent_Name_t"
            ></label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                tabindex="1"
                formControlName="legalAgreementName"
                placeholder="Enter Agreement Name"
                rxFocus
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-md-12 col-form-label"
              rxText="LegalAgreementProjMappingAddComponent_Type_t"
            ></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
              <rx-select
                [(source)]="legalAgreementLookup.agreementTypeLookUp"
                #rxSelect
                mainClass="form-control"
                [controlTabIndex]="2"
                [selectPlaceholder]="'Select Agreement Type'"
                formControlName="agreementType"
                [keyValueProps]="['agreementTypeName', 'agreementTypeId']"
              >
              </rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-md-12 col-form-label"
              rxText="LegalAgreementProjMappingAddComponent_Leader_t"
            ></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
              <rx-select
                [(source)]="legalAgreementLookup.gglEmployeesLookUp"
                #rxSelect
                mainClass="form-control"
                [controlTabIndex]="3"
                [selectPlaceholder]="'Select Internal Commercial Leader'"
                formControlName="leader"
                [keyValueProps]="['name', 'id']"
              >
              </rx-select>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group row">
            <label
              class="col-md-12 col-form-label"
              rxText="LegalAgreementProjMappingAddComponent_DateFrom_t"
            ></label>
            <div class="col-md-12">
              <rx-date
                showAddon="true"
                pickerClass="form-control"
                [controlTabIndex]="5"
                formControlName="startDate"
                placeholder="Select Date"
              >
              </rx-date>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-md-12 col-form-label"
              rxText="LegalAgreementProjMappingAddComponent_DateTo_t"
            ></label>
            <div class="col-md-12">
              <rx-date
                showAddon="true"
                pickerClass="form-control"
                [controlTabIndex]="6"
                formControlName="endDate"
                placeholder="Select Date"
              >
              </rx-date>
              <small
                class="form-text text-danger"
                *ngIf="dateError == true"
                rxText="LegalAgreementProjMappingAddComponent_DateError_t"
              ></small>
            </div>
          </div>
        </div>
      </div>

      <div class="hr-line-dashed border-top w-100"></div>
      <div class="row mx-0">
        <div
          class="form-group ml-auto mb-0"
          [rxLocalisationInit]="componentName"
        >
          <button
            (click)="search()"
            class="btn btn-success"
            rxText="Btn_Search_t"
          ></button>
        </div>
      </div>
      <div
        class="table-responsive mt-3"
        (keyup.enter)="saveLegalAgreement(false)"
        *ngIf="showGrid"
      >
        <div
          id="DataTables_Table_0_wrapper"
          class="dataTables_wrapper pb-0 dt-bootstrap4"
        >
          <div class="table-responsive" *ngIf="isShowGrid">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="LegalAgreementProjectListGrid"></rx-grid>
            </div>
          </div>
          <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
            <h4>{{ ErrorMsg }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal-footer"
      *ngIf="isShowGrid"
      [rxLocalisationInit]="componentName"
    >
      <ng-container *ngIf="showSaveButton" [rxLocalisationInit]="componentName">
        <button
          (click)="save(false)"
          class="btn btn-success"
          [disabled]="(this.legalAgreementProjectMappingArray.length == 0  && this.excludedLegalAgreementProjectMappingArray.length == 0 && !this.headerBtnChk)
          || (this.totalRecords - this.excludedLegalAgreementProjectMappingArray.length == 0)"
          rxText="Btn_Save_t"
        ></button>
      </ng-container>
      <ng-container *ngIf="showSaveButton" [rxLocalisationInit]="componentName">
        <button
          (click)="save(true)"
          class="btn btn-success"
          [disabled]="(this.legalAgreementProjectMappingArray.length == 0  && this.excludedLegalAgreementProjectMappingArray.length == 0 && !this.headerBtnChk)
          || (this.totalRecords - this.excludedLegalAgreementProjectMappingArray.length == 0)"
          rxText="Btn_Save_Continue_Edit_t"
        ></button>
      </ng-container>

      <button
        (click)="hide()"
        class="btn btn-outline-primary ml-0"
        rxText="Btn_Close_t"
      ></button>
    </div>
  </div>
</div>
