import { Component, OnInit, OnDestroy, EventEmitter, Input } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractProjectEventMapping } from '../domain/abstract-project-event-mapping';
import { ProjectEventMapping } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { DatePipe, PlatformLocation } from '@angular/common';
import { GridColumnConfig } from '@rxweb/grid';
import { ProjectEventMappingAddComponent } from '../add/project-event-mapping-add.component';

import { PaginationEnum } from 'src/app/enums/pagination.enums';

import { multilingual } from '@rxweb/localization';
import { SERVER_URL } from 'src/app/domain/system-constant';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "src/app/domain/customize-design/dialog";

@multilingual("ProjectEventMappingListComponent")
@Component({
  selector: "app-project-event-mapping-list",
  templateUrl: './project-event-mapping-list.component.html'
})
export class ProjectEventMappingListComponent extends AbstractProjectEventMapping implements OnInit, OnDestroy {
  subscription: any;
  selectedProjects: number[];
  id: number;
  json: any;
  totalRecords: number;
  isFilter: boolean = false;
  gridSearchString: string = "";
  isHideAll: boolean = false;
  isShowAll: boolean = false;
  titleSearch: string;
  isShowGridColumn: boolean = false;
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";
  isLongTextExists: boolean=false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, modelView: ModalView, private datePipe: DatePipe, private browserStorage: BrowserStorage,private location : PlatformLocation) {
    super();
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })
    this.modalView = modelView;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.selectedProjects = new Array<number>();
    this.isFilter = false;
    this.totalRecords = 0;
    this.json = JSON.parse(JSON.stringify({
      eventId: this.id,
      //clientUrl:SERVER_URL,
      projectOrEvent: 1,
      pageIndex: 1,
      rowCount: 25,
      sortOrder: true,
      orderBy: 'projectName',
      isSearch: false,
      searchString: ''
    }));
    this.json["userId"] = 0;
    this.json["eventId"] = this.id;
    this.json["pageIndex"] = PaginationEnum.PageIndex;
    this.json["rowCount"] = PaginationEnum.RowCount;
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
  }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
          this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      setTimeout(() => {
          this.bindGrid();
      }, 0);
  })
  }

  bindGrid(isOnload: boolean = true) {
    this.isShowGrid = true;
    this.spin = true;
    this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((t: any) => {
      this.projectEventMappingList = t[0].UserData;
      // if (isOnload) {
      //   for (let element of this.projectEventMappingList) {
      //     if (element.noteCharCount > 100) {
      //       this.isShowAll = true;
      //       break;
      //     }
      //   }
      // }
      if (this.projectEventMappingList.length > 0) {
        this.totalRecords = this.projectEventMappingList[0].totalCount;
        // this.projectEventMappingList.forEach(x => {
        //   //  x.projectStartDate=this.datePipe.transform(x.projectStartDate,'dd-MMM-yyyy');
        //   x.isChecked = true;
        // });
        this.projectEventMappingList.forEach(x => {
          if (this.selectedProjects.indexOf(x.projectID) > -1) {
            x.isChecked = true;
          } else {
            x.isChecked = false;
          }
        });
        this.isNoRecordFound = false;
      }
      else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }


      if (!this.isFilter) {
        this.projectEventMappingGrid = new AppGrid(this.projectEventMappingList, ProjectEventMappingViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onEdit: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(x => {
              this.projectEventMappingGrid.gridColumns.forEach(y => {
                  if (y.name == x.ColumnName)
                      y.visible = x.Visible;
              });
          });
          this.gridColumnConfig = this.projectEventMappingGrid.gridColumns;
          this.gridColumnSettings.emit(this.gridColumnConfig)
      }
      this.checkForLongTexts();
        this.projectEventMappingGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }
        this.projectEventMappingGrid.maxPerPage = this.json['rowCount'];
        this.projectEventMappingGrid.componentId = this.componentName;
        this.projectEventMappingGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
        this.projectEventMappingGrid.designClass.headerClass = "table-header-sticky".split(" ");

      }
      else {
        if (this.projectEventMappingGrid != null) {
          this.projectEventMappingGrid.storeProcedure.length = this.totalRecords;
          //  this.projectEventMappingGrid.updateSource([]);
          this.projectEventMappingGrid.updateSource(this.projectEventMappingList);

          this.checkForLongTexts();
        }
      }

      if (this.projectEventMappingGrid != null) {
        this.projectEventMappingGrid.gridColumns.forEach(x => {
          if (x.columnIndex == 0) {
            x.visible = true;

          }
          if (x.columnIndex == 1) {
            x.visible = false;
          }
          if (x.name == 'prjName') {
            x.visible = false;
            x.parameter=true;
          }
        })
      }
      this.spin = false;

    })
  }
  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
}
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(element => {
        var index = this.projectEventMappingGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
        if (index != -1) {
          if (this.projectEventMappingGrid.gridColumns[index].visible != element.Visible) {
            element.Visible = this.projectEventMappingGrid.gridColumns[index].visible;
          }
        }
      })
    }
  this.checkForLongTexts();
    this.projectEventMappingGrid.reDesign();
    this.projectEventMappingGrid.updateSource([])
    this.projectEventMappingGrid.updateSource(this.projectEventMappingList);
}

showOrHideAll() {
  this.isShowAll = !this.isShowAll;
  this.isFilter = false;
  this.projectEventMappingGrid.destroy();
  this.projectEventMappingGrid = null;
  this.json["isShowAll"]=this.isShowAll;
  this.json["pageIndex"] = 1;
  this.bindGrid();
}

checkForLongTexts() {
  this.isLongTextExists = false;
  let isDescriptionCheckRequired = false;
  this.projectEventMappingGrid.gridColumns.forEach(column => {
    if (column.name == "noteDescription" && column.visible) {
      isDescriptionCheckRequired = true;
    }
  });
  if (isDescriptionCheckRequired ) {
    for (let element of this.projectEventMappingList) {

      if (element.noteCharCount > 100 && isDescriptionCheckRequired) {
        this.isLongTextExists = true;
        break;
      }
    }
  }
}

  onMoreClick(t, x) {

    var element = document.getElementById("note_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");

    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");


    }
  }

  onCheckboxSelect(t, x, y, z) {
    if (x.target.checked) {
      if (this.selectedProjects.findIndex(x => x == t.projectID) == -1) {
        this.selectedProjects.push(t.projectID);
      }
    }
    else {
      if (this.selectedProjects.findIndex(x => x == t.projectID) != -1) {
        this.selectedProjects.splice(this.selectedProjects.indexOf(t.projectID), 1);
      }
    }
  }

  onEdit(eventMapping: ProjectEventMappingViewModel) {
    //this.router.navigate(['/projects', eventMapping.projectID]);
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(['/projects', eventMapping.projectID]));
    redirectOnCTRL(url,this.router,event);
  }

  addProject() {
    this.modalView.show(ProjectEventMappingAddComponent, { eventId: this.id }).then(t => {
      this.isFilter = true;
      this.projectEventMappingGrid.updateSource([]);
      this.bindGrid();
    });
  }


  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  search(value) {
    // this.projectEventMappingGrid.search=value.target.value;
    this.isFilter = true;
    // if(!(value.keyCode>=37 && value.keyCode<=40) && value.keyCode!=9 && value.keyCode!=32){
    // if((value.target.value.replace(/ /g,'').length>2 && value.target.value!=undefined && value.target.value.replace(/ /g,'')!="") || (value.keyCode==8 && value.target.value=='')){
    setTimeout(() => {
      this.spin = false;
      this.json['searchString'] = value.target.value.replace(/^\s+|\s+$/gm, '');
      this.totalRecords = 0;
      this.json['pageIndex'] = 1;
      if (this.projectEventMappingGrid != null) {
        this.projectEventMappingGrid.updateSource([]);
        this.projectEventMappingGrid.storeProcedure.nextPage = 1;
      }
      this.projectEventMappingGrid.updateSource([]);
      this.bindGrid(false);
    }, 4000);
    this.spin = true;
    //   }
    // }
  }



  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json['sortOrder'] = y;
    this.json['orderBy'] = x;
    this.projectEventMappingGrid.storeProcedure.nextPage = z;
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json['pageIndex'] = x;
    this.json['rowCount'] = this.projectEventMappingGrid.maxPerPage;
    this.projectEventMappingGrid.storeProcedure.nextPage = x;
    this.bindGrid(false);
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Project_Event");
    if (existsAlert) {
      return existsAlert;
    }
  }
  saveContinue(isRedirect : boolean){
    if(this.selectedProjects.length > 0)
    {
      this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
            this.deleteProjects(isRedirect);
          }
          this.dialog.hide()
        })
    }
    else
    {
      this.deleteProjects(isRedirect);
    }
  }

  deleteProjects(isRedirect: boolean) {
    // this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
    //   if (t === DialogEnum.Ok) {
    //     this.dialog.hide();
        let selectedProject: any = JSON.parse(JSON.stringify({ projectsArray: [] }));
        selectedProject.projectsArray = this.selectedProjects;
        if (this.selectedProjects.length > 0) {
          this.spin = true;
          this.delete({ params: [this.id], body: null, queryParams: { "projectOrEvent": 1, "query": JSON.stringify(selectedProject.projectsArray) } }).subscribe(data => {
            this.spin = false;
            //this.toastr.success("Data Updated Successfully");
            var existsAlert = getLocalizedValue("Data_Updated");
            if (existsAlert) {
              this.toastr.success(existsAlert);
            }
            //this.selectedProjects.splice(0);
            this.selectedProjects=[];
            this.isFilter = true;
            this.totalRecords = 0;

            if (!isRedirect) {
              this.json = JSON.parse(JSON.stringify({ eventId: this.id, projectOrEvent: 1, pageIndex: 1, rowCount: 25, sortOrder: true, orderBy: 'projectName', isSearch: 'false', userId:0}));
              this.projectEventMappingGrid.updateSource([]);
              this.bindGrid();
            }
            else {
              this.router.navigate(["events", this.id], { queryParams: { activeTab: 'event-info' } });
            }

          })
        }
    //   }
    //   this.dialog.hide()
    // })
  }
  get componentName(): string {
    return "ProjectEventMappingListComponent";
  }
}
