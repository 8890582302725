<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
        <div class="col-md-8 my-1">
            <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/dashboard']">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/payment-methods']" rxText="Label_Payment_Methods_t"></a>
                </li>
                <li class="breadcrumb-item active">Add New</li>
            </ol>
        </div>
        <div class="col-md-4 text-right">
            <app-system-time></app-system-time>
          </div>
    </div>
    <div class="wrapper wrapper-content flex-grow-1" >
        <div class="ibox mb-0 d-flex flex-column">
            <div class="ibox-title bg-white pr-3">
                <h5><label rxText="PaymentMethodAddComponent_Title"></label></h5>    
                <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                  <i class="fa fa-question-circle fa-2x"></i>
                </a>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="paymentMethodFormGroup"  [rxSpinner]="spin">    
                <div class="row add-countries-form">
                  <div class="col-lg-6 col-xl-4 pr-xl-5">
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_MethodName_t"></label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="name" tabindex="1"  rxPlaceholder="PaymentMethodAddEditComponent_MethodName_p" rxFocus />
                        <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.name.errors">{{paymentMethodFormGroup.controls.name.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_VisibleName_t"></label>
                      <div class="col-md-8">
                        <input type="text" class="form-control" formControlName="visibleName" tabindex="4"  rxPlaceholder="PaymentMethodAddEditComponent_VisibleName_p" />
                        <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.visibleName.errors">{{paymentMethodFormGroup.controls.visibleName.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_IsImpersonated_t"></label>
                      <div class="col-md-auto d-flex align-items-center">
                        <div class="i-checks">
                          <label class="mb-0">
                            <!-- <input type="checkbox" formControlName="isImpersonated"><i></i> -->
                            <app-checkbox [checkFormControl]="paymentMethodFormGroup.controls.isImpersonated" tabindex="6"></app-checkbox>
                          </label>
                          <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.isImpersonated.errors">{{paymentMethodFormGroup.controls.isImpersonated.errorMessage}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xl-4 px-xl-4">
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_Description_t"></label>
                      <div class="col-md-8">
                        <textarea class="form-control" formControlName="description" tabindex="2" rows="4"  rxPlaceholder="PaymentMethodAddEditComponent_Description_p"></textarea>
                        <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.description.errors">{{paymentMethodFormGroup.controls.description.errorMessage}}</small>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_IsActive_t"></label>
                      <div class="col-md-auto d-flex align-items-center">
                        <div class="i-checks">
                          <label class="mb-0">
                            <!-- <input type="checkbox" formControlName="isActive"><i></i> -->
                            <app-checkbox [checkFormControl]="paymentMethodFormGroup.controls.isActive" tabindex="7"></app-checkbox>
                          </label>
                          <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.isActive.errors">{{paymentMethodFormGroup.controls.isActive.errorMessage}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="col-lg-6 col-xl-4 pl-xl-5">
                        <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_SystemKeyword_t"></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control"  formControlName="systemKeyword" tabindex="3"  rxPlaceholder="PaymentMethodAddEditComponent_SystemKeyword_p"/>
                                <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.systemKeyword.errors">{{paymentMethodFormGroup.controls.systemKeyword.errorMessage}}</small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="PaymentMethodAddEditComponent_DisplayPriority_t"></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control"  formControlName="displayOrder" tabindex="5"  rxPlaceholder="PaymentMethodAddEditComponent_DisplayPriority_p"/>
                                <small class="form-text text-danger" [class.d-block]="paymentMethodFormGroup.controls.displayOrder.errors">{{paymentMethodFormGroup.controls.displayOrder.errorMessage}}</small>
                            </div>
                        </div>
                    </div>
                
                </div>                
                <div class="row mt-auto">
                    <div class="hr-line-dashed border-top w-100"></div>
                    <div class="col text-right">
                        <button (click)="addPaymentMethod(false)"  class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                        <button (click)="addPaymentMethod(true)"   class="btn btn-primary mr-1" *rxAuthorize="[paymentMethodEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</div>
