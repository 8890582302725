import {QueuedEmailBase,} from '@app/database-models'
//Generated Imports
export class QueuedEmail extends QueuedEmailBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}