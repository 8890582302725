import {SEORedirectionBase,} from '@app/database-models'
//Generated Imports
export class SEORedirection  
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}