import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldsEnhancementTypeBase {

//#region enhancementTypeId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldsEnhancementTypeListComponent_TypeId_gh', keyColumn: true })
        enhancementTypeId : number;
//#endregion enhancementTypeId Prop


//#region enhancementTypeName Prop
        @gridColumn({isAscending:true, style: { width: "50%", "text-align": "initial" },visible: true, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsEnhancementTypeListComponent_TypeName_gh', keyColumn: false })
        enhancementTypeName : string;
//#endregion enhancementTypeName Prop

}