import {vDisplayOrderLookUpBase,} from '@app/database-models'
//Generated Imports
export class vDisplayOrderLookUp extends vDisplayOrderLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}