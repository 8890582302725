import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerRegistrationTypeLookUpBase {

//#region customerRegistrationTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerRegistrationTypeId', keyColumn: true})
        customerRegistrationTypeId : number;
//#endregion customerRegistrationTypeId Prop


//#region customerRegistrationTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'customerRegistrationTypeName', keyColumn: false})
        customerRegistrationTypeName : string;
//#endregion customerRegistrationTypeName Prop

}