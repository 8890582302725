//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentOrderSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  @gridColumn({ parameter: true, style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_OrderId_gh', keyColumn: false })
  orderId: string;

  //#region customerName 
  @gridColumn({ parameter: true, style: { width: "80%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_OrderCustomerName_gh', keyColumn: false })
  customerName: string;
  //#endregion customerName 

  totalCount: number;
}
