import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BookingTypeBase {

//#region tripTypeId Prop
        @prop()
        tripTypeId : number;
//#endregion tripTypeId Prop


//#region tripType Prop
        @required()
        @notEmpty()
        tripType : string;
//#endregion tripType Prop

}