import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCurrencyRecordBase {

//#region currencyId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'currencyId', keyColumn: true})
        currencyId : number;
//#endregion currencyId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region currencyCode Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'currencyCode', keyColumn: false})
        currencyCode : string;
//#endregion currencyCode Prop


//#region displayLocale Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'displayLocale', keyColumn: false})
        displayLocale : string;
//#endregion displayLocale Prop


//#region rate Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'rate', keyColumn: false})
        rate : number;
//#endregion rate Prop


//#region customFormatting Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'customFormatting', keyColumn: false})
        customFormatting : string;
//#endregion customFormatting Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : any;
//#endregion countryId Prop


//#region domain Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'domain', keyColumn: false})
        domain : number;
//#endregion domain Prop

}