import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vForumTopicTypeLookUpBase {

//#region forumTopicTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'forumTopicTypeId', keyColumn: true})
        forumTopicTypeId : number;
//#endregion forumTopicTypeId Prop


//#region forumTopicTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'forumTopicTypeName', keyColumn: false})
        forumTopicTypeName : string;
//#endregion forumTopicTypeName Prop

}