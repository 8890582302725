import {vRecurringProductCyclePeriodLookUpBase,} from '@app/database-models'
//Generated Imports
export class vRecurringProductCyclePeriodLookUp extends vRecurringProductCyclePeriodLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}