import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class IncidentAccidentTypeBase {

//#region incAccidentTypeId Prop
        @prop()
        incAccidentTypeId : number;
//#endregion incAccidentTypeId Prop


//#region incidentId Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        incidentId : number;
//#endregion incidentId Prop


//#region occurrenceType Prop
        @required()
        occurrenceType : any;
//#endregion occurrenceType Prop


//#region accidentTypeId Prop
        @required()
        accidentTypeId : any;
//#endregion accidentTypeId Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop





}