import {CarnetNoteBase,CarnetBase,} from '@app/database-models'
//Generated Imports
export class CarnetNote extends CarnetNoteBase 
{




//#region Generated Reference Properties
//#region carnet Prop
carnet : CarnetBase;
//#endregion carnet Prop

//#endregion Generated Reference Properties











}