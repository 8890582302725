import {vEnhancementGemfieldsRecordBase,} from '@app/database-models'
//Generated Imports
export class vEnhancementGemfieldsRecord extends vEnhancementGemfieldsRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}