import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPOSBrandRecordBase {

//#region brandId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'brandId', keyColumn: true})
        brandId : number;
//#endregion brandId Prop


//#region brandName Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'brandName', keyColumn: false})
        brandName : string;
//#endregion brandName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}