import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTripflagLookUpBase {

//#region tripflagId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'tripflagId', keyColumn: true})
        tripflagId : number;
//#endregion tripflagId Prop


//#region tripflagName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'tripflagName', keyColumn: false})
        tripflagName : string;
//#endregion tripflagName Prop

}