import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RingEggSizeBase {

//#region sizeId Prop
        @prop()
        sizeId : number;
//#endregion sizeId Prop


//#region size Prop
        @required()
        @notEmpty()
        @numeric({allowDecimal:true})
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @pattern({expression:{"size":/^[0-9]\d{0,13}(\.\d{1,2})?%?$/},message:"Please enter valid Size:(14 digits and 2 decimal places allowed)"})
        size : number;
//#endregion size Prop


//#region sizeType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        sizeType : number;
//#endregion sizeType Prop
      
//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

        @prop()
        @maxLength({value:20})
        inches:string;

        @prop()
        @maxLength({value:20})
        mm:string;

        @prop()
        @maxLength({value:20})
        ukSize:string;

        @prop()
        @maxLength({value:20})
        usSize:string;

        @prop({defaultValue: false})
        published:boolean;



}