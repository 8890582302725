import {AuditRecordDetailBase,AuditRecordBase,} from '@app/database-models'
//Generated Imports
export class AuditRecordDetail extends AuditRecordDetailBase 
{




//#region Generated Reference Properties
//#region auditRecord Prop
auditRecord : AuditRecordBase;
//#endregion auditRecord Prop

//#endregion Generated Reference Properties












}