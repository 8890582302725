import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vkeyRelationshipHolderLookUpBase {

//#region userId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion userId Prop


//#region username Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'username', keyColumn: false})
        username : string;
//#endregion username Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop

}