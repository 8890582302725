import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_FABERGE_ADVANCED_SEARCH_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onRedirectLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.id != null || this.id != undefined) {
                                if (this.category == "Design") {
                                    return "/product-design/" + this.id + "?activeTab=design-info";
                                }
                                else if (this.category == "Products") {
                                    return "/product/" + this.id + "?activeTab=product-info";
                                } else if (this.category == "Pieces") {
                                    return "/product-piece/" + this.id + "?activeTab=piece-info";
                                } else if (this.category == "People") {
                                    return "/users/" + this.id + "?activeTab=user-edit";
                                } else if (this.category == "Companies Group") {
                                    return "/suppliers/" + this.id + "?activeTab=supplier-edit";
                                }
                                else if (this.category == "Events") {
                                    return "/events/" + this.id + "?activeTab=event-info";
                                }
                                else if (this.category == "Projects") {
                                    return "/projects/" + this.id + "?activeTab=project-edit";
                                }
                                else if (this.category == "News") {
                                    return "/content-management-news/" + this.id + "?activeTab=news-edit";
                                }
                                else if (this.category == "Help & Training") {
                                    return "/help-and-training/" + this.id;
                                }
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.id != null || this.id != undefined) {
                                if (this.category == "Design") {
                                    return "/product-design/" + this.id + "?activeTab=design-info";
                                }
                                else if (this.category == "Products") {
                                    return "/product/" + this.id + "?activeTab=product-info";
                                } else if (this.category == "Pieces") {
                                    return "/product-piece/" + this.id + "?activeTab=piece-info";
                                } else if (this.category == "People") {
                                    return "/users/" + this.id + "?activeTab=user-edit";
                                } else if (this.category == "Companies Group") {
                                    return "/suppliers/" + this.id + "?activeTab=supplier-edit";
                                }
                                else if (this.category == "Events") {
                                    return "/events/" + this.id + "?activeTab=event-info";
                                }
                                else if (this.category == "Projects") {
                                    return "/projects/" + this.id + "?activeTab=project-edit";
                                }
                                else if (this.category == "News") {
                                    return "/content-management-news/" + this.id + "?activeTab=news-edit";
                                }
                                else if (this.category == "Help & Training") {
                                    return "/help-and-training/" + this.id;
                                }
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    },
};