import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDepartmentRecordBase {

//#region departmentId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'departmentId', keyColumn: true})
        departmentId : number;
//#endregion departmentId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region peopleType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'peopleType', keyColumn: false})
        peopleType : number;
//#endregion peopleType Prop

}