import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SettingBase {

//#region settingID Prop
        @prop()
        settingID : number;
//#endregion settingID Prop


//#region name Prop
        @required()
        @maxLength({value:200})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region value Prop
        @required()
        @notEmpty()
     //   @maxLength({value:2000})
        @trim()
        value : string;
//#endregion value Prop


//#region description Prop
        @required()
        @notEmpty()
        @trim()
        description : string;
//#endregion description Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}