import { FileTypeEnums, FileSizeEnums } from '../custom-enum/file-type-enums'

export enum INPUT_TYPE_ENUMS {
  TextBox = 1,
  CheckBox = 2,
  RadioButton = 3,
  Select = 4,
  File = 5
}
export enum dataKey_TYPE_ENUMS {
  CountrydataKey = 1,
  StatedataKey = 2
}
export const GLOBAL_ALL_SETTINGS_TYPE = [
  { "value": "Admin Settings", "key": 1, },
  { "value": "General", "key": 4, },
  { "value": "Gift cards", "key": 7, },
  { "value": "Return Requests", "key": 8, "description": "The returns system will allow your customers to request a return on items they've purchased. These are also known as RMA requests. <br/> NOTE: This option is available for completed orders." },
  { "value": "Reward Points", "key": 6, "description": "The Reward Points Program allows customers to earn points for certain actions they take on the site. Points are awarded based on making purchases and customer actions such as registration." },
  { "value": "Security", "key": 9, },
  { "value": "SEO/Display", "key": 5, },
  { "value": "Shipping Settings", "key": 2, },
  { "value": "Tax Settings", "key": 3, },
 







  { "value": "Other", "key": 10, },
]

export const GLOBAL_ALL_SETTINGS_KEYS = [
  {
    "label": "Internal Directory Diagram",
    "key": "Adminsettings.directoryfile",
    "type": INPUT_TYPE_ENUMS.File,
    "fileType": FileTypeEnums.Pdf,
    "fileSize": FileSizeEnums.FiveMB,
    "localLizationKey": "allSettingInternalDirectoryFile",
    //"placeHolderLocalLizationKey":"allSettingInternalDirectoryFile",
    "customPlaceHolder":"select only pdf file",
    "active": true,
    "order": 1,
    "dataKey": "",
    "settingType": 1
  },
  // {
  //   "label": "Fav icon",
  //   "key": "Admin.Favicon",
  //   "type": INPUT_TYPE_ENUMS.File,
  //   "fileType": FileTypeEnums.Image,
  //   "fileSize": FileSizeEnums.FiveMB,
  //   "localLizationKey": "allSettingInternalDirectoryFile",
  //   //"placeHolderLocalLizationKey":"allSettingInternalDirectoryFile",
  //   "active": true,
  //   "order": 1,
  //   "dataKey": "",
  //   "settingType": 1
  // },
  {
    "label": "Estimate shipping enabled",
    "key": "Shipping.EstimateShipping.Enabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingEstimateShipping",
    "active": true,
    "order": 1,
    "dataKey": "",
    "settingType": 2
  },
  {
    "label": "Free shipping over 'X'",
    "key": "Shipping.FreeShippingOverX.Enabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingFreeShippingOverX",
    "active": true,
    "order": 2,
    "dataKey": "",
    "settingType": 2
  },
  {
    "label": "Value of 'X'",
    "key": "Shipping.FreeShippingOverX.Value",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingFreeShippingOverXValue",
    "placeHolderLocalLizationKey": "allSettingFreeShippingOverXValue",
    "active": true,
    "order": 3,
    "dataKey": "",
    "settingType": 2
  },
  {
    "label": "Shipping Origin Country",
    "key": "Shipping.ShippingOrigin.CountryId",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingShippingOriginCountry",
    "active": true,
    "order": 4,
    "dataKey": "countryLookUps",
    "settingType": 2
  },
  {
    "label": "Shipping Origin State / Province",
    "key": "Shipping.ShippingOrigin.StateProvinceId",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingShippingOriginState",
    "active": true,
    "order": 5,
    "dataKey": "stateProvinceLookUps",
    "settingType": 2
  },
  {
    "label": "Shipping Origin Zip / Postal code",
    "key": "Shipping.ShippingOrigin.ZipPostalCode",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingShippingOriginZip",
    "placeHolderLocalLizationKey": "allSettingShippingOriginZip",
    "active": true,
    "order": 6,
    "dataKey": "",
    "settingType": 2
  },

  {
    "label": "Prices include tax",
    "key": "Tax.PricesIncludeTax",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingPricesIncludeTax",
    "active": true,
    "order": 1,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Allow customers to select tax display type",
    "key": "Tax.AllowCustomersToSelectTaxDisplayType",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingAllowCustomersToSelectTaxDisplayType",
    "active": true,
    "order": 2,
    "dataKey": "taxDisplayTypeLookUps",
    "settingType": 3
  },
  {
    "label": "Tax display type",
    "key": "Tax.TaxDisplayType",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingTaxDisplayType",
    "active": true,
    "order": 3,
    "dataKey": "taxDisplayTypeLookUps",
    "settingType": 3
  },
  {
    "label": "Display tax suffix",
    "key": "Tax.DisplayTaxSuffix",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingDisplayTaxSuffix",
    "active": true,
    "order": 4,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Display all applied tax rates",
    "key": "Tax.DisplayTaxRates",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingDisplayTaxRates",
    "active": true,
    "order": 5,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Hide zero tax",
    "key": "Tax.HideZeroTax",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingHideZeroTax",
    "active": true,
    "order": 6,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Hide tax in order summary",
    "key": "Tax.HideTaxInOrderSummary",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingHideTaxInOrderSummary",
    "active": true,
    "order": 7,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Tax based on",
    "key": "Tax.TaxBasedOn",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingDisplayTaxBasedOn",
    "active": true,
    "order": 8,
    "dataKey": "taxBasedOnLookUps",
    "settingType": 3
  },
  {
    "label": "Default Country",
    "key": "Tax.DefaultTaxAddress.CountryId",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingDefaultTaxAddressCountry",
    "active": true,
    "order": 9,
    "dataKey": "countryLookUps",
    "settingType": 3
  },
  {
    "label": "Default State / Province",
    "key": "Tax.DefaultTaxAddress.StateProvinceId",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingDefaultTaxAddressState",
    "active": true,
    "order": 10,
    "dataKey": "stateProvinceLookUps",
    "settingType": 3
  },
  {
    "label": "Default Zip / Postal code",
    "key": "Tax.DefaultTaxAddress.ZipPostalCode",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingDefaultTaxAddressZip",
    "placeHolderLocalLizationKey": "allSettingDefaultTaxAddressZip",
    "active": true,
    "order": 11,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Shipping is taxable",
    "key": "Tax.ShippingIsTaxable",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingShippingIsTaxable",
    "active": true,
    "order": 12,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Shipping price includes tax",
    "key": "Tax.ShippingPriceIncludesTax",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingShippingPriceIncludesTaxs",
    "active": true,
    "order": 13,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Shipping tax class",
    "key": "Tax.ShippingTaxClassId",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingShippingTaxClassId",
    "active": true,
    "order": 14,
    "dataKey": "taxCategoryLookUps",
    "settingType": 3
  },
  {
    "label": "Payment method additional fee is taxable",
    "key": "Tax.PaymentMethodAdditionalFeeIsTaxable",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingPaymentMethodAdditionalFeeIsTaxable",
    "active": true,
    "order": 15,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Payment method additional fee includes tax",
    "key": "Tax.PaymentMethodAdditionalFeeIncludesTax",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingPaymentMethodAdditionalFeeIncludesTax",
    "active": true,
    "order": 16,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Payment method additional fee tax class",
    "key": "Tax.PaymentMethodAdditionalFeeTaxClassId",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingPaymentMethodAdditionalFeeTaxClassId",
    "active": true,
    "order": 17,
    "dataKey": "taxCategoryLookUps",
    "settingType": 3
  },
  {
    "label": "EU VAT enabled",
    "key": "Tax.EUVat.Enabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingEUVatEnabled",
    "active": true,
    "order": 18,
    "dataKey": "",
    "settingType": 3
  },
  {
    "label": "Store name",
    "key": "Common.StoreName",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingStoreName",
    "placeHolderLocalLizationKey": "allSettingStoreName",
    "active": true,
    "order": 1,
    "settingType": 4
  },
  {
    "label": "Store URL",
    "key": "Common.StoreURL",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingStoreURL",
    "placeHolderLocalLizationKey": "allSettingStoreURL",
    "active": true,
    "order": 2,
    "settingType": 4
  },
  {
    "label": "Store closed",
    "key": "Common.StoreClosed",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingStoreClosed",
    "active": true,
    "order": 3,
    "settingType": 4
  },
  {
    "label": "Default title",
    "key": "SEO.DefaultTitle",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingDefaultTitle",
    "placeHolderLocalLizationKey": "allSettingDefaultTitle",
    "active": true,
    "order": 1,
    "settingType": 5
  },
  {
    "label": "Default meta description",
    "key": "SEO.DefaultMetaDescription",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingDefaultMetaDescription",
    "placeHolderLocalLizationKey": "allSettingDefaultMetaDescription",
    "active": true,
    "order": 2,
    "settingType": 5
  },
  {
    "label": "Default meta keywords",
    "key": "SEO.DefaultMetaKeywords",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingDefaultMetaKeywords",
    "placeHolderLocalLizationKey": "allSettingDefaultMetaKeywords",
    "active": true,
    "order": 3,
    "settingType": 5
  },
  // {
  //     "label": "Favicon",
  //     "key": "Common.StoreName",
  //     "type": INPUT_TYPE_ENUMS.TextBox,
  //     "localLizationKey": "allSettingFavicon",
  //     "active": true,
  //     "order": 4,
  //     "settingType": 5
  // },
  {
    "label": "Enabled",
    "key": "RewardPoints.Enabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingRewardPointsEnabled",
    "active": true,
    "order": 1,
    "settingType": 6
  },
  {
    "label": "Exchange rate",
    "key": "RewardPoints.Rate",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingRewardPointsRate",
    "placeHolderLocalLizationKey": "allSettingRewardPointsRate",
    "active": true,
    "order": 2,
    "settingType": 6
  },
  {
    "label": "Points for registration",
    "key": "RewardPoints.Earning.ForRegistration",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingRewardPointForRegistration",
    "placeHolderLocalLizationKey": "allSettingRewardPointForRegistration",
    "active": true,
    "order": 3,
    "settingType": 6
  },
  {
    "label": "Points for purchases Amount",
    "key": "RewardPoints.Earning.RewardPointsForPurchases.Amount",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingRewardPointsForPurchasesAmount",
    "placeHolderLocalLizationKey": "allSettingRewardPointsForPurchasesAmount",
    "active": true,
    "order": 4,
    "settingType": 6
  },
  {
    "label": "Points for purchases Point",
    "key": "RewardPoints.Earning.RewardPointsForPurchases.Points",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingRewardPointsForPurchasesPoint",
    "placeHolderLocalLizationKey": "allSettingRewardPointsForPurchasesPoint",
    "active": true,
    "order": 5,
    "settingType": 6
  },
  {
    "label": "Awarded order status",
    "key": "RewardPoints.Earning.RewardPointsForPurchases.AwardedOS",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingRewardPointsAwardedOS",
    "active": true,
    "order": 6,
    "dataKey": "orderStatusLookUps",
    "settingType": 6
  },
  {
    "label": "Canceled order status",
    "key": "RewardPoints.Earning.RewardPointsForPurchases.CanceledOS",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingRewardPointsCanceledOS",
    "active": true,
    "order": 7,
    "dataKey": "orderStatusLookUps",
    "settingType": 6
  },
  {
    "label": "Activation order status",
    "key": "GiftCards.Activation.ActivatedOS",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingRewardPointsActivatedOS",
    "active": true,
    "order": 1,
    "dataKey": "orderStatusLookUps",
    "settingType": 7
  },
  {
    "label": "Deactivation order status",
    "key": "GiftCards.Activation.DeactivatedOS",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingRewardPointsDeactivatedOS",
    "active": true,
    "order": 1,
    "dataKey": "orderStatusLookUps",
    "settingType": 7
  },
  {
    "label": "Enable Returns System",
    "key": "ReturnRequests.Enable",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingReturnRequestEnabled",
    "active": true,
    "order": 1,
    "settingType": 8
  },
  {
    "label": "Return reasons",
    "key": "ReturnRequests.ReturnReasons",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingReturnReasons",
    "placeHolderLocalLizationKey": "allSettingReturnReasons",
    "active": true,
    "order": 2,
    "settingType": 8
  },
  {
    "label": "Return action",
    "key": "ReturnRequests.ReturnActions",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingReturnActions",
    "placeHolderLocalLizationKey": "allSettingReturnActions",
    "active": true,
    "order": 3,
    "settingType": 8
  },
  {
    "label": "Admin area allowed IP",
    "key": "Security.AdminAreaAllowedIP",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingAdminAreaAllowedIP",
    "placeHolderLocalLizationKey": "allSettingAdminAreaAllowedIP",
    "active": true,
    "order": 1,
    "settingType": 9
  },
  {
    "label": "Encryption private key",
    "key": "Security.EncryptionPrivateKey",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingEncryptionPrivateKey",
    "placeHolderLocalLizationKey": "allSettingEncryptionPrivateKey",
    "active": true,
    "order": 2,
    "settingType": 9
  },
  {
    "label": "Login captcha image enabled",
    "key": "Common.LoginCaptchaImageEnabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingLoginCaptchaImageEnabled",
    "active": true,
    "order": 3,
    "settingType": 9
  },
  {
    "label": "Registration captcha image enabled",
    "key": "Common.RegisterCaptchaImageEnabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingRegisterCaptchaImageEnabled",
    "active": true,
    "order": 4,
    "settingType": 9
  },
  {
    "label": "Booking Approval Email",
    "key": "GemFieldsBookings.GemFieldsBookingApprovalEmail",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingGemFieldsBookingApprovalEmail",
    "placeHolderLocalLizationKey": "allSettingGemFieldsBookingApprovalEmail",
    "active": true,
    "order": 1,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Invoice Approval Email",
    "key": "Invoice.InvoiceApprovalEmail",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingInvoiceApprovalEmail",
    "placeHolderLocalLizationKey": "allSettingInvoiceApprovalEmail",
    "active": true,
    "order": 2,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Invoice Payment Approval Email",
    "key": "Invoice.InvoicePaymentApprovalEmail",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingInvoicePaymentApprovalEmail",
    "placeHolderLocalLizationKey": "allSettingInvoicePaymentApprovalEmail",
    "active": true,
    "order": 3,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "New Customer Registration Email",
    "key": "Common.NotifyNewCustomerRegistration",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingNotifyNewCustomerRegistration",
    "placeHolderLocalLizationKey": "allSettingNotifyNewCustomerRegistration",
    "active": true,
    "order": 4,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Customer Call me Now Email",
    "key": "Customer.CallMeNowNotification",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingCallMeNowNotification",
    "placeHolderLocalLizationKey": "allSettingCallMeNowNotification",
    "active": true,
    "order": 5,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Customer Price On Application Email",
    "key": "Customer.PriceOnApplicationNotification",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingPriceOnApplicationNotification",
    "placeHolderLocalLizationKey": "allSettingPriceOnApplicationNotification",
    "active": true,
    "order": 6,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Customer Login failed Email",
    "key": "Customer.LoginfailedNotification",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingLoginfailedNotification",
    "placeHolderLocalLizationKey": "allSettingLoginfailedNotification",
    "active": true,
    "order": 7,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Customer Login Attempt failed Email",
    "key": "Customer.LoginfailedNowNotification",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingLoginfailedNowNotification",
    "placeHolderLocalLizationKey": "allSettingLoginfailedNowNotification",
    "active": true,
    "order": 8,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "MakeToOrderNotification",
    "key": "Customer.MakeToOrderNotification",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingMakeToOrderNotification",
    "placeHolderLocalLizationKey": "allSettingMakeToOrderNotification",
    "active": true,
    "order": 9,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "'Usernames' enabled",
    "key": "Customer.UsernamesEnabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingUsernamesEnabled",
    "active": true,
    "order": 10,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Notify about new customer registration",
    "key": "Common.NotifyNewCustomerRegistration",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingNotifyAboutNewCustomerRegistration",
    "active": true,
    "order": 11,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Registration method",
    "key": "Common.CustomerRegistrationType",
    "type": INPUT_TYPE_ENUMS.Select,
    "localLizationKey": "allSettingCustomerRegistrationType",
    "active": true,
    "order": 12,
    "dataKey": "customerRegistrationTypeLookUps",
    "settingType": 10
  },
  {
    "label": "Allow navigation only for registered customers",
    "key": "Common.AllowNavigationOnlyRegisteredCustomers",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingAllowNavigationOnlyRegisteredCustomers",
    "active": true,
    "order": 13,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Min order sub-total amount",
    "key": "Order.MinOrderSubtotalAmount",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingMinOrderSubtotalAmount",
    "placeHolderLocalLizationKey": "allSettingMinOrderSubtotalAmount",
    "active": true,
    "order": 14,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": " Min order total amount",
    "key": "Order.MinOrderAmount",
    "type": INPUT_TYPE_ENUMS.TextBox,
    "localLizationKey": "allSettingMinOrderAmount",
    "placeHolderLocalLizationKey": "allSettingMinOrderAmount",
    "active": true,
    "order": 15,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Show discount box",
    "key": "Display.Checkout.DiscountCouponBox",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingDiscountCouponBox",
    "active": true,
    "order": 16,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Show gift card box",
    "key": "Display.Checkout.GiftCardBox",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingGiftCardBox",
    "active": true,
    "order": 17,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Show mini-purchase list",
    "key": "Common.ShowMiniShoppingCart",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingShowMiniShoppingCart",
    "active": true,
    "order": 18,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Is re-order allowed",
    "key": "Order.IsReOrderAllowed",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingIsReOrderAllowed",
    "active": true,
    "order": 19,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Use images for language selection",
    "key": "Common.UseImagesForLanguageSelection",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingUseImagesForLanguageSelection",
    "active": true,
    "order": 20,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Hide newsletter box",
    "key": "Display.HideNewsletterBox",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingHideNewsletterBox",
    "active": true,
    "order": 21,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "'Email a friend' enabled",
    "key": "Common.EnableEmailAFirend",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingEnableEmailAFirend",
    "active": true,
    "order": 22,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Allow anonymous users to email a friend",
    "key": "Common.AllowAnonymousUsersToEmailAFriend",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingAllowAnonymousUsersToEmailAFriend",
    "active": true,
    "order": 23,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Allow anonymous users to vote on polls",
    "key": "Common.AllowAnonymousUsersToVotePolls",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingAllowAnonymousUsersToVotePolls",
    "active": true,
    "order": 24,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
  {
    "label": "Display page execution time",
    "key": "Display.PageExecutionTimeInfoEnabled",
    "type": INPUT_TYPE_ENUMS.CheckBox,
    "localLizationKey": "allSettingPageExecutionTimeInfoEnabled",
    "active": true,
    "order": 25,
    "dataKey": dataKey_TYPE_ENUMS.CountrydataKey,
    "settingType": 10
  },
]
