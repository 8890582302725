import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PieceDeliveryStatusBase {

//#region pieceDeliveryStatusId Prop
        @prop()
        pieceDeliveryStatusId : number;
//#endregion pieceDeliveryStatusId Prop


//#region pieceDeliverySatus Prop
        @required()
        @maxLength({value:200})
        @trim()
        @notEmpty()
        pieceDeliverySatus : string;
//#endregion pieceDeliverySatus Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}