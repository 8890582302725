import {HSEStatusMappingBase,} from '@app/database-models'
//Generated Imports
export class HSEStatusMapping extends HSEStatusMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties



}