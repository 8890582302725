import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationObject } from '@app/models';
import { ModalView } from '../../../../domain/customize-design/modal';
import { access } from '@rxweb/angular-router';
import { Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
import { AbstractApplicationObject } from "../domain/abstract-application-objects";
import { vApplicationObjectTypeLookup } from "src/app/models/extended-models/v-application-object-type-look-up-base";
import * as CryptoJS from 'crypto-js';
@access({ accessLevel: RolePermissionEnums.ApplicationObject, action: "post" })

@multilingual("ApplicationObjectAddComponent")
@Component({
    selector: "app-application-objects-add",
    templateUrl: './application-objects-add.component.html'
})
export class ApplicationObjectAddComponent extends AbstractApplicationObject implements OnInit, OnDestroy {
    applicationObject: ApplicationObject;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    applicationObjectTypeLookup: vApplicationObjectTypeLookup;
    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private applicationBroadcaster: ApplicationBroadcaster, router: Router, modelView: ModalView, private el: ElementRef, private title: Title, private location: PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.ApplicationObject;
        this._this = this;
        this.router = router;
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Application Object - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");

        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

        this.lookup([
            { path: USER_LOOKUPS["applicationObjectTypeLookup"], propName: "applicationObjectTypeLookup" },
        ]).subscribe((lookup: any) => {
            this.applicationObjectTypeLookup = new vApplicationObjectTypeLookup();
            this.applicationObjectTypeLookup = lookup.applicationObjectTypeLookup;
        });


        this.applicationObjectFormGroup = this.formBuilder.formGroup(ApplicationObject) as IFormGroup<ApplicationObject>;
        this.showComponent = true;
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    addApplicationObject(isRedirect: boolean) {
        this.applicationObjectFormGroup.submitted = true;
        if (this.applicationObjectFormGroup.valid) {
            this.spin = true;
            this.post({ body: this.applicationObjectFormGroup.value }).subscribe((data) => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert[0].showMessage);
                }
                if(data){
                    isRedirect ? this.router.navigate(['/application-object']) : this.router.navigate(['/application-object',data])
                }
            })
        } else {
            for (const key of Object.keys(this.applicationObjectFormGroup.controls)) {
                if (this.applicationObjectFormGroup.controls[key].invalid) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    invalidControl.focus();
                    break;
                }
            }
        }
    }

    get componentName(): string {
        return "ApplicationObjectAddComponent";
    }
}
