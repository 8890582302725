import { Component, OnInit, OnDestroy, Input, ViewChild, HostListener, ViewContainerRef, ComponentFactoryResolver, ViewChildren, QueryList, ComponentRef, ComponentFactory } from "@angular/core";
import { AbstractNote } from "../domain/abstract-note";

import { Subscription } from "rxjs";
import {
  RxFormBuilder,
  IFormGroup,
  RxFormArray,
  IAbstractControl,
} from "@rxweb/reactive-form-validators";

import { Note, TaskDetail } from "@app/models";
import { List } from "@rxweb/generics";
import { NotesEnum } from "src/app/enums/notes.enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { MultiLingualData, multilingual } from "@rxweb/localization";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { v4 as uuidv4 } from "uuid";
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { TINY_MCE_KEY } from "src/app/domain/system-constant";
import { Router } from "@angular/router";
import { TinyMCEdraftModel } from "src/app/view-model/tiny-mce-draft-view-model";
import { NotePopUpType } from "src/app/enums/note-popup-type.enum";
import { FormArray, FormGroup } from "@angular/forms";
import { TaskDetailBase } from "@app/database-models";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { DatePipe, PlatformLocation } from "@angular/common";
import { TaskReplyComponent } from "../Task-Reply/task-reply.component";

@multilingual("NoteEditComponent")
@Component({
  selector: "app-note-edit",
  templateUrl: "./note-edit.component.html",
})
export class NoteEditComponent
  extends AbstractNote
  implements OnInit, OnDestroy {
  note: Note;

  subscription: any;
  @Input() id: number;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() supplierId: number;
  @Input() companyName: string;
  @Input() isReply: boolean = false;
  @Input() isTaskList: boolean = false;
  @Input() eventProjectRestricted: boolean = false;
  isRestricatedDisabled: boolean = false;
  hashTagArray: any[];
  userIdArray: any[];
  olduserIdArray: any[];
  isFromSalesOrder: boolean = false;
  checkAllUser: boolean = false;
  titleClose: string;
  expandComments : string
  collapseComments: string
  entityTypeIdForEditorDocuments = EditorDocumentsEnum.Notes;
  noteObject: Note;
  isRestrictedChanged: boolean = false;
  restrictedSubjectPrefix: string = "";
  apiKey: any = TINY_MCE_KEY;
  tinymceEditmodal: Array<TinyMCEdraftModel> = [];
  tinymceRetriveEditmodal: Array<TinyMCEdraftModel> = [];
  draftSaveInterval: any;
  _this;
  editNoteDraftId: number;
  replyNoteDraftId: number;
  NoteIdforReplyDraft: number;
  isDataUpdated: boolean = false;
  isFormDirty: boolean = false;
  formSubscription = null;
  showcomment: number | null = null;
  loggedInUserId: number = 0;
  taskStatus: List<any>;
  showColumns: boolean = false;
  showTinyMCEEditor: boolean = true;
  @ViewChild(EditorComponent, { static: false }) editorComponent: EditorComponent;
  @ViewChildren('commentDetailsRow', { read: ViewContainerRef }) commentDetailsRows: QueryList<ViewContainerRef>;
  @ViewChildren('commentDetailsDiv', { read: ViewContainerRef }) commentDetailsDivs: QueryList<ViewContainerRef>;
  commentRowViewRef: ViewContainerRef;
  commentDivViewRef: ViewContainerRef;
  isTaskReplyComponentVisible: boolean = false;
  noteDescriptionChangeDetect$: Subscription;
  isAllUserChangeDetetct$: Subscription;
  isViewByPeopleChangeDetect$: Subscription;
  toEmailsChangeDetect$: Subscription;
  isDisplayTeamMemberChangeDetect$: Subscription;
  ccEmailsChangeDetect$: Subscription;
  isfabergeUsersSelected: boolean = false;
  showToEmails: boolean = true;
  showCCEmails: boolean = true;
  subjectValueTemp: string;
  isProcessing: boolean = false;
  fromUserEmail: string = "";
  constructor(
    private formBuilder: RxFormBuilder,
    private browserStorage: BrowserStorage,
    private blobState: BlobUploadsViewStateService,
    private router: Router,
    private datePipe: DatePipe,
    private location: PlatformLocation, private componentFactoryResolver: ComponentFactoryResolver
  ) {
    super();
    this._this = this;


    this.loggedInUserId = this.browserStorage.local.get('userId');
    location.onPopState(() => { this.hide(), this.dialog.hide() });
    if (localStorage.getItem("userName"))
      this.loginUserName = this.browserStorage.local.get("userName", false);
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.expandComments = getLocalizedValue("Expand_Comments");
    this.collapseComments = getLocalizedValue("Collapse_Comments");
    this.restrictedSubjectPrefix = getLocalizedValue(
      "Note_RestrictedNoteSubjectPrefix"
    );
    this.restrictedBodyPrefix = getLocalizedValue("Note_RestrictedNoteMessage");
    this.userIdArray = new Array<any>();
    this.olduserIdArray = new Array<any>();
    this.hashTagArray = new Array<any>();
    this.userId = this.browserStorage.local.get("userId");
    this.fromUserEmail = this.browserStorage.local.get("userEmail",true);
    this.spin = false;
    this.post({
      body: { query: "{}" },
      path: "api/NoteDbLookups/NotesHasTagPeopleList",
    }).subscribe((respons: any) => {
      this.get({ params: [this.id], queryParams: { timezoneOffset: new Date().getTimezoneOffset() } }).subscribe(async (t) => {
        this.notesLookups = respons;
        this.notesLookups = this.notesLookups[0];
        this.toUserList = new List<any>(this.notesLookups.peopleList);
        this.ccUserList = new List<any>(this.notesLookups.peopleList);
        this.allUsers = new List<any>(this.notesLookups.peopleList);
        let note = t as Note;
        this.noteObject = t as Note;
        this.NoteIdforReplyDraft = this.noteObject.noteId;
        this.noteObject.noteId = this.isReply ? 0 : this.noteObject.noteId;
        let setNoteValues = this.setNoteFormGroupValues(note);
        note.toEmails = setNoteValues.toEmails;
        note.ccEmails = setNoteValues.ccEmails;
        note.hasTags = setNoteValues.hasTags;
        note.from = this.fromUserEmail;
        this.hashTagArray = this.notesLookups.hasTags;
        note.isViewByPeople =
          this.isReply == false ? note.isViewByPeople : false;
        this.checkAllUser =
          this.entityTypeId == NotesEnum.ProjectNotes
            ? (this.checkAllUser = true)
            : (this.checkAllUser = false);
        this.isFromSalesOrder =
          this.entityTypeId == NotesEnum.OrderNotes ? true : false;
        // note.isManual=this.isReply;
        this.isRestricatedDisabled = note.isDisplayTeamMember;
        if (this.isReply) {
          note.noteDescription = "";
        }
        if (this.eventProjectRestricted) {
          if (note.entityTypeId == NotesEnum.EventNotes) {
            this.restrictedEventProjectBodyPrefix = getLocalizedValue(
              "Note_Event_Restricted_PrefixBody"
            );
          } else {
            this.restrictedEventProjectBodyPrefix = getLocalizedValue(
              "Note_Project_Restricted_PrefixBody"
            );
          }
        }
        if (note.taskDetailMappings.length > 0 && !this.isReply) {
          this.isShowTask = true;
          this.isNoTaskFound = false;
          note.taskDetailMappings.forEach(x => {
            x.isStatusCompleted = x.isCompleted;
            x.isAssignedByLoggedIn = x.assignedByUserId == this.loggedInUserId;
            x.isAssignedToLoggedIn = x.assignedToUserId == this.loggedInUserId;
            if (x.createdOn != null) x.createdOn = new Date(x.createdOn + 'Z');
            if (x.completedAt != null) x.completedAt = new Date(x.completedAt + 'Z');
          })
        }
        else {
          note.taskDetailMappings = [];
          this.isNoTaskFound = true;
        }
        this.noteFormGroup = this.formBuilder.formGroup(
          Note,
          note
        ) as IFormGroup<Note>;
        this.subjectValueTemp = note.subject;
        if (this.isReply) {
          this.setNoteBody(note);
        }
        if (!this.isTaskList) {
          await this.getEditnoteDraft();
          this.showComponent = true;
          let loadTinyMce = setInterval(x => {
            if (document.querySelector(".tox-tinymce")) {
              this.isAllUserChangeDetetct$ = this.noteFormGroup.controls.isAllUser.valueChanges.subscribe(change => {
                this.isDataUpdated = true;
                this.isFormDirty = true;
              })
              this.isViewByPeopleChangeDetect$ = this.noteFormGroup.controls.isViewByPeople.valueChanges.subscribe(change => {
                this.isDataUpdated = true;
                this.isFormDirty = true;
              })
              this.toEmailsChangeDetect$ = this.noteFormGroup.controls.toEmails.valueChanges.subscribe(change => {
                this.isDataUpdated = true;
                this.isFormDirty = true;
              })
              this.ccEmailsChangeDetect$ = this.noteFormGroup.controls.ccEmails.valueChanges.subscribe(change => {
                this.isDataUpdated = true;
                this.isFormDirty = true;
              })
              this.isDisplayTeamMemberChangeDetect$ = this.noteFormGroup.controls.isDisplayTeamMember.valueChanges.subscribe(change => {
                this.isDataUpdated = true;
                this.isFormDirty = true;
              })
              this.noteDescriptionChangeDetect$ = this.noteFormGroup.controls.noteDescription.valueChanges.subscribe(change => {
                this.isDataUpdated = true;
                this.isFormDirty = true;
              })
              clearInterval(loadTinyMce)
            }

          }, 500)
          this.draftSaveInterval = setInterval(() => {
            if (this.isDataUpdated && this.isFormDirty) {
              this.saveEditNotedraft()
            }
          }, 15000)
        }
        else {
          this.showComponent = true;
        }
        setTimeout(() => {

          document.querySelectorAll('.comment-tr').forEach(row => {
            row.classList.add('d-none');
          })
        }, 0);

      });

    });
  }

  newTask() {
    var objTask = new TaskDetailBase();
    objTask.taskDetailId = 0;
    objTask.noteId = this.id;
    objTask.assignedByUserId = this.loggedInUserId;
    objTask.assignedByUserName = this.loginUserName;
    objTask.createdBy = this.loggedInUserId;
    objTask.createdOn = new Date();
    objTask.createdOnString = this.datePipe.transform(new Date(), "dd/MMM/yyyy HH:mm");
    objTask.isCompleted = false;
    return this.formBuilder.formGroup(TaskDetailBase, objTask) as IFormGroup<TaskDetailBase>;
  }
  addNewTask() {
    this.isShowTask = true;
    const taskDetailFormArray = this.noteFormGroup.get("taskDetailMappings") as FormArray;
    const newIndex = taskDetailFormArray.length;
    taskDetailFormArray.push(this.newTask())
    setTimeout(() => {
      this.ResetExpandCollapseButtons();

      document.querySelectorAll('.comment-tr').forEach(row => {
        row.classList.add('d-none');
      })
      document.querySelectorAll('#replybtn' + newIndex).forEach(row => {
        row.classList.add('d-none');
      })
    }, 0);
  }

  // statusChange(formGroup: IFormGroup<TaskDetailBase>){
  //   const fg = formGroup;
  //   fg.controls.taskStatusId.setValue(fg.value.taskStatusId == 1? 2:1)
  // }

  isDisableField(formGroup: IFormGroup<TaskDetailBase>) {
    const fg = formGroup;
    return (fg.value.isStatusCompleted || !fg.value.isAssignedByLoggedIn) && fg.value.taskDetailId > 0
  }

  isDisableCheck(formGroup: IFormGroup<TaskDetailBase>) {
    const fg = formGroup;
    return (!fg.value.isAssignedByLoggedIn && !fg.value.isAssignedToLoggedIn)
  }

  remove(index, taskDetail: IFormGroup<TaskDetailBase>) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        if (taskDetail.value.taskDetailId == 0) {
          if (this.taskDetailFormArray.length > 0) {
            this.isShowTask = false;
            const form = this.noteFormGroup.get('taskDetailMappings') as FormArray;
            form.removeAt(index);

            this.isShowTask = form.length != 0;
            //this.toastr.warning("Data Deleted Successfully");
          }
        }
        else {
          this.spin = true;
          this.delete({ path: 'api/TaskDetails', body: null, params: [taskDetail.value.taskDetailId] }).subscribe(data => {
            this.spin = false;
            this.toastr.success("Data Deleted Successfully");
            this.bindTaskData();
          });
        }
      }
      this.dialog.hide()
    })
  }

  onMoreClickComment(index, taskDetail: IFormGroup<TaskDetailBase>) {
    this.spin = true;

    this.ResetExpandCollapseButtons();

    const buttonElement = document.getElementById('replybtn'+index);
    if (buttonElement) {
      buttonElement.classList.add("d-none");
    }
    const buttonElement2 = document.getElementById('replybtnm'+index);
    if (buttonElement2) {
      buttonElement2.classList.remove('d-none');
    }

    if (this.commentDivViewRef) {
      this.commentDivViewRef.clear();
      this.commentDetailsRows.forEach(commentRowViewRef => {
        (<HTMLElement>commentRowViewRef.element.nativeElement).classList.add('d-none');
      });
      this.commentDivViewRef = this.commentDetailsDivs.find((divElement) => (divElement.element.nativeElement.id == index));
      this.commentRowViewRef = this.commentDetailsRows.find((rowElement) => (rowElement.element.nativeElement.id == 'comment-tr-' + index));

      if(this.commentRowViewRef){
        (<HTMLElement>this.commentRowViewRef.element.nativeElement).classList.remove('d-none');

      }
      if(this.commentDivViewRef){
        const childComponentRef: ComponentRef<TaskReplyComponent> = this.commentDivViewRef.createComponent(TaskReplyComponent);
        childComponentRef.instance.TaskId = taskDetail.value.taskDetailId;
      }
    }
    else {
      this.commentDivViewRef = this.commentDetailsDivs.find((divElement) => (divElement.element.nativeElement.id == index))
      this.commentRowViewRef = this.commentDetailsRows.find((rowElement) => (rowElement.element.nativeElement.id == 'comment-tr-' + index));
      if(this.commentRowViewRef){
        (<HTMLElement>this.commentRowViewRef.element.nativeElement).classList.remove('d-none');

      }
      if(this.commentDivViewRef){
        const childComponentRef: ComponentRef<TaskReplyComponent> = this.commentDivViewRef.createComponent(TaskReplyComponent);
        childComponentRef.instance.TaskId = taskDetail.value.taskDetailId;
      }

    }
    this.spin = false
  }

  hideCommentSection(index){
    // this.commentRowViewRef.clear();
    // this.commentDivViewRef.clear();
    setTimeout(() => {


      this.ResetExpandCollapseButtons();

      const buttonElement = document.getElementById('replybtn'+index);
      if (buttonElement) {
        buttonElement.classList.remove("d-none");
      }
      const buttonElement2 = document.getElementById('replybtnm'+index);
      if (buttonElement2) {
        buttonElement2.classList.add('d-none');
      }
      document.querySelectorAll('.comment-tr').forEach(row => {
        row.classList.add('d-none');
      })
    }, 0);

  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Note_Task");
    if (existsAlert) {
      return existsAlert;
    }
  }


  // bindTo() {
  //   return this.toUserList;
  // }

  bindTask() {
    return this.taskStatus;
  }

  saveTask(index, taskDetailFormGroup: IFormGroup<TaskDetailBase>) {
    this.isProcessing = true;
    taskDetailFormGroup.submitted = true;
    if (taskDetailFormGroup.valid) {
      this.spin = true;
      this.put({ path: 'api/TaskDetails', body: taskDetailFormGroup.value, params: [taskDetailFormGroup.value.taskDetailId] }).subscribe(data => {
        this.isProcessing = false;
        this.spin = false;
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
        this.bindTaskData();
      });
    }
    else {
      this.toastr.warning("Plese enter valid data");
    }
  }

  bindTaskData() {
    this.spin = true;
    this.isShowTask = false;
    var json = {
      noteId: this.id,
      timezoneOffset: new Date().getTimezoneOffset(),
      orderBy: 'taskDetailIdText',
      sortOrder: true
    }
    this.get({ path: 'api/TaskDetails', queryParams: { query: JSON.stringify(json) } }).subscribe((data: any) => {
      this.spin = false;
      const form = this.noteFormGroup.get('taskDetailMappings') as FormArray;
      form.clear();
      if (data.length > 0) {
        data.forEach(x => {
          x.isStatusCompleted = x.isCompleted;
          x.isAssignedByLoggedIn = x.assignedByUserId == this.loggedInUserId;
          x.isAssignedToLoggedIn = x.assignedToUserId == this.loggedInUserId;
          if (x.createdOn != null) x.createdOn = new Date(x.createdOn + 'Z');
          if (x.completedAt != null) x.completedAt = new Date(x.completedAt + 'Z');
          form.push(this.formBuilder.formGroup(TaskDetailBase, x) as IFormGroup<TaskDetailBase>);
        })
        this.isShowTask = true;
      }
      else {
        this.isShowTask = false;
        this.isNoTaskFound = true;
      }
      setTimeout(() => {

        document.querySelectorAll('.comment-tr').forEach(row => {
          row.classList.add('d-none');
        })
      }, 0);
    })
  }

  setNoteBody(noteData: Note) {
    if (this.eventProjectRestricted) {
      if (noteData.isDisplayTeamMember) {
        this.noteFormGroup.controls.noteDescription.setValue(
          '<p style="color:red;">' +
          (this.restrictedEventProjectBodyPrefix != undefined &&
            this.restrictedEventProjectBodyPrefix != ""
            ? this.restrictedEventProjectBodyPrefix
            : this.noteObject.entityTypeId == NotesEnum.EventNotes
              ? "Restricted Event"
              : "Restricted Project") +
          "</p>" +
          '<p style="color:red;">' +
          (this.restrictedBodyPrefix != undefined &&
            this.restrictedBodyPrefix != ""
            ? this.restrictedBodyPrefix
            : "Restricted Note") +
          "</p><br>" +
          this.noteFormGroup.controls.noteDescription.value
        );
      } else {
        this.noteFormGroup.controls.noteDescription.setValue(
          '<p id="restricted-event-project-notice" style="color:red;">' +
          (this.restrictedEventProjectBodyPrefix != undefined &&
            this.restrictedEventProjectBodyPrefix != ""
            ? this.restrictedEventProjectBodyPrefix
            : this.noteObject.entityTypeId == NotesEnum.EventNotes
              ? "Restricted Event"
              : "Restricted Project") +
          "</p><br>" +
          this.noteFormGroup.controls.noteDescription.value
        );
      }
    } else {
      if (noteData.isDisplayTeamMember) {
        this.noteFormGroup.controls.noteDescription.setValue(
          '<p style="color:red;">' +
          (this.restrictedBodyPrefix != undefined &&
            this.restrictedBodyPrefix != ""
            ? this.restrictedBodyPrefix
            : "Restricted Note") +
          "</p><br>" +
          this.noteFormGroup.controls.noteDescription.value
        );
      }
    }
  }
  getHashtags() {
    let hashTags = "";
    this.hashTagArray.forEach((x) => {
      hashTags = hashTags + "," + x.noteTypeName;
    });
    return hashTags;
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.noteDescriptionChangeDetect$) {
      this.noteDescriptionChangeDetect$.unsubscribe();
    }
    if (this.isViewByPeopleChangeDetect$) {
      this.isViewByPeopleChangeDetect$.unsubscribe();
    }
    if (this.toEmailsChangeDetect$) {
      this.toEmailsChangeDetect$.unsubscribe();
    }
    if (this.isAllUserChangeDetetct$) {
      this.isAllUserChangeDetetct$.unsubscribe();
    }
    if(this.ccEmailsChangeDetect$) {
      this.ccEmailsChangeDetect$.unsubscribe();
    }
    if (this.isDisplayTeamMemberChangeDetect$) {
      this.isDisplayTeamMemberChangeDetect$.unsubscribe();
    }
    clearInterval(this.draftSaveInterval);
  }

  async editNotes() {
    this.noteFormGroup.value.noteDescription = this.decodeEntities(this.noteFormGroup.value.noteDescription);
    if (this.isReply) {
      (<RxFormArray>this.noteFormGroup.get("noteMemberMappings")).clear();
      this.noteFormGroup.submitted = true;
      if (this.noteFormGroup.valid) {
        if (this.noteFormGroup.value.toEmails != null) {
          this.olduserIdArray = this.noteFormGroup.value.toEmails.split(",");
          //  this.userIdArray=this.noteFormGroup.value.toEmails.split(',');
          this.userIdArray = this.olduserIdArray.filter(
            (thing, i, arr) => arr.findIndex((t) => t === thing) === i
          );
          this.noteFormGroup.patchValue({
            toEmails: "",
          });
          var newToEmails = "";
          this.userIdArray.forEach((x) => {
            newToEmails += "," + x;
          });
          this.noteFormGroup.patchValue({
            toEmails: newToEmails.substring(1, newToEmails.length),
          });
        }

        if (this.noteFormGroup.value.ccEmails != null) {
          this.olduserIdArray = this.noteFormGroup.value.ccEmails.split(",");
          //  this.userIdArray=this.noteFormGroup.value.ccEmails.split(',');
          this.userIdArray = this.olduserIdArray.filter(
            (thing, i, arr) => arr.findIndex((t) => t === thing) === i
          );
          this.noteFormGroup.patchValue({
            ccEmails: "",
          });
          var newCCEmails = "";
          this.userIdArray.forEach((x) => {
            newCCEmails += "," + x;
          });
          this.noteFormGroup.patchValue({
            ccEmails: newCCEmails.substring(1, newCCEmails.length),
          });
        }

        this.bindTeamMembersMapping(this.formBuilder);
        this.noteFormGroup.patchValue({ isReplay: true });
        this.spin = true;

        if (this.noteFormGroup.value.file) {
          let fileDataObj = this.noteFormGroup.value.file;
          let fileNames: string[] = [];
          let fileUploadRes = [];
          try {
            // IF FAILED TO UPLOAD
            for (const x of this.noteFormGroup.value.file) {
              let newGUID = uuidv4();
              let fileName = `${newGUID}_${new Date().toISOString()}.${x.name
                .split(".")
                .pop()}`;
              var res = await this.blobState.uploadItems(x, fileName);
              fileUploadRes.push(res);
              fileNames.push(fileName);
            }

            if (fileUploadRes.includes(x => x._response.status != 200) && fileUploadRes.includes(res._response.status != 201)) {
              console.log(
                "Failed to upload file to blob from client!",
                fileUploadRes.find(x => x._response.status != 200 || res._response.status != 201).errorCode
              );
              this.noteFormGroup.patchValue({
                blobFileName: "",
                file: fileDataObj,
              });
            } else {
              // IF SUCCESS
              this.noteFormGroup.patchValue({
                blobFileName: fileNames.join(","),
              });
              this.callNotesPostAPI();
            }
          } catch (error) {
            console.log(error);
            this.noteFormGroup.patchValue({
              blobFileName: "",
              file: fileDataObj,
            });
          }
        } else {
          this.callNotesPostAPI();
        }
      }
    } else {
      this.noteFormGroup.submitted = true;
      this.noteFormGroup.value.noteDescription = this.decodeEntities(this.noteFormGroup.value.noteDescription);
      if (this.noteFormGroup.controls["noteDescription"].valid && this.noteFormGroup.controls["taskDetailMappings"].valid) {
        this.spin = true;
        this.put({
          params: [this.id],
          body: this.noteFormGroup.value,
        }).subscribe((t) => {
          this.spin = false;
          //this.toastr.success("Data Updated Successfully");
          this.deleteEditReplayNoteDraft();
          var existsAlert = getLocalizedValue("Data_Updated");
          if (existsAlert) {
            this.toastr.success(existsAlert);
          }
          // Fix FireFox Bug Ticket #259650
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        });
      }
    }
  }

  saveAsDraft(){
    if(this.isFormDirty && this.isDataUpdated){
      this.saveEditNotedraft();
      // this.isDataUpdated = false;
      this.isFormDirty = false;
    }
  }

  private callNotesPostAPI() {
    let fileDataObj = null;
    if (this.noteFormGroup.value.file) {
      fileDataObj = this.noteFormGroup.value.file;
      this.noteFormGroup.patchValue({
        file: null,
        supplierId:this.supplierId,
        companyName:this.companyName
      });
    }
    this.noteFormGroup.value.noteDescription = this.decodeEntities(this.noteFormGroup.value.noteDescription);
    this.bindTaskDetails();
    clearInterval(this.draftSaveInterval);
    this.post({ body: this.noteFormGroup.toFormData() }).subscribe(
      (t: { documentId: number; noteId: number }) => {
        this.spin = false;
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
        this.deleteEditReplayNoteDraft();

        // Fix FireFox Bug Ticket #259650
        this.showTinyMCEEditor = false;
        this.editorComponent.ngOnDestroy();
        this.hide();
      },
      (err) => {
        this.noteFormGroup.patchValue({
          file: fileDataObj,
        });
      }
    );
  }

  changeRestrictionType(checkbox) {
    var isRestricted = checkbox.target.value;
    if (this.eventProjectRestricted) {
      if (isRestricted == "true") {
        var noteBody = this.getEventProjectRestrictedNoteData(false);
        this.noteFormGroup.controls.noteDescription.setValue(
          `<p id="restricted-event-project-notice" style="color : red;">` +
          (this.restrictedEventProjectBodyPrefix != undefined &&
            this.restrictedEventProjectBodyPrefix != ""
            ? this.restrictedEventProjectBodyPrefix
            : "Restricted [Event/Project] Note") +
          "</p>" +
          '<p id="restricted-note-notice" style="color:red;">' +
          (this.restrictedBodyPrefix != undefined &&
            this.restrictedBodyPrefix != ""
            ? this.restrictedBodyPrefix
            : "Restricted Note") +
          "</p>" +
          noteBody
        );
        this.setNoteSubject(true);
      } else {
        var noteBody = this.getEventProjectRestrictedNoteData(true);
        this.noteFormGroup.controls.noteDescription.setValue(noteBody);
        this.setNoteSubject(false);
      }
    } else {
      if (isRestricted == "true") {
        this.noteFormGroup.controls.noteDescription.setValue(
          `<p id="restricted-note-notice" style="color:red;">` +
          (this.restrictedBodyPrefix != undefined &&
            this.restrictedBodyPrefix != ""
            ? this.restrictedBodyPrefix
            : "Restricted Note") +
          "</p>" +
          (this.noteFormGroup.value.noteDescription == "" ? "<br>" : "") +
          this.noteFormGroup.controls.noteDescription.value
        );
        this.setNoteSubject(true);
      } else if (isRestricted == "false") {
        var parser = new DOMParser();
        var doc = parser.parseFromString(
          this.noteFormGroup.value.noteDescription,
          "text/html"
        );
        var noticeElement = doc.getElementById("restricted-note-notice");
        if (noticeElement != null) {
          if (noticeElement.nextSibling.nodeName == "BR") {
            noticeElement.nextSibling.remove();
          }
          noticeElement.remove();
          this.noteFormGroup.controls.noteDescription.setValue(
            doc.getElementsByTagName("BODY")[0].innerHTML
          );
        }
        this.setNoteSubject(false);
      }
    }
  }
  getEventProjectRestrictedNoteData(restirectedNote: boolean) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(
      this.noteFormGroup.value.noteDescription,
      "text/html"
    );
    var noticeElement = doc.getElementById("restricted-event-project-notice");
    if (restirectedNote) {
      var restrictedElement = doc.getElementById("restricted-note-notice");
      if (restrictedElement != null) {
        if (restrictedElement.nextSibling) {
          if (restrictedElement.nextSibling.nodeName == "BR") {
            restrictedElement.nextSibling.remove();
          }
        }
        restrictedElement.remove();
        return doc.getElementsByTagName("BODY")[0].innerHTML;
      } else {
        return doc.getElementsByTagName("BODY")[0].innerHTML;
      }
    } else {
      if (noticeElement != null) {
        // if(noticeElement.nextSibling)
        // {
        //   if(noticeElement.nextSibling.nodeName == "BR")
        //   {
        //     noticeElement.nextSibling.remove();
        //   }
        // }
        noticeElement.remove();
        return doc.getElementsByTagName("BODY")[0].innerHTML;
      } else {
        return "";
      }
    }
  }
  setNoteSubject(restirectedNote: boolean) {
    if (restirectedNote) {
      let subjectVal = this.restrictedSubjectPrefix + this.noteFormGroup.controls.subject.value;
      if(this.entityTypeId == NotesEnum.LegalAgreementNotes){
        if(subjectVal.length>=250){
          subjectVal = subjectVal.substring(0, 247);
          subjectVal += '...';
        }
      }
      this.noteFormGroup.controls.subject.setValue(subjectVal);
    } else {
      if(this.entityTypeId == NotesEnum.LegalAgreementNotes){
        if (
          this.noteFormGroup.value.subject.indexOf(this.restrictedSubjectPrefix) >
          -1
        ) {
          this.subjectValueTemp = this.subjectValueTemp.replace(
            this.restrictedSubjectPrefix,
            ""
          );
          this.noteFormGroup.controls.subject.setValue(this.subjectValueTemp);
        }
      }
      else{
        if (
          this.noteFormGroup.value.subject.indexOf(this.restrictedSubjectPrefix) >
          -1
        ) {
          var newSubject = this.noteFormGroup.value.subject.replace(
            this.restrictedSubjectPrefix,
            ""
          );
          this.noteFormGroup.controls.subject.setValue(newSubject);
        }
      }

    }
  }
  get componentName(): string {
    return "NoteEditComponent";
  }

  ResetExpandCollapseButtons(){
    document.querySelectorAll('.replybtn').forEach(row => {
      row.classList.remove('d-none');
    })
    document.querySelectorAll('.replybtnm').forEach(row => {
      row.classList.add('d-none');
    })
  }

  hidePopup() {
    if(this.isFormDirty && !this.isTaskList)
    {
      if (this.isActualNoteDescriptionBlank(this.noteFormGroup, this.eventProjectRestricted, this.isReply) && (!this.isReply || !this.toEmailsChanged)) {
        if((this.isReply ? this.replyNoteDraftId : this.editNoteDraftId) > 0){
          this.delete({ path: "api/NoteDrafts", body: null, params: [this.isReply ? this.replyNoteDraftId : this.editNoteDraftId] }).subscribe((data) => {
            this.showTinyMCEEditor = false;
            this.editorComponent.ngOnDestroy();
            this.hide();
          })
        }
        else{
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        }
      }
      else{
        var multilingualData = MultiLingualData.get(this.componentName) as any;
        this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
        this.dialog.defaultConfig.showIcon = true;
        this.dialog.designClass.button.cancel = ["btn-cancle", "sweet-alert-cancel-button",];
        this.dialog.defaultConfig.text.heading = multilingualData["NoteAddEditComponent_CloseMessageTitle_t_t"];
        this.dialog.defaultConfig.text.ok = multilingualData["NoteAddEditComponent_CloseMessage_Yes_t_t"];
        this.dialog.defaultConfig.text.cancel = multilingualData["NoteAddEditComponent_CloseMessage_No_t_t"];
        this.dialog
          .show("", DialogViewMode.okWithCancel)
          .then((t) => {
            if (t === DialogEnum.Ok) {
              this.dialog.hide();

              // Fix FireFox Bug Ticket #259650
              if (!this.isTaskList) {
                this.showTinyMCEEditor = false;
                this.editorComponent.ngOnDestroy();
              }
              this.hide();
            }
            else {
              this.dialog.hide();
            }
          });
      }
    }
    else{
      if (!this.isTaskList) {
        this.showTinyMCEEditor = false;
        this.editorComponent.ngOnDestroy();
      }
      this.hide();
    }
  }



  /**
   * @getEditnoteDraft This function is called on OnInit.
   * - For NoteReply and NoteEdit this function will get the draft data by using note id and page type.
   * - This function patch data in form group if data is not null.
   */
  async getEditnoteDraft() {
    this.spin = true;
    if (this.isReply) {
      var data: TinyMCEdraftModel = await this.get<TinyMCEdraftModel>({ path: "api/NoteDrafts", queryParams: { EntityId: this.entityId, EntityTypeId: this.entityTypeId, ModuleId: NotePopUpType.Reply, Id: this.NoteIdforReplyDraft } }).toPromise();
      this.spin = false;
      if (data != null) {
        this.replyNoteDraftId = data.noteDraftId
        this.noteFormGroup.patchValue({ noteDescription: data.noteDescription });
        this.noteFormGroup.patchValue({ toEmails: data.sendTo });
        this.noteFormGroup.patchValue({ ccEmails : data.sendCc })
        this.noteFormGroup.patchValue({ subject: data.noteSubject });
        this.noteFormGroup.patchValue({ isDisplayTeamMember: data.isRestrict });
        this.noteFormGroup.patchValue({ isViewByPeople: data.isViewByPeople });
        this.noteFormGroup.patchValue({ isAllUser: data.addAllUserinProject });
        // this.isDataUpdated = true;
        // this.isFormDirty = true;
        this.subjectValueTemp = data.noteSubject;
        this.toEmailsForDraft = data.sendTo;
      }
      else{
        this.isDataUpdated = true;
      }
    }
    else {
      this.get({ path: "api/NoteDrafts", queryParams: { EntityId: this.entityId, EntityTypeId: this.entityTypeId, ModuleId: NotePopUpType.EditNote, Id: this.noteFormGroup.controls.noteId.value } }).subscribe((data: TinyMCEdraftModel) => {
        this.spin = false;
        if (data != null) {
          this.editNoteDraftId = data.noteDraftId
          this.noteFormGroup.patchValue({ noteDescription: data.noteDescription });
          // this.isDataUpdated = true;
          // this.isFormDirty = true;
        }
        else{
          this.isDataUpdated = true;
        }
      });
    }
  }

  selectFabergeUKUsers() {
    this.showToEmails = false;
    this.isfabergeUsersSelected = true;
    this.spin = true;
    this.post({
      body: { query: '{ "isfabergeUsersSelected" : true}' },
      path: "api/NoteDbLookups/NotesHasTagPeopleList",
    }).subscribe(async (res: any) => {
      this.toUserList = new List<any>();
      this.toUserList = new List<any>(res[0].peopleList);
      this.ccUserList = new List<any>();
      this.ccUserList = new List<any>(res[0].peopleList);
      this.noteFormGroup.controls.toEmails.setValue(res[0].fabergeUKUsers)
      this.spin = false;
      setTimeout(() => {
        this.showToEmails = true;
      }, 100);
    })
  }

  removeFabergeUKUsers() {
    this.showToEmails = false;
    this.isfabergeUsersSelected = false;
    this.toUserList = new List<any>();
    this.toUserList = new List<any>(this.notesLookups.peopleList);
    this.ccUserList = new List<any>();
    this.ccUserList = new List<any>(this.notesLookups.peopleList);
    this.noteFormGroup.controls.toEmails.setValue('');
    setTimeout(() => {
      this.showToEmails = true;
    }, 100);
  }

  /**
   * @setEditnoteDraft This function is called on Page close button and cross icon if any unsaved changes are found.
   * it will show one confiramation for draft save.
   * - on Save : it will store draft.
   * - on Discard : it will delete draft if found in Database.
   */
  setEditnoteDraft() {
    var multilingualData = MultiLingualData.get(this.componentName) as any;
    this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
    this.dialog.defaultConfig.showIcon = true;
    this.dialog.designClass.button.cancel = ["btn-cancle", "sweet-alert-cancel-button",];
    this.dialog.defaultConfig.text.heading = multilingualData["NoteAddEditComponent_DraftMessageTitle_t_t"];
    this.dialog.defaultConfig.text.ok = "Save as Draft";
    this.dialog.defaultConfig.text.cancel = "Close";
    this.dialog
      .show(multilingualData["NoteAddEditComponent_DraftMessageBody_t_t"], DialogViewMode.okWithCancel)
      .then((t) => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.saveEditNotedraft();

          // Fix FireFox Bug Ticket #259650
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        }
        else {
          this.dialog.hide();
          this.deleteEditReplayNoteDraft();

          // Fix FireFox Bug Ticket #259650
          this.showTinyMCEEditor = false;
          this.editorComponent.ngOnDestroy();
          this.hide();
        }
      });

  }

  /**
   * @saveEditNotedraft This function is called on Save button of confirmation pop-up and after every 15 sec if any unsaved changes are found in note.
   * - For reply it will store entityId, entityTypeId, notePopupType, sendTo, hashtags, noteSubject, isRestrict, noteId.
   * - For Edit it will store entityId, entityTypeId, notePopupType, noteDescription, noteId.
   * - after that it will assign draft id using respose data.
   */
  saveEditNotedraft() {
    if (this.isReply) {
      var Replydraft: TinyMCEdraftModel = {
        entityId: this.entityId,
        entityTypeId: this.entityTypeId,
        notePopupType: NotePopUpType.Reply,
        sendTo: this.noteFormGroup.controls.toEmails.value,
        sendCc: this.noteFormGroup.controls.ccEmails.value,
        hashtags: this.noteFormGroup.controls.hasTags.value,
        noteSubject: this.noteFormGroup.controls.subject.value,
        noteDescription: this.noteFormGroup.controls.noteDescription.value,
        isRestrict: this.noteFormGroup.controls.isDisplayTeamMember.value,
        noteId: this.NoteIdforReplyDraft,
        isViewByPeople: this.noteFormGroup.controls.isViewByPeople.value,
        addAllUserinProject: this.noteFormGroup.controls.isAllUser.value,
        toEmailsChanged: this.toEmailsChanged,
        hasBlankDescription: this.isActualNoteDescriptionBlank(this.noteFormGroup, this.eventProjectRestricted, this.isReply)
      }
      this.post({ path: "api/NoteDrafts", body: Replydraft }).subscribe((data: number) => {
        this.replyNoteDraftId = data;
        this.spin = false;
        this.isDataUpdated = data==0;
        this.isFormDirty = false;
      })
    }
    else {
      var draft: TinyMCEdraftModel = {
        entityId: this.entityId,
        entityTypeId: this.entityTypeId,
        notePopupType: NotePopUpType.EditNote,
        noteDescription: this.noteFormGroup.controls.noteDescription.value,
        noteId: this.noteFormGroup.controls.noteId.value,
        hasBlankDescription: this.isActualNoteDescriptionBlank(this.noteFormGroup, this.eventProjectRestricted, this.isReply)
      }
      this.post({ path: "api/NoteDrafts", body: draft }).subscribe((data: number) => {
        this.editNoteDraftId = data;
        this.spin = false;
        this.isDataUpdated = data==0;
        this.isFormDirty = false;
      });
    }
  }

  /**
   * @deleteEditReplayNoteDraft This function is called on Discard button, on edite note -> save button and on reply note -> save button.
   * - it will delete draft if draft id found.
   */
  deleteEditReplayNoteDraft() {
    if (this.isReply) {
      if (this.replyNoteDraftId) {
        this.delete({ path: "api/NoteDrafts", body: null, params: [this.replyNoteDraftId] }).subscribe((data) => { })
      }
    }
    else {
      if (this.editNoteDraftId) {
        this.delete({ path: "api/NoteDrafts", body: null, params: [this.editNoteDraftId] }).subscribe((data) => { })
      }
    }
    this.isDataUpdated = false;
    this.isFormDirty = false;
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    return false;
  }

  bindStatus() {
    return this.taskStatus;
  }

}

class StatusLookup {
  statusId: number;
  status: string;
}
