import { TemplateConfig } from '@rxweb/grid';
import { IANTypeEnum } from 'src/app/enums/i-a-n-type.enum';

export const IAN_MULTIPLE_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                // i: {
                //     class: ["row", "align-items-center", "font-normal"],
                //     childrens: [
                //         {
                //             input:
                //             {
                //                 style: {
                //                     "margin": "0px"
                //                 },
                //                 class: ["i-checks", "mr-2"],
                //                 event: {
                //                     input: "OnSelectNearMiss"
                //                 },
                //                 attributes: {
                //                     type: "checkbox",
                //                     value: function (e) {
                //                         return this[e.name];
                //                     },
                //                     //this.UserId
                //                     checked: function (e) {
                //                         // var a = this[e.name];
                //                         if (this.occuranceType != null) {
                //                             var occuranceSplit = this.occuranceType.split(",");
                //                             if (occuranceSplit.length > 0) {
                //                                 for (let index = 0; index < occuranceSplit.length; index++) {
                //                                     const element = occuranceSplit[index];
                //                                     if (element.toString() != "" && element == IANTypeEnum.NearMissUnSafeAct) {
                //                                         return true;
                //                                     }
                //                                     // else {
                //                                     //     return false;
                //                                     // }
                //                                 }
                //                             }
                //                             else {
                //                                 return false;
                //                             }
                //                         }
                //                         else {
                //                             return false;
                //                         }
                //                     },
                //                 }
                //             },
                //             span: {
                //                 childrens: [{
                //                     text: {
                //                         text: "Near-miss / Unsafe act",
                //                     },
                //                 }]
                //             },
                //         },
                //     ]
                // },
                // span: {
                //     class: ["row", "align-items-center"],
                //     childrens: [
                //         {
                //             input:
                //             {
                //                 style: {
                //                     "margin": "0px"
                //                 },
                //                 class: ["i-checks", "mr-2"],
                //                 event: {
                //                     input: "OnSelectAccident"
                //                 },
                //                 attributes: {
                //                     type: "checkbox",
                //                     value: function (e) {
                //                         return this[e.name];
                //                     },
                //                     checked: function (e) {
                //                         // var a = this[e.name];
                //                         if (this.occuranceType != null) {
                //                             var occuranceSplit = this.occuranceType.split(",");
                //                             if (occuranceSplit.length > 0) {
                //                                 for (let index = 0; index < occuranceSplit.length; index++) {
                //                                     const element = occuranceSplit[index];
                //                                     if (element.toString() != "" && element == IANTypeEnum.AccidentInjury) {
                //                                         return true;
                //                                     }
                //                                     // else {
                //                                     //     return false;
                //                                     // }
                //                                 }
                //                             }
                //                             else {
                //                                 return false;
                //                             }
                //                         }
                //                         else {
                //                             return false;
                //                         }
                //                     },
                //                 }
                //             },
                //             span: {
                //                 childrens: [{
                //                     text: {
                //                         text: "Accident / Injury / Fatality",
                //                     },
                //                 }]
                //             },
                //         }
                //     ]
                // },
                // div: {
                //     class: ["row", "align-items-center"],
                //     childrens: [
                //         {
                //             input:
                //             {
                //                 class: ["i-checks", "mr-2"],
                //                 event: {
                //                     input: "OnSelectIncident"
                //                 },
                //                 attributes: {
                //                     type: "checkbox",
                //                     value: function (e) {
                //                         return this[e.name];
                //                     },
                //                     checked: function (e) {
                //                         // var a = this[e.name];
                //                         if (this.occuranceType != null) {
                //                             var occuranceSplit = this.occuranceType.split(",");
                //                             if (occuranceSplit.length > 0) {
                //                                 for (let index = 0; index < occuranceSplit.length; index++) {
                //                                     const element = occuranceSplit[index];
                //                                     if (element.toString() != "" && element == IANTypeEnum.Incident) {
                //                                         return true;
                //                                     }
                //                                 }
                //                             }
                //                             else {
                //                                 return false;
                //                             }
                //                         }
                //                         else {
                //                             return false;
                //                         }
                //                     },
                //                 },

                //             },
                //             span: {
                //                 childrens: [{
                //                     text: {
                //                         text: "Incident",
                //                     },
                //                 }]
                //             },
                //         }
                //     ]
                // },
                label: {
                    //class: ["row", "align-items-center"],
                    childrens: [
                        {
                            input:
                            {
                                class: ["form-check-input", "checkbox-custom-style", "mr-2"],
                                style: {
                                    "margin-top": "-4px",
                                    "position": "relative",
                                    "margin-left": "auto",
                                },
                                event: {
                                    input: "OnSelectGrievance"
                                },
                                attributes: {
                                    type: "checkbox",
                                    value: function (e) {
                                        return this[e.name];
                                    },
                                    checked: function (e) {
                                        // var a = this[e.name];
                                        if (this.occuranceType != null) {
                                            var occuranceSplit = this.occuranceType.split(",");
                                            if (occuranceSplit.length > 0) {
                                                for (let index = 0; index < occuranceSplit.length; index++) {
                                                    const element = occuranceSplit[index];
                                                    if (element.toString() != "" && element == IANTypeEnum.Grievance) {
                                                        return true;
                                                    }
                                                }
                                            }
                                            else {
                                                return false;
                                            }
                                        }
                                        else {
                                            return false;
                                        }
                                    },
                                }
                            },
                            
                        }
                    ]
                }
            },
            // {
            //     i: {
            //         class: ["row", "align-items-center", "font-normal"],
            //         childrens: [
            //             {
            //                 input:
            //                 {
            //                     style: {
            //                         "margin": "0px"
            //                     },
            //                     class: ["i-checks", "mr-2"],
            //                     event: {
            //                         input: "OnSelectSensitive"
            //                     },
            //                     attributes: {
            //                         type: "checkbox",
            //                         value: function (e) {
            //                             return this[e.name];
            //                         },
            //                         checked: function (e) {
            //                              return this.sensitive;
            //                            },
            //                     }
            //                 },
            //                 span: {
            //                     childrens: [{
            //                         text: {
            //                             text: "Sensitive",
            //                         },
            //                     }]
            //                 },
            //             },
            //         ]
            //     },
            // }
        ],
        

    },
};

export const BIND_CHECKBOX_GRID_NOTIFICATION: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style"],
                  style: {
                    "margin-top": "-4px",
                    "position": "relative",
                    "margin-left": "auto",
                  },
                  event: {
                    input: "OnSelectSensitive"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      return this[e.name];
                    },
                    checked: function (e) {
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }

  export const BIND_IANGeneral_CHECKBOX_GRID_NOTIFICATION: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style"],
                  style: {
                    "margin-top": "-4px",
                    "position": "relative",
                    "margin-left": "auto",
                  },
                  event: {
                    input: "OnSelectGeneral"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      return this[e.name];
                    },
                    checked: function (e) {
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
