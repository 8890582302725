<!-- <div class="modal-dialog modal-lg" style="line-height:0.1;height:90%;overflow: auto;"> -->
<div class="modal fade show" id="app-project-event-mapping-add" tabindex="-1" role="dialog"
    [rxLocalisationInit]="componentName" style="padding-right: 17px; display: block;">
    <div class="modal-dialog modal-lg" style="line-height:0.1" *rxMultilingual="">
        <div class="modal-content" [rxLocalisationInit]="componentName">
            <div class="modal-header text-left">
                <h4 class="modal-title mb-0" rxText="ProjectEventMappingAddComponent_Title_t"></h4>
                <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                </button>
            </div>
            <div class="modal-body" [formGroup]="projectEventMappingFilterFormGroup" *ngIf="showComponent==true"
                (keyup.enter)="getProjectEventMappings()" [rxSpinner]="spin">
                <div [rxLocalisationInit]="componentName">
                    <!-- <div class="col-lg-6 col-xl-4 pr-xl-5"> -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-md-12 col-form-label" rxText="projectEventMappingProjectName"></label>
                                <div class="col-md-12">
                                    <input type="text" class="form-control" rxFocus formControlName="projectName"
                                        rxPlaceholder="projectEventMappingProjectName" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-12 col-form-label"
                                    rxText="projectEventMappingProjectEndDate"></label>
                                <div class="col-md-12">
                                    <!-- <div class="input-group date"> -->
                                    <rx-date showAddon="true" formControlName="projectEndDate"
                                        placeholder="Select End Date" pickerClass="form-control"></rx-date>
                                    <small class="form-text text-danger mt-2" *ngIf="dateError==true">End Date should be
                                        greater
                                        than Start Date<br /></small>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="col-md-12 col-form-label"
                                    rxText="projectEventMappingProjectStartDate"></label>
                                <div class="col-md-12">
                                    <!-- <div class="input-group date"> -->
                                    <rx-date showAddon="true" formControlName="projectStartDate"
                                        placeholder="Select Start Date" pickerClass="form-control"></rx-date>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- </div>

                        <div class="col-lg-6 col-xl-4 pr-xl-5"> -->

                    <!-- </div>


                         <div class="col-lg-6 col-xl-4 pr-xl-5"> -->

                    <!-- </div>   -->
                    <!-- <div lass="col-lg-6 col-xl-4 pr-xl-5"> -->
                    <div class="form-group row pull-right mr-3">
                        <button type="button" class="btn btn-success" (click)="getProjectEventMappings()"
                            rxText="Btn_Search_t"></button>
                    </div>
                    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                        <!-- <div class="dataTables_filter  d-flex flex-wrap align-items-center" *ngIf="totalRecords>0">
                                         <label class="ml-auto mr-0 mb-3" *ngIf="totalRecords>0">
                                            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4" placeholder="">
                                        </label>
                                        </div>  -->
                        <div class="table-responsive" *ngIf="isShowGrid">
                            <div [rxLocalisationInit]="componentName" class="dataTables_wrapper pb-0 dt-bootstrap4">
                                <rx-grid [design]="projectEventMappingGrid"></rx-grid>
                            </div>
                        </div>
                    </div>
                    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                        <h4>{{ErrorMsg}}</h4>
                    </div>

                    <!-- </div>   -->
                </div>
            </div>
            <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
                <button type="button" class="btn btn-success" (click)="save()"
                    [disabled]="(projectEventMapping.projectEventInput.length==0 && this.excludedProjectEvent.projectEventInput.length == 0 && !this.headerBtnChk)
                    || ((this.totalRecords - this.excludedProjectEvent.projectEventInput.length - this.totalRestrictedCount) == 0)"
                    rxText="Btn_Save_t"></button>
                <button (click)="hide()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
            </div>
        </div>
    </div>
</div>