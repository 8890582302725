<div
  class="row wrapper white-bg page-heading py-2 align-items-center"
  [rxLocalisationInit]="componentName"
  *rxMultilingual=""
  [rxSpinner]="spin"
>
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item">
        <a
          [routerLink]="['/language-content-keys']"
          rxText="Label_Localisation_t"
        ></a>
      </li>
      <li class="breadcrumb-item active">Add New</li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div
  class="wrapper wrapper-content"
  *ngIf="showComponent"
  [rxLocalisationInit]="componentName"
>
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5>
        <label rxText="LanguageContentKeyAddComponent_Title"></label>
      </h5>
      <a
        (click)="showSystemHelp()"
        class="float-right"
        [title]="titleHelp"
        *ngIf="showSystemHelpIcon"
      >
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div
      class="ibox-content bg-white d-flex flex-column flex-grow-1"
      [formGroup]="localizationFormGroup"
    >
      <div class="row">
        <div class="col-lg-12 col-xl-8 pr-xl-4">
          <!-- <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Select language</label>
                        <div class="col-md-8">
                            <select class="form-control">
                                <option [selected]="true" value="0">Select</option>
                                <option *ngFor="let item of localizationLookup.languageUserLookUp"
                                    [value]="item.languageId">
                                    {{item.languageName}}
                                </option>
                            </select>
                        </div>
                    </div> -->
          <div class="form-group row">
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_ResourceName_t"
            ></label>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                formControlName="keyName"
                rxFocus
                rxPlaceholder="LanguageContentKeyAddEditComponent_ResourceName_p"
              />
              <small
                class="form-text text-danger"
                [class.d-block]="localizationFormGroup.controls.keyName.errors"
                >{{
                  localizationFormGroup.controls.keyName.errorMessage
                }}</small
              >
            </div>
          </div>
          <div class="form-group row">
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_Component_t"
            ></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <rx-select [autoComplete]="true" mainClass="form-control" formControlName="id"
                                id="nameDropdown" [queryParams]="{'lookupType':'Component'}" [valueInQueryParams]="true"
                                [keyValueProps]="['#keyName#','id']" path="api/SearchEvent/GetLookups"
                                [minimumCharacterSearchLength]="3"
                                [enableFreeText]="true">
                            </rx-select> -->
              <!-- <select class="form-control"  formControlName="id">
                                <option [selected]="true" value="0">Select</option>
                                <option *ngFor="let item of localizationLookup.localizationComponents"
                                    [value]="item.id">{{item.keyName}}</option>
                            </select> -->
              <rx-select
                [(source)]="localizationLookup.localizationComponents"
                #rxSelect
                mainClass="form-control"
                formControlName="id"
                [keyValueProps]="['keyName', 'id']"
                [selectPlaceholder]="'Select Component'"
              ></rx-select>
              <small
                class="form-text text-danger"
                [class.d-block]="localizationFormGroup.controls.id.errors"
                >{{ localizationFormGroup.controls.id.errorMessage }}</small
              >
            </div>
          </div>
          <div class="form-group row">
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_ContentType_t"
            ></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control"
                                formControlName="contentType">
                                <option [selected]="true" value="0">Select</option>
                                <option value="t">Text </option>
                                <option value="gh">Grid Header </option>
                                <option value="p">Placeholder </option>
                                <option value="g">Server side validation </option>
                                <option value="v">Client side validation </option>
                            </select> -->
              <rx-select
                [(source)]="contentTypeArray"
                #rxselect
                mainClass="form-control"
                formControlName="contentType"
                [keyValueProps]="['value', 'id']"
                (changed)="changeContentType($event.item.key)"
                [selectPlaceholder]="'Select Content Type'"
              ></rx-select>
              <small
                class="form-text text-danger"
                [class.d-block]="
                  localizationFormGroup.controls.contentType.errors
                "
                >{{
                  localizationFormGroup.controls.contentType.errorMessage
                }}</small
              >
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="!isEditor"
            [rxLocalisationInit]="componentName"
          >
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_English_t"
            ></label>
            <div class="col-md-8">
              <textarea
                type="text"
                class="form-control"
                formControlName="en"
                rows="5"
                rxPlaceholder="LanguageContentKeyAddEditComponent_ValueEn_p"
              ></textarea>
              <small
                class="form-text text-danger"
                [class.d-block]="localizationFormGroup.controls.en.errors"
                >{{ localizationFormGroup.controls.en.errorMessage }}</small
              >
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="!isEditor"
            [rxLocalisationInit]="componentName"
          >
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_Mobile_t"
            ></label>
            <div class="col-md-8">
              <textarea
                type="text"
                class="form-control"
                formControlName="m_en"
                rows="5"
                rxPlaceholder="LanguageContentKeyAddEditComponent_ValueM_En_p"
              ></textarea>
              <small
                class="form-text text-danger"
                [class.d-block]="localizationFormGroup.controls.m_en.errors"
                >{{ localizationFormGroup.controls.m_en.errorMessage }}</small
              >
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="isEditor"
            [rxLocalisationInit]="componentName"
          >
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_English_t"
            >
            </label>
            <div class="col-md-8">
              <editor
                formControlName="en"
                tabindex="2"
                [init]="{
                  height: 400,
                  menubar: true,
                  automatic_uploads: false,
                  convert_urls: false,
                  relative_urls: false,
                  remove_script_host: false,
                  plugins:'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode fullscreen insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed mergetags tinymcespellchecker',
                  toolbar:'undo redo | blocks | bold italic backcolor | spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help ',
                  spellchecker_languages: 'UK English=en_GB',
                  statusbar: true,
                  menu: {
                    format: {
                      title: 'Format',
                      items: 'configurepermanentpen | bold italic underline strikethrough ' +
                        'superscript subscript codeformat | styles blocks fontfamily ' +
                        'fontsize align lineheight | forecolor backcolor | removeformat'
                    }
                  },
                  a11y_advanced_options: true,
                  allow_html_in_named_anchor: true,
                  content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                }"
                apiKey="{{ apiKey }}"
              >
              </editor>
              <small
                class="form-text text-danger"
                [class.d-block]="localizationFormGroup.controls.en.errors"
                >{{ localizationFormGroup.controls.en.errorMessage }}</small
              >
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="isEditor"
            [rxLocalisationInit]="componentName"
          >
            <label
              class="col-md-3 col-form-label"
              rxText="LanguageContentKeyAddEditComponent_Mobile_t"
            >
            </label>
            <div class="col-md-8">
              <editor
                formControlName="m_en"
                tabindex="2"
                [init]="{
                  height: 400,
                  menubar: true,
                  automatic_uploads: false,
                  convert_urls: false,
                  relative_urls: false,
                  remove_script_host: false,
                  plugins:'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode fullscreen insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed mergetags tinymcespellchecker',
                  toolbar:'undo redo | blocks | bold italic backcolor |spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help ',
                  spellchecker_languages: 'UK English=en_GB',
                  statusbar: true,
                  menu: {
                    format: {
                      title: 'Format',
                      items: 'configurepermanentpen | bold italic underline strikethrough ' +
                        'superscript subscript codeformat | styles blocks fontfamily ' +
                        'fontsize align lineheight | forecolor backcolor | removeformat'
                    }
                  },
                  a11y_advanced_options: true,
                  allow_html_in_named_anchor: true,
                  content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                }"
                apiKey="{{ apiKey }}"
              >
              </editor>
              <small
                class="form-text text-danger"
                [class.d-block]="localizationFormGroup.controls.m_en.errors"
                >{{ localizationFormGroup.controls.m_en.errorMessage }}</small
              >
            </div>
          </div>
          <!-- <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                             rxText="LanguageContentKeyAddEditComponent_French_t"></label>
                        <div class="col-md-8">
                            <textarea type="text" class="form-control"
                                formControlName="fr" rows="5" rxPlaceholder="LanguageContentKeyAddEditComponent_ValueFr_p"></textarea>
                        </div>
                    </div> -->
        </div>
      </div>
      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100"></div>
        <div class="col text-right">
          <button
            (click)="saveLocalization(false)"
            class="btn btn-success mr-1"
            rxText="Btn_Save_t"
          ></button>
          <button
            (click)="saveLocalization(true)"
            class="btn btn-primary mr-1"
            *rxAuthorize="[localizationEdit]"
            rxText="Btn_Save_Continue_Edit_t"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
