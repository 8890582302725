import {vDiscountLimitationLookUpBase,} from '@app/database-models'
//Generated Imports
export class vDiscountLimitationLookUp extends vDiscountLimitationLookUpBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}