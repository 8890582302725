import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ACLBase {

//#region aCLId Prop
        @prop()
        aCLId : number;
//#endregion aCLId Prop


//#region customerActionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerActionId : number;
//#endregion customerActionId Prop


//#region customerRoleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerRoleId : number;
//#endregion customerRoleId Prop


//#region allow Prop
        @required()
        allow : boolean;
//#endregion allow Prop





}