import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerChildRelationshipBase {

  //#region customerRelationshipChildId Prop
  @prop()
  customerRelationshipChildId: number;
  //#endregion customerRelationshipChildId Prop


  //#region customerRelationShipParentId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  customerRelationShipParentId: number;
  //#endregion customerRelationShipParentId Prop


  //#region customerRelationshipChildParentId Prop
  @prop()
  @required()
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  customerRelationshipChildParentId: number;
  //#endregion customerRelationshipChildParentId Prop


  //#region relationShipGenderId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  relationShipGenderId: number;
  //#endregion relationShipGenderId Prop


  //#region statusId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  statusId: number;
  //#endregion statusId Prop



}
