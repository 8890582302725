import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTicketEmailsTypesLookUpBase {

//#region ticketEmailsTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'ticketEmailsTypeId', keyColumn: true})
        ticketEmailsTypeId : number;
//#endregion ticketEmailsTypeId Prop


//#region ticketEmailsTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'ticketEmailsTypeName', keyColumn: false})
        ticketEmailsTypeName : string;
//#endregion ticketEmailsTypeName Prop

}