import { prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class localizationFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "keyName";
        this.languageId = 0;
        this.contentType = "0";
    }


    @prop()
    languageId: number;

    @prop()
    resourceName: string;

    @prop()
    resourceValue: string;

    @prop()
    contentType: string;

    @prop()
    id: number;

    @prop()
    componentText: string;
    
    @prop()
    rowCount: number;
  
    @prop()
    pageIndex: number;
  
    @prop()
    @trim()
    sortOrder: string;
  
    @prop()
    @trim()
    orderBy: string;

    @prop()
    searchString:string;
}
