import { vAgreementTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vAgreementTypeLookUp extends vAgreementTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}