import {ProductBatchPieceMappingLocalizationBase,} from '@app/database-models'
//Generated Imports
export class ProductBatchPieceMappingLocalization extends ProductBatchPieceMappingLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}