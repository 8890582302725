<div [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="SubProjectListComponent_Add_Button_t"></button>
    <label class="ml-auto mr-4">
      <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
        placeholder="" [title]="titleSearch">
    </label>
    <div class="ibox-tools" *ngIf="!isNoRecordFound">
      <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
          <i class="fa fa-cog"></i>
      </a>
      <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="subProjectsGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
          [moduleID]="moduleID" [selectedTab]="selectedTab">
      </app-system-table-setting>
  </div>
  </div>
  <div data-toggle="buttons"  *ngIf="isLongTextExists"
    class="btn-group btn-group-gray btn-group-toggle mb-1 d-flex flex-wrap justify-content-end">
    <ng-container *ngIf="!isShowAll" [rxLocalisationInit]="componentName">
      <button class="btn btn-success" (click)="showOrHideAll()"  rxText="Btn_Show_All_t"></button>
    </ng-container>
    <ng-container *ngIf="isShowAll" [rxLocalisationInit]="componentName">
      <button class="btn btn-success" (click)="showOrHideAll()"  rxText="Btn_Hide_All_t"></button>
    </ng-container>
  </div>
  <div class="table-responsive" *ngIf="isShowGrid">
    <div class="dataTables_wrapper pb-0 dt-bootstrap4" id="subProjectsGridData">
      <rx-grid [design]="subProjectsGrid"></rx-grid>
    </div>
  </div>
  <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>
</div>
