import {WarehouseBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
import { prop } from "@rxweb/reactive-form-validators"
export class Warehouse extends WarehouseBase {


    @prop()
    memberType: number;

    //#region Generated Reference Properties
//#region productPieceGemstones Prop
productPieceGemstones: ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop

//#endregion Generated Reference Properties












}