import { MaterialBase } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
//Generated Imports
export class Material extends MaterialBase implements FileDetailViewModel
{

//#region Generated Reference Properties

  @prop()
  fileData1: string;
  @prop()
  fileExtention1: string;
  @prop()
  fileName1: string;
  @prop()
  fileData2: string;
  @prop()
  fileExtention2: string;
  @prop()
  fileName2: string;
  @prop()
  fileData: string;
  @prop()
  fileExtention: string;
  @prop()
  fileName: string;
  @prop()
  fileData3: string;
  @prop()
  fileExtention3: string;
  @prop()
  fileName3: string;
  @prop()
  fileData4: string;
  @prop()
  fileExtention4: string;
  @prop()
  fileName4: string;
  @prop()
  displayImage: boolean;
  @prop()
  imageURL1: string;
  @prop()
  imageURL2: string;
  @prop()
  imageURL3: string;
  @prop()
  imageURL4: string;
  @prop()
  imageURL5: string;
  @prop({defaultValue: false})
  IsRemovedImage:boolean;
  @prop()
  blobFileName: string;
  @prop()
  blobPath: string;
  @prop()
  size: number;

//#endregion Generated Reference Properties

}