import { CoreComponent } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { ModalView } from 'src/app/domain/customize-design/modal';

export class AbstractHSEReport extends CoreComponent {
    toastr: BaseToastr;
    dialog: BaseDialog;
    isShowGrid: boolean;
    spin: boolean = false;
    modalView: ModalView;
    showComponent: boolean = false;
    isNoRecordFound : boolean;

    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
}
