import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { CoreComponent } from '@rxweb/angular-router';
import { TaskDetailsViewModel } from 'src/app/view-model/task-details-view-model';
import { PlatformLocation } from '@angular/common';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { TaskDetailBase } from '@app/database-models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { TaskReplyComponent } from 'src/app/components/note/notes/Task-Reply/task-reply.component';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';

@multilingual("TaskDetailAddComponent")
@Component({
  selector: 'app-task-detail-add',
  templateUrl: './task-detail-add.component.html'
})
export class TaskDetailAddComponent extends CoreComponent implements OnInit {
  @Input() taskDetail: TaskDetailsViewModel;
  @Input() loggedInUserId: number = 0;
  @Input() entityId: number = 0;
  @Input() entityTypeId: number = 0;
  @Input() hide: () => any;
  @ViewChildren('commentDetailsRow', { read: ViewContainerRef }) commentDetailsRows: QueryList<ViewContainerRef>;
  @ViewChildren('commentDetailsDiv', { read: ViewContainerRef }) commentDetailsDivs: QueryList<ViewContainerRef>;
  commentRowViewRef: ViewContainerRef;
  commentDivViewRef: ViewContainerRef;
  toastr: BaseToastr;
  _this;
  loggedInUserName:string = '';
  isProcessing: boolean = false;


  taskDetailFormGroup: IFormGroup<TaskDetailBase>
  constructor(private location: PlatformLocation, private formBuilder: RxFormBuilder, private componentFactoryResolver: ComponentFactoryResolver,private browserStorage: BrowserStorage) {
    super();
    this.toastr = new BaseToastr();
    location.onPopState(() => this.hide());
    this._this = this;
    if (localStorage.getItem("userName"))
      this.loggedInUserName = this.browserStorage.local.get("userName", false);
  }
  spin: boolean = false;
  showComponent: boolean = false;
  titleClose: string;
  isEditAccess: boolean = false;
  isCheckboxAccess: boolean = false;

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    var task = new TaskDetailBase();
    task.taskDetailId = 0;
    task.noteId = 0;
    task.taskDescription = '';
    task.assignedByUserId = this.loggedInUserId;
    task.assignedByUserName = this.loggedInUserName;
    task.createdBy = this.loggedInUserId;
    task.createdOn = new Date();
    task.isCompleted = false;
    task.entityId = this.entityId;
    task.entityTypeId = this.entityTypeId;
    this.taskDetailFormGroup = this.formBuilder.formGroup(TaskDetailBase, task) as IFormGroup<TaskDetailBase>;
    this.spin = false;
    this.showComponent = true;
  }

  saveTask() {
    this.taskDetailFormGroup.submitted = true;
    if (this.taskDetailFormGroup.valid) {
      this.spin = true;
      this.isProcessing = true;
      this.post({ path: 'api/TaskDetails', body: this.taskDetailFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = getLocalizedValue("Data_Added");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
        this.hide()
      });
    }
    else {
      this.toastr.warning("Plese enter valid data");
    }
  }


  get componentName(): string {
    return "TaskDetailAddComponent";
  }
}
