import { prop, propObject, propArray, required, maxLength, range, trim, email } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SupplierBase {

        //#region supplierId Prop
        @prop()
        supplierId: number;
        //#endregion supplierId Prop


        //#region supplierGUId Prop
        @required()
        @prop({ defaultValue: "2C846AF5-5D73-45F1-9915-0C0623C02913" })
        supplierGUId: any;
        //#endregion supplierGUId Prop


        //#region email Prop
        //@required()
        @maxLength({ value: 255 })
        @email()
        @trim()
        email: string;
        //#endregion email Prop


        //#region username Prop
        @maxLength({ value: 1000 })
        @prop({ defaultValue: "temp" })
        username: string;
        //#endregion username Prop


        //#region timeZoneId Prop
        @prop()
        timeZoneId: number;
        //#endregion timeZoneId Prop


        //#region affiliateId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        affiliateId: number;
        //#endregion affiliateId Prop


        //#region billingAddressId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        billingAddressId: number;
        //#endregion billingAddressId Prop


        //#region shippingAddressId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        shippingAddressId: number;
        //#endregion shippingAddressId Prop


        //#region lastPaymentMethodId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        lastPaymentMethodId: number;
        //#endregion lastPaymentMethodId Prop


        //#region lastAppliedCouponCode Prop
        @required()
        @maxLength({ value: 100 })
        @prop({ defaultValue: "temp" })
        lastAppliedCouponCode: string;
        //#endregion lastAppliedCouponCode Prop


        //#region giftCardCouponCodes Prop
        @required()
        @prop({ defaultValue: "temp" })
        giftCardCouponCodes: string;
        //#endregion giftCardCouponCodes Prop


        //#region checkoutAttributes Prop
        @required()
        @prop({ defaultValue: "temp" })
        checkoutAttributes: string;
        //#endregion checkoutAttributes Prop


        //#region languageId Prop
        @range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 7 })
        languageId: number;
        //#endregion languageId Prop


        //#region currencyId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        currencyId: number;
        //#endregion currencyId Prop


        //#region taxDisplayTypeId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        taxDisplayTypeId: number;
        //#endregion taxDisplayTypeId Prop


        //#region isTaxExempt Prop
        @required()
        @prop({ defaultValue: false })
        isTaxExempt: boolean;
        //#endregion isTaxExempt Prop


        //#region isAdmin Prop
        @required()
        @prop({ defaultValue: false })
        isAdmin: boolean;
        //#endregion isAdmin Prop


        //#region isGuest Prop
        @required()
        @prop({ defaultValue: false })
        isGuest: boolean;
        //#endregion isGuest Prop


        //#region isForumModerator Prop
        @required()
        @prop({ defaultValue: false })
        isForumModerator: boolean;
        //#endregion isForumModerator Prop


        //#region totalForumPosts Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        totalForumPosts: number;
        //#endregion totalForumPosts Prop


        //#region signature Prop
        @required()
        @maxLength({ value: 300 })
        @prop({ defaultValue: "temp" })
        signature: string;
        //#endregion signature Prop


        //#region adminComment Prop
        @required()
        @maxLength({ value: 4000 })
        @prop({ defaultValue: "temp" })
        adminComment: string;
        //#endregion adminComment Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region deleted Prop
        @required()
        @prop({ defaultValue: false })
        deleted: boolean;
        //#endregion deleted Prop


        //#region registrationDate Prop
        //@required()
        @prop()
        registrationDate: Date;
        //#endregion registrationDate Prop


        //#region avatarId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        @prop({ defaultValue: 0 })
        avatarId: number;
        //#endregion avatarId Prop


        //#region dateOfBirth Prop
        @prop()
        dateOfBirth: Date;
        //#endregion dateOfBirth Prop


        //#region registrationTypeId Prop
        @prop()
        registrationTypeId: number;
        //#endregion registrationTypeId Prop


        //#region salesAdvisorsId Prop
        //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        @prop({ defaultValue: 0 })
        salesAdvisorsId: number;
        //#endregion salesAdvisorsId Prop


        //#region roleTypeId Prop
        @prop()
        roleTypeId: number;
        //#endregion roleTypeId Prop


        //#region isBanOrders Prop
        @required()
        @prop({ defaultValue: false })
        isBanOrders: boolean;
        //#endregion isBanOrders Prop


        //#region lastLockoutDate Prop
        @prop()
        lastLockoutDate: Date;
        //#endregion lastLockoutDate Prop


        //#region failedPasswordAttemptCount Prop
        @prop()
        @prop({ defaultValue: 0 })
        failedPasswordAttemptCount: number;
        //#endregion failedPasswordAttemptCount Prop


        //#region introducesBy Prop
        @prop()
        @prop({ defaultValue: 0 })
        introducesBy: number;
        //#endregion introducesBy Prop


        //#region profileAbout Prop
        @prop()
        profileAbout: string;
        //#endregion profileAbout Prop


        //#region dietaryInfo Prop
        @maxLength({ value: 400 })
        dietaryInfo: string;
        //#endregion dietaryInfo Prop


        //#region salesAdvisorAdded Prop
        @prop()
        salesAdvisorAdded: Date;
        //#endregion salesAdvisorAdded Prop


        //#region introducedByAdded Prop
        @prop()
        introducedByAdded: Date;
        //#endregion introducedByAdded Prop


        //#region contactPermissionRequired Prop
        @required()
        @prop({ defaultValue: false })
        contactPermissionRequired: boolean;
        //#endregion contactPermissionRequired Prop


        //#region industryTypeId Prop
        @prop()
        industryTypeId: number;
        //#endregion industryTypeId Prop


        //#region anniversaryDate Prop
        @prop()
        anniversaryDate: Date;
        //#endregion anniversaryDate Prop


        //#region reportTo Prop
        @prop()
        @prop({ defaultValue: 0 })
        reportTo: number;
        //#endregion reportTo Prop


        //#region widgetState Prop
        @maxLength({ value: 150 })
        widgetState: string;
        //#endregion widgetState Prop


        //#region loginBlocked Prop
        @required()
        @prop({ defaultValue: false })
        loginBlocked: boolean;
        //#endregion loginBlocked Prop

        //#region dateOfCompanyRegistration Prop
        @prop()
        dateOfCompanyRegistration: Date
        //#endregion dateOfCompanyRegistration Prop

        //#region enableRating Prop
        @prop()
        enableRating: boolean
        //#endregion enableRating Prop
        
        //#region ratingStartDate Prop
        @prop()
        ratingStartDate: Date
        //#endregion ratingStartDate Prop

        //#region ratingEndDate Prop
        @prop()
        ratingEndDate: Date
        //#endregion ratingEndDate Prop

        //#region isSupplierUnwelcome Prop
        @required()
        @prop({ defaultValue: false })
        isSupplierUnwelcome: boolean;
        //#endregion isSupplierUnwelcome Prop
}
