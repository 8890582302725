import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RxHttp, http } from '@rxweb/http';
import { SummaryViewModel } from 'src/app/view-model/summary-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { CoreComponent } from '@rxweb/framework';
import { SummaryDetailViewModel } from 'src/app/view-model/summary-detail-view-model';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';
@multilingual("SummaryDetailsComponent")
@Component({
  selector: 'app-summary-details',
  templateUrl: './summary-details.component.html',
  styleUrls: ['./summary-details.component.css']
})
@http({
  path: 'api/SearchEvent/SummaryDetails'
})
export class SummaryDetailsComponent extends CoreComponent implements OnInit {
  @Input() inviteeType: number;
  @Input() eventId: number;
  @Input() addedBy: number;
  @Input() hide: Function;
  id: number;
  spin: boolean = false;
  userId: number;
  summaryDetailGrid: AppGrid;
  summaryList: SummaryDetailViewModel[];
  isFilter: boolean;
  totalRecords: number = 0;
  json: any;
  isShowGrid: boolean;
  isNoRecordFound: boolean
  titleClose: string;
  constructor(router: Router, private activatedRoute: ActivatedRoute,private location : PlatformLocation) {
    super();
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    });
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    //this.userId=Number(localStorage.getItem('userId'));
    this.titleClose = getLocalizedValue("Title_Close");
    this.isFilter = false;
    this.json = JSON.parse(JSON.stringify({ eventId: this.eventId, inviteeType: this.inviteeType, addedBy: this.addedBy, pageIndex: PaginationEnum.PageIndex, rowCount: PaginationEnum.RowCount, sortOrder: 'true', orderBy: 'fullName', searchString: null }));
    this.bindGrid();

  }

  bindGrid() {
    this.spin = true;
    this.isShowGrid = true;
    this.post({ body: { query: JSON.stringify(this.json) } }).subscribe((t: any) => {
      this.summaryList = t;
      this.spin = false;
      if (this.summaryDetailGrid != null) {
        this.isFilter = true;
      }
      if (this.summaryList.length > 0) {
        this.totalRecords = this.summaryList[0].totalCount;
        this.isNoRecordFound = false;
      }
      else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
      }
      if (!this.isFilter) {
        this.summaryDetailGrid = new AppGrid(this.summaryList, SummaryDetailViewModel);
        this.summaryDetailGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this)
        }
      }
      else {
        // this.summaryDetailGrid.updateSource([]);
        this.summaryDetailGrid.storeProcedure.length = this.totalRecords;
        this.summaryDetailGrid.updateSource(this.summaryList)
      }
      this.summaryDetailGrid.maxPerPage = this.json['rowCount'];
      this.summaryDetailGrid.componentId = "SummaryDetailsComponent";
    });
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json['sortOrder'] = y;
    this.json['orderBy'] = x;
    this.summaryDetailGrid.storeProcedure.nextPage = z;
    this.summaryDetailGrid.updateSource([]);
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json['pageIndex'] = x;
    this.json['rowCount'] = this.summaryDetailGrid.maxPerPage
    this.summaryDetailGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }

  search(event: any) {
    this.json['searchString'] = event.target.value;
    // this.isFilter=true;
    // this.summaryDetailGrid.updateSource([]);
    // this.bindGrid();

    this.isFilter = true;
    // if(!(event.keyCode>=37 && event.keyCode<=40) && event.keyCode!=9 && event.keyCode!=32){
    // if((event.target.value.replace(/ /g,'').length>2 && event.target.value!=undefined && event.target.value.replace(/ /g,'')!="") || (event.keyCode==8 && event.target.value=='')){

    setTimeout(() => {
      this.spin = false;
      this.json['searchString'] = event.target.value.replace(/^\s+|\s+$/gm, '');
      this.totalRecords = 0;
      this.json['pageIndex'] = 1;
      if (this.summaryDetailGrid != null) {
        this.summaryDetailGrid.storeProcedure.nextPage = 1;
        this.summaryDetailGrid.updateSource([]);
      }

      this.bindGrid();
    }, 4000);
    this.spin = true;
    // }
    //}
  }
  get componentName(): string {
    return "SummaryDetailsComponent";
  }
}
