<div class="modal-dialog modal-xl" [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-content">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="MoveNoteDocumentComp_Header_t"></h4>
            <button type="button" class="close py-2" (click)="closePopup()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="moveNoteDocumentFormGroup" (keyup.enter)="searchData()" *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
            <div class="row people_form_details mb-3">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row mb-0">
                        <label class="col-md-4 col-lg-4 col-xl-4 col-form-label col-form-label-first pl-3"
                            rxText="MoveNoteDocumentComp_Module_t"></label>
                        <div class="col-md-8 col-lg-8 col-xl-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.entityTypesLookup" #rxSelect
                                [controlTabIndex]="1" (changed)="getLookupData($event)"
                                formControlName="rootEntityTypeId" mainClass="form-control"
                                [keyValueProps]="['entityTypeName','entityTypeId']"
                                [selectPlaceholder]="'Select Module'">
                            </rx-select>
                            <small class="form-text text-danger" [class.d-block]="
                                moveNoteDocumentFormGroup.controls.rootEntityTypeId.errors
                                ">{{ moveNoteDocumentFormGroup.controls.rootEntityTypeId.errorMessage }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentUserFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.userModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_UserId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="userId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_UserId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentUserFilterViewModel.userId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentUserFilterViewModel.userId')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_UserEmail_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [autoComplete]="true" [controlTabIndex]="2" mainClass="form-control"
                                formControlName="email" [queryParams]="{'lookupType':'userEmail'}"
                                [valueInQueryParams]="true" path="api/SearchEvent/GetLookups"
                                [keyValueProps]="['#email#','id']" [minimumCharacterSearchLength]="2"
                                [enableFreeText]="true" (keyup)="checkEmailText($event)"
                                [selectPlaceholder]="'Select Email'">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_UserName_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1"
                                formControlName="name" id="nameDropdown" [queryParams]="{'lookupType':'IanUser'}"
                                [valueInQueryParams]="true" [keyValueProps]="['#name#','id']"
                                [selectPlaceholder]="'Select Name'" path="api/SearchEvent/GetLookups"
                                (keyup)="checkNameText($event)" (keyup.enter)="checkNameText($event)"
                                (changed)="onNameClick($event)" [text]="searchName" [minimumCharacterSearchLength]="2"
                                [enableFreeText]="true">
                            </rx-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_UserCountry_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.countryLookUp" #rxSelect
                                formControlName="country" mainClass="form-control" [controlTabIndex]="4"
                                [keyValueProps]="['countryName','countryId']"
                                [selectPlaceholder]="'Select Country'"></rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_UserKeyRelHolder_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.keyRelationshipHolderLookUp" #rxSelect
                                [controlTabIndex]="5" formControlName="keyRelationshipHolder" mainClass="form-control"
                                [keyValueProps]="['fullName','userId']"
                                [selectPlaceholder]="'Select Relationship Holder'">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_UserSalesAdv_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.salesAdvisorLookUp" #rxSelect
                                formControlName="salesAdvisor" mainClass="form-control" [controlTabIndex]="3"
                                [keyValueProps]="['fullName','userId']"
                                [selectPlaceholder]="'Select Sales Advisor'"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentCorporateFilterViewModel"
                [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.corporateModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_CorporateId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="supplierId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_CorporateId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentCorporateFilterViewModel.supplierId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentCorporateFilterViewModel.supplierId')?.errorMessage}}</small>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_CorporateIndustry_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [(source)]="moveNoteDocumentLookUps.industryTypeLookUp" #rxTag [controlTabIndex]="3"
                                [isSort]="false" formControlName="industryTypeIds" mainClass="form-control"
                                placeholder="Select Industry Type"
                                [keyValueProps]="['industryTypeName','industryTypeId']">
                            </rx-tag>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_CorporateName_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" placeholder="Enter Supplier Name"
                                formControlName="name" rxPlaceholder="MoveNoteDocumentComp_CorporateName_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_CorporateRole_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [selectPlaceholder]="'Select Corporate Role'"
                                [(source)]="moveNoteDocumentLookUps.supplierRoleLookUp" #rxSelect
                                mainClass="form-control" formControlName="supplierRoleId"
                                [keyValueProps]="['roleName','roleId']">
                            </rx-select>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_CorporateCountry_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [selectPlaceholder]="'Select Country'"
                                [(source)]="moveNoteDocumentLookUps.countryLookUp" #rxSelect mainClass="form-control"
                                formControlName="countryId" [keyValueProps]="['countryName','countryId']">
                            </rx-select>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_CorporateKeyRelHolder_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [selectPlaceholder]="'Select Key Relationship Holder'"
                                [(source)]="moveNoteDocumentLookUps.keyRelationshipHolderLookUp" #rxSelect
                                mainClass="form-control" formControlName="introducesBy"
                                [keyValueProps]="['fullName','userId']"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentEventFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.eventModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_EventId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="eventId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_EventId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentEventFilterViewModel.eventId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentEventFilterViewModel.eventId')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_EventStartDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" [controlTabIndex]="3" formControlName="eventStartDate"
                                        pickerClass="form-control" placeholder="Select Start Date"></rx-date>
                                    <!-- <rx-date class="w-100" showAddon="true" formControlName="eventStartDate" placeholder="" pickerClass="form-control"></rx-date> -->
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_EventName_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="2" class="form-control" formControlName="eventName"
                                rxPlaceholder="MoveNoteDocumentComp_EventName_p" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_EventEndDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" [controlTabIndex]="4" formControlName="eventEndDate"
                                        pickerClass="form-control" placeholder="Select End Date"></rx-date>
                                    <!-- <rx-date class="w-100" showAddon="true" formControlName="eventEndDate" placeholder="" pickerClass="form-control"></rx-date> -->
                                    <small class="form-text text-danger" *ngIf="eventDateError==true">Event End Date
                                        should
                                        be greater than Event Start Date<br /></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_EventOwner_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.eventOwnerLookUp" #rxSelect
                                mainClass="form-control" formControlName="eventOwner" [controlTabIndex]="5"
                                [keyValueProps]="['fullName','userId']" [selectPlaceholder]="'Select Event Owner'">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_EventType_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.eventTypeLookUp" #rxSelect
                                mainClass="form-control" formControlName="eventType" [controlTabIndex]="6"
                                [keyValueProps]="['eventTypeName','eventTypeId']"
                                [selectPlaceholder]="'Select Event Type'">
                            </rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentProjectFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.projectModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProjectId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="projectId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_ProjectId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentProjectFilterViewModel.projectId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentProjectFilterViewModel.projectId')?.errorMessage}}</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProjectName_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="2" class="form-control" formControlName="projectName"
                                rxPlaceholder="MoveNoteDocumentComp_ProjectName_p" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProjectStartDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" [controlTabIndex]="3" formControlName="projectStartDate"
                                        pickerClass="form-control" placeholder="Select Start Date"></rx-date>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProjectEndDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" [controlTabIndex]="4" formControlName="projectEndDate"
                                        pickerClass="form-control" placeholder="Select End Date"></rx-date>
                                    <small class="form-text text-danger" *ngIf="projectDateError==true">Project End Date
                                        should
                                        be greater than Project Start Date<br /></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProjectOwner_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.projectOwnerLookUp" #rxSelect
                                mainClass="form-control" formControlName="projectOwner" [controlTabIndex]="5"
                                [keyValueProps]="['fullName','userId']" [selectPlaceholder]="'Select Project Owner'">
                            </rx-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" formGroupName="moveNoteDocumentOrderFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.orderModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_OrderId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="orderId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_OrderId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentOrderFilterViewModel.orderId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentOrderFilterViewModel.orderId')?.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_OrderCustomerName_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="2" class="form-control" formControlName="customerName"
                                rxPlaceholder="MoveNoteDocumentComp_OrderCustomerName_p" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" formGroupName="moveNoteDocumentPressMediaFilterViewModel"
                [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.pressMediaModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_PressMediaId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="pressMediaId"
                                tabindex="1" rxPlaceholder="MoveNoteDocumentComp_PressMediaId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentPressMediaFilterViewModel.pressMediaId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentPressMediaFilterViewModel.pressMediaId')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaCorporates_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [source]="moveNoteDocumentLookUps.corporateLookup" #rxTag [controlTabIndex]="6"
                                formControlName="companyIds" mainClass="form-control" placeholder="Select Corporate"
                                [keyValueProps]="['company','companyId']" [isSort]="false"></rx-tag>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaPublications_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [source]="moveNoteDocumentLookUps.publicationLookup" #rxTag [controlTabIndex]="7"
                                formControlName="publicationIds" mainClass="form-control"
                                placeholder="Select Publication" [keyValueProps]="['company','companyId']"
                                [isSort]="false"></rx-tag>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaPublishedFrom_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" formControlName="publishedStartDate" [controlTabIndex]="2"
                                        pickerClass="form-control" placeholder="Select Published From"></rx-date>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaType_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [source]="moveNoteDocumentLookUps.hashtagLookup" #rxTag [controlTabIndex]="1"
                                formControlName="hashtags" mainClass="form-control"
                                placeholder="Select Press Media Type" [keyValueProps]="['typeName','typeId']"
                                [isSort]="false"></rx-tag>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaPeople_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [source]="moveNoteDocumentLookUps.peopleLookup" #rxTag [controlTabIndex]="4"
                                formControlName="userIds" mainClass="form-control" placeholder="Select People"
                                [keyValueProps]="['name','userId']" [isSort]="false"></rx-tag>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaJournalists_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [source]="moveNoteDocumentLookUps.journalistLookup" #rxTag [controlTabIndex]="5"
                                formControlName="journalistIds" mainClass="form-control" placeholder="Select Journalist"
                                [keyValueProps]="['name','userId']" [isSort]="false"></rx-tag>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PressMediaPublishedTo_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date showAddon="true" formControlName="publishedEndDate"
                                        pickerClass="form-control" [controlTabIndex]="3"
                                        placeholder="Select Published To"></rx-date>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" formGroupName="moveNoteDocumentLegalAgreementFilterViewModel"
                [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.legalAgreementModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_LADAgreementId_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <input type="text" class="form-control" tabindex="8" formControlName="legalAgreementId"
                                rxPlaceholder="MoveNoteDocumentComp_LADAgreementId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentLegalAgreementFilterViewModel.legalAgreementId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentLegalAgreementFilterViewModel.legalAgreementId')?.errorMessage}}</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_LADKeyword_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <input type="text" tabindex="1" formControlName="keyword" class="form-control"
                                rxPlaceholder="MoveNoteDocumentComp_LADKeyword_p" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_LADAgreementType_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.agreementTypeLookUp"
                                mainClass="form-control" [controlTabIndex]="2"
                                [selectPlaceholder]="'Select Agreement Type'" formControlName="agreementType"
                                [keyValueProps]="['agreementTypeName','agreementTypeId']">
                            </rx-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_LADLeader_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.gglEmployeesLookUp"
                                mainClass="form-control" [controlTabIndex]="3"
                                [selectPlaceholder]="'Select Internal Commercial Leader'" formControlName="leader"
                                [keyValueProps]="['name','id']">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_LADExpiryFrom_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" pickerClass="form-control" [controlTabIndex]="4"
                                formControlName="expiryDateFrom" placeholder="Select Expiry Date From">
                            </rx-date>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_LADExpiryTo_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" pickerClass="form-control" [controlTabIndex]="5"
                                formControlName="expiryDateTo" placeholder="Select Expiry Date To">
                            </rx-date>
                            <ng-container *ngIf="expiryDateError==true" [rxLocalisationInit]="componentName">
                                <small class="form-text text-danger" rxText="">
                                    Agreement ExpiryDate To
                                    should
                                    be greater than Agreement ExpiryDate From
                                </small>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentHSEFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.hseModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_IncidentId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="incidentId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_IncidentId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentHSEFilterViewModel.incidentId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentHSEFilterViewModel.incidentId')?.errorMessage}}</small>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_HSEStartDate_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-date pickerClass="" class="w-100" showAddon="true"
                                formControlName="registrationFrom" [controlTabIndex]="4"
                                placeholder="Enter Occurrence From Date" pickerClass="form-control">
                            </rx-date>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_HSERefNo_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <input type="text" formControlName="referenceNo" tabindex="1" class="form-control"
                                rxPlaceholder="MoveNoteDocumentComp_HSERefNo_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_HSEEndDate_t"></label>
                        <div class="col-md-8">
                            <rx-date class="w-100" showAddon="true" [controlTabIndex]="7"
                                formControlName="registrationTo" [controlTabIndex]="4"
                                placeholder="Enter Occurrence To Date" pickerClass="form-control">
                            </rx-date>
                            <small class="form-text text-danger" *ngIf="dateError==true">Registration To Date should be
                                greater
                                than Registration from Date<br /></small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_HSECompany_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [(source)]="moveNoteDocumentLookUps.genfieldsGroupEntity" #rxTag
                                [controlTabIndex]="13" formControlName="supplierId" [isSort]="false"
                                placeholder="Select Gemfields Group Entity" mainClass="form-control"
                                [keyValueProps]="['company','supplierId']"></rx-tag>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentRiskFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.riskModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_RiskId_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="1" class="form-control" formControlName="riskId"
                                rxPlaceholder="MoveNoteDocumentComp_RiskId_p">
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentRiskFilterViewModel.riskId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentRiskFilterViewModel.riskId')?.errorMessage}}</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_RiskCompany_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.gemfieldGroupCompanyLookUp" #rxSelect
                                mainClass="form-control" [controlTabIndex]="3" formControlName="gemfieldGroupCompanyId"
                                [selectPlaceholder]="'Select Gemfields Group'"
                                [keyValueProps]="['company','supplierId']">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_RiskLocalOwner_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.localRiskOwnerLookUp" #rxSelect
                                mainClass="form-control" [controlTabIndex]="2" formControlName="localRiskOwnerId"
                                [selectPlaceholder]="'Select Local Risk Owner'"
                                [keyValueProps]="['fullName','localRiskOwner']"></rx-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_RiskBoardOwner_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.boardRiskOwnerLookUp" #rxSelect
                                mainClass="form-control" [controlTabIndex]="4" formControlName="boardRiskOwnerId"
                                [selectPlaceholder]="'Select Board Risk Owner'"
                                [keyValueProps]="['fullName','boardRiskOwner']"></rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_RiskCategory_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.riskCategoryLookUp" #rxSelect
                                mainClass="form-control" [controlTabIndex]="5" formControlName="riskCategoryId"
                                [selectPlaceholder]="'Select Strategic Risk Category'"
                                [keyValueProps]="['riskCategoryName','riskCategoryId']"></rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_RiskType_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.specificRiskLookUp" #rxSelect
                                mainClass="form-control" [controlTabIndex]="6" formControlName="specificRiskId"
                                [selectPlaceholder]="'Select Risk Type'"
                                [keyValueProps]="['specificRiskName','specificRiskId']"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentITHelpDeskFilterViewModel"
                [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.itHelpDeskModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_TicketId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="ticketId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_TicketId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentITHelpDeskFilterViewModel.ticketId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentITHelpDeskFilterViewModel.ticketId')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ITHDTicketStatus_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag [(source)]="moveNoteDocumentLookUps.ticketStatus" #rxTag mainClass="form-control"
                                formControlName="statusId" [keyValueProps]="['value','id']" [controlTabIndex]="4"
                                [isSort]="false" placeholder="Select Status">
                            </rx-tag>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ITHDIncidentNo_t"></label>
                        <div class="col-md-8">
                            <input type="text" formControlName="ticketNo" class="form-control"
                                rxPlaceholder="MoveNoteDocumentComp_ITHDIncidentNo_p" tabindex="1" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ITHDTicketType_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select [(source)]="moveNoteDocumentLookUps.ticketIncType" #rxSelect
                                mainClass="form-control" formControlName="iNCTypeId"
                                [keyValueProps]="['incTypeName','incTypeId']" [controlTabIndex]="2"
                                [selectPlaceholder]="'Select Ticket Type'">
                            </rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentPieceFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.piecesModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_PieceId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="productVariantBatchPieceId"
                                tabindex="1" rxPlaceholder="MoveNoteDocumentComp_PieceId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentPieceFilterViewModel.productVariantBatchPieceId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentPieceFilterViewModel.productVariantBatchPieceId')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PieceProdTitle_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" tabindex="1" formControlName="title"
                                rxPlaceholder="MoveNoteDocumentComp_PieceProdTitle_p" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PieceNo_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="7" class="form-control" formControlName="pieceNo"
                                rxPlaceholder="MoveNoteDocumentComp_PieceNo_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PieceLocation_t"></label>
                        <div class="col-md-8" [rxTagExtended]="_this">
                            <rx-tag #rxTag [source]="moveNoteDocumentLookUps.warehouseLookUp" [controlTabIndex]="13"
                                formControlName="location" mainClass="form-control" placeholder="Select Location"
                                [keyValueProps]="['warehouseName','warehouseId']" [isSort]="false"></rx-tag>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PieceCategory_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.categoryLookup"
                                [controlTabIndex]="4" mainClass="form-control" formControlName="categoryId"
                                [selectPlaceholder]="'All'" [keyValueProps]="['categoryName','categoryId']"></rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PieceSalesCh_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [source]="moveNoteDocumentLookUps.salesChannelLookups"
                                [controlTabIndex]="13" formControlName="salesChannel" mainClass="form-control"
                                [selectPlaceholder]="'Select Sales Channel'"
                                [keyValueProps]="['salesChannelName','salesChannelId']" [isSort]="false"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentProductFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.productModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_ProductId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="productVariantId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_ProductId_p" />
                                <small class="form-text text-danger"
                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentProductFilterViewModel.productVariantId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentProductFilterViewModel.productVariantId')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProductCategory_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.productSearchCategoryLookup"
                                [controlTabIndex]="4" mainClass="form-control" formControlName="categoryId"
                                [selectPlaceholder]="'All'" [keyValueProps]="['categoryName','categoryId']"></rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProductTitle_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="1" class="form-control" formControlName="title"
                                rxPlaceholder="MoveNoteDocumentComp_ProductTitle_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ProductCollection_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.collectionLookup"
                                [controlTabIndex]="6" mainClass="form-control" formControlName="collectionId"
                                [selectPlaceholder]="'All'"
                                [keyValueProps]="['name','specificationAttributeOptionId']"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentGemstoneFilterViewModel"
                [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.gemstoneModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_GemstoneId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" tabindex="1" formControlName="gemstoneId"
                                rxPlaceholder="MoveNoteDocumentComp_GemstoneId_p" />
                                <small class="form-text text-danger"
                                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentGemstoneFilterViewModel.gemstoneId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentGemstoneFilterViewModel.gemstoneId')?.errorMessage}}</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_GemstoneType_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.productGemstonetypeLookUps"
                                [controlTabIndex]="4" mainClass="form-control" formControlName="gemstoneType"
                                [selectPlaceholder]="'Select Gemstone Type'"
                                [keyValueProps]="['categoryName','categoryId']">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_GemstoneStartDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date class="w-100" showAddon="true" formControlName="createdDateFrom"
                                        [controlTabIndex]="15" placeholder="Enter Created Date From"
                                        pickerClass="form-control">
                                    </rx-date>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_GemstoneEndDate_t"></label>
                        <div class="col-md-8">
                            <div class="" id="data_1">
                                <div class="input-group date">
                                    <rx-date class="w-100" showAddon="true" formControlName="createdDateTo"
                                        [controlTabIndex]="15" placeholder="Enter Created Date To"
                                        pickerClass="form-control">
                                    </rx-date>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_GemstoneLocation_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.warehouseLookUp"
                                mainClass="form-control" formControlName="location"
                                [selectPlaceholder]="'Select Location'" [controlTabIndex]="11"
                                [keyValueProps]="['warehouseName','warehouseId']">
                            </rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentBookingFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.bookingsModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BookingId_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="1" class="form-control" formControlName="bookingId"
                                rxPlaceholder="MoveNoteDocumentComp_BookingId_p" />
                                <small class="form-text text-danger"
                                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentBookingFilterViewModel.bookingId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentBookingFilterViewModel.bookingId')?.errorMessage}}</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BookingStartDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" pickerClass="form-control" [controlTabIndex]="3"
                                formControlName="bookingStartDate" placeholder="Select Date"></rx-date>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BookingName_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="2" class="form-control" formControlName="bookingName"
                                rxPlaceholder="MoveNoteDocumentComp_BookingName_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BookingEndDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" pickerClass="form-control" [controlTabIndex]="4"
                                formControlName="bookingEndDate" placeholder="Select Date"></rx-date>
                            <small class="form-text text-danger" *ngIf="bookingDateError==true">Booking End Date should
                                be
                                greater than
                                Booking Start Date<br /></small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_ResponsiblePer_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.usersLookUp"
                                mainClass="form-control" [controlTabIndex]="6" formControlName="responsiblePersons"
                                [selectPlaceholder]="'Select Responsible Person'"
                                [keyValueProps]="['fullName','userId']"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentDesignFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.designModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_DesignId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="designId"
                                rxPlaceholder="MoveNoteDocumentComp_DesignId_p" />
                                <small class="form-text text-danger"
                                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentDesignFilterViewModel.designId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentDesignFilterViewModel.designId')?.errorMessage}}</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_DesignTitle_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="title"
                                rxPlaceholder="MoveNoteDocumentComp_DesignTitle_p" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_DesignCategory_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.categoryLookup"
                                [selectPlaceholder]="'Select Category'" mainClass="form-control"
                                formControlName="categoryId"
                                [keyValueProps]="['categoryName','categoryId']"></rx-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_DesignStatus_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.designStatusLookup"
                                [selectPlaceholder]="'Select Design Status'" mainClass="form-control"
                                formControlName="statusId"
                                [keyValueProps]="['designStatusName','designStatusId']"></rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_DesignCollection_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.collectionLookup"
                                [selectPlaceholder]="'Select Collection'" mainClass="form-control"
                                formControlName="collectionId"
                                [keyValueProps]="['name','specificationAttributeOptionId']"></rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentPricelistFilterViewModel"
                [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.priceListModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="MoveNoteDocumentComp_PricelistId_t"></label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" formControlName="tripId" tabindex="1"
                                rxPlaceholder="MoveNoteDocumentComp_PricelistId_p" />
                                <small class="form-text text-danger"
                                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentPricelistFilterViewModel.tripId')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentPricelistFilterViewModel.tripId')?.errorMessage}}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">

                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PriceListCreatedBy_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.createdBy" mainClass="form-control"
                                formControlName="createdBy" [keyValueProps]="['fullName','createdBy']"
                                [selectPlaceholder]="'Select Created By'">
                            </rx-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_PriceListCreatedBy_t"></label>
                        <div class="col-md-8" [rxSelectExtended]="_this">
                            <rx-select #rxSelect [(source)]="moveNoteDocumentLookUps.createdBy" mainClass="form-control"
                                formControlName="createdBy" [keyValueProps]="['fullName','createdBy']"
                                [selectPlaceholder]="'Select Created By'">
                            </rx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" formGroupName="moveNoteDocumentBatchFilterViewModel" [rxLocalisationInit]="componentName"
                *ngIf="moveNoteDocumentFormGroup.controls.rootEntityTypeId.value == modouleIdObj.batchesModuleId">
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BatchNo_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="7" class="form-control" formControlName="batchNo"
                                rxPlaceholder="MoveNoteDocumentComp_BatchNo_p" />
                                <small class="form-text text-danger"
                                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentBatchFilterViewModel.batchNo')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentBatchFilterViewModel.batchNo')?.errorMessage}}</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BatchStartDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" [controlTabIndex]="16" formControlName="batchCreatedDateFrom"
                                [controlTabIndex]="0" pickerClass="form-control" placeholder="Select Date"></rx-date>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BatchSupplierNo_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="7" class="form-control" formControlName="supplierOrderNo"
                                rxPlaceholder="MoveNoteDocumentComp_BatchSupplierNo_p" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BatchEndDate_t"></label>
                        <div class="col-md-8">
                            <rx-date showAddon="true" [controlTabIndex]="16" formControlName="batchCreatedDateTo"
                                [controlTabIndex]="0" pickerClass="form-control" placeholder="Select Date"></rx-date>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pr-xl-5">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="MoveNoteDocumentComp_BatchProductNo_t"></label>
                        <div class="col-md-8">
                            <input type="text" tabindex="7" class="form-control" formControlName="productNo"
                                rxPlaceholder="MoveNoteDocumentComp_BatchProductNo_p" />
                                <small class="form-text text-danger"
                                [class.d-block]="moveNoteDocumentFormGroup.get('moveNoteDocumentBatchFilterViewModel.productNo')?.errors">{{moveNoteDocumentFormGroup.get('moveNoteDocumentBatchFilterViewModel.productNo')?.errorMessage}}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hr-line-dashed border-top w-100"></div>
            <div class="row mx-0">
                <div class="form-group ml-auto mb-0" [rxLocalisationInit]="componentName">
                    <button (click)="searchData()" class="btn btn-success" rxText="MoveNoteDocumentComp_SearchBtn_t"></button>
                </div>
            </div>
            <div class="table-responsive mt-3" [rxLocalisationInit]="componentName">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
                    <div class="table-responsive" *ngIf="isShowGrid">
                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.userModuleId"
                                [design]="userSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.corporateModuleId"
                                [design]="corporateSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.eventModuleId"
                                [design]="eventSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.projectModuleId"
                                [design]="projectSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.pressMediaModuleId"
                                [design]="pressMediaSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.orderModuleId"
                                [design]="orderSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.legalAgreementModuleId"
                                [design]="legalAgreementSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.hseModuleId"
                                [design]="hseSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.riskModuleId"
                                [design]="riskSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.itHelpDeskModuleId"
                                [design]="itHelpDeskSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.piecesModuleId"
                                [design]="piecesSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.bookingsModuleId"
                                [design]="bookingsSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.priceListModuleId"
                                [design]="priceListSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.designModuleId"
                                [design]="designSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.gemstoneModuleId"
                                [design]="gemstoneSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.productModuleId"
                                [design]="productSearchGrid"></rx-grid>
                            <rx-grid *ngIf="localFormGroupJson.rootEntityTypeId == modouleIdObj.batchesModuleId"
                                [design]="batchesSearchGrid"></rx-grid>
                        </div>

                    </div>
                    <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
                        <h4>No Record Found</h4>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
            <button (click)="moveNoteDocuments()" [disabled]="disabledMoveNotebtn" class="btn btn-success" rxText="MoveNoteDocumentComp_MoveBtn_t"></button>
            <button (click)="closePopup()" class="btn btn-outline-primary ml-0" rxText="MoveNoteDocumentComp_CloseBtn_t"></button>
        </div>
    </div>
</div>