import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CategoryDiscountMappingBase {

//#region categoryId Prop
        @prop()
        categoryId : number;
//#endregion categoryId Prop


//#region discountId Prop
        @prop()
        discountId : number;
//#endregion discountId Prop



}