import {ProductionOrderFormTypeBase,} from '@app/database-models'
//Generated Imports
export class ProductionOrderFormType extends ProductionOrderFormTypeBase
{


//#region Generated Reference Properties

//#endregion Generated Reference Properties


}