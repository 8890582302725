<div class="modal-dialog modal-lg" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="modal-content">
      <div class="modal-header text-left">
          <h4 class="modal-title mb-0" rxText="Label_Gemfield_Group_Companies_t"></h4>
          <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
          </button>
      </div>
      <div class="modal-body pb-0"  [rxSpinner]="spin" (keyup.enter)="bindGrid()">
          <!-- <div class="form-group row">
              <label class="col-md-3 col-lg-3 col-xl-3 col-form-label" 
                  rxText="CorporateCompanyMappingAddComponent_Name_t"></label>
              <div class="col-md-4">
                  <input type="text" class="form-control"  [(ngModel)]="fullName"
                      rxPlaceholder="CorporateCompanyMappingAddComponent_Name_p">
              </div>
          </div> -->
          <!-- <div class="form-group row">
              <div class="col-md-9"></div>
              <div class="col-md-3">
                  <button type="button" class="btn btn-success pull-right" (click)="search()">Search</button>
              </div>

          </div> -->
          <div class="dataTables_filter  d-flex flex-wrap align-items-center">
          
          <label class="ml-auto mr-0 mb-3">
            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
              placeholder="" [title]="titleSearch">
          </label>
        </div>
          <div class="form-group row" *ngIf="totalRecords>0">
              <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                  <div class="table-responsive">
                      <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                          <rx-grid [design]="leaveTypeCorporateGrid"></rx-grid>
                      </div>
                  </div>
              </div>
          </div>
          <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
          </div>
      </div>
      <div class="modal-footer" *ngIf="totalRecords>0" [rxLocalisationInit]="componentName">
          <button type="button" class="btn btn-success" (click)="saveLeaveTypeCorporateMappings()"
              [disabled]="leaveTypeCorporateMappingArray.length <= 0" rxText="Btn_Save_t"></button>
          <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
      </div>
  </div>
</div>