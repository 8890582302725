import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { NgxPrintModule } from 'ngx-print';
import { AppComponent } from './components/start/app.component';
import { ROUTING } from './components/start/routing';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { TopBarComponent } from './components/shared/top-bar/top-bar.component';
import { SideBarComponent } from './components/shared/side-bar/side-bar.component';
import { FooterBarComponent } from './components/shared/footer-bar/footer-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { LoginService } from './components/authentication/login.service';
import { RxHttp } from '@rxweb/http';
import { ApplicationBroadcaster } from './temp-service/application-broadcaster';
import { SystemTimeSharedModule } from './components/shared/system-time/system-time.shared-module';
import { BedRequestComponent } from './components/shared/global/bed-request/bed-request.component'
import { ModalView } from './domain/customize-design/modal';
import { AppPopup } from './components/shared/app-popup/app-popup.component';
import { BaseToastr } from './domain/customize-design/toastr';
import { DatePipe } from '@angular/common';
import { ChangePasswordComponent } from './components/authentication/change-password/change-password.component';
import { KeyAccess } from './temp-service/key-access-service';
import { SharedModule } from './components/shared/shared.module';
import { localize } from './domain/customize-design/localization';
import { EditorModule } from '@tinymce/tinymce-angular';
import { RxWebModule } from './rxweb.module';
import { EventSharedModule } from './components/event/events/event-shared.module';
import { UserSharedModule } from './components/user/users/user-shared.module';
import { ProductPieceSharedModule } from './components/inventory/product-piece/product-piece-shared.module';
import { RxDatePickerModule } from '@rxweb/angular/datepicker';
import { PasswordAlertComponent } from './components/shared/global/password-alert/password-alert.component';
import { CustomRouteReuseStategy } from './domain/services/route-reuse';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ProductDesignSharedModule } from './components/inventory/product-design/product-design-shared.module';
import { SupplierSharedModule } from './components/user/suppliers/supplier-shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AnonymousTokenGenerator } from './domain/services/token-activator';
import { ResetPasswordService } from './components/authentication/reset-password/reset-password-service';
import { GridModule } from '@rxweb/grid';

localize("global", {
  "dialog-ok": "Ok",
  "dialog-cancel": "Cancel",
  "dialog-save": "Save",
  "dialog-dontsave": "Dont Save",
  "dialog-heading": "Delete Alert",
  "dialog-message": "message",
  internationalization: {
    date: {
      format: 'dmy',
      separator: '/',
      enableFullMonth: true
    }
  },
  control: {
    "rx-date": {
      "placeholder": "Select Date",
    }
  }
});
import { WatchInvoiceSharedModule } from './components/inventory/watch-invoices/watch-invoice-shared.module';
import { SystemHelpSharedModule } from './components/master/system-help/system-help-shared.module';
import { PurchaseOrderValidationPopupComponent } from './components/inventory/product-batch/purchase-order-validation-popup/purchase-order-validation-popup.component';
import { DownloadPageComponent } from './components/shared/download-page/download-page.component';
import { CustomUrlSerializer } from './domain/services/custom-url-serializer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GlobalErrorHandler } from './domain/services/global-error-handler';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

import { msalConfig, loginRequest } from './auth-config';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
    loginFailedRoute: '/login'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    SideBarComponent,
    FooterBarComponent,
    BedRequestComponent,
    AppPopup,
    ChangePasswordComponent,
    PasswordAlertComponent,
    PurchaseOrderValidationPopupComponent,
    DownloadPageComponent
  ],
  imports: [
    EditorModule, RxWebModule, NgxPrintModule, GridModule, SystemHelpSharedModule,
    BrowserModule, SystemTimeSharedModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, SharedModule,
    RxDatePickerModule, NgxCaptchaModule, HttpClientModule, NgxSkeletonLoaderModule,
    MsalModule, ROUTING, ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }), PowerBIEmbedModule
  ],
  providers: [ResetPasswordService, KeyAccess, AnonymousTokenGenerator, LoginService, RxHttp, ApplicationBroadcaster, ModalView, BaseToastr, DatePipe,
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    }, {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    }
    ,
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [UserSharedModule, EventSharedModule, SupplierSharedModule, ProductDesignSharedModule, ProductPieceSharedModule, GridModule, WatchInvoiceSharedModule
    // ProductBatchSharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
