import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractLanguageContentKey } from '../domain/abstract-language-content-key';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { LanguageContentKey, LanguageContent, ComponentLanguageContent } from '@app/models';
import { LocalizationFormViewModel } from 'src/app/view-model/localization-form-view-model';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { multilingual } from '@rxweb/localization';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import * as CryptoJS from 'crypto-js';
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { PlatformLocation } from "@angular/common";
@multilingual("LanguageContentKeyEditComponent")
@access({ accessLevel: RolePermissionEnums.Localization, action: "put" })
@Component({
    selector: "app-language-content-key-edit",
    templateUrl: './language-content-key-edit.component.html'
})
export class LanguageContentKeyEditComponent extends AbstractLanguageContentKey implements OnInit, OnDestroy {
    languageContentKey: LanguageContentKey;
    languageContents: LanguageContent;
    componentLanguage: ComponentLanguageContent;
    localization: LocalizationFormViewModel;
    subscription: any;
    id: number;
    router: Router;
    contentType: string;
    componentKeyId: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    showSystemHelpIcon: boolean = false;
    titleHelp: string;
    contentTypeDisabled: boolean = true;
    isEditor: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder, modelView: ModalView, private activatedRoute: ActivatedRoute,private applicationBroadcaster:ApplicationBroadcaster, router: Router, private el:ElementRef, private title: Title, private location : PlatformLocation) {
        super();
        this.applicationBroadcaster.activeMenu({ activeMenu:'system' ,subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id1'];
            this.contentType = t['id2'];
        })
        this.router = router;
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.Localization;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        if(this.contentType=='gn')
        {
            this.isEditor=true;
        }
        else
        {
            this.isEditor=false;
        }
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Localisation - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.spin = true;
        this.lookup([{ params: [this.id],queryParams: { ContentType: this.contentType }, propName: "localization" }, { path: USER_LOOKUPS["languageUserLookUps"], propName: "languageUserLookUp" }, { path: USER_LOOKUPS["localizationComponents"], propName: "localizationComponents" }]).subscribe(response => {
            this.localizationLookup = response;
            this.localization = new LocalizationFormViewModel();
            this.localization = this.localizationLookup.localization;
            this.languageContentKey = new LanguageContentKey();
            this.languageContents = new LanguageContent();
            this.componentLanguage = new ComponentLanguageContent();
            this.languageContentKey.languageContents = new Array<LanguageContent>();
            this.languageContentKey.componentLanguageContents = new Array<ComponentLanguageContent>();
            this.localizationFormGroup = this.formBuilder.formGroup(LocalizationFormViewModel, this.localization) as IFormGroup<LocalizationFormViewModel>;
            this.showComponent = true;
            this.spin = false;
        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    editLocalization(isRedirect: boolean) {
        this.localizationFormGroup.submitted = true;
        if (this.localizationFormGroup.valid) {
            this.spin = true;
            this.languageContents.languageContentId = 0;
            this.languageContents.languageContentKeyId = 0;
            this.languageContents.contentType = this.localizationFormGroup.value.contentType;
            this.languageContents.en = this.localizationFormGroup.value.en;
            this.languageContents.m_en = this.localizationFormGroup.value.m_en;
            this.languageContents.fr = this.localizationFormGroup.value.fr == null ? this.localizationFormGroup.value.en +"---" : this.localizationFormGroup.value.fr;
            this.componentLanguage.componentKeyId = this.localization.id;
            this.componentLanguage.en = "";
            this.componentLanguage.fr = "";
            this.languageContentKey.languageContentKeyId = +this.id;
            this.languageContentKey.keyName = this.localizationFormGroup.value.keyName;
            this.languageContentKey.isComponent = false;
            this.languageContentKey.languageContents.push(this.languageContents);
            this.languageContentKey.componentLanguageContents.push(this.componentLanguage);
            this.put({
                body: this.languageContentKey,
                params: [this.id]
            }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                if (localStorage.getItem('AllLocalizedMessages')) {
                    localStorage.removeItem('AllLocalizedMessages');
                    this.get({ path: "api/Authentication/GetAllLocalizedMessages" }).
                        subscribe(response => {
                            var encryptstring = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(response), 'secret key 123').toString());
                            localStorage.setItem('AllLocalizedMessages', encryptstring);
                            // this.decryptstring = localStorage.getItem('AllLocalizedMessages');
                            // this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
                            // this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
                        });
                }
                isRedirect ? this.router.navigate(['/language-content-keys', data]) : this.router.navigate(['/language-content-keys']);
            });
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.localizationFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }
    deleteLocalization() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/language-content-keys']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Localization");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "LanguageContentKeyEditComponent";
    }
}
