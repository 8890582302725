import { numeric, pattern, prop, range } from '@rxweb/reactive-form-validators';

export class OrderSearchFilterViewModelBase {
    @prop()
    startDate:Date;

    @prop()
    endDate:Date;
    
    @prop()
    custName: string

    @prop()
    custEmail: string

    @prop()
    orderStatusId: number

    @prop()
    paymentStatusId: number

    @prop()
    shippingStatusId: number

    @prop()
    invoiceNumber: string

    @prop()
    salesChannelIds: string

    @prop()
    salesPersonId: string

    @prop()
    @numeric({allowDecimal:false})
    @pattern({expression:{"orderId":/^[0-9]/},message:"Please enter valid orderId"})
    @range({minimumNumber:1,maximumNumber:2147483647})
    orderId: number

    @prop({defaultValue:25})
    rowCount:number;

    @prop({defaultValue:'CreatedOn'})
    orderBy:string;

    @prop({defaultValue:true})
    sortOrder:boolean;

    @prop({defaultValue:1})
    pageIndex:number;

    @prop({defaultValue:0})
    statusId:number;

    @prop()
    trackingNumber: string

    @prop()
    shippingMethodID: number

    @prop()
    deliveryTypeID: number
}