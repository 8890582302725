import { prop, required } from "@rxweb/reactive-form-validators";
import { StatusEnum } from "src/app/enums/status.enum";
//#291870//28-06-2024//adding order mapping to project and event
export class CorporateOrderEventProjectMappingBase {

    //#region corporateOrderEventProjectMappingId Prop
    @prop({ defaultValue: 0 })
    corporateOrderEventProjectMappingId: number;
    //#endregion corporateOrderEventProjectMappingId Prop

    //#region orderId Prop
    @prop()
    @required()
    orderId: number;
    //#endregion orderId Prop

    //#region projectId Prop
    @prop()
    @required()
    projectId: number;
    //#endregion projectId Prop

     //#region eventId Prop
     @prop()
     @required()
     eventId: number;
     //#endregion eventId Prop

      //#region entityId Prop
    @prop()
    @required()
    entityId: number;
    //#endregion entityId Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    @required()
    statusId: number;
    //#endregion statusId Prop

}
