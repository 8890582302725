import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DirectorshipMappingBase {

//#region directorshipId Prop
        @prop()
        directorshipId : number;
//#endregion directorshipId Prop


//#region startDate Prop
        @prop()
        startDate : Date;
//#endregion startDate Prop


//#region endDate Prop
        @prop()
        endDate : Date;
//#endregion endDate Prop


//#region companyId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        companyId : number;
//#endregion companyId Prop


//#region customerId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        customerId : number;
//#endregion customerId Prop

}