import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
import { getLocalizedValue, showPersonaNonGrataToolTip } from 'src/app/domain/common-logic/common-logic';

export class UserKnownToColleguesListViewModel {


  @gridColumn({ style: { width: "10%", "min-width":"180px", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "reDirectPeopleCorporateAndTooltip" }, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'KnownToColleguesViewComponent_Name_gh', keyColumn: false })
  fullName: any;


  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.company;
          }
        },
      }
    }, visible: true, style: { width: "5%", "min-width":"350px", "text-align": "left" }, columnIndex: 2, allowSorting: false, headerKey: 'KnownToColleguesViewComponent_Corporate_gh', name: 'company', keyColumn: false, parameter: false
  })

  company: string;
  companyDetails: string;


  @gridColumn({ visible: false, columnIndex: 0, allowSorting: false, headerKey: 'userId', keyColumn: true })
  knownToColleagueId: any;


  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.profile;
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.profileCharCount > 100) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClickProfile"
            }
          },
        }]
      }
    }, style: { width: "25%", "min-width": "250px", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 3, headerKey: 'KnownToColleguesViewComponent_Profile_gh', keyColumn: false, allowSorting: false
  })
  profile: string;

  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.profile1;
          }
        },

      }
    }, style: { width: "25%", "min-width": "300px", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 12, headerKey: 'KnownToColleguesViewComponent_Profile_gh', keyColumn: false, allowSorting: false
  })
  profile1: any;

  profileCharCount: number;

 
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'KnownToColleguesViewComponent_Department_gh', keyColumn: false })
  department: string;
  

  @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 16, allowSorting: false, headerTitle: 'StatusId', keyColumn: false })
  statusId: number;

  @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 17, headerTitle: 'totalCount', keyColumn: false })
  totalCount: number;

  contactPermissionRequired: boolean;
  isUnwelcome: boolean;

}