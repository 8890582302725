import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Country, vCustomerRoleDomainLookUp } from '@app/models';
import { AbstractCountry } from '../domain/abstract-country';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { LanguagesEnums } from '../../../../custom-enum/language-enums';
import { CoreComponent, access } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { CountryEditComponent } from '../edit/country-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { vRegion } from 'src/app/models/extended-models/v-region';
@multilingual("CountryAddComponent")
@access({ accessLevel: RolePermissionEnums.Countries, action: "post" })

@Component({
  selector: "app-country-add",
  templateUrl: './country-add.component.html'
})
export class CountryAddComponent extends AbstractCountry implements OnInit, OnDestroy {
  country: Country;
  subscription: any;
  countryEdit: any = CountryEditComponent;
  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
  countriesLookup: {
    customerRoleDomainLookUp: vCustomerRoleDomainLookUp
    regionsLookup: vRegion[],
  }
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el:ElementRef,private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.Countries;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Countries - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    this.lookup([
      { propName: "customerRoleDomainLookUp", path: USER_LOOKUPS.customerRoleDomainLookUp },
      { propName: "regionsLookup", path: USER_LOOKUPS.regionsLookup }
    ]).subscribe((lookup: any) => {
      this.countriesLookup = lookup;
      this.country = new Country();
      this.country.countryId = 0;
      this.country.regionId = 0;
      this.country.statusId = StatusEnum.Active;
      this.country.languageId = LanguagesEnums.English;
      this.countryFormGroup = this.formBuilder.formGroup(this.country) as IFormGroup<Country>;
      this.countryFormGroup.controls.insurancePer.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
      RxwebValidators.pattern({ expression: { 'insurancePer': /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ }, message: 'Please enter valid rate(8 digits and 2 decimal places allowed)' })]);
      this.countryFormGroup.controls.dutyPer.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
      RxwebValidators.pattern({ expression: { 'dutyPer': /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ }, message: 'Please enter valid rate(8 digits and 2 decimal places allowed)' })]);
      this.showComponent = true;

    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addCountry(isRedirect: boolean) {
    this.countryFormGroup.submitted = true;
    if (this.countryFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.countryFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
           this.toastr.success(existsAlert[0].showMessage);
                    }
        isRedirect ? this.router.navigate(['/countries', data]) : this.router.navigate(['/countries']);
      })
    } else {
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.countryFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
  }

  get componentName(): string {
    return "CountryAddComponent";
}
}
