import {vPriceBasisLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPriceBasisLookUp extends vPriceBasisLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}