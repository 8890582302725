<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="UserAddComponent_Add_Title_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_People_t"></li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/users']" rxText="Label_People_t"></a>
      </li>
      <li class="breadcrumb-item active">Add New</li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5 rxText="UserAddComponent_Title_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div id="wizard" class="theme-wizard wizard clearfix" style="margin-bottom: 9rem; overflow: visible;">
        <div class="steps clearfix">
          <ul role="tablist">
            <li role="tab" class="first current">
              <a id="wizard-t-0">
                <span class="current-info audible">current step: </span>
                <span class="number">1.</span>
                <i class="fr-basic-info wizard-icon"></i>
                <span class="fr-circle wizard-check"></span>
                <span class="font-weight-bold" rxText="UserAddComponent_Step_Basic_Info_t"></span>
              </a>
            </li>
            <li role="tab" class="disabled" [class.current]="activeStep == 'step-2'"
              [class.done]="activeStep == 'step-3'">
              <a id="wizard-t-1">
                <span class="number">2.</span>
                <i class="fr-contact-info wizard-icon"></i>
                <span class="fr-circle wizard-check"></span>
                <span class="font-weight-bold" rxText="UserAddComponent_Step_Contact_Info_t"></span>
              </a>
            </li>
            <li role="tab" class="last disabled" [class.current]="activeStep == 'step-3'"
              [class.done]="activeStep == 'step-3'">
              <a id="wizard-t-2">
                <span class="number">3.</span>
                <i class="fr-user-created wizard-icon"></i>
                <span class="fr-circle wizard-check"></span>
                <span class="font-weight-bold" rxText="UserAddComponent_Step_User_Created_t"></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="step-content" id='basicInfo_customerRole' *ngIf="activeStep == 'step-1'" [rxLocalisationInit]="componentName"
          [formGroup]="userFormGroup">
          <div class="row">
            <div class="col-xl-12">
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="UserAddComponent_Title_Basic_Info_t"></h5>
              </div>
              <div class="row">
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Title_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="titleLookUp" #rxSelect [controlTabIndex]="1" formControlName="title"
                        mainClass="form-control" [keyValueProps]="['titleName','titleId']"
                        [selectPlaceholder]="'Select Title'"></rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.title.errors">{{userDetailFormGroup.controls.title.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4  col-xl-4 col-form-label">
                      <span rxText="UserAddEditComponent_MiddleName_t"></span></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="middleName" class="form-control" tabindex="2" rxFocus
                        rxPlaceholder="UserAddEditComponent_MiddleName_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.middleName.errors">{{userDetailFormGroup.controls.middleName.errorMessage}}</small>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_DateOfBirth_t"></label>
                    <div class="col-md-8">
                      <div class="" id="data_1">
                        <div class="input-group date">
                          <rx-date showAddon="true" [controlTabIndex]="7" formControlName="dateOfBirth"
                            placeholder="Enter Date Of Birth" pickerClass="form-control"></rx-date>
                          <!-- <input type="date" formControlName="dateOfBirth" class="form-control" placeholder="Enter Date Of Birth"> -->
                          <small class="form-text text-danger" *ngIf="dateOfBirthError == true">Date Of Birth should not
                            be greater than Today's Date</small>
                          <small class="form-text text-danger"
                            [class.d-block]="userFormGroup.controls.dateOfBirth.errors">{{userFormGroup.controls.dateOfBirth.errorMessage}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="showRoleType" [rxLocalisationInit]="componentName">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_UserRoleType_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.customerRoleTypeLookUp" #rxSelect [controlTabIndex]="8"
                        formControlName="roleTypeId" mainClass="form-control"
                        [keyValueProps]="['customerRoleName','customerRoleId']" (changed)="changeRoleType($event)"
                        [selectPlaceholder]="'Select User Role Type'"></rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userFormGroup.controls.roleTypeId.errors">{{userFormGroup.controls.roleTypeId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row mt-md-4" *ngIf="showPassword" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Password_t"></label>
                    <div class="col-md-8">
                      <input type="password" tabindex="10" formControlName="password" class="form-control " autocomplete="off"
                        rxPlaceholder="UserAddEditComponent_Password_p">
                      <small class="form-text text-danger" [class.d-block]="userFormGroup.controls.password.errors"
                        [innerHTML]="userFormGroup.controls.password.errorMessage"></small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 px-xl-5">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4  col-xl-4 col-form-label">
                      <span rxText="UserAddEditComponent_FirstName_t"></span><span
                        class="text-danger">*</span></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="firstName" class="form-control" tabindex="2" rxFocus
                        rxPlaceholder="UserAddEditComponent_FirstName_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.firstName.errors">{{userDetailFormGroup.controls.firstName.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Image_t"></label>
                    <div class="col-md-8">
                      <app-file [fileViewModel]="fileViewModel" [tabindex]="'4'" (files)="updateFiles($event)"
                        [title]="imageTooltip"></app-file>
                      <img *ngIf="userFormGroup.value.displayImage && userFormGroup.value.blobFileName==null"
                        src="{{userFormGroup.value.imageURL1}}" class="event-img  mt-2 upload-img-preview" />
                      <img *ngIf="userFormGroup.value.fileData!=null" [src]="getImgContent()"
                        class="event-img mt-2 upload-img-preview" />
                      <ng-container *ngIf="userFormGroup.value.displayImage || userFormGroup.value.blobFileName!=null" [rxLocalisationInit]="componentName">
                        <button (click)="removeImage()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                      </ng-container>
                    </div>
                  </div>

                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_AnniversaryDate_t"></label>
                    <div class="col-md-8">
                      <div class="" id="data_1">
                        <div class="input-group date">
                          <rx-date showAddon="true" [controlTabIndex]="11" formControlName="anniversaryDate"
                            placeholder="Enter Anniversary Date " pickerClass="form-control"></rx-date>
                          <!-- <input type="date" formControlName="anniversaryDate" class="form-control" placeholder="Enter Date Of Birth"> -->
                          <small class="form-text text-danger" *ngIf="anniversaryDateError == true">Anniversary Date
                            should be not be greater than Today's Date
                          </small>
                          <small class="form-text text-danger"
                            [class.d-block]="userFormGroup.controls.anniversaryDate.errors">{{userFormGroup.controls.anniversaryDate.errorMessage}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_DietaryInfo_t"></label>
                    <div class="col-md-8">
                      <input type="text" tabindex="12" formControlName="dietaryInfo" class="form-control"
                        rxPlaceholder="UserAddEditComponent_DietaryInfo_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.dietaryInfo.errors">{{userDetailFormGroup.controls.dietaryInfo.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_FamilyMember_t"></label>
                    <div class="col-md-8">
                      <textarea class="form-control" tabindex="9" formControlName="familyMembers" rows="4"
                        rxPlaceholder="UserAddEditComponent_Family_p"></textarea>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.familyMembers.errors">{{userDetailFormGroup.controls.familyMembers.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                </div>
                <div class="col-md-4 pl-xl-5">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label">
                      <span rxText="UserListComponent_LastName_t"></span> <span
                        class="text-danger">*</span></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="lastName" tabindex="3" class="form-control"
                        rxPlaceholder="UserAddEditComponent_LastName_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.lastName.errors">{{userDetailFormGroup.controls.lastName.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Profile/About_t"></label>
                    <div class="col-md-8">
                      <textarea class="form-control" tabindex="5" formControlName="profileAbout" rows="4"
                        style="min-height: 70px;" id="profileAbout"
                        rxPlaceholder="UserAddEditComponent_Profile_p"></textarea>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.profileAbout.errors">{{userDetailFormGroup.controls.profileAbout.errorMessage}}</small>
                    </div>
                  </div>
                  <div *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Nationality_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="nationalityLookUp" #rxSelect [controlTabIndex]="6" formControlName="nationality"
                        mainClass="form-control" [keyValueProps]="['nationalityName','nationalityId']"
                        [selectPlaceholder]="'Select Nationality'"></rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.nationality.errors">{{userDetailFormGroup.controls.nationality.errorMessage}}</small>
                    </div>
                  </div>


                </div>
                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Hobbies_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                      <textarea class="form-control" tabindex="13" formControlName="hobbies" rows="4"
                        rxPlaceholder="UserAddEditComponent_Hobbies_p"></textarea>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.hobbies.errors">{{userDetailFormGroup.controls.hobbies.errorMessage}}</small>
                    </div>
                  </div>
                  <!-- <div class="form-group" *ngIf="showAdminRole">
                    <div [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" class="row">
                      <label class="col-md-4 col-xl-4 col-form-label"
                        rxText="UserAddEditComponent_BadgeId_t"></label>
                      <div class="col-md-8">
                        <input type="text" formControlName="badgeId" tabindex="14" class="form-control"
                          rxPlaceholder="UserAddEditComponent_BadgeId_p">
                        <small class="form-text text-danger"
                          [class.d-block]="userDetailFormGroup.controls.lastName.errors">{{userDetailFormGroup.controls.lastName.errorMessage}}</small>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="hr-line-dashed border-top w-100 mb-2" *ngIf="showCustomerRole"></div>
            <div class="mt-5 mt-lg-0 col-xl-12" *ngIf="showCustomerRole" [rxLocalisationInit]="componentName">
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5><span rxText="UserAddComponent_Title_User_Roles_t"></span>
                  <span class="text-danger">*</span>
                </h5>
                <small class="form-text text-danger" *ngIf="userRoleValidation"
                  [class.d-block]="userRoleValidation">Select At least one role</small>
              </div>
              <div class="row">
                <div class="col-md-4 pl-xl-5" [formGroup]="userRoleFormGroup"
                  *ngFor="let userRoleFormGroup of userRoleFormArray; let i = index">
                  <div class="form-group row check-group" *ngIf="userRoleFormGroup.value.roleId != 2147483647">
                    <label class="col-md-10 col-form-label">{{getRoleName(userRoleFormGroup.value.roleId)}}</label>
                    <app-checkbox (change)="RoleSelectionCheck()" [tabIndex]="getTabIndex(13,i)"
                      [checkFormControl]="userRoleFormGroup.controls.active"
                      class="col-md-auto d-flex align-items-center"></app-checkbox>
                    <small class="form-text text-danger"
                      [class.d-block]="userRoleFormGroup.controls.active.errors">{{userRoleFormGroup.controls.active.errorMessage}}</small>
                  </div>

                </div>
              </div>
            </div>
            <div class="hr-line-dashed border-top w-100 mb-2" *ngIf="showAdminRole"></div>
            <div class="mt-5 mt-lg-0 col-xl-12" *ngIf="showAdminRole" [rxLocalisationInit]="componentName">
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5><span rxText="UserAddComponent_Title_User_Roles_t"></span>
                  <span class="text-danger">*</span>
                </h5>
                <small class="form-text text-danger" *ngIf="userRoleValidation"
                  [class.d-block]="userRoleValidation">Select At least one role</small>
              </div>
              <div class="row">
                <div class="col-md-4 pl-xl-5" [formGroup]="userRoleFormGroup"
                  *ngFor="let userRoleFormGroup of adminRoleFormArray; let i = index">
                  <div class="form-group row check-group" *ngIf="!this.RoleEnum && userRoleFormGroup.value.roleId!=2147483647">
                    <label class="col-md-10 col-form-label">{{getRoleName(userRoleFormGroup.value.roleId)}}</label>
                    <app-checkbox (change)="RoleSelectionCheck()" [tabIndex]="getTabIndex(12,i)"
                      [checkFormControl]="userRoleFormGroup.controls.active"
                      class="col-md-auto d-flex align-items-center"></app-checkbox>
                    <small class="form-text text-danger"
                      [class.d-block]="userRoleFormGroup.controls.active.errors">{{userRoleFormGroup.controls.active.errorMessage}}</small>
                  </div>
                  <div class="i-checks mr-4" *ngIf="this.RoleEnum && userRoleFormGroup.value.roleId!=2147483647">
                    <label class="mb-0 d-flex">
                      <fieldset>
                        <div class="iradio_square-green" [class.checked]="userRoleFormGroup.controls.active.value==true"
                          style="position: relative;">
                          <input type="radio" (change)="RoleSelectionCheck()" name="active"
                            [value]='userRoleFormGroup.controls.roleId.value' style="visibility: hidden;"
                            (change)="changeStatus($event,i)">
                        </div>

                        <label class="ml-1">{{getRoleName(userRoleFormGroup.value.roleId)}}</label>
                      </fieldset>
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step-content" id='ContactInfo_userInfo' *ngIf="activeStep == 'step-2'" [formGroup]="userFormGroup" [rxLocalisationInit]="componentName">
          <div class="row">
            <div class="col-xl-12">
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="UserAddComponent_Title_Contact_Info_t"></h5>
              </div>
              <div class="row">
                <div class="col-lg-6 col-xl-4 pr-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_PrimaryEmailAdd_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="email" tabindex="13" class="form-control" autocomplete="off"
                        rxPlaceholder="UserAddEditComponent_PrimaryEmailAdd_p"
                        rxFocus>
                      <small class="form-text text-danger"
                        [class.d-block]="userFormGroup.controls.email.errors">{{userFormGroup.controls.email.errorMessage}}</small>
                    </div>
                  </div>
                  <div *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_SecondaryEmailAdd_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="secondaryEmail" tabindex="16" class="form-control" autocomplete="off"
                        rxPlaceholder="UserAddEditComponent_SecondaryEmailAdd_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.secondaryEmail.errors">{{userDetailFormGroup.controls.secondaryEmail.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_MobileTelephone_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="mobileNumber" class="form-control" tabindex="19"
                        rxPlaceholder="UserAddEditComponent_MobileTelephone_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.mobileNumber.errors">{{userDetailFormGroup.controls.mobileNumber.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_HomeTelephone_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="phoneNumber" class="form-control" tabindex="22"
                        rxPlaceholder="UserAddEditComponent_HomeTelephone_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.phoneNumber.errors">{{userDetailFormGroup.controls.phoneNumber.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_WorkTelephone_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="workPhoneNumber" class="form-control" tabindex="24"
                        rxPlaceholder="UserAddEditComponent_WorkTelephone_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.workPhoneNumber.errors">{{userDetailFormGroup.controls.workPhoneNumber.errorMessage}}</small>
                    </div>
                  </div>
                  <div *ngIf="!showCustomerControl" [rxLocalisationInit]="componentName">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                  *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                  <label class="col-md-4 col-xl-4 col-form-label"
                    rxText="UserAddEditComponent_MobileTelephone_t"></label>
                  <div class="col-md-8">
                    <input type="text" formControlName="mobileNumber" class="form-control" tabindex="19"
                      rxPlaceholder="UserAddEditComponent_MobileTelephone_p">
                    <small class="form-text text-danger"
                      [class.d-block]="userDetailFormGroup.controls.mobileNumber.errors">{{userDetailFormGroup.controls.mobileNumber.errorMessage}}</small>
                  </div>
                </div>
              </div>
                </div>
                <div class="col-lg-6 col-xl-4 px-xl-4">
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_StreetAddress_t"></label>
                    <div class="col-md-8">
                      <input type="text" tabindex="14" formControlName="streetAddress" class="form-control"
                        rxPlaceholder="UserAddEditComponent_StreetAddress_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.streetAddress.errors">{{userDetailFormGroup.controls.streetAddress.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_StreetAddress2_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="streetAddress2" tabindex="17" class="form-control"
                        rxPlaceholder="UserAddEditComponent_StreetAddress2_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.streetAddress2.errors">{{userDetailFormGroup.controls.streetAddress2.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_City_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="city" tabindex="20" class="form-control"
                        rxPlaceholder="UserAddEditComponent_City_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.city.errors">{{userDetailFormGroup.controls.city.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Country_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.countryLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="23"
                        (changed)="selectState($event)" formControlName="countryId"
                        [keyValueProps]="['countryName','countryId']" [selectPlaceholder]="'Select Country'">
                      </rx-select>

                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.countryId.errors">{{userDetailFormGroup.controls.countryId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="!isshowStateTextbox" [rxLocalisationInit]="componentName">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_State/Province_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" >
                      <ng-container *ngIf="isDDStateChange" [rxSelectExtended]="_this">
                      <rx-select [selectPlaceholder]="'Select State/Province'" [(source)]="stateProvinceLookUp" #rxSelect
                        [controlTabIndex]="25"  mainClass="form-control"
                        formControlName="stateProvinceId" [keyValueProps]="['stateProvinceName','stateProvinceId']">
                      </rx-select>
                    </ng-container>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.stateProvinceId.errors">{{userDetailFormGroup.controls.stateProvinceId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="isshowStateTextbox" [rxLocalisationInit]="componentName">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_State/Province_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="stateName" tabindex="25" class="form-control"
                        placeholder="Enter State/Province">
                      <small class="form-text text-danger"
                        [class.d-block]="userFormGroup.controls.stateName.errors">{{userFormGroup.controls.stateName.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4 pl-xl-4">

                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_ZipPostalCode_t"></label>
                    <div class="col-md-8">
                      <input type="text" tabindex="15" formControlName="zipPostalCode" class="form-control"
                        rxPlaceholder="UserAddEditComponent_ZipPostalCode_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.zipPostalCode.errors">{{userDetailFormGroup.controls.zipPostalCode.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_POBOX_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="poBox" tabindex="18" class="form-control"
                        rxPlaceholder="UserAddEditComponent_POBOX_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.poBox.errors">{{userDetailFormGroup.controls.poBox.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="userDetailFormGroup"
                    *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Website/Linkedin_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="webSite" class="form-control" tabindex="21"
                        rxPlaceholder="UserAddEditComponent_Website/Linkedin_p">
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.webSite.errors">{{userDetailFormGroup.controls.webSite.errorMessage}}</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5>Faberge Sales Information</h5>
              </div>
              <div class="row">
                <div class="col-lg-6 col-xl-4 pr-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_SalesAdvisor_t"></label>
                    <div class="col-md-8">
                      <rx-select [(source)]="userLookUps.salesAdvisorLookUp" [controlTabIndex]="25" formControlName="salesAdvisorsID" mainClass="form-control"
                      [keyValueProps]="['userName','userId']" [selectPlaceholder]="'Select Sales Advisor'"></rx-select>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- <div class="hr-line-dashed border-top w-100 mb-2"></div> -->
            <div class="mt-5 mt-lg-0 col-xl-12">
              <!-- <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5>User Information</h5>
              </div> -->

              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <!-- <h5>Sales Info</h5> -->
                <h5 rxText="UserAddComponent_Title_CRM_Sales_Relations_t"></h5>
              </div>
              <div class="row">
                <div class="col-lg-6 col-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4  col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_SalesAdvisor_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.salesAdvisorLookUp" #rxSelect [controlTabIndex]="25"
                        formControlName="salesAdvisorsID" mainClass="form-control"
                        [keyValueProps]="['fullName','userId']" [selectPlaceholder]="'Select Sales Advisor'">
                      </rx-select>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_PeferredContactMethod_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.customerContactMethodTypeLookUp" #rxSelect mainClass="form-control"
                        [controlTabIndex]="28" formControlName="contactMethod"
                        [keyValueProps]="['customerContactMethodTypeName','customerContactMethodTypeId']"
                        [selectPlaceholder]="'Select Contact Method'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.contactMethod.errors">{{userDetailFormGroup.controls.contactMethod.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_LanguagePreference _t">

                    </label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.spokenLanguageLookUp" #rxSelect mainClass="form-control"
                        [controlTabIndex]="31" formControlName="languagePreference"
                        [keyValueProps]="['language','spokenLangId']"
                        [selectPlaceholder]="'Select Language Preference'">
                      </rx-select>
                    </div>
                  </div>
                   <!-- #287839//11-06-2024//new Channel DropDown added  -->
                   <div class="form-group row" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Channel_IEC_t">
                    </label>
                    <div class="col-md-8" [formGroup]="userFormGroup"
                      [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.channelIECLookUps" #rxSelect [controlTabIndex]="34"
                        mainClass="form-control" formControlName="channelIECId"
                        [keyValueProps]="['channelIECName','channelIECId']"
                        [selectPlaceholder]="'Select Channel'">
                      </rx-select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_SensitiveContact_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'26'"
                        [checkFormControl]="userFormGroup.controls.contactPermissionRequired">
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_BanClient_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'29'" [checkFormControl]="userFormGroup.controls.isBanOrders">
                      </app-checkbox>

                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4">
                  <div class="form-group row">
                    <div class="col-md-4 col-xl-4 ">
                      <label class="col-form-label">
                        <span  rxText="UserAddEditComponent_IsUnwelcome_t" style="display: inline;">
                        </span>
                        <i class="fa fa-info-circle ml-1" id="user_IsUnwelcome_helpToolTip"
                        data-toggle="tooltip" data-placement="right"
                        data-html="true" title=""></i>
                        <!-- <i class="fa fa-info-circle text-right float-right p-1" [title]="personaNonGrataMean"></i> -->
                      </label>
                    </div>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'28'"
                        [checkFormControl]="userFormGroup.controls.isUnwelcome">
                      </app-checkbox>
                    </div>
                  </div>

                  <div class="form-group row" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Rating_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.systemRatingLookUp" #rxSelect mainClass="form-control"
                        [controlTabIndex]="27" formControlName="rating" [keyValueProps]="['ratingName','ratingId']"
                        [selectPlaceholder]="'Unknown/uncategorised'"></rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.rating.errors">{{userDetailFormGroup.controls.rating.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_KeyRelationshipHolder_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.keyRelationshipHolderLookUp" #rxSelect mainClass="form-control"
                        [controlTabIndex]="30" formControlName="introducesBy" [keyValueProps]="['fullName','userId']"
                        [selectPlaceholder]="'Select Relationship Holder'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userFormGroup.controls.introducesBy.errors">{{userFormGroup.controls.introducesBy.errorMessage}}</small>
                    </div>
                  </div>
                </div>
              </div>
            <div *ngIf="showAdminRole" [rxLocalisationInit]="componentName">
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="UserEditComponent_PressMediaInternalNotification_t"></h5>
              </div>
              <div class="row">
                <div class="form-group col-md-6 col-lg-6 col-xl-4">
                  <div class="form-group row" *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index"
                    [formGroup]="userDetailFormGroup">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_PressMediaNotification_t"></label>
                    <div class="col-md-8" [rxTagExtended]="_this">
                      <rx-tag [controlTabIndex]="31" [(source)]="userLookUps.pressMediaTypesToSendEmail" #rxTag
                        formControlName="pressMediaEmailsType" mainClass="form-control"
                        [keyValueProps]="['typeName','typeId']"
                        placeholder="Select Press Media Notifications to receive"></rx-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="UserAddComponent_Title_Newsletter_Subscription_t"></h5>
              </div>
              <div class="row">
                <div class="form-group col-md-6 col-lg-6 col-xl-4" [formGroup]="userSubscriptionFormGroup"
                  *ngFor="let userSubscriptionFormGroup of userSubscriptionFormArray; let i = index">
                  <div class="row">
                    <label
                      class="col-md-4 col-xl-4 col-form-label">{{getSubscriptionName(userSubscriptionFormGroup.value.entitytypeId)}}</label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="getTabIndex(32,i)"
                        [checkFormControl]="userSubscriptionFormGroup.controls.isActive"></app-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6 col-lg-6 col-xl-4">
                  <div class="form-group row" *ngIf="showCustomerControl" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_FabergeMBRUpdates_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                      <app-checkbox [tabIndex]="'38'"
                        [checkFormControl]="userDetailFormGroup.controls.commu_FabergeMBRupdates">
                      </app-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                <div class="form-group col-md-6 col-lg-6 col-xl-4">
                  <div class="form-group row" *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index"
                    [formGroup]="userDetailFormGroup">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_PressMediaNotification_t"></label>
                    <div class="col-md-8">
                      <rx-tag [controlTabIndex]="39" [(source)]="userLookUps.pressMediaTypesToSendEmail"
                        formControlName="pressMediaEmailsType" mainClass="form-control"
                        [keyValueProps]="['typeName','typeId']"
                        placeholder="Select Press Media Notifications to receive"></rx-tag>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="UserAddComponent_Title_User_Information_t"></h5>
              </div>
              <div class="row">
                <div class="col-lg-6 col-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_ApprovalStatus_t"></label>
                    <div class="col-md-8">
                      <app-checkbox (change)="updateStatus($event)" [tabIndex]="'39'"
                        [checked]="userFormGroup.value.statusId == statusEnums.Active"></app-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4" *ngIf="showEmailNotificationCheckbox" >
                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_WelcomeNotification_t"></label>
                    <div class="col-md-8">
                      <app-checkbox  (change)="updateWelcomeEmailStatus($event)" [tabIndex]="'43'"
                        ></app-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_RegistrationMethod_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.customerRegistrationTypeLookUp" #rxSelect [controlTabIndex]="40"
                        formControlName="registrationTypeID" mainClass="form-control"
                        [keyValueProps]="['customerRegistrationTypeName','customerRegistrationTypeId']"
                        [selectPlaceholder]="'Select Registration Method'"></rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userFormGroup.controls.registrationTypeID.errors">{{userFormGroup.controls.registrationTypeID.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-4">
                  <!-- <div class="form-group row" *ngIf="showExternalDepartment">
                <label class="col-md-4 col-xl-4 col-form-label"
                  rxText="UserAddEditComponent_Department_t"></label>
                <div class="col-md-8" [formGroup]="userDetailFormGroup"
                  *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index">
                  <rx-select [(source)]="userLookUps.externalDepartment" mainClass="form-control" [controlTabIndex]="28"
                  formControlName="department"
                 [keyValueProps]="['departmentName','departmentId']" [selectPlaceholder]="'Select Department'">
                </rx-select>

                </div>
              </div> -->
                  <div class="form-group row" *ngIf="showInternalDepartment" [rxLocalisationInit]="componentName">
                    <label class="col-md-4 col-xl-4 col-form-label"
                      rxText="UserAddEditComponent_Department_t"></label>
                    <div class="col-md-8" [formGroup]="userDetailFormGroup"
                      *ngFor="let userDetailFormGroup of userDetailFormArray; let i = index" [rxSelectExtended]="_this">
                      <rx-select [(source)]="userLookUps.internalDepartment" #rxSelect mainClass="form-control"
                        [controlTabIndex]="41" formControlName="department"
                        [keyValueProps]="['departmentName','departmentId']" [selectPlaceholder]="'Select Department'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="userDetailFormGroup.controls.department.errors">{{userDetailFormGroup.controls.department.errorMessage}}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions clearfix" *ngIf="activeStep != 'step-3'" [rxLocalisationInit]="componentName">
          <ul role="menu" aria-label="Pagination" style="display:flex!important">
            <li aria-hidden="false" class="mx-0">
              <button [disabled]="activeStep == 'step-1'" role="menuitem" class="btn btn-light"
                (click)="peopleWizard(step,false)" rxText="Btn_Previous_t"></button>
            </li>
            <li aria-hidden="false" class="pull-right mx-0">
              <!-- <a href="javascript:void(0)" class="btn btn-success" role="menuitem" (click)="peopleWizard(step,true)">Next</a> -->
              <button class="btn btn-success" role="menuitem" (click)="peopleWizard(step,true)"
                rxText="Btn_Next_t"></button>
            </li>
          </ul>
        </div>

        <div class="step-content" id='userRegistered' *ngIf="activeStep == 'step-3'" [rxLocalisationInit]="componentName">
          <div class="text-center m-t-md py-5" *ngIf="id > 0" [rxLocalisationInit]="componentName">
            <i class="fr-check-confirmation fa-5x text-green"></i>
            <p class="font-weight-bold mt-0 my-3 font-xmd" rxText="UserAddComponent_User_Successfully_Created_t"></p>
            <button (click)="goToUser()" class="btn btn-success" rxText="UserAddComponent_Go_To_User_t"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
