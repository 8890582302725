import {SupplierRoleBase,} from '@app/database-models'
//Generated Imports
export class SupplierRole extends SupplierRoleBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}