import {GrievanceTierBase,} from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { RiskCategoryBase } from '../database-models/risk-category-base'

export class RiskCategory extends RiskCategoryBase 
{

 

//#region Generated Reference Properties

//#endregion Generated Reference Properties






}