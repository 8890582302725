<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
        <div class="col-md-8 my-1">
          <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
            </li>
            <li class="breadcrumb-item">
                    <a [routerLink]="['/credit-card-types']" rxText="Label_Credit_Cards_t"></a>
             </li>
            <li class="breadcrumb-item active" rxText="CreditCardTypeEditComponent_Title"></li>
          </ol>
        </div>
        <div class="col-md-4 text-right">
          <app-system-time></app-system-time>
        </div>
      </div>
  
    <div class="wrapper wrapper-content flex-grow-1">
  
      <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
          <h5><label rxText="CreditCardTypeEditComponent_Title"></label></h5>
          <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
            <i class="fa fa-question-circle fa-2x"></i>
          </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="creditCardTypeFormGroup"  [rxSpinner]="spin">
          <div class="row add-countries-form" (keyup.enter)="editCreditCardType(false)">

            <div class="col-md-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"  rxText="CreditCardTypeEditComponent_ID_t"></label>
                <div class="col-md-8">
                  <label type="text" 
                              class="form-control disableLabel-withoutheight mb-0">{{(creditCardTypeFormGroup.value.creditCardTypeId
                              === null || creditCardTypeFormGroup.value.creditCardTypeId === undefined ) ? '' :
                              creditCardTypeFormGroup.value.creditCardTypeId}}</label>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="CreditCardTypeAddEditComponent_CardTypeName_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="name" class="form-control"  rxPlaceholder="CreditCardTypeAddEditComponent_CardTypeName_p" rxFocus/>
                  <small class="form-text text-danger" [class.d-block]="creditCardTypeFormGroup.controls.name.errors">{{creditCardTypeFormGroup.controls.name.errorMessage}}<br /></small>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="CreditCardTypeAddEditComponent_SystemKeyword_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="systemKeyword" class="form-control"  rxPlaceholder="CreditCardTypeAddEditComponent_SystemKeyword_p"/>
                  <small class="form-text text-danger" [class.d-block]="creditCardTypeFormGroup.controls.systemKeyword.errors">{{creditCardTypeFormGroup.controls.systemKeyword.errorMessage}}<br /></small>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="CreditCardTypeAddEditComponent_DisplayPriority_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="displayOrder" class="form-control"  rxPlaceholder="CreditCardTypeAddEditComponent_DisplayPriority_p"/>
                  <small class="form-text text-danger" [class.d-block]="creditCardTypeFormGroup.controls.displayOrder.errors">{{creditCardTypeFormGroup.controls.displayOrder.errorMessage}}<br /></small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-auto">
            <div class="hr-line-dashed border-top w-100"></div>
            <div class="col text-right">
              <button (click)="editCreditCardType(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
              <button (click)="editCreditCardType(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
              <ng-container *ngIf="creditCardDelete" [rxLocalisationInit]="componentName">
                <button (click)="deleteCreditCardType()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
  