import {CarnetBase,CarnetNoteDocumentBase,CarnetsProductBase,CarnetDocumentBase,CarnetNoteBase,} from '@app/database-models'
//Generated Imports
export class Carnet extends CarnetBase 
{




//#region Generated Reference Properties
//#region carnetNoteDocuments Prop
carnetNoteDocuments : CarnetNoteDocumentBase[];
//#endregion carnetNoteDocuments Prop
//#region carnetsProducts Prop
carnetsProducts : CarnetsProductBase[];
//#endregion carnetsProducts Prop
//#region carnetDocuments Prop
carnetDocuments : CarnetDocumentBase[];
//#endregion carnetDocuments Prop
//#region carnetNotes Prop
carnetNotes : CarnetNoteBase[];
//#endregion carnetNotes Prop

//#endregion Generated Reference Properties











}