import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLeaveTypeLookUpBase {

        //#region leaveId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'leaveId', keyColumn: true })
        leaveId: number;
        //#endregion leaveId Prop


        //#region leaveTypeName Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'leaveTypeName', keyColumn: false })
        leaveTypeName: string;
        //#endregion leaveTypeName Prop

        //#region typeColor Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'leaveTypeColor', keyColumn: false })
        typeColor: string;
        //#endregion typeColor Prop

}