import { maxLength, minDate, prop, required } from "@rxweb/reactive-form-validators";

export class TaskDetailBase{
    @prop()
    taskDetailId: number;

    @prop()
    noteId: number;

    @prop()
    @maxLength({ value: 4000 })
    @required()
    taskDescription: string;

    @prop()
    @required()
    assignedByUserId: number

    @prop()
    @required()
    assignedToUserId: number

    //#region completionDate Prop
    @required()
    completionDate: Date
    //#endregion completionDate Prop

    //#region completedAt Prop
    @prop()
    completedAt: Date
    //#endregion completedAt Prop

    //#region isCompleted Prop
    @required()
    isCompleted: boolean;
    //#endregion isCompleted Prop

    //#region createdBy Prop
    @prop()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdOn Prop
    @prop()
    createdOn: Date;
    //#endregion createdOn Prop

    @prop()
    createdOnString: string;

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region modifiedOn Prop
    @prop()
    modifiedOn: Date;
    //#endregion modifiedOn Prop

    //#region Deleted Prop
    @prop({ defaultValue: 307 })
    statusId: number;
    //#endregion Deleted Prop

    @prop({defaultValue: false})
    isStatusCompleted: boolean;

    @prop({defaultValue: false})
    isAssignedByLoggedIn: boolean;

    @prop({defaultValue: false})
    isEditAccess: boolean;

    @prop({defaultValue: false})
    isDeleteAccess: boolean;

    @prop({defaultValue: false})
    isAssignedToLoggedIn: boolean;

    @prop()
    assignedByUserName: string;

    @prop()
    assignedToUserName: string;

    @prop()
    noteDescription: string;

    @prop({defaultValue: 0})
    entityId: number;

    @prop({defaultValue: 0})
    entityTypeId: number;

    @prop()
    taskComment: string;

    @prop({defaultValue: 0})
    commentsCount:number;

    @prop()
    recentComment: string;
}