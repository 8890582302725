import {ProductBatchPieceRepairBase,ProductPieceBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
//Generated Imports
export class ProductBatchPieceRepair extends ProductBatchPieceRepairBase implements FileDetailViewModel
{

@prop()
pieceNumber:string;
@prop({defaultValue:0})
showClientRepairApproval:boolean
@prop()
salesPersonName :string
@prop({defaultValue:0})
isApprove : number;
@prop()
estimationId:number;
//#region Generated Reference Properties
//#region productPiece Prop
productPiece : ProductPieceBase;
//#endregion productPiece Prop

//#endregion Generated Reference Properties

@prop()
fileData: string;
@prop()
fileExtention: string;
@prop()
fileName: string;
@prop()
displayImage: boolean;
@prop()
imageURL1: string;
@prop()
imageURL2: string;
@prop()
imageURL3: string;
@prop()
imageURL4: string;
@prop()
imageURL5: string;
@prop()
fileData1: string;
@prop()
fileExtention1: string;
@prop()
fileName1: string;
@prop()
fileData2: string;
@prop()
fileExtention2: string;
@prop()
fileName2: string;
















}