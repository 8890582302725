import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBackordersModeLookUpBase {

//#region backordersModeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'backordersModeId', keyColumn: true})
        backordersModeId : number;
//#endregion backordersModeId Prop


//#region backordersModeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'backordersModeName', keyColumn: false})
        backordersModeName : string;
//#endregion backordersModeName Prop

}