import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vFiltersLookupBase {

//#region filterId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'filterId', keyColumn: true})
        filterId : number;
//#endregion filterId Prop


//#region filterName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'filterName', keyColumn: false})
        filterName : string;
//#endregion filterName Prop

}