import {CategoryDiscountMappingBase,CategoryBase,} from '@app/database-models'
//Generated Imports
export class CategoryDiscountMapping extends CategoryDiscountMappingBase 
{




//#region Generated Reference Properties
//#region category Prop
category : CategoryBase;
//#endregion category Prop

//#endregion Generated Reference Properties




}