import { prop } from '@rxweb/reactive-form-validators';

export class TrackIncidentRawFormAffectedPerson {
    
    @prop({defaultValue:0})
    affectedPersonId: number

    @prop({defaultValue:0})
    trackIncidentFormId: number
    
    @prop()
    userName: string

    @prop()
    corporateGroup: string

    @prop({defaultValue:''})
    grievanceInjurySeverityId: string

    grievanceInjurySeverity: string

    @prop({defaultValue:''})
    grievanceInjurySeverityText: string

    @prop({defaultValue:''})
    grievanceInjuryTypeId: string

    grievanceInjuryType: string

    @prop({defaultValue:''})
    grievanceInjuryTypeText: string

    @prop({defaultValue:''})
    grievanceBodilyLocationId: string

    grievanceBodilyLocation: string
    
    @prop()
    condition: string

}
