import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Project, vUserLookUp, vDepartmentLookUp, vProjectTypeLookUp } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent, } from '@rxweb/angular-router';
import { FileOutputViewModel } from 'src/app/view-model/file-output-view-model';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from 'src/app/custom-enum/file-type-enums';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GanttChart } from 'src/app/domain/customize-plugin/gantt-chart';
import { vProjectOwnerLookUp } from 'src/app/models/database-models/v-project-owner-look-up';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@http({
    path: "api/Projects",
})

export class AbstractProject extends CoreComponent {
    projectFormGroup: IFormGroup<Project>
    spin: boolean = false;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    selectedTab: string = "project-edit";
    fileViewModel: FileInputViewModel;
    fileViewModelDoc: FileInputViewModel;
    fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
    fileOutputViewModelDoc: FileOutputViewModel = new FileOutputViewModel();
    isShowGridColumn: boolean = false;
    projectGrid: AppGrid;
    id: number = 0;
    router: Router;
    subscription: any;
    isShowGrid:boolean;
    isNoRecordFound:boolean;
    validField: string[] = ["projectName","projectDescription","projectOwner","projectStartDate","budget"];
    breakLoop: boolean;
    tabChange: boolean = false;
    constructor(private blobState: BlobUploadsViewStateService) {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        this.fileViewModel = new FileInputViewModel();
        this.fileViewModel.fileMode = FileTypeEnums.Image;
        this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
        this.fileViewModelDoc = new FileInputViewModel();
        this.fileViewModelDoc.fileMode = FileTypeEnums.Doc;
        this.fileViewModelDoc.fileAllowSize = FileSizeEnums.FourMB;
    }

    projectLookup: any = {
        projectTypeLookUp: vProjectTypeLookUp,
       // projectOwnerLookUp: vUserLookUp,
        projectOwnerLookUp: vProjectOwnerLookUp,
        departmentLookUp: vDepartmentLookUp,
        project: Project
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }

    activeTab(tabName: string) {
        this.tabChange = true;
        this.selectedTab = tabName;
        if (this.selectedTab == 'gantt-chart') {
            this.bindGanttChar();
        }
        this.router.navigate(["projects", this.id], { queryParams: { activeTab: tabName, tabChange: this.tabChange } });
    }

    async updateFiles($event: FileOutputViewModel) {
       
        this.fileOutputViewModel = $event;
        this.projectFormGroup.patchValue({
            fileData: $event.fileBaseString,
            fileExtention: $event.fileExtension,
            fileName: $event.fileName,
            displayImage: $event.displayImage,
            //blobFileName:uploadedFileName,
            blobPath:BLOB_BASE.ProjectFiles,
            size:$event.files.size
        });
    }

   async updateDocFiles($event: FileOutputViewModel) {
        
        this.fileOutputViewModelDoc = $event;
        this.projectFormGroup.patchValue({
          fileData1: $event.fileBaseString,
          fileExtention1: $event.fileExtension,
          fileName1: $event.fileName,
          //blobFileName2:uploadedFileName,
          blobPath2:BLOB_BASE.ProjectDocumentFiles,
          size2:$event.files.size
        });
      }
      async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
        let fileUploadRes = [];
        let newGUID = uuidv4();
        let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
          .split(".")
          .pop()}`;
        var res = await this.blobState.uploadItems($event.files, fileName);
        // fileUploadRes.push(res);
    
        let url = res._response.request.url;
        let uploadedFileName = blobPath + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
    
        // IF SUCCEED TO UPLOAD
        if (res && (res._response.status == 200 || res._response.status == 201)) {
          if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.toastr.error(
            "Error occured while uploading file! Please try again."
          );
          if (uploadedFileName != undefined) {
            this.modalView
              .show(BedRequestComponent, {
                validationMessages: JSON.stringify(
                  uploadedFileName
                ),
                headerMessage: "Image failed to upload:",
              })
              .then(() => { });
          }
        }
        return uploadedFileName;
      }

    applyClass() {
        this.projectGrid.designClass.rowClass = [function () {
            //let data = this;
            //if (data.roleId == CustomerRoleTypeEnum.Admin) {
            //  return "ratingAdmin";
            //}
        }]
    }

    removeImage() {
        this.projectFormGroup.patchValue({
            fileData: null,
            blobFileName:null,
            fileExtention: null,
            fileName: null,
            displayImage: false,
            isRemovedProjectPicture: true
        });

    }

    removeDoc(){
        this.projectFormGroup.patchValue({
            fileData1: null,
            blobFileName2:null,
            fileExtention1: null,
            fileName1: null,
            filePath: null,
            budgetCostCertificate: null,
            isRemovedCostFile: true
        });
    }

    bindGanttChar() {
        this.subscription = this.get({ params: [this.id], queryParams: { projOrGanttChart: 0, Flag: true} }).subscribe((data: any[]) => {
            setTimeout(() => {
                GanttChart(data);
            }, 0);
        });
    }
}
