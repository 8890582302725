import {IP2LocationsBase,} from '@app/database-models'
//Generated Imports
export class IP2Locations extends IP2LocationsBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}