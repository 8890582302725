import {vCompanyLookupBase,} from '@app/database-models'
//Generated Imports
export class vCompanyLookup extends vCompanyLookupBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}