<div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0" *rxMultilingual="">
    <div class="dataTables_filter  d-flex flex-wrap align-items-center mt-3">
      <label class="ml-auto mr-0 mb-3">
        <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
          placeholder="" [title]="titleSearch">
      </label>
    </div>
    <div class="table-responsive">
      <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4" *ngIf="isShowGrid">
        <div class="table-responsive">
          <rx-grid [design]="documentTypeNoteGrid"></rx-grid>
        </div>
      </div>
    </div>
    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
      <h4>No Record Found</h4>
    </div>
  </div>