import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription, Subject } from 'rxjs';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { getLocalizedValue, dateCompareValidation } from 'src/app/domain/common-logic/common-logic';
import { AbstractLegalAgreementProjectMapping } from '../domain/abstract-legal-agreement-project-mapping';
import { LegalAgreementProjectMappingFilterViewModel } from 'src/app/view-model/legal-agreement-project-mapping-filter-view-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { LegalAgreementProjectListViewModel } from 'src/app/models/extended-models/legal-agreement-project-list-view-model';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { AppGrid } from 'src/app/domain/app-grid';
import { LegalAgreementProjectMapping, LegalAgreementUserMapping } from '@app/models';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { multilingual } from '@rxweb/localization';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";
@multilingual("LegalAgreementProjectMappingAddComponent")
@Component({
    selector: "app-legal-agreement-project-mapping-add",
    templateUrl: './legal-agreement-project-mapping-add.component.html'
})

export class LegalAgreementProjectMappingAddComponent extends AbstractLegalAgreementProjectMapping implements OnInit, OnDestroy {

    @Input() mapId: number;
    @Input() hide: Function;
    @Input() entityTypeId: number;

    legalAgreementProject: LegalAgreementProjectMappingFilterViewModel;
    legalAgreementProjectSearchFormGroup: IFormGroup<LegalAgreementProjectMappingFilterViewModel>;
    LegalAgreementProjectList: LegalAgreementProjectListViewModel[];
    LegalAgreementProjectListGrid: AppGrid;

    legalAgreementProjectMappingInfo: LegalAgreementProjectMapping;
    legalAgreementProjectMappingArray: LegalAgreementProjectMapping[];
    excludedLegalAgreementProjectMappingArray: LegalAgreementProjectMapping[];
    subscription: any;
    legalAgreementProjectArray: LegalAgreementProjectListViewModel[];
    showSaveButton: boolean = true;
    path: string;
    isFilter: boolean = false;
    spin: boolean = false;
    totalRecords: number = 0;
    headerBtnChk: boolean = false;
    cloneArray: LegalAgreementProjectListViewModel[];
    legalAgreementProjectIds: number[];
    excludedlegalAgreementProjectIds:number[];
    showGrid: boolean = false;
    titleClose: string;
    ErrorMsg: string;
    searchPerson: string;
    dateError: boolean = false;
    filterJson:any;
    _this;

    constructor(private formBuilder: RxFormBuilder, modelView: ModalView, private browserStorage: BrowserStorage,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.legalAgreementProjectArray = new Array<LegalAgreementProjectListViewModel>();
        this.legalAgreementProjectIds = new Array<number>();
        this.cloneArray = new Array<LegalAgreementProjectListViewModel>();
        this.legalAgreementProjectMappingArray = new Array<LegalAgreementProjectMapping>();
      this.excludedLegalAgreementProjectMappingArray = new Array<LegalAgreementProjectMapping>();
      this.excludedlegalAgreementProjectIds = new Array<number>()
        this._this = this;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.lookup([
            { path: USER_LOOKUPS["agreementTypeLookUp"], propName: "agreementTypeLookUp" },
            { path: USER_LOOKUPS["gglEmployeesLookUp"], propName: "gglEmployeesLookUp" }
        ]).subscribe((lookup: any) => {
            this.legalAgreementProject = new LegalAgreementProjectMappingFilterViewModel();
            this.legalAgreementProject.pageIndex = PaginationEnum.PageIndex;
            this.legalAgreementProject.rowCount = PaginationEnum.RowCount;
            this.legalAgreementProjectSearchFormGroup = this.formBuilder.formGroup(this.legalAgreementProject) as IFormGroup<LegalAgreementProjectMappingFilterViewModel>;
            this.legalAgreementLookup = lookup;
            setTimeout(() => {
                this.spin = false;
                this.showComponent = true;
            }, 100);
        });
    }

    search() {
        this.cloneArray = [];
        this.cloneArray = new Array<LegalAgreementProjectListViewModel>();
        if(this.headerBtnChk) {
            this.legalAgreementProjectMappingArray = [];
            this.excludedLegalAgreementProjectMappingArray = [];
            this.legalAgreementProjectIds = [];
            this.excludedlegalAgreementProjectIds= [];
        }
        this.headerBtnChk = false;
        if (this.LegalAgreementProjectListGrid != null) {
            this.isFilter = false;
            this.LegalAgreementProjectListGrid.updateSource([]);
        }
        this.legalAgreementProjectSearchFormGroup.value.pageIndex = PaginationEnum.PageIndex;
        this.searchLegalAgreement();
    }

    searchLegalAgreement() {
        this.legalAgreementProjectSearchFormGroup.submitted = true;
        this.totalRecords = 0;
        if (dateCompareValidation(this.legalAgreementProjectSearchFormGroup.value.startDate, this.legalAgreementProjectSearchFormGroup.value.endDate) == true) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            this.showGrid = true;
            this.isShowGrid = true;
            this.showSaveButton = false;
            if (this.legalAgreementProjectSearchFormGroup.valid) {
                this.spin = true;
                var json = JSON.parse(JSON.stringify(this.legalAgreementProjectSearchFormGroup.value));
                json["userId"] = 0;

                if (this.entityTypeId == NotesEnum.ProjectNotes)
                    json["projectId"] = this.mapId;
                else if (this.entityTypeId == NotesEnum.EventNotes)
                    json["eventId"] = this.mapId;
                else if (this.entityTypeId == NotesEnum.CorporateGroupsNotes)
                    json["corporateId"] = this.mapId;
                else if (this.entityTypeId == NotesEnum.CustomerNotes)
                    json["personId"] = this.mapId;
                else if (this.entityTypeId == NotesEnum.RiskNotes)
                    json["riskId"] = this.mapId;
                this.filterJson = json;

                this.subscription = this.get(
                    {
                        path: "api/FormSearch/GetLegalAgreementMappingAddList",
                        queryParams: {
                            query: JSON.stringify(this.filterJson)
                        }
                    }
                ).subscribe((t: any) => {
                    this.spin = false;
                    if (this.LegalAgreementProjectListGrid != null) {
                        this.isFilter = true;
                    }
                    this.LegalAgreementProjectList = t[0].LegalAgreementData;
                    this.ErrorMsg = t[0].ErrorMessage;

                    if (this.LegalAgreementProjectList.length > 0) {
                        this.totalRecords = this.LegalAgreementProjectList[0].totalCount;
                        this.LegalAgreementProjectList.forEach(x => {
                            x.isChecked = false;
                        })
                        this.LegalAgreementProjectList.forEach(x => {
                            x.isChecked = this.headerBtnChk;
                        })
                        this.LegalAgreementProjectList.forEach(x => {
                            if (this.cloneArray.findIndex(z => z.legalAgreementId == x.legalAgreementId) == -1) {
                                this.cloneArray.push(x);
                            }
                            else {
                                this.cloneArray.forEach(z => {
                                    if (z.legalAgreementId == x.legalAgreementId) {
                                        x.isChecked = z.isChecked
                                    }
                                })
                            }
                            if(this.headerBtnChk) {
                                if(this.excludedlegalAgreementProjectIds.indexOf(x.legalAgreementId) != -1) {
                                    x.isChecked = false;
                                }
                            }
                            else {
                                if(this.legalAgreementProjectIds.indexOf(x.legalAgreementId) != -1){
                                    x.isChecked = true;
                                }
                            }   
                        })

                        this.updateLegalAgreementMappingArray();
                        this.isNoRecordFound = false;
                    }
                    if (this.LegalAgreementProjectList.length == 0) {
                        this.isNoRecordFound = true;
                        this.isShowGrid = false;
                    }
                    if (this.isNoRecordFound == false) {
                        if (!this.isFilter) {
                            this.LegalAgreementProjectListGrid = new AppGrid(this.LegalAgreementProjectList, LegalAgreementProjectListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onMoreSummaryClick: this.onMoreSummaryClick.bind(this) } });
                            this.LegalAgreementProjectListGrid.storeProcedure = {
                                length: this.totalRecords,
                                onPageChanging: this.onPageChanging.bind(this),
                                nextPage: 1,
                                onPageSorting: this.onPageSorting.bind(this)
                            }
                        }
                        else {
                            if (this.LegalAgreementProjectListGrid != null) {
                                this.LegalAgreementProjectListGrid.storeProcedure.length = this.totalRecords;
                                if (this.legalAgreementProjectSearchFormGroup.value.pageIndex == 1) {
                                    this.LegalAgreementProjectListGrid.storeProcedure.nextPage = 1;
                                }
                                this.LegalAgreementProjectListGrid.updateSource([]);
                                this.LegalAgreementProjectListGrid.updateSource(this.LegalAgreementProjectList);
                            }
                        }
                        this.LegalAgreementProjectListGrid.maxPerPage = this.legalAgreementProjectSearchFormGroup.value.rowCount;
                        this.LegalAgreementProjectListGrid.componentId = this.componentName;
                        this.LegalAgreementProjectListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                        this.LegalAgreementProjectListGrid.designClass.headerClass = "table-header-sticky".split(" ");

                        this.showSaveButton = true;
                    }
                    // if (this.legalAgreementProjectArray.length > 0 || this.headerBtnChk) {
                    //     this.showSaveButton = true;
                    // }
                    // else {
                    //     this.showSaveButton = false;
                    // }
                })
            }
        }
    }

    onMoreSummaryClick(t, x) {
        var element = document.getElementById("legalAgreementLASummary_" + t.legalAgreementId);
        var maskedElement = document.getElementById("maskedLASummary_" + t.legalAgreementId);
        if (element.className == "collapse") {
            x.srcElement.innerText = "Show Less";
            x.srcElement.className = "less-btn"
            element.classList.add("show");
            maskedElement.setAttribute("style", "display:none");
        }
        else {
            element.classList.remove("show");
            x.srcElement.innerText = "Show More";
            x.srcElement.className = "more-btn";
            maskedElement.setAttribute("style", "display:inline");
        }
    }

    updateLegalAgreementMappingArray() {
        this.cloneArray.forEach(x => {
            let legalAgreementProjectMappingObj = new LegalAgreementProjectMapping();
            legalAgreementProjectMappingObj.legalAgreementProjectMappingId = 0;
            legalAgreementProjectMappingObj.legalAgreementId = Number(x.legalAgreementId);
            legalAgreementProjectMappingObj.projectId = +this.mapId;
            if (x.isChecked) {
                if(this.headerBtnChk){
                    if(this.excludedLegalAgreementProjectMappingArray.findIndex(t => t.legalAgreementId == x.legalAgreementId) != -1 ) {
                        this.excludedLegalAgreementProjectMappingArray.splice(this.excludedLegalAgreementProjectMappingArray.findIndex(t => t.legalAgreementId == x.legalAgreementId),1);
                        this.excludedlegalAgreementProjectIds.splice(this.excludedLegalAgreementProjectMappingArray.findIndex(t => t.legalAgreementId == x.legalAgreementId),1);
                    }
                }
                else{
                    if (this.legalAgreementProjectIds.indexOf(Number(x.legalAgreementId)) == -1) {
                        this.legalAgreementProjectMappingArray.push(legalAgreementProjectMappingObj);
                        this.legalAgreementProjectIds.push(Number(x.legalAgreementId));
                    }
                }
            }
            else {
                if(this.headerBtnChk){
                    if (this.excludedLegalAgreementProjectMappingArray.findIndex(t => t.legalAgreementId == x.legalAgreementId) == -1) {
                        this.excludedLegalAgreementProjectMappingArray.push(legalAgreementProjectMappingObj);
                        this.excludedlegalAgreementProjectIds.push(x.legalAgreementId);
                      }
                }
                else{
                    if (this.legalAgreementProjectIds.indexOf(Number(x.legalAgreementId)) != -1) {
                        this.legalAgreementProjectMappingArray.splice(this.legalAgreementProjectIds.indexOf(Number(x.legalAgreementId)), 1);
                        this.legalAgreementProjectIds.splice(this.legalAgreementProjectIds.indexOf(Number(x.legalAgreementId)), 1);
                    }
                }
            }
        })
    }

    onCheckboxSelect(t, x) {
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<LegalAgreementProjectListViewModel>();

        oldCloneArray.forEach(data => {
            let newObj = new LegalAgreementProjectListViewModel();
            newObj.legalAgreementName = data.legalAgreementName;
            newObj.summary = data.summary;
            newObj.summaryCharCount = data.summaryCharCount;
            newObj.agreementTypeId = data.agreementTypeId;
            newObj.legalAgreementId = data.legalAgreementId;
            newObj.isChecked = data.legalAgreementId == t.legalAgreementId ? x.target.checked : data.isChecked
            newObj.agreementTypeName = data.agreementTypeName;
            newObj.date = data.date;
            newObj.leader = data.leader;
            newObj.leaderName = data.leaderName;
            newObj.signedAgreement = data.signedAgreement;
            newObj.isUnwelcome = data.isUnwelcome;
            newObj.isSigned = data.isSigned;
            newObj.totalCount = data.totalCount;
            this.cloneArray.push(newObj);

        });
        this.LegalAgreementProjectListGrid.updateSource([]);
        this.LegalAgreementProjectListGrid.updateSource(this.cloneArray);
        this.updateLegalAgreementMappingArray();
        // if (this.legalAgreementProjectArray.length > 0 || this.headerBtnChk) {
        //     this.showSaveButton = true;
        // }
        // else {
        //     this.showSaveButton = false;
        // }
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<LegalAgreementProjectListViewModel>();
        this.legalAgreementProjectMappingArray = [];
        this.excludedLegalAgreementProjectMappingArray = [];
        this.legalAgreementProjectIds = [];
        this.excludedlegalAgreementProjectIds= [];
        this.LegalAgreementProjectList.forEach(data => {
            let newObj = new LegalAgreementProjectListViewModel();
            newObj.legalAgreementName = data.legalAgreementName;
            newObj.summary = data.summary;
            newObj.summaryCharCount = data.summaryCharCount;
            newObj.agreementTypeId = data.agreementTypeId;
            newObj.legalAgreementId = data.legalAgreementId;
            newObj.isChecked = this.headerBtnChk;
            newObj.agreementTypeName = data.agreementTypeName;
            newObj.date = data.date;
            newObj.leader = data.leader;
            newObj.leaderName = data.leaderName;
            newObj.signedAgreement = data.signedAgreement;
            newObj.isUnwelcome = data.isUnwelcome;
            newObj.isSigned = data.isSigned;
            newObj.totalCount = data.totalCount;
            this.cloneArray.push(newObj);

        });
        // if (this.legalAgreementProjectArray.length > 0 || this.headerBtnChk) {
        //     this.showSaveButton = true;
        // }
        // else {
        //     this.showSaveButton = false;
        // }
        this.updateLegalAgreementMappingArray();
        this.LegalAgreementProjectListGrid.updateSource([]);
        this.LegalAgreementProjectListGrid.updateSource(this.cloneArray);
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.legalAgreementProjectSearchFormGroup.patchValue({
            sortOrder: y,
            orderBy: x
        });
        this.cloneArray = [];
        this.LegalAgreementProjectListGrid.storeProcedure.nextPage = z;
        this.searchLegalAgreement();
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.cloneArray = [];
        this.legalAgreementProjectSearchFormGroup.patchValue({
            pageIndex: x,
            rowCount: this.LegalAgreementProjectListGrid.maxPerPage
        });

        this.LegalAgreementProjectListGrid.storeProcedure.nextPage = x;
        this.searchLegalAgreement();
    }

    save(isRedirect){
       if(this.headerBtnChk){
        this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
        this.dialog.defaultConfig.showIcon = true;
        this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
        this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludedLegalAgreementProjectMappingArray.length)  + " Records";;
        this.dialog
              .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
              .then((t) => {
                if (t === DialogEnum.Ok) {
                  this.saveLegalAgreement(isRedirect);
                  this.dialog.hide();
                }
                else{
                  this.spin = false;
                  this.dialog.hide();
                }
              });
       }
       else{
        this.saveLegalAgreement(isRedirect);
       }
    }

    saveLegalAgreement(isRedirect) {
        var legalAgreementProjectMappingInfo = new LegalAgreementProjectMapping();
            legalAgreementProjectMappingInfo.projectId = +this.mapId;
            legalAgreementProjectMappingInfo.legalAgreementIds = this.legalAgreementProjectMappingArray;
        if(this.headerBtnChk){
                legalAgreementProjectMappingInfo.legalAgreementIds = this.excludedLegalAgreementProjectMappingArray;
                legalAgreementProjectMappingInfo.headerBtnCheck = this.headerBtnChk;
                this.filterJson["sortOrder"] = "false";
                this.filterJson["rowCount"] = this.totalRecords;
                this.filterJson["pageIndex"] = 1;
                legalAgreementProjectMappingInfo.query = JSON.stringify(this.filterJson);
                legalAgreementProjectMappingInfo.projectId = this.mapId;
        }

        this.spin = true;
        this.post({
            path: "api/SearchProject/AddLegalAgreementMapping",
            body: legalAgreementProjectMappingInfo,
            params: [this.entityTypeId]
        }).subscribe(data => {
            this.spin = false;
            var existsAlert = getLocalizedValue("Legal Agreement Added");
            if (existsAlert) {
                this.toastr.success(existsAlert);
            }
            this.legalAgreementProjectMappingArray = [];
            this.cloneArray = [];
            this.headerBtnChk = false;
            isRedirect ? this.searchLegalAgreement() : this.hide();
            //this.hide();
        });
    }

    ngOnDestroy(): void {

    }

    get componentName(): string {
        return "LegalAgreementProjectMappingAddComponent";
    }
}
