import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim, pattern, startsWith} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { startWith } from "rxjs/operators";


export class FileExtentionBase {

//#region salesChannelId Prop
        @prop()
        fileExtentionId : number;
//#endregion salesChannelId Prop


//#region salesChannelName Prop
        @required()
        @maxLength({value:255})
        //@trim()
        @notEmpty()        
       @startsWith({value:'.', message: "File Extention Name start with dot (.)"})
        fileExtentionName : string;
//#endregion salesChannelName Prop


//#region statusId Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @prop()
        statusId : number;
//#endregion statusId Prop

        @required()
        fileType:number;

}