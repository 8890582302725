import {RelatedProductBase,ProductBase,} from '@app/database-models'
import { RelatedProductsViewModel } from 'src/app/view-model/related-product-view-model';
import { propArray } from '@rxweb/reactive-form-validators';
import { RelatedProductsAddViewModel } from 'src/app/view-model/related-product-add-view-model';
//Generated Imports
export class RelatedProduct extends RelatedProductBase
{




//#region Generated Reference Properties
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties

@propArray(RelatedProductsViewModel)
relatedProductsInput:RelatedProductsViewModel[];

@propArray(RelatedProductsAddViewModel)
relatedProductsAddInput:RelatedProductsAddViewModel[];
    headerBtnChk: boolean;
    query: any;








}