import {UserRoleBase,} from '@app/database-models'
//Generated Imports
export class UserRole extends UserRoleBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}