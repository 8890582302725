import { PlatformLocation } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

@Component({
  selector: 'app-img-modal',
  templateUrl: './img-modal.component.html',
  styleUrls: ['./img-modal.component.css']
})
export class ImgModalComponent implements OnInit {

  @Input() imgSrc: any;
  @Input() isBarcode: boolean = false;
  @Input() hide: Function;
  titleClose: string;
  constructor(private location : PlatformLocation) {location.onPopState(() => this.hide()); }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
  }



}
