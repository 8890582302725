<div class="modal-dialog modal-lg">
    <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="CustomerHolidayBulkAddComponent_Title_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body pb-3" [formGroup]="userHolidayBulkAllocationFormGroup" [rxSpinner]="spin"
            *ngIf="showComponent" [rxLocalisationInit]="componentName">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label" rxText="CustomerHolidayBulkAddComponent_ForYear_t"></label>
                        <div class="col-md-8">
                            <rx-select #rxSelect [controlTabIndex]="3" [(source)]="vYearLookUp"
                                [selectPlaceholder]="'Select Year'" mainClass="form-control" formControlName="year"
                                [keyValueProps]="['year','id']"></rx-select>
                            <small class="form-text text-danger"
                                [class.d-block]="userHolidayBulkAllocationFormGroup.controls.year.errors">{{userHolidayBulkAllocationFormGroup.controls.year.errorMessage}}<br /></small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">
                <div class="modal-footer">
                    <ng-container>
                        <button (click)="onSubmit()" class="btn btn-success mr-1"
                            rxText="Btn_Save_t"></button>
                    </ng-container>
                    <button (click)="hide(false)" class="btn btn-outline-primary" rxText="Btn_Cancel_t"></button>
                </div>
            </div>
        </div>
    </div>
</div>