<div class="ibox mb-0 d-flex flex-column" *ngIf="uiSelection(taxProviderId,2)">
  <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
      <button class="btn btn-success btn-sm mb-3" (click)="onAdd()"><label
          rxText="TaxRateListComponent_Title"></label></button>
      <label class="ml-auto mr-0 mb-3">
        <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
          placeholder="" [title]="titleSearch">
      </label>
    </div>
    <div class="table-responsive" *ngIf="!isNoRecordFound">
      <div id="tax-rate" class="dataTables_wrapper pb-0 dt-bootstrap4">
      </div>
    </div>
    <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
      <h4>No Record Found</h4>
    </div>
  </div>

</div>

<div *ngIf="uiSelection(taxProviderId,3)">Strike Iron Basic (US and CA Only)</div>
<div *ngIf="uiSelection(taxProviderId,4)">
  Fixed Rate Tax


</div>
