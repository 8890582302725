import {vSizeTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSizeTypeLookUp extends vSizeTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}