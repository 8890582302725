import {TaxCategoryBase,TaxRateBase,} from '@app/database-models'
//Generated Imports
export class TaxCategory extends TaxCategoryBase 
{




//#region Generated Reference Properties
//#region taxRates Prop
taxRates : TaxRateBase[];
//#endregion taxRates Prop

//#endregion Generated Reference Properties












}