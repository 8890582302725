import {vSpecificationAttributeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSpecificationAttributeLookUp extends vSpecificationAttributeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}