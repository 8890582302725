import { gridColumn } from "@rxweb/grid";

export class MoveNoteDocumentRiskSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  //#region riskMenuDetailId Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-left"],
    visible: true,
    columnIndex: 0,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_RiskId_gh",
    keyColumn: true,
    configuredTemplate: { templateName: "reDirect" },
  })
  riskMenuDetailId: number;
  //#endregion riskMenuDetailId Prop

  //#region company Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "200px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 1,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_RiskCompany_gh",
    keyColumn: false,
  })
  company: string;
  //#endregion company Prop

  //#region riskCategoryId Prop
  @gridColumn({ visible: false, columnIndex: 3, keyColumn: false })
  riskCategoryId: number;
  //#endregion riskCategoryId Prop

  //#region riskCategoryName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-left"],
    visible: true,
    columnIndex: 4,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_RiskCategory_gh",
    parameter: false,
    keyColumn: false,
  })
  riskCategoryName: string;
  //#endregion riskCategoryName Prop

  //#region specificRiskId Prop
  @gridColumn({ visible: false, columnIndex: 5, keyColumn: false })
  specificRiskId: number;
  //#endregion specificRiskId Prop

  //#region specificRiskName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "150px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 6,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_RiskType_gh",
    keyColumn: false,
    parameter: false,
    configuredTemplate: { templateName: "specificRiskTooltip" },
  })
  specificRiskName: string;
  //#endregion specificRiskName Prop

  //#region isRelevant Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left" },
    class: ["text-center"],
    visible: true,
    columnIndex: 7,
    allowSorting: true,
    headerKey: "MoveNoteDocumentComp_RiskIsRelevent_gh",
    keyColumn: false,
    parameter: false,
    configuredTemplate: { templateName: "recordActive" },
  })
  isRelevant: boolean;
  //#endregion isRelevant Prop

  // @gridColumn({ style: { width: "1%", "text-align": "left", "min-width":"150px" }, parameter : false,  class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'RiskMenuDetailListComponent_LastUpdateBy_gh', keyColumn: false })
  lastUpdatesBy: string;

  //#region localRiskOwnerName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "150px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 12,
    allowSorting: true,
    parameter: false,
    headerKey: "MoveNoteDocumentComp_RiskLocalOwner_gh",
    keyColumn: false,
  })
  localRiskOwnerName: string;
  //#endregion localRiskOwnerName Prop

  //#region boardRiskOwnerName Prop
  @gridColumn({
    style: { width: "1%", "text-align": "left", "min-width": "150px" },
    class: ["text-left"],
    visible: true,
    columnIndex: 13,
    allowSorting: true,
    parameter: false,
    headerKey: "MoveNoteDocumentComp_RiskBoardOwner_gh",
    keyColumn: false,
  })
  boardRiskOwnerName: string;
  //#endregion boardRiskOwnerName Prop

  //#region noteId Prop
  @gridColumn({ visible: false, columnIndex: 12, keyColumn: false })
  noteId: number;
  //#endregion noteId Prop

  

  incidentCount: number;

  incidentDetails: any[];

  pressMediaCount: number;

  pressMediaDetails: any[];

  eventCount: number;

  eventDetails: any[];
  legalAgreementCount: number;

  legalAgreementDetails: any[];

  projectExcel: string;
  eventExcel: string;
  legalAgreementExcel: string;
  pressMediaExcel: string;
  incidentExcel: string;
}
