import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAdhocPagesBase {

//#region adhocName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'AdhocPagesListComponent_Name_gh', keyColumn: false})
        adhocName : string;
//#endregion adhocName Prop


//#region description Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'AdhocPagesListComponent_Description_gh', keyColumn: false})
        description : string;
//#endregion description Prop


//#region metakeyword Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'AdhocPagesListComponent_MetaKeyWord_gh', keyColumn: false})
        metakeyword : string;
//#endregion metakeyword Prop


//#region metaTitle Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'AdhocPagesListComponent_MetaTitle_gh', keyColumn: false})
        metaTitle : string;
//#endregion metaTitle Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'AdhocPagesListComponent_Published_gh', keyColumn: false,configuredTemplate: { templateName:"published" }})
        published : any;
//#endregion published Prop


//#region viewByPeople Prop
        // @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'AdhocPagesListComponent_ViewByPeople_gh', keyColumn: false,configuredTemplate: { templateName: "recordActive" }})
        // viewByPeople : any;
//#endregion viewByPeople Prop


//#region inspirations Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'AdhocPagesListComponent_Inspiration_gh', keyColumn: false})
        inspirations : string;
//#endregion inspirations Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'AdhocPagesListComponent_DisplayOrderId_gh', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop


//#region parentAdhocName Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'AdhocPagesListComponent_ParentAdhocName_gh', keyColumn: false})
        parentAdhocName : string;
//#endregion parentAdhocName Prop


//#region adhocId Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'AdhocPagesListComponent_AdhocId_gh', keyColumn: true})
        adhocId : number;
//#endregion adhocId Prop

//#region imagePictureId1 Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'imagePictureId1', keyColumn: false})
        imagePictureId1 : string;
//#endregion imagePictureId1 Prop


//#region imagePictureId2 Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'imagePictureId2', keyColumn: false})
        imagePictureId2 : string;
//#endregion imagePictureId2 Prop


//#region imagePictureId3 Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'imagePictureId3', keyColumn: false})
        imagePictureId3 : string;
//#endregion imagePictureId3 Prop

}