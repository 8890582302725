<div class="modal-dialog modal-dialog-centered" [ngClass]="isBarcode ? 'modal-md': 'modal-lg'">
  <div class="modal-content">
    <div class="modal-body" [ngClass]="isBarcode ? 'p-4': 'p-0'">
      <button type="button" class="close py-2 close-overlay" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
      <img [src]="imgSrc" [ngClass]="isBarcode ? 'w-auto h-auto': 'img-fluid w-100'">
    </div>
  </div>
</div>