import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractEvent } from '../domain/abstract-event';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Event, EventBooking, vBookingLookup, vEventCorporateMapping } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { ProjectEventMappingBase, EventBookingBase } from '@app/database-models';
import { EventTypeEnum } from 'src/app/enums/event-type.enum';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { DatePipe, PlatformLocation } from '@angular/common';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { ModalView } from "src/app/domain/customize-design/modal";
import { vEventCustomerInvloveListViewModel } from "src/app/view-model/v-event-customer-invlove-list-view-model";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BLOB_BASE, getBlobImgURL } from "src/app/domain/system-constant";
import { v4 as uuidv4 } from "uuid";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";


@multilingual("EventEditComponent")
@access({ accessLevel: RolePermissionEnums.Event, action: "put" })
@Component({
  selector: "app-event-edit",
  templateUrl: './event-edit.component.html'
})
export class EventEditComponent extends AbstractEvent implements OnInit, OnDestroy {

  subscription: any;
  eventName: string;

  tripName: string;

  entityTypeId: number = NotesEnum.EventNotes;
  entityId: number;
  bookingName: any = null;
  eventDelete: any = dbAccessModule[RolePermissionEnums.Event]["delete"];
  isAfterTabbing: boolean = false;
  showTab: boolean = false;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  fullName: any;
  moduleID: any;
  titleOpen: string;
  titleClose: string;
  isRestrictedEvent: boolean = false;
  loggedInUser: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;
  imageTooltip: string;
  eventCorporateMappingList: vEventCustomerInvloveListViewModel[];
  ProfileImageTag: string = "";
  titleMindMap: string;
  labelMindMap: string;
  isExpandAssignedByTask: boolean = true;
  isExpandAssignedToTask: boolean = false;
  // eventPut:any=EventPutComponent;


  _this;

  constructor(router: Router, private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, private sanitizer: DomSanitizer, private datePipe: DatePipe, private applicationBroadcaster: ApplicationBroadcaster, private el: ElementRef, private title: Title, private browserStorage: BrowserStorage, modelView: ModalView,private location : PlatformLocation,blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modelView;
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    });
    this.activatedRoute.queryParams.subscribe(t => {
      this.selectedTab = t['activeTab'];
    });
    this.activatedRoute.queryParams.subscribe(t => {
      if (t['tabChange']) {
        this.tabChange = t['tabChange'];
      }
    })
    if (this.browserStorage.local.get("userId") != null) {
      this.loggedInUser = this.browserStorage.local.get("userId");
    } else {
      this.loggedInUser = 0;
    }
    this.router = router;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'events', subMenu: '' })
    this.moduleID = RolePermissionEnums.Event;
    this.localizationData = (MultiLingualData.get(this.componentName) as any);
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.imageTooltip = getLocalizedValue("EventAddEdit_ImageUploadTooltip_t");
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleMindMap = getLocalizedValue("Title_Mind_Map");
    this.labelMindMap = getLocalizedValue("Visual_Mind_Map_t");
    this.titleOpen = getLocalizedValue("Title_Open");
    this.titleClose = getLocalizedValue("Title_Close");
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    this.title.setTitle("Events - G-Trac Admin");
    this.showComponent = false;
    this.showDropdown = false;
    this.showTab = false;
    this.spin = true;
    this.entityId = this.id;

    this.lookup([{ params: [this.id], propName: "event" },
    { path: USER_LOOKUPS["eventOwnerLookUp"], propName: "eventOwnerLookUp" },
    { path: USER_LOOKUPS["eventTypeLookUp"], propName: "eventTypeLookUp" },
    { path: 'api/SearchEvent/GetEventTrainerDeliveryMethod', propName: "eventTrainerDeliveryMethod" },
    { path: 'api/SearchEvent/GetEventTrainerCoveredArea', propName: "eventTrainerCoveredArea" },
    { path: 'api/SearchEvent/GetEventEmployeeRiskCategory', propName: "eventEmpoyeeRiskCategory" },
    { path: 'api/userlookups/EventCustomerInvloveList', propName: "eventInvolveLookUp", params: [this.id] },
    { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
    { path: USER_LOOKUPS.genfieldsGroupEntity, propName: "genfieldsGroupEntity" },
    { path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp" },
    { path: USER_LOOKUPS["eventServiceTypeLookUp"], propName: "eventServiceTypeLookUp" }
    ]).subscribe((response: any) => {
      setTimeout(() => {
        this.spin = false;
      }, 750);
      this.eventLookup = response;
      if (this.eventLookup.event == null || this.eventLookup.event == undefined) {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "NoRecordFound_Error");
        if (existsAlert) {
          this.toastr.error(existsAlert[0].showMessage);
        }
        this.router.navigate(['/events']);
      }
      else {
        this.bookingName = response.event.eventBooking.booking == null ? null : response.event.eventBooking.booking.tripName;
        this.checkRestrictedEvent();
      }
    
    });
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
  }

  checkRestrictedEvent() {
    if (this.eventLookup.event.isRestrictedEvent) {
      this.spin = false;
      this.eventCorporateMappingList = this.eventLookup.eventInvolveLookUp;
      if (this.eventCorporateMappingList.length > 0) {
        if (this.eventCorporateMappingList.findIndex(x => x.customerID == this.loggedInUser) == -1 && this.loggedInUser != this.eventLookup.event.reportFiledBy) {
          this.router.navigate(["events/restricted-event", this.id]);
        } else {
          if (!this.tabChange) {
            this.router.navigate(['/events', this.id], { queryParams: { activeTab: "event-info" } });
          } else {
            this.tabChange = false;
          }
          this.notRestrictedEvent();
        }
      } else {
        if (this.loggedInUser != this.eventLookup.event.reportFiledBy) {
          this.router.navigate(["events/restricted-event", this.id]);
        } else {
          if (!this.tabChange) {
            this.router.navigate(['/events', this.id], { queryParams: { activeTab: "event-info" } });
          } else {
            this.tabChange = false;
          }
          this.notRestrictedEvent();
        }
      }
    }
    else {
      if (!this.tabChange) {
        this.router.navigate(['/events', this.id], { queryParams: { activeTab: "event-info" } });
      } else {
        this.tabChange = false;
      }
      this.notRestrictedEvent();
    }
  }
  notRestrictedEvent() {
    this.event = new Event();
    this.event = this.eventLookup.event as Event;
    this.event.imageURL1 == null ? this.event.displayImage = false : this.event.displayImage = true;
    this.eventName = this.event.eventName;
    this.isRestrictedEvent = this.event.isRestrictedEvent;
    this.eventFormGroup = this.formBuilder.formGroup(Event, this.eventLookup.event) as IFormGroup<Event>;
    //  this.bookingName=response.event.eventBooking.booking==null?null:response.event.eventBooking.booking.tripName;
    this.eventFormGroup.controls.eventIndicator.setValidators([
      RxwebValidators.required({ message: "You can't leave this field empty" }),
      RxwebValidators.pattern({ expression: { 'eventIndicator': /^[0-9][0-9]?$|^100$/ }, message: 'Please enter correct value' })
    ]);
    this.eventFormGroup.controls.actualCost.setValidators([
      RxwebValidators.pattern({ expression: { 'actualCost': /^[0-9]\d{0,12}(\.\d{1,2})?$/ }, message: 'Please enter valid actual cost (13 digits and 2 decimal places are allowed)' })
    ]);
    this.eventFormGroup.controls.costPerGuest.setValidators([
      RxwebValidators.pattern({ expression: { 'costPerGuest': /^[0-9]\d{0,12}(\.\d{1,2})?$/ }, message: 'Please enter valid cost per guest (13 digits and 2 decimal places are allowed)' })
    ]);
    this.eventFormGroup.controls.budget.setValidators([
      RxwebValidators.required({ message: "You can't leave this field empty" }),
      RxwebValidators.pattern({ expression: { 'budget': /^[0-9]\d{0,12}(\.\d{1,2})?$/ }, message: 'Please enter valid budget (13 digits and 2 decimal places are allowed)' })
    ]);
    this.showComponent = true;
    this.showTab = true;

    if (this.eventFormGroup.value.imageURL1 != null) {
      if (!this.eventFormGroup.value.imageURL1.includes("default_image_560.png")) {
        this.ProfileImageTag = '<img src="' + this.eventFormGroup.value.imageURL1 + '" width="95" height="95" style="border-radius:50%" />'
      }
    }
  }
  async EditEventInfo(isRedirect: boolean) {
    this.eventFormGroup.submitted = true;
    let eventStartdate = new Date();
    let eventEndDate = new Date();
    eventStartdate = this.eventFormGroup.value.eventDate;
    eventEndDate = this.eventFormGroup.value.eventEndDate;

    if (dateCompareValidation(this.eventFormGroup.value.eventDate, this.eventFormGroup.value.eventEndDate) == true) {
      this.dateError = true;
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.eventFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
    else {
      this.dateError = false;
      if (this.eventFormGroup.valid) {
        this.eventFormGroup.patchValue({
          eventDate: eventStartdate,
          eventEndDate: eventEndDate

        });
        if (this.eventFormGroup.value.eventType != EventTypeEnum.Training) {
          this.eventFormGroup.patchValue({
            deliveryMethod: 0,
            trainerDetails: '',
            riskCategory: 0,
            trainingAreas: '',
            isAttendanceUploaded: false,
            isIncludeBusinessElement: false,
          })
        }
        this.spin = true;
        if (this.eventFormGroup.value.fileData) {

          let res = await this.uploadToBlob(this.fileOutputViewModel, this.eventFormGroup.value.blobPath);
          this.eventFormGroup.patchValue({
              fileData: null,
              blobFileName: res
          });
      }
        if (this.eventFormGroup.value.eventBooking.bookingID == undefined) {
          this.eventFormGroup.value.eventBooking.bookingID = 0;
        }

        if (this.eventFormGroup.value.eventBooking.bookingID == 0 || this.eventFormGroup.value.eventBooking.bookingID.toString() == "" || this.eventFormGroup.value.eventBooking.bookingID == undefined || this.eventFormGroup.value.eventBooking.bookingID == null) {
          this.eventFormGroup.value.eventBooking.bookingID = 0;
        }
        // const document = this.eventFormGroup.value;
        // let newGUID = uuidv4();
        // let fileName = BLOB_BASE.DocumentFiles+`${newGUID}_${new Date().toISOString()}.${document.fileName
        //   .split(".")
        //   .pop()}`;
        //   this.blobState.uploadItems(document.fileData, fileName)
        //   this.eventFormGroup.patchValue({BlobName: fileName})
        this.put({ body: this.eventFormGroup.value, params: [this.id] }).subscribe(data => {
          this.spin = false;

          //this.toastr.success("Data Updated Successfully");
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
          if (existsAlert) {
            this.toastr.success(existsAlert[0].showMessage);
          }
          if (isRedirect) {
            this.showComponent = false;
            this.ngOnInit();
          }
          else {
            this.router.navigate(['/events']);
          }

          //this.router.navigate(["events", data], { queryParams: { activeTab: 'event-info' } })
          // this.ngOnInit()
        })
      } else {
        this.breakLoop = false;
        this.validField.forEach(x => {
          if (this.eventFormGroup.controls[x].invalid && !this.breakLoop) {
            const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
            invalidControl.focus();
            this.breakLoop = true;
          }
        });
      }
    }
  }
  deliveryMethodValidation(event) {
    if (this.eventFormGroup.value.eventType != EventTypeEnum.Training) {
      this.eventFormGroup.get('deliveryMethod').clearValidators();
      this.eventFormGroup.get('deliveryMethod').updateValueAndValidity();
    }
    else {
      this.eventFormGroup.get('deliveryMethod').setValidators([RxwebValidators.required()]);
      this.eventFormGroup.get('deliveryMethod').updateValueAndValidity();
    }
  }
  getImgContent(): SafeUrl {
    let imgUrl = "data:" + this.eventFormGroup.controls.fileExtention.value + ";base64," + this.eventFormGroup.controls.fileData.value;
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
  }
  activeTab(tabName: string) {
    this.tabChange = true;
    this.selectedTab = tabName;
    this.router.navigate(["events", this.id], { queryParams: { activeTab: tabName, tabChange: this.tabChange } });
    if (this.selectedTab == 'event-info') {
      this.ngOnInit();
    }
  }

  /**
   * This method change the sate of flag to show or hide Assigned By Tasks
   *  */
  expandAssignedByTask() {
    this.isExpandAssignedByTask = !this.isExpandAssignedByTask;
  }

  /**
   * This method change the sate of flag to show or hide Assigned To Tasks
   *  */
  expandAssignedToTask() {
    this.isExpandAssignedToTask = !this.isExpandAssignedToTask;
  }

  DeleteEvent() {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [this.id] }).subscribe(data => {
          this.spin = false;
          //this.toastr.success("Data Deleted Successfully")
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
          if (existsAlert) {
            this.toastr.success(existsAlert[0].showMessage);
          }
          this.router.navigate(['/events']);
        })
      }
      this.dialog.hide()
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Event");
    if (existsAlert) {
      return existsAlert[0].showMessage;
    }
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "EventEditComponent";
  }

}
