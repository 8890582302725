import {ProductGemstoneCertificateBase,ProductPieceGemstoneBase,GemstoneLaboratoryBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
//Generated Imports
export class ProductGemstoneCertificate extends ProductGemstoneCertificateBase implements FileDetailViewModel
{

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1:string;
    @prop()
    fileExtention1:string;
    @prop()
    fileName1:string;
    @prop()
    fileData2:string;
    @prop()
    fileExtention2:string;
    @prop()
    fileName2:string;
    @prop()
    filePath: string;

    productGemstoneCertificates: Array<ProductGemstoneCertificate>;


//#region Generated Reference Properties
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop
//#region gemstoneLaboratory Prop
gemstoneLaboratory : GemstoneLaboratoryBase;
//#endregion gemstoneLaboratory Prop

//#endregion Generated Reference Properties

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;









}