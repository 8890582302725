import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vOrderStatusLookUpBase {

//#region orderStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'orderStatusId', keyColumn: true})
        orderStatusId : number;
//#endregion orderStatusId Prop


//#region orderStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'orderStatusName', keyColumn: false})
        orderStatusName : string;
//#endregion orderStatusName Prop

}