<!-- <div class="modal-dialog modal-lg" style="line-height:0.1;height:90%;overflow: auto;"> -->
<div class="modal-dialog modal-lg" style="line-height:0.1" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-content">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="RelatedProductDesignAddComponent_Title_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="relatedDesignSearchFormGroup"
            *ngIf="showComponent==true" [rxLocalisationInit]="componentName" (keyup.enter)="getDesigns()" [rxSpinner]="spin">
            <div>
                <!-- <div class="col-lg-6 col-xl-4 pr-xl-5"> -->
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-md-12 col-form-label"
                          rxText="RelatedProductDesignAddEditComponent_DesignTitle_t"></label>
                      <div class="col-md-12">
                          <input type="text" class="form-control" rxFocus formControlName="title"
                              rxPlaceholder="RelatedProductDesignAddEditComponent_DesignTitle_p" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label class="col-md-12 col-form-label"
                          rxText="RelatedProductDesignAddEditComponent_Category_t"></label>
                      <div class="col-md-12" [rxSelectExtended]="_this">
                          <rx-select #rxSelect [(source)]="categoryLookup" [selectPlaceholder]="'All'" mainClass="form-control"
                              formControlName="category" [keyValueProps]="['categoryName','categoryId']"></rx-select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- </div>

                        <div class="col-lg-6 col-xl-4 pr-xl-5"> -->

                <!-- </div>


                         <div class="col-lg-6 col-xl-4 pr-xl-5"> -->

                <!-- </div>   -->
                <!-- <div lass="col-lg-6 col-xl-4 pr-xl-5"> -->
                <div class="form-group row pull-right mr-3">
                    <button type="button" class="btn btn-success" (click)="getDesigns()" rxText="Btn_Search_t"></button>
                </div>
                <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                    <!-- <div class="dataTables_filter  d-flex flex-wrap align-items-center" *ngIf="totalRecords>0">

                                        </div> -->
                    <div class="table-responsive" *ngIf="isShowGrid">
                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                            <rx-grid [design]="relatedDesignGrid"></rx-grid>
                        </div>
                    </div>
                </div>
                <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                    <h4>{{ErrorMsg}}</h4>
                </div>

                <!-- </div>   -->
            </div>
        </div>
        <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
            <button type="button" class="btn btn-success" (click)="save()"
                [disabled]="(relatedProductDesign.relatedDesignsAddInput.length==0 && this.excludeRelatedProductDesign.relatedDesignsAddInput.length==0 && !this.headerBtnChk)
                || (this.totalRecords - this.excludeRelatedProductDesign.relatedDesignsAddInput.length ==0 )" rxText="Btn_Save_t"></button>
            <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
        </div>




    </div>
</div>