import { LeaveMasterBase, LeaveTravelEventProjectMappingBase } from '@app/database-models'

//Generated Imports
import { prop, propArray } from '@rxweb/reactive-form-validators';
import { EventCustomerMapping } from './event-customer-mapping';
import { EventCustomerMappingBase } from '@app/database-models'
import { LeaveFlightMappingBase } from '../database-models/leave-flight-mapping-base';
import { LeaveAccomodationMappingBase } from '../database-models/leave-accomodation-mapping-base';
import { LeaveVehicleMappingBase } from '../database-models/leave-vehicle-mapping-base';

export class LeaveMaster extends LeaveMasterBase {




  //#region Generated Reference Properties

  //#region LeaveTravelEventProjectMapping Prop
  @propArray(LeaveTravelEventProjectMappingBase)
  leaveTravelEventProjectMappings: LeaveTravelEventProjectMappingBase[];
  //#endregion LeaveTravelEventProjectMapping Prop

  //#region LeaveTravelEventProjectMapping Prop
  @propArray(LeaveVehicleMappingBase)
  leaveVehicleMappings: LeaveVehicleMappingBase[];
  //#endregion LeaveTravelEventProjectMapping Prop
  
  //#region LeaveTravelEventProjectMapping Prop
  @propArray(LeaveFlightMappingBase)
  leaveFlightMappings: LeaveFlightMappingBase[];
  //#endregion LeaveTravelEventProjectMapping Prop

  //#region leaveAccomodationMappings Prop
  @propArray(LeaveAccomodationMappingBase)
  leaveAccomodationMappings: LeaveAccomodationMappingBase[];
  //#endregion leaveAccomodationMappings Prop
  
  //#region LeaveTravelEventProjectMapping Prop
  @propArray(EventCustomerMappingBase)
  eventCustomerMappings: EventCustomerMappingBase[];
  //#endregion LeaveTravelEventProjectMapping Prop

  //#endregion Generated Reference Properties

  @prop()
  eventIds: string;
  @prop()
  projectIds: string; 
  @prop()
  corporatIds: string;
  @prop()
  balance: number;
  @prop()
  reportToName: string;
  @prop()
  IsEditLeaveApply: boolean;
  @prop()
  clickedButtonType: number;

  @prop()
  file: File;
  @prop()
  fileData: string;
  @prop()
  fileExtention: string;
  @prop()
  fileName: string;
  @prop()
  fileUrl: string;

  @prop({ defaultValue: false })
  approveEmailLink: boolean;

  @prop()
  leaveUserFullName: string;

  @prop()
  reportToNames: string;

  @prop()
  reportToNamesWithHOD: string;

  @prop()
  reportToIds: string;

  @prop()
  reportToEmailIds: string;

  @prop({ defaultValue: false })
  isVisibleTravelType: boolean;

  @prop({ defaultValue: false })
  isOnBehalf: boolean;

  @prop()
  travelReportToNames:string;
  @prop()
  fileData1: string;
  @prop()
  blobFileName: string;
  @prop()
  blobPath: string;
  @prop()
  size: number;
}
