import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const PRODUCTION_ORDER_FORM_EXPAND: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        isBind: (x) => {
          return x.formType == "REPAIR FORM" || x.formType == "WATCH REPAIR" ? false : true;
        },
        event: {
          click: "onNoteExpand"
        },
        childrens: [{
          i: {
            class: "fa fa-plus fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_Expand") }
          }
        }],
        class: ["mr-2"]
      }
    }]
  }
};