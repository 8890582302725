import { prop,propObject,propArray,required,maxLength,range, numeric, maxNumber, pattern  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { __values } from 'tslib';


export class GemstoneOrderGemstoneMapingBase {

//#region mapingID Prop
        @prop()
        mapingID : number;
//#endregion mapingID Prop


//#region gemStoneOrderID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemStoneOrderID : number;
//#endregion gemStoneOrderID Prop


//#region gemStoneID Prop     
        @numeric()        
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemstoneId : number;
//#endregion gemStoneID Prop


//#region invoicePrepared Prop
        @required()
        invoicePrepared : boolean;
//#endregion invoicePrepared Prop





}
