import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHelpSupportLookUpBase {

//#region helpSupportId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'helpSupportId', keyColumn: true})
        helpSupportId : number;
//#endregion helpSupportId Prop


//#region helpSupportName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'helpSupportName', keyColumn: false})
        helpSupportName : string;
//#endregion helpSupportName Prop

}