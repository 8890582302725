import { prop, propObject, propArray, required, maxLength, range, notEmpty, numeric, digit } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventBase {

  //#region eventID Prop
  @prop()
  eventID: number;
  //#endregion eventID Prop


  //#region eventGUID Prop
  @required()
  eventGUID: any;
  //#endregion eventGUID Prop


  //#region eventName Prop
  @required()
  @notEmpty()
  @maxLength({ value: 255 })
  eventName: string;
  //#endregion eventName Prop


  //#region eventFormat Prop
  @required()
  @notEmpty()
  @maxLength({ value: 2000 })
  eventFormat: string;
  //#endregion eventFormat Prop


  //#region eventDescription Prop
  @required()
  @notEmpty()
  @maxLength({ value: 4000 })
  eventDescription: string;
  //#endregion eventDescription Prop


  //#region eventDate Prop
  @required()
  @prop()
  eventDate: Date;
  //#endregion eventDate Prop


  //#region location Prop
  @required()
  @notEmpty()
  @maxLength({ value: 200 })
  location: string;
  //#endregion location Prop

  //#region countryId Prop
  @prop()
  countryId: number;
  //#endregion countryId Prop

  //#region budget Prop
  //   @required()
  @prop({ defaultValue: 1 })
  //   @numeric()
  //   @range({minimumNumber:1,maximumNumber:2147483647})
  budget: number;
  //#endregion budget Prop


  //#region actualCost Prop
  @prop({ defaultValue: 0 })
  @numeric()
  //@range({minimumNumber:0,maximumNumber:2147483647})
  actualCost: number;
  //#endregion actualCost Prop


  //#region costPerGuest Prop
  @prop({ defaultValue: 0 })
  @numeric()
  //@range({minimumNumber:0,maximumNumber:2147483647})
  costPerGuest: number;
  //#endregion costPerGuest Prop


  //#region reportFiledBy Prop
  @required()
  @prop({ defaultValue: 0 })
  reportFiledBy: number;
  //#endregion reportFiledBy Prop


  //#region salesLeads Prop
  //@prop({defaultValue:" "})
  @prop()
  salesLeads: string;
  //#endregion salesLeads Prop


  //#region createdOn Prop
  @required()
  createdOn: Date;
  //#endregion createdOn Prop


  //#region updatedOn Prop
  @prop()
  updatedOn: Date;
  //#endregion updatedOn Prop


  //#region deleted Prop
  @prop()
  deleted: boolean;
  //#endregion deleted Prop


  //#region budgetCostCertificate Prop
  @maxLength({ value: 255 })
  budgetCostCertificate: string;
  //#endregion budgetCostCertificate Prop


  //#region eventEndDate Prop
  @prop()
  eventEndDate: Date;
  //#endregion eventEndDate Prop


  //#region eventIndicator Prop
  @prop({ defaultValue: 0 })
  // @digit({messageKey:'eventIndicator',message:'Please enter correct value'})
  // @range({minimumNumber:0,maximumNumber:100})
  eventIndicator: number;
  //#endregion eventIndicator Prop


  //#region eventType Prop
  @required()
  @prop({ defaultValue: 0 })
  eventType: number;
  //#endregion eventType Prop


  //#region budgetCostCertificate1 Prop
  @maxLength({ value: 255 })
  budgetCostCertificate1: string;
  //#endregion budgetCostCertificate1 Prop


  //#region budgetCostCertificate2 Prop
  @maxLength({ value: 255 })
  budgetCostCertificate2: string;
  //#endregion budgetCostCertificate2 Prop


  //#region eInvitationDescription Prop
  @prop()
  eInvitationDescription: string;
  //#endregion eInvitationDescription Prop


  //#region website Prop
  // @prop({defaultValue:" "})
  @prop()
  website: string;
  //#endregion website Prop


  //#region pictureID Prop
  //  @range({minimumNumber:0,maximumNumber:2147483647})
  @prop()
  pictureID: number;
  //#endregion pictureID Prop


  //#region statusId Prop
  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
  statusId: number;
  //#endregion statusId Prop

  //#region isRestrictedEvent Prop
  @prop({ defaultValue: false })
  isRestrictedEvent: boolean;
  //#endregion isRestrictedEvent Prop

  @required()
  //@prop({defaultValue:0})
  @prop()
  deliveryMethod: number;

  @prop()
  trainerDetails: string;

  @prop()
  riskCategory: number;

  @prop()
  trainingAreas: string;

  @prop({ defaultValue: false })
  isAttendanceUploaded: boolean;

  @prop({ defaultValue: false })
  isIncludeBusinessElement: boolean;

  @required()
  gemfieldsGroupCompanyEventOwner: number;

  @required()
  department: number;

}
