import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HelpSupportBase {

//#region helpSupportId Prop
        @prop()
        helpSupportId : number;
//#endregion helpSupportId Prop


//#region name Prop
        @required()
        @maxLength({value:50})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region parentHelpSupportId Prop
        @prop()
        parentHelpSupportId : number;
//#endregion parentHelpSupportId Prop


//#region description Prop
        // @required()
        // @notEmpty()
        @maxLength({value:4000})
        @trim()
        description : string;
//#endregion description Prop


//#region displayOrderId Prop
        @prop()
        @numeric()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region body Prop
        @prop()
      //  @maxLength({value:4000})
        @trim()
        body : string;
//#endregion body Prop


//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}
