import { actionColumn, gridColumn } from '@rxweb/grid';

export class MoveNoteDocumentLegalAgreementSearchViewModel {

    id: number;

    @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
    isChecked: boolean

    isAllowMoveNoteDocument: boolean

    //#region legalAgreementId Prop
    @gridColumn({ parameter: true, style: { width: "1%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_LADAgreementId_gh', keyColumn: true, configuredTemplate: { templateName: 'legalAgreementRedirect' } })
    legalAgreementId: number;
    //#endregion legalAgreementId Prop

    //#region legalAgreementName Prop
    @gridColumn({ parameter: false, style: { width: "2%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, headerKey: 'MoveNoteDocumentComp_LADAgreementName_gh', keyColumn: false })
    legalAgreementName: string;
    //#endregion legalAgreementName Prop

    //#region summary Prop
    @gridColumn({
        parameter: false,
        template: {
            div: {
                style: {
                    "word-break": "break-all",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.summary;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.summaryCharCount > 100) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreSummaryClick"
                        }
                    },
                }]
            }
        }, style: { width: "2%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'MoveNoteDocumentComp_LADSummary_gh', keyColumn: false
    })
    summary: string;
    //#endregion summary Prop

    //#region agreementTypeId Prop
    @gridColumn({ visible: false, columnIndex: 3, keyColumn: false })
    agreementTypeId: number;
    //#endregion agreementTypeId Prop

    //#region agreementTypeName Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, headerKey: 'MoveNoteDocumentComp_LADType_gh', keyColumn: false})
    agreementTypeName: string;
    //#endregion agreementTypeName Prop

    //#region date Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'MoveNoteDocumentComp_LADDate_gh', keyColumn: false })
    date: string;
    //#endregion date Prop

    //#region expiryDate Prop
    @gridColumn({ parameter: false, style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, headerKey: 'MoveNoteDocumentComp_LADExpiryDate_gh', keyColumn: false})
    expiryDate: string;
    //#endregion expiryDate Prop

    //#region leader Prop
    @gridColumn({ visible: false, columnIndex: 7, keyColumn: false })
    leader: number;
    //#endregion leader Prop

    //#region leaderName Prop
    @gridColumn({ parameter: false, style: { width: "2%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, headerKey: 'MoveNoteDocumentComp_LADLeader_gh', keyColumn: false, configuredTemplate: { templateName: "reDirectPeopleAndTooltip" }})
    leaderName: string;
    //#endregion leaderName Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 12, keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    notes: string
    noteCharCount:any;
    isAccess : boolean;
    userId:any;
}
