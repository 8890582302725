import { prop, required } from '@rxweb/reactive-form-validators';

export class RiskProjectMappingBase {

    //#region projectRiskMappingId Prop
    @prop({ defaultValue: 0 })
    projectRiskMappingId: number;
    //#endregion projectRiskMappingId Prop

    //#region riskMenuDetailId Prop
    @required()
    @prop({ defaultValue: 0 })
    riskMenuDetailId: number;
    //#endregion riskMenuDetailId Prop

    //#region ProjectId Prop
    @required()
    @prop({ defaultValue: 0 })
    ProjectId: number;
    //#endregion ProjectId Prop

}

