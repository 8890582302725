import {GemfieldsStationBase,} from '@app/database-models'
//Generated Imports
export class GemfieldsStation extends GemfieldsStationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}