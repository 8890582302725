import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vWeekDayLookUpBase {

//#region weekDayId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'weekDayId', keyColumn: true})
        weekDayId : number;
//#endregion weekDayId Prop


//#region weekDayName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'weekDayName', keyColumn: false})
        weekDayName : string;
//#endregion weekDayName Prop

}