import { TemplateConfig } from '@rxweb/grid';

export const CLIENT_REPAIR: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {

                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onOneLink"
                    },
                    childrens: [{
                        text: {
                            text: function (e) {
                                return this[e.name];
                            }
                        }
                    }]
                },                
            }
        ]
    },
};