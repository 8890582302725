import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BespokeOrderCustomerMappingBase {

//#region customerBespokeOrderID Prop
        @prop()
        customerBespokeOrderID : number;
//#endregion customerBespokeOrderID Prop


//#region customerID Prop
        @prop()
        customerID : number;
//#endregion customerID Prop


//#region supplierID Prop
        @prop()
        supplierID : number;
//#endregion supplierID Prop


//#region bespokeOrderID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        bespokeOrderID : number;
//#endregion bespokeOrderID Prop


//#region noteID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        noteID : number;
//#endregion noteID Prop


//#region documentID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        documentID : number;
//#endregion documentID Prop


//#region clientRepairID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        clientRepairID : number;
//#endregion clientRepairID Prop


//#region pieceID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pieceID : number;
//#endregion pieceID Prop


//#region mappingType Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        mappingType : number;
//#endregion mappingType Prop





}