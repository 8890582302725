import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SharedModule } from '../../shared/shared.module';
import { FileModule } from '../../shared/global/file/file.module';
import { RelatedProductDesignAddComponent } from '../related-product-design/add/related-product-design-add.component';
import { SystemTableSettingModule } from '../../shared/system-table-settings/system-table-setting.module';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule, SharedModule, FileModule, SystemTableSettingModule
    ],
    declarations: [RelatedProductDesignAddComponent],
    providers: []
})
export class ProductDesignSharedModule { }

