import {GemStoneColorGemfieldBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneColorGemfield extends GemStoneColorGemfieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop

//#endregion Generated Reference Properties












}