import {LocaleStringResourceBase,LanguageBase,} from '@app/database-models'
//Generated Imports
export class LocaleStringResource extends LocaleStringResourceBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop

//#endregion Generated Reference Properties




}