<div class="modal-dialog modal-lg" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="modal-content" >
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="CorporateOrdersDetailComponent_Item_Purchased_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div  [rxSpinner]="spin">
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="table-responsive">
          <div class="dataTables_wrapper pb-0 dt-bootstrap4">
            <rx-grid [design]="fabergeOrderDetailGrid"></rx-grid>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="hide()" class="btn btn-outline-primary ml-0">Close</button>
    </div>
  </div>
</div>