import {vCarrierGemfieldsRecordBase,} from '@app/database-models'
//Generated Imports
export class vCarrierGemfieldsRecord extends vCarrierGemfieldsRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}