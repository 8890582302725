import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    allowSorting: false,
    style: { width: "5%","text-align": "initial"},
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
  })

export class ProjectUserListViewModel {

    @gridColumn({ style: { width: "0.1%", "text-align": "initial" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region id Prop
    @gridColumn({visible: false,  columnIndex: 1, headerKey: 'ProjectCorpMappingAddEditComponent_Id_gh', keyColumn: true })
    id: number;
    //#endregion id Prop

    //#region fullName Prop
    @gridColumn({style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName: 'peopleNameAndTooltip' }, columnIndex: 2, allowSorting: true, headerKey: 'ProjectCorpMappingAddEditComponent_FullName_gh', keyColumn: false })
    fullName: number;
    //#endregion fullName Prop

    //#region email Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ProjectCorpMappingAddEditComponent_Email_gh', keyColumn: false })
    email: number;
    //#endregion email Prop

    @gridColumn({visible: false, columnIndex: 4, allowSorting: false,  name: "rating", style: { width: "7%", "text-align": "initial" }, configuredTemplate: { templateName: "peopleRating" }, headerKey: 'ProjectCorpMappingAddEditComponent_Rating_gh', keyColumn: false })
    rating:number
    //#region statusId Prop
    @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ProjectCorpMappingAddEditComponent_StatusId_gh', keyColumn: false, configuredTemplate: { templateName: "recordStatus" } })
    statusId: string;
    //#endregion statusId Prop

    //#region registrationDate Prop
    @gridColumn({ style: { width: "5%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ProjectCorpMappingAddEditComponent_RegDate_gh', keyColumn: false })
    registrationDate: string;
    //#endregion registrationDate Prop


    //#region corporate Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, configuredTemplate: { templateName: 'corporateNameAndTooltip'}, columnIndex: 7, allowSorting: true, headerKey: 'ProjectCorpMappingAddEditComponent_Corporate_gh', keyColumn: false })
    corporate: string;
    //#endregion corporate Prop

    @prop()
    roleId: number;


    @prop()
    totalCount: number

    @prop()
    contactPermissionRequired: boolean;

    @prop()
    introducesBy: string;

    @prop()
    isUnwelcome: boolean;
}