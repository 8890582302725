import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class HomeLayoutDetailBase {

        //#region detailId Prop
        @prop()
        detailId: number;
        //#endregion detailId Prop


        //#region layoutId Prop
        @prop()
        layoutId: number;
        //#endregion layoutId Prop


        //#region title Prop
        //@required()
        @prop({defaultValue: ""})
        title: string;
        //#endregion title Prop


        //#region subTitle Prop
        //@required()
        @prop({defaultValue: ""})
        subTitle: string;
        //#endregion subTitle Prop


        //#region mType Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        @prop()
        mType: number;
        //#endregion mType Prop


        //#region description Prop
        //@required()
        @prop({defaultValue: ""})
        description: string;
        //#endregion description Prop


        //#region src Prop
        //@required()
        @prop({defaultValue: ""})
        src: string;
        //#endregion src Prop


        //#region url Prop
        //@required()
        @prop({defaultValue: ""})
        url: string;
        //#endregion url Prop


        //#region languageId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        //@required()
        @prop({defaultValue:7})
        languageId: number;
        //#endregion languageId Prop


        //#region mSrc Prop
        @prop()
        mSrc: string;
        //#endregion mSrc Prop

        //#region displayBlockNo Prop
        @prop()
        displayBlockNo: number;
        //#endregion displayBlockNo Prop

        @prop()
        imageAlt:string;

        @prop({defaultValue:false})
        isShoppingDisable:boolean;
}