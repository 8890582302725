import { gridColumn } from '@rxweb/grid';

export class HSEReportIncidentData{

    @gridColumn({ visible: false, columnIndex: 0, allowSorting: false, keyColumn: false })
    IncidentID: number

    @gridColumn({
        template: {
          div: {
            style: {
              "word-break": "break-all;",
            },
            attributes: {
              innerHTML: function (x, y) {
                if (this.incidentURL == null) {
                  return "";
                } else {
    
                  return this.incidentURL;
                }
              }
            }
          }
        }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'HseReportAddComponent_RefNo_gh', keyColumn: false
      })
      incidentURL: string;

    @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'HseReportAddComponent_RefNo_gh', keyColumn: false,  configuredTemplate: { templateName:"reDirect"}})
    Ref_No: string

    @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'HseReportAddComponent_CompanyName_gh', keyColumn: false })
    CompanyName: string

    @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'HseReportAddComponent_OccDescription_gh', keyColumn: false })
    OccurrenceDescription: string

    @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'HseReportAddComponent_IncType_gh', keyColumn: false })
    TypeOfIncident: string
  
    //@gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'HseReportAddComponent_HSEReptStatus_gh', keyColumn: false })
    HSEReportStatus: string
 
}
