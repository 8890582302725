export enum NotesEnum {
        CarnetDocuments = 309,
        PieceNotesDocuments = 310,
        GFGemstoneNotesDocument = 311,
        IncidentAccidentNearDashMissNotes = 290,
        ProductVariantNotes = 291,
        EventNotes = 292,
        ProjectNotes = 293,
        GemfieldOrderNotes = 294,
        ITTicketNotes = 295,
        GemFieldsBookingNotes = 296,
        TemporaryAssigneeNotes = 297,
        BoutiqueNotes = 298,
        FabergeBookingNotes = 299,
        CarnetsNotes = 300,
        GemStoneNotes = 301,
        POSNotes = 302,
        ProductNotes = 303,
        CustomerNotes = 304,
        ProductVariantBatchPieceNotes = 305,
        OrderNotes = 306,
        TripNotes = 1394, //1420 for local
        BookingNotes = 1395, //1421 for local
        CorporateGroupsNotes = 1397, //1423 for local
        NewsNotes = 1458, //1484 for local
        RiskNotes = 1488, // for STG
        PressMediaNote=1492,// 1517 for local
        LegalAgreementNotes = 1495, //1519 local
        ProductionOrderFormNotes = 1497, // 1521 local       //1497 stg
        ElectronicGiftFormNotes = 1536, // 1548 local       //1536 stg
        GoalsNotificationNotes = 1538, // 1549 local       //1538 stg
        WatchPartNotes = 2543,  // 1554 local         // 2543 stg        // 1529 UAT/PRD
        DesignCategoryNotes = 3546, // 2559 local       // 3546 stg
        MainCollectionNotes = 3547, // 2560 local       //3547 stg
        CustomisedPageNotes = 3548,  //2561 local        //3548 stg
        LegalAgreementAccessPermissionNotes = 4546,     //2562 local    //4546 stg      //uat
        AdditionalGroupCompanyUserMappingNotes = 6546,   //3562 local    //6546 stg      //1534 uat
        PersonalDetailAccessPermissionNotes=6547,  //3563 local //6547 stg //1536 uat
        ProductBatchNotes = 9546,  //5564 local //9546 stg //1543 uat
        ProductionOrderFormInfoNotes = 12553,  //1549 UAT/PRD   // 12553 stg
        FabergePageNotes = 13562,
        MenuLayoutNotes = 13564,
        HomePageLayoutNotes = 13566,
        GemstoneOrderPageNotes = 13565,
        DesktopAssets = 13603,
        MobileAssets = 13604,
        ApplicationObject=13647,
        CustomerCallMeNotes = 1504
}
