import {vNoteTypesLookUpBase,} from '@app/database-models'
//Generated Imports
export class vNoteTypesLookUp extends vNoteTypesLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}