import {ProductVarintBatchPieceDeliveryStatuBase,} from '@app/database-models'
//Generated Imports
export class ProductVarintBatchPieceDeliveryStatu extends ProductVarintBatchPieceDeliveryStatuBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}