import { LeaveMasterHolidayProcessViewModelBase } from '../database-models/leave-master-holiday-process-view-model-base';
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
// @actionColumn({
//     allowSorting: false,
//     style: { width: "2%" },
//     name: "action",
//     visible: false,
//     configuredTemplate: { templateName: 'editModel' }, columnIndex: 10, headerTitle: "Action"
// })
export class LeaveMasterHolidayProcessViewModel extends LeaveMasterHolidayProcessViewModelBase {

    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'editModel' }, columnIndex: 10, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 11, headerTitle: "Delete"
    })
    deleteAction:any;
    //#region Generated Reference Properties

    //#endregion Generated Reference Properties





}