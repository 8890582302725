import {vChainGaugeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vChainGaugeLookUp extends vChainGaugeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}