import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBangleSizeLookUpBase {

//#region bangleSizeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'bangleSizeId', keyColumn: true})
        bangleSizeId : number;
//#endregion bangleSizeId Prop


//#region bangleSizeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'bangleSizeName', keyColumn: false})
        bangleSizeName : string;
//#endregion bangleSizeName Prop

}