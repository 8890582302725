import {vCategoryRecordBase,} from '@app/database-models'
//Generated Imports
export class vCategoryRecord extends vCategoryRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}