import { gridColumn } from "@rxweb/grid"

export class vTrackIncidentRawFormLookUpBase {

//#region trackIncidentFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'trackIncidentFormId', keyColumn: true})
        trackIncidentFormId : number;
//#endregion trackIncidentFormId Prop


//#region ref_No Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'ref_No', keyColumn: false})
        ref_No : string;
//#endregion ref_No Prop
}