import {LookupIncidentTypeBase,IncidentTypeBase,} from '@app/database-models'
//Generated Imports
export class LookupIncidentType extends LookupIncidentTypeBase 
{




//#region Generated Reference Properties
//#region incidentTypes Prop
incidentTypes : IncidentTypeBase[];
//#endregion incidentTypes Prop

//#endregion Generated Reference Properties





































}