import {vBangleSizeRecordBase,} from '@app/database-models'
//Generated Imports
export class vBangleSizeRecord extends vBangleSizeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}