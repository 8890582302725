import {vCustomerRegistrationTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCustomerRegistrationTypeLookUp extends vCustomerRegistrationTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}