import {vChainColourLookUpBase,} from '@app/database-models'
//Generated Imports
export class vChainColourLookUp extends vChainColourLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}