import {vAddressBase,} from '@app/database-models'
//Generated Imports
export class vAddress extends vAddressBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}