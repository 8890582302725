import {GemfieldSalesChannelBase,} from '@app/database-models'
//Generated Imports
export class GemfieldSalesChannel extends GemfieldSalesChannelBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}