import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPriceListExportModeLookUpBase {

//#region priceListExportModeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'priceListExportModeId', keyColumn: true})
        priceListExportModeId : number;
//#endregion priceListExportModeId Prop


//#region priceListExportModeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'priceListExportModeName', keyColumn: false})
        priceListExportModeName : string;
//#endregion priceListExportModeName Prop

}