import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMeasureWeightBase {

//#region measureWeightId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'MeasureWeightListComponent_MeasureWeightId_gh', keyColumn: true})
        measureWeightId : number;
//#endregion measureWeightId Prop


//#region measureWeightName Prop
        @gridColumn({isAscending:true, style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'MeasureWeightListComponent_MeasureWeightName_gh', keyColumn: false})
        measureWeightName : string;
//#endregion measureWeightName Prop


//#region systemKeyword Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'MeasureWeightListComponent_SystemKeyword_gh', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region ratio Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'MeasureWeightListComponent_Ratio_gh', keyColumn: false})
        ratio : number;
//#endregion ratio Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'MeasureWeightListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}