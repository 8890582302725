<!-- <div class="modal-dialog modal-lg" style="line-height:0.1;height:90%;overflow: auto;"> -->
<div class="modal-dialog modal-lg" style="line-height:0.1" *rxMultilingual="">
    <div class="modal-content" style="height:650px;overflow: scroll;">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0">Event Customer Details</h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" >
            <div>
                <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
                    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
                        <label class="ml-auto mr-0 mb-3">
                            <!-- <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4" placeholder=""> -->
                        </label>
                    </div>
                    <div class="table-responsive" [rxSpinner]="spin" *ngIf="isShowGrid">
                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                            <rx-grid [design]="summaryDetailGrid"></rx-grid>
                        </div>
                    </div>
                    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                        <h4>No Record Found</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="hide()">Close</button>
        </div>




    </div>