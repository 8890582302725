import {CategoryTemplateBase,CategoryBase,} from '@app/database-models'
//Generated Imports
export class CategoryTemplate extends CategoryTemplateBase 
{




//#region Generated Reference Properties
//#region categories Prop
categories : CategoryBase[];
//#endregion categories Prop

//#endregion Generated Reference Properties












}