import { gridColumn } from "@rxweb/grid"


export class vProductionFormMaterialLookUpBase {

//#region materialId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'materialId', keyColumn: true})
        materialId : number;
//#endregion materialId Prop


//#region materialName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'materialName', keyColumn: false})
        materialName : string;
//#endregion materialName Prop

}