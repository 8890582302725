import {BespokeOrderCustomerMappingBase,UserBase,SupplierBase,} from '@app/database-models'
//Generated Imports
export class BespokeOrderCustomerMapping extends BespokeOrderCustomerMappingBase 
{




//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop

//#endregion Generated Reference Properties











}