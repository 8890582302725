<div [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="dataTables_filter  d-flex flex-wrap align-items-center">
    <button class="btn btn-success mb-3" (click)="showAddForm()" rxText="Btn_Add_New_t" ></button>
    <label class="ml-auto mr-0 mb-3">
      <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
        placeholder="" [title]="titleSearch">
    </label>
  </div>
  <!-- <div data-toggle="buttons"
    class="btn-group btn-group-gray btn-group-toggle mb-1 d-flex flex-wrap justify-content-end">
    <button class="btn btn-success" (click)="onMoreClickAll()" *ngIf="isShowAll">Show All</button>
    <button class="btn btn-success" (click)="onLessClickAll()" *ngIf="isHideAll">Hide All</button>
  </div> -->
  <div class="table-responsive" *ngIf="isShowGrid">
    <div class="dataTables_wrapper pb-0 dt-bootstrap4">
      <rx-grid [design]="leaveTypeCorporateGrid"></rx-grid>
    </div>
  </div>
  <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
    <h4>No Record Found</h4>
  </div>
</div>