import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStonePictureBase {

//#region gemstonePictureId Prop
        @prop()
        gemstonePictureId : number;
//#endregion gemstonePictureId Prop


//#region gemStoneId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        gemStoneId : number;
//#endregion gemStoneId Prop


//#region pictureId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureId : number;
//#endregion pictureId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}