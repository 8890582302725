import {InvoiceBase,SupplierBase,InvoiceBatchMappingBase,ProductPieceGemstoneMappingBase,} from '@app/database-models'
//Generated Imports
export class Invoice extends InvoiceBase 
{




//#region Generated Reference Properties
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region invoiceBatchMappings Prop
invoiceBatchMappings : InvoiceBatchMappingBase[];
//#endregion invoiceBatchMappings Prop
//#region productPieceGemstoneMappings Prop
productPieceGemstoneMappings : ProductPieceGemstoneMappingBase[];
//#endregion productPieceGemstoneMappings Prop

//#endregion Generated Reference Properties











}