import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCreditCardTypeBase {

//#region creditCardTypeId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'CreditCardTypeListComponent_CardTypeId_gh', keyColumn: true})
        creditCardTypeId : number;
//#endregion creditCardTypeId Prop


//#region creditCartName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'CreditCardTypeListComponent_CardTypeName_gh', keyColumn: false})
        creditCartName : string;
//#endregion creditCartName Prop


//#region systemKeyword Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'CreditCardTypeListComponent_SystemKeyword_gh', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'CreditCardTypeListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}