import {vParentAdhocLookupBase,} from '@app/database-models'
//Generated Imports
export class vParentAdhocLookup extends vParentAdhocLookupBase
{



//#region Generated Reference Properties

//#endregion Generated Reference Properties










}