import {vPurchasedFromLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPurchasedFromLookUp extends vPurchasedFromLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties


}