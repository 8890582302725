<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
        <div class="row wrapper white-bg page-heading py-2 align-items-center">
            <div class="col-md-8 my-1">
              <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard']">Home</a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
                </li>
                <!--<li class="breadcrumb-item">
                  <a [routerLink]="['/tax-provider']">Tax Providers</a>
                </li>-->
                <li class="breadcrumb-item active" rxText="TaxProviderEditComponent_Title"></li>
              </ol>
            </div>
            <div class="col-md-4 text-right">
              <app-system-time></app-system-time>
            </div>
          </div>

        <div class="wrapper wrapper-content flex-grow-1">

          <div class="ibox mb-0 d-flex flex-column">
            <div class="ibox-title bg-white pr-3">
              <h5><label rxText="TaxProviderEditComponent_Title"></label></h5>
              <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x" ></i>
              </a>
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="taxProviderFormGroup" [rxSpinner]="spin">


              <div class="tabs-container people-tabs">

                <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
                  <li>
                    <a class="nav-link" [class.active]="currentActiveTab == 'system-info'" data-toggle="tab" (click)="activatedTab('system-info')"><label class="mb-0" rxText="TaxProviderEditComponent_Title_SystemInfo"></label></a>
                  </li>
                  <li>
                    <a class="nav-link" [class.active]="currentActiveTab == 'configuration'" data-toggle="tab" (click)="activatedTab('configuration')"><label class="mb-0" rxText="TaxProviderEditComponent_Title_Configuration"></label></a>
                  </li>
                </ul>
                <div class="slick_demo-btn"></div>

                <div class="tab-content">
                  <div role="tabpanel" class="tab-pane d-block"  *ngIf="currentActiveTab == 'system-info'" [rxLocalisationInit]="componentName">
                    <div class="panel-body">
                      <!--<div class="ibox-title bg-white  px-0">
    <h5>Boutique Info</h5>
  </div>-->
                      <div class="row add-countries-form" (keyup.enter)="editTaxProvider(false)">
                        <div class="col-lg-6 col-xl-4 pr-xl-5">
                          <div class="form-group row">
                            <label class="col-md-4 col-form-label" rxText="TaxProviderEditComponent_ID_t"></label>
                            <div class="col-md-8">
                              <label type="text"
                                          class="form-control disableLabel-withoutheight mb-0">{{(taxProviderFormGroup.value.taxProviderId
                                          === null || taxProviderFormGroup.value.taxProviderId === undefined ) ? '' :
                                          taxProviderFormGroup.value.taxProviderId}}</label>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaxProviderAddEditComponent_Name_t"></label>
                            <div class="col-md-8">
                              <input type="text" formControlName="name" tabindex="1" class="form-control" rxPlaceholder="TaxProviderAddEditComponent_Name_p" rxFocus />
                              <small class="form-text text-danger" [class.d-block]="taxProviderFormGroup.controls.name.errors">{{taxProviderFormGroup.controls.name.errorMessage}}</small>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaxProviderAddEditComponent_ClassName_t"></label>
                            <div class="col-md-8">
                              <input type="text" formControlName="className" tabindex="4" class="form-control" rxPlaceholder="TaxProviderAddEditComponent_ClassName_p" />
                              <small class="form-text text-danger" [class.d-block]="taxProviderFormGroup.controls.className.errors">{{taxProviderFormGroup.controls.className.errorMessage}}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 px-xl-4">
                          <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaxProviderAddEditComponent_ConfiTemplatePath_t"></label>
                            <div class="col-md-8">
                              <input type="text" formControlName="configureTemplatePath" tabindex="2" class="form-control" rxPlaceholder="TaxProviderAddEditComponent_ConfiTemplatePath_p" />
                              <small class="form-text text-danger" [class.d-block]="taxProviderFormGroup.controls.configureTemplatePath.errors">{{taxProviderFormGroup.controls.configureTemplatePath.errorMessage}}</small>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="TaxProviderAddEditComponent_DisplayPriority_t"></label>
                            <div class="col-md-8">
                              <input type="text" formControlName="displayOrder" tabindex="5" class="form-control" rxPlaceholder="TaxProviderAddEditComponent_DisplayPriority_p" />
                              <small class="form-text text-danger" [class.d-block]="taxProviderFormGroup.controls.displayOrder.errors">{{taxProviderFormGroup.controls.displayOrder.errorMessage}}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 pl-xl-5">
                          <div class="form-group row">
                            <label class="col-md-4 col-xl-4 col-form-label" rxText="TaxProviderAddEditComponent_Description_t"></label>
                            <div class="col-md-8">
                              <textarea class="form-control" rows="4" tabindex="3" formControlName="description" rxPlaceholder="TaxProviderAddEditComponent_Description_p"></textarea>
                              <small class="form-text text-danger" [class.d-block]="taxProviderFormGroup.controls.description.errors">{{taxProviderFormGroup.controls.description.errorMessage}}</small>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="hr-line-dashed border-top w-100"></div>
                      <div class="row mt-auto">
                        <div class="col text-right">
                          <button (click)="editTaxProvider(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                          <button (click)="editTaxProvider(true)" class="btn btn-primary" rxText="Btn_Save_Continue_Edit_t"></button>
                          <!--<button (click)="deleteTaxProvider()" class="btn btn-danger">Delete</button>-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div role="tabpanel" class="tab-pane d-block" *ngIf="currentActiveTab == 'configuration'" [rxLocalisationInit]="componentName">
                    <div class="panel-body">
                      <app-tax-rate-list *ngIf="isEnableTaxConfiguration()" [taxProviderId]="id"></app-tax-rate-list>

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>

      </div>
