import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBoutiquePictureBase {

    //#region boutiquePictureId Prop
    @gridColumn({ class:[], visible: false, columnIndex: 3, allowSorting: true, headerKey: 'boutiquePictureId', keyColumn: true })
    boutiquePictureId: number;
    //#endregion boutiquePictureId Prop


    //#region boutiqueId Prop
    @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'boutiqueId', keyColumn: false })
    boutiqueId: number;
    //#endregion boutiqueId Prop



    //#region statusId Prop
    @gridColumn({ isAscending: true, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'BoutiquePictureListComponent_DisplayOrder_gh', keyColumn: false })
    displayOrder: number;
    //#endregion statusId Prop

    // @gridColumn({ isAscending: true, visible: true, columnIndex: 1, allowSorting: true, headerKey: 'displayOrder', keyColumn: false })
    // displayOrder: string;

}
