import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class NoteHastagMappingBase {

//#region noteHastagId Prop
        @prop()
        noteHastagId : number;
//#endregion noteHastagId Prop


//#region hastagId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        hastagId : number;
//#endregion hastagId Prop


//#region noteId Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        noteId : number;
//#endregion noteId Prop



}
