import { gridColumn, actionColumn } from '@rxweb/grid';

export class ProjectEventAttendeesViewModel {

    //#region CustomerID
    @gridColumn({ visible: false, columnIndex: 1, allowSorting: false, headerKey: 'CustomerID', keyColumn: false })
    CustomerID: number;
    //#endregion CustomerID

    //#region AddedBy
    @gridColumn({ visible: false, columnIndex: 2, allowSorting: false, headerKey: 'AddedBy', keyColumn: false })
    AddedBy: number;
    //#endregion AddedBy

    //#region Involved
    @gridColumn({ visible: false, columnIndex: 3, allowSorting: false, headerKey: 'Involved', keyColumn: false })
    Involved: boolean;
    //#endregion Involved

    //#region Attended
    @gridColumn({ visible: false, columnIndex: 4, allowSorting: false, headerKey: 'Attended', keyColumn: false })
    Attended: boolean;
    //#endregion Attended

    //#region Event URL
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.EventURL == null) {
                            return "";
                        } else {
                            return this.EventURL;
                        }
                    }
                }
            }
        }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'EventURL', headerTitle: 'Event Name', keyColumn: false
    })
    EventURL: string;
    //#endregion Event URL

    //#region EventName
    @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: false, headerKey: 'EventName', keyColumn: false })
    EventName: string;
    //#endregion EventName

    //#region UserURL
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.UserURL == null) {
                            return "";
                        } else {
                            return this.UserURL;
                        }
                    }
                }
            }
        }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'UserURL', headerTitle: 'User Name', keyColumn: false
    })
    UserURL: string;
    //#endregion User URL

    //#region UserName
    @gridColumn({configuredTemplate: { templateName: "reDirectPeopleAndTooltip" } ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'UserURL', headerTitle: 'User Name', keyColumn: false })
    UserName: string;
    //#endregion UserName

    //#region Corporate URL
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.CorporateURL == null) {
                            return "";
                        } else {
                            return this.CorporateURL;
                        }
                    }
                }
            }
        }, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'CorporateURL', headerTitle: 'Corporate(s)', keyColumn: false
    })
    CorporateURL: string;
    //#endregion Corporate URL

    //#region CorporateName
    @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'CorporateName', keyColumn: false })
    CorporateName: string;
    //#endregion CorporateName

    //#region JobTitle
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.JobTitle == null) {
                            return "";
                        } else {
                            return this.JobTitle;
                        }
                    }
                }
            }
        }, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: false, headerKey: 'JobTitle', headerTitle: 'Job(s)', keyColumn: false
    })
    JobTitle: string;
    //#endregion JobTitle

    JobTitles: any;
    JobTitlesCount: number;
    UserId:any;


    //#region JobData
    @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'JobData', keyColumn: false })
    JobData: string;
    //#endregion JobData

    //#region EventDate
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'ProjectEventListComponent_EventStartDate_gh', keyColumn: false })
    EventDate: string;
    //#endregion EventDate

    //#region EventEndDate
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'ProjectEventListComponent_EventEndDate_gh', keyColumn: false })
    EventEndDate: string;
    //#endregion EventEndDate


    // #region EVID
    @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: false, headerKey: 'EVID', keyColumn: false })
    EVID: number;
    // #endregion EVID
    totalCount: number;

    //#region status Prop
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.Status == null) {
                            return "";
                        } else {
                            return this.Status;
                        }
                    }
                }
            }
        }, style: { width: "15%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 9, headerKey: 'ProjectEventListComponent_Status_gh', keyColumn: false, allowSorting: true
    })
    Status: string;
    //#endregion status Prop


    // #region EventType
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ProjectEventListComponent_EventType_gh', keyColumn: false })
    EventType: string;
    // #endregion EventType

}
