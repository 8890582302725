import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSpecificationAttributeOptionBase {

//#region specificationAttributeOptionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'specificationAttributeOptionId', keyColumn: true})
        specificationAttributeOptionId : number;
//#endregion specificationAttributeOptionId Prop


//#region specificationAttributeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'specificationAttributeId', keyColumn: false})
        specificationAttributeId : number;
//#endregion specificationAttributeId Prop


//#region specificationName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'specificationName', keyColumn: false})
        specificationName : string;
//#endregion specificationName Prop


//#region viewbyClients Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'viewbyClients', keyColumn: false})
        viewbyClients : any;
//#endregion viewbyClients Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region inspirations Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'inspirations', keyColumn: false})
        inspirations : string;
//#endregion inspirations Prop


//#region metaDescription Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'metaDescription', keyColumn: false})
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaKeywords Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'metaKeywords', keyColumn: false})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaTitle Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'metaTitle', keyColumn: false})
        metaTitle : string;
//#endregion metaTitle Prop


//#region seName Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'seName', keyColumn: false})
        seName : string;
//#endregion seName Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : boolean;
//#endregion published Prop

}