import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
  allowSorting: false,
  name: 'action',
  style: { width: "1%", "text-align": "left", "margin-top": "unset" },
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})
export class ProductViewModel {
  @gridColumn({ style: { width: "0.01%", "text-align": "initial" }, class: ["text-right"], visible: true, configuredTemplate: { templateName: 'bindCheckBox' }, allowSorting: false, columnIndex: 0, headerTitle: 'Action', name: 'isChecked', keyColumn: false })
  isChecked: boolean

  designId: number;
  designName: string;

  pictureId: number;

  @gridColumn({
    template: {
      img: {
        isBind: (x) => {
          if (x.productVariantId == null || x.productVariantId == undefined || x.productVariantId <= 0) {
            return false;
          }
          else {
            return true;
          }
        },
        attributes: {
          src: function (e) {
            return this.imageUrl
          }
        },
        event: {
          error: "onErrorImg"
          //   error: function (e) {
          //     return ("this.onerror=null;this.src='"+SERVER_URL+"/images/default/default_image_560.png';");
          // }
        },
        style: { width: "90px", "max-width": "100%", "max-height": "100%", "height": "90px" },
        class: ["grid-image"]
      },
      div: {
        isBind: (x) => {
          if (x.productVariantId == null || x.productVariantId == undefined || x.productVariantId <= 0) {
            return true;
          }
          else {
            return false;
          }
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.imageUrl;
          }
        },
      }
    },
    parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey: 'ProductListComponent_ImageUrl_gh', keyColumn: false
  })
  imageUrl: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.productName;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, headerKey: 'ProductListComponent_ProductName_gh', keyColumn: false
  })
  productName: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.supplierName;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'ProductListComponent_SupplierName_gh', keyColumn: false
  })
  supplierName: string


  @gridColumn({
    template: {
      span: {
        isBind: (x) => {
          if (x.productVariantId == null || x.productVariantId == undefined || x.productVariantId <= 0) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [{
          text: {
            text: function (e) { return this.productVariantId }
          }
        }]

      }
    }
    , style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, allowSorting: false, columnIndex: 5, headerKey: 'ProductListComponent_ProductVariantId_gh', keyColumn: false
  })
  productVariantId: number;

  @gridColumn({
    template: {
      div: {
        isBind: (x) => {
          if (x.exportPrice == null || x.exportPrice == undefined || x.exportPrice == "NA") {
            return false;
          }
          else {
            return true;
          }
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.exportPrice;
          }
        },
      }
    },
    parameter: false, style: { width: "8%", "text-align": "initial", }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: false, headerKey: 'ProductListComponent_ExportPrice_gh', keyColumn: false
  })
  exportPrice: string

  @gridColumn({
    template: {
      span: {
        isBind: (x) => {
          if (x.displayOrder == null || x.displayOrder == undefined || x.displayOrder < 0) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [{
          text: {
            text: function (e) { return this.displayOrder }
          }
        }]

      }
    }
    , style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, allowSorting: false, columnIndex: 7, headerKey: 'ProductListComponent_DisplayOrder_gh', keyColumn: false
  })
  displayOrder: number;
  @gridColumn({
    template: {
      span: {
        isBind: (x) => {
          if (x.inStockQuantity == null || x.inStockQuantity == undefined || x.inStockQuantity < 0) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [{
          text: {
            text: function (e) { return this.inStockQuantity }
          }
        }]

      }
    }
    , style: { width: "3%", "text-align": "centre" }, visible: true, parameter: false, class: ["text-centre"], allowSorting: false, columnIndex: 8, headerKey: 'ProductListComponent_InStockQuantity_gh', keyColumn: false
  })
  inStockQuantity: number;

  @gridColumn({
    template: {
      span: {
        isBind: (x) => {
          if (x.inProductionQuantity == null || x.inProductionQuantity == undefined || x.inProductionQuantity < 0) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [{
          text: {
            text: function (e) { return this.inProductionQuantity }
          }
        }]

      }
    }
    , style: { width: "3%", "text-align": "initial" }, visible: true, parameter: false, allowSorting: false, columnIndex: 9, headerKey: 'ProductListComponent_InProductionQuantity_gh', keyColumn: false
  })
  inProductionQuantity: number;

  @gridColumn({
    parameter: false, style: { width: "7%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 10, allowSorting: false, headerKey: 'ProductListComponent_IsCoreProduct_gh', keyColumn: false,
    configuredTemplate: { templateName: 'coreProduct' }, name: 'isCoreProduct'
  })
  isCoreProduct: boolean;

  @gridColumn({
    parameter: false, style: { width: "7%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 11, allowSorting: false, headerKey: 'ProductListComponent_IsNoReorder_gh', keyColumn: false,
    configuredTemplate: { templateName: 'noReorder' }, name: 'isNoReorder'
  })
  isNoReorder: boolean;

  @gridColumn({
    configuredTemplate: { templateName: 'superSeller' },
    parameter: false, style: { width: "7%", "text-align": "centre" }, class: ["text-centre"], visible: true, columnIndex: 12, allowSorting: false, headerKey: 'ProductListComponent_IsSuperSeller_gh', keyColumn: false, name: 'isSuperSeller'
  })
  isSuperSeller: boolean;

  @gridColumn({
    template: {
      div: {
        isBind: (x) => {
          if (x.hasCollectionPriority == null || x.hasCollectionPriority == false) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [
          {
            label: {
              childrens: [
                {
                  input:
                  {
                    class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                    event: {
                      input: "onCategoryPriorty"
                    },
                    attributes: {
                      type: "text",
                      value: function (e) {
                        return this.categoryPriority;
                      },

                    }
                  }
                }
              ]
            }
          }
        ]
      },
    }
    , style: { width: "9%", "text-align": "initial" }, parameter: false, class: ["text-left"], visible: false, columnIndex: 13, allowSorting: false, /*headerTitle: 'Category Priority'*/ headerKey: 'ProductListComponent_CategoryOrder_gh', keyColumn: false, name: 'categoryPriority'
  })
  categoryPriority: any;

  @gridColumn({
    template: {
      div: {
        isBind: (x) => {
          if (x.hasCategoryPriority == null || x.hasCategoryPriority == false) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [
          {
            label: {
              childrens: [
                {
                  input:
                  {
                    class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                    event: {
                      input: "onCostData"
                    },
                    attributes: {
                      type: "text",
                      value: function (e) {
                        return this.categoryPriority;
                      },
                      disabled: true
                    }
                  }
                }
              ]
            }
          }
        ]
      },
    }
    , style: { width: "9%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 14, allowSorting: false, /*headerTitle: 'Category Priority'*/headerKey: 'ProductListComponent_CategoryOrder_gh', name: 'disabledCategory', keyColumn: false
  })
  disabledCategory: any;


  @gridColumn({
    template: {
      div: {
        isBind: (x) => {
          if (x.hasCategoryPriority == null || x.hasCategoryPriority == false) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [
          {
            label: {
              childrens: [
                {
                  input:
                  {
                    class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                    event: {
                      input: "onCollectionPriorty"
                    },
                    attributes: {
                      type: "text",
                      value: function (e) {
                        return this.collectionPriority;
                      },
                    }
                  }
                }
              ]
            }
          }
        ]
      },
    }
    , style: { width: "9%", "text-align": "initial" }, parameter: false, class: ["text-left"], visible: false, columnIndex: 15, allowSorting: false, /*headerTitle: 'Collection Priority'*/headerKey: 'ProductListComponent_CollectionOrder_gh', keyColumn: false, name: 'collectionPriority'
  })
  collectionPriority: any;

  @gridColumn({
    template: {
      div: {
        isBind: (x) => {
          if (x.hasCategoryPriority == null || x.hasCategoryPriority == false) {
            return false;
          }
          else {
            return true;
          }
        },
        childrens: [
          {
            label: {
              childrens: [
                {
                  input:
                  {
                    class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                    event: {
                      input: "onCostData"
                    },
                    attributes: {
                      type: "text",
                      value: function (e) {
                        return this.collectionPriority;
                      },
                      disabled: true
                    }
                  }
                }
              ]
            }
          }
        ]
      },
    }
    , style: { width: "9%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 16, allowSorting: false, /*headerTitle: 'Collection Priority'*/headerKey: 'ProductListComponent_CollectionOrder_gh', keyColumn: false, name: 'disableCollection'
  })
  disableCollection: any;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 17, allowSorting: false, headerKey: 'ProductListComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
  published: boolean;



  totalCount: number;
  hasCoreProduct: boolean;
  hasNoReorder: boolean;
  hasSuperSeller: boolean;
  design: string;
  name: string;
  exportRetailPrice: string;
  hasCategoryPriority: boolean;
  hasCollectionPriority: boolean;
  userAllowed: boolean;
}