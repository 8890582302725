import {vNewsLetterTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vNewsLetterTypeLookUp extends vNewsLetterTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}