export const externalRoutes: string[] = [
  "reset-password",
  "forgot-password",
  "member-feedback",
  "event-redirection",
  "quick-reply",
  "under-maintenance",
  "leavetravel",
  "unlock-account",
  "accessrequest",
  "i-t-help-desk-weekly-report-view",
  "grievance-form",
  "purchase-order-update",
  "purchase-order-download",
  "sso-redirected"
];
