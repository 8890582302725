import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractApplicationExceptionLog } from '../domain/abstract-application-exception-log';
import { ApplicationExceptionLog } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { ApplicationExceptionLogsViewModel } from 'src/app/view-model/application-exception-logs-view-model';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe, PlatformLocation } from '@angular/common';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { ApplicationExceptionLogsFilterViewModel } from 'src/app/view-model/application-exception-logs-filter-view-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { debounceTime } from 'rxjs/operators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@access({ accessLevel: RolePermissionEnums.Log, action: "get" })
@multilingual("ApplicationExceptionLogListComponent")
@Component({
  selector: "app-application-exception-log-list",
  templateUrl: './application-exception-log-list.component.html'
})
export class ApplicationExceptionLogListComponent extends AbstractApplicationExceptionLog implements OnInit, OnDestroy {
  applicationExceptionLogs: ApplicationExceptionLogsViewModel[];
  subscription: any;
  logGrid: AppGrid;
  logGridColumns: any[];
  id: number = 0;
  errorType: ApplicationExceptionLogsFilterViewModel;
  logGridDisplay: boolean = false;
  applicationExceptionLogsSearchFormGroup: IFormGroup<ApplicationExceptionLogsFilterViewModel>;
  rowCount: number = 25;
  pageIndex: number = 1;
  sortOrder: string = "true";
  orderByColumn: string = "logType";
  applicationExceptionLogIds: number[] = [];
  gridSearchString: string = "";
  showSearchBar: boolean = false;
  isFirstTime: boolean = true;
  selectedTab: string = "logs";
  logDelete: any = dbAccessModule[RolePermissionEnums.Log]["delete"];
  isFilter: boolean = false;
  totalRecords: number = 0;
  showTab: boolean = false;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  titleSearch: string;
  dateError: boolean;


  _this;

  private applicationExceptionSubject: Subject<string> = new Subject<string>();
  showSystemHelpIcon: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private formBuilder: RxFormBuilder, private title: Title, private location : PlatformLocation) {
    super();
    this.modalView = modelView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.activatedRoute.queryParams.subscribe(t => {
      this.selectedTab = t['activeTab'] == undefined ? "logs" : t['activeTab'];
    });
    this.moduleID = RolePermissionEnums.Log;
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Log - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.showTab = false;
    this.applicationExceptionSubject.pipe(debounceTime(300)).subscribe(t => {
      this.filterSearch();
    })
    this.lookup([
      { propName: "errorTypeLookUps", path: USER_LOOKUPS.errorTypeLookUps }
    ]).subscribe((lookup: any) => {
      this.errorType = new ApplicationExceptionLogsFilterViewModel();
      this.errorType.errorTypeId = 0
      this.applicationExceptionLogIds = new Array<number>();
      this.applicationExceptionLogsSearchFormGroup = this.formBuilder.formGroup(this.errorType) as IFormGroup<ApplicationExceptionLogsFilterViewModel>;
      this.errorTypeLookUps = lookup;
      this.showComponent = true;
      this.showTab = true;
    });
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  activeTab(tabName: string) {
    this.selectedTab = tabName;
    this.router.navigate(["application-exception-logs"], { queryParams: { activeTab: tabName } });
  }

  clearGrid() {
    if (this.logGrid && this.isShowGrid) {
      this.logGrid.refresh("application-exception-logs")
    }
  }

  bindGrid(page: number = 0,onload :boolean=true) {
    this.spin = true;
    this.isShowGrid = true;
    var json = JSON.parse(JSON.stringify(this.applicationExceptionLogsSearchFormGroup.value));
    json["rowCount"] = this.rowCount;
    json["pageIndex"] = page == 0 ? this.pageIndex : page;
    json["searchString"] = this.gridSearchString.replace('&', '%26');
    json["sortOrder"] = this.sortOrder;
    json["orderByColumn"] = this.orderByColumn;
    // this.spin = true;
    this.subscription = this.get(
      {
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(json))
        }
      }).subscribe((t: any) => {
        this.showComponent = true;
        this.spin = false;
        this.applicationExceptionLogs = t;
        if(onload ){
          this.showSearchBar=true;
        }
        else{
          this.showSearchBar=false;

        }
        if (this.applicationExceptionLogs.length > 0) {
          this.totalRecords = this.applicationExceptionLogs[0].totalCount;
          this.isNoRecordFound = false;
          this.showSearchBar = true;

          if (!this.isFilter) {
            this.logGrid = new AppGrid(this.applicationExceptionLogs, ApplicationExceptionLogsViewModel, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this), onCheckBoxSelect: this.onCheckboxSelect.bind(this) } });
            this.logGrid.storeProcedure = {
              length: this.totalRecords,
              onPageChanging: this.onPageChanging.bind(this),
              nextPage: 1,
              onPageSorting: this.onPageSorting.bind(this)
            }
            this.logGrid.gridColumns.forEach(x => {
              if (this.logGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "put" }) && this.logGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "delete" })) {
                if (x.name == "action") {
                  x.visible = true;
                }
                if (x.name == "applicationExceptionLogId") {
                  x.visible = true;
                }
              }
              else {
                if (x.name == "action") {
                  x.visible = false;
                }
                if (x.name == "editAction") {
                  x.visible = this.logGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "put" });
                }
                if (x.name == "deleteAction") {
                  x.visible = this.logGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "delete" });
                }
                if (x.name == "applicationExceptionLogId") {
                  x.visible = this.logGrid.authorize({ accessLevel: RolePermissionEnums.Log, action: "delete" });
                }
              }
            })
          }
          else {
            if (this.logGrid != null) {
              this.logGrid.storeProcedure.length = this.totalRecords;
              if (this.pageIndex == 1) {
                this.logGrid.storeProcedure.nextPage = 1;
              }
              setTimeout(() => {
                this.logGrid.updateSource([]);
                this.logGrid.updateSource(this.applicationExceptionLogs);
              }, 100);
            }
          }
          this.logGrid.maxPerPage = this.rowCount;
          this.logGrid.componentId = this.componentName;
          this.logGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.logGrid.designClass.headerClass = "table-header-sticky".split(" ");
        } else {
          this.isShowGrid = false;
          // this.showSearchBar = false;
          this.isNoRecordFound = true;
        }
      })
  }

  onKeyPress() {
    this.applicationExceptionSubject.next();
  }

  filterSearch() {
    this.applicationExceptionLogsSearchFormGroup.submitted = true;
    if (this.applicationExceptionLogsSearchFormGroup.valid) {
      if (dateCompareValidation(this.applicationExceptionLogsSearchFormGroup.value.createdFrom, this.applicationExceptionLogsSearchFormGroup.value.createdTo) == true) {
        this.dateError = true;
      }
      else {
        this.dateError = false;
        if (this.logGrid) {
          this.isFilter = true;
        }
        this.pageIndex = 1;
        this.bindGrid(this.pageIndex,false);

      }
    }

  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.sortOrder = y;
    this.orderByColumn = x;
    this.logGrid.storeProcedure.nextPage = z;
    this.bindGrid(this.pageIndex,true);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.pageIndex = x;
    this.rowCount = this.logGrid.maxPerPage;
    this.logGrid.storeProcedure.nextPage = x;
    this.bindGrid(x,true);
  }

  onCheckboxSelect(t, x, y, z) {
    if (x.target.checked) {
      if (!this.applicationExceptionLogIds.includes(t.applicationExceptionLogId)) {
        this.applicationExceptionLogIds.push(t.applicationExceptionLogId);
      }
    }
    else {
      if (this.applicationExceptionLogIds.includes(t.applicationExceptionLogId)) {
        this.applicationExceptionLogIds.splice(this.applicationExceptionLogIds.indexOf(t.applicationExceptionLogId), 1);
      }
    }
  }

  onEdit(log: ApplicationExceptionLogsViewModel) {
    //this.router.navigate(["application-exception-logs", log.applicationExceptionLogId], { queryParams: { log: 'old-log' } });
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["application-exception-logs", log.applicationExceptionLogId], { queryParams: { log: 'old-log' } }));
    redirectOnCTRL(url, this.router, event);
  }

  onDelete(log: ApplicationExceptionLogsViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [0], queryParams: { "query": log.applicationExceptionLogId.toString(), "paramFlag": 0 } }).subscribe(data => {
          this.spin = false;
          this.isFilter = true;
          this.bindGrid();
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert[0].showMessage);
          }
        })
      }
      this.dialog.hide()
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Exception_Log");
    if (existsAlert) {
      return existsAlert[0].showMessage;
    }
  }

  DeleteConfirmationMessageClearLog() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Exception_Logs");
    if (existsAlert) {
      return existsAlert[0].showMessage;
    }
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.totalRecords = 0;
      this.gridSearchString = value.target.value;
      this.pageIndex = 1;
      this.bindGrid(this.pageIndex,true);

    }, 0);
    this.spin = true;
  }

  clearLog() {
    if (this.applicationExceptionLogIds.length > 0) {
      this.dialog.show(this.DeleteConfirmationMessageClearLog(), DialogViewMode.okWithCancel).then(t => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({ body: null, params: [0], queryParams: { "query": this.applicationExceptionLogIds.toString(), "paramFlag": 0 } }).subscribe(data => {
            this.spin = false;
            this.isFilter = true;
            this.bindGrid();
            var existsAlert = this.localizedMessages.filter(x => x.keyName == "LogsClear_Success");
            if (existsAlert) {
              //this.toastr.success("Data Updated Successfully")
              this.toastr.success(existsAlert[0].showMessage);
            }
            this.applicationExceptionLogIds = [];
          })
        }
        this.dialog.hide()
      })
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ApplicationExceptionLogListComponent";
  }
}
