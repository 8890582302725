import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vNoteTypeBase {

//#region noteTypeId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'NoteTypeListComponent_Id_gh', keyColumn: true})
        noteTypeId : number;
//#endregion noteTypeId Prop


//#region noteTypeName Prop
        @gridColumn({ isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'NoteTypeListComponent_Hashtag_gh', keyColumn: false})
        noteTypeName : string;
//#endregion noteTypeName Prop


//#region noteTypeDescription Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'NoteTypeListComponent_Description_gh', keyColumn: false})
        noteTypeDescription : string;
//#endregion noteTypeDescription Prop


//#region isManual Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'NoteTypeListComponent_IsManual_gh', keyColumn: false})
        isManual : boolean;
//#endregion isManual Prop


//#region active Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'NoteTypeListComponent_Active_gh', keyColumn: false,configuredTemplate: { templateName: "recordActive" }})
        active : boolean;
//#endregion active Prop

}