import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LeaveMasterHolidayProcessViewModelBase {

//#region id Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'id', keyColumn: true})
        id : number;
//#endregion id Prop


//#region userId Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : number;
//#endregion userId Prop


//#region holidayType Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:2, allowSorting: true, headerKey: 'holidayType', keyColumn: false})
        holidayType : number;
//#endregion holidayType Prop


//#region leaveTypeName Prop
        @gridColumn({isAscending:true,style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:3, allowSorting: true, headerKey: 'LeaveMasterListComponent_LeaveTypeName_gh', keyColumn: false})
        leaveTypeName : string;
//#endregion leaveTypeName Prop


//#region openingBalance Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:4, allowSorting: true, headerKey: 'LeaveMasterListComponent_OpeningBalance_gh', keyColumn: false})
        openingBalance : number;
//#endregion openingBalance Prop


//#region holidayAlloted Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:5, allowSorting: true, headerKey: 'LeaveMasterListComponent_HolidayAlloted_gh', keyColumn: false})
        holidayAlloted : number;
//#endregion holidayAlloted Prop


//#region weekOff Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: false, columnIndex:6, allowSorting: true, headerKey: 'LeaveMasterListComponent_WeekOff_gh', keyColumn: false})
        weekOff : string;
//#endregion weekOff Prop

//#region workingDays Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:7, allowSorting: true, headerKey: 'LeaveMasterListComponent_WeekOff_gh', keyColumn: false})
        workingDays : string;
//#endregion workingDays Prop

//#region workPublicHoliday Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:8, allowSorting: true, headerKey: 'LeaveMasterListComponent_WorkPublicHoliday_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" }})
        workPublicHoliday : any;
//#endregion workPublicHoliday Prop

//#region forYear Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:9, allowSorting: true, headerKey: 'LeaveMasterListComponent_ForYear_gh', keyColumn: false})
        forYear : number;
//#endregion forYear Prop

}