import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LocaleStringResourceBase {

//#region localeStringResourceId Prop
        @prop()
        localeStringResourceId : number;
//#endregion localeStringResourceId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region resourceName Prop
        @required()
        @maxLength({value:200})
        @notEmpty()
        resourceName : string;
//#endregion resourceName Prop


//#region resourceValue Prop
        @required()
        resourceValue : string;
//#endregion resourceValue Prop



}