import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSalesAnalysisByLookUpBase {

//#region salesAnalysisById Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'salesAnalysisById', keyColumn: true})
        salesAnalysisById : number;
//#endregion salesAnalysisById Prop


//#region salesAnalysisByName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'salesAnalysisByName', keyColumn: false})
        salesAnalysisByName : string;
//#endregion salesAnalysisByName Prop

}