import { prop, required } from '@rxweb/reactive-form-validators';

export class RiskMenuDetailBase {

    //#region tripID Prop
    @prop({ defaultValue: 0 })
    riskMenuDetailID: number;
    //#endregion tripID Prop

    //#region companyId Prop
    @required()
    @prop({ defaultValue: 0 })
    companyId: number;
    //#endregion companyId Prop

    //#region riskCategoryId Prop
    @required()
    @prop({ defaultValue: 0 })
    riskCategoryId: number;
    //#endregion riskCategoryId Prop

    //#region specificRiskId Prop
    @required()
    @prop({ defaultValue: 0 })
    specificRiskId: number;
    //#endregion specificRiskId Prop
    
    //#region isRelevant Prop
    @prop({ defaultValue: false })
    isRelevant: boolean;
    //#endregion isRelevant Prop

    //#region likelihood Prop
    @required()
    @prop({ defaultValue: 0 })
    likelihood: number;
    //#endregion likelihood Prop

    //#region impact Prop
    @required()
    @prop({ defaultValue: 0 })
    impact: number;
    //#endregion impact Prop

    //#region riskIndex Prop
    @required()
    @prop({ defaultValue: 0 })
    riskIndex: number;
    //#endregion riskIndex Prop

    //#region localRiskOwner Prop
    @required()
    @prop()
    localRiskOwner: number;
    //#endregion localRiskOwner Prop

    //#region boardRiskOwner Prop
    @required()
    @prop({ defaultValue: 0 })
    boardRiskOwner: number;
    //#endregion boardRiskOwner Prop

    //#region isDeleted Prop
    @prop({ defaultValue: false })
    isDeleted: boolean;
    //#endregion isDeleted Prop

    //#region createdOn Prop
    @required()
    @prop({ defaultValue: new Date() })
    createdOn: Date;
    //#endregion createdOn Prop

    //#region createdBy Prop
    @required()
    @prop({ defaultValue: 0 })
    createdBy: number;
    //#endregion createdBy Prop

    //#region modifiedOn Prop
    @prop()
    modifiedOn: Date;
    //#endregion modifiedOn Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    @prop()
    isImpactShowField:boolean;
}

