import { gridColumn } from "@rxweb/grid";

export class vWhiteListIpBase {

    @gridColumn({ style: { width: "12%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'WhitelistIpListComponent_Id_gh', keyColumn: true })
    id:number;

    @gridColumn({ isAscending: true, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'WhitelistIpListComponent_IPAddress_gh', keyColumn: false })
    ipAddress:string;

    @gridColumn({ isAscending: true, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'WhitelistIpListComponent_Comment_gh', keyColumn: false })
    comment:string;

    @gridColumn({ isAscending: true, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 1, allowSorting: true,headerKey:'WhitelistIpListComponent_CreatedOn_gh', keyColumn: false })
    createdOn:string;

    @gridColumn({ isAscending: true, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 1, allowSorting: true,  headerKey: 'WhitelistIpListComponent_UpdatedOn_gh', keyColumn: false })
    updatedOn:string;

    @gridColumn({ isAscending: true,configuredTemplate: { templateName: "recordActive" }, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 1, allowSorting: true,  headerKey: 'WhitelistIpListComponent_IsActive_gh', keyColumn: false })
    isActive:number;
}