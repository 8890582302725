import {vGemstoneStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemstoneStatusLookUp extends vGemstoneStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}