import {ComponentLanguageContentBase,LanguageContentKeyBase,LanguageContentBase,} from '@app/database-models'
//Generated Imports
export class ComponentLanguageContent extends ComponentLanguageContentBase 
{




//#region Generated Reference Properties
//#region languageContentKey Prop
languageContentKey : LanguageContentKeyBase;
//#endregion languageContentKey Prop
//#region languageContent Prop
languageContent : LanguageContentBase;
//#endregion languageContent Prop

//#endregion Generated Reference Properties












}