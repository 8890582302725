import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit } from "@angular/core"
import { AbstractEmailAccount } from '../domain/abstract-email-account';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailAccount } from '@app/models';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { access, } from '@rxweb/angular-router';
import { Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { CLIENT_URL, SERVER_URL } from "src/app/domain/system-constant";
import { PlatformLocation } from "@angular/common";
@multilingual("EmailAccountEditComponent")
@access({ accessLevel: RolePermissionEnums.EmailAccounts, action: "put" })

@Component({
    selector: "app-email-account-edit",
    templateUrl: './email-account-edit.component.html'
})
export class EmailAccountEditComponent extends AbstractEmailAccount implements OnInit, OnDestroy, AfterViewInit {
    emailAccount: EmailAccount;
    subscription: any;
    id: number;
    sendMailTo: boolean = false;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    emailAccountDelete: any = dbAccessModule[RolePermissionEnums.EmailAccounts]["delete"];
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    authToasterMessage: string = "";
    authMessageType: string = "";
    ouathCredentials: {
        clientId: string,
        scope: string,
        state: string,
        redirectURI: string
    } = {
        clientId: "",
        scope: "mail.send user.read offline_access",
        state: "",
        redirectURI: "/api/Authentication/OAuthLoginUsingCode"
    };

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private applicationBroadcaster: ApplicationBroadcaster, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.activatedRoute.queryParams.subscribe(t => {
            this.authToasterMessage = (t['message'] == undefined ? "" : t['message']);
            this.authMessageType = (t['type'] == undefined ? "" : t['type']);
        });
        this.moduleID = RolePermissionEnums.EmailAccounts;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Email Accounts - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {

            if (t) {
                this.emailAccountFormGroup = this.formBuilder.formGroup(EmailAccount, t) as IFormGroup<EmailAccount>;
                this.emailAccountFormGroup.controls.password.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
                RxwebValidators.notEmpty({ message: "You can't leave this field empty" }),
                RxwebValidators.maxLength({ value: 16 })]);
                this.get({ hostUri: CLIENT_URL, path: "assets/keys/keys.json" }).subscribe((res: any) => {
                    if (res != undefined && res.oauthCredentials != undefined) {
                        this.ouathCredentials = res.oauthCredentials;
                    }
                    this.showComponent = true;
                });
            }
            else {
                this.router.navigate(['/email-account']);
            }

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    ngAfterViewInit() {
        if (this.authToasterMessage != "") {
            if (this.authMessageType == "error")
                this.toastr.error(this.authToasterMessage);
            if (this.authMessageType == "success")
                this.toastr.success(this.authToasterMessage);
            this.router.navigate(["/email-account", this.id], { replaceUrl: true });
        }
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editEmailAccount(isRedirect) {
        this.emailAccountFormGroup.submitted = true;
        if (this.emailAccountFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.emailAccountFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                if (existsAlert) {
                    //this.toastr.success("Data Updated Successfully")
                    this.toastr.success(existsAlert[0].showMessage);
                }
                isRedirect ? this.ngOnInit() : this.router.navigate(['/email-account']);
            })
        } else {
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.emailAccountFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
    }
    sendTestMail() {
        this.emailAccountFormGroup.submitted = true;
        if (this.emailAccountFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.emailAccountFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin = false;
                // var existsAlert = this.localizedMessages.filter(x => x.keyName == "Email_Sent");
                //     if (existsAlert) {
                // 	//this.toastr.success("Email has been successfully sent");
                //         this.toastr.success(existsAlert[0].showMessage);
                //     }
                this.sendMailTo = false;
                this.ngOnInit();
            })
        }
        else {
            var existsAlert = this.localizedMessages.filter(x => x.keyName == "Email_Failure");
            if (existsAlert) {
                //this.toastr.warning("Failure sending mail!");
                this.toastr.warning(existsAlert[0].showMessage);
            }
        }
    }
    changeMailTo(event) {
        //this.sendMailTo=true;
        this.sendMailTo = event.target.value == "" ? false : true;

    }
    deleteEmailAccount() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/email-account']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Email");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    callMicrosoftOAuth() {
        window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.ouathCredentials.clientId}&response_type=code&redirect_uri=${SERVER_URL}${this.ouathCredentials.redirectURI}&scope=${this.ouathCredentials.scope}&state=${this.ouathCredentials.state}_${this.id}&prompt=login`);
    }

    get componentName(): string {
        return "EmailAccountEditComponent";
    }
}
