import {vProductDesignLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductDesignLookUp extends vProductDesignLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}