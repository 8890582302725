import {vCustomerCallMeBase,} from '@app/database-models'
//Generated Imports
export class vCustomerCallMe extends vCustomerCallMeBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}