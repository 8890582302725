import {TitleBase,} from '@app/database-models'
//Generated Imports
export class Title extends TitleBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}