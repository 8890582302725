import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemfieldsEnhancementBase {

//#region enhancementId Prop
        @prop()
        enhancementId : number;
//#endregion enhancementId Prop


//#region name Prop
        @required()
        //@alpha({allowWhiteSpace:true})
        @trim()
        @maxLength({value:100})
        @notEmpty()
        name : string;
//#endregion name Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}