import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { http, RxHttp } from "@rxweb/http";

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';

import { ProjectCorporateMapping, EventCustomerMapping, ProjectEventMapping, vRiskCategoryLookUp, RiskProjectMapping } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { ProjectsCorporateMappingFilterViewModel } from 'src/app/view-model/project-corporate-mapping-filter-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { ActivatedRoute } from '@angular/router';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { EventInviteesViewModel } from 'src/app/view-model/event-invitees-view-model';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { PeopleCorporateInvolvedModel } from 'src/app/view-model/people-corporate-involved-model';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { DatePipe, PlatformLocation } from '@angular/common';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { CoreComponent, } from '@rxweb/angular-router';

import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { ProjectRiskAddModel } from "src/app/view-model/project-risk-add-model";
import { AbstractProjectRiskDetail } from "../domain/abstract-project-risk-detail";
import { projectRiskAddListModel } from "src/app/models/extended-models/project-risk-add-list-view-model";
import { RiskProjectMappingAddViewModel } from "src/app/view-model/risk-project-mapping-add-view-model";
import { RiskMenuDetailFilterViewModel } from "src/app/view-model/risk-menu-detail-filter-view-model";
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
@multilingual("ProjectRiskAddComponent")

@Component({
    selector: "app-project-risk-add",
    templateUrl: './project-risk-add.component.html'
})
export class ProjectRiskAddComponent extends AbstractProjectRiskDetail implements OnInit, OnDestroy {
    subscription: any;

    projectRisk: ProjectRiskAddModel;
    projectRiskSearchFormGroup: IFormGroup<ProjectRiskAddModel>;
    projectRiskSearchLookup: any = {
        riskCategory: vRiskCategoryLookUp
    }
    selectedRiskCategoryId: number = 0;
    projectRiskList: projectRiskAddListModel[];
    ProjectRiskListGrid: AppGrid;
    projectRiskMappingArray: projectRiskAddListModel[];
    excludeprojectRiskMappingArray: projectRiskAddListModel[];

    ProjectUserListColumns: any[];
    @Input() mapId: number;
    @Input() hide: () => any;
    @Input() componentType: string;
    @Input() entityTypeId : number
    showSaveButton: boolean = false;
    errorMessage: string = "[\n\"Person/Corporate already exists !\"\n]";
    path: string;
    isFilter: boolean = false;
    totalRecords: number = 0;
    headerBtnChk: boolean = false;
    cloneArray: projectRiskAddListModel[];
    showGrid: boolean = false;
    modalView: ModalView;
    spin: boolean = false;
    titleClose: string;
    Error:string;
    selectedGemFieldGroup : number;
    filterJson : any;


    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, modelView: ModalView, private datePipe: DatePipe,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.projectRiskMappingArray = new Array<projectRiskAddListModel>();
        this.excludeprojectRiskMappingArray = new Array<projectRiskAddListModel>();
        this.cloneArray = new Array<projectRiskAddListModel>()
        this._this = this;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }


    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.bindForm();
    }

    bindForm(){
      this.subscription = this.lookup([
        { propName: "gemfieldGroupCompanyLookUp", path: USER_LOOKUPS.gemfieldGroupCompanyLookUp },
        { propName: "riskCategoryLookUp", path: USER_LOOKUPS.riskCategoryLookUp },
        { params: [this.selectedRiskCategoryId], propName: "specificRiskLookUp", path: USER_LOOKUPS.specificRiskLookUp }
      ]).subscribe((lookup: any) => {
        this.spin = false;
        this.projectRiskSearchLookup = lookup;
        this.projectRisk = new ProjectRiskAddModel();

        if (this.selectedRiskCategoryId > 0) {
            this.projectRisk.categoryId = this.selectedRiskCategoryId;
        }
        if(this.selectedGemFieldGroup > 0 && this.selectedGemFieldGroup.toString() != "")
        {
          this.projectRisk.gemfieldGroupCompany = this.selectedGemFieldGroup;
        }
        this.projectRiskSearchFormGroup = this.formBuilder.formGroup(this.projectRisk) as IFormGroup<ProjectRiskAddModel>;
        this.showComponent = true;
      });
    }
    onRiskCategoryChange(riskCategoryId: number) {
      if (riskCategoryId > 0) {
          this.selectedRiskCategoryId = riskCategoryId;
          this.selectedGemFieldGroup = this.projectRiskSearchFormGroup.value.gemfieldGroupCompany;
          // if (this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId > 0 && this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId != undefined && this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId != null) {
          //     this.selectedLocalRiskOwnerId = this.gemfieldRiskSearchFormGroup.value.localRiskOwnerId;
          // }
          // if (this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId > 0 && this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId != undefined && this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId != null) {
          //     this.selectedBoardRiskOwnerId = this.gemfieldRiskSearchFormGroup.value.boardRiskOwnerId;
          // }
          this.showComponent = false;
          // if (this.gemfieldRiskGrid != null) {
          //     this.isFilter = true;
          //     this.gemfieldRiskGrid.updateSource([]);
          //     this.gemfieldRiskGrid.storeProcedure.nextPage = 1;
          //     this.gemfieldRiskSearchFormGroup.value.rowCount = this.gemfieldRiskGrid.maxPerPage;
          // }
          // this.gemfieldRiskSearchFormGroup.value.pageIndex = 1
          this.bindForm();
      }
    }
    search()
    {
      if(this.ProjectRiskListGrid)
      {
        this.ProjectRiskListGrid.destroy();
        this.ProjectRiskListGrid = undefined;
        this.isFilter = false;
        this.cloneArray = new Array<projectRiskAddListModel>();
        this.headerBtnChk = false;
        // his.ProjectRiskListGrid.storeProcedure.nthis.ProjectRiskListGrid.updateSource([]);
        // textPage = 1;

      }
      this.projectRiskSearchFormGroup.value.pageIndex = PaginationEnum.PageIndex;
      this.bindGrid();
    }
    bindGrid() {
        // this.cloneArray = [];
        // this.cloneArray = new Array<projectRiskAddListModel>();
        if (this.projectRiskSearchFormGroup.valid) {
            this.spin = true;
            this.showGrid = true;
            this.showSaveButton = false;

            if(this.projectRiskSearchFormGroup.value.gemfieldGroupCompany == undefined || this.projectRiskSearchFormGroup.value.gemfieldGroupCompany == null || this.projectRiskSearchFormGroup.value.gemfieldGroupCompany.toString() == "")
            {
              this.projectRiskSearchFormGroup.patchValue({
                gemfieldGroupCompany : ""
              })
            }
            this.projectRiskSearchFormGroup.value.specificRiskName = this.projectRiskSearchFormGroup.value.specificRiskName.trim();
            this.projectRiskSearchFormGroup.value.projectId = this.mapId;
            var json = JSON.parse(JSON.stringify(this.projectRiskSearchFormGroup.value));
            json["moduleId"] = this.entityTypeId

            this.filterJson = json;
            this.get(
                {
                    path: "api/SearchProject/GetProjectRisk",
                    queryParams: {
                        "query": JSON.stringify(json)
                    }
                }
            ).subscribe((searchData: projectRiskAddListModel[]) => {
                this.spin = false;
                if (this.ProjectRiskListGrid != null) {
                    this.isFilter = true;
                }
                this.projectRiskList = searchData[0].UserData;
                this.Error = searchData[0].ErrorMessage;
                if (this.projectRiskList.length > 0) {

                    this.totalRecords = this.projectRiskList[0].totalCount;
                    this.isShowGrid = true;
                    this.isNoRecordFound = false;

                    this.projectRiskList.forEach(x => {
                        x.isChecked = this.headerBtnChk;
                    });

                    this.projectRiskList.forEach(x => {
                        if (this.cloneArray.findIndex(z => z.riskMenuDetailId == x.riskMenuDetailId) == -1) {
                            this.cloneArray.push(x);
                        }
                        else {
                            this.cloneArray.forEach(z => {
                                if (z.riskMenuDetailId == x.riskMenuDetailId) {
                                    x.isChecked = z.isChecked;
                                }
                            });
                        }
                        if(this.headerBtnChk) {
                            if(this.excludeprojectRiskMappingArray.findIndex(x => x.riskMenuDetailId == x.riskMenuDetailId) != -1) {
                                x.isChecked = false;
                            }
                        }
                        else {
                            if(this.projectRiskMappingArray.findIndex(z => z.riskMenuDetailId == x.riskMenuDetailId) != -1){
                                x.isChecked = true;
                            }
                        }
                    });


                    if (!this.isFilter) {
                        this.ProjectRiskListGrid = new AppGrid(this.projectRiskList, projectRiskAddListModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                        this.ProjectRiskListGrid.storeProcedure = {
                            length: this.totalRecords,
                            onPageChanging: this.onPageChanging.bind(this),
                            nextPage: 1,
                            onPageSorting: this.onPageSorting.bind(this)
                        }
                    }
                    else {
                        if (this.ProjectRiskListGrid != null) {
                            this.ProjectRiskListGrid.storeProcedure.length = this.totalRecords;
                            if (this.projectRiskSearchFormGroup.value.pageIndex == 1) {
                                this.ProjectRiskListGrid.storeProcedure.nextPage = 1;
                            }
                            setTimeout(function () {
                            this.ProjectRiskListGrid.updateSource([]);
                            this.ProjectRiskListGrid.updateSource(this.projectRiskList);
                            }.bind(this), 0);

                        }
                    }
                    this.ProjectRiskListGrid.maxPerPage = this.projectRiskSearchFormGroup.value.rowCount;
                    this.ProjectRiskListGrid.componentId = this.componentName;
                    this.updateProjectMappingArray();
                    if (this.projectRiskMappingArray.length > 0 || this.excludeprojectRiskMappingArray.length > 0 || this.headerBtnChk) {
                        this.showSaveButton = true;
                    }
                    else {
                        this.showSaveButton = false;
                    }
                }
                else {
                    this.isNoRecordFound = true;
                    this.isShowGrid = false;
                }
            })
        }
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.projectRiskSearchFormGroup.patchValue({ sortOrder: y, orderBy: x })
        this.ProjectRiskListGrid.storeProcedure.nextPage = z;
        // this.subOpenArray = [];
        // this.openArray = [];
        this.cloneArray = [];
        this.bindGrid();
    }
    onPageChanging(x) {
        this.isFilter = true;
        this.cloneArray = [];
        this.projectRiskSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.ProjectRiskListGrid.maxPerPage })
        this.ProjectRiskListGrid.storeProcedure.nextPage = x;
        // this.subOpenArray = [];
        // this.openArray = [];
        this.bindGrid();
    }

    onCheckboxSelect(t, x) {
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<projectRiskAddListModel>();

        oldCloneArray.forEach(data => {
            let newObj = new projectRiskAddListModel();
            newObj.riskName = data.riskName;
            newObj.riskURL = data.riskURL;
            newObj.riskMenuDetailId = data.riskMenuDetailId;
            newObj.companyName = data.companyName;
            newObj.totalCount = data.totalCount;
            newObj.isSupplierUnwelcome = data.isSupplierUnwelcome;
            newObj.supplierId = data.supplierId;
            newObj.companyContactPermissionRequired = data.companyContactPermissionRequired;
            newObj.introducesBy = data.introducesBy;
            // if ((data.isChecked == true && x.target.checked) || (t.riskMenuDetailId == data.riskMenuDetailId && x.target.checked)) {
            //     newObj.isChecked = true;
            // }
            // else{
            //     newObj.isChecked = false;
            // }

            if(data.riskMenuDetailId == t.riskMenuDetailId)
            {
                newObj.isChecked = x.target.checked;
            }
            else
            {
                newObj.isChecked = data.isChecked;
            }

            this.cloneArray.push(newObj);

        });

        let scrollTop: number = 0;
        if(navigator.userAgent.match(/edg/i))
            scrollTop = document.getElementsByClassName('modal fade show').item(0).scrollTop;
        this.ProjectRiskListGrid.updateSource([]);
        this.ProjectRiskListGrid.updateSource(this.cloneArray);
        if(navigator.userAgent.match(/edg/i))
            document.getElementsByClassName('modal fade show').item(0).scrollTop = scrollTop;
        this.updateProjectMappingArray();
        if (this.projectRiskMappingArray.length > 0 || this.excludeprojectRiskMappingArray.length > 0 || this.headerBtnChk) {
            this.showSaveButton = true;
        }
        else {
            this.showSaveButton = false;
        }
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<projectRiskAddListModel>();
        this.projectRiskMappingArray = [];
        this.excludeprojectRiskMappingArray = [];

        this.projectRiskList.forEach(data => {
            let newObj = new projectRiskAddListModel();
            newObj.riskName = data.riskName;
            newObj.riskURL = data.riskURL;
            newObj.riskMenuDetailId = data.riskMenuDetailId;
            newObj.totalCount = data.totalCount;
            newObj.companyName = data.companyName;
            newObj.isChecked = this.headerBtnChk;
            newObj.supplierId = data.supplierId;
            newObj.isSupplierUnwelcome = data.isSupplierUnwelcome;
            newObj.companyContactPermissionRequired = data.companyContactPermissionRequired;
            newObj.introducesBy = data.introducesBy;
            this.cloneArray.push(newObj);

        });
        this.updateProjectMappingArray();
        this.ProjectRiskListGrid.updateSource([]);
        this.ProjectRiskListGrid.updateSource(this.cloneArray);
        if (this.projectRiskMappingArray.length > 0 || this.excludeprojectRiskMappingArray.length > 0 || this.headerBtnChk) {
            this.showSaveButton = true;
        }
        else {
            this.showSaveButton = false;
        }
    }

    updateProjectMappingArray() {

        this.cloneArray.forEach(x => {
            let projectRiskModel = new projectRiskAddListModel();
            projectRiskModel.riskMenuDetailId = Number(x.riskMenuDetailId);
            projectRiskModel.riskName = x.riskName;
            projectRiskModel.riskURL = x.riskURL;
            projectRiskModel.companyName = x.companyName;

            if (x.isChecked) {
                if(this.headerBtnChk) {
                    if(this.excludeprojectRiskMappingArray.findIndex(z => z.riskMenuDetailId == Number(x.riskMenuDetailId)) != -1) {
                        this.excludeprojectRiskMappingArray.splice(this.excludeprojectRiskMappingArray.findIndex(z => z.riskMenuDetailId == Number(x.riskMenuDetailId)),1);
                    }
                }
                else {
                    if (this.projectRiskMappingArray.findIndex(z => z.riskMenuDetailId == Number(x.riskMenuDetailId)) == -1) {
                        this.projectRiskMappingArray.push(projectRiskModel);
                    }
                }
            }
            else {
                if(this.headerBtnChk) {
                    if(this.excludeprojectRiskMappingArray.findIndex(z => z.riskMenuDetailId == Number(x.riskMenuDetailId)) == -1) {
                        this.excludeprojectRiskMappingArray.push(projectRiskModel);
                    }
                }
                else {
                    if (this.projectRiskMappingArray.findIndex(z => z.riskMenuDetailId == Number(x.riskMenuDetailId)) != -1) {
                        this.projectRiskMappingArray.splice(this.projectRiskMappingArray.findIndex(z => z.riskMenuDetailId == Number(x.riskMenuDetailId)), 1);
                    }
                }
            }
        });
    }
    save(saveFlag: boolean){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludeprojectRiskMappingArray.length)  + " Records";
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.savePersonRisk(saveFlag);
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.savePersonRisk(saveFlag);
        }
     }

    savePersonRisk(saveFlag: boolean) {
        var riskProjectMapping: RiskProjectMapping;
        riskProjectMapping = new RiskProjectMapping();
        riskProjectMapping.ProjectId = this.mapId;
        riskProjectMapping.riskMenuDetailId = 0;
        riskProjectMapping.projectRiskMappingId = 0;
        riskProjectMapping.riskProjectInput = new Array<RiskProjectMappingAddViewModel>();
        this.spin = true;

        if(this.headerBtnChk) {
            riskProjectMapping.headerBtnChk = this.headerBtnChk;
            this.filterJson["rowCount"] = this.totalRecords;
            this.filterJson["pageIndex"] = 1;
            this.filterJson["sortOrder"] = "false";
            riskProjectMapping.query = JSON.stringify(this.filterJson);
            this.excludeprojectRiskMappingArray.forEach(data => {
                var riskProjectAdd = new RiskProjectMappingAddViewModel();
                riskProjectAdd.riskMenuDetailId = data.riskMenuDetailId;
                riskProjectMapping.riskProjectInput.push(riskProjectAdd);
            });
        }
        else {
            this.projectRiskMappingArray.forEach(data => {
                var riskProjectAdd = new RiskProjectMappingAddViewModel();
                riskProjectAdd.riskMenuDetailId = data.riskMenuDetailId;
                riskProjectMapping.riskProjectInput.push(riskProjectAdd);
            });
        }
        this.spin = true;
        this.post(
            {
                path: "api/SearchProject/AddProjectRisk",
                body: riskProjectMapping,
                params : [this.entityTypeId]
            }
        ).subscribe(data => {
            this.spin = false;
            this.cloneArray = [];
            this.projectRiskMappingArray = new Array<projectRiskAddListModel>();
            this.showSaveButton = false;
            this.headerBtnChk = false;
            var existsAlert = getLocalizedValue("Data_Added");
            if(existsAlert)
            {
                this.toastr.success(existsAlert);
            }
            if (!saveFlag) {
                this.hide();
            }
            else {
                this.bindGrid();
            }
        })


    }
    ngOnDestroy(): void {

    }
    get componentName(): string {
        return "ProjectRiskAddComponent";
    }
}
