import {RoleBase,ACLBase,ACLPerObjectBase,RolePermissionBase,} from '@app/database-models'
//Generated Imports
export class Role extends RoleBase 
{




//#region Generated Reference Properties
//#region aCL Prop
aCL : ACLBase[];
//#endregion aCL Prop
//#region aCLPerObjects Prop
aCLPerObjects : ACLPerObjectBase[];
//#endregion aCLPerObjects Prop
//#region rolePermissions Prop
rolePermissions : RolePermissionBase[];
//#endregion rolePermissions Prop

//#endregion Generated Reference Properties












}