import {vNoteTypeRecordBase,} from '@app/database-models'
//Generated Imports
export class vNoteTypeRecord extends vNoteTypeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}