import { ProjectCorporateMappingBase, ProjectBase, } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { EventCustomerMapping } from './event-customer-mapping';
//Generated Imports
export class ProjectCorporateMapping extends ProjectCorporateMappingBase {


    corporateIds: ProjectCorporateMapping[];

    //#region Generated Reference Properties
    //#region project Prop
    project: ProjectBase;
    //#endregion project Prop

    //#endregion Generated Reference Properties
    //#region isSupplier Prop
    @prop({ defaultValue: false })
    isSupplier: boolean
    //#endregion isSupplier Prop

    isHeaderChecked : boolean;

    query:string;
    projectOrEvent:Number = 0;
    ModuleType: string;










}