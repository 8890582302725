import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldSalesChannelRecordBase {

//#region salesChannelId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'salesChannelId', keyColumn: true})
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region salesChannel Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'salesChannel', keyColumn: false})
        salesChannel : string;
//#endregion salesChannel Prop


//#region salesChannelType Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'salesChannelType', keyColumn: false})
        salesChannelType : number;
//#endregion salesChannelType Prop


//#region billingCountryId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'billingCountryId', keyColumn: false})
        billingCountryId : number;
//#endregion billingCountryId Prop

}