import { prop, email, trim,disable, numeric } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';
import { AbstractControl } from '@angular/forms';
import { AnimationKeyframesSequenceMetadata } from '@angular/animations';

export class MoveNoteDocumentHSEFilterViewModel {

    constructor() {
      //  this.incidentType = "";
        this.injurySeverity = "";
        this.injuryType = "";
        this.grievanceType="";
        this.cateogryPersonAffected = "";
        this.corporateGroupOfPersonAffected = "";
       // this.occurrenceArea = "";
        //this.workHourFrom = null;
        //this.workHourTo = null;
        this.referenceNo = "";
        this.supplierId = "";
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.arrested = false;
        this.juvenile = false;
        this.illegalMiner=false;
        this.trespasser=false;
       // this.artisanalIllegalMiner = 0;
        //this.occurrenceType = 0;
        //this.injuredPersonName = 0;
        this.bodilyLocation = "";
        this.lostTimeInjury = "";
        this.vehicleInvolvement = "";
        this.prevailingWeather = "";
        //this.hSEReportStatus = 0;
       // this.accidentNearMissType = "";
        this.sortOrder = "false";
        this.orderBy = "stOccurrence_date";
       // this.nameText="";
      // this.IsRequireOfficialGrievance = 0;
      // this.IsNearMiss = 0;
      // this.IsProperyDamange = 0;
      // this.IsInjuryOccur = 0;
      // this.IsAnySufferedFatality = 0;
    }

    // @prop()
    // modeHoursFrom:string;
    // @prop()
    // modeHoursTo:string;
    @prop()
    registrationFrom: Date;

    @prop()
    registrationTo: Date;

    @prop()
    occurrenceType: string;

    @prop()
    supplierId: string;

    // @prop()
    // @trim()
    // nameText:string;

    @prop()
    injuredPersonName: number;

    @prop()
    injuredPersonNameText: string;

   // @disable({conditionalExpression:function(control:AbstractControl){return this.modeType1 == "View"; }})
    @prop()
    bodilyLocation: string;

   // @disable({conditionalExpression:function(control:AbstractControl){return this.modeType2 == "View"; }})
    @prop()
    lostTimeInjury: string;

    @prop()
    vehicleInvolvement: string;

    @prop()
    prevailingWeather: string;

    @prop()
    occurrenceTypes:string;

    // @prop()
    // hSEReportStatus: number;

   // @disable({conditionalExpression:function(control:AbstractControl){return this.modeType3 == "View"; }})
//     @prop()
//     accidentNearMissType: string;

//    // @disable({conditionalExpression:function(control:AbstractControl){return this.modeType4 == "View"; }})
//     @prop()
//     incidentType: string;

   // @disable({conditionalExpression:function(control:AbstractControl){return this.modeType5 == "View"; }})
    @prop()
    injurySeverity: string;

   // @disable({conditionalExpression:function(control:AbstractControl){return this.modeType6 == "View"; }})
    @prop()
    injuryType: string;

    @prop()
    grievanceType:string

    @prop()
    cateogryPersonAffected: string;

    @prop()
    corporateGroupOfPersonAffected: string;

    // @prop()
    // occurrenceArea: string;

    // @disable({conditionalExpression:function(control:AbstractControl){return this.modeHoursFrom == "View"; }})
    // @prop()
    // @numeric({allowDecimal:true})
    // workHourFrom: any;

    // @disable({conditionalExpression:function(control:AbstractControl){return this.modeHoursTo == "View"; }})
    // @prop()
    // @numeric({allowDecimal:true})
    // workHourTo: any;

    @prop()
    referenceNo: string;

    @prop()
    juvenile: boolean;

    @prop()
    arrested: boolean;

    @prop()
    illegalMiner:boolean;

    @prop()
    trespasser:boolean

    // @prop()
    // artisanalIllegalMiner: number;

    @prop()
    departmentId: string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    IsRequireOfficialGrievance : number;

    @prop()
    IsNearMiss : number;

    @prop()
    IsProperyDamange : number;

    @prop()
    IsInjuryOccur : number;

    @prop()
    IsAnySufferedFatality : number;

    @prop()
    isInternalAudit: number;
    @prop()
    isCourtFiled: number;
    @prop()
    isCourtCredible: number;
    @prop()
    isHseqPerformace: number;

    @prop()
    @numeric()
    incidentId: number;

}
