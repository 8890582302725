import { Component, OnInit, OnDestroy, ElementRef, Input } from "@angular/core"
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AbstractDocumentTypeUserMapping } from '../domain/abstract-document-type-user-mapping';
import { Router } from '@angular/router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { AppGrid } from "src/app/domain/app-grid";
import { DocumentTypeUserViewModelBase } from "src/app/view-model/document-type-user-view-model-base";
import { DocumentTypeUserMapping } from "src/app/models/extended-models/document-type-user-mapping";
import { DocumentTypeUserMappingBase } from "src/app/models/database-models/document-type-user-mapping-base";
import { USER_LOOKUPS } from "src/app/const/uris/user-lookups.uri";
import { DocumentTypeUserMappingAddFilterViewModel } from "src/app/view-model/document-type-user-add-filter-view-model";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
@multilingual("DocumentTypeUserMappingAddComponent")

@Component({
    selector: "app-document-type-user-mapping-add",
    templateUrl: './document-type-user-mapping-add.component.html'
})
export class DocumentTypeUserMappingAddComponent extends AbstractDocumentTypeUserMapping implements OnInit, OnDestroy {
    documentTypeUserMapping: DocumentTypeUserMapping;
    excludeddocumentTypeUserMapping: DocumentTypeUserMapping
    documentTypeUserMappingArray: DocumentTypeUserViewModelBase[];
    subscription: any;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    titleClose: string;
    rowCount: number = PaginationEnum.RowCount;
    pageIndex: number = PaginationEnum.PageIndex;
    sortOrder: string = "true";
    // orderByColumn: string = "fullName";
    orderBy: string = "fullName";
    totalRecords: number = 0;
    isFilter: boolean = false;
    isSearch: boolean = false;
    gridSearchString: string = "";
    documentTypeUserMappingList: DocumentTypeUserViewModelBase[];
    cloneArray: DocumentTypeUserViewModelBase[];
    headerBtnChk: boolean = false;
    json: any;
    titleSearch: string;

    documenttypeIds: number[];
    excludeddocumenttypeIds : number[];

    @Input() documentTypeId: number;
    @Input() hide: Function;
    isShow: boolean = true;
    documentTypeUserMappingAddFilterViewModel: DocumentTypeUserMappingAddFilterViewModel;
    documentTypeUserMappingAddFilterFormGroup: IFormGroup<DocumentTypeUserMappingAddFilterViewModel>
    ErrorMsg: any;
    filterJson: DocumentTypeUserMappingAddFilterViewModel;
    showGrid: boolean;
    _this;

    constructor(private router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.DocumentType;
        this._this = this;
        this.documenttypeIds = new Array<number>();
    }

    ngOnInit(): void {
        this.cloneArray = new Array<DocumentTypeUserViewModelBase>();
       
        this.titleClose = getLocalizedValue("Title_Close");
        this.title.setTitle("Document Type - G-Trac Admin");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.spin = true;

        this.lookup([ { path: 'api/userlookups/CompanyLookUpsForDocumentTypes', propName: "companyLookUp" }]).subscribe((lookup: any) => {
            this.spin = false;
            this.documentTypeCorporateMappingLookup = lookup;
            this.cloneArray = new Array<DocumentTypeUserViewModelBase>();
            this.documentTypeUserMapping = new DocumentTypeUserMapping();
            this.documentTypeUserMapping.documentTypeId = this.documentTypeId;
            this.documentTypeUserMapping.documentTypeUserMappingBase = new Array<DocumentTypeUserMappingBase>();
            this.documentTypeUserMappingFormGroup = this.formBuilder.formGroup(this.documentTypeUserMapping) as IFormGroup<DocumentTypeUserMapping>;

            this.documentTypeUserMapping.documentTypeUserMappingBase = new Array<DocumentTypeUserMappingBase>();
            this.excludeddocumentTypeUserMapping = new DocumentTypeUserMapping();
            this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase = new Array<DocumentTypeUserMappingBase>();
            this.excludeddocumenttypeIds = new Array<number>();
            this.documentTypeUserMappingAddFilterViewModel = new DocumentTypeUserMappingAddFilterViewModel();
            this.documentTypeUserMappingAddFilterViewModel.documentTypeId = +this.documentTypeId;
            this.documentTypeUserMappingAddFilterFormGroup = this.formBuilder.formGroup(DocumentTypeUserMappingAddFilterViewModel, this.documentTypeUserMappingAddFilterViewModel) as IFormGroup<DocumentTypeUserMappingAddFilterViewModel>;
            
            this.showComponent = true;
        })
        
    }

    bindGrid() {
        this.documentTypeUserMappingAddFilterFormGroup.submitted = true;
        this.totalRecords = 0;
        var json = this.documentTypeUserMappingAddFilterFormGroup.value;
        json["pageIndex"] = this.pageIndex;
        json["rowCount"] = this.rowCount;
        json["sortOrder"] = this.sortOrder;
        json["orderBy"] = this.orderBy;
        this.filterJson = json;
        this.spin = true;
        this.showGrid = true;
        this.isShowGrid = true;
        this.isNoRecordFound = false;
        this.dateError = false;
        this.subscription = this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(json)), "flag": false } }).subscribe((t: any) => {
            this.spin = false;
            this.documentTypeUserMappingList = t[0].UserData;
            this.ErrorMsg=t[0].ErrorMessage;
            if (this.documentTypeUserGrid != null) {
                this.isFilter = true;
            }
            if (this.documentTypeUserMappingList.length > 0) {
                this.totalRecords = this.documentTypeUserMappingList[0].totalCount;
                this.isNoRecordFound = false;
                this.documentTypeUserMappingList.forEach(x => {
                    x.isChecked = this.headerBtnChk;
                })
                this.documentTypeUserMappingList.forEach(x => {
                    if(this.headerBtnChk){
                        if(this.excludeddocumenttypeIds.indexOf(x.userId) != -1){
                            x.isChecked = false;
                        }
                    }
                    else{
                        if(this.documenttypeIds.indexOf(Number(x.userId))!= -1){
                            x.isChecked = true;
                        }
                    }
                    if (this.cloneArray.findIndex(z => z.userId == x.userId) == -1) {
                        this.cloneArray.push(x);
                    }
                    else {
                        this.cloneArray.forEach(z => {
                            if (z.userId == x.userId) {
                                x.isChecked = z.isChecked
                            }
                        })
                    }
                    
                })
                this.updateUsersArray();
            } else {
                this.isShowGrid = false;
                this.isNoRecordFound = true;
            }
            if (!this.isFilter) {
                this.documentTypeUserGrid = new AppGrid(this.documentTypeUserMappingList, DocumentTypeUserViewModelBase, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                this.documentTypeUserGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            }
            else {
                if (this.documentTypeUserGrid != null) {
                    this.documentTypeUserGrid.storeProcedure.length = this.totalRecords;
                    if (this.pageIndex == 1) {
                        this.documentTypeUserGrid.storeProcedure.nextPage = 1;
                    }
                    this.documentTypeUserGrid.updateSource([]);
                    this.documentTypeUserGrid.updateSource(this.documentTypeUserMappingList);
                }
            }
            this.documentTypeUserGrid.maxPerPage = this.rowCount;
            this.documentTypeUserGrid.componentId = this.componentName;
            this.documentTypeUserGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(' ');
            this.documentTypeUserGrid.designClass.headerClass = "table-header-sticky".split(" ");
        })
    }

    // saveDocumentTypeUserMappings() {
    //     if (this.documentTypeUserMappingArray.length > 0) {
    //         this.spin = true;
    //         this.documentTypeUserMapping.documentTypeArray = this.documentTypeUserMappingArray;

    //         this.post({ body: this.documentTypeUserMapping }).subscribe(data => {
    //             this.spin = false;
    //             var existsAlert = getLocalizedValue("Data_Added");
    //             if (existsAlert) {
    //                 this.toastr.success(existsAlert);
    //             }
    //             this.hide();
    //         })
    //     }
    // }

    
    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t")+ " total " +  (this.totalRecords - this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.length)  + " Records";;
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveDocumentTypeUserMappings();
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveDocumentTypeUserMappings();
        }
     }

     saveDocumentTypeUserMappings() {
        if (this.documentTypeUserMapping.documentTypeUserMappingBase.length > 0 || this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.length > 0 || this.headerBtnChk) {
            if(this.headerBtnChk){
                this.documentTypeUserMapping.documentTypeUserMappingBase = this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase;
                this.filterJson["sortOrder"] = "false";
                this.filterJson["rowCount"] = this.totalRecords;
                this.filterJson["pageIndex"] = 1;
                this.documentTypeUserMapping.query = JSON.stringify(this.filterJson);
                this.documentTypeUserMapping.headerBtnCheck = this.headerBtnChk;
                this.documentTypeUserMapping.documentTypeId = this.documentTypeId;
            }
            this.spin = true;
            this.post({ body: this.documentTypeUserMapping }).subscribe(data => {
                this.spin = false;
                //this.toastr.success("Data Added Successfully")
                var existsAlert = getLocalizedValue("Data_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
                this.hide();
            })
        }
    }

    onPageSorting(x, y, z) {
        this.sortOrder = y;
        this.orderBy = x;
        this.isFilter = true;
        this.documentTypeUserGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x, y, z) {
        this.documentTypeUserGrid.storeProcedure.nextPage = x;
        this.pageIndex = x;
        this.rowCount = this.documentTypeUserGrid.maxPerPage;
        this.bindGrid();
    }

    onCheckboxSelect(t, x) {
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<DocumentTypeUserViewModelBase>();
        oldCloneArray.forEach(data => {
            let newObj = new DocumentTypeUserViewModelBase();
            newObj.isChecked = data.userId == t.userId ? x.target.checked : data.isChecked;
            newObj.userId = data.userId;
            newObj.fullName = data.fullName;
            newObj.email = data.email;
            newObj.statusId = data.statusId;
            newObj.totalCount = data.totalCount;
            newObj.contactPermissionRequired = data.contactPermissionRequired;
            newObj.introducesBy = data.introducesBy;
            newObj.isUnwelcome = data.isUnwelcome;
            this.cloneArray.push(newObj);
        });

        let scrollTop: number = 0;
        if(navigator.userAgent.match(/edg/i))
            scrollTop = document.getElementsByClassName('modal fade show').item(0).scrollTop;
        this.documentTypeUserGrid.updateSource([]);
        this.documentTypeUserGrid.updateSource(this.cloneArray);
        if(navigator.userAgent.match(/edg/i))
            document.getElementsByClassName('modal fade show').item(0).scrollTop = scrollTop;

        this.updateUsersArray();
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<DocumentTypeUserViewModelBase>();
        this.documentTypeUserMappingList.forEach(data => {
            let newObj = new DocumentTypeUserViewModelBase();
            newObj.isChecked = this.headerBtnChk;
            newObj.userId = data.userId;
            newObj.fullName = data.fullName;
            newObj.email = data.email;
            newObj.statusId = data.statusId;
            newObj.totalCount = data.totalCount;
            newObj.contactPermissionRequired = data.contactPermissionRequired;
            newObj.introducesBy = data.introducesBy;
            newObj.isUnwelcome = data.isUnwelcome;
            this.cloneArray.push(newObj);
        })
        this.updateUsersArray();
        this.documentTypeUserGrid.updateSource([]);
        this.documentTypeUserGrid.updateSource(this.cloneArray);
    }

    updateUsersArray() {
        this.cloneArray.forEach(x => {
            let docTypeUserMapping = new DocumentTypeUserMapping();
            docTypeUserMapping.documentTypeUserMappingId = 0;
            docTypeUserMapping.documentTypeId = this.documentTypeId;
            docTypeUserMapping.createdOn = new Date();
            docTypeUserMapping.createdBy = 0; 
            if (x.isChecked) {
                if(this.headerBtnChk){
                    if(this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.findIndex(t => t.userId == x.userId) != -1 ) {
                        this.excludeddocumenttypeIds.splice(this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.findIndex(t => t.userId == x.userId),1);
                        this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.splice(this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.findIndex(t => t.userId == x.userId),1);
                    }
                }
                else{
                    docTypeUserMapping.userId = x.userId;
                    if (this.documentTypeUserMapping.documentTypeUserMappingBase.findIndex(y => y.userId == x.userId) == -1) {
                        this.documentTypeUserMapping.documentTypeUserMappingBase.push(docTypeUserMapping);
                    }
                    if (this.documenttypeIds.indexOf(Number(x.userId)) == -1) {
                        this.documenttypeIds.push(Number(x.userId));
                    }
                }
            }
            else {
                if(this.headerBtnChk){
                    docTypeUserMapping.userId = x.userId;
                    if (this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.findIndex(t => t.userId == x.userId) == -1) {
                        this.excludeddocumentTypeUserMapping.documentTypeUserMappingBase.push(docTypeUserMapping);
                        this.excludeddocumenttypeIds.push(x.userId);
                    }
                }
                else{
                    if (this.documentTypeUserMapping.documentTypeUserMappingBase.findIndex(y => y.userId == x.userId) != -1) {
                        this.documentTypeUserMapping.documentTypeUserMappingBase.splice(this.documentTypeUserMapping.documentTypeUserMappingBase.findIndex(z => z.userId == x.userId), 1);
                        this.documenttypeIds.splice(this.documenttypeIds.indexOf(Number(x.userId)), 1);

                    }

                    if (this.documenttypeIds.indexOf(Number(x.userId)) != -1) {
                        this.documenttypeIds.splice(this.documenttypeIds.indexOf(Number(x.userId)), 1);
                    }
                }
            }
        });
    }

    search() {
        // this.spin = false;
        // this.isFilter = true;
        // this.totalRecords = 0;
        // this.documentTypeUserGrid.storeProcedure.nextPage = 1;
        // this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
        // this.pageIndex = 1;
        // this.bindGrid();
        // this.spin = true;

        if (this.dateError == false) {
            this.cloneArray = [];
            this.cloneArray = new Array<DocumentTypeUserViewModelBase>();
            this.headerBtnChk = false;
            if (this.documentTypeUserGrid != null) {
                this.documentTypeUserGrid.updateSource([]);
                this.documentTypeUserGrid.storeProcedure.nextPage = 1;
                this.rowCount = this.documentTypeUserGrid.maxPerPage;
            }
            this.pageIndex = 1;
            this.bindGrid();
        }
    }


    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "DocumentTypeUserMappingAddComponent";
      }
}
