import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractBankHoliday } from '../domain/abstract-bank-holidays';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { HolidayList } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { HolidayMappingBase } from '../../../../models/database-models';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormArray } from '@angular/forms';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { PlatformLocation } from "@angular/common";

@access({ accessLevel: RolePermissionEnums.BankHolidays, action: "put" })

@multilingual("BankHolidayEditComponent")
@Component({
  selector: "app-bank-holiday-edit",
  templateUrl: './bank-holidays-edit.component.html'
})
export class BankHolidayEditComponent extends AbstractBankHoliday implements OnInit, OnDestroy {
  holidayList: HolidayList;
  subscription: any;
  id: number;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  holidayDelete: any = dbAccessModule[RolePermissionEnums.BankHolidays]["delete"];
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;



  _this;

  constructor(private title: Title, private router: Router, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private el: ElementRef, private location : PlatformLocation) {
    super();
    this.modalView = modelView;
    applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })
    this.moduleID = RolePermissionEnums.BankHolidays;
    this._this = this;
    location.onPopState(() => {this.dialog.hide()});
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
    this.title.setTitle("Bank Holiday - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    // this.activatedRoute.queryParams.subscribe(t => {
    //   this.domainType = t['domain'];
    // });

    this.lookup([
      { params: [this.id], propName: "holidayList" },
      { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.holidayTypeLookUp, propName: "holidayTypeLookUp" },
    ]).subscribe((lookup: any) => {
      if (lookup.holidayList) {
        this.holidayLookups = lookup;
        let holidayList = new HolidayList();
        holidayList.holidayId = this.holidayLookups.holidayList[0].holidayId;
        holidayList.holidayName = this.holidayLookups.holidayList[0].holidayName;
        holidayList.holidayYear = this.holidayLookups.holidayList[0].holidayYear;
        holidayList.statusId = this.holidayLookups.holidayList[0].statusId;
        holidayList.holidayTypeId = this.holidayLookups.holidayList[0].holidayTypeId;
        holidayList.countryIds = null;
        holidayList.holidayMappings = new Array<HolidayMappingBase>();
        if (this.holidayLookups.holidayList[0].holidayMappings) {
          this.holidayLookups.holidayList[0].holidayMappings.forEach(country => {
            holidayList.countryIds = holidayList.countryIds + country.countryId + ",";
          })
        }
        this.holidayListFormGroup = this.formBuilder.formGroup(holidayList) as IFormGroup<HolidayList>;
        this.showComponent = true;
      }
      else {
        this.router.navigate(['/bank-holidays']);
      }

    })
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
    Swal.close();
  }

  editHolidayList(isRedirect) {
    this.holidayListFormGroup.submitted = true;
    if (this.holidayListFormGroup.valid) {
      Swal.fire({
        width: 600,
        icon: 'info',
        title: getLocalizedValue("Bank_Holiday_Confirmation"),
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
        hideClass: {
          popup: '',                     // disable popup fade-out animation
        },
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.holidayListFormGroup.patchValue({
            recalculateLeave: true
          })
          this.updateBankHoliday(isRedirect);
        } else if (result.isDenied) {
          this.holidayListFormGroup.patchValue({
            recalculateLeave: false
          })
          this.updateBankHoliday(isRedirect);
        }
      })
    } else {
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.holidayListFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
  }

  updateBankHoliday(isRedirect) {
    this.spin = true;
    this.countryIds = this.holidayListFormGroup.value.countryIds.split(",");
    let holidayMappingFormArray = this.holidayListFormGroup.get("holidayMappings") as FormArray;
    holidayMappingFormArray.clear();
    this.countryIds.forEach(data => {
      let holidayMapping = new HolidayMappingBase();
      holidayMapping.countryId = parseInt(data);
      holidayMapping.holidayId = this.id;
      holidayMapping.holidayMappingId = 0;
      this.holidayListFormGroup.get("holidayMappings") as FormArray;
      holidayMappingFormArray.push(this.formBuilder.formGroup(holidayMapping) as IFormGroup<HolidayMappingBase>);
    })
    this.put({ body: this.holidayListFormGroup.value, params: [this.id] }).subscribe(data => {
      this.spin = false;
      let link = "/holiday-lists/" + this.id;
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
      if (existsAlert) {
        //this.toastr.success("Data Updated Successfully")
        this.toastr.success(existsAlert[0].showMessage);
      }
      isRedirect ? this.router.navigate(['/bank-holidays', data]) : this.router.navigate(['/bank-holidays']);
    })
  }

  deleteHolidayList() {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [this.id] }).subscribe(data => {
          this.spin = false;
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert[0].showMessage);
          }
          this.router.navigate(['/bank-holidays']);
        })

      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Bank_Holiday");
    if (existsAlert) {
      return existsAlert[0].showMessage;
    }
  }

  get componentName(): string {
    return "BankHolidayEditComponent";
  }
}
