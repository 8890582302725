import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHolidayProcessListBase {

//#region masterId Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'CustomerHolidayListComponent_Id_gh', keyColumn: true})
        masterId : number;
//#endregion masterId Prop

//#region userName Prop
        @gridColumn({style: { width: "18%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:1, allowSorting: true, headerKey: 'CustomerHolidayListComponent_UserName_gh', keyColumn: false})
        userName : string;
//#endregion userName Prop

//#region leaveTypeName Prop
        @gridColumn({style: { width: "18%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:2, allowSorting: true, headerKey: 'CustomerHolidayListComponent_TypeName_gh', keyColumn: false})
        leaveTypeName : string;
//#endregion leaveTypeName Prop


//#region createdOn Prop
        @gridColumn({style: { width: "18%", "text-align": "initial" },configuredTemplate: { templateName: "dateDispay" },class: ["text-left"], visible: true, columnIndex:3, allowSorting: true, headerKey: 'CustomerHolidayListComponent_CreatedOn_gh', keyColumn: false})
        createdOn : Date;
//#endregion createdOn Prop


//#region startDate Prop
        @gridColumn({style: { width: "18%", "text-align": "initial" } , configuredTemplate: { templateName: "dateDispay" },class: ["text-left"], visible: true, columnIndex:4, allowSorting: true, headerKey: 'CustomerHolidayListComponent_StartDate_gh', keyColumn: false})
        startDate : Date;
//#endregion startDate Prop


//#region endDate Prop
        @gridColumn({style: { width: "18%", "text-align": "initial" },configuredTemplate: { templateName: "dateDispay" },class: ["text-left"], visible: true, columnIndex:5, allowSorting: true, headerKey: 'CustomerHolidayListComponent_EndDate_gh', keyColumn: false})
        endDate : Date;
//#endregion endDate Prop


//#region days Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:6, allowSorting: true, headerKey: 'CustomerHolidayListComponent_Days_gh', keyColumn: false})
        days : number;
//#endregion days Prop


//#region status Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:7, allowSorting: false, headerKey: 'CustomerHolidayListComponent_Status_gh', keyColumn: false, configuredTemplate: { templateName:"leaveStatus" }})
        status : number;
//#endregion status Prop

        statusName: string;

//#region name Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop

//#region managerName Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'managerName', keyColumn: false})
        managerName : string;
//#endregion managerName Prop

//#region reason Prop
        @gridColumn({visible: false, columnIndex:10, allowSorting: true, headerKey: 'reason', keyColumn: false})
        reason : string;
//#endregion reason Prop

//#region holidayType Prop
        @gridColumn({visible: false, columnIndex:11, allowSorting: true, headerKey: 'holidayType', keyColumn: false})
        holidayType : string;
//#endregion holidayType Prop

//#region totalCount Prop
        @gridColumn({ visible: false, columnIndex:12, keyColumn: false})
        totalCount : number;
//#endregion totalCount Prop

roleId:any;
}