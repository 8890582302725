import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductOccassionMappingBase {

//#region productVariantOccassionId Prop
        @prop()
        productVariantOccassionId : number;
//#endregion productVariantOccassionId Prop


//#region productVariantId Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region occassionId Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        occassionId : number;
//#endregion occassionId Prop





}