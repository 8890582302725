import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PaymentMethodBase {

//#region paymentMethodId Prop
        @prop()
        paymentMethodId : number;
//#endregion paymentMethodId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region visibleName Prop
        @required()
        @maxLength({value:200})
        @trim()
        @notEmpty()
        visibleName : string;
//#endregion visibleName Prop


//#region description Prop
        @required()
        @maxLength({value:4000})
        @trim()
        @notEmpty()
        description : string;
//#endregion description Prop


//#region systemKeyword Prop
        @required()
        @maxLength({value:500})
        @trim()
        @notEmpty()
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region displayOrder Prop
        @required()
        @notEmpty()
        @numeric()
        @notEmpty()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrder : number;
//#endregion displayOrder Prop


//#region isImpersonated Prop
        @prop({defaultValue:false})
        isImpersonated : boolean;
//#endregion isImpersonated Prop


//#region isActive Prop
        @prop({defaultValue:false})
        isActive : boolean;
//#endregion isActive Prop

}