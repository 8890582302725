<div>
  <div class="">
    <div class="row">
      <div class="col-lg-12" [rxSpinner]="spin"  *rxMultilingual="" [rxLocalisationInit]="componentName">


        <div class="row" style="padding: 0px 15px 15px 15px" *ngIf="hideButton" [rxLocalisationInit]="componentName">
          <button class="btn btn-success" (click)="AddImages()" rxText="WebPictureListComponent_Add_New_t"></button>
        </div>
        <div class="row" style="padding: 15px;" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
          <div cdkDropList class="ibox example-list row" (cdkDropListDropped)="drop($event)"
            [cdkDropListData]="productsList" style="margin: auto;width: 100% !important; max-width: 100% !important;">
            <div class="ibox-content col-12">
              <div class="example-box col-12" *ngFor="let lt of productsList;let i = index" cdkDrag [cdkDragData]="lt" [rxLocalisationInit]="componentName"
                style="height: auto;width: auto;cursor: initial;">
                <div class="ibox  col-12">
                <!-- <div class="ibox" *ngFor="let lt of productsList;let i = index"  class="drag_container" igxDrag igxDrop #dragRef id="{{1}}"
              (dropped)="onIconDropped($event)" (dragStart)="dragStartHandler(1)"
              (ghostCreate)="ghostCreateHandler(dragRef)" (dragEnd)="dragEndHandler(dragRef)"
              (enter)="onEnterHandler($event)" [ghostClass]="'drag_ghost_class'"> -->
                  <!-- <div class="row col-12">
                    <i class="fa fa-bars" aria-hidden="true" [title]="titleMoveWidget"></i>
                  </div> -->
                  <div class="ibox-content lightBoxGallery col-12" style="cursor: initial;">

                    <div class="col-12">

                      <div class="row">
                        <div class="col-1" *ngIf='lt.IsDrag == 1' [title]="titleMoveWidget" cdkDragHandle style="cursor: move !important;height: inherit;display: flex;background-color: rgb(255, 255, 255);
                        justify-content: center;
                        align-items: center;">
                            <i class="fa fa-bars" aria-hidden="true" [title]="titleMoveWidget"></i>
                          </div>
                          <div class="col-1" *ngIf='lt.IsDrag == 0'>

                          </div>
                        <div class="pl-lg-5 col-11 row">
                          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-lg-0 mb-2">
                            <div class="card border" style="height : 100%">
                              <!-- <div class="ribbon ribbon-top-left"
                              [ngStyle]="{'cursor': products[i*3].productWebPictureId != 0 ? 'pointer' : 'default'}"
                              (click)="clickProduct(products[i*3].pictureURL);">
                              <span>Web</span>
                            </div> -->
                              <div class="card-body text-center" style="display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 235px;max-height: 235px;" data-toggle='modal' data-target='#insertProduct'
                                [ngStyle]="{'cursor': products[i*3].productWebPictureId != 0 ? 'pointer' : 'default'}"
                                (click)="clickProduct(products[i*3].pictureURL);">
                                <ng-container *ngIf="products[i*3].isPreviewSupported">
                                  <img *ngIf="products[i*3].pictureURL != ''" src={{products[i*3].pictureURL}} alt="Card1"
                                    class="img-fluid h-100" (error)="onImageError($event)">
                                </ng-container>
                                <ng-container *ngIf="!products[i*3].isPreviewSupported">
                                  <img class="img-fluid h-100" src="{{defaultThumbnailPath}}" alt="Preview Not Available">
                              </ng-container>
                              </div>
                              <div class="card-footer bg-white" style="padding-top: 0px;">
                                <div class="d-flex">
                                  <div class="mr-auto" style="font-size: 0.875rem;font-weight: 600;padding: 15px 2px;"
                                    rxText="WebPictureListComponent_Web_t">
                                  </div>
                                  <div class="p-2" *ngIf="products[i*3].productWebPictureId != 0">
                                    <a class="" *ngIf="products[i*3].pictureURL !=''" [href]="products[i*3].pictureURL"
                                      type="image/*" data-gallery="">
                                      <button class="btn px-2" [title]="titleView">
                                        <i class="fa fa-eye" aria-hidden="true" style="color:green;font-size: 1rem;"></i>
                                      </button>
                                    </a>
                                    <button class="btn ml-2 px-2" (click)="onDownload(products[i*3].pictureURL)" [title]="titleDownload">
                                      <i class="fa fa-download" aria-hidden="true"></i>             
                                    </button>
                                  </div>
                                  <div class="py-2" *ngIf="products[i*3].productWebPictureId != 0"><button
                                      [title]="titleDelete" class="btn px-2" (click)="onDelete(products[i*3])"> <i
                                        class="fr-delete text-danger" style="font-size: 1rem;"
                                        aria-hidden="true"></i></button></div>
                                </div>
                                <div>
                                  <ngx-dropzone (change)="onSelect($event,1,lt.PictureIndex)" style="height: 80px;">
                                    <ngx-dropzone-label>
                                      <label>Drop here image file</label>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                      (removed)="onRemove(f)">
                                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                  </ngx-dropzone>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-lg-0 mb-2">
                            <div class="card border" style="height : 100%">
                              <!-- <div class="ribbon ribbon-top-left"
                              [ngStyle]="{'cursor': products[i*3+1].productWebPictureId != 0 ? 'pointer' : 'default'}"
                              (click)="clickProduct(products[i*3+1].pictureURL);">
                              <span>Mobile</span>
                            </div> -->
                              <div class="card-body text-center" style="display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 235px;max-height: 235px;" data-toggle='modal' data-target='#insertProduct'
                                [ngStyle]="{'cursor': products[i*3+1].productWebPictureId != 0 ? 'pointer' : 'default'}"
                                (click)="clickProduct(products[i*3+1].pictureURL);">
                                <ng-container *ngIf="products[i*3+1].isPreviewSupported">
                                  <img *ngIf="products[i*3+1].pictureURL!= ''" src={{products[i*3+1].pictureURL}}
                                    alt="Card1" class="img-fluid h-100" (error)="onImageError($event)">
                                </ng-container>
                                <ng-container *ngIf="!products[i*3+1].isPreviewSupported">
                                  <img class="img-fluid h-100" src="{{defaultThumbnailPath}}" alt="Preview Not Available">
                                </ng-container>
                              </div>
                              <div class="card-footer bg-white" style="padding-top: 0px;">
                                <div class="d-flex">
                                  <div class="mr-auto" style="font-size: 0.875rem;font-weight: 600;padding: 15px 2px;"
                                    rxText="WebPictureListComponent_Mobile_t">
                                  </div>
                                  <div class="p-2" *ngIf="products[i*3+1].productWebPictureId != 0">
                                    <a class="" *ngIf="products[i*3+1].pictureURL !=''"
                                      [href]="products[i*3+1].pictureURL" type="image/*" data-gallery="">
                                      <button class="btn px-2" [title]="titleView">
                                        <i class="fa fa-eye" aria-hidden="true" style="color:green;font-size: 1rem;"></i>
                                      </button>
                                    </a>
                                    <button class="btn ml-2 px-2" (click)="onDownload(products[i*3+1].pictureURL)" [title]="titleDownload">
                                      <i class="fa fa-download" aria-hidden="true"></i>              
                                    </button>
                                  </div>
                                  <div class="py-2" *ngIf="products[i*3+1].productWebPictureId != 0"><button
                                      [title]="titleDelete" class="btn px-2" (click)="onDelete(products[i*3+1])"> <i
                                        class="fr-delete text-danger" style="font-size: 1rem;"
                                        aria-hidden="true"></i></button></div>
                                </div>
                                <div>
                                  <ngx-dropzone (change)="onSelect($event,2,lt.PictureIndex)" style="height: 80px;">
                                    <ngx-dropzone-label>
                                      <label>Drop here image file</label>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                      (removed)="onRemove(f)">
                                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                  </ngx-dropzone>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-lg-0 mb-2">
                            <div class="card  border" style="height : 100%">
                              <!-- <div class="ribbon ribbon-top-left"
                              [ngStyle]="{'cursor': products[i*3+2].productWebPictureId != 0 ? 'pointer' : 'default'}"
                              (click)="clickProduct(products[i*3+2].pictureURL);">
                              <span>Zoom</span>
                            </div> -->
                              <div class="card-body text-center" style="display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 235px;max-height: 235px;" data-toggle='modal' data-target='#insertProduct'
                                [ngStyle]="{'cursor': products[i*3+2].productWebPictureId != 0 ? 'pointer' : 'default'}"
                                (click)="clickProduct(products[i*3+2].pictureURL);">
                                <ng-container *ngIf="products[i*3+2].isPreviewSupported">
                                  <img *ngIf="products[i*3+2].pictureURL!=''" src={{products[i*3+2].pictureURL}} alt="Card1"
                                    class="img-fluid h-100" (error)="onImageError($event)">
                                </ng-container>
                                <ng-container *ngIf="!products[i*3+2].isPreviewSupported">
                                  <img class="img-fluid h-100" src="{{defaultThumbnailPath}}" alt="Preview Not Available">
                                </ng-container>
                              </div>
                              <div class="card-footer bg-white" style="padding-top: 0px;">
                                <div class="d-flex">
                                  <div class="mr-auto" style="font-size: 0.875rem;font-weight: 600;padding: 15px 2px;"
                                    rxText="WebPictureListComponent_Web_Zoom_t">
                                  </div>
                                  <div class="p-2" *ngIf="products[i*3+2].productWebPictureId != 0">
                                    <a class="" *ngIf="products[i*3+2].pictureURL !=''"
                                      [href]="products[i*3+2].pictureURL" type="image/*" data-gallery="">
                                      <button class="btn px-2" [title]="titleView">
                                        <i class="fa fa-eye" aria-hidden="true" style="color:green;font-size: 1rem;"></i>
                                      </button>
                                    </a>
                                    <button class="btn ml-2 px-2" (click)="onDownload(products[i*3+2].pictureURL)" [title]="titleDownload">
                                      <i class="fa fa-download" aria-hidden="true"></i>              
                                    </button>
                                  </div>
                                  <div class="py-2" *ngIf="products[i*3+2].productWebPictureId != 0"><button
                                      [title]="titleDelete" class="btn px-2" (click)="onDelete(products[i*3+2])"> <i
                                        class="fr-delete text-danger" style="font-size: 1rem;"
                                        aria-hidden="true"></i></button></div>
                                </div>

                                <div>
                                  <ngx-dropzone (change)="onSelect($event,3,lt.PictureIndex)" style="height: 80px;">
                                    <ngx-dropzone-label>
                                      <label>Drop here image file</label>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                      (removed)="onRemove(f)">
                                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                  </ngx-dropzone>
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>


                        <div class="col-11 row" *ngIf='lt.IsDrag == 1'></div>
                        <div class="col-1 mt-2 d-flex" *ngIf='lt.IsDrag == 1' [rxLocalisationInit]="componentName">
                          <button class="btn btn-danger ml-auto" [title]="titleDelete" (click)="onDelete(products[i*3],lt.PictureIndex)" rxText="Btn_Delete_t"></button>
                        </div>
                      </div>

                      <!-- <div class="col-md-0 col-lg-0 col-xl-1" style="height: inherit;display: flex;
                    justify-content: center;
                    align-items: center;">
                      </div> -->
                    </div>
                    <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls" data-hide-page-scrollbars="false">
                      <div class="slides"></div>
                      <h3 class="title"></h3>
                      <a class="prev">‹</a>
                      <a class="next">›</a>
                      <a class="close">×</a>
                      <a class="play-pause"></a>
                      <ol class="indicator"></ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
