import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CarnetBase {

        //#region carnetID Prop
        @prop()
        carnetID: number;
        //#endregion carnetID Prop


        //#region carnetGuID Prop
        @required()
        @prop({ defaultValue: "13A1994E-6800-4C6F-A096-FC25742B7FD3" })
        carnetGuID: any;
        //#endregion carnetGuID Prop


        //#region aTACarnetNo Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({ value: 50 })
        ataCarnetNo: string;
        //#endregion aTACarnetNo Prop


        //#region carnetExpiryDate Prop
        @required()
        carnetExpiryDate: Date;
        //#endregion carnetExpiryDate Prop


        //#region deleted Prop
        @prop({ defaultValue: 0 })
        deleted: boolean;
        //#endregion deleted Prop


        //#region carnetName Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({ value: 50 })
        carnetName: string;
        //#endregion carnetName Prop


        //#region carnetStatus Prop
        @prop()
        carnetStatus: number;
        //#endregion carnetStatus Prop









}