import {vBillingCountryLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBillingCountryLookUp extends vBillingCountryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}