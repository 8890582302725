import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractFileExtention } from '../domain/abstract-file-extentions';
import { Router } from '@angular/router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Title } from '@angular/platform-browser';
import { getLocalizedValue, redirectOnCTRL, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { AppGrid } from 'src/app/domain/app-grid';
import { vFileExtention } from 'src/app/models/extended-models/v-file-extention';
import { Subscription } from 'rxjs';
import { access } from '@rxweb/angular-router';
import { FileExtentionAddComponent } from '../add/file-extentions-add.component';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';

@multilingual("FileExtentionListComponent")
@access({ accessLevel: RolePermissionEnums.FileExtentions, action: "get" })
@Component({
  selector: 'app-file-extentions-list',
  templateUrl: './file-extentions-list.component.html',
  styleUrls: ['./file-extentions-list.component.css']
})

export class FileExtentionListComponent extends AbstractFileExtention implements OnInit, OnDestroy {
  fileExtention: vFileExtention[];
  fileExtentionAdd:any = FileExtentionAddComponent;
  subscription: any;
  fileExtentionGrid: AppGrid;
  fileExtentionGridColumns: any[];
  isNoRecordFound: boolean = false;
  moduleID: number;
  titleHelp: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;
  constructor(private router: Router, modelView: ModalView, private title: Title, private location : PlatformLocation) {
      super();
      this.modalView = modelView;
      this.moduleID = RolePermissionEnums.FileExtentions;
      location.onPopState(() => this.dialog.hide());
  }


  ngOnInit(): void {
      this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
      this.title.setTitle("File Extentions - G-Trac Admin");
      this.titleHelp = getLocalizedValue("Title_Help");
      this.titleSearch = getLocalizedValue("Title_Search");
      this.bindGrid();
  }

  showSystemHelp() {
      this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  bindGrid() {
      this.spin = true;
      this.subscription = this.get().subscribe((t: any) => {
          this.spin = false;
          this.fileExtention = t;
          this.fileExtentionGrid = new AppGrid(t, vFileExtention, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
          this.fileExtentionGrid.gridColumns.forEach(x => {
              if (this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "put" }) && this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "delete" })) {
                  if (x.name == "action") {
                      x.visible = true;
                  }
              }
              else {
                  if (x.name == "action") {
                      x.visible = false;
                  }
                  if (x.name == "editAction") {
                      x.visible = this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "put" });
                  }
                  if (x.name == "deleteAction") {
                      x.visible = this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "delete" });
                  }
              }
          })
          this.fileExtentionGrid.componentId = this.componentName;
          this.fileExtentionGrid.design(document.getElementById("file-extention"));
      })
  }

   onEdit(fileExtention: vFileExtention ) {
      //this.router.navigate(["email-account", emailAccount.emailAccountId]);
      let url : any;
      url = this.router.serializeUrl(this.router.createUrlTree(["file-extentions", fileExtention.fileExtentionId]));
      redirectOnCTRL(url,this.router,event);
   }

   onDelete(fileExtention: vFileExtention) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {

        if (t === DialogEnum.Ok) {
            this.dialog.hide();
            this.spin = true;
            this.delete({ body: null, params: [fileExtention.fileExtentionId] }).subscribe(data => {
                this.spin = false;
                this.fileExtentionGrid.remove(fileExtention.fileExtentionId);
                var existsAlert = getLocalizedValue("Data_Deleted");
                if (existsAlert) {
                    //this.toastr.success("Data Deleted Successfully")
                    this.toastr.success(existsAlert);
                }
                this.router.navigate(['/file-extentions']);
            })
        }
        this.dialog.hide()
    })
}
DeleteConfirmationMessage() {
  var existsAlert = getLocalizedValue("Delete_Confirmation_File_Extension");
    if (existsAlert) {
        return existsAlert;
    }
}
search(value) {
    if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
        let ele = this.fileExtention.filter(x => x.fileExtentionName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.fileTypeName.toString().trim().toLowerCase().includes(value.target.value.toLowerCase()) ||
            x.fileExtentionId.toString().includes(value.target.value.trim()));
        if (ele.length == 0) {
            this.isNoRecordFound = true;
        } else {
            this.isNoRecordFound = false;
            this.fileExtentionGrid = null;
        }
    } else {
        this.isNoRecordFound = false;
        this.fileExtentionGrid = null;
    }
    setTimeout(() => {
        if (this.fileExtentionGrid == null || this.fileExtentionGrid == undefined) {
            this.fileExtentionGrid = new AppGrid(this.fileExtention, vFileExtention, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.fileExtentionGrid.gridColumns.forEach(x => {
                if (this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "put" }) && this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.fileExtentionGrid.authorize({ accessLevel: RolePermissionEnums.FileExtentions, action: "delete" });
                    }
                }
            })
            this.fileExtentionGrid.componentId = this.componentName;
            this.fileExtentionGrid.refresh("file-extention");
            this.fileExtentionGrid.design(document.getElementById("file-extention"));
        }
        this.fileExtentionGrid.search = value.target.value;
    }, 0);
  }

  ngOnDestroy(): void {
      if (this.subscription)
          this.subscription.unsubscribe();
  }

  get componentName(): string {
      return "FileExtentionListComponent";
  }

}
