import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { AbstractAddress } from '../domain/abstract-address';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';

import { Address, vStateProvinceLookUp, vCountryLookUp } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CoreComponent, } from '@rxweb/angular-router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { List } from '@rxweb/generics';
import * as CryptoJS from 'crypto-js';

import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
import { Validators } from "@angular/forms";
@multilingual("AddressEditComponent")

@Component({
    selector: "app-address-edit",
    templateUrl: './address-edit.component.html'
})
export class AddressEditComponent extends AbstractAddress implements OnInit, OnDestroy {
    @Input() userId: number;
    @Input() addressId: number;
    @Input() isBillingAddress: boolean;
    @Input() isCorporate: boolean;
    @Input() hide: Function;
    address: Address;
    @Input() email: string;
    subscription: any;
    stateProvinceLookUp: vStateProvinceLookUp[];
    stateProvinces:stateProvincesList[];
    tempStateProvinces:vStateProvinceLookUp[];
    id: number;
    titleClose: string;


    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.activatedRoute.queryParams.subscribe(t => {
            this.id = t['id'];
        });
        this.modalView = modalView;
        this._this = this;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.stateProvinces=new Array<stateProvincesList>();
        this.userOrSupplier = this.isCorporate ? "Supplier" : "Customer";
        this.lookup([{ propName: "address", params: [this.addressId] }, { propName: "vStateProvinceLookUp", path: USER_LOOKUPS.stateProvinceLookup }, { propName: "vCountryLookUp", path: USER_LOOKUPS.countryLookUp }]).subscribe((lookup: any) => {
            this.addressLookup = lookup;
            this.stateProvinceLookUp = this.addressLookup.vStateProvinceLookUp;
            this.tempStateProvinces=this.addressLookup.vStateProvinceLookUp;
            this.stateProvinceLookUp.forEach(x=>{
                let province=new stateProvincesList();
                province.stateProvinceId=x.stateProvinceId;
                province.stateProvinceName=x.stateProvinceName;
                this.stateProvinces.push(province);
            })
           // this.tempStateProvinces=this.stateProvinces;
            this.addressLookup.address.isBillingAddress = this.isBillingAddress;
            this.addressLookup.address.statusId = StatusEnum.Active;
            this.addressLookup.address.updatedOn = new Date();
            //this.email = this.addressLookup.address.email;
            this.country = this.addressLookup.address.countryID;
            this.changeState(this.addressLookup.address.countryID, true);
            this.addressFormGroup = this.formBuilder.formGroup(Address, this.addressLookup.address) as IFormGroup<Address>;
            this.showComponent = true;

        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onEmailKeyPress(){
        this.addressFormGroup.submitted=true;
        this.emailError=true;
    }

    onPhoneKeyPress(){
        this.addressFormGroup.submitted=true;
        this.phoneError=true;
    }

    EditAddress() {
        this.addressFormGroup.submitted = true;
        //this.formSubmit=true;
        let usa = this.addressLookup.vCountryLookUp.find(x => x.countryName.toLowerCase().trim() == "usa");
        let canada = this.addressLookup.vCountryLookUp.find(x => x.countryName.toLowerCase().trim() == "canada")
        if ((this.addressFormGroup.value.stateProvinceID == null || this.addressFormGroup.value.stateProvinceID.toString() == "")) {
            if (this.addressFormGroup.value.countryID == usa.countryId || this.addressFormGroup.value.countryID == canada.countryId) {
                this.addressFormGroup.get('stateProvinceID').setValidators([Validators.required])
                this.addressFormGroup.patchValue({
                    stateProvinceID: ""
                })

            }
            else{

                this.addressFormGroup.get('stateProvinceID').clearValidators();
              }

        }
        if (this.addressFormGroup.valid )
        //     && (
        //       (this.isshowStateTextbox==true && this.addressFormGroup.value.stateProvinceName!=null && this.addressFormGroup.value.stateProvinceName.trim()!="")
        //       ||
        //       (this.isshowStateTextbox==false && this.addressFormGroup.value.stateProvinceID!=null && this.addressFormGroup.value.stateProvinceID>0)

        //    ))
           {

            this.stateProvinceError=false;
            this.spin = true;

            if ((this.addressFormGroup.value.stateProvinceID == null || this.addressFormGroup.value.stateProvinceID.toString() == "")) {

                    this.addressFormGroup.patchValue({
                        stateProvinceID: 0
                    })

            }
            if(this.isshowStateTextbox==true)
            {
                this.addressFormGroup.patchValue({
                    stateProvinceID:0
                })
            }
            else
            {
                var state:vStateProvinceLookUp = this.addressLookup.vStateProvinceLookUp.find(x => x.stateProvinceId == this.addressFormGroup.value.stateProvinceID)
                this.addressFormGroup.patchValue({
                    stateProvinceName: (state!=null && state!=undefined)?state.stateProvinceName:null
                })
            }
            this.put({ body: this.addressFormGroup.value, params: [this.addressId] }).subscribe(data => {
                this.spin = false;
                //this.toastr.success("Data Updated Successfully");
                var existsAlert = getLocalizedValue("Address_Updated");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
            })
            this.hide();
        }
        // else{
        //     if(
        //         (this.isshowStateTextbox==true && (this.addressFormGroup.value.stateProvinceName==null || this.addressFormGroup.value.stateProvinceName.trim()==""))
        //         ||
        //         (this.isshowStateTextbox==false && (this.addressFormGroup.value.stateProvinceID==null || this.addressFormGroup.value.stateProvinceID<=0))
        //     ){
        //         this.stateProvinceError=true;
        //     }
        // }

    }

    deleteAddress() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.spin = true;
                this.dialog.hide();
                this.delete({ body: null, params: [this.addressId] }).subscribe(data => {
                    this.spin = false;
                    //this.toastr.success("Data Deleted Successfully");
                    var existsAlert = getLocalizedValue("Address_Deleted");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_People_Address");
        if (existsAlert) {
            return existsAlert;
        }
    }

    selectState(event) {
        if(event.item!=null && event.item != undefined)
            this.changeState(event.item.key);
    }
    changeState(id: number, onInit: boolean = false) {
        this.countryId = id;
        this.stateProvinceError=false;
        if(this.country!=this.countryId)
        {
          this.addressFormGroup.patchValue({
            stateProvinceName:null
          })
          let usa = this.addressLookup.vCountryLookUp.find(x => x.countryName.toLowerCase().trim() == "usa");
          let canada = this.addressLookup.vCountryLookUp.find(x => x.countryName.toLowerCase().trim() == "canada")
          if(this.addressFormGroup.value.countryID==usa.countryId || this.addressFormGroup.value.countryID==canada.countryId){
            this.addressFormGroup.get('stateProvinceID').setValidators([Validators.required])
          }else{

            this.addressFormGroup.get('stateProvinceID').clearValidators();
          }
       }
        this.isDDStateChange = false;
        setTimeout(function () {
          this.isDDStateChange = true;
        }.bind(this), 100);
       // this.showSelectOption = onInit == true ? false : true;


       // this.stateProvinceLookUp = new List<vStateProvinceLookUp>(this.addressLookup.vStateProvinceLookUp);
        //this.stateProvinceLookUp = this.stateProvinceLookUp.where(x => x.countryId == this.countryId);
        this.stateProvinces=new Array<stateProvincesList>();

        this.tempStateProvinces=this.stateProvinceLookUp.filter(x => x.countryId == this.countryId);
        this.tempStateProvinces.forEach(x=>{
            let province=new stateProvincesList();
            province.stateProvinceId=x.stateProvinceId;
            province.stateProvinceName=x.stateProvinceName;
            this.stateProvinces.push(province);
        })
        this.isshowStateTextbox = this.stateProvinces.length>0 ?false:true;
    }

    get componentName(): string {
        return "AddressEditComponent";
      }

}
class stateProvincesList{
    stateProvinceId:number;
    stateProvinceName:string;
}

function Viewchild(arg0: string): (target: AddressEditComponent, propertyKey: "rxSelect") => void {
    throw new Error("Function not implemented.");
}
