import {LeaveTravelEventProjectMappingBase,LeaveMasterBase,} from '@app/database-models'
//Generated Imports
export class LeaveTravelEventProjectMapping extends LeaveTravelEventProjectMappingBase 
{




//#region Generated Reference Properties

//#region leaveMaster Prop
        leaveMaster : LeaveMasterBase;
//#endregion leaveMaster Prop

//#endregion Generated Reference Properties












}