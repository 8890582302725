import {vLeaveTypeLookUpBase,} from '@app/database-models'
import { vLeaveStatusLookUpBase } from '../database-models/v-leave-status-look-up-base';
//Generated Imports
export class vLeaveStatusLookUp extends vLeaveStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}