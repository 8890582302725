import { propArray } from '@rxweb/reactive-form-validators';
import { LeaveTypeCorporateMappingBase } from '../database-models/leave-type-corporate-mapping-base';
import { LeaveTypeCorporateViewModelBase } from '../database-models/leave-type-corporate-view-model-base';
//Generated Imports
export class LeaveTypeCorporateMapping extends LeaveTypeCorporateMappingBase {

    //#region Generated Reference Properties
    //#endregion Generated Reference Properties

    //#region leaveTypeCorporateMappingBase
    @propArray(LeaveTypeCorporateMappingBase)
    leaveTypeCorporateMappingBase: LeaveTypeCorporateMappingBase[];
    //#endregion leaveTypeCorporateMappingBase

    leaveTypeArray: LeaveTypeCorporateViewModelBase[];
}