import {GemStoneLaboratoryGemFieldBase,GemStoneCertificateGemFieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneLaboratoryGemField extends GemStoneLaboratoryGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneCertificateGemFields Prop
gemStoneCertificateGemFields : GemStoneCertificateGemFieldBase[];
//#endregion gemStoneCertificateGemFields Prop

//#endregion Generated Reference Properties






































}