import { TemplateConfig } from '@rxweb/grid';

export const NOOREORDER_CHECKBOX_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => { 
                if(x.hasNoReorder==null || x.hasNoReorder==undefined || x.hasNoReorder==false){
                  return false;
                }
                else{
                  return true;
                }
    },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style"],
                style:{
                  "margin-top":"-4px",
                  "position":"relative"
                },
                 event: {
                  input: "onNoReorderSelect"
                },
                attributes: {
                  type: "checkbox",
                  value: function (e) {
                    //var a = this[e.name];
                    return this[e.name];
                  },
                  checked: function (e) {
                   // var a = this[e.name];
                    return this[e.name];
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

