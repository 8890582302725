
<div *ngIf="showComponent" class="modal fade show" id="address_edit" tabindex="-1" role="dialog" style="padding-right: 17px; display: block;">

  <div class="modal-dialog">
    <div class="modal-content" [formGroup]="documentFormGroup">
      <div class="modal-header text-left">
        <h4 class="modal-title mb-0">Add New Document</h4>
        <button type="button" (click)="hidePopup()" class="close py-2" data-dismiss="modal" [title]="titleClose">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="form-group row m-1" *ngIf="index<2">
        <div class="col-md-8">
          <button type="button" class="btn btn-success">Add new </button>
        </div>

      </div>

      <div class="modal-body pb-0" [formGroup]="documentsFormGroup" *ngFor="let documentsFormGroup of documentsFormArray; let i = index">
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Select Document to upload</label>
          <div class="col-md-8">
            <div class="custom-file">
              <input (change)="onFileChange($event,i)" id="logo" type="file" class="custom-file-input">
              <label for="logo" class="custom-file-label">Choose file...</label>
              <label class="mt-1" *ngIf="documentsFormGroup.value.file">{{documentsFormGroup.value.file.name}}</label>
              <small class="form-text text-danger" [class.d-block]="documentsFormGroup.controls.file.errors">{{documentsFormGroup.controls.file.errorMessage}}</small>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Document Name</label>
          <div class="col-md-8">
            <input type="text" formControlName="fileName" class="form-control">
            <small class="form-text text-danger" [class.d-block]="documentsFormGroup.controls.fileName.errors">{{documentsFormGroup.controls.fileName.errorMessage}}</small>
          </div>
        </div>
        <div *ngIf="i > 0" class="form-group row">
          <div class="col-md-8">
            <button type="button" class="btn btn-danger">Remove</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success">Save</button>
        <button type="button" (click)="hidePopup()" class="btn btn-danger">Close</button>
      </div>
    </div>
  </div>
</div>




