import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractRole } from '../domain/abstract-role';
import { Role, vRole, vCustomerRoleTypeLookUp, vCustomerRoleDomainLookUp } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';

import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { RoleAddComponent } from '../add/role-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("RoleListComponent")
@access({ accessLevel: RolePermissionEnums.UserRoles, action: "get" })

@Component({
    selector: "app-role-list",
    templateUrl: './role-list.component.html'
})
export class RoleListComponent extends AbstractRole implements OnInit, OnDestroy {
    roles: vRole[];
    filteredData: any[];
    role: Role;
    subscription: any;
    rolesGrid: AppGrid;
    roleAdd: any = RoleAddComponent;
    roleType: any;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    SearchString: string = "";


    _this;


    constructor(private formBuilder: RxFormBuilder, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.UserRoles;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("User Roles - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.lookup([
            { path: USER_LOOKUPS.customerRoleTypeLookUp, propName: "customerRoleTypeLookUp" },
        ]).
            subscribe((lookups: any) => {
                this.roleLookups = lookups;
                this.showComponent = true;
                this.bindGrid();
            })
    }
    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vRole[]) => {
            this.spin = false;
            this.roles = t;
            this.rolesGrid = new AppGrid(t, vRole, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.rolesGrid.gridColumns.forEach(x => {
                if (this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "put" }) && this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "delete" });
                    }
                }
            })
            this.rolesGrid.componentId = this.componentName;
            this.rolesGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.rolesGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.rolesGrid.design(document.getElementById("roles"));
        })

    }


    clearGrid() {
        let element = document.getElementById("roles");
        if(element){
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
        }
    }

    filterByRole(event: any) {
        let typeId = event.item.key;
        this.clearGrid();
        if(event.text == ''){
            typeId = "All";
        }
        this.roleType = typeId;

        let ele = this.roles;
        if (this.SearchString != '') {
            ele = this.roles.filter(x => 
                (x.roleName.toLowerCase().includes(this.SearchString.trim().toLowerCase()) 
                || x.roleId.toString().toLowerCase().includes(this.SearchString.trim().toLowerCase()))
                );
        }
        if (typeId == "undefined" || typeId == undefined || typeId == null || typeId == "All") {

            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.rolesGrid = null;
            }
            
            this.rolesGrid = new AppGrid(ele, vRole, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
        }
        else {
            this.filteredData = ele.filter(roleType => {
                return roleType.roleTypeName == typeId;
            })
            if (this.filteredData.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.rolesGrid = null;
            }
            this.rolesGrid = new AppGrid(this.filteredData, vRole, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
        }
        this.rolesGrid.gridColumns.forEach(x => {
            if (this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "put" }) && this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "delete" })) {
                if (x.name == "action") {
                    x.visible = true;
                }
            }
            else {
                if (x.name == "action") {
                    x.visible = false;
                }
                if (x.name == "editAction") {
                    x.visible = this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "put" });
                }
                if (x.name == "deleteAction") {
                    x.visible = this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "delete" });
                }
            }
        })
        this.rolesGrid.componentId = this.componentName;
        this.rolesGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.rolesGrid.designClass.headerClass = "table-header-sticky".split(" ");
        setTimeout(() => {
            let element = document.getElementById("roles");
            if(element){
                this.rolesGrid.design(document.getElementById("roles"));
            }  
        }, 100);

    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onEdit(roles: vRole) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["roles", roles.roleId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["roles", roles.roleId]);
    }

    onDelete(roles: vRole) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {

                this.spin = true;
                this.delete({ body: null, params: [roles.roleId] }).subscribe(data => {
                    this.spin = false;
                    this.rolesGrid.remove(roles.roleId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/roles']);
                })
            }
            this.dialog.hide();

        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_User_Role");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        // this.rolesGrid.search = value.target.value;
        //    let filteredRoles  = new Array<vRole>();
        //    if(value.target.value=="" && (this.roleType=="undefined" || this.roleType==undefined || this.roleType==null || this.roleType=="All")){
        //     filteredRoles=this.roles;
        //    }
        //    else if(value.target.value!="" && (this.roleType=="undefined" || this.roleType==undefined || this.roleType==null || this.roleType=="All")){
        //     filteredRoles=this.roles.filter(x=>x.roleName.toLowerCase().includes(value.target.value.toLowerCase()));
        //    }
        //    else{
        //      filteredRoles=this.roles.filter(x=>x.roleName.toLowerCase().includes(value.target.value.toLowerCase()) && x.roleTypeName==this.roleType);
        //    }
        //    this.rolesGrid.refresh("roles");
        //    this.rolesGrid = new AppGrid(filteredRoles, vRole, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
        //    this.rolesGrid.gridColumns.forEach(x=>{
        //     if(this.rolesGrid.authorize({accessLevel:RolePermissionEnums.UserRoles,action:"put"}) && this.rolesGrid.authorize({accessLevel:RolePermissionEnums.UserRoles,action:"delete"})){
        //         if(x.name=="action"){
        //             x.visible=true;
        //         }
        //     }
        //     else{
        //             if(x.name=="action"){
        //                 x.visible=false;
        //             }
        //             if(x.name=="editAction"){
        //                 x.visible=this.rolesGrid.authorize({accessLevel:RolePermissionEnums.UserRoles,action:"put"});
        //             }
        //             if(x.name=="deleteAction"){
        //                 x.visible=this.rolesGrid.authorize({accessLevel:RolePermissionEnums.UserRoles,action:"delete"});
        //             }
        //     }
        // })
        //    this.rolesGrid.componentId = this.componentName;
        //    this.rolesGrid.design(document.getElementById("roles"));
        let filteredRoles = new Array<vRole>();
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = [];
            if (this.roleType == "undefined" || this.roleType == undefined || this.roleType == null || this.roleType == "All") {
                ele = this.roles.filter(x => x.roleName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.roleId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            }
            else {
                filteredRoles = this.roles.filter(x => x.roleTypeName == this.roleType);
                ele = filteredRoles.filter(x => x.roleName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.roleId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            }
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.rolesGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.rolesGrid = null;
        }
        setTimeout(() => {
            if (this.rolesGrid == null || this.rolesGrid == undefined) {

                if (this.roleType == "undefined" || this.roleType == undefined || this.roleType == null || this.roleType == "All") {
                    this.rolesGrid = new AppGrid(this.roles, vRole, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                }
                else {
                    filteredRoles = this.roles.filter(x => x.roleTypeName == this.roleType);
                    this.rolesGrid = new AppGrid(filteredRoles, vRole, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                }
                this.rolesGrid.gridColumns.forEach(x => {
                    if (this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "put" }) && this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.rolesGrid.authorize({ accessLevel: RolePermissionEnums.UserRoles, action: "delete" });
                        }
                    }
                })
                this.rolesGrid.componentId = this.componentName;
                this.rolesGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
              this.rolesGrid.designClass.headerClass = "table-header-sticky".split(" ");
              this.rolesGrid.refresh("roles");
                this.rolesGrid.design(document.getElementById("roles"));
            }
            this.rolesGrid.search = value.target.value;
        }, 0);
    }

    get componentName(): string {
        return "RoleListComponent";
    }

}
