import {ApplicationObjectBase,} from '@app/database-models'
import { actionColumn, gridColumn } from '@rxweb/grid';

export class ApplicationObject extends ApplicationObjectBase 
{


    @gridColumn({
        visible:true,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 4, headerTitle: "Edit"
    })
    editAction:any;

//#region Generated Reference Properties

//#endregion Generated Reference Properties












}
