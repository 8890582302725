import { Component, OnInit, OnDestroy } from "@angular/core"
import { AppGrid } from '../../../../domain/app-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { AbstractDocumentTypeUserMapping } from "../domain/abstract-document-type-user-mapping";
import { DocumentTypeUserMappingViewModel } from "src/app/view-model/document-type-user-mapping-view-model";
import { DocumentTypeUserMappingAddComponent } from "../add/document-type-user-mapping-add.component";

@access({ accessLevel: RolePermissionEnums.DocumentType, action: "get" })

@multilingual("DocumentTypeUserMappingListComponent")
@Component({
    selector: "app-document-type-user-mapping-list",
    templateUrl: './document-type-user-mapping-list.component.html'
})
export class DocumentTypeUserMappingListComponent extends AbstractDocumentTypeUserMapping implements OnInit, OnDestroy {
    subscription: any;
    id: number = 0;
    loggedInUser: number;
    isFilter: boolean = false;
    titleSearch: string;
    json: any;
    totalRecords: number = 0;
    documentTypeCorporateGridColumns: any;
    documentTypeUserMappingList: DocumentTypeUserMappingViewModel[];
    titleExpand: string;
    titleCollapse: string;

    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private activatedRoute: ActivatedRoute, private browserStorage: BrowserStorage) {
        super();
        Window["DocumentTypeUserMappingListComponent"] = this;
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        if (this.browserStorage.local.get("userId") != null) {
            this.loggedInUser = this.browserStorage.local.get("userId");
        } else {
            this.loggedInUser = 0;
        }
        this.modalView = modelView;
    }

    ngOnInit(): void {
        this.isFilter = false;
        this.titleSearch = getLocalizedValue("Title_Search");
        this.json = JSON.parse(JSON.stringify({
            documentTypeId: this.id,
            userId: this.browserStorage.local.get("userId"),
            pageIndex: 1,
            rowCount: 25,
            sortOrder: true,
            orderBy: 'documentTypeName',
            searchString: ''
        }));
        this.json["pageIndex"] = PaginationEnum.PageIndex;
        this.json["rowCount"] = PaginationEnum.RowCount;
        this.titleExpand = getLocalizedValue("Title_Expand");
        this.titleCollapse = getLocalizedValue("Title_Collapse");
        this.bindGrid();
    }

    bindGrid() {
        this.isShowGrid = true;
        this.spin = true;

        this.get({ path: 'api/DocumentTypeUserMappings', queryParams: { query: encodeURIComponent(JSON.stringify(this.json)), "flag": true } }).subscribe((t: any) => {
            this.spin = false;
            this.documentTypeUserMappingList = t;

            if (this.documentTypeUserMappingList.length > 0) {
                this.totalRecords = this.documentTypeUserMappingList[0].totalCount;
                this.isNoRecordFound = false;

                this.documentTypeUserMappingList.forEach(x => {
                    if (x.companyDetails != null && x.companyDetails != undefined && x.companyDetails.length > 0) {
                      x.company = this.formEmploymentTable(x);
                    }
                });
            }
            else {
                this.isNoRecordFound = true;
                this.isShowGrid = false;
            }

            if (!this.isFilter) {
                this.documentTypeUserGrid = new AppGrid(this.documentTypeUserMappingList, DocumentTypeUserMappingViewModel, { actions: { onDelete: this.onDelete.bind(this)} });
                this.documentTypeUserGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            }
            else {
                if (this.documentTypeUserGrid != null) {
                    this.documentTypeUserGrid.storeProcedure.length = this.totalRecords;
                    this.documentTypeUserGrid.updateSource([]);
                    this.documentTypeUserGrid.updateSource(this.documentTypeUserMappingList)
                }
            }
            this.documentTypeUserGrid.designClass.tableClass.push("table-list");
            if (this.documentTypeUserGrid != null) {
                this.documentTypeCorporateGridColumns = this.documentTypeUserGrid.gridColumns.filter(x => x.keyColumn == false);
                //this.applyClassProjectCorporateGrid();
            }
            this.documentTypeUserGrid.maxPerPage = this.json['rowCount'];
            this.documentTypeUserGrid.componentId = this.componentName;
            this.spin = false;
            this.showComponent = true;
        });
    }
    
  formEmploymentTable(item: DocumentTypeUserMappingViewModel): any {
    let tabledata: any;
    let row: string = "";
    let subRow: any = "";
    tabledata = "<div><table class='w-100'><tbody>";
    let subTable: any;
    subTable = "<tr id='employment-row-" + item.documentTypeUserMappingId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
    if (item.companyDetails != null && item.companyDetails != undefined) {
      if (item.companyDetails.length > 1) {
        for (let i = 0; i < item.companyDetails.length; i++) {
          let x = item.companyDetails[i];
          if (i == 0) {
            row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.jobTitle} ${x.companyStatus}<i title="${this.titleExpand}" class='float-right fa fa-chevron-down ml-2' id='icon-employment-${item.documentTypeUserMappingId}' onclick='Window.DocumentTypeUserMappingListComponent.expandEmploymentTable("${item.documentTypeUserMappingId}")'></i></td></tr>`;
          }
          else {
            subRow = subRow + `<tr><td><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.jobTitle} ${x.companyStatus}</td></tr>`;
          }
        }
      }
      else if (item.companyDetails.length == 1) {
        let x = item.companyDetails[0];
        row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> ${x.jobTitle} ${x.companyStatus}</td></tr>`;
      }
      if (subRow != "") {
        subTable = subTable + subRow + "</tbody></table></div></td></tr>";
        row = row + subTable;
      }
      tabledata = tabledata + row + "</tbody></table></div>"
    }
    return tabledata;
  }

  
  expandEmploymentTable(id: number) {
    var element = document.getElementById("employment-row-" + id.toString());
    var iconElement = document.getElementById("icon-employment-" + id.toString());
    if (element != null) {
      if (element.style.display == "none") {
        element.style.display = "block";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-up ml-2"
          iconElement.title = this.titleCollapse;
        }

      }
      else {
        element.style.display = "none";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-down ml-2"
          iconElement.title = this.titleExpand;
        }

      }
    }
  }


    showAddForm() {
        this.modalView.show(DocumentTypeUserMappingAddComponent, { documentTypeId: this.id }).then(t => {
            this.isFilter = true;
            this.totalRecords = 0;
            this.documentTypeUserGrid.updateSource([]);
            this.bindGrid();
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onDelete(DocumentTypeUserMappingDtl: DocumentTypeUserMappingViewModel) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [DocumentTypeUserMappingDtl.documentTypeUserMappingId] }).subscribe(data => {
                    this.spin = false;
                    this.documentTypeUserGrid.remove(DocumentTypeUserMappingDtl.documentTypeUserMappingId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.bindGrid();
                })
            }
            this.dialog.hide()
        })

    }

    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_DocumentType_UserMapping");
        if (existsAlert) {
            return existsAlert;
        }
    }

    search(value) {
        setTimeout(() => {
            this.spin = false;
            this.isFilter = true;
            this.json['searchString'] = value.target.value.replace(/^\s+|\s+$/gm, '');
            this.totalRecords = 0;
            this.documentTypeUserGrid.storeProcedure.nextPage = 1;
            this.json['pageIndex'] = 1;
            this.bindGrid();
        }, 4000);
        this.spin = true;
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json['sortOrder'] = y;
        this.json['orderBy'] = x;
        this.documentTypeUserGrid.storeProcedure.nextPage = z;
        this.documentTypeUserGrid.updateSource([]);
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.json['pageIndex'] = x;
        this.json['rowCount'] = this.documentTypeUserGrid.maxPerPage
        this.documentTypeUserGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    get componentName(): string {
        return "DocumentTypeUserMappingListComponent";
    }
}
