import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Title } from '@angular/platform-browser';
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { AppGrid } from 'src/app/domain/app-grid';
import { access } from '@rxweb/angular-router';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';
import { AbstractApplicationObject } from '../domain/abstract-application-objects';
import { ApplicationObject, vApplicationObjectLookup } from '@app/models';
import { ApplicationObjectAddComponent } from '../add/application-objects-add.component';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { IFormGroup, prop, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { vApplicationObjectTypeLookup } from 'src/app/models/extended-models/v-application-object-type-look-up-base';

@multilingual("ApplicationObjectListComponent")
@access({ accessLevel: RolePermissionEnums.ApplicationObject, action: "get" })
@Component({
    selector: 'app-application-objects-list',
    templateUrl: './application-objects-list.component.html'
})

export class ApplicationObjectListComponent extends AbstractApplicationObject implements OnInit, OnDestroy {
    applicationObject: ApplicationObject[];
    applicationObjectAdd: any = ApplicationObjectAddComponent;
    applicationObjectSearchModel: ApplicationObjectSearchModel;
    applicationObjectSearchFormGroup: IFormGroup<ApplicationObjectSearchModel>;
    subscription: any;
    applicationObjectGrid: AppGrid;
    fileExtentionGridColumns: any[];
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    applicationObjectTypeLookup: vApplicationObjectTypeLookup;
    showSystemHelpIcon: boolean = false;
    appObjTypelookups: any;
    _this;
    constructor(router: Router, modelView: ModalView, private title: Title, private location: PlatformLocation,
        private formBuilder: RxFormBuilder
    ) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.ApplicationObject;
        location.onPopState(() => this.dialog.hide());
        this._this = this;
        this.router = router;

    }


    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Application Object - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");


        this.lookup([
            { path: USER_LOOKUPS["applicationObjectTypeLookup"], propName: "applicationObjectTypeLookup" },
        ]).subscribe((lookup: any) => {
            this.applicationObjectTypeLookup = new vApplicationObjectTypeLookup();
            this.applicationObjectTypeLookup = lookup.applicationObjectTypeLookup;
            this.appObjTypelookups = lookup.applicationObjectTypeLookup;
            this.applicationObjectSearchModel = new ApplicationObjectSearchModel();

            this.applicationObjectSearchModel.applicationObjectTypeName = "Select Application Type";
            this.applicationObjectSearchFormGroup = this.formBuilder.formGroup(
                ApplicationObjectSearchModel,
                this.applicationObjectSearchModel
            ) as IFormGroup<ApplicationObjectSearchModel>;
            this.showComponent = true;
        });

        this.bindGrid();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        // this.spin = true;
        let appObjTypeId = this.applicationObjectSearchFormGroup.controls.applicationObjectTypeName.value;
        if(appObjTypeId != undefined && appObjTypeId != null && appObjTypeId != ""){
        this.subscription = this.get({ path: "api/ApplicationObject/GetByApplicationObjectTypeId", params: [appObjTypeId] }).subscribe((t: any) => {
            this.spin = false;
            this.isNoRecordFound = false;
            this.applicationObject = t;
            this.applicationObject.forEach(y => {
                y.applicationObjectTypeName = this.appObjTypelookups.find(x => x.applicationObjectTypeId == y.applicationObjectTypeId).applicationObjectTypeName;
            });
            this.applicationObjectGrid = new AppGrid(t, ApplicationObject
                , { actions: { onEdit: this.onEdit.bind(this) } }
            );
            this.applicationObjectGrid.gridColumns.forEach(x => {
                if (this.applicationObjectGrid.authorize({ accessLevel: RolePermissionEnums.ApplicationObject, action: "put" })) {
                    if (x.name == "editAction") {
                        x.visible = this.applicationObjectGrid.authorize({ accessLevel: RolePermissionEnums.ApplicationObject, action: "put" });
                    }
                }
            })
            this.applicationObjectGrid.componentId = this.componentName;
            setTimeout(() => {
                this.applicationObjectGrid.refresh("application-object");
                this.applicationObjectGrid.design(document.getElementById("application-object"));
            }, 100);
        })
    }else{
        this.isNoRecordFound = true;
    }
    }

    onEdit(applicationObject: ApplicationObject) {
        this.router.navigate(["/application-object/", applicationObject.applicationObjectId], { queryParams: { activeTab: 'edit-info' } });
    }

    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {

            this.applicationObject.forEach(y => {
                y.applicationObjectTypeName = this.appObjTypelookups.find(x => x.applicationObjectTypeId == y.applicationObjectTypeId).applicationObjectTypeName;
            });

            let ele = this.applicationObject.filter(x => x.applicationObjectId == (value.target.value.trim().toLowerCase()) || x.applicationObjectName.toString().trim().toLowerCase().includes(value.target.value.toLowerCase()) ||
                x.applicationObjectId.toString().includes(value.target.value.trim()) || x.applicationObjectTypeName.toString().trim().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.applicationObjectGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.applicationObjectGrid = null;
        }
        setTimeout(() => {
            if (this.applicationObjectGrid == null || this.applicationObjectGrid == undefined) {
                this.applicationObjectGrid = new AppGrid(this.applicationObject, ApplicationObject, { actions: { onEdit: this.onEdit.bind(this) } });
                this.applicationObjectGrid.gridColumns.forEach(x => {
                    if (this.applicationObjectGrid.authorize({ accessLevel: RolePermissionEnums.ApplicationObject, action: "put" })) {
                        if (x.name == "editAction") {
                            x.visible = this.applicationObjectGrid.authorize({ accessLevel: RolePermissionEnums.ApplicationObject, action: "put" });
                        }
                    }
                })
                this.applicationObjectGrid.componentId = this.componentName;
                this.applicationObjectGrid.refresh("application-object");
                this.applicationObjectGrid.design(document.getElementById("application-object"));
            }
            this.applicationObjectGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "ApplicationObjectListComponent";
    }

}

class ApplicationObjectSearchModel {
    @prop()
    applicationObjectTypeName: string;
}
