import {GemfieldsLocationBase,} from '@app/database-models'
//Generated Imports
export class GemfieldsLocation extends GemfieldsLocationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}