import {vProductAttributeRecordBase,} from '@app/database-models'
//Generated Imports
export class vProductAttributeRecord extends vProductAttributeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}