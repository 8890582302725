import { actionColumn, gridColumn } from '@rxweb/grid';

@actionColumn({
  allowSorting: false,
  style: { width: "5%", "text-align": "initial" },
  visible : false,
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})

export class PressMediaViewViewModel {

  @gridColumn({ style: { width: "0.1%", "text-align": "initial" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
  isChecked: boolean

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.pressMediaId;
          }
        },
      }
    },
    parameter: true, style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'PressMediaViewComponent_CoverPageId_gh', keyColumn: false
  })
  pressMediaId: string

  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.article;
          }
        },
        childrens:[{
          a: {
            isBind: (x) => {
                    if (x.articleCount > 100 && !x.isShowAll) {
                            return true;
                    }
                    else {
                            return false;
                    }
            }, style: {
                    "display": "block"
            },
            childrens: [{
                    span: {
                            class: "more-btn mr-2".split(" "),
                            childrens: [{
                                    text: {
                                            text: function (e) {
                                                    return "Show More";
                                            }
                                    }
                            }]
                    }
            }],
            event: {
                    click: "onMoreArticleClick"
            }
          },
        }]

      }
    },
    parameter: false, style: { "min-width": "300px", width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey: 'PressMediaViewComponent_Article_gh', keyColumn: false
  })
  article: string

  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.url;
          }
        },
      }
    },
    parameter: false, style: {"min-width": "200px", width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, headerKey: 'PressMediaViewComponent_Url_gh', keyColumn: false
  })
  url: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.keywordNames;
          }
        },
      }
    },
    parameter: false, style: { "min-width": "150px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'PressMediaViewComponent_Keyword_gh', keyColumn: false
  })
  keywordNames: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.hashtagName;
          }
        },
      }
    },
    parameter: false, style: { "min-width": "200px",width: "6%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'PressMediaViewComponent_Hashtag_gh', keyColumn: false
  })
  hashtagName: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            if (this.journalistNamesData == null) {
              return "";
            }
            else {
              return this.journalistNamesData;
            }
          }
        }
      }
    },
    parameter: false, style: { "min-width": "200px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'PressMediaViewComponent_Journalist_gh', keyColumn: false
  })
  journalistNamesData: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            if (this.publicationsData == null) {
              return "";
            }
            else {
              return this.publicationsData;
            }
          }
        }
      }
    },
    parameter: false, style: { "min-width": "200px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'PressMediaViewComponent_Publication_gh', keyColumn: false
  })
  publicationsData: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            if (this.peopleNamesData == null) {
              return "";
            }
            else {
              return this.peopleNamesData;
            }
          }
        }
      }
    },
    parameter: false, style: { "min-width": "120px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'PressMediaViewComponent_People_gh', keyColumn: false
  })
  peopleNamesData: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            if (this.corporatesData == null) {
              return "";
            }
            else {
              return this.corporatesData;
            }
          }
        }
      }
    },
    parameter: false, style: {"min-width": "200px", width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'PressMediaViewComponent_Corporate_gh', keyColumn: false
  })
  corporatesData: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.eventNames;
          }
        },
      }
    },
    parameter: false, style: {"min-width": "200px", width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'PressMediaViewComponent_Event_gh', keyColumn: false
  })
  eventNames: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.projectNames;
          }
        },
      }
    },
    parameter: false, style: { "min-width": "200px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'PressMediaViewComponent_Project_gh', keyColumn: false
  })
  projectNames: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.productNames;
          }
        },
      }
    },
    parameter: false, style: { "min-width": "200px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'PressMediaViewComponent_Product_gh', keyColumn: false
  })
  productNames: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.hSEGINANames;
          }
        },
      }
    },
    parameter: false, style: { "min-width": "200px",width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 13, allowSorting: true, headerKey: 'PressMediaViewComponent_HSEGINA_gh', keyColumn: false
  })
  hSEGINANames: string

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.articleName;
          }
        },
      }
    },
    parameter: false, style: { "min-width": "200px",width: "5%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 14, allowSorting: true, headerKey: 'PressMediaViewComponent_ArticleType_gh', keyColumn: false
  })
  articleName: string

  @gridColumn({
    template: {
      // div:{
      //   attributes:{
      //     innerHTML:function(x,y){
      //      return this.mostRecentManualNote;
      //     }
      //   },
      // }
      div: {
        style: {
          "word-break": "break-all",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.mostRecentManualNote;
          }
        },
        childrens: [
          {
          a: {
            isBind: (x) => {
              if (x.charCount > 100 && !x.isShowAll) {
                return true;
              }
              else {
                return false;
              }
            },
            style: {
              "display": "block"
            },
            childrens: [{
              span: {
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClick"
            }
          },
        },
          {
            span: {
              isBind: (x) => {
                if (x.physicalFileName != null && x.restrictedUser != "true") {
                  return true;
                } else {
                  return false;
                }
              },
              style: {
                display: "block",
              },
              attributes: {
                innerHTML: function (x, y) {
                  return this.multipleDocumentName;
                },
              },
              event: {
                click: "onDocClick",
              },
            },
          }
      ]
      }
    },
    parameter: false, style: { width: "5%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 17, allowSorting: false, headerKey: 'PressMediaViewComponent_MRManualNote_gh', keyColumn: false
  })
  mostRecentManualNote: string

  noteCharCount: any;

  // @gridColumn({
  //   template: {

  //     div: {
  //       style: {
  //         "word-break": "break-all",
  //       },
  //       attributes: {
  //         innerHTML: function (x, y) {
  //           return this.noteDescription1;
  //         }
  //       },

  //     }
  //   },
  //   parameter: true, style: { width: "5%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 17, allowSorting: false, headerKey: 'PressMediaViewComponent_MRManualNote_gh', keyColumn: false
  // })

  // noteDescription1: any;
  noteId: number;
  charCount: any;
  totalCount: number;
  mediaId: number;
  isJournalists: string;
  isPublication: string;
  articleCount:any;


  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            if (this.risks == null) {
              return "";
            }
            else {
              return this.risks;
            }
          }
        }
      }
    },
    style: {"min-width": "200px", width: "7%", "text-align": "initial" }, class: ["text-left"],parameter: false, visible: true, columnIndex: 19, allowSorting: false, headerKey: 'PressMediaViewComponent_Risk_gh', keyColumn: false
  })
  risks: string;
  riskDetails: any[];
  corporateList: any[];
  PeoplesList: any[];
  JournalistsList: any[];
  PublicationsList: any[];
  @gridColumn({ parameter: false, visible: true, columnIndex: 15, allowSorting: true, headerKey: 'PressMediaViewComponent_Published_gh', keyColumn: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"] })
  publishedOn: string

  @gridColumn({ parameter: false, visible: true, columnIndex: 16, allowSorting: true, headerKey: 'PressMediaViewComponent_CreatedOn_gh', keyColumn: false, style: { width: "5%", "text-align": "initial" }, class: ["text-left"] })
  createdOn: string

  @gridColumn({
    visible: false,
    name: "deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 20, headerTitle: "Delete"
  })
  deleteAction: any;
}
