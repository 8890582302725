 import { LegalAgreementBase} from '@app/database-models';
import { CorporateOrderEventProjectMappingBase } from '../database-models/corporate-order-event-project-mapping-base';
//Generated Imports
//#291870//28-06-2024//adding order mapping to project and event
export class CorporateOrderEventProjectMapping extends CorporateOrderEventProjectMappingBase
{

    OrderIds: CorporateOrderEventProjectMapping[];

    //#endregion Generated Reference Properties
    headerBtnCheck:boolean;
    query:string;
    isProject:boolean;
    projectId: number;
    eventId: number;

}
