import { prop, propObject, propArray, required, maxLength, range, notEmpty, email, numeric, alpha, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BoutiqueBase {

        //#region boutiqueId Prop
        @prop()
        boutiqueId: number;
        //#endregion boutiqueId Prop


        //#region boutiqueName Prop
        @required()
        @maxLength({ value: 400 })
        @trim()
        @notEmpty()
        boutiqueName: string;
        //#endregion boutiqueName Prop


        //#region address Prop
        @prop()
        @maxLength({ value: 1000 })
        @trim()
        address: string;
        //#endregion address Prop


        //#region openingHours Prop
        @maxLength({ value: 400 })
        @trim()
        openingHours: string;
        //#endregion openingHours Prop


        //#region phoneNumber Prop
        //@numeric()
        @prop()
        //@maxLength({value:50})
        @maxLength({ value: 1000 })
        phoneNumber: string;
        //#endregion phoneNumber Prop


        //#region email Prop
        @maxLength({ value: 255 })
        @trim()
        // @email()
        email: string;
        //#endregion email Prop


        //#region description Prop
        @prop()
        @maxLength({ value: 3000 })
        @trim()
        description: string;
        //#endregion description Prop


        //#region pictureId Prop
        //@range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureId: number;
        //#endregion pictureId Prop


        //#region latitude Prop
        @prop()
        latitude: number;
        //#endregion latitude Prop


        //#region longitude Prop
        @prop()
        longitude: number;
        //#endregion longitude Prop


        //#region displayOrder Prop
        @required()
        @notEmpty()
        @numeric()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        displayOrder: number;
        //#endregion displayOrder Prop


        //#region boutiqueManager Prop
        @maxLength({ value: 255 })
        boutiqueManager: string;
        //#endregion boutiqueManager Prop


        //#region managerId Prop
        @prop()
        managerId: number;
        //#endregion managerId Prop


        //#region supervisorId Prop
        @prop()
        supervisorId: number;
        //#endregion supervisorId Prop


        //#region isFaberge Prop
        @prop({ defaultValue: false })
        isFaberge: boolean;
        //#endregion isFaberge Prop

        //#region isFaberge Prop
        @prop({ defaultValue: false })
        isBoutiqueAddress: boolean;
        //#endregion isFaberge Prop

        //#region countryId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        countryId: number;
        //#endregion countryId Prop

        
        //#region cityName Prop
        @required()
        @notEmpty()
        @maxLength({ value: 255 })
        @trim()
        cityName: string;
        //#endregion cityName Prop


        //#region sEName Prop
        @prop()
        @maxLength({ value: 1000 })
        @trim()
        sEName: string;
        //#endregion sEName Prop


        //#region metaTitleCountry Prop
        @prop()
        @maxLength({ value: 1000 })
        metaTitleCountry: string;
        //#endregion metaTitleCountry Prop


        //#region metaDescriptionCountry Prop
        @prop()
        @maxLength({ value: 2000 })
        @trim()
        metaDescriptionCountry: string;
        //#endregion metaDescriptionCountry Prop


        //#region metaKeywordsCountry Prop
        @prop()
        @maxLength({ value: 2000 })
        @trim()
        metaKeywordsCountry: string;
        //#endregion metaKeywordsCountry Prop


        //#region metaTitleCity Prop
        @prop()
        @maxLength({ value: 2000 })
        @trim()
        metaTitleCity: string;
        //#endregion metaTitleCity Prop


        //#region metaDescriptionCity Prop
        @prop()
        @maxLength({ value: 4000 })
        @trim()
        metaDescriptionCity: string;
        //#endregion metaDescriptionCity Prop


        //#region metaKeywordsCity Prop
        @prop()
        @maxLength({ value: 2000 })
        @trim()
        metaKeywordsCity: string;
        //#endregion metaKeywordsCity Prop


        //#region metaTitleBoutique Prop
        @prop()
        @maxLength({ value: 2000 })
        @trim()
        metaTitleBoutique: string;
        //#endregion metaTitleBoutique Prop


        //#region metaDescriptionBoutique Prop
        @prop()
        @maxLength({ value: 4000 })
        @trim()
        metaDescriptionBoutique: string;
        //#endregion metaDescriptionBoutique Prop


        //#region metaKeywordsBoutique Prop
        @prop()
        @maxLength({ value: 2000 })
        @trim()
        metaKeywordsBoutique: string;
        //#endregion metaKeywordsBoutique Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region languageId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        languageId: number;
        //#endregion languageId Prop


        //#region parentBoutiqueId Prop
        @prop()
        parentBoutiqueId: number;
        //#endregion parentBoutiqueId Prop

        //#region pictureId1 Prop
        @prop({ defaultValue: 0 })
        pictureId1: number;
        //#endregion pictureId1 Prop

        //#region address1 Prop
        @required()
        @maxLength({ value: 100 })
        @notEmpty()
        @trim()
        address1: string;
        //#endregion address1 Prop


        //#region address2 Prop
        //@required()
        @prop({ defaultValue: '' })
        @maxLength({ value: 100 })
        //@notEmpty()
        @trim()
        address2: string;
        //#endregion address2 Prop

        //#region stateProvinceID Prop
        @prop({ defaultValue: 0 })
        stateProvinceID: number;
        //#endregion stateProvinceID Prop

        //#region stateProvinceName Prop
        @maxLength({ value: 100 })
        @trim()
        stateProvinceName: string;
        //#endregion stateProvinceName Prop

        //#region zipPostalCode Prop
        @required()
        @notEmpty()
        @maxLength({ value: 30 })
        @trim()
        zipPostalCode: string;
        //#endregion zipPostalCode Prop


       


}
