import {vPaymentMethodTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPaymentMethodTypeLookUp extends vPaymentMethodTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}