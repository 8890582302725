import {vMainCollectionRecordBase,} from '@app/database-models'
//Generated Imports
export class vMainCollectionRecord extends vMainCollectionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}