import { Component, OnInit, OnDestroy, Input, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ProjectsEventsMappingFilterViewModel } from 'src/app/view-model/project-events-mapping-filter-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { ProjectEventsListViewModel } from 'src/app/models/extended-models/project-events-list-view-model';
import { ProjectEventMapping, Project, vProjectTypeLookUp, vUserLookUp } from '@app/models';
import { AbstractSubProject } from '../domain/abstract-sub-project';
import { SubProjectsDesignFilterViewModel } from 'src/app/view-model/sub-project-design-filter-view-model';
import { SubProjectDesignListViewModel } from 'src/app/models/extended-models/sub-project-design-list-view-model';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { Router } from '@angular/router';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { CoreComponent, } from '@rxweb/angular-router';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { dateCompareValidation, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import * as CryptoJS from 'crypto-js';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { getBlobImgURL } from "src/app/domain/system-constant";

@multilingual("SubProjectAddComponent")
@Component({
    selector: "app-sub-project-add",
    templateUrl: './sub-project-add.component.html'
})
export class SubProjectAddComponent extends AbstractSubProject implements OnInit, OnDestroy {
    subProjectOrDesign: SubProjectsDesignFilterViewModel;
    subProjectDesignListViewModel: SubProjectDesignListViewModel[];
    subscription: any;
    cloneArray: SubProjectDesignListViewModel[];
    subProjectDesignListColumns: any[];
    subProjectInfo: Project;
    subProjectArray: Project[];
    excludesubProjectArray: Project[];
    productIds: number[];
    excludeProductIds: number[];
    project: Project;
    ProjectInfo: Project;
    showSubProjectForm: boolean = false;
    isFilter: boolean = false;
    totalRecords: number = 0;
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    @Input() projectId: number;
    @Input() hide: Function;
    headerBtnChk: boolean;
    projectIndicatorError: boolean;
    titleClose: string;
    ErrorMsg:string;
    imageTooltip: string;
    selectedProductIds:number[];
    filterJson:any;
    constructor(private formBuilder: RxFormBuilder, private router: Router, private sanitizer: DomSanitizer, modalView: ModalView, private el: ElementRef,private location : PlatformLocation,blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.modalView = modalView;
        this.subProjectArray = new Array<Project>();
        this.productIds = new Array<number>();
        this.cloneArray = new Array<SubProjectDesignListViewModel>();
        this.selectedProductIds = new Array<number>();
        this.excludesubProjectArray = new Array<Project>();
        this.excludeProductIds = new Array<number>();
        location.onPopState(() => {this.hide(),this.dialog.hide()});

    }

    projectLookup: any = {
        projectTypeLookUp: vProjectTypeLookUp,
        projectOwnerLookUp: vUserLookUp,
        project: Project
    }

    ngOnInit(): void {
        this.imageTooltip = getLocalizedValue("SubProjectAddEditComponent_ImageUploadTooltip_t");
        this.selectedTab = "sub-sroject-workstream-info";
        this.titleClose = getLocalizedValue("Title_Close");
        this.lookup([{ path: USER_LOOKUPS["userLookUp"], propName: "projectOwnerLookUp" }, { path: USER_LOOKUPS["projectTypeLookUp"], propName: "projectTypeLookUp" }]).subscribe(response => {
            this.projectLookup = response;
            this.project = new Project();
            this.project.parentID = this.projectId;
            this.project.projectOwner = 0;
            this.project.gemfieldsGroupCompanyProjectOwner = 0;
            this.project.department = 0;
            this.projectFormGroup = this.formBuilder.formGroup(this.project) as IFormGroup<Project>;
            this.subProjectOrDesign = new SubProjectsDesignFilterViewModel();
            this.subProjectOrDesign.pageIndex = PaginationEnum.PageIndex;
            this.subProjectOrDesign.rowCount = PaginationEnum.RowCount;
            this.subProjectDesignSearchFormGroup = this.formBuilder.formGroup(this.subProjectOrDesign) as IFormGroup<SubProjectsDesignFilterViewModel>;
            this.showSubProjectForm = true;
            this.showComponent = true;  
        });
    }

    bindGrid() {
        this.subProjectDesignSearchFormGroup.submitted = true;
        if (this.subProjectDesignSearchFormGroup.valid) {
            this.isShowGrid = true;
            this.spin = true;
            this.totalRecords = 0;
            var json = JSON.parse(JSON.stringify(this.subProjectDesignSearchFormGroup.value));
            json["projectId"] = this.projectId;
            this.filterJson = json;
            this.subscription = this.post({
                path: "api/SearchProject/DesignSearch",
                body: {
                    query: JSON.stringify(json),
                }
            }).subscribe((t: any) => {
                this.spin = false;
                if (this.subProjectDesignListGrid) {
                    this.subProjectDesignListGrid.storeProcedure.length = 0;
                }
                this.subProjectDesignListViewModel = t[0].UserData;
                this.ErrorMsg=t[0].ErrorMessage;
                this.subProjectDesignListViewModel.forEach(z => z.isChecked = this.headerBtnChk);
                if (this.subProjectDesignListViewModel.length > 0) {
                    this.totalRecords = this.subProjectDesignListViewModel[0].totalCount;
                    this.subProjectDesignListViewModel.forEach(x => {
                        if (this.cloneArray.findIndex(z => z.productId == x.productId) == -1) {
                            this.cloneArray.push(x);
                        }
                        else {
                            this.cloneArray.forEach(y => {
                                if (y.productId == x.productId) {
                                    x.isChecked = y.isChecked
                                }
                            })

                        }
                        if(this.headerBtnChk) {
                            if(this.excludeProductIds.indexOf(x.productId) != -1) {
                                x.isChecked = false;
                            }
                        }
                        else {
                            if(this.selectedProductIds.indexOf(x.productId) != -1) {
                                x.isChecked = true;
                            }
                        }

                    })
                    this.updateSubscriberArray();
                    this.isNoRecordFound = false;
                }
                else {
                    this.isShowGrid = false;
                    this.isNoRecordFound = true;
                }
                if (!this.isFilter) {
                    this.subProjectDesignListGrid = new AppGrid(this.subProjectDesignListViewModel, SubProjectDesignListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                    this.subProjectDesignListGrid.storeProcedure = {
                        length: this.totalRecords,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                    this.subProjectDesignListGrid.gridColumns.forEach(x => {
                        if (x.columnIndex == 0) {
                            x.visible = true;

                        }
                    });
                }
                else {
                    this.subProjectDesignListGrid.storeProcedure.length = this.totalRecords;
                    this.subProjectDesignListGrid.updateSource([]);
                    this.subProjectDesignListGrid.updateSource(this.subProjectDesignListViewModel)
                }
                this.subProjectDesignListGrid.maxPerPage = this.subProjectDesignSearchFormGroup.value.rowCount;
                this.subProjectDesignListGrid.componentId = this.componentName;
                this.showSaveButton = true;
            })
        }
    }

    activeTab(tabName: string) {
        if (tabName == 'faberge-design') {
            this.showSaveButton = false;
            if (this.subProjectDesignListGrid) {
                this.isFilter = true;
                this.subProjectArray = [];
                this.bindGrid();
            }
        }
        this.selectedTab = tabName;
    }

    searchDesign() {
        if (this.subProjectDesignListGrid) {
            this.isFilter = true;
            this.cloneArray = [];
            this.headerBtnChk = false;
            this.subProjectDesignListGrid.updateSource([]);
            this.subProjectDesignListGrid.storeProcedure.nextPage = 1;

        }
        if (dateCompareValidation(this.subProjectDesignSearchFormGroup.value.startDate, this.subProjectDesignSearchFormGroup.value.endDate) == true) {
            this.dateDesignError = true;
        }
        else {
            this.dateDesignError = false;
            this.subProjectDesignSearchFormGroup.value.pageIndex = 1
            this.bindGrid();
        }
    }

    updateSubscriberArray() {
        this.cloneArray.forEach(x => {
            let projectObj = new Project();
            projectObj.projectID = 0;
            projectObj.projectName = x.design;
            projectObj.projectDescription = '';
            projectObj.projectStartDate = new Date();
            projectObj.budget = 0;
            projectObj.createdOn = new Date;
            projectObj.parentID = this.projectId;
            projectObj.linkID = x.productId;
            projectObj.subStreamType = 'DES';
            projectObj.pictureID = 0;
            projectObj.actualCost = 0;
            projectObj.isRestrictedProject = false;
            projectObj.projectIndicator = 0;
            projectObj.deleted = false;
            projectObj.productId = x.productId.toString();
            projectObj.projectType = 0;
            if (x.isChecked) {
                if(this.headerBtnChk) {

                    if(this.excludeProductIds.indexOf(x.productId) != -1) {
                        this.excludeProductIds.splice(this.excludeProductIds.indexOf(x.productId),1);
                        this.excludesubProjectArray.splice(this.excludeProductIds.indexOf(x.productId),1);
                    }
                }
                else  {
                    if (this.productIds.indexOf(x.productId) == -1) {
                        this.subProjectArray.push(projectObj);
                        this.productIds.push(x.productId);
                    }
                }
            }
            else {
                if(this.headerBtnChk) {
                    if(this.excludeProductIds.indexOf(x.productId) == -1) {
                        this.excludeProductIds.push(x.productId);
                        this.excludesubProjectArray.push(projectObj);
                    }
                }
                else {
                    if (this.productIds.indexOf(x.productId) != -1) {
                        this.subProjectArray.splice(this.productIds.indexOf(x.productId), 1);
                        this.productIds.splice(this.productIds.indexOf(x.productId), 1);
                    }
                }
            }
        })
    }

    onCheckboxSelect(t, x) {
        let projectObj = new Project();
            projectObj.projectID = 0;
            projectObj.projectName = t.design;
            projectObj.projectDescription = '';
            projectObj.projectStartDate = new Date();
            projectObj.budget = 0;
            projectObj.createdOn = new Date;
            projectObj.parentID = this.projectId;
            projectObj.linkID = t.productId;
            projectObj.subStreamType = 'DES';
            projectObj.pictureID = 0;
            projectObj.actualCost = 0;
            projectObj.isRestrictedProject = false;
            projectObj.projectIndicator = 0;
            projectObj.deleted = false;
            projectObj.productId = t.productId.toString();
            projectObj.projectType = 0;
        if (x.target.checked) {
            if(this.headerBtnChk) {

                if(this.excludeProductIds.indexOf(t.productId) != -1) {
                    this.excludeProductIds.splice(this.excludeProductIds.indexOf(t.productId),1);
                    this.excludesubProjectArray.splice(this.excludeProductIds.indexOf(t.productId),1);
                }
            }
            else {
                if (this.productIds.indexOf(t.productId) == -1) {
                    this.subProjectArray.push(projectObj);
                    this.productIds.push(t.productId);
                }
                if(this.selectedProductIds.indexOf(t.productId) == -1) {
                    this.selectedProductIds.push(t.productId);
                }
            }
        }
        else {
            if(this.headerBtnChk) {
                if(this.excludeProductIds.indexOf(t.productId) == -1) {
                    this.excludeProductIds.push(t.productId);
                    this.excludesubProjectArray.push(projectObj);
                }


            } else {
                if (this.productIds.indexOf(t.productId) != -1) {
                    this.subProjectArray.splice(this.productIds.indexOf(t.productId), 1);
                    this.productIds.splice(this.productIds.indexOf(t.productId), 1);
                }

                if(this.selectedProductIds.indexOf(t.productId) != -1) {
                    this.selectedProductIds.splice(this.selectedProductIds.indexOf(t.productId), 1);
                }
            }
        }
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<SubProjectDesignListViewModel>();
        this.subProjectArray = [];
        this.productIds = [];
        this.excludeProductIds = [];
        this.excludesubProjectArray = [];
        this.subProjectDesignListViewModel.forEach(data => {
            let newObj = new SubProjectDesignListViewModel();
            newObj.productId = data.productId;
            newObj.design = data.design;
            newObj.startDate = data.startDate;
            newObj.endDate = data.endDate;
            newObj.isChecked = this.headerBtnChk;
            newObj.totalCount = data.totalCount;
            this.cloneArray.push(newObj);
        });
        this.updateSubscriberArray();
        this.subProjectDesignListGrid.updateSource([]);
        this.subProjectDesignListGrid.updateSource(this.cloneArray);
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.cloneArray = [];
        this.subProjectDesignSearchFormGroup.patchValue({ sortOrder: y, orderBy: x })
        this.subProjectDesignListGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.subProjectDesignSearchFormGroup.value.rowCount;
        let toIndex: number = this.subProjectDesignListGrid.maxPerPage;
        if (this.subProjectDesignSearchFormGroup.value.pageIndex < x) {
            this.cloneArray = [];
            this.subProjectArray = [];
            this.productIds = [];
        }
        if (this.subProjectDesignListGrid.maxPerPage < this.subProjectDesignSearchFormGroup.value.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }

        }
        this.subProjectDesignSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.subProjectDesignListGrid.maxPerPage })
        this.subProjectDesignListGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludesubProjectArray.length)  + " Records";
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveDesign();
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveDesign();
        }
     }

    saveDesign() {
        this.subProjectInfo = new Project();
        this.subProjectInfo.projectName = 'val';
        this.subProjectInfo.projectDescription = 'val';
        this.subProjectInfo.subStreamType = 'val';
        if(this.headerBtnChk) {
            this.subProjectInfo.projectID = this.projectId;
            this.subProjectInfo.projects = this.excludesubProjectArray;
            this.subProjectInfo.headerBtnChk = this.headerBtnChk;
            this.filterJson["rowCount"] = this.totalRecords;
            this.filterJson["sortOrder"] = "false";
            this.filterJson["pageIndex"] = 1;
            this.subProjectInfo.query = JSON.stringify(this.filterJson);
        }
        else
            this.subProjectInfo.projects = this.subProjectArray;
        this.spin = true;
        this.post({ path: "api/Projects", body: this.subProjectInfo }).subscribe(data => {
            this.spin = false;
            var existsAlert = getLocalizedValue("Project_Added");
            if (existsAlert) {
                //this.toastr.success("Project Added Successfully");
                this.toastr.success(existsAlert);
            }
            this.subProjectArray = [];
            this.hide();
        });
    }

    chekcValidation() {
        if (this.projectFormGroup.value.projectIndicator < 0 || this.projectFormGroup.value.projectIndicator > 100) {
            this.projectIndicatorError = true;
        } else {
            this.projectIndicatorError = false;
        }
    }

    saveProject() {
        this.projectFormGroup.submitted = true;
        this.startDate = new Date();
        this.startDate = this.projectFormGroup.controls.projectStartDate.value;
        this.endDate = new Date();
        this.endDate = this.projectFormGroup.controls.projectEndDate.value;
        if (this.projectFormGroup.value.projectIndicator == null || this.projectFormGroup.value.projectIndicator == undefined || this.projectFormGroup.value.projectIndicator.toString() == "") {
            this.projectFormGroup.value.projectIndicator = 0;
        }
        if (this.projectFormGroup.value.actualCost == null || this.projectFormGroup.value.actualCost == undefined || this.projectFormGroup.value.actualCost.toString() == "") {
            this.projectFormGroup.value.actualCost = 0;
        }
        if (this.endDate != null && this.startDate > this.endDate) {
            this.dateError = true;
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.projectFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        } else {
            if (this.projectFormGroup.valid && !this.projectIndicatorError) {
                this.dateError = false;
                this.spin = true;
                this.projectIndicatorError = false;
                if (this.projectFormGroup.value.projectIndicator == 0) {
                    this.projectFormGroup.patchValue({ projectIndicator: 0 });
                }
                if (this.projectFormGroup.value.actualCost == 0) {
                    this.projectFormGroup.patchValue({ actualCost: 0 });
                }
                this.projectFormGroup.value.projects = new Array<Project>();
                this.post({ path: "api/Projects", body: this.projectFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Added");
                    if (existsAlert) {
                        //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            } else {
                this.breakLoop = false;
                this.validField.forEach(x => {
                    if (this.projectFormGroup.controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                });
            }
        }
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    getImgContent(): SafeUrl {
        let imgUrl = getBlobImgURL(this.projectFormGroup.value.blobFileName);
        return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
    }
    get componentName(): string {
        return "SubProjectAddComponent";
    }
}
