import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Region, vCustomerRoleDomainLookUp } from '@app/models';
import { AbstractRegion } from '../domain/abstract-region';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { LanguagesEnums } from '../../../../custom-enum/language-enums';
import { CoreComponent, access } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { RegionEditComponent } from '../edit/region-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("RegionAddComponent")
@access({ accessLevel: RolePermissionEnums.Regions, action: "post" })

@Component({
  selector: "app-region-add",
  templateUrl: './region-add.component.html'
})
export class RegionAddComponent extends AbstractRegion implements OnInit, OnDestroy {
  region: Region;
  subscription: any;
  regionEdit: any = RegionEditComponent;
  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
  regionsLookup: any = {
    customerRoleDomainLookUp: vCustomerRoleDomainLookUp
  }
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;

  constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el:ElementRef,private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.Regions;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Regions - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    this.lookup([{ propName: "customerRoleDomainLookUp", path: USER_LOOKUPS.customerRoleDomainLookUp }]).subscribe((lookup: any) => {
      this.regionsLookup = lookup;
      this.region = new Region();
      this.region.regionId = 0;
      this.region.statusId = StatusEnum.Active;
      this.region.published = false;
      this.regionFormGroup = this.formBuilder.formGroup(Region, this.region) as IFormGroup<Region>;
      this.showComponent = true;

    })
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addRegion(isRedirect: boolean) {
    this.regionFormGroup.submitted = true;
    if (this.regionFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.regionFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
           this.toastr.success(existsAlert[0].showMessage);
                    }
        isRedirect ? this.router.navigate(['/regions', data]) : this.router.navigate(['/regions']);
      })
    } else {
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.regionFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
  }

  get componentName(): string {
    return "RegionAddComponent";
  }
}
