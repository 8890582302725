<ng-container *rxMultilingual="">
<div class="modal-dialog modal-lg" *ngIf="showComponent==true" [rxLocalisationInit]="componentName">
  <div class="modal-content" >
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="ClientRepairEditComponent_Title_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-0"  [rxSpinner]="spin"
      [formGroup]="clientRepairFormGroup">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_StockRepairNo_t"></label>
            <div class="col-md-8">
              <label class="col-form-label"
                >{{clientRepairFormGroup.value.clientRepairId}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_DateofRequest_t"></label>
            <div class="col-md-8">
              <label class="col-form-label"
                >{{clientRepairFormGroup.value.dateOfRequest | date:"dd/MMM/yyyy"}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_SalesPerson_t"></label>
            <div class="col-md-8">
              <label class="col-form-label" >{{salesPerson}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_Approval_t"></label>
            <div class="col-md-8">
              <label class="col-form-label" >{{approveStatus}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_ClientName_t"></label>
            <div class="col-md-8">
              <label class="col-form-label" >{{clientName}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_PieceNo_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <rx-tag [(source)]="pieceLookup" #rxTag formControlName="pieceIds" mainClass="form-control"
                [keyValueProps]="['pieceName','pieceId']" [tagDisabled]="disabelPieces"
                placeholder="Enter Piece Number"></rx-tag>
              <!-- <small class="form-text text-danger" *ngIf="pieceIdError">You can't leave this field empty</small> -->

            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"> </label>
            <div class="col-md-8">
              <small class="mr-2">OR</small>
              <small class="text-danger" *ngIf="pieceIdError || oldPieceError">Piece Number and Old Faberge Pieces can't
                both be empty</small>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_OldFabergePieces_t"></label>
            <div class="col-md-8">
              <textarea formControlName="oldFabergePieces" class="form-control"
                rxPlaceholder="ClientRepairAddEditComponent_OldFabergePieces_p"></textarea>
              <!-- <small class="form-text text-danger" *ngIf="oldPieceError">You can't leave this field empty</small> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_EstimationRequired_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select (change)="updateEstimation($event)" class="form-control">
                  <option value="1" [selected]="clientRepairFormGroup.value.estimationRequired">Yes</option>
                  <option value="2" [selected]="!clientRepairFormGroup.value.estimationRequired">No</option>
                  </select> -->
              <rx-select [(source)]="estimationLookup" #rxSelect (changed)="updateEstimation()" formControlName="estimationId"
                mainClass="form-control" [selectPlaceholder]="'Select Estimation Required'"
                [keyValueProps]="['estimation','estimationId']"></rx-select>
              <small class="form-text text-danger"
                [class.d-block]="clientRepairFormGroup.controls.estimationRequired.errors">{{clientRepairFormGroup.controls.estimationRequired.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_DeliveryDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="deliveryDate" placeholder="Select Date"
                pickerClass="form-control" ></rx-date>
              <small class="form-text text-danger"
                [class.d-block]="clientRepairFormGroup.controls.deliveryDate.errors">{{clientRepairFormGroup.controls.deliveryDate.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_PieceCondition_t"></label>
            <div class="col-md-8">
              <textarea formControlName="pieceCondition" class="form-control"
                rxPlaceholder="ClientRepairAddEditComponent_PieceCondition_p"></textarea>
              <small class="form-text text-danger"
                [class.d-block]="clientRepairFormGroup.controls.pieceCondition.errors">{{clientRepairFormGroup.controls.pieceCondition.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_RepairNeeded_t"></label>
            <div class="col-md-8">
              <textarea formControlName="repairNeeded" class="form-control"
                rxPlaceholder="ClientRepairAddEditComponent_RepairNeeded_p"></textarea>
              <small class="form-text text-danger"
                [class.d-block]="clientRepairFormGroup.controls.repairNeeded.errors">{{clientRepairFormGroup.controls.repairNeeded.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_AdditionalDetails_t"></label>
            <div class="col-md-8">
              <textarea formControlName="additionalRepairDetails" class="form-control"
                rxPlaceholder="ClientRepairAddEditComponent_Details_p"></textarea>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label class="col-md-6 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_UploadTool_t"></label>
            <div class="col-md-6" *ngIf="this.formArray.length<3" [rxLocalisationInit]="componentName">
              <button type="button" class="btn btn-success" (click)="updateFileCount()" rxText="Btn_Add_New_t"></button>
            </div>
          </div>
          <div class="form-group row" *ngFor="let i of formArray" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ClientRepairAddEditComponent_DocumentToupload_t"></label>

            <div class="col-md-7">
              <div class="custom-file">
                <app-file *ngIf="i==1" [fileViewModel]="fileInputViewModel1" (files)="uploadFiles($event,i)"></app-file>
                <ng-container *ngIf="i==1" [rxLocalisationInit]="componentName">
                  <button class="btn btn-danger col-md-3 mt-2" (click)="removeFile(i)" rxText="Btn_Remove_t"></button>
                </ng-container>

                <br />

                <small *ngIf="i==1 && clientRepairFormGroup.value.uploadFile1 && !showFile1"><a href="{{fileURL1}}"
                    target="_blank">{{clientRepairFormGroup.value.uploadFile1}}</a></small>

                <small
                  *ngIf="i==1 && clientRepairFormGroup.value.fileData && !showImageURL1">{{clientRepairFormGroup.value.fileName}}</small>

                <img *ngIf="i==1 && clientRepairFormGroup.value.fileData == null && showimage1 && showFile1"
                  src="{{fileURL1}}" class="event-img  mt-2 upload-img-preview imageMargin" />

                <img *ngIf="i==1 && clientRepairFormGroup.value.fileData != null && showImageURL1"
                  [src]="getImgContent(i)" class="event-img mt-2 upload-img-preview imageMargin" />

                <app-file *ngIf="i==2" [fileViewModel]="fileInputViewModel2" (files)="uploadFiles($event,i)"></app-file>
                <ng-container *ngIf="i==2" [rxLocalisationInit]="componentName">
                  <button class="btn btn-danger col-md-3 mt-2" (click)="removeFile(i)" rxText="Btn_Remove_t"></button>
                </ng-container>

                <br />
                <label *ngIf="i==2 && clientRepairFormGroup.value.uploadFile2 && !showFile2 "><a href="{{fileURL2}}"
                    target="_blank">{{clientRepairFormGroup.value.uploadFile2}}</a></label>
                <label
                  *ngIf="i==2 && clientRepairFormGroup.value.fileData1 && !showImageURL2">{{clientRepairFormGroup.value.fileName1}}</label>

                <img *ngIf="i==2 && clientRepairFormGroup.value.fileData1 == null && showimage2 && showFile2"
                  src="{{fileURL2}}" class="event-img  mt-2 upload-img-preview imageMargin" />

                <img *ngIf="i==2 && clientRepairFormGroup.value.fileData1 != null && showImageURL2"
                  [src]="getImgContent(i)" class="event-img mt-2 upload-img-preview imageMargin" />

                <app-file *ngIf="i==3" [fileViewModel]="fileInputViewModel3" (files)="uploadFiles($event,i)"></app-file>
                <ng-container *ngIf="i==3" [rxLocalisationInit]="componentName">
                  <button class="btn btn-danger col-md-3 mt-2" (click)="removeFile(i)" rxText="Btn_Remove_t"></button>
                </ng-container>

                <br />
                <label *ngIf="i==3 && clientRepairFormGroup.value.uploadFile3 && !showFile3"><a href="{{fileURL3}}"
                    target="_blank">{{clientRepairFormGroup.value.uploadFile3}}</a></label>
                <label
                  *ngIf="i==3 && clientRepairFormGroup.value.fileData2 && !showImageURL3 ">{{clientRepairFormGroup.value.fileName2}}</label>

                <img *ngIf="i==3 && clientRepairFormGroup.value.fileData2 == null && showimage3 && showFile3"
                  src="{{fileURL3}}" class="event-img  mt-2 upload-img-preview imageMargin" />

                <img *ngIf="i==3 && clientRepairFormGroup.value.fileData2 != null && showImageURL3"
                  [src]="getImgContent(i)" class="event-img mt-2 upload-img-preview imageMargin" />

                <!-- <label class="mt-1" *ngIf="documentsFormGroup.value.file">{{documentsFormGroup.value.file.name}}</label> -->
                <!-- <small class="form-text text-danger" [class.d-block]="documentsFormGroup.controls.file.errors">{{documentsFormGroup.controls.file.errorMessage}}</small> -->
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
    <div class="modal-footer" [rxLocalisationInit]="componentName">
      <button type="button" [disabled]="true" class="btn btn-success" (click)="editClientRepair(null)" rxText="Btn_Save_t"></button>
      <ng-container >
        <button type="button" [disabled]="true" class="btn btn-success" *ngIf="showClientRepairApproval"
        (click)="editClientRepair(1)" rxText="Btn_Approve_t"></button>
      </ng-container>
      <ng-container >
        <button type="button" [disabled]="true" class="btn btn-danger" *ngIf="showClientRepairApproval"
          (click)="editClientRepair(2)" rxText="Btn_Decline_t"></button>
      </ng-container>
      <button (click)="hide()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
    </div>
  </div>
</div>
</ng-container>

<style>
  .imageMargin {
    margin-bottom: 50px;
  }
</style>
