import { actionColumn, gridColumn } from "@rxweb/grid"
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';

@actionColumn({
  allowSorting: false,
  style: { width: "1%", "text-align": "left" },
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})

export class vBookingPiecesBase {

  @gridColumn({ style: { width: "0.01%", "text-align": "initial" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
  isChecked: boolean

  //#region tripProductId Prop
  @gridColumn({ style: { width: "1%", "text-align": "initial" }, visible: false, columnIndex: 1, allowSorting: true, headerKey: 'tripProductId', keyColumn: false })
  tripProductId: number;
  //#endregion tripProductId Prop

  //#region imageUrl Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "imageUrl", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 2, allowSorting: false, headerKey: 'PieceListComponent_Image_gh', keyColumn: false })
  imageUrl: string;
  //#endregion imageUrl Prop

  //#region productVariantInfo Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'PieceListComponent_ProductVariantInfo_gh', keyColumn: false })
  productVariantInfo: string;
  //#endregion productVariantInfo Prop

  //#region serial Prop
  //@gridColumn({ parameter:false,style: { width: "1%", "text-align": "initial" }, class: ["text-left"], configuredTemplate: { templateName: "pieceRedirectLink" }, visible: true, columnIndex: 4, allowSorting: true, headerKey: 'PieceListComponent_Serial_gh', keyColumn: false })
  serial: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.pieceNumber;
          }
        },
      }
    }, parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'PieceListComponent_Serial_gh', keyColumn: false
  })
  pieceNumber: string;

  @gridColumn({
    parameter: false, configuredTemplate: { templateName: 'barcodeImageView' }, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "barcodeUrl", visible: true, columnIndex: 5, allowSorting: false, headerKey: 'PieceListComponent_Barcode_gh', keyColumn: false
  })
  barcodeUrl: any;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'PieceListComponent_GTINNumber_gh', keyColumn: false })
  gtinNumber: number;

  @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'PieceListComponent_Serial_gh', keyColumn: false })
  pieceNumberForExcel: string;

  //#endregion serial Prop

  //#region currencyId Prop
  @gridColumn({ visible: false, columnIndex: 5, allowSorting: true, headerKey: 'currencyId', keyColumn: false })
  currencyId: number;
  //#endregion currencyId Prop

  //#region orderId Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'PieceListComponent_OrderId_gh', keyColumn: false, configuredTemplate: { templateName: "orderRedirectLink" } })
  orderId: number;
  //#endregion orderId Prop

  //#region categoryName Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'PieceListComponent_CategoryName_gh', keyColumn: false })
  categoryName: string;
  //#endregion categoryName Prop

  //#region internalDescription Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'PieceListComponent_InternalDescription_gh', keyColumn: false })
  internalDescription: string;
  //#endregion internalDescription Prop

  //#region gramWeight Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'PieceListComponent_GramWeight_gh', keyColumn: false })
  gramWeight: number;
  //#endregion gramWeight Prop

  //#region goldGramWeight Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'PieceListComponent_GoldGramWeight_gh', keyColumn: false })
  goldGramWeight: number;
  //#endregion goldGramWeight Prop

  //#region supplierId Prop
  @gridColumn({ visible: false, columnIndex: 12, allowSorting: true, headerKey: 'supplierId', keyColumn: false })
  supplierId: number;
  //#endregion supplierId Prop

  //#region supplier Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'PieceListComponent_Supplier_gh', keyColumn: false })
  supplier: string;
  //#endregion supplier Prop

  //#region collection Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 13, allowSorting: true, headerKey: 'PieceListComponent_Collection_gh', keyColumn: false })
  collection: string;
  //#endregion collection Prop

  //#region clientFacingDescription Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "300px", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 14, allowSorting: true, headerKey: 'PieceListComponent_ClientFacingDescription_gh', keyColumn: false })
  clientFacingDescription: string;
  //#endregion clientFacingDescription Prop

  //#region tripProductStatus Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 15, allowSorting: true, headerKey: 'PieceListComponent_TripProductStatus_gh', keyColumn: false })
  tripProductStatus: string;
  //#endregion tripProductStatus Prop

  //#region dutyStId Prop
  @gridColumn({ visible: false, columnIndex: 16, allowSorting: true, headerKey: 'dutyStId', keyColumn: false })
  dutyStId: number;
  //#endregion dutyStId Prop

  //#region dutyStatus Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 17, allowSorting: true, headerKey: 'PieceListComponent_DutyStatus_gh', keyColumn: false })
  dutyStatus: string;
  //#endregion dutyStatus Prop

  //#region tripLocationName Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 18, allowSorting: true, headerKey: 'PieceListComponent_TripLocationName_gh', keyColumn: false })
  tripLocationName: string;
  //#endregion tripLocationName Prop

  //#region legalOwnerName Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 19, allowSorting: true, headerKey: 'PieceListComponent_LegalOwnerName_gh', keyColumn: false })
  legalOwnerName: number;
  //#endregion legalOwnerName Prop

  //#region pieceStatus Prop
  pieceStatus: string;
  //#endregion pieceStatus Prop

  //#region exportPrice Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 24, allowSorting: true, headerKey: 'PieceListComponent_ExportPrice_gh', keyColumn: false })
  exportPrice: number;
  //#endregion exportPrice Prop

  //#region exportValueEuro Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 26, allowSorting: true, headerKey: 'PieceListComponent_ExportValueEuro_gh', keyColumn: false })
  exportValueEuro: string;
  //#endregion exportValueEuro Prop

  //#region supplierDeliveryDate Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 27, allowSorting: true, headerKey: 'PieceListComponent_SupplierDeliveryDate_gh', keyColumn: false })
  supplierDeliveryDate: string;
  //#endregion supplierDeliveryDate Prop

  //#region insurrenceValue Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 25, allowSorting: true, headerKey: 'PieceListComponent_InsurrenceValue_gh', keyColumn: false })
  insurrenceValue: number;
  //#endregion insurrenceValue Prop

  //#region status Prop
  @gridColumn({ visible: false, columnIndex: 49, allowSorting: true, headerKey: 'status', keyColumn: false })
  status: number;
  //#endregion status Prop

  //#region tripATACarnetNo Prop
  @gridColumn({ visible: false, columnIndex: 28, allowSorting: true, headerKey: 'tripATACarnetNo', keyColumn: false })
  tripATACarnetNo: number;
  //#endregion tripATACarnetNo Prop

  //#region location Prop
  @gridColumn({ visible: false, columnIndex: 29, allowSorting: true, headerKey: 'location', keyColumn: false })
  location: string;
  //#endregion location Prop

  //#region priceBasis Prop
  @gridColumn({ visible: false, columnIndex: 30, allowSorting: true, headerKey: 'priceBasis', keyColumn: false })
  priceBasis: number;
  //#endregion priceBasis Prop

  //#region productVariantPrice Prop
  @gridColumn({ visible: false, columnIndex: 31, allowSorting: true, headerKey: 'productVariantPrice', keyColumn: false })
  productVariantPrice: number;
  //#endregion productVariantPrice Prop

  //#region responsibleBy Prop
  @gridColumn({ visible: false, columnIndex: 32, allowSorting: true, headerKey: 'responsibleBy', keyColumn: false })
  responsibleBy: number;
  //#endregion responsibleBy Prop

  //#region categoryId Prop
  @gridColumn({ visible: false, columnIndex: 33, allowSorting: true, headerKey: 'categoryId', keyColumn: false })
  categoryId: number;
  //#endregion categoryId Prop

  //#region dutyStatusId Prop
  @gridColumn({ visible: false, columnIndex: 34, allowSorting: true, headerKey: 'dutyStatusID', keyColumn: false })
  dutyStatusId: number;
  //#endregion dutyStatusId Prop

  //#region dutyStatusDate Prop
  @gridColumn({ visible: false, columnIndex: 35, allowSorting: true, headerKey: 'dutyStatusDate', keyColumn: false })
  dutyStatusDate: string;
  //#endregion dutyStatusDate Prop

  //#region pieceRingSizeId Prop
  @gridColumn({ visible: false, columnIndex: 36, allowSorting: true, headerKey: 'pieceRingSizeID', keyColumn: false })
  pieceRingSizeId: number;
  //#endregion pieceRingSizeId Prop

  //#region pieceBangleSizeId Prop
  @gridColumn({ visible: false, columnIndex: 37, allowSorting: true, headerKey: 'pieceBangleSizeId', keyColumn: false })
  pieceBangleSizeId: number;
  //#endregion pieceBangleSizeId Prop

  //#region productId Prop
  @gridColumn({ visible: false, columnIndex: 38, allowSorting: true, headerKey: 'productId', keyColumn: false })
  productId: number;
  //#endregion productId Prop

  //#region ringSize Prop
  @gridColumn({ visible: false, columnIndex: 39, allowSorting: true, headerKey: 'ringSize', keyColumn: false })
  ringSize: number;
  //#endregion ringSize Prop

  //#region productVariantId Prop
  @gridColumn({ visible: false, columnIndex: 40, allowSorting: true, headerKey: 'productVariantId', keyColumn: false })
  productVariantId: number;
  //#endregion productVariantId Prop

  //#region productVariantBatchPieceId Prop
  @gridColumn({ visible: false, columnIndex: 41, allowSorting: true, headerKey: 'productVariantBatchPieceId', keyColumn: false })
  productVariantBatchPieceId: number;
  //#endregion productVariantBatchPieceId Prop

  //#region tripLocationId Prop
  @gridColumn({ visible: false, columnIndex: 42, allowSorting: true, headerKey: 'tripLocationId', keyColumn: false })
  tripLocationId: number;
  //#endregion tripLocationId Prop

  //#region pieceStatusId Prop
  @gridColumn({ visible: false, columnIndex: 43, allowSorting: true, headerKey: 'pieceStatusId', keyColumn: false })
  pieceStatusId: number;
  //#endregion pieceStatusId Prop

  //#region pieceOwnerId Prop
  @gridColumn({ visible: false, columnIndex: 44, allowSorting: true, headerKey: 'pieceOwnerID', keyColumn: false })
  pieceOwnerId: number;
  //#endregion pieceOwnerId Prop

  //#region totalInsuranceValueUSD Prop
  @gridColumn({ visible: false, columnIndex: 45, allowSorting: true, headerKey: 'totalInsuranceValueUSD', keyColumn: false })
  totalInsuranceValueUSD: string;
  //#endregion totalInsuranceValueUSD Prop

  //#region totalGramWeight Prop
  @gridColumn({ visible: false, columnIndex: 46, allowSorting: true, headerKey: 'totalGramWeight', keyColumn: false })
  totalGramWeight: string;
  //#endregion totalGramWeight Prop

  //#region cost Prop
  @gridColumn({ visible: false, columnIndex: 47, allowSorting: true, headerKey: 'cost', keyColumn: false })
  cost: number;
  //#endregion cost Prop

  //#region cost Prop
  totalGemstone: number
  //#region cost Prop

  //#region bangleSize Prop
  @gridColumn({ visible: false, columnIndex: 48, allowSorting: true, headerKey: 'bangleSize', keyColumn: false })
  bangleSize: number;
  //#endregion bangleSize Prop

  //#region bookingType Prop
  //@gridColumn({ visible: true, columnIndex: 45, allowSorting: true, headerKey: 'bookingType', keyColumn: false })
  bookingType: number;
  //#endregion bookingType Prop

  //#region file Prop
  @gridColumn({ visible: false, columnIndex: 50, allowSorting: true, headerKey: 'file', keyColumn: false })
  file: FileDetailViewModel;
  //#endregion file Prop

  //#region totalExportRetailValueUSD Prop
  @gridColumn({ visible: false, columnIndex: 51, allowSorting: true, headerKey: 'totalExportRetailValueUSD', keyColumn: false })
  totalExportRetailValueUSD: string;
  //#endregion totalExportRetailValueUSD Prop

  //#region pieceDeliveryDate Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 52, allowSorting: true, headerKey: 'PieceListComponent_ShippingStatus_gh', keyColumn: false })
  pieceShippingStatus: string;
  //#endregion pieceDeliveryDate Prop

  //#region pieceShippedDate Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 53, allowSorting: true, headerKey: 'PieceListComponent_PieceShippedDate_gh', keyColumn: false })
  shippedDate: string;
  //#endregion pieceShippedDate Prop

  //#region pieceDeliveryDate Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 54, allowSorting: true, headerKey: 'PieceListComponent_PieceDeliveryDate_gh', keyColumn: false })
  actualDeliveryDate: string;
  //#endregion pieceDeliveryDate Prop

  //#region pieceShippedDate Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "100px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 55, allowSorting: true, headerKey: 'PieceListComponent_CountryName_gh', keyColumn: false })
  countryName: string;
  //#endregion pieceShippedDate Prop

  
  sizes: any;
  
  //#region soldPrice Prop
  @gridColumn({ parameter: false, style: { width: "1%", "text-align": "initial" }, name:"soldPrice",class: ["text-left"], visible: true, columnIndex: 57, allowSorting: true, headerKey: 'PieceListComponent_SoldPrice_gh', keyColumn: false })
  soldPrice: boolean;
  //#endregion soldPrice Prop
  

  //#region oldInventoryNumber Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "100px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 58, allowSorting: true, headerKey: 'PieceListComponent_OldInventoryNumber_gh', keyColumn: false })
  oldInventoryNumber: string;
  
  //#region productionStatus Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 20, allowSorting: true, headerKey: 'PieceListComponent_ProductionStatus_gh', keyColumn: false })
  productionStatus: string;
  //#endregion productionStatus Prop
  
  //#region salesStatus Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 21, allowSorting: true, headerKey: 'PieceListComponent_SalesStatus_gh', keyColumn: false })
  salesStatus: string;
  //#endregion salesStatus Prop
  
  //#region consignmentStatus Prop
  @gridColumn({ parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 22, allowSorting: true, headerKey: 'PieceListComponent_ConsignmentStatus_gh', keyColumn: false })
  consignmentStatus: string;
  //#endregion consignmentStatus Prop
  
  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.otherStatus;
          }
        },
      }
    }, parameter: false, style: { width: "1%", "min-width": "150px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 23, allowSorting: true, headerKey: 'PieceListComponent_OtherStatus_gh', keyColumn: false
  })
  otherStatus: string;
  
  //#endregion oldInventoryNumber Prop
  isPaidAndDelivered: boolean;
  barcodeFile: FileDetailViewModel;
  isAllRRP: boolean;
  isRing: boolean;
  isBangle: boolean;

}
