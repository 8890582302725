import {BookingBase,CountryBase,EventBookingBase,BookingCustomerMappingBase,BookingGemstoneBase,BookingProductBase,} from '@app/database-models'
//Generated Imports
export class Booking extends BookingBase 
{




//#region Generated Reference Properties
//#region country Prop
country : CountryBase;
//#endregion country Prop
//#region eventBookings Prop
eventBookings : EventBookingBase[];
//#endregion eventBookings Prop
//#region bookingCustomerMappings Prop
bookingCustomerMappings : BookingCustomerMappingBase[];
//#endregion bookingCustomerMappings Prop
//#region bookingGemstones Prop
bookingGemstones : BookingGemstoneBase[];
//#endregion bookingGemstones Prop
//#region bookingProducts Prop
bookingProducts : BookingProductBase[];
//#endregion bookingProducts Prop

headerBtnCheck:boolean;

query:string;

//#endregion Generated Reference Properties



}