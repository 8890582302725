import {vWatchStatusBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    visible:false,
    name:"action",
     allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 3, headerTitle: "Action"
})

export class vWatchStatus extends vWatchStatusBase 
{

    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 4, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 5, headerTitle: "Delete"
    })
    deleteAction:any;

   
   

//#region Generated Reference Properties
@prop()
isGemfield:string;
//#endregion Generated Reference Properties










}