import { prop, required, propObject, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MindMapPressMediaFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "projectName";
    }

    @prop()
    hashtags: string;

    @prop()
    journalistIds: string;

    @prop()
    userIds: number;

    @prop()
    publicationIds: string;

    @prop()
    companyIds: string;

    @prop()
    publishedEndDate: Date;

    @prop()
    publishedStartDate: Date;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}