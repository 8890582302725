import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldsArrangementBase {

//#region arrangementId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldsArrangementListComponent_ArrangementId_gh', keyColumn: true })
        arrangementId : number;
//#endregion arrangementId Prop


//#region arrangement Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsArrangementListComponent_Arrangement_gh', keyColumn: false })
        arrangement : string;
//#endregion arrangement Prop

}