import {vGemfieldsLocationLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemfieldsLocationLookUp extends vGemfieldsLocationLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}