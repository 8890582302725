import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemstoneArrangementsGemfieldsLookUpBase {

//#region arrangementId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'arrangementId', keyColumn: true})
        arrangementId : number;
//#endregion arrangementId Prop


//#region arrangement Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'arrangement', keyColumn: false})
        arrangement : string;
//#endregion arrangement Prop

}