import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vInvoiceStatusLookUpBase {

//#region invoiceStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'invoiceStatusId', keyColumn: true})
        invoiceStatusId : number;
//#endregion invoiceStatusId Prop


//#region invoiceStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'invoiceStatusName', keyColumn: false})
        invoiceStatusName : string;
//#endregion invoiceStatusName Prop

}