<!-- <div class="modal-dialog modal-xl" style="line-height:0.1"  *ngIf="showCard">
                    <div class="modal-content" style="height:1050px;overflow: scroll;">
                            <div class="modal-header text-left">
                                    <h4 class="modal-title mb-0">Stock Card</h4>
                                    <button type="button" class="close py-2" (click)="hide()">
                                        <span aria-hidden="true">×</span>
                                        <span class="sr-only">Close</span>
                                    </button>
                                </div> -->
<div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4">
        <div id="stock-card" style="margin-top: 5%;">
            <div class="master-wrapper-page">
                <div class="master-wrapper-content">
                    <div class="content">
                        <div class="cph">
                            <table width="100%" height="100%" align="center" cellpadding="20" cellspacing="0"
                                class="stock_table" bgcolor="#FFFFFF">
                                <tbody>
                                    <tr>
                                        <td align="center" valign="top" bgcolor="#FFFFFF">
                                            <table border="0" cellpadding="0" cellspacing="0" style="border: none;">
                                                <tbody>
                                                    <tr>
                                                        <td align="center" valign="top" width="100%" height="100%">
                                                            <div class="scale_stock_logo"><img src=""
                                                                    id="fabLogo" class="stock_logo"></div><br><br>

                                                            <div class="scale_stock_pic" style="width:55%"><img
                                                                    id="imgProduct" class="stock_pic"
                                                                    [src]="cardJson.imageUrl"
                                                                    (error)="onImageError($event)"
                                                                    style="vertical-align: middle;width: 100%;max-width: 800px;">
                                                            </div>

                                                            <div class="titles_stock" id="cardProductName"
                                                                style="font-size: 14pt;"></div>

                                                            <div class="stock_title_sub" id="serialNo"></div>
                                                            <br>
                                                            <table cellspacing="0" border="0" class="price_box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="center" valign="top"
                                                                            class="stock_Price">
                                                                            <table id="stockCardPriceData"
                                                                                cellspacing="0" border="0"
                                                                                style="border-collapse:collapse;">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div align="center">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="stock_Price">
                                                                                            GBP
                                                                                            <span id="gbpPrice"></span>
                                                                                            <!-- <span id="ctl00_cph1_ctrlProductStockcard_dsStockCard_ctl00_lbldash">|</span> -->
                                                                                        </td>

                                                                                        <td>
                                                                                            <div align="center">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="stock_Price">
                                                                                            EUR
                                                                                            <span id="eurPrice"></span>
                                                                                            <!-- <span id="ctl00_cph1_ctrlProductStockcard_dsStockCard_ctl01_lbldash">|</span> -->
                                                                                        </td>

                                                                                        <td>
                                                                                            <div align="center">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="stock_Price">
                                                                                            HKD
                                                                                            <span id="hkdPrice"></span>
                                                                                            <!-- <span id="ctl00_cph1_ctrlProductStockcard_dsStockCard_ctl02_lbldash">|</span> -->
                                                                                        </td>

                                                                                        <td>
                                                                                            <div align="center">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="stock_Price">
                                                                                            CHF
                                                                                            <span id="chfPrice"></span>
                                                                                            <!-- <span id="ctl00_cph1_ctrlProductStockcard_dsStockCard_ctl03_lbldash">|</span> -->
                                                                                        </td>

                                                                                        <td>
                                                                                            <div align="center">
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="stock_Price">
                                                                                            USD
                                                                                            <span id="usdPrice"></span>

                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <br>
                                                            <div class="stock_text">
                                                                <p id="description"></p>
                                                                <br>
                                                                <p id="adminComment"></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="bottom" class="stock_footer" bgcolor="#FFFFFF"
                                            style="color: #CC9966">
                                            <span id="copyrightText"></span><br>

                                            <span id="footerTime"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4"></div>
</div>

<!-- </div>
                
            </div> -->