import {vEmailLookUpBase,} from '@app/database-models'
//Generated Imports
export class vEmailLookUp extends vEmailLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}