import {vInternalDepartmentBase,} from '@app/database-models'
//Generated Imports
export class vInternalDepartment extends vInternalDepartmentBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}