<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/message-templates']" rxText="Label_Message_Templates_t"></a>
        </li>
        <li class="breadcrumb-item active">Add New</li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1" [formGroup]="messageTemplateFormGroup"  [rxSpinner]="spin">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="MessageTemplateAddComponent_Title"></label></h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="row add-countries-form" (keyup.enter)="addMessageTemplate(false)">
          <div class="col-md-4">
            <div class="form-group row mb-0">
              <label class="col-md-4 col-form-label"  rxText="MessageTemplateAddEditComponent_TemplateName_t"></label>
              <div class="col-md-8">
                <input tabindex="1" type="text" class="form-control" formControlName="name"  rxPlaceholder="MessageTemplateAddEditComponent_TemplateName_p" rxFocus>
                <small class="form-text text-danger" [class.d-block]="messageTemplateFormGroup.controls.name.errors">{{messageTemplateFormGroup.controls.name.errorMessage}}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row add-countries-form">
          <div class="col-md-8">
            <div class="form-group row mb-0">
              <label class="col-md-2 col-form-label"  rxText="MessageTemplateAddEditComponent_Description_t"></label>
              <div class="col-md-9">
                <textarea class="form-control" tabindex="2" formControlName="description"
                  rows="4"  rxPlaceholder="MessageTemplateAddEditComponent_Description_p"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="hr-line-dashed border-top row"></div>
        <div class="row add-countries-form" (keyup.enter)="addMessageTemplate(false)">
          <div class="col-md-12">
            <div class="form-group row mb-0">
              <label class="col-md-1 col-form-label"  rxText="MessageTemplateAddEditComponent_Token_t"></label>
              <div class="col-md-10 ml-5">
                <label type="text">{{messageTemplateLookup.MessageKey.MessageKey}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="hr-line-dashed border-top row"></div>
        <div class="row add-countries-form">
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div *ngFor="let detail of messageTemplateDetails" [formGroup]="detail">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label" rxText="MessageTemplateAddEditComponent_EmailAccount_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <select class="form-control" formControlName="emailAccountId">
                        <option [selected]="true" value="">Select</option>
                        <option *ngFor="let item of messageTemplateLookup.emailAccountLookup" [value]="item.emailAccountId">{{item.email}}</option>
                      </select> -->
                      <rx-select [controlTabIndex]="2" [(source)]="messageTemplateLookup.emailAccountLookup" #rxSelect mainClass="form-control" formControlName="emailAccountId"
                      [keyValueProps]="['email','emailAccountId']" [selectPlaceholder]="'Select Email Account'"></rx-select>
                      <small class="form-text text-danger" [class.d-block]="detail.controls.emailAccountId.errors">{{detail.controls.emailAccountId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"  rxText="MessageTemplateAddEditComponent_Subject_t"></label>
                    <div class="col-md-8">
                      <input tabindex="3" type="text" class="form-control" formControlName="subject"  rxPlaceholder="MessageTemplateAddEditComponent_Subject_p">
                      <small class="form-text text-danger" [class.d-block]="detail.controls.subject.errors">{{detail.controls.subject.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"  rxText="MessageTemplateAddEditComponent_BCC_t"></label>
                    <div class="col-md-8">
                      <input tabindex="4" type="text" class="form-control" formControlName="bccEmailAddresses"  rxPlaceholder="MessageTemplateAddEditComponent_BCC_p">
                      <small class="form-text text-danger" [class.d-block]="detail.controls.bccEmailAddresses.errors">{{detail.controls.bccEmailAddresses.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row check-group">
                    <label class="col-md-4 col-form-label"  rxText="MessageTemplateAddEditComponent_IsActive_t"></label>
                    <div class="col-md-auto d-flex align-items-center">
                      <div class="i-checks">
                        <label class="mb-0">
                          <!-- <input type="checkbox" formControlName="isActive"><i></i> -->
                          <app-checkbox [tabIndex]="'5'" [checkFormControl]="detail.controls.isActive"></app-checkbox>
                        </label>
                      </div>
                      <small class="form-text text-danger" [class.d-block]="detail.controls.isActive.errors">{{detail.controls.isActive.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <!-- <div class="form-group row">
                    <label class="col-md-2 col-form-label"  rxText="messageTemplateBody"></label>
                    <div class="col-md-10">
                       <textarea class="form-control" rows="8" cols="37" formControlName="body"  rxPlaceholder="messageTemplateBody"></textarea>

                      <small class="form-text text-danger" [class.d-block]="detail.controls.body.errors">{{detail.controls.body.errorMessage}}</small>
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label"  rxText="MessageTemplateAddEditComponent_Body_t"></label>
                    <div class="col-md-8">
                          <editor
                                formControlName="body"
                                tabindex="6"
                                [init]="{
                                  height: 400,
                                  width: 630,
                                  menubar: true,
                                  automatic_uploads: false,
                                  convert_urls: false,
                                  relative_urls: false,
                                  remove_script_host: false,
                                  plugins:
                                  'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode fullscreen insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed mergetags tinymcespellchecker',
                                  toolbar:
                                  'undo redo | blocks | bold italic backcolor | spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help ',
                                  spellchecker_languages: 'UK English=en_GB',
                                  statusbar: true,
                                  menu: {
                                    format: {
                                      title: 'Format',
                                      items: 'configurepermanentpen | bold italic underline strikethrough ' +
                                        'superscript subscript codeformat | styles blocks fontfamily ' +
                                        'fontsize align lineheight | forecolor backcolor | removeformat'
                                    }
                                  },
                                  a11y_advanced_options: true,
                                  allow_html_in_named_anchor: true,
                                  content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                                }"
                                apiKey="{{ apiKey }}"
                            >
                            </editor>
                          <small class="form-text text-danger" [class.d-block]="detail.controls.body.errors">{{detail.controls.body.errorMessage}}</small>
                    </div>
                </div>

                </div>

              </div>

              </div>
          </div>
        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="addMessageTemplate(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="addMessageTemplate(true)" class="btn btn-primary mr-1" *rxAuthorize="[messageTemplateEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
