import { gridColumn } from "@rxweb/grid";

export class vTicketSeverityBase {
    //#region severityId Prop
    @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ITTicketSeverityListComponent_SeverityId_gh', keyColumn: true })
    severityId: number;
    //#endregion severityId Prop


    //#region name Prop
    @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true,headerKey: 'ITTicketSeverityListComponent_Name_gh', keyColumn: false })
    name: string;
    //#endregion name Prop
}