import { TemplateConfig } from "@rxweb/grid";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const ACTION_GRID_TEMPLATE_RISK: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          event: {
            click: "onEdit"
          },
          childrens: [{
            i: {
              class: "fa fa-edit fa-4x font-md text-default".split(" "),
              attributes: { "title": getLocalizedValue("Title_Edit") }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.projectID != null || this.projectID != undefined) {
                return "/projects/" + this.projectID
              }

            },
            "ng-reflect-router-link": function (e) {
              if (this.projectID != null || this.projectID != undefined) {
                return "/projects/" + this.projectID
              }
            },
            onClick: "return false;"
          },
          class: ["mr-2"]
        }
      }, {
        a: {
          authorize: "delete",
          event: {
            click: "onDelete"
          },
          childrens: [{
            i: {
              class: "fr-delete fa-4x font-md text-danger".split(" "),
              attributes: { "title": getLocalizedValue("Title_Delete") }
            }
          }]
        }
      }
    ]
  }
}