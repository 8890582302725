import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';

import { LeaveMaster } from '@app/models';
import { AbstractLeaveMaster } from '../domain/abstract-leave-master';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ActivatedRoute } from '@angular/router';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { LeaveTravelEventProjectMappingBase, EventCustomerMappingBase } from '@app/database-models';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { DatePipe, PlatformLocation } from '@angular/common';
import { LeaveStatusEnum, LeaveStatusEnumString, LeaveTravelButtons } from 'src/app/enums/leave-status.enum';
import { LeaveTypeSessionEnum } from 'src/app/enums/leave-type-session.enum';
import { LeaveTypeEnum } from 'src/app/enums/leave-type.enum';
import { multilingual } from '@rxweb/localization';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { DomSanitizer } from '@angular/platform-browser';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { FormArray } from "@angular/forms";
import { LeaveFlightMappingBase } from "src/app/models/database-models/leave-flight-mapping-base";
import { LeaveAccomodationMappingBase } from "src/app/models/database-models/leave-accomodation-mapping-base";
import { LeaveVehicleMappingBase } from "src/app/models/database-models/leave-vehicle-mapping-base";

@multilingual("LeaveMasterAddComponent")

@Component({
    selector: "app-leave-master-add",
    templateUrl: './leave-master-add.component.html'
})
export class LeaveMasterAddComponent extends AbstractLeaveMaster implements OnInit, OnDestroy {
    leaveMaster: LeaveMaster;
    subscription: any;
    isSourceChanged: boolean = true;
    @Input() userId: number;
    @Input() leaveId: number;
    @Input() reportTo: number;
    @Input() reportTos: string;
    @Input() reportToName: string;
    @Input() reportToNames: string;
    @Input() reportToNamesWithHOD: string;
    @Input() travelReportToNames : string;
    @Input() hide: () => any;
    @Input() masterId: number;
    @Input() year: number;
    @Input() viewOrCancel: number;
    @Input() isVisibleTravelType: boolean;
    @Input() isOnBehalf: boolean;
    @Input() isVisibleOtherUserLeaveDetails: boolean;
    @Input() hideUpdateButton: boolean = false;
    @Input() isPopupComponent : boolean = true;
    @Input() childFunction: Function;
    @Input() previousActiveTab: string;
    id: number;
    eventLookUp: any;
    projectLookUp: any;
    errorMessage: string = "[\n\"Start Date and End Date should be of same year !\"\n]";
    showApproveButton: boolean = true;
    showRejectButton: boolean = true;
    showCancelButton: boolean = false;
    showUpdateButton: boolean = false;
    showEditButton: boolean = false;
    annualLeaveReadOnlyControl: boolean = false;
    travelLeaveReadOnlyControl: boolean = false;
    otherLeaveReadOnlyControl: boolean = false;
    travelLeaveApprovedReadOnlyControl: boolean = false;
    isReadOnly: boolean = false;
    status: string = "";
    balanceLeave: number = 0;
    multipleYearBalance: string = "";
    defaultCurrencySelected: boolean = true;
    leaveType: string;
    startSession: string;
    endSession: string;
    currency: string;
    titleClose: string;
    onLoadStartDate: boolean = true;
    onLoadEndDate: boolean = true;
    hrCanCancelLeave: boolean = false;
    isAddLeave :boolean = false;
    radioFilter: any;
    leavereportToNames:string;
    // showDetailedForm: boolean = false;
    // showVehicleDetails: boolean = false;
    // showFlightDetails: boolean = false;
    // showAccomodationDetails: boolean = false;
    // showVehicleDetailTable:boolean=false;
    // showFlightDetailTable:boolean=false;
    showreason:boolean=false;
    showotherreason:boolean=false;
    isCancelled:boolean = false;



    _this;

    constructor(private formBuilder: RxFormBuilder, modelView: ModalView, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, private elementRef: ElementRef, private sanitizer: DomSanitizer,private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.modalView = modelView;
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this._this = this;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }
    
    ngOnInit(): void {
        console.log(this.reportToName);
        this.titleClose = getLocalizedValue("Title_Close");
        var json = {
            userId: this.userId,
            year: this.year,
            leaveId: this.leaveId,
            masterId: this.masterId
        }
        this.isAddLeave =  (this.masterId != 0) ? false : true;
        this.showComponent = false;
        this.lookup([
            { propName: "vLeaveTypeLookUp", path: USER_LOOKUPS.leaveTypesLookUp , params: [this.userId]},
            { propName: "vCurrencyLookUp", path: USER_LOOKUPS.currencyLookUp },
            { propName: "vFlightClassLookUp", path: USER_LOOKUPS.flightClassLookUp },
            { propName: "vAccomodationPreferenceLookUp", path: USER_LOOKUPS.accomodationPreferenceLookUp },
            { propName: "vAccomodationPreferenceReasonsLookUp", path: USER_LOOKUPS.accomodationPreferenceReasonsLookUp },
            { propName: "vehicleRequestTypesLookup", path: USER_LOOKUPS.vehicleRequestTypesLookup },
            { propName: "leaveMasterDetail", queryParams: { query: JSON.stringify(json) } }
        ]).subscribe((lookup: any) => {
            this.leaveMaster = new LeaveMaster();
            this.leaveLookUps = lookup;
            if (this.masterId > 0) {
                this.leaveDetail = lookup.leaveMasterDetail[0];
                this.leaveMaster.masterId = this.leaveDetail.masterId;
                this.leaveMaster.userID = this.leaveDetail.userId;
                this.leaveMaster.managerID = this.leaveDetail.managerId;
                this.leaveMaster.reportToName = this.leaveDetail.managerName;
                this.leaveMaster.reportToNames = this.leaveDetail.reportToNames;
                this.leaveMaster.reportToNamesWithHOD = this.leaveDetail.reportToNamesWithHOD;
                this.leaveMaster.travelReportToNames = this.leaveDetail.travelReportToNames;
                this.leaveMaster.isVisibleTravelType = this.leaveDetail.isVisibleTravelType;
                this.isVisibleTravelType = this.leaveDetail.isVisibleTravelType;
                this.leaveMaster.reportToIds = this.leaveDetail.reportToIds;
                this.leaveMaster.startSession = this.leaveDetail.startSession;
                this.leaveMaster.endSession = this.leaveDetail.endSession;
                this.leaveMaster.updatedOn = new Date();
                this.leaveMaster.status = this.leaveDetail.status;
                this.leaveMaster.projectIds = this.leaveDetail.leaveMasterProjectId;
                this.leaveMaster.budget = this.leaveDetail.budget;
                this.leaveMaster.currency = this.leaveDetail.currency;
                this.leaveMaster.days = this.leaveDetail.days;
                this.leaveMaster.citiesMinesLocations = this.leaveDetail.citiesMinesLocations;
                this.leaveMaster.startDate = this.datePipe.transform(this.leaveDetail.startDate, "dd/MMM/yyyy");
                this.leaveMaster.endDate = this.datePipe.transform(this.leaveDetail.endDate, "dd/MMM/yyyy");
                this.leaveMaster.IsEditLeaveApply = true;
                this.leaveMaster.comments = this.leaveDetail.comments;
                this.leaveMaster.attachment = this.leaveDetail.attachment;
                this.leaveMaster.fileUrl = this.leaveDetail.fileUrl;
                this.leaveMaster.leaveUserFullName = this.leaveDetail.leaveUserFullName;
                this.leaveMaster.isDomesticTravel = this.leaveDetail.isDomesticTravel;
                this.leaveMaster.reason = this.leaveDetail.reason;
                this.leaveMaster.purposeOfTravel = this.leaveDetail.purposeOfTravel;
                this.leaveMaster.isOfficial = this.leaveDetail.isOfficial;
                this.leaveMaster.isDetailedFormAdded = this.leaveDetail.isDetailedFormAdded;
                this.leaveMaster.isVehicleRequested = this.leaveDetail.isVehicleRequested;
                this.leaveMaster.isFlightRequested = this.leaveDetail.isFlightRequested;
                this.leaveMaster.isAccomodationRequested = this.leaveDetail.isAccomodationRequested;
                this.leaveMaster.leaveVehicleMappings = this.leaveDetail.leaveVehicleMappings;
                this.leaveMaster.leaveFlightMappings = this.leaveDetail.leaveFlightMappings;
                this.leaveMaster.leaveAccomodationMappings = this.leaveDetail.leaveAccomodationMappings;
                this.leaveMaster.totalHrsRequested = this.leaveDetail.totalHrsRequested;
                this.leaveMaster.logisticUse = this.leaveDetail.logisticUse;
                this.leaveMaster.carProvided = this.leaveDetail.carProvided;
                this.leaveMaster.driverProvided = this.leaveDetail.driverProvided;
                this.leaveMaster.driverPhone = this.leaveDetail.driverPhone;
                this.leaveMaster.emailForUpdates = this.leaveDetail.emailForUpdates;
                this.leaveMaster.telePhoneForUpdates = this.leaveDetail.telePhoneForUpdates;
             
                this.hrCanCancelLeave = this.leaveDetail.hrCanCancelLeave;
                this.isReadOnly = true;
                if (this.leaveDetail.currency != 0) {
                    this.defaultCurrencySelected = false;
                }
                this.status = LeaveStatusEnumString[this.leaveDetail.status];
                this.showStatusButtons(this.leaveMaster.status, this.leaveMaster.userID, this.leaveId, this.leaveMaster.budget);
            }
            else {
                this.leaveMaster.masterId = 0;
                this.leaveMaster.userID = this.userId;
                this.leaveMaster.managerID = this.reportTo;
                this.leaveMaster.reportToName = this.reportToName;

                this.leaveMaster.reportToIds = this.reportTos;
                this.leaveMaster.reportToNames = this.reportToNames;
                this.leaveMaster.reportToNamesWithHOD = this.reportToNamesWithHOD;
                this.leaveMaster.isVisibleTravelType = this.isVisibleTravelType;
                this.leaveMaster.isDomesticTravel = this.isVisibleTravelType == true ? true : null;
                this.leaveMaster.startSession = LeaveTypeSessionEnum.AM;
                this.leaveMaster.endSession = LeaveTypeSessionEnum.PM;
                this.leaveMaster.status = LeaveStatusEnum.Apply;
                this.leaveMaster.IsEditLeaveApply = false;
                this.leaveMaster.travelReportToNames = this.travelReportToNames;
                //this.leaveMaster.currency = 0;
                this.leaveMaster.clickedButtonType = 0;
                this.leaveMaster.leaveVehicleMappings = new Array<LeaveVehicleMappingBase>();
                this.leaveMaster.leaveFlightMappings = new Array<LeaveFlightMappingBase>();
                this.leaveMaster.leaveAccomodationMappings = new Array<LeaveAccomodationMappingBase>();
            }
            this.leaveMaster.isOnBehalf = this.isOnBehalf;
            this.leaveMaster.leaveType = this.leaveId;
            this.selectLeaveType = this.leaveId;
            this.leaveMaster.createdOn = new Date();
            this.leaveMaster.leaveTravelEventProjectMappings = new Array<LeaveTravelEventProjectMappingBase>();
          this.leaveMaster.eventCustomerMappings = new Array<EventCustomerMappingBase>();

            this.previousStartDate =  this.leaveMaster.startDate;
            this.previousEndDate = this.leaveMaster.endDate
            this.previousBudget = this.leaveMaster.budget;
            console.dir(this.leaveMaster.reportToName);
            this.leaveMasterFormGroup = this.formBuilder.formGroup(this.leaveMaster) as IFormGroup<LeaveMaster>;
            console.dir(this.leaveMasterFormGroup.value.reportToName);
            this.changeLeaveType(this.leaveId);
            this.getApplyLeaveLookups();
            console.log(this.leaveMasterFormGroup.value.reportToName);

            if (this.leaveLookUps.vLeaveTypeLookUp.findIndex(x => x.leaveId == this.leaveMasterFormGroup.value.leaveType) != -1) {
                this.leaveType = this.leaveLookUps.vLeaveTypeLookUp.find(x => x.leaveId == this.leaveMasterFormGroup.value.leaveType).leaveTypeName;
            }
            if (this.timeZoneLookUp.findIndex(x => x.id == this.leaveMasterFormGroup.value.startSession) != -1) {
                this.startSession = this.timeZoneLookUp.find(x => x.id == this.leaveMasterFormGroup.value.startSession).value;
            }
            if (this.timeZoneLookUp.findIndex(x => x.id == this.leaveMasterFormGroup.value.endSession) != -1) {
                this.endSession = this.timeZoneLookUp.find(x => x.id == this.leaveMasterFormGroup.value.endSession).value;
            }
            if (this.leaveLookUps.vCurrencyLookUp.findIndex(x => x.currencyId == this.leaveMasterFormGroup.value.currency) != -1) {
                this.currency = this.leaveLookUps.vCurrencyLookUp.find(x => x.currencyId == this.leaveMasterFormGroup.value.currency).currencyName;
            }
            this.radioFilter = this.isReadOnly? this.sanitizer.bypassSecurityTrustStyle('position: relative;filter: grayscale(1);'): this.sanitizer.bypassSecurityTrustStyle('position: relative;')
            this.showComponent = true;

            console.log(this.leaveMasterFormGroup.value.reportToName);
        })
    }

    getApplyLeaveLookups(controlName: string = "") {
        if(controlName != '' ){
            if (this.onLoadStartDate) {
                this.onLoadStartDate = false;
                return;
            }
            if (this.onLoadEndDate) {
                this.onLoadEndDate = false;
                return;
            }
        }
        if (this.leaveMasterFormGroup.controls.startDate.value != null && this.leaveMasterFormGroup.controls.endDate.value != null) {
            let startDate = new Date(this.leaveMasterFormGroup.controls.startDate.value);
            let endDate = new Date(this.leaveMasterFormGroup.controls.endDate.value)
            endDate.setHours(startDate.getHours(),startDate.getMinutes(),0);

            if (startDate > endDate) {
                this.dateError = true;
            } else if (startDate.getTime() == endDate.getTime()) {
                if (this.leaveMasterFormGroup.controls.startSession.value == 2 && this.leaveMasterFormGroup.controls.endSession.value == 1) {
                    this.dateError = true;
                } else {
                    this.dateError = false;
                }
            } else {
                this.dateError = false;
            }
            if (!this.dateError) {
                this.spin = true;
                var json = {
                    startDate: this.leaveMasterFormGroup.controls.startDate.value,
                    endDate: this.leaveMasterFormGroup.controls.endDate.value,
                    paramUserId: this.userId,
                    startSession: this.leaveMasterFormGroup.controls.startSession.value,
                    endSession: this.leaveMasterFormGroup.controls.endSession.value,
                    leaveTypeId: this.leaveMasterFormGroup.controls.leaveType.value,
                    masterId: this.leaveMasterFormGroup.controls.masterId.value,
                    onLoad: controlName == "onLoad" ? 1 : 0
                }
                this.post({ path: "api/masterlookups/UserApplyLeaveLookUps", body: { query: JSON.stringify(json) } }).subscribe((data: any) => {
                    this.spin = false;
                    this.applyLeaveLookUps = JSON.parse(data as string);
                    this.applyLeaveLookUps = this.applyLeaveLookUps[0];
                    this.eventLookUp = this.applyLeaveLookUps.applyLeaveEventLookUps;
                    this.isSourceChanged = false;
                    this.projectLookUp = this.applyLeaveLookUps.applyLeaveProjectLookUps;
                    this.balanceLeave = (this.applyLeaveLookUps.LeaveDayBalanceCount[0].balanceCount < 0) ? 0 : this.applyLeaveLookUps.LeaveDayBalanceCount[0].balanceCount;
                    this.multipleYearBalance = this.applyLeaveLookUps.LeaveDayBalanceCount[0].multipleYearBalance;
                    this.leaveMasterFormGroup.patchValue({
                        days: this.applyLeaveLookUps.LeaveDayBalanceCount[0].holidayCount,
                        balance: this.balanceLeave,
                        eventIds: this.leaveDetail.leaveMasterEventId,
                        projectIds: this.leaveDetail.leaveMasterProjectId
                    });
                    this.eventCustomerMappings().clear();
                    this.leaveTravelEventProjectMappings().clear();
                    window.setTimeout(x => {
                        this.isSourceChanged = true;
                    })
                    if (controlName == "startDate") {
                        const focusControl = this.elementRef.nativeElement.querySelector('#btnClose');
                        focusControl.focus();
                    }
                    else if (controlName == "endDate") {
                        const focusControl = this.elementRef.nativeElement.querySelector('#btnClose');
                        focusControl.focus();
                    }
                    this.showComponent = true;
                })
            }
        }
    }

    async addLeave() {
        if (!this.dateError) {
            // if ((new Date(this.leaveMasterFormGroup.controls.startDate.value).getFullYear() != new Date(this.leaveMasterFormGroup.controls.endDate.value).getFullYear()) && (this.leaveMasterFormGroup.controls.leaveType.value == 1 || this.leaveMasterFormGroup.controls.leaveType.value == 8)) {
            //     this.modalView.show(BedRequestComponent, { validationMessages: this.errorMessage }).then(t => {
            //     });
            // }
            // else {
                this.leaveMasterFormGroup.submitted = true;
                if (this.leaveMasterFormGroup.valid) {
                    this.spin = true;
                    if (this.leaveMasterFormGroup.value.fileData1) {

                        let res = await this.uploadToBlob(this.fileOutputViewModelHoliday, this.leaveMasterFormGroup.value.blobPath);
                        this.leaveMasterFormGroup.patchValue({
                            fileData1: null,
                            blobFileName: res
                        });
                    }
                    if (this.leaveMasterFormGroup.value.fileData) {
        
                        let res = await this.uploadToBlob(this.fileOutputViewModel, this.leaveMasterFormGroup.value.blobPath);
                        this.leaveMasterFormGroup.patchValue({
                            fileData: null,
                            blobFileName: res
                        });
                    }
                    this.bindEventProjectFormArray(this.formBuilder, this.userId, this.masterId);
                    this.post({ body: this.leaveMasterFormGroup.value }).subscribe(data => {
                        this.spin = false;
                        var existsAlert = getLocalizedValue("Data_Added");
                        if (existsAlert) {
                            //this.toastr.success("Data Added Successfully")
                            this.toastr.success(existsAlert);
                        }
                        if(!this.isPopupComponent){
                            this.childFunction(this.previousActiveTab);
                            //this.ngOnInit()
                        }
                        this.hide();
                    });
                }
           // }
        }
    }

    showStatusButtons(status: number, userId: number, leaveType: number, budget: number) {
        this.isAddLeave =  (this.masterId != 0) ? false : true;
        this.hideUpdateButton  = (userId != this.loggedInUserId) ? true : false;
        this.showUpdateButton = (status != LeaveStatusEnum.Cancle && status != LeaveStatusEnum.Reject && status != LeaveStatusEnum.Approve && userId == this.loggedInUserId && this.hideUpdateButton != true) ? true : false;
        this.showEditButton = (userId == this.loggedInUserId && !this.showUpdateButton) ? true : false;
        if (userId == this.loggedInUserId || status != LeaveStatusEnum.Apply || this.viewOrCancel == 1 || this.isVisibleOtherUserLeaveDetails == false) {
            this.showApproveButton = false;
            this.showRejectButton = false;
        }
        if (leaveType == LeaveTypeEnum.AnnualHolidays) {
            //this.showEditButton = (status != LeaveStatusEnum.Apply && this.viewOrCancel == 0 && userId == this.loggedInUserId) ? true : false;
            if (status == LeaveStatusEnum.Apply || userId != this.loggedInUserId) {
                this.annualLeaveReadOnlyControl = true;
            }
            else if (this.viewOrCancel == 1) {
                this.annualLeaveReadOnlyControl = true;
            }
        } else if (leaveType == LeaveTypeEnum.TravelRequest) {
            // this.showUpdateButton = (budget > 0 && status == LeaveStatusEnum.Approve && userId == this.loggedInUserId && this.viewOrCancel == 0) ? true : false;
            if (status == LeaveStatusEnum.Apply || status == LeaveStatusEnum.Reject || status == LeaveStatusEnum.Approve || status == LeaveStatusEnum.Cancle || userId != this.loggedInUserId) {
                this.travelLeaveReadOnlyControl = true;
            //} else if (status == LeaveStatusEnum.Approve && userId == this.loggedInUserId) {
                this.travelLeaveApprovedReadOnlyControl = true;
            }
        }
        else {
            if (userId != this.loggedInUserId) {
                this.otherLeaveReadOnlyControl = true;
            }
            else if (status == LeaveStatusEnum.Apply || status == LeaveStatusEnum.Reject) {
                this.otherLeaveReadOnlyControl = true;
            }
        }
        if (this.viewOrCancel == 1) {
            this.showCancelButton = true;
        } else if (this.hrCanCancelLeave == true && status != LeaveStatusEnum.Reject && status != LeaveStatusEnum.Cancle && this.showApproveButton == false && this.showRejectButton == false) {
            this.showCancelButton = true;
        }
    }

    updateLeave(clickedButtonType: number) {
        if (!this.dateError){
            var confirmMessage = ""
            this.leaveMasterFormGroup.submitted = true;
            if (this.leaveMasterFormGroup.valid) {
                if (clickedButtonType == LeaveTravelButtons.Edit || clickedButtonType == LeaveTravelButtons.Update) {
                    if (this.leaveMasterFormGroup.controls.status.value == LeaveStatusEnum.Apply) {
                        confirmMessage = "Note: You are editing awaiting leave, once you press submit this will be sent to your line manager for Approval."
                    }
                    else if (this.leaveMasterFormGroup.controls.status.value == LeaveStatusEnum.Approve) {
                        confirmMessage = "Note: You are editing approved leave, once you press submit this will be sent to your line manager for Re-Approval."
                    } else if (this.leaveMasterFormGroup.controls.status.value == LeaveStatusEnum.Cancle) {
                        confirmMessage = "Note: You are editing cancelled leave, once you press submit this will be sent to your line manager for Re-Approval."
                    }
                    else if (this.leaveMasterFormGroup.controls.status.value == LeaveStatusEnum.Reject) {
                        confirmMessage = "Note: You are editing rejected leave, once you press submit this will be sent to your line manager for Re-Approval."
                    }
                    this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
                    this.dialog.designClass.button.cancel = ["btn-cancle", "sweet-alert-cancel-button"];
                    this.dialog.designClass.button.ok = ["btn-ok"];
                    this.dialog.defaultConfig.showIcon = false;
                    this.dialog.defaultConfig.text.heading = '';
                    this.dialog.show(confirmMessage, DialogViewMode.okWithCancel).then(t => {
                        if (t === DialogEnum.Ok) {
                            this.leaveMasterFormGroup.patchValue({
                                clickedButtonType: clickedButtonType
                            });
                            this.spin = true;
                            this.bindEventProjectFormArray(this.formBuilder, this.userId, this.masterId);
                            this.put({ params: [this.masterId], body: this.leaveMasterFormGroup.value }).subscribe(data => {
                                this.spin = false;
                                var existsAlert = getLocalizedValue("Data_Updated");
                                if (existsAlert) {
                                    //this.toastr.success("Data Updated Successfully")
                                    this.toastr.success(existsAlert);
                                }

                                if(!this.isPopupComponent){
                                    this.ngOnInit()
                                }
                                this.hide();
                            })
                        }
                        this.dialog.hide();
                    });
                } else {
                    this.leaveMasterFormGroup.patchValue({
                        clickedButtonType: clickedButtonType
                    });
                    if (clickedButtonType == LeaveTravelButtons.Cancel || clickedButtonType == LeaveTravelButtons.Approve || clickedButtonType == LeaveTravelButtons.Reject) {
                        confirmMessage = this.getConfirmationMessage(clickedButtonType);
                        this.dialog.defaultConfig.text.ok = "Yes";
                        this.dialog.defaultConfig.text.cancel = "No";
                        this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
                        this.dialog.designClass.button.cancel = ["btn-cancle", "sweet-alert-cancel-button"];
                        this.dialog.designClass.button.ok = ["btn-ok"];
                        this.dialog.defaultConfig.showIcon = false;
                        this.dialog.defaultConfig.text.heading = '';
                        this.dialog.show(confirmMessage, DialogViewMode.okWithCancel).then(t => {
                            if (t === DialogEnum.Ok) {
                                this.spin = true;
                                this.bindEventProjectFormArray(this.formBuilder, this.userId, this.masterId);
                                this.put({ params: [this.masterId], body: this.leaveMasterFormGroup.value }).subscribe(data => {
                                    this.spin = false;
                                    var existsAlert = getLocalizedValue("Data_Updated");
                                    if (existsAlert) {
                                        //this.toastr.success("Data Updated Successfully")
                                        this.toastr.success(existsAlert);
                                    }
                                    if(!this.isPopupComponent){
                                        if(clickedButtonType == LeaveTravelButtons.Cancel){
                                            this.isCancelled = true;
                                        }
                                        this.ngOnInit()
                                    }
                                    this.hide();
                                })
                            }
                            this.dialog.hide();
                        });
                    } else {
                        this.spin = true;
                        this.bindEventProjectFormArray(this.formBuilder, this.userId, this.masterId);
                        if (this.previousStartDate != this.datePipe.transform(this.leaveMasterFormGroup.value.startDate, "dd/MMM/yyyy") || this.previousEndDate != this.datePipe.transform(this.leaveMasterFormGroup.value.endDate, "dd/MMM/yyyy") || this.previousBudget != this.leaveMasterFormGroup.value.budget) {
                            this.leaveMasterFormGroup.patchValue({
                                status: LeaveStatusEnum.Apply
                            });
                        }

                        this.put({ params: [this.masterId], body: this.leaveMasterFormGroup.value }).subscribe(data => {
                            this.spin = false;
                            var existsAlert = getLocalizedValue("Data_Updated");
                            if (existsAlert) {
                                //this.toastr.success("Data Updated Successfully")
                                this.toastr.success(existsAlert);
                            }
                            if(!this.isPopupComponent){
                               this.ngOnInit()
                            }
                            this.hide();
                        })
                    }
                }
            }
        }
    }

    getConfirmationMessage(clickedButtonType: number) {
        switch (clickedButtonType) {
            case LeaveTravelButtons.Cancel:
                return getLocalizedValue("ConfirmLeaveCancel_t");
            case LeaveTravelButtons.Approve:
                return getLocalizedValue("ConfirmLeaveApprove_t");
            case LeaveTravelButtons.Reject:
                return getLocalizedValue("ConfirmLeaveReject_t");
            default:
                "";
        }
    }

    

    get leaveFlightDetailFormArray(): FormArray {
        let leaveFlightDetailFormArray = this.leaveMasterFormGroup.controls.leaveFlightMappings as IAbstractControl;
        let data = <FormArray>leaveFlightDetailFormArray["controls"]
        return data;
      }

      get leaveVehicleDetailFormArray(): FormArray {
        let leaveVehicleDetailFormArray = this.leaveMasterFormGroup.controls.leaveVehicleMappings as IAbstractControl;
        let data = <FormArray>leaveVehicleDetailFormArray["controls"]
        return data;
      }

      get leaveAccomodationDetailFormArray(): FormArray {
        let leaveAccomodationDetailFormArray = this.leaveMasterFormGroup.controls.leaveAccomodationMappings as IAbstractControl;
        let data = <FormArray>leaveAccomodationDetailFormArray["controls"]
        return data;
      }


    UpdateVehicleDetail($event){
      if ($event.target.checked && this.leaveVehicleDetailFormArray.length == 0) {
            this.AddVehicle();    
          }   
    }

    UpdateFlightDetail($event){
      if ($event.target.checked && this.leaveFlightDetailFormArray.length == 0) {
          this.AddFlight();
          }    
    }

    UpdateAccomodationDetail($event){
      if ($event.target.checked && this.leaveAccomodationDetailFormArray.length == 0) {
          this.AddAccomodation();
          }
          
    }

    // checkAccomodationPreference(ev){
    //     if(ev.text.toLowerCase() == 'hotel')
    //     {
    //         this.showreason = true;
    //     }
    //     else{
    //         this.showreason = false;
    //         this.showotherreason = false;
    //         this.leaveAccomodationDetailFormArray[0].patchValue({
    //             reasonID: '',
    //             otherReason: ''
    //         });
    //     }
    // }

    // checkAccomodationPreferenceReason(ev){
    //     if(ev.text.toLowerCase() == 'other')
    //     {
    //         this.showotherreason = true;
    //     }
    //     else{
    //         this.showotherreason = false;
    //         this.leaveAccomodationDetailFormArray[0].patchValue({
    //             otherReason: ''
    //         });
    //     }
    // }


    AddFlight(){
        const leaveFlightDetailFormArray = this.leaveMasterFormGroup.get('leaveFlightMappings') as FormArray;

        leaveFlightDetailFormArray.push(this.formBuilder.group({
            flightFrom:0,
            flightTo:0,
            arrivalDate: '',
            departureDate : '',
            classOfService:0,
            flightFromName:'',
            flightToName:''
        }));   
    }

    DeleteFlight(index:number){
        const leaveFlightDetailFormArray = this.leaveMasterFormGroup.get(
            "leaveFlightMappings"
          ) as FormArray;
          leaveFlightDetailFormArray.removeAt(index);
    }

    AddVehicle(){
        const leaveVehicleDetailFormArray = this.leaveMasterFormGroup.get('leaveVehicleMappings') as FormArray;

        leaveVehicleDetailFormArray.push(this.formBuilder.group({
            destinationFrom:'',
            destinationTo:'',
            dateFrom: '',
            dateTo : '',
            exitOrReturn:0
        }));   
    }

    DeleteVehicle(index:number){
        const leaveVehicleDetailFormArray = this.leaveMasterFormGroup.get(
            "leaveVehicleMappings"
          ) as FormArray;
          leaveVehicleDetailFormArray.removeAt(index);
    }

    AddAccomodation(){
        const leaveAccomodationDetailFormArray = this.leaveMasterFormGroup.get('leaveAccomodationMappings') as FormArray;
        leaveAccomodationDetailFormArray.push(this.formBuilder.group({
          accomodationPreferenceID:'',
          reasonID:'',
          otherReason: '',
          checkInDate : '',
          checkOutDate:'',
          noOfDays:''
        }));   
    }

        ngOnDestroy(): void {
            if (this.subscription)
                this.subscription.unsubscribe();
        }
        
        get componentName() {
            return "LeaveMasterAddComponent";
        }
    
}
