import { Component, OnInit, OnDestroy } from "@angular/core"
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { CoreComponent, access } from '@rxweb/angular-router';
import { BannedIpAddressAddComponent } from '../banned-ip-addresses/add/banned-ip-address-add.component';
import { BannedIpNetworkAddComponent } from '../banned-ip-network/add/banned-ip-network-add.component';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { BrowserModule, Title } from '@angular/platform-browser';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { bindSlider } from "src/app/domain/customize-plugin/bind-slider";
import { multilingual } from '@rxweb/localization';
import { SystemHelpViewComponent } from "../system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("BlackListComponent")
@access({ accessLevel: RolePermissionEnums.Blacklist, action: "get" })
@Component({
    selector: "app-blacklist",
    templateUrl: './blacklist.component.html'
})
export class BlackListComponent extends CoreComponent implements OnInit, OnDestroy {
    selectedTab: string = "address";
    showTab: boolean = false;
    moduleID:number;
    modalView: ModalView;
    titleHelp:string;
    currentActiveTab: string = "address";
    spin:any;
    //componentId:any;
    get componentName(): string {
        return "BlackListComponent";
      }
    // bannedIpAddressAdd:any=BannedIpAddressAddComponent;
    // bannedIpNetworkAdd:any=BannedIpNetworkAddComponent;

    // bannedIpAddressList:any=dbAccessModule[RolePermissionEnums.BlacklistIpAddress]["get"];;
    // bannedIpNetworkList:any=dbAccessModule[RolePermissionEnums.BlacklistIpNetwork]["get"];;
    router: Router;
    showSystemHelpIcon: boolean = false;
    constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private activatedRoute: ActivatedRoute, private applicationBroadcaster: ApplicationBroadcaster, router: Router, private title: Title) {
        super();
        this.modalView = modalView;
        this.router = router;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.activatedRoute.queryParams.subscribe(t => {
            if (t['activeTab']) {
                this.selectedTab = t['activeTab'];
            }
        });
        this.moduleID = RolePermissionEnums.DesignCategories;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Blacklist - G-Trac Admin");
        this.activeTab(this.selectedTab);
        this.titleHelp = getLocalizedValue("Title_Help");
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    ngOnDestroy(): void {
    }

    activeTab(tabName, firstTime: boolean = false) {
        this.selectedTab = tabName;
        this.router.navigate(["blacklist"], { queryParams: { activeTab: tabName } });
    }

}
