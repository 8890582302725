import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ApplicationRef, NgZone, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractRolePermission } from '../domain/abstract-role-permission';
import { Subscription, merge } from 'rxjs';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { RolePermissionSearchModel } from '../../../../view-model/role-permission-search-model';
import { IFormGroup, RxFormBuilder, even } from '@rxweb/reactive-form-validators';
import { RolePermissionViewModel } from '../../../../view-model/role-permission-view-model';
import { access } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { DialogViewMode } from '@rxweb/js';
import { RolePermissionEnums } from '../../../../custom-enum/role-permission-enums';
import { RolePermission, vDepartmentLookUp, vAdminRoleLookUp } from '../../../../models/extended-models';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { Title } from "@angular/platform-browser";
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { UserPermission } from 'src/app/models/extended-models/user-permission';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@access({ accessLevel: RolePermissionEnums.ModuleLevelPermissions, action: "get" })
@multilingual("RolePermissionListComponent")
@Component({
    selector: "app-role-permission-list",
    templateUrl: './role-permission-list.component.html'
    , changeDetection: ChangeDetectionStrategy.Default
})
export class RolePermissionListComponent extends AbstractRolePermission implements OnInit, OnDestroy {
    rolePermissions: List<RolePermission>;
    subscription: any;
    isFilter: boolean;
    roleLookup: vAdminRoleLookUp[];
    parentModules: List<any>;
    rolePermissionSearch: RolePermissionSearchModel;
    userLookup: any;
    rolePermissionSearchFormGroup: IFormGroup<RolePermissionSearchModel>;
    rolePermissioinsData: List<RolePermissionViewModel>;
    showDiv: boolean;
    dropdownDisabled: boolean;
    showUser: boolean;
    oldPermissions: any[];
    isDataChanged: boolean;
    isForDepartment: boolean;
    fullName: string;
    moduleID:number;
    titleHelp:string;
    titleExpand:string;
    titleCollapse:string;
    showSystemHelpIcon: boolean = false;
    titleToggle:string[]=[];


    _this;

    constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private zone: NgZone, private _cdr: ChangeDetectorRef, private ref: ApplicationRef, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.isFilter = false;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.ModuleLevelPermissions;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        // this.subscription = this.get().subscribe((t: List<RolePermission>) => {
        //     this.rolePermissions = t;
        // })
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Role Permissions - G-Trac Admin");
        this.showComponent = false;
        this.showDiv = false;
        this.showUser = true;
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleExpand= getLocalizedValue("Title_Expand");
        this.titleCollapse=getLocalizedValue("Title_Collapse");
        this.spin = true;
        this.dropdownDisabled = true;
        this.isDataChanged = true;
        this.oldPermissions = new Array<any>();
        this.lookup([
            { path: USER_LOOKUPS["adminRoleTypeLookUp"], propName: 'roleLookup' },
        ]).subscribe((t: any) => {
            this.roleLookup = t.roleLookup;
            if (!this.isFilter) {
              //  let deptObj = new vAdminRoleLookUp();
                // deptObj.departmentId=0;
                // deptObj.departmentName="Select Department";
                // this.departmentLookup.unshift(deptObj);
                this.rolePermissionSearch = new RolePermissionSearchModel();
                // this.rolePermissionSearch.department=0;
                this.rolePermissionSearch.userId = 0;
            }

            this.rolePermissionSearchFormGroup = this.formBuilder.formGroup(RolePermissionSearchModel, this.rolePermissionSearch) as IFormGroup<RolePermissionSearchModel>;
            this.spin = false;
            this.showComponent = true;
        })
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    getUsers(event: any) {


        this.userLookup = null;
        this.rolePermissionSearchFormGroup.patchValue({
            userId: 0
        });
        setTimeout(() => {
            this.showUser = true;
        }, 4)
        this.showUser = false;
        this.post({ path: 'api/SearchUsers/GetUsersByRole', body: { query: JSON.stringify(this.rolePermissionSearchFormGroup.value) } }).subscribe((x: any) => {
            this.userLookup = x;
            if (this.roleLookup.findIndex(d => d.roleName == "Select Role") != -1) {
                this.roleLookup.splice(0, 1);
            }
            this.dropdownDisabled = this.userLookup.length > 0 ? false : true;
            // this.rolePermissioinsData=new List<RolePermissionViewModel>();
            // this.parentModules=new List<any>();
            // this.showDiv=false;
            if (this.userLookup.findIndex(x => x.userId == this.rolePermissionSearchFormGroup.value.userId) != -1) {
                this.fullName = this.userLookup.find(x => x.userId == this.rolePermissionSearchFormGroup.value.userId).fullName;
            }
            this.refreshGrid();
        })
    }


    enableButton() {
        if (this.rolePermissioinsData != undefined && this.rolePermissioinsData.length > 0) {
            this.isDataChanged = false;
        }
    }
    checkFullControl(permissionData: any) {
        // this.isForDepartment=false;
        if (((permissionData.CanAdd &&
            permissionData.CanView &&
            permissionData.CanEdit &&
            permissionData.CanDelete) ||
            (
                (permissionData.CanView && permissionData.HasView && !permissionData.HasAdd && !permissionData.HasEdit && !permissionData.HasDelete) ||
                (permissionData.CanAdd && !permissionData.HasView && permissionData.HasAdd && !permissionData.HasEdit && !permissionData.HasDelete) ||
                (permissionData.CanEdit && !permissionData.HasView && !permissionData.HasAdd && permissionData.HasEdit && !permissionData.HasDelete) ||
                (permissionData.CanView && permissionData.CanEdit && permissionData.HasView && !permissionData.HasAdd && permissionData.HasEdit && !permissionData.HasDelete) ||
                (permissionData.CanView && permissionData.CanDelete && permissionData.HasView && !permissionData.HasAdd && !permissionData.HasEdit && permissionData.HasDelete) ||
                (permissionData.CanView && permissionData.CanEdit && permissionData.CanDelete && permissionData.HasView && !permissionData.HasAdd && permissionData.HasEdit && permissionData.HasDelete)
            ) &&
            (permissionData.FullControl == false || permissionData.FullControl == undefined))) {
            this.rolePermissioinsData.forEach(t => {
                if (t.ModuleMastersId == permissionData.ModuleMastersId) {
                    t.FullControl = true;
                    document.getElementById(t.checkFCId).classList.add("checked");
                }
            })
        }
        else if (permissionData.FullControl == true && (!permissionData.CanAdd || !permissionData.CanView || !permissionData.CanEdit || !permissionData.CanDelete)) {
            this.rolePermissioinsData.forEach(t => {
                if (t.ModuleMastersId == permissionData.ModuleMastersId) {
                    t.FullControl = false;
                    document.getElementById(t.checkFCId).classList.remove("checked");
                }
            })
        }
        else {
        }
    }

    refreshGrid() {
        setTimeout(() => {
            this.showDiv = false;
            this.rolePermissioinsData = new List<RolePermissionViewModel>();
        }, 4);
    }
    checkboxSelect(event: any, permissionData: RolePermissionViewModel, permissionType: string) {

        let chkId: string;
        this.isDataChanged = false;
        this.isForDepartment = (this.rolePermissionSearchFormGroup.value.userId == 0 || this.rolePermissionSearchFormGroup.value.userId == null) ? true : false;
        if (permissionType == 'fullControl') {

            this.rolePermissioinsData.forEach(x => {
                if (x.ModuleMastersId == permissionData.ModuleMastersId) {
                    x.CanEdit = event.target.checked;
                    x.CanView = event.target.checked;
                    x.CanDelete = event.target.checked;
                    x.CanAdd = event.target.checked;
                    x.FullControl = event.target.checked;
                    document.getElementById(x.checkFCId).classList.add("checked");
                    this.updateCheckBoxes(x);
                }
            })
        }

        else if (permissionType == 'view' && (permissionData.CanEdit == true || permissionData.CanDelete == true || permissionData.CanAdd == true) && event.target.checked == false) {
            this.rolePermissioinsData.forEach(x => {
                if (x.ModuleMastersId == permissionData.ModuleMastersId) {
                    x.CanEdit = false;
                    x.CanView = false;
                    x.CanDelete = false;
                    x.CanAdd = false;
                    this.updateCheckBoxes(x);
                }
            })
            let permissionObj = this.rolePermissioinsData.where(x => x.ModuleMastersId == permissionData.ModuleMastersId).first();
            this.checkFullControl(permissionObj);

        }
        else if ((permissionType == 'delete' || permissionType == 'edit' || permissionType == 'add') && permissionData.CanView == false && event.target.checked) {
            this.rolePermissioinsData.forEach(x => {
                if (x.ModuleMastersId == permissionData.ModuleMastersId) {
                    x.CanEdit = permissionType == 'edit' ? true : x.CanEdit;
                    x.CanAdd = permissionType == 'add' ? true : x.CanAdd;
                    x.CanView = true;
                    x.CanDelete = permissionType == 'delete' ? true : x.CanDelete;
                    this.updateCheckBoxes(x);
                }
            })
            let permissionObj = this.rolePermissioinsData.where(x => x.ModuleMastersId == permissionData.ModuleMastersId).first();
            this.checkFullControl(permissionObj);

        }
        else {
            this.rolePermissioinsData.forEach(x => {
                if (x.ModuleMastersId == permissionData.ModuleMastersId) {
                    x.CanAdd = permissionType == 'add' ? event.target.checked : x.CanAdd;
                    x.CanDelete = permissionType == 'delete' ? event.target.checked : x.CanDelete;
                    x.CanView = permissionType == 'view' ? event.target.checked : x.CanView;
                    x.CanEdit = permissionType == 'edit' ? event.target.checked : x.CanEdit;
                    this.updateCheckBoxes(x);
                }
            })
            let permissionObj = this.rolePermissioinsData.where(x => x.ModuleMastersId == permissionData.ModuleMastersId).first();
            this.checkFullControl(permissionObj);
        }

    }


    updateCheckBoxes(chkObj: RolePermissionViewModel) {
        let element: any;
        if (chkObj.CanEdit) {
            element = document.getElementById(chkObj.checkEditId);
            if (element = null) {
                element.classList.add("checked");
            }

        }
        if (!chkObj.CanEdit) {
            element = document.getElementById(chkObj.checkEditId);
            if (element = null) {
                element.classList.remove("checked");
            }
        }
        if (chkObj.CanAdd) {
            element = document.getElementById(chkObj.checkAddId);
            if (element = null) {
                element.classList.add("checked");
            }
        }
        if (!chkObj.CanAdd) {
            element = document.getElementById(chkObj.checkAddId);
            if (element = null) {
                element.classList.remove("checked");
            }
        }
        if (chkObj.CanDelete) {
            element = document.getElementById(chkObj.checkDeleteId);
            if (element = null) {
                element.classList.add("checked");
            }
        }
        if (!chkObj.CanDelete) {
            element = document.getElementById(chkObj.checkDeleteId);
            if (element = null) {
                element.classList.remove("checked");
            }
        }
        if (chkObj.CanView) {
            element = document.getElementById(chkObj.checkViewId);
            if (element = null) {
                element.classList.add("checked");
            }
        }
        if (!chkObj.CanView) {
            element = document.getElementById(chkObj.checkViewId);
            if (element = null) {
                element.classList.remove("checked");
            }
        }
    }

    getPermissions() {
        this.rolePermissionSearchFormGroup.submitted = true;
        this.titleToggle=[];
        if (this.rolePermissionSearchFormGroup.valid) {
            this.spin = true;
            this.isDataChanged = true;
            this.isForDepartment = (this.rolePermissionSearchFormGroup.value.userId == 0 || this.rolePermissionSearchFormGroup.value.userId == null) ? true : false;
            this.post({ path: 'api/SearchUsers/GetPermissionsByRole', body: { query: JSON.stringify(this.rolePermissionSearchFormGroup.value) } }).subscribe((x: any) => {
                this.spin = false;
                this.rolePermissioinsData = new List<RolePermissionViewModel>(x);
                this.rolePermissioinsData = this.rolePermissioinsData.distinctBy(x => x.ModuleMastersId);
                this.rolePermissioinsData.forEach(t => {
                    t.checkAddId = "chkAdd" + t.ModuleMastersId;
                    t.checkDeleteId = "chkDelete" + t.ModuleMastersId;
                    t.checkEditId = "chkEdit" + t.ModuleMastersId;
                    t.checkFCId = "chkFC" + t.ModuleMastersId;
                    t.checkViewId = "chkView" + t.ModuleMastersId;
                    if (((t.CanAdd &&
                        t.CanView &&
                        t.CanEdit &&
                        t.CanDelete) ||
                        (
                            (t.CanView && t.HasView && !t.HasAdd && !t.HasEdit && !t.HasDelete) ||
                            (t.CanAdd && !t.HasView && t.HasAdd && !t.HasEdit && !t.HasDelete) ||
                            (t.CanEdit && !t.HasView && !t.HasAdd && t.HasEdit && !t.HasDelete) ||
                            (t.CanView && t.CanEdit && t.HasView && !t.HasAdd && t.HasEdit && !t.HasDelete) ||
                            (t.CanView && t.CanDelete && t.HasView && !t.HasAdd && !t.HasEdit && t.HasDelete) ||
                            (t.CanView && t.CanEdit && t.CanDelete && t.HasView && !t.HasAdd && t.HasEdit && t.HasDelete)
                        ) &&
                        (t.FullControl == false || t.FullControl == undefined))) {
                        t.FullControl = true;
                    }
                })
                this.parentModules = new List<any>();

                this.parentModules = this.rolePermissioinsData;
                this.parentModules.forEach(z => {
                    if (z.ParentId == null) {
                        z.ParentId = z.ModuleMastersId;

                    }
                });
                this.parentModules = this.parentModules.distinctBy(y => y.ParentId);
             //   this.parentModules=this.parentModules.where(x=>x.DisplayOrder>0);
                this.parentModules= this.parentModules.orderBy(x=>x.DisplayOrder);
                for (let index = 0; index < this.parentModules.length; index++) {
                        this.titleToggle.push(this.titleCollapse);
                }
                this.parentModules.forEach(t => t.isCollapse = false);
                this.showDiv = true;

            })
        }
    }


    collapseDiv(divObj: any, index:number) {
        if (divObj.isCollapse == false) {
            document.getElementById(divObj.ParentId).style.display = "none";
            this.titleToggle[index]=this.titleExpand;
            this.parentModules.where(t => t.ParentId == divObj.ParentId).firstOrDefault().isCollapse = true;
        }
        else {
            document.getElementById(divObj.ParentId).style.display = "table-row-group";
            this.titleToggle[index]=this.titleCollapse;
            this.parentModules.where(t => t.ParentId == divObj.ParentId).firstOrDefault().isCollapse = false;
        }


    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    savePermissions() {

        let rolePermissionObj = new UserPermission();
        rolePermissionObj.applicationModuleId = 1;
        rolePermissionObj.rolePermissionsArray = new Array<RolePermissionViewModel>();
        rolePermissionObj.isForDepartment = true;
        rolePermissionObj.roleId = this.rolePermissionSearchFormGroup.value.roleId;
        this.rolePermissioinsData.forEach(x => {
            x.UserId = (this.rolePermissionSearchFormGroup.value.userId == null
                || this.rolePermissionSearchFormGroup.value.userId.toString() == ""
                || this.rolePermissionSearchFormGroup.value.userId == undefined) ? 0 : this.rolePermissionSearchFormGroup.value.userId;

            x.RoleId=this.rolePermissionSearchFormGroup.value.roleId;
            rolePermissionObj.rolePermissionsArray.push(x);
        })

        if (this.isForDepartment == true && this.rolePermissionSearchFormGroup.value.userId > 0) {
            if (this.rolePermissionSearchFormGroup.value.userId > 0) {
                rolePermissionObj.isForDepartment = false;
                rolePermissionObj.userId = this.rolePermissionSearchFormGroup.value.userId;
            }
            this.dialog.defaultConfig.text = {
                message: "Are you sure, you want to give default permissions to the selected user ?",
                ok: "Yes",
                save: "Yes",
                heading: "Set Permissions",
                dontSave: "No",
                cancel: "No"
            } as any;
            this.dialog.defaultConfig.showIcon = false;
            this.dialog.designClass.root = ["sweet-alert", "sweet-alert-info", "showSweetAlert", "visible"];
            this.dialog.show(this.dialog.defaultConfig, DialogViewMode.okWithCancel).then(t => {
                if (t === DialogEnum.Ok) {
                    this.dialog.hide();
                    this.spin = true;
                    this.post({ body: rolePermissionObj }).subscribe(x => {
                        this.spin = false;
                        this.toastr.success("Data Updated Successfully");
                        this.showDiv = false;
                        this.getPermissions();
                    })
                }
                this.dialog.hide()
            })
        }
        else {
            if (this.rolePermissionSearchFormGroup.value.userId > 0) {
                rolePermissionObj.isForDepartment = false;
                rolePermissionObj.userId = this.rolePermissionSearchFormGroup.value.userId;
            }
            this.spin = true;
            this.post({ body: rolePermissionObj }).subscribe(x => {
                this.spin = false;
                this.toastr.success("Data Updated Successfully");
                this.showDiv = false;
                this.getPermissions();
            })
        }
    }
    get componentName(): string {
        return "RolePermissionListComponent";
    }
}
