import {vBoutiquePictureRecordBase,} from '@app/database-models'
//Generated Imports
export class vBoutiquePictureRecord extends vBoutiquePictureRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}