import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Role, vCustomerRoleTypeLookUp, vCustomerRoleDomainLookUp } from '@app/models';
import { AbstractRole } from '../domain/abstract-role';
import { Router } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { RoleEditComponent } from '../edit/role-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@multilingual("RoleAddComponent")
@access({ accessLevel: RolePermissionEnums.UserRoles, action: "post" })

@Component({
    selector: "app-role-add",
    templateUrl: './role-add.component.html'
})
export class RoleAddComponent extends AbstractRole implements OnInit, OnDestroy {
    role: Role;
    subscription: any;
    roleEdit:any=RoleEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.UserRoles;
        this._this = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("User Roles - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.lookup([{ path: USER_LOOKUPS.customerRoleDomainLookUp, propName: "customerRoleDomainLookUps" }, { path: USER_LOOKUPS.customerRoleTypeLookUp, propName: "customerRoleTypeLookUps" }]).
            subscribe((lookups: any) => {
                this.roleLookups = lookups;
                this.role = new Role();
                this.role.statusId = StatusEnum.Active;
                this.role.roleId = 0;
                this.roleFormGroup = this.formBuilder.formGroup(this.role) as IFormGroup<Role>;
                this.showComponent = true;

            })
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    addRole(isRedirect:boolean) {
        this.roleFormGroup.submitted = true;
        if (this.roleFormGroup.valid) {
            this.spin=true;
            this.post({ body: this.roleFormGroup.value }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/roles', data]) : this.router.navigate(['/roles']);
            })
        }else{
            for (const key of Object.keys(this.roleFormGroup.controls)) {
                if (this.roleFormGroup.controls[key].invalid) {
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }
    get componentName(): string {
        return "RoleAddComponent";
    }
}
