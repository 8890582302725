import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim} from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventServiceTypesBase {

//#region serviceId Prop
        @prop()
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'EventServiceTypesListComponent_ServiceId_gh', keyColumn: true })
        eventServiceId : number;
//#endregion serviceId Prop


//#region serviceName Prop
        @required()
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'EventServiceTypesListComponent_EvntSer_Name_gh', keyColumn: false })
        eventServiceName : string;
//#endregion serviceName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}