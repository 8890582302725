import { Component, OnInit, OnDestroy, ComponentFactoryResolver, Input, EventEmitter } from "@angular/core"
import { Document } from "@app/models";
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe, PlatformLocation } from '@angular/common';
import { DocumentListModel } from '../../../../models/extended-models/DocumentListModel';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { SERVER_URL } from "src/app/domain/system-constant";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { GridColumnConfig } from '@rxweb/grid';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { HttpClient } from "@angular/common/http";
import { AbstractNoteDocument } from "../domain/abstract-note-document";
import { multilingual } from "@rxweb/localization";

@multilingual("NoteDocumentComponent")
@Component({
  selector: 'app-note-document',
  templateUrl: './note-document.component.html',
  styleUrls: ['./note-document.component.css']
})
export class NoteDocumentComponent extends AbstractNoteDocument implements OnInit, OnDestroy {
  documentListGridColumns: any[];
  files: File[] = [];
  paginationViewModel: PaginationViewModel;
  titleSearch: string;
  isDrag: boolean = false;
  public dragIconId: number;
  public dropTileId: number;
  serverUrl: string = SERVER_URL;
  titleSave: string;
  titleDelete: string;
  titleView: string;
  titleDownload: string;
  deleteHelp: string = '';
  copyHelp: string = '';
  isShowGridColumn: boolean = false;
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";
  @Input() showInnerSpin: boolean = true;
  @Input() disabled: boolean = false;
  @Input() noteDraftId: number = 0;
  _this: any;

  constructor(private formBuilder: RxFormBuilder, private router: Router, private activatedRoute: ActivatedRoute, browserStorage: BrowserStorage, componentFactoryResolver: ComponentFactoryResolver, modelView: ModalView, datePipe: DatePipe, blobState: BlobUploadsViewStateService, httpClient: HttpClient, location: PlatformLocation) {
    super(browserStorage, blobState, httpClient, location);
    this._this = this;
    this.datePipe = datePipe;
    this.modalView = modelView;
    this.modalView.setResolver(componentFactoryResolver);
    this.activatedRoute.params.subscribe(t => {
      if (t['id'] != null && t['id'] != undefined)
        this.id = t['id'];
    })
    this.deleteHelp = getLocalizedValue("Title_Delete");
    this.copyHelp = getLocalizedValue("Title_Copy");
  }

  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleSave = getLocalizedValue("Title_Save");
    this.titleDelete = getLocalizedValue("Title_Delete");
    this.titleView = getLocalizedValue("Title_View");
    this.titleDownload = getLocalizedValue("Title_Download");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.documentFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<Document> ;
    this.documentUpdateFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<Document>;
    this.paginationViewModel = new PaginationViewModel();
    this.paginationViewModel.sortOrder = "false";
    this.paginationViewModel.orderBy = "createdOn";
    this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
    //console.log(this.entityId)
    if (this.isEditorDocument == undefined)
      this.isEditorDocument = false;
    if (this.entityId != undefined && this.isEditorDocument) {
      this.id = this.entityId;
    }

    this.delete({ params: [1], body: null, queryParams: { isPicture: false, entityTypeId: this.entityTypeId, flag: true,isNoteDocumentComponent : true, "EntityId": this.entityId } }).subscribe(data => {
      this.get({
        path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
      }).subscribe((t: any) => {
        this.getColumnsData = t;
        if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
          this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
        }
        setTimeout(() => {
          this.bindGrid();
        }, 0);
      })
    });
    //this.bindGrid();
  }

  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.totalRecords = 0;
      this.json["searchString"] = value.target.value;
      this.json["pageIndex"] = 1;
      if (this.documentListGrid != null) {
        this.documentListGrid.storeProcedure.nextPage = 1;
      }
      this.bindGrid();
    }, 0);
    this.spin = true;
  }


  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  public onIconDropped(ev) {
    ev.drag.dropFinished();
  }

  public onEnterHandler(ev): void {
    this.dropTileId = parseInt(ev.owner.element.nativeElement.id, 10);
    // the event gets raised immediately, but we want to swap only when we drag over another icon
    if (this.dragIconId === this.dropTileId) {
      return;
    }
    const dragIndex = this.documentListModel.findIndex(
      (iconObj) => iconObj.pictureId === this.dragIconId
    );
    const dropIndex = this.documentListModel.findIndex(
      (iconObj) => iconObj.pictureId === this.dropTileId
    );
  }
  public dragStartHandler(id: string): void {
    this.dragIconId = parseInt(id, 10);
    //console.log('docstartId', this.dragIconId);

  }

  public dragEndHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'visible';
    //console.log('docEndId', this.dropTileId);


    this.documentUpdateFormGroup.patchValue({
      sourceId: this.dragIconId,
      destinationId: this.dropTileId,
      displayOrder: 1,
      entityId: this.id,
      entityTypeId: this.entityTypeId,
      isPicture: true,
      isDrag: true,
      noteId: this.noteDraftId,
      isNoteComponent: true
    })

    if (//this.documentUpdateFormGroup.controls.entityTypeId.value!=NotesEnum.NewsNotes &&
      this.documentUpdateFormGroup.controls.destinationId.value != null &&
      this.documentUpdateFormGroup.controls.destinationId.value != undefined) {
      this.put({ body: this.documentUpdateFormGroup.toFormData(), params: [0] }).subscribe(data => {
        this.spin = false;
        //this.baseToastr.success("Data Updated successfully");
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          this.baseToastr.success(existsAlert);
        }
        this.bindGrid();
      })
    }


  }

  public ghostCreateHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'hidden';
  }

  urlExistOrNot(variable) {
    var image = new Image();
    var url_image = './ImageFolder/' + variable + '.jpg';
    image.src = variable;
    if (image.width == 0) {
      return "../assets/images/dimond.jpg";
    } else {
      return variable;
    }
  }

  onSelect(event) {
    this.onFileChange(event.addedFiles, this.formBuilder);
  }

  onRemove(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  hasComment() {
    if (this.entityTypeId == NotesEnum.GemStoneNotes) {
      return false;
    }
    else {
      return true;
    }
  }
  fabergeNews() {
    if (this.entityTypeId == NotesEnum.NewsNotes) {
      return true;
    }
    else {
      return false;
    }
  }
  onCopy(url: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  onEdit(userProject: DocumentListModel) {
    this.spin = true;
    this.documentUpdateFormGroup.patchValue({
      documentId: userProject.documentId,
      createdOn: userProject.createdOn,
      documentDescription: userProject.documentDescription,
      fileName: userProject.fileName,
      entityId: userProject.entityId,
      entityTypeId: userProject.entityTypeId,
      noteId: userProject.noteId,
      seeOnline: userProject.seeOnline,
      isPicture: userProject.isPicture,
      createdBy: userProject.createdBy,
      displayOrder: userProject.displayOrder,
      isDrag: false
    })

    this.put({ body: this.documentUpdateFormGroup.toFormData(), params: [userProject.documentId] }).subscribe(data => {
      this.spin = false;
      //this.baseToastr.success("Data Updated successfully");
      var existsAlert = getLocalizedValue("Data_Updated");
      if (existsAlert) {
        this.baseToastr.success(existsAlert);
      }
      this.bindGrid();
    })
  }

  discriptionChange(discription: any, userProject: DocumentListModel) {
    userProject.documentDescription = discription.target.value;
  }

  displayOrderChange(order: any, userProject: DocumentListModel) {
    if (userProject.displayOrder == null) {
      userProject.displayOrder = 1;
    }
    else {
      userProject.displayOrder = order.target.value;
    }
  }

  get componentName(): string {
    return "NoteDocumentComponent";
  }

}
