import { gridColumn } from "@rxweb/grid"


export class vEventsLookUpBase {

//#region eventId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'eventId  ', keyColumn: true})
        eventId : number;
//#endregion eventId Prop


//#region eventName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'eventName', keyColumn: false})
        eventName : string;
//#endregion eventName Prop

}