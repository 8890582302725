import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductTemplateBase {

//#region productTemplateId Prop
        @prop()
        productTemplateId : number;
//#endregion productTemplateId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region templatePath Prop
        @required()
        @maxLength({value:200})
        templatePath : string;
//#endregion templatePath Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @required()
        updatedOn : Date;
//#endregion updatedOn Prop



}