import {CustomerRoleNoteTypeMappingBase,} from '@app/database-models'
//Generated Imports
export class CustomerRoleNoteTypeMapping extends CustomerRoleNoteTypeMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}