import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CollectionDesignModuleLocalizationBase {

//#region designModuleLocalizedId Prop
        @prop()
        designModuleLocalizedId : number;
//#endregion designModuleLocalizedId Prop


//#region designModuleId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        designModuleId : number;
//#endregion designModuleId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region imageAlt1 Prop
        @maxLength({value:500})
        imageAlt1 : string;
//#endregion imageAlt1 Prop


//#region imageAlt2 Prop
        @maxLength({value:500})
        imageAlt2 : string;
//#endregion imageAlt2 Prop


//#region imageAlt3 Prop
        @maxLength({value:500})
        imageAlt3 : string;
//#endregion imageAlt3 Prop


//#region imageAlt4 Prop
        @maxLength({value:500})
        imageAlt4 : string;
//#endregion imageAlt4 Prop


//#region imageAlt5 Prop
        @maxLength({value:500})
        imageAlt5 : string;
//#endregion imageAlt5 Prop


//#region contentText Prop
        @required()
        contentText : string;
//#endregion contentText Prop

}