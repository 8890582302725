<div *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_Events_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/events']" rxText="Label_Events_t"></a>
        </li>
        <li class="breadcrumb-item active">Add New</li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1" [rxSpinner]="spin">
    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5 rxText="EventAddComponent_Title_t"></h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="eventFormGroup"
        *ngIf="showComponent" style="padding-bottom:10rem;" [rxLocalisationInit]="componentName">
        <div class="row add-countries-form">
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Name_t"></label>
              <div class="col-md-8">
                <input formControlName="eventName" tabindex="1" type="text" class="form-control"
                  rxPlaceholder="EventAddEditComponent_Name_p" rxFocus />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.eventName.errors">{{eventFormGroup.controls.eventName.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Description_t"></label>
              <div class="col-md-8">
                <textarea formControlName="eventDescription" tabindex="4" rows="4" class="form-control"
                  rxPlaceholder="EventAddEditComponent_Description_p"></textarea>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.eventDescription.errors">{{eventFormGroup.controls.eventDescription.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Format_t"></label>
              <div class="col-md-8">
                <textarea formControlName="eventFormat" tabindex="7" rows="4" class="form-control"
                  rxPlaceholder="EventAddEditComponent_Format_p"></textarea>
                <small class="form-text text-danger d-none"
                  [class.d-block]="eventFormGroup.controls.eventFormat.errors">{{eventFormGroup.controls.eventFormat.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Location_t"></label>
              <div class="col-md-8">
                <input formControlName="location" tabindex="10" type="text" class="form-control "
                  rxPlaceholder="EventAddEditComponent_Location_p" />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.location.errors">{{eventFormGroup.controls.location.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Owner_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="eventLookup.eventOwnerLookUp" [controlTabIndex]="13" #rxSelect
                  mainClass="form-control" formControlName="reportFiledBy" [keyValueProps]="['fullName','userId']"
                  [selectPlaceholder]="'Select Event Owner'">
                </rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.reportFiledBy.errors">{{eventFormGroup.controls.reportFiledBy.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Group_Company_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="eventLookup.genfieldsGroupEntity" [controlTabIndex]="16" #rxSelect
                  mainClass="form-control" formControlName="gemfieldsGroupCompanyEventOwner" [keyValueProps]="['company','supplierId']"
                  [selectPlaceholder]="'Select Gemfields Group Entity'">
                </rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.gemfieldsGroupCompanyEventOwner.errors">{{eventFormGroup.controls.gemfieldsGroupCompanyEventOwner.errorMessage}}</small>
              </div>
            </div>
            
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_StartDate_t"></label>
              <div class="col-md-8">
                <rx-date showAddon="true" [controlTabIndex]="2" formControlName="eventDate"
                  placeholder="Select Start Date" pickerClass="form-control">
                </rx-date>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.eventDate.errors">{{eventFormGroup.controls.eventDate.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_EndDate_t"></label>
              <div class="col-md-8">
                <rx-date showAddon="true" [controlTabIndex]="5" formControlName="eventEndDate"
                  placeholder="Select End Date" pickerClass="form-control"></rx-date>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.eventEndDate.errors">{{eventFormGroup.controls.eventEndDate.errorMessage}}</small>
                <small class="form-text text-danger" *ngIf="dateError==true">Event End Date should be
                  greater than Event
                  Start Date<br /></small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Type_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="eventLookup.eventTypeLookUp" #rxSelect [controlTabIndex]="8"
                  mainClass="form-control" formControlName="eventType" [keyValueProps]="['eventTypeName','eventTypeId']"
                  [selectPlaceholder]="'Select Event Type'" (changed)="deliveryMethodValidation($event)"></rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.eventType.errors">{{eventFormGroup.controls.eventType.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Website_t"></label>
              <div class="col-md-8">
                <input formControlName="website" type="text" tabindex="11" class="form-control "
                  rxPlaceholder="EventAddEditComponent_Website_p" />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.website.errors">{{eventFormGroup.controls.website.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Indicator_t"></label>
              <div class="col-md-8">
                <input formControlName="eventIndicator" tabindex="14" type="text" class="form-control "
                  rxPlaceholder="EventAddEditComponent_Indicator_p" />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.eventIndicator.errors">{{eventFormGroup.controls.eventIndicator.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Country_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="eventLookup.countryLookUp" [controlTabIndex]="17" #rxSelect
                  mainClass="form-control" formControlName="countryId" [keyValueProps]="['countryName','countryId']"
                  [selectPlaceholder]="'Select Country'">
                </rx-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Department_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="eventLookup.departmentLookUp" [controlTabIndex]="19" #rxSelect
                  mainClass="form-control" formControlName="department" [keyValueProps]="['departmentName', 'departmentId']"
                  [selectPlaceholder]="'Select Department'">
                </rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.department.errors">{{eventFormGroup.controls.department.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Type_Of_Services_t"></label>
              <div class="col-md-8" [rxTagExtended]="_this">
                <rx-tag #rxTag [controlTabIndex]="20" [(source)]="eventLookup.eventServiceTypeLookUp"
                  formControlName="eventServiceTypeIds" mainClass="form-control" placeholder=" Select Types of Support / Services"
                  [isSort]="false" [keyValueProps]="['eventServiceName','eventServiceId']">
                </rx-tag>
              </div>
            </div>
            
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_CostPerGuest_t"></label>
              <div class="col-md-8">
                <input formControlName="costPerGuest" tabindex="3" type="text" class="form-control "
                  rxPlaceholder="EventAddEditComponent_CostPerGuest_p" />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.costPerGuest.errors">{{eventFormGroup.controls.costPerGuest.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Budget_t"></label>
              <div class="col-md-8">
                <input formControlName="budget" tabindex="6" type="text" class="form-control "
                  rxPlaceholder="EventAddEditComponent_Budget_p" />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.budget.errors">{{eventFormGroup.controls.budget.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_ActCost_t"></label>
              <div class="col-md-8">
                <input formControlName="actualCost" tabindex="9" type="text" class="form-control "
                  rxPlaceholder="EventAddEditComponent_ActCost_p" />
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.actualCost.errors">{{eventFormGroup.controls.actualCost.errorMessage}}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_SalesLeads_t"></label>
              <div class="col-md-8">
                <textarea formControlName="salesLeads" tabindex="12" class="form-control" rows="4"
                  rxPlaceholder="EventAddEditComponent_SalesLeads_p"></textarea>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.salesLeads.errors">{{eventFormGroup.controls.salesLeads.errorMessage}}</small>
              </div>
            </div>
           
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Picture_t"></label>
              <div class="col-md-8">
                <app-file [fileViewModel]="fileViewModel" [tabindex]="'15'" (files)="updateFiles($event)"
                  [title]="imageTooltip"></app-file>
                <img *ngIf="eventFormGroup.value.displayImage && eventFormGroup.value.fileData==null"
                  src="{{eventFormGroup.value.imageURL1}}" class="event-img  mt-2 upload-img-preview" />
                <img *ngIf="eventFormGroup.value.fileData!=null" [src]="getImgContent()"
                  class="event-img mt-2 upload-img-preview" />
                <ng-container *ngIf="eventFormGroup.value.displayImage || eventFormGroup.value.fileData!=null"
                  [rxLocalisationInit]="componentName">
                  <button (click)="deleteImage()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                </ng-container>
              </div>
            </div>
            <div class="form-group row" [formGroup]="eventFormGroup.controls.eventBooking">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="EventAddEditComponent_Booking_t"></label>
              <div class="col-md-8">
                <ng-container *ngIf="showDropdown==true && bookingLookup.length>0" [rxSelectExtended]="_this">
                  <rx-select [(source)]="bookingLookup" #rxSelect [controlTabIndex]="18" mainClass="form-control"
                    formControlName="bookingID" [selectPlaceholder]="'Select Event Booking'"
                    [keyValueProps]="['tripName','tripId']">
                  </rx-select>
                </ng-container>
                <button (click)="searchBookings()" class="btn btn-primary mr-1 mt-1"
                  title="You need to select Event Start date in order to search bookings"
                  rxText="Btn_Search_Booking_t"></button>
                <small class="form-text text-danger" *ngIf="bookingError==true">You need to select Event
                  Start date in
                  order to search bookings</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-auto" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
          <div class="hr-line-dashed border-top w-100 mb-2"></div>
          <div class="ibox-title col-12 bg-white px-0 border-bottom mb-4">
            <h5 class="ml-2" rxText="EventAddEditComponent_Training_Details_t"></h5>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Delivery_Method_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <!-- <input formControlName="deliveryMethod" type="text" tabindex="11" class="form-control "
                               rxPlaceholder="" placeholder="method of delivery" /> -->
                <rx-select [(source)]="eventLookup.eventTrainerDeliveryMethod" #rxSelect [controlTabIndex]="21"
                  mainClass="form-control" formControlName="deliveryMethod"
                  [keyValueProps]="['eventTrainingTypeName','eventTrainingTypeId']"
                  [selectPlaceholder]="localizationData.EventAddEditComponent_Delivery_Method_p_p">
                </rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="eventFormGroup.controls.deliveryMethod.errors">{{eventFormGroup.controls.deliveryMethod.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Risk_Category_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="eventLookup.eventEmpoyeeRiskCategory" #rxSelect [controlTabIndex]="22"
                  mainClass="form-control" formControlName="riskCategory"
                  [keyValueProps]="['eventTrainingTypeName','eventTrainingTypeId']"
                  [selectPlaceholder]="localizationData.EventAddEditComponent_Risk_Category_p_p">
                </rx-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-none d-xl-block col-xl-4 pr-xl-4">
            <div class="form-group row checkbox" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Attendance_Uploaded_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox tabindex="23" [checkFormControl]="eventFormGroup.controls.isAttendanceUploaded">
                    </app-checkbox>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Trainer_Details_t"></label>
              <div class="col-md-8">
                <textarea formControlName="trainerDetails" tabindex="24" rows="4" class="form-control"
                  rxPlaceholder="EventAddEditComponent_Trainer_Details_p"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Training_Area_Covered_t"></label>
              <div class="col-md-8" [rxTagExtended]="_this">
                <rx-tag [source]="eventLookup.eventTrainerCoveredArea" #rxTag mainClass="form-control"
                  formControlName="trainingAreas" [keyValueProps]="['eventTrainingTypeName','eventTrainingTypeId']"
                  placeholder="{{localizationData.EventAddEditComponent_Training_Area_Covered_p_p}}" [isSort]="false">
                </rx-tag>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row checkbox" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Included_In_Business_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox tabindex="25" [checkFormControl]="eventFormGroup.controls.isIncludeBusinessElement">
                    </app-checkbox>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-xl-none col-xl-4 pr-xl-4">
            <div class="form-group row checkbox" *ngIf="eventFormGroup.value.eventType == eventTypeEnum.Training" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="EventAddEditComponent_Attendance_Uploaded_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox tabindex="26" [checkFormControl]="eventFormGroup.controls.isAttendanceUploaded">
                    </app-checkbox>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button tabindex="27" (click)="addEvent(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button tabindex="28" (click)="addEvent(true)" class="btn btn-primary mr-1" *rxAuthorize="[eventEdit]"
              rxText="Btn_Save_Continue_Edit_t">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>