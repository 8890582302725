import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vStateProvinceBase {

//#region stateProvinceId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:0, allowSorting: true, headerKey: 'StateProvinceListComponent_StateProvinceId_gh', keyColumn: true})
        stateProvinceId : number;
//#endregion stateProvinceId Prop


//#region stateName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'StateProvinceListComponent_StateName_gh', keyColumn: false})
        stateName : string;
//#endregion stateName Prop


//#region abbreviation Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'StateProvinceListComponent_Abbreviation_gh', keyColumn: false})
        abbreviation : string;
//#endregion abbreviation Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'StateProvinceListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region countryName Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'StateProvinceListComponent_CountryName_gh', keyColumn: false})
        countryName : string;
//#endregion countryName Prop

}