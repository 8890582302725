import {vLocationCustomerTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vLocationCustomerTypeLookUp extends vLocationCustomerTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}