import { numeric, prop, required } from '@rxweb/reactive-form-validators';

export class ProductionFormBespokePieceMappingBase {
    //#region ProductionFormBespokePieceMappingID Prop
    @prop()
    productionFormBespokePieceMappingID: number;
    //#endregion ProductionFormBespokePieceMappingID Prop

   //#region productionOrderFormID Prop
   @required()
   @prop()
   productionOrderFormID: number;
   //#endregion productionOrderFormID Prop

     //#region bespokePieceID Prop
     @required()
     @prop()
     bespokePieceID: number;
     //#endregion bespokePieceID Prop

       //#region productVariantBatchID Prop
    @prop()
    productVariantBatchID: number;
    //#endregion productVariantBatchID Prop


}