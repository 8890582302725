import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneLaboratoryGemFieldBase {

//#region laboratoryID Prop
        @prop()
        laboratoryID : number;
//#endregion laboratoryID Prop


//#region laboratoryName Prop
        @required()
        @maxLength({value:50})
        laboratoryName : string;
//#endregion laboratoryName Prop



}