import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractEventCustomerMapping } from '../domain/abstract-event-customer-mapping';
import { EventCustomerMapping } from "@app/models";
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EventInviteesViewModel } from 'src/app/view-model/event-invitees-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { EventCustomerMappingEditComponent } from '../edit/event-customer-mapping-edit.component';
import { ProjectCorporateMappingAddComponent } from 'src/app/components/project/project-corporate-mappings/add/project-corporate-mapping-add.component';
import { GridColumnConfig } from '@rxweb/grid';

import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { appendPageFooter, removeScrollableFooter, removeFooter, getLocalizedValue, redirectOnCTRL, innerTextFromHtmlContent } from 'src/app/domain/common-logic/common-logic';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { prop, IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import * as CryptoJS from 'crypto-js';

import {
    Workbook
} from 'exceljs';
import {
    DatePipe, PlatformLocation
} from '@angular/common';
import * as fs from 'file-saver';
import * as moment from 'moment'
import { debounceTime } from 'rxjs/operators';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { table } from 'console';
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { EventCustomerMappingAddComponent } from "../add/event-customer-mapping-add.component";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { bindScroolEventInvitees } from "src/app/domain/customize-plugin/bind-scroll-on-search";
@multilingual("EventCustomerMappingListComponent")
@Component({
    selector: "app-event-customer-mapping-list",
    templateUrl: './event-customer-mapping-list.component.html'
})
export class EventCustomerMappingListComponent extends AbstractEventCustomerMapping implements OnInit, OnDestroy {
    eventInviteeList: EventInviteesViewModel[];
    subscription: any;
    id: number;
    total: number;
    inviteesCount: number;
    attendedCount: number;
    acceptedCount: number;
    involvedCount: number;
    eInviteCount: number;
    declinedCount: number;
    json: any;
    selectedInvitees: ProjectEventMappingViewModel[];
    rowCount: number = 25;
    pageIndex: number = 1;
    sortOrder: boolean = true;
    orderBy: string = 'fullName';
    totalRecords: number = 0;
    isFilter: boolean;
    inviteeData: EventCustomerMapping;
    updateSelectedInvitees: EventInviteesViewModel[];
    updateInviteeData: EventInviteesViewModel;
    inviteeObject: EventInviteesViewModel;
    statusLookup: any[];
    statusSearchModel: StatusModel;
    statusFormGroup: IFormGroup<StatusModel>;
    exportExcel = new ExportToExcelModel();
    list: any[];
    @Input() selectedTab: string = "";
    moduleID: number;
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    arrConfig: any[];
    scrollPosition: any;
    rowExpandFlag = false;
    rowExpandEver = false;
    eventData: any = {
        statusLookup: StatusLookup
    }
    private eventSubject: Subject<string> = new Subject<string>();
    titleSearch: string;
    titleGridColumnSetting: string;
    searchString : string;
    isHideAll: boolean = false;
    isShowAll: boolean = false;
    isLongTextExists: boolean = false;


    _this;

    constructor(private browserStorage: BrowserStorage, private activatedRoute: ActivatedRoute, private router: Router, modelView: ModalView, private formBuilder: RxFormBuilder,private location : PlatformLocation, blobState: BlobUploadsViewStateService,) {
        super(blobState);
        this.modalView = modelView;
        this.searchString = "";
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.eventInviteeList = new Array<EventInviteesViewModel>();
        this.selectedInvitees = new Array<ProjectEventMappingViewModel>();
        this.updateSelectedInvitees = new Array<EventInviteesViewModel>();
        Window["EventCustomerMappingListComponent"] = this;
      this.moduleID = RolePermissionEnums.Event;
      location.onPopState(() => this.dialog.hide());

    }

    ngOnInit(): void {
        this.titleSearch = getLocalizedValue("Title_Search");
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.showComponent = false;
        this.isFilter = false;
        this.sort = true;
        this.totalRecords = 0;
        // this.statusLookup = new Array<StatusLookup>();
        // let status = new StatusLookup();
        // status.statusId = 0;
        // status.status = "All";
        // this.statusLookup.push(status);
        // status = new StatusLookup();
        // status.statusId = 1;
        // status.status = "Invited";
        // this.statusLookup.push(status);
        // status = new StatusLookup();
        // status.statusId = 2;
        // status.status = "Accepted";
        // this.statusLookup.push(status);
        // status = new StatusLookup();
        // status.statusId = 3;
        // status.status = "Declined";
        // this.statusLookup.push(status);
        // status = new StatusLookup();
        // status.statusId = 4;
        // status.status = "Attended";
        // this.statusLookup.push(status);
        // status = new StatusLookup();
        // status.statusId = 5;
        // status.status = "Send e-Invitation";
        // this.statusLookup.push(status);
        // status = new StatusLookup();
        // status.statusId = 6;
        // status.status = "Involved";
        // this.statusLookup.push(status);
        this.get({ path: 'api/MasterLookups/InviteStatusLookup' }).subscribe((t: any) => {
            this.statusLookup = t;
            this.statusSearchModel = new StatusModel();
            this.statusSearchModel.statusId = 0;
            this.statusFormGroup = this.formBuilder.formGroup(StatusModel, this.statusSearchModel) as IFormGroup<StatusModel>;
            this.showComponent = true;
            this.eventSubject.pipe(debounceTime(300)).subscribe(t => {
                this.filterEvents();
            })
            this.updateSelectedInvitees.splice(this.updateSelectedInvitees.findIndex(x => x.userId > 0), 1);
            this.json = JSON.parse(JSON.stringify(
                {
                    userId: this.browserStorage.local.get("userId") == undefined ? 0 : Number(this.browserStorage.local.get("userId")),
                    eventId: this.id,
                    name: '',
                    rowCount: PaginationEnum.RowCount,
                    pageIndex: PaginationEnum.PageIndex,
                    sortOrder: this.sortOrder,
                    orderBy: this.orderBy,
                    searchString: this.searchString//,
                    //clientUrl:CLIENT_URL
                }));

            this.get({
                path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
            }).subscribe((t: any) => {
                this.getColumnsData = t;
                if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                    this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
                }
            })
            setTimeout(() => {
                this.bindGrid();
            }, 0);
        })

    }

    onKeyPress() {
        this.eventSubject.next();
    }

    getColumnConfig()
    {
      this.get({
        path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
      }).subscribe((t: any) => {
          this.getColumnsData = t;
          if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
              this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
          }
          this.bindGrid();
      })
    }
    onCheckboxSelect(t, x, y, z) {
      this.spin = true;
        this.scrollPosition = document.getElementsByClassName('scrollTable')[0].scrollTop;
        let scrollPositionRow = window.scrollY;
        let deleteInvitee = new ProjectEventMappingViewModel();
        if (!x.target.checked) {
            if (this.selectedInvitees.findIndex(x => x.projectID == t.userId) == -1) {
                deleteInvitee.projectID = t.userId
                deleteInvitee.eventCustomerId = t.eventCustomerId
                this.selectedInvitees.push(deleteInvitee);
            }
        }
        else {
            if (this.selectedInvitees.findIndex(x => x.projectID == t.userId) != -1) {
                this.selectedInvitees.splice(this.selectedInvitees.indexOf(t.userId), 1);
            }
        }
        this.eventInviteeList.forEach(b => {
            if (b.eventCustomerId == t.eventCustomerId) {
                b.isChecked = x.target.checked;
            }
        })
        // this.eventInviteeGrid.updateSource([]);
        // this.eventInviteeGrid.updateSource(this.eventInviteeList);

        this.clearGrid();
        this.updateEventAttendeesList()
        this.get({
          path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
          this.getColumnsData = t;
          if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
              this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
          }

            this.eventInviteeGrid = new AppGrid(this.eventInviteeList, EventInviteesViewModel, {
                actions: {
                    onEditData: this.onEdit.bind(this),
                    onOneLink: this.redirectToUser.bind(this),
                    onData: this.addGuests.bind(this),
                    onCorporateLink: this.redirectToCorporate.bind(this),
                    onCheckBoxSelect: this.onCheckboxSelect.bind(this),
                    onEInviteSelect: this.sendEInviteStatus.bind(this),
                    onInvolveSelect: this.involvedStatus.bind(this),
                    onGridSelect: this.inviteeStatus.bind(this),
                    onRowExpand: this.RowExpand.bind(this),
                    onMoreClick: this.onInviteesMoreClick.bind(this),
                    onMoreProfileClick:this.onProfileMoreClick.bind(this),
                    onMoreDietaryInfoClick:this.onMoreDietaryInfoClick.bind(this)

                }
            });
            if (this.arrConfig != undefined && this.arrConfig != null) {
              this.arrConfig.forEach(x => {
                  this.eventInviteeGrid.gridColumns.forEach(y => {
                      if (y.name == x.ColumnName)
                          y.visible = x.Visible;
                  });
              });
              this.gridColumnConfig = this.eventInviteeGrid.gridColumns;
              this.gridColumnSettings.emit(this.gridColumnConfig)
            }
            this.eventInviteeGrid.storeProcedure = {
                length: this.totalRecords,
                onPageChanging: this.onPageChanging.bind(this),
                nextPage: this.json['pageIndex'],
                onPageSorting: this.onPageSorting.bind(this),
            }
            this.eventInviteeGrid.currentPage = this.json['pageIndex'];
            this.eventInviteeGrid.pagingSource.splice(this.eventInviteeGrid.pagingSource.findIndex(x => x == this.json['rowCount']), 1);
            this.eventInviteeGrid.pagingSource.unshift(this.json['rowCount']);
            this.eventInviteeGrid.maxPerPage = this.json['rowCount'];
            this.eventInviteeGrid.componentId = this.componentName;
            this.eventInviteeGrid.designClass.tableClass = "scrollTable table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.eventInviteeGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.applyClass();
            this.eventInviteeGrid.design(document.getElementById("eventInviteeGrid"));
            removeScrollableFooter("eventInviteeGrid");
            setTimeout(() => {
                bindScroolEventInvitees(scrollPositionRow)
                document.getElementsByClassName('scrollTable')[0].scrollTop = this.scrollPosition;
              this.spin = false;
            }, 100);
          });
    }

    updateEventAttendeesList()
    {
      if(this.updateSelectedInvitees.length > 0)
      {
        this.updateSelectedInvitees.forEach(element=>{
          var index = this.eventInviteeList.findIndex(x=>x.eventCustomerId == element.eventCustomerId);
          if(index != -1)
          {
            this.eventInviteeList[index].guestCount = element.guestCount;
            this.eventInviteeList[index].attended = element.attended;
            this.eventInviteeList[index].declined = element.declined;
            this.eventInviteeList[index].invited = element.invited;
            this.eventInviteeList[index].accepted = element.accepted;
            this.eventInviteeList[index].status = element.status;
            this.eventInviteeList[index].involved = element.involved;
            this.eventInviteeList[index].isEventOrganiser = element.isEventOrganiser;
            this.eventInviteeList[index].sendEInvitation = element.sendEInvitation;
          }
        })
      }
    }
    updateCheckedEventAttendeesList()
    {
      if(this.selectedInvitees.length > 0)
      {
        this.selectedInvitees.forEach(element=>{
          var index = this.eventInviteeList.findIndex(x=>x.eventCustomerId == element.eventCustomerId);
          if(index != -1)
          {
            this.eventInviteeList[index].isChecked = false
          }
        })
      }
    }

    bindGrid(updateListFlag : boolean = false ) {
        this.spin = true;
        this.isShowGrid = true;
        this.total = 0;
        this.acceptedCount = 0;
        this.attendedCount = 0;
        this.inviteesCount = 0;
        this.declinedCount = 0;
        this.involvedCount = 0;
        this.eInviteCount = 0;
        this.eventInviteeList = new Array<EventInviteesViewModel>();

        this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((response: any) => {
            this.eventInviteeList = response;
            if (this.eventInviteeList[0] != undefined) {
                this.total = this.eventInviteeList[0].totalCount;
                this.acceptedCount = this.eventInviteeList[0].acceptedCount;
                this.attendedCount = this.eventInviteeList[0].attendedCount;
                this.inviteesCount = this.eventInviteeList[0].inviteeCount;
                this.declinedCount = this.eventInviteeList[0].declinedCount;
                this.involvedCount = this.eventInviteeList[0].involvedCount;
                this.eInviteCount = this.eventInviteeList[0].eInviteCount;
            }

            this.eventInviteeList.forEach(x => {
                x.status = (x.invited == true ? 'Invited, ' : '')
                    + (x.isEventOrganiser == true ? 'Involved in Organising the Event, ' : '')
                    + (x.accepted == true ? 'Accepted, ' : '')
                    + (x.attended == true ? 'Attended, ' : '')
                    + (x.sendEInvitation == true ? 'Send E-Invitation, ' : '')
                    + (x.declined == true ? 'Declined ' : '');
                x.isChecked = true;

                if (x.companyDetails != null && x.companyDetails != undefined && x.companyDetails.length > 0 && x.companyId == 0) {
                    x.company = this.formCompanyTable(x);
                }

                if(x.directorshipDetails != null && x.directorshipDetails != undefined && x.directorshipDetails.length > 0) {
                    x.directorship = this.formDirectorshipTable(x);
                }
                else {
                    x.directorship = '<table></table>'
                }

                if (x.knownToDetail != null && x.knownToDetail != undefined && x.knownToDetail.length > 1) {
                    x.knownTo = this.formKnownToTable(x);
                }
            })
            if (this.eventInviteeList.length > 0) {
                this.totalRecords = this.eventInviteeList[0].totalRecordsCount;
                this.selectedInvitees = new Array<ProjectEventMappingViewModel>();
                this.updateSelectedInvitees = new Array<EventInviteesViewModel>();
                this.isNoRecordFound = false;
                this.eventInviteeGrid = new AppGrid(this.eventInviteeList, EventInviteesViewModel, {
                    actions: {
                        onEditData: this.onEdit.bind(this),
                        onOneLink: this.redirectToUser.bind(this),
                        onData: this.addGuests.bind(this),
                        onCorporateLink: this.redirectToCorporate.bind(this),
                        onCheckBoxSelect: this.onCheckboxSelect.bind(this),
                        onEInviteSelect: this.sendEInviteStatus.bind(this),
                        onInvolveSelect: this.involvedStatus.bind(this),
                        onGridSelect: this.inviteeStatus.bind(this),
                        onRowExpand: this.RowExpand.bind(this),
                        onMoreClick: this.onInviteesMoreClick.bind(this),
                        onMoreProfileClick:this.onProfileMoreClick.bind(this),
                        onMoreDietaryInfoClick:this.onMoreDietaryInfoClick.bind(this)
                    }
                });

                if (this.arrConfig != undefined && this.arrConfig != null) {
                    this.arrConfig.forEach(x => {
                        this.eventInviteeGrid.gridColumns.forEach(y => {
                            if (y.name == x.ColumnName)
                                y.visible = x.Visible;
                        });
                    });
                    this.gridColumnConfig = this.eventInviteeGrid.gridColumns;
                    this.gridColumnSettings.emit(this.gridColumnConfig)
                }

                this.eventInviteeGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: this.json['pageIndex'],
                    onPageSorting: this.onPageSorting.bind(this),
                }
                this.eventInviteeGrid.currentPage = this.json['pageIndex'];
                //this.eventInviteeGrid.pagingSource.splice(this.eventInviteeGrid.pagingSource.findIndex(x => x == this.json['rowCount']), 1);
                //this.eventInviteeGrid.pagingSource.unshift(this.json['rowCount']);
                this.eventInviteeGrid.maxPerPage = this.json['rowCount'];
                this.eventInviteeGrid.componentId = this.componentName;
                this.eventInviteeGrid.designClass.tableClass = "scrollTable table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
                this.eventInviteeGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.applyClass();

                this.eventInviteeGrid.design(document.getElementById("eventInviteeGrid"));
                setTimeout(() => {
                  if (this.isShowGrid) {

                    if (!this.isFilter) {
                        removeFooter("eventCustomerTable");
                      appendPageFooter("eventCustomerTable");
                    }
                    else {
                      removeFooter("eventCustomerTable");
                      appendPageFooter("eventCustomerTable");
                      removeScrollableFooter("eventInviteeGrid");
                    }
                    this.spin = false;
                  }
                  else
                  {
                    this.spin = false;
                  }
                }, 750);
                this.checkForLongTexts();
            }
            else {
                this.isShowGrid = false;
                this.isFilter = false;
                removeFooter("eventCustomerTable");
                this.isNoRecordFound = true;
                this.spin = false;
            }
        });
    }

    onInviteesMoreClick(t, x) {
        var element = document.getElementById("note_invitees_" + t.noteId);
        var maskedElement = document.getElementById("maskednote_invitees_" + t.noteId);

        if (this.rowExpandEver) {
            if (this.rowExpandFlag) {
                x.srcElement.innerText = "Show Less";
                x.srcElement.className = "less-btn"
                element.classList.add("show");
                maskedElement.setAttribute("style", "display:none");
            }
            else {
                element.classList.remove("show");
                x.srcElement.innerText = "Show More";
                x.srcElement.className = "more-btn";
                maskedElement.setAttribute("style", "display:inline");
            }
        }
        else {
            if (element.className == "collapse") {
                x.srcElement.innerText = "Show Less";
                x.srcElement.className = "less-btn"
                element.classList.add("show");
                maskedElement.setAttribute("style", "display:none");

            }
            else {
                element.classList.remove("show");
                x.srcElement.innerText = "Show More";
                x.srcElement.className = "more-btn";
                maskedElement.setAttribute("style", "display:inline");
            }
        }

    }

    onProfileMoreClick(t, x) {
        var element = document.getElementById("profile_about_" + t.userId);
        var maskedElement = document.getElementById("maskedprofile_about_" + t.userId);

        if (this.rowExpandEver) {
            if (this.rowExpandFlag) {
                x.srcElement.innerText = "Show Less";
                x.srcElement.className = "less-btn"
                element.classList.add("show");
                maskedElement.setAttribute("style", "display:none");
            }
            else {
                element.classList.remove("show");
                x.srcElement.innerText = "Show More";
                x.srcElement.className = "more-btn";
                maskedElement.setAttribute("style", "display:inline");
            }
        }
        else {
            if (element.className == "collapse") {
                x.srcElement.innerText = "Show Less";
                x.srcElement.className = "less-btn"
                element.classList.add("show");
                maskedElement.setAttribute("style", "display:none");

            }
            else {
                element.classList.remove("show");
                x.srcElement.innerText = "Show More";
                x.srcElement.className = "more-btn";
                maskedElement.setAttribute("style", "display:inline");
            }
        }

    }

    onMoreDietaryInfoClick(t, x) {
        var element = document.getElementById("dietary_info_" + t.userId);
        var maskedElement = document.getElementById("maskedDietary_info" + t.userId);

        if (this.rowExpandEver) {
            if (this.rowExpandFlag) {
                x.srcElement.innerText = "Show Less";
                x.srcElement.className = "less-btn"
                element.classList.add("show");
                maskedElement.setAttribute("style", "display:none");
            }
            else {
                element.classList.remove("show");
                x.srcElement.innerText = "Show More";
                x.srcElement.className = "more-btn";
                maskedElement.setAttribute("style", "display:inline");
            }
        }
        else {
            if (element.className == "collapse") {
                x.srcElement.innerText = "Show Less";
                x.srcElement.className = "less-btn"
                element.classList.add("show");
                maskedElement.setAttribute("style", "display:none");

            }
            else {
                element.classList.remove("show");
                x.srcElement.innerText = "Show More";
                x.srcElement.className = "more-btn";
                maskedElement.setAttribute("style", "display:inline");
            }
        }
    }

    redirectToUser(invitee: EventInviteesViewModel) {
        // this.router.navigate(['/users', invitee.userId], { queryParams: { activeTab: 'user-edit' } })
        let url: any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/users', invitee.userId], { queryParams: { activeTab: 'user-edit' } }));
        redirectOnCTRL(url, this.router, event);


    }

    addGuests(x, y, z, u, invitee: EventInviteesViewModel) {
        // if (x.isChecked) {
            this.updateInviteeData = new EventInviteesViewModel();
            if (this.updateSelectedInvitees.findIndex(t => t.eventCustomerId == x.eventCustomerId) != -1) {
                this.updateSelectedInvitees.forEach(t => {
                    if (t.eventCustomerId == x.eventCustomerId) {
                        t.guestCount = Number(y.target.value);
                    }
                })
            } else {
                let inviteeData = new EventInviteesViewModel();
                inviteeData = this.eventInviteeList.filter(t => t.eventCustomerId == x.eventCustomerId)[0];
                this.setEventCustomerObject(inviteeData);
                this.inviteeObject.guestCount = Number(y.target.value);
                this.updateSelectedInvitees.push(this.inviteeObject);
            }
        // }
    }

    clearGrid() {
        if (this.isShowGrid && this.eventInviteeGrid != undefined && this.eventInviteeGrid.source.length > 0) {
            this.eventInviteeGrid.refresh("eventInviteeGrid");
        }
    }

    redirectToCorporate(invitee: EventInviteesViewModel) {
        //this.router.navigate(['/suppliers', invitee.userId], { queryParams: { activeTab: 'supplier-edit' } })
        let url: any;
        url = this.router.serializeUrl(this.router.createUrlTree(['/suppliers', invitee.userId], { queryParams: { activeTab: 'supplier-edit' } }));
        redirectOnCTRL(url, this.router, event);
    }

    sendEInviteStatus(x, y, z, u, invitee: EventInviteesViewModel) {
        // if (x.isChecked) {
            this.updateInviteeData = new EventInviteesViewModel();
            if (this.updateSelectedInvitees.findIndex(t => t.eventCustomerId == x.eventCustomerId) != -1) {
                this.updateSelectedInvitees.forEach(t => {
                    if (t.eventCustomerId == x.eventCustomerId) {
                        t.sendEInvitation = y.target.checked;
                    }
                })
            } else {
                let inviteeData = new EventInviteesViewModel();
                inviteeData = this.eventInviteeList.filter(t => t.eventCustomerId == x.eventCustomerId)[0];
                this.setEventCustomerObject(inviteeData);
                this.inviteeObject.sendEInvitation = y.target.checked;
                this.updateSelectedInvitees.push(this.inviteeObject);
            }
        // }


    }

    getInviteeStatus(value: any) {
        if (value == "2") {
            this.updateInviteeData.attended = true;

        }
        else if (value == "3") {
            this.updateInviteeData.declined = true;
        }
        else if (value == "1") {
            this.updateInviteeData.invited = true;
        }
        else if (value == "4") {
            this.updateInviteeData.accepted = true;
        }
        else {
            this.updateInviteeData.attended = false;
            this.updateInviteeData.accepted = false;
            this.updateInviteeData.invited = false;
            this.updateInviteeData.declined = false;
        }
    }

    inviteeStatus(x, y, z, u, invitee: EventInviteesViewModel) {
        // if (x.isChecked) {
            this.updateInviteeData = new EventInviteesViewModel();
            this.updateInviteeData.attended = null;
            this.updateInviteeData.accepted = null;
            this.updateInviteeData.invited = null;
            this.updateInviteeData.declined = null;
            this.getInviteeStatus(y.target.value);
            if (this.updateSelectedInvitees.findIndex(t => t.eventCustomerId == x.eventCustomerId) != -1) {
                this.updateSelectedInvitees.forEach(t => {
                    if (t.eventCustomerId == x.eventCustomerId) {
                        t.attended = this.updateInviteeData.attended;
                        t.accepted = this.updateInviteeData.accepted;
                        t.declined = this.updateInviteeData.declined;
                        t.invited = this.updateInviteeData.invited;
                    }
                })
            } else {
                let inviteeData = this.eventInviteeList.filter(t => t.eventCustomerId == x.eventCustomerId)[0];
                this.setEventCustomerObject(inviteeData);
                this.inviteeObject.sendEInvitation = y.target.checked;
                if (y.target.value == "0") {
                    this.inviteeObject.attended = false;
                    this.inviteeObject.accepted = false;
                    this.inviteeObject.invited = false;
                    this.inviteeObject.declined = false;
                }
                else {
                    this.inviteeObject.attended = y.target.value == "2" ? true : null;
                    this.inviteeObject.accepted = y.target.value == "4" ? true : null;
                    this.inviteeObject.invited = y.target.value == "1" ? true : null;
                    this.inviteeObject.declined = y.target.value == "3" ? true : null;
                }

                this.updateSelectedInvitees.push(this.inviteeObject);
            }
        // }

    }

    eventFilter() {
        this.json['inviteeStatus'] = this.statusFormGroup.value.statusId == 0 ? "" : this.statusFormGroup.value.statusId;
    }

    filterEvents() {
        this.isFilter = true;
        //  this.eventInviteeGrid.updateSource([]);
        this.clearGrid();
        this.totalRecords = 0;
        this.getColumnConfig();
    }


    setEventCustomerObject(invitee: EventInviteesViewModel) {
        this.inviteeObject = new EventInviteesViewModel();
        this.inviteeObject.company = invitee.company;
        this.inviteeObject.directorship = invitee.directorship;
        this.inviteeObject.declined = invitee.declined;
        this.inviteeObject.accepted = invitee.accepted;
        this.inviteeObject.attended = invitee.attended;
        this.inviteeObject.invited = invitee.invited;
        this.inviteeObject.inviteeCount = invitee.inviteeCount;
        this.inviteeObject.totalCount = invitee.totalCount;
        this.inviteeObject.declined = invitee.declined;
        this.inviteeObject.accepted = invitee.accepted;
        this.inviteeObject.involved = invitee.involved;
        this.inviteeObject.isEventOrganiser = invitee.isEventOrganiser;
        this.inviteeObject.sendEInvitation = invitee.sendEInvitation;
        this.inviteeObject.salesAdvisorId = invitee.salesAdvisorId;
        this.inviteeObject.status = invitee.status;
        this.inviteeObject.acceptedCount = invitee.acceptedCount;
        this.inviteeObject.attendedCount = invitee.attendedCount;
        this.inviteeObject.declinedCount = invitee.declinedCount;
        this.inviteeObject.eInviteCount = invitee.eInviteCount;
        this.inviteeObject.email = invitee.email;
        this.inviteeObject.eventCustomerId = invitee.eventCustomerId;
        this.inviteeObject.eventId = invitee.eventId;
        this.inviteeObject.userId = invitee.userId;
        this.inviteeObject.isChecked = invitee.isChecked;
        this.inviteeObject.isMapped = invitee.isMapped;
        this.inviteeObject.involvedCount = invitee.involvedCount;
        this.inviteeObject.introducesBy = invitee.introducesBy;
        this.inviteeObject.inviteeCount = invitee.inviteeCount;
        this.inviteeObject.guestCount = invitee.guestCount;
    }


    involvedStatus(x, y, z, u, invitee: EventInviteesViewModel) {
        // if (x.isChecked) {
            this.updateInviteeData = new EventInviteesViewModel();
            if (this.updateSelectedInvitees.findIndex(t => t.eventCustomerId == x.eventCustomerId) != -1) {
                this.updateSelectedInvitees.forEach(t => {
                    if (t.eventCustomerId == x.eventCustomerId) {
                        t.isEventOrganiser = y.target.checked;
                    }
                })
            } else {
                let inviteeData = this.eventInviteeList.filter(t => t.eventCustomerId == x.eventCustomerId)[0];
                this.setEventCustomerObject(inviteeData);
                this.inviteeObject.isEventOrganiser = y.target.checked;
                this.updateSelectedInvitees.push(this.inviteeObject);
            }
        // }
    }



    onEdit(x, eventInvitee: EventInviteesViewModel) {
        let eventCustomerMapping = new EventCustomerMapping();

        if (x.isChecked) {
            eventCustomerMapping.EventInvitees = this.updateSelectedInvitees.filter(t => t.eventCustomerId == x.eventCustomerId);
            eventCustomerMapping.addedBy = 1;
            eventCustomerMapping.customerID = 1;
            eventCustomerMapping.eventCustomerID = 1;
            eventCustomerMapping.eventID = this.id;
            eventCustomerMapping.EventInvitees.forEach(t => {
                t.guestCount = t.guestCount == null ? 0 : t.guestCount;

            })

            if (eventCustomerMapping.EventInvitees != null && eventCustomerMapping.EventInvitees.length > 0) {
                this.spin = true;

                if (eventCustomerMapping.EventInvitees[0].guestCount.toString().length > 9) {
                    var existsAlert = getLocalizedValue("Number_Validation");
                    if (existsAlert) {
                        alert(existsAlert);
                    }
                    this.spin = false;
                }

                else if (isNaN(eventCustomerMapping.EventInvitees[0].guestCount) || !!(eventCustomerMapping.EventInvitees[0].guestCount % 1)) {
                    var existsAlert = getLocalizedValue("Number_Required");
                    if (existsAlert) {
                        alert(existsAlert);
                    }
                    this.spin = false;
                }


                else {

                    this.put({ body: eventCustomerMapping, params: [x.eventCustomerId] }).subscribe(data => {
                        this.spin = false;
                        //this.toastr.success("Data Updated Successfully");
                        var existsAlert = getLocalizedValue("Data_Updated");
                        if (existsAlert) {
                            this.toastr.success(existsAlert);
                        }
                        this.updateSelectedInvitees.splice(this.updateSelectedInvitees.findIndex(t => t.eventCustomerId == x.eventCustomerId), this.updateSelectedInvitees.filter(t => t.eventCustomerId == x.eventCustomerId).length);
                        this.isFilter = true;
                        //  this.eventInviteeGrid.updateSource([]);

                        this.clearGrid();
                        this.getColumnConfig();
                    })
                }
            }

        }
        else {
            if (this.selectedInvitees.findIndex(z => z.projectID == x.userId) != -1) {
                let eventCustomerMapping = new EventCustomerMapping();
                eventCustomerMapping.EventInvitees = [];
                eventCustomerMapping.addedBy = 1;
                eventCustomerMapping.customerID = 1;
                eventCustomerMapping.eventCustomerID = 132;
                eventCustomerMapping.eventID = this.id;
                eventCustomerMapping.otherPeopleInvited = 1;
                eventCustomerMapping.PeopleEventInput = this.selectedInvitees.filter(t => t.projectID == x.userId);
                this.spin = true;



                this.put({ body: eventCustomerMapping, params: [eventCustomerMapping.eventCustomerID] }).subscribe(data => {
                    this.spin = false;
                    //this.toastr.success("Data Updated Successfully");
                    var existsAlert = getLocalizedValue("Data_Updated");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.isFilter = true;
                    let i = 0;
                    this.json = JSON.parse(JSON.stringify({ eventId: this.id, name: '', rowCount: 25, pageIndex: 1, sortOrder: this.sortOrder, orderBy: this.orderBy, searchString: this.searchString }));
                    // this.eventInviteeGrid.updateSource([]);

                    this.clearGrid();
                    this.updateSelectedInvitees = [];
                    this.eventInviteeList = [];
                    this.selectedInvitees = [];
                    this.totalRecords = 0;
                    this.bindGrid();
                })

            }
        }

    }


    applyVisible(gridColumnConfig: GridColumnConfig[]) {
        if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
                gridColumnConfig.forEach(y => {
                    if (y.name == x.ColumnName)
                        x.Visible = y.visible;
                });
            });
        }
        let count: number;
        count = this.eventInviteeGrid.gridColumns.filter(x => x.parameter == false && x.visible == false).length;
        if (count > 3) {
            //this.eventInviteeGrid.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable border-bottom table-list".split(' ');
            this.eventInviteeGrid.designClass.tableClass = "scrollTable table table-height table-striped table-hover dataTables-example dataTable border-bottom table-responsive table-list".split(" ");
            this.eventInviteeGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
        else {
            //this.eventInviteeGrid.designClass.tableClass = "table table-striped table-hover dataTables-example dataTable text-center border-bottom table-list".split(' ');
            this.eventInviteeGrid.designClass.tableClass = "scrollTable table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
            this.eventInviteeGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
        this.checkForLongTexts();
          this.eventInviteeGrid.reDesign();
        removeScrollableFooter("eventInviteeGrid");
        // setTimeout(()=>{
        //     if(this.isShowGrid){
        //       if(!this.isFilter){
        //           appendPageFooter("eventCustomerTable");
        //         }
        //         else{
        //             removeScrollableFooter("eventInviteeGrid");
        //         }
        //     }

        // },400);
    }

    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }

    addPersonOrCorporate(type: number) {
        this.modalView.show(ProjectCorporateMappingAddComponent, { mapId: this.id, inviteedArray: this.eventInviteeList, componentType: 'events', involved: false, peopleOrCorporate: type, hasBoughtProductFlag : true }).then(t => {
            this.isFilter = this.isNoRecordFound == true ? false : true;
            this.clearGrid();
            this.bindGrid();
        });
    }
    importInviteesAttendees()
    {
      this.modalView.show(EventCustomerMappingAddComponent,{eventId : this.id}).then(data=>{
        this.isFilter = this.isNoRecordFound == true ? false : true;
        this.clearGrid();
        this.bindGrid();
      })
    }
    search(value) {
        this.isFilter = this.isNoRecordFound == true ? false : true;
        this.totalRecords = 0;
        setTimeout(() => {
            this.spin = false;
            this.searchString = value.target.value.replace(/^\s+|\s+$/gm, '');
            this.json['searchString'] = value.target.value.replace(/^\s+|\s+$/gm, '');
            this.json['pageIndex'] = 1;
            this.totalRecords = 0;
            this.clearGrid();
            this.getColumnConfig();
        }, 4000);
        this.spin = true;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json['sortOrder'] = !this.sort;
        this.sort = !this.sort;
        this.json['orderBy'] = x;
        this.eventInviteeGrid.storeProcedure.nextPage = z;
        //  this.eventInviteeGrid.updateSource([]);

        this.clearGrid();
        this.eventInviteeList.splice(0, this.eventInviteeList.length);
        this.eventInviteeList = null;
        this.getColumnConfig();
    }

    onPageChanging(x) {
        this.isFilter = false;
        this.json['pageIndex'] = x;
        this.json['rowCount'] = this.eventInviteeGrid.maxPerPage;
        this.eventInviteeGrid.storeProcedure.nextPage = x;
        //  this.eventInviteeGrid.updateSource([]);
        removeFooter("eventCustomerTable");
        this.clearGrid();
        this.eventInviteeList.splice(0, this.eventInviteeList.length);
        this.eventInviteeList = null;
        this.getColumnConfig();
    }

    checkForLongTexts() {
        this.isLongTextExists = false;
        let isDescriptionCheckForNote = false;
        let isDescriptionCheckForProfile = false;
        let isDescriptionCheckForDietary = false;

        this.eventInviteeGrid.gridColumns.forEach(column => {
          if (column.name == "latestNote" && column.visible) isDescriptionCheckForNote = true;
          if(column.name == "profile" && column.visible) isDescriptionCheckForProfile = true;
          if(column.name == "dietaryInfo" && column.visible) isDescriptionCheckForDietary = true;
        });

        if (isDescriptionCheckForNote || isDescriptionCheckForProfile || isDescriptionCheckForDietary ) {
          for (let element of this.eventInviteeList) {
              if(isDescriptionCheckForNote && element.noteCharCount > 100){
                this.isLongTextExists = true;
                break;
              }
              if(isDescriptionCheckForProfile && element.profileCharCount > 100){
                this.isLongTextExists = true;
                break;
              }
              if(isDescriptionCheckForDietary && element.dietaryCharCount > 100){
                this.isLongTextExists = true;
                break;
              }
          }
        }
      }

    showOrHideAll() {
        this.isShowAll = !this.isShowAll;
        this.isFilter = false;
        this.eventInviteeGrid.destroy();
        this.eventInviteeGrid = null;
        this.json["isShowAll"] = this.isShowAll;
        this.json["pageIndex"] = 1;
        this.bindGrid();
      }

    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Event_Invitee");
        if (existsAlert) {
            return existsAlert;
        }
    }

    update() {
        if (this.selectedInvitees.length > 0) {
            this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
                if (t === DialogEnum.Ok) {
                    this.dialog.hide();
                    //this.getColumnConfig();
                    this.updateInvitee();
                }
                this.dialog.hide()
            })
        }
        else {
            //this.getColumnConfig();
            this.updateInvitee();
        }
    }

    updateInvitee() {
        // this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
        //     if (t === DialogEnum.Ok) {
        //         this.dialog.hide();
        let eventCustomerMapping = new EventCustomerMapping();
        eventCustomerMapping.EventInvitees = this.updateSelectedInvitees;
        eventCustomerMapping.addedBy = 1;
        eventCustomerMapping.customerID = 1;
        eventCustomerMapping.eventCustomerID = 132;
        eventCustomerMapping.eventID = this.id;
        eventCustomerMapping.otherPeopleInvited = 1;
        this.selectedInvitees.forEach(x => {
            this.updateSelectedInvitees.splice(this.updateSelectedInvitees.findIndex(t => t.userId == x.projectID), 1)
        })
        eventCustomerMapping.PeopleEventInput = this.selectedInvitees;

        if (this.updateSelectedInvitees.length > 0 || this.selectedInvitees.length > 0) {
            this.spin = true;
            if (this.updateSelectedInvitees.length > 0) {
                if (eventCustomerMapping.EventInvitees[0].guestCount.toString().length > 9) {
                    var existsAlert = getLocalizedValue("Number_Validation");
                    if (existsAlert) {
                        alert(existsAlert);
                    }
                    this.spin = false;
                }

                else if (isNaN(eventCustomerMapping.EventInvitees[0].guestCount) || !!(eventCustomerMapping.EventInvitees[0].guestCount % 1) && (eventCustomerMapping.EventInvitees[0].guestCount.toString().length <= 9)) {
                    var existsAlert = getLocalizedValue("Number_Required");
                    if (existsAlert) {
                        alert(existsAlert);
                    }
                    this.spin = false;
                }
                else {
                    this.put({ body: eventCustomerMapping, params: [eventCustomerMapping.eventCustomerID] }).subscribe(data => {
                        this.spin = false;
                        //this.toastr.success("Data Updated Successfully");
                        var existsAlert = getLocalizedValue("Data_Updated");
                        if (existsAlert) {
                            this.toastr.success(existsAlert);
                        }
                        this.isFilter = true;
                        let i = 0;
                        this.json = JSON.parse(JSON.stringify({ eventId: this.id, name: '', rowCount: 25, pageIndex: 1, sortOrder: this.sortOrder, orderBy: this.orderBy,searchString: this.searchString}));
                        // this.eventInviteeGrid.updateSource([]);
                        this.clearGrid();
                        this.updateSelectedInvitees = [];
                        this.eventInviteeList = [];
                        this.selectedInvitees = [];
                        this.totalRecords = 0;
                        //this.bindGrid();
                        this.getColumnConfig();
                    })
                }
            }
            else {
                this.put({ body: eventCustomerMapping, params: [eventCustomerMapping.eventCustomerID] }).subscribe(data => {
                    this.spin = false;
                    //this.toastr.success("Data Updated Successfully");
                    var existsAlert = getLocalizedValue("Data_Updated");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.isFilter = true;
                    let i = 0;
                    this.json = JSON.parse(JSON.stringify({ eventId: this.id, name: '', rowCount: 25, pageIndex: 1, sortOrder: this.sortOrder, orderBy: this.orderBy, searchString: this.searchString}));
                    // this.eventInviteeGrid.updateSource([]);
                    this.clearGrid();
                    this.updateSelectedInvitees = [];
                    this.eventInviteeList = [];
                    this.selectedInvitees = [];
                    this.totalRecords = 0;
                    this.getColumnConfig();
                    //this.bindGrid();
                })
            }
        }
        //     }
        //     this.dialog.hide()
        // })
    }

    exportToExcel() {
        //this.spin = true;
        if (this.eventInviteeList != null && this.eventInviteeList.length > 0) {
            var TodayDate = new Date();
            let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
            this.exportExcel.FileName = "Invitees&Attendees" + MMDDYY;
            this.exportExcel.Type = "Excel";
            this.exportExcel.Description = "Invitees & Attendees in Events"
            this.exportExcel.EntityUrl = window.location.href;
            this.exportExcel.EntityId = this.id;
            this.json['rowCount'] = this.eventInviteeList[0].totalCount;
            //this.json['searchString'] = "";
            this.json['pageIndex'] = 1;
            this.spin = true;
            this.post({ path: 'api/SearchEvent/EventInviteesExcel', body: { query: JSON.stringify(this.json) } }).subscribe((response: any) => {
                this.list = response;
                let returnedRequestData = this.requestExcelData(this.list, this.eventInviteeGrid.gridColumns);
                // console.log(returnedRequestData);
                // return false;
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet();
                // Cell Style : Fill and Header
                let columnHeaders = [];
                this.eventInviteeGrid.gridColumns.forEach(e => {
                    if (e.visible && (e.name != "isChecked" && e.name != "action" && e.name != "eventCustomerId")) {
                        columnHeaders.push(
                            { header: this.getGridColumnName(e.name), key: this.getGridColumnName(e.name), width: 20 }
                        )
                    }
                    else if(!e.visible && e.name == "lastName")
                    {
                        columnHeaders.push(
                            { header: this.getGridColumnName(e.name), key: this.getGridColumnName(e.name), width: 20 }
                        )
                    }
                    else if(!e.visible && e.name == "MiddleName")
                    {
                        columnHeaders.push(
                            { header: this.getGridColumnName(e.name), key: this.getGridColumnName(e.name), width: 20 }
                        )
                    }
                }, this);
                this.ExcelColumns.forEach(e => {
                    columnHeaders.push(
                        { header: this.getGridColumnName(e), key: this.getGridColumnName(e), width: 20 }
                    )
                });
                worksheet.columns = columnHeaders;
                this.rowCount = 1;
                const objKeys = Object.keys(returnedRequestData[0]);
                for (let index = 0; index < returnedRequestData.length; index++) {
                    let row = {};
                    objKeys.forEach(key => {
                        if (key == "LatestNote") {
                            row[key] = innerTextFromHtmlContent(returnedRequestData[index]["LatestNote"]);
                        } else if (key != "") {
                            row[key] = returnedRequestData[index][key];
                        }
                    });
                    worksheet.addRow(row);
                }
                worksheet.getRow(1).eachCell((cell, number) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: '00000000'
                        },
                        bgColor: {
                            argb: '00000000'
                        },
                    };
                    cell.font = {
                        color: {
                            argb: 'FFFFFFFF',
                        },
                        bold: true
                    }
                    cell.border = {
                        top: {
                            style: 'thin'
                        },
                        left: {
                            style: 'thin'
                        },
                        bottom: {
                            style: 'thin'
                        },
                        right: {
                            style: 'thin'
                        }
                    };
                });


                setTimeout(() => {
                    workbook.xlsx.writeBuffer().then(async (y: any) => {

                        var u8 = new Uint8Array(y);
                        var b64encoded = btoa(this.Uint8ToString(u8));

                        const blob = new Blob([y], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        });

                        let uploadedBlobName = await this.uploadToBlob(blob, "xlsx");
                        // this.exportExcel.Result = b64encoded;
                        this.exportExcel.BlobFileName = uploadedBlobName;
                        this.post({ body: this.exportExcel, path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
                            this.spin = false;
                            fs.saveAs(blob, Response);

                        })
                    });
                }, 1000);
            })
        }
    }

    generatePiecesExcel() {
        this.spin = true;
        let returnedRequestData = this.requestExcelData(this.list, this.eventInviteeGrid.gridColumns);
        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet();
        // Cell Style : Fill and Header
        var TodayDate = new Date();
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
        var FileName = "EventAttendees" + MMDDYY + "-" + this.id;
        this.exportExcel.FileName = "Invitees&Attendees" + MMDDYY;
        this.exportExcel.Type = "Excel";
        this.exportExcel.Description = "Invitees & Attendees in Events"
        this.exportExcel.EntityUrl = window.location.href;
        this.exportExcel.EntityId = this.id;
        // const headerRow = worksheet.addRow(header);
        this.rowCount = 1;
        worksheet.columns = [
            { header: 'FullName', key: 'FullName', width: 20 },
            { header: 'GuestCount', key: 'GuestCount', width: 10, style: { alignment: { horizontal: 'right' } } },
            { header: 'Email', key: 'Email', width: 15 },
            { header: 'Corporate Group With Job Title', key: 'Corporate Group With Job Title', width: 25 },
            { header: 'Introduced By', key: 'Introduced By', width: 20 },
            { header: 'Sales Advisor', key: 'Sales Advisor', width: 20 },
            { header: 'Profile', key: 'Profile', width: 20 },
            { header: 'Latest Manual Note', key: 'Latest Manual Note', width: 30 },
            { header: 'Dietary Info', key: 'Dietary Info', width: 20 },
            { header: 'Known To', key: 'Known To', width: 20 },
            { header: 'Invited', key: 'Invited', width: 10 },
            { header: 'Accepted', key: 'Accepted', width: 10 },
            { header: 'Declined', key: 'Declined', width: 10 },
            { header: 'Attended', key: 'Attended', width: 10 },
            { header: 'SendEInvitation', key: 'SendEInvitation', width: 20 },
            { header: 'Involved in Organising the Event?', key: 'Involved in Organising the Event?', width: 10 }
        ];

        returnedRequestData.forEach(x => {
            worksheet.addRow(x);
        });
        worksheet.getRow(1).eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '00000000'
                },
                bgColor: {
                    argb: '00000000'
                },
            };
            cell.font = {
                color: {
                    argb: 'FFFFFFFF',
                },
                bold: true
            }
            cell.border = {
                top: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            };
        });


        setTimeout(() => {
            this.spin = false;
            workbook.xlsx.writeBuffer().then((y: any) => {

                var u8 = new Uint8Array(y);
                var b64encoded = btoa(this.Uint8ToString(u8));

                const blob = new Blob([y], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                this.exportExcel.Result = b64encoded;
                this.post({ body: this.exportExcel, path: "api/SearchUsers/ExportExcel" }).subscribe(Response => {
                    fs.saveAs(blob, Response);

                })
            });
        }, 1000);


    }

    Uint8ToString(u8a) {
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return c.join("");
    }

    RowExpand(x, y, z) {
        debugger;
        //y.srcElement.className = y.srcElement.tagName.toLowerCase() == "i" ? "fa fa-plus fa-4x font-md text-default" : y.srcElement.className;
        //y.srcElement.className = y.srcElement.tagName.toLowerCase() == "i" ? "fa fa-minus fa-4x font-md text-default" : y.srcElement.className;

        // if(this.rowExpandFlag)
        // {
        //     this.rowExpandFlag = false;
        // }
        // else
        // {
        //     this.rowExpandFlag = true;
        // }
        // this.rowExpandEver = true;
        // this.onInviteesMoreClick.bind(x);

        // this.rowExpandEver = false;
        //this.element= y.srcElement.className;
        this.expandTable(x.eventCustomerId, "companyTable", y);
        this.expandTable(x.eventCustomerId, "directorshipTable",y);
        this.expandTable(x.eventCustomerId, "", y);

    }

    expandTable(id: number, column: string, y: any = null) {
        if (column == "companyTable") {
            var element = document.getElementById("company-id" + id.toString());
            //var iconElement = document.getElementById("companyIcon" + id.toString());
        }
        else if(column == "directorshipTable") {
            var element = document.getElementById("directorship-id" + id.toString());
        }
        else {
            var element = document.getElementById("knownTo-id" + id.toString());
            //var iconElement = document.getElementById("knownToIcon" + id.toString());
        }
        if (element != null) {
            if (element.style.display == "none") {
                element.style.display = "block";
                //if (iconElement != null) {
                //iconElement.className = "fa fa-chevron-up mr-2"
                if (y != null)
                    //y.srcElement.className= "fa fa-minus fa-4x font-md text-default"
                    y.srcElement.className = y.srcElement.tagName.toLowerCase() == "i" ? "fa fa-minus fa-4x font-md text-default" : y.srcElement.className;
                y.srcElement.setAttribute("title", (y.srcElement.tagName.toLowerCase() == "i" ? getLocalizedValue("Title_Collapse") : getLocalizedValue("Title_EventExpand")));
                //}

            }
            else {
                element.style.display = "none";
                //if (iconElement != null) {
                //iconElement.className = "fa fa-chevron-down mr-2"
                if (y != null)
                    //y.srcElement.className = "fa fa-plus fa-4x font-md text-default"
                    y.srcElement.className = y.srcElement.tagName.toLowerCase() == "i" ? "fa fa-plus fa-4x font-md text-default" : y.srcElement.className;
                y.srcElement.setAttribute("title", (y.srcElement.tagName.toLowerCase() == "i" ? getLocalizedValue("Title_EventExpand") : getLocalizedValue("Title_Collapse")));

                //}

            }
        }
    }

    formCompanyTable(item: EventInviteesViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table><tbody>";
        let subTable: any;
        subTable = "<tr id='company-id" + item.eventCustomerId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.companyDetails != null && item.companyDetails != undefined) {
            // item.companyDetails.forEach(x=>{
            //     row=row+"<tr><td><a class='text-blue font-weight-bold' href='"+x.companyUrl+"'>"+x.company+"</a>"+x.jobTitle+" - "+x.companyStatus+"</td></tr>";
            // })

            for (let i = 0; i < item.companyDetails.length; i++) {
                let x: any = item.companyDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.eventCustomerId + "'";
                    //row = row + "<tr><td ><a class='text-blue font-weight-bold' href='" + item.companyDetails[i].companyUrl + "'>" + item.companyDetails[i].company + "</a>" + item.companyDetails[i].jobTitle + " - <span class='mr-2'>" + item.companyDetails[i].companyStatus + "</span><i class='fa fa-chevron-down' id='companyIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"companyTable\")'></i></td></tr>";
                    // row = row + "<tr><td ><a class='text-blue font-weight-bold' href='" + item.companyDetails[i].companyUrl + "'>" + item.companyDetails[i].company + "</a>" + item.companyDetails[i].jobTitle + " - <span class='mr-2'>" + item.companyDetails[i].companyStatus + "</span><i id='companyIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"companyTable\")'></i></td></tr>";

                    // row = row + "<tr><td ><a class='" + ${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} + " font-weight-bold' href='" + item.companyDetails[i].companyUrl + "'>" + item.companyDetails[i].company + "</a>" +
                    // item.companyDetails[i].jobTitle + " - <span class='mr-2'>" + item.companyDetails[i].companyStatus + "</span><i id='companyIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"companyTable\")'></i></td></tr>";

                    row = row + `<tr><td><a class='${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' href='${ item.companyDetails[i].companyUrl }'>${ item.companyDetails[i].company }</a>
                                ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default; data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
                                ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy.replaceAll("'",'&#39;') + "' id='" + x.companyId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.companyId + "," + x.companyIntroducesBy.replaceAll("'",'&#39;') + ")'></i>": ""}
                                ${item.companyDetails[i].jobTitle}  - <span class='mr-2'> ${item.companyDetails[i].companyStatus} </span><i id='companyIcon"  ${ item.eventCustomerId } "' onclick='Window.EventCustomerMappingListComponent.expandTable(" ${ item.eventCustomerId }  + ",\"companyTable\")'></i></td></tr>`;

                }
                else {

                    subRow = subRow + `<tr><td><a class='${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' href=' ${ item.companyDetails[i].companyUrl } '>${ item.companyDetails[i].company }</a>
                                      ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default; data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
                                      ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy.replaceAll("'",'&#39;') + "' id='" + x.companyId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.companyId + "," + x.companyIntroducesBy.replaceAll("'",'&#39;') + ")'></i>": ""}
                                      ${item.companyDetails[i].jobTitle} - <span class='mr-2'> ${item.companyDetails[i].companyStatus} </span>`;
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;

    }

    formDirectorshipTable(item: EventInviteesViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table><tbody>";
        let subTable: any;
        subTable = "<tr id='directorship-id" + item.eventCustomerId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.directorshipDetails != null && item.directorshipDetails != undefined) {
            // item.companyDetails.forEach(x=>{
            //     row=row+"<tr><td><a class='text-blue font-weight-bold' href='"+x.companyUrl+"'>"+x.company+"</a>"+x.jobTitle+" - "+x.companyStatus+"</td></tr>";
            // })

            for (let i = 0; i < item.directorshipDetails.length; i++) {
                let x: any = item.directorshipDetails[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.eventCustomerId + "'";
                    //row = row + "<tr><td ><a class='text-blue font-weight-bold' href='" + item.companyDetails[i].companyUrl + "'>" + item.companyDetails[i].company + "</a>" + item.companyDetails[i].jobTitle + " - <span class='mr-2'>" + item.companyDetails[i].companyStatus + "</span><i class='fa fa-chevron-down' id='companyIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"directorshipTable\")'></i></td></tr>";
                    // row = row + "<tr><td ><a class='text-blue font-weight-bold' href='" + item.directorshipDetails[i].companyUrl + "'>" + item.directorshipDetails[i].company + "</a>" + item.directorshipDetails[i].jobTitle + " - <span class='mr-2'>" + item.directorshipDetails[i].companyStatus + "</span><i id='companyIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"directorshipTable\")'></i></td></tr>";

                    row = row + `<tr><td><a class='${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' href=' ${ item.directorshipDetails[i].companyUrl } '>${ item.directorshipDetails[i].company }</a>
                    ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default; data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
                    ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy.replaceAll("'",'&#39;') + "' id='" + x.companyId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.companyId + "," + x.companyIntroducesBy.replaceAll("'",'&#39;') + ")'></i>": ""}
                    ${item.directorshipDetails[i].jobTitle}  - <span class='mr-2'> ${item.directorshipDetails[i].companyStatus} </span><i id='companyIcon"  ${ item.eventCustomerId } "' onclick='Window.EventCustomerMappingListComponent.expandTable(" ${ item.eventCustomerId }  + ",\"companyTable\")'></i></td></tr>`;

                }
                else {
                    // subRow = subRow + "<tr><td><a class='text-blue font-weight-bold' href='" + item.directorshipDetails[i].companyUrl + "'>" + x.company + "</a>" + item.directorshipDetails[i].jobTitle + " - " + item.directorshipDetails[i].companyStatus + "</td></tr>";

                    subRow = subRow + `<tr><td><a class='${x.companyContactPermissionRequired == 1 ? 'text-red' : 'text-blue'} font-weight-bold' href=' ${ item.directorshipDetails[i].companyUrl } '>${ item.directorshipDetails[i].company }</a>
                    ${x.isSupplierUnwelcome == 1 ? "<span class='personaNonGrataToolTip' style='font-size: 0.75rem; margin-left: 0.15rem; cursor: default; data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t") +"' id='personaNonGrataToolTip' (mouseenter)='showPersonaNonGrataToolTip()'>\u26D4</span>": ""}
                    ${x.companyContactPermissionRequired == 1 ? "<i class='fa fa-exclamation-circle text-red' data-toggle='tooltip' data-placement='right' data-html='true' title='" + getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + x.companyIntroducesBy.replaceAll("'",'&#39;') + "' id='" + x.companyId + "' style='margin-left: 0.15rem;'  (mouseenter)='bindTooltip(" + x.companyId + "," + x.companyIntroducesBy.replaceAll("'",'&#39;') + ")'></i>": ""}
                    ${item.directorshipDetails[i].jobTitle} - <span class='mr-2'> ${item.directorshipDetails[i].companyStatus} </span>`;
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;

    }



    formKnownToTable(item: EventInviteesViewModel): any {
        let tabledata: any;
        let row: string = "";
        let subRow: any = "";
        tabledata = "<div><table><tbody>";
        let subTable: any;
        subTable = "<tr id='knownTo-id" + item.eventCustomerId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
        if (item.knownToDetail != null && item.knownToDetail != undefined) {
            // item.companyDetails.forEach(x=>{
            //     row=row+"<tr><td><a class='text-blue font-weight-bold' href='"+x.companyUrl+"'>"+x.company+"</a>"+x.jobTitle+" - "+x.companyStatus+"</td></tr>";
            // })

            for (let i = 0; i < item.knownToDetail.length; i++) {
                let x: any = item.knownToDetail[i];
                if (i == 0) {
                    let id: string;
                    id = "'" + item.eventCustomerId + "'";
                    //row = row + "<tr><td ><a class='text-blue font-weight-bold' href='" + item.knownToDetail[i].knownToURL + "'>" + item.knownToDetail[i].knownToName + "</a>" + " <span class='mr-2'>" + "</span><i class='fa fa-chevron-down' id='knownToIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"knownToTable\")'></i></td></tr>";
                    row = row + "<tr><td ><a class='text-blue font-weight-bold' href='" + item.knownToDetail[i].knownToURL + "'>" + item.knownToDetail[i].knownToName + "</a>" + " <span class='mr-2'>" + "</span><i id='knownToIcon" + item.eventCustomerId + "' onclick='Window.EventCustomerMappingListComponent.expandTable(" + item.eventCustomerId + ",\"knownToTable\")'></i></td></tr>";

                }
                else {

                    subRow = subRow + "<tr><td><a class='text-blue font-weight-bold' href='" + item.knownToDetail[i].knownToURL + "'>" + x.knownToName + "</a>" + "</td></tr>";
                }
            }
            if (subRow != "") {
                subTable = subTable + subRow + "</tbody></table></div></td></tr>";
                row = row + subTable;
            }
            tabledata = tabledata + row + "</tbody></table></div>"
        }
        return tabledata;
    }

    requestExcelData(usersGrid: EventInviteesViewModel[], gridColumns: any[]) {
        var listObject = usersGrid.map(function (obj) {
            if (gridColumns != undefined && gridColumns != null) {
                var objNew: any = {}
                gridColumns.forEach(e => {
                    if (e.visible && (e.name != "isChecked" && e.name != "action" && e.name != "eventCustomerId" && e.name != "status")) {
                        objNew[this.getGridColumnName(e.name)] = obj[e.name];
                    }
                    else if (e.visible && e.name == "status") {
                        objNew[this.getGridColumnName(e.name)] = this.eventCustomerStatus(obj);
                    }
                    else if (!e.visible && e.name == "lastName") {
                        objNew[this.getGridColumnName(e.name)] = obj[e.name];
                    }
                    else if (!e.visible && e.name == "MiddleName") {
                        objNew[this.getGridColumnName(e.name)] = obj[e.name];
                    }

                }, this);
                this.ExcelColumns.forEach(e => {
                    objNew[this.getGridColumnName(e)] = obj[e];
                });
                return objNew;
            } else {
                return obj;
            }
        }, this)
        // var list = new List<any>(listObject);
        return listObject;
        // let userListData = new Array<SaleSuccessData>();

        // usersGrid.forEach(element => {
        //     let listData = new SaleSuccessData();
        //     listData["FullName"] = element.fullName;
        //     listData["GuestCount"] = element.guestCount.toString();
        //     listData["Email"] = element.email;
        //     listData["Corporate Group With Job Title"] = element.company;
        //     listData["Introduced By"] = element.introducesBy;
        //     listData["Sales Advisor"] = element.salesAdvisorId;
        //     listData["Profile"] = element.profile;
        //     listData["Dietary Info"] = element.dietaryInfo;
        //     listData["Known To"] = element.knownTo;
        //     listData["Invited"] = element.invited == true ? "True" : "False";
        //     listData["Accepted"] = element.accepted == true ? "True" : "False";
        //     listData["Declined"] = element.declined == true ? "True" : "False";
        //     listData["Attended"] = element.attended == true ? "True" : "False";
        //     listData["SendEInvitation"] = element.sendEInvitation == true ? "True" : "False";
        //     listData["Involved"] = element.involved == true ? "True" : "False";
        //     var latestNoteData = document.createElement("DIV");
        //     latestNoteData.innerHTML = element.latestNote;
        //     listData["Latest Manual Note"] = latestNoteData.innerText || latestNoteData.textContent || '';
        //     //  listData["Booking Details"]=element.bookingData;





        //     //   listData["Supplier"]=element.supplier;


        //     //   listData["Upcycled"]=element.isUpcycled==true?"True":"False";
        //     //   listData["Bespoke"]=element.isBespoke==true?"True":"False";


        //     userListData.push(listData);

        // });
        // return userListData;
    }
    getGridColumnName(key: string) {
        var localizationData = (MultiLingualData.get(this.componentName) as any);
        switch (key) {
            case "company":
                return localizationData.EventCustomerMappingListComponent_Company_ex_ex;
            case "directorship":
                return localizationData.EventCustomerMappingListComponent_Directorship_ex_ex;
            case "fullName":
                return localizationData.EventCustomerMappingListComponent_Name_ex_ex;
            case "lastName":
                return localizationData.EventCustomerMappingListComponent_LastName_ex_ex;
            case "MiddleName":
                return localizationData.EventCustomerMappingListComponent_MiddleName_ex_ex;
            case "guestCount":
                return localizationData.EventCustomerMappingListComponent_GuestCount_ex_ex;
            case "email":
                return localizationData.EventCustomerMappingListComponent_Email_ex_ex;
            case "introducesBy":
                return localizationData.EventCustomerMappingListComponent_IntroBy_ex_ex;
            case "salesAdvisorId":
                return localizationData.EventCustomerMappingListComponent_SalesAdvisor_ex_ex;
            case "rating":
                return localizationData.EventCustomerMappingListComponent_Rating_ex_ex;
            case "profile":
                return localizationData.EventCustomerMappingListComponent_Profile_ex_ex;
            case "latestNote":
                return localizationData.EventCustomerMappingListComponent_LatestNote_ex_ex;
            case "dietaryInfo":
                return localizationData.EventCustomerMappingListComponent_DietInfo_ex_ex;
            case "knownTo":
                return localizationData.EventCustomerMappingListComponent_KnownTo_ex_ex;
            case "status":
                return localizationData.EventCustomerMappingListComponent_Status_ex_ex;
            case "isEventOrganiser":
                return localizationData.EventCustomerMappingListComponent_Involved_ex_ex;
            case "sendEInvitation":
                return localizationData.EventCustomerMappingListComponent_SendEInv_ex_ex;
            case "invited":
                return localizationData.EventCustomerMappingListComponent_Invited_ex_ex;
            case "accepted":
                return localizationData.EventCustomerMappingListComponent_Accepted_ex_ex;
            case "declined":
                return localizationData.EventCustomerMappingListComponent_Declined_ex_ex;
            case "attended":
                return localizationData.EventCustomerMappingListComponent_Attended_ex_ex;
            default:
                return ""
        }
    }
    ExcelColumns = [
        "invited",
        "accepted",
        "declined",
        "attended"
    ];
    public downloadDocument(data: any, fileName: string, fileType: string) {
        var binaryString = window.atob(data.result);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var link = document.createElement('a');
        var blob = new Blob([bytes], { type: fileType });
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

    }
    get componentName(): string {
        return "EventCustomerMappingListComponent";
    }
    eventCustomerStatus(obj: any) {
        var status = "";
        if (obj.invited) {
            status = 'Invited'
        }
        if (obj.isEventOrganiser) {
            status = status == "" ? "Involved in Organising the Event" : status + ", Involved in Organising the Event"
        }
        if (obj.accepted) {
            status = status == "" ? "Accepted" : status + ", Accepted"
        }
        if (obj.attended) {
            status = status == "" ? "Attended" : status + ", Attended"
        }
        if (obj.declined) {
            status = status == "" ? "Declined" : status + ", Declined"
        }
        if (obj.sendEInvitation) {
            status = status == "" ? "Send E-Invitation" : status + ", Send E-Invitation"
        }
        return status;
    }
}

class StatusLookup {
    @prop()
    statusId: number;
    @prop()
    status: string;
}
class StatusModel {
    @prop()
    statusId: number;
}
class ExportToExcelModel {
    Result: string;
    Type: string;
    FileName: string;
    Description: string;
    BlobFileName:string;
    EntityId:number;
    EntityUrl:string;
}
