<div class="modal-dialog modal-lg h-75" *rxMultilingual="">
  <div class="modal-content" [rxLocalisationInit]="componentName">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0">
        <label rxText="SystemHelpViewComponent_Title_t"></label>
      </h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-0 h-75">

      <!-- <br>
        {{systemHelp.endUserHelp}}
        <br>
        {{systemHelp.technicalHelp}} -->
      <div class="tabs-container people-tabs h-100">
        <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-slider" role="tablist">
          <li>
            <a class="nav-link" [class.active]="selectedTab == 'endUserHelp'" data-toggle="tab"
              (click)="activeTab('endUserHelp')"><label rxText="SystemHelpViewComponent_EndUserHelpTitle_t"></label></a>
          </li>
          <li>
            <a class="nav-link" *ngIf="systemHelp.isShowTechnicalHelp" data-toggle="tab"
              [class.active]="selectedTab == 'technicalHelp'" (click)="activeTab('technicalHelp')"><label
                rxText="SystemHelpViewComponent_TechnicalHelpTitle_t"></label></a>
          </li>
        </ul>
        <!-- <div class="slick_demo-btn"></div> -->
        <div class="hr-line-dashed border-top w-100"></div>
        <div class="tab-content h-75" style="overflow-y: auto;max-height: 65vh;">
          <div role="tabpanel" *ngIf="selectedTab == 'endUserHelp'" [class.active]="selectedTab == 'endUserHelp'"
            class="tab-pane">
            <span class="system-help-content" [innerHTML]="transformYourHtml(systemHelp.endUserHelp)">
            </span>
          </div>
          <div role="tabpanel" *ngIf="selectedTab == 'technicalHelp'" [class.active]="selectedTab == 'technicalHelp'"
            class="tab-pane">
            <span class="system-help-content" [innerHTML]="transformYourHtml(systemHelp.technicalHelp)">
            </span>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer"> 
      <button (click)="hide()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
    </div>
  </div>
</div>
