import {ShoppingCartItemBase,CustomerSessionBase,ProductBase,} from '@app/database-models'
//Generated Imports
export class ShoppingCartItem extends ShoppingCartItemBase 
{




//#region Generated Reference Properties
//#region customerSession Prop
customerSession : CustomerSessionBase;
//#endregion customerSession Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties




}