import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBOMMaterialTypeLookUpBase {

//#region currencyId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'categoryId', keyColumn: true})
        materialID : number;
//#endregion currencyId Prop


//#region currencyName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion currencyName Prop

}