import {vUserLookUpBase,} from '@app/database-models'
//Generated Imports
export class vUserLookUp extends vUserLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}