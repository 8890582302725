import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUpdateFrequencyLookUpBase {

//#region updateFrequencyId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'updateFrequencyId', keyColumn: true})
        updateFrequencyId : number;
//#endregion updateFrequencyId Prop


//#region updateFrequencyName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'updateFrequencyName', keyColumn: false})
        updateFrequencyName : string;
//#endregion updateFrequencyName Prop

}