import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSpecificationAttributeRecordBase {

//#region specificationAttributeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'specificationAttributeId', keyColumn: true})
        specificationAttributeId : number;
//#endregion specificationAttributeId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region pictureId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'pictureId', keyColumn: false})
        pictureId : any;
//#endregion pictureId Prop

}