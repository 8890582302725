import { gridColumn } from "@rxweb/grid"
export class vEventBookings {

  //#region eventID Prop
  @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerTitle: 'eventID', keyColumn: true })
  eventID: number;
  //#endregion eventID Prop

  //#region eventName Prop
  @gridColumn({
    template: {
      div: {
          attributes: {
              innerHTML: function (x, y) {
                  if(this.eventName == null)
                  {
                    return "";
                  }
                  else
                  {
                    return this.eventName;
                  }
              }
          }
      }
    }
    ,isAscending: true, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 1, allowSorting: true, headerKey : 'EventDetailComponent_EventName_gh', keyColumn: false })
  eventName: string;
  //#endregion eventName Prop

  //#region eventType Prop
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 2, allowSorting: true, headerKey : 'EventDetailComponent_EventType_gh', keyColumn: false })
  eventType: any;
  //#endregion eventType Prop

   //#region location Prop
   @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 3, allowSorting: true, headerKey : 'EventDetailComponent_Location_gh', keyColumn: false })
   location: any;
   //#endregion location Prop

  //#region startDate Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'EventDetailComponent_StartDate_gh', keyColumn: false })
  startDate: string;
  //#endregion startDate Prop

  //#region endDate Prop
  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 5, allowSorting: true, headerKey: 'EventDetailComponent_EndDate_gh', keyColumn: false })
  endDate: string;
  //#endregion endDate Prop


}
