import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
  name:"action",
  visible:false,
  allowSorting: false,
  style: { width: "5%","text-align": "left"},
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, class: ["text-left"], columnIndex: 1
})
export class QueuedEmailViewModel {
  @gridColumn({ name:"ischecked",configuredTemplate: { templateName: 'bindCheckBox' },allowSorting: false, visible: false, columnIndex: 0,  headerTitle: "Action", keyColumn: false, style: { width: "1%", "text-align": "initial" } })
  ischecked: boolean;

  @gridColumn({ allowSorting:true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2,  headerKey: 'QueuedEmailListComponent_Id_gh', keyColumn: true })
  queuedEmailId: number;

  @gridColumn({ allowSorting:false, style: { width: "8%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2,  headerKey: 'QueuedEmailListComponent_TemplateId_gh', keyColumn: false })
  templateId: string;

  @gridColumn({ allowSorting:false,  style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3,  headerKey: 'QueuedEmailListComponent_Priority_gh', keyColumn: false })
  priority : number;
  
  @gridColumn({ allowSorting:true,  style: { width: "5%", "text-align": "initial","word-wrap":"break-word" }, class: ["text-left"], visible: true, columnIndex: 4,  headerKey: 'QueuedEmailListComponent_From_gh', keyColumn: false })
  emailFrom: string;
  
  @gridColumn({ allowSorting:true,  style: {  width: "5%", "text-align": "initial","word-wrap":"break-word" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'QueuedEmailListComponent_To_gh', keyColumn: false })
  emailTo: string;

  @gridColumn({ allowSorting:false,  style: { width: "8%", "text-align": "initial","word-wrap":"break-word"  }, class: ["text-left"], visible: true, columnIndex: 6,  headerKey: 'QueuedEmailListComponent_Subject_gh', keyColumn: false })
  subject: string;

  @gridColumn({  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7,  headerKey: 'subject', keyColumn: false })
  body: string;

  @gridColumn({ allowSorting:false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8,  headerKey: 'QueuedEmailListComponent_CreatedOn_gh', keyColumn: false })
  createdOn: Date;
  
  @gridColumn({ allowSorting:false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9,  headerKey: 'QueuedEmailListComponent_SentOn_gh', keyColumn: false })
  sentOn: string;

  @gridColumn({  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 10,  headerKey: 'emailAccountId', keyColumn: false })
  emailAccountId: number;

  @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 11, headerKey: 'QueuedEmailListComponent_SentOn_gh', keyColumn: false })
  sendTries: number;

  totalCount:number;

  @gridColumn({
    visible:false,
    name:"editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 12, headerTitle: "Edit"
})
editAction:any;


@gridColumn({
  visible:false,
  name:"deleteAction",
  allowSorting: false,
  style: { width: "1%" },
  configuredTemplate: { templateName: 'delete' }, columnIndex:13, headerTitle: "Delete"
})
deleteAction:any;

}
