import { prop, propObject, propArray, required, maxLength, range, notEmpty, alpha, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneCutTypeBase {

        //#region cutTypeId Prop
        @prop()
        cutTypeId: number;
        //#endregion cutTypeId Prop


        //#region cutTypeName Prop
        @required()
        @maxLength({ value: 50 })
        @notEmpty()
        @trim()
        cutTypeName: string;
        //#endregion cutTypeName Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        //#region languageId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        languageId: number;
        //#endregion languageId Prop


}