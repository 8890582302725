import {vGemfieldsGroupEntityLookUpBase} from '@app/database-models'
//Generated Imports
export class vGemfieldsGroupEntityLookUp extends vGemfieldsGroupEntityLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}