import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const DELETEACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [{
            a: {
                event: {
                    click: "onDelete"
                },
                childrens: [{
                    i: {
                        class: "fr-delete fa-4x font-md text-danger".split(" "),
                        attributes: { "title": getLocalizedValue("Title_Delete") }
                    }
                }]
            }
        }]
    }
};

export const SAVEDELETEACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                span: {
                    childrens: [{
                        a: {
                            event: {
                                click: "onDelete"
                            },
                            childrens: [{
                                i: {
                                    class: "fr-delete fa-4x font-md text-danger".split(" "),
                                    attributes: { "title": getLocalizedValue("Title_Delete") }
                                }
                            }]
                        }
                    }]
                },
                a: {
                    event: {
                        click: "onUpdateData"
                    },
                    childrens: [{
                        i: {
                            class: "fa fr-save fa-4x font-md text-default".split(" "),
                            attributes: { "title": getLocalizedValue("Title_Save") }
                        }
                    }],
                    class: ["mr-2"]
                }

            }
        ]
    },
};
