import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { SystemHelpViewComponent } from './view/system-help-view.component';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule,SharedModule],
    declarations: [SystemHelpViewComponent],
    providers: []
})
export class SystemHelpSharedModule { }

