import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCategoryRecordBase {

//#region categoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'categoryId', keyColumn: true})
        categoryId : number;
//#endregion categoryId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region abbr Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'abbr', keyColumn: false})
        abbr : string;
//#endregion abbr Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region templateId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'templateId', keyColumn: false})
        templateId : number;
//#endregion templateId Prop


//#region metaKeywords Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'metaKeywords', keyColumn: false})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'metaDescription', keyColumn: false})
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'metaTitle', keyColumn: false})
        metaTitle : string;
//#endregion metaTitle Prop


//#region sEName Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'sEName', keyColumn: false})
        sEName : string;
//#endregion sEName Prop


//#region parentCategoryId Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'parentCategoryId', keyColumn: false})
        parentCategoryId : number;
//#endregion parentCategoryId Prop


//#region pictureId Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'pictureId', keyColumn: false})
        pictureId : number;
//#endregion pictureId Prop


//#region pageSize Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'pageSize', keyColumn: false})
        pageSize : number;
//#endregion pageSize Prop


//#region priceRanges Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'priceRanges', keyColumn: false})
        priceRanges : string;
//#endregion priceRanges Prop


//#region showOnHomePage Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'showOnHomePage', keyColumn: false})
        showOnHomePage : boolean;
//#endregion showOnHomePage Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:14, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:15, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region categoryTypeId Prop
        @gridColumn({visible: true, columnIndex:16, allowSorting: true, headerKey: 'categoryTypeId', keyColumn: false})
        categoryTypeId : number;
//#endregion categoryTypeId Prop


//#region pageTheme Prop
        @gridColumn({visible: true, columnIndex:17, allowSorting: true, headerKey: 'pageTheme', keyColumn: false})
        pageTheme : string;
//#endregion pageTheme Prop


//#region pictureId2 Prop
        @gridColumn({visible: true, columnIndex:18, allowSorting: true, headerKey: 'pictureId2', keyColumn: false})
        pictureId2 : number;
//#endregion pictureId2 Prop


//#region display1 Prop
        @gridColumn({visible: true, columnIndex:19, allowSorting: true, headerKey: 'display1', keyColumn: false})
        display1 : boolean;
//#endregion display1 Prop


//#region display2 Prop
        @gridColumn({visible: true, columnIndex:20, allowSorting: true, headerKey: 'display2', keyColumn: false})
        display2 : boolean;
//#endregion display2 Prop


//#region titlePictureId Prop
        @gridColumn({visible: true, columnIndex:21, allowSorting: true, headerKey: 'titlePictureId', keyColumn: false})
        titlePictureId : number;
//#endregion titlePictureId Prop


//#region pictureId3 Prop
        @gridColumn({visible: true, columnIndex:22, allowSorting: true, headerKey: 'pictureId3', keyColumn: false})
        pictureId3 : number;
//#endregion pictureId3 Prop


//#region pictureId4 Prop
        @gridColumn({visible: true, columnIndex:23, allowSorting: true, headerKey: 'pictureId4', keyColumn: false})
        pictureId4 : number;
//#endregion pictureId4 Prop


//#region pictureId5 Prop
        @gridColumn({visible: true, columnIndex:24, allowSorting: true, headerKey: 'pictureId5', keyColumn: false})
        pictureId5 : any;
//#endregion pictureId5 Prop


//#region frontEndCategoryPictureId Prop
        @gridColumn({visible: true, columnIndex:25, allowSorting: true, headerKey: 'frontEndCategoryPictureId', keyColumn: false})
        frontEndCategoryPictureId : number;
//#endregion frontEndCategoryPictureId Prop


//#region frontEndCategoryTitle Prop
        @gridColumn({visible: true, columnIndex:26, allowSorting: true, headerKey: 'frontEndCategoryTitle', keyColumn: false})
        frontEndCategoryTitle : string;
//#endregion frontEndCategoryTitle Prop


//#region frontEndCategoryDescription Prop
        @gridColumn({visible: true, columnIndex:27, allowSorting: true, headerKey: 'frontEndCategoryDescription', keyColumn: false})
        frontEndCategoryDescription : string;
//#endregion frontEndCategoryDescription Prop


//#region frontEndCategoryButtonText Prop
        @gridColumn({visible: true, columnIndex:28, allowSorting: true, headerKey: 'frontEndCategoryButtonText', keyColumn: false})
        frontEndCategoryButtonText : string;
//#endregion frontEndCategoryButtonText Prop


//#region frontEndCategoryButtonLink Prop
        @gridColumn({visible: true, columnIndex:29, allowSorting: true, headerKey: 'frontEndCategoryButtonLink', keyColumn: false})
        frontEndCategoryButtonLink : string;
//#endregion frontEndCategoryButtonLink Prop


//#region frontEndCategoryTopic Prop
        @gridColumn({visible: true, columnIndex:30, allowSorting: true, headerKey: 'frontEndCategoryTopic', keyColumn: false})
        frontEndCategoryTopic : string;
//#endregion frontEndCategoryTopic Prop


//#region titlePictureId_ImageAlt Prop
        @gridColumn({visible: true, columnIndex:31, allowSorting: true, headerKey: 'titlePictureId_ImageAlt', keyColumn: false})
        titlePictureId_ImageAlt : string;
//#endregion titlePictureId_ImageAlt Prop


//#region pictureId_ImageAlt Prop
        @gridColumn({visible: true, columnIndex:32, allowSorting: true, headerKey: 'pictureId_ImageAlt', keyColumn: false})
        pictureId_ImageAlt : string;
//#endregion pictureId_ImageAlt Prop


//#region pictureId2_ImageAlt Prop
        @gridColumn({visible: true, columnIndex:33, allowSorting: true, headerKey: 'pictureId2_ImageAlt', keyColumn: false})
        pictureId2_ImageAlt : string;
//#endregion pictureId2_ImageAlt Prop


//#region pictureId3_ImageAlt Prop
        @gridColumn({visible: true, columnIndex:34, allowSorting: true, headerKey: 'pictureId3_ImageAlt', keyColumn: false})
        pictureId3_ImageAlt : string;
//#endregion pictureId3_ImageAlt Prop


//#region pictureId4_ImageAlt Prop
        @gridColumn({visible: true, columnIndex:35, allowSorting: true, headerKey: 'pictureId4_ImageAlt', keyColumn: false})
        pictureId4_ImageAlt : string;
//#endregion pictureId4_ImageAlt Prop


//#region pictureId5_ImageAlt Prop
        @gridColumn({visible: true, columnIndex:36, allowSorting: true, headerKey: 'pictureId5_ImageAlt', keyColumn: false})
        pictureId5_ImageAlt : string;
//#endregion pictureId5_ImageAlt Prop


//#region showOnFabergeHomePage Prop
        @gridColumn({visible: true, columnIndex:37, allowSorting: true, headerKey: 'showOnFabergeHomePage', keyColumn: false})
        showOnFabergeHomePage : any;
//#endregion showOnFabergeHomePage Prop

}