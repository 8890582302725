import { ApplicationBroadcaster } from '../../../temp-service/application-broadcaster';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HelpSupport } from '@app/models';
import { CoreComponent } from '@rxweb/angular-router';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMenuLinkViewModel } from 'src/app/view-model/form-menu-link-view-model';
import { List } from '@rxweb/generics';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { MultiLingualData, multilingual } from '@rxweb/localization';
import { FormsDocument } from 'src/app/models/extended-models/forms-document';
import { sideBarScroll, fixedContent } from 'src/app/domain/customize-plugin/bind-slider';
import { getLocalizedValue, injectgoogleapi } from 'src/app/domain/common-logic/common-logic';
@multilingual("SideBarComponent")
@HostListener('window:resize', ['$event'])
@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent extends CoreComponent implements OnInit, OnDestroy {
    get componentName(): string {
        return "SideBarComponent";
    }
    showSkeletonLoading:boolean = false;
    //For Help and Training

    powerBIReportMenuList: any;
    subscription: any;
    menuList: HelpSupport[];
    parentMenuList: HelpSupport[];
    childMenuList: HelpSupport[];
    formsMenuList: List<FormsDocument>;
    formsMenuArr: FormsDocument[];
    formsGroupBusiness: FormsDocument[];
    formsEmployees: FormsDocument[];
    formsFaberge: FormsDocument[];
    menuContent: HelpSupport;
    spin: boolean = false;
    showComponent: boolean = false;
    showContent: boolean = false;
    browserStorage: BrowserStorage;
    toggleSecondLevelMenu: boolean = false;
    toggleThirdLevelMenu: boolean = false;
    mainMenuList: string[];
    listMenus: string[];
    isSideBarActive: boolean = false;
    favList:any;
    favorites: string[] = [];
    activeMenu: string;
    toggleMenu: boolean;
    activeSubMenu: string;
    activeSubChildMenu: string;
    eventList: any;
    newsList: any;
    projectList: any;
    newsLetterList: any;
    userList: any;

    supplierList: any;
    callmeList: any;
    internalDirectory: any;
    btnText: string = getLocalizedValue("Sidebar_Close");
    //gemfield Admin declarations

    gemfieldTeamList: any;
    gemstoneClarityList: any;
    gemstoneEnhancementList: any;
    gemstoneCarrier: any;
    gemstoneColorList: any;
    locationList: any;
    arrangementList: any;
    taxDutyList: any;
    stationList: any;
    enhancementTypeList: any;
    salesChannelList: any;
    bookingStatusList: any;
    holidayList: any;
    hseUpdate: any;
    itINCType: any;
    itINCCategory: any;
    itTicketCompanyCustomer: any;
    incidentType: any;
    corporateCreditRating: any;
    agreementType: any;
    goalsNotifications: any;
    leavesNotifications: any;
    adminSetting: any;
    legalAgreementAccessPermissions: any;
    additionalGroupCompanyUserMappings: any;
    trackIncidentRawForms: any;
    personalCompanyCustomerMappings: any;
    productionOrderFormUserDepartmentMappings: any;
    ticketSeverityList:any;
    eventServiceTypeList:any;
    patersonGrade: any; //#292627//08-07-2024// PatersonGrade master added in gemfield admin

    //system admin declarations

    globalSettings: any;
    messageTemplate: any;
    emailAccounts: any;
    measureDimensions: any;
    measureWeights: any;
    allSettings: any;
    log: any;
    systemInfo: any;
    moduleLevel: any;
    creditCard: any;
    paymentMethods: any;
    discounts: any;
    taxProviders: any
    taxClasses: any;
    shippingMethods: any;
    stateProvinces: any;
    languages: any;
    activityTypes: any;
    activityLogs: any;
    blacklist: any;
    faberegeLocalization: any;
    localization: any;
    formsDocument: any;
    systemHelp: any;
    regions: any;
    fileExtentions: any;
  powerBIReportList: any;
  applicationObject:any;

    // bannedIpAddressList:any=BannedIpAddressListComponent;
    // bannedIpNetworkList:any=BannedIpNetworkListComponent;


    //faberge admin declarations

    fabergeTeam: any;
    categories: any;
    location: any;
    posBrands: any;
    pieceOwner: any;
    userRole: any;
    customerRelationship: any;
    countries: any;
    collections: any;
    adhocs: any;
    designAttributes: any;
    currencies: any;
    titles: any;
    salesChannel: any;
    boutiques: any;
    priceBandOptions: any;
    pieceDeliveryStatus: any;
    productPieceStatus: any;
    messageQueue: any;
    helpSupport: any;
    ringSizes: any;
    ringSizeList: any;
    dutyStatus: any;
    hashtags: any;
    nationality: any;
    industryType: any;
    bookingType: any;
    chainLength: any;
    chainColors: any;
    gemstoneStatus: any;
    departments: any;
    groupLocations: any;
    gemstoneOrigins: any;
    leaveTypes: any;
    bangleSizes: any;
    bankHolidays: any;
    userLockout: any;
    holidayProcess: any;
    chainGauge: any;
    chainStyle: any;
    riskNotifications: any;
    specificRisk: any;
    ianNotifications: any;
    seoRedirections: any;
    productTypes: any;
    trainingTypes: any;
    bom: any;
    gemstonetype: any;
    grievanceTier: any;
    grievanceType: any;
    riskCategory: any;
    gemfieldsTreatment: any;
    watchStatusList: any;
    watchLegalOwnerList: any;
    watchItemTypesList: any;
    watchMaterialList: any;
    gemstoneCutTypeList: any;
    materialList: any;
    deliveryTypeList: any;
    duplicateCustomerList: any;
    apiKeysList:any;

    //reports->people declarations
    teamLoginReport: any;
    repeatCustomerReport: any;
    customerStatisticReport: any;
    customerLastContactedReport: any;
    teamMemberReport: any;
    travelReport: any;
    leaveReport: any;
    itHelpDeskWeeklyReport: any;

    clientSalesReport: any;
    SalesAdvisorReport: any;
    SalesAnalysisReport: any;
    SalesMarginReport: any;
    WholeSaleReport: any;

    //reports->hse start
    hseReport: any;
    //reports->hse end

    //reports->GINA start
    gINAReport: any;
    //reports->GINA end

    //reports->notes start
    notesReport: any;
    //reports->notes end


    //HSE Report


    //Press And Media Report start
    pressMediaReport: any;
    //Press And Media Report end


    //faberge Invetory Reports
    missingProductDescriptionList: any;
    bespokeOrderReportList: any;
    bOMReportList: any;
    coreProductListReportList: any;
    designProductSuccessReportList: any;
    financeReportList: any;
    gemstoneSetPieceReportList: any;
    inventoryLocationSummaryReportList: any;
    inventorySummaryReportList: any;
    locationInventorySummaryByCollectionReportList: any;
    missingProductPictureList: any;
    pieceInproductionReportList: any;
    pieceOwnerReportList: any;
    priceBandOptionReportList: any;
    productCaptionReportList: any;
    soldPieceReportList: any;
    stockByLocationAnalyserReportList: any;
    supplierProductionReportList: any;
    timePiecesSalesReportList: any;
    repairReportList: any;
    inventoryHistoryReportList:any;

    //order declarations start
    orders: any;
    returnOrders:any;
    purchaseList: any;
    //order declarations end

    //Trackeme
    liveusertracking:any;
    timelineusertracking:any;

    //inventory declarations start

    productDesign: any;
    productList: any;
    batchList:any;
    pieceList: any;
    pieceGemstone: any;
    gemstoneOrders: any;
    bookingList: any;
    bookingApproval: any;
    priceList: any;
    carnets: any;
    watchParts: any;

    //inventory declarations end

    showPeoplemenu: boolean;
    route: Router;
    formMenuLinkList: any;
    //activeMenu: string = "dashboard";


    //Forms Declaration Start
    legalAgreementList: any;
    pressMediaDetailList: any;
    productionForm: any;
    electronicGiftForm: any;
    riskMenu: any;
    hseForm: any;
    //Forms Declaration End

    //mines menu start
    minesList: any
    //mins menu end

    //cms menu start
    homeLayout: any;
    fabergePages: any;
    fabergeNews: any;
    menuLayout: any;
    mirFaberge: any;


    //cms menu end

    //Help & training start
    helpTraining: any
    //Help & training end

    //pos menu start
    pos: any;
    //pos menu end

    //it ticket update menu start
    ticketDetails: any;
    //it ticket update menu end
    ItAssets:any;
    //advanced search menu start
    advancedSearch: any;
    //advanced search menu end

    //mind map start
    mindMap: any;
    //mind map end

    helpSupportId: number;
    activeSubSubMenu: string;
    showhelpTraining: boolean = false;
    showpowerBIReports: boolean = false;
    public innerWidth: any;
    titleAddToFavorites: string = 'Add to Favorites';
    titleRemoveFromFavorites: string = 'Remove from Favorites';
    hovering: { [key: string]: boolean } = {};

    constructor(private applicationBroadcaster: ApplicationBroadcaster, private router: Router, private activatedRoute: ActivatedRoute) {
        super();
        this.route = router;
        this.formMenuLinkList = new List<FormMenuLinkViewModel>();
        // this.applicationBroadcaster.loginSubscriber.subscribe(t => {
        //     setTimeout(function () {
        //         this.ngOnInit();
        //     }.bind(this), 0);
        // })
        this.applicationBroadcaster.menuLevelSubscriber.subscribe(data => {
            if (data.activeMenu && data.subMenu) {
                this.activeMenu = data.activeMenu;
                this.activeSubMenu = data.subMenu;
                sessionStorage.setItem("selectedModule", this.activeMenu);
                sessionStorage.setItem("selectedRootModule", this.activeSubMenu);
            }
            if (data.activeMenu) {
                this.activeMenu = data.activeMenu;
                if (this.activeMenu == "dashboard" || this.activeMenu == "suppliers" || this.activeMenu == "gemfield-risk-menu") {
                    this.activeSubMenu = "";
                }
                sessionStorage.setItem("selectedModule", this.activeMenu);
                sessionStorage.setItem("selectedRootModule", this.activeSubMenu);
            }
            if (this.activeMenu == "reports") {
            this.checkPowerBIReportActiveMenues();
            }
            else if(this.activeMenu == "help")  {
                this.checkHelpAndTrainingActiveMenues();
            }
            this.setActiveMenuSubMenu();
        })
        this.parentMenuList = new Array<HelpSupport>();
        this.childMenuList = new Array<HelpSupport>();
        this.browserStorage = new BrowserStorage();

    }

    onResize(event) {
        this.innerWidth = window.innerWidth;
        if(this.innerWidth < 769 && !this.isSideBarActive) {
           this.isToggleSideBar(true);
        }
    }

    lazyImportComponents(){
        let importsList = [
            import('../../event/events/search/event-search.component'),
            import('../../news/news.component'),
            import('../../project/projects/list/project-list.component'),
            import('../../user/newsletter-subscriber/list/newsletter-subscriber-list.component'),
            import('../../user/users/list/user-list.component'),
            import('../../user/suppliers/list/supplier-list.component'),
            import('../../user/faberge-customer-call-me/list/faberge-customer-call-me-list.component'),
            import('../../user/internal-directory/list/internal-directory.component'),
            import('../../master/gemfield-team/list/gemfield-team-list.component'),
            import('../../master/gem-stone-clarity-gemfields/list/gem-stone-clarity-gemfield-list.component'),
            import('../../master/gemfields-enhancement/list/gemfields-enhancement-list.component'),
            import('../../master/gemfields-carrier/list/gemfields-carrier-list.component'),
            import('../../master/gem-stone-color-gemfields/list/gem-stone-color-gemfield-list.component'),
            import('../../master/gemfields-location/list/gemfields-location-list.component'),
            import('../../master/gemfields-arrangements/list/gemfields-arrangement-list.component'),
            import('../../master/temporary-assignee-types/list/temporary-assignee-type-list.component'),
            import('../../master/gemfields-station/list/gemfields-station-list.component'),
            import('../../master/gemfields-enhancement-type/list/gemfields-enhancement-type-list.component'),
            import('../../master/gemfield-sales-channels/list/gemfield-sales-channel-list.component'),
            import('../../master/gem-fields-booking-statuses/list/gem-fields-booking-status-list.component'),
            import('../../master/holiday-lists/list/holiday-list-list.component'),
            import('../../master/h-s-e-updates/add/h-s-e-update-add.component'),
            import('../../i-t-help-desk/i-t-inc-types/list/i-t-inc-type-list.component'),
            import('../../i-t-help-desk/i-t-inc-categories/list/i-t-inc-category-list.component'),
            import('../../i-t-help-desk/i-t-ticket-company-customer-mappings/list/i-t-ticket-company-customer-mapping-list.component'),
            import('../../i-t-help-desk/incident-types/list/incident-type-list.component'),
            import('../../master/corporate-credit-ratings/list/corporate-credit-rating-list.component'),
            import('../../master/agreement-type/list/agreement-type-list.component'),
            import('../../master/goals-company-customer-mapping/list/goals-company-customer-mapping-list.component'),
            import('../../master/leave-company-customer-mappings/list/leave-company-customer-mapping-list.component'),
            import('../../master/admin-setting/list/admin-setting-list.component'),
            import('../../master/legal-agreement-access-permissions/list/legal-agreement-access-permission-list.component'),
            import('../../master/additional-group-company-user-mappings/list/additional-group-company-user-mapping-list.component'),
            import('../../form/track-incident-raw-forms/list/track-incident-raw-form-list.component'),
            import('../../master/personal-company-customer-mapping/list/personal-company-customer-mapping-list.component'),
            import('../../master/production-order-form-user-department-mappings/list/production-order-form-user-department-mapping-list.component'),
            import('../../i-t-help-desk/i-t-ticket-severity/list/i-t-ticket-severity-list.component'),
            import('../../master/patersongrade/list/patersongrade-list.component'),  //#292627//08-07-2024// PatersonGrade master added in gemfield admin
            import('../../master/all-settings/add/all-setting-add.component'),
            import('../../master/message-templates/list/message-template-list.component'),
            import('../../master/email-account/list/email-account-list.component'),
            import('../../master/measure-dimensions/list/measure-dimension-list.component'),
            import('../../master/measure-weights/list/measure-weight-list.component'),
            import('../../master/settings/list/setting-list.component'),
            import('../../master/application-exception-logs/list/application-exception-log-list.component'),
            import('../../master/system-information/list/system-information-list.component'),
            import('../../master/role-permissions/list/role-permission-list.component'),
            import('../../master/credit-card-types/list/credit-card-type-list.component'),
            import('../../master/payment-methods/list/payment-method-list.component'),
            import('../../master/discounts/list/discount-list.component'),
            import('../../master/tax-provider/edit/tax-provider-edit.component'),
            import('../../master/tax-category/list/tax-category-list.component'),
            import('../../master/shipping-method/list/shipping-method-list.component'),
            import('../../master/state-provinces/list/state-province-list.component'),
            import('../../master/languages/list/language-list.component'),
            import('../../master/activity-log-types/list/activity-log-type-list.component'),
            import('../../master/activity-logs/list/activity-log-list.component'),
            import('../../master/black-list/blacklist.component'),
            import('../../master/faberge-localization/list/faberge-localization-list.component'),
            import('../../master/language-content-keys/list/language-content-key-list.component'),
            import('../../master/forms-document/list/forms-document-list.component'),
            import('../../master/system-help/list/system-help-list.component'),
            import('../../master/regions/list/region-list.component'),
            import('../../master/file-extentions/list/file-extentions-list.component'),
            import('../../master/application-objects/list/application-objects-list.component'),
            import('../../master/power-bi-reports/list/power-bi-report-list.component'),
            import('../../master/faberge-team/list/faberge-team-list.component'),
            import('../../master/categories/list/category-list.component'),
            import('../../master/warehouses/list/warehouse-list.component'),
            import('../../master/p-o-s-brands/list/p-o-s-brand-list.component'),
            import('../../master/piece-owners/list/piece-owner-list.component'),
            import('../../master/roles/list/role-list.component'),
            import('../../master/customer-relationships/list/customer-relationship-list.component'),
            import('../../master/countries/list/country-list.component'),
            import('../../master/main-collections/list/main-collection-list.component'),
            import('../../master/adhoc-pages/list/adhoc-pages-list.component'),
            import('../../master/product-attributes/list/product-attribute-list.component'),
            import('../../master/currencies/list/currency-list.component'),
            import('../../master/titles/list/title-list.component'),
            import('../../master/sales-channels/list/sales-channel-list.component'),
            import('../../master/boutiques/list/boutique-list.component'),
            import('../../master/priceband-options/list/priceband-option-list.component'),
            import('../../master/piece-delivery-statuses/list/piece-delivery-status-list.component'),
            import('../../master/product-variant-statuses/list/product-variant-status-list.component'),
            import('../../master/queued-emails/list/queued-email-list.component'),
            import('../../master/help-supports/list/help-support-list.component'),
            import('../../master/ring-egg-sizes/list/ring-egg-size-list.component'),
            import('../../master/ring-sizes/list/ring-sizes-list.component'),
            import('../../master/duty-statuses/list/duty-status-list.component'),
            import('../../master/note-type/list/note-type-list.component'),
            import('../../master/nationalities/list/nationality-list.component'),
            import('../../master/industry-types/list/industry-type-list.component'),
            import('../../master/trip-types/list/trip-type-list.component'),
            import('../../master/chain-lengths/list/chain-length-list.component'),
            import('../../master/chain-colours/list/chain-colour-list.component'),
            import('../../master/gemstone-statuses/list/gemstone-status-list.component'),
            import('../../master/departments/list/department-list.component'),
            import('../../master/location-groups/list/location-group-list.component'),
            import('../../master/gemstone-origins/list/gemstone-origin-list.component'),
            import('../../master/leave-types/list/leave-type-list.component'),
            import('../../master/bangle-sizes/list/bangle-size-list.component'),
            import('../../master/bank-holidays/list/bank-holidays-list.component'),
            import('../../master/user-lockout/list/user-lockout-list.component'),
            import('../../master/customer-holidays/list/customer-holiday-list.component'),
            import('../../master/chain-gauges/list/chain-gauge-list.component'),
            import('../../master/chain-styles/list/chain-style-list.component'),
            import('../../master/risk-company-customer-mappings/list/risk-company-customer-mapping-list.component'),
            import('../../master/specific-risks/list/specific-risk-list.component'),
            import('../../master/i-a-n-company-customer-mappings/list/i-a-n-company-customer-mapping-list.component'),
            import('../../master/seo-redirections/list/seo-redirection-list.component'),
            import('../../master/product-types/list/product-type-list.component'),
            import('../../master/training-type/list/training-type-list.component'),
            import('../../master/bom/list/bom-list.component'),
            import('../../master/gemstones/list/gemstone-list.component'),
            import('../../master/grievance-tiers/list/grievance-tier-list.component'),
            import('../../master/grievance-types/list/grievance-type-list.component'),
            import('../../master/risk-categories/list/risk-category-list.component'),
            import('../../master/gemfields-treatment/list/gemfields-treatment-list.component'),
            import('../../master/watch-status/list/watch-status-list.component'),
            import('../../master/legal-owners/list/legal-owner-list.component'),
            import('../../master/watch-item-types/list/watch-item-type-list.component'),
            import('../../master/watch-materials/list/watch-material-list.component'),
            import('../../master/gem-stone-cut-types/list/gem-stone-cut-type-list.component'),
            import('../../master/materials/list/material-list.component'),
            import('../../master/delivery-type/list/delivery-type-list.component'),
            import('../../master/duplicate-customer/list/duplicate-customer-list.component'),
            import('../../reports/people/faberge-team-login-report/list/faberge-team-login-report-list.component'),
            import('../../reports/people/repeat-customers-report/list/repeat-customers-report-list.component'),
            import('../../reports/people/customer-statistics/customer-statistic-report/list/customer-statistics-list.component'),
            import('../../reports/people/customers-last-contacted/list/customer-last-contacted-list.component'),
            import('../../reports/people/team-member-report/list/team-member-report-list.component'),
            import('../../reports/people/travel-report/list/travel-report-list.component'),
            import('../../reports/people/leave-report/list/leave-report-list.component'),
            import('../../reports/i-t-help-desk/i-t-help-desk-weekly-report/list/i-t-help-desk-weekly-report-list.component'),
            import('../../reports/faberge-sales-report/client-sales-report/list/client-sales-report-list.component'),
            import('src/app/components/reports/faberge-sales-report/sales-advisor-report/list/sales-advisor-report-list.component'),
            import('src/app/components/reports/faberge-sales-report/sales-analysis-report/list/sales-analysis-report-list.component'),
            import('src/app/components/reports/faberge-sales-report/sales-margin-report/list/sales-margin-report-list.component'),
            import('src/app/components/reports/faberge-sales-report/wholesale-report/list/wholesale-report-list.component'),
            import('../../reports/hse-report/list/hse-report-list.component'),
            import('../../reports/g-i-n-a-report/list/g-i-n-a-report.component'),
            import('../../reports/notes-report/list/notes-report-list.component'),
            import('../../reports/press-media-report/list/press-media-report-list.component'),
            import('../../reports/faberge-inventory/missing-product-description/List/missing-product-description-list.component'),
            import('../../reports/faberge-inventory/bespokes-order-report/list/bespoke-order-report-list.component'),
            import('../../reports/faberge-inventory/bom-report/list/bom-report-list.component'),
            import('../../reports/faberge-inventory/core-product-list-report/list/core-product-list-report-list.component'),
            import('../../reports/faberge-inventory/design-product-success-report/list/design-product-success-report-list.component'),
            import('../../reports/faberge-inventory/finance-report/list/finance-report-list.component'),
            import('../../reports/faberge-inventory/gemstone-set-piece-report/list/gemstone-set-piece-report-list.component'),
            import('../../reports/faberge-inventory/inventory-location-summary-report/List/inventory-location-summary-report-list.component'),
            import('../../reports/faberge-inventory/inventory-summary-report/List/inventory-summary-report-list.component'),
            import('../../reports/faberge-inventory/location-inventory-summary-by-collection-report/List/location-inventory-summary-by-collection-report-list.component'),
            import('../../reports/faberge-inventory/missing-product-picture/List/missing-product-picture-list.component'),
            import('../../reports/faberge-inventory/piece-inproduction-report/list/piece-inproduction-report-list.component'),
            import('../../reports/faberge-inventory/piece-owner-report/List/piece-owner-report-list.component'),
            import('../../reports/faberge-inventory/price-band-option-report/list/price-band-option-report-list.component'),
            import('../../reports/faberge-inventory/product-caption-report/list/product-caption-report-list.component'),
            import('../../reports/faberge-inventory/sold-piece-report/list/sold-piece-report-list.component'),
            import('../../reports/faberge-inventory/stock-by-location-analyser-report/list/stock-by-location-analyser-report-list.component'),
            import('../../reports/faberge-inventory/supplier-production-report/List/supplier-production-report-list.component'),
            import('../../reports/faberge-inventory/timepieces-sales-report/list/timepieces-sales-report-list.component'),
            import('../../reports/faberge-inventory/repair-report/list/repair-report-list.component'),
            import('../../reports/faberge-inventory/inventory-history-report/list/inventory-history-report-list.component'),
            import('../../sales-order/orders/list/order-list.component'),
            import('../../sales-order/return-order/list/return-order-list.component'),
            import('../../sales-order/faberge-current-purchase-list/list/current-purchase-list.component'),
            import('../../trackme/liveusertracking/listliveuser/live-user-tracking.component'),
            import('../../trackme/timelineusertraking/timelineuser/timeline-user-tracking.component'),
            import('../../inventory/product-design/list/product-design-list.component'),
            import('../../inventory/product/list/product-list.component'),
            import('../../inventory/product-piece/list/product-piece-list.component'),
            import('../../inventory/product-piece/product-piece-gemstone/list/product-piece-gemstone.component'),
            import('../../inventory/gemstone-orders/list/gemstone-order-list.component'),
            import('../../booking/bookings/list/booking-list.component'),
            import('../../booking-approval/booking-approval/list/booking-approval-list.component'),
            import('../../inventory/faberge-price/list/faberge-price-list.component'),
            import('../../inventory/carnets/list/carnet-list.component'),
            import('../../inventory/watch-parts/list/watch-part-list.component'),
            import('../../inventory/product-batch-section/list/product-batch-section-list.component'),
            import('../../form/legal-agreement/list/legal-agreement-list.component'),
            import('../../press-media/press-media-detail/list/press-media-detail-list.component'),
            import('../../form/production-order-forms/list/production-order-form-list.component'),
            import('../../form/electronic-gift-forms/list/electronic-gift-form-list.component'),
            import('../../form/risk-menu-details/list/risk-menu-detail-list.component'),
            import('../../form/track-incidents/list/track-incident-list.component'),
            import('../../reports/mines/mines.component'),
            import('../../content-management/home-layouts/list/home-layout-list.component'),
            import('../../content-management/topics/list/topic-list.component'),
            import('../../content-management/news/list/news-list.component'),
            import('../../master/main-menu-items/list/main-menu-item-list.component'),
            import('../../content-management/history-mir-faberge/list/history-mir-faberge-list.component'),
            import('../../help-and-trainning/help-and-trainning/details/help-and-trainning-details.component'),
            import('../../p-o-s/p-o-s-customer-search/list/p-o-s-customer-search-list.component'),
            import('../../i-t-help-desk/ticket-details/list/ticket-detail-list.component'),
            import('../../i-t-help-desk/it-assets/list/it-assets-list.component'),
            import('../../faberge-advanced-search/list/faberge-advanced-search-list.component'),
            import('../../mind-map/list/mind-map-list.component'),
            import('../../master/event-service-types/list/event-service-types-list.component'),
            import('../../master/api-keys/list/api-key-list.component')
        ]
        Promise.all(importsList).then(
            ([
            eventList,
            newsList,
            projectList,
            newsLetterList,
            userList,

            supplierList,
            callmeList,
            internalDirectory,
            gemfieldTeamList,
            gemstoneClarityList,
            gemstoneEnhancementList,
            gemstoneCarrier,
            gemstoneColorList,
            locationList,
            arrangementList,
            taxDutyList,
            stationList,
            enhancementTypeList,
            salesChannelList,
            bookingStatusList,
            holidayList,
            hseUpdate,
            itINCType,
            itINCCategory,
            itTicketCompanyCustomer,
            incidentType,
            corporateCreditRating,
            agreementType,
            goalsNotifications,
            leavesNotifications,
            adminSetting,
            legalAgreementAccessPermissions,
            additionalGroupCompanyUserMappings,
            trackIncidentRawForms,
            personalCompanyCustomerMappings,
            productionOrderFormUserDepartmentMappings,
            ticketSeverityList,
            patersonGrade,   //#292627//08-07-2024// PatersonGrade master added in gemfield admin
            globalSettings,
            messageTemplate,
            emailAccounts,
            measureDimensions,
            measureWeights,
            allSettings,
            log,
            systemInfo,
            moduleLevel,
            creditCard,
            paymentMethods,
            discounts,
            taxProviders,
            taxClasses,
            shippingMethods,
            stateProvinces,
            languages,
            activityTypes,
            activityLogs,
            blacklist,
            faberegeLocalization,
            localization,
            formsDocument,
            systemHelp,
            regions,
            fileExtentions,
            applicationObject,
            powerBIReportList,
            fabergeTeam,
            categories,
            location,
            posBrands,
            pieceOwner,
            userRole,
            customerRelationship,
            countries,
            collections,
            adhocs,
            designAttributes,
            currencies,
            titles,
            salesChannel,
            boutiques,
            priceBandOptions,
            pieceDeliveryStatus,
            productPieceStatus,
            messageQueue,
            helpSupport,
            ringSizes,
            ringSizeList,
            dutyStatus,
            hashtags,
            nationality,
            industryType,
            bookingType,
            chainLength,
            chainColors,
            gemstoneStatus,
            departments,
            groupLocations,
            gemstoneOrigins,
            leaveTypes,
            bangleSizes,
            bankHolidays,
            userLockout,
            holidayProcess,
            chainGauge,
            chainStyle,
            riskNotifications,
            specificRisk,
            ianNotifications,
            seoRedirections,
            productTypes,
            trainingTypes,
            bom,
            gemstonetype,
            grievanceTier,
            grievanceType,
            riskCategory,
            gemfieldsTreatment,
            watchStatusList,
            watchLegalOwnerList,
            watchItemTypesList,
            watchMaterialList,
            gemstoneCutTypeList,
            materialList,
            deliveryTypeList,
            duplicateCustomerList,
            teamLoginReport,
            repeatCustomerReport,
            customerStatisticReport,
            customerLastContactedReport,
            teamMemberReport,
            travelReport,
            leaveReport,
            itHelpDeskWeeklyReport,
            clientSalesReport,
            SalesAdvisorReport,
            SalesAnalysisReport,
            SalesMarginReport,
            WholeSaleReport,
            hseReport,
            gINAReport,
            notesReport,
            pressMediaReport,
            missingProductDescriptionList,
            bespokeOrderReportList,
            bOMReportList,
            coreProductListReportList,
            designProductSuccessReportList,
            financeReportList,
            gemstoneSetPieceReportList,
            inventoryLocationSummaryReportList,
            inventorySummaryReportList,
            locationInventorySummaryByCollectionReportList,
            missingProductPictureList,
            pieceInproductionReportList,
            pieceOwnerReportList,
            priceBandOptionReportList,
            productCaptionReportList,
            soldPieceReportList,
            stockByLocationAnalyserReportList,
            supplierProductionReportList,
            timePiecesSalesReportList,
            repairReportList,
            inventoryHistoryReportList,
            orders,
            returnOrders,
            purchaseList,
            liveusertracking,
            timelineusertracking,
            productDesign,
            productList,
            pieceList,
            pieceGemstone,
            gemstoneOrders,
            bookingList,
            bookingApproval,
            priceList,
            carnets,
            watchParts,
            batchList,
            legalAgreementList,
            pressMediaDetailList,
            productionForm,
            electronicGiftForm,
            riskMenu,
            hseForm,
            minesList,
            homeLayout,
            fabergePages,
            fabergeNews,
            menuLayout,
            mirFaberge,
            helpTraining,
            pos,
            ticketDetails,
            ItAssets,
            advancedSearch,
            mindMap,
            eventServiceTypeList,
            apiKeysList
        ]: any[])=>{
            this.eventList = eventList.EventSearchComponent;
            this.newsList = newsList.NewsComponent;
            this.projectList = projectList.ProjectListComponent;
            this.newsLetterList = newsLetterList.NewsletterSubscriberListComponent;
            this.userList = userList.UserListComponent;

            this.supplierList = supplierList.SupplierListComponent;
            this.callmeList = callmeList.FabergeCustomerCallMeListComponent;
            this.internalDirectory = internalDirectory.InternalDirectoryComponent;

            //gemfield Admin declarations

            this.gemfieldTeamList = gemfieldTeamList.GemfieldTeamListComponent;
            this.gemstoneClarityList = gemstoneClarityList.GemStoneClarityGemfieldListComponent;
            this.gemstoneEnhancementList = gemstoneEnhancementList.GemfieldsEnhancementListComponent;
            this.gemstoneCarrier = gemstoneCarrier.GemfieldsCarrierListComponent;
            this.gemstoneColorList = gemstoneColorList.GemStoneColorGemfieldListComponent;
            this.locationList = locationList.GemfieldsLocationListComponent;
            this.arrangementList = arrangementList.GemfieldsArrangementListComponent;
            this.taxDutyList = taxDutyList.TemporaryAssigneeTypeListComponent;
            this.stationList = stationList.GemfieldsStationListComponent;
            this.enhancementTypeList = enhancementTypeList.GemfieldsEnhancementTypeListComponent;
            this.salesChannelList = salesChannelList.GemfieldSalesChannelListComponent;
            this.bookingStatusList = bookingStatusList.GemFieldsBookingStatusListComponent;
            this.holidayList = holidayList.HolidayListListComponent;
            this.hseUpdate = hseUpdate.HSEUpdateAddComponent;
            this.itINCType = itINCType.ITIncTypeListComponent;
            this.itINCCategory = itINCCategory.ITIncCategoryListComponent;
            this.itTicketCompanyCustomer = itTicketCompanyCustomer.ITTicketCompanyCustomerMappingListComponent;
            this.incidentType = incidentType.IncidentTypeListComponent;
            this.corporateCreditRating = corporateCreditRating.CorporateCreditRatingListComponent;
            this.agreementType = agreementType.AgreementTypeListComponent;
            this.goalsNotifications = goalsNotifications.GoalsCompanyCustomerMappingListComponent;
            this.leavesNotifications = leavesNotifications.LeaveCompanyCustomerMappingListComponent;
            this.adminSetting = adminSetting.AdminSettingListComponent;
            this.legalAgreementAccessPermissions = legalAgreementAccessPermissions.LegalAgreementAccessPermissionListComponent;
            this.additionalGroupCompanyUserMappings = additionalGroupCompanyUserMappings.AdditionalGroupCompanyUserMappingListComponent;
            this.trackIncidentRawForms = trackIncidentRawForms.TrackIncidentRawFormListComponent;
            this.personalCompanyCustomerMappings = personalCompanyCustomerMappings.PersonalCompanyCustomerMappingListComponent;
            this.productionOrderFormUserDepartmentMappings = productionOrderFormUserDepartmentMappings.ProductionOrderFormUserDepartmentMappingListComponent;
            this.ticketSeverityList= ticketSeverityList.ITTicketSeverityListComponent;
            this.patersonGrade = patersonGrade.PatersonGradeListComponent;   //#292627//08-07-2024// PatersonGrade master added in gemfield admin
            this.eventServiceTypeList = eventServiceTypeList.EventServiceTypesListComponent;

            //system admin declarations
            this.globalSettings = globalSettings.AllSettingAddComponent;
            this.messageTemplate = messageTemplate.MessageTemplateListComponent;
            this.emailAccounts = emailAccounts.EmailAccountListComponent;
            this.measureDimensions = measureDimensions.MeasureDimensionListComponent;
            this.measureWeights = measureWeights.MeasureWeightListComponent;
            this.allSettings = allSettings.SettingListComponent;
            this.log = log.ApplicationExceptionLogListComponent;
            this.systemInfo = systemInfo.SystemInformationListComponent;
            this.moduleLevel = moduleLevel.RolePermissionListComponent;
            this.creditCard = creditCard.CreditCardTypeListComponent;
            this.paymentMethods = paymentMethods.PaymentMethodListComponent;
            this.discounts = discounts.DiscountListComponent;
            this.taxProviders = taxProviders.TaxProviderEditComponent
            this.taxClasses = taxClasses.TaxCategoryListComponent;
            this.shippingMethods = shippingMethods.ShippingMethodListComponent;
            this.stateProvinces = stateProvinces.StateProvinceListComponent;
            this.languages = languages.LanguageListComponent;
            this.activityTypes = activityTypes.ActivityLogTypeListComponent;
            this.activityLogs = activityLogs.ActivityLogListComponent;
            this.blacklist = blacklist.BlackListComponent;
            this.faberegeLocalization = faberegeLocalization.FabergeLocalizationListComponent;
            this.localization = localization.LanguageContentKeyListComponent;
            this.formsDocument = formsDocument.FormsDocumentListComponent;
            this.systemHelp = systemHelp.SystemHelpListComponent;
            this.regions = regions.RegionListComponent;
            this.fileExtentions = fileExtentions.FileExtentionListComponent;
            this.applicationObject = applicationObject.ApplicationObjectListComponent;
            this.powerBIReportList = powerBIReportList.PowerBIReportListComponent;

            //gemfield admin
            this.fabergeTeam = fabergeTeam.FabergeTeamListComponent;
            this.categories = categories.CategoryListComponent;
            this.location = location.WarehouseListComponent;
            this.posBrands = posBrands.POSBrandListComponent;
            this.pieceOwner = pieceOwner.PieceOwnerListComponent;
            this.userRole = userRole.RoleListComponent;
            this.customerRelationship = customerRelationship.CustomerRelationshipListComponent;
            this.countries = countries.CountryListComponent;
            this.collections = collections.MainCollectionListComponent;
            this.adhocs = adhocs.AdhocPagesListComponent;
            this.designAttributes = designAttributes.ProductAttributeListComponent;
            this.currencies = currencies.CurrencyListComponent;
            this.titles = titles.TitleListComponent;
            this.salesChannel = salesChannel.SalesChannelListComponent;
            this.boutiques = boutiques.BoutiqueListComponent;
            this.priceBandOptions = priceBandOptions.PricebandOptionListComponent;
            this.pieceDeliveryStatus = pieceDeliveryStatus.PieceDeliveryStatusListComponent;
            this.productPieceStatus = productPieceStatus.ProductVariantStatusListComponent;
            this.messageQueue = messageQueue.QueuedEmailListComponent;
            this.helpSupport = helpSupport.HelpSupportListComponent;
            this.ringSizes = ringSizes.RingEggSizeListComponent;
            this.ringSizeList = ringSizeList.RingSizesListComponent;
            this.dutyStatus = dutyStatus.DutyStatusListComponent;
            this.hashtags = hashtags.NoteTypeListComponent;
            this.nationality = nationality.NationalityListComponent;
            this.industryType = industryType.IndustryTypeListComponent;
            this.bookingType = bookingType.TripTypeListComponent;
            this.chainLength = chainLength.ChainLengthListComponent;
            this.chainColors = chainColors.ChainColourListComponent;
            this.gemstoneStatus = gemstoneStatus.GemstoneStatusListComponent;
            this.departments = departments.DepartmentListComponent;
            this.groupLocations = groupLocations.LocationGroupListComponent;
            this.gemstoneOrigins = gemstoneOrigins.GemstoneOriginListComponent;
            this.leaveTypes = leaveTypes.LeaveTypeListComponent;
            this.bangleSizes = bangleSizes.BangleSizeListComponent;
            this.bankHolidays = bankHolidays.BankHolidayListComponent;
            this.userLockout = userLockout.UserLockoutListComponent;
            this.holidayProcess = holidayProcess.CustomerHolidayListComponent;
            this.chainGauge = chainGauge.ChainGaugeListComponent;
            this.chainStyle = chainStyle.ChainStyleListComponent;
            this.riskNotifications = riskNotifications.RiskCompanyCustomerMappingListComponent;
            this.specificRisk = specificRisk.SpecificRiskListComponent;
            this.ianNotifications = ianNotifications.IANCompanyCustomerMappingListComponent;
            this.seoRedirections = seoRedirections.SeoRedirectionListComponent;
            this.productTypes = productTypes.ProductTypeListComponent;
            this.trainingTypes = trainingTypes.TrainingTypeListComponent;
            this.bom = bom.BomListComponent;
            this.gemstonetype = gemstonetype.GemstoneListComponent;
            this.grievanceTier = grievanceTier.GrievanceTierListComponent;
            this.grievanceType = grievanceType.GrievanceTypeListComponent;
            this.riskCategory = riskCategory.RiskCategoryListComponent;
            this.gemfieldsTreatment = gemfieldsTreatment.GemfieldsTreatmentListComponent;
            this.watchStatusList = watchStatusList.WatchStatusListComponent;
            this.watchLegalOwnerList = watchLegalOwnerList.LegalOwnerListComponent;
            this.watchItemTypesList = watchItemTypesList.WatchItemTypeListComponent;
            this.watchMaterialList = watchMaterialList.WatchMaterialListComponent;
            this.gemstoneCutTypeList = gemstoneCutTypeList.GemStoneCutTypeListComponent;
            this.materialList = materialList.MaterialListComponent;
            this.deliveryTypeList = deliveryTypeList.DeliveryTypeListComponent;
            this.duplicateCustomerList = duplicateCustomerList.DuplicateCustomerListComponent;
            this.apiKeysList = apiKeysList.ApiKeyListComponent;

            //reports >> people

            this.teamLoginReport = teamLoginReport.FabergeTeamLoginReportListComponent;
            this.repeatCustomerReport = repeatCustomerReport.RepeatCustomerReportListComponent;
            this.customerStatisticReport = customerStatisticReport.CustomerStatisticsListComponent;
            this.customerLastContactedReport = customerLastContactedReport.CustomerLastContactedListComponent;
            this.teamMemberReport = teamMemberReport.TeamMemberReportListComponent;
            this.travelReport = travelReport.TravelReportListComponent;
            this.leaveReport = leaveReport.LeaveReportListComponent;
            this.itHelpDeskWeeklyReport = itHelpDeskWeeklyReport.ITHelpDeskWeeklyReportListComponent;
            this.clientSalesReport = clientSalesReport.ClientSalesReportListComponent;
            this.SalesAdvisorReport = SalesAdvisorReport.SalesAdvisorReportListComponent;
            this.SalesAnalysisReport = SalesAnalysisReport.SalesAnalysisReportListComponent;
            this.SalesMarginReport = SalesMarginReport.SalesMarginReportListComponent;
            this.WholeSaleReport = WholeSaleReport.WholeSaleReportListComponent;

            //reports >> hse
            this.hseReport = hseReport.HseReportListComponent;

            //reports >> GINA
            this.gINAReport = gINAReport.GINAReportComponent;

            //reports >> notes
            this.notesReport = notesReport.NotesReportListComponent;

            //Press And Media Report
            this.pressMediaReport = pressMediaReport.PressMediaReportListComponent;

            //faberge Invetory Reports
            this.missingProductDescriptionList = missingProductDescriptionList.MissingProductDescriptionListComponent;
            this.bespokeOrderReportList = bespokeOrderReportList.BespokeOrderReportListComponent;
            this.bOMReportList = bOMReportList.BOMReportListComponent;
            this.coreProductListReportList = coreProductListReportList.CoreProductListReportListComponent;
            this.designProductSuccessReportList = designProductSuccessReportList.DesignProductSuccessReportListComponent;
            this.financeReportList = financeReportList.FinanceReportListComponent;
            this.gemstoneSetPieceReportList = gemstoneSetPieceReportList.GemstoneSetPieceReportListComponent;
            this.inventoryLocationSummaryReportList = inventoryLocationSummaryReportList.InventoryLocationSummaryReportListComponent;
            this.inventorySummaryReportList = inventorySummaryReportList.InventorySummaryReportListComponent;
            this.locationInventorySummaryByCollectionReportList = locationInventorySummaryByCollectionReportList.LocationInventorySummaryByCollectionReportListComponent;
            this.missingProductPictureList = missingProductPictureList.MissingProductPictureListComponent;
            this.pieceInproductionReportList = pieceInproductionReportList.PieceInproductionReportListComponent;
            this.pieceOwnerReportList = pieceOwnerReportList.PieceOwnerReportListComponent;
            this.priceBandOptionReportList = priceBandOptionReportList.PriceBandOptionReportListComponent;
            this.productCaptionReportList = productCaptionReportList.ProductCaptionReportListComponent;
            this.soldPieceReportList = soldPieceReportList.SoldPieceReportListComponent;
            this.stockByLocationAnalyserReportList = stockByLocationAnalyserReportList.StockByLocationAnalyserReportListComponent;
            this.supplierProductionReportList = supplierProductionReportList.SupplierProductionReportListComponent;
            this.timePiecesSalesReportList = timePiecesSalesReportList.TimePiecesSalesReportListComponent;
            this.repairReportList = repairReportList.RepairReportListComponent;
            this.inventoryHistoryReportList= inventoryHistoryReportList.InventoryHistoryReportListComponent;

            //order declarations start
            this.orders = orders.OrderListComponent;
            this.returnOrders = returnOrders.ReturnOrderListComponent;
            this.purchaseList = purchaseList.CurrentPurchaseListComponent;

             //Trackme
             this.liveusertracking = liveusertracking.LiveUserTrackingComponent;
             this.timelineusertracking = timelineusertracking.TimelineUserTrackingComponent;

            //inventory declarations start
            this.productDesign = productDesign.ProductDesignListComponent;
            this.productList = productList.ProductListComponent;
            this.pieceList = pieceList.ProductPieceListComponent;
            this.pieceGemstone = pieceGemstone.ProductPieceGemstoneListComponent;
            this.gemstoneOrders = gemstoneOrders.GemstoneOrderListComponent;
            this.bookingList = bookingList.BookingListComponent;
            this.bookingApproval = bookingApproval.BookingApprovalListComponent;
            this.priceList = priceList.FabergePriceListComponent;
            this.carnets = carnets.CarnetListComponent;
            this.watchParts = watchParts.WatchPartListComponent;
            this.batchList = batchList.ProductBatchSectionListComponent;

            //Forms Declaration Start
            this.legalAgreementList = legalAgreementList.LegalAgreementListComponent;
            this.pressMediaDetailList = pressMediaDetailList.PressMediaDetailListComponent;
            this.productionForm = productionForm.ProductionOrderFormListComponent;
            this.electronicGiftForm = electronicGiftForm.ElectronicGiftFormListComponent;
            this.riskMenu = riskMenu.RiskMenuDetailListComponent;
            this.hseForm = hseForm.TrackIncidentListComponent;

            //mines menu
            this.minesList = minesList.MinesComponent

            //cms menu start
            this.homeLayout = homeLayout.HomeLayoutListComponent;
            this.fabergePages = fabergePages.TopicListComponent;
            this.fabergeNews = fabergeNews.NewsListComponent;
            this.menuLayout = menuLayout.MainMenuItemListComponent;
            this.mirFaberge = mirFaberge.HistoryMirFabergeListComponent;

            //Help & training start
            this.helpTraining = helpTraining.HelpAndTrainningDetailsComponent

            //pos menu start
            this.pos = pos.POSCustomerSearchListComponent;

            //it ticket update menu start
            this.ticketDetails = ticketDetails.TicketDetailListComponent;
            this.ItAssets = ItAssets.ItAssetsListComponent;
            //advanced search menu start
            this.advancedSearch = advancedSearch.FabergeAdvancedSearchListComponent;

            //mind map start
            this.mindMap = mindMap.MindMapListComponent;
            this.showSkeletonLoading = false;


        })
    }
    getTooltipText(menuType: string): string {

        return this.isFavorite(menuType) ? this.titleRemoveFromFavorites : this.titleAddToFavorites;

    }

    ngOnInit() {
        this.lazyImportComponents();
        this.onResize(null);
        this.titleAddToFavorites = getLocalizedValue('Title_Add_to_Favorites');
        this.titleRemoveFromFavorites = getLocalizedValue('Title_Remove_From_Favorites')
        this.mainMenuList = new Array<string>();
        this.mainMenuList = ['inventory', 'people', 'sales-order', 'reports','it-support',
            'forms', 'cms', 'help-and-training'];
        this.listMenus = new Array<string>();
        this.listMenus = ['inventory', 'people', 'sales-order', 'events', 'projects', 'reports',
            'news', 'faberge', 'system', 'gemfield', 'ticket-details','it-assets', 'dashboard','it-support',
            'p-o-s-customer-search', 'forms', 'cms', 'help-and-training', 'suppliers','view-user-tracking']


        let dbAccessModule: any;

        this.bindFormsMenu();
        setTimeout(() => {
            sideBarScroll();
        }, 500);
        var json = {
            IsFavourite: true,
            StatusId: 307
        }
        this.post({body: { query: JSON.stringify(json) },path:"api/SearchCommon/GetFavourites"}).subscribe((res:any)=>{

            this.favList=res;
            this.favorites =this.favList.map((item: any) => item.ModuleName);
        })


        this.get<string>({ path: "api/Authorize/access", }).subscribe(response => {
            dbAccessModule = JSON.parse(response);
            if (dbAccessModule != undefined && dbAccessModule[RolePermissionEnums.HelpTraining]["get"]) {
                this.showhelpTraining = true;
                this.bindMenu();
            }

        })

        this.showpowerBIReports = true;
        this.bindpowerBIReportMenus();

        if (sessionStorage.getItem("selectedRootModule") == undefined) {
            this.activeSubMenu = '';
            sessionStorage.setItem("selectedRootModule", '');
            this.activeMenu = 'dashboard';
            sessionStorage.setItem("selectedModule", 'dashboard');
        }
        else {
            this.activeSubMenu = sessionStorage.getItem("selectedRootModule");
            this.activeMenu = sessionStorage.getItem("selectedModule");
        }
        setTimeout(() => {
            this.activeMenu = window.location.href.split('/')[3];
            this.activeSubMenu = window.location.href.split('/')[3];
            this.activeSubChildMenu = window.location.href.split('/')[3];
            this.setActiveMenuSubMenu();
        }, 0);

        this.post({ body: {}, path: "api/FormLookups/FormMenuLinkList" }).subscribe((respons: any) => {
            this.formMenuLinkList = respons;
            this.formMenuLinkList = this.formMenuLinkList[0];
        })

    }

    setActiveMenuSubMenu() {
       
        if (this.activeSubMenu == 'product-design' || this.activeSubMenu == 'product' || this.activeSubMenu == 'product-batch' || this.activeSubMenu == 'batch-section' || this.activeSubMenu == 'product-piece' || this.activeSubMenu == 'product-piece-gemstones' || this.activeSubMenu == 'gemstone-orders'
            || this.activeSubMenu == 'components' || this.activeSubMenu == 'bookings' || this.activeSubMenu == 'booking-approval' || this.activeSubMenu == 'faberge-price-lists' || this.activeSubMenu == 'carnets' || this.activeSubMenu == 'product-piece-watches'
            || this.activeSubMenu == 'watch-parts') {
            this.activeMenu = "inventory";
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'users' || this.activeSubMenu == 'internal-directory'
            || this.activeSubMenu == 'faberge-customer-call-me' || this.activeSubMenu == 'newsletter-subscriber') {
            this.activeMenu = "people";
            this.toggleMenu = true;
        }

        if (this.activeSubMenu == 'production-order-forms' || this.activeSubMenu == 'electronic-gift-forms') {
            this.activeMenu = "forms"; //#298241//09-09-2024//module focus when double click is resolved
            this.toggleMenu = true;
        }

        if (this.activeSubMenu == 'pointof-sales') {
            this.activeMenu = "people";
            this.activeSubMenu = "suppliers"
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'content-management-news' || this.activeSubMenu == 'history-mir-faberge' ||
            this.activeSubMenu == 'faberge-pages' || this.activeSubMenu == 'home-layouts' || this.activeSubMenu == 'menus') {
            this.activeMenu = "cms";
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'track-incidents') {
            this.activeMenu = "track-incidents";
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'press-media-detail' || this.activeMenu == 'news') {
            this.activeMenu = "pressMediaResearch";
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'orders' || this.activeSubMenu == 'current-purchase-list' || this.activeSubMenu == 'return-orders') {
            this.activeMenu = "sales-order";
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'live-user-tracking' || this.activeSubMenu == 'timeline-user-tracking') {
            this.activeMenu = "View User Tracking";
            this.toggleMenu = true;
        }
        if (this.activeSubMenu == 'ticket-details' || this.activeSubMenu == 'it-assets'  ) {
            this.activeMenu = "it-support";
            this.toggleMenu = true;
        }

        if (this.activeSubChildMenu == 'missing-product-description-report' || this.activeSubChildMenu == 'piece-owner-report' || this.activeSubChildMenu == 'missing-product-picture-report'
            || this.activeSubChildMenu == 'inventory-summary-report' || this.activeSubChildMenu == 'inventory-location-summary-report' || this.activeSubChildMenu == 'location-inventory-summary-by-collection-report'
            || this.activeSubChildMenu == 'bom-report' || this.activeSubChildMenu == 'sold-piece-report' || this.activeSubChildMenu == 'bespoke-order-report'
            || this.activeSubChildMenu == 'product-caption-report' || this.activeSubChildMenu == 'core-product-list-report'
            || this.activeSubChildMenu == 'gemstone-set-piece-report' || this.activeSubChildMenu == 'piece-inproduction-report'
            || this.activeSubChildMenu == 'timepieces-sales-report' || this.activeSubChildMenu == 'finance-report'
            || this.activeSubChildMenu == 'supplier-production-report'
            || this.activeSubChildMenu == 'stock-by-location-analyser-report' || this.activeSubChildMenu == 'design-product-success-report' || this.activeSubChildMenu == 'price-band-option-report') {
            this.activeMenu = "reports";
            this.activeSubMenu = "faberge-inventory";
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = true;
        }

        if (this.activeSubMenu == 'faberge-sales-orders' || this.activeMenu == 'inventory-comment-report' || this.activeSubMenu == 'people' || this.activeSubMenu == 'faberge-inventory' || this.activeSubMenu == 'mines' || this.activeSubMenu == 'note-report' || this.activeSubMenu == 'hse-report' || this.activeSubMenu == 'g-i-n-a-report' || this.activeSubMenu == 'press-media-report') {
            this.activeMenu = "reports";
            this.activeSubMenu = this.activeSubMenu;
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = false;
        }

        if (this.activeSubMenu == "powerbireports") {
            this.activeMenu = "reports";
            this.activeSubMenu = this.activeSubMenu;
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = true;
        }

        if (this.activeMenu == 'travel-report' || this.activeMenu == 'team-member-report' || this.activeMenu == 'customer-last-contacted' || this.activeMenu == 'leave-report'
            || this.activeMenu == 'customer-statistics' || this.activeMenu == 'repeat-customers-report' || this.activeMenu == 'faberge-team-login-report' || this.activeMenu == 'i-t-help-desk-weekly-report' || this.activeMenu.indexOf('users') != -1) {
            this.activeMenu = "reports";
            this.activeSubMenu = "people";
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = false;
        }
        if (this.activeMenu.indexOf('press-media-detail') != -1) {
            this.activeMenu = "pressMediaResearch";
            this.activeSubMenu = "press-media-detail";
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = false;
        }
        if (this.activeMenu.indexOf('users') != -1) {
            this.activeMenu = "people";
            this.activeSubMenu = "users";
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = false;
        }
        if (this.activeMenu == 'wholesale-report' || this.activeMenu == 'client-sales-report' || this.activeMenu == 'sales-advisor-report'
            || this.activeMenu == 'sales-margin-report' || this.activeMenu == 'sales-analysis-report') {
            this.activeMenu = "reports";
            this.activeSubMenu = "faberge-sales-orders";
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = false;
        }
        if (this.activeMenu == 'help-and-training') {
            this.activeMenu = "help";
            //this.activeSubMenu="faberge-sales-orders";
            this.toggleMenu = true;
            this.toggleSecondLevelMenu = false;
        }

        if (this.activeSubMenu == "bom" || this.activeSubMenu == "categories" || this.activeSubMenu == "gemstone") {
            this.toggleMenu = true;
            this.activeMenu = "faberge";
        }     
    }

    isToggleSideBar(isNotClicked?:boolean) {
        if(this.innerWidth >= 769 || isNotClicked) {
            var element = document.getElementById("gtrac");
            var navBar = document.getElementById("side-menu");
            this.isSideBarActive = !this.isSideBarActive
            if (this.isSideBarActive) {
                element.classList.add("mini-navbar");
                if(navBar!=null) navBar.classList.add("mini-navbar-side-menu");
                this.btnText = getLocalizedValue("Sidebar_Open");
                this.hideSubMenuOnScroll();
            }
            else {
                element.classList.remove("mini-navbar");
                navBar.classList.remove("mini-navbar-side-menu");
                this.btnText = getLocalizedValue("Sidebar_Close");
                setTimeout(() => {
                    if ((document.querySelector(".nav.nav-second-level.collapse") as HTMLElement) != undefined) {
                        (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.display = 'block';
                    }
                }, 500);
            }
        }
    }
    bindMenu() {
        this.spin = true;
        this.subscription = this.get({ path: "api/HelpTrainings", queryParams: { helpAndTrainning: 1 } }).subscribe((t: any) => {
            this.spin = false;
            this.menuList = t;
            this.menuList.forEach(element => {
                if (element.parentHelpSupportId == 0) {
                    this.parentMenuList.push(element);
                } else {
                    this.childMenuList.push(element);
                }
            });
            this.checkHelpAndTrainingActiveMenues();
            this.showComponent = true;
        });
    }

    bindpowerBIReportMenus(){
        this.spin = true;
        this.subscription = this.get({ path: "api/PowerBIRepoerts", queryParams: { powerBIRepoert: 1 } }).subscribe((t: any) => {
            this.spin = false;
            this.powerBIReportMenuList = t;
            if (this.powerBIReportMenuList != undefined && this.powerBIReportMenuList.length > 0) {
                this.showpowerBIReports = true;
                this.checkPowerBIReportActiveMenues();
                this.showComponent = true;
            }
            else{
                this.showpowerBIReports = false;
            }

        });
    }

    checkPowerBIReportActiveMenues() {
        setTimeout(() => {
            if (this.powerBIReportMenuList != undefined && this.powerBIReportMenuList.length > 0) {
                let currentSubMenu = window.location.href.split('/')[3];
                let activeMenuId = +window.location.href.split('/')[4];
                if ((currentSubMenu == "power-bi-reports" || currentSubMenu == "powerbireports") && (activeMenuId != null && activeMenuId != undefined && activeMenuId > 0)) {
                    this.activeMenu = "reports";
                    this.activeSubMenu=="powerbireports";
                    // if (this.powerBIReportMenuList.findIndex(x => x.powerBIReportId == activeMenuId) > 0) {
                    if (this.powerBIReportMenuList.findIndex(x => x.powerBIReportId == activeMenuId) != -1) {
                        this.activeSubSubMenu = this.powerBIReportMenuList.find(x => x.powerBIReportId == activeMenuId).powerBIReportName;
                        // this.activeSubChildMenu = this.powerBIReportMenuList.find(x => x.powerBIReportId == activeMenuId).powerBIReportName;
                        this.toggleMenu = true;
                        this.toggleSecondLevelMenu = true;
                    } else{
                        this.activeSubSubMenu = "";
                    }

                        // this.activeSubMenu = this.powerBIReportMenuList.find(x => x.powerBIReportId == activeMenuId).powerBIReportName;
                        // this.activeSubSubMenu = "";
                        // this.toggleSecondLevelMenu = true;
                }
            }
        }, 0);
    }


    bindFormsMenu() {
        this.spin = true;
        this.subscription = this.get({ path: "api/FormsDocument" }).subscribe((t: any) => {
            sideBarScroll();
            this.spin = false;
            this.formsMenuList = new List<FormsDocument>();
            this.formsMenuArr = new Array<FormsDocument>();
            this.formsGroupBusiness = new Array<FormsDocument>();
            this.formsEmployees = new Array<FormsDocument>();
            this.formsFaberge = new Array<FormsDocument>();
            // this.formsMenuList = t;

            t.forEach(element => {

                let listData = new FormsDocument();
                listData.name = element.name;
                listData.FormId = element.FormId;
                listData.filename = element.filename;
                listData.displayOrderId = element.displayOrderId;
                listData.isActive = element.isActive;
                listData.published = element.published;
                listData.fileUrl = element.fileUrl;
                this.formsMenuList.add(listData);

            });


            this.formsMenuList = this.formsMenuList.orderBy(x => x.displayOrderId);

            this.formsMenuList.forEach(element => {
                if (element.published && element.name.trim() != 'Faberge Supplier Compliance' &&
                    element.name.trim() != 'Gemfields Supplier Compliance' &&
                    element.name.trim() != 'Customer Compliance' &&
                    element.name.trim() != 'New Supplier Form Navision' &&
                    element.name.trim() != 'Auction Application Form' &&
                    element.name.trim() != 'Mozambican Visa Application Form' &&
                    element.name.trim() != 'Conflict Interest Declaration Form' &&
                    element.name.trim() != 'Site Visit Forms' &&
                    element.name.trim() != 'Lost Receipt Form' &&
                    element.name.trim() != 'Share Dealing Consent Application' &&
                    element.name.trim() != 'Expense Claim Form' &&
                    element.name.trim() != 'Creative Project Briefing Form' &&
                    element.name.trim() != 'Receiving/Providing A Gift' &&
                    element.name.trim() != 'Thief Description Form'
                ) {
                    this.formsMenuArr.push(element);
                }
            });

            this.formsMenuList.forEach(element => {
                if (element.published && element.name.trim() == 'Faberge Supplier Compliance' ||
                    element.name.trim() == 'Gemfields Supplier Compliance' ||
                    element.name.trim() == 'Customer Compliance' ||
                    element.name.trim() == 'New Supplier Form Navision'
                ) {
                    this.formsGroupBusiness.push(element);
                }
            });

            this.formsMenuList.forEach(element => {
                if (element.published && element.name.trim() == 'Mozambican Visa Application Form' ||
                    element.name.trim() == 'Conflict Interest Declaration Form' ||
                    element.name.trim() == 'Site Visit Forms' ||
                    element.name.trim() == 'Lost Receipt Form' ||
                    element.name.trim() == 'Share Dealing Consent Application' ||
                    element.name.trim() == 'Expense Claim Form' ||
                    element.name.trim() == 'Creative Project Briefing Form' ||
                    element.name.trim() == 'Receiving/Providing A Gift'
                ) {
                    this.formsEmployees.push(element);
                }
            });

            this.formsMenuList.forEach(element => {
                if (element.published && element.name.trim() == 'Thief Description Form'
                ) {
                    this.formsFaberge.push(element);
                }
            });

            // this.checkFormsActiveMenues();
            this.showComponent = true;
        });
    }
    checkHelpAndTrainingActiveMenues() {
        setTimeout(() => {
            if (this.menuList != undefined && this.menuList.length > 0) {
                let currentSubMenu = window.location.href.split('/')[3];
                let activeMenuId = +window.location.href.split('/')[4];
                if (currentSubMenu == "help-and-training" && (activeMenuId != null && activeMenuId != undefined && activeMenuId > 0)) {
                    this.activeMenu = "help";
                    if (this.menuList.find(x => x.helpSupportId == activeMenuId).parentHelpSupportId > 0) {
                        this.activeSubMenu = this.menuList.find(y => y.helpSupportId == (this.menuList.find(x => x.helpSupportId == activeMenuId).parentHelpSupportId)).name;
                        this.activeSubSubMenu = this.menuList.find(x => x.helpSupportId == activeMenuId).name;
                        this.toggleMenu = true;
                        this.toggleSecondLevelMenu = true;
                    } else {
                        this.activeSubMenu = this.menuList.find(x => x.helpSupportId == activeMenuId).name;
                        this.activeSubSubMenu = "";
                        this.toggleMenu = true;
                    }
                }
            }
        }, 0);
    }


    // changeActiveMenu(activeMenu: string): void {
    //   if (this.activeMenu == activeMenu) {
    //     this.toggleMenu = !this.toggleMenu;
    //     if(!this.toggleMenu){
    //       this.activeMenu="";
    //     }
    //   }
    //    else {
    //     this.toggleMenu = true;
    //     this.activeMenu = activeMenu;
    //     this.activeSubMenu="";
    //     this.activeSubChildMenu="";
    //    // sessionStorage.setItem("selectedModule", activeMenu);
    //   }
    // }

  changeActiveMenu(activeMenu: string, event: MouseEvent): void {

        if(activeMenu == 'View User Tracking'){
            injectgoogleapi(true);
        }else{
            injectgoogleapi(false);
        }
        const target = event.target as HTMLElement;
        if (target.tagName !== 'I') {
            if (this.activeMenu == activeMenu) {
                this.toggleMenu = !this.toggleMenu;
                // if (!this.toggleMenu) {
                //     this.activeMenu = ""; //#298241//02-09-2024//Remove sidebar deactive menu feature, from now only we can select sidebarmenu
                // }
            }
            else {
                this.toggleMenu = true;
                this.activeMenu = activeMenu;
                this.activeSubMenu = "";
                this.activeSubChildMenu = "";
                sessionStorage.setItem("selectedModule", activeMenu);
            }
        }
    }

    handleChangeMenu(item:string, event: MouseEvent):void{
        this.changeActiveMenu(item, event);
        this.changeActiveSubMenu(item, event);
        this.changeActiveChildMenu(item, event);
        this.setActiveMenuSubMenu(); //#298241//09-09-2024//adding active menu method to focus on modules when click more than one

  }


    addToFavorites(activeMenu: string, event: MouseEvent, dynamicMenu: string = '') {
        event.preventDefault();
        event.stopPropagation();
        var LocalizationKey = this.getColumnName(activeMenu);
        var className = this.getClassNames(activeMenu);
        if (dynamicMenu == 'powerBI') {
            let data = this.powerBIReportMenuList.find(x => x.powerBIReportName == activeMenu);
            var json = {
                ModuleLink: '/powerbireports/' + data.powerBIReportId,
                ModuleName: activeMenu,
                IsFavourite: true,
                StatusId: 307,
                LocalizationKey: LocalizationKey,
                ClassKey: "fr-reports"
            }
            if (!this.isFavorite(activeMenu)) {
                this.favorites.push(activeMenu);
            } else {
                // Remove from favorites
                const index = this.favorites.indexOf(activeMenu);
                if (index !== -1) {
                    this.favorites.splice(index, 1);
                }
            }
            var getJson = {
                IsFavourite: true,
                StatusId: 307
            }
            this.post({ body: json, path: "api/SearchCommon/Favourites" }).subscribe((response: any) => {
                this.favList = response;
            })
        }
        else if (dynamicMenu == "help") {
            let data = this.childMenuList.find(x => x.name == activeMenu);

            var json = {
                ModuleLink: '/help-and-training/' + data.helpSupportId,
                ModuleName: activeMenu,
                IsFavourite: true,
                StatusId: 307,
                LocalizationKey: LocalizationKey,
                ClassKey: "fr-help"
            }
            if (!this.isFavorite(activeMenu)) {
                this.favorites.push(activeMenu);
            } else {
                // Remove from favorites
                const index = this.favorites.indexOf(activeMenu);
                if (index !== -1) {
                    this.favorites.splice(index, 1);
                }
            }
            var getJson = {
                IsFavourite: true,
                StatusId: 307
            }
            this.post({ body: json, path: "api/SearchCommon/Favourites" }).subscribe((response: any) => {
                this.favList = response;
            })
        }
        else if (dynamicMenu == "it-assets") {
            let data = this.childMenuList.find(x => x.name == activeMenu);

            var json = {
                ModuleLink: '/it-assets/desktop' ,
                ModuleName: activeMenu,
                IsFavourite: true,
                StatusId: 307,
                LocalizationKey: LocalizationKey,
                ClassKey: className
            }
            if (!this.isFavorite(activeMenu)) {
                this.favorites.push(activeMenu);
            } else {
                // Remove from favorites
                const index = this.favorites.indexOf(activeMenu);
                if (index !== -1) {
                    this.favorites.splice(index, 1);
                }
            }
            var getJson = {
                IsFavourite: true,
                StatusId: 307
            }
            this.post({ body: json, path: "api/SearchCommon/Favourites" }).subscribe((response: any) => {
                this.favList = response;
            })
        }
        else{
            var json = {
                ModuleLink: '/' + activeMenu,
                ModuleName: activeMenu,
                IsFavourite: true,
                StatusId: 307,
                LocalizationKey: LocalizationKey,
                ClassKey: className
            }
            if (!this.isFavorite(activeMenu)) {
                this.favorites.push(activeMenu);
            } else {
                // Remove from favorites
                const index = this.favorites.indexOf(activeMenu);
                if (index !== -1) {
                    this.favorites.splice(index, 1);
                }
            }
            var getJson = {
                IsFavourite: true,
                StatusId: 307
            }
            this.post({ body: json, path: "api/SearchCommon/Favourites" }).subscribe((response: any) => {
                this.favList = response;
            })
        }
    }

    handleStarIconHover(menu): void {
        this.hovering[menu] = !this.hovering[menu];
    }

    isFavorite(item: any): boolean {
        return this.favorites.includes(item);
    }
    getColumnName(key: string) {
        switch (key) {
            case "dashboard":
                return 'DashBoardComponent_Title_t';
            case "faberge-advanced-search":
                return "FabergeAdvancedSearchListComponent_Title";
            case "content-management-news":
                return "Label_Faberge_News_t";
            case "history-mir-faberge":
                return "HistoryMirFabergeListComponent_Title_t";
            case "faberge-pages":
                return "Label_Faberge_Pages_t";
            case "home-layouts":
                return "Label_Home_Page_Layout_t";
            case "menus":
                return "Label_Menu_Layout_t";
            case "suppliers":
                return "Label_Corporates_Groups_t";
            case "events":
                return "Label_Events_t";
            case "production-order-forms":
                return "Label_Faberge_Production_Order_Form_t";
            case "electronic-gift-forms":
                return "Label_Receiving_Gift_or_Hospitality_Form_t";
            case "track-incidents":
                return "Label_HSE/GINA_t";
            case "product-design":
                return "Label_Faberge_Designs_t";
            case "product":
                return "Label_Faberge_Products_t";
            case "batch-section":
                return "Label_Faberge_Batches_t";
            case "product-piece":
                return "Label_Faberge_Pieces_t";
            case "product-piece-gemstones":
                return "Label_Faberge_Gemstones_t";
            case "gemstone-orders":
                return "Label_Faberge_Gemstones_Orders_t";
            case "watch-parts":
                return "WatchParts_ModuleHeader_t";
            case "bookings":
                return "Label_Bookings_t";
            case "booking-approval":
                return "Label_Bookings_Approval_t";
            case "faberge-price-lists":
                return "Label_Faberge_Price_Lists_t";
            case "carnets":
                return "Label_Faberge_Carnets_t";
            case "ticket-details":
                return "Label_IT_Help_Desk_t";
            case "it-assets":
                return "Label_IT_Assets_t";
            case "legal-agreement":
                return "Label_Legal_Agreements_t";
            case "people":
                return "Label_People_t";
            case "users":
                return "Label_People_t";
            case "internal-directory":
                return "Label_Internal_Directory_t";
            case "faberge-customer-call-me":
                return "Label_Faberge_Customer_Call_Me_t";
            case "newsletter-subscriber":
                return "Label_Newsletter_Subscribers_t";
            case "p-o-s-customer-search":
                return "Label_POS_t";
            case "press-media-detail":
                return "Label_Press_and_Media_t";
            case "news":
                return "Label_PressMedia_News_t";
            case "projects":
                return "Label_Projects_t";
            case "faberge-inventory":
                return "Label_Faberge_Inventory_t";
            case "faberge-sales-orders":
                return "Label_Faberge_Sales_Orders_t";
            case "note-report":
                return "Label_Notes_t";
            case "hse-report":
                return "Label_HSE_GINA_Report_t";
            case "g-i-n-a-report":
                return "Label_Group_wide_HSE_GINA_Report_t";
            case "press-media-report":
                return "Label_Press_Media_Report_t";
            case "mines":
                return "Label_Mining_Performance_t";
            case "gemfield-risk-menu":
                return "Label_Risk_Menu_t";
            case "orders":
                return "Label_Faberge_Sales_Orders_t";
            case "return-orders":
                return "Label_Faberge_Return_Orders_t";
            case "current-purchase-list":
                return "Label_Current_Purchase_Lists_t";
            case "help":
                return "Label_Help_Training_t";
            case "faberge":
                return "Label_Faberge_Admin_t";
            case "gemfield":
                return "Label_Gemfields_Admin_t";
            case "system":
                return "Label_System_t";
            case "mind-map":
                return "Label_Visual_Mind_Map_t";
            case "timeline-user-tracking":
                return "Label_Timeline_User_Traking_t";
            case "live-user-tracking":
                return "Label_Live_User_Tracking_t";
            default:
                return ""
        }
    }
    getClassNames(key: string) {
        switch (key) {
            case "dashboard":
                return 'fr-dashboard';
            case "faberge-advanced-search":
                return "fr-pos";
            case "suppliers":
                return "fa fa-th-large";
            case "events":
                return "fr-event";
            case "production-order-forms":
                return "fr-form";
            case "electronic-gift-forms":
                return "fr-form";
            case "track-incidents":
                return "fr-form";
            case "content-management-news":
                return "fr-content-management";
            case "history-mir-faberge":
                return "fr-content-management";
            case "faberge-pages":
                return "fr-content-management";
            case "home-layouts":
                return "fr-content-management";
            case "menus":
                return "fr-content-management";
            case "product-design":
                return "fr-inventory";
            case "product":
                return "fr-inventory";
            case "batch-section":
                return "fr-inventory";
            case "product-piece":
                return "fr-inventory";
            case "product-piece-gemstones":
                return "fr-inventory";
            case "gemstone-orders":
                return "fr-inventory";
            case "watch-parts":
                return "fr-inventory";
            case "bookings":
                return "fr-inventory";
            case "booking-approval":
                return "fr-inventory";
            case "faberge-price-lists":
                return "fr-inventory";
            case "carnets":
                return "fr-inventory";
            case "ticket-details":
                return "fa fa-ticket";
            case "it-assets":
                return "fa fa-ticket";
            case "legal-agreement":
                return "fr-form";
            case "people":
                return "fr-reports";
            case "users":
                return "fa fa-users";
            case "internal-directory":
                return "fa fa-users";
            case "faberge-customer-call-me":
                return "fa fa-users";
            case "newsletter-subscriber":
                return "fa fa-users";
            case "users":
                return "fa fa-users";
            case "p-o-s-customer-search":
                return "fr-pos";
            case "press-media-detail":
                return "fr-news";
            case "news":
                return "fr-news";
            case "projects":
                return "fr-project";
            case "faberge-inventory":
                return "fr-reports";
            case "faberge-sales-orders":
                return "fr-reports";
            case "note-report":
                return "fr-reports";
            case "hse-report":
                return "fr-reports";
            case "g-i-n-a-report":
                return "fr-reports";
            case "press-media-report":
                return "fr-reports";
            case "mines":
                return "fr-reports";
            case "gemfield-risk-menu":
                return "fr-form";
            case "orders":
                return "fr-order";
            case "return-orders":
                return "fr-order";
            case "current-purchase-list":
                return "fr-order";
            case "help":
                return "Label_Help_Training_t";
            case "faberge":
                return "fr-f-admin";
            case "gemfield":
                return "fr-g-admin";
            case "system":
                return "fr-system";
            case "mind-map":
                return "fa fa-code-fork ml-1";
            case "timeline-user-tracking":
                return "fa fa-solid fa-street-view";
            case "live-user-tracking":
                return "fa fa-solid fa-street-view";
            default:
                return ""
        }
    }
    changeActiveSubMenu(activeSubMenu: string, event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (target.tagName !== 'I') {
            if (this.activeSubMenu == activeSubMenu) {
                this.toggleSecondLevelMenu = !this.toggleSecondLevelMenu;
            } else {
                this.toggleSecondLevelMenu = true;
                this.activeSubChildMenu = "";
                this.activeSubSubMenu = "";
                this.activeSubMenu = activeSubMenu; //+ "?isRelaod=false";
                sessionStorage.setItem("selectedRootModule", activeSubMenu);
            }
        }
    }

    changeActiveChildMenu(activeSubChildMenu: string, event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (target.tagName !== 'I') {
            if (this.activeSubChildMenu == activeSubChildMenu) {
                this.toggleThirdLevelMenu = !this.toggleThirdLevelMenu;
            } else {
                this.toggleSecondLevelMenu = true;
                this.toggleThirdLevelMenu = true;
                this.activeSubChildMenu = activeSubChildMenu; //+ "?isRelaod=false";
                sessionStorage.setItem("selectedRootModule", activeSubChildMenu);
            }
        }
    }

    checkHeightForSubMenu() {
        var heightFromBottom = (window.innerHeight - document.querySelector('.nav-item.active').getBoundingClientRect().top);
        (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.display = 'block';
        if (heightFromBottom >= 350) {
            (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.bottom = 'auto';
            (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.top = (document.querySelector(".nav-item.active") as HTMLElement).getBoundingClientRect().top.toString() + "px";
        }
        else {
            (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.top = 'auto';
            (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.bottom = (window.innerHeight - (document.querySelector(".nav-item.active") as HTMLElement).getBoundingClientRect().bottom).toString() + "px";
        }
    }

    hideSubMenuOnScroll() {
        if (this.isSideBarActive) {
            if ((document.querySelector(".nav.nav-second-level.collapse") as HTMLElement) != undefined)
                (document.querySelector(".nav.nav-second-level.collapse") as HTMLElement).style.display = 'none';
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

}
