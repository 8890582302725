import {SpokenLanguageBase,} from '@app/database-models'
//Generated Imports
export class SpokenLanguage extends SpokenLanguageBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}