import { maxLength, numeric, prop, required } from '@rxweb/reactive-form-validators';


export class EventSearchModel {
  @prop({ defaultValue: "" })
  eventStartDate: Date;
  @prop({ defaultValue: "" })
  eventEndDate: Date;
  @prop({ defaultValue: "" })
  eventName: string;
  @prop({ defaultValue: "" })
  eventText: string;
  @prop({ defaultValue: "" })
  eventOwnerText: string;
  @prop({ defaultValue: 0 })
  eventOwner: number;
  @prop({ defaultValue: 0 })
  eventCountry: number;
  @prop({ defaultValue: 1 })
  projectOrEvent: number;
  @prop({ defaultValue: 0 })
  eventType: number;
  @prop({ defaultValue: 0 })
  gemfieldsGroupCompanyEventOwner: number;
  @prop({ defaultValue: 0 })
  department: number;
  @prop({ defaultValue: "" })
  eventServiceTypeIds: string;
  @prop({ defaultValue: 1 })
  pageIndex: number;
  @prop({ defaultValue: 25 })
  rowCount: number;
  @prop()
  sortOrder: number;
  @prop()
  orderBy: number;
  @prop({ defaultValue: false })
  isTimeline: boolean;

  @prop()
  @numeric()
  @maxLength({value:9})
  eventId: number;

  // @prop()
  // clientURL:string;
  @prop()
  specificRiskId: string;
}
