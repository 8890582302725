import { LegalAgreementBase } from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
//Generated Imports
export class LegalAgreement extends LegalAgreementBase implements FileDetailViewModel {

    @prop()
    eventIds: string;

    @prop()
    projectIds: string;

    @prop()
    riskIds: string;

    @prop()
    counterPartyIds: LegalAgreement[];

    @prop()
    fileData: string;

    @prop()
    fileExtention: string;

    @prop()
    fileName: string;

    @prop()
    displayImage: boolean;

    @prop()
    imageURL1: string;

    @prop()
    imageURL2: string;

    @prop()
    imageURL3: string;

    @prop()
    imageURL4: string;

    @prop()
    imageURL5: string;

    @prop()
    fileData1: string;

    @prop()
    fileExtention1: string;

    @prop()
    fileName1: string;

    @prop()
    fileData2: string;

    @prop()
    fileExtention2: string;

    @prop()
    fileName2: string;

    @prop()
    productId: string;

    @prop()
    filePath: string;

    @prop()
    filePath1: string;

    @prop()
    counterPartyId: number;

    @prop()
    counterPartySupplierId: number;

    @prop()
    isCorporate: boolean;

    @prop({ defaultValue: false })
    isRemovedSignedAgreement: boolean;

    @prop({ defaultValue: false })
    isRemovedExecutionVersion: boolean;

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;
    //#region Generated Reference Properties


    //#endregion Generated Reference Properties











}