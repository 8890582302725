import { prop, required } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';

export class RiskCompanyCustomerMappingBase {

    //#region riskCompanyCustomerMappingId Prop
    @prop({ defaultValue: 0 })
    riskCompanyCustomerMappingId: number;
    //#endregion riskCompanyCustomerMappingId Prop

    //#region companyId Prop
    @required({message:"Please Select Gemfields Group Entity"})
    @prop({ defaultValue: 0 })
    companyId: number;
    //#endregion companyId Prop

    //#region customerId Prop
    @required()
    @prop({ defaultValue: 0 })
    customerId: number;
    //#endregion customerId Prop

    //#region statusId Prop
    @required()
    @prop({defaultValue: StatusEnum.Active})
    statusId: number;
    //#endregion statusId Prop

}