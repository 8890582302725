import {GemStoneColorBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class GemStoneColor extends GemStoneColorBase 
{




//#region Generated Reference Properties
//#region productPieceGemstones Prop
productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop

//#endregion Generated Reference Properties












}