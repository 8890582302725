import {
  Injector,
  Directive,
  EmbeddedViewRef,
  ViewContainerRef,
  TemplateRef,
  Input,
  ElementRef,
} from "@angular/core";
import { NgIfContext } from "@angular/common";
import { RolePermissionEnums } from "../custom-enum/role-permission-enums";
import { dbAccessModule } from "../domain/security/authorization-resolver";
import { RxHttp } from "@rxweb/http";

/**
 * Directive to Hide/Show tabs/panels based on Roles & Permissions
 *
 * Usage: Define directive *tabPermission="'<moduleName>'" in respective element
 * Refer switch cases in the getModuleIdFromName() method to get <moduleName>
 *
 * Author: Milit Panchasara
 * Date: 2023-02-20
 *
 */

@Directive({
  selector: "[tabPermission]",
})
export class TabPermission {
  private viewRef: EmbeddedViewRef<any> | null = null;
  private _context: NgIfContext = new NgIfContext();
  private dbModules;
  @Input("tabPermission") value: string;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private injector: Injector,
    private http: RxHttp
  ) {}

  ngOnInit() {
    this.dbModules = dbAccessModule;
    this.checkPermissions().then((res) => {
      this.updateView(res);
    });
  }

  private async checkPermissions(): Promise<boolean> {
    let isAllowed: boolean = false;
    let moduleDependencies: string[] = this.value.split(",");
    let moduleId: number = 0;
    for (const moduleName of moduleDependencies) {
      if (moduleName == "") {
        isAllowed = true;
        break;
      }
      moduleId = this.getModuleIdFromName(moduleName);
      if (this.dbModules == undefined || this.dbModules == null) {
        let res = await this.http
          .get<string>({ path: "api/Authorize/access" })
          .toPromise();
          if(res != null) {
            this.dbModules = JSON.parse(res);
          }
      }
     
      try {
        isAllowed = moduleId != 0 ? this.dbModules[moduleId]["get"] : false;
      } catch (error) {
        console.log(error);
        isAllowed = false;
      }
      if (isAllowed) {
        break;
      }
    }
    return isAllowed;
  }

  private getModuleIdFromName(moduleName: string) {
    let moduleId: number = 0;
    switch (moduleName.trim().toLowerCase()) {
      case "people":
        moduleId = RolePermissionEnums.User;
        break;

      case "corporate":
        moduleId = RolePermissionEnums.Supplier;
        break;

      case "project":
        moduleId = RolePermissionEnums.Project;
        break;

      case "event":
        moduleId = RolePermissionEnums.Event;
        break;

      case "press-media":
        moduleId = RolePermissionEnums.PressandMedia;
        break;

      case "legal-agreement":
        moduleId = RolePermissionEnums.LegalAgreements;
        break;

      case "risk":
        moduleId = RolePermissionEnums.GemfieldsRiskMenu;
        break;

      case "hse":
        moduleId = RolePermissionEnums.HSE;
        break;

      case "product":
        moduleId = RolePermissionEnums.Products;
        break;
      case "batches":
        moduleId = RolePermissionEnums.FabergeBatches;
        break;

      case "design":
        moduleId = RolePermissionEnums.ProductDesign;
        break;

      case "product-piece":
        moduleId = RolePermissionEnums.Pieces;
        break;

      case "gemstone":
        moduleId = RolePermissionEnums.FabergeGemstones;
        break;

      case "gemstone-order":
        moduleId = RolePermissionEnums.FabergeGemstoneOrders;
        break;

      case "watch-part":
        moduleId = RolePermissionEnums.FabergeWatchParts;
        break;

      case "booking":
        moduleId = RolePermissionEnums.Bookings;
        break;

      case "sales-order":
        moduleId = RolePermissionEnums.SalesOrders;
        break;

      case "it-helpdesk":
        moduleId = RolePermissionEnums.ITHelpDesk;
        break;

      case "mind-map":
        moduleId = RolePermissionEnums.MindMap;
        break;

      case "electronic-form":
        moduleId = RolePermissionEnums.ElectronicGiftForm;
        break;

      case "production-order-form":
        moduleId = RolePermissionEnums.ProductionOrderForm;
        break;

      default:
        moduleId = 0;
        break;
    }
    return moduleId;
  }

  private updateView(value: boolean) {
    if (this.viewRef) {
      this.viewContainerRef.clear();
      this.viewRef = null;
    }
    if (value)
      this.viewRef = this.viewContainerRef.createEmbeddedView(
        this.templateRef,
        this._context
      );
    else this.viewContainerRef.clear();
  }
}
