import {ProductBatchPieceOwnerBase,} from '@app/database-models'
//Generated Imports
export class ProductBatchPieceOwner extends ProductBatchPieceOwnerBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}