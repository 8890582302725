import {CollectionDesignModuleLocalizationBase,} from '@app/database-models'
//Generated Imports
export class CollectionDesignModuleLocalization extends CollectionDesignModuleLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}