import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerCallMeBase {

//#region name Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region callMeId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'callMeId', keyColumn: true})
        callMeId : number;
//#endregion callMeId Prop


//#region country Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'country', keyColumn: false})
        country : string;
//#endregion country Prop


//#region customerMessage Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'customerMessage', keyColumn: false})
        customerMessage : string;
//#endregion customerMessage Prop


//#region requestedDate Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'requestedDate', keyColumn: false})
        requestedDate : any;
//#endregion requestedDate Prop


//#region isCallCompleted Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'isCallCompleted', keyColumn: false})
        isCallCompleted : any;
//#endregion isCallCompleted Prop

}