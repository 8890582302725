import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AbstractWatchInvoice } from '../domain/abstract-watch-invoice';
import { WatchInvoice } from 'src/app/models/extended-models/watch-invoice';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
@multilingual("WatchInvoiceAddComponent")
@Component({
  selector: 'app-watch-invoice-add',
  templateUrl: './watch-invoice-add.component.html'
})
export class WatchInvoiceAddComponent extends AbstractWatchInvoice implements OnInit, OnDestroy {
  watchInvoice: WatchInvoice;
  watchInvoice2: WatchInvoice;
  watchInvoice3: WatchInvoice;
  watchInvoiceInfo: WatchInvoice;
  subscription: any;
  @Input() watchPartId: number;
  @Input() hide: Function;
  showInvoiceForm2: boolean = false;
  showInvoiceForm3: boolean = false;
  showInvoiceError: boolean = false;
  showInvoiceError2: boolean = false;
  showInvoiceError3: boolean = false;
  titleClose: string;

  constructor(private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster, public modelView: ModalView,private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modelView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'inventory', subMenu: 'watch-parts' });
    location.onPopState(() => this.hide());
}

  ngOnInit() :void{
    this.titleClose = getLocalizedValue("Title_Close");
    this.spin = true;
    this.watchInvoice = new WatchInvoice();
    this.watchInvoice2 = new WatchInvoice();
    this.watchInvoice3 = new WatchInvoice();
    this.watchInvoice.watchPartId = this.watchPartId;
    this.watchInvoice2.watchPartId = this.watchPartId;
    this.watchInvoice3.watchPartId = this.watchPartId;
    this.watchInvoiceFormGroup = this.formBuilder.formGroup(this.watchInvoice) as IFormGroup<WatchInvoice>;
    this.watchInvoice2FormGroup = this.formBuilder.formGroup(this.watchInvoice2) as IFormGroup<WatchInvoice>;
    this.watchInvoice3FormGroup = this.formBuilder.formGroup(this.watchInvoice3) as IFormGroup<WatchInvoice>;
 
    this.showComponent = true;
    this.spin = false;
  }
  async uploadInvoice() {
    this.watchInvoiceFormGroup.submitted = true;
    if (this.watchInvoiceFormGroup.value.fileName == null || this.watchInvoiceFormGroup.value.fileName == undefined || this.watchInvoiceFormGroup.value.fileName == "") {
        this.showInvoiceError = true;
    } else {
        this.showInvoiceError = false;
    }
    if (this.showInvoiceForm2) {
        this.watchInvoice2FormGroup.submitted = true;
        if (this.watchInvoice2FormGroup.value.fileName == null || this.watchInvoice2FormGroup.value.fileName == undefined || this.watchInvoice2FormGroup.value.fileName == "") {
            this.showInvoiceError2 = true;
        } else {
            this.showInvoiceError2 = false;
        }
    }
    if (this.showInvoiceForm3) {
        this.watchInvoice3FormGroup.submitted = true;
        if (this.watchInvoice3FormGroup.value.fileName == null || this.watchInvoice3FormGroup.value.fileName == undefined || this.watchInvoice3FormGroup.value.fileName == "") {
            this.showInvoiceError3 = true;
        } else {
            this.showInvoiceError3 = false;
        }
    }
    if (!this.showInvoiceError && !this.showInvoiceError2 && !this.showInvoiceError3) {
        if (this.watchInvoiceFormGroup.valid && (this.showInvoiceForm2 ? this.watchInvoice2FormGroup.valid : true) && (this.showInvoiceForm3 ? this.watchInvoice3FormGroup.valid : true)) {
            this.spin = true;
            if (this.watchInvoiceFormGroup.value.fileData) {
                
                let res = await this.uploadToBlob(this.fileOutputViewModelCertificate, this.watchInvoiceFormGroup.value.blobPath);
                this.watchInvoiceFormGroup.patchValue({
                    fileData: null,
                    blobFileName: res
                });
            }
            if (this.watchInvoice2FormGroup.value.fileData) {
                
                let res = await this.uploadToBlob(this.fileOutputViewModelCertificate2, this.watchInvoice2FormGroup.value.blobPath);
                this.watchInvoice2FormGroup.patchValue({
                    fileData: null,
                    blobFileName: res
                });
            }
            if (this.watchInvoice3FormGroup.value.fileData) {
                
                let res = await this.uploadToBlob(this.fileOutputViewModelCertificate3, this.watchInvoice3FormGroup.value.blobPath);
                this.watchInvoice3FormGroup.patchValue({
                    fileData: null,
                    blobFileName: res
                });
            }
            this.watchInvoiceInfo = new WatchInvoice();
            this.watchInvoiceInfo.watchPartId = this.watchPartId;
            this.watchInvoiceInfo.fileName = "fileName";
            this.watchInvoiceInfo.watchInvoices = new Array<WatchInvoice>();
            this.watchInvoiceInfo.watchInvoices.push(this.watchInvoiceFormGroup.value);
            if (this.showInvoiceForm2) {
                this.watchInvoiceInfo.watchInvoices.push(this.watchInvoice2FormGroup.value);
            }
            if (this.showInvoiceForm3) {
                this.watchInvoiceInfo.watchInvoices.push(this.watchInvoice3FormGroup.value);
            }
            this.subscription = this.post({ path: "api/WatchInvoice", body: this.watchInvoiceInfo }).subscribe(data => {
                this.spin = false;
                //this.toastr.success("Data Added Successfully");
                var existsAlert = getLocalizedValue("Data_Added");
                if (existsAlert) {
                    this.toastr.success(existsAlert);
                }
                this.hide();
            })
        }
    }
}

addMoreInvoice() {
    if (this.showInvoiceForm2) {
        this.showInvoiceForm3 = true;
    } else {
        this.showInvoiceForm2 = true;
    }
}

  ngOnDestroy(): void {
    if (this.subscription)
        this.subscription.unsubscribe();
}
get componentName(): string {
    return "WatchInvoiceAddComponent";
  }

}
