<div *ngIf="showComponent" class="modal fade show" id="bom_add" tabindex="-1" role="dialog"
    style="padding-right: 17px; display: block;">
    <div class="modal-dialog modal-lg" [rxSpinner]="spin">
        <div class="modal-content">
            <div class="modal-header text-left">
                <h4 class="modal-title mb-0">Print Piece Label</h4>
                <button type="button" class="close py-2" (click)="hidePopup()" data-dismiss="modal"
                    [title]="titleClose">
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                </button>
            </div>
            <div class="modal-body pb-0" [formGroup]="priceLabelFormGroup" *rxMultilingual="" [rxLocalisationInit]="componentName">
                <div class="row">
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_PieceNumber_t"></label>
                            <div class="col-md-6">
                                <!-- <input type="text" disabled class="form-control" formControlName="pieceNumber" placeholder="" value="{{this.pieceNumber}}"> -->
                                <label type="text" 
                                    class="form-control disableLabel mb-0">{{(pieceNumber === null || pieceNumber === undefined || pieceNumber === '')  ? '' : pieceNumber}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_ProductName_t"></label>
                            <div class="col-md-6">
                                <!-- <input type="text" disabled class="form-control" formControlName="productName" placeholder="" value="{{this.productName}}"> -->
                                <label type="text" 
                                    class="form-control disableLabel mb-0">{{(productName === null || productName === undefined || productName === '')  ? '' : productName}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_Currency_t"></label>
                            <div class="col-md-6" [rxSelectExtended]="_this">
                                <rx-select #rxSelect [selectPlaceholder]="'Select Currency'" mainClass="form-control"
                                    [(source)]="currencyLookUp1" (changed)="changeCurrency($event.item.key)"
                                    formControlName="currencyId" [keyValueProps]="['name','currencyId']">
                                </rx-select>
                                <!-- <select rxFocus class="form-control" (change)="changeCurrency($event.target.value)">
                                <option *ngFor="let item of currencyLookUp1" [value]="item.currencyId" value="{{this.currencyId}}">
                                    {{item.name}}
                                </option>
                            </select> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_Country_t"></label>
                            <div *ngIf="showSelectOption" class="col-md-6" [rxSelectExtended]="_this">
                                <rx-select #rxSelect [selectPlaceholder]="'Select Country'" mainClass="form-control"
                                    [(source)]="countryLookup1" (changed)="changeCountry($event.text != '' ? $event.item.key : 0)"
                                    formControlName="countryId" [keyValueProps]="['name','countryId']">
                                </rx-select>
                                <!-- <select class="form-control rounded" (change)="changeCountry($event.target.value)">
                                <option *ngFor="let item of countryLookup1" [value]="item.countryId" value="{{this.countryId}}">
                                    {{item.name}}
                                </option>
                            </select> -->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showStateProvince" class="col-lg-12 col-xl-12 pr-xl-12" [rxLocalisationInit]="componentName">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_StateProvince_t"></label>
                            <div *ngIf="showSelectOption" class="col-md-6" [rxSelectExtended]="_this">
                                <rx-select #rxSelect [selectPlaceholder]="'Select State / Province'" mainClass="form-control"
                                    [(source)]="stateProvinceLookUp1" (changed)="changeStateProvince($event.text != '' ? $event.item.key : 0)"
                                    formControlName="stateProvinceId" [keyValueProps]="['stateProvinceName','stateProvinceId']">
                                </rx-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-12 pr-xl-12">
                        <div class="form-group row">
                            <label class="col-md-2 col-xl-3 col-form-label"
                                rxText="PriceLabelComponent_Price_t"></label>
                            <div class="col-md-6">
                                <!-- <input type="text" disabled class="form-control" placeholder="" value="{{this.price}}"> -->
                                <label type="text" 
                                    class="form-control disableLabel mb-0">{{(price === null || price === undefined || price === 0)  ? 0 : price}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" id="price-label" style="display: none;">
                    <div class="col-lg-6 col-xl-4 px-xl-2 d-flex">
                        <table cellspacing="0" cellpadding="0" bgcolor="#ffffff" border="0"
                            style="border:1px solid #000000; height:62px; width:62px">
                            <tbody>
                                <tr valign="top" align="center">
                                    <td valign="top" align="center">
                                        <table cellpadding="2" cellspacing="0"
                                            style="font-size:9px;width:78px; text-align:left;vertical-align:top; height:55px;">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span
                                                            id="ctl00_cph1_ctrlPrintPieceLabel_lblPieceNo">{{this.pieceNumber}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style="overflow:hidden;word-wrap:break-word; display:block; width:56px;">
                                                        <span
                                                            id="ctl00_cph1_ctrlPrintPieceLabel_lblProductName">{{this.productName}}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style="overflow:hidden;word-wrap:break-word; display:block; width:56px;">
                                                        <span
                                                            id="ctl00_cph1_ctrlPrintPieceLabel_lblCurrency">{{this.currencyCode}}</span>&nbsp;
                                                        <span
                                                            id="ctl00_cph1_ctrlPrintPieceLabel_lblPrice">{{this.price}}</span>&nbsp;
                                                        <span
                                                            id="ctl00_cph1_ctrlPrintPieceLabel_lblCountry">{{this.countryName}}</span>&nbsp;
                                                        <span
                                                            id="ctl00_cph1_ctrlPrintPieceLabel_lblStateProvince">{{this.stateProvinceName}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- <table cellspacing="0" cellpadding="0" bgcolor="#ffffff" border="0" style="border:1px solid #000000; height:62px; width:62px" class="billings-card w-100 mb-3 ml-3 border p-4">
                        <tr>
                            <td class="col-form-label px-2"><p class="mb-0">{{this.pieceNumber}}</p></td>
                        </tr>
                        <tr>
                            <td class="col-form-label px-2"><p class="mb-0">{{this.productName}}</p></td>
                        </tr>
                        <tr>
                            <td class="col-form-label px-2"><p class="mb-0">{{this.currencyCode}} {{this.price}} {{this.countryName}}</p></td>
                        </tr>
                    </table> -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="printPriceLabel()" class="btn btn-success" printSectionId="price-label"
                    ngxPrint>Print</button>
            </div>
        </div>
    </div>
</div>
