import {MessageTemplateBase,MessageTemplateDetailBase,} from '@app/database-models'
//Generated Imports
import { propArray, prop } from '@rxweb/reactive-form-validators';
export class MessageTemplate extends MessageTemplateBase 
{

  

//#region Generated Reference Properties
//#region messageTemplateDetails Prop
    @propArray(MessageTemplateDetailBase)
    messageTemplateDetails : MessageTemplateDetailBase[];

    @prop()
    isNewLanguageMessageTemplate:Boolean;
//#endregion messageTemplateDetails Prop

//#endregion Generated Reference Properties












}