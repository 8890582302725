import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TopicLocalizationBase {

//#region topicLocalizedId Prop
        @prop()
        topicLocalizedId : number;
//#endregion topicLocalizedId Prop


//#region topicId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        topicId : number;
//#endregion topicId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region title Prop
        @prop({defaultValue: ""})
        //@required()
        @maxLength({value:200})
        title : string;
//#endregion title Prop


//#region body Prop
        @prop({defaultValue: ""})
        //@required()
        body : string;
//#endregion body Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region updatedOn Prop
        @required()
        updatedOn : Date;
//#endregion updatedOn Prop


//#region metaTitle Prop
        @prop({defaultValue: ""})
        //@required()
        @maxLength({value:400})
        metaTitle : string;
//#endregion metaTitle Prop


//#region metaKeywords Prop
        @prop({defaultValue: ""})
        //@required()
        @maxLength({value:400})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @prop({defaultValue: ""})
        //@required()
        @maxLength({value:4000})
        metaDescription : string;
//#endregion metaDescription Prop





}