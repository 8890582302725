import {vCollectionDesignBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';

@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 12, headerTitle: "Action"
})
export class vCollectionDesign extends vCollectionDesignBase 
{

    @gridColumn({ class: ["text-left"],allowSearch:false,  name: "imageURL1", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'CollectionDesignListComponent_Image_gh', keyColumn: false })
    imageURL1: string;

    @gridColumn({ class: ["text-left"], allowSearch:false, name: "imageURL2", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'CollectionDesignListComponent_Image_gh', keyColumn: false })
    imageURL2: string;

    @gridColumn({ class: ["text-left"], allowSearch:false, name: "imageURL3", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'CollectionDesignListComponent_Image_gh', keyColumn: false })
    imageURL3: string;

    @gridColumn({ class: ["text-left"], allowSearch:false, name: "imageURL4", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'CollectionDesignListComponent_Image_gh', keyColumn: false })
    imageURL4: string;

    @gridColumn({ class: ["text-left"], allowSearch:false, name: "imageURL5", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'CollectionDesignListComponent_Image_gh', keyColumn: false })
    imageURL5: string;

//#region Generated Reference Properties
 
//#endregion Generated Reference Properties










}