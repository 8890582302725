import {CustomerChildRelationshipBase,CustomerRelationshipBase,} from '@app/database-models'
//Generated Imports
export class CustomerChildRelationship extends CustomerChildRelationshipBase 
{




//#region Generated Reference Properties
//#region customerRelationship Prop
customerRelationship : CustomerRelationshipBase;
//#endregion customerRelationship Prop

//#endregion Generated Reference Properties












}