import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMessageTemplateDetailRecordBase {

//#region messageTemplateDetailID Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'messageTemplateDetailID', keyColumn: true})
        messageTemplateDetailID : number;
//#endregion messageTemplateDetailID Prop


//#region messageTemplateID Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'messageTemplateID', keyColumn: false})
        messageTemplateID : number;
//#endregion messageTemplateID Prop


//#region languageID Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'languageID', keyColumn: false})
        languageID : number;
//#endregion languageID Prop


//#region bCCEmailAddresses Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'bCCEmailAddresses', keyColumn: false})
        bCCEmailAddresses : string;
//#endregion bCCEmailAddresses Prop


//#region subject Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'subject', keyColumn: false})
        subject : string;
//#endregion subject Prop


//#region body Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'body', keyColumn: false})
        body : string;
//#endregion body Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region emailAccountId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'emailAccountId', keyColumn: false})
        emailAccountId : number;
//#endregion emailAccountId Prop

}