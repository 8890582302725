import { numeric, prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentITHelpDeskFilterViewModel {
    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "stCreatedDate";
        this.timezoneOffset = new Date().getTimezoneOffset()

    }
    @prop()
    @trim()
    ticketNo: string;

    @prop({ defaultValue: '' })
    statusId: string;

    @prop({ defaultValue: '' })
    assignedToUserId: string;

    @prop({ defaultValue: 0 })
    severityId: number;

    @prop({ defaultValue: 0 })
    iNCTypeId: number;

    @prop({ defaultValue: 0 })
    iNCCategoryId: number;

    @prop({ defaultValue: '' })
    supplierId: string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop({ defaultValue: true })
    flag: boolean;

    @prop()
    @trim()
    searchString: string;

    @prop({ defaultValue: 0 })
    timezoneOffset: number;

    @prop()
    @numeric()
    ticketId: number;
}