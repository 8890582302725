<div class="row wrapper white-bg page-heading py-2 align-items-center" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1" [rxLocalisationInit]="componentName">
    <h2 class="m-0 page-title mb-2" rxText="SupplierAddComponent_Add_Title_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <!-- <li class="breadcrumb-item active">People/Companies</li> -->
      <li class="breadcrumb-item">
        <a [routerLink]="['/suppliers']">Corporates/Groups</a>
      </li>
      <li class="breadcrumb-item active">Add New</li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>
<div class="wrapper wrapper-content" *ngIf="showComponent"  [rxSpinner]="spin">
  <div class="ibox mb-0 d-flex flex-column" >
    <div class="ibox-title bg-white pr-3" [rxLocalisationInit]="componentName">
      <h5 rxText="SupplierAddComponent_Title_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [rxLocalisationInit]="componentName">
      <div id="wizard" class="theme-wizard wizard clearfix" style="margin-bottom: 9rem; overflow: visible;">
        <div class="steps clearfix">
          <ul role="tablist">
            <li role="tab" class="first current">
              <a id="wizard-t-0">
                <span class="current-info audible">current step: </span>
                <span class="number">1.</span>
                <i class="fr-basic-info wizard-icon"></i>
                <span class="fr-circle wizard-check"></span>
                <span class="font-weight-bold" rxText="SupplierAddComponent_Title_Basic_Info_t"></span>
              </a>
            </li>
            <li role="tab" class="disabled" [class.current]="activeStep == 'step-2'"
              [class.done]="activeStep == 'step-3'">
              <a id="wizard-t-1">
                <span class="number">2.</span>
                <i class="fr-contact-info wizard-icon"></i>
                <span class="fr-circle wizard-check"></span>
                <span class="font-weight-bold" rxText="SupplierAddComponent_Title_Contact_Info_t"></span>
              </a>
            </li>
            <li role="tab" class="last disabled" [class.done]="activeStep == 'step-3'">
              <a id="wizard-t-2">
                <span class="number">3.</span>
                <i class="fr-user-created wizard-icon"></i>
                <span class="fr-circle wizard-check"></span>
                <span class="font-weight-bold" rxText="SupplierAddComponent_Title_Created_t"></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="step-content" id='basicInfo_customerRole' *ngIf="activeStep == 'step-1'"
          [formGroup]="supplierFormGroup">
          <div class="row" [rxLocalisationInit]="componentName">
            <div class="col-xl-12">
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="SupplierAddComponent_Basic_Info_t"></h5>
              </div>
              <div class="row">
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierCrpsGroupsName_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="company" class="form-control " tabindex="1" rxFocus
                        rxPlaceholder="SupplierAddEditComponent_SupplierCrpsGroupsName_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.company.errors">{{suplierDetailFormGroup.controls.company.errorMessage}}</small>
                      <ng-container *ngIf="emojisErr==true && !suplierDetailFormGroup.controls.company.errors" [rxLocalisationInit]="componentName">
                          <small class="form-text text-danger" rxText="SupplierAddComponent_EmojiCharErrort_t"></small>
                      </ng-container>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierIndustryType_t"></label>
                    <div class="col-md-8" [rxTagExtended]="_this">
                      <!-- <select class="form-control" formControlName="industryTypeId">
                        <option [selected]="true">Select</option>
                        <option *ngFor="let item of supplierLookups.industryTypeLookUp" [value]="item.industryTypeId">
                          {{item.industryTypeName}}</option>
                      </select> -->
                      <rx-tag [(source)]="supplierLookups.industryTypeLookUp" #rxTag [controlTabIndex]="3"
                            [isSort]="false" formControlName="industryTypeIds" mainClass="form-control" placeholder="Select Industry Type"
                            [keyValueProps]="['industryTypeName','industryTypeId']">
                      </rx-tag>
                      <small class="form-text text-danger"
                        [class.d-block]="supplierFormGroup.controls.industryTypeId.errors">{{supplierFormGroup.controls.industryTypeId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierCmpnyRegstrdNo_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="companyRegisteredNumber" tabindex="3" class="form-control"
                        rxPlaceholder="SupplierAddEditComponent_SupplierCmpnyRegstrdNo_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.companyRegisteredNumber.errors">{{suplierDetailFormGroup.controls.companyRegisteredNumber.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 pr-xl-5">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-md-4 col-xl-4 col-form-label"
                          rxText="SupplierAddEditComponent_SupDateOfCmpnyRegstrtin_t"></label>
                        <div class="col-md-8">
                          <div class="" id="data_1">
                            <div class="input-group date">
                              <rx-date showAddon="true" formControlName="dateOfCompanyRegistration"
                                [controlTabIndex]="4" pickerClass="form-control"></rx-date>
                              <small class="form-text text-danger"
                                [class.d-block]="supplierFormGroup.controls.dateOfCompanyRegistration.errors">{{supplierFormGroup.controls.dateOfCompanyRegistration.errorMessage}}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                          rxText="SupplierAddEditComponent_SupplierImage_t"></label>
                        <div class="col-md-8">
                          <app-file [fileViewModel]="fileViewModel" (files)="updateFiles($event)" [tabindex]="'5'" [title] = "imageTooltip">
                          </app-file>
                          <img *ngIf="supplierFormGroup.value.displayImage && supplierFormGroup.value.fileData==null"
                            src="{{supplierFormGroup.value.imageURL1}}" class="event-img  mt-2 upload-img-preview" />
                          <img *ngIf="supplierFormGroup.value.fileData!=null" [src]="getImgContent()"
                            class="event-img mt-2 upload-img-preview" />
                          <ng-container *ngIf="supplierFormGroup.value.displayImage || supplierFormGroup.value.fileData!=null" [rxLocalisationInit]="componentName">
                            <button (click)="removeImage()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                        *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                        <label class="col-md-2 col-form-label"
                          rxText="SupplierAddEditComponent_SupplierDescription_t"></label>
                        <div class="col-md-10">
                          <textarea class="form-control" formControlName="description" tabindex="6" rows="5"
                            rxPlaceholder="SupplierAddEditComponent_Description_p"></textarea>
                          <small class="form-text text-danger"
                            [class.d-block]="suplierDetailFormGroup.controls.description.errors">{{suplierDetailFormGroup.controls.description.errorMessage}}</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="form-group row">
                    <label class="col-md-4 col-xl-4 col-form-label" rxText="supplierRegistrationDate"></label>
                    <div class="col-md-8">
                      <div class="" id="data_1">
                        <div class="input-group date">
                          <rx-date showAddon="true" formControlName="registrationDate" placeholder=""
                            pickerClass="form-control"></rx-date>
                          <small class="form-text text-danger"
                            [class.d-block]="supplierFormGroup.controls.registrationDate.errors">{{supplierFormGroup.controls.registrationDate.errorMessage}}</small>
                        </div>
                      </div>
                    </div>
                  </div> -->

                </div>
                <!-- <div class="col-md-4 pr-xl-5">

                </div> -->
              </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5><span rxText="SupplierAddComponent_Supplier_Roles_t"></span>
                  <span class="text-danger">*</span>
                </h5>
                <small class="form-text text-danger" *ngIf="supplierRoleValidation"
                  [class.d-block]="supplierRoleValidation">Select At least one role</small>
              </div>
              <div class="row">
                <div class="col-md-4" [formGroup]="supplierRoleFormGroup"
                  *ngFor="let supplierRoleFormGroup of supplierRoleFormArray; let i = index">
                  <div class="form-group row check-group" *ngIf="supplierRoleFormGroup.value.roleId != 0">
                    <label class="col-md-9 col-form-label">
                      {{getRoleName(supplierRoleFormGroup.value.roleId)}}
                    </label>
                    <app-checkbox [tabIndex]="getTabIndex(7,i)"
                      [checkFormControl]="supplierRoleFormGroup.controls.active"
                      class="col-md-auto d-flex align-items-center"></app-checkbox>
                    <small class="form-text text-danger"
                      [class.d-block]="supplierRoleFormGroup.controls.active.errors">{{supplierRoleFormGroup.controls.active.errorMessage}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hr-line-dashed border-top w-100 mb-2"></div>
          <div class="ibox-title bg-white px-0 border-bottom mb-4" [rxLocalisationInit]="componentName">
            <h5 rxText="SupplierAddComponent_Diligence_Info_t"></h5>
          </div>

          <div class="row" [rxLocalisationInit]="componentName">
            <div class="col-md-4 pr-xl-5">
              <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">

                <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_IncorporationCountry_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                  <rx-select [(source)]="supplierLookups.countryLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="8"
                    formControlName="incorporationCountry" class="countryFocus"
                    [keyValueProps]="['countryName','countryId']"
                    [selectPlaceholder]="'Select Country of Incorporation'">
                  </rx-select>

                </div>
              </div>

              <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">

                <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_ComplianceForm_t"></label>
                <div class="col-md-1">
                  <app-checkbox (change)="updateCompliance($event)" [tabIndex]="'9'"
                    [checkFormControl]="suplierDetailFormGroup.controls.complianceForm">
                  </app-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-4 pr-xl-5">
              <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">

                <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_CompanyID_t"></label>
                <div class="col-md-8">
                  <app-checkbox (change)="updateCompany($event)" [tabIndex]="'10'"
                    [checkFormControl]="suplierDetailFormGroup.controls.companyID">
                  </app-checkbox>
                </div>
              </div>
              <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">

                <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_KeyIndividual_t"></label>
                <div class="col-md-1">
                  <app-checkbox (change)="updateKeyIndividualID($event)" [tabIndex]="'11'"
                    [checkFormControl]="suplierDetailFormGroup.controls.keyIndividualID">
                  </app-checkbox>
                </div>
              </div>
            </div>
            <div class="col-md-4 pr-xl-5">
              <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                <label class="col-md-4 col-xl-4 col-form-label"
                  rxText="SupplierAddEditComponent_CreditRating_t"></label>
                <div class="col-md-7" [rxSelectExtended]="_this">
                  <rx-select [(source)]="supplierLookups.corporateCreditRatings" #rxSelect [controlTabIndex]="10"
                    mainClass="form-control" formControlName="corporateCreditRatingId"
                    [selectPlaceholder]="'Select Corporate Credit Rating'"
                    [keyValueProps]="['sp','corporateCreditRatingId']">
                  </rx-select>
                  <small class="form-text text-danger"
                    [class.d-block]="suplierDetailFormGroup.controls.corporateCreditRatingId.errors">{{suplierDetailFormGroup.controls.corporateCreditRatingId.errorMessage}}</small>
                </div>
                <div class="col-md-1">
                  <a (click)="corporateCreditRating()" class="float-right m-1" [title]="titleHelp"
                    *ngIf="supplierLookups.corporateCreditRatings.length > 0">
                    <i class="fa fa-question-circle fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row extraBottomSpace">
            &nbsp;
          </div> -->
        </div>




        <div class="step-content" id='ContactInfo_userInfo' *ngIf="activeStep == 'step-2'"
          [formGroup]="supplierFormGroup">
          <div class="row" [rxLocalisationInit]="componentName">
            <div class="col-xl-12">
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="SupplierAddComponent_Contact_Info_t"></h5>
              </div>
              <div class="row">
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierPrmryEmailAddr_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="email" class="form-control" tabindex="6"
                        rxPlaceholder="SupplierAddEditComponent_SupplierPrmryEmailAddr_p">
                      <small class="form-text text-danger"
                        [class.d-block]="supplierFormGroup.controls.email.errors">{{supplierFormGroup.controls.email.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierStreetAddress_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="streetAddress" class="form-control" tabindex="9"
                        rxPlaceholder="SupplierAddEditComponent_SupplierStreetAddress_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.streetAddress.errors">{{suplierDetailFormGroup.controls.streetAddress.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierStreetAddress2_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="streetAddress2" class="form-control" tabindex="12"
                        rxPlaceholder="SupplierAddEditComponent_SupplierStreetAddress2_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.streetAddress2.errors">{{suplierDetailFormGroup.controls.streetAddress2.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_SupplierCity_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="city" class="form-control" tabindex="14"
                        rxPlaceholder="SupplierAddEditComponent_SupplierCity_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.city.errors">{{suplierDetailFormGroup.controls.city.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_Country_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <rx-select [(source)]="supplierLookups.countryLookUp" #rxSelect mainClass="form-control"
                        [controlTabIndex]="7" (changed)="selectState($event)" formControlName="countryId"
                        [keyValueProps]="['countryName','countryId']" [selectPlaceholder]="'Select Country'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.countryId.errors">{{suplierDetailFormGroup.controls.countryId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="!isshowStateTextbox" [rxLocalisationInit]="componentName">
                    <label class="col-md-4  col-xl-4 col-form-label" 
                      rxText="SupplierAddEditComponent_SupplierStateProvince_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this"  [formGroup]="suplierDetailFormGroup"
                      *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                      <rx-select [selectPlaceholder]="'Select State/Province'" [(source)]="stateProvinceLookUp" #rxSelect
                        [controlTabIndex]="10" *ngIf="isDDStateChange" mainClass="form-control"
                        formControlName="stateProvinceId" [keyValueProps]="['stateProvinceName','stateProvinceId']">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.stateProvinceId.errors">{{suplierDetailFormGroup.controls.stateProvinceId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="isshowStateTextbox" [rxLocalisationInit]="componentName">
                    <label class="col-md-4  col-xl-4 col-form-label" 
                      rxText="SupplierAddEditComponent_SupplierStateProvince_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="stateName" tabindex="10" class="form-control"
                         placeholder="Enter State/Province">
                      <small class="form-text text-danger"
                        [class.d-block]="supplierFormGroup.controls.stateName.errors">{{supplierFormGroup.controls.stateName.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierZipPostalcode_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="zipPostalCode" class="form-control " tabindex="13"
                        rxPlaceholder="SupplierAddEditComponent_SupplierZipPostalcode_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.zipPostalCode.errors">{{suplierDetailFormGroup.controls.zipPostalCode.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label" rxText="SupplierAddEditComponent_SupplierPOBOX_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="poBox" class="form-control" tabindex="15"
                        rxPlaceholder="SupplierAddEditComponent_SupplierPOBOX_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.poBox.errors">{{suplierDetailFormGroup.controls.poBox.errorMessage}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierWorkTelephone_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="workPhoneNumber" class="form-control" tabindex="8"
                        rxPlaceholder="SupplierAddEditComponent_SupplierWorkTelephone_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.workPhoneNumber.errors">{{suplierDetailFormGroup.controls.workPhoneNumber.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierWebsiteLinkedin_t"></label>
                    <div class="col-md-8">
                      <input type="text" formControlName="webSite" class="form-control" tabindex="11"
                        rxPlaceholder="SupplierAddEditComponent_SupplierWebsiteLinkedin_p">
                      <small class="form-text text-danger"
                        [class.d-block]="suplierDetailFormGroup.controls.webSite.errors">{{suplierDetailFormGroup.controls.webSite.errorMessage}}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="SupplierAddComponent_Sales_Info_t"></h5>
              </div>
              <div class="row">
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierSalesAdvisor_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <select class="form-control" formControlName="salesAdvisorsId">
                        <option [selected]="true">Select</option>
                        <option *ngFor="let item of supplierLookups.salesAdvisorLookUp" [value]="item.userId">
                          <span *ngIf="!item.username">{{item.email}}</span>
                          {{item.username}}
                        </option>
                      </select> -->
                      <rx-select [(source)]="supplierLookups.salesAdvisorLookUp" #rxSelect mainClass="form-control"
                        [controlTabIndex]="16" formControlName="salesAdvisorsId" [keyValueProps]="['fullName','userId']"
                        [selectPlaceholder]="'Select Sales Advisor'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="supplierFormGroup.controls.salesAdvisorsId.errors">{{supplierFormGroup.controls.salesAdvisorsId.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SuppCrntFabergeStckist_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'19'"
                        [checkFormControl]="suplierDetailFormGroup.controls.currentFabergeStockist">
                      </app-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SuppJewelleryStockists_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'17'"
                        [checkFormControl]="suplierDetailFormGroup.controls.jewelleryStockist">
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupComTypeFabrgeMBRUpdt_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'20'"
                        [checkFormControl]="suplierDetailFormGroup.controls.commu_FabergeMBRupdates">
                      </app-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row" [formGroup]="suplierDetailFormGroup"
                    *ngFor="let suplierDetailFormGroup of supplierDetailFormArray; let i = index">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierWatchStockist_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'18'"
                        [checkFormControl]="suplierDetailFormGroup.controls.watchStockist">
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4 col-xl-4">
                      <label class="col-form-label">
                        <span  rxText="SupplierAddEditComponent_IsSupplierUnwelcome_t">
                        </span>
                        <!-- <i class="fa fa-info-circle text-right float-right p-1" [title]="personaNonGrataMean"></i> -->
                        <i class="fa fa-info-circle ml-1" id="supplier_IsUnwelcome_helpToolTip"
                        data-toggle="tooltip" data-placement="right"
                        data-html="true" title=""></i>
                      </label>
                    </div>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'21'"
                        [checkFormControl]="supplierFormGroup.controls.isSupplierUnwelcome">
                      </app-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hr-line-dashed border-top w-100 mb-2"></div>
              <div class="ibox-title bg-white px-0 border-bottom mb-4">
                <h5 rxText="SupplierAddComponent_Information_t"></h5>
              </div>
              <div class="row">
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SuppKeyRltshipHolder_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <select class="form-control" formControlName="introducesBy">
                        <option [selected]="true">Select</option>
                        <option *ngFor="let item of supplierLookups.keyRelationshipHolderLookUp" [value]="item.userId">
                          {{item.username}}</option>
                      </select> -->
                      <rx-select [(source)]="supplierLookups.keyRelationshipHolderLookUp" #rxSelect mainClass="form-control"
                        class="chosen-height dropdown-menu-right" [controlTabIndex]="22" formControlName="introducesBy"
                        [keyValueProps]="['fullName','userId']" [selectPlaceholder]="'Select Key Relationship Holder'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="supplierFormGroup.controls.introducesBy.errors">{{supplierFormGroup.controls.introducesBy.errorMessage}}</small>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierApprovalStatus_t"></label>
                    <div class="col-md-1">
                      <app-checkbox (change)="updateStatus($event)" [tabIndex]="'25'"
                        [checked]="supplierFormGroup.value.statusId == statusEnums.Active"></app-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupSensitiveCntactPrmsn_t"></label>
                    <div class="col-md-8">
                      <app-checkbox [tabIndex]="'23'"
                        [checkFormControl]="supplierFormGroup.controls.contactPermissionRequired">
                      </app-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 pr-xl-5">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      rxText="SupplierAddEditComponent_SupplierRegtMethod_t"></label>
                    <div class="col-md-8" [rxSelectExtended]="_this">
                      <!-- <select class="form-control" formControlName="registrationTypeId">
                        <option *ngFor="let item of supplierLookups.customerRegistrationTypeLookUp"
                          [value]="item.customerRegistrationTypeId">{{item.customerRegistrationTypeName}}</option>
                      </select> -->
                      <rx-select [(source)]="supplierLookups.customerRegistrationTypeLookUp" #rxSelect [controlTabIndex]="24"
                        mainClass="form-control" formControlName="registrationTypeId"
                        [keyValueProps]="['customerRegistrationTypeName','customerRegistrationTypeId']"
                        [selectPlaceholder]="'Select Registration Method'">
                      </rx-select>
                      <small class="form-text text-danger"
                        [class.d-block]="supplierFormGroup.controls.registrationTypeId.errors">{{supplierFormGroup.controls.registrationTypeId.errorMessage}}</small>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row extraBottomSpace">

              </div> -->

            </div>
          </div>
        </div>
        <div class="actions clearfix" *ngIf="activeStep != 'step-3'" [rxLocalisationInit]="componentName">
          <ul role="menu" aria-label="Pagination" style="display:flex!important">
            <li aria-hidden="false" class="mx-0">
              <button [disabled]="activeStep == 'step-1'" role="menuitem" class="btn btn-light"
                (click)="peopleWizard(step,false)" rxText="Btn_Previous_t"></button>
            </li>
            <li aria-hidden="false" class="pull-right mx-0">
              <button class="btn btn-success" role="menuitem" (click)="peopleWizard(step,true)" rxText="Btn_Next_t"></button>
            </li>
          </ul>
        </div>
        <div class="step-content" id='userRegistered' *ngIf="activeStep == 'step-3'">
          <div class="text-center m-t-md py-5" *ngIf="id > 0" [rxLocalisationInit]="componentName">
            <i class="fr-check-confirmation fa-5x text-green"></i>
            <p class="font-weight-bold mt-0 my-3 font-xmd" rxText="SupplierAddComponent_Corporate_Created_t"></p>
            <button (click)="goToSupplier()" class="btn btn-success" rxText="SupplierAddComponent_Btn_GoToCorporate_t"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>