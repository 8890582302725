import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PersonInjuryTypeBase {

//#region incPersonInjuryTypeId Prop
        @prop()
        incPersonInjuryTypeId : number;
//#endregion incPersonInjuryTypeId Prop


//#region incidentId Prop
       // @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        incidentId : number;
//#endregion incidentId Prop


//#region incPersonAffId Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        incPersonAffId : number;
//#endregion incPersonAffId Prop


//#region injuryTypeId Prop
        @required()
        injuryTypeId : any;
//#endregion injuryTypeId Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop







}