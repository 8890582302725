export enum MindMapNodeType {
    ROOT = 0,
    HEADER = 1,
    LIST = 2
}

export enum MindMapNodeSide {
    LEFT = 0,
    RIGHT = 1
}


export enum MindMapDataTypeEnum {
    ROOT = 0,

    PROJECT = 1,
    EVENT = 2,
    HSE = 3,
    ORDER = 4,
    KNOWNTO = 5,
    PRESSMEDIA = 6,
    MYDIRECTTEAM = 7,
    LEGALAGREEMENT = 8,
    PEOPLEDIRECTOR = 9,
    RISK = 10,
    USER = 11,
    GROUPCOMPANIES = 12,
    OWNERSHIP = 13,
    PEOPLECORPORATEINVOLVED = 14,
    INVITEESATTENDEES = 15,
    PRODUCTPIECES = 16,
    ORDERTOTAL = 17,
    PRESSMEDIAPEOPLE = 18,
    PRESSMEDIAJOURNALIST = 19,
    PRESSMEDIACORPORATE = 20,
    PRESSMEDIAPUBLICATION = 21,
    PRESSMEDIAPRODUCT = 22,
    SUBPROJECT = 23,
    EMPLOYMENT = 24,
    DIRECTORSHIP = 25,
    PICTUREVIDEO=26
}