import { RxHttp, http } from "@rxweb/http";
import { IAbstractControl, IFormGroup, RxFormBuilder } from "@rxweb/reactive-form-validators";
import { Note, TaskDetail } from "@app/models";
import { ModalView } from "../../../../domain/customize-design/modal";
import { BaseDialog } from "../../../../domain/customize-design/dialog";
import { BaseToastr } from "../../../../domain/customize-design/toastr";
import { CoreComponent } from "@rxweb/angular-router";
import { List } from "@rxweb/generics";
import {
  NoteMemberMappingBase,
  NoteHastagMappingBase,
} from "../../../../models/database-models";
import { FormArray } from "@angular/forms";
import { FileInputViewModel } from "../../../../view-model/file-input-view-model";
import { FileOutputViewModel } from "../../../../view-model/file-output-view-model";
import {
  FileTypeEnums,
  FileSizeEnums,
} from "../../../../custom-enum/file-type-enums";
import { TinyMCEdraftModel } from "src/app/view-model/tiny-mce-draft-view-model";
@http({
  path: "api/Notes",
})
export class AbstractNote extends CoreComponent {
  noteFormGroup: IFormGroup<Note>;
  spin: boolean = false;
  spinner: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  showComponent: boolean = false;
  notesLookups: any;
  toUserList: List<any>;
  ccUserList: List<any>;
  allUsers: List<any>;
  toUserSplits: string[];
  ccUserSplits: string[];
  // spin:boolean=false;
  hasTagsSplist: string[];
  fileViewModel: FileInputViewModel;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  isShowGrid: boolean = false;
  isNoRecordFound: boolean;
  userId: number;
  completionDateError: boolean = false;
  isShowTask: boolean;
  isNoTaskFound: boolean;
  loginUserName: string;
  restrictedBodyPrefix: string = "";
  restrictedEventProjectBodyPrefix = "";
  toEmailsForDraft = "";
  toEmailsChanged: boolean = false;
  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.All;
    //  this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
    // this.fileViewModel.isMultipart = true;
  }
  bindTeamMembersMapping(formBuilder: RxFormBuilder) {
    let tos = this.noteFormGroup.value.toEmails;
    let ccs = this.noteFormGroup.value.ccEmails;
    let noteMemberMapping = this.noteFormGroup.get(
      "noteMemberMappings"
    ) as FormArray;
    if (tos) {
      this.toUserSplits = this.noteFormGroup.value.toEmails.split(",");
      this.toUserSplits.forEach((data) => {
        if (data != null && data != undefined && data != "") {
          let member = new NoteMemberMappingBase();
          member.noteId = 0;
          member.noteTeammembermappingId = 0;
          member.teammemberId = parseInt(data);
          member.typeofNote = 0;
          member.isTo = true;
          // if(this.userId!=member.teammemberId){
          noteMemberMapping.push(
            formBuilder.formGroup(member) as IFormGroup<NoteMemberMappingBase>
          );
        }

        // }
      });
    }
    if (ccs) {
      this.ccUserSplits = this.noteFormGroup.value.ccEmails.split(",");
      this.ccUserSplits.forEach((data) => {
        if (data != null && data != undefined && data != "") {
          let member = new NoteMemberMappingBase();
          member.noteId = 0;
          member.noteTeammembermappingId = 0;
          member.teammemberId = parseInt(data);
          member.typeofNote = 0;
          member.isTo = false;
          noteMemberMapping.push(
            formBuilder.formGroup(member) as IFormGroup<NoteMemberMappingBase>
          );
        }
      });
    }
  }
  decodeEntities(str) {
    const element = document.createElement("div");
    if (str && typeof str === "string") {
      str = encodeURI(str);
      element.innerHTML = str;
      if (element.innerText) {
        str = element.innerText;
        element.innerText = '';
      } else {
        // Firefox support
        str = element.textContent;
        element.textContent = '';
      }
    }
    return decodeURI(str);
  }

  bindHastags(formBuilder: RxFormBuilder) {
    let hasTags = this.noteFormGroup.value.hasTags;
    let noteHastagMappings = this.noteFormGroup.get(
      "noteHastagMappings"
    ) as FormArray;
    if (hasTags) {
      this.hasTagsSplist = this.noteFormGroup.value.hasTags.split(",");
      this.hasTagsSplist.forEach((data) => {
        if (data != null && data != undefined && data != "") {
          let hasTags = new NoteHastagMappingBase();
          hasTags.noteId = 0;
          hasTags.noteHastagId = 0;
          hasTags.hastagId = parseInt(data);
          noteHastagMappings.push(
            formBuilder.formGroup(hasTags) as IFormGroup<NoteHastagMappingBase>
          );
        }
      });
    }
  }

  bindTaskDetails() {
    const taskDetailFormArray = this.noteFormGroup.get("taskDetailMappings") as FormArray;
    if(taskDetailFormArray.length > 0 ){
      taskDetailFormArray.controls.forEach(control => {
        control.get('completionDate').setValue(new Date(control.get('completionDate').value).toISOString())
        control.get('createdOn').setValue(new Date(control.get('createdOn').value).toISOString())
        control.get('completedAt').setValue(null)
      });
    }
  }

  setNoteFormGroupValues(note: Note): Note {
    note.toEmails = "";
    note.ccEmails = "";
    note.noteMemberMappings.forEach((data) => {
      if(data.isTo == true) {
        note.toEmails = note.toEmails + data.teammemberId + ",";
      }
      else {
        note.ccEmails = note.ccEmails + data.teammemberId + ",";
      }
    });

    // note.noteMemberMappings.filter(x => x.isTo == false).forEach(data => {
    //   note.ccEmails = note.ccEmails + data.teammemberId + ",";
    // })
    note.hasTags = "";
    note.noteHastagMappings.forEach((data) => {
      note.hasTags = note.hasTags + data.hastagId + ",";
    });
    this.toEmailsForDraft = note.toEmails;
    return note;
  }

  getnotes(isCC = false): string {
    let userDetail = "";
    let tos = this.noteFormGroup.value.toEmails;
    let ccs = this.noteFormGroup.value.ccEmails;
    this.toUserSplits = [];
    this.ccUserSplits = [];
    if (tos) {
      this.toUserSplits = this.noteFormGroup.value.toEmails.split(",");
    }
    if (ccs) {
      this.ccUserSplits = this.noteFormGroup.value.ccEmails.split(",");
    }

    let finalUserListArray;
    if (isCC) {
      finalUserListArray = this.ccUserSplits;
    }
    else {
      finalUserListArray = this.toUserSplits;
    }

    finalUserListArray.forEach((data) => {
      let obj = this.allUsers.where((x) => x.userId == data).firstOrDefault();
      if (obj) {
        userDetail = userDetail + obj.userName + ", ";
      }
    });
    if (userDetail) {
      userDetail = userDetail.substring(0, userDetail.length - 1);
    }

    return userDetail;
  }

  updateFiles($event: FileOutputViewModel) {
    this.fileOutputViewModel = $event;
    this.noteFormGroup.patchValue({
      file: $event.multipleFiles,
      // fileData: $event.fileBaseString,
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      //fabergeCertificate:$event.fileName,
    });
  }

  hideTinyMCEMenu() {
    // const menu = document.getElementsByClassName("tox-mbtn--active");
    // if (menu.length > 0) {
    //   (menu[0] as HTMLElement).click();
    // }

    // const paragraphMenu = document.getElementsByClassName("tox-tbtn--active");
    // if (paragraphMenu.length > 0) {
    //   (paragraphMenu[0] as HTMLElement).click();
    // }

    // const colorMenu = document.getElementsByClassName("tox-tbtn tox-split-button__chevron tox-tbtn--enabled");
    // for (let i = 0; i < colorMenu.length; i++) {
    //   (colorMenu[i] as HTMLElement).click();
    // }

    // const additionalToolbar = document.getElementsByClassName("tox-toolbar__group")[7]?.querySelector(".tox-tbtn.tox-tbtn--enabled")
    // if(additionalToolbar) {
    //   (additionalToolbar as HTMLElement).click();
    // }
    const menu = document.getElementsByClassName("tox tox-silver-sink tox-tinymce-aux");
    if (menu.length > 0) {
      for (let index = 0; index < menu.length; index++) {
        (menu[index] as HTMLElement).innerHTML = null;
      }
    }
  }

  get taskDetailFormArray(): FormArray {
    let taskDetailFormArray = this.noteFormGroup.controls["taskDetailMappings"] as IAbstractControl;
    let data = <FormArray>taskDetailFormArray["controls"]
    return data;
  }

  hasSpecificValues(formGroup: IFormGroup<Note>,  isRestrictedEventProject: boolean): boolean {
    const controlsToCheck = ['noteDescription', 'hasTags', 'toEmails', 'subject'];
    for (const controlName of controlsToCheck) {
      const control = formGroup.get(controlName);
      if (control && control.value?.trim().length > 0) {
        if(controlName == 'noteDescription'){
          if(!this.isActualNoteDescriptionBlank(formGroup, isRestrictedEventProject)) return true;
        }
        else{
          return true;
        }
      }
    }
    return false;
  }

  checkValidityOfDraft(model: TinyMCEdraftModel): boolean {
    return (
      model.noteSubject?.trim().length > 0 ||
      model.hashtags?.trim().length > 0 ||
      model.noteDescription?.trim().length > 0 ||
      model.sendTo?.trim().length > 0
    );
  }

  isActualNoteDescriptionBlank(formGroup: IFormGroup<Note>, isRestrictedEventProject: boolean, isReplay: boolean = false): boolean {
    var description = formGroup.controls.noteDescription.value;
    var isNoteRestricted = formGroup.value.isDisplayTeamMember;
    this.toEmailsChanged = false;
    if(isReplay){
      this.toEmailsChanged = this.toEmailsForDraft?.replace(/,+\s*$/, '') != formGroup.controls.toEmails.value?.replace(/,+\s*$/, '')
    }
    if (isRestrictedEventProject || isNoteRestricted) {
      try {
        var parser = new DOMParser();
        var doc = parser.parseFromString(
          description,
          "text/html"
        );
        if (isRestrictedEventProject) {
          var paragraphs = doc.getElementsByTagName("p");
          if (paragraphs.length > 0) {
            var firstParagraph = paragraphs[0];
            if (firstParagraph && firstParagraph.textContent?.trim() == this.restrictedEventProjectBodyPrefix?.trim()) {
              if (firstParagraph.nextSibling?.nodeName == "BR") {
                firstParagraph.nextSibling.remove();
              }
              firstParagraph.remove();
            }
          }
          var noticeElement = doc.getElementById("restricted-event-project-notice");
          if (noticeElement != null) {
            if (noticeElement.nextSibling?.nodeName == "BR") {
              noticeElement.nextSibling.remove();
            }
            noticeElement.remove();
          }
        }
        if (isNoteRestricted) {
          var paragraphs = doc.getElementsByTagName("p");
          if (paragraphs.length > 0) {
            var firstParagraph = paragraphs[0];
            if (firstParagraph && firstParagraph.textContent?.trim() == this.restrictedBodyPrefix?.trim()) {
              if (firstParagraph.nextSibling?.nodeName == "BR") {
                firstParagraph.nextSibling.remove();
              }
              firstParagraph.remove();
            }
          }
          var restrictedElement = doc.getElementById("restricted-note-notice");
          if (restrictedElement != null) {
            if (restrictedElement.nextSibling?.nodeName == "BR") {
              restrictedElement.nextSibling.remove();
            }
            restrictedElement.remove();
          }
        }

        var remainingNote = doc.getElementsByTagName("BODY")[0].innerHTML;
        remainingNote = remainingNote.replace(/\n<p>&nbsp;<\/p>/, "");
        return remainingNote.trim().length == 0;
      } catch (error) {
        return (description?.trim().length == 0 || description?.replace(/\n<p>&nbsp;<\/p>/, "").trim().length == 0);
      }
    }
    return (description?.trim().length == 0 || description?.replace(/\n<p>&nbsp;<\/p>/, "").trim().length == 0);
  }
}
