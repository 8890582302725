import { gridColumn } from "@rxweb/grid";

export class FabergeOrderDetailsViewModel{
    //#region OrderProductVariantID 
    @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 0, allowSorting: false, headerKey: 'id', keyColumn: true })
    orderProductVariantID: number;
    //#endregion OrderProductVariantID 

    //#region imageUrl Prop
    @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], allowSearch: false, name: "imageUrl", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 1, allowSorting: false, headerKey: 'CorporateOrdersDetailComponent_Image_gh', keyColumn: false })
    imageUrl: string;
    //#endregion imageUrl Prop

    //#region Name 
    @gridColumn({ parameter: true, style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'CorporateOrdersDetailComponent_ProductName_gh', keyColumn: false })
    name: string;
    //#endregion Name

    //#region SerialNo
    @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, configuredTemplate: { templateName: 'redirectPieceLinkTemplate' }, headerKey: 'CorporateOrdersDetailComponent_PieceNumber_gh', keyColumn: false })
    serialNo: string;
    //#endregion SerialNo

    //#region TotalCount
    @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: false, headerTitle: 'Total Count', keyColumn: false })
    totalCount: number;
    //#endregion TotalCount

    productVariantBatchPieceId: number;
}