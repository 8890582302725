import { prop } from '@rxweb/reactive-form-validators';

export class vHolidayTypeLookUpBase {

    //#region holidayTypeId Prop
    @prop()
    holidayTypeId: number;
    //#endregion holidayTypeId Prop

    //#region holidayTypeName Prop
    @prop()
    holidayTypeName: string;
    //#endregion holidayTypeName Prop
}