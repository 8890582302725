import {vGemstoneStationGemfieldsLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemstoneStationGemfieldsLookUp extends vGemstoneStationGemfieldsLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}