import { prop, required } from "@rxweb/reactive-form-validators";
import { StatusEnum } from "src/app/enums/status.enum";

export class LegalAgreementUserMappingBase {

    //#region legalAgreementUserMappingId Prop
    @prop({ defaultValue: 0 })
    legalAgreementUserMappingId: number;
    //#endregion legalAgreementUserMappingId Prop

    //#region legalAgreementId Prop
    @prop()
    @required()
    legalAgreementId: number;
    //#endregion legalAgreementId Prop

    //#region userId Prop
    @prop()
    @required()
    userId: number;
    //#endregion userId Prop

    //#region supplierId Prop
    @prop()
    @required()
    supplierId: number;
    //#endregion supplierId Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    @required()
    statusId: number;
    //#endregion statusId Prop

}
