import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { TaxRate, vStateProvinceLookUp, vCountryLookUp, vTaxCategoryLookUp } from '@app/models';
import { AbstractTaxRate } from '../domain/abstract-tax-rate';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { MASTER_LOOKUPS } from '../../../../uris/master-lookups.uri';
import { List } from '@rxweb/generics';
import { StatusEnum } from '../../../../enums/status.enum';
import { vTaxRate } from '../../../../models/extended-models/v-tax-rate';
import { ApplicationBroadcaster } from '../../../../temp-service/application-broadcaster';
import { Router } from '@angular/router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import * as CryptoJS from 'crypto-js';
import { multilingual } from '@rxweb/localization';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
@multilingual("TaxRateAddComponent")
@Component({
  selector: "app-tax-rate-add",
  templateUrl: './tax-rate-add.component.html'
})
export class TaxRateAddComponent extends AbstractTaxRate implements OnInit, OnDestroy {
  taxRate: TaxRate;
  iD: any
  subscription: any;
  @Input() taxProviderId: number;
  taxRateEdit: vTaxRate;
  @Input() taxRateId: number = 0;
  @Input() hide: Function;
  @ViewChild('search') searchElement: ElementRef;
  titleClose: string;


  _this;
  constructor(modal: ModalView, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, private formBuilder: RxFormBuilder, private el: ElementRef, private location : PlatformLocation) {
    super();
    this.applicationBroadcaster.taxRateSubscriber.subscribe(data => {
      this.taxRateId = data.taxRateId;
      //this.ngOnInit();
    })
    this.modalView = modal;
    this._this = this;
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    if (this.taxRateId == 0) {
      this.showSelectOption = true;
      this.lookup([{ path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.stateProvinceLookup, propName: "stateProvinceLookUp" },
      { path: MASTER_LOOKUPS.taxCategoryLookUps, propName: "taxCategoryLookUp" }]).subscribe((data: any) => {
        this.taxRate = new TaxRate();
        this.taxRate.taxRateId = 0;
        this.taxRate.statusId = StatusEnum.Active;
        this.taxRate.countryId = 0;
        this.taxRate.stateProvinceId = 0;
        this.taxRate.taxCategoryId = 0;
        // this.taxRateLookUps = data
        this.taxRateLookUps.countryLookUp = new Array<vCountryLookUp>();
        this.taxRateLookUps.stateProvinceLookUp = new Array<vStateProvinceLookUp>();
        this.taxRateLookUps.taxCategoryLookUp = new Array<vTaxCategoryLookUp>();
        this.taxRateLookUps.countryLookUp = data.countryLookUp;
        this.taxRateLookUps.stateProvinceLookUp = data.stateProvinceLookUp;
        this.taxRateLookUps.taxCategoryLookUp = data.taxCategoryLookUp;
        // let country = new vCountryLookUp();
        // country.countryId = 0;
        // country.countryName = "Select";
        // let province = new vStateProvinceLookUp();
        // province.stateProvinceId = 0;
        // province.countryId = 0;
        // province.stateProvinceName = "All";
        // let category = new vTaxCategoryLookUp();
        // category.taxCategoryId = 0;
        // category.taxCategoryName = "Select";
        // this.taxRateLookUps.countryLookUp.unshift(country);
        // // this.taxRateLookUps.stateProvinceLookUp.unshift(province);
        // this.taxRateLookUps.taxCategoryLookUp.unshift(category);
        this.taxRate.countryId = 0;
        this.taxRate.taxCategoryId = 0;
        this.taxRate.stateProvinceId = 0;
        this.stateProvinceLookUp = [];
        //this.stateProvinceLookUp.add(province);
        this.taxRateFormGroup = this.formBuilder.formGroup(this.taxRate) as IFormGroup<TaxRate>;
        this.taxRateFormGroup.controls.percentage.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
        RxwebValidators.pattern({ expression: { 'percentage': /^[0-9]{0,3}[.]{0,2}[0-9]{0,3}$/ }, message: 'Please enter valid rate(3 digits and 3 decimal places allowed)' })]);
        this.showComponent = true;
      })
    }
    else {

      this.lookup([{ params: [this.taxRateId], propName: "taxRate" }, { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
      { path: USER_LOOKUPS.stateProvinceLookup, propName: "stateProvinceLookUp" },
      { path: MASTER_LOOKUPS.taxCategoryLookUps, propName: "taxCategoryLookUp" }]).subscribe(data => {
        this.taxRateLookUps = data
        this.taxRateFormGroup = this.formBuilder.formGroup(TaxRate, this.taxRateLookUps.taxRate) as IFormGroup<TaxRate>;

        this.taxRateFormGroup.controls.percentage.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
        RxwebValidators.pattern({ expression: { 'percentage': /^[0-9]{0,3}[.]{0,2}[0-9]{0,3}$/ }, message: 'Please enter valid rate(3 digits and 3 decimal places allowed)' })]);
        this.selectState(this.taxRateFormGroup.value.stateProvinceId);
        this.showComponent = true;
      })
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addConfiguration(redirect: boolean) {
    this.taxRateFormGroup.submitted = true;
    if (this.taxRateFormGroup.valid) {
      this.spin = true;
      this.iD = this.taxRateFormGroup.get('stateProvinceId').value
      console.log(this.iD)
      if (this.iD === "") {
        this.taxRateFormGroup.value.stateProvinceId = 0
      }
      if (this.taxRateId == 0) {
        this.post({ body: this.taxRateFormGroup.value }).subscribe(data => {
          this.spin = false;
          var existsAlert = getLocalizedValue("Data_Added");
          if (existsAlert) {
            //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert);
          }
          this.applicationBroadcaster.taxRateBroadCast(this.taxRateFormGroup.value as TaxRate)
          this.hidePopup();
        })
      }
      else {
        this.put({ params: [this.taxRateId], body: this.taxRateFormGroup.value }).subscribe(data => {
          this.spin = false;
          var existsAlert = getLocalizedValue("Data_Updated");
          if (existsAlert) {
            //this.toastr.success("Data Updated Successfully")
            this.toastr.success(existsAlert);
          }
          this.applicationBroadcaster.taxRateBroadCast(this.taxRateFormGroup.value as TaxRate)
          this.taxRateId = 0;
          this.hidePopup();
        })
      }
    } else {
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.taxRateFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }

  }

  hidePopup() {
    this.hide()
  }

  get componentName(): string {
    return "TaxRateAddComponent";
  }
}
