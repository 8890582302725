import {vBookingDataLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBookingDataLookUp extends vBookingDataLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}