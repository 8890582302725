import {NationalityBase,} from '@app/database-models'
//Generated Imports
export class Nationality extends NationalityBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}