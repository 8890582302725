import { MindMapDataTypeEnum, MindMapNodeType, MindMapNodeSide } from 'src/app/enums/mind-map-data-type-enum';
import { RxHttp } from '@rxweb/http';
import { MindMapData } from './mind-map-data';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';


export class RootCorporateMindMap {

    expandedProjectId: number = 0;
    oldExpandedProjectId: number = 0;
    expandedOrderId: number = 0;
    expandedOrderData: string = '';
    oldExpandedOrderId: number = 0;

    mindMapData: any = null;

    rootEntityName: string = '';
    profileImageUrl:string = '';

    // mindMap;
    mindMapDataObj: MindMapData;

    httpClient: any;

    constructor(private mindMapService: MindMapService) {
        this.httpClient = new RxHttp();
        this.mindMapDataObj = new MindMapData();
        Window['RootCorporateMindMap'] = this;
    }

    async setupCorporateMindMap(mindMapData, rootEntityName,imageUrl) {
        this.mindMapData = mindMapData;
        this.rootEntityName = rootEntityName;
        this.profileImageUrl = imageUrl;
        if (this.mindMapData != null) {
            await this.createMindMap();
        }
    }

    async createMindMap() {
        var [projectData
            , eventData
            , orderData
            , hseData
            , legalAgreementData
            , pressMediaData
            , groupCompaniesData
            , peopleDirectorData
            , ownershipData
            , riskData
            ,pictureVideoData] = await Promise.all([
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.ProjectData, "project-templates.json", "Project", this.mindMapService.graphId, MindMapDataTypeEnum.PROJECT, RolePermissionEnums.Project),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.EventData, "event-templates.json", "Event", this.mindMapService.graphId, MindMapDataTypeEnum.EVENT, RolePermissionEnums.Event),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.OrderData, "order-templates.json", "Order", this.mindMapService.graphId, MindMapDataTypeEnum.ORDER, RolePermissionEnums.SalesOrders),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.HSEData, "hse-templates.json", "HSE", this.mindMapService.graphId, MindMapDataTypeEnum.HSE, RolePermissionEnums.HSE),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.LegalAgreementData, "legal-agreement-templates.json", "LegalAgreement", this.mindMapService.graphId, MindMapDataTypeEnum.LEGALAGREEMENT, RolePermissionEnums.LegalAgreements),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PressMediaData, "press-media-templates.json", "PressMedia", this.mindMapService.graphId, MindMapDataTypeEnum.PRESSMEDIA, RolePermissionEnums.PressandMedia),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.GroupCompaniesData, "group-companies-templates.json", "GroupCompanies", this.mindMapService.graphId, MindMapDataTypeEnum.GROUPCOMPANIES, RolePermissionEnums.Supplier),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PeopleDirectorData, "people-director-templates.json", "PeopleDirector", this.mindMapService.graphId, MindMapDataTypeEnum.PEOPLEDIRECTOR, RolePermissionEnums.User),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.OwnershipData, "ownership-templates.json", "Ownership", this.mindMapService.graphId, MindMapDataTypeEnum.OWNERSHIP, RolePermissionEnums.User),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.RiskData, "risk-templates.json", "Risk", this.mindMapService.graphId, MindMapDataTypeEnum.RISK, RolePermissionEnums.GemfieldsRiskMenu),
                this.mindMapDataObj.getTemplateFormattedData(this.mindMapData.PictureVideoData, "picture-video-templates.json", "PICTUREVIDEO", this.mindMapService.graphId, MindMapDataTypeEnum.PICTUREVIDEO, RolePermissionEnums.User)
            ]);

        var childrenArray = [];
        if (this.mindMapService.graphId == 1) {
            if (this.mindMapData.ProjectData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    direction: 'right',
                    selectedType: 'PROJECT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    expanded: false,
                    children: projectData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: projectData,
                            direction: 'right',
                            selectedType: 'PROJECTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.EventData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'EVENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: eventData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: eventData,
                            direction: 'right',
                            selectedType: 'EVENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.HSEData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}` + '\')\">HSE Entries (' + this.mindMapData.HSEData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'HSE',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: hseData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: hseData,
                            direction: 'left',
                            selectedType: 'HSELIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.OrderData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}` + '\')\">Orders (' + this.mindMapData.OrderData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'ORDER',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: orderData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: orderData,
                            direction: 'left',
                            selectedType: 'ORDERLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.PeopleDirectorData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">People/Directors (' + this.mindMapData.PeopleDirectorData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PEOPLEDIRECTOR',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: peopleDirectorData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: peopleDirectorData,
                            direction: 'right',
                            selectedType: 'PEOPLEDIRECTORLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.LegalAgreementData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'LEGALAGREEMENT',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: legalAgreementData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: legalAgreementData,
                            direction: 'left',
                            selectedType: 'LEGALAGREEMENTLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.PressMediaData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'PRESSMEDIA',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pressMediaData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pressMediaData,
                            direction: 'right',
                            selectedType: 'PRESSMEDIALIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.GroupCompaniesData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Group Companies (' + this.mindMapData.GroupCompaniesData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'GROUPCOMPANIES',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: groupCompaniesData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: groupCompaniesData,
                            direction: 'left',
                            selectedType: 'GROUPCOMPANIESLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.OwnershipData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">Ownerships (' + this.mindMapData.OwnershipData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'OWNERSHIP',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: ownershipData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: ownershipData,
                            direction: 'left',
                            selectedType: 'OWNERSHIPLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }
            if (this.mindMapData.RiskData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}` + '\')\">Risks (' + this.mindMapData.RiskData.length + ')</span>',
                    direction: 'right',
                    expanded: false,
                    selectedType: 'RISK',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: riskData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: riskData,
                            direction: 'right',
                            selectedType: 'RISKLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                childrenArray.push({
                    id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    color: '#000000',
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    direction: 'left',
                    expanded: false,
                    selectedType: 'PICTUREVIDEO',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: pictureVideoData != '' ? [
                        {
                            id: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                            color: '#000000',
                            topic: pictureVideoData,
                            direction: 'left',
                            selectedType: 'PICTUREVIDEOLIST',
                            backgroundColor: 'rgba(250, 250, 250, 1)',
                            children: []
                        },
                    ] : []
                });
            }

            this.mindMapService.mindMapObject = {
                format: 'nodeTree',
                data: {
                    id: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    isroot: true,
                    topic: '<h2 class="m-0" style="font-size:20px!important"><a class="d-flex align-items-center" target="_blank" href="/suppliers/' + this.mindMapData.RootEntityId + '?activeTab=mind-map"> '+this.profileImageUrl+' <b>' + this.rootEntityName + '</b></a></h2>',
                    selectedType: "",
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    children: childrenArray
                }
            };
        }
        else {
            this.mindMapService.tempSubgraphData.root = {
                parentNodeId: this.mindMapService.parentNodeToExpand,
                nodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                topic: '<h2 class="m-0"><a target="_blank" href="/suppliers/' + this.mindMapData.RootEntityId + '?activeTab=mind-map"><b>' + this.rootEntityName + '</b></a></h2>',
                data: { selectedType: "ROOT" }
            };

            if (this.mindMapData.ProjectData.length > 0) {
                this.mindMapService.tempSubgraphData.project = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}` + '\')\">Projects (' + this.mindMapData.ProjectData.length + ')</span>',
                    data: { selectedType: "PROJECT" }
                };

                this.mindMapService.tempSubgraphData.projectList = (projectData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PROJECT}_${RolePermissionEnums.Project}_${MindMapNodeType.LIST}`,
                    topic: projectData,
                    data: { selectedType: "PROJECTLIST" }
                };
            }
            if (this.mindMapData.EventData.length > 0) {
                this.mindMapService.tempSubgraphData.event = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}` + '\')\">Events (' + this.mindMapData.EventData.length + ')</span>',
                    data: { selectedType: "EVENT" }
                };

                this.mindMapService.tempSubgraphData.eventList = (eventData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.EVENT}_${RolePermissionEnums.Event}_${MindMapNodeType.LIST}`,
                    topic: eventData,
                    data: { selectedType: "EVENTLIST" }
                };
            }
            if (this.mindMapData.HSEData.length > 0) {
                this.mindMapService.tempSubgraphData.hse = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}` + '\')\">HSE Entries (' + this.mindMapData.HSEData.length + ')</span>',
                    data: { selectedType: "HSE" }
                };

                this.mindMapService.tempSubgraphData.hseList = (hseData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${(MindMapDataTypeEnum.HSE)}_${RolePermissionEnums.HSE}_${MindMapNodeType.LIST}`,
                    topic: hseData,
                    data: { selectedType: "HSELIST" }
                };
            }
            if (this.mindMapData.OrderData.length > 0) {
                this.mindMapService.tempSubgraphData.order = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}` + '\')\">Orders (' + this.mindMapData.OrderData.length + ')</span>',
                    data: { selectedType: "ORDER" }
                };

                this.mindMapService.tempSubgraphData.orderList = (orderData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.ORDER}_${RolePermissionEnums.SalesOrders}_${MindMapNodeType.LIST}`,
                    topic: orderData,
                    data: { selectedType: "ORDERLIST" }
                };
            }
            if (this.mindMapData.PeopleDirectorData.length > 0) {
                this.mindMapService.tempSubgraphData.peopleDirector = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">People/Directors (' + this.mindMapData.PeopleDirectorData.length + ')</span>',
                    data: { selectedType: "PEOPLEDIRECTOR" }
                };

                this.mindMapService.tempSubgraphData.peopleDirectorList = (peopleDirectorData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PEOPLEDIRECTOR}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                    topic: peopleDirectorData,
                    data: { selectedType: "PEOPLEDIRECTORLIST" }
                };
            }
            if (this.mindMapData.LegalAgreementData.length > 0) {
                this.mindMapService.tempSubgraphData.LegalAgreement = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}` + '\')\">Legal Agreements (' + this.mindMapData.LegalAgreementData.length + ')</span>',
                    data: { selectedType: "LEGALAGREEMENT" }
                };

                this.mindMapService.tempSubgraphData.LegalAgreementList = (legalAgreementData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.LEGALAGREEMENT}_${RolePermissionEnums.LegalAgreements}_${MindMapNodeType.LIST}`,
                    topic: legalAgreementData,
                    data: { selectedType: "LEGALAGREEMENTLIST" }
                };
            }
            if (this.mindMapData.PressMediaData.length > 0) {
                this.mindMapService.tempSubgraphData.pressMedia = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}` + '\')\">Press and Media (' + this.mindMapData.PressMediaData.length + ')</span>',
                    data: { selectedType: "PRESSMEDIA" }
                };

                this.mindMapService.tempSubgraphData.pressMediaList = (pressMediaData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PRESSMEDIA}_${RolePermissionEnums.PressandMedia}_${MindMapNodeType.LIST}`,
                    topic: pressMediaData,
                    data: { selectedType: "PRESSMEDIALIST" }
                };
            }
            if (this.mindMapData.GroupCompaniesData.length > 0) {
                this.mindMapService.tempSubgraphData.groupCompanies = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}` + '\')\">Group Companies (' + this.mindMapData.GroupCompaniesData.length + ')</span>',
                    data: { selectedType: "GROUPCOMPANIES" }
                };

                this.mindMapService.tempSubgraphData.groupCompaniesList = (groupCompaniesData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.GROUPCOMPANIES}_${RolePermissionEnums.Supplier}_${MindMapNodeType.LIST}`,
                    topic: groupCompaniesData,
                    data: { selectedType: "GROUPCOMPANIESLIST" }
                };
            }
            if (this.mindMapData.OwnershipData.length > 0) {
                this.mindMapService.tempSubgraphData.ownership = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}` + '\')\">Ownerships (' + this.mindMapData.OwnershipData.length + ')</span>',
                    data: { selectedType: "OWNERSHIP" }
                };

                this.mindMapService.tempSubgraphData.ownershipList = (ownershipData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.OWNERSHIP}_${RolePermissionEnums.User}_${MindMapNodeType.LIST}`,
                    topic: ownershipData,
                    data: { selectedType: "OWNERSHIPLIST" }
                };
            }
            if (this.mindMapData.RiskData.length > 0) {
                this.mindMapService.tempSubgraphData.risk = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}` + '\')\">Risks (' + this.mindMapData.RiskData.length + ')</span>',
                    data: { selectedType: "RISK" }
                };

                this.mindMapService.tempSubgraphData.riskList = (riskData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.RISK}_${RolePermissionEnums.GemfieldsRiskMenu}_${MindMapNodeType.LIST}`,
                    topic: riskData,
                    data: { selectedType: "RISKLIST" }
                };
            }

            if (this.mindMapData.PictureVideoData.length > 0) {
                this.mindMapService.tempSubgraphData.pictureVideo = {
                    parentNodeId: `${this.mindMapService.graphId}_${this.mindMapData.RootEntityId}_${MindMapDataTypeEnum.ROOT}_${RolePermissionEnums.Supplier}_${MindMapNodeType.ROOT}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    topic: '<span style="cursor:pointer;" onclick=\"Window.AbstractMindMap.expandCollapseList(\'' + `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}` + '\')\">Picture/Videos (' + this.mindMapData.PictureVideoData.length + ')</span>',
                    data: { selectedType: "PICTUREVIDEO" }
                };

                this.mindMapService.tempSubgraphData.pictureVideoList = (pictureVideoData == '') ? null : {
                    parentNodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.HEADER}`,
                    nodeId: `${this.mindMapService.graphId}_${MindMapDataTypeEnum.PICTUREVIDEO}_0_${MindMapNodeType.LIST}`,
                    topic: pictureVideoData,
                    data: { selectedType: "PICTUREVIDEOLIST" }
                };
            }


        }

        return;
    }
}
