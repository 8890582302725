import {TitleLocalizationBase,} from '@app/database-models'
//Generated Imports
export class TitleLocalization extends TitleLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}