import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractMeasureWeight } from '../domain/abstract-measure-weight';
import { MeasureWeight, vMeasureWeight } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { MeasureWeightAddComponent } from '../add/measure-weight-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("MeasureWeightListComponent")
@access({ accessLevel: RolePermissionEnums.MeasureWeights, action: "get" })

@Component({
    selector: "app-measure-weight-list",
    templateUrl: './measure-weight-list.component.html'
})
export class MeasureWeightListComponent extends AbstractMeasureWeight implements OnInit, OnDestroy {
    measureWeights: vMeasureWeight[];
    subscription: any;
    measureWeightGrid: AppGrid;
    measureWeightGridColumns: any[];
    measureWeightAdd: any = MeasureWeightAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.MeasureWeights;
        location.onPopState(() => this.dialog.hide());
    }


    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Measure Weights - G-Trac Admin");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
        this.titleHelp = getLocalizedValue("Title_Help");
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vMeasureWeight[]) => {
            this.spin = false;
            this.measureWeights = t;
            this.measureWeightGrid = new AppGrid(t, vMeasureWeight, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.measureWeightGrid.gridColumns.forEach(x => {
                if (this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "put" }) && this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "delete" });
                    }
                }
            })
            this.measureWeightGrid.componentId = this.componentName;
            this.measureWeightGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.measureWeightGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.measureWeightGrid.design(document.getElementById("measure-weights"));
            this.showComponent = true;
        })
    }

    onEdit(measureWeights: vMeasureWeight) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["measure-weights", measureWeights.measureWeightId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["measure-weights", measureWeights.measureWeightId]);
    }

    onDelete(measureWeights: vMeasureWeight) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [measureWeights.measureWeightId] }).subscribe(data => {
                    this.spin = false;
                    this.measureWeightGrid.remove(measureWeights.measureWeightId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/measure-weights']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Measure_Weights");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.measureWeights.filter(x => x.measureWeightName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.measureWeightId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.ratio.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.displayOrder.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.measureWeightGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.measureWeightGrid = null;
        }
        setTimeout(() => {
            if (this.measureWeightGrid == null || this.measureWeightGrid == undefined) {
                this.measureWeightGrid = new AppGrid(this.measureWeights, vMeasureWeight, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.measureWeightGrid.gridColumns.forEach(x => {
                    if (this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "put" }) && this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.measureWeightGrid.authorize({ accessLevel: RolePermissionEnums.MeasureWeights, action: "delete" });
                        }
                    }
                })
                this.measureWeightGrid.componentId = this.componentName;
                this.measureWeightGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.measureWeightGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.measureWeightGrid.refresh("measure-weights");
                this.measureWeightGrid.design(document.getElementById("measure-weights"));
            }
            this.measureWeightGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "MeasureWeightListComponent";
    }

}
