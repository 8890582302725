import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CarnetNoteDocumentBase {

//#region carnetDocumentID Prop
        @prop()
        carnetDocumentID : number;
//#endregion carnetDocumentID Prop


//#region carnetID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        carnetID : number;
//#endregion carnetID Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region noteID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        noteID : number;
//#endregion noteID Prop


//#region fileName Prop
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop



}