import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { RxHttp, http } from "@rxweb/http";
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyCustomerMapping, vCompanyLookup } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { CompanyCorporateViewModel } from '../../../../../../view-model/comapny-corporate-mapping-view-model';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CustomerCorporateMappingViewModel } from '../../../../../../view-model/customer-corporate-mapping-view-model';
import { CoreComponent } from '@rxweb/framework';
import { BaseToastr } from '../../../../../../domain/customize-design/toastr';
import { StatusEnum } from '../../../../../../enums/status.enum';
import { BedRequestComponent } from '../../../../../shared/global/bed-request/bed-request.component';
import { currentDateValidation, dateCompareValidation, getLocalizedValue } from '../../../../../../domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import { vUserDetail } from '../../../../../../models/extended-models/v-user-detail';
import { List } from '@rxweb/generics';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import * as CryptoJS from 'crypto-js';
import { DatePipe, PlatformLocation } from "@angular/common";
@multilingual("CorporateGroupsAddComponent")
@Component({
    selector: "app-corporate-groups-add",
    templateUrl: './corporate-groups-add.component.html'
})

export class CorporateGroupsAddComponent extends CoreComponent implements OnInit, OnDestroy {

    customerCompanyAddFormGroup: IFormGroup<CompanyCustomerMapping>;
    companyCorporate: CompanyCorporateViewModel;
    showDate: boolean = false;
    showJobTitle: boolean = true;
    dateError: boolean = false;
    modalView: ModalView;
    spin: boolean = false;
    toastr: BaseToastr;
    isFutureDate: boolean = false;
    showComponent: boolean = false;
    @Input() hide: Function;
    @Input() userId: number;
    @Input() isSupplier: boolean;
    id: number;
    date: Date;
    showDirector: boolean = true;
    showActive: boolean = true;
    selectedUserId: number;
    roleTypeId: number;
    peopleLookups: any;
    customerCorporate: CustomerCorporateMappingViewModel;
    companyCustomerMapping: CompanyCustomerMapping;
    subscription: any;
    employmentActive: boolean = true;
    dateNull: boolean = true;
    enableSaveBtn: boolean = false;
    resetCommonControl: boolean = true;
    corporateLookUp: any = {
        companyLookup: vCompanyLookup,

    }
    userName: List<any>;
    toUserList: List<any>;
    titleClose: string;

    _this;

    constructor(private formBuilder: RxFormBuilder, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.modalView = modalView;
        this.toastr = new BaseToastr();
        this.userName = new List<any>();
        this._this = this;
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.spin = true;
        this.post({ body: { query: "123" }, path: "api/FormLookups/NearMissPeopleList" }).subscribe((respons: any) => {
            this.peopleLookups = respons;
            this.peopleLookups = this.peopleLookups[0];
            this.toUserList = new List<any>(this.peopleLookups.usersList);
            this.userName = this.bindTo();
        })
        this.lookup([{ propName: "companyLookup", path: USER_LOOKUPS.companyLookUp }]).subscribe((lookup: any) => {
            this.corporateLookUp = lookup;
            this.companyCustomerMapping = new CompanyCustomerMapping();
            this.customerCompanyAddFormGroup = this.formBuilder.formGroup(CompanyCustomerMapping, this.companyCustomerMapping) as IFormGroup<CompanyCustomerMapping>;
            this.showComponent = true;
        });
    }
    bindTo() {
        return this.toUserList;
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => { })
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    changeDirectorship(event) {
        this.showDate = event.target.checked ? true : false;
        this.showJobTitle = event.target.checked ? false : true;
        this.employmentActive = false;
        this.dateNull = true;

        // this.customerCompanyAddFormGroup.patchValue({
        //     jobTitle: null
        // })
    }
    changeEmploymentActive(event) {
        this.employmentActive = true;
        // if (this.dateNull == false) {
        //     if(event.target.checked && this.showDate==false)
        //     {
        //         this.customerCompanyAddFormGroup.patchValue({
        //             endDate: null,
        //             startDate: null
        //         })
        //     }
        // }

    }

    userSelected($event) {
        setTimeout(() => {
            this.selectedUserId = this.companyCustomerMapping.userId;
            this.userName.forEach(element => {
                if (element.userId == this.selectedUserId) {
                    this.roleTypeId = element.roleTypeId
                }
                return false
            });
            if (this.roleTypeId == CustomerRoleTypeEnum.Customer) {
                this.showDirector = true;
                this.showActive = true;
            }
            else if (this.roleTypeId == CustomerRoleTypeEnum.Admin) {
                this.showDirector = true;
                this.showActive = false;
            }
        }, 100);
    }
    AddCorporate() {
        var date = new Date();
        if (dateCompareValidation(this.customerCompanyAddFormGroup.value.startDate, this.customerCompanyAddFormGroup.value.endDate) == true) {
            this.dateError = true;
        }
        // else if(currentDateValidation(new Date(this.datePipe.transform(this.customerCompanyAddFormGroup.value.startDate,'dd/MMM/yyyy')),date)==true)
        // {
        //     this.isFutureDate=true;
        // }
        else {
            this.dateError = false;
            //this.isFutureDate=false;
            this.customerCompanyAddFormGroup.submitted = true;
            if (this.employmentActive == true && this.customerCompanyAddFormGroup.controls.isDirector.value == false && this.customerCompanyAddFormGroup.controls.activeEmployement.value == true) {
                this.customerCompanyAddFormGroup.patchValue({
                    statusId: StatusEnum.Active
                })
            }
            else {
                if (this.customerCompanyAddFormGroup.controls.isDirector.value == true && this.customerCompanyAddFormGroup.controls.endDate.value == null && this.showActive == false) {
                    this.customerCompanyAddFormGroup.patchValue({
                        statusId: StatusEnum.Active
                    })
                }
                else {
                    this.customerCompanyAddFormGroup.patchValue({
                        statusId: StatusEnum.Inactive
                    })
                }

            }
            if (this.customerCompanyAddFormGroup.controls.isDirector.value == false && this.showActive == false) {
                this.customerCompanyAddFormGroup.patchValue({
                    statusId: StatusEnum.Active
                })
            }
            if (this.customerCompanyAddFormGroup.controls.isDirector.value == false && (this.customerCompanyAddFormGroup.controls.startDate.value != null || this.customerCompanyAddFormGroup.controls.startDate.value != null)) {
                this.customerCompanyAddFormGroup.patchValue({
                    startDate: null,
                    endDate: null
                })
            }

            if (this.isSupplier == false) {
                this.customerCompanyAddFormGroup.patchValue({
                    //statusId: StatusEnum.Active,
                    isCorporate: false,
                    companyCustomerMappingId: 0,
                    reportTo: 0,
                    userId: this.userId,
                    isCorporateNote: this.isSupplier
                    // supplierId: this.customerCompanyAddFormGroup.value.companyId
                    //userId: this.isSupplier ? this.customerCompanyAddFormGroup.value.userId : this.id,
                    // companyId: this.isSupplier ? this.id : this.customerCompanyAddFormGroup.value.companyId,
                    //supplierId: this.isSupplier ? this.id : null,
                })
            }
            else {
                this.customerCompanyAddFormGroup.patchValue({
                    isCorporate: true,
                    companyCustomerMappingId: 0,
                    reportTo: 0,
                    companyId: this.userId,
                    supplierId: this.userId,
                    isCorporateNote: this.isSupplier
                })
            }
            if (this.customerCompanyAddFormGroup.valid) {
                // this.enableSaveBtn = true;
                this.customerCompanyAddFormGroup.value.corporateUserIds = new Array<CompanyCustomerMapping>();
                this.spin = true;
                this.post({ path: 'api/CompanyCustomerMappings', body: this.customerCompanyAddFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Added");
                    if (existsAlert) {
                        //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }
        }
    }

    get componentName(): string {
        return "CorporateGroupsAddComponent";
    }
}
