import { RxHttp,http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Language } from '@app/models';
import { CultureInfoViewModel } from '../../../../view-model/culture-info-view-model';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
@http({
    path: "api/Languages",
})
export class AbstractLanguage extends CoreComponent {
    languageFormGroup: IFormGroup<Language>
    showComponent: boolean = false;
    spin: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr;
    dialog: BaseDialog;
    validField: string[] = ["name","languageCulture","displayOrder","flagImageFileName"];
    breakLoop: boolean;
    languageCultureLookup: any = {
        culturesLookUp: CultureInfoViewModel,
        language: Language
    }
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

  badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }

}
