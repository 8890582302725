import { Component, OnInit, Input } from '@angular/core';
import { vValidateUser } from 'src/app/models/extended-models/v-validate-user';
import { Title } from '@angular/platform-browser';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { CoreComponent } from '@rxweb/framework';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';
@multilingual("ExistsUserSearchComponent")
@Component({
  selector: 'app-exists-user-search',
  templateUrl: './exists-user-search.component.html'
})
export class ExistsUserSearchComponent extends CoreComponent implements OnInit  {
  modalView: ModalView;
  @Input() validateUser: any;
  validUser: any;
  titleClose: string;
  isNoRecordFound: boolean = false;
  isShowGrid: boolean = false;
  isFilter: boolean = false;
  userSearchGrid: AppGrid;
  router: Router;
  spin: boolean = false;
  @Input() hide: () => any;
  constructor(private title: Title, modalView: ModalView, private formBuilder: RxFormBuilder, private applicationBroadcaster: ApplicationBroadcaster, router: Router,private location : PlatformLocation) {
    super();
    this.modalView = modalView;   
    this.titleClose = getLocalizedValue("Title_Close");
    this.router = router;
    location.onPopState(() => this.hide());
   }

  ngOnInit() {
    this.validUser = this.validateUser;
    this.isShowGrid = this.validUser.length > 0 ? true : false;
    this.isNoRecordFound = this.validUser.length == 0 ? true : false;
    console.log("viewCount",this.validUser.length);
    this.bindUserGrid();
  }

  bindUserGrid() {
  
    if (!this.isFilter) {
      this.userSearchGrid = new AppGrid(this.validUser, vValidateUser, { actions: {onView: this.onUserInfoLink.bind(this)} });
      this.userSearchGrid.componentId = this.componentName;
      this.userSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
      this.userSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");   
    }
    else {
      if (this.userSearchGrid != null) {      
        this.userSearchGrid.updateSource([]);
        this.userSearchGrid.updateSource(this.validUser);
      }  
      this.userSearchGrid.componentId = this.componentName;
      this.userSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
      this.userSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
    }
    this.userSearchGrid.hideFooter = true;
  }
  onUserInfoLink(userInfo: vValidateUser, event) {
    //this.hide();
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["users", userInfo.userId], { queryParams: { activeTab: 'user-edit' } }));    
    window.open(url, '_blank');   
  }
  close()
  {
    let currentUrl = window.location.href;
    window.open(currentUrl, '_self'); 
  }
  get componentName(): string {
    return "ExistsUserSearchComponent";
  }
}
