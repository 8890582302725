import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractSupplier } from '../domain/abstract-supplier';
import { Supplier } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { SupplierViewModel } from 'src/app/models/extended-models/supplier-view-model';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { SupplierSearchFilterViewModel } from 'src/app/view-model/supplier-search-filter-view-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { scroolOnSearchResult } from 'src/app/domain/customize-plugin/bind-scroll-on-search';
import { AppGrid } from 'src/app/domain/app-grid';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { CLIENT_URL } from 'src/app/domain/system-constant';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { SupplierAddComponent } from '../add/supplier-add.component';
import { GridColumnConfig } from '@rxweb/grid';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { NotesEnum } from "src/app/enums/notes.enum";
@access({ accessLevel: RolePermissionEnums.Supplier, action: "get" })
@multilingual("SupplierListComponent")
@Component({
  selector: "app-supplier-list",
  templateUrl: './supplier-list.component.html'
})
export class SupplierListComponent extends AbstractSupplier implements OnInit, OnDestroy {
  supplierViewModel: SupplierViewModel[];
  supplierExportViewModel: SupplierViewModel[];
  subscription: any;
  suppliers: SupplierSearchFilterViewModel;
  supplierSearchFilterViewModelFormGroup: IFormGroup<SupplierSearchFilterViewModel>;
  totalRecords: number = 0;
  isSearch: boolean = false;
  isFilter: boolean = false;
  suppliersGrid: AppGrid;
  hasPurchasedFromList: any;
  list: any[];
  exportExcel = new ExportToExcelModel();
  supplierAdd: any = SupplierAddComponent;
  isDisplayFStockist: boolean = true;
  moduleID: number;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  titleGridColumnSetting: string;
  titleHelp: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;
  private supplierSubject: Subject<string> = new Subject<string>();
  selectedlListTab: string = "supplier-list" 
  msgError: boolean = false;
  supplerIdGo:number;
  _this;

  constructor(private applicationBroadcaster: ApplicationBroadcaster, router: Router, modelView: ModalView, private formBuilder: RxFormBuilder, private title: Title,private location : PlatformLocation, blobState: BlobUploadsViewStateService, private activatedRoute: ActivatedRoute,) {
    super(blobState);
    this.activatedRoute.queryParams.subscribe(t => {
      this.userId = t['userId'];
    })
    this.modalView = modelView;
    this.router = router;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'suppliers', subMenu: '' })
    this.moduleID = RolePermissionEnums.Supplier;
    this._this = this;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Corporates/Groups - G-Trac Admin");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.showComponent = false;
    this.supplierSubject.pipe(debounceTime(300)).subscribe(t => {
      this.supplierSearch();
    })
    this.lookup([
      { propName: "salesChannel", path: USER_LOOKUPS.salesChannel },
      { propName: "customerRoleLookUps", path: USER_LOOKUPS.customerRoleLookUp },
      { propName: "countryLookUp", path: USER_LOOKUPS.countryLookUp },
      { propName: "keyRelationshipHolderLookUp", path: USER_LOOKUPS.keyRelationshipHolderLookUp },
      { propName: "industryTypeLookUp", path: USER_LOOKUPS.industryTypeLookUp },
      { propName: "supplierRoleLookUp", path: USER_LOOKUPS.supplierRoleLookUp },
      { path: USER_LOOKUPS["fabergePurchasedLookUp"], propName: "fabergePurchasedLookUp" },
    ]).subscribe((lookup: any) => {
      this.suppliers = new SupplierSearchFilterViewModel();
      this.suppliers.pageIndex = PaginationEnum.PageIndex;
      this.suppliers.rowCount = PaginationEnum.RowCount;
      this.supplierSearchFilterViewModelFormGroup = this.formBuilder.formGroup(this.suppliers) as IFormGroup<SupplierSearchFilterViewModel>;
      this.supplierLookups = lookup;
      var fabergeStockistLookup = [
        { "id": 0, "value": "No" },
        { "id": 1, "value": "Yes" }
      ];
      this.supplierLookups.fabergeStockistLookup = fabergeStockistLookup;
      var kycLookups = [
        { "id": 1, "value": "Completed" },
        { "id": 2, "value": "Pending" }
      ]
      this.supplierLookups.kycLookups = kycLookups;
      this.showComponent = true;

      if (this.userId != undefined) {
        if(this.supplierLookups.keyRelationshipHolderLookUp.some(x=>x.userId == this.userId)){
          this.supplierSearchFilterViewModelFormGroup.patchValue({
            introducesBy: this.userId,
          });
          this.supplierSearch();
        }
      }
    })
  }

  bindGrid() {
    this.spin = true;
    this.totalRecords = 0;
    this.isShowGrid = true;

    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedlListTab }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
    })

    this.subscription = this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(this.supplierSearchFilterViewModelFormGroup.value)) } }).subscribe((t: any) => {
      this.showComponent = true;
      setTimeout(() => {
        scroolOnSearchResult();
        this.spin = false;
        this.isShowGrid = true;
        this.isSearch = true;
        this.supplierViewModel = t;
        if (this.supplierViewModel.length > 0) {
          this.totalRecords = this.supplierViewModel[0].totalCount;
          this.isNoRecordFound = false;
        } else {
          this.isShowGrid = false;
          this.isNoRecordFound = true;
        }
        if (!this.isFilter) {
          this.suppliersGrid = new AppGrid(this.supplierViewModel, SupplierViewModel, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this), onOneLink: this.onSupplierInfoLink.bind(this) } });
          console.log(this.suppliersGrid.authorize({
            accessLevel: RolePermissionEnums.Supplier,
            action: "put"
          }));

          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
              this.suppliersGrid.gridColumns.forEach(y => {
                if (y.name == x.ColumnName)
                  y.visible = x.Visible;
              });
            });
            this.gridColumnConfig = this.suppliersGrid.gridColumns;
            this.gridColumnSettings.emit(this.gridColumnConfig)
          }

          this.suppliersGrid.gridColumns.forEach(x => {
            if (this.suppliersGrid.authorize({ accessLevel: RolePermissionEnums.Supplier, action: "put" }) && this.suppliersGrid.authorize({ accessLevel: RolePermissionEnums.Supplier, action: "delete" })) {
              if (x.columnIndex == 13) {
                x.visible = true;
              }
            }
            else {
              if (x.columnIndex == 13) {
                x.visible = false;
              }
              if (x.name == "editAction") {
                x.visible = this.suppliersGrid.authorize({ accessLevel: RolePermissionEnums.Supplier, action: "put" })
              }
              if (x.name == "deleteAction") {
                x.visible = this.suppliersGrid.authorize({ accessLevel: RolePermissionEnums.Supplier, action: "delete" })
              }
            }
          })
          this.suppliersGrid.componentId = this.componentName;
          this.suppliersGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }

        }
        else {
          this.suppliersGrid.storeProcedure.length = this.totalRecords;
          this.suppliersGrid.updateSource([])
          this.suppliersGrid.updateSource(this.supplierViewModel)
        }
        this.suppliersGrid.maxPerPage = this.supplierSearchFilterViewModelFormGroup.value.rowCount;
        this.suppliersGrid.componentId = this.componentName;
        this.suppliersGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.suppliersGrid.designClass.headerClass = "table-header-sticky".split(" ");
      }, 0);
    })

  }
  onSupplierInfoLink(supplierInfo: SupplierViewModel, event) {
    //this.router.navigate(["suppliers", supplierInfo.supplierId], { queryParams: { activeTab: 'supplier-edit' } });
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["suppliers", supplierInfo.supplierId], { queryParams: { activeTab: 'supplier-edit' } }));
    redirectOnCTRL(url, this.router, event);
  }
  onKeyPress() {
    this.supplierSubject.next();
  }
  supplierSearch() {
    // if (this.suppliersGrid) {
    //   this.isFilter = true;
    // }
    // this.supplierSearchFilterViewModelFormGroup.value.pageIndex = 1;
    // this.bindGrid();
    this.msgError=false;
    this.supplierSearchFilterViewModelFormGroup.patchValue({
      supplierId: null,
    });
    if (this.suppliersGrid != null) {
      this.isFilter = true;
      this.suppliersGrid.storeProcedure.nextPage = 1;
      this.supplierSearchFilterViewModelFormGroup.value.rowCount = this.suppliersGrid.maxPerPage;
    }
    this.supplierSearchFilterViewModelFormGroup.value.pageIndex = 1;
    this.bindGrid();
  }
  resetSearch() {
    this.spin = true;
    this.msgError=false;
    this.supplierSearchFilterViewModelFormGroup.reset();
    this.showComponent = false;
    if(this.suppliersGrid && this.suppliersGrid.source.length > 0){
      this.suppliersGrid.destroy();
    }
    if(this.suppliersGrid){
      this.suppliersGrid = null;
      this.isFilter = false;
    }
    this.isShowGrid = false;
    this.isSearch = false;
    setTimeout(() => {
      this.showComponent = true;
      this.spin = false;
    }, 100);
  }

  clearGrid() {
    this.suppliersGrid.refresh("suppliers")
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.supplierSearchFilterViewModelFormGroup.patchValue({ sortOrder: y, orderByColumn: x })
    this.suppliersGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.supplierSearchFilterViewModelFormGroup.patchValue({ pageIndex: x, rowCount: this.suppliersGrid.maxPerPage })
    this.suppliersGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }

  onEdit(supplier: SupplierViewModel) {
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["suppliers", supplier.supplierId], { queryParams: { "activeTab": "supplier-edit" } }));
    redirectOnCTRL(url, this.router, event);
    //this.router.navigate(["suppliers", supplier.supplierId], { queryParams: { "activeTab": "supplier-edit" } });
  }

  onDelete(supplier: SupplierViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ body: null, params: [supplier.supplierId] }).subscribe(data => {
          this.spin = false;
          this.isFilter = true;
          this.bindGrid();
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            //this.toastr.success("Data Deleted Successfully")
            this.toastr.success(existsAlert);
          }
        })
      }
      else {
        this.dialog.hide()
      }
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Corporate");
    if (existsAlert) {
      return existsAlert;
    }
  }
  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.supplierSearchFilterViewModelFormGroup.patchValue({ pageIndex: 1, searchString: value.target.value.replace(/^\s+|\s+$/gm, '').replace('&', '%26')})
      if (this.suppliersGrid != null) {
        this.suppliersGrid.storeProcedure.nextPage = 1;
        this.suppliersGrid.updateSource([]);
      }
      this.bindGrid();
    }, 4000);
    this.spin = true;
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.suppliersGrid.reDesign();
    this.suppliersGrid.updateSource([])
    this.suppliersGrid.updateSource(this.supplierViewModel);
    this.arrConfig = gridColumnConfig;
  }
  exportToExcel() {
    this.spin = true;
    var TodayDate = new Date();
    let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
    this.exportExcel.FileName = "Corporates/Groups" + MMDDYY;
    this.exportExcel.Type = "Excel";
    this.exportExcel.Description = "Corporates/Groups"
    var json = JSON.parse(JSON.stringify(this.supplierSearchFilterViewModelFormGroup.value));
    json["pageIndex"] = PaginationEnum.PageIndex;
    json["rowCount"] = this.totalRecords
    this.get({ queryParams: { "query": encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: any) => {
      this.supplierExportViewModel = t;
      let returnedRequestData = this.requestExcelData(this.supplierExportViewModel, this.suppliersGrid.gridColumns);
      this.exportExcel.Result = JSON.stringify(returnedRequestData);
      return this.post({ body: this.exportExcel, path: "api/SearchUsers/GetExcel" }).subscribe(Response => {
        this.downloadDocument(Response, this.exportExcel.FileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        this.spin = false;
      }, error => {
        this.spin = false;
        //  this.toastr.error("There was some problem while downloading the Excel");
      })
    });
  }
  requestExcelData(suppliersGrid: any[], gridColumns: any[]) {
    var listObject = suppliersGrid.map(function (obj) {
      if (gridColumns != undefined && gridColumns != null) {
        var objNew: any = {}
        gridColumns.forEach(e => {
          if (e.visible) {
            var name = e.name == "statusId" ? "verified" : e.name;
            objNew[this.getGridColumnName(e.name)] = obj[name];
          }
        }, this);
        return objNew;
      } else {
        return obj;
      }
    }, this)
    var list = new List<any>(listObject);
    return list;
  }

  public downloadDocument(data: any, fileName: string, fileType: string) {
    var binaryString = window.atob(data.result.result);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var link = document.createElement('a');
    var blob = new Blob([bytes], { type: fileType });
    link.href = window.URL.createObjectURL(blob);
    link.download = data.result.fileName;
    document.body.appendChild(link);
    link.click();

  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }
 //#295644 / 29 - 07 - 2024 / add Search by ID Controller and validation Method
  goTo() {
    this.supplierSearchFilterViewModelFormGroup.submitted = true;
    if (this.supplierSearchFilterViewModelFormGroup.controls.supplierId.valid) {
              this.supplerIdGo = this.supplierSearchFilterViewModelFormGroup.controls.supplierId.value;
              if(this.supplerIdGo){
                this.get({ path: 'api/SearchCommon/IsRecordExist', queryParams: {"id":this.supplerIdGo,"moduleId":NotesEnum.CorporateGroupsNotes} }).subscribe((data: any) => {
                  debugger;
                  if(data){
                    this.router.navigate(['/suppliers', this.supplerIdGo], { queryParams: { "activeTab": "supplier-edit" } });
                        }else{
                          this.msgError=true;
                        }
                  });
              }else{
                this.msgError = true;
              }
            }
            else {
                this.msgError = true;
            }
}

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  getGridColumnName(key: string) {
    var localizationData = (MultiLingualData.get(this.componentName) as any);
    switch (key) {
      case "company":
        return localizationData.SupplierListComponent_CompanyName_ex_ex;
      case "salesAdvisor":
        return localizationData.SupplierListComponent_SalesAdvisor_ex_ex;
      case "introduceBy":
        return localizationData.SupplierListComponent_IntroducedBy_ex_ex;
      case "statusId":
        return localizationData.SupplierListComponent_Verified_ex_ex;
      case "registrationDate":
        return localizationData.SupplierListComponent_RegistrationDate_ex_ex;
      case "stRegistrationDate":
        return localizationData.SupplierListComponent_RegistrationDate_ex_ex;
      case "loginCount":
        return localizationData.SupplierListComponent_LoginCount_ex_ex;
      case "countryName":
        return localizationData.SupplierListComponent_Country_ex_ex;
      case "hasPurchasedFrom":
        return localizationData.SupplierListComponent_HasPurchasedFrom_ex_ex;
      case "ddKYCStatus":
        return localizationData.SupplierListComponent_KYC_ex_ex;
      case "emailAddress":
        return localizationData.SupplierListComponent_EmailAddress_gh_gh;
      case "contactPermissionRequired":
        return localizationData.SupplierListComponent_SensitiveContact_ex_ex;
      default:
        return ""
    }
  }
  get componentName(): string {
    return "SupplierListComponent";
  }
}

class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
  Description: string;
}
class SupplierListData {
  CorporateGroupId: string;
  CompanyName: string;
  SalesAdvisor: string;
  KeyRelationshipHolder: string;
  HasPurchasedFrom: string;
  Verified: string;
  RegistrationDate: string;
  LoginCount: string;
  Country: string;
  DDKYCStatus: string;
}
