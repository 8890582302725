import {ClientRepairPieceMappingBase,} from '@app/database-models'
//Generated Imports
export class ClientRepairPieceMapping extends ClientRepairPieceMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}