<div *rxMultilingual="" [rxLocalisationInit]="componentName" class="notes-list">
  <div
    class="ibox-title bg-white border-bottom px-0"
    [rxSpinner]="spinner"
  ></div>
  <div class="row mx-0 py-3" [rxLocalisationInit]="componentName" *ngIf="!isCMSNotes">
    <button #AddNote (click)="addNotes()" class="btn btn-success">
      <span rxText="NoteListComponent_AddNew_t"></span>
    </button>
    <div *ngIf="isDraftAvailable" [rxLocalisationInit]="componentName" class="col-md-4 col-lg-3 col-xl-4 col-form-label">
      <label rxText="NoteListComponent_Draft_t"></label>
    </div>

  </div>
  <div class="input-group custom-alert mb-3 flex-nowrap" [rxLocalisationInit]="componentName" *ngIf="!isCMSNotes">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon1">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <div class="input-group-desc px-3 py-4 d-flex flex-grow-1">
      <!-- <ul class="p-0 m-0 ml-3">
      <li class="mb-2">In general, use “Updates and Developments” as your subject.</li>
      <li class="mb-2">Please limit the number of new ‘subjects’ added (be generic not specific, so they can be used
        repeatedly).</li>
      <li> Check whether an already existing-subject is suitable for your note.</li>
    </ul> -->
      <div>
        <span class="text" [innerHTML]="transformYourHtml(noticeData)"></span>
      </div>
    </div>
  </div>

  <div class="hr-line-dashed border-top w-100" *ngIf="!isCMSNotes"></div>

  <div class="ibox mb-0 d-flex flex-column" [rxSpinner]="spin">
    <div class="ibox-title d-flex flex-wrap bg-white pr-5">
      <h5 rxText="Label_Notes_t"></h5>
      <div class="ibox-tools">
        <label class="ml-auto mr-0 mb-3">
          <input
            (keyup.enter)="search($event)"
            type="search"
            class="table_search form-control form-control-sm pr-4"
            placeholder=""
            [title]="titleSearch"
          />
        </label>
        <a
          class="dropdown-toggle"
          (click)="showGridColumn()"
          [title]="titleGridColumnSetting"
        >
          <i class="fa fa-cog"></i>
        </a>
        <app-system-table-setting
          [componentId]="'NoteListComponent'"
          *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)"
          (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="notesGrid.gridColumns"
          [isShowGridColumn]="isShowGridColumn"
          [moduleID]="moduleID"
          [selectedTab]="selectedTab"
        ></app-system-table-setting>
      </div>
    </div>

    <div
      data-toggle="buttons"
      class="btn-group btn-group-gray btn-group-toggle ml-auto mb-1"
    >
    <ng-container
      *ngIf="noteType == 1 && notesSpList.length > 0 && isAllowMoveNotes == true && isShowMoveNoteOptions == true"
      [rxLocalisationInit]="componentName">
      <ng-container *ngIf="!isHeaderBtnChecked" [rxLocalisationInit]="componentName">
        <button class="btn btn-success mr-1 border-0" (click)="selectAllNotesToMove()"
          style="background-color: #2084c3;text-decoration: none;" rxText="NoteListComponent_SelectBtn_t"></button>
      </ng-container>
      <ng-container *ngIf="isHeaderBtnChecked" [rxLocalisationInit]="componentName">
        <button class="btn btn-success mr-1 border-0" (click)="selectAllNotesToMove()"
          style="background-color: #2084c3;text-decoration: none;" rxText="NoteListComponent_UnselectBtn_t"></button>
      </ng-container>
      <button class="btn btn-success mr-1 border-0"
        [disabled]="!(selectedNotedIds.length > 0 || (isHeaderBtnChecked && excludedNotedIds.length < totalRecords))"
        (click)="moveNotes()" style="background-color: #23be9e;text-decoration: none;"
        rxText="NoteListComponent_MoveBtn_t"></button>
    </ng-container>
    <ng-container *ngIf="!isShowAllExpanded" [rxLocalisationInit]="componentName">
      <button class="btn btn-success chronological-show-button" (click)="onShowHideAllExpandedClick(true)" rxText="Btn_Show_All_Expanded_t"></button>
    </ng-container>
    <ng-container *ngIf="isShowAllExpanded" [rxLocalisationInit]="componentName">
      <button class="btn btn-success chronological-show-button" (click)="onShowHideAllExpandedClick(false)" rxText="Btn_Hide_All_Expanded_t"></button>
    </ng-container>
      <ng-container *ngIf="!hideShowAll && !isShowAll" [rxLocalisationInit]="componentName">
        <button
          class="btn btn-success"
          (click)="onShowHideAllClick(true)"
          rxText="Btn_Show_All_t"
        ></button>
      </ng-container>
      <ng-container *ngIf="!hideShowAll && isShowAll" [rxLocalisationInit]="componentName">
        <button
          class="btn btn-success"
          (click)="onShowHideAllClick(false)"
          rxText="Btn_Hide_All_t"
        ></button>
      </ng-container>
      <label
        class="btn btn-white active"
        [class.active]="noteType == 2"
        (click)="filterNotes(2)"
        rxText="Btn_All_t"
        [rxLocalisationInit]="componentName" *ngIf="!isCMSNotes"
      >
        <input value="2" type="radio" name="options" />
      </label>
      <label
        class="btn btn-white"
        [class.active]="noteType == 0"
        (click)="filterNotes(0)"
        rxText="Btn_Automated_t"
        [rxLocalisationInit]="componentName" *ngIf="!isCMSNotes"
      >
        <input value="0" type="radio" name="options" />
      </label>
      <label
        class="btn btn-white"
        [class.active]="noteType == 1"
        (click)="filterNotes(1)"
        rxText="Btn_Manual_t"
        [rxLocalisationInit]="componentName" *ngIf="!isCMSNotes"
      >
        <input value="1" type="radio" name="options" />
      </label>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="table-responsive" *ngIf="isShowGrid">
        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
          <rx-grid [design]="notesGrid"></rx-grid>
        </div>
      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>
</div>
