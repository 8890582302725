import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { CreditCardType } from '@app/models';
import { AbstractCreditCardType } from '../domain/abstract-credit-card-type';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access } from '@rxweb/angular-router';
import { BrowserModule, Title } from '@angular/platform-browser'; 
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { CreditCardTypeEditComponent } from '../edit/credit-card-type-edit.component';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("CreditCardTypeAddComponent")
@access({ accessLevel: RolePermissionEnums.CreditCard, action: "post" })

@Component({
  selector: "app-credit-card-type-add",
  templateUrl: './credit-card-type-add.component.html'
})
export class CreditCardTypeAddComponent extends AbstractCreditCardType implements OnInit, OnDestroy {
  creditCardType: CreditCardType;
  subscription: any;
  creditCardEdit: any = CreditCardTypeEditComponent;
  localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    showSystemHelpIcon: boolean = false;
    titleHelp: string;

  constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.CreditCard;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Credit Cards - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.creditCardType = new CreditCardType();
    this.creditCardType.creditCardTypeId = 0;
    this.creditCardType.statusId = StatusEnum.Active;
    this.creditCardTypeFormGroup = this.formBuilder.formGroup(this.creditCardType) as IFormGroup<CreditCardType>;
    this.showComponent = true;
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  addCreditCardType(isRedirect) {
    this.creditCardTypeFormGroup.submitted = true;
    if (this.creditCardTypeFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.creditCardTypeFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert[0].showMessage);
        }
        isRedirect ? this.router.navigate(['/credit-card-types', data]) : this.router.navigate(['/credit-card-types']);
      })
    } else {
      for (const key of Object.keys(this.creditCardTypeFormGroup.controls)) {
        if (this.creditCardTypeFormGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }
  get componentName(): string {
    return "CreditCardTypeAddComponent";
  }
}
