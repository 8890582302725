import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRecurringProductCyclePeriodLookUpBase {

//#region recurringProductCyclePeriodId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'recurringProductCyclePeriodId', keyColumn: true})
        recurringProductCyclePeriodId : number;
//#endregion recurringProductCyclePeriodId Prop


//#region recurringProductCyclePeriodName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'recurringProductCyclePeriodName', keyColumn: false})
        recurringProductCyclePeriodName : string;
//#endregion recurringProductCyclePeriodName Prop

}