import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCollectionDesignBase {

//#region collectionDesignId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'collectionDesignId', keyColumn: true})
        collectionDesignId : number;
//#endregion collectionDesignId Prop


//#region collectionId Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'collectionId', keyColumn: false })
        collectionId : number;
//#endregion collectionId Prop


//#region designTypeId Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'displayType', keyColumn: false})
        designTypeId : number;
//#endregion designTypeId Prop


//#region languageId Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" }, class: ["text-left"],visible: false, columnIndex:9, allowSorting: true, headerKey: 'video', keyColumn: false})
        languageId : number;
//#endregion languageId Prop


//#region displayOrderId Prop
        @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 10, allowSorting: true, headerKey: 'displayOrder', keyColumn: false })
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region designTypeName Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayType', keyColumn: false})
        designTypeName : string;
//#endregion designTypeName Prop


//#region description Prop
        @gridColumn({
                template:{
                        div:{
                          attributes:{
                            innerHTML:function(x,y){
                             return this.description;
                            }
                          },
                        }
                    },
                style: { width: "15%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'contentText', keyColumn: false})
        description : string;
//#endregion description Prop


//#region imagePictureId1 Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'imagePictureId1', keyColumn: false})
        imagePictureId1 : string;
//#endregion imagePictureId1 Prop


//#region imagePictureId2 Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'imagePictureId2', keyColumn: false})
        imagePictureId2 : string;
//#endregion imagePictureId2 Prop


//#region imagePictureId3 Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'imagePictureId3', keyColumn: false})
        imagePictureId3 : string;
//#endregion imagePictureId3 Prop


//#region imagePictureId4 Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'imagePictureId4', keyColumn: false})
        imagePictureId4 : string;
//#endregion imagePictureId4 Prop


//#region imagePictureId5 Prop
        @gridColumn({visible: false, columnIndex:10, allowSorting: true, headerKey: 'imagePictureId5', keyColumn: false})
        imagePictureId5 : string;
//#endregion imagePictureId5 Prop


//#region videoUrl Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:9, allowSorting: true, headerKey: 'video', keyColumn: false})
        videoUrl : string;
//#endregion videoUrl Prop

}