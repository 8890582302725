import { getLocalizedValue } from "../common-logic/common-logic";

declare const $: any;
declare const jQuery: any;
declare const Dropzone: any;

export function bindSlider(index?: number) {
  var peoplesTab = $("#menu_slider");
  if ($(peoplesTab).length > 0) {
    var menuSlider = peoplesTab,
      menuSliderItems = $("#menu_slider > li"),
      sliderOptions = {
        slidesToShow: 9,
        slidesToScroll: 1,
        variableWidth: true,
        touchThreshold: 50,
        arrows: true,
        appendArrows: $(".slick_demo-btn"),
        lazyLoad: 'ondemand',
        infinite: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1,
              infinite: false
            }
          }
        ]
      };

    function getNewOptions() {
      var sliderWidth = menuSlider.outerWidth();
      var slideArr = [];
      var $slidesToShow;

      function getSum(total, num) {
        return total + Math.round(num);
      }
      let data = menuSliderItems;
      menuSliderItems.each(function (i, v) {
        slideArr.push($(this).outerWidth());
        var currentSum = slideArr.reduce(getSum, 0);
        if (currentSum >= sliderWidth) {
          $slidesToShow = i;
          return false;
        } else {

          $slidesToShow = $("#menu_slider > li").length;
        }
      });

      return $slidesToShow;
    }

    sliderOptions.slidesToShow = getNewOptions();
    menuSlider.not('.slick-initialized').slick(sliderOptions);

    function createSlider() {
      sliderOptions.slidesToShow = getNewOptions();
      menuSlider.not('.slick-initialized').slick(sliderOptions);
    }

    //createSlider();
    $(window).on('resize orientationChange', function (event) {
      $("#menu_slider.slick-initialized").slick('unslick');
      createSlider();
    });

    $("#menu_slider .nav-link").click(function () {
      $("#menu_slider .nav-link").removeClass("active");
    });
  }
  // if (!!index) {
  //   gotoslider(index)
  // }
}
export function gotoslider(index) {
  $('.slider-nav').slick('slickGoTo', index - 1);
}
export function bindChildSlider() {
  var peoplesTab = $("#menu_slider_child");
  if ($(peoplesTab).length > 0) {
    var menuSlider = peoplesTab,
      menuSliderItems = $("#menu_slider_child > li"),
      sliderOptions = {
        slidesToShow: 9,
        slidesToScroll: 1,
        variableWidth: true,
        touchThreshold: 50,
        arrows: true,
        appendArrows: $(".slick_demo-btn"),
        lazyLoad: 'ondemand',
        infinite: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1,
              infinite: false
            }
          }
        ]
      };

    function getNewOptions() {
      var sliderWidth = menuSlider.outerWidth();
      var slideArr = [];
      var $slidesToShow;

      function getSum(total, num) {
        return total + Math.round(num);
      }
      let data = menuSliderItems;
      menuSliderItems.each(function (i, v) {
        slideArr.push($(this).outerWidth());
        var currentSum = slideArr.reduce(getSum, 0);
        if (currentSum >= sliderWidth) {
          $slidesToShow = i;
          console.log("else statement");
          return false;
        } else {

          $slidesToShow = $("#menu_slider_child > li").length;
        }
      });

      return $slidesToShow;
    }

    sliderOptions.slidesToShow = getNewOptions();
    menuSlider.not('.slick-initialized').slick(sliderOptions);

    function createSlider() {
      sliderOptions.slidesToShow = getNewOptions();
      menuSlider.not('.slick-initialized').slick(sliderOptions);
    }

    //createSlider();
    $(window).on('resize orientationChange', function (event) {
      $("#menu_slider_child.slick-initialized").slick('unslick');
      createSlider();
    });

    $("#menu_slider_child .nav-link").click(function () {
      $("#menu_slider_child .nav-link").removeClass("active");
    });
  }
}

export function bindUserSlider(index?: number) {
  var peoplesTab = $("#menu_slider");
  if ($(peoplesTab).length > 0) {
    var menuSlider = peoplesTab,
      menuSliderItems = $("#menu_slider > li"),
      sliderOptions = {
        slidesToShow: 9,
        slidesToScroll: 1,
        variableWidth: true,
        touchThreshold: 50,
        arrows: true,
        appendArrows: $(".slick_demo-btn"),
        lazyLoad: 'ondemand',
        focusOnSelect: true,
        adaptiveHeight: true,
        asNavFor: '.slider-nav',
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1,
            }
          }
        ]
      };

    function getNewOptions() {
      var sliderWidth = menuSlider.outerWidth();
      var slideArr = [];
      var $slidesToShow;

      function getSum(total, num) {
        return total + Math.round(num);
      }
      let data = menuSliderItems;
      menuSliderItems.each(function (i, v) {
        slideArr.push($(this).outerWidth());
        var currentSum = slideArr.reduce(getSum, 0);
        if (currentSum >= sliderWidth) {
          $slidesToShow = i;
          return false;
        } else {

          $slidesToShow = $("#menu_slider > li").length;
        }
      });

      return $slidesToShow;
    }

    sliderOptions.slidesToShow = getNewOptions();
    menuSlider.not('.slick-initialized').slick(sliderOptions);

    function createSlider() {
      sliderOptions.slidesToShow = getNewOptions();
      menuSlider.not('.slick-initialized').slick(sliderOptions);
    }

    //createSlider();
    $(window).on('resize orientationChange', function (event) {
      $("#menu_slider.slick-initialized").slick('unslick');
      createSlider();
    });

    $("#menu_slider .nav-link").click(function () {
      $("#menu_slider .nav-link").removeClass("active");
    });
  }
  if (!!index) {
    gotoslider(index)
  }
}

export function bindSupplierSlider(selectedTab: string = "", index?: number) {
  var peoplesTab = $("#menu_slider");
  if ($(peoplesTab).length > 0) {
    var menuSlider = peoplesTab,
      menuSliderItems = $("#menu_slider > li"),
      sliderOptions = {
        slidesToShow: 9,
        slidesToScroll: 1,
        variableWidth: true,
        touchThreshold: 50,
        arrows: true,
        appendArrows: $(".slick_demo-btn"),
        lazyLoad: 'ondemand',
        focusOnSelect: true,
        infinite: true,
        adaptiveHeight: true,
        asNavFor: '.slider-nav',
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1,
              infinite: true
            }
          }
        ]
      };

    function getNewOptions() {
      var sliderWidth = menuSlider.outerWidth();
      var slideArr = [];
      var $slidesToShow;

      function getSum(total, num) {
        return total + Math.round(num);
      }
      let data = menuSliderItems;
      menuSliderItems.each(function (i, v) {
        slideArr.push($(this).outerWidth());
        var currentSum = slideArr.reduce(getSum, 0);
        if (currentSum >= sliderWidth) {
          $slidesToShow = i;
          return false;
        } else {

          $slidesToShow = $("#menu_slider > li").length;
        }
      });

      return $slidesToShow;
    }

    sliderOptions.slidesToShow = getNewOptions();
    menuSlider.not('.slick-initialized').slick(sliderOptions);

    // if (selectedTab != "") {
    //   if (selectedTab == "hse-entries" || selectedTab == "client-repair" || selectedTab == "client-repair" || selectedTab == "risks" || selectedTab == "bespoke-order") {
    //     var docTrack = document.getElementsByClassName("slick-track")[0];
    //     if (docTrack != null) {
    //       (docTrack as HTMLElement).style.transform = "translate3d(-508px, 0px, 0px)";
    //     }
    //   }
    // }

    function createSlider() {
      sliderOptions.slidesToShow = getNewOptions();
      menuSlider.not('.slick-initialized').slick(sliderOptions);
      // if (selectedTab != "") {
      //   if (selectedTab == "hse-entries" || selectedTab == "client-repair" || selectedTab == "client-repair" || selectedTab == "risks" || selectedTab == "bespoke-order") {
      //     var docTrack = document.getElementsByClassName("slick-track")[0];
      //     if (docTrack != null) {
      //       (docTrack as HTMLElement).style.transform = "translate3d(-508px, 0px, 0px)";
      //     }
      //   }
      // }
    }

    //createSlider();
    $(window).on('resize orientationChange', function (event) {
      $("#menu_slider.slick-initialized").slick('unslick');
      createSlider();
    });

    $("#menu_slider .nav-link").click(function () {
      $("#menu_slider .nav-link").removeClass("active");
    });
  }
  if (!!index) {
    $('.slider-nav').slick('slickGoTo', index - 1);
  }
}


export function bindDataItem() {
  Dropzone.options.dropzoneForm = {
    paramName: "file", // The name that will be used to transfer the file
    maxFilesize: 2, // MB
    dictDefaultMessage: "<strong>Drop files here or click to upload. </strong></br> (This is just a demo dropzone. Selected files are not actually uploaded.)"
  };
}

export function showToolTip(id: string, content) {
  $("#" + id).tooltip({
    content: content,
    position: { my: "left+1 center", at: "right center" },
    open: function (event, ui) {
      ui.tooltip.css("max-width", "400px");
      ui.tooltip.css("background-color", "gray");
      ui.tooltip.css("color", "white");
    }
  });





}

export function showPersonaNonGrataToolTip() {
  
  $(".personaNonGrataToolTip").tooltip({
    content: getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t"),
    position: { my: "left+2 center", at: "right center" },
    open: function (event, ui) {
      ui.tooltip.css("z-index",9999999)
      ui.tooltip.css("max-width", "400px");
      ui.tooltip.css("background-color", "gray");
      ui.tooltip.css("color", "white");
    }
  });
}

export function bindTooltip(id?, introducesBy?) {
  
  setTimeout(() => {
    showSensitiveContactToolTip(id.toString(), getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + introducesBy);
  }, 100);
}

export function showSensitiveContactToolTip(id: string, content) {
  $("#" + id).tooltip({
    content: content,
    position: { my: "left+2 center", at: "right center" },
    open: function (event, ui) {
      ui.tooltip.css("z-index",9999999)
      ui.tooltip.css("max-width", "400px");
      ui.tooltip.css("background-color", "gray");
      ui.tooltip.css("color", "white");
    }
  });
}

export function triggerPeopleMenuClick() {
  var element = document.getElementById("peopleMenuLink");
  if (element != null) {
    element.click();
  }
}
export function sideBarScroll() {
  var element = document.getElementById("gtrac");
  var height = (window.outerHeight) - (window.outerHeight * 0.1);
  // $("#nav-side-menu").mouseenter(function () {

  //   disableScrolling();
  // });
  // $("#nav-side-menu").mouseleave(function () {
  //   enableScrolling();
  // });

  // $('.slim-scroll').slimScroll({
  //   allowPageScroll: false,
  //   height:"auto"
  // });

}

function disableScrolling() {
  window.event.stopImmediatePropagation();
  var x = window.pageXOffset || document.documentElement.scrollLeft;
  var y = window.pageYOffset || document.documentElement.scrollTop;

  // window.onscroll=function(){
  //   window.scrollTo(x, y);};

  window.onscroll = function () {
    window.scrollTo(x, y);

  };

}


function enableScrolling() {
  window.onscroll = function () { };
}

export function fixedContent() {
  let SideBar = document.querySelector(".navbar-default.navbar-static-side.navbar-pos-md");
  let MainContent = document.querySelector("#page-wrapper");
  SideBar.addEventListener("mouseleave", (e) => {
    SideBar.setAttribute("style", "position:fixed;overflow-y:scroll;");
    MainContent.setAttribute("style", "position:relative;overflow-y:scroll;");
  });

  SideBar.addEventListener("mouseenter", (e) => {
    MainContent.setAttribute("style", "position:fixed !important;overflow-y:scroll;");
    SideBar.setAttribute("style", "position:relative;overflow-y:scroll;");
  });
}
