import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPaymentMethodRecordBase {

//#region paymentMethodId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'paymentMethodId', keyColumn: true})
        paymentMethodId : number;
//#endregion paymentMethodId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region visibleName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'visibleName', keyColumn: false})
        visibleName : string;
//#endregion visibleName Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region systemKeyword Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'systemKeyword', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region isImpersonated Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'isImpersonated', keyColumn: false})
        isImpersonated : boolean;
//#endregion isImpersonated Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region isActive Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'isActive', keyColumn: false})
        isActive : boolean;
//#endregion isActive Prop

}