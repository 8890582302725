import {vMainCollectionLookUpBase,} from '@app/database-models'
//Generated Imports
export class vMainCollectionLookUp extends vMainCollectionLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}