import {TripBase,CountryBase,} from '@app/database-models'
//Generated Imports
export class Trip extends TripBase 
{




//#region Generated Reference Properties
//#region country Prop
country : CountryBase;
//#endregion country Prop

//#endregion Generated Reference Properties











}