import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vShippingRateComputationMethodTypeLookUpBase {

//#region shippingRateComputationMethodTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'shippingRateComputationMethodTypeId', keyColumn: true})
        shippingRateComputationMethodTypeId : number;
//#endregion shippingRateComputationMethodTypeId Prop


//#region shippingRateComputationMethodTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'shippingRateComputationMethodTypeName', keyColumn: false})
        shippingRateComputationMethodTypeName : string;
//#endregion shippingRateComputationMethodTypeName Prop

}