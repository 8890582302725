import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vChainLengthRecordBase {

//#region lengthId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'lengthId', keyColumn: true})
        lengthId : number;
//#endregion lengthId Prop


//#region chainLengthName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'chainLengthName', keyColumn: false})
        chainLengthName : string;
//#endregion chainLengthName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}