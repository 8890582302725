import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceBigPictureBase {

//#region piecePictureID Prop
        @prop()
        piecePictureID : number;
//#endregion piecePictureID Prop


//#region productVariantBatchPieceID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceID : number;
//#endregion productVariantBatchPieceID Prop


//#region pictureID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID : number;
//#endregion pictureID Prop


//#region pictureID1 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureID1 : number;
//#endregion pictureID1 Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}