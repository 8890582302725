import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { vVatNumberStatusLookUpBase } from './v-vat-number-status-look-up-base';


export class vCollectionInspirationBase {

//#region collectionInspirationId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerTitle: 'collectionInspirationId', keyColumn: true})
        collectionInspirationId : number;
//#endregion collectionInspirationId Prop


//#region collectionId Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerTitle: 'collectionId', keyColumn: false })
        collectionId : number;
//#endregion collectionId Prop


//#region displayOrderId Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 4, allowSorting: true, headerTitle: 'Display Order', keyColumn: false })
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region imageAlt Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'imageAlt', keyColumn: false})
        imageAlt : string;
//#endregion imageAlt Prop

//#region imagePictureId Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'imagePictureId', keyColumn: false})
        imagePictureId : number;
//#endregion imagePictureId Prop

}