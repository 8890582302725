import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectCorporateMappingBase {

//#region projectCorporateID Prop
        @prop()
        projectCorporateID : number;
//#endregion projectCorporateID Prop


//#region projectID Prop
        @required()
        projectID : number;
//#endregion projectID Prop


//#region corporateID Prop
        @required()
        corporateID : number;
//#endregion corporateID Prop


//#region invited Prop
        @prop()
        invited : boolean;
//#endregion invited Prop


//#region accepted Prop
        @prop()
        accepted : boolean;
//#endregion accepted Prop


//#region declined Prop
        @prop()
        declined : boolean;
//#endregion declined Prop


//#region attended Prop
        @prop()
        attended : boolean;
//#endregion attended Prop


//#region sendEInvitation Prop
        @prop()
        sendEInvitation : boolean;
//#endregion sendEInvitation Prop


//#region eInvitationSentOn Prop
        @prop()
        eInvitationSentOn : Date;
//#endregion eInvitationSentOn Prop


//#region insiderRequestApproval Prop
        @required()
        insiderRequestApproval : number;
//#endregion insiderRequestApproval Prop

//#region statusId Prop
        @prop({defaultValue: 307})
        @required()
        statusId : number;
//#endregion statusId Prop

//#region peopleOrCorporate Prop
        @prop({defaultValue: 0})
        peopleOrCorporate : Number;
//#endregion peopleOrCorporate Prop

}