import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemFieldsBookingStatusRecordBase {

//#region statusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'statusId', keyColumn: true})
        statusId : number;
//#endregion statusId Prop

}