import {vNationalityLookUpBase,} from '@app/database-models'
//Generated Imports
export class vNationalityLookUp extends vNationalityLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}