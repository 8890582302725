import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBangleSizeBase {

//#region bangleSizeId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:0, allowSorting: true, headerKey: 'BangleSizeListComponent_Id_gh', keyColumn: true})
        bangleSizeId : number;
//#endregion bangleSizeId Prop


//#region bangleSize Prop
        @gridColumn({ isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'BangleSizeListComponent_Size_gh', keyColumn: false})
        bangleSize : string;
//#endregion bangleSize Prop

}