import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductAttributeMappingBase {

//#region productVariantAttributeId Prop
        @prop()
        productVariantAttributeId : number;
//#endregion productVariantAttributeId Prop


//#region productVariantId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantId : number;
//#endregion productVariantId Prop


//#region productAttributeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productAttributeId : number;
//#endregion productAttributeId Prop


//#region textPrompt Prop
        @required()
        @maxLength({value:200})
        textPrompt : string;
//#endregion textPrompt Prop


//#region isRequired Prop
        @required()
        isRequired : boolean;
//#endregion isRequired Prop


//#region attributeControlTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        attributeControlTypeId : number;
//#endregion attributeControlTypeId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop







}