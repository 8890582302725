import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, OnDestroy, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Subscription } from 'rxjs';
import { AbstractWatchInvoice } from '../domain/abstract-watch-invoice';
import { WatchInvoiceAddComponent } from '../add/watch-invoice-add.component';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { GridColumnConfig } from '@rxweb/grid';
import { WatchInvoiceListViewModel } from 'src/app/view-model/watch-invoice-list-view-model';
import { AppGridSelectDesigner } from 'src/app/domain/app-grid';
import { WatchInvoice } from 'src/app/models/extended-models/watch-invoice';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { multilingual } from '@rxweb/localization';
import { PlatformLocation } from '@angular/common';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
@multilingual("WatchInvoiceListComponent")
@Component({
  selector: 'app-watch-invoice-list',
  templateUrl: './watch-invoice-list.component.html'
})
export class WatchInvoiceListComponent extends AbstractWatchInvoice implements OnInit,OnDestroy {
  watchInvoices: WatchInvoiceListViewModel[];
  watchInvoicesGrid: AppGridSelectDesigner;
  modalView: ModalView;
  subscription: any;
  id: number;
  isFilter: boolean = false;
  titleSearch: string;
  titleGridColumnSetting: string;
  paginationViewModel: PaginationViewModel;
  sort: boolean;
  json: any;
  isShowGrid: boolean;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: any;
  totalRecords: number = 0;
  isNoRecordFound: boolean;
  isShowGridColumn: boolean = false;
  constructor(modelView: ModalView, private activatedRoute: ActivatedRoute, private componentResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    });
    this.modalView = modelView;
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit() : void
  {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.paginationViewModel = new PaginationViewModel();
    this.sort = true;
    this.paginationViewModel.sortOrder = "true";
    this.paginationViewModel.orderBy = "createdOn";
    this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      setTimeout(() => {
        this.bindGrid();
      }, 0);
    })

  }
  bindGrid()
  {
    this.spin = true;
    this.isShowGrid = true;
    this.json["watchPartId"] = this.id;
    this.subscription = this.get({ path: "api/WatchInvoice", queryParams: { query: encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((t: any) => {

      this.spin = false;
      this.showComponent = true;
      setTimeout(() => {
        if (this.watchInvoicesGrid) {
          this.watchInvoicesGrid.storeProcedure.length = 0;
        }
        this.watchInvoices = t;
        if (this.watchInvoices.length > 0) {
          this.totalRecords = this.watchInvoices[0].totalCount;
          this.isNoRecordFound = false;
          this.isShowGrid = true;
        }
        else {
          this.isNoRecordFound = true;
          this.isShowGrid = false;
        }

        this.watchInvoicesGrid = new AppGridSelectDesigner(this.watchInvoices, WatchInvoiceListViewModel, { actions: {onWatchInvoiceNumber: this.onWatchInvoiceNumber.bind(this), onEditData: this.onEdit.bind(this),onCertificateView: this.onCertificateView.bind(this) , onDelete: this.onDelete.bind(this)} }, this.componentResolver, this.viewContainerRef, this.json["sortOrder"]);
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(x => {
            this.watchInvoicesGrid.gridColumns.forEach(y => {
              if (y.name == x.ColumnName)
                y.visible = x.Visible;
            });
          });
          this.gridColumnConfig = this.watchInvoicesGrid.gridColumns;
          this.gridColumnSettings.emit(this.gridColumnConfig)
        }
        this.watchInvoicesGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: !this.isFilter ? 1 : this.paginationViewModel.pageIndex,
          onPageSorting: this.onPageSorting.bind(this)
        }

        this.watchInvoicesGrid.currentPage = this.json["pageIndex"];
        this.watchInvoicesGrid.pagingSource.splice(this.watchInvoicesGrid.pagingSource.findIndex(x => x == this.json["rowCount"]), 1);
        this.watchInvoicesGrid.pagingSource.unshift(this.json["rowCount"]);
        this.watchInvoicesGrid.maxPerPage = this.json["rowCount"];
        this.watchInvoicesGrid.designClass.tableClass = "table table-responsive table-hover dataTables-example dataTable text-center border-bottom table-list".split(" ");
        this.watchInvoicesGrid.componentId = this.componentName;
        if (this.isShowGrid) {
          setTimeout(() => {
            this.watchInvoicesGrid.design(document.getElementById("watchInvoicesGrid"));
          }, 100);
          var data = document.getElementsByClassName('table-responsive');
          if (data != null) {
            for (let i = 0; i < data.length; i++) {
              const e = data[i];
              if (e instanceof HTMLElement) {
                e.style.overflow = "visible"
              }
            }
          }
        }
      }, 0);
    })
  }
  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = (!this.sort).toString();
    this.sort = !this.sort;
    this.json["orderBy"] = x;
    this.watchInvoicesGrid.storeProcedure.nextPage = z;
    this.showComponent = false;
    this.watchInvoicesGrid.refresh("watchInvoicesGrid");
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.watchInvoicesGrid.maxPerPage;
    this.watchInvoicesGrid.storeProcedure.nextPage = x;
    this.watchInvoicesGrid.refresh("watchInvoicesGrid");
    this.bindGrid();
  }
  onCertificateView(watchInvoiceURL: WatchInvoiceListViewModel) {
    if (watchInvoiceURL.invoiceUrl != null && watchInvoiceURL.invoiceUrl != "") {
      const filePath = watchInvoiceURL.invoiceUrl?.split("?")[0];
      const encodedQueryString = watchInvoiceURL.invoiceUrl.split("?")[1];
      const encodedFilePath  = filePath.replace(/%/g, '%25').replace(/#/g, '%23');
      watchInvoiceURL.invoiceUrl = encodedFilePath  + (encodedQueryString ? '?' + encodedQueryString : '');
      window.open(watchInvoiceURL.invoiceUrl, "_blank");
    }
  }
  onWatchInvoiceNumber(watchInvoiceNumber: WatchInvoiceListViewModel, y) {
    watchInvoiceNumber.invoiceNumber = y.target.value;
  }
  onEdit(watchInvoice: WatchInvoiceListViewModel) {
    this.watchInvoicesGrid.source.forEach(x => {
      if (x.watchInvoiceId == watchInvoice.watchInvoiceId) {
        if (x.keyId > 0) {
          watchInvoice.keyId = x.keyId;
        }
      }
    });
    this.spin = true;
    let watchInvoiceInfo = new WatchInvoice();
    watchInvoiceInfo.watchInvoiceId = watchInvoice.watchInvoiceId;
    watchInvoiceInfo.watchPartId = watchInvoice.watchPartId;
    watchInvoiceInfo.fileName = watchInvoice.fileName;
    watchInvoiceInfo.createdOn = new Date();
    watchInvoiceInfo.createdBy = watchInvoice.createdBy;
    watchInvoiceInfo.invoiceNumber = watchInvoice.invoiceNumber;

    this.put({ path: "api/WatchInvoice", params: [watchInvoice.watchInvoiceId], body: watchInvoiceInfo }).subscribe(data => {
      this.spin = false;
      //this.toastr.success("Data Updated Successfully");
      var existsAlert = getLocalizedValue("Data_Updated");
      if (existsAlert) {
        this.toastr.success(existsAlert);
      }
      this.isFilter = true;
      this.watchInvoicesGrid.refresh("watchInvoicesGrid");
      this.bindGrid();
    })
  }
  onDelete(watchInvoice: WatchInvoiceListViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ path: "api/WatchInvoice", body: null, params: [watchInvoice.watchInvoiceId] }).subscribe(data => {
          this.spin = false;
          var existsAlert = getLocalizedValue("Data_Deleted");
          if (existsAlert) {
            this.toastr.success(existsAlert);
          }
          this.isFilter = true;
          this.totalRecords = 0;
          this.watchInvoicesGrid.refresh("watchInvoicesGrid");
          this.bindGrid();
        })
      }
      this.dialog.hide()
    })

  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Watch_Invoice");
    if (existsAlert) {
      return existsAlert;
    }
  }
  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(element => {
        var index = this.watchInvoicesGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
        if (index != -1) {
          if (this.watchInvoicesGrid.gridColumns[index].visible != element.Visible) {
            element.Visible = this.watchInvoicesGrid.gridColumns[index].visible;
          }
        }
      })
    }
    this.watchInvoicesGrid.refresh("watchInvoicesGrid");
    this.bindGrid();
  }
  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.totalRecords = 0;
      this.json['searchString'] = value.target.value;
      this.json["pageIndex"] = 1;
      this.watchInvoicesGrid.storeProcedure.nextPage = 1;
      if (this.watchInvoicesGrid != null && this.watchInvoicesGrid != undefined && this.watchInvoicesGrid.source.length > 0) {
        this.watchInvoicesGrid.refresh("watchInvoicesGrid");
      }
      this.bindGrid();
    }, 4000);
    this.spin = true;








  }
  showAddForm() {
    this.modalView.show(WatchInvoiceAddComponent, { watchPartId: this.id }).then(t => {
      this.isFilter = true;
      if (this.watchInvoicesGrid != null && this.watchInvoicesGrid.source.length > 0) {
        this.watchInvoicesGrid.refresh("watchInvoicesGrid");
      }
      this.bindGrid();
    });
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "WatchInvoiceListComponent";
  }
}
