import { AbstractEvent } from '../domain/abstract-event';
import { OnInit, Component, EventEmitter, Input } from '@angular/core';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Router, ActivatedRoute } from '@angular/router';
import { AppGrid } from 'src/app/domain/app-grid';
import { SummaryViewModel } from 'src/app/view-model/summary-view-model';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';

import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { SummaryDetailsComponent } from '../summary-details/summary-details.component';
import { multilingual } from '@rxweb/localization';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { GridColumnConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
@multilingual("EventSummaryListComponent")


@Component({
    selector: "app-event-summary-list",
    templateUrl: './event-summary-list.component.html'
})
export class EventSummaryListComponent extends AbstractEvent implements OnInit {
    id:number;
    summaryGrid:AppGrid;
    summaryList:SummaryViewModel[];
    json:any;
    isFilter:boolean;
    totalRecords:number=0;
    isShowGridColumn: boolean = false;
    titleGridColumnSetting: string;
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    arrConfig: any[];
    @Input() moduleID: any;
    @Input() selectedTab: string = "";

    constructor(router:Router,private activatedRoute:ActivatedRoute,modelView:ModalView,private applicationBroadcaster: ApplicationBroadcaster,blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        this.router=router;
        this.modalView=modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'events', subMenu: '' })
    }
    ngOnInit(){
      this.isFilter = false;
      this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.json = JSON.parse(JSON.stringify({addedBy:0,eventId:this.id,pageIndex:PaginationEnum.PageIndex,rowCount:PaginationEnum.RowCount,sortOrder:true,orderBy:'name',searchString:''}));
        this.json["eventId"]=this.id;
        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
            this.getColumnsData = t;
            if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            }
            setTimeout(() => {
                this.bindGrid();
            }, 0);
        }) 
    }

    

    bindGrid(){
        this.isShowGrid = true;
        this.spin=true;
        this.totalRecords=0;
      this.get({ queryParams: { query: encodeURIComponent(JSON.stringify(this.json)), flag: true}}).subscribe((response:any)=>{
            this.summaryList= response;
            if(this.summaryList.length>0){
                this.totalRecords=this.summaryList[0].totalCount;
                this.isNoRecordFound = false;
            }
            else{
                this.isNoRecordFound =true;
                this.isShowGrid=false;
            }
            if(!this.isFilter){
                this.summaryGrid = new AppGrid(this.summaryList,SummaryViewModel,{actions:{onInvitedLink:this.showInvitedDetails.bind(this),
                                                                                           onAcceptedLink:this.showAcceptedDetails.bind(this),
                                                                                           onAttendedLink:this.showAttendedDetails.bind(this),
                                                                                           onDeclinedLink:this.showDeclinedDetails.bind(this),
                                                                                           onIntroducedLink:this.showIntroducedDetails.bind(this)
                                                                                            }});
                if (this.arrConfig != undefined && this.arrConfig != null) {
                    this.arrConfig.forEach(x => {
                        this.summaryGrid.gridColumns.forEach(y => {
                            if (y.name == x.ColumnName)
                                y.visible = x.Visible;
                        });
                    });
                    this.gridColumnConfig = this.summaryGrid.gridColumns;
                    this.gridColumnSettings.emit(this.gridColumnConfig)
                }
                this.summaryGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                  }
                  this.summaryGrid.maxPerPage=this.json['rowCount'];
                  this.summaryGrid.componentId = this.componentName;  
            }
            else{
                this.summaryGrid.storeProcedure.length = this.totalRecords;
                this.summaryGrid.updateSource([]);
                this.summaryGrid.updateSource(this.summaryList)
            }
           
            this.summaryGrid.designClass.rowClass = [function () {
                let data = this;
                if (data.roleId == CustomerRoleTypeEnum.Admin) {
                  return "admin";
                }
                else {
                  return "customer";
                }
              }]
              this.spin=false;
        //    this.summaryGrid.design(document.getElementById("summary-list"));
        });
        this.summaryGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
        this.summaryGrid.designClass.headerClass = "table-header-sticky".split(" ");
    }
    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }
      applyVisible(gridColumnConfig: GridColumnConfig[]) {
        this.summaryGrid.reDesign();
        this.summaryGrid.updateSource([])
        this.summaryGrid.updateSource(this.summaryList);
    }

    showInvitedDetails(summaryList:SummaryViewModel){
        this.modalView.show(SummaryDetailsComponent,{inviteeType:1,eventId:this.id,addedBy:summaryList.AddedBy}).then(t=>{
           this.isFilter=true;
           this.summaryGrid.updateSource([]);
           this.bindGrid();
        })
    }

    showAcceptedDetails(summaryList:SummaryViewModel){
        this.modalView.show(SummaryDetailsComponent,{inviteeType:3,eventId:this.id,addedBy:summaryList.AddedBy}).then(t=>{
            this.isFilter=true;
            this.summaryGrid.updateSource([]);
            this.bindGrid();
        })
    }

    showAttendedDetails(summaryList:SummaryViewModel){
        this.modalView.show(SummaryDetailsComponent,{inviteeType:4,eventId:this.id,addedBy:summaryList.AddedBy}).then(t=>{
            this.isFilter=true;
            this.summaryGrid.updateSource([]);
            this.bindGrid();
        })
    }

    showDeclinedDetails(summaryList:SummaryViewModel){
        this.modalView.show(SummaryDetailsComponent,{inviteeType:2,eventId:this.id,addedBy:summaryList.AddedBy}).then(t=>{
            this.isFilter=true;
            this.summaryGrid.updateSource([]);
            this.bindGrid();
        })
    }

    showIntroducedDetails(summaryList:SummaryViewModel){
        this.modalView.show(SummaryDetailsComponent,{inviteeType:5,eventId:this.id,addedBy:summaryList.AddedBy}).then(t=>{
            this.isFilter=true;
           this.summaryGrid.updateSource([]);
           this.bindGrid();
         })
    }

    search(value) {
        // if(!(value.keyCode>=37 && value.keyCode<=40) && value.keyCode!=9 && value.keyCode!=32){
        //     if((value.target.value.replace(/ /g,'').length>2 && value.target.value!=undefined && value.target.value.replace(/ /g,'')!="") || (value.keyCode==8 && value.target.value=='')){
               setTimeout(() => {
                this.spin=false;
                this.json["pageIndex"]=1;
                  this.json['searchString']=value.target.value.replace(/^\s+|\s+$/gm,'');
                  this.summaryGrid.updateSource([]);
                  this.bindGrid();
                }, 4000);
                this.spin=true;
                
        //     }
        //   }
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json['sortOrder']=y;
        this.json['orderBy']=x;
        this.summaryGrid.storeProcedure.nextPage = z;
        this.summaryGrid.updateSource([]);
        this.bindGrid();
      }
    
      onPageChanging(x) {
        this.isFilter = true;
        this.json['pageIndex']=x;
        this.json['rowCount']= this.summaryGrid.maxPerPage;
        this.summaryGrid.storeProcedure.nextPage = x;
        this.bindGrid();
      }
      get componentName(): string {
        return "EventSummaryListComponent";
     }
}
