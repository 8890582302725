import { actionColumn, gridColumn } from "@rxweb/grid"
@actionColumn({
  allowSorting: false,
  style: { width: "1%","text-align": "left" },
  configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})

export class  vBookingClientsBase {

    @gridColumn({ style: { width: "1%", "text-align": "right" }, visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region tripCustomerID Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 1, allowSorting: true, headerKey: 'TripCustomerID', keyColumn: true })
    tripCustomerID: number;
    //#endregion tripCustomerID Prop

    //#region tripID Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, visible: false, columnIndex: 2, allowSorting: true, headerKey: 'TripID', keyColumn: false })
    tripID: number;
    //#endregion tripID Prop

    //#region customerID Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, visible: false, columnIndex: 3, allowSorting: true, headerKey: 'CustomerID', keyColumn: false })
    customerId: number;
    //#endregion customerID Prop

    //#region fullName Prop
    @gridColumn({ configuredTemplate: { templateName: 'reDirectPeopleAndTooltip' },style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 4, allowSorting: true, headerKey: 'ClientListComponent_FullName_gh', keyColumn: false })
    fullName: string;
    //#endregion fullName Prop

    //#region email Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 6, allowSorting: true, headerKey: 'ClientListComponent_Email_gh', keyColumn: false })
    email: string;
    //#endregion email Prop

    //#region registrationDate Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 7, allowSorting: true, headerKey: 'ClientListComponent_RegistrationDate_gh', keyColumn: false })
    registrationDate: string;
    //#endregion registrationDate Prop

    //#region statusId Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, headerKey: 'ClientListComponent_Verified_gh', keyColumn: false, configuredTemplate: {templateName: 'recordActive'} })
    statusId: string;
    //#endregion statusId Prop

    //#region isMapped Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, visible: false, columnIndex: 9, allowSorting: true, headerKey: 'IsMapped', keyColumn: false })
    isMapped: number;
    //#endregion isMapped Prop

    @gridColumn(
    {
      // template:{
      //     div:{
      //       attributes:{
      //         innerHTML:function(x,y){
      //           return this.company;
      //         }
      //       },
      //     }
      // }, 
      parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: false, configuredTemplate: {templateName: 'corporateGroupLink'}, headerKey: 'ClientListComponent_Corporates/Groups_gh', keyColumn: false
    })
    company:string

    url : string;

    CompanyDetails : any;
    CompaniesCount : number;
    contactPermissionRequired: boolean;
    introducesBy: string;
    isUnwelcome: boolean;
    roleId:number;
}
