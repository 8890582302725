import { gridColumn } from "@rxweb/grid"


export class vProjectsLookUpBase {

//#region projectId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'projectId', keyColumn: true})
        projectId : number;
//#endregion projectId Prop


//#region projectName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectName', keyColumn: false})
        projectName : string;
//#endregion projectName Prop

}