import {
  IFormGroup,
  RxwebValidators,
  minLength,
  RxFormBuilder,
} from "@rxweb/reactive-form-validators";
import {
  LeaveMaster,
  vLeaveTypeLookUp,
  vCurrencyLookUp,
  CustomerHoliday,
  vWeekDayLookUp,
} from "@app/models";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { ModalView } from "src/app/domain/customize-design/modal";
import { BaseDialog } from "src/app/domain/customize-design/dialog";
import { HolidayInfoViewModel } from "src/app/view-model/holiday-info-view-model";
import { CoreComponent } from "@rxweb/angular-router";
import { http } from "@rxweb/http";
import { BedRequestComponent } from "src/app/components/shared/global/bed-request/bed-request.component";
import { LeaveMasterDetailViewModel } from "src/app/models/extended-models/leave-master-detail-view-model";
import {
  LeaveTravelEventProjectMappingBase,
  EventCustomerMappingBase,
} from "@app/database-models";
import { FormArray } from "@angular/forms";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ViewChild, Directive } from "@angular/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { FileOutputViewModel } from "../../../../view-model/file-output-view-model";
import { FileInputViewModel } from "../../../../view-model/file-input-view-model";
import {
  FileTypeEnums,
  FileSizeEnums,
} from "../../../../custom-enum/file-type-enums";
import { LeaveMasterHolidayProcessList } from "src/app/models/extended-models/leave-master-holiday-process-list";
import { LeaveMasterHolidayProcessViewModel } from "src/app/models/extended-models/leave-master-holiday-process-view-model";
import { CalendarOptions } from "@fullcalendar/core";
import interactionPlugin from '@fullcalendar/interaction';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { LeaveFlightMappingBase } from "src/app/models/database-models/leave-flight-mapping-base";
import { LeaveAccomodationMappingBase } from "src/app/models/database-models/leave-accomodation-mapping-base";
import { LeaveVehicleMappingBase } from "src/app/models/database-models/leave-vehicle-mapping-base";
@Directive()
@http({
  path: "api/LeaveMasters",
})
export class AbstractLeaveMaster extends CoreComponent {
  leaveMasterFormGroup: IFormGroup<LeaveMaster>;
  leaveVehicleMappingFormGroup: IFormGroup<LeaveVehicleMappingBase>;
  leaveFlightMappingFormGroup: IFormGroup<LeaveFlightMappingBase>;
  leaveAccomodationMappingFormGroup: IFormGroup<LeaveAccomodationMappingBase>;
  holidayProcessListFormGroup: IFormGroup<HolidayInfoViewModel>;
  customerHolidayFormGroup: IFormGroup<CustomerHoliday>;
  showComponent: boolean = false;
  spin: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  browserStorage: BrowserStorage;
  modalView: ModalView;
  vLookUpYear: number[];
  vYearLookUp: any[];
  currentYear: number;
  prevYear: number;
  currentMonth: number;
  prevMonth: number;
  currentActiveTab: string = "myLeave";
  previousActiveTab: string = "";
  applyLeaveLookUps: any;
  selectLeaveType: number = 0;
  eventIds: string[];
  projectIds: string[];
  leaveDetail: LeaveMasterDetailViewModel;
  loggedInUserId: number;
  dateError: boolean = false;
  calendarData: any;
  options: CalendarOptions;
  @ViewChild("fullcalendar", { static: false })
  eventsModel: any;
  fullcalendar: FullCalendarComponent;
  fileViewModel: FileInputViewModel;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  fileOutputViewModelHoliday: FileOutputViewModel = new FileOutputViewModel();
  timeZoneLookUp: any;
  previousStartDate: any;
  previousEndDate: any;
  previousBudget: number;
  constructor(private blobState: BlobUploadsViewStateService) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.browserStorage = new BrowserStorage();
    this.vLookUpYear = new Array();
    this.vYearLookUp = new Array();
    this.bindYearLookup();
    this.bindAddHolidayYearLookup();
    this.currentYear = new Date().getFullYear();
    this.prevYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth();
    this.prevMonth = new Date().getMonth();
    this.leaveDetail = new LeaveMasterDetailViewModel();
    this.loggedInUserId = this.browserStorage.local.get("userId", true);
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.All;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
    this.timeZoneLookUp = [
      { id: "1", value: "A.M." },
      { id: "2", value: "P.M." },
    ];
    //this.fileViewModel.isMultipart = true;

    this.options = {
      editable: false,
      initialView: "dayGridMonth",
      headerToolbar: {
        left: "title",
        right: "prev,next today",
        center: "",
      },
      plugins: [dayGridPlugin, interactionPlugin],
      contentHeight: "auto",
    };
  }

  // updateFiles($event: FileOutputViewModel) {
  //   this.leaveMasterFormGroup.patchValue({
  //     file: $event.files
  //   });
  // }

  async updateFiles($event: FileOutputViewModel) {
    
    this.fileOutputViewModel = $event;
    this.leaveMasterFormGroup.patchValue({
      fileData: $event.fileBaseString,
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      //blobFileName: uploadedFileName,
      blobPath: BLOB_BASE.PeopleLeaveDocument,
      size: $event.files.size
    });
  }

  async updateFilesHolidayProcess($event: FileOutputViewModel) {
    this.fileOutputViewModelHoliday = $event;
    this.holidayProcessListFormGroup.patchValue({
      fileData1: $event.fileBaseString,
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      //blobFileName: uploadedFileName,
      blobPath: BLOB_BASE.PeopleLeaveDocument,
      size: $event.files.size
    });
  }

  async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);

    let url = res._response.request.url;
    let uploadedFileName = blobPath + decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );

    // IF SUCCEED TO UPLOAD
    if (res && (res._response.status == 200 || res._response.status == 201)) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined) {
        this.modalView
          .show(BedRequestComponent, {
            validationMessages: JSON.stringify(
              uploadedFileName
            ),
            headerMessage: "Image failed to upload:",
          })
          .then(() => { });
      }
    }
    return uploadedFileName;
  }

  holidayProcessListLookups: any = {
    leaveTypeLookUp: vLeaveTypeLookUp,
    holidayProcessList: HolidayInfoViewModel,
    currencyLookUp: vCurrencyLookUp,
  };
  leaveLookUps: any = {
    leaveTypeLookUp: vLeaveTypeLookUp,
    currencyLookUp: vCurrencyLookUp,
    leaveMasterDetail: LeaveMasterDetailViewModel,
  };
  userHolidayLeave: any = {
    customerHoliday: LeaveMasterHolidayProcessViewModel,
    customerLeave: LeaveMasterHolidayProcessList,
  };
  bindYearLookup() {
    for (let i = -2; i <= 2; i++) {
      this.vLookUpYear.push(new Date().getFullYear() + i);
    }
  }
  bindAddHolidayYearLookup() {
    for (let i = -1; i < 6; i++) {
      this.vYearLookUp.push({
        id: new Date().getFullYear() + i,
        year: new Date().getFullYear() + i,
      });
      //this.vYearLookUp.push(new Date().getFullYear() + i);
    }
  }
  badRequest = (data) => {
    this.spin = false;
    this.modalView
      .show(BedRequestComponent, { validationMessages: data })
      .then((t) => {});
  };
  changeLeaveType(leaveType) {
    this.selectLeaveType = leaveType;
    if (this.selectLeaveType == 8) {
      this.leaveMasterFormGroup.controls.currency.setValidators([
        RxwebValidators.required({ message: "You can't leave this empty" }),
      ]);
      this.leaveMasterFormGroup.controls.currency.updateValueAndValidity();
      this.leaveMasterFormGroup.controls.citiesMinesLocations.setValidators([
        RxwebValidators.required({ message: "You can't leave this empty" }),
      ]);
      this.leaveMasterFormGroup.controls.citiesMinesLocations.updateValueAndValidity();
      this.leaveMasterFormGroup.controls.budget.setValidators([
        RxwebValidators.required({ message: "You can't leave this empty" }),
        RxwebValidators.pattern({
          expression: { budget: /^[0-9]{0,8}[.]{0,1}[0-9]{0,2}$/ },
          message:
            "Please enter valid budget(8 digits and 2 decimal places allowed)",
        }),
      ]);
      this.leaveMasterFormGroup.controls.budget.updateValueAndValidity();
      console.log(this.leaveMasterFormGroup.value.leaveType);
      console.log(this.leaveMasterFormGroup.value.travelReportToNames);
      if(this.leaveMasterFormGroup.value.leaveType == 8) {
        this.leaveMasterFormGroup.patchValue({
          reportToName: this.leaveMasterFormGroup.value.travelReportToNames
        });
      }
      else {
        this.leaveMasterFormGroup.patchValue({
          reportToName: this.leaveMasterFormGroup.value.reportToNames
        });
      }

      console.log(this.leaveMasterFormGroup.value.reportToName);


    } else {
      this.leaveMasterFormGroup.controls.currency.setValidators([]);
      this.leaveMasterFormGroup.controls.currency.updateValueAndValidity();
      this.leaveMasterFormGroup.controls.citiesMinesLocations.setValidators([]);
      this.leaveMasterFormGroup.controls.citiesMinesLocations.updateValueAndValidity();
      this.leaveMasterFormGroup.controls.budget.setValidators([]);
      this.leaveMasterFormGroup.controls.budget.updateValueAndValidity();
      this.leaveMasterFormGroup.patchValue({
        reportToName: this.leaveMasterFormGroup.value.reportToNamesWithHOD,
      });
    }
  }
  bindEventProjectFormArray(
    formBuilder: RxFormBuilder,
    userId: number,
    masterId: number
  ) {
    let LeaveTravelEventProjectMappingsFormArray =
      this.leaveMasterFormGroup.get(
        "leaveTravelEventProjectMappings"
      ) as FormArray;
    let EventCustomerMappingsFormArray = this.leaveMasterFormGroup.get(
      "eventCustomerMappings"
    ) as FormArray;
    if (this.leaveMasterFormGroup.value.eventIds) {
      this.eventIds = this.leaveMasterFormGroup.value.eventIds.split(",");
      this.eventIds.forEach((data) => {
        let leaveTravelEventProjectMapping =
          new LeaveTravelEventProjectMappingBase();
        leaveTravelEventProjectMapping.eventId = parseInt(data);
        leaveTravelEventProjectMapping.projectId = 0;
        leaveTravelEventProjectMapping.eventOrProjectType = "E";
        leaveTravelEventProjectMapping.masterId = masterId;
        leaveTravelEventProjectMapping.lTA_MappingID = 0;
        this.leaveMasterFormGroup.get(
          "leaveTravelEventProjectMappings"
        ) as FormArray;
        LeaveTravelEventProjectMappingsFormArray.push(
          formBuilder.formGroup(
            leaveTravelEventProjectMapping
          ) as IFormGroup<LeaveTravelEventProjectMappingBase>
        );
      });
      this.eventIds.forEach((data) => {
        let eventCustomerMapping = new EventCustomerMappingBase();
        eventCustomerMapping.eventID = parseInt(data);
        eventCustomerMapping.customerID = userId;
        eventCustomerMapping.invited = true;
        eventCustomerMapping.addedBy = userId;
        eventCustomerMapping.eventCustomerID = 0;
        eventCustomerMapping.otherPeopleInvited = 0;
        eventCustomerMapping.guestCount = 0;
        this.leaveMasterFormGroup.get("eventCustomerMappings") as FormArray;
        EventCustomerMappingsFormArray.push(
          formBuilder.formGroup(
            eventCustomerMapping
          ) as IFormGroup<EventCustomerMappingBase>
        );
      });
    }
    if (this.leaveMasterFormGroup.value.projectIds) {
      this.projectIds = this.leaveMasterFormGroup.value.projectIds.split(",");
      this.projectIds.forEach((data) => {
        let leaveTravelEventProjectMapping =
          new LeaveTravelEventProjectMappingBase();
        leaveTravelEventProjectMapping.eventId = 0;
        leaveTravelEventProjectMapping.projectId = parseInt(data);
        leaveTravelEventProjectMapping.eventOrProjectType = "P";
        leaveTravelEventProjectMapping.masterId = masterId;
        leaveTravelEventProjectMapping.lTA_MappingID = 0;
        this.leaveMasterFormGroup.get(
          "leaveTravelEventProjectMappings"
        ) as FormArray;
        LeaveTravelEventProjectMappingsFormArray.push(
          formBuilder.formGroup(
            leaveTravelEventProjectMapping
          ) as IFormGroup<LeaveTravelEventProjectMappingBase>
        );
      });
    }
  }

  eventCustomerMappings(): FormArray {
    return this.leaveMasterFormGroup.get("eventCustomerMappings") as FormArray;
  }

  leaveTravelEventProjectMappings(): FormArray {
    return this.leaveMasterFormGroup.get(
      "leaveTravelEventProjectMappings"
    ) as FormArray;
  }

  holidayProcessLookups: any = {
    leaveTypeLookUp: vLeaveTypeLookUp,
    weekDaysLookUp: vWeekDayLookUp,
    customerHoliday: CustomerHoliday,
  };

  // eventClick(model) {
  //   alert(model.event._def.extendedProps.masterId);
  //   console.log(model);
  // }
  eventDragStop(model) {
    console.log(model);
  }
  dateClick(model) {
    console.log(model);
  }
}
