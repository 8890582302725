<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
    <div class="modal-content">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0">Error while Importing Users From Excel</h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" *ngIf="showComponent">
            <div rxText="ErrorImportingUserComponent_ErrorMessage_t">
            </div>
            <a (click)="ExportToExcelErrorData()"> Download Excel</a>
        </div>
    </div>
</div>