import { Component, OnInit, OnDestroy, Input, ViewChild, HostListener } from "@angular/core";
import { AbstractNote } from "../domain/abstract-note";

import { Subscription } from "rxjs";
import {
  RxFormBuilder,
  IFormGroup,
  RxFormArray,
  IAbstractControl,
} from "@rxweb/reactive-form-validators";

import { Note, TaskDetail } from "@app/models";
import { List } from "@rxweb/generics";
import { NotesEnum } from "src/app/enums/notes.enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { MultiLingualData, multilingual } from "@rxweb/localization";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { v4 as uuidv4 } from "uuid";
import { DialogViewMode } from "@rxweb/js";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { TINY_MCE_KEY } from "src/app/domain/system-constant";
import { Router } from "@angular/router";
import { TinyMCEdraftModel } from "src/app/view-model/tiny-mce-draft-view-model";
import { NotePopUpType } from "src/app/enums/note-popup-type.enum";
import { FormArray, FormGroup } from "@angular/forms";
import { TaskDetailBase } from "@app/database-models";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { DatePipe, PlatformLocation } from "@angular/common";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { Taskcomment } from "src/app/models/extended-models/task-comment";
import { AppGrid } from "src/app/domain/app-grid";
import { PaginationViewModel } from "src/app/view-model/pagination-view-model";
import { TaskCommentsViewModel } from "src/app/models/extended-models/Task-comment-model";

@multilingual("TaskReplyComponent")
@Component({
  selector: "app-task-reply",
  templateUrl: "./task-reply.component.html",
})
export class TaskReplyComponent
  extends AbstractNote implements OnInit, OnDestroy {
  
  isShowGrid: boolean = true;
  rowCount: number = PaginationEnum.RowCount;
  pageIndex: number = PaginationEnum.PageIndex;
  isFilter: boolean = false;
  sortOrder: string = "false";
  orderByColumn: string = "createdOn";
  totalRecords: number = 0;
  TaskGriddata: Taskcomment[];
  TaskGrid: AppGrid;
  loggedInUser: number;
  paginationViewModel: PaginationViewModel;
  TaskCommentFormGroup: IFormGroup<TaskCommentsViewModel>;
  Commentsdata: TaskCommentsViewModel;
  @Input() TaskId: any;
  @Input() Isdashboard: boolean=false;
  _this: any;
  constructor(private formBuilder: RxFormBuilder, private browserStorage: BrowserStorage) {
    super();
    if (this.browserStorage.local.get("userId") != null) {
      this.loggedInUser = this.browserStorage.local.get("userId");
    } else {
      this.loggedInUser = 0;
    }
    this._this = this;
  }


  ngOnInit(): void {
    this.Commentsdata = new TaskCommentsViewModel();
    this.Commentsdata.TaskDetailId = this.TaskId;
    this.TaskCommentFormGroup = this.formBuilder.formGroup(TaskCommentsViewModel, this.Commentsdata) as IFormGroup<TaskCommentsViewModel>;
    this.bindGrid();
   
  }
  bindGrid() {
    this.spin = true;
    this.totalRecords = 0;
    var json = {
      rowCount: this.rowCount,
      pageIndex: this.pageIndex,
     sortOrder: this.sortOrder,
     orderBy: this.orderByColumn,
      taskDetailId: this.TaskId,
      timezoneOffset: new Date().getTimezoneOffset()
    }
    //console.log(json);
    this.get({ path: 'api/TaskComments', queryParams: { query: encodeURIComponent(JSON.stringify(json)) } }).subscribe((t: any) => {
      this.spin = false;
      this.TaskGriddata = t;
      if (this.TaskGriddata.length > 0) {
        this.isShowGrid = true;
        this.totalRecords = this.TaskGriddata[0].totalCount;
        this.isNoRecordFound = false;
      } else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
      }
      if (!this.isFilter) {

        this.TaskGrid = new AppGrid(this.TaskGriddata, Taskcomment, {
          actions: {onMoreClick: this.onTaskCommentMoreClick.bind(this)}
        })
      }
      else {

        this.TaskGrid.storeProcedure.length = this.totalRecords
        this.TaskGrid.updateSource([]);
        this.TaskGrid.updateSource(this.TaskGriddata)
      }
      this.TaskGrid.storeProcedure = {
        length: this.totalRecords,
        onPageChanging: this.onPageChanging.bind(this),
        nextPage: 1,
        onPageSorting: this.onPageSorting.bind(this),
      };
      this.TaskGrid.componentId = this.componentName;
      if(this.Isdashboard ){
        this.TaskGrid.hideFooter=true;
      }
    })
    
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.sortOrder = y;
    this.orderByColumn = x;
    this.TaskGrid.storeProcedure.nextPage = z;
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.pageIndex = x;
    this.rowCount = this.TaskGrid.maxPerPage;
    this.TaskGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }

  onTaskCommentMoreClick(t, x) {
    var element = document.getElementById("task_comment_" + t.taskCommentsId);
    var maskedElement = document.getElementById("maskedtask_comment_" + t.taskCommentsId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");

    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  save() {
    this.TaskCommentFormGroup.submitted = true;
    if(this.TaskCommentFormGroup.valid){
      this.post({ path: 'api/TaskComments', body: this.TaskCommentFormGroup.value }).subscribe(data => {
        this.sortOrder = "false";
        this.orderByColumn = "createdOn";
        if(this.TaskGrid.source.length >0){
          this.isFilter=true;
          this.TaskGrid.updateSource([]);
          this.bindGrid();
        }
        else{
          this.isFilter = false;
          this.bindGrid()
        }
        this.TaskCommentFormGroup.reset();
        this.TaskCommentFormGroup.submitted = false;
        var existsAlert = getLocalizedValue("Data_Added");
        if (existsAlert) {
          this.toastr.success(existsAlert);
        }
      })
    }
    else{
      var existsAlert = getLocalizedValue("Enter_Valid_Details");
      if (existsAlert) {
        this.toastr.warning(existsAlert);
      }
    }

  }
  get componentName(): string {
    return "TaskReplyComponent";
  }
}



