import {vPeopleTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPeopleTypeLookUp extends vPeopleTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}