import {GemstoneOrderBase,SupplierBase,UserBase,GemstoneOrderGemstoneMapingBase,} from '@app/database-models'
//Generated Imports
export class GemstoneOrder extends GemstoneOrderBase 
{




//#region Generated Reference Properties
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region gemstoneOrderGemstoneMapings Prop
gemstoneOrderGemstoneMapings : GemstoneOrderGemstoneMapingBase[];
//#endregion gemstoneOrderGemstoneMapings Prop

//#endregion Generated Reference Properties











}