import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vINCCategoryLookUpBase {

//#region iNCCategoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'iNCCategoryId', keyColumn: true})
        incCategoryId : number;
//#endregion iNCCategoryId Prop


//#region iNCCategoryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'iNCCategoryName', keyColumn: false})
        incCategoryName : string;
//#endregion iNCCategoryName Prop

}