import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTripTypeBase {

//#region tripTypeId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'TripTypeListComponent_Id_gh', keyColumn: true})
        tripTypeId : number;
//#endregion tripTypeId Prop


//#region tripTypeName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'TripTypeListComponent_Name_gh', keyColumn: false})
        tripTypeName : string;
//#endregion tripTypeName Prop

}