import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ApplicationObjectTypeBase {

//#region applicationObjectTypeId Prop
        @prop()
        applicationObjectTypeId : number;
//#endregion applicationObjectTypeId Prop


//#region applicationObjectTypeName Prop
        @required()
        @maxLength({value:100})
        applicationObjectTypeName : string;
//#endregion applicationObjectTypeName Prop


//#region isActive Prop
        @prop({defaultValue:false})
        isActive : boolean;
//#endregion isActive Prop

}