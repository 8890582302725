import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LookupSituationTypeBase {

//#region situationTypeId Prop
        @prop()
        situationTypeId : any;
//#endregion situationTypeId Prop


//#region situationTypeName Prop
        @maxLength({value:500})
        situationTypeName : string;
//#endregion situationTypeName Prop


//#region active Prop
        @required()
        active : boolean;
//#endregion active Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop



}