import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBookingDataLookUpBase {

//#region bookingId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'bookingId', keyColumn: true})
        bookingId : number;
//#endregion bookingId Prop


//#region bookingData Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'bookingData', keyColumn: false})
        bookingData : string;
//#endregion bookingData Prop

}