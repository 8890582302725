import {vMeasureDimensionRecordBase,} from '@app/database-models'
//Generated Imports
export class vMeasureDimensionRecord extends vMeasureDimensionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}