import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMessageTemplateBase {

        //#region messageTemplateID Prop
        @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'MessageTemplateListComponent_TemplateId_gh', keyColumn: true })
        messageTemplateID: number;
        //#endregion messageTemplateID Prop

        //#region messageTemplateName Prop
        @gridColumn({ isAscending: true, style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MessageTemplateListComponent_Description_gh', keyColumn: false })
        description: string;
        //#endregion messageTemplateName Prop

        //#region messageTemplateName Prop
        @gridColumn({ isAscending: true, style: { width: "30%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MessageTemplateListComponent_TemplateName_gh', keyColumn: false })
        messageTemplateName: string;
        //#endregion messageTemplateName Prop

        //#region subject Prop
        @gridColumn({ isAscending: true, style: { width: "45%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MessageTemplateListComponent_Subject_gh', keyColumn: false })
        subject: string;
        //#endregion subject Prop

        //#region isActiveStatus Prop
        @gridColumn({
                isAscending: true,configuredTemplate: { templateName: "recordActive" },style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey: 'MessageTemplateListComponent_ActiveStatus_gh', keyColumn: false
        })
        isActive: any;
        //#endregion isActiveStatus Prop


}
