<div *ngIf="showComponent" class="modal fade show" id="address_edit" tabindex="-1" role="dialog"
  (scroll)="hideTinyMCEMenu()" style="padding-right: 17px; display: block;">
  <div class="modal-dialog modal-xl" [rxSpinner]="spin" *rxMultilingual=""  style="margin-bottom: 90px!important;">
    <div class="modal-content" [rxLocalisationInit]="componentName">
      <div class="modal-header text-left m-0">
        <h3 class="modal-title" rxText="NoteAddComponent_Add_New_Notes_t"></h3>
        <ng-container *ngIf="!isDataUpdated" [rxLocalisationInit]="componentName">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" style="margin-top: 1px;"  rxText="Saved_As_Draft_t"></label>
        </ng-container>
        <button type="button" class="close py-2" (click)="hidePopup()" data-dismiss="modal" [title]="titleClose">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="modal-body pb-0" [formGroup]="noteFormGroup">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row" *ngIf="checkAllUser">
              <div class="col-md-2">
                <div class="i-checks">
                  <label class="mt-1">
                    <app-checkbox [tabIndex]="'1'" [checkFormControl]="noteFormGroup.controls.isAllUser"></app-checkbox>
                  </label>
                </div>
              </div>
              <label class="col-md-10 col-form-label" rxText="NoteAddEditComponent_AllUsers_t"></label>
            </div>
            <div class="form-group row" *ngIf="isFromSalesOrder">
              <div class="col-md-2">
                <div class="i-checks">
                  <label class="mt-1">
                    <app-checkbox [tabIndex]="'6'" [checkFormControl]="noteFormGroup.controls.isViewByPeople">
                    </app-checkbox>
                  </label>
                </div>
              </div>
              <label class="col-md-10 col-form-label" rxText="NoteAddEditComponent_ViewbyPeople_t"></label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_From_t"></label>
              <div class="col-md-10">
                <input disabled tabindex="4" type="text" formControlName="from" class="form-control "
                  rxPlaceholder="">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_To_t"></label>
              <div class="col-md-10 scrollable-tag" [rxTagExtended]="_this" *ngIf="showToEmails">
                <rx-tag [controlTabIndex]="2" [source]="bindTo()" #rxTag formControlName="toEmails"
                  mainClass="form-control" [keyValueProps]="['userName','userId']"
                  placeholder="Type the persons name that you wish to send this note to" [isSort]="false"></rx-tag>
                <small class="form-text text-danger"
                  [class.d-block]="noteFormGroup.controls.toEmails.errors">{{noteFormGroup.controls.toEmails.errorMessage}}<br /></small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_CC_t"></label>
              <div class="col-md-10 scrollable-tag" [rxTagExtended]="_this">
                <rx-tag [controlTabIndex]="2" [source]="bindTo()" #rxTag formControlName="ccEmails"
                  mainClass="form-control" [keyValueProps]="['userName','userId']"
                  placeholder="Type the persons name that you wish to send this note as cc" [isSort]="false"></rx-tag>
                <small class="form-text text-danger"
                  [class.d-block]="noteFormGroup.controls.toEmails.errors">{{noteFormGroup.controls.toEmails.errorMessage}}<br /></small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label"></label>
              <div class="col-md-10">
                <ng-container *ngIf="!isfabergeUsersSelected" [rxLocalisationInit]="componentName">
                  <button (click)="selectFabergeUKUsers()" class="btn btn-success mr-1" rxText="Btn_Faberge_Users_Select_All_t"></button>
                </ng-container>
                <ng-container *ngIf="isfabergeUsersSelected" [rxLocalisationInit]="componentName">
                  <button (click)="removeFabergeUKUsers()" class="btn btn-danger mr-1" rxText="Btn_Faberge_Users_Remove_All_t"></button>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-md-2">
                <div class="i-checks">
                  <label class="mt-1">
                    <app-checkbox [tabIndex]="'8'" [checkFormControl]="noteFormGroup.controls.isDisplayTeamMember"
                      (change)="changeRestrictionType($event)">
                    </app-checkbox>
                  </label>
                </div>
              </div>
              <label class="col-md-10 col-form-label" rxText="NoteAddEditComponent_RestricttoUsers_t"></label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_Hashtags_t"></label>
              <div class="col-md-10" [rxTagExtended]="_this">
                <rx-tag [controlTabIndex]="3" [(source)]="notesLookups.hasTags" #rxTag formControlName="hasTags"
                  mainClass="form-control" placeholder="Select Hashtag" [keyValueProps]="['noteTypeName','noteTypeId']"
                  [isSort]="false" [tagDisabled]="isForGINA"></rx-tag>
                <small class="form-text text-danger"
                  [class.d-block]="noteFormGroup.controls.hasTags.errors">{{noteFormGroup.controls.hasTags.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <fieldset [disabled]="isForGINA">
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_Subject_t"></label>
                <div class="col-md-10">
                  <input tabindex="4" type="text" formControlName="subject" class="form-control "
                    rxPlaceholder="NoteAddEditComponent_Subject_p">
                  <small class="form-text text-danger"
                    [class.d-block]="noteFormGroup.controls.subject.errors">{{noteFormGroup.controls.subject.errorMessage}}</small>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_NewNote_t"></label>
              <div class="col-md-10">
                <!--<textarea style="height:315px" formControlName="noteDescription" class="form-control "></textarea>-->
                <editor *ngIf="showTinyMCEEditor" formControlName="noteDescription" tabindex="5" [init]="{
                  height: 300,
                  menubar: true,
                  automatic_uploads: false,
                  convert_urls: false,
                  relative_urls: false,
                  remove_script_host: false,
                  spellchecker_languages: 'UK English=en_GB',
                  plugins:'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed tinymcespellchecker',
                  toolbar:'undo redo | blocks | bold italic backcolor | spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help ',
                    menu: {
                      format: {
                        title: 'Format',
                        items: 'configurepermanentpen | bold italic underline strikethrough ' +
                          'superscript subscript codeformat | styles blocks fontfamily ' +
                          'fontsize align lineheight | forecolor backcolor | removeformat'
                      }
                    },
                  statusbar: true,
                  content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                }" apiKey="{{ apiKey }}">
                </editor>
                <small class="form-text text-danger"
                  [class.d-block]="noteFormGroup.controls.noteDescription.errors">{{noteFormGroup.controls.noteDescription.errorMessage}}</small>

              </div>
            </div>
            <!-- Hidden as per client discussion -->
            <div class="col-12" style="display :none !important; visibility: hidden !important;">
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 mt-3 col-form-label" rxText="NoteAddComponent_EditorDocument_t">
                </label>
                <div class="col-md-10">
                  <app-document-list [entityTypeId]="entityTypeIdForEditorDocuments" [isPicture]="false"
                    [isEditorDocument]="true"></app-document-list>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="isFromSalesOrder" class="col-lg-6 col-xl-6 pr-xl-6">
              <div class="form-group row">
                <label class="col-md-6 col-form-label" rxText="NoteAddEditComponent_ViewbyPeople_t"
                  ></label>
                <div class="col-md-6">
                  <div class="i-checks">
                    <label class="mt-1">
                      <app-checkbox [tabIndex]="'6'" [checkFormControl]="noteFormGroup.controls.isViewByPeople">
                      </app-checkbox>
                    </label>
                  </div>
                </div>
              </div>
            </div> -->


            <!-- <div *ngIf="checkAllUser" class="col-lg-6 col-xl-6 pr-xl-6">
            <div class="form-group row">
              <label class="col-md-6 col-form-label" rxText="NoteAddEditComponent_AllUsers_t" ></label>
              <div class="col-md-6">
                <div class="i-checks">
                  <label class="mt-1">

                    <app-checkbox [tabIndex]="'8'" [checkFormControl]="noteFormGroup.controls.isAllUser"></app-checkbox>
                  </label>
                </div>
              </div>
            </div>
          </div> -->




          </div>
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div class="form-group row">
              <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_UploadFile_t"></label>
              <div class="col-md-10">
                <app-note-document [isEditorDocument]="true" [entityId]="note.entityId" [isNoteComponent]="true"
                  [entityTypeId]="note.entityTypeId" [isPicture]="false" [selectedTab]="'Notes'"></app-note-document>
              </div>
            </div>
          </div>
        </div>

        <div class="hr-line-dashed border-top w-100"></div>
        <div *ngIf="isShowTask" [rxLocalisationInit]="componentName">
          <h3 rxText="NoteAddEditComponent_Task_t"></h3>
          <div class="hr-line-dashed border-top w-100"></div>

          <div class="mb-3 table-container">
            <table class="table table-responsive table-bordered chosen-drop-static mb-0" style="min-width: 1000px!important;">
              <thead>
                <tr>
                  <th width="15%"><span rxText="TaskDetail_TaskDescription_t"></span></th>
                  <th width="15%"><span rxText="TaskDetail_AssignedBy_t"></span></th>
                  <th width="15%"><span rxText="TaskDetail_AssignedTo_t"></span></th>
                  <th width="15%"><span rxText="TaskDetail_CompletionDate_t"></span></th>
                  <th width="5%"><span rxText="">Action</span></th>
                </tr>
              </thead>
              <tbody>
                <tr [formGroup] = 'taskDetailFormGroup' *ngFor="let taskDetailFormGroup of taskDetailFormArray; let i = index" [rxLocalisationInit]="componentName">
                  <td>
                    <textarea class="form-control" rows="5" formControlName="taskDescription"
                      placeholder="Enter Task Description"></textarea>
                    <small class="form-text text-danger"
                      [class.d-block]="taskDetailFormGroup.controls.taskDescription.errors">{{taskDetailFormGroup.controls.taskDescription.errorMessage}}</small>
                  </td>
                  <td [rxSelectExtended]="_this">
                    <rx-select mainClass="form-control" [autoComplete]="true" [queryParams]="{'lookupType':'adminUsers'}"
                      path="api/SearchEvent/GetLookups" #rxSelect [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                      [controlTabIndex]="13" formControlName="assignedByUserId" [selectPlaceholder]="'Select Assigned By'"
                      [keyValueProps]="['#name#','userId']" [text]="taskDetailFormGroup.value.assignedByUserName">
                    </rx-select>
                    <small class="form-text text-danger"
                        [class.d-block]="taskDetailFormGroup.controls.assignedByUserId.errors">{{taskDetailFormGroup.controls.assignedByUserId.errorMessage}}</small>
                  </td>
                  <td [rxSelectExtended]="_this">
                    <rx-select mainClass="form-control" [autoComplete]="true" [queryParams]="{'lookupType':'adminUsers'}"
                      path="api/SearchEvent/GetLookups" #rxSelect [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                      [controlTabIndex]="13" formControlName="assignedToUserId" [selectPlaceholder]="'Select Assignee'"
                      [keyValueProps]="['#name#','userId']" [text]="taskDetailFormGroup.value.assignedToUserName">
                    </rx-select>
                    <small class="form-text text-danger"
                        [class.d-block]="taskDetailFormGroup.controls.assignedToUserId.errors">{{taskDetailFormGroup.controls.assignedToUserId.errorMessage}}</small>
                  </td>
                  <td>
                    <rx-date showAddon="true" formControlName="completionDate" pickerClass="form-control">
                    </rx-date>
                    <small class="form-text text-danger"
                      [class.d-block]="taskDetailFormGroup.controls.completionDate.errors">{{taskDetailFormGroup.controls.completionDate.errorMessage}}</small>
                  </td>
                  <td><a href="javascript:void(0);" (click)="remove(i, taskDetailFormGroup)"><i
                        class="fr-delete fa-4x font-md text-danger"></i></a></td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
        <div>
          <button (click)="addNewTask()" class="btn btn-primary mb-3" rxText="Btn_Add_New_Task_t"></button>
        </div>
      </div>
      <div class="modal-footer" [rxLocalisationInit]="componentName">

          <ng-container *ngIf="isDataUpdated" [rxLocalisationInit]="componentName"><button type="button" (click)="saveAsDraft()" class="btn btn-success"
            rxText="Btn_Save_Draft_t"></button></ng-container>

          <ng-container *ngIf="!isDataUpdated" [rxLocalisationInit]="componentName"><button type="button" (click)="saveAsDraft()" class="btn btn-success"
            ><span rxText="Btn_Saved_Draft_t"></span><i class="fa fa-check-circle ml-1" aria-hidden="true"></i></button></ng-container>

        <button type="button" (click)="addNotes()" class="btn btn-success" [disabled]="submitted"
          rxText="Btn_Send_Note_t"></button>
        <button type="button" (click)="hidePopup()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
      </div>
    </div>
  </div>
</div>
