import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vNoteTypeRecordBase {

//#region typeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'typeId', keyColumn: true})
        typeId : number;
//#endregion typeId Prop


//#region typeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'typeName', keyColumn: false})
        typeName : string;
//#endregion typeName Prop


//#region typeDescription Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'typeDescription', keyColumn: false})
        typeDescription : string;
//#endregion typeDescription Prop


//#region isManual Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'isManual', keyColumn: false})
        isManual : boolean;
//#endregion isManual Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : any;
//#endregion statusId Prop

}