import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, date } from '@rxweb/reactive-form-validators';

import { CoreComponent } from '@rxweb/angular-router';
import { List } from '@rxweb/generics';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';

import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { SERVER_URL, CLIENT_URL } from "src/app/domain/system-constant";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: 'app-print-certificate',
  templateUrl: './print-certificate.component.html',
  // styleUrls: ['./print-certificate.component.css']
})
export class PrintCertificateComponent extends CoreComponent implements OnInit, OnDestroy {

  // @Input() pieceNumber: string;
  // @Input() productName: string;
  // @Input() productSpecification: string;
  // @Input() productDescription: string;
  // @Input() imageUrl: string;

  @Input() printList: any[];

  subscription: any;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  modalView: ModalView;
  spin: boolean = false;
  showComponent: boolean = false;
  @Input() hide: Function;
  pieceSerialNo: string;
  pieceName: string;
  pieceSpecification: string;
  pieceDescription: string;
  pieceImageUrl: string;
  piecePrintCertificateDetailData:PiecePrintCertificateDetailData[];
  fabHallmarkUrl:string;
  newLogoUrl:string;
  certificateCss: string;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, router: Router, modelView: ModalView,private location : PlatformLocation) {
    super();
    this.router = router;
    this.modalView = modelView;
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.piecePrintCertificateDetailData = new Array<PiecePrintCertificateDetailData>();
    this.piecePrintCertificateDetailData=this.printList;
    console.log("print list", this.piecePrintCertificateDetailData);
    this.fabHallmarkUrl = CLIENT_URL + "/assets/images/Faberge_Hallmark_GLD.png";
    this.newLogoUrl = CLIENT_URL + "/assets/images/new-logo.png";
    this.certificateCss = CLIENT_URL + "/assets/css/print-certificate.css";
    setTimeout(() => {
      if (this.piecePrintCertificateDetailData != null) {
         this.printCertificate();
      }
    }, 200)
  }

  printCertificate(): void {

    let printContents, popupWin;

    printContents = document.getElementById('print-certificate').innerHTML;
    this.hide();
    let params = 'width=' + screen.width;
    params += ', height=' + screen.height;
    params += ', top=0, left=0'
    params += ', fullscreen=yes';

    popupWin = window.open('', '_blank', params);
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head><link rel="stylesheet" type="text/css" href="${this.certificateCss}">
          <title></title>
        </head>
        <body >${printContents}</body>
      </html>`
    );
     setTimeout(() => {
            popupWin.focus();
            popupWin.print();
        }, 1000);
    // popupWin.document.close();

  }
  hidePopup() {
    this.hide()
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
class PiecePrintCertificateDetailData {
  PieceImageUrl: string;
  PieceSerialNo: string;
  PieceName: string;
  PieceSpecification: string;
  PieceDescription: string;
}
