<div class="wrapper wrapper-content" *rxMultilingual="" [rxSpinner]="spin">
    <div class="ibox mb-0 d-flex flex-column">
        <div class="content d-flex flex-column" role="main" id="mind-map-area" style="background-color:#f1f6f7;"
            [class.pt-3]="!mindMapVisible">
            <div class="align-self-end">
                <ng-contrainer *ngIf="mindMapVisible">
                    <button (click)="zoomInOut(1)" class="btn btn-white mr-2" [title]="titleZoomIn"
                        [disabled]="mindMapServiceLocal.zoomPercentage == 120">
                        <i class="h3 fa fa-plus m-0"></i>
                    </button>
                    <label><b>Zoom: {{mindMapServiceLocal.zoomPercentage}}%</b></label>
                    <button (click)="zoomInOut(0)" class="btn btn-white m-2" [title]="titleZoomOut"
                        [disabled]="mindMapServiceLocal.zoomPercentage == 50">
                        <i class="h3 fa fa-minus m-0"></i>
                    </button>
                    <button (click)="zoomInOut(100)" class="btn btn-white mr-2 py-2" [title]="titleResetZoom"
                        [disabled]="mindMapServiceLocal.zoomPercentage == 100">
                        <b>Reset Zoom</b>
                    </button>
                    <button (click)="rootInCenter()" class="btn btn-white mr-2 py-2 my-2" [title]="titleBackToRoot">
                        <i class="fa fa-bullseye h3 m-0"></i>
                    </button>
                    <button (click)="showFullscreenMap()" class="btn btn-white my-2" [title]="titleFullScreen">
                        <i *ngIf="!isFullScreen" class="h3 fa fa-expand m-0"></i>
                        <i *ngIf="isFullScreen" class="h3 fa fa-compress m-0"></i>
                    </button>
                </ng-contrainer>
            </div>
            <div id="jsmind_container" style="background-color:white;"></div>
        </div>
    </div>
</div>
