import { propArray } from '@rxweb/reactive-form-validators';
import { DocumentTypeUserMappingBase } from '../database-models/document-type-user-mapping-base';

export class DocumentTypeUserMapping extends DocumentTypeUserMappingBase {

    @propArray(DocumentTypeUserMappingBase)
    documentTypeUserMappingBase: DocumentTypeUserMappingBase[];

    headerBtnCheck:boolean;
    query:string;
    isSupplier:boolean;
}