import {vUserKnownToBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 12, headerTitle: "Action"
})

export class vUserKnownTo extends vUserKnownToBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}