import {vManageInventoryMethodLookUpBase,} from '@app/database-models'
//Generated Imports
export class vManageInventoryMethodLookUp extends vManageInventoryMethodLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}