import {ProductBatchPieceRepairNotesMappingBase,} from '@app/database-models'
//Generated Imports
export class ProductBatchPieceRepairNotesMapping extends ProductBatchPieceRepairNotesMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}