//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';
import { UserGridCompanyMappingViewModel } from 'src/app/view-model/user-grid-company-mapping-view-model';
@actionColumn({
  visible:false,
  allowSorting: false,
  style: { width: "2%" },
  configuredTemplate: { templateName: 'action' }, columnIndex: 19, headerTitle: "Action"
})
export class UsersSearchViewModel {

  @gridColumn({
    template:{
        div:{
          attributes:{
            innerHTML:function(x,y){
             return this.company;
            }
          },
        }
    }, parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 3, allowSorting: false, headerKey: 'UserListComponent_CorporatesOrGroups_gh', keyColumn: false
  })

   company:string

   @gridColumn({
    template:{
        div:{
          attributes:{
            innerHTML:function(x,y){
             return this.directorship;
            }
          },
        }
    }, parameter: false, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey:'UserListComponent_Directorships_gh', keyColumn: false
  })

   directorship:string

  //#region ApplicationExceptionLogId
  @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 0, allowSorting: true, headerKey: 'userId', keyColumn: true })
  userId: number;
  //#endregion ApplicationExceptionLogId

  //#region titleName
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'UserListComponent_titleName_gh', keyColumn: false })
  title: string;
  //#endregion titleName

  //#region RowNumber
  @gridColumn({ parameter: true, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, configuredTemplate: { templateName:"reDirectPeopleCorporateAndTooltip" }, columnIndex: 1, allowSorting: true, headerKey: 'UserListComponent_Name_gh', keyColumn: false })
  name: string;
  //#endregion RowNumber

  //#region ExceptionTypeId
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'UserListComponent_Email_gh', keyColumn: false })
  email: string;
  //#endregion ExceptionTypeId

  //#region corporateOrGroup
  //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 3, allowSorting: true, headerKey: 'corporateOrGroup', keyColumn: false })
  //corporateOrGroup: string;
  //#endregion corporateOrGroup


  //#region isActiveFormer
  //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 4, allowSorting: true, headerKey: 'isActiveFormer', keyColumn: false })
  //isActiveFormer: string;
  //#endregion isActiveFormer

  //#region jobTitle
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey:'UserListComponent_Department_gh', keyColumn: false })
  departmentName: string;
  //#endregion jobTitle


  //#region jobTitle
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey:'UserListComponent_JobTitle_gh', keyColumn: false })
  jobTitle: string;
  //#endregion jobTitle
  //#region profile
  @gridColumn({parameter: false,
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.profile;
          }
        },
        childrens: [{
          a: {
            isBind: (x) => {
              if (x.profileCharCount > 100) {
                return true;
              }
              else {
                return false;
              }
            }, style: {
              "display": "block"
            },
            childrens: [{
              span: {
                class: "more-btn mr-2".split(" "),
                childrens: [{
                  text: {
                    text: function (e) {
                      return "Show More";
                    }
                  }
                }]
              }
            }],
            event: {
              click: "onMoreClickProfile"
            }
          },
        }]
      }
    }, style: { width: "15%", "min-width": "250px", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex: 7, headerKey: 'UserListComponent_Profile_gh', keyColumn: false, allowSorting: true
  })
  profile: string;

  @gridColumn({
    template: {
      div: {
        style: {
          "word-break": "break-all;",
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.profile1;
          }
        },

      }
    }, style: { width: "15%", "min-width": "300px", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 12, headerKey: 'UserListComponent_Profile_gh', keyColumn: false, allowSorting: false
  })
  profile1: any;

  profileCharCount: number;
  //#end region profile
  //#region salesAdversierName
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, headerKey: 'UserListComponent_SalesAdversierName_gh', keyColumn: false })
  salesAdversierName: string;
  //#endregion salesAdversierName

  //#region keyRelationshipHolder
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerKey: 'UserListComponent_KeyRelationshipHolder_gh', keyColumn: false })
  keyRelationshipHolder: string;
  //#endregion keyRelationshipHolder

  //#region hasPurchasedFrom
  //@gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'hasPurchasedFrom', keyColumn: false })
  //hasPurchasedFrom: string;
  //#endregion hasPurchasedFrom

  //#region isVerified
  @gridColumn({ parameter: false, configuredTemplate: { templateName: "recordStatus" },allowSorting: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10,headerKey: 'UserListComponent_IsVerified_gh', keyColumn: false })
  isVerified: number;
  //#endregion isVerified

  //#region verified
  //@gridColumn({ parameter: false,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, keyColumn: false })
  verified: number;
  //#endregion verified

  //#region registrationDate
  // @gridColumn({ style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerKey: 'RegistrationDate', keyColumn: false })
   registrationDate: Date;
  //#endregion registrationDate

  //#region stRegistrationDate
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'UserListComponent_RegistrationDate_gh', keyColumn: false })
  stRegistrationDate: Date;
  //#endregion stRegistrationDate

  //#region countryName
  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'UserListComponent_CountryName_gh', keyColumn: false })
  countryName: string;
  //#endregion countryName

  //#region city
  @gridColumn({ parameter: false,  style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 13, allowSorting: true, headerKey: 'UserListComponent_City_gh', keyColumn: false })
  city: string;
  //#endregion city

  //#region phoneNumber
  @gridColumn({ parameter: false,  style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 14, allowSorting: true, headerKey: 'UserListComponent_workPhoneNumber_gh', keyColumn: false })
  phoneNumber: string;
  //#endregion phoneNumber

  //#region totalCount
  @gridColumn({ parameter: true, name: "totalCount", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 12, allowSorting: true, headerKey: 'totalCount', keyColumn: false })
  totalCount: number;
  //#endregion totalCount

  //#region roleId
  @gridColumn({ parameter: true, name: "roleId", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 12, allowSorting: true, headerKey: 'roleId', keyColumn: false })
  roleId: number;
  //#endregion roleId

  //#region hasPurchasedForm
  @gridColumn({ parameter: true, name: "hasPurchasedForm", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 15, allowSorting: false, headerKey: 'UserListComponent_HasPurchased_gh', keyColumn: false })
  hasPurchasedFrom: string;
  //#endregion hasPurchasedForm


  //#region rating
  @gridColumn({ parameter: false, configuredTemplate: { templateName: "peopleRating" }, name: "rating", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 16, allowSorting: false, headerKey: 'UserListComponent_Rating_gh', keyColumn: false })
  rating: number;
  //#endregion rating

  //#region ratingName
  //@gridColumn({ parameter: false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 12, allowSorting: true, keyColumn: false })
  ratingName: string;
  //#endregion ratingName

  //#region dateOfBirth
  @gridColumn({ parameter: false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 17, allowSorting: false, headerKey: 'UserListComponent_DateOfBirth_gh', keyColumn: false })
  dateOfBirth: string;
  //#endregion dateOfBirth

  //#region anniversaryDate
  @gridColumn({ parameter: false,  style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 18, allowSorting: true, headerKey: 'UserListComponent_AnniversaryDate_gh', keyColumn: false })
  anniversaryDate: string;
  //#endregion anniversaryDate



  roleName:string;

  deceased: boolean;

  exportName: string;

  @gridColumn({
    visible:false,
    name:"editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 20, headerTitle: "Edit"
})
editAction:any;

@gridColumn({
    visible:false,
    name:"deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 21, headerTitle: "Delete"
})
deleteAction:any;

companyDetails: UserGridCompanyMappingViewModel[];

directorshipDetails: UserGridCompanyMappingViewModel[];

isUnwelcome: boolean;
}
