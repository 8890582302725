import { prop, propObject, propArray, required, maxLength, range, alpha, notEmpty, numeric, email, trim, alphaNumeric, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class WarehouseBase {

        //#region warehouseId Prop
        @prop()
        warehouseId: number;
        //#endregion warehouseId Prop


        //#region name Prop
        @required()
        @maxLength({ value: 255 })
        //@alpha({ allowWhiteSpace: true })
        @trim()
        @notEmpty()
        name: string;
        //#endregion name Prop


        //#region phoneNumber Prop
        //@required()
       //@maxLength({ value: 12 })
        //@numeric()
        @prop()
        phoneNumber: string;  
        //#endregion phoneNumber Prop


        //#region email Prop
      //  @required()
        @maxLength({ value: 255 })
        @email()
       // @notEmpty()
     
        @trim()
        email: string;
        //#endregion email Prop


        //#region faxNumber Prop
      //  @required()
        @maxLength({ value: 12 })
      //  @notEmpty()
   
        @numeric()
        faxNumber: string;
        //#endregion faxNumber Prop


        //#region address1 Prop
      //  @required()
        @maxLength({ value: 100 })
        @trim()
     
     //   @notEmpty()
        address1: string;
        //#endregion address1 Prop


        //#region address2 Prop
     //   @required()
        @maxLength({ value: 100 })
        @trim()

     //   @notEmpty()
        address2: string;
        //#endregion address2 Prop


        //#region city Prop
    //    @required()
        @maxLength({ value: 100 })
        //@alpha({ allowWhiteSpace: true })
        @trim()
    
     //   @notEmpty()
        city: string;
        //#endregion city Prop


        //#region stateProvince Prop
     //   @required()
        @maxLength({ value: 100 })
        //@alpha({ allowWhiteSpace: true })
        @trim()
      
     //   @notEmpty()
        stateProvince: string;
        //#endregion stateProvince Prop


        //#region zipPostalCode Prop
        @prop()
        @maxLength({ value: 30 })
        @trim()
        //@alphaNumeric({ allowWhiteSpace: true })
    
        zipPostalCode: string;
        //#endregion zipPostalCode Prop


        //#region countryId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        countryId: number;
        //#endregion countryId Prop


        //#region customerId Prop
        @prop()
        customerId: number;
        //#endregion customerId Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        //#region createdOn Prop
        @prop()
        createdOn: Date;
        //#endregion createdOn Prop

        //#region updatedOn Prop
        @prop()
        updatedOn: Date;
        //#endregion updatedOn Prop

      //#region isWatchType Prop
        @prop({defaultValue: false})
        isWatchType: boolean;
      //#endregion isWatchType Prop

      @prop()
        salesChannelId: number;

}
