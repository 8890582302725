import { prop,propObject,propArray,required,maxLength,range, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ITIncCategoryBase {

//#region incCategoryId Prop
        @prop()
        incCategoryId : number;
//#endregion incCategoryId Prop


//#region incCategoryName Prop
        @required()
        @notEmpty()
        @trim()
        @maxLength({ value: 4000 })
        incCategoryName : string;
//#endregion incCategoryName Prop


//#region incTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        incTypeId : number;
//#endregion incTypeId Prop
        @prop()
        parentIncCategoryId:number;

}