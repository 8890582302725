import {ProductDesignPictureBase,PictureBase,ProductDesignBase,} from '@app/database-models'
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop, numeric, required, range, notEmpty, propArray } from '@rxweb/reactive-form-validators';
import { PictureListViewModel } from 'src/app/view-model/picture-list-view-model';
import { PictureViewModel } from 'src/app/view-model/picture-view-model';
//Generated Imports
export class ProductDesignPicture extends ProductDesignPictureBase implements FileDetailViewModel
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop

//#endregion Generated Reference Properties
@prop()
// @required()
// @notEmpty()
// @numeric()
// @range({minimumNumber:1,maximumNumber:999})
displayOrder1:number;

@prop()
// @required()
// @notEmpty()
// @numeric()
// @range({minimumNumber:1,maximumNumber:999})
displayOrder2:number;

@prop()
// @notEmpty()
// @required()
// @numeric()
// @range({minimumNumber:1,maximumNumber:999})
displayOrder3:number;

@prop()
fileData: string;
@prop()
fileExtention: string;
@prop()
fileName: string;
@prop()
displayImage: boolean;
@prop()
imageURL1: string;
@prop()
imageURL2: string;
@prop()
imageURL3: string;
@prop()
imageURL4: string;
@prop()
imageURL5: string;
@prop()
fileData1: string;
@prop()
fileExtention1: string;
@prop()
fileName1: string;
@prop()
fileData2: string;
@prop()
fileExtention2: string;
@prop()
fileName2: string;

@propArray(PictureViewModel)
pictures:PictureViewModel[];
@prop({defaultValue:false})
isDamagedPiece:boolean;

@prop()
entityId:number;

@prop()
entityTypeId:number;

@prop()
destinationId:number;

@prop()
sourceId:number;

@prop()
displayOrder:number;

@prop({defaultValue:false})
isDrag:boolean;

@prop()
comment: string;

}
