import {LanguageBase,LocaleStringResourceBase,NewsBase,ProductLocalizedBase,ProductAttributeValueLocalizationBase,ProductVariantLocalizationBase,UserBase,MessageTemplateDetailBase,SpecificationAttributeOptionLocalizationBase,TopicLocalizationBase,} from '@app/database-models'
//Generated Imports
export class Language extends LanguageBase 
{

    constructor() {
        super();
        this.published = false;
    }


//#region Generated Reference Properties
//#region localeStringResources Prop
// localeStringResources : LocaleStringResourceBase[];
//#endregion localeStringResources Prop
//#region news Prop
// news : NewsBase[];
//#endregion news Prop
//#region productLocalized Prop
productLocalized : ProductLocalizedBase[];
//#endregion productLocalized Prop
//#region productAttributeValueLocalizations Prop
// productAttributeValueLocalizations : ProductAttributeValueLocalizationBase[];
//#endregion productAttributeValueLocalizations Prop
//#region productVariantLocalizations Prop
// productVariantLocalizations : ProductVariantLocalizationBase[];
//#endregion productVariantLocalizations Prop
//#region users Prop
users : UserBase[];
//#endregion users Prop
//#region messageTemplateDetails Prop
//messageTemplateDetails : MessageTemplateDetailBase[];
//#endregion messageTemplateDetails Prop
//#region specificationAttributeOptionLocalizations Prop
specificationAttributeOptionLocalizations : SpecificationAttributeOptionLocalizationBase[];
//#endregion specificationAttributeOptionLocalizations Prop
//#region topicLocalizations Prop
topicLocalizations : TopicLocalizationBase[];
//#endregion topicLocalizations Prop

//#endregion Generated Reference Properties












}