import {vGemfieldsLocationRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemfieldsLocationRecord extends vGemfieldsLocationRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}