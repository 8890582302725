import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vAddressBase {

        //#region addressId Prop
        @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'addressId', keyColumn: true })
        addressId: number;
        //#endregion addressId Prop


        //#region userId Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'userId', keyColumn: false })
        userId: any;
        //#endregion userId Prop


        //#region supplierId Prop
        @gridColumn({ visible: true, columnIndex: 2, allowSorting: true, headerKey: 'supplierId', keyColumn: false })
        supplierId: any;
        //#endregion supplierId Prop


        //#region isBillingAddress Prop
        @gridColumn({ visible: true, columnIndex: 3, allowSorting: true, headerKey: 'isBillingAddress', keyColumn: false })
        isBillingAddress: boolean;
        //#endregion isBillingAddress Prop


        //#region firstName Prop
        @gridColumn({ visible: true, columnIndex: 4, allowSorting: true, headerKey: 'firstName', keyColumn: false })
        firstName: string;
        //#endregion firstName Prop


        //#region lastName Prop
        @gridColumn({ visible: true, columnIndex: 5, allowSorting: true, headerKey: 'lastName', keyColumn: false })
        lastName: string;
        //#endregion lastName Prop


        //#region phoneNumber Prop
        @gridColumn({ visible: true, columnIndex: 6, allowSorting: true, headerKey: 'phoneNumber', keyColumn: false })
        phoneNumber: string;
        //#endregion phoneNumber Prop


        //#region email Prop
        @gridColumn({ visible: true, columnIndex: 7, allowSorting: true, headerKey: 'email', keyColumn: false })
        email: string;
        //#endregion email Prop


        //#region faxNumber Prop
        @gridColumn({ visible: true, columnIndex: 8, allowSorting: true, headerKey: 'faxNumber', keyColumn: false })
        faxNumber: string;
        //#endregion faxNumber Prop


        //#region company Prop
        @gridColumn({ visible: true, columnIndex: 9, allowSorting: true, headerKey: 'company', keyColumn: false })
        company: string;
        //#endregion company Prop


        //#region address1 Prop
        @gridColumn({ visible: true, columnIndex: 10, allowSorting: true, headerKey: 'address1', keyColumn: false })
        address1: string;
        //#endregion address1 Prop


        //#region address2 Prop
        @gridColumn({ visible: true, columnIndex: 11, allowSorting: true, headerKey: 'address2', keyColumn: false })
        address2: string;
        //#endregion address2 Prop


        //#region city Prop
        @gridColumn({ visible: true, columnIndex: 12, allowSorting: true, headerKey: 'city', keyColumn: false })
        city: string;
        //#endregion city Prop


        //#region stateProvinceID Prop
        @gridColumn({ visible: true, columnIndex: 13, allowSorting: true, headerKey: 'stateProvinceID', keyColumn: false })
        stateProvinceID: number;
        //#endregion stateProvinceID Prop


        //#region zipPostalCode Prop
        @gridColumn({ visible: true, columnIndex: 14, allowSorting: true, headerKey: 'zipPostalCode', keyColumn: false })
        zipPostalCode: string;
        //#endregion zipPostalCode Prop


        //#region countryID Prop
        @gridColumn({ visible: true, columnIndex: 15, allowSorting: true, headerKey: 'countryID', keyColumn: false })
        countryID: number;
        //#endregion countryID Prop


        //#region createdOn Prop
        @gridColumn({ visible: true, columnIndex: 16, allowSorting: true, headerKey: 'createdOn', keyColumn: false })
        createdOn: any;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @gridColumn({ visible: true, columnIndex: 17, allowSorting: true, headerKey: 'updatedOn', keyColumn: false })
        updatedOn: any;
        //#endregion updatedOn Prop


        //#region isImpersonated Prop
        @gridColumn({ visible: true, columnIndex: 18, allowSorting: true, headerKey: 'isImpersonated', keyColumn: false })
        isImpersonated: any;
        //#endregion isImpersonated Prop


        //#region stateProvinceName Prop
        @gridColumn({ visible: true, columnIndex: 19, allowSorting: true, headerKey: 'stateProvinceName', keyColumn: false })
        stateProvinceName: string;
        //#endregion stateProvinceName Prop


        //#region stateID Prop
        @gridColumn({ visible: true, columnIndex: 20, allowSorting: true, headerKey: 'stateID', keyColumn: false })
        stateID: any;
        //#endregion stateID Prop


        //#region countryName Prop
        @gridColumn({ visible: true, columnIndex: 21, allowSorting: true, headerKey: 'countryName', keyColumn: false })
        countryName: string;
        //#endregion countryName Prop

        //#region stateName Prop
        @gridColumn({ visible: true, columnIndex: 22, allowSorting: true, headerKey: 'stateName', keyColumn: false })
        stateName: string;
        //#endregion stateName Prop

}