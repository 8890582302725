import { prop,required, maxLength, range, trim, numeric, minLength, pattern, notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectBase {

        //#region projectID Prop
        @prop({defaultValue: 0})
        projectID: number;
        //#endregion projectID Prop


        //#region projectGUID Prop
        @required()
        @prop({ defaultValue: "13A1994E-6800-4C6F-A096-FC25742B7FD3" })
        projectGUID: any;
        //#endregion projectGUID Prop


        //#region projectName Prop
        @required()
        @maxLength({ value: 255 })
        @trim()
        @notEmpty()
        projectName: string;
        //#endregion projectName Prop


        //#region projectDescription Prop
        @required()
        @trim()
        @notEmpty()
        projectDescription: string;
        //#endregion projectDescription Prop


        //#region projectType Prop
        @prop({defaultValue:176})
        @required()
        // @prop({defaultValue:0})
        projectType: number;
        //#endregion projectType Prop

        //#region GemfieldsGroupCompanyProjectOwner Prop
        @prop()
        @required()
        @notEmpty()
        gemfieldsGroupCompanyProjectOwner: number;
        //#endregion GemfieldsGroupCompanyProjectOwner Prop

         //#region Department Prop
        //@prop({defaultValue:175})
        @required()
        @notEmpty()
        department: number;
        //#endregion Department Prop


        //#region projectStartDate Prop
        @required()
        projectStartDate: Date;
        //#endregion projectStartDate Prop


        //#region projectEndDate Prop
        @prop()
        projectEndDate: Date;
        //#endregion projectEndDate Prop


        //#region budget Prop  
        @prop({defaultValue:1})              
        @required()       
        @numeric({allowDecimal:true})        
        @pattern({expression:{"budget":/^[0-9]\d{0,12}(\.\d{1,2})?$/},message:"Please enter valid Budget (13 digits and 2 decimal places are allowed)"})
       // @range({minimumNumber: 0, maximumNumber: 2147483647})    
        budget: number;
        //#endregion budget Prop


        //#region actualCost Prop
        @prop({defaultValue:0.00})        
        @numeric({allowDecimal:true})       
        @pattern({expression:{"actualCost":/^[0-9]\d{0,12}(\.\d{1,2})?$/},message:"Please enter valid Actual Cost (13 digits and 2 decimal places are allowed)"}) 
        //@range({minimumNumber: 0, maximumNumber: 2147483647})       
        actualCost: number;
        //#endregion actualCost Prop


        //#region projectOwner Prop
        @required()
        @prop()
        projectOwner: number;
        //#endregion projectOwner Prop


        //#region budgetCostCertificate Prop
        @maxLength({ value: 255 })
        budgetCostCertificate: string;
        //#endregion budgetCostCertificate Prop


        //#region projectIndicator Prop
        @numeric()
        //@range({minimumNumber: 0, maximumNumber: 100})
        @prop({defaultValue: 0})
        projectIndicator: number;
        //#endregion projectIndicator Prop


        //#region createdOn Prop
        @required()
        @prop({defaultValue: new Date()})
        createdOn: Date;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @prop({defaultValue: new Date()})
        updatedOn: Date;
        //#endregion updatedOn Prop


        //#region deleted Prop
        @prop({defaultValue: false})
        deleted: boolean;
        //#endregion deleted Prop


        //#region parentID Prop
        @required()
        @prop({defaultValue: 0})
        parentID: number;
        //#endregion parentID Prop


        //#region linkID Prop
        @required()
        @prop({defaultValue: 0})
        linkID: number;
        //#endregion linkID Prop


        //#region subStreamType Prop
        @required()
        @maxLength({ value: 3 })
        @prop({defaultValue: "PRJ"})
        subStreamType: string;
        //#endregion subStreamType Prop


        //#region pictureID Prop
        @required()
        @prop({defaultValue: 0})
        pictureID: number;
        //#endregion pictureID Prop


        //#region isRestrictedProject Prop
        @prop({defaultValue: false})
        isRestrictedProject: boolean;
        //#endregion isRestrictedProject Prop







}
