import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSalesChannelRecordBase {

//#region salesChannelId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'salesChannelId', keyColumn: true})
        salesChannelId : number;
//#endregion salesChannelId Prop


//#region billingCountryId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'billingCountryId', keyColumn: false})
        billingCountryId : number;
//#endregion billingCountryId Prop


//#region salesChannelAddress Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'salesChannelAddress', keyColumn: false})
        salesChannelAddress : string;
//#endregion salesChannelAddress Prop


//#region salesChannelType Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'salesChannelType', keyColumn: false})
        salesChannelType : number;
//#endregion salesChannelType Prop


//#region salesChannelName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'salesChannelName', keyColumn: false})
        salesChannelName : string;
//#endregion salesChannelName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}