import {vSalesChannelTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vSalesChannelTypeLookUp extends vSalesChannelTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}