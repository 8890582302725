import {CarnetDocumentBase,CarnetBase,} from '@app/database-models'
//Generated Imports
export class CarnetDocument extends CarnetDocumentBase 
{




//#region Generated Reference Properties
//#region carnet Prop
carnet : CarnetBase;
//#endregion carnet Prop

//#endregion Generated Reference Properties











}