import {vReturnStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vReturnStatusLookUp extends vReturnStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}