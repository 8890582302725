import { vSpecificRiskLookUpBase} from '@app/database-models'
//Generated Imports
export class vSpecificRiskLookUp extends vSpecificRiskLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}