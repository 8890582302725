import { vProductCategoryLookUpBase } from '../database-models/v-product-category-look-up-base';
//Generated Imports
export class vProductCategoryLookUp extends vProductCategoryLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}