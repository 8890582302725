import {vWarrantyCardLookUpBase,} from '@app/database-models'
//Generated Imports
export class vWarrantyCardLookUp extends vWarrantyCardLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}