import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vParentProductCategoryLookUpBase {

//#region categoryId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'categoryId', keyColumn: true})
        categoryId : number;
//#endregion categoryId Prop


//#region categoryName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'categoryName', keyColumn: false})
        categoryName : string;
//#endregion categoryName Prop


//#region categoryId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'categoryTypeId', keyColumn: false})
        categoryTypeId : number;
//#endregion categoryId Prop

}