import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RelatedProductBase {

//#region relatedProductVariantID Prop
        @prop()
        relatedProductVariantID : number;
//#endregion relatedProductVariantID Prop


//#region productVariantID1 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID1 : number;
//#endregion productVariantID1 Prop


//#region productVariantID2 Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID2 : number;
//#endregion productVariantID2 Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop



}