import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ProjectEventMapping } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ProjectEventMappingViewModel } from 'src/app/view-model/project-event-mapping-view-model';
import { AppGrid } from 'src/app/domain/app-grid';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';

@http({
    path:'api/ProjectEventMapping'
})
export class AbstractProjectEventMapping extends CoreComponent {
    spin: boolean = false;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    projectEventMappingList:ProjectEventMappingViewModel[];
    projectEventMappingGrid:AppGrid;
    projectEventMappingFormGroup: IFormGroup<ProjectEventMapping>
    isShowGrid : boolean;
    isNoRecordFound : boolean;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
}
