import {LookupSituationTypeBase,IncidentAccidentTypeBase,} from '@app/database-models'
//Generated Imports
export class LookupSituationType extends LookupSituationTypeBase 
{




//#region Generated Reference Properties
//#region incidentAccidentTypes Prop
incidentAccidentTypes : IncidentAccidentTypeBase[];
//#endregion incidentAccidentTypes Prop

//#endregion Generated Reference Properties






































}