import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vNotifyTeamLookUpBase {

//#region notifyTeamId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'notifyTeamId', keyColumn: true})
        notifyTeamId : number;
//#endregion notifyTeamId Prop


//#region notifyTeamValue Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'notifyTeamValue', keyColumn: false})
        notifyTeamValue : string;
//#endregion notifyTeamValue Prop

}