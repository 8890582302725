import {vDiscountBase,} from '@app/database-models'
import { actionColumn, gridColumn } from '@rxweb/grid';
//Generated Imports
@actionColumn({
    visible:false,
    name:"action",
    allowSorting: false,
   configuredTemplate: { templateName: 'action' }, columnIndex: 8, headerTitle: "Action"
})
export class vDiscount extends vDiscountBase 
{

    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 9, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 10, headerTitle: "Delete"
    })
    deleteAction:any;
//#region Generated Reference Properties
//#endregion Generated Reference Properties
}