import { prop,propObject,propArray,required,maxLength,range, pattern, numeric  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceGemstoneMappingBase {

//#region productVariantBatchPieceGemStoneId Prop
        @prop()
        productVariantBatchPieceGemStoneId : number;
//#endregion productVariantBatchPieceGemStoneId Prop


//#region productVariantBatchPieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region gemStoneID Prop
        @prop()
        gemStoneID : number;
//#endregion gemStoneID Prop


//#region WatchPartId Prop
        @prop()
        watchPartId : number;
//#endregion WatchPartId Prop


//#region materialID Prop
        @prop()
        materialID : number;
//#endregion materialID Prop


//#region materialName Prop
        @required()
        @maxLength({value:100})
        materialName : string;
//#endregion materialName Prop


//#region quantity Prop
        @required()
        //@numeric({allowDecimal:true})
        //@pattern({expression:{"quantity":/^[0-9]\d{0,11}(\.\d{1,2})?%?$/},message:"Please enter valid quantity(11 digits and 2 decimal places allowed)"})
        quantity : number;
//#endregion quantity Prop


//#region unitPrice Prop
        @required()
        //@numeric({allowDecimal:true})
        //@pattern({expression:{"unitPrice":/^[0-9]\d{0,7}(\.\d{1,4})?%?$/},message:"Please enter valid unitPrice(8 digits and 4 decimal places allowed)"})
        unitPrice : number;
//#endregion unitPrice Prop


//#region currencyID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        currencyID : number;
//#endregion currencyID Prop


//#region exchangeRate Prop
        @required()
        //@numeric({allowDecimal:true})
        //@pattern({expression:{"exchangeRate":/^[0-9]\d{0,8}(\.\d{1,4})?%?$/},message:"Please enter valid exchangeRate(8 digits and 4 decimal places allowed)"})
        exchangeRate : number;
//#endregion exchangeRate Prop


//#region comments Prop
        @maxLength({value:255})
        comments : string;
//#endregion comments Prop


//#region invoiceID Prop
        @prop()
        invoiceID : number;
//#endregion invoiceID Prop


//#region boostedUnitPrice Prop
        @required()
        //@numeric({allowDecimal:true})
        //@pattern({expression:{"boostedUnitPrice":/^[0-9]\d{0,7}(\.\d{1,4})?%?$/},message:"Please enter valid boostedUnitPrice(8 digits and 4 decimal places allowed)"})
        boostedUnitPrice : number;
//#endregion boostedUnitPrice Prop


//#region supplierID Prop
        @prop()
        supplierID : number;
//#endregion supplierID Prop


//#region dateCreated Prop
        @prop()
        dateCreated : Date;
//#endregion dateCreated Prop


//#region dateupdated Prop
        @prop()
        dateupdated : Date;
//#endregion dateupdated Prop


//#region chainPieceID Prop
        @prop()
        chainPieceID : number;
//#endregion chainPieceID Prop

        @prop()
        invoiceNumber: string;


}