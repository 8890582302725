import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EggHuntBase {

//#region eggHuntId Prop
        @prop()
        eggHuntId : number;
//#endregion eggHuntId Prop


//#region firstName Prop
        @required()
        @maxLength({value:50})
        firstName : string;
//#endregion firstName Prop


//#region lastName Prop
        @required()
        @maxLength({value:50})
        lastName : string;
//#endregion lastName Prop


//#region email Prop
        @required()
        @maxLength({value:100})
        email : string;
//#endregion email Prop


//#region telephone Prop
        @required()
        @maxLength({value:50})
        telephone : string;
//#endregion telephone Prop


//#region country Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        country : number;
//#endregion country Prop


//#region code Prop
        @required()
        @maxLength({value:50})
        code : string;
//#endregion code Prop


//#region isNewsletterSubscribed Prop
        @required()
        isNewsletterSubscribed : boolean;
//#endregion isNewsletterSubscribed Prop


//#region dateAdded Prop
        @required()
        dateAdded : Date;
//#endregion dateAdded Prop

}