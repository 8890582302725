import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, date, prop } from '@rxweb/reactive-form-validators';

import { CoreComponent } from '@rxweb/angular-router';
import { vCountryLookUp, vCurrencyRecord, vCountryRecord, vTaxRate, vStateProvinceLookUp } from '@app/models';
import { List } from '@rxweb/generics';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { vCurrencyLookUp } from 'src/app/models/extended-models/v-currency-look-up';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { DecimalProvider } from "@rxweb/reactive-form-validators/domain/element-processor/decimal.provider";
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
@multilingual("PriceLabelComponent")

@Component({
  selector: 'app-price-label',
  templateUrl: './price-label.component.html'
})
export class PriceLabelComponent extends CoreComponent implements OnInit, OnDestroy {

  _this;

  @Input() pieceNumber: string;
  @Input() productName: string;
  @Input() price: number;
  @Input() primaryCurrenyId: number;
  @Input() primaryExchangeRate: number;
  @Input() pieceRRP: string;

  actualPrice: number;
  priceLabelSearchModel: PriceLabelFilterModel;
  priceLabelFormGroup: IFormGroup<PriceLabelFilterModel>

  subscription: any;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  modalView: ModalView;
  spin: boolean = false;
  showComponent: boolean = false;
  currencyLookUp1: any;
  countryLookup1: any;
  currencyLookUp: List<vCurrencyRecord>;
  countryLookup: List<vCountryRecord>;
  taxRateLookup: List<vTaxRate>;
  stateProvinceLookUp1: any;
  stateProvinceLookUp: List<vStateProvinceLookUp>;
  priceLabelLookups: any = {
    currencyLookUp: vCurrencyLookUp,
    countryLookup: vCountryLookUp,
    taxRateLookup: vTaxRate,
    stateProvinceLookUp: vStateProvinceLookUp
  }
  @Input() hide: Function;
  countryName: string;
  currency: string;
  countryId: number;
  currencyId: number;
  showSelectOption: boolean = false;
  currencyCode: string;
  isOn: boolean = false;
  titleClose: string;
  stateProvinceName: string;
  stateProvinceId: number;
  showStateProvince: boolean = true;
  get componentName(): string {
    return "PriceLabelComponent";
  }

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, router: Router, modelView: ModalView,private location : PlatformLocation) {
    super();
    this.router = router;
    this.modalView = modelView;
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this._this=this;
    location.onPopState(() => this.hide());
  }


  ngOnInit() {
    this.spin = true;
    this.titleClose = getLocalizedValue("Title_Close");
    this.lookup([{ propName: "countryLookup", path: "api/MasterLookups/CountryRecord" },
    { propName: "currencyLookUp", path: "api/MasterLookups/CurrencyRecord" },
    { propName: "taxRateLookup", path: "api/MasterLookups/TaxRateLookUp" },
    { propName: "stateProvinceLookups", path: "api/MasterLookups/StateProvinceLookups" }
    ]).subscribe(response => {
      this.spin = false;

      this.priceLabelLookups = response;
      this.currencyLookUp1 = this.priceLabelLookups.currencyLookUp;
      this.countryLookup1 = this.priceLabelLookups.countryLookup;
      this.taxRateLookup = new List<vTaxRate>(this.priceLabelLookups.taxRateLookup);
      this.currencyLookUp = new List<vCurrencyRecord>(this.priceLabelLookups.currencyLookUp);
      this.countryLookup = new List<vCountryRecord>(this.priceLabelLookups.countryLookup);
      this.stateProvinceLookUp1 = this.priceLabelLookups.stateProvinceLookups;
      this.stateProvinceLookUp = new List<vStateProvinceLookUp>(this.priceLabelLookups.stateProvinceLookups);
      this.priceLabelFormGroup = this.formBuilder.formGroup(PriceLabelFilterModel, this.priceLabelSearchModel) as IFormGroup<PriceLabelFilterModel>;
      this.actualPrice = this.price;
      this.currencyId = this.currencyLookUp.where(x => x.currencyId == 1)["_entities"][0]["currencyId"]; //US Dollar
      this.priceLabelFormGroup.patchValue({ currencyId: this.currencyId });
      this.changeCurrency(this.currencyId, true);
      this.showComponent = true;
    })
  }

  change(event) {
    //this.changeCurrency(event.target.value);
    //this.changeCountry(event.target.value);
    this.calculatePrice(event.target.value, event.target.value);
  }

  changeCurrency(id: number, onInit: boolean = false) {
    // this.showSelectOption = onInit == true ? false : true;
    if (id > 0) {
      this.currencyId = id;
      this.currencyLookUp = new List<vCurrencyRecord>(this.priceLabelLookups.currencyLookUp);
      if (this.currencyLookUp.length > 0 && id != 0 && id != null && id != undefined) {
        this.currencyCode = this.currencyLookUp.where(x => x.currencyId == id)["_entities"][0]["currencyCode"]; //USD
        this.countryId = this.currencyLookUp.where(x => x.currencyId == id)["_entities"][0]["countryId"]; //244
        this.priceLabelFormGroup.patchValue({ countryId: this.countryId });
        this.changeCountry(this.countryId, true);
      }
    }
  }

  changeCountry(id: number, onInit: boolean = false) {
    if(id > 0){
      this.showSelectOption = false;
      this.countryLookup = new List<vCountryRecord>(this.priceLabelLookups.countryLookup);
      this.countryLookup1 = this.priceLabelLookups.countryLookup;
      this.countryLookup1 = this.countryLookup1.filter(t => t.countryId == id);
      if (this.countryLookup1.length > 0 && id != 0 && id != null && id != undefined) {
        this.countryName = this.countryLookup.where(x => x.countryId == id)["_entities"][0]["name"];
      }
  
      setTimeout(() => {
        this.showSelectOption = true;
        this.filterStateProvince(id)
        this.calculatePrice(this.currencyId, id);
      }, 400);
    }
    else{
      this.price = 0;
      this.filterStateProvince(id);
    }    
  }

  filterStateProvince(countryId: number) {
    this.stateProvinceId = null
    this.stateProvinceName = null;
    this.showStateProvince = false;
    
    if(countryId > 0){
      var selectedCountryName = this.countryLookup.where(x => x.countryId == countryId)["_entities"][0]["name"];
      if(selectedCountryName.toLowerCase().trim() == "usa" || selectedCountryName.toLowerCase().trim() == "canada" || selectedCountryName.toLowerCase().trim() == "united arab emirates"){
        this.showStateProvince = true;
      }
    }    
    
    this.priceLabelFormGroup.patchValue({ stateProvinceId: undefined });
    if(this.showStateProvince){
      this.stateProvinceLookUp = new List<vStateProvinceLookUp>(this.priceLabelLookups.stateProvinceLookups);
      this.stateProvinceLookUp1 = this.priceLabelLookups.stateProvinceLookups;
      this.stateProvinceLookUp1 = this.stateProvinceLookUp1.filter(t => t.countryId == countryId);
    }
  }

  changeStateProvince(stateProvinceId: number, onInit: boolean = false) {
    this.stateProvinceName = null;
    this.stateProvinceId = stateProvinceId
    if (stateProvinceId != 0 && stateProvinceId != null && stateProvinceId != undefined) {
      this.stateProvinceName = this.stateProvinceLookUp.where(x => x.stateProvinceId == stateProvinceId)["_entities"][0]["stateProvinceName"];
    }
    setTimeout(() => {
      this.calculatePrice(this.currencyId, this.countryId, this.stateProvinceName);
    }, 400);
  }

  calculatePrice(currencyId: number, countryId: number, stateProvinceName: string = null) {
    if ((this.currencyId != null && this.currencyId > 0) && (this.countryId != null && this.countryId > 0)) {
      this.currencyLookUp = new List<vCurrencyRecord>(this.priceLabelLookups.currencyLookUp);
      this.countryLookup = new List<vCountryRecord>(this.priceLabelLookups.countryLookup);

      var dutyPer = this.countryLookup.where(x => x.countryId == countryId)["_entities"][0]["dutyPer"];
      var exchangeRate = this.currencyLookUp.where(x => x.currencyId == currencyId)["_entities"][0]["rate"];

      var CountryName = this.countryLookup.where(x => x.countryId == this.countryId)["_entities"][0]["name"]

      var taxRate = 0;
      if(stateProvinceName != null){
        if (this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == stateProvinceName)["_entities"].length > 0) {
          taxRate = this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == stateProvinceName)["_entities"][0]["percentage"];
        }
      }
      else{
        if (this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == '')["_entities"].length > 0) {
          taxRate = this.taxRateLookup.where(x => x.countryName == CountryName && x.stateName == '')["_entities"][0]["percentage"];
        }
      }
      var dutyPercent = dutyPer;
      if (this.pieceRRP == "1") {
        var actualExportPrice = this.currencyCode.toUpperCase() != "USD" ? this.roundValueToNext_100(this.convertCurrency(this.actualPrice, this.primaryCurrenyId, currencyId, this.primaryExchangeRate, exchangeRate)) : parseFloat((this.actualPrice).toString());
      }
      else {
        var actualExportPrice = this.currencyCode.toUpperCase() != "USD" ? this.roundValueToNext_100(this.actualPrice * exchangeRate) : (this.actualPrice * exchangeRate);
      }
      var dutyfees = ((actualExportPrice * dutyPercent) / 100);
      var vatValue = ((actualExportPrice + dutyfees) * taxRate) / 100;
      var taxValue = Math.round(dutyfees + vatValue);
      var totalValue = actualExportPrice + taxValue;
      var deliveryPrice = totalValue;

      this.price = Math.round(deliveryPrice);

    }
  }
  convertCurrency(amount: any, sourceCurrency: number, targetCurrency: number, sourceRate: number, targetRate: number) {
    var result = 0;
    result = Math.round(amount);
    if (sourceCurrency == targetCurrency) {
      return result;
    }
    if (result != 0 && sourceCurrency != targetCurrency) {
      result = this.convertToPrimaryExchangeRateCurrency(result, sourceRate);
      result = this.convertFromPrimaryExchangeRateCurrency(result, targetRate);
    }
    result = this.roundValueToNext_100(result);
    return result;
  }
  convertToPrimaryExchangeRateCurrency(amount: any, sourceRate: number) {
    var result = amount;
    var exchangeRate = 0;
    var power = 0;
    power = (Math.pow(10.0, 4))
    exchangeRate = Math.trunc((power * sourceRate)) / power;
    result = result / exchangeRate;
    return result;
  }

  convertFromPrimaryExchangeRateCurrency(amount: any, targetRate: number) {
    var result = amount;
    var exchangeRate = 0;
    var power = 0;
    power = (Math.pow(10.0, 4))
    exchangeRate = Math.trunc((power * targetRate)) / power;
    result = result * exchangeRate;
    return result;
  }
  roundValueToNext_100(value: any) {
    var result = 0;
    var remainder = ((parseInt(value) % 100));
    // if (value < 100)
    // {
    //     result = 100;
    // }
    if (remainder != 0) {
      result = parseInt(((100 - value % 100) + value).toString());
    }
    else {
      result = parseInt((value.toString()));
    }
    return parseFloat(result.toString());
  }


  printPriceLabel(): void {
    let printContents, popupWin;
    this.isOn = true;
    printContents = document.getElementById('price-label').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
        </head>
        <body onload="window.print();">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
    this.isOn = false;
  }

  hidePopup() {
    this.hide()
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}

class PriceLabelFilterModel {
  @prop()
  pieceNumber: string;

  @prop()
  productName: string;

  @prop()
  currencyId: number;

  @prop()
  countryId: number;

  @prop()
  stateProvinceId: number;
}
