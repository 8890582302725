import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { RelatedProductDesign } from '@app/models';
import { AbstractRelatedProductDesign } from '../domain/abstract-related-product-design';
import { AppGrid } from 'src/app/domain/app-grid';
import { RelatedDesignAddViewModel } from 'src/app/view-model/related-design-add-view-model';
import { RelatedDesignSearchModel } from 'src/app/view-model/related-design-search-model';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { List } from '@rxweb/generics';
import { multilingual } from '@rxweb/localization';
import { RelatedDesignViewModel } from 'src/app/view-model/related-design-view-model';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";

@multilingual("RelatedProductDesignAddComponent")
@Component({
    selector: "app-related-product-design-add",
    templateUrl: './related-product-design-add.component.html'
})
export class RelatedProductDesignAddComponent extends AbstractRelatedProductDesign implements OnInit, OnDestroy {

    _this;

    @Input() designId: number;
    @Input() hide: Function;
    relatedProductDesign: RelatedProductDesign;
    excludeRelatedProductDesign:RelatedProductDesign;
    relatedDesignSearch: RelatedDesignSearchModel;
    relatedDesignSearchFormGroup: IFormGroup<RelatedDesignSearchModel>
    relatedDesignList: RelatedDesignAddViewModel[];
    relatedDesignViewObj: RelatedDesignAddViewModel;
    relatedDesignGrid: AppGrid;
    subscription: any;
    categoryLookup: any;
    json: any;
    isFilter: boolean;
    totalRecords: number;
    cloneArray: RelatedDesignAddViewModel[];
    headerBtnChk: boolean;
    relatedDesignDataObj: RelatedDesignAddViewModel;
    selectedDesigns: RelatedDesignAddViewModel[];
    titleClose: string;
    ErrorMsg:string;
    designIds : number[];
    excludeDesignIds : number[];
    fitlerJson:any;
    constructor(private formBuilder: RxFormBuilder, private location : PlatformLocation) {
        super();
        this._this=this;
        this.designIds = Array<number>();
        this.excludeDesignIds = Array<number>();
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.showComponent = false;
        this.spin = true;
        this.headerBtnChk = false;
        this.cloneArray = new Array<RelatedDesignAddViewModel>();
        this.selectedDesigns = new Array<RelatedDesignAddViewModel>();
        this.relatedProductDesign = new RelatedProductDesign();
        this.excludeRelatedProductDesign = new RelatedProductDesign();
        this.relatedProductDesign.displayOrder = 1;
        this.relatedProductDesign.productID1 = this.designId;
        this.relatedProductDesign.productID2 = 1;
        this.relatedProductDesign.relatedProductID = 1;

        this.relatedProductDesign.relatedDesignsAddInput = new Array<RelatedDesignAddViewModel>();
        this.relatedProductDesign.relatedDesignsInput = new Array<RelatedDesignViewModel>();
        this.excludeRelatedProductDesign.relatedDesignsAddInput = new Array<RelatedDesignAddViewModel>();
        this.excludeRelatedProductDesign.relatedDesignsInput = new Array<RelatedDesignViewModel>();

        this.lookup([{ path: USER_LOOKUPS["designCategoryLookup"], propName: "categoryLookup" }]).subscribe((response: any) => {
            this.categoryLookup = response.categoryLookup;
            this.categoryLookup = this.categoryLookup.filter(t => t.parentCategoryId != 0);
            this.categoryLookup = this.categoryLookup.filter(t => t.categoryName = t.categoryName.substring(t.categoryName.indexOf(">") + 3));
            this.relatedDesignSearch = new RelatedDesignSearchModel();
            this.relatedDesignSearch.designId = this.designId;
            this.relatedDesignSearch.category = 9999;
            this.relatedDesignSearchFormGroup = this.formBuilder.formGroup(this.relatedDesignSearch) as IFormGroup<RelatedDesignSearchModel>;
            this.spin = false;
            this.showComponent = true;
        });

    }


    getDesigns() {
        this.totalRecords = 0;
        this.cloneArray = new Array<RelatedDesignAddViewModel>();
        if(this.headerBtnChk) {
            this.relatedProductDesign.relatedDesignsAddInput = new Array<RelatedDesignAddViewModel>();
            this.relatedProductDesign.relatedDesignsInput = new Array<RelatedDesignViewModel>();
            this.excludeRelatedProductDesign.relatedDesignsAddInput = new Array<RelatedDesignAddViewModel>();
            this.excludeRelatedProductDesign.relatedDesignsInput = new Array<RelatedDesignViewModel>();
        }
        this.headerBtnChk = false;
        if (this.relatedDesignGrid != null) {
            this.isFilter = true;
            this.relatedDesignGrid.updateSource([]);
            this.relatedDesignGrid.storeProcedure.nextPage = 1;
            this.relatedDesignSearchFormGroup.value.rowCount = this.relatedDesignGrid.maxPerPage;
        }

        this.relatedDesignSearchFormGroup.value.searchString = null;
        this.relatedDesignSearchFormGroup.value.pageIndex = 1;
        if (this.relatedDesignSearchFormGroup.value.title != null || this.relatedDesignSearchFormGroup.value.title != undefined) {
            this.relatedDesignSearchFormGroup.value.title = this.relatedDesignSearchFormGroup.value.title.replace(/^\s+|\s+$/gm, '');
            //this.relatedDesignSearchFormGroup.value.title=encodeURI(this.relatedDesignSearchFormGroup.value.title);
        }
        // this.relatedDesignSearchFormGroup.value.title=this.relatedDesignSearchFormGroup.value.title!=null?this.relatedDesignSearchFormGroup.value.title.trim():null;
        // this.relatedDesignSearchFormGroup.value.categoryId=this.relatedDesignSearchFormGroup.value.category==9999?null:this.relatedDesignSearchFormGroup.value.category;



        this.bindGrid();
    }

    bindGrid() {
        this.spin = true;
        this.isShowGrid = true;
        this.relatedDesignSearchFormGroup.value.category = this.relatedDesignSearchFormGroup.value.category == 9999 ? null : this.relatedDesignSearchFormGroup.value.category;
        this.fitlerJson = this.relatedDesignSearchFormGroup.value;
        this.post({ path: 'api/SearchProductDesigns/GetRelateDesigns', body: { query: JSON.stringify(this.relatedDesignSearchFormGroup.value) } }).subscribe((t: RelatedDesignAddViewModel[]) => {
            this.relatedDesignList = t[0].UserData;
            this.ErrorMsg=t[0].ErrorMessage;
            this.spin = false;
            this.totalRecords = this.relatedDesignList.length > 0 ? this.relatedDesignList[0].totalCount : 0;
            this.isShowGrid = this.totalRecords > 0 ? true : false;
            this.isNoRecordFound = this.totalRecords > 0 ? false : true;
            if (this.totalRecords > 0) {
                this.relatedDesignList.forEach(x => { x.isChecked1 = this.headerBtnChk });
                this.relatedDesignList.forEach(x => {
                    if (this.cloneArray.findIndex(z => z.designId == x.designId) == -1) {
                        this.cloneArray.push(x);
                    }
                    else {
                        this.cloneArray.forEach(z => {
                            if (z.designId == x.designId) {
                                x.isChecked1 = z.isChecked1
                            }
                        })
                    }
                    if(this.headerBtnChk) {
                        if(this.excludeDesignIds.indexOf(x.designId) != -1) {
                            x.isChecked = false;
                            x.isChecked1 = false;
                        }
                    }
                    else {
                        if(this.designIds.indexOf(x.designId) != -1) {
                            x.isChecked = true;
                            x.isChecked1 = true;
                        }
                    }
                })
                this.updateDesignArray();
            }

            if (this.isShowGrid) {
                if (!this.isFilter) {
                    this.relatedDesignGrid = new AppGrid(this.relatedDesignList, RelatedDesignAddViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this), onData: this.setDisplayOrder.bind(this) } });
                    this.relatedDesignGrid.gridColumns.forEach(x => {
                        if (x.name == 'isChecked' || x.name == 'ViewButton') {
                            x.visible = false;
                        }
                        else if (x.name == 'displayOrder') {
                            x.allowSorting = false;
                            x.style = { width: "12%", "text-align": "initial", "word-break": "break-all" }
                        }
                        else {
                            x.visible = true;
                        }
                    })
                    this.relatedDesignGrid.storeProcedure = {
                        length: this.totalRecords,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                }
                else {
                    this.relatedDesignGrid.updateSource([]);
                    this.relatedDesignGrid.storeProcedure.length = this.totalRecords;
                    this.relatedDesignGrid.updateSource(this.relatedDesignList);
                }
                this.relatedDesignGrid.componentId = this.componentName;
                this.relatedDesignGrid.maxPerPage = this.relatedDesignSearchFormGroup.value.rowCount
                var footerElement = document.getElementsByClassName("dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center")
                if (footerElement != null && footerElement.length > 0) {
                    var child = footerElement[0].children;
                    if (child.length > 0 && child != null) {
                        child[1].className = "col-5"
                    }
                }
            }
        });
    }


    setDesignObject(designData: RelatedDesignAddViewModel) {
        this.relatedDesignDataObj = new RelatedDesignAddViewModel();
        this.relatedDesignDataObj.designId = this.designId;
        this.relatedDesignDataObj.ViewButton = designData.ViewButton;
        this.relatedDesignDataObj.designName = designData.designName;
        this.relatedDesignDataObj.displayOrder = designData.displayOrder;
        this.relatedDesignDataObj.relatedDesignId = designData.designId;
        this.relatedDesignDataObj.totalCount = designData.totalCount;
        this.relatedDesignDataObj.published = designData.published;
        this.relatedDesignDataObj.isChecked1 = designData.isChecked1;
        this.relatedDesignDataObj.isChecked = designData.isChecked;
    }

    setDisplayOrder(x, y, z, u, invitee: RelatedDesignAddViewModel) {

        //this.relatedDesignDataObj.isChecked1=y.target.checked;

        if (this.relatedProductDesign.relatedDesignsAddInput.findIndex(z => z.relatedDesignId == x.designId) != -1) {
            this.relatedProductDesign.relatedDesignsAddInput.forEach(t => {
                if (t.relatedDesignId == x.designId) {
                    t.displayOrder = Math.round(Number(y.target.value));
                }
            })
        }
        else {
            this.relatedDesignViewObj = new RelatedDesignAddViewModel();
            if (this.selectedDesigns.findIndex(t => t.relatedDesignId == x.designId) != -1) {
                this.selectedDesigns.forEach(t => {
                    if (t.relatedDesignId == x.designId) {
                        t.displayOrder = Math.round(Number(y.target.value));
                    }
                })
            } else {
                this.setDesignObject(x);
                this.relatedDesignDataObj.displayOrder = Math.round(Number(y.target.value));
                this.relatedDesignDataObj.isChecked1 = false;
                this.selectedDesigns.push(this.relatedDesignDataObj);
            }
        }
    }

    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  ( this.totalRecords - this.excludeRelatedProductDesign.relatedDesignsAddInput.length )  + " Records";
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveRelatedDesigns();
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveRelatedDesigns();
        }
     }

    saveRelatedDesigns() {
        if(this.headerBtnChk) {
            this.relatedProductDesign.relatedDesignsAddInput = this.excludeRelatedProductDesign.relatedDesignsAddInput.filter(z => z.isChecked1 == false);
            this.relatedProductDesign.HeaderBtnChk = this.headerBtnChk;
            this.fitlerJson["rowCount"] = this.totalRecords;
            this.fitlerJson["PageIndex"] = 1;
            this.fitlerJson["sortOrder"] = "false";
            this.relatedProductDesign.query = JSON.stringify(this.fitlerJson);
            if (this.relatedProductDesign.relatedDesignsAddInput != null && this.relatedProductDesign.relatedDesignsAddInput.length > 0) {
                this.relatedProductDesign.relatedDesignsInput = new Array<RelatedDesignAddViewModel>();
                this.relatedProductDesign.relatedDesignsAddInput.forEach(x => {
                    this.relatedProductDesign.relatedDesignsInput.push(x);
                })
            }
        }
        else {
            this.relatedProductDesign.relatedDesignsAddInput = this.relatedProductDesign.relatedDesignsAddInput.filter(z => z.isChecked1 == true);
            if (this.relatedProductDesign.relatedDesignsAddInput != null && this.relatedProductDesign.relatedDesignsAddInput.length > 0) {
                this.relatedProductDesign.relatedDesignsInput = new Array<RelatedDesignAddViewModel>();
                this.relatedProductDesign.relatedDesignsAddInput.forEach(x => {
                    this.relatedProductDesign.relatedDesignsInput.push(x);
                })
            }
        }


        this.spin = true;
        this.post({ body: this.relatedProductDesign }).subscribe(t => {
            this.spin = false;
            this.cloneArray = [];
            this.headerBtnChk = false;
            //this.toastr.success("Data Added Successfully")
            var existsAlert = getLocalizedValue("Data_Added");
            if (existsAlert) {
                this.toastr.success(existsAlert);
            }
            this.hide();
        })
    }

    onCheckboxSelect(t, x) {
        this.relatedDesignViewObj = new RelatedDesignAddViewModel();
        this.relatedDesignViewObj.relatedDesignId = t.designId;
        this.relatedDesignViewObj.isChecked1 = x.target.checked;
        let selectedDesign = this.selectedDesigns.filter(z => z.relatedDesignId == t.designId)[0];
        if (selectedDesign != null && selectedDesign != undefined) {
            this.relatedDesignViewObj.displayOrder = selectedDesign.displayOrder;
        }
        else {
            this.relatedDesignViewObj.displayOrder = t.displayOrder;
        }

        this.relatedDesignViewObj.designId = this.designId;

        if (x.target.checked) {
            if(this.headerBtnChk) {
                if(this.excludeRelatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) != -1){
                    this.excludeRelatedProductDesign.relatedDesignsAddInput.splice(this.excludeRelatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId),1);
                    this.excludeDesignIds.splice(this.excludeDesignIds.indexOf(t.designId),1);
                }
            }
            else {
                if (this.relatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) == -1) {
                    this.relatedProductDesign.relatedDesignsAddInput.push(this.relatedDesignViewObj);
                    this.designIds.push(t.designId);
                }
            }
        }
        else {
            if(this.headerBtnChk) {
                if(this.excludeRelatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) == -1){
                    this.excludeRelatedProductDesign.relatedDesignsAddInput.push(this.relatedDesignViewObj);
                    this.excludeDesignIds.push(t.designId);
                }
            }
            else {
                if (this.relatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) != -1) {
                    this.relatedProductDesign.relatedDesignsAddInput.splice(this.relatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId), 1);
                    this.designIds.splice( this.designIds.indexOf(t.designId) ,1);
                }
            }
        }
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        let designArray: RelatedDesignAddViewModel[];
        designArray = this.cloneArray;
        this.cloneArray = new Array<RelatedDesignAddViewModel>();

        // this.relatedProductDesign = new RelatedProductDesign();
        this.relatedProductDesign.relatedDesignsAddInput = new Array<RelatedDesignAddViewModel>();
        this.relatedProductDesign.relatedDesignsInput = new Array<RelatedDesignViewModel>();

        // this.excludeRelatedProductDesign = new RelatedProductDesign();
        this.excludeRelatedProductDesign.relatedDesignsAddInput = new Array<RelatedDesignAddViewModel>();
        this.excludeRelatedProductDesign.relatedDesignsInput = new Array<RelatedDesignViewModel>();


        designArray.forEach(data => {
            let newObj = new RelatedDesignAddViewModel();
            newObj.designId = data.designId;
            newObj.isChecked1 = this.headerBtnChk;
            newObj.designName = data.designName;
            if (this.selectedDesigns.findIndex(z => z.relatedDesignId == data.designId) != -1) {
                let addedDesign = this.selectedDesigns.filter(z => z.relatedDesignId == data.designId)[0];
                newObj.displayOrder = addedDesign.displayOrder;
            }
            else if (this.relatedProductDesign.relatedDesignsAddInput.findIndex(z => z.relatedDesignId == data.designId) != -1) {
                let addedDesign = this.relatedProductDesign.relatedDesignsAddInput.filter(z => z.relatedDesignId == data.designId)[0];
                newObj.displayOrder = addedDesign.displayOrder;
            }
            else {
                newObj.displayOrder = data.displayOrder;
            }
            newObj.relatedDesignId = data.relatedDesignId;
            newObj.published = data.published;
            newObj.ViewButton = data.ViewButton;
            newObj.isChecked = false;
            newObj.totalCount = data.totalCount;
            this.cloneArray.push(newObj);
        });

        this.updateDesignArray();
        this.relatedDesignGrid.updateSource([]);
        this.relatedDesignGrid.updateSource(this.cloneArray);
    }

    updateDesignArray() {
        this.cloneArray.forEach(t => {
            this.relatedDesignViewObj = new RelatedDesignAddViewModel();
            this.relatedDesignViewObj.relatedDesignId = t.designId;
            this.relatedDesignViewObj.displayOrder = t.displayOrder;
            this.relatedDesignViewObj.designId = this.designId;
            this.relatedDesignViewObj.isChecked1 = t.isChecked1;

            if (t.isChecked1) {
                if(this.headerBtnChk) {
                    if(this.excludeRelatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) != -1){
                        this.excludeRelatedProductDesign.relatedDesignsAddInput.splice(this.excludeRelatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId),1);
                        this.excludeDesignIds.splice(this.excludeDesignIds.indexOf(t.designId),1);
                    }
                }
                else {
                    if (this.relatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) == -1) {
                        this.relatedProductDesign.relatedDesignsAddInput.push(this.relatedDesignViewObj);
                        this.designIds.push(t.designId);
                    }
                }
            }
            else {
                if(this.headerBtnChk) {
                    if(this.excludeRelatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) == -1){
                        this.excludeRelatedProductDesign.relatedDesignsAddInput.push(this.relatedDesignViewObj);
                        this.excludeDesignIds.push(t.designId);
                    }
                }
                else {
                    if (this.relatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId) != -1) {
                        this.relatedProductDesign.relatedDesignsAddInput.splice(this.relatedProductDesign.relatedDesignsAddInput.findIndex(x => x.relatedDesignId == t.designId), 1);
                        this.designIds.splice(this.designIds.indexOf(t.designId),1);
                    }
                    if(this.designIds.indexOf(t.designId) != -1) {
                        this.designIds.splice(this.designIds.indexOf(t.designId),1);
                    }
                }
            }
        });
    }

    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.relatedDesignSearchFormGroup.value.rowCount;
        let toIndex: number = this.relatedDesignGrid.maxPerPage;
        if (this.relatedDesignSearchFormGroup.value.pageIndex < x) {
            this.cloneArray = [];
        }
        if(this.headerBtnChk) {
            this.cloneArray = [];
        }
        if (this.relatedDesignGrid.maxPerPage < this.relatedDesignSearchFormGroup.value.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }
        }
        this.relatedDesignSearchFormGroup.value.pageIndex = x;
        this.relatedDesignSearchFormGroup.value.rowCount = this.relatedDesignGrid.maxPerPage;
        this.relatedDesignGrid.storeProcedure.nextPage = x;
        this.relatedDesignGrid.updateSource([]);
        this.bindGrid();
    }


    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.relatedDesignSearchFormGroup.value.sortOrder = y;
        this.relatedDesignSearchFormGroup.value.orderBy = x;
        this.relatedDesignGrid.storeProcedure.nextPage = z;
        this.cloneArray = [];
        this.relatedDesignGrid.updateSource([]);
        this.bindGrid();
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "RelatedProductDesignAddComponent";
    }


}
