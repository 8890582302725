<ng-container *ngIf="showSkeletonLoading">
  <nav class="navbar-default navbar-static-side navbar-pos-md" id="nav-side-menu" (window:resize)="onResize($event)"
  style="height: 100%;overscroll-behavior: contain;" [rxLocalisationInit]="componentName" role="navigation"
  [ngClass]="{'navbar-collapse':isSideBarActive}" *rxMultilingual="">

  <div>
    <ngx-skeleton-loader count="1" appearance="line" animation="progress-dark" [theme]="{ 
      'height': '60px',
      'background-color': '#3a4651',
      'margin': '5px',
      'width': 'calc(100% - 10px)'
    }"></ngx-skeleton-loader>
  </div>
  <div>
    <ngx-skeleton-loader count="16" appearance="line" animation="progress-dark" [theme]="{ 
      'height': '30px',
      'background-color': '#3a4651',
      'margin': '5px',
      'width': 'calc(100% - 10px)'
    }"></ngx-skeleton-loader>
  </div>
  </nav>
</ng-container>

<ng-container *ngIf="!showSkeletonLoading">


<nav class="navbar-default navbar-static-side navbar-pos-md" id="nav-side-menu" (window:resize)="onResize($event)"
  style="height: 100%;overscroll-behavior: contain;" [rxLocalisationInit]="componentName" role="navigation"
  [ngClass]="{'navbar-collapse':isSideBarActive}" *rxMultilingual="">
  <div class="sidebar-angle" (click)="isToggleSideBar()" *ngIf="innerWidth >= 769">
    <span [title]="btnText" class="angle-sidebar"
      [ngStyle]="{'margin-top' : isSideBarActive?'12px' : '15px','margin-right' : isSideBarActive?'-1px' : '10px','font-size': isSideBarActive?'x-large' : 'xx-large'}">
      <i class="fa" [ngClass]="isSideBarActive?' fa-angle-double-right' : ' fa-angle-double-left'"></i></span>
    <!-- <button (click)="isToggleSideBar()" class="btn btn-dark text-white" style=" float: right;">{{btnText}}</button> -->
  </div>
  <div class="dropdown profile-element text-center sidebar-open-image">
    <div class="logo-container text-center" (click)="isToggleSideBar()"><img alt="image"
        style="cursor: pointer;width:160px;" src="../../../../assets/images/GtracLogoBW.png" /></div>
  </div>
  <div class="logo-element" (click)="isToggleSideBar()"
    [ngStyle]="{'width': !isSideBarActive ? '220px' : '50px', 'cursor': (innerWidth < 769) ? 'default' : 'pointer'}">
    <img alt="image" class="img-fluid" style="width: 30px;"
      [ngStyle]="{'cursor': (innerWidth < 769) ? 'default' : 'pointer'}" src="../../../../assets/images/GLogoNew.png" />
  </div>
  <div class="sidebar-collapse" (scroll)="hideSubMenuOnScroll()"
    [ngStyle]="{'margin-top': isSideBarActive ? '0px' : '80px','top': isSideBarActive ? '66px' : '80px', 'width': !isSideBarActive ? '220px' : '50px'}"
    [ngClass]="{'sidebar-collapsed' : isSideBarActive}">
    
    <ul [rxLocalisationInit]="componentName" class="nav metismenu  slim-scroll" id="side-menu">
      <div class="container m-0 p-0">
        <div class="m-0 p-0 d-flex">
          <span [rxLocalisationInit]="componentName" class="navigation-button__label" rxText="SideBarComponent_Favourites_t"></span>
        </div>
        
        <div [ngClass]="{'d-block mt-2': favList.length == 0,'d-none':favList.length > 0}" class="message-box p-3 rounded text-center">
          <div class="d-flex justify-content-center align-items-center">
            <i class=" fa fa-star fa-lg"></i>
            <p class="m-0 ml-2 favorites-title-text" rxText="SideBarComponent_FavouritesTitle_t"></p>
          </div>
          <!-- <div>
            <p class="mt-2 text-muted" style="font-size: smaller;">Click on the star on the right side of a menu item to add as a favorite.</p>
          </div> -->
        </div>
      </div>
 
      <ng-container *ngIf="favList.length > 0">
        <ng-container *ngFor="let item of favList" [rxLocalisationInit]="componentName">
          <li class="nav-item d-flex align-items-center favorite-menu">
            <a class="nav-link w-100" [routerLink]="[item.ModuleLink]" (click)="handleChangeMenu(item.ModuleName, $event)">
              <i class="{{item.ClassKey}}"></i>
              <span class="nav-label" *ngIf="item.LocalizationKey">{{item.LocalizationKey}}</span>
              <!-- <span class="nav-label" *ngIf="item.localizationKey" [rxText]="item.localizationKey"></span> -->
              <span class="nav-label" *ngIf="!item.LocalizationKey">{{item.ModuleName}}</span>
              <span class="sr-only">(current)</span>
              <i (click)="addToFavorites(item.ModuleName, $event)" [title]="titleRemoveFromFavorites"
                
                class="favorites-icon mt-1 fa fa-star fa-lg"></i>
            </a>
          </li>
        </ng-container>
      </ng-container>
      
      
      <span class="navigation-button__label" rxText="SideBarComponent_Pages_t"></span>
      <!-- <li class="nav-header">
        <div class="dropdown profile-element text-center">
          <div class="logo-container text-center" (click)="isToggleSideBar()"><img alt="image"
              style="cursor: pointer;width:160px;" src="../../../../assets/images/GtracLogoBW.png" /></div>
        </div>
        <div class="logo-element" (click)="isToggleSideBar()" style="cursor: pointer;">
          <img alt="image" class="img-fluid" style="cursor: pointer;width: 30px;"
            src="../../../../assets/images/GLogoNew.png" />
        </div>
      </li> -->
      

      <li class="nav-item d-flex align-items-center" [class.active]="activeMenu == 'dashboard'">
        <a (click)="changeActiveMenu('dashboard',$event)" class="nav-link w-100" [routerLink]="['/dashboard']">
            <i class="fr-dashboard" title="Dashboard"></i>
            <span class="nav-label" rxText="DashBoardComponent_Title_t"></span>
            
            <i class="favorites-icon mt-1 fa fa-star fa-lg"  [ngClass]="{'fa-star-o': !isFavorite('dashboard') && !hovering['dashboard'] }" [title]="getTooltipText('dashboard')"   (click)="addToFavorites('dashboard', $event)" (mouseenter)="handleStarIconHover('dashboard')"
            (mouseleave)="handleStarIconHover('dashboard')"></i>
        </a>
        <!-- <a class="nav-link w-25" [class.active]="activeMenu == 'dashboard'"  *ngIf="activeMenu == 'dashboard'">
        </a> -->
      </li>
    

      <li class="nav-item d-flex align-items-center" [class.active]="activeMenu.indexOf('faberge-advanced-search')!=-1"
        *rxAuthorize="[advancedSearch]" [rxLocalisationInit]="componentName">
        <a (click)="changeActiveMenu('faberge-advanced-search', $event)" class="nav-link w-100" 
          [routerLink]="['/faberge-advanced-search']"><i class="fr-pos" title="Advanced Search"></i><span
            class="nav-label" rxText="FabergeAdvancedSearchListComponent_Title"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge-advanced-search')  && !hovering['faberge-advanced-search'] }" [title]="getTooltipText('faberge-advanced-search')"   (click)="addToFavorites('faberge-advanced-search', $event)" (mouseenter)="handleStarIconHover('faberge-advanced-search')"
            (mouseleave)="handleStarIconHover('faberge-advanced-search')"></i>
            </a>
            <!-- <a  class="" > -->
              <!-- <a class="nav-link" >
              </a> -->
            </li>

      <ng-container *rxAuthorize="[homeLayout,fabergePages,fabergeNews,menuLayout,mirFaberge]"
        [rxLocalisationInit]="componentName">

        <li class="nav-item " [class.active]="activeMenu=='cms'" [class.hasSub]="activeMenu=='cms'"  *ngIf="activeMenu == 'cms' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('cms', $event)" class="nav-link "
            aria-expanded="true" href="javascript:void(0)"><i class="fr-content-management"
              title="Content Management"></i><span class="nav-label" rxText="Label_Content_Management_t"></span><span
              class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse "
            [class.in]="activeMenu == 'cms'&& toggleMenu">
            <li [class.active]="activeSubMenu=='content-management-news'"
            class="nav-item d-flex align-items-center" *rxAuthorize="[fabergeNews]"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('content-management-news', $event)" [routerLink]="['/content-management-news']" class="nav-link w-100">
                <span class="nav-label" rxText="Label_Faberge_News_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('content-management-news') && !hovering['content-management-news']}" [title]="getTooltipText('content-management-news')"
                (click)="addToFavorites('content-management-news', $event)" (mouseenter)="handleStarIconHover('content-management-news')" (mouseleave)="handleStarIconHover('content-management-news')"></i>
              </a>
            </li>

        
            <li [class.active]="activeSubMenu=='history-mir-faberge'" *rxAuthorize="[mirFaberge]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('history-mir-faberge', $event)" class="nav-link w-100" 
                [routerLink]="['/history-mir-faberge']" >
                <span class="nav-label" rxText="HistoryMirFabergeListComponent_Title_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('history-mir-faberge') && !hovering['history-mir-faberge'] }" [title]="getTooltipText('history-mir-faberge')"  (click)="addToFavorites('history-mir-faberge', $event)" (mouseenter)="handleStarIconHover('history-mir-faberge')" (mouseleave)="handleStarIconHover('history-mir-faberge')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'history-mir-faberge'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='faberge-pages'" *rxAuthorize="[fabergePages]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('faberge-pages', $event)" class="nav-link w-100" 
                [routerLink]="['/faberge-pages']" >
                <span class="nav-label" rxText="Label_Faberge_Pages_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge-pages') && !hovering['faberge-pages'] }" [title]="getTooltipText('faberge-pages')"  (click)="addToFavorites('faberge-pages', $event)" (mouseenter)="handleStarIconHover('faberge-pages')" (mouseleave)="handleStarIconHover('faberge-pages')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'faberge-pages'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='home-layouts'" *rxAuthorize="[homeLayout]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('home-layouts', $event)" class="nav-link w-100"  
                [routerLink]="['/home-layouts']" >
                <span class="nav-label" rxText="Label_Home_Page_Layout_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('home-layouts') && !hovering['home-layouts']}" [title]="getTooltipText('home-layouts')" (click)="addToFavorites('home-layouts', $event)" (mouseenter)="handleStarIconHover('home-layouts')" (mouseleave)="handleStarIconHover('home-layouts')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'home-layouts'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='menus'" *rxAuthorize="[menuLayout]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('menus', $event)" [routerLink]="['/menus']" class="nav-link w-100"
                >
                <span class="nav-label" rxText="Label_Menu_Layout_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('menus') && !hovering['menus'] }"  [title]="getTooltipText('menus')"  (click)="addToFavorites('menus', $event)" (mouseenter)="handleStarIconHover('menus')" (mouseleave)="handleStarIconHover('menus')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'menus'">
                </a> -->
              </li>
          </ul>
        </li>

        <li class="nav-item" *ngIf="activeMenu != 'cms' || (activeMenu=='cms' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('cms', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)"><i class="fr-content-management"
              title="Content Management"></i><span class="nav-label" rxText="Label_Content_Management_t"></span><span
              class="fa arrow"></span></a>
        </li>

      </ng-container>

      <li class="nav-item" [class.active]="activeMenu=='suppliers'" *rxAuthorize="[supplierList]" class="nav-item d-flex align-items-center"
        [rxLocalisationInit]="componentName">
        <a (click)="changeActiveMenu('suppliers', $event)" class="nav-link w-100" [routerLink]="['/suppliers']">
          <i class="fa fa-th-large" title="Corporates/Groups"></i><span class="nav-label"
            rxText="Label_Corporates_Groups_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('suppliers') && !hovering['suppliers'] }" [title]="getTooltipText('suppliers')"    (click)="addToFavorites('suppliers', $event)" (mouseenter)="handleStarIconHover('suppliers')" (mouseleave)="handleStarIconHover('suppliers')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'suppliers'">
            </a> -->
          </li>

      <li class="nav-item" [class.active]="activeMenu == 'events'" *rxAuthorize="[eventList]" class="nav-item d-flex align-items-center"
        [rxLocalisationInit]="componentName">
        <a (click)="changeActiveMenu('events', $event)" class="nav-link w-100"  [routerLink]="['/events']">
          <i class="fr-event" title="Events"></i><span class="nav-label" rxText="Label_Events_t"></span>
          <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('events') && !hovering['events'] }" [title]="getTooltipText('events')"   (click)="addToFavorites('events', $event)" (mouseenter)="handleStarIconHover('events')" (mouseleave)="handleStarIconHover('events')"></i>
        </a>
          <!-- <a class="nav-link" *ngIf="activeMenu == 'events'">
          </a> -->
        </li>



      <ng-container *rxAuthorize="[productionForm, electronicGiftForm]" [rxLocalisationInit]="componentName">

        <li class="nav-item" [class.active]="activeMenu=='forms'" [class.hasSub]="activeMenu=='forms'" *ngIf="activeMenu == 'forms' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('forms', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)"><i class="fr-form" title="Forms"></i><span class="nav-label"
              rxText="Label_Forms_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'forms'&& toggleMenu">



            <li [class.active]="activeSubMenu=='production-order-forms'" *rxAuthorize="[productionForm]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('production-order-forms', $event)" class="nav-link w-100" 
                [routerLink]="['/production-order-forms']" ><span class="nav-label" rxText="Label_Faberge_Production_Order_Form_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('production-order-forms') && !hovering['production-order-forms'] }" [title]="getTooltipText('production-order-forms')"   (click)="addToFavorites('production-order-forms', $event)" (mouseenter)="handleStarIconHover('production-order-forms')" (mouseleave)="handleStarIconHover('production-order-forms')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'production-order-forms'">
                </a> -->
              </li>

            <li [class.active]="activeSubMenu=='electronic-gift-forms'" *rxAuthorize="[electronicGiftForm]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('electronic-gift-forms', $event)" class="nav-link w-100" 
                [routerLink]="['/electronic-gift-forms']" ><span class="nav-label" rxText="Label_Receiving_Gift_or_Hospitality_Form_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('electronic-gift-forms') && !hovering['electronic-gift-forms'] }" [title]="getTooltipText('electronic-gift-forms')"    (click)="addToFavorites('electronic-gift-forms', $event)" (mouseenter)="handleStarIconHover('electronic-gift-forms')" (mouseleave)="handleStarIconHover('electronic-gift-forms')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'electronic-gift-forms'">
                </a> -->
              </li>


            <li [class.active]="activeSubMenu=='Group Business Compliance'">
              <a class="nav-link" aria-expanded="true" (click)="changeActiveSubMenu('Group Business Compliance', $event)"
                href="javascript:void(0)">
                <span class="" style="flex-grow: 1;">Group Business Compliance</span>
                <span class="fa arrow"></span></a>
            </li>

            <ul class="nav nav-third-level collapse" *ngFor="let item of formsGroupBusiness"
              [class.in]="activeSubMenu == 'Group Business Compliance' && toggleSecondLevelMenu">
              <li class="nav-item">
                <a href="{{item.fileUrl}}" target="_blank" class="nav-link" aria-expanded="true"> <span class=""
                    style="word-break: break-all;">{{item.name}}</span></a>
              </li>
            </ul>

            <li [class.active]="activeSubMenu=='Group Employees'">
              <a class="nav-link" aria-expanded="true" (click)="changeActiveSubMenu('Group Employees', $event)"
                href="javascript:void(0)"><span class="" style="flex-grow: 1;">Group Employees</span>
                <span class="fa arrow"></span></a>
            </li>

            <ul class="nav nav-third-level collapse" *ngFor="let item of formsEmployees"
              [class.in]="activeSubMenu == 'Group Employees' && toggleSecondLevelMenu">
              <li class="nav-item">
                <a href="{{item.fileUrl}}" target="_blank" class="nav-link" aria-expanded="true"> <span class=""
                    style="word-break: break-all;">{{item.name}}</span></a>
              </li>
            </ul>

            <li [class.active]="activeSubMenu=='Faberge'">
              <a class="nav-link" aria-expanded="true" (click)="changeActiveSubMenu('Faberge', $event)"
                href="javascript:void(0)"><span class="" style="flex-grow: 1;">Faberge</span>
                <span class="fa arrow"></span></a>
            </li>

            <ul class="nav nav-third-level collapse" *ngFor="let item of formsFaberge"
              [class.in]="activeSubMenu == 'Faberge' && toggleSecondLevelMenu">
              <li class="nav-item">
                <a href="{{item.fileUrl}}" target="_blank" class="nav-link" aria-expanded="true"> <span class=""
                    style="word-break: break-all;">{{item.name}}</span></a>
              </li>
            </ul>

            <li *ngFor="let menu of formsMenuArr">
              <a href="{{menu.fileUrl}}" target="_blank" class="nav-link" aria-expanded="true" class=""><span class=""
                  style="flex-grow: 1;">{{menu.name}}</span></a>
            </li>


          </ul>
        </li>
        <li class="nav-item" *ngIf="activeMenu != 'forms' || (activeMenu=='forms' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('forms', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)">
            <i class="fr-form" title="Forms"></i><span class="nav-label" rxText="Label_Forms_t"></span>
            <span class="fa arrow"></span></a>
        </li>

      </ng-container>


      <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeMenu=='track-incidents'" class="nav-item d-flex align-items-center"
        *rxAuthorize="[hseForm]">
        <a (click)="changeActiveMenu('track-incidents', $event)" class="nav-link"  class="nav-link w-100" 
          [routerLink]="['/track-incidents']"><i class="fr-form" title="HSE / GINA "></i><span class="nav-label"
            rxText="Label_HSE/GINA_t"></span> 
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('track-incidents') && !hovering['track-incidents'] }" [title]="getTooltipText('track-incidents')"    (click)="addToFavorites('track-incidents', $event)" (mouseenter)="handleStarIconHover('track-incidents')" (mouseleave)="handleStarIconHover('track-incidents')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'track-incidents'">
            </a> -->
      </li>


      <ng-container *rxAuthorize="[productDesign,productList,pieceList,pieceGemstone,gemstoneOrders,
                    bookingList,bookingApproval,priceList,carnets,watchParts,batchList]" [rxLocalisationInit]="componentName">

        <li class="nav-item" [class.active]="activeMenu=='inventory'" [class.hasSub]="activeMenu=='inventory'" *ngIf="activeMenu == 'inventory' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('inventory', $event)" class="nav-link" 
            href="javascript:void(0)" aria-expanded="true"><i class="fr-inventory" title="Inventory"></i><span
              class="nav-label" rxText="Label_Inventory_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'inventory' && toggleMenu">
            <li [class.active]="activeSubMenu=='product-design'" *rxAuthorize="[productDesign]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('product-design', $event)" class="nav-link w-100" 
                [routerLink]="['/product-design']" ><span class="nav-label" rxText="Label_Faberge_Designs_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('product-design') && !hovering['product-design'] }"  [title]="getTooltipText('product-design')"  (click)="addToFavorites('product-design', $event)" (mouseenter)="handleStarIconHover('product-design')" (mouseleave)="handleStarIconHover('product-design')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'product-design'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='product'" *rxAuthorize="[productList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('product', $event)" class="nav-link w-100"
                [routerLink]="['/product']" ><span class="nav-label" rxText="Label_Faberge_Products_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('product') && !hovering['product'] }" [title]="getTooltipText('product')"   (click)="addToFavorites('product', $event)" (mouseenter)="handleStarIconHover('product')" (mouseleave)="handleStarIconHover('product')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'product'">
                </a> -->
              </li>
                <!--  -->
            <li [class.active]="activeSubMenu=='batch-section'" *rxAuthorize="[batchList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('batch-section', $event)" class="nav-link w-100"
                [routerLink]="['/batch-section']" ><span class="nav-label" rxText="Label_Faberge_Batches_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('batch-section') && !hovering['batch-section'] }" [title]="getTooltipText('batch-section')" (click)="addToFavorites('batch-section', $event)" (mouseenter)="handleStarIconHover('batch-section')" (mouseleave)="handleStarIconHover('batch-section')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'batch-section'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='product-piece'" *rxAuthorize="[pieceList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a [routerLink]="['/product-piece']" class="nav-link w-100" 
                (click)="changeActiveSubMenu('product-piece', $event)" ><span class="nav-label" rxText="Label_Faberge_Pieces_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('product-piece') && !hovering['product-piece'] }" [title]="getTooltipText('product-piece')"  (click)="addToFavorites('product-piece', $event)" (mouseenter)="handleStarIconHover('product-piece')" (mouseleave)="handleStarIconHover('product-piece')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'product-piece'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='product-piece-gemstones'" *rxAuthorize="[pieceGemstone]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('product-piece-gemstones', $event)" class="nav-link w-100" 
                [routerLink]="['/product-piece-gemstones']" ><span class="nav-label" rxText="Label_Faberge_Gemstones_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('product-piece-gemstones') && !hovering['product-piece-gemstones'] }" [title]="getTooltipText('product-piece-gemstones')"  (click)="addToFavorites('product-piece-gemstones', $event)" (mouseenter)="handleStarIconHover('product-piece-gemstones')" (mouseleave)="handleStarIconHover('product-piece-gemstones')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'product-piece-gemstones'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='gemstone-orders'" *rxAuthorize="[gemstoneOrders]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('gemstone-orders', $event)" class="nav-link w-100" 
                [routerLink]="['/gemstone-orders']" ><span class="nav-label" rxText="Label_Faberge_Gemstones_Orders_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('gemstone-orders') && !hovering['gemstone-orders'] }"  [title]="getTooltipText('gemstone-orders')" (click)="addToFavorites('gemstone-orders', $event)" (mouseenter)="handleStarIconHover('gemstone-orders')" (mouseleave)="handleStarIconHover('gemstone-orders')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'gemstone-orders'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='watch-parts'" *rxAuthorize="[watchParts]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('watch-parts', $event)" class="nav-link w-100" 
                [routerLink]="['/watch-parts']" ><span class="nav-label" rxText="WatchParts_ModuleHeader_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('watch-parts') && !hovering['watch-parts'] }" [title]="getTooltipText('watch-parts')"  (click)="addToFavorites('watch-parts', $event)" (mouseenter)="handleStarIconHover('watch-parts')" (mouseleave)="handleStarIconHover('watch-parts')"></i>
              </a>
              <!-- <a class="nav-link" *ngIf="activeSubMenu == 'watch-parts'">
              </a> -->
            </li>
            <!--<li [class.active]="activeSubMenu=='gemfields-gemstones'"><a  href="javascript:void(0)"  (click)="changeActiveSubMenu('gemfields-gemstones')">Gemfields Gemstones</a></li>-->
            <!-- <li [class.active]="activeSubMenu=='components'"><a  href="javascript:void(0)"  (click)="changeActiveSubMenu('components')">Components</a></li> -->
            <li [class.active]="activeSubMenu=='bookings'" *rxAuthorize="[bookingList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('bookings', $event)" class="nav-link w-100" 
                [routerLink]="['/bookings']" ><span class="nav-label" rxText="Label_Bookings_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('bookings') && !hovering['bookings'] }" [title]="getTooltipText('bookings')"  (click)="addToFavorites('bookings', $event)" (mouseenter)="handleStarIconHover('bookings')" (mouseleave)="handleStarIconHover('bookings')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'bookings'">
                </a> -->
            </li>
            <li [class.active]="activeSubMenu.indexOf('booking-approval')!=-1" *rxAuthorize="[bookingApproval]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a class="nav-link w-100" (click)="changeActiveSubMenu('booking-approval', $event)"
                [routerLink]="['/booking-approval']"><span class="nav-label" rxText="Label_Bookings_Approval_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('booking-approval') && !hovering['booking-approval'] }" [title]="getTooltipText('booking-approval')"  (click)="addToFavorites('booking-approval', $event)" (mouseenter)="handleStarIconHover('booking-approval')" (mouseleave)="handleStarIconHover('booking-approval')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'booking-approval'">
                </a> -->
              </li>
            <!--<li [class.active]="activeSubMenu=='gemfields-bookings'"><a  href="javascript:void(0)"  (click)="changeActiveSubMenu('gemfields-bookings')">Gemfields Bookings</a></li>-->
            <li [class.active]="activeSubMenu=='faberge-price-lists'" *rxAuthorize="[priceList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('faberge-price-lists', $event)" class="nav-link w-100" 
                [routerLink]="['/faberge-price-lists']" ><span class="nav-label" rxText="Label_Faberge_Price_Lists_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge-price-lists') && !hovering['faberge-price-lists'] }"  [title]="getTooltipText('faberge-price-lists')" (click)="addToFavorites('faberge-price-lists', $event)" (mouseenter)="handleStarIconHover('faberge-price-lists')" (mouseleave)="handleStarIconHover('faberge-price-lists')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'faberge-price-lists'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='carnets'" *rxAuthorize="[carnets]" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center">
              <a (click)="changeActiveSubMenu('carnets', $event)" class="nav-link w-100" [routerLink]="['/carnets']"
               ><span class="nav-label" rxText="Label_Faberge_Carnets_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('carnets') && !hovering['carnets'] }" [title]="getTooltipText('carnets')"  (click)="addToFavorites('carnets', $event)" (mouseenter)="handleStarIconHover('carnets')" (mouseleave)="handleStarIconHover('carnets')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'carnets'">
                </a> -->
              </li>
          </ul>
        </li>

        <li class="nav-item" [rxLocalisationInit]="componentName"
          *ngIf="activeMenu != 'inventory' || (activeMenu=='inventory' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('inventory', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i class="fr-inventory" title="Inventory"></i><span
              class="nav-label" rxText="Label_Inventory_t"></span><span class="fa arrow"></span></a>
        </li>

      </ng-container>
<!-- 
      <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeMenu == 'ticket-details'" class="nav-item d-flex align-items-center"
        *rxAuthorize="[ticketDetails]">
        <a (click)="changeActiveMenu('ticket-details', $event)" class="nav-link" aria-expanded="true"  class="nav-link w-100" 
          [routerLink]="['/ticket-details']">
          <i class="fa fa-ticket" title="IT Help Desk"></i><span class="nav-label"
            rxText="Label_IT_Help_Desk_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('ticket-details') && !hovering['ticket-details'] }" [title]="getTooltipText('ticket-details')"   (click)="addToFavorites('ticket-details', $event)" (mouseenter)="handleStarIconHover('ticket-details')" (mouseleave)="handleStarIconHover('ticket-details')"></i>
          </a>
       
        </li> -->
<!--  -->
<ng-container *rxAuthorize="[ticketDetails,ItAssets]"
        [rxLocalisationInit]="componentName">
        <li class="nav-item" [class.active]="activeMenu=='it-support'" [class.hasSub]="activeMenu=='it-support'" *ngIf="activeMenu=='it-support' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('it-support', $event)" href="javascript:void(0)"
            aria-expanded="true"><i class="fa fa-ticket" title="People"></i> <span class="nav-label"
              rxText="Label_IT_Help_Support_t"></span> <span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'it-support' && toggleMenu">
            
          <!--  -->
          <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeSubMenu == 'ticket-details'" class="nav-item d-flex align-items-center"
        *rxAuthorize="[ticketDetails]">
        <a (click)="changeActiveSubMenu('ticket-details', $event)" class="nav-link"   class="nav-link w-100" 
          [routerLink]="['/ticket-details']">
          <i  title="IT Help Desk"></i><span class="nav-label"
            rxText="Label_IT_Help_Desk_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('ticket-details') && !hovering['ticket-details'] }" [title]="getTooltipText('ticket-details')"   (click)="addToFavorites('ticket-details', $event)" (mouseenter)="handleStarIconHover('ticket-details')" (mouseleave)="handleStarIconHover('ticket-details')"></i>
          </a>
          <!-- <a class="nav-link" *ngIf="activeMenu == 'ticket-details'">
          </a> -->
        </li>


        <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeSubMenu == 'it-assets'" class="nav-item d-flex align-items-center"
        *rxAuthorize="[ItAssets]">
        <a (click)="changeActiveSubMenu('it-assets', $event)" class="nav-link"  class="nav-link w-100" 
          [routerLink]="['/it-assets/desktop']">
          <i  title="IT Help Desk"></i><span class="nav-label"
            rxText="Label_IT_Assets_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('it-assets') && !hovering['it-assets'] }" [title]="getTooltipText('it-assets')"  (click)="addToFavorites('it-assets', $event, 'it-assets')"  (mouseenter)="handleStarIconHover('it-assets')" (mouseleave)="handleStarIconHover('it-assets')"></i>
          </a>
          <!-- <a class="nav-link" *ngIf="activeMenu == 'ticket-details'">
          </a> -->
        </li>
          <!--  -->

          </ul>
        </li>





        <li class="nav-item" *ngIf="activeMenu!='it-support' || (activeMenu=='it-support' && !toggleMenu)"
          (click)="changeActiveMenu('it-support', $event)">
          <a [rxLocalisationInit]="componentName" href="javascript:void(0)" aria-expanded="true"><i class="fa fa-ticket"
              title="People"></i>
            <span class="nav-label" (click)="changeActiveMenu('it-support',$event)" id="peopleMenuLink"
              rxText="Label_IT_Help_Support_t"></span>
            <span class="fa arrow"></span></a>
        </li>
      </ng-container>
<!--  -->
      <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeMenu=='legal-agreement'" class="nav-item d-flex align-items-center"
        *rxAuthorize="[legalAgreementList]">
        <a (click)="changeActiveMenu('legal-agreement', $event)" class="nav-link"  class="nav-link w-100"  
          [routerLink]="['/legal-agreement']">
          <i class="fr-form" title="Legal Agreements"></i><span class="nav-label"
            rxText="Label_Legal_Agreements_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('legal-agreement') && !hovering['legal-agreement'] }" [title]="getTooltipText('legal-agreement')"   (click)="addToFavorites('legal-agreement', $event)" (mouseenter)="handleStarIconHover('legal-agreement')" (mouseleave)="handleStarIconHover('legal-agreement')"></i>
          </a>
          <!-- <a class="nav-link" *ngIf="activeMenu == 'legal-agreement'">
          </a> -->
        </li>

      <ng-container *rxAuthorize="[userList, newsLetterList, callmeList, internalDirectory]"
        [rxLocalisationInit]="componentName">
        <li class="nav-item" [class.active]="activeMenu=='people'" [class.hasSub]="activeMenu=='people'" *ngIf="activeMenu=='people' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('people', $event)" href="javascript:void(0)"
            aria-expanded="true"><i class="fa fa-users" title="People"></i> <span class="nav-label"
              rxText="Label_People_t"></span> <span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'people' && toggleMenu">
            <li [class.active]="activeSubMenu=='users'" *rxAuthorize="[userList]" class="nav-item d-flex align-items-center" [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('users', $event)" class="nav-link w-100"  [routerLink]="['/users']" ><span class="nav-label" rxText="Label_People_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('users') && !hovering['users'] }" [title]="getTooltipText('users')"   (click)="addToFavorites('users', $event)" (mouseenter)="handleStarIconHover('users')" (mouseleave)="handleStarIconHover('users')"></i>
              </a>
              <!-- <a class="nav-link" *ngIf="activeSubMenu == 'users'">
              </a> -->
          </li>
            <li [class.active]="activeSubMenu=='internal-directory'" class="nav-item d-flex align-items-center" *rxAuthorize="[internalDirectory]"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('internal-directory', $event)"
                [routerLink]="['/internal-directory']" class="nav-link w-100" ><span class="nav-label" rxText="Label_Internal_Directory_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('internal-directory') && !hovering['internal-directory'] }" [title]="getTooltipText('internal-directory')"   (click)="addToFavorites('internal-directory', $event)" (mouseenter)="handleStarIconHover('internal-directory')" (mouseleave)="handleStarIconHover('internal-directory')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'internal-directory'">
                </a> -->
              </li>
            <!-- <li [class.active]="activeSubMenu=='suppliers'" *rxAuthorize="[supplierList]"><a
                (click)="changeActiveSubMenu('suppliers')" [routerLink]="['/suppliers']">Corporates/Groups</a></li> -->
            <li [class.active]="activeSubMenu=='faberge-customer-call-me'" class="nav-item d-flex align-items-center" *rxAuthorize="[callmeList]"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('faberge-customer-call-me', $event)"
                [routerLink]="['/faberge-customer-call-me']" class="nav-link w-100" ><span class="nav-label" rxText="Label_Faberge_Customer_Call_Me_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge-customer-call-me') && !hovering['faberge-customer-call-me'] }" [title]="getTooltipText('faberge-customer-call-me')"   (click)="addToFavorites('faberge-customer-call-me', $event)" (mouseenter)="handleStarIconHover('faberge-customer-call-me')" (mouseleave)="handleStarIconHover('faberge-customer-call-me')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'faberge-customer-call-me'">
                </a> -->
              </li>
            <li [class.active]="activeSubMenu=='newsletter-subscriber'" class="nav-item d-flex align-items-center" *rxAuthorize="[newsLetterList]"
              [rxLocalisationInit]="componentName"><a (click)="changeActiveSubMenu('newsletter-subscriber', $event)"
                [routerLink]="['/newsletter-subscriber']" class="nav-link w-100" ><span class="nav-label" rxText="Label_Newsletter_Subscribers_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('newsletter-subscriber') && !hovering['newsletter-subscriber'] }" [title]="getTooltipText('newsletter-subscriber')"   (click)="addToFavorites('newsletter-subscriber', $event)" (mouseenter)="handleStarIconHover('newsletter-subscriber')" (mouseleave)="handleStarIconHover('newsletter-subscriber')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'newsletter-subscriber'">
                </a> -->
              </li>
          </ul>
        </li>





        <li class="nav-item" *ngIf="activeMenu!='people' || (activeMenu=='people' && !toggleMenu)"
          (click)="changeActiveMenu('people', $event)">
          <a [rxLocalisationInit]="componentName" href="javascript:void(0)" aria-expanded="true"><i class="fa fa-users"
              title="People"></i>
            <span class="nav-label" (click)="changeActiveMenu('people',$event)" id="peopleMenuLink"
              rxText="Label_People_t"></span>
            <span class="fa arrow"></span></a>
        </li>
      </ng-container>

      <li  [rxLocalisationInit]="componentName" [class.active]="activeMenu == 'p-o-s-customer-search'"
        *rxAuthorize="[pos]" class="nav-item d-flex align-items-center">
        <a (click)="changeActiveMenu('p-o-s-customer-search', $event)" class="nav-link w-100" 
          [routerLink]="['/p-o-s-customer-search']"><i class="fr-pos" title="POS"></i><span class="nav-label"
            rxText="Label_POS_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('p-o-s-customer-search') && !hovering['p-o-s-customer-search'] }" [title]="getTooltipText('p-o-s-customer-search')"   (click)="addToFavorites('p-o-s-customer-search', $event)" (mouseenter)="handleStarIconHover('p-o-s-customer-search')" (mouseleave)="handleStarIconHover('p-o-s-customer-search')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'p-o-s-customer-search'">
            </a>  -->
        </li>

      <ng-container *rxAuthorize="[newsList,pressMediaDetailList]" [rxLocalisationInit]="componentName">

        <li class="nav-item" [class.active]="activeMenu=='pressMediaResearch'" [class.hasSub]="activeMenu=='pressMediaResearch'" 
          *ngIf="activeMenu == 'pressMediaResearch' && toggleMenu" (mouseover)="checkHeightForSubMenu()"
          (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()" (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('pressMediaResearch', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)"><i class="fr-news" title="Press/Media/Research"></i><span
              class="nav-label" rxText="Label_Press_Media_Research_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'pressMediaResearch'&& toggleMenu">


            <li [class.active]="activeSubMenu=='press-media-detail'" *rxAuthorize="[pressMediaDetailList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('press-media-detail', $event)" class="nav-link w-100" [routerLink]="['/press-media-detail']"
                href="javascript:void(0)" ><span class="nav-label" rxText="Label_Press_and_Media_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('press-media-detail') && !hovering['press-media-detail'] }"  [title]="getTooltipText('press-media-detail')"  (click)="addToFavorites('press-media-detail', $event)" (mouseenter)="handleStarIconHover('press-media-detail')" (mouseleave)="handleStarIconHover('press-media-detail')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'press-media-detail'">
                </a>  -->
              </li>

            <li [class.active]="activeSubMenu == 'news'" *rxAuthorize="[newsList]" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center">
              <a (click)="changeActiveSubMenu('news', $event)" class="nav-link w-100" [routerLink]="['/news']" href="javascript:void(0)"
                ><span class="nav-label" rxText="Label_PressMedia_News_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('news') && !hovering['news'] }"  [title]="getTooltipText('news')"  (click)="addToFavorites('news', $event)" (mouseenter)="handleStarIconHover('news')" (mouseleave)="handleStarIconHover('news')"></i>
              </a>
              <!-- <a class="nav-link" *ngIf="activeSubMenu == 'news'">
              </a>  -->
          </li>

          </ul>
        </li>
        <li class="nav-item"
          *ngIf="activeMenu != 'pressMediaResearch' || (activeMenu=='pressMediaResearch' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('pressMediaResearch', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)">
            <i class="fr-news" title="Press/Media/Research"></i><span class="nav-label"
              rxText="Label_Press_Media_Research_t"></span>
            <span class="fa arrow"></span></a>
        </li>

      </ng-container>

      <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeMenu == 'projects'"
        *rxAuthorize="[projectList]" class="nav-item d-flex align-items-center">
        <a (click)="changeActiveMenu('projects', $event)" class="nav-link w-100"  [routerLink]="['/projects']"><i
            class="fr-project" title="Projects"></i><span class="nav-label" rxText="Label_Projects_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('projects') && !hovering['projects'] }" [title]="getTooltipText('projects')"   (click)="addToFavorites('projects', $event)" (mouseenter)="handleStarIconHover('projects')" (mouseleave)="handleStarIconHover('projects')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'projects'">
            </a>       -->
      </li>

      <ng-container *rxAuthorize="[missingProductDescriptionList,bespokeOrderReportList,bOMReportList,coreProductListReportList,designProductSuccessReportList,
      financeReportList,gemstoneSetPieceReportList,inventoryLocationSummaryReportList,inventorySummaryReportList,
      locationInventorySummaryByCollectionReportList,missingProductPictureList,pieceInproductionReportList,pieceOwnerReportList,
      priceBandOptionReportList,productCaptionReportList,soldPieceReportList,
      stockByLocationAnalyserReportList,supplierProductionReportList,timePiecesSalesReportList,
      teamLoginReport,repeatCustomerReport,customerStatisticReport,customerLastContactedReport,teamMemberReport,travelReport,leaveReport,itHelpDeskWeeklyReport,
      clientSalesReport,SalesAdvisorReport,SalesAnalysisReport,SalesMarginReport,WholeSaleReport,
      notesReport,hseReport,minesList,gINAReport,repairReportList,inventoryHistoryReportList]"
        [rxLocalisationInit]="componentName">
        <li class="nav-item" [class.active]="activeMenu=='reports'" [class.hasSub]="activeMenu=='reports'" *ngIf="activeMenu == 'reports' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('reports', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i class="fr-reports" title="Reports"></i><span
              class="nav-label" rxText="Label_Reports_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'reports'&& toggleMenu">
            <li [class.active]="activeSubMenu=='faberge-inventory' " class="nav-item d-flex align-items-center"
              *rxAuthorize="[missingProductDescriptionList,bespokeOrderReportList,bOMReportList,coreProductListReportList,designProductSuccessReportList,financeReportList,gemstoneSetPieceReportList,inventoryLocationSummaryReportList,inventorySummaryReportList,locationInventorySummaryByCollectionReportList,missingProductPictureList,pieceInproductionReportList,pieceOwnerReportList,priceBandOptionReportList,productCaptionReportList,soldPieceReportList,stockByLocationAnalyserReportList,supplierProductionReportList,timePiecesSalesReportList, repairReportList,inventoryHistoryReportList]"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('faberge-inventory', $event)" [routerLink]="['/faberge-inventory']"
                href="javascript:void(0)"  class="nav-link w-100"><span class="nav-label" rxText="Label_Faberge_Inventory_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge-inventory') && !hovering['faberge-inventory'] }" [title]="getTooltipText('faberge-inventory')"   (click)="addToFavorites('faberge-inventory', $event)" (mouseenter)="handleStarIconHover('faberge-inventory')" (mouseleave)="handleStarIconHover('faberge-inventory')"></i>
              </a>
            </li>
            <li [class.active]="activeSubMenu=='people'" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center"
              *rxAuthorize="[teamLoginReport,repeatCustomerReport,customerStatisticReport,customerLastContactedReport,teamMemberReport,travelReport,leaveReport,itHelpDeskWeeklyReport]">
              <a (click)="changeActiveSubMenu('people', $event)" [routerLink]="['/people']" href="javascript:void(0)" class="nav-link w-100"
                ><span class="nav-label" rxText="Label_People_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('people') && !hovering['people'] }" [title]="getTooltipText('people')"   (click)="addToFavorites('people', $event)" (mouseenter)="handleStarIconHover('people')" (mouseleave)="handleStarIconHover('people')"></i>
              </a>
            </li>

            <li [class.active]="activeSubMenu=='faberge-sales-orders'" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center"
              *rxAuthorize="[clientSalesReport,SalesAdvisorReport,SalesAnalysisReport,SalesMarginReport,WholeSaleReport]"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('faberge-sales-orders', $event)" [routerLink]="['/faberge-sales-orders']" class="nav-link w-100"
                href="javascript:void(0)" ><span class="nav-label" rxText="Label_Faberge_Sales_Orders_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge-sales-orders') && !hovering['faberge-sales-orders'] }"  [title]="getTooltipText('faberge-sales-orders')" (click)="addToFavorites('faberge-sales-orders', $event)" (mouseenter)="handleStarIconHover('faberge-sales-orders')" (mouseleave)="handleStarIconHover('faberge-sales-orders')"></i>
              </a>
            </li>

            <li [class.active]="activeSubMenu=='note-report'" *rxAuthorize="[notesReport]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('note-report', $event)" [routerLink]="['/note-report']" href="javascript:void(0)"  class="nav-link w-100"
               ><span class="nav-label" rxText="Label_Notes_t"></span>
               <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('note-report') && !hovering['note-report'] }" [title]="getTooltipText('note-report')"  (click)="addToFavorites('note-report', $event)" (mouseenter)="handleStarIconHover('note-report')" (mouseleave)="handleStarIconHover('note-report')"></i>
              </a>
            </li>
            <!--<li [class.active]="activeSubMenu=='gemfields-reports'"><a (click)="changeActiveSubMenu('gemfields-reports')" href="javascript:void(0)">Gemfields Reports</a></li>-->
            <li [class.active]="activeSubMenu=='hse-report'" *rxAuthorize="[hseReport]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('hse-report', $event)" [routerLink]="['/hse-report']" href="javascript:void(0)"
              class="nav-link w-100" ><span class="nav-label" rxText="Label_HSE_GINA_Report_t"></span>
              <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('hse-report') && !hovering['hse-report'] }" [title]="getTooltipText('hse-report')"  (click)="addToFavorites('hse-report', $event)" (mouseenter)="handleStarIconHover('hse-report')" (mouseleave)="handleStarIconHover('hse-report')"></i>
              </a>
            </li>
            <li [class.active]="activeSubMenu=='g-i-n-a-report'" *rxAuthorize="[gINAReport]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('g-i-n-a-report', $event)" [routerLink]="['/g-i-n-a-report']"  class="nav-link w-100"
                href="javascript:void(0)" ><span class="nav-label" rxText="Label_Group_wide_HSE_GINA_Report_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('g-i-n-a-report') && !hovering['g-i-n-a-report'] }" [title]="getTooltipText('g-i-n-a-report')"  (click)="addToFavorites('g-i-n-a-report', $event)" (mouseenter)="handleStarIconHover('g-i-n-a-report')" (mouseleave)="handleStarIconHover('g-i-n-a-report')"></i>
              </a>
            </li>
            <li [class.active]="activeSubMenu=='press-media-report'" *rxAuthorize="[pressMediaReport]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName">
              <a (click)="changeActiveSubMenu('press-media-report', $event)" [routerLink]="['/press-media-report']" class="nav-link w-100"
                href="javascript:void(0)" ><span class="nav-label" rxText="Label_Press_Media_Report_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('press-media-report') && !hovering['press-media-report'] }"  [title]="getTooltipText('press-media-report')" (click)="addToFavorites('press-media-report', $event)" (mouseenter)="handleStarIconHover('press-media-report')" (mouseleave)="handleStarIconHover('press-media-report')"></i>
              </a>
            </li>
            <li [class.active]="activeSubMenu=='mines'" *rxAuthorize="[minesList]" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center">
              <a (click)="changeActiveSubMenu('mines', $event)" [routerLink]="['/mines']" href="javascript:void(0)" 
              class="nav-link w-100"  ><span class="nav-label" rxText="Label_Mining_Performance_t"></span>
              <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('mines') && !hovering['mines'] }"  [title]="getTooltipText('mines')" (click)="addToFavorites('mines', $event)" (mouseenter)="handleStarIconHover('mines')" (mouseleave)="handleStarIconHover('mines')"></i>
            </a>
            </li>

            <ng-container *ngIf="showpowerBIReports">

              <li [class.active]="activeSubMenu=='powerbireports'" [rxLocalisationInit]="componentName">

                <a (click)="changeActiveSubMenu('powerbireports', $event)" class="nav-link"
                aria-expanded="true" href="javascript:void(0)">
                <span class="nav-label" rxText="Label_Gtrac_PowerBI_Reports_t"></span><span class="fa arrow"></span></a>

                <ul [rxLocalisationInit]="componentName" class="nav nav-third-level collapse w-100" [class.in]="activeSubMenu.indexOf('powerbireports')!=-1 && toggleSecondLevelMenu"
                  *ngFor="let menu of powerBIReportMenuList">
                  <li  [class.active]="activeSubSubMenu==menu.powerBIReportName" class="nav-item d-flex align-items-center w-100">

                    <a [rxLocalisationInit]="componentName" [routerLink]="['/powerbireports',menu.powerBIReportId]"
                       class="nav-link " style="width:100%" (click)="changeActiveChildMenu(menu.powerBIReportName, $event)"
                      class=""><span class="" style="flex-grow: 1;">{{menu.powerBIReportName}}</span>
                      <i class="favorites-icon mt-1 fa fa-star fa-lg"  [ngClass]="{'fa-star-o': !isFavorite(menu.powerBIReportName) && !hovering[menu.powerBIReportName] }"  [title]="getTooltipText(menu.powerBIReportName)" (click)="addToFavorites(menu.powerBIReportName, $event, 'powerBI')" (mouseenter)="handleStarIconHover(menu.powerBIReportName)" (mouseleave)="handleStarIconHover(menu.powerBIReportName)"></i>
                    </a>

                  </li>
                </ul>
                
              </li>
            </ng-container>

          </ul>

        </li>

        <li class="nav-item" *ngIf="activeMenu != 'reports' || (activeMenu=='reports' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('reports', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i class="fr-reports" title="Reports"></i><span
              class="nav-label" rxText="Label_Reports_t"></span><span class="fa arrow"></span></a>
        </li>
      </ng-container>

      <li [class.active]="activeMenu=='gemfield-risk-menu'" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center"
        *rxAuthorize="[riskMenu]"><a (click)="changeActiveMenu('gemfield-risk-menu', $event)" class="nav-link w-100"
          [routerLink]="['/gemfield-risk-menu']">
          <i class="fr-form" title="Risk Menu"></i><span class="nav-label" rxText="Label_Risk_Menu_t"></span>
          <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('gemfield-risk-menu') && !hovering['gemfield-risk-menu'] }"  [title]="getTooltipText('gemfield-risk-menu')" (click)="addToFavorites('gemfield-risk-menu', $event)" (mouseenter)="handleStarIconHover('gemfield-risk-menu')" (mouseleave)="handleStarIconHover('gemfield-risk-menu')"></i>
        </a>
        <!-- <a class="nav-link" *ngIf="activeMenu == 'gemfield-risk-menu' ">
          </a> -->
    </li>




      <ng-container *rxAuthorize="[purchaseList,orders]" [rxLocalisationInit]="componentName">
        <li class="nav-item" [class.active]="activeMenu=='sales-order'" [class.hasSub]="activeMenu=='sales-order'" *ngIf="activeMenu=='sales-order' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('sales-order', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i class="fr-order" title="Sales Orders"></i><span
              class="nav-label" rxText="Label_Sales_Orders_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'sales-order'&& toggleMenu">
            <li [class.active]="activeSubMenu=='orders'" *rxAuthorize="[orders]" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center">
              <a (click)="changeActiveSubMenu('orders', $event)" class="nav-link w-100" [routerLink]="['/orders']" href="javascript:void(0)">
              <span class="nav-label" rxText="Label_Faberge_Sales_Orders_t"></span>
              <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('orders') && !hovering['orders'] }" [title]="getTooltipText('orders')"  (click)="addToFavorites('orders', $event)" (mouseenter)="handleStarIconHover('orders')" (mouseleave)="handleStarIconHover('orders')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'orders'">
                </a> -->
            </li>
            <li [class.active]="activeSubMenu=='return-orders'" *rxAuthorize="[returnOrders]" [rxLocalisationInit]="componentName" class="nav-item d-flex align-items-center">
              <a (click)="changeActiveSubMenu('return-orders', $event)" class="nav-link w-100" [routerLink]="['/return-orders']" href="javascript:void(0)">
              <span class="nav-label" rxText="Label_Faberge_Return_Orders_t"></span>
              <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('return-orders') && !hovering['return-orders'] }" [title]="getTooltipText('return-orders')"  (click)="addToFavorites('return-orders', $event)" (mouseenter)="handleStarIconHover('return-orders')" (mouseleave)="handleStarIconHover('return-orders')"></i>
              </a>
            </li>
            <li [class.active]="activeSubMenu=='current-purchase-list'" *rxAuthorize="[purchaseList]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a class="nav-link w-100" (click)="changeActiveSubMenu('current-purchase-list', $event)"
                href="javascript:void(0)" [routerLink]="['/current-purchase-list']">
                <span class="nav-label" rxText="Label_Current_Purchase_Lists_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('current-purchase-list') && !hovering['current-purchase-list'] }"  [title]="getTooltipText('current-purchase-list')" (click)="addToFavorites('current-purchase-list', $event)" (mouseenter)="handleStarIconHover('current-purchase-list')" (mouseleave)="handleStarIconHover('current-purchase-list')"></i>
              </a>
                <!-- <a class="nav-link" *ngIf="activeSubMenu == 'current-purchase-list'">
                </a> -->
              </li>
            </ul>
        </li>

        <li class="nav-item" *ngIf="activeMenu!='sales-order' || (activeMenu=='sales-order' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('sales-order', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i class="fr-order" title="Sales Orders"></i><span
              class="nav-label" rxText="Label_Sales_Orders_t"></span><span class="fa arrow"></span> </a>
             
        </li>

      </ng-container>
      <!-- add on menu for tracking user Start-->
      <ng-container *rxAuthorize="[timelineusertracking,liveusertracking]" [rxLocalisationInit]="componentName">
        <li class="nav-item" [class.active]="activeMenu=='View User Tracking'" [class.hasSub]="activeMenu=='View User Tracking'" *ngIf="activeMenu=='View User Tracking' && toggleMenu"
          (mouseover)="checkHeightForSubMenu()" (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()"
          (touchstart)="checkHeightForSubMenu()">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('View User Tracking', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i class="fa fa-solid fa-street-view" title="View User Tracking"></i><span
              class="nav-label" rxText="Label_View_User_Traking_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu == 'View User Tracking'&& toggleMenu">
              <li [class.active]="activeSubMenu=='live-user-tracking'" *rxAuthorize="[liveusertracking]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a class="nav-link w-100" (click)="changeActiveSubMenu('live-user-tracking', $event)"
                href="javascript:void(0)" [routerLink]="['/live-user-tracking']">
                <span class="nav-label" rxText="Label_Live_User_Tracking_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('live-user-tracking') && !hovering['live-user-tracking'] }"  [title]="getTooltipText('live-user-tracking')" (click)="addToFavorites('live-user-tracking', $event)" (mouseenter)="handleStarIconHover('live-user-tracking')" (mouseleave)="handleStarIconHover('live-user-tracking')"></i>
              </a>
              </li>
              <li [class.active]="activeSubMenu=='timeline-user-tracking'" *rxAuthorize="[timelineusertracking]" class="nav-item d-flex align-items-center"
              [rxLocalisationInit]="componentName"><a class="nav-link w-100" (click)="changeActiveSubMenu('timeline-user-tracking', $event)"
                href="javascript:void(0)" [routerLink]="['/timeline-user-tracking']">
                <span class="nav-label" rxText="Label_Timeline_User_Traking_t"></span>
                <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('timeline-user-tracking') && !hovering['timeline-user-tracking'] }"  [title]="getTooltipText('timeline-user-tracking')" (click)="addToFavorites('timeline-user-tracking', $event)" (mouseenter)="handleStarIconHover('timeline-user-tracking')" (mouseleave)="handleStarIconHover('timeline-user-tracking')"></i>
              </a>
              </li>
            </ul>
        </li>

        <li class="nav-item" *ngIf="activeMenu!='View User Tracking' || (activeMenu=='View User Tracking' && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('View User Tracking', $event)" class="nav-link"
            href="javascript:void(0)" aria-expanded="true"><i style="margin: 0px 14px 0px 0px !important;"  class="fa fa-solid fa-street-view" title="View User Tracking"></i><span
              class="nav-label" rxText="Label_View_User_Traking_t"> </span><span class="fa arrow"></span> </a>
             
        </li>

      </ng-container>
      <!-- add on menu for tracking user end-->
      <ng-container *ngIf="showhelpTraining">
        <li class="nav-item" [class.active]="activeMenu.indexOf('help')!=-1" [class.hasSub]="activeMenu.indexOf('help')!=-1" (mouseover)="checkHeightForSubMenu()"
          (mouseout)="hideSubMenuOnScroll()" (click)="checkHeightForSubMenu()" (touchstart)="checkHeightForSubMenu()"
          *ngIf="activeMenu.indexOf('help')!=-1 && toggleMenu">
          <!-- <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('help', $event)" class="nav-link" aria-expanded="true" [routerLink]="['/help-and-training']"><i class="fr-help"></i><span class="nav-label">Help & Training</span></a>  -->

          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('help', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)"><i class="fr-help" title="Help & Training"></i><span
              class="nav-label" rxText="Label_Help_Training_t"></span><span class="fa arrow"></span></a>
          <ul [rxLocalisationInit]="componentName" class="nav nav-second-level collapse"
            [class.in]="activeMenu.indexOf('help')!=-1 && toggleMenu">
            <ul [rxLocalisationInit]="componentName" class="nav nav-third-level collapse"
              [class.in]="activeMenu.indexOf('help')!=-1 && toggleMenu" *ngFor="let menu of parentMenuList">
              <li *ngIf="menu.parentHelpSupportId == 0" [class.active]="activeSubMenu==menu.name" [class.hasSub]="activeSubMenu==menu.name">

                <a [rxLocalisationInit]="componentName" [routerLink]="['/help-and-training',menu.helpSupportId]"
                  class="nav-link" aria-expanded="true" (click)="changeActiveSubMenu(menu.name, $event)" class=""><span class=""
                    style="flex-grow: 1;">{{menu.name}}</span><span class="fa arrow"></span></a>
                <!--href="/help-and-training/{{menu.helpSupportId}}"-->

                <ul class="nav nav-third-level collapse" *ngFor="let item of childMenuList"
                  [class.in]="activeSubMenu == menu.name && toggleSecondLevelMenu">
                  <li class="nav-item d-flex align-items-center" 
                    *ngIf="item.parentHelpSupportId != 0 && item.parentHelpSupportId == menu.helpSupportId"
                    [class.active]="activeSubSubMenu==item.name">
                    <a [rxLocalisationInit]="componentName" class="nav-link w-100" style="width:100%" (click)="changeActiveChildMenu(item.name, $event)"
                      [routerLink]="['/help-and-training',item.helpSupportId]"> <span class=""
                      style="flex-grow: 1;">{{item.name}}</span>
                        <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite(item.name) && !hovering[item.name] }" [title]="getTooltipText(item.name)"  (click)="addToFavorites(item.name, $event, 'help')" (mouseenter)="handleStarIconHover(item.name)" (mouseleave)="handleStarIconHover(item.name)"></i>
                      </a>
                    <!-- href="/help-and-training/{{item.helpSupportId}}" -->
                  </li>
                </ul>
              </li>
            </ul>
          </ul>
        </li>

        <li class="nav-item" *ngIf="activeMenu.indexOf('help')==-1 || (activeMenu.indexOf('help')==-1 && !toggleMenu)">
          <a [rxLocalisationInit]="componentName" (click)="changeActiveMenu('help', $event)" class="nav-link"
            aria-expanded="true" href="javascript:void(0)"><i class="fr-help" title="Help & Training"></i><span
              class="nav-label" rxText="Label_Help_Training_t"></span><span class="fa arrow"></span></a>
        </li>
      </ng-container>

      <li class="nav-item d-flex align-items-center" [rxLocalisationInit]="componentName" [class.active]="activeMenu == 'faberge'" 
        *rxAuthorize="[fabergeTeam,categories,location,posBrands,pieceOwner,
      customerRelationship,collections,adhocs,designAttributes,
      currencies,titles,salesChannel,boutiques,priceBandOptions,pieceDeliveryStatus,
      productPieceStatus,ringSizes,ringSizeList,dutyStatus,hashtags,
      nationality,industryType,bookingType,chainLength,chainColors,gemstoneStatus,gemstoneCutTypeList,materialList,
      groupLocations,gemstoneOrigins,bangleSizes,
      chainGauge,chainStyle,gemstonetype,bom,seoRedirections,productTypes,trainingTypes,gemfieldsTreatment,watchStatusList,watchItemTypesList,watchMaterialList,watchLegalOwnerList,deliveryTypeList,duplicateCustomerList]">
        <a (click)="changeActiveMenu('faberge', $event)" class="nav-link w-100" 
          [routerLink]="['/faberge']"><i class="fr-f-admin" title="Faberge Admin"></i><span class="nav-label"
            rxText="Label_Faberge_Admin_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('faberge') && !hovering['faberge'] }" [title]="getTooltipText('faberge')"  (click)="addToFavorites('faberge', $event)" (mouseenter)="handleStarIconHover('faberge')" (mouseleave)="handleStarIconHover('faberge')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'faberge' ">
            </a>       -->
          </li>

      <li class="nav-item d-flex align-items-center" [rxLocalisationInit]="componentName" [class.active]="activeMenu == 'gemfield'" 
        *rxAuthorize="[gemfieldTeamList,gemstoneClarityList,gemstoneEnhancementList,
        gemstoneCarrier,gemstoneColorList,locationList,arrangementList,taxDutyList,
        stationList,enhancementTypeList,salesChannelList,bookingStatusList,hseUpdate,ianNotifications,
        riskNotifications,specificRisk,grievanceTier,grievanceType,riskCategory,itINCType,itINCCategory,
        itTicketCompanyCustomer,incidentType,agreementType,goalsNotifications,leavesNotifications,
        corporateCreditRating, adminSetting, legalAgreementAccessPermissions, additionalGroupCompanyUserMappings, personalCompanyCustomerMappings, productionOrderFormUserDepartmentMappings, trackIncidentRawForms,ticketSeverityList,eventServiceTypeList]">
        <a (click)="changeActiveMenu('gemfield', $event)" class="nav-link w-100" 
          [routerLink]="['/gemfield']"><i class="fr-g-admin" title="Gemfields Admin"></i><span class="nav-label"
            rxText="Label_Gemfields_Admin_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('gemfield') && !hovering['gemfield'] }" [title]="getTooltipText('gemfield')"  (click)="addToFavorites('gemfield', $event)" (mouseenter)="handleStarIconHover('gemfield')" (mouseleave)="handleStarIconHover('gemfield')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'gemfield' ">
            </a> -->
          </li>


      <li class="nav-item d-flex align-items-center" [rxLocalisationInit]="componentName" [class.active]="activeMenu == 'system'"
        *rxAuthorize="[globalSettings,messageTemplate,emailAccounts,measureDimensions,
        measureWeights,allSettings,log,systemInfo,moduleLevel,creditCard,paymentMethods,
        discounts,taxProviders,taxClasses,shippingMethods,stateProvinces,languages,
        activityTypes,activityLogs,blacklist,faberegeLocalization,localization,messageQueue,helpSupport,countries,userLockout,
        userRole,leaveTypes,bankHolidays,holidayProcess,departments,formsDocument, systemHelp, regions, fileExtentions, powerBIReportList, applicationObject]" >

        <a (click)="changeActiveMenu('system', $event)" class="nav-link w-100"  [routerLink]="['/system']"><i
            class="fr-system" title="System"></i><span class="nav-label" rxText="Label_System_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('system') && !hovering['system'] }" [title]="getTooltipText('system')"  (click)="addToFavorites('system', $event)" (mouseenter)="handleStarIconHover('system')" (mouseleave)="handleStarIconHover('system')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'system' ">
            </a> -->
      </li>

      <li class="nav-item" [rxLocalisationInit]="componentName" [class.active]="activeMenu=='mind-map'" class="nav-item d-flex align-items-center"
        *rxAuthorize="[mindMap]">
        <a (click)="changeActiveMenu('mind-map', $event)" class="nav-link w-100"  [routerLink]="['/mind-map']">
          <i class="fa fa-code-fork ml-1" title="Mind Map"></i><span class="nav-label"
            rxText="Label_Visual_Mind_Map_t"></span>
            <i class="favorites-icon mt-1 fa fa-star fa-lg" [ngClass]="{'fa-star-o': !isFavorite('mind-map') && !hovering['mind-map'] }" [title]="getTooltipText('mind-map')" (click)="addToFavorites('mind-map', $event)" (mouseenter)="handleStarIconHover('mind-map')" (mouseleave)="handleStarIconHover('mind-map')"></i>
          </a>
            <!-- <a class="nav-link" *ngIf="activeMenu == 'mind-map'" >
            </a> -->
      </li>





      <!-- <li class="nav-item" [class.active]="activeMenu.indexOf('faberge-advanced-search')!=-1">
        <a (click)="changeActiveMenu('faberge-advanced-search', $event)" class="nav-link" aria-expanded="true" [routerLink]="['/faberge-advanced-search']"><i class="fr-pos" title="Advanced Search"></i><span class="nav-label">Advanced Search</span></a>
      </li> -->


    </ul>
  </div>
</nav>
</ng-container>
