import {GemStonePicturesGemFieldBase,GemStoneGemfieldBase,PictureBase,} from '@app/database-models'
//Generated Imports
export class GemStonePicturesGemField extends GemStonePicturesGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfield Prop
gemStoneGemfield : GemStoneGemfieldBase;
//#endregion gemStoneGemfield Prop
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop

//#endregion Generated Reference Properties






































}