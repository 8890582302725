import {GemfieldsArrangementBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    // allowSorting: false,
    configuredTemplate: { templateName: 'action' }, columnIndex: 3, headerTitle: "Action"
})
export class GemfieldsArrangement extends GemfieldsArrangementBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop

//#endregion Generated Reference Properties












}