import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCheckoutStepLookUpBase {

//#region checkoutStepId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'checkoutStepId', keyColumn: true})
        checkoutStepId : number;
//#endregion checkoutStepId Prop


//#region checkoutStepName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'checkoutStepName', keyColumn: false})
        checkoutStepName : string;
//#endregion checkoutStepName Prop

}