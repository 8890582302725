import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxProviderLookUpBase {

//#region taxProviderId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'taxProviderId', keyColumn: true})
        taxProviderId : number;
//#endregion taxProviderId Prop


//#region taxProviderName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'taxProviderName', keyColumn: false})
        taxProviderName : string;
//#endregion taxProviderName Prop

}