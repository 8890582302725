import {ChainColourBase,} from '@app/database-models'
//Generated Imports
export class ChainColour extends ChainColourBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}