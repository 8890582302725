<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/faberge']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/bank-holidays']" rxText="Label_Bank_Holiday_t"></a>
        </li>
        <li class="breadcrumb-item active">Add New</li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="BankHolidayAddComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="holidayListFormGroup"
         [rxSpinner]="spin" (keyup.enter)="addHolidayList(false)">
        <div class="row add-countries-form">
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" 
                rxText="HolidayListAddEditComponent_Name_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="holidayName" class="form-control " 
                  rxPlaceholder="HolidayListAddEditComponent_Name_p" rxFocus />
                <small class="form-text text-danger"
                  [class.d-block]="holidayListFormGroup.controls.holidayName.errors">{{holidayListFormGroup.controls.holidayName.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" 
                rxText="HolidayListAddEditComponent_Year_t"></label>
              <div class="col-md-8">
                <!--<input type="date" class="form-control " formControlName="holidayYear" />-->

                <rx-date showAddon="true" formControlName="holidayYear" placeholder="Select Date"
                  pickerClass="form-control"></rx-date>

                <small class="form-text text-danger"
                  [class.d-block]="holidayListFormGroup.controls.holidayYear.errors">{{holidayListFormGroup.controls.holidayYear.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" 
                rxText="HolidayListAddEditComponent_HolidayType_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <rx-select [(source)]="holidayLookups.holidayTypeLookUp" #rxSelect [selectPlaceholder]="'Select Holiday Type'"
                  formControlName="holidayTypeId" mainClass="form-control"
                  [keyValueProps]="['holidayTypeName','holidayTypeId']"></rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="holidayListFormGroup.controls.holidayTypeId.errors">{{holidayListFormGroup.controls.holidayTypeId.errorMessage}}</small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row">
              <label class="col-md-4 col-form-label" 
                rxText="HolidayListAddEditComponent_Country_t"></label>
              <div class="col-md-8" [rxTagExtended]="_this">
                <rx-tag [(source)]="holidayLookups.countryLookUp" #rxTag [placeholder]="'Select Country'"
                  formControlName="countryIds" mainClass="form-control" [keyValueProps]="['countryName','countryId']"
                  [isSort]="false"></rx-tag>
                <small class="form-text text-danger"
                  [class.d-block]="holidayListFormGroup.controls.countryIds.errors">{{holidayListFormGroup.controls.countryIds.errorMessage}}</small>
                <!--<rx-select [(source)]="holidayLookups.countryLookUp"
                mainClass="form-control" formControlName="statusId"
                [keyValueProps]="['countryName','countryId']"></rx-select>-->

                <small class="form-text text-danger"></small>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6 col-xl-4 pr-xl-5">
            <div class="form-group row check-group">
              <label class="col-md-4 col-form-label" 
                rxText="HolidayListAddEditComponent_Recalculate_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <app-checkbox [tabIndex]="'12'" [checkFormControl]="holidayListFormGroup.controls.recalculateLeave">
                    </app-checkbox>
                  </label>
                </div>
                <small class="form-text text-danger"
                  [class.d-block]="holidayListFormGroup.controls.recalculateLeave.errors">{{holidayListFormGroup.controls.recalculateLeave.errorMessage}}</small>
              </div>
            </div>
          </div> -->
        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="addHolidayList(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="addHolidayList(true)" class="btn btn-primary mr-1" *rxAuthorize="[holidayListEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>