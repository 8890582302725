import {
  Component,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  Input,
  ChangeDetectorRef,
  AfterViewChecked,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { List } from "@rxweb/generics";
import { AbstractNote } from "../domain/abstract-note";
import { Note } from "@app/models";
import { Document } from "@app/models";
import { ModalView } from "../../../../domain/customize-design/modal";
import { NoteAddComponent } from "../add/note-add.component";
import { NoteEditComponent } from "../edit/note-edit.component";
import { ActivatedRoute } from "@angular/router";
import { NotesSpList } from "../../../../models/extended-models/notes-sp-list";
import { AppGrid, DivGrid, AppDivGrid } from "../../../../domain/app-grid";
import { PaginationEnum } from "../../../../enums/pagination.enums";

import { PaginationViewModel } from "../../../../view-model/pagination-view-model";
import { GridColumnConfig } from "@rxweb/grid";

import { multilingual, MultiLingualData } from "@rxweb/localization";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { NotesEnum } from "src/app/enums/notes.enum";

import { RxFormBuilder, IFormGroup } from "@rxweb/reactive-form-validators";
import {
  isSalesRole,
  getLocalizedValue,
} from "src/app/domain/common-logic/common-logic";
import { DomSanitizer } from "@angular/platform-browser";
import { PlatformLocation } from "@angular/common";
import { TinyMCEdraftModel } from "src/app/view-model/tiny-mce-draft-view-model";
import { NotePopUpType } from "src/app/enums/note-popup-type.enum";
import { MoveNoteDocumentModel } from "src/app/models/extended-models/move-note-document-model";
import { MoveNoteDocumentTargetSearchComponent } from "src/app/components/move-note-document-target/move-note-document-target-search/move-note-document-target-search.component";
import { rolesEnum } from "src/app/enums/roles-enum";
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";

@multilingual("NoteListComponent")
@Component({
  selector: "app-note-list",
  templateUrl: "./note-list.component.html",
  styleUrls: ['./note-list.component.css'],
})
export class NoteListComponent
  extends AbstractNote
  implements OnInit, OnDestroy, AfterViewChecked {
  notes: List<Note>;
  subNotes: NotesSpList[];
  subscription: any;
  isShowGridColumn: boolean = false;
  id: number;
  @Input() entityTypeId: number;
  @Input() supplierId: number;
  @Input() companyName: string;
  notesSpList: NotesSpList[];
  notesGrid: AppGrid;
  pageIndex: number = PaginationEnum.PageIndex;
  rowCount: number = PaginationEnum.RowCount;
  paginationViewModel: PaginationViewModel;
  noteType: number = 2;
  isFilter: boolean = false;
  openArray: number[];
  totalRecords: number = 0;
  parser: DOMParser;
  firstPageChange: boolean;
  firstPageSort: boolean;
  sort: boolean;
  noteId:number = 0;
  gridSearchString: string = "";
  isNoteComponent: boolean = false;
  documentUpdateFormGroup: IFormGroup<Document>;
  isSalesTeam: boolean;
  loggedInUserRole: string;
  isShowAll: boolean = false;
  hideShowAll: boolean = false;
  isShowAllExpanded: boolean = false;
  isDraftAvailable:boolean = false;
  isCMSNotes:boolean = false;
  @Input() selectedTab: string = "";
  @Input() moduleID: any = 0;
  @Input() eventProjectRestricted: boolean = false;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<
    GridColumnConfig[]
  >();
  getColumnsData: any;
  arrConfig: any[];
  titleGridColumnSetting: string;
  titleSearch: string;
  childGridArray: any[] = [];
  noticeData: string;
  moveNoteDocumentModel: MoveNoteDocumentModel;
  selectedNotedIds: number[] = [];
  excludedNotedIds: number[] = [];
  isAllowMoveNotes: boolean = false;
  isHeaderBtnChecked: boolean = false;
  isShowMoveNoteOptions: boolean = false;
  @ViewChild('AddNote') AddNoteButton: ElementRef;
  constructor(
    private formBuilder: RxFormBuilder,
    private activatedRoute: ActivatedRoute,
    private browserStorage: BrowserStorage,
    private cdRef: ChangeDetectorRef,
    componentFactoryResolver: ComponentFactoryResolver,
    modelView: ModalView,
    private sanitizer: DomSanitizer,
    private location: PlatformLocation
  ) {
    super();
    this.modalView = modelView;
    this.modalView.setResolver(componentFactoryResolver);
    this.activatedRoute.params.subscribe((t) => {
      this.id = t["id"];
    });

    this.browserStorage = new BrowserStorage();
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
    // this.moduleID = RolePermissionEnums.Event;
    location.onPopState(() => this.dialog.hide());
    this.moveNoteDocumentModel = new MoveNoteDocumentModel();
    this.moveNoteDocumentModel.isDocument = false;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const noteId = params['noteId'];

      if(noteId){

        this.noteId = noteId;
      }

    });
    this.isSalesTeam = isSalesRole();
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.documentUpdateFormGroup = this.formBuilder.formGroup(
      this.bindFormGroup()
    ) as IFormGroup<Document>;
    this.paginationViewModel = new PaginationViewModel();
    this.paginationViewModel.noteType = this.noteType;
    this.paginationViewModel.orderBy = "createdOn";
    this.paginationViewModel.sortOrder = "false";
    this.openArray = new Array<number>();
    this.childGridArray = new Array<any>();
    this.firstPageChange = true;
    this.firstPageSort = true;
    this.isFilter = false;
    this.sort = true;
    this.parser = new DOMParser();
    if(this.entityTypeId == NotesEnum.NewsNotes || this.entityTypeId == NotesEnum.FabergePageNotes
      || this.entityTypeId == NotesEnum.HomePageLayoutNotes || this.entityTypeId == NotesEnum.MenuLayoutNotes || this.entityTypeId == NotesEnum.DesktopAssets
      || this.entityTypeId == NotesEnum.MobileAssets || this.entityTypeId == NotesEnum.ApplicationObject
      || this.entityTypeId == NotesEnum.GemstoneOrderPageNotes){
      this.isCMSNotes = true;
    }
    this.isShowMoveNoteOptions = true;
    if (this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString())) {
      this.isAllowMoveNotes = true;
    }
    if (this.entityTypeId == NotesEnum.ElectronicGiftFormNotes || this.entityTypeId == NotesEnum.ProductionOrderFormNotes
      || this.entityTypeId == NotesEnum.CarnetsNotes || this.entityTypeId == NotesEnum.WatchPartNotes
      || this.entityTypeId == NotesEnum.POSNotes || this.entityTypeId == NotesEnum.BoutiqueNotes 
      || this.entityTypeId == NotesEnum.MainCollectionNotes || this.entityTypeId == NotesEnum.DesignCategoryNotes 
      || this.entityTypeId == NotesEnum.CustomisedPageNotes || this.moduleID == RolePermissionEnums.CallMe) { 
      this.isAllowMoveNotes = false;
    }

    this.get({
      path: "api/SearchCommon/GetGridColumns",
      queryParams: {
        moduleID: encodeURIComponent(JSON.stringify(this.moduleID)),
        childTabs: this.selectedTab,
      },
    }).subscribe(async (t: any) => {
      this.getColumnsData = t;
      if (
        this.getColumnsData.result != null &&
        this.getColumnsData.result != undefined
      ) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
      setTimeout(() => {
        this.getAddnoteDraft();
        this.bindGrid();
      }, 0);

    });
  }

  openNoteReplyModal(noteId: any) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    const queryParams = {
      entityId: this.id,
      entityTypeId: this.entityTypeId,
      isReply: true,
      eventProjectRestricted: this.eventProjectRestricted,
    };
    this.modalView
      .show(NoteEditComponent, {
        ...queryParams,
        id: noteId,
      })
      .then((data) => {
        this.isFilter = true;
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
        this.openArray = [];
        this.bindGrid();
      });
  }
  async getAddnoteDraft(){
    var data: TinyMCEdraftModel = await this.get<TinyMCEdraftModel>({ path: "api/NoteDrafts", queryParams: { EntityId: this.id, EntityTypeId: this.entityTypeId, ModuleId: NotePopUpType.AddNote } }).toPromise();
    if (data != null) {
      this.isDraftAvailable = true;
    }
    else{
      this.isDraftAvailable = false;
    }
  }

  bindFormGroup(): Document {
    let document = new Document();
    document.entityId = this.id;
    document.entityTypeId = this.entityTypeId;
    return document;
  }
  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  showAlert() {
    var existsAlert = getLocalizedValue("Called");
    if (existsAlert) {
      // alert("Called");
      alert(existsAlert);
    }
  }

  addNotes() {
    this.AddNoteButton.nativeElement.blur();
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.modalView
      .show(NoteAddComponent, {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        eventProjectRestricted: this.eventProjectRestricted,
        supplierId:this.supplierId,
        companyName:this.companyName,
      })
      .then((data) => {
        this.isFilter = true;
        this.openArray = [];
        document.getElementsByTagName("body")[0].style.overflow = "auto";
        this.getAddnoteDraft();
        this.bindGrid();
      });
  }
  onEdit(x, note: Note) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.modalView
      .show(NoteEditComponent, {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        id: x.noteId,
        eventProjectRestricted: this.eventProjectRestricted,
        isReply: false,
      })
      .then((data) => {
        this.isFilter = true;
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
        this.openArray = [];
        this.bindGrid();
      });
  }

  onTask(x, note: Note) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.modalView
      .show(NoteEditComponent, {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        id: x.noteId,
        isReply: false,
        isTaskList: true
      })
      .then((data) => {
        this.isFilter = true;
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
        this.openArray = [];
        this.bindGrid();
      });
  }

  onReply(x, note: Note) {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.modalView
      .show(NoteEditComponent, {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        id: x.noteId,
        isReply: true,
        eventProjectRestricted: this.eventProjectRestricted,
      })
      .then((data) => {
        this.isFilter = true;
        document.getElementsByTagName("body")[0].style.overflow = "scroll";
        this.openArray = [];
        this.bindGrid();
      });
  }

  onDelete(note: Note) {
    debugger
    this.dialog
      .show(this.DeleteConfirmationMessage(note), DialogViewMode.okWithCancel)
      .then((t) => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({ body: null, params: [note.noteId] }).subscribe(
            (data) => {
              this.spin = false;
              //this.toastr.success("Data Deleted Successfully")
              var existsAlert = getLocalizedValue("Data_Deleted");
              if (existsAlert) {
                this.toastr.success(existsAlert);
              }
              this.isFilter = true;
              this.firstPageChange = true;
              this.firstPageSort = true;
              this.bindGrid();
            }
          );
        }
        this.dialog.hide();
      });
  }

  DeleteConfirmationMessage(note: Note) {
    if (note.taskCount > 0) {
      var localizationData = MultiLingualData.get(this.componentName) as any;
      var noteAlert = localizationData.NoteListComponent_DeleteNoteTaskWarning_t_t;
      if (noteAlert) {
        return noteAlert;
      }
    } else {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Note");
      if (existsAlert) {
        return existsAlert;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onMoveNoteCheckBoxSelect(t, x) {
    let noteId = parseInt(t.noteId);
    if (x.target.checked) {
      if (this.selectedNotedIds.findIndex(p => p == noteId) == -1) {
        this.selectedNotedIds.push(noteId);
      }
      if (this.excludedNotedIds.findIndex(p => p == noteId) == -1) {
        this.excludedNotedIds.splice(this.excludedNotedIds.findIndex(p => p == noteId), 1);
      }
    }
    else {
      if (this.excludedNotedIds.findIndex(p => p == noteId) == -1) {
        this.excludedNotedIds.push(noteId);
      }
      let index = this.selectedNotedIds.findIndex(p => p == noteId);
      if (index != -1) {
        this.selectedNotedIds.splice(index, 1);
      }
    }
  }

  selectAllNotesToMove() {
    this.spin = true;
    this.isHeaderBtnChecked = !this.isHeaderBtnChecked;
    let cloneArray = new Array<NotesSpList>();
    this.notesSpList.forEach(x => {
      x.isChecked = (x.isManual == true && x.isSubNote == false && this.isHeaderBtnChecked) ? true : false;
      let obj = new NotesSpList();
      obj.isChecked = this.isHeaderBtnChecked;
      obj.noteId = x.noteId;
      obj.subject = x.subject;
      obj.noteDescription = x.noteDescription;
      obj.createdOn = x.createdOn;
      obj.isExpand = x.isExpand;
      obj.isViewByPeople = x.isViewByPeople;
      obj.userName = x.userName;
      obj.isManual = x.isManual;
      obj.isDisplayTeamMember = x.isDisplayTeamMember;
      obj.typeName = x.typeName;
      obj.totalCount = x.totalCount;
      obj.isReply = x.isReply;
      obj.isEdit = x.isEdit;
      obj.isDelete = x.isDelete;
      obj.isTask = x.isTask;
      obj.isSubNote = x.isSubNote;
      obj.isShowAll = x.isShowAll;
      obj.taskCount = x.taskCount;
      cloneArray.push(obj);
    })

    this.notesGrid.updateSource([]);
    this.notesGrid.updateSource(cloneArray);
    this.spin = false;
  }

  moveNotes() {
    if(this.isHeaderBtnChecked){
      let filterJson = JSON.parse(JSON.stringify(this.paginationViewModel));
      filterJson["searchString"] = this.gridSearchString;
      filterJson["isShowAll"] = this.isShowAll;
      filterJson["isShowAllExpanded"] = this.isShowAllExpanded;
      filterJson["sortOrder"] = "false";
      filterJson["rowCount"] = this.totalRecords;
      filterJson["pageIndex"] = 1;
      let query = JSON.stringify(filterJson)
      this.moveNoteDocumentModel.query = query;
    }
    this.moveNoteDocumentModel.selectedIds = this.selectedNotedIds;
    this.moveNoteDocumentModel.excludedIds = this.excludedNotedIds;
    this.moveNoteDocumentModel.currentEntityId = this.id;
    this.moveNoteDocumentModel.currentEntityTypeId = this.entityTypeId;
    this.moveNoteDocumentModel.currentModuleId = this.moduleID;
    this.moveNoteDocumentModel.isHeaderBtnChecked = this.isHeaderBtnChecked;
    if (this.selectedNotedIds.length > 0 || (this.isHeaderBtnChecked && this.excludedNotedIds.length < this.totalRecords)) {
      this.modalView.show(MoveNoteDocumentTargetSearchComponent, { moveNoteDocumentModel : this.moveNoteDocumentModel }).then(t => {
        if(t){
          this.isFilter = true;
          this.selectedNotedIds = [];
          this.excludedNotedIds = [];
          this.isHeaderBtnChecked = false;
          this.bindGrid();
        }
      });
    }
  }

  bindGrid() {
    this.notesSpList = new Array<NotesSpList>();
    this.totalRecords = 0;
    this.spin = true;

    var json = JSON.parse(JSON.stringify(this.paginationViewModel));
    json["searchString"] = this.gridSearchString;
    json["isShowAll"] = this.isShowAll;
    json["isShowAllExpanded"] = this.isShowAllExpanded;

    this.get({
      queryParams: {
        query: encodeURIComponent(JSON.stringify(json)),
        userId: 0,
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        pageIndex: this.pageIndex,
        rowCount: this.rowCount,
        flag: this.isSalesTeam,
        timezoneOffset: new Date().getTimezoneOffset(),
        noteId: this.noteId,
      },
    }).subscribe((t: any) => {
      this.spin = false;
      // this.ngAfterViewChecked();
      this.notesSpList = t;
      if (this.notesSpList.length > 0) {
        this.isShowGrid = true;
        this.totalRecords = this.notesSpList[0].totalCount;
        this.isNoRecordFound = false;
        this.notesSpList.forEach(x => {
          x.isChecked = false;
          if(this.isHeaderBtnChecked){
            if (this.excludedNotedIds.findIndex(p => p == x.noteId) == -1) {
              x.isChecked = true;
            }
          }
          else{
            if (this.selectedNotedIds.findIndex(p => p == x.noteId) != -1) {
              x.isChecked = true;
            }
          }
        });
      } else {
        this.isShowGrid = false;
        this.isNoRecordFound = true;
      }
      if (!this.isFilter) {
        this.notesGrid = new AppDivGrid(this.notesSpList, NotesSpList, {
          actions: {
            onMoreClick: this.onMoreClick.bind(this),
            onDocClick: this.onDocumentDownloadLink.bind(this),
            onNoteDelete: this.onDelete.bind(this),
            onNoteEdit: this.onEdit.bind(this),
            onReply: this.onReply.bind(this),
            onNoteExpand: this.getSubNote.bind(this),
            onNoteTask: this.onTask.bind(this),
            onMoveNoteCheckBoxSelect: this.onMoveNoteCheckBoxSelect.bind(this)
          },
        });
        if(this.noteId > 0  ) {
          var notesData = this.notesSpList.find(x=>x.noteId==this.noteId);
          if(notesData!=undefined){

            if( notesData.isReply.toString() == "true" && !notesData.isSubNote){

              this.openNoteReplyModal(this.noteId);
            }
          }
        }else if(this.noteId  == -1){
            this.addNotes();
        }
        if (this.noteType != 1 || this.isShowAllExpanded == true || this.isAllowMoveNotes == false) {
          this.notesGrid.gridColumns.forEach((y) => {
            if (y.name == 'isChecked') {
              y.visible = false
            }
          });
        }
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach((x) => {
            this.notesGrid.gridColumns.forEach((y) => {
              if (y.name == x.ColumnName) y.visible = x.Visible;
            });
          });
          this.gridColumnConfig = this.notesGrid.gridColumns;
          this.gridColumnSettings.emit(this.gridColumnConfig);
        }

        this.notesGrid.storeProcedure = {
          length: this.totalRecords,
          onPageChanging: this.onPageChanging.bind(this),
          nextPage: 1,
          onPageSorting: this.onPageSorting.bind(this),
        };
        setTimeout(() => {
          this.notesGrid.source.forEach((x) => {
            if (x.isSubNote) {
              (
                this.notesGrid.getRowElement(x.noteId) as HTMLDivElement
              ).style.backgroundColor = "#b6bbbfa8";
            }
          });
        }, 200)
        this.notesGrid.gridColumns.forEach((z) => {
          if (z.name == "isViewByPeople") {
            z.visible =
              this.entityTypeId == NotesEnum.OrderNotes ? true : false;
          }
          if (z.name == "isDelete") {
            z.visible = this.loggedInUserRole == "1" ? true : false;
          }
          if (z.name == "isEdit") {
            z.visible = !this.isCMSNotes ? true : false;
          }
          if (z.name == "isTask") {
            z.visible = !this.isCMSNotes ? true : false;
          }
          if (z.name == "isReply") {
            z.visible = !this.isCMSNotes ? true : false;
          }
        });
        this.notesGrid.designClass.tableClass =
          "div-table div-table-striped div-table-hover table".split(" ");
        this.notesGrid.designClass.rowClass = "div-tr pb-1".split(" ");
        this.notesGrid.componentId = this.componentName;
        this.isFilter = true;
      } else {
        if (this.notesGrid != null) {
          this.notesGrid.storeProcedure.length = this.totalRecords;
          setTimeout(() => {
            this.notesGrid.updateSource([]);
            this.notesGrid.updateSource(this.notesSpList);
            this.notesGrid.source.forEach((x) => {
              if (x.isSubNote) {
                (
                  this.notesGrid.getRowElement(x.noteId) as HTMLDivElement
                ).style.backgroundColor = "#b6bbbfa8";
              }
            });
          }, 0)
        }
        this.notesGrid.designClass.tableClass =
          "div-table div-table-striped div-table-hover table".split(" ");
      }
    });
    this.noteData();
  }
  noteData() {
    setTimeout(() => {
      var localizationData = MultiLingualData.get(this.componentName) as any;
      this.noticeData = localizationData.NoteListComponent_Notice_gn_gn;
    }, 500);
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  onMoreClick(t, x) {
    var element = document.getElementById("note_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn";
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    } else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  onShowHideAllClick(showAllButtonValue) {
    this.isShowAll = showAllButtonValue;
    if (this.notesGrid != null && this.isShowGrid) {
      this.notesGrid.destroy();
    }
    this.isFilter = false;
    this.bindGrid();
  }

  onShowHideAllExpandedClick(showAllExpandedButtonValue) {
    this.isShowMoveNoteOptions = !showAllExpandedButtonValue
    this.isShowAllExpanded = showAllExpandedButtonValue;
    if (this.isShowAllExpanded){
      this.hideShowAll = true;
      this.paginationViewModel.sortOrder = "True";
    }else{
      this.hideShowAll = false;
      this.paginationViewModel.pageIndex = PaginationEnum.PageIndex;
      this.paginationViewModel.rowCount = PaginationEnum.RowCount;
      this.paginationViewModel.orderBy = "createdOn";
      this.paginationViewModel.sortOrder = "false";
    }
    if (this.notesGrid != null) {
      this.notesGrid.destroy();
    }
    this.isFilter = false;
    this.noteId = 0;
    this.bindGrid();
  }

  onDocumentDownloadLink(x) {
    //this.isNoteComponent=true;
    this.spin = true;    
    this.documentUpdateFormGroup.patchValue({
      physicalFileName: x.physicalFileName,
      documentId: x.documentId,
      entityId: this.id,
      entityTypeId: this.entityTypeId,
      isNoteComponent: true,
      ModuleUrl:window.location.href
    });
    this.put({
      path: "api/Document",
      params: [this.id],  
      body: this.documentUpdateFormGroup.toFormData(),
    }).subscribe((data: any) => {
      this.spin = false;
    });
    // window.open(`/download-content?did=${x.documentId}&t=${'document'}`, "_blank");
  }
  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.paginationViewModel.sortOrder = y;
    this.paginationViewModel.orderBy = x;
    this.notesGrid.storeProcedure.nextPage = z;
    this.openArray = [];
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.paginationViewModel.pageIndex = x;
    this.paginationViewModel.rowCount = this.notesGrid.maxPerPage;
    this.notesGrid.storeProcedure.nextPage = x;
    this.bindGrid();
  }
  filterNotes(noteType: number) {
    if (this.notesGrid != null && this.isShowGrid) {
      this.notesGrid.destroy();
    }
    this.isFilter = false;
    this.noteType = noteType;
    this.childGridArray = [];
    this.openArray = [];
    this.paginationViewModel.noteType = noteType;
    this.noteId=0;
    this.bindGrid();
  }
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.notesGrid.source.forEach((x) => {
      this.notesGrid.removeChildGrid(x.noteId);
    });
    this.childGridArray = [];
    this.openArray = [];
    this.notesGrid.reDesign();
    this.notesGrid.updateSource([]);
    this.bindGrid();
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  search(value) {
    setTimeout(() => {
      this.spin = false;
      this.isFilter = true;
      this.paginationViewModel.pageIndex = 1;
      if (this.notesGrid != null) {
        this.notesGrid.storeProcedure.nextPage = 1;
      }
      this.gridSearchString = value.target.value
        .replace(/^\s+|\s+$/gm, "")
      //this.gridSearchString = encodeURIComponent(this.gridSearchString);
      this.bindGrid();
    }, 1000);
    this.spin = true;
  }
  getSubNote(x, y, z) {
    var element = this.notesGrid.getRowElement(x.noteId);
    var noteId = x.noteId;

    if (!this.isShowAll) {
      if (this.openArray.findIndex((t) => t == x.noteId) != -1) {
        //y.srcElement.className = "fa fa-plus fa-4x font-md text-default";
        y.srcElement.className =
          y.srcElement.tagName.toLowerCase() == "i"
            ? "fa fa-plus fa-4x font-md text-default"
            : y.srcElement.className;
        y.srcElement.setAttribute(
          "title",
          y.srcElement.tagName.toLowerCase() == "i"
            ? getLocalizedValue("Title_Expand")
            : getLocalizedValue("Title_Collapse")
        );
        this.openArray.splice(
          this.openArray.findIndex((t) => t == x.noteId),
          1
        );
        this.childGridArray.splice(
          this.childGridArray.findIndex((t) => t.id == x.noteId),
          1
        );
        element.removeChild(element.lastChild);
      } else {
        this.spin = true;
        this.post({
          path: "api/SearchEvent/SubNotes",
          body: {
            userId: 0,
            entityId: this.id,
            entityTypeId: this.entityTypeId,
            noteId: noteId,
            subject: x.subject,
            hashTags: x.typeName,
            timezoneOffset: new Date().getTimezoneOffset(),
          },
        }).subscribe((data: any) => {
          this.spin = false;
          this.subNotes = data;

          y.srcElement.className =
            y.srcElement.tagName.toLowerCase() == "i"
              ? "fa fa-minus fa-4x font-md text-default"
              : y.srcElement.className;
          y.srcElement.setAttribute(
            "title",
            y.srcElement.tagName.toLowerCase() == "i"
              ? getLocalizedValue("Title_Collapse")
              : getLocalizedValue("Title_Expand")
          );
          if (element) {
            let childGrid = new DivGrid(this.subNotes, NotesSpList, {
              actions: {
                onMoreClick: this.onMoreClick.bind(this),
                onDocClick: this.onDocumentDownloadLink.bind(this),
                onNoteDelete: this.onDelete.bind(this),
                onNoteEdit: this.onEdit.bind(this),
                onReply: this.onReply.bind(this),
                onNoteExpand: this.getSubNote.bind(this),
                onNoteTask: this.onTask.bind(this),
              },
            });

            childGrid.maxPerPage = 1000000;
            let columnIndexes: string[];
            columnIndexes = new Array<string>();
            this.notesGrid.gridColumns.forEach((x) => {
              if (x.visible == false) {
                columnIndexes.push(x.name);
              }
            });
            childGrid.gridColumns.forEach((x) => {
              columnIndexes.forEach((t) => {
                if (t == x.name) {
                  x.visible = false;
                }
                if (x.name == "isViewByPeople") {
                  x.visible =
                    this.entityTypeId == NotesEnum.OrderNotes ? true : false;
                }
              });
            });
            childGrid.gridColumns.forEach((x) => {
              if (x.columnIndex == 2) {
                x.isAscending = false;
              }
            });
            this.notesGrid.addChildGrid(x.noteId, childGrid);
            element.lastElementChild.className = "div-tr";
            childGrid.designClass.tableClass =
              "div-table div-table-striped div-table-hover table".split(" ");

            childGrid.designClass.rowClass = "div-tr".split(' ');
            this.openArray.push(x.noteId);
            if (childGrid != null) {
              let index: number;
              childGrid.source.forEach((x) => {
                (
                  childGrid.getRowElement(x.noteId) as HTMLDivElement
                ).style.backgroundColor = "#b6bbbfa8";
                (
                  childGrid.getRowElement(x.noteId) as HTMLDivElement
                ).style.paddingBottom = "10px";
                index = childGrid.getRowElement(x.noteId).children.length;

              });
            }
            var isExist = false;
            this.childGridArray.forEach((element) => {
              if (element.id == x.noteId) {
                isExist = true;
                return;
              }
            });
            if (!isExist) {
              this.childGridArray.push({ id: x.noteId, grid: childGrid });
            }
          }
        });
      }
    } else {
      this.notesGrid.source.forEach((i) => {
        if (
          x.typeName.trim().toLowerCase() == i.typeName.trim().toLowerCase() &&
          x.subject.trim().toLowerCase() == i.subject.trim().toLowerCase() &&
          i.isSubNote
        ) {
          if (
            (this.notesGrid.getRowElement(i.noteId) as HTMLDivElement).style
              .display == "none"
          ) {
            (
              this.notesGrid.getRowElement(i.noteId) as HTMLDivElement
            ).style.display = "flex";
            y.srcElement.className =
              y.srcElement.tagName.toLowerCase() == "i"
                ? "fa fa-minus fa-4x font-md text-default"
                : y.srcElement.className;
            y.srcElement.setAttribute(
              "title",
              getLocalizedValue("Title_Collapse")
            );
          } else {
            (
              this.notesGrid.getRowElement(i.noteId) as HTMLDivElement
            ).style.display = "none";
            y.srcElement.className =
              y.srcElement.tagName.toLowerCase() == "i"
                ? "fa fa-plus fa-4x font-md text-default"
                : y.srcElement.className;
            y.srcElement.setAttribute(
              "title",
              getLocalizedValue("Title_Expand")
            );
          }
        }
      });
    }
  }
  get componentName(): string {
    return "NoteListComponent";
  }
}



