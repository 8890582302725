import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LocationGroupWarehouseMappingBase {

//#region locationGroupWarehouseMappingId Prop
        @prop()
        locationGroupWarehouseMappingId : number;
//#endregion locationGroupWarehouseMappingId Prop


//#region locationGroupId Prop
        @prop()
        locationGroupId : number;
//#endregion locationGroupId Prop


//#region warehouseId Prop
        @prop()
        warehouseId : number;
//#endregion warehouseId Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}