import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ShippingMethodBase {

//#region shippingMethodID Prop
        @prop()
        shippingMethodID : number;
//#endregion shippingMethodID Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region description Prop
        @required()
        @maxLength({value:2000})
        @trim()
        @notEmpty()
        description : string;
//#endregion description Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}