import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDesignProductStatusBase {

//#region designProductStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'designProductStatusId', keyColumn: true})
        designProductStatusId : number;
//#endregion designProductStatusId Prop


//#region designProductStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'designProductStatusName', keyColumn: false})
        designProductStatusName : string;
//#endregion designProductStatusName Prop

}