import { prop } from '@rxweb/reactive-form-validators';
import { gridColumn } from '@rxweb/grid';

export class  SummaryViewModel{

    @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'eventId', keyColumn: true })
    eventId:number
    
    @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'roleId', keyColumn: true })
    roleId:number

    @gridColumn({style: { width: "16%", "text-align": "initial" }, class: ["text-left"],configuredTemplate: { templateName: 'introducedLink' }, visible: true, parameter:false,columnIndex: 6, allowSorting: true, headerKey: 'EventSummaryListComponent_IntroBy_gh', keyColumn: false })
    introducedby:number
    @gridColumn({style: { width: "16%", "text-align": "initial" }, class: ["text-left"],configuredTemplate: { templateName: 'attendedLink' },visible: true, parameter:false,columnIndex: 5, allowSorting: true, headerKey: 'EventSummaryListComponent_Attended_gh', keyColumn: false })
    attended:number
    @gridColumn({style: { width: "16%", "text-align": "initial" }, class: ["text-left"],configuredTemplate: { templateName: 'declinedLink' },visible: true,parameter:false, columnIndex: 3, allowSorting: true, headerKey: 'EventSummaryListComponent_Declined_gh', keyColumn: false })
    declined:number
    @gridColumn({style: { width: "16%", "text-align": "initial" }, class: ["text-left"],configuredTemplate: { templateName: 'acceptedLink' },visible: true, parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'EventSummaryListComponent_Accepted_gh', keyColumn: false })
    accepted:number
    @gridColumn({style: { width: "16%", "text-align": "initial" }, class: ["text-left"],configuredTemplate: { templateName: 'invitedLink' },  visible: true,parameter:false, columnIndex: 2, allowSorting: true, headerKey: 'EventSummaryListComponent_Invited_gh', keyColumn: false })
    invited:number
    @gridColumn({isAscending:true,style: { width: "20%", "text-align": "initial" }, class: ["text-left"],  visible: true,parameter:false, columnIndex: 1, allowSorting: true, headerKey: 'EventSummaryListComponent_Name_gh', keyColumn: false })
    name:string

    totalCount:number

    AddedBy:number
}
