import { maxLength, prop } from "@rxweb/reactive-form-validators";

export class LeaveVehicleMappingBase
{

    //#region LFM_MapppingID Prop
    @prop()
    lvM_MapppingID : number;
    //#endregion LFM_MapppingID Prop


    //#region masterId Prop
    //@range({minimumNumber:0,maximumNumber:2147483647})
    //@required()
    @prop()
    masterId : number;
    //#endregion masterId Prop


    //#region destinationFrom Prop
    @prop()
    destinationFrom : string;
    //#endregion destinationFrom Prop


     //#region destinationTo Prop
     @prop()
     destinationTo : string;
     //#endregion destinationTo Prop


     @prop()
     dateFrom :Date; 
 
     @prop()
     dateTo :Date; 

    @prop()
    exitOrReturn :number; 
    
}

