"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Observable = void 0;
var Observable = (function () {
    function Observable(subscribe) {
        this.subscriber = subscribe;
    }
    Observable.prototype.pipe = function () {
        var operations = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            operations[_i] = arguments[_i];
        }
        return operations.reduce(function (prev, fn) { return fn(prev); }, this);
    };
    Observable.prototype.subscribe = function (observer) {
        var defaultObserver = {
            next: function () {
            },
            error: function () {
            },
            complete: function () {
            },
        };
        if (typeof observer === 'function') {
            return this.subscriber(__assign(__assign({}, defaultObserver), { next: observer }));
        }
        else {
            return this.subscriber(__assign(__assign({}, defaultObserver), observer));
        }
    };
    return Observable;
}());
exports.Observable = Observable;
