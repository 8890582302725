import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { DocumentType } from "@app/models";
@http({
    path: "api/DocumentType",
})
export class AbstractDocumentType extends CoreComponent {  
  selectedTab: string = "document-type-info";
  tabChange: boolean = false;
    showComponent: boolean = false;
    spin: boolean = false;
    modalView: ModalView;
    toastr: BaseToastr;
    dialog: BaseDialog;
    documentTypeFormGroup: IFormGroup<DocumentType>

	
	constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
     }
	
}
