import { TemplateConfig } from '@rxweb/grid';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { StatusEnum } from 'src/app/enums/status.enum';

  export const ACTIVE_FORMER_BIND: TemplateConfig = {
    select:
    {
      class: ["checkbox-custom-style","form-control"],
      event: {
        input: "onGridSelectChange"
      },
      style:{
        "margin-top":"-6px",
        "width":"auto"
      },
      attributes: {
        value: function (e) {
          return this.value;
        },
        selected: function (e) {
        var data  = this[e.name];
          return data;
        }
      },
      childrens: [
        {
          option: {
            attributes: {
              value: function (e) {
                return this.value;
              },
              selected: function (e) {
                if(this.parentObject.status=="Active"){
                  
                 return this.parentObject.status && this.value=="Active"
                }
                else{
                  return this.parentObject.status && this.value=="Former"
                }
            }
            },
            
            childrens: [{
              text: {
                text: function (e) { return this.text }
              }
            }]
          }
        }
      ],
      sourceItems: ActiveFormer(),
    }
  }

             

  
  export function ActiveFormer() {
    return [{ value: "Active", text: "Active" }, { value: "Former", text: "Former" }]
  }


  export const ACTIVE_FORMER_EMPLOYMENT_BIND: TemplateConfig = {
    div:{
      style:{"text-align":"center"},
 
      childrens:[
        {
          select:
          {
            isBind: (x) => {
              if ((x.directorship && x.companyCustomerMappingId == x.directorMappingId ) || x.ownerMappingId > 0) {
                return false;
            }
            else {
                return true;
            }

            },
            class: ["checkbox-custom-style","form-control","disabled"],
            event: {
              input: "onGridSelectChange"
            },
            style:{
              "margin-top":"-6px",
              "width":"auto"
            },
            attributes: {
              value: function (e) {
                return this.value;
              },
              id: function (e) {
                return "employement-select-" + this.companyCustomerMappingId;
              },
              selected: function (e) {
              var data  = this[e.name];
                return data;
              }
              // ,
              // disabled: function (e) {
              //   return (
              //     (this.endDate != null && new Date(this.endDate) < new Date(new Date().setHours(0,0,0,0)))
              //     || (this.startDate != null && new Date(this.startDate) > new Date(new Date().setHours(0,0,0,0)))
              //   );
              // },
            },
            childrens: [
              {
                option: {
                  attributes: {
                    value: function (e) {
                      return this.value;
                    },
                    selected: function (e) {
                      if (this.parentObject.status == "Active") {

                        return this.parentObject.status && this.value == "Active"
                      }
                      else {
                        return this.parentObject.status && this.value == "Former"
                      }
                  }
                  },
                  
                  childrens: [{
                    text: {
                      text: function (e) { return this.text }
                    }
                  }]
                }
              }
            ],
            sourceItems: ActiveFormer(),
          }
        }
        // {
        //   span:{
        //     isBind: (x) => {
        //       if (x.roleTypeId == CustomerRoleTypeEnum.Admin && x.ownerMappingId == 0  && !(x.ownerMappingId > 0 || (x.directorship && x.companyCustomerMappingId == x.directorMappingId))) {
        //           return true;
        //       }
        //       else {
        //           return false;
        //       }
        //     },
        //     childrens: [{
        //       text: {
        //         text: function (e) { return this.statusId == StatusEnum.Active ? 'Active': 'Former' }
        //       }
        //     }],
        //   }
        // }
        
      ]
    }
  }
  // export const ACTIVE_FORMER_EMPLOYMENT_BIND: TemplateConfig = {
  //   div: {
  //     isBind: (x) => {
  //       return x.ownerMappingId > 0 ? false : true
  //     },
  //     childrens: [
  //       {
  //         label: {
  //           childrens: [
  //             {
  //               input:
  //               {
  //                 class: ["checkbox-custom-style"],
  
  //                 event: {
  //                   input: "onGridSelectChange"
  //                 },
  //                 attributes: {
  //                   type: "checkbox",
  //                   value: function (e) {
  //                     return this[e.name];
  //                   },
  //                   checked: function (e) {
  //                     return this[e.name];
  //                   },
  //                 }
  //               }
  //             }
  //           ],
  //           style: {
  //             "margin-top":"-6px",
  //             "width":"auto"
  //           }
  //         }
  //       }
  //     ]
  //   },
  // }
