import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ModalView } from "src/app/domain/customize-design/modal";
import { multilingual } from '@rxweb/localization';
import { dateCompareValidation, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";
import { MoveNoteDocumentFilterViewModel } from "src/app/view-model/move-note-document-filters-view-model";
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";
import { AbstractMoveNoteDocumentTarget } from "../domain/abstract-move-note-document-target";
import { AppGrid } from "src/app/domain/app-grid";
import { MoveNoteDocumentUserSearchViewModel } from "src/app/view-model/move-note-document-user-search-view-model";
import { MoveNoteDocumentModel } from "src/app/models/extended-models/move-note-document-model";
import { NotesEnum } from "src/app/enums/notes.enum";
import { MoveNoteDocumentCorporateSearchViewModel } from "src/app/view-model/move-note-document-corporate-search-view-model";
import { MoveNoteDocumentEventSearchViewModel } from "src/app/view-model/move-note-document-event-search-view-model";
import { MoveNoteDocumentProjectSearchViewModel } from "src/app/view-model/move-note-document-project-search-view-model";
import { MoveNoteDocumentOrderSearchViewModel } from "src/app/view-model/move-note-document-order-search-view-model";
import { MoveNoteDocumentPressMediaSearchViewModel } from "src/app/view-model/move-note-document-press-media-search-view-model";
import { MoveNoteDocumentLegalAgreementSearchViewModel } from "src/app/view-model/move-note-document-lagal-agreement-search-view-model";
import { MoveNoteDocumentHSESearchViewModel } from "src/app/view-model/move-note-document-hse-search-view-model";
import { MoveNoteDocumentRiskSearchViewModel } from "src/app/view-model/move-note-document-risk-search-view-model";
import { I_T_HELP_DESK_LOOKUPS } from "src/app/uris/i-t-help-desk-lookups.uri";
import { CLIENT_URL } from "src/app/domain/system-constant";
import { MoveNoteDocumentITHelpDeskSearchViewModel } from "src/app/view-model/move-note-document-it-help-desk-search-view-model";
import { MoveNoteDocumentPieceSearchViewModel } from "src/app/view-model/move-note-document-piece-search-view-model";
import { MoveNoteDocumentProductSearchViewModel } from "src/app/view-model/move-note-document-product-search-view-model";
import { MoveNoteDocumentGemstoneSearchViewModel } from "src/app/view-model/move-note-document-gemstone-search-view-model";
import { MoveNoteDocumentBookingSearchViewModel } from "src/app/view-model/move-note-document-booking-search-view-model";
import { MoveNoteDocumentDesignSearchViewModel } from "src/app/view-model/move-note-document-design-search-view-model";
import { MoveNoteDocumentPriceListSearchViewModel } from "src/app/view-model/move-note-document-pricelist-search-view-model";
import { MoveNoteDocumentBatchSearchViewModel } from "src/app/view-model/move-note-document-batch-search-view-model";

@multilingual("MoveNoteDocumentTargetSearchComponent")
@Component({
  selector: "app-move-note-document-target-search",
  templateUrl: './move-note-document-target-search.component.html'
})
export class MoveNoteDocumentTargetSearchComponent extends AbstractMoveNoteDocumentTarget implements OnInit, OnDestroy {
  subscription: any;
  @Input() hide: Function;
  @Input() moveNoteDocumentModel: MoveNoteDocumentModel
  titleClose: string;
  _this;
  textSearchCount: number = 3;
  isShowControl: boolean = false;
  searchName: string;
  gridParameter: any
  isNoRecordFound: boolean = false;
  isShowGrid: boolean = false;
  isFilter: boolean = false;
  disabledMoveNotebtn: boolean = false;
  totalRecords: number = 0;
  moveNoteDocumentSearchResult: any;
  userSearchGrid: AppGrid;
  corporateSearchGrid: AppGrid;
  eventSearchGrid: AppGrid;
  projectSearchGrid: AppGrid;
  orderSearchGrid: AppGrid;
  pressMediaSearchGrid: AppGrid;
  legalAgreementSearchGrid: AppGrid;
  hseSearchGrid: AppGrid;
  riskSearchGrid: AppGrid;
  itHelpDeskSearchGrid: AppGrid;
  piecesSearchGrid: AppGrid;
  bookingsSearchGrid: AppGrid;
  priceListSearchGrid: AppGrid;
  designSearchGrid: AppGrid;
  gemstoneSearchGrid: AppGrid;
  productSearchGrid: AppGrid;
  batchesSearchGrid: AppGrid;
  localFormGroupJson: MoveNoteDocumentFilterViewModel;
  eventDateError: boolean = false;
  projectDateError: boolean = false;
  expiryDateError: boolean = false;
  dateError: boolean = false;
  selectedCompany: number = 0;
  selectedRiskCategoryId: number = 0;
  bookingDateError: boolean = false;

  constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private location: PlatformLocation) {
    super();
    this._this = this;
    location.onPopState(() => { this.hide(), this.dialog.hide() });
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.spin = true;
    this.lookup([
      { propName: "entityTypesLookup", path: 'api/SearchEvent/GetMoveNoteDocumentTargetModules' },
      { propName: "salesAdvisorLookUp", path: USER_LOOKUPS.salesUserLookup },
      { propName: "countryLookUp", path: USER_LOOKUPS.countryLookUp },
      { propName: "keyRelationshipHolderLookUp", path: USER_LOOKUPS.keyRelationshipHolderLookUp },
      { propName: "industryTypeLookUp", path: USER_LOOKUPS.industryTypeLookUp },
      { propName: "supplierRoleLookUp", path: USER_LOOKUPS.supplierRoleLookUp },
      { propName: "eventOwnerLookUp", path: USER_LOOKUPS.eventOwnerLookUp },
      { propName: "eventTypeLookUp", path: USER_LOOKUPS.eventTypeLookUp },
      { propName: "projectOwnerLookUp", path: USER_LOOKUPS.projectOwnerLookUp },
      { propName: 'eventLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'event' } },
      { propName: 'projectLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'project' } },
      { propName: 'publicationLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'publication' } },
      { propName: 'journalistLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'journalist' } },
      { propName: 'peopleLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'people' } },
      { propName: 'corporateLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'corporate' } },
      { propName: 'hashtagLookup', path: USER_LOOKUPS.getPressMediaLookup, queryParams: { "query": 'hashtag' } },
      { propName: "agreementTypeLookUp", path: USER_LOOKUPS["agreementTypeLookUp"] },
      { propName: "gglEmployeesLookUp", path: USER_LOOKUPS["gglEmployeesLookUp"] },
      { propName: "genfieldsGroupEntity", path: USER_LOOKUPS.genfieldsGroupEntity },
      { propName: "gemfieldGroupCompanyLookUp", path: USER_LOOKUPS.gemfieldGroupCompanyLookUp },
      { propName: "riskCategoryLookUp", path: USER_LOOKUPS.riskCategoryLookUp },
      { propName: "specificRiskLookUp", path: USER_LOOKUPS.specificRiskLookUp, params: [this.selectedRiskCategoryId] },
      { propName: "localRiskOwnerLookUp", path: USER_LOOKUPS.localRiskOwnerLookUp, params: [this.selectedCompany] },
      { propName: "boardRiskOwnerLookUp", path: USER_LOOKUPS.boardRiskOwnerLookUp, params: [this.selectedCompany] },
      { propName: "ticketIncType", path: I_T_HELP_DESK_LOOKUPS.itIncTypeLookUp },
      { propName: "ticketStatus", hostUri: CLIENT_URL, path: "assets/ticket-details/ticket-status.json" },
      { propName: "categoryLookup", path: USER_LOOKUPS["designCategoryLookup"] },
      { propName: "warehouseLookUp", path: USER_LOOKUPS["warehouseLookUp"] },
      { propName: "salesChannelLookups", path: USER_LOOKUPS["salesChannel"] },
      { propName: "collectionLookup", path: USER_LOOKUPS["getCollectionLookUps"], params: [13] },
      { propName: "productSearchCategoryLookup", path: USER_LOOKUPS["productSearchCategoryLookup"] },
      { propName: "productGemstonetypeLookUps", path: USER_LOOKUPS["productGemstonetypeLookUps"] },
      { propName: "usersLookUp", path: USER_LOOKUPS["userLookUpsToShowExEmpForBookings"] },
      { propName: "designStatusLookup", path: USER_LOOKUPS["designStatusLookup"] },
      { propName: "createdBy", path: USER_LOOKUPS.fabergePriceListCreatedByLookUps }
    ]).subscribe((lookup: any) => {
      this.moveNoteDocumentLookUps = lookup;
      if (this.moveNoteDocumentModel.isDocument) {
        let ithelpDeskIndex = this.moveNoteDocumentLookUps.entityTypesLookup.findIndex(x => x.entityTypeId == this.modouleIdObj.itHelpDeskModuleId)
        if (ithelpDeskIndex != -1) {
          this.moveNoteDocumentLookUps.entityTypesLookup.splice(ithelpDeskIndex, 1)
        }
        let batchIndex = this.moveNoteDocumentLookUps.entityTypesLookup.findIndex(x => x.entityTypeId == this.modouleIdObj.batchesModuleId)
        if (batchIndex != -1) {
          this.moveNoteDocumentLookUps.entityTypesLookup.splice(batchIndex, 1)
        }
      }
      this.moveNoteDocumentFilters = new MoveNoteDocumentFilterViewModel();
      this.moveNoteDocumentFormGroup = this.formBuilder.formGroup(this.moveNoteDocumentFilters) as IFormGroup<MoveNoteDocumentFilterViewModel>;
      this.showComponent = true;
      this.spin = false;
    })
  }

  getLookupData(event) {
    this.isShowGrid = false;
    this.isFilter = false;
    this.isNoRecordFound = false;
    this.localFormGroupJson = new MoveNoteDocumentFilterViewModel();
    if (this.userSearchGrid && this.userSearchGrid != null) {
      this.userSearchGrid.destroy();
      this.userSearchGrid = null;
    }
    if (this.corporateSearchGrid && this.corporateSearchGrid != null) {
      this.corporateSearchGrid.destroy();
      this.corporateSearchGrid = null;
    }
    if (this.eventSearchGrid && this.eventSearchGrid != null) {
      this.eventSearchGrid.destroy();
      this.eventSearchGrid = null;
    }
    if (this.projectSearchGrid && this.projectSearchGrid != null) {
      this.projectSearchGrid.destroy();
      this.projectSearchGrid = null;
    }
    if (this.orderSearchGrid && this.orderSearchGrid != null) {
      this.orderSearchGrid.destroy();
      this.orderSearchGrid = null;
    }
    if (this.pressMediaSearchGrid && this.pressMediaSearchGrid != null) {
      this.pressMediaSearchGrid.destroy();
      this.pressMediaSearchGrid = null;
    }
    if (this.legalAgreementSearchGrid && this.legalAgreementSearchGrid != null) {
      this.legalAgreementSearchGrid.destroy();
      this.legalAgreementSearchGrid = null;
    }
    if (this.hseSearchGrid && this.hseSearchGrid != null) {
      this.hseSearchGrid.destroy();
      this.hseSearchGrid = null;
    }
    if (this.riskSearchGrid && this.riskSearchGrid != null) {
      this.riskSearchGrid.destroy();
      this.riskSearchGrid = null;
    }
    if (this.itHelpDeskSearchGrid && this.itHelpDeskSearchGrid != null) {
      this.itHelpDeskSearchGrid.destroy();
      this.itHelpDeskSearchGrid = null;
    }
    if (this.piecesSearchGrid && this.piecesSearchGrid != null) {
      this.piecesSearchGrid.destroy();
      this.piecesSearchGrid = null;
    }
    if (this.bookingsSearchGrid && this.bookingsSearchGrid != null) {
      this.bookingsSearchGrid.destroy();
      this.bookingsSearchGrid = null;
    }
    if (this.priceListSearchGrid && this.priceListSearchGrid != null) {
      this.priceListSearchGrid.destroy();
      this.priceListSearchGrid = null;
    }
    if (this.designSearchGrid && this.designSearchGrid != null) {
      this.designSearchGrid.destroy();
      this.designSearchGrid = null;
    }
    if (this.gemstoneSearchGrid && this.gemstoneSearchGrid != null) {
      this.gemstoneSearchGrid.destroy();
      this.gemstoneSearchGrid = null;
    }
    if (this.productSearchGrid && this.productSearchGrid != null) {
      this.productSearchGrid.destroy();
      this.productSearchGrid = null;
    }
    if (this.batchesSearchGrid && this.batchesSearchGrid != null) {
      this.batchesSearchGrid.destroy();
      this.batchesSearchGrid = null;
    }
    if (event.text == "") {
      this.textSearchCount = 3;
      this.moveNoteDocumentFormGroup.patchValue({
        rootEntityId: 0
      })
      this.isShowControl = false;
      this.rootEntityName = '';
    }
    else {
      this.isShowControl = true;
      if (event.sourceItem != undefined && (event.sourceItem.entityTypeId == RolePermissionEnums.SalesOrders || event.sourceItem.entityTypeId == RolePermissionEnums.PressandMedia)) {
        this.textSearchCount = 1;
      }
      else {
        this.textSearchCount = 3;
      }
    }
  }

  moveNoteDocuments() {
    this.moveNoteDocumentFormGroup.submitted = true;
    if (this.localFormGroupJson && this.localFormGroupJson.rootEntityTypeId >0 &&this.moveNoteDocumentSearchResult) {
      this.spin = true;
      switch (this.localFormGroupJson.rootEntityTypeId) {
        case RolePermissionEnums.User:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.CustomerNotes;
          break;
        case RolePermissionEnums.Supplier:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.CorporateGroupsNotes;
          break;
        case RolePermissionEnums.Event:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.EventNotes;
          break;
        case RolePermissionEnums.Project:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.ProjectNotes;
          break;
        case RolePermissionEnums.SalesOrders:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.OrderNotes;
          break;
        case RolePermissionEnums.PressandMedia:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.PressMediaNote;
          break;
        case RolePermissionEnums.LegalAgreements:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.LegalAgreementNotes;
          break;
        case RolePermissionEnums.HSE:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.IncidentAccidentNearDashMissNotes;
          break;
        case RolePermissionEnums.GemfieldsRiskMenu:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.RiskNotes;
          break;
        case RolePermissionEnums.ITHelpDesk:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.ITTicketNotes;
          break;
        case RolePermissionEnums.Pieces:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.ProductVariantBatchPieceNotes;
          break;
        case RolePermissionEnums.Bookings:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.BookingNotes;
          break;
        case RolePermissionEnums.FabergePriceLists:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.TripNotes;
          break;
        case RolePermissionEnums.ProductDesign:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.ProductNotes;
          break;
        case RolePermissionEnums.FabergeGemstones:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.GemStoneNotes;
          break;
        case RolePermissionEnums.Products:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.ProductVariantNotes;
          break;
        case RolePermissionEnums.FabergeBatches:
          this.moveNoteDocumentModel.entityTypeId = NotesEnum.ProductBatchNotes;
          break;
      }
      let obj = this.moveNoteDocumentSearchResult.find(x => x.isChecked == true);
      if (obj) {
        this.moveNoteDocumentModel.entityId = obj.id;
        this.moveNoteDocumentModel.targetModuleId = this.localFormGroupJson.rootEntityTypeId;
        this.post({ path: 'api/Notes/MoveNoteDocument', body: this.moveNoteDocumentModel }).subscribe((res:any) => {
          if(res && res != null && res.length>0 ){
            if(res[0] == 'Success'){
              var existsAlert = this.moveNoteDocumentModel.isDocument ? getLocalizedValue("Doc_Moved_Success") : getLocalizedValue("Note_Moved_Success"); 
              if (existsAlert) {
                this.toastr.success(existsAlert);
              }
            }else{
              var existsAlert = getLocalizedValue("Note_Doc_Move_Error");
              if (existsAlert) {
                this.toastr.error(existsAlert);
              }
            }
          }
          this.spin = false;
          this.hide(true);
        })
      }
      else{
        this.spin = false;
      }
    }
  }

  closePopup(){
    this.hide(false);
  }

  onRadioSelect(t) {
    this.moveNoteDocumentSearchResult.forEach(x => {
      if (x.id == t.id) {
        x.isChecked = true;
      }
      else {
        x.isChecked = false;
      }
    })
    if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.User) {
      this.userSearchGrid.updateSource([]);
      this.userSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
    }
    this.disabledMoveNotebtn = this.moveNoteDocumentSearchResult.findIndex(x => x.isChecked == true) == -1;
  }

  searchData() {
    this.disabledMoveNotebtn = true;
    this.moveNoteDocumentFormGroup.submitted = true;
    if (this.moveNoteDocumentFormGroup.value.rootEntityTypeId == RolePermissionEnums.Event && dateCompareValidation(this.moveNoteDocumentFormGroup.value.moveNoteDocumentEventFilterViewModel.eventStartDate, this.moveNoteDocumentFormGroup.value.moveNoteDocumentEventFilterViewModel.eventEndDate) == true) {
      this.eventDateError = true;
      return;
    }
    if (this.moveNoteDocumentFormGroup.value.rootEntityTypeId == RolePermissionEnums.Project && dateCompareValidation(this.moveNoteDocumentFormGroup.value.moveNoteDocumentProjectFilterViewModel.projectStartDate, this.moveNoteDocumentFormGroup.value.moveNoteDocumentProjectFilterViewModel.projectEndDate) == true) {
      this.projectDateError = true;
      return;
    }
    if (this.moveNoteDocumentFormGroup.value.rootEntityTypeId == RolePermissionEnums.LegalAgreements && dateCompareValidation(this.moveNoteDocumentFormGroup.value.moveNoteDocumentLegalAgreementFilterViewModel.expiryDateFrom, this.moveNoteDocumentFormGroup.value.moveNoteDocumentLegalAgreementFilterViewModel.expiryDateTo) == true) {
      this.expiryDateError = true;
      return;
    }
    if (this.moveNoteDocumentFormGroup.value.rootEntityTypeId == RolePermissionEnums.HSE && dateCompareValidation(this.moveNoteDocumentFormGroup.value.moveNoteDocumentHSEFilterViewModel.registrationFrom, this.moveNoteDocumentFormGroup.value.moveNoteDocumentHSEFilterViewModel.registrationTo) == true) {
      this.dateError = true;
      return;
    }
    if (this.moveNoteDocumentFormGroup.value.rootEntityTypeId == RolePermissionEnums.Bookings && dateCompareValidation(this.moveNoteDocumentFormGroup.value.moveNoteDocumentBookingFilterViewModel.bookingStartDate, this.moveNoteDocumentFormGroup.value.moveNoteDocumentBookingFilterViewModel.bookingEndDate) == true) {
      this.bookingDateError = true;
      return;
    }
    if (this.moveNoteDocumentFormGroup.valid) {
      this.spin = true;
      this.eventDateError = false;
      this.projectDateError = false;
      this.expiryDateError = false;
      this.dateError = false;
      this.bookingDateError = false;
      this.localFormGroupJson = this.moveNoteDocumentFormGroup.value as MoveNoteDocumentFilterViewModel;
      this.post({ path: 'api/SearchEvent/GetMoveNoteDocumentTargetEntities', body: { query: JSON.stringify(this.moveNoteDocumentFormGroup.value) } }).subscribe(async (response: any) => {
        this.moveNoteDocumentSearchResult = (response as any[]).length > 0 ? response : [];
        if (this.moveNoteDocumentModel.currentModuleId == this.localFormGroupJson.rootEntityTypeId) {
          let index = this.moveNoteDocumentSearchResult.findIndex(p => p.id == this.moveNoteDocumentModel.currentEntityId);
          if (index != -1) {
            this.moveNoteDocumentSearchResult.splice(index, 1);
          }
        }
        this.isShowGrid = this.moveNoteDocumentSearchResult.length > 0 ? true : false;
        this.isNoRecordFound = this.moveNoteDocumentSearchResult.length == 0 ? true : false;
        this.bindUserGrid();
      });
    }
  }

  bindUserGrid() {
    if (this.moveNoteDocumentSearchResult.length > 0) {
      this.totalRecords = this.moveNoteDocumentSearchResult[0].totalCount;
      this.isNoRecordFound = false;
      if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.User) {
        if (!this.isFilter && (this.userSearchGrid == undefined || this.userSearchGrid == null)) {
          this.userSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentUserSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.userSearchGrid.componentId = this.componentName;
          this.userSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.userSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.userSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.userSearchGrid != null) {
            this.userSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentUserFilterViewModel.pageIndex == 1) {
              this.userSearchGrid.storeProcedure.nextPage = 1;
            }
            this.userSearchGrid.updateSource([]);
            this.userSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.userSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentUserFilterViewModel.rowCount;
          this.userSearchGrid.componentId = this.componentName;
          this.userSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.userSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Supplier) {
        if (!this.isFilter && (this.corporateSearchGrid == undefined || this.corporateSearchGrid == null)) {
          this.corporateSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentCorporateSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.corporateSearchGrid.componentId = this.componentName;
          this.corporateSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.corporateSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.corporateSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.corporateSearchGrid != null) {
            this.corporateSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentCorporateFilterViewModel.pageIndex == 1) {
              this.corporateSearchGrid.storeProcedure.nextPage = 1;
            }
            this.corporateSearchGrid.updateSource([]);
            this.corporateSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.corporateSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentCorporateFilterViewModel.rowCount;
          this.corporateSearchGrid.componentId = this.componentName;
          this.corporateSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.corporateSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Event) {
        if (!this.isFilter && (this.eventSearchGrid == undefined || this.eventSearchGrid == null)) {
          this.eventSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentEventSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.eventSearchGrid.componentId = this.componentName;
          this.eventSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.eventSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.eventSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.eventSearchGrid != null) {
            this.eventSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentEventFilterViewModel.pageIndex == 1) {
              this.eventSearchGrid.storeProcedure.nextPage = 1;
            }
            this.eventSearchGrid.updateSource([]);
            this.eventSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.eventSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentEventFilterViewModel.rowCount;
          this.eventSearchGrid.componentId = this.componentName;
          this.eventSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.eventSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Project) {
        if (!this.isFilter && (this.projectSearchGrid == undefined || this.projectSearchGrid == null)) {
          this.projectSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentProjectSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.projectSearchGrid.componentId = this.componentName;
          this.projectSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.projectSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.projectSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.projectSearchGrid != null) {
            this.projectSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentProjectFilterViewModel.pageIndex == 1) {
              this.projectSearchGrid.storeProcedure.nextPage = 1;
            }
            this.projectSearchGrid.updateSource([]);
            this.projectSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.projectSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentProjectFilterViewModel.rowCount;
          this.projectSearchGrid.componentId = this.componentName;
          this.projectSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.projectSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.SalesOrders) {
        if (!this.isFilter && (this.orderSearchGrid == undefined || this.orderSearchGrid == null)) {
          this.orderSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentOrderSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.orderSearchGrid.componentId = this.componentName;
          this.orderSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.orderSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.orderSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.orderSearchGrid != null) {
            this.orderSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentOrderFilterViewModel.pageIndex == 1) {
              this.orderSearchGrid.storeProcedure.nextPage = 1;
            }
            this.orderSearchGrid.updateSource([]);
            this.orderSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.orderSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentOrderFilterViewModel.rowCount;
          this.orderSearchGrid.componentId = this.componentName;
          this.orderSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.orderSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.PressandMedia) {
        if (!this.isFilter && (this.pressMediaSearchGrid == undefined || this.pressMediaSearchGrid == null)) {
          this.pressMediaSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentPressMediaSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.pressMediaSearchGrid.componentId = this.componentName;
          this.pressMediaSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.pressMediaSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.pressMediaSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.pressMediaSearchGrid != null) {
            this.pressMediaSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentPressMediaFilterViewModel.pageIndex == 1) {
              this.pressMediaSearchGrid.storeProcedure.nextPage = 1;
            }
            this.pressMediaSearchGrid.updateSource([]);
            this.pressMediaSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.pressMediaSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentPressMediaFilterViewModel.rowCount;
          this.pressMediaSearchGrid.componentId = this.componentName;
          this.pressMediaSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.pressMediaSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.LegalAgreements) {
        if (!this.isFilter && (this.legalAgreementSearchGrid == undefined || this.legalAgreementSearchGrid == null)) {
          this.legalAgreementSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentLegalAgreementSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.legalAgreementSearchGrid.componentId = this.componentName;
          this.legalAgreementSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.legalAgreementSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.legalAgreementSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.legalAgreementSearchGrid != null) {
            this.legalAgreementSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentLegalAgreementFilterViewModel.pageIndex == 1) {
              this.legalAgreementSearchGrid.storeProcedure.nextPage = 1;
            }
            this.legalAgreementSearchGrid.updateSource([]);
            this.legalAgreementSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.legalAgreementSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentLegalAgreementFilterViewModel.rowCount;
          this.legalAgreementSearchGrid.componentId = this.componentName;
          this.legalAgreementSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.legalAgreementSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.HSE) {
        if (!this.isFilter && (this.hseSearchGrid == undefined || this.hseSearchGrid == null)) {
          this.hseSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentHSESearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.hseSearchGrid.componentId = this.componentName;
          this.hseSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.hseSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.hseSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.hseSearchGrid != null) {
            this.hseSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentHSEFilterViewModel.pageIndex == 1) {
              this.hseSearchGrid.storeProcedure.nextPage = 1;
            }
            this.hseSearchGrid.updateSource([]);
            this.hseSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.hseSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentHSEFilterViewModel.rowCount;
          this.hseSearchGrid.componentId = this.componentName;
          this.hseSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.hseSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.GemfieldsRiskMenu) {
        if (!this.isFilter && (this.riskSearchGrid == undefined || this.riskSearchGrid == null)) {
          this.riskSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentRiskSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.riskSearchGrid.componentId = this.componentName;
          this.riskSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.riskSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.riskSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.riskSearchGrid != null) {
            this.riskSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentRiskFilterViewModel.pageIndex == 1) {
              this.riskSearchGrid.storeProcedure.nextPage = 1;
            }
            this.riskSearchGrid.updateSource([]);
            this.riskSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.riskSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentRiskFilterViewModel.rowCount;
          this.riskSearchGrid.componentId = this.componentName;
          this.riskSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.riskSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ITHelpDesk) {
        if (!this.isFilter && (this.itHelpDeskSearchGrid == undefined || this.itHelpDeskSearchGrid == null)) {
          this.itHelpDeskSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentITHelpDeskSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.itHelpDeskSearchGrid.componentId = this.componentName;
          this.itHelpDeskSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.itHelpDeskSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.itHelpDeskSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.itHelpDeskSearchGrid != null) {
            this.itHelpDeskSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentITHelpDeskFilterViewModel.pageIndex == 1) {
              this.itHelpDeskSearchGrid.storeProcedure.nextPage = 1;
            }
            this.itHelpDeskSearchGrid.updateSource([]);
            this.itHelpDeskSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.itHelpDeskSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentITHelpDeskFilterViewModel.rowCount;
          this.itHelpDeskSearchGrid.componentId = this.componentName;
          this.itHelpDeskSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.itHelpDeskSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Pieces) {
        if (!this.isFilter && (this.piecesSearchGrid == undefined || this.piecesSearchGrid == null)) {
          this.piecesSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentPieceSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.piecesSearchGrid.componentId = this.componentName;
          this.piecesSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.piecesSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.piecesSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.piecesSearchGrid != null) {
            this.piecesSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentPieceFilterViewModel.pageIndex == 1) {
              this.piecesSearchGrid.storeProcedure.nextPage = 1;
            }
            this.piecesSearchGrid.updateSource([]);
            this.piecesSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.piecesSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentPieceFilterViewModel.rowCount;
          this.piecesSearchGrid.componentId = this.componentName;
          this.piecesSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.piecesSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Products) {
        if (!this.isFilter && (this.productSearchGrid == undefined || this.productSearchGrid == null)) {
          this.productSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentProductSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.productSearchGrid.componentId = this.componentName;
          this.productSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.productSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.productSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.productSearchGrid != null) {
            this.productSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentProductFilterViewModel.pageIndex == 1) {
              this.productSearchGrid.storeProcedure.nextPage = 1;
            }
            this.productSearchGrid.updateSource([]);
            this.productSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.productSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentProductFilterViewModel.rowCount;
          this.productSearchGrid.componentId = this.componentName;
          this.productSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.productSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergeGemstones) {
        if (!this.isFilter && (this.gemstoneSearchGrid == undefined || this.gemstoneSearchGrid == null)) {
          this.gemstoneSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentGemstoneSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.gemstoneSearchGrid.componentId = this.componentName;
          this.gemstoneSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.gemstoneSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.gemstoneSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.gemstoneSearchGrid != null) {
            this.gemstoneSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentGemstoneFilterViewModel.pageIndex == 1) {
              this.gemstoneSearchGrid.storeProcedure.nextPage = 1;
            }
            this.gemstoneSearchGrid.updateSource([]);
            this.gemstoneSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.gemstoneSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentGemstoneFilterViewModel.rowCount;
          this.gemstoneSearchGrid.componentId = this.componentName;
          this.gemstoneSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.gemstoneSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Bookings) {
        if (!this.isFilter && (this.bookingsSearchGrid == undefined || this.bookingsSearchGrid == null)) {
          this.bookingsSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentBookingSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.bookingsSearchGrid.componentId = this.componentName;
          this.bookingsSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.bookingsSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.bookingsSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.bookingsSearchGrid != null) {
            this.bookingsSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentBookingFilterViewModel.pageIndex == 1) {
              this.bookingsSearchGrid.storeProcedure.nextPage = 1;
            }
            this.bookingsSearchGrid.updateSource([]);
            this.bookingsSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.bookingsSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentBookingFilterViewModel.rowCount;
          this.bookingsSearchGrid.componentId = this.componentName;
          this.bookingsSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.bookingsSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ProductDesign) {
        if (!this.isFilter && (this.designSearchGrid == undefined || this.designSearchGrid == null)) {
          this.designSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentDesignSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.designSearchGrid.componentId = this.componentName;
          this.designSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.designSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.designSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.designSearchGrid != null) {
            this.designSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentDesignFilterViewModel.pageIndex == 1) {
              this.designSearchGrid.storeProcedure.nextPage = 1;
            }
            this.designSearchGrid.updateSource([]);
            this.designSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.designSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentDesignFilterViewModel.rowCount;
          this.designSearchGrid.componentId = this.componentName;
          this.designSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.designSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergePriceLists) {
        if (!this.isFilter && (this.priceListSearchGrid == undefined || this.priceListSearchGrid == null)) {
          this.priceListSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentPriceListSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.priceListSearchGrid.componentId = this.componentName;
          this.priceListSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.priceListSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.priceListSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.priceListSearchGrid != null) {
            this.priceListSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentPricelistFilterViewModel.pageIndex == 1) {
              this.priceListSearchGrid.storeProcedure.nextPage = 1;
            }
            this.priceListSearchGrid.updateSource([]);
            this.priceListSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.priceListSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentPricelistFilterViewModel.rowCount;
          this.priceListSearchGrid.componentId = this.componentName;
          this.priceListSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.priceListSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
      else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergeBatches) {
        if (!this.isFilter && (this.batchesSearchGrid == undefined || this.batchesSearchGrid == null)) {
          this.batchesSearchGrid = new AppGrid(this.moveNoteDocumentSearchResult, MoveNoteDocumentBatchSearchViewModel, { actions: { onRadioSelect: this.onRadioSelect.bind(this) } });
          this.batchesSearchGrid.componentId = this.componentName;
          this.batchesSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.batchesSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.batchesSearchGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChangingGrid.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSortingGrid.bind(this)
          }
        }
        else {
          if (this.batchesSearchGrid != null) {
            this.batchesSearchGrid.storeProcedure.length = this.totalRecords;
            if (this.localFormGroupJson.moveNoteDocumentBatchFilterViewModel.pageIndex == 1) {
              this.batchesSearchGrid.storeProcedure.nextPage = 1;
            }
            this.batchesSearchGrid.updateSource([]);
            this.batchesSearchGrid.updateSource(this.moveNoteDocumentSearchResult);
          }
          this.batchesSearchGrid.maxPerPage = this.localFormGroupJson.moveNoteDocumentBatchFilterViewModel.rowCount;
          this.batchesSearchGrid.componentId = this.componentName;
          this.batchesSearchGrid.designClass.tableClass = "table table-height-65 table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          this.batchesSearchGrid.designClass.headerClass = "table-header-sticky".split(" ");
        }
      }
    }
    this.spin = false;
  }

  onPageSortingGrid(x, y, z) {
    this.spin = true;
    this.isFilter = true;
    if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.User) {
      this.localFormGroupJson.moveNoteDocumentUserFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentUserFilterViewModel.orderBy = x;
      this.userSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Supplier) {
      this.localFormGroupJson.moveNoteDocumentCorporateFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentCorporateFilterViewModel.orderBy = x;
      this.corporateSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Event) {
      this.localFormGroupJson.moveNoteDocumentEventFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentEventFilterViewModel.orderBy = x;
      this.eventSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Project) {
      this.localFormGroupJson.moveNoteDocumentProjectFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentProjectFilterViewModel.orderBy = x;
      this.projectSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.SalesOrders) {
      this.localFormGroupJson.moveNoteDocumentOrderFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentOrderFilterViewModel.orderBy = x;
      this.orderSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.PressandMedia) {
      this.localFormGroupJson.moveNoteDocumentPressMediaFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentPressMediaFilterViewModel.orderBy = x;
      this.pressMediaSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.LegalAgreements) {
      this.localFormGroupJson.moveNoteDocumentLegalAgreementFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentLegalAgreementFilterViewModel.orderBy = x;
      this.legalAgreementSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.HSE) {
      this.localFormGroupJson.moveNoteDocumentHSEFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentHSEFilterViewModel.orderBy = x;
      this.hseSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.GemfieldsRiskMenu) {
      this.localFormGroupJson.moveNoteDocumentRiskFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentRiskFilterViewModel.orderBy = x;
      this.riskSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ITHelpDesk) {
      this.localFormGroupJson.moveNoteDocumentITHelpDeskFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentITHelpDeskFilterViewModel.orderBy = x;
      this.itHelpDeskSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Pieces) {
      this.localFormGroupJson.moveNoteDocumentPieceFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentPieceFilterViewModel.orderBy = x;
      this.piecesSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Products) {
      this.localFormGroupJson.moveNoteDocumentProductFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentProductFilterViewModel.orderBy = x;
      this.productSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergeGemstones) {
      this.localFormGroupJson.moveNoteDocumentGemstoneFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentGemstoneFilterViewModel.orderBy = x;
      this.gemstoneSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Bookings) {
      this.localFormGroupJson.moveNoteDocumentBookingFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentBookingFilterViewModel.orderBy = x;
      this.bookingsSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ProductDesign) {
      this.localFormGroupJson.moveNoteDocumentDesignFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentDesignFilterViewModel.orderBy = x;
      this.designSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergePriceLists) {
      this.localFormGroupJson.moveNoteDocumentPricelistFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentPricelistFilterViewModel.orderBy = x;
      this.priceListSearchGrid.storeProcedure.nextPage = z;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergeBatches) {
      this.localFormGroupJson.moveNoteDocumentBatchFilterViewModel.sortOrder = y;
      this.localFormGroupJson.moveNoteDocumentBatchFilterViewModel.orderBy = x;
      this.batchesSearchGrid.storeProcedure.nextPage = z;
    }
    this.searchData();
  }

  onPageChangingGrid(x) {
    this.spin = true;
    this.isFilter = true;
    if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.User) {
      this.localFormGroupJson.moveNoteDocumentUserFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentUserFilterViewModel.rowCount = this.userSearchGrid.maxPerPage;
      this.userSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Supplier) {
      this.localFormGroupJson.moveNoteDocumentCorporateFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentCorporateFilterViewModel.rowCount = this.corporateSearchGrid.maxPerPage;
      this.corporateSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Event) {
      this.localFormGroupJson.moveNoteDocumentEventFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentEventFilterViewModel.rowCount = this.eventSearchGrid.maxPerPage;
      this.eventSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Project) {
      this.localFormGroupJson.moveNoteDocumentProjectFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentProjectFilterViewModel.rowCount = this.projectSearchGrid.maxPerPage;
      this.projectSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.SalesOrders) {
      this.localFormGroupJson.moveNoteDocumentOrderFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentOrderFilterViewModel.rowCount = this.orderSearchGrid.maxPerPage;
      this.orderSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.PressandMedia) {
      this.localFormGroupJson.moveNoteDocumentPressMediaFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentPressMediaFilterViewModel.rowCount = this.pressMediaSearchGrid.maxPerPage;
      this.pressMediaSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.LegalAgreements) {
      this.localFormGroupJson.moveNoteDocumentLegalAgreementFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentLegalAgreementFilterViewModel.rowCount = this.legalAgreementSearchGrid.maxPerPage;
      this.legalAgreementSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.HSE) {
      this.localFormGroupJson.moveNoteDocumentHSEFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentHSEFilterViewModel.rowCount = this.hseSearchGrid.maxPerPage;
      this.hseSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.GemfieldsRiskMenu) {
      this.localFormGroupJson.moveNoteDocumentRiskFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentRiskFilterViewModel.rowCount = this.riskSearchGrid.maxPerPage;
      this.riskSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ITHelpDesk) {
      this.localFormGroupJson.moveNoteDocumentITHelpDeskFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentITHelpDeskFilterViewModel.rowCount = this.itHelpDeskSearchGrid.maxPerPage;
      this.itHelpDeskSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Pieces) {
      this.localFormGroupJson.moveNoteDocumentPieceFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentPieceFilterViewModel.rowCount = this.piecesSearchGrid.maxPerPage;
      this.piecesSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Products) {
      this.localFormGroupJson.moveNoteDocumentProductFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentProductFilterViewModel.rowCount = this.productSearchGrid.maxPerPage;
      this.productSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergeGemstones) {
      this.localFormGroupJson.moveNoteDocumentGemstoneFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentGemstoneFilterViewModel.rowCount = this.gemstoneSearchGrid.maxPerPage;
      this.gemstoneSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.Bookings) {
      this.localFormGroupJson.moveNoteDocumentBookingFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentBookingFilterViewModel.rowCount = this.bookingsSearchGrid.maxPerPage;
      this.bookingsSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ProductDesign) {
      this.localFormGroupJson.moveNoteDocumentDesignFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentDesignFilterViewModel.rowCount = this.designSearchGrid.maxPerPage;
      this.designSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.ProductDesign) {
      this.localFormGroupJson.moveNoteDocumentPricelistFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentPricelistFilterViewModel.rowCount = this.priceListSearchGrid.maxPerPage;
      this.priceListSearchGrid.storeProcedure.nextPage = x;
    }
    else if (this.localFormGroupJson.rootEntityTypeId == RolePermissionEnums.FabergeBatches) {
      this.localFormGroupJson.moveNoteDocumentBatchFilterViewModel.pageIndex = x;
      this.localFormGroupJson.moveNoteDocumentBatchFilterViewModel.rowCount = this.batchesSearchGrid.maxPerPage;
      this.batchesSearchGrid.storeProcedure.nextPage = x;
    }
    this.searchData();
  }

  checkNameText(event: any) {
    this.moveNoteDocumentFormGroup.get('moveNoteDocumentUserFilterViewModel').patchValue({
      nameText: event.target.value
    })
    this.searchName = event.target.value;
  }

  onNameClick($event) {
    this.moveNoteDocumentFormGroup.get('moveNoteDocumentUserFilterViewModel').patchValue({
      nameText: ''
    })
    this.searchName = $event.item.value;
  }

  checkEmailText(event: any) {
    this.moveNoteDocumentFormGroup.get('moveNoteDocumentUserFilterViewModel').patchValue({
      emailText: event.target.value,
    });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "MoveNoteDocumentTargetSearchComponent";
  }
}
