import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductionFormOrderNoBase {

//#region productionOrderFormId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'productionOrderFormId', keyColumn: true})
        productionOrderFormId : number;
//#endregion productionOrderFormId Prop


//#region orderNo Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'orderNo', keyColumn: false})
        orderNo : string;
//#endregion orderNo Prop

}