import {
  XhrContext,
  AbstractRequestFilter,
  ResponseFilter,
  HttpResponse,
} from "@rxweb/http";
import { BaseToastr } from "../domain/customize-design/toastr";
import { BrowserStorage } from "../domain/services/browser-storage";
import { authKeyForAzureJob } from "../domain/system-constant";
export class AuthFilter
  extends AbstractRequestFilter
  implements ResponseFilter
{
  constructor(
    private browserStorage: BrowserStorage,
    private baseToastr: BaseToastr
  ) {
    super();
    this.browserStorage = new BrowserStorage();
  }

  onRequest = (context: XhrContext) => {
    let xSession = this.browserStorage.local.get("xSession");
    if (xSession) {
      var diff = Math.abs(new Date().getTime() - new Date(xSession).getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600));
      if (diffDays > 3) {
        this.browserStorage.local.clearAll();
        if (this.baseToastr) this.baseToastr.error("Invalid User");
        var element = document.getElementById("gtrac");
        element.classList.add("login-page-view");

        /**
         * FIXED #251279 BY ADDING 'previousroute' again after clearing localstorage
        */
        if(
          window.location.pathname.indexOf("/member-feedback") == -1 &&
          window.location.pathname.indexOf("/event-redirection") == -1 && 
          window.location.pathname.indexOf("/forgot-password") == -1 &&
          window.location.pathname.indexOf("/sso-redirected") == -1 &&
          window.location.pathname.indexOf("/reset-password") == -1 &&
          window.location.pathname.indexOf("/accessrequest") == -1 &&
          window.location.pathname.indexOf("/i-t-help-desk-weekly-report-view") ==
            -1 &&
          window.location.pathname.indexOf("/grievance-form/add") == -1 &&
          window.location.pathname.indexOf("/quick-reply") == -1 &&
          window.location.pathname.indexOf("/unlock-account") == -1 &&
          window.location.pathname.indexOf("/purchase-order-download") == -1
        ){
          if (window.location.pathname.indexOf("/login") == -1) {
            localStorage.setItem(
              "previousroute",
              window.location.pathname +
                (window.location.search ? window.location.search : "")
            );
          }
          window.location.href = "/login";
        }
      }
    }

    if (this.byPassApiList.indexOf(context.request.path) == -1) {
      var anonymousAuth = this.browserStorage.local.get("anonymousAuth", false);
      if (anonymousAuth) {
        var Authorization = this.browserStorage.local.get(
          "anonymousAuth",
          false
        );
      } else {
        let key = this.browserStorage.local.get("key", false);
        var Authorization = this.browserStorage.local.get("auth", false);
      }
      if (Authorization) {
        if (context.request.headers["Authorization"] != null)
          context.request.headers["Authorization"] = null;
        context.request.headers["Authorization"] = Authorization;
      }
    }
    if(this.schedulerApiList.indexOf(context.request.path) != -1){
      context.request.headers["AzureJobAuthKey"] = authKeyForAzureJob
    }
    this.onRequestExecuting(context);
  };

  onResponse = (response: HttpResponse) => {
    this.browserStorage.local.save("xSession", new Date());
    return response;
  };

  byPassApiList: any[] = [
    "api/Authentication",
    "api/SearchCommon/GetNoteMembersMail",
    "api/Authentication/NoteAuthentication",
    "api/SearchCommon/UpdateUserLockout",
    "api/SearchCommon/ITHelpdeskWeeklyReport",
    "api/SearchCommon/GetSupplierByGrievanceSuffix",
  ];

  schedulerApiList: any[] = [
    "api/SearchCommon/GetGoogleFeedXML",
    "api/SearchCommon/GetProductXML",
    "api/Authentication/UpdateNewsLetterSubscription"
  ];
}

export enum HttpResponseCode {
  InvalidStatusCode = 0,
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  TokenNotFound = 600,
  BadIpFound = 450,
}
