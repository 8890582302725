import {ApplicationUserTokenBase,} from '@app/database-models'
//Generated Imports
export class ApplicationUserToken extends ApplicationUserTokenBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}