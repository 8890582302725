import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim, numeric, pattern, minNumber } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DutyStatusBase {

//#region dutyStatusId Prop
        @prop()
        dutyStatusId : number;
//#endregion dutyStatusId Prop


//#region dutyStatusName Prop
        @required()
        @maxLength({value:100})
        @trim()
        @notEmpty()
        dutyStatusName : string;
//#endregion dutyStatusName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

        @prop()
        @minNumber({value:1,message:'User allow Expiry Days greater than 0.'})
        @pattern({expression:{"dutyStatusExpiryDays":/^[0-9]*$/},message:"User allow Expiry Days greater than 0."})
        dutyStatusExpiryDays:number;

}