<div *rxMultilingualExtended="_this">

  <div class="container mt-2" *rxMultilingual="" id="removespace" [rxLocalisationInit]="componentName" [rxSpinner]="spin">
    <div>

      <div class="table-responsive" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
        <rx-grid [design]="TaskGrid"></rx-grid>
      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <div>
            <h4>No Record Found</h4>
        </div>
    </div>
    </div>
  </div>
</div>
