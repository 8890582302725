export enum EventTypeEnum {
        FabergeEvent = 165,
        NonFabergeEvent = 166,
        MediaFabergeEvent = 167,
        FabergeProductsPiecesEvent = 168,
        GemfieldsEvent = 169,
        WholeSale = 170,
        Training = 171,
        BoardMeetings = 172,
        PallinghurstEvent = 173,
        GemfieldsMedia = 174,
}