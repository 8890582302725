import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractDiscount } from '../domain/abstract-discount';
import { Discount, vDiscount, vDepartment } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { DialogViewMode } from '@rxweb/js';
import { DatePipe, PlatformLocation } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { DiscountAddComponent } from '../add/discount-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("DiscountListComponent")
@access({ accessLevel: RolePermissionEnums.Discounts, action: "get" })

@Component({
    selector: "app-discount-list",
    templateUrl: './discount-list.component.html'
})
export class DiscountListComponent extends AbstractDiscount implements OnInit, OnDestroy {

    discounts: vDiscount[];
    subscription: any;
    discountListGrid: AppGrid;
    discountListGridColumns: any[];
    discountAdd: any = DiscountAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private datePipe: DatePipe, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.Discounts;
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Discounts - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vDiscount[]) => {
            t.forEach(x => x.startDate = this.datePipe.transform(x.startDate, 'dd/MMM/yyyy'));
            t.forEach(x => x.endDate = this.datePipe.transform(x.endDate, 'dd/MMM/yyyy'));
            this.spin = false;
            this.showComponent = true;
            this.discounts = t;
            this.discountListGrid = new AppGrid(t, vDiscount, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.discountListGrid.gridColumns.forEach(x => {
                if (this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "put" }) && this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "delete" });
                    }
                }
            })
            this.discountListGrid.componentId = this.componentName;
            this.discountListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.discountListGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.discountListGrid.design(document.getElementById("discounts-list"));
        })
    }

    onDelete(discount: vDiscount) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [discount.discountId] }).subscribe(data => {
                    this.spin = false;
                    this.discountListGrid.remove(discount.discountId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/discounts']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Discount");
        if (existsAlert) {
            return existsAlert;
        }
    }

    onEdit(discount: vDiscount) {
        //this.router.navigate(["discounts", discount.discountId]);
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["discounts", discount.discountId]));
        redirectOnCTRL(url,this.router,event);
    }

    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.discounts.filter(x => x.name.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.discountId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.discountAmount.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.discountPercentage.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.startDate.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.endDate.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.discountListGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.discountListGrid = null;
        }
        setTimeout(() => {
            if (this.discountListGrid == null || this.discountListGrid == undefined) {
                this.discountListGrid = new AppGrid(this.discounts, vDiscount, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.discountListGrid.gridColumns.forEach(x => {
                    if (this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "put" }) && this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.discountListGrid.authorize({ accessLevel: RolePermissionEnums.Discounts, action: "delete" });
                        }
                    }
                })
                this.discountListGrid.componentId = this.componentName;
                this.discountListGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.discountListGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.discountListGrid.refresh("discounts-list");
                this.discountListGrid.design(document.getElementById("discounts-list"));
            }
            this.discountListGrid.search = value.target.value;
        }, 0);
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "DiscountListComponent";
    }
}
