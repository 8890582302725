import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ConditionOfProductBase {

//#region prodConditionId Prop
        @prop()
        prodConditionId : number;
//#endregion prodConditionId Prop


//#region watchRepairId Prop
        @prop()
        watchRepairId : number;
//#endregion watchRepairId Prop


//#region productVariantBatchPieceId Prop
        @prop()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop


//#region component Prop
        @maxLength({value:50})
        component : string;
//#endregion component Prop


//#region condition Prop
        @maxLength({value:50})
        condition : string;
//#endregion condition Prop

}