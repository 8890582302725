import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTitleBase {

//#region titleId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'TitleListComponent_TitleId_gh', keyColumn: true})
        titleId : number;
//#endregion titleId Prop


//#region genderId Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'genderId', keyColumn: false})
        genderId : number;
//#endregion genderId Prop


//#region titleName Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'TitleListComponent_Title_gh', keyColumn: false})
        titleName : string;
//#endregion titleName Prop

}