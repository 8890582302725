<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin">
  <div class="col-md-8 my-1" [rxLocalisationInit]="componentName">
    <h2 class="m-0 page-title mb-2" rxText="Label_People_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_People_t"></li>
      <li class="breadcrumb-item active" rxText="Label_People_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" *ngIf="showComponent">

  <div class="ibox mb-0 d-flex flex-column" (keyup.enter)="onKeyPress()" [rxLocalisationInit]="componentName">
    <div class="ibox-title bg-white pr-3">
      <h5 rxText="Label_People_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="userSearchFilterViewModelFormGroup">
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <button routerLink="/users/add" class="btn btn-success mb-4 mr-1" *rxAuthorize="[userAdd]" [rxLocalisationInit]="componentName" rxText="Btn_Add_New_t"></button>
        <button class="btn btn-success mb-4" (click)="importExcel()" rxText="Btn_Import_From_Excel_t"></button>
        <div class=" ml-auto mb-4" role="group" aria-label="Basic example" *ngIf="isSearch" [rxLocalisationInit]="componentName">
          <button type="button" class="btn btn-primary" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t">
            </button>
        </div>
      </div>
      <div class="row people_form_details">        
        <div class="col-lg-6 col-xl-4 pr-xl-5">
          <!-- //287714//06-06-2024/added Title filter in user search  -->
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="UserListComponent_Title_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="titleLookUp" #rxSelect [controlTabIndex]="1" formControlName="title" mainClass="form-control"
                [keyValueProps]="['titleName','titleId']" [selectPlaceholder]="'Select Title'"></rx-select>
            </div>
          </div>
          <div class="form-group row" *ngIf="resetNameControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Name_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1" formControlName="name"
                id="nameDropdown" [queryParams]="{'lookupType':'SearchUsersLookUp'}" [valueInQueryParams]="true"
                [keyValueProps]="['#name#','id']" [selectPlaceholder]="'Select Name'" path="api/SearchEvent/GetLookups"
                (keyup)="checkNameText($event)" (keyup.enter)="checkNameText($event)" (changed)="onNameClick($event)"
                [text]="searchName" [minimumCharacterSearchLength]="2" [enableFreeText]="true">
              </rx-select>
            </div>
          </div>
         
          <ng-container *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Email_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" [controlTabIndex]="4" mainClass="form-control" formControlName="email"
                [queryParams]="{'lookupType':'userEmail'}" [valueInQueryParams]="true" path="api/SearchEvent/GetLookups"
                [keyValueProps]="['#email#','id']" [minimumCharacterSearchLength]="2" [enableFreeText]="true"
                (keyup)="checkEmailText($event)" [selectPlaceholder]="'Select Email'">
              </rx-select>

              <!-- <rx-select  mainClass="form-control" id="emailDropdown" formControlName="email"  [source]="emailLookup"
                [keyValueProps]="['#email#','id']" (keyup)="checkEmailText($event)" (click)="removeActive('emailDropdown')"
              >
              </rx-select> -->

            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_SalesAdvisor_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="salesAdvisor">
                <option [selected]="true" value="0">Select Sales Advisor</option>
                <option *ngFor="let item of userLookups.salesAdvisorLookUp" [value]="item.userId">
                  {{item.username}}
                </option>
              </select> -->
              <rx-select [(source)]="userLookups.salesAdvisorLookUp" #rxSelect formControlName="salesAdvisor"
                mainClass="form-control" [controlTabIndex]="7" [keyValueProps]="['fullName','userId']"
                [selectPlaceholder]="'Select Sales Advisor'"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_SalesChannel_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="salesChannel">
                <option [selected]="true" value="0">Select Sales Channel</option>
                <option *ngFor="let item of userLookups.salesChannel" [value]="item.salesChannelId">
                  {{item.salesChannelName}}
                </option>
              </select> -->
              <rx-select [(source)]="userLookups.salesChannel" #rxSelect formControlName="salesChannel" mainClass="form-control"
                [controlTabIndex]="10" [keyValueProps]="['salesChannelName','salesChannelId']"
                [selectPlaceholder]="'Select Sales Channel'"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Roles_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="roles">
                <option [selected]="true" value="0">Select Roles</option>
                <option *ngFor="let item of userLookups.customerRoleLookUps" [value]="item.roleId">
                  {{item.roleName}}
                </option>
              </select> -->
              <rx-select [(source)]="userLookups.customerRoleLookUps" #rxSelect [controlTabIndex]="13" formControlName="roles"
                mainClass="form-control" [keyValueProps]="['roleName','roleId']" [selectPlaceholder]="'Select Role'">
              </rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_RegistrationFrom_t"></label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date class="w-100" showAddon="true" formControlName="registrationFrom" [controlTabIndex]="15"
                    placeholder="Enter Registration From" pickerClass="form-control">
                  </rx-date>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_RegistrationTo_t"></label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date class="w-100" showAddon="true" formControlName="registrationTo" [controlTabIndex]="18"
                    placeholder="Enter Registration To" pickerClass="form-control">
                  </rx-date>
                  <small class="form-text text-danger" *ngIf="dateError==true">Registration To Date should be greater
                    than Registration from Date<br /></small>
                </div>
              </div>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_HasPurchased_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="userLookups.fabergePurchasedLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="20"
                formControlName="hasPurchasedFrom" [keyValueProps]="['purchasedFormName','purchasedFormId']" (changed)="changeOrder($event)"
                [selectPlaceholder]="'Select Has Purchased From'"></rx-select>
            </div>
          </div>
          <!--  -->
          <div class="form-group row checkbox" *ngIf="isOrderCancelled" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_CancelledOrders_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <app-checkbox [checkFormControl]="userSearchFilterViewModelFormGroup.controls.isCancelledOrder"
                    >
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_jobTitle_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="" tabindex="24" formControlName="jobTitle"
                rxPlaceholder="UserListComponent_jobTitle_p" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Department_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="userLookups.departmentLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="28"
                formControlName="department" [selectPlaceholder]="'Select Department'"
                [keyValueProps]="['departmentName','departmentId']"></rx-select>
            </div>
          </div>
        </ng-container>
        </div>
        <div class="col-lg-6 col-xl-4 px-xl-4">

          <div class="form-group row" *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Country_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="country">
                <option [selected]="true" value="0">Select Country</option>
                <option *ngFor="let item of userLookups.countryLookUp" [value]="item.countryId">
                  {{item.countryName}}
                </option>
              </select> -->
              <rx-select [(source)]="userLookups.countryLookUp" #rxSelect formControlName="country" mainClass="form-control"
                [controlTabIndex]="2" [keyValueProps]="['countryName','countryId']"
                [selectPlaceholder]="'Select Country'"></rx-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_City_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="" tabindex="5" formControlName="city"
                rxPlaceholder="UserListComponent_City_p" />
            </div>
          </div>

          <div class="form-group row" *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Rating_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="rating">
                <option [selected]="true" value="315">Unknown/uncategorised</option>
                <option *ngFor="let item of userLookups.systemRatingLookUp" [value]="item.ratingId">
                  {{item.ratingName}}
                </option>
              </select> -->
              <rx-select [(source)]="userLookups.systemRatingLookUp" #rxSelect formControlName="rating" mainClass="form-control"
                [controlTabIndex]="8" [keyValueProps]="['ratingName','ratingId']"
                [selectPlaceholder]="'Unknown/uncategorised'"></rx-select>
            </div>
          </div>
          <div class="form-group row" *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_KeyRelationshipHolder_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="keyRelationshipHolder">
                <option [selected]="true" value="0">Select Relationship Holder</option>
                <option *ngFor="let item of userLookups.keyRelationshipHolderLookUp" [value]="item.userId">
                  {{item.username}}
                </option>
              </select> -->
              <rx-select [(source)]="userLookups.keyRelationshipHolderLookUp" #rxSelect [controlTabIndex]="11"
                formControlName="keyRelationshipHolder" mainClass="form-control" [keyValueProps]="['fullName','userId']"
                [selectPlaceholder]="'Select Relationship Holder'"></rx-select>
            </div>
          </div>
          <div class="form-group row" *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_IndustryType_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <!-- <select class="form-control" formControlName="industryType">
                <option [selected]="true" value="0">Select Industry Type</option>
                <option *ngFor="let item of userLookups.industryTypeLookUp" [value]="item.industryTypeId">
                  {{item.industryTypeName}}
                </option>
              </select> -->
              <rx-tag [(source)]="userLookups.industryTypeLookUp" #rxTag [controlTabIndex]="16"
                [isSort]="false" formControlName="industryTypeIds" mainClass="form-control" placeholder="Select Industry Type"
                [keyValueProps]="['industryTypeName','industryTypeId']">
              </rx-tag>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="UserListComponent_WithDateOf_t"></label>
            <div class="col-md-8 d-flex align-items-center">
              <fieldset>
                <div data-toggle="buttons" class="btn-group btn-group-gray btn-group-toggle ml-auto pr-2">
                <label class="btn btn-sm btn-white" [class.active]="isBirthday" (click)="changeLayout(true)" rxText="Btn_BirthdayDate_t">
                  <input value="1" type="radio" name="options">
                </label>
                <label class="btn btn-sm btn-white" [class.active]="!isBirthday" (click)="changeLayout(false)" rxText="Btn_AnniversaryDate_t">
                  <input value="0" type="radio" name="options">
                </label>
                </div>
              </fieldset>
            </div>
          </div>

          <div *ngIf="isBirthday" [rxLocalisationInit]="componentName">
            <div class="form-group row " *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_DateOfBirthFrom_t"></label>
              <div class="col-md-4">
                <div class="" id="data_1" *ngIf="showDateFrom" [rxSelectExtended]="_this">
                  <rx-select [(source)]="dateListFrom" #rxSelect mainClass="form-control" [controlTabIndex]="21"
                  [keyValueProps]="['date','id']" formControlName="birthDateFrom"
                  [selectPlaceholder]="'Date'"></rx-select>
                </div>
                <small class="form-text text-danger" *ngIf="dateOfBirthFromError==true" rxText="UserListComponent_date_error_t"></small>
              </div>
              <div class="col-md-4">
                <div class="" id="data_1" [rxSelectExtended]="_this">
                  <rx-select [(source)]="monthList" #rxSelect mainClass="form-control" [controlTabIndex]="22" (changed)="setDateList($event,true)"
                  [keyValueProps]="['month','id']" formControlName="birthMonthFrom"
                  [selectPlaceholder]="'Month'"></rx-select>
                  <small class="form-text text-danger" *ngIf="monthOfBirthFromError==true" rxText="UserListComponent_month_error_t"></small>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_DateOfBirthTo_t"></label>
              <div class="col-md-4" [rxSelectExtended]="_this">
                <div class="" id="data_1" *ngIf="showDateTo">
                  <rx-select [(source)]="dateListTo" #rxSelect mainClass="form-control" [controlTabIndex]="25"
                  [keyValueProps]="['date','id']" formControlName="birthDateTo"
                  [selectPlaceholder]="'Date'"></rx-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="" id="data_1" *ngIf="showDateTo" [rxSelectExtended]="_this">
                  <rx-select [(source)]="monthList" #rxSelect mainClass="form-control" [controlTabIndex]="26" (changed)="setDateList($event,false)"
                  [keyValueProps]="['month','id']" formControlName="birthMonthTo"
                  [selectPlaceholder]="'Month'"></rx-select>
                  <ng-container *ngIf="monthOfBirthToError==true" [rxLocalisationInit]="componentName">
                    <small class="form-text text-danger" rxText="UserListComponent_month_error_t"></small>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-4" *ngIf="dateOfBirthToError==true || dateOfBirthdateError == true"></div>
              <div class="col-md-8" *ngIf="dateOfBirthToError==true || dateOfBirthdateError == true">
                <ng-container *ngIf="dateOfBirthToError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="UserListComponent_date_error_t"></small>
                </ng-container>
                <ng-container *ngIf="dateOfBirthdateError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="UserListComponent_date_of_birth_error_t"></small>
                </ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="!isBirthday" [rxLocalisationInit]="componentName">
            <div class="form-group row" *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_AnniversaryDateFrom_t"></label>
              <div class="col-md-4" >
                <div class="" id="data_1" *ngIf="showAnniversaryDateFrom" [rxSelectExtended]="_this">
                  <rx-select [(source)]="anniversaryDateListFrom" #rxSelect mainClass="form-control" [controlTabIndex]="21"
                  [keyValueProps]="['date','id']" formControlName="anniversaryFromDate"
                  [selectPlaceholder]="'Date'"></rx-select>
                </div>
                <small class="form-text text-danger" *ngIf="anniversaryDateFromError==true" rxText="UserListComponent_date_error_t" [rxLocalisationInit]="componentName"></small>
              </div>
              <div class="col-md-4">
                <div class="" id="data_1" [rxSelectExtended]="_this">
                  <rx-select [(source)]="monthList" #rxSelect mainClass="form-control" [controlTabIndex]="22" (changed)="setAnniversaryDateList($event,true)"
                  [keyValueProps]="['month','id']" formControlName="anniversaryMonthFrom"
                  [selectPlaceholder]="'Month'"></rx-select>
                  <small class="form-text text-danger" *ngIf="anniversaryMonthFromError==true" rxText="UserListComponent_month_error_t" [rxLocalisationInit]="componentName"></small>
                </div>
              </div>

            </div>
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_AnniversaryDateTo_t"></label>
              <div class="col-md-4">
                <div class="" id="data_1" *ngIf="showAnniversaryDateTo" [rxSelectExtended]="_this">
                  <rx-select [(source)]="anniversaryDateListTo" #rxSelect mainClass="form-control" [controlTabIndex]="25"
                  [keyValueProps]="['date','id']" formControlName="anniversaryToDate"
                  [selectPlaceholder]="'Date'"></rx-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="" id="data_1" *ngIf="showAnniversaryDateTo" [rxSelectExtended]="_this">
                  <rx-select [(source)]="monthList" #rxSelect mainClass="form-control" [controlTabIndex]="26" (changed)="setAnniversaryDateList($event,false)"
                  [keyValueProps]="['month','id']" formControlName="anniversaryMonthTo"
                  [selectPlaceholder]="'Month'"></rx-select>
                  <ng-container [rxLocalisationInit]="componentName" *ngIf="anniversaryMonthToError==true">
                    <small class="form-text text-danger" rxText="UserListComponent_month_error_t"></small>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-4" *ngIf="anniversaryDateToError==true || anniversarydateError == true"></div>
              <div class="col-md-8" *ngIf="anniversaryDateToError==true || anniversarydateError == true">
                <ng-container *ngIf="anniversaryDateToError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="UserListComponent_date_error_t"></small>
                </ng-container>
                <ng-container *ngIf="anniversarydateError==true" [rxLocalisationInit]="componentName">
                  <small class="form-text text-danger" rxText="UserListComponent_date_of_anniversary_error_t"></small>
                </ng-container>
              </div>
            </div>

          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
            rxText="UserListComponent_Corporates_t"></label>
          <div class="col-md-8" [rxSelectExtended]="_this">
            <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="corporates"
              [valueInQueryParams]="true" [queryParams]="{'lookupType':'corporates'}" [controlTabIndex]="29"
              path="api/SearchEvent/GetLookups" [keyValueProps]="['#name#','id']" [minimumCharacterSearchLength]="2"
              [enableFreeText]="true" (keyup)="checkCorpText($event)" (keyup.enter)="checkCorpText($event)" (changed)="onCorpClick($event)"
              [text]="searchCorporateName" [selectPlaceholder]="'Select Corporate'">
            </rx-select>
          </div>
          </div>
        </div>
        <div class="col-xl-4 pl-xl-5" *ngIf="resetCommonControl" [rxLocalisationInit]="componentName">

          <div class="form-group row">
            <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                   rxText="UserListComponent_UserId_t"></label>
            <div class="col-md-8 ">
              <div class="d-flex">
                <input type="text" class="form-control mr-3" tabindex="1" formControlName="userId"
                       rxPlaceholder="UserListComponent_UserId_p" (keyup.enter)="goTo()"/>
                <button (click)="goTo()" class="btn btn-success search-result-btn" [rxLocalisationInit]="componentName"><span rxText="Btn_Go_t"></span></button>
              </div>
              <small class="form-text text-danger" *ngIf="msgError==true" [rxLocalisationInit]="componentName"><span rxText="UserListComponent_Go_Error_t"></span><br /></small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Category_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <!-- <rx-select [(source)]="userLookups.categoryLookUp" mainClass="form-control" formControlName="cateogry" [controlTabIndex]="14"
                [keyValueProps]="['categoryName','categoryId']" [selectPlaceholder]="'Select Category'"></rx-select> -->
              <rx-tag [(source)]="categoryLookup" #rxTag mainClass="form-control" formControlName="cateogry"
                [controlTabIndex]="3" [keyValueProps]="['categoryName','categoryId']" placeholder="Select Category"
                [isSort]="false"></rx-tag>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Collection_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
              <!-- <rx-select [(source)]="userLookups.parentCollectionLookup" mainClass="form-control" [controlTabIndex]="3"
                formControlName="collection" [keyValueProps]="['name','collectionId']" [selectPlaceholder]="'Select Collection'"></rx-select> -->
              <rx-tag [source]="collectionLookup" #rxTag [controlTabIndex]="6" [isSort]="false" formControlName="collection"
                mainClass="form-control" placeholder="Select Collection"
                [keyValueProps]="['name','specificationAttributeOptionId']"></rx-tag>
            </div>
          </div>

          <div class="form-group row checkbox">
            <!-- <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Juvenile?</label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <div class="icheckbox_square-green" style="position: relative;">
                    <input type="checkbox" style="position: absolute; opacity: 0;">
                    <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;">
                    </ins>
                  </div>
                  <i></i>
                </label>
              </div>
            </div> -->
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Juvenile_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <!-- <input type="checkbox" formControlName="juvenile"><i></i> -->
                  <app-checkbox [checkFormControl]="userSearchFilterViewModelFormGroup.controls.juvenile"
                    [tabIndex]="'9'">
                  </app-checkbox>
                </label>
              </div>
              <!-- <small class="form-text text-danger" [class.d-block]="countryFormGroup.controls.allowsRegistration.errors">{{countryFormGroup.controls.allowsRegistration.errorMessage}}<br /></small> -->
            </div>
          </div>

          <div class="form-group row checkbox">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Arrested_t"></label>
            <div class="col-md-auto d-flex align-items-center">

              <div class="i-checks">

                <label class="mb-0">
                  <!-- <input type="checkbox" formControlName="arrested"><i></i> -->
                  <app-checkbox [tabIndex]="'12'"
                    [checkFormControl]="userSearchFilterViewModelFormGroup.controls.arrested">
                  </app-checkbox>
                </label>
              </div>
              <!-- <small class="form-text text-danger" [class.d-block]="countryFormGroup.controls.allowsRegistration.errors">{{countryFormGroup.controls.allowsRegistration.errorMessage}}<br /></small> -->
            </div>
            <!-- <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Arrested?</label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <div class="icheckbox_square-green" style="position: relative;"><input type="checkbox" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div><i></i>
                </label>
              </div>
            </div> -->
          </div>

          <div class="form-group row checkbox">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Deceased_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <app-checkbox [tabIndex]="'14'"
                    [checkFormControl]="userSearchFilterViewModelFormGroup.controls.deceased">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div>

          <!-- <div class="form-group row checkbox">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText=" UserListComponent_artisanal_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <app-checkbox [checkFormControl]="userSearchFilterViewModelFormGroup.controls.artisanalIllegalMiner" [tabIndex]="'12'">
                  </app-checkbox>
                </label>
              </div>
            </div>
          </div> -->
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_artisanal_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="minerTypeLookup" #rxSelect [controlTabIndex]="17" formControlName="artisanalIllegalMiner"
                mainClass="form-control" [keyValueProps]="['type','id']" [selectPlaceholder]="'Select Value'">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="userSearchFilterViewModelFormGroup.controls.artisanalIllegalMiner.errors">{{userSearchFilterViewModelFormGroup.controls.artisanalIllegalMiner.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_Nationality_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="userLookups.nationalityLookUp" #rxSelect formControlName="nationality"
                  mainClass="form-control" [controlTabIndex]="23" [keyValueProps]="['nationalityName','nationalityId']"
                  [selectPlaceholder]="'Select Nationality'"></rx-select>
              <small class="form-text text-danger"
                [class.d-block]="userSearchFilterViewModelFormGroup.controls.nationality.errors">{{userSearchFilterViewModelFormGroup.controls.nationality.errorMessage}}</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="UserListComponent_workPhoneNumber_t"></label>
            <div class="col-md-8">
                <input type="text" class="form-control" placeholder="" tabindex="27" formControlName="phoneNumber"
                  rxPlaceholder="UserListComponent_workPhoneNumber_p" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="UserListComponent_profile_t"></label>
            <div class="col-md-8">
                <input type="text" class="form-control" placeholder="" tabindex="28" formControlName="profile"
                  rxPlaceholder="UserListComponent_profile_p" />
            </div>
          </div>
          <!-- <div class="form-group row" *ngIf="resetCommonControl">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_AnniversaryDateFrom_t"></label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date class="w-100" showAddon="true" formControlName="anniversaryDateFrom" [controlTabIndex]="15"
                    placeholder="Enter Anniversary Date From" pickerClass="form-control">
                  </rx-date>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-5">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_AnniversaryDateTo_t"></label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date class="w-100" showAddon="true" formControlName="anniversaryDateTo" [controlTabIndex]="17"
                    placeholder="Enter Anniversary Date To" pickerClass="form-control">
                  </rx-date>
                  <small class="form-text text-danger" *ngIf="anniversarydateError==true">Anniversary Date To should be greater
                    than Anniversary Date From<br /></small>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="form-group row" *ngIf="resetCommonControl">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_AnniversaryDateFrom_t"></label>
            <div class="col-md-4">
              <div class="" id="data_1" *ngIf="showAnniversaryDateFrom">
                <rx-select [(source)]="anniversaryDateListFrom" mainClass="form-control" [controlTabIndex]="19"
                [keyValueProps]="['date','id']" formControlName="anniversaryFromDate"
                [selectPlaceholder]="'Date'"></rx-select>
              </div>
              <small class="form-text text-danger" *ngIf="anniversaryDateFromError==true" rxText="UserListComponent_date_error_t"></small>
            </div>
            <div class="col-md-4">
              <div class="" id="data_1">
                <rx-select [(source)]="monthList" mainClass="form-control" [controlTabIndex]="19" (changed)="setAnniversaryDateList($event,true)"
                [keyValueProps]="['month','id']" formControlName="anniversaryMonthFrom"
                [selectPlaceholder]="'Month'"></rx-select>
                <small class="form-text text-danger" *ngIf="anniversaryMonthFromError==true" rxText="UserListComponent_month_error_t"></small>
              </div>
            </div>

          </div>
          <div class="form-group row mb-5">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="UserListComponent_AnniversaryDateTo_t"></label>
            <div class="col-md-4">
              <div class="" id="data_1" *ngIf="showAnniversaryDateTo">
                <rx-select [(source)]="anniversaryDateListTo" mainClass="form-control" [controlTabIndex]="22"
                [keyValueProps]="['date','id']" formControlName="anniversaryToDate"
                [selectPlaceholder]="'Date'"></rx-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="" id="data_1" *ngIf="showAnniversaryDateTo">
                <rx-select [(source)]="monthList" mainClass="form-control" [controlTabIndex]="22" (changed)="setAnniversaryDateList($event,false)"
                [keyValueProps]="['month','id']" formControlName="anniversaryMonthTo"
                [selectPlaceholder]="'Month'"></rx-select>
                <small class="form-text text-danger" *ngIf="anniversaryMonthToError==true" rxText="UserListComponent_month_error_t"></small>
              </div>
            </div>
            <div class="col-md-4" *ngIf="anniversaryDateToError==true || anniversarydateError == true"></div>
            <div class="col-md-8" *ngIf="anniversaryDateToError==true || anniversarydateError == true">
              <small class="form-text text-danger" *ngIf="anniversaryDateToError==true" rxText="UserListComponent_date_error_t"></small>
              <small class="form-text text-danger" *ngIf="anniversarydateError==true" >Anniversary Date To should be greater
                than Anniversary Date From</small>
            </div>
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="hr-line-dashed border-top w-100"></div>
      </div>
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <!--<div class="hr-line-dashed border-top w-100"></div>-->
        <div class="col text-right px-0">
          <button (click)="onKeyPress()" tabindex="30" class="btn btn-success mr-1 search-result-btn" rxText="Btn_Search_t"></button>
          <button (click)="resetSearch()" tabindex="31" class="btn btn-primary mr-1" rxText="Btn_Reset_t"></button>

        </div>
      </div>

      <!--<div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <label class="ml-auto mr-0 mb-3">
          <input type="search" (keyup)="search($event)" class=" table_search form-control form-control-sm pr-4" placeholder="">
        </label>
      </div>-->
      <div class="table-responsive">
        <div id="application-exception-logs" class="dataTables_wrapper pb-0 dt-bootstrap4">
        </div>
      </div>

    </div>
  </div>
  <div class="search-result-container">

    <div class="ibox mb-0 d-flex flex-column mt-4" *ngIf="isSearch">

      <div class="ibox-title bg-white" [rxLocalisationInit]="componentName">
        <h5 rxText="Label_Search_Result_t"></h5>
        <div class="ibox-tools">
          <!-- <label class="ml-auto mr-0 mb-3">
            <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
              placeholder="">
          </label> -->
          <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
            <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
            (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
            [gridColumnConfig]="usersGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID">
          </app-system-table-setting>
        </div>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="table-responsive">
          <div class="dataTables_wrapper pb-0 dt-bootstrap4">
            <rx-grid [design]="usersGrid"></rx-grid>
          </div>

        </div>
      </div>
      <!-- <div class="table-responsive">
        <div id="users-Grid" class="dataTables_wrapper pb-0 dt-bootstrap4">

        </div>

      </div> -->
    </div>
    <div class="ibox-title bg-white" *ngIf="isNoRecordFound" [rxLocalisationInit]="componentName">
      <h5 rxText="Label_Search_Result_t"></h5>
      <div>
        <h4>No Record Found</h4>
      </div>
    </div>

  </div>
</div>
