import { gridColumn } from "@rxweb/grid"


export class vProjectEventsBase {

        @gridColumn({ style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
        isChecked: boolean

        //#region projectEventMappingID Prop
        @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'ProjectEventListComponent_ProjectEventMappingID_gh', keyColumn: true })
        projectEventMappingID: number;
        //#endregion projectEventMappingID Prop

        //#region projectID Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'ProjectEventListComponent_ProjectID_gh', keyColumn: false })
        projectID: number;
        //#endregion projectID Prop

        //#region eventID Prop
        @gridColumn({ style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 3, allowSorting: true, headerKey: 'ProjectEventListComponent_EventID_gh', keyColumn: false })
        eventID: number;
        //#endregion eventID Prop

        //#region eventName Prop
        @gridColumn({
                configuredTemplate: { templateName:"reDirect" },
         style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 4, allowSorting: true, headerKey: 'ProjectEventListComponent_EventName_gh', keyColumn: false })
        eventName: string;
        //#endregion eventName Prop

        //#region eventTypeID Prop
        @gridColumn({ style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: true, headerKey: 'ProjectEventListComponent_EventTypeID_gh', keyColumn: false })
        eventTypeID: number;
        //#endregion eventTypeID Prop

        //#region eventType Prop
        @gridColumn({ style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 6, allowSorting: true, headerKey: 'ProjectEventListComponent_EventType_gh', keyColumn: false })
        eventType: string;
        //#endregion eventType Prop

        //#region eventStartDate Prop
        @gridColumn({ style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 7, allowSorting: true, headerKey: 'ProjectEventListComponent_EventStartDate_gh', keyColumn: false })
        eventStartDate: string;
        //#endregion eventStartDate Prop

        //#region eventEndDate Prop
        @gridColumn({ style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 8, allowSorting: true, headerKey: 'ProjectEventListComponent_EventEndDate_gh', keyColumn: false })
        eventEndDate: string;
        //#endregion eventEndDate Prop

        //#region noteDescription Prop
        // @gridColumn({ style: { width: "15%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:9, allowSorting: true, headerKey: 'noteDescription', keyColumn: false})
        // noteDescription : string;
        @gridColumn({
                template: {
                        div: {
                                style: {
                                        "word-break": "break-all",
                                },
                                attributes: {
                                        innerHTML: function (x, y) {
                                                return this.noteDescription;
                                        }
                                },
                                childrens: [{
                                        a: {
                                                isBind: (x) => {
                                                        if (x.noteCharCount > 100 && !x.isShowAll) {
                                                                return true;
                                                        }
                                                        else {
                                                                return false;
                                                        }
                                                }, style: {
                                                        "display": "block"
                                                },
                                                childrens: [{
                                                        span: {
                                                                class: "more-btn mr-2".split(" "),
                                                                childrens: [{
                                                                        text: {
                                                                                text: function (e) {
                                                                                        return "Show More";
                                                                                }
                                                                        }
                                                                }]
                                                        }
                                                }],
                                                event: {
                                                        click: "onMoreNotelick"
                                                }
                                        },
                                }]
                        }
                }, visible: true,parameter:false,keyColumn:false, columnIndex: 9, allowSorting: true, headerKey: 'ProjectEventListComponent_NoteDescription_gh', name: 'noteDescription', style: { width: "5%", "text-align": "initial" }, class: ["text-left"],
        })

        noteDescription: any;
        //#endregion noteDescription Prop


        // @gridColumn({
        //         template: {
        //                 div: {
        //                         style: {
        //                                 "word-break": "break-all",
        //                         },
        //                         attributes: {
        //                                 innerHTML: function (x, y) {
        //                                         return this.noteDescription1;
        //                                 }
        //                         }
        //                 }
        //         }, visible: false, columnIndex: 9, allowSorting: true, headerKey: 'ProjectEventListComponent_NoteDescription_gh', name: 'noteDescription1', style: { width: "5%", "text-align": "initial" }, class: ["text-left"],
        // })

        // noteDescription1: any;
        //#region noteCharCount Prop
        @gridColumn({ visible: false, columnIndex: 10, keyColumn: false })
        noteCharCount: number;
        //#endregion noteCharCount Prop

        //#region noteId Prop
        @gridColumn({ visible: false, columnIndex: 11, keyColumn: false })
        noteId: number;
        //#endregion noteId Prop

}