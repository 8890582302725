import {vPOSSalesChannelLookUpBase,} from '@app/database-models'
//Generated Imports
export class vPOSSalesChannelLookUp extends vPOSSalesChannelLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}