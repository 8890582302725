import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vStateProvinceRecordBase {

//#region stateProvinceId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'stateProvinceId', keyColumn: true})
        stateProvinceId : number;
//#endregion stateProvinceId Prop


//#region countryId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'countryId', keyColumn: false})
        countryId : number;
//#endregion countryId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region abbreviation Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'abbreviation', keyColumn: false})
        abbreviation : string;
//#endregion abbreviation Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}