import {TripTypeBase,} from '@app/database-models'
//Generated Imports
export class TripType extends TripTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}