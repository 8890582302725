import {ColorLocalizationBase,} from '@app/database-models'
//Generated Imports
export class ColorLocalization extends ColorLocalizationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties






































}