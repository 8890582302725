import { prop } from '@rxweb/reactive-form-validators';

//Generated Imports
export class vProjectEventMappingsBase {

@prop()
projectId:number;

@prop()
projectName:string;


//#region Generated Reference Properties

//#endregion Generated Reference Properties










}