import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from "@angular/core"
import { AbstractCustomerHoliday } from '../domain/abstract-customer-holiday';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CustomerHoliday } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { WeekDaysEnum } from 'src/app/enums/week-days.enum';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { HolidayInfoViewModel } from 'src/app/view-model/holiday-info-view-model';
import { DatePipe } from '@angular/common';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@multilingual("CustomerHolidayEditComponent")
@access({ accessLevel: RolePermissionEnums.HolidayProcess, action: "put" })


@Component({
  selector: "app-customer-holiday-edit",
  templateUrl: './customer-holiday-edit.component.html'
})
export class CustomerHolidayEditComponent extends AbstractCustomerHoliday implements OnInit, OnDestroy {
  customerHoliday: CustomerHoliday;
  subscription: any;
  id: number;
  isHolidayList: boolean = false;
  decryptstring: string;
  Jsonstring: any;
  localizedMessages: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private title: Title, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private applicationBroadcaster: ApplicationBroadcaster, private router: Router, private datePipe: DatePipe, modalView: ModalView, private el: ElementRef) {
    super();
    this.modalView = modalView;
    applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    });
    this.moduleID = RolePermissionEnums.HolidayProcess;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
    this.title.setTitle("Holiday Process - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.spin = true;
    this.lookup([
      { params: [this.id], propName: "customerHoliday" },
      { propName: "leaveTypeLookUp", path: USER_LOOKUPS.holidayTypeLookup },
      { propName: "weekDaysLookUp", path: USER_LOOKUPS.weekDaysLookUp },
      { propName: "adminUserLookup", path: USER_LOOKUPS.adminUserLookup }
    ]).subscribe((lookup: any) => {
      this.holidayProcessLookups = lookup;
      this.customerHoliday = new CustomerHoliday();
      this.customerHoliday.userId = this.userId;
      this.customerHolidayFormGroup = this.formBuilder.formGroup(CustomerHoliday, this.holidayProcessLookups.customerHoliday) as IFormGroup<CustomerHoliday>;
      
      if (!this.vYearLookUp.find(i => i.id === this.holidayProcessLookups.customerHoliday.forYear)) {
        let selectedyear = this.holidayProcessLookups.customerHoliday.forYear;
        this.vYearLookUp = [];
        for (let i = selectedyear; i < new Date().getFullYear() + 5; i++) {
          this.vYearLookUp.push({ id: i, year: i, });
        }
      }

      this.showComponent = true;
      setTimeout(() => {
        this.checkboxChecked(WeekDaysEnum.Monday);
        this.checkboxChecked(WeekDaysEnum.Tuesday);
        this.checkboxChecked(WeekDaysEnum.Wednesday);
        this.checkboxChecked(WeekDaysEnum.Thursday);
        this.checkboxChecked(WeekDaysEnum.Friday);
        this.checkboxChecked(WeekDaysEnum.Saturday);
        this.checkboxChecked(WeekDaysEnum.Sunday);
      }, 0);
      this.spin = false;
    });
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
  }

  checkboxChecked(WeekDay: number) {
    if (document.getElementById(WeekDay.toString()) !== null) {
      var array = this.customerHolidayFormGroup.value.weekOff.split(',');
      if (array.includes(WeekDay.toString())) {
        var el = <HTMLInputElement>document.getElementById(WeekDay.toString());
        el.checked = true;
      }
    }
  }

  isChecked(WeekDay: number) {
    if (document.getElementById(WeekDay.toString()) !== null) {
      var el = <HTMLInputElement>document.getElementById(WeekDay.toString());
      if (el.checked == true) {
        if (this.customerHolidayFormGroup.value.weekOff == '') {
          this.customerHolidayFormGroup.value.weekOff = this.customerHolidayFormGroup.value.weekOff + WeekDay.toString();
        } else {
          this.customerHolidayFormGroup.value.weekOff = this.customerHolidayFormGroup.value.weekOff + ',' + WeekDay.toString();
        }
      }
    }
  }

  editHoliday(isRedirect: boolean) {

    this.customerHolidayFormGroup.value.weekOff = '';
    this.isChecked(WeekDaysEnum.Monday);
    this.isChecked(WeekDaysEnum.Tuesday);
    this.isChecked(WeekDaysEnum.Wednesday);
    this.isChecked(WeekDaysEnum.Thursday);
    this.isChecked(WeekDaysEnum.Friday);
    this.isChecked(WeekDaysEnum.Saturday);
    this.isChecked(WeekDaysEnum.Sunday);
    if (this.customerHolidayFormGroup.value.weekOff == null || this.customerHolidayFormGroup.value.weekOff == '') {
      this.customerHolidayFormGroup.value.weekOff = '0'
    }
    this.customerHolidayFormGroup.submitted = true;
    if (this.customerHolidayFormGroup.valid) {
      this.spin = true;
      this.put({ body: this.customerHolidayFormGroup.value, params: [this.id] }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
        if (existsAlert) {
          //this.toastr.success("Data Updated Successfully")
          this.toastr.success(existsAlert[0].showMessage);
        }
        isRedirect ? this.router.navigate(['/customer-holidays', this.id]) : this.router.navigate(['/customer-holidays']);
      })
    } else {
      for (const key of Object.keys(this.customerHolidayFormGroup.controls)) {
        if (this.customerHolidayFormGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "CustomerHolidayEditComponent";
  }

}
