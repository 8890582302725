import { numeric, pattern, prop, range, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentLegalAgreementFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "false";
        this.orderBy = "legalAgreementId";
        this.agreementType = 0;
    }

    @numeric({allowDecimal:false,message:"Please enter valid Legal Agreement Id"})
    @pattern({expression:{"legalAgreementId":/^[0-9]/},message:"Please enter valid Legal Agreement Id"})
    @range({minimumNumber:1,maximumNumber:2147483647,message:"Please enter valid Legal Agreement Id"})
    legalAgreementId: number;

    @prop()
    keyword: String;

    @prop()
    agreementType: number;

    @prop()
    leader: number;

    @prop()
    supplierId:number;

    @prop()
    supplierIdText:string;

    @prop()
    counterPartyUserId:number;

    @prop()
    counterPartyUserIdText:string;

    @prop()
    specificRiskId: number;

    @prop()
    startDate: Date;

    @prop()
    endDate: Date;

    @prop()
    expiryDateFrom: Date;

    @prop()
    expiryDateTo: Date;

    @prop()
    @trim()
    agreementTypeText: string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    searchString: string;  

}
