import {vGemStoneClarityBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneClarity extends vGemStoneClarityBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}