import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

// @actionColumn({
//     allowSorting: false,
//     visible:true,
//     parameter:true,
//     headerTitle:'',
//     style: { width: "5%","text-align": "initial"},
//     configuredHeaderTemplate: { templateName: 'knownToHeaderCheckbox' }, columnIndex: 0
//   })
@actionColumn({
    allowSorting: false,
    style: { width: "3%","text-align": "initial"},
    headerTitle:'        ',
    configuredHeaderTemplate: { templateName: 'bindKnownToHeaderCheckBox' }, columnIndex: 1
  })
export class KnownToUserListViewModel {


//#region id Prop
    @gridColumn({style: { width: "1%", "text-align": "initial" },visible: false, columnIndex: 13, headerTitle: '', keyColumn: true,allowSorting:false})
    id : string;
//#endregion id Prop

//#region isChecked Prop
@gridColumn({style: { width: "1%", "text-align": "initial" },visible: true, columnIndex: 0, headerKey: 'KnownToAddComponent_Action_gh',configuredTemplate: { templateName: 'bindCheckBox' },allowSorting:false})
isChecked : boolean;
//#endregion isChecked Prop


//#region fullName Prop
    @gridColumn({style: { width: "20%", "text-align": "left"}, class: ["text-left"], visible: true, columnIndex:2, configuredTemplate: { templateName: 'peopleNameAndTooltip' }, headerKey: 'KnownToAddComponent_Name_gh', keyColumn: false,allowSorting:true})
    fullName : string;
//#endregion fullName Prop

//#region email Prop
    @gridColumn({style: { width: "30%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:2,headerKey: 'KnownToAddComponent_Email_gh', keyColumn: false,allowSorting:true})
    email : string;
//#endregion email Prop

//#region statusId Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:9,headerKey: 'KnownToAddComponent_verified_gh', keyColumn: false,  configuredTemplate: { templateName:"recordStatus" },allowSorting:false})
    statusId : number;
//#endregion statusId Prop

//#region registrationDate Prop
    @gridColumn({style: { width: "8%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:10,headerKey: 'KnownToAddComponent_RegistraionDate_gh', keyColumn: false,allowSorting:true})
    registrationDate : string;
//#endregion registrationDate Prop

//#region ralationShipName Prop
  //  @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:5,headerKey: 'relationShip', keyColumn: false,allowSorting:true})
    //  @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:5,headerKey:'relationShip',configuredTemplate:{templateName:"relationshipSelect"}, keyColumn: false,allowSorting:false})
    // relationShipName : string;
//#endregion ralationShipName Prop

//#region ralationShipName Prop
   @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:3,headerKey: 'KnownToAddComponent_RelationShip_gh', keyColumn: false})
  relationShipId : number;
//#endregion ralationShipName Prop


//#region dropdownData Prop
//@gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:6,headerTitle: 'RelationShip', keyColumn: false})
    @gridColumn({style: { width: "40%", "text-align": "left", "min-width":"170px" },
       template: {
           div: {
               onDemandSelector: {
                   selector: 'rx-select', columnName: 'relationShipId'
               }
           }
       }, headerKey: "KnownToAddComponent_RelationShip_gh", name: "relationShipId", columnIndex: 4,class: ["text-left"],visible:true,allowSorting:false
    })
dropdownData : string;
//#endregion dropdownData Prop

//#region currentUsername Prop
@gridColumn({template: {
    div: {
      childrens: [{
        span: {
          attributes: {
            innerHTML: function (x, y) {
              return this.currentUsername;
            }
          },
        }
      }]
    }}, style: { width: "10%", "min-width":"180px", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: false, headerKey: 'KnownToAddComponent_CurrentUserName_gh', keyColumn: false })
  currentUsername: string;
  //#endregion currentUsername Prop

//#region parentRelationshipId Prop
@gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:5,headerKey: 'KnownToAddComponent_RelationShip_gh', keyColumn: false})
parentRelationshipId : number;
//#endregion parentRelationshipId Prop

//#region parentRelationshipDropdown Prop
@gridColumn({style: { width: "40%", "text-align": "left", "min-width":"170px" },
template: {
    div: {
        onDemandSelector: {
            selector: 'rx-select', columnName: 'parentRelationshipId'
        }
    }
}, headerKey: "KnownToAddComponent_CurrentUserRelationship_gh", name: "parentRelationshipId", columnIndex: 5,class: ["text-left"],visible:true,allowSorting:false
})
parentRelationshipDropdown : string;
//#endregion parentRelationshipDropdown Prop


@gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:5,headerKey: 'KnownToAddComponent_RelationShip_gh', keyColumn: false})
relationshipStatusId : number;

@gridColumn({style: { width: "40%", "text-align": "left", "min-width":"170px" },
template: {
    div: {
        onDemandSelector: {
            selector: 'rx-select', columnName: 'relationshipStatusId'
        }
    }
}, headerKey: "KnownToAddComponent_RelationshipStatus_gh", name: "relationshipStatusId", columnIndex: 6,class: ["text-left"],visible:true,allowSorting:false
})
relationshipStatusDropdown : string;

//#region isEmergencyContact Prop
@gridColumn({ allowSorting: false, style: { width: "1%", "text-align": "initial" }, class: ["text-centre"], visible: true, configuredTemplate: { templateName: 'bindEmergencyContactCheckBox' }, columnIndex: 8, headerKey: 'KnownToAddComponent_EmergencyContact_gh', keyColumn: false })
isEmergencyContact: boolean
//#endregion isEmergencyContact Prop

//#region isClose Prop
@gridColumn({ allowSorting: false, style: { width: "1%", "text-align": "initial" }, class: ["text-centre"], visible: true, configuredTemplate: { templateName: 'bindIsCloseCheckBox' }, columnIndex: 7, headerKey: '', keyColumn: false,configuredHeaderTemplate:{
    templateName: 'bindHeaderInformation'
  } })
isClose: boolean
//#endregion isClose Prop



//#region totalCount Prop
@prop()
    totalCount : number;
//#endregion totalCount Prop

@prop({defaultValue:false})
isSelected:boolean;

@prop()
contactPermissionRequired: boolean;

@prop()
introducesBy: string;

@prop()
relationshipLookup:any;

@prop()
relationshipStatusLookUp:any;

@prop()
parentRelationshipStatusLookUp:any;

@prop()
isUnwelcome: boolean;
}
