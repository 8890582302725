import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RiskCategoryBase {

//#region RiskCategoryId Prop
    @prop()
    riskCategoryId : number;
//#endregion RiskCategoryId Prop

//#region RiskCategoryName Prop
    @required({message:"Risk Category Name is required"})
    @notEmpty()
    @trim()
    riskCategoryName : string;
//#endregion RiskCategoryName Prop

//#region StatusId Prop
@prop({defaultValue:307})
statusId : number;
//#endregion StatusId Prop

}