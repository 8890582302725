<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Payment_Methods_t"></li> 
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>
<div class="wrapper wrapper-content "  [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_Payment_Methods_t"></label></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="table-responsive">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
          <div class="dataTables_filter  d-flex align-items-center mb-3">
            <a [routerLink]="['/payment-methods/add']" class="btn btn-success btn-sm"
              *rxAuthorize="[paymentMethodAdd]" rxText="Btn_Add_New_t"></a>
            <label class="mb-0 ml-auto mr-0">
              <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm"
                placeholder="" [title]="titleSearch">
            </label>
          </div>
          <div id="payment-methods" class="dataTables_wrapper pb-0 dt-bootstrap4" *ngIf="!isNoRecordFound">

          </div>
          <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>