import { debug } from 'util';

declare const $: any;
declare const jQuery: any;
declare const Timeline: any;


export function importTimelineChart(dataSource: any[],elementId:string) {

  dataSource.forEach(x => {
    if(x.end != null){      
            x.end = x.end +' GMT-2359'
    }
        })

  // dataSource = 
  //  [
  //   {
  //     // 'start': '2021',
  //     // 'end': '2021',
  //     'title': 'Barfusserkirche assssadsd fdf gfgthyuu fhyghhu',      
  //     'image': 'http://images.allposters.com/images/AWI/NR096_b.jpg',
  //     'link': 'http://www.allposters.com/-sp/Barfusserkirche-1924-Posters_i1116895_.htm',     
  //     'icon' : "http://127.0.0.1:4200/assets/js/plugins/timeline/images/dark-red-circle.png",
  //     'isDuration' : false, 
  //     'description':'<table><tr><td>kkkk</td></tr></table>',
  //     'textColor' : 'green',
  //     // 'start': 'Wed Sep 25 2021',
  //     // 'end': 'Wed Sep 26 2021',
  //     'start':"Wed May 01 2021 00:00:00 GMT-0000" ,
  //     'end':"Sat May 02 2021 00:00:00 GMT-2359" ,
  //     'durationEvent' : false,
  //     // 'start' : new Date(2021, 11-1, 4, 5, 30, 0, 0),
  //     // 'end' : new Date(2021, 11-1, 6, 5,30, 0,0)
  //   },


  //   {
  //     'start': 'Wed Sep 25 2021',
  //     'end': 'Wed Sep 26 2021',
  //     'title': 'Three Figures dfdgfhg fghgnj fjdgbhfbd fdngjkfdn fngjkfdnbg fngfjkdn fnjgbn fdngjkfdng',
  //     'description': 'by Kasimir Malevich, Ukrainian Painter, 1878-1935',
  //     'isDuration' : true,
  //     'link': 'http://www.allposters.com/-sp/Three-Figures-1913-28-Posters_i1349989_.htm',    
  //     'color':'#2084c3',
  //     'icon' : "http://127.0.0.1:4200/assets/js/plugins/timeline/images/dark-red-circle.png",
  //     'textColor' : '#2084c3'
  //   },



  //   {
  //     'start': 'Fri Oct 11 2020 00:00:00',
  //     'end': 'Fri Nov 22 2020 11:10:00',
  //     'title': 'Portrait of Pablo Picasso',
  //     'description': 'by Juan Gris, Spanish Painter/Sculptor, 1887-1927',
  //     'image': 'http://images.allposters.com/images/BRGPOD/156514_b.jpg',
  //     'link': 'http://www.allposters.com/-sp/Portrait-of-Pablo-Picasso-1881-1973-1912-Posters_i1344154_.htm'
  //   },


  //   {
  //     'start': 'Wed Oct 16 2020 00:00:00',
  //     'end': 'Wed Nov 20 2020 00:00:00',
  //     'title': 'Portrait of Horace Brodsky',
  //     'description': 'by Henri Gaudier-Brzeska, French Sculptor, 1891-1915',
  //     'image': 'https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&w=1000&q=80',
  //     'link': 'http://www.allposters.com/-sp/Portrait-of-Horace-Brodsky-Posters_i1584413_.htm',

  //   },
  //   {'start': '2020',
  //   'end': '2021',
  //   'title': 'Castor Et Pollux',
  //   'description': 'by Roger de la Fresnaye, French Painter, 1885-1925',
  //   'image': 'http://images.allposters.com/images/CORPOD/IX001463_b.jpg',
  //   'link': 'http://www.allposters.com/-sp/Castor-Et-Pollux-Posters_i831718_.htm',
  //   'tapeImage': 'blue_stripes.png',
  //   'tapeRepeat': 'repeat-x',
  //   'caption': "This is the event's caption attribute.",
  //   'classname': 'hot_event' 
  //   },
  // ]
  var timeline_data = {
    'events': dataSource
  }
  var tl;  

  // var eventSource = new Timeline.DefaultEventSource();

  // var theme = Timeline.ClassicTheme.create();
  // theme.event.bubble.width = 320;
  // theme.event.bubble.height = 220;
  // var d = Timeline.DateTime.parseGregorianDateTime("2012")

  // var bandInfos = [
  //   Timeline.createBandInfo({
  //     width: "100%",
  //     intervalUnit: Timeline.DateTime.MONTH,
  //     intervalPixels: 200,
  //     eventSource: eventSource,
  //     date: d,
  //     theme: theme
  //   })
  // ];
  // Timeline.createBandInfo = function(params) {
  //   return {
  //     width:          params.width,
  //     eventSource:    params.eventSource ,
  //     timeZone:       params.timeZone ,
  //     ether:          new Timeline.LinearEther({
  //                       interval:params.intervalUnit,
  //                       pixelsPerInterval: params.intervalPixels,
  //                       centersOn:         params.date 
  //                     }),
  //     etherPainter:   new Timeline.GregorianEtherPainter({
  //                       unit: params.intervalUnit,
  //                       theme: params.theme 
  //                     }),
  //     eventPainter:   new Timeline.DurationEventPainter({
  //                       showText:          params.showEventText,
  //                       theme:            params.theme,
  //                       trackHeight:       params.trackHeight,
  //                       trackGap:          params.trackHeight,
  //                       layout:            new Timeline.StaticTrackBasedLayout({
  //                                            eventSource:params.eventSource,
  //                                            ether: params.ether,
  //                                            showText:    params.showEventText,
  //                                            theme:          params.theme
  //                                          })
  //                     })
  //   }
  // };

 

  var minDate =dataSource[0].start;
  
  // var valuelength=19;
  // var dynamicWidth = "0";
  // var flexWidth;
  //       if (valuelength <= 19)
  //       {
  //          flexWidth="95%"
  //       }
  //       else
  //       {
  //           flexWidth= flexWidth*valuelength
  //       }
        var eventSource = new Timeline.DefaultEventSource(0);   
        var eventSource_test = new Timeline.DefaultEventSource(0);      
        //Timeline.OriginalEventPainter.prototype._showBubble = function (x, y, evt) { alert("hii"); }
        var theme = Timeline.ClassicTheme.create();        
        theme.event.bubble.width = 320;
        theme.event.bubble.height = 120;        
      
         var d = Timeline.DateTime.parseGregorianDateTime("1900")
        var bandInfos = [
          Timeline.createBandInfo({
              eventSource:    eventSource,
              date:           minDate.toString(),
              width:          "95%", 
              intervalUnit:   Timeline.DateTime.DAY, 
              intervalPixels: 100,
              theme:theme,
              layout: 'original'
            
          }),
          Timeline.createBandInfo({
              eventSource:    eventSource,
              date:            minDate.toString(),
              width:          "95%",
              intervalUnit:   Timeline.DateTime.MONTH, 
              intervalPixels: 200,
              theme:theme,
              layout: 'overview',              
            
          })
        ];
        bandInfos[1].syncWith = 0;
        bandInfos[1].highlight = true;
    
      
        tl = Timeline.create(document.getElementById(elementId), bandInfos, Timeline.HORIZONTAL);                
        var url = ''; // The base url for image, icon and background image references in the data    
        console.log("eventSource",eventSource)  
        eventSource.loadJSON(timeline_data,"."); // The data was stored into the timeline_data variable.
        console.log("timeline_data",timeline_data)  
        setTimeout(() => {
          var loaderElement= document.getElementsByClassName("timeline-message-container") as HTMLCollectionOf<Element>
        if(loaderElement!=null && loaderElement!=undefined){
          for(let i=0;i<loaderElement.length;i++){
            loaderElement[i].remove();
          }
        }
        }, 6000);
        
       

        //tl.layout();        

}


