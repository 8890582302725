import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ApplicationObjectBase {

//#region applicationObjectId Prop
        @prop({defaultValue:0})
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ApplicationObjectListComponent_App_Obj_Id_gh', keyColumn: true })
        applicationObjectId : number;
//#endregion applicationObjectId Prop


//#region applicationObjectTypeId Prop
        @prop()
        @required()
        applicationObjectTypeId: number;
//#endregion applicationObjectTypeId Prop


//#region applicationObjectName Prop
        @required()
        @maxLength({value:100})
        @gridColumn({style: { width: "35%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'ApplicationObjectListComponent_App_Obj_Name_gh', keyColumn: false })
        applicationObjectName : string;
//#endregion applicationObjectName Prop



        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ApplicationObjectListComponent_Type_Name_gh', keyColumn: false })
        applicationObjectTypeName : string;


}
