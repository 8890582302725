import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProjectTypeLookUpBase {

//#region projectTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'projectTypeId', keyColumn: true})
        projectType : number;
//#endregion projectTypeId Prop


//#region projectTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'projectTypeName', keyColumn: false})
        projectTypeName : string;
//#endregion projectTypeName Prop

}