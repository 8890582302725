import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractSetting } from '../domain/abstract-setting';
import { Setting, vSetting } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from 'src/app/domain/app-grid';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { SettingAddComponent } from '../add/setting-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("SettingListComponent")
@access({ accessLevel: RolePermissionEnums.AllSettings, action: "get" })

@Component({
    selector: "app-setting-list",
    templateUrl: './setting-list.component.html'
})
export class SettingListComponent extends AbstractSetting implements OnInit, OnDestroy {
    settings: vSetting[];
    subscription: any;
    settingGrid: AppGrid;
    settingAdd: any = SettingAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private formBuilder: RxFormBuilder, private router: Router, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.AllSettings;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("All Settings - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vSetting[]) => {
            this.spin = false;
            this.settings = t;
            this.settingGrid = new AppGrid(t, vSetting, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.settingGrid.gridColumns.forEach(x => {
                if (this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "put" }) && this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "delete" });
                    }
                }
            })
            this.settingGrid.componentId = this.componentName;
            this.settingGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.settingGrid.designClass.headerClass = "table-header-sticky".split(" ");            
            this.settingGrid.design(document.getElementById("settings"));
        })

    }

    onEdit(setting: vSetting) {
        //this.router.navigate(["settings", setting.settingID]);
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["settings", setting.settingID]));
        redirectOnCTRL(url,this.router,event);
    }

    onDelete(setting: vSetting) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {

                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [setting.settingID] }).subscribe(data => {
                    this.spin = false;
                    this.settingGrid.remove(setting.settingID);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/settings']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Setting");
        if (existsAlert) {
            return existsAlert;
        }
    }

    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.settings.filter(x => x.name.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.settingID.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.value.toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.description.toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.settingGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.settingGrid = null;
        }
        setTimeout(() => {
            if (this.settingGrid == null || this.settingGrid == undefined) {
                this.settingGrid = new AppGrid(this.settings, vSetting, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.settingGrid.gridColumns.forEach(x => {
                    if (this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "put" }) && this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.settingGrid.authorize({ accessLevel: RolePermissionEnums.AllSettings, action: "delete" });
                        }
                    }
                })
                this.settingGrid.componentId = this.componentName;
                this.settingGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.settingGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.settingGrid.refresh("settings");
                this.settingGrid.design(document.getElementById("settings"));
            }
            this.settingGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "SettingListComponent";
    }

}
