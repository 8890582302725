<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-content" >
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="WatchInvoiceAddComponent_AddInvoice_Title_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body"  *ngIf="showComponent" [rxLocalisationInit]="componentName">
            <div class="row" [formGroup]="watchInvoiceFormGroup">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="WatchInvoiceAddComponent_Select_Invoice_t"></label>
                        <div class="col-md-8">
                            <app-file [fileViewModel]="fileViewModelCertificate" [tabindex]="'1'"
                                (files)="updateInvoiceFiles($event)">
                            </app-file>
                            <label style="word-break: break-all;" class="mt-3">{{watchInvoiceFormGroup.value.fileName}}</label>
                            <ng-container [rxLocalisationInit]="componentName" *ngIf="watchInvoiceFormGroup.value.fileName != null">
                                <button  
                                    (click)="removeInvoice()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                            </ng-container>
                            <small class="form-text text-danger" *ngIf="showInvoiceError == true">You can't leave
                                this empty<br /></small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="WatchInvoiceAddComponent_Invoice_Number_t"></label>
                        <div class="col-md-8">
                            <input type="text" formControlName="invoiceNumber"
                                rxPlaceholder="WatchInvoiceAddComponent_Invoice_Number_p" class="form-control"
                                tabindex="2" rxFocus>
                        </div>
                    </div>                   
                </div>
            </div>
            <div class="hr-line-dashed border-top w-100" *ngIf="showInvoiceForm2"></div>
            <div class="row" *ngIf="showInvoiceForm2" [formGroup]="watchInvoice2FormGroup" [rxLocalisationInit]="componentName">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="WatchInvoiceAddComponent_Select_Invoice_t"></label>
                        <div class="col-md-8">
                            <app-file [fileViewModel]="fileViewModelCertificate2" [tabindex]="'1'"
                                (files)="updateInvoice2Files($event)">
                            </app-file>
                            <label style="word-break: break-all;" class="mt-3">{{watchInvoice2FormGroup.value.fileName}}</label>
                            <ng-container *ngIf="watchInvoice2FormGroup.value.fileName != null" [rxLocalisationInit]="componentName">
                                <button (click)="removeInvoice2()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                            </ng-container>
                            <small class="form-text text-danger" *ngIf="showInvoiceError2 == true">You can't leave
                                this empty<br /></small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="WatchInvoiceAddComponent_Invoice_Number_t"></label>
                        <div class="col-md-8">
                            <input type="text" formControlName="invoiceNumber"
                                rxPlaceholder="WatchInvoiceAddComponent_Invoice_Number_p" class="form-control"
                                tabindex="2" rxFocus>
                        </div>
                    </div>                 
                </div>
            </div>
            <div class="hr-line-dashed border-top w-100" *ngIf="showInvoiceForm3"></div>
            <div class="row" *ngIf="showInvoiceForm3" [rxLocalisationInit]="componentName" [formGroup]="watchInvoice3FormGroup">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="WatchInvoiceAddComponent_Select_Invoice_t"></label>
                        <div class="col-md-8">
                            <app-file [fileViewModel]="fileViewModelCertificate3" [tabindex]="'1'"
                                (files)="updateInvoice3Files($event)">
                            </app-file>
                            <label style="word-break: break-all;" class="mt-3">{{watchInvoice3FormGroup.value.fileName}}</label>
                            <ng-container *ngIf="watchInvoice3FormGroup.value.fileName != null" [rxLocalisationInit]="componentName">
                                <button (click)="removeInvoice3()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                            </ng-container>
                            <small class="form-text text-danger" *ngIf="showInvoiceError3 == true">You can't leave
                                this empty<br /></small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                            rxText="WatchInvoiceAddComponent_Invoice_Number_t"></label>
                        <div class="col-md-8">
                            <input type="text" formControlName="invoiceNumber"
                                rxPlaceholder="WatchInvoiceAddComponent_Invoice_Number_p" class="form-control"
                                tabindex="2" rxFocus>
                        </div>
                    </div>                 
                </div>
            </div>
            <div class="modal-footer px-0">
                <button (click)="addMoreInvoice()" class="btn btn-primary" [disabled]="showInvoiceForm3" rxText="Btn_Add_More_t" ></button>
                <button (click)="uploadInvoice()" class="btn btn-success" rxText="Btn_Upload_t" ></button>
            </div>
        </div>
    </div>
</div>
