import { prop, email,trim} from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class  CustomerCorporateMappingViewModel{

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "company";
    }
    
    @prop()
    userId: Number;

    @prop()
    @trim()
    name : String; 

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}
