import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { EmailAccount } from '@app/models';
import { AbstractEmailAccount } from '../domain/abstract-email-account';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access } from '@rxweb/angular-router';

import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { EmailAccountEditComponent } from '../edit/email-account-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
@access({ accessLevel: RolePermissionEnums.EmailAccounts, action: "post" })

@multilingual("EmailAccountAddComponent")
@Component({
  selector: "app-email-account-add",
  templateUrl: './email-account-add.component.html'
})
export class EmailAccountAddComponent extends AbstractEmailAccount implements OnInit, OnDestroy {
  emailAccount: EmailAccount;
  subscription: any;
  emailAccountEdit: any = EmailAccountEditComponent;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;

  constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.EmailAccounts;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Email Accounts - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.emailAccount = new EmailAccount();
    this.emailAccount.emailAccountId = 0;
    this.emailAccount.statusId = StatusEnum.Active;
    this.emailAccountFormGroup = this.formBuilder.formGroup(this.emailAccount) as IFormGroup<EmailAccount>;
    //this.emailAccountFormGroup.controls.password.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
    //RxwebValidators.pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&_^]{8,20}$/ }, message: 'Password length should be of 8 to 20 characters and should have atleast one uppercase,one lowercase letter, a number and a special character,without any whitespaces' })
  //]);
  this.emailAccountFormGroup.controls.password.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }),
  RxwebValidators.notEmpty({ message: "You can't leave this field empty" }),
  RxwebValidators.maxLength({ value: 16 }) ]);
    // this.emailAccountFormGroup.controls.port.setValidators(RxwebValidators.numeric({messageKey:'port',message:'Only number reuired'}));
    this.showComponent = true;
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
}

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  addEmailAccount(isRedirect) {
    this.emailAccountFormGroup.submitted = true;
    if (this.emailAccountFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.emailAccountFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert[0].showMessage);
        }
        isRedirect ? this.router.navigate(['/email-account', data]) : this.router.navigate(['/email-account']);
      })
    } else {
      this.breakLoop = false;
      this.validField.forEach(x => {
        if (this.emailAccountFormGroup.controls[x].invalid && !this.breakLoop) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
          invalidControl.focus();
          this.breakLoop = true;
        }
      });
    }
  }
  get componentName(): string {
    return "EmailAccountAddComponent";
  }
}
