import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TicketUpdateDescriptionDetailBase {

//#region ticketUpdateDescriptionDetailId Prop
        @prop()
        ticketUpdateDescriptionDetailId : number;
//#endregion ticketUpdateDescriptionDetailId Prop


//#region ticketId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        ticketId : number;
//#endregion ticketId Prop


//#region updateDescription Prop
        @required()
        @maxLength({value:1000})
        updateDescription : string;
//#endregion updateDescription Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region createdDate Prop
        @required()
        createdDate : Date;
//#endregion createdDate Prop

}