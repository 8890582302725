import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxCategoryBase {

//#region taxCategoryId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'TaxCategoryListComponent_TaxCategoryId_gh', keyColumn: true})
        taxCategoryId : number;
//#endregion taxCategoryId Prop


//#region taxCategoryName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'TaxCategoryListComponent_TaxCategoryName_gh', keyColumn: false})
        taxCategoryName : string;
//#endregion taxCategoryName Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'TaxCategoryListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}