import {vForumSearchTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vForumSearchTypeLookUp extends vForumSearchTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}