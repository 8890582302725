import { TemplateConfig } from '@rxweb/grid';
import { RouterLink } from '@angular/router';
import { route } from '@rxweb/angular-router';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { showPersonaNonGrataToolTip, showSensitiveContactToolTip } from 'src/app/domain/customize-plugin/bind-slider';

export const CORPORATE_LINK_GRID_TEMPLATE: TemplateConfig = {

  div: {
    childrens: [
      {
        a: {
          class: [function (e) {
            if (this.ContactPermissionRequired != null && this.ContactPermissionRequired != undefined) {
              if (this.ContactPermissionRequired) return "text-red font-weight-bold".split(" ")
              else return "text-blue font-weight-bold".split(" ")
            }
            else return "text-blue font-weight-bold".split(" ")
          }],
          // class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onCorporateLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.customerID != null || this.customerID != undefined) {
                return "/suppliers/" + this.customerID + "?activeTab=supplier-edit";
              }
              if(this.SupplierId != null || this.SupplierId != undefined){
                if(this.SupplierId != -1 && this.SupplierId != 0){
                return "/suppliers/" + this.SupplierId + "?activeTab=supplier-edit";
              }
              else{
                return "/internal-directory";
              }
              }
              if(this.supplierId != null || this.supplierId != undefined){
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              if(this.companyId != null || this.companyId != undefined){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.customerID != null || this.customerID != undefined ) {
                return "/suppliers," + this.customerID + "?activeTab=supplier-edit";
              }
              if(this.SupplierId != null || this.SupplierId != undefined){
                if(this.SupplierId != -1 && this.SupplierId != 0){
                return "/suppliers/" + this.SupplierId + "?activeTab=supplier-edit";
              }
              else{
                return "/internal-directory";
              }
              }
              if(this.supplierId != null || this.supplierId != undefined){
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              if(this.companyId != null || this.companyId != undefined){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              }
            },
            onClick: "return false;"
          }
        }
      },
      {
        span:{
          isBind : (x)=>{
            return x.isSupplierUnwelcome ? true : false;
          },
          class: "personaNonGrataToolTip".split(" "),
          style: {"font-size": "0.75rem","margin-left":"0.15rem","cursor":"default"},
          attributes: { 
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
            },
            id: function (e) {
              showPersonaNonGrataToolTip();
              return "personaNonGrataToolTip";
            }
         },
          childrens: [{
            text: {
              text: function (e) {
                return '\u26D4';
              }
            }
          }]
        }
      },
      {
        i: {
          isBind: (x) => {
            return (x.ContactPermissionRequired != null && x.ContactPermissionRequired != undefined && x.ContactPermissionRequired == true) && (x.Company) ? true : false;
          },
          class: "fa fa-exclamation-circle text-red".split(" "),
          style: { "margin-left": "0.15rem" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              if (this.introducesBy != null && this.introducesBy != undefined && this.introducesBy != '') {
                return this.introducesBy;
              }
            },
            id: function (e) {
              if(this.SupplierId != null && this.SupplierId != undefined && this.introducesBy != null && this.introducesBy != undefined){
                bindTooltip(this.SupplierId, this.introducesBy);
                return this.SupplierId;
              }
              if (this.customerID != null || this.customerID != undefined && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.customerID, this.introducesBy);
                return this.customerID;
              }
              if(this.supplierId != null || this.supplierId != undefined && this.introducesBy != null && this.introducesBy != undefined){
                bindTooltip(this.supplierId, this.introducesBy);
                return this.supplierId;
              }
              if(this.companyId != null || this.companyId != undefined && this.introducesBy != null && this.introducesBy != undefined){
                bindTooltip(this.companyId, this.introducesBy);
                return this.companyId;
              }
             
            },
          }
        }
      }
    ]
  },
};


export const CORPORATE_GROUP_LINK_GRID_TEMPLATE: TemplateConfig = {

  div: {
    childrens: [
      {
        a: {
          isBind:(x) => {
            //bind this if employment tables are NOT coming from sp
            return ((x.companyId && x.companyId > 0) || (x.supplierID && x.supplierID > 0) || (x.supplierId && x.supplierId > 0) || (x.corporateBy && x.corporateBy > 0) || (x.corporateId && x.corporateId > 0)) ? true : false;
          },
          class: [function (e) {
            if (this.companyContactPermissionRequired != null && this.companyContactPermissionRequired != undefined) {
              if (this.companyContactPermissionRequired) return "text-red font-weight-bold".split(" ")
              else return "text-blue font-weight-bold".split(" ")
            }
            else return "text-blue font-weight-bold".split(" ")
          }],
          // class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onCorporateGroupLink"
          },
          childrens: [{ 
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              //the sequence is IMPORTANT
              if((this.supplierId != null || this.supplierId != undefined) && (this.ownershipId != null || this.ownershipId != undefined)){
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              else if((this.companyId && this.companyId != 0) && (this.ownershipId != null || this.ownershipId != undefined)){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              } 
              else if(this.corporateBy != null || this.corporateBy != undefined){
                return "/suppliers/" + this.corporateBy + "?activeTab=supplier-edit";
              }
              else if(this.supplierId != null || this.supplierId != undefined){
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              else if(this.companyId && this.companyId != 0){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              }
              else if(this.supplierID && this.supplierID != 0){
                return "/suppliers/" + this.supplierID + "?activeTab=supplier-edit";
              }
              else if(this.corporateId && this.corporateId != 0){
                return "/suppliers/" + this.corporateId + "?activeTab=supplier-edit";
              }
            },
            'ng-reflect-router-link': function (e) {
              //the sequence is IMPORTANT
              if((this.supplierId != null || this.supplierId != undefined) && (this.ownershipId != null || this.ownershipId != undefined)){
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              } 
              else if((this.corporateBy != null || this.corporateBy != undefined)){
                return "/suppliers/" + this.corporateBy + "?activeTab=supplier-edit";
              }
              else if(this.supplierId != null || this.supplierId != undefined){
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              else if(this.companyId && this.companyId != 0){
                return "/suppliers/" + this.companyId + "?activeTab=supplier-edit";
              }
              else if(this.supplierID && this.supplierID != 0){
                return "/suppliers/" + this.supplierID + "?activeTab=supplier-edit";
              }
              else if(this.corporateId && this.corporateId != 0){
                return "/suppliers/" + this.corporateId + "?activeTab=supplier-edit";
              }
            },
               onClick: "return false;"
          }
        }
      },
      {
        span:{
          isBind : (x)=>{
            return x.isSupplierUnwelcome ? true : false;
          },
          class: "personaNonGrataToolTip".split(" "),
          style: {"font-size": "0.75rem","margin-left":"0.15rem","cursor":"default"},
          attributes: { 
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
            },
            id: function (e) {
              showPersonaNonGrataToolTip();
              return "personaNonGrataToolTip";
            }
         },
          childrens: [{
            text: {
              text: function (e) {
                return '\u26D4';
              }
            }
          }]
        }
      },
      {
        i: {
          isBind: (x) => {
            return (x.companyContactPermissionRequired != null && x.companyContactPermissionRequired != undefined && x.companyContactPermissionRequired == true) && (x.company || x.companyName || x.FullName || x.corporateGroups || x.corporate || x.CorporateName) ? true : false;
          },
          class: "fa fa-exclamation-circle text-red".split(" "),
          style: { "margin-left": "0.15rem" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              if (this.introducesBy != null && this.introducesBy != undefined && this.introducesBy != '') {
                return this.introducesBy;
              }
              else if (this.companyIntroducesBy != null && this.companyIntroducesBy != undefined && this.companyIntroducesBy != '') {
                return this.companyIntroducesBy;
              }
            },
            id: function (e) {

              if (this.corporateBy != null && this.corporateBy != undefined && this.corporateBy > 0 && this.introduceBy != null && this.introduceBy != undefined) {
                bindTooltip(this.corporateBy, this.introduceBy);
                return this.corporateBy;
              }
              else if (this.projectRiskMappingId > 0 && this.companyId != null && this.companyId != undefined && this.companyId > 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                bindTooltip(this.projectRiskMappingId.toString()+this.companyId.toString(), this.companyIntroducesBy);
                return this.projectRiskMappingId.toString()+this.companyId.toString();
              }

              else if (this.ticketDetailId > 0 && this.companyId != null && this.companyId != undefined && this.companyId > 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                bindTooltip(this.ticketDetailId.toString()+this.companyId.toString(), this.companyIntroducesBy);
                return this.ticketDetailId.toString()+this.companyId.toString();
              }
              else if (this.companyId != null && this.companyId != undefined && this.companyId > 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                if(this.ownershipId != null && this.ownershipId != undefined && this.ownershipId > 0){
                  bindTooltip(this.companyId+this.ownershipId, this.companyIntroducesBy);
                  return this.companyId+this.ownershipId;
                }
                bindTooltip(this.companyId, this.companyIntroducesBy);
                return this.companyId;
              }
              else if (this.companyId != null && this.companyId != undefined && this.companyId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.companyId, this.introducesBy);
                return this.companyId;
              }
              else if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0 && this.introduceBy != null && this.introduceBy != undefined) {
                bindTooltip(this.supplierId, this.introduceBy);
                return this.supplierId;
              }
              else if (this.supplierID != null && this.supplierID != undefined && this.supplierID > 0 && this.introduceBy != null && this.introduceBy != undefined) {
                bindTooltip(this.supplierID, this.introduceBy);
                return this.supplierID;
              }
              else if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                bindTooltip(this.supplierId, this.companyIntroducesBy);
                return this.supplierId;
              }
              else if (this.riskMenuDetailId > 0 && this.supplierId != null && this.supplierId != undefined && this.supplierId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip( this.supplierId + this.riskMenuDetailId, this.introducesBy);
                return this.supplierId + this.riskMenuDetailId;
              }

              else if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.supplierId, this.introducesBy);
                return this.supplierId;
              }
              else if (this.supplierID != null && this.supplierID != undefined && this.supplierID > 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                bindTooltip(this.supplierID, this.companyIntroducesBy);
                return this.supplierID;
              }
            },
          }
        }
      }
    ],
    attributes:{
      innerHTML:function(x,y){
        //bind this if employment tables are coming from sp
        //the sequence is IMPORTANT
        if(this.hasOwnProperty('corporateBy') && (this.corporateBy == null || this.corporateBy == undefined || this.corporateBy <= 0))
          return this.companyName;
        else if(this.hasOwnProperty('companyId') && (this.companyId == null || this.companyId == undefined || this.companyId <= 0) && !this.hasOwnProperty('corporateBy'))
          return this.company;
        else if(this.hasOwnProperty('supplierId') && (this.supplierId == null || this.supplierId == undefined || this.supplierId <= 0))
          return this.company == undefined ? (this.corporateGroups == undefined ? '' : this.corporateGroups) : this.company;
        else if(this.hasOwnProperty('supplierID') && (this.supplierID == null || this.supplierID == undefined || this.supplierID <= 0))
          return this.corporate;
        else 
          return null;
      }
    }
  },
};
function bindTooltip(id?, introducesBy?) {
  setTimeout(() => {
    showSensitiveContactToolTip(id.toString(), getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + introducesBy);
  }, 100);
}

