import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCreditCardTypeRecordBase {

//#region creditCardTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'creditCardTypeId', keyColumn: true})
        creditCardTypeId : number;
//#endregion creditCardTypeId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region systemKeyword Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'systemKeyword', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop

}