import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vFileExtentionBase {

//#region fileExtentionId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey:'FileExtentionListComponent_Id_gh', keyColumn: true})
        fileExtentionId : number;
//#endregion fileExtentionId Prop


//#region fileExtentionName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'FileExtentionListComponent_Name_gh', keyColumn: false})
        fileExtentionName : string;
//#endregion fileExtentionName Prop

        fileType:number;

//#region fileTypeName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'FileExtentionListComponent_Type_gh', keyColumn: false})
        fileTypeName : string;
//#endregion fileTypeName Prop

}