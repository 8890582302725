import {vHSEUpdateStatusLookupBase,} from '@app/database-models'
//Generated Imports
export class vHSEUpdateStatusLookup extends vHSEUpdateStatusLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}