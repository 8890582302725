import { TemplateConfig } from "@rxweb/grid";

export const POS_PRODUCT_SALESSTATUS_GRID_TEMPLATE: TemplateConfig = {
    div: {
      isBind: (x) => {
        console.log(x)
        if (x.salesStatus == "") {
          return false;
        }
        else {
          return true;
        }
      },
      class: [function (e) {
        if (this.statusId == 5) {
          return "label label-primary text-wrap".split(" ");
        } else {
          return "label text-wrap".split(" ");
        }
      }],
      style: { "text-wrap": "wrap", "line-height": "1.5" },
      childrens: [{
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        }
      }]
    }
  }


  export const POS_PRODUCT_PRODUCTIONSTATUS_GRID_TEMPLATE: TemplateConfig = {
    div: {
      isBind: (x) => {
        console.log(x)
        if (x.productionStatus == "") {
          return false;
        }
        else {
          return true;
        }
      },
      class: [function (e) {
        return "label_status text-center text-wrap".split(" ");
      }],
      style: { "text-wrap": "wrap", "line-height": "1.5" },
      childrens: [{
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        }
      }]
    }
  }

  export const POS_PRODUCT_CONSIGNMENTSTATUS_GRID_TEMPLATE: TemplateConfig = {
    div: {
      isBind: (x) => {
        console.log(x)
        if (x.consignmentStatus == "") {
          return false;
        }
        else {
          return true;
        }
      },
      class: [function (e) {
        return "label_status text-center label-primary text-wrap".split(" ");
      }],
      style: { "text-wrap": "wrap", "line-height": "1.5" },
      childrens: [{
        span: {
          childrens: [{
            text: {
              text: function (e) {
                return this[e.name];
              }
            }
          }]
        }
      }]
    }
  }
