import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventInvitationBase {

//#region eventInvitationID Prop
        @prop()
        eventInvitationID : number;
//#endregion eventInvitationID Prop


//#region eventID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        eventID : number;
//#endregion eventID Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        createdBy : number;
//#endregion createdBy Prop


//#region fileName Prop
        @maxLength({value:255})
        fileName : string;
//#endregion fileName Prop



}