import {CountryBase,StateProvinceBase,TripBase,CurrencyBase,BookingBase,TaxRateBase,} from '@app/database-models'
//Generated Imports
export class Country extends CountryBase 
{

    constructor() {
        super();
        this.published = false;
        this.subjectToVAT=false;
        this.allowsRegistration=false;
        this.allowPOBShipping=false;
        this.allowsBilling=false;
        this.allowsShipping=false;
    }


//#region Generated Reference Properties
//#region stateProvinces Prop
stateProvinces : StateProvinceBase[];
//#endregion stateProvinces Prop
//#region trips Prop
trips : TripBase[];
//#endregion trips Prop
//#region currencies Prop
currencies : CurrencyBase[];
//#endregion currencies Prop
//#region bookings Prop
bookings : BookingBase[];
//#endregion bookings Prop
//#region taxRates Prop
taxRates : TaxRateBase[];
//#endregion taxRates Prop

//#endregion Generated Reference Properties












}