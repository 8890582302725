import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class NewsPictureBase {

//#region newsPictureId Prop
        @prop()
        newsPictureId : number;
//#endregion newsPictureId Prop


//#region newsId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        newsId : number;
//#endregion newsId Prop


//#region pictureId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        pictureId : number;
//#endregion pictureId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region comment Prop
        @maxLength({value:4000})
        comment : string;
//#endregion comment Prop

}