import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractHelpSupport } from '../domain/abstract-help-support';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpSupport } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("HelpSupportEditComponent")
@access({ accessLevel: RolePermissionEnums.HelpSupport, action: "put" })

@Component({
    selector: "app-help-support-edit",
    templateUrl: './help-support-edit.component.html'
})
export class HelpSupportEditComponent extends AbstractHelpSupport implements OnInit, OnDestroy {
    helpSupport: HelpSupport;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    helpSupportDelete: any = dbAccessModule[RolePermissionEnums.HelpSupport]["delete"];;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;

    entityTypeIdForEditorDocuments = EditorDocumentsEnum.HelpSupport;


    _this;

    constructor(private title: Title, private router: Router,private applicationBroadcaster:ApplicationBroadcaster, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, modelView: ModalView, private el: ElementRef, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.HelpSupport;
        this._this = this;
        location.onPopState(() => this.dialog.hide());
    }

    activatedTab(tab) {

        this.currentActiveTab = tab;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Help & Support - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.spin = true;
        this.lookup([{ params: [this.id], propName: "helpSupport" }, { params: [this.id], path: USER_LOOKUPS.parentHelpSupportLookUp, propName: "parentHelpSupportLookUp" }]).subscribe((lookup: any) => {
            this.spin = false;
            if (lookup.helpSupport) {
                this.helpSupportLookups = lookup;
                this.helpSupportFormGroup = this.formBuilder.formGroup(HelpSupport, this.helpSupportLookups.helpSupport[0]) as IFormGroup<HelpSupport>;
                this.showComponent = true;
            }
            else {
                this.router.navigate(['/help-supports']);
            }

        })

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    editHelpSupport(isRedirect) {
        this.helpSupportFormGroup.submitted = true;
        if (this.helpSupportFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.helpSupportFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/help-supports', this.id]) : this.router.navigate(['/help-supports']);
            })
        } else {
            for (const key of Object.keys(this.helpSupportFormGroup.controls)) {
                if (this.helpSupportFormGroup.controls[key].invalid) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                    invalidControl.focus();
                    break;
                }
            }
        }
    }

    deleteHelpSupport() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/help-supports']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Help_Support");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }
    tinyMCE_images_upload_handler = (blobInfo, success, failure) => {
        var fiileName = blobInfo.blob().name;
        if (fiileName != undefined) {
        }
    }

    get componentName(): string {
        return "HelpSupportEditComponent";
    }
}
