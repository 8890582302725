import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductBatchPieceRepairNotesMappingBase {

//#region pieceRepairMappingID Prop
        @prop()
        pieceRepairMappingID : number;
//#endregion pieceRepairMappingID Prop


//#region productVariantBatchPieceID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceID : number;
//#endregion productVariantBatchPieceID Prop


//#region productVariantBatchPieceRepairID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceRepairID : number;
//#endregion productVariantBatchPieceRepairID Prop


//#region noteID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        noteID : number;
//#endregion noteID Prop


//#region documentID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        documentID : number;
//#endregion documentID Prop

}