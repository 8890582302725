import {GemStoneCutTypeGemfieldBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class GemStoneCutTypeGemfield extends GemStoneCutTypeGemfieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop


//#endregion Generated Reference Properties












}