import { prop, required } from "@rxweb/reactive-form-validators";
import { TaskDetailBase } from "../database-models/task-detail-base";

export class TaskCommentsViewModel {

    @prop()
    @required()
    TaskComment: string;
  
    @prop()
    ModifiedBy: number;
  
    @prop()
    CreatedBy: number;
  
    @prop()
    TaskDetailId: number;
}