import { TemplateConfig } from '@rxweb/grid';

export const BIND_CHECKBOX_GRID_TEMPLATE_WITHOUT_FORM_CHECK_CLASS_THIRD: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["checkbox-custom-style"],
                  style:{
                    "margin-top":"-4px",
                    "position":"relative"
                  },
                  
                   event: {
                    input: "onCheckBoxSelectThird"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    },
                    checked: function (e) {
                     // var a = this[e.name];
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }

  export const BIND_CHECKBOX_GRID_isFatality: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["checkbox-custom-style"],
                  style:{
                    "margin-top":"-4px",
                    "position":"relative"
                  },
                  isBind: (x) => { 
                    if(x.total!='Total'){
                      return true;
                    }
                    else{
                      return false;
            
                    }
                  },
                   event: {
                    input: "onCheckBoxSelectThird"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    },
                    checked: function (e) {
                     // var a = this[e.name];
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }

  export const BIND_CHECKBOX_GRID_isArrested: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style"],
                  style: {
                    "margin-top": "-4px",
                    "position": "relative",
                    "margin-left": "auto",
                  },
                  isBind: (x) => { 
                    if(x.total!='Total'){
                      return true;
                    }
                    else{
                      return false;
            
                    }
                  },
                  event: {
                    input: "onCheckBoxSelect"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    },
                    checked: function (e) {
                      // var a = this[e.name];
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }
  export const BIND_CHECKBOX_GRID_isArtisanal: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["checkbox-custom-style"],
                  style: {
                    "margin-top": "-4px",
                    "position": "relative"
                  },
                  isBind: (x) => { 
                    if(x.total!='Total'){
                      return true;
                    }
                    else{
                      return false;
            
                    }
                  },
                  event: {
                    input: "onCheckBoxSelectSecond"
                  },
                  attributes: {
                    type: "checkbox",
                    value: function (e) {
                      
                      return this[e.name];
                    },
                    checked: function (e) {
                      
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }