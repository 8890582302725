<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_Projects_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Projects_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5 rxText="Label_Projects_t"></h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="projectsSearchFormGroup"
      (keyup.enter)="onKeyPress()">
      <div class="d-flex align-items-center mb-4">
        <button routerLink="/projects/add" class="btn btn-success" *rxAuthorize="[projectAdd]" rxText="ProjectListComponent_Add_Button_t">
          </button>
      </div>
      <div class="row">

        <div class="col-lg-6 col-xl-4 pr-xl-5">

          <div class="form-group row" *ngIf="resetNameControl" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_ProjectName_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <input type=" [rxSelectExtended]="_this"text" class="form-control" tabindex="1" formControlName="projectName"
                rxFocus rxPlaceholder="ProjectListComponent_ProjectName_p" /> -->
                <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" [controlTabIndex]="1" formControlName="projectNameId"
                  [valueInQueryParams]="true"
                  [keyValueProps]="['#ProjectName#','ProjectId']" [selectPlaceholder]="'Select Project'" path="api/SearchProject/SearchProjectName"
                  (keyup)="checkProjectNameText($event)" (keyup.enter)="checkProjectNameText($event)" (changed)="onProjectNameClick($event)"
                  [text]="searchProjectName" [minimumCharacterSearchLength]="2" [enableFreeText]="true">
                  </rx-select>
            </div>
          </div>


          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_ProjectStartDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="4" formControlName="projectStartDate"
                placeholder="Select Start Date" pickerClass="form-control"></rx-date>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_ProjectEndDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" [controlTabIndex]="6" formControlName="projectEndDate"
                placeholder="Select End Date" pickerClass="form-control"></rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">Project End Date should be greater than
                Project Start Date<br /></small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_RiskType_t"></label>
            <div class="col-md-8" [rxTagExtended]="_this">
                <rx-tag [(source)]="projectLookup.specificRiskLookUp" #rxTag [controlTabIndex]="9" [isSort]="false"
                  formControlName="specificRiskId" mainClass="form-control" placeholder="Select Risk Type"
                  [keyValueProps]="['specificRiskName','specificRiskId']">
                </rx-tag>

            </div>
          </div>

        </div>

        <div class="col-lg-6 col-xl-4 pr-xl-5">

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_ProjectId_t"></label>
            <div class="col-md-8 ">
              <div class="d-flex">
                <input type="text" class="form-control mr-3" tabindex="2" formControlName="projectId"
                rxPlaceholder="ProjectListComponent_ProjectId_p" (keyup.enter)="goToProject()"/>
              <!-- #295644/29-07-2024/add Search by ID Controller and validation along with Button -->
                <button (click)="goToProject()" class="btn btn-success search-result-btn" [rxLocalisationInit]="componentName"><span rxText="Btn_Go_t"></span></button>
              </div>
              <small class="form-text text-danger" *ngIf="projectIdError==true" [rxLocalisationInit]="componentName"><span rxText="ProjectListComponent_Go_Error_t"></span><br /></small>
              </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_ProjectOwner_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectLookup.usersLookUp" #rxSelect [controlTabIndex]="5" mainClass="form-control"
                formControlName="projectOwner" [selectPlaceholder]="'Select Project Owner'"
                [keyValueProps]="['fullName','userId']"></rx-select>
            </div>
          </div> -->

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_Corporates_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="corporates"
                [valueInQueryParams]="true" [queryParams]="{'lookupType':'corporates'}" [controlTabIndex]="11"
                path="api/SearchEvent/GetLookups" [keyValueProps]="['#name#','id']" [minimumCharacterSearchLength]="2"
                [enableFreeText]="true" (keyup)="checkCorpText($event)" (keyup.enter)="checkCorpText($event)" (changed)="onCorpClick($event)"
                [text]="searchCorporateName" [selectPlaceholder]="'Select Corporate'">
              </rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_Persons_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect autoComplete="true" mainClass="form-control" formControlName="persons"
                [queryParams]="{'lookupType':'persons'}" path="api/SearchEvent/GetLookups" [controlTabIndex]="7"
                [keyValueProps]="['#name#','id']" [minimumCharacterSearchLength]="2" [valueInQueryParams]="true"
                [enableFreeText]="true" (keyup)="checkNameText($event)" [selectPlaceholder]="'Select Person'">
              </rx-select>
            </div>
          </div>

          <div class="form-group row"> 
          <label class="col-md-4 col-form-label" rxText="ProjectListComponent_Country_t"></label>
          <div class="col-md-8" [rxSelectExtended]="_this">
            <rx-select [(source)]="projectLookup.countryLookUp" #rxSelect mainClass="form-control"
              [controlTabIndex]="10" formControlName="countryId"
              [keyValueProps]="['countryName','countryId']" [selectPlaceholder]="'Select Country'">
            </rx-select>
          </div>
        </div>

        </div>

        <div class="col-lg-6 col-xl-4 ">

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_ProjectOwner_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectLookup.usersLookUp" #rxSelect [controlTabIndex]="5" mainClass="form-control"
                formControlName="projectOwner" [selectPlaceholder]="'Select Project Owner'"
                [keyValueProps]="['fullName','userId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_gemCompPO_t"></label>

            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectLookup.genfieldsGroupEntity" #rxSelect mainClass="form-control" [controlTabIndex]="3"
                formControlName="gemfieldsGroupCompanyProjectOwner" [selectPlaceholder]="'Select Gemfields Group Entity'"
                [keyValueProps]="['company','supplierId']"></rx-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_Department_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectLookup.departmentLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="8"
                formControlName="department" [selectPlaceholder]="'Select Department'"
                [keyValueProps]="['departmentName','departmentId']"></rx-select>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="ProjectListComponent_Corporates_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="corporates"
                [valueInQueryParams]="true" [queryParams]="{'lookupType':'corporates'}" [controlTabIndex]="11"
                path="api/SearchEvent/GetLookups" [keyValueProps]="['#name#','id']" [minimumCharacterSearchLength]="2"
                [enableFreeText]="true" (keyup)="checkCorpText($event)" (keyup.enter)="checkCorpText($event)" (changed)="onCorpClick($event)"
                [text]="searchCorporateName" [selectPlaceholder]="'Select Corporate'">
              </rx-select>
            </div>
          </div> -->

        </div>
      </div>

      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100 mt-1"></div>
        <div class="col text-right">
          <button (click)="onKeyPress()" tabindex="12" class="btn btn-success mr-1 search-result-btn" rxText="Btn_Search_t"></button>
          <button (click)="resetSearch()" tabindex="13" class="btn btn-primary" rxText="Btn_Reset_t"></button>
        </div>
      </div>

    </div>
  </div>
  <div class="search-result-container">
    <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container" *ngIf="showGrid" [rxLocalisationInit]="componentName">
      <div class="ibox-title bg-white d-flex flex-wrap pr-5">
        <h5 rxText="Label_Search_Result_t"></h5>
        <!-- <div data-toggle="buttons" class="btn-group btn-group-gray btn-group-toggle ml-auto">
          <label class="btn btn-sm btn-white active" [class.active]="projectType == null" (click)="filterProjects(null)"
            rxText="ProjectListComponent_All_t">
            <input type="radio" id="option1" name="options"></label>
          <label class="btn btn-sm btn-white" [class.active]="projectType == 175" (click)="filterProjects(175)"
            rxText="ProjectListComponent_Faberge_t">
            <input type="radio" id="option2" name="options"></label>
          <label class="btn btn-sm btn-white" [class.active]="projectType == 176" (click)="filterProjects(176)"
            rxText="ProjectListComponent_Gemfields_t">
            <input type="radio" id="option2" name="options"></label>
        </div> -->
        <div class="ibox-tools" *ngIf="!isNoRecordFound">
          <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
            <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
            (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
            [gridColumnConfig]="projectGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID">
          </app-system-table-setting>
        </div>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="tab-content">
          <div role="tabpanel" id="tab-event-all" class="tab-pane" [class.active]="selectedTab == 'All-Projects'">
            <div class="table-responsive" *ngIf="isShowGrid">
              <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                <rx-grid [design]="projectGrid"></rx-grid>
              </div>
            </div>
            <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
              <h4>No Record Found</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
