import {vWarehousLookUpBase,} from '@app/database-models'
//Generated Imports
export class vWarehousLookUp extends vWarehousLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}