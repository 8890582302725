import {BodilyLocationBase,} from '@app/database-models'
//Generated Imports
export class BodilyLocation extends BodilyLocationBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}