import {ProductSpecificationAttributeBase,ProductDesignBase,SpecificationAttributeBase,} from '@app/database-models'
//Generated Imports
export class ProductSpecificationAttribute extends ProductSpecificationAttributeBase 
{




//#region Generated Reference Properties
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop
//#region specificationAttribute Prop
specificationAttribute : SpecificationAttributeBase;
//#endregion specificationAttribute Prop

//#endregion Generated Reference Properties











}