import { gridColumn } from "@rxweb/grid"
// @actionColumn({
//     allowSorting: false,
//     style: { width: "2%" },
//     configuredTemplate: { templateName: 'action' }, columnIndex: 10, headerTitle: "Action"
// })
export class MoveNoteDocumentPriceListSearchViewModel {


  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh' , keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean
  
  //#region tripId Prop
  @gridColumn({ visible: false, columnIndex: 1, allowSorting: true, headerKey: 'FabergePriceListComponent_TripId_gh', keyColumn: true })
  tripId: number;
  //#endregion tripId Prop

  //#region tripName Prop
  @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PriceListTitle_gh', keyColumn: false })
  tripName: string;
  //#endregion tripName Prop

  //#region createdBy Prop
  @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PriceListCreatedBy_gh', keyColumn: false })
  createdBy: string;
  //#endregion createdBy Prop

  //#region totalInsuranceValue Prop
  @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, parameter: false, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PriceListInsuranceVal_gh', keyColumn: false})
  totalInsuranceValue: string;
  //#endregion totalInsuranceValue Prop

  //#region totalCount 
  @gridColumn({ parameter: true, name: "totalCount", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerKey: 'Total Count', keyColumn: false })
  totalCount: number;
  //#endregion totalCount

}