import { gridColumn, actionColumn } from '@rxweb/grid';
import { FabergePriceListPiecesViewModelBase } from '../models/database-models/faberge-price-list-pieces-view-model-base';
import { FileDetailViewModel } from './file-detail-view-model';

export class MoveNoteDocumentPieceSearchViewModel {

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.designName;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PieceDesign_gh', keyColumn: false
  })
  designName: string;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.productName;
          }
        },
      }
    },
    parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PieceProdTitle_gh', keyColumn: false
  })
  productName: string;

  @gridColumn({ parameter: false, style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PiecebatchId_gh', keyColumn: false})
  productVariantBatchId: number;

  @gridColumn({
    template: {
      div: {
        attributes: {
          innerHTML: function (x, y) {
            return this.pieceSerialNumber;
          }
        },
      }
    },
    parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PieceNo_gh', keyColumn: false
  })
  pieceSerialNumber: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PieceSupplier_gh', keyColumn: false })
  supplier: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 15, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PieceLocation_gh', keyColumn: false })
  location: string;

  @gridColumn({ parameter: false, style: { width: "7%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 34, allowSorting: true, headerKey: 'MoveNoteDocumentComp_PieceSalesCh_gh', keyColumn: false })
  salesChannel: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  serialNo: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  shortSerialNo: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  size: string;

  @gridColumn({visible: false, columnIndex: 5, keyColumn: false })
  sizes: string;

  hasCoreProduct: boolean;
  hasSuperSeller: boolean;
  totalCount: number;
  productVariantBatchPieceId: number;
  productVariantId: number;
  productId: number;
  design: string;
  product: string;
  description: string;
  adminComment: string;
  usdRate: number;
  gbpRate: number;
  eurRate: number;
  hkdRate: number;
  chfRate: number;
  pictureID: number;
  stockcardPictureID: number;
  exportRetailPrice: number;
  supplierDeliveryDate: Date;
  orderDetails: string;
  totalInsuranceValue: any;
  totalRRPValue: any;
  //@gridColumn({parameter:true,style: { width: "6%", "text-align": "initial"}, class: ["text-left"],  visible: false, columnIndex: 8, allowSorting: true, headerTitle: 'bookingData', keyColumn: false })
  bookingData: string;
  isRRP: boolean;
  //#region file Prop
  file: FileDetailViewModel;
  //#endregion file Prop
  imageUrlOrg: string; 
}
