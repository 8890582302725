<div class="row border-bottom" *rxMultilingual="">
  <nav class="navbar navbar-static-top bg-white" [rxLocalisationInit]="componentName" role="navigation" style="margin-bottom: 0">
    <div class="navbar-header" *rxAuthorize="[productPieceList]">
      <form role="search" class="form-inline d-inline-flex header-search-bar form-topbar-pos ml-0" #f="ngForm"
        (ngSubmit)="redirectPieces(f)" [rxLocalisationInit]="componentName" novalidate>

        <div class="form-group ml-sm-3 mr-sm-2 input-group-sm">
          <label class="sr-only">Search</label>
          <input type="text" class="form-control" ngModel #first="ngModel" rxPlaceholder="TopBarComponent_Search_Something_p"
            autocomplete="off" name="searchProduct" id="top-search" [title]="titleSearch">
        </div>
        <div>
          <button type="submit" class="btn btn-success btn-sm" rxText="TopBarComponent_Product_Fast_Find_t"></button>
        </div>
      </form>
    </div>
    <ul class="nav navbar-top-links navbar-right topbar-right-pos ml-auto pr-3">
      <li class="dropdown">
        <a class="dropdown-toggle count-info" data-toggle="dropdown" (click)="displayCount()">
          <i class="fa fa-user" style="font-size: 22px;"></i>
        </a>
        <div class="dropdown-menu dropdown-alerts topbar-dropdown-menu">
          <div class="row margin-custom">
            
            <div class="col-md-10 border-bottom p-0">
              <div class="font-weight-bold font-md">
                <a [routerLink]="['/users',loggedInUserId]" (click)="changeActiveMenu()" class="dropdown-item" [queryParams]="{activeTab: 'user-edit'}">
                  <i class="fa fa-user mr-2"></i>{{loginUserName}}
                </a>
              </div>
            </div>
            
            <div class="col-md-2 border-bottom p-0">
              <a href="javascript:void(0);" (click)="logout()" class="dropdown-item" [title]="titleLogOut"><i
                class="fa fa-sign-out fa-lg mr-2"></i><span></span></a>
            </div>

            <div class="col-md-6 p-0 ">
              <a [rxLocalisationInit]="componentName" [routerLink]="['/team-member-report']" [queryParams]="{ userId: loggedInUserId }" class="dropdown-item">
                <i class="fa fa-id-card mr-2"></i><span rxText="TopBarComponent_TeamMemberReport_t"></span>
              </a>
            </div>
            <div class="col-md-6 p-0 " >
              <a [rxLocalisationInit]="componentName" [routerLink]="['/users',loggedInUserId]" [queryParams]="{activeTab: 'tasks',myTasks : true}" class="dropdown-item">
                <i class="fa fa-tasks mr-2"></i><span rxText="TopBarComponent_Task_t"></span>
                <sup  *ngIf="totalTasks > 0" style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top:3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                  <span class="badge badge-pill badge-secondary">{{this.totalTasks}}</span>
              </sup>
              </a>
            </div>
            <div class="col-md-6 p-0" *rxAuthorize="[eventList]">
              <a [rxLocalisationInit]="componentName" [routerLink]="['/users',loggedInUserId]" [queryParams]="{activeTab: 'events',myEvents : true}" class="dropdown-item">
              <i class="fr-event mr-2"></i><span rxText="TopBarComponent_Events_t"></span>
              <!-- <sup *ngIf="documentNotSeenCount > 0"  style="color:rgb(255, 132, 132);top : -2px;font-size: 8px;animation-duration: 1s;animation-name: notification;"> -->
              <sup  *ngIf="totalEvents > 0" style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top:3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                <span class="badge badge-pill badge-secondary">{{this.totalEvents}}</span>
            </sup>
                 </a>
            </div>
            <div class="col-md-6 p-0 " *rxAuthorize="[projectList]">
              <a [rxLocalisationInit]="componentName" [routerLink]="['/users',loggedInUserId]" [queryParams]="{activeTab: 'project',myProjects : true}" class="dropdown-item">
                <i class="fa fr-project mr-2"></i><span rxText="TopBarComponent_Projects_t"></span>
                <sup  *ngIf="totalProjects > 0" style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top: 3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                  <span class="badge badge-pill badge-secondary">{{this.totalProjects}}</span>
              </sup>
              </a>
            </div>
            <div class="col-md-6 p-0 " *rxAuthorize="[riskList]">
              <a [rxLocalisationInit]="componentName" [routerLink]="['/gemfield-risk-menu']" [queryParams]="{ myrisks : true}" class="dropdown-item">
              <i class="fr-form mr-2"></i><span rxText="TopBarComponent_Risks_t"></span>
              <sup  *ngIf="totalMyRisk > 0" style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top: 3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                <span class="badge badge-pill badge-secondary">{{this.totalMyRisk}}</span>
            </sup>
            </a>
            </div>
            <div class="col-md-6 p-0 " *rxAuthorize="[corporateList]">
               <a [rxLocalisationInit]="componentName" [routerLink]="['/suppliers']" [queryParams]="{ userId: loggedInUserId }" class="dropdown-item">
              <i class="fa fa-th-large mr-2"></i><span rxText="TopBarComponent_Corporates_t"></span>
              <sup  *ngIf="totalSupplier > 0" style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top: 3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                <span class="badge badge-pill badge-secondary">{{this.totalSupplier}}</span>
            </sup>
            </a>
            </div>
            <div class="col-md-6 p-0 " *rxAuthorize="[peopleList]">
              <a [rxLocalisationInit]="componentName" [routerLink]="['/users']" [queryParams]="{ userId: loggedInUserId }" class="dropdown-item">
              <i class="fa fa-users mr-2"></i><span rxText="TopBarComponent_People_t"></span>
              <sup *ngIf="totalPeople > 0"  style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top: 3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                <span class="badge badge-pill badge-secondary">{{this.totalPeople}}</span>
            </sup>
            </a>
            </div>
            <div class="col-md-6 p-0 " *ngIf="adminRole">
              <a [rxLocalisationInit]="componentName" [routerLink]="['/users',loggedInUserId]" [queryParams]="{activeTab: 'my-direct-report'}" class="dropdown-item">
                <i class="fa fa-users mr-2"></i><span rxText="TopBarComponent_MyTeam_t"></span>
                <sup  *ngIf="totalMyTeam > 0" style="color:rgb(255, 132, 132);top : 0px;padding-left: 4px;padding-top: 3px;font-size: 8px;animation-duration: 1s;animation-name: notification;">
                  <span class="badge badge-pill badge-secondary">{{this.totalMyTeam}}</span>
              </sup>
              </a>
            </div>
            <div class="col-md-6 p-0 " *ngIf="!isSSOEnabled">
              <a href="javascript:void(0);" (click)="showChangePasswordModal();" class="dropdown-item"><i
                class="fa fa-key mr-2"></i><span rxText="TopBarComponent_Change_Password_t"></span></a>
            </div>
          </div>
        </div>
    </ul>
  </nav>

</div>
