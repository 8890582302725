import { vBookingTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBookingTypeLookUp extends vBookingTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}