import { gridColumn, actionColumn } from '@rxweb/grid';

@actionColumn({
  visible: false,
  name: "action",
  allowSorting: false,
  style: { width: "2%" },
  configuredTemplate: { templateName: 'logsAction' }, columnIndex: 10, headerTitle: "Action"
})

export class ExceptionLogsViewModel {


  //#region ApplicationExceptionLogId 
  @gridColumn({ name: "exceptionLogId", configuredTemplate: { templateName: 'bindCheckBox' }, visible: true, columnIndex: 0, allowSorting: false, headerTitle: "Action", keyColumn: true, style: { width: "6%", "text-align": "initial" } })
  ExceptionLogId: number;
  //#endregion ApplicationExceptionLogId 

   //#region ApplicationExceptionLogId 
   @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ExceptionLogListComponent_ExceptionIdNo_gh', keyColumn: false,  style: { width: "20%", "text-align": "initial" }, class: ["text-left"] })
   ExceptionLogIdNumber: number;
   //#endregion ApplicationExceptionLogId 

  //#region RowNumber 
  @gridColumn({ visible: false, columnIndex: 2, allowSorting: false, headerKey: 'RowNumber', keyColumn: false })
  rowNumber: number;
  //#endregion RowNumber 

  //#region LogType 
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ExceptionLogListComponent_ExceptionType_gh', keyColumn: false })
  ExceptionType: string;
  //#endregion LogType 

  //#region Email 
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'ExceptionLogListComponent_Customer_gh', keyColumn: false })
  Email: string;
  //#endregion Email 

  //#region createdDate 
  @gridColumn({ style: { width: "20%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ExceptionLogListComponent_CreatedOn_gh', keyColumn: false })
  CreatedDate: string;
  //#endregion createdDate 

  //#region Message 
  @gridColumn({ style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ExceptionLogListComponent_Message_gh', keyColumn: false })
  Message: string;
  //#endregion Message 

  @gridColumn({ style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ExceptionLogListComponent_ExceptionSource_gh', keyColumn: false })
  ExceptionSource: string;

  // @gridColumn({ style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: false, headerKey: 'message', keyColumn: false })
  // StackTrace: string;

  @gridColumn({ style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: false, headerKey: 'ExceptionLogListComponent_InnerExceptionMessage_gh', keyColumn: false })
  InnerExceptionMessage: string;

  // @gridColumn({ style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: false, headerKey: 'message', keyColumn: false })
  // InnerExceptionStackTrace: string;

  @gridColumn({ style: { width: "40%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 6, allowSorting: false, headerKey: 'ExceptionLogListComponent_RequestBody_gh', keyColumn: false })
  RequestBody: string;

  //#region totalCount 
  @gridColumn({ name: "totalCount", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 7, allowSorting: false, headerTitle: 'Total Count', keyColumn: false })
  totalCount: number;
  //#endregion totalCount

  @gridColumn({
    visible: false,
    name: "editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 8, headerTitle: "Edit"
  })
  editAction: any;

  @gridColumn({
    visible: false,
    name: "deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 9, headerTitle: "Delete"
  })
  deleteAction: any;

}