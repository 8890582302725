import {HelpSupportBase,HelpSupportUserRoleBase,} from '@app/database-models'
//Generated Imports
import { HelpSupportUserRole } from './help-support-user-role'
import { prop, propArray } from '@rxweb/reactive-form-validators'
import { PowerBIReportBase } from '../database-models/power-bi-report-base';
import { PowerBIReportUserRole } from './power-bi-report-user-role';
export class PowerBIReport extends PowerBIReportBase 
{

    constructor() {
        super();
        this.published = false;
    }

    @propArray(PowerBIReportUserRole)
    powerBIReportUserRoles: PowerBIReportUserRole[]


    @prop({defaultValue:false})
    isRequestFromInfoTab:boolean;
}