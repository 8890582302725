<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/roles']" rxText="Label_User_Roles_t"></a>
        </li>
        <li class="breadcrumb-item active">Add New</li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="RoleAddComponent_Title"></label>
        </h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="roleFormGroup"
         [rxSpinner]="spin">
        <div class="row add-countries-form">
          <div class="col-lg-6 col-xl-4 pr-xl-4" (keyup.enter)="addRole(false)">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="RoleAddEditComponent_RoleName_t"></label>
              <div class="col-md-8">
                <input tabindex="1" type="text" formControlName="roleName" class="form-control " 
                  rxPlaceholder="RoleAddEditComponent_RoleName_p" rxFocus />
                <small class="form-text text-danger"
                  [class.d-block]="roleFormGroup.controls.roleName.errors">{{roleFormGroup.controls.roleName.errorMessage}}<br /></small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="RoleAddEditComponent_RoleType_t"></label>

              <div class="col-md-8" [rxSelectExtended]="_this">
                <!-- <select class="form-control" formControlName="roleType">
                  <option [selected]="true" value=0>Select</option>
                  <option *ngFor="let item of roleLookups.customerRoleTypeLookUps" [value]="item.customerRoleId">
                    {{item.customerRoleName}}</option>
                </select> -->
                <rx-select [controlTabIndex]="2" [(source)]="roleLookups.customerRoleTypeLookUps" #rxSelect mainClass="form-control"
                  formControlName="roleType" [selectPlaceholder]="'Select Role Type'"
                  [keyValueProps]="['customerRoleName','customerRoleId']">
                </rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="roleFormGroup.controls.roleType.errors">{{roleFormGroup.controls.roleType.errorMessage}}<br /></small>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="RoleAddEditComponent_RoleDomain_t"></label>
              <div class="col-md-8" [rxSelectExtended]="_this">
                <!-- <select class="form-control" formControlName="roleDomainId">
                  <option [selected]="true" value=0>Select</option>
                  <option *ngFor="let item of roleLookups.customerRoleDomainLookUps"
                    [value]="item.customerRoleDomainId">{{item.customerRoleDomainName}}</option>
                </select> -->
                <rx-select [controlTabIndex]="3" [(source)]="roleLookups.customerRoleDomainLookUps" #rxSelect mainClass="form-control"
                  formControlName="roleDomainId" [selectPlaceholder]="'Select Role Domain'"
                   [keyValueProps]="['customerRoleDomainName','customerRoleDomainId']">
                </rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="roleFormGroup.controls.roleDomainId.errors">{{roleFormGroup.controls.roleDomainId.errorMessage}}<br /></small>
              </div>
            </div>
          </div>
        </div>

        <div class="row add-countries-form">
          <div class="col-lg-6 col-xl-4 pr-xl-5">

            <div class="form-group row check-group">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                rxText="RoleAddEditComponent_Active_t"></label>
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label class="mb-0">
                    <!-- <input type="checkbox" formControlName="subjectToVAT"><i></i> -->
                    <app-checkbox [tabIndex]="'4'" [checkFormControl]="roleFormGroup.controls.active"></app-checkbox>
                  </label>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="addRole(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="addRole(true)" class="btn btn-primary mr-1" *rxAuthorize="[roleEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
