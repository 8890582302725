import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class RelationshipBase {

//#region relationShipId Prop
        @prop()
        relationShipId : number;
//#endregion relationShipId Prop


//#region relationShipName Prop
        @required()
        @maxLength({value:150})
        relationShipName : string;
//#endregion relationShipName Prop



}