import {vTaxProviderRecordBase,} from '@app/database-models'
//Generated Imports
export class vTaxProviderRecord extends vTaxProviderRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}