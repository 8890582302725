<!-- <div class="wrapper wrapper-content"  [rxSpinner]="spin"> -->

<div class="ibox mb-0 d-flex flex-column" [rxLocalisationInit]="componentName" [rxSpinner]="spin" *rxMultilingual="">
  <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
      <button class="btn btn-success btn-sm mb-3" (click)="addProject()" rxText="Btn_Add_New_t" ></button>
      <label class="ml-auto mr-3 mb-3">

      <ng-container [rxLocalisationInit]="componentName" *ngIf="!isNoRecordFound">
        <button type="button" class="btn btn-danger mt-3 pull-right" (click)="saveContinue(false)"
          [disabled]="selectedProjects.length==0" rxText="Btn_Remove_t" ></button>
      </ng-container>
        <!-- <button type="button" class="btn btn-success mr-3 mt-3 pull-right" (click)="saveContinue(true)"
          [disabled]="selectedProjects.length==0">Save</button> -->

      </label>
      <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4"
      placeholder="" [title]="titleSearch">
      <div class="ibox-tools mb-3 pb-1 ml-4" style="position: relative;" *ngIf="!isNoRecordFound">
        <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
          <i class="fa fa-cog"></i>
      </a>
      <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn"
      (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
      [gridColumnConfig]="projectEventMappingGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
      [moduleID]="moduleID" [selectedTab]="selectedTab">
      </app-system-table-setting>
      </div>

    </div>
    <div data-toggle="buttons" class="btn-group btn-group-gray btn-group-toggle ml-auto mb-1"  *ngIf="isLongTextExists">
      <ng-container [rxLocalisationInit]="componentName" *ngIf="!isShowAll">
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Show_All_t" ></button>
      </ng-container>
      <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowAll">
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Hide_All_t" ></button>
      </ng-container>
    </div>
    <div class="table-responsive" *ngIf="isShowGrid">
      <!-- <div id="project-event-mapping-list" class="dataTables_wrapper pb-0 dt-bootstrap4">


            </div> -->

      <div class="dataTables_wrapper pb-0 dt-bootstrap4" (keyup.enter)="deleteProjects(false)">
        <rx-grid [design]="projectEventMappingGrid"></rx-grid>
      </div>
    </div>

    <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
      <h4>No Record Found</h4>
    </div>

  </div>
</div>

<!-- </div> -->
