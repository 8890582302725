import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vSettingBase {

    //#region settingID Prop
    @gridColumn({ style: { width: "5%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'SettingListComponent_SettingId_gh', keyColumn: true })
    settingID: number;
    //#endregion settingID Prop


    //#region name Prop
    @gridColumn({
        isAscending: true,
        style: { width: "15%", "text-align": "initial" },
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                    "display": "-webkit-box",
                    "-webkit-line-clamp": "3",
                    "-webkit-box-orient": "vertical",
                    "overflow": "hidden",
                    "text-overflow": "ellipsis"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.name;
                    }
                },
            }
        },
        class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'SettingListComponent_Name_gh', keyColumn: false
    })
    name: string;
    //#endregion name Prop


    //#region value Prop
    @gridColumn({
        style: { width: "15%", "text-align": "initial" },
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                    "display": "-webkit-box",
                    "-webkit-line-clamp": "3",
                    "-webkit-box-orient": "vertical",
                    "overflow": "hidden",
                    "text-overflow": "ellipsis"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.value;
                    }
                },
            }
        },
        class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'SettingListComponent_Value_gh', keyColumn: false
    })
    value: string;
    //#endregion value Prop


    //#region description Prop
    @gridColumn({
        style: { width: "15%", "text-align": "initial" },
        template: {
            div: {
                style: {
                    "word-break": "break-word;",
                    "display": "-webkit-box",
                    "-webkit-line-clamp": "3",
                    "-webkit-box-orient": "vertical",
                    "overflow": "hidden",
                    "text-overflow": "ellipsis"
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.description;
                    }
                },
            }
        },
        class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'SettingListComponent_Description_gh', keyColumn: false
    })
    description: string;
    //#endregion description Prop


    //#region statusId Prop
    @gridColumn({ visible: false, columnIndex: 4, allowSorting: true, headerKey: 'statusId', keyColumn: false })
    statusId: any;
    //#endregion statusId Prop

}