import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { AbstractEventSendEInvitation } from '../domain/abstract-event-send-einvitation';
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Event, EventCustomerMapping } from '@app/models';
import { InviteesEmailLookup } from 'src/app/view-model/invitees-email-lookup';
import { EventSendEInvitation } from 'src/app/view-model/event-send-einvitation-model';
import { TINY_MCE_KEY } from 'src/app/domain/system-constant';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { EditorDocumentsEnum } from "src/app/enums/editorDocuments.enum";
import { DatePipe } from "@angular/common";

@multilingual("EventSendEInvitationEditComponent")
@Component({
    selector: "app-event-send-einvitation-edit",
    templateUrl: './event-send-einvitation-edit.component.html'
})
export class EventSendEInvitationEditComponent extends AbstractEventSendEInvitation implements OnInit, OnDestroy {
    eventInvitation: Event;
    subscription: any;
    id: number;
    emailLookup: InviteesEmailLookup[];
    invitedUserIds: string[];
    apiKey: any;
    toError: boolean = false;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    showHideButton: boolean = true;
    showInvitees: boolean = true;
    entityTypeIdForEditorDocuments: number = EditorDocumentsEnum.EventSendEInvitation;

    _this;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router,public datepipe: DatePipe) {
        super();
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this._this = this;
    }

    ngOnInit(): void {
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.spin = true;
        this.apiKey = TINY_MCE_KEY;

        this.lookup([{ params: [this.id], propName: "eventInvitation" }, { path: 'api/SearchEvent/InviteesEmail', propName: 'emailLookup', params: [this.id] }]).subscribe((t: any) => {

            this.eventInvitation = t.eventInvitation;
            this.emailLookup = t.emailLookup;
            this.eventInvitation.eventSendEInvitation = new Array<EventSendEInvitation>();

            this.get({path:'api/userlookups/EInvitationLookup',params:[this.eventInvitation.eventType == null || this.eventInvitation.eventType == 0 ? 0 : this.eventInvitation.eventType ]}).subscribe((data : string)=>{

                var arrDescription = this.eventInvitation.eventDescription.split("\n");
                var descriptionData = "";
                var eventDate = '';
                arrDescription.forEach(element => {
                    descriptionData+=`<p style="margin : 4px !important">`+element+`</p>`;
                });

                let date = new Date(this.eventInvitation.eventDate);
                let endDate = new Date(this.eventInvitation.eventEndDate);
                eventDate = this.eventInvitation.eventEndDate == null ? this.datepipe.transform(date, 'dd MMM yyyy') : 
                this.datepipe.transform(date, 'dd MMM yyyy') + ' - ' +  this.datepipe.transform(endDate, 'dd MMM yyyy');
                data = data.replace(/%Event_Description%/gi,descriptionData);
                data = data.replace(/%Event_Name%/gi, this.eventInvitation.eventName);

                data = data.replace(/%Event_Date%/gi, eventDate);
                this.eventInvitation.eInvitationDescription = data;

                this.eventEInvitationFormGroup = this.formBuilder.formGroup(Event, this.eventInvitation) as IFormGroup<Event>;

                this.spin = false;
                this.showComponent = true;
            });


        })
    }

    tinyMCE_images_upload_handler = (blobInfo, success, failure) => {
        var fiileName = blobInfo.blob().name;
        if (fiileName != undefined) {
        }
    }
    EditInvitation(isRedirect: boolean) {
        this.eventEInvitationFormGroup.submitted = true;
        if (!this.eventEInvitationFormGroup.value.invitedUserIds) {
            this.toError = true;
        }

        else {
            
            this.invitedUserIds = this.eventEInvitationFormGroup.value.invitedUserIds.split(",");
            this.invitedUserIds.forEach(x => {

                let selecteduserid = parseInt(x);
                let eInvitation = new EventSendEInvitation();
                eInvitation.eventId = this.id;
                eInvitation.userType = this.emailLookup.find(x =>x.userId == selecteduserid).userType;
                eInvitation.userId = selecteduserid;
                eInvitation.body = this.eventEInvitationFormGroup.value.eInvitationDescription;
                eInvitation.eventName = this.eventEInvitationFormGroup.value.eventName;
                this.eventEInvitationFormGroup.value.eventSendEInvitation.push(eInvitation);
            })
            this.spin = true;
            // this.eventEInvitationFormGroup.;
            let eventCustomerMapping = new EventCustomerMapping();
            eventCustomerMapping.customerID = 2;
            eventCustomerMapping.addedBy = 2;
            eventCustomerMapping.eventCustomerID = 1;
            eventCustomerMapping.eventID = this.id;
            eventCustomerMapping.sendEInvitation = true;
            this.eventEInvitationFormGroup.patchValue({
                eventCustomerMapping: eventCustomerMapping
            });
            this.put({ body: this.eventEInvitationFormGroup.value, params: [this.id] }).subscribe(data => {

                //this.toastr.success("Data Updated Successfully");
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                if (existsAlert) {
                    this.toastr.success(existsAlert[0].showMessage);
                }
                this.eventEInvitationFormGroup.patchValue({
                    invitedUserIds: '',
                    eInvitationDescription: ''
                });
                this.eventEInvitationFormGroup.reset(this.eventEInvitationFormGroup.value);

                isRedirect ?
                    //this.router.navigate(["events", this.id], { replaceUrl: true,queryParams: { activeTab: 'e-invite', tabChange: true } })
                    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    //     this.router.navigate(["events", this.id], { queryParams: { activeTab: 'e-invite', tabChange: true } });

                    // })
                    window.location.reload()
                    : this.router.navigate(["events", this.id], { queryParams: { activeTab: 'invitees', tabChange: true } });

            })
        }
    }

    selectAllInvitees(){
        let invitedUserIds = this.emailLookup.map(x=>x.userId)
        if(invitedUserIds.length>0){
            this.showInvitees = false;
            this.showHideButton = false;
            this.eventEInvitationFormGroup.controls.invitedUserIds.setValue(invitedUserIds.join(','))
            setTimeout(() => {
                this.showInvitees = true;
            }, 100);
        }
    }

    removeAllInvitees(){
        this.showInvitees = false;
        this.showHideButton = true;
        this.eventEInvitationFormGroup.controls.invitedUserIds.setValue('')
        setTimeout(() => {
            this.showInvitees = true;
        }, 100);
    }

    checkInvitees(){
        let invitedUserIds = this.eventEInvitationFormGroup.value.invitedUserIds;
        if(invitedUserIds!=null && invitedUserIds!= undefined){
            this.showHideButton = (this.emailLookup.length == this.eventEInvitationFormGroup.value.invitedUserIds.split(',').length)?false:true;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "EventSendEInvitationEditComponent";
    }

}
