import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class KnownToBase {

//#region knownToId Prop
        @prop()
        knownToId : number;
//#endregion knownToId Prop


//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop


//#region userRelationId Prop
        @prop()
        userRelationId : number;
//#endregion userRelationId Prop


//#region relationShipId Prop
      //  @range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        relationShipId : number;
//#endregion relationShipId Prop

//#region statusId Prop
        @prop()
        @required()
        statusId : number;
//#endregion statusId Prop





}