import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCustomerRelationshipLookUpBase {

//#region customerRelationShipId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'customerRelationShipId', keyColumn: true})
        customerRelationShipId : number;
//#endregion customerRelationShipId Prop


//#region customerRelationShipStatusName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'customerRelationShipStatusName', keyColumn: false})
        customerRelationShipStatusName : string;
//#endregion customerRelationShipStatusName Prop

}