<ng-container *rxMultilingual="">
  <div *ngIf="showComponent" [rxLocalisationInit]="componentName" class="modal fade show" id="address_edit"
    tabindex="-1" role="dialog" (scroll)="hideTinyMCEMenu()" style="padding-right: 17px; display: block">
    <div class="modal-dialog modal-xl" [rxSpinner]="spin" style="margin-bottom: 90px!important;">
      <div class="modal-content">
        <div class="modal-header text-left">
          <h3 class="modal-title mb-0" *ngIf="isReply" rxText="NoteEditComponent_Reply_Notes_t"></h3>
          <h3 class="modal-title mb-0" *ngIf="!isTaskList && !isReply" rxText="NoteEditComponent_Edit_Notes_t"></h3>
          <h3 class="modal-title mb-0" *ngIf="isTaskList" rxText="NoteEditComponent_Task_List_t"></h3>

          <ng-container *ngIf="!isDataUpdated && isReply" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" style="margin-top: 1px;"  rxText="Saved_As_Draft_t"></label>
          </ng-container>
          <ng-container *ngIf="!isDataUpdated && !isReply && !isTaskList" [rxLocalisationInit]="componentName">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" style="margin-top: 1px;"  rxText="Saved_As_Draft_t"></label>
          </ng-container>

          <button type="button" class="close py-2" (click)="hidePopup()" data-dismiss="modal" [title]="titleClose">
            <span aria-hidden="true">×</span>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="modal-body pb-0" [formGroup]="noteFormGroup">
          <div class="row" *ngIf="!isTaskList">
            <div class="col-lg-12 col-xl-12" *ngIf="isReply">
              <div class="form-group row" >
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_From_t"></label>
                <div class="col-md-10">
                  <input disabled tabindex="4" type="text" formControlName="from" class="form-control "
                    rxPlaceholder="">
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-xl-12" [rxLocalisationInit]="componentName" *ngIf="isReply">
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteEditComponent_Send_To_List_t"></label>
                <div class="col-md-10" style="word-break: break-word">
                  {{ getnotes() }}
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-xl-12" [rxLocalisationInit]="componentName" *ngIf="isReply">
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteEditComponent_Send_CC_List_t"></label>
                <div class="col-md-10" style="word-break: break-word">
                  {{ getnotes(true) }}
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-xl-12" [rxLocalisationInit]="componentName" *ngIf="isReply">
              <div class="form-group row" *ngIf="checkAllUser">
                <!-- <label class="col-md-4 col-xl-4 col-form-label"  rxText="NoteAddEditComponent_AllUsers_t"></label> -->
                <div class="col-md-2" [rxLocalisationInit]="componentName">
                  <div class="i-checks">
                    <label class="mt-1">
                      <!-- <input type="checkbox" formControlName="isDisplayTeamMember"> -->
                      <app-checkbox [tabIndex]="'1'"
                        [checkFormControl]="noteFormGroup.controls.isAllUser"></app-checkbox>
                      <!-- <small class="form-text text-danger" [class.d-block]="noteFormGroup.controls.isAllUser.errors">{{noteFormGroup.controls.isAllUser.errorMessage}}  </small> -->
                    </label>
                  </div>
                </div>
                <label class="col-md-6 col-form-label" rxText="NoteAddEditComponent_AllUsers_t"></label>
              </div>
              <div *ngIf="isFromSalesOrder" [rxLocalisationInit]="componentName">
                <fieldset>
                  <div class="form-group row">
                    <div class="col-md-2">
                      <div class="i-checks">
                        <label class="mt-1">
                          <app-checkbox [tabIndex]="'5'" [checkFormControl]="
                              noteFormGroup.controls.isViewByPeople
                            ">
                          </app-checkbox>
                        </label>
                      </div>
                    </div>
                    <label class="col-md-10 col-xl-10 col-form-label"
                      rxText="NoteAddEditComponent_ViewbyPeople_t"></label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="col-lg-12 col-xl-12" *ngIf="isReply">
              <div class="form-group row" [rxLocalisationInit]="componentName">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_To_t"></label>
                <div class="col-md-10" [rxTagExtended]="_this" *ngIf="showToEmails">
                  <rx-tag #rxTag [controlTabIndex]="2" [(source)]="toUserList" formControlName="toEmails"
                    mainClass="form-control" [keyValueProps]="['userName', 'userId']"></rx-tag>
                  <small class="form-text text-danger" [class.d-block]="noteFormGroup.controls.toEmails.errors">{{
                    noteFormGroup.controls.toEmails.errorMessage }}
                  </small>
                </div>
              </div>
              <div class="form-group row" *ngIf="isReply">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_CC_t"></label>
                <div class="col-md-10 scrollable-tag" [rxTagExtended]="_this">
                  <rx-tag [controlTabIndex]="2" [source]="ccUserList" #rxTag formControlName="ccEmails"
                    mainClass="form-control" [keyValueProps]="['userName','userId']"
                    placeholder="Type the persons name that you wish to send this note as cc" [isSort]="false"></rx-tag>
                  <small class="form-text text-danger"
                    [class.d-block]="noteFormGroup.controls.toEmails.errors">{{noteFormGroup.controls.toEmails.errorMessage}}<br /></small>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 col-form-label"></label>
                <div class="col-md-10">
                  <ng-container *ngIf="!isfabergeUsersSelected" [rxLocalisationInit]="componentName">
                    <button (click)="selectFabergeUKUsers()" class="btn btn-success mr-1"
                      rxText="Btn_Faberge_Users_Select_All_t"></button>
                  </ng-container>
                  <ng-container *ngIf="isfabergeUsersSelected" [rxLocalisationInit]="componentName">
                    <button (click)="removeFabergeUKUsers()" class="btn btn-danger mr-1"
                      rxText="Btn_Faberge_Users_Remove_All_t"></button>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="col-lg-12" *ngIf="isReply" [rxLocalisationInit]="componentName">
              <fieldset [disabled]="isRestricatedDisabled">
                <div class="form-group row">
                  <div class="col-md-2">
                    <div class="i-checks">
                      <label class="mt-1">
                        <app-checkbox [isDisabled]="isRestricatedDisabled" [tabIndex]="'7'" [checkFormControl]="
                            noteFormGroup.controls.isDisplayTeamMember
                          " (change)="changeRestrictionType($event)">
                        </app-checkbox>
                        <small class="form-text text-danger" [class.d-block]="
                            noteFormGroup.controls.isDisplayTeamMember.errors
                          ">{{
                          noteFormGroup.controls.isDisplayTeamMember
                          .errorMessage
                          }}
                        </small>
                      </label>
                    </div>
                  </div>
                  <label class="col-md-10 col-form-label" rxText="NoteAddEditComponent_RestricttoUsers_t"></label>
                </div>
              </fieldset>
            </div>
            <div class="col-lg-12 col-xl-12" *ngIf="isReply" [rxLocalisationInit]="componentName">
              <fieldset>
                <div class="form-group row">
                  <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_Hashtags_t"></label>
                  <div class="col-md-10" [rxTagExtended]="_this">
                    <rx-tag #rxTag [controlTabIndex]="3" [(source)]="notesLookups.hasTags" [tagDisabled]="true"
                      formControlName="hasTags" mainClass="form-control"
                      [keyValueProps]="['noteTypeName', 'noteTypeId']">
                    </rx-tag>
                    <!-- <label class="col-md-8 col-xl-8 col-form-label">{{getHashtags()}}</label>       -->
                    <small class="form-text text-danger" [class.d-block]="noteFormGroup.controls.hasTags.errors">{{
                      noteFormGroup.controls.hasTags.errorMessage }}
                    </small>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-lg-12 col-xl-12" *ngIf="isReply" [rxLocalisationInit]="componentName">
              <fieldset disabled>
                <div class="form-group row">
                  <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_Subject_t"></label>
                  <div class="col-md-10">
                    <!-- <input tabindex="3" type="text" formControlName="subject" class="form-control " rxPlaceholder="NoteAddEditComponent_Subject_p"> -->
                    <label type="text" class="form-control disableLabel-withoutheight mb-0">{{
                      noteFormGroup.value.subject === null ||
                      noteFormGroup.value.subject === undefined ||
                      noteFormGroup.value.subject === ""
                      ? "Enter subject"
                      : noteFormGroup.value.subject
                      }}</label>
                    <small class="form-text text-danger" [class.d-block]="noteFormGroup.controls.subject.errors">{{
                      noteFormGroup.controls.subject.errorMessage }}
                    </small>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="col-lg-12 col-xl-12">
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_Note_t"></label>
                <div class="col-md-10">
                  <!-- <textarea rows="7" formControlName="noteDescription" class="form-control "></textarea>                apiKey="ymc9oaydbj58s75jn7mltppfbhbhzp26megff2k81hm1i1af" -->
                  <editor *ngIf="showTinyMCEEditor" tabindex="4" [init]="{
                      height: 300,
                      menubar: true,
                      convert_urls: false,
                      relative_urls: false,
                      remove_script_host: false,
                      paste_retain_style_properties: true,
                      plugins:'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed  tinymcespellchecker',
                      toolbar:'undo redo | blocks | bold italic backcolor | spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help ',
                      spellchecker_languages: 'UK English=en_GB',
                      statusbar: true,
                      menu: {
                        format: {
                          title: 'Format',
                          items: 'configurepermanentpen | bold italic underline strikethrough ' +
                            'superscript subscript codeformat | styles blocks fontfamily ' +
                            'fontsize align lineheight | forecolor backcolor | removeformat'
                        }
                      },
                      content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                    }" formControlName="noteDescription" apiKey="{{ apiKey }}">
                  </editor>
                  <small class="form-text text-danger" [class.d-block]="
                      noteFormGroup.controls.noteDescription.errors
                    ">{{ noteFormGroup.controls.noteDescription.errorMessage }}
                  </small>
                </div>
              </div>
              <!-- Hidden as per client discussion -->
              <div class="col-12" style="display: none !important; visibility: hidden !important">
                <div class="form-group row">
                  <label class="col-md-2 col-xl-2 mt-3 col-form-label"
                    rxText="NoteEditComponent_EditorDocument_t"></label>
                  <div class="col-md-10">
                    <app-document-list [entityTypeId]="entityTypeIdForEditorDocuments" [entityId]="noteObject.noteId"
                      [isPicture]="false" [isEditorDocument]="true"></app-document-list>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-xl-12 pr-xl-12" *ngIf="isReply" [rxLocalisationInit]="componentName">
              <div class="form-group row">
                <label class="col-md-2 col-xl-2 col-form-label" rxText="NoteAddEditComponent_UploadFile_t"></label>
                <div class="col-md-10">
                  <app-note-document [isEditorDocument]="true" [entityId]="entityId" [isNoteComponent]="true"
                    [entityTypeId]="entityTypeId" [isPicture]="false" [selectedTab]="'Notes'"></app-note-document>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isTaskList" class="hr-line-dashed border-top w-100"></div>
          <div>
            <h3 *ngIf="!isTaskList" rxText="NoteAddEditComponent_Task_t"></h3>
            <div *ngIf="!isTaskList" class="hr-line-dashed border-top w-100"></div>

            <div class="mb-3 table-container" *ngIf="isShowTask" [rxLocalisationInit]="componentName">
              <table class="table table-responsive table-bordered chosen-drop-static mb-0">
                <thead>
                  <tr>
                    <th width="8%" *ngIf="!isReply"><span rxText="TaskDetail_TaskId_t"></span></th>
                    <th width="17%"><span rxText="TaskDetail_TaskDescription_t"></span></th>
                    <th width="15%"><span rxText="TaskDetail_AssignedBy_t"></span></th>
                    <th width="15%"><span rxText="TaskDetail_AssignedTo_t"></span></th>
                    <th width="15%" *ngIf="!isReply"><span rxText="TaskDetail_CreatedAt_t"></span></th>
                    <th width="15%"><span rxText="TaskDetail_CompletionDate_t"></span></th>
                    <th width="7%" *ngIf="!isReply"><span rxText="TaskDetail_IsCompleted_t"></span></th>
                    <th width="10%" *ngIf="showColumns && !isReply"><span rxText="TaskDetail_CompletedAt_t"></span></th>
                    <th width="7%"><span rxText="">Action</span></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let taskDetailFormGroup of taskDetailFormArray; let i = index"
                    [rxLocalisationInit]="componentName">
                    <tr [formGroup]="taskDetailFormGroup">
                      <td *ngIf="!isReply" [attr.rowspan]="taskDetailFormGroup.value.taskDetailId!=0 ? (taskDetailFormGroup.value.commentsCount > 0 ? '3' : '2') : '1'"><label>{{taskDetailFormGroup.value.taskDetailId>0 ? 'Task #'+taskDetailFormGroup.value.taskDetailId:''}}</label></td>
                      <td>
                        <textarea class="form-control" rows="5" formControlName="taskDescription"
                          placeholder="Enter Task Description"
                          [attr.disabled]="isDisableField(taskDetailFormGroup) ? '' : null"></textarea>
                        <small class="form-text text-danger"
                          [class.d-block]="taskDetailFormGroup.controls.taskDescription.errors">{{taskDetailFormGroup.controls.taskDescription.errorMessage}}</small>
                      </td>
                      <td [rxSelectExtended]="_this">
                        <rx-select mainClass="form-control" [autoComplete]="true"
                          [queryParams]="{'lookupType':'adminUsers'}" path="api/SearchEvent/GetLookups" #rxSelect
                          [valueInQueryParams]="true" [minimumCharacterSearchLength]="2" [controlTabIndex]="13"
                          formControlName="assignedByUserId" [selectPlaceholder]="'Select Assigned By'"
                          [keyValueProps]="['#name#','userId']" [text]="taskDetailFormGroup.value.assignedByUserName"
                          [selectDisabled]="isDisableField(taskDetailFormGroup)">
                        </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="taskDetailFormGroup.controls.assignedByUserId.errors">{{taskDetailFormGroup.controls.assignedByUserId.errorMessage}}</small>
                      </td>
                      <td [rxSelectExtended]="_this">
                        <rx-select mainClass="form-control" [autoComplete]="true"
                          [queryParams]="{'lookupType':'adminUsers'}" path="api/SearchEvent/GetLookups" #rxSelect
                          [valueInQueryParams]="true" [minimumCharacterSearchLength]="2" [controlTabIndex]="13"
                          formControlName="assignedToUserId" [selectPlaceholder]="'Select Assignee'"
                          [keyValueProps]="['#name#','userId']" [text]="taskDetailFormGroup.value.assignedToUserName"
                          [selectDisabled]="isDisableField(taskDetailFormGroup)">
                        </rx-select>
                        <small class="form-text text-danger"
                          [class.d-block]="taskDetailFormGroup.controls.assignedToUserId.errors">{{taskDetailFormGroup.controls.assignedToUserId.errorMessage}}</small>
                      </td>
                      <td *ngIf="!isReply">
                        <div class="input-group">
                          <label type="text" class="mb-0">{{(taskDetailFormGroup.value.createdOnString === null
                            ||
                            taskDetailFormGroup.value.createdOnString === undefined ||
                            taskDetailFormGroup.value.createdOnString === '')
                            ? '' : taskDetailFormGroup.value.createdOnString}}</label>
                        </div>
                      </td>
                      <td>
                        <div class="input-group" *ngIf="isDisableField(taskDetailFormGroup); else completionDateElse">
                          <label type="text" class="mb-0"
                            class="form-control disableLabel disableLabel-rightborder mb-0">{{(taskDetailFormGroup.value.completionDate
                            === null
                            ||
                            taskDetailFormGroup.value.completionDate === undefined ||
                            taskDetailFormGroup.value.completionDate === '')
                            ? '' : taskDetailFormGroup.value.completionDate | date: 'dd/MMM/yyyy'}}</label>
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                        <ng-template #completionDateElse>
                          <rx-date showAddon="true" formControlName="completionDate" pickerClass="form-control">
                          </rx-date>
                        </ng-template>
                        <small class="form-text text-danger"
                          [class.d-block]="taskDetailFormGroup.controls.completionDate.errors">{{taskDetailFormGroup.controls.completionDate.errorMessage}}</small>
                      </td>
                      <td *ngIf="!isReply">
                        <div class="col-md-2">
                          <div class="i-checks">
                            <label class="mt-1">
                              <fieldset [disabled]="!taskDetailFormGroup.value.isEditAccess">
                                <app-checkbox [tabIndex]="'7'"
                                  [checkFormControl]="taskDetailFormGroup.controls.isCompleted"
                                  [isDisabled]="!taskDetailFormGroup.value.isEditAccess"></app-checkbox>
                              </fieldset>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="showColumns && !isReply">
                        <div class="input-group">
                          <label type="text" class="mb-0">{{(!taskDetailFormGroup.value.isStatusCompleted
                            || taskDetailFormGroup.value.completedAt === null ||
                            taskDetailFormGroup.value.completedAt === undefined ||
                            taskDetailFormGroup.value.completedAt === '')
                            ? '' : taskDetailFormGroup.value.completedAt | date: 'dd/MMM/yyyy hh:mm a'}}</label>
                        </div>
                      </td>
                      <td [attr.rowspan]="taskDetailFormGroup.value.taskDetailId!=0 ? (taskDetailFormGroup.value.commentsCount > 0 ? '3' : '2') : '1'" >
                        <a href="javascript:void(0);" (click)="!isProcessing ? saveTask(i, taskDetailFormGroup) : null"
                          *ngIf="!isReply" title="Save Task"><i
                            class="fr-save fa-4x font-md text-default mr-2" ></i></a>
                        <a href="javascript:void(0);" class="mr-1" (click)="remove(i, taskDetailFormGroup)"
                          *ngIf="taskDetailFormGroup.value.isDeleteAccess || taskDetailFormGroup.value.taskDetailId ==0" title="Delete Task"><i
                            class="fr-delete fa-4x font-md text-danger" ></i></a>
                      </td>
                    </tr>

                    <tr [formGroup]="taskDetailFormGroup" *ngIf="taskDetailFormGroup.value.taskDetailId!=0 ">
                      <td colspan="6">
                        <div>
                            <textarea class="form-control" placeholder="Add a comment" formControlName="taskComment" rows="2"></textarea>
                        </div>
                      </td>
                    </tr>

                    <tr *ngIf="taskDetailFormGroup.value.taskDetailId!=0 && taskDetailFormGroup.value.commentsCount>0">
                      <td colspan="6">
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                        <span class="mb-0" style="margin-right: 25px;" [rxLocalisationInit]="componentName">
                          <span style="font-weight: bold;" rxText="NoteEditComponent_RecentComment_t"></span>
                          <span>
                          {{ taskDetailFormGroup.value.commentsCount>0 ? taskDetailFormGroup.value.recentComment != null && taskDetailFormGroup.value.recentComment.length  > 255 ? (taskDetailFormGroup.value.recentComment | slice:0:255) + '...' : taskDetailFormGroup.value.recentComment : 'N/A'}}
                          </span>
                        </span>
                        <span>
                          <a *ngIf="taskDetailFormGroup.value.commentsCount>0" [id]="'replybtn' + i" href="javascript:void(0);" class="mr-1 float-right replybtn" (click)="onMoreClickComment(i,taskDetailFormGroup)"
                            [title]="expandComments"><i
                                class="fa fa-plus fa-4x font-md text-default " ></i></a>

                            <a [id]="'replybtnm' + i" href="javascript:void(0);" class="mr-1 float-right replybtnm d-none" (click)="hideCommentSection(i)"
                            [title]="collapseComments"><i
                                class="fa fa-minus fa-4x font-md text-default " ></i></a>
                        </span>
                        </div>
                      </td>
                    </tr>
                   <tr #commentDetailsRow [id]="'comment-tr-' + i" class="comment-tr">
                    <td colspan="8" >

                      <div #commentDetailsDiv [id]="i">
                    </div>
                      </td>

                    </tr>
                  </ng-container>
                </tbody>


              </table>


            </div>
            <div class="ibox-title bg-white px-0 py-0" *ngIf="!isShowTask && !isReply">
              <h4>No Record Found</h4>
              <!-- <div *ngIf="isNoRecordFound"></div> -->
            </div>
          </div>
          <ng-container *ngIf="taskDetailFormArray && taskDetailFormArray.length > 0 && !isTaskList && !isReply"
            [rxLocalisationInit]="componentName">
            <p class="save-task-warning" rxText="NoteAddEditComponent_SaveTaskWarning_t"></p>
          </ng-container>
          <div>
            <button (click)="addNewTask()" class="btn btn-primary mb-3" rxText="Btn_Add_New_Task_t"></button>
          </div>
        </div>
<div class="modal-footer" [rxLocalisationInit]="componentName">



  <ng-container *ngIf="!isTaskList" [rxLocalisationInit]="componentName">

    <ng-container *ngIf="isDataUpdated" [rxLocalisationInit]="componentName"><button type="button" (click)="saveAsDraft()" class="btn btn-success"
      rxText="Btn_Save_Draft_t"></button></ng-container>

    <ng-container *ngIf="!isDataUpdated" [rxLocalisationInit]="componentName"><button type="button" (click)="saveAsDraft()" class="btn btn-success"
      ><span rxText="Btn_Saved_Draft_t"></span><i class="fa fa-check-circle ml-1" aria-hidden="true"></i></button></ng-container>
  </ng-container>
  <ng-container *ngIf="isReply" [rxLocalisationInit]="componentName">
    <button type="button" (click)="editNotes()" class="btn btn-success" rxText="Btn_Send_Note_t"></button>
  </ng-container>
  <ng-container *ngIf="!isTaskList && !isReply" [rxLocalisationInit]="componentName">
    <button type="button" (click)="editNotes()" class="btn btn-success" rxText="Btn_Save_t"></button>
  </ng-container>
  <button type="button" (click)="hidePopup()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
</div>
</div>
</div>
</div>
</ng-container>
