import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vHolidayListBase {

  //#region holidayId Prop
  @gridColumn({ style: { width: "25%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'HolidayListListComponent_HolidayId_gh', keyColumn: true })
  holidayId: number;
  //#endregion holidayId Prop


  //#region holidayName Prop
  @gridColumn({ isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'HolidayListListComponent_HolidayName_gh', keyColumn: false })
  holidayName: string;
  //#endregion holidayName Prop


  //#region holidayYear Prop
  @gridColumn({ parameter:{isDate:true},configuredTemplate: { templateName: "dateDispay" }, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible:true, columnIndex: 2, allowSorting: true, headerKey: 'HolidayListListComponent_HolidayYear_gh', keyColumn: false })
  holidayYear: Date;
  //#endregion holidayYear Prop
  // //#region holidayYear Prop
  // @gridColumn({ parameter:{isDate:true} ,style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'holidayYear', keyColumn: false })
  // holidayDate: string;
  // //#endregion holidayYear Prop
  @gridColumn({parameter:{isDate:true},
    template:{
        div:{
          style: {
            "display": "none",
          },
          attributes:{
            innerHTML:function(x,y){
             return this.holidayDate;
            }
          },
        }
    },visible: true, columnIndex: 3, allowSorting: false, headerTitle: '',name:'holidayDate',style: { width: "1%", "text-align": "initial" }, class: ["text-left"],
})

holidayDate:string;

@gridColumn({ isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerTitle: 'Country', keyColumn: false })
country:string;

}
