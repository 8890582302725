import { TemplateConfig } from '@rxweb/dom/models/template-config';

export const REDIRECT_CONTENT_MANAGEMENT_FAB_PAGE_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                        click: "onNameClick"
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this[e.name] }
                        }
                    }],
                    attributes: {
                        href: function (e) {
                            if (this.topicId != undefined && this.topicId != null && this.topicId > 0) {
                                return "/faberge-pages/" + this.topicId + "?activeTab=page-edit";
                            }
                        },
                        "ng-reflect-router-link": function (e) {
                            if (this.topicId != undefined && this.topicId != null && this.topicId > 0) {
                                return "/faberge-pages/" + this.topicId + "?activeTab=page-edit";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    },
};
