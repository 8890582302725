import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ActivityLogTypeBase {

//#region activityLogTypeId Prop
        @prop()
        activityLogTypeId : number;
//#endregion activityLogTypeId Prop


//#region systemKeyword Prop
        @prop()
        // @required()
        // @maxLength({value:50})
        // @notEmpty()
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region name Prop
        @prop()
        // @required()
        // @maxLength({value:100})
        // @notEmpty()
        name : string;
//#endregion name Prop


//#region statusId Prop
        @prop()
        // @range({minimumNumber:1,maximumNumber:2147483647})
        // @required()
        statusId : number;
//#endregion statusId Prop

//#region viewByPeople Prop
        @prop({defaultValue:false})
        isEnabled : boolean;
//#endregion viewByPeople Prop



}