import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { SystemHelpBase } from 'src/app/models/database-models/system-help-base';
import { AbstractSystemHelp } from '../domain/abstract-system-help';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';

@multilingual("SystemHelpViewComponent")
@Component({
  selector: 'app-system-help-view',
  templateUrl: './system-help-view.component.html',
  styleUrls: ['./system-help-view.component.css']
})
export class SystemHelpViewComponent extends AbstractSystemHelp implements OnInit {
  systemHelp: SystemHelpBase;
  @Input() moduleId: number;
  @Input() systemHelpPageId: number;
  @Input() hide: () => any;
  selectedTab: string = "endUserHelp";
  constructor(private sanitizer: DomSanitizer,private location : PlatformLocation) {
    super();
    this.systemHelp = new SystemHelpBase();
    this.systemHelp.endUserHelp = '';
    this.systemHelp.technicalHelp = '';
    location.onPopState(() => this.hide());
    
  }
  titleClose: string;

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.selectedTab = "endUserHelp";
    this.lookup([
      { propName: "systemHelp", path: "api/systemHelp", queryParams: { "moduleId": encodeURIComponent(JSON.stringify(this.moduleId)), "systemHelpPageId": encodeURIComponent(JSON.stringify(this.systemHelpPageId)) } }
    ]).subscribe((response: any) => {
      this.spin = false;
      this.systemHelp = response.systemHelp;
      this.showComponent = true;

    });
  }

  activeTab(activeTabName: string) {
    this.selectedTab = activeTabName;
  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  get componentName(): string {
    return "SystemHelpViewComponent";
  }
}
