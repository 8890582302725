import {HelpSupportUserRoleBase,HelpSupportBase,} from '@app/database-models'
//Generated Imports
export class HelpSupportUserRole extends HelpSupportUserRoleBase 
{




//#region Generated Reference Properties
//#region helpSupport Prop
helpSupport : HelpSupportBase;
//#endregion helpSupport Prop

//#endregion Generated Reference Properties












}