import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemstoneArrangementsGemFieldBase {

//#region arrangementId Prop
        @prop()
        arrangementId : number;
//#endregion arrangementId Prop


//#region arrangement Prop
        @required()
        @maxLength({value:100})
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        arrangement : string;
//#endregion arrangement Prop



}