import { maxLength, prop, range, required } from '@rxweb/reactive-form-validators';

export class GoalBase {
    //#region goalId Prop
    @prop()
    goalId: number;
    //#endregion goalId Prop

    //#region goalDescription Prop
    @maxLength({ value: 4000 })
    @required()
    goalDescription: string;
    //#endregion goalDescription Prop

    //#region completionDate Prop
    @prop()
    @required()
    completionDate: Date;
    //#endregion completionDate Prop

    //#region startDate Prop
    @prop()
    startDate: Date;
    //#endregion startDate Prop

    //#region goalStatusId Prop
    @required()
    goalStatusId: number;
    //#endregion goalStatusId Prop

    //#region createdBy Prop
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdDate Prop
    @required()
    createdDate: Date;
    //#endregion createdDate Prop

    //#region isSubmitted Prop
    @prop({ defaultValue: false })
    isSubmitted: boolean;
    //#endregion isSubmitted Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region modifiedDate Prop
    @prop()
    modifiedDate: Date;
    //#endregion modifiedDate Prop

    //#region goalRejectionNote Prop
    @maxLength({ value: 4000 })
    @prop()
    goalRejectionNote: string;
    //#endregion goalRejectionNote Prop

    //#region isDeleted Prop
    @prop({ defaultValue: false })
    isDeleted: boolean;
    //#endregion isDeleted Prop

    //#region isApproveMailSent Prop
    @prop({ defaultValue: false })
    isApproveMailSent: boolean;
    //#endregion isApproveMailSent Prop

    //#region isApproveMailSent Prop
    @prop({ defaultValue: false })
    isGoalAddUpdateMailSent: boolean;
    //#endregion isApproveMailSent Prop

}