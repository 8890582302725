import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vActivityLogBase {

        //#region activityLogID Prop
        @gridColumn({ name: "activityLogID", configuredTemplate: { templateName: 'bindCheckBox' }, visible: true, columnIndex: 0, allowSorting: false, headerKey: 'image', headerTitle: "Action", keyColumn: true, style: { width: "1%", "text-align": "initial" } })
        activityLogID: number;
        //#endregion activityLogID Prop

        //#region activityLogID Prop
        @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ActivityLogListComponent_ActivityLogIdNo_gh', keyColumn: false, style: { width: "3%", "text-align": "initial" }, class: ["text-left"] })
        activityLogIDNumber: number;
        //#endregion activityLogID Prop


        //#region activityLogTypeId Prop
        @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerKey: 'ActivityLogListComponent_ActivityLogTypeId_gh', keyColumn: false })
        activityLogTypeId: number;
        //#endregion activityLogTypeId Prop


        //#region name Prop
        @gridColumn({ style: { width: "3%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'ActivityLogListComponent_Name_gh', keyColumn: false })
        name: string;
        //#endregion name Prop


        //#region userId Prop
        @gridColumn({ visible: false, columnIndex: 4, allowSorting: true, headerKey: 'ActivityLogListComponent_UserId_gh', keyColumn: false })
        userId: number;
        //#endregion userId Prop


        //#region email Prop
        @gridColumn({ style: { width: "2%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'ActivityLogListComponent_Email_gh', keyColumn: false, configuredTemplate: { templateName: "reDirect" } })
        email: string;
        //#endregion email Prop


        //#region comment Prop
        @gridColumn({
                template: {
                        div: {
                                attributes: {
                                        innerHTML: function (x, y) {
                                                return this.comment;
                                        }
                                },
                        }
                }, style: { width: "18%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ActivityLogListComponent_Comment_gh', keyColumn: false
        })
        comment: string;
        //#endregion comment Prop


        //#region stCreatedOn Prop
        @gridColumn({ style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ActivityLogListComponent_CreatedOn_gh', keyColumn: false })
        stCreatedOn: any;
        //#endregion stCreatedOn Prop

        //#region createdOn Prop
        //#region createdOn Prop
        @gridColumn({ style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, headerKey: 'ActivityLogListComponent_CreatedOn_gh', keyColumn: false })
        createdOn: Date;
        //#endregion createdOn Prop

        //#region totalCount 
        @gridColumn({ name: "totalCount", style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerTitle: 'Total Count', keyColumn: false })
        totalCount: number;
        //#endregion totalCount

}
