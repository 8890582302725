import { Component, OnInit, Input } from '@angular/core';
import { ChangePasswordViewModel } from 'src/app/models/extended-models/change-password-view-model';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CoreComponent } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { multilingual } from '@rxweb/localization';
import { PasswordViewModel } from 'src/app/view-model/password-view-model';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { userGuid } from 'src/app/domain/services/token-activator';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from '@angular/common';
@multilingual("ChangePasswordComponent")
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends CoreComponent implements OnInit {
  @Input() userId: number;
  @Input() hide: Function;
  spin: boolean = false;
  showComponent: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  changePasswordFormGroup: IFormGroup<ChangePasswordViewModel>
  changePasswordViewModel: ChangePasswordViewModel
  subscription: any;
  passwordModel: PasswordViewModel;
  ispasswordUpdate: boolean;
  isCurrentPassword: boolean;
  passwordAlreadyUsed: boolean;
  confirmPasswordError: boolean;
  isSameCurrentPassword: boolean;
  expiryDays: number;
  daysLeft: number;
  titleClose: string;
  constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private browserStorage: BrowserStorage,private location : PlatformLocation) {
    super()
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.modalView = modalView;
    location.onPopState(() => this.hide());
  }
  get componentName(): string {
    return "ChangePasswordComponent";
  }
  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.expiryDays = this.browserStorage.local.get('expireDays') == undefined ? 5 : Number(this.browserStorage.local.get('expireDays'));
    this.daysLeft = this.browserStorage.local.get('daysLeft') == undefined ? 5 : Number(this.browserStorage.local.get('daysLeft'));
    this.passwordModel = new PasswordViewModel();
    this.passwordModel.userId = this.userId;
    this.passwordModel.isPasswordChanged = true;
    this.passwordModel.isExpired = this.browserStorage.local.get('daysLeft') == undefined ? false : (Number(this.browserStorage.local.get('daysLeft')) == 0 ? true : false)
    this.changePasswordViewModel = new ChangePasswordViewModel();
    this.changePasswordViewModel.userId = this.userId;
    this.changePasswordViewModel.isChangePassword = true;
    this.changePasswordFormGroup = this.formBuilder.formGroup(this.changePasswordViewModel) as IFormGroup<ChangePasswordViewModel>;
    this.changePasswordFormGroup.controls.newPassword.setValidators([RxwebValidators.required({ message: "You can't leave this empty" }),
    RxwebValidators.pattern({ expression: { 'newPassword': /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&,.=_])[A-Za-z\d@$!%*#?&,.=_]{8,50}$/ }, message: '• Must be at least 8 characters and less than 50<br>• Must contain at least one lower case letter, one upper case <span class="ml-2">letter,one digit and one special character</span><br>• Allowed special characters are @$!%*#?&,.=_' })]);
    this.showComponent = true;

  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  hideMsg() {
    if (this.passwordAlreadyUsed) {
      this.passwordAlreadyUsed = false;
    }
    if (this.isCurrentPassword) {
      this.isCurrentPassword = false;
    }
    if (this.confirmPasswordError) {
      this.confirmPasswordError = false;
    }
    if (this.isSameCurrentPassword) {
      this.isSameCurrentPassword = false;
    }

  }
  ChangePassword() {
    this.changePasswordFormGroup.submitted = true;
    if (this.changePasswordFormGroup.valid) {
      if (this.changePasswordFormGroup.value.newPassword != this.changePasswordFormGroup.value.confirmPassword) {
        this.confirmPasswordError = true;
      }
      else {
        this.confirmPasswordError = false;
        this.passwordModel.oldPassword = this.changePasswordFormGroup.value.currentPassword;
        this.passwordModel.newPassword = this.changePasswordFormGroup.value.newPassword;
        this.passwordModel.guid = userGuid == "" ? "00000000-0000-0000-0000-000000000000" : userGuid;
        this.changePasswordFormGroup.value.isExpired = false;
        this.changePasswordFormGroup.value.guid = userGuid;
        this.changePasswordFormGroup.value.isChangePassword = true;
        this.changePasswordFormGroup.value.screen = "changePasswordScreen";
        this.spin = true;
        this.post({ body: this.changePasswordFormGroup.value, path: "api/Authentication/changePasswordAfterLogin" }).subscribe((data: any) => {
          this.spin = false;
          if (data > 0) {
            this.spin = false;
            this.isCurrentPassword = false;
            this.passwordAlreadyUsed = true;
            this.isSameCurrentPassword = false;
          }
          else {
            this.passwordAlreadyUsed = false;
            this.isSameCurrentPassword = false;

            if (data == -2) {
              this.spin = false;
              // this.element.classList.remove("reset-page-view");
              //   this.toastr.success("Password Changed Successfully");
              //   this.router.navigate(['/login']);
              this.isCurrentPassword = true;
            }
            else if (data == -1) {
              this.passwordAlreadyUsed = false;
              this.isSameCurrentPassword = true;
            }
            else {
              this.passwordAlreadyUsed = false;
              this.isCurrentPassword = false;
              this.isSameCurrentPassword = false;
              this.spin = false;
              this.browserStorage.local.save('expireDays', 5)
              this.browserStorage.local.save('daysLeft', 90)
              this.hide();
              this.toastr.success(getLocalizedValue("Password_Changed_Success_Message_Key"));
            }
          }
          
        })
      }

    }
  }

  badRequest = (data) => {
    this.spin = false;
    this.toastr.warning(JSON.parse(data)[0]);
    //this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
    //})
  }

}
