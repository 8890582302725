import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerSessionBase {

//#region customerSessionGUID Prop
        @prop()
        customerSessionGUID : any;
//#endregion customerSessionGUID Prop


//#region userId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        userId : number;
//#endregion userId Prop


//#region lastAccessed Prop
        @required()
        lastAccessed : Date;
//#endregion lastAccessed Prop


//#region isExpired Prop
        @required()
        isExpired : boolean;
//#endregion isExpired Prop


//#region iPAddress Prop
        @maxLength({value:100})
        iPAddress : string;
//#endregion iPAddress Prop


//#region isLoggedinFromAdmin Prop
        @required()
        isLoggedinFromAdmin : boolean;
//#endregion isLoggedinFromAdmin Prop



}