import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { multilingual } from "@rxweb/localization";
import { AbstractProductPiece } from "../domain/abstract-product-piece";
import { DomSanitizer } from "@angular/platform-browser";
import { BulkUpdatePiece } from "src/app/view-model/bulk-update-piece";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@multilingual("ErrorUpdatePiecesFromExcelComponent")
@Component({
  selector: "app-error-update-pieces-from-excel",
  templateUrl: "./error-update-pieces-from-excel.component.html",
  styleUrls: ["./error-update-pieces-from-excel.component.css"],
})
export class ErrorUpdatePiecesFromExcelComponent
  extends AbstractProductPiece
  implements OnInit
{
  @Input() failedPiecesList: BulkUpdatePiece[];
  @Input() hide: Function;
  @Input() totalPieces: number;
  TodayDate = new Date();
  MMDDYY = moment(this.TodayDate).format("MMDDYY").toString();
  titleClose: string;
  lookUp: any;

  constructor(private domSanitizer: DomSanitizer,private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(domSanitizer,blobState);
    location.onPopState(() => this.hide());
  }

  ngOnInit() {
    this.titleClose = getLocalizedValue("Title_Close");
    this.showComponent = true;
  }

  ExportToExcelErrorData() {
    this.exportAsExcelFile(
      this.failedPiecesList != undefined ? this.failedPiecesList : []
    );
  }

  public exportAsExcelFile(pieceData: BulkUpdatePiece[]): void {
      this.spin = false;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("PiecesInfo");
      let columnHeaders = [];
      columnHeaders.push(
        { header: "Remarks", key: "remarks", width: 60 },
        { header: "Invoice No", key: "invoiceNo", width: 25 },
        { header: "Product Code", key: "productCode", width: 25 },
        { header: "Description", key: "description", width: 60 },
        { header: "Total Weight", key: "totalWeight", width: 25 },
        { header: "Metal Weight", key: "metalWeight", width: 25 },
        { header: "Ring Size", key: "ringSize", width: 25 },
        { header: "Bangle Size", key: "bangleSize", width: 25 },
        { header: "Egg Size", key: "eggSize", width: 25 },
        { header: "Chain Length", key: "chainLength", width: 25 },
        { header: "Workshop Ref No", key: "workshopRefNo", width: 25 }
      );
      worksheet.columns = columnHeaders;

      const objKeys = Object.keys(pieceData[0]);
      for (let index = 0; index < pieceData.length; index++) {
        let row = {};
        objKeys.forEach((key) => {
          row[key] = pieceData[index][key];
        });
        let addedRow = worksheet.addRow(row);
        addedRow.height = 30;
        pieceData[index].failedFields.forEach((field) => {
          addedRow.getCell(field).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "FFFFFF00",
            },
            bgColor: {
              argb: "FFFFFF00",
            },
          };

          addedRow.getCell(field).border = {
            top: { style: "thin", color: { argb: "FF808080" } },
            left: { style: "thin", color: { argb: "FF808080" } },
            bottom: { style: "thin", color: { argb: "FF808080" } },
            right: { style: "thin", color: { argb: "FF808080" } },
          };
        });
      }

      setTimeout(() => {
        this.spin = false;
        workbook.xlsx.writeBuffer().then((y: any) => {
          const blob = new Blob([y], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          fs.saveAs(blob, "Failed pieces " + this.MMDDYY);
        });
      }, 0);
  }

  Uint8ToString(u8a) {
    var CHUNK_SZ = 0x8000;
    var c = [];
    for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
      c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
    }
    return c.join("");
  }

  get componentName(): string {
    return "ErrorUpdatePiecesFromExcelComponent";
  }
}
