import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';

//Generated Imports
export class BulkUpdatePiece implements FileDetailViewModel
{
  fileData: string;
  fileExtention: string;
  fileName: string;
  displayImage: boolean;
  imageURL1: string;
  imageURL2: string;
  imageURL3: string;
  imageURL4: string;
  imageURL5: string;
  fileData1: string;
  fileExtention1: string;
  fileName1: string;
  fileData2: string;
  fileExtention2: string;
  fileName2: string;

  invoiceNo: string;
  productCode: string;
  description: string;
  totalWeight: string;
  metalWeight: string;
  ringSize: string;
  bangleSize: string;
  eggSize: string;
  unitPrice: string;
  chainLength: string;
  workshopRefNo: string;
  isPieceNotFound?: boolean;
  failedFields: string[];
}
