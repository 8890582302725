import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemfieldsArrangementBase {

//#region arrangementId Prop
        @prop()
        arrangementId : number;
//#endregion arrangementId Prop


//#region arrangement Prop
        @required()
        @maxLength({value:50})
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        @trim()
        arrangement : string;
//#endregion arrangement Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}