import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CollectionDesignModuleBase {

//#region designModuleId Prop
        @prop()
        designModuleId : number;
//#endregion designModuleId Prop


//#region collectionId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        collectionId : number;
//#endregion collectionId Prop


//#region displayType Prop
        @required()
        @maxLength({value:5})
        displayType : string;
//#endregion displayType Prop


//#region sliderImage1 Prop
        @required()
        sliderImage1 : string;
//#endregion sliderImage1 Prop


//#region sliderImage2 Prop
        @required()
        sliderImage2 : string;
//#endregion sliderImage2 Prop


//#region sliderImage3 Prop
        @required()
        sliderImage3 : string;
//#endregion sliderImage3 Prop


//#region sliderImage4 Prop
        @required()
        sliderImage4 : string;
//#endregion sliderImage4 Prop


//#region sliderImage5 Prop
        @required()
        sliderImage5 : string;
//#endregion sliderImage5 Prop


//#region videopath Prop
        @required()
        videopath : string;
//#endregion videopath Prop


//#region contentText Prop
        @required()
        contentText : string;
//#endregion contentText Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region imageAlt1 Prop
        @maxLength({value:500})
        imageAlt1 : string;
//#endregion imageAlt1 Prop


//#region imageAlt2 Prop
        @maxLength({value:500})
        imageAlt2 : string;
//#endregion imageAlt2 Prop


//#region imageAlt3 Prop
        @maxLength({value:500})
        imageAlt3 : string;
//#endregion imageAlt3 Prop


//#region imageAlt4 Prop
        @maxLength({value:500})
        imageAlt4 : string;
//#endregion imageAlt4 Prop


//#region imageAlt5 Prop
        @maxLength({value:500})
        imageAlt5 : string;
//#endregion imageAlt5 Prop

}