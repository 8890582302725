<!-- #291870//28-06-2024//adding order mapping to project and event -->
<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="modal-content">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="CorporateOrdersAddComponent_Faberge_Orders_t"></h4>

      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div [rxLocalisationInit]="componentName" class="modal-body" [formGroup]="orderSearchFilterFormGroup"
         *ngIf="showComponent" (keyup.enter)="search()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_OrderID_t"></label>
            <div class="col-md-12">
              <input type="text" class="form-control" formControlName="orderId" tabindex="1"
                     rxPlaceholder="CorporateOrdersAddComponent_OrderID_p" />
            </div>

          </div>

          <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_Orderstatus_t"></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
              <rx-select mainClass="form-control" [(source)]="corporateOrderLookup.orderStatusLookUp" #rxSelect [controlTabIndex]="2"
                         formControlName="orderStatusId" [selectPlaceholder]="'Select Order Status'"
                         [keyValueProps]="['orderStatusName','orderStatusId']">
              </rx-select>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_OrderPaymentStatus_t"></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
              <rx-select mainClass="form-control" [(source)]="corporateOrderLookup.paymentStatusLookUp" #rxSelect [controlTabIndex]="12"
                         formControlName="paymentStatusId" [selectPlaceholder]="'Select Payment Status'"
                         [keyValueProps]="['paymentStatusName','paymentStatusId']">
              </rx-select>
            </div>
          </div> -->
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_OrderSalesChannel_t"></label>
            <div class="col-md-12" [rxTagExtended]="_this">
              <rx-tag [(source)]="corporateOrderLookup.salesChannelLookUps" #rxTag [controlTabIndex]="8"
                [isSort]="false" formControlName="salesChannelIds" mainClass="form-control" placeholder="Select Sales Channel"
                [keyValueProps]="['salesChannelName','salesChannelId']">
              </rx-tag>
            </div>
          </div>


        </div>

        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_OrderDateFrom_t"></label>
            <div class="col-md-12">
              <rx-date showAddon="true" [controlTabIndex]="5" rxFocus formControlName="startDate"
                       pickerClass="form-control" placeholder="Select Start Date"></rx-date>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_OrderDateTo_t"></label>
            <div class="col-md-12">
              <rx-date showAddon="true" formControlName="endDate" [controlTabIndex]="6" pickerClass="form-control"
                       placeholder="Select End Date"></rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true" [rxLocalisationInit]="componentName"><span rxText="CorporateOrdersAddComponent_DateError_t"></span><br /></small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
                   rxText="CorporateOrdersAddComponent_OrderSalesPerson_t"></label>
            <div class="col-md-12" [rxTagExtended]="_this">
              <rx-tag [(source)]="corporateOrderLookup.salesAdvisorLookUps" #rxTag [controlTabIndex]="9"
                [isSort]="false" formControlName="salesPersonId" mainClass="form-control" placeholder="Select Sales Person"
                [keyValueProps]="['fullName','userId']">
              </rx-tag>
            </div>
          </div>
        </div>
      </div>

      <div class="hr-line-dashed border-top w-100"></div>
      <div class="row mx-0">
        <div class="form-group ml-auto mb-0" [rxLocalisationInit]="componentName">
          <button (click)="search()" class="btn btn-success" rxText="Btn_Search_t"></button>
        </div>
      </div>
      <div class="table-responsive mt-3" (keyup.enter)="searchOrder()" *ngIf="showGrid">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
          <div class="table-responsive" *ngIf="isShowGrid">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="CorporateOrderEventProjectListGrid"></rx-grid>
            </div>
          </div>
          <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
            <h4>No Record Found</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
      <ng-container *ngIf="showGrid" [rxLocalisationInit]="componentName">
        <button (click)="save(false)" class="btn btn-success"
                [disabled]="(this.corporateOrderEventProjectMappingArray.length == 0  && this.excludedCorporateOrderEventProjectMappingArray.length == 0 && !this.headerBtnChk)
          || (this.totalRecords - this.excludedCorporateOrderEventProjectMappingArray.length == 0)"
                rxText="Btn_Save_t"></button>
      </ng-container>
      <ng-container *ngIf="showGrid" [rxLocalisationInit]="componentName">
        <button (click)="save(true)" class="btn btn-success"
                [disabled]="(this.corporateOrderEventProjectMappingArray.length == 0  && this.excludedCorporateOrderEventProjectMappingArray.length == 0 && !this.headerBtnChk)
                || (this.totalRecords - this.excludedCorporateOrderEventProjectMappingArray.length == 0)"
                rxText="Btn_Save_Continue_Edit_t"></button>
      </ng-container>
      <ng-container *ngIf="showGrid" [rxLocalisationInit]="componentName">
        <button  (click)="hide()" class="btn btn-outline-primary ml-0" rxText="Btn_Close_t"></button>
      </ng-container>
    </div>
  </div>
</div>
