import { prop,propObject,propArray,required,maxLength,range, notEmpty, trim  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductBatchPieceRepairBase {

//#region productVariantBatchPieceRepairID Prop
        @prop()
        productVariantBatchPieceRepairID : number;
//#endregion productVariantBatchPieceRepairID Prop


//#region repairGUID Prop
        @required()
        repairGUID : any;
//#endregion repairGUID Prop


//#region productVariantBatchPieceID Prop
        @prop()
        productVariantBatchPieceID : number;
//#endregion productVariantBatchPieceID Prop


//#region dateOfRequest Prop
        @required()
        dateOfRequest : Date;
//#endregion dateOfRequest Prop


//#region salesPersonID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        salesPersonID : number;
//#endregion salesPersonID Prop


//#region approvalStatus Prop
       // @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        approvalStatus : number;
//#endregion approvalStatus Prop


//#region estimationRequired Prop
        @required()
        estimationRequired : boolean;
//#endregion estimationRequired Prop


//#region deliveryDate Prop
        @required()
        deliveryDate : Date;
//#endregion deliveryDate Prop


//#region repairNeeded Prop
        @required()
        @notEmpty()
        @trim()
        repairNeeded : string;
//#endregion repairNeeded Prop


//#region additionalRepairDetails Prop
        @prop()
        additionalRepairDetails : string;
//#endregion additionalRepairDetails Prop


//#region uploadFile1 Prop
        @prop()
        uploadFile1 : string;
//#endregion uploadFile1 Prop


//#region uploadFile2 Prop
        @prop()
        uploadFile2 : string;
//#endregion uploadFile2 Prop


//#region uploadFile3 Prop
        @prop()
        uploadFile3 : string;
//#endregion uploadFile3 Prop


//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop


//#region approveDate Prop
        @prop()
        approveDate : Date;
//#endregion approveDate Prop



}