import { AbstractUser } from '../domain/abstract-user';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { User, UserDetail } from '@app/models';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import * as CryptoJS from 'crypto-js';
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';

@Component({
    selector: "user-view",
    templateUrl: './user-view.component.html'
})
export class UserViewComponent extends AbstractUser implements OnInit, OnDestroy {
    subscription: any;
    user: User;
    email: string;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private datePipe: DatePipe, blobState: BlobUploadsViewStateService) {
        super(blobState)
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(t => {
            this.email = t['email'];
        });

        this.user = new User();
        this.user.userId = 10;
        this.user.email = this.email;
        this.user.statusId = StatusEnum.Active;
        this.user.accountActivation = true;
        this.user.roleTypeId = 1;
        this.user.username = this.email;
        this.user.registrationDate = new Date();
        this.userFormGroup = this.formBuilder.formGroup(this.user) as IFormGroup<User>;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    updateUserStatus() {
        this.userFormGroup.submitted = true;
        if (this.userFormGroup.valid) {
            this.spin = true;
            this.put({ body: this.userFormGroup.value }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					              //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
