import {GemStoneClarityBase,ProductPieceGemstoneBase,} from '@app/database-models'
//Generated Imports
export class GemStoneClarity extends GemStoneClarityBase 
{




//#region Generated Reference Properties
//#region productPieceGemstones Prop
productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop

//#endregion Generated Reference Properties












}