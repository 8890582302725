<div class="modal-dialog">
  <div class="modal-content" *rxMultilingual="">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0">Edit Known To User Details</h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="ml-4 modal-body pb-0 ibox-content bg-white d-flex flex-column flex-grow-1"
      [formGroup]="userKnownToEditFormGroup" *ngIf="showComponent" [rxSpinner]="spin" [rxLocalisationInit]="componentName"
      (keyup.enter)="editRelationShip()">
      <div class="row">
        <div class="col-md-12 col-xl-12 pr-xl-12">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="knownToName"></label>
            <div class="col-md-8">
              <!-- <input type="text" formControlName="fullName" class="form-control" disabled /> -->
              <label type="text" class="form-control disableLabel mb-0">{{(userKnownToEditFormGroup.value.fullName
                ===
                null ||
                userKnownToEditFormGroup.value.fullName ===
                undefined ||
                userKnownToEditFormGroup.value.fullName === '')
                ? '' :
                userKnownToEditFormGroup.value.fullName}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 pr-xl-12">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="knownToCorporateGroup"></label>
            <div class="col-md-8">
              <!-- <input type="text" formControlName="company" class="form-control" disabled /> -->
              <label type="text" class="form-control disableLabel mb-0">{{(userKnownToEditFormGroup.value.company
                ===
                null ||
                userKnownToEditFormGroup.value.company ===
                undefined ||
                userKnownToEditFormGroup.value.company === '')
                ? '' :
                userKnownToEditFormGroup.value.company}}</label>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12 col-xl-12 pr-xl-12">
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="knownRelationShip"></label>
            <div class="col-md-auto">
              <select class="form-control" formControlName="relationShipId">
                <option *ngFor="let item of userKnownToLookUp.relationShipLookUp" [value]="item.relationShipId">
                  {{item.relationShipName}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="editRelationShip()" class="btn btn-success mr-1">Save</button>
        <button type="button" class="btn btn-primary" (click)="hide()">Close</button>
      </div>
    </div>

  </div>
</div>
