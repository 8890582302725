import { ProjectBase, ProjectCorporateMappingBase, ProjectEventMappingBase, ProjectPictureBase, } from '@app/database-models'
//Generated Imports
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop } from '@rxweb/reactive-form-validators';
export class Project extends ProjectBase implements FileDetailViewModel {
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    productId: string;
    @prop()
    filePath: string;

    projects: Array<Project>;

    //#region Generated Reference Properties
    //#region projectCorporateMappings Prop
    projectCorporateMappings: ProjectCorporateMappingBase[];
    //#endregion projectCorporateMappings Prop
    //#region projectEventMappings Prop
    projectEventMappings: ProjectEventMappingBase[];
    //#endregion projectEventMappings Prop
    //#region projectPictures Prop
    projectPictures: ProjectPictureBase[];
    //#endregion projectPictures Prop

    //#endregion Generated Reference Properties








    @prop({ defaultValue: false })
    isRemovedProjectPicture: boolean

    @prop({ defaultValue: false })
    isRemovedCostFile: boolean
    headerBtnChk: boolean;
    query: string;
    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;

}