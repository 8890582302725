import { actionColumn, gridColumn } from '@rxweb/grid';

@actionColumn({
   allowSorting: false,
   style: { width: "1%" },
   configuredTemplate: { templateName: 'saveButton' }, columnIndex: 6, headerKey: "WatchInvoiceListComponent_Update_gh"
})

@actionColumn({
   allowSorting: false,
   style: { width: "1%" },
   configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 7, headerKey: "WatchInvoiceListComponent_Delete_gh"
})

export class WatchInvoiceListViewModel {

   //@gridColumn({visible: false, columnIndex: 0, keyColumn: false })
   totalCount: number;

   @gridColumn({ name: "watchInvoiceId", visible: false, columnIndex: 1, keyColumn: true })
   watchInvoiceId: number;

   @gridColumn({ name: "createdOn", parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, headerKey: 'WatchInvoiceListComponent_Created_On_gh', keyColumn: false })
   createdOn: string;

   //@gridColumn({ name: "createdBy", visible: false, columnIndex: 3, keyColumn: false })
   createdBy: number;

   @gridColumn({ name: "createdByName", parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true,allowSorting: true, columnIndex: 3, headerKey: 'WatchInvoiceListComponent_Created_By_gh', keyColumn: false })
   createdByName: string;

   @gridColumn({ name: "fileName", parameter: false, style: { width: "1%", "text-align": "initial"}, class: ["text-left" , "word-break"], visible: true, columnIndex: 4, headerKey: 'WatchInvoiceListComponent_Invoices_gh', keyColumn: false, configuredTemplate: { templateName: "fabGemstoneCertificateLink" } })
   fileName: string;

   //@gridColumn({ name: "certificateUrl", visible: false, columnIndex: 6, keyColumn: false })
   invoiceUrl: string;

   @gridColumn({ name: "invoiceNumber", parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, headerKey: 'WatchInvoiceListComponent_Invoice_No_gh', keyColumn: false, configuredTemplate: { templateName: "onWatchInvoiceNumber" } })
   invoiceNumber: string;

    @gridColumn({ name: "keyId",  visible: false, columnIndex: 8, keyColumn: false })
    keyId: number;

   // @gridColumn({ name: "laboratoryName", visible: false, columnIndex: 9, keyColumn: false })
   // laboratoryName: string;

   // @gridColumn({
   //    template: {
   //       div: {
   //          onDemandSelector: {
   //             selector: 'rx-select', columnName: 'lookupData'
   //          }
   //       }
   //    }, name: "lookupData", parameter: false, style: { width: "1%", "text-align": "initial" }, class: ["text-left","pt-0"], visible: true, columnIndex: 10, headerKey: 'ProdGemCertiListComponent_LaboratoryId_gh', keyColumn: false
   // })
   // lookupData: any;

   //@gridColumn({ name: "gemstoneId", visible: false, columnIndex: 11, keyColumn: false })
   watchPartId: number;
}