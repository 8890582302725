import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMainCollectionRecordBase {

//#region collectionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'collectionId', keyColumn: true})
        collectionId : number;
//#endregion collectionId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region description Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region metaKeyword Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'metaKeyword', keyColumn: false})
        metaKeyword : string;
//#endregion metaKeyword Prop


//#region metaDescription Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'metaDescription', keyColumn: false})
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'metaTitle', keyColumn: false})
        metaTitle : string;
//#endregion metaTitle Prop


//#region parentCollectionId Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'parentCollectionId', keyColumn: false})
        parentCollectionId : any;
//#endregion parentCollectionId Prop


//#region inspirations Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'inspirations', keyColumn: false})
        inspirations : string;
//#endregion inspirations Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'displayOrderId', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'published', keyColumn: false})
        published : any;
//#endregion published Prop


//#region videoUrl Prop
        @gridColumn({visible: true, columnIndex:10, allowSorting: true, headerKey: 'videoUrl', keyColumn: false})
        videoUrl : string;
//#endregion videoUrl Prop


//#region imageUrlOne Prop
        @gridColumn({visible: true, columnIndex:11, allowSorting: true, headerKey: 'imageUrlOne', keyColumn: false})
        imageUrlOne : string;
//#endregion imageUrlOne Prop


//#region imageUrlSecond Prop
        @gridColumn({visible: true, columnIndex:12, allowSorting: true, headerKey: 'imageUrlSecond', keyColumn: false})
        imageUrlSecond : string;
//#endregion imageUrlSecond Prop


//#region imageUrlThird Prop
        @gridColumn({visible: true, columnIndex:13, allowSorting: true, headerKey: 'imageUrlThird', keyColumn: false})
        imageUrlThird : string;
//#endregion imageUrlThird Prop

}