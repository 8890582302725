import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLeaveStatusLookUpBase {

//#region leaveId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'LeaveStatusId', keyColumn: true})
        leaveStatusId : number;
//#endregion leaveId Prop


//#region leaveTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'LeaveStatusName', keyColumn: false})
        leaveStatusName : string;
//#endregion leaveTypeName Prop

}