import { gridColumn } from "@rxweb/grid"


export class vGemStoneColorBase {

//#region colorId Prop
        @gridColumn({style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemStoneColorListComponent_ColorId_gh', keyColumn: true })
        colorId : number;
//#endregion colorId Prop


//#region colorName Prop
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, visible: true, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'GemStoneColorListComponent_ColorName_gh', keyColumn: false })
        colorName : string;
//#endregion colorName Prop

}