import {PictureBase,ProductPiecePictureBase,ProductDesignPictureBase,ProjectPictureBase,GemStonePictureBase,EventPictureBase,ProductDesignSketchBase,ProductSketchBase,SupplierPictureBase,ProductPictureBase,ProductDownloadPictureBase,GemStonePicturesGemFieldBase,ProductPieceBigPictureBase,} from '@app/database-models'
//Generated Imports
export class Picture extends PictureBase 
{




//#region Generated Reference Properties
//#region productPiecePictures Prop
productPiecePictures : ProductPiecePictureBase[];
//#endregion productPiecePictures Prop
//#region productDesignPictures Prop
productDesignPictures : ProductDesignPictureBase[];
//#endregion productDesignPictures Prop
//#region projectPictures Prop
projectPictures : ProjectPictureBase[];
//#endregion projectPictures Prop
//#region gemStonePictures Prop
gemStonePictures : GemStonePictureBase[];
//#endregion gemStonePictures Prop
//#region eventPictures Prop
eventPictures : EventPictureBase[];
//#endregion eventPictures Prop
//#region productDesignSketches Prop
productDesignSketches : ProductDesignSketchBase[];
//#endregion productDesignSketches Prop
//#region productSketches Prop
productSketches : ProductSketchBase[];
//#endregion productSketches Prop
//#region supplierPictures Prop
supplierPictures : SupplierPictureBase[];
//#endregion supplierPictures Prop
//#region productPictures Prop
productPictures : ProductPictureBase[];
//#endregion productPictures Prop
//#region productDownloadPictures Prop
productDownloadPictures : ProductDownloadPictureBase[];
//#endregion productDownloadPictures Prop
//#region gemStonePicturesGemFields Prop
gemStonePicturesGemFields : GemStonePicturesGemFieldBase[];
//#endregion gemStonePicturesGemFields Prop
//#region productPieceBigPictures Prop
productPieceBigPictures : ProductPieceBigPictureBase[];
//#endregion productPieceBigPictures Prop

//#endregion Generated Reference Properties




















}