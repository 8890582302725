import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CarrierGemFieldBase {

//#region carrierId Prop
        @prop()
        carrierId : number;
//#endregion carrierId Prop


//#region carrierName Prop
        @required()
        carrierName : string;
//#endregion carrierName Prop

}