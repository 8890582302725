import { PressMediaDetailBase } from '@app/database-models';
import { prop, required } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';

export class PressMediaDetail extends PressMediaDetailBase implements FileDetailViewModel {
    @prop()
    journalistIds:string;
    @prop()
    journalistNames:string;
    @prop()
    publicationIds:string;
    @prop()
    publicationNames:string;
    @prop()
    userIds:string;
    @prop()
    userNames:string;
    @prop()
    eventIds:string;
    @prop()
    eventNames:string;
    @prop()
    projectIds:string;
    @prop()
    marketIds:string;
    @prop()
    languageIds:string;
    @prop()
    hSEGINAIds:string;
    @prop()
    projectNames:string;
    @prop()
    productIds:string;
    @prop()
    productNames:string;
    @prop()
    addedUserIds:string;
    @prop()
    supplierIds:string;
    @prop()
    supplierNames:string;
    @prop()
    keywordIds:string;
    @prop()
    keywordNames:string[];
    @prop()
    keywordText:string;
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;

    @prop()
    fileURL1: string;
    @prop()
    fileURL2: string;

    @prop()
    pressMediaDetailModel:any;
    @prop({defaultValue:false})
    isPrimaryCategoryUpdate:boolean;

    @prop({defaultValue:0})
    oldMappingId:number;

    @prop({defaultValue:0})
    newMappingId:number;

    @prop({defaultValue:0})
    newCategoryType:number;

    @prop({defaultValue:0})
    oldCategoryType:number;

    @prop({defaultValue:false})
    showDelete:boolean;

    @prop({defaultValue:false})
    showPrimarytab:boolean;


    @prop({defaultValue:false})
    isRemoveFile:boolean;

    @prop()
    @required()
    publishedOn: Date;

    @prop({defaultValue : ''})
    riskIds : string;

    @prop()
    isUpdate : boolean;

    @prop()
    blobFileName:string;
    @prop()
    blobPath:string;
    @prop()
    size:number;
}
