import {vProductGemstoneTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vProductGemstoneTypeLookUp extends vProductGemstoneTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}