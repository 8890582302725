import { SubMenuItemBase } from '@app/database-models';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop } from '@rxweb/reactive-form-validators';

export class SubMenuItem extends SubMenuItemBase implements FileDetailViewModel{
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    fileData3: string;
    @prop()
    fileExtention3: string;
    @prop()
    fileName3: string;
    @prop()
    fileData4: string;
    @prop()
    fileExtention4: string;
    @prop()
    fileName4: string;
    @prop()
    displayImage: boolean;
    @prop()
    displayImage2: boolean;
    @prop()
    displayImage3: boolean;
    @prop()
    displayImage4: boolean;
    @prop()
    displayImage5: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;

    @prop({defaultValue:false})
    isRemoveImage:boolean;

    @prop()
    isRemoveImage1:boolean;

    @prop({defaultValue:7})
    languageId:number;

    @prop()
    subMenuNameEng: string;

    @prop({defaultValue:0})
    subMenuRank: number;

    @prop()
    featuredNameEng: string;
    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
}