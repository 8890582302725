import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneGemfieldBase {

//#region gemStoneId Prop
        @prop()
        gemStoneId : number;
//#endregion gemStoneId Prop


//#region tAId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        tAId : number;
//#endregion tAId Prop


//#region title Prop
        @required()
        @maxLength({value:100})
        title : string;
//#endregion title Prop


//#region categoryId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        categoryId : number;
//#endregion categoryId Prop


//#region clientDescription Prop
        @prop()
        clientDescription : string;
//#endregion clientDescription Prop


//#region internalDescription Prop
        @prop()
        internalDescription : string;
//#endregion internalDescription Prop


//#region status Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        status : number;
//#endregion status Prop


//#region published Prop
        @required()
        published : boolean;
//#endregion published Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        @numeric()
        @notEmpty()
        displayOrder : number;
//#endregion displayOrder Prop


//#region carats Prop
        @prop()
        carats : number;
//#endregion carats Prop


//#region cutType Prop
        @prop()
        cutType : number;
//#endregion cutType Prop


//#region pricePerCarat Prop
        @prop()
        pricePerCarat : number;
//#endregion pricePerCarat Prop


//#region exportPrice Prop
        @prop()
        exportPrice : number;
//#endregion exportPrice Prop


//#region locationId Prop
        @prop()
        locationId : number;
//#endregion locationId Prop


//#region legalOwnerId Prop
        @prop()
        legalOwnerId : number;
//#endregion legalOwnerId Prop


//#region colorId Prop
        @prop()
        colorId : number;
//#endregion colorId Prop


//#region saturationId Prop
        @prop()
        saturationId : number;
//#endregion saturationId Prop


//#region clartyId Prop
        @prop()
        clartyId : number;
//#endregion clartyId Prop


//#region consignment Prop
        @required()
        @maxLength({value:100})
        consignment : string;
//#endregion consignment Prop


//#region quantity Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        quantity : number;
//#endregion quantity Prop


//#region supplierId Prop
        @prop()
        supplierId : number;
//#endregion supplierId Prop


//#region enhancementId Prop
        @prop()
        enhancementId : number;
//#endregion enhancementId Prop


//#region arrangementId Prop
        @prop()
        arrangementId : number;
//#endregion arrangementId Prop


//#region isCertified Prop
        @required()
        isCertified : boolean;
//#endregion isCertified Prop


//#region totalExportPrice Prop
        @prop()
        totalExportPrice : number;
//#endregion totalExportPrice Prop


//#region allocatedShippingCost Prop
        @prop()
        allocatedShippingCost : number;
//#endregion allocatedShippingCost Prop


//#region allocatedShippingCostperCarat Prop
        @prop()
        allocatedShippingCostperCarat : number;
//#endregion allocatedShippingCostperCarat Prop


//#region profitInUSD Prop
        @prop()
        profitInUSD : number;
//#endregion profitInUSD Prop


//#region profitperCaratInUSD Prop
        @prop()
        profitperCaratInUSD : number;
//#endregion profitperCaratInUSD Prop


//#region caratWeight Prop
        @prop()
        caratWeight : number;
//#endregion caratWeight Prop


//#region dimensionsInmm Prop
        @maxLength({value:50})
        dimensionsInmm : string;
//#endregion dimensionsInmm Prop


//#region consignmentDepartureDate Prop
        @prop()
        consignmentDepartureDate : Date;
//#endregion consignmentDepartureDate Prop


//#region soldQty Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        soldQty : number;
//#endregion soldQty Prop


//#region soldCarats Prop
        @prop()
        soldCarats : number;
//#endregion soldCarats Prop


//#region serialNumber Prop
        @maxLength({value:100})
        serialNumber : string;
//#endregion serialNumber Prop


//#region pricePerCaratInUSD Prop
        @prop()
        pricePerCaratInUSD : number;
//#endregion pricePerCaratInUSD Prop


//#region pricePerCaratInINR Prop
        @prop()
        pricePerCaratInINR : number;
//#endregion pricePerCaratInINR Prop


//#region exportPriceInUSD Prop
        @prop()
        exportPriceInUSD : number;
//#endregion exportPriceInUSD Prop


//#region exportPriceInINR Prop
        @prop()
        exportPriceInINR : number;
//#endregion exportPriceInINR Prop


//#region totalExportPriceInUSD Prop
        @prop()
        totalExportPriceInUSD : number;
//#endregion totalExportPriceInUSD Prop


//#region totalExportPriceInINR Prop
        @prop()
        totalExportPriceInINR : number;
//#endregion totalExportPriceInINR Prop


//#region allocatedShippingCostInUSD Prop
        @prop()
        allocatedShippingCostInUSD : number;
//#endregion allocatedShippingCostInUSD Prop


//#region allocatedShippingCostInINR Prop
        @prop()
        allocatedShippingCostInINR : number;
//#endregion allocatedShippingCostInINR Prop


//#region allocatedShippingCostperCaratInUSD Prop
        @prop()
        allocatedShippingCostperCaratInUSD : number;
//#endregion allocatedShippingCostperCaratInUSD Prop


//#region allocatedShippingCostperCaratInINR Prop
        @prop()
        allocatedShippingCostperCaratInINR : number;
//#endregion allocatedShippingCostperCaratInINR Prop


//#region profitInINR Prop
        @prop()
        profitInINR : number;
//#endregion profitInINR Prop


//#region profitperCaratInINR Prop
        @prop()
        profitperCaratInINR : number;
//#endregion profitperCaratInINR Prop


//#region profit Prop
        @prop()
        profit : number;
//#endregion profit Prop


//#region profitperCarat Prop
        @prop()
        profitperCarat : number;
//#endregion profitperCarat Prop


//#region serial Prop
        @prop()
        serial : number;
//#endregion serial Prop


//#region interSaleRelation Prop
        @prop()
        interSaleRelation : number;
//#endregion interSaleRelation Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop































}