import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const PROJECT_REDIRECT_SUB_CHILDGRID_LINK_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [
        {
          span: {
            childrens: [{
              text: {
                text: function (e) {
                  var shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  var projectName = new Date(this.projectStartDate).getDate() + " " + shortMonths[new Date(this.projectStartDate).getMonth()] + " - " + new Date(this.projectEndDate).getDate() + " " + shortMonths[new Date(this.projectStartDate).getMonth()] + " ";
                  return projectName;
                }
              }
            }]
          },
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onOneLink"
            },
            childrens: [{
              text: {
                text: function (e) { return this[e.name] }
              }
            }],
            attributes: {
              href: function (e) {
                if (this.projectId != null || this.projectId != undefined) {
                  return "/projects/" + this.projectId + "?activeTab=project-edit";
                }
              },
              "ng-reflect-router-link": function (e) {
                if (this.projectId != null || this.projectId != undefined) {
                  return "/projects," + this.projectId + "?activeTab=project-edit";
                }
              },
              onClick: "return false;"
            }
          }
        },
        {
          div:{
            childrens:[{
              span:{
                class: [function (e) {
                  if (this.parentId == 0) {
                    return "pagination-bold mr-1".split(" ");
                  } else {
                    return "font-weight-bold text-muted mr-1".split(" ");
                  }
                }],
                childrens: [
                  {  
                  i:{
                    class: [function (e) {
                      if (this.parentId == 0) {
                        return "fa fa-list-alt mr-1".split(" ");
                      } else {
                        return "fa fa-sitemap mr-1".split(" ");
                      }
                    }]
                  },
                  text: {
                    text: function (e) {
                      if(this.parentId == 0) {
                        return getLocalizedValue("MainProject_label")
                      }
                      else{
                        return getLocalizedValue("SubProject_label")
                      }
                    }
                  }
                }]
              }
            }]
          }
        }
      ],
      style: {
        "padding-left" : "30%",
        "width" : "100%"
      }
    },
  };