import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_RISK_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if(this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId > 0){
                return "/gemfield-risk-menu/" + this.riskMenuDetailId;
              }
            },
            "ng-reflect-router-link": function (e) {
              if(this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId > 0){
                return "/gemfield-risk-menu/" + this.riskMenuDetailId;
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};
