import {vTicketStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vTicketStatusLookUp extends vTicketStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}