import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GrievanceTypeBase {

//#region grievanceTypeId Prop
    @prop()
    grievanceTypeId : number;
//#endregion grievanceTypeId Prop

//#region grievanceTypeName Prop
    @required({message:"Grievance Type is required"})
    @notEmpty()
    @maxLength({value:500})
    @trim()
    grievanceTypeName : string;
//#endregion grievanceTypeName Prop

//#region tierId Prop
    @required({message:"Tiers are required"})
    @maxLength({value:50})
    tierId : string;
//#endregion tierId Prop

//#region active Prop
    @prop({defaultValue:false})
    active : boolean;
//#endregion active Prop

//#region deleted Prop
    @prop({defaultValue:false})
    deleted : boolean;
//#endregion deleted Prop

//#region createdOn Prop
    @required()
    createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
    @prop()
    createdBy : number;
//#endregion createdBy Prop

//#region modifiedOn Prop
    @prop()
    modifiedOn : Date;
//#endregion modifiedOn Prop


//#region modifiedBy Prop
    @prop()
    ModifiedBy : number;
//#endregion modifiedBy Prop


}