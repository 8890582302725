import { PaginationEnum } from '../enums/pagination.enums';
import { maxLength, numeric, prop, trim } from '@rxweb/reactive-form-validators';

export class SupplierSearchFilterViewModel {
  constructor() {
    //this.industryTypeId = 0;
    //this.salesChannelId = 0;
    //this.roleId = 0;
    //this.supplierRoleId = 0;
    //this.introducesBy = 0;
    this.currentFabergeStockist = 2;
    this.ddKYCStatus = 0;
    this.isApproval = false;
    this.isSensitive = false;
    this.pageIndex = PaginationEnum.PageIndex
    this.rowCount = PaginationEnum.RowCount;
    //this.countryId = 0;
    this.sortOrder = "true";
    this.orderByColumn = "company";
    this.searchString = "";
  }

  @prop()
  @trim()
  name: string;

  @prop()
  @trim()
  email: string

  @prop()
  salesChannelId: number;

  @prop()
  roleId: number;

  @prop()
  supplierRoleId: number;

  @prop()
  countryId: number;

  @prop()
  introducesBy: number;

  @prop()
  industryTypeId: number;

  @prop()
  workPhoneNumber: string;

  // @prop()
  // @trim()
  // hasPurchasedFrom: string;

  @prop()
  currentFabergeStockist: number;

  @prop()
  rowCount: number;

  @prop()
  pageIndex: number;

  @prop()
  @trim()
  sortOrder: string;

  @prop()
  @trim()
  orderByColumn: string;

  @prop()
  searchString: string;

  @prop()
  hasPurchasedFrom: number;

  @prop()
  ddKYCStatus: number;

  @prop()
  isApproval: boolean;

  @prop()
  isSensitive: boolean;

  @prop()
  industryTypeIds: string="";
  // #295644 / 29 - 07 - 2024 / add Search by supplierId
  @prop()
  @numeric()
  @maxLength({value:9})
  supplierId: number;
}
