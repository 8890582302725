import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vWarehousLookUpBase {

//#region warehouseId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'warehouseId', keyColumn: true})
        warehouseId : number;
//#endregion warehouseId Prop


//#region warehouseName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'warehouseName', keyColumn: false})
        warehouseName : string;
//#endregion warehouseName Prop

}