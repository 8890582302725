import { Component, OnInit, OnDestroy, ElementRef, Input } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { LeaveType } from '@app/models';
import { AbstractLeaveTypeCorporateMapping } from '../domain/abstract-leave-type-corporate-mapping';
import { Router } from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { LeaveTypeCorporateMappingEditComponent } from '../edit/leave-type-corporate-mapping-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { LeaveTypeCorporateViewModelBase } from "src/app/models/database-models/leave-type-corporate-view-model-base";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { AppGrid } from "src/app/domain/app-grid";
import { LeaveTypeCorporateMapping } from "src/app/models/extended-models/leave-type-corporate-mapping";
import { LeaveTypeCorporateMappingBase } from "src/app/models/database-models/leave-type-corporate-mapping-base";
import { PlatformLocation } from "@angular/common";
@multilingual("LeaveTypeCorporateMappingAddComponent")

@Component({
    selector: "app-leave-type-corporate-mapping-add",
    templateUrl: './leave-type-corporate-mapping-add.component.html'
})
export class LeaveTypeCorporateMappingAddComponent extends AbstractLeaveTypeCorporateMapping implements OnInit, OnDestroy {
    leaveTypeCorporateMapping: LeaveTypeCorporateMapping;
    leaveTypeCorporateMappingArray: LeaveTypeCorporateViewModelBase[];
    leaveType: LeaveType;
    subscription: any;
    leaveTypeCorporateGrid: AppGrid;
    leaveTypeEdit: any = LeaveTypeCorporateMappingEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    titleClose: string;
    rowCount: number = PaginationEnum.RowCount;
    pageIndex: number = PaginationEnum.PageIndex;
    sortOrder: string = "true";
    orderByColumn: string = "company";
    totalRecords: number = 0;
    isFilter: boolean = false;
    isSearch: boolean = false;
    gridSearchString: string = "";
    leaveTypeCorporateMappingList: LeaveTypeCorporateViewModelBase[];
    cloneArray: LeaveTypeCorporateViewModelBase[];
    headerBtnChk: boolean = false;
    json: any;
    titleSearch: string;

    @Input() leaveTypeId: number;
    @Input() hide: Function;

    constructor(private router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.HolidayLeaveTypes;
        this.leaveTypeCorporateMappingArray = new Array<LeaveTypeCorporateViewModelBase>();
        this.cloneArray = new Array<LeaveTypeCorporateViewModelBase>();
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.cloneArray = new Array<LeaveTypeCorporateViewModelBase>();
        this.leaveTypeCorporateMapping = new LeaveTypeCorporateMapping();
        this.leaveTypeCorporateMapping.leaveTypeId = this.leaveTypeId;
        this.leaveTypeCorporateMapping.leaveTypeCorporateMappingBase = new Array<LeaveTypeCorporateMappingBase>();
        this.leaveTypeCorporateMappingFormGroup = this.formBuilder.formGroup(this.leaveTypeCorporateMapping) as IFormGroup<LeaveTypeCorporateMapping>;
        this.titleClose = getLocalizedValue("Title_Close");
        this.title.setTitle("Leave Type - G-Trac Admin");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    bindGrid() {
        this.json = {
            rowCount: this.rowCount,
            pageIndex: this.pageIndex,
            sortOrder: this.sortOrder,
            orderBy: this.orderByColumn,
            leaveTypeId: this.leaveTypeId,
            searchString: this.gridSearchString
        }
        this.spin = true;
        this.isShowGrid = true;
        this.subscription = this.post({ body: { "query": (JSON.stringify(this.json)) }, path: "api/SearchCommon/GetCorporatesForLeaveType" }).subscribe((t: any) => {
            this.spin = false;
            this.leaveTypeCorporateMappingList = t;
            this.isSearch = true;
            if (this.leaveTypeCorporateGrid != null) {
                this.isFilter = true;
            }
            if (this.leaveTypeCorporateMappingList.length > 0) {
                this.totalRecords = this.leaveTypeCorporateMappingList[0].totalCount;
                this.isNoRecordFound = false;
                this.isShowGrid = true;
            } else {
                this.isShowGrid = false;
                this.isNoRecordFound = true;
            }

            this.leaveTypeCorporateMappingList.forEach(x => {
                if (this.cloneArray.findIndex(z => z.corporateId == x.corporateId) == -1) {

                    this.cloneArray.push(x);
                }
                else {
                    this.cloneArray.forEach(z => {
                        if (z.corporateId == x.corporateId) {
                            x.isChecked = z.isChecked
                        }
                    })
                }
            })
            if (!this.isFilter) {
                this.leaveTypeCorporateGrid = new AppGrid(this.leaveTypeCorporateMappingList, LeaveTypeCorporateViewModelBase,
                    { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } }
                );
                this.leaveTypeCorporateGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
                // this.leaveTypeCorporateGrid.gridColumns.forEach(x => {
                //     if (this.leaveTypeCorporateGrid.authorize({ accessLevel: RolePermissionEnums.FabergeNews, action: "put" }) && this.leaveTypeCorporateGrid.authorize({ accessLevel: RolePermissionEnums.FabergeNews, action: "delete" })) {
                //         if (x.name == "action") {
                //             x.visible = true;
                //         }
                //     }
                //     else {
                //         if (x.name == "action") {
                //             x.visible = false;
                //         }
                //         if (x.name == "editAction") {
                //             x.visible = this.leaveTypeCorporateGrid.authorize({ accessLevel: RolePermissionEnums.FabergeNews, action: "put" });
                //         }
                //         if (x.name == "deleteAction") {
                //             x.visible = this.leaveTypeCorporateGrid.authorize({ accessLevel: RolePermissionEnums.FabergeNews, action: "delete" });
                //         }
                //     }
                // })
            }
            else {
                this.leaveTypeCorporateGrid.storeProcedure.length = this.totalRecords;
                setTimeout(() => {
                    this.leaveTypeCorporateGrid.updateSource([]);
                    this.leaveTypeCorporateGrid.updateSource(this.leaveTypeCorporateMappingList);
                }, 0);

            }
            this.leaveTypeCorporateGrid.maxPerPage = this.rowCount;
            this.leaveTypeCorporateGrid.componentId = this.componentName;
            this.updateGroupCompaniesArray();
            this.leaveTypeCorporateGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(' ');
            this.leaveTypeCorporateGrid.designClass.headerClass = "table-header-sticky".split(" ");
        })
    }

    saveLeaveTypeCorporateMappings() {
        if (this.leaveTypeCorporateMappingArray.length > 0) {
            this.spin = true;
            this.leaveTypeCorporateMapping.leaveTypeArray = this.leaveTypeCorporateMappingArray;

            this.post({ body: this.leaveTypeCorporateMapping }).subscribe(data => {
                this.spin = false;
                var existsAlert = getLocalizedValue("Data_Added");
                if (existsAlert) {
                    //this.toastr.success("Data Added Successfully")
                    this.toastr.success(existsAlert);
                }
                this.hide();
            })
        }
    }

    onPageSorting(x, y, z) {
        this.sortOrder = y;
        this.orderByColumn = x;
        this.isFilter = true;
        this.leaveTypeCorporateGrid.storeProcedure.nextPage = z;
        //this.cloneArray = [];
        this.bindGrid();
    }

    onPageChanging(x, y, z) {
        this.leaveTypeCorporateGrid.storeProcedure.nextPage = x;
        // let fromIndex: number = this.rowCount;
        // let toIndex: number = this.leaveTypeCorporateGrid.maxPerPage;
        // if (this.pageIndex < x) {
        //     this.cloneArray = [];
        // }
        // if (this.leaveTypeCorporateGrid.maxPerPage < this.rowCount) {
        //     for (let i = fromIndex; i > toIndex; i--) {
        //         this.cloneArray.splice(i - 1, 1);
        //     }
        // }
        this.pageIndex = x;
        this.rowCount = this.leaveTypeCorporateGrid.maxPerPage;
        this.bindGrid();
    }

    onCheckboxSelect(t, x) {
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<LeaveTypeCorporateViewModelBase>();
        oldCloneArray.forEach(data => {
            let newObj = new LeaveTypeCorporateViewModelBase();
            newObj.isChecked = data.corporateId == t.corporateId ? x.target.checked : data.isChecked;
            newObj.company = data.company;
            newObj.email = data.email;
            newObj.fullName = data.fullName;
            newObj.totalCount = data.totalCount;
            newObj.corporateId = data.corporateId;
            this.cloneArray.push(newObj);
        });
        this.updateGroupCompaniesArray();
        // this.leaveTypeCorporateGrid.updateSource([]);
        // this.leaveTypeCorporateGrid.updateSource(this.cloneArray);
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<LeaveTypeCorporateViewModelBase>();
        this.leaveTypeCorporateMappingList.forEach(data => {
            let newObj = new LeaveTypeCorporateViewModelBase();
            newObj.isChecked = this.headerBtnChk;
            newObj.company = data.company;
            newObj.email = data.email;
            newObj.fullName = data.fullName;
            newObj.totalCount = data.totalCount;
            newObj.corporateId = data.corporateId;
            this.cloneArray.push(newObj);
        })
        this.updateGroupCompaniesArray();
        this.leaveTypeCorporateGrid.updateSource([]);
        this.leaveTypeCorporateGrid.updateSource(this.cloneArray);
    }

    updateGroupCompaniesArray() {
        this.cloneArray.forEach(x => {
            if (x.isChecked) {
                let leaveTypeCorporateMapping = new LeaveTypeCorporateViewModelBase();
                leaveTypeCorporateMapping.corporateId = x.corporateId;
                leaveTypeCorporateMapping.company = x.company;
                leaveTypeCorporateMapping.email = x.email;

                if (this.leaveTypeCorporateMappingArray.findIndex(y => y.corporateId == x.corporateId) == -1) {
                    this.leaveTypeCorporateMappingArray.push(leaveTypeCorporateMapping);
                }
            }
            else {
                if (this.leaveTypeCorporateMappingArray.findIndex(y => y.corporateId == x.corporateId) != -1) {
                    this.leaveTypeCorporateMappingArray.splice(this.leaveTypeCorporateMappingArray.findIndex(z => z.corporateId == x.corporateId), 1);
                }
            }
        });
    }

    search(value) {
        this.spin = false;
        this.isFilter = true;
        //this.json['searchString'] = value.target.value.replace(/^\s+|\s+$/gm, '');
        this.totalRecords = 0;
        this.leaveTypeCorporateGrid.storeProcedure.nextPage = 1;
        this.gridSearchString = value.target.value.replace(/^\s+|\s+$/gm, '');
        //this.json['pageIndex'] = 1;
        this.pageIndex = 1;
        this.bindGrid();
        this.spin = true;
    }


    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "LeaveTypeCorporateMappingAddComponent";
      }
}
