import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBannedIpAddressBase {

        //#region bannedIpAddressID Prop
        @gridColumn({ style: { width: "12%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex: 0, allowSorting: true, headerKey: 'BannedIpAddressListComponent_AddressID_gh', keyColumn: true })
        bannedIpAddressID: number;
        //#endregion bannedIpAddressID Prop


        //#region address Prop
        @gridColumn({ isAscending: true, visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'BannedIpAddressListComponent_Address_gh', keyColumn: false })
        address: string;
        //#endregion address Prop


        //#region comment Prop
        @gridColumn({ visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 2, allowSorting: true, headerKey: 'BannedIpAddressListComponent_Comment_gh', keyColumn: false })
        comment: string;
        //#endregion comment Prop


        //#region createdOn Prop
        @gridColumn({ configuredTemplate: { templateName: "dateDispay" }, visible: false, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 3, allowSorting: true, headerKey: 'BannedIpAddressListComponent_CreatedOn_gh', keyColumn: false })
        createdOn: any;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @gridColumn({ configuredTemplate: { templateName: "dateDispay" }, visible: false, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 4, allowSorting: true, headerKey: 'BannedIpAddressListComponent_UpdatedOn_gh', keyColumn: false })
        updatedOn: any;
        //#endregion updatedOn Prop

        //#region createdOn Prop
        @gridColumn({ visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 3, allowSorting: false, headerKey: 'BannedIpAddressListComponent_CreatedOn_gh', keyColumn: false })
        stCreatedOn: string;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @gridColumn({ visible: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], columnIndex: 4, allowSorting: false, headerKey: 'BannedIpAddressListComponent_UpdatedOn_gh', keyColumn: false })
        stUpdatedOn: string;
        //#endregion updatedOn Prop

}
