import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    visible:false,
    name:'headerCheckbox',
    allowSorting: false,
    style: { width: "1%","text-align": "initial"},
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 2
  })
export class RelatedProductsAddViewModel{ 
    @gridColumn({name:'isChecked',style: { width: "4%", "text-align": "initial" },visible: true,configuredTemplate: { templateName: 'bindCheckBox' },allowSorting:false, columnIndex: 1, headerTitle: '', keyColumn: false })
    isChecked:boolean

    @gridColumn({name:'isChecked1',style: { width: "1%", "text-align": "initial" },visible: false,configuredTemplate: { templateName: 'bindCheckBox' },allowSorting:false, columnIndex: 0, /*headerTitle: 'Action'*/ headerKey:'RelatedProductAddEditComponent_Action_gh', keyColumn: false })
    isChecked1:boolean

    @gridColumn({parameter:false,style: { width: "10%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: false, columnIndex: 4, allowSorting: true, headerKey: 'RelatedProductAddEditComponent_ProductVariantId_gh', keyColumn: false,name:'productId' })
    productId:number;
    @gridColumn({parameter:false,style: { width: "15%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 2, allowSorting: true, headerKey: 'RelatedProductAddEditComponent_ProductName_gh', keyColumn: false,name:'productName' })
    productName:string;
   

    @gridColumn({ name:'published',parameter: false, style: { width: "4%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'RelatedProductAddEditComponent_Published_gh', keyColumn: false, configuredTemplate: { templateName: "published" } })
    published:boolean;

    @gridColumn({
        template:{
          div:{
            attributes:{
              innerHTML:function(x,y){
               return this.viewButton;
              }
            },
          }
      },
    parameter:false,style: { width: "4%", "text-align": "initial","word-break":"break-all"}, class: ["text-left"],  visible: true, columnIndex: 6, allowSorting: false, /*headerTitle: 'View'*/headerKey:'RelatedProductAddEditComponent_View_gh', name:'ViewButton',keyColumn: false })
    viewButton:string;

    @gridColumn({
        template:{
          div:{
            attributes:{
              innerHTML:function(x,y){
               return this.imageUrl;
              }
            },
          }
      },
    parameter:false,style: { width: "7%", "text-align": "centre"}, class: ["text-centre"],  visible: true, columnIndex: 3, allowSorting: false, headerKey: 'RelatedProductAddEditComponent_ImageUrl_gh', name:'imageurl',keyColumn: false })
    imageUrl:string;


    pictureId:number;
    relatedProductId:number;
    relatedProductVariantID:number;
    totalCount:number;    
    errorMessage:string;
}