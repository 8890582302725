import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRingAndEggSizeTypeLookUpBase {

//#region ringAndEggSizeTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'ringAndEggSizeTypeId', keyColumn: true})
        ringAndEggSizeTypeId : number;
//#endregion ringAndEggSizeTypeId Prop


//#region ringAndEggSizeTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'ringAndEggSizeTypeName', keyColumn: false})
        ringAndEggSizeTypeName : string;
//#endregion ringAndEggSizeTypeName Prop

}