import {vGrievanceTierBase,} from '@app/database-models'
//Generated Imports
export class vGrievanceTier extends vGrievanceTierBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}