import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDutyStatusBase {

//#region dutyStatusId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'DutyStatusListComponent_Id_gh', keyColumn: true})
        dutyStatusId : number;
//#endregion dutyStatusId Prop


//#region dutyStatusName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'DutyStatusListComponent_Name_gh', keyColumn: false})
        dutyStatusName : string;
//#endregion dutyStatusName Prop

        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'DutyStatusListComponent_DutyStatusExpiryDays_gh', keyColumn: false})
        dutyStatusExpiryDays : string;
}