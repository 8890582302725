export const TAX_PROVIDER = [
    {
        id: 3,
        name: 'No tax',
        uiType: 1,
        isUIActive:false
    },
    {
        id: 6,
        name: 'Tax By Country & State',
        uiType: 2,
        isUIActive: true
    },
    {
        id: 7,
        name: 'Strike Iron Basic (US and CA Only)',
        uiType: 3,
        isUIActive: true
    },
    {
        id: 8,
        name: 'Fixed Rate Tax',
        uiType: 4,
        isUIActive: true
    }
]
