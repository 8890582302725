import {TaxProviderBase,} from '@app/database-models'
//Generated Imports
export class TaxProvider extends TaxProviderBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}