import {GemstoneArrangementsGemFieldBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
export class GemstoneArrangementsGemField extends GemstoneArrangementsGemFieldBase 
{




//#region Generated Reference Properties
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop

//#endregion Generated Reference Properties






































}