import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SupplierRoleBase {

        //#region supplierRoleId Prop
        @prop()
        supplierRoleId: number;
        //#endregion supplierRoleId Prop


        //#region supplierId Prop
        @prop({ defaultValue: 0 })
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @prop({ defaultValue: 0 })
        supplierId: number;
        //#endregion supplierId Prop


        //#region roleId Prop
        @prop({ defaultValue: 0 })
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        roleId: number;
        //#endregion roleId Prop


        //#region active Prop
        @prop({ defaultValue: false })
        active: boolean;
        //#endregion active Prop

}