import {vGemStoneCutTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemStoneCutTypeLookUp extends vGemStoneCutTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}