import {ProjectBase, RiskProjectMappingBase,} from '@app/database-models'
import { propArray, prop } from '@rxweb/reactive-form-validators';
import { RiskProjectMappingViewModel } from 'src/app/view-model/risk-project-mapping-view-model';
//Generated Imports
export class RiskProjectMapping extends RiskProjectMappingBase
{
    //#region Generated Reference Properties
    riskMenuDetailIds: RiskProjectMapping[];

    //#region Generated Reference Properties
    //#region project Prop

    project : ProjectBase;

    @propArray(RiskProjectMappingViewModel)
    riskProjectInput:RiskProjectMappingViewModel[];
    //#endregion project Prop
    //#endregion Generated Reference Properties

    @prop({defaultValue : 0 })
    hseTab : boolean
    headerBtnChk: boolean;
    query: string;
}