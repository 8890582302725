import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { EventInvitation,  Event } from '@app/models';
import { CoreComponent,  } from '@rxweb/angular-router';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';

@http({
    path:'api/Events'
})
export class AbstractEventSendEInvitation extends CoreComponent {
    eventEInvitationFormGroup: IFormGroup<Event>;
    spin: boolean = false;
    showComponent: boolean = false;
    toastr: BaseToastr;

    constructor() {
        super();
        this.toastr = new BaseToastr();
    }
    

}
