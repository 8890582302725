import { prop, propObject, propArray, required, maxLength, range, notEmpty, numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CategoryBase {

    //#region categoryId Prop
    @prop()
    categoryId: number;
    //#endregion categoryId Prop


    //#region name Prop
    @required()
    @maxLength({ value: 100 })
    @trim()
    @notEmpty()
    name: string;
    //#endregion name Prop


    //#region abbr Prop
    @required()
    @maxLength({ value: 5 })
    @notEmpty()
    @trim()
    abbr: string;
    //#endregion abbr Prop


    //#region description Prop
    @maxLength({ value: 4000 })
    @trim()
    description: string;
    //#endregion description Prop


    //#region templateId Prop
    @prop()
    templateId: number;
    //#endregion templateId Prop


    //#region metaKeywords Prop
    @maxLength({ value: 400 })
    @trim()
    metaKeywords: string;
    //#endregion metaKeywords Prop


    //#region metaDescription Prop
    @maxLength({ value: 4000 })
    @trim()
    metaDescription: string;
    //#endregion metaDescription Prop


    //#region metaTitle Prop
    @maxLength({ value: 400 })
    @trim()
    metaTitle: string;
    //#endregion metaTitle Prop


    //#region sEName Prop
    @prop()
    @maxLength({ value: 400 })
    @trim()
    seName: string;
    //#endregion sEName Prop


    //#region parentCategoryId Prop
    //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
   // @required()
    @prop()
    parentCategoryId: number;
    //#endregion parentCategoryId Prop


    //#region pictureId Prop
    @prop()
    pictureId: number;
    //#endregion pictureId Prop


    //#region pageSize Prop
    @prop()
    pageSize: number;
    //#endregion pageSize Prop


    //#region priceRanges Prop
    @maxLength({ value: 400 })
    @trim()
    priceRanges: string;
    //#endregion priceRanges Prop


    //#region showOnHomePage Prop
    @prop({ defaultValue: false })
    showOnHomePage: boolean;
    //#endregion showOnHomePage Prop


    //#region published Prop
    @prop({ defaultValue: false })
    published: boolean;
    //#endregion published Prop


    //#region displayOrder Prop

    @required()
    @notEmpty()
    @numeric()
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    displayOrder: number;
    //#endregion displayOrder Prop


    //#region categoryTypeId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    categoryTypeId: number;
    //#endregion categoryTypeId Prop


    //#region pageTheme Prop
    @maxLength({ value: 100 })
    @trim()
    pageTheme: string;
    //#endregion pageTheme Prop


    //#region pictureId2 Prop
    @prop()
    pictureId2: number;
    //#endregion pictureId2 Prop


    //#region display1 Prop

    @prop({ defaultValue: false })
    display1: boolean;
    //#endregion display1 Prop


    //#region display2 Prop

    @prop({ defaultValue: false })
    display2: boolean;
    //#endregion display2 Prop


    //#region titlePictureId Prop
    @prop()
    titlePictureId: number;
    //#endregion titlePictureId Prop


    //#region pictureId3 Prop
    @prop()
    pictureId3: number;
    //#endregion pictureId3 Prop


    //#region pictureId4 Prop
    @prop()
    pictureId4: number;
    //#endregion pictureId4 Prop


    //#region pictureId5 Prop
    @prop()
    pictureId5: number;
    //#endregion pictureId5 Prop


    //#region frontEndCategoryPictureId Prop
    @prop()
    frontEndCategoryPictureId: number;
    //#endregion frontEndCategoryPictureId Prop


    //#region frontEndCategoryTitle Prop
    @maxLength({ value: 2000 })
    @trim()
    frontEndCategoryTitle: string;
    //#endregion frontEndCategoryTitle Prop


    //#region frontEndCategoryDescription Prop
    @maxLength({ value: 2000 })
    @trim()
    frontEndCategoryDescription: string;
    //#endregion frontEndCategoryDescription Prop


    //#region frontEndCategoryButtonText Prop
    @maxLength({ value: 2000 })
    @trim()
    frontEndCategoryButtonText: string;
    //#endregion frontEndCategoryButtonText Prop


    //#region frontEndCategoryButtonLink Prop
    @maxLength({ value: 2000 })
    @trim()
    frontEndCategoryButtonLink: string;
    //#endregion frontEndCategoryButtonLink Prop


    //#region frontEndCategoryTopic Prop
    @maxLength({ value: 2000 })
    @trim()
    frontEndCategoryTopic: string;
    //#endregion frontEndCategoryTopic Prop


    //#region titlePictureId_ImageAlt Prop
    @maxLength({ value: 500 })
    @trim()
    titlePictureId_ImageAlt: string;
    //#endregion titlePictureId_ImageAlt Prop


    //#region pictureId_ImageAlt Prop
    @maxLength({ value: 500 })
    @trim()
    pictureId_ImageAlt: string;
    //#endregion pictureId_ImageAlt Prop


    //#region pictureId2_ImageAlt Prop
    @maxLength({ value: 500 })
    @trim()
    pictureId2_ImageAlt: string;
    //#endregion pictureId2_ImageAlt Prop


    //#region pictureId3_ImageAlt Prop
    @maxLength({ value: 500 })
    @trim()
    pictureId3_ImageAlt: string;
    //#endregion pictureId3_ImageAlt Prop


    //#region pictureId4_ImageAlt Prop
    @maxLength({ value: 500 })
    @trim()
    pictureId4_ImageAlt: string;
    //#endregion pictureId4_ImageAlt Prop


    //#region pictureId5_ImageAlt Prop
    @maxLength({ value: 500 })
    @trim()
    pictureId5_ImageAlt: string;
    //#endregion pictureId5_ImageAlt Prop


    //#region showOnFabergeHomePage Prop

    @prop({ defaultValue: false })
    showOnFabergeHomePage: boolean;
    //#endregion showOnFabergeHomePage Prop


    //#region statusId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    statusId: number;
    //#endregion statusId Prop


    //#region languageId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    languageId: number;
    //#endregion languageId Prop


    //#region parentLanguageCategoryId Prop
    @prop()
    parentLanguageCategoryId: number;
    //#endregion parentLanguageCategoryId Prop

    //#region mobileDescription Prop
    @prop()
    @maxLength({ value: 400 })
    mobileDescription:string;
    //#endregion mobileDescription Prop

     //#region frontBannerContentColour Prop
    @prop({defaultValue:0})
    frontBannerContentColour:number;
     //#region frontBannerContentColour Prop

    //#region showTitlePictureId Prop
    @prop({ defaultValue: true })
    showTitlePictureId: boolean;
    //#region showTitlePictureId Prop

    //#region showPictureId5 Prop
    @prop({ defaultValue: false })
    showPictureId4: boolean;
    //#region showPictureId5 Prop



}
