import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserLookUpBase {

//#region UserId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: true})
        userId : number;
//#endregion UserId Prop


//#region FullName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'fullName', keyColumn: false})
        fullName : string;
//#endregion FullName Prop

//#region RoleTypeId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'roleTypeId', keyColumn: false})
        roleTypeId : number;
//#endregion RoleTypeId Prop

}