<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="modal-content" >
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="ProjectRiskAddComponent_Add_Risk_t"></h4>

      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body "
      (keyup.enter)="search()">

      <div class="row" *ngIf="showComponent" [rxLocalisationInit]="componentName" [formGroup]="projectRiskSearchFormGroup">
        <!-- Hidden as per client requirement -->
        <!-- <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"   rxText="ProjectRiskAddComponent_Risk_ID_t"></label>
            <div class="col-md-12">
              <input type="number" class="form-control" min="0" tabindex="1" formControlName="riskId" rxPlaceholder="ProjectRiskAddComponent_Risk_ID_p" rxFocus
                autocomplete="off">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"  rxText="ProjectRiskAddComponent_Risk_Name_t"></label>
            <div class="col-md-12">
              <input type="text" class="form-control" tabindex="3" formControlName="specificRiskName" rxPlaceholder="ProjectRiskAddComponent_Risk_Name_p"
                 autocomplete="off">

            </div>
          </div>
        </div> -->
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"  rxText="ProjectRiskAddComponent_Risk_Type_t"></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectRiskSearchLookup.riskCategoryLookUp" #rxSelect mainClass="form-control"
                [controlTabIndex]="3" formControlName="categoryId"
                (changed)="onRiskCategoryChange($event.item.key)"
                [keyValueProps]="['riskCategoryName','riskCategoryId']" [selectPlaceholder]="'Select Strategic Risk Category'">
              </rx-select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"  rxText="ProjectRiskAddComponent_Specific_Risk_t"></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
                <rx-select [(source)]="projectRiskSearchLookup.specificRiskLookUp" #rxSelect mainClass="form-control"
                    [controlTabIndex]="6" formControlName="specificRiskId"
                    [selectPlaceholder]="'Select Specific Risk'"
                    [keyValueProps]="['specificRiskName','specificRiskId']"></rx-select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"  rxText="ProjectRiskAddComponent_Gemfield_t"></label>
            <div class="col-md-12" [rxSelectExtended]="_this">
                <rx-select [(source)]="projectRiskSearchLookup.gemfieldGroupCompanyLookUp" #rxSelect mainClass="form-control"
                    [controlTabIndex]="7" formControlName="gemfieldGroupCompany"
                    [selectPlaceholder]="'Select Gemfield Group Company'"
                    [keyValueProps]="['company','supplierId']"></rx-select>
            </div>
          </div>
        </div>
      </div>


       <div class="hr-line-dashed border-top w-100"></div>
      <div class="row mx-0">
        <div class="form-group ml-auto mb-0">
          <button (click)="search()" class="btn btn-success" rxText="Btn_Search_t"></button>
        </div>
      </div>
     <div class="table-responsive mt-3"  *ngIf="showGrid">
        <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
          <div class="table-responsive" *ngIf="isShowGrid">
            <div class="dataTables_wrapper pb-0 dt-bootstrap4">
              <rx-grid [design]="ProjectRiskListGrid"></rx-grid>
            </div>

          </div>
          <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
            <h4>{{Error}}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf = "isShowGrid" [rxLocalisationInit]="componentName">

      <button (click)="save(false)" class="btn btn-success" [disabled] = "!showSaveButton" rxText="Btn_Save_t">
      </button>

      <button (click)="save(true)" class="btn btn-success" [disabled] = "!showSaveButton" rxText="Btn_Save_Continue_Edit_t">
      </button>

      <button (click)="hide()" class="btn btn-outline-primary " rxText="Btn_Close_t"></button>

    </div>
  </div>
</div>
