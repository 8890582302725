import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vShoppingCartTypeLookUpBase {

//#region shoppingCartTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'shoppingCartTypeId', keyColumn: true})
        shoppingCartTypeId : number;
//#endregion shoppingCartTypeId Prop


//#region shoppingCartTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'shoppingCartTypeName', keyColumn: false})
        shoppingCartTypeName : string;
//#endregion shoppingCartTypeName Prop

}