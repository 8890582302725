import { prop, propObject, propArray, required, maxLength, range, trim, notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class NoteTypeBase {

        //#region typeId Prop
        @prop()
        typeId: number;
        //#endregion typeId Prop


        //#region typeName Prop
        @required()
        @maxLength({ value: 100 })
        @trim()
        @notEmpty()
        typeName: string;
        //#endregion typeName Prop


        //#region typeDescription Prop
        @maxLength({ value: 400 })
        @trim()
        typeDescription: string;
        //#endregion typeDescription Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop


        //#region isManual Prop
        @required()
        @prop({ defaultValue: true })
        isManual: boolean;
        //#endregion isManual Prop


        //#region active Prop
        @required()
        active: boolean;
        //#endregion active Prop

}