import { prop, required } from "@rxweb/reactive-form-validators"

export class AdditionalGroupCompanyUserMappingBase {

        //#region goalsCompanyCustomerMappingId Prop
        @prop()
        additionalGroupCompanyUserMappingId: number;
        //#endregion goalsCompanyCustomerMappingId Prop

        //#region companyId Prop
        @required({ message: "Please Select Gemfields Group Entity" })
        companyId: number;
        //#endregion companyId Prop

        //#region userId Prop
        // @required()
        @prop({ defaultValue: 0 })
        userId: number;
        //#endregion userId Prop

        //#region deleted Prop
        deleted: boolean;
        //#endregion deleted Prop

        //#region createdAt Prop
        createdAt?: Date;
        //#endregion createdAt Prop

        //#region DeletedAt Prop
        DeletedAt?: Date;
        //#endregion DeletedAt 

        //#region createdBy Prop
        createdBy?: number;
        //#endregion createdBy Prop

        //#region deletedBy Prop
        deletedBy?: number;
        //#endregion deletedBy Prop
}