import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductAttributeValueLocalizationBase {

//#region productVariantAttributeValueLocalizedId Prop
        @prop()
        productVariantAttributeValueLocalizedId : number;
//#endregion productVariantAttributeValueLocalizedId Prop


//#region productVariantAttributeValueId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantAttributeValueId : number;
//#endregion productVariantAttributeValueId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop





}