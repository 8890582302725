import { EventRateMappingBase } from '../database-models/event-rate-mapping-base';
import { prop } from '@rxweb/reactive-form-validators';

export class EventRateMapping extends EventRateMappingBase {
    eventRateMappingIds: EventRateMapping[];

    @prop()
    eventName:string;

    @prop()
    eventDate:string;

    @prop()
    eventEndDate:string;

    @prop()
    eventTypeName:number;
}