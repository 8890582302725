import {EventInvitationBase,EventBase,} from '@app/database-models'
//Generated Imports
export class EventInvitation extends EventInvitationBase 
{




//#region Generated Reference Properties
//#region event Prop
event : EventBase;
//#endregion event Prop

//#endregion Generated Reference Properties











}