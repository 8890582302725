import {ApplicationExceptionLogBase,} from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"

export class ApplicationExceptionLog extends ApplicationExceptionLogBase 
{

    @prop()
    logType:string;

    @prop()
    exception:string;

    @prop()
    createdDate:string;

    @prop()
    email:string;

//#region Generated Reference Properties

//#endregion Generated Reference Properties






}