<div [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="dataTables_filter d-flex flex-wrap align-items-center">
    <button
      class="btn btn-danger mb-3"
      (click)="AddRisk()"
      style="background-color: #23be9e; color: white; border: none"
      rxText="ProjectRiskListComponent_Add_Risk_t"
    ></button>
    <div class="text-right ml-auto" *ngIf="isShowSearch">
      <label class="mr-5 mb-3 mt-2">
        <input
          type="search"
          (keyup.enter)="search($event)"
          class="table_search form-control form-control-sm pr-4"
          placeholder=""
          [title]="titleSearch"
        />
      </label>
      <div class="ibox-tools" *ngIf="!isNoRecordFound">
        <a
          class="dropdown-toggle"
          (click)="showGridColumn()"
          [title]="titleGridColumnSetting"
        >
          <i class="fa fa-cog"></i>
        </a>
        <app-system-table-setting
          [componentId]="componentName"
          *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)"
          (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="gemfieldRiskProjectGrid.gridColumns"
          [isShowGridColumn]="isShowGridColumn"
          [moduleID]="moduleID"
          [selectedTab]="selectedTab"
        >
        </app-system-table-setting>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <div class="dataTables_wrapper pb-0 dt-bootstrap4">
      <div
        data-toggle="buttons"
        *ngIf="isLongTextExists"
        class="btn-group btn-group-gray btn-group-toggle mb-1 d-flex flex-wrap justify-content-end"
        [rxLocalisationInit]="componentName"
      >
        <ng-container *ngIf="!isShowAll" [rxLocalisationInit]="componentName">
          <button
            class="btn btn-success"
            (click)="showOrHideAll()"
            rxText="Btn_Show_All_t"
          ></button>
        </ng-container>
        <ng-container *ngIf="isShowAll" [rxLocalisationInit]="componentName">
          <button
            class="btn btn-success"
            (click)="showOrHideAll()"
            rxText="Btn_Hide_All_t"
          ></button>
        </ng-container>
      </div>
      <div class="table-responsive" *ngIf="isShowGrid">
        <rx-grid [design]="gemfieldRiskProjectGrid"></rx-grid>
      </div>
      <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>
</div>
