import {MeasureWeightBase,} from '@app/database-models'

//Generated Imports
import { prop } from '@rxweb/reactive-form-validators'
export class MeasureWeight extends MeasureWeightBase 
{




//#region Generated Reference Properties
  @prop()
  ratioText:string
//#endregion Generated Reference Properties












}