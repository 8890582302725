import { AdhocPagesBase, CollectionDesignBase, CollectionInspirationBase, } from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';
export class AdhocPages extends AdhocPagesBase implements FileDetailViewModel {

    constructor() {
        super();
        this.published = false;
        this.viewByPeople = false;
    }

    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    fileData3: string;
    @prop()
    fileExtention3: string;
    @prop()
    fileName3: string;
    @prop()
    fileData4: string;
    @prop()
    fileExtention4: string;
    @prop()
    fileName4: string;
    @prop()
    displayImage: boolean;
    @prop()
    displayImage2: boolean;
    @prop()
    displayImage3: boolean;
    @prop()
    displayImage4: boolean;
    @prop()
    displayImage5: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop({ defaultValue: false })
    IsRemovedImage: boolean;
    @prop({ defaultValue: false })
    IsRemovedImage2: boolean;
    @prop({ defaultValue: false })
    IsRemovedImage3: boolean;
    @prop({ defaultValue: false })
    IsRemovedImage4: boolean;
    @prop({ defaultValue: false })
    IsRemovedImage5: boolean;

    @prop()
    URL:string;

    @prop()
    filters: string;

    // //#region Generated Reference Properties
    // //#region collectionDesigns Prop
    // collectionDesigns: CollectionDesignBase[];
    // //#endregion collectionDesigns Prop
    // //#region collectionInspirations Prop
    // collectionInspirations: CollectionInspirationBase[];
    // //#endregion collectionInspirations Prop

    // //#endregion Generated Reference Properties

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;
    @prop()
    blobFileName3: string;
    @prop()
    blobPath3: string;
    @prop()
    size3: number;
    @prop()
    blobFileName4: string;
    @prop()
    blobPath4: string;
    @prop()
    size4: number;
    @prop()
    blobFileName5: string;
    @prop()
    blobPath5: string;
    @prop()
    size5: number;
   










}