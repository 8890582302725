import { prop, propObject, propArray, required, maxLength, range, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CompanyCustomerMappingBase {

        //#region companyCustomerMappingId Prop
        @prop()
        companyCustomerMappingId: number;
        //#endregion companyCustomerMappingId Prop


        //#region companyId Prop
        @prop()
        @required()
        //@range({minimumNumber:0,maximumNumber:2147483647})
        companyId: number;
        //#endregion companyId Prop


        //#region supplierId Prop
        @prop()
        supplierId: number;
        //#endregion supplierId Prop


        //#region userId Prop
        //@range({minimumNumber:0,maximumNumber:2147483647})
        @required()
        userId: number;
        //#endregion userId Prop


        //#region startDate Prop
        @prop()
        // @required()
        startDate: Date;
        //#endregion startDate Prop


        //#region endDate Prop
        @prop()
        endDate: Date;
        //#endregion endDate Prop


        //#region reportTo Prop
        @prop()
        reportTo: number;
        //#endregion reportTo Prop
      //#292627//08-07-2024//Payroll and patersongade model param added
        //#region payRollId Prop
        @prop()
        payRollId: number;
        //#endregion payRollId Prop

        //#region patersonGradeId Prop
        @prop()
        patersonGradeId: number;
        //#endregion patersonGradeId Prop

        //#region jobTitle Prop
        @prop()
        @maxLength({ value: 500 })
        @trim()
        jobTitle: string;
        //#endregion jobTitle Prop


        //#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId: number;
        //#endregion statusId Prop

        @prop({ defaultValue: false })
        isEmployment: boolean;
        //#region isDirector Prop

        @prop({ defaultValue: false })
        isDirector: boolean;
        //#endregion isDirector Prop

        //#region directorStartDate Prop
        @prop()
        // @required()
        directorStartDate: Date;
        //#endregion directorStartDate Prop


        //#region directorEndDate Prop
        @prop()
        directorEndDate: Date;
        //#endregion directorEndDate Prop


        //#region isPrimary Prop
        @prop({ defaultValue: false })
        isPrimary: boolean;
        //#endregion isPrimary Prop

        @prop({ defaultValue: false })
        isOwnership: boolean;

        //#region ownershipStartDate Prop
        @prop()
        // @required()
        ownershipStartDate: Date;
        //#endregion ownershipStartDate Prop


        //#region ownershipEndDate Prop
        @prop()
        ownershipEndDate: Date;
        //#endregion ownershipEndDate Prop

        @prop()
        ownershipPerc: number;
        @prop()
        expatId: any;
}
