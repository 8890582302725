import { gridColumn } from '@rxweb/grid'
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 13, headerTitle: "Action"
})
export class PeopleCorporateInvolvedModel {
    @gridColumn({ visible: false, columnIndex: 10, headerKey: 'ProjectCorporateMappingListComponent_ProjectId_gh', keyColumn: true })
    projectId: number
   
    @gridColumn({ visible: false, columnIndex: 11, headerKey: 'ProjectCorporateMappingListComponent_RoleId_gh', keyColumn: false })
    roleId: number
   
    @gridColumn({ visible: false, columnIndex: 12, headerKey: 'ProjectCorporateMappingListComponent_EventID_gh', keyColumn: false })
    eventID: number
   
    @gridColumn({ visible: false, columnIndex: 7, headerKey: 'ProjectCorporateMappingListComponent_ECstID_gh', keyColumn: false })
    eventCustomerID: number
   
    @gridColumn({ visible: false, columnIndex: 8, headerKey: 'ProjectCorporateMappingListComponent_PCrpID_gh', keyColumn: false })
    projectCorporateID: number
   
    @gridColumn({ visible: false, columnIndex: 9, headerKey: 'customerID', keyColumn: false })
    customerID: number
   
    @gridColumn({ style: { width: "1%", "min-width":"150px", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 4, allowSorting: true, headerKey: 'ProjectCorporateMappingListComponent_Department_gh', keyColumn: false })
    department: string
    
    @gridColumn({ style: { width: "1%", "min-width":"150px", "text-align": "initial" }, class: ["text-left"], visible: true,  parameter:false,columnIndex: 1, allowSorting: true, headerKey: 'ProjectCorporateMappingListComponent_Person_gh', configuredTemplate: { templateName: 'reDirectPeopleAndTooltip' }, keyColumn: false })
    person: string
 
    @gridColumn({
        // template: {
        //     div: {
        //         style: {
        //             "word-break": "break-all;",
        //         },
        //         attributes: {
        //             innerHTML: function (x, y) {
        //                 if (this.corporate == null) {
        //                     return "";
        //                 } else {
        //                     return this.corporate;
        //                 }
        //             }
        //         }
        //     }
        // }, 
        style: { width: "1%", "min-width":"150px", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false, keyColumn: false,columnIndex: 2, allowSorting: false, configuredTemplate: { templateName: 'corporateGroupLink' }, headerKey: 'ProjectCorporateMappingListComponent_Corporate_gh', name: 'corporate'
    })
    corporate: string
    companyDetails: string

    @gridColumn({ template: {
        div: {
            style: {
                "word-break": "break-all",
            },
            attributes: {
                innerHTML: function (x, y) {
                    return this.profileorDescription;
                }
            },
            childrens: [{
                a: {
                    isBind: (x) => {
                        if (x.profileorDescriptionCharCount > 100 && !x.isShowAll) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }, style: {
                        "display": "block"
                    },
                    childrens: [{
                        span: {
                            class: "more-btn mr-2".split(" "),
                            childrens: [{
                                text: {
                                    text: function (e) {
                                        return "Show More";
                                    }
                                }
                            }]
                        }
                    }],
                    event: {
                        click: "onMoreProfDesclick"
                    }
                },
            }]
        }
    }, style: { width: "1%", "min-width":"150px", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 3, allowSorting: true, headerKey: 'ProjectCorporateMappingListComp_EvtPrj_PrfDesc_gh', keyColumn: false ,name : 'profileorDescription'})
    profileorDescription: string
    profileorDescriptionCharCount:any
    

    @gridColumn({template:{
        div: {
            attributes: {
                innerHTML: function (x, y) {
                    return this.mostRecentPressMedia;
                }
            },
        }
    }, style: { width: "1%", "min-width":"150px", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 5, allowSorting: true, headerKey: 'ProjectCorporateMappingListComponent_PressMedia_gh', keyColumn: false })
    mostRecentPressMedia: string

    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.notes;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.noteCharCount > 100 && !x.isShowAll) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreNotelick"
                        }
                    },
                }]
            }
        }, style: { width: "1%", "min-width":"300px", "text-align": "initial" }, class: ["text-left"], visible: true, parameter:false,keyColumn:false,columnIndex: 6, allowSorting: false, headerKey: 'ProjectCorporateMappingListComponent_Notes_gh', name: 'notes'
    })
    notes: string
    noteCharCount:any;
    // @gridColumn({
    //     template: {
    //         div: {
    //             style: {
    //                 "word-break": "break-all",
    //             },
    //             attributes: {
    //                 innerHTML: function (x, y) {
    //                     return this.notes1;
    //                 }
    //             }
    //         }
    //     }, style: { width: "1%", "text-align": "initial" }, class: ["text-left"], visible: false, columnIndex: 5, allowSorting: false, headerKey: 'ProjectCorporateMappingListComponent_Notes_gh', name: 'notes1'
    // })
    // notes1: string

    @prop()
    totalCount: number;

    @prop()
    noteId: number;

    @prop()
    contactPermissionRequired: boolean;

    @prop()
    introducesBy: string;
}
