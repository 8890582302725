import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDocumentBase {

//#region DocumentId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: false, headerTitle: 'DocumentId', keyColumn: true})
        documentId: number;
//#endregion DocumentId Prop


//#region FileName Prop
        @gridColumn({isAscending:true,style: { width: "20%", "text-align": "center" }, class: ["text-center"],visible: true, columnIndex:1, allowSorting: false
        , headerTitle: 'CreatedOn', keyColumn: false})
        createdOn : string;
//#endregion FileName Prop


//#region  Prop
        @gridColumn({style: { width: "20%", "text-align": "center" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: false, headerTitle: 'CreatedBy', keyColumn: false})
        createdBy : string;
//#endregion  Prop

//#region  Prop
        @gridColumn({style: { width: "15%", "text-align": "center" }, class: ["text-left"],visible: true, columnIndex:3, allowSorting: false, headerTitle: 'FileName', keyColumn: false})
        fileName : string;
//#endregion  Prop

//#region  Prop
        @gridColumn({style: { width: "25%", "text-align": "center" }, class: ["text-left"],visible: true, columnIndex:4, allowSorting: false, headerTitle: 'NoteType', keyColumn: false})
        noteType : string;
//#endregion  Prop

//#region  Prop
        @gridColumn({style: { width: "25%", "text-align": "center" }, class: ["text-left"],visible: true, columnIndex:5, allowSorting: false, headerTitle: 'Subject', keyColumn: false})
        subject : string;
//#endregion  Prop
}