import { prop, numeric,range } from '@rxweb/reactive-form-validators';

export class  QueuedEmailFilterViewModel{
    @prop()
    startDate : Date;

    @prop()
    endDate : Date;

    @prop()
    @numeric()
    @range({minimumNumber:1,maximumNumber:2147483647})
    queuedEmailId : number;
    @prop({defaultValue:''})
    
    emailFrom : string;
    @prop({defaultValue:''})
    emailTo : string;
    
    @prop()
    subject: string;

    @prop()
    @numeric()
    sendTries : number;
    @prop({defaultValue:0})
    loadNotSentItemsOnly:boolean;

    @prop({defaultValue:25})
    rowCount:number;

    @prop({defaultValue:1})
    pageIndex:number;

    @prop({defaultValue:'true'})
    sortOrder:string;

    @prop({defaultValue:'queuedEmailId'})
   
    orderByColumn:string;

}
