import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vForumSearchTypeLookUpBase {

//#region forumSearchTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'forumSearchTypeId', keyColumn: true})
        forumSearchTypeId : number;
//#endregion forumSearchTypeId Prop


//#region forumSearchTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'forumSearchTypeName', keyColumn: false})
        forumSearchTypeName : string;
//#endregion forumSearchTypeName Prop

}