import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductAttributeBase {

//#region productAttributeId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'ProductAttributeListComponent_Id_gh', keyColumn: true})
        productAttributeId : number;
//#endregion productAttributeId Prop


//#region productAttributeName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'ProductAttributeListComponent_Name_gh', keyColumn: false})
        productAttributeName : string;
//#endregion productAttributeName Prop


//#region description Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'ProductAttributeListComponent_Description_gh', keyColumn: false})
        description : string;
//#endregion description Prop

}