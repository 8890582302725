import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractProject } from '../domain/abstract-project';
import { Project, vPersonCorporateMapping } from "@app/models";
import { Subject, Subscription } from 'rxjs';
import { DivGrid, AppDivGrid } from 'src/app/domain/app-grid';
import { DialogViewMode } from '@rxweb/js';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { Title } from '@angular/platform-browser';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { ProjectsFilterViewModel } from 'src/app/view-model/projects-filter-view-model';
import { IFormGroup, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { GridColumnConfig } from '@rxweb/grid';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';

import { multilingual } from '@rxweb/localization';
import { scroolOnSearchResult } from 'src/app/domain/customize-plugin/bind-scroll-on-search';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { MainSubProjectChildListViewModel } from 'src/app/models/extended-models/main-sub-project-childlist-model';
import { access } from '@rxweb/angular-router';
import { ProjectAddComponent } from '../add/project-add.component';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { debounceTime } from 'rxjs/operators';
import { SubProjectLevel3ListViewModel } from "src/app/models/extended-models/sub-project-level3-list-view.model";
import { MainProjectListViewModel } from "src/app/models/extended-models/main-project-list-view-model";
import { MainSubProjectListViewModel } from "src/app/models/extended-models/main-sub-project-list-view-model";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("ProjectListComponent")
@access({ accessLevel: RolePermissionEnums.Project, action: "get" })
@Component({
  selector: "app-project-list",
  templateUrl: './project-list.component.html'
})
export class ProjectListComponent extends AbstractProject implements OnInit, OnDestroy {
  projectsSearchFormGroup: IFormGroup<ProjectsFilterViewModel>;
  projects: ProjectsFilterViewModel;
  subscription: any;
  projectGridColumns: any[];
  id: any;
  showGrid: boolean = false;
  projectList: MainProjectListViewModel[];
  subProjectList: MainSubProjectListViewModel[];
  subProjectChlidList: MainSubProjectChildListViewModel[];
  level4GridList: MainSubProjectChildListViewModel[];
  specificProjects: MainProjectListViewModel[];
  rowCount: number = 100;
  pageIndex: number = 1;
  isSearch: boolean = false;
  isFilter: boolean = false;
  totalRecords: number = 0;
  projectType: number = null;
  router: Router;
  dateError: boolean;
  paginationViewModel: PaginationViewModel;
  openArray: number[];
  subOpenArray: any[];
  childGrid: DivGrid;
  subchildGrid: DivGrid;
  projectAdd: any = ProjectAddComponent;
  mainProjectId: number;
  mainProjectIdLevel1: number;
  mainProjectIdLevel2: number;
  projectIds: any[];
  projectId: number;
  childGridArray: any[];
  subChildGridArray: any[];
  projectCorporateMappingList: vPersonCorporateMapping[];
  loggedInUser: number;
  moduleID: number;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  titleHelp: string;
  titleGridColumnSetting: string;
  private projectSubject: Subject<string> = new Subject<string>();
  showSystemHelpIcon: boolean = false;
  projectsList: any;
  ProjectIds: number[];
  projectIdError: boolean = false;
  searchProjectName: string = "";
  searchCorporateName: string = "";
  searchgemfieldsGroupCompanyProjectOwner: string = "";

  resetNameControl: boolean = true;
  titleExpand: string;
  titleCollapse: string;


  _this;

  constructor(router: Router, modalView: ModalView, private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, private browserStorage: BrowserStorage, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.modalView = modalView;
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })
    this.router = router;
    this.openArray = new Array<number>();
    this.subOpenArray = new Array<any>();
    this.projectIds = new Array<any>();
    this.childGridArray = new Array<any>();
    this.subChildGridArray = new Array<any>();
    this.applicationBroadcaster.activeMenu({ activeMenu: 'projects', subMenu: '' })
    if (this.browserStorage.local.get("userId") != null) {
      this.loggedInUser = this.browserStorage.local.get("userId");
    } else {
      this.loggedInUser = 0;
    }
    this.moduleID = RolePermissionEnums.Project;
    this._this = this;
    Window["ProjectListComponent"] = this;

  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Projects - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleExpand = getLocalizedValue("Title_Expand");
    this.titleCollapse = getLocalizedValue("Title_Collapse");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.spin = true;
    this.selectedTab = "All-Projects";
    this.projectSubject.pipe(debounceTime(300)).subscribe(t => {
      this.searchProjects();
    })
    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)) }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
    });
    // this.lookup([{ path: USER_LOOKUPS["userLookUp"], propName: "usersLookUp" },
    this.lookup([{ path: USER_LOOKUPS["projectOwnerLookUp"], propName: "usersLookUp" },
    { path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp" },
    { path: USER_LOOKUPS.genfieldsGroupEntity, propName: "genfieldsGroupEntity" },
    { path: USER_LOOKUPS.countryLookUp, propName: "countryLookUp" },
    { path: USER_LOOKUPS.specificRiskLookUp, propName: "specificRiskLookUp", params: [0] }]).subscribe((lookup: any) => {
      this.projects = new ProjectsFilterViewModel();
      this.projects.pageIndex = PaginationEnum.PageIndex;
      this.projects.rowCount = PaginationEnum.RowCount;
      this.projects.projectType = this.projectType;
      this.projectsSearchFormGroup = this.formBuilder.formGroup(this.projects) as IFormGroup<ProjectsFilterViewModel>;
      this.projectLookup = lookup;
      this.showComponent = true;
      this.spin = false;
    });
  }

  onKeyPress() {
    this.projectSubject.next();
  }

  bindGrid() {
    this.projectsSearchFormGroup.submitted = true;
      this.spin = true;
      this.totalRecords = 0;


      if (this.projectsSearchFormGroup.value.projectName == null || (this.projectsSearchFormGroup.value.projectNameId > 0 && typeof this.projectsSearchFormGroup.value.projectNameId === 'number')) {
        this.projectsSearchFormGroup.patchValue({
          projectName: ''
        })
      }

      if (this.projectsSearchFormGroup.value.projectName != '') {
        this.projectsSearchFormGroup.patchValue({
          projectNameId: null
        })
      }
      if (this.projectsSearchFormGroup.controls["personText"].value == null || this.projectsSearchFormGroup.controls["persons"].value > 0) {
        this.projectsSearchFormGroup.patchValue({
          personText: ''
        })
      }

      // if (this.projectsSearchFormGroup.controls["corporateText"].value == null || this.projectsSearchFormGroup.controls["corporates"].value > 0) {
      //     this.projectsSearchFormGroup.patchValue({
      //         corporateText: ''
      //     })
      // }

      if (this.projectsSearchFormGroup.controls["persons"].value == '') {
        this.projectsSearchFormGroup.patchValue({
          persons: null
        })
      }

      // if (this.projectsSearchFormGroup.controls["corporates"].value == '') {
      //     this.projectsSearchFormGroup.patchValue({
      //         corporates: null
      //     })
      // }

      if (this.projectsSearchFormGroup.value.personText != null && this.projectsSearchFormGroup.value.personText != "") {
        this.projectsSearchFormGroup.patchValue({
          persons: null
        });
      }


        this.projectsSearchFormGroup.patchValue({
          projectId: null
        });
      

      // if (this.projectsSearchFormGroup.value.corporateText != null && this.projectsSearchFormGroup.value.corporateText != "") {
      //     this.projectsSearchFormGroup.patchValue({
      //         corporates: null
      //     });
      // }

      if (this.projectsSearchFormGroup.value.corporateText == null || (this.projectsSearchFormGroup.value.corporates > 0 && typeof this.projectsSearchFormGroup.value.corporates === 'number')) {
        this.projectsSearchFormGroup.patchValue({
          corporateText: ''
        })
      }

      if (this.projectsSearchFormGroup.value.corporateText != '') {
        this.projectsSearchFormGroup.patchValue({
          corporates: null
        });
      }

      if (this.projectsSearchFormGroup.value.gemfieldsGroupCompanyProjectOwnerText== null || (this.projectsSearchFormGroup.value.gemfieldsGroupCompanyProjectOwner > 0 && typeof this.projectsSearchFormGroup.value.gemfieldsGroupCompanyProjectOwner === 'number')) {
        this.projectsSearchFormGroup.patchValue({
          gemfieldsGroupCompanyProjectOwnerText: ''
        })
      }

      if (this.projectsSearchFormGroup.value.gemfieldsGroupCompanyProjectOwnerText != '') {
        this.projectsSearchFormGroup.patchValue({
          gemfieldsGroupCompanyProjectOwner: null
        });
      }
      
      if (this.projectType != null) {
        this.projectsSearchFormGroup.patchValue({ projectType: this.projectType });
      }
      var json = JSON.parse(JSON.stringify(this.projectsSearchFormGroup.value));
      json["projectOrSubproject"] = 0;
      this.subscription = this.post({
        path: "api/SearchProject/ProjectSearch",
        body: {
          query: JSON.stringify(json),
        }
      }).subscribe((t: any) => {
        this.showComponent = true;
        this.isShowGrid = true;
        setTimeout(() => {
          this.projectsSearchFormGroup.patchValue({
            personText: null,
          })
          scroolOnSearchResult();
          if (this.projectGrid) {
            this.projectGrid.storeProcedure.length = 0;
          }
          this.showGrid = true;
          this.spin = false;
          this.projectList = t;
          if (this.projectList.length > 0) {
            this.totalRecords = this.projectList[0].totalCount;
            this.isNoRecordFound = false;
            this.projectList.forEach(x => {
              var jsoncompanyDetails = JSON.parse(x.companyDetails);
              if (jsoncompanyDetails != null && jsoncompanyDetails != undefined && jsoncompanyDetails.length > 0) {
                x.companyName = this.formCorporateTable(jsoncompanyDetails);
              }
              else {
                x.companyName = ''
              }
            });
          }
          else {
            this.isShowGrid = false;
            this.isNoRecordFound = true;
          }
          if (!this.isFilter) {
            this.projectGrid = new AppDivGrid(this.projectList, MainProjectListViewModel, { actions: { onOneLink: this.onProjectLink.bind(this), onExpand: this.getSubProject.bind(this), onMoreClick: this.onMoreClick.bind(this) } });
            this.projectGrid.storeProcedure = {
              length: this.totalRecords,
              onPageChanging: this.onPageChanging.bind(this),
              nextPage: 1,
              onPageSorting: this.onPageSorting.bind(this)
            }
          }
          else {
            if (this.projectGrid != null) {
              this.projectGrid.storeProcedure.length = this.totalRecords;
              if (this.projectsSearchFormGroup.value.pageIndex == 1) {
                this.projectGrid.storeProcedure.nextPage = 1;
              }
              this.projectGrid.updateSource([]);
              this.projectGrid.updateSource(this.projectList);
            }
          }

          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
              this.projectGrid.gridColumns.forEach(y => {
                if (y.name == x.ColumnName)
                  y.visible = x.Visible;
              });
            });
            this.gridColumnSettings.emit(this.gridColumnConfig)
          }
          this.gridColumnConfig = this.projectGrid.gridColumns;

          this.projectGrid.maxPerPage = this.projectsSearchFormGroup.value.rowCount;
          this.projectGrid.componentId = this.componentName;
          this.projectIdError = false;
          // this.projectGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
          // this.projectGrid.designClass.headerClass = "table-header-sticky".split(" ");
          this.applyResProjClass();
        }, 500);
      })
  }


  onMoreClick(t, x) {
    var element = document.getElementById("note_project_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_project_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");

    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  formCorporateTable(companyDetails: any): any {
    let tabledata: any;
    let row: string = "";
    let subRow: any = "";
    tabledata = "<div><table class='w-100'><tbody>";
    let subTable: any;
    subTable = "<tr id='project-row-" + companyDetails[0].ccmId + "' style='display:none'><td colspan='12'><div class='accordian-body'><table><tbody>";
    if (companyDetails != null && companyDetails != undefined) {
      if (companyDetails.length > 1) {
        for (let i = 0; i < companyDetails.length; i++) {
          let x = companyDetails[i];
          if (i == 0) {
            row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> <i title="${this.titleExpand}" class='float-right fa fa-chevron-down ml-2' id='icon-project-${x.ccmId}' onclick='Window.ProjectListComponent.expandCorporateTable("${x.ccmId}")'></i></td></tr>`;
          }
          else {
            subRow = subRow + `<tr><td><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> </td></tr>`;
          }
        }
      }
      else if (companyDetails.length == 1) {
        let x = companyDetails[0];
        row = row + `<tr><td ><a class="text-blue font-weight-bold" href="${x.companyUrl}">${x.companyName}</a> </td></tr>`;
      }
      if (subRow != "") {
        subTable = subTable + subRow + "</tbody></table></div></td></tr>";
        row = row + subTable;
      }
      tabledata = tabledata + row + "</tbody></table></div>"
    }
    return tabledata;
  }

  expandCorporateTable(id: number) {
    var element = document.getElementById("project-row-" + id.toString());
    var iconElement = document.getElementById("icon-project-" + id.toString());
    if (element != null) {
      if (element.style.display == "none") {
        element.style.display = "block";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-up ml-2"
          iconElement.title = this.titleCollapse;
        }

      }
      else {
        element.style.display = "none";
        if (iconElement != null) {
          iconElement.className = "float-right fa fa-chevron-down ml-2"
          iconElement.title = this.titleExpand;
        }

      }
    }
  }

  getSubProject(t, x, y, z) {
    this.spin = true;

    if (t.parentId > 0) {
      this.getSubChildGrid(t, x, y, z);
    } else {
      if (this.projectIds.findIndex((x) => x.mainProjectId == t.projectId) == -1) {
        this.projectIds.push({ mainProjectId: t.projectId, mainProjectIdLevel1: null, mainProjectIdLevel2: null });
        this.mainProjectId = t.projectId;
      } else {
        this.mainProjectId = this.projectIds[this.projectIds.findIndex((x) => x.mainProjectId == t.projectId)].mainProjectId;
      }
      var element = this.projectGrid.getRowElement(t.projectId);
    }

    if (this.openArray.findIndex((x) => x == t.projectId) != -1) {
      this.openArray.splice(this.openArray.findIndex((x) => x == t.projectId), 1);
      let count = this.subOpenArray.length;
      for (let i = 0; i < count; i++) {
        if (this.subOpenArray.findIndex((x) => x.mainProjectId == t.projectId) != -1) {
          this.subOpenArray.splice(this.subOpenArray.findIndex((x) => x.mainProjectId == t.projectId), 1);
        }
      }
      element.removeChild(element.lastChild);
      this.childGridArray.splice(this.childGridArray.findIndex((x) => x.parentProjectId == t.projectId), 1);
      this.subChildGridArray.splice(this.subChildGridArray.findIndex((x) => x.mainProjectId == t.projectId), 1);
      x.srcElement.className = "fa fa-chevron-down mr-2";
      x.srcElement.setAttribute("title", getLocalizedValue("Title_Expand"))
      this.spin = false;
    }
    else {

      this.paginationViewModel = new PaginationViewModel();
      this.paginationViewModel.sortOrder = "true";
      this.paginationViewModel.orderBy = "projectName";
      var jsonSub = JSON.parse(JSON.stringify(this.paginationViewModel));
      jsonSub["projectId"] = t.projectId;
      jsonSub["projectOrSubproject"] = 2;
      jsonSub["projectOwner"] = this.projectsSearchFormGroup.value.projectOwner;
      jsonSub["subProjectName"] = this.projectsSearchFormGroup.value.projectName == null ? null : this.projectsSearchFormGroup.value.projectName;
      jsonSub["projectStartDate"] = this.projectsSearchFormGroup.value.projectStartDate;
      jsonSub["projectEndDate"] = this.projectsSearchFormGroup.value.projectEndDate;
      jsonSub["persons"] = this.projectsSearchFormGroup.value.persons;
      jsonSub["corporates"] = this.projectsSearchFormGroup.value.corporates;
      jsonSub["department"] = this.projectsSearchFormGroup.value.department;
      jsonSub["countryId"] = this.projectsSearchFormGroup.value.countryId;
      jsonSub["personText"] = this.projectsSearchFormGroup.value.personText;
      jsonSub["corporateText"] = this.projectsSearchFormGroup.value.corporateText;
      jsonSub["searchString"] = this.projectsSearchFormGroup.value.searchString;
      this.post({ path: "api/SearchProject/SubProjects", body: { query: JSON.stringify(jsonSub) } }).subscribe((data: any) => {
        //this.spin = false;
        setTimeout(() => {
          this.spin = false;
        }, 2000);
        this.subProjectList = data;
        if (element) {
          x.srcElement.className = "fa fa-chevron-up mr-2";
          x.srcElement.setAttribute("title", getLocalizedValue("Title_Collapse"))
          let childGrid = new DivGrid(this.subProjectList, MainSubProjectListViewModel, { actions: { onOneLink: this.onProjectLink.bind(this), onExpand: this.getSubProject.bind(this) } });
          childGrid.gridColumns.forEach(x => {

            switch (x.name) {
              case "month":
                x.visible = this.gridColumnConfig.find(x => x.name == "month").visible;
                break;
              case "projectId":
                x.visible = this.gridColumnConfig.find(x => x.name == "viewProjectId").visible;
                break;
              // case "projectTypeName":
              //   x.visible = this.gridColumnConfig.find(x => x.name == "projectTypeName").visible;
              //   break;
                case "gemfieldsGroupCompanyProjectOwnerName":
                x.visible = this.gridColumnConfig.find(x => x.name == "gemfieldsGroupCompanyProjectOwnerName").visible;
                break;
              case "projectName":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectName").visible;
                break;
              case "projectOwnerName":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectOwnerName").visible;
                break;
              case "projectIndicator":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectIndicator").visible;
                break;
              case "departmentName":
                x.visible = this.gridColumnConfig.find(x => x.name == "departmentName").visible;
                break;
              case "companyName":
                x.visible = this.gridColumnConfig.find(x => x.name == "companyName").visible;
                break;
              case "recentNote":
                  x.visible = this.gridColumnConfig.find(x => x.name == "recentNote").visible;
                  break;
              case "noteCreatedDate":
                    x.visible = this.gridColumnConfig.find(x => x.name == "noteCreatedDate").visible;
                    break;
              case "countryName":
                    x.visible = this.gridColumnConfig.find(x => x.name == "countryName").visible;
                    break;
              default:
                break;
            }
          })
          this.applyResProjClassChildgrid(childGrid, t.isRestrictedProject);
          this.projectGrid.addChildGrid(t.projectId, childGrid);
          this.childGridArray.push({ parentProjectId: this.mainProjectId, childGrid: childGrid });
          element.lastElementChild.className = "div-tr";
          this.openArray.push(t.projectId);
        }
      });
    }

  }


  getSubChildGrid(t, x, y, z) {
    this.spin = true;
    if (this.projectIds.findIndex((x) => x.mainProjectId == t.parentId) == -1) {
      this.projectIds[this.projectIds.findIndex((x) => x.mainProjectId == t.parentId)].mainProjectIdLevel1 = t.projectId;
      this.mainProjectIdLevel1 = t.projectId;
    } else {
      this.projectIds[this.projectIds.findIndex((x) => x.mainProjectId == t.parentId)].mainProjectIdLevel1 = t.projectId;
      this.mainProjectId = this.projectIds[this.projectIds.findIndex((x) => x.mainProjectId == t.parentId)].mainProjectId;
      this.mainProjectIdLevel1 = this.projectIds[this.projectIds.findIndex((x) => x.mainProjectId == t.parentId)].mainProjectIdLevel1;
    }
    var element = this.childGridArray[this.childGridArray.findIndex((x) => x.parentProjectId == t.parentId)].childGrid.getRowElement(t.projectId);
    this.childGrid = this.childGridArray[this.childGridArray.findIndex((x) => x.parentProjectId == t.parentId)].childGrid;
    if (this.subOpenArray.findIndex((x) => x.projectId == t.projectId) != -1) {
      let count = this.subOpenArray.length;
      for (let i = 0; i < count; i++) {
        if (this.subOpenArray.findIndex((x) => x.mainProjectIdLevel1 == t.projectId) != -1) {
          this.subOpenArray.splice(this.subOpenArray.findIndex((x) => x.mainProjectIdLevel1 == t.projectId), 1);
        }
      }
      element.removeChild(element.lastChild);
      this.subChildGridArray.splice(this.subChildGridArray.findIndex((x) => x.parentProjectId == t.projectId), 1);
      x.srcElement.className = "fa fa-chevron-down mr-2";
      x.srcElement.setAttribute("title", getLocalizedValue("Title_Expand"))
      this.spin = false;
    }
    else {
      this.paginationViewModel = new PaginationViewModel();
      this.paginationViewModel.sortOrder = "true";
      this.paginationViewModel.orderBy = "projectName";
      var jsonSub = JSON.parse(JSON.stringify(this.paginationViewModel));
      jsonSub["projectId"] = t.projectId;
      jsonSub["projectOrSubproject"] = 2;
      jsonSub["projectOwner"] = this.projectsSearchFormGroup.value.projectOwner;
      jsonSub["subProjectName"] = this.projectsSearchFormGroup.value.projectName == null ? null : this.projectsSearchFormGroup.value.projectName;
      jsonSub["projectStartDate"] = this.projectsSearchFormGroup.value.projectStartDate;
      jsonSub["projectEndDate"] = this.projectsSearchFormGroup.value.projectEndDate;
      jsonSub["persons"] = this.projectsSearchFormGroup.value.persons;
      jsonSub["corporates"] = this.projectsSearchFormGroup.value.corporates;
      jsonSub["department"] = this.projectsSearchFormGroup.value.department;
      jsonSub["countryId"] = this.projectsSearchFormGroup.value.countryId;
      jsonSub["personText"] = this.projectsSearchFormGroup.value.personText;
      jsonSub["corporateText"] = this.projectsSearchFormGroup.value.corporateText;
      jsonSub["searchString"] = this.projectsSearchFormGroup.value.searchString;
      this.post({ path: "api/SearchProject/SubProjects", body: { query: JSON.stringify(jsonSub) } }).subscribe((data: any) => {
        //this.spin = false;
        setTimeout(() => {
          this.spin = false;
        }, 2000);
        this.subProjectChlidList = data;
        if (element) {
          x.srcElement.className = "fa fa-chevron-up mr-2";
          x.srcElement.setAttribute("title", getLocalizedValue("Title_Collapse"))
          let subchildGrid = new DivGrid(this.subProjectChlidList, SubProjectLevel3ListViewModel, { actions: { onOneLink: this.onProjectLink.bind(this), onExpand: this.getLevel4Grid.bind(this) } });
          subchildGrid.gridColumns.forEach(x => {

            switch (x.name) {
              case "month":
                x.visible = this.gridColumnConfig.find(x => x.name == "month").visible;
                break;
              case "projectId":
                x.visible = this.gridColumnConfig.find(x => x.name == "viewProjectId").visible;
                break;
              // case "projectTypeName":
              //   x.visible = this.gridColumnConfig.find(x => x.name == "projectTypeName").visible;
              //   break;
              case "gemfieldsGroupCompanyProjectOwnerName":
                x.visible = this.gridColumnConfig.find(x => x.name == "gemfieldsGroupCompanyProjectOwnerName").visible;
                break;
              case "projectName":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectName").visible;
                break;
              case "projectOwnerName":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectOwnerName").visible;
                break;
              case "projectIndicator":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectIndicator").visible;
                break;
              case "departmentName":
                x.visible = this.gridColumnConfig.find(x => x.name == "departmentName").visible;
                break;
              case "companyName":
                x.visible = this.gridColumnConfig.find(x => x.name == "companyName").visible;
                break;
              case "recentNote":
                  x.visible = this.gridColumnConfig.find(x => x.name == "recentNote").visible;
                  break;
              case "noteCreatedDate":
                    x.visible = this.gridColumnConfig.find(x => x.name == "noteCreatedDate").visible;
                    break;
              case "countryName":
                    x.visible = this.gridColumnConfig.find(x => x.name == "countryName").visible;
                    break;
              default:
                break;
            }
          })
          this.applyResProjClassChildgrid(subchildGrid, t.isRestrictedProject);
          this.childGrid.addChildGrid(t.projectId, subchildGrid);
          this.subChildGridArray.push({ mainProjectId: this.mainProjectId, parentProjectId: this.mainProjectIdLevel1, childGrid: subchildGrid });
          element.lastElementChild.className = "div-tr";
          this.subOpenArray.push({ mainProjectId: this.mainProjectId, mainProjectIdLevel1: this.mainProjectIdLevel1, mainProjectIdLevel2: null, projectId: t.projectId });
        }
      });
    }
  }

  getLevel4Grid(t, x, y, z) {
    this.spin = true;
    if (t.parentId != this.projectIds.filter(x => x.mainProjectIdLevel1)[0]) {
      this.projectIds[this.projectIds.findIndex((x) => x.mainProjectId == this.mainProjectId)].mainProjectIdLevel1 = t.parentId;
    }
    if (this.projectIds.findIndex((x) => x.mainProjectIdLevel1 == t.parentId) == -1) {
      this.projectIds[this.projectIds.findIndex((x) => x.mainProjectIdLevel1 == t.parentId)].mainProjectIdLevel2 = t.projectId;
      this.mainProjectIdLevel2 = t.projectId;
    } else {
      this.projectIds[this.projectIds.findIndex((x) => x.mainProjectIdLevel1 == t.parentId)].mainProjectIdLevel2 = t.projectId;
      this.mainProjectId = this.projectIds[this.projectIds.findIndex((x) => x.mainProjectIdLevel1 == t.parentId)].mainProjectId;
      this.mainProjectIdLevel1 = this.projectIds[this.projectIds.findIndex((x) => x.mainProjectIdLevel1 == t.parentId)].mainProjectIdLevel1;
      this.mainProjectIdLevel2 = this.projectIds[this.projectIds.findIndex((x) => x.mainProjectIdLevel1 == t.parentId)].mainProjectIdLevel2;
    }
    var element = this.subChildGridArray[this.subChildGridArray.findIndex((x) => x.parentProjectId == t.parentId)].childGrid.getRowElement(t.projectId);
    this.subchildGrid = this.subChildGridArray[this.subChildGridArray.findIndex((x) => x.parentProjectId == t.parentId)].childGrid;
    if (this.subOpenArray.findIndex((x) => x.projectId == t.projectId) != -1) {
      let count = this.subOpenArray.length;
      for (let i = 0; i < count; i++) {
        if (this.subOpenArray.findIndex((x) => x.mainProjectIdLevel2 == t.projectId) != -1) {
          this.subOpenArray.splice(this.subOpenArray.findIndex((x) => x.mainProjectIdLevel2 == t.projectId), 1);
        }
      }
      element.removeChild(element.lastChild);
      x.srcElement.className = "fa fa-chevron-down mr-2";
      x.srcElement.setAttribute("title", getLocalizedValue("Title_Expand"))
      this.spin = false;
    }
    else {
      this.paginationViewModel = new PaginationViewModel();
      this.paginationViewModel.sortOrder = "true";
      this.paginationViewModel.orderBy = "projectName";
      var jsonSub = JSON.parse(JSON.stringify(this.paginationViewModel));
      jsonSub["projectId"] = t.projectId;
      jsonSub["projectOrSubproject"] = 2;
      jsonSub["projectOwner"] = this.projectsSearchFormGroup.value.projectOwner;
      jsonSub["subProjectName"] = this.projectsSearchFormGroup.value.projectName == null ? null : this.projectsSearchFormGroup.value.projectName;
      jsonSub["projectStartDate"] = this.projectsSearchFormGroup.value.projectStartDate;
      jsonSub["projectEndDate"] = this.projectsSearchFormGroup.value.projectEndDate;
      jsonSub["persons"] = this.projectsSearchFormGroup.value.persons;
      jsonSub["corporates"] = this.projectsSearchFormGroup.value.corporates;
      jsonSub["department"] = this.projectsSearchFormGroup.value.department;
      jsonSub["countyId"] = this.projectsSearchFormGroup.value.countryId;
      jsonSub["personText"] = this.projectsSearchFormGroup.value.personText;
      jsonSub["corporateText"] = this.projectsSearchFormGroup.value.corporateText;
      jsonSub["searchString"] = this.projectsSearchFormGroup.value.searchString;
      this.post({ path: "api/SearchProject/SubProjects", body: { query: JSON.stringify(jsonSub) } }).subscribe((data: any) => {
        //this.spin = false;
        setTimeout(() => {
          this.spin = false;
        }, 2000);
        this.level4GridList = data;
        if (element) {
          x.srcElement.className = "fa fa-chevron-up mr-2";
          x.srcElement.setAttribute("title", getLocalizedValue("Title_Collapse"))
          let subchildLevel4Grid = new DivGrid(this.level4GridList, MainSubProjectChildListViewModel, { actions: { onOneLink: this.onProjectLink.bind(this) } });
          subchildLevel4Grid.gridColumns.forEach(x => {

            switch (x.name) {
              case "month":
                x.visible = this.gridColumnConfig.find(x => x.name == "month").visible;
                break;
              case "projectId":
                x.visible = this.gridColumnConfig.find(x => x.name == "viewProjectId").visible;
                break;
              // case "projectTypeName":
              //   x.visible = this.gridColumnConfig.find(x => x.name == "projectTypeName").visible;
              //   break;
              case "gemfieldsGroupCompanyProjectOwnerName":
                x.visible = this.gridColumnConfig.find(x => x.name == "gemfieldsGroupCompanyProjectOwnerName").visible;
                break;
              case "projectName":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectName").visible;
                break;
              case "projectOwnerName":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectOwnerName").visible;
                break;
              case "projectIndicator":
                x.visible = this.gridColumnConfig.find(x => x.name == "projectIndicator").visible;
                break;
              case "departmentName":
                x.visible = this.gridColumnConfig.find(x => x.name == "departmentName").visible;
                break;
              case "companyName":
                x.visible = this.gridColumnConfig.find(x => x.name == "companyName").visible;
                break;
              case "recentNote":
                  x.visible = this.gridColumnConfig.find(x => x.name == "recentNote").visible;
                  break;
              case "noteCreatedDate":
                    x.visible = this.gridColumnConfig.find(x => x.name == "noteCreatedDate").visible;
                    break;
              case "countryName":
                    x.visible = this.gridColumnConfig.find(x => x.name == "countryName").visible;
                    break;
              default:
                break;
            }
          })
          this.applyResProjClassChildgrid(subchildLevel4Grid, t.isRestrictedProject);
          this.subchildGrid.addChildGrid(t.projectId, subchildLevel4Grid);
          element.lastElementChild.className = "div-tr";
          this.subOpenArray.push({ mainProjectId: this.mainProjectId, mainProjectIdLevel1: this.mainProjectIdLevel1, mainProjectIdLevel2: this.mainProjectIdLevel2, projectId: t.projectId });
        }
      });
    }
  }

  onProjectLink(userProject: MainProjectListViewModel, event) {
    //let newTab: boolean = false;
    let url: any;

    // if (event.ctrlKey) {
    //     newTab = true;
    // }
    this.spin = true;
    // if (userProject.isRestrictedProject) {
    //     this.subscription = this.get({
    //         path: "api/ProjectCorporateMappings",
    //         queryParams: {
    //             projectId: userProject.projectId
    //         }
    //     }).subscribe((t: any) => {
    //         this.spin = false;
    //         this.projectCorporateMappingList = t;
    //         if (this.projectCorporateMappingList.length > 0) {
    //             if (this.projectCorporateMappingList.findIndex(x => x.customerID == this.loggedInUser) == -1 && this.loggedInUser != userProject.projectOwner) {
    //                 url = this.router.serializeUrl(this.router.createUrlTree(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } }));
    //                 // this.router.navigate(["projects/restricted-project", userProject.projectId]);
    //             } else {
    //                 // this.router.navigate(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } });
    //                 url = this.router.serializeUrl(this.router.createUrlTree(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } }));
    //             }
    //         } else {
    //             if (this.loggedInUser != userProject.projectOwner) {
    //                 url = this.router.serializeUrl(this.router.createUrlTree(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } }));
    //                 // this.router.navigate(["projects/restricted-project", userProject.projectId]);
    //             } else {
    //                 // this.router.navigate(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } });
    //                 url = this.router.serializeUrl(this.router.createUrlTree(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } }));

    //             }
    //         }
    //         if (newTab) {
    //             this.spin = false;
    //             window.open(url, '_blank');
    //         }
    //         else
    //             this.router.navigateByUrl(url);
    //     });
    // }
    //else {
    // this.router.navigate(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } });

    // if (userProject.isRestrictedProject == true) {
    //     url = this.router.serializeUrl(this.router.createUrlTree(["projects/restricted-project", userProject.projectId]));
    //     this.spin = redirectOnCTRL(url, this.router, event);
    // }
    // else if (userProject.isRestrictedProject == false) {
    url = this.router.serializeUrl(this.router.createUrlTree(["projects", userProject.projectId], { queryParams: { activeTab: 'project-edit' } }));
    this.spin = redirectOnCTRL(url, this.router, event);
    // }
    // if(!redirectOnCTRL(url,event))
    // {
    //     this.router.navigateByUrl(url);
    // }
    // if (newTab) {
    //     this.spin = false;
    //     window.open(url, '_blank');
    // }
    // else
    //     this.router.navigateByUrl(url);
    //}
    // if (newTab) {
    //     this.spin = false;
    //     window.open(url, '_blank');
    // }
    // else
    //     this.router.navigateByUrl(url);
  }

  applyResProjClass() {
    this.projectGrid.designClass.rowClass = [function () {
      let data = this;
      if (data.isRestrictedProject) {
        return "div-tr restrictedProject".split(" ");
      } else {
        return "div-tr";
      }
    }]
  }
  applyResProjClassChildgrid(childGrid: DivGrid, parentResricted: boolean) {
    if (parentResricted) {
      childGrid.designClass.rowClass = [function () {
        let data = this;
        if (data.isRestrictedProject) {
          return "div-tr restrictedProject".split(" ");
        } else {
          return "div-tr normalChildProject".split(" ");
        }
      }]
    } else {
      childGrid.designClass.rowClass = [function () {
        let data = this;
        if (data.isRestrictedProject) {
          return "div-tr restrictedProject".split(" ");
        } else {
          return "div-tr";
        }
      }]
    }
  }

  checkNameText(event: any) {
    this.projectsSearchFormGroup.patchValue({
      personText: event.target.value
    })
  }

  checkgemCorpText(event: any) {
    this.projectsSearchFormGroup.patchValue({
      gemfieldsGroupCompanyProjectOwnerText: event.target.value
    })
    this.searchgemfieldsGroupCompanyProjectOwner = event.target.value;
  }
  ongemCorpClick($event) {

    this.projectsSearchFormGroup.patchValue({
      gemfieldsGroupCompanyProjectOwnerText: '',
    })
    this.searchgemfieldsGroupCompanyProjectOwner = $event.item.value;
  }

  checkCorpText(event: any) {
    this.projectsSearchFormGroup.patchValue({
      corporateText: event.target.value
    })
    this.searchCorporateName = event.target.value;
  }
  onCorpClick($event) {

    this.projectsSearchFormGroup.patchValue({
      corporateText: '',
    })
    this.searchCorporateName = $event.item.value;
  }

  searchProjects() {
    if (dateCompareValidation(this.projectsSearchFormGroup.value.projectStartDate, this.projectsSearchFormGroup.value.projectEndDate) == true) {
      this.dateError = true;
    }
    else {
      this.resetNameControl = false;
      setTimeout(() => {
        this.resetNameControl = true;
      }, 10);
      this.dateError = false;
      if (this.projectGrid) {
        this.isFilter = true;
        this.isFilter = true;
        this.projectGrid.updateSource([]);
        this.projectGrid.storeProcedure.nextPage = 1;
        this.projectsSearchFormGroup.value.rowCount = this.projectGrid.maxPerPage;
      }
      this.subOpenArray = [];
      this.openArray = [];
      this.projectIds = [];
      this.childGridArray = [];
      this.subChildGridArray = [];
      //this.projectsSearchFormGroup.value.pageIndex=1
      this.projectsSearchFormGroup.patchValue({
        pageIndex: 1
      });
      this.bindGrid();
    }
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.projectsSearchFormGroup.patchValue({ sortOrder: y, orderBy: x })
    this.projectGrid.storeProcedure.nextPage = z;
    this.subOpenArray = [];
    this.openArray = [];
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.projectsSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.projectGrid.maxPerPage })
    this.projectGrid.storeProcedure.nextPage = x;
    this.subOpenArray = [];
    this.openArray = [];
    this.bindGrid();
  }

  resetSearch() {
    this.spin = true;
    this.showComponent = false;
    if(this.projectGrid && this.projectGrid.source.length > 0){
      this.projectGrid.destroy();
    }
    if (this.projectGrid) {
      this.subOpenArray = [];
      this.openArray = [];
      this.projectIds = [];
      this.childGridArray = [];
      this.subChildGridArray = [];
      this.searchProjectName = "";
      this.projectGrid = null;
      this.isFilter = false;
    }
    this.showGrid = false;
    this.projectsSearchFormGroup.reset();
    this.searchCorporateName = ""
    this.searchProjectName = "";
    setTimeout(() => {
      this.showComponent = true;
      this.spin = false;
    }, 100);
  }

  filterProjects(projectType: number) {
    this.projectType = projectType;
    if (this.projectGrid) {
      this.isFilter = true;
    }
    this.projectsSearchFormGroup.patchValue({ projectType: projectType })
    this.subOpenArray = [];
    this.openArray = [];
    this.bindGrid();
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    // if (this.subchildGrid) {
    //     this.childGrid.source.forEach(x => {
    //         this.childGrid.removeChildGrid(x.projectId);
    //     });
    // }

    // if (this.childGrid) {
    //     this.childGrid.source.forEach(x => {
    //         this.childGrid.removeChildGrid(x.projectId);
    //     });
    // }
    // this.subOpenArray = [];
    // if (this.projectGrid) {
    //     this.projectGrid.source.forEach(x => {
    //         this.projectGrid.removeChildGrid(x.projectId);
    //     });
    // }
    // this.openArray = [];
    // this.projectGrid.reDesign();
    // this.projectGrid.updateSource([])
    // this.projectGrid.updateSource(this.projectList);
    if (this.arrConfig == undefined || this.arrConfig == null) {
      const listObject = gridColumnConfig.map(obj => {
        if (!obj.parameter && !obj.keyColumn) {
          return { ColumnName: obj.name, Visible: obj.visible };
        }
      });
      this.arrConfig = listObject;
    }
    gridColumnConfig.forEach(column => {
      var arrConfigColumnIndex = this.arrConfig.findIndex(x => x.ColumnName == column.name);
      if (arrConfigColumnIndex != -1) {
        this.arrConfig[arrConfigColumnIndex].Visible = column.visible;
      }
    });
    
    this.projectGrid.maxPerPage = this.projectsSearchFormGroup.value.rowCount;
    this.isFilter = false;
    this.subOpenArray = [];
    this.openArray = [];
    this.projectIds = [];
    this.childGridArray = [];
    this.subChildGridArray = [];
    this.projectGrid.destroy();
    this.projectGrid = null;
    this.projectsSearchFormGroup.patchValue({
      pageIndex: PaginationEnum.PageIndex,
      rowCount: PaginationEnum.RowCount
    })

    this.bindGrid();
    this.gridColumnConfig = gridColumnConfig;
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ProjectListComponent";
  }

  goToProject() {
    this.projectsSearchFormGroup.submitted = true;

    if (this.projectsSearchFormGroup.controls.projectId.valid) {
      this.id = this.projectsSearchFormGroup.controls.projectId.value;
      this.projectId = parseInt(this.id);

      this.get({ path: 'api/SearchProject/GetProjectExistingProjectIds', queryParams: {} }).subscribe((data: any) => {
        this.projectsList = data;
        var projectSearch = this.projectsList.find(x => x.projectId == this.projectId);
        if (projectSearch) {
          //   if (projectSearch.isRestrictedProject == false) {
          this.router.navigate(['/projects', this.id], { queryParams: { "activeTab": "project-edit" } });
          //   }
          //   else {
          //     this.router.navigate(['/projects/restricted-project', this.id]);
          //   }
        }
        else {
          this.projectIdError = true;
        }
        // for (const data of this.projectsList) {
        //     if (data.projectId == this.projectId && data.isRestrictedProject == false) {
        //         this.router.navigate(['/projects', this.id], { queryParams: { "activeTab": "project-edit" } });
        //         break;
        //     }
        //     else if (data.projectId == this.projectId && data.isRestrictedProject == true) {
        //         this.router.navigate(['/projects/restricted-project', this.id]);
        //         break;
        //     }
        //     else {
        //         this.projectIdError = true;
        //     }
        // }
      });
    }
    else {
      this.projectIdError = true;
    }
  }
  onProjectNameClick($event) {
    this.projectsSearchFormGroup.patchValue({
      projectName: ''
    })
    this.searchProjectName = $event.item.value;
  }
  checkProjectNameText(event: any) {
    this.projectsSearchFormGroup.patchValue({
      projectName: event.target.value
    })
    this.searchProjectName = event.target.value;
  }
}
