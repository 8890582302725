import { gridColumn } from "@rxweb/grid"


export class vAgreementTypeLookUpBase {

//#region agreementTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'agreementTypeId', keyColumn: true})
        agreementTypeId : number;
//#endregion agreementTypeId Prop


//#region agreementTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'agreementTypeName', keyColumn: false})
        agreementTypeName : string;
//#endregion agreementTypeName Prop

}