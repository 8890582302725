import { prop, required, range, numeric, pattern } from "@rxweb/reactive-form-validators"
import { StatusEnum } from "src/app/enums/status.enum";



export class CustomerHolidayBase {

        //#region id Prop
        @prop()
        id: number;
        //#endregion id Prop


        //#region userId Prop
        @required()
        userId: number;
        //#endregion userId Prop


        //#region holidayType Prop
        @required()
        holidayType: number;
        //#endregion holidayType Prop


        //#region openingBalance Prop
        @required()
        //@numeric()
        //@range({ minimumNumber: 0, maximumNumber: 214 })
        @pattern({ expression: { "openingBalance": /^[0-9]\d{0,15}(\.\d{1,2})?%?$/ }, message: "You need to enter appropriate value in this field" })
        openingBalance: number;
        //#endregion openingBalance Prop


        //#region holidayAlloted Prop
        @required()
        //@numeric({ allowDecimal: true })
        //@range({ minimumNumber: 0, maximumNumber: 9999999999999999, message:"You need to enter appropriate value in this field" })
        @pattern({expression:{"holidayAlloted":/^\s*(?=.*[1-9])\d{0,15}(?:\.[0,5]0{0,1})?\s*$/},message:"Please enter valid Holiday Alloted"})
        holidayAlloted: number;
        //#endregion holidayAlloted Prop


        //#region weekOff Prop
        @prop()
        weekOff: string;
        //#endregion weekOff Prop


        //#region workPublicHoliday Prop
        @required()
        @prop({ defaultValue: "" })
        workPublicHoliday: number;
        //#endregion workPublicHoliday Prop


        //#region forYear Prop
        @required()
        @prop({ defaultValue: "" })
        forYear: number;
        //#endregion forYear Prop

        @required()
        @prop({ defaultValue: StatusEnum.Active })
        statusId: number;
}