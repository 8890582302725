export enum DiscountRequirementEnum {
        None = 353,
        Mustbeassignedtorole = 354,
        Customermusrberegistered = 355,
        HasalloftheseDesignProductsintheTray = 356,
        HasoneoftheseDesignProductsintheTray = 357,
        HaspurchasedalloftheseDesignProducts = 358,
        HaspurchasedoneoftheseDesignProducts = 359,
        HadspentxPeriodxxamount = 360,
        Billingcountryis = 361,
        Shippingcountryis = 362,
}