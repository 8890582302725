import { prop, required, propObject, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MindMapCorporateFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "fullName";
    }

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;

    @prop()
    @trim()
    name: string;

    @prop()
    countryId: number;

    @prop()
    introducesBy: number;

    @prop()
    industryTypeId: number;

    @prop()
    supplierRoleId: number;

    @prop()
    industryTypeIds: string="";
}