import { gridColumn } from '@rxweb/grid';


export class SubProjectsViewModel {

    //#region projectId Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 0, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectId_gh', keyColumn: true })
    projectId: number;
    //#endregion projectId Prop

    //#region projectName Prop
    @gridColumn({ configuredTemplate: { templateName: "reDirect" }, style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 2, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectName_gh', keyColumn: false })
    projectName: string;
    //#endregion projectName Prop

    //#region projectOwner Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 3, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectOwner_gh', keyColumn: false })
    projectOwner: number;
    //#endregion projectOwner Prop

    //#region projectOwnerName Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 4, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectOwnerName_gh', keyColumn: false })
    projectOwnerName: string;
    //#endregion projectOwnerName Prop

    //#region projectStartDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true,parameter:false,columnIndex: 5, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectStartDate_gh', keyColumn: false })
    projectStartDate: Date;
    //#endregion projectStartDate Prop

    //#region projectEndDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true, parameter:false,columnIndex: 6, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectEndDate_gh', keyColumn: false })
    projectEndDate: Date;
    //#endregion projectEndDate Prop

    //#region projectIndicator Prop
    @gridColumn({ style: { width: "2%", "text-align": "initial"},configuredTemplate: { templateName: "progressBar" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 7, allowSorting: true, headerKey: 'SubProjectListComponent_ProjectIndicator_gh', keyColumn: false })
    projectIndicator: string;
    //#endregion projectIndicator Prop 

    //#region noteDescription Prop
    // @gridColumn({style: { width: "4%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:8,  allowSorting: true, headerKey: 'noteDescription', keyColumn: false})
    // noteDescription : string;
    @gridColumn({
        template: {

            div: {
                style: {
                    "word-break": "break-all",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        return this.noteDescription;
                    }
                },
                childrens: [{
                    a: {
                        isBind: (x) => {
                            if (x.noteCharCount > 100 && !x.isShowAll) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }, style: {
                            "display": "block"
                        },
                        childrens: [{
                            span: {
                                class: "more-btn mr-2".split(" "),
                                childrens: [{
                                    text: {
                                        text: function (e) {
                                            return "Show More";
                                        }
                                    }
                                }]
                            }
                        }],
                        event: {
                            click: "onMoreClick"
                        }
                    },
                }]
            }
        }, visible: true, parameter:false,keyColumn:false,style: { width: "2%", "text-align": "left" }, class: ["text-left"], columnIndex: 8, allowSorting: false, headerKey: 'SubProjectListComponent_NoteDescription_gh', name: 'noteDescription'
    })
    noteDescription: string;
    //#endregion noteDescription Prop

    // @gridColumn({
    //     template: {

    //         div: {
    //             style: {
    //                 "word-break": "break-all",
    //             },
    //             attributes: {
    //                 innerHTML: function (x, y) {
    //                     return this.noteDescription1;
    //                 }
    //             },
    //         }
    //     }, visible: false, style: { width: "2%", "text-align": "left" }, class: ["text-left"], columnIndex: 8, allowSorting: false, headerKey: 'SubProjectListComponent_NoteDescription_gh', name: 'noteDescription1'
    // })
    // noteDescription1: string;
    noteCharCount: any;

    //#region createdBy Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 9, allowSorting: true, headerKey: 'SubProjectListComponent_CreatedBy_gh', keyColumn: false })
    createdBy: number;
    //#endregion createdBy Prop

    //#region noteAddedBy Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 10, allowSorting: true, headerKey: 'SubProjectListComponent_NoteAddedBy_gh', keyColumn: false })
    noteAddedBy: string;
    //#endregion noteAddedBy Prop

    //#region createdDate Prop
    @gridColumn({ style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: true,parameter:false, columnIndex: 11, allowSorting: true, headerKey: 'SubProjectListComponent_CreatedDate_gh', keyColumn: false })
    createdDate: Date;
    //#endregion createdDate Prop

    //#region totalCount Prop
    @gridColumn({ parameter: true, name: "totalCount", visible: false, columnIndex: 12, allowSorting: true, headerKey: 'totalCount', keyColumn: false })
    totalCount: number;
    //#endregion totalCount Prop

    //#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 13, keyColumn: false })
    parentId: number;
    //#endregion totalCount Prop

    //#region subStreamType Prop
    @gridColumn({ visible: false, columnIndex: 14, keyColumn: false })
    subStreamType: string;
    //#endregion subStreamType Prop

    //#region linkID Prop
    @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
    linkID: number;
    //#endregion linkID Prop
    @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
    isRestrictedProject : boolean;

}
