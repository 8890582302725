<div [rxSpinner]="spin" *rxMultilingual="" >
  <div class="wrapper wrapper-content flex-grow-1" *ngIf="showComponent">
    <div class="ibox mb-0 d-flex flex-column" [rxLocalisationInit]="componentName">
      <div
        class="ibox-content bg-white d-flex flex-column flex-grow-1"
        [formGroup]="eventEInvitationFormGroup"
      >
        <div class="row add-countries-form">
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div class="form-group row">
              <label
                class="col-md-2 col-lg-2 col-xl-2 col-form-label"
                rxText="SendEInvitationEditComponent_eventName_t"
              >
              </label>
              <div class="col-md-5">
                <!-- <input type="text" formControlName="eventName" rxFocus class="form-control "  disabled="true"/> -->
                <label type="text" class="form-control disableLabel mb-0">{{
                  eventEInvitationFormGroup.value.eventName
                }}</label>
                <small
                  class="form-text text-danger"
                  [class.d-block]="
                    eventEInvitationFormGroup.controls.eventName.errors
                  "
                  >{{
                    eventEInvitationFormGroup.controls.eventName.errorMessage
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row add-countries-form">
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div class="form-group row">
              <label
                class="col-md-2 col-lg-2 col-xl-2 col-form-label"
                rxText="SendEInvitationEditComponent_eventInvitees_t"
              >
              </label>
              <div class="col-md-5" *ngIf="showInvitees" [rxTagExtended]="_this">
                <rx-tag
                  [(source)]="emailLookup"
                  #rxTag
                  formControlName="invitedUserIds"
                  mainClass="form-control"
                  [keyValueProps]="['Email', 'userId']"
                  placeholder="Select Invitees"
                  (keyup.enter)="checkInvitees()"
                  (click)="checkInvitees()"
                ></rx-tag>
                <small class="form-text text-danger" *ngIf="toError == true"
                  >Please select atleast one email</small
                >
              </div>
              <div class="col-md-5" *ngIf="!showInvitees"></div>
              <div class="col-md-5 pl-xl-0">
                <ng-container *ngIf="showHideButton" [rxLocalisationInit]="componentName">
                  <button
                    (click)="selectAllInvitees()"
                    class="btn btn-success mr-1"
                    rxText="Btn_Invitees_Select_All_t"
                  ></button>
                </ng-container>
                <ng-container *ngIf="!showHideButton" [rxLocalisationInit]="componentName">
                  <button
                    (click)="removeAllInvitees()"
                    class="btn btn-danger mr-1"
                    rxText="Btn_Invitees_Remove_All_t"
                  ></button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="row add-countries-form">
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div class="form-group row">
              <label
                class="col-md-2 col-lg-3 col-xl-2 col-form-label"
                rxText="SendEInvitationEditComponent_eventDescription_t"
              >
              </label>
            </div>
           
          </div>
        </div>
        
        <div class="row add-countries-form">
          <div class="col-lg-12 col-xl-12 pr-xl-12">
            <div class="form-group row">
              <div class="col-md-12">
                <editor
                formControlName="eInvitationDescription"
                tabindex="2"
                [init]="{
                  height: 800,
                  menubar: true,
                  automatic_uploads: false,
                  convert_urls: false,
                  relative_urls: false,
                  remove_script_host: false,
                  images_upload_handler: tinyMCE_images_upload_handler,
                  plugins:'lists checklist advlist link autolink image editimage charmap preview anchor searchreplace visualblocks advcode fullscreen insertdatetime media table advtable help wordcount casechange export permanentpen powerpaste formatpainter tableofcontents pageembed mergetags  tinymcespellchecker',
                  toolbar:'undo redo | blocks | bold italic backcolor | spellcheckdialog | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | tableofcontents |casechange formatpainter permanentpen checklist | image pageembed export | help',
                  spellchecker_languages: 'UK English=en_GB',
                  statusbar: true,
                  menu: {
                    format: {
                      title: 'Format',
                      items: 'configurepermanentpen | bold italic underline strikethrough ' +
                        'superscript subscript codeformat | styles blocks fontfamily ' +
                        'fontsize align lineheight | forecolor backcolor | removeformat'
                    }
                  },
                  a11y_advanced_options: true,
                  allow_html_in_named_anchor: true,
                  content_style: '::-webkit-scrollbar{width:5px;height:5px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#838282;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#585555}html{scrollbar-width:thin!important;scrollbar-color:dark}'
                }"
                apiKey="{{ apiKey }}"
              >
              </editor>
                <!-- <textarea formControlName="eInvitationDescription" class="form-control"  rxPLaceholder="boutiqueBoutiqueAddress"></textarea> -->
                <small
                  class="form-text text-danger"
                  [class.d-block]="
                    eventEInvitationFormGroup.controls.eInvitationDescription
                      .errors
                  "
                  >{{
                    eventEInvitationFormGroup.controls.eInvitationDescription
                      .errorMessage
                  }}<br
                /></small>
              </div>
            </div>
            <!-- hidden as per client discussion  -->
            <div class="form-group row">
              <!-- style="display: none !important; visibility: hidden !important"    > -->
              <label
                class="col-md-2 mt-3 col-form-label"
                rxText="SendEInvitationEditComponent_EditorDocument_t"
              ></label>
              <div class="col-md-8">
                <app-document-list
                  [entityTypeId]="entityTypeIdForEditorDocuments"
                  [isPicture]="false"
                  [isEditorDocument]="true"
                ></app-document-list>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button
              (click)="EditInvitation(true)"
              class="btn btn-success mr-1"
              rxText="Btn_Send_t"
            ></button>
          </div>
        </div>
        <!-- <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100"></div>
                <div class="col text-right">
                  <button (click)="EditInvitation(false)" class="btn btn-success mr-1" [disabled]="true">Save</button>
                  <button (click)="EditInvitation(true)" class="btn btn-primary mr-1"  [disabled]="true">Save & Continue Edit</button>
                </div>
              </div> -->
      </div>
    </div>
  </div>
</div>
