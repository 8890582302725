import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailAccountRecordBase {

//#region emailAccountId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'emailAccountId', keyColumn: true})
        emailAccountId : number;
//#endregion emailAccountId Prop


//#region email Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'email', keyColumn: false})
        email : string;
//#endregion email Prop


//#region displayName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayName', keyColumn: false})
        displayName : string;
//#endregion displayName Prop


//#region host Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'host', keyColumn: false})
        host : string;
//#endregion host Prop


//#region port Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'port', keyColumn: false})
        port : number;
//#endregion port Prop


//#region username Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'username', keyColumn: false})
        username : string;
//#endregion username Prop


//#region password Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'password', keyColumn: false})
        password : string;
//#endregion password Prop


//#region enableSSL Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'enableSSL', keyColumn: false})
        enableSSL : boolean;
//#endregion enableSSL Prop


//#region useDefaultCredentials Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'useDefaultCredentials', keyColumn: false})
        useDefaultCredentials : boolean;
//#endregion useDefaultCredentials Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}