import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const NOTEEDIT_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [{
        a: {
            isBind: (x) => {
                if(x.isEdit=="true"){
                return true;
                }
                else{
                return false;

                }
            },
          event: {
            click: "onNoteEdit"
          },
          childrens: [{
            i: { class: "fa fa-edit fa-4x font-md text-default".split(" ") }
          }],
          class: ["mr-2"],
          attributes: { "title": getLocalizedValue("Title_Edit") },
          style : {"line-height":"1.5rem"}
        }
      }],
      style: { "width": "100%", "text-align": "center"}
    }
  }
