import {ACLBase,CustomerActionBase,RoleBase,} from '@app/database-models'

export class ACL {
//Generated Imports
//#region customerAction Prop
        customerAction : CustomerActionBase;
//#endregion customerAction Prop

//#region role Prop
        role : RoleBase;
//#endregion role Prop

//#endregion Generated Reference Properties
}