import { prop, propObject, propArray, required, maxLength, range, notEmpty, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"
import { Time } from "@angular/common";


export class NewsBase {

        //#region newsId Prop
        @prop()
        newsId: number;
        //#endregion newsId Prop


        //#region languageId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        //@required()
        @prop()
        languageId: number;
        //#endregion languageId Prop


        //#region title Prop
        @required()
        @maxLength({ value: 1000 })
        @notEmpty()
        @trim()
        title: string;
        //#endregion title Prop


        //#region short Prop
        @maxLength({ value: 4000 })
        @required()
        @notEmpty()
        @trim()
        short: string;
        //#endregion short Prop


        //#region full Prop
        @prop()
        @required()
        @notEmpty()
        @trim()
        full: string;
        //#endregion full Prop


        //#region published Prop
        @prop({ defaultValue: true })
        published: boolean;
        //#endregion published Prop


        //#region allowComments Prop
        @prop({ defaultValue: true })
        allowComments: boolean;
        //#endregion allowComments Prop


        //#region createdOn Prop
        @required()
        createdOn: Date;
        //#endregion createdOn Prop


        //#region pictureId Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        //@required()
        @prop()
        pictureId: number;
        //#endregion pictureId Prop


        //#region establishedOn Prop
        @required()
        establishedOn: Date;
        //#endregion establishedOn Prop


        //#region newsType Prop
        @prop()
        @required()
        newsType: number;
        //#endregion newsType Prop


        //#region shortTitle Prop
        @required()
        @maxLength({ value: 255 })
        @notEmpty()
        @trim()
        shortTitle: string;
        //#endregion shortTitle Prop


        //#region videoPreference Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        //@required()
        @prop()
        videoPreference: number;
        //#endregion videoPreference Prop


        //#region video Prop
        @prop()
        video: string;
        //#endregion video Prop


        //#region pictureId1 Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        //@required()
        @prop()
        pictureId1: number;
        //#endregion pictureId1 Prop


        //#region sEName Prop
        @prop()
        sEName: string;
        //#endregion sEName Prop


        //#region altText Prop
        @maxLength({ value: 500 })
        @notEmpty()
        @trim()
        altText: string;
        //#endregion altText Prop

        //#region newsPublishedOnFaberge Prop
        @prop()
        newsPublishedOnFaberge?: any;
        //#endregion newsPublishedOnFaberge Prop

        //#region newsPublishedOnFabergeDate Prop
        @prop()
        newsPublishedOnFabergeDate?: any;
        //#endregion newsPublishedOnFabergeDate Prop

        //#region newsPublishedOnFabergeTime Prop
        @prop()
        newsPublishedOnFabergeTime?: any;
        //#endregion newsPublishedOnFabergeTime Prop

        //#region parentNewsId Prop
        @prop()
        parentNewsId: number;
        //#endregion parentNewsId Prop

        //#region parentNewsId Prop
        @prop({defaultValue:0})
        timezoneOffset: number;
        //#endregion parentNewsId Prop
        //#region parentNewsId Prop
        @prop({defaultValue:false})
        IsTimeSet: boolean;
        //#endregion parentNewsId Prop

}
