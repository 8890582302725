import { prop } from '@rxweb/reactive-form-validators';
import { gridColumn, actionColumn } from '@rxweb/grid';


// @actionColumn({
//    allowSorting: false,
//    style: { width: "2%" },
//    configuredTemplate: { templateName: 'priceBandAction' }, columnIndex: 7, headerTitle: "Action"
// })

export class  AllEventViewModel{

   @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'eventId', keyColumn: true })
   eventId:number
   @gridColumn({style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: false, headerKey: 'EventSearchComponent_MonthName_gh', keyColumn: false })
   eventMonth:string
   @gridColumn({
      template:{
         div:{
           attributes:{
             innerHTML:function(x,y){
              return this.fabergeSales;
             }
           },
         }
     }
      ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey: 'EventSearchComponent_FabergeEvent_gh', keyColumn: false })
   fabergeSales:string
   @gridColumn({ template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.fabergeMedia;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, headerKey:'EventSearchComponent_MediaFabergeEvent_gh', keyColumn: false })
   fabergeMedia:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.fabergeProducts;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: false, headerKey: 'EventSearchComponent_ProductVariantEvent_gh', keyColumn: false })
   fabergeProducts:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.relevantSector;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey: 'EventSearchComponent_NonFabergeEvent_gh', keyColumn: false })
   relevantSector:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.gemfieldsMedia;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: false,headerKey:'EventSearchComponent_GemfieldsMediaEvent_gh', keyColumn: false })
   gemfieldsMedia:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.gemfieldsEvent;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: false, headerKey:'EventSearchComponent_GemfieldsEvent_gh', keyColumn: false })
   gemfieldsEvent:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.fabergeWholesale;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: false, headerKey: 'EventSearchComponent_Wholesale_gh', keyColumn: false })
   fabergeWholesale:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.training;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: false, headerKey: 'EventSearchComponent_Training_gh', keyColumn: false })
   training:string
   @gridColumn({template:{
      div:{
        attributes:{
          innerHTML:function(x,y){
           return this.boardMeetings;
          }
        },
      }
  }
   ,style: { width: "10%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: false, headerKey:'EventSearchComponent_BoardMeetings_gh', keyColumn: false })
   boardMeetings:string

   totalCount:number;

   isRestrictedEvent:any;
   end:string;
   start:string;
   title:string;

}
