import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIANTypeLookUpBase {

//#region iANTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'iANTypeId', keyColumn: true})
        iANTypeId : number;
//#endregion iANTypeId Prop


//#region iANTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'iANTypeName', keyColumn: false})
        iANTypeName : string;
//#endregion iANTypeName Prop

}