import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { AbstractProject } from '../domain/abstract-project';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';

import { Project, vPersonCorporateMapping } from '@app/models';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { PlatformLocation } from '@angular/common';
import { NotesEnum } from 'src/app/enums/notes.enum';
import { SafeUrl, DomSanitizer, Title } from '@angular/platform-browser';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { multilingual, MultiLingualData } from '@rxweb/localization';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { AllProjectListViewModel } from "src/app/view-model/all-project-list-view-model";
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { getBlobImgURL } from "src/app/domain/system-constant";
@multilingual("ProjectEditComponent")
@access({ accessLevel: RolePermissionEnums.Project, action: "put" })

@Component({
    selector: "app-project-edit",
    templateUrl: './project-edit.component.html'
})
export class ProjectEditComponent extends AbstractProject implements OnInit, OnDestroy {
    project: Project;
    subscription: any;
    id: number;
    router: Router;
    entityTypeId: number = NotesEnum.ProjectNotes;
    dateError: boolean;
    isSubProject: boolean = false;
    isRestrictedProject: boolean = false;
    projectName: string;
    entityId: number;
    isshowSubprojecttab: boolean = true;
    subprojectid: number;
    parentProject: Project;
    projectDelete: any = dbAccessModule[RolePermissionEnums.Project]["delete"];
    projectIndicatorError: boolean;
    projectCorporateMappingList: vPersonCorporateMapping[];
    loggedInUser: number;
    prevProjectId: number = 0;
    showTab: boolean = false;
    allProjectList: Array<AllProjectListViewModel>;
    displayProjectList: boolean = false;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: any;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    imageTooltip: string;
    noticeData: any;
    ProfileImageTag:string = "";
    titleMindMap: string;
    labelMindMap: string;
    titleClose: string = "";
    searchProjectName: string = "";
    isValidParent: boolean = true;
    isValidParentErr: boolean = false;
    validParentErrorList: string[];
    titleOpen: string;
    titleClosed: string;
    isExpandAssignedByTask: boolean = true;
    isExpandAssignedToTask: boolean = false;
    _this;
    showParentProject:boolean = true;
    scrolledtoDiv:number = 0;

    constructor(location: PlatformLocation, private formBuilder: RxFormBuilder, private browserStorage: BrowserStorage, private activatedRoute: ActivatedRoute, router: Router, modelView: ModalView, private sanitizer: DomSanitizer, private applicationBroadcaster: ApplicationBroadcaster, private el: ElementRef, private title: Title, blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.modalView = modelView;
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.activatedRoute.queryParams.subscribe(t => {
            if (t['activeTab']) {
                this.selectedTab = t['activeTab'];
            }
        })
        this.activatedRoute.queryParams.subscribe(t => {
            if (t['tabChange']) {
                this.tabChange = t['tabChange'];
            }
        })
        if (this.browserStorage.local.get("userId") != null) {
            this.loggedInUser = this.browserStorage.local.get("userId");
        } else {
            this.loggedInUser = 0;
        }
        this.router = router;
        location.onPopState(() => {
            this.hidemodal();
            this.dialog.hide();
            setTimeout(() => {
                let id: number = 0;
                this.activatedRoute.params.subscribe(t => {
                    id = t['id'];
                })
                if (id != this.prevProjectId && this.prevProjectId != 0) {
                    this.id = id;
                    this.entityId = this.id;
                    this.showComponent = true;
                    this.bindForm();
                }
            }, 0);
        });
        this.applicationBroadcaster.activeMenu({ activeMenu: 'projects', subMenu: '' })
        this.moduleID = RolePermissionEnums.Project;
        this._this = this;
    }

    ngOnInit(): void {
        this.imageTooltip = getLocalizedValue("ProjectAddEditComponent_ImageUploadTooltip_t");
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("Projects - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleMindMap = getLocalizedValue("Title_Mind_Map");
        this.labelMindMap = getLocalizedValue("Visual_Mind_Map_t");
        this.titleClose = getLocalizedValue("Title_Close")
        this.entityId = this.id;
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
        this.bindForm();
        this.noteData()
    }

    ngAfterViewChecked(): void {  
        if(this.scrolledtoDiv < 2){
        const scrollToDiv = document.getElementById('scrollToDiv');
            if (scrollToDiv) {
                scrollToDiv.scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"});
                this.scrolledtoDiv = this.scrolledtoDiv+1;  
            }
        }
    }

    noteData(){
        var localizationData = (MultiLingualData.get(this.componentName) as any);
        this.noticeData=localizationData.ProjectEditComponent_Notice_gn_gn;
    }
    hidemodal(){
        this.isValidParent = true;
        document.getElementById('change-parent-modal-close-btn').click();
    }
    bindForm() {
        this.prevProjectId = this.id;
        this.spin = true;
        this.lookup([{ params: [this.id], propName: "project" },
         { path: USER_LOOKUPS["userLookUp"], propName: "projectOwnerLookUp" },
        //  { path: USER_LOOKUPS["projectOwnerLookUp"], propName: "projectOwnerLookUp" },
        { path: USER_LOOKUPS.genfieldsGroupEntity, propName: "genfieldsGroupEntity" },
        { path: USER_LOOKUPS["departmentLookUp"], propName: "departmentLookUp" },
          { path: USER_LOOKUPS["projectTypeLookUp"], propName: "projectTypeLookUp" }]).subscribe(response => {
            this.projectLookup = response;
            if (this.projectLookup.project == null || this.projectLookup.project == undefined) {

                var existsAlert = this.localizedMessages.filter(x => x.keyName == "NoRecordFound_Error");
                if (existsAlert) {
                    // this.toastr.error("No Record Found");
                    this.toastr.error(existsAlert[0].showMessage);
                }
                this.router.navigate(['/projects']);
            } else {
                this.checkRestrictedProject();
                this.bindProjectList();
            }
        });
    }
    transformYourHtml(htmlTextWithStyle) {
        return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
      }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }

    checkRestrictedProject() {
        if (this.projectLookup.project.isRestrictedProject) {
            this.subscription = this.get({
                path: "api/ProjectCorporateMappings",
                queryParams: {
                    projectId: this.id
                }
            }).subscribe((t: any) => {
                this.spin = false;
                this.projectCorporateMappingList = t;
                if (this.projectCorporateMappingList.length > 0) {
                    if (this.projectCorporateMappingList.findIndex(x => x.customerID == this.loggedInUser) == -1 && this.loggedInUser != this.projectLookup.project.projectOwner) {
                        this.router.navigate(["projects/restricted-project", this.id]);
                    } else {
                        if (!this.tabChange) {
                            this.router.navigate(['/projects', this.id], { queryParams: { activeTab: "project-edit" } });
                        } else {
                            this.tabChange = false;
                        }
                        this.notRestrictedProject();
                        this.bindGanttChar();
                    }
                } else {
                    if (this.loggedInUser != this.projectLookup.project.projectOwner) {
                        this.router.navigate(["projects/restricted-project", this.id]);
                    } else {
                        if (!this.tabChange) {
                            this.router.navigate(['/projects', this.id], { queryParams: { activeTab: "project-edit" } });
                        } else {
                            this.tabChange = false;
                        }
                        this.notRestrictedProject();
                        this.bindGanttChar();
                    }
                }
            });
        }
        else {
            if (!this.tabChange) {
                this.router.navigate(['/projects', this.id], { queryParams: { activeTab: "project-edit" } });
            } else {
                this.tabChange = false;
            }
            this.notRestrictedProject();
            this.bindGanttChar();
        }
    }

      /**
   * This method change the sate of flag to show or hide Assigned By Tasks
   *  */
  expandAssignedByTask() {
    this.isExpandAssignedByTask = !this.isExpandAssignedByTask;
  }

  /**
   * This method change the sate of flag to show or hide Assigned To Tasks
   *  */
  expandAssignedToTask() {
    this.isExpandAssignedToTask = !this.isExpandAssignedToTask;
  }

    notRestrictedProject() {
        this.showTab = false;
        if (this.projectLookup.project.imageURL1 == null || this.projectLookup.project.imageURL1 == undefined || this.projectLookup.project.imageURL1 == "") {
            this.projectLookup.project.displayImage = false;
        } else {
            this.projectLookup.project.displayImage = true;
        }
        if (this.projectFormGroup) {
            this.projectFormGroup.patchValue(this.projectLookup.project);
        } else {
            this.projectFormGroup = this.formBuilder.formGroup(Project, this.projectLookup.project) as IFormGroup<Project>;
        }

        // if (this.projectFormGroup.controls.filePath.value != null) {
        //     var replacePercentage = this.projectFormGroup.value.filePath.replace('%', '%25')
        //     var replaceHashtag = replacePercentage.replace('#', '%23');
        //     this.projectFormGroup.controls.filePath.setValue(replaceHashtag);
        // }

        this.projectName = this.projectLookup.project.projectName;
        this.isRestrictedProject = this.projectLookup.project.isRestrictedProject;
        this.isSubProject = this.projectLookup.project.parentID == 0 ? false : true;
        if (this.isSubProject) {
            this.subprojectid = this.projectLookup.project.parentID;
            this.get({ params: [this.subprojectid] }).subscribe((data: Project) => {
                if (data.parentID != 0) {
                    this.get({ params: [data.parentID] }).subscribe((x: Project) => {
                        //console.log(x);
                        if (x.parentID != 0) {
                            this.isshowSubprojecttab = false;
                        }
                        else {
                            this.isshowSubprojecttab = true;
                        }
                    });
                }
                else {
                    this.isshowSubprojecttab = true;
                }
            })
        }
        this.spin = false;
        this.showComponent = true;
        this.showTab = true;

        if(this.projectFormGroup.value.imageURL1!=null){
            if(!this.projectFormGroup.value.imageURL1.includes("default_image_560.png")) {
                this.ProfileImageTag =  '<img src="' + this.projectFormGroup.value.imageURL1 +'" width="95" height="95" style="border-radius:50%" />'
            }
        }

    }

    chekcValidation() {
        if (this.projectFormGroup.value.projectIndicator < 0 || this.projectFormGroup.value.projectIndicator > 100) {
            this.projectIndicatorError = true;
        } else {
            this.projectIndicatorError = false;
        }
    }

    async updateProject(isRedirect: boolean) {
        this.projectFormGroup.submitted = true;
        if (this.projectFormGroup.value.projectIndicator == null || this.projectFormGroup.value.projectIndicator == undefined || this.projectFormGroup.value.projectIndicator.toString() == "") {
            this.projectFormGroup.value.projectIndicator = 0;
        }
        if (this.projectFormGroup.value.actualCost == null || this.projectFormGroup.value.actualCost == undefined || this.projectFormGroup.value.actualCost.toString() == "") {
            this.projectFormGroup.value.actualCost = 0;
        }
        if (dateCompareValidation(this.projectFormGroup.value.projectStartDate, this.projectFormGroup.value.projectEndDate) == true) {
            this.dateError = true;
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.projectFormGroup.controls[x].invalid && !this.breakLoop) {
                    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                    invalidControl.focus();
                    this.breakLoop = true;
                }
            });
        }
        else {
            if (this.projectFormGroup.valid && !this.projectIndicatorError) {
                this.dateError = false;
                this.spin = true;
                if (this.projectFormGroup.value.fileData) {

                    let res = await this.uploadToBlob(this.fileOutputViewModel, this.projectFormGroup.value.blobPath);
                    this.projectFormGroup.patchValue({
                      fileData: null,
                      blobFileName: res
                    });
                  }
                if (this.projectFormGroup.value.fileData1) {

                    let res = await this.uploadToBlob(this.fileOutputViewModelDoc, this.projectFormGroup.value.blobPath2);
                    this.projectFormGroup.patchValue({
                      fileData1: null,
                      blobFileName2: res
                    });
                  }
                this.projectIndicatorError = false;
                if (this.projectFormGroup.value.projectIndicator == 0) {
                    this.projectFormGroup.patchValue({ projectIndicator: 0 });
                }
                if (this.projectFormGroup.value.actualCost == 0) {
                    this.projectFormGroup.patchValue({ actualCost: 0 });
                }
                if (this.projectFormGroup.value.projectOwner == null || this.projectFormGroup.value.projectOwner == undefined || this.projectFormGroup.value.projectOwner.toString() == "") {
                    this.projectFormGroup.value.projectOwner = 0;
                }
                this.put({ body: this.projectFormGroup.value, params: [this.id] }).subscribe(data => {
                    this.spin = false;

                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
                        // this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    if (isRedirect) {
                        this.router.navigate(['/projects']);
                    } else {
                        this.hidemodal();                        
                        this.showComponent = false;
                        this.bindForm();
                    }
                });
            } else {
                this.breakLoop = false;
                this.validField.forEach(x => {
                    if (this.projectFormGroup.controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                });
            }
        }
    }

    deleteProject() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
                        // this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/projects']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Project");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    getImgContent(): SafeUrl {
        let imgUrl = "data:" + this.projectFormGroup.controls.fileExtention.value + ";base64," + this.projectFormGroup.controls.fileData.value;
        return this.sanitizer.bypassSecurityTrustUrl(imgUrl);
    }

    onProjectIdChange(obj: any) {
        if (obj.subStreamType == "PRJ") {
            this.id = obj.id;
            this.showComponent = false;
            this.bindForm();
        }

        if (obj.subStreamType == "DES") {
            this.router.navigate(['/product-design', obj.id], { queryParams: { activeTab: "design-info" } });
        }
    }

    backToProject() {
        this.id = this.projectLookup.project.parentID;
        if (this.id > 0) {
            this.showComponent = false;
            this.bindForm();
        } else {
            this.router.navigate(['/projects']);
        }
    }

    bindProjectList() {
        this.spin = true;
        var json = JSON.parse(JSON.stringify({ projectId: this.id }));
        this.subscription = this.get({
            path: "api/SearchProject/GetAllSubProjectsList",
            queryParams: { "query": encodeURIComponent(JSON.stringify(json)) }
        }).subscribe((t: any) => {
            this.spin = false;
            this.allProjectList = new Array<AllProjectListViewModel>();
            this.allProjectList = t;
            if (this.allProjectList[0].subProjects.length > 0) {
                this.displayProjectList = true;
            }
        });
    }

    backToProjectList(){
        this.router.navigate(['/projects']);
    }
    onProjectNameClick($event) {
        if(this.projectLookup.project.parentID == $event.item.key || this.projectFormGroup.value.projectID == $event.item.key || $event.text == "" ){
            this.isValidParent = false;
            this.projectFormGroup.patchValue({
                parentID: this.projectLookup.project.parentID
            })
        }
        else{
            this.projectFormGroup.patchValue({
                parentID: $event.item.key
            })
            this.isValidParent = true;
        }
    }

    checkProjectNameText(isClose:boolean = false) {
        this.projectFormGroup.patchValue({
            parentID: this.projectLookup.project.parentID
        })
        this.isValidParent =  false;
        this.isValidParentErr = false;

        if(isClose){
            this.showParentProject = false;
            setTimeout(function(){
                this.showParentProject = true;
                this.searchProjectName = '';
                this.isValidParent =  true;
            }.bind(this), 50);
        }
    }

    moveproject(){
        if(this.projectLookup.project.parentID != this.projectFormGroup.value.parentID){
            var json = {
                projectId: this.projectFormGroup.value.projectID, 
                parentId :this.projectFormGroup.value.parentID}
            
            this.subscription = this.get({
                path: 'api/SearchProject/MoveProjectToAnotherProject',
                queryParams: { Query: encodeURIComponent(JSON.stringify(json)) }
            }).subscribe((t: any) => {
                
                if(t.length >0){
                    this.isValidParentErr = true;
                    this.validParentErrorList = t;
                }
                else{
                    this.hidemodal();                        
                    this.showComponent = false;
                    this.bindForm();
                }
                
            }); 
        }
        else{
            this.isValidParent = false;
        }
    }
    get componentName(): string {
        return "ProjectEditComponent";
    }
}
