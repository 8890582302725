import {ProductGemstoneCutMappingBase,GemStoneCutTypeBase,ProductBase,} from '@app/database-models'
//Generated Imports
export class ProductGemstoneCutMapping extends ProductGemstoneCutMappingBase 
{




//#region Generated Reference Properties
//#region gemstoneCut Prop
gemstoneCut : GemStoneCutTypeBase;
//#endregion gemstoneCut Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties











}