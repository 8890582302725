import { http, RxHttp } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { RiskMenuDetail, vBoardRiskOwnerLookUp, vGemfieldGroupCompanyLookUp, vLocalRiskOwnerLookUp, vRiskCategoryLookUp, vSpecificRiskLookUp } from '@app/models';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { Router } from "@angular/router";
import { BlobUploadsViewStateService } from 'src/app/temp-service/blob-uploads-view-state.service';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
@http({
    path: "api/RiskMenuDetails",
})

export class AbstractRiskMenuDetail extends RxHttp {
    riskMenuDetailFormGroup: IFormGroup<RiskMenuDetail>;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    showComponent: boolean = false;
    isFilter: boolean = false;
    isRiskAddFilter: boolean = false;
    isShowGrid : boolean;
    isShowAddRiskGrid: boolean;
    showAddRiskGrid: boolean = false;
    showGrid: boolean = false;
    isNoRecordFound:boolean = false;
    isNoRiskAddRecordFound: boolean = false;
    totalRecords: number = 0;
    totalAddRiskRecords: number = 0;
    id:number = 0;
    router: Router;
    gemfieldRiskSearchLookUp: any = {
        gemfieldGroupCompany: vGemfieldGroupCompanyLookUp,
        riskCategory: vRiskCategoryLookUp,
        specificRisk: vSpecificRiskLookUp,
        localRiskOwner: vLocalRiskOwnerLookUp,
        boardRiskOwner: vBoardRiskOwnerLookUp
    }
    selectedTab: string = "risk-edit"
    constructor(private blobState: BlobUploadsViewStateService) {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        //this.router = router;
        // this.activatedRoute.params.subscribe(t => {
        //     this.id = t['id'];
        //})
    }
    async uploadExcelToBlob(file: any, fileExtention: string): Promise<string> {

        let fileUploadRes = [];
        let newGUID = uuidv4();
        let fileName = BLOB_BASE.ExcelSheet + `${newGUID}_${new Date().toISOString()}.${fileExtention}`;
        var res = await this.blobState.uploadItems(file, fileName);
    
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.ExcelSheet + decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
    
        // IF SUCCEED TO UPLOAD
        if (res && (res._response.status == 200 || res._response.status == 201)) {
          if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.toastr.error(
            "Error occured while uploading file! Please try again."
          );
          if (uploadedFileName != undefined) {
            this.modalView
              .show(BedRequestComponent, {
                validationMessages: JSON.stringify(
                  uploadedFileName
                ),
                headerMessage: "Image failed to upload:",
              })
              .then(() => { });
          }
        }
        return uploadedFileName;
      }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }
    activeTab(tabName: string) {
        this.selectedTab = tabName;
        this.router.navigate(["gemfield-risk-menu", this.id], { queryParams: { activeTab: tabName } });
      }
}
