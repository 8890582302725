import { gridColumn } from '@rxweb/grid';

export class ProjectRiskListViewModel {

  //#region riskMenuDetailId Prop
  @gridColumn({ style: { width: "5%", "text-align": "left" },parameter:true, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'ProjectRiskListComponent_RiskId_gh', keyColumn: true, configuredTemplate: { templateName: "reDirectRiskMenuDetail" } })
  riskMenuDetailId: number;
  //#endregion riskMenuDetailId Prop

  //#region company Prop
  @gridColumn({ configuredTemplate: { templateName: 'corporateGroupLink' }, style: { width: "1%", "min-width": "160px", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'ProjectRiskListComponent_GemfieldsGroupCompany_gh', keyColumn: false })
  company: string;
  //#endregion company Prop
  companyId:any;

  //#region createdOn Prop
  @gridColumn({ style: { width: "7%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'ProjectRiskListComponent_CreatedDate_gh', keyColumn: false })
  createdOn: string;
  //#endregion createdOn Prop

  //#region riskCategoryId Prop
  @gridColumn({ visible: false, columnIndex: 3, keyColumn: false })
  riskCategoryId: number;
  //#endregion riskCategoryId Prop

  //#region riskCategoryName Prop
  @gridColumn({ style: { width: "9%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'ProjectRiskListComponent_RiskCategory_gh', keyColumn: false })
  riskCategoryName: string;
  //#endregion riskCategoryName Prop

  //#region specificRiskId Prop
  @gridColumn({ visible: false, columnIndex: 5, keyColumn: false })
  specificRiskId: number;
  //#endregion specificRiskId Prop

  //#region specificRiskName Prop
  @gridColumn({ style: { width: "15%", "min-width": "160px", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 6, allowSorting: true, headerKey: 'ProjectRiskListComponent_SpecificRiskName_gh', keyColumn: false })
  specificRiskName: string;
  //#endregion specificRiskName Prop

  //#region isRelevant Prop
  @gridColumn({ style: { width: "10%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 7, allowSorting: true, headerKey: 'ProjectRiskListComponent_IsRelevant_gh', keyColumn: false, configuredTemplate: { templateName: "recordActive" } })
  isRelevant: boolean;
  //#endregion isRelevant Prop

  //#region likelihood Prop
  @gridColumn({ style: { width: "5%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 8, allowSorting: true, headerKey: 'ProjectRiskListComponent_Likelihood_gh', keyColumn: false })
  likelihood: number;
  //#endregion likelihood Prop

  //#region impact Prop
  @gridColumn({ style: { width: "5%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 9, allowSorting: true, headerKey: 'ProjectRiskListComponent_Impact_gh', keyColumn: false })
  impact: number;
  //#endregion impact Prop

  //#region riskIndex Prop
  @gridColumn({ style: { width: "7%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 10, allowSorting: true, headerKey: 'ProjectRiskListComponent_RiskIndex_gh', keyColumn: false, configuredTemplate: { templateName: "gemfieldRiskIndex" } })
  riskIndex: number;
  //#endregion riskIndex Prop

  //#region localRiskOwnerName Prop
  @gridColumn({ style: { width: "10%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 11, allowSorting: true, headerKey: 'ProjectRiskListComponent_LocalRiskOwner_gh', keyColumn: false })
  localRiskOwnerName: string;
  //#endregion localRiskOwnerName Prop

  //#region boardRiskOwnerName Prop
  @gridColumn({ style: { width: "10%", "text-align": "left" },parameter:false, class: ["text-left"], visible: true, columnIndex: 12, allowSorting: true, headerKey: 'ProjectRiskListComponent_BoardRiskOwner_gh', keyColumn: false })
  boardRiskOwnerName: string;
  //#endregion boardRiskOwnerName Prop

  //#region noteId Prop
  @gridColumn({ visible: false, columnIndex: 13, keyColumn: false })
  noteId: number;
  //#endregion noteId Prop

  //#region noteDescription Prop
  @gridColumn({
    style: { width: "18%", "min-width": "300px", "text-align": "left" },
    template: {
      div: {
        style: {
          "word-break": "break-all;",
          // "display": "-webkit-box",
          // "-webkit-line-clamp": "3",
          // "-webkit-box-orient": "vertical",
          // "overflow": "hidden",
          // "text-overflow": "ellipsis"
        },
        attributes: {
          innerHTML: function (x, y) {
            return this.noteDescription;
          }
        },
        childrens: [{
          a: {
              isBind: (x) => {
                  if (x.noteCharCount > 100 && !x.isShowAll) {
                      return true;
                  }
                  else {
                      return false;
                  }
              }, style: {
                  "display": "block"
              },
              childrens: [{
                  span: {
                      class: "more-btn mr-2".split(" "),
                      childrens: [{
                          text: {
                              text: function (e) {
                                  return "Show More";
                              }
                          }
                      }]
                  }
              }],
              event: {
                  click: "onMoreClick"
              }
          },
      }]
      }
    }, visible: true, columnIndex: 14, allowSorting: false,parameter:false,class: ["text-left"], headerKey: 'ProjectRiskListComponent_ManualNote_gh', keyColumn: false, name:'noteDescription'
  })
  noteDescription: string;
  //#endregion noteDescription Prop

  // @gridColumn({
  //   style: { width: "18%", "text-align": "left" },
  //   template: {
  //     div: {
  //       style: {
  //         "word-break": "break-all;",
  //         // "display": "-webkit-box",
  //         // "-webkit-line-clamp": "3",
  //         // "-webkit-box-orient": "vertical",
  //         // "overflow": "hidden",
  //         // "text-overflow": "ellipsis"
  //       },
  //       attributes: {
  //         innerHTML: function (x, y) {
  //           return this.noteDescription1;
  //         }
  //       }
  //     }
  //   }, visible: false, columnIndex: 14, allowSorting: false,parameter:true,class: ["text-left"], headerKey: 'ProjectRiskListComponent_ManualNote_gh', keyColumn: false, name:'noteDescription1'
  // })
  // noteDescription1: string;
  noteCharCount:any;
  //#region riskCauses Prop
  @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
  riskCauses: string;
  //#endregion riskCauses Prop

  //#region riskConsequences Prop
  @gridColumn({ visible: false, columnIndex: 16, keyColumn: false })
  riskConsequences: string;
  //#endregion riskConsequences Prop

  //#region totalCount Prop
  @gridColumn({ visible: false, columnIndex: 17, keyColumn: false })
  totalCount: number;
  //#endregion totalCount Prop

  //#region projectRiskMappingId Prop
  @gridColumn({ visible: false, columnIndex: 18, keyColumn: true })
  projectRiskMappingId: number;
  //#endregion projectRiskMappingId Prop

  //#region projectId Prop
  @gridColumn({ visible: false, columnIndex: 19, keyColumn: false })
  projectId: number;
  //#endregion projectId Prop
  @gridColumn({
    visible: true,
    name:"deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 20, headerTitle: "Delete"
  })
  deleteAction:any;

  companyIntroducesBy:any;
  companyContactPermissionRequired: boolean;
}
