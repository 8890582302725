import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vBoutiqueBase {

//#region boutiqueId Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex:0, allowSorting: true, headerKey: 'BoutiqueListComponent_Id_gh', keyColumn: true})
        boutiqueId : number;
//#endregion boutiqueId Prop


//#region boutiqueName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'BoutiqueListComponent_Name_gh', keyColumn: false})
        boutiqueName : string;
//#endregion boutiqueName Prop


//#region boutiqueAddress Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'BoutiqueListComponent_Address_gh', keyColumn: false})
        boutiqueAddress : string;
//#endregion boutiqueAddress Prop


//#region boutiqueOpeningHours Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerKey: 'BoutiqueListComponent_OpeningHours_gh', keyColumn: false})
        boutiqueOpeningHours : string;
//#endregion boutiqueOpeningHours Prop


//#region phoneNumber Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerKey: 'BoutiqueListComponent_PhoneNo_gh', keyColumn: false})
        phoneNumber : string;
//#endregion phoneNumber Prop


//#region email Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:5, allowSorting: true, headerKey: 'BoutiqueListComponent_Email_gh', keyColumn: false})
        email : string;
//#endregion email Prop


//#region description Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'BoutiqueListComponent_Description_gh', keyColumn: false})
        description : string;
//#endregion description Prop


//#region latitude Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'BoutiqueListComponent_Latitude_gh', keyColumn: false})
        latitude : string;
//#endregion latitude Prop


//#region longitude Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'BoutiqueListComponent_Longitude_gh', keyColumn: false})
        longitude : string;
//#endregion longitude Prop


//#region boutiqueManager Prop
        @gridColumn({visible: false, columnIndex:9, allowSorting: true, headerKey: 'BoutiqueListComponent_BoutiqManager_gh', keyColumn: false})
        boutiqueManager : string;
//#endregion boutiqueManager Prop


//#region isFaberge Prop
        @gridColumn({visible: false, columnIndex:10, allowSorting: true, headerKey: 'BoutiqueListComponent_IsFaberge_gh', keyColumn: false})
        isFaberge : boolean;
//#endregion isFaberge Prop


//#region cityName Prop
        @gridColumn({visible: false, columnIndex:11, allowSorting: true, headerKey: 'BoutiqueListComponent_City_gh', keyColumn: false})
        cityName : string;
//#endregion cityName Prop


//#region seName Prop
        @gridColumn({visible: false, columnIndex:12, allowSorting: true, headerKey: 'BoutiqueListComponent_SEName_gh', keyColumn: false})
        seName : string;
//#endregion seName Prop


//#region metaTitleCountry Prop
        @gridColumn({visible: false, columnIndex:13, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaTitleCountry_gh', keyColumn: false})
        metaTitleCountry : string;
//#endregion metaTitleCountry Prop


//#region metaDescriptionCountry Prop
        @gridColumn({visible: false, columnIndex:14, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaDescCountry_gh', keyColumn: false})
        metaDescriptionCountry : string;
//#endregion metaDescriptionCountry Prop


//#region metaKeywordsCountry Prop
        @gridColumn({visible: false, columnIndex:15, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaKeywordCountry_gh', keyColumn: false})
        metaKeywordsCountry : string;
//#endregion metaKeywordsCountry Prop


//#region metaTitleCity Prop
        @gridColumn({visible: false, columnIndex:16, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaTitleCity_gh', keyColumn: false})
        metaTitleCity : string;
//#endregion metaTitleCity Prop


//#region metaDescriptionCity Prop
        @gridColumn({visible: false, columnIndex:17, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaDescCity_gh', keyColumn: false})
        metaDescriptionCity : string;
//#endregion metaDescriptionCity Prop


//#region metaKeywordsCity Prop
        @gridColumn({visible: false, columnIndex:18, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaKeywordCity_gh', keyColumn: false})
        metaKeywordsCity : string;
//#endregion metaKeywordsCity Prop


//#region metaTitleBoutique Prop
        @gridColumn({visible: false, columnIndex:19, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaTitleBoutique_gh', keyColumn: false})
        metaTitleBoutique : string;
//#endregion metaTitleBoutique Prop


//#region metaKeywordsBoutique Prop
        @gridColumn({visible: false, columnIndex:20, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaKeywordBoutique_gh', keyColumn: false})
        metaKeywordsBoutique : string;
//#endregion metaKeywordsBoutique Prop


//#region metaDescriptionBoutique Prop
        @gridColumn({visible: false, columnIndex:21, allowSorting: true, headerKey: 'BoutiqueListComponent_MetaDescBoutique_gh', keyColumn: false})
        metaDescriptionBoutique : string;
//#endregion metaDescriptionBoutique Prop

}