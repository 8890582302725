import {LookupLostTimeInjuryBase,IncidentPersonsAffectedBase,} from '@app/database-models'
//Generated Imports
export class LookupLostTimeInjury extends LookupLostTimeInjuryBase 
{




//#region Generated Reference Properties
//#region incidentPersonsAffected Prop
incidentPersonsAffected : IncidentPersonsAffectedBase[];
//#endregion incidentPersonsAffected Prop

//#endregion Generated Reference Properties






































}