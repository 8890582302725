import {vSystemInformationBase,} from '@app/database-models'
//Generated Imports
export class vSystemInformation extends vSystemInformationBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}