import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLocationGemfieldsLookUpBase {

//#region locationId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'locationId', keyColumn: true})
        locationId : number;
//#endregion locationId Prop


//#region locationName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'locationName', keyColumn: false})
        locationName : string;
//#endregion locationName Prop

}