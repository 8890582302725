import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemfieldsStationBase {

//#region stationId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemfieldsStationListComponent_StationId_gh', keyColumn: true })
        stationId : number;
//#endregion stationId Prop


//#region stationName Prop
        //@gridColumn({ isAscending:true,style: { width: "90%", "text-align": "initial" }, visible: true, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsStationListComponent_StationName_gh', keyColumn: false })
        @gridColumn({ isAscending:true,style: { width: "50%", "text-align": "initial" }, visible: true, class: ["text-left"], columnIndex: 1, allowSorting: true, headerKey: 'GemfieldsStationListComponent_StationName_gh', keyColumn: false })
        stationName : string;
//#endregion stationName Prop

}