import {vLeaveTypeBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
@actionColumn({
    visible:false,
    name:"action",
    allowSorting: false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 2, headerTitle: "Action"
})
export class vLeaveType extends vLeaveTypeBase 
{


    @gridColumn({
        visible:false,
        name:"editAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'edit' }, columnIndex: 3, headerTitle: "Edit"
    })
    editAction:any;
    
    @gridColumn({
        visible:false,
        name:"deleteAction",
        allowSorting: false,
        style: { width: "1%" },
        configuredTemplate: { templateName: 'delete' }, columnIndex: 4, headerTitle: "Delete"
    })
    deleteAction:any;

//#region Generated Reference Properties

//#endregion Generated Reference Properties










}