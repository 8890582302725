<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="ProjectAddComponent_Add_Title_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/projects']" rxText="Label_Projects_t"></a>
      </li>
      <li class="breadcrumb-item active">Add New</li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content " [rxSpinner]="spin">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3" [rxLocalisationInit]="componentName">
      <h5 rxText="ProjectAddComponent_Title_t">
      </h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="projectFormGroup" *ngIf="showComponent" [rxLocalisationInit]="componentName">
      <div class="row">
        <div class="col-lg-4 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectName_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="projectName" tabindex="1"
                rxPlaceholder="ProjectAddEditComponent_ProjectName_p" rxFocus>
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.projectName.errors">{{projectFormGroup.controls.projectName.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectDescription_t"></label>
            <div class="col-md-8">
              <textarea class="form-control" formControlName="projectDescription" tabindex="4"
                rxPlaceholder="ProjectAddEditComponent_ProjectDescription_p" rows="4"></textarea>
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.projectDescription.errors">{{projectFormGroup.controls.projectDescription.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectPicture_t"></label>
            <div class="col-md-8">
              <app-file [fileViewModel]="fileViewModel" [tabindex]="'5'" (files)="updateFiles($event)" [title] = "imageTooltip">
              </app-file>
              <div style="margin-top: 0.5em;">
              <img *ngIf="projectFormGroup.value.displayImage && projectFormGroup.value.fileData==null"
                src="{{projectFormGroup.value.imageURL1}}" class="event-img upload-img-preview mt-2" width="100"
                height="100" />
              <img *ngIf="projectFormGroup.value.fileData!=null" [src]="getImgContent()"
                class="event-img mt-2 upload-img-preview" width="100" height="100" />
              <ng-container *ngIf="projectFormGroup.value.displayImage || projectFormGroup.value.fileData!=null" [rxLocalisationInit]="componentName">
                <button
                  (click)="removeImage()" class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
              </ng-container>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectStartDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="projectStartDate" [controlTabIndex]="7"  placeholder="Select Start Date"
                rxPlaceholder="projectStartDatePH" pickerClass="form-control">
              </rx-date>
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.projectStartDate.errors">{{projectFormGroup.controls.projectStartDate.errorMessage}}</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectEndDate_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="projectEndDate" placeholder="Select End Date"
                [controlTabIndex]="10" pickerClass="form-control">
              </rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">Project End Date should be
                greater than Project Start Date<br /></small>
            </div>
          </div>
        <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label" rxText="ProjectAddEditComponent_Budget_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="budget" tabindex="3"
                rxPlaceholder="ProjectAddEditComponent_ProjectBudget_p">
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.budget.errors">{{projectFormGroup.controls.budget.errorMessage}}</small>
            </div>
        </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ActualCost_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" tabindex="6" formControlName="actualCost"
                rxPlaceholder="ProjectAddEditComponent_ProjectActualCost_p">
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.actualCost.errors">{{projectFormGroup.controls.actualCost.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_BudgetCostCertificate_t"></label>
            <div class="col-md-8">
              <app-file [fileViewModel]="fileViewModelDoc" [tabindex]="'8'" (files)="updateDocFiles($event)">
              </app-file>
              <div style="margin-top: 0.5em;">
                <label class="mt-1">{{projectFormGroup.value.fileName1}}</label>
                <ng-container [rxLocalisationInit]="componentName" *ngIf="projectFormGroup.value.fileName1 != null" >
                  <button class="btn btn-danger ml-2" rxText="Btn_Remove_t" (click)="removeDoc()"></button>
                </ng-container>
              </div>
            </div>
          </div>


        </div>
        <div class="col-lg-4 col-xl-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectIndicator_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="projectIndicator" tabindex="11"
                (keyup)="chekcValidation()" rxPlaceholder="ProjectAddEditComponent_ProjectProgress_p">
              <small class="form-text text-danger" *ngIf="projectIndicatorError==true">You need to enter
                appropriate value in this field</small>
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.projectIndicator.errors">{{projectFormGroup.controls.projectIndicator.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectOwner_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectLookup.projectOwnerLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="2"
                formControlName="projectOwner" [selectPlaceholder]="'Select Owner'"
                [keyValueProps]="['fullName','userId']">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="projectFormGroup.controls.projectOwner.errors">{{projectFormGroup.controls.projectOwner.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_ProjectType_t"></label>

            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="projectLookup.genfieldsGroupEntity" #rxSelect [controlTabIndex]="9"
                  formControlName="gemfieldsGroupCompanyProjectOwner" mainClass="form-control"
                  [keyValueProps]="['company','supplierId']"
                  [selectPlaceholder]="'Select Gemfields Group Entity'"
                  ></rx-select>
              <small class="form-text text-danger"
                  [class.d-block]="projectFormGroup.controls.gemfieldsGroupCompanyProjectOwner.errors">{{projectFormGroup.controls.gemfieldsGroupCompanyProjectOwner.errorMessage}}</small>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4  col-xl-4 col-form-label"
              rxText="ProjectAddEditComponent_Dept_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">

            <rx-select [(source)]="projectLookup.departmentLookUp" #rxSelect mainClass="form-control" [controlTabIndex]="12"
                formControlName="department" [selectPlaceholder]="'Select Department'"
                [keyValueProps]="['departmentName','departmentId']"></rx-select>

              <small class="form-text text-danger"
              [class.d-block]="projectFormGroup.controls.department.errors">{{projectFormGroup.controls.department.errorMessage}}</small>
            </div>
          </div>

        </div>
      </div>
      <div class="hr-line-dashed border-top w-100 mb-2"></div>
      <div class="row mt-auto">
        <div class="col text-right">
          <button class="btn btn-success mr-1" tabindex="12" (click)="saveProject(false)" rxText="Btn_Save_t"></button>
          <button class="btn btn-primary" tabindex="13" (click)="saveProject(true)" *rxAuthorize="[projectEdit]" rxText="Btn_Save_Continue_Edit_t">
            </button>
        </div>
      </div>
    </div>
  </div>
</div>
