import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';

import { OwnershipMapping } from '@app/models';
import { AbstractOwnershipMapping } from '../domain/abstract-ownership-mapping';
import { StatusEnum } from 'src/app/enums/status.enum';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { dateCompareValidation, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from "@angular/common";
@multilingual("OwnershipMappingAddComponent")
@Component({
    selector: "app-ownership-mapping-add",
    templateUrl: './ownership-mapping-add.component.html'
})
export class OwnershipMappingAddComponent extends AbstractOwnershipMapping implements OnInit, OnDestroy {
    ownershipMapping: OwnershipMapping;
    subscription: any;
    @Input() corporateBy: number;
    @Input() componentType: string;
    @Input() hide: Function;
    _this;
    constructor(private formBuilder: RxFormBuilder, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this._this = this;
        location.onPopState(() => this.hide());
    }
    titleClose: string;
    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.ownershipMapping = new OwnershipMapping();
        this.ownershipMapping.corporateBy = this.corporateBy;
        this.ownershipMapping.statusId = StatusEnum.Active;
        this.ownershipMapping.peopleOrCorporate = 0;
        this.ownershipMapping.ownershipId = 0;
        this.ownershipMappingFormGroup = this.formBuilder.formGroup(this.ownershipMapping) as IFormGroup<OwnershipMapping>;
        this.ownershipMappingFormGroup.controls.ownershipPerc.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }), RxwebValidators.pattern({ expression: { 'ownershipPerc': /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/ }, message: 'Please enter valid ownership percentage' })]);
        this.ownershipMappingFormGroup.controls.ownershipPerc.updateValueAndValidity();
        this.changePeopleOrCorporate(0);
        if (this.componentType == "user-edit") {
            this.changePeopleOrCorporate(1);
            this.ownershipMapping.peopleOrCorporate = 1;
            this.ownershipMapping.customerID = this.corporateBy;
        }
        this.showComponent = true;
    }

    saveOwnershipMappings() {
        if ((this.ownershipMappingFormGroup.value.startDate != null && this.ownershipMappingFormGroup.value.endDate != null) && dateCompareValidation(this.ownershipMappingFormGroup.value.startDate, this.ownershipMappingFormGroup.value.endDate) == true) {
            this.dateError = true;
        } else {
            this.dateError = false;
            this.personError = false;
            this.corporateError = false;
            if (this.componentType == "user-edit") {
                this.ownershipMappingFormGroup.value.corporateBy = this.ownershipMappingFormGroup.value.companyID;
                this.ownershipMappingFormGroup.value.companyID = 0;
            }
            this.ownershipMappingFormGroup.submitted = true;
            if (this.ownershipMappingFormGroup.valid) {
                this.spin = true;
                this.post({ body: this.ownershipMappingFormGroup.value }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Added");
                    if (existsAlert) {
                        //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "OwnershipMappingAddComponent";
    }
}
