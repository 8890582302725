<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="" [rxLocalisationInit]="componentName">
  <div class="modal-content">
    <div class="modal-header text-left">
      <ng-container *ngIf="modalType == 'ERROR'" [rxLocalisationInit]="componentName">
        <h4 class="modal-title mb-0" rxText="PurchaseOrderValidationPopup_ErrorHeader_t"
          ></h4>
      </ng-container>
      <ng-container *ngIf="modalType == 'INFO'" [rxLocalisationInit]="componentName">
        <h4 class="modal-title mb-0" rxText="PurchaseOrderValidationPopup_InfoHeader_t"
          ></h4>
      </ng-container>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>

    <div class="modal-body" *ngIf="showComponent" [rxLocalisationInit]="componentName">
      <div class="">
        <ng-container *ngIf="modalType == 'ERROR'" [rxLocalisationInit]="componentName">
          <h3 class="text-danger" rxText="PurchaseOrderValidationPopup_ErrorMsgLine1_t"></h3>
        </ng-container>
        <ng-container *ngIf="modalType == 'INFO'" [rxLocalisationInit]="componentName">
          <h3 class="" rxText="PurchaseOrderValidationPopup_InfoMsgLine1_t"></h3>
        </ng-container>
        <div class="table-responsive dataTables_wrapper pb-0 dt-bootstrap4">
          <table class="d-table table table-striped table-hover dataTables-example dataTable text-center border-bottom">
            <thead>
              <tr>
                <th rxText="PurchaseOrderValidationPopupComponent_BatchNo_t" ></th>
                <th rxText="PurchaseOrderValidationPopupComponent_suppliers_t" ></th>
                <ng-container *ngIf="modalType == 'INFO'" [rxLocalisationInit]="componentName">
                  <th rxText="PurchaseOrderValidationPopupComponent_email_t" ></th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let batchObj of supplierEmailList">
                <td class="text-center">
                  {{batchObj.batchNo}}
                </td>
                <td class="text-center">
                  <a class="text-blue font-weight-bold" [routerLink]="['/suppliers/' + batchObj.supplierId]"
                    [queryParams]="{'activeTab': 'supplier-edit'}" target="_blank">{{batchObj.supplierName}}</a>
                </td>
                <td *ngIf="modalType == 'INFO'" class="text-center">
                  {{batchObj.supplierEmail}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ng-container *ngIf="modalType == 'ERROR'" [rxLocalisationInit]="componentName">
          <h3 class="text-danger" rxText="PurchaseOrderValidationPopup_ErrorMsgLine2_t"></h3>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer px-0">
      <ng-container *ngIf="modalType == 'INFO'" [rxLocalisationInit]="componentName">
        <button (click)="hide(true)" class="btn btn-success mr-1" rxText="Btn_Continue_t"
          ></button>
      </ng-container>
      <button (click)="hide(false)" class="btn btn-primary mr-3" rxText="Btn_Close_t"
        ></button>
    </div>
  </div>
</div>
