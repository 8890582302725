import { prop,propObject,propArray,required,maxLength,range ,alpha,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class WatchStatusBase {

//#region watchStatusId Prop
        @prop()
        watchStatusId : number;
//#endregion watchStatusId Prop


//#region watchStatusName Prop
        @required()
        //@alpha({allowWhiteSpace:true})
        @notEmpty()
        @trim()
        @maxLength({value:100})
        watchStatusName : string;
//#endregion watchStatusName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}