import {ActivityLogBase,ActivityLogTypeBase,} from '@app/database-models'
//Generated Imports
import {SupplierBase,UserBase,} from '@app/database-models'

export class ActivityLog extends ActivityLogBase 
{




//#region Generated Reference Properties
//#region activityLogType Prop
activityLogType : ActivityLogTypeBase;
//#endregion activityLogType Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop
//#region user Prop
user : UserBase;
//#endregion user Prop

//#endregion Generated Reference Properties











}