import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDisplayOrderLookUpBase {

//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'displayOrderId', keyColumn: true})
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : string;
//#endregion displayOrder Prop

}