import { vBookingPiecesBase } from '@app/database-models';
//Generated Imports
import { prop } from '@rxweb/reactive-form-validators';
import { actionColumn } from '@rxweb/grid';
// @actionColumn({
//     allowSorting: false,
//     style: { width: "1%", "text-align": "left", "position": "relative" }, headerTitle: "Action",
//     configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
// })

export class vBookingPieces extends vBookingPiecesBase 
{

@prop()
totalCount: number;


//#region Generated Reference Properties
//#endregion Generated Reference Properties
}