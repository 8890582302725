<div class="modal-dialog modal-lg" *ngIf="showComponent==true">
  <div class="modal-content" *rxMultilingual="">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" *ngIf="isDirector==0">Edit Employment</h4>
      <h4 class="modal-title mb-0" *ngIf="isDirector==1">Edit Directorship</h4>
      <h4 class="modal-title mb-0" *ngIf="componentType=='corporate'">Corporates/Groups</h4>

      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-0" [formGroup]="companyCustomerMappingFormGroup"
      [rxSpinner]="spin" *ngIf="componentType=='employment'">

      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentCompanyId"></label>
        <div class="col-md-8" [rxSelectExtended]="_this">
          <!-- <select class="form-control" formControlName="companyId">
                <option *ngFor="let item of employmentData.companyLookup" [value]="item.supplierId">
                    {{item.company}}</option>
            </select>  -->
          <rx-select [selectPlaceholder]="'Select Company'" [(source)]="employmentData.companyLookup" #rxSelect
            mainClass="form-control" formControlName="companyId" [keyValueProps]="['company','supplierId']"
            [text]="company"></rx-select>
          <small class="form-text text-danger"
            [class.d-block]="companyCustomerMappingFormGroup.controls.companyId.errors">{{companyCustomerMappingFormGroup.controls.companyId.errorMessage}}
            <br />
          </small>
        </div>
      </div>
      <div class="form-group row" *ngIf="isDirector==0">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentJobTitle"> </label>
        <div class="col-md-8">
          <input type="text" class="form-control" formControlName="jobTitle"
            rxPlaceholder="employmentJobTitle">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentStartDate">
        </label>
        <div class="col-md-8">
          <!-- <input type="date" class="form-control" value="{{companyCustomerMappingFormGroup.value.startDate | date:'dd/MMM/yyyy'}}" formControlName="startDate"
          />
          <small class="form-text text-danger" [class.d-block]="companyCustomerMappingFormGroup.controls.startDate.errors">{{companyCustomerMappingFormGroup.controls.startDate.errorMessage}}
            <br />
          </small> -->
          <rx-date class="w-100" showAddon="true" formControlName="startDate" placeholder="Enter Start Date"
            pickerClass="form-control"></rx-date>
          <small class="form-text text-danger"
            [class.d-block]="companyCustomerMappingFormGroup.controls.startDate.errors">{{companyCustomerMappingFormGroup.controls.startDate.errorMessage}}<br /></small>
        </div>
      </div>

      <div class="form-group row" *ngIf="isDirector==0">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentReportTo"> </label>
        <div class="col-md-8" [rxSelectExtended]="_this">
          <!-- <select class="form-control" formControlName="reportTo">
              <option *ngFor="let item of employmentData.reportingHeadLookup" [value]="item.userId">{{item.fullName}}</option>
            </select> -->
          <rx-select [(source)]="employmentData.reportingHeadLookup" #rxSelect mainClass="form-control" formControlName="reportTo"
            [keyValueProps]="['fullName','userId']" [selectPlaceholder]="'Select Report To'">
          </rx-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentEndDate"> </label>
        <div class="col-md-8">
          <!-- <input type="date" class="form-control" value="{{companyCustomerMappingFormGroup.value.endDate | date:'dd/MMM/yyyy'}}" formControlName="endDate"
          />
          <small class="form-text text-danger" [class.d-block]="companyCustomerMappingFormGroup.controls.endDate.errors">{{companyCustomerMappingFormGroup.controls.endDate.errorMessage}}
            <br />
          </small> -->
          <rx-date class="w-100" showAddon="true" formControlName="endDate" placeholder="Enter End Date"
            pickerClass="form-control"></rx-date>
          <small class="form-text text-danger" *ngIf="dateError==true">End Date should be greater than Start
            Date<br /></small>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="EditForm()" class="btn btn-success mr-1">Save</button>
        <button type="button" class="btn btn-outline-primary" (click)="hide()">Close</button>
        <!-- <button (click)="DeleteForm()" class="btn btn-danger mr-1">Delete</button> -->
      </div>
    </div>


    <div class="ml-4 modal-body pb-0 ibox-content bg-white d-flex flex-column flex-grow-1"
      [formGroup]="companyCustomerMappingFormGroup" *ngIf="componentType=='corporate'">
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="name"></label>
        <div class="col-md-8">
          <!-- <input type="text" class="form-control" formControlName="company" disabled /> -->
          <label type="text" class="form-control disableLabel-withoutheight mb-0">{{(companyCustomerMappingFormGroup.value.company
            ===
            null ||
            companyCustomerMappingFormGroup.value.company ===
            undefined ||
            companyCustomerMappingFormGroup.value.company === '')
            ? '' :
            companyCustomerMappingFormGroup.value.company}}</label>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="jobTitle"></label>
        <div class="col-md-8">
          <input type="text" class="form-control" formControlName="jobTitle"
            rxPlaceholder="jobTitle" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="industryType"> </label>
        <div class="col-md-8">
          <!-- <input type="text" class="form-control" formControlName="industryTypeName" disabled /> -->
          <label type="text" class="form-control disableLabel-withoutheight mb-0">{{(companyCustomerMappingFormGroup.value.industryTypeName
            ===
            null ||
            companyCustomerMappingFormGroup.value.industryTypeName ===
            undefined ||
            companyCustomerMappingFormGroup.value.industryTypeName === '')
            ? '' :
            companyCustomerMappingFormGroup.value.industryTypeName}}</label>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="activeFormer"></label>
        <div class="col-md-8">
          <select class="form-control" formControlName="isActive" (change)="changeStatus($event)">
            <option value="Active">Active</option>
            <option value="Former">Former</option>
          </select>
        </div>
      </div>
      <!-- <div class="form-group row check-group">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Director</label>
          <div class="col-md-auto d-flex align-items-center">
            <div class="i-checks">
              <label class="mb-0">
                <app-checkbox [checkFormControl]="companyCustomerMappingFormGroup.controls.isDirector" (change)="changeDirectorship($event)"></app-checkbox>
              </label>
            </div>
          </div>
        </div>  -->
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentEndDate"></label>
        <div class="col-md-8">
          <!--<input type="date" class="form-control"
            value="{{companyCustomerMappingFormGroup.value.startDate | date:'dd/MMM/yyyy'}}" formControlName="startDate"
            disabled />-->
          <label type="text" class="form-control disableLabel-withoutheight mb-0">{{(companyCustomerMappingFormGroup.value.startDate
            ===
            null ||
            companyCustomerMappingFormGroup.value.startDate ===
            undefined)
            ? '' :
            companyCustomerMappingFormGroup.value.startDate | date:'dd/MMM/yyyy'}}</label>
          <small class="form-text text-danger"
            [class.d-block]="companyCustomerMappingFormGroup.controls.startDate.errors">{{companyCustomerMappingFormGroup.controls.startDate.errorMessage}}</small>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
          rxText="employmentStartDate"></label>
        <div class="col-md-8">
          <!--<input type="date" class="form-control"
            value="{{companyCustomerMappingFormGroup.value.endDate | date:'dd/MMM/yyyy'}}" formControlName="endDate"
            disabled />-->
          <label type="text" class="form-control disableLabel-withoutheight mb-0">{{(companyCustomerMappingFormGroup.value.endDate
            ===
            null ||
            companyCustomerMappingFormGroup.value.endDate ===
            undefined)
            ? '' :
            companyCustomerMappingFormGroup.value.endDate | date:'dd/MMM/yyyy'}}</label>
        </div>
      </div>
      <!-- <div class="form-group row" *ngIf=showDate>
          <label class="col-md-4 col-xl-4 col-form-label">Directorship Start Date</label>
          <div class="col-md-8">
            <div class="" id="data_1">
              <div class="input-group date">
                <rx-date showAddon="true" formControlName="startDate" placeholder="" pickerClass="form-control"></rx-date>
                <small class="form-text text-danger" [class.d-block]="companyCustomerMappingFormGroup.controls.startDate.errors">{{companyCustomerMappingFormGroup.controls.startDate.errorMessage}}</small>
                  <br />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf=showDate>
            <label class="col-md-4 col-xl-4 col-form-label">Directorship End Date</label>
            <div class="col-md-8">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date showAddon="true" formControlName="endDate" placeholder="" pickerClass="form-control"></rx-date>
                </div>
                <small class="form-text text-danger" *ngIf="dateError==true">>Directorship End Date Should Be Smaller Then Directorship Start Date
                    <br />
                </small>
              </div>
            </div>
          </div> -->

      <div class="modal-footer">
        <button (click)="EditForm()" class="btn btn-success mr-1">Save</button>
        <button type="button" class="btn btn-outline-primary" (click)="hide()">Close</button>
      </div>
    </div>

  </div>
</div>