import {WatchRepairDetailBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
//Generated Imports
export class WatchRepairDetail extends WatchRepairDetailBase implements FileDetailViewModel 
{
    @prop()
    warrantyCardId:number
    
    @prop({defaultValue:false})
    isCaseCheked:boolean
    @prop()
    clientURL : string;
    
    @prop({defaultValue:0})
    isApprove : number;

    @prop({defaultValue:false})
    showClientRepairApproval:boolean;

    @prop()
    caseArray:string;
    @prop()
    crystalArray:string;
    @prop()
    dialArray:string;
    @prop()
    handsArray:string;
    @prop()
    braceletArray:string;
    @prop()
    buckleArray:string;
    @prop()
    movementArray:string;
    @prop()
    crownArray:string;
    @prop()
    pusherArray:string;
   
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;

    @prop({defaultValue:false})
    isImageRemoved:boolean;




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}