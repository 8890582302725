import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LeaveMasterBase {

        //#region masterId Prop
        @prop()
        masterId: number;
        //#endregion masterId Prop


        //#region userID Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        userID: number;
        //#endregion userID Prop


        //#region managerID Prop
        @prop({ defaultValue: 0 })
        //@range({ minimumNumber: 0, maximumNumber: 2147483647})
        //@required()
        managerID: number;
        //#endregion managerID Prop


        //#region leaveType Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        leaveType: number;
        //#endregion leaveType Prop


        //#region startDate Prop
        @required()
        startDate: any;
        //#endregion startDate Prop


        //#region startSession Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        startSession: number;
        //#endregion startSession Prop


        //#region endDate Prop
        @required()
        endDate: any;
        //#endregion endDate Prop


        //#region endSession Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        endSession: number;
        //#endregion endSession Prop


        //#region emailcc Prop
        @maxLength({ value: 500 })
        emailcc: string;
        //#endregion emailcc Prop


        //#region reason Prop
        @prop()
        reason: string;
        //#endregion reason Prop


        //#region attachment Prop
        @prop()
        attachment: string;
        //#endregion attachment Prop


        //#region status Prop
        @range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @required()
        status: number;
        //#endregion status Prop


        //#region comments Prop
        @prop()
        comments: string;
        //#endregion comments Prop


        //#region createdOn Prop
        @required()
        createdOn: Date;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @prop()
        updatedOn: Date;
        //#endregion updatedOn Prop


        //#region days Prop
        @required()
        days: number;
        //#endregion days Prop


        //#region budget Prop
        @prop()
        budget: number;
        //#endregion budget Prop


        //#region budgetInUSD Prop
        @prop()
        budgetInUSD: number;
        //#endregion budgetInUSD Prop


        //#region currency Prop
        //@required()
        @range({ minimumNumber: 1, maximumNumber: 2147483647, message: "You can't leave this empty" })
        @required()
        currency: number;
        //#endregion currency Prop


        //#region citiesMinesLocations Prop
        @prop()
        citiesMinesLocations: string;
        //#endregion citiesMinesLocations Prop

        //#region isDomesticTravel Prop
        @prop()
        isDomesticTravel: boolean;
        //#endregion isDomesticTravel Prop

        //#region purposeOfTravel Prop
        @prop()
        purposeOfTravel: string;
        //#endregion purposeOfTravel Prop

        //#region isOfficial Prop
        @prop()
        isOfficial: boolean;
        //#endregion isOfficial Prop

        //#region isDetailedFormAdded Prop
        @prop()
        isDetailedFormAdded: boolean;
        //#endregion isDetailedFormAdded Prop


        //#region isVehicleRequested Prop
        @prop()
        isVehicleRequested: boolean;
        //#endregion isVehicleRequested Prop


        //#region isFlightRequested Prop
        @prop()
        isFlightRequested: boolean;
        //#endregion isFlightRequested Prop

        //#region isAccomodationRequested Prop
        @prop()
        isAccomodationRequested: boolean;
        //#endregion isAccomodationRequested Prop

        //#region totalHrsRequested Prop
        @prop()
        totalHrsRequested: string;
        //#endregion totalHrsRequested Prop

        //#region logisticUse Prop
        @prop()
        logisticUse: string;
        //#endregion logisticUse Prop

        //#region carProvided Prop
        @prop()
        carProvided: string;
        //#endregion carProvided Prop


        //#region driverProvided Prop
        @prop()
        driverProvided: string;
        //#endregion driverProvided Prop


        //#region driverPhone Prop
        @prop()
        driverPhone: string;
        //#endregion driverPhone Prop


        //#region emailForUpdates Prop
        @prop()
        emailForUpdates: string;
        //#endregion emailForUpdates Prop


        //#region telePhoneForUpdates Prop
        @prop()
        telePhoneForUpdates: string;
        //#endregion telePhoneForUpdates Prop

}