import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivityLogType } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent, } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';

@http({
  path: "api/ActivityLogTypes",
})

export class AbstractActivityLogType extends CoreComponent {
  activityLogTypeFormGroup: IFormGroup<ActivityLogType>
  showComponent: boolean = false;
  spin: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  isFilter: boolean = false;
  totalRecords: number = 0;
  isNoRecordFound: boolean;
  isShowGrid: boolean;

  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }
  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

    })
  }

}
