<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
    <div class="row wrapper white-bg page-heading py-2 align-items-center">
        <div class="col-md-8 my-1">
          <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/discounts']" rxText="Label_Discounts_t"></a>
            </li>
            <li class="breadcrumb-item active">Add New</li>
          </ol>
        </div>
        <div class="col-md-4 text-right">
          <app-system-time></app-system-time>
        </div>
    </div>

    <div class="wrapper wrapper-content flex-grow-1">

      <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
          <h5><label rxText="DiscountAddComponent_Title"></label></h5>
          <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
            <i class="fa fa-question-circle fa-2x"></i>
          </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="discountFormGroup"  [rxSpinner]="spin">
          <div class="row add-countries-form" (keyup.enter)="adddiscount(false)">
            <div class="col-lg-6 col-xl-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="DiscountAddEditComponent_DiscountType_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                  <!-- <select class="form-control" formControlName="discountTypeId">
                    <option [selected]="true" value=0>Select</option>
                    <option *ngFor="let item of discountLookupGroup.discountTypeLookUp" [value]="item.applicationObjectId">{{item.applicationObjectName}}</option>
                  </select> -->

                  <rx-select [(source)]="discountLookupGroup.discountTypeLookUp" #rxSelect formControlName="discountTypeId"
                  mainClass="form-control"  [selectPlaceholder]="'Select Discount Type'" [controlTabIndex]="1" rxFocus
                  [keyValueProps]="['applicationObjectName','applicationObjectId']"></rx-select>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.discountTypeId.errors">{{discountFormGroup.controls.discountTypeId.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="DiscountAddEditComponent_DiscountRequirement_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                  <!-- <select class="form-control" (change)="discountRequirementChange($event)" formControlName="discountRequirementId">
                    <option [selected]="true" value=0>Select</option>
                    <option *ngFor="let item of discountLookupGroup.discountRequirementLookUp" [value]="item.applicationObjectId">{{item.applicationObjectName}}</option>
                  </select> -->

                  <rx-select [(source)]="discountLookupGroup.discountRequirementLookUp" #rxSelect formControlName="discountRequirementId"
                  mainClass="form-control" (changed)="discountRequirementChange($event)" [selectPlaceholder]="'Select Discount Requirement'"  [controlTabIndex]="4"
                  [keyValueProps]="['applicationObjectName','applicationObjectId']"></rx-select>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.discountRequirementId.errors">{{discountFormGroup.controls.discountRequirementId.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="DiscountAddEditComponent_DiscountLimitation_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                  <!-- <select class="form-control" (change)="discountLimitation($event)" formControlName="discountLimitationId">
                    <option [selected]="true" value=0>Select</option>
                    <option *ngFor="let item of discountLookupGroup.discountLimitationLookUp" [value]="item.applicationObjectId">{{item.applicationObjectName}}</option>
                  </select> -->
                  <rx-select [(source)]="discountLookupGroup.discountLimitationLookUp" #rxSelect formControlName="discountLimitationId"
                  mainClass="form-control" (changed)="discountLimitation($event)" [selectPlaceholder]="'Select Discount Limitation'"   [controlTabIndex]="7"
                  [keyValueProps]="['applicationObjectName','applicationObjectId']"></rx-select>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.discountLimitationId.errors">{{discountFormGroup.controls.discountLimitationId.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div  class="form-group row"  *ngIf="showRestrictedDesignProducts" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_RequirementSpentAmount_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="requirementSpentAmount"   tabindex="10" class="form-control "  rxPlaceholder="DiscountAddEditComponent_RestrictDesignProducts_p" />
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.requirementSpentAmount.errors">{{discountFormGroup.controls.requirementSpentAmount.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4" > -->
              <div  class="form-group row" *ngIf="showRequiredShippingCountry" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="DiscountAddEditComponent_ReqShippingCountry_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                  <!-- <select class="form-control" formControlName="requirementShippingCountryIs">
                    <option [selected]="true" value=0>Select Country</option>
                    <option *ngFor="let item of discountLookupGroup.shippingCountryLookUp" [value]="item.countryId">{{item.countryName}}</option>
                  </select> -->
                  <rx-select [(source)]="discountLookupGroup.shippingCountryLookUp" #rxSelect  formControlName="requirementShippingCountryIs"
                  mainClass="form-control" [selectPlaceholder]="'Select Country'"  [controlTabIndex]="10"
                  [keyValueProps]="['countryName','countryId']"></rx-select>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.requirementShippingCountryIs.errors">{{discountFormGroup.controls.requirementShippingCountryIs.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div  class="form-group row" *ngIf="showRequiredBillingCountry" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="DiscountAddEditComponent_ReqBillingCountry_t"></label>
                <div class="col-md-8" [rxSelectExtended]="_this">
                  <!-- <select class="form-control" formControlName="requirementBillingCountryIs">
                    <option [selected]="true" value=0>Select Country</option>
                    <option *ngFor="let item of discountLookupGroup.billingCountryLookUp" [value]="item.countryId">{{item.countryName}}</option>
                  </select> -->
                  <rx-select [(source)]="discountLookupGroup.billingCountryLookUp" #rxSelect formControlName="requirementBillingCountryIs"
                  mainClass="form-control" [selectPlaceholder]="'Select Country'"   [controlTabIndex]="10"
                  [keyValueProps]="['countryName','countryId']"></rx-select>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.requirementBillingCountryIs.errors">{{discountFormGroup.controls.requirementBillingCountryIs.errorMessage}}</small>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_Name_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="name" tabindex="2"  class="form-control "  placeholder="Enter Name" />
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.name.errors">{{discountFormGroup.controls.name.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div class="form-group row" *ngIf="showDiscountAmount" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_DiscountAmount_t"></label>
                <div class="col-md-8">
                  <div class="input-group">
                    <input type="text" formControlName="discountAmount"  tabindex="5"  class="form-control border-right-0"  rxPlaceholder="DiscountAddEditComponent_DiscountAmount_p" />
                    <div class="input-group-addon border-left-0 py-2">[USD]</div>
                  </div>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.discountAmount.errors">{{discountFormGroup.controls.discountAmount.errorMessage}}</small>
                </div>

              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4" > -->
              <div class="form-group row"  *ngIf="showDiscountPercentage" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_DiscountPercentage_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="discountPercentage" tabindex="5"  class="form-control"  rxPlaceholder="DiscountAddEditComponent_DiscountAmount_p" />
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.discountPercentage.errors">{{discountFormGroup.controls.discountPercentage.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div class="form-group row" >
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_UsePercentage_t"></label>
                <div class="col-md-auto d-flex align-items-center">
                    <div class="i-checks">
                        <label>
                            <app-checkbox (change)="userPercentage($event)"  tabindex="8" [checkFormControl]="discountFormGroup.controls.usePercentage"></app-checkbox>
                          </label>
                    </div>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.usePercentage.errors">{{discountFormGroup.controls.usePercentage.errorMessage}}</small>
                </div>
              </div>
              <div *ngIf="showNtimes" class="form-group row" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" tabindex="11"  rxText="DiscountAddEditComponent_LimitationTimes_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="limitationTimes" class="form-control "  rxPlaceholder="DiscountAddEditComponent_LimitationTimes_p" />
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.limitationTimes.errors">{{discountFormGroup.controls.limitationTimes.errorMessage}}</small>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4">
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_StartDate_t"></label>
                <div class="col-md-8">
                  <rx-date showAddon="true" formControlName="startDate"   [controlTabIndex]="3" placeholder="Select Start Date" pickerClass="form-control"></rx-date>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.startDate.errors">{{discountFormGroup.controls.startDate.errorMessage}}</small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4"> -->
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_EndDate_t"></label>
                <div class="col-md-8">
                  <rx-date showAddon="true" formControlName="endDate" placeholder="Select End Date"   [controlTabIndex]="6" pickerClass="form-control"></rx-date>
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.endDate.errors">{{discountFormGroup.controls.endDate.errorMessage}}</small>
                  <small class="form-text text-danger" *ngIf="dateError==true">End Date should be greater than Start Date<br /></small>
                </div>
              </div>
            <!-- </div>
            <div class="col-lg-6 col-xl-4 pr-xl-4">-->
              <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_RequiresCouponCode_t"></label>
                <div class="col-md-auto d-flex align-items-center">
                    <div class="i-checks">
                        <label>
                            <app-checkbox (change)="requiresCouponCode($event)" tabindex="9" [checkFormControl]="discountFormGroup.controls.requiresCouponCode"></app-checkbox>
                          </label>
                    </div>
                    <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.requiresCouponCode.errors">{{discountFormGroup.controls.requiresCouponCode.errorMessage}}<br /></small>
                </div>
              </div>
              <div *ngIf="showCouponcode" class="form-group row" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="DiscountAddEditComponent_CouponCode_t"></label>
                <div class="col-md-8">
                  <input type="text" formControlName="couponCode" tabindex="12" class="form-control "  rxPlaceholder="DiscountAddEditComponent_CouponCode_p" />
                  <small class="form-text text-danger" [class.d-block]="discountFormGroup.controls.couponCode.errors">{{discountFormGroup.controls.couponCode.errorMessage}}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-auto">
            <div class="hr-line-dashed border-top w-100"></div>
            <div class="col text-right">
              <button (click)="adddiscount(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
              <button (click)="adddiscount(true)" class="btn btn-primary mr-1" *rxAuthorize="[discountEdit]" rxText="Btn_Save_Continue_Edit_t"></button>
            </div>
          </div>
      </div>
    </div>

</div>
