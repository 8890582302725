import {vMeasureWeightLookUpBase,} from '@app/database-models'
//Generated Imports
export class vMeasureWeightLookUp extends vMeasureWeightLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}