import { gridColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';


export class SubProjectListViewModel { 

//#region month Prop
  @gridColumn({ parameter: false, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'month', keyColumn: false })
  month: string;
//#endregion month Prop

//#region projectId Prop
    @gridColumn({ parameter: false, class: ["text-left"], visible: false, columnIndex:1,  allowSorting: true, headerKey: 'projectId', keyColumn: true})
    projectId : number;
//#endregion projectId Prop

  //#region projectType Prop
  @gridColumn({ parameter: true, class: ["text-left"], visible: false, columnIndex: 2, allowSorting: true, headerKey: 'projectType', keyColumn: false })
  projectType: string;
//#endregion projectType Prop

//#region projectTypeName Prop
@gridColumn({parameter: false, class: ["text-left"], visible: false, columnIndex:3,  allowSorting: true, headerKey: 'projectTypeName', keyColumn: false})
projectTypeName : string;
//#endregion projectTypeName Prop

//#region projectName Prop
  @gridColumn({ configuredTemplate: { templateName:"projectLevele2Link" }, class: ["text-left"], visible: true, columnIndex:6,  allowSorting: true, headerKey: 'projectName', keyColumn: false})
    projectName : string;
//#endregion projectName Prop

  //#region projectOwner Prop    
    @gridColumn({ class: ["text-left"], visible: false, columnIndex:5,  allowSorting: true, headerKey: 'projectOwner', keyColumn: false})
    projectOwner : number;
//#endregion projectOwner Prop

  //#region projectOwnerName Prop    
  @gridColumn({ class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'projectOwnerName', keyColumn: false })
    projectOwnerName : string;
//#endregion projectOwnerName Prop

  //#region projectStartDate Prop
  @gridColumn({ class: ["text-left"], visible: false, columnIndex: 7, allowSorting: true, headerKey: 'projectStartDate', keyColumn: false })
    projectStartDate : Date;
//#endregion projectStartDate Prop

  //#region projectEndDate Prop
  @gridColumn({ style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex: 8, allowSorting: true, headerKey: 'projectEndDate', keyColumn: false })
    projectEndDate : Date;
//#endregion projectEndDate Prop

//#region projectIndicator Prop
  @gridColumn({ parameter:false, configuredTemplate: { templateName: "progressBar" }, class: ["text-left"], visible: true, columnIndex:9,  allowSorting: true, headerKey: 'projectIndicator', keyColumn: false})
    projectIndicator : string;
//#endregion projectIndicator Prop 

  //#region noteDescription Prop
  // @gridColumn({ class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey: 'noteDescription', keyColumn: false })
  // noteDescription : string;

//#endregion noteDescription Prop

//#region createdBy Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:10,  allowSorting: true, headerKey: 'createdBy', keyColumn: false})
    createdBy : number;
//#endregion createdBy Prop

//#region noteAddedBy Prop
    @gridColumn({style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:11,  allowSorting: true, headerKey: 'noteAddedBy', keyColumn: false})
    noteAddedBy : string;
//#endregion noteAddedBy Prop

//#region createdDate Prop
    @gridColumn({style: { width: "2%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:12,  allowSorting: true, headerKey: 'createdDate', keyColumn: false})
    createdDate : Date;
//#endregion createdDate Prop

//#region totalCount Prop
    @gridColumn({ parameter: true, name: "totalCount", visible: false, columnIndex: 13, allowSorting: true, headerKey: 'totalCount', keyColumn: false })
    totalCount: number;
//#endregion totalCount Prop

//#region totalCount Prop
    @gridColumn({ visible: false, columnIndex: 14,  keyColumn: false })
    parentId: number;
//#endregion totalCount Prop

//#region isRestrictedProject 
  @gridColumn({ visible: false, columnIndex: 15, keyColumn: false })
  isRestrictedProject: boolean;
//#endregion isRestrictedProject

}
