import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vUserKnownToBase {

        

        //#region userId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'userId', keyColumn: false})
        knownToId : any;
        //#endregion userId Prop

        //#region userId Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : any;
        //#endregion userId Prop


        //#region userId Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'userId', keyColumn: false})
        knownToUserId : any;
        //#endregion userId Prop

        //#region userRelationId Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userRelationId : any;
        //#endregion userRelationId Prop

        //#region fullName Prop
        @gridColumn({style: { width: "20%", "text-align": "initial" },class: ["text-left"],isAscending:true,visible: true, columnIndex:1, allowSorting: true, headerTitle: 'Name', keyColumn: false})
        fullName : any;
        //#endregion fullName Prop


        //#region company Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerTitle: 'Corporates/Groups', keyColumn: false})
        company : boolean;
        //#endregion company Prop

        //#region JobTitle Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:3, allowSorting: true, headerTitle: 'Job Title', keyColumn: false})
        JobTitle : string;
        //#endregion JobTitle Prop


        //#region relationShipName Prop
        @gridColumn({style: { width: "10%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:4, allowSorting: true, headerTitle: 'Relationship', keyColumn: false})
        relationShipName : string;
        //#endregion relationShipName Prop

        //#region relationShipId Prop
        @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:3, allowSorting: true, headerTitle: 'Relationship', keyColumn: false})
        relationShipId : number;
        //#endregion relationShipId Prop

         //#region statusId Prop
         @gridColumn({style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: false, columnIndex:3, allowSorting: true, headerTitle: 'StatusId', keyColumn: false})
         statusId : number;
         //#endregion statusId Prop

         //#region countryName Prop
         @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:4,headerTitle: 'totalCount', keyColumn: false})
         totalCount : number;
        //#endregion countryName Prop





}