import { prop,notEmpty,range,required} from "@rxweb/reactive-form-validators"


export class ChainGaugeBase {
    //#region chainGaugeID Prop
    @prop()
    chainGaugeID : number;
    //#endregion chainGaugeID Prop

    //#region gaugeName Prop
    @prop()
    @notEmpty()
    gaugeName : string;
    //#endregion gaugeName Prop

    //#region statusId Prop
    @range({minimumNumber:1,maximumNumber:2147483647})
    @required()
    statusId : number;
    //#endregion statusId Prop
}