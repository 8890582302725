<!-- <div class="modal-dialog modal-lg" style="line-height:0.1;max-width:80%!important"> -->
<div class="modal-dialog modal-xl">
  <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="KnownToAddComponent_Title_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body pb-3" [formGroup]="userKnownToMappingSearchFormGroup"
      [rxSpinner]="spin" *ngIf="showComponent" (keyup.enter)="searchKnownToUser()" [rxLocalisationInit]="componentName">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"
              rxText="KnownToAddComponent_RegistrationFrom_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="ragistrationFromDate" placeholder="Select Date"
                pickerClass="form-control" rxFocus></rx-date>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"
              rxText="KnownToAddComponent_RegistrationTo_t"></label>
            <div class="col-md-8">
              <rx-date showAddon="true" formControlName="ragistrationToDate" placeholder="Select Date"
                pickerClass="form-control"></rx-date>
              <small class="form-text text-danger" *ngIf="dateError==true">Registration To Date should be greater than
                Registration from Date<br /></small>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" rxText="knownToName"></label>
            <div class="col-md-8">
              <rx-select autoComplete="true" mainClass="form-control" formControlName="name" [params]="['persons']"
              path="api/SearchEvent/GetLookups" [keyValueProps]="['#name#','id']"
              [minimumCharacterSearchLength]="3" (keyup)="checkNameText($event)">
            </rx-select>
            </div>
          </div>
        </div> -->
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"
              rxText="KnownToAddComponent_Name_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="name"
                [queryParams]="{'lookupType':'users'}" path="api/SearchEvent/GetLookups"
                [keyValueProps]="['#name#','id']" (keyup)="checkNameText($event)" [minimumCharacterSearchLength]="2"
                [enableFreeText]="true" [valueInQueryParams]="true" [selectPlaceholder]="'Select Name'" [text]="searchNameText">
              </rx-select>
            </div>
          </div>
        </div>


        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"
              rxText="KnownToAddComponent_Email_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="email" id="nameDropdown"
                [queryParams]="{'lookupType':'userEmail'}" [keyValueProps]="['#email#','id']"
                path="api/SearchEvent/GetLookups" (keyup)="checkEmailText($event)" [minimumCharacterSearchLength]="2"
                [enableFreeText]="true" [valueInQueryParams]="true" [selectPlaceholder]="'Select Email'" [text]="searchEmailText">
              </rx-select>
              <!-- <rx-select autoComplete="true" mainClass="form-control" formControlName="email" [params]="[{'searchString':searchString,'lookupType':'userEmail'}]"
                  path="api/SearchEvent/GetLookups" [keyValueProps]="['#email#','id']" (keyup)="checkEmailText($event)"
                  [minimumCharacterSearchLength]="3" [enableFreeText]="true">
                </rx-select> -->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"
              rxText="KnownToAddComponent_SalesAdvisor_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="salesAdvisor">
                <option [selected]="true" value="0">Select</option>
                <option *ngFor="let item of userKnownToLookUp.salesAdvisorLookUp" [value]="item.userId">{{item.username}}</option>
              </select> -->
              <rx-select [(source)]="userKnownToLookUp.salesAdvisorLookUp" #rxSelect mainClass="form-control"
                formControlName="salesAdvisor" [keyValueProps]="['fullName','userId']"
                [selectPlaceholder]="'Select Sales Advisor'">
              </rx-select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pb-0 px-0">
        <button (click)="searchKnownToUser()" class="btn btn-success mr-1" rxText="Btn_Search_t"></button>

      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">
        <div class="dataTables_filter d-flex flex-wrap align-items-center mt-3">

          <!-- <label class="ml-auto mr-0 mb-3" *ngIf="showSaveButton">
            <input type="search" (keyup)="search($event)" class=" table_search form-control form-control-sm pr-4" placeholder="">
          </label> -->
        </div>
        <div class="" *ngIf="isShowGrid">
          <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
            <div class="">
              <!-- <div id="known-to-user-list" class="dataTables_wrapper pb-0 dt-bootstrap4 scrollable-table">
                    </div> -->
              <div class="dataTables_wrapper pb-0 dt-bootstrap4" id="knownToSearch">
                <!-- <rx-grid [design]="knownToUserListGrid"></rx-grid> -->
              </div>
            </div>
          </div>
        </div>
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
          <h4>{{ErrorMsg}}</h4>
        </div>
        <div class="modal-footer" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
          <ng-container [rxLocalisationInit]="componentName" *ngIf="showSaveButton">
            <button class="btn btn-success mr-1" (click)="save()"
              [disabled]=" (trackArray.length == 0 && this.KnownToIds.length == 0 && this.excludedTrackArray.length == 0 && !this.headerBtnChk)
              || (this.totalRecords - this.excludedTrackArray.length == 0)" rxText="Btn_Save_t"></button>
          </ng-container>
          <button (click)="hide()" class="btn btn-outline-primary" rxText="Btn_Close_t"></button>
        </div>
      </div>

    </div>

  </div>
</div>
