<div class="ibox-content bg-white d-flex flex-column flex-grow-1 border-0 p-0" *rxMultilingual="" [rxLocalisationInit]="componentName" [rxSpinner]="spin">
    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <a class="btn btn-success btn-sm mb-3" [routerLink]="['/banned-ip-network/add']" rxText="Btn_Add_Banned_Network_t" ></a>
        <label class="ml-auto mr-0 mb-3">
            <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
                placeholder="" [title]="titleSearch">
        </label> 
    </div>
    <div class="table-responsive" *ngIf="!isNoRecordFound">
      <rx-grid [design]="bannedIPNetworkGrid"></rx-grid>
    </div>
    <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
    </div>
</div>
