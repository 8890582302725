import {vBoutiquePictureBase,} from '@app/database-models'
//Generated Imports
import { actionColumn, gridColumn } from '@rxweb/grid';
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';
@actionColumn({
    allowSorting:false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 12, headerTitle: "Action"
})
export class vBoutiquePicture extends vBoutiquePictureBase {


    @gridColumn({ style: { width: "15%" },allowSorting:false, class: ["text-intial"],  name: "imageURL1", configuredTemplate: { templateName: 'imageView' }, visible: true, columnIndex: 0, headerKey: 'BoutiquePictureListComponent_Image_gh', keyColumn: false })
    imageURL1: string;



    //#region Generated Reference Properties

//#endregion Generated Reference Properties






}

