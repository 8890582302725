import { prop, propObject, propArray, required, maxLength, range, notEmpty, alpha, trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductVariantStatusBase {

  //#region productStatusId Prop
  @prop()
  productStatusId: number;
  //#endregion productStatusId Prop


  //#region productStatus Prop
  @required()
  @maxLength({ value: 150 })
  @trim()
  @notEmpty()
  productStatus: string;
  //#endregion productStatus Prop


  //#region statusId Prop
  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  @required()
  statusId: number;
  //#endregion statusId Prop

  //#region TypeId Prop
  @prop()
  typeId?: number;
  //#endregion TypeId Prop

  //#region IncludeInFrontStore Prop
  @prop({defaultValue:false})
  includeInFrontStore: boolean;
  //#endregion IncludeInFrontStore Prop

  //#region IncludeInPOS Prop
  @prop({defaultValue:false})
  includeInPOS: boolean;
  //#endregion IncludeInPOS Prop

}
