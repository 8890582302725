//Generated Imports
import { gridColumn, actionColumn } from '@rxweb/grid';

export class MoveNoteDocumentCorporateSearchViewModel {

  supplierId: string;

  id: number;

  @gridColumn({ name: 'isChecked', style: { width: "1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindMoveNoteDocRadio' }, columnIndex: 0, headerKey: 'MoveNoteDocumentComp_SelectAction_gh', keyColumn: false })
  isChecked: boolean

  isAllowMoveNoteDocument: boolean
  
  //#region fullName
  @gridColumn({ parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'MoveNoteDocumentComp_CorporateName_gh', keyColumn: false })
  company: string;
  //#endregion fullName

  //#region salesAdvisor
  @gridColumn({ parameter: true, style: { width: "22%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: true, headerKey: 'MoveNoteDocumentComp_CorporateSalAdv_gh', keyColumn: false })
  salesAdvisor: string;
  //#endregion salesAdvisor

  //#region introduceBy
  @gridColumn({ parameter: false, style: { width: "16%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: true, headerKey: 'MoveNoteDocumentComp_CorporateIntroBy_gh', keyColumn: false })
  introduceBy: string;
  //#endregion introduceBy

  //#region countryName
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: true, headerKey: 'MoveNoteDocumentComp_CorporateCountry_gh', keyColumn: false })
  countryName: string;
  //#endregion countryName

  //#region industryTypeName
  @gridColumn({ parameter: false, style: { width: "19%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 5, allowSorting: true, headerKey: 'MoveNoteDocumentComp_CorporateIndustry_gh', keyColumn: false })
  industryTypeName: string;
  //#endregion industryTypeName
  UserProfilePicture:string;
  totalCount: number;
}
