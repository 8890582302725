import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractMessageTemplate } from '../domain/abstract-message-template';
import { MessageTemplate, vMessageTemplate } from "../../../../models/extended-models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { MessageTemplateAddComponent } from '../add/message-template-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@access({ accessLevel: RolePermissionEnums.MessageTemplates, action: "get" })

@multilingual("MessageTemplateListComponent")
@Component({
    selector: "app-message-template-list",
    templateUrl: './message-template-list.component.html'
})
export class MessageTemplateListComponent extends AbstractMessageTemplate implements OnInit, OnDestroy {
    messageTemplate: vMessageTemplate[];
    subscription: any;
    messageTemplateGrid: AppGrid;
    messageTemplateGridColumns: any[];
    messageTemplateAdd: any = MessageTemplateAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private router: Router, private applicationBroadcaster: ApplicationBroadcaster, modelView: ModalView, private title: Title,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.MessageTemplates;
        location.onPopState(() => this.dialog.hide());
    }


    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Message Templates - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vMessageTemplate[]) => {
            this.spin = false;
            this.messageTemplate = t;
            this.messageTemplateGrid = new AppGrid(t, vMessageTemplate, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.messageTemplateGrid.gridColumns.forEach(x => {
                if (this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "put" }) && this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "delete" });
                    }
                }
            })
            this.messageTemplateGrid.componentId = this.componentName;
            this.messageTemplateGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.messageTemplateGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.messageTemplateGrid.design(document.getElementById("message-template"));
            this.showComponent = true;
        })
    }

    onEdit(messageTemplate: vMessageTemplate) {
        //this.router.navigate(["message-templates", messageTemplate.messageTemplateID]);
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["message-templates", messageTemplate.messageTemplateID]));
        redirectOnCTRL(url,this.router,event);
    }

    onDelete(messageTemplate: vMessageTemplate) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [messageTemplate.messageTemplateID] }).subscribe(data => {
                    this.spin = false;
                    this.messageTemplateGrid.remove(messageTemplate.messageTemplateID);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/message-templates']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Message_Template");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.messageTemplate.filter(x => x.messageTemplateName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.messageTemplateID.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.subject.toString().toLocaleLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.messageTemplateGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.messageTemplateGrid = null;
        }
        setTimeout(() => {
            if (this.messageTemplateGrid == null || this.messageTemplateGrid == undefined) {
                this.messageTemplateGrid = new AppGrid(this.messageTemplate, vMessageTemplate, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.messageTemplateGrid.gridColumns.forEach(x => {
                    if (this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "put" }) && this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.messageTemplateGrid.authorize({ accessLevel: RolePermissionEnums.MessageTemplates, action: "delete" });
                        }
                    }
                })
                this.messageTemplateGrid.componentId = this.componentName;
                this.messageTemplateGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.messageTemplateGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.messageTemplateGrid.refresh("message-template");
                this.messageTemplateGrid.design(document.getElementById("message-template"));
            }
            this.messageTemplateGrid.search = value.target.value;
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "MessageTemplateListComponent";
    }

}
