import {vLegalOwnerBatchLookUpBase ,} from '@app/database-models'
//Generated Imports
export class vLegalOwnerBatchLookUp extends vLegalOwnerBatchLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}