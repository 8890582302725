<div id="print-barcode">
    <div *ngFor="let item of printBrocodeData">
        <div style="display: flex;gap: 0.5cm;font-size: small;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; margin-bottom: 0.25cm;page-break-inside: avoid;">
            <div
                style="border:0.027cm solid #000000; height:1.2cm; width:2.5cm;box-sizing: border-box; padding: 0.1cm;">
                <div style="display: flex; flex-direction:column;">
                    <img style="height: 0.7cm; width: 2.2cm;" src="{{item.barcodeUrl}}" />
                    <span style="padding-top: 0.027cm; font-size: 0.15cm;height: 0.2cm; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{item.categoryName | uppercase}}</span>
                </div>
            </div>
            <div
                style="border:0.027cm solid #000000; height:1.2cm; width:2.5cm;box-sizing: border-box; padding: 0.1cm; font-size: 0.15cm">
                <div style="display: flex; flex-direction:column;">
                    <div style="margin-bottom: 0.027cm;">
                        <span>{{item.serialNo}}</span>
                    </div>
                    <div style="display: flex; justify-content: space-between;margin-bottom: 0.027cm;">
                        <span *ngIf="(item.isRing || item.isBangle) && item.pieceSize && item.pieceSize != ''">SIZE:
                            {{item.pieceSize}}</span>
                        <span *ngIf="item.isRRP">RRP:
                            {{item.exportPrice | number:'1.2-2'}} USD</span>
                        <span *ngIf="!item.isRRP">
                            {{item.exportPrice | number:'1.2-2'}} USD</span>
                    </div>
                    <div style="margin-bottom: 0.027cm; height: 0.5cm; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;">
                        <span>{{item.productName | uppercase}}</span>
                    </div>
                    <!-- <div style="margin-bottom: 3px;">
                        <span>{{item.designName | uppercase}}</span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
