<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
    <div class="modal-content" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="SubProjectAddComponent_Title_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body pb-0">
            <div class="tabs-container people-tabs px-0">
                <ul id="menu_slider" class="nav nav-tabs flex-nowrap mb-0 slick-initialized slick-slider border-bottom"
                    role="tablist" style="overflow:visible;">
                    <li class="d-inline-block float-none">
                        <a class="nav-link" [class.active]="selectedTab == 'sub-sroject-workstream-info'"
                            (click)="activeTab('sub-sroject-workstream-info')">
                            <label class="mb-0" rxText="SubProjectAddComponent_SubProject_t"></label>
                        </a>
                    </li>
                    <li class="d-inline-block float-none">
                        <a class="nav-link" [class.active]="selectedTab == 'faberge-design'"
                            (click)="activeTab('faberge-design')">
                            <label class="mb-0" rxText="SubProjectAddComponent_FabergeDesign_t"></label>
                        </a>
                    </li>
                </ul>
                <div class="slick_demo-btn"></div>
                <div class="tab-content">
                    <div role="tabpanel" *ngIf="selectedTab == 'faberge-design'" class="tab-pane"
                        [class.active]="selectedTab == 'faberge-design'">
                        <div class="panel-body border-0 px-0" *ngIf="showComponent" [rxLocalisationInit]="componentName">
                            <div class="row mx-0"
                                [formGroup]="subProjectDesignSearchFormGroup" (keyup.enter)="searchDesign()">
                                <div class="col-xl-12">
                                    <div class="ibox-title bg-white px-0 border-bottom mb-4">
                                        <h5 rxText="SubProjectAddComponent_Title_Faberge_Design_t"></h5>
                                    </div>
                                </div>
                                <div class="col-md-6" (keyup.enter)="saveProject()">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                                            rxText="SubProjectAddEditComponent_Name_t"></label>
                                        <div class="col-md-8">
                                            <input type="text" formControlName="name" tabindex="1"
                                                rxPlaceholder="SubProjectAddComponent_Name_p" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"

                                            rxText="SubProjectAddEditComponent_StartDate_t"></label>
                                        <div class="col-md-8">
                                            <rx-date showAddon="true" formControlName="startDate" rxFocus
                                                placeholder="Select Start Date" pickerClass="form-control"
                                                [controlTabIndex]="3"></rx-date>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"

                                            rxText="SubProjectAddEditComponent_EndDate_t"></label>
                                        <div class="col-md-8">
                                            <rx-date showAddon="true" formControlName="endDate"
                                                placeholder="Select End Date" pickerClass="form-control"
                                                [controlTabIndex]="2"></rx-date>
                                            <small class="form-text text-danger" *ngIf="dateDesignError==true">End
                                                Date should be greater than Start Date<br /></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer px-0">
                                <button (click)="searchDesign()" class="btn btn-success" rxText="Btn_Search_t">
                                </button>
                            </div>
                            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 p-0 border-0">
                                <div class="table-responsive" *ngIf="isShowGrid">
                                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper pb-0 dt-bootstrap4">
                                        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                                            <rx-grid [design]="subProjectDesignListGrid"></rx-grid>
                                        </div>
                                    </div>
                                </div>
                                <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                                    <h4>{{ErrorMsg}}</h4>
                                </div>
                                <div class="modal-footer px-0" *ngIf="isShowGrid" [rxLocalisationInit]="componentName">
                                  <ng-container [rxLocalisationInit]="componentName" *ngIf="showSaveButton">
                                    <button (click)="save()" class="btn btn-success"
                                        [disabled]="this.subProjectArray.length === 0 && this.excludesubProjectArray.length == 0 && !this.headerBtnChk" rxText="Btn_Save_t">
                                    </button>
                                  </ng-container>
                                    <button (click)="hide()" class="btn btn-outline-primary ml-0" rxText="Btn_Close_t"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div role="tabpanel" *ngIf="selectedTab == 'sub-sroject-workstream-info'" class="tab-pane"
                        [class.active]="selectedTab == 'sub-sroject-workstream-info'">
                        <div class="panel-body border-0 px-0" [formGroup]="projectFormGroup" [rxLocalisationInit]="componentName"
                            *ngIf="showSubProjectForm">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-xl-6 pr-xl-6">
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_ProjectName_t"></label>
                                                <div class="col-md-12">
                                                    <input type="text" class="form-control" tabindex="1"
                                                        rxPlaceholder="SubProjectAddComponent_Name_p"
                                                        formControlName="projectName" rxFocus>
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.projectName.errors">{{projectFormGroup.controls.projectName.errorMessage}}</small>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_ProjectDescription_t"></label>
                                                <div class="col-md-12">
                                                    <textarea class="form-control" formControlName="projectDescription"
                                                        tabindex="3"
                                                        rxPlaceholder="SubProjectAddComponent_Description_p"
                                                        rows="5"></textarea>
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.projectDescription.errors">{{projectFormGroup.controls.projectDescription.errorMessage}}</small>
                                                </div>
                                            </div>
                                            <!-- <div class="form-group row">
                                                <label class="col-md-12 col-form-label"
                                                    rxText="SubProjectAddEditComponent_ProjectType_t"></label>
                                                <div class="col-md-12">
                                                    <select class="form-control" formControlName="projectType"
                                                       >
                                                        <option [selected]="true" value="">Select</option>
                                                        <option *ngFor="let item of projectLookup.projectTypeLookUp"
                                                            [value]="item.projectType">
                                                            {{item.projectTypeName}}</option>
                                                    </select>
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.projectType.errors">{{projectFormGroup.controls.projectType.errorMessage}}</small>
                                                </div>
                                            </div> -->
                                            <!-- <div class="form-group row">
                                                <label class="col-md-12 col-form-label"
                                                    rxText="SubProjectAddEditComponent_ProjectOwner_t"></label>
                                                <div class="col-md-12">
                                                    <select class="form-control" formControlName="projectOwner"
                                                       >
                                                        <option [selected]="true" value="">Select</option>
                                                        <option *ngFor="let item of projectLookup.projectOwnerLookUp"
                                                            [value]="item.userId">{{item.fullName}}</option>
                                                    </select>
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.projectOwner.errors">{{projectFormGroup.controls.projectOwner.errorMessage}}</small>
                                                </div>
                                            </div> -->
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_ProjectPicture_t"></label>
                                                <div class="col-md-12">
                                                    <app-file [fileViewModel]="fileViewModel" [tabindex]="'5'"
                                                        (files)="updateFiles($event)" [title] = "imageTooltip"></app-file>
                                                    <img *ngIf="projectFormGroup.value.displayImage && projectFormGroup.value.blobFileName==null"
                                                        src="{{projectFormGroup.value.imageURL1}}"
                                                        class="event-img  mt-2 upload-img-preview" width="100"
                                                        height="100" />
                                                    <img *ngIf="projectFormGroup.value.blobFileName!=null"
                                                        [src]="getImgContent()"
                                                        class="event-img mt-2 upload-img-preview" width="100"
                                                        height="100" />
                                                      <ng-container [rxLocalisationInit]="componentName" *ngIf="projectFormGroup.value.displayImage || projectFormGroup.value.blobFileName!=null">
                                                          <button
                                                              (click)="removeImage()"
                                                              class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                                                      </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_ProjectStartDate_t"></label>
                                                <div class="col-md-12">
                                                    <rx-date showAddon="true" [controlTabIndex]="7"
                                                        formControlName="projectStartDate"
                                                        placeholder="Select Start Date" pickerClass="form-control"
                                                       ></rx-date>
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.projectStartDate.errors">{{projectFormGroup.controls.projectStartDate.errorMessage}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-xl-6 px-xl-6">
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_Budget_t"></label>
                                                <div class="col-md-12">
                                                    <input type="text" class="form-control" formControlName="budget"
                                                        tabindex="2" rxPlaceholder="SubProjectAddComponent_Budget_p">
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.budget.errors">{{projectFormGroup.controls.budget.errorMessage}}</small>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_ActualCost_t"></label>
                                                <div class="col-md-12">
                                                    <input type="text" class="form-control" tabindex="4"
                                                        formControlName="actualCost"
                                                        rxPlaceholder="SubProjectAddComponent_ActualCost_p">
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.actualCost.errors">{{projectFormGroup.controls.actualCost.errorMessage}}</small>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_BudgetCostCertificate_t"></label>
                                                <div class="col-md-12">
                                                    <app-file [fileViewModel]="fileViewModelDoc" [tabindex]="'6'"
                                                        (files)="updateDocFiles($event)"></app-file>
                                                    <label class="mt-1">{{projectFormGroup.value.fileName1}}</label>
                                                    <ng-container [rxLocalisationInit]="componentName" *ngIf="projectFormGroup.value.fileName1 != null">
                                                      <button (click)="removeDoc()"
                                                          class="btn btn-danger ml-2" rxText="Btn_Remove_t"></button>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-12 col-form-label"

                                                    rxText="SubProjectAddEditComponent_ProjectIndicator_t"></label>
                                                <div class="col-md-12">
                                                    <input type="text" class="form-control" tabindex="8"
                                                        formControlName="projectIndicator" (keyup)="chekcValidation()"
                                                        rxPlaceholder="SubProjectAddComponent_ProgressIndicator_p">
                                                    <small class="form-text text-danger"
                                                        *ngIf="projectIndicatorError==true">You need to enter
                                                        appropriate value in this field</small>
                                                    <small class="form-text text-danger"
                                                        [class.d-block]="projectFormGroup.controls.projectIndicator.errors">{{projectFormGroup.controls.projectIndicator.errorMessage}}</small>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                              <label class="col-md-12 col-form-label"

                                                  rxText="SubProjectAddEditComponent_ProjectEndDate_t"></label>
                                              <div class="col-md-12">
                                                  <rx-date showAddon="true" [controlTabIndex]="9"
                                                      formControlName="projectEndDate" placeholder="Select End Date"
                                                      pickerClass="form-control">
                                                  </rx-date>
                                                  <small class="form-text text-danger" *ngIf="dateError==true">End
                                                      Date should be greater than Start Date<br /></small>
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hr-line-dashed border-top w-100"></div>
                            <div class="row mt-auto">
                                <div class="col text-right">
                                    <button class="btn btn-success mr-1" (click)="saveProject()" rxText="Btn_Save_t"></button>
                                    <button class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
