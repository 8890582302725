import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductAttributeValueBase {

//#region productVariantAttributeValueId Prop
        @prop()
        productVariantAttributeValueId : number;
//#endregion productVariantAttributeValueId Prop


//#region productVariantAttributeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantAttributeId : number;
//#endregion productVariantAttributeId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        name : string;
//#endregion name Prop


//#region priceAdjustment Prop
        @required()
        priceAdjustment : number;
//#endregion priceAdjustment Prop


//#region weightAdjustment Prop
        @required()
        weightAdjustment : number;
//#endregion weightAdjustment Prop


//#region isPreSelected Prop
        @required()
        isPreSelected : boolean;
//#endregion isPreSelected Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop





}