import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_TICKET_DETAIL_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [
        {
          a: {
            class: "text-blue font-weight-bold".split(" "),
            event: {
              click: "onRedirectLink"
            },
            childrens: [{
              text: {
                text: function (e) { return this[e.name] }
              }
            }],
            attributes: {
              href: function (e) {
                if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
                  return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
                }
              },
              "ng-reflect-router-link": function (e) {
                if (this.ticketDetailId != null || this.ticketDetailId != undefined) {
                  return "/ticket-details/" + this.ticketDetailId + "?activeTab=ticket-detail-edit";
                }
              },
              onClick: "return false;"
            }
          }
        }
      ]
    },
  };