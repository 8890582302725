import {ProductionFormMappingDetailBase, ProductionOrderFormBase,} from '@app/database-models'
import { numeric, prop, propArray, range, required, pattern } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ProductionOrderForm extends ProductionOrderFormBase
{


//#region Generated Reference Properties

   //#region quantity Prop
   @prop({defaultValue:1})
   @required()
   @numeric({allowDecimal:false,message:"Only Number Required"})
   // @range({minimumNumber:1,maximumNumber:2147483647,message:"Minimum Quantity should be 1"})
   @range({minimumNumber:1,maximumNumber:99999,message: 'Quantity must be from 1 to 99999' })
   quantity: number;
   //#endregion quantity Prop

//    @range({minimumNumber:1,maximumNumber:500,message:"Can not add more than 500 pieces at one time"})

    //#region relatedProductIds Prop
    @prop()
    relatedProductIds: string;
    //#endregion relatedProductIds Prop

    //#region rrp Prop
     @prop({defaultValue:0})
    rrp: number;
    //#endregion rrp Prop

    //#region peopleOrCorporate prop
    @prop()
    peopleOrCorporate: number;
    //#endregion peopleOrCorporate prop

    //#region clientBudget Prop
    @prop({defaultValue:0})
    @numeric({allowDecimal:true})
    @pattern({expression:{"budget":/^[0-9]\d{0,9}(\.\d{1,2})?$/},message:"Please enter valid Budget (10 digits and 2 decimal places are allowed)"})
    clientBudget: number;
    //#endregion clientBudget Prop

    //#region clientBudgetUSD Prop
    @prop({defaultValue:0})
    clientBudgetUSD: number;
    //#endregion clientBudgetUSD Prop

    //#region currencyID Prop
    @prop({defaultValue:0})
    currencyID: number;
    //#endregion currencyID Prop

    @prop()
    quoteRequiredId:number;

    @prop()
    designRequiredId:number;

    @prop()
    stoneRequiredId:number;

    // @prop({defaultValue:0})
    @prop()
    quoteRequired: boolean;

    @prop()
    stoneViewingRequired: boolean;

    @prop()
    designsRequired: boolean;

    @prop()
    formMappingDetailIds: string;

    @prop()
    formTypeName:string

    // @prop()
    // isAllowDelete:boolean

    @prop()
    isSubmitForApproval:boolean;

    @prop()
    isAllowApprove:boolean;

    @prop()
    isUpdate:boolean;

    @prop()
    clientName:string;

    @prop()
    productID:number;

    @prop()
    corporateName:string;

    @prop()
    pieceIds:string;

    @prop()
    pieceName:string;

    @prop()
    isNewDetailAdded:boolean;

    @prop()
    isAllowComplete:boolean;

    @prop()
    isAllowConfirm:boolean;

    @prop()
    isWholeSaleTeam:boolean;

//#region productionFormMappingDetails Prop
    @propArray(ProductionFormMappingDetailBase)
    productionFormMappingDetails: ProductionFormMappingDetailBase[];
//#endregion productionFormMappingDetails Prop

//#endregion Generated Reference Properties


}
