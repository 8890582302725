import {vAddressLookupBase,} from '@app/database-models'
//Generated Imports
export class vAddressLookup extends vAddressLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties











}