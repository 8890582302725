import {ProductPieceGemstoneBase,CategoryBase,GemStoneClarityBase,GemStoneColorBase,GemStoneCutTypeBase,GemStoneSaturationBase,LegalOwnerBase,WarehouseBase,BookingGemstoneBase,ProductGemstoneCertificateBase,GemStonePictureBase,ProductGemstoneMappingBase,GemstoneOrderGemstoneMapingBase,ProductPieceGemstoneMappingBase,GemStoneNoteBase,} from '@app/database-models'
import { FileDetailViewModel } from 'src/app/view-model/file-detail-view-model';
import { prop, numeric } from '@rxweb/reactive-form-validators';
//Generated Imports
export class ProductPieceGemstone extends ProductPieceGemstoneBase implements FileDetailViewModel
{

    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    fileData1:string;
    @prop()
    fileExtention1:string;
    @prop()
    fileName1:string;
    @prop()
    fileData2:string;
    @prop()
    fileExtention2:string;
    @prop()
    fileName2:string;
    @prop()
    productId:string;
    @prop()
    filePath: string;
    @prop()
    fileURL: string;
    


//#region Generated Reference Properties
//#region category Prop
category : CategoryBase;
//#endregion category Prop
//#region gemStoneClarity Prop
gemStoneClarity : GemStoneClarityBase;
//#endregion gemStoneClarity Prop
//#region gemStoneColor Prop
gemStoneColor : GemStoneColorBase;
//#endregion gemStoneColor Prop
//#region gemStoneCutType Prop
gemStoneCutType : GemStoneCutTypeBase;
//#endregion gemStoneCutType Prop
//#region gemStoneSaturation Prop
gemStoneSaturation : GemStoneSaturationBase;
//#endregion gemStoneSaturation Prop
//#region legalOwner Prop
legalOwner : LegalOwnerBase;
//#endregion legalOwner Prop
//#region warehouse Prop
warehouse : WarehouseBase;
//#endregion warehouse Prop
//#region bookingGemstones Prop
bookingGemstones : BookingGemstoneBase[];
//#endregion bookingGemstones Prop
//#region productGemstoneCertificates Prop
productGemstoneCertificates : ProductGemstoneCertificateBase[];
//#endregion productGemstoneCertificates Prop
//#region gemStonePictures Prop
gemStonePictures : GemStonePictureBase[];
//#endregion gemStonePictures Prop
//#region productGemstoneMappings Prop
productGemstoneMappings : ProductGemstoneMappingBase[];
//#endregion productGemstoneMappings Prop
//#region gemstoneOrderGemstoneMapings Prop
gemstoneOrderGemstoneMapings : GemstoneOrderGemstoneMapingBase[];
//#endregion gemstoneOrderGemstoneMapings Prop
//#region productPieceGemstoneMappings Prop
productPieceGemstoneMappings : ProductPieceGemstoneMappingBase[];
//#endregion productPieceGemstoneMappings Prop
//#region gemStoneNotes Prop
gemStoneNotes : GemStoneNoteBase[];
//#endregion gemStoneNotes Prop
//#region productPieceGemstones Prop
 productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop
//#region IsSuccess Prop
IsSuccess : Boolean;
//#endregion IsSuccess Prop
@prop()
@numeric({ allowDecimal: true })
leftOverCarat: number;

//#endregion Generated Reference Properties



    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;

}