import { gridColumn } from "@rxweb/grid"

export class vLaboratoryLookUpBase {

    //#region laboratoryId Prop
    @gridColumn({ visible: true, columnIndex: 0, allowSorting: true, headerKey: 'laboratoryId', keyColumn: true })
    laboratoryId: number;
    //#endregion laboratoryId Prop


    //#region laboratoryName Prop
    @gridColumn({ visible: true, columnIndex: 1, allowSorting: true, headerKey: 'laboratoryName', keyColumn: false })
    laboratoryName: string;
    //#endregion laboratoryName Prop

}