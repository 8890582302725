import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import {  MessageTemplateDetail } from '@app/models';
import { AbstractMessageTemplate } from '../domain/abstract-message-template';
import { Router } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { MessageTemplateDetailBase } from '../../../../models/database-models';
import { MessageTemplate } from '@app/models';
import { LanguagesEnums } from '../../../../custom-enum/language-enums';
import { TINY_MCE_KEY, CLIENT_URL } from 'src/app/domain/system-constant';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { MessageTemplateEditComponent } from '../edit/message-template-edit.component';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("MessageTemplateAddComponent")
@access({ accessLevel: RolePermissionEnums.MessageTemplates, action: "post" })


@Component({
    selector: "app-message-template-add",
    templateUrl: './message-template-add.component.html'
})
export class MessageTemplateAddComponent extends AbstractMessageTemplate implements OnInit, OnDestroy {
    messageTemplate: MessageTemplate;
    subscription: any;
    apiKey:any;
    messageKey:any;
    messageTemplateEdit:any=MessageTemplateEditComponent;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;


    _this;

    constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el:ElementRef, private title: Title) {
        super();
        this.modalView = modelView;
        this.moduleID = RolePermissionEnums.MessageTemplates;
        this._this = this;
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
        this.title.setTitle("Message Templates - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.apiKey = TINY_MCE_KEY;
        this.lookup([{ hostUri: CLIENT_URL, path: "assets/message-token/message-token.json", propName: "MessageKey" },{ path: USER_LOOKUPS.emailAccountLookUp, propName: "emailAccountLookup" }, { path: USER_LOOKUPS.languageLookUp, propName: "languageLookup" }]).subscribe(response => {
            this.messageTemplate = new MessageTemplate();
            this.messageTemplate.messageTemplateID = 0;
            this.messageTemplate.statusId = StatusEnum.Active;
            this.messageTemplateLookup = response;
            let messageTemplateDetail = new MessageTemplateDetailBase();
            messageTemplateDetail.messageTemplateDetailID = 0;
            messageTemplateDetail.messageTemplateID = 0;
            messageTemplateDetail.statusId = StatusEnum.Active;
            messageTemplateDetail.languageId= LanguagesEnums.English;
            this.messageTemplate.messageTemplateDetails = new Array<MessageTemplateDetailBase>(messageTemplateDetail);
            this.messageTemplateFormGroup = this.formBuilder.formGroup(MessageTemplate, this.messageTemplate) as IFormGroup<MessageTemplate>;
            this.showComponent = true;

        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    addMessageTemplate(isRedirect: boolean) {
        this.messageTemplateFormGroup.submitted = true;
        if (this.messageTemplateFormGroup.valid) {
            this.spin = true;
            this.messageTemplateFormGroup.patchValue({
                isNewLanguageMessageTemplate:false
            });
            this.messageTemplateFormGroup.value.description = this.messageTemplateFormGroup.value.description == null ? '': this.messageTemplateFormGroup.value.description.trim();
            this.post({ body: this.messageTemplateFormGroup.value }).subscribe(data => {
                this.spin = false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
                    if (existsAlert) {
					 //this.toastr.success("Data Added Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.router.navigate(['/message-templates', data]) : this.router.navigate(['/message-templates']);

            })
        }else{
            this.breakLoop = false;
            this.validField.forEach(x => {
                if (this.messageTemplateFormGroup.controls[x] != undefined && this.messageTemplateFormGroup.controls[x] != null) {
                    if (this.messageTemplateFormGroup.controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                }else{
                    if (this.messageTemplateDetails[0].controls[x].invalid && !this.breakLoop) {
                        const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + x + '"]');
                        invalidControl.focus();
                        this.breakLoop = true;
                    }
                }
            });
        }

    }
    get componentName(): string {
        return "MessageTemplateAddComponent";
    }

}
