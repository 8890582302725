import {vChainStyleLookUpBase,} from '@app/database-models'
//Generated Imports
export class vChainStyleLookUp extends vChainStyleLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}