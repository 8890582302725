import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class EventCustomerMappingBase {

        //#region eventCustomerID Prop
        @prop()
        eventCustomerID: number;
        //#endregion eventCustomerID Prop


        //#region eventID Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        eventID: number;
        //#endregion eventID Prop


        //#region customerID Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        customerID: number;
        //#endregion customerID Prop


        //#region invited Prop
        @prop({ defaultValue: false })
        invited: boolean;
        //#endregion invited Prop


        //#region accepted Prop
        @prop({ defaultValue: false })
        accepted: boolean;
        //#endregion accepted Prop


        //#region declined Prop
        @prop({ defaultValue: false })
        declined: boolean;
        //#endregion declined Prop


        //#region attended Prop
        @prop({ defaultValue: false })
        attended: boolean;
        //#endregion attended Prop


        //#region sendEInvitation Prop
        @prop({ defaultValue: false })
        sendEInvitation: boolean;
        //#endregion sendEInvitation Prop


        //#region eInvitationSentOn Prop
        @prop()
        eInvitationSentOn: Date;
        //#endregion eInvitationSentOn Prop


        //#region otherPeopleInvited Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @prop()
        otherPeopleInvited: number;
        //#endregion otherPeopleInvited Prop


        //#region guestCount Prop
        //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
        @prop()
        guestCount: number;
        //#endregion guestCount Prop


        //#region addedBy Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        addedBy: number;
        //#endregion addedBy Prop


        //#region involved Prop
        @prop({ defaultValue: false })
        involved: boolean;
        //#endregion involved Prop

          //#region peopleOrCorporate Prop
          @prop({ defaultValue: false })
          peopleOrCorporate: Number;
          //#endregion peopleOrCorporate Prop

        //#region customerID Prop
        @prop({ defaultValue: 0 })
        @required()
        corporateID: number;
        //#endregion customerID Prop




}