import {vShoppingCartTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vShoppingCartTypeLookUp extends vShoppingCartTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}