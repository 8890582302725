import { vProjectEventsBase} from '@app/database-models'
//Generated Imports
export class vProjectEvent extends vProjectEventsBase 
{




//#region Generated Reference Properties
//#endregion Generated Reference Properties
}
