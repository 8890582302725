import { prop,notEmpty,range,required} from "@rxweb/reactive-form-validators"


export class ChainStyleBase {
    //#region chainStyleID Prop
    @prop()
    chainStyleID : number;
    //#endregion chainStyleID Prop

    //#region styleName Prop
    @prop()
    @notEmpty()
    styleName : string;
    //#endregion styleName Prop

    //#region statusId Prop
    @range({minimumNumber:1,maximumNumber:2147483647})
    @required()
    statusId : number;
    //#endregion statusId Prop
}