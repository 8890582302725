import {vGemfieldSalesChannelLookUpBase,} from '@app/database-models'
//Generated Imports
export class vGemfieldSalesChannelLookUp extends vGemfieldSalesChannelLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}