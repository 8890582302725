import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
import { User, vNationalitieLookUp, vCustomerRegistrationTypeLookUp, vSalesAdvisorLookUp, vCustomerRoleTypeLookUp, vRoleLookUp, vTitleLookUp, vCountryLookUp, vStateProvinceLookUp, vExternalDepartment, vInternalDepartment, vSalesChannel, vCustomerRoleLookUp, vSystemRatingLookUp, vIndustryTypeLookUp, vCategoryLookUp, vkeyRelationshipHolderLookUp, vCustomerContactMethodTypeLookUp, vDepartment, vMusicPreferenceLookUp, vDepartmentLookUp, vLanguageLookUp, UserRole, vSpokenLanguageLookUp, vCollectionLookUp, vParentCollectionLookup, UserDetail } from '@app/models';
import { CoreComponent, } from '@rxweb/angular-router';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { UserDetailBase, SubscriptionBase } from '@app/database-models';
import { FormArray, FormGroup } from '@angular/forms';
import { List } from '@rxweb/generics';
import { Router } from '@angular/router';
import { StatusEnum } from '../../../../enums/status.enum';
import { FileOutputViewModel } from '../../../../view-model/file-output-view-model';
import { FileInputViewModel } from '../../../../view-model/file-input-view-model';
import { FileSizeEnums, FileTypeEnums } from '../../../../custom-enum/file-type-enums';
import { SUBSCRIPTION } from '../../../../collections/subscription.collection';
import { AppGrid } from '../../../../domain/app-grid';
import { CustomerRoleTypeEnum } from '../../../../enums/customer-role-type.enum';
import { SystemRatingEnum } from '../../../../enums/system-rating.enum';
import { RoleTypesEnums } from '../../../../custom-enum/role-type-enum';
import { dateValidation, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { vReportingHeadLookup } from '../../../../models/extended-models/v-reporting-head-lookup';
import * as CryptoJS from 'crypto-js';
import { vSalesUserLookUpBase } from 'src/app/models/database-models/v-sales-user-look-up';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { ExistsUserSearchComponent } from '../search/exists-user-search.component';
import { vValidateUser } from 'src/app/models/extended-models/v-validate-user';
import { v4 as uuidv4 } from "uuid";
import { BLOB_BASE } from "src/app/domain/system-constant";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { showToolTip } from "src/app/domain/customize-plugin/bind-slider";
@http({
  path: 'api/Users'
})
export class AbstractUser extends CoreComponent {
  isNoRecordFound: boolean;
  isShowGridColumn: boolean = false;
  userFormGroup: IFormGroup<User>
  userDetailFormGroup: IFormGroup<UserDetail>
  userDetail: UserDetail;
  user: User;
  isDDStateChange = false;
  personaNonGrataMean: string;
  showSelectOption: boolean = false;
  userDetailBase: UserDetailBase;
  showComponent: boolean = false;
  spin: boolean = false;
  toastr: BaseToastr
  dialog: BaseDialog;
  usersGrid: AppGrid;
  modalView: ModalView;
  activeStep: string = 'step-1';
  step: number = 1;
  validArray: List<boolean>;
  router: Router;
  id: number = 0;
  roleId: number = 0;
  roleTypeId: number;
  dateOfBirthError: boolean;
  anniversaryDateError: boolean;
  countryId: number;
  dobError: boolean = false;
  dateError: boolean = false;
  keyName: string = "userRoleSelect";
  userRoleValidation: boolean = false;
  userRoles: List<vRoleLookUp>;
  stateProvinceLookUp: vStateProvinceLookUp[];
  isRoles: boolean = false;
  fileUrl: string;
  fileName: string;
  titleLookUp: vTitleLookUp[];
  nationalityLookUp: vNationalitieLookUp[];
  showAdminRole: boolean = false;
  showCustomerRole: boolean = true;
  newSubscription: any[] = SUBSCRIPTION;
  isshowStateTextbox: boolean = true;
  subscriptionList: List<SubscriptionBase>;
  oldCountryId: number;
  country: number;
  collectionLookup: any;
  showEmailNotificationCheckbox: boolean = false;

  categoryLookup: any;
  userLookups: any = {
    //salesAdvisorLookUp: vSalesAdvisorLookUp,
    salesAdvisorLookUp: vSalesUserLookUpBase,
    salesChannel: vSalesChannel,
    customerRoleLookUps: vCustomerRoleLookUp,
    countryLookUp: vCountryLookUp,
    systemRatingLookUp: vSystemRatingLookUp,
    keyRelationshipHolderLookUp: vkeyRelationshipHolderLookUp,
    industryTypeLookUp: vIndustryTypeLookUp,
    categoryLookUp: vCategoryLookUp,
    parentCollectionLookup: vParentCollectionLookup,
    tabAccessLevel: null,
    hasPurchasedFromList: null,
    reportToLookUp: vReportingHeadLookup,
    externalDepartment: vExternalDepartment,
    internalDepartment: vInternalDepartment
  }
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  fileOutputViewModel1: FileOutputViewModel = new FileOutputViewModel();
  fileViewModel: FileInputViewModel;
  fileInputViewModel1: FileInputViewModel;
  browserStorage: BrowserStorage;
  showInternalDepartment: boolean = false;
  showExternalDepartment: boolean = true;
  validateUser: any;
  oldFirstName:string;
  oldLastName:string;
  oldMiddleName:string;

  constructor(private blobState: BlobUploadsViewStateService) {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.Image;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;

    this.fileInputViewModel1 = new FileInputViewModel();
    this.fileInputViewModel1.fileMode = FileTypeEnums.Pdf;
    this.fileInputViewModel1.fileAllowSize = FileSizeEnums.FourMB;
    this.browserStorage = new BrowserStorage();
  }
  selectedTab: string = "user-edit"
  statusEnums: any = StatusEnum;
  badRequest = (data) => {
    this.step = this.step - 1;
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
      if (this.step == Steps.BasicInfo) {
        this.activeStep = "step-" + Steps.BasicInfo;
      }
      else (this.step == Steps.ContactInfo)
      {
        this.activeStep = "step-" + Steps.ContactInfo;
      }

    })
  }
  userLookUps: any = {
    customerRegistrationTypeLookUp: vCustomerRegistrationTypeLookUp,
    salesAdvisorLookUp: vSalesAdvisorLookUp,
    customerRoleTypeLookUp: vCustomerRoleTypeLookUp,
    roleLookUp: vRoleLookUp,
    // titleLookUp: vTitleLookUp,
    countryLookUp: vCountryLookUp,
    stateProvinceLookUp: vStateProvinceLookUp,
    user: User,
    customerContactMethodTypeLookUp: vCustomerContactMethodTypeLookUp,
    keyRelationshipHolderLookUp: vkeyRelationshipHolderLookUp,
    departmentLookUp: vDepartmentLookUp,
    musicPreferenceLookUp: vMusicPreferenceLookUp,
    systemRatingLookUp: vSystemRatingLookUp,
    languageLookUp: vLanguageLookUp,
    spokenLanguageLookUp: vSpokenLanguageLookUp,
    hasPurchasedFromList: null,
  }

  peopleWizard(step: number, next: boolean) {
    this.dateOfBirthError = false; 

    this.anniversaryDateError = false;
    if (this.userFormGroup.value.dateOfBirth != null) {
      if (dateValidation(this.userFormGroup.value.dateOfBirth) == false) {
        this.dateOfBirthError = true;
      }
      else {
        this.dateOfBirthError = false;
      }
    }

    if (this.userFormGroup.value.anniversaryDate != null) {
      if (dateValidation(this.userFormGroup.value.anniversaryDate) == false) {
        this.anniversaryDateError = true;
      }
      else {
        this.anniversaryDateError = false;
      }
    }
    //if (this.dateOfBirthError == false && this.anniversaryDateError == false) {
    // this.dateOfBirthError = false;
    // this.anniversaryDateError = false;
    this.userFormGroup.submitted = true;
    this.userRoleValidation = false;
    let isStepValidated = this.validateForm(step);
    if(isStepValidated == true)
    {
  if(
    this.oldFirstName !=this.userFormGroup.value.userDetails[0].firstName.toLowerCase().trim() || 
    this.oldLastName != this.userFormGroup.value.userDetails[0].lastName.toLowerCase().trim() ||
    this.oldMiddleName != (this.userFormGroup.value.userDetails[0].middleName != null ? this.userFormGroup.value.userDetails[0].middleName.toLowerCase().trim() : "")
    ){
    
    
    this.oldFirstName=this.userFormGroup.value.userDetails[0].firstName.toLowerCase().trim();
    this.oldLastName=this.userFormGroup.value.userDetails[0].lastName.toLowerCase().trim();
    this.oldMiddleName = this.userFormGroup.value.userDetails[0].middleName != null ? this.userFormGroup.value.userDetails[0].middleName.toLowerCase().trim() : ""
      this.get({ path: "api/UserDetail", queryParams: { firstName: encodeURIComponent(this.userFormGroup.value.userDetails[0].firstName)
        ,middleName: encodeURIComponent(this.userFormGroup.value.userDetails[0].middleName),
        lastName:encodeURIComponent(this.userFormGroup.value.userDetails[0].lastName)  } }).subscribe((t: vValidateUser) => {
        this.validateUser = t;
        if(this.validateUser.length > 0)
        {
          this.step -= 1;
          this.activeStep = "step-" + this.step;
      this.modalView.show(ExistsUserSearchComponent, {
        "validateUser": this.validateUser
       }).then(t => {

      });
    }
  });
   }
  }
    if (this.dateOfBirthError == false && this.anniversaryDateError == false) {
      if (!next) {
        isStepValidated = true;
      }
      if (isStepValidated) {
        this.userFormGroup.submitted = false;
        if (next) {
          this.step += 1;
        }
        else {
          this.step -= 1;
        }
        this.activeStep = "step-" + this.step;
        if (this.step == Steps.Final) {
          this.addUser();
        }
      } else {
        var existsAlert = getLocalizedValue("Enter_Valid_Details");
        if (existsAlert) {
          //this.toastr.warning("Please enter valid details !");
          this.toastr.warning(existsAlert);
        }
      }
    } else {
      var existsAlert = getLocalizedValue("Enter_Valid_Details");
      if (existsAlert) {
        //this.toastr.warning("Please enter valid details !");
        this.toastr.warning(existsAlert);
      }
    }
    this.personaNonGrataMean =  getLocalizedValue("UserAddEditComponent_PersonaNonGrataMean_t");
    setTimeout(() => {      
      showToolTip("user_IsUnwelcome_helpToolTip",this.personaNonGrataMean);
    }, 1000);

    // }
  }

  removeImage() {
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.Image;
    this.fileViewModel.fileAllowSize = FileSizeEnums.FourMB;
      this.userFormGroup.patchValue({
        fileData: null,
        blobFileName:null,
        fileExtention: null,
        fileName: null,
        displayImage: false,
        isImageRemoved: true
      });
  }
  removeFile() {
    this.userFormGroup.patchValue({
      fileData1: null,
      blobFileName2:null,
      fileExtention1: null,
      fileName1: null,
      displayImage: false,

    });
    this.fileName = null
    this.fileUrl = "";
    this.post({ path: 'api/SearchCommon/RemoveBlob', body: { "Query": this.userFormGroup.value.blobFileName2 } }).subscribe(x => {
      this.userFormGroup.patchValue({
        fileData1: null,
        blobFileName2:null,
        fileExtention1: null,
        fileName1: null,
        displayImage: false,
  
      });

    })
  }

  validateForm(step: number): boolean {
    this.validArray = new List<boolean>();
    if (step == Steps.BasicInfo) {
      let controlsUserDetails = ["title", "firstName", "lastName", "profileAbout", "nationality", "familyMembers", "hobbies"];
      controlsUserDetails.forEach(control => {
        let data = this.userDetailFormArray;
        for (let i = 0; i < data.length; i++) {
          this.validArray.add(data[i].controls[control].valid);
        }
      });
      let controlsUsers = ["password", "dateOfBirth", "anniversaryDate"];
      controlsUsers.forEach(control => {
        this.validArray.add(this.userFormGroup.controls[control].valid);
      });
      this.userFormGroup.patchValue({ username: this.userDetailFormArray[0].value.firstName + " " + this.userDetailFormArray[0].value.lastName })
       if (!this.checkUserRoleSelected()) {
         this.userRoleValidation = true;
         this.validArray.add(false);
       }
    }
    else if (step == Steps.ContactInfo) {
      let controlsUserDetails = ["secondaryEmail", "department", "phoneNumber", "workPhoneNumber", "mobileNumber", "streetAddress", "streetAddress2", "city", "countryId", "stateProvinceId", "stateProvinceName", "zipPostalCode", "poBox", "webSite", "skypeName", "salesadvisorsID"];
      controlsUserDetails.forEach(control => {
        let data = this.userDetailFormArray;
        for (let i = 0; i < data.length; i++) {
          this.validArray.add(data[i].controls[control].valid);
        }
      });
      let controlsUsers = ["email", "roleTypeId", "registrationTypeID", "registrationDate", "lastLockoutDate", "stateName"];
      controlsUsers.forEach(control => {
        this.validArray.add(this.userFormGroup.controls[control].valid);
      });
      // this.userFormGroup.patchValue({ email: this.userDetailFormArray[0].value.email })
    }
    return !this.validArray.any(x => x == false);
  }


  get userDetailFormArray(): FormArray {
    let userDetailFormArray = this.userFormGroup.controls["userDetails"] as IAbstractControl;
    let data = <FormArray>userDetailFormArray["controls"]
    return data;
  }
  get userRoleFormArray(): FormArray {
    let userDetailFormArray = this.userFormGroup.controls["userRoles"] as IAbstractControl;
    let data = <FormArray>userDetailFormArray["controls"];
    return data;
  }
  get adminRoleFormArray(): FormArray {
    let userDetailFormArray = this.userFormGroup.controls["adminRoles"] as IAbstractControl;
    let data = <FormArray>userDetailFormArray["controls"];
    return data;
  }
  get userSubscriptionFormArray(): FormArray {
    let userDetailFormArray = this.userFormGroup.controls["subscriptions"] as IAbstractControl;
    let data = <FormArray>userDetailFormArray["controls"]
    return data;
  }


  async addUser() {
    this.userFormGroup.value.adminRoles = this.userFormGroup.value.adminRoles.filter(function(item) {
      return item.roleId != 2147483647
    })
    this.userFormGroup.value.userRoles = this.userFormGroup.value.userRoles.filter(function(item) {
      return item.roleId != 2147483647
    })

    this.userFormGroup.submitted = true;
    // if(this.userFormGroup.controls.salesAdvisorsID.value === undefined)
    // {
    //   this.userFormGroup.patchValue({
      //     salesAdvisorsID:0
      //   })
      // }
       if (this.userFormGroup.valid) {
        if(this.userFormGroup.value.userDetails[0].countryId == null || this.userFormGroup.value.userDetails[0].countryId == undefined)
        {
           this.userFormGroup.get('userDetails.0.stateProvinceId').patchValue(0);
        }
        else
        {

        if(this.userFormGroup.value.userDetails[0].countryId.toString() == "")
        {
          this.userFormGroup.get('userDetails.0.stateProvinceId').patchValue(0);
        }
      }
      this.spin = true;
      if (this.userFormGroup.value.fileData) {

        let res = await this.uploadToBlob(this.fileOutputViewModel, this.userFormGroup.value.blobPath);
        this.userFormGroup.patchValue({
          fileData: null,
          blobFileName: res
        });
      }
      if (this.userFormGroup.value.fileData1) {

        let res = await this.uploadToBlob(this.fileOutputViewModel1, this.userFormGroup.value.blobPath2);
        this.userFormGroup.patchValue({
          fileData1: null,
          blobFileName2: res
        });
      }
      this.post({ body: this.userFormGroup.value }).subscribe(data => {
        if (data) {
          this.spin = false;
          this.id = data as number;
          var existsAlert = getLocalizedValue("Data_Added");
          if (existsAlert) {
            //this.toastr.success("Data Added Successfully")
            this.toastr.success(existsAlert);
          }
          this.activeStep = "step-" + this.step;
        }
      })
     } else {
       var existsAlert = getLocalizedValue("Enter_Valid_Details");
       if (existsAlert) {
         //this.toastr.warning("Please enter valid details !");
         this.toastr.warning(existsAlert);
       }
     }
  }

  goToUser() {
    this.router.navigate(['/users', this.id], { queryParams: { activeTab: this.selectedTab } })
  }

  checkUserRoleSelected(): boolean {
    let isSelected = false;
    let data = this.showCustomerRole == true ? this.userRoleFormArray : this.adminRoleFormArray;
    for (let i = 0; i < data.length; i++) {
      if (data[i].value.active == true) {
        return true;
      }
    }
    return isSelected;
  }

  activeTab(tabName: string) {
    this.selectedTab = tabName;
    this.router.navigate(["users", this.id], { queryParams: { activeTab: tabName } });
  }

  updateStatus($event) {
    if ($event.target.checked) {
      this.userFormGroup.patchValue({ statusId: StatusEnum.Active })
    } else {
      this.userFormGroup.patchValue({ statusId: StatusEnum.Inactive, isSSOEnabled: false }) //289781//12-06-2024//added SSO false if user set approval status false
    }
  }
  updateWelcomeEmailStatus($event){
    if ($event.target.checked) {
      this.userFormGroup.patchValue({ welcomeEmailstatusId: 1 })
    } else {
      this.userFormGroup.patchValue({ welcomeEmailstatusId: 0 })
    }
  }
  async updateFiles($event: FileOutputViewModel) {
    
    this.fileOutputViewModel = $event;
    this.userFormGroup.patchValue({
      fileData: $event.fileBaseString,
      fileExtention: $event.fileExtension,
      fileName: $event.fileName,
      displayImage: $event.displayImage,
      imageUrl1: null,
      isImageRemoved: true,
      //blobFileName: uploadedFileName,
      blobPath: BLOB_BASE.UserFiles,
      size: $event.files.size
    });
  }
  async uploadFiles($event: FileOutputViewModel) {
    
    this.fileOutputViewModel1 = $event;
    this.userFormGroup.patchValue({
      fileData1: $event.fileBaseString,
      fileExtention1: $event.fileExtension,
      fileName1: $event.fileName,
      displayImage: $event.displayImage,
      //blobFileName2: uploadedFileName,
      blobPath2: BLOB_BASE.UserDocumentFiles,
      size2: $event.files.size
    });
  }

  async uploadToBlob($event: FileOutputViewModel, blobPath: string) {
    let fileUploadRes = [];
    let newGUID = uuidv4();
    let fileName = blobPath + `${newGUID}_${new Date().toISOString()}.${$event.fileName
      .split(".")
      .pop()}`;
    var res = await this.blobState.uploadItems($event.files, fileName);
    // fileUploadRes.push(res);

    let url = res._response.request.url;
    let uploadedFileName = blobPath + decodeURIComponent(
      url.split("?")[0].split("/").pop()
    );

    // IF SUCCEED TO UPLOAD
    if (res && (res._response.status == 200 || res._response.status == 201)) {
      if (uploadedFileName != undefined) fileUploadRes.push(uploadedFileName);
    } else {
      // IF FAILED TO UPLOAD
      console.log("Failed to upload file to blob from client!", res);
      this.toastr.error(
        "Error occured while uploading file! Please try again."
      );
      if (uploadedFileName != undefined) {
        this.modalView
          .show(BedRequestComponent, {
            validationMessages: JSON.stringify(
              uploadedFileName
            ),
            headerMessage: "Image failed to upload:",
          })
          .then(() => { });
      }
    }
    return uploadedFileName;
  }

  getRoleName(roleId: number): string {
    let roleName = this.userRoles.firstOrDefault(x => x.roleId == roleId);
    return roleName["roleName"];
  }
  getSubscriptionName(entityTypeId: number): string {
    let obj = this.newSubscription.find(x => x.id == entityTypeId);
    if (obj) {
      return obj.value
    }
    return "";
  }
  selectState(event) {
    if(event.item!=null && event.item != undefined)
      this.stateChange(event.item.key);
  }
  stateChange(id: number, onInit: boolean = false) {
    this.showSelectOption = onInit == true ? false : true;
    this.countryId = id;
    if (this.country != this.countryId) {
      this.userFormGroup.patchValue({
        stateName: null
      })
    }
    this.isDDStateChange = false;
    setTimeout(function () {
      this.isDDStateChange = true;
    }.bind(this), 4);
    this.stateProvinceLookUp = [];
    this.stateProvinceLookUp = this.userLookUps.stateProvinceLookUp;
    this.stateProvinceLookUp = this.stateProvinceLookUp.filter(x => x.countryId == this.countryId);
    this.isshowStateTextbox = this.stateProvinceLookUp.length > 0 ? false : true;


  }
  bindSubscription(): SubscriptionBase[] {
    let subScriptionArray = new Array<SubscriptionBase>();
    this.newSubscription.forEach(data => {
      let obj = new SubscriptionBase();
      var editObj = null;
      if (this.id > 0) {
        editObj = this.subscriptionList.where(x => x.entitytypeId == data.id).firstOrDefault();
      }
      if (editObj && this.id > 0) {

        obj.createdOn = editObj["createdOn"];
        obj.entitytypeId = editObj["entitytypeId"];
        obj.isGeneralNewsLetter = editObj["isGeneralNewsLetter"];
        obj.isNewProductActive = editObj["isNewProductActive"];
        obj.isActive = editObj["isActive"];
        obj.userId = editObj["userId"];
        obj.email = editObj["email"] == "" ? "abc@g.com" : editObj["email"];
        obj.oldSubscriptionID = editObj["oldSubscriptionID"];
        obj.subscriptionID = editObj["subscriptionID"];
        obj.isSubscribedEver = editObj["isSubscribedEver"];
      }
      else {
        obj.createdOn = new Date();
        obj.entitytypeId = data.id;
        obj.isGeneralNewsLetter = false;
        obj.isNewProductActive = false;
        obj.subscriptionID = 0;
        obj.isActive = false;
        obj.oldSubscriptionID = 0;
        obj.userId = null;
        obj.email = 'abc@g.com';
        obj.isSubscribedEver = false;
      }
      subScriptionArray.push(obj);
    })
    return subScriptionArray;
  }


  isAccessPermission(data: any) {
    if (data.selectedTab == 'mind-map') {
      if(dbAccessModule[RolePermissionEnums.MindMap]["get"]) {
        return true;
      }
      else {
        return false;
      }
    }

    let isRecordLevelRolePermisison = false;
    let isActiveTabRolePermisison = false
    let loginUserRolePermisison = true;
    let isUserRoleAllow = true;
    if (data.recordLevelPermisison.activePermission) {
      let isAccess = data.recordLevelPermisison.accessRole.find(x => x == this.roleId)
      if (isAccess) {
        isRecordLevelRolePermisison = true;
      }
      //if (this.browserStorage.local.get("userId", true) != this.id) {
      if (data.tabName == "Leave/Travel Request") {
        let userRoles = this.browserStorage.local.get("userRoles", true);
        userRoles = userRoles.split(",");
        let isRestrictUserRole = data.recordLevelPermisison.restrictUserRole;
        if (isRestrictUserRole) {
          userRoles.forEach(x => {
            if (isRestrictUserRole.includes(Number(x))) {
              isUserRoleAllow = false;
            }
          })
        }
      }
      //}
    }
    if (data.isActiveTab) {
      isActiveTabRolePermisison = true;
    }
    if (data.loginUserPermisison.activePermission) {
      //login userId role Id
      //let isAccess = data.loginUserPermisison.accessRole.find(x => x == this.roleId)
      //if (isAccess) {
      //  flag = true;
      //}
    }
    if (isRecordLevelRolePermisison && isActiveTabRolePermisison && loginUserRolePermisison && isUserRoleAllow) {
      return true;
    }

    return false;
  }

  applyClass() {
    this.usersGrid.designClass.rowClass = [function () {
      //let data = this;
      //if (data.roleId == CustomerRoleTypeEnum.Admin) {
      //  return "ratingAdmin";
      //}
    }]
  }

}

export enum Steps {
  BasicInfo = 1,
  ContactInfo = 2,
  Final = 3
}
