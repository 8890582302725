<div *ngIf="showComponent" [rxLocalisationInit]="componentName">
  <div class="row wrapper white-bg page-heading py-2 align-items-center">
    <div class="col-md-8 my-1">
      <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/dashboard']">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/application-object']" rxText="Label_ApplicationObject_t"></a>
        </li>
        <li class="breadcrumb-item active" rxText="ApplicationObjectAddComponent_Title"></li>
      </ol>
    </div>
    <div class="col-md-4 text-right">
      <app-system-time></app-system-time>
    </div>
  </div>

  <div class="wrapper wrapper-content flex-grow-1">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white pr-3">
        <h5><label rxText="ApplicationObjectAddComponent_Title"></label></h5>
        <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
          <i class="fa fa-question-circle fa-2x"></i>
        </a>
      </div>
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="applicationObjectFormGroup"
        [rxSpinner]="spin">
        <div class="row add-countries-form" (keyup.enter)="addApplicationObject(false)">


          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="ApplicationObjectAddComponent_App_Obj_Type_t"></label>
              <div class="col-md-8">
                <rx-select [(source)]="applicationObjectTypeLookup" #rxSelect formControlName="applicationObjectTypeId"
                  mainClass="form-control" [keyValueProps]="['applicationObjectTypeName','applicationObjectTypeId']"
                  [selectPlaceholder]="'Select Application Object'"></rx-select>
                <small class="form-text text-danger"
                  [class.d-block]="applicationObjectFormGroup.controls.applicationObjectName.errors">{{applicationObjectFormGroup.controls.applicationObjectTypeId.errorMessage}}</small>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-4 pr-xl-4">
            <div class="form-group row">
              <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                rxText="ApplicationObjectAddComponent_App_Obj_Name_t"></label>
              <div class="col-md-8">
                <input type="text" formControlName="applicationObjectName" class="form-control" tabindex="2"
                  rxPlaceholder="ApplicationObjectAddComponent_App_Obj_Name_p" />
                <small class="form-text text-danger"
                  [class.d-block]="applicationObjectFormGroup.controls.applicationObjectName.errors">{{applicationObjectFormGroup.controls.applicationObjectName.errorMessage}}</small>
              </div>
            </div>
          </div>


        </div>
        <div class="row mt-auto">
          <div class="hr-line-dashed border-top w-100"></div>
          <div class="col text-right">
            <button (click)="addApplicationObject(true)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
            <button (click)="addApplicationObject(false)" class="btn btn-primary mr-1"
              rxText="Btn_Save_Continue_Edit_t"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
