import {SalesChannelBase,} from '@app/database-models'
//Generated Imports
export class SalesChannel extends SalesChannelBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}