<!-- <div class="wrapper wrapper-content"  [rxSpinner]="spin"> -->

    <div *rxMultilingual="" [rxLocalisationInit]="componentName" class="ibox mb-0 d-flex flex-column"  [rxSpinner]="spin">
      <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
        <div class="dataTables_filter  d-flex flex-wrap align-items-center">
          <label class="ml-auto mr-3 mb-3">
             <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4" placeholder="">
          </label>
          <div class="ibox-tools mb-4 pb-1" style="position: relative;" *ngIf="!isNoRecordFound">        
            <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
              <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="componentName" *ngIf="isShowGridColumn" 
          (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="summaryGrid.gridColumns" [isShowGridColumn]="isShowGridColumn"
          [moduleID]="moduleID" [selectedTab]="selectedTab">
          </app-system-table-setting>
          </div>
        </div>
        <div class="table-responsive" *ngIf="isShowGrid">
          <!-- <div id="summary-list" class="dataTables_wrapper pb-0 dt-bootstrap4">
  
  
          </div> -->
          <div  class="dataTables_wrapper pb-0 dt-bootstrap4">
            <rx-grid [design]="summaryGrid"></rx-grid>
          </div>
        </div>
        <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
          <h4>No Record Found</h4>
      </div>
      </div>
    </div>
  
  <!-- </div> -->
