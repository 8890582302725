import { vBookingWatchPartsBase } from '@app/database-models';
//Generated Imports
import { actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
// @actionColumn({
//     allowSorting: false,
//     style: { width: "1%", "text-align": "left" },
//     configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
// })
@actionColumn({
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'priceBandAction' }, columnIndex: 18, headerTitle: "Action"
})
export class vBookingWatchParts extends vBookingWatchPartsBase 
{

@prop()
totalCount: number;


//#region Generated Reference Properties
//#endregion Generated Reference Properties
}