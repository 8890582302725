import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemStoneCutTypeGemfieldBase {

//#region cutTypeId Prop
        @prop()
        cutTypeId : number;
//#endregion cutTypeId Prop


//#region cutTypeName Prop
        @required()
        @maxLength({value:50})
        @notEmpty()
        @alpha({allowWhiteSpace:true})
        @trim()
        cutTypeName : string;
//#endregion cutTypeName Prop


//#region typeCode Prop
        @maxLength({value:10})
        @trim()
        typeCode : string;
//#endregion typeCode Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop





}