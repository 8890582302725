import {MainSeoDetailBase,LanguageBase} from '@app/database-models'
//Generated Imports
export class MainSeoDetail extends MainSeoDetailBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop

//#endregion Generated Reference Properties






































}