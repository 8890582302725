import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Discount, vDiscountTypeLookUp, vUserLookUp } from '@app/models';
import { AbstractDiscount } from '../domain/abstract-discount';
import { List } from '@rxweb/generics';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { DiscountLookupGroup } from '../../../../view-model/discount-lookup-group';
import { StatusEnum } from 'src/app/enums/status.enum';
import { CoreComponent, access } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { dateCompareValidation, getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { DiscountEditComponent } from '../edit/discount-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
@multilingual("DiscountAddComponent")
@access({ accessLevel: RolePermissionEnums.Discounts, action: "post" })

@Component({
  selector: "app-discount-add",
  templateUrl: './discount-add.component.html'
})
export class DiscountAddComponent extends AbstractDiscount implements OnInit, OnDestroy {
  discount: Discount;
  subscription: any;
  discountTypeLookUp: vDiscountTypeLookUp[];
  discountEdit:any=DiscountEditComponent;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private formBuilder: RxFormBuilder, private router: Router, modelView: ModalView, private el: ElementRef, private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.Discounts;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Discounts - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.spin = true;
    this.lookup([{ path: USER_LOOKUPS.discountTypeLookUp, propName: "discountTypeLookUp" }, { path: USER_LOOKUPS.discountRequirementLookUp, propName: "discountRequirementLookUp" }, { path: USER_LOOKUPS.discountLimitationLookUp, propName: "discountLimitationLookUp" }, { path: USER_LOOKUPS.billingCountryLookUp, propName: "billingCountryLookUp" }, { path: USER_LOOKUPS.shippingCountryLookUp, propName: "shippingCountryLookUp" }]).subscribe((x: DiscountLookupGroup) => {
      this.discountLookupGroup = x;
      this.discount = new Discount();
      this.discount.discountId = 0;
      this.discount.deleted = false;
      this.discount.requirementSpentAmount = 0;
      this.discount.requirementShippingCountryIs = 0;
      this.discount.requirementBillingCountryIs = 0;
      // this.discount.discountTypeId=348;
      // this.discount.discountRequirementId=353;
      // this.discount.discountLimitationId=363;
      //this.discount.discountPercentage = 0;
      this.discount.limitationTimes = 1;
      //this.discount.discountAmount = 0;
      this.discount.couponCode = " ";
      this.discount.deleted = false;
      this.discount.statusId = StatusEnum.Active;
      this.discountFormGroup = this.formBuilder.formGroup(this.discount) as IFormGroup<Discount>;
      this.discountFormGroup.controls.discountAmount.setValidators([
        RxwebValidators.required({message:"You can't leave this field empty"}),
        RxwebValidators.pattern({expression:{'discountAmount':/^(?:[0-9][0-9]{0,8}(?:\.\d{1,2})?|2147483641|2147483641.00)$/}, message:'Please Enter a Correct Value'}),
        RxwebValidators.numeric({allowDecimal:true, message:'Only numbers are allowed'})
    ]);
    this.discountFormGroup.controls.requirementSpentAmount.setValidators([
      RxwebValidators.required({message:"You can't leave this field empty"}),
      RxwebValidators.numeric({allowDecimal:true, message:'Only numbers are allowed'})
  ]);

    this.discountFormGroup.controls.couponCode.setValidators([RxwebValidators.required({message:"You can't leave this field empty"})]);
      this.showComponent = true;
      this.spin = false;
    });
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  adddiscount(isRedirect) {
    this.discountFormGroup.submitted = true;
    if (dateCompareValidation(this.discountFormGroup.value.startDate, this.discountFormGroup.value.endDate) == true) {
      this.dateError = true;
    }
    else {
      this.dateError = false;
      console.log(this.discountFormGroup);
      if (this.discountFormGroup.valid) {
        this.spin = true;
        this.post({ body: this.discountFormGroup.value }).subscribe(data => {
          this.spin = false;
          var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
          if (existsAlert) {
            //this.toastr.success("Data Added Successfully")
              this.toastr.success(existsAlert[0].showMessage);
          }
          isRedirect ? this.router.navigate(['/discounts', data]) : this.router.navigate(['/discounts']);
        })
      } else {
        for (const key of Object.keys(this.discountFormGroup.controls)) {
          if (this.discountFormGroup.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
            invalidControl.focus();
            break;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "DiscountAddComponent";
  }
}
