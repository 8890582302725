import { TemplateConfig } from '@rxweb/grid';
import { xhrFilter } from '@rxweb/http';

export const REDIRECT_REGISTERED_CUSTOMER: TemplateConfig = {
  
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onredriect"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              var registrationFrom = new Date();
              if(this.Name=="Registered Customers")
              {
                if(this.countFor24HourId)
                {
                  registrationFrom.setDate(registrationFrom.getDate()-1);
                }
                else if(this.count7DaysId)
                {
                  registrationFrom.setDate(registrationFrom.getDate()-7);
                }
                else if(this.count14DaysId)
                {
                  registrationFrom.setDate(registrationFrom.getDate()-14);
                }
                else if(this.count1MonthId)
                {
                  registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() + 1);
                }
                else
                {
                  registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() + 1);
                }
              
            
                return "/users?registrationFrom="+registrationFrom.toDateString()+"&isFromDashboard=true";
              }      
              else
              {
                if(this.countFor24HourId)
                {
                  registrationFrom.setDate(registrationFrom.getDate()-1);
                }
                else if(this.count7DaysId)
                {
                  registrationFrom.setDate(registrationFrom.getDate()-7);
                }
                else if(this.count14DaysId)
                {
                  registrationFrom.setDate(registrationFrom.getDate()-14);
                }
                else if(this.count1MonthId)
                {
                  registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() + 1);
                }
                else
                {
                  registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                  registrationFrom.setDate(registrationFrom.getDate() + 1);
                }
                return "/faberge-team-login-report?registrationFrom="+registrationFrom.toDateString()+"&isFromDashboard=true";
              }
            },
            "ng-reflect-router-link": function (e) {
              var registrationFrom = new Date();
                  if(this.Name=="Registered Customers")
                  {
                    if(this.countFor24HourId)
                    {
                      registrationFrom.setDate(registrationFrom.getDate()-1);
                    }
                    else if(this.count7DaysId)
                    {
                      registrationFrom.setDate(registrationFrom.getDate()-7);
                    }
                    else if(this.count14DaysId)
                    {
                      registrationFrom.setDate(registrationFrom.getDate()-14);
                    }
                    else if(this.count1MonthId)
                    {
                      registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                      registrationFrom.setDate(registrationFrom.getDate() + 1);
                    }
                    else
                    {
                      registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                      registrationFrom.setDate(registrationFrom.getDate() + 1);
                    }
                  
                
                    return "/users?registrationFrom="+registrationFrom.toDateString()+"&isFromDashboard=true";
                  }      
                  else
                  {
                    if(this.countFor24HourId)
                    {
                      registrationFrom.setDate(registrationFrom.getDate()-1);
                    }
                    else if(this.count7DaysId)
                    {
                      registrationFrom.setDate(registrationFrom.getDate()-7);
                    }
                    else if(this.count14DaysId)
                    {
                      registrationFrom.setDate(registrationFrom.getDate()-14);
                    }
                    else if(this.count1MonthId)
                    {
                      registrationFrom.setMonth(registrationFrom.getMonth() - 1);
                      registrationFrom.setDate(registrationFrom.getDate() + 1);
                    }
                    else
                    {
                      registrationFrom.setFullYear(registrationFrom.getFullYear() - 1);
                      registrationFrom.setDate(registrationFrom.getDate() + 1);
                    }
                    return "/faberge-team-login-report?registrationFrom="+registrationFrom.toDateString()+"&isFromDashboard=true";
                  }
            
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

