import {CategoryBase,CategoryTemplateBase,ProductPieceGemstoneBase,ProductGemstoneMappingBase,ProductPieceGemstoneMappingBase,ProductCategoryMappingBase,CategoryDiscountMappingBase,GemStoneGemfieldBase,} from '@app/database-models'
//Generated Imports
import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { FileDetailViewModel } from '../../view-model/file-detail-view-model';
export class Category extends CategoryBase implements FileDetailViewModel
{

    constructor() {
        super();
        this.published= false;
        this.showOnFabergeHomePage=false;
        this.showOnHomePage=false;
    } 
    @prop()
    fileData1: string;
    @prop()
    fileExtention1: string;
    @prop()
    fileName1: string;
    @prop()
    fileData2: string;
    @prop()
    fileExtention2: string;
    @prop()
    fileName2: string;
    @prop()
    fileData: string;
    @prop()
    fileExtention: string;
    @prop()
    fileName: string;
    @prop()
    fileData3: string;
    @prop()
    fileExtention3: string;
    @prop()
    fileName3: string;
    @prop()
    fileData4: string;
    @prop()
    fileExtention4: string;
    @prop()
    fileName4: string;

    @prop()
    fileData5: string;
    @prop()
    fileExtention5: string;
    @prop()
    fileName5: string;
    @prop()
    displayImage: boolean;
    @prop()
    imageURL1: string;
    @prop()
    imageURL2: string;
    @prop()
    imageURL3: string;
    @prop()
    imageURL4: string;
    @prop()
    imageURL5: string;
    @prop()
    imageURL6: string;
    @prop({defaultValue: false})
    IsRemovedImage: boolean;

    @prop({defaultValue: false})
    IsRemovedImage1: boolean;


    @prop({defaultValue: false})
    IsRemovedImage2: boolean;

    @prop({defaultValue: false})
    IsRemovedImage3: boolean;

    @prop({defaultValue: false})
    IsRemovedImage4: boolean;

    @prop({defaultValue: false})
    IsRemovedImage5: boolean;

    @prop()
    filters: string;



//#region Generated Reference Properties
//#region categoryTemplate Prop
categoryTemplate : CategoryTemplateBase;
//#endregion categoryTemplate Prop
//#region productPieceGemstones Prop
productPieceGemstones : ProductPieceGemstoneBase[];
//#endregion productPieceGemstones Prop
//#region productGemstoneMappings Prop
productGemstoneMappings : ProductGemstoneMappingBase[];
//#endregion productGemstoneMappings Prop
//#region productPieceGemstoneMappings Prop
productPieceGemstoneMappings : ProductPieceGemstoneMappingBase[];
//#endregion productPieceGemstoneMappings Prop
//#region productCategoryMappings Prop
productCategoryMappings : ProductCategoryMappingBase[];
//#endregion productCategoryMappings Prop
//#region categoryDiscountMappings Prop
// categoryDiscountMappings : CategoryDiscountMappingBase[];
//#endregion categoryDiscountMappings Prop
//#region gemStoneGemfields Prop
gemStoneGemfields : GemStoneGemfieldBase[];
//#endregion gemStoneGemfields Prop

//#endregion Generated Reference Properties



@prop({defaultValue:1})
// @required()
isStandard : number;

    @prop()
    blobFileName: string;
    @prop()
    blobPath: string;
    @prop()
    size: number;
    @prop()
    blobFileName2: string;
    @prop()
    blobPath2: string;
    @prop()
    size2: number;
    @prop()
    blobFileName3: string;
    @prop()
    blobPath3: string;
    @prop()
    size3: number;
    @prop()
    blobFileName4: string;
    @prop()
    blobPath4: string;
    @prop()
    size4: number;
    @prop()
    blobFileName5: string;
    @prop()
    blobPath5: string;
    @prop()
    size5: number;
    @prop()
    blobFileName6: string;
    @prop()
    blobPath6: string;
    @prop()
    size6: number;





}