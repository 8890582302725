import {vCustomerRoleLookUpBase,} from '@app/database-models'
//Generated Imports
export class vCustomerRoleLookUp extends vCustomerRoleLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





}