<div class="modal-dialog modal-lg" *ngIf="showComponent == true">
    <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
        <div class="modal-header text-left">
            <h4 class="modal-title mb-0" rxText="OwnershipMappingEditComponent_Title_t"></h4>
            <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
                <span aria-hidden="true">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body pb-0" [formGroup]="ownershipMappingFormGroup"
            [rxSpinner]="spin">
            <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="OwnershipMappingAddEditComponent_StartDate_t"></label>
                <div class="col-md-8">
                    <rx-date showAddon="true" formControlName="startDate" pickerClass="form-control"
                        placeholder="Select Start Date">
                        <!-- (onSelected)="validateDate()" -->
                    </rx-date>
                    <small class="form-text text-danger"
                        [class.d-block]="ownershipMappingFormGroup.controls.startDate.errors">{{ownershipMappingFormGroup.controls.startDate.errorMessage}}</small>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                    rxText="OwnershipMappingAddEditComponent_EndDate_t"></label>
                <div class="col-md-8">
                    <rx-date showAddon="true" formControlName="endDate" pickerClass="form-control"
                        placeholder="Select End Date">
                        <!-- (onSelected)="validateDate()" -->
                    </rx-date>
                    <small class="form-text text-danger"
                        [class.d-block]="ownershipMappingFormGroup.controls.endDate.errors">{{ownershipMappingFormGroup.controls.endDate.errorMessage}}</small>
                    <small class="form-text text-danger mt-2" *ngIf="dateError">
                        End Date should be greater than Start Date
                    </small>
                </div>
            </div>
            <!-- <div class="form-group row">
                <label class="col-md-4 col-form-label">Person/Corporate</label>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-6 d-flex align-items-center">
                            <input type="radio" class="form-control w-auto" name="peopleOrCorporate" [value]="0"
                                formControlName="peopleOrCorporate" (change)="changePeopleOrCorporate(0)"
                                disabled="disabled"><label class="mb-0">Person</label>
                        </div>
                        <div class="col-md-6 d-flex align-items-center">
                            <input type="radio" class="form-control w-auto" name="peopleOrCorporate" [value]="1"
                                formControlName="peopleOrCorporate" (change)="changePeopleOrCorporate(1)"
                                disabled="disabled"><label class="mb-0">Company</label>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="form-group row" *ngIf="peopleOrCorporate == 0" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
                    rxText="OwnershipMappingAddEditComponent_Person_t"></label>
                <div class="col-md-8">
                    <input type="text" formControlName="personName" class="form-control" [attr.readonly]="true">
                </div>
            </div>
            <div class="form-group row" *ngIf="peopleOrCorporate == 1" [rxLocalisationInit]="componentName">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
                    rxText="OwnershipMappingAddEditComponent_Corporate_t"></label>
                <div class="col-md-8">
                    <input type="text" formControlName="corporateName" class="form-control" [attr.readonly]="true">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-4 col-lg-3 col-xl-4 col-form-label required"
                    rxText="OwnershipMappingAddEditComponent_Percentage_t"></label>
                <div class="col-md-8">
                    <input type="text" class="form-control" formControlName="ownershipPerc"
                        rxPlaceholder="OwnershipMappingAddEditComponent_Percentage_p">
                    <small class="form-text text-danger"
                        [class.d-block]="ownershipMappingFormGroup.controls.ownershipPerc.errors">{{ownershipMappingFormGroup.controls.ownershipPerc.errorMessage}}</small>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="saveOwnershipMappings()" rxText="Btn_Save_t"></button>
            <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
        </div>
    </div>
</div>

<style>
    .required:after {
        content: " *";
        color: red;
    }
</style>
