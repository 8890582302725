import {vCustomerChildRelationshipBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';

@actionColumn({
    style: { width: "2%" },
    configuredTemplate: { templateName: 'action' }, columnIndex: 12, headerTitle: "Action"
})
export class vCustomerChildRelationship extends vCustomerChildRelationshipBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}