import {LegalAgreementUserMappingBase} from '@app/database-models'
//Generated Imports
export class LegalAgreementUserMapping extends LegalAgreementUserMappingBase
{


    personCorporateIds: LegalAgreementUserMapping[];

    isCorporate : boolean;
//#region Generated Reference Properties


//#endregion Generated Reference Properties

    headerBtnChk: boolean;
    query:string;









}