import { TemplateConfig } from '@rxweb/grid';

export const GEMFIELD_RISK_INDEX: TemplateConfig = {
    div: {
        childrens: [
            {
                text: {
                    text: function (e) {
                        return this[e.name] + ' %';
                    }
                }
            }
        ]
    },
}


export const SPECIFICRISK_TOOLTIP: TemplateConfig = {
    div: {
        childrens: [
            {
                text: {
                    text: function (e) {
                        return this[e.name];
                    }
                }
            }
        ],
        attributes: {
            title: function (e) {
                let title = "";
                if(this.riskCauses != null && this.riskCauses != undefined && this.riskCauses != ""){
                    title = "Causes : " + '\n' + this.riskCauses;
                }
                if(this.riskConsequences != null && this.riskConsequences != undefined && this.riskConsequences != ""){
                    title += '\n\n' + "Consequences : " + '\n' + this.riskConsequences;
                }
                return title;
            }
        }
    },
}