<!-- <div class="row" id="print-certificate"> 
    <div class="col-lg-12 d-flex">
        <table align="center" class="billings-card w-100 mb-3 ml-3 border p-4">
            <thead>
                <tr>
                    <th align="center" valign="top" width="100%" colspan="2">
                        <div class="scale_certificate_logo">
                            <img src="http://fabergegtrac-p1.live1.dev.radixweb.net/Creative/assets/logos/logo_sctockcard.png" class="certificate_logo">
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style="height: 100px;"><td></td></tr>
                <tr>
                    <td vertical-align="top" colspan="2" align="center">
                        <div class="scale_val_pic">
                            <img id="ctl00_cph1_ctrlPrintValuation_imgProduct" class="val_pic" [src]="this.pieceImageUrl" style="border-width:0px;">
                        </div>
                    </td>
                </tr>
                <tr style="height: 100px;"><td></td></tr>
                <tr>
                    <td colspan="2" class="head_val" align="center" style="font-size: 16pt; font-family: garda_titlingtworegular; font-weight: normal;">
                      CERTIFICATE OF AUTHENTICITY
                    </td>
                </tr>
                <tr style="height: 20px;"><td></td></tr>
                <tr>
                    <td colspan="2" style="vertical-align: top;" align="center">
                        <table border="0" width="90%" cellpadding="5">
                            <tbody>
                                <tr>
                                <td class="titles_val" align="right" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; font-weight: bold; font-style: italic; width: 50%;">
                                    Unique Product Code</td>
                                <td class="val_text" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; width: 50%;">
                                    {{this.pieceSerialNo}}</td>
                            </tr>
                            <tr>
                                <td class="titles_val" align="right" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; font-weight: bold; font-style: italic; width: 50%;">
                                    Product Title</td>
                                <td class="val_text" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; width: 50%;">
                                    {{this.pieceName}}
                                </td>
                            </tr>
                            <tr>
                                <td class="titles_val" align="right" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; font-weight: bold; font-style: italic; width: 50%;">
                                    Piece Specification</td>
                                <td class="val_text" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; width: 50%;">
                                    <div style="width: 50%;" >
                                        {{this.pieceSpecification}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="titles_val" align="right" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; font-weight: bold; font-style: italic; width: 50%;">
                                  Piece Description</td>
                                <td class="val_text" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; width: 50%;">
                                    <div style="width: 50%;" >
                                        {{this.pieceDescription}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="height: 20px" colspan="2"></td>
                            </tr>
                            <tr>
                                <td class="titles_val" align="right" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; font-weight: bold; font-style: italic; width: 50%;">
                                    Date and Location</td>
                                <td class="val_text">
                                    <div style="border-bottom: 1px dotted #000000; width: 200px; vertical-align: bottom; height: 15px;"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style="height: 20px" colspan="2"></td>
                            </tr>
                            <tr>
                                <td class="titles_val" align="right" style="vertical-align: top; font-size: 12pt; font-family: Conv_DomaineTextLight; font-weight: bold; font-style: italic; width: 50%;">
                                    On behalf of Fabergé</td>
                                <td class="val_text">
                                    <div style="border-bottom: 1px dotted #000000; width: 200px; vertical-align: bottom; height: 15px;"></div>
                                </td>
                            </tr>
                        </tbody></table>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td align="center" valign="bottom" class="val_footer" colspan="2">
                        <img src="http://fabergegtrac-p1.live1.dev.radixweb.net/App_Themes/administration/images/Fabergé_Hallmark.png" width="107">
                    </td>
                </tr>
            </tfoot>
        </table>
        <div class="certificate_outer">
          <table align="center" cellpadding="30" cellspacing="0" class="certificate_table" bgcolor="#FFFFFF">
            <thead>
                <tr>
                    <th align="center" valign="top" width="100%">
                        <div class="scale_certificate_logo">
                            <img src="http://fabergegtrac-p1.live1.dev.radixweb.net/Creative/assets/logos/logo_sctockcard.png" class="certificate_logo">
                        </div>
                        <br>
                        <div class="head_certificate">
                            CERTIFICATE OF AUTHENTICITY
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td align="center" style="vertical-align: top;" width="100%">
    
                        <div class="certificate_text" style="width: 50%;">
                            Peter Carl Fabergé, legendary jeweller and goldsmith to the Russian Imperial Court,
                        left a rich heritage of inspired artistry and unrivalled craftsmanship that has
                        resonated through the decades and still exerts a powerful influence today. We at
                        Fabergé remain passionately committed to perpetuating our predecessor’s original
                        values, philosophy and spirit, established more than 150 years ago when Gustav Fabergé,
                        Peter Carl’s father, established his firm in 1842.<br>
                            <br>
                            <br>
                        </div>
                        <div class="certificate_text" style="width: 50%;">
                            Your Fabergé creation has been crafted to the most exacting standards and with the
                        attention to detail for which Fabergé is renowned. It bears both the authentic Fabergé
                        name and hallmark. The details of your Fabergé creation are recorded on this certificate.
                        In accordance with family tradition, its particulars and associated inventory number
                        have also been recorded in our archives.
                    <br>
                            <br>
                        </div>
                        <div class="certificate_text">
                            We hope this creation brings you many years of enjoyment,<br>
                            <br>
                            <br>
                            Sarah Fabergé
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td align="center" valign="bottom" class="certificate_footer">FABERGÉ.COM
                    </td>
                </tr>
            </tfoot>
          </table>
      </div>
    </div>
</div> -->

<div id="print-certificate">
    <div *ngFor="let item of piecePrintCertificateDetailData">
        <div style="height: 97vh; page-break-after: always;">
            <div class="border-div" style="border: 1px solid #af9a65; position: fixed; width: 94.5%; height: 95%; left: 15px; top: 25px; right: 15px; bottom: 10px; z-index:9;"></div>
            <div class="faberge-logo" style="position: fixed; left: 0; top: 12px; right:0; margin:0 auto; background: #ffffff; z-index: 99;  padding: 0px 15px; width:250px; text-align:center;"> <img width="210" src="{{newLogoUrl}}" /> </div>
            <div class="faberge-certificate" style="padding: 80px 50px; text-align: center; position:relative; z-index:9; height:80%;">
                <div class="faberge-header" style="font-size: 16px; letter-spacing: 1px; color: #000000; text-align: center; margin-bottom: 10px; font-family: 'garda-titlingtwo';font-variant-ligatures: none; font-weight:normal;"> CERTIFICATE OF AUTHENTICITY</div>
                <div class="faberge-dec" style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic; margin-bottom: 20px; font-weight: 100;"> With this document we certify that this is an authentic Fabergé creation. Your creation has been crafted to the most
                    <br/> exacting standards and with the attention to detail for which Fabergé is renowned. It bears both the authentic Fabergé
                    <br/> name and hallmark. The details of your Fabergé creation are recorded on this certificate. In accordance with family tradition,
                    <br/> its particulars and associated inventory number have also been recorded in our archives. Peter Carl Fabergé, legendary
                    <br/> jeweller and goldsmith to the Russian Imperial Court, left a rich heritage of inspired artistry and unrivalled craftsmanship that
                    <br/> has resonated through the decades and still exerts a powerful influence today. We at Fabergé remain passionately committed
                    <br/> to perpetuating our predecessor’s original values, philosophy and spirit, established more than 150 years ago when Gustav
                    <br/> Fabergé, Peter Carl’s father, established his firm in 1842. We hope this creation brings you many years of enjoyment.
                    <br/> </div>
                <div class="prod-title" style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;"> Product Title </div>
                <div class="prod-header" style="font-size: 12px; color: #000000; font-weight: 300; text-align: center; text-transform: uppercase ;margin-bottom: 15px; font-family: 'garda-titlingtwo';font-variant-ligatures: none;"> {{item.PieceName}} </div>
                <div class="prod-title" style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;"> Unique Product Code </div>
                <div class="prod-header" style="font-size: 12px; color: #000000; font-weight: 300; text-align: center; text-transform: uppercase ;margin-bottom: 15px; font-family: 'garda-titlingtwo';font-variant-ligatures: none;"> {{item.PieceSerialNo}} </div>
                <div class="prod-img" style="text-align: center;margin-bottom: 15px;"> <img width="220" [src]="item.PieceImageUrl" /> </div>
                <div class="prod-title" style="font-size:10px; color:#000000; font-weight: 300; text-align: center; margin-bottom: 5px; font-weight: 100;"> Specifications </div>
                <div class="prod-header" style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic; font-weight: 100; margin-bottom: 20px;"> {{item.PieceSpecification}} </div>
                <div class="date-location" style="width: 90%; margin: 0 auto; margin: 10px 0px;">
                   <table style="width: 80%; margin: 0 auto">
                      <tbody>
                         <tr>
                            <td class="lbl" style="text-align: right; font-style: italic; padding: 10px 0px; font-size: 10px;" width="30%">Date &amp; Location:</td>
                            <td class="ans" style="width: 50%;" width="40%"><span style="border-bottom: 1px solid; display: inline-block; width: 100%;">&nbsp;</span></td>
                         </tr>
                         <tr>
                            <td class="lbl" style="text-align: right; font-style: italic; padding: 10px 0px; font-size: 10px;" width="30%">Signature:</td>
                            <td class="ans" style="width: 50%;" width="40%"><span style="border-bottom: 1px solid; display: inline-block; width: 100%;">&nbsp;</span></td>
                         </tr>
                      </tbody>
                   </table>
                </div>
            </div>
            <div class="hallmwark" style="position: fixed; right: 0px; bottom: -40px; z-index: 0;background : #ffffff;"> <img width="130" src="{{fabHallmarkUrl}}" /> </div>
        </div>
    </div>
</div>

<!-- <div class="row" id="print-certificate">
        <div  *ngFor="let item of piecePrintCertificateDetailData"> 
            <div style="min-height: 100vh;height: auto;">
                <div class="hallmwark" style="position: fixed;  right: 0px; bottom: -40px; z-index: 1;">
                    <img width="130" src="{{fabHallmarkUrl}}" /> 
               </div>
               <div class="border-div" style="border: 1px solid #af9a65; position: fixed; width: 94.5%; height: 95%; left: 15px; top:25px; right:15px;bottom: 10px;"></div>
              <div class="faberge-certificate" style="margin: 10px 10px 10px; padding: 70px 60px 20px;">
                 <div class="faberge-logo" style="position: fixed; left: 50%; transform: translate(-50%, 0%); background: white; top: 12px; z-index: 11;
                 padding: 0px 20px;">
                    <img width="210" src="{{newLogoUrl}}" />
                 </div>
                 <div class="faberge-header" style="font-size: 16px; letter-spacing: 1px; color: #000000; text-align: center;
                 margin-top: 20px; margin-bottom: 10px;font-family: 'garda-titlingtwo';font-variant-ligatures: none;">
                      CERTIFICATE OF AUTHENTICITY 
                 </div>
                 <div class="faberge-dec" style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic;
                 margin-bottom: 20px;  font-weight: 100;">
                      With this document we certify that this an authentic Fabergé creation. Your creation has been crafted to the most<br/>
                      exacting standards and with the attention to detail for which Fabergé is renowned. It bears both the authentic Fabergé <br/> 
                      name and hallmark. The details of your Fabergé creation are recorded on this certificate. In accordance with family tradition, <br/>
                      its particulars and associated inventory number have also been recorded in our archives. Peter Carl Fabergé, legendary <br/>
                      jeweller and goldsmith to the Russian Imperial Court, left a rich heritage of inspired artistry and unrivalled craftsmanship that <br/>
                      has resonated through the decades and still exerts a powerful influence today. We at Fabergé remain passionately committed <br/>
                      to perpetuating our predecessor’s original values, philosophy and spirit, established more than 150 years ago when Gustav <br/>
                      Fabergé, Peter Carl’s father, established his firm in 1842. We hope this creation brings you many years of enjoyment. <br/>
                 </div>
                 
                 <div class="prod-title" style="font-size:10px; color: #000000; 
                 font-weight: 300; text-align: center;margin-bottom: 4px; font-weight: 100;">
                      Product Title   
                 </div>
                 <div class="prod-header" style="font-size: 12px; color: #000000; font-weight: 300; text-align: center;
                 text-transform: uppercase;margin-bottom: 15px; font-family: 'garda-titlingtwo';font-variant-ligatures: none; width: 70%;margin: 0 auto 15px;">
                      {{item.PieceName}}
                 </div>
                 <div class="prod-code-title"  style="font-size:10px; color: #000000; 
                 font-weight: 300; text-align: center;margin-bottom: 4px; font-weight: 100;">
                      Unique Product Code 
                 </div>
                 <div class="prod-code"  style="font-size: 12px; color: #000000; font-weight: 300; text-align: center;
                 text-transform: uppercase;margin-bottom: 15px; font-family: 'garda-titlingtwo';font-variant-ligatures: none;">
                       {{item.PieceSerialNo}}
                 </div>
                 <div class="prod-img" style="text-align: center;margin-bottom: 15px;">
                    <img width="220" [src]="item.PieceImageUrl"/>
                 </div>
                 <div class="specification-title"  style="font-size:10px; color: #000000; 
                 font-weight: 300; text-align: center;margin-bottom: 4px; font-weight: 100;">
                      Specifications 
                 </div>
                 <div class="specification" style="font-size: 7px; color: #000000; font-weight: 300; text-align: center;font-style: italic;
                 margin-bottom: 20px;  font-weight: 100;margin-bottom: 30px;">
                    {{item.PieceSpecification}}
                 </div>
                 <div class="date-location" style="width: 90%; margin: 0 auto; margin: 15px 0px;">
                      <table style="width: 80%; margin: 0 auto">
                          <tr>
                              <td width="30%" class="lbl" style="text-align: right; font-style: italic; padding: 10px 0px; font-size: 10px;">Date & Loaction:</td>
                              <td width="40%" class="ans" style="width: 50%;"><span style="border-bottom: 1px solid; display: inline-block; width: 100%;">&nbsp;</span></td>
                          </tr>
                          <tr>
                              <td width="30%" class="lbl" style="text-align: right; font-style: italic; padding: 10px 0px; font-size: 10px;">Signature:</td>
                              <td width="40%" class="ans" style="width: 50%;"><span style="border-bottom: 1px solid; display: inline-block; width: 100%;">&nbsp;</span></td>
                          </tr>
                      </table>
                 </div>
              </div>
            </div>
   
</div>
</div> -->

<!-- <div class="row" id="print-certificate" > 
    <div class="hallmwark">
         <img width="130" src="../assets/images/Fabergé_Hallmark_GLD.png" /> 
    </div>
    <div class="border-div"></div>
   <div class="faberge-certificate">
      <div class="faberge-logo">
         <img width="210" src="../assets/images/new-logo.png" />
      </div>
      <div class="faberge-header" >
           CERTIFICATE OF AUTHENTICITY 
      </div>
      <div class="faberge-dec">
           With this document we certify that this an authentic Fabergé creation. Your creation has been crafted to the most<br/>
           exacting standards and with the attention to detail for which Fabergé is renowned. It bears both the authentic Fabergé <br/> 
           name and hallmark. The details of your Fabergé creation are recorded on this certificate. In accordance with family tradition, <br/>
           its particulars and associated inventory number have also been recorded in our archives. Peter Carl Fabergé, legendary <br/>
           jeweller and goldsmith to the Russian Imperial Court, left a rich heritage of inspired artistry and unrivalled craftsmanship that <br/>
           has resonated through the decades and still exerts a powerful influence today. We at Fabergé remain passionately committed <br/>
           to perpetuating our predecessor’s original values, philosophy and spirit, established more than 150 years ago when Gustav <br/>
           Fabergé, Peter Carl’s father, established his firm in 1842. We hope this creation brings you many years of enjoyment. <br/>
      </div>
      
      <div class="prod-title">
           Product Title   
      </div>
      <div class="prod-header">
        {{this.pieceName}}
      </div>
      <div class="prod-code-title">
           Unique Product Code 
      </div>
      <div class="prod-code">
        {{this.pieceSerialNo}}
      </div>
      <div class="prod-img">
         <img width="220" src="{{this.pieceImageUrl}}" />
      </div>
      <div class="specification-title">
           Specifications 
      </div>
      <div class="specification">
        {{this.pieceSpecification}}
      </div>
      <div class="date-location">
           <table style="width: 80%; margin: 0 auto">
               <tr>
                   <td width="30%" class="lbl">Date & Loaction:</td>
                   <td width="40%" class="ans"><span>&nbsp;</span></td>
               </tr>
               <tr>
                   <td width="30%" class="lbl">Signature:</td>
                   <td width="40%" class="ans"><span>&nbsp;</span></td>
               </tr>
           </table>
      </div>
   </div>
</div> -->

