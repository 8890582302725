import { prop,propObject,propArray,required,maxLength,range ,numeric, notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TaxRateBase {

//#region taxRateId Prop
        @prop()
        taxRateId : number;
//#endregion taxRateId Prop


//#region taxCategoryId Prop
        @required()
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        taxCategoryId : number;
//#endregion taxCategoryId Prop


//#region countryId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        countryId : number;
//#endregion countryId Prop


//#region stateProvinceId Prop
      //  @range({ minimumNumber: 1, maximumNumber: 2147483647 })
       // @required()
       @prop({defaultValue:0})
        stateProvinceId : number;
//#endregion stateProvinceId Prop


//#region zip Prop
       // @required()
        //@notEmpty()
        @numeric()
        @maxLength({ value: 50 })
        zip : string;
//#endregion zip Prop


//#region percentage Prop
        @prop()
        percentage : number;
//#endregion percentage Prop


//#region statusId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        statusId : number;
//#endregion statusId Prop





}
