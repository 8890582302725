<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
    <div class="col-md-8 my-1">
        <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard">Home</a>
            </li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
              </li>
            <li class="breadcrumb-item active" rxText="Label_Faberge_Localisation_t"></li>
        </ol>
    </div>
    <div class="col-md-4 text-right">
        <app-system-time></app-system-time>
    </div>
</div>

<div class="wrapper wrapper-content " [rxSpinner]="spin" [rxLocalisationInit]="componentName">
    <div class="ibox mb-0 d-flex flex-column">
        <div class="ibox-title bg-white pr-3">
            <h5><label rxText="Label_Faberge_Localisation_t"></label></h5>
            <a  (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
                <i class="fa fa-question-circle fa-2x" ></i>
            </a>
        </div>
        <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="showComponent"
            [formGroup]="localizationSearchFormGroup" [rxLocalisationInit]="componentName">
            <div class="dataTables_filter  d-flex align-items-center">
                <div class=" " role="group" aria-label="Basic example">
                    <a [routerLink]="['/faberge-localization/add']" [queryParams]="{'languageId':localizationSearchFormGroup.value.languageId}" class="btn btn-success mb-3 pull-left" *rxAuthorize="[localizationAdd]" rxText="Btn_Add_New_t"></a>
                </div>
                <ng-container *ngIf="!isNoRecordFound" [rxLocalisationInit]="componentName">
                    <button class="btn btn-primary mb-3 ml-auto mb-4" (click)="exportToExcel()" rxText="Btn_Export_to_Excel_t"></button>
                </ng-container>
            </div>
            <div>
                <div class="row people_form_details">
                    <div class="col-lg-7 col-xl-6 pr-xl-5">
                        <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                                rxText="FabergeLocalizationListComponent_Language_t"></label>
                            <div class="col-md-8" [rxSelectExtended]="_this">
                                <rx-select [(source)]="languageLookup" #rxSelect [selectPlaceholder]="'Select Language'"
                                    mainClass="form-control" formControlName="languageId"
                                    [keyValueProps]="['languageName','languageId']"></rx-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row people_form_details">
                    <div class="col-lg-7 col-xl-6 pr-xl-5">
                        <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
                                rxText="FabergeLocalizationListComponent_Name_t"></label>
                            <div class="col-md-8">
                                <div class="" id="data_1">
                                    <div class="date">
                                        <input type="text" class="form-control" formControlName="localizationKey"
                                            rxPlaceholder="FabergeLocalizationListComponent_Name_p" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row people_form_details">
                    <div class="col-lg-7 col-xl-6 pr-xl-5">
                        <div class="form-group row">
                            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
                             rxText="FabergeLocalizationListComponent_Value_t"></label>
                                <div class="col-md-8">
                                    <div class="" id="data_1">
                                        <div class="date">
                                            <input type="text" class="form-control" formControlName="localizationValue"
                                                rxPlaceholder="FabergeLocalizationListComponent_Value_p" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row mt-auto">
                <div class="hr-line-dashed border-top w-100 mt-1"></div>
                <div class="col text-right">
                    <button class="btn btn-success mr-1 search-result-btn"
                        (click)="searchGrid()" rxText="Btn_Search_t"></button>
                </div>
            </div>
        </div>
    </div>

    <div class="search-result-container">
        <div class="ibox mb-0 d-flex flex-column mt-4 search-result-container">
            <div class="ibox-title bg-white d-flex flex-wrap pr-3" [ngStyle]="{'display':isShowGrid==true?'block':'none'}">
                <h5 rxText="Label_Search_Result_t"></h5>
                <!-- <label class="ml-auto mr-0"
                    *ngIf="(!isFilter && isShowGrid) || (isFilter && isShowGrid) || (isFilter && productDesignSearchFormGroup.value.searchString!=null)">
                    <input (keyup.enter)="search($event)" type="search"
                        class=" table_search form-control form-control-sm pr-4" placeholder="">
                </label> -->
            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1"  [ngStyle]="{'display':isShowGrid==true?'block':'none'}">
                <div class="table-responsive">
                    <div id="localization-content" class="dataTables_wrapper pb-0 dt-bootstrap4">


                    </div>
                </div>
                <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                    <h4>No Record Found</h4>
                </div>
            </div>
        </div>
    </div>
</div>