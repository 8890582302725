<ng-container *ngIf="isDashboardWidgets">
  <div class="title">
    {{settingTitle}}
  </div>
  <div class="widgets-container">
    <div class="setings-item d-flex justify-content-between align-items-center">
      <label style="width: 75%;font-weight: 550;">
        Show All Widgets
      </label>

      <label class="switch mb-0"><input (change)="visibleColumnAll($event)"
        type="checkbox" [checked]="allChecked"/><span class="slider round"></span></label>
    </div>
    <div class="setings-item d-flex justify-content-between align-items-center" *ngFor="let item of gridColumnConfig">
        <label style="width: 75%;">
          {{item.headerKey}}
        </label>

        <label class="switch mb-0"><input (change)="visibleColumn(item.columnIndex,$event)"
          type="checkbox" [checked]="item.visible"/><span class="slider round"></span></label>
    </div>
  </div>

  <div class="d-flex justify-content-end align-items-center setings-item">
      <button class="btn btn-success bg-green text-white d-inline-block my-0 mr-3" (click)="applyAction()">Apply</button>
      <button class="btn border d-inline-block my-0" (click)="hideComponent()">Cancel</button>
  </div>
</ng-container>

<ul class="dropdown-menu height-dropdown"  [ngStyle]="dropdownStyle" [class.show]="isShowGridColumn" *ngIf="isShowGridColumn && showComponent && !isDashboardWidgets">
  <li>
    <div class="i-checks i-checks-sm">
      <label>
        <input type="checkbox" [checked]="allChecked" (change)="visibleColumnAll($event)"><i></i>&nbsp; Select Columns to View
      </label>
    </div>
  </li>
  <li *ngFor="let item of gridColumnConfig">
    <div class="i-checks i-checks-sm">
      <label>
        <input type="checkbox" (change)="visibleColumn(item.columnIndex,$event)" [checked]="item.visible"><i></i> &nbsp;
        <span  [attr.component-id]="componentId" [componentId]="componentId" rxText="" [text]="item.headerKey+'_gh'"></span>
      </label>
    </div>
  </li>

  <li class="text-right position-sticky">
    <button class="btn btn-success bg-green text-white d-inline-block my-0 mr-3" (click)="applyAction()">Apply</button>
    <button class="btn border d-inline-block my-0" (click)="hideComponent()">Cancel</button>
  </li>
</ul>

<!--<input type="checkbox" (change)="visibleColumn(item.columnIndex,$event)" [checked]="item.visible"><i></i> &nbsp;
<span [componentId]="componentName" rxText="" [text]="item.headerKey+'_gh'"></span>-->
