import {EventBookingBase,BookingBase,EventBase,} from '@app/database-models'
import { propObject } from '@rxweb/reactive-form-validators';
//Generated Imports
export class EventBooking extends EventBookingBase 
{




//#region Generated Reference Properties
//#region booking Prop
@propObject(BookingBase)
booking : BookingBase;
//#endregion booking Prop
//#region event Prop
// event : EventBase;
//#endregion event Prop

//#endregion Generated Reference Properties











}