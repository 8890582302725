import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGrievanceTierBase {

//#region tripId Prop
    @prop()
    tripId : number;
//#endregion tripId Prop

//#region tierName Prop
    @prop()
    tierName : string;
//#endregion tierName Prop

}