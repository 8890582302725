import {vSpecificationAttributeOptionRecordBase,} from '@app/database-models'
//Generated Imports
export class vSpecificationAttributeOptionRecord extends vSpecificationAttributeOptionRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}