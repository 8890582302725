<div class="modal-dialog modal-lg" *ngIf="showComponent==true">
  <div class="modal-content" *rxMultilingual="" [rxLocalisationInit]="componentName">
    <div class="modal-header text-left">
      <ng-container *ngIf="!isSupplier" [rxLocalisationInit]="componentName">
        <h4 class="modal-title mb-0" rxText="CorporateGroupsAddComponent_Add_Corporate_Groups_t"></h4>
      </ng-container>
      <ng-container *ngIf="isSupplier" [rxLocalisationInit]="componentName">
        <h4 class="modal-title mb-0" rxText="CorporateGroupsAddComponent_Add_People_Directors_t"></h4>
      </ng-container>

      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>

    <div class="ml-4 modal-body pb-0 ibox-content bg-white d-flex flex-column flex-grow-1"
      [formGroup]="customerCompanyAddFormGroup">
      <div class="row">
        <div class="col-md-6" *ngIf="!isSupplier" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
              rxText="CorporateGroupsAddEditComponent_Name_t"></label>
            <div class="col-md-12">
              <rx-select [autoComplete]="false" [(source)]="corporateLookUp.companyLookup" mainClass="form-control"
                formControlName="companyId" [keyValueProps]="['company','supplierId']"
                [selectPlaceholder]="'Select Company'"></rx-select>
              <small class="form-text text-danger"
                [class.d-block]="customerCompanyAddFormGroup.controls.companyId.errors">{{customerCompanyAddFormGroup.controls.companyId.errorMessage}}</small>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="isSupplier" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
              rxText="CorporateGroupsAddEditComponent_Name_t"></label>
            <div class="col-md-12">
              <div class="row">
                <div class="col-10" [rxSelectExtended]="_this">
                  <rx-select #rxSelect [autoComplete]="true" mainClass="form-control" formControlName="userId"
                    [queryParams]="{'lookupType':'userDetail'}" path="api/SearchEvent/GetLookups"
                    [keyValueProps]="['#fullName#','id']" [valueInQueryParams]="true" [minimumCharacterSearchLength]="2"
                    (changed)="userSelected($event)" [selectPlaceholder]="'Select Person'">
                  </rx-select>
                </div>
                <div class="col-2">
                  <a class="btn btn-success" [routerLink]="'/users/add'" target="_blank" rxText="Btn_Add_People_t"></a>
                </div>
              </div>
              <small class="form-text text-danger"
                [class.d-block]="customerCompanyAddFormGroup.controls.userId.errors">{{customerCompanyAddFormGroup.controls.userId.errorMessage}}</small>
            </div>
            <!-- <div class="col-md-6">
              <rx-select [autoComplete]="false" [(source)]="employmentData.companyLookup" mainClass="form-control" formControlName="companyId" [keyValueProps]="['','userId']"></rx-select>
              <small class="form-text text-danger" [class.d-block]="customerCompanyAddFormGroup.controls.userId.errors">{{companyCustomerMappingFormGroup.controls.userId.errorMessage}}
                <br />
              </small>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="showDirector" [rxLocalisationInit]="componentName">
          <div class="form-group row check-group">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="CorporateGroupsAddEditComponent_Director_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <app-checkbox [checkFormControl]="customerCompanyAddFormGroup.controls.isDirector"
                    class="col-md-auto d-flex align-items-center" (change)="changeDirectorship($event)"></app-checkbox>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="showDate && resetCommonControl" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-12 col-xl-12 col-form-label"
              rxText="CorporateGroupsAddEditComponent_StartDate_t"></label>
            <div class="col-md-12">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date showAddon="true" formControlName="startDate" placeholder="Select Date"
                    pickerClass="form-control"></rx-date>
                  <!-- <small class="form-text text-danger" *ngIf="isFutureDate==true">Start Date shuold not be greater than Current Date<br/></small> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="showDate && resetCommonControl" [rxLocalisationInit]="componentName">
          <div class="form-group row">
            <label class="col-md-12 col-form-label"
              rxText="CorporateGroupsAddEditComponent_EndDate_t"></label>
            <div class="col-md-12">
              <div class="" id="data_1">
                <div class="input-group date">
                  <rx-date showAddon="true" formControlName="endDate" placeholder="Select Date"
                    pickerClass="form-control">
                  </rx-date>
                  <small class="form-text text-danger" *ngIf="dateError==true">End Date should be greater than Start
                    Date<br /></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" >
          <div class="form-group row check-group">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"
              rxText="CorporateGroupsAddEditComponent_EmploymentActive_t"></label>
            <div class="col-md-auto d-flex align-items-center">
              <div class="i-checks">
                <label class="mb-0">
                  <app-checkbox class="col-md-auto d-flex align-items-center"
                    [checkFormControl]="customerCompanyAddFormGroup.controls.activeEmployement"
                    (change)="changeEmploymentActive($event)"></app-checkbox>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="AddCorporate()" class="btn btn-success mr-1" [disabled]=enableSaveBtn rxText="Btn_Save_t"></button>
        <button type="button" class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
      </div>
    </div>
  </div>
</div>
