import {vTaxCategoryRecordBase,} from '@app/database-models'
//Generated Imports
export class vTaxCategoryRecord extends vTaxCategoryRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}