import {LookupIllnessTypeBase,} from '@app/database-models'
//Generated Imports
export class LookupIllnessType extends LookupIllnessTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}