import { numeric, prop } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentPricelistFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = false;
        this.orderBy = "createdOn";
    }
    
    @prop()
    @numeric()
    tripId: number;

    @prop()
    tripName: string;

    @prop({ defaultValue: 0 })
    createdBy: number;

    @prop({ defaultValue: 25 })
    rowCount: number;

    @prop()
    orderBy: string;

    @prop({ defaultValue: false })
    sortOrder: boolean;

    @prop({ defaultValue: 1 })
    pageIndex: number;
}