import {vMessageTemplateLookUpBase,} from '@app/database-models'
//Generated Imports
export class vMessageTemplateLookUp extends vMessageTemplateLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}