import { TemplateConfig } from '@rxweb/grid';

export const RECORD_STATUS_GRID_TEMPLATE: TemplateConfig = {
    i: {
        class: [function (e) {
            return this[e.name] == 307 ? "fa fa-check text-green".split(" ") : 'fa fa-times text-danger'.split(" ");
        }],
    }
}

export const RECORD_STATUSES_GRID_TEMPLATE: TemplateConfig = {
    i: {
        class: [function (e) {
            return this[e.name] == true ? "fa fa-check text-green".split(" ") : 'fa fa-times text-danger'.split(" ");
        }],
    }
}
export const RECORD_STATUS_GrievanceTier_GRID_TEMPLATE: TemplateConfig = {
    i: {
        class: [function (e) {
            return this[e.name] == 1 ? "fa fa-check text-green".split(" ") : 'fa fa-times text-danger'.split(" ");
        }],
    }
}
