import {NewsPictureBase,} from '@app/database-models'
//Generated Imports
export class NewsPicture extends NewsPictureBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





































}