import {
  Directive,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
  ContentChild,
  AfterContentInit,
  OnInit,
  HostListener,
} from "@angular/core";
import { RxTagComponent } from "@rxweb/angular";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
  selector: "[rxTagExtended]",
})
export class RxTagExtendedDirective implements OnInit,AfterContentInit {

  @ContentChild("rxTag") rxTag: RxTagComponent;
  componentConstructor = null;
  @Input("rxTagExtended") set component(value: any) {
    this.componentConstructor = value;
  }

  private rxTagSubject$ : Subject<string> = new Subject<string>();
  clickListener: EventListenerOrEventListenerObject;
  isGINAForm: boolean = false;
  ngOnInit(): void {
    this.rxTagSubject$.pipe(debounceTime(300)).subscribe(t => {
      this.onFocus(t);
    })
    this.isGINAForm = document.getElementById('hse-gina-add-form')!= null;
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.rxTag.onKeyup = this.onKeyUpRxTag.bind(this);
      this.rxTag.onFocus = this.onFocus.bind(this);
    }, 1000);
    if(this.isGINAForm){
      const tag: any = this.rxTag;
      const clearActiveTabClass = tag.clearActiveTabClass;
      this.clickListener = (e) => {
        const ele = e.target as HTMLElement;
        if (ele.id != tag.searchId) {
            setTimeout(() => {
                clearActiveTabClass.bind(tag).call()
            }, 50);
        }
      }
      window.document.addEventListener('click', this.clickListener)
      if (!tag.path)
        tag.clearActiveTabClass = () => {
          if (tag.timeOutId) {
            setTimeout(() => {
              window.clearTimeout(tag.timeOutId)
              tag.timeOutId = undefined
            }, 400)
          }
        }

    }
  }

  onKeyUpRxTag(event) {
    var value = event.srcElement.value;
    var newValue = value.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "").replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    var regex = new RegExp(newValue.toLowerCase());
    if (
      value !== null &&
      event.keyCode !== 40 &&
      event.keyCode !== 13 &&
      event.keyCode !== 37
    ) {
      if (this.rxTag.autoComplete) {
        this.rxTag["currentValue"] = value;
        // this.rxTag.onFocus(event);
        this.rxTagSubject$.next(event);
      }
      else {

        this.rxTag.dropSource = this.rxTag["mainSource"].filter((t) =>
          regex.test(
            t.text
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
        );

        this.rxTag.dropSource.sort((a, b) => {
          const nameA = a.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const nameB = b.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

          // Compare the starting position of the keyword in fullname
          if (nameA.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && !nameB.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
            return -1; // a comes first
          } else if (!nameA.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && nameB.startsWith(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) {
            return 1; // b comes first
          }

          return 0;
        });

        this.rxTag.selectedTags.forEach((t) => {
          if (this.rxTag.dropSource.length > 0) {
            var indexOf = this.rxTag.dropSource.indexOf(t);
            if (indexOf != -1) this.rxTag.dropSource.splice(indexOf, 1);
          }
        });
        this.rxTag["setActiveTabClass"]();
      }
    }
  }

  onFocus(event) {
    if ((!this.rxTag.userSource && this.rxTag["mainSource"].length === 0 && this.rxTag.lookup) || (this.rxTag.autoComplete) || this.rxTag.isObjectChanged) {
      if ((this.rxTag["currentValue"] && this.rxTag["currentValue"].length >= this.rxTag.minimumCharacterSearchLength) || (!this.rxTag.autoComplete)) {
        let params = [];
        if (this.rxTag.params)
          this.rxTag.params.forEach(t => params.push(t));
        if (this.rxTag.autoComplete && this.rxTag["currentValue"]) {
          if (this.rxTag.valueInQueryParams) {
            if (!this.rxTag.queryParams)
              this.rxTag.queryParams = {};
            this.rxTag.queryParams['q'] = this.rxTag["currentValue"];
          }
          else
            params.push(this.rxTag["currentValue"]);
        }
        this.rxTag.isSpinner = true;

        Object.keys(this.rxTag.queryParams).forEach(key => {
          if (key == "q") {
            var str: string = this.rxTag.queryParams[key];
            this.rxTag.queryParams[key] = encodeURIComponent(str);
          }
        });
        this.rxTag.get({ path: this.rxTag.path, params: params, queryParams: this.rxTag.queryParams }).subscribe((t) => {
          this.rxTag["userSource"] = t as any;
          this.rxTag.userSource = this.rxTag.userSource.filter(item => {
            if( 
              this.rxTag.selectedTags.findIndex(t => t.id == item.pieceId) == -1 
              && this.rxTag.selectedTags.findIndex(t => t.id == item.keyID) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.journalistId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.publicationId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.userId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.companyId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.eventId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.projectId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.productVariantId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.designId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.categoryId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.collectionId) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.languageID) == -1
              && this.rxTag.selectedTags.findIndex(t => t.id == item.assetId) == -1
              ) {
              return true;
            }
            else {
              return false;
            }
          });
          this.rxTag["mainSource"] = [];
          this.rxTag.dropSource = [];
          this.rxTag["setMainSource"](this.rxTag.userSource);


          this.rxTag.selectedTags.forEach(t => {
            if (this.rxTag.dropSource.length > 0) {
              var indexOf = this.rxTag.dropSource.indexOf(t);
              if (indexOf != -1)
                this.rxTag.dropSource.splice(indexOf, 1);
            }
          });
          this.rxTag["sortSource"]();
          this.rxTag["setActiveTabClass"]();
          this.rxTag.isSpinner = false;
          this.rxTag.isObjectChanged = false;
        });
      }
    }
    else {
      var value = event.srcElement.value;
      var compareValue = this.rxTag.removeDoubleBackslashes(String(value).toLowerCase());
      this.rxTag.dropSource = this.rxTag.getCloned(this.rxTag.itemTexts ? this.rxTag.dropSource : this.rxTag["mainSource"]).filter(t => String(t.text).toLowerCase().indexOf(compareValue) !== -1);
      this.rxTag.selectedTags.forEach(t => {
        if (this.rxTag.dropSource.length > 0) {
          var indexOf = this.rxTag.dropSource.indexOf(t);
          if (indexOf != -1)
            this.rxTag.dropSource.splice(indexOf, 1);
        }
      });
      this.rxTag["sortSource"]();
      this.rxTag["setActiveTabClass"]();
    }
  }

  ngOnDestroy(){
    if(this.isGINAForm)
        window.document.removeEventListener('click',this.clickListener);
  }
}
