import {BookingTypeBase,} from '@app/database-models'
//Generated Imports
export class BookingType extends BookingTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




































}