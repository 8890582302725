import {ProductLocalizedBase,LanguageBase,ProductDesignBase,} from '@app/database-models'
//Generated Imports
export class ProductLocalized extends ProductLocalizedBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop

//#endregion Generated Reference Properties



























}