import {vRingEggSizeRecordBase,} from '@app/database-models'
//Generated Imports
export class vRingEggSizeRecord extends vRingEggSizeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}