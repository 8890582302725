import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewContainerRef } from "@angular/core"
import { AbstractDocument } from '../domain/abstract-document';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';

import { Document } from '@app/models';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { HttpClient } from "@angular/common/http";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: "app-document-edit",
  templateUrl: './document-edit.component.html'
})
export class DocumentEditComponent extends AbstractDocument implements OnInit, OnDestroy {
  subscription: any;
  @Input() id: number;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() moduleName: string;
  titleClose: string;
  constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, browserStorage: BrowserStorage, blobState: BlobUploadsViewStateService, httpClient: HttpClient, location : PlatformLocation, componentFactoryResolver: ComponentFactoryResolver, viewContainerRef: ViewContainerRef) {
    super(browserStorage, blobState, httpClient,location,componentFactoryResolver, viewContainerRef);
    //this.activatedRoute.queryParams.subscribe(t => {
    //  this.id = t['id'];
    //})
    location.onPopState(() => this.hide());
  }

  ngOnInit(): void {
    this.titleClose = getLocalizedValue("Title_Close");
    this.get({ params: [this.id] }).subscribe(t => {
      this.documentFormGroup = this.formBuilder.formGroup(Document, t) as IFormGroup<Document>;
      this.showComponent = true;
    })
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  onFileChange(data: any): void {
    let target = data.target || data.srcElement;
    var file = target.files[0];
    var _size = file.size;
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0; while (_size > 900) { _size /= 1024; i++; }
    var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
    if (file.size / 1024 > (this.fileViewModel.fileAllowSize * 1024)) {
      //this.baseToastr.error("File Size should be " + exactSize);
      var existsAlert = getLocalizedValue("FileSize_Error");
      if (existsAlert) {
        this.baseToastr.error(existsAlert + exactSize);
      }
      return;
    }

    if (file) {
      this.documentFormGroup.patchValue({ file: file });
    }
  }

  async uploadDocument() {
    this.documentFormGroup.submitted = true;
    if (this.documentFormGroup.valid) {
      this.put({ params: [this.id], body: this.documentFormGroup.toFormData() }).subscribe(data => {
        //this.baseToastr.success("Data added successfully");
        var existsAlert = getLocalizedValue("Data_Added");
        if (existsAlert) {
          this.baseToastr.success(existsAlert);
        }
        this.hide();
      })
    }
  }

}
