import { TemplateConfig } from '@rxweb/grid';
import { BLOB_CONTAINER, BLOB_PUBLIC_URI, SERVER_URL } from 'src/app/domain/system-constant';
import { showPersonaNonGrataToolTip, showSensitiveContactToolTip } from 'src/app/domain/customize-plugin/bind-slider';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const REDIRECT_LINK_PEOPLE_CORPORATE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID != 0) {
                return "/users/" + this.customerID + "?activeTab=user-edit";
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId != 0) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.CustomerID != null && this.CustomerID != undefined && this.CustomerID != 0) {
                return "/users/" + this.CustomerID + "?activeTab=user-edit";
              }
              if (this.SupplierId != null && this.SupplierId != undefined && this.SupplierId != 0) {
                return "/suppliers/" + this.SupplierId + "?activeTab=supplier-edit";
              }
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID != 0) {
                return "/users/" + this.customerID + "?activeTab=user-edit";
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId != 0) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.CustomerID != null && this.CustomerID != undefined && this.CustomerID != 0) {
                return "/users/" + this.CustomerID + "?activeTab=user-edit";
              }
              if (this.SupplierId != null && this.SupplierId != undefined && this.SupplierId != 0) {
                return "/suppliers/" + this.SupplierId + "?activeTab=supplier-edit";
              }
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const REDIRECT_LINK_USER_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.leader != null || this.leader != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.leader != null || this.leader != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};


export const REDIRECT_LINK_PEOPLE_CORPORATE_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: [function (e) {
            if (this.contactPermissionRequired != null && this.contactPermissionRequired != undefined) {
              if (this.contactPermissionRequired) return "text-red font-weight-bold".split(" ")
              else return "text-blue font-weight-bold".split(" ")
            }
            else return "text-blue font-weight-bold".split(" ")
          }],
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return (this.deceased == null || this.deceased == undefined || this.deceased == false) ? this[e.name] : '' }
            }
          }
          ],
          attributes: {
            innerHTML: function (e) {
              return (this.deceased) ? this[e.name] : '';
            },
            href: function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID > 0) {
                return "/users/" + this.customerID + "?activeTab=user-edit";
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId > 0) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.userId != null && this.userId != undefined && this.userId > 0) {
                if (this.knownToUserId != null && this.knownToUserId != undefined && this.knownToUserId > 0) {
                  return "/users/" + this.knownToUserId + "?activeTab=user-edit";
                }
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.UserId != null && this.UserId != undefined && this.UserId > 0) {
                return "/users/" + this.UserId + "?activeTab=user-edit";
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.corporateAffectedId != null && this.corporateAffectedId != undefined && this.corporateAffectedId > 0) {
                return "/suppliers/" + this.corporateAffectedId + "?activeTab=supplier-edit";
              }
              if (this.knownToColleagueId != null && this.knownToColleagueId != undefined && this.knownToColleagueId > 0) {
                return "/users/" + this.knownToColleagueId + "?activeTab=user-edit";
              }
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID > 0) {
                return "/users/" + this.customerID + "?activeTab=user-edit";
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId > 0) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.userId != null && this.userId != undefined && this.userId > 0) {
                if (this.knownToUserId != null && this.knownToUserId != undefined && this.knownToUserId > 0) {
                  return "/users/" + this.knownToUserId + "?activeTab=user-edit";
                }
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.UserId != null && this.UserId != undefined && this.UserId > 0) {
                return "/users/" + this.UserId + "?activeTab=user-edit";
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.corporateAffectedId != null && this.corporateAffectedId != undefined && this.corporateAffectedId > 0) {
                return "/suppliers/" + this.corporateAffectedId + "?activeTab=supplier-edit";
              }
              if (this.knownToColleagueId != null && this.knownToColleagueId != undefined && this.knownToColleagueId > 0) {
                return "/users/" + this.knownToColleagueId + "?activeTab=user-edit";
              }
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }              
            },
            onClick: "return false;"
          }
        },
      },
      {
        span: {
          isBind: (x) => {
            return (x.isUnwelcome != null && x.isUnwelcome != undefined && x.isUnwelcome == true) || x.isSupplierUnwelcome ? true : false;
          },
          class: "personaNonGrataToolTip".split(" "),
          style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
            },
            id: function (e) {
              showPersonaNonGrataToolTip();
              return "personaNonGrataToolTip";
            }
         },
          childrens: [{
            text: {
              text: function (e) {
                return '\u26D4';
              }
            }
          }]
        }
      },
      {
        i: {
          isBind: (x) => {
            return x.contactPermissionRequired != null && x.contactPermissionRequired != undefined && x.contactPermissionRequired == true && (x.person || x.fullName || x.counterPartyName || x.userName || x.name || x.involvedPersonName || x.UserName || x.company || x.FullName) ? true : false;
          },
          class: "fa fa-exclamation-circle text-red".split(" "),
          style: { "margin-left": "0.15rem" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              if (this.introducesBy != null && this.introducesBy != undefined && this.introducesBy != '') {
                return this.introducesBy;
              }
            },
            id: function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID != 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.customerID, this.introducesBy);
                return this.customerID;
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.customerId, this.introducesBy);
                return this.customerId;
              }
              if (this.userId != null && this.userId != undefined && this.userId != 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.userId, this.introducesBy);
                return this.userId;
              }
              if (this.userId != null && this.userId != undefined && this.userId != 0 && this.keyRelationshipHolder != null && this.keyRelationshipHolder != undefined) {
                bindTooltip(this.userId, this.keyRelationshipHolder);
                return this.userId;
              }
              if (this.knownToUserId != null && this.knownToUserId != undefined && this.knownToUserId != 0 && this.keyRelationshipHolderName != null && this.keyRelationshipHolderName != undefined) {
                bindTooltip(this.knownToUserId, this.keyRelationshipHolderName);
                return this.knownToUserId;
              }
              if (this.UserId != null && this.UserId != undefined && this.UserId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                if (this.SupplierId) {
                  bindTooltip(this.SupplierId + this.UserId, this.introducesBy);
                  return this.SupplierId + this.UserId;
                }
                else if(this.ticketDetailId > 0){
                    bindTooltip(this.ticketDetailId.toString()+this.UserId.toString(), this.introducesBy);
                    return this.ticketDetailId.toString()+this.UserId.toString();
                }
                else {
                  bindTooltip(this.UserId, this.introducesBy);
                  return this.UserId;
                }
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.personAffectedId, this.introducesBy);
                return this.personAffectedId;
              }
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0 && this.introduceBy != null && this.introduceBy != undefined) {
                bindTooltip(this.supplierId, this.introduceBy);
                return this.supplierId;
              }
            },
          }
        }
      },
      {
        span: {
          isBind: (x) => {
            return x.userId != null && x.userId != undefined && x.userId > 0 && x.knownToUserId != null && x.knownToUserId != undefined && x.knownToUserId > 0 && x.knownToId != null && x.knownToId != undefined && x.knownToId > 0 ? true : false;
          },
          style: { "white-space": "nowrap" },
          childrens: [{
            text: {
              text: function (e) {
                return ' is their:';
              }
            }
          }],
        },
      }
    ]
  },
};

export const REDIRECT_LINK_PEOPLE_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: [function (e) {
            if (this.contactPermissionRequired != null && this.contactPermissionRequired != undefined) {
              if (this.contactPermissionRequired) return "text-red font-weight-bold".split(" ")
              else return "text-blue font-weight-bold".split(" ")
            }
            else return "text-blue font-weight-bold".split(" ")
          }],
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return (this.deceased == null || this.deceased == undefined || this.deceased == false) ? this[e.name] : '' }
            }
          }
          ],
          attributes: {
            innerHTML: function (e) {
              return (this.deceased) ? this[e.name] : '';
            },
            href: function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID > 0) {
                return "/users/" + this.customerID + "?activeTab=user-edit";
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId > 0) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.userId != null && this.userId != undefined && this.userId > 0) {
                if (this.knownToUserId != null && this.knownToUserId != undefined && this.knownToUserId > 0) {
                  return "/users/" + this.knownToUserId + "?activeTab=user-edit";
                }
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.UserId != null && this.UserId != undefined && this.UserId > 0) {
                return "/users/" + this.UserId + "?activeTab=user-edit";
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.requestedById != null && this.requestedById != undefined && this.requestedById > 0) {
                return "/users/" + this.requestedById + "?activeTab=user-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID > 0) {
                return "/users/" + this.customerID + "?activeTab=user-edit";
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId > 0) {
                return "/users/" + this.customerId + "?activeTab=user-edit";
              }
              if (this.userId != null && this.userId != undefined && this.userId > 0) {
                if (this.knownToUserId != null && this.knownToUserId != undefined && this.knownToUserId > 0) {
                  return "/users/" + this.knownToUserId + "?activeTab=user-edit";
                }
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.UserId != null && this.UserId != undefined && this.UserId > 0) {
                return "/users/" + this.UserId + "?activeTab=user-edit";
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.requestedById != null && this.requestedById != undefined && this.requestedById > 0) {
                return "/users/" + this.requestedById + "?activeTab=user-edit";
              }
            },
            onClick: "return false;"
          }
        },
      },
      {
        span: {
          isBind: (x) => {
            return x.isUnwelcome ? true : false;
          },
          class: "personaNonGrataToolTip".split(" "),
          style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
            },
            id: function (e) {
              showPersonaNonGrataToolTip();
              return "personaNonGrataToolTip";
            }
         },
          childrens: [{
            text: {
              text: function (e) {
                return '\u26D4';
              }
            }
          }]
        }
      },
      {
        i: {
          isBind: (x) => {
            return x.contactPermissionRequired != null && x.contactPermissionRequired != undefined && x.contactPermissionRequired == true && (x.person || x.fullName || x.counterPartyName || x.userName || x.name || x.involvedPersonName || x.UserName || x.company || x.FullName || x.introducedBy || x.leaderName) ? true : false;
          },
          class: "fa fa-exclamation-circle text-red".split(" "),
          style: { "margin-left": "0.15rem" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              if (this.introducesBy != null && this.introducesBy != undefined && this.introducesBy != '') {
                return this.introducesBy;
              }
            },
            id: function (e) {
              if (this.customerID != null && this.customerID != undefined && this.customerID != 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.customerID, this.introducesBy);
                return this.customerID;
              }
              if (this.customerId != null && this.customerId != undefined && this.customerId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                if(this.ownershipId != null && this.ownershipId != undefined && this.ownershipId > 0){
                  bindTooltip(this.customerId+this.ownershipId, this.introducesBy);
                  return this.customerId+this.ownershipId;
                }
                bindTooltip(this.customerId, this.introducesBy);
                return this.customerId;
              }
              if (this.userId != null && this.userId != undefined && this.userId != 0 && this.introducesBy != null && this.introducesBy != undefined) {
                if (this.legalAgreementId != null && this.legalAgreementId > 0){
                  bindTooltip(this.legalAgreementId + this.userId, this.introducesBy);
                  return this.legalAgreementId + this.userId;
                }
                bindTooltip(this.userId, this.introducesBy);
                return this.userId;
              }
              if (this.userId != null && this.userId != undefined && this.userId != 0 && this.keyRelationshipHolder != null && this.keyRelationshipHolder != undefined) {
                bindTooltip(this.userId, this.keyRelationshipHolder);
                return this.userId;
              }
              if (this.knownToUserId != null && this.knownToUserId != undefined && this.knownToUserId != 0 && this.keyRelationshipHolderName != null && this.keyRelationshipHolderName != undefined) {
                bindTooltip(this.knownToUserId, this.keyRelationshipHolderName);
                return this.knownToUserId;
              }
              if (this.UserId != null && this.UserId != undefined && this.UserId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                if (this.SupplierId) {
                  bindTooltip(this.SupplierId + this.UserId, this.introducesBy);
                  return this.SupplierId + this.UserId;
                }
                else {
                  bindTooltip(this.UserId, this.introducesBy);
                  return this.UserId;
                }
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0 && this.introducesBy != null && this.introducesBy != undefined) {
                bindTooltip(this.personAffectedId, this.introducesBy);
                return this.personAffectedId;
              }
              if (this.requestedById != null && this.requestedById != undefined && this.requestedById != 0 && this.introducedBy != null && this.introducedBy != undefined && this.id != null && this.id != undefined && this.id != 0 ) {
                bindTooltip(this.id, this.introducedBy);
                return this.id;
              }
            },
          }
        }
      },
      {
        span: {
          isBind: (x) => {
            return x.userId != null && x.userId != undefined && x.userId > 0 && x.knownToUserId != null && x.knownToUserId != undefined && x.knownToUserId > 0 && x.knownToId != null && x.knownToId != undefined && x.knownToId > 0 ? true : false;
          },
          style: { "white-space": "nowrap" },
          childrens: [{
            text: {
              text: function (e) {
                return ' is their:';
              }
            }
          }],
        },
      },
      {
        span: {
          isBind: (x) => {
            return x.customerID != null && x.customerID != undefined && x.customerID > 0 && x.GemfieldFormerColleagues != null && x.GemfieldFormerColleagues != undefined && x.GemfieldFormerColleagues.length > 0 ? true : false;
          },
          style: { "white-space": "nowrap" },
          childrens: [{
            text: {
              text: function (e) {
                let IsGFormerColleague : boolean = false;
                for (let index = 0; index < this.GemfieldFormerColleagues.length; index++) {
                  const element = this.GemfieldFormerColleagues[index];
                  if (element.IsGFormerColleague) {
                    IsGFormerColleague = true;
                  }
                  else{
                    IsGFormerColleague = false;
                    break;
                  }
                }
                if (IsGFormerColleague) {
                  return getLocalizedValue("ProjectCorpMapListCmpt_GemfieldFormerColleagues_t")
                }
              }
            }
          }],
        },
      },
    ]
  },
};

export const REDIRECT_LINK_CORPORATE_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: [function (e) {
            if (this.companyContactPermissionRequired != null && this.companyContactPermissionRequired != undefined) {
              if (this.companyContactPermissionRequired) return "text-red font-weight-bold".split(" ")
              else return "text-blue font-weight-bold".split(" ")
            }
            else return "text-blue font-weight-bold".split(" ")
          }],
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) { return (this.deceased == null || this.deceased == undefined || this.deceased == false) ? this[e.name] : '' }
            }
          }
          ],
          attributes: {
            innerHTML: function (e) {
              return (this.deceased) ? this[e.name] : '';
            },
            href: function (e) {
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.corporateAffectedId != null && this.corporateAffectedId != undefined && this.corporateAffectedId > 0) {
                return "/suppliers/" + this.corporateAffectedId + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId > 0) {
                return "/suppliers/" + this.supplierId + "?activeTab=supplier-edit";
              }
              if (this.personAffectedId != null && this.personAffectedId != undefined && this.personAffectedId > 0) {
                return "/users/" + this.personAffectedId + "?activeTab=user-edit";
              }
              if (this.corporateAffectedId != null && this.corporateAffectedId != undefined && this.corporateAffectedId > 0) {
                return "/suppliers/" + this.corporateAffectedId + "?activeTab=supplier-edit";
              }
            },
            onClick: "return false;"
          }
        },
      },
      {
        span: {
          isBind: (x) => {
            return x.isSupplierUnwelcome ? true : false;
          },
          class: "personaNonGrataToolTip".split(" "),
          style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
            },
            id: function (e) {
              showPersonaNonGrataToolTip();
              return "personaNonGrataToolTip";
            }
         },
          childrens: [{
            text: {
              text: function (e) {
                return '\u26D4';
              }
            }
          }]
        }
      },
      {
        i: {
          isBind: (x) => {
            return x.companyContactPermissionRequired != null && x.companyContactPermissionRequired != undefined && x.companyContactPermissionRequired == true && (x.person || x.fullName || x.counterPartyName || x.userName || x.name ||x.Name || x.involvedPersonName || x.UserName || x.company || x.FullName) ? true : false;
          },
          class: "fa fa-exclamation-circle text-red".split(" "),
          style: { "margin-left": "0.15rem" },
          attributes: {
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-html": "true",
            title: function (e) {
              if (this.companyIntroducesBy != null && this.companyIntroducesBy != undefined && this.companyIntroducesBy != '') {
                return this.companyIntroducesBy;
              }
            },
            id: function (e) {
              if (this.supplierId != null && this.supplierId != undefined && this.supplierId != 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                bindCorpTooltip(this.supplierId, this.companyIntroducesBy);
                return this.supplierId;
              }
              if (this.corporateAffectedId != null && this.corporateAffectedId != undefined && this.corporateAffectedId != 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
                bindCorpTooltip(this.corporateAffectedId, this.companyIntroducesBy);
                return this.corporateAffectedId;
              }
              
            },
          }
        }
      },
    
      
    ]
  },
};

export const CORPORATE_NAME_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.contactPermissionRequired != null && this.contactPermissionRequired != undefined) {
        if (this.contactPermissionRequired) return "text-red".split(" ")
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this[e.name];
        }
      }
    },
    {
      span: {
        isBind: (x) => {
          return x.isSupplierUnwelcome != null && x.isSupplierUnwelcome != undefined && x.isSupplierUnwelcome == true ? true : false;
        },
        class: "personaNonGrataToolTip".split(" "),
        style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
          },
          id: function (e) {
            showPersonaNonGrataToolTip();
            return "personaNonGrataToolTip";
          }
       },
        childrens: [{
          text: {
            text: function (e) {
              return '\u26D4';
            }
          }
        }]
      }
    },
    
    ]
  }
}
export const CORP_NAME_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.companyContactPermissionRequired != null && this.companyContactPermissionRequired != undefined) {
        if (this.companyContactPermissionRequired) return "text-red".split(" ")
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this[e.name];
        }
      }
    },
    {
      span: {
        isBind: (x) => {
          return x.isSupplierUnwelcome != null && x.isSupplierUnwelcome != undefined && x.isSupplierUnwelcome == true ? true : false;
        },
        class: "personaNonGrataToolTip".split(" "),
        style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
          },
          id: function (e) {
            showPersonaNonGrataToolTip();
            return "personaNonGrataToolTip";
          }
       },
        childrens: [{
          text: {
            text: function (e) {
              return '\u26D4';
            }
          }
        }]
      }
    },
    {
      i: {
        isBind: (x) => {
          return x.companyContactPermissionRequired != null && x.companyContactPermissionRequired != undefined && x.companyContactPermissionRequired == true ? true : false;
        },
        class: "fa fa-exclamation-circle text-red".split(" "),
        style: { "margin-left": "0.15rem" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            if (this.companyIntroducesBy != null && this.companyIntroducesBy != undefined && this.companyIntroducesBy != '') {
              return this.companyIntroducesBy;
            }
          },
          id: function (e) {
            if (this.SupplierId != null && this.SupplierId != undefined && this.SupplierId != 0 && this.companyIntroducesBy != null && this.companyIntroducesBy != undefined) {
              bindCorpTooltip(this.SupplierId, this.companyIntroducesBy);
              return this.SupplierId;
            }
            
          },
        }
      }
    }
    ]
  }
}

export const PEOPLE_NAME_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.contactPermissionRequired != null && this.contactPermissionRequired != undefined) {
        if (this.contactPermissionRequired) return "text-red".split(" ")
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this[e.name];
        }
      }
    },
    {
      span: {
        isBind: (x) => {
          return x.isUnwelcome != null && x.isUnwelcome != undefined && x.isUnwelcome == true ? true : false;
        },
        class: "personaNonGrataToolTip".split(" "),
        style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
          },
          id: function (e) {
            showPersonaNonGrataToolTip();
            return "personaNonGrataToolTip";
          }
       },
        childrens: [{
          text: {
            text: function (e) {
              return '\u26D4';
            }
          }
        }]
      }
    },
    {
      i: {
        isBind: (x) => {
          return x.contactPermissionRequired != null && x.contactPermissionRequired != undefined && x.contactPermissionRequired == true ? true : false;
        },
        class: "fa fa-exclamation-circle text-red".split(" "),
        style: { "margin-left": "0.15rem" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            if (this.introducesBy != null && this.introducesBy != undefined && this.introducesBy != '') {
              return this.introducesBy;
            }
          },
          id: function (e) {
            if (this.customerID != null && this.customerID != undefined && this.customerID != 0 && this.introducesBy != null && this.introducesBy != undefined) {
              bindTooltip(this.customerID, this.introducesBy);
              return this.customerID;
            }
            if (this.id != null && this.id != undefined && this.id != 0 && this.introducesBy != null && this.introducesBy != undefined) {
              bindTooltip(this.id, this.introducesBy);
              return this.id;
            }
            if (this.UserID != null && this.UserID != undefined && this.UserID != 0 && this.introducesBy != null && this.introducesBy != undefined) {
              bindTooltip(this.UserID, this.introducesBy);
              return this.UserID;
            }
            if (this.userId != null && this.userId != undefined && this.userId != 0 && this.keyRelationshipHolder != null && this.keyRelationshipHolder != undefined) {
              bindTooltip(this.userId, this.keyRelationshipHolder);
              return this.userId;
            }
            if (this.userId != null && this.userId != undefined && this.userId != 0 && this.introducesBy != null && this.introducesBy != undefined) {
              bindTooltip(this.userId, this.introducesBy);
              return this.userId;
            }
            if (this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId != 0 && this.introducesBy != null && this.introducesBy != undefined && this.specificRiskId != null && this.specificRiskId != undefined && this.specificRiskId != 0) {
              bindTooltip(this.riskMenuDetailId+this.specificRiskId, this.introducesBy);
              return this.riskMenuDetailId+this.specificRiskId;
            }
            if (this.SalesPersonID != null && this.SalesPersonID != undefined && this.SalesPersonID != 0 && this.introducesBy != null && this.introducesBy != undefined && this.RepairFormId != null && this.RepairFormId != undefined && this.RepairFormId != 0) {
              bindTooltip(this.SalesPersonID+this.RepairFormId, this.introducesBy);
              return this.SalesPersonID+this.RepairFormId;
            }
          },
        }
      }
    }]
  }
};
export const CORPORATE_PEOPLE_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  span: {
    class: [function (e) {
      if (this.contactPermissionRequired != null && this.contactPermissionRequired != undefined) {
        if (this.contactPermissionRequired) return "text-red".split(" ")
      }
    }],
    childrens: [{
      text: {
        text: function (e) {
          return this[e.name];
        }
      }
    },
    {
      span: {
        isBind: (x) => {
          return (x.isUnwelcome != null && x.isUnwelcome != undefined && x.isUnwelcome == true) || x.isSupplierUnwelcome ? true : false;
        },
        class: "personaNonGrataToolTip".split(" "),
        style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
          },
          id: function (e) {
            showPersonaNonGrataToolTip();
            return "personaNonGrataToolTip";
          }
       },
        childrens: [{
          text: {
            text: function (e) {
              return '\u26D4';
            }
          }
        }]
      }
    },
    ]
  }
};

export const PEOPLE_ANOTHERNAME_AND_TOOLTIP_GRID_TEMPLATE: TemplateConfig = {
  span: {
    childrens: [{
      text: {
        text: function (e) {
          return this[e.name];
        }
      }
    },
    {
      span: {
        isBind: (x) => {
          return x.isUserUnwelcome != null && x.isUserUnwelcome != undefined && x.isUserUnwelcome == true ? true : false;
        },
        class: "personaNonGrataToolTip".split(" "),
        style: { "font-size": "0.75rem", "margin-left": "0.15rem", "cursor": "default" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            return getLocalizedValue("UserEditComponent_IsUnwelcomeIconTooltip_t")
          },
          id: function (e) {
            showPersonaNonGrataToolTip();
            return "personaNonGrataToolTip";
          }
       },
        childrens: [{
          text: {
            text: function (e) {
              return '\u26D4';
            }
          }
        }]
      }
    },
    {
      i: {
        isBind: (x) => {
          return x.userContactPermissionRequired != null && x.userContactPermissionRequired != undefined && x.userContactPermissionRequired == true && (x.introducesBy || x.boardRiskOwnerName || x.salesPerson) ? true : false;
        },
        class: "fa fa-exclamation-circle text-red".split(" "),
        style: { "margin-left": "0.15rem" },
        attributes: {
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-html": "true",
          title: function (e) {
            if (this.userIntroducesBy != null && this.userIntroducesBy != undefined && this.userIntroducesBy != '') {
              return this.userIntroducesBy;
            }
          },
          id: function (e) {
            if (this.userId != null && this.userId != undefined && this.userId != 0 && this.userIntroducesBy != null && this.userIntroducesBy != undefined) {
              bindTooltip(this.userId+this.eventCustomerId, this.userIntroducesBy);
              return this.userId+this.eventCustomerId;
            }
            if (this.riskMenuDetailId != null && this.riskMenuDetailId != undefined && this.riskMenuDetailId != 0 && this.userIntroducesBy != null && this.userIntroducesBy != undefined) {
              bindTooltip(this.riskMenuDetailId, this.userIntroducesBy);
              return this.riskMenuDetailId;
            }
            if (this.formId != null && this.formId != undefined && this.formId != 0 && this.userIntroducesBy != null && this.userIntroducesBy != undefined) {
              bindTooltip(this.formId, this.userIntroducesBy);
              return this.formId;
            }
          },
        }
      }
    }
    ]
  }
};


export const REDIRECT_LINK_INTERNAL_DIRECTORY_DOCUMENT_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onDocLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.JobDescription != null || this.JobDescription != undefined) {
                return  BLOB_PUBLIC_URI + "/" + BLOB_CONTAINER + "/documents/user/" + this.JobDescription;
              }
            },
            target: "_blank"
          }
        }
      }
    ]
  },
};


export const RESTRICTED_PROJECT_PERSON_INVOLED_REDIRECT_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onIdLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.userId != null && this.userId != undefined && this.userId.toString() != "" && this.userId > 0) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }

              if (this.corporateId != null && this.corporateId != undefined && this.corporateId.toString() != "" && this.corporateId > 0) {
                return "/suppliers/" + this.corporateId + "?activeTab=supplier-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.userId != null && this.userId != undefined && this.userId.toString() != "" && this.userId > 0) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }

              if (this.corporateId != null && this.corporateId != undefined && this.corporateId.toString() != "" && this.corporateId > 0) {
                return "/suppliers/" + this.corporateId + "?activeTab=supplier-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};


export const REDIRECT_LINK_LA_LEADER_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onUserLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.leader != null || this.leader != undefined) {
                return "/users/" + this.leader + "?activeTab=user-edit";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.userId != null || this.userId != undefined) {
                return "/users/" + this.userId + "?activeTab=user-edit";
              }
              if (this.leader != null || this.leader != undefined) {
                return "/users/" + this.leader + "?activeTab=user-edit";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

function bindTooltip(id?, introducesBy?) {
  setTimeout(() => {
    showSensitiveContactToolTip(id.toString(), getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + introducesBy);
  }, 100);
}
function bindCorpTooltip(id?, introducesBy?) {
  setTimeout(() => {
    showSensitiveContactToolTip(id.toString(), getLocalizedValue("PermissionRequiredToContact_Tooltip_Title") + introducesBy);
  }, 500);
}
