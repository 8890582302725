import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vEmailAccountBase {

//#region emailAccountId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'EmailAccountListComponent_AccountId_gh', keyColumn: true})
        emailAccountId : number;
//#endregion emailAccountId Prop


//#region email Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'EmailAccountListComponent_Email_gh', keyColumn: false})
        email : string;
//#endregion email Prop


//#region displayName Prop
        @gridColumn({ style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'EmailAccountListComponent_DisplayName_gh', keyColumn: false})
        displayName : string;
//#endregion displayName Prop

}