import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPictureTypeLookUpBase {

//#region pictureTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'pictureTypeId', keyColumn: true})
        pictureTypeId : number;
//#endregion pictureTypeId Prop


//#region pictureTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'pictureTypeName', keyColumn: false})
        pictureTypeName : string;
//#endregion pictureTypeName Prop

}