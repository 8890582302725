import {vCreditCardTypeRecordBase,} from '@app/database-models'
//Generated Imports
export class vCreditCardTypeRecord extends vCreditCardTypeRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}