import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemTableSettingComponent } from './system-table-setting.component';
import { SystemTableSettingService } from './system-table-setting.service';
import { SharedModule } from '../../shared/shared.module';
@NgModule({
    declarations: [SystemTableSettingComponent],
  imports: [
    CommonModule, SharedModule
    ],
    exports: [SystemTableSettingComponent],
    providers: [SystemTableSettingService]
})
export class SystemTableSettingModule { }
