import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPersonTypeLookUpBase {

//#region personTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'personTypeId', keyColumn: true})
        personTypeId : number;
//#endregion personTypeId Prop


//#region personTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'personTypeName', keyColumn: false})
        personTypeName : string;
//#endregion personTypeName Prop

}