import { notEmpty, prop, required, trim } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';

export class SpecificRiskBase {

    //#region specificRiskId Prop
    @prop({ defaultValue: 0 })
    specificRiskId: number;
    //#endregion specificRiskId Prop

    //#region riskCategoryId Prop
    @required()
    @prop({ defaultValue: 0 })
    riskCategoryId: number;
    //#endregion riskCategoryId Prop

    //#region specificRiskName Prop
    @required()
    @notEmpty()
    @prop()
    @trim()
    specificRiskName: string;
    //#endregion specificRiskName Prop

    //#region riskCauses Prop
    @prop()
    riskCauses: string;
    //#endregion riskCauses Prop

    //#region riskConsequences Prop
    @prop()
    riskConsequences: string;
    //#endregion riskConsequences Prop

    //#region statusId Prop
    @prop({defaultValue: StatusEnum.Active})
    statusId: number;
    //#endregion statusId Prop

}