import {ProductVariantLocalizationBase,LanguageBase,ProductBase,} from '@app/database-models'
//Generated Imports
export class ProductVariantLocalization extends ProductVariantLocalizationBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties




}