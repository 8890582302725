<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxSpinner]="spin" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']" rxText="Label_Home_t"></a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/customer-holidays']" rxText="Label_Holiday_Process_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="CustomerHolidayEditComponent_Title"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>
<div class="wrapper wrapper-content" *ngIf="showComponent" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="CustomerHolidayEditComponent_Title"></label>
      </h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="customerHolidayFormGroup"
      >
      <div class="row add-countries-form" (keyup.enter)="editHoliday(false)">
        <div class="col-md-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="CustomerHolidayEditComponent_ID_t"></label>
            <div class="col-md-8">
              <label type="text" 
                              class="form-control disableLabel-withoutheight mb-0">{{(customerHolidayFormGroup.value.id
                              === null || customerHolidayFormGroup.value.id === undefined ) ? '' :
                              customerHolidayFormGroup.value.id}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="CustomerHolidayAddEditComponent_UserName_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <rx-select [(source)]="holidayProcessLookups.adminUserLookup" #rxSelect mainClass="form-control"
               formControlName="userId" [selectPlaceholder]="'Select User'"
                [keyValueProps]="['fullName','userId']">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="customerHolidayFormGroup.controls.userId.errors">{{customerHolidayFormGroup.controls.userId.errorMessage}}<br /></small>
            </div>
          </div>
        </div>
        <div class="col-md-4 px-xl-4">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" 
              rxText="CustomerHolidayAddEditComponent_ForYear_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select autofocus class="form-control" formControlName="forYear">

                <option *ngFor="let year of vYearLookUp" [value]="year">{{year}}</option>
              </select> -->
              <rx-select [(source)]="vYearLookUp" #rxSelect mainClass="form-control"
                formControlName="forYear" [keyValueProps]="['year','id']" [selectPlaceholder]="'Select For Year'">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="customerHolidayFormGroup.controls.forYear.errors">{{customerHolidayFormGroup.controls.forYear.errorMessage}}<br /></small>
            </div>
          </div>
        </div>
        <div class="col-md-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" 
              rxText="CustomerHolidayAddEditComponent_Type_t"></label>
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="holidayType">
                <option>Select</option>
                <option *ngFor="let item of holidayProcessLookups.leaveTypeLookUp" [value]="item.leaveId">
                  {{item.leaveTypeName}}
                </option>
              </select> -->
              <rx-select [(source)]="holidayProcessLookups.leaveTypeLookUp" #rxSelect mainClass="form-control"
                formControlName="holidayType" [selectPlaceholder]="'Select Holiday Type'"
                [keyValueProps]="['leaveTypeName','leaveId']">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="customerHolidayFormGroup.controls.holidayType.errors">{{customerHolidayFormGroup.controls.holidayType.errorMessage}}<br /></small>
            </div>
          </div>
        </div>

        <div class="col-md-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="CustomerHolidayAddEditComponent_CarriedForward_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="openingBalance" 
                rxPlaceholder="CustomerHolidayAddEditComponent_CarriedForward_p" />
              <small class="form-text text-danger"
                [class.d-block]="customerHolidayFormGroup.controls.openingBalance.errors">{{customerHolidayFormGroup.controls.openingBalance.errorMessage}}<br /></small>
            </div>
          </div>
        </div>
        <div class="col-md-4 px-xl-4">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" 
              rxText="CustomerHolidayAddEditComponent_HolidayAlloted_t"></label>
            <div class="col-md-8">
              <input type="text" class="form-control" formControlName="holidayAlloted" 
                rxPlaceholder="CustomerHolidayAddEditComponent_HolidayAlloted_p" />
              <small class="form-text text-danger"
                [class.d-block]="customerHolidayFormGroup.controls.holidayAlloted.errors">{{customerHolidayFormGroup.controls.holidayAlloted.errorMessage}}<br /></small>
            </div>
          </div>
        </div>
        <div class="col-md-4 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="CustomerHolidayAddEditComponent_WorkPubHoliday_t"></label> <!-- rxText="workPublicHoliday" -->
            <div class="col-md-8" [rxSelectExtended]="_this">
              <!-- <select class="form-control" formControlName="workPublicHoliday">
                <option>Select</option>
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select> -->
              <rx-select [(source)]="choises" #rxSelect mainClass="form-control"
                formControlName="workPublicHoliday" [keyValueProps]="['choise','id']" [selectPlaceholder]="'Select Works On Public Holidays'">
              </rx-select>
              <small class="form-text text-danger"
                [class.d-block]="customerHolidayFormGroup.controls.workPublicHoliday.errors">{{customerHolidayFormGroup.controls.workPublicHoliday.errorMessage}}<br /></small>
            </div>
          </div>
        </div>
        <div class="col-md-8 pr-xl-5">
          <div class="form-group row">
            <label class="col-md-2 col-form-label"  rxText="CustomerHolidayAddEditComponent_WeekOff_t"></label>
            <div *ngFor="let item of holidayProcessLookups.weekDaysLookUp; let i=index">
              <div class="col-md-auto d-flex align-items-center">
                <div class="i-checks">
                  <label><input type="checkbox" [value]="item.weekDayId" id="{{item.weekDayId}}"><i></i>
                    {{item.weekDayName}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-auto">
        <div class="hr-line-dashed border-top w-100"></div>
        <div class="col text-right">
          <button (click)="editHoliday(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
          <button (click)="editHoliday(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="wrapper wrapper-content" *ngIf="!showComponentHoliday">

    <div class="ibox mb-0 d-flex flex-column">
      <div class="ibox-title bg-white">
        <h5>Edit Holidays</h5>
      </div>
      <app-leave-master-edit [isHolidayList]="isHolidayList" [id]="id"></app-leave-master-edit>
    </div>

</div>-->