import { prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class RiskPressMediaAddViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "false";
        this.orderBy = "pressMedia";
    }



    @prop({
      defaultValue : 0
    })
    hashTag : number;

    @prop({
      defaultValue : 0
    })
    riskId : number;

    @prop({
      defaultValue : 0
    })
    hseId : number;

    @prop({
      defaultValue : ''
    })
    @trim()
    peoples : string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;


}
