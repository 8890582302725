import {POSBrandMappingBase,} from '@app/database-models'
//Generated Imports
export class POSBrandMapping extends POSBrandMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





































}