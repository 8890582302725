import {GemfieldsCarrierBase,} from '@app/database-models'
//Generated Imports
export class GemfieldsCarrier extends GemfieldsCarrierBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}