import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCompanyCustomerMappingBase {

//#region companyCustomerMappingId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'companyCustomerMappingId', keyColumn: true})
        companyCustomerMappingId : number;
//#endregion companyCustomerMappingId Prop


//#region companyId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'companyId', keyColumn: false})
        companyId : any;
//#endregion companyId Prop


//#region supplierId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'supplierId', keyColumn: false})
        supplierId : any;
//#endregion supplierId Prop


//#region userId Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'userId', keyColumn: false})
        userId : number;
//#endregion userId Prop


//#region startDate Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'startDate', keyColumn: false})
        startDate : any;
//#endregion startDate Prop


//#region endDate Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'endDate', keyColumn: false})
        endDate : any;
//#endregion endDate Prop


//#region reportTo Prop
        @gridColumn({visible: true, columnIndex:6, allowSorting: true, headerKey: 'reportTo', keyColumn: false})
        reportTo : number;
//#endregion reportTo Prop


//#region jobTitle Prop
        @gridColumn({visible: true, columnIndex:7, allowSorting: true, headerKey: 'jobTitle', keyColumn: false})
        jobTitle : string;
//#endregion jobTitle Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:8, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region isDirector Prop
        @gridColumn({visible: true, columnIndex:9, allowSorting: true, headerKey: 'isDirector', keyColumn: false})
        isDirector : boolean;
//#endregion isDirector Prop

//#region company Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'company', keyColumn: false})
        company : string;
//#endregion company Prop
//#region company Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'fullName', keyColumn: false})
        fullName : string;
//#endregion company Prop

//#region IndustryTypeName Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'industryTypeName', keyColumn: false})
        industryTypeName : string;
//#endregion IndustryTypeName Prop

//#region status Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'status', keyColumn: false})
        status : string;
//#endregion status Prop

//#region isActive Prop
        @gridColumn({visible: false, columnIndex:8, allowSorting: true, headerKey: 'isActive', keyColumn: false,configuredTemplate:{templateName:"activeFormerBind"}})
        isActive : boolean;
//#endregion isActive Prop

}