<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_User_Roles_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content"  [rxSpinner]="spin" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_User_Roles_t"></label>
      </h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>
    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <a [routerLink]="['/roles/add']" class="btn btn-success btn-sm mb-3" *rxAuthorize="[roleAdd]" rxText="Btn_Add_New_t"></a>


        <label class="ml-auto ml-lg-0 mr-0 mb-3 order-lg-3">
          <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
            placeholder=""  [(ngModel)]="SearchString" [title]="titleSearch">
        </label>
        <div class="ml-auto mb-3 col-md-12 col-lg-3 col-xl-2 order-lg-2 px-0 px-lg-3" *ngIf="showComponent" [rxSelectExtended]="_this">
          <!--<label class="col-md-1 col-lg-1 col-xl-1 col-form-label" rxText="roleRoleType"></label>-->
          <!-- <select class="form-control" (change)="filterByRole($event.target.value)">
            <option value="All">Select Role Type</option>
            <option *ngFor="let item of roleLookups.customerRoleTypeLookUp" [value]="item.customerRoleName">{{item.customerRoleName}}</option>
          </select> -->
          <rx-select [(source)]="roleLookups.customerRoleTypeLookUp" #rxSelect mainClass="form-control"
            [selectPlaceholder]="'Select Role Type'"
            (changed)="filterByRole($event)" [keyValueProps]="['customerRoleName','customerRoleName']">
          </rx-select>
        </div>
      </div>

      <div class="table-responsive" *ngIf="!isNoRecordFound">
        <div id="roles" class="dataTables_wrapper pb-0 dt-bootstrap4">


        </div>
      </div>
      <div class="ibox-title bg-white p-0" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>

</div>