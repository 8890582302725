import { vEventsLookUpBase,} from '@app/database-models'
//Generated Imports
export class vEventsLookUp extends vEventsLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}