import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductVariantStatusRecordBase {

//#region productStatusId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'productStatusId', keyColumn: true})
        productStatusId : number;
//#endregion productStatusId Prop


//#region productStatus Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'productStatus', keyColumn: false})
        productStatus : string;
//#endregion productStatus Prop

}