import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { AbstractOwnershipMapping } from '../domain/abstract-ownership-mapping';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';

import { OwnershipMapping } from '@app/models';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { dateCompareValidation, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { PlatformLocation } from "@angular/common";
@multilingual("OwnershipMappingEditComponent")
@Component({
    selector: "app-ownership-mapping-edit",
    templateUrl: './ownership-mapping-edit.component.html'
})
export class OwnershipMappingEditComponent extends AbstractOwnershipMapping implements OnInit, OnDestroy {
    ownershipMapping: OwnershipMapping;
    subscription: any;
    id: number;
    @Input() corporateBy: number;
    @Input() hide: Function;
    @Input() ownershipId: number;
    titleClose: string;
    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, modalView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.activatedRoute.queryParams.subscribe(t => {
            this.id = t['id'];
        })
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.get({ params: [this.ownershipId] }).subscribe((t: any) => {
            this.ownershipMapping = new OwnershipMapping();
            this.ownershipMapping = t;
            this.peopleOrCorporate = this.ownershipMapping.customerID > 0 ? 0 : 1;
            this.ownershipMappingFormGroup = this.formBuilder.formGroup(OwnershipMapping, this.ownershipMapping) as IFormGroup<OwnershipMapping>;
            this.ownershipMappingFormGroup.controls.ownershipPerc.setValidators([RxwebValidators.required({ message: "You can't leave this field empty" }), RxwebValidators.pattern({ expression: { 'ownershipPerc': /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/ }, message: 'Please enter valid ownership percentage' })]);
            this.showComponent = true;
        })
    }

    saveOwnershipMappings() {
        if ((this.ownershipMappingFormGroup.value.startDate != null && this.ownershipMappingFormGroup.value.endDate != null) && dateCompareValidation(this.ownershipMappingFormGroup.value.startDate, this.ownershipMappingFormGroup.value.endDate) == true) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            this.ownershipMappingFormGroup.submitted = true;
            if (this.ownershipMappingFormGroup.valid) {
                this.spin = true;
                this.put({ body: this.ownershipMappingFormGroup.value, params: [this.ownershipId] }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Updated");
                    if (existsAlert) {
                        //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                });
            }
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "OwnershipMappingEditComponent";
    }
}
