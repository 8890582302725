import { TemplateConfig } from '@rxweb/grid';

export const ORDER_TOTAL_FORMAT: TemplateConfig = {
  div: {
    childrens: [
      {
        span: {
          childrens: [{
            text: {
              text: function (e) {
                var ordertotal = ('$' + this.orderTotal + ' (USD)').toString();
                return ordertotal;
              }
            }
          }]
        }
      }
    ]
  },
};