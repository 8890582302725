import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AbstractMindMap } from '../domain/abstract-mind-map';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { Title } from '@angular/platform-browser';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { multilingual } from '@rxweb/localization';
import { MindMapFilterViewModel } from 'src/app/view-model/mind-map-filters-view-model';
import { MindMapService } from 'src/app/domain/common-logic/mind-map.service';
import { access } from '@rxweb/angular-router';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { rolesEnum } from 'src/app/enums/roles-enum';

@multilingual("MindMapDetailComponent")
@access({ accessLevel: RolePermissionEnums.MindMap, action: "get" })

@Component({
  selector: 'app-mind-map-detail',
  templateUrl: './mind-map-detail.component.html',
  styleUrls: ['./mind-map-detail.component.css']
})
export class MindMapDetailComponent extends AbstractMindMap implements OnInit, AfterViewInit, OnDestroy {

  @Input() entityID: number;
  @Input() moduleID: number;
  @Input() selectedTab: string = "";
  @Input() entityName: string = "";
  @Input() imageUrl:string = "";
  mindMapFilters: MindMapFilterViewModel = new MindMapFilterViewModel();
  mindMapServiceLocal: MindMapService;
  toastr: BaseToastr;
  browserStorage: BrowserStorage;
  loggedInUserRole: string;

  constructor(mindMapService: MindMapService, chRef: ChangeDetectorRef, private title: Title, modalView: ModalView, private formBuilder: RxFormBuilder, private router: Router, private applicationBroadcaster: ApplicationBroadcaster) {
    super(mindMapService, chRef);
    Window['MindMapDetailComponent'] = this;
    this.mindMapServiceLocal = mindMapService;
    this.toastr = new BaseToastr();
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
    if(!(this.loggedInUserRole.split(',').includes(rolesEnum.Administrator.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRCompanySpecific.toString()) || this.loggedInUserRole.split(',').includes(rolesEnum.HRGroupwide.toString()))){
      if (!dbAccessModule[RolePermissionEnums.MindMap]["get"]) {
        this.toastr.info(getLocalizedValue("WarningMessage_DontAccessKey_t"));
        this.router.navigate(['/dashboard']);
      }
    }
  }

  ngOnInit() {
    this.rootEntityName = this.entityName;
    this.mindMapFilters = new MindMapFilterViewModel();
    this.mindMapFilters.rootEntityTypeId = this.moduleID;
    this.mindMapFormGroup = this.formBuilder.formGroup(this.mindMapFilters) as IFormGroup<MindMapFilterViewModel>;
  }

  ngAfterViewInit(): void {
    this.setupMindMap(this.moduleID, this.entityID , this.imageUrl);
    this.mindMapVisible = true;
  }

  ngOnDestroy(): void {
    this.storage.local.remove("mindmapEntity");
    this.mindMapServiceLocal.graphId = 1;
    this.mindMapServiceLocal.resetTempSubGraphData();
  }

  get componentName(): string {
    return "MindMapDetailComponent";
  }

}
