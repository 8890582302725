import {AddressBase,UserBase,SupplierBase,} from '@app/database-models'
import { prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class Address extends AddressBase 
{

    @prop({defaultValue: false})
    isPOS: boolean;
    @prop({defaultValue: false})
    isStoreAddress: boolean;
    @prop({defaultValue: false})
    sameBillingShippingAddress: boolean;


//#region Generated Reference Properties
//#region user Prop
user : UserBase;
//#endregion user Prop
//#region supplier Prop
supplier : SupplierBase;
//#endregion supplier Prop

//#endregion Generated Reference Properties












}