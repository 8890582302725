import { gridColumn, actionColumn } from '@rxweb/grid';
@actionColumn({
    allowSorting: false,
    style: { width: "1%","text-align": "initial"},
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
  })

export class CustomerCorporateMapplingListViewModel {

    @gridColumn({ style: { width: "0.3%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0,keyColumn: false,headerTitle: 'Action', })
    isChecked: boolean
//#region companyId Prop
    @gridColumn({style: { width: "1%", "text-align": "initial" },visible: false, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    companyId : number;
//#endregion companyId Prop

//#region companyCustomerMappingId Prop
   // @gridColumn({style: { width: "1%", "text-align": "initial" },visible: false,columnIndex: 0, headerTitle: 'Action', keyColumn: true })
   // companyCustomerMappingId : number;
//#endregion companyCustomerMappingId Prop

//#region userId Prop
  
    //@gridColumn({style: { width: "1%", "text-align": "initial" },visible: true,configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: true,allowSorting: false,  })
    //userId : number;
//#endregion userId Prop

//#region supplierId Prop
   // @gridColumn({style: { width: "1%", "text-align": "initial" },visible: false,columnIndex: 0, headerTitle: 'supplierId', keyColumn: false })
    @gridColumn({style: { width: "1%", "text-align": "initial" },visible: false,columnIndex: 0, headerTitle: 'Action', keyColumn: true,allowSorting: false,  })
    supplierId : number;
//#endregion supplierId Prop    

//#region company Prop
    @gridColumn({style: { width: "3%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:1,  allowSorting: true, headerTitle: 'Company', keyColumn: false})
    company : string;
//#endregion company Prop

//#region startDate Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:4,  allowSorting: true, headerTitle: 'Start Date', keyColumn: false})
    startDate : Date;
//#endregion startDate Prop

//#region startDate Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:4,  allowSorting: true, headerTitle: 'End Date', keyColumn: false})
    endDate : Date;
//#endregion startDate Prop

//#region isDirector Prop
    @gridColumn({configuredTemplate: { templateName: "recordActive" },style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:5,  allowSorting: true, headerTitle: 'Director', keyColumn: false})
    isDirector : boolean;
//#endregion isDirector Prop

//#region jobTitle Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:6,  allowSorting: true, headerTitle: 'Job Title', keyColumn: false})
    jobTitle : string;
//#endregion jobTitle Prop

//#region industryTypeId Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:6,  allowSorting: true, headerTitle: 'industryTypeId', keyColumn: false})
    industryTypeId : number;
//#endregion industryTypeId Prop

//#region industryTypeName Prop
    @gridColumn({style: { width: "3%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:3,  allowSorting: true, headerTitle: 'Industry Type Name', keyColumn: false})
    industryTypeName : string;
//#endregion industryTypeName Prop

//#region reportTo Prop
   // @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:6,  allowSorting: true, headerTitle: 'Report To', keyColumn: false})
   // reportTo : number;
//#endregion reportTo Prop

//#region reportTo Prop
    @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:6,  allowSorting: true, headerTitle: 'Report To', keyColumn: false})
    name : string
//#endregion reportTo Prop

//#region countryName Prop
    @gridColumn({style: { width: "3%", "text-align": "left" }, class: ["text-left"], visible: true, columnIndex:2,  allowSorting: true, headerTitle: 'Country', keyColumn: false})
    countryName : string;
//#endregion countryName Prop

//#region countryName Prop
  //  @gridColumn({style: { width: "1%", "text-align": "left" }, class: ["text-left"], visible: false, columnIndex:2,  allowSorting: true, headerTitle: 'Status', keyColumn: false})
   // status : string;
//#endregion countryName Prop


//#region totalCount Prop
    @gridColumn({visible: false, columnIndex:6,  allowSorting: true, headerKey: 'totalCount', keyColumn: false})
    totalCount : number;
//#endregion totalCount Prop
}