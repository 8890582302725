
<div *ngIf="showComponent" class="modal fade show" id="address_edit" tabindex="-1" role="dialog" style="padding-right: 17px; display: block;">

  <div class="modal-dialog">
    <div class="modal-content" [formGroup]="documentFormGroup">
      <div class="modal-header text-left">
        <h4 class="modal-title mb-0">Edit Document</h4>
        <button type="button" (click)="hide()" class="close py-2" data-dismiss="modal" [title]="titleClose">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Select Document to upload</label>
          <div class="col-md-8">
            <div class="custom-file">
              <input (change)="onFileChange($event)" id="logo" type="file" class="custom-file-input">
              <label for="logo" class="custom-file-label">Choose file...</label>
              <label class="mt-1" *ngIf="documentFormGroup.value.file  && documentFormGroup.value.fileName ">{{documentFormGroup.value.file.name}}</label>
              <label class="mt-1" *ngIf="documentFormGroup.value.fileName && !documentFormGroup.value.file">{{documentFormGroup.value.fileName}}</label>
              <small class="form-text text-danger" [class.d-block]="documentFormGroup.controls.file.errors">{{documentFormGroup.controls.file.errorMessage}}</small>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-lg-3 col-xl-4 col-form-label">Document Name</label>
          <div class="col-md-8">
            <input type="text" formControlName="fileName" class="form-control">
            <small class="form-text text-danger" [class.d-block]="documentFormGroup.controls.fileName.errors">{{documentFormGroup.controls.fileName.errorMessage}}</small>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="uploadDocument()" class="btn btn-success">Save</button>
        <button type="button" (click)="hide()" class="btn btn-danger">Close</button>
      </div>
    </div>
  </div>
</div>

