import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractLeaveTypeCorporateMapping } from '../domain/abstract-leave-type-corporate-mapping';
import { LeaveType, vLeaveType } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { LeaveTypeCorporateMappingAddComponent } from '../add/leave-type-corporate-mapping-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { LeaveTypeCorporateMappingViewModel } from "src/app/models/extended-models/leave-type-corporate-mapping-view-model";
import { PlatformLocation } from "@angular/common";

@access({ accessLevel: RolePermissionEnums.HolidayLeaveTypes, action: "get" })

@multilingual("LeaveTypeCorporateMappingListComponent")
@Component({
    selector: "app-leave-type-corporate-mapping-list",
    templateUrl: './leave-type-corporate-mapping-list.component.html'
})
export class LeaveTypeCorporateMappingListComponent extends AbstractLeaveTypeCorporateMapping implements OnInit, OnDestroy {
    subscription: any;
    id: number = 0;
    loggedInUser: number;
    isFilter: boolean = false;
    titleSearch: string;
    json: any;
    totalRecords: number = 0;
    leaveTypeCorporateGridColumns: any;
    leaveTypeCorporateMappingList: LeaveTypeCorporateMappingViewModel[];


    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private activatedRoute: ActivatedRoute, private browserStorage: BrowserStorage, private location : PlatformLocation) {
        super();
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        if (this.browserStorage.local.get("userId") != null) {
            this.loggedInUser = this.browserStorage.local.get("userId");
        } else {
            this.loggedInUser = 0;
        }
        this.modalView = modelView;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.isFilter = false;
        this.titleSearch = getLocalizedValue("Title_Search");
        this.json = JSON.parse(JSON.stringify({
            leaveTypeId: this.id,
            //userId: this.browserStorage.local.get("userId"),
            pageIndex: 1,
            rowCount: 25,
            sortOrder: true,
            orderBy: 'companyName',
            searchString: ''
        }));
        this.json["pageIndex"] = PaginationEnum.PageIndex;
        this.json["rowCount"] = PaginationEnum.RowCount;
        this.bindGrid();
    }

    bindGrid() {
        this.isShowGrid = true;
        this.spin = true;

        this.get({ path: 'api/LeaveTypeCorporateMappings', queryParams: { query: encodeURIComponent(JSON.stringify(this.json)) } }).subscribe((t: any) => {
            this.spin = false;
            this.leaveTypeCorporateMappingList = t;

            if (this.leaveTypeCorporateMappingList.length > 0) {
                this.totalRecords = this.leaveTypeCorporateMappingList[0].totalCount;
                this.isNoRecordFound = false;
            }
            else {
                this.isNoRecordFound = true;
                this.isShowGrid = false;
            }

            if (!this.isFilter) {
                this.leaveTypeCorporateGrid = new AppGrid(this.leaveTypeCorporateMappingList, LeaveTypeCorporateMappingViewModel, { actions: { onDelete: this.onDelete.bind(this)} });
                this.leaveTypeCorporateGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
            }
            else {
                if (this.leaveTypeCorporateGrid != null) {
                    this.leaveTypeCorporateGrid.storeProcedure.length = this.totalRecords;
                    this.leaveTypeCorporateGrid.updateSource([]);
                    this.leaveTypeCorporateGrid.updateSource(this.leaveTypeCorporateMappingList)
                }
            }
            this.leaveTypeCorporateGrid.designClass.tableClass.push("table-list");
            if (this.leaveTypeCorporateGrid != null) {
                this.leaveTypeCorporateGridColumns = this.leaveTypeCorporateGrid.gridColumns.filter(x => x.keyColumn == false);
                //this.applyClassProjectCorporateGrid();
            }
            this.leaveTypeCorporateGrid.maxPerPage = this.json['rowCount'];
            this.leaveTypeCorporateGrid.componentId = this.componentName;
            this.spin = false;
            this.showComponent = true;
        });

    }

    showAddForm() {
        this.modalView.show(LeaveTypeCorporateMappingAddComponent, { leaveTypeId: this.id }).then(t => {
            this.isFilter = true;
            this.totalRecords = 0;
            this.leaveTypeCorporateGrid.updateSource([]);
            this.bindGrid();
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onDelete(corporate: LeaveTypeCorporateMappingViewModel) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [corporate.leaveTypeCorporateMappingId] }).subscribe(data => {
                    this.spin = false;
                    this.leaveTypeCorporateGrid.remove(corporate.leaveTypeCorporateMappingId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    //this.router.navigate(['/leave-types']);
                    this.bindGrid();
                })
            }
            this.dialog.hide()
        })

    }

    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Leave_Type_Company");
        if (existsAlert) {
            return existsAlert;
        }
    }

    search(value) {
        setTimeout(() => {
            this.spin = false;
            this.isFilter = true;
            this.json['searchString'] = value.target.value.replace(/^\s+|\s+$/gm, '');
            this.totalRecords = 0;
            this.leaveTypeCorporateGrid.storeProcedure.nextPage = 1;
            this.json['pageIndex'] = 1;
            this.bindGrid();
        }, 4000);
        this.spin = true;
    }

    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.json['sortOrder'] = y;
        this.json['orderBy'] = x;
        this.leaveTypeCorporateGrid.storeProcedure.nextPage = z;
        this.leaveTypeCorporateGrid.updateSource([]);
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.json['pageIndex'] = x;
        this.json['rowCount'] = this.leaveTypeCorporateGrid.maxPerPage
        this.leaveTypeCorporateGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    get componentName(): string {
        return "LeaveTypeCorporateMappingListComponent";
    }
}
