import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_LINK_FABERGE_PRICE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLink"
          },
          childrens: [{
            text: {
              text: function (e) {
                if (this.bookingProductCount > 0) {
                  return this[e.name]
                } else {
                  return "";
                }
              }
            }
          }],
          // attributes: {
          //   href: function (e) {
          //     if (this.tripId != null || this.tripId != undefined) {
          //       return "/print-look-book?tripid=" + this.tripId + "&withprice=1";
          //     }
          //   },
          //   "ng-reflect-router-link": function (e) {
          //     if (this.tripId != null || this.tripId != undefined) {
          //       return "/print-look-book?tripid=" + this.tripId + "&withprice=1";
          //     }
          //   },
          //   onClick: "return false;"
          // }
        }
      }
    ]
  },
};

export const REDIRECT_LINK_FABERGE_WITHOUT_PRICE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOneLinkWithoutPrice"
          },
          childrens: [{
            text: {
              text: function (e) {
                if (this.bookingProductCount > 0) {
                  return this[e.name]
                } else {
                  return "";
                }
              }
            }
          }],
          // attributes: {
          //   href: function (e) {
          //     if (this.tripId != null || this.tripId != undefined) {
          //       return "/print-look-book?tripid=" + this.tripId + "&withprice=0";
          //     }
          //   },
          //   "ng-reflect-router-link": function (e) {
          //     if (this.tripId != null || this.tripId != undefined) {
          //       return "/print-look-book?tripid=" + this.tripId + "&withprice=0";
          //     }
          //   },
          //   onClick: "return false;"
          // }
        }
      }
    ]
  },
};