import {vTaxDisplayTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vTaxDisplayTypeLookUp extends vTaxDisplayTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}