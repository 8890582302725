import { prop, required, range } from "@rxweb/reactive-form-validators"
export class ITTicketCustomerCorporateMappingBase {

    //#region ticketCustomerId Prop
    @prop()
    ticketCustomerId: number;
    //#endregion ticketCustomerId Prop


    //#region ticketId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    ticketId: number;
    //#endregion ticketId Prop


    //#region userId Prop
    @prop()
    userId: number;
    //#endregion userId Prop


    //#region supplierId Prop
    @prop()
    supplierId: number;
    //#endregion supplierId Prop

    //#region addedBy Prop
    @prop()
    addedBy: number;
    //#endregion addedBy Prop

    //#region createdOn Prop
    @prop()
    createdOn: Date;
    //#endregion createdOn Prop


    //#region enableTicketUpdateForUser Prop
    @prop()
    enableTicketUpdateForUser: boolean;
    //#endregion enableTicketUpdateForUser Prop
}