import {HelpSupportUserRoleBase,HelpSupportBase,} from '@app/database-models'
import { PowerBIReportUserRoleBase } from '../database-models/power-bi-report-user-role-base';
import { PowerBIReport } from './power-bi-report';
import { PowerBIReportBase } from '../database-models/power-bi-report-base';
//Generated Imports
export class PowerBIReportUserRole extends PowerBIReportUserRoleBase 
{

    powerBIReport : PowerBIReportBase;

}