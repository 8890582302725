import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class AdhocPagesBase {

//#region adhocId Prop
        @prop()
        adhocId : number;
//#endregion adhocId Prop


//#region name Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        @trim()
      //  @alpha({allowWhiteSpace:true})
        name : string;
//#endregion name Prop


//#region description Prop
        @prop()
        @maxLength({value:4000})
        @trim()
        description : string;
//#endregion description Prop


//#region metakeyword Prop
        @prop()
        @maxLength({value:4000})
        @trim()
        metakeyword : string;
//#endregion metakeyword Prop


//#region metaDescription Prop
        @prop()
        @maxLength({value:4000})
        @trim()
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @prop()
        @maxLength({value:2000})
        @trim()
        metaTitle : string;
//#endregion metaTitle Prop


//#region parentAdhocId Prop
        @prop()
        parentAdhocId : number;
//#endregion parentAdhocId Prop


//#region inspirations Prop
        @prop()
        @maxLength({value:1000})
        @trim()
        inspirations : string;
//#endregion inspirations Prop


//#region displayOrderId Prop
        @prop()
        @numeric()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrderId : number;
//#endregion displayOrderId Prop


//#region published Prop
        @prop({defaultValue:false})
        published : boolean;
//#endregion published Prop


//#region videoUrl Prop
        @maxLength({value:500})
        @trim()
        videoUrl : string;
//#endregion videoUrl Prop


//#region viewByPeople Prop
        @prop({defaultValue:false})
        viewByPeople : boolean;
//#endregion viewByPeople Prop


//#region imageUrlOne Prop
        @maxLength({value:100})
        @trim()
        imageUrlOne : string;
//#endregion imageUrlOne Prop


//#region imageUrlSecond Prop
        @maxLength({value:100})
        @trim()
        imageUrlSecond : string;
//#endregion imageUrlSecond Prop


//#region imageUrlThird Prop
        @maxLength({value:100})
        @trim()
        imageUrlThird : string;
//#endregion imageUrlThird Prop


//#region imageUrlFourth Prop
        @maxLength({value:100})
        @trim()
        imageUrlFourth : string;
//#endregion imageUrlFourth Prop


//#region imageUrlFifth Prop
        @maxLength({value:100})
        @trim()
        imageUrlFifth : string;
//#endregion imageUrlFifth Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region parentLanguageAdhocId Prop
        @prop()
        parentLanguageAdhocId : number;
//#endregion parentLanguageAdhocId Prop


    //#region mobileDescription Prop
    @prop()
    @maxLength({ value: 400 })
    mobileDescription:string;
    //#endregion mobileDescription Prop

     //#region frontBannerContentColour Prop
    @prop({defaultValue:0})
    frontBannerContentColour:number;
     //#region frontBannerContentColour Prop

//#region IsStandard Prop
@prop({defaultValue:0})
// @required()
isStandard : number;
//#endregion IsStandard Prop

//#region SEName Prop
@prop()
@trim()
@required()
@maxLength({value:50})
seName : string;
//#endregion SEName Prop

@maxLength({ value: 500 })
@trim()
titleImageAlt :string;

@maxLength({ value: 500 })
@trim()
imageAlt1 :string;

@maxLength({ value: 500 })
@trim()
imageAlt2 :string;

@maxLength({ value: 500 })
@trim()
imageAlt3 :string;

@maxLength({ value: 500 })
@trim()
imageAlt4 :string;

@maxLength({ value: 500 })
@trim()
imageAlt5 :string;


@prop({ defaultValue: true })
showImageUrlOne: boolean;

@prop({ defaultValue: false })
showImageUrlFifth: boolean;


}