import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vProductionFormSalesPersonBase {

//#region salespersonId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'salespersonId', keyColumn: true})
        salespersonId : number;
//#endregion salespersonId Prop


//#region fullName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'fullName', keyColumn: false})
        fullName : string;
//#endregion fullName Prop

}