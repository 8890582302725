import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core"
import { AbstractClientRepair } from '../domain/abstract-client-repair';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';

import { ClientRepair } from '@app/models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileInputViewModel } from 'src/app/view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums, FileTypeValiationEnums } from 'src/app/custom-enum/file-type-enums';
import { BLOB_CONTAINER, BLOB_PUBLIC_URI, CLIENT_URL, SERVER_URL } from 'src/app/domain/system-constant';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { PlatformLocation } from "@angular/common";

@multilingual("ClientRepairEditComponent")
@Component({
    selector: "app-client-repair-edit",
    templateUrl: './client-repair-edit.component.html'
})
export class ClientRepairEditComponent extends AbstractClientRepair implements OnInit, OnDestroy {
    clientRepair: ClientRepair;
    subscription: any;
    id: number;
    @Input() supplierId: number
    @Input() customerId: number
    salesPerson: string
    @Input() clientName: string;
    @Input() hide: Function;
    @Input() clientRepairId: number;
    approveStatus: string;
    remainingArray: number[];
    firstUpload: boolean;
    showClientRepairApproval: boolean;
    estimationLookup: EstimationLookup[];
    userId: number;
    showimage1: boolean;
    showimage2: boolean;
    showimage3: boolean;
    showFile1: boolean;
    showFile2: boolean;
    showFile3: boolean;
    titleClose: string;



    _this;
    constructor(private browserStorage: BrowserStorage, private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, sanitizer: DomSanitizer,private location : PlatformLocation) {
        super(sanitizer);
        this.activatedRoute.queryParams.subscribe(t => {
            this.id = t['id'];
        })
        this._this = this;
        location.onPopState(() => this.hide());
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.formArray = new Array<number>();
        this.remainingArray = new Array<number>();
        this.oldPieceError = false;
        this.fileURL1 = null;
        this.fileURL2 = null;
        this.fileURL3 = null;
        this.disabelPieces = false;
        this.userId = this.supplierId == null ? this.customerId : this.supplierId;
        this.showComponent = false;
        this.supplierId = this.supplierId == null ? 0 : this.supplierId;
        this.customerId = this.customerId == null ? 0 : this.customerId;
        this.lookup([{ path: 'api/UserLookups/CustomerPieceLookups', propName: 'pieceLookup', params: [this.customerId, this.supplierId] },
        { params: [this.clientRepairId], propName: "clientRepair" },]).subscribe((t: any) => {
            this.clientRepair = t.clientRepair;
            this.estimationLookup = new Array<EstimationLookup>();
            let estimation = new EstimationLookup();
            estimation = new EstimationLookup();
            estimation.estimationId = 2;
            estimation.estimation = "No";
            this.estimationLookup.push(estimation);
            estimation = new EstimationLookup();
            estimation.estimationId = 1;
            estimation.estimation = "Yes";
            this.clientRepair.estimationId = this.clientRepair.estimationRequired ? 1 : 2;
            this.clientRepair.uploadFile1 == null ? this.showimage1 = false : this.showimage1 = true;
            this.clientRepair.uploadFile2 == null ? this.showimage2 = false : this.showimage2 = true;
            this.clientRepair.uploadFile3 == null ? this.showimage3 = false : this.showimage3 = true;
            this.estimationLookup.push(estimation);
            if (this.clientRepair.pieceIds != null) {
                this.clientRepair.pieceIds = this.clientRepair.pieceIds.slice(0, this.clientRepair.pieceIds.length - 1)
            }

            this.pieceLookup = t.pieceLookup;
            this.salesPerson = this.clientRepair.salesPersonName;
            this.clientName = this.clientRepair.clientName;
            this.showClientRepairApproval = false;
            if (this.clientRepair.approvalStatus == 0 && this.clientRepair.showClientRepairApproval == true) {
                this.showClientRepairApproval = true;
            }
            if (this.clientRepair.uploadFile1 != null) {
                //this.index+=1;
                this.firstUpload = false;
                this.formArray.push(1);
            }
            if (this.clientRepair.uploadFile2 != null) {
                // this.index+=1;
                this.firstUpload = false;
                this.formArray.push(2);
            }
            if (this.clientRepair.uploadFile3 != null) {
                // this.index+=1;
                this.firstUpload = false;
                this.formArray.push(3);
            }

            this.remainingArray.push(1);
            this.remainingArray.push(2);
            this.remainingArray.push(3);
            for (var i = 1; i <= 3; i++) {
                if (this.formArray.findIndex(x => x == i) != -1) {
                    this.remainingArray.splice(this.remainingArray.findIndex(z => z == i), 1);
                }
            }

            if (this.clientRepair.approvalStatus != null) {
                if (this.clientRepair.approvalStatus == 0) {
                    this.approveStatus = "PENDING";
                }
                else if (this.clientRepair.approvalStatus == 1) {
                    this.approveStatus = "APPROVED";
                }
                else {
                    this.approveStatus = "DECLINED";
                }
            }
            if (this.pieceLookup == null || this.pieceLookup.length == 0) {
                this.disabelPieces = true;
            }
            //this.clientRepair.clientURL=CLIENT_URL;
            this.clientRepairFormGroup = this.formBuilder.formGroup(ClientRepair, this.clientRepair) as IFormGroup<ClientRepair>;
            this.initiatefiles();
            this.showComponent = true;
        })
    }



    initiatefiles() {
        let fileUrl = BLOB_PUBLIC_URI + "/" + BLOB_CONTAINER + "/documents/user/";
        this.fileInputViewModel1 = new FileInputViewModel();
        this.fileInputViewModel2 = new FileInputViewModel();
        this.fileInputViewModel3 = new FileInputViewModel();
        this.fileInputViewModel1.fileMode = FileTypeEnums.All;
        this.fileInputViewModel1.fileAllowSize = FileSizeEnums.FiveMB;
        this.fileInputViewModel2.fileMode = FileTypeEnums.All;
        this.fileInputViewModel2.fileAllowSize = FileSizeEnums.FiveMB;
        this.fileInputViewModel3.fileMode = FileTypeEnums.All;
        this.fileInputViewModel3.fileAllowSize = FileSizeEnums.FiveMB;
        if (this.clientRepair.uploadFile1 != null) {

            this.fileURL1 = fileUrl + this.clientRepair.uploadFile1;
            if (this.fileURL1 != null) {
                var replacePercentage = this.fileURL1.replace(/%/g, '%25')
                var replaceHashtag = replacePercentage.replace(/#/g, '%23');
                this.fileURL1 = replaceHashtag;

                let extension = this.fileURL1.substr((this.fileURL1.lastIndexOf('.') + 1)).toLowerCase();
                let split_str = FileTypeValiationEnums.Image
                if (split_str.indexOf(extension) == -1) {
                    this.showFile1 = false;
                }
                else {
                    this.showFile1 = true;
                }
            }

        }

        if (this.clientRepair.uploadFile2 != null) {

            this.fileURL2 = fileUrl + this.clientRepair.uploadFile2;
            if (this.fileURL2 != null) {
                var replacePercentage = this.fileURL2.replace(/%/g, '%25')
                var replaceHashtag = replacePercentage.replace(/#/g, '%23');
                this.fileURL2 = replaceHashtag;

                let extension = this.fileURL2.substr((this.fileURL2.lastIndexOf('.') + 1)).toLowerCase();
                let split_str = FileTypeValiationEnums.Image
                if (split_str.indexOf(extension) == -1) {
                    this.showFile2 = false;
                }
                else {
                    this.showFile2 = true;
                }
            }

        }

        if (this.clientRepair.uploadFile3 != null) {

            this.fileURL3 = fileUrl + this.clientRepair.uploadFile3;
            if (this.fileURL3 != null) {
                var replacePercentage = this.fileURL3.replace(/%/g, '%25')
                var replaceHashtag = replacePercentage.replace(/#/g, '%23');
                this.fileURL3 = replaceHashtag;

                let extension = this.fileURL3.substr((this.fileURL3.lastIndexOf('.') + 1)).toLowerCase();
                let split_str = FileTypeValiationEnums.Image
                if (split_str.indexOf(extension) == -1) {
                    this.showFile3 = false;
                }
                else {
                    this.showFile3 = true;
                }
            }

        }



    }

    updateEstimation() {
        if (this.clientRepairFormGroup.value.estimationId != null && this.clientRepairFormGroup.value.estimationId.toString() != "") {
            this.clientRepairFormGroup.patchValue({
                estimationRequired: this.clientRepairFormGroup.value.estimationId == 1 ? true : false
            });
        } else {
            this.clientRepairFormGroup.patchValue({
                estimationRequired: null
            });
        }

    }


    removeFile(i: number) {
        this.remainingArray.push(i);
        this.formArray.splice(this.formArray.findIndex(x => x == i), 1);

        this.index -= 1;
        if (i == 1) {
            this.clientRepairFormGroup.patchValue({
                fileData: null,
                fileExtention: null,
                fileName: null,
                displayImage: null,
                uploadFile1: null
            });
            this.fileURL1 = null;
            this.clientRepair.uploadFile1 = null;
        }
        else if (i == 2) {
            this.clientRepairFormGroup.patchValue({
                fileData1: null,
                fileExtention1: null,
                fileName1: null,
                displayImage: null,
                uploadFile2: null
            });
            this.fileURL2 = null;
            this.clientRepair.uploadFile2 = null;
        }
        else {
            this.clientRepairFormGroup.patchValue({
                fileData2: null,
                fileExtention2: null,
                fileName2: null,
                displayImage: null,
                uploadFile3: null
            });
            this.fileURL3 = null;
            this.clientRepair.uploadFile3 = null;
        }
    }

    updateFileCount() {
        if (this.firstUpload == false) {
            if (this.remainingArray.length > 0) {
                this.remainingArray.forEach(x => {
                    this.formArray.push(x);
                    this.remainingArray.splice(this.remainingArray.findIndex(t => t == x), 1);
                })
            }
        }
        else {
            this.index += 1;
            this.formArray.push(this.index);
        }

    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    editClientRepair(approveStatus: any) {
        this.clientRepairFormGroup.submitted = true;
        if ((this.clientRepairFormGroup.value.pieceIds == undefined)) {
            this.clientRepairFormGroup.controls.pieceIds.setValue("");
        }
        if ((this.clientRepairFormGroup.value.oldFabergePieces == null || this.clientRepairFormGroup.value.oldFabergePieces.trim() == "") && (this.clientRepairFormGroup.value.pieceIds.trim() == "")) {
            this.oldPieceError = true;
            this.pieceIdError = true;


        }


        //   else if(this.clientRepairFormGroup.value.oldFabergePieces!=null && this.clientRepairFormGroup.value.oldFabergePieces!=""  && (this.clientRepairFormGroup.value.pieceIds=="" || this.clientRepairFormGroup.value.pieceIds==undefined)){
        //     this.oldPieceError=false;
        //      this.pieceIdError=false;
        //   }
        else {
            if (approveStatus != null) {
                this.clientRepairFormGroup.patchValue({
                    approvalStatus: approveStatus,
                    isApprove: approveStatus,
                    clientName: this.clientName,
                    salesPersonName: this.salesPerson,
                    approvedBy: this.browserStorage.local.get('userId')
                })
            }
            this.oldPieceError = false;
            this.pieceIdError = false;
            if (this.clientRepairFormGroup.valid) {
                this.spin = true;
                this.put({ body: this.clientRepairFormGroup.value, params: [this.clientRepairId] }).subscribe(t => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Data_Updated");
                    if (existsAlert) {
                        //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.hide();
                })
            }

        }

    }
    get componentName(): string {
        return "ClientRepairEditComponent";
      }

}
class EstimationLookup {
    estimationId: number;
    estimation: string;
}
