import {vSpecificationAttributeBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';


export class vSpecificationAttribute extends vSpecificationAttributeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}