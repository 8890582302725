import { TemplateConfig } from "@rxweb/grid";

export const REPAIR_FROM :TemplateConfig = {
    div: {
        childrens: [
            {
                a: {
                    class: "text-blue font-weight-bold".split(" "),
                    event: {
                      click: "onOneLink"
                    },
                    childrens: [{
                      text: {
                        text: function (e) { return this[e.name] }
                      }
                    }],
                    attributes: {
                        href: function(e) {
                            if (this.formId != null || this.formId != undefined) {
                                return "/production-order-forms/" + this.formId + "?activeTab=production-form-edit";
                            }
                        },
                        onClick: "return false;"
                    }
                }
            }
        ]
    },
}