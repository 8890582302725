import {ProjectPictureBase,PictureBase,ProjectBase,} from '@app/database-models'
//Generated Imports
export class ProjectPicture extends ProjectPictureBase 
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region project Prop
project : ProjectBase;
//#endregion project Prop

//#endregion Generated Reference Properties











}