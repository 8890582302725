import { prop } from "@rxweb/reactive-form-validators"

export class ProductPieceStatusMappingBase {

  @prop()
  productPieceStatusMappingId: number = 0;

  @prop()
  productPieceId: number;

  @prop()
  salesStatusId: number;

  @prop()
  productionTeamStatusId: number;

  @prop()
  consignmentStatusId: number;

  @prop()
  isFraud: boolean = false;

  @prop()
  isMissing: boolean = false;

  @prop()
  isGifted: boolean = false;

  @prop()
  isPrototype: boolean = false;

  @prop()
  isSampleStock: boolean = false;

  @prop()
  isStolen: boolean = false;

  @prop()
  isWrittenOff: boolean = false;
}
