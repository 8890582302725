import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { CustomerHoliday } from '@app/models';
import { AbstractCustomerHoliday } from '../domain/abstract-customer-holiday';
import { USER_LOOKUPS } from 'src/app/const/uris/user-lookups.uri';
import { Router, ActivatedRoute } from '@angular/router';
import { WeekDaysEnum } from 'src/app/enums/week-days.enum';
import { CoreComponent, access } from '@rxweb/angular-router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { CustomerHolidayEditComponent } from '../edit/customer-holiday-edit.component';
import { BrowserStorage } from 'src/app/domain/services/browser-storage';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { CustomerHolidayBulkAddComponent } from "../bulk-add/customer-holiday-bulk-add.component";
@multilingual("CustomerHolidayAddComponent")
@access({ accessLevel: RolePermissionEnums.HolidayProcess, action: "post" })

@Component({
  selector: "app-customer-holiday-add",
  templateUrl: './customer-holiday-add.component.html'
})
export class CustomerHolidayAddComponent extends AbstractCustomerHoliday implements OnInit, OnDestroy {
  customerHoliday: CustomerHoliday;
  subscription: any;
  customerHolidayEdit: any = CustomerHolidayEditComponent;
  leaveTypeLookUp: any[] = [{ leaveId: 1, leaveTypeName: 'Annual Holiday' }];
  workPublicHoliday: any[] = [{ id: 0, value: 'No' }, { id: 1, value: 'Yes' }];
  Jsonstring: any;
  decryptstring: string;
  localizedMessages: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView, private title: Title) {
    super();
    this.modalView = modalView
    this.activatedRoute.queryParams.subscribe(t => {
      //this.userId = t['userId'];
    });
    this.moduleID = RolePermissionEnums.HolidayProcess;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Holiday Process - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.lookup([
      { propName: "leaveTypeLookUp", path: USER_LOOKUPS.holidayTypeLookup },
      { propName: "weekDaysLookUp", path: USER_LOOKUPS.weekDaysLookUp },
      { propName: "adminUserLookup", path: USER_LOOKUPS.adminUserLookup }
    ]).subscribe((lookup: any) => {
      this.customerHoliday = new CustomerHoliday();
      this.customerHoliday.id = 0;
      // this.customerHoliday.userId = 0;
      this.customerHoliday.holidayType = 1;
      this.customerHolidayFormGroup = this.formBuilder.formGroup(this.customerHoliday) as IFormGroup<CustomerHoliday>;
      this.holidayProcessLookups = lookup;

      this.showComponentHoliday = true;
    });
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  addHoliday(isRedirect: boolean) {
    this.spin = true;
    this.customerHolidayFormGroup.value.weekOff = '';
    this.isChecked(WeekDaysEnum.Monday);
    this.isChecked(WeekDaysEnum.Tuesday);
    this.isChecked(WeekDaysEnum.Wednesday);
    this.isChecked(WeekDaysEnum.Thursday);
    this.isChecked(WeekDaysEnum.Friday);
    this.isChecked(WeekDaysEnum.Saturday);
    this.isChecked(WeekDaysEnum.Sunday);
    console.log(this.customerHolidayFormGroup.value);
    if (this.customerHolidayFormGroup.value.weekOff == null || this.customerHolidayFormGroup.value.weekOff == '') {
      this.customerHolidayFormGroup.value.weekOff = '0'
    }
    this.customerHolidayFormGroup.submitted = true;
    if (this.customerHolidayFormGroup.valid) {
      this.post({ body: this.customerHolidayFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          //this.toastr.success("Data Added Successfully")
          this.toastr.success(existsAlert[0].showMessage);
        }
        if (this.userId > 0) {
          this.router.navigate(['/users/', this.userId], { queryParams: { activeTab: 'leave-request' } });
        } else {
          isRedirect ? this.router.navigate(['/customer-holidays', data]) : this.router.navigate(['/customer-holidays']);
        }
      })
    }
  }

  isChecked(WeekDay: number) {
    if (document.getElementById(WeekDay.toString()) !== null) {
      var el = <HTMLInputElement>document.getElementById(WeekDay.toString());
      if (el.checked == true) {
        if (this.customerHolidayFormGroup.value.weekOff == '') {
          this.customerHolidayFormGroup.value.weekOff = this.customerHolidayFormGroup.value.weekOff + WeekDay.toString();
        } else {
          this.customerHolidayFormGroup.value.weekOff = this.customerHolidayFormGroup.value.weekOff + ',' + WeekDay.toString();
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  get componentName(): string {
    return "CustomerHolidayAddComponent";
  }
}
