import { TemplateConfig } from '@rxweb/grid';
import { CustomerRoleTypeEnum } from 'src/app/enums/customer-role-type.enum';
import { StatusEnum } from 'src/app/enums/status.enum';
export const BIND_RADIO_GRID_TEMPLATE: TemplateConfig = {
    div: {
      childrens: [
        {
          label: {
            childrens: [
              {
                input:
                {
                  class: ["form-check-input", "checkbox-custom-style", "iradio_square-green","disabled"],
                  style: {
                    "margin-top": "4px",
                    "position": "relative",
                    "margin-left": "auto",
                  },

                  event: {
                    input: "onRadioSelect"
                  },
                  attributes: {
                    id: function(e) {
                      return "duplicate-merge-checkbox-"+ this.userId;
                    },
                    type: "radio",
                    value: function (e) {
                      //var a = this[e.name];
                      return this[e.name];
                    },
                    checked: function (e) {
                      // var a = this[e.name];
                      return this[e.name];
                    },
                  }
                }
              }
            ]
          }
        }
      ]
    },
  }

export const BIND_RADIO_Employment_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style", "iradio_square-green", "disabled"],
                style: {
                  "margin-top": "4px",
                  "position": "relative",
                  "margin-left": "auto",
                },

                event: {
                  input: "onRadioSelect"
                },
                attributes: {
                  id: function (e) {
                    return "primary-employement-radio-" + this.companyCustomerMappingId;
                  },
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  },
                  disabled: function (e) {
                    return (
                      this.statusId == StatusEnum.Inactive || this.ownerMappingId > 0
                      || this.isDirector == true
                      || (this.endDate != null && new Date(this.endDate) < new Date(new Date().setHours(0,0,0,0)))
                    );
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_RADIO_EMPLOYMENT_TABLE_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style", "iradio_square-green", "disabled"],
                style: {
                  "margin-top": "4px",
                  "position": "relative",
                  "margin-left": "auto",
                },

                event: {
                  input: "onRadioSelect"
                },
                attributes: {
                  id: function (e) {
                    return "primary-employement-radio-" + this.companyCustomerMappingId;
                  },
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  },
                  disabled: function (e) {
                    return (
                      this.statusId == StatusEnum.Inactive || this.ownerMappingId > 0
                      || (this.endDate != null && new Date(this.endDate) < new Date(new Date().setHours(0,0,0,0)))
                      //  || (this.roleTypeId == CustomerRoleTypeEnum.Admin ? this.hasDirectorship == true : this.isDirector == true)
                      || (this.isDirector==true && this.hasDirectorship == true )
                    );
                  },
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const BIND_SINGLE_RADIO_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => { 
      if(x.isRestricted==true && !x.isRestrictedAccess){
        return false;
      }
      else{
        return true;
      }
  },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style", "iradio_square-green","disabled"],
                style: {
                  "margin-top": "4px",
                  "position": "relative",
                  "margin-left": "auto",
                },

                event: {
                  input: "onRadioSelect"
                },
                attributes: {
                  id: function(e) {
                    return "order-project-event-checkbox-"+ this.id;
                  },
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const BIND_MOVE_NOTE_DOC_TARGET_RADIO_GRID_TEMPLATE: TemplateConfig = {
  div: {
    isBind: (x) => { 
      if(x.isAllowMoveNoteDocument ==true){
        return true;
      }
      else{
        return false;
      }
  },
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-check-input", "checkbox-custom-style", "iradio_square-green","disabled"],
                style: {
                  "margin-top": "4px",
                  "position": "relative",
                  "margin-left": "auto",
                },

                event: {
                  input: "onRadioSelect"
                },
                attributes: {
                  id: function(e) {
                    return "order-project-event-checkbox-"+ this.id;
                  },
                  type: "radio",
                  value: function (e) {
                    return this[e.name];
                  },
                  checked: function (e) {
                    return this[e.name];
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}