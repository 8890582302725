import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTicketAutoGeneratedNotesTypeLookUpBase {

//#region ticketAutoGeneratedNotesTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'ticketAutoGeneratedNotesTypeId', keyColumn: true})
        ticketAutoGeneratedNotesTypeId : number;
//#endregion ticketAutoGeneratedNotesTypeId Prop


//#region ticketAutoGeneratedNotesTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'ticketAutoGeneratedNotesTypeName', keyColumn: false})
        ticketAutoGeneratedNotesTypeName : string;
//#endregion ticketAutoGeneratedNotesTypeName Prop

}