import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';
import {  vEmailAccount } from '@app/models';
import { vEmailAccountLookUp } from '@app/models';
import { vLanguageLookUp } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { MessageTemplate } from '@app/models';
import { FormArray } from '@angular/forms';

@http({
    path: "api/MessageTemplates",
})
export class AbstractMessageTemplate extends CoreComponent {
    messageTemplateFormGroup: IFormGroup<MessageTemplate>
    showComponent: boolean = false;
    modalView: ModalView;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    validField: string[] = ["name","emailAccountId","subject","bccEmailAddresses","body"];
    breakLoop: boolean;
    messageTemplateLookup: any = {
        emailAccountLookup: vEmailAccountLookUp,
        languageLookup: vLanguageLookUp,
        messageTemplate: MessageTemplate
    }
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
        
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }

    get messageTemplateDetails(): FormArray {
        let appointmentCustomeFormArray = this.messageTemplateFormGroup.controls["messageTemplateDetails"] as IAbstractControl;
        let data = <FormArray>appointmentCustomeFormArray["controls"]
        return data;
     //   return this.messageTemplateFormGroup ? this.messageTemplateFormGroup.controls.messageTemplateDetails : [];
    }


}
