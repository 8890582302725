import {vActivityLogBase,} from '@app/database-models'
//Generated Imports
import { actionColumn } from '@rxweb/grid';
@actionColumn({
    visible:false,
    name:"action",
    allowSorting : false,
    style: { width: "2%" },
    configuredTemplate: { templateName: 'deleteAction' }, columnIndex: 10, headerTitle: "Action"
})
export class vActivityLog extends vActivityLogBase 
{


    activityLogIds: number[];

//#region Generated Reference Properties

//#endregion Generated Reference Properties











}
