import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCollectionInspirationRecordBase {

//#region collectionInspirationId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'collectionInspirationId', keyColumn: true})
        collectionInspirationId : number;
//#endregion collectionInspirationId Prop


//#region collectionId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'collectionId', keyColumn: false})
        collectionId : number;
//#endregion collectionId Prop


//#region displayOrderId Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'displayOrderId', keyColumn: false})
        displayOrderId : any;
//#endregion displayOrderId Prop


//#region imageAlt Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'imageAlt', keyColumn: false})
        imageAlt : string;
//#endregion imageAlt Prop


//#region imageUrlName Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'imageUrlName', keyColumn: false})
        imageUrlName : string;
//#endregion imageUrlName Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop

}