import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vDepartmentBase {

        //#region departmentId Prop
        @gridColumn({ style: { width: "10%", "text-align": "initial" },class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'DepartmentListComponent_Id_gh', keyColumn: true })
        departmentId: number;
        //#endregion departmentId Prop


        //#region departmentName Prop
        @gridColumn({ isAscending: true, style: { width: "25%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, allowSorting: true, headerKey: 'DepartmentListComponent_Name_gh', keyColumn: false })
        departmentName: string;
        //#endregion departmentName Prop


        //#region peopleType Prop
        @gridColumn({
                style: { width: "25%", "text-align": "initial" },
                visible: true, columnIndex: 2, allowSorting: true, headerKey: 'DepartmentListComponent_Type_gh', keyColumn: false, class: ["text-left"],
                configuredTemplate: { templateName: "peopleType" }
        })

        peopleType: number;
        //#endregion peopleType Prop

}