import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { access } from '@rxweb/angular-router';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { AbstractFileExtention } from '../domain/abstract-file-extentions';
import { Router, ActivatedRoute } from '@angular/router';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { Title } from '@angular/platform-browser';
import { FileExtention } from 'src/app/models/extended-models/file-extention';
import * as CryptoJS from 'crypto-js';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { getSystemHelpStatus, getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from 'src/app/enums/system-help-page.enum';
import { multilingual } from '@rxweb/localization';
import { SystemHelpViewComponent } from '../../system-help/view/system-help-view.component';
import { PlatformLocation } from '@angular/common';


@multilingual("FileExtentionEditComponent")
@access({ accessLevel: RolePermissionEnums.FileExtentions, action: "put" })
@Component({
  selector: 'app-file-extentions-edit',
  templateUrl: './file-extentions-edit.component.html',
  styleUrls: ['./file-extentions-edit.component.css']
})
export class FileExtentionEditComponent extends AbstractFileExtention implements OnInit {
  id: number;
  moduleID: number;
  fileExtention: FileExtention;
  fileExtentionDelete: any = dbAccessModule[RolePermissionEnums.FileExtentions]["delete"];
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  showSystemHelpIcon: boolean = false;
  titleHelp: string;


  _this;
  constructor(private router: Router,private formBuilder: RxFormBuilder,private activatedRoute: ActivatedRoute, private el: ElementRef, private applicationBroadcaster:ApplicationBroadcaster,modelView: ModalView,private title: Title, private location : PlatformLocation) {
    super();
    this.modalView = modelView;
    this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
    this.moduleID = RolePermissionEnums.FileExtentions;
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })
    this._this = this;
    location.onPopState(() => this.dialog.hide());
   }

  ngOnInit() {
      this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
      this.title.setTitle("File Extentions - G-Trac Admin");
      this.titleHelp = getLocalizedValue("Title_Help");
      this.decryptstring = localStorage.getItem('AllLocalizedMessages');
      this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
      this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
      this.lookup([{params:[this.id],propName: 'fileExtentionList'}]).subscribe((lookup:any)=>{
          //let data = lookup.fileExtentionList;
          if (lookup.fileExtentionList) {
            this.fileExtention=lookup.fileExtentionList;
            // this.productCategoryLookUp=new Array<vProductCategoryLookUp>();
            // this.productCategoryLookUp=lookup.productCategoryLookup;
            this.fileExtentionFormGroup = this.formBuilder.formGroup(FileExtention, this.fileExtention) as IFormGroup<FileExtention>;
            this.showComponent = true;
        }
        else {
            this.router.navigate(['/file-extentions']);
        }
      })
  }
  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
  }


  editFileExtention(isRedirect) {
    this.fileExtentionFormGroup.submitted = true;
    if (this.fileExtentionFormGroup.valid) {
        this.spin=true;
        this.put({ body: this.fileExtentionFormGroup.value, params: [this.id] }).subscribe(data => {
            this.spin=false;
            var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                if (existsAlert) {
                    //this.toastr.success("Data Updated Successfully")
                    this.toastr.success(existsAlert[0].showMessage);
                }
            if(isRedirect){
                this.showComponent=false;
                setTimeout(() => {
                    this.ngOnInit()
                }, 400);
            }
            else{
                this.router.navigate(['/file-extentions']);
            }
        })
    }else{
        for (const key of Object.keys(this.fileExtentionFormGroup.controls)) {
            if (this.fileExtentionFormGroup.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
              invalidControl.focus();
              break;
           }
        }
    }
}
deleteFileExtention() {
  this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
  if(t === DialogEnum.Ok)
  {
            this.dialog.hide();
            this.spin=true;
            this.delete({ body: null, params: [this.id] }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                if (existsAlert) {
      //this.toastr.success("Data Deleted Successfully")
                    this.toastr.success(existsAlert[0].showMessage);
                }
                this.router.navigate(['/file-extentions']);
            })
        }
        this.dialog.hide()
    })
  }
DeleteConfirmationMessage() {
  var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_File_Extension");
  if (existsAlert) {
      return existsAlert[0].showMessage;
  }
}

get componentName(): string {
  return "FileExtentionEditComponent";
}

}
