import { vBoutiquesForWishlistLookUpBase } from '@app/database-models';

//Generated Imports
export class vBoutiquesForWishlistLookUp extends vBoutiquesForWishlistLookUpBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}