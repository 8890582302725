import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, RxFormBuilder } from "@rxweb/reactive-form-validators";
import { Document, Picture } from "@app/models";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { BaseDialog, DialogEnum } from "src/app/domain/customize-design/dialog";
import { ModalView } from "src/app/domain/customize-design/modal";
import { CoreComponent } from "@rxweb/angular-router";
import { FileInputViewModel } from "../../../../view-model/file-input-view-model";
import { FileOutputViewModel } from "../../../../view-model/file-output-view-model";
import {
  FileTypeEnums,
  FileSizeEnums,
  FileTypeValiationEnums,
} from "../../../../custom-enum/file-type-enums";
import { FormArray, FormBuilder } from "@angular/forms";
import { Input, EventEmitter, Directive } from "@angular/core";
import { DocumentListViewModel } from "../../../../models/extended-models/document-list-view-model";
import { vDocuments } from "../../../../models/extended-models/v-document";
import { Subscription } from "rxjs";
import { AppGrid } from "../../../../domain/app-grid";
import { List } from "@rxweb/generics";
import { DocumentListModel } from "../../../../models/extended-models/DocumentListModel";
import { DatePipe, PlatformLocation } from "@angular/common";
import { DialogViewMode } from "@rxweb/js";
import { Router } from "@angular/router";
import { $ } from "protractor";
import { NotesEnum } from "src/app/enums/notes.enum";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import * as CryptoJS from "crypto-js";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";
import { vFileExtention } from "src/app/models/extended-models/v-file-extention";
import { GridColumnConfig } from "@rxweb/grid";
import { v4 as uuidv4 } from "uuid";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { BedRequestComponent } from "src/app/components/shared/global/bed-request/bed-request.component";
import { HttpClient } from "@angular/common/http";
import { BLOB_BASE } from "src/app/domain/system-constant";

@Directive()
@http({
  path: "api/Document",
})
export class AbstractNoteDocument extends CoreComponent {
  isShowGridColumn: boolean = false;
  documentFormGroup: IFormGroup<Document>;
  documentUpdateFormGroup: IFormGroup<Document>;
  showComponent: boolean = false;
  spin: boolean = false;
  baseToastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  fileViewModel: FileInputViewModel;
  @Input() hide: Function;
  @Input() entityTypeId: number;
  @Input() entityId: number;
  @Input() moduleName: string;
  @Input() isPicture: boolean;
  @Input() isEditorDocument: boolean;
  @Input() isNoteComponent: boolean;
  @Input() noteDraftId: number;
  id: number = 0;
  document: List<Document>;
  documnentList: vDocuments[] = [];
  subscription: any;
  documentListGrid: AppGrid;
  documentListModel: DocumentListModel[];
  datePipe: DatePipe;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  isValidate: boolean = false;
  isArrayCount: number = 0;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  pictureUpdate: Document;
  isFilter: boolean = false;
  totalRecords: number = 0;
  json: any;
  isShowSearch: boolean = false;
  extentionList: vFileExtention[];
  titleGridColumnSetting: string;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<
    GridColumnConfig[]
  >();
  getColumnsData: any;
  arrConfig: any[];
  @Input() moduleID: any;
  @Input() selectedTab: string = "";

  constructor(
    private browserStorage: BrowserStorage,
    private blobState: BlobUploadsViewStateService,
    private httpClient: HttpClient,
    private location : PlatformLocation
  ) {
    super();
    this.baseToastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.fileViewModel = new FileInputViewModel();
    this.fileViewModel.fileMode = FileTypeEnums.All;
    this.fileViewModel.fileAllowSize = FileSizeEnums.MaxMB;
    this.fileViewModel.isMultipart = true;
    //this.datePipe = new DatePipe();
    //setTimeout(() => {
      this.get({ path: "api/FileExtentions" }).subscribe((response: any) => {
        this.extentionList = response;
      });
    //}, 1000);
    location.onPopState(() => this.dialog.hide());
  }

  onFileChange(data: File[], formBuilder: RxFormBuilder): void {
    let imgFileExtentionList = this.extentionList
      .filter((x) => x.fileType == FileTypeEnums.Image)
      .map((x) => x.fileExtentionName)
      .join(",");
    let videoExtentionList = this.extentionList
      .filter((x) => x.fileType == FileTypeEnums.Video)
      .map((x) => x.fileExtentionName)
      .join(",");
    let docExtentionList = this.extentionList
      .filter((x) => x.fileType == FileTypeEnums.Doc)
      .map((x) => x.fileExtentionName)
      .join(",");
    let validationMessage = '';

    var docCount = data.length;
    if (this.documnentList){
      docCount = docCount + this.documnentList.length;
    }

    if (docCount > 25){
      validationMessage = "Maximum 25 attachments are allowaed at once.";
      this.isValidate = false;
      console.log(validationMessage);
      this.baseToastr.error(validationMessage);
    }
    else{
      data.forEach((dataArray) => {
        var file = dataArray;
        var _size = file.size;
        var fSExt = new Array("Bytes", "KB", "MB", "GB"),
          i = 0;
        while (_size > 900) {
          _size /= 1024;
          i++;
        }
        var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
        if (file.size / 1024 > this.fileViewModel.fileAllowSize * 1024) {
          this.baseToastr.error(
            "File Size should be " + this.fileViewModel.fileAllowSize + "MB"
          );
          this.isValidate = false;
          return;
        }

        let filename = file.name;
        let extension = filename
          .substr(filename.lastIndexOf(".") + 1)
          .toLowerCase();
        if (this.isPicture) {
          if (
            this.entityTypeId == NotesEnum.ProjectNotes ||
            this.entityTypeId == NotesEnum.EventNotes ||
            this.entityTypeId == NotesEnum.CorporateGroupsNotes ||
            this.entityTypeId == NotesEnum.IncidentAccidentNearDashMissNotes ||
            this.entityTypeId == NotesEnum.CustomerNotes
          ) {
            let split_str = imgFileExtentionList + "," + videoExtentionList;
            let extentionArray = split_str.split(',');
            let checkExtension = extentionArray.find(x => x == '.'+extension)
            if (checkExtension == null) {
              validationMessage =
                "Only following extensions are allowed:" +
                split_str;
              this.isValidate = false;
              this.isArrayCount = 0;
            } else {
              this.isValidate = true;
            }
          } else {
            let split_str = imgFileExtentionList;
            let extentionArray = split_str.split(',');
            let checkExtension = extentionArray.find(x => x == '.'+extension)
            if (checkExtension == null) {
              validationMessage =
                "Only following extensions are allowed:" +
                split_str;
              this.isValidate = false;
              this.isArrayCount = 0;
            } else {
              this.isValidate = true;
            }
          }
        } else {
          let split_str =
            docExtentionList +
            "," +
            imgFileExtentionList +
            "," +
            videoExtentionList +
            ",";
          let extentionArray = split_str.split(',');
          let checkExtension = extentionArray.find(x => x == '.'+extension)
          if (checkExtension == null) {
            validationMessage =
              "Only following extensions are allowed:" +
              split_str;
            this.isValidate = false;
            this.isArrayCount = 0;
          } else {
            this.isValidate = true;
          }
        }

        if (this.isValidate) {
          let documentArray = this.documentFormGroup.get(
            "documents"
          ) as FormArray;
          let document = new DocumentListViewModel();
          document.createdBy = 1;
          document.documentId = 0;
          document.isPicture = this.isPicture;
          document.moduleName = this.moduleName;
          document.seeOnline = false;
          document.entityId = this.id;
          document.entityTypeId = this.entityTypeId;
          document.file = file;
          document.fileName = file.name;
          document.size = file.size;
          documentArray.push(
            formBuilder.formGroup(document) as IFormGroup<FormArray>
          );
          this.isArrayCount++;
        } else {
          this.baseToastr.error(validationMessage);
        }
      });

      if (this.isArrayCount > 0) {
        if (this.isNoteComponent) {
          this.documentFormGroup.value.isNoteComponent = true;
          this.documentFormGroup.value.noteId = this.noteDraftId;
          this.documentFormGroup.value.entityId = this.entityId;
          this.id = this.entityId;
        }
        // CURRENTLY ONLY CHANGING LOGIC FOR Documents TAB
        if (
          this.selectedTab == "document" ||
          this.selectedTab == "documents" ||
          this.selectedTab == "document-files"
        ) {
          this.uploadDocumentFromDocumentsTab(formBuilder);
        } else {
          this.uploadDocument(formBuilder);
        }
      }
    }
  }

  bindGrid(isOnload: boolean = true) {
    this.spin = true;
    this.json["userId"] = 0;
    if (this.json["orderBy"] == "documentId") {
      this.json["orderBy"] = "createdOn";
    }
    this.isShowGrid = true;
    this.subscription = this.get({
      queryParams: {
        entityId: this.id,
        entityTypeId: this.entityTypeId,
        isPicture: this.isPicture,
        isNoteDocumentComponent: true,
        query: encodeURIComponent(JSON.stringify(this.json)),
      },
    }).subscribe((t: any) => {
      this.spin = false;
      this.documnentList = t;
      this.documentListModel = t;

      if (this.documentListModel != null) {
        this.documentListModel.forEach((x) => {
          if (x.isVideo == "false") {
            x.type = "image/*";
          } else {
            x.type = "video/*";
          }
        });
      }
      //<-----For No Record Found Start------>
      if (this.documentListModel.length > 0) {
        this.totalRecords = this.documentListModel[0].totalCount;
        this.isShowGrid = true;
        this.isNoRecordFound = false;
        if (isOnload && this.totalRecords > 0) {
          this.isShowSearch = true;
        }
      } else {
        this.isNoRecordFound = true;
        this.isShowGrid = false;
      }
      //<-----For No Record Found End------>
      if (!this.isPicture) {
        if (!this.isFilter) {
          this.documentListGrid = new AppGrid(
            this.documnentList,
            DocumentListModel,
            {
              actions: {
                onOneLink: this.onDocumentDownloadLink.bind(this),
                onDelete: this.onDelete.bind(this),
              },
            }
          );
          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach((x) => {
              this.documentListGrid.gridColumns.forEach((y) => {
                if (y.name == x.ColumnName) y.visible = x.Visible;
              });
            });
            this.gridColumnConfig = this.documentListGrid.gridColumns;
            this.gridColumnSettings.emit(this.gridColumnConfig);
          }
          this.documentListGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this),
          };
          if (this.isPicture) {
            this.documentListGrid.gridColumns.forEach((data) => {
              if (data.columnIndex == 4 || data.columnIndex == 5) {
                data.visible = false;
              }
            });
          }
        } else {
          this.documentListGrid.storeProcedure.length = this.totalRecords;
          this.documentListGrid.updateSource([]);
          this.documentListGrid.updateSource(this.documnentList);

          // if (this.documentListGrid != null) {
          //   this.documentListGrid.storeProcedure.length = this.totalRecords;
          //   if (this.json["pageIndex"] == 1) {
          //     this.documentListGrid.storeProcedure.nextPage = 1;
          //   }
          //   this.documentListGrid.updateSource([]);
          //   this.documentListGrid.updateSource(this.documnentList);
          // }
        }
        this.documentListGrid.maxPerPage = this.json["rowCount"];
        this.documentListGrid.componentId = "NoteDocumentComponent";
        this.isFilter = true;
      }
      // setTimeout(function () {
      //   this.showComponent = true;
      // }, 1000)
    });
  }
  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }
  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.documentListGrid.reDesign();
    this.documentListGrid.updateSource([]);
    this.documentListGrid.updateSource(this.documnentList);
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = y;
    this.json["orderBy"] = x;
    this.documentListGrid.storeProcedure.nextPage = z;
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.documentListGrid.maxPerPage;
    this.documentListGrid.storeProcedure.nextPage = x;
    this.bindGrid(false);
  }

  bindFormGroup(): Document {
    let document = new Document();
    document.createdBy = 1;
    document.documentId = 0;
    document.entityId = this.id;
    document.entityTypeId = this.entityTypeId;
    document.fileName = "test";
    document.isPicture = this.isPicture;
    document.documents = new Array<DocumentListViewModel>();
    document.size = 0;
    document.fileNamesCollection = new Array<{
      fileName: string;
      blobFileName: string;
      size:number;
    }>();
    return document;
  }

  onDocumentDownloadLink(userProject: DocumentListModel) {
    let documentData = new Document();
    documentData.documentId = userProject.documentId;
    documentData.entityId = this.entityId;
    documentData.entityTypeId = this.entityTypeId;
    documentData.createdBy = 2;
    documentData.fileName = userProject.fileName;
    documentData.seeOnline = false;
    this.documentFormGroup.patchValue({
      documentId: userProject.documentId,
      noteId: userProject.noteId,
    });
    let documentArray = this.documentFormGroup.get("documents") as FormArray;
    this.documentFormGroup.value.documents = [];
    documentArray.controls = [];
    this.put({
      body: this.documentFormGroup.toFormData(),
      params: [this.id],
    }).subscribe((data) => {});
    window.open(`/download-content?did=${userProject.documentId}&t=${'document'}`, "_blank");
  }

  rebindData() {}

  async uploadDocumentFromDocumentsTab(formBuilder: RxFormBuilder) {
    this.documentFormGroup.submitted = true;
    if (
      this.documentFormGroup.valid &&
      this.isArrayCount > 0 &&
      this.isValidate
    ) {
      let data = this.documentFormGroup.value;
      this.spin = true;

      let arrayOfPromises = [];
      let allfileNames = [];
      for (let i = 0; i < this.documentFormGroup.value.documents.length; i++) {
        const document = this.documentFormGroup.value.documents[i];

        let newGUID = uuidv4();
        let fileName = `${newGUID}_${new Date().toISOString()}.${document.fileName
          .split(".")
          .pop()}`;
        allfileNames.push({
          blobFileName: fileName,
          fileName: document.fileName,
          size:document.file.size
        });
        arrayOfPromises.push(
          this.blobState.uploadItems(document.file, fileName)
        );
      }

      let combinedRes = await Promise.all(arrayOfPromises);

      let finishedFileUploads = [];
      let erroredFileUploads = [];
      combinedRes.forEach((res) => {
        let url = res._response.request.url;
        let uploadedFileName = decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
        let fileNameObj = allfileNames.find(
          (x) => x.blobFileName == uploadedFileName
        );
        // IF SUCCEED TO UPLOAD
        if (
          res &&
          (res._response.status == 200 || res._response.status == 201)
        ) {
          if (fileNameObj != undefined) finishedFileUploads.push(fileNameObj);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.baseToastr.error(
            "Error occured while uploading files! Please try again."
          );
          if (fileNameObj != undefined) erroredFileUploads.push(fileNameObj);
        }
      });

      let documentArray = this.documentFormGroup.get("documents") as FormArray;
      this.documentFormGroup.value.documents = [];
      documentArray.controls = [];

      let fileNamesFormArray = this.documentFormGroup.get(
        "fileNamesCollection"
      ) as FormArray;
      fileNamesFormArray.controls = finishedFileUploads.map((fileNameObj) => {
        return formBuilder.group(fileNameObj);
      });
      this.documentFormGroup.value.fileNamesCollection = finishedFileUploads;

      if (finishedFileUploads.length > 0) {
        this.post({ body: this.documentFormGroup.toFormData() }).subscribe(
          (data) => {
            this.spin = false;
            let documentArray = this.documentFormGroup.get(
              "documents"
            ) as FormArray;
            this.documentFormGroup.value.documents = [];
            documentArray.controls = [];

            var existsAlert = getLocalizedValue("Data_Added");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            if (
              this.documentListGrid != null &&
              this.documentListGrid != undefined
            ) {
              this.documentListGrid.updateSource([]);
              this.isFilter = true;
              this.json["pageIndex"] = 1;
            }
            this.bindGrid();
            this.handleFailedUploadDocumentsPopup(erroredFileUploads);
          }
        );
      } else {
        this.handleFailedUploadDocumentsPopup(erroredFileUploads);
        this.spin = false;
      }
    }
  }

  private handleFailedUploadDocumentsPopup(erroredFileUploads) {
    if (erroredFileUploads.length > 0) {
      this.modalView
        .show(BedRequestComponent, {
          validationMessages: JSON.stringify(
            erroredFileUploads.map((x) => x.fileName)
          ),
          headerMessage: "Following files are failed to upload:",
        })
        .then(() => {});
    }
  }

  async uploadDocument(formBuilder: RxFormBuilder) {
    this.documentFormGroup.submitted = true;
    if (
      this.documentFormGroup.valid &&
      this.isArrayCount > 0 &&
      this.isValidate
    ) {
      let data = this.documentFormGroup.value;
      this.spin = true;

      let arrayOfPromises = [];
      let allfileNames = [];
      for (let i = 0; i < this.documentFormGroup.value.documents.length; i++) {
        const document = this.documentFormGroup.value.documents[i];

        let newGUID = uuidv4();
        let fileName = BLOB_BASE.DocumentFiles+`${newGUID}_${new Date().toISOString()}.${document.fileName
          .split(".")
          .pop()}`;
         // this.documentFormGroup.value.documents[i].blobPath=BLOB_BASE.DocumentFiles
        allfileNames.push({
          
          blobFileNameBeforeUpdate:fileName,
          blobPath:BLOB_BASE.DocumentFiles,
          size:document.file.size,
          fileName:document.file.name

        });
        arrayOfPromises.push(
          this.blobState.uploadItems(document.file, fileName)
        );
      }

      let combinedRes = await Promise.all(arrayOfPromises);

      let finishedFileUploads = [];
      let erroredFileUploads = [];
      combinedRes.forEach((res) => {
        let url = res._response.request.url;
        let uploadedFileName = BLOB_BASE.DocumentFiles+decodeURIComponent(
          url.split("?")[0].split("/").pop()
        );
        let fileNameObj = allfileNames.find(
          (x) => x.blobFileNameBeforeUpdate == uploadedFileName
        );
        // IF SUCCEED TO UPLOAD
        if (
          res &&
          (res._response.status == 200 || res._response.status == 201)
        ) {
          if (fileNameObj != undefined) finishedFileUploads.push(fileNameObj);
        } else {
          // IF FAILED TO UPLOAD
          console.log("Failed to upload file to blob from client!", res);
          this.baseToastr.error(
            "Error occured while uploading files! Please try again."
          );
          if (fileNameObj != undefined) erroredFileUploads.push(fileNameObj);
        }
      });

      let documentArray = this.documentFormGroup.get("documents") as FormArray;
      this.documentFormGroup.value.documents = [];
      documentArray.controls = [];

      let documentsFormArray = this.documentFormGroup.get("documents") as FormArray;
      documentsFormArray.controls = finishedFileUploads.map((fileNameObj) => {
        return formBuilder.group(fileNameObj);
      });
      this.documentFormGroup.value.documents = finishedFileUploads;

      this.documentFormGroup.value.documents.forEach(element => {
        element.createdBy = 1,
        element.documentId =0
        element.isPicture = this.isPicture;
        element.moduleName = this.moduleName;
        element.seeOnline = false;
        element.entityId = this.id;
        element.entityTypeId = this.entityTypeId;
        
      });
      if (finishedFileUploads.length > 0) {
        this.post({ body: this.documentFormGroup.toFormData() }).subscribe(
          (data) => {
            this.spin = false;
            let documentArray = this.documentFormGroup.get(
              "documents"
            ) as FormArray;
            this.documentFormGroup.value.documents = [];
            documentArray.controls = [];

            //this.baseToastr.success("Data added successfully");
            // var existsAlert = getLocalizedValue("Data_Added");
            // if (existsAlert) {
            //   this.baseToastr.success(existsAlert);
            // }
            // if (!this.isPicture) {
            //   if(this.documentListGrid!=null){
            //     this.documentListGrid.refresh("document");
            //   }

            // }
            if (
              this.documentListGrid != null &&
              this.documentListGrid != undefined
            ) {
              this.documentListGrid.updateSource([]);
              this.isFilter = true;
              this.json["pageIndex"] = 1;
              //this.documentListGrid.refresh("documentListGrid");
            }
            this.bindGrid();
          }
        );
      }
      else {
        this.handleFailedUploadDocumentsPopup(erroredFileUploads);
        this.spin = false;
      } 
    }
  }

  onDelete(userProject: DocumentListModel) {
    this.dialog
      .show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel)
      .then((t) => {
        if (t === DialogEnum.Ok) {
          this.dialog.hide();
          this.spin = true;
          this.delete({
            params: [userProject.documentId],
            body: null,
            queryParams: {
              isPicture: this.isPicture,
              entityId: this.id,
              entityTypeId: this.entityTypeId,
              flag: false,
              isNoteDocumentComponent: true,
            },
          }).subscribe((data) => {
            //this.baseToastr.success("Data deleted successfully");
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              this.baseToastr.success(existsAlert);
            }
            this.spin = false;
            if (!this.isPicture) {
              //this.documentListGrid.remove(userProject.documentId);
              this.documentListGrid.updateSource([]);
              this.isShowSearch = false;
              this.documentListGrid.storeProcedure.nextPage = 1;
              this.json["pageIndex"] = 1;
              this.bindGrid();
            } else {
              this.documentListModel.splice(
                this.documentListModel.findIndex(
                  (x) => x.documentId == userProject.documentId
                ),
                1
              );
            }
            if (this.documentListModel.length <= 0) {
              this.isNoRecordFound = true;
              this.isShowGrid = false;
            }
          });
        } else {
          this.dialog.hide();
        }
      });
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Note_Document");
    if (existsAlert) {
      return existsAlert;
    }
  }
}
