import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vIndustryTypeRecordBase {

//#region industryTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'industryTypeId', keyColumn: true})
        industryTypeId : number;
//#endregion industryTypeId Prop


//#region statusId Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'statusId', keyColumn: false})
        statusId : number;
//#endregion statusId Prop


//#region industryTypeName Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'industryTypeName', keyColumn: false})
        industryTypeName : string;
//#endregion industryTypeName Prop

}