<!-- <div class="wrapper wrapper-content"  [rxSpinner]="spin"> -->

<div class="ibox mb-0 d-flex flex-column mt-5" [rxLocalisationInit]="componentName" [rxSpinner]="spin" *rxMultilingual="">
  <!-- <div class="ibox-tools mt-4 mr-4">
    <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
      <i class="fa fa-cog"></i>
    </a>
    <app-system-table-setting [componentId]="'EventCustomerMappingListComponent'" *ngIf="isShowGridColumn"
      (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
      [gridColumnConfig]="eventInviteeGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID"
      [selectedTab]="selectedTab"></app-system-table-setting>
  </div> -->
  <div class="ibox-content bg-white d-flex flex-column flex-grow-1" id="eventCustomerTable">

    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
      <button class="btn btn-success mb-3 mr-3" (click)="addPersonOrCorporate(0)"
        rxText="EventCustomerMappingListComponent_Add_Person_t"></button>
      <button class="btn btn-success mb-3 mr-3" (click)="addPersonOrCorporate(1)"
        rxText="EventCustomerMappingListComponent_Add_Corporate_t"></button>
      <button class="btn btn-success mb-3" (click)="importInviteesAttendees()"
        rxText="EventCustomerMappingListComponent_Import_Invitee_t"></button>
      <label class="mr-sm-3 ml-sm-3"><span
          rxText="EventCustomerMappingListComponent_Total_Added_t"></span>{{total}}</label>
      <label class="mr-sm-3"><span rxText="EventCustomerMappingListComponent_Invited_t"></span>{{inviteesCount}}</label>
      <label class="mr-sm-3"><span
          rxText="EventCustomerMappingListComponent_Accepted_t"></span>{{acceptedCount}}</label>
      <label class="mr-sm-3"> <span
          rxText="EventCustomerMappingListComponent_Declined_t"></span>{{declinedCount}}</label>
      <label class="mr-sm-3"> <span
          rxText="EventCustomerMappingListComponent_Attended_t"></span>{{attendedCount}}</label>
      <label class="mr-sm-3"><span
          rxText="EventCustomerMappingListComponent_E_Invitation_t"></span>{{eInviteCount}}</label>
      <label class="mr-sm-3"><span
          rxText="EventCustomerMappingListComponent_Involved_t"></span>{{involvedCount}}</label>
    </div>

    <div class="dataTables_filter  d-flex flex-wrap align-items-center flex-row-reverse">
      <div class="ml-auto mb-3 d-flex flex-wrap flex-row-reverse">
        <ng-container [rxLocalisationInit]="componentName" *ngIf="!isNoRecordFound" >
          <button type="button" class="btn btn-success ml-3 mb-2" (click)="exportToExcel()"
            rxText="Btn_Export_to_Excel_t" ></button>
        </ng-container>
        <button type="button" class="btn btn-success ml-3 mb-2" (click)="update()"
          [disabled]="updateSelectedInvitees.length==0 && selectedInvitees.length==0" rxText="Btn_Update_t"></button>
        <button type="button" class="btn btn-primary ml-3 mb-2" (click)="onKeyPress()"
          rxText="Btn_Show_Only_t" ></button>
        <div [formGroup]="statusFormGroup" class="ml-3 mb-2" *ngIf="showComponent" [rxLocalisationInit]="componentName" >
          <div [rxSelectExtended]="_this">
            <rx-select [(source)]="statusLookup" #rxSelect mainClass="form-control" formControlName="statusId"
              [selectPlaceholder]="'Select Status'" (changed)="eventFilter()"
              [keyValueProps]="['statusName','statusId']"></rx-select>
          </div>
        </div>
      </div>
    </div>
    <div class="dataTables_filter  d-flex flex-wrap align-items-center">
      <label class="ml-auto ibox-tools ibox-position mb-1  d-flex">
        <b>
          <h4><label class="mt-1" rxText="EventCustomerMappingListComponent_Search"></label></h4>
        </b>
        <input (keyup.enter)="search($event)" type="search" class=" table_search form-control form-control-sm pr-4 mr-2 my-0"
          rxPlaceholder="EventCustomerMappingListComponent_Search_p" [title]="titleSearch">
        <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
          <i class="fa fa-cog"></i>
        </a>
        <app-system-table-setting [componentId]="'EventCustomerMappingListComponent'" *ngIf="isShowGridColumn"
          (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
          [gridColumnConfig]="eventInviteeGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID"
          [selectedTab]="selectedTab" [rxLocalisationInit]="componentName" ></app-system-table-setting>
      </label>
    </div>


    <div class="table-responsive mt-3" *ngIf="isShowGrid" [rxLocalisationInit]="componentName" >
      <div data-toggle="buttons" *ngIf="isLongTextExists" [rxLocalisationInit]="componentName"
      class="btn-group btn-group-gray btn-group-toggle mb-1 d-flex flex-wrap justify-content-end">
      <ng-container [rxLocalisationInit]="componentName" *ngIf="!isShowAll" >
        <button class="btn btn-success" (click)="showOrHideAll()"  rxText="Btn_Show_All_t"></button>
      </ng-container>
      <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowAll" >
        <button class="btn btn-success" (click)="showOrHideAll()" rxText="Btn_Hide_All_t"></button>
      </ng-container>
      </div>
      <div class="dataTables_wrapper pb-0 dt-bootstrap4" id="eventInviteeGrid">
        <!-- <rx-grid [design]="eventInviteeGrid"  ></rx-grid> -->
      </div>
    </div>
    <div class="ibox-title bg-white" [ngStyle]="{'display': isNoRecordFound==true?'block':'none'}" id="noRecordDiv">
      <h4>No Record Found</h4>
    </div>

  </div>
</div>

<!-- </div> -->