import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMeasureWeightLookUpBase {

//#region measureWeightId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'measureWeightId', keyColumn: true})
        measureWeightId : number;
//#endregion measureWeightId Prop


//#region measureWeightName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'measureWeightName', keyColumn: false})
        measureWeightName : string;
//#endregion measureWeightName Prop

}