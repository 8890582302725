import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
//import { HelpSupport } from '@app/models';
import { Router } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { HelpSupport } from '@app/models';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { BrowserModule, Title } from '@angular/platform-browser';
import { TINY_MCE_KEY } from '../../../../domain/system-constant';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import * as CryptoJS from 'crypto-js';
import { FormsDocumentBase } from 'src/app/models/database-models/forms-document-base';
import { AbstractFormsDocument } from '../domain/abstract-forms-document';
import { FormsDocument } from 'src/app/models/extended-models/forms-document';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("FormsDocumentAddComponent")
@access({ accessLevel: RolePermissionEnums.FormsDocument, action: "post" })

@Component({
  selector: "app-forms-document-add",
  templateUrl: './forms-document-add.component.html'
})
export class FormsDocumentAddComponent extends AbstractFormsDocument implements OnInit {
  localizedMessages: any;
  spin: boolean;
  formDocument: any;
  toastr: any;
  activatedRoute: any;
  subscription: any;
  FormsdocumentEdit: any = dbAccessModule[RolePermissionEnums.FormsDocument]["put"];
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;

  constructor(private router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title, blobState: BlobUploadsViewStateService) {
    super(blobState);
    this.router = router;
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.FormsDocument;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.titleHelp = getLocalizedValue("Title_Help");
    this.title.setTitle("Forms Document - G-Trac Admin");
    this.formDocument = new FormsDocument();
    this.spin = true;
    this.formDocument.FormId = 0;
    this.formDocument.UserRoleId=1;
    // this.formDocument.languageId = LanguagesEnums.English;
    // this.formDocument.frontEndCategoryPictureId = 0;
    // this.category.statusId = StatusEnum.Active;
    this.showComponent = true;
    this.formsDocumentFormGroup = this.formBuilder.formGroup(this.formDocument) as IFormGroup<FormsDocument>;
    this.spin = false;

    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  async addFormsDocument(isRedirect) {
    this.formDocument.Filename=this.formDocument.fileName1;
    // this.formDocument.userRoleId=0;
    
    this.formsDocumentFormGroup.submitted = true;
    if (this.formsDocumentFormGroup.valid) {
      this.spin = true;
      if (this.formsDocumentFormGroup.value.fileData1) {
                
        let res = await this.uploadToBlob(this.fileOutputViewModelDoc, this.formsDocumentFormGroup.value.blobPath);
        this.formsDocumentFormGroup.patchValue({
            fileData1: null,
            blobFileName: res
        });
    }
      this.post({ body: this.formsDocumentFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          this.toastr.success(existsAlert[0].showMessage);
        }
          // this.toastr.success('added succesfully.');
        // isRedirect ? this.router.navigate(['/forms-document', data]) : this.router.navigate(['/forms-document']);
        isRedirect ? this.router.navigate(['/forms-document', data]) : this.router.navigate(['/forms-document']);
        
      })
    } else {
      for (const key of Object.keys(this.formsDocumentFormGroup.controls)) {
        if (this.formsDocumentFormGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }

  get componentName(): string {
    return "FormsDocumentAddComponent";
  }
}
