import { prop, propObject, propArray, required, maxLength, range, notEmpty, numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BoutiquePictureBase {

    //#region boutiquePictureId Prop
    @prop()
    boutiquePictureId: number;
    //#endregion boutiquePictureId Prop


    //#region boutiqueId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    boutiqueId: number;
    //#endregion boutiqueId Prop


    //#region displayOrder Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    @numeric()
    @notEmpty()
    displayOrder: number;
    //#endregion displayOrder Prop

    //#region displayOrder Prop
    //@range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @prop({defaultValue:0})
    pictureId: number;
    //#endregion displayOrder Prop

    //#region pictureId1 Prop
    @prop({defaultValue:0})
    pictureId1: number;
    //#endregion pictureId1 Prop

}

