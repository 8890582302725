import {vWarehouseLookUpBase,} from '@app/database-models'
//Generated Imports
export class vWarehouseLookUp extends vWarehouseLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}