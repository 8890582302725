import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vLeaveTypeBase {

//#region leaveId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'LeaveTypeListComponent_Id_gh', keyColumn: true})
        leaveId : number;
//#endregion leaveId Prop


//#region leaveTypeName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'LeaveTypeListComponent_TypeName_gh', keyColumn: false})
        leaveTypeName : string;
//#endregion leaveTypeName Prop

}