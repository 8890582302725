import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class CustomerRelationShipWithParentBase {

//#region id Prop
        @prop()
        id : number;
//#endregion id Prop


//#region praentRelationShipId Prop
        @prop()
        praentRelationShipId : number;
//#endregion praentRelationShipId Prop


//#region customerRelationShipId Prop
        @prop()
        customerRelationShipId : number;
//#endregion customerRelationShipId Prop


//#region gender Prop
        @maxLength({value:10})
        gender : string;
//#endregion gender Prop

}