import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { GridColumnConfig } from '@rxweb/grid';
import { RxFormBuilder, IFormGroup, json, date } from '@rxweb/reactive-form-validators';
import { CoreComponent } from '@rxweb/framework';
import { SaveGridColumn } from 'src/app/models/extended-models/save-grid-column';
import { DocumentEditComponent } from '../../document/document/edit/document-edit.component';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-system-table-setting',
  templateUrl: './system-table-setting.component.html',
  styleUrls: ['./system-table-setting.component.css']
})
export class SystemTableSettingComponent extends SaveGridColumn implements OnInit, OnChanges,OnDestroy  {
  gridFormGroup: IFormGroup<GridColumnConfig>
  allChecked: boolean = false;
  isNgOnInit: boolean = true;
  appliedColumns : any[] = [];
  @Input() componentId: string;
  @Input() gridColumnConfig: GridColumnConfig[];
  @Input() isShowGridColumn: boolean = false;
  @Input() moduleID: number=0;
  @Input() isDashboardWidgets: boolean=false;
  @Input() selectedTab: string="";
  @Input() settingTitle: string="";
  @Output() hideGridColumnComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  showComponent: boolean = false;
  savegridData: SaveGridColumn;
  columnsJson=[];
  dropdownStyle :any;
  dropdownHeight : number;
  toastr: BaseToastr;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  constructor(private formBuilder: RxFormBuilder) {
    super();
    this.toastr = new BaseToastr();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
    if(changes.gridColumnConfig != undefined && changes.gridColumnConfig != null) {
      if(!this.isNgOnInit)
        this.ngOnInit();
      else
        this.isNgOnInit = false;
    }
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  ngOnInit() {
    this.gridColumnConfig = this.gridColumnConfig.filter(x => x.keyColumn == false && x.parameter == false);
    this.isAllChecked();
    this.gridColumnConfig.filter(x => x.keyColumn == false && x.parameter == false).forEach(data => {
      if (data.visible) {
        var json = {};
        var columnName = data.name;
        json['ColumnName'] = columnName;
        this.appliedColumns.push(json);
      }
    })
    this.dropdownHeight = (this.appliedColumns.length + 1) * 60;
    this.dropdownHeight = this.dropdownHeight > 400 ? 400 : this.dropdownHeight;
    var l = document.getElementsByTagName('body')[0]
    var lt = document.querySelector('.ibox-tools .dropdown-toggle')
    if(l && lt)
    {
      if(l.getBoundingClientRect().height - lt.getBoundingClientRect().bottom < this.dropdownHeight)
        //this.dropdownStyle = {'top':'auto','bottom':'100%', 'max-height':lt.getBoundingClientRect().top + window.pageYOffset - 10 +'px'}
        this.dropdownStyle = {'top':'auto','bottom':'100%', 'max-height':'400px'}
      else
        this.dropdownStyle = {'top':'30px'}
    }
    else
    {
      this.dropdownStyle = {'top':'30px'}
    }
    this.showComponent = true;
  }

  visibleColumn(columnIndex: number, $event) {
    var item = this.gridColumnConfig.find(x => x.columnIndex == columnIndex);
    if (item)
      item.visible = $event.target.checked;

    this.isAllCheckBoxChecked();
  }

  visibleColumnAll($event) {
    this.gridColumnConfig.filter(x => x.keyColumn == false && x.parameter == false).forEach(data => {
      data.visible = $event.target.checked;
    })
    this.isAllCheckBoxChecked();
  }

  hideComponent() {
    this.isShowGridColumn = !this.isShowGridColumn;
    this.gridColumnConfig.forEach(data=>{data.visible=false});
    this.appliedColumns.forEach(column=>{
      this.gridColumnConfig.forEach(data=>{
        if(data.name == column.ColumnName)
          data.visible=true;
      })

    });
    this.hideGridColumnComponent.emit(true);
  }

  applyAction() {
    let appliedColumnsBKP = this.appliedColumns;
    this.appliedColumns=[];
    let jsonObj;

    if(this.gridColumnConfig)
    {
      if(this.gridColumnConfig.filter(x=>x.visible == true).length < 1)
      {
        if(this.isDashboardWidgets){
          var existsAlert = getLocalizedValue("Setting_Gear_Min_Select");
          if (existsAlert) {
              this.toastr.warning("Please select atleast 1 widget");
          }
        }
        else{
          var existsAlert = getLocalizedValue("Setting_Gear_Min_Select");
          if (existsAlert) {
              this.toastr.warning(existsAlert == "Setting_Gear_Min_Select"  ? existsAlert : "Please select atleast 1 column");
          }
        }
        this.appliedColumns = appliedColumnsBKP;
      }
      else
      {
        this.gridColumnSettings.emit(this.gridColumnConfig)
        this.gridColumnConfig.forEach(column =>
        {
            var jsonData = {};
            var columnName = column.name;
            jsonData['ColumnName'] = columnName;
            jsonData['Visible'] = column.visible ? true: false;
            if(column.visible)
            {
              var json = {};
              json['ColumnName'] = columnName;
              this.appliedColumns.push(json);
            }
            this.columnsJson.push(jsonData);
        });
        jsonObj=JSON.stringify(this.columnsJson);
        this.savegridData= new SaveGridColumn();

        this.savegridData.gridColumns= jsonObj;
        this.savegridData.moduleId=this.moduleID;
        this.savegridData.childTabs=this.selectedTab;
        this.savegridData.userId=0;

        this.post({ path: 'api/SearchCommon/SaveGridColumn', body: this.savegridData}).subscribe(data  => {
        });
        this.hideComponent();
      }
    }
  }
  isAllChecked() {
    let totalColumn = this.gridColumnConfig.length;
    let totalCount = 0;
    this.gridColumnConfig.filter(x => x.keyColumn == false && x.parameter == false).forEach(data => {
      if (data.visible) {
        totalCount++;
      }
    })
    if (totalColumn === totalCount) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  isAllCheckBoxChecked() {
    let totalColumn = this.gridColumnConfig.length;
    let totalCount = 0;
    this.gridColumnConfig.filter(x => x.keyColumn == false && x.parameter == false).forEach(data => {
      if (data.visible) {
        totalCount++;
      }
    })
    if (totalColumn === totalCount) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  ngOnDestroy(): void {
    this.isShowGridColumn = !this.isShowGridColumn;
    this.gridColumnConfig.forEach(data=>{data.visible=false});
    this.appliedColumns.forEach(column=>{
      this.gridColumnConfig.forEach(data=>{
        if(data.name == column.ColumnName)
          data.visible=true;
      })

    });
  }

}
