import {vCollectionDesignRecordBase,} from '@app/database-models'
//Generated Imports
export class vCollectionDesignRecord extends vCollectionDesignRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}