import { TemplateConfig } from '@rxweb/grid';

export const DROPDOWN_GEMFIELD_RISK_LIKELIHOOD: TemplateConfig = {
    select:
    {
        class: ["select-option", "form-control"],
        event: {
            input: "onLikeChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.likelihood == 0) {
                                return this.parentObject.likelihood && this.value == 0;
                            }
                            if (this.parentObject.likelihood == 1) {
                                return this.parentObject.likelihood && this.value == 1;
                            }
                            if (this.parentObject.likelihood == 2) {
                                return this.parentObject.likelihood && this.value == 2;
                            }
                            if (this.parentObject.likelihood == 3) {
                                return this.parentObject.likelihood && this.value == 3;
                            }
                            if (this.parentObject.likelihood == 4) {
                                return this.parentObject.likelihood && this.value == 4;
                            }
                            if (this.parentObject.likelihood == 5) {
                                return this.parentObject.likelihood && this.value == 5;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: LikelihoodDropdown(),
    }
}

export function LikelihoodDropdown() {
    return [{ value: 0, text: "Select Likelihood" }, { value: 1, text: "1" }, { value: 2, text: "2" }, { value: 3, text: "3" }, { value: 4, text: "4" }, { value: 5, text: "5" }];
}


export const DROPDOWN_GEMFIELD_RISK_IMPACT: TemplateConfig = {
    select:
    {
        class: ["select-option", "form-control"],
        event: {
            input: "onImpactChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.impact == 0) {
                                return this.parentObject.impact && this.value == 0;
                            }
                            if (this.parentObject.impact == 1) {
                                return this.parentObject.impact && this.value == 1;
                            }
                            if (this.parentObject.impact == 2) {
                                return this.parentObject.impact && this.value == 2;
                            }
                            if (this.parentObject.impact == 3) {
                                return this.parentObject.impact && this.value == 3;
                            }
                            if (this.parentObject.impact == 4) {
                                return this.parentObject.impact && this.value == 4;
                            }
                            if (this.parentObject.impact == 5) {
                                return this.parentObject.impact && this.value == 5;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: ImpactDropdown(),
    }
}

export function ImpactDropdown() {
    return [{ value: 0, text: "Select Impact" }, { value: 1, text: "1" }, { value: 2, text: "2" }, { value: 3, text: "3" }, { value: 4, text: "4" }, { value: 5, text: "5" }];
}

export const DROPDOWN_GINA_LIT: TemplateConfig = {
    select:
    {
        class: ["select-option", "form-control"],
        event: {
            input: "onChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        isBind: (x) => {
            return x.total != "Total" ? true : false;
       },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.lostTimeInjuryId == 0) {
                                return this.parentObject.lostTimeInjuryId && this.value == 0;
                            }
                            if (this.parentObject.lostTimeInjuryId == 1) {
                                return this.parentObject.lostTimeInjuryId && this.value == 1;
                            }
                            if (this.parentObject.lostTimeInjuryId == 2) {
                                return this.parentObject.lostTimeInjuryId && this.value == 2;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                }
            }
        ],
        sourceItems: DaysDropdown(),
    }
}

export function DaysDropdown() {
    return [ { value: 1, text: "No-Not LTI" },{ value: 2, text: "Yes-LTI" } ];
}

export const DROPDOWN_GINA_LIT_EDIT: TemplateConfig = {
    select:
    {
        class: ["select-option", "form-control"],
        event: {
            input: "onChange"
        },
        style: {
            "margin-top": "-6px",
            "width": "100%"
        },
        attributes: {
            value: function (e) {
                return this.value;
            },
            selected: function (e) {
                var data = this[e.name];
                return data;
            }
        },
        isBind: (x) => {
            return x.total != "Total" && x.isAllowEdit==true ? true : false;
       },
        childrens: [
            {
                option: {
                    attributes: {
                        value: function (e) {
                            return this.value;
                        },
                        selected: function (e) {
                            if (this.parentObject.lostTimeInjuryId == 0) {
                                return this.parentObject.lostTimeInjuryId && this.value == 0;
                            }
                            if (this.parentObject.lostTimeInjuryId == 1) {
                                return this.parentObject.lostTimeInjuryId && this.value == 1;
                            }
                            if (this.parentObject.lostTimeInjuryId == 2) {
                                return this.parentObject.lostTimeInjuryId && this.value == 2;
                            }
                        }
                    },
                    childrens: [{
                        text: {
                            text: function (e) { return this.text }
                        }
                    }]
                },
            }
            
        ],
        
        sourceItems: DaysDropdown(),
    },
    label:{
        isBind: (x) => {
            return x.total != "Total" && x.isAllowEdit==false ? true : false;
          },
          childrens: [{
             text: {
              text: function (e) { return this.lostTimeInjuryName }
              }
           }]
          }
}
