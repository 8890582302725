import { TemplateConfig } from "@rxweb/grid";
import { getLocalizedValue } from "src/app/domain/common-logic/common-logic";

export const NOTEEXPAND_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            if (x.isExpand == "true" && !x.isSubNote) {
              return true;
            } else {
              return false;
            }
          },
          event: {
            click: "onNoteExpand",
          },
          childrens: [
            {
              i: {
                isBind: (x) => {
                  if (!x.isShowAll) {
                    return true;
                  } else {
                    return false;
                  }
                },
                class: "fa fa-plus fa-4x font-md text-default".split(" "),
                attributes: { title: getLocalizedValue("Title_Expand") },
              },
            },
            {
              i: {
                isBind: (x) => {
                  if (x.isShowAll) {
                    return true;
                  } else {
                    return false;
                  }
                },
                class: "fa fa-minus fa-4x font-md text-default".split(" "),
                attributes: { title: getLocalizedValue("Title_Collapse") },
              },
            },
          ],
          class: ["mr-2"],
        },
      },
    ],
  },
};
