<!-- <div class="modal-dialog modal-lg" style="line-height:0.1;height:90%;overflow: auto;"> -->
  <div class="modal-dialog modal-lg" *rxMultilingual="">
    <div class="modal-content" *ngIf="showComponent==true">
        <div class="modal-header text-left" [rxLocalisationInit]="componentName">
            <h4 class="modal-title mb-0" rxText="PressMediaViewAddComponent_Title_t"></h4>
            <button type="button" class="close py-2">
                <span aria-hidden="true" (click)="hide()">×</span>
                <span class="sr-only">Close</span>
            </button>
        </div>
        <div class="modal-body" [formGroup]="riskPressMediaFormGroup" [rxLocalisationInit]="componentName"
          *ngIf="showComponent==true" [rxSpinner]="spin" (keyup.enter)="searchRiskPress()">
          <div>
              <!-- <div class="col-lg-6 col-xl-4 pr-xl-5"> -->
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group row">
                    <label class="col-5 col-form-label"
                        rxText="PressMediaViewAddComponent_Type_t"></label>
                    <div class="col-7" [rxSelectExtended]="_this">
                      <rx-select [(source)]="hashtagLookup" #rxSelect [controlTabIndex]="10"
                        formControlName="hashTag" mainClass="form-control"
                        [keyValueProps]="['typeName','typeId']" [selectPlaceholder]="'Select Press and Media Type'">
                      </rx-select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group row">
                    <label class="col-5 col-form-label"
                        rxText="PressMediaViewAddComponent_Added_t"></label>
                    <div class="col-7" [rxTagExtended]="_this">
                      <rx-tag [(source)]="addedByLookup" #rxTag [controlTabIndex]="13"
                        formControlName="peoples" [isSort]="false" placeholder="Select Added by"
                        mainClass="form-control" [keyValueProps]="['fullName','addedUserId']"></rx-tag>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row pull-right mr-3">
                  <button type="button" class="btn btn-success" (click)="searchRiskPressList()" rxText="Btn_Search_t"></button>
              </div>
              <div class="ibox-content bg-white d-flex flex-column flex-grow-1" *ngIf="isShowGrid">
                  <div class="dataTables_filter  d-flex flex-wrap align-items-center" *ngIf="totalRecords>0">

                  </div>
                  <div class="table-responsive" *ngIf="isShowGrid">
                      <div class="dataTables_wrapper pb-0 dt-bootstrap4">
                          <rx-grid [design]="riskPressMediaGrid"></rx-grid>
                      </div>
                  </div>
              </div>
              <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
                  <h4>{{ErrorMsg}}</h4>
              </div>

              <!-- </div>   -->
          </div>
        </div>
        <div class="modal-footer" [rxLocalisationInit]="componentName">
          <button type="button" class="btn btn-success"
          [disabled]="(riskPressMapping.riskProjectInput.length==0 && this.excludedRiskPressMapping.riskProjectInput.length == 0 && !this.headerBtnChk)
          || (this.totalRecords - this.excludedRiskPressMapping.riskProjectInput.length == 0)" (click)="savePressMedia()" rxText="Btn_Save_t"></button>
          <button class="btn btn-outline-primary" (click)="hide()" rxText="Btn_Close_t"></button>
        </div>




    </div>
  </div>
