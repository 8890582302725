import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ITTicketMailSettingBase {

//#region iTTicketSettingId Prop
        @prop()
        iTTicketSettingId : number;
//#endregion iTTicketSettingId Prop


//#region companyId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        companyId : number;
//#endregion companyId Prop


//#region emails Prop
        @required()
        emails : string;
//#endregion emails Prop

}