import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BodilyLocationBase {

//#region bodilyLocationId Prop
        @prop()
        bodilyLocationId : any;
//#endregion bodilyLocationId Prop


//#region bodilyLocationName Prop
        @maxLength({value:250})
        bodilyLocationName : string;
//#endregion bodilyLocationName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}