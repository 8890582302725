import { prop, propObject, propArray, required, maxLength, range, trim, email, alpha, numeric, notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PointofSaleBase {

        //#region saleId Prop
        @prop()
        saleId: number;
        //#endregion saleId Prop


        //#region title Prop
        @trim()
        @required()
        @notEmpty()
        title: string;
        //#endregion title Prop


        //#region email Prop
        @maxLength({ value: 500 })
        @email()
        email: string;
        //#endregion email Prop


        //#region address1 Prop
        @maxLength({ value: 200 })
        @trim()
        address1: string;
        //#endregion address1 Prop


        //#region address2 Prop
        @maxLength({ value: 200 })
        @trim()
        address2: string;
        //#endregion address2 Prop


        //#region city Prop
        @maxLength({ value: 200 })
        @trim()
        // @alpha({ allowWhiteSpace: true })
        city: string;
        //#endregion city Prop


        //#region stateProvinceId Prop
       // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
       // @required()
       @prop({defaultValue:0})
        stateProvinceId: number;
        //#endregion stateProvinceId Prop


        //#region zipCode Prop
        @prop()
        @maxLength({ value: 10 })
        @trim()
        //@numeric()
        zipCode: string;
        //#endregion zipCode Prop


        //#region countryId Prop
        @range({ minimumNumber: 1, maximumNumber: 2147483647 })
        @required()
        countryId: number;
        //#endregion countryId Prop


        //#region createdOn Prop
        @prop()
        createdOn: Date;
        //#endregion createdOn Prop


        //#region updatedOn Prop
        @prop()
        updatedOn: Date;
        //#endregion updatedOn Prop


        //#region stockingFaberge Prop
        @required()
        @prop({ defaultValue: false })
        stockingFaberge: boolean;
        //#endregion stockingFaberge Prop


        //#region opoeningTimes Prop
        @maxLength({ value: 1000 })
        opoeningTimes: string;
        //#endregion opoeningTimes Prop


        //#region sizeofBoutique Prop
        @maxLength({ value: 200 })
        sizeofBoutique: string;
        //#endregion sizeofBoutique Prop


        //#region telephone Prop
        //@maxLength({ value: 200 })
        @prop()
        telephone: string;
        //#endregion telephone Prop

}