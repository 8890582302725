import {OrderPieceStatusHistoryMappingBase,} from '@app/database-models'
//Generated Imports
export class OrderPieceStatusHistoryMapping extends OrderPieceStatusHistoryMappingBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





































}