import { gridColumn } from '@rxweb/grid'

export class vEventCorporateMappingBase{
    @gridColumn({ visible: false, columnIndex: 0, allowSorting: true, headerKey: 'eventID', keyColumn: true })
    eventID:number
    @gridColumn({style: { width: "10%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex: 1, allowSorting: true, headerTitle: 'Person', keyColumn: true })
    person:string
    @gridColumn({ visible: false, columnIndex: 2, allowSorting: true, headerTitle: 'Corporate/Groups', keyColumn: true })
    corporate:number
    @gridColumn({ visible: false, columnIndex: 5, allowSorting: true, headerKey: 'eventCustomerID', keyColumn: true })
    eventCustomerID:number
    @gridColumn({style: { width: "5%", "text-align": "initial" }, class: ["text-left"],  visible: false, columnIndex: 4, allowSorting: true, headerTitle: 'Department', keyColumn: true })
    department:string
    @gridColumn({style: { width: "15%", "text-align": "initial" }, class: ["text-left"],  visible: true, columnIndex: 3, allowSorting: true, headerTitle: 'Notes', keyColumn: true })
    notes:string
    customerID:number;
   
}