import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class WatchAttributeMappingBase {

//#region watchAttributeID Prop
        @prop()
        watchAttributeID : number;
//#endregion watchAttributeID Prop


//#region productVariantID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantID : number;
//#endregion productVariantID Prop


//#region pieceID Prop
        @prop()
        pieceID : number;
//#endregion pieceID Prop


//#region watchAttributeName Prop
        @prop()
        watchAttributeName : string;
//#endregion watchAttributeName Prop


//#region watchAttributeValue Prop
        @prop()
        watchAttributeValue : string;
//#endregion watchAttributeValue Prop



}