import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductPieceOrderBase {

//#region orderProductVariantPieceID Prop
        @prop()
        orderProductVariantPieceID : number;
//#endregion orderProductVariantPieceID Prop


//#region orderProductVariantID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        orderProductVariantID : number;
//#endregion orderProductVariantID Prop


//#region productVariantBatchPieceId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productVariantBatchPieceId : number;
//#endregion productVariantBatchPieceId Prop





}