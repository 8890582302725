import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from "@angular/core"
import { vProjectEvents, SubProjectListViewModel } from "@app/models";
import { Subscription } from 'rxjs';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGrid } from 'src/app/domain/app-grid';
import { AbstractSubProject } from '../domain/abstract-sub-project';
import { SubProjectAddComponent } from '../add/sub-project-add.component';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { PaginationViewModel } from 'src/app/view-model/pagination-view-model';
import { multilingual } from '@rxweb/localization';
import { SubProjectsViewModel } from 'src/app/view-model/sub-projects-view-model';
import { SERVER_URL } from "src/app/domain/system-constant";
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { GridColumnConfig } from '@rxweb/grid';
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("SubProjectListComponent")

@Component({
    selector: "app-sub-project-list",
    templateUrl: './sub-project-list.component.html'
})
export class SubProjectListComponent extends AbstractSubProject implements OnInit, OnDestroy {
    subProjects: SubProjectsViewModel[];
    subProjectsGrid: AppGrid;
    subProjectsGridColumns: any;
    subscription: any;
    id: number;
    modalView: ModalView;
    isFilter: boolean = false;
    totalRecords: number = 0;
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    paginationViewModel: PaginationViewModel;
    gridSearchString: string = "";
    json: any;
    isHideAll: boolean = false;
    isShowAll: boolean = false;
    @Output() projectIdChange: EventEmitter<any> = new EventEmitter();
    titleSearch: string;
    isLongTextExists: boolean=false;
    isShowGridColumn: boolean = false;
    titleGridColumnSetting: string;
    gridColumnConfig: GridColumnConfig[];
    gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
    getColumnsData: any;
    arrConfig: any[];
    @Input() moduleID: any;
    @Input() selectedTab: string = "";

    constructor(modelView: ModalView, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private router: Router,blobState: BlobUploadsViewStateService ) {
        super(blobState);
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        this.modalView = modelView;
    }

    ngOnInit(): void {
        this.titleSearch = getLocalizedValue("Title_Search");
        this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
        this.paginationViewModel = new PaginationViewModel();
        this.paginationViewModel.sortOrder = "true";
        this.paginationViewModel.orderBy = "projectName";
        this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
        this.get({
            path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
        }).subscribe((t: any) => {
            this.getColumnsData = t;
            if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
                this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
            }
            setTimeout(() => {
                this.bindGrid();
            }, 0);
        })
    }

    bindGrid(isOnload: boolean = true) {
        this.isShowGrid = true;
        this.spin = true;
        this.json["projectId"] = this.id;
        this.json["projectOrSubproject"] = 2;
        this.json["clientUrl"] = SERVER_URL;
        this.subscription = this.post({ path: "api/SearchProject/ProjectSearch", body: { query: JSON.stringify(this.json), } }).subscribe((t: any) => {
            this.spin = false;
            if (this.subProjectsGrid) {
                this.subProjectsGrid.storeProcedure.length = 0;
            }
            this.subProjects = t;
            // if (isOnload) {
            //     for (let element of this.subProjects) {
            //         if (element.noteCharCount > 100) {
            //             this.isShowAll = true;
            //             break;
            //         }
            //     }
            // }
            if (this.subProjects.length > 0) {
                this.totalRecords = this.subProjects[0].totalCount;
                this.isNoRecordFound = false;
            }
            else {
                this.isShowGrid = false;
                this.isNoRecordFound = true;
            }
            if (!this.isFilter) {

                this.subProjectsGrid = new AppGrid(this.subProjects, SubProjectsViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.projectRedirect.bind(this) } });
                this.subProjectsGrid.storeProcedure = {
                    length: this.totalRecords,
                    onPageChanging: this.onPageChanging.bind(this),
                    nextPage: 1,
                    onPageSorting: this.onPageSorting.bind(this)
                }
                if (this.arrConfig != undefined && this.arrConfig != null) {
                    this.arrConfig.forEach(x => {
                        this.subProjectsGrid.gridColumns.forEach(y => {
                            if (y.name == x.ColumnName)
                                y.visible = x.Visible;
                        });
                    });
                    this.gridColumnConfig = this.subProjectsGrid.gridColumns;
                    this.gridColumnSettings.emit(this.gridColumnConfig)
                }
                this.checkForLongTexts();
              }
              else {
                this.subProjectsGrid.storeProcedure.length = this.totalRecords;
                this.subProjectsGrid.updateSource([]);
                this.subProjectsGrid.updateSource(this.subProjects);
                this.checkForLongTexts();
              }
            this.subProjectsGrid.designClass.tableClass.push("table-list");
            this.subProjectsGrid.maxPerPage = this.paginationViewModel.rowCount;
            this.subProjectsGrid.componentId = this.componentName;
            this.applyResProjClass();
        });
    }
    showGridColumn() {
        this.isShowGridColumn = !this.isShowGridColumn;
    }
    applyVisible(gridColumnConfig: GridColumnConfig[]) {
        if (this.arrConfig != undefined && this.arrConfig != null) {
          this.arrConfig.forEach(element => {
            var index = this.subProjectsGrid.gridColumns.findIndex(x => x.name == element.ColumnName);
            if (index != -1) {
              if (this.subProjectsGrid.gridColumns[index].visible != element.Visible) {
                element.Visible = this.subProjectsGrid.gridColumns[index].visible;
              }
            }
          })
        }
      this.checkForLongTexts();
        this.subProjectsGrid.reDesign();
        this.subProjectsGrid.updateSource([])
        this.subProjectsGrid.updateSource(this.subProjects);
    }
    showOrHideAll() {
        this.isShowAll = !this.isShowAll;
        this.isFilter = false;
        this.subProjectsGrid.destroy();
        this.subProjectsGrid = null;
        this.json["isShowAll"] =this.isShowAll;
        this.bindGrid();
      }
      checkForLongTexts() {
        this.isLongTextExists = false;
        let isDescriptionCheckRequired = false;
        this.subProjectsGrid.gridColumns.forEach(column => {
          if (column.name == "noteDescription" && column.visible) {
            isDescriptionCheckRequired = true;
          }
        });
        if (isDescriptionCheckRequired ) {
          for (let element of this.subProjects) {

            if (element.noteCharCount > 100 && isDescriptionCheckRequired) {
              this.isLongTextExists = true;
              break;
            }
          }
        }
    }

    applyResProjClass() {
        this.subProjectsGrid.designClass.rowClass = [function () {
            let data = this;
            if (data.isRestrictedProject) {
                return "danger"
            } else {
                return "div-tr";
            }
        }]
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.paginationViewModel.sortOrder = y;
        this.paginationViewModel.orderBy = x;
        this.json["sortOrder"] = y;
        this.json["orderBy"] = x;
        this.subProjectsGrid.storeProcedure.nextPage = z;
        this.bindGrid(false);
    }

    onPageChanging(x) {
        this.isFilter = true;
        this.paginationViewModel.pageIndex = x;
        this.paginationViewModel.rowCount = this.subProjectsGrid.maxPerPage;
        this.json["pageIndex"] = x;
        this.json["rowCount"] = this.subProjectsGrid.maxPerPage;
        this.subProjectsGrid.storeProcedure.nextPage = x;
        this.bindGrid(false);
    }

    onMoreClick(t, x) {
        var element = document.getElementById("note_project_" + t.noteId);
        var maskedElement = document.getElementById("maskednote_" + t.noteId);
        if (element.className == "collapse") {
            x.srcElement.innerText = "Show Less";
            x.srcElement.className = "less-btn"
            element.classList.add("show");
            maskedElement.setAttribute("style", "display:none");
        }
        else {
            element.classList.remove("show");
            x.srcElement.innerText = "Show More";
            x.srcElement.className = "more-btn";
            maskedElement.setAttribute("style", "display:inline");
        }
    }

    onMoreClickAll() {
        this.isFilter = true;
        //this.isShowGrid=true;
        this.subProjectsGrid.destroy();
        this.subProjectsGrid = new AppGrid(this.subProjects, SubProjectsViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.projectRedirect.bind(this) } });

        this.subProjectsGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
        }
        this.subProjectsGrid.designClass.tableClass.push("table-list");
        this.subProjectsGrid.gridColumns.forEach(z => {
            // if (z.name == "isViewByPeople") {
            //   z.visible = this.entityTypeId == NotesEnum.OrderNotes ? true : false;
            // }
            if (z.name == 'noteDescription') {
                z.visible = false
            }
            if (z.name == 'noteDescription1') {
                z.visible = true
            }
        })
        //this.isShow=false;
        //this.isShowAll = this.isShow;
        this.isShowAll = false;
        this.isHideAll = true;
        this.subProjectsGrid.maxPerPage = this.paginationViewModel.rowCount;
        this.subProjectsGrid.componentId = this.componentName;
    }

    onLessClickAll() {
        this.subProjectsGrid.destroy();
        this.subProjectsGrid = new AppGrid(this.subProjects, SubProjectsViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.projectRedirect.bind(this) } });
        this.subProjectsGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
        }
        this.subProjectsGrid.designClass.tableClass.push("table-list");
        this.subProjectsGrid.gridColumns.forEach(z => {
            // if (z.name == "isViewByPeople") {
            //   z.visible = this.entityTypeId == NotesEnum.OrderNotes ? true : false;
            // }
            if (z.name == 'noteDescription') {
                z.visible = true
            }
            if (z.name == 'noteDescription1') {
                z.visible = false
            }
        })
        this.isShowAll = true;
        this.isHideAll = false;
        this.subProjectsGrid.maxPerPage = this.paginationViewModel.rowCount;
        this.subProjectsGrid.componentId = this.componentName;
    }

    showAddForm() {
        this.modalView.show(SubProjectAddComponent, { projectId: this.id }).then(t => {
            this.isFilter = true;
            this.bindGrid();
        });
    }

    projectRedirect(project: SubProjectsViewModel,event) {
        if (project.subStreamType == "PRJ") {

            if(event.ctrlKey)
            {
                let url : any;
                url = this.router.serializeUrl(this.router.createUrlTree(["projects", project.projectId], { queryParams: { activeTab: 'project-edit' } }));
                redirectOnCTRL(url,this.router,event);
            }
            else
            {
            this.projectIdChange.emit({ id: project.projectId, subStreamType: project.subStreamType });
            }
        }

        if (project.subStreamType == "DES") {
            if(event.ctrlKey)
            {
                let url : any;
                url = this.router.serializeUrl(this.router.createUrlTree(["projects", project.projectId], { queryParams: { activeTab: 'project-edit' } }));
                redirectOnCTRL(url,this.router,event);
            }
            else
            {
            this.projectIdChange.emit({ id: project.linkID, subStreamType: project.subStreamType });
            }
        }
    }

    search(value) {
        setTimeout(() => {
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.json['searchString'] = value.target.value;

            if (this.subProjectsGrid != null) {
                this.subProjectsGrid.updateSource([]);
                this.json["pageIndex"] = 1;
                //this.json["rowCount"] = this.paginationViewModel.rowCount;
                this.subProjectsGrid.storeProcedure.nextPage = 1;
                this.json["rowCount"] = this.subProjectsGrid.maxPerPage;
            }
            this.bindGrid(false);
        }, 4000);
        this.spin = true;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "SubProjectListComponent";
    }
}
