import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class OccurrenceTypeBase {

    //#region occurrenceTypeId Prop
    @prop()
    occurrenceTypeId: number;
    //#endregion occurrenceTypeId Prop


    //#region occurrenceTypeName Prop
    //@range({ minimumNumber: 1, maximumNumber: 2147483647 })
   //@required()
    occurrenceTypeName: string;
    //#endregion occurrenceTypeName Prop


    //#region roleId Prop
   // @range({ minimumNumber: 1, maximumNumber: 2147483647 })
  //  @required()
   // roleId: number;
    //#endregion roleId Prop

    //#region roleId Prop
  //  @prop({ defaultValue: false })
   // active: boolean;
    //#endregion roleId Prop
    
 //   @prop()
 //   deleted:boolean;

    //#region createdOn Prop
 //   @required()
 //   createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
 //   @prop()
  //  createdBy : number;
//#endregion createdBy Prop

   //#region roleId Prop
   @prop({ defaultValue: false })
   active: boolean;
   //#endregion roleId Prop

   @prop({ defaultValue: false })
   isTicketType : boolean;

    

}
