import {GemstoneStatusBase,} from '@app/database-models'
//Generated Imports
export class GemstoneStatus extends GemstoneStatusBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}