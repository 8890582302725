import {ProductGemstoneMappingBase,CategoryBase,ProductPieceGemstoneBase,ProductBase,} from '@app/database-models'
//Generated Imports
export class ProductGemstoneMapping extends ProductGemstoneMappingBase 
{




//#region Generated Reference Properties
//#region category Prop
category : CategoryBase;
//#endregion category Prop
//#region productPieceGemstone Prop
productPieceGemstone : ProductPieceGemstoneBase;
//#endregion productPieceGemstone Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties











}