import {LegalAgreementProjectMappingBase, LegalAgreementBase} from '@app/database-models';
//Generated Imports
export class LegalAgreementProjectMapping extends LegalAgreementProjectMappingBase
{

    legalAgreementIds: LegalAgreementProjectMapping[];

    //#region Generated Reference Properties
    //#region legalAgreement Prop
    legalAgreement: LegalAgreementBase;
    //#endregion legalAgreement Prop

    //#endregion Generated Reference Properties
    headerBtnCheck:boolean;
    query:string;








}