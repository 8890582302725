import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vMeasureDimensionRecordBase {

//#region measureDimensionId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'measureDimensionId', keyColumn: true})
        measureDimensionId : number;
//#endregion measureDimensionId Prop


//#region name Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'name', keyColumn: false})
        name : string;
//#endregion name Prop


//#region systemKeyword Prop
        @gridColumn({visible: true, columnIndex:2, allowSorting: true, headerKey: 'systemKeyword', keyColumn: false})
        systemKeyword : string;
//#endregion systemKeyword Prop


//#region ratio Prop
        @gridColumn({visible: true, columnIndex:3, allowSorting: true, headerKey: 'ratio', keyColumn: false})
        ratio : number;
//#endregion ratio Prop


//#region displayOrder Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'displayOrder', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop

}