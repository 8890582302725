import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric,trim, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class PricebandOptionBase {

//#region pricebandOptionId Prop
        @prop()
        pricebandOptionId : number;
//#endregion pricebandOptionId Prop


//#region pricebandName Prop
        @maxLength({value:50})
        @trim()
        @notEmpty()
        pricebandName : string;
//#endregion pricebandName Prop


//#region pricebandFrom Prop
       @pattern({expression:{"pricebandFrom":/^[0-9]\d{0,7}$/},message:"You can not enter value more than 8"})
        @required()
        @notEmpty()
        @numeric()
      //  @range({minimumNumber:1,maximumNumber:9999999})
        pricebandFrom : number;
//#endregion pricebandFrom Prop


//#region pricebandTo Prop
        @pattern({expression:{"pricebandTo":/^[0-9]\d{0,7}$/},message:"You can not enter value more than 8"})
        @required()
        @notEmpty()
        @numeric()      
      //  @range({minimumNumber:1,maximumNumber:9999999})          
        pricebandTo : number;
//#endregion pricebandTo Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}