import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractLanguage } from '../domain/abstract-language';
import { Language, vLanguage } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';

import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { LanguageAddComponent } from '../add/language-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
@multilingual("LanguageListComponent")
@access({ accessLevel: RolePermissionEnums.Languages, action: "get" })

@Component({
    selector: "app-language-list",
    templateUrl: './language-list.component.html'
})
export class LanguageListComponent extends AbstractLanguage implements OnInit, OnDestroy {
    languages: vLanguage[];
    subscription: any;
    languageGrid: AppGrid;
    languageGridColumns: any[];
    languageAdd: any = LanguageAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    constructor(private router: Router, modelView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.moduleID = RolePermissionEnums.Languages;
        location.onPopState(() => this.dialog.hide());
    }


    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("Languages - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }
    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vLanguage[]) => {
            this.spin = false;
            this.languages = t;
            this.languageGrid = new AppGrid(t, vLanguage, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.languageGrid.gridColumns.forEach(x => {
                if (this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "put" }) && this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "delete" });
                    }
                }
            })
            this.languageGrid.componentId = this.componentName;
            this.languageGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.languageGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.languageGrid.design(document.getElementById("languages"));
            this.showComponent = true;
        })
    }

    onEdit(language: vLanguage) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["languages", language.languageId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["languages", language.languageId]);
    }

    onDelete(language: vLanguage) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [language.languageId] }).subscribe(data => {
                    this.spin = false;
                    this.languageGrid.remove(language.languageId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/languages']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = getLocalizedValue("Delete_Confirmation_Language");
        if (existsAlert) {
            return existsAlert;
        }
    }
    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.languages.filter(x => x.languageName.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.languageId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase())
                || x.languageCulture.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.displayOrder.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.languageGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.languageGrid = null;
        }
        setTimeout(() => {
            if (this.languageGrid == null || this.languageGrid == undefined) {
                this.languageGrid = new AppGrid(this.languages, vLanguage, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.languageGrid.gridColumns.forEach(x => {
                    if (this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "put" }) && this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.languageGrid.authorize({ accessLevel: RolePermissionEnums.Languages, action: "delete" });
                        }
                    }
                })
                this.languageGrid.componentId = this.componentName;
                this.languageGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.languageGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.languageGrid.refresh("languages");
                this.languageGrid.design(document.getElementById("languages"));
            }
            this.languageGrid.search = value.target.value;
        }, 0);
    }


    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "LanguageListComponent";
    }

}
