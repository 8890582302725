import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"

export class DocumentTypeUserMappingBase {

//#region documentTypeUserMappingId Prop
        @prop()
        documentTypeUserMappingId : number;
//#endregion documentTypeUserMappingId Prop

//#region documentTypeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        documentTypeId : number;
//#endregion documentTypeId Prop

//#region userId Prop
        @prop()
        userId : number;
//#endregion userId Prop

 //#region createdBy Prop
 @prop()
 createdBy: number;
 //#endregion createdBy Prop

 //#region createdOn Prop
 @prop()
 createdOn: Date;
 //#endregion createdOn Prop

}