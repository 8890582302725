import { prop, trim } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class ImportEventInviteesAttendeesViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "eventName";
    }

    @prop()
    eventId : number;

    @prop()
    eventName: string;

    @prop()
    @trim()
    eventText : string;

    @prop()
    eventStartDate: Date;

    @prop()
    eventEndDate: Date;

    @prop()
    eventType : number;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}
