import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { TaxProvider } from '@app/models';
import { BaseToastr } from '../../../../domain/customize-design/toastr';
import { BaseDialog } from '../../../../domain/customize-design/dialog';
import { CoreComponent,  } from '@rxweb/angular-router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BedRequestComponent } from '../../../shared/global/bed-request/bed-request.component';
import { List } from '@rxweb/generics';
import { TAX_PROVIDER } from '../../../../custom-collections/tax-provider';
@http({
    path: "api/TaxProviders",
})
export class AbstractTaxProvider extends CoreComponent {
    taxProviderFormGroup: IFormGroup<TaxProvider>
    showComponent: boolean = false;
    spin: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    modalView: ModalView;
    currentActiveTab: string = "system-info";
    taxProviderClassses: List<any> = new List(TAX_PROVIDER);
    validField: string[] = ["name","configureTemplatePath","description","className","displayOrder"];
    breakLoop: boolean;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }
    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {

        })
    }

    activatedTab(tab) {
        
        this.currentActiveTab = tab;
    }

}
