import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { HelpSupport, vHelpSupport } from "@app/models";
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from '../../../../domain/customize-design/dialog';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { AbstractFormsDocument } from '../domain/abstract-forms-document';
import { vFormsDocument } from 'src/app/models/extended-models/v-forms-document';
import { FormsDocumentAddComponent } from '../add/forms-document-add.component';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { PlatformLocation } from "@angular/common";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
@multilingual("FormsDocumentListComponent")
@access({ accessLevel: RolePermissionEnums.FormsDocument, action: "get" })


@Component({
    selector: "app-forms-document-list",
    templateUrl: './forms-document-list.component.html'
})
export class FormsDocumentListComponent extends AbstractFormsDocument implements OnInit, OnDestroy {
    subscription: any;
    formsDocument: vFormsDocument[];
    FormsDocumentGrid: AppGrid;
    id: any;
    formsDocumentAdd: any = FormsDocumentAddComponent;
    isNoRecordFound: boolean = false;
    moduleID: number;
    titleHelp: string;
    titleSearch: string;
    showSystemHelpIcon: boolean = false;
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }

    constructor(private activatedRoute: ActivatedRoute, private router: Router, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title, private location : PlatformLocation, blobState: BlobUploadsViewStateService) {
        super(blobState);
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        });
        this.modalView = modalView;
        this.moduleID = RolePermissionEnums.FormsDocument;
        location.onPopState(() => this.dialog.hide());
    }
    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.titleHelp = getLocalizedValue("Title_Help");
        this.titleSearch = getLocalizedValue("Title_Search");
        this.bindGrid();
    }

    bindGrid() {
        this.spin = true;
        this.subscription = this.get({ queryParams: { formId: 0 } }).subscribe((t: vFormsDocument[]) => {
            this.spin = false;
            this.formsDocument = t;
            this.FormsDocumentGrid = new AppGrid(t, vFormsDocument, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
            this.FormsDocumentGrid.gridColumns.forEach(x => {

                if (this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "put" }) && this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "delete" })) {
                    if (x.name == "action") {
                        x.visible = true;
                    }
                }
                else {
                    if (x.name == "action") {
                        x.visible = false;
                    }
                    if (x.name == "editAction") {
                        x.visible = this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "put" });
                    }
                    if (x.name == "deleteAction") {
                        x.visible = this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "delete" });
                    }
                }

            })
            this.FormsDocumentGrid.componentId = this.componentName;
            this.FormsDocumentGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
            this.FormsDocumentGrid.designClass.headerClass = "table-header-sticky".split(" ");
            this.FormsDocumentGrid.design(document.getElementById("forms-document"));
        })
    }

    onEdit(formsDocument: vFormsDocument) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["forms-document", formsDocument.formId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["forms-document", formsDocument.formId]);
    }

    search(value) {
        if (value.target.value.trim() != "" && value.target.value != undefined && value.target.value != null) {
            let ele = this.formsDocument.filter(x => x.name.toLowerCase().includes(value.target.value.trim().toLowerCase()) || x.displayOrderId.toString().toLowerCase().includes(value.target.value.trim().toLowerCase()));
            if (ele.length == 0) {
                this.isNoRecordFound = true;
            } else {
                this.isNoRecordFound = false;
                this.FormsDocumentGrid = null;
            }
        } else {
            this.isNoRecordFound = false;
            this.FormsDocumentGrid = null;
        }
        setTimeout(() => {
            if (this.FormsDocumentGrid == null || this.FormsDocumentGrid == undefined) {
                this.FormsDocumentGrid = new AppGrid(this.formsDocument, vFormsDocument, { actions: { onDelete: this.onDelete.bind(this), onEdit: this.onEdit.bind(this) } });
                this.FormsDocumentGrid.gridColumns.forEach(x => {
                    if (this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "put" }) && this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "delete" })) {
                        if (x.name == "action") {
                            x.visible = true;
                        }
                    }
                    else {
                        if (x.name == "action") {
                            x.visible = false;
                        }
                        if (x.name == "editAction") {
                            x.visible = this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "put" });
                        }
                        if (x.name == "deleteAction") {
                            x.visible = this.FormsDocumentGrid.authorize({ accessLevel: RolePermissionEnums.FormsDocument, action: "delete" });
                        }
                    }

                })
                this.FormsDocumentGrid.componentId = this.componentName;
                this.FormsDocumentGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(" ");
                this.FormsDocumentGrid.designClass.headerClass = "table-header-sticky".split(" ");
                this.FormsDocumentGrid.refresh("forms-document");
                this.FormsDocumentGrid.design(document.getElementById("forms-document"));
            }
            this.FormsDocumentGrid.search = value.target.value;
        }, 0);
    }
    onDelete(formsDocument: vFormsDocument) {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if (t === DialogEnum.Ok) {
                this.dialog.hide();
                this.spin = true;
                this.delete({ body: null, params: [formsDocument.formId] }).subscribe(data => {
                    this.spin = false;
                    this.FormsDocumentGrid.remove(formsDocument.formId);
                    var existsAlert = getLocalizedValue("Data_Deleted");
                    if (existsAlert) {
                        //this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert);
                    }
                    this.router.navigate(['/forms-document']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
      var existsAlert = getLocalizedValue("Delete_Confirmation_Forms_Document");
        if (existsAlert) {
            return existsAlert;
        }
    }

    get componentName(): string {
        return "FormsDocumentListComponent";
    }
}
