import { TemplateConfig } from '@rxweb/grid';
export const DATA_BIND: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onData"
                },

                attributes: {
                  type: "text",
                  value: function (e) {
                    return this.guestCount;
                  },
                  placeholder:"Enter Plus Additional Guest",
                  min: 0
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const POS_TAX_DUTY_DELIVERY_PRICE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onPOSTaxDutyDeliveryPrice"
                },

                attributes: {
                  type: "text",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  },
                  disabled: true
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const POS_SOLD_PRICE: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onPOSSoldPrice"
                },

                attributes: {
                  type: "text",
                  placeholder:"Sold Price",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const FAB_GEMSTONE_CERTIFICATE_NUMBER: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onFabGemCertiNum"
                },

                attributes: {
                  type: "text",
                  placeholder:"Enter Certificate Number",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const FAB_GEMSTONE_Invoice_NUMBER: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onFabGemInvoiceNum"
                },

                attributes: {
                  type: "text",
                  placeholder:"Enter Invoice Number",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}

export const WATCH_INVOICE_NUMBER: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              input:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onWatchInvoiceNumber"
                },

                attributes: {
                  type: "text",
                  placeholder:"Enter Invoice Number",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  }
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const RATING_FEEDBACK: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          childrens: [
            {
              textarea:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onFeedback",
                },

                attributes: {
                  type: "text",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  },
                  placeholder: "Enter Feedback",
                  cols: "20",
                  rows: "4"
                }
              }
            }
          ]
        }
      }
    ]
  },
}


export const TEAM_OBJECTIVE_RATING_FEEDBACK: TemplateConfig = {
  div: {
    childrens: [
      {
        label: {
          isBind: (x) => {
            if (x.goalStatusId == 2) {
              return true;
            } else {
              return false;
            }
          },
          childrens: [
            {
              textarea:
              {
                class: ["form-control", "checkbox-custom-style", "col-xs-1"],
                event: {
                  input: "onReject"
                },

                attributes: {
                  type: "text",
                  min: 0,
                  value: function (e) {
                    if (this[e.name] != null && this[e.name] != undefined) {
                      return this[e.name];
                    } else {
                      return '';
                    }
                  },
                  placeholder: "Enter Rejection Note",
                  cols: "20",
                  rows: "4"
                }
              }
            }
          ]
        }
      }
    ]
  },
}