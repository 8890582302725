import { TemplateConfig } from '@rxweb/grid';

export const REDIRECT_ORDER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.orderId > 0 ? true : false;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOrderNoLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.orderId != undefined && this.orderId != null && this.orderId > 0) {
                return "/orders/" + this.orderId + "?activeTab=sales-order";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.orderId != undefined && this.orderId != null && this.orderId > 0) {
                return "/orders/" + this.orderId + "?activeTab=sales-order";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const REDIRECT_RETURN_ORDER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.returnOrderId > 0 ? true : false;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOrderNoLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.returnOrderId != undefined && this.returnOrderId != null && this.returnOrderId > 0) {
                return "/return-orders/" + this.returnOrderId + "?activeTab=edit-info";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.returnOrderId != undefined && this.returnOrderId != null && this.returnOrderId > 0) {
                return "/return-orders/" + this.returnOrderId + "?activeTab=edit-info";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const REDIRECT_SALES_ORDER_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.OrderId > 0 ? true : false;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onOrderNoLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.OrderId != undefined && this.OrderId != null && this.OrderId > 0) {
                return "/orders/" + this.OrderId + "?activeTab=sales-order";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.OrderId != undefined && this.OrderId != null && this.OrderId > 0) {
                return "/orders/" + this.orderId + "?activeTab=sales-order";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const REDIRECT_LINK_PRODUCT_SALES_ORDER_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onRedirectProduct"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.productvariantid != null || this.productvariantid != undefined) {
                return "/product/" + this.productvariantid + "?activeTab=product-info";
              }
              if (this.ProductVariantId != null || this.ProductVariantId != undefined) {
                return "/product/" + this.ProductVariantId + "?activeTab=product-info";
              }
              if (this.productVariantId != null || this.productVariantId != undefined) {
                return "/product/" + this.productVariantId + "?activeTab=product-info";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.productvariantid != null || this.productvariantid != undefined) {
                return "/product/" + this.productvariantid + "?activeTab=product-info";
              }
              if (this.ProductVariantId != null || this.ProductVariantId != undefined) {
                return "/product/" + this.ProductVariantId + "?activeTab=product-info";
              }
              if (this.productVariantId != null || this.productVariantId != undefined) {
                return "/product/" + this.productVariantId + "?activeTab=product-info";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};

export const REDIRECT_LINK_PIECE_SALES_ORDER_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onRedirectPiece"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.ProductVariantBatchPieceId != null || this.ProductVariantBatchPieceId != undefined) {
                return "/product-piece/" + this.ProductVariantBatchPieceId + "?activeTab=piece-info";
              }
              if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.ProductVariantBatchPieceId != null || this.ProductVariantBatchPieceId != undefined) {
                return "/product-piece/" + this.ProductVariantBatchPieceId + "?activeTab=piece-info";
              }
              if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
              }
            },
            onClick: "return false;"
          }
        }
      }
    ]
  },
};
export const REDIRECT_LINK_PIECE_SALES_MARGIN_REPORT_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          isBind: (x) => {
            return x.imageUrl == "Total" ? false : true;
          },
          class: "text-blue font-weight-bold".split(" "),
          event: {
            click: "onRedirectPiece"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }],
          attributes: {
            href: function (e) {
              if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
              }
            },
            "ng-reflect-router-link": function (e) {
              if (this.productVariantBatchPieceId != null || this.productVariantBatchPieceId != undefined) {
                return "/product-piece/" + this.productVariantBatchPieceId + "?activeTab=piece-info";
              }
            },
            onClick: "return false;"
          }
        },
        div: {
          isBind: (x) => {
            return x.imageUrl == "Total" ? true : false;
          },
          style: { "word-break": "break-all;", "font-weight": "bold" },
          attributes: {
            innerHTML: function (x, y) {
              return this.serialNo;
            }
          },
        }
      }
    ]
  },
};
