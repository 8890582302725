import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vCollectionBase {

//#region collectionName Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'collectionName', keyColumn: false})
        collectionName : string;
//#endregion collectionName Prop


//#region description Prop
        @gridColumn({visible: false, columnIndex:1, allowSorting: true, headerKey: 'description', keyColumn: false})
        description : string;
//#endregion description Prop


//#region metakeyword Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'metakeyword', keyColumn: false})
        metakeyword : string;
//#endregion metakeyword Prop


//#region metaTitle Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'metaTitle', keyColumn: false})
        metaTitle : string;
//#endregion metaTitle Prop


//#region published Prop
        @gridColumn({visible: true, columnIndex:4, allowSorting: true, headerKey: 'published', keyColumn: false,configuredTemplate: { templateName: "recordActive" }})
        published : any;
//#endregion published Prop


//#region viewByPeople Prop
        @gridColumn({visible: true, columnIndex:5, allowSorting: true, headerKey: 'viewByPeople', keyColumn: false,configuredTemplate: { templateName: "recordActive" }})
        viewByPeople : any;
//#endregion viewByPeople Prop


//#region inspirations Prop
        @gridColumn({visible: false, columnIndex:6, allowSorting: true, headerKey: 'inspirations', keyColumn: false})
        inspirations : string;
//#endregion inspirations Prop


//#region parentCollectionName Prop
        @gridColumn({visible: false, columnIndex:7, allowSorting: true, headerKey: 'parentCollectionName', keyColumn: false})
        parentCollectionName : string;
//#endregion parentCollectionName Prop

}