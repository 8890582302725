import {ProductOccassionMappingBase,ProductOccassionBase,ProductBase,} from '@app/database-models'
//Generated Imports
export class ProductOccassionMapping extends ProductOccassionMappingBase 
{




//#region Generated Reference Properties
//#region productOccassion Prop
productOccassion : ProductOccassionBase;
//#endregion productOccassion Prop
//#region product Prop
product : ProductBase;
//#endregion product Prop

//#endregion Generated Reference Properties




































}