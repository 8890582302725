import { HomeLayoutBase, HomeLayoutDetailBase, } from '@app/database-models'
import { propArray, prop } from '@rxweb/reactive-form-validators';
import { HomeLayoutDetail } from './home-layout-detail';
//Generated Imports
export class HomeLayout extends HomeLayoutBase {




    //#region Generated Reference Properties
    //#region homeLayoutDetails Prop
    @propArray(HomeLayoutDetail)
    homeLayoutDetails: HomeLayoutDetail[];
    //#endregion homeLayoutDetails Prop
    
    @prop()
    fileData: string;

    @prop()
    fileExtention: string;

    @prop()
    fileName: string;

    @prop()
    displayImage: boolean;

    @prop()
    imageURL1: string;
    //#endregion Generated Reference Properties







































}