export enum LeaveTypeEnum {
    AnnualHolidays = 1,
    MaternityLeaves = 2,
    PaternityLeaves = 3,
    WorkingFromHome = 4,
    SickLeave = 5,
    ReCoupOrLieuDays = 6,
    HolidayUnpaid = 7,
    TravelRequest = 8,
    StudyLeave = 9,
    ApprovedAbsence = 10,
    TimeOffForDependents = 11
}