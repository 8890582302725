import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class SpecificationAttributeOptionBase {

//#region specificationAttributeOptionId Prop
        @prop()
        specificationAttributeOptionId : number;
//#endregion specificationAttributeOptionId Prop


//#region specificationAttributeId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        specificationAttributeId : number;
//#endregion specificationAttributeId Prop


//#region name Prop
        @required()
        @maxLength({value:500})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        displayOrder : number;
//#endregion displayOrder Prop


//#region viewbyClients Prop
        @prop()
        viewbyClients : boolean;
//#endregion viewbyClients Prop


//#region description Prop
        @prop()
        description : string;
//#endregion description Prop


//#region inspirations Prop
        @prop()
        inspirations : string;
//#endregion inspirations Prop


//#region pictureId Prop
        @prop()
        pictureId : number;
//#endregion pictureId Prop


//#region pictureId2 Prop
        @prop()
        pictureId2 : number;
//#endregion pictureId2 Prop


//#region pictureId3 Prop
        @prop()
        pictureId3 : number;
//#endregion pictureId3 Prop


//#region totCollection Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        totCollection : number;
//#endregion totCollection Prop


//#region parentCollectionId Prop
        @prop()
        parentCollectionId : number;
//#endregion parentCollectionId Prop


//#region metaKeywords Prop
        @prop()
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @prop()
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @maxLength({value:400})
        metaTitle : string;
//#endregion metaTitle Prop


//#region videoUpload Prop
        @maxLength({value:200})
        videoUpload : string;
//#endregion videoUpload Prop


//#region published Prop
        @required()
        published : boolean;
//#endregion published Prop


//#region sEName Prop
        @prop()
        sEName : string;
//#endregion sEName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop





}