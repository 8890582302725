import { RxHttp, http } from "@rxweb/http";
import { IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { OwnershipMapping } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { Router } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
@http({
    path: 'api/OwnershipMappings'
})
export class AbstractOwnershipMapping extends RxHttp {
    ownershipMappingFormGroup: IFormGroup<OwnershipMapping>
    spin: boolean = false;
    showComponent: boolean = false;
    toastr: BaseToastr;
    dialog: BaseDialog;
    router: Router;
    modalView: ModalView;
    peopleOrCorporate: number = 0;
    dateError: boolean;
    corporateError: boolean = false;
    personError: boolean = false;
    constructor() {
        super();
        this.toastr = new BaseToastr();
        this.dialog = new BaseDialog();
    }


    changePeopleOrCorporate(peopleOrCorporate: any) {
        this.peopleOrCorporate = peopleOrCorporate;
        if (this.peopleOrCorporate == 0) {
            this.ownershipMappingFormGroup.controls.customerID.setValidators([RxwebValidators.required({ message: "Please select Person" })]);
            this.ownershipMappingFormGroup.controls.customerID.updateValueAndValidity();
            this.ownershipMappingFormGroup.controls.companyID.setValidators([]);
            this.ownershipMappingFormGroup.controls.companyID.updateValueAndValidity();
            this.ownershipMappingFormGroup.patchValue({
                companyID: 0,
                customerID: null
            })

        } else {
            this.ownershipMappingFormGroup.controls.companyID.setValidators([RxwebValidators.required({ message: "Please select Company" })]);
            this.ownershipMappingFormGroup.controls.companyID.updateValueAndValidity();
            this.ownershipMappingFormGroup.controls.customerID.setValidators([]);
            this.ownershipMappingFormGroup.controls.customerID.updateValueAndValidity();
            this.ownershipMappingFormGroup.patchValue({
                customerID: 0,
                companyID: null
            })
        }
    }

    badRequest = (data) => {
        this.spin = false;
        this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
        })
    }
}
