import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxwebValidators } from '@rxweb/reactive-form-validators';
import { AbstractPowerBIReport } from '../domain/abstract-power-bi-report';
import { Router } from '@angular/router';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { CoreComponent, access, } from '@rxweb/angular-router';
import { multilingual } from '@rxweb/localization';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { PowerBIReportEditComponent } from '../edit/power-bi-report-edit.component';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PowerBIReport } from "src/app/models/extended-models/power-bi-report";
@multilingual("PowerBIReportAddComponent")
@access({ accessLevel: RolePermissionEnums.PowerBIReports, action: "post" })

@Component({
  selector: "app-power-bi-report-add",
  templateUrl: './power-bi-report-add.component.html'
})
export class PowerBIReportAddComponent extends AbstractPowerBIReport implements OnInit, OnDestroy {
  powerBIReport : PowerBIReport;
  subscription: any;
  powerBIReportEdit: any = PowerBIReportEditComponent;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID: number;
  titleHelp: string;
  showSystemHelpIcon: boolean = false;
  _this;

  constructor(private router: Router, private formBuilder: RxFormBuilder, modelView: ModalView, private el: ElementRef, private title: Title) {
    super();
    this.modalView = modelView;
    this.moduleID = RolePermissionEnums.PowerBIReports;
    this._this = this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Add);
    this.title.setTitle("Power BI Reports - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");

    // this.lookup([{ params: [0], path: USER_LOOKUPS.parentHelpSupportLookUp, propName: "parentHelpSupportLookUp" }]).subscribe((lookup: any) => {
    //   this.spin = false;
    //   this.helpSupportLookups = lookup;
    // });

    this.powerBIReport = new PowerBIReport();
    this.powerBIReport.statusId = StatusEnum.Active;
    this.powerBIReport.powerBIReportId = 0;
    this.powerBiReportFormGroup = this.formBuilder.formGroup(this.powerBIReport) as IFormGroup<PowerBIReport>;
    
    this.powerBiReportFormGroup.controls.powerBIReportGUID.setValidators([
      RxwebValidators.required({ message: "You can't leave this field empty" }),
      RxwebValidators.pattern({ 
        expression: {'powerBIReportGUID': /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/ },
        message: 'GUID is not valid' })
    ]);

    this.showComponent = true;
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  addPowerBIReport(isRedirect) {
    this.powerBiReportFormGroup.submitted = true;
    if (this.powerBiReportFormGroup.valid) {
      this.spin = true;
      this.post({ body: this.powerBiReportFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Added");
        if (existsAlert) {
          this.toastr.success(existsAlert[0].showMessage);
        }
        isRedirect ? this.router.navigate(['/power-bi-reports', data]) : this.router.navigate(['/power-bi-reports']);
      })
    } else {
      for (const key of Object.keys(this.powerBiReportFormGroup.controls)) {
        if (this.powerBiReportFormGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
        }
      }
    }
  }

  get componentName(): string {
    return "PowerBIReportAddComponent";
  }
}
