import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup, IAbstractControl } from '@rxweb/reactive-form-validators';

//import { AllSetting } from '@app/models';
import { AbstractAllSetting } from '../domain/abstract-all-setting';
import { AllSettingViewModel, AllSettingFile } from '../../../../view-model/all-setting-view-model';
import { List } from '@rxweb/generics';
import { Setting } from '../../../../models/extended-models';
import { GLOBAL_ALL_SETTINGS_KEYS, INPUT_TYPE_ENUMS, GLOBAL_ALL_SETTINGS_TYPE } from '../../../../custom-collections/setting';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { CoreComponent, access } from '@rxweb/angular-router';
import { FormArray, FormGroup } from '@angular/forms';
import { FileOutputViewModel } from '../../../../view-model/file-output-view-model';
import { FileInputViewModel } from '../../../../view-model/file-input-view-model';
import { FileTypeEnums, FileSizeEnums } from '../../../../custom-enum/file-type-enums';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { SERVER_URL } from 'src/app/domain/system-constant';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { ModalView } from "src/app/domain/customize-design/modal";
import { getLocalizedValue, getSystemHelpStatus } from 'src/app/domain/common-logic/common-logic';
import * as FileSaver from 'file-saver';
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";

@multilingual("AllSettingAddComponent")
@access({ accessLevel: RolePermissionEnums.GlobalSettings, action: "get" })


@Component({
  selector: "app-all-setting-add",
  templateUrl: './all-setting-add.component.html'
})
export class AllSettingAddComponent extends AbstractAllSetting implements OnInit, OnDestroy {
  //allSetting: AllSetting;
  subscription: any;
  allSetting: Setting
  settings: List<Setting>
  showComponent: boolean = false;
  inputTypeEnum: any = INPUT_TYPE_ENUMS
  settingList: any[] = GLOBAL_ALL_SETTINGS_TYPE;
  settingKeyObj: any = GLOBAL_ALL_SETTINGS_TYPE;
  isShowSetting: boolean = false;
  fileViewModel: FileInputViewModel;
  selectedId: number = 0;
  data: any;
  selectedValue: boolean = false;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  moduleID:number;
  titleHelp:string;
  showSystemHelpIcon: boolean = false;


  _this;

  constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title) {
    super();
    this.settingList = this.settingList.filter(x=>x.key != 1)
    this.modalView = modalView;
    this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
    this.moduleID = RolePermissionEnums.GlobalSettings;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
    this.title.setTitle("Global Settings - G-Trac Admin");
    this.spin = false;
    this.titleHelp = getLocalizedValue("Title_Help");
    this.bindData();
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Add });
  }

  bindData(isLoad: boolean = false) {
    this.lookup([{ propName: "setting" }]).subscribe(response => {
      this.post({ path: "api/SearchAllSettings", body: { "query": "" } }).subscribe((data: any) => {
        this.settings = new List<Setting>(response["setting"], Setting)
        if (data) {
          this.settingLookups = data;
          this.settingLookups = this.settingLookups[0]
          this.stateLookup = this.settingLookups["stateProvinceLookUps"];
        }
        this.allSetting = new Setting();
        this.allSetting.description = "test";
        this.allSetting.name = "test";
        this.allSetting.settingID = 0;
        this.allSetting.statusId = 307;
        this.allSetting.value = "0";
        this.allSetting.allSettingViewModels = new Array<AllSettingViewModel>();
        this.showComponent = true;
        if (isLoad) {
          // this.spin=true;
          this.bindSetting(this.selectedId);
          // this.spin=false;
        }
      })
    })
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  bindSetting($event) {
    let id = 0;
    if ($event && $event.item && $event.item.key) {
      id = $event.item.key;
      this.selectedId = $event.item.key;
    }
    else {
      id = $event;
      this.selectedId = $event;
    }
    if (this.allSetting.allSettingViewModels.length > 0) {
      this.allSetting.allSettingViewModels = [];
    }
    GLOBAL_ALL_SETTINGS_TYPE.filter(x => x.key == id).forEach(data => {
      this.settingKeyObj = data;
    });
    GLOBAL_ALL_SETTINGS_KEYS.filter(x => x.settingType == id && x.active == true).forEach(data => {
      let allSettingViewModel = new AllSettingViewModel();
      let obj = this.settings.firstOrDefault(x => x.name == data.key)
      if (obj) {
        if (obj["settingID"] == 342) {
          console.log(obj);
        }
        allSettingViewModel.settingKeyName = obj["name"];
        allSettingViewModel.settingKeyId = obj["settingID"];
        allSettingViewModel.settingValue = obj["value"];
        allSettingViewModel.description = obj["description"];
        allSettingViewModel.type = data.type
        allSettingViewModel.statusId = 0;
        allSettingViewModel.localLizationKey = data.localLizationKey;
        allSettingViewModel.placeHolderLocalLizationKey = data.placeHolderLocalLizationKey;
        if (data.type == INPUT_TYPE_ENUMS.File) {
          let fileModel = new AllSettingFile();
          fileModel.settingKeyId = obj["settingID"];;
          fileModel.settingKeyName = obj["name"];
          fileModel.settingValue = SERVER_URL + obj["value"];
          allSettingViewModel.file = new Array<AllSettingFile>()
          allSettingViewModel.file.push(fileModel);
        }
        allSettingViewModel.dataKey = (String)(data.dataKey);
        this.allSetting.allSettingViewModels.push(allSettingViewModel);
      }
    })

    this.allSettingFormGroup = this.formBuilder.formGroup(this.allSetting) as IFormGroup<Setting>;
    this.isShowSetting = true;
    this.ngOnInit();
  }


  getLabel(settingName: string) {
    let settings = GLOBAL_ALL_SETTINGS_KEYS.find(x => x.key == settingName);
    if (settings) {
      return settings.label;
    }
  }

  getData(settingName: string) {
    let settings = GLOBAL_ALL_SETTINGS_KEYS.find(x => x.key == settingName);
    if (settings) {
      let test = this.settingLookups[settings.dataKey];
      //this.data = this.settingLookups[settings.dataKey];
      return this.settingLookups[settings.dataKey];
    }
  }

  updateSetting() {

    this.allSettingFormGroup.submitted = true;

    if (this.allSettingFormGroup.valid) {
      let allSettingViewModel = new AllSettingViewModel();
      allSettingViewModel.statusId = 307;
      allSettingViewModel.file = new Array<AllSettingFile>()
      if (this.allSettingFormGroup.controls.allSettingViewModels != null) {
        this.allSettingFormGroup.value.allSettingViewModels.forEach(data => {
          if (data.file != undefined) {
            allSettingViewModel.file.push(data.file[0]);
          }
        })
      }
      allSettingViewModel.settingJson = JSON.stringify(this.allSettingFormGroup.value.allSettingViewModels);
      this.spin = true;
      this.post({ body: allSettingViewModel }).subscribe((response: any) => {
        this.spin = false;
        this.allSettingFormGroup.submitted = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
        if (existsAlert) {
          //this.toastr.success("Data Updated Successfully")
          this.toastr.success(existsAlert[0].showMessage);
        }
        this.allSetting.allSettingViewModels = null;
        this.allSetting = null;
        this.isShowSetting = false;
        this.allSettingFormGroup = null;
        this.spin = true;
        setTimeout(() => {
          this.bindData(true);
          this.spin = false;
        }, 300)

        // this.ngOnInit();
      })
    }
  }

  fileFormArray(control: any, index: number): FormArray {
    let appointmentCustomeFormArray = this.allSettingFormGroup.controls["allSettingViewModels"] as IAbstractControl;
    let data = <FormArray>appointmentCustomeFormArray["controls"][index];//["file"];
    let file = data.controls["file"] as IAbstractControl;
    let test = <FormArray>file["controls"]
    return test;
  }

  updateFiles($event: FileOutputViewModel, file: FormGroup, settingName: string) {
    file.patchValue({ fileData: $event.fileBaseString, fileExtention: $event.fileExtension, fileName: $event.fileName });
  }

  fileType(settingName: string): FileInputViewModel {
    let file = new FileInputViewModel();
    let settings = GLOBAL_ALL_SETTINGS_KEYS.find(x => x.key == settingName);
    if (settings) {
      file.fileMode = settings.fileType;
      file.fileAllowSize = settings.fileSize;
      file.fileModeId = settings.fileType;
      file.filePlaceHolder = settings.customPlaceHolder;
    }
    return file;
  }
  stateBind(countryId: number) {
    let stateLookup = this.stateLookup.filter(x => x.countryId == countryId)
    this.settingLookups["stateProvinceLookUps"] = stateLookup;
    this.selectedValue = true;
  }

  generateSiteMap() {
    this.spin = true;
    this.get({ path:"api/SearchCommon/GenerateSiteMap" }).subscribe((response: any) => {
      this.spin = false;
      console.log(response);
      var myblob = new Blob([response.sitemap], {
        type: 'application/xml'
      });
      FileSaver.saveAs(myblob, 'SiteMap.xml');
    })
  }

  get componentName(): string {
    return "AllSettingAddComponent";
  }
}
