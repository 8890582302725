import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const CANCELACTION_GRID_TEMPLATE: TemplateConfig = {
    div: {
        childrens: [{
            a: {
                isBind: (x) => {
                    if (x.cancel == true) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
                event: {
                    click: "onCancel"
                },
                childrens: [{
                    i: {
                        class: "fa fa-window-close fa-4x font-md text-danger".split(" "),
                        attributes: { "title": getLocalizedValue("Title_Cancel") }
                    }
                }]
            }
        }]
    }
};