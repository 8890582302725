import {vTaxBasedOnLookUpBase,} from '@app/database-models'
//Generated Imports
export class vTaxBasedOnLookUp extends vTaxBasedOnLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}