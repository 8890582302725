import {POSDocumentBase,} from '@app/database-models'
//Generated Imports
export class POSDocument extends POSDocumentBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties





































}