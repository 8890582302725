import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ActivityLogBase {

//#region activityLogID Prop
        @prop()
        activityLogID : number;
//#endregion activityLogID Prop


//#region activityLogTypeID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        activityLogTypeID : number;
//#endregion activityLogTypeID Prop


//#region customerID Prop
        @prop()
        customerID : number;
//#endregion customerID Prop


//#region supplierID Prop
        @prop()
        supplierID : number;
//#endregion supplierID Prop


//#region comment Prop
        @required()
        @maxLength({value:4000})
        @notEmpty()
        comment : string;
//#endregion comment Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

//#region IpAddress Prop
@prop()
IpAddress : number;
//#endregion IpAddress Prop






}