import { maxLength, prop, range, required } from '@rxweb/reactive-form-validators';

export class EventRateMappingBase {

    //#region eventRateMappingId Prop
    @prop()
    eventRateMappingId: number;
    //#endregion eventRateMappingId Prop

    //#region eventId Prop
    @range({ minimumNumber: 1, maximumNumber: 2147483647 })
    @required()
    eventId: number;
    //#endregion eventId Prop

    //#region eventRatingId Prop
    @prop()
    eventRatingId: number;
    //#endregion eventRatingId Prop

    //#region feedBack Prop
    @maxLength({ value: 4000 })
    feedBack: string;
    //#endregion feedBack Prop

    //#region createdBy Prop
    @range({ minimumNumber: 0, maximumNumber: 2147483647 })
    @required()
    createdBy: number;
    //#endregion createdBy Prop

    //#region createdDate Prop
    @required()
    createdDate: Date;
    //#endregion createdDate Prop

    //#region modifiedBy Prop
    @prop()
    modifiedBy: number;
    //#endregion modifiedBy Prop

    //#region modifiedDate Prop
    @prop()
    modifiedDate: Date;
    //#endregion modifiedDate Prop
}