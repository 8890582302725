import { prop,propObject,propArray,required,maxLength,range ,notEmpty,numeric } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ChainLengthBase {

//#region lengthId Prop
        @prop()
        lengthId : number;
//#endregion lengthId Prop


//#region chainLengthName Prop
        @prop()
        @notEmpty()
        @numeric()
        @maxLength({value:10})
        chainLengthName : string;
//#endregion chainLengthName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}