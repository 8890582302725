import { prop, propObject, propArray, required, maxLength, range } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemStoneCutTypeBase {

        //#region cutTypeId Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 0, allowSorting: true, headerKey: 'GemStoneCutTypeListComponent_CutTypeId_gh', keyColumn: true })
        cutTypeId: number;
        //#endregion cutTypeId Prop


        //#region cutTypeName Prop
        @gridColumn({ style: { width: "50%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 1, isAscending: true, allowSorting: true, headerKey: 'GemStoneCutTypeListComponent_CutTypeName_gh', keyColumn: false })
        cutTypeName: string;
        //#endregion cutTypeName Prop

}