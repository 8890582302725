import {vNoteTypeLookUpBase,} from '@app/database-models'
//Generated Imports
export class vNoteTypeLookUp extends vNoteTypeLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}