<div class="row wrapper white-bg page-heading py-2 align-items-center" [rxLocalisationInit]="componentName">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_Regions_t"></li>
    </ol>
  </div>
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>

<div class="wrapper wrapper-content" [rxSpinner]="spin" [rxLocalisationInit]="componentName">

  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white pr-3">
      <h5><label rxText="Label_Regions_t"></label>
      </h5>
      <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
        <i class="fa fa-question-circle fa-2x"></i>
      </a>

    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="dataTables_filter  d-flex flex-wrap align-items-center">
        <ng-container [rxLocalisationInit]="componentName" *rxAuthorize="[regionAdd]">
          <a [routerLink]="['/regions/add']" class="btn btn-success btn-sm mb-3" rxText="Btn_Add_New_t"></a>
        </ng-container>
        <label class="ml-auto mr-3 mb-3">
          <input type="search" (keyup.enter)="search($event)" class=" table_search form-control form-control-sm pr-4"
            placeholder="" [title]="titleSearch">
        </label>
        <div class="ibox-tools mb-4 pb-1" style="position: relative;">
          <a class="dropdown-toggle" (click)="showGridColumn()" [title]="titleGridColumnSetting">
            <i class="fa fa-cog"></i>
          </a>
          <app-system-table-setting [componentId]="'RegionListComponent'" *ngIf="isShowGridColumn"
            (gridColumnSettings)="applyVisible($event)" (hideGridColumnComponent)="showGridColumn()"
            [gridColumnConfig]="regionsGrid.gridColumns" [isShowGridColumn]="isShowGridColumn" [moduleID]="moduleID">
          </app-system-table-setting>
        </div>
      </div>
      <div class="table-responsive">
        <div id="regions" class="dataTables_wrapper pb-0 dt-bootstrap4">

        </div>
      </div>

    </div>
  </div>

</div>
