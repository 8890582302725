import { maxLength, prop } from "@rxweb/reactive-form-validators";

export class LeaveAccomodationMappingBase
{

    //#region LAM_MapppingID Prop
    @prop()
    laM_MapppingID : number;
    //#endregion LAM_MapppingID Prop


    //#region masterId Prop
    //@range({minimumNumber:0,maximumNumber:2147483647})
    //@required()
    @prop()
    masterId : number;
    //#endregion masterId Prop

    //#region AccomodationPreferenceID Prop
    //@range({minimumNumber:0,maximumNumber:2147483647})
    //@required()
    @prop()
    accomodationPreferenceID : number;
    //#endregion AccomodationPreferenceID Prop

    @prop()
    reasonID : number;


    //#region OtherReason Prop
    @prop()
    otherReason : string;
    //#endregion OtherReason Prop

     @prop()
     checkInDate :Date; 

     @prop()
     checkInTime?:any;
 
     @prop()
     checkOutDate :Date; 

     @prop()
     noOfDays : number;
    

}

