import { Component, OnInit, OnDestroy } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractSystemHelp } from '../domain/abstract-system-help';
import { Subscription } from 'rxjs';
import { AppGrid } from '../../../../domain/app-grid';
import { Router } from '@angular/router';
import { ModalView } from '../../../../domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser';
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { access } from '@rxweb/angular-router';
import { SystemHelpAddComponent } from '../add/system-help-add.component';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { vSystemHelpBase } from "src/app/models/database-models/v-system-help-base";
import { dbAccessModule } from "src/app/domain/security/authorization-resolver";
import { SystemHelpViewComponent } from "../view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import * as moment from "moment";
import { BLOB_CONTAINER, BLOB_PUBLIC_URI } from "src/app/domain/system-constant";

@access({ accessLevel: RolePermissionEnums.SystemHelp, action: "get" })
@multilingual("SystemHelpListComponent")

@Component({
    selector: "app-system-help-list",
    templateUrl: './system-help-list.component.html'
})
export class SystemHelpListComponent extends AbstractSystemHelp implements OnInit, OnDestroy {
    systemHelpList: vSystemHelpBase[];
    parentModulesList: List<any> = new List<any>();
    subscription: any;
    systemHelpGrid: AppGrid;
    systemHelpGridColumns: any[];
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    isNoRecordFound: boolean = false;
    moduleID:number;
    titleHelp:string;
    titleExpand:string;
    titleCollapse:string;
    titleEdit:string;
    exportDoc = new ExportToDocModel();
    titleToggle:string[]= [];
    // distinctModuleList: List<vSystemHelpBase> = new List<vSystemHelpBase>();
    showSystemHelpIcon: boolean = false;

    constructor(private router: Router, modalView: ModalView, private applicationBroadcaster: ApplicationBroadcaster, private title: Title) {
        super();
        this.modalView = modalView;
        this.applicationBroadcaster.activeMenu({ activeMenu: 'system', subMenu: 'system' })
        this.hasAddAccess = dbAccessModule[RolePermissionEnums.SystemHelp]["post"];
        this.hasEditAccess = dbAccessModule[RolePermissionEnums.SystemHelp]["put"];
        this.moduleID = RolePermissionEnums.SystemHelp;
    }


    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Search);
        this.title.setTitle("System Help - G-Trac Admin");
        this.titleExpand= getLocalizedValue("Title_Expand");
        this.titleCollapse=getLocalizedValue("Title_Collapse");
        this.titleEdit=getLocalizedValue("Title_Edit");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.bindGrid();
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Search });
    }
    
    bindGrid() {
        this.spin = true;
        this.subscription = this.get().subscribe((t: vSystemHelpBase[]) => {
            this.spin = false;
            this.systemHelpList = t;

            this.parentModulesList.addRange(t);
            this.parentModulesList = this.parentModulesList.distinctBy(y => y.parentApplicationModuleId);

            for (let index = 0; index < this.parentModulesList.length; index++) {
                this.titleToggle.push(this.titleCollapse);           
            }

            this.parentModulesList.forEach(t => t.isCollapse = true);
            this.showComponent = true;
        })
    }

    onEdit(systemHelp: vSystemHelpBase) {
        let url : any;
        url = this.router.serializeUrl(this.router.createUrlTree(["system-help", systemHelp.moduleMasterId]));
        redirectOnCTRL(url,this.router,event);
        //this.router.navigate(["system-help", systemHelp.moduleMasterId]);
    }

    collapseDiv(divObj: any, index: number) {
        if (divObj.isCollapse == false) {
            this.titleToggle[index]=this.titleCollapse;
            this.parentModulesList.where(t => t.parentApplicationModuleId == divObj.parentApplicationModuleId).firstOrDefault().isCollapse = true;
        }
        else {
            this.titleToggle[index]=this.titleExpand;
            this.parentModulesList.where(t => t.parentApplicationModuleId == divObj.parentApplicationModuleId).firstOrDefault().isCollapse = false;
        }
    }

    filterModules(parentApplicationModuleId: number) {
        return this.systemHelpList.filter(x => x.parentApplicationModuleId == parentApplicationModuleId);
    }

    exportDocument() {
        this.spin = true;
        var TodayDate = new Date();
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();
        this.exportDoc.FileName = "SystemHelp_" + MMDDYY;
        this.exportDoc.Description = 'System Help';
        this.exportDoc.Type = 'Document';
        this.post({ body: this.exportDoc, path: "api/SearchUsers/ExportDocx" }).subscribe(Response => {
            this.spin = false;
            if(Response != null)
                window.location.href = Response.toString();
        })
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get componentName(): string {
        return "SystemHelpListComponent";
    }

}

class ExportToDocModel {
    Result: string;
    Type: string;
    FileName: string;
    Description: string;
}
