import {CreditCardTypeBase,} from '@app/database-models'
//Generated Imports
export class CreditCardType extends CreditCardTypeBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties












}