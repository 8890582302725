import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vPaymentMethodTypeLookUpBase {

//#region paymentMethodTypeId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'paymentMethodTypeId', keyColumn: true})
        paymentMethodTypeId : number;
//#endregion paymentMethodTypeId Prop


//#region paymentMethodTypeName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'paymentMethodTypeName', keyColumn: false})
        paymentMethodTypeName : string;
//#endregion paymentMethodTypeName Prop

}