import { Component, OnInit, OnDestroy, Input, EventEmitter } from "@angular/core"
import { Subscription } from 'rxjs';
import { DatePipe, PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { multilingual } from '@rxweb/localization';
import { CoreComponent } from '@rxweb/angular-router';
import { AppGrid } from 'src/app/domain/app-grid';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import { PaginationViewModel } from "src/app/view-model/pagination-view-model";
import { ProjectRiskListViewModel } from "src/app/models/extended-models/project-risk-view-model";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { GridColumnConfig } from "@rxweb/grid";
import { getLocalizedValue, redirectOnCTRL } from 'src/app/domain/common-logic/common-logic';
import { ModalView } from "src/app/domain/customize-design/modal";
import { ProjectRiskAddComponent } from "../add/project-risk-add.component";
import { BaseDialog, DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";

@multilingual("ProjectRiskListComponent")
@Component({
  selector: "app-project-risk-list",
  templateUrl: './project-risk-list.component.html'
})
export class ProjectRiskListComponent extends CoreComponent implements OnInit, OnDestroy {
  showComponent: boolean = false;
  subscription: any;
  paginationViewModel: PaginationViewModel;
  @Input() projectId: number;
  @Input() entityTypeId: number;
  gemfieldRiskProjectGrid: AppGrid;
  isShowGridColumn: boolean = false;
  id: number;
  json: any;
  isFilter: boolean = false;
  isShowGrid: boolean;
  showGrid: boolean = false;
  isNoRecordFound: boolean = false;
  isShowSearch : boolean = false;
  totalRecords: number = 0;
  modalView: ModalView;
  spin: boolean = false;
  riskProjectMappingList: ProjectRiskListViewModel[];
  @Input() selectedTab: string = "";
  @Input() moduleID: any;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<GridColumnConfig[]>();
  getColumnsData: any;
  arrConfig: any[];
  isHideAll: boolean = false;
  isShowAll: boolean = false;
  titleSearch: string;
  titleGridColumnSetting: string;
  isLongTextExists: boolean = false;

  toastr: BaseToastr;
  dialog: BaseDialog;
  constructor(private applicationBroadcaster: ApplicationBroadcaster, private router: Router, modal: ModalView, private formBuilder: RxFormBuilder, private browserStorage: BrowserStorage, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private location : PlatformLocation) {
    super();
    this.modalView = modal;
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    });

    this.dialog = new BaseDialog();
    this.toastr = new BaseToastr();
    this.router = router;
    this.paginationViewModel = new PaginationViewModel();
    this.paginationViewModel.sortOrder = "true";
    this.paginationViewModel.orderBy = "riskMenuDetailId";
    location.onPopState(() => this.dialog.hide());
  }

  ngOnInit(): void {
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.isFilter = false;
    this.totalRecords = 0;
    this.json = JSON.parse(JSON.stringify(this.paginationViewModel));
    this.json["projectId"] = this.projectId;
    //this.json["clientUrl"] = SERVER_URL;
    this.json["userId"] = 0;
    this.json["moduleId"] = this.entityTypeId;
    this.showComponent = true;

    this.bindGrid();
  }

  AddRisk() {
    this.modalView.show(ProjectRiskAddComponent, { mapId: this.projectId, entityTypeId: this.entityTypeId }).then(t => {
      this.isFilter = true;
      this.totalRecords = 0;
      this.gemfieldRiskProjectGrid.updateSource([]);
      this.bindGrid();
    });
  }

  bindGrid(isOnload: boolean = true) {
    this.spin = true;
    this.showGrid = true;
    this.isShowGrid = true;
    this.totalRecords = 0;

    this.get({
      path: 'api/SearchCommon/GetGridColumns', queryParams: { "moduleID": encodeURIComponent(JSON.stringify(this.moduleID)), "childTabs": this.selectedTab }
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (this.getColumnsData.result != null && this.getColumnsData.result != undefined) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }


      this.subscription = this.post({ path: "api/SearchProject/ProjectRiskList", body: { "query": JSON.stringify(this.json) } }).subscribe((t: ProjectRiskListViewModel[]) => {
        this.spin = false;
        if (this.gemfieldRiskProjectGrid) {
          this.gemfieldRiskProjectGrid.storeProcedure.length = 0;
        }
        this.riskProjectMappingList = t;
        // if (isOnload) {
        //   for (let element of this.riskProjectMappingList) {
        //     if (element.noteCharCount > 100) {
        //       this.isShowAll = true;
        //       break;
        //     }
        //   }
        // }
        if (this.riskProjectMappingList.length > 0) {
          this.totalRecords = this.riskProjectMappingList[0].totalCount;
          this.isNoRecordFound = false;
          if(isOnload && this.totalRecords > 0){
            this.isShowSearch = true;
          }
        }
        else {
          this.isNoRecordFound = true;
          this.isShowGrid = false;
        }
        if (!this.isFilter) {
          this.gemfieldRiskProjectGrid = new AppGrid(this.riskProjectMappingList, ProjectRiskListViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.onRiskIdClik.bind(this), onDelete: this.onDelete.bind(this),onCorporateGroupLink:this.onCorporateGroupLink.bind(this) } });
          if (this.arrConfig != undefined && this.arrConfig != null) {
            this.arrConfig.forEach(x => {
              this.gemfieldRiskProjectGrid.gridColumns.forEach(y => {
                if (y.name == x.ColumnName)
                  y.visible = x.Visible;
              });
            });
          }
          this.gemfieldRiskProjectGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this)
          }
          this.checkForLongTexts()
          // this.gemfieldRiskProjectGrid.gridColumns.forEach(x => {
          //   if (x.columnIndex == 1) {
          //     x.visible = false;
          //   }
          // });
        }
        else {
          this.gemfieldRiskProjectGrid.storeProcedure.length = this.totalRecords;
          this.gemfieldRiskProjectGrid.updateSource([]);
          this.gemfieldRiskProjectGrid.updateSource(this.riskProjectMappingList)
          this.checkForLongTexts()
        }

        this.gemfieldRiskProjectGrid.designClass.tableClass.push("table-list");


        this.gemfieldRiskProjectGrid.maxPerPage = this.json["rowCount"];
        this.gemfieldRiskProjectGrid.componentId = this.componentName;
        this.gemfieldRiskProjectGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
        this.gemfieldRiskProjectGrid.designClass.headerClass = "table-header-sticky".split(" ");
        setTimeout(() => {
          let riskIndexTarget = 0;
          let riskIndexColFound = false;
          this.gemfieldRiskProjectGrid.gridColumns.find((item) => {
            if (item.visible) {
              if (item.name === 'riskIndex') {
                riskIndexColFound = true;
                return riskIndexTarget
              }
              else
                riskIndexTarget += 1
            }
          })

          if (riskIndexColFound) {
            this.riskProjectMappingList.forEach(x => {
              let element = this.gemfieldRiskProjectGrid.getRowElement(x.projectRiskMappingId);
              let riskIndex = x.likelihood * x.impact * 4;
              if (riskIndex >= 77) {
                element.children[riskIndexTarget].style.backgroundColor = "red";
              }
              if (riskIndex <= 25) {
                element.children[riskIndexTarget].style.backgroundColor = "lightGreen";
              }
              if (riskIndex >= 26 && riskIndex <= 51) {
                element.children[riskIndexTarget].style.backgroundColor = "yellow";
              }
              if (riskIndex >= 52 && riskIndex <= 76) {
                element.children[riskIndexTarget].style.backgroundColor = "Orange";
              }
            })
          }
        }, 0);

      })
    });
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.json["sortOrder"] = y;
    this.json["orderBy"] = x;
    this.gemfieldRiskProjectGrid.storeProcedure.nextPage = z;
    this.bindGrid(false);
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.json["pageIndex"] = x;
    this.json["rowCount"] = this.gemfieldRiskProjectGrid.maxPerPage;
    this.gemfieldRiskProjectGrid.storeProcedure.nextPage = x;
    this.bindGrid(false);
  }

  search(value) {
    this.spin = false;
    this.isFilter = true;
    this.totalRecords = 0;
    this.json['searchString'] = value.target.value.trim();
    if (this.gemfieldRiskProjectGrid != null) {
      this.gemfieldRiskProjectGrid.updateSource([]);
      this.json["pageIndex"] = 1;
      this.gemfieldRiskProjectGrid.storeProcedure.nextPage = 1;
      //this.json["rowCount"] = this.gemfieldRiskProjectGrid.maxPerPage;
    }
    this.bindGrid(false);
  }

  onMoreClick(t, x) {
    var element = document.getElementById("note_" + t.noteId);
    var maskedElement = document.getElementById("maskednote_" + t.noteId);
    if (element.className == "collapse") {
      x.srcElement.innerText = "Show Less";
      x.srcElement.className = "less-btn"
      element.classList.add("show");
      maskedElement.setAttribute("style", "display:none");
    }
    else {
      element.classList.remove("show");
      x.srcElement.innerText = "Show More";
      x.srcElement.className = "more-btn";
      maskedElement.setAttribute("style", "display:inline");
    }
  }

  onMoreClickAll() {
    this.isFilter = true;
    //this.isShowGrid=true;
    this.gemfieldRiskProjectGrid.destroy();
    this.gemfieldRiskProjectGrid = new AppGrid(this.riskProjectMappingList, ProjectRiskListViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.onRiskIdClik.bind(this) } });

    this.gemfieldRiskProjectGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: 1,
      onPageSorting: this.onPageSorting.bind(this)
    }
    this.gemfieldRiskProjectGrid.designClass.tableClass.push("table-list");
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(x => {
        this.gemfieldRiskProjectGrid.gridColumns.forEach(y => {
          if (y.name == x.ColumnName)
            y.visible = x.Visible;
        });
      });
    }

    this.gemfieldRiskProjectGrid.gridColumns.forEach(z => {
      if (z.name == 'noteDescription') {
        z.visible = false
        //z.parameter=true
      }
      if (z.name == 'noteDescription1') {
        z.visible = true
        //z.parameter=false
      }
    })

    this.gemfieldRiskProjectGrid.maxPerPage = this.json["rowCount"];
    this.gemfieldRiskProjectGrid.componentId = this.componentName;
    this.gemfieldRiskProjectGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
    this.gemfieldRiskProjectGrid.designClass.headerClass = "table-header-sticky".split(" ");
    setTimeout(() => {
      let riskIndexTarget = 0;
      let riskIndexColFound = false;
      this.gemfieldRiskProjectGrid.gridColumns.find((item) => {
        if (item.visible) {
          if (item.name === 'riskIndex') {
            riskIndexColFound = true;
            return riskIndexTarget
          }
          else
            riskIndexTarget += 1
        }
      })

      if (riskIndexColFound) {
        this.riskProjectMappingList.forEach(x => {
          let element = this.gemfieldRiskProjectGrid.getRowElement(x.projectRiskMappingId);
          let riskIndex = x.likelihood * x.impact * 4;
          if (riskIndex >= 77) {
            element.children[riskIndexTarget].style.backgroundColor = "red";
          }
          if (riskIndex <= 25) {
            element.children[riskIndexTarget].style.backgroundColor = "lightGreen";
          }
          if (riskIndex >= 26 && riskIndex <= 51) {
            element.children[riskIndexTarget].style.backgroundColor = "yellow";
          }
          if (riskIndex >= 52 && riskIndex <= 76) {
            element.children[riskIndexTarget].style.backgroundColor = "Orange";
          }
        })
      }
    }, 0);
    //this.isShowAll = false;
    this.isHideAll = true;
  }

  onLessClickAll() {
    this.gemfieldRiskProjectGrid.destroy();
    this.gemfieldRiskProjectGrid = new AppGrid(this.riskProjectMappingList, ProjectRiskListViewModel, { actions: { onMoreClick: this.onMoreClick.bind(this), onOneLink: this.onRiskIdClik.bind(this) } });
    this.gemfieldRiskProjectGrid.storeProcedure = {
      length: this.totalRecords,
      onPageChanging: this.onPageChanging.bind(this),
      nextPage: 1,
      onPageSorting: this.onPageSorting.bind(this)
    }
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(x => {
        this.gemfieldRiskProjectGrid.gridColumns.forEach(y => {
          if (y.name == x.ColumnName)
            y.visible = x.Visible;
        });
      });
    }
    this.gemfieldRiskProjectGrid.designClass.tableClass.push("table-list");
    this.gemfieldRiskProjectGrid.gridColumns.forEach(z => {
      if (z.name == 'noteDescription') {
        z.visible = true
        //z.parameter=true
      }
      if (z.name == 'noteDescription1') {
        z.visible = false
        //z.parameter=false
      }
    })

    this.gemfieldRiskProjectGrid.maxPerPage = this.json["rowCount"];
    this.gemfieldRiskProjectGrid.componentId = this.componentName;
    this.gemfieldRiskProjectGrid.designClass.tableClass = "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive table-list".split(" ");
    this.gemfieldRiskProjectGrid.designClass.headerClass = "table-header-sticky".split(" ");
    setTimeout(() => {
      let riskIndexTarget = 0;
      let riskIndexColFound = false;
      this.gemfieldRiskProjectGrid.gridColumns.find((item) => {
        if (item.visible) {
          if (item.name === 'riskIndex') {
            riskIndexColFound = true;
            return riskIndexTarget
          }
          else
            riskIndexTarget += 1
        }
      })

      if (riskIndexColFound) {
        this.riskProjectMappingList.forEach(x => {
          let element = this.gemfieldRiskProjectGrid.getRowElement(x.projectRiskMappingId);
          let riskIndex = x.likelihood * x.impact * 4;
          if (riskIndex >= 77) {
            element.children[riskIndexTarget].style.backgroundColor = "red";
          }
          if (riskIndex <= 25) {
            element.children[riskIndexTarget].style.backgroundColor = "lightGreen";
          }
          if (riskIndex >= 26 && riskIndex <= 51) {
            element.children[riskIndexTarget].style.backgroundColor = "yellow";
          }
          if (riskIndex >= 52 && riskIndex <= 76) {
            element.children[riskIndexTarget].style.backgroundColor = "Orange";
          }
        })
      }
    }, 0);
    //this.isShowAll = true;
    this.isHideAll = false;
  }



  onRiskIdClik(risk: ProjectRiskListViewModel) {
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["gemfield-risk-menu", risk.riskMenuDetailId]));
    redirectOnCTRL(url, this.router, event);
    //this.router.navigate(["gemfield-risk-menu", risk.riskMenuDetailId]);
  }

  onCorporateGroupLink(risk: ProjectRiskListViewModel) {
    let url: any;
    url = this.router.serializeUrl(this.router.createUrlTree(["suppliers", risk.companyId],{ queryParams: { "activeTab": "supplier-edit" }}));
    redirectOnCTRL(url, this.router, event);
  }


  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    if (this.arrConfig != undefined && this.arrConfig != null) {
      this.arrConfig.forEach(x => {
        gridColumnConfig.forEach(y => {
          if (y.name == x.ColumnName)
            x.Visible = y.visible;
        });
      });
    }
    this.checkForLongTexts()
    this.gemfieldRiskProjectGrid.reDesign();
    this.gemfieldRiskProjectGrid.updateSource([])
    this.gemfieldRiskProjectGrid.updateSource(this.riskProjectMappingList);
    setTimeout(() => {
      let riskIndexTarget = 0;
      let riskIndexColFound = false;
      this.gemfieldRiskProjectGrid.gridColumns.find((item) => {
        if (item.visible) {
          if (item.name === 'riskIndex') {
            riskIndexColFound = true;
            return riskIndexTarget
          }
          else
            riskIndexTarget += 1
        }
      })
      if (riskIndexColFound) {
        this.riskProjectMappingList.forEach(x => {
          let element = this.gemfieldRiskProjectGrid.getRowElement(x.projectRiskMappingId);
          let riskIndex = x.likelihood * x.impact * 4;
          if (riskIndex >= 77) {
            element.children[riskIndexTarget].style.backgroundColor = "red";
          }
          if (riskIndex <= 25) {
            element.children[riskIndexTarget].style.backgroundColor = "lightGreen";
          }
          if (riskIndex >= 26 && riskIndex <= 51) {
            element.children[riskIndexTarget].style.backgroundColor = "yellow";
          }
          if (riskIndex >= 52 && riskIndex <= 76) {
            element.children[riskIndexTarget].style.backgroundColor = "Orange";
          }

        })
      }
    }, 0);
  }
  checkForLongTexts() {
    this.isLongTextExists = false;
    let isDescriptionCheckRequired = false;
    this.gemfieldRiskProjectGrid.gridColumns.forEach(column => {
      if (column.name == "noteDescription" && column.visible) {
        isDescriptionCheckRequired = true;
      }

    });
    if (isDescriptionCheckRequired) {
      for (let element of this.riskProjectMappingList) {
        if (element.noteCharCount > 100 && isDescriptionCheckRequired) {
          this.isLongTextExists = true;
          break;
        }
      }
    }
  }
  showOrHideAll() {
    this.isShowAll = !this.isShowAll;
    this.isFilter = false;
    this.gemfieldRiskProjectGrid.destroy();
    this.gemfieldRiskProjectGrid = null;
    this.json["isShowAll"] = this.isShowAll;
    this.json["pageIndex"] = 1;
    this.bindGrid();
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  onDelete(element: ProjectRiskListViewModel) {
    this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
      if (t === DialogEnum.Ok) {
        this.dialog.hide();
        this.spin = true;
        this.delete({ path: "api/SearchProject/deleteRiskEventGinaPressProject", body: { entityTypeId: this.entityTypeId, entityId: this.id, id: element.riskMenuDetailId } }).subscribe(response => {
          if (response == 1) {
            var existsAlert = getLocalizedValue("Data_Deleted");
            if (existsAlert) {
              this.toastr.success(existsAlert);
            }
            this.spin = false;
            this.isFilter = true;
            this.totalRecords = 0;
            this.gemfieldRiskProjectGrid.updateSource([]);
            this.isShowSearch  = false;
            this.bindGrid();
          }
          else {
            this.spin = false;
          }
        });
      }
      this.dialog.hide()
    })
  }
  DeleteConfirmationMessage() {
    var existsAlert = getLocalizedValue("Delete_Confirmation_Project_Risk");
    if (existsAlert) {
      return existsAlert;
    }
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
  get componentName(): string {
    return "ProjectRiskListComponent";
  }
}
