import {HolidayMappingBase,HolidayListBase,} from '@app/database-models'
//Generated Imports
export class HolidayMapping extends HolidayMappingBase 
{




//#region Generated Reference Properties
//#region holidayList Prop
holidayList : HolidayListBase;
//#endregion holidayList Prop

//#endregion Generated Reference Properties












}