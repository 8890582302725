import { Component, OnInit, OnDestroy, ElementRef } from "@angular/core"
import { AbstractSetting } from '../domain/abstract-setting';

import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Setting } from '@app/models';
import { CoreComponent, access,  } from '@rxweb/angular-router';
import { DialogViewMode } from '@rxweb/js';
import { DialogEnum } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BrowserModule, Title } from '@angular/platform-browser'; 
import { multilingual } from '@rxweb/localization';
import { RolePermissionEnums } from 'src/app/custom-enum/role-permission-enums';
import { dbAccessModule } from 'src/app/domain/security/authorization-resolver';
import { ApplicationBroadcaster } from 'src/app/temp-service/application-broadcaster';
import * as CryptoJS from 'crypto-js';
import { SystemHelpViewComponent } from "../../system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { getLocalizedValue, getSystemHelpStatus } from "src/app/domain/common-logic/common-logic";
import { PlatformLocation } from "@angular/common";
@multilingual("SettingEditComponent")
@access({ accessLevel: RolePermissionEnums.AllSettings, action: "put" })

@Component({
    selector: "app-setting-edit",
    templateUrl: './setting-edit.component.html'
})
export class SettingEditComponent extends AbstractSetting implements OnInit, OnDestroy {
    setting: Setting;
    subscription: any;
    id: number;
    localizedMessages: any;
    decryptstring: any;
    Jsonstring: any;
    settingDelete:any=dbAccessModule[RolePermissionEnums.AllSettings]["delete"];
    moduleID: number;
    titleHelp: string;
    showSystemHelpIcon: boolean = false;
    
    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute,private applicationBroadcaster:ApplicationBroadcaster,private router:Router,modalView:ModalView, private el: ElementRef,private title: Title,private location : PlatformLocation) {
        super();
        this.modalView=modalView;
        this.applicationBroadcaster.activeMenu({activeMenu:'system',subMenu:'system'})
        this.activatedRoute.params.subscribe(t => {
            this.id = t['id'];
        })
        this.moduleID = RolePermissionEnums.AllSettings;
        location.onPopState(() => this.dialog.hide());
    }

    ngOnInit(): void {
        this.showSystemHelpIcon = getSystemHelpStatus(this.moduleID, SystemHelpPage.Edit);
        this.title.setTitle("All Settings - G-Trac Admin");
        this.titleHelp = getLocalizedValue("Title_Help");
        this.get({ params: [this.id] }).subscribe(t => {
            this.settingFormGroup = this.formBuilder.formGroup(Setting,t) as IFormGroup<Setting>;
            this.showComponent=true;
            
        })
        this.decryptstring = localStorage.getItem('AllLocalizedMessages');
        this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
        this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));

    }

    showSystemHelp() {
        this.modalView.show(SystemHelpViewComponent, { moduleId: this.moduleID, systemHelpPageId: SystemHelpPage.Edit });
    }


    editSetting(isRedirect) {
        this.settingFormGroup.submitted = true;
        if (this.settingFormGroup.valid) {
            this.spin=true;
            this.put({ body: this.settingFormGroup.value, params: [this.id] }).subscribe(data => {
                this.spin=false;
                var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
                    if (existsAlert) {
					  //this.toastr.success("Data Updated Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                isRedirect ? this.ngOnInit() : this.router.navigate(['/settings']);
            })
        }else{
            for (const key of Object.keys(this.settingFormGroup.controls)) {
                if (this.settingFormGroup.controls[key].invalid) { 
                  const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
                  invalidControl.focus();
                  break;
               }
            }
        }
    }

    deleteSetting() {
        this.dialog.show(this.DeleteConfirmationMessage(), DialogViewMode.okWithCancel).then(t => {
            if(t === DialogEnum.Ok)
            {
                this.dialog.hide();
                this.spin=true;
                this.delete({ body: null, params: [this.id] }).subscribe(data => {
                    this.spin=false;
                    var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Deleted");
                    if (existsAlert) {
					//this.toastr.success("Data Deleted Successfully")
                        this.toastr.success(existsAlert[0].showMessage);
                    }
                    this.router.navigate(['/settings']);
                })
            }
            this.dialog.hide()
        })
    }
    DeleteConfirmationMessage() {
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Delete_Confirmation_Setting");
        if (existsAlert) {
            return existsAlert[0].showMessage;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "SettingEditComponent";
    }
}
