import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BangleSizeBase {

//#region id Prop
        @prop()
        id : number;
//#endregion id Prop


//#region size Prop
        @required()
        @notEmpty()
        @maxLength({value:100})
        @trim()
        size : string;
//#endregion size Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}