import { prop,propObject,propArray,required,maxLength,range ,notEmpty,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductAttributeBase {

//#region productAttributeId Prop
        @prop()
        productAttributeId : number;
//#endregion productAttributeId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        @trim()
        @notEmpty()
        name : string;
//#endregion name Prop


//#region description Prop
        @required()
        @maxLength({value:400})
        @trim()
        @notEmpty()
        description : string;
//#endregion description Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region parentProductAttributeId Prop
        @prop()
        parentProductAttributeId : number;
//#endregion parentProductAttributeId Prop



}