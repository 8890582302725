import {TopicLocalizationBase,LanguageBase,TopicBase,} from '@app/database-models'
//Generated Imports
export class TopicLocalization extends TopicLocalizationBase 
{




//#region Generated Reference Properties
//#region language Prop
language : LanguageBase;
//#endregion language Prop
//#region topic Prop
topic : TopicBase;
//#endregion topic Prop

//#endregion Generated Reference Properties






































}