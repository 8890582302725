<div class="modal-dialog modal-lg" [rxSpinner]="spin" *rxMultilingual="">
  <div class="modal-content" [rxLocalisationInit]="componentName">
    <div class="modal-header text-left">
      <h4 class="modal-title mb-0" rxText="ErrorUpdatePiecesFromExcelComponent_Title_t"></h4>
      <button type="button" class="close py-2" (click)="hide()" [title]="titleClose">
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="showComponent">
      <div class="row mx-0">
        <h3 rxText="ErrorUpdatePiecesFromExcelComponent_Summary_t"></h3>
        <div class="table-responsive dataTables_wrapper pb-0 dt-bootstrap4">
          <table class="d-table table table-striped table-hover dataTables-example dataTable text-center border-bottom">
            <thead>
              <tr>
                <th rxText="ErrorUpdatePiecesFromExcelComponent_TotalPiece_t"></th>
                <th rxText="ErrorUpdatePiecesFromExcelComponent_UpdatedPiece_t"></th>
                <th rxText="ErrorUpdatePiecesFromExcelComponent_FailedPiece_t"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  {{totalPieces}}
                </td>
                <td class="text-center">
                  {{totalPieces - failedPiecesList.length}}
                </td>
                <td class="text-center">
                  {{failedPiecesList.length}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer px-0">
      <button class="btn btn-primary mr-3" (click)="ExportToExcelErrorData()"
        rxText="ErrorUpdatePiecesFromExcelComponent_Download_t"></button>
    </div>
  </div>
</div>
