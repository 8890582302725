import { prop,propObject,propArray,required,maxLength,range, notEmpty  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemstoneOrderBase {

//#region gemStoneOrderID Prop
        @prop({defaultValue:0})
        gemStoneOrderID : number;
//#endregion gemStoneOrderID Prop


//#region supplierID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        supplierID : number;
//#endregion supplierID Prop


//#region supplierOrderNo Prop
        @required()
        @notEmpty()
        @maxLength({value:50})
        supplierOrderNo : string;
//#endregion supplierOrderNo Prop


//#region supplierInvoiceNo Prop
        @required()
        @notEmpty()
        @maxLength({value:50})
        supplierInvoiceNo : string;
//#endregion supplierInvoiceNo Prop


//#region purchaseOrderNo Prop
        @required()
        @notEmpty()
        @maxLength({value:50})
        purchaseOrderNo : string;
//#endregion purchaseOrderNo Prop


//#region supplierDeliveryDate Prop
        @prop()
        supplierDeliveryDate : Date;
//#endregion supplierDeliveryDate Prop


//#region acceptedBy Prop
        @prop()
        acceptedBy : number;
//#endregion acceptedBy Prop


//#region orderDate Prop
        @prop()
        orderDate : Date;
//#endregion orderDate Prop







}