import {PersonBodilyLocationBase,IncidentPersonsAffectedBase,LookupBodilyLocationBase,TrackIncidentBase,} from '@app/database-models'
//Generated Imports
export class PersonBodilyLocation extends PersonBodilyLocationBase 
{




//#region Generated Reference Properties
//#region incidentPersonsAffected Prop
incidentPersonsAffected : IncidentPersonsAffectedBase;
//#endregion incidentPersonsAffected Prop
//#region lookupBodilyLocation Prop
lookupBodilyLocation : LookupBodilyLocationBase;
//#endregion lookupBodilyLocation Prop
//#region trackIncident Prop
trackIncident : TrackIncidentBase;
//#endregion trackIncident Prop

//#endregion Generated Reference Properties





































}