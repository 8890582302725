import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProjectProductMappingBase {

//#region projectProductMappingID Prop
        @prop()
        projectProductMappingID : number;
//#endregion projectProductMappingID Prop


//#region projectID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        projectID : number;
//#endregion projectID Prop


//#region productID Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productID : number;
//#endregion productID Prop



}