import {vProductionFormClientCorporateBase,} from '@app/database-models'
//Generated Imports
export class vProductionFormClientCorporate extends vProductionFormClientCorporateBase
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}