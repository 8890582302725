import {ProductDesignSketchBase,PictureBase,ProductDesignBase,} from '@app/database-models'
//Generated Imports
export class ProductDesignSketch extends ProductDesignSketchBase 
{




//#region Generated Reference Properties
//#region picture Prop
picture : PictureBase;
//#endregion picture Prop
//#region productDesign Prop
productDesign : ProductDesignBase;
//#endregion productDesign Prop

//#endregion Generated Reference Properties











}