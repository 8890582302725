import {vApplicationObjectLookupBase,} from '@app/database-models'
//Generated Imports
export class vApplicationObjectLookup extends vApplicationObjectLookupBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties




}