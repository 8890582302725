import { prop, required, propObject, trim, numeric } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class MoveNoteDocumentOrderFilterViewModel {

    constructor() {
        this.pageIndex = PaginationEnum.PageIndex;
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "false";
        this.orderBy = "orderId";
    }
    
    @prop({ defaultValue: "" })
    customerName: string;

    @prop({ defaultValue: "" })
    @numeric()
    orderId: string;

    @prop()
    rowCount: number;

    @prop()
    pageIndex: number;

    @prop()
    @trim()
    sortOrder: string;

    @prop()
    @trim()
    orderBy: string;
}
