declare const $: any;
declare const jQuery: any;
export function bindScrool() {
  // go to top scroll
  jQuery(".scroll_top").click(function ($) {
    jQuery("html, body").animate({
      scrollTop: 0
    }, "slow");
    return false;
  });
  //scroll to top 
  jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > 300) {
      jQuery('.scroll_top').addClass('in');
    } else {
      jQuery('.scroll_top').removeClass('in');
    }
  });

}
