import { prop, required, maxLength, notEmpty, trim } from '@rxweb/reactive-form-validators';

export class LocalizationContentBase {
    @prop()
    @required()
    @trim()
    @notEmpty()
    @maxLength({value:100})
    localizationKey:string;

    @prop()
    @required()
    @trim()
    @notEmpty()
    localizationValue:string;

    @prop()
    @required()
    languageId: number;

    @prop()
    oldKey: string;

    @prop({ defaultValue: false })
    isDelete: boolean;
}