import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductVariantColorMappingBase {

//#region ProductVariantColorMappingId Prop
        @prop()
        productVariantColorMappingId : number;
//#endregion ProductVariantColorMappingId Prop


//#region ProductVariantId Prop
        @required()
        productVariantId : number;
//#endregion ProductVariantId Prop


//#region ColorId Prop
        @prop()
        colorId : number;
//#endregion ColorId Prop

}