import { numeric, prop } from "@rxweb/reactive-form-validators";

export class MoveNoteDocumentBatchFilterViewModel {

    @prop()
    supplierId: number

    @prop()
    supplierOrderNo: string

    @prop()
    @numeric()
    batchNo: number;
    @prop()
    @numeric()
    productNo: number;

    @prop({ defaultValue: null })
    prototype: boolean
    @prop({ defaultValue: null })
    bespoke: boolean

    @prop()
    batchCreatedDateFrom: Date

    @prop()
    batchCreatedDateTo: Date
    @prop()
    supplierDeliveryDateFrom: Date
    @prop()
    supplierDeliveryDateTo: Date

    @prop()
    orderBy:string
    
    @prop()        
    sortOrder:boolean
    
    @prop({defaultValue:1})
    pageIndex:number
    
    @prop({defaultValue:25})
    rowCount:number
}