import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class BangleSizeLocalizationBase {

//#region id Prop
        @prop()
        id : number;
//#endregion id Prop


//#region size Prop
        @required()
        size : string;
//#endregion size Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region valueData Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        valueData : number;
//#endregion valueData Prop

}