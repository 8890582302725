import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vInternalDepartmentBase {

//#region departmentId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'departmentId', keyColumn: true})
        departmentId : number;
//#endregion departmentId Prop


//#region departmentName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'departmentName', keyColumn: false})
        departmentName : string;
//#endregion departmentName Prop


}