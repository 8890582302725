import { gridColumn, actionColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';
@actionColumn({
    allowSorting: false,
    style: { width: "5%", "text-align": "initial" },
    visible : true,
    configuredHeaderTemplate: { templateName: 'bindHeaderCheckBox' }, columnIndex: 0
})
export class projectRiskAddListModel {

    @gridColumn({ style: { width: "0.1%", "text-align": "initial" }, visible: true, allowSorting: false, configuredTemplate: { templateName: 'bindCheckBox' }, columnIndex: 0, headerTitle: 'Action', keyColumn: false })
    isChecked: boolean

    //#region specificRiskId Prop
    @gridColumn({ visible: false, columnIndex: 1, keyColumn: true })
    riskMenuDetailId: number;
    //#endregion specificRiskId Prop


    
    //#region riskURL Prop
    @gridColumn({
        // template: {
        //     div: {
        //         style: {
        //             "word-break": "break-all;",
        //         },
        //         attributes: {
        //             innerHTML: function (x, y) {
        //                 if (this.companyName == null) {
        //                     return "";
        //                 } else {
        //                     return this.companyName;
        //                 }
        //             }
        //         }
        //     }
        // }, 
        style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 3, allowSorting: false, configuredTemplate: { templateName: 'corporateGroupLink' }, headerKey : 'ProjectRiskAddComponent_Corporate_gh', keyColumn: false })
    companyName: number;
    //#endregion riskURL Prop

    //#region riskURL Prop
    @gridColumn({
        template: {
            div: {
                style: {
                    "word-break": "break-all;",
                },
                attributes: {
                    innerHTML: function (x, y) {
                        if (this.riskURL == null) {
                            return "";
                        } else {
                            return this.riskURL;
                        }
                    }
                }
            }
        }, style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 2, allowSorting: false, headerKey : 'ProjectRiskAddComponent_Risk_URL_gh', keyColumn: false })
    riskURL: string;
    //#endregion riskURL Prop

    //#region riskName Prop
    @gridColumn({ style: { width: "15%", "text-align": "initial" }, class: ["text-left"], visible: true, columnIndex: 4, allowSorting: false, headerKey : 'ProjectRiskAddComponent_Risk_Name_gh', keyColumn: false })
    riskName: string;
    //#endregion riskName Prop

    totalCount : number;
    UserData:any;
    ErrorMessage:string;
    isSupplierUnwelcome: boolean;
    supplierId: number;  
    companyContactPermissionRequired: boolean;  
    introducesBy:string;
}