import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class LookupOccurrenceAreaBase {

//#region occurrenceAreaId Prop
        @prop()
        occurrenceAreaId : any;
//#endregion occurrenceAreaId Prop


//#region occurrenceAreaName Prop
        @maxLength({value:100})
        occurrenceAreaName : string;
//#endregion occurrenceAreaName Prop


//#region active Prop
        @required()
        active : boolean;
//#endregion active Prop


//#region deleted Prop
        @required()
        deleted : boolean;
//#endregion deleted Prop


//#region createdOn Prop
        @required()
        createdOn : Date;
//#endregion createdOn Prop


//#region createdBy Prop
        @prop()
        createdBy : number;
//#endregion createdBy Prop



}