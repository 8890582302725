import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { CorporateCompanyMapping } from '@app/models';
import { AbstractCorporateCompanyMapping } from '../domain/abstract-corporate-company-mapping';
import { PaginationEnum } from 'src/app/enums/pagination.enums';
import { AppGrid } from 'src/app/domain/app-grid';
import { CorporateSuppliersViewModel } from 'src/app/models/extended-models/corporate-suppliers-view-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { CorporateCompanyMappingBase } from '@app/database-models';
import { multilingual } from '@rxweb/localization';
import * as CryptoJS from 'crypto-js';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";

@multilingual("CorporateCompanyMappingAddComponent")
@Component({
    selector: "app-corporate-company-mapping-add",
    templateUrl: './corporate-company-mapping-add.component.html'
})
export class CorporateCompanyMappingAddComponent extends AbstractCorporateCompanyMapping implements OnInit, OnDestroy {
    corporateCompanyMapping: CorporateCompanyMapping;
    excludedCorporateCompanyMapping:CorporateCompanyMapping;
    excludedCorporateCompanyIds: number [];
    corporateCompanyMappingBase: CorporateCompanyMappingBase;
    corporateCompanyMappingList: CorporateSuppliersViewModel[];
    subscription: any;
    pageIndex: number = PaginationEnum.PageIndex
    rowCount: number = PaginationEnum.RowCount;
    sortOrder: string = "true";
    orderBy: string = "fullName";
    fullName: string = "";
    isFilter: boolean;
    totalRecords: number = 0;
    showGrid: boolean = false;
    @Input() hide: Function;
    @Input() corporateId: number = 0;
    corporateSuppliersGrid: AppGrid;
    cloneArray: CorporateSuppliersViewModel[];
    headerBtnChk: boolean = false;
    titleClose: string;
    ErrorMsg:string;
    corporateIds : number[];
    filterjson:any;

    constructor(private formBuilder: RxFormBuilder, private activatedRoute: ActivatedRoute, modelView: ModalView,private location : PlatformLocation) {
        super();
        this.modalView = modelView;
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.cloneArray = new Array<CorporateSuppliersViewModel>();
        this.corporateCompanyMapping = new CorporateCompanyMapping();
        this.excludedCorporateCompanyMapping = new CorporateCompanyMapping();
        this.corporateCompanyMapping.corporateId = this.corporateId;
        this.corporateCompanyMapping.corporateCompanyMappingBase = new Array<CorporateCompanyMappingBase>();
        this.excludedCorporateCompanyMapping.corporateCompanyMappingBase = new Array<CorporateCompanyMappingBase>();
        this.corporateCompanyMappingFormGroup = this.formBuilder.formGroup(this.corporateCompanyMapping) as IFormGroup<CorporateCompanyMapping>;
        this.corporateIds = new Array<number>();
        this.excludedCorporateCompanyIds = new Array<number>();
    }

    search() {
        this.cloneArray = [];
        this.cloneArray = new Array<CorporateSuppliersViewModel>();
        if(this.headerBtnChk) {
            this.excludedCorporateCompanyIds = [];
            this.excludedCorporateCompanyMapping.corporateCompanyMappingBase = [];
            this.corporateIds = [];
            this.corporateCompanyMapping.corporateCompanyMappingBase = [];
        }
        this.headerBtnChk = false;
        this.pageIndex = 1;
        if (this.corporateSuppliersGrid != null) {
            this.corporateSuppliersGrid.storeProcedure.nextPage = 1
            this.corporateSuppliersGrid.updateSource([]);
        }

        this.bindGrid();
    }

    bindGrid() {
        this.totalRecords = 0;
        var json = {
            fullName: this.fullName,
            pageIndex: this.pageIndex,
            rowCount: this.rowCount,
            sortOrder: this.sortOrder,
            orderBy: this.orderBy,
            corporateId: this.corporateId
        }
        this.filterjson = json;
        this.spin = true;
        this.subscription = this.post({ body: { query: JSON.stringify(json) }, path: "api/SearchUsers/GetSupplierForGroupCompanies" }).subscribe((t: any) => {
            this.corporateCompanyMappingList = t[0].UserData;
            this.ErrorMsg=t[0].ErrorMessage
            if (this.corporateSuppliersGrid != null) {
                this.isFilter = true;
            }
            if (this.corporateCompanyMappingList.length > 0) {
                //this.totalRecords = this.corporateCompanyMappingList[0].totalCount;
                this.totalRecords = this.corporateCompanyMappingList[0].totalCount;
                this.corporateCompanyMappingList.forEach(x => {
                    x.isChecked = this.headerBtnChk;
                })
                this.corporateCompanyMappingList.forEach(x => {
                    if(this.headerBtnChk){
                        if(this.excludedCorporateCompanyIds.indexOf(x.supplierId) != -1){
                            x.isChecked = false;
                        }
                    }
                    else{
                        if(this.corporateIds.indexOf(Number(x.supplierId)) != -1) {
                            x.isChecked = true;
                        }
                    }
                    if (this.cloneArray.findIndex(z => z.supplierId == x.supplierId) == -1) {
                        this.cloneArray.push(x);
                    }
                    else {
                        this.cloneArray.forEach(z => {
                            if (z.supplierId == x.supplierId) {
                                x.isChecked = z.isChecked
                            }
                        })
                    }
                })
                this.updateGroupCompaniesArray();
                if (!this.isFilter) {
                    this.corporateCompanyMappingList.length > 0 ? this.showGrid = true : this.showGrid = false;
                    this.corporateSuppliersGrid = new AppGrid(this.corporateCompanyMappingList, CorporateSuppliersViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } });
                    this.corporateSuppliersGrid.storeProcedure = {
                        length: this.totalRecords,
                        onPageChanging: this.onPageChanging.bind(this),
                        nextPage: 1,
                        onPageSorting: this.onPageSorting.bind(this)
                    }
                }
                else {
                    if (this.corporateSuppliersGrid != null) {
                        this.corporateSuppliersGrid.storeProcedure.length = this.totalRecords;
                        setTimeout(() => {
                            this.corporateSuppliersGrid.updateSource([]);
                            this.corporateSuppliersGrid.updateSource(this.corporateCompanyMappingList)
                        }, 0);
                    }
                }
                this.corporateSuppliersGrid.maxPerPage = this.rowCount;
                this.corporateSuppliersGrid.componentId = this.componentName;
            }

            else {
                this.isNoRecordFound = true;
                if (this.corporateSuppliersGrid != null) {
                    this.corporateSuppliersGrid.destroy();
                    this.corporateSuppliersGrid = null;
                    this.isFilter = false;
                }
                this.isShowGrid = false;
            }
            this.spin = false;
            this.showComponent = true;
        })
    }

    onCheckboxSelect(t, x) {
        // this.corporateCompanyMappingList.forEach(data => {
        //     if (data.supplierId == t.supplierId) {
        //         data.isChecked = this.headerBtnChk;
        //     }
        // });
        let oldCloneArray = this.cloneArray;
        this.cloneArray = new Array<CorporateSuppliersViewModel>();
        oldCloneArray.forEach(data => {
            let newObj = new CorporateSuppliersViewModel();
            newObj.isChecked = data.supplierId == t.supplierId ? x.target.checked : data.isChecked;
            newObj.company = data.company;
            newObj.email = data.email;
            newObj.fullName = data.fullName;
            newObj.totalCount = data.totalCount;
            newObj.supplierId = data.supplierId;
            newObj.isSupplierUnwelcome = data.isSupplierUnwelcome;
            this.cloneArray.push(newObj);
        });

        let scrollTop: number = 0;
        if(navigator.userAgent.match(/edg/i))
            scrollTop = document.getElementById('app-corporate-company-mapping-add').scrollTop;
        this.corporateSuppliersGrid.updateSource([]);
        this.corporateSuppliersGrid.updateSource(this.cloneArray);
        if(navigator.userAgent.match(/edg/i))
            document.getElementById('app-corporate-company-mapping-add').scrollTop = scrollTop;

        this.updateGroupCompaniesArray();
    }

    updateGroupCompaniesArray() {
        this.cloneArray.forEach(x => {
            let corporateCompanyMapping = new CorporateCompanyMapping();
            corporateCompanyMapping.corporateCompanyMapppingId = 0;
            corporateCompanyMapping.supplierId = x.supplierId;
            corporateCompanyMapping.corporateId = this.corporateId
            if (x.isChecked) {
                if(this.headerBtnChk){
                    if(this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.findIndex(t => t.supplierId == x.supplierId) != -1 ) {
                        this.excludedCorporateCompanyIds.splice(this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.findIndex(t => t.supplierId == x.supplierId),1);
                        this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.splice(this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.findIndex(t => t.supplierId == x.supplierId),1);
                    }
                }
                else{
                    if (this.corporateCompanyMapping.corporateCompanyMappingBase.findIndex(y => y.supplierId == x.supplierId) == -1) {
                        this.corporateCompanyMapping.corporateCompanyMappingBase.push(corporateCompanyMapping);
                    }

                    if(this.corporateIds.indexOf(Number(x.supplierId)) == -1) {
                        this.corporateIds.push(Number(x.supplierId));
                    }
                }
            }
            else {
                if(this.headerBtnChk){
                    if (this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.findIndex(t => t.supplierId == x.supplierId) == -1) {
                        this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.push(corporateCompanyMapping);
                        this.excludedCorporateCompanyIds.push(x.supplierId);
                     }
                }
                else{
                    if (this.corporateCompanyMapping.corporateCompanyMappingBase.findIndex(y => y.supplierId == x.supplierId) != -1) {
                        this.corporateCompanyMapping.corporateCompanyMappingBase.splice(this.corporateCompanyMapping.corporateCompanyMappingBase.findIndex(z => z.supplierId == x.supplierId), 1);
                        this.corporateIds.splice(this.corporateIds.indexOf(Number(x.supplierId)),1);
                    }

                    if(this.corporateIds.indexOf(Number(x.supplierId)) != -1) {
                        this.corporateIds.splice(this.corporateIds.indexOf(Number(x.supplierId)),1);
                    }
                }
            }
        });
    }

    onHeaderCheckBoxSelect(t, x) {
        this.headerBtnChk = x.target.checked;
        this.cloneArray = new Array<CorporateSuppliersViewModel>();
        this.excludedCorporateCompanyIds = [];
        this.excludedCorporateCompanyMapping.corporateCompanyMappingBase = [];
        this.corporateIds = [];
        this.corporateCompanyMapping.corporateCompanyMappingBase = [];
        this.corporateCompanyMappingList.forEach(data => {
            let newObj = new CorporateSuppliersViewModel();
            newObj.isChecked = this.headerBtnChk;
            newObj.company = data.company;
            newObj.email = data.email;
            newObj.fullName = data.fullName;
            newObj.totalCount = data.totalCount;
            newObj.supplierId = data.supplierId;
            newObj.isSupplierUnwelcome = data.isSupplierUnwelcome;
            this.cloneArray.push(newObj);
        })
        this.updateGroupCompaniesArray();
        this.corporateSuppliersGrid.updateSource([]);
        this.corporateSuppliersGrid.updateSource(this.cloneArray);
    }

    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t")+ " total " +  (this.totalRecords - this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.length)  + " Records";;
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveCorporateSupplierMappings();
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveCorporateSupplierMappings();
        }
     }

    saveCorporateSupplierMappings() {
        if (this.corporateCompanyMapping.corporateCompanyMappingBase.length > 0 || this.excludedCorporateCompanyMapping.corporateCompanyMappingBase.length > 0 || this.headerBtnChk) {
        if(this.headerBtnChk){
            this.corporateCompanyMapping.corporateCompanyMappingBase = this.excludedCorporateCompanyMapping.corporateCompanyMappingBase;
            this.corporateCompanyMapping.headerBtnCheck = this.headerBtnChk;
                this.filterjson["sortOrder"] = "false";
                this.filterjson["rowCount"] = this.totalRecords;
                this.filterjson["pageIndex"] = 1;
            this.corporateCompanyMapping.query = JSON.stringify(this.filterjson);
            this.corporateCompanyMapping.corporateId = this.corporateId;
        }
        this.spin = true;
        this.post({ body: this.corporateCompanyMapping }).subscribe(data => {
            this.spin = false;
            var existsAlert = getLocalizedValue("Data_Added");
            if (existsAlert) {
                //this.toastr.success("Data Added Successfully")
                this.toastr.success(existsAlert);
            }
            this.hide();
        })
     }
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.sortOrder = y;
        this.orderBy = x;
        this.corporateSuppliersGrid.storeProcedure.nextPage = z;
        this.cloneArray = [];
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.rowCount;
        let toIndex: number = this.corporateSuppliersGrid.maxPerPage;
        //if (this.pageIndex < x) {
            this.cloneArray = [];
        //}
        if (this.corporateSuppliersGrid.maxPerPage < this.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }
        }
        this.pageIndex = x;
        this.rowCount = this.corporateSuppliersGrid.maxPerPage;
        this.corporateSuppliersGrid.storeProcedure.nextPage = x;
        this.bindGrid();
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    get componentName(): string {
        return "CorporateCompanyMappingAddComponent";
    }
}
