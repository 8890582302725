import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class GemstoneOriginBase {

//#region originId Prop
        @prop()
        originId : number;
//#endregion originId Prop


//#region originName Prop
        @required()
        @maxLength({value:200})
        @notEmpty()
        //@alpha({allowWhiteSpace:true})
        @trim()
        originName : string;
//#endregion originName Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop

}