import { Component, OnInit, OnDestroy, EventEmitter, ViewChild } from "@angular/core";
import { List } from "@rxweb/generics";
import { AbstractProductPiece } from "../domain/abstract-product-piece";
import { ProductPiece, vWholeSalePartnerLookUp } from "@app/models";
import { Subject } from "rxjs";
import { PiecesSearchModel } from "src/app/view-model/pieces-search-model";
import { IFormGroup, RxFormBuilder } from "@rxweb/reactive-form-validators";
import { USER_LOOKUPS } from "src/app/const/uris/user-lookups.uri";
import { DEFAULT_BLOB_IMAGE_URL } from "src/app/domain/system-constant";
import { PiecesViewModel } from "src/app/view-model/pieces-view-model";
import { AppGrid } from "src/app/domain/app-grid";
import { GridColumnConfig } from "@rxweb/grid";
import { ModalView } from "src/app/domain/customize-design/modal";
import {
  dateCompareValidation,
  appendPageFooter,
  isSalesRole,
  getLocalizedValue,
  getSystemHelpStatus,
} from "src/app/domain/common-logic/common-logic";
import { multilingual, MultiLingualData } from "@rxweb/localization";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { NavigationExtras, Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { FileDetailViewModel } from "src/app/view-model/file-detail-view-model";
import { RolePermissionEnums } from "src/app/custom-enum/role-permission-enums";
import { access } from "@rxweb/angular-router";
import { debounceTime } from "rxjs/operators";
import { ApplicationBroadcaster } from "src/app/temp-service/application-broadcaster";
import { BrowserStorage } from "src/app/domain/services/browser-storage";
import { PaginationEnum } from "src/app/enums/pagination.enums";
import { ImgModalComponent } from "src/app/components/img-modal/img-modal.component";
import { SystemHelpViewComponent } from "src/app/components/master/system-help/view/system-help-view.component";
import { SystemHelpPage } from "src/app/enums/system-help-page.enum";
import { UpdatePiecesFromExcelComponent } from "../update-pieces-from-excel/update-pieces-from-excel.component";
import { scroolOnSearchResult } from "src/app/domain/customize-plugin/bind-scroll-on-search";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";
import { NotesEnum } from "src/app/enums/notes.enum";

@access({ accessLevel: RolePermissionEnums.Pieces, action: "get" })
@multilingual("ProductPieceListComponent")
@Component({
  selector: "app-product-piece-list",
  templateUrl: "./product-piece-list.component.html",
})
export class ProductPieceListComponent
  extends AbstractProductPiece
  implements OnInit, OnDestroy
{


  _this;

  productPiece: ProductPiece;
  subscription: any;
  productPieceSearchFormGroup: IFormGroup<PiecesSearchModel>;
  productPieceSearch: PiecesSearchModel;
  pieceCreatedError: boolean = false;
  piecesGrid: AppGrid;
  piecesList: PiecesViewModel[];
  piecesExcelList: PiecesViewModel[];
  insuranceValue: string;
  rrpValue: string;
  selectedItems: PiecesViewModel[];
  isShowGridColumn: boolean = false;
  list: any[];
  exportExcel = new ExportToExcelModel();
  rowCount: number;
  title: string;
  private pieceSubject: Subject<string> = new Subject<string>();
  isSalesTeam: boolean;
  browserStorage: BrowserStorage;
  loggedInUserRole: string;
  moduleID: number;
  gridColumnConfig: GridColumnConfig[];
  gridColumnSettings: EventEmitter<GridColumnConfig[]> = new EventEmitter<
    GridColumnConfig[]
  >();
  getColumnsData: any;
  arrConfig: any[];
  showGrid: boolean = false;
  titleHelp: string;
  titleGridColumnSetting: string;
  titleSearch: string;
  showSystemHelpIcon: boolean = false;
  wholeSalePartnerLookUp: List<vWholeSalePartnerLookUp>;
  lookups: any;
  //
  salesChannelLookUps: any;
  searchString: any;
  piecesIdGo:number;
  msgError: boolean = false;

  constructor(
    private formBuilder: RxFormBuilder,
    private applicationBroadcaster: ApplicationBroadcaster,
    modelView: ModalView,
    domSanitizer: DomSanitizer,
    route: Router,
    private activatedRoute: ActivatedRoute,
    private title1: Title, blobState: BlobUploadsViewStateService
  ) {
    super(domSanitizer,blobState);
    this.modalView = modelView;
    applicationBroadcaster.activeMenu({
      activeMenu: "inventory",
      subMenu: "product-piece",
    });
    this.router = route;
    this.activatedRoute.queryParams.subscribe((t) => {
      this.title = t["searchTerms"] == undefined ? null : t["searchTerms"];
    });
    this.browserStorage = new BrowserStorage();
    this.loggedInUserRole = this.browserStorage.local.get("userRoles", true);
    this.moduleID = RolePermissionEnums.Pieces;
    this._this=this;
  }

  ngOnInit(): void {
    this.showSystemHelpIcon = getSystemHelpStatus(
      this.moduleID,
      SystemHelpPage.Search
    );
    this.isSalesTeam = isSalesRole();
    this.title1.setTitle("Faberge Pieces - G-Trac Admin");
    this.titleHelp = getLocalizedValue("Title_Help");
    this.titleSearch = getLocalizedValue("Title_Search");
    this.titleGridColumnSetting = getLocalizedValue("Title_GridColumnSetting");
    this.isSearch = false;
    this.pieceSubject.pipe(debounceTime(300)).subscribe((searchTextValue) => {
      this.searchPieces();
    });
    this.selectedItems = new Array<PiecesViewModel>();
    this.spin = true;
    this.productPiece = new ProductPiece();
    this.productPiece.productVariantBatchId = 2;
    this.productPiece.serialNo = "test";
    this.productPiece.cost = 1.0;
    this.productPiece.ataCarnetNo = "Test ATA";
    this.productPiece.location = 1;
    this.productPiece.status = 1;
    this.productPiece.createdOn = new Date();
    this.productPiece.updatedOn = new Date();
    this.productPiece.size = 2;
    this.productPiece.eggSize = 2;
    this.productPiece.supplierInvoiceNo = "Invoiceno";
    this.productPiece.exportPrice = 1.0;
    this.productPiece.doNotPublish = false;
    this.productPiece.displayRRPFor = "2";
    this.productPiece.imageAlt = "alt";
    this.productPiece.dutyStatusID = 1;
    this.productPiece.priceWholesalesoldat = 1.0;
    this.productPiece.wholesalepiecestatus = false;
    this.productPiece.isPrototype = false;
    this.productPiece.chainLengthID = 12;
    this.productPiece.bangleSize = 1;
    this.productPiece.isUpcycled = false;
    this.productPiece.isBeSpoke = false;
    this.productPiece.selectedPiece = new PiecesViewModel();
    this.lookup([
      {
        path: USER_LOOKUPS["getCollectionLookUps"],
        propName: "collectionLookup",
        params: [13],
      },
      {
        path: USER_LOOKUPS["designCategoryLookup"],
        propName: "categoryLookup",
      },
      {
        path: USER_LOOKUPS["productBatchEggSizeLookUp"],
        propName: "productBatchEggSizeLookUp",
      },
      { path: USER_LOOKUPS["pieceOwnerLookUp"], propName: "pieceOwnerLookUp" },
      { path: USER_LOOKUPS["warehouseLookUp"], propName: "warehouseLookUp" },
      { path: USER_LOOKUPS["ringSizeLookup"], propName: "ringSizeLookup" },
      { path: USER_LOOKUPS["bangleSizeLookup"], propName: "bangleSizeLookup" },
      { path: USER_LOOKUPS["dutyStatusLookUp"], propName: "dutyStatusLookUp" },
      {
        path: USER_LOOKUPS["productSupplierLookups"],
        propName: "supplierLookUps",
      },
      {
        path: USER_LOOKUPS["productMaterialLookUps"],
        propName: "productMaterialLookUps",
      },
      {
        path: USER_LOOKUPS["productColorLookUps"],
        propName: "productColorLookUps",
      },
      {
        path: USER_LOOKUPS["productGemstonetypeLookUps"],
        propName: "productGemstonetypeLookUps",
      },
      {
        propName: "wholeSalePartnerLookUp",
        path: "api/UserLookups/GetWholeSaleCorporates",
      },
      //
      { propName: "salesChannelLookups", path: USER_LOOKUPS["salesChannel"] },
      { propName: "salesStatusFilterLookup", path: USER_LOOKUPS.salesStatusFilterLookup },
      { propName: "productionTeamStatusLookup", path: USER_LOOKUPS.productionTeamStatusLookUp },
      { propName: "consignmentStatusLookup", path: USER_LOOKUPS.consignmentStatusLookup },
      { propName: "specialStatusLookup", path: USER_LOOKUPS.specialStatusLookup },
      { propName: "countryLookUp", path: USER_LOOKUPS.countryLookUp },
      { path: USER_LOOKUPS["showSupplierDeliveryDate"], propName: "showSupplierDeliveryDate" }
    ]).subscribe((response: any) => {
      this.productPieceSearch = new PiecesSearchModel();
      this.lookups=response;
      // this.productPieceSearch.clientUrl = CLIENT_URL;
      // this.productPieceSearch.serverUrl = SERVER_URL;
      this.productPieceSearch.orderBy = "pieceId";
      this.productPieceSearch.categoryId = 9999;
      this.productPieceSearch.sortOrder = false;
      //this.productPieceSearch.status = 9999;
      this.collectionLookup = response.collectionLookup;
      this.productBatchEggSizeLookUp = response.productBatchEggSizeLookUp;
      this.pieceOwnerLookUp = response.pieceOwnerLookUp;
      this.warehouseLookUp = response.warehouseLookUp;
      this.ringSizeLookup = response.ringSizeLookup;
      this.wholeSalePartnerLookUp = response.wholeSalePartnerLookUp;
      this.bangleSizeLookup = response.bangleSizeLookup;
      this.dutyStatusLookUp = response.dutyStatusLookUp;
      this.supplierLookUps = response.supplierLookUps;
      //
      this.salesChannelLookUps = response.salesChannelLookups;
      this.productMaterialLookUps = response.productMaterialLookUps;
      this.productColorLookUps = response.productColorLookUps;
      this.productGemstonetypeLookUps = response.productGemstonetypeLookUps;
      this.categoryLookup = new Array<CategoryLookup>();
      this.categoryLookup = response.categoryLookup;
      this.showSupplierDeliveryDate = response.showSupplierDeliveryDate;
      this.categoryLookup = this.categoryLookup.filter(
        (t) => t.parentCategoryId != 0
      );
      this.categoryLookup = this.categoryLookup.filter(
        (t) =>
          (t.categoryName = t.categoryName.substring(
            t.categoryName.indexOf(">") + 3
          ))
      );
      let category = new CategoryLookup();
      category.categoryId = 9999;
      category.categoryName = "All";
      category.abbreviation = "";
      category.categoryTypeId = 4;
      category.categoryTypeName = "";
      category.displayOrder = 0;
      category.published = false;
      this.productPieceSearch.title = this.title == null ? "" : this.title;
      this.productPieceSearchFormGroup = this.formBuilder.formGroup(
        PiecesSearchModel,
        this.productPieceSearch
      ) as IFormGroup<PiecesSearchModel>;
      this.spin = false;
      this.showComponent = true;
      if (this.title != null) {
        this.searchPieces();
      }

      // this.bindGrid();
    });
  }

  showSystemHelp() {
    this.modalView.show(SystemHelpViewComponent, {
      moduleId: this.moduleID,
      systemHelpPageId: SystemHelpPage.Search,
    });
  }

  onSearchPress() {
    this.pieceSubject.next();
  }

  searchPieces() {
    this.productPieceSearchFormGroup.patchValue({
      piecesId: null
    });
    this.msgError =false;
    this.totalRecords = 0;
    this.productPieceSearchFormGroup.value.pageIndex = 1;
    this.productPieceSearchFormGroup.value.title =
      this.productPieceSearchFormGroup.value.title != null
        ? this.productPieceSearchFormGroup.value.title.trim()
        : null;
    this.productPieceSearchFormGroup.value.categoryId =
      this.productPieceSearchFormGroup.value.categoryId == 9999
        ? null
        : this.productPieceSearchFormGroup.value.categoryId;
    this.checkDates();
    this.productPieceSearchFormGroup.submitted = true;
    if (
      this.tripDateError == false &&
      this.pieceCreatedError == false &&
      this.expiryDateError == false &&
      this.deliveryDateError == false ) {
      if (this.piecesGrid != null ) {
        this.isFilter = true;
        this.productPieceSearchFormGroup.value.rowCount =
          this.piecesGrid.maxPerPage;
        this.piecesGrid.updateSource([]);
        // this.piecesGrid = undefined;
        // this.isShowGrid = false;
      }
      // setTimeout(() => {
        this.bindGrid();
      // }, 100);
    }
  }

  resetSearch() {
    this.showComponent = false;
    this.msgError =false;
    this.spin = true;
    this.productPieceSearchFormGroup.reset();
    this.productPieceSearchFormGroup.value.superSeller = false;
    this.productPieceSearchFormGroup.value.noReorder = false;
    this.productPieceSearchFormGroup.value.coreProduct = false;
    this.productPieceSearchFormGroup.value.superSeller = null;
    this.productPieceSearchFormGroup.value.noReorder = null;
    this.productPieceSearchFormGroup.value.coreProduct = null;
    if(this.piecesGrid && this.piecesGrid.source.length > 0){
      this.piecesGrid.destroy();
    }
    if(this.piecesGrid){
      this.piecesGrid = null;
      this.isFilter = false;
    }
    this.showGrid = false;
    this.isShowGrid = false;
    this.isNoRecordFound = false;
    setTimeout(() => {
      this.showComponent = true;
      this.spin = false;
    }, 100);
  }

  checkDates() {
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.expiryDateFrom,
        this.productPieceSearchFormGroup.value.expiryDateTo
      ) == true
    ) {
      this.expiryDateError = true;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.expiryDateFrom,
        this.productPieceSearchFormGroup.value.expiryDateTo
      ) != true
    ) {
      this.expiryDateError = false;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.deliveryDateFrom,
        this.productPieceSearchFormGroup.value.deliveryDateTo
      ) == true
    ) {
      this.deliveryDateError = true;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.deliveryDateFrom,
        this.productPieceSearchFormGroup.value.deliveryDateTo
      ) != true
    ) {
      this.deliveryDateError = false;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.tripFrom,
        this.productPieceSearchFormGroup.value.tripTo
      ) == true
    ) {
      this.tripDateError = true;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.tripFrom,
        this.productPieceSearchFormGroup.value.tripTo
      ) != true
    ) {
      this.tripDateError = false;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.pieceCreatedDateFrom,
        this.productPieceSearchFormGroup.value.pieceCreatedDateTo
      ) == true
    ) {
      this.pieceCreatedError = true;
    }
    if (
      dateCompareValidation(
        this.productPieceSearchFormGroup.value.pieceCreatedDateFrom,
        this.productPieceSearchFormGroup.value.pieceCreatedDateTo
      ) != true
    ) {
      this.pieceCreatedError = false;
    }
  }

  applyVisible(gridColumnConfig: GridColumnConfig[]) {
    this.piecesGrid.gridColumns.forEach((x) => {
      if (x.name == "insuranceValue") {
        x.visible = x.visible && !this.isSalesTeam;
      }
    });

    this.piecesGrid.reDesign();
    this.piecesGrid.updateSource([]);
    this.piecesGrid.updateSource(this.piecesList);

    // setTimeout(() => {
    //   var tableElement = document.getElementById("piecesGrid");
    //   var footer = document.getElementsByClassName(
    //     "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center"
    //   );
    //   tableElement.childNodes[0].removeChild(footer[0]);
    // }, 400);
  }

  showGridColumn() {
    this.isShowGridColumn = !this.isShowGridColumn;
  }

  search(event: any) {
    this.msgError =false;
    this.searchString = event.target.value.replace(/^\s+|\s+$/gm, "");
    this.productPieceSearchFormGroup.value.searchString = this.searchString;
    this.isFilter = true;
    this.productPieceSearchFormGroup.value.pageIndex = 1;
    if (this.piecesGrid != null  || this.piecesGrid != undefined) {
      this.productPieceSearchFormGroup.value.rowCount =
        this.piecesGrid.maxPerPage;
      this.piecesGrid.updateSource([]);
      this.piecesGrid.storeProcedure.nextPage = 1;
    }
    this.isShowGrid = false;
    this.bindGrid();
  }

  
  goTo() {
    this.productPieceSearchFormGroup.submitted = true;
    if (this.productPieceSearchFormGroup.controls.piecesId.valid) {
              this.piecesIdGo = this.productPieceSearchFormGroup.controls.piecesId.value;

              if(this.piecesIdGo){
                this.get({ path: 'api/SearchCommon/IsRecordExist', queryParams: {"id":this.piecesIdGo,"moduleId": NotesEnum.ProductVariantBatchPieceNotes} }).subscribe((data: any) => {
                  if(data){
                    this.router.navigate(['/product-piece', this.piecesIdGo], { queryParams: { "activeTab": "piece-info" } });
                        }else{
                          this.msgError=true;
                        }
                  });
              }else{
                this.msgError=true;
              }
            }
            else {
                this.msgError = true;
            }
}

  bindGrid() {
    this.showGrid = true;
    this.totalRecords = 0;
    this.spin = true;

    this.get({
      path: "api/SearchCommon/GetGridColumns",
      queryParams: {
        moduleID: encodeURIComponent(JSON.stringify(this.moduleID)),
      },
    }).subscribe((t: any) => {
      this.getColumnsData = t;
      if (
        this.getColumnsData.result != null &&
        this.getColumnsData.result != undefined
      ) {
        this.arrConfig = JSON.parse(this.getColumnsData.result.gridColumns);
      }
    });
    if(this.productPieceSearchFormGroup.value.gtinNumber != null && this.productPieceSearchFormGroup.value.gtinNumber.toString() == ''){
      this.productPieceSearchFormGroup.controls.gtinNumber.setValue(null)
    }
    this.post({
      path: "api/SearchPieces/GetPieces",
      body: {
        query: JSON.stringify(this.productPieceSearchFormGroup.value),
        isExcel: false,
      },
    }).subscribe((t: PiecesViewModel[]) => {
      setTimeout(() => {


      this.piecesList = t;
      this.spin = false;
      this.isNoRecordFound = this.piecesList.length == 0 ? true : false;
      this.isShowGrid = this.piecesList.length > 0 ? true : false;
      if (this.isNoRecordFound == false) {
        this.totalRecords = this.piecesList[0].totalCount;
        this.insuranceValue =
          "$" +
          Math.round(this.piecesList[0].totalInsuranceValue)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.rrpValue =
          "$" +
          Math.round(this.piecesList[0].totalRRPValue)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (!this.isFilter && (this.piecesGrid==null || this.piecesGrid==undefined)) {
          this.piecesGrid = new AppGrid(this.piecesList, PiecesViewModel, {
            actions: {
              onImgError: this.onImgError.bind(this),
              onStockImgError: this.onStockImgError.bind(this),
              onEditData: this.onEditData.bind(this),
              onCoreSelect: this.onCoreSelect.bind(this),
              onImgLoad: this.showImgModal.bind(this),
              onSuperSellerSelect: this.onSuperSellerSelect.bind(this),
              onStockPrice: this.onStockPrice.bind(this),
              onStockWithoutPrice: this.onStockWithoutPrice.bind(this),
              onBarcodeImgError: this.onBarcodeImgError.bind(this), 
              onBarcodeImgLoad: this.onBarcodeImgLoad.bind(this)
            },
          });

          this.piecesGrid.gridColumns.forEach((x) => {
            if (x.name == "insuranceValue") {
              x.visible = !this.isSalesTeam;
            }
          });

          if (this.isSalesTeam)
            this.piecesGrid.gridColumns.splice(
              this.piecesGrid.gridColumns.findIndex(
                (x) => x.name == "insuranceValue"
              ),
              1
            );
          if (!this.showSupplierDeliveryDate)
          this.piecesGrid.gridColumns.splice(
            this.piecesGrid.gridColumns.findIndex(
              (x) => x.name == "supplierDate"
            ),
            1
          );
            if (this.arrConfig != undefined && this.arrConfig != null) {
              this.arrConfig.forEach((x) => {
                this.piecesGrid.gridColumns.forEach((y) => {
                  if (y.name == x.ColumnName) y.visible = x.Visible;
                });
              });
              this.gridColumnConfig = this.piecesGrid.gridColumns;
              this.gridColumnSettings.emit(this.gridColumnConfig);
            }
          this.piecesGrid.storeProcedure = {
            length: this.totalRecords,
            onPageChanging: this.onPageChanging.bind(this),
            nextPage: 1,
            onPageSorting: this.onPageSorting.bind(this),
          };
        } else {
          if (this.piecesGrid != null ) {
            this.piecesGrid.storeProcedure.length = this.totalRecords;
            if (this.productPieceSearchFormGroup.value.pageIndex == 1) {
              this.piecesGrid.storeProcedure.nextPage = 1;
            }
            this.piecesGrid.updateSource([]);
            this.piecesGrid.updateSource(this.piecesList);
          }
        }



        this.piecesGrid.maxPerPage =
          this.productPieceSearchFormGroup.value.rowCount;
        this.piecesGrid.componentId = this.componentName;
        this.piecesGrid.designClass.tableClass =
          "table table-height table-striped table-hover dataTables-example dataTable text-center border-bottom table-responsive".split(
            " "
          );
        this.piecesGrid.designClass.headerClass = "table-header-sticky".split(
          " "
        );
        scroolOnSearchResult();
        // setTimeout(() => {
        //   if (!this.isNoRecordFound) {
        //     if (!this.isFilter) {
        //       appendPageFooter("piecesTable");
        //     } else {
        //       var tableElement = document.getElementById("piecesGrid");
        //       var footer = document.getElementsByClassName(
        //         "dataTables_paginate paging_simple_numbers d-flex flex-column flex-lg-row justify-content-between align-items-center"
        //       );
        //       appendPageFooter("piecesTable");
        //     }
        //   }
        // }, 0);
      }
    }, 0)
    });
  }

  onBarcodeImgError(t) {
    t.ImageUrl = DEFAULT_BLOB_IMAGE_URL;
  }

  onBarcodeImgLoad(pieceData: PiecesViewModel) {
    if (pieceData.barcodeUrl) {
      this.modalView.show(ImgModalComponent, { imgSrc: pieceData.barcodeUrl, isBarcode: true }).then(x => { });
    }
  }

  onStockImgError(t) {
    t.stockImageUrl = DEFAULT_BLOB_IMAGE_URL;
  }

  onImgError(t) {
    t.imageUrl = DEFAULT_BLOB_IMAGE_URL;
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onEditData(pieceObj: PiecesViewModel) {
    if (
      this.selectedItems.findIndex(
        (z) =>
          z.productVariantBatchPieceId == pieceObj.productVariantBatchPieceId
      ) != -1
    ) {
      this.productPiece.selectedPiece = this.selectedItems.filter(
        (z) =>
          z.productVariantBatchPieceId == pieceObj.productVariantBatchPieceId
      )[0];
      this.spin = true;
      this.post({ body: this.productPiece }).subscribe((t) => {
        this.toastr.success("Data Updated Successfully");
        this.spin = false;
        this.selectedItems = [];
        setTimeout(() => {
          this.isFilter = true;
          if (this.piecesGrid != null || this.piecesGrid != undefined) {
            this.piecesGrid.updateSource([]);
          }
          this.bindGrid();
        }, 400);
      });
    }
  }

  showImgModal(pieceObj: PiecesViewModel) {
    if (pieceObj.imageUrlOrg) {
      this.modalView
        .show(ImgModalComponent, { imgSrc: pieceObj.imageUrlOrg })
        .then((x) => {});
    }
  }
  onStockPrice(pieceData: PiecesViewModel) {
    let jsonData: any;
    let gbpPrice: number = Math.round(
      pieceData.exportRetailPrice * pieceData.gbpRate
    );
    let usdPrice: number = Math.round(
      pieceData.exportRetailPrice * pieceData.usdRate
    );
    let chfPrice: number = Math.round(
      pieceData.exportRetailPrice * pieceData.chfRate
    );
    let hkdPrice: number = Math.round(
      pieceData.exportRetailPrice * pieceData.hkdRate
    );
    let eurPrice: number = Math.round(
      pieceData.exportRetailPrice * pieceData.eurRate
    );
    this.jsonParam = JSON.parse(
      JSON.stringify({
        pieceId: pieceData.productVariantBatchPieceId,
        productId: pieceData.productVariantId,
      })
    );
    this.post({
      path: "api/SearchPieces/GetStockCardImage",
      body: { query: JSON.stringify(this.jsonParam) },
    }).subscribe((t) => {
      jsonData = JSON.parse(
        JSON.stringify({
          productName: pieceData.product,
          withPrice: true,
          gbpPrice: gbpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          usdPrice: usdPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          chfPrice: chfPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          hkdPrice: hkdPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          eurPrice: eurPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          description: pieceData.description,
          adminComment: pieceData.adminComment,
          imageUrl: t,
          serialNo: pieceData.serialNo,
        })
      );
      let navigationExtras: NavigationExtras = {
        queryParams: {
          cardJson: JSON.stringify(jsonData),
        },
      };

      let url = this.router.serializeUrl(
        this.router.createUrlTree(["/stock-card"], navigationExtras)
      );
      window.open(
        url,
        "_blank",
        "location=yes,height=800,width=960,scrollbars=yes,status=yes"
      );
    });
  }

  onStockWithoutPrice(pieceData: PiecesViewModel) {
    let jsonData: any;
    this.jsonParam = JSON.parse(
      JSON.stringify({
        pieceId: pieceData.productVariantBatchPieceId,
        productId: pieceData.productVariantId,
      })
    );
    this.post({
      path: "api/SearchPieces/GetStockCardImage",
      body: { query: JSON.stringify(this.jsonParam) },
    }).subscribe((t) => {
      jsonData = JSON.parse(
        JSON.stringify({
          productName: pieceData.product,
          withPrice: false,
          gbpPrice: 0,
          usdPrice: 0,
          chfPrice: 0,
          hkdPrice: 0,
          eurPrice: 0,
          description: pieceData.description,
          adminComment: pieceData.adminComment,
          imageUrl: t,
          serialNo: pieceData.serialNo,
        })
      );
      let navigationExtras: NavigationExtras = {
        queryParams: {
          cardJson: JSON.stringify(jsonData),
        },
      };

      let url = this.router.serializeUrl(
        this.router.createUrlTree(["/stock-card"], navigationExtras)
      );
      window.open(
        url,
        "_blank",
        "location=yes,height=800,width=960,scrollbars=yes,status=yes"
      );
    });
  }

  onSuperSellerSelect(pieceObj: PiecesViewModel, x) {
    if (
      this.selectedItems.findIndex(
        (z) =>
          z.productVariantBatchPieceId == pieceObj.productVariantBatchPieceId
      ) == -1
    ) {
      pieceObj.superSeller = x.target.checked;
      this.selectedItems.push(pieceObj);
    } else {
      this.selectedItems.forEach((z) => {
        if (
          z.productVariantBatchPieceId == pieceObj.productVariantBatchPieceId
        ) {
          z.superSeller = x.target.checked;
        }
      });
    }
  }
  onCoreSelect(pieceObj: PiecesViewModel, x) {
    if (
      this.selectedItems.findIndex(
        (z) =>
          z.productVariantBatchPieceId == pieceObj.productVariantBatchPieceId
      ) == -1
    ) {
      pieceObj.coreProduct = x.target.checked;
      this.selectedItems.push(pieceObj);
    } else {
      this.selectedItems.forEach((z) => {
        if (
          z.productVariantBatchPieceId == pieceObj.productVariantBatchPieceId
        ) {
          z.coreProduct = x.target.checked;
        }
      });
    }
  }

  exportToExcel() {
    this.spin = true;
    this.productPieceSearchFormGroup.patchValue({
      pageIndex: PaginationEnum.PageIndex,
      rowCount: this.totalRecords,
      orderBy: this.productPieceSearchFormGroup.value.orderBy,
      searchString: this.searchString,
      sortOrder: this.productPieceSearchFormGroup.value.sortOrder
    });
    this.post({
      path: "api/SearchPieces/GetPieces",
      body: {
        query: JSON.stringify(this.productPieceSearchFormGroup.value),
        isExcel: true,
      },
    }).subscribe((t: PiecesViewModel[]) => {
      this.piecesExcelList = t;
      let returnedRequestData = this.requestExcelData(
        this.piecesExcelList,
        this.piecesGrid.gridColumns
      );
      var TodayDate = new Date();
      let MMDDYY = moment(TodayDate).format("MMDDYY").toString();
      this.exportExcel.FileName = "FabergePieces" + MMDDYY;
      this.exportExcel.Type = "Excel";
      this.exportExcel.Description = "FabergePieces";
      this.exportExcel.ModuleId = this.moduleID;
      this.exportExcel.Result = JSON.stringify(returnedRequestData);
      return this.post({
        body: this.exportExcel,
        path: "api/SearchUsers/GetExcel",
      }).subscribe(
        (Response) => {
          this.downloadDocument(
            Response,
            this.exportExcel.FileName,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          this.spin = false;
        },
        (error) => {
          this.spin = false;
        }
      );
    });
  }

  requestExcelData(productPieceGrid: any, gridColumns: any[]) {
    var listObject = productPieceGrid.map(function (obj) {
      var otherStatusArray = obj.otherStatus.split("</br>")
      otherStatusArray.forEach((element, index) => {
          if (index == otherStatusArray.length - 1 && element == "") {
              otherStatusArray.splice(index,1)
          }
      });
      var otherStatusForExcel = otherStatusArray.join(',\n')
      obj.otherStatus = otherStatusForExcel;
      if (gridColumns != undefined && gridColumns != null) {
        var objNew: any = {};
        gridColumns.forEach((e) => {
          if (
            e.name == "mappedPieceForExcel" ||
            e.name == "noReOrder" ||
            e.name == "size" ||
            e.name == "shortSerialNo"
          ) {
            objNew[this.getGridColumnName(e.name)] = obj[e.name] == 0 ? null : obj[e.name] ;
          } else {
            if (e.visible) {
              if (e.name == "mappedPiece") {
                objNew[this.getGridColumnName(e.name)] = obj[e.name];
              }else if (e.name == "orderIdUrl") {
                objNew[this.getGridColumnName("orderId")] = obj["orderId"] == 0 ? null : obj["orderId"];
              }
              else if (e.name == "upcycled") {
                if(obj["isUpcycled"] ==false){

                  objNew[this.getGridColumnName(e.name)] = '';
                }
                else{
                  objNew[this.getGridColumnName(e.name)] = obj["isUpcycled"];

                }
              } else if (e.name == "bespoke") {
                if(obj["isBespoke"] ==false)
                objNew[this.getGridColumnName(e.name)] = '';
              else{
                objNew[this.getGridColumnName(e.name)] = obj["isBespoke"];

              }
              } else if (e.name == "pieceSerialNumber") {
                objNew[this.getGridColumnName("serialNo")] = obj["serialNo"];
              }
              else if (e.name == "exportPrice" || e.name == "insuranceValue") {
                let val = obj[e.name];
                val = val.replace("$","");
                objNew[this.getGridColumnName(e.name)] = val;
              }else if (e.name == "designName") {
                objNew[this.getGridColumnName("design")] = obj["design"];
              }else if (e.name == "productName") {
                objNew[this.getGridColumnName("product")] = obj["product"];
              }else if (e.name == "bespokeOrderFormNo") {
                objNew[this.getGridColumnName("bespokeOrderFormNoTextOnly")] = obj["bespokeOrderFormNoTextOnly"];
              }else if (e.name == "bookingDetails") {
                objNew[this.getGridColumnName("bookingData")] = obj["bookingData"];
              } else if (e.name != "mappedPiece") {
                objNew[this.getGridColumnName(e.name)] = obj[e.name];
              }

            }
          }
        }, this);
        return objNew;
      } else {

        return obj;
      }
    }, this);
    var list = new List<any>(listObject);
    return list;
  }

  getGridColumnName(key: string) {
    var localizationData = MultiLingualData.get(this.componentName) as any;
    switch (key) {
      case "designName":
        return localizationData.ProductPieceListComponent_Design_ex_ex;
      case "design":
        return localizationData.ProductPieceListComponent_Design_ex_ex;
      case "productName":
        return localizationData.ProductPieceListComponent_ProductVariantName_ex_ex;
      case "product":
        return localizationData.ProductPieceListComponent_ProductVariantName_ex_ex;
      case "coreProduct":
        return localizationData.ProductPieceListComponent_IsCoreProduct_ex_ex;
      case "superSeller":
        return localizationData.ProductPieceListComponent_IsSuperSeller_ex_ex;
      case "serialNo":
        return localizationData.ProductPieceListComponent_Serial_ex_ex;
      case "shortSerialNo":
        return localizationData.ProductPieceListComponent_ShortSerialNo_ex_ex;
      case "size":
        return localizationData.ProductPieceListComponent_Size_ex_ex;
      case "orderIdUrl":
        return localizationData.ProductPieceListComponent_OrderId_ex_ex;
      case "orderId":
        return localizationData.ProductPieceListComponent_OrderId_ex_ex;
      case "noReOrder":
        return localizationData.ProductPieceListComponent_NoReOrder_ex_ex;
      case "customerName":
        return localizationData.ProductPieceListComponent_PieceOwnerCustomer_ex_ex;
      case "supplier":
        return localizationData.ProductPieceListComponent_Supplier_ex_ex;
      case "bookingDetails":
        return localizationData.ProductPieceListComponent_BookingDetails_ex_ex;
      case "bookingData":
        return localizationData.ProductPieceListComponent_BookingDetails_ex_ex;
      case "collectionName":
        return localizationData.ProductPieceListComponent_CollectionName_ex_ex;
      case "oldInventoryNo":
        return localizationData.ProductPieceListComponent_OldInventoryNumber_ex_ex;
      case "dutyStatus":
        return localizationData.ProductPieceListComponent_DutyStatus_ex_ex;
      case "location":
        return localizationData.ProductPieceListComponent_LocationCity_ex_ex;
      case "salesChannel":
        return localizationData.ProductPieceListComponent_SalesChannel_ex_ex;
      case "productStatus":
        return localizationData.ProductPieceListComponent_AvailabilityStatus_ex_ex;
      case "mappedPieceForExcel":
        return localizationData.ProductPieceListComponent_MappedPiece_ex_ex;
      case "createdOn":
        return localizationData.ProductPieceListComponent_CreatedOn_ex_ex;
      case "shippedDate":
        return localizationData.ProductPieceListComponent_ShippedDate_ex_ex;
      case "supplierDate":
        return localizationData.ProductPieceListComponent_SupplierDate_ex_ex;
      case "forSaleDate":
        return localizationData.ProductPieceListComponent_AvailableForSale_ex_ex;
      case "insuranceValue":
        return localizationData.ProductPieceListComponent_InsurrenceValue_ex_ex;
      case "upcycled":
        return localizationData.ProductPieceListComponent_Upcycled_ex_ex;
      case "grossProfitMargin":
        return localizationData.ProductPieceListComponent_ProfitMargin_ex_ex;
      case "bespoke":
        return localizationData.ProductPieceListComponent_Bespoke_ex_ex;
      case "bespokeOrderFormNo":
        return localizationData.ProductPieceListComponent_BespokeOrderFormNo_ex_ex;
      case "bespokeOrderFormNoTextOnly":
        return localizationData.ProductPieceListComponent_BespokeOrderFormNo_ex_ex;
      case "exportPrice":
        return localizationData.ProductPieceListComponent_Cost_ex_ex;
      case "purchaseOrderNo":
        return localizationData.ProductPieceListComponent_PurchaseOrderNo_ex_ex;
      case "supplierOrderNo":
        return localizationData.ProductPieceListComponent_SupplierOrderNo_ex_ex;
      case "isConsigned":
        return localizationData.ProductPieceListComponent_IsConsigned_ex_ex;
      case "categoryName":
        return localizationData.ProductPieceListComponent_Category_ex_ex;
      case "legalOwner":
        return localizationData.ProductPieceListComponent_LegalOwner_ex_ex;
      case "actualDeliveryDate":
        return localizationData.ProductPieceListComponent_DeliveryDate_ex_ex;
      case "productionStatus":
        return localizationData.ProductPieceListComponent_ProductionStatus_ex_ex;
      case "consignmentStatus":
        return localizationData.ProductPieceListComponent_ConsignmentStatus_ex_ex;
      case "salesStatus":
        return localizationData.ProductPieceListComponent_SalesStatus_ex_ex;
      case "otherStatus":
        return localizationData.ProductPieceListComponent_OtherStatus_ex_ex;
      case "productVariantBatchId":
          return localizationData.ProductPieceListComponent_BatchNo_ex_ex;
      case "gtinNumber":
          return localizationData.ProductPieceListComponent_GTINNumber_ex_ex;
      case "country":
          return localizationData.ProductPieceListComponent_Country_ex_ex;
      default:
        return "";
    }
  }

  public downloadDocument(data: any, fileName: string, fileType: string) {
    var binaryString = window.atob(data.result.result);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var link = document.createElement("a");
    var blob = new Blob([bytes], { type: fileType });
    link.href = window.URL.createObjectURL(blob);
    link.download = data.result.fileName;
    document.body.appendChild(link);
    link.click();
  }

  onPageSorting(x, y, z) {
    this.isFilter = true;
    this.productPieceSearchFormGroup.value.sortOrder = y;
    this.productPieceSearchFormGroup.value.orderBy = x;
    this.piecesGrid.storeProcedure.nextPage = z;
    this.piecesGrid.updateSource([]);
    this.bindGrid();
  }

  onPageChanging(x) {
    this.isFilter = true;
    this.productPieceSearchFormGroup.value.pageIndex = x;
    this.piecesGrid.updateSource([]);
    if (
      this.productPieceSearchFormGroup.value.rowCount !=
      this.piecesGrid.maxPerPage
    ) {
      this.productPieceSearchFormGroup.value.rowCount =
        this.piecesGrid.maxPerPage;
      this.piecesGrid.storeProcedure.nextPage = x;
      this.isFilter = false;
      this.piecesGrid = null;
      this.isShowGrid = false;
      setTimeout(() => {
        this.bindGrid();
      }, 400);
    } else {
      this.productPieceSearchFormGroup.value.rowCount =
        this.piecesGrid.maxPerPage;
      this.piecesGrid.storeProcedure.nextPage = x;
      this.bindGrid();
    }
  }

  importExcel() {
    this.modalView
      .show(UpdatePiecesFromExcelComponent, { tripId: this.id })
      .then((t) => {});
  }

  get componentName(): string {
    return "ProductPieceListComponent";
  }
}

class StatusLookup {
  productStatusName: string;
  productStatusId: number;
}
class CategoryLookup {
  abbreviation: string;
  categoryId: number;
  categoryName: string;
  categoryTypeId: number;
  categoryTypeName: string;
  displayOrder: number;
  published: boolean;
}
class ExportToExcelModel {
  Result: string;
  Type: string;
  FileName: string;
  Description: string;
  ModuleId: number;
}
class SaleSuccessData {
  "image": any;
  "design": string;
  "pieceNumber": string;
  "oldInventoryNumber": string;
  "product": string;
  "availabilityStatus": string;
  "coreProduct": string;
  "superSeller": string;
  "exportRetailPrice": string;
  "location": string;
  "supplier": string;
  "createdOn": string;
  "deliveryDate": string;
  "bookingData": string;
  "collection": string;
  "dutyStatus": string;
  "availableForSale": string;
  "upcycled": string;
  "insuranceValue": string;
  "bespoke": string;
  "grossProfitMargin": string;
  "rrp": string;
  "sizes": string;
  "orderId": string;
  "noReOrder": string;
  "mappedPieceForExcel": string;
  "file": FileDetailViewModel;
}
