import { prop,propObject,propArray,required,maxLength,range ,numeric,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductionOrderFormBase {

//#region productionOrderFormID Prop
        @prop()
        productionOrderFormID : number;
//#endregion productionOrderFormID Prop


//#region orderNo Prop
        @maxLength({ value: 50 })
        orderNo : string;
//#endregion orderNo Prop

//#region type Prop
        @prop()
        @required()
        type : number;
//#endregion type Prop

//#region requestDate Prop
        @prop()
        requestDate : Date;
//#endregion requestDate Prop

//#region status Prop
        @required()
        status : number;
//#endregion status Prop

//#region createdBy Prop
        //@required()
        @prop({defaultValue:0})
        createdBy : number;
//#endregion createdBy Prop

//#region modifiedOn Prop
        @prop()
        modifiedOn : Date;
//#endregion modifiedOn Prop

//#region modifiedBy Prop
        @prop({defaultValue:0})
        modifiedBy : number;
//#endregion modifiedBy Prop

//#region isDeleted Prop
        @required()
        isDeleted : boolean;
//#endregion isDeleted Prop

//#region approvedBy Prop
        @prop()
        approvedBy : number;
//#endregion approvedBy Prop

//#region ApproveDate Prop
        @prop()
        approveDate : Date;
//#endregion ApproveDate Prop

//#region clientID Prop
        @prop()
        clientID:number
//#endregion clientID Prop

//#region corporateID Prop
        @prop()
        corporateID:number
//#endregion corporateID Prop

//#region salesChannelID Prop
        @prop()
        @required()
        salesChannelID:number
//#endregion salesChannelID Prop

//#region salesPersonID Prop
        @prop()
        @required()
        salesPersonID:number
//#endregion salesPersonID Prop

//#region isMailSent Prop
        @prop()
        @required()
        isMailSent:boolean
//#endregion isMailSent Prop

//#region completedBy Prop
        @prop()
        completedBy:number
//#endregion completedBy Prop

//#region completedDate Prop
        @prop()
        completedDate:Date
//#endregion completedDate Prop

//#region submittedBy Prop
        @prop()
        submittedBy:number
//#endregion submittedBy Prop

//#region submitDate Prop
        @prop()
        submitDate:Date
//#endregion submitDate Prop

//#region personInvolved Prop
        @prop()
        personInvolved:string
//#endregion personInvolved Prop


}
