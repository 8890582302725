import {StateProvinceBase,CountryBase,} from '@app/database-models'
//Generated Imports
export class StateProvince extends StateProvinceBase 
{




//#region Generated Reference Properties
//#region country Prop
country : CountryBase;
//#endregion country Prop

//#endregion Generated Reference Properties












}