import { prop, email, trim, maxLength, numeric, required } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';
import { CLIENT_URL } from '../domain/system-constant';

export class UserSearchFilterViewModel {

  constructor() {
    this.industryType = 0;
    this.salesChannel = 0;
    this.salesAdvisor = 0;
    this.roles = 0;
    this.country = 0;
    //this.rating = 0;
    this.keyRelationshipHolder = 0;
    this.pageIndex = PaginationEnum.PageIndex
    this.rowCount = PaginationEnum.RowCount;
    this.arrested = false;
    this.juvenile = false;
    this.isCancelledOrder=false;
    this.isBirthdate = false;
    this.artisanalIllegalMiner = 0;
    this.cateogry = "";
    this.collection = "";
    this.sortOrder = "true";
    this.orderBy = "name";
    //this.clientUrl= CLIENT_URL ;
    this.searchString = "";
    this.department = 0;
  }
  @prop()
  registrationFrom: Date;

  @prop()
  registrationTo: Date;

  @prop()
  @trim()
  name: string;

  @prop()
  @trim()
  nameText: string;

  @prop()
  //@email()
  @trim()
  email: string;


  @prop()
  //@email()
  @trim()
  emailText: string;

  @prop()
  salesAdvisor: number;

  @prop()
  salesChannel: number;

  @prop()
  roles: number;

  @prop()
  country: number;

  @prop()
  rating: number;

  @prop()
  keyRelationshipHolder: number;

  @prop()
  industryType: number;

  @prop()
  @trim()
  hasPurchasedFrom: string;

  @prop()
  isCancelledOrder: boolean;

  @prop()
  @trim()
  cateogry: string;

  @prop()
  @trim()
  collection: string;

  @prop()
  juvenile: boolean;

  @prop()
  arrested: boolean;

  @prop()
  deceased: boolean;

  @prop()
  artisanalIllegalMiner: number;

  @prop()
  isBirthdate:boolean;

  @prop()
  dateofBirthFrom: Date;

  @prop()
  birthDateFrom : number;

  @prop()
  birthMonthFrom : number;


  @prop()
  birthDateTo : number;

  @prop()
  birthMonthTo : number;

  @prop()
  dateofBirthTo: Date;

  @prop()
  anniversaryDateFrom: Date;

  @prop()
  anniversaryFromDate : number;

  @prop()
  anniversaryMonthFrom : number;


  @prop()
  anniversaryToDate : number;

  @prop()
  anniversaryMonthTo : number;


  @prop()
  anniversaryDateTo: Date;

  @prop()
  rowCount: number;

  @prop()
  pageIndex: number;

  @prop()
  @trim()
  sortOrder: string;

  @prop()
  @trim()
  orderBy: string;

  // @prop()
  // clientUrl:string;

  @prop()
  searchString:string;

   //#region jobTitle Prop
   @prop()
   @maxLength({ value: 500 })
   jobTitle: string;
   //#endregion jobTitle Prop

   //#region city Prop
   @prop()
   city: string;
   //#endregion city Prop

  @prop()
  nationality: number;

  @prop()
  phoneNumber:number;

  @prop()
  industryTypeIds: string="";

  @prop()
  department: Number;

  @prop()
  corporates: number;

  @prop()
  @trim()
  corporateText: string;

  @prop()
  @trim()
  profile: string;

  //#region title Prop  //287714//06-06-2024/added Title filter in user search 
  @prop()
  title: number;
  //#endregion title Prop

  @prop()
  @numeric()
  @maxLength({value:9})
  userId:number;
}
