import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vNationalityBase {

//#region nationalityId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'NationalityListComponent_Id_gh', keyColumn: true})
        nationalityId : number;
//#endregion nationalityId Prop


//#region nationalityName Prop
        @gridColumn({ isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'NationalityListComponent_Name_gh', keyColumn: false})
        nationalityName : string;
//#endregion nationalityName Prop

}