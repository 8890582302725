import {vDutyStatusLookUpBase,} from '@app/database-models'
//Generated Imports
export class vDutyStatusLookUp extends vDutyStatusLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}