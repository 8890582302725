import { notEmpty, prop, required, trim } from '@rxweb/reactive-form-validators';
import { StatusEnum } from 'src/app/enums/status.enum';

export class APIKeyBase {

    @prop({ defaultValue: 0 })
    id:number;

    @prop()
    token:string;

    @prop({defaultValue:''})
    description:string;

    @prop()
    userId:number;

    @prop()
    createdAt:Date;

    @prop()
    modifeidAt:Date;

    @prop({defaultValue: true})
    isActive:boolean;

    @prop()
    expiresAt:Date;

    @prop()
    requestCount:number;

    @prop()
    lastRequestAt:Date;

    @prop({defaultValue: false})
    deleted:boolean;

    @prop({defaultValue: StatusEnum.Active})
    statusId:number;

}