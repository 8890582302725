import { Component, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewContainerRef, EmbeddedViewRef, ViewChild } from "@angular/core"
import { Subscription } from 'rxjs';

import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';

import { KnownTo } from '@app/models';
import { AbstractKnownTo } from '../domain/abstract-known-to';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { UserKnownToFilterViewModel } from '../../../../view-model/user-known-to-filter-view-model';
import { AppGrid, AppGridDesigner, SelectDropDown } from '../../../../domain/app-grid';
import { KnownToUserListViewModel } from '../../../../models/extended-models/known-to-user-list-view-model';
import { StatusEnum } from '../../../../enums/status.enum';
import { ModalView } from '../../../../domain/customize-design/modal';
import { PaginationEnum } from '../../../../enums/pagination.enums';
import { dateCompareValidation, getLocalizedValue } from '../../../../domain/common-logic/common-logic';
import { multilingual } from '@rxweb/localization';

import { ElementOnDemand } from '@rxweb/grid';
import { FormControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { RxSelectComponent } from "@rxweb/angular";
import { DialogEnum } from "src/app/domain/customize-design/dialog";
import { DialogViewMode } from "@rxweb/js";
import { PlatformLocation } from "@angular/common";


@multilingual("KnownToAddComponent")
@Component({
    selector: "app-known-to-add",
    templateUrl: './known-to-add.component.html',
    entryComponents: [RxSelectComponent]
})
export class KnownToAddComponent extends AbstractKnownTo implements OnInit, OnDestroy {
    knownTo: KnownTo;
    userKnownToMapping: UserKnownToFilterViewModel;
    userKnownToListViewModel: KnownToUserListViewModel[];
    userKnownToMappingSearchFormGroup: IFormGroup<UserKnownToFilterViewModel>;
    subscription: any;
    @Input() userId: number;
    sort: boolean;
    @Input() sendData: Function;
    @Input() relationShipId: number = null;
    @Input() hide: Function;
    showSaveButton: boolean = false;
    knownToUserListGrid: AppGridDesigner;
    knownToUserListGridColumns: any[];
    knownToUserMappingInfo: KnownTo;
    knownToUserMappingArray: KnownTo[];
    selectedItems: number;
    totalRecords: number = 0;
    pageIndex: number = PaginationEnum.PageIndex;
    rowCount: number = PaginationEnum.RowCount;
    isFilter: boolean = false;
    isSelect: boolean = true;
    KnownToIds : number[];
    excludeKnownToIds : number[];
    searchString: string;
    dateError: boolean = false;
    dropSource: any;
    dropSource1: any;
    dropSource2: any;
    headerBtnChk: boolean = false;
    cloneArray: KnownToUserListViewModel[];
    isAllSelected: boolean;
    trackArray: KnownToUserListViewModel[];
    excludedTrackArray: KnownToUserListViewModel[];
    titleClose: string;
    ErrorMsg:string;
    filterJson:any;
    userRelationArray:KnownTo[];
    searchNameText: string = ''
    searchEmailText: string = ''


    _this;

    constructor(private formBuilder: RxFormBuilder, modalView: ModalView, private componentResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef,private location : PlatformLocation) {
        super();
        this.modalView = modalView;
        this.knownToUserMappingArray = new Array<KnownTo>();
        this.KnownToIds = new Array<number>();
        this.excludeKnownToIds = new Array<number>();
        this.userRelationArray = new Array<KnownTo>();
        location.onPopState(() => {this.hide(),this.dialog.hide()});
    }

    ngOnInit(): void {
        this.titleClose = getLocalizedValue("Title_Close");
        this.isShowGrid = false;
        this.spin = true;
        this.sort = true;
        this.totalRecords = 0;
        this.selectedItems = 0;
        this.isAllSelected = true;
        this.cloneArray = new Array<KnownToUserListViewModel>();
        this.trackArray = new Array<KnownToUserListViewModel>();
        this.excludedTrackArray = new Array<KnownToUserListViewModel>();
        this.lookup([{ path: USER_LOOKUPS["salesUserLookup"], propName: "salesAdvisorLookUp" }]).subscribe((lookup: any) => {
            this.userKnownToMapping = new UserKnownToFilterViewModel();
            this.userKnownToMapping.userId = this.userId;
            // this.userKnownToMapping.pageIndex = PaginationEnum.PageIndex;
            // this.userKnownToMapping.rowCount = PaginationEnum.RowCount;
            this.userKnownToLookUp = lookup;
            this.userKnownToMappingSearchFormGroup = this.formBuilder.formGroup(this.userKnownToMapping) as IFormGroup<UserKnownToFilterViewModel>;
            this.showComponent = true;
            this.spin = false;
        });
    }
    searchKnownToUser() {
        //this.isShowGrid=false;
        // if (this.knownToUserListGrid && this.isShowGrid) {
        // this.knownToUserListGrid.refresh("knownToSearch");
        // }
        if (dateCompareValidation(this.userKnownToMappingSearchFormGroup.value.ragistrationFromDate, this.userKnownToMappingSearchFormGroup.value.ragistrationToDate) == true) {
            this.dateError = true;
        }
        else {
            this.dateError = false;
            this.selectedItems = 0;
            this.trackArray = [];
            this.cloneArray = [];
            if(this.headerBtnChk) {
                this.excludeKnownToIds = [];
                this.excludedTrackArray = [];
                this.trackArray = [];
                this.KnownToIds = [];
                this.userRelationArray = [];
            }
            this.headerBtnChk = false;
            if (this.knownToUserListGrid) {
                this.isFilter = true;
                this.knownToUserListGrid.storeProcedure.nextPage = 1;
                this.userKnownToMappingSearchFormGroup.value.rowCount = this.knownToUserListGrid.maxPerPage;
            }
            this.userKnownToMappingSearchFormGroup.patchValue({ pageIndex: 1 })

            this.bindGrid();
        }
    }
    bindGrid() {
        this.isShowGrid = true;
        this.totalRecords = 0;
        this.userKnownToMappingSearchFormGroup.submitted = true;
        if (this.userKnownToMappingSearchFormGroup.valid) {
            this.spin = true;
            if (this.userKnownToMappingSearchFormGroup.controls["emailText"].value == null || this.userKnownToMappingSearchFormGroup.controls["email"].value > 0) {
                this.userKnownToMappingSearchFormGroup.patchValue({
                    emailText: ''
                })
            }
            if (this.userKnownToMappingSearchFormGroup.controls["nameText"].value == null || this.userKnownToMappingSearchFormGroup.controls["name"].value > 0) {
                this.userKnownToMappingSearchFormGroup.patchValue({
                    nameText: ''
                })
            }
            if (this.userKnownToMappingSearchFormGroup.controls["email"].value == '') {
                this.userKnownToMappingSearchFormGroup.patchValue({
                    email: null
                })
            }
            if (this.userKnownToMappingSearchFormGroup.controls["name"].value == '') {
                this.userKnownToMappingSearchFormGroup.patchValue({
                    name: null
                })
            }

            if (this.userKnownToMappingSearchFormGroup.value.nameText != '' && this.userKnownToMappingSearchFormGroup.value.nameText != null) {
                this.userKnownToMappingSearchFormGroup.patchValue({
                    name: null
                })
            }


            if (this.userKnownToMappingSearchFormGroup.value.emailText != '' && this.userKnownToMappingSearchFormGroup.value.emailText != null) {
                this.userKnownToMappingSearchFormGroup.patchValue({
                    email: null
                })
            }

            var json = JSON.parse(JSON.stringify(this.userKnownToMappingSearchFormGroup.value));
            this.filterJson = json;
            this.subscription = this.post({ path: "api/SearchKnownToLookUpsSearch", body: { query: JSON.stringify(json) } }).subscribe((t: any) => {
                //this.isShowGrid = true;
                this.spin = false;
                this.userKnownToMappingSearchFormGroup.patchValue({
                    //emailText: null,
                    //nameText: null
                })
                this.userKnownToListViewModel = t[0].UserData;
                this.ErrorMsg=t[0].ErrorMessage;
                if (this.userKnownToListViewModel.length > 0) {
                    this.dropSource = this.userKnownToListViewModel[0].relationshipLookup;
                    this.dropSource1 = this.userKnownToListViewModel[0].relationshipStatusLookUp;
                    this.dropSource2 = this.userKnownToListViewModel[0].parentRelationshipStatusLookUp;
                    this.userKnownToListViewModel.forEach(x => x.isChecked = this.headerBtnChk);
                    this.userKnownToListViewModel.forEach(x => {
                        x.isChecked = this.headerBtnChk;
                    })

                    this.userKnownToListViewModel.forEach(x => {
                        if (this.cloneArray.findIndex(z => z.id == x.id) == -1) {
                            this.cloneArray.push(x);
                        }
                        else {
                            this.cloneArray.forEach(z => {
                                if (z.id == x.id) {
                                    x.isChecked = z.isChecked
                                }
                            })
                        }
                        if(this.headerBtnChk) {
                            if(this.excludeKnownToIds.indexOf(Number(x.id)) != -1) {
                                x.isChecked = false;
                            }
                        }
                        else{
                            if(this.KnownToIds.indexOf(Number(x.id)) != -1) {
                                x.isChecked = true;
                            }
                        }
                    })
                    this.cloneArray.forEach(z => {
                        if (z.isChecked) {
                            this.trackArray.push(t);
                        }
                        else {
                            this.trackArray.splice(this.trackArray.findIndex(x => x.id == t.id), 1);
                        }
                    })
                    //this.updateProjectMappingArray();
                    this.totalRecords = this.userKnownToListViewModel[0].totalCount;
                    this.isNoRecordFound = false;
                }
                else {
                    this.isShowGrid = false;
                    this.isNoRecordFound = true;
                }
                // if(this.knownToUserListGrid == null)
                // {
                //     this.isFilter = false;
                // }
                if (this.isShowGrid == true) {
                    if (!this.isFilter) {
                        this.knownToUserListGrid = new AppGridDesigner(this.userKnownToListViewModel, KnownToUserListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this),onEmergencyContactCheckBoxSelect: this.onEmergencyContactCheckBoxSelect.bind(this),onIsCloseCheckBoxSelect: this.onIsCloseCheckBoxSelect.bind(this), '$onChecked': this.onChecked.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } }, this.componentResolver, this.viewContainerRef, this.isSelect, this.dropSource,this.dropSource1,this.dropSource2);

                        this.knownToUserListGrid.storeProcedure = {
                            length: this.totalRecords,
                            onPageChanging: this.onPageChanging.bind(this),
                            nextPage: 1,
                            onPageSorting: this.onPageSorting.bind(this)
                        }
                        this.knownToUserListGrid.componentId = this.componentName;
                        this.knownToUserListGrid.designClass.tableClass.push('table-responsive', 'chosen-drop-static');
                        this.knownToUserListGrid.design(document.getElementById("knownToSearch"));
                    }
                    else {
                        this.knownToUserListGrid = new AppGridDesigner(this.userKnownToListViewModel, KnownToUserListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this),onEmergencyContactCheckBoxSelect: this.onEmergencyContactCheckBoxSelect.bind(this),onIsCloseCheckBoxSelect: this.onIsCloseCheckBoxSelect.bind(this), '$onChecked': this.onChecked.bind(this), onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } }, this.componentResolver, this.viewContainerRef, this.isSelect, this.dropSource,this.dropSource1,this.dropSource2);
                        this.knownToUserListGrid.maxPerPage = this.userKnownToMappingSearchFormGroup.value.rowCount;
                        this.knownToUserListGrid.refresh("knownToSearch");
                        this.knownToUserListGrid.componentId = this.componentName;
                        this.knownToUserListGrid.storeProcedure = {
                            length: this.totalRecords,
                            onPageChanging: this.onPageChanging.bind(this),
                            nextPage: this.userKnownToMappingSearchFormGroup.value.pageIndex,
                            onPageSorting: this.onPageSorting.bind(this)
                        }
                        // this.clearGrid();

                        //this.knownToUserListGrid.useUpdatedSource=true;
                        //this.knownToUserListGrid.updatedSource=this.userKnownToListViewModel;

                        this.knownToUserListGrid.designClass.tableClass.push('table-responsive', 'chosen-drop-static');
                        this.knownToUserListGrid.design(document.getElementById("knownToSearch"));

                         let headerCheckboxEle = document.getElementById("KnownTo-checkbox") as HTMLInputElement;;
            
                         headerCheckboxEle.checked = this.headerBtnChk;
                        
            
                        this.knownToUserListGrid.updateSource(this.userKnownToListViewModel);

                    }
                    this.knownToUserListGrid.maxPerPage = this.userKnownToMappingSearchFormGroup.value.rowCount;
                    this.showSaveButton = true;
                }
            })
        }
    }
    get componentName(): string {
        return "KnownToAddComponent";
    }
    clearGrid() {
        // if (this.knownToUserListGrid) {
        //     this.knownToUserListGrid.refresh("known-to-user-list")
        // }
        this.knownToUserListGrid.cnt = 0;
        this.knownToUserListGrid.cnt3 = 0;
        this.knownToUserListGrid.cnt5 = 0;
        this.knownToUserListGrid.updateSource([]);
    }



    checkNameText(event: any) {
        this.userKnownToMappingSearchFormGroup.patchValue({
            nameText: event.target.value
        })
        this.searchNameText = event.target.value;
    }
    checkEmailText(event: any) {
        this.userKnownToMappingSearchFormGroup.patchValue({
            emailText: event.target.value
        })
        this.searchEmailText = event.target.value;
    }
    onCheckboxSelect(t, x, y, z, e) {
        // this.cloneArray.forEach(z=>{
        //     if(z.id==t.id){
        //       z.isChecked=x.target.checked;
        //       var relationId=this.knownToUserListGrid.source.filter(t=>t.id==x.id)[0]
        //       z.relationShipId=relationId==null?1:relationId.relationShipId;
        //     }
        //   })
        if (x.target.checked) {
            if(this.headerBtnChk) {
                if(this.excludeKnownToIds.indexOf(Number(t.id)) != -1) {
                    this.excludedTrackArray.splice(this.excludeKnownToIds.indexOf(Number(t.id)),1);
                    this.excludeKnownToIds.splice(this.excludeKnownToIds.indexOf(Number(t.id)),1);
                }
            }
            else {
                this.trackArray.push(t);
                if(this.KnownToIds.indexOf(t.id) == -1)
                this.KnownToIds.push(t.id);
            }
        }
        else {
            if(this.headerBtnChk) {
                if(this.excludeKnownToIds.indexOf(Number(t.id)) == -1) {
                    this.excludedTrackArray.push(t);
                    this.excludeKnownToIds.push(Number(t.id));
                }
            }
            else {
                this.trackArray.splice(this.trackArray.findIndex(x => x.id == t.id), 1);
                if(this.KnownToIds.indexOf(t.id) != -1)
                this.KnownToIds.splice(this.KnownToIds.indexOf(t.id),1);
            }
        }
        // this.clearGrid();
        // this.knownToUserListGrid.useUpdatedSource=true;
        // this.knownToUserListGrid.updatedSource=this.cloneArray;
        // this.knownToUserListGrid.updateSource(this.cloneArray);
    }

    updateKnownToArray() {
        this.cloneArray.forEach(x => {
            if (x.isChecked == true) {
                this.knownToUserListGrid.source.forEach(z => {
                    if (z.id == x.id) {
                        z.isSelected = true;

                    }

                })
            }
            else {
                this.knownToUserListGrid.source.forEach(y => {
                    if (y.id == x.id) {
                        y.isSelected = false;

                    }

                })
            }
        });

    }


    onChecked(x, y) {
        return this.headerBtnChk
    }

    onEmergencyContactCheckBoxSelect(t, x, y, z) {
        let obj = this.userKnownToListViewModel.find(x => x.id == t.id)
        if(obj){
            obj.isEmergencyContact = x.target.checked ? true : false;
        }
        let obj1 = this.trackArray.find(x => x.id == t.id)
        if(obj1){
            obj1.isEmergencyContact = x.target.checked ? true : false;
        }
        let obj2 = this.knownToUserListGrid.source.find(x => x.id == t.id)
        if(obj2){
            obj2.isEmergencyContact = x.target.checked ? true : false;
        }
    }

    onIsCloseCheckBoxSelect(t, x, y, z) {
        let obj = this.userKnownToListViewModel.find(x => x.id == t.id)
        if(obj){
            obj.isClose = x.target.checked ? true : false;
        }
        let obj1 = this.trackArray.find(x => x.id == t.id)
        if(obj1){
            obj1.isClose = x.target.checked ? true : false;
        }
        let obj2 = this.knownToUserListGrid.source.find(x => x.id == t.id)
        if(obj2){
            obj2.isClose = x.target.checked ? true : false;
        }
    }

    onHeaderCheckBoxSelect(t, x) {
        this.spin = true;
        this.headerBtnChk =  x.target.checked;
        this.isAllSelected = !this.isAllSelected;
        this.cloneArray = new Array<KnownToUserListViewModel>();
        this.excludeKnownToIds = [];
        this.excludedTrackArray = [];
        this.trackArray = [];
        this.KnownToIds = [];
        this.userRelationArray = [];


        this.knownToUserListGrid.source.forEach(z => {
            let newObj = new KnownToUserListViewModel();
            newObj.dropdownData = z.dropdownData;
            newObj.relationshipStatusDropdown = z.relationshipStatusDropdown;
            newObj.email = z.email;
            newObj.fullName = z.fullName;
            newObj.id = z.id;
            newObj.isSelected = z.isSelected;
            newObj.registrationDate = z.registrationDate;
            newObj.relationShipId = z.relationShipId;
            newObj.statusId = z.statusId;
            newObj.totalCount = z.totalCount;
            newObj.isChecked = false;
            newObj.contactPermissionRequired = z.contactPermissionRequired;
            newObj.introducesBy = z.introducesBy;
            newObj.isUnwelcome = z.isUnwelcome;
            newObj.relationshipStatusId = z.relationshipStatusId;
            newObj.parentRelationshipId = z.parentRelationshipId;
            newObj.isEmergencyContact = z.isEmergencyContact;
            newObj.isClose = z.isClose;
            newObj.currentUsername = z.currentUsername;
            this.cloneArray.push(newObj);
        })
        this.cloneArray.forEach(y => {
            y.isChecked = this.headerBtnChk;
        })
        this.cloneArray.forEach(z => {
            if(this.headerBtnChk) {
                if(z.isChecked) {
                    if(this.excludeKnownToIds.indexOf(Number(z.id)) != -1) {
                        this.excludedTrackArray.splice(this.excludeKnownToIds.indexOf(Number(z.id)),1);
                        this.excludeKnownToIds.splice(this.excludeKnownToIds.indexOf(Number(z.id)),1);
                    }
                }
                else {
                    if(this.excludeKnownToIds.indexOf(Number(z.id)) == -1) {
                        this.excludedTrackArray.push(z);
                        this.excludeKnownToIds.push(Number(z.id));
                    }
                }
            }
            else {
                if (z.isChecked) {
                    this.trackArray.push(z);
                }
                else {
                    this.trackArray.splice(this.trackArray.findIndex(x => x.id == z.id), 1);
                }
            }
        })



        // this.updateKnownToArray();
        // this.userKnownToListViewModel.forEach(data=>{
        //     data.isChecked=false;

        // });
        // this.userKnownToListViewModel.forEach(data=>{
        //     data.isChecked=this.headerBtnChk;

        // });
        //     this.knownToUserListGrid.refresh("knownToSearch");
        //     this.knownToUserListGrid=null;
        //     this.knownToUserListGrid = new AppGridDesigner(this.userKnownToListViewModel, KnownToUserListViewModel, { actions: { onCheckBoxSelect: this.onCheckboxSelect.bind(this),onHeaderCheckBoxSelect: this.onHeaderCheckBoxSelect.bind(this) } },this.componentResolver,this.viewContainerRef,this.isSelect);
        //     this.knownToUserListGrid.storeProcedure = {
        //         length: this.totalRecords,
        //         onPageChanging: this.onPageChanging.bind(this),
        //         nextPage: this.userKnownToMappingSearchFormGroup.value.pageIndex,
        //         onPageSorting: this.onPageSorting.bind(this)
        //   }

        //   this.knownToUserListGrid.currentPage=this.userKnownToMappingSearchFormGroup.value.pageIndex;
        //   this.knownToUserListGrid.pagingSource.splice(this.knownToUserListGrid.pagingSource.findIndex(x=>x==this.userKnownToMappingSearchFormGroup.value.rowCount),1);
        //   this.knownToUserListGrid.pagingSource.unshift(this.userKnownToMappingSearchFormGroup.value.rowCount);
        //   this.knownToUserListGrid.maxPerPage = this.userKnownToMappingSearchFormGroup.value.rowCount;
        //   this.knownToUserListGrid.componentId = this.componentName;
        //   this.knownToUserListGrid.design(document.getElementById("knownToSearch"));
        this.clearGrid();
        this.knownToUserListGrid.useUpdatedSource = true;
        this.knownToUserListGrid.updatedSource = this.cloneArray;
        this.knownToUserListGrid.updateSource(this.cloneArray);
        setTimeout(() => {
            this.spin = false;
        }, 250);
        
    }

    save(){
        if(this.headerBtnChk){
         this.dialog.designClass.root = ["sweet-alert", "showSweetAlert", "visible"];
         this.dialog.defaultConfig.showIcon = true;
         this.dialog.designClass.button.cancel = ["btn-cancle","sweet-alert-cancel-button",];
         this.dialog.defaultConfig.text.heading = getLocalizedValue("MultiSelect_Alert_Heading_t") + " total " +  (this.totalRecords - this.excludedTrackArray.length)  + " Records";
         this.dialog
               .show(getLocalizedValue("MultiSelect_Alert_Body_t"), DialogViewMode.okWithCancel)
               .then((t) => {
                 if (t === DialogEnum.Ok) {
                   this.saveKnownToUser()
                   this.dialog.hide();
                 }
                 else{
                   this.spin = false;
                   this.dialog.hide();
                 }
               });
        }
        else{
         this.saveKnownToUser()
        }
     }

    saveKnownToUser() {
        this.allowEdit = true;
        this.knownToUserMappingInfo = new KnownTo();
        this.knownToUserMappingInfo.knownToUserIds = new Array<KnownTo>();
        this.trackArray.forEach(x => {
            var relationId = this.knownToUserListGrid.source.filter(t => t.id == x.id)[0]
            if (relationId!=null &&(relationId.relationShipId == null || relationId.relationShipId  == 0 || relationId.parentRelationshipId == null || relationId.parentRelationshipId  == 0)) {
                this.allowEdit = false;
                return false;
            }
        })
        if (!this.allowEdit) {
            // alert("Please Select RelationShip");
            var existsAlert = "Please Select Relationship";
            if (existsAlert) {
                this.toastr.warning(existsAlert);
            }
        }
        else {
            if(this.headerBtnChk) {
                this.excludedTrackArray.forEach(x => {
                    var relationId = this.knownToUserListGrid.source.filter(t => t.id == x.id)[0]
                    let knownToUserMappingObj = new KnownTo(); 
                    knownToUserMappingObj.knownToId = 0;
                    knownToUserMappingObj.userRelationId = Number(x.id);
                    knownToUserMappingObj.userId = this.userId;
                    knownToUserMappingObj.statusId = StatusEnum.Active;
                    knownToUserMappingObj.relationShipId = relationId.relationShipId;
                    this.knownToUserMappingInfo.knownToUserIds.push(knownToUserMappingObj);
                })
                this.knownToUserMappingInfo.headerBtnChk = this.headerBtnChk;
                this.filterJson["rowCount"] = this.totalRecords;
                this.filterJson["pageIndex"] = 1;
                this.filterJson["sortOrder"] = "false";
                this.knownToUserMappingInfo.query = JSON.stringify(this.filterJson);
                this.saveUserRelationArray();
                this.userRelationArray.forEach(x => {
                    if (x.relationShipId == null || x.relationShipId  == 0 || x.parentRelationshipId == null || x.parentRelationshipId  == 0) {
                        this.allowEdit = false;
                        return false;
                    }
                })
                this.knownToUserMappingInfo.relationArray = this.userRelationArray;
                this.knownToUserMappingInfo.userId = this.userId;
            }
            else {
                this.trackArray.forEach(x => {
                    var relationId = this.knownToUserListGrid.source.filter(t => t.id == x.id)[0]
                    let knownToUserMappingObj = new KnownTo();
                    knownToUserMappingObj.knownToId = 0;
                    knownToUserMappingObj.userRelationId = Number(x.id);
                    knownToUserMappingObj.userId = this.userId;
                    knownToUserMappingObj.statusId = StatusEnum.Active;
                    knownToUserMappingObj.relationShipId = relationId.relationShipId;
                    knownToUserMappingObj.relationshipStatusId = relationId.relationshipStatusId;
                    knownToUserMappingObj.parentRelationshipId = relationId.parentRelationshipId;
                    knownToUserMappingObj.isEmergencyContact = relationId.isEmergencyContact;
                    knownToUserMappingObj.isClose = relationId.isClose;
                    this.knownToUserMappingInfo.knownToUserIds.push(knownToUserMappingObj);
                })
            }
            if (!this.allowEdit) {
                var existsAlert = "Please Select Relationship";
                if (existsAlert) {
                    this.toastr.warning(existsAlert);
                }
            }
            else{
                this.spin = true;
                this.post({ body: this.knownToUserMappingInfo }).subscribe(data => {
                    this.spin = false;
                    var existsAlert = getLocalizedValue("Person_Added");
                    if (existsAlert) {
                        this.toastr.success(existsAlert);
                    }
                    this.isFilter = true;
                    this.knownToUserMappingArray = [];
                    this.trackArray = [];
                    this.hide();
                });
            }
        }
    }
    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    search(value) {
        this.knownToUserListGrid.search = value.target.value;
    }
    onPageSorting(x, y, z) {
        this.isFilter = true;
        this.userKnownToMappingSearchFormGroup.patchValue({ sortOrder: (!this.sort).toString() })
        this.sort = !this.sort;
        this.userKnownToMappingSearchFormGroup.patchValue({ orderBy: x, pageIndex: z });
        this.knownToUserListGrid.storeProcedure.nextPage = z;
        this.bindGrid();
    }

    onPageChanging(x) {
        this.isFilter = true;
        let fromIndex: number = this.userKnownToMappingSearchFormGroup.value.rowCount;
        let toIndex: number = this.knownToUserListGrid.maxPerPage;
        if (this.userKnownToMappingSearchFormGroup.value.pageIndex < x) {
            this.cloneArray = [];
        }
        if(this.headerBtnChk) {
            this.cloneArray = [];
        }
        if (this.knownToUserListGrid.maxPerPage < this.userKnownToMappingSearchFormGroup.value.rowCount) {
            for (let i = fromIndex; i > toIndex; i--) {
                this.cloneArray.splice(i - 1, 1);
            }
        }
        this.userKnownToMappingSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.knownToUserListGrid.maxPerPage })
        this.knownToUserListGrid.storeProcedure.nextPage = x;

        this.saveUserRelationArray();

        this.bindGrid();
    }


    saveUserRelationArray() {
        var updatedList = this.knownToUserListGrid.source;
        updatedList.forEach(x => {
            if(this.userRelationArray.findIndex(t => t.userRelationId == x.id) == -1) {
                let obj = new KnownTo();
                obj.userRelationId = x.id;
                obj.relationShipId = x.relationShipId;
                obj.relationshipStatusId = x.relationshipStatusId;
                obj.parentRelationshipId = x.parentRelationshipId;
                obj.isEmergencyContact = x.isEmergencyContact;
                obj.isClose = x.isClose;
                this.userRelationArray.push(obj);
            }
            else{
                let existingObj = this.userRelationArray.find(t => t.userRelationId == x.id);
                if(existingObj){
                    existingObj.userRelationId = x.id;
                    existingObj.relationShipId = x.relationShipId;
                    existingObj.relationshipStatusId = x.relationshipStatusId;
                    existingObj.parentRelationshipId = x.parentRelationshipId;
                    existingObj.isEmergencyContact = x.isEmergencyContact;
                    existingObj.isClose = x.isClose;
                }
            }
        })
    }
    // onPageSorting(x, y, z) {
    //     this.isFilter = true;
    //     this.userKnownToMappingSearchFormGroup.patchValue({ sortOrder: y, orderBy: x,pageIndex:z })
    //     this.knownToUserListGrid.storeProcedure.nextPage = z;
    //     this.bindGrid();
    //   }

    //   onPageChanging(x) {
    //     this.isFilter = true;
    //     this.userKnownToMappingSearchFormGroup.patchValue({ pageIndex: x, rowCount: this.knownToUserListGrid.maxPerPage })
    //     this.knownToUserListGrid.storeProcedure.nextPage = x;
    //     this.bindGrid();
    //   }

}
