export class FileOutputViewModel {
  fileBaseString: string;
  fileExtension: string;
  fileExtensionType: string;
  fileName: string;
  fileMode: number;
  displayImage: boolean;
  fileModeId: number = 0;
  files: File;
  multipleFiles: File[];
  isMultipart: boolean = false;
  url: string;
}


