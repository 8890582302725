import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { prop, required, trim, numeric, pattern, range } from '@rxweb/reactive-form-validators';
import { PaginationEnum } from '../enums/pagination.enums';

export class RiskMenuDetailFilterViewModel {
    constructor() {
        this.pageIndex = PaginationEnum.PageIndex
        this.rowCount = PaginationEnum.RowCount;
        this.sortOrder = "true";
        this.orderBy = "riskMenuDetailId";
    }    
    @prop()
    @required({message: "You need to first select Gemfields Group Company in order to add new Risks"})
    gemfieldGroupCompanyId: number;

    @prop()
    riskCategoryId: number;

    @prop()
    specificRiskId: number;

    @prop()
    localRiskOwnerId: number;

    @prop()
    boardRiskOwnerId: number;

    @prop()
    rowCount: number;
  
    @prop()
    pageIndex: number;
  
    @prop()
    @trim()
    sortOrder: string;
  
    @prop()
    @trim()
    orderBy: string;

    @prop()
    searchString:string;

    @prop()
    lastupdatesFrom: Date;

    @prop()
    lastupdatesTo: Date;

    @numeric({allowDecimal:false})
    @pattern({expression:{"orderId":/^[0-9]/},message:"Please enter valid orderId"})
    @range({minimumNumber:1,maximumNumber:2147483647})
    riskId: number;
}