import { prop, propObject, propArray, required, maxLength, range, email, url, alpha, numeric, trim, alphaNumeric, notEmpty, pattern } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class UserDetailBase {

  //#region userDetailId Prop
  @prop()
  userDetailId: number;
  //#endregion userDetailId Prop


  //#region userId Prop
  //  @range({ minimumNumber: 0, maximumNumber: 2147483647 })
  @required()
  userId: number;
  //#endregion userId Prop


  //#region firstName Prop
  @maxLength({ value: 100 })
  @trim()
  @required()
  @notEmpty()
  firstName: string;
  //#endregion firstName Prop

    //#region middleName Prop
    @maxLength({ value: 100 })
    @trim()
    //@required()
    //@notEmpty()
    middleName: string;
    //#endregion middleName Prop


  //#region lastName Prop
  @maxLength({ value: 100 })
  @trim()
  @required()
  @notEmpty()
  lastName: string;
  //#endregion lastName Prop


  //#region fullName Prop
  @maxLength({ value: 250 })
  @trim()
  fullName: string;
  //#endregion fullName Prop


  //#region title Prop
  //@required()
  //@range({minimumNumber: 1, maximumNumber: 2147483647,message:"You can't leave this empty"})
  @prop()
  title: number;
  //#endregion title Prop


  //#region company Prop
  @maxLength({ value: 1000 })
  company: string;
  //#endregion company Prop


  //#region industryTypeId Prop
  @prop()
  industryTypeId: number;
  //#endregion industryTypeId Prop


  //#region jobTitle Prop
  @maxLength({ value: 1000 })
  jobTitle: string;
  //#endregion jobTitle Prop


  //#region profileAbout Prop
  @prop()
  @trim()
  @maxLength({ value: 4000 })
  profileAbout: string;
  //#endregion profileAbout Prop


  //#region email Prop
  @prop()
  @maxLength({ value: 255 })
  // @required()
  @trim()
  //@email()
  email: string;
  //#endregion email Prop


  //#region secondaryEmail Prop
  @maxLength({ value: 255 })
  @trim()
  @email()
  secondaryEmail: string;
  //#endregion secondaryEmail Prop


  //#region mobileNumber Prop
  @prop()
  @maxLength({ value: 100 })
  //@numeric()
  mobileNumber: string;
  //#endregion mobileNumber Prop


  //#region streetAddress Prop
  @maxLength({ value: 1000 })
  @trim()
  streetAddress: string;
  //#endregion streetAddress Prop


  //#region streetAddress2 Prop
  @maxLength({ value: 1000 })
  @trim()
  streetAddress2: string;
  //#endregion streetAddress2 Prop


  //#region city Prop
  @maxLength({ value: 1000 })
  @trim()
  city: string;
  //#endregion city Prop


  //#region countryId Prop
  @prop()
  countryId: number;
  //#endregion countryId Prop


  //#region shoeSizeId Prop
  @prop()
  shoeSizeId: number;
  //#endregion shoeSizeId Prop

  
  //#region stateProvinceId Prop
  @prop()
  stateProvinceId: number;
  //#endregion stateProvinceId Prop


  //#region zipPostalCode Prop
  @prop()
  @maxLength({ value: 15 })
  @trim()
  //@alphaNumeric({ allowWhiteSpace: true })
  zipPostalCode: string;
  //#endregion zipPostalCode Prop


  //#region pOBox Prop
  @maxLength({ value: 100 })
  @trim()
  poBox: string;
  //#endregion pOBox Prop


  //#region nationality Prop
  @prop()
  nationality: number;
  //#endregion nationality Prop


  //#region familyMembers Prop
  @maxLength({ value: 1000 })
  @trim()
  familyMembers: string;
  //#endregion familyMembers Prop


  //#region hobbies Prop
  @maxLength({ value: 1000 })
  @trim()
  hobbies: string;
  //#endregion hobbies Prop


  //#region salesadvisorsID Prop
  @prop()
  salesadvisorsID: number;
  //#endregion salesadvisorsID Prop


  //#region introducesBy Prop
  @prop()
  introducesBy: number;
  //#endregion introducesBy Prop


  //#region dietaryInfo Prop
  @maxLength({ value: 400 })
  @trim()
  dietaryInfo: string;
  //#endregion dietaryInfo Prop


  //#region department Prop
  @prop()
  //@required()
  department: number;
  //#endregion department Prop


  //#region description Prop
  @prop()
  @maxLength({ value: 400 })
  @trim()
  description: string;
  //#endregion description Prop


  //#region webSite Prop
  @prop()
  @trim()
  webSite: string;
  //#endregion webSite Prop


  //#region industryType Prop
  @prop()
  @maxLength({ value: 400 })
  industryType: string;
  //#endregion industryType Prop


  //#region rating Prop
  @prop()
  rating: number;
  //#endregion rating Prop


  //#region gender Prop
  @maxLength({ value: 250 })
  gender: string;
  //#endregion gender Prop


  //#region vatNumber Prop
  @maxLength({ value: 250 })
  vatNumber: string;
  //#endregion vatNumber Prop


  //#region vatNumberStatus Prop
  @maxLength({ value: 250 })
  vatNumberStatus: string;
  //#endregion vatNumberStatus Prop


  //#region phoneNumber Prop
  @maxLength({ value: 100 })
  @prop()
  //@numeric()
  phoneNumber: string;
  //#endregion phoneNumber Prop


  //#region faxNumber Prop
  @maxLength({ value: 15 })
  @trim()
  faxNumber: string;
  //#endregion faxNumber Prop


  //#region countryName Prop
  @maxLength({ value: 250 })
  @trim()
  countryName: string;
  //#endregion countryName Prop


  //#region knownTo Prop
  @maxLength({ value: 1000 })
  knownTo: string;
  //#endregion knownTo Prop


  //#region jewelleryStockist Prop
  @prop()
  jewelleryStockist: boolean;
  //#endregion jewelleryStockist Prop


  //#region watchStockist Prop
  @prop()
  watchStockist: boolean;
  //#endregion watchStockist Prop


  //#region currentFabergeStockist Prop
  @prop()
  currentFabergeStockist: boolean;
  //#endregion currentFabergeStockist Prop


  //#region commu_FabergeMBRupdates Prop
  @prop()
  commu_FabergeMBRupdates: boolean;
  //#endregion commu_FabergeMBRupdates Prop


  //#region bankAccount Prop
  @maxLength({ value: 100 })
  bankAccount: string;
  //#endregion bankAccount Prop


  //#region financeContactName Prop
  @maxLength({ value: 100 })
  financeContactName: string;
  //#endregion financeContactName Prop


  //#region languagePreference Prop
  @prop()
  languagePreference: number;
  //#endregion languagePreference Prop


  //#region lastShippingOption Prop
  @prop()
  lastShippingOption: string;
  //#endregion lastShippingOption Prop


  //#region skypeName Prop
  @maxLength({ value: 250 })
  @trim()
  skypeName: string;
  //#endregion skypeName Prop


  //#region stateProvinceName Prop
  @maxLength({ value: 250 })
  @trim()
  //  @alpha({allowWhiteSpace:true})
  stateProvinceName: string;
  //#endregion stateProvinceName Prop


  //#region useRewardPointsDuringCheckout Prop
  @prop()
  useRewardPointsDuringCheckout: boolean;
  //#endregion useRewardPointsDuringCheckout Prop


  //#region companyRegisteredNumber Prop
  @maxLength({ value: 250 })
  companyRegisteredNumber: string;
  //#endregion companyRegisteredNumber Prop


  //#region financePhone Prop
  @maxLength({ value: 10 })
  financePhone: string;
  //#endregion financePhone Prop


  //#region impersonatedCustomerGuid Prop
  @maxLength({ value: 100 })
  impersonatedCustomerGuid: string;
  //#endregion impersonatedCustomerGuid Prop


  //#region impersonatedGemfieldsCustomerGuid Prop
  @maxLength({ value: 100 })
  impersonatedGemfieldsCustomerGuid: string;
  //#endregion impersonatedGemfieldsCustomerGuid Prop


  //#region jobDescription Prop
  @maxLength({ value: 500 })
  jobDescription: string;
  //#endregion jobDescription Prop


  //#region stateProvince Prop
  @maxLength({ value: 100 })
  stateProvince: string;
  //#endregion stateProvince Prop


  //#region workPhoneNumber Prop
  @maxLength({ value: 100 })
  @prop()
  //@numeric()
  workPhoneNumber: string;
  //#endregion workPhoneNumber Prop


  //#region accountActivationToken Prop
  @maxLength({ value: 100 })
  accountActivationToken: string;
  //#endregion accountActivationToken Prop


  //#region displayPrice Prop
  @prop()
  displayPrice: boolean;
  //#endregion displayPrice Prop


  //#region financeEmail Prop
  @maxLength({ value: 255 })
  //@email()
  financeEmail: string;
  //#endregion financeEmail Prop


  //#region financeFax Prop
  @maxLength({ value: 255 })
  financeFax: string;
  //#endregion financeFax Prop


  //#region musicPreference Prop
  @prop()
  musicPreference: number;
  //#endregion musicPreference Prop


  //#region passwordRecoveryToken Prop
  @maxLength({ value: 100 })
  passwordRecoveryToken: string;
  //#endregion passwordRecoveryToken Prop


  //#region advisorassigned Prop
  @maxLength({ value: 100 })
  advisorassigned: string;
  //#endregion advisorassigned Prop


  //#region contactMethod Prop
  @prop()
  contactMethod: number;
  //#endregion contactMethod Prop


  //#region country Prop
  @prop()
  country: number;
  //#endregion country Prop


  //#region discountLevel Prop
  @prop()
  discountLevel: number;
  //#endregion discountLevel Prop


  //#region financeMobile Prop
  @maxLength({ value: 100 })
  financeMobile: string;
  //#endregion financeMobile Prop


  //#region pOBShippingCountryId Prop
  @prop()
  pOBShippingCountryId: number;
  //#endregion pOBShippingCountryId Prop

  //#region lastLoginDate Prop
  @prop()
  lastLoginDate: Date;
  //#endregion lastLoginDate Prop

  //#region loginCount Prop
  @prop()
  loginCount: number;
  //#endregion loginCount Prop

  //#region deceased Prop
  @prop({ defaultValue: false })
  deceased: boolean;
  //#endregion deceased Prop

  @prop()
  deceasedDate: Date;

  @prop()
  userDeceasedDate: Date;

  @prop()
  badgeId: string;

  @prop()
  pressMediaEmailsType:string;

  @prop()
  @maxLength({ value: 1000 })
  knownAs:string;

 //#region homeStreetAddress Prop
 @maxLength({ value: 1000 })
 @trim()
 homeStreetAddress: string;
 //#endregion homeStreetAddress Prop


 //#region homeStreetAddress2 Prop
 @maxLength({ value: 1000 })
 @trim()
 homeStreetAddress2: string;
 //#endregion homeStreetAddress2 Prop


 //#region homeCity Prop
 @maxLength({ value: 1000 })
 @trim()
 homeCity: string;
 //#endrehomeCitygion homeCity Prop


 //#region homeCountryId Prop
 @prop()
 homeCountryId: number;
 //#endregion homeCountryId Prop


 //#region homeStateProvinceId Prop
 @prop()
 homeStateProvinceId: number;
 //#endregion homeStateProvinceId Prop

//#region stateProvinceName Prop
@maxLength({ value: 250 })
@trim()
//  @alpha({allowWhiteSpace:true})
homeStateProvinceName: string;
//#endregion stateProvinceName Prop

 //#region homeZipPostalCode Prop
 @prop()
 @maxLength({ value: 15 })
 @trim()
 //@alphaNumeric({ allowWhiteSpace: true })
 homeZipPostalCode: string;
 //#endregion homeZipPostalCode Prop

 //#region homePOBox Prop
 @maxLength({ value: 100 })
 @trim()
 homePOBox: string;
 //#endregion homePOBox Prop

 //#region expatriateEmployee Prop
 @prop({ defaultValue:0 })
 expatriateEmployee: number;
 //#endregion expatriateEmployee Prop

//#region socialSecurityNumber Prop
@maxLength({ value: 1000 })
@trim()
socialSecurityNumber: string;
//#endregion socialSecurityNumber Prop

//#region emergencyContactName Prop
@maxLength({ value: 1000 })
@trim()
emergencyContactName: string;
//#endregion emergencyContactName Prop

//#region emergencyContactNumber Prop
@maxLength({ value: 1000 })
@trim()
emergencyContactNumber: string;
//#endregion emergencyContactNumber Prop

//#region emergencyContactEmail Prop
@maxLength({ value: 255 })
@trim()
@email()
emergencyContactEmail: string;
//#endregion emergencyContactEmail Prop

//#region emergencyDetails Prop
@maxLength({ value: 1000 })
@trim()
emergencyDetails: string;
//#endregion emergencyDetails Prop

//#region medicalInformation Prop
@maxLength({ value: 1000 })
@trim()
medicalInformation: string;
//#endregion medicalInformation Prop

  //#region personalNumber Prop
  @prop()
  @maxLength({ value: 100 })
  personalNumber: string;
  //#endregion personalNumber Prop
  @prop()
  jobDescriptionUrl: string;

}
