import {vGemstoneStatuRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemstoneStatuRecord extends vGemstoneStatuRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}