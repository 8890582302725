import { Component, OnInit, OnDestroy, ComponentFactoryResolver, Input } from "@angular/core"
import { List } from "@rxweb/generics"
import { AbstractWebPicture } from '../domain/abstract-web-picture';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Picture} from '@app/models';
import { ModalView } from '../../../../domain/customize-design/modal';
import { WebPictureAddComponent } from '../add/web-picture-add.component';
import { WebPictureEditComponent } from '../edit/web-picture-edit.component';
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-form-validators';
import { DatePipe, PlatformLocation } from '@angular/common';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { HttpClient } from "@angular/common/http";
import * as fs from 'file-saver';
import { multilingual } from '@rxweb/localization';
import { ProductWebPicture } from "src/app/models/database-models/product-web-picture";
import { ProductWebPictureViewModel } from "src/app/view-model/product-web-picture-view-model";
import { DEFAULT_BLOB_IMAGE_URL, DEFAULT_THUMBNAIL_IMAGE_URL } from "src/app/domain/system-constant";
import { PictureViewModel } from "src/app/view-model/picture-view-model";
import { StatusEnum } from "src/app/enums/status.enum";
import { BlobUploadsViewStateService } from "src/app/temp-service/blob-uploads-view-state.service";

@multilingual("WebPictureListComponent")
@Component({
    selector: "app-web-picture-list",
    templateUrl: './web-picture-list.component.html',
    styleUrls: ['./web-picture-list.component.css']
})

export class WebPictureListComponent extends AbstractWebPicture implements OnInit, OnDestroy {
  pictures: List<Picture>;
  subscription: any;
  documentListGridColumns: any[];
  files: File[] = [];
  titleEdit: string;
  titleDelete: string;
  titleDownload: string;
  titleView: string;
  public dragIconId: number;
  public dropTileId: number;
  defaultThumbnailPath:string = "";
  titleMoveWidget : string;

  constructor(private http: HttpClient, private formBuilder: RxFormBuilder, private router: Router, private activatedRoute: ActivatedRoute, componentFactoryResolver: ComponentFactoryResolver, modelView: ModalView, datePipe: DatePipe, location : PlatformLocation, blobState: BlobUploadsViewStateService) {
    super(location,blobState)
    this.datePipe = datePipe;
    this.modalView = modelView;
    this.modalView.setResolver(componentFactoryResolver);
    this.activatedRoute.params.subscribe(t => {
      this.id = t['id'];
    })
    location.onPopState(() => this.hide());
  }
  ngOnInit(): void {
    this.titleEdit = getLocalizedValue("Title_Edit");
    this.titleDelete = getLocalizedValue("Title_Delete");
    this.titleView = getLocalizedValue("Title_View");
    this.titleDownload = getLocalizedValue("Title_Download");
    this.titleMoveWidget = getLocalizedValue("Title_MoveWidget");
    this.pictureWebFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<ProductWebPicture>;
    this.pictureWebUpdateFormGroup = this.formBuilder.formGroup(this.bindFormGroup()) as IFormGroup<ProductWebPicture>;
    this.defaultThumbnailPath = DEFAULT_THUMBNAIL_IMAGE_URL;
    this.bindGrid();
  }

  AddImages()
  {
    if(this.products.length < 16)
    {
      var model = new ProductWebPictureViewModel();
      model.pictureIndex =  Math.max(...this.products.map(o => o.pictureIndex)) + 1;
      model.pictureURL = DEFAULT_BLOB_IMAGE_URL;
      model.pictureType = 1;
      this.products.push(model);
      model.pictureType = 2;
      this.products.push(model);
      model.pictureType = 3;
      this.products.push(model);

      this.productsList.push({PictureIndex : Math.max(...this.productsList.map(o => o.PictureIndex)) + 1, EntityId :  this.id, EntityTypeId:  this.entityTypeId,DisplayOrder : this.productsList[this.productsList.length -1].DisplayOrder+ 1,Destination :  this.productsList[this.productsList.length -1].Destination+ 1,IsDrag : 0});

      if(this.productsList.length == 4)
      {
        this.hideButton = false;
      }
      setTimeout(() => {
        window.scrollTo(0,document.body.scrollHeight);
      }, 100);
    }
    else
    {
      this.hideButton = false;
    }
  }


  search(value) {
    this.documentListGrid.search = value.target.value;
  }



  ngOnDestroy(): void {


  }
  clickProduct(url:any)
  {
    if(url && !url.includes(DEFAULT_BLOB_IMAGE_URL))
      window.open(url, '_blank');
  }

  onSelect(event, ImageType : number, PictureIndex : number)
  {
    this.onFileChange(event.addedFiles,ImageType, PictureIndex,this.formBuilder)
  }

  onRemove(event)
  {

  }
  public onIconDropped(ev) {
    ev.drag.dropFinished();
  }
  public dragStartHandler(id: string): void {
    this.dragIconId = parseInt(id, 10);
    //console.log('docstartId', this.dragIconId);

  }
  public ghostCreateHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'hidden';
  }
  public dragEndHandler(dragRef: HTMLElement) {
    dragRef.style.visibility = 'visible';

  }

  public onEnterHandler(ev): void {
    this.dropTileId = parseInt(ev.owner.element.nativeElement.id, 10);
    // the event gets raised immediately, but we want to swap only when we drag over another icon
    if (this.dragIconId === this.dropTileId) {
      return;
    }
    const dragIndex = this.pictureListModel.findIndex(
      (iconObj) => iconObj.pictureID === this.dragIconId
    );
    const dropIndex = this.pictureListModel.findIndex(
      (iconObj) => iconObj.pictureID === this.dropTileId
    );
  }
  // onDownload(imageSrc) {
  //   const image = this.http.get(imageSrc).subscribe(data => {
  //     const imageBlog = data.blob().then(res => {
  //       const imageURL = URL.createObjectURL(res)

  //       const link = document.createElement('a')
  //       link.href = imageURL
  //       link.download = 'image'
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //     })
  //   })
  // }

  onDownload(imageLink: string) {
    fs.saveAs(imageLink, imageLink.split('/')[imageLink.split('/').length - 1]?.split('?')[0]);
  }
  get componentName(): string {
      return "WebPictureListComponent"
  }
  drop(event)
  {
    if(this.productsList[event.previousIndex].IsDrag == 1 && this.productsList[event.currentIndex].IsDrag == 1 && event.previousIndex != event.currentIndex)
    {

      this.pictureWebUpdateFormGroup.patchValue({
        pictureType : 0,
        pictureIndex : this.productsList[event.currentIndex].PictureIndex,
        destinationId : this.productsList[event.currentIndex].DisplayOrder,
        sourceId : this.productsList[event.previousIndex].DisplayOrder,
        displayOrder : this.productsList[event.previousIndex].DisplayOrder,
        isDrag: false,
        isDamagedPiece: false
      })

      //var formGroup = this.formBuilder.formGroup(this.pictureWebUpdateFormGroup) as IFormGroup<ProductWebPicture>;

      this.put({path: 'api/ProductWebPictures', body: this.pictureWebUpdateFormGroup.toFormData(), params: [0]}).subscribe(data=>{
        var existsAlert = getLocalizedValue("Data_Updated");
        if (existsAlert) {
          this.baseToastr.success(existsAlert);
        }
        this.bindGrid();
      })
    }
  }

  onImageError(event:any){
    event.target.src = this.defaultThumbnailPath;
  }
}
async function downloadImage(imageSrc) {
  const image = await fetch(imageSrc)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)



  const link = document.createElement('a')
  link.href = imageURL
  link.download = 'image file name here'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
