import { Component, OnInit } from '@angular/core';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {
  scrolToTop: string;
  constructor() { }
  currentYear: Date = new Date();
  yearText: any;
  ngOnInit() {
    this.yearText = this.currentYear.getFullYear();
    this.scrolToTop = getLocalizedValue("Title_ScrollToTop");
  }

}
