import {POSBrandBase,} from '@app/database-models'
//Generated Imports
export class POSBrand extends POSBrandBase 
{

    constructor() {
        super();
        this.isActive = false;
    }


//#region Generated Reference Properties

//#endregion Generated Reference Properties












}