<div *rxMultilingual="" [rxLocalisationInit]="componentName">
<div class="row wrapper white-bg page-heading py-2 align-items-center">
  <div class="col-md-8 my-1">
    <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/dashboard']">Home</a>
      </li>
      <li class="breadcrumb-item active" rxText="Label_System_Admin_t"></li>
    </ol>
  </div> 
  <div class="col-md-4 text-right">
    <app-system-time></app-system-time>
  </div>
</div>
<div class="wrapper wrapper-content flex-grow-1">
  <div class="ibox mb-0 d-flex flex-column">
    <div class="ibox-title bg-white">
      <h5 rxText="Label_System_t"></h5>

    </div>
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1">
      <div class="row ml-0">
        <ul class="menu-pills pl-0 d-flex flex-wrap mb-0 w-100">
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[globalSettings]">
            <a [routerLink]="['/all-settings/add']" 
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-global-settings icon-sm"></i>
              <p class="mb-0" rxText="Label_Global_Settings_t"></p>
            </a>
          </li>
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[messageTemplate]">
            <a [routerLink]="['/message-templates']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-message-templates icon-sm"></i>
              <p class="mb-0" rxText="Label_Message_Templates_t"></p>
            </a>
          </li>
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[localizationContent]"> 
            <a [routerLink]="['/language-content-keys']" 
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-location icon-sm"></i>
              <p class="mb-0" rxText="Label_Localisation_t"></p>
            </a>
          </li>
 
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[fabergeLocalization]">
            <a [routerLink]="['/faberge-localization']" 
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-location icon-sm"></i>
              <p class="mb-0" rxText="Label_Faberge_Localisation_t"></p>
            </a>
          </li>
 
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[emailAccounts]">
            <a [routerLink]="['/email-account']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-email-account icon-xsm"></i>
              <p class="mb-0" rxText="Label_Email_Accounts_t"></p>
            </a>
          </li>
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[blacklist]">
            <a [routerLink]="['/blacklist']" [queryParams]="{ activeTab: 'address' }"
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-blacklist icon-xsm"></i>
              <p class="mb-0" rxText="Label_Blacklist_t"></p> 
            </a>
          </li>
          <!-- <li>
            <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-attributes icon-xsm"></i>
              <p class="mb-0">Attributes</p>
            </a>
          </li> -->

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[measureDimensions]">
            <a [routerLink]="['/measure-dimensions']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-measures icon-xsm"></i>
              <p class="mb-0" rxText="Label_Measure_Dimensions_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[measureWeights]">
            <a [routerLink]="['/measure-weights']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-measures icon-xsm"></i>
              <p class="mb-0" rxText="Label_Measure_Weights_t"></p>
            </a>
          </li>
          <!-- <li>
            <a href="" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-news-setting icon-xsm"></i>
              <p class="mb-0">News Settings</p>
            </a>
          </li> -->

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[allSettings]">
            <a [routerLink]="['/settings']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-all-settings icon-xsm"></i>
              <p class="mb-0" rxText="Label_All_Settings_t"></p>
            </a>
          </li>
          <!-- <li>
            <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-sms-provider icon-xsm"></i>
              <p class="mb-0">SMS Providers</p>
            </a>
          </li> -->
          <!-- <li>
            <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-third-party-integration icon-sm"></i>
              <p class="mb-0">Third Party Integration</p>
            </a> 
          </li> --> 

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[log]">
            <a [routerLink]="['/application-exception-logs']"
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-log icon-xsm"></i>
              <p class="mb-0" rxText="Label_Log_t"></p>
            </a>
          </li>
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[systemInfo]">
            <a [routerLink]="['/system-information']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-maintenance icon-sm"></i>
              <p class="mb-0" rxText="Label_System_Information_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[userLockout]">
            <a [routerLink]="['/user-lockout']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-user-role"></i>
              <p class="mb-0" rxText="Label_Users_Lockout_t"></p> 
            </a>
          </li>



          <li [rxLocalisationInit]="componentName" *rxAuthorize="[messageQueue]">
            <a [routerLink]="['/queued-emails']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-product-boxes"></i>
              <p class="mb-0" rxText="Label_Message_Queue_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[helpSupport]">
            <a [routerLink]="['/help-supports']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-help-support icon-sm"></i>
              <p class="mb-0" rxText="Label_Help_Support_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[departments]">
            <a [routerLink]="['/departments']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-department icon-lg"></i>
              <p class="mb-0" rxText="Label_Department_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[holidayProcess]">
            <a [routerLink]="['/customer-holidays']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-user-role"></i>
              <p class="mb-0" rxText="Label_Holiday_Process_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[leaveTypes]">
            <a [routerLink]="['/leave-types']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-leave-type"></i>
              <p class="mb-0" rxText="Label_Leave_Type_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[bankHolidays]">
            <a [routerLink]="['/bank-holidays']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-bank"></i>
              <p class="mb-0" rxText="Label_Bank_Holiday_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[userRole]"> 
            <a [routerLink]="['/roles']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-user-role"></i>
              <p class="mb-0" rxText="Label_User_Roles_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[formsDocument]">
            <a [routerLink]="['/forms-document']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-user-role"></i>
              <p class="mb-0" rxText="Label_Forms_Document_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[systemHelp]">
            <a [routerLink]="['/system-help']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-user-role"></i>
              <p class="mb-0" rxText="Label_System_Help_t"></p>  
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[fileExtentions]">
            <a [routerLink]="['/file-extentions']"
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-page-level icon-xsm"></i>
              <p class="mb-0" rxText="Label_File_Extentions_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[applicationObject]">
            <a [routerLink]="['/application-object']"
              class="d-flex flex-column text-center justify-content-center">
              <i class="fr-page-level icon-xsm"></i>
              <p class="mb-0" rxText="Label_Application_Object_t"></p>
            </a>
          </li>
          
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[powerBIReportList]">
            <a [routerLink]="['/power-bi-reports']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-bom icon-sm"></i>
              <p class="mb-0" rxText="Label_Power_BI_Report_t"></p>
            </a>
          </li>

          <li [rxLocalisationInit]="componentName" *rxAuthorize="[documentTypes]">
            <a [routerLink]="['/document-types']" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-bom icon-sm"></i>
              <p class="mb-0" rxText="Label_Document_Type_t"></p>
            </a>
          </li>
          <!-- <li [rxLocalisationInit]="componentName" *rxAuthorize="[desktopAsset]">
            <a [routerLink]="['/desktop-assets']" class="d-flex flex-column text-center justify-content-center">
              <i class="fa fa-desktop icon-sm"></i>
              <p class="mb-0" rxText="Label_DesktopAssets_t"></p>
            </a>
          </li>
          <li [rxLocalisationInit]="componentName" *rxAuthorize="[mobileAsset]">
            <a [routerLink]="['/mobile-assets']" class="d-flex flex-column text-center justify-content-center">
              <i class="fa fa-mobile icon-sm"></i>
              <p class="mb-0" rxText="Label_MobileAssets_t"></p>
            </a>
          </li> -->

          <!-- <li>
            <a [routerLink]="['/menus']" class="d-flex flex-column text-center justify-content-center">
              <i class="fa fa-th-large"></i>
              <p class="mb-0">Menu Layout</p>
            </a>
          </li> -->
          <!-- <li>
            <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-currencies icon-sm"></i>
              <p class="mb-0">Warnings</p>
            </a>
          </li> -->
          <!--
          <li>
            <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-information icon-xsm"></i>
              <p class="mb-0">Systen Information</p>
            </a>
          </li> -->
          <!-- <li>
            <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
              <i class="fr-home icon-xsm"></i>
              <p class="mb-0">Home Layout</p>
            </a>
          </li> -->
        </ul>
      </div>
      <div class="row">
        <div class="col-xl-6" [rxLocalisationInit]="componentName" *rxAuthorize="[moduleLevel]">
          <div class="ibox mb-0 d-flex flex-column border-0 shadow-none">
            <div class="ibox-title bg-white px-0">
              <h5 rxText="Label_Access_Control_List_t"></h5>

            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 px-0">
              <div class="row ml-0">
                <ul class="menu-pills menu-pills-medium pl-0 d-flex flex-wrap mb-0 w-100">
                  <!-- <li>
                    <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-top-menu icon-xsm"></i>
                      <p class="mb-0">Top Menu Level</p>
                    </a>
                  </li> -->
                  <!-- <li>
                    <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-menu icon-xsm"></i>
                      <p class="mb-0">Menu Level</p>
                    </a>
                  </li>
                  <li>
                    <a href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-page-level icon-xsm"></i>
                      <p class="mb-0">Page Level</p>
                    </a>
                  </li>-->
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[rolePermission]">

                    <a [routerLink]="['/role-permissions']" onclick="return false;"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-page-level icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Roles_Permissions_t"></p>
                    </a>


                  </li>
                  <!-- <li>
                    <a href="javscript:void(0)" onclick="return false;"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-hashtag icon-xsm"></i>
                      <p class="mb-0">Hashtag Level</p>
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6" [rxLocalisationInit]="componentName" *rxAuthorize="[creditCard,paymentMethods,discounts]">
          <div class="ibox mb-0 d-flex flex-column border-0 shadow-none">
            <div class="ibox-title bg-white px-0">
              <h5 rxText="Label_Payment_t"></h5>

            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 px-0">
              <div class="row ml-0">
                <ul class="menu-pills menu-pills-medium pl-0 d-flex flex-wrap mb-0 w-100">
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[creditCard]">
                    <a [routerLink]="['/credit-card-types']"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-credit-card"></i>
                      <p class="mb-0" rxText="Label_Credit_Cards_t"></p>
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[paymentMethods]">
                    <a [routerLink]="['/payment-methods']"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-payment-method icon-sm"></i>
                      <p class="mb-0" rxText="Label_Payment_Methods_t"></p>
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[discounts]"> 
                    <a [routerLink]="['/discounts']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-discount icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Discounts_t"></p> 
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6" [rxLocalisationInit]="componentName" *rxAuthorize="[taxProviders,taxClasses]">
          <div class="ibox mb-0 d-flex flex-column border-0 shadow-none">
            <div class="ibox-title bg-white px-0">
              <h5 rxText="Label_Tax_t"></h5>

            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 px-0">
              <div class="row ml-0">
                <ul class="menu-pills menu-pills-medium pl-0 d-flex flex-wrap mb-0 w-100">
                  <!-- <li>
                    <a  href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-tax-settings icon-xsm"></i>
                      <p class="mb-0">Tax Settings</p>
                    </a>
                  </li> -->
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[taxProviders]">
                    <a [routerLink]="['/tax-provider/6']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-tax-provider icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Tax_Providers_t"></p> 
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[taxClasses]">
                    <a [routerLink]="['/tax-category']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-tax-classes icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Tax_Classes_t"></p>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6" [rxLocalisationInit]="componentName" *rxAuthorize="[shippingMethods]">
          <div class="ibox mb-0 d-flex flex-column border-0 shadow-none">
            <div class="ibox-title bg-white px-0">
              <h5 rxText="Label_Shipping_t"></h5>

            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 px-0">
              <div class="row ml-0">
                <ul class="menu-pills menu-pills-medium pl-0 d-flex flex-wrap mb-0 w-100">
                  <!-- <li>
                    <a href="javscript:void(0)" onclick="return false;" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-shipping-setting icon-xsm"></i>
                      <p class="mb-0">Shipping Settings</p>
                    </a>
                  </li> -->
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[shippingMethods]">
                    <a [routerLink]="['/shipping-method']"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-shipping-method icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Shipping_Methods_t"></p>
                    </a>
                  </li>
                  <!-- <li>
                    <a href="javscript:void(0)" onclick="return false;"  class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-shipping-rate-computation icon-xsm"></i>
                      <p class="mb-0">
                        Shipping Rate Computation
                      </p>
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <div class="row">
        <div class="col-xl-6" [rxLocalisationInit]="componentName" *rxAuthorize="[stateProvinces,languages,regions,countries]">
          <div class="ibox mb-0 d-flex flex-column border-0 shadow-none">
            <div class="ibox-title bg-white px-0">
              <h5 rxText="Label_Location_t"></h5>

            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 px-0">
              <div class="row ml-0">
                <ul class="menu-pills menu-pills-medium pl-0 d-flex flex-wrap mb-0 w-100">
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[regions]">
                    <a [routerLink]="['/regions']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fa fa-map icon-sm"></i>
                      <p class="mb-0" rxText="Label_Regions_t"></p>
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[countries]"> 
                    <a [routerLink]="['/countries']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-countries icon-sm"></i>
                      <p class="mb-0" rxText="Label_Countries_t"></p>
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[stateProvinces]">
                    <a [routerLink]="['/state-provinces']"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-states-provinces icon-xsm"></i>
                      <p class="mb-0" rxText="Label_States/Provinces_t"></p>
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[languages]">
                    <a [routerLink]="['/languages']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-languages icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Languages_t"></p>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6" [rxLocalisationInit]="componentName" *rxAuthorize="[activityLogs,activityTypes]">
          <div class="ibox mb-0 d-flex flex-column border-0 shadow-none">
            <div class="ibox-title bg-white px-0">
              <h5 rxText="Label_Activity_Log_t"></h5>

            </div>
            <div class="ibox-content bg-white d-flex flex-column flex-grow-1 px-0">
              <div class="row ml-0">
                <ul class="menu-pills menu-pills-medium pl-0 d-flex flex-wrap mb-0 w-100">
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[activityTypes]">
                    <a [routerLink]="['/activity-log-types']"
                      class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-activity-type icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Activity_Types_t"></p>
                    </a>
                  </li>
                  <li [rxLocalisationInit]="componentName" *rxAuthorize="[activityLogs]">
                    <a [routerLink]="['/activity-logs']" class="d-flex flex-column text-center justify-content-center">
                      <i class="fr-menu-activity icon-xsm"></i>
                      <p class="mb-0" rxText="Label_Activity_Log_t"></p>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
