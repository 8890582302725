import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {
currentYear:number;
currentApplicationVersion = environment.appVersion;
buildDate = environment.buildDate;
  constructor() { }

  ngOnInit() {
    let currentDate= new Date();
    this.currentYear=currentDate.getFullYear();
    
  }

}
