import { prop,propObject,propArray,required,maxLength,range ,notEmpty } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class DualCategoryProductVariantMappingBase {

//#region dualCategoryProductVariantMappingId Prop
        @prop()
        dualCategoryProductVariantMappingId : number;
//#endregion dualCategoryProductVariantMappingId Prop


//#region dualcategoryId Prop
      
    dualcategoryId : number;
//#endregion dualcategoryId Prop

//#region productVariantId Prop
      
    productVariantId : number;
//#endregion productVariantId Prop

//#region isFeaturedProduct Prop
      
    isFeaturedProduct : boolean;
//#endregion isFeaturedProduct Prop

//#region displayOrder Prop
      
    displayOrder : boolean;
//#endregion displayOrder Prop


}