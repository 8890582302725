import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vTaxProviderBase {

//#region taxProviderId Prop
        @gridColumn({visible: false, columnIndex:0, allowSorting: true, headerKey: 'TaxProviderListComponent_TaxProviderId_gh', keyColumn: true})
        taxProviderId : number;
//#endregion taxProviderId Prop


//#region taxProviderName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'TaxProviderListComponent_TaxProviderName_gh', keyColumn: false})
        taxProviderName : string;
//#endregion taxProviderName Prop


//#region displayOrder Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" }, class: ["text-left"],visible: true, columnIndex:2, allowSorting: true, headerKey: 'TaxProviderListComponent_DisplayOrder_gh', keyColumn: false})
        displayOrder : number;
//#endregion displayOrder Prop


//#region description Prop
        @gridColumn({visible: false, columnIndex:3, allowSorting: true, headerKey: 'TaxProviderListComponent_Description_gh', keyColumn: false})
        description : string;
//#endregion description Prop


//#region className Prop
        @gridColumn({visible: false, columnIndex:4, allowSorting: true, headerKey: 'TaxProviderListComponent_ClassName_gh', keyColumn: false})
        className : string;
//#endregion className Prop


//#region configureTemplatePath Prop
        @gridColumn({visible: false, columnIndex:5, allowSorting: true, headerKey: 'TaxProviderListComponent_ConfigureTemplatePath_gh', keyColumn: false})
        configureTemplatePath : string;
//#endregion configureTemplatePath Prop

}