import {
    AbstractRequestFilter,
    HttpResponse,
    ResponseFilter,
    XhrContext
  } from "@rxweb/http";
import { BrowserStorage } from '../services/browser-storage';

  
  export class QueryParamsFilter extends AbstractRequestFilter implements ResponseFilter {
    languageId:number;
    constructor(private browserStorage:BrowserStorage){
    super();
   // this.languageId=this.browserStorage.local.get('language')==undefined?7:Number(this.browserStorage.local.get('language'));
   
    }
    
    onRequest = (context: XhrContext) => {
    //  console.log(this.browserStorage.local)
      context.request["_fullPath"] = undefined;
      context.request.queryParams["v"] = Math.random();
     // context.request.queryParams["languageId"] = languageData;
      this.onRequestExecuting(context);
    };
    onResponse = (response: HttpResponse) => {
      return response;
    };
  }