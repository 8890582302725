import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class ProductLocalizedBase {

//#region productLocalizedId Prop
        @prop()
        productLocalizedId : number;
//#endregion productLocalizedId Prop


//#region productId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        productId : number;
//#endregion productId Prop


//#region languageId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        languageId : number;
//#endregion languageId Prop


//#region name Prop
        @required()
        @maxLength({value:400})
        name : string;
//#endregion name Prop


//#region shortDescription Prop
        @required()
        shortDescription : string;
//#endregion shortDescription Prop


//#region fullDescription Prop
        @required()
        fullDescription : string;
//#endregion fullDescription Prop


//#region metaKeywords Prop
        @required()
        @maxLength({value:400})
        metaKeywords : string;
//#endregion metaKeywords Prop


//#region metaDescription Prop
        @required()
        @maxLength({value:4000})
        metaDescription : string;
//#endregion metaDescription Prop


//#region metaTitle Prop
        @required()
        @maxLength({value:400})
        metaTitle : string;
//#endregion metaTitle Prop


//#region sEName Prop
        @required()
        @maxLength({value:100})
        sEName : string;
//#endregion sEName Prop





}