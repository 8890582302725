import { gridColumn } from '@rxweb/grid';
import { prop } from '@rxweb/reactive-form-validators';

export class LocalizationContentViewModel{
    
//#region dutyStatusId Prop
@gridColumn({isAscending:true,style: { width: "15%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'FabergeLocalizationListComponent_Name_gh', keyColumn: true})
localizationKey : string;
//#endregion dutyStatusId Prop


//#region dutyStatusName Prop
@gridColumn({style: { width: "65%", "text-align": "initial","word-break":"break-all" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'FabergeLocalizationListComponent_Value_gh', keyColumn: false})
localizationValue : string;
//#endregion dutyStatusName Prop
@prop()
languageId:number;

@prop()
oldKey:string;

@gridColumn({
    visible:false,
    name:"editAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'edit' }, columnIndex: 4, headerTitle: "Edit"
})
editAction:any;

@gridColumn({
    visible:false,
    name:"deleteAction",
    allowSorting: false,
    style: { width: "1%" },
    configuredTemplate: { templateName: 'delete' }, columnIndex: 5, headerTitle: "Delete"
})
deleteAction:any;

}