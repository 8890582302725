import { prop,propObject,propArray,required,maxLength,range ,notEmpty,alpha,numeric,trim } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class TaxCategoryBase {

//#region taxCategoryId Prop
        @prop()
        taxCategoryId : number;
//#endregion taxCategoryId Prop


//#region name Prop
        @required()
        @maxLength({value:100})
        @notEmpty()
        @trim()
        //@alpha({allowWhiteSpace:true})
        name : string;
//#endregion name Prop


//#region displayOrder Prop
        @prop()
        @numeric()
        @notEmpty()
        @range({minimumNumber:1,maximumNumber:2147483647})
        displayOrder : number;
//#endregion displayOrder Prop


//#region statusId Prop
        @range({minimumNumber:1,maximumNumber:2147483647})
        @required()
        statusId : number;
//#endregion statusId Prop



}