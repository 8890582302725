import { TemplateConfig } from '@rxweb/grid';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';

export const EVENTEXPAND_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [{
      a: {
        isBind: (x) => {
          if (x.companyDetails.length>1 || x.directorshipDetails.length>1  ||x.knownToDetail.length>1) {
            return true;
          }
          else {
            return false;

          }
        },
        event: {
          click: "onRowExpand"
        },
        childrens: [{
          i: {
            class: "fa fa-plus fa-4x font-md text-default".split(" "),
            attributes: { "title": getLocalizedValue("Title_EventExpand") }

          }
        }],
        class: ["mr-2"]
      }
    }]
  }
}
