import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vGemFieldBookingSortingLookUpBase {

//#region gemFieldBookingSortingId Prop
        @gridColumn({visible: true, columnIndex:0, allowSorting: true, headerKey: 'gemFieldBookingSortingId', keyColumn: true})
        gemFieldBookingSortingId : number;
//#endregion gemFieldBookingSortingId Prop


//#region gemFieldBookingSortingName Prop
        @gridColumn({visible: true, columnIndex:1, allowSorting: true, headerKey: 'gemFieldBookingSortingName', keyColumn: false})
        gemFieldBookingSortingName : string;
//#endregion gemFieldBookingSortingName Prop

}