import { prop, required, minNumber } from '@rxweb/reactive-form-validators';

export class RolePermissionSearchModel{
    @prop()
    @required()
    @minNumber({value:1,messageKey:'department',message:'Please select a Role'})
    roleId:number;
    @prop()
    // @required()
    // @minNumber({value:1,messageKey:'userId',message:'Please select a User'})
    userId:number;
}