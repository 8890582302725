import { TaxRateBase, CountryBase, TaxCategoryBase, } from '@app/database-models'
//Generated Imports
export class TaxRate extends TaxRateBase {

    constructor() {
        super();
        this.countryId = 0;
    }


    //#region Generated Reference Properties
    //#region country Prop
    country: CountryBase;
    //#endregion country Prop
    //#region taxCategory Prop
    taxCategory: TaxCategoryBase;
    //#endregion taxCategory Prop

    //#endregion Generated Reference Properties












}
