import { TemplateConfig } from '@rxweb/grid';

export const INTRODUCED_LINK_GRID_TEMPLATE: TemplateConfig = {
  div: {
    childrens: [
      {
        a: {
          event: {
            click: "onIntroducedLink"
          },
          childrens: [{
            text: {
              text: function (e) { return this[e.name] }
            }
          }]
        }
      }
    ]
  },
};
