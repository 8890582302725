import {vPieceOwnerRecordBase,} from '@app/database-models'
//Generated Imports
export class vPieceOwnerRecord extends vPieceOwnerRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}