import { RxHttp, http } from "@rxweb/http";
import { IFormGroup } from '@rxweb/reactive-form-validators';
import { Address, vAdminUserLookUp, vStateProvinceLookUp, vCountryLookUp } from '@app/models';
import { BaseToastr } from 'src/app/domain/customize-design/toastr';
import { BaseDialog } from 'src/app/domain/customize-design/dialog';
import { ModalView } from 'src/app/domain/customize-design/modal';
import { BedRequestComponent } from 'src/app/components/shared/global/bed-request/bed-request.component';
import { CoreComponent, } from '@rxweb/angular-router';
import { Input, Directive } from '@angular/core';
import { AddressAddComponent } from '../add/address-add.component';
import { List } from '@rxweb/generics';
import { getLocalizedValue } from 'src/app/domain/common-logic/common-logic';
import { Validators } from "@angular/forms";
@Directive()
@http({

  path: "api/Address"
})

export class AbstractAddress extends CoreComponent {

  @Input() userId: number;
  addressFormGroup: IFormGroup<Address>;
  stateProvinceLookUp: vStateProvinceLookUp[];
  showComponent: boolean = false;
  spin: boolean = false;
  countryId: number;
  toastr: BaseToastr;
  dialog: BaseDialog;
  modalView: ModalView;
  showSelectOption: boolean = false;
  userOrSupplier: string;
  isDDStateChange = true;
  isshowStateTextbox:boolean=true;
  provinceShow:number;
  stateProvinceError:boolean;
  formSubmit:boolean=false;
  emailError:boolean=false;
  phoneError:boolean=false;
  country:number;
  titleClose: string;
  addressLookup: any = {
    stateProvinceLookup: vStateProvinceLookUp,
    countryLookup: vCountryLookUp,
    address: Address
  };
  //address: Address;
  //addressId:number;

  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
    this.titleClose = getLocalizedValue("Title_Close");
  }

  selectState(stateId: number = 0) {
    // if(this.country!=stateId)
    // {
      this.addressFormGroup.patchValue({
        stateProvinceName:null
      })
   //}
    this.stateProvinceError=false;
    this.isDDStateChange = false;
    setTimeout(function () {
      this.isDDStateChange = true;
    }.bind(this), 4);
    this.stateProvinceLookUp = this.addressLookup.vStateProvinceLookUp;
    this.stateProvinceLookUp = this.stateProvinceLookUp.filter(x => x.countryId == this.addressFormGroup.value.countryID);
    let usa = this.addressLookup.vCountryLookUp.find(x => x.countryName.toLowerCase().trim() == "usa");
    let canada = this.addressLookup.vCountryLookUp.find(x => x.countryName.toLowerCase().trim() == "canada")
    if(this.addressFormGroup.value.countryID==usa.countryId || this.addressFormGroup.value.countryID==canada.countryId){
      this.addressFormGroup.get('stateProvinceID').setValidators([Validators.required])
    }else{
      
      this.addressFormGroup.get('stateProvinceID').clearValidators();
    }
    this.addressFormGroup.patchValue({ stateProvinceID: stateId })
    this.isshowStateTextbox = this.stateProvinceLookUp.length>0 ?false:true;
  }
  
  hideMsg(event:any){
    if(this.addressFormGroup.submitted){
        if(this.isshowStateTextbox==true){
            if(this.addressFormGroup.value.stateProvinceName.trim()!="" && this.addressFormGroup.value.stateProvinceName!=null){
                this.stateProvinceError=false;
            }
            else{
                this.stateProvinceError=true;
            }
        }
        else{
            if(this.addressFormGroup.value.stateProvinceID>0 && this.addressFormGroup.value.stateProvinceID.toString()!="" && this.addressFormGroup.value.stateProvinceID!=null){
                this.stateProvinceError=false;
            }
            else{
                this.stateProvinceError=true;
            }
        }
    }
    
}
  badRequest = (data) => {
    this.spin = false;
    this.modalView.show(BedRequestComponent, { validationMessages: data }).then(t => {
    })
  }
}
