<div *ngIf="showComponent">
    <div class="row wrapper white-bg page-heading py-2 align-items-center"  *rxMultilingual="" [rxLocalisationInit]="componentName">
        <div class="col-md-8 my-1">
          <h2 class="m-0 page-title mb-2" rxText="Label_System_Admin_t"></h2>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/system']" rxText="Label_System_Admin_t"></a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/file-extentions']" rxText="Label_File_Extentions_t"></a>
            </li>
            <li class="breadcrumb-item active" rxText="FileExtentionEditComponent_Title_t"></li>
          </ol>
        </div>
        <div class="col-md-4 text-right">
          <app-system-time></app-system-time>
        </div>
      </div>

      <div class="wrapper wrapper-content flex-grow-1" [rxLocalisationInit]="componentName">

        <div class="ibox mb-0 d-flex flex-column">
          <div class="ibox-title bg-white pr-3">
            <h5><label rxText="FileExtentionEditComponent_Title_t"></label>
            </h5>
            <a (click)="showSystemHelp()" class="float-right" [title]="titleHelp" *ngIf="showSystemHelpIcon">
              <i class="fa fa-question-circle fa-2x"></i>
            </a>
          </div>
          <div class="ibox-content bg-white d-flex flex-column flex-grow-1" [formGroup]="fileExtentionFormGroup"  [rxSpinner]="spin">
            <div class="row add-countries-form" (keyup.enter)="editFileExtention(false)">
              <div class="col-md-4 pl-xl-5">
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText="FileExtentionEditComponent_ID_t"></label>
                  <div class="col-md-8">
                    <label type="text" 
                    class="form-control disableLabel-withoutheight mb-0">{{(fileExtentionFormGroup.value.fileExtentionId
                    === null || fileExtentionFormGroup.value.fileExtentionId === undefined ) ? '' :
                    fileExtentionFormGroup.value.fileExtentionId}}</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pr-xl-5">
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label"  rxText = "FileExtentionAddComponent_Name_t"></label>
                  <div class="col-md-8">
                    <input type="text" formControlName="fileExtentionName" class="form-control "  rxPlaceholder="FileExtentionAddComponent_Name_p" rxFocus/>
                    <small class="form-text text-danger" [class.d-block]="fileExtentionFormGroup.controls.fileExtentionName.errors">{{fileExtentionFormGroup.controls.fileExtentionName.errorMessage}}<br /></small>
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-4">
                <div class="form-group row">
                  <label class="col-md-4 col-lg-3 col-xl-4 col-form-label" rxText="FileExtentionAddComponent_Type_t"></label>
                  <div class="col-md-8" [rxSelectExtended]="_this">
                    <rx-select [(source)]="fileExtentionType" #rxSelect mainClass="form-control"
                      formControlName="fileType" [keyValueProps]="['value','id']" [selectPlaceholder]="'Select File Type'">
                    </rx-select>
                    <small class="form-text text-danger" [class.d-block]="fileExtentionFormGroup.controls.fileType.errors">{{fileExtentionFormGroup.controls.fileType.errorMessage}}<br /></small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-auto">
              <div class="hr-line-dashed border-top w-100"></div>
              <div class="col text-right">
                <button (click)="editFileExtention(false)" class="btn btn-success mr-1" rxText="Btn_Save_t"></button>
                <button (click)="editFileExtention(true)" class="btn btn-primary mr-1" rxText="Btn_Save_Continue_Edit_t"></button>
                <ng-container *ngIf="fileExtentionDelete" [rxLocalisationInit]="componentName">
                  <button (click)="deleteFileExtention()" class="btn btn-danger" rxText="Btn_Delete_t"></button>
                </ng-container>

              </div>
            </div>
          </div>
        </div>

      </div>









</div>
