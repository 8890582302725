import { PowerBIReportUserRole } from 'src/app/models/extended-models/power-bi-report-user-role';
import { AbstractPowerBIReport } from '../domain/abstract-power-bi-report';
import { PowerBIReport } from 'src/app/models/extended-models/power-bi-report';
import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from 'rxjs';
import { RxFormBuilder, IFormGroup, RxFormGroup, RxFormArray, IAbstractControl } from '@rxweb/reactive-form-validators';
import { HelpSupportUserRole, vRoleLookUp, HelpSupport } from '@app/models';
import { USER_LOOKUPS } from '../../../../const/uris/user-lookups.uri';
import { StatusEnum } from '../../../../enums/status.enum';
import { FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { List } from '@rxweb/generics';
import { ModalView } from '../../../../domain/customize-design/modal';
import * as CryptoJS from 'crypto-js';
import { CustomerRoleTypeEnum } from "src/app/enums/customer-role-type.enum";
import { multilingual } from '@rxweb/localization';

@multilingual("PowerBIReportUserRoleComponent")
@Component({
  selector: 'app-power-bi-report-user-role',
  templateUrl: './power-bi-report-user-role.component.html',
  styleUrls: ['./power-bi-report-user-role.component.css']
})
export class PowerBIReportUserRoleComponent extends AbstractPowerBIReport implements OnInit, OnDestroy  {

  subscription: any;
  powerBIReportId: number;
  powerBIReportUserRole: List<PowerBIReportUserRole>;
  isEdit: boolean = false;
  localizedMessages: any;
  decryptstring: any;
  Jsonstring: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: RxFormBuilder, modelView: ModalView) {
      super();
      this.modalView = modelView;
      this.activatedRoute.params.subscribe(t => {
          this.powerBIReportId = t['id'];
      })

  }

  get powerBIReportUserRoles(): FormArray {
    let userRoleArray = this.powerBiReportFormGroup.controls["powerBIReportUserRoles"] as IAbstractControl;
    let data = <FormArray>userRoleArray["controls"]
    return data;
}

  ngOnInit(): void {
    this.spin = true;
    this.lookup([{ params: [this.powerBIReportId], propName: "powerBIReport" }, 
                 { propName: "roleLookUp", path: USER_LOOKUPS.roleLookUp }]).subscribe((lookup: any) => {
      this.powerBIReportLookups = lookup;
        let tempAdminRolesArray: Array<vRoleLookUp> = new Array<vRoleLookUp>();
        this.powerBIReportLookups.roleLookUp.forEach(element => {
            if (element.roleType == CustomerRoleTypeEnum.Admin) {
                tempAdminRolesArray.push(element);
            }
        });
        console.log(this.powerBIReportLookups);
        this.powerBIReportLookups.roleLookUp = tempAdminRolesArray;
        this.powerBIReportLookups.powerBIReport = lookup.powerBIReport.result[0];
        if (lookup.powerBIReport.powerBIReportUserRoles.length > 0) {
            this.powerBIReportUserRole = new List<PowerBIReportUserRole>(lookup.powerBIReport.powerBIReportUserRoles);
        }
        this.powerBIReportLookups.powerBIReport.powerBIReportUserRoles = new Array<PowerBIReportUserRole>();
        this.powerBIReportLookups.powerBIReport.powerBIReportUserRoles = this.bindFormGroup();
        this.powerBiReportFormGroup = this.formBuilder.formGroup(PowerBIReport, this.powerBIReportLookups.powerBIReport) as IFormGroup<PowerBIReport>;
        this.spin = false;
        this.showComponent = true;
    })
    this.decryptstring = localStorage.getItem('AllLocalizedMessages');
    this.Jsonstring = CryptoJS.AES.decrypt(decodeURIComponent(this.decryptstring), 'secret key 123');
    this.localizedMessages = JSON.parse(this.Jsonstring.toString(CryptoJS.enc.Utf8));
  }

  bindFormGroup(): PowerBIReportUserRole[] {
    let roleLookUps = this.powerBIReportLookups.roleLookUp as Array<vRoleLookUp>;
    let powerBIReportUserRoleArray = Array<PowerBIReportUserRole>();
    roleLookUps.forEach((roleLookUp: vRoleLookUp) => {
        let powerBIReportUserRole = new PowerBIReportUserRole();
        if (this.powerBIReportUserRole !== undefined && this.powerBIReportUserRole !== null) {
            if (this.powerBIReportUserRole.where(x => x.userRoleId == roleLookUp.roleId).count() > 0) {
                let helpSupportUserRoleEdit = this.powerBIReportUserRole.firstOrDefault(x => x.userRoleId == roleLookUp.roleId);
                if (helpSupportUserRoleEdit) {
                  powerBIReportUserRole.isActive = helpSupportUserRoleEdit["isActive"]
                  powerBIReportUserRole.powerBIReportUserRoleId = helpSupportUserRoleEdit["powerBIReportUserRoleId"];
                }
            }
            else {
              powerBIReportUserRole.isActive = false;
              powerBIReportUserRole.powerBIReportUserRoleId = 0;
            }
        } else {
          powerBIReportUserRole.isActive = false;
          powerBIReportUserRole.powerBIReportUserRoleId = 0;
        }
        powerBIReportUserRole.userRoleId = roleLookUp.roleId;
        powerBIReportUserRole.stausId = StatusEnum.Active;
        powerBIReportUserRole.powerBIReportId = this.powerBIReportId;
        powerBIReportUserRoleArray.push(powerBIReportUserRole)
    })
    return powerBIReportUserRoleArray;
}

  getRoleName(roleId: number): string {
    let roleName = new List<vRoleLookUp>(this.powerBIReportLookups.roleLookUp, vRoleLookUp).firstOrDefault(x => x.roleId == roleId);
    return roleName["roleName"];
}

manageUserRoles(isRedirect: boolean): void {
    this.spin = true;
    this.put({ params: [this.powerBIReportId], path: "api/PowerBIRepoerts", body: this.powerBiReportFormGroup.value }).subscribe(data => {
        this.spin = false;
        var existsAlert = this.localizedMessages.filter(x => x.keyName == "Data_Updated");
        if (existsAlert) {
            this.toastr.success(existsAlert[0].showMessage);
        }
        isRedirect ? this.ngOnInit() : this.router.navigate(['/power-bi-reports']);
    })
}
get componentName(): string {
    return "PowerBIReportUserRoleComponent";
}

}
