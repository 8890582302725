import {vGemfieldsArrangementRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemfieldsArrangementRecord extends vGemfieldsArrangementRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}