import { TemplateConfig } from '@rxweb/grid';

export const IMAGE_VIEW_WITH_SOURCE_GRID_TEMPLATE: TemplateConfig = {
    img: {
        attributes: {
            src: function (e) {
                return this[e.name] 
                // if(this.Wholesalepiecestatus == true){
                //     return this["assets/images/checked.gif"]
                // }
                // else{
                //     return this["assets/images/unchecked.gif"]
                // }
            },
        },
        // class: ["grid-image"],
        style: [{
            "width":"16px",
            "margin-left":"10px"
        }]
    }
}


