import {OrderBase,ProductOrderBase,} from '@app/database-models'
import { propArray, propObject, prop } from '@rxweb/reactive-form-validators';
//Generated Imports
export class Order extends OrderBase
{


    @prop()
    filePath:string;

    @prop()
    orderStatus:string;

    @prop()
    customer:string;

    @prop()
    orderInsuranceExclTax :string;

    @prop()
    orderDutyandCustomFee :string;

    @prop()
    paymentStatus :string;

    @prop()
    salesPerson :string;

    @prop()
    salesChannel :string;

    @prop()
    shippingStatus:string;

    @prop({defaultValue:false})
    isBannedIP: boolean;

    @prop({defaultValue:""})
    pieceIds :string;

    @prop({defaultValue:0})
    pieceIdToBeDeleted :number;

    @prop({defaultValue:0})
    location :number;

    @prop({defaultValue:0})
    pieceDeliveryStatus :number;

    @prop({defaultValue:false})
    isOrderCancelled : boolean;

    @prop({defaultValue:false})
    isOrderDetailsEdited : boolean;

    @prop({defaultValue:false})
    isPaymentStatusEdited : boolean;

    @prop({defaultValue:false})
    isInvoiceNoEdited : boolean;

    @prop({defaultValue:false})
    isSalesMemberChannelEdited : boolean;

    @prop({defaultValue:false})
    isBillingAddressEdited : boolean;

    @prop({defaultValue:false})
    isShippingAddressEdited : boolean;

    @prop({defaultValue:false})
    isShippingMethodEdited : boolean;

    @prop({defaultValue:false})
    isTrackingNoEdited : boolean;

    @prop({defaultValue:false})
    isNewPieceAdded : boolean;

    @prop({defaultValue:false})
    isPieceDeleted : boolean;

    @prop({defaultValue:false})
    isPieceDeliveryStatusEdited : boolean;

    @prop({defaultValue:false})
    isLocationEdited : boolean;

    @prop({defaultValue:false})
    isOrderShippedEmail : boolean;

    @prop({defaultValue:false})
    isOrderDeliveredEmail : boolean;

    @prop()
    creditCardID : boolean;

    @prop()
    email : string;

    @prop()
    adminId : number;

    @prop({defaultValue:false})
    IsPOS : boolean;

    @prop({defaultValue:false})
    isStore : boolean;

    @prop({defaultValue:false})
    isBilling : boolean

    @prop({defaultValue:false})
    isNewPieceAddedforBooking:boolean

    @prop()
    deliveryType:string;

    @prop()
    orderDateTime:string;

    @prop({defaultValue:false})
    showPaymentMethodPopup : boolean;


//#region Generated Reference Properties
//#region productOrders Prop
 productOrders : ProductOrderBase[];
//#endregion productOrders Prop

//#endregion Generated Reference Properties

    @prop({defaultValue:0})
    bookingId:number;

    @prop()
    customerEmail:string;

    @prop()
    vatNumberString: string;

    @prop({defaultValue:0})
    posCallMe:number;

    @prop({defaultValue:0})
    eventId:number;

    @prop({defaultValue:0})
    projectId:number;

    posCallMeName:string=""
    posCallMeMessage:string=""
    @prop()
    rawShopifyId:string;

    isReturnOrdersAvailable:boolean;
}
