import {vGemstoneOriginRecordBase,} from '@app/database-models'
//Generated Imports
export class vGemstoneOriginRecord extends vGemstoneOriginRecordBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}