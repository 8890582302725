import { http } from "@rxweb/http";
import { IFormGroup } from "@rxweb/reactive-form-validators";
import {
  ProductBatch,
  vCurrencyLookUp,
  vChainLengthLookUp,
  vWarehousLookUp,
  vEggSizeLookUp,
  vAvailabilityStatusLookUp,
  vEventOwnerLookUp,
  vProductionFormOrderNo,
  vLegalOwnerBatchLookUp,
} from "@app/models";
import { ModalView } from "src/app/domain/customize-design/modal";
import { BaseToastr } from "src/app/domain/customize-design/toastr";
import { BaseDialog } from "src/app/domain/customize-design/dialog";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { FileInputViewModel } from "src/app/view-model/file-input-view-model";
import { FileComponent } from "src/app/components/shared/global/file/file.component";
import { FileOutputViewModel } from "src/app/view-model/file-output-view-model";
import { vProductSupplierLookup } from "src/app/view-model/vProductSupplierLookUp";
import { CoreComponent } from "@rxweb/framework";
import { ImgModalComponent } from "src/app/components/img-modal/img-modal.component";
@http({
  path: "api/ProductBatch",
})
export class AbstractProductBatch extends CoreComponent {
  productBatchFormGroup: IFormGroup<ProductBatch>;
  isShowGrid: boolean;
  isNoRecordFound: boolean;
  designStatusLookup: any;
  startDate: Date;
  endDate: Date;
  collectionLookup: any;
  categoryLookup: any;
  productStatusLookUp: any;
  productQuestions: any;
  spin: boolean = false;
  modalView: ModalView;
  showComponent: boolean = false;
  toastr: BaseToastr;
  dialog: BaseDialog;
  router: Router;
  id: number;
  sanitizer: DomSanitizer;
  fileInputViewModel1: FileInputViewModel;
  fileInputViewModel2: FileInputViewModel;
  fileInputViewModel3: FileInputViewModel;
  fileComponent: FileComponent;
  fileURL1: any;
  fileURL2: any;
  fileOutputViewModel: FileOutputViewModel = new FileOutputViewModel();
  dateError: boolean = false;
  bookingError: boolean;
  selectedTab: string = "batch-info";
  validField: string[] = [
    "supplierID",
    "supplierOrderNo",
    "supplierDeliveryDate",
    "fabergePurchaseOrderNo",
    "quantity",
    "shipperId",
    "location",
    "availabilityStatus",
  ];
  breakLoop: boolean;
  constructor() {
    super();
    this.toastr = new BaseToastr();
    this.dialog = new BaseDialog();
  }
  productBatchLookups: any = {
    productCurrencyLookUp: vCurrencyLookUp,
    productSupplierLookUp: vProductSupplierLookup,
    productBatchEggSizeLookUp: vEggSizeLookUp,
    chainLengthLookUp: vChainLengthLookUp,
    batchWarehouseLookUp: vWarehousLookUp,
    legalOwnerLookup: vLegalOwnerBatchLookUp,
    availabilityStatusLookUp: vAvailabilityStatusLookUp,
    adminUserLookUp: vEventOwnerLookUp,
    productionFormOrderNo: vProductionFormOrderNo,
    productBatch: ProductBatch,
  };

  showImgModal() {
    this.modalView
      .show(ImgModalComponent, {
        imgSrc: this.productBatchLookups.productBatch.productImageUrl,
      })
      .then((x) => {});
  }
}
