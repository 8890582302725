<div class="search-result-container" *rxMultilingualExtended="_this" [rxLocalisationInit]="componentName">
  <div class="ibox mb-0 d-flex flex-column mt-4" >
    <div class="ibox-content bg-white d-flex flex-column flex-grow-1" >
      <div class="dataTables_filter  d-flex flex-wrap align-items-center" [ngClass]="!taskDescriptionShowHideButton ? 'mb-3' : ''">
        <button class="btn btn-success" (click)="showAddForm()" rxText="TaskDetailListComponent_AddTask_t"></button>
        <button class="btn btn-success ml-2" (click)="resetFilter()" rxText="TaskDetailListComponent_Btn_ViewAllTasks_t" *ngIf="isFromMyProfile"></button>
          <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowSearch && taskDescriptionShowHideButton && !isShowAllTaskDescription">
            <label class="ml-auto mr-3 mb-2 btn-group-gray">
              <button type="button" class="btn mt-2 pull-right" (click)="onMoreClickAllTaskDescription()" rxText="Btn_Show_All_t"></button>
          </label>
          </ng-container>
          <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowSearch && taskDescriptionShowHideButton && isShowAllTaskDescription">
            <label class="ml-auto mr-3 mb-3 btn-group-gray">
              <button type="button" class="btn mt-3 pull-right" (click)="onMoreClickAllTaskDescription()" rxText="Btn_Hide_All_t"></button>
            </label>
          </ng-container>
          <ng-container [rxLocalisationInit]="componentName" *ngIf="isShowSearch">
            <input (keyup.enter)="search($event)" type="search" class="table_search form-control form-control-sm pr-4"
            placeholder="" [title]="titleSearch" [ngClass]="!taskDescriptionShowHideButton ? 'ml-auto' : ''">
          </ng-container>
      </div>
      <div class="table-responsive" *ngIf="showGrid" [rxSpinner]="spin">
        <div class="dataTables_wrapper pb-0 dt-bootstrap4">
          <rx-grid [design]="TaskDetailListGrid"></rx-grid>
        </div>
      </div>
      <div class="ibox-title bg-white" *ngIf="isNoRecordFound">
        <h4>No Record Found</h4>
      </div>
    </div>
  </div>
</div>
