import { prop,propObject,propArray,required,maxLength,range  } from "@rxweb/reactive-form-validators"
import { gridColumn } from "@rxweb/grid"


export class vRoleBase {

//#region roleId Prop
        @gridColumn({style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:0, allowSorting: true, headerKey: 'RoleListComponent_RoleId_gh', keyColumn: true})
        roleId : number;
//#endregion roleId Prop


//#region roleName Prop
        @gridColumn({isAscending:true,style: { width: "25%", "text-align": "initial" },class: ["text-left"],visible: true, columnIndex:1, allowSorting: true, headerKey: 'RoleListComponent_RoleName_gh', keyColumn: false})
        roleName : string;
//#endregion roleName Prop


//#region roleDomainName Prop
        @gridColumn({visible: false, columnIndex:2, allowSorting: true, headerKey: 'RoleListComponent_DomainName_gh', keyColumn: false})
        roleDomainName : string;
//#endregion roleDomainName Prop


//#region roleTypeName Prop
        @gridColumn({isAscending:true,visible: false, columnIndex:3, allowSorting: true, headerKey: 'RoleListComponent_TypeName_gh', keyColumn: false})
        roleTypeName : string;
//#endregion roleTypeName Prop

//#region roleTypeName Prop
        @gridColumn({isAscending:false,visible: true, columnIndex:4, allowSorting: false, headerKey: 'RoleListComponent_IsActive_gh', keyColumn: false,configuredTemplate: { templateName: "recordActive" }})
        active : boolean;
//#endregion roleTypeName Prop

}