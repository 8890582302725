import {vBOMSuppliersLookUpBase,} from '@app/database-models'
//Generated Imports
export class vBOMSuppliersLookUp extends vBOMSuppliersLookUpBase 
{




//#region Generated Reference Properties

//#endregion Generated Reference Properties










}